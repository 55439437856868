import { TFunction } from 'react-i18next';

import {
    QUICK_ACTION_LINKS,
    QuickActionLink,
} from 'app/addon/QuickAction/links';
import { V2UserData } from 'app/api/types/user';
import { APP_PRODUCTS } from 'app/common/data/productIds';
import {
    ADMIN,
    BUSINESS_MANAGER,
    GROUP_MANAGER,
    ORG_ADMIN,
    PROVIDER,
} from 'app/common/data/roles';

export const getAvailableActions = (
    user: V2UserData | undefined,
    t: TFunction,
): Array<QuickActionLink> => {
    if (!user) return [];

    const actions: QuickActionLink[] = [];

    addVisibilityActions(actions, user);
    addMessagesActions(actions, user);
    addReviewsActions(actions, user);
    addReviewBoosterActions(actions, user);
    addSettingsActions(actions, user);

    return actions.map(({ title, subtitle, ...remaining }) => {
        return {
            title: t(title),
            subtitle: t(subtitle),
            ...remaining,
        };
    });
};

const addVisibilityActions = (actions: QuickActionLink[], user: V2UserData) => {
    actions.push(QUICK_ACTION_LINKS.BUSINESS_LIST);

    if (user.sidebar_products?.includes(APP_PRODUCTS.PRESENCE_MANAGEMENT)) {
        actions.push(QUICK_ACTION_LINKS.DIFFUSION_STATUS);

        if (user.role != ADMIN)
            actions.push(QUICK_ACTION_LINKS.VISIBILITY_STATS);
    }

    if (!user.sidebar_excluded_pages?.includes('google_post'))
        actions.push(QUICK_ACTION_LINKS.POSTS);
};

const addMessagesActions = (actions: QuickActionLink[], user: V2UserData) => {
    if (
        user.sidebar_products?.includes(APP_PRODUCTS.BUSINESS_MESSAGE) &&
        ![ADMIN, PROVIDER].includes(user.role)
    ) {
        actions.push(
            QUICK_ACTION_LINKS.MESSAGES,
            QUICK_ACTION_LINKS.MESSAGE_SETTINGS,
        );

        if (![GROUP_MANAGER, BUSINESS_MANAGER].includes(user.role))
            actions.push(QUICK_ACTION_LINKS.MESSAGE_REPLY_TEMPLATES);
    }
};

const addReviewsActions = (actions: QuickActionLink[], user: V2UserData) => {
    if (user.sidebar_products?.includes(APP_PRODUCTS.REVIEW_MANAGEMENT)) {
        actions.push(
            QUICK_ACTION_LINKS.REVIEWS,
            QUICK_ACTION_LINKS.NOTIFICATIONS_SETTINGS,
        );

        if (![GROUP_MANAGER, BUSINESS_MANAGER].includes(user.role))
            actions.push(QUICK_ACTION_LINKS.REVIEW_TEMPLATES);

        if (user.role != ADMIN) actions.push(QUICK_ACTION_LINKS.REVIEWS_STATS);

        if (user.role == ORG_ADMIN)
            actions.push(QUICK_ACTION_LINKS.REVIEW_AUTO_REPLY);
    }
};

const addReviewBoosterActions = (
    actions: QuickActionLink[],
    user: V2UserData,
) => {
    if (user.sidebar_products?.includes(APP_PRODUCTS.REVIEW_BOOSTER))
        actions.push(QUICK_ACTION_LINKS.REVIEW_BOOSTER);
};

const addSettingsActions = (actions: QuickActionLink[], user: V2UserData) => {
    actions.push(
        QUICK_ACTION_LINKS.PROFILE_SETTINGS,
        QUICK_ACTION_LINKS.BUSINESS_CONNECTIONS,
        QUICK_ACTION_LINKS.USER_LIST,
        QUICK_ACTION_LINKS.CREATE_USER,
        QUICK_ACTION_LINKS.HELP_CENTER,
        QUICK_ACTION_LINKS.VERIFY_LISTINGS,
    );

    if (
        ![GROUP_MANAGER, BUSINESS_MANAGER].includes(user.role) &&
        user.role != ADMIN
    )
        actions.push(QUICK_ACTION_LINKS.USER_PERMISSIONS);

    if (![GROUP_MANAGER, BUSINESS_MANAGER, PROVIDER, ADMIN].includes(user.role))
        actions.push(QUICK_ACTION_LINKS.CUSTOM_FIELDS);

    if (
        !user.sidebar_excluded_pages?.includes('api_key_manager') &&
        user.role != ADMIN
    )
        actions.push(QUICK_ACTION_LINKS.API_KEYS);
};
