import { connect } from 'react-redux';

import { partnerToPartnerName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import {
    connectedLocationIdSelector,
    selectedPartnerSelector,
} from 'app/states/knowledge/reducers/businessConnection/connection';
import { AppState } from 'app/states/reducers';

import { InfoSection as InfoSectionComponent } from './InfoSection';

const mapStateToProps = ({ businessConnection: { page } }: AppState) => ({
    connectedLocationId: connectedLocationIdSelector(page),
    partnerName: partnerToPartnerName[selectedPartnerSelector(page)],
});

const InfoSection = connect(mapStateToProps)(InfoSectionComponent);

export default InfoSection;
