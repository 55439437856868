import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import { CONNECTION_STATS } from 'app/common/data/queryKeysConstants';

import { Platform } from '../components/services/constants';

export default function useBusinessConnectionStats(platforms: Platform[]) {
    const businessFilterParams = useBusinessModalFilters();
    const selectedBusinessesCount = useSelectedBusinessesCount();
    const enabled = !!selectedBusinessesCount && platforms?.length > 0;

    const { data: connectionData } = useQuery(
        [CONNECTION_STATS, businessFilterParams],
        () =>
            api.business.getUserBusinessConnectionsStats(businessFilterParams),
        { enabled },
    );

    return connectionData;
}
