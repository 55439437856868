import { useTranslation } from 'react-i18next';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';

export default function useTimeOfLastPhotoUpdateInfo() {
    const { data: business } = useBusiness();
    const { t } = useTranslation();
    const timeOfLastPhotoUpdate = business?.time_of_last_photo_update
        ? new Date(business?.time_of_last_photo_update)
        : undefined;

    const options = {
        val: timeOfLastPhotoUpdate,
        formatParams: {
            val: { year: 'numeric', month: 'long', day: 'numeric' },
        },
    };

    return timeOfLastPhotoUpdate
        ? t('business_edit_time_of_last_photo_update', options)
        : undefined;
}
