import {
    PostCardSticker,
    PostCardText,
    PostCardTypeIcon,
} from 'app/common/designSystem/components/molecules/PreviewPostCard/PreviewPostTypeLabel.styled';

type Props = {
    icon: string;
    text: string;
};

const PostTypeLabel = ({ icon, text }: Props) => (
    <PostCardSticker>
        <PostCardTypeIcon className={icon} />
        <PostCardText>{text}</PostCardText>
    </PostCardSticker>
);

export default PostTypeLabel;
