import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix } from '@partoohub/ui';

import { hideableComponentsNames, withHideableComponent } from 'app/SDKBridge';

type Props = {
    onClick: () => void;
};

const PageHeaderSearchIcon = ({ onClick }: Props) => (
    <IconButton
        dataTrackId="search_icon_button"
        appearance="outlined"
        variant="primary"
        icon={[FontAwesomeIconsPartooUsed.faSearch, IconPrefix.REGULAR]}
        onClick={onClick}
    />
);

export default withHideableComponent(
    PageHeaderSearchIcon,
    hideableComponentsNames.PAGE_HEADER_SEARCH_BUTTON,
);
