import { APP_PRODUCTS, BUSINESS_MESSAGE } from 'app/common/data/productIds';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useCanAccessMessagesSetting = () => {
    const { data: me, isLoading: isLoadingMe } = useMe();

    // Sidebar product (BUSINESS_MESSAGE) ON for user
    const userData = me ?? null;
    const userHasBusinessMessage = !!userData?.sidebar_products?.includes(
        APP_PRODUCTS[BUSINESS_MESSAGE],
    );

    // Valid BM subscription on org
    const {
        hasBusinesses,
        subscribedToBM,
        isLoading: isLoadingBusinessMessageSubscription,
    } = useBusinessSubscriptions();

    const canAccess = userHasBusinessMessage && hasBusinesses && subscribedToBM;
    const isLoading = isLoadingMe || isLoadingBusinessMessageSubscription;

    return { canAccess, isLoading };
};
