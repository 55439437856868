import { FC, useState } from 'react';

import { css } from '@emotion/react';
import { push } from '@lagunovsky/redux-react-router';
import { HEX_COLORS, radius } from '@partoohub/branding';

import { Checkbox, Chip, FontAwesomeIconsPartooUsed, Icon, Modal, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { V2LocationData } from 'app/api/types/account';
import { BusinessReachEnum, V2BusinessData } from 'app/api/types/business';
import { ColorProgress } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import { getBusinessStatusCompletionInfos } from 'app/businessEditV2/sections/BusinessStatusSection/BusinessStatusCompletion/BusinessStatusCompletion';
import { COLORS_PROGRESS } from 'app/businessEditV2/utils/businessBanner';
import { isBusinessOpeningSoon } from 'app/businessEditV2/utils/businessOpeningSoon';
import { doesLocationRequiresVerification } from 'app/businessEditV2/utils/locations';
import VerificationRequiredButton from 'app/common/components/buttons/VerificationRequiredButton';
import { ImageWithFallback } from 'app/common/components/ImageWithFallback/ImageWithFallback';
import { CLOSED, CLOSED_TEMPORARILY } from 'app/common/data/businessStatuses';
import IS_IFRAME from 'app/common/data/iframe';
import { SMB } from 'app/common/data/organization';
import useOrganization from 'app/common/hooks/queries/useOrganization';
import dataLayer from 'app/common/utils/dataLayer';
import { useBusinessSelectionContext } from 'app/pages/visibility/BusinessListV2/hooks/businessSelection/useBusinessSelectionContext';
import useDisplayBulkCheckbox from 'app/pages/visibility/BusinessListV2/hooks/businessSelection/useDisplayBulkCheckbox';
import {
    BusinessAddress,
    BusinessCardColor,
    BusinessCardContainer,
    BusinessCaret,
    BusinessCompletionRate,
    BusinessFullAddress,
    BusinessImage,
    BusinessReach,
    BusinessStatusChip,
    BusinessSubscriptionsButton,
    BusinessTitle,
    BusinessVerificationButton,
    CheckboxWrapper,
    IconContainer,
} from 'app/pages/visibility/BusinessListV2/sections/BusinessListSection/BusinessCard/BusinessCard.styled';
import SubscriptionsButton from 'app/pages/visibility/BusinessListV2/sections/BusinessListSection/BusinessCard/SubscriptionsButton';
import SubscriptionsModal from 'app/pages/visibility/BusinessListV2/sections/BusinessListSection/BusinessCard/SubscriptionsModal';
import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';

import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

import { getMappedUrl } from 'app/utils/resizeImage';

interface Props {
    business: V2BusinessData;
    location?: V2LocationData;
}

const BusinessCard: FC<Props> = ({ business, location }) => {
    const { data: org } = useOrganization(business.org_id);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id, name, city, zipcode, country } = business;
    const [showModal, setShowModal] = useState<boolean>(false);
    const businessPhoto = getMappedUrl(business?.photos?.primary ?? '', business?.media_mapping);
    const { isSelected, toggle } = useBusinessSelectionContext();
    const { displayBulkCheckbox } = useDisplayBulkCheckbox();

    const address = `${business?.address_full}, ${zipcode} ${city}, ${country}`;
    const completionRate = business.completion_rate;
    const isBusinessClosed = business.status === CLOSED;
    const isBusinessClosedTemporarily = business.status === CLOSED_TEMPORARILY;
    const isOpeningSoon = isBusinessOpeningSoon(business);
    const disableBulkCheckbox = isBusinessClosed;
    const isBusinessSelected = isSelected(business.id);

    const businessNotSubscribed = Object.values(business.subscriptions).every(
        subscription => !subscription.active,
    );
    const displaySubscribeButton =
        !isBusinessClosed &&
        businessNotSubscribed &&
        ((!IS_IFRAME && org?.organization_type === SMB) || (IS_IFRAME && org?.display_subscribe));
    const displayRequiresVerification =
        !isBusinessClosed &&
        !displaySubscribeButton &&
        business?.google_location_id &&
        doesLocationRequiresVerification(location);

    const color = isBusinessClosed
        ? COLORS_PROGRESS[ColorProgress.NONE]
        : getBusinessStatusCompletionInfos(completionRate);

    const onClick = (e: any) => {
        dataLayer.pushDict('click_business', {
            completion_score_business: completionRate,
        });
        if (IS_IFRAME) {
            e.preventDefault();
            e.stopPropagation();
        }
        sdkBridge.linkFunctionToEvent(
            () => {
                if (IS_IFRAME) {
                    dispatch(push(`${VISIBILITY_LOCATION_PATH}/${id}`));
                }
            },
            PARTOO_APP_EVENT_IDS.OPEN_BUSINESS_EVENT,
            false,
            () => id,
        )();
    };

    const disableOpenBusinessEdit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        toggle(business.id);
    };

    const containerCss = css`
        height: 50px;
        width: 50px;
        border-radius: ${radius.default};
    `;

    return (
        <>
            <NavLink
                to={`${VISIBILITY_LOCATION_PATH}/${id}`}
                onClick={onClick}
                style={{ textDecoration: 'none' }}
            >
                <BusinessCardContainer
                    data-track="visibility_locations__list_line"
                    data-intercom-target="visibility_locations__list_line"
                    displayBulkCheckbox={displayBulkCheckbox}
                    isSelected={isBusinessSelected}
                >
                    <BusinessCardColor
                        color={color.gradient.middle}
                        displayBulkCheckbox={displayBulkCheckbox}
                        isSelected={isBusinessSelected}
                    />
                    {displayBulkCheckbox && (
                        <CheckboxWrapper onClick={disableOpenBusinessEdit}>
                            <Checkbox
                                dataTrackId="business_list__bulk_select_business"
                                aria-label=""
                                checked={isBusinessSelected}
                                onChange={() => toggle(business.id)}
                                disabled={disableBulkCheckbox}
                            />
                        </CheckboxWrapper>
                    )}
                    <ImageWithFallback
                        src={businessPhoto}
                        width={200}
                        height={200}
                        containerCss={containerCss}
                        shouldResize
                        fallback={
                            <BusinessImage url={businessPhoto}>
                                {!businessPhoto && <i className="fa-solid fa-image" />}
                            </BusinessImage>
                        }
                    />

                    <BusinessFullAddress
                        data-track="visibility_locations__list_line__full_address"
                        data-intercom-target="visibility_locations__list_line__full_address"
                    >
                        <BusinessTitle>{name}</BusinessTitle>
                        {business.reach === BusinessReachEnum.GLOBAL ? (
                            <BusinessReach>
                                <Text variant="bodySBold">{t('business_reach__global')}</Text>
                            </BusinessReach>
                        ) : (
                            <BusinessAddress>
                                <i className="fa-sharp fa-solid fa-location-dot" />
                                {address}
                            </BusinessAddress>
                        )}
                    </BusinessFullAddress>

                    {isBusinessClosed && (
                        <BusinessStatusChip>
                            <Chip chipShape="rounded" variant="danger" dataTrackId={'closed-chip'}>
                                {t('business_closed')}
                            </Chip>
                        </BusinessStatusChip>
                    )}
                    {isBusinessClosedTemporarily && (
                        <BusinessStatusChip>
                            <Chip chipShape="rounded" variant="danger" dataTrackId={'closed-chip'}>
                                <IconContainer>
                                    <Icon
                                        icon={[FontAwesomeIconsPartooUsed.faClock]}
                                        color="danger"
                                    />
                                    {t(CLOSED_TEMPORARILY)}
                                </IconContainer>
                            </Chip>
                        </BusinessStatusChip>
                    )}
                    {isOpeningSoon && (
                        <BusinessStatusChip>
                            <Chip
                                chipShape="rounded"
                                variant="primary"
                                dataTrackId={'opening-soon-chip'}
                            >
                                <IconContainer>
                                    <Icon icon={['fa-calendar']} color={HEX_COLORS.primary} />
                                    {t('business_opening_soon')}
                                </IconContainer>
                            </Chip>
                        </BusinessStatusChip>
                    )}

                    {displaySubscribeButton && (
                        <BusinessSubscriptionsButton>
                            <SubscriptionsButton setShowModal={setShowModal} />
                        </BusinessSubscriptionsButton>
                    )}

                    {displayRequiresVerification && (
                        <BusinessVerificationButton>
                            <VerificationRequiredButton
                                eventName="verify_from_business_list"
                                location={location}
                            />
                        </BusinessVerificationButton>
                    )}

                    <BusinessCompletionRate
                        color={color.gradient.middle}
                        data-track="visibility_locations__list_line__completion_rate"
                        data-intercom-target="visibility_locations__list_line__completion_rate"
                    >
                        {completionRate === null ? '-' : `${completionRate}%`}
                    </BusinessCompletionRate>

                    <BusinessCaret>
                        <i className="fa-solid fa-angle-right" />
                    </BusinessCaret>
                </BusinessCardContainer>
            </NavLink>
            <Modal isOpen={showModal} closeModal={() => setShowModal(false)}>
                <SubscriptionsModal business={business} setShowModal={setShowModal} />
            </Modal>
        </>
    );
};

export default BusinessCard;
