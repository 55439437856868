import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';
import { IconButton, Stack } from '@partoohub/ui';

export const ImageUploaderRowHeaderRight = styled(Stack)`
    flex-grow: 1;
    max-width: calc(
        100% - 90px
    ); // 90px corresponds to the total width of the reordering icon and the image miniature
`;

export const ImageModalCloseButton = styled(IconButton)`
    position: fixed;
    top: 16px;
    right: 16px;
`;

export const TextWrapper = styled.div<{ collapsable: boolean }>`
    max-width: ${({ collapsable }) => (collapsable ? 'calc(100% - 250px)' : 'calc(100% - 56px)')};
`;

export const ImageWrapper = styled.div<{ hasError: boolean; isLoading: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: ${greyColorObject.initial};
    overflow: hidden;

    ${({ hasError, isLoading, theme }) =>
        hasError &&
        !isLoading &&
        css`
            border-radius: 8px;
            border: dashed 1px ${theme.colors.danger.initial};
            background-color: ${theme.colors.danger.alpha};

            :hover {
                border-color: ${theme.colors.danger.dark};
            }
        `}
`;

export const ImageOverlay = styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.colors.overlay};
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    &:hover {
        opacity: 0.8;
    }
`;

export const shineAnimation = keyframes`
  from {
    left: -25%;
  }
  to {
    left: 100%;
  }
`;

export const ImageLoader = styled.div`
    position: relative;
    height: 100%;
    width: 35%;
    opacity: 0.8;
    background: linear-gradient(
        90deg,
        ${({ theme }) => theme.colors.theme.background}00,
        ${({ theme }) => theme.colors.theme.background}4D,
        ${({ theme }) => theme.colors.theme.background}66,
        ${({ theme }) => theme.colors.theme.background}4D,
        ${({ theme }) => theme.colors.theme.background}00
    );
    animation: ${shineAnimation} 2s linear infinite;

    @media (prefers-reduced-motion: reduce) {
        animation: unset;
    }
`;

export const ClickableRow = styled(Stack)`
    cursor: pointer;
    padding: 16px;
`;

export const IconWrapper = styled.div`
    cursor: grab;
`;

export const ActionButtonWrapper = styled.div``;
