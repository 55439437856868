import { LocaleDatePicker } from '@partoohub/ui';
import ar from 'date-fns/locale/ar';
import ca from 'date-fns/locale/ca';
import cs from 'date-fns/locale/cs'; // Czech
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import lv from 'date-fns/locale/lv'; // Latvian
import nl from 'date-fns/locale/nl';
import pl from 'date-fns/locale/pl'; // Polish
import pt from 'date-fns/locale/pt';
import ptBR from 'date-fns/locale/pt-BR';
import sl from 'date-fns/locale/sl'; // Slovenian

export const getDateFnsLocaleFromLocale = (
    locale: string,
): LocaleDatePicker => {
    const locales: any = {
        pl,
        cs,
        lv,
        sl,
        nl,
        'en-GB': en,
        fr,
        it,
        ca,
        es,
        ar,
        pt,
        de,
        'pt-BR': ptBR,
    };

    const selectedLocale = locales?.[locale];

    if (!selectedLocale) {
        return { name: 'en', locale: en };
    }
    return { name: locale, locale: selectedLocale };
};
