import { useContext, useMemo, useState } from 'react';

import { DatePickerType, RangeDate, Switch, Text } from '@partoohub/ui';
import { addHours, addMinutes, format, isAfter, isBefore, set } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LocaleDatePicker } from 'app/common/components/LocaleDatePicker/LocaleDatePicker';
import {
    LocaleTimeField,
    convert24hTimeToLocaleFormat,
} from 'app/common/components/LocaleTimeField';
import PostEditBulkConfirmModal from 'app/presence/googlePosts/components/googlePostList/PostEditBulkConfirmModal';

import {
    PostPublishScheduleWrapper,
    SwitchBox,
    SwitchBoxDescription,
} from './PostFormPublish.styled';
import PostPublishingModal from '../../../PostPublishingModal';
import PostPublishOnInstagramConfirmModal from '../../../PostPublishOnInstagramConfirmModal';
import NextBackButtons from '../../components/NextBackButtons/NextBackButtons';
import { NewPostContext, NewPostContextType } from '../../context/NewPost';

import { EditPostsContext, EditPostsContextType } from '../../hooks/useEditPostContext';
import usePlatformSelection from '../../hooks/usePlatformSelection';
import { infoStep } from '../../PostCreationModal';
import { ContentTitle } from '../TypePlatform/PostFormTypePlatform.styled';

export const PostFormPublish = () => {
    const [showModal, setShowModal] = useState<'bulk' | 'instagram' | undefined>(undefined);
    const { t } = useTranslation();
    const { getValues } = useFormContext();
    const {
        formFields: { isScheduled, scheduledDate, scheduledTime },
        postMutation,
        setPreviousStep,
        updateField,
        setStep,
        allSteps,
        isEditing,
    } = useContext<NewPostContextType>(NewPostContext);

    const { postCreationModalEditMode, postToEditData, bulkId } =
        useContext<EditPostsContextType>(EditPostsContext);

    const { containsInstagram } = usePlatformSelection();

    const hasLivePosts = postToEditData?.postStatus?.some(post => post.state === 'live');

    const shouldDisableSchedule = postCreationModalEditMode && hasLivePosts; // imposible to schedule in edit mode (with live posts)

    const hasError = (() => {
        if (!isScheduled) return { publishDisabled: false };

        if (!scheduledDate || !scheduledTime) return { publishDisabled: true };

        const hours = Number(scheduledTime.split(':')[0]);
        const minutes = Number(scheduledTime.split(':')[1]);
        const scheduleDateTime = set(scheduledDate, { hours, minutes });
        const twoHoursAhead = addHours(new Date(), 2);

        if (isBefore(scheduleDateTime, twoHoursAhead)) {
            return {
                publishDisabled: true,
                explanation: t('post_publish_schedule_hour_constraint'),
            };
        }

        if (getValues('event_end_date') && getValues('event_end_time')) {
            const eventEndDate = new Date(getValues('event_end_date'));
            const eventEndTime = new Date(getValues('event_end_time'));
            const formattedEventEndDateTime = new Date(
                eventEndDate.getFullYear(),
                eventEndDate.getMonth(),
                eventEndDate.getDate(),
                eventEndTime.getHours(),
                eventEndTime.getMinutes(),
            );

            if (isAfter(scheduleDateTime, formattedEventEndDateTime)) {
                return {
                    publishDisabled: true,
                    explanation: t('post_publish_schedule_after_event_end_date'),
                };
            }
        }

        return { publishDisabled: false };
    })();

    const onSwitch = (): void => {
        if (shouldDisableSchedule) {
            return;
        }

        if (isScheduled) {
            updateField({ field: 'isScheduled', value: false });
            updateField({ field: 'scheduledDate', value: null });
            updateField({ field: 'scheduledTime', value: null });
        } else {
            updateField({ field: 'isScheduled', value: true });
            updateField({ field: 'scheduledDate', value: new Date() });
            updateField({
                field: 'scheduledTime',
                value: format(addMinutes(addHours(new Date(), 2), 30), 'HH:mm'),
            });
        }
    };

    const onDateChange = (dates: RangeDate): void => {
        updateField({ field: 'scheduledDate', value: dates[0] });
    };

    const onHoursChange = (value: string | undefined): void => {
        updateField({ field: 'scheduledTime', value });
    };

    const { mutate, isLoading } = postMutation!;

    const onSubmit = () => {
        if (containsInstagram && !isEditing) {
            // Instagram posts are not editable, so we skip its confirmation modal if isEditing
            setShowModal('instagram');
        } else if (bulkId) {
            setShowModal('bulk');
        } else {
            mutate();
        }
    };

    const formattedDates: RangeDate = useMemo(() => {
        return [scheduledDate, null];
    }, [scheduledDate]);

    return (
        <>
            <PostPublishingModal isOpen={isLoading} />
            <PostPublishOnInstagramConfirmModal
                show={showModal === 'instagram'}
                cancel={() => {
                    setShowModal(undefined);
                    setStep(allSteps.indexOf(infoStep));
                }}
                confirm={() => {
                    setShowModal(undefined);
                    mutate();
                }}
            />
            <PostEditBulkConfirmModal
                show={showModal === 'bulk'}
                cancel={() => setShowModal(undefined)}
                confirm={() => {
                    setShowModal(undefined);
                    mutate();
                }}
            />
            <ContentTitle>{t('post_publish_title')}</ContentTitle>

            <SwitchBox isScheduled={isScheduled}>
                <SwitchBoxDescription>
                    <Text variant="heading6" as="span">
                        {t('post_publish_schedule_title')}
                    </Text>
                    <Text variant="bodyMBold" as="span" color="secondary">
                        {t('post_publish_schedule_description')}
                    </Text>
                </SwitchBoxDescription>
                <Switch
                    dataTrackId="post_publish__switch"
                    name="post_publish"
                    checked={isScheduled}
                    onChange={onSwitch}
                    disabled={shouldDisableSchedule}
                    labelPosition="left"
                />
            </SwitchBox>
            {isScheduled && (
                <PostPublishScheduleWrapper>
                    <LocaleDatePicker
                        dataTrackId="post_from__schedule_date"
                        dates={formattedDates}
                        type={DatePickerType.DateSelector}
                        onChange={onDateChange}
                        labels={{ startDate: t('date') }}
                        placeholders={{ startDate: t('date_placeholder') }}
                        minDate={new Date()}
                        error={hasError.explanation}
                        required
                        full
                    />
                    <LocaleTimeField
                        dataTrackId="post_form__schedule_hour"
                        label={t('time')}
                        placeholder={convert24hTimeToLocaleFormat('09:00')}
                        value={scheduledTime ?? ''}
                        onChange={onHoursChange}
                        error={!!hasError.explanation}
                        required
                    />
                </PostPublishScheduleWrapper>
            )}

            <NextBackButtons
                disabled={isLoading || hasError.publishDisabled}
                onNext={onSubmit}
                onPrevious={() => {
                    setPreviousStep();
                }}
                nextButtonTextKey={isScheduled ? 'post_schedule' : 'publish_google_post'}
            />
        </>
    );
};
