import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import useCustomFieldsSections from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useCustomFieldsSections';
import useDeleteCustomFieldSection from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useDeleteCustomFieldSection';
import { CustomFieldsSectionQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

type Props = {
    showModal: boolean;
    closeModal: () => void;
};

const CustomFieldsSectionDeleteContent = ({ showModal, closeModal }: Props) => {
    const { t } = useTranslation();

    const [deleteSectionId] = useStateQueryParams(CustomFieldsSectionQueryKeys.DELETE);
    const { data: customFieldsSections } = useCustomFieldsSections();

    const section = customFieldsSections?.sections.find(
        ({ id }) => id?.toString() === deleteSectionId,
    );

    const deleteSection = useDeleteCustomFieldSection(section?.id ?? 0, () => {
        toast.success(
            t('custom_fields_section_toast_delete_successful_description'),
            t('custom_fields_section_toast_delete_successful', { name: section?.name ?? '' }),
        );
        closeModal();
    });

    return (
        <ConfirmModal
            show={showModal}
            title={t('custom_fields_section_deletion_title')}
            content={t('custom_fields_section_deletion_description')}
            confirmLabel={t('delete')}
            modalFor="delete"
            onConfirm={() => deleteSection.mutate()}
            onHide={closeModal}
            trackId="delete-group"
        />
    );
};

export default CustomFieldsSectionDeleteContent;
