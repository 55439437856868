import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import { GRID_GAP } from 'app/businessEditV2/components/BusinessEditV2Content/BusinessEditV2Content.styled';
import { EDIT_SECTION_HEIGHT } from 'app/businessEditV2/components/EditSection/EditSection.styled';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

export const FULL_HEIGHT_SECTION = 2 * EDIT_SECTION_HEIGHT + GRID_GAP;
export const FULL_SECTION_PADDING = 16;

type SectionColorProps = {
    sectionColor: ColorState;
};

export const EditSectionFullHeightContainer = styled.button<SectionColorProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: ${FULL_HEIGHT_SECTION}px;
    padding: ${FULL_SECTION_PADDING}px;
    background: none;
    outline: inherit;
    border: solid 1px ${DISABLED_COLOR};
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.2s;

    &:hover {
        border: 1px solid ${greyColorObject.dark};
        cursor: pointer;
    }

    > i {
        margin: 0 4px 0 8px;
        color: ${({ theme }) => theme.colors.secondary.initial};
    }

    ${({ sectionColor, theme }) =>
        [ColorState.DISABLED, ColorState.CLOSED].includes(sectionColor) &&
        css`
            border-color: ${theme.colors.secondary.alpha};

            &:hover {
                border-color: ${greyColorObject.dark};
            }
        `};
`;
