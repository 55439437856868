import React from 'react';

const AutoReplyLogo = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.85786 34.1422C13.6683 41.9527 26.3317 41.9527 34.1421 34.1422C41.9526 26.3317 41.9526 13.6684 34.1421 5.85796C26.3317 -1.95253 13.6683 -1.95253 5.85786 5.85796C-1.95262 13.6684 -1.95262 26.3317 5.85786 34.1422Z"
            fill="#0085F2"
            fillOpacity="0.12"
        />
        <path
            d="M16.1382 16.0469C15.4719 16.0469 14.9314 16.5873 14.9314 17.2537C14.9314 17.92 15.4719 18.4605 16.1382 18.4605C16.8046 18.4605 17.345 17.92 17.345 17.2537C17.345 16.5873 16.8058 16.0469 16.1382 16.0469ZM8.89747 16.0469C8.20733 16.0469 7.69067 16.6114 7.69067 17.2537C7.69067 17.897 8.20846 18.4265 8.89747 18.4265C9.60835 18.4265 9.85687 17.925 9.91683 17.8571H11.3111V16.6503H9.91683C9.85687 16.5824 9.60835 16.0469 8.89747 16.0469ZM23.379 16.0469C22.7126 16.0469 22.1722 16.5873 22.1722 17.2537C22.1722 17.92 22.7126 18.4605 23.379 18.4605C24.0454 18.4605 24.5858 17.92 24.5858 17.2537C24.5858 16.5873 24.0465 16.0469 23.379 16.0469ZM30.6198 16.0469C29.9089 16.0469 29.6604 16.5488 29.6004 16.6503H28.2062V17.8571H29.6008C29.6619 17.9928 29.9108 18.4605 30.6198 18.4605C31.3092 18.4605 31.8266 17.897 31.8266 17.2876C31.8266 16.6782 31.3099 16.0469 30.6198 16.0469Z"
            fill="#0085F2"
        />
        <path
            opacity="0.4"
            d="M25.4306 10.0469H14.5695C12.9026 10.0469 11.5525 11.3974 11.5525 13.0639V22.6843C11.5525 24.3504 12.9033 25.7013 14.5695 25.7013H16.3797V28.8687C16.3797 29.0275 16.4815 29.3556 16.8322 29.3556C16.9274 29.3556 17.0207 29.3251 17.0998 29.2678L21.8103 25.7013L25.4306 25.7015C27.0968 25.7015 28.4476 24.3506 28.4476 22.6845V13.0639C28.4476 11.3974 27.0975 10.0469 25.4306 10.0469ZM26.034 19.7012C26.034 20.3676 25.4936 20.908 24.8272 20.908H15.1729C14.5054 20.908 13.9661 20.3687 13.9661 19.7012V14.8741C13.9661 14.2077 14.5065 13.6673 15.1729 13.6673H24.8272C25.4936 13.6673 26.034 14.2077 26.034 14.8741V19.7012Z"
            fill="#0085F2"
        />
    </svg>
);

export default AutoReplyLogo;
