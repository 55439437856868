export const LOAD_INITIAL_FILTER_DATA_REQUEST =
    'LOAD_INITIAL_FILTER_DATA_REQUEST';

export type LoadInitialFilterDataRequestAction = {
    type: 'LOAD_INITIAL_FILTER_DATA_REQUEST';
};

export const loadInitialFilterDataRequest =
    (): LoadInitialFilterDataRequestAction => ({
        type: LOAD_INITIAL_FILTER_DATA_REQUEST,
    });
