import { useCallback, useContext } from 'react';

import { useMutation } from 'react-query';

import { InteractionTagType, ReviewTagMatch } from 'app/api/types/interactionTag';
import { REVIEW_PERMISSION_ASSIGN_TAG } from 'app/api/types/review';
import API from 'app/api/v2/api_calls/';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { useWindowSize } from 'app/hooks/useWindowSize';
import { useUpdateReviewQueryData } from 'app/reviewManagement/reviewList/hooks/queryData/useUpdateReviewQueryData';
import { ReviewsQueryKeys } from 'app/reviewManagement/reviewList/hooks/useGetReviewsFilters';
import { useHasReviewPermission } from 'app/reviewManagement/reviewList/hooks/useReviewPermissions';
import {
    TagBlackTooltip,
    TagPillBodyText,
} from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/TagSection/TagList.styled';
import {
    PillBody,
    PillColor,
    PillCross,
} from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/TagSection/TagSection.styled';
import { truncate } from 'app/utils';

import { ReviewCardContext, ReviewCardContextType } from '../ReviewCard';

type Props = {
    collapsedList?: boolean;
    setIsOpen?: (isOpen: boolean) => void;
};

const MOBILE_BREAKPOINT = 769;
const FULL_BREAKPOINT = 1_280;

export default function TagList({ setIsOpen = undefined, collapsedList = false }: Props) {
    const { review } = useContext<ReviewCardContextType>(ReviewCardContext);
    const { width = 0 } = useWindowSize();
    const [queryValue] = useStateQueryParams(ReviewsQueryKeys.TAG);
    const updateReviewQueryData = useUpdateReviewQueryData();

    const numTags = width < MOBILE_BREAKPOINT ? 1 : width < FULL_BREAKPOINT ? 2 : 3;

    const tagLength = width < MOBILE_BREAKPOINT ? 10 : 20;

    const hasAssignTagPermission = useHasReviewPermission(REVIEW_PERMISSION_ASSIGN_TAG);

    const { mutate: unassignTagMutate } = useMutation(
        (interactionTagId: number) => {
            const body: ReviewTagMatch[] = [
                { review_id: review.id, interaction_tag_id: interactionTagId },
            ];
            return API.reviewTag.unassign(body);
        },
        {
            onSuccess: (_, interactionTagId: number) => {
                const tagIdsInFilter = queryValue !== '' ? queryValue.split(',').map(Number) : [];
                const restTagsInReview = review.tags.filter(
                    ({ id }: InteractionTagType) => id !== interactionTagId,
                );
                const shouldRemoveReviewFromView =
                    tagIdsInFilter.length > 0 &&
                    new Set([
                        ...tagIdsInFilter,
                        ...restTagsInReview.map(({ id }: InteractionTagType) => id),
                    ]).size ===
                        tagIdsInFilter.length + restTagsInReview.length;
                updateReviewQueryData(
                    { ...review, tags: restTagsInReview },
                    { hideReview: shouldRemoveReviewFromView },
                );
            },
        },
    );

    const openMenu = useCallback(() => {
        if (setIsOpen) {
            setIsOpen(true);
        }
    }, []);

    let reviewTagsToShow;
    let collapsedTagsToolTip = '';

    if (collapsedList) {
        reviewTagsToShow = review.tags.slice(0, numTags);
        collapsedTagsToolTip = review.tags
            .map(tag => tag.label)
            .slice(numTags)
            .join(', ');
    } else {
        reviewTagsToShow = review.tags;
    }

    return (
        <>
            {reviewTagsToShow.map(({ id, color, label }) => (
                <PillBody key={id}>
                    <PillColor color={color} />
                    <TagPillBodyText>{truncate(label, tagLength)}</TagPillBodyText>
                    {hasAssignTagPermission && (
                        <PillCross
                            onClick={() => {
                                if (hasAssignTagPermission) unassignTagMutate(id);
                            }}
                            className="fa-solid fa-xmark"
                        />
                    )}
                </PillBody>
            ))}
            {review.tags.length > numTags && collapsedList && (
                <TooltipWrapper
                    text={collapsedTagsToolTip}
                    position={'top'}
                    tooltip={TagBlackTooltip}
                >
                    <PillBody onClick={openMenu}>
                        <TagPillBodyText>+{review.tags.length - numTags}</TagPillBodyText>
                    </PillBody>
                </TooltipWrapper>
            )}
        </>
    );
}
