import { omit } from 'lodash-es';

import { ReviewCountByType } from 'app/states/reviews';

import { ReviewObject } from 'app/states/reviews';

import { ReviewsState } from './types';
import * as actions from '../actions';
import {
    DELETED_REVIEWS,
    NOT_TREATED_REVIEWS,
    TREATED_REVIEWS,
    initialState as filtersInitialState,
    reviewFiltersReducer,
} from '../filters';
import { reviewsStatsReducer, initialState as statsInitialState } from '../stats';

const initialState: ReviewsState = {
    byId: {},
    ids: [],
    hasMore: false,
    stats: statsInitialState,
    count: null,
    countByType: {} as ReviewCountByType,
    filters: filtersInitialState,
};

/**
 * Generates a unique id to be used in the store.
 * (because we are storing reviews AND feedbacks, which may share the same id in db,
 * we need to generate a unique id to handle those object in the redux store)
 * @param review
 */
export const getStateId = (review: ReviewObject) => {
    return `${review.reviewObjectType}_${review.id}`;
};

// REDUCER
export const reviewsReducer = (
    state: ReviewsState = initialState,
    action: actions.ReviewsActionType,
): ReviewsState => {
    switch (action.type) {
        case actions.SEARCH_REVIEWS_RESET:
            return { ...initialState, stats: state.stats, filters: state.filters };

        case actions.SEARCH_REVIEWS_FAILURE:
            return { ...state, hasMore: false };

        case actions.SEARCH_REVIEWS_SUCCESS:
            return {
                ...state,
                byId: action.byId,
                ids: Array.from(new Set(action.ids)),
                hasMore: action.hasMore,
                count: action.count,
                countByType: action.countByType,
            };

        case actions.UPDATE_REVIEW_SUCCESS: {
            const id = getStateId(action.review);

            return {
                ...state,
                byId: { ...state.byId, [id]: action.review },
            };
        }

        case actions.DELETE_REVIEW: {
            const id = getStateId(action.review);

            return {
                ...state,
                byId: { ...omit(state.byId, [id]) },
                ids: state.ids.filter(reviewId => reviewId !== id),
            };
        }

        case actions.UPDATE_REVIEW_MARK_AS_TREATED: {
            const id = getStateId(action.review);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [id]: {
                        ...state.byId[id],
                        state: TREATED_REVIEWS,
                    },
                },
                stats: {
                    ...state.stats,
                    treated: state.stats.treated + 1,
                    notTreated: state.stats.notTreated - 1,
                },
            };
        }

        case actions.UPDATE_REVIEW_MARK_AS_NOT_TREATED: {
            const id = getStateId(action.review);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [id]: {
                        ...state.byId[id],
                        state: NOT_TREATED_REVIEWS,
                    },
                },
                stats: {
                    ...state.stats,
                    treated: state.stats.treated - 1,
                    notTreated: state.stats.notTreated + 1,
                },
            };
        }

        case actions.UPDATE_REVIEW_MARK_AS_DELETED: {
            const id = getStateId(action.review);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [id]: {
                        ...state.byId[id],
                        state: DELETED_REVIEWS,
                    },
                },
                stats: { ...state.stats, deleted: state.stats.deleted + 1 },
            };
        }

        case actions.UPDATE_COUNT:
            return {
                ...state,
                count: action.count,
            };

        case actions.UPDATE_TAG_ON_REVIEWS:
            Object.entries(state.byId).forEach(([key, review]) => {
                state.byId[key] = {
                    ...review,
                    tags: review.tags.map(t => {
                        if (t.id === action.tag.id) {
                            return action.tag;
                        }

                        return t;
                    }),
                };
            });

            return {
                ...state,
                byId: { ...state.byId },
            };

        case actions.DELETE_TAG_ON_REVIEWS:
            Object.entries(state.byId).forEach(([key, review]) => {
                state.byId[key] = {
                    ...review,
                    tags: review.tags.filter(t => {
                        return t.id !== action.tag.id;
                    }),
                };
            });

            return {
                ...state,
                byId: { ...state.byId },
            };

        default:
            return {
                ...state,
                stats: reviewsStatsReducer(state.stats, action as any),
                filters: reviewFiltersReducer(state.filters, action as any),
            };
    }
};
export default reviewsReducer;
