import { FC } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { V2BusinessData } from 'app/api/types/business';

import IS_IFRAME from 'app/common/data/iframe';

import AlertBanner from 'app/common/designSystem/components/molecules/AlertBanner';
import { useProductUpsale } from 'app/common/hooks/queries/useProductUpsale';
import { SETTINGS_PLATFORMS_CONNECTION_PATHNAME } from 'app/routing/routeIds';

type Props = {
    business: V2BusinessData | undefined;
    businessHasReviewBooster: boolean;
    businessHasReviewUrl: boolean;
};

const DesktopAlertBanner: FC<Props> = ({
    business,
    businessHasReviewBooster,
    businessHasReviewUrl,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { refetch: handleUpsaleRequest } = useProductUpsale('review_booster');

    if (!business) return null;

    const searchString = `${business?.name || ''} ${business?.address_full || ''} ${
        business?.zipcode || ''
    } ${business?.city || ''}`;
    const redirectPath = `${SETTINGS_PLATFORMS_CONNECTION_PATHNAME}?search=${encodeURIComponent(
        searchString,
    )}`;
    return (
        [
            !businessHasReviewBooster && (
                <AlertBanner
                    title={t('rb_business_not_subscribed_to_rb_banner_title')}
                    color={HEX_COLORS.primary}
                    onClick={handleUpsaleRequest}
                    buttonText={t('rb_business_not_subscribed_upsale_button_text')}
                >
                    {!IS_IFRAME && (
                        <div>{t('rb_business_not_subscribed_to_rb_banner_description')}</div>
                    )}
                </AlertBanner>
            ),

            !businessHasReviewUrl && (
                <AlertBanner
                    title={t('rb_connect_location_banner_title')}
                    color={HEX_COLORS.warning}
                    {...(!IS_IFRAME && {
                        onClick: () => navigate(redirectPath),
                        buttonText: t('rb_connect_location_banner_button'),
                    })}
                >
                    {!IS_IFRAME && <div>{t('rb_connect_location_banner_description')}</div>}
                </AlertBanner>
            ),
        ].filter(Boolean)[0] || null
    );
};

export default DesktopAlertBanner;
