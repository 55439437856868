import { Chip, FontAwesomeIconsPartooUsed, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { CONVERSATION_STARTERS } from 'app/common/data/queryKeysConstants';
import { useBusinesses } from 'app/common/hooks/queries/useBusinesses';
import {
    AutomaticMessagesConfigurationContainer,
    AutomaticMessagesConfigurationSectionContainer,
    AutomaticMessagesHeaderContainer,
    AutomaticMessagesPreviewContainer,
    BackIconButton,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/AutomaticMessagesConfiguration.styled';
import { AutomaticMessagesConfigurationContent } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/AutomaticMessagesConfigurationContent';
import { AutomaticMessagesName } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/AutomaticMessagesName';
import PreviewPanelContainer from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/PreviewPanel';
import { useConversationStarter } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarter';
import { useConversationStarterDelete } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarterDelete';
import { ConversationStarter } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';
import queryClient from 'app/states/queryClient';

const isConvStarterModified = (data?: ConversationStarter) => {
    if (!data) {
        return false;
    }
    // Only a newly created CS will have both dates same, and a new CS is not considered modified.
    return data.updated_at !== data.created_at;
};

export const AutomaticMessagesConfiguration = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: conversationStarter } = useConversationStarter();

    const businessesFilters = useBusinessModalFilters(true);
    const deleteConvStarterMutation = useConversationStarterDelete(conversationStarter?.id);

    const businesses = useBusinesses(businessesFilters);

    const firstBusiness = businesses.businesses?.[0];

    return (
        <AutomaticMessagesConfigurationContainer>
            <BackIconButton
                appearance="contained"
                onClick={() => {
                    if (!isConvStarterModified(conversationStarter)) {
                        deleteConvStarterMutation.mutate(undefined, {
                            onSuccess: () => {
                                queryClient.removeQueries([CONVERSATION_STARTERS]);
                                navigate({ search: '?conversationStarterId=' });
                            },
                        });
                    } else {
                        navigate({ search: '?conversationStarterId=' });
                    }
                }}
                icon={[FontAwesomeIconsPartooUsed.faArrowLeft]}
                dataTrackId="close_automatic_message_configuration_button"
            />

            <AutomaticMessagesConfigurationSectionContainer>
                <AutomaticMessagesHeaderContainer
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <AutomaticMessagesName />
                    {conversationStarter?.automatic_message_enabled ? (
                        <Chip dataTrackId="" chipShape="cube" variant="emerald">
                            <Text color="emerald" variant="bodySBold">
                                {t('conv_starter_active_state')}
                            </Text>
                        </Chip>
                    ) : (
                        <Chip dataTrackId="" chipShape="cube" variant="warning">
                            <Text color="warning" variant="bodySBold">
                                {t('conv_starter_paused_state')}
                            </Text>
                        </Chip>
                    )}
                </AutomaticMessagesHeaderContainer>
                <AutomaticMessagesConfigurationContent />
            </AutomaticMessagesConfigurationSectionContainer>

            <AutomaticMessagesPreviewContainer>
                <Stack direction="column" gap="16px">
                    <Text variant="heading6" color="default">
                        {t('conversation_starters_preview_header')}
                    </Text>
                    <PreviewPanelContainer businessId={firstBusiness?.id} />
                </Stack>
            </AutomaticMessagesPreviewContainer>
        </AutomaticMessagesConfigurationContainer>
    );
};
