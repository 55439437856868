import styled from '@emotion/styled';

export const StyledErrorBanner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    margin: 16px 0;
    background-color: ${({ theme }) => theme.colors.danger.alpha};
    gap: 8px;
    border-radius: 8px;
`;

export const StyledErrorList = styled.ul`
    padding-left: 20px;
    list-style-position: outside;

    li::marker {
        font-size: 10px;
        display: none;
    }
`;
