import { useState } from 'react';

import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { ButtonsGroup, FontAwesomeIconsPartooUsed, Icon, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useBusinessStatus } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import { FormValues } from 'app/businessEditV2/sections/AdvancedSettingsSection/AdvancedSettingsForm/AdvancedSettingsForm';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { CLOSED, CLOSED_TEMPORARILY, OPEN } from 'app/common/data/businessStatuses';

import { ButtonsGroupContainer, StatusContainer, StatusTitle } from './Status.styled';

type Props = {
    onSubmit: (data: FormValues) => void;
};

const StatusComponent = ({ onSubmit }: Props) => {
    const { t } = useTranslation();
    const { data: business } = useBusiness();
    const [displayCloseBusinessModal, setDisplayCloseBusinessModal] = useState<boolean>(false);

    const statusPermission = useFieldMetadata('advanced_settings', 'status')?.enabled;
    const disabled = !statusPermission;

    const businessStatus = useBusinessStatus();
    const [status, setStatus] = useState<string>(businessStatus);

    const onConfirmOpenOrCloseBusiness = () => {
        if (statusPermission) {
            setDisplayCloseBusinessModal(false);
            onSubmit({ status: status });
        }
    };

    const onHideChangeBusinessStatus = () => {
        setDisplayCloseBusinessModal(false);
        setStatus(businessStatus);
    };

    const onChange = (data: string) => {
        if (data != businessStatus) {
            setStatus(data);
            setDisplayCloseBusinessModal(true);
        }
    };

    const getIllustration = () => {
        if (status === OPEN) {
            return IllustrationOldEnum.Open;
        }
        if (status === CLOSED) {
            return IllustrationOldEnum.Close;
        }
        // Else it's CLOSED_TEMPORARILY
        return IllustrationOldEnum.Hours;
    };

    const IllustrationComponent = (
        <Illustration
            dataTrackId=""
            illustration={{
                type: IllustrationCategoryEnum.Old,
                name: getIllustration(),
            }}
        />
    );

    const getIcon = (choice: string): FontAwesomeIconsPartooUsed => {
        if (choice === OPEN) {
            return FontAwesomeIconsPartooUsed.faDoorOpen;
        }
        if (choice === CLOSED) {
            return FontAwesomeIconsPartooUsed.faDoorClosed;
        }
        // Else it's CLOSED_TEMPORARILY
        return FontAwesomeIconsPartooUsed.faClock;
    };

    const translation_mapping: Record<string, string> = {
        [OPEN]: OPEN,
        [CLOSED]: 'business_closed',
        [CLOSED_TEMPORARILY]: CLOSED_TEMPORARILY,
    };
    const statuses = [OPEN, CLOSED, CLOSED_TEMPORARILY];

    return (
        <>
            <StatusContainer>
                <StatusTitle variant="heading6">
                    {t('business_edit_content_advanced_settings_business_status')}
                </StatusTitle>
                <ButtonsGroupContainer>
                    <ButtonsGroup
                        selected={status}
                        onChange={onChange}
                        data-trackid="visibility_location__form_advanced_settings__status_button"
                    >
                        {statuses.map(element => (
                            <ButtonsGroup.ButtonGroup
                                key={element}
                                value={element}
                                tooltip={statusPermission ? '' : 'field_read_only'}
                                disabled={disabled}
                            >
                                {
                                    <Icon
                                        icon={[getIcon(element), IconPrefix.SOLID]}
                                        color={status === element ? 'blackberry' : 'secondary'}
                                    />
                                }
                                {t(translation_mapping[element])}
                            </ButtonsGroup.ButtonGroup>
                        ))}
                    </ButtonsGroup>
                </ButtonsGroupContainer>
                {/* Confirm open/close/close-temporarily business modal */}
            </StatusContainer>
            <ConfirmModal
                title={t(`${status}_business_action`)}
                content={t(`${status}_reply_modal_text`, { business_name: business?.name })}
                show={displayCloseBusinessModal}
                modalFor={status === OPEN ? 'info' : 'delete'}
                trackId={`visibility_location__form_advanced_settings__${status}_business_modal`}
                onConfirm={onConfirmOpenOrCloseBusiness}
                onHide={onHideChangeBusinessStatus}
                confirmLabel={t('confirm')}
                image={IllustrationComponent}
            />
        </>
    );
};

export default StatusComponent;
