import { Icon, IconPrefix, Slider, Text } from '@partoohub/ui';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    DailyReportSliderContainer,
    DailyReportSliderContent,
    DailyReportSliderFacebook,
    DailyReportSliderHeader,
    DailyReportSliderItem,
    SliderPosition,
    sliderElementStyle,
} from './DailyReportSlider.styled';

interface Props {
    title: string;
    name: string;
    control: Control;
}

export const DailyReportSlider = ({ title, name, control }: Props) => {
    const { t } = useTranslation();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <DailyReportSliderContainer>
                    <DailyReportSliderHeader>
                        <Text variant="bodyMBold" color="secondary">
                            {t(title)}
                        </Text>
                    </DailyReportSliderHeader>
                    <Slider
                        min={1}
                        max={3}
                        step={1}
                        value={value}
                        onSliderChange={onChange}
                        cssContainer={sliderElementStyle}
                    />
                    <DailyReportSliderContent>
                        <DailyReportSliderItem sliderPosition={SliderPosition.FIRST}>
                            <Text variant="bodyMBold" as="span" color="primary">
                                {t('negative')}
                            </Text>
                            <Text variant="bodySBold" as="span" color="secondary">
                                {t('one_to_two_stars')}
                            </Text>
                            <DailyReportSliderFacebook sliderPosition={SliderPosition.FIRST}>
                                <Icon
                                    icon={['fa-facebook-square', IconPrefix.BRANDS]}
                                    color="secondary"
                                />
                                <Text variant="bodySBold" as="span" color="secondary">
                                    {t('not_recommended_filter')}
                                </Text>
                            </DailyReportSliderFacebook>
                        </DailyReportSliderItem>
                        <DailyReportSliderItem
                            sliderPosition={SliderPosition.CENTRAL}
                            disabled={value < 2}
                        >
                            <Text variant="bodyMBold" as="span" color="primary">
                                {t('neutral')}
                            </Text>
                            <Text variant="bodySBold" as="span" color="secondary">
                                {t('three_stars')}
                            </Text>
                        </DailyReportSliderItem>
                        <DailyReportSliderItem
                            sliderPosition={SliderPosition.LAST}
                            disabled={value < 3}
                        >
                            <Text variant="bodyMBold" as="span" color="primary">
                                {t('positive')}
                            </Text>
                            <Text variant="bodySBold" as="span" color="secondary">
                                {t('four_to_five_stars')}
                            </Text>
                            <DailyReportSliderFacebook sliderPosition={SliderPosition.LAST}>
                                <Icon
                                    icon={['fa-facebook-square', IconPrefix.BRANDS]}
                                    color="secondary"
                                />
                                <Text variant="bodySBold" as="span" color="secondary">
                                    {t('recommended_filter')}
                                </Text>
                            </DailyReportSliderFacebook>
                        </DailyReportSliderItem>
                    </DailyReportSliderContent>
                </DailyReportSliderContainer>
            )}
        />
    );
};
