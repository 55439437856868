import { ConversationStarterPlaceholders } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';

export const formatMentionToPlaceholder = (text: string): string =>
    text.replace(/@\[.*?\]\((\w+)\)/g, '{{$1}}');

const translateDisplay = (_, p1: string, t: (value: string) => string): string => {
    if (!ConversationStarterPlaceholders.find(placeholder => placeholder.id === p1)) {
        return p1;
    }

    return `@[${t(
        ConversationStarterPlaceholders.find(placeholder => placeholder.id === p1).display,
    )}](${p1})`;
};

export const formatPlaceholderToMention = (value: string, t: (value: string) => string): string => {
    return value.replace(/{{(\w+)}}/g, (arg, p1) => translateDisplay(arg, p1, t));
};
