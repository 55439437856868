import styled from '@emotion/styled';

export const ModalContainer = styled.div`
    padding: 24px;
    min-width: 500px;
`;

export const ModalTitle = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
    font-size: 22px;
    font-weight: bold;
`;

export const ModalSubtitle = styled.div`
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin-bottom: 24px;
`;

export const ModalButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
`;
