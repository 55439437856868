import styled from '@emotion/styled';

import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

type DropZoneContainerProps = {
    hideBorder: boolean;
    hasError?: boolean;
};

export const DropZoneContainer = styled.div<DropZoneContainerProps>`
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    height: 0; // Hack to make it square with the padding-bottom
    width: 56px;
    padding-bottom: 56px;

    > div {
        // Overwrite ReactDropZone style
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        width: 56px !important;
        height: 56px !important;
        border-radius: 8px !important;
        background-color: ${greyColorObject.light} !important;
        position: initial !important;
        border: 1px dashed
            ${({ hasError, theme }) =>
                hasError ? theme.colors.danger.initial : theme.colors.secondary.light} !important;
        transition: border-color ease 0.3s !important;

        &:hover {
            border-color: ${({ hasError, theme }) =>
                hasError ? theme.colors.danger.initial : theme.colors.secondary.initial} !important;
        }
    }
`;

type PhotoPreviewProps = {
    opacity: number;
    url: string;
};

export const PhotoPreview = styled.div<PhotoPreviewProps>`
    opacity: ${props => props.opacity};
    position: absolute;
    width: 56px;
    height: 56px;
    top: 0;
    left: 0;
    border-radius: 8px;
    background-image: url('${props => props.url}');
    background-position: center center;
    background-size: cover;
    background-color: ${greyColorObject.light};
    background-repeat: no-repeat;
    border: none;
    overflow: hidden;
`;

export const PhotoPreviewOverlay = styled.div`
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 22, 31, 0.5);
    transition: all ease 0.2s;

    > i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 28px;
        background-color: ${HEX_COLORS.white};
        color: ${({ theme }) => theme.colors.secondary.initial};
        font-size: 13px;
    }

    &:hover {
        opacity: 1;
        cursor: pointer;
    }
`;
