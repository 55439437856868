import styled from '@emotion/styled';

export const ApiKeyManagerHeaderSectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
    margin: 16px 0;
`;

export const ApiKeyManagerDocLink = styled.div`
    margin-left: auto;
    display: flex;
    gap: 16px;
`;

export const ApiKeyManagerDocLinkV2 = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
`;
