import { ReactNode } from 'react';

import { IconElement, NotificationColorsVariants, Text } from '@partoohub/ui';
import { isEmpty } from 'lodash-es';

import { IconTitle } from 'app/common/designSystem/components/atoms/IconTitle/IconTitle';

import {
    PBoxActions,
    PBoxTitleContainer,
    PBoxTitleWithActions,
    PBoxTitleWithoutActions,
} from './PBox.styled';

interface Props {
    title: string;
    subtitle?: string;
    icon: IconElement;
    variant?: NotificationColorsVariants;
    actions: Array<ReactNode>;
}

export const PBoxTitle = ({
    title,
    subtitle,
    icon,
    actions,
    variant = 'primary',
}: Props) => {
    const Title = (
        <>
            <PBoxTitleContainer>
                <IconTitle icon={icon} variant={variant}>
                    {title}
                </IconTitle>
                {!!subtitle && (
                    <Text variant="bodyMSemibold" as="span" color="secondary">
                        {subtitle}
                    </Text>
                )}
            </PBoxTitleContainer>

            {!isEmpty(actions) && (
                <PBoxActions>{actions.map(action => action)}</PBoxActions>
            )}
        </>
    );
    return (
        <>
            {!isEmpty(actions) ? (
                <PBoxTitleWithActions>{Title}</PBoxTitleWithActions>
            ) : (
                <PBoxTitleWithoutActions>{Title}</PBoxTitleWithoutActions>
            )}
        </>
    );
};
