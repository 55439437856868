import { times } from 'lodash-es';
// TODO: Replace by InfiniteScroll from 'react-infinite-scroll-component'
import InfiniteScroll from 'react-infinite-scroller';

import { V2FormattedBusinessData } from 'app/api/types/business';
import SmallBusinessLoadingCard from 'app/common/components/businessCards/SmallBusinessCard/SmallBusinessLoadingCard';
import { isMobile } from 'app/common/services/screenDimensions';

import SmallBusinessCard from './businessCard.container';
import { GridInfiniteScroll, ThreeRowScrollerTable } from './BusinessTable.styled';

type Props = {
    businesses: Array<V2FormattedBusinessData>;
    fetchNextBusinesses: () => void;
    allBusinessesHaveBeenFetched: boolean;
    businessLoadingCards: number;
};

const infiniteScroll = (
    businesses,
    fetchNextBusinesses,
    allBusinessesHaveBeenFetched,
    businessLoadingCards,
) => (
    <InfiniteScroll
        loadMore={fetchNextBusinesses}
        hasMore={!allBusinessesHaveBeenFetched}
        useWindow={false}
        threshold={500}
    >
        <GridInfiniteScroll isMobile={isMobile()}>
            {businesses.map((business, index) => (
                // @ts-ignore
                <SmallBusinessCard business={business} key={`filter_card_${index}`} />
            ))}
            {!allBusinessesHaveBeenFetched &&
                times(businessLoadingCards, index => (
                    <SmallBusinessLoadingCard key={`filter_loading_card_${index}`} />
                ))}
        </GridInfiniteScroll>
    </InfiniteScroll>
);

const BusinessTableComponent = ({
    businesses,
    fetchNextBusinesses,
    allBusinessesHaveBeenFetched,
    businessLoadingCards,
}: Props) =>
    isMobile() ? (
        infiniteScroll(
            businesses,
            fetchNextBusinesses,
            allBusinessesHaveBeenFetched,
            businessLoadingCards,
        )
    ) : (
        <ThreeRowScrollerTable>
            {infiniteScroll(
                businesses,
                fetchNextBusinesses,
                allBusinessesHaveBeenFetched,
                businessLoadingCards,
            )}
        </ThreeRowScrollerTable>
    );

export default BusinessTableComponent;
