import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix } from '@partoohub/ui';

import AccessGroupModalButton from 'app/common/components/AccessGroupModal';

import { AccessLine } from './AccessesSelect.styled';

export type Props = {
    value: Array<Array<number>>;
    onChange: (value: Array<Array<number>>) => void;
    mandatory?: boolean;
    orgId?: number;
};

const AccessesSelect = ({ value, onChange, mandatory, orgId }: Props) => {
    const accessesToDisplay = value?.length ? value : [[]];

    const onAccessChange = (newValue: Array<number>, index: number) => {
        const result = [...accessesToDisplay];
        result[index] = newValue;
        onChange(result);
    };

    const addAccess = () => {
        const result = [...accessesToDisplay];
        result.push([]);
        onChange(result);
    };

    const removeAccess = (index: number) => {
        const result = [...accessesToDisplay];
        result.splice(index, 1);
        onChange(result);
    };

    return (
        <>
            {accessesToDisplay.map((access, index) => (
                <AccessLine key={`access_${index}`}>
                    <AccessGroupModalButton
                        value={access}
                        onChange={newValue => onAccessChange(newValue, index)}
                        mandatory={mandatory}
                        orgId={orgId}
                    />
                    {index ? (
                        <IconButton
                            dataTrackId="add_access__icon_button"
                            icon={['fa-trash-alt', IconPrefix.SOLID]}
                            appearance="outlined"
                            variant="danger"
                            onClick={() => removeAccess(index)}
                        />
                    ) : (
                        <IconButton
                            dataTrackId="add_access__icon_button"
                            icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                            appearance="outlined"
                            variant="primary"
                            onClick={addAccess}
                        />
                    )}
                </AccessLine>
            ))}
        </>
    );
};

export default AccessesSelect;
