import { useEffect } from 'react';

import { NumberInput, Stack, Switch } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { customFieldsType as types } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/services';

import { GridColumn, SwitchContainer } from './FormType.styled';

type Props = {
    params: Record<string, any>;
    updateParams: (typeParams: Record<string, any>) => void;
    editMode: boolean;
};

export const FormTypeNumber = ({ params, updateParams, editMode }: Props) => {
    const { t } = useTranslation();

    const onChange = (value: string, constraint: string) => {
        updateParams({ ...params, [constraint]: value });
    };

    useEffect(() => {
        if (!params.type || params.type === types.NUMBER) {
            onChange(types.INTEGER, 'type');
        }
    }, []);

    const onSwitchClick = (type: string) => {
        const newType = type === types.INTEGER ? types.FLOAT : types.INTEGER;
        onChange(newType, 'type');
    };

    return (
        <Stack>
            <GridColumn>
                <NumberInput
                    dataTrackId="minimum_value"
                    label={t('minimum_value')}
                    value={params.min ?? ' '}
                    onChange={value => onChange(value ?? '', 'min')}
                />
                <NumberInput
                    dataTrackId="maximum_value"
                    label={t('maximum_value')}
                    value={params.max ?? ' '}
                    onChange={value => onChange(value ?? '', 'max')}
                />
            </GridColumn>
            <SwitchContainer>
                <TooltipWrapper
                    text={editMode ? t('custom_fields_edit_switch_disabled_tooltip') : ''}
                >
                    <Switch
                        dataTrackId="custom_fields_number__switch"
                        name="custom_fields_number"
                        checked={params.type === types.FLOAT}
                        onChange={() => {
                            if (!editMode) onSwitchClick(params.type);
                        }}
                        disabled={editMode}
                        label={t('with_decimal')}
                    />
                </TooltipWrapper>
            </SwitchContainer>
        </Stack>
    );
};
