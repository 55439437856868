import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import Flexbox from 'app/styles/utils/flexbox';

export const MessagingContentContainer = styled(Flexbox)`
    height: 100%;
    border-top: 1px solid ${greyColorObject.initial};
`;

export const LeftPanelContent = styled.div`
    flex: 1;
    min-width: 250px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: 1px solid ${greyColorObject.initial};
`;
