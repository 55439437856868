import { useState } from 'react';

import { Modal, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import Clickable from 'app/common/components/buttons/Clickable';
import FullScreenImage from 'app/common/designSystem/components/atoms/FullScreenImage';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import MultipleSelectImageRightChild from 'app/common/designSystem/components/molecules/MultipleSelectImage/MultipleSelectImageRightChild';

import { STATIC_BASE_URL } from 'app/config';

import { MultipleSelectCardHeader, StyledCheckbox } from './MultipleSelectImage.styled';

interface Props {
    name: string;
    possibleImagesLabels: Array<string>;
    possibleImagesUrls: Array<string>;
    possibleValues: Array<string>;
    selectedOptions: Array<string>;
    onChange: (arg0: Array<string>) => void;
    disabled?: boolean;
}

const MultipleSelectImage = ({
    disabled = false,
    possibleValues,
    onChange,
    selectedOptions,
    name,
    possibleImagesLabels,
    possibleImagesUrls,
}: Props) => {
    const { t } = useTranslation();
    const [showFullScreenImage, setShowFullScreenImage] = useState<boolean>(false);
    const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(null);
    const [brokenImages, setBrokenImages] = useState<Array<boolean>>([]);

    const onHideFullScreenImage = () => {
        setShowFullScreenImage(false);
    };

    const onShowFullScreenImage = (index: number) => {
        setShowFullScreenImage(true);
        setCurrentImageIndex(index);
    };

    const onNewImage = (right: boolean) => {
        const next = right ? 1 : -1;
        setCurrentImageIndex(
            ((currentImageIndex || 0) + next + possibleValues.length) % possibleValues.length,
        );
    };

    const onItemClick = (image: string) => {
        const filtered = selectedOptions.filter(a => a !== image);

        if (filtered.length === selectedOptions.length) {
            onChange([...selectedOptions, image]);
        } else {
            onChange(filtered);
        }
    };

    const indeterminateCheckboxState = (): boolean | 'indeterminate' => {
        let indeterminateCheckboxState: boolean | 'indeterminate' = 'indeterminate';

        if (selectedOptions.length === 0) {
            indeterminateCheckboxState = false;
        } else if (selectedOptions.length === possibleValues.length) {
            indeterminateCheckboxState = true;
        }

        return indeterminateCheckboxState;
    };

    const onCheckboxClick = () => {
        if (selectedOptions.length === 0) {
            onChange(possibleValues);
        } else {
            onChange([]);
        }
    };

    const showBrokenImage = (index: number) => {
        brokenImages[index] = true;
        setBrokenImages([...brokenImages]);
    };

    return (
        <div className="multiple_select_image_container">
            <Stack gap="8px" direction="row" alignItems="center" justifyContent="space-between">
                <Text variant="bodySBold" color="secondary" as="span" disabled={disabled}>
                    {name}
                </Text>
                <Stack gap="4px" direction="row" alignItems="center" justifyContent="center">
                    <Text variant="bodySBold" color="secondary" as="span" disabled={disabled}>
                        {`${selectedOptions.length} / ${possibleValues.length}
                            ${selectedOptions.length > 1 ? t('selected') : t('selected_singular')}`}
                    </Text>
                    <TooltipWrapper
                        text={disabled ? t('field_read_only') : ''}
                        position="bottom-end"
                    >
                        <StyledCheckbox
                            dataTrackId="multiple_select_image_image_top__checkbox"
                            aria-label=""
                            checked={indeterminateCheckboxState()}
                            disabled={disabled}
                            onChange={() => onCheckboxClick()}
                        />
                    </TooltipWrapper>
                </Stack>
            </Stack>
            <div className="multiple_select_image_list">
                {possibleImagesUrls.map((image, index) => (
                    <div
                        className={`
                                multiple_select_card
                                ${disabled ? 'multiple_select_card--disabled' : ''}
                            `}
                        key={`${possibleImagesLabels[index]}__${index}`}
                    >
                        <MultipleSelectCardHeader>
                            <TooltipWrapper
                                text={disabled ? t('field_read_only') : ''}
                                position="bottom"
                            >
                                <StyledCheckbox
                                    dataTrackId="multiple_select_card_header__checkbox"
                                    checked={selectedOptions.includes(possibleValues[index])}
                                    disabled={disabled}
                                    onChange={() => onItemClick(possibleValues[index])}
                                />
                            </TooltipWrapper>
                        </MultipleSelectCardHeader>
                        <Clickable
                            className="multiple_select_image_clickable"
                            onClick={() => onShowFullScreenImage(index)}
                        >
                            {brokenImages[index] ? (
                                <img
                                    className="multiple_select_image_broken"
                                    src={`${STATIC_BASE_URL}/images/app/icons/broken_file_dark.svg`}
                                    alt="broken_image"
                                />
                            ) : (
                                <img
                                    className="multiple_select_image"
                                    src={image}
                                    onError={() => showBrokenImage(index)}
                                    alt=""
                                />
                            )}
                            <div className="multiple_select_image_preview">
                                <div className="font-button multiple_select_image_preview_text">
                                    {t('preview')}
                                </div>
                            </div>
                        </Clickable>
                        <div className="multiple_select_card_footer">
                            <div className="multiple_select_card_image_label">
                                <Clickable onClick={() => onShowFullScreenImage(index)}>
                                    {possibleImagesLabels[index] || ''}
                                </Clickable>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Modal
                isOpen={showFullScreenImage}
                closeModal={onHideFullScreenImage}
                placement="center"
                animation="fadeIn"
            >
                <FullScreenImage
                    imageIndex={currentImageIndex}
                    possibleImagesUrls={possibleImagesUrls}
                    brokenImages={brokenImages}
                    onHideClick={onHideFullScreenImage}
                    onNewImage={onNewImage}
                    show={showFullScreenImage}
                    rightChild={
                        <MultipleSelectImageRightChild
                            imageIndex={currentImageIndex}
                            possibleImagesLabels={possibleImagesLabels}
                            possibleValues={possibleValues}
                            selectedOptions={selectedOptions}
                            disabled={disabled || false}
                            onItemClick={onItemClick}
                        />
                    }
                />
            </Modal>
        </div>
    );
};

export default MultipleSelectImage;
