import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

type PostCardStickerType = {
    isExpired: boolean;
};

export const PostCardSticker = styled.div`
    padding: 4px 8px;
    border-radius: 4px;
    width: fit-content;
    background-color: ${HEX_COLORS.white};
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 13px;
    font-weight: bold;
    display: flex;
    position: absolute;
    top: 0;
    margin: 12px 0 0 12px;
    ${(props: PostCardStickerType) =>
        props.isExpired &&
        css`
            color: ${DISABLED_COLOR};
        `}
`;

export const PostCardTypeIcon = styled.i`
    margin: auto 4px auto 0;
    font-size: 10px;
`;

export const PostCardText = styled.span`
    margin: auto;
`;
