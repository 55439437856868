import { ReactElement } from 'react';

import { LogoButtonContainer, StyledText } from './LogoButton.styled';

type Props = {
    active: boolean;
    handleClick: () => void;
    text: string;
    logo: string | ReactElement;
    disabled: boolean;
};

const LogoButton = ({ active, handleClick, text, logo, disabled }: Props) => (
    <LogoButtonContainer
        onClick={handleClick}
        onKeyPress={handleClick}
        role="button"
        tabIndex={-1}
        disabled={disabled}
        active={active}
        className="clickable"
    >
        {logo && (typeof logo === 'string' ? <i className={logo} /> : logo)}
        <StyledText variant="bodySBold" as="span">
            {text}
        </StyledText>
    </LogoButtonContainer>
);

export default LogoButton;
