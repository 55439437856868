import React from 'react';

import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import EditSection from 'app/businessEditV2/components/EditSection';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import UnsavedModal from 'app/businessEditV2/components/UnsavedModal';
import useIsLoadingSpecificHours from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingSpecificHours';
import useSpecificHoursSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useSpecificHoursSectionColor';
import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import { SectionNames } from 'app/businessEditV2/utils/utils';

import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import SpecificHoursForm from './SpecificHoursForm';
import SpecificHoursSectionContent from './SpecificHoursSectionContent';

const SpecificHoursSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const {
        showModal,
        setShowModal,
        closeSafeModal,
        showUnsavedModal,
        setShowUnsavedModal,
        useDirtyUpdate,
    } = useUnsavedModal(SectionNames.SPECIFIC_HOURS);

    const sectionColor = useSpecificHoursSectionColor();

    const { t } = useTranslation();
    const isLoading = useIsLoadingSpecificHours();

    if (isLoading) {
        return <EditSectionLoader />;
    }

    return (
        <div
            ref={ref}
            data-track="visibility_location__section_specific_hours"
            data-intercom-target="visibility_location__section_specific_hours"
        >
            <EditSection
                title={t('business_specific_hours')}
                description={
                    <SpecificHoursSectionContent colorState={sectionColor} />
                }
                onClick={() => setShowModal(true)}
                colorState={sectionColor}
                sectionName={SectionNames.SPECIFIC_HOURS}
            />
            <FullScreenModal
                show={showModal}
                childrenRight={
                    <EditFormDescription
                        title={t('business_edit_title_specific_hours')}
                        description={t(
                            'business_edit_description_specific_hours',
                        )}
                        advice={t('business_edit_advice_specific_hours')}
                    />
                }
                childrenLeft={
                    <>
                        <SpecificHoursForm
                            closeModal={() => setShowModal(false)}
                            useDirtyUpdate={useDirtyUpdate}
                        />
                        <UnsavedModal
                            show={showUnsavedModal}
                            cancel={() => setShowUnsavedModal(false)}
                            validate={() => setShowModal(false)}
                        />
                    </>
                }
                onHideClick={closeSafeModal}
            />
        </div>
    );
});

export default SpecificHoursSection;
