import React from 'react';

import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import InvitationForm from 'app/connection/components/InvitationForm';

export const InvitationPage = () => {
    const { t } = useTranslation();
    return (
        <>
            <DynamicPageHeader title={t('create_an_account')} />
            <InvitationForm />
        </>
    );
};
