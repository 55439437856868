import React from 'react';

import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';
import {
    Body,
    BodyComponent,
    BodyDescription,
    BodyLabel,
    BodyMain,
    BodyOptionalElements,
    BodyOptionalLabel,
} from 'app/common/designSystem/components/molecules/SectionContainer/SectionBody.styled';

const LoadingServicesBody = () => (
    <Body>
        <BodyMain>
            <BodyLabel>
                <LoadingRectangle
                    recWidth="200px"
                    recHeight="14px"
                    recRadius="5%"
                    recMargin="4px 0"
                />
            </BodyLabel>
            <BodyDescription>
                <LoadingRectangle
                    recWidth="600px"
                    recHeight="14px"
                    recRadius="5%"
                    recMargin="4px 0"
                />
            </BodyDescription>
        </BodyMain>
        <BodyOptionalElements>
            <BodyOptionalLabel>
                <LoadingRectangle
                    recWidth="50px"
                    recHeight="14px"
                    recRadius="5%"
                    recMargin="28px 0"
                />
            </BodyOptionalLabel>
            <BodyComponent>
                <LoadingRectangle
                    recWidth="24px"
                    recHeight="24px"
                    recRadius="100%"
                    recMargin="24px 0 24px 0"
                />
            </BodyComponent>
        </BodyOptionalElements>
    </Body>
);
export default LoadingServicesBody;
