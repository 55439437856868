import React from 'react';

type Props = {
    children: React.ReactNode;
    fallback: React.ReactNode;
};

type State = {
    hasError: boolean;
};

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, _errorInfo: any) {
        console.error(error);
    }

    render() {
        const { hasError } = this.state;
        const { children, fallback } = this.props;

        if (hasError) return <>{fallback}</>;
        return children;
    }
}
