import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DefaultShortcutComponent } from 'app/pages/Homepage/sections/HomepageShortcuts/DefaultShortcutComponent';
import { SETTINGS_CHATBOT_PATH } from 'app/routing/routeIds';

export const ShortcutJim = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <DefaultShortcutComponent
            title={t('homepage_shortcut_jim')}
            variant="slate"
            iconElement={[FontAwesomeIconsPartooUsed.faMessageBot, IconPrefix.SOLID]}
            onClick={() => navigate(SETTINGS_CHATBOT_PATH)}
            dataTrack="click_homepage_shortcut_jim"
        />
    );
};
