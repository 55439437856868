import { Icon, IconButton, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ReplySuggestionButtonWrapper } from './ReplySuggestionButton.styled';

type Props = {
    onClick: () => void;
};

function ReplySuggestionButton({ onClick = () => undefined }: Props) {
    const { t } = useTranslation();
    return (
        <ReplySuggestionButtonWrapper>
            <IconButton
                dataTrackId="reply-suggestion-button"
                appearance="outlined"
                icon={<Icon icon={['fa-sparkles', IconPrefix.REGULAR]} />}
                onClick={onClick}
                tooltip={t('review_ai_suggestion_fetch_tooltip')}
            />
        </ReplySuggestionButtonWrapper>
    );
}

export default ReplySuggestionButton;
