import Linkify from 'linkify-react';

import { MessageBubble, MessageBubbleWrapper } from './ChatbotPreview.styled';

type MessageBubbleProps = {
    text: string;
    isConsumer?: boolean;
};

export const MessageBubbleContainer = ({ text, isConsumer }: MessageBubbleProps) => (
    <MessageBubbleWrapper isConsumer={isConsumer}>
        <MessageBubble isConsumer={isConsumer}>
            <Linkify
                tagName="span"
                options={{
                    target: {
                        url: '_blank',
                    },
                }}
            >
                {text}
            </Linkify>
        </MessageBubble>
    </MessageBubbleWrapper>
);
