import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    UserListQueryKeys,
    UserStatus,
} from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

export default function useUserManagementPageTitle(): string {
    const { t } = useTranslation();
    const [queryStatus] = useStateQueryParams(UserListQueryKeys.STATUS);

    if (queryStatus === UserStatus.ACTIVE) {
        return t('activated_title');
    } else if (queryStatus === UserStatus.INVITED) {
        return t('invited_title');
    } else {
        return t('all_users_title');
    }
}
