import { Icon, IconPrefix, Stack } from '@partoohub/ui';

import Clickable from 'app/common/components/buttons/Clickable';

import { ButtonSelectContainer, IconContainer } from './ButtonSelect.styled';

type Props = {
    label: string;
    placeholder?: string;
    onClick?: (arg0: Event) => void;
    isMandatory?: boolean;
};

const ButtonSelect = ({ label, placeholder, onClick = () => undefined, isMandatory }: Props) => (
    <ButtonSelectContainer>
        <div className="multiple_select">
            <Clickable
                className="multiple_select__field multiple_select__field--unfocused"
                // @ts-ignore
                onClick={onClick}
            >
                <Stack style={{ flex: 1 }} justify-content="space-between" direction="row">
                    <div className="multiple_select__placeholder multiple_select__placeholder--top">
                        <div>
                            {placeholder}
                            {isMandatory && <span className="mandatory-field"> *</span>}
                        </div>
                    </div>
                    <div>{label}</div>
                    <IconContainer>
                        <Icon icon={['fa-caret-down', IconPrefix.SOLID]} color="secondary" />
                    </IconContainer>
                </Stack>
            </Clickable>
        </div>
    </ButtonSelectContainer>
);

export default ButtonSelect;
