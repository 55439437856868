import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import {
    PillBody as BasePillBody,
    PillCross as BasePillCross,
} from 'app/common/designSystem/components/molecules/AsyncSubMenuMultipleSelect/components/SearchBox.styled';
import {
    ButtonWithMenuContainer as BaseButtonMenuContainer,
    ButtonWithMenuWrapper as BaseButtonWithMenuWrapper,
} from 'app/common/designSystem/components/molecules/ButtonWithSearchMenuFilterSelection/ButtonWithSearchMenuFilterSelection.styled';

export const ButtonWithMenuWrapper = styled(BaseButtonWithMenuWrapper)`
    align-self: flex-start;
`;

export const ButtonWithMenuContainer = styled(BaseButtonMenuContainer)`
    min-height: auto;
`;

export const PillCross = styled(BasePillCross)`
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin-left: 5px;
    display: block;
    cursor: pointer;
`;

export const PillBody = styled(BasePillBody)`
    background-color: white;
    font-weight: 600;
    border: 1px solid ${greyColorObject.initial};
    color: ${({ theme }) => theme.colors.secondary.initial};
    cursor: default;
    margin: unset;
`;

export const CreateTagPillBody = styled(PillBody)`
    display: grid;
    cursor: pointer;
    margin-left: 4px;
`;

export const ColorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    i {
        color: white;
        font-size: 7px;
    }
    ${({ color }) =>
        color &&
        css`
            background-color: ${color};
        `};
`;

export const PillColor = styled(ColorContainer)`
    width: 11px;
    height: 11px;
    margin-right: 5px;
`;

export const TagListContainer = styled.div`
    display: flex;
    justify-content: right;
    align-self: flex-start;
    align-items: flex-end;
    grid-area: tag;
    gap: 5px;

    ${BasePillBody} {
        margin: 0;
    }
`;

export const TagListSection = styled.div`
    display: flex;
    justify-content: right;
    align-items: flex-end;
    gap: 5px;
    flex-wrap: wrap;
`;
