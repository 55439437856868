import usePlaceActionLinks from './usePlaceActionLinks';

type PlaceActionLinkTranslations = {
    [gmb_id: string]: string;
};

export default function usePlaceActionLinksTranslations(): PlaceActionLinkTranslations {
    const { data: placeActionLinksData } = usePlaceActionLinks();
    const placeActionLinks = placeActionLinksData?.place_action_links ?? [];
    return placeActionLinks.reduce<PlaceActionLinkTranslations>((acc, curr) => {
        acc[curr.gmb_id] = curr.name;
        return acc;
    }, {});
}
