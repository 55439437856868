import { get } from 'lodash-es';

import {
    // @ts-ignore
    PublisherBusinessDetails,
    PublisherBusinessesState,
} from 'app/states/presence/reducers/listing/businessDetails/reducer';

const emptyObject = {};

export const getPublisherBusinessDetailsSelector: PublisherBusinessDetails = (
    state: PublisherBusinessesState,
    publisherName: string,
    status: string,
    businessId: string,
) =>
    get(
        state,
        `[${publisherName}][${status}].byId[${businessId}]`,
        emptyObject,
    );
