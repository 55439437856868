import LoadingView from 'app/common/components/LoadingView';
import NoBusiness from 'app/common/components/placeholder/NoBusiness.container';
import { NoReviewManagement } from 'app/common/components/placeholder/reviewMangement/NoReviewManagement';

import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';

import { GetParamsReduxSync } from './GetParamsReduxSync';
import { FilterSection } from './pageHeader';
import ReviewAnalyticsSection from './ReviewAnalyticsSection/ReviewAnalyticsSection';
import { PageContainer } from './ReviewAnalyticsWithNewSidebar.styled';

export const ReviewAnalyticsWithNewSidebar = () => {
    const { hasBusinesses, subscribedToRM, isLoading } = useBusinessSubscriptions();

    if (isLoading) {
        return <LoadingView />;
    }

    if (!hasBusinesses) {
        return <NoBusiness />;
    }

    if (!subscribedToRM) {
        return <NoReviewManagement />;
    }

    return (
        <PageContainer>
            <GetParamsReduxSync />
            <FilterSection />
            <ReviewAnalyticsSection />
        </PageContainer>
    );
};
