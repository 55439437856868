import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { Button } from '@partoohub/ui';

export const CommonButton = styled(Button)`
    width: 302px;
    max-width: 75vw;
    margin-bottom: 8px;
`;

export const AccountButton = styled(CommonButton)`
    background-color: ${HEX_COLORS.white};
    box-shadow: 0 2px 10px 0 #3c3c3c29;

    &:hover {
        background-color: ${greyColorObject.light};
    }
`;
