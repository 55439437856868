import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'app/config';

import { StyledRecommendations } from './RatingRecommendations.styled';

type Props = {
    recommendation: 'positive' | 'negative';
};

const RatingRecommendations = ({ recommendation }: Props) => {
    const { t } = useTranslation();

    return (
        <StyledRecommendations recommendation={recommendation}>
            <img
                alt="rating_logo"
                className="rating_logo"
                src={`${STATIC_BASE_URL}/images/app/app_review/f-${recommendation}_recommendation.svg`}
            />{' '}
            <Text
                as="span"
                variant="bodySBold"
                color={recommendation === 'positive' ? 'success' : 'danger'}
            >
                {t(`${recommendation}_recommendation`)}
            </Text>
        </StyledRecommendations>
    );
};

export default RatingRecommendations;
