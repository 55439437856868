import { useQuery } from 'react-query';

import Services from 'app/api/v2/api_calls/servicesApiCalls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import {
    BUSINESS,
    IS_SERVICES_AVAILABLE,
} from 'app/common/data/queryKeysConstants';

export default function useIsServicesAvailable() {
    const businessId = useGetBusinessId();

    return useQuery(
        [BUSINESS, IS_SERVICES_AVAILABLE, { businessId }],
        () => Services.isServicesAvailable(businessId),
        {
            enabled: !!businessId,
            retry: false,
            retryOnMount: false,
        },
    );
}
