import { useQuery } from 'react-query';

import FoodMenu from 'app/api/v2/api_calls/foodMenuApiCalls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import {
    BUSINESS,
    IS_FOOD_MENUS_AVAILABLE,
} from 'app/common/data/queryKeysConstants';

export default function useIsFoodMenusAvailable() {
    const businessId = useGetBusinessId();

    return useQuery(
        [BUSINESS, IS_FOOD_MENUS_AVAILABLE, { businessId }],
        () => FoodMenu.isFoodMenusAvailable(businessId),
        {
            enabled: !!businessId,
            retry: false,
            retryOnMount: false,
        },
    );
}
