import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';

export enum Partner {
    Google = 'google',
    Facebook = 'facebook',
}

export function useIsConnectedToPartner(partner: Partner) {
    const { data: business } = useBusiness();
    return !!(partner === Partner.Google
        ? business?.google_location_id
        : business?.facebook_page_id);
}
