import { TextInput } from '@partoohub/ui';

import { useImagesUploaderContext } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/hooks/useImagesUploaderContext';
import { errorMaxCharacters } from 'app/businessEditV2/utils/utils';

type ImageUploaderTextFieldProps = {
    imageIndex: number;
    field: string;
    maxLength: number;
};

export const ImageUploaderTextField = ({
    imageIndex,
    field,
    maxLength,
}: ImageUploaderTextFieldProps) => {
    const { images, updateTextField } = useImagesUploaderContext();
    const value = images?.[imageIndex]?.texts[field];

    return (
        <TextInput
            dataTrackId=""
            label={field}
            value={value}
            onChange={v => updateTextField(imageIndex, field, v)}
            error={errorMaxCharacters(value, maxLength)}
            characterCount={maxLength}
        />
    );
};
