import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { SETTINGS_PLATFORMS_CONNECTION_PATHNAME } from 'app/routing/routeIds';

const NotConnectedToGmbTemplate = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const goOnPartnerConection = () => {
        navigate(`${SETTINGS_PLATFORMS_CONNECTION_PATHNAME}?publisher=google`);
    };

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };

    return (
        <NotFoundTemplate
            show
            imgSrc={illustrationElement}
            title={t('not_connected_to_gmb_title')}
            subtitle={t('not_connected_to_gmb_subtitle')}
            buttonText={t('connection_required')}
            handleClick={goOnPartnerConection}
        />
    );
};

export default NotConnectedToGmbTemplate;
