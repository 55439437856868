import { useState } from 'react';

import { Banner, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ImageUploadErrorDetails } from 'app/common/designSystem/components/molecules/DropZone/helpers';

import useImageUploadErrors from '../../hooks/useImageUploadErrors';

type Props = {
    uploadImageErrors: ImageUploadErrorDetails[];
};

const ImageUploadErrorBanner = ({ uploadImageErrors }: Props) => {
    const { t } = useTranslation();
    const { minFileResolution, maxFileResolution } = useImageUploadErrors();
    const [isShown, setIsShown] = useState<boolean>(true);

    if (!isShown) return null;

    const onClose = () => {
        setIsShown(false);
    };

    const renderText = (errorType: string) => {
        const errorMessageText = (
            <Text
                as="span"
                color="danger"
                variant={uploadImageErrors.length > 1 ? 'bodyMRegular' : 'bodySRegular'}
            >
                {getErrorText(errorType)}
            </Text>
        );

        return uploadImageErrors.length > 1 ? (
            <ul style={{ paddingLeft: '24px' }}>
                <li key={errorType}>{errorMessageText}</li>
            </ul>
        ) : (
            errorMessageText
        );
    };

    return (
        <Banner dataTrackId="image_upload_error_banner" variant="danger" onClose={onClose}>
            <Stack gap="16px" direction="row">
                <Icon
                    icon={[FontAwesomeIconsPartooUsed.faImage, IconPrefix.REGULAR]}
                    iconSize="40px"
                    color="danger"
                />
                <Stack gap="4px">
                    {uploadImageErrors.length > 1 && (
                        <Text variant="bodyLSemibold">
                            {t('post_wrong_photo_quantity', {
                                numberOfImages: uploadImageErrors.length,
                            })}
                        </Text>
                    )}
                    <div>
                        {uploadImageErrors.map(({ imageName, errorTypes }) => (
                            <Stack key={imageName} gap="0px">
                                <Text
                                    variant={
                                        uploadImageErrors.length > 1
                                            ? 'bodyMRegular'
                                            : 'bodyMSemibold'
                                    }
                                >
                                    {imageName}
                                </Text>
                                {Array.from(errorTypes).map(renderText)}
                            </Stack>
                        ))}
                    </div>
                </Stack>
            </Stack>
        </Banner>
    );

    function getErrorText(errorType: string) {
        switch (errorType) {
            case 'wrong_format':
                return t('drop_zone_error_type_wrong_format');
            case 'too_big':
                return t('drop_zone_error_type_too_big');
            case 'too_small':
                return t('drop_zone_error_type_too_small');
            case 'minResolution':
                return t('post_wrong_photo_size', {
                    acceptedSizeWidth: minFileResolution.width,
                    acceptedSizeHeight: minFileResolution.height,
                });
            case 'maxResolution':
                return t('post_wrong_photo_size_max', {
                    acceptedSizeWidth: maxFileResolution.width,
                    acceptedSizeHeight: maxFileResolution.height,
                });
            case 'unexpected':
                return t('unexpected_error');
            default:
                return t('unexpected_error');
        }
    }
};

export default ImageUploadErrorBanner;
