import { Stack } from '@partoohub/ui';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditForm from 'app/businessEditV2/components/EditForm';
import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import ControlledAddressFullInput from 'app/businessEditV2/hookControllerWrapper/ControlledAddressFullInput';
import ControlledAsyncSingleSelect from 'app/businessEditV2/hookControllerWrapper/ControlledAsyncSingleSelect';
import { ControlledCityField } from 'app/businessEditV2/hookControllerWrapper/ControlledCityField';
import { ControlledTextInput } from 'app/businessEditV2/hookControllerWrapper/ControlledTextInput';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import useCountry from 'app/businessEditV2/hooks/business/useCountry';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useAddressSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useAddressSectionColor';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useFormFieldFormatter from 'app/businessEditV2/hooks/publisherErrors/useFormFieldFormatter';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import GoogleMapsMap from 'app/common/components/form/GoogleMaps/GoogleMapsMap';
import GoogleMapsPlaceHolder from 'app/common/components/form/GoogleMaps/GoogleMapsPlaceHolder';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

import { removeBracketsCharacters } from 'app/utils/regex';

import { BusinessNameInput, MapContainer, MapDescription } from './AddressForm.styled';

type Props = {
    closeModal: () => void;
    form: UseFormReturn;
};

const AddressForm = ({ form, closeModal }: Props) => {
    const colorsState = useAddressSectionColor();
    const { t } = useTranslation();

    const { data: business } = useBusiness();
    const { data: countryData } = useCountry();

    const { control, handleSubmit, watch, setValue } = form;

    const [watchLat, watchManualLat] = form.watch(['lat', 'manualLat']);
    const lat = watchManualLat || watchLat;

    const [watchLong, watchManualLong] = form.watch(['long', 'manualLong']);
    const long = watchManualLong || watchLong;

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const businessUpdate = useBusinessUpdate(() => {
        businessUpdateCompletionRate.mutate();
        closeModal();
    });

    // @ts-ignore
    const errors = businessUpdate.error?.response?.data?.errors?.json;

    const onSubmit = payload => {
        businessUpdate.mutate(payload);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_ADDRESS_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
    };

    // Permissions
    const businessIsOpen = !useIsBusinessClosed();
    const namePermission = useFieldMetadata('infos', 'name')?.enabled && businessIsOpen;
    const addressFullPermission =
        useFieldMetadata('infos', 'address_full')?.enabled && businessIsOpen;
    const address2Permission = useFieldMetadata('infos', 'address2')?.enabled && businessIsOpen;
    const zipcodePermission = useFieldMetadata('infos', 'zipcode')?.enabled && businessIsOpen;
    const cityPermission = useFieldMetadata('infos', 'city')?.enabled && businessIsOpen;
    const countryPermission = useFieldMetadata('infos', 'country')?.enabled && businessIsOpen;
    const coordinatesPermission =
        useFieldMetadata('infos', 'coordinates')?.enabled && businessIsOpen;

    const formatPayload = payload => ({
        ...(namePermission && { name: payload?.name ?? '' }),
        ...(addressFullPermission && {
            address_full: payload?.addressFull ?? '',
        }),
        ...(address2Permission && { address2: payload?.address2 ?? '' }),
        ...(zipcodePermission && { zipcode: payload?.zipcode ?? '' }),
        ...(cityPermission && { city: payload?.city?.value ?? '' }),
        ...(countryPermission && { country: payload?.country?.value }),
        ...(coordinatesPermission && {
            manual_lat: payload.manualLat,
            manual_long: payload.manualLong,
        }),
    });

    const submitForm = () => {
        handleSubmit(payload => onSubmit(formatPayload(payload)))();
        trackSaveBusinessEditSection(SectionNames.ADDRESS, ColorState.OK);
    };

    const onDrag = (lat: number, long: number) => {
        setValue('manualLat', lat, { shouldDirty: true });
        setValue('manualLong', long, { shouldDirty: true });
    };

    const country = watch('country')?.value;

    const handleChangeValueWithoutBracketsCharacters = (value: string) => {
        return removeBracketsCharacters(value);
    };

    return (
        <>
            <EditForm
                title={t('main_information')}
                description={t('main_infos_section_description')}
                colorState={colorsState}
            >
                <div>
                    <Stack style={{ marginBottom: '16px' }}>
                        <BusinessNameInput
                            name="name"
                            control={control}
                            label={t('business_name')}
                            {...useFormFieldFormatter(
                                'infos',
                                'name',
                                watch,
                                formatPayload,
                                errors,
                            )}
                            dataTrackId="visibility_location__form_address__business_name"
                            onChangeValue={handleChangeValueWithoutBracketsCharacters}
                            hasOldTextFieldHeight
                        />
                        <Stack direction="row">
                            <ControlledAddressFullInput
                                name="addressFull"
                                control={control}
                                setValue={setValue}
                                {...useFormFieldFormatter(
                                    'infos',
                                    'address_full',
                                    watch,
                                    formatPayload,
                                    errors,
                                )}
                                dataTrackId="visibility_location__form_address__address_full"
                            />
                            <ControlledTextInput
                                name="address2"
                                control={control}
                                label={t('business_address_complement')}
                                {...useFormFieldFormatter(
                                    'infos',
                                    'address2',
                                    watch,
                                    formatPayload,
                                    errors,
                                )}
                                hasOldTextFieldHeight
                                dataTrackId="visibility_location__form_address__address2"
                            />
                        </Stack>
                        <Stack direction="row">
                            <ControlledTextInput
                                name="zipcode"
                                control={control}
                                label={t('zipcode')}
                                {...useFormFieldFormatter(
                                    'infos',
                                    'zipcode',
                                    watch,
                                    formatPayload,
                                    errors,
                                )}
                                hasOldTextFieldHeight
                                dataTrackId="visibility_location__form_address__zipcode"
                            />
                            <ControlledCityField
                                name="city"
                                control={control}
                                {...useFormFieldFormatter(
                                    'infos',
                                    'city',
                                    watch,
                                    formatPayload,
                                    errors,
                                )}
                                renderSelect={country === 'FR'}
                                dataTrack="visibility_location__form_address__city"
                            />
                        </Stack>
                        <ControlledAsyncSingleSelect
                            name="country"
                            control={control}
                            placeholder={t('country')}
                            options={countryData || []}
                            hideCross
                            {...useFormFieldFormatter(
                                'infos',
                                'country',
                                watch,
                                formatPayload,
                                errors,
                            )}
                            dataTrack="visibility_location__form_address__country"
                        />
                    </Stack>
                    <MapDescription>{t('location-assistive-text')}</MapDescription>
                    <MapContainer
                        data-track="visibility_location__form_address__map"
                        data-intercom-target="visibility_location__form_address__map"
                    >
                        {lat !== null && long !== null ? (
                            <GoogleMapsMap
                                lat={lat}
                                long={long}
                                onDrag={onDrag}
                                disabled={!coordinatesPermission}
                            />
                        ) : (
                            <GoogleMapsPlaceHolder
                                icon={<i className="fa-solid fa-map-marker-alt" />}
                                message={t('confirm_address_to_place')}
                            />
                        )}
                    </MapContainer>
                </div>
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={businessUpdate.isLoading}
                    hasError={!!businessUpdate.error}
                    onClick={submitForm}
                    dataTrackId="visibility_location__form_address__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default AddressForm;
