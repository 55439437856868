import React from 'react';

import { Button, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';

import {
    ApiKeyTypeEnum,
    ApiKeysQueryKeys,
    ApiKeysQueryParams,
} from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/utils/enums';
import { translationApiKeyLabels } from 'app/settingsManagement/components/ApiKey/ApiKey';

const ApiKeysHeader = () => {
    const { t } = useTranslation();
    const [typeQuery] = useStateQueryParams(ApiKeysQueryKeys.TYPE);
    const [, setCreateApiKey] = useStateQueryParams(ApiKeysQueryParams.FORM);

    return (
        <DefaultMenuListHeader
            title={t('api_key_manager_title')}
            description={t(translationApiKeyLabels.description[typeQuery || ApiKeyTypeEnum.USER])}
            actionsButton={[
                <Button
                    size="medium"
                    variant="primary"
                    appearance="contained"
                    shape="cube"
                    icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                    onClick={() => setCreateApiKey(ApiKeysQueryParams.CREATE)}
                    key="api_key_manager_create_button"
                    dataTrackId="api_key_manager_create_button"
                >
                    {t('api_key_manager_create_button')}
                </Button>,
            ]}
        />
    );
};

export default ApiKeysHeader;
