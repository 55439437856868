import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const StyledStack = styled(Stack)<{ status?: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    padding: ${({ status }) => (status === 'default' ? '20px' : '20px 20px 30px')};
    background: ${({ status, theme }) =>
        status === 'default' ? theme.colors.warning.alpha : theme.colors.secondary.alpha};
    color: ${({ status, theme }) =>
        status === 'default' ? theme.colors.warning.initial : theme.colors.default.initial};
`;
