import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';

import { ReviewAnalyticsV2 } from './ReviewAnalyticsV2';
import { ReviewAnalyticsV2WithNewSidebar } from './ReviewAnalyticsV2WithNewSidebar';

export const ReviewAnalyticsV2Page = () => {
    const { t } = useTranslation();
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const ReviewAnalyticsV2Component = isNewSidebarEnabled
        ? ReviewAnalyticsV2WithNewSidebar
        : ReviewAnalyticsV2;

    return (
        <>
            <DynamicPageHeader title={t('analytics')} />
            <ReviewAnalyticsV2Component />
        </>
    );
};
