import { useGetProductFilter } from 'app/pages/visibility/BusinessListV2/hooks/queryParams/params/useGetProductFilter';

interface GetBusinessProductFilterType {
    subscribed_to_pm?: boolean;
    subscribed_to_rb?: boolean;
    subscribed_to_rm?: boolean;
}
export const useGetBusinessProductFilter = (): GetBusinessProductFilterType => {
    const { subscribed_to_pm, subscribed_to_rb, subscribed_to_rm } = useGetProductFilter();
    const result: GetBusinessProductFilterType = {};
    if (subscribed_to_pm) {
        result.subscribed_to_pm = true;
    }

    if (subscribed_to_rb) {
        result.subscribed_to_rb = true;
    }

    if (subscribed_to_rm) {
        result.subscribed_to_rm = true;
    }
    return result;
};
