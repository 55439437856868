import { useMutation } from 'react-query';

import { Chatbots } from 'app/api/v2/api_calls/chatbotsApiCalls';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

export function useValidateBusinesses(chatbotId?: number) {
    return useMutation((businessSearchFilter: BusinessModalFiltersType) =>
        Chatbots.validateBusinesses(chatbotId, businessSearchFilter),
    );
}
