// DESIGN SYSTEM TYPES
export type PartooProduct =
    | 'CUSTOMER_EXPERIENCE'
    | 'KNOWLEDGE_MANAGEMENT'
    | 'PRESENCE_MANAGEMENT'
    | 'REVIEW_MANAGEMENT'
    | 'REVIEW_BOOSTER'
    | 'BUSINESS_MESSAGE'
    | 'TEAM_MANAGEMENT'
    | 'SETTINGS'
    | 'PARTOO_MAIN_PAGE_MOBILE'
    | 'LOGOUT'
    | 'HOMEPAGE';

// DESIGN SYSTEM CONSTANTS
export const CUSTOMER_EXPERIENCE: PartooProduct = 'CUSTOMER_EXPERIENCE';
export const KNOWLEDGE_MANAGEMENT: PartooProduct = 'KNOWLEDGE_MANAGEMENT';
export const PRESENCE_MANAGEMENT: PartooProduct = 'PRESENCE_MANAGEMENT';
export const REVIEW_MANAGEMENT: PartooProduct = 'REVIEW_MANAGEMENT';
export const REVIEW_BOOSTER: PartooProduct = 'REVIEW_BOOSTER';
export const BUSINESS_MESSAGE: PartooProduct = 'BUSINESS_MESSAGE';
export const TEAM_MANAGEMENT: PartooProduct = 'TEAM_MANAGEMENT';
export const SETTINGS: PartooProduct = 'SETTINGS';
export const LOGOUT: PartooProduct = 'LOGOUT';
export const HOMEPAGE: PartooProduct = 'HOMEPAGE';

export type AppProduct =
    | 'presence_management'
    | 'review_management'
    | 'review_booster'
    | 'messages' // Need to have it to handle both BM product names
    | 'business_message';

export const APP_PRODUCTS: Readonly<Record<string, AppProduct>> = {
    PRESENCE_MANAGEMENT: 'presence_management',
    REVIEW_MANAGEMENT: 'review_management',
    REVIEW_BOOSTER: 'review_booster',
    BUSINESS_MESSAGE: 'business_message',
};
