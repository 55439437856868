import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { StyledDimensionSwitch } from './DimensionSwitch.styled';
import { useInternalRankingParameters } from '../hooks/useInternalRankingParameters';

export const DimensionSwitch = () => {
    const { t } = useTranslation();

    const options = [
        { label: t('businesses'), value: 'business' as const },
        { label: t('users'), value: 'user' as const },
    ];

    const {
        dimension: [selectedDimension, setDimension],
        resetParameters,
    } = useInternalRankingParameters();

    useEffect(() => {
        resetParameters({ dimension: options });
    }, []);

    return (
        <StyledDimensionSwitch
            dataTrackId=""
            name=""
            onChange={e => setDimension(e.target.value)}
            value={selectedDimension}
            leftButton={options[0]}
            rightButton={options[1]}
        />
    );
};
