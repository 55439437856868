import { useEffect, useState } from 'react';

import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Dialog, Modal, Option, SingleSelect, Stack, TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { ExportSelectionType } from 'app/api/types/competitiveBenchmark';
import useLocalBusinessIdCompetitiveBenchmark from 'app/common/components/businessModal/hooks/birdOnly/useLocalBusinessIdCompetitiveBenchmark';
import StatefulButton, {
    ButtonStatus,
    DISABLED,
    LOADING,
    TOSAVE,
} from 'app/common/components/buttons/StatefulButton';

import { ModalButtonsContainer, SingleSelectContainer } from './ExportModal.styled';

import { usePostExportTask } from './hooks/usePostExportTask';
import { useUserEmail } from './hooks/useUserEmail';
import { MonthPicker } from '../components/MonthPicker/MonthPicker';
import { useDateFilter } from '../hooks/useDateFilter';
import { formatDate } from '../utils/formatDate';

type Props = {
    isOpen: boolean;
    toggle: () => void;
};

export const ExportModal = ({ isOpen, toggle }: Props) => {
    const { t } = useTranslation();
    const userEmail = useUserEmail();
    const [email, setEmail] = useState(userEmail);
    const [date] = useDateFilter();
    const [selection, setSelection] = useState(ExportSelectionType.ALL);
    const [statusButton, setStatusButton] = useState<ButtonStatus>(TOSAVE);
    const localBusinessId = useLocalBusinessIdCompetitiveBenchmark();
    const { postExportTask, isLoading } = usePostExportTask();

    useEffect(
        () => setStatusButton(!selection || !email || !date ? DISABLED : TOSAVE),
        [selection, email, date],
    );
    useEffect(() => setStatusButton(isLoading ? LOADING : TOSAVE), [isLoading]);

    const handleDownload = () => {
        if (localBusinessId) {
            setSelection(ExportSelectionType.BUSINESSES);
        }
        postExportTask({ email, monthDate: formatDate(date), type: selection });
        toggle();
    };

    const illustrationElement: IllustrationElement = {
        name: IllustrationOldEnum.Download,
        type: IllustrationCategoryEnum.Old,
    };

    const buildSelectOption = (value: string): Option => ({
        value: value,
        name: t(value),
        label: t(value),
    });

    const sections = [{ options: Object.values(ExportSelectionType).map(buildSelectOption) }];

    return (
        <Modal isOpen={isOpen} closeModal={toggle}>
            <Dialog
                dataTrackId="competitive_benchmark_export_modal"
                header={
                    <div style={{ paddingTop: '24px' }}>
                        <Illustration
                            dataTrackId="competitive_benchremark_export_illustration"
                            height="140"
                            illustration={illustrationElement}
                            width="320"
                        />
                    </div>
                }
                onClose={toggle}
                title={t('competitive_benchmark_export_title')}
                subTitle={t('export_description')}
            >
                <Stack direction="row" gap="16px">
                    {!localBusinessId ? (
                        <>
                            <MonthPicker />
                            <SingleSelectContainer>
                                <SingleSelect
                                    label={t('competitive_benchmark_export_data')}
                                    dataTrackId={'competitive_benchmark_export_option'}
                                    onChange={(selected?: Option) => {
                                        setSelection(
                                            selected
                                                ? (selected.value as ExportSelectionType)
                                                : ExportSelectionType.ALL,
                                        );
                                    }}
                                    sections={sections}
                                    selectedValue={buildSelectOption(selection)}
                                    disableReset
                                />
                            </SingleSelectContainer>
                        </>
                    ) : (
                        <SingleSelectContainer>
                            <MonthPicker />
                        </SingleSelectContainer>
                    )}
                </Stack>
                <Stack direction="row">
                    <TextInput
                        label={t('email')}
                        dataTrackId={'competitive_benchmark_export_email'}
                        type="email"
                        value={email}
                        onChange={setEmail}
                    />
                </Stack>
                <ModalButtonsContainer>
                    <StatefulButton label={t('cancel')} status="cancel" onClick={toggle} />
                    <StatefulButton
                        label={t('send_email')}
                        status={statusButton}
                        onClick={handleDownload}
                        hideIcon
                    />
                </ModalButtonsContainer>
            </Dialog>
        </Modal>
    );
};

export default ExportModal;
