import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import { useFeedbackResultQueryParams } from 'app/reviewManagement/reviewList/hooks/useFeedbackResultQueryParams';

export const useDownloadFeedbackResults = () => {
    const { t } = useTranslation();
    const queryParams = useFeedbackResultQueryParams();

    const { mutate } = useMutation(
        async ({ email }: { email: string }) =>
            api.feedbackResults.downloadFeedbackResults({
                ...queryParams,
                optin: null,
                email,
            }),
        {
            onSuccess: () => {
                toast.success(null, t('feedback_results_download_success'));
            },
            onError: () => {
                toast.error(null, t('reply_error'));
            },
        },
    );

    return mutate;
};
