import { InteractionAxis, InteractionMode, PointStyle } from 'chart.js';

import { useOverviewSelection } from './useOverviewSelection';

export const useChartOptions = () => {
    const [overviewSelection] = useOverviewSelection();

    return {
        scales: {
            x: {
                grid: { display: true, lineWidth: 0.5, drawTicks: false },
                ticks: { font: { size: 10 } },
            },
            y: {
                grid: { display: false },
                beginAtZero: false,
                ticks: {
                    callback: (value: string | number) => (Number(value) % 1 ? undefined : value),
                    font: { size: 10 },
                },
                reverse: overviewSelection === 'rank',
            },
        },
        plugins: { legend: { display: false } },
        responsive: true,
        maintainAspectRatio: false,
        animation: {},
        elements: {
            point: { pointStyle: false as PointStyle },
            line: { tension: 0.3 },
        },
        interaction: {
            intersect: false,
            mode: 'nearest' as InteractionMode,
            axis: 'xy' as InteractionAxis,
        },
        spanGaps: false,
    };
};
