import { push } from '@lagunovsky/redux-react-router';
import { toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { ReviewObject } from 'app/api/types/review';
import { buildToastAction } from 'app/common/components/ToastWithAction/ToastWithAction';
import IS_IFRAME from 'app/common/data/iframe';
import { useUpdateReviewQueryData } from 'app/reviewManagement/reviewList/hooks/queryData/useUpdateReviewQueryData';
import { SETTINGS_PLATFORMS_CONNECTION_PATHNAME } from 'app/routing/routeIds';

export const useShowReviewErrorToast = () => {
    const { t } = useTranslation();
    const updateReviewQueryData = useUpdateReviewQueryData();

    const showReviewErrorToast = (
        review: ReviewObject,
        error: Record<string, any>,
        dispatch: any,
    ) => {
        const errorMessage = error?.response?.data?.errors?.json;

        switch (errorMessage) {
            case 'Requested entity was not found.':
                updateReviewQueryData(review, { markReviewAs: 'deleted' });
                dispatch(toast.error(null, t('deleted_review_error')));
                break;

            case 'Missing GMB location_id':
                toast.error(
                    buildToastAction(
                        t('gmb_location_error_message'),
                        !IS_IFRAME ? t('gmb_location_error_action') : '',
                        () => dispatch(push(SETTINGS_PLATFORMS_CONNECTION_PATHNAME)),
                    ),
                    t('gmb_location_error_title'),
                );
                break;

            case 'Missing Facebook location_id':
                toast.error(
                    buildToastAction(
                        t('facebook_location_error_message'),
                        !IS_IFRAME ? t('facebook_location_error_action') : '',
                        () => dispatch(push(SETTINGS_PLATFORMS_CONNECTION_PATHNAME)),
                    ),
                    t('facebook_location_error_title'),
                );
                break;

            case 'Unable to fetch an active link to a GMB account for GMB Location':
                toast.error(
                    buildToastAction(
                        t('gmb_account_error_message'),
                        !IS_IFRAME ? t('gmb_location_error_action') : '',
                        () => dispatch(push(SETTINGS_PLATFORMS_CONNECTION_PATHNAME)),
                    ),
                    t('gmb_location_error_title'),
                );
                break;

            default:
                dispatch(toast.error(null, t('reply_error')));
        }
    };

    return showReviewErrorToast;
};
