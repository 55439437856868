import React from 'react';

import {
    ChartCardsContainer,
    ChartGraphContainer,
    ContentSectionContainer,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/ContentSection/ContentSection.styled';
import {
    KeywordsCard,
    KeywordsChart,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/Keywords';
import {
    MetricsCard,
    MetricsChart,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/Metrics';

export const ContentSection = () => {
    return (
        <ContentSectionContainer>
            <ChartCardsContainer>
                <KeywordsCard />
                <MetricsCard />
            </ChartCardsContainer>
            <ChartGraphContainer>
                <KeywordsChart />
                <MetricsChart />
            </ChartGraphContainer>
        </ContentSectionContainer>
    );
};
