import { PublisherData } from 'app/api/types/publisher';

export const PUBLISHERS_REQUEST = 'PUBLISHERS_REQUEST';
export const PUBLISHERS_REQUEST_SUCCESS = 'PUBLISHERS_REQUEST_SUCCESS';
export const PUBLISHERS_REQUEST_FAILURE = 'PUBLISHERS_REQUEST_FAILURE';

// ACTION FLOW TYPES
type PublisherRequestAction = {
    type: 'PUBLISHERS_REQUEST';
};

type PublisherRequestSuccessAction = {
    type: 'PUBLISHERS_REQUEST_SUCCESS';
};

type PublisherRequestFailureAction = {
    type: 'PUBLISHERS_REQUEST_FAILURE';
};

export const publisherRequest = (): PublisherRequestAction => ({
    type: PUBLISHERS_REQUEST,
});

export const publisherRequestSuccess = (
    names: Array<string>,
    byName: Record<string, PublisherData>,
): PublisherRequestSuccessAction => ({
    type: PUBLISHERS_REQUEST_SUCCESS,
    // @ts-ignore
    names,
    byName,
});

export const publisherRequestFailure = (): PublisherRequestFailureAction => ({
    type: PUBLISHERS_REQUEST_FAILURE,
});

export type PublishersAction =
    | PublisherRequestAction
    | PublisherRequestSuccessAction
    | PublisherRequestFailureAction;
