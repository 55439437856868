import { AppIcon, AppIconsValuesEnum } from '@partoohub/iconography';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { STATIC_APP_ASSETS_URL } from 'app/config';
import {
    MESSAGING_STATS_PERIOD_MONTHS,
    useHomepageMessagingStats,
} from 'app/pages/Homepage/hooks/stats/statsItems/messages/useHomepageMessagingStats';
import { useIsLoadingMessagingStats } from 'app/pages/Homepage/hooks/stats/statsItems/messages/useIsLoadingMessagingStats';
import { DefaultStatComponent } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent';
import { SmallStatProps } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/DefaultStatComponent';
import { CONVERSATIONS_MESSAGING_PATH } from 'app/routing/routeIds';

import { StatMessagesChip } from './StatMessagesChip';

const secondsToMinutesAndSeconds = (seconds: number) => {
    const roundedSeconds = Math.round(seconds);

    const minutes = Math.floor(roundedSeconds / 60);
    const remainingSeconds = roundedSeconds % 60;

    return {
        minutes: minutes,
        seconds: remainingSeconds,
    };
};

export const StatMessages = (props: SmallStatProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data } = useHomepageMessagingStats(MESSAGING_STATS_PERIOD_MONTHS, 0);
    const isLoading = useIsLoadingMessagingStats();

    const actualAverageResponseTime = data?.previousAverageResponseTime ?? 0;
    const { minutes: minResponseTime, seconds: secondsResponseTime } =
        secondsToMinutesAndSeconds(actualAverageResponseTime);
    const noStats = actualAverageResponseTime === 0 || isNaN(actualAverageResponseTime);

    return (
        <DefaultStatComponent
            {...props}
            productTitle={t('homepage_stats_messages_title')}
            statTitle={t('homepage_stats_messages_response_time', {
                minutes: minResponseTime,
                seconds: secondsResponseTime,
            })}
            statSubtitle={t('homepage_stats_messages_subtitle')}
            description={t('homepage_stats_messages_description')}
            productIcon={
                <AppIcon
                    dataTrackId="stat_message__app_icon"
                    name={AppIconsValuesEnum.GetClients}
                    width="24"
                    height="24"
                />
            }
            isLoading={isLoading}
            onClick={() => navigate(CONVERSATIONS_MESSAGING_PATH)}
            bannerUrl={`${STATIC_APP_ASSETS_URL}/homepage/MessagesBanner.svg`}
            displayEmptyPlaceholder={!isLoading && noStats}
            statChip={<StatMessagesChip />}
        />
    );
};
