import React from 'react';

import { useTranslation } from 'react-i18next';

import Clickable from 'app/common/components/buttons/Clickable';
import { GROUP_MANAGER } from 'app/common/data/roles';
import explanationHOC from 'app/common/designSystem/components/atoms/ExplanationHOC';
import useGroupsV2 from 'app/common/hooks/queries/useGroupsV2';
import useMe from 'app/common/hooks/queries/useMeUncamel';

type Props = {
    value: Array<number>;
    onClick: () => void;
    reset: () => void;
    orgId?: number;
    mandatory?: boolean;
};

/**
 * Fake copy of AsyncMultipleSelect, only the styling without the actual search and dropdown
 * Reusing the AsyncMultipleSelect scss for now... Should be redone with Emotion one day
 */
const AccessGroupButton = ({
    value,
    onClick,
    reset,
    orgId,
    mandatory,
}: Props) => {
    const { t } = useTranslation();
    const { sections } = useGroupsV2({ org_id: orgId });
    const groups = sections?.flatMap(section => section.groups) ?? [];

    const { data: me } = useMe();

    const resetClick = (e: React.MouseEvent<HTMLElement>) => {
        if (value.length) {
            e.preventDefault();
            e.stopPropagation();
            reset();
        }
    };

    return (
        <div className="multiple_select">
            <Clickable
                className={`
                    multiple_select__field
                    multiple_select__field--async
                    multiple_select__field--unfocused
                `}
                onClick={onClick}
            >
                <div
                    className={`
                        multiple_select__placeholder
                        ${
                            value.length > 0
                                ? 'multiple_select__placeholder--top'
                                : ''
                        }
                    `}
                >
                    <div>
                        {t('select_groups')}
                        {(mandatory || me?.role === GROUP_MANAGER) && (
                            <span className="mandatory-field"> *</span>
                        )}
                    </div>
                </div>
                <div className="multiple_select__field_items">
                    {value.map(groupId => {
                        const group = groups.find(
                            group => group.id === groupId,
                        );

                        return (
                            <div
                                className="multiple_select__field_items_item font-small-bold"
                                key={groupId}
                            >
                                {group?.name ?? groupId}
                            </div>
                        );
                    })}
                </div>
                <button
                    className="multiple_select__field__icon_button"
                    onClick={resetClick}
                >
                    {value.length > 0 ? (
                        <i className="fas fa-times" />
                    ) : (
                        <i className="fas fa-caret-down" />
                    )}
                </button>
            </Clickable>
        </div>
    );
};

export default explanationHOC<Props>(AccessGroupButton);
