import styled from '@emotion/styled';

export const FiltersSectionContainer = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 16px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
`;
