import { useMutation, useQueryClient } from 'react-query';

import { ReportUserPreferencesResponse } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import { REVIEW_USER_REPORT_PREFERENCES } from 'app/common/data/queryKeysConstants';

export const useUpdateUserReportPreferences = () => {
    const queryClient = useQueryClient();
    const mutation = useMutation(
        ({ userId, payload }: { userId: string; payload: ReportUserPreferencesResponse }) =>
            api.user.updateUserReportPreferences(payload, userId),
        {
            onSuccess: (_, { userId }) => {
                queryClient.invalidateQueries([REVIEW_USER_REPORT_PREFERENCES, userId]);
            },
        },
    );

    return [mutation.mutate, mutation] as const;
};
