import { Banner, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ReviewObjectType } from 'app/api/types/review';
import useLocalStorage from 'app/common/hooks/useLocalStorage';

import { useCanReplyClientReviews } from 'app/pages/customerExperience/useCanReplyClientReviews';

export const ReplyPermissionDeniedBanner = () => {
    const { t } = useTranslation();
    const {
        canReplyClientReviews: { [ReviewObjectType.REVIEW]: hasReviewReplyPermission },
    } = useCanReplyClientReviews();
    const [closed, setClosed] = useLocalStorage('reviewReplyPermissionDeniedBannerClosed', false);

    return !hasReviewReplyPermission && !closed ? (
        <Banner
            icon={['fa-circle-info', IconPrefix.SOLID]}
            dataTrackId="review-reply-permission-denied-banner"
            onClose={() => setClosed(true)}
        >
            {t('review_reply_permission_denied_banner')}
        </Banner>
    ) : null;
};
