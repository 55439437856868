import { useTranslation } from 'react-i18next';

import EditForm from 'app/businessEditV2/components/EditForm';
import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import useServicesSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useServicesSectionColor';
import ServicesList from 'app/businessEditV2/sections/ServicesSection/ServicesListForm';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';

type Props = {
    closeModal: () => void;
};

const ServicesForm = ({ closeModal }: Props) => {
    const { t } = useTranslation();
    const colorState = useServicesSectionColor();

    const closeModalWithGTM = () => {
        closeModal();
        trackSaveBusinessEditSection(SectionNames.SERVICES, colorState);
    };

    return (
        <>
            <EditForm
                title={t('gmb_services_business_edit')}
                description={t('business_edit_services_description')}
                colorState={colorState}
            >
                <ServicesList />
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={false}
                    hasError={false}
                    onClick={closeModalWithGTM}
                    dataTrackId="visibility_location__form_services__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default ServicesForm;
