import { FeedbackFormFieldType } from 'app/api/types/feedbackResults';

import { StyledFeedbackResultFields } from './FeedbackResultFields.styled';
import { FeedbackResultField } from './fields';
import { useFeedbackResultPanel } from '../hooks';

export const FeedbackResultFields = () => {
    const { feedbackResult } = useFeedbackResultPanel();

    if (!feedbackResult?.details) return undefined;

    const fields = [...feedbackResult.details];
    const npsFieldIdx = fields.findIndex(field => field.type === FeedbackFormFieldType.NPS);
    const npsField = npsFieldIdx !== -1 ? fields.splice(npsFieldIdx, 1)[0] : undefined;

    const textAreaFieldIdx = fields.findIndex(
        field => field.type === FeedbackFormFieldType.FREE_FORM,
    );
    const textAreaField =
        textAreaFieldIdx !== -1 ? fields.splice(textAreaFieldIdx, 1)[0] : undefined;

    return (
        <>
            <StyledFeedbackResultFields>
                {/* NPS */}
                {!!npsField && <FeedbackResultField field={npsField} />}
            </StyledFeedbackResultFields>
            <StyledFeedbackResultFields>
                {/* Free form */}
                {!!textAreaField && <FeedbackResultField field={textAreaField} />}
                {/* Other fields */}
                {fields.map(field => (
                    <FeedbackResultField field={field} key={field.question} />
                ))}
            </StyledFeedbackResultFields>
        </>
    );
};
