import { useMutation } from 'react-query';

import { Chatbots } from 'app/api/v2/api_calls/chatbotsApiCalls';
import { CHATBOTS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

export const useChatbotDuplicate = () => {
    return useMutation((chatbot_id: number) => Chatbots.duplicateChatbot(chatbot_id), {
        onSuccess: () => {
            queryClient.invalidateQueries([CHATBOTS]);
        },
    });
};
