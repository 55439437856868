import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { COUNTRY } from 'app/common/data/queryKeysConstants';
import { useLanguage } from 'app/common/services/getLang';

export default function useCountry() {
    const lang = useLanguage();
    return useQuery([COUNTRY, { lang }], () => api.address.getCountry(lang), {
        enabled: !!lang,
    });
}
