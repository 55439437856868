import { ReactNode } from 'react';

import { Stack, Text } from '@partoohub/ui';

type Props = {
    title: string;
    subTitle: string;
    button: ReactNode;
};

export const BulkReplyTemplateRow = ({ title, subTitle, button }: Props) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <div>
                <Text variant="heading6" color="blackberry">
                    {title}
                </Text>
                <Text variant="bodyMRegular" color="secondary">
                    {subTitle}
                </Text>
            </div>
            {button}
        </Stack>
    );
};
