import React from 'react';

import { HEADER_SECTION_HEIGHT } from 'app/businessEditV2/components/BusinessEditV2Content/BusinessEditV2Content.styled';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import BusinessTooltip from 'app/businessEditV2/sections/BusinessStatusSection/BusinessTooltip';

import BusinessBanner from './BusinessBanner';
import BusinessStatusCompletion from './BusinessStatusCompletion';
import BusinessStatusDescription from './BusinessStatusDescription';
import {
    BusinessStatusBottom,
    BusinessStatusContainer,
} from './BusinessStatusSection.styled';
import ModalCongratulationBadge from './ModalCongratulationBadge/ModalCongratulationBadge';

type Props = {
    onClick: () => void;
};

const BusinessStatusSection = ({ onClick }: Props) => {
    const { isLoading } = useBusiness();

    return (
        <BusinessStatusContainer
            data-track="visibility_location__section_status"
            data-intercom-target="visibility_location__section_status"
        >
            {isLoading ? (
                <EditSectionLoader height={HEADER_SECTION_HEIGHT} />
            ) : (
                <>
                    <BusinessBanner />
                    <BusinessStatusBottom>
                        <BusinessStatusDescription onClick={onClick} />
                        <BusinessStatusCompletion />
                        <BusinessTooltip />
                    </BusinessStatusBottom>
                    <ModalCongratulationBadge />
                </>
            )}
        </BusinessStatusContainer>
    );
};

export default BusinessStatusSection;
