import React from 'react';

import { useTranslation } from 'react-i18next';

import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useBusinessMoreHours from 'app/businessEditV2/hooks/moreHours/useBusinessMoreHours';
import useMoreHours from 'app/businessEditV2/hooks/moreHours/useMoreHours';

type Props = {
    colorState?: ColorState;
};

const MoreHourSectionContent = ({ colorState = ColorState.OK }: Props) => {
    const { t } = useTranslation();
    const { data: moreHoursData } = useMoreHours();
    const { data: businessMoreHoursData } = useBusinessMoreHours();

    const moreHours = moreHoursData?.more_hours ?? [];
    const moreHoursGmbIdToName = moreHours.reduce(
        (previous, { gmb_id, name }) => ({ ...previous, [gmb_id]: name }),
        {},
    );

    const businessMoreHours = businessMoreHoursData?.more_hours ?? [];
    const filledMoreHours = businessMoreHours.filter(
        moreHour => moreHour.value,
    );
    const moreHoursToDisplay = filledMoreHours.splice(0, 3);
    const numberOfAdditionalMoreHours = filledMoreHours.length;
    const moreHourNames = moreHoursToDisplay.map(
        ({ gmb_id }) => moreHoursGmbIdToName?.[gmb_id],
    );

    switch (colorState) {
        case ColorState.PRECHECK_ERRORS:
            return <>{t('business_edit_content_correct_this_field')}</>;
        case ColorState.MISSING_WARNING:
        case ColorState.CLOSED:
        case ColorState.DISABLED:
            return <>{t('business_edit_content_more_hours_add')}</>;
        case ColorState.OK: {
            const moreHourNamesString = moreHourNames.join(', ');
            const stringToDisplay =
                numberOfAdditionalMoreHours === 0
                    ? moreHourNamesString
                    : `${moreHourNamesString}, +${numberOfAdditionalMoreHours}`;

            if (moreHoursToDisplay.length === 0) {
                return <>{t('business_edit_content_more_hours_add')}</>;
            }
            return (
                <>
                    <i className="fa-solid fa-calendar-clock" />
                    <span>{stringToDisplay}</span>
                </>
            );
        }
        default:
            return null;
    }
};

export default MoreHourSectionContent;
