import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const HomepageShortcutsContainer = styled.div`
    margin-bottom: 32px;
`;

type HomepageShortcutsGridProps = {
    mobileView: boolean;
};

export const HomepageShortcutsGrid = styled.div<HomepageShortcutsGridProps>`
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 16px;

    // Handle mobile view
    ${props =>
        props.mobileView &&
        css`
            grid-template-columns: repeat(2, minmax(0, 1fr));
        `}
`;
