import React, { useState } from 'react';

import { Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';

import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useCoverSectionColors from 'app/businessEditV2/hooks/editV2utils/sectionColors/useCoverSectionColors';
import CoverForm from 'app/businessEditV2/sections/AddressSection/PhotoFormModal/CoverForm';
import { trackSelectBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { getMappedUrl, resizeImage } from 'app/utils/resizeImage';

import { PhotoCover, PhotoCoverOverlay, PhotoCoverText } from './PhotoCoverSection.styled';

const PhotoCoverSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const { data: business } = useBusiness();
    const sectionColor = useCoverSectionColors();
    const coverUrl = business?.photos?.primary ?? '';

    const onClick = () => {
        setShowModal(true);
        trackSelectBusinessEditSection(SectionNames.COVER, sectionColor);
    };

    return (
        <>
            <TooltipWrapper text={sectionColor === ColorState.DISABLED ? t('field_read_only') : ''}>
                <PhotoCover
                    ref={ref}
                    url={resizeImage(getMappedUrl(coverUrl, business?.media_mapping))}
                    onClick={onClick}
                >
                    {coverUrl ? (
                        <PhotoCoverOverlay>
                            <i className="fa-solid fa-camera" />
                        </PhotoCoverOverlay>
                    ) : (
                        <PhotoCoverText sectionColor={sectionColor}>
                            {t('add_main_photo')}
                            <i className="fa-solid fa-circle-plus" />
                        </PhotoCoverText>
                    )}
                </PhotoCover>
            </TooltipWrapper>

            <Modal isOpen={showModal} closeModal={() => setShowModal(false)}>
                <CoverForm closeModal={() => setShowModal(false)} />
            </Modal>
        </>
    );
});

export default PhotoCoverSection;
