import { APP_PRODUCTS, BUSINESS_MESSAGE } from 'app/common/data/productIds';
import { ORG_ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import env from 'app/common/services/getEnvironment';

export const useIsChatbotPageEnabled = (): boolean => {
    const { data: me } = useMe();

    const hasBusinessMessage: boolean =
        me?.sidebar_products.includes(APP_PRODUCTS[BUSINESS_MESSAGE]) ?? false;
    const hasChatbotFeature: boolean =
        !me?.sidebar_excluded_pages.includes('jim') || env.isSandbox();
    const hasCorrectRole: boolean = me?.role === ORG_ADMIN;

    return hasBusinessMessage && hasChatbotFeature && hasCorrectRole;
};
