import {
    useHomepagePresenceStatsSearches,
    useHomepagePresenceStatsSearchesPrevious,
} from './useHomepagePresenceStats';

export const useIsLoadingMessagingStats = (): boolean => {
    const { isLoading } = useHomepagePresenceStatsSearches();
    const { isLoading: isLoadingPrevious } = useHomepagePresenceStatsSearchesPrevious();

    return isLoading || isLoadingPrevious;
};
