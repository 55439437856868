import { useEffect, useMemo } from 'react';

import { IconPrefix, LeftElementType, Stack } from '@partoohub/ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditForm from 'app/businessEditV2/components/EditForm';
import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import { ControlledTextInput } from 'app/businessEditV2/hookControllerWrapper/ControlledTextInput';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import useContactSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useContactSectionColor';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useFormFieldFormatter from 'app/businessEditV2/hooks/publisherErrors/useFormFieldFormatter';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames, handlePhoneValue } from 'app/businessEditV2/utils/utils';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

type Props = {
    closeModal: () => void;
    useDirtyUpdate: (boolean) => void;
};

const ContactForm = ({ closeModal, useDirtyUpdate }: Props) => {
    const { t } = useTranslation();
    const colorState = useContactSectionColor();
    const { data: business, isLoading } = useBusiness();

    const setFormValue = () => ({
        email: business?.contacts?.[0]?.email ?? '',
        phone: business?.contacts?.[0]?.phone_numbers[0] ?? '',
        fax: business?.contacts?.[0]?.fax ?? '',
    });

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        watch,
        reset,
    } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    useDirtyUpdate(isDirty);

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const businessUpdate = useBusinessUpdate(() => {
        businessUpdateCompletionRate.mutate();
        closeModal();
    });

    // @ts-ignore
    const errors = businessUpdate.error?.response?.data?.errors?.json?.contacts[0];

    const onSubmit = payload => {
        businessUpdate.mutate(payload);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_CONTACT_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
    };

    // Permission
    const businessIsOpen = !useIsBusinessClosed();
    const emailPermission = useFieldMetadata('contact', 'email')?.enabled && businessIsOpen;

    const phonePermission = useFieldMetadata('contact', 'phone_number')?.enabled && businessIsOpen;

    const faxPermission = useFieldMetadata('contact', 'fax')?.enabled && businessIsOpen;

    const formatPayload = payload => ({
        contacts: [
            {
                ...(emailPermission && { email: payload?.email ?? '' }),
                ...(phonePermission && {
                    phone_numbers: [payload?.phone ?? ''],
                }),
                ...(faxPermission && { fax: payload?.fax ?? '' }),
            },
        ],
    });

    const submitForm = () => {
        handleSubmit(payload => onSubmit(formatPayload(payload)))();
        trackSaveBusinessEditSection(SectionNames.CONTACT, colorState);
    };

    return (
        <>
            <EditForm
                title={t('contact')}
                description={t('contact_section_description')}
                colorState={colorState}
            >
                <Stack>
                    <ControlledTextInput
                        name="email"
                        type="email"
                        control={control}
                        label={t('email_extended')}
                        leftElement={['fa-envelope', IconPrefix.SOLID]}
                        leftElementType={LeftElementType.Icon}
                        {...useFormFieldFormatter('contact', 'email', watch, formatPayload, errors)}
                        dataTrackId="visibility_location__form_contact__email"
                    />
                    <ControlledTextInput
                        name="phone"
                        control={control}
                        onChangeValue={handlePhoneValue}
                        label={t('phone')}
                        leftElement={['fa-phone', IconPrefix.SOLID]}
                        leftElementType={LeftElementType.Icon}
                        {...useFormFieldFormatter(
                            'contact',
                            'phone_number',
                            watch,
                            formatPayload,
                            errors,
                        )}
                        dataTrackId="visibility_location__form_contact__phone"
                    />
                    <ControlledTextInput
                        name="fax"
                        control={control}
                        onChangeValue={handlePhoneValue}
                        label={t('fax')}
                        leftElement={['fa-fax', IconPrefix.SOLID]}
                        leftElementType={LeftElementType.Icon}
                        {...useFormFieldFormatter('contact', 'fax', watch, formatPayload, errors)}
                        dataTrackId="visibility_location__form_contact__fax"
                    />
                </Stack>
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={businessUpdate.isLoading}
                    hasError={!!businessUpdate.error}
                    onClick={submitForm}
                    dataTrackId="visibility_location__form_contact__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default ContactForm;
