import React, { FC } from 'react';

import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';

import {
    AlertBannerButton,
    AlertBannerContainer,
    AlertBannerContent,
    AlertBannerTitle,
    AlertBannerWrapper,
    AlertBannerWrapperWithNewSidebar,
    BackgroundIcon,
    StyledButton,
} from './AlertBanner.styled';

export type Props = {
    title: string;
    color: string;
    onClick?: () => void;
    buttonText?: string;
    fixed?: boolean;
    children?: React.ReactNode;
};

const AlertBanner: FC<Props> = ({
    title,
    color,
    onClick = undefined,
    buttonText = '',
    fixed = true,
    children,
}) => {
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const AlertBannerWrapperComponent = isNewSidebarEnabled
        ? AlertBannerWrapperWithNewSidebar
        : AlertBannerWrapper;

    return (
        <AlertBannerWrapperComponent fixed={fixed}>
            <AlertBannerContainer color={color}>
                <div>
                    <AlertBannerTitle>{title}</AlertBannerTitle>
                    {children && <AlertBannerContent>{children}</AlertBannerContent>}
                    {onClick && (
                        <AlertBannerButton color={color}>
                            <StyledButton
                                dataTrackId="alert_banner__button"
                                appearance="outlined"
                                variant="primary"
                                onClick={onClick}
                            >
                                {buttonText}
                            </StyledButton>
                        </AlertBannerButton>
                    )}
                </div>
                <BackgroundIcon>
                    <i className="fa-solid fa-info-circle" />
                </BackgroundIcon>
            </AlertBannerContainer>
        </AlertBannerWrapperComponent>
    );
};
export default AlertBanner;
