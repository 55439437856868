import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';

import { ColorState } from './types';

export default function useDescriptionSectionColor(): ColorState {
    // Hooks
    const { data: publisherErrors } = usePublisherErrors({});

    const descriptionShortPermission = useFieldMetadata('description', 'description_short')
        ?.enabled;
    const descriptionLongPermission = useFieldMetadata('description', 'description_long')?.enabled;

    const { data: business } = useBusiness();
    const isClosed = useIsBusinessClosed();

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // Disabled
    if (!descriptionShortPermission && !descriptionLongPermission) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (
        publisherErrors?.description?.description_long ||
        publisherErrors?.description?.description_short
    ) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields
    if (!business?.description_long || !business?.description_short) {
        return ColorState.MISSING_WARNING;
    }

    // OK
    return ColorState.OK;
}
