import React from 'react';

type Props = {
    width?: number;
    height?: number;
};

const BadgeHigh = React.forwardRef<SVGSVGElement>(
    ({ width = 128, height = 130 }: Props, ref) => (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 128 130"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M59.4555 17L81.1201 20.3206L71.0479 48.1914L88.6364 22.7557L102.564 35.8168L79.3009 54.2036L106.195 42.6794L111.185 60.168L82.4533 63.9314L111.185 70.5726L104.111 89.8322L79.3009 73.6592L97.2581 98.023L79.3009 107.321L71.0479 79.6713L72.0563 110.42L50.6127 109.092L60.8466 79.6713L42.6543 105.771L27.9531 90.7177L52.5935 73.6592L23.4214 84.0764L19 61.7176L49.4411 63.9314L20.1053 53.3054L29.3902 35.8168L52.5935 54.2036L34.9169 29.6183L50.6127 19.2137L60.8466 48.1914L59.4555 17Z"
                fill="url(#paint0_radial_22641_119212)"
            />
            <g
                style={{ mixBlendMode: 'color-burn' }}
                filter="url(#filter0_f_22641_119212)"
            >
                <path
                    d="M77.9848 43.4372C78.7181 44.5362 85.6796 54.4314 86.9481 56.5314C89.9263 61.4647 90.6208 65.9261 87.9 70.6347C85.9165 74.0666 83.9433 77.5046 81.9476 80.9303C79.5556 85.0404 75.9891 87.122 71.25 87.1587C67.2505 87.1914 63.2509 87.1444 59.2513 87.1587C54.4142 87.1751 50.7783 85.0914 48.3495 80.9303C46.3293 77.4699 44.3254 74.0012 42.344 70.5203C40.1563 66.6779 40.1502 61.9611 42.346 58.1227C44.3172 54.6827 52.3348 41.6049 55.215 41.3475C58.5629 40.8715 72.7841 40.3751 75.1026 41.3373C76.2403 41.5517 77.3168 42.4322 77.9848 43.4352V43.4372Z"
                    fill="#67E4B0"
                />
                <path
                    d="M65.1832 46.4033C67.2566 46.4033 69.3299 46.3992 71.4053 46.4033C74.0771 46.4115 76.0544 47.5677 77.3862 49.9108C79.4943 53.6204 81.6922 57.2791 83.7942 60.9928C85.0647 63.2378 85.0096 65.5421 83.7227 67.7789C81.6575 71.3722 79.5352 74.9348 77.4925 78.5403C76.0994 80.9998 74.0424 82.2356 71.2276 82.2356C67.2014 82.2356 63.1773 82.207 59.1512 82.2458C56.3119 82.2724 54.2671 81.0447 52.8618 78.6056C50.8844 75.1717 48.9235 71.7276 46.8501 68.3529C45.169 65.6197 45.1751 63.0233 46.8461 60.2901C48.9459 56.8582 50.9233 53.3528 52.9557 49.8822C54.2998 47.5902 56.269 46.3788 58.9612 46.3992C61.0345 46.4135 63.1079 46.4013 65.1832 46.4033Z"
                    fill="#C6FCE5"
                />
                <path
                    d="M58.4342 71.3927C59.3248 68.4388 56.986 66.4532 55.3252 64.2164C53.9893 62.4167 54.3427 60.7273 56.3895 59.9531C56.6142 59.8694 56.8409 59.7713 57.0738 59.7386C60.2931 59.2831 62.5196 57.5284 64.1497 54.4438C65.7225 51.4715 65.7593 51.4572 67.3567 54.4315C69.095 57.6652 73.2886 59.3873 76.5876 60.1431C78.9162 60.6783 79.3043 62.0674 77.899 64.1531C77.7948 64.3083 77.6906 64.4677 77.566 64.6004C75.3293 66.9925 74.3427 69.8238 74.8247 73.2189C74.9207 73.8971 74.9024 74.6448 74.7349 75.3025C74.3978 76.6324 73.5113 77.6088 72.2346 76.9306C68.4577 74.9287 64.8135 74.9777 61.0305 76.9306C59.7742 77.5782 58.6038 76.5343 58.4506 74.9348C58.3545 73.9278 58.4322 72.9002 58.4322 71.3906L58.4342 71.3927Z"
                    fill="#67E3B0"
                />
                <path
                    d="M56.7327 69.6868C57.6457 66.8555 55.2476 64.9496 53.542 62.8026C52.1713 61.0765 52.5329 59.4566 54.6348 58.713C54.8656 58.6313 55.0985 58.5394 55.3375 58.5087C58.6385 58.0716 60.9222 56.3883 62.5972 53.4304C64.2109 50.5807 65.9799 50.6114 67.6181 53.4651C69.4014 56.5681 71.9731 58.1717 75.3579 58.8969C77.7478 59.4096 78.1441 60.7415 76.702 62.7434C76.5957 62.8925 76.4895 63.0437 76.3608 63.1724C74.0669 65.4664 73.0537 68.1813 73.548 71.4375C73.6461 72.0871 73.6277 72.8041 73.4561 73.4353C73.1109 74.712 72.2019 75.6456 70.8905 74.998C67.0176 73.0778 63.2774 73.1248 59.3984 74.998C58.1094 75.619 56.9083 74.6181 56.7531 73.0839C56.655 72.1177 56.7347 71.1331 56.7347 69.6848L56.7327 69.6868Z"
                    fill="#8FEEC7"
                />
                <path
                    d="M75.2557 50.8423C73.7224 51.6117 72.6241 52.8476 71.9609 54.5499C71.6238 52.4316 70.6638 51.2774 68.6844 51.0037C70.4472 50.4562 71.8077 49.7004 71.9874 47.5555C72.3347 49.6003 73.4684 50.5257 75.2578 50.8443L75.2557 50.8423Z"
                    fill="#80EABF"
                />
                <path
                    d="M55.2762 68.4694C53.9852 68.5409 53.2519 69.2742 52.8863 70.7389C52.2449 68.7942 51.4196 68.9065 50.1838 68.6083C51.5483 67.9995 51.7873 67.8055 52.8863 65.9752C52.8863 67.4419 53.9852 67.8075 55.2762 68.4694Z"
                    fill="#80EABF"
                />
                <path
                    d="M79.8334 69.2519C79.8334 69.2519 79.0122 69.5522 78.6119 70.0669C78.2115 70.5817 78.1829 71.4908 78.1829 71.4908C78.1829 71.4908 77.9112 70.5041 77.3903 70.0669C76.8695 69.6298 76.1688 69.6604 76.1688 69.6604C76.1688 69.6604 76.9941 69.4439 77.3903 68.8454C77.7866 68.2468 77.7968 67.6238 77.7968 67.6238C77.7968 67.6238 78.0481 68.4756 78.6119 68.8454C79.1756 69.2151 79.8334 69.2519 79.8334 69.2519Z"
                    fill="#80EABF"
                />
                <path
                    d="M54.8268 52.3152C55.3845 53.0261 54.9004 53.6982 56.6142 53.9474C56.0116 54.5847 55.266 54.4315 54.9943 55.7573C54.5347 54.4315 54.1752 54.3314 53.0272 54.3273C54.5327 54.0638 54.5102 53.2018 54.8268 52.3132V52.3152Z"
                    fill="#80EABF"
                />
                <path
                    d="M60.5586 82.2377C60.0561 82.2418 59.5557 82.2459 59.0532 82.25C56.2138 82.2766 54.1691 81.0488 52.7637 78.6098C50.7864 75.1759 48.8254 71.7318 46.7521 68.3571C45.071 65.6239 45.0751 63.0254 46.748 60.2943C48.8459 56.8624 50.8232 53.357 52.8577 49.8863C54.2018 47.5923 56.1709 46.383 58.8632 46.4013C59.4188 46.4054 59.9764 46.4075 60.5321 46.4075C58.0318 46.4994 56.1811 47.6985 54.9004 49.8863C52.8659 53.357 50.8885 56.8624 48.7907 60.2943C47.1177 63.0254 47.1137 65.6239 48.7948 68.3571C50.8681 71.7318 52.8291 75.1759 54.8064 78.6098C56.1219 80.8916 57.9971 82.1131 60.5586 82.2377Z"
                    fill="#C6FCE5"
                />
            </g>
            <g clipPath="url(#clip0_22641_119212)">
                <path
                    d="M77.985 43.4372C78.7183 44.5362 85.6798 54.4314 86.9483 56.5314C89.9266 61.4647 90.6211 65.9261 87.9002 70.6347C85.9168 74.0666 83.9435 77.5046 81.9478 80.9303C79.5559 85.0404 75.9893 87.122 71.2503 87.1587C67.2507 87.1914 63.2511 87.1444 59.2516 87.1587C54.4145 87.1751 50.7785 85.0914 48.3497 80.9303C46.3295 77.4699 44.3257 74.0012 42.3442 70.5203C40.1565 66.6779 40.1504 61.9611 42.3463 58.1227C44.3175 54.6827 52.335 41.6049 55.2152 41.3475C58.5632 40.8715 72.7843 40.3751 75.1028 41.3373C76.2406 41.5517 77.3171 42.4322 77.985 43.4352V43.4372Z"
                    fill="#67E4B0"
                />
                <path
                    d="M65.1835 46.4033C67.2568 46.4033 69.3301 46.3992 71.4055 46.4033C74.0773 46.4115 76.0546 47.5677 77.3865 49.9108C79.4945 53.6204 81.6925 57.2791 83.7944 60.9928C85.0649 63.2378 85.0098 65.5421 83.7229 67.7789C81.6577 71.3722 79.5354 74.9348 77.4927 78.5403C76.0996 80.9998 74.0426 82.2356 71.2278 82.2356C67.2016 82.2356 63.1776 82.207 59.1514 82.2459C56.3121 82.2724 54.2674 81.0447 52.862 78.6056C50.8847 75.1717 48.9237 71.7276 46.8504 68.3529C45.1692 65.6197 45.1754 63.0233 46.8463 60.2901C48.9462 56.8582 50.9235 53.3528 52.956 49.8822C54.3 47.5902 56.2692 46.3788 58.9615 46.3992C61.0348 46.4135 63.1081 46.4013 65.1835 46.4033Z"
                    fill="#C6FCE5"
                />
                <path
                    d="M58.4343 71.3927C59.3249 68.4388 56.986 66.4533 55.3253 64.2164C53.9894 62.4167 54.3428 60.7274 56.3895 59.9531C56.6142 59.8694 56.841 59.7713 57.0738 59.7387C60.2931 59.2831 62.5196 57.5284 64.1497 54.4438C65.7226 51.4715 65.7593 51.4572 67.3567 54.4315C69.095 57.6652 73.2887 59.3873 76.5876 60.1431C78.9163 60.6783 79.3044 62.0674 77.899 64.1531C77.7948 64.3083 77.6907 64.4677 77.5661 64.6005C75.3293 66.9926 74.3427 69.8238 74.8248 73.2189C74.9208 73.8971 74.9024 74.6448 74.7349 75.3026C74.3979 76.6324 73.5113 77.6089 72.2346 76.9306C68.4577 74.9287 64.8136 74.9778 61.0305 76.9306C59.7743 77.5782 58.6038 76.5344 58.4506 74.9349C58.3546 73.9278 58.4322 72.9003 58.4322 71.3906L58.4343 71.3927Z"
                    fill="#67E3B0"
                />
                <path
                    d="M56.7328 69.6869C57.6459 66.8556 55.2478 64.9497 53.5422 62.8027C52.1715 61.0766 52.5331 59.4566 54.635 58.7131C54.8658 58.6314 55.0987 58.5394 55.3377 58.5088C58.6387 58.0716 60.9224 56.3884 62.5974 53.4305C64.2111 50.5808 65.9801 50.6114 67.6183 53.4652C69.4016 56.5682 71.9733 58.1717 75.358 58.8969C77.748 59.4097 78.1443 60.7415 76.7021 62.7435C76.5959 62.8926 76.4897 63.0438 76.361 63.1724C74.0671 65.4665 73.0539 68.1813 73.5482 71.4375C73.6463 72.0871 73.6279 72.8041 73.4563 73.4353C73.1111 74.7121 72.2021 75.6456 70.8907 74.9981C67.0178 73.0779 63.2776 73.1248 59.3985 74.9981C58.1096 75.6191 56.9085 74.6181 56.7533 73.084C56.6552 72.1178 56.7349 71.1331 56.7349 69.6848L56.7328 69.6869Z"
                    fill="#8FEEC7"
                />
                <path
                    d="M75.2559 50.8423C73.7225 51.6118 72.6242 52.8476 71.961 54.5499C71.624 52.4316 70.6639 51.2774 68.6846 51.0037C70.4474 50.4562 71.8078 49.7004 71.9876 47.5555C72.3348 49.6003 73.4685 50.5257 75.2579 50.8443L75.2559 50.8423Z"
                    fill="#80EABF"
                />
                <path
                    d="M55.2765 68.4694C53.9855 68.5409 53.2522 69.2742 52.8866 70.7389C52.2452 68.7942 51.4199 68.9065 50.1841 68.6083C51.5486 67.9995 51.7876 67.8055 52.8866 65.9752C52.8866 67.4419 53.9855 67.8075 55.2765 68.4694Z"
                    fill="#80EABF"
                />
                <path
                    d="M79.8335 69.2519C79.8335 69.2519 79.0124 69.5522 78.612 70.0669C78.2116 70.5817 78.183 71.4908 78.183 71.4908C78.183 71.4908 77.9114 70.5041 77.3905 70.0669C76.8696 69.6298 76.1689 69.6604 76.1689 69.6604C76.1689 69.6604 76.9942 69.4439 77.3905 68.8454C77.7868 68.2468 77.797 67.6238 77.797 67.6238C77.797 67.6238 78.0482 68.4756 78.612 68.8454C79.1758 69.2151 79.8335 69.2519 79.8335 69.2519Z"
                    fill="#80EABF"
                />
                <path
                    d="M54.8269 52.3152C55.3846 53.0261 54.9005 53.6982 56.6143 53.9474C56.0117 54.5847 55.2661 54.4315 54.9944 55.7573C54.5348 54.4315 54.1753 54.3314 53.0273 54.3273C54.5328 54.0638 54.5103 53.2018 54.8269 52.3132V52.3152Z"
                    fill="#80EABF"
                />
                <g style={{ mixBlendMode: 'multiply' }}>
                    <path
                        d="M60.5588 82.2378C60.0563 82.2419 59.5558 82.246 59.0533 82.25C56.214 82.2766 54.1693 81.0489 52.7639 78.6098C50.7866 75.1759 48.8256 71.7318 46.7523 68.3571C45.0711 65.6239 45.0752 63.0255 46.7482 60.2943C48.846 56.8624 50.8233 53.357 52.8578 49.8864C54.2019 47.5923 56.1711 46.383 58.8633 46.4014C59.419 46.4055 59.9766 46.4075 60.5322 46.4075C58.032 46.4994 56.1813 47.6986 54.9005 49.8864C52.866 53.357 50.8887 56.8624 48.7909 60.2943C47.1179 63.0255 47.1138 65.6239 48.7949 68.3571C50.8683 71.7318 52.8292 75.1759 54.8066 78.6098C56.1221 80.8916 57.9972 82.1132 60.5588 82.2378Z"
                        fill="#C6FCE5"
                    />
                </g>
            </g>
            <g filter="url(#filter1_d_22641_119212)">
                <path
                    d="M88.5739 35.2631L89.5135 37.8022L92.0526 38.7418L89.5135 39.6813L88.5739 42.2204L87.6343 39.6813L85.0952 38.7418L87.6343 37.8022L88.5739 35.2631Z"
                    fill="#98F4D2"
                />
            </g>
            <g filter="url(#filter2_d_22641_119212)">
                <ellipse
                    cx="92.0525"
                    cy="44.8295"
                    rx="0.869675"
                    ry="0.869674"
                    fill="#98F4D2"
                />
            </g>
            <g filter="url(#filter3_d_22641_119212)">
                <ellipse
                    cx="42.4812"
                    cy="89.1828"
                    rx="0.869674"
                    ry="0.869675"
                    fill="#98F4D2"
                />
            </g>
            <g filter="url(#filter4_d_22641_119212)">
                <path
                    d="M91.5279 87.913L90.6333 90.2898L91.7437 92.5607L89.5138 91.6072L87.3832 92.7908L88.2778 90.414L87.1674 88.143L89.3973 89.0966L91.5279 87.913Z"
                    fill="#98F4D2"
                />
            </g>
            <g filter="url(#filter5_d_22641_119212)">
                <path
                    d="M47.1746 33.9933L46.28 36.37L47.3904 38.641L45.1606 37.6875L43.03 38.871L43.9246 36.4943L42.8142 34.2233L45.044 35.1768L47.1746 33.9933Z"
                    fill="#98F4D2"
                />
            </g>
            <g filter="url(#filter6_d_22641_119212)">
                <path
                    d="M46.8295 90.0526L47.7691 92.5917L50.3082 93.5312L47.7691 94.4708L46.8295 97.0099L45.89 94.4708L43.3508 93.5312L45.89 92.5917L46.8295 90.0526Z"
                    fill="#98F4D2"
                />
            </g>
            <g filter="url(#filter7_d_22641_119212)">
                <path
                    d="M35.5238 71.7894L35.9936 73.0589L37.2631 73.5287L35.9936 73.9985L35.5238 75.2681L35.054 73.9985L33.7844 73.5287L35.054 73.0589L35.5238 71.7894Z"
                    fill="#98F4D2"
                />
            </g>
            <g filter="url(#filter8_d_22641_119212)">
                <path
                    d="M97.2706 57.8746L97.7404 59.1442L99.0099 59.614L97.7404 60.0838L97.2706 61.3533L96.8008 60.0838L95.5312 59.614L96.8008 59.1442L97.2706 57.8746Z"
                    fill="#98F4D2"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_22641_119212"
                    x="0.701172"
                    y="0.787231"
                    width="128.904"
                    height="126.383"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="20"
                        result="effect1_foregroundBlur_22641_119212"
                    />
                </filter>
                <filter
                    id="filter1_d_22641_119212"
                    x="81.6696"
                    y="31.8374"
                    width="13.8086"
                    height="13.8087"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.597353 0 0 0 0 0.956465 0 0 0 0 0.823346 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119212"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119212"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_d_22641_119212"
                    x="87.7572"
                    y="40.5342"
                    width="8.59056"
                    height="8.59062"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.597353 0 0 0 0 0.956465 0 0 0 0 0.823346 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119212"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119212"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter3_d_22641_119212"
                    x="38.1859"
                    y="84.8875"
                    width="8.59056"
                    height="8.59062"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.597353 0 0 0 0 0.956465 0 0 0 0 0.823346 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119212"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119212"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter4_d_22641_119212"
                    x="83.7418"
                    y="84.4874"
                    width="11.4275"
                    height="11.729"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.597353 0 0 0 0 0.956465 0 0 0 0 0.823346 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119212"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119212"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter5_d_22641_119212"
                    x="39.3886"
                    y="30.5676"
                    width="11.4275"
                    height="11.729"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.597353 0 0 0 0 0.956465 0 0 0 0 0.823346 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119212"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119212"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter6_d_22641_119212"
                    x="39.9252"
                    y="86.6269"
                    width="13.8086"
                    height="13.8087"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.597353 0 0 0 0 0.956465 0 0 0 0 0.823346 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119212"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119212"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter7_d_22641_119212"
                    x="30.3588"
                    y="68.3637"
                    width="10.3301"
                    height="10.33"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.597353 0 0 0 0 0.956465 0 0 0 0 0.823346 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119212"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119212"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter8_d_22641_119212"
                    x="92.1056"
                    y="54.449"
                    width="10.3301"
                    height="10.33"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.597353 0 0 0 0 0.956465 0 0 0 0 0.823346 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119212"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119212"
                        result="shape"
                    />
                </filter>
                <radialGradient
                    id="paint0_radial_22641_119212"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(65.8664 63.9314) rotate(90.9535) scale(46.4951 45.3252)"
                >
                    <stop stopColor="#98F4D2" stopOpacity="0.32" />
                    <stop offset="1" stopColor="#98F4D2" stopOpacity="0" />
                </radialGradient>
                <clipPath id="clip0_22641_119212">
                    <rect
                        width="49.1802"
                        height="46.7536"
                        fill="white"
                        transform="translate(40.5015 40.5856)"
                    />
                </clipPath>
            </defs>
        </svg>
    ),
);

export default BadgeHigh;
