import React, { useEffect, useState } from 'react';

import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useMutation, useQueryClient } from 'react-query';

import {
    GoogleVerificationStartData,
    GoogleVerificationStartReceivedData,
    V2FormattedLocationData,
} from 'app/api/types/account';

import api from 'app/api/v2/api_calls';
import Clickable from 'app/common/components/buttons/Clickable';
import {
    BUSINESS_LOCATIONS,
    GOOGLE_VERIFICATION_OPTIONS,
} from 'app/common/data/queryKeysConstants';

import { OptionList } from './OptionList';
import { ConfirmButton } from '../Fields/ConfirmButton.styled';
import { FloatingFooter, FooterChangeMethodWrapper } from '../Fields/FloatingFooter.styled';
import { SideTabContainer } from '../Fields/SideTabContainer.styled';
import { SubTitle, Title } from '../Fields/Title.styled';
import { useGoogleVerificationContext } from '../GoogleVerification.context';
import ConfirmationScreen from '../screens/ConfirmationScreen/ConfirmationScreen';

type OptionsProps = {
    location: V2FormattedLocationData;
};

const Options: React.FC<OptionsProps> = ({ location }) => {
    const { t } = useTranslation();
    const { setStore } = useGoogleVerificationContext();
    const queryClient = useQueryClient();

    const [optionSelected, setOptionSelected] = useState<GoogleVerificationStartData>();
    const [isOnConfirmationScreen, setIsOnConfirmationScreen] = useState<boolean>(false);
    const [isRetried, setIsRetried] = useState<boolean>(false);

    const {
        isLoading,
        isError,
        mutate: startVerification,
        data: startData,
    } = useMutation(
        (data: GoogleVerificationStartData) =>
            api.account.startGoogleVerification(location.id, data),
        {
            onSuccess: (response: GoogleVerificationStartReceivedData) => {
                if (response?.status === 'success') {
                    setStore((prev: any) => ({
                        ...prev,
                        locations: {
                            ...(prev.locations || []),
                            [location.id]: {
                                screen:
                                    optionSelected?.method === 'ADDRESS'
                                        ? 'postcard_sent'
                                        : 'complete',
                                optionSelected: optionSelected,
                            },
                        },
                    }));
                    queryClient.invalidateQueries(BUSINESS_LOCATIONS);
                    queryClient.resetQueries([GOOGLE_VERIFICATION_OPTIONS, location.id]);
                }
            },
        },
    );

    const isEmailOptionValid = () => {
        return !!(
            optionSelected?.method === 'EMAIL' &&
            optionSelected?.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(optionSelected.email)
        );
    };

    const isAddressOptionValid = () => {
        return optionSelected?.method === 'ADDRESS' && !optionSelected.full_name;
    };

    const isConfirmationButtonDisabled =
        isLoading || !optionSelected?.method || isEmailOptionValid() || isAddressOptionValid();

    const getConfirmButtonText = () => {
        if (isRetried) {
            return t('try_again');
        }

        switch (optionSelected?.method) {
            case 'SMS':
                return t('google_verification__methods__sms_send');
            case 'PHONE_CALL':
                return t('google_verification__methods__phone_call_send');
            case 'ADDRESS':
                return t('google_verification__methods__address_send');
            case 'EMAIL':
                return t('google_verification__methods__email_send');
        }
        return t('error');
    };

    const unselectVerification = () => {
        setIsRetried(false);
        setIsOnConfirmationScreen(false);
        setOptionSelected(undefined);
    };

    const confirmVerification = () => {
        if (optionSelected) startVerification(optionSelected);
    };

    useEffect(() => {
        if (!isLoading && (isError || startData?.status === 'error')) {
            if (!isRetried && startData?.message == 'start_error') setIsRetried(true);
            else
                setStore((prev: any) => ({
                    ...prev,
                    locations: {
                        ...(prev.locations || []),
                        [location.id]: {
                            screen: 'error',
                            errorMessage: startData?.message,
                        },
                    },
                }));
        }
    }, [startData, isLoading, isError]);
    return (
        <SideTabContainer>
            <Title>{t('google_verification__options__title')}</Title>

            {!isOnConfirmationScreen && (
                <>
                    <SubTitle>{t('google_verification__options__subtitle')}</SubTitle>
                    <OptionList
                        location={location}
                        optionSelected={optionSelected}
                        setOptionSelected={setOptionSelected}
                        setIsOnConfirmationScreen={setIsOnConfirmationScreen}
                    />
                </>
            )}
            {isOnConfirmationScreen && (
                <>
                    <ConfirmationScreen
                        location={location}
                        optionSelected={optionSelected}
                        setOptionSelected={setOptionSelected}
                    />
                    <FloatingFooter>
                        <ConfirmButton
                            dataTrackId="google_verification__methods__options_confirm_button"
                            variant={isRetried ? 'danger' : 'primary'}
                            disabled={isConfirmationButtonDisabled}
                            icon={isLoading ? ['fa-spinner fa-spin'] : undefined}
                            size="large"
                            shape="cube"
                            onClick={confirmVerification}
                        >
                            {getConfirmButtonText()}
                        </ConfirmButton>
                        <FooterChangeMethodWrapper>
                            <Text variant="bodyMRegular">
                                {t('google_verification__methods__change_label')}
                            </Text>
                            <Clickable onClick={unselectVerification}>
                                <Text variant="bodyMBold" color="#0085F2">
                                    {t('google_verification__methods__change_button')}
                                </Text>
                            </Clickable>
                        </FooterChangeMethodWrapper>
                    </FloatingFooter>
                </>
            )}
        </SideTabContainer>
    );
};

export default Options;
