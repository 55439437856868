import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba } from '@partoohub/branding';

export const LoadingRowContainer = styled.div`
    display: flex;
    height: 70px;
    margin-bottom: 16px;
    padding: 16px;
    box-shadow: 0 2px 16px rgba(20, 37, 66, 0.08);
    border-radius: 8px;
    align-items: center;
    gap: 64px;
`;

export const LoadingRowContent = styled.div`
    display: flex;
    flex-basis: 100%;
    background-color: ${convertToRgba(HEX_COLORS.blackberry, 0.12)};
    height: 14px;
`;
