import React from 'react';

export const CrossIcon = () => (
    <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.99813 4.99982L8.99304 2.00491C9.26873 1.72923 9.26873 1.28225 8.99304 1.00661C8.71736 0.730923 8.27042 0.730923 7.99474 1.00661L4.99979 4.00156L2.00484 1.00657C1.72916 0.730883 1.28222 0.730883 1.00654 1.00657C0.730893 1.28225 0.730893 1.72923 1.00654 2.00487L4.00149 4.99979L1.00654 7.99474C0.730893 8.27042 0.730893 8.7174 1.00654 8.99304C1.28222 9.26873 1.72916 9.26873 2.00484 8.99304L4.99979 5.99809L7.99474 8.99304C8.27038 9.26873 8.71736 9.26873 8.99304 8.99304C9.26873 8.71736 9.26873 8.27042 8.99304 7.99474L5.99813 4.99982Z"
        />
    </svg>
);
