import qs from 'query-string';
import { useQuery } from 'react-query';

import { useLocation } from 'react-router-dom';

import { Chatbots } from 'app/api/v2/api_calls/chatbotsApiCalls';
import { CHATBOT } from 'app/common/data/queryKeysConstants';

export const useChatbot = () => {
    const { search } = useLocation();
    const search_chatbot_id = qs.parse(search).chatbot_id;
    const chatbot_id =
        typeof search_chatbot_id === 'string' ? parseInt(search_chatbot_id) : undefined;
    return useQuery([CHATBOT, chatbot_id], () => Chatbots.getChatbot(chatbot_id), {
        enabled: !!chatbot_id,
    });
};
