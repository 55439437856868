import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';

import { ColorState } from './types';

export default function useCoverSectionColors(): ColorState {
    // Hooks
    const { data: business } = useBusiness();
    const coverUrl = business?.photos?.primary ?? '';

    const { data: publisherErrors } = usePublisherErrors({});

    const coverPermission = useFieldMetadata('photos', 'cover')?.enabled;
    const photosPermission = useFieldMetadata('photos', 'secondary')?.enabled;

    const isClosed = useIsBusinessClosed();

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // Disabled
    if (!(coverPermission && photosPermission)) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (publisherErrors?.photos?.cover) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields
    if (!coverUrl) {
        return ColorState.MISSING_WARNING;
    }

    // OK
    return ColorState.OK;
}
