import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import { useIsBusinessGlobal } from 'app/businessEditV2/hooks/editV2utils/useIsBusinessGlobal';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';

import { ColorState } from './types';
import useFieldMetadata from '../../permissions/useFieldMetadata';

export default function useAddressSectionColor(): ColorState {
    // Hooks
    const isGlobalBusiness = useIsBusinessGlobal();
    const isClosed = useIsBusinessClosed();
    const { data: publisherErrors } = usePublisherErrors({});
    const namePermission = useFieldMetadata('infos', 'name')?.enabled || false;
    const addressFullPermission = useFieldMetadata('infos', 'address_full')?.enabled || false;
    const zipCodePermission = useFieldMetadata('infos', 'zipcode')?.enabled || false;
    const cityPermission = useFieldMetadata('infos', 'city')?.enabled || false;
    const countryPermission = useFieldMetadata('infos', 'country')?.enabled || false;
    const adress2Permission = useFieldMetadata('infos', 'address2')?.enabled || false;
    const coordinatesPermission = useFieldMetadata('infos', 'coordinates')?.enabled || false;

    const permissionsList = isGlobalBusiness
        ? [namePermission, countryPermission]
        : [
              namePermission,
              addressFullPermission,
              zipCodePermission,
              cityPermission,
              countryPermission,
              adress2Permission,
              coordinatesPermission,
          ];

    const hasAnyPermission = permissionsList.some(permission => permission);

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // Disabled
    if (!hasAnyPermission) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    const publisherErrorFields = isGlobalBusiness
        ? ['name', 'country']
        : ['name', 'address_full', 'zipcode', 'city', 'country', 'coordinates'];
    const adresssPublisherErrors = publisherErrorFields.map(
        fieldName => publisherErrors?.infos?.[fieldName],
    );
    const hasAnyPublisherError = adresssPublisherErrors.some(error => error);
    if (hasAnyPublisherError) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields skipped for now for address section // Anders 10-10-2022

    // OK
    return ColorState.OK;
}
