import React, { useState } from 'react';

import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import useCategories from 'app/businessEditV2/hooks/business/useCategories';
import CategorySelectSplit, {
    Props,
} from 'app/businessEditV2/sections/CategoriesSection/CategorySelectSplit';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    country: string | undefined;
};

type OmitProps = 'onChange' | 'selectedOption' | 'options' | 't';

const ControlledCategorySelectSplit = ({
    name,
    control,
    country,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => {
    const [query, setQuery] = useState<string>('');
    const { data: options, isLoading } = useCategories(query, country);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ref: _ref, value, ...usedFields } }) => (
                <CategorySelectSplit
                    selectedOption={value}
                    {...props}
                    {...usedFields}
                    onInputChange={setQuery}
                    options={
                        options?.categories.map(category => ({
                            label: category.name,
                            value: category.gmb_id,
                        })) ?? []
                    }
                    isLoading={isLoading}
                />
            )}
        />
    );
};

export default ControlledCategorySelectSplit;
