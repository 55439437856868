import Clickable from 'app/common/components/buttons/Clickable';

import { StyledClickable } from './FilterButton.styled';

type Props = {
    active?: boolean;
    inactiveIcon?: string;
    activeIcon?: string;
    onClick?: () => void;
    handleClick?: () => void;
    handleErase?: () => void;
    text: string;
    dataTrack?: string;
};

const FilterButton = ({
    active = false,
    inactiveIcon,
    activeIcon,
    onClick,
    handleClick,
    handleErase,
    text,
    dataTrack,
}: Props) => {
    const handleInnerClick = (e: Event) => {
        e.stopPropagation();
        if (handleErase) handleErase();
    };

    return (
        <StyledClickable
            active={active}
            onClick={() => {
                if (handleClick) handleClick();
                if (onClick) onClick(); // ugly walkaround for ButtonWithSearchMenuFilterSelection
            }}
            dataTrack={dataTrack}
        >
            <span className="flex--fill margin_right--simple">{text}</span>
            {active &&
                (handleErase ? (
                    <Clickable onClick={handleInnerClick}>
                        <i className={`${activeIcon ?? 'fa fa-times'}`} />
                    </Clickable>
                ) : (
                    <i className={`${activeIcon ?? 'fa fa-caret-down'}`} />
                ))}
            {!active && <i className={`${inactiveIcon ?? 'fa fa-caret-down'}`} />}
        </StyledClickable>
    );
};

export default FilterButton;
