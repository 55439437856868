import LoadingView from 'app/common/components/LoadingView';
import useGroupsV2 from 'app/common/hooks/queries/useGroupsV2';

import GroupsSettingsEmptyPlaceholder from './GroupsSettingsEmptyPlaceholder';
import SectionList from './SectionList';

const GroupsSettingsContent = () => {
    const { query, sections } = useGroupsV2();

    if (query.isLoading) {
        return <LoadingView />;
    }

    if (sections?.length === 0) {
        return <GroupsSettingsEmptyPlaceholder />;
    } else {
        return <SectionList />;
    }
};

export default GroupsSettingsContent;
