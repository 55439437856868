import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import {
    ReviewCardContext,
    ReviewCardContextType,
} from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/ReviewCard';

import { StyledIconButton } from './ResponseButton.styled';

interface Props {
    disabled: boolean;
    canCancel: boolean;
    handleReply: (e: React.MouseEvent<HTMLButtonElement>) => void;
    handleUndo: () => void;
}

export default function ResponseButtons({ disabled, canCancel, handleReply, handleUndo }: Props) {
    const { t } = useTranslation();
    const { handleReply: replyInContext } = useContext<ReviewCardContextType>(ReviewCardContext);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        handleReply(event);
        replyInContext(event);
    };

    return (
        <>
            {canCancel && (
                <StrikeThroughButton color={'secondary'} text={t('undo')} onClick={handleUndo} />
            )}
            <StyledIconButton
                data-track="reviews__button_reply_review"
                disabled={disabled}
                // @ts-ignore
                icon={['fa-paper-plane', 'fa-solid']}
                onClick={handleClick}
            />
        </>
    );
}
