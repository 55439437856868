import {
    ReactNode,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import { CarouselContainer, CarouselScroller } from './Carousel.styled';

interface ContainerProps {
    className?: string;
    children: [ReactNode, ReactNode, ...ReactNode[]]; // Minimum of 2 children
}

export interface CarouselHandle {
    goNext: () => void;
    goBack: () => void;
}

const Carousel = forwardRef<CarouselHandle, ContainerProps>(({ className, children }, ref) => {
    const scrollerRef = useRef<HTMLDivElement>(null);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const nbItems = children.length;

    useEffect(() => {
        if (scrollerRef.current) {
            const items = scrollerRef.current.children as HTMLCollectionOf<HTMLElement>;
            for (let itemIndex = 0; itemIndex < items.length; itemIndex += 1) {
                items[itemIndex].style.left = `${itemIndex * 100}%`;
            }
        }
    }, [scrollerRef.current]);

    const updateCurrentIndexWrap = useCallback(
        (offset: number) => {
            if (!scrollerRef.current) {
                return;
            }
            const nextIndex = currentIndex + offset;
            const nextCard =
                nextIndex >= 0 ? nextIndex % nbItems : (nbItems + (nextIndex % nbItems)) % nbItems;

            const items = scrollerRef.current.children as HTMLCollectionOf<HTMLElement>;
            items[nextCard].style.left = `${100 * nextIndex}%`;

            scrollerRef.current.style.left = `${-100 * nextIndex}%`;

            setCurrentIndex(nextIndex);
        },
        [scrollerRef, currentIndex, nbItems, setCurrentIndex],
    );

    useImperativeHandle(
        ref,
        () => ({
            goNext: () => updateCurrentIndexWrap(1),
            goBack: () => updateCurrentIndexWrap(-1),
        }),
        [updateCurrentIndexWrap],
    );

    return (
        <CarouselContainer className={className}>
            <CarouselScroller ref={scrollerRef}>{children}</CarouselScroller>
        </CarouselContainer>
    );
});

export default Carousel;
