import { Icon, IconElement, Stack } from '@partoohub/ui';

import { StyledText } from './SelectableContentFilters.styled';

interface SelectableContent {
    text: string;
    icon?: IconElement;
    maxWidth?: string;
}

export const SelectableContentFilters = ({
    icon,
    text,
    maxWidth = '78px',
}: SelectableContent) => {
    return (
        <Stack
            gap="4px"
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            {icon && <Icon icon={icon} color="secondary" />}
            <StyledText variant="bodySBold" as="span" maxWidth={maxWidth}>
                {text}
            </StyledText>
        </Stack>
    );
};
