import { useEffect, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { Drawer } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from 'react-query';

import { V2FormattedLocationData } from 'app/api/types/account';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';

import { BUSINESS_LOCATIONS } from 'app/common/data/queryKeysConstants';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { CardList } from './LocationsResults.styled';
import { useGoogleVerificationContext } from '../GoogleVerification.context';
import Process from '../Process';
import LoadingVerificationCard from '../VerificationCard/LoadingVerificationCard';
import VerificationCard from '../VerificationCard/VerificationCard';

export const LocationsResults = () => {
    const { t } = useTranslation();
    const { setStore, locationQuery } = useGoogleVerificationContext();
    const [selectedLocation, setSelectedLocation] = useState<V2FormattedLocationData | undefined>(
        undefined,
    );
    const queryClient = useQueryClient();
    const [opened, setOpened] = useState<boolean>(false);
    const [queryLocationId, setQuerylocationId] = useStateQueryParams('location_id', true);

    const handleClick = (location: V2FormattedLocationData) => {
        setSelectedLocation(location);
        setOpened(true);
        setQuerylocationId(location.id);
    };

    const handleClose = () => {
        queryClient.invalidateQueries(BUSINESS_LOCATIONS);
        if (selectedLocation) {
            setStore((prev: any) => {
                const updatedLocations = {
                    ...prev.locations,
                    [selectedLocation.id]: undefined,
                };
                return {
                    ...prev,
                    locations: updatedLocations,
                };
            });
        }
        setSelectedLocation(undefined);
        setOpened(false);
        setQuerylocationId('');
    };

    const locations = locationQuery.data?.pages.flatMap(page => page.locations) ?? [];

    useEffect(() => {
        if (queryLocationId && !locationQuery.isLoading) {
            const matchingLocation = locations.find(
                (location: V2FormattedLocationData) =>
                    location.id === queryLocationId && location.addressDetails,
            );
            if (matchingLocation) {
                handleClick(matchingLocation);
            }
        }
    }, [locations, locationQuery, queryLocationId]);

    if (
        locationQuery.isError ||
        (locations.length === 0 &&
            locationQuery.status !== 'idle' &&
            !locationQuery.isLoading &&
            !locationQuery.isFetchingNextPage)
    ) {
        const illustrationElement: IllustrationLaptopElement = {
            type: IllustrationCategoryEnum.Laptop,
            name: IllustrationLaptopEnum.Happy,
        };
        return (
            <NotFoundTemplate
                show
                imgSrc={illustrationElement}
                title={t('google_verification__no_google_listing_found')}
                withButton={false}
            />
        );
    }

    return (
        <>
            <Drawer
                dataTrackId="app_google_verifications"
                isOpen={opened}
                onHide={handleClose}
                hideOnEsc={false}
                width="500px"
            >
                <Process selectedLocation={selectedLocation} handleClose={handleClose} />
            </Drawer>
            <CardList>
                {locationQuery.isLoading ? (
                    <>
                        <LoadingVerificationCard />
                        <LoadingVerificationCard />
                        <LoadingVerificationCard />
                        <LoadingVerificationCard />
                    </>
                ) : (
                    locations.map(location => (
                        <VerificationCard
                            key={location.id}
                            location={location}
                            onClick={() => handleClick(location)}
                        />
                    ))
                )}
            </CardList>
        </>
    );
};

export default LocationsResults;
