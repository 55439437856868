import { toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import api from 'app/api/v2/api_calls';
import {
    displayModalSelector,
    hideConfirm,
    pendingParamsSelector,
} from 'app/common/components/ConfirmModal/reducers/confirm';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { REPLY_TEMPLATE_LIST } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';
import { confirmModalSelector } from 'app/states/reducers';

export default function ConfirmDeleteReplyTemplateModal() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const hide = () => {
        dispatch(hideConfirm());
    };

    const { templateId } = pendingParamsSelector(useSelector(confirmModalSelector));

    const showModal = displayModalSelector(
        useSelector(confirmModalSelector),
        'delete_reply_template_modal',
    );

    const deleteTemplate = useMutation(
        (templateId: string) => {
            return api.review.deleteReplyTemplate(templateId).then(() => {
                return templateId;
            });
        },
        {
            onSuccess: () => queryClient.invalidateQueries([REPLY_TEMPLATE_LIST]),
            onError: () => {
                toast.error(null, t('error'));
            },
            onSettled: hide,
        },
    );

    return (
        <ConfirmModal
            modalFor="delete"
            show={showModal}
            title={t('delete_reply_template')}
            content={t('delete_reply_template_modal_text')}
            onConfirm={() => deleteTemplate.mutate(templateId.toString())}
            onHide={hide}
            confirmDisabled={deleteTemplate.isLoading}
            trackId={'reply_template_delete'}
        />
    );
}
