import { call, put, takeEvery } from 'redux-saga/effects';

import { V2AccountDetails } from 'app/api/types/account';
import api from 'app/api/v2/api_calls';
import formatGmbDetails from 'app/businessConnection/services/formatGmbDetails';
import { Saga } from 'app/common/types';
import {
    ACCOUNT_RESOURCES_DETAILS_REQUEST,
    GetAccountResourcesDetailsAction,
    getAccountResourcesDetailsFailure,
    getAccountResourcesDetailsSuccess,
} from 'app/states/knowledge/reducers/businessConnection/accounts';

function* getAccountResourceDetails({
    accountId,
    partnerResourceName,
}: GetAccountResourcesDetailsAction): Saga {
    try {
        const data = yield call(
            api.account.getAccountDetails,
            accountId,
            partnerResourceName,
        );
        const gmbDetails = formatGmbDetails(data as V2AccountDetails);
        yield put(getAccountResourcesDetailsSuccess(accountId, gmbDetails));
    } catch (error) {
        yield put(getAccountResourcesDetailsFailure(accountId, error));
    }
}

function* getAccountResourceDetailsSaga(): Saga {
    yield takeEvery(
        ACCOUNT_RESOURCES_DETAILS_REQUEST,
        getAccountResourceDetails,
    );
}

export default getAccountResourceDetailsSaga;
