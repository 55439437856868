import { useContext, useState } from 'react';

import { Filters, Option, Stack } from '@partoohub/ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'app/config';

import { TextContainer } from './CustomField.styled';
import NextBackButtons from '../../components/NextBackButtons/NextBackButtons';
import { NewPostContext, NewPostContextType } from '../../context/NewPost';
import { NoLinkMessage } from '../../forms/News.styled';
import { getCustomLinkOptions } from '../../PostConstants';

const CustomField = () => {
    const { t } = useTranslation();
    const {
        updateField,
        formFields: { buttonTypeGoogle },
        setNextStep,
        setPreviousStep,
    } = useContext<NewPostContextType>(NewPostContext);

    const [error, setError] = useState(false);

    const shouldDisplayCustomPhoneWarningGoogle = buttonTypeGoogle?.value === 'call';

    const { watch } = useFormContext();
    const calculateDisabled = () => watch('content_link') !== 'no_link' && !buttonTypeGoogle;

    const options = getCustomLinkOptions(t).filter(({ value }) => {
        if (watch('content_link') === 'no_link') {
            return value === 'call';
        } else if (watch('content_link') !== 'no_link') {
            return value !== 'call';
        } else {
            return true;
        }
    }) as Option[];

    const sections = [{ options }];

    const handleChange = (value: Record<string, boolean>) => {
        const recordValue = Object.keys(value).find(key => !!value[key]);
        if (recordValue) {
            updateField({
                field: 'buttonTypeGoogle',
                value: recordValue,
            });
            setError(false);
        }
    };

    return (
        <Stack>
            <TextContainer>
                <img
                    width={18}
                    height={18}
                    src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/google_no_empty_space.svg`}
                    alt=""
                />
                <p>{t('posts_custom_google_content')}</p>
            </TextContainer>
            <Filters
                dataTrackId="posts_add_button_step"
                menuListArray={sections}
                selectedItems={buttonTypeGoogle ? { [buttonTypeGoogle]: true } : {}}
                position="bottom"
                onChange={handleChange}
                placeholder={t('posts_add_button_step')}
                disableReset
                error={!!error}
                required={watch('content_link') !== 'no_link'}
                notice={error ? t('fields_required') : ''}
            />

            {shouldDisplayCustomPhoneWarningGoogle && (
                <NoLinkMessage>{t('posts_custom_google_field_link_save')}</NoLinkMessage>
            )}

            <NextBackButtons
                disabled={calculateDisabled()}
                onNext={() => {
                    if (
                        (watch('content_link') !== 'no_link' && buttonTypeGoogle) ||
                        watch('content_link') === 'no_link'
                    ) {
                        setNextStep();
                    } else {
                        setError(true);
                    }
                }}
                onPrevious={() => {
                    setPreviousStep();
                }}
            />
        </Stack>
    );
};

export default CustomField;
