import styled from '@emotion/styled';

export const InfoSectionExtensionGMBContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px 40px;
    gap: 32px;
    overflow-y: auto;
    overscroll-behavior: contain;
`;
