import { useSettingIntegrationsSummary } from '../Integrations/useSettingIntegrationsSummary';
import { useSettingMainSummary } from '../Main/useSettingMainSummary';
import { useSettingMessagesSummary } from '../Messages/useSettingMessagesSummary';
import { useSettingPlatformsSummary } from '../Platforms/useSettingPlatformsSummary';
import { useSettingReviewsSummary } from '../Reviews/useSettingReviewsSummary';
import { useSettingTeamSummary } from '../Team/useSettingSummaryTeam';

export const useIsLoadingSettingsSummary = () => {
    const { isLoading: isLoadingMain } = useSettingMainSummary();
    const { isLoading: isLoadingTeam } = useSettingTeamSummary();
    const { isLoading: isLoadingMessages } = useSettingMessagesSummary();
    const { isLoading: isLoadingReviews } = useSettingReviewsSummary();
    const { isLoading: isLoadingPlatforms } = useSettingPlatformsSummary();
    const { isLoading: isLoadingIntegrations } = useSettingIntegrationsSummary();

    return (
        isLoadingMain ||
        isLoadingTeam ||
        isLoadingMessages ||
        isLoadingReviews ||
        isLoadingPlatforms ||
        isLoadingIntegrations
    );
};
