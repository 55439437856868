import { Stack } from '@partoohub/ui';

import BusinessModalButton from 'app/common/components/businessModal';
import { FilterBusinessModalButton } from 'app/common/components/businessModal/components/BusinessModal/FilterBusinessModalButton/FilterBusinessModalButton';
import { IS_BELOW_MOBILE, useDeviceType } from 'app/hooks';

import Flexbox from 'app/styles/utils/flexbox';

import { DateFilter } from './DateFilter';
import { FilterButton } from './FilterButton';
import { SearchFilter } from './SearchFilter';

export function FilterSection() {
    const { isBelowProvidedDevices: isMobile } = useDeviceType(IS_BELOW_MOBILE);

    return (
        <Stack direction="column">
            {!isMobile ? (
                <Flexbox gap="8px">
                    <BusinessModalButton
                        key="business-modal-key"
                        resetReducerOnUnmount={false}
                        isSelectionMode
                        button={
                            <div>
                                <FilterBusinessModalButton />
                            </div>
                        }
                    />
                    <DateFilter />
                    <SearchFilter />
                    <FilterButton />
                </Flexbox>
            ) : (
                <Flexbox flexDirection="column" gap="8px" flex="1">
                    <BusinessModalButton
                        key="business-modal-key"
                        resetReducerOnUnmount={false}
                        isSelectionMode
                        button={
                            <div>
                                <FilterBusinessModalButton />
                            </div>
                        }
                    />
                    <SearchFilter />
                    <Flexbox gap="8px" flex="1">
                        <DateFilter isMobile={isMobile} />
                        <FilterButton />
                    </Flexbox>
                </Flexbox>
            )}
        </Stack>
    );
}
