import { useState } from 'react';

import { Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { PasswordChecks } from 'app/common/components/PasswordChecks/PasswordChecks';
import { PasswordField } from 'app/common/designSystem/components/atoms/PasswordField/PasswordField';
import isPasswordValid, {
    passWordChecker,
    passwordErrorHandler,
} from 'app/common/services/passwordChecker';
import {
    LoginFormButton,
    LoginFormContainer,
    LoginFormMessage,
} from 'app/connection/components/LoginForm/LoginForms.styled';
import {
    globalErrorSelector,
    hasErrors,
    passwordChecksErrorSelector,
    passwordConfirmationSelector,
    passwordSelector,
    resetPasswordRequest,
    updateResetPassword,
    updateResetPasswordConfirmation,
} from 'app/states/connection/reducers/resetPassword';
import { AppState } from 'app/states/reducers';

export const ResetPasswordForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [showPasswordChecker, setShowPasswordChecker] = useState<boolean>(false);
    const resetPassword = useSelector((state: AppState) => state.resetPassword);

    const password = passwordSelector(resetPassword);
    const confirmationPassword = passwordConfirmationSelector(resetPassword);

    const pwdNotEquals = confirmationPassword.length > 5 && confirmationPassword !== password;
    const errors = globalErrorSelector(resetPassword) || '';
    const passwordError = passwordChecksErrorSelector(resetPassword)
        ? passwordErrorHandler(passwordChecksErrorSelector(resetPassword))
        : '';

    const errorConfirmationPassword = pwdNotEquals || hasErrors(resetPassword);
    const noticeConfirmationPassword = t(
        pwdNotEquals ? 'password and its confirmation are not equal' : errors || passwordError,
    );

    const disabled = !(password === confirmationPassword && isPasswordValid(password));

    const variant = disabled ? 'secondary' : 'primary';

    const handleChangePassword = (value: string) => dispatch(updateResetPassword(value));

    const handleChangeConfirmationPassword = (value: string) =>
        dispatch(updateResetPasswordConfirmation(value));
    return (
        <LoginFormContainer>
            <LoginFormMessage>
                <i className="fa-solid fa-info-circle" />
                {t('reset_password__explanation')}
            </LoginFormMessage>
            <Stack>
                <Stack gap={showPasswordChecker ? '16px' : '0px'}>
                    <PasswordField
                        dataTrackId="password"
                        value={password}
                        onChange={handleChangePassword}
                        label={t('password')}
                        error={hasErrors(resetPassword)}
                        onFocus={() => setShowPasswordChecker(true)}
                        onBlur={() => setShowPasswordChecker(false)}
                    />
                    <PasswordChecks
                        displayChecker={showPasswordChecker}
                        {...passWordChecker(password ?? '')}
                    />
                </Stack>
                <PasswordField
                    dataTrackId="confirm_password"
                    label={t('confirm_password')}
                    value={confirmationPassword}
                    onChange={handleChangeConfirmationPassword}
                    error={errorConfirmationPassword}
                    notice={noticeConfirmationPassword}
                />
                <LoginFormButton
                    variant={variant}
                    shape="cube"
                    size="large"
                    full
                    onClick={() => dispatch(resetPasswordRequest())}
                    dataTrackId="sso_sign_in_button"
                    type="submit"
                    disabled={variant === 'secondary'}
                >
                    {t('reset_password')}
                </LoginFormButton>
            </Stack>
        </LoginFormContainer>
    );
};
