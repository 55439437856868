import useAttributes from 'app/businessEditV2/hooks/attributes/attributesMetadata/useAttributes';
import { ATTRIBUTES_URL_TYPES } from 'app/businessEditV2/utils/attributes';

export default function useFormattedAttributes() {
    const { data: attributesData } = useAttributes();

    return (
        attributesData?.groups
            ?.map(group => ({
                ...group,
                attributes: group.attributes.filter(
                    attribute => !ATTRIBUTES_URL_TYPES.includes(attribute.type),
                ),
            }))
            .filter(group => group.attributes.length > 0) ?? []
    );
}
