import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';

type Props = {
    title: string;
    subtitle: string;
};

export const NotFoundPlaceholder = ({ title, subtitle }: Props) => {
    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };
    return (
        <NotFoundTemplate
            withButton={false}
            show
            imgSrc={illustrationElement}
            title={title}
            subtitle={subtitle}
        />
    );
};
