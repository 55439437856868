import { useState } from 'react';

import { Button, Stack, Switch, Text, TextArea } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import ModalValidationRibbon from 'app/common/components/ModalValidationRibbon';
import {
    CustomFieldsJsonEditViewSwitch,
    CustomFieldsModalContent,
    CustomFieldsModalContentTop,
    CustomFieldsModalDescription,
    CustomFieldsModalTitle,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/sections/CustomFieldsModals/CustomFieldsModals.styled';

import SelectImageValueJsonEdit from './SelectImageValueJsonEdit';

type Props = {
    value: string;
    saveValue: (arg0: string) => void;
    hideModal: () => void;
};

const isSimpleJson = (value: string): boolean => {
    // Check that is object with string values
    try {
        const parsedValue = JSON.parse(value);
        if (typeof parsedValue !== 'object') {
            return false;
        }

        for (const key in parsedValue) {
            if (typeof parsedValue[key] !== 'string') {
                return false;
            }
        }
        return true;
    } catch (e) {
        return false;
    }
};

const SelectImageValueModal = ({ value, saveValue, hideModal }: Props) => {
    const { t } = useTranslation();

    const [newValue, setNewValue] = useState<string>(value);
    const [simpleMode, setSimpleMode] = useState<boolean>(() => isSimpleJson(value));

    return (
        <CustomFieldsModalContent>
            <CustomFieldsModalContentTop>
                <CustomFieldsModalTitle variant="heading4">
                    {t('associate_data')}
                </CustomFieldsModalTitle>
                <CustomFieldsModalDescription color="secondary">
                    {t('select_image_modal_description')}
                </CustomFieldsModalDescription>
                {(isSimpleJson(newValue) || newValue === '') && (
                    <CustomFieldsJsonEditViewSwitch>
                        <Switch
                            checked={simpleMode}
                            name="simpleMode"
                            onChange={() => setSimpleMode(!simpleMode)}
                            dataTrackId="select_image_value__simple_mode"
                            label={t(
                                simpleMode
                                    ? 'custom_fields__json_edit__toggle__to_json_mode'
                                    : 'custom_fields__json_edit__toggle__to_simple_mode',
                            )}
                        />
                    </CustomFieldsJsonEditViewSwitch>
                )}
                {simpleMode ? (
                    <SelectImageValueJsonEdit value={newValue} saveValue={setNewValue} />
                ) : (
                    <TextArea
                        maxRows={10}
                        value={newValue}
                        onChange={setNewValue}
                        label={t('value')}
                        dataTrackId={'value'}
                    />
                )}
            </CustomFieldsModalContentTop>
            <Stack direction="row" style={{ padding: '0 16px 16px 16px' }}>
                <Button
                    dataTrackId="select_image_value__cancel"
                    onClick={hideModal}
                    variant="secondary"
                    appearance="outlined"
                    size="large"
                    full
                >
                    <Text variant="bodyLBold" as="span" color="default">
                        {t('undo')}
                    </Text>
                </Button>
                <ModalValidationRibbon
                    handleClick={() => saveValue(newValue)}
                    state="enabled"
                    text="confirm"
                    noIcon
                />
            </Stack>
        </CustomFieldsModalContent>
    );
};

export default SelectImageValueModal;
