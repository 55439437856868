import { toast } from '@partoohub/ui';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import messagingApi from 'app/api/v2/api_calls/messagingApiCalls';
import { ConversationMessage, MESSAGE_STATUS } from 'app/states/messaging';

import queryClient from 'app/states/queryClient';

import { moveMessageToHead, updateMessage } from '../services/handleMessagesCache';

export const usePostMessageRetry = (conversationId: number, message: ConversationMessage) => {
    const { t } = useTranslation();

    const handlePostMessageRetryRequest = () => {
        const retryMessage = {
            ...message,
            status: MESSAGE_STATUS.SENDING,
            date: dayjs().format('YYYY-MM-DD HH:mm:ss.SSSSSSZ'),
        };
        moveMessageToHead(queryClient, conversationId, retryMessage);
    };

    const handlePostMessageRetryFailure = () => {
        toast.error(null, t('api_key_manager_snackbar_error'));
        const updatedMessage = { ...message, status: MESSAGE_STATUS.FAIL };
        updateMessage(queryClient, conversationId, updatedMessage);
    };

    const handlePostMessageRetrySuccess = () => {
        const updatedMessage = { ...message, status: MESSAGE_STATUS.SUCCESS };
        updateMessage(queryClient, conversationId, updatedMessage);
    };

    return useMutation(() => messagingApi.retryToSendMessage(message.id!), {
        onMutate: handlePostMessageRetryRequest,
        onError: handlePostMessageRetryFailure,
        onSuccess: handlePostMessageRetrySuccess,
    });
};
