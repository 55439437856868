import { ChartType, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Tooltip } from '@partoohub/ui';
import { InteractionMode, PointStyle } from 'chart.js';
import { useTranslation } from 'react-i18next';

import type { KeywordType, OverviewType } from 'app/api/types/competitiveBenchmark';

import LoadingSpinner from 'app/common/components/loaders/LoadingSpinner';

import { ChartContainer, ChartHeader, OverviewContainer, StyledChart } from './Overview.styled';
import { useCompetitiveOverview } from '../../hooks/useCompetitiveOverview';
import { computeOverviewChartData, getOneYearDateRange } from '../../utils/overview';

type OverviewProps = {
    keyword: KeywordType;
    currentDate: Date;
    selection: OverviewType;
    colors: Record<string, string>;
};

const CHART_OPTIONS = {
    scales: {
        x: {
            grid: { display: true, lineWidth: 0.5, drawTicks: false },
            ticks: { font: { size: 10 } },
        },
        y: {
            grid: { display: false },
            beginAtZero: true,
            ticks: {
                callback: (value: string | number) => (Number(value) % 1 ? undefined : value),
                font: { size: 10 },
            },
        },
    },
    plugins: {
        legend: {
            display: false,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: {},
    elements: {
        point: {
            pointStyle: false as PointStyle,
        },
        line: {
            tension: 0.3,
        },
    },
    interaction: {
        intersect: false,
        mode: 'nearest' as InteractionMode,
        axis: 'xy',
    },
    spanGaps: true,
};

export const Overview = ({ keyword, currentDate, selection, colors }: OverviewProps) => {
    const [startDate, endDate] = getOneYearDateRange(currentDate);
    const { overview, isLoading } = useCompetitiveOverview(keyword.keyword, startDate, endDate);

    const { i18n, t } = useTranslation();
    const data = computeOverviewChartData(overview, selection, i18n.language, colors);

    return (
        <OverviewContainer>
            <ChartHeader variant="heading6" as="span">
                {t('key_figures_word')}
                <Tooltip text={t('tooltip_key_figures')}>
                    <Icon
                        icon={[FontAwesomeIconsPartooUsed.faCircleInfo, IconPrefix.LIGHT]}
                        iconSize="16px"
                    />
                </Tooltip>
            </ChartHeader>
            <ChartContainer>
                {isLoading ? (
                    <LoadingSpinner fontSize="1em" />
                ) : (
                    <StyledChart
                        type={ChartType.Line}
                        dataTrackId={''}
                        data={data}
                        options={CHART_OPTIONS}
                    />
                )}
            </ChartContainer>
        </OverviewContainer>
    );
};
