import { ChangeEventHandler, useRef } from 'react';

import { css } from '@emotion/react';
import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useImagesUploaderContext } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/hooks/useImagesUploaderContext';
import { preventCardOpen } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/utils';
import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';

import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';

import { ActionButtonWrapper } from './ImageUploaderRowHeader.styled';

interface ImageEditProps {
    imageIndex: number;
    onFileUpload: ChangeEventHandler<HTMLInputElement>;
}
export const ImageEdit = ({ imageIndex, onFileUpload }: ImageEditProps) => {
    const { t } = useTranslation();
    const menuOptions = [
        {
            name: 'edit',
            value: 'edit',
            label: (
                <MenuLabelItem text={t('edit_image')} icon={['fa-pen-clip', IconPrefix.REGULAR]} />
            ),
        },
        {
            name: 'delete',
            value: 'delete',
            label: (
                <MenuLabelItem
                    text={t('delete')}
                    icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                    color="danger"
                />
            ),
        },
    ];

    const inputFile = useRef<HTMLInputElement | null>(null);
    const { deleteImage } = useImagesUploaderContext();

    const onMenuClick = (value: string) => {
        if (value === 'edit') {
            onEdit();
        } else if (value === 'delete') {
            deleteImage(imageIndex);
        }
    };

    const onEdit = () => {
        if (inputFile?.current) {
            inputFile.current.click();
        }
    };

    return (
        <ActionButtonWrapper onClick={preventCardOpen}>
            <input
                type="file"
                accept="image/*"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={onFileUpload}
            />
            <ActionButtonWithMenu
                dataTrackId=""
                icon={[FontAwesomeIconsPartooUsed.faEllipsisVertical, IconPrefix.SOLID]}
                options={menuOptions}
                // Align menu to the right
                cssMenu={css`
                    min-width: 100px;
                    top: 60px;
                    right: -8px;
                    left: unset;
                `}
                onMenuClick={onMenuClick}
            />
        </ActionButtonWrapper>
    );
};
