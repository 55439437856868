// ACTION TYPES
const SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL';
const HIDE_CONFIRM_MODAL = 'HIDE_CONFIRM_MODAL';
const UPDATE_INPUT_PARAM = 'UPDATE_INPUT_PARAM';
const UPDATE_CONFIRMATION_TEXT_TYPED = 'UPDATE_CONFIRMATION_TEXT_TYPED';

// ACTION FLOW TYPES
type ShowConfirmAction = {
    type: 'SHOW_CONFIRM_MODAL';
    id: string;
    pendingParams: Record<string, any>;
    inputParamKeys: Array<string>;
};

type HideConfirmAction = {
    type: 'HIDE_CONFIRM_MODAL';
};

type UpdateInputParamAction = {
    type: 'UPDATE_INPUT_PARAM';
    key: string;
    value: string;
};

type UpdateConfirmationTextTypedAction = {
    type: 'UPDATE_CONFIRMATION_TEXT_TYPED';
    confirmationTextTyped: string;
};

export type ConfirmState = {
    displayModal: string | null;
    pendingParams: Record<string, any>;
    inputParamKeys: Array<string>;
    confirmationTextTyped: string;
};

type ConfirmAction =
    | ShowConfirmAction
    | HideConfirmAction
    | UpdateInputParamAction
    | UpdateConfirmationTextTypedAction;

// ACTION CREATORS
export const showConfirm = (
    id: string,
    pendingParams: Record<string, any>,
    inputParamKeys: Array<string> = [],
): ShowConfirmAction => ({
    type: SHOW_CONFIRM_MODAL,
    id,
    pendingParams,
    inputParamKeys,
});

export const hideConfirm = (): HideConfirmAction => ({
    type: HIDE_CONFIRM_MODAL,
});

export const updateInputParam = (
    key: string,
    value: string,
): UpdateInputParamAction => ({
    type: UPDATE_INPUT_PARAM,
    key,
    value,
});

export const updateConfirmationTextTyped = (
    confirmationTextTyped: string,
): UpdateConfirmationTextTypedAction => ({
    type: UPDATE_CONFIRMATION_TEXT_TYPED,
    confirmationTextTyped,
});

// INITIAL STATES
const initialState = {
    displayModal: null,
    pendingParams: {},
    inputParamKeys: [],
    confirmationTextTyped: '',
};

// REDUCER
const confirmReducer = (
    state: ConfirmState = initialState,
    action: ConfirmAction,
): ConfirmState => {
    switch (action.type) {
        case SHOW_CONFIRM_MODAL:
            return {
                ...state,
                displayModal: action.id,
                pendingParams: action.pendingParams,
                inputParamKeys: action.inputParamKeys,
            };

        case HIDE_CONFIRM_MODAL:
            return initialState;

        case UPDATE_INPUT_PARAM:
            return {
                ...state,
                pendingParams: {
                    ...state.pendingParams,
                    [action.key]: action.value,
                },
            };

        case UPDATE_CONFIRMATION_TEXT_TYPED:
            return {
                ...state,
                confirmationTextTyped: action.confirmationTextTyped,
            };

        default:
            return state;
    }
};

// SELECTORS
export const displayModalSelector = (
    { displayModal }: ConfirmState,
    id: string,
): boolean => displayModal === id;

export const pendingParamsSelector = ({
    pendingParams,
}: ConfirmState): Record<string, any> => pendingParams;

export const inputParamKeysSelector = ({ inputParamKeys }: ConfirmState): any =>
    inputParamKeys;

export const inputParamSelector = (
    { pendingParams }: ConfirmState,
    key: string,
): any => pendingParams[key];

export const confirmationTextTypedSelector = ({
    confirmationTextTyped,
}: ConfirmState): string => confirmationTextTyped;

export default confirmReducer;
