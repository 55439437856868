import { PropsWithChildren, ReactNode } from 'react';

import { Button, Stack, Text } from '@partoohub/ui';

interface Props {
    action?: () => void;
    description?: string;
}

export const ToastWithAction = ({ description, children, action }: PropsWithChildren<Props>) => {
    return (
        <Stack justifyContent="left" gap="4px">
            {description && (
                <Text variant="bodyMRegular" as="span" color="secondary">
                    {description}
                </Text>
            )}
            {children && (
                <Button
                    dataTrackId="toast_with_action"
                    onClick={action}
                    variant="primary"
                    appearance="text"
                >
                    {children}
                </Button>
            )}
        </Stack>
    );
};

export const buildToastAction = (
    description?: string,
    children?: ReactNode | string,
    action?: () => void,
) => {
    return (
        <ToastWithAction action={action} description={description}>
            {children}
        </ToastWithAction>
    );
};
