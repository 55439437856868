import { useEffect, useRef, useState } from 'react';

import { useTheme } from '@emotion/react';
import { Button, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Text } from '@partoohub/ui';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useWebsocket from 'app/common/hooks/useWebsocket';
import { SendMessageIcon } from 'app/pages/settings/Chatbot/assets/send-message-icon';
import {
    ChatbotFrame,
    ChatbotFrameContent,
    ChatbotFrameFooter,
    ChatbotFrameHeader,
    JimHeader,
    PreloadTypingIndicator,
    SendMessageButton,
    TypingIndicator,
} from 'app/pages/settings/Chatbot/components/Preview/ChatbotPreview.styled';
import { useChatbot } from 'app/pages/settings/Chatbot/hooks/useChatbot';
import { useChatbotPreviewRequest } from 'app/pages/settings/Chatbot/hooks/useChatbotPreviewRequest';
import { ChatbotMessage, ChatbotPreviewWSEvent } from 'app/pages/settings/Chatbot/type/chatbot';

import { MessageBubbleContainer } from './ChatbotPreviewMessageBubble';

import { JimIcon } from '../../assets/jim-icon';

export const ChatbotPreview = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { control, handleSubmit, reset, watch } = useForm();
    const chatbotInputRef = useRef<HTMLTextAreaElement>(null);

    const [isJimTyping, setIsJimTyping] = useState(false);
    const [disableMessageInput, setDisableMessageInput] = useState(false);

    const [messageList, setMessageList] = useState<ChatbotMessage[]>([]);

    const businessesFilters = useBusinessModalFilters();

    const chatbotPreviewRequestMutation = useChatbotPreviewRequest(
        businessesFilters.business__in?.split(',')[0] ?? undefined,
    );
    const chatbot = useChatbot();

    const messageInput = watch('messageInput');

    useWebsocket(
        'chatbot-preview-message-event',
        (data: ChatbotPreviewWSEvent) => {
            if (chatbotPreviewRequestMutation.data?.uuid !== data.uuid) {
                return;
            }
            const messages = [...messageList, data.response];
            setMessageList(messages);
            setIsJimTyping(false);
            setDisableMessageInput(false);
        },
        [messageList, chatbotPreviewRequestMutation],
        undefined,
    );

    useEffect(() => {
        if (!disableMessageInput) {
            chatbotInputRef.current?.focus();
        }
    }, [disableMessageInput]);

    useEffect(() => {
        if (!chatbotInputRef.current) {
            return;
        }
        chatbotInputRef.current.style.height = 'auto';
        chatbotInputRef.current.style.height = `${chatbotInputRef.current?.scrollHeight}px`;
    }, [messageInput]);

    const onEnterKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            if (!event.shiftKey) {
                handleSubmit(onSendMessage)();
                event.preventDefault();
            }
        }
    };

    const onSendMessage = () => {
        const message: ChatbotMessage = { content: messageInput, role: 'user' };
        const messages = [...messageList, message];
        setMessageList(messages);
        setDisableMessageInput(true);
        reset({ messageInput: '' });
        chatbotPreviewRequestMutation.mutate(messages);
    };

    // Show typing indicator after 1 second.
    // Makes the chatbot experience more realistic.
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (disableMessageInput) {
            timeout = setTimeout(() => {
                setIsJimTyping(true);
            }, 1300);
        }
        return () => clearTimeout(timeout);
    }, [disableMessageInput]);

    const onResetConversation = () => {
        setMessageList([]);
        setIsJimTyping(false);
        setDisableMessageInput(false);
    };

    // Add scroll to bottom when new message is added.
    const chatbotFrameContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (chatbotFrameContentRef.current) {
            chatbotFrameContentRef.current.scroll({
                top: chatbotFrameContentRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [messageList, isJimTyping]);

    return (
        <ChatbotFrame>
            <ChatbotFrameHeader>
                <JimHeader>
                    <Icon iconSize="24px" icon={<JimIcon />} />

                    <Text variant="heading6" color={theme.colors.slate.initial}>
                        Jim
                    </Text>
                </JimHeader>

                <Button
                    dataTrackId=""
                    appearance="text"
                    variant="secondary"
                    size="small"
                    onClick={onResetConversation}
                    icon={
                        <Icon
                            icon={[FontAwesomeIconsPartooUsed.faArrowsRotate, IconPrefix.SOLID]}
                            iconSize="16px"
                            color={'secondary'}
                        />
                    }
                >
                    {t('chatbot_preview_reset_conversation')}
                </Button>
            </ChatbotFrameHeader>

            <ChatbotFrameContent ref={chatbotFrameContentRef}>
                <PreloadTypingIndicator />
                <MessageBubbleContainer text={chatbot.data?.welcome_message ?? ''} />
                {messageList.map(({ content, role }, index) => (
                    <MessageBubbleContainer
                        key={index}
                        text={content}
                        isConsumer={role === 'user'}
                    />
                ))}
                {isJimTyping ? <TypingIndicator /> : undefined}
            </ChatbotFrameContent>

            <ChatbotFrameFooter>
                <Controller
                    control={control}
                    name="messageInput"
                    rules={{ required: true }}
                    render={({ field }) => (
                        <textarea
                            {...field}
                            placeholder={t('chatbot_preview_message_input_placeholder')}
                            rows={1}
                            onKeyDown={onEnterKeyDown}
                            disabled={disableMessageInput}
                            ref={chatbotInputRef}
                        />
                    )}
                />

                <SendMessageButton
                    dataTrackId=""
                    appearance="outlined"
                    variant="primary"
                    icon={<SendMessageIcon />}
                    onClick={handleSubmit(onSendMessage)}
                    size="small"
                />
            </ChatbotFrameFooter>
        </ChatbotFrame>
    );
};
