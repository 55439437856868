import i18n from 'app/common/translations/i18n';

type PasswordCheckObject = {
    hasCorrectLength: boolean;
    hasSpecialCharacter: boolean;
    hasUpperLowerInt: boolean;
    notPartooLocaloo: boolean;
    has25Chars: boolean;
};

export const passWordChecker = (password: string): PasswordCheckObject => ({
    hasCorrectLength: password.length >= 8,
    hasSpecialCharacter: /[@#$%^&+=\-_!?.,<>[\]'";:/|(){}*]/.test(password),
    hasUpperLowerInt:
        /[A-Z]/.test(password) && /[a-z]/.test(password) && /\d/.test(password),
    notPartooLocaloo: !/partoo/gi.test(password) && !/localoo/gi.test(password),
    has25Chars: password.length >= 25,
});

export const passwordErrorHandler = (errors: Array<string>) => {
    const translatedErrors = errors.map(msg => i18n.t(msg));
    return translatedErrors.join('\r\n');
};

const isPasswordValid = (password: string): boolean => {
    const checks = passWordChecker(password);
    return (
        checks.has25Chars ||
        [
            checks.hasCorrectLength,
            checks.hasSpecialCharacter,
            checks.hasUpperLowerInt,
            checks.notPartooLocaloo,
        ].every(isChecked => isChecked)
    );
};

export default isPasswordValid;
