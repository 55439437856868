import { useState } from 'react';

import { IconPrefix, LeftElementType, TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import useDebounce from 'app/common/hooks/useDebounce';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { StyledSearchFilter } from './SearchFilter.styled';

export function SearchFilter() {
    const { t } = useTranslation();
    const [searchParam, setSearchParam] = useStateQueryParams('search');
    const [currentSearch, setCurrentSearch] = useState(searchParam);

    useDebounce(currentSearch, 500, () => {
        setSearchParam(currentSearch);
    });

    const resetSearch = () => {
        setCurrentSearch('');
        setSearchParam('');
    };

    return (
        <StyledSearchFilter>
            <TextInput
                dataTrackId=""
                value={currentSearch}
                onChange={setCurrentSearch}
                label={t('post_filter_search')}
                leftElement={['fa-search', IconPrefix.REGULAR]}
                leftElementType={LeftElementType.Icon}
                isReset
                onReset={resetSearch}
            />
        </StyledSearchFilter>
    );
}
