import { useTranslation } from 'react-i18next';

import { FormattedSpecificHoursData, OpenHoursData } from 'app/api/types/business';

export const useFormatConversationStarterDate = (
    openHours: OpenHoursData | undefined,
    specificHours: FormattedSpecificHoursData | undefined,
): string => {
    const { t } = useTranslation();
    const getDayDate = (date: Date) => {
        /*
         * Convert a string "2023-11-21" to Date with new Date("2023-11-21") and returns the current date at 1:00 am.
         */
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 1);
    };

    const serializedSpecificHours = (
        day: Date,
        specific_hours: FormattedSpecificHoursData | undefined,
    ) => {
        const dayDate = getDayDate(day);

        if (!specific_hours) return;

        if (specific_hours.close.length != 0) {
            for (const row of specific_hours.close) {
                const myStartDate = new Date(row.starts_at);
                const myEndDate = new Date(row.ends_at);
                if (myStartDate <= dayDate && dayDate <= myEndDate) {
                    return (
                        t('closed') +
                        ' ' +
                        `(${t('conversation_starters_open_hours_exceptional_hours')})`
                    );
                }
            }
        }

        if (specific_hours.open.length != 0) {
            for (const row of specific_hours.open) {
                const myStartDate = new Date(row.starts_at);
                const myEndDate = new Date(row.ends_at);
                if (myStartDate <= dayDate && dayDate <= myEndDate) {
                    return (
                        row.open_hours.join(', ') +
                        ' ' +
                        `(${t('conversation_starters_open_hours_exceptional_hours')})`
                    );
                }
            }
        }

        return null;
    };

    if (!openHours) return '';

    const daynameSubs = [
        'conversation_starters_open_hours_today',
        'conversation_starters_open_hours_tomorrow',
    ];
    // Day Mapping from JS Object Date
    const dayMapping = {
        1: 'monday',
        2: 'tuesday',
        3: 'wednesday',
        4: 'thursday',
        5: 'friday',
        6: 'saturday',
        0: 'sunday',
    };

    const today = new Date();

    let day;
    let dayAsDate;
    let dayName;
    let dayOpenHours;
    let openHoursAsString = '';
    for (let i = 0; i < 7; i++) {
        day = (today.getDay() + i) % 7;

        // Day name
        dayName = dayMapping[day as keyof typeof dayMapping];

        // open Hours (exceptional if specific hours)
        dayAsDate = new Date().setDate(today.getDate() + i);

        dayOpenHours = openHours[dayName as keyof typeof openHours].length
            ? openHours[dayName as keyof typeof openHours].join(', ')
            : t('closed');

        const exceptionalOpenHours = serializedSpecificHours(new Date(dayAsDate), specificHours);

        if (exceptionalOpenHours) dayOpenHours = exceptionalOpenHours;

        if (i < 2) dayName = daynameSubs[i];

        openHoursAsString = openHoursAsString + `${t(dayName)}: ${dayOpenHours}\n`;
    }

    return openHoursAsString;
};
