import { useMemo } from 'react';

import { getDateFnsLocaleFromLocale } from 'app/common/utils/dateFns';

import { useLanguage } from '../services/getLang';

export const useLocaleDatepicker = () => {
    const lang = useLanguage();
    return useMemo(() => {
        return getDateFnsLocaleFromLocale(lang);
    }, [lang]);
};
