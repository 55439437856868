import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { PUBLISHER_ERRORS } from 'app/common/data/queryKeysConstants';
import useWebsocket from 'app/common/hooks/useWebsocket';
import queryClient from 'app/states/queryClient';

const POST_CHECK_ERROR_EVENT = 'POST_CHECK_ERROR_EVENT';

type PostCheckErrorType = {
    business_id: string;
};

export default function useWebsocketResetPublisherErrors() {
    const businessId = useGetBusinessId();

    return useWebsocket(POST_CHECK_ERROR_EVENT, (event: PostCheckErrorType) => {
        if (event?.business_id === businessId) {
            queryClient.invalidateQueries(invalidatesAny(PUBLISHER_ERRORS));
        }
    });
}
