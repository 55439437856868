import { connect } from 'react-redux';

import { dataSelector, productSelector } from 'app/common/reducers/me';
import { AppState } from 'app/states/reducers';

import Visibility from './Visibility';

const mapStateToProps = (state: AppState) => {
    const userData = dataSelector(state.me);
    return {
        userData,
        sidebarProducts: productSelector(state.me),
    };
};

export default connect(mapStateToProps)(Visibility);
