import { Button, FontAwesomeIconsPartooUsed, IconPrefix, Stack, Switch, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { IPAddressFormListing } from './IPAddressForm.styled';
import IPAddress from '../IPAddress';

type Props = {
    ipWhitelist: Array<string>;
    changeRestrictKeySwitch: () => void;
    removeIpAddress: (index: number) => void;
    changeIPAddress: (index: number, newIP: string) => void;
    ipErrors: Record<string, any>;
    restrictKeySwitchPosition: boolean;
    enableAddIpAddress: boolean;
    addIPAddressField: () => void;
};

const IPAddressForm = ({
    changeRestrictKeySwitch,
    removeIpAddress,
    changeIPAddress,
    ipWhitelist,
    ipErrors,
    restrictKeySwitchPosition,
    enableAddIpAddress,
    addIPAddressField,
}: Props) => {
    const { t } = useTranslation();
    return (
        <Stack gap="8px" style={{ paddingTop: '16px' }}>
            <Text variant="heading6" as="span">
                {t('api_key_manager_ip_restrictions_title')}
            </Text>
            <Text variant="bodyMBold" as="span" color="secondary">
                {t('api_key_manager_ip_restrictions_helper')}
            </Text>
            <Stack gap="8px" direction="row" alignItems="center" style={{ padding: '16px 0' }}>
                <Switch
                    dataTrackId="ip_address_form__switch"
                    name="ip_address_form"
                    checked={restrictKeySwitchPosition}
                    onChange={() => changeRestrictKeySwitch()}
                />
                <Text variant="bodyMBold" as="span" color="secondary">
                    {t('api_key_manager_ip_restrictions_toggle')}
                </Text>
            </Stack>
            {restrictKeySwitchPosition && (
                <div>
                    <IPAddressFormListing>
                        {ipWhitelist.map((ipAddress, index) => (
                            <IPAddress
                                ipAddress={ipAddress}
                                ipError={ipErrors[index] !== undefined}
                                key={index}
                                index={index}
                                changeRestrictKeySwitch={() => changeRestrictKeySwitch()}
                                removeIpAddress={indexToDelete => removeIpAddress(indexToDelete)}
                                changeIPAddress={(indexToRename, newIP) => {
                                    changeIPAddress(indexToRename, newIP);
                                }}
                            />
                        ))}
                    </IPAddressFormListing>
                    <Button
                        dataTrackId="ip-address-form__add-button"
                        icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                        appearance="outlined"
                        variant="primary"
                        onClick={() => addIPAddressField()}
                        disabled={!enableAddIpAddress}
                    >
                        {t('api_key_manager_ip_restrictions_add_button')}
                    </Button>
                </div>
            )}
        </Stack>
    );
};

export default IPAddressForm;
