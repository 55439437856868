import { useMutation } from 'react-query';

import { useDispatch } from 'react-redux';

import { CommentData, UpdateCommentParams } from 'app/api/types/comments';
import API from 'app/api/v2/api_calls';

import { useUpdateReviewQueryData } from 'app/reviewManagement/reviewList/hooks/queryData/useUpdateReviewQueryData';
import { useShowReviewErrorToast } from 'app/reviewManagement/reviewList/hooks/ReviewCard/useShowReviewErrorToast';
import { ReviewObject } from 'app/states/reviews';

import { NOT_TREATED_REVIEWS, TREATED_AUTO_REPLY_REVIEWS } from 'app/states/reviews/filters';

import { useNotifyOnComment } from './useNotifyOnComment';
import { CommentOptions } from '../CommentForm';
import { updateReviewCommentData } from '../utils/updateReviewCommentData';

export const useUpdateComment = (review: ReviewObject, comment?: CommentData) => {
    const dispatch = useDispatch();
    const notifyOnComment = useNotifyOnComment();
    const updateReviewQueryData = useUpdateReviewQueryData();
    const showReviewErrorToast = useShowReviewErrorToast();

    return useMutation(
        ({ content, commentOptions }: { content: string; commentOptions: CommentOptions }) => {
            const commentParams: UpdateCommentParams = {
                content,
                ...commentOptions,
            };
            return API.comments.updateComment(commentParams, comment?.id);
        },
        {
            onSuccess: data => {
                if ([NOT_TREATED_REVIEWS, TREATED_AUTO_REPLY_REVIEWS].includes(review.state)) {
                    updateReviewQueryData(updateReviewCommentData(review, data), {
                        markReviewAs: 'treated',
                    });
                } else {
                    updateReviewQueryData(updateReviewCommentData(review, data));
                }

                notifyOnComment(review, comment);
            },
            onError: (error: Record<string, any>) => {
                showReviewErrorToast(review, error, dispatch);
            },
        },
    );
};
