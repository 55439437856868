import { InfiniteData, QueryKey } from 'react-query';

import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { AssignFilterOption, Conversation, StatusFilterOption } from 'app/states/messaging';
import queryClient from 'app/states/queryClient';

import { conversationsSort } from '../ConversationUtils';
import { getConversationsKey } from '../hooks/useGetConversations';

export const addConversation = (
    conversation: Conversation,
    statusFilter: StatusFilterOption,
    assignFilter: AssignFilterOption,
    businessFilters: BusinessModalFiltersType,
) => {
    const queryDataKey = getConversationsKey(statusFilter, assignFilter, businessFilters);
    const { allConversations, conversationsCache } = getCachedConversations(queryDataKey);

    if (conversationsCache) {
        allConversations.push(conversation);
        allConversations.sort(conversationsSort);

        updateConversationsCache(queryDataKey, conversationsCache, allConversations);
    }
};

export const removeConversation = (
    removedConversationId: number,
    statusFilter: StatusFilterOption,
    assignFilter: AssignFilterOption,
    businessFilters: BusinessModalFiltersType,
) => {
    const queryDataKey = getConversationsKey(statusFilter, assignFilter, businessFilters);
    const { allConversations, conversationsCache } = getCachedConversations(queryDataKey);

    if (conversationsCache) {
        const filteredConversations = allConversations.filter(
            (conversation: Conversation) => conversation.id !== removedConversationId,
        );

        updateConversationsCache(queryDataKey, conversationsCache, filteredConversations);
    }
};

export const updateConversation = (
    updatedConversation: Conversation,
    statusFilter: StatusFilterOption,
    assignFilter: AssignFilterOption,
    businessFilters: BusinessModalFiltersType,
) => {
    const queryDataKey = getConversationsKey(statusFilter, assignFilter, businessFilters);
    const { allConversations, conversationsCache } = getCachedConversations(queryDataKey);

    if (conversationsCache) {
        const conversationIndex = allConversations.findIndex(
            (conversation: Conversation) => conversation.id === updatedConversation.id,
        );
        if (conversationIndex !== -1) {
            allConversations[conversationIndex] = updatedConversation;
            updateConversationsCache(queryDataKey, conversationsCache, allConversations);
            return true;
        }
    }

    return false;
};

export const clearConversationsExceptActive = (
    activeConversation: Conversation,
    statusFilter: StatusFilterOption,
    assignFilter: AssignFilterOption,
    businessFilters: BusinessModalFiltersType,
) => {
    const queryDataKey = getConversationsKey(statusFilter, assignFilter, businessFilters);
    const { conversationsCache } = getCachedConversations(queryDataKey);

    if (conversationsCache) {
        const updatedConversations = [activeConversation];

        updateConversationsCache(queryDataKey, conversationsCache, updatedConversations);
    }
};

export const createConversationsCache = (
    activeConversation: Conversation,
    statusFilter: StatusFilterOption,
    assignFilter: AssignFilterOption,
    businessFilters: BusinessModalFiltersType,
) => {
    const queryDataKey = getConversationsKey(statusFilter, assignFilter, businessFilters);
    const cache = {
        pagesParams: [],
        pages: [{ conversations: [activeConversation] }],
    };

    queryClient.setQueryData(queryDataKey, cache);
};

const getCachedConversations = (queryDataKey: QueryKey) => {
    const conversationPages: InfiniteData<Record<string, any>> | undefined =
        queryClient.getQueryData(queryDataKey);

    if (conversationPages) {
        const allConversations = conversationPages.pages.reduce(
            (acc, page) => [...acc, ...page.conversations],
            [],
        );

        return { allConversations: allConversations, conversationsCache: conversationPages };
    }

    return { allConversations: [], conversationsCache: undefined };
};

const updateConversationsCache = (
    queryDataKey: QueryKey,
    conversationsCache: InfiniteData<Record<string, any>>,
    updatedConversations: Record<string, any>,
) => {
    const lastPageIndex = conversationsCache.pages.length - 1;
    const lastCursor = conversationsCache.pages[lastPageIndex].next_cursor;
    const updatedFirstPage = {
        ...conversationsCache.pages[0],
        conversations: updatedConversations,
        next_cursor: lastCursor,
    };
    const updatedConversationPages = {
        ...conversationsCache,
        pages: [updatedFirstPage],
    };

    queryClient.setQueryData(queryDataKey, updatedConversationPages);
};
