import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Clickable from 'app/common/components/buttons/Clickable';

export const ClickableWrapper = styled(Clickable)<{ modifier: string }>`
    display: flex;
    border: solid 1px #e6e8ed;
    border-radius: 4px;
    align-items: center;
    padding: 0 16px;
    position: relative;
    overflow: hidden;

    .suggestions_list_row_arrow {
        padding: 0 32px;
        color: #7f8ea4;
    }

    &:before {
        position: absolute;

        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 40px 40px 0;
        border-color: transparent;

        top: 0;
        right: 0;
        content: '';

        opacity: 0;
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:after {
        position: absolute;

        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: 'Font Awesome 6 Pro', sans-serif;
        content: '\\f00c';
        font-size: 14px;
        font-weight: 900;
        color: #ffffff;

        top: 0;
        right: 4px;

        opacity: 0;
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    ${props => {
        if (props.modifier === 'error') {
            return css`
                border-color: #ff426e;
                box-shadow:
                    0 0 0 1px #ff426e,
                    0 2px 10px rgba(0, 0, 0, 0.15);

                &:before {
                    border-color: transparent #ff426e transparent transparent;
                    opacity: 1;
                }

                &:after {
                    opacity: 1;
                }
            `;
        }

        if (props.modifier === 'loading') {
            return css`
                border-color: #40dba2;
                box-shadow:
                    0 0 0 1px #40dba2,
                    0 2px 10px rgba(0, 0, 0, 0.15);

                &:before {
                    border-color: transparent #40dba2 transparent transparent;
                    opacity: 1;
                }

                &:after {
                    opacity: 1;
                }
            `;
        }

        if (props.modifier === 'selected') {
            return css`
                border-color: #0085f2;
                box-shadow:
                    0 0 0 1px #0085f2,
                    0 2px 10px rgba(0, 0, 0, 0.15);

                &:before {
                    border-color: transparent #0085f2 transparent transparent;
                    opacity: 1;
                }

                &:after {
                    opacity: 1;
                }
            `;
        }
    }};
`;
