import styled from '@emotion/styled';
import { IconButton, Text } from '@partoohub/ui';

export const ChatbotKnowledgeItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid rgba(20, 37, 66, 0.12);
    padding: 16px;
    gap: 4px;
    cursor: pointer;
`;

export const ChatbotKnowledgeItemContent = styled.div`
    align-items: center;
`;

export const ChatbotKnowledgeItemAnswer = styled(Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
`;

export const ChatbotKnowledgeItemDeleteButton = styled(IconButton)`
    padding: 0 16px;
`;
