import { useCallback, useRef, useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Stack, Text } from '@partoohub/ui';

import { ArcElement, Chart as ChartJS } from 'chart.js';
import { Doughnut as ChartDoughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import {
    DoughnutLegendsStyled,
    DoughnutStyled,
    DoughnutWithOverlayStyled,
} from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/AverageGrade/Doughnut.styled';
import { DoughnutLegendTooltip } from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/AverageGrade/DoughnutLegendTooltip';
import DoughnutOverlay from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/AverageGrade/DoughnutOverlay';
import NoReviewPlaceHolder from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/AverageGrade/NoReviewPlaceHolder';
import { GraphTypes } from 'app/utils/chartjs/graphTypes';
import { buildOptionsForGraphic } from 'app/utils/chartjs/options';

type Props = {
    labels: Array<string>;
    // [satisfied, neutral, dissatisfied]
    labelValues: Array<number>;
    averageGrade: number;
    labelPercentages: Array<number>;
    title: string;
    hasReviews: boolean;
    isLoading: boolean;
};

ChartJS.register(ArcElement);

const doughnutColors = {
    success: HEX_COLORS.success,
    quaternary: HEX_COLORS.ratings,
    danger: HEX_COLORS.danger,
};

const gradeToGradeColor = (grade: number) => {
    switch (true) {
        case grade < 3:
            return 'danger';

        case grade < 4:
            return 'quaternary';

        default:
            return 'success';
    }
};

const Doughnut = ({
    labels,
    labelValues,
    averageGrade,
    labelPercentages,
    title,
    hasReviews,
    isLoading,
}: Props) => {
    const doughnutRef = useRef<ChartJS>(null);
    const { t } = useTranslation();
    const type = GraphTypes.DOUGHNUT;
    const doughnutColorsArray = [
        doughnutColors.success,
        doughnutColors.quaternary,
        doughnutColors.danger,
    ];

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const percentage =
        hoveredIndex !== null ? (labelPercentages[hoveredIndex] * 100).toFixed() : hoveredIndex;
    const color = hoveredIndex !== null ? Object.keys(doughnutColors)[hoveredIndex] : null;

    const data: any = {
        labels: labels.map(a => t(a)),
        datasets: [
            {
                data: isLoading ? [] : labelValues,
                backgroundColor: doughnutColorsArray,
                hoverBorderWidth: [1, 1, 1],
                hoverBorderColor: doughnutColorsArray,
                hoverBackgroundColor: doughnutColorsArray,
                borderAlign: ['inner', 'inner', 'inner'],
            },
        ],
    };

    const handleHover = (e, event) => {
        setHoveredIndex(event[0] != null ? event[0].index : null);
    };

    const buildOptions: any = useCallback(() => {
        const options: any = buildOptionsForGraphic(type);
        options.cutout = '66%';
        options.onHover = handleHover;
        return options;
    }, []);

    return (
        <DoughnutStyled>
            <DoughnutWithOverlayStyled>
                <ChartDoughnut ref={doughnutRef} data={data} options={buildOptions()} />
                <DoughnutOverlay
                    averageGrade={averageGrade}
                    percentage={percentage}
                    color={color || gradeToGradeColor(averageGrade)}
                    hasReviews={hasReviews}
                    isLoading={isLoading}
                />
            </DoughnutWithOverlayStyled>
            <DoughnutLegendsStyled className="padding--simple">
                <Text variant="heading4" as="span">
                    {t(title)}
                </Text>
                <Stack gap="8px" style={{ cursor: 'help' }}>
                    {hasReviews || isLoading ? (
                        labels.map((label, index) => (
                            <DoughnutLegendTooltip
                                label={label}
                                color={Object.keys(doughnutColors)[index]}
                                // @ts-ignore
                                count={labelValues[index]}
                                key={label + Object.keys(doughnutColors)[index]}
                                isLoading={isLoading}
                            />
                        ))
                    ) : (
                        <NoReviewPlaceHolder />
                    )}
                </Stack>
            </DoughnutLegendsStyled>
        </DoughnutStyled>
    );
};
export default Doughnut;
