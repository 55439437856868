import { FC, useRef, useState } from 'react';

import { Button, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { CarouselHandle } from 'app/common/components/Carousel';

import { STATIC_APP_ASSETS_URL } from 'app/config';

import { Timer } from './Timer';
import {
    LinkWrapper,
    TipsArrow,
    TipsArrows,
    TipsCarousel,
    TipsContainer,
    TipsHeader,
} from './Tips.styled';
import TipsItem from './TipsItem';

const NB_TIPS = 5;

export const WhatsappTips: FC = () => {
    const { t } = useTranslation();
    const carouselRef = useRef<CarouselHandle>(null);

    const [tipId, setTipId] = useState(0);

    const onNext = () => {
        carouselRef.current?.goNext();
        if (tipId < NB_TIPS - 1) {
            setTipId(tipId + 1);
        } else {
            setTipId(0);
        }
    };
    const onBack = () => {
        carouselRef.current?.goBack();
        if (tipId > 0) {
            setTipId(tipId - 1);
        } else {
            setTipId(NB_TIPS - 1);
        }
    };

    return (
        <TipsContainer>
            <TipsHeader>
                <Timer size={NB_TIPS} tipId={tipId} onNext={onNext} />
                <TipsArrows>
                    <TipsArrow
                        icon={['fa-arrow-left', IconPrefix.SOLID]}
                        onClick={onBack}
                        iconSize="16px"
                    ></TipsArrow>
                    <TipsArrow
                        icon={['fa-arrow-right', IconPrefix.SOLID]}
                        onClick={onNext}
                        iconSize="16px"
                    ></TipsArrow>
                </TipsArrows>
            </TipsHeader>
            <TipsCarousel ref={carouselRef}>
                <TipsItem
                    image={`${STATIC_APP_ASSETS_URL}/whatsapp/whatsapp_tips_1.svg`}
                    title={t('whatsapp_tips_1_title')}
                    description={t('whatsapp_tips_1_description')}
                />
                <TipsItem
                    image={`${STATIC_APP_ASSETS_URL}/whatsapp/whatsapp_tips_2.svg`}
                    title={t('whatsapp_tips_2_title')}
                    description={t('whatsapp_tips_2_description')}
                />
                <TipsItem
                    image={`${STATIC_APP_ASSETS_URL}/whatsapp/whatsapp_tips_3.svg`}
                    title={t('whatsapp_tips_3_title')}
                    description={t('whatsapp_tips_3_description')}
                />
                <TipsItem
                    image={`${STATIC_APP_ASSETS_URL}/whatsapp/whatsapp_tips_4.svg`}
                    title={t('whatsapp_tips_4_title')}
                    description={t('whatsapp_tips_4_description')}
                />
                <TipsItem
                    image={`${STATIC_APP_ASSETS_URL}/whatsapp/whatsapp_tips_5.svg`}
                    title={t('whatsapp_tips_5_title')}
                    description={t('whatsapp_tips_5_description')}
                />
            </TipsCarousel>
            <LinkWrapper
                href="https://help.partoo.co/articles/8857309-comment-et-ou-communiquer-mon-compte-whatsapp-business"
                target="_blank"
            >
                <Button
                    dataTrackId={''}
                    full={true}
                    appearance="outlined"
                    shape="rounded"
                    size="medium"
                >
                    {t('whatsapp_tips_more_information')}
                </Button>
            </LinkWrapper>
        </TipsContainer>
    );
};
