import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BusinessCreationRequestData, CreationSuccessResponses } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { BUSINESSES } from 'app/common/data/queryKeysConstants';
import { canSendClevertapEventsSelector } from 'app/common/reducers/me';
import { PushNotifsEvent } from 'app/common/services/pushNotifications/events';
import { PushNotificationsWrapper } from 'app/common/services/pushNotifications/pushNotificationsWrapper';
import useBusinessCalculateCompletionRate from 'app/pages/visibility/BusinessCreate/hooks/useBusinessCalculateCompletionRate';
import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';
import queryClient from 'app/states/queryClient';

export default function useBusinessCreate(onError: (error: Error) => void = () => undefined) {
    const navigate = useNavigate();
    const calculateCompletionRate = useBusinessCalculateCompletionRate();
    const canSendClevertapEvents = useSelector(canSendClevertapEventsSelector);

    return useMutation(
        (payload: BusinessCreationRequestData) => api.business.createBusiness(payload),
        {
            onSuccess: async data => {
                const response = data as CreationSuccessResponses;
                const businessId = response.business_id;

                // CleverTap event
                if (canSendClevertapEvents) {
                    PushNotificationsWrapper.getInstance().sendEvent(
                        PushNotifsEvent.CREATE_BUSINESS,
                    );
                }

                sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_CREATED_EVENT, businessId);

                // update completion rate
                await calculateCompletionRate.mutateAsync(businessId).then(() => {
                    // Reset cache
                    queryClient.resetQueries(invalidatesAny(BUSINESSES)).then(() => {
                        // Navigate to Business Edit
                        navigate(`${VISIBILITY_LOCATION_PATH}/${response.business_id}`);
                    });
                });
            },
            onError,
        },
    );
}
