import { TextInput } from '@partoohub/ui';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
    control: Control<any>;
}

const MAX_COUPON_CODE_CHARACTERS = 58;

export const ControlledCouponCode = ({ control }: Props) => {
    const { t } = useTranslation();
    const { field, formState } = useController({
        name: 'offer_coupon_code',
        control,
        rules: {
            maxLength: MAX_COUPON_CODE_CHARACTERS,
            minLength: 0,
        },
    });

    const hasErrorsCouponCodeLength = formState.errors.offer_coupon_code?.type === 'maxLength';

    const handleChange = (value: string) => {
        field.onChange(value?.slice(0, MAX_COUPON_CODE_CHARACTERS));
    };

    return (
        <TextInput
            {...field}
            dataTrackId="offer_coupon_code"
            label={t('coupon_code')}
            error={hasErrorsCouponCodeLength}
            characterCount={MAX_COUPON_CODE_CHARACTERS}
            onChange={handleChange}
        />
    );
};
