import React from 'react';

import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import { AppFeatureFlagsProvider } from 'app/common/contexts/featureFlags';
import { useHandlePreLoginScripts } from 'app/common/services/scripts/preLogin';
import { BusinessListGroupProvider } from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupContext';
import { RoutesComponent } from 'app/routing';
import { CustomRouter } from 'app/routing/components/CustomRouter';
import { sdkBridge } from 'app/SDKBridge';

import configureStore from 'app/states/configureStore';
import queryClient from 'app/states/queryClient';
import { Theme } from 'app/Theme';

const MemoizedRoutes = React.memo(RoutesComponent);
const store: any = configureStore();

const App = () => {
    sdkBridge.registerStore(store);

    useHandlePreLoginScripts();

    return (
        <Provider store={store}>
            <CustomRouter>
                <QueryClientProvider client={queryClient}>
                    <Theme>
                        <AppFeatureFlagsProvider>
                            <BusinessListGroupProvider>
                                <MemoizedRoutes />
                            </BusinessListGroupProvider>
                            <ReactQueryDevtools initialIsOpen={false} position="top-right" />
                        </AppFeatureFlagsProvider>
                    </Theme>
                </QueryClientProvider>
            </CustomRouter>
        </Provider>
    );
};

export default App;
