import React from 'react';

import { MetricsTemplateChart } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Metrics/MetricsChart/MetricsTemplateChart/MetricsTemplateChart';
import { useMetricsActionsChart } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/metrics/useMetricsActionsChart';
import { AnalyticsQueryKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queryKeys';

export const ActionsChart = () => {
    const ACTIONS_KEY = 'actions';

    const { data, isLoading, refetch, error } = useMetricsActionsChart();

    return (
        <MetricsTemplateChart
            name={ACTIONS_KEY}
            icon="fa-arrow-pointer"
            orderBykey={AnalyticsQueryKeys.ORDER_BY_ACTIONS}
            dimensionKey={AnalyticsQueryKeys.DIMENSIONS_ACTIONS}
            data={data}
            isLoading={isLoading}
            refetch={refetch}
            error={error}
            dataTrack="analytics_visiblity__chart_actions"
        />
    );
};
