import styled from '@emotion/styled';
import { Progress, Stack } from '@partoohub/ui';

export const StyledCard = styled.div`
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
`;

export const StyledProgressBars = styled(Stack)`
    width: 100%;
`;

export const StyledBar = styled(Stack)`
    width: 100%;
`;

export const StyledProgress = styled(Progress)`
    width: 100%;
`;
