import Bowser from 'bowser';

/**
 * Returns true if the browser is supported for push notifications from CleverTap.
 * @returns {boolean}
 */
export default function isSupportedBrowser(): boolean {
    const parser = Bowser.getParser(window.navigator.userAgent);
    const result = parser.satisfies({
        desktop: {
            chrome: '>=60',
            firefox: '>=60',
            // safari: '>=11',  TODO: Enable safari once we've done config.
        },
    });
    return result || false;
}
