import React from 'react';

import { getBusinessStatusCompletionInfos } from 'app/businessEditV2/sections/BusinessStatusSection/BusinessStatusCompletion/BusinessStatusCompletion';

import AverageCompletionValue from './AverageCompletionValue';
import AverageProgressBar from './AverageProgressBar';
import { BusinessAverageCompletionContainer } from './BusinessAverageCompletion.styled';

type Props = {
    average?: number | null;
};

const BusinessAverageCompletion = ({ average }: Props) => {
    const color = getBusinessStatusCompletionInfos(average ?? null);

    return (
        <BusinessAverageCompletionContainer
            data-track="visibility_locations__average_completion"
            data-intercom-target="visibility_locations__average_completion"
        >
            <AverageProgressBar value={average ?? 0} color={color} />
            <AverageCompletionValue value={average ?? null} />
        </BusinessAverageCompletionContainer>
    );
};

export default BusinessAverageCompletion;
