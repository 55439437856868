import styled from '@emotion/styled';

export const Title = styled.div`
    margin-right: 8px;
`;

export const ClickableContainer = styled.div`
    display: flex;
    > div {
        margin: auto;
    }
`;
