import { V2FormattedBusinessData } from 'app/api/types/business';

const reviewBoosterFilter = (
    businesses: Array<V2FormattedBusinessData>,
    subscribed: boolean,
): Array<V2FormattedBusinessData> =>
    businesses.filter(
        ({ subscriptions }) =>
            subscriptions.review_booster.active === subscribed,
    );

export default reviewBoosterFilter;
