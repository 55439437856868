import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { REVIEW_CARD_AVATAR_WIDTH } from './ReviewCardHeader/ReviewCardAvatar';

export const StyledReviewCard = styled.div<{
    displayTags: boolean;
    isDeleted?: boolean;
    animateEnter?: boolean;
    hasMaxWidth?: boolean;
}>`
    border: 1px solid ${greyColorObject.initial};
    position: relative;
    display: grid;
    background-color: ${HEX_COLORS.white};
    padding: 16px;
    border-radius: 4px;
    min-width: 0;
    scroll-margin-top: 16px;

    ${({ displayTags }) => {
        if (displayTags) {
            return `
                grid-template-areas:
                    'logo author tag toggle'
                    'separator rating tag .'
                    'separator content content content'
                    'comments comments comments comments';

                grid-template-columns: ${REVIEW_CARD_AVATAR_WIDTH}px 1fr auto min-content;
            `;
        }

        return `
            grid-template-areas:
                'logo author toggle'
                'separator rating .'
                'separator content content'
                'comments comments comments';

            grid-template-columns: ${REVIEW_CARD_AVATAR_WIDTH}px 1fr min-content;
        `;
    }}

    grid-gap: 0 8px;

    ${({ animateEnter }) =>
        animateEnter &&
        css`
            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            animation: 0.4s linear 0s fadeIn;
        `}
    ${({ isDeleted, theme }) =>
        isDeleted &&
        css`
            border-left: 5px solid ${theme.colors.danger.initial};
        `}
`;
