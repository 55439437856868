import React from 'react';

import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { useTranslation } from 'react-i18next';

import AsyncSingleSelect from 'app/common/designSystem/components/molecules/AsyncSingleSelect';
import { Props } from 'app/common/designSystem/components/molecules/AsyncSingleSelect/AsyncSingleSelect';
import useAvailableRoles, { formatRoleToChoice } from 'app/common/hooks/queries/useAvailableRoles';

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'placeholder' | 'options' | 'onChange';

const ControlledRoleSelect = ({ name, control }: Omit<Props, OmitProps> & ControlledComponent) => {
    const { data } = useAvailableRoles();
    const { t } = useTranslation();

    const options = data?.roles.map(roleMapped => formatRoleToChoice(roleMapped, t)) ?? [];

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return (
                    <AsyncSingleSelect
                        options={options}
                        selectedValue={value}
                        onChange={onChange}
                        placeholder={t('role')}
                        isMandatory
                        mustFilterOptions
                    />
                );
            }}
        />
    );
};

export default ControlledRoleSelect;
