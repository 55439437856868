import { APP_PRODUCTS, REVIEW_BOOSTER } from 'app/common/data/productIds';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useCanSendInvite = (): boolean => {
    const { data: me } = useMe();
    const subscriptions = useBusinessSubscriptions();

    const isPageAvailable = me?.sidebar_products.includes(APP_PRODUCTS[REVIEW_BOOSTER]) ?? false;

    return isPageAvailable && subscriptions.subscribedToRB && subscriptions.hasBusinesses;
};
