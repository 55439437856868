import { Chart, ChartType } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { sumValues } from 'app/common/utils/sumValues';

import { BAR_CHART_OPTIONS } from './common';
import { useReviewMetricsByDate } from '../../../hooks/useMetrics';

export const RatingDistributionGraph = () => {
    const { t } = useTranslation();
    const metrics = useReviewMetricsByDate();

    if (!metrics) return undefined;

    const { data } = metrics;

    return (
        <Chart
            dataTrackId=""
            type={ChartType.Bar}
            options={BAR_CHART_OPTIONS}
            data={{
                datasets: [
                    {
                        label: t('review_analytics_rating_distribution_stars_count', {
                            count: 5,
                        }),
                        data: data.map(({ metrics: { rating_distribution } }) => {
                            const total = sumValues(rating_distribution);
                            return total ? (100 * rating_distribution[5]) / total : 0;
                        }),
                        backgroundColor: 'rgba(44, 184, 132, 0.8)',
                    },
                    {
                        label: t('review_analytics_rating_distribution_stars_count', {
                            count: 4,
                        }),
                        data: data.map(({ metrics: { rating_distribution } }) => {
                            const total = sumValues(rating_distribution);
                            return total ? (100 * rating_distribution[4]) / total : 0;
                        }),
                        backgroundColor: 'rgba(44, 184, 132, 0.5)',
                    },
                    {
                        label: t('review_analytics_rating_distribution_stars_count', {
                            count: 3,
                        }),
                        data: data.map(({ metrics: { rating_distribution } }) => {
                            const total = sumValues(rating_distribution);
                            return total ? (100 * rating_distribution[3]) / total : 0;
                        }),
                        backgroundColor: 'rgba(247, 194, 0, 0.8)',
                    },
                    {
                        label: t('review_analytics_rating_distribution_stars_count', {
                            count: 2,
                        }),
                        data: data.map(({ metrics: { rating_distribution } }) => {
                            const total = sumValues(rating_distribution);
                            return total ? (100 * rating_distribution[2]) / total : 0;
                        }),
                        backgroundColor: 'rgba(255, 66, 110, 0.5)',
                    },
                    {
                        label: t('review_analytics_rating_distribution_stars_count', {
                            count: 1,
                        }),
                        data: data.map(({ metrics: { rating_distribution } }) => {
                            const total = sumValues(rating_distribution);
                            return total ? (100 * rating_distribution[1]) / total : 0;
                        }),
                        backgroundColor: 'rgba(255, 66, 110, 0.8)',
                    },
                ],
                labels: data.map(d => d.dimension),
            }}
        />
    );
};
