import { useTranslation } from 'react-i18next';

import {
    MESSAGING_STATS_PERIOD_MONTHS,
    useHomepageMessagingStats,
} from 'app/pages/Homepage/hooks/stats/statsItems/messages/useHomepageMessagingStats';
import {
    ChipColor,
    ChipEvolution,
    StatChip,
} from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/StatChip';
import {
    calculateResponseTimeEvolution,
    formatResponseTime,
    getResponseTimeTranslation,
} from 'app/pages/Homepage/utils/responseTimeFunctions';

export const StatMessagesChip = () => {
    const { t } = useTranslation();
    const { data } = useHomepageMessagingStats(MESSAGING_STATS_PERIOD_MONTHS, 0);
    const { data: dataPrevious } = useHomepageMessagingStats(
        MESSAGING_STATS_PERIOD_MONTHS * 2,
        MESSAGING_STATS_PERIOD_MONTHS,
    );

    const actualAverageResponseTime = data?.previousAverageResponseTime ?? 0;
    const previousAverageResponseTime = dataPrevious?.previousAverageResponseTime ?? 0;

    const responseTimeEvolution = calculateResponseTimeEvolution(
        previousAverageResponseTime,
        actualAverageResponseTime,
    );

    const formattedResponseTimeEvolution = formatResponseTime(responseTimeEvolution);
    const { translationKey: responseTimeTranslationKey, ...units } = getResponseTimeTranslation(
        formattedResponseTimeEvolution,
    );

    const statChipProps = () => {
        if (responseTimeEvolution === 0) {
            return {
                text: `${t('homepage_stats_no_evolution_months', {
                    duration: 1,
                })}`,
                chipColor: ChipColor.GREY,
                chipEvolution: ChipEvolution.NONE,
            };
        }

        if (formattedResponseTimeEvolution.isNegative) {
            return {
                text: `${t(responseTimeTranslationKey, units)} ${t('homepage_stats_messages_evolution')}`,
                chipColor: ChipColor.GREEN,
                chipEvolution: ChipEvolution.DECREASE,
            };
        }

        return {
            text: `+${t(responseTimeTranslationKey, units)} ${t('homepage_stats_messages_evolution')}`,
            chipColor: ChipColor.RED,
            chipEvolution: ChipEvolution.INCREASE,
        };
    };

    return <StatChip {...statChipProps()} />;
};
