import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useWaitForGoogleScripts } from 'app/common/hooks/useWaitForGoogleScript';

import { GoogleMapsContent, GoogleMapsView, LoadingElement } from './GoogleMaps.styled';
import GoogleMapsPlaceHolder from './GoogleMapsPlaceHolder';

type Props = {
    lat: number | null;
    long: number | null;
    onDrag: (lat: number, long: number) => void;
    disabled?: boolean;
};

const DEFAULT_ZOOM = 15;

export const createMarker = (markerRef, viewRef, latLng, onDrag, disabled) => {
    const onDragEnd = (event, onDrag) => {
        onDrag(event.latLng.lat(), event.latLng.lng());
    };

    markerRef.current = new google.maps.Marker({
        map: viewRef.current,
        position: latLng,
        draggable: !disabled,
        animation: google.maps.Animation.DROP,
    });
    markerRef.current.addListener('dragend', event => onDragEnd(event, onDrag));
};

export const setPosition = (ref, latLng, pov: google.maps.StreetViewPov | null = null) => {
    ref.current.setPosition(latLng);
    if (pov !== null) {
        ref.current.setPov(pov);
        ref.current.setZoom(1);
        ref.current.setVisible(true);
    }
};

const GoogleMaps = ({ lat, long, onDrag, disabled = false }: Props) => {
    // This Component needs the Google Maps JavaScript API to work

    const { t } = useTranslation();

    // Ref used for google maps
    const map = useRef<google.maps.Map>();
    const mapMarker = useRef<google.maps.Marker>();

    const mapsRef = useRef<HTMLDivElement>(null);

    // Placeholders
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const setCenter = (ref, latLng) => {
        ref.current.setCenter(latLng);
    };

    const handleScriptLoad = () => {
        if (window?.google?.maps && mapsRef.current) {
            setIsLoaded(true);

            const latLng = new google.maps.LatLng(lat!, long!);

            // Create map
            map.current = new google.maps.Map(mapsRef.current, {
                center: latLng,
                zoom: DEFAULT_ZOOM,
                streetViewControl: false,
            });

            // Create markers (red pins)
            createMarker(mapMarker, map, latLng, onDrag, disabled);
        }
    };

    useWaitForGoogleScripts(handleScriptLoad);

    useEffect(() => {
        if (map.current && mapMarker.current) {
            const latLng = new google.maps.LatLng(lat!, long!);
            setCenter(map, latLng);
            setPosition(mapMarker, latLng);
        }
    }, [lat, long]);

    return (
        <>
            {!isLoaded && (
                <GoogleMapsPlaceHolder
                    icon={<LoadingElement />}
                    message={t('geocoding_in_progress_message')}
                />
            )}
            <GoogleMapsContent>
                <GoogleMapsView ref={mapsRef} />
            </GoogleMapsContent>
        </>
    );
};

export default GoogleMaps;
