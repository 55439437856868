import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import {
    GET_ME_SUCCESS,
    MeState,
    dataSelector,
    isShadowUserSelector,
} from 'app/common/reducers/me';
import PushNotificationsBrowserState from 'app/common/services/pushNotifications/browserState';
import isSupportedBrowser from 'app/common/services/pushNotifications/isSupportedBrowser';
import { NotificationPermissionType } from 'app/common/services/pushNotifications/permissionTypes';
import { PushNotificationsWrapper } from 'app/common/services/pushNotifications/pushNotificationsWrapper';
import { Saga } from 'app/common/types';
import isInsideIFrame from 'app/common/utils/isInsideIFrame';
import {
    MAYBE_SHOW_PUSH_NOTIFICATIONS_OPT_IN_PROMPT,
    showPushNotificationsOptInPrompt,
} from 'app/states/pushNotifs/actions';
import { meSelector } from 'app/states/reducers';

const MIN_TIME_BETWEEN_PROMPTS = 60 * 24 * 60 * 60 * 1000;

function* handleOptInPromptActivation(): Saga {
    // Test if inside an iframe.
    const insideIFrame: boolean = yield call(isInsideIFrame);
    if (insideIFrame) {
        console.warn('[PushNotifs] Not showing opt-in prompt because we are in an iframe');
        return;
    }
    const browserIsSupported: boolean = yield call(isSupportedBrowser);
    // Test if browser is supported.
    if (!browserIsSupported) {
        console.warn('[PushNotifs] Not showing opt-in prompt because the browser is not supported');
        return;
    }
    // Test if already accepted push notifications, or denied. In other words,
    // check if optInState is DEFAULT.
    const optInState: NotificationPermissionType = yield call(PushNotificationsWrapper.getOptIn);
    if (optInState !== NotificationPermissionType.DEFAULT) {
        console.warn(
            '[PushNotifs] Not showing opt-in prompt because the user already accepted or denied push notifications',
        );
        return;
    }

    // Test if we have already asked and user denied within last 60 days
    const meState: MeState = yield select(meSelector);
    const { id: userId }: { id: string } = yield call(dataSelector, meState);

    const browserStateInstance: PushNotificationsBrowserState = yield call(
        PushNotificationsBrowserState.getInstance,
    );
    const lastPrompts: Record<string, number> = yield call([browserStateInstance, 'getPrompts']);
    const lastPrompt = lastPrompts[userId];

    if (lastPrompt && Date.now() - lastPrompt < MIN_TIME_BETWEEN_PROMPTS) {
        console.warn(
            '[PushNotifs] Not showing opt-in prompt because the user already refused custom prompt within last 60 days',
        );
        return;
    }

    // Add a checkpoint for later.
    yield call([browserStateInstance, 'addPrompt'], { [userId]: Date.now() });

    yield put(showPushNotificationsOptInPrompt());
}

export function* handlePushNotificationsOptInPromptActivationSaga(): Saga {
    // Wait for login to complete.
    yield take(GET_ME_SUCCESS);

    const isShadowUser = yield select(isShadowUserSelector);

    // If the user is not a shadow user, then we can attempt to show modal.
    if (!isShadowUser) {
        yield takeEvery([MAYBE_SHOW_PUSH_NOTIFICATIONS_OPT_IN_PROMPT], handleOptInPromptActivation);
    } else {
        console.warn('[PushNotifs] Not showing opt-in prompt because the user is a shadow user');
    }
}
