import { DAY, MONTH, WEEK, YEAR } from 'app/common/services/allowedSeasonalityOptions';

export type CategoryOption = {
    value: string;
    name: string;
    label: string;
};
export type CategoryExportOptions = Array<CategoryOption>;

export const categoryOptions: CategoryExportOptions = [
    { value: 'all', name: 'all', label: 'all' },
    { value: 'queries', name: 'queries', label: 'queries' },
    { value: 'views_actions', name: 'views_actions', label: 'views_actions' },
];

export type PeriodOption = {
    value: string;
    name: string;
    label: string;
};
export type PeriodExportOptions = Array<PeriodOption>;
export const periodOptions: PeriodExportOptions = [
    {
        value: DAY,
        name: DAY,
        label: 'day_capitalized',
    },
    {
        value: WEEK,
        name: WEEK,
        label: 'week_capitalized',
    },
    {
        value: MONTH,
        name: MONTH,
        label: 'month_capitalized',
    },
    {
        value: YEAR,
        name: YEAR,
        label: 'year_capitalized',
    },
];
