import styled from '@emotion/styled';

export const PartnerConnectionButtonIcon = styled.div`
    width: 24px;
    height: 24px;
`;

export const AccountSpinner = styled.div`
    background-color: ${({ theme }) => theme.colors.success.initial};
`;

export const ServiceAreaButton = styled.div`
    font-feature-settings:
        'clig' off,
        'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;
