import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';

export const NoReviews = () => {
    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.ReviewManagementUnsubscribed,
    };
    return <NotFoundTemplate show={false} title="no_reviews_found" imgSrc={illustrationElement} />;
};
