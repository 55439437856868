import { GroupData } from 'app/common/reducers/groups';

const deleteGroup = (
    data: Array<GroupData> | null,
    parentGroupId: number | null,
    groupId: number,
): Array<GroupData> | null => {
    if (data) {
        if (parentGroupId) {
            return data.map(group => {
                if (group.groupId === parentGroupId) {
                    return {
                        ...group,
                        subgroups: group.subgroups?.filter(
                            subgroup => subgroup.groupId !== groupId,
                        ),
                    };
                }

                return group;
            });
        }

        return data.filter(group => group.groupId !== groupId);
    }

    return data;
};

export default deleteGroup;
