import styled from '@emotion/styled';

export const ErrorPlaceholderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;
`;

export const ErrorMessage = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.default.initial};
`;
