import { HEX_COLORS, TransparencyEnum, convertToRgba, hexToRGB } from '@partoohub/branding';

export const getInitials = (firstName: string, lastName: string): string => {
    const [strippedFirstName, strippedLastName] = removeEmojis(firstName, lastName);

    return `${strippedFirstName[0] || ''}${strippedLastName[0] || ''}`;
};

const removeEmojis = (firstName: string, lastName: string): string[] => {
    const emojiRegex = /[\p{Extended_Pictographic}]/gu;
    return [firstName.replace(emojiRegex, ''), lastName.replace(emojiRegex, '')];
};

const colors = [
    HEX_COLORS.primary,
    HEX_COLORS.success,
    HEX_COLORS.danger,
    HEX_COLORS.warning,
    HEX_COLORS.ratings,
    HEX_COLORS.emerald,
];

export const getInitialsAndBackgroundColours = (truncatedUserId: string) => {
    const index = convertAlphaToNumber(truncatedUserId) % colorsAndAlpha.length;

    return colorsAndAlpha[index];
};
const colorsAndAlpha = colors.map(color => [
    color,
    convertToRgba(hexToRGB(color), TransparencyEnum.ALPHA),
]);

const convertAlphaToNumber = (alphanumeric: string): number => {
    const strNumber = Array.from(alphanumeric).reduce((acc, alphaChar) => {
        return acc.concat(alphaChar.charCodeAt(0).toString());
    }, '');

    return parseInt(strNumber);
};
