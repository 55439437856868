import { Checkbox, Image, Text } from '@partoohub/ui';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import { STATIC_BASE_URL } from 'app/config';

import { PartnerBoxContainer, PartnerTitleContainer } from './PartnerCheckBox.styled';

interface Props {
    label: string;
    name: string;
    control: Control;
    partner: string;
    disabled?: boolean;
}

export const PartnerCheckBox = ({ label, name, control, partner, disabled = false }: Props) => {
    const { t } = useTranslation();

    return (
        <Controller
            disabled={disabled}
            name={name}
            control={control}
            render={({ field: { onChange, value: checked } }) => (
                <PartnerBoxContainer>
                    <TooltipWrapper
                        text={
                            disabled
                                ? t('no_businesses_connected_to', {
                                      disabled_partner: label,
                                  })
                                : ''
                        }
                        position="right"
                    >
                        <PartnerTitleContainer>
                            <Image
                                height={32}
                                alt="partner-logo"
                                src={`${STATIC_BASE_URL}/images/app/app_review/${partner}${
                                    disabled ? '_disabled.png' : '.png'
                                }`}
                            />
                            <Text variant="bodyLBold" as="span" disabled={disabled}>
                                {label}
                            </Text>
                        </PartnerTitleContainer>
                    </TooltipWrapper>
                    <TooltipWrapper
                        text={
                            disabled
                                ? t('no_businesses_connected_to', {
                                      disabled_partner: label,
                                  })
                                : ''
                        }
                        position="left"
                    >
                        <div style={{ paddingLeft: '4px' }}>
                            <Checkbox
                                dataTrackId="partner__checkbox"
                                aria-label=""
                                checked={checked}
                                onChange={onChange}
                                disabled={disabled}
                            />
                        </div>
                    </TooltipWrapper>
                </PartnerBoxContainer>
            )}
        />
    );
};
