import { ShortcutJim } from 'app/pages/Homepage/sections/HomepageShortcuts/shortcuts/ShortcutJim';
import { Item } from 'app/pages/Homepage/utils/typing';
import { useIsChatbotPageEnabled } from 'app/pages/settingsV2/hooks/Messages/pagesEnabled/useIsChatbotPageEnabled';

export const useShortcutJimItem = (): Item => {
    const isAvailable = useIsChatbotPageEnabled();

    return {
        order: 1,
        isAvailable,
        component: ShortcutJim,
        key: 'shortcut-jim',
    };
};
