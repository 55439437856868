import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { GROUPS } from 'app/common/data/queryKeysConstants';

export default function useGroup(groupId: number, enabled: boolean) {
    return useQuery([GROUPS, groupId], () => api.groups.getGroup(groupId), {
        enabled: enabled,
    });
}
