import React, { useEffect } from 'react';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import useDeleteGroup from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/hooks/groups/useDeleteGroup';
import useSection from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/hooks/sections/useSection';

import { GroupsSettingsQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

import DeleteGroupErrorContent from './contents/DeleteGroupErrorContent';
import DeleteGroupWarningContent from './contents/DeleteGroupWarningContent';

type Props = {
    closeModal: () => void;
    onDelete: () => void;
};

const DeleteGroupContent = ({ closeModal, onDelete }: Props) => {
    const [deleteGroupString] = useStateQueryParams(GroupsSettingsQueryKeys.DELETE);
    const [sectionId, groupId] = deleteGroupString.split(',');

    const { data: section } = useSection(sectionId);

    useEffect(() => {
        if (section && !section?.can_edit) {
            closeModal();
        }
    }, [section?.can_edit]);

    const deleteGroup = useDeleteGroup(sectionId, groupId, onDelete);
    // @ts-ignore
    const error = deleteGroup.error?.response?.data?.errors;

    if (error) {
        return <DeleteGroupErrorContent closeModal={closeModal} error={error} />;
    } else {
        return (
            <DeleteGroupWarningContent
                closeModal={closeModal}
                deleteGroup={() => deleteGroup.mutate()}
            />
        );
    }
};

export default DeleteGroupContent;
