import { Trans, useTranslation } from 'react-i18next';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { useSettingsPageContext } from 'app/pages/settings/AutoReply/hooks/useSettingsPageContext';

type Props = {
    show: boolean;
    onHide: () => void;
};

const ToggleConfirmModal = ({ show, onHide }: Props) => {
    const { t } = useTranslation();

    const {
        forReviewsWithComment,
        loadedSettings,
        saveHelpers: { saveAndDeactivate, saveAndActivate },
    } = useSettingsPageContext();

    const variant = loadedSettings?.enabled
        ? 'deactivate'
        : forReviewsWithComment
          ? 'activateWithComment'
          : 'activateWithoutComment';
    const { title, checkbox, content, confirmLabel, handleConfirm } = {
        activateWithComment: {
            title: t('auto_reply_settings_with_comment_activate_modal_title'),
            content: t('auto_reply_settings_with_comment_activate_modal_description'),
            checkbox: (
                <Trans
                    t={t}
                    i18nKey="auto_reply_settings_with_comment_activate_modal_checkbox_text"
                    components={{
                        b: <span style={{ fontWeight: 'bold' }} />,
                    }}
                />
            ),
            confirmLabel: t('auto_reply_settings_with_comment_activate_modal_confirm'),
            handleConfirm: saveAndActivate,
        },
        activateWithoutComment: {
            title: t('auto_reply_settings_reactivate_modal_title'),
            content: t('auto_reply_settings_reactivate_modal_description'),
            confirmLabel: t('auto_reply_settings_reactivate_modal_confirm'),
            handleConfirm: saveAndActivate,
        },
        deactivate: {
            title: t('auto_reply_settings_pause_modal_title'),
            content: t('auto_reply_settings_pause_modal_description'),
            confirmLabel: t('auto_reply_settings_pause_modal_confirm'),
            handleConfirm: saveAndDeactivate,
        },
    }[variant];

    return (
        <ConfirmModal
            modalFor="warning"
            show={show}
            trackId="auto_reply_toggle_confirm_modal"
            title={title}
            content={content}
            checkbox={checkbox}
            confirmLabel={confirmLabel}
            onConfirm={() => {
                handleConfirm();
                onHide();
            }}
            onHide={onHide}
        />
    );
};

export default ToggleConfirmModal;
