import { useMemo } from 'react';

import {
    CEPages,
    useCanAccessCustomerExperiencePage,
} from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import {
    CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH,
    CUSTOMER_EXPERIENCE_SEND_INVITE_PATH,
    NOT_FOUND_PATH,
} from 'app/routing/routeIds';

export const useDefaultCustomerExperiencePath = () => {
    const { canAccessCustomerExperiencePage } = useCanAccessCustomerExperiencePage();

    const canAccessToClientReviews = canAccessCustomerExperiencePage[CEPages.ClientReviews];
    const canAccessToRB = canAccessCustomerExperiencePage[CEPages.RB];
    const defaultCustomerExperiencePath = useMemo(() => {
        if (canAccessToClientReviews) {
            return CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH;
        }

        if (canAccessToRB) {
            return CUSTOMER_EXPERIENCE_SEND_INVITE_PATH;
        }

        return NOT_FOUND_PATH;
    }, [canAccessCustomerExperiencePage]);

    return defaultCustomerExperiencePath;
};
