import { useMutation } from 'react-query';

import { Chatbots } from 'app/api/v2/api_calls/chatbotsApiCalls';
import { CHATBOTS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

export const useChatbotDelete = (chatbot_id?: number) => {
    return useMutation(() => Chatbots.deleteChatbot(chatbot_id ?? -1), {
        onSuccess: () => {
            queryClient.invalidateQueries([CHATBOTS]);
        },
    });
};
