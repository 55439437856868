import { Button, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import FunnelModal from 'app/common/components/funnel';
import { FunnelObject } from 'app/common/components/funnel/FunnelContext';
import AddAddressesPage from 'app/onboarding/AddAddressesPage';
import AddNewLocationPage from 'app/onboarding/AddNewLocationPage';
import CongratulationsPage from 'app/onboarding/CongratulationsPage';
import ErrorPage from 'app/onboarding/ErrorPage';
import LoadingLocations from 'app/onboarding/LoadingLocations';
import NoAddressPage from 'app/onboarding/NoAddressPage';
import NoLocationPage from 'app/onboarding/NoLocationPage';
import { useImportFunnelOAuthAccount } from 'app/onboarding/OnboardingHooks';
import SelectionPage from 'app/onboarding/SelectionPage';
import StartAddAddressPage from 'app/onboarding/StartAddAddressPage';
import SummaryPage from 'app/onboarding/SummaryPage';

type Props = {
    showFunnel: boolean;
    setShowFunnel: Function;
};

const AddAccountButton = () => {
    const { t } = useTranslation();
    const { startOAuthFlow } = useImportFunnelOAuthAccount();

    return (
        <Button
            dataTrackId="add_account_onboarding"
            variant="secondary"
            appearance="outlined"
            shape="cube"
            icon={[FontAwesomeIconsPartooUsed.faUserPlus, IconPrefix.SOLID]}
            onClick={() => startOAuthFlow(undefined, ['onboarding_funnel'])}
        >
            {t('add_google_account')}
        </Button>
    );
};

const funnelScreens = [
    {
        id: 'add_location_page',
        component: <AddNewLocationPage />,
        progressBar: 10,
    },
    {
        id: 'loading_locations',
        component: <LoadingLocations />,
        progressBar: 50,
    },
    {
        id: 'no_location_page',
        component: <NoLocationPage />,
        backButtonAction: (funnel: FunnelObject) => {
            funnel.navigate('selection_page');
        },
        progressBar: 50,
    },
    {
        id: 'selection_page',
        component: <SelectionPage />,
        backButtonAction: (funnel: FunnelObject) => {
            funnel.navigate('add_location_page');
        },
        additionButtonsActions: <AddAccountButton />,
        progressBar: 50,
    },
    {
        id: 'congratulations_page',
        component: <CongratulationsPage />,
        progressBar: 100,
    },
    {
        id: 'error_page',
        component: <ErrorPage />,
        backButtonAction: (funnel: FunnelObject) => {
            funnel.navigate('selection_page');
        },
    },
    {
        id: 'start_add_address_page',
        component: <StartAddAddressPage />,
        backButtonAction: (funnel: FunnelObject) => {
            funnel.navigate('selection_page');
        },
        progressBar: 65,
    },
    {
        id: 'add_addresses_page',
        component: <AddAddressesPage />,
        backButtonAction: (funnel: FunnelObject) => {
            funnel.navigate('start_add_address_page');
        },
        progressBar: 75,
        displayUnsavedAlert: true,
    },
    {
        id: 'no_address_page',
        component: <NoAddressPage />,
        backButtonAction: (funnel: FunnelObject) => {
            funnel.navigate('add_addresses_page');
        },
        progressBar: 75,
    },
    {
        id: 'summary_page',
        component: <SummaryPage />,
        backButtonAction: (funnel: FunnelObject) => {
            funnel.navigate('add_addresses_page');
        },
        progressBar: 90,
        displayUnsavedAlert: true,
    },
    {
        id: 'summary_page_direct_from_selection_page',
        component: <SummaryPage />,
        backButtonAction: (funnel: FunnelObject) => {
            funnel.navigate('selection_page');
        },
        progressBar: 90,
        displayUnsavedAlert: true,
    },
];

const FunnelOnboarding = ({ showFunnel, setShowFunnel }: Props) => (
    <FunnelModal show={showFunnel} setShow={setShowFunnel} initScreens={funnelScreens} />
);

export default FunnelOnboarding;
