import styled from '@emotion/styled';

import { FunnelModalBody } from 'app/common/components/FunnelModal/FunnelModal.styled';

export const ModalBodyContainer = styled(FunnelModalBody)`
    display: flex;
`;

export const CenteredBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    margin: auto;
    max-width: 500px;
`;
