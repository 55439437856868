import styled from '@emotion/styled';

import { UserStatus as UserStatusType } from 'app/api/types/user';
import { UserStatus } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

export const RowContainer = styled.div`
    display: flex;
    min-height: 70px;
    margin-bottom: 16px;
    padding: 16px;
    box-shadow: 0 2px 16px rgba(20, 37, 66, 0.08);
    border-radius: 8px;
    align-items: center;
    font-weight: bold;
    gap: 16px;

    > * {
        flex-basis: 100%;
    }
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const UserName = styled.div`
    color: ${({ theme }) => theme.colors.default.initial};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const UserEmail = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: normal;
`;

export const UserRole = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-weight: 600;
`;

type StatusItemProps = {
    status: UserStatusType;
};

export const Status = styled.div<StatusItemProps>`
    display: flex;
    align-items: center;

    flex-basis: 40%;

    color: ${({ status, theme }) =>
        status === UserStatus.INVITED
            ? theme.colors.primary.initial
            : theme.colors.success.initial};

    > i {
        margin-right: 8px;
    }
`;

export const UserActions = styled.div`
    display: flex;
    justify-content: end;
`;
