import { put, select, takeEvery } from 'redux-saga/effects';

import {
    SET_CURRENT_GROUP_ID,
    SET_CURRENT_SUB_GROUP_ID,
    SetCurrentGroupIdAction,
    SetCurrentSubGroupIdAction,
    UNGROUPED_ID,
    getGroupByIdSelector,
    getSubGroupBySubGroupIdSelector,
    setGroupHasPuller,
} from 'app/common/reducers/groups';
import {
    setBusinessGroupIdFilter,
    setBusinessGroupIdInFilter,
} from 'app/common/reducers/newBusinesses';
import { Saga } from 'app/common/types';
import { groupStateSelector } from 'app/states/reducers';

function* setGroupIdFilter({ groupId }: SetCurrentGroupIdAction): Saga {
    const groupState = yield select(groupStateSelector) as any;
    const group = getGroupByIdSelector(groupState, groupId);

    if (group) {
        yield put(setGroupHasPuller(!!group.pullerId));
    }

    if (groupId && groupId !== UNGROUPED_ID) {
        const subGroupIds =
            group && group.subgroups
                ? group.subgroups.map(sub => sub.groupId)
                : [];
        subGroupIds.push(groupId);
        yield put(setBusinessGroupIdFilter(null));
        yield put(setBusinessGroupIdInFilter(subGroupIds));
    } else {
        yield put(setBusinessGroupIdInFilter(null));
        yield put(setBusinessGroupIdFilter(groupId));
    }
}

function* setSubGroupIdFilter({ groupId }: SetCurrentSubGroupIdAction): Saga {
    const groupState = yield select(groupStateSelector) as any;
    const subgroup = getSubGroupBySubGroupIdSelector(groupState, groupId);

    if (subgroup) {
        yield put(setGroupHasPuller(!!subgroup.pullerId));
    }
    if (groupId !== null) {
        yield put(setBusinessGroupIdInFilter(null));
        yield put(setBusinessGroupIdFilter(groupId));
    }
}

function* groupFilterSaga(): Saga {
    yield takeEvery(SET_CURRENT_GROUP_ID, setGroupIdFilter);
    yield takeEvery(SET_CURRENT_SUB_GROUP_ID, setSubGroupIdFilter);
}

export default groupFilterSaga;
