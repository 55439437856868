import styled from '@emotion/styled';

export const SortTextContainer = styled.div`
    height: 100%;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    text-transform: uppercase;
`;

type SortTextClickableProps = {
    clickable?: boolean;
};

export const SortTextClickable = styled.div<SortTextClickableProps>`
    display: flex;
    height: 100%;
    align-items: center;
    cursor: ${props => (props.clickable ? 'pointer' : 'default')};

    > i {
        margin: 0 6px;
    }

    .rotate_icon {
        transform: rotate(180deg);
    }
`;
