import React from 'react';

import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useSettingsPageContext } from 'app/pages/settings/AutoReply/hooks/useSettingsPageContext';

import { StyledErrorBanner, StyledErrorList } from './ErrorBanner.styled';

const ErrorBanner = () => {
    const { t } = useTranslation();
    const {
        saveHelpers: { hasTriedToActivate, errorBannerRef, getAutoCheckErrors },
    } = useSettingsPageContext();

    const errors = getAutoCheckErrors();

    return (
        <div ref={errorBannerRef}>
            {!!errors.length && hasTriedToActivate && (
                <StyledErrorBanner>
                    <Text variant="bodyMMedium" color={'danger'}>
                        {t('auto_reply_activate_error')}
                    </Text>
                    <StyledErrorList>
                        {errors.map(errorMsg => (
                            <Text key={errorMsg} variant="bodyMMedium" as="li" color={'danger'}>
                                {errorMsg}
                            </Text>
                        ))}
                    </StyledErrorList>
                </StyledErrorBanner>
            )}
        </div>
    );
};

export default ErrorBanner;
