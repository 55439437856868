import { AnyAction } from 'redux';

interface InitialState {
    action: AnyAction;
    count: number;
}
const initialState: InitialState = {
    action: {
        type: 'DEFAULT_REDUX_EFFECT_ACTION',
    },
    count: 0,
};

export const actionReduxEffectReducer = (
    state = initialState,
    action: AnyAction,
): InitialState => ({
    action,
    count: state.count + 1,
});
