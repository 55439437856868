import { IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { PBox } from 'app/common/components/PBox/components/PBox';

import { useFormatNumber } from 'app/common/hooks/useFormatNumber';

import AnalyticsBoxTextBody from './AnalyticsBoxTextBody';

import { StyledText } from './AnswerRate.styled';

interface Props {
    totalAnswerRate: number;
    periodAnswerRate: number;
    inputPeriod: boolean;
    totalAnswerTime: number;
    periodAnswerTime: number;
    leftLoading: boolean;
    rightLoading: boolean;
}

const AnswerRate = ({
    totalAnswerRate,
    periodAnswerRate,
    inputPeriod,
    totalAnswerTime,
    periodAnswerTime,
    leftLoading,
    rightLoading,
}: Props) => {
    const { t } = useTranslation();
    const formattedPeriodAnswerRate = useFormatNumber(+periodAnswerRate);
    const formattedTotalAnswerRate = useFormatNumber(+totalAnswerRate);
    return (
        <PBox
            title={t('reviews_answering_rate')}
            icon={['fa-clipboard-list', IconPrefix.SOLID]}
            content={
                <AnalyticsBoxTextBody
                    leftValue={`${formattedPeriodAnswerRate}%`}
                    leftValueText={
                        inputPeriod ? 'period_reviews' : 'last_six_months'
                    }
                    rightValue={`${formattedTotalAnswerRate}%`}
                    rightValueText="total_answering_rate"
                    leftLoading={leftLoading}
                    rightLoading={rightLoading}
                    isSmall={false}
                />
            }
            boxLegend={
                <>
                    <StyledText
                        variant="bodySRegular"
                        as="span"
                        color="secondary"
                        italic
                    >
                        {t('estimated_answering_time')}{' '}
                        <strong className="color--blueberry">
                            {periodAnswerTime} {t('days')}
                        </strong>
                    </StyledText>
                    <StyledText
                        variant="bodySRegular"
                        as="span"
                        color="secondary"
                        italic
                    >
                        {t('estimated_answering_time')}{' '}
                        <strong className="color--warning">
                            {totalAnswerTime} {t('days')}
                        </strong>
                    </StyledText>
                </>
            }
        />
    );
};

export default AnswerRate;
