import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import { ReplyTemplates } from 'app/reviewManagement/replyTemplate/ReplyTemplates';

export const ReviewReplyTemplatesPage = () => {
    const { t } = useTranslation();
    return (
        <>
            <DynamicPageHeader title={t('reply_templates')} />
            <ReplyTemplates />
        </>
    );
};
