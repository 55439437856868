import { css } from '@emotion/react';
import { Stack } from '@partoohub/ui';

import {
    CardsSection,
    FilterSection,
    GraphSection,
    GraphSelectorSection,
    InternalRankingSection,
} from './Sections';

export const ReviewAnalyticsV2WithNewSidebar = () => {
    return (
        <Stack>
            <FilterSection />
            <CardsSection />
            <Stack direction="row">
                <GraphSelectorSection />
                <Stack
                    css={css`
                        min-width: calc(75% - 16px);
                    `}
                >
                    <GraphSection />
                    <InternalRankingSection />
                </Stack>
            </Stack>
        </Stack>
    );
};
