import { ChangeEventHandler, MouseEvent, useRef, useState } from 'react';

import { Image, theme } from '@partoohub/ui';

import { Color } from '@partoohub/ui/dist/theme';

import { ImagesUploaderValue } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/hooks/useImagesUploaderContext';
import { preventCardOpen } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/utils';
import AddImageIcon from 'app/common/components/icons/AddImageIcon';
import { resizeImage } from 'app/utils/resizeImage';

import { ImageLoader, ImageOverlay, ImageWrapper } from './ImageUploaderRowHeader.styled';

interface ImagePreviewProps {
    image: ImagesUploaderValue;
    isLoading: boolean;
    hasError: boolean;
    setShowImage: (value: boolean) => void;
    onFileUpload: ChangeEventHandler<HTMLInputElement>;
}

export const ImagePreview = ({
    image,
    isLoading,
    hasError,
    setShowImage,
    onFileUpload,
}: ImagePreviewProps) => {
    const inputFile = useRef<HTMLInputElement | null>(null);

    const [imageHover, setImageHover] = useState(false);

    const onImageClick = (event: MouseEvent<HTMLDivElement>) => {
        preventCardOpen(event);
        if (isLoading) return;
        if (hasError) {
            inputFile?.current?.click();
            return;
        }
        setShowImage(true);
    };

    return (
        <ImageWrapper
            onMouseEnter={() => {
                setImageHover(true);
            }}
            onMouseLeave={() => {
                setImageHover(false);
            }}
            onClick={onImageClick}
            hasError={hasError}
            isLoading={isLoading}
        >
            <input
                type="file"
                accept="image/*"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={onFileUpload}
            />
            {isLoading && <ImageLoader />}
            {!isLoading && !hasError && (
                <>
                    <ImageOverlay />
                    <Image src={resizeImage(image.url)} width={40} height={40} />
                </>
            )}
            {!isLoading && hasError && (
                <AddImageIcon
                    width={24}
                    height={24}
                    color={
                        (imageHover
                            ? theme.colors.danger.dark
                            : theme.colors.danger.initial) as Color
                    }
                />
            )}
        </ImageWrapper>
    );
};
