import useAvailableLanguages from 'app/common/hooks/queries/useAvailableLanguages';
import useMe from 'app/common/hooks/queries/useMeUncamel';

const useIsLoadingProfileSettings = (): boolean => {
    const { isLoading: isMeLoading } = useMe();
    const { isLoading: isAvailableLanguagesLoading } = useAvailableLanguages();

    return isMeLoading || isAvailableLanguagesLoading;
};

export default useIsLoadingProfileSettings;
