import { List, Text } from '@partoohub/ui';

import { FeedbackFormData } from 'app/api/types/feedbackForm';

interface Props {
    form: FeedbackFormData;
}

export const FeedbackFormTitle = ({ form }: Props) => {
    return (
        <List.Cell>
            <Text variant="bodyMSemibold">{form.display_name}</Text>
        </List.Cell>
    );
};
