import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

export const OpeningDateContainerError = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
`;

export const OpeningDateErrorMessage = styled(Text)`
    margin-left: 8px;
    margin-top: 4px;
`;

// Temporary fixes, need to update OverrideDatePicker component in partoo-ui
type OverrideDatePickerWithErrorProps = {
    error: boolean;
};

export const OverrideDatePickerWithError = styled.div<OverrideDatePickerWithErrorProps>`
    ${({ error, theme }) =>
        error &&
        css`
            div {
                border-color: ${theme.colors.danger.initial};

                &:hover {
                    border-color: ${theme.colors.danger.initial};
                }
            }
        `}
`;
