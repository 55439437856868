import { ChangeEvent, useEffect, useState } from 'react';

import {
    Button,
    DatePickerType,
    FontAwesomeIconsPartooUsed,
    Icon,
    IconButton,
    IconPrefix,
    Modal,
    RangeDate,
    Stack,
    Switch,
    Text,
    TextInput,
    toast,
} from '@partoohub/ui';
import { format } from 'date-fns';
import { isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import { ApiKeyPayload } from 'app/api/types/api_keys';
import api from 'app/api/v2/api_calls';
import { LocaleDatePicker } from 'app/common/components/LocaleDatePicker/LocaleDatePicker';
import { buildToastAction } from 'app/common/components/ToastWithAction/ToastWithAction';
import { API_KEYS_LIST } from 'app/common/data/queryKeysConstants';
import { ORG_ADMIN, PROVIDER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import i18n from 'app/common/translations/i18n';
import {
    ApiKeyTypeEnum,
    ApiKeysQueryKeys,
    ApiKeysQueryParams,
} from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/utils/enums';
import { useApiKeyContext } from 'app/settingsManagement/components/ApiKey/ApiKey.context';

import queryClient from 'app/states/queryClient';
import { DATE_FORMAT } from 'app/utils/dates';

import {
    ApiKeyFormModalContainer,
    ApiKeyFormModalContent,
    ApiKeyFormModalForm,
    ApiKeyFormModalHeader,
    IconContainer,
    StyledExpirationText,
    StyledSubmitButton,
} from './ApiKeyFormModal.styled';
import IPAddressForm from './IPAddressForm';

const DEFAULT_STATE_FORM = {
    label: '',
    expiration_date: null,
    type: ApiKeyTypeEnum.USER,
    ip_whitelist: [],
};

const ApiKeyFormModal = () => {
    const { t } = useTranslation();
    const {
        defaultFormValues,
        apiKeyId,
        showApiKeyModalForm,
        setApiKeyShowModalForm,
        setApiKeyId,
    } = useApiKeyContext();
    const [typeQuery] = useStateQueryParams(ApiKeysQueryKeys.TYPE);
    const [createApiKey, setCreateApiKey] = useStateQueryParams(ApiKeysQueryParams.FORM);
    const { data: me } = useMe();

    const [form, setForm] = useState(DEFAULT_STATE_FORM);
    const [forceDisabled, setForceDisabled] = useState(false);
    const [restrictKeySwitchPosition, setRestrictKeySwitchPosition] = useState(false);
    const [enableAddIpAddress, setEnableAddIpAddress] = useState(true);
    const [submitColor, setSubmitColor] = useState<'primary' | 'danger'>('primary');
    const [submitTitleState, setSubmitTitleState] = useState('');
    const [title, setTitle] = useState('');
    const [localIpErrors, setLocalIpErrors] = useState({});
    const isApiKeyTypeUser = form.type === ApiKeyTypeEnum.USER;

    const mutationCreation = useMutation(
        (payload: ApiKeyPayload) => api.apiKeys.createApiKey(payload),
        {
            onSuccess: apiKey => {
                queryClient.invalidateQueries([API_KEYS_LIST]);
                toast.success(
                    buildToastAction(
                        t('api_key_manager_saved_snackbar_message'),
                        t('api_key_manager_saved_snackbar_button'),
                        () => navigator.clipboard.writeText(apiKey.api_key),
                    ),
                    i18n.t('api_key_manager_saved_snackbar_title'),
                );
                onClose();
            },
            onError: (error: any) => {
                setSubmitColor('danger');
                setSubmitTitleState(t('api_key_manager_error_message'));
                setForceDisabled(false);
                setLocalIpErrors(
                    JSON.parse(JSON.stringify(error?.log.response.data.errors.json))?.ip_whitelist,
                );
                toast.error(error.message, t('api_key_manager_snackbar_error'));
            },
        },
    );

    const mutationEdition = useMutation(
        (payload: ApiKeyPayload) => api.apiKeys.editApiKey(apiKeyId, payload),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([API_KEYS_LIST]);
                toast.success(null, t('api_key_manager_edited_snackbar_title'));
                onClose();
            },
            onError: (error: any) => {
                setSubmitColor('danger');
                setSubmitTitleState(t('api_key_manager_error_message'));
                setForceDisabled(false);
                setLocalIpErrors(
                    JSON.parse(JSON.stringify(error?.log.response.data.errors.json))?.ip_whitelist,
                );
                toast.error(error.message, t('api_key_manager_snackbar_error'));
            },
        },
    );

    useEffect(() => {
        if (apiKeyId) {
            setTitle(t('api_key_manager_edit_title'));
            setSubmitTitleState(t('api_key_manager_edit_save'));
        } else {
            setTitle(t('api_key_manager_create_title'));
            setSubmitTitleState(t('api_key_manager_create_save'));
        }
    }, [apiKeyId]);

    useEffect(() => {
        const hasIpRestrictions =
            defaultFormValues.ip_whitelist && defaultFormValues.ip_whitelist.length > 0;

        setForm(apiKeyId ? defaultFormValues : DEFAULT_STATE_FORM);
        setRestrictKeySwitchPosition(hasIpRestrictions);
        setEnableAddIpAddress(
            !defaultFormValues.ip_whitelist || defaultFormValues.ip_whitelist.length < 100,
        );
    }, [defaultFormValues, apiKeyId]);

    const setOnError = () => {
        setSubmitColor('danger');
        setSubmitTitleState(t('api_key_manager_error_message'));
        setForceDisabled(false);
    };

    const unsetError = () => {
        setForceDisabled(false);
        setSubmitColor('primary');
    };

    const resetState = () => {
        const hasIpRestrictions =
            defaultFormValues.ip_whitelist && defaultFormValues.ip_whitelist.length > 0;

        setForm({
            ...DEFAULT_STATE_FORM,
            ...defaultFormValues,
            ip_whitelist: defaultFormValues.ip_whitelist
                ? [...defaultFormValues.ip_whitelist]
                : DEFAULT_STATE_FORM.ip_whitelist,
        });
        setRestrictKeySwitchPosition(hasIpRestrictions);
        setEnableAddIpAddress(
            !defaultFormValues.ip_whitelist || defaultFormValues.ip_whitelist.length < 100,
        );
        setLocalIpErrors({});
    };

    const onLabelChange = value => {
        if (value.length > 100) {
            setOnError();
        } else if (Object.keys(localIpErrors).length === 0) {
            unsetError();
        }

        setForm(prevForm => ({ ...prevForm, label: value }));
    };

    const onDateChange = (dates: RangeDate) => {
        if (dates[0]) {
            const formattedDate = format(dates[0], DATE_FORMAT);
            if (formattedDate !== 'Invalid date') {
                setForm(prevForm => ({ ...prevForm, expiration_date: formattedDate }));
                setForceDisabled(false);
            } else setForceDisabled(true);
        }
    };

    const onClose = () => {
        setForm(DEFAULT_STATE_FORM);
        setApiKeyShowModalForm(false);
        setCreateApiKey('');
        setApiKeyId(0);
    };

    const onSubmit = () => {
        const cleanedForm = {
            ...form,
            ip_whitelist: restrictKeySwitchPosition
                ? form.ip_whitelist.filter(ip => ip !== '')
                : DEFAULT_STATE_FORM.ip_whitelist,
        };

        if (apiKeyId) {
            mutationEdition.mutate(cleanedForm);
        } else {
            mutationCreation.mutate(cleanedForm);
        }
    };

    const changeRestrictKeySwitch = () => {
        if (!restrictKeySwitchPosition) {
            if (!form.ip_whitelist.length) {
                addIPAddressField();
            }

            if (Object.keys(localIpErrors).length !== 0) {
                setOnError();
            }
        } else if (
            Object.keys(localIpErrors).length !== 0 &&
            form.label &&
            form.label.length <= 100
        ) {
            unsetError();
        }

        setRestrictKeySwitchPosition(!restrictKeySwitchPosition);
    };

    const addIPAddressField = () => {
        if (form.ip_whitelist.length === 99) {
            setEnableAddIpAddress(false);
        }

        const appendedWhitelist = [...form.ip_whitelist, ''];
        setForm(prevForm => ({ ...prevForm, ip_whitelist: appendedWhitelist }));
    };

    const removeIpAddress = index => {
        const filteredIpWhitelist = form.ip_whitelist.filter((x, idx) => idx !== index);
        const filteredIpErrors = { ...localIpErrors };
        delete filteredIpErrors[index];

        if (Object.keys(filteredIpErrors).length !== 0) {
            setOnError();
        } else if (form.label && form.label.length <= 100) {
            unsetError();
        }

        setForm(prevForm => ({ ...prevForm, ip_whitelist: filteredIpWhitelist }));
        setLocalIpErrors(filteredIpErrors);
        setEnableAddIpAddress(true);

        if (filteredIpWhitelist.length === 0) {
            changeRestrictKeySwitch();
        }
    };

    const onIpAddressChange = (index, ipAddress) => {
        const updatedIpErrors = { ...localIpErrors };
        const updatedIpWhitelist = [...form.ip_whitelist];
        delete updatedIpErrors[index];

        if (Object.keys(updatedIpErrors).length !== 0) {
            setOnError();
        } else if (form.label && form.label.length <= 100) {
            unsetError();
        }

        updatedIpWhitelist[index] = ipAddress;
        setForm(prevForm => ({ ...prevForm, ip_whitelist: updatedIpWhitelist }));
        setLocalIpErrors(updatedIpErrors);
    };

    const noEmptyIpsForm = {
        ...form,
        ip_whitelist: form.ip_whitelist.filter(ip => ip !== ''),
    };
    const formHasChanged =
        !isEqual({ ...DEFAULT_STATE_FORM, ...defaultFormValues }, noEmptyIpsForm) ||
        (!restrictKeySwitchPosition && form.ip_whitelist.length !== 0);
    const disabled = !formHasChanged || !form.label || forceDisabled;

    const onToggleType = (event: ChangeEvent<HTMLInputElement>) => {
        setForm(prevForm => ({
            ...prevForm,
            type: event.target.checked ? ApiKeyTypeEnum.USER : ApiKeyTypeEnum.BOT,
        }));
    };

    const minAuthorizedDateSelection = new Date();
    minAuthorizedDateSelection.setDate(minAuthorizedDateSelection.getDate() + 1);

    const expirationDate = form.expiration_date ? new Date(form.expiration_date) : null;

    const apiKeyCreationTrackingId =
        typeQuery === ApiKeyTypeEnum.BOT
            ? 'api_key_manager_save_new_key_org'
            : 'api_key_manager_save_new_key_user';

    const apiKeyEditTrackingId =
        typeQuery === ApiKeyTypeEnum.BOT
            ? 'api_key_manager_edit_key_org'
            : 'api_key_manager_edit_key_user';

    const gtmTrackId = apiKeyId ? apiKeyEditTrackingId : apiKeyCreationTrackingId;

    return (
        <Modal
            isOpen={showApiKeyModalForm || createApiKey === ApiKeysQueryParams.CREATE}
            closeModal={onClose}
            placement="center"
            animation="fadeIn"
        >
            <ApiKeyFormModalContainer>
                <ApiKeyFormModalHeader>
                    <IconButton
                        dataTrackId="close_model_apikey"
                        icon={['fa-close', IconPrefix.SOLID]}
                        onClick={onClose}
                    />
                    {formHasChanged && !!apiKeyId && (
                        <Button
                            dataTrackId="api-key-form-modal__cancel"
                            icon={<Icon icon={['fa-undo', IconPrefix.SOLID]} color="secondary" />}
                            onClick={resetState}
                            variant="secondary"
                            appearance="text"
                            size="large"
                        >
                            {t('api_key_manager_creation_clearupdates')}
                        </Button>
                    )}
                </ApiKeyFormModalHeader>
                <ApiKeyFormModalContent>
                    <Stack gap="8px" direction="row" alignItems="center">
                        <IconContainer>
                            <Icon
                                icon={[FontAwesomeIconsPartooUsed.faKey, IconPrefix.SOLID]}
                                color="white"
                            />
                        </IconContainer>
                        <Text variant="heading3" as="span">
                            {title}
                        </Text>
                    </Stack>
                    <ApiKeyFormModalForm>
                        <TextInput
                            dataTrackId="api_key_manager_label"
                            placeholder={t('api_key_manager_label_placeholder')}
                            notice={t('api_key_manager_label_guidance')}
                            onChange={onLabelChange}
                            value={form.label}
                            required
                            autoFocus
                            characterCount={100}
                        />
                        <LocaleDatePicker
                            dataTrackId="expiration_date_picker_apikey"
                            type={DatePickerType.DateSelector}
                            dates={[expirationDate, null]}
                            labels={{
                                startDate: t('api_key_manager_expirationdate_placeholder'),
                            }}
                            placeholders={{
                                startDate: t('date_placeholder'),
                            }}
                            onChange={onDateChange}
                            minDate={minAuthorizedDateSelection}
                        />
                        <StyledExpirationText variant="bodySRegular" as="span" color="secondary">
                            {t('api_key_manager_expirationdate_helper')}
                        </StyledExpirationText>
                        <div>
                            <IPAddressForm
                                ipWhitelist={form.ip_whitelist}
                                ipErrors={localIpErrors}
                                changeRestrictKeySwitch={changeRestrictKeySwitch}
                                removeIpAddress={removeIpAddress}
                                changeIPAddress={(index, IPAddress) => {
                                    onIpAddressChange(index, IPAddress);
                                }}
                                restrictKeySwitchPosition={restrictKeySwitchPosition}
                                enableAddIpAddress={enableAddIpAddress}
                                addIPAddressField={addIPAddressField}
                            />
                        </div>
                        <Switch
                            dataTrackId=""
                            name="api_key_type_switch"
                            checked={isApiKeyTypeUser}
                            onChange={onToggleType}
                            disabled={me?.role !== ORG_ADMIN && me?.role !== PROVIDER}
                            label={
                                <Text variant="bodyMBold" as="span" color="secondary">
                                    {t(
                                        isApiKeyTypeUser
                                            ? 'api_keys_user_type'
                                            : 'api_keys_bot_type',
                                    )}
                                </Text>
                            }
                        />
                    </ApiKeyFormModalForm>

                    <StyledSubmitButton
                        size="large"
                        variant={submitColor === 'danger' ? 'danger' : 'primary'}
                        appearance="contained"
                        shape="cube"
                        icon={[FontAwesomeIconsPartooUsed.faKey, IconPrefix.SOLID]}
                        onClick={onSubmit}
                        disabled={disabled}
                        dataTrackId={gtmTrackId}
                        full
                    >
                        {submitTitleState}
                    </StyledSubmitButton>
                </ApiKeyFormModalContent>
            </ApiKeyFormModalContainer>
        </Modal>
    );
};

export default ApiKeyFormModal;
