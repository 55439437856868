import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const BusinessModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: min(800px, 100vw);
`;

export const BusinessModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 20px 8px 20px;
`;

export const BusinessTitle = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: 8px 0 28px 0;
`;

export const BusinessModalFilters = styled.div`
    display: flex;
`;

export const BodyTitleContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 1px;
    align-items: baseline;
`;

export const BodyTitleSelect = styled.div`
    &:hover {
        cursor: pointer;
    }
`;

export const NoResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    height: calc(3.45 * #{66px} + 2 * #{8px});
    overflow-y: auto;
    overflow-anchor: none;

    @media (max-width: 769px) {
        height: 100%;
        overflow-y: initial;
    }
`;

export const NoBusinessText = styled(Text)`
    margin: auto;
`;
