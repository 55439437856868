import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';
import { Icon, Text } from '@partoohub/ui';

export const SmallBusinessCardContainer = styled.div<{ selected?: boolean; clickable?: boolean }>`
    position: relative;
    display: flex;
    min-width: 0;
    height: 66px;
    align-items: center;
    padding: 6px;
    background-color: white;
    border-radius: 4px;
    padding: 6px;
    border: ${({ selected, theme }) =>
        selected
            ? `2px solid ${theme.colors.primary.initial}`
            : `1px solid ${greyColorObject.initial}`};
    ${({ clickable }) =>
        clickable &&
        css`
            cursor: pointer;
            user-select: none;

            &:focus {
                outline: 0;
            }
        `};
`;

export const BusinessCardImageSmall = styled.div<{ url: string }>`
    display: flex;
    min-width: 50px;
    height: 50px;
    border-radius: 4px;
    background-image: ${({ url }) => `url(${url})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`;

export const BusinessCardLines = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-flow: nowrap column;
    padding-left: 16px;
    min-width: 0;
`;

export const StyledText = styled(Text)`
    width: 100%;
`;

export const TickBox = styled.div`
    position: absolute;
    width: 39px;
    height: 39px;
    top: 0;
    right: 0;
    border-radius: 4px;
    &::after {
        position: absolute;
        content: ' ';
        border-top: 39px solid ${({ theme }) => theme.colors.primary.initial};
        border-left: 39px solid transparent;
        width: 0;
    }
`;

export const StyledIconTickBox = styled(Icon)`
    position: absolute;
    padding: 4px;
    color: #fff;
    top: 0;
    right: 0;
    z-index: 2;
`;
