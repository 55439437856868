import { V2FormattedLocationData } from 'app/api/types/account';
import {
    FACEBOOK,
    GOOGLE_MY_BUSINESS,
} from 'app/states/knowledge/reducers/businessConnection/accounts';
import {
    FacebookResourceAction,
    allFacebookLocationSearchResultsSelector,
    facebookLocationGetRequestErrorSelector,
    facebookLocationSearchResultsSelector,
    facebookLocationSelector,
    isSearchingOnServerSelector as facebookSearchingOnServer,
    getFacebookLocation,
} from 'app/states/knowledge/reducers/businessConnection/facebook/facebookLocations';
import {
    GmbResourceAction,
    allGmbLocationsSearchResultsSelector,
    getGmbLocation,
    gmbLocationGetRequestErrorSelector,
    gmbLocationSearchResultsSelector,
    gmbLocationSelector,
    isSearchingOnServerSelector as gmbSearchingOnServer,
} from 'app/states/knowledge/reducers/businessConnection/gmb/gmbLocations';
import {
    AppState,
    facebookLocationsSelector,
    gmbLocationsSelector,
} from 'app/states/reducers';

export type PartnerResourceAction = GmbResourceAction | FacebookResourceAction;

export type PartnerLocationMethods = {
    locationSelector(
        arg0: AppState,
        arg1: string,
    ): V2FormattedLocationData | void;
    locationSearchResultsSelector(
        arg0: AppState,
        arg1: string,
    ): V2FormattedLocationData | void;
    getLocation(arg0: string): PartnerResourceAction;
    locationGetRequestErrorSelector(
        arg0: AppState,
        arg1: string,
    ): Record<string, any> | null;
    allLocationsSearchResultsSelector(
        arg0: AppState,
    ): Array<V2FormattedLocationData>;
    isSearchingOnServerSelector(arg0: AppState): boolean;
};

const google: PartnerLocationMethods = {
    locationSelector: (state: AppState, locationId: string) =>
        gmbLocationSelector(gmbLocationsSelector(state), locationId),
    locationSearchResultsSelector: (state: AppState, locationId: string) =>
        gmbLocationSearchResultsSelector(
            gmbLocationsSelector(state),
            locationId,
        ),
    getLocation: getGmbLocation,
    locationGetRequestErrorSelector: (state: AppState, locationId: string) =>
        gmbLocationGetRequestErrorSelector(
            gmbLocationsSelector(state),
            locationId,
        ),
    allLocationsSearchResultsSelector: (state: AppState) =>
        allGmbLocationsSearchResultsSelector(gmbLocationsSelector(state)),
    isSearchingOnServerSelector: (state: AppState) =>
        gmbSearchingOnServer(gmbLocationsSelector(state)),
};

const facebook: PartnerLocationMethods = {
    locationSelector: (state: AppState, locationId: string) =>
        facebookLocationSelector(facebookLocationsSelector(state), locationId),
    locationSearchResultsSelector: (state: AppState, locationId: string) =>
        facebookLocationSearchResultsSelector(
            facebookLocationsSelector(state),
            locationId,
        ),
    getLocation: getFacebookLocation,
    locationGetRequestErrorSelector: (state: AppState, locationId: string) =>
        facebookLocationGetRequestErrorSelector(
            facebookLocationsSelector(state),
            locationId,
        ),
    allLocationsSearchResultsSelector: (state: AppState) =>
        allFacebookLocationSearchResultsSelector(
            facebookLocationsSelector(state),
        ),
    isSearchingOnServerSelector: (state: AppState) =>
        facebookSearchingOnServer(facebookLocationsSelector(state)),
};

const partnerMethods = {
    [FACEBOOK]: facebook,
    [GOOGLE_MY_BUSINESS]: google,
};

export default partnerMethods;
