import useServices from 'app/businessEditV2/hooks/services/useServices';

export default function useDisplayServices(): boolean {
    const { error: servicesError, isSuccess } = useServices();

    const canModifyServiceList =
        servicesError?.response?.data?.errors?.authorization?.services !==
        'not_allowed';

    return isSuccess && canModifyServiceList;
}
