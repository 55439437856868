import styled from '@emotion/styled';

import { List, Text } from '@partoohub/ui';

export const LoadingBusinessRowContainer = styled.div`
    display: flex;
    height: 96px;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    margin-bottom: 16px;
    border-radius: ${({ theme }) => theme.radius.large};
    overflow: hidden;
    box-shadow: ${({ theme }) => theme.shadows.light};
`;

export const ClosedBusinessText = styled(Text)`
    padding-right: 24px;
`;

export const BusinessRowContainer = styled(List.Row)``;
