import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { POST_PUBLISHER_STATES_EXPORT } from 'app/common/data/queryKeysConstants';

export const usePostPublisherStatesExport = (email?: string) => {
    const { t } = useTranslation();

    return useQuery(
        [POST_PUBLISHER_STATES_EXPORT, email],
        () => api.publisherState.postPublisherStatesExport(email!),
        {
            enabled: false,
            onSuccess: () => {
                toast.success(null, t('your_analytics_will_be_sent_by_email'));
            },
            onError: () => {
                toast.error(null, t('reply_error'));
            },
        },
    );
};
