import { FC, ReactNode, createContext, useContext, useMemo, useState } from 'react';

import { ImagesUploaderError } from '../utils';

export interface ImagesUploaderValue {
    url: string;
    texts: Record<string, string>;
    size: number;
    name: string;
}

interface ImagesUploaderContextType {
    textFields: Array<string>;
    maxLengths: Array<number>;
    images: Array<ImagesUploaderValue>;
    errors: Array<ImagesUploaderError>;
    setErrors: (value: Array<ImagesUploaderError>) => void;
    setImages: (value: ImagesUploaderValue[]) => void;
    deleteImage: (index: number) => void;
    replaceImage: (index: number, url: string, size: number, name: string) => void;
    updateTextField: (index: number, name: string, value: string) => void;
}
const ImagesUploaderContext = createContext<ImagesUploaderContextType>({
    textFields: [],
    maxLengths: [],
    images: [],
    errors: [],
    setErrors: (_value: Array<ImagesUploaderError>) => undefined,
    setImages: (_value: ImagesUploaderValue[]) => undefined,
    deleteImage: (_index: number) => undefined,
    replaceImage: (_index: number, _url: string, _size: number, _name: string) => undefined,
    updateTextField: (_index: number, _name: string, _value: string) => undefined,
});

export const useImagesUploaderContext = () =>
    useContext<ImagesUploaderContextType>(ImagesUploaderContext);

interface Props {
    children: ReactNode;
    initialValue?: ImagesUploaderValue[];
    textFields: Array<string>;
    maxLengths: Array<number>;
}

export const ImagesUploaderProvider: FC<Props> = ({
    children,
    initialValue = [],
    textFields,
    maxLengths,
}) => {
    const [images, setImages] = useState<ImagesUploaderValue[]>(initialValue ?? []);

    const [errors, setErrors] = useState<Array<ImagesUploaderError>>([]);

    const deleteImage = (index: number) => {
        setImages([...images.slice(0, index), ...images.slice(index + 1)]);

        // delete errors associated to the deleted image and redefine the new index of other errors
        const newErrors = errors.filter(err => err.index !== index);
        for (const err of newErrors) {
            if (err.index > index) {
                err.index--;
            }
        }
        setErrors(newErrors);
    };

    const replaceImage = (index: number, url: string, size: number, name: string) => {
        let imageToUpdate = images[index] as ImagesUploaderValue;
        imageToUpdate = {
            ...imageToUpdate,
            url,
            size,
            name,
        };
        setImages([...images.slice(0, index), imageToUpdate, ...images.slice(index + 1)]);
    };

    const updateTextField = (index: number, name: string, value: string) => {
        const imageToUpdate = images[index] as ImagesUploaderValue;
        imageToUpdate.texts[name] = value;
        setImages([...images.slice(0, index), imageToUpdate, ...images.slice(index + 1)]);
    };

    const value: ImagesUploaderContextType = useMemo(
        () => ({
            textFields,
            maxLengths,
            errors,
            setErrors,
            images,
            setImages,
            deleteImage,
            replaceImage,
            updateTextField,
        }),
        [images, errors, setErrors, setImages, deleteImage, replaceImage, updateTextField],
    );

    return (
        <ImagesUploaderContext.Provider value={value}>{children}</ImagesUploaderContext.Provider>
    );
};
