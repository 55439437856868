import styled from '@emotion/styled';

export const PasswordSubformContainer = styled.div`
    padding-bottom: 16px;
`;

export const ToggleContainer = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

export const ToggleText = styled.div`
    margin-left: 8px;
`;

export const UpdatePasswordField = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 24px;
    gap: 6px;

    &:hover {
        cursor: pointer;
    }
`;
