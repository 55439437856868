import { useCallback, useMemo } from 'react';

import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ToastWithAction } from 'app/common/components/ToastWithAction/ToastWithAction';
import useLocalStorage from 'app/common/hooks/useLocalStorage';
import dataLayer from 'app/common/utils/dataLayer';

interface Props {
    storageKey: string;
    descriptionKey: string;
    actionKey: string;
    actionTrackEvent?: string;
}
const ShowReminderToast = ({ storageKey, descriptionKey, actionKey, actionTrackEvent }: Props) => {
    const { t } = useTranslation();
    const [nextDateToShowModal, setNextDateToShowModal] = useLocalStorage<string | null>(
        storageKey,
        null,
    );

    const calculateNextDateIn = useCallback(() => {
        const now = new Date();
        const nextDate = nextDateToShowModal ? new Date(nextDateToShowModal) : now;
        return Math.floor((nextDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)) + 1;
    }, [nextDateToShowModal]);

    const nextDateToShowModalIn = useMemo(calculateNextDateIn, [calculateNextDateIn]);

    const handleAction = useCallback(() => {
        const nextYear = new Date();
        nextYear.setFullYear(nextYear.getFullYear() + 1);
        setNextDateToShowModal(nextYear.toString());
        if (actionTrackEvent) {
            dataLayer.pushDict(actionTrackEvent);
        }
    }, [setNextDateToShowModal, actionTrackEvent]);

    const getDescription = useCallback(
        () => t(descriptionKey, { count: nextDateToShowModalIn }),
        [t, descriptionKey, nextDateToShowModalIn],
    );

    return (
        <ToastWithAction description={getDescription()} action={handleAction}>
            {t(actionKey)}
        </ToastWithAction>
    );
};

export const instagramReminderToast = (title: string) => {
    toast.success(
        <ShowReminderToast
            storageKey="nextDateToShowInstagramModal"
            descriptionKey="messaging_add_instagram_information_modale_later_snackbar_text"
            actionKey="messaging_add_instagram_information_modale_later_snackbar_button"
            actionTrackEvent="click_never_again_instagram_self_onboarding_snackbar"
        />,
        title,
    );
};

export const messengerReminderToast = (title: string) => {
    toast.success(
        <ShowReminderToast
            storageKey="nextDateToShowMessengerModal"
            descriptionKey="messaging_add_messenger_information_modale_later_snackbar_text"
            actionKey="messaging_add_messenger_information_modale_later_snackbar_button"
        />,
        title,
    );
};
