import { push } from '@lagunovsky/redux-react-router';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { STATIC_BASE_URL } from 'app/config';

import {
    LoginFormButton,
    LoginFormContainer,
    LoginFormSuccessImage,
    LoginFormSuccessMessage,
} from 'app/connection/components/LoginForm/LoginForms.styled';
import { CONNECTION_PATH } from 'app/routing/routeIds';
import {
    isSuccessSelector,
    resetResetPasswordForm,
} from 'app/states/connection/reducers/resetPassword';

import { AppState } from 'app/states/reducers';

import { ResetPasswordForm as Form } from './Form';

export const ResetPasswordForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const resetPassword = useSelector((state: AppState) => state.resetPassword);

    const isSuccess = isSuccessSelector(resetPassword);

    if (!isSuccess) {
        return <Form />;
    }

    return (
        <LoginFormContainer>
            <LoginFormSuccessImage
                src={`${STATIC_BASE_URL}/images/app/success.svg`}
                alt="success"
            />
            <LoginFormSuccessMessage>
                <img
                    src={`${STATIC_BASE_URL}/images/common/custom-svg/success.svg`}
                    alt="success"
                />
                {t('reset_password_success')}
            </LoginFormSuccessMessage>

            <LoginFormButton
                variant="primary"
                shape="cube"
                size="large"
                full
                onClick={() => {
                    dispatch(resetResetPasswordForm());
                    dispatch(push(CONNECTION_PATH));
                }}
                dataTrackId="reset_password_success_button"
                type="submit"
            >
                {t('connection_to_app')}
            </LoginFormButton>
        </LoginFormContainer>
    );
};
