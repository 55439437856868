import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { MORE_HOURS } from 'app/common/data/queryKeysConstants';
import { useLanguage } from 'app/common/services/getLang';

export default function useMoreHours() {
    const { data: business } = useBusiness();
    const lang = useLanguage();
    return useQuery(
        [MORE_HOURS, { category: business?.categories[0] }, lang],
        () =>
            api.moreHours.getMoreHours({
                category: business?.categories?.[0] ?? '',
                country: business?.country ?? null,
            }),
        {
            enabled: !!business?.categories?.[0],
        },
    );
}
