import { useMemo } from 'react';

import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack, Text, Tooltip } from '@partoohub/ui';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { BenchmarkContent } from './content.styled';
import { LocalTitleContainer } from './LocalCompetitiveBenchmark.styled';

import { useComparisonDateFilter } from '../../hooks/useComparisonDateFilter';
import { useLocalRankings } from '../../hooks/useLocalRankings';
import { buildLocalRows } from '../../utils/buildTableRowOptions';
import { CompetitorTableSection } from '../CompetitorTableSection/CompetitorTableSection';

const TableHeaderTitle = () => {
    const { t } = useTranslation();
    return (
        <LocalTitleContainer>
            <Stack direction="row" alignItems="center">
                <Text variant="heading6" as={'span'}>
                    {t('comparison_with_competitors_word')}
                </Text>
                <Tooltip text={t('tooltip_appearance_rate')}>
                    <Icon
                        icon={[FontAwesomeIconsPartooUsed.faCircleInfo, IconPrefix.REGULAR]}
                        color="secondary"
                        iconSize="16px"
                    />
                </Tooltip>
            </Stack>
        </LocalTitleContainer>
    );
};

const getLocalColumns = (t: TFunction<'translation', undefined>) => {
    return [
        {
            label: t('name'),
            width: '400px',
        },
        {
            label: t('ranking_wording_column'),
            orderOn: 'rank',
        },
        {
            label: t('reviews'),
            orderOn: 'reviews',
        },
        {
            label: t('note'),
            orderOn: 'rating',
        },
    ];
};

export const LocalCompetitiveBenchmark = () => {
    const [currentDate, comparisonDate] = useComparisonDateFilter();
    const { data, isLoading } = useLocalRankings(currentDate);
    const { data: lastData } = useLocalRankings(comparisonDate);

    const rows = buildLocalRows(data, lastData);
    const { t } = useTranslation();
    const columns = useMemo(() => getLocalColumns(t), []);
    return (
        <BenchmarkContent>
            <TableHeaderTitle />
            <CompetitorTableSection rows={rows} isLoading={isLoading} columns={columns} />
        </BenchmarkContent>
    );
};
