// @ts-nocheck
import React from 'react';

import {
    CardContainer,
    CardContent,
    CardTitle,
    ContentColumn,
    ContentColumnIcon,
    ContentColumnText,
    ContentColumnTextDetail,
} from './InfoCard.styled';

export const Title = ({ children, ...extraProps }) => (
    <CardTitle {...extraProps}>{children}</CardTitle>
);

export const Content = ({ children }) => <CardContent>{children}</CardContent>;

// Todo, refactor this in it's own compund component
export const ContentColumnComponent = ({
    iconClass,
    title,
    text,
    showAlert = false,
}) => (
    <ContentColumn>
        <ContentColumnIcon>
            <i className={`${iconClass} fa-lg`} />
        </ContentColumnIcon>
        <ContentColumnText>
            <h5>{title}</h5>
            <ContentColumnTextDetail>
                <p>{text}</p>
                {showAlert && (
                    <i
                        className="fa fa-exclamation-circle"
                        aria-hidden="true"
                    />
                )}
            </ContentColumnTextDetail>
        </ContentColumnText>
    </ContentColumn>
);

export default function InfoCard({ children }) {
    return <CardContainer>{children}</CardContainer>;
}
