import { useEffect, useState } from 'react';

import { Button, IconPrefix, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { PhotosData } from 'app/api/types/business';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import usePhotosUpload from 'app/businessEditV2/hooks/usePhotosUpload';
import { LOGO_PHOTO, PRIMARY_PHOTO } from 'app/businessEditV2/oldFromEditV1/data/photoConsts';
import getAllPhotos, {
    addNewSecondaries,
    businessEditMaxPhotoSize,
    businessEditMinPhotoSize,
    deleteUrl,
    getFirstPhotoUploadFileError,
    getLabel,
    setCover,
    setLogo,
} from 'app/businessEditV2/utils/photos';
import AddImageIcon from 'app/common/components/icons/AddImageIcon';
import FullScreenImage from 'app/common/designSystem/components/atoms/FullScreenImage';
import DropZone from 'app/common/designSystem/components/molecules/DropZone';
import {
    FileError,
    getErrorsFromFiles,
} from 'app/common/designSystem/components/molecules/DropZone/helpers';
import dataLayer from 'app/common/utils/dataLayer';

import { getMappedUrl, resizeImage } from 'app/utils/resizeImage';

import Photo from './Photo';
import {
    DropZoneContainer,
    FullScreenImageRight,
    FullScreenImageRightIcons,
    FullScreenImageRightText,
    PhotosContainer,
} from './PhotosTable.styled';

type Props = {
    value: PhotosData;
    onChange: (photos: PhotosData) => void;
    secondaryPermission: boolean;
    logoPermission: boolean;
    coverPermission: boolean;
};

const PhotosTable = ({
    value,
    onChange,
    secondaryPermission,
    logoPermission,
    coverPermission,
}: Props) => {
    const { t } = useTranslation();

    const { data: business } = useBusiness();
    const photos = getAllPhotos(value);
    const [fileErrors, setFileErrors] = useState<FileError[]>([]);

    // Fullscreen preview
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showFullScreenImage, setShowFullScreenImage] = useState(false);

    useEffect(() => {
        if (showFullScreenImage && !photos.length) {
            setShowFullScreenImage(false);
        }
    }, [photos]);

    const uploadPhotos = usePhotosUpload(
        images => {
            onChange(addNewSecondaries(value, images.result.uploads));
        },
        error => {
            const fileErrors = getFirstPhotoUploadFileError(error);
            if (fileErrors) {
                setFileErrors([fileErrors]);
            }
        },
    );

    const onPhotoClick = (index: number) => {
        setCurrentImageIndex(index);
        setShowFullScreenImage(true);
    };

    const onNewImage = (right: boolean) => {
        const next = right ? 1 : -1;
        setCurrentImageIndex(((currentImageIndex || 0) + next + photos.length) % photos.length);
    };

    const deletePhoto = (index: number) => {
        onChange(deleteUrl(value, photos[index].url));
        setCurrentImageIndex(Math.min(index, photos.length - 2));
    };

    const setAsLogo = (index: number) => {
        const newValue = setLogo(value, photos[index].url);
        onChange(newValue);
        setCurrentImageIndex(newValue.primary ? 1 : 0);
    };

    const setAsCover = (index: number) => {
        onChange(setCover(value, photos[index].url));
        setCurrentImageIndex(0);
    };

    const currentCategory = photos[currentImageIndex]?.category;

    const canSetCover = coverPermission && currentCategory !== PRIMARY_PHOTO;
    const canSetLogo = logoPermission && currentCategory !== LOGO_PHOTO;

    const isLogoWithoutPermission = currentCategory === LOGO_PHOTO && !logoPermission;
    const isCoverWithoutPermission = currentCategory === PRIMARY_PHOTO && !coverPermission;

    const displaySetCover = canSetCover && !isLogoWithoutPermission;
    const displaySetLogo = canSetLogo && !isCoverWithoutPermission;

    return (
        <div>
            <PhotosContainer>
                {secondaryPermission && (
                    <DropZoneContainer
                        data-track="visibility_location__form_photos__add_photo_dropzone"
                        data-intercom-target="visibility_location__form_photos__add_photo_dropzone"
                    >
                        <DropZone
                            files={[]}
                            handleDropSuccess={files => {
                                setFileErrors([]);
                                uploadPhotos.mutate(files);
                            }}
                            handleDropError={files => {
                                const errors = getErrorsFromFiles(files, {
                                    maxSize: businessEditMaxPhotoSize,
                                    minSize: businessEditMinPhotoSize,
                                });
                                setFileErrors(errors);
                            }}
                            label={t('menu_photo_dropzone_label')}
                            uploadIcon={<AddImageIcon />}
                            acceptedFiles={[
                                {
                                    memeType: 'image/jpeg',
                                    extension: 'JPEG',
                                },
                                {
                                    memeType: 'image/jpg',
                                    extension: 'JPG',
                                },
                                {
                                    memeType: 'image/png',
                                    extension: 'PNG',
                                },
                            ]}
                            isCompact
                            isBusinessEditV2
                            useCompactError
                            hideAcceptedFiles={true}
                            errors={fileErrors}
                            maxSize={businessEditMaxPhotoSize}
                            disabled={uploadPhotos.isLoading}
                            isLoading={uploadPhotos.isLoading}
                        />
                    </DropZoneContainer>
                )}
                {photos.map(({ category, url }, index) => (
                    <Photo
                        key={`photo_preview_${category}_${url}`}
                        category={category}
                        url={resizeImage(getMappedUrl(url, business?.media_mapping))}
                        onClick={() => onPhotoClick(index)}
                        deletePhoto={() => deletePhoto(index)}
                        secondaryPermission={secondaryPermission}
                        logoPermission={logoPermission}
                        coverPermission={coverPermission}
                    />
                ))}
            </PhotosContainer>
            <Modal
                isOpen={showFullScreenImage && !!photos.length}
                closeModal={() => setShowFullScreenImage(false)}
                placement="center"
                animation="fadeIn"
            >
                <FullScreenImage
                    imageIndex={currentImageIndex}
                    possibleImagesUrls={photos.map(photo =>
                        resizeImage(getMappedUrl(photo.url, business?.media_mapping)),
                    )}
                    onHideClick={() => {
                        setShowFullScreenImage(false);
                    }}
                    onNewImage={onNewImage}
                    show={showFullScreenImage}
                    rightChild={
                        <FullScreenImageRight>
                            <FullScreenImageRightText>
                                <i className="fa fa-image" />
                                {t(getLabel(currentCategory))}
                            </FullScreenImageRightText>
                            <FullScreenImageRightIcons>
                                {displaySetCover && (
                                    <Button
                                        dataTrackId="set_cover__button"
                                        full
                                        variant="secondary"
                                        appearance="outlined"
                                        shape="cube"
                                        onClick={() => {
                                            setAsCover(currentImageIndex);
                                            dataLayer.pushDict('set_as_main_photo');
                                        }}
                                    >
                                        {t('business_edit_photo_edit_set_main')}
                                    </Button>
                                )}
                                {displaySetLogo && (
                                    <Button
                                        dataTrackId="set_logo__button"
                                        full
                                        appearance="outlined"
                                        shape="cube"
                                        variant="secondary"
                                        onClick={() => {
                                            setAsLogo(currentImageIndex);
                                            dataLayer.pushDict('set_as_logo_photo');
                                        }}
                                    >
                                        {t('business_edit_photo_edit_set_logo')}
                                    </Button>
                                )}
                                {secondaryPermission && (
                                    <Button
                                        dataTrackId="delete_image__button"
                                        full
                                        appearance="outlined"
                                        shape="cube"
                                        variant="danger"
                                        onClick={() => deletePhoto(currentImageIndex)}
                                        icon={['fa-trash-alt', IconPrefix.SOLID]}
                                    >
                                        {t('delete')}
                                    </Button>
                                )}
                            </FullScreenImageRightIcons>
                        </FullScreenImageRight>
                    }
                />
            </Modal>
        </div>
    );
};

export default PhotosTable;
