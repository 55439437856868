import { ReportUserPreferencesResponse } from 'app/api/types/user';

import { NotificationsFormState } from './types';

export const formStateToReportUserPreferences = (
    formState: NotificationsFormState,
    hasPartners: { hasTripAdvisor: boolean; hasFacebook: boolean; hasGoogle: boolean },
): ReportUserPreferencesResponse => {
    const { hasTripAdvisor, hasFacebook, hasGoogle } = hasPartners;

    const preferences: ReportUserPreferencesResponse = {
        reports: {
            review_management_daily: formState.reports.selectRmDailyReport,
            review_management_weekly: formState.reports.selectRmWeeklyReport,
            review_management_monthly: formState.reports.selectRmMonthlyReport,
            daily_review_preferences: undefined,
        },
        receive_negative_reviews_email_notifications: formState.receiveReviewsEmailNotifications,
    };

    if (formState.reports.selectRmDailyReport) {
        preferences.reports = {
            ...preferences.reports,
            daily_review_preferences: {
                partners: [
                    hasTripAdvisor && formState.reports.dailyReportPartners.tripAdvisor
                        ? 'tripAdvisor'
                        : '',
                    hasFacebook && formState.reports.dailyReportPartners.facebook ? 'facebook' : '',
                    hasGoogle && formState.reports.dailyReportPartners.google
                        ? 'google_my_business'
                        : '',
                ].filter(Boolean),
                review_type: formState.reports.reviewType,
            },
        };
    }
    return preferences;
};
