import { useTranslation } from 'react-i18next';

import { BusinessUser } from 'app/api/types/user';

import { AvatarContainer, LabelContainer, OverridenText } from './AssignConversationButton.styled';
import { UserAvatar } from '../../UserAvatar/UserAvatar';

interface UserLabelProps {
    assignedUser: BusinessUser | null;
}

export const UserLabel = ({ assignedUser }: UserLabelProps) => {
    const { t } = useTranslation();
    const label =
        (assignedUser && `${assignedUser.firstName} ${assignedUser.lastName}`) ||
        t('messaging_assign_icon_user_not_found');

    return (
        <LabelContainer>
            <AvatarContainer>
                <UserAvatar user={assignedUser} size={20} textSize={10} />
            </AvatarContainer>
            <OverridenText as={'span'} variant="bodySSemibold">
                {label}
            </OverridenText>
        </LabelContainer>
    );
};
