import { useMutation, useQueryClient } from 'react-query';

import { PlaceActionLinksPostRequest } from 'app/api/types/placeActionLinks';
import api from 'app/api/v2/api_calls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { BUSINESS } from 'app/common/data/queryKeysConstants';

export default function usePlaceActionLinksUpdate(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
    ignoreInvalidateQueries = false,
) {
    const businessId = useGetBusinessId();
    const queryClient = useQueryClient();
    return useMutation(
        (payload: PlaceActionLinksPostRequest) =>
            api.business.businessPlaceActionLinksPost(businessId, payload),
        {
            onSuccess: () => {
                if (ignoreInvalidateQueries) {
                    return onSuccess();
                }
                queryClient.invalidateQueries(invalidatesAny(BUSINESS)).then(onSuccess);
            },
            onError,
        },
    );
}
