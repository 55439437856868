import { Control } from 'react-hook-form/dist/types';

import { useTranslation } from 'react-i18next';

import { BusinessFieldType } from 'app/api/types/business';
import { namesMapping } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldFormWithSections';
import CustomFieldsTypeBoolean from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeBoolean';
import CustomFieldsTypeImagesUploader from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader';
import CustomFieldsTypeMultipleSelect from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeMultipleSelect';
import CustomFieldsTypeMultipleSelectImage from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeMultipleSelectImage';
import CustomFieldsTypeNumber from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeNumber';
import CustomFieldsTypeSingleSelect from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeSingleSelect';
import CustomFieldsTypeText from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeText';
import { customFieldsType as types } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/services';

type Props = {
    customField: BusinessFieldType;
    namesMapping: namesMapping;
    errors: any;
    control: Control<any>;
};

export const CustomField = ({ customField, namesMapping, errors, control }: Props) => {
    const { t } = useTranslation();

    // Permissions
    const displayName = customField.name;
    const disabled = !customField?.enabled;
    const key = `custom_fields_${displayName}`;
    const name = namesMapping.find(cf => cf.name === displayName)!.mapping;

    const errorMessage = errors[customField.name]
        ? t(errors[customField.name].message, errors[customField.name])
        : '';
    switch (customField.type) {
        case types.INTEGER:
            return (
                <CustomFieldsTypeNumber
                    name={name}
                    displayName={displayName}
                    control={control}
                    key={key}
                    type="integer"
                    disabled={disabled}
                    min={customField?.min}
                    max={customField?.max}
                    error={
                        errors[customField.name]
                            ? t(errors[customField.name].message, errors[customField.name])
                            : ''
                    }
                />
            );

        case types.TEXT:
            return (
                <CustomFieldsTypeText
                    name={name}
                    displayName={displayName}
                    control={control}
                    key={key}
                    disabled={disabled}
                    maxLen={customField?.max_len}
                    error={
                        errors[customField.name]
                            ? t(errors[customField.name].message, errors[customField.name])
                            : ''
                    }
                />
            );

        case types.BOOLEAN:
            return (
                <CustomFieldsTypeBoolean
                    name={name}
                    displayName={displayName}
                    control={control}
                    key={key}
                    disabled={disabled}
                />
            );

        case types.SINGLE_SELECT:
            return (
                <CustomFieldsTypeSingleSelect
                    name={name}
                    displayName={displayName}
                    control={control}
                    key={key}
                    possibleValues={
                        customField?.possible_values?.map(option => ({
                            label: option,
                            value: option,
                        })) ?? []
                    }
                    disabled={disabled}
                />
            );

        case types.MULTIPLE_SELECT:
            return (
                <CustomFieldsTypeMultipleSelect
                    name={name}
                    displayName={displayName}
                    control={control}
                    key={key}
                    possibleValues={
                        customField?.possible_values?.map(option => ({
                            label: option,
                            value: option,
                        })) ?? []
                    }
                    disabled={disabled}
                />
            );

        case types.FLOAT:
            return (
                <CustomFieldsTypeNumber
                    name={name}
                    displayName={displayName}
                    control={control}
                    key={key}
                    type="float"
                    disabled={disabled}
                    min={customField?.min}
                    max={customField?.max}
                    error={errorMessage}
                />
            );

        case types.MULTIPLE_SELECT_IMAGE:
            return (
                <CustomFieldsTypeMultipleSelectImage
                    name={name}
                    displayName={displayName}
                    control={control}
                    key={key}
                    possibleImagesLabels={
                        (customField?.possible_images_labels as Array<string>) ?? []
                    }
                    possibleImagesUrls={customField?.possible_images_urls ?? []}
                    possibleValues={customField?.possible_values ?? []}
                    disabled={disabled}
                />
            );

        case types.IMAGES_UPLOADER:
            return (
                <CustomFieldsTypeImagesUploader
                    name={name}
                    displayName={displayName}
                    key={key}
                    control={control}
                    disabled={disabled}
                    maxLengths={customField?.max_lengths ?? []}
                    textFields={customField?.text_fields ?? []}
                    errors={errors[customField.name] ?? []}
                />
            );

        default:
            return null;
    }
};
