const SCRIPT_ID = 'google-map';

export const handleGoogleMap = () => {
    return new Promise((resolve, reject) => {
        const scriptSrc =
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyCQlisKnz3_6UjFIo7j4NahuVNAdp2DTkc&loading=async&libraries=places,geometry,drawing';

        const scripts = Array.from(document.getElementsByTagName('script'));
        const isScriptLoaded = scripts.some(script => script.src === scriptSrc);

        // Run twice due to React.StrictMode, needed in order to have google-maps available locally
        if (isScriptLoaded) {
            resolve('[Google Map] Script already loaded');
            return;
        }

        const script = document.createElement('script');
        script.async = true;
        script.src = scriptSrc;
        document.body.appendChild(script);

        script.onload = () => {
            resolve('[Google Map] Script loaded successfully');
        };

        script.onerror = () => {
            reject(new Error('[Google Map] Error loading the script'));
        };
    });
};

export const removeGoogleMapScript = () => {
    const script = document.getElementById(SCRIPT_ID);
    if (script) {
        script.remove();
    }
};
