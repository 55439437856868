import { TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { OpenHourStartEndContainer } from 'app/common/components/OpenHour/OpenHour.styled';

const HoursAllDay = () => {
    const { t } = useTranslation();

    return (
        <OpenHourStartEndContainer>
            <div>
                <TextInput
                    dataTrackId="specific_hours"
                    disabled
                    value={t('specific_hours__twenty_four_seven')}
                    valueContainer=""
                />
            </div>
        </OpenHourStartEndContainer>
    );
};

export default HoursAllDay;
