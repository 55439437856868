import { Banner, IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import useLocalStorage from 'app/common/hooks/useLocalStorage';
import { useHasPostPermission } from 'app/presence/googlePosts/hooks/useHasPostPermission';

export const PermissionDeniedBanner = () => {
    const { t } = useTranslation();
    const hasPostPermission = useHasPostPermission();
    const [closed, setClosed] = useLocalStorage('postManagePermissionDeniedBannerClosed', false);

    return !hasPostPermission && !closed ? (
        <Banner
            icon={['fa-circle-info', IconPrefix.SOLID]}
            dataTrackId="post-manage-permission-denied-banner"
            onClose={() => setClosed(true)}
        >
            {t('post_manage_permission_denied_banner')}
        </Banner>
    ) : null;
};
