import { remove } from 'lodash-es';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { FuzzySearchUsersReceivedData, UserReceivedData } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import { USERS } from 'app/common/data/queryKeysConstants';

interface Options {
    onSuccess: (data: UserReceivedData) => void;
}

type Status = 'invited' | 'active' | null;

export default function useDeleteUser(
    userId: string,
    query = '',
    status: Status = null,
    options?: Options,
) {
    const queryClient = useQueryClient();
    return useMutation(() => api.user.deleteUser(userId), {
        onMutate: () => {
            // OPTIMISTIC UPDATE
            // Cancel any outgoing refetches
            queryClient.cancelQueries([USERS]);
            // Snapshot the previous value
            const previousData = queryClient.getQueryData<
                InfiniteData<FuzzySearchUsersReceivedData>
            >([USERS, { query, status }]);
            if (previousData) {
                // Clone previous data collection
                const newData: InfiniteData<FuzzySearchUsersReceivedData> = JSON.parse(
                    JSON.stringify(previousData),
                );
                newData.pages.forEach(page => {
                    remove(page.users, user => user.id === userId);
                });
                // Optimistically update to the new value
                queryClient.setQueryData<InfiniteData<FuzzySearchUsersReceivedData>>(
                    [USERS, { query, status }],
                    newData,
                );
            }
            return { previousData };
        },
        onError: (err, newData, context) => {
            if (context?.previousData) {
                // If the mutation fails, use the context returned from onMutate to roll back
                queryClient.setQueryData<any>([USERS, { query, status }], context.previousData);
            }
        },
        ...options,
    });
}
