import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

export const TextAreaContainer = styled.div`
    position: relative;
    p {
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
    &:focus-within {
        p {
            color: ${({ theme }) => theme.colors.primary.initial};
        }
    }
`;

export const TextAreaFieldName = styled(Text)`
    position: absolute;
    transform: translateY(-60%);
    top: 0;
    left: 14px;
    background-color: ${HEX_COLORS.white};
    padding: 0 4px;
`;

export const ButtonsContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 24px;
    left: 8px;
`;
