import { ReactChild } from 'react';

export type ArrowDirection = 'left' | 'right';
export type ArrowPosition = 'top' | 'middle' | 'bottom';

/**
 * To handle infinite scrolling without breaking the animation,
 * we'll use a little trick.
 *
 * By adding a shallow copy of the first and last slides at the end and start
 * of the carousel, we can keep going in these direction even if there is no slide
 * remaining.
 *
 * Beware! This force us to start the carousel at index 1.
 */
export const createSlidesFromChildren = (
    baseSlides: ReactChild[],
    infiniteScrolling: boolean,
) =>
    infiniteScrolling
        ? [baseSlides[baseSlides.length - 1], ...baseSlides, baseSlides[0]]
        : baseSlides;
