import React from 'react';

import { useTranslation } from 'react-i18next';

import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import { EditSectionFullHeightContainer } from './EditSectionFullHeight.styled';

type Props = {
    children: React.ReactNode;
    onClick?: () => void;
    showCaret?: boolean;
    sectionColor: ColorState;
};

const EditSectionFullHeight = ({
    children,
    onClick = () => undefined,
    showCaret = false,
    sectionColor,
}: Props) => {
    const { t } = useTranslation();

    return (
        <TooltipWrapper
            text={
                sectionColor === ColorState.DISABLED ? t('field_read_only') : ''
            }
        >
            <EditSectionFullHeightContainer
                onClick={onClick}
                sectionColor={sectionColor}
            >
                {children}
                {showCaret && <i className="fa-solid fa-angle-right" />}
            </EditSectionFullHeightContainer>
        </TooltipWrapper>
    );
};

export default EditSectionFullHeight;
