import { NavigateFunction } from 'react-router-dom';

import { MESSAGING_CONVERSATION_STATS } from 'app/common/data/queryKeysConstants';
import { AssignFilterOption, Conversation, MessagingFilters } from 'app/states/messaging';

import queryClient from 'app/states/queryClient';

import { ConversationsData } from '../../hooks/useConversationUpdate';
import { removeConversation, updateConversation } from '../../services/handleConversationsCache';

export const handleConversationIsAutomatedChange = (
    conversation: Conversation,
    { activeConversation, setActiveConversation }: ConversationsData,
    { statusFilter, assignFilter, businessFilters }: MessagingFilters,
    navigate: NavigateFunction,
) => {
    const isOnActiveConversation = activeConversation?.id === conversation.id;

    updateConversation(conversation, statusFilter, assignFilter, businessFilters);

    if (isOnActiveConversation) {
        setActiveConversation(conversation);
    }

    if (assignFilter === AssignFilterOption.AUTOMATED && !conversation.is_automated) {
        removeConversation(conversation.id, statusFilter, assignFilter, businessFilters);
        const search = '?conversationId=';
        navigate({ search }, { replace: true });
    }

    queryClient.invalidateQueries([MESSAGING_CONVERSATION_STATS, businessFilters]);
};
