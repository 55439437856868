import { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/react';
import { Button, Dialog, IconPrefix, Modal, Stack } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { QueryClient, useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls/camel';
import IS_IFRAME from 'app/common/data/iframe';
import { REVIEW_BOOSTER_SMS_TEMPLATE } from 'app/common/data/queryKeysConstants';
import MentionTextArea from 'app/common/designSystem/components/atoms/MentionTextArea';
import { MentionTextAreaImperativeHandle } from 'app/common/designSystem/components/atoms/MentionTextArea/MentionTextArea';
import { ButtonWithMenu } from 'app/common/designSystem/components/molecules/ButtonWithMenu/ButtonWithMenu';

import getHelper, {
    MAX_AUTHORIZED_CHARACTER_COUNT_TEMPLATE,
    SmsTemplatePlaceholder,
} from 'app/common/services/smsTemplateHelper';

import {
    EditSmsTemplateButtonWrapper,
    EditSmsTemplateTextCount,
    EditSmsTemplateTextareaWrapper,
} from './EditSmsTemplateModal.styled';

function useTemplateText(initialText = ''): [string, Dispatch<SetStateAction<string>>, () => void] {
    const [text, setText] = useState(initialText);
    useMemo(() => setText(initialText), [initialText]);
    const reset = useCallback(() => setText(initialText), [initialText]);
    return [text, setText, reset];
}

type Props = {
    show: boolean;
    close: () => void;
};

const EditSmsTemplateModal = ({ show, close }: Props) => {
    const { t } = useTranslation();

    const { placeholders, convertForComponent, convertForApi, count } = getHelper(t);

    const queryClient: QueryClient = useQueryClient();

    const data = queryClient.getQueryData<{
        template: string;
        template_id: number;
    }>(REVIEW_BOOSTER_SMS_TEMPLATE);

    const initialText: string = convertForComponent(data?.template || '');

    const [text, setText, resetText] = useTemplateText(initialText);

    const textCount: number = count(text.trim());

    const { isLoading, mutate } = useMutation(
        (template: string) => api.reviewBooster.updateTemplate(template),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(REVIEW_BOOSTER_SMS_TEMPLATE).then(() => close());
            },
        },
    );

    const mentionTextArea = useRef<MentionTextAreaImperativeHandle>(null);

    const addMention = (id: string) => {
        const index: number = placeholders.findIndex(
            (item: SmsTemplatePlaceholder) => item.id === id,
        );
        if (index > -1) {
            mentionTextArea.current?.addPlaceholder(placeholders[index].display, id);
        } else {
            throw new Error('Mention not supported');
        }
    };

    const onClose = (): void => {
        close();
        resetText();
    };

    const isTextTooLong: boolean = textCount > MAX_AUTHORIZED_CHARACTER_COUNT_TEMPLATE;

    const numOfLink: number = (text.match(/@\[(.+?)]\(url\)/g) || []).length;

    const disabled = isTextTooLong || numOfLink !== 1 || isLoading;

    const getExplanation = (): string => {
        if (numOfLink === 0) {
            return t('edit_sms_template_no_link');
        }
        if (numOfLink > 1) {
            return t('edit_sms_template_multiple_links');
        }
        return '';
    };

    const handleClose = () => {
        const currentText: string = text.trim();
        if (currentText !== initialText) {
            if (confirm(t('rb_template_click_outside_modal'))) {
                onClose();
            }
            return;
        }
        onClose();
    };

    const handleConfirm = () => {
        if (isTextTooLong || numOfLink !== 1) {
            return;
        }
        const parsedText: string = convertForApi(text.trim());
        mutate(parsedText);
    };

    return (
        <Modal
            isOpen={show}
            closeModal={handleClose}
            overlayAppearance={IS_IFRAME ? 'iframe' : 'dark'}
        >
            <Dialog
                dataTrackId=""
                size="medium"
                title={t('edit_sms_template_title')}
                subTitle={t('edit_sms_template_description')}
            >
                <EditSmsTemplateButtonWrapper>
                    <ButtonWithMenu
                        dataTrackId="edit_sms_template__button_menu"
                        icon={['fa-paste', IconPrefix.SOLID]}
                        text={t('add_a_placeholder')}
                        appearance="text"
                        variant="primary"
                        options={placeholders.reduce(
                            (
                                acc: { value: string; label: string }[],
                                cur: SmsTemplatePlaceholder,
                            ) => {
                                const { id: value, display: label } = cur;
                                if (numOfLink >= 1 && value === 'url') return acc;
                                return [...acc, { value, label, name: value }];
                            },
                            [],
                        )}
                        onMenuClick={addMention}
                        cssMenu={css`
                            top: 30px;
                            right: -2px;
                            left: unset;
                        `}
                    />
                </EditSmsTemplateButtonWrapper>
                <EditSmsTemplateTextareaWrapper>
                    <MentionTextArea
                        value={text}
                        choices={placeholders.filter(
                            ({ id }: SmsTemplatePlaceholder) => id !== 'url' || numOfLink === 0,
                        )}
                        ref={mentionTextArea}
                        onChange={setText}
                        hasError={isTextTooLong || numOfLink !== 1}
                        autoFocus
                        explanation={getExplanation()}
                    />
                    <EditSmsTemplateTextCount isTextTooLong={isTextTooLong}>
                        {`${textCount}/${MAX_AUTHORIZED_CHARACTER_COUNT_TEMPLATE} (${t(
                            'edit_sms_template_warning',
                        )})`}
                    </EditSmsTemplateTextCount>
                </EditSmsTemplateTextareaWrapper>
                <Stack direction="row">
                    <Button
                        dataTrackId="update_sms__close"
                        variant="secondary"
                        onClick={onClose}
                        size="large"
                        appearance="outlined"
                        full
                    >
                        {t('undo')}
                    </Button>
                    <Button
                        dataTrackId="update_sms__confirm"
                        disabled={disabled}
                        variant="primary"
                        onClick={handleConfirm}
                        size="large"
                        full
                    >
                        {t('edit_sms_template_save')}
                    </Button>
                </Stack>
            </Dialog>
        </Modal>
    );
};

export default EditSmsTemplateModal;
