import { WEEK } from 'app/common/services/allowedSeasonalityOptions';

import { ReviewAnalyticsState } from './types';
import * as constants from '../actions/constants';
import { ReviewAnalyticsActionType } from '../actions/types';

const isTotal = (type: string) =>
    type === constants.GET_REVIEW_STATS_TOTAL_SUCCESS;

export const evolutionNameMaker = (isQualitative: boolean, isPeriod: boolean) =>
    `${isPeriod ? 'period' : 'total'}${isQualitative ? 'Average' : 'Sum'}`;

export const initialState = {
    evolution: {
        analytics: {
            totalSum: [],
            periodSum: [],
            totalAverage: [],
            periodAverage: [],
            evolutionFetching: false,
        },
        isPeriod: constants.DEFAULT_IS_PERIOD,
        isQualitative: constants.DEFAULT_IS_QUALITATIVE,
        bucket: WEEK,
    },
    averageGrade: {
        total: {
            value: 0,
            labels: {
                satisfied: 0,
                neutral: 0,
                dissatisfied: 0,
            },
        },
        period: {
            value: 0,
            labels: {
                satisfied: 0,
                neutral: 0,
                dissatisfied: 0,
            },
        },
    },
    ratings: {
        totalReviews: 0,
        periodReviews: 0,
    },
    answerRate: {
        totalAnswerRate: 0,
        periodAnswerRate: 0,
        estimatedTotalTimeAnswerRate: 0,
        estimatedPeriodTimeAnswerRate: 0,
    },
    isFetchingTotal: false,
    isFetchingPeriod: false,
};

export const reviewAnalyticsReducer = (
    state: ReviewAnalyticsState = initialState,
    action: ReviewAnalyticsActionType,
): ReviewAnalyticsState => {
    switch (action.type) {
        case constants.GET_REVIEWS_PERIOD_TOTAL_REQUEST:
            return {
                ...state,
                evolution: { ...state.evolution, isPeriod: action.isPeriod },
            };

        case constants.GET_REVIEWS_EVOLUTION_FAILURE:
            return {
                ...state,
                evolution: {
                    ...state.evolution,
                    analytics: {
                        ...state.evolution.analytics,
                        evolutionFetching:
                            action.error.message === constants.DATA_IS_OUTDATED
                                ? state.evolution.analytics.evolutionFetching
                                : false,
                    },
                },
            };

        case constants.GET_REVIEWS_EVOLUTION_SUCCESS: {
            const { isQualitative, isPeriod, analytics } = action;
            return {
                ...state,
                evolution: {
                    ...state.evolution,
                    analytics: {
                        ...state.evolution.analytics,
                        [evolutionNameMaker(isQualitative, isPeriod)]:
                            analytics,
                        evolutionFetching: false,
                    },
                },
            };
        }

        case constants.GET_REVIEWS_EVOLUTION_REQUEST:
            return {
                ...state,
                evolution: {
                    ...state.evolution,
                    analytics: {
                        ...state.evolution.analytics,
                        evolutionFetching: true,
                    },
                },
            };

        case constants.GET_REVIEW_STATS_TOTAL_REQUEST:
            return { ...state, isFetchingTotal: true };

        case constants.GET_REVIEW_STATS_PERIOD_REQUEST:
            return { ...state, isFetchingPeriod: true };

        case constants.GET_REVIEW_STATS_TOTAL_SUCCESS:
        case constants.GET_REVIEW_STATS_PERIOD_SUCCESS: {
            const [average, answerRate, answerTime, reviewCount, isFetching] =
                isTotal(action.type)
                    ? [
                          'total',
                          'totalAnswerRate',
                          'estimatedTotalTimeAnswerRate',
                          'totalReviews',
                          'isFetchingTotal',
                      ]
                    : [
                          'period',
                          'periodAnswerRate',
                          'estimatedPeriodTimeAnswerRate',
                          'periodReviews',
                          'isFetchingPeriod',
                      ];
            return {
                ...state,
                averageGrade: {
                    ...state.averageGrade,
                    [average]: {
                        value: action.average,
                        labels: {
                            satisfied: action.satisfied,
                            neutral: action.neutral,
                            dissatisfied: action.dissatisfied,
                        },
                    },
                },
                answerRate: {
                    ...state.answerRate,
                    [answerRate]: action.answerRate,
                    [answerTime]: action.answerTime,
                },
                ratings: {
                    ...state.ratings,
                    [reviewCount]: action.total,
                },
                [isFetching]: false,
            };
        }

        case constants.GET_REVIEW_STATS_TOTAL_FAILURE:
            return { ...state, isFetchingTotal: false };

        case constants.GET_REVIEW_STATS_PERIOD_FAILURE:
            return { ...state, isFetchingPeriod: false };

        case constants.UPDATE_IS_QUALITATIVE:
            return {
                ...state,
                evolution: {
                    ...state.evolution,
                    isQualitative: action.isQualitative,
                },
            };

        case constants.RESET_EVOLUTION_TOTAL_ANALYTICS:
            return {
                ...state,
                evolution: {
                    ...state.evolution,
                    analytics: {
                        ...state.evolution.analytics,
                        totalSum: [],
                        totalAverage: [],
                    },
                },
            };

        case constants.RESET_EVOLUTION_PERIOD_ANALYTICS:
            return {
                ...state,
                evolution: {
                    ...state.evolution,
                    analytics: {
                        ...state.evolution.analytics,
                        periodSum: [],
                        periodAverage: [],
                    },
                },
            };

        case constants.UPDATE_REVIEWS_EVOLUTION_BUCKET:
            return {
                ...state,
                evolution: { ...state.evolution, bucket: action.bucket },
            };

        default:
            return state;
    }
};

export default reviewAnalyticsReducer;
