import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

import { FunnelModalBody } from 'app/common/components/FunnelModal/FunnelModal.styled';
import { STATIC_APP_ASSETS_URL } from 'app/config';

export const TextContainer = styled(Text)`
    text-align: center;
    max-width: 296px;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    text-align: center;
`;

export const TitleTextContainer = styled(Text)`
    text-align: center;
    max-width: 463px;
`;

export const DescriptionTextContainer = styled(Text)`
    text-align: center;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
`;

interface ConfettiModalBodyProps {
    hasConfetti: boolean;
}

export const ConfettiModalBody = styled(FunnelModalBody)`
    display: flex;
    ${({ hasConfetti }: ConfettiModalBodyProps) =>
        hasConfetti &&
        css`
            background-image: url('${STATIC_APP_ASSETS_URL}/common/confettis_background_no_loop.gif');
            background-repeat: no-repeat;
            background-size: 100%;

            /* Need to enforce the radius here, otherwise the bg overflows & hides it */
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        `}
`;

export const CenteredBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    margin: auto;
`;
