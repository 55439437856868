import { useEffect, useRef, useState } from 'react';

import { IconButton, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import useGetMessageReplyTemplates from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/hooks/useGetMessageReplyTemplates';
import MessagingReplyTemplatesPopIn from 'app/reviewManagement/messaging/activeConversation/messages/MessagingReplyTemplatesPopIn';

import { USAGE } from 'app/states/messageReplyTemplate/dataTypes';

import { IconButtonWithLabelWrapper } from './MessageForm.styled';
import { PopInWrapper, ReplyTemplateButtonWrapper } from './ReplyTemplateButton.styled';

interface ReplyTemplateButtonProps {
    showPopInCache: boolean;
    isPopoverOpen: boolean;
    onButtonClick: () => void;
    onPopInClick: () => void;
}

const ReplyTemplateButton = ({
    showPopInCache,
    isPopoverOpen,
    onButtonClick,
    onPopInClick,
}: ReplyTemplateButtonProps) => {
    const { t } = useTranslation();
    const popInRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);
    const [pos, setPos] = useState({ top: 0, left: 0 });
    const { data: replyTemplatesData } = useGetMessageReplyTemplates(USAGE, true, showPopInCache);

    const showPopIn = showPopInCache && replyTemplatesData?.length == 0 && !isPopoverOpen;

    useEffect(() => {
        if (showPopIn && popInRef.current && buttonRef.current) {
            const top = -popInRef.current.offsetHeight;
            const left = (buttonRef.current.offsetWidth - popInRef.current.offsetWidth) / 2;
            setPos({ top, left });
        }
    }, [showPopIn]);

    return (
        <ReplyTemplateButtonWrapper ref={buttonRef}>
            <TooltipWrapper
                text={t('messaging_conversation_reply_templates_button_label')}
                position="top"
            >
                <IconButtonWithLabelWrapper>
                    <IconButton
                        dataTrackId="messaging_template__template_popover"
                        data-intercom-target="messaging_template__template_popover"
                        icon={['fa-bolt-lightning', IconPrefix.REGULAR]}
                        color="primary"
                        appearance="outlined"
                        onClick={onButtonClick}
                    />
                </IconButtonWithLabelWrapper>
            </TooltipWrapper>
            {showPopIn && (
                <PopInWrapper ref={popInRef} top={pos.top} left={pos.left}>
                    <MessagingReplyTemplatesPopIn
                        content={t('messaging_conversation_reply_templates_button_pop_in')}
                        onClick={onPopInClick}
                        dataTrackId={'messaging_template__template_button_pop_in'}
                    />
                </PopInWrapper>
            )}
        </ReplyTemplateButtonWrapper>
    );
};

export default ReplyTemplateButton;
