import { useCallback, useContext, useEffect } from 'react';

import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import {
    UNGROUPED_ID,
    currentGroupIdSelector,
    currentSubGroupIdSelector,
} from 'app/common/reducers/groups';
import useGroups from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroups';
import { OpenedGroupsContext } from 'app/pages/visibility/BusinessListV2/sections/BusinessFiltersSection/BusinessFilters/BusinessOldGroupsFilter';
import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';

import NavItem from './NavItemCopy';
import { GroupsContainer } from './OldGroupsList.styled';

const OldGroupsList = () => {
    /**
     * No idea what is happening here
     * Let's ignore this for now and wait for the old groups to die
     */

    const { groups, hasNextPage, fetchNextPage, isFetchingNextPage } = useGroups();

    const locationPageUrl = VISIBILITY_LOCATION_PATH;

    const { t } = useTranslation();

    const currentGroupItem = currentGroupIdSelector();
    const currentSubGroupItem = currentSubGroupIdSelector();

    const match = useMatch({
        path: locationPageUrl,
    });
    const navigate = useNavigate();
    const { search } = useLocation();
    const { openedGroups, setOpenedGroups } = useContext(OpenedGroupsContext);
    const closeAllNavItemsExcept = useCallback(
        (targetGroupId: number) => {
            setOpenedGroups([targetGroupId]);
        },
        [setOpenedGroups],
    );
    const onGroupClick = useCallback(
        groupId => {
            if (currentSubGroupItem && currentGroupItem === groupId) {
                setOpenedGroups([]);
            } else {
                closeAllNavItemsExcept(groupId);
            }
        },
        [currentSubGroupItem, currentGroupItem, setOpenedGroups, closeAllNavItemsExcept],
    );

    const handleChevronClick = useCallback(
        groupId => {
            setOpenedGroups(
                openedGroups.includes(groupId)
                    ? openedGroups.filter(id => id !== groupId)
                    : [...openedGroups, groupId],
            );
        },
        [openedGroups, setOpenedGroups],
    );

    const isValidGroupSearchParam = useCallback(
        ([groupId, subGroupId]) => {
            const group = groups?.find(g => g.groupId.toString() === groupId);
            const groupIdIsValid = ['ungrouped', 'all'].includes(groupId) || !!group;
            const subGroupIdIsValid =
                subGroupId === undefined ||
                subGroupId === groupId ||
                group?.subgroups?.find(subGroup => subGroup.groupId.toString() === subGroupId);
            return groupIdIsValid && subGroupIdIsValid;
        },
        [groups],
    );
    const { group: groupSearchParam } = qs.parse(search);

    useEffect(() => {
        if (
            !!match &&
            !isValidGroupSearchParam(
                typeof groupSearchParam === 'string' ? groupSearchParam.split(',') : [],
            )
        ) {
            navigate(`${locationPageUrl}?group=all`, { replace: true });
        }
    }, [match, history, isValidGroupSearchParam, groupSearchParam]);

    return (
        <GroupsContainer>
            <NavItem
                title={t('all_businesses_with_count')}
                to={`${locationPageUrl}?group=all`}
                id={null}
                onClick={onGroupClick}
                selected={!currentSubGroupItem && currentGroupItem === null}
            />
            <InfiniteScroll
                loadMore={fetchNextPage}
                hasMore={!isFetchingNextPage && !!hasNextPage}
                useWindow={false}
            >
                {groups?.map(group => (
                    <NavItem
                        key={group.groupId}
                        title={group.groupName[0].toUpperCase() + group.groupName.slice(1)}
                        to={`${locationPageUrl}?group=${group.groupId}`}
                        id={group.groupId}
                        onClick={onGroupClick} // v avoid passing non necessary handleChevronClick which will change reference
                        // each time `openedGroups` changes
                        onChevronClick={group.subgroups?.length ? handleChevronClick : undefined}
                        opened={openedGroups.includes(group.groupId)}
                        selected={!currentSubGroupItem && currentGroupItem === group.groupId}
                    >
                        {group.subgroups && group.subgroups.length > 0 && (
                            <>
                                {group.subgroups.map(subgroup => (
                                    <NavItem
                                        key={subgroup.groupId}
                                        title={
                                            subgroup.groupName[0].toUpperCase() +
                                            subgroup.groupName.slice(1)
                                        }
                                        to={`${locationPageUrl}?group=${[
                                            group.groupId,
                                            subgroup.groupId,
                                        ].join(',')}`}
                                        id={subgroup.groupId}
                                        selected={currentSubGroupItem === subgroup.groupId}
                                        isChild
                                    />
                                ))}
                                <NavItem
                                    title={t('other_group_name')}
                                    to={`${locationPageUrl}?group=${[
                                        group.groupId,
                                        group.groupId,
                                    ].join(',')}`}
                                    id={group.groupId}
                                    selected={currentSubGroupItem === group.groupId}
                                    isChild
                                />
                            </>
                        )}
                    </NavItem>
                ))}
            </InfiniteScroll>
            <NavItem
                title={t('other_group_name')}
                to={`${locationPageUrl}?group=ungrouped`}
                id={UNGROUPED_ID}
                onClick={onGroupClick}
                selected={!currentSubGroupItem && currentGroupItem === UNGROUPED_ID}
            />
        </GroupsContainer>
    );
};

export default OldGroupsList;
