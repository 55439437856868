import { useTranslation } from 'react-i18next';

import { useSettingTeamMenu } from 'app/pages/settingsV2/hooks/Team/useSettingMenuTeam';

import { HeaderSubPages } from '../../subComponents';
import { SettingSkeleton } from '../../subComponents/SettingSkeleton';
import { Template } from '../../subComponents/Template/Template';

export const SettingTeam = () => {
    const { t } = useTranslation();
    const { menus } = useSettingTeamMenu();

    return (
        <Template header={<HeaderSubPages>{t('team_management')}</HeaderSubPages>}>
            <SettingSkeleton sections={menus} />
        </Template>
    );
};
