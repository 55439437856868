import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Clickable from 'app/common/components/buttons/Clickable';
import { ImageContainer } from 'app/common/designSystem/components/molecules/ButtonWithSearchMenuFilterSelection/ButtonWithSearchMenuFilterSelection.styled';

export const StyledCard = styled(Clickable)<{ selected?: boolean }>`
    ${({ selected, theme }) =>
        selected
            ? css`
                  border: 2px solid ${theme.colors.primary.initial};
                  padding: 23px;
              `
            : css`
                  border: 1px solid #dddfe3;
                  padding: 24px;
                  &:hover {
                      border: 1px solid ${theme.colors.secondary.initial};
                      background: rgba(215, 219, 223, 0.12);
                  }
              `}
    border-radius: 8px;
`;

export const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
`;

export const FlagContainer = styled(ImageContainer)`
    grid-area: flag;
`;

export const ReplyTemplateHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
`;

export const StyledIcon = styled.div`
    display: flex;
    align-items: center;
    height: 10px;
    width: 10px;
    margin-right: 4px;
`;

export const StyledPartooLogo = styled.img`
    height: 10px;
    width: 10px;
    margin-right: 4px;
`;

export const StyledBadges = styled.div`
    display: flex;
    gap: 4px;
`;

export const StyledBadge = styled.div`
    display: flex;
    align-items: center;
    background: #f6f8fc;
    border-radius: 4px;
    padding: 4px 8px;
`;

export const StyledCheckbox = styled.input`
    width: 16px;
    height: 16px;
    margin: 0;
    cursor: pointer;
`;
