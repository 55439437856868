import { Chip, List, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { InvoiceData } from 'app/api/types/invoice';
import DownloadInvoiceButton from 'app/settingsManagement/components/Invoice/DownloadInvoiceButton/DownloadInvoiceButton';
import { formatISODate } from 'app/utils/dates';

export const getInvoiceVariant = (status: string) => {
    switch (status) {
        case 'paid':
            return 'success';
        case 'adjusted':
            return 'primary';
        case 'payment_due':
            return 'warning';
        default:
            return 'secondary';
    }
};

type InvoiceRowProps = {
    invoice: InvoiceData;
};

const InvoiceRow = ({ invoice }: InvoiceRowProps) => {
    const { t } = useTranslation();

    return (
        <List.Row dataTrackId="invoice_row" id={invoice.id}>
            <List.Cell>
                <Text variant="bodyMSemibold">{formatISODate(invoice.date)}</Text>
            </List.Cell>
            <List.Cell>
                <Text variant="bodyMRegular">{invoice.invoice_id}</Text>
            </List.Cell>
            <List.Cell>
                <Chip
                    dataTrackId="invoices_status"
                    chipShape="cube"
                    variant={getInvoiceVariant(invoice.invoice_status)}
                >
                    {t(`invoice_status__${invoice.invoice_status}`)}
                </Chip>
            </List.Cell>
            <List.Cell>
                <Text variant="bodyMRegular">{invoice.formatted_total}</Text>
            </List.Cell>
            <List.Cell>
                <DownloadInvoiceButton invoiceId={invoice.id} />
            </List.Cell>
        </List.Row>
    );
};

export default InvoiceRow;
