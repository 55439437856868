import { V2FormattedBusinessData } from 'app/api/types/business';
import { FormattedReplyTemplate } from 'app/api/types/reviewReplyTemplates';
import { V2UserData } from 'app/api/types/user';
import { ReviewObject } from 'app/states/reviews';

export enum TemplatePlaceholder {
    BUSINESS_CITY = 'business_city',
    BUSINESS_NAME = 'business_name',
    BUSINESS_PHONE_NUMBER = 'business_phone_number',
    CLIENT_FIRST_NAME = 'client_first_name',
    CLIENT_FULL_NAME = 'client_full_name',
    MY_FIRST_NAME = 'my_first_name',
}

export function placeholderConvertor(
    placeholder: TemplatePlaceholder,
    data: {
        business: Pick<V2FormattedBusinessData, 'city' | 'name' | 'contacts'> | undefined;
        review: Pick<ReviewObject, 'authorName'> | undefined;
        meData: Pick<V2UserData, 'first_name'> | undefined;
    },
): string {
    const { business, review, meData } = data;
    switch (placeholder) {
        case TemplatePlaceholder.CLIENT_FIRST_NAME:
            return review?.authorName?.split(' ')[0] ?? '';
        case TemplatePlaceholder.CLIENT_FULL_NAME:
            return review?.authorName ?? '';
        case TemplatePlaceholder.BUSINESS_NAME:
            return business?.name ?? '';
        case TemplatePlaceholder.MY_FIRST_NAME:
            return meData?.first_name ?? '';
        case TemplatePlaceholder.BUSINESS_CITY:
            return business?.city ?? '';
        case TemplatePlaceholder.BUSINESS_PHONE_NUMBER:
            return business?.contacts[0]?.phone_numbers[0] ?? '';
        default:
            return '';
    }
}

export function fillTemplateService(
    template: FormattedReplyTemplate,
    review: ReviewObject,
    business: V2FormattedBusinessData,
    meData: V2UserData,
): string {
    const data = { review, business, meData };
    return template.text.replace(
        /@\[.+?\]\((.+?)\)/g,
        (_, p1) => placeholderConvertor(p1, data) ?? '',
    );
}
