import React, {
    DependencyList,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';

import { CollapsibleContainer } from './Collapsible.styled';

type Props = {
    collapsed: boolean;
    children?: ReactNode;
    deps?: DependencyList;
};

const UNNECESSARY_BIG_MAX_HEIGHT = 999999999;

export default function Collapsible({ collapsed, children, deps = [] }: Props) {
    const [contentHeight, setContentHeight] = useState<number>(
        collapsed ? 0 : UNNECESSARY_BIG_MAX_HEIGHT,
    );
    const contentRef = useRef<HTMLDivElement>(null);
    const [magicNumber, setMagicNumber] = useState<number>(
        window.innerWidth + window.innerHeight,
    );

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [contentRef.current, collapsed, magicNumber, ...deps]);

    // Handle window resize
    useEffect(() => {
        const handleResize = () => {
            setMagicNumber(window.innerWidth + window.innerHeight);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <CollapsibleContainer
            ref={contentRef}
            collapsed={collapsed}
            maxHeight={contentHeight}
        >
            {children}
        </CollapsibleContainer>
    );
}
