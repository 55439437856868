import { FC, useEffect } from 'react';

import { TimerBackground, TimerContainer, TipTimer } from './Timer.styled';

interface Props {
    size: number;
    tipId: number;
    onNext: () => void;
}

export const Timer: FC<Props> = ({ size, tipId, onNext }) => {
    const time = 10;
    useEffect(() => {
        const id = setTimeout(() => onNext(), time * 1000);
        return () => clearTimeout(id);
    }, [tipId]);
    return (
        <TimerContainer>
            {Array.from(Array(size), (e, idx) => {
                return (
                    <TimerBackground>{tipId === idx && <TipTimer time={time} />}</TimerBackground>
                );
            })}
        </TimerContainer>
    );
};
