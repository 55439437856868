import React from 'react';

import { useTranslation } from 'react-i18next';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useBusinessHasOpenHours from 'app/businessEditV2/hooks/editV2utils/useBusinessHasOpenHours';
import { DAYS } from 'app/businessEditV2/oldFromEditV1/data/constants';
import DayPreview from 'app/businessEditV2/sections/OpenHoursSection/OpenHoursPreview/DayPreview';
import DayPreviewIcon from 'app/businessEditV2/sections/OpenHoursSection/OpenHoursPreview/DayPreview/DayPreviewIcon';
import {
    AddOpenHoursTitle,
    OpenHoursContent,
    OpenHoursPreviewContainer,
    OpenHoursPreviewContainerNoOpenHours,
} from 'app/businessEditV2/sections/OpenHoursSection/OpenHoursPreview/OpenHoursPreview.styled';

type Props = {
    sectionColor: ColorState;
};

const OpenHoursPreview = ({ sectionColor }: Props) => {
    const { t } = useTranslation();
    const { data: business } = useBusiness();
    const hasOpenHours = useBusinessHasOpenHours();

    if (!hasOpenHours) {
        return (
            <OpenHoursPreviewContainerNoOpenHours sectionColor={sectionColor}>
                <DayPreviewIcon />
                <AddOpenHoursTitle>
                    {t('business_edit_open_hours_add_title')}
                </AddOpenHoursTitle>
            </OpenHoursPreviewContainerNoOpenHours>
        );
    }

    return (
        <OpenHoursContent>
            <OpenHoursPreviewContainer>
                {DAYS.map(day => (
                    <DayPreview
                        day={day}
                        hours={business?.open_hours?.[day] ?? []}
                        key={day}
                    />
                ))}
            </OpenHoursPreviewContainer>
            <i className="fa-solid fa-angle-right" />
        </OpenHoursContent>
    );
};

export default OpenHoursPreview;
