import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import EASINGS from 'app/styles/constants/easings';

type CollapsibleContainerProps = {
    collapsed: boolean;
};

export const CollapsibleSidebarTitle = styled.h4`
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 8px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const CollapsibleContainer = styled.div`
    width: 230px;
    will-change: width;
    transition: width 0.3s ${EASINGS.easeOutStrong};
    ${(props: CollapsibleContainerProps) =>
        props.collapsed &&
        css`
            width: 0;
        `}
`;

export const PositionAbsoluteContainer = styled.div`
    position: absolute;
    width: 230px;
    height: 100%;
    padding: 32px 16px 22px 16px;
    box-sizing: border-box;
    background-color: ${greyColorObject.light};
    display: flex;
    flex-direction: column;
    overflow: auto;
`;
