import { AppDataContextType } from 'app/common/contexts/appData';

import { handleGoogleMap, removeGoogleMapScript } from './handleGoogleMap';
import { handleGoogleTagManager, removeGoogleTagManagerScript } from './handleGoogleTagManager';
import { handleSurvicate, removeSurvicateScript } from './handleSurvicate';
import { handleWhatsappEmbeddedSignup } from './handleWhatsappEmbeddedSignupScript';

export const setupPostLoginScripts = async (appData: AppDataContextType) => {
    return await Promise.allSettled([
        handleGoogleMap(),
        handleWhatsappEmbeddedSignup(),
        handleSurvicate(appData.me, appData.organization),
        handleGoogleTagManager(appData.me, appData.organization),
    ]);
};

export const removePostLoginScripts = () => {
    removeGoogleMapScript();
    removeSurvicateScript();
    removeGoogleTagManagerScript();
};
