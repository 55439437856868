import { Switch } from '@partoohub/ui';
import { Controller, UseFormWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { V2BusinessData } from 'app/api/types/business';
import explanationHOC from 'app/common/designSystem/components/atoms/ExplanationHOC';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import {
    CEOrgFeatures,
    useHasCEOrgFeatures,
} from 'app/pages/customerExperience/settings/useHasCEOrgFeatures';
import { Products } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

import {
    ProductTogglesContainer,
    ToggleContainer,
    ToggleName,
} from './ControlledProductsToggles.styled';

type Props = {
    control: Control<any>;
    watch: UseFormWatch<any>;
    explanation?: string;
};

const ControlledProductsToggles = ({ control, watch }: Props) => {
    const { t } = useTranslation();

    const selectedBusinesses: Array<V2BusinessData> = watch('businesses');

    const hasPMtoggle = !!selectedBusinesses.find(
        (business: V2BusinessData) => business.subscriptions.presence_management.active,
    );
    const hasRMtoggle = !!selectedBusinesses.find(
        (business: V2BusinessData) => business.subscriptions.review_management.active,
    );
    const hasRBtoggle = !!selectedBusinesses.find(
        (business: V2BusinessData) => business.subscriptions.review_booster.active,
    );
    const hasMessagestoggle = !!selectedBusinesses.find(
        (business: V2BusinessData) => business.subscriptions.messages.active,
    );
    const {
        orgFeatures: { [CEOrgFeatures.FeedbackManagement]: hasFBMtoggle },
    } = useHasCEOrgFeatures();
    const products = [
        {
            name: Products.PRESENCE_MANAGEMENT,
            active: hasPMtoggle,
        },
        {
            name: Products.REVIEW_MANAGEMENT,
            active: hasRMtoggle,
        },
        {
            name: Products.REVIEW_BOOSTER,
            active: hasRBtoggle,
        },
        {
            name: Products.MESSAGES,
            active: hasMessagestoggle,
        },
        {
            name: 'feedback_management',
            active: hasFBMtoggle,
        },
    ];

    return (
        <ProductTogglesContainer>
            {products.map(product => (
                <Controller
                    key={product.name}
                    name={product.name}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <TooltipWrapper
                                text={
                                    product.active ? '' : t('not_subscribed_to') + t(product.name)
                                }
                                position="auto-start"
                            >
                                <ToggleContainer>
                                    <Switch
                                        dataTrackId={`toggle-${product.name}`}
                                        name={product.name}
                                        checked={!product.active ? false : value}
                                        disabled={!product.active}
                                        onChange={() => onChange(!watch(product.name))}
                                    />
                                    <ToggleName disabled={!product.active}>
                                        {t(product.name)}
                                    </ToggleName>
                                </ToggleContainer>
                            </TooltipWrapper>
                        );
                    }}
                />
            ))}
        </ProductTogglesContainer>
    );
};

export default explanationHOC<Props>(ControlledProductsToggles);
