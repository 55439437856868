import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

export const HelperCardContainer = styled.div``;

export const StyledSubtitle = styled(Text)`
    margin-bottom: 16px;
    text-align: center;
`;
