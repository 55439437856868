import { useTranslation } from 'react-i18next';

import {
    MessageBubbleWrapperStyled,
    NoticeBubbleStyled,
} from 'app/reviewManagement/messaging/activeConversation/messages/MessageBubble.styled';

import {
    AnswerMessage,
    Conversation,
    ConversationMessage,
    MESSAGE_CONTENT_TYPE,
    MESSAGE_SENDER_TYPE,
    MessagingPartnerEnum,
} from 'app/states/messaging';

import { ButtonsBubble } from './messageBubbleContents/ButtonsBubble';
import { ConversationStarterBubble } from './messageBubbleContents/ConversationStarterBubble';
import { IgStoryMentionBubble } from './messageBubbleContents/IgStoryMentionBubble';
import { IgStoryReplyBubble } from './messageBubbleContents/IgStoryReplyBubble';
import { ImageBubble } from './messageBubbleContents/ImageBubble';
import { QuickRepliesBubble } from './messageBubbleContents/QuickRepliesBubble';
import { TextBubble } from './messageBubbleContents/TextBubble';
import { UnsupportedBubble } from './messageBubbleContents/UnsupportedBubble';

type Props = {
    message: ConversationMessage;
    conversation: Conversation;
    handleBubbleClick: () => void;
    consumerName: string;
};

export const MessageBubbleContent = ({
    message,
    conversation,
    handleBubbleClick,
    consumerName,
}: Props) => {
    const { t } = useTranslation();
    const isConsumerMessage = message.sender_type === MESSAGE_SENDER_TYPE.CONSUMER;

    if (message.is_deleted) {
        return (
            <MessageBubbleWrapperStyled onClick={handleBubbleClick} isIgStory={false}>
                <NoticeBubbleStyled
                    variant="bodySMedium"
                    as="span"
                    italic
                    color={'secondary'}
                    isConsumer={isConsumerMessage}
                >
                    {t('messaging_conversation_message_deleted')}
                </NoticeBubbleStyled>
            </MessageBubbleWrapperStyled>
        );
    }

    switch (message.content_type) {
        case MESSAGE_CONTENT_TYPE.TEXT: {
            const messageContent = message.content as string;
            return (
                <TextBubble
                    text={
                        conversation.messaging_partner === MessagingPartnerEnum.DELIGHT
                            ? t(messageContent)
                            : messageContent
                    }
                    isConsumerMessage={isConsumerMessage}
                    onClick={handleBubbleClick}
                />
            );
        }

        case MESSAGE_CONTENT_TYPE.ANSWER: {
            const answerContent = message.content as AnswerMessage;
            return (
                <TextBubble
                    text={answerContent.text as string}
                    isConsumerMessage={isConsumerMessage}
                    onClick={handleBubbleClick}
                />
            );
        }

        case MESSAGE_CONTENT_TYPE.IMAGE: {
            return (
                <ImageBubble
                    url={message.content as string}
                    isConsumerMessage={isConsumerMessage}
                    onClick={handleBubbleClick}
                />
            );
        }

        case MESSAGE_CONTENT_TYPE.BUTTONS: {
            return <ButtonsBubble message={message} handleBubbleClick={handleBubbleClick} />;
        }

        case MESSAGE_CONTENT_TYPE.QUICK_REPLIES: {
            return <QuickRepliesBubble message={message} handleBubbleClick={handleBubbleClick} />;
        }

        case MESSAGE_CONTENT_TYPE.CONVERSATION_STARTER: {
            return (
                <ConversationStarterBubble
                    conversation={conversation}
                    message={message}
                    handleBubbleClick={handleBubbleClick}
                />
            );
        }

        case MESSAGE_CONTENT_TYPE.IG_STORY_MENTION: {
            return (
                <IgStoryMentionBubble
                    message={message}
                    consumerName={consumerName}
                    handleBubbleClick={handleBubbleClick}
                />
            );
        }

        case MESSAGE_CONTENT_TYPE.IG_STORY_REPLY: {
            return (
                <IgStoryReplyBubble
                    message={message}
                    consumerName={consumerName}
                    handleBubbleClick={handleBubbleClick}
                />
            );
        }

        case MESSAGE_CONTENT_TYPE.UNSUPPORTED: {
            return (
                <UnsupportedBubble
                    message={message}
                    consumerName={consumerName}
                    handleBubbleClick={handleBubbleClick}
                />
            );
        }

        default:
            return null;
    }
};
