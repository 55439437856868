import { ProductForUpsale } from '@partoohub/api-client';
import { toast } from '@partoohub/ui';
import { t } from 'i18next';
import { useQuery } from 'react-query';

import { productUpsaleApiClient } from 'app/api/v2/api_calls/apiResources';
import { PRODUCT_UPSALE } from 'app/common/data/queryKeysConstants';

export const useProductUpsale = (product: ProductForUpsale) => {
    return useQuery([PRODUCT_UPSALE, product], () => productUpsaleApiClient.upsale(product), {
        enabled: false,
        onSuccess: () => {
            toast.success(null, t('detail_success_message'));
        },
        onError: () => {
            toast.error(null, t('detail_error_message'));
        },
    });
};
