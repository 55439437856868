import styled from '@emotion/styled';
import { Icon, Text } from '@partoohub/ui';

export const StyledTag = styled(Text)`
    height: 50px;
    border-radius: 8px;
    padding: 16px;
    background-color: rgba(215, 219, 223, 0.12);
    width: 100%;
    justify-content: center;
    display: flex;
`;

const StyledIcon = styled(Icon)`
    padding: 8px;
    border-radius: 32px;
`;

export const StyledPositiveIcon = styled(StyledIcon)`
    background-color: ${({ theme }) => theme.colors.success.alpha};
`;

export const StyledNegativeIcon = styled(StyledIcon)`
    background-color: ${({ theme }) => theme.colors.danger.alpha};
`;
