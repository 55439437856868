import styled from '@emotion/styled';

export const ModalContainer = styled.div`
    min-width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    text-align: center;
`;

export const ModalHeader = styled.div`
    border-bottom: 1px solid #e5e5e5;
    padding: 15px;
    font-size: 18px;
`;

export const ModalBody = styled.div`
    padding: 24px;
`;
