import styled from '@emotion/styled';

import { STATIC_APP_ASSETS_URL } from 'app/config';

export const ChatbotKnowledgeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const QAKnowledgeListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const QAKnowledgeListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ChatbotKnowledgeButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

export const BusinessVariablesList = styled.ol`
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 16px;
    columns: 2;

    li {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 14px;
        margin-bottom: 8px;
    }
`;

export const BannerIllustrationWrapper = styled.div`
    position: relative;
    width: 220px;
    max-width: 100%;
    height: 120px;
    margin: auto;
    margin-bottom: -20px;

    svg {
        max-width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
    }
`;

export const KnowledgeBannerContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-image: url('${STATIC_APP_ASSETS_URL}/common/chatbot_instruction_background.svg');
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    min-height: 132px;
    padding: 20px 30px;

    .knowledge_banner_text {
        background: linear-gradient(270deg, #c182ff -30.25%, #8a5df2 51.3%, #533891 134.57%);
        background-clip: text;
        color: transparent;
        word-wrap: break-word;
        flex: 1;
    }
`;
