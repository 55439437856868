import { FontAwesomeIconsPartooUsed, Icon, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ActivationSlide1 } from 'app/pages/settings/Chatbot/assets/activation-slide-1';
import { ActivationSlide2 } from 'app/pages/settings/Chatbot/assets/activation-slide-2';
import { ActivationSlide3 } from 'app/pages/settings/Chatbot/assets/activation-slide-3';
import {
    ChatbotActivationContainer,
    ChatbotActivationSlide,
    ChatbotActivationSlideTitle,
    ChatbotActivationSlidesContainer,
} from 'app/pages/settings/Chatbot/components/Activation/ChatbotActivation.styled';

export const ChatbotActivation = () => {
    const { t } = useTranslation();

    return (
        <ChatbotActivationContainer>
            <Stack direction="row" gap="4px" alignItems="center">
                <Icon icon={[FontAwesomeIconsPartooUsed.faCircleInfo]} color="secondary" />
                <Text variant="bodyMBold">{t('chatbot_activation_section_subtitle')}</Text>
            </Stack>
            <ChatbotActivationSlidesContainer>
                <ChatbotActivationSlide>
                    <ActivationSlide1 />
                    <ChatbotActivationSlideTitle as="p" variant="bodyMMedium">
                        {t('chatbot_activation_slide_1_title')}
                    </ChatbotActivationSlideTitle>
                </ChatbotActivationSlide>
                <ChatbotActivationSlide>
                    <ActivationSlide2 />
                    <ChatbotActivationSlideTitle as="p" variant="bodyMMedium">
                        {t('chatbot_activation_slide_2_title')}
                    </ChatbotActivationSlideTitle>
                </ChatbotActivationSlide>
                <ChatbotActivationSlide>
                    <ActivationSlide3 />
                    <ChatbotActivationSlideTitle as="p" variant="bodyMMedium">
                        {t('chatbot_activation_slide_3_title')}
                    </ChatbotActivationSlideTitle>
                </ChatbotActivationSlide>
            </ChatbotActivationSlidesContainer>
        </ChatbotActivationContainer>
    );
};
