import { css } from '@emotion/react';
import styled from '@emotion/styled';

type StyledHiddenTextAreaProps = {
    fontSize: number;
    lineHeight: number;
};

export const StyledHiddenTextArea = styled.textarea<StyledHiddenTextAreaProps>`
    // This element should have same size as the visible MessageInput component.
    pointer-events: none;
    letter-spacing: inherit;
    border: none;
    height: 0;
    padding: 0;
    visibility: hidden;

    ${({ lineHeight, fontSize }) => css`
        line-height: ${lineHeight}px;
        font-size: ${fontSize}px;
    `}
`;

type StyledTextAreaProps = {
    height: number;
    minHeight: number;
    maxHeight: number;
} & StyledHiddenTextAreaProps;

export const StyledTextArea = styled.textarea<StyledTextAreaProps>`
    border: none;
    cursor: text;
    letter-spacing: inherit;
    transition: height 0.2s ease-in;
    box-sizing: content-box;
    min-height: ${({ lineHeight }) => `${lineHeight}px`};
    max-height: ${({ maxHeight }) => `${maxHeight}px`};
    overflow: ${({ height, maxHeight }) => (height <= maxHeight ? 'hidden' : 'scroll')};

    ${({ height, lineHeight, fontSize }) => css`
        height: ${height}px;
        line-height: ${lineHeight}px;
        font-size: ${fontSize}px;
    `}

    &::placeholder {
        color: ${({ theme }) => theme.colors.secondary.initial}!important;
        letter-spacing: inherit;
    }
    &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: ${({ theme }) => theme.colors.secondary.initial} !important;
        letter-spacing: inherit;
    }
    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: ${({ theme }) => theme.colors.secondary.initial} !important;
        letter-spacing: inherit;
    }
    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: ${({ theme }) => theme.colors.secondary.initial} !important;
        letter-spacing: inherit;
    }
    :focus {
        outline: none;
    }
`;
