import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const SettingMenuItemContainer = styled(Link, {
    shouldForwardProp: prop => !['isActive'].includes(prop),
})<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
    margin-top: 4px;
    background-color: ${({ theme, isActive }) =>
        isActive ? theme.colors.primary.alpha : theme.colors.theme.background};
    transition: background-color 0.2s ease-in;
    &:hover {
        background-color: ${({ theme }) => theme.colors.secondary.alpha};
    }
    &:focus {
        background: ${({ theme }) => theme.colors.primary.alpha};
        border-radius: ${({ theme }) => theme.radius.default};
    }
`;
