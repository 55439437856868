import styled from '@emotion/styled';
import { Table } from '@partoohub/ui';

export const StyledTable = styled(Table)`
    width: 100%;
`;

export const StyledCell = styled(Table.Cell)`
    height: 52px;
`;
