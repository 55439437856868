import React from 'react';

import { PostContainer } from 'app/common/designSystem/components/molecules/PreviewPostCard/PreviewPostCard.styled';

import PostCardCover from './PreviewPostCardCover';
import PostCardMain from './PreviewPostCardMain';

type Props = {
    topicType: string;
    photoUrl?: string;
    title?: string;
    dates?: string;
    summary?: string;
    businessInfo: string;
};

const PostCard = ({
    topicType,
    photoUrl,
    title,
    dates,
    summary = '',
    businessInfo = '',
}: Props) => (
    <PostContainer>
        <PostCardCover
            topicType={topicType}
            dates={dates}
            title={title}
            photoUrl={photoUrl}
        />
        <PostCardMain summary={summary} businessInfo={businessInfo} />
    </PostContainer>
);

export default PostCard;
