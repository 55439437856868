import { FC, ReactNode, createContext, useContext, useMemo, useState } from 'react';

interface CustomFieldsSectionContextType {
    disabled: boolean;
    setDisabled: (value: boolean) => void;
}
const CustomFieldsSectionContext = createContext<CustomFieldsSectionContextType>({
    disabled: false,
    setDisabled: (_value: boolean) => undefined,
});

export const useCustomFieldsSectionContext = () =>
    useContext<CustomFieldsSectionContextType>(CustomFieldsSectionContext);

interface Props {
    children: ReactNode;
}

export const CustomFieldsSectionProvider: FC<Props> = ({ children }) => {
    const [disabled, setDisabled] = useState(false);

    const value: CustomFieldsSectionContextType = useMemo(
        () => ({
            disabled,
            setDisabled,
        }),
        [disabled, setDisabled],
    );

    return (
        <CustomFieldsSectionContext.Provider value={value}>
            {children}
        </CustomFieldsSectionContext.Provider>
    );
};
