import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { BUSINESSES, GROUPS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

export default function useGroupDelete(
    groupId: number,
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    return useMutation(() => api.groups.deleteGroup(groupId), {
        onSuccess: () => {
            Promise.all([
                queryClient.invalidateQueries(invalidatesAny(GROUPS)),
                // important to reset and not invalidate
                queryClient.resetQueries(invalidatesAny(BUSINESSES)),
            ])
                .then(onSuccess)
                .catch(() => undefined);
        },
        onError,
    });
}
