import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { Button, IconPrefix } from '@partoohub/ui';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { WhatsappProfile } from 'app/api/types/whatsapp';
import WhatsappApiCalls from 'app/api/v2/api_calls/whatsappApiCalls';
import LoadingView from 'app/common/components/LoadingView';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { WHATSAPP_PHONE_NUMBER } from 'app/common/data/queryKeysConstants';
import WhatsappPhoneNumberSettings from 'app/pages/settings/WhatsappPhoneNumber/WhatsappPhoneNumberSettings';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';

export const WhatsappPhoneNumberPage = () => {
    const { t } = useTranslation();
    const { whatsappPhoneNumberId } = useParams();
    const {
        data: whatsappProfileData,
        isLoading,
        isError,
        error,
    } = useQuery<WhatsappProfile, AxiosError>(
        [WHATSAPP_PHONE_NUMBER, whatsappPhoneNumberId],
        () => WhatsappApiCalls.getProfile(parseInt(whatsappPhoneNumberId!)),
        {
            enabled: whatsappPhoneNumberId !== undefined,
        },
    );

    if (isLoading) {
        return <LoadingView />;
    }

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Monocle,
    };

    if (isError && error.response?.status == 404) {
        return (
            <NotFoundTemplate
                show
                title={t('whatsapp_profile_settings__not_found_title')}
                subtitle={t('whatsapp_profile_settings__not_found_description')}
                imgSrc={illustrationElement}
                withButton={false}
            />
        );
    }

    if (isError) {
        return (
            <NotFoundTemplate
                show
                title={t('whatsapp_profile_settings__error_title')}
                subtitle={t('whatsapp_profile_settings__error_description')}
                imgSrc={illustrationElement}
                withButton={false}
            />
        );
    }

    let action;
    if (!isLoading && whatsappProfileData) {
        const metaLink = new URL('https://business.facebook.com/wa/manage/phone-numbers/');
        metaLink.searchParams.append(
            'waba_id',
            whatsappProfileData.whatsapp_business_account.whatsapp_business_account_id,
        );
        metaLink.searchParams.append(
            'phone_number',
            whatsappProfileData.whatsapp_phone_number.number,
        );
        if (whatsappProfileData.whatsapp_business_account.meta_business_id) {
            metaLink.searchParams.append(
                'business_id',
                whatsappProfileData.whatsapp_business_account.meta_business_id,
            );
        }
        metaLink.searchParams.append('childRoute', 'PHONE_PROFILE/PROFILE');

        action = [
            <a href={metaLink.href} target="_blank" rel="noreferrer">
                <Button
                    dataTrackId={''}
                    appearance="contained"
                    shape="cube"
                    size="medium"
                    variant="primary"
                    icon={['fa-whatsapp', IconPrefix.BRANDS]}
                >
                    {t('whatsapp_profile_settings__edit_my_profile')}
                </Button>
            </a>,
        ];
    }

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('whatsapp_settings_title2')}
                    description={t('whatsapp_settings_description')}
                    actionsButton={action}
                />
            }
        >
            <MenuListContentContainer>
                <WhatsappPhoneNumberSettings whatsappProfileData={whatsappProfileData} />
            </MenuListContentContainer>
        </MenuListContentTemplate>
    );
};
