import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

import Clickable from 'app/common/components/buttons/Clickable';
import { STATIC_BASE_URL } from 'app/config';

type PostCardCoverPhotoType = {
    photoUrl: string | undefined;
    isExpired: boolean;
};

export const PostCardCoverContainer = styled.div`
    grid-area: photo;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 50px 100px;
    position: relative;
`;

export const PostCardCoverPhoto = styled(Clickable)`
    grid-area: 1 / 1 / 3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-position: center;
    background-size: cover;
    ${(props: PostCardCoverPhotoType) => css`
        background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(0, 0, 0, 0.8)),
            url('${props.photoUrl || `${STATIC_BASE_URL}/images/app/post_image_placeholder.png`}');
    `}
    ${(props: PostCardCoverPhotoType) =>
        props.isExpired &&
        css`
            opacity: 0.33;
            filter: grayscale(100%);
        `}
`;

export const PostCardActionButton = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px 12px 0 0;
    display: flex;
    gap: 8px;
`;

export const PostCardCoverPhotoBottom = styled.div`
    position: absolute;
    bottom: 0;
    color: ${HEX_COLORS.white};
    margin: 0 16px 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const PostCardCoverTitle = styled.div`
    display: -webkit-box; /* WebKit */
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    word-break: break-word;
    font-size: 18px;
    font-weight: bold;
    color: ${HEX_COLORS.white};
`;

export const PostCardCoverDate = styled.div`
    margin-bottom: 8px;
    font-size: 12px;
    color: ${HEX_COLORS.white};
    font-weight: bold;
`;
