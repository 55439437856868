import { Outlet, useRoutes } from 'react-router-dom';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { CompetitiveBenchmarkPageHeader } from 'app/pages/analytics/CompetitiveBenchmark/components/HeaderSection/CompetitiveBenchmarkPageHeader';
import { useHasReviewAnalyticsV2 } from 'app/pages/analytics/hooks';
import { ReviewsPageHeader } from 'app/pages/analytics/ReviewAnalytics/pageHeader/ReviewsPageHeader';
import { ReviewsV2PageHeader } from 'app/pages/analytics/ReviewAnalyticsV2/Sections/HeaderSection/ReviewsV2PageHeader';
import { AnalyticsTabs } from 'app/pages/analytics/VisibilityAnalyticsV2/components/AnalyticsTabs';
import { VisibilityPageHeader } from 'app/pages/analytics/VisibilityAnalyticsV2/components/HeaderSection/VisibilityPageHeader';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import {
    COMPETITIVE_BENCHMARK_PATH_TOKEN,
    REVIEWS_PATH_TOKEN,
    VISIBILITY_PATH_TOKEN,
} from 'app/routing/routeIds';

const AnalyticsHeaderRoutes = () => {
    const { data: me } = useMe();
    const meRole = me ? me.role : null;

    const hasReviewAnalyticsV2 = useHasReviewAnalyticsV2();

    const routes = [
        {
            path: VISIBILITY_PATH_TOKEN,
            element: (
                <ProtectedRoute userRole={meRole}>
                    <VisibilityPageHeader />
                </ProtectedRoute>
            ),
        },
        {
            path: REVIEWS_PATH_TOKEN,
            element: (
                <ProtectedRoute userRole={meRole}>
                    <ReviewsPageHeader />
                </ProtectedRoute>
            ),
        },
        ...(!me?.sidebar_excluded_pages.includes('competitive_benchmark')
            ? [
                  {
                      path: COMPETITIVE_BENCHMARK_PATH_TOKEN,
                      element: <CompetitiveBenchmarkPageHeader />,
                  },
              ]
            : []),
        ...(hasReviewAnalyticsV2
            ? [
                  {
                      path: `${REVIEWS_PATH_TOKEN}/v2`,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <ReviewsV2PageHeader />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
    ];

    return useRoutes(routes);
};

export const AnalyticsHeader = () => {
    return (
        <>
            <AnalyticsHeaderRoutes />
            <AnalyticsTabs />
            <Outlet />
        </>
    );
};
