import React, { createContext, useMemo, useState } from 'react';

import { BusinessSearchParams } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { ALL_STATUSES_EXCEPT_CLOSED } from 'app/common/data/businessStatuses';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import PostsListPage from 'app/presence/googlePosts/components/googlePostList/PostsList';
import { PostsListWithNewSidebar } from 'app/presence/googlePosts/components/googlePostList/PostsListWithNewSidebar';

export const POSTS_SEARCH_PARAMS: BusinessSearchParams = {
    subscribed_to_pm: true,
    status: ALL_STATUSES_EXCEPT_CLOSED,
};

export const RefreshPostsContext = createContext<RefreshPostsContextType>({
    refreshPosts: false,
    setRefreshPosts: () => {},
});
export interface RefreshPostsContextType {
    refreshPosts: boolean;
    setRefreshPosts: React.Dispatch<React.SetStateAction<boolean>>;
}

const GooglePostsList = () => {
    const [refreshPosts, setRefreshPosts] = useState(false);
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);

    const PostsListComponent = isNewSidebarEnabled ? PostsListWithNewSidebar : PostsListPage;

    const refreshPostsContext: RefreshPostsContextType = useMemo(
        () => ({
            refreshPosts,
            setRefreshPosts,
        }),
        [refreshPosts],
    );

    return (
        <RefreshPostsContext.Provider value={refreshPostsContext}>
            <PostsListComponent />
        </RefreshPostsContext.Provider>
    );
};

export default GooglePostsList;
