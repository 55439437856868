import { useTranslation } from 'react-i18next';

import useReviewsStats from 'app/common/hooks/queries/reviews/useReviewsStats';
import { useReviewStatsPrevious } from 'app/pages/Homepage/hooks/stats/statsItems/reviews/useReviewStatsPrevious';
import {
    ChipColor,
    ChipEvolution,
    StatChip,
} from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/StatChip';

const getReviewEvolution = (from: number, to: number): number => {
    // Handle edge case
    if (from === 0) {
        return 0;
    }

    const result = Math.round((to - from) * 100) / 100;

    // Handle edge cases
    if (isNaN(result) || result === Infinity || result === -Infinity) {
        return 0;
    }

    // Handle impossible values
    if (result < -5 || result > 5) {
        return 0;
    }

    return result;
};

export const StatReviewsChip = () => {
    const { t } = useTranslation();
    const { data } = useReviewsStats();
    const { data: dataPrevious } = useReviewStatsPrevious();

    const average = data?.average ?? 0;
    const averagePrevious = dataPrevious?.average ?? 0;

    const statEvolution = getReviewEvolution(averagePrevious, average);

    const statChipProps = () => {
        if (statEvolution === 0) {
            return {
                text: `${t('homepage_stats_no_evolution_months', {
                    duration: 6,
                })}`,
                chipColor: ChipColor.GREY,
                chipEvolution: ChipEvolution.NONE,
            };
        }

        if (statEvolution > 0) {
            return {
                text: `${t('homepage_stats_reviews_positive_evolution', {
                    amount: statEvolution.toFixed(2),
                })}`,
                chipColor: ChipColor.GREEN,
                chipEvolution: ChipEvolution.INCREASE,
            };
        }

        return {
            text: `${t('homepage_stats_reviews_negative_evolution', {
                amount: statEvolution.toFixed(2),
            })}`,
            chipColor: ChipColor.RED,
            chipEvolution: ChipEvolution.DECREASE,
        };
    };

    return <StatChip {...statChipProps()} />;
};
