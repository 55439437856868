import styled from '@emotion/styled';

export const SmallModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 468px;
    padding: 24px;
`;

export const ModalSectionName = styled.div`
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const ModalTitle = styled.div`
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 16px;
`;

export const ModalDescription = styled.div`
    margin-bottom: 24px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-weight: bold;
`;

export const ModalButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 24px;
`;
