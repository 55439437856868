import { PropsWithChildren } from 'react';

import { Droppable } from 'react-beautiful-dnd';

import { DroppableZone } from './DroppableWrapper.styled';

interface Props {
    droppableId: string;
}

export const DroppableWrapper = ({
    droppableId,
    children,
}: PropsWithChildren<Props>) => {
    return (
        <Droppable droppableId={droppableId}>
            {droppableRef => (
                <DroppableZone
                    ref={droppableRef.innerRef}
                    {...droppableRef.droppableProps}
                >
                    {children}
                    {droppableRef.placeholder}
                </DroppableZone>
            )}
        </Droppable>
    );
};
