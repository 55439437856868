import styled from '@emotion/styled';

export const ChatbotListContainer = styled.div`
    padding: 24px;
`;

export const ActionsContainer = styled.div`
    display: flex;
    gap: 4px;
`;
