import { useNavigate } from 'react-router-dom';

import { OUTLINED, Type } from 'app/common/designSystem/constants/type';
import { useMessagingSettings } from 'app/reviewManagement/messaging/hooks/useMessagingSettings';
import { SETTINGS_MESSAGES_CONVERSATION_STARTERS_PATHNAME } from 'app/routing/routeIds';
import {
    Conversation,
    ConversationMessage,
    ConversationStarterMessageContent,
    MESSAGE_SENDER_TYPE,
} from 'app/states/messaging';

import { TextBubble } from './TextBubble';
import { TextBubbleContainer } from '../MessageBubbleContent.styled';

interface ConversationStarterBubbleProps {
    conversation: Conversation;
    message: ConversationMessage;
    handleBubbleClick: () => void;
}

export const ConversationStarterBubble = ({
    conversation,
    message,
    handleBubbleClick,
}: ConversationStarterBubbleProps) => {
    const navigate = useNavigate();
    const [allSettings] = useMessagingSettings('', conversation.business.id);
    const correspondingSetting = allSettings.find(
        setting => setting.businessId === conversation.business.id,
    );
    const isConsumerMessage = message.sender_type === MESSAGE_SENDER_TYPE.CONSUMER;
    const conversationMessageContent = message.content as ConversationStarterMessageContent;
    const conversationStarterSideIcon = {
        name: 'fa-gear',
        onClickIcon: () =>
            navigate(
                `${SETTINGS_MESSAGES_CONVERSATION_STARTERS_PATHNAME}?conversationStarterId=${correspondingSetting?.conversationStarterId ?? ''}`,
            ),
    };
    return (
        <TextBubbleContainer>
            {conversationMessageContent.text && (
                <TextBubble
                    text={conversationMessageContent.text}
                    isConsumerMessage={isConsumerMessage}
                    onClick={handleBubbleClick}
                    sideIcon={conversationStarterSideIcon}
                />
            )}
            {conversationMessageContent.buttons.map(button => (
                <TextBubble
                    text={button.title}
                    isConsumerMessage={isConsumerMessage}
                    bubbleType={OUTLINED as Type}
                    onClick={() => undefined}
                    sideIcon={conversationStarterSideIcon}
                />
            ))}
        </TextBubbleContainer>
    );
};
