import React from 'react';

import { MetricsTemplateChart } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Metrics/MetricsChart/MetricsTemplateChart/MetricsTemplateChart';
import { useMetricsViewsChart } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/metrics/useMetricsViewsChart';
import { AnalyticsQueryKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queryKeys';

export const ViewsChart = () => {
    const VIEWS_KEY = 'views';

    const { data, isLoading, refetch, error } = useMetricsViewsChart();

    return (
        <MetricsTemplateChart
            name={VIEWS_KEY}
            icon="fa-eye"
            orderBykey={AnalyticsQueryKeys.ORDER_BY_VIEWS}
            dimensionKey={AnalyticsQueryKeys.DIMENSIONS_VIEWS}
            data={data}
            isLoading={isLoading}
            refetch={refetch}
            error={error}
            dataTrack="analytics_visiblity__chart_views"
        />
    );
};
