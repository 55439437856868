import React, { useEffect } from 'react';

import { useQuery } from 'react-query';

import {
    AvailableGoogleVerificationMethod,
    GoogleOptionsMethodData,
    GoogleVerificationStartData,
    V2FormattedLocationData,
} from 'app/api/types/account';
import api from 'app/api/v2/api_calls';
import { GOOGLE_VERIFICATION_OPTIONS } from 'app/common/data/queryKeysConstants';

import { LoadingOptionCard, getCardByMethod } from './OptionCard';
import { OptionListWrapper } from './Options.styled';
import { useGoogleVerificationContext } from '../GoogleVerification.context';

type OptionSelectedType = {
    phoneNumber?: string;
    email?: string;
    fullName?: string;
    fullAddress?: string;
    expectedDeliveryDays?: number;
    isPending: boolean;
    method: AvailableGoogleVerificationMethod;
};

type SelectOptionProps = {
    location: V2FormattedLocationData;
    optionSelected?: GoogleVerificationStartData;
    setOptionSelected: React.Dispatch<React.SetStateAction<GoogleVerificationStartData>>;
    setIsOnConfirmationScreen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OptionList: React.FC<SelectOptionProps> = ({
    location,
    optionSelected,
    setOptionSelected,
    setIsOnConfirmationScreen,
}) => {
    const { store, setStore } = useGoogleVerificationContext();
    const { isLoading, isError, data } = useQuery(
        [GOOGLE_VERIFICATION_OPTIONS, location.id],
        () => api.account.fetchGoogleVerificationsOptions(location.id),
        {
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );

    useEffect(() => {
        if (isError) {
            setStore((prev: any) => ({
                ...prev,
                locations: {
                    ...(prev.locations || []),
                    [location.id]: { screen: 'error' },
                },
            }));
            return;
        }

        if (!data) return;

        if (data.status === 'success') {
            if (data.message === 'listing_verified') {
                setStore((prev: any) => ({
                    ...prev,
                    locations: {
                        ...(prev.locations || []),
                        [location.id]: { screen: 'verified' },
                    },
                }));
            } else if (
                !data.options?.some(({ status }) => ['AVAILABLE', 'PENDING'].includes(status))
            )
                setStore((prev: any) => ({
                    ...prev,
                    locations: {
                        ...(prev.locations || []),
                        [location.id]: { screen: 'business_video' },
                    },
                }));

            const pendingOption = data.options?.find(option => option.status === 'PENDING');
            if (
                pendingOption &&
                !(store?.locations && store?.locations[location.id]?.screen === 'force_method_list')
            ) {
                setStore((prev: any) => ({
                    ...prev,
                    locations: {
                        ...(prev.locations || []),
                        [location.id]: {
                            screen: 'complete',
                            optionSelected: {
                                ...pendingOption,
                                isPending: true,
                            } as OptionSelectedType,
                        },
                    },
                }));
            }
        } else if (data.message === 'listing_in_review') {
            setStore((prev: any) => ({
                ...prev,
                locations: {
                    ...(prev.locations || []),
                    [location.id]: {
                        screen: data.message,
                        warningMessage: data.message,
                    },
                },
            }));
        } else {
            setStore((prev: any) => ({
                ...prev,
                locations: {
                    ...(prev.locations || []),
                    [location.id]: {
                        screen: 'error',
                        errorMessage: data.message,
                    },
                },
            }));
        }
    }, [isLoading, isError, data]);

    const selectOption = (option: GoogleOptionsMethodData) => {
        setOptionSelected(option);
        setIsOnConfirmationScreen(true);
    };

    return (
        <OptionListWrapper>
            {isLoading && (
                <>
                    <LoadingOptionCard />
                    <LoadingOptionCard />
                </>
            )}
            {!isLoading &&
                data?.options
                    ?.filter(option => option.status === 'AVAILABLE')
                    .map(option =>
                        getCardByMethod(option.method, true, optionSelected?.method, () =>
                            selectOption(option),
                        ),
                    )}
        </OptionListWrapper>
    );
};
