import styled from '@emotion/styled';

export const DesktopHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const PageHeaderFilter = styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 500px;
    height: 50px;
    margin-bottom: auto;

    .multiple_select__placeholder {
        font-weight: bold;
        color: black;
    }

    > *:not(:last-child) {
        margin-right: 15px;
    }
`;
