import { AppIcon, AppIconsValuesEnum } from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useFormatNumber } from 'app/common/hooks/useFormatNumber';
import { STATIC_APP_ASSETS_URL } from 'app/config';
import {
    useHomepagePresenceStatsSearches,
    useRetrieveTotalSearches,
} from 'app/pages/Homepage/hooks/stats/statsItems/presence/useHomepagePresenceStats';
import { useIsLoadingMessagingStats } from 'app/pages/Homepage/hooks/stats/statsItems/presence/useIsLoadingPresenceStats';
import { DefaultStatComponent } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent';
import { SmallStatProps } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/DefaultStatComponent';
import { ANALYTICS_VISIBILITY_PATH } from 'app/routing/routeIds';

import { StatPresenceChip } from './StatPresenceChip';

export const StatPresence = (props: SmallStatProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data } = useHomepagePresenceStatsSearches();
    const isLoading = useIsLoadingMessagingStats();

    const totalSearches = useRetrieveTotalSearches(data);
    const formattedTotalSearches = useFormatNumber(totalSearches);

    const noStats = totalSearches === 0 || isNaN(totalSearches);

    return (
        <DefaultStatComponent
            {...props}
            productTitle={t('homepage_stats_presence_title')}
            statTitle={formattedTotalSearches}
            statSubtitle={t('homepage_stats_presence_subtitle')}
            description={t('homepage_stats_presence_description')}
            productIcon={
                <AppIcon
                    dataTrackId="stat_presence__app_icon"
                    name={AppIconsValuesEnum.GetFound}
                    width="24"
                    height="24"
                />
            }
            isLoading={isLoading}
            onIconClick={() => navigate(ANALYTICS_VISIBILITY_PATH)}
            bannerUrl={`${STATIC_APP_ASSETS_URL}/homepage/VisibilityBanner.svg`}
            displayEmptyPlaceholder={!isLoading && noStats}
            statChip={<StatPresenceChip />}
            dataTrack="click_homepage_stats_presence"
        />
    );
};
