import { useCallback, useContext, useState } from 'react';

import { Filters, Option, Stack } from '@partoohub/ui';
import capitalize from 'lodash/capitalize';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BusinessModalButton from 'app/common/components/businessModal';
import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';

import useSelectedBusinessesTitle from 'app/common/components/businessModal/hooks/useSelectedBusinessesTitle';
import useTotalBusinessesCount from 'app/common/components/businessModal/hooks/useTotalBusinessesCount';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { ButtonBusinessSelect } from 'app/common/designSystem/components/molecules/ButtonBusinessSelect/ButtonBusinessSelect';
import dataLayer from 'app/common/utils/dataLayer';
import { POSTS_SEARCH_PARAMS } from 'app/presence/googlePosts/components/googlePostList';
import ConnectionBanner from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/screens/TypePlatform/ConnectionBanner';
import {
    FACEBOOK,
    INSTAGRAM,
    PLATFORMS,
    PLATFORMS_IMAGE,
    PLATFORM_CONNECTION,
    Platform,
} from 'app/presence/googlePosts/components/services/constants';
import usePostBulkCount from 'app/presence/googlePosts/hooks/usePostBulkCount';
import {
    EVENT_TYPE,
    NEWS_TYPE,
    OFFER_TYPE,
} from 'app/presence/googlePosts/variables/googlePostType';
import Flexbox from 'app/styles/utils/flexbox';

import InstagramConnectModal from './InstagramConnectModal';
import {
    ContentContainer,
    ContentTitle,
    MandatoryField,
    PlatformContainer,
    PlatformIcon,
    PlatformLabel,
    PlatformLabelContainer,
    PlatformTitle,
    PlatformsContainer,
    StyledCheckbox,
} from './PostFormTypePlatform.styled';
import { EditPostsContext, EditPostsContextType } from '../../../PostsList';
import NextBackButtons from '../../components/NextBackButtons/NextBackButtons';
import { NewPostContext, NewPostContextType } from '../../context/NewPost';
import usePlatformSelection from '../../hooks/usePlatformSelection';

export default function PostFormTypePlatform() {
    const { t } = useTranslation();
    const { postCreationModalEditMode, postToEditData } = useContext(EditPostsContext);

    const {
        formFields: { platform = [], type },
        unauthorizedBusinessCounts = {},
        setNextStep,
        updateField,
        connectionStats,
    } = useContext<NewPostContextType>(NewPostContext);

    // Number of businesses available
    const totalBusinesses = useTotalBusinessesCount();

    const { containsFacebook, containsInstagram } = usePlatformSelection();

    const { bulkId } = useContext<EditPostsContextType>(EditPostsContext);
    const { bulkCount } = usePostBulkCount(bulkId);

    const businessCountRedux = useSelectedBusinessesCount() ?? 0;
    const businessCount = bulkCount || businessCountRedux;

    const { reset: resetCurrentForm } = useFormContext();

    const postTypeOptions = [
        {
            label: t('post_type_name_news'),
            value: NEWS_TYPE,
            name: NEWS_TYPE,
        },
        {
            label: t('post_type_name_offer'),
            value: OFFER_TYPE,
            name: OFFER_TYPE,
        },
        {
            label: t('post_type_name_event'),
            value: EVENT_TYPE,
            name: EVENT_TYPE,
        },
    ] as Array<Option>;

    const isScopeLoading = Object.keys(unauthorizedBusinessCounts).length === 0;
    const isAllUnauthorized: boolean = Object.values(unauthorizedBusinessCounts).every(
        (value: number) => value > 0 && value === businessCount,
    );

    const isFormValid = platform.length > 0 && businessCount > 0 && !!type;
    const isSubmissionDisabled: boolean = !isFormValid || isAllUnauthorized || isScopeLoading;

    const [showInstagramModal, setShowInstagramModal] = useState<boolean>(false);

    const onPlatformClick = useCallback(
        (value: Platform) => {
            const newPlatform = [...platform];

            if (!platform.includes(value)) {
                newPlatform.push(value);
            } else {
                newPlatform.splice(newPlatform.indexOf(value), 1);
            }

            if (newPlatform.includes(FACEBOOK) && !postCreationModalEditMode) {
                updateField({ field: 'postImage', value: [] });
            }

            if (newPlatform.includes(FACEBOOK) || newPlatform.includes(INSTAGRAM)) {
                updateField({ field: 'type', value: 'news' });
                // added to avoid covid/offer/news post when not possible
                // remove when added the facebook covid/offer/news integration
            }

            updateField({ field: 'platform', value: newPlatform });
        },
        [platform],
    );

    const onPostTypeChange = (option: Record<string, boolean>) => {
        const optionValue = Object.keys(option).find(key => !!option[key]);

        if (optionValue) {
            updateField({ field: 'type', value: optionValue });
        }

        resetCurrentForm(); // se we don't don't have the same info for different post type in post description
        updateField({ field: 'isScheduled', value: false });
        updateField({ field: 'scheduledDate', value: null });
        updateField({ field: 'scheduledTime', value: null });
        updateField({
            field: 'postImage',
            value: [],
        });
        updateField({
            field: 'buttonTypeGoogle',
            value: null,
        });
    };

    // Generate label to be displayed on the business modal button
    const { title: buttonTitle } = useSelectedBusinessesTitle();
    let label = '';
    if (businessCount === 1) {
        label = postToEditData?.businessInfo || buttonTitle;
    } else if (businessCount > 1) {
        if (bulkId) {
            label = t('selected_businesses_with_count', {
                count: businessCount,
            });
        } else {
            label = buttonTitle;
        }
    }

    const selectedValue = postTypeOptions.filter(option => option.value === type);
    const options = postTypeOptions.filter(option => {
        if (containsFacebook || containsInstagram) return option.value === NEWS_TYPE;
        else return true; // return all options in multiplatform
    });

    const sections = [{ options }];

    return (
        <Flexbox flexDirection="column" gap="24px">
            <ConnectionBanner onInstagramBannerClick={() => setShowInstagramModal(true)} />
            {showInstagramModal && (
                <InstagramConnectModal
                    onClose={() => {
                        setShowInstagramModal(false);
                    }}
                />
            )}
            <ContentContainer>
                <ContentTitle>{t('post_type_and_platform')}</ContentTitle>
                <Stack gap="24px">
                    <PlatformTitle>
                        {t('post_select_platform')}
                        <MandatoryField>*</MandatoryField>
                    </PlatformTitle>
                    <PlatformsContainer>
                        {PLATFORMS.map((p, index) => (
                            <TooltipWrapper
                                key={`${p}-${index}`}
                                text={
                                    connectionStats && connectionStats[PLATFORM_CONNECTION[p]] === 0
                                        ? t('post_no_connection_established')
                                        : ''
                                }
                                position={'top'}
                            >
                                <PlatformContainer
                                    checked={platform.includes(p)}
                                    onClick={() => {
                                        dataLayer.pushDict(`select_${p}_post`);
                                        onPlatformClick(p);
                                    }}
                                    disabled={
                                        postCreationModalEditMode ||
                                        (connectionStats &&
                                            connectionStats[PLATFORM_CONNECTION[p]] === 0)
                                    }
                                >
                                    <PlatformLabelContainer>
                                        <PlatformIcon src={PLATFORMS_IMAGE[p]} alt={p} />
                                        <PlatformLabel>{capitalize(p)}</PlatformLabel>
                                    </PlatformLabelContainer>
                                    <StyledCheckbox
                                        dataTrackId="reply_template_form__checkbox"
                                        aria-label=""
                                        checked={platform.includes(p)}
                                        onChange={() => undefined}
                                    />
                                </PlatformContainer>
                            </TooltipWrapper>
                        ))}
                    </PlatformsContainer>
                    <Filters
                        dataTrackId="post_select_type"
                        position="bottom"
                        selectedItems={selectedValue ? { [selectedValue[0].value]: true } : {}}
                        menuListArray={sections}
                        maxHeight={300}
                        onChange={onPostTypeChange}
                        placeholder={t('post_select_type')}
                        required
                        disabled={postCreationModalEditMode}
                        disableReset
                    />
                    <BusinessModalButton
                        searchFilters={POSTS_SEARCH_PARAMS}
                        resetReducerOnUnmount={false}
                        button={
                            <ButtonBusinessSelect
                                label={label}
                                placeholder={t('business_selection')}
                                isMandatory
                                disabled={postCreationModalEditMode || totalBusinesses === 1}
                                readonly={totalBusinesses === 1}
                            />
                        }
                    />
                </Stack>

                <NextBackButtons
                    onNext={() => {
                        setNextStep?.();
                    }}
                    isLoading={isFormValid && isScopeLoading}
                    disabled={isSubmissionDisabled}
                />
            </ContentContainer>
        </Flexbox>
    );
}
