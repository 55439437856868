import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';
import { Button, Text } from '@partoohub/ui';

import { STATIC_APP_ASSETS_URL } from 'app/config';

export const ChatbotActivationSlidesContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ChatbotActivationSlide = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    gap: 8px;
    & > svg {
        width: 100%;
        height: auto;
    }
`;

export const ChatbotActivationSlideTitle = styled(Text)`
    text-align: center;
`;

export const ChatbotActivationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px;
    background-color: ${greyColorObject.alpha};
    border-radius: 8px;
`;

export const ActivatedDialog = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    background: #102942 url('${STATIC_APP_ASSETS_URL}/common/confetti-robot-optimized.gif');
    width: 490px;
    height: 354px;
    border-radius: 8px;
    text-align: center;
    padding: 150px 24px 24px;
`;

export const ActivatedModelButton = styled(Button)`
    background: #fff;
    span {
        color: #000;
    }

    &:hover {
        span {
            color: #fff;
        }
    }
`;
