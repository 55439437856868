export const DOWNLOADABLE_MAX = 30000;

// ACTION TYPES
export const SEARCH_REVIEWS_SUCCESS = 'SEARCH_REVIEWS_SUCCESS';
export const SEARCH_REVIEWS_FAILURE = 'SEARCH_REVIEWS_FAILURE';
export const SEARCH_REVIEWS_RESET = 'SEARCH_REVIEWS_RESET';
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
export const DELETE_REVIEW = 'DELETE_REVIEW';
export const UPDATE_REVIEW_MARK_AS_TREATED = 'UPDATE_REVIEW_MARK_AS_TREATED';
export const UPDATE_REVIEW_MARK_AS_NOT_TREATED = 'UPDATE_REVIEW_MARK_AS_NOT_TREATED';
export const UPDATE_REVIEW_MARK_AS_DELETED = 'UPDATE_REVIEW_MARK_AS_DELETED';
export const UPDATE_UNSEEN_REVIEWS_COUNT = 'UPDATE_UNSEEN_REVIEWS_COUNT';
export const UPDATE_COUNT = 'UPDATE_COUNT';
export const UPDATE_TAG_ON_REVIEWS = 'UPDATE_TAG_ON_REVIEWS';
export const DELETE_TAG_ON_REVIEWS = 'DELETE_TAG_ON_REVIEWS';
