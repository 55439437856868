import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const SPECIFIC_HOUR_BOX_WIDTH = 180;
export const SPECIFIC_HOUR_BOX_HEIGHT = 56;
export const SPECIFIC_HOUR_GAP = 8;

export const OpenHourContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 24px 8px 24px 24px;
    margin-bottom: 16px;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    border-radius: ${({ theme }) => theme.radius.large};
    gap: 24px;
`;

export const OpenHourHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 16px;
`;

export const OpenHourTogglesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const OpenHourContainerLeftRight = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OpenHourIconButtons = styled.div`
    display: flex;

    > * {
        margin-left: 4px;
    }
`;

export const HoursContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const HoursContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const OpenHourAllDayContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: ${SPECIFIC_HOUR_GAP}px;
    flex-grow: 1;

    > * {
        flex-grow: 1;
        max-width: ${SPECIFIC_HOUR_BOX_WIDTH}px;
        min-height: ${SPECIFIC_HOUR_BOX_HEIGHT}px;
    }
`;

export const OpenHourStartEndContainer = styled.div`
    display: flex;
    gap: ${SPECIFIC_HOUR_GAP}px;
    flex-grow: 1;

    > * {
        flex-grow: 1;
        max-width: ${SPECIFIC_HOUR_BOX_WIDTH}px;
        min-height: ${SPECIFIC_HOUR_BOX_HEIGHT}px;
    }
`;

export const ErrorWarningText = styled(Text)`
    margin-left: 16px;
    margin-bottom: 8px;
`;

// Temporary fixes, need to update TextInput component in partoo-ui
type TextInputWithErrorProps = {
    error: boolean;
};

export const TextInputWithError = styled.div<TextInputWithErrorProps>`
    ${({ error, theme }) =>
        error &&
        css`
            div {
                border-color: ${theme.colors.danger.initial};

                &:hover {
                    border-color: ${theme.colors.danger.initial};
                }
            }
        `}
`;

export const EmptyButton = styled.div`
    width: 40px;
    height: 40px;
`;

export const EmptyTextInput = styled.div`
    box-sizing: content-box;
    min-height: ${SPECIFIC_HOUR_BOX_HEIGHT}px;
`;
