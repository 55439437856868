import styled from '@emotion/styled';

export const CustomFieldsCreateSectiongModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px 24px;
    width: 524px;
`;

export const CustomFieldsCreateSectionModalButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-left: auto;
`;
