import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { useProductUpsale } from 'app/common/hooks/queries/useProductUpsale';

export const NoReviewManagementSubscribed = () => {
    const { refetch } = useProductUpsale('review_management');
    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.ReviewManagementUnsubscribed,
    };
    return (
        <NotFoundTemplate
            show={true}
            imgSrc={illustrationElement}
            title="settings_reports_on_no_review_management"
            subtitle="subscribe_to_rm_subtitle"
            buttonText="contact_us_to_get_more_information"
            buttonStatus="contained"
            handleClick={refetch}
        />
    );
};
