import { css } from '@emotion/react';
import { IconButton } from '@partoohub/ui';

import { Option } from '@partoohub/ui/dist/types/components/menuList';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReviewObjectType } from 'app/api/types/review';
import IS_IFRAME from 'app/common/data/iframe';
import { ORG_ADMIN } from 'app/common/data/roles';
import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import dataLayer from 'app/common/utils/dataLayer';
import { useCanShowClientReviewCards } from 'app/pages/customerExperience/useCanShowClientReviewCards';
import { useFeedbackFormList } from 'app/pages/settingsV2/subPages/Reviews/components/FeedbackFormListPage/hooks';
import { ANALYTICS_REVIEW_PATH } from 'app/routing/routeIds';
import { businessParams } from 'app/utils/queryString';

export const AnalyticsButton = () => {
    const { data: me } = useMe();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onViewAnalyticsClick = (): void => {
        // GTM
        dataLayer.pushDict('click_stats_button');
        navigate(`${ANALYTICS_REVIEW_PATH}?${businessParams()}`);
    };
    const [forms] = useFeedbackFormList();

    const { canShowClientReviewCards } = useCanShowClientReviewCards();
    const configuration = {
        [ReviewObjectType.REVIEW]: {
            show: canShowClientReviewCards[ReviewObjectType.REVIEW],
            option: {
                value: ReviewObjectType.REVIEW,
                name: ReviewObjectType.REVIEW,
                label: t('review_object_type_reviews'),
                icon: ['fa-star'],
            },
            onMenuClick: onViewAnalyticsClick,
        },
        [ReviewObjectType.FEEDBACK_RESULT]: {
            show:
                canShowClientReviewCards[ReviewObjectType.FEEDBACK_RESULT] &&
                me?.role === ORG_ADMIN &&
                Boolean(forms?.[0]?.looker_studio_dashboard_link),
            option: {
                value: ReviewObjectType.FEEDBACK_RESULT,
                name: ReviewObjectType.FEEDBACK_RESULT,
                label: t('review_object_type_feedback_results'),
                icon: ['fa-gauge'],
            },
            onMenuClick: () => {
                if (forms?.[0]?.looker_studio_dashboard_link) {
                    window.open(forms[0].looker_studio_dashboard_link, '_blank');
                }
            },
        },
    };

    if (IS_IFRAME) {
        return <></>;
    }

    const menuItems = Object.values(configuration).reduce((menuItems, { show, option }) => {
        if (show) {
            menuItems.push(option as Option);
        }
        return menuItems;
    }, [] as Option[]);

    if (!menuItems.length) {
        return <></>;
    }

    const onMenuClick = (value: ReviewObjectType) => {
        return configuration[value]?.onMenuClick();
    };

    if (menuItems.length === 1) {
        const value = menuItems[0].value as ReviewObjectType;

        return (
            <IconButton
                dataTrackId="review_list_go_to_analytics"
                appearance="outlined"
                variant="default"
                icon={['fa-chart-column']}
                onClick={() => onMenuClick(value)}
                tooltip={t('review_management_view_statistics')}
            />
        );
    }

    return (
        <ActionButtonWithMenu
            dataTrackId="review_list_go_to_analytics"
            icon={['fa-chart-column']}
            appearance="outlined"
            variant="default"
            // @ts-expect-error
            onMenuClick={onMenuClick}
            options={menuItems}
            tooltip={t('review_management_view_statistics')}
            cssMenu={css`
                min-width: 160px;
                top: 44px;
                right: 0px;
                left: unset;
            `}
        />
    );
};
