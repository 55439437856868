import { useTranslation } from 'react-i18next';

import { Navigate } from 'react-router-dom';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import NotAccessibleForAdmin from 'app/common/components/placeholder/NotAccessibleForAdmin';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import { PRESENCE_MANAGEMENT } from 'app/common/data/productIds';
import { ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMe';
import { LoaderView } from 'app/pages/analytics/VisibilityAnalyticsV2/components/LoaderView/LoaderView';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

import CompetitiveBenchmarkRoot from './CompetitiveBenchmarkRoot';
import { CompetitiveBenchmarkRootWithNewSidebar } from './CompetitiveBenchmarkRootWithNewSidebar';

export const CompetitiveBenchmarkPage = () => {
    const { t } = useTranslation();
    const { data: me, isLoading } = useMe();
    const hasRights = ![ADMIN].includes(me?.role || '');
    const hasPmAccess = !!me?.sidebarProducts.includes(PRESENCE_MANAGEMENT.toLowerCase());

    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const CompetitiveBenchmarkRootComponent = isNewSidebarEnabled
        ? CompetitiveBenchmarkRootWithNewSidebar
        : CompetitiveBenchmarkRoot;

    return (
        <>
            <DynamicPageHeader title={t('competitive_benchmark_title')} />
            {!isLoading ? (
                <>
                    {hasPmAccess && hasRights && <CompetitiveBenchmarkRootComponent />}
                    {hasPmAccess && !hasRights && <NotAccessibleForAdmin />}
                    {!hasPmAccess && <Navigate replace to={NOT_FOUND_PATH} />}
                </>
            ) : (
                <LoaderView height={100} />
            )}
        </>
    );
};
