import { useQuery } from 'react-query';

import { ReviewPermissionName } from 'app/api/types/review';
import ReviewApiCalls from 'app/api/v2/api_calls/reviewApiCalls';

/**
 * @deprecated
 */
export function useHasReviewPermission(permission: ReviewPermissionName) {
    const { data } = useReviewPermissions();

    return !!data && data[permission] === true;
}

/**
 * @deprecated
 * Use useOrgFieldMetadata instead of a custom route (cf: ./useHadReviewReplyPermission.ts)
 */
export default function useReviewPermissions() {
    return useQuery('REVIEW_PERMISSIONS', () => ReviewApiCalls.getPermissions());
}
