import React from 'react';

import { Switch, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { SpecificHourFormPeriod } from 'app/api/types/business';
import { isOpenAllDay } from 'app/businessEditV2/utils/specificHours';
import { OpenHourTogglesContainer } from 'app/common/components/OpenHour/OpenHour.styled';

type Props = {
    disabled: boolean;
    specificHour: SpecificHourFormPeriod;
    onChange: (specificHour: SpecificHourFormPeriod) => void;
};

const Toggles = ({ disabled, specificHour, onChange }: Props) => {
    const { t } = useTranslation();

    const openHours = specificHour.open_hours;

    const toggleOpenClose = () => {
        const newOpenValue = !specificHour.open;

        onChange({
            ...specificHour,
            open: newOpenValue,
            open_hours: newOpenValue ? [''] : [],
        });
    };

    const toggleAllDay = () => {
        onChange({
            ...specificHour,
            open: true,
            open_hours: isOpenAllDay(specificHour.open_hours) ? [''] : ['00:00-23:59'],
        });
    };

    return (
        <OpenHourTogglesContainer>
            <Switch
                disabled={disabled}
                checked={specificHour.open}
                dataTrackId="specific-hours-open-close-switch"
                name="specific-hours-open-close-switch"
                onChange={toggleOpenClose}
                label={
                    <Text variant="bodyMBold" color={'secondary'}>
                        {specificHour.open ? t('open') : t('closed')}
                    </Text>
                }
            />
            <Switch
                disabled={disabled}
                checked={isOpenAllDay(openHours)}
                dataTrackId="specific-hours-24/7-switch"
                name="specific-hours-24/7-switch"
                onChange={toggleAllDay}
                label={
                    <Text variant="bodyMBold" color={'secondary'}>
                        {t('specific_hours__twenty_four_seven')}
                    </Text>
                }
            />
        </OpenHourTogglesContainer>
    );
};

export default Toggles;
