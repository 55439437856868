import { css } from '@emotion/react';
import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix, Option } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { SpecificHourFormPeriod } from 'app/api/types/business';
import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';
import {
    ErrorWarningText,
    OpenHourIconButtons,
} from 'app/common/components/OpenHour/OpenHour.styled';
import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';

import DatePickerStartEnd from './DatePickerStartEnd';
import { DatesWithButtonsContainer } from './DatesWithButtons.styled';

type Props = {
    disabled: boolean;
    specificHour: SpecificHourFormPeriod;
    onChange: (specificHour: SpecificHourFormPeriod) => void;
    duplicateSpecificHour: () => void;
    deleteSpecificHour: () => void;
    applyDefaultHours: () => void;
    hasDateError: boolean;
};

export enum SpecificHourActions {
    DUPLICATE = 'duplicate',
    DELETE = 'delete',
}

const DatesWithButtons = ({
    disabled,
    specificHour,
    onChange,
    duplicateSpecificHour,
    deleteSpecificHour,
    applyDefaultHours,
    hasDateError,
}: Props) => {
    const { t } = useTranslation();

    const options: Array<Option> = [
        {
            name: SpecificHourActions.DUPLICATE,
            value: SpecificHourActions.DUPLICATE,
            label: <MenuLabelItem text={t('duplicate')} icon={['fa-copy', IconPrefix.REGULAR]} />,
        },
        {
            name: SpecificHourActions.DELETE,
            value: SpecificHourActions.DELETE,
            label: (
                <MenuLabelItem
                    text={t('delete')}
                    icon={['fa-trash', IconPrefix.REGULAR]}
                    color="danger"
                />
            ),
        },
    ];

    const onMenuClick = (value: string) => {
        if (value === SpecificHourActions.DUPLICATE) {
            duplicateSpecificHour();
        }
        if (value === SpecificHourActions.DELETE) {
            deleteSpecificHour();
        }
    };

    return (
        <>
            <DatesWithButtonsContainer>
                <DatePickerStartEnd
                    disabled={disabled}
                    specificHour={specificHour}
                    onChange={onChange}
                    hasDateError={hasDateError}
                />

                <OpenHourIconButtons>
                    <IconButton
                        appearance="outlined"
                        icon={[FontAwesomeIconsPartooUsed.faBoltLightning, IconPrefix.REGULAR]}
                        dataTrackId="specific-hour-apply-default-hours"
                        onClick={applyDefaultHours}
                        disabled={disabled}
                        tooltip={t('apply_time_slot_reference')}
                    />
                    {!disabled && (
                        <ActionButtonWithMenu
                            dataTrackId=""
                            icon={['fa-ellipsis-v', IconPrefix.SOLID]}
                            appearance="outlined"
                            onMenuClick={value => onMenuClick(value)}
                            options={options}
                            cssMenu={css`
                                top: 50px;
                                right: 0px;
                                left: unset;
                            `}
                            tooltip={t('more')}
                        />
                    )}
                </OpenHourIconButtons>
            </DatesWithButtonsContainer>
            {hasDateError && (
                <ErrorWarningText variant="bodySRegular" color="danger">
                    {t('cannot be empty')}
                </ErrorWarningText>
            )}
        </>
    );
};

export default DatesWithButtons;
