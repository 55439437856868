export const tripAdvisorRating = (rate: number) => {
    const whole = Math.floor(rate);
    return [
        ['tripadvisor-green-full', whole],
        ['tripadvisor-green-empty', 5 - whole],
    ];
};

export const starRating = (rate: number) => {
    const whole = Math.floor(rate);
    const half = Math.ceil(rate - whole);
    return [
        ['fas fa-star', whole],
        ['fas fa-star-half-alt', half],
        ['far fa-star', 5 - whole - half],
    ];
};

export default starRating;
