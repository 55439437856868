import { Filters, Section } from '@partoohub/ui';
import { useController } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { FlexContainer } from './ControlledFilters.styled';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    placeholder: string;
    menuListArray: Section[];
    disableReset: boolean;
};

export const ControlledFilters = ({
    name,
    control,
    placeholder,
    menuListArray,
    disableReset,
}: ControlledComponent) => {
    const {
        field: { ref: _ref, value, ...usedFields },
    } = useController({
        name,
        control,
    });

    return (
        <FlexContainer>
            <Filters
                dataTrackId="controlled_single_select"
                position="bottom"
                menuListArray={menuListArray}
                maxHeight={300}
                selectedItems={value}
                placeholder={placeholder}
                disableReset={disableReset}
                {...usedFields}
            />
        </FlexContainer>
    );
};
