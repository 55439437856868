import { cloneDeep } from 'lodash-es';

import { OpenHoursData } from 'app/api/types/business';
import {
    DAYS,
    HOUR_SEPARATOR,
} from 'app/businessEditV2/oldFromEditV1/data/constants';
import {
    getNextDay,
    isMidnightOpenHourWindow,
} from 'app/businessEditV2/oldFromEditV1/services/utils';

const MIDNIGHT_START_HOUR = '00:00';
const MIDNIGHT_END_HOUR = '00:00';

type BeforeAndAfterMidnightWindows = {
    beforeMidnight: string;
    afterMidnight: string;
};

type MidnightAndRegularHours = {
    regularHours: Array<string>;
    midnightHours: Array<string>;
};

const splitMidnightWindow = (
    openHourWindow: string,
): BeforeAndAfterMidnightWindows => {
    const [startTime, endTime] = openHourWindow.split(HOUR_SEPARATOR);
    return {
        beforeMidnight: `${startTime}-${MIDNIGHT_END_HOUR}`,
        afterMidnight: `${MIDNIGHT_START_HOUR}-${endTime}`,
    };
};

const separateMidnightAndRegularHours = (
    openHours: Array<string>,
): MidnightAndRegularHours =>
    openHours.reduce(
        ({ regularHours, midnightHours }: MidnightAndRegularHours, window) => ({
            regularHours: [
                ...(isMidnightOpenHourWindow(window)
                    ? regularHours
                    : [...regularHours, window]),
            ],
            midnightHours: [
                ...(isMidnightOpenHourWindow(window)
                    ? [...midnightHours, window]
                    : midnightHours),
            ],
        }),
        {
            regularHours: [],
            midnightHours: [],
        },
    );

export const formatOpenHoursFields = (
    openHoursData: OpenHoursData,
): OpenHoursData =>
    DAYS.reduce((formattedOpenHoursData, day) => {
        const dayOpenHours = [...openHoursData[day]];
        const { regularHours, midnightHours } =
            separateMidnightAndRegularHours(dayOpenHours);
        const splitMidnightWindows = midnightHours.map(splitMidnightWindow);
        const nextDay = getNextDay(day);
        const currentDaysHours = [
            ...(formattedOpenHoursData[day] || []),
            ...regularHours,
        ];
        const nextDaysHours = [...(formattedOpenHoursData[nextDay] || [])];
        splitMidnightWindows.forEach(({ beforeMidnight, afterMidnight }) => {
            currentDaysHours.push(beforeMidnight);

            if (
                afterMidnight !== `${MIDNIGHT_START_HOUR}-${MIDNIGHT_END_HOUR}`
            ) {
                nextDaysHours.unshift(afterMidnight);
            }
        });

        const sortedCurrentDaysHours = cloneDeep(currentDaysHours).sort(
            (a, b) => a.localeCompare(b, undefined, { numeric: true }),
        );
        const sortedNextDaysHours = cloneDeep(nextDaysHours).sort((a, b) =>
            a.localeCompare(b, undefined, { numeric: true }),
        );

        return {
            ...formattedOpenHoursData,
            [day]: sortedCurrentDaysHours,
            [nextDay]: sortedNextDaysHours,
        };
    }, {} as OpenHoursData);
