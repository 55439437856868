import React from 'react';

import { useTranslation } from 'react-i18next';

import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { ErrorPlaceholder } from 'app/pages/analytics/VisibilityAnalyticsV2/components/ErrorPlaceholder/ErrorPlaceholder';
import { QueriesChartGraphContainer } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Keywords/KeywordsChart/QueriesChart/QueriesChartGraph/QueriesChartGraph.styled';
import { LoaderView } from 'app/pages/analytics/VisibilityAnalyticsV2/components/LoaderView/LoaderView';
import { NoAnalytics } from 'app/pages/analytics/VisibilityAnalyticsV2/components/NoAnalytics/NoAnalytics';
import { TrendChart } from 'app/pages/analytics/VisibilityAnalyticsV2/components/TrendChart/TrendChart';
import { useKeywordsChart } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/keywords/useKeywordsChart';

export const QueriesChartGraph = () => {
    const { t } = useTranslation();
    const QUERIES_KEY = 'queries';
    const { data, isLoading, error, refetch } = useKeywordsChart();

    const handleClick = () => {
        refetch();
    };

    const getElementToReturn = () => {
        if (isLoading) {
            return <LoaderView />;
        }
        if (error) {
            return (
                <QueriesChartGraphContainer>
                    <ErrorPlaceholder
                        message={t('error_page_placeholder_title_app')}
                    >
                        <StrikeThroughButton
                            color="primary"
                            onClick={handleClick}
                            text={t('try_agin')}
                            icon={<i className="fa-solid fa-redo" />}
                        />
                    </ErrorPlaceholder>
                </QueriesChartGraphContainer>
            );
        }
        return (
            <>
                {data ? (
                    <TrendChart
                        data={{
                            ...data,
                            key: QUERIES_KEY,
                        }}
                    />
                ) : (
                    <NoAnalytics />
                )}
            </>
        );
    };

    return getElementToReturn();
};
