import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { MESSAGING_CONVERSATION } from 'app/common/data/queryKeysConstants';

export const useGetConversation = (conversationId: number) => {
    return useQuery(
        [MESSAGING_CONVERSATION, conversationId],
        () => {
            return api.messaging.getConversation(conversationId);
        },
        { enabled: conversationId >= 0 },
    );
};
