import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import { Permissions as PermissionsSettingsV2 } from './Permissions';

export const Permissions = () => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('business_fields_title')} />
            <PermissionsSettingsV2 />
        </>
    );
};
