import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';

const seasonalityDateFormatter = (dates: Array<string>, seasonality: string): Array<string> => {
    const seasonalityMapper = {
        day: 'EEEE d MMMM',
        week: 'd MMMM',
        month: 'MMMM yyyy',
        year: 'yyyy',
    };
    return dates.map(date => format(parseISO(date), seasonalityMapper[seasonality]));
};

export default seasonalityDateFormatter;
