import { forwardRef, useImperativeHandle, useRef } from 'react';

import { Text } from '@partoohub/ui';

import {
    markupToDisplay,
    textAreaPositionToValuePosition,
} from 'app/common/services/reactMentionsUtils';

import {
    MentionTextAreaExplanation,
    StyledMention,
    StyledMentionsInput,
} from './MentionTextArea.styled';

export type TemplateChoice = {
    id: string;
    display: string;
};

type Props = {
    value?: string;
    onChange: (inputValue: string) => void;
    // Optional props
    autoFocus?: boolean; // autofocus the input (/!\ only one per page)
    className?: string; // use with caution
    idName?: string; // if you need to use an id for a getElementById
    // Props from the third-party react-mentions
    // See https://github.com/signavio/react-mentions readme.md
    onBlur?: () => void;
    trigger?: string;
    choices?: Array<TemplateChoice>;
    hasError?: boolean;
    explanation?: string;
    noBackgroundMentions?: boolean;
    noSuggestions?: boolean;
    spellCheck?: boolean;
    hasCustomAbsoluteButtons: boolean;
};

export type MentionTextAreaImperativeHandle = {
    insert: (inputValue: string, textValue: string) => void;
    addPlaceholder: (label: string, value: string) => void;
};

export default forwardRef<MentionTextAreaImperativeHandle, Props>(function (
    {
        value = undefined,
        onChange,
        idName = '',
        className = '',
        autoFocus = false,
        onBlur = () => undefined,
        trigger = '@',
        choices = [],
        hasError = false,
        explanation = undefined,
        noBackgroundMentions = false,
        noSuggestions = false,
        spellCheck = true,
        hasCustomAbsoluteButtons = false,
    },
    ref,
) {
    // if noSuggestions is true we setup a random string for the trigger so that
    // the suggestion list never appears
    const realTrigger = !noSuggestions ? trigger : 'rm4O9x6YQe';
    const shownTrigger = !noSuggestions ? trigger : '';
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(ref, () => ({
        insert: (inputValue: string, textValue: string) => {
            if (!textAreaRef?.current) return;
            const textAreaSelectionStartBackup = textAreaRef.current.selectionStart;
            const insertPosition = textAreaPositionToValuePosition(
                textAreaSelectionStartBackup,
                textValue,
                shownTrigger,
            );
            onChange(
                textValue.slice(0, insertPosition) + inputValue + textValue.slice(insertPosition),
            );
            setTimeout(() => {
                textAreaRef.current?.focus();
                const cursorPosision = textAreaSelectionStartBackup + inputValue.length;
                textAreaRef.current?.setSelectionRange(cursorPosision, cursorPosision);
            }, 0);
        },
        addPlaceholder: (label: string, pvalue: string) => {
            const { current: textArea } = textAreaRef;
            if (!textArea || value === undefined) {
                return;
            }

            const placeholderValue = `${shownTrigger}[${label}](${pvalue})`;
            const textAreaSelectionStartBackup = textArea.selectionStart;
            const placeholderPosition = textAreaPositionToValuePosition(
                textAreaSelectionStartBackup,
                value,
                shownTrigger,
            );
            onChange(
                [
                    value.slice(0, placeholderPosition),
                    placeholderValue,
                    value.slice(placeholderPosition),
                ].join(''),
            );

            setTimeout(() => {
                textArea.focus();
                textArea.selectionEnd =
                    textAreaSelectionStartBackup + markupToDisplay(placeholderValue).length;
                textArea.selectionStart = textArea.selectionEnd;
            }, 0);
        },
    }));
    return (
        <div className="flex--col flex--fill notranslate">
            <StyledMentionsInput
                id={`textarea__id__${idName}`}
                inputRef={textAreaRef}
                noSuggestions={!!noSuggestions}
                className={`mentions__textarea ${className} ${hasError ? 'mentions_danger' : ''}`}
                // @ts-ignore
                EXPERIMENTAL_cutCopyPaste
                {...(value || value === '' ? { value } : {})}
                onBlur={() => onBlur && onBlur()}
                onChange={e => onChange(e.target.value)}
                {...(autoFocus ? { autoFocus: true } : {})}
                style={{
                    '&multiLine': {
                        input: {
                            outline: 0,
                            border: 0,
                        },
                    },
                }}
                spellCheck={spellCheck}
                hasCustomAbsoluteButtons={hasCustomAbsoluteButtons}
            >
                <StyledMention
                    noBackground={!!noBackgroundMentions}
                    trigger={realTrigger}
                    data={choices}
                    displayTransform={(_id, display) => `${shownTrigger}${display}`}
                    renderSuggestion={(
                        _suggestion,
                        _search,
                        highlightedDisplay,
                        _index,
                        focused,
                    ) =>
                        noSuggestions ? null : (
                            <div
                                className={`mdc-list-item ${
                                    focused ? 'mdc-list-item--selected' : ''
                                }`}
                            >
                                {highlightedDisplay}
                            </div>
                        )
                    }
                />
            </StyledMentionsInput>
            <div
                className={
                    hasError ? 'mentions_danger__explanation' : 'mentions_normal__explanation'
                }
            >
                <MentionTextAreaExplanation>
                    <Text variant="bodySRegular" color="secondary">
                        {explanation}
                    </Text>
                </MentionTextAreaExplanation>
            </div>
        </div>
    );
});
