import { useTranslation } from 'react-i18next';

import {
    useHomepagePresenceStatsSearches,
    useHomepagePresenceStatsSearchesPrevious,
    useRetrieveTotalSearches,
} from 'app/pages/Homepage/hooks/stats/statsItems/presence/useHomepagePresenceStats';
import {
    ChipColor,
    ChipEvolution,
    StatChip,
} from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/StatChip';

const calculateRateIncrease = (from: number, to: number): number => {
    const result = Math.round(((to - from) / from) * 100);
    if (isNaN(result) || result === Infinity || result === -Infinity) {
        return 0;
    }
    return result;
};

export const StatPresenceChip = () => {
    const { t } = useTranslation();
    const { data } = useHomepagePresenceStatsSearches();
    const { data: dataPrevious } = useHomepagePresenceStatsSearchesPrevious();

    const totalSearches = useRetrieveTotalSearches(data);
    const totalSearchesDelta = useRetrieveTotalSearches(dataPrevious);

    const percentEvolution = calculateRateIncrease(totalSearchesDelta, totalSearches);

    const statChipProps = () => {
        if (percentEvolution === 0) {
            return {
                text: `${t('homepage_stats_no_evolution_months', {
                    duration: 6,
                })}`,
                chipColor: ChipColor.GREY,
                chipEvolution: ChipEvolution.NONE,
            };
        }

        if (percentEvolution > 0) {
            return {
                text: `${t('homepage_stats_presence_positive_evolution', {
                    amount: percentEvolution,
                })}`,
                chipColor: ChipColor.GREEN,
                chipEvolution: ChipEvolution.INCREASE,
            };
        }

        return {
            text: `${t('homepage_stats_presence_negative_evolution', {
                amount: percentEvolution,
            })}`,
            chipColor: ChipColor.RED,
            chipEvolution: ChipEvolution.DECREASE,
        };
    };

    return <StatChip {...statChipProps()} />;
};
