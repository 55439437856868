import { Table } from '@partoohub/ui';

import { StyledCell } from './Rankings.styled';

export const ReplyTimeRanking = () => {
    return (
        <Table
            columns={[
                {
                    label: '#',
                    width: '300px',
                },
                {
                    label: 'Établissement',
                    width: '3000px',
                },
                {
                    label: 'Avis',
                    width: '1000px',
                },
                {
                    label: '- 2 jours',
                    width: '1000px',
                },
                {
                    label: '+ 2 jours',
                    width: '1000px',
                },
                {
                    label: 'Non répondu',
                    width: '1000px',
                },
            ]}
        >
            <Table.Body>
                <Table.Row dataTrackId="" id="6">
                    <StyledCell>-</StyledCell>
                    <StyledCell>Test org</StyledCell>
                    <StyledCell>10%</StyledCell>
                    <StyledCell>30%</StyledCell>
                    <StyledCell>-</StyledCell>
                    <StyledCell>60%</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="7">
                    <StyledCell>-</StyledCell>
                    <StyledCell>Test org</StyledCell>
                    <StyledCell>-</StyledCell>
                    <StyledCell>30%</StyledCell>
                    <StyledCell>70%</StyledCell>
                    <StyledCell>-</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="8">
                    <StyledCell>-</StyledCell>
                    <StyledCell>Test org</StyledCell>
                    <StyledCell>-</StyledCell>
                    <StyledCell>35%</StyledCell>
                    <StyledCell>-</StyledCell>
                    <StyledCell>65%</StyledCell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};
