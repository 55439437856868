import { ReactNode } from 'react';

import { IconElement } from '@partoohub/ui';

import { PBoxContainer } from './PBox.styled';
import { PBoxBody } from './PBoxBody';
import { PBoxFooter } from './PBoxFooter';
import { PBoxTitle } from './PBoxTitle';

interface Props {
    title: string;
    subtitle?: string;
    icon: IconElement;
    content: ReactNode | null;
    actions?: Array<ReactNode>;
    boxLegend?: string | ReactNode;
}

export const PBox = ({
    title,
    subtitle,
    icon,
    content,
    boxLegend = '',
    actions = [],
}: Props) => (
    <PBoxContainer>
        <PBoxTitle
            title={title}
            subtitle={subtitle}
            icon={icon}
            actions={actions || []}
        />
        <PBoxBody content={content} />
        {boxLegend && <PBoxFooter boxLegend={boxLegend} />}
    </PBoxContainer>
);
