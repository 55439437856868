import styled from '@emotion/styled';
import { Button } from '@partoohub/ui';

import { COLORS_FLOW } from 'app/styles/constants/colors_flow';

export const FiltersSectionContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const BusinessButtonModal = styled(Button)`
    background-color: ${COLORS_FLOW.others.white};
    border: 1px solid ${COLORS_FLOW.shaded.blackberry.transparent};
    padding: 16px;
    > div {
        gap: 24px;
    }
    & span {
        color: ${COLORS_FLOW.others.secondary};
        font-size: 14px;
        font-weight: 600;
    }

    &:hover {
        border: 1px solid ${COLORS_FLOW.others.secondary};
        background-color: ${COLORS_FLOW.others.white};
    }
    & i {
        color: ${COLORS_FLOW.others.secondary};
    }
`;
