import { useContext, useState } from 'react';

import { DndContext, closestCorners } from '@dnd-kit/core';
import { PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';

import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ImageUploadErrorDetails } from 'app/common/designSystem/components/molecules/DropZone/helpers';
import Flexbox from 'app/styles/utils/flexbox';

import AutoCropBanner from './AutoCropBanner';
import ImageDropzone from './ImageDropzone';
import { StyledHeader } from './ImageUpload.styled';
import ImageUploadErrorBanner from './ImageUploadErrorBanner';
import NextBackButtons from '../../components/NextBackButtons/NextBackButtons';
import { ImageListType, NewPostContext } from '../../context/NewPost';
import usePlatformSelection from '../../hooks/usePlatformSelection';
import { MandatoryField } from '../TypePlatform/PostFormTypePlatform.styled';

export const MULTI_IMAGE_MAX_PHOTO_COUNT = 10;

export const ImageUpload = () => {
    const { t } = useTranslation();

    const [isRecropping, setIsRecropping] = useState(false);
    const [loadingImages, setLoadingImages] = useState(0);
    const [uploadImageErrors, setUploadImageErrors] = useState<ImageUploadErrorDetails[]>([]);

    const { formFields, setNextStep, setPreviousStep, updateField } = useContext(NewPostContext);
    const postImages = formFields.postImage;

    const { hasOnlyGoogle, containsInstagram } = usePlatformSelection();

    const canAcceptMultiImages = !hasOnlyGoogle;
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // Require pointer to move by 5 pixels before activating draggable
                // Allows nested onClicks/buttons/interactions to be accessed
                distance: 5,
            },
        }),
    );

    const updateFormFiles = (files: ImageListType) => {
        updateField({
            field: 'postImage',
            value: files,
        });
    };

    const handleUploadImageErrorsUpdate = (errors: ImageUploadErrorDetails[]) => {
        setUploadImageErrors(errors);
    };

    return (
        <DndContext
            sensors={sensors}
            onDragEnd={event => {
                const { active, over } = event;
                if (!over) return;

                if (active.id !== over.id) {
                    const oldIndex = postImages.findIndex(
                        postImage => postImage.data_url === active.id,
                    );
                    const newIndex = postImages.findIndex(
                        postImage => postImage.data_url === over.id,
                    );
                    updateFormFiles(arrayMove(postImages, oldIndex, newIndex));
                }
            }}
            modifiers={[restrictToParentElement]}
            collisionDetection={closestCorners}
        >
            <AutoCropBanner postImages={postImages} />
            <StyledHeader>
                <Flexbox>
                    <Text variant="heading6">
                        {canAcceptMultiImages ? t('post_multi_photo_title') : t('post_photo_title')}
                    </Text>
                    {containsInstagram && <MandatoryField>*</MandatoryField>}
                </Flexbox>
                {canAcceptMultiImages && (
                    <Text color="secondary">
                        {t('post_photo_max_photos__count', {
                            count: MULTI_IMAGE_MAX_PHOTO_COUNT,
                        })}
                    </Text>
                )}
            </StyledHeader>

            {uploadImageErrors.length > 0 && (
                <ImageUploadErrorBanner uploadImageErrors={uploadImageErrors} />
            )}
            <ImageDropzone
                updateFormFiles={updateFormFiles}
                maxPhotoCount={canAcceptMultiImages ? MULTI_IMAGE_MAX_PHOTO_COUNT : 1}
                isRecropping={isRecropping}
                setIsRecropping={setIsRecropping}
                loadingImages={loadingImages}
                setLoadingImages={setLoadingImages}
                onUploadImageErrorsUpdate={handleUploadImageErrorsUpdate}
            />

            <NextBackButtons
                isLoading={isRecropping || !!loadingImages}
                disabled={
                    isRecropping || !!loadingImages || (containsInstagram && !postImages.length)
                }
                onNext={setNextStep}
                onPrevious={() => {
                    setPreviousStep?.();
                }}
                nextButtonTextKey="next"
            />
        </DndContext>
    );
};
