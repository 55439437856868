import styled from '@emotion/styled';

import { Stack } from '@partoohub/ui';

export const ConversationStarterItemsContainer = styled(Stack)``;

export const ConversationStarterItemsHeader = styled.div``;

export const ConversationStarterItemsList = styled.div`
    display: flex;
    flex-direction: column;
`;
