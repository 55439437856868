import { useCallback, useEffect, useMemo, useState } from 'react';

import { IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Link } from 'react-router-dom';

import ReviewTag from 'app/api/v2/api_calls/reviewTagApiCalls';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import LoadingSpinner from 'app/common/components/loaders/LoadingSpinner';
import { PBox } from 'app/common/components/PBox/components/PBox';
import IS_IFRAME from 'app/common/data/iframe';
import { REVIEW_TAG_STATUS } from 'app/common/data/queryKeysConstants';
import HorizontalScroller from 'app/common/designSystem/components/molecules/HorizontalScroller/HorizontalScroller';
import { useIsMobile } from 'app/common/services/screenDimensions';
import { STATIC_BASE_URL } from 'app/config';
import { Select } from 'app/pages/analytics/components/Select/Select';
import TagStatCard from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/TagStatSection/TagStatCard';
import {
    TagStatElement,
    TagStatSectionWrapper,
} from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/TagStatSection/TagStatSection.styled';

import { CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH } from 'app/routing/routeIds';
import queryClient from 'app/states/queryClient';
import { API_NOT_TREATED_REVIEWS, GOOGLE, TREATED_REVIEWS } from 'app/states/reviews/filters';

export default function TagStatSection() {
    const { t } = useTranslation();
    const [selectedBucket, setSelectedBucket] = useState<string>('-review_count');
    const isMobile = useIsMobile();
    const filters = useBusinessModalFilters();
    const options = useMemo(() => {
        return [
            {
                value: '-review_count',
                label: t('review_analytics_most_used'),
            },
            {
                value: '-review_avg_rating',
                label: t('review_analytics_highest_average_score'),
            },
            {
                value: 'review_avg_rating',
                label: t('review_analytics_lowest_average_score'),
            },
        ];
    }, [t]);
    const { data: stats, isFetching } = useQuery([REVIEW_TAG_STATUS, filters], () => {
        // @ts-ignore
        return ReviewTag.status({
            ...filters,
            state__in: [TREATED_REVIEWS, API_NOT_TREATED_REVIEWS],
            partner: GOOGLE,
        });
    });

    const handleClick = useCallback(
        (value: string) => {
            setSelectedBucket(value);
        },
        [setSelectedBucket],
    );

    // API request is costly, so we are doing the sorting on the frontend
    const tagStats = useMemo(() => {
        if (stats && stats.length) {
            const s = [...stats];
            s.sort((a, b) => {
                switch (selectedBucket) {
                    case '-review_count':
                        return b.review_count - a.review_count;
                    case '-review_avg_rating':
                        return b.review_avg_rating - a.review_avg_rating;
                    case 'review_avg_rating':
                        return a.review_avg_rating - b.review_avg_rating;
                    default:
                        return 0;
                }
            });

            return s;
        } else {
            return [];
        }
    }, [stats, selectedBucket]);

    const content = useMemo(() => {
        if (isFetching) {
            return (
                <div className="flex--col flex--justify_center">
                    <LoadingSpinner fontSize="48px" />
                </div>
            );
        }

        if (tagStats.length === 0) {
            return (
                <TagStatSectionWrapper className="tag-stat-section-no-tag">
                    <TagStatElement>
                        <p>
                            <img
                                src={STATIC_BASE_URL + '/images/app/app_review/no_tag.png'}
                                alt="no tag"
                            />
                        </p>
                        <p>
                            <b>{t('review_analytics_no_tags_title')}</b>
                            <br />
                            <b className="color--secondary">
                                {t('review_analytics_no_tags_message')}
                            </b>
                        </p>
                        {!IS_IFRAME && (
                            <p>
                                <Link
                                    data-track="review_analytics__start_tag"
                                    data-intercom-target="review_analytics__start_tag"
                                    className="strike_through_button strike_through_button--primary"
                                    to={CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH}
                                >
                                    {t('review_analytics_no_tags_cta')}
                                    &nbsp;
                                    <i className="fa fa-arrow-right"></i>
                                </Link>
                            </p>
                        )}
                    </TagStatElement>
                </TagStatSectionWrapper>
            );
        }

        return (
            <HorizontalScroller scrollStep={185}>
                <TagStatSectionWrapper>
                    {tagStats.map(s => {
                        return <TagStatCard key={s.interaction_tag.id} tagStat={s} />;
                    })}
                </TagStatSectionWrapper>
            </HorizontalScroller>
        );
    }, [isFetching, tagStats]);

    useEffect(() => {
        return () => {
            queryClient.invalidateQueries([REVIEW_TAG_STATUS]);
        };
    }, []);

    return (
        <PBox
            title={`${t('review_analytics_score_by_tags')} ${
                tagStats.length ? `(${tagStats.length})` : ''
            }`}
            subtitle={t('in_total')}
            icon={['fa-tags', IconPrefix.SOLID]}
            actions={[
                !isMobile && !isFetching && tagStats.length > 0 && (
                    <Select
                        label={`${t('review_analytics_sort_by')} : `}
                        handleClick={handleClick}
                        options={options}
                        selectedValue={selectedBucket}
                        key="period_span"
                    />
                ),
            ]}
            content={content}
        />
    );
}
