import React from 'react';

import { Button } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { V2FormattedLocationData } from 'app/api/types/account';
import PartnerIcon from 'app/businessConnection/components/common/PartnerIcon';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import {
    Card,
    LocationAddress,
    LocationDetailsContainer,
    LocationName,
    PartnerIconContainer,
    VerificationButtonContainer,
    VerificationStatusContainer,
    VerificationStatusIcon,
    VerificationStatusText,
} from './VerificationCard.styled';

type Props = {
    location: V2FormattedLocationData;
    onClick: () => void;
};

const VerificationCard: React.FC<Props> = ({ location, onClick }) => {
    const { t } = useTranslation();

    const status = location.googleVerifications?.status || 'unverified';
    const statusIconTextButton = {
        unverified: {
            icon: 'fa-solid fa-shield-exclamation',
            text: 'google_verification_location_status_unverified',
            button: 'google_verification_btn_get_verified',
        },
        has_duplicate: {
            icon: 'fa-solid fa-shield-exclamation',
            text: 'google_verification_location_status_has_duplicate',
            button: 'google_verification_btn_get_verified',
        },
        pending: {
            icon: 'fa-solid fa-key',
            text: 'google_verification_location_status_pending',
            button: 'google_verification_btn_enter_code',
        },
    };

    const buttonTooltip = !location.addressDetails
        ? t('google_verification__location_with_no_address')
        : undefined;
    return (
        <Card>
            <PartnerIconContainer>{PartnerIcon('google')}</PartnerIconContainer>
            <LocationDetailsContainer>
                <LocationName>{location.locationName}</LocationName>
                <LocationAddress>
                    {location.addressDetails && `${location.addressDetails}`}
                </LocationAddress>
                <LocationAddress>
                    {location.postalCode && `${location.postalCode}`}{' '}
                    {location.locality && `${location.locality}`}
                </LocationAddress>
            </LocationDetailsContainer>
            <VerificationStatusContainer>
                <VerificationStatusIcon
                    className={statusIconTextButton[status].icon}
                />
                <VerificationStatusText>
                    {t(statusIconTextButton[status].text)}
                </VerificationStatusText>
            </VerificationStatusContainer>
            <VerificationButtonContainer>
                <TooltipWrapper text={buttonTooltip} position="bottom-start">
                    <div>
                        <Button
                            dataTrackId="google_verification__button"
                            onClick={() => onClick()}
                            disabled={!location.addressDetails}
                            full
                            shape="cube"
                            variant="primary"
                        >
                            {t(statusIconTextButton[status].button)}
                        </Button>
                    </div>
                </TooltipWrapper>
            </VerificationButtonContainer>
        </Card>
    );
};

export default VerificationCard;
