import styled from '@emotion/styled';

export const StyledBusinessSelectStep = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    @media (max-width: 769px) {
        flex-direction: column;
    }

    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    padding: 16px 24px;
    margin-top: 16px;
`;

export const StyledBusinessSelectStepButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    @media (max-width: 769px) {
        flex-direction: column;
    }
`;
