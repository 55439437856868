import { useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditForm from 'app/businessEditV2/components/EditForm';
import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import ControlledCategorySelectSplit from 'app/businessEditV2/hookControllerWrapper/ControlledCategorySelectSplit';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessCategories from 'app/businessEditV2/hooks/business/useBusinessCategories';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import useCategoriesSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useCategoriesSectionColor';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useFormFieldFormatter from 'app/businessEditV2/hooks/publisherErrors/useFormFieldFormatter';
import { BusinessPage, trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames, getNumberOfAdditionalCategories } from 'app/businessEditV2/utils/utils';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

type Props = {
    closeModal: () => void;
    useDirtyUpdate: (boolean) => void;
};

const CategoriesForm = ({ closeModal, useDirtyUpdate }: Props) => {
    const { t } = useTranslation();
    const colorState = useCategoriesSectionColor();
    const { data: business } = useBusiness();
    const { data: businessCategories, isLoading } = useBusinessCategories();

    const setFormValue = () => ({
        categories: businessCategories,
    });

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        watch,
        reset,
    } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    useDirtyUpdate(isDirty);

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const businessUpdate = useBusinessUpdate(() => {
        businessUpdateCompletionRate.mutate();
        closeModal();
    });

    // @ts-ignore
    const errors = businessUpdate.error?.response?.data?.errors?.json;

    const onSubmit = payload => {
        businessUpdate.mutate(payload);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_CATEGORIES_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
        trackSaveBusinessEditSection(
            SectionNames.CATEGORIES,
            colorState,
            getNumberOfAdditionalCategories(payload.categories),
        );
    };

    // Permission
    const businessIsOpen = !useIsBusinessClosed();
    const categoriesPermission = useFieldMetadata('infos', 'categories')?.enabled && businessIsOpen;

    const formatPayload = payload => ({
        ...(categoriesPermission && {
            categories: payload?.categories.map(category => category.value),
        }),
    });

    const submitForm = () => {
        handleSubmit(payload => onSubmit(formatPayload(payload)))();
    };

    return (
        <>
            <EditForm
                title={t('categories')}
                description={t('business_edit_categories_description')}
                colorState={colorState}
            >
                <ControlledCategorySelectSplit
                    name="categories"
                    control={control}
                    hideExplanation
                    country={business?.country}
                    page={BusinessPage.EDIT}
                    {...useFormFieldFormatter('infos', 'categories', watch, formatPayload, errors)}
                />
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={businessUpdate.isLoading}
                    hasError={!!businessUpdate.error}
                    onClick={submitForm}
                    dataTrackId="visibility_location__form_categories__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default CategoriesForm;
