import { useMemo } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { ReviewBoosterPage } from 'app/pages/conversations/ReviewBooster';
import { ReviewListPage } from 'app/pages/conversations/ReviewList';
import {
    CEPages,
    useCanAccessCustomerExperiencePage,
} from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import {
    CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH,
    CUSTOMER_EXPERIENCE_SEND_INVITE_PATH,
    NOT_FOUND_PATH,
    REVIEWS_PATH_TOKEN,
    SEND_INVITE_PATH_TOKEN,
} from 'app/routing/routeIds';

export const CustomerExperienceRoutes = () => {
    const { data: me } = useMe();
    const meRole = me ? me.role : null;

    const { canAccessCustomerExperiencePage } = useCanAccessCustomerExperiencePage();

    const canAccessToClientReviews = canAccessCustomerExperiencePage[CEPages.ClientReviews];
    const canAccessToRB = canAccessCustomerExperiencePage[CEPages.RB];
    const defaultCustomerExperiencePath = useMemo(() => {
        if (canAccessToClientReviews) {
            return CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH;
        }

        if (canAccessToRB) {
            return CUSTOMER_EXPERIENCE_SEND_INVITE_PATH;
        }

        return NOT_FOUND_PATH;
    }, [canAccessCustomerExperiencePage]);

    const routes = useRoutes([
        {
            index: true,
            element: <Navigate replace to={defaultCustomerExperiencePath} />,
        },
        ...(canAccessToClientReviews
            ? [
                  {
                      path: REVIEWS_PATH_TOKEN,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <ReviewListPage />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
        ...(canAccessToRB
            ? [
                  {
                      path: SEND_INVITE_PATH_TOKEN,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <ReviewBoosterPage />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
        {
            path: '*',
            element: <Navigate replace to={NOT_FOUND_PATH} />,
        },
    ]);
    return routes;
};
