import { GraphTypes } from './graphTypes';

export const buildOptionsForGraphic = type => {
    switch (type) {
        case GraphTypes.BAR: {
            return verticalGraphicOptions();
        }
        case GraphTypes.DOUGHNUT: {
            return doughnutGraphicOptions();
        }
        case GraphTypes.LINE: {
            return lineGraphicOptions();
        }
        default: {
            return [];
        }
    }
};
export const verticalGraphicOptions = (stacked = true) => {
    const optionsGraphic = {
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 13,
                    padding: 15,
                    fontSize: 12,
                },
                onHover: event => {
                    event.native.target.style.cursor = 'pointer';
                },
                onLeave: event => {
                    event.native.target.style.cursor = 'default';
                },
            },
        },
        scales: {
            x: {
                stacked: stacked,
                grid: {
                    display: false,
                    drawBorder: true,
                },
            },

            y: {
                stacked: stacked,
                beginAtZero: true,
                min: 0,
                ticks: {
                    callback(value) {
                        if (value % 1 === 0) return value;
                        return null;
                    },
                },
            },
        },
        hover: {
            onHover: event => {
                event.native.target.style.cursor = 'auto';
            },
        },
    };

    return optionsGraphic;
};

export const doughnutGraphicOptions = () => {
    const optionsGraphic = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        animation: {
            duration: 200,
            easing: 'linear',
        },
        aspectRatio: 1,
        maintainAspectRatio: false,
    };
    return optionsGraphic;
};

export const lineGraphicOptions = (max = 5.1) => {
    const optionsGraphic = {
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    drawBorder: true,
                },
            },
            y: {
                beginAtZero: true,
                stepSize: 1,
                padding: 10,
                ticks: {
                    callback(value) {
                        if (value % 1 === 0) return value;
                        return null;
                    },
                },
                max: max,
                min: 0,
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 13,
                    padding: 15,
                    fontSize: 12,
                },
                onHover: event => {
                    event.native.target.style.cursor = 'pointer';
                },
                onLeave: event => {
                    event.native.target.style.cursor = 'default';
                },
            },
        },
        aspectRatio: 1,
        maintainAspectRatio: false,
        hover: {
            onHover(e) {
                e.target.style.cursor = 'auto';
            },
        },
    };
    return optionsGraphic;
};
