import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const PostImageInfoContainer = styled.div`
    margin: 84px 42px;
`;

export const PostImageInfoHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-wrap: anywhere;
`;

export const PostImageInfoContentContainer = styled.div`
    background-color: white;
    border: 1px solid ${greyColorObject.initial};
    margin-top: 24px;
    padding: 16px;
    border-radius: 8px;
`;

export const PostImageInfoContentTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const PostImageInfoCriteriaListContainer = styled.div`
    margin: 0 16px 8px 16px;
    display: flex;
    flex-flow: row wrap;
`;

export const PostMultiPhotoWarningContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;
