import { useTranslation } from 'react-i18next';

import useMe from 'app/common/hooks/queries/useMeUncamel';

import {
    HomepageHeaderContainer,
    HomepageHeaderDescription,
    HomepageHeaderTitle,
} from './HomepageHeader.styled';

export const HomepageHeader = () => {
    const { t } = useTranslation();

    const { data: me } = useMe();

    return (
        <HomepageHeaderContainer>
            <HomepageHeaderTitle variant="heading3">
                {me?.first_name
                    ? t('homepage_greeting_with_name', {
                          name: me?.first_name,
                      })
                    : t('homepage_greeting')}
            </HomepageHeaderTitle>
            <HomepageHeaderDescription variant="bodyLRegular" color="secondary">
                {t('homepage_header_description')}
            </HomepageHeaderDescription>
        </HomepageHeaderContainer>
    );
};
