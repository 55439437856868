import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import { DiffusionListModal } from 'app/pages/visibility/Diffusion/DiffusionListModal';

export const DiffusionPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('listing')} />
            <DiffusionListModal />
        </>
    );
};
