import { Outlet, useRoutes } from 'react-router-dom';

import { useAppDataContext } from 'app/common/contexts/appData';
import { ADMIN, PROVIDER } from 'app/common/data/roles';
import { MessagingPageHeader } from 'app/reviewManagement/messaging/pageHeader/MessagePageHeader';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import { MESSAGING_PATH_TOKEN } from 'app/routing/routeIds';

const ConversationsHeaderRoutes = () => {
    const { me } = useAppDataContext();
    const meRole = me ? me.role : null;

    const routes = [
        {
            path: MESSAGING_PATH_TOKEN,
            element: (
                <ProtectedRoute userRole={meRole} excludeRoles={[ADMIN, PROVIDER]}>
                    <MessagingPageHeader />
                </ProtectedRoute>
            ),
        },
    ];

    return useRoutes(routes);
};

export const ConversationsHeader = () => {
    return (
        <>
            <ConversationsHeaderRoutes />
            <Outlet />
        </>
    );
};
