import { useController } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { PlaceActionLinkUrlsError } from 'app/api/types/placeActionLinks';

import { PlaceActionLinks } from './PlaceActionLinks';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    errors?: Record<string, PlaceActionLinkUrlsError>;
    disabled?: boolean;
};

const ControlledPlaceActionLinks = ({ control, name, errors, disabled }: ControlledComponent) => {
    const {
        field: { value, onChange },
    } = useController({ control, name });

    return (
        <PlaceActionLinks value={value} onChange={onChange} errors={errors} disabled={disabled} />
    );
};

export default ControlledPlaceActionLinks;
