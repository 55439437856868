import { FontAwesomeIconsPartooUsed, IconPrefix, Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { SectionWithIcon } from 'app/common/designSystem/components/atoms/SectionWithIcon/SectionWithIcon';

import { ModalContent, ModalSubtitle, ModalTitle } from './ExportDataModal.styled';
import { InstructionModalContent } from './InstructionModal.styled';

interface Props {
    show: boolean;
    handleClose: () => void;
}

export const InstructionModal = ({ show, handleClose }: Props) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={show} closeModal={handleClose}>
            <ModalContent>
                <ModalTitle>{t('bulk_update_export_instructions_title')}</ModalTitle>
                <ModalSubtitle>{t('bulk_update_export_instructions_subtitle')}</ModalSubtitle>
                <InstructionModalContent>
                    <SectionWithIcon
                        iconName={[FontAwesomeIconsPartooUsed.faAlarmClock, IconPrefix.SOLID]}
                        title={t('bulk_category_hours')}
                        items={[
                            ['open_hours', t('business_open_hours')],
                            ['specific_hours', t('business_specific_hours')],
                        ]}
                    />
                    <SectionWithIcon
                        iconName={[FontAwesomeIconsPartooUsed.faTag, IconPrefix.SOLID]}
                        title={t('categories')}
                        items={[
                            ['main_category', t('main_category')],
                            ['additional_categories', t('additional_categories')],
                        ]}
                    />
                    <SectionWithIcon
                        iconName={[FontAwesomeIconsPartooUsed.faPhone, IconPrefix.SOLID]}
                        title={t('contact')}
                        items={[
                            ['phone', t('phone')],
                            ['email', t('business_email')],
                        ]}
                    />
                    <SectionWithIcon
                        iconName={[FontAwesomeIconsPartooUsed.faBarsFilter, IconPrefix.SOLID]}
                        title={t('bulk_category_description')}
                        items={[
                            ['description_short', t('description_short')],
                            ['description_long', t('description_long')],
                        ]}
                    />
                    <SectionWithIcon
                        iconName={[FontAwesomeIconsPartooUsed.faLink, IconPrefix.SOLID]}
                        title={t('urls')}
                        items={[
                            ['website_url', t('website_url')],
                            ['facebook_url', t('facebook_url')],
                            ['twitter_url', t('twitter_url')],
                        ]}
                    />
                </InstructionModalContent>
            </ModalContent>
        </Modal>
    );
};
