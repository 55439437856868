import { Stack } from '@partoohub/ui';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import businessCreationErrorsFormatter from 'app/api/formatters/response/businessCreationErrorsFormatter';
import ControlledCategorySelectSplit from 'app/businessEditV2/hookControllerWrapper/ControlledCategorySelectSplit';
import ControlledOpeningDate from 'app/businessEditV2/hookControllerWrapper/ControlledOpeningDate';

import { ControlledTextInput } from 'app/businessEditV2/hookControllerWrapper/ControlledTextInput';
import Section from 'app/businessEditV2/oldFromEditV1/Section';
import { BusinessPage } from 'app/businessEditV2/utils/tracking';
import { handlePhoneValue } from 'app/businessEditV2/utils/utils';
import { FieldRowContainer } from 'app/pages/visibility/BusinessCreate/sections/BusinessCreateContent/BusinessCreateContent.styled';
import { BusinessCreateFormValues } from 'app/pages/visibility/BusinessCreate/utils/businessCreateForm';

type Props = {
    form: UseFormReturn<BusinessCreateFormValues>;
    errors?: any;
};

const MainInfoSection = ({ form, errors }: Props) => {
    const { t } = useTranslation();
    const errorObject = errors ? businessCreationErrorsFormatter(errors) : {};
    const country = form.watch('country')?.value;

    return (
        <Section title={t('main_infos_section')} description={t('main_infos_section_description')}>
            <Stack gap="24px">
                <ControlledTextInput
                    name="name"
                    control={form.control}
                    label={t('business_name')}
                    dataTrackId="visibility_location__form_address__business_name"
                    required
                    hasOldTextFieldHeight
                />

                <FieldRowContainer direction="row">
                    <ControlledTextInput
                        name="code"
                        control={form.control}
                        label={t('store_code')}
                        dataTrackId="visibility_location__form_address__business_code"
                        notice={errorObject?.code ? t(errorObject?.code) : t('store_code_empty')}
                        error={!!errorObject?.code}
                        hasOldTextFieldHeight
                    />

                    <ControlledTextInput
                        name="phoneNumber"
                        control={form.control}
                        onChangeValue={handlePhoneValue}
                        label={t('phone')}
                        required
                        notice={
                            errorObject?.phoneNumber ? t('phone_invalid') : t('phone_explanation')
                        }
                        dataTrackId="visibility_location__form_contact__phone"
                        hasOldTextFieldHeight
                    />
                </FieldRowContainer>
                <ControlledCategorySelectSplit
                    name="categories"
                    control={form.control}
                    country={country}
                    isMandatory
                    page={BusinessPage.CREATION}
                />

                <ControlledOpeningDate
                    name={'openingDate'}
                    control={form.control}
                    errorMessage={errorObject?.openingDate}
                />
            </Stack>
        </Section>
    );
};

export default MainInfoSection;
