import PageHeader from 'app/common/designSystem/components/molecules/PageHeader';
import { Export } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Export/Export';

export const DesktopHeader = () => {
    return (
        <PageHeader
            title={'sidebar_visibility'}
            pageButtons={[<Export key="--export-analytics-button-key--" />]}
        />
    );
};
