import { ReviewObjectType } from 'app/api/types/review';
import { useCanShowClientReviewCards } from 'app/pages/customerExperience/useCanShowClientReviewCards';
import {
    ReviewFiltersKeys,
    ReviewFiltersType,
    useGetReviewsFilters,
} from 'app/reviewManagement/reviewList/hooks/useGetReviewsFilters';
import { DELETED_REVIEWS } from 'app/states/reviews/filters';

type ActiveQueries = {
    activeQueries: Record<ReviewObjectType, boolean>;
    enabled: boolean;
};

const areFiltersApplicableToFeedback = (reviewsFilters: ReviewFiltersType) => {
    const allowedFilters: Set<ReviewFiltersKeys> = new Set([
        'dateFrom',
        'dateTo',
        'search',
        'rating',
        'status',
        'reviewObjectType',
    ]);

    const reviewObjectTypeFilter = reviewsFilters.reviewObjectType.length
        ? reviewsFilters.reviewObjectType.split(',')
        : [];

    return [
        // Check that every selected filter can be applied to feedbacks
        Object.entries(reviewsFilters).every(([filter, value]: [ReviewFiltersKeys, string]) => {
            return !value || allowedFilters.has(filter);
        }),

        // Check that no review object type filter is selected
        // Or that the review object type filter contains feedbacks
        !reviewObjectTypeFilter.length ||
            reviewObjectTypeFilter.includes(ReviewObjectType.FEEDBACK_RESULT),

        // Check that the selected status filter is not DELETED_REVIEWS
        // (since it does not apply to feedbacks)
        reviewsFilters.status !== DELETED_REVIEWS,
    ].every(Boolean);
};

const areFiltersApplicableToReview = (reviewsFilters: ReviewFiltersType) => {
    const reviewObjectTypeFilter = reviewsFilters.reviewObjectType.length
        ? reviewsFilters.reviewObjectType.split(',')
        : [];

    return (
        !reviewObjectTypeFilter.length || reviewObjectTypeFilter.includes(ReviewObjectType.REVIEW)
    );
};

export const useActiveQueries = (): ActiveQueries => {
    const reviewsFilters = useGetReviewsFilters();
    const {
        canShowClientReviewCards: {
            [ReviewObjectType.REVIEW]: showReviewCards,
            [ReviewObjectType.FEEDBACK_RESULT]: showFeedbackResultCards,
        },
        isLoading,
    } = useCanShowClientReviewCards();

    return {
        activeQueries: {
            [ReviewObjectType.REVIEW]:
                Boolean(showReviewCards) && areFiltersApplicableToReview(reviewsFilters),
            [ReviewObjectType.FEEDBACK_RESULT]:
                Boolean(showFeedbackResultCards) && areFiltersApplicableToFeedback(reviewsFilters),
        },
        enabled: !isLoading,
    };
};
