import { useQuery } from 'react-query';

import { ServicesGetErrorType } from 'app/api/types/services';
import api from 'app/api/v2/api_calls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import useIsServicesAvailable from 'app/businessEditV2/hooks/services/useIsServicesAvailable';
import { BUSINESS, GET_SERVICES } from 'app/common/data/queryKeysConstants';

export default function useServices() {
    const businessId = useGetBusinessId();
    const { data } = useIsServicesAvailable();

    return useQuery(
        [BUSINESS, GET_SERVICES, { businessId }],
        () => api.services.getServices(businessId),
        {
            enabled: !!(businessId && data),
            onError: (error: ServicesGetErrorType): ServicesGetErrorType =>
                error,
            retry: false,
            retryOnMount: false,
        },
    );
}
