import { PropsWithChildren } from 'react';

import { Text } from '@partoohub/ui';

import { StyledStep } from './Step.styled';

interface Props {
    title: string;
    subtitle: string;
}

export const Step = ({ title, subtitle, children }: PropsWithChildren<Props>) => {
    return (
        <StyledStep>
            <Text variant="heading6">{title}</Text>
            <Text color={'secondary'}>{subtitle}</Text>
            {children}
        </StyledStep>
    );
};
