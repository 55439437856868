import styled from '@emotion/styled';

export const SemiCircleProgressBar = styled.div`
    position: relative;
    width: 116px;
    height: 80px;

    svg {
        position: absolute;
        top: -10px;
        width: 116px;
        height: 80px;
        fill: none;
        stroke-width: 14;
        stroke-linecap: round;
    }
`;
