import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

// Information modals content
export const CustomFieldsModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    text-align: center;
    max-height: calc(100vh - 120px);
    overflow: auto;
`;

export const CustomFieldsModalContentTop = styled.div`
    padding: 40px 32px 0 32px;
    margin-bottom: 32px;
`;

export const CustomFieldsModalTitle = styled(Text)`
    margin-bottom: 8px;
`;

export const CustomFieldsModalDescription = styled(Text)`
    margin-bottom: 32px;
`;

export const CustomFieldsJsonEditViewSwitch = styled.div`
    margin-bottom: 24px;
`;

// Warning modals with cancel button
export const CustomFieldsWarningModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 524px;
`;

export const CustomFieldsWarningModalIconTop = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: -26px;
`;

export const CustomFieldsWarningModalIconTopImage = styled.img`
    position: absolute;
    transform: translateY(-50%);
`;

export const CustomFieldsWarningModalIconTopDescription = styled(Text)`
    margin-top: 52px;
    text-align: center;
    text-transform: uppercase;
    white-space: pre-line;
    font-weight: bold;
`;

export const CustomFieldsWarningModalContentTop = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 26px 40px 0 40px;
`;

export const CustomFieldsWarningModalTitle = styled(Text)`
    margin-top: 16px;
    text-align: center;
    word-break: break-word;
`;

export const CustomFieldsWarningModalDescription = styled(Text)`
    margin-top: 8px;
    margin-bottom: 26px;
    text-align: center;
    white-space: pre-line;
`;

export const CustomFieldsWarningModalWarningDescription = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
`;

export const CustomFieldsWarningModalWarningDescriptionIcon = styled.img`
    display: flex;
    width: 50px;
    height: 50px;
`;

export const CustomFieldsWarningModalWarningDescriptionText = styled(Text)`
    display: flex;
    flex: 1;
    margin-left: 16px;
`;

export const CustomFieldsWarningModalTopDescription = styled(Text)`
    margin-top: 8px;
    margin-bottom: 26px;
    text-align: center;
    white-space: pre-line;
`;

export const CustomFieldsWarningModalCheckbox = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0 8px 0;
`;

export const ModalUndo = styled.div`
    display: flex;
    justify-content: center;
    margin: 8px 0;
`;
