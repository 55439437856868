import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

export const CardContainer = styled.div`
    border: 1px solid ${greyColorObject.initial};
    border-radius: 8px;
    padding: 4px 16px;
    background-color: ${HEX_COLORS.white};
`;

export const CardTitle = styled.h1`
    font-size: 14px;
    font-weight: bold;
    margin: 16px 0;
    display: flex;
    align-items: center;
`;

// todo remove display flex this to it's own component
export const CardContent = styled.div`
    margin: 24px 8px;
    display: flex;
    justify-content: space-around;
`;

export const ContentColumn = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const ContentColumnIcon = styled.div`
    color: ${({ theme }) => theme.colors.primary.initial};
    display: flex;
    align-items: center;
    margin-right: 16px;
    > i {
        font-size: 32px;
    }
`;

export const ContentColumnText = styled.div`
    flex-direction: column;
    h5 {
        margin: 0;
        color: ${({ theme }) => theme.colors.secondary.initial};
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1px;
    }
    p {
        margin: 0;
        font-weight: bold;
    }
`;

export const ContentColumnTextDetail = styled.div`
    display: flex;
    align-items: center;
    i {
        color: ${({ theme }) => theme.colors.warning.initial};
        margin-left: 4px;
    }
`;
