import { TFunction } from 'i18next';

import { UnsupportedContent } from 'app/states/messaging';

export const getUnsupportedMessage = (
    content: UnsupportedContent,
    isConsumerMessage: boolean,
    consumerName: string,
    t: TFunction,
): string => {
    const consumerTranslations = {
        audio: t('messaging_media_type_audio_error', { user: consumerName }),
        video: t('messaging_media_type_video_error', { user: consumerName }),
        unknown: t('messaging_media_type_unknown_error', { user: consumerName }),
    };
    const userTranslations = {
        audio: t('messaging_media_type_audio_error_user'),
        video: t('messaging_media_type_video_error_user'),
        unknown: t('messaging_media_type_unknown_error_user'),
    };
    const translations: { [x: string]: string } = isConsumerMessage
        ? consumerTranslations
        : userTranslations;

    return translations[content.type];
};
