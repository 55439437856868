import { useContext } from 'react';

import {
    ALERT_TYPE,
    COVID_TYPE,
    EVENT_TYPE,
    NEWS_TYPE,
    OFFER_TYPE,
} from 'app/presence/googlePosts/variables/googlePostType';

import { NewPostContext, NewPostContextType } from '../../context/NewPost';
import AlertForm from '../../forms/Alert';
import EventForm from '../../forms/Event';
import NewsForm from '../../forms/News';
import { OfferForm } from '../../forms/Offer';

function renderForm(postType) {
    switch (postType) {
        case NEWS_TYPE:
            return <NewsForm />;

        case EVENT_TYPE:
            return <EventForm />;

        case OFFER_TYPE:
            return <OfferForm />;

        case ALERT_TYPE:
        case COVID_TYPE:
            return <AlertForm />;

        default:
            return <>No form configured for this post</>;
    }
}

const PostFormGlobalContent = () => {
    const {
        formFields: { type },
    } = useContext<NewPostContextType>(NewPostContext);

    return <div>{renderForm(type)}</div>;
};

export default PostFormGlobalContent;
