import { useState } from 'react';

import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack, Text, Tooltip } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { BusinessInfosImportType } from 'app/api/types/business';
import Clickable from 'app/common/components/buttons/Clickable';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import env from 'app/common/services/getEnvironment';

import {
    ImportFieldSection,
    ImportFieldSectionFields,
    ImportFieldSectionTop,
    StyledCheckbox,
    StyledStack,
} from './BusinessImportFieldSection.styled';
import { ImportField } from './ImportField';

interface Props {
    businessInfos: BusinessInfosImportType;
    onChange: (value: BusinessInfosImportType) => void;
    infosDisabled: BusinessInfosImportType;
    // Optional props
    businessWarning?: BusinessInfosImportType;
}

export const BusinessImportFieldSection = ({
    businessInfos,
    onChange,
    infosDisabled,
    businessWarning,
}: Props) => {
    const { t } = useTranslation();
    const [showFields, setShowFields] = useState(false);

    const toggleShowFields = () => {
        setShowFields(!showFields);
    };

    const onFieldClick = (name: string, value: boolean) => {
        onChange({ ...businessInfos, [name]: value });
    };

    const onSectionClick = (status: boolean | 'indeterminate') => {
        const result: any = {};
        Object.keys(businessInfos).forEach(key => {
            result[key] = !status && !infosDisabled[key];
        });

        onChange(result);
    };

    const checkboxSectionValues = () => {
        const filteredBusinessInfos = Object.keys(businessInfos);
        const selectedImport = filteredBusinessInfos.filter(key => businessInfos[key]);
        let indeterminateCheckboxState = 'indeterminate';

        if (selectedImport.length === 0) {
            indeterminateCheckboxState = false;
        } else if (selectedImport.length === filteredBusinessInfos.length) {
            indeterminateCheckboxState = true;
        }

        return {
            indeterminateCheckboxState,
            countText: `${selectedImport.length} / ${filteredBusinessInfos.length}`,
        };
    };

    const businessFieldsWarnings = () => {
        const result = {};

        if (businessWarning) {
            Object.keys(businessInfos).forEach(key => {
                result[key] = businessInfos[key] && businessWarning[key];
            });
        }

        return result;
    };

    const isAllInfosDisabled = (): boolean => {
        return !Object.values(infosDisabled).includes(false);
    };

    const { indeterminateCheckboxState, countText } = checkboxSectionValues();
    const businessFieldsWarningsValue = businessFieldsWarnings();
    const hasWarnings = Object.values(businessFieldsWarnings).includes(true);
    const allInfosDisabled = isAllInfosDisabled();
    return (
        <ImportFieldSection>
            <ImportFieldSectionTop>
                <Stack justifyContent="center" direction="column" style={{ flex: 1 }}>
                    <Clickable style={{}} onClick={toggleShowFields}>
                        <Stack direction="row" alignItems="center">
                            <Text variant="heading6" disabled={allInfosDisabled}>
                                {t('business_data')}
                            </Text>
                            {hasWarnings && (
                                <Tooltip text={t('data_will_be_overwritten_by_google')}>
                                    <Icon
                                        icon={[
                                            FontAwesomeIconsPartooUsed.faCircleExclamation,
                                            IconPrefix.SOLID,
                                        ]}
                                        color="warning"
                                    />
                                </Tooltip>
                            )}
                        </Stack>
                        <StyledStack
                            direction="row"
                            alignItems="center"
                            disabled={allInfosDisabled}
                        >
                            <Text variant="bodyMBold" as="span" color="secondary">
                                {t(showFields ? 'hide_details' : 'see_details')}
                            </Text>
                            <Icon
                                icon={[
                                    `fa-chevron-${showFields ? 'down' : 'right'}`,
                                    IconPrefix.SOLID,
                                ]}
                                iconSize="12px"
                                color="secondary"
                            />
                        </StyledStack>
                    </Clickable>
                </Stack>
                <Stack gap="8px" direction="row" alignItems="center">
                    <Text variant="bodyMSemibold" color="secondary" disabled={allInfosDisabled}>
                        {countText}
                    </Text>
                    <TooltipWrapper
                        text={allInfosDisabled ? t('import_disabled') : ''}
                        position="left"
                    >
                        <StyledCheckbox
                            dataTrackId="business_import_field_section__checkbox"
                            aria-label=""
                            checked={indeterminateCheckboxState}
                            disabled={allInfosDisabled}
                            onChange={() => onSectionClick(indeterminateCheckboxState)}
                        />
                    </TooltipWrapper>
                </Stack>
            </ImportFieldSectionTop>
            {showFields && (
                <ImportFieldSectionFields>
                    <ImportField
                        name={t('business_name')}
                        hasWarning={businessFieldsWarningsValue.name}
                        value={businessInfos.name}
                        onChange={value => onFieldClick('name', value)}
                        disabled={infosDisabled.name}
                    />
                    <ImportField
                        name={t('store_code')}
                        hasWarning={businessFieldsWarningsValue.code}
                        value={businessInfos.code}
                        onChange={value => onFieldClick('code', value)}
                        disabled={infosDisabled.code}
                    />
                    <ImportField
                        name={t('categories')}
                        hasWarning={businessFieldsWarningsValue.categories}
                        value={businessInfos.categories}
                        onChange={value => onFieldClick('categories', value)}
                        disabled={infosDisabled.categories}
                    />
                    <ImportField
                        name={t('business_about')}
                        hasWarning={businessFieldsWarningsValue.addressFull}
                        value={businessInfos.addressFull}
                        onChange={value => onFieldClick('addressFull', value)}
                        disabled={infosDisabled.addressFull}
                    />
                    <ImportField
                        name={t('location')}
                        hasWarning={businessFieldsWarningsValue.coordinates}
                        value={businessInfos.coordinates}
                        onChange={value => onFieldClick('coordinates', value)}
                        disabled={infosDisabled.coordinates}
                    />
                    <ImportField
                        name={t('business_open_hours')}
                        hasWarning={businessFieldsWarningsValue.openHours}
                        value={businessInfos.openHours}
                        onChange={value => onFieldClick('openHours', value)}
                        disabled={infosDisabled.openHours}
                    />
                    {!(env.isProdEnv() || env.isPreprod()) && (
                        <ImportField
                            name={t('more_hours')}
                            hasWarning={businessFieldsWarningsValue.moreHours}
                            value={businessInfos.moreHours}
                            onChange={value => onFieldClick('moreHours', value)}
                            disabled={infosDisabled.moreHours}
                        />
                    )}
                    <ImportField
                        name={t('business_specific_hours')}
                        hasWarning={businessFieldsWarningsValue.specificHours}
                        value={businessInfos.specificHours}
                        onChange={value => onFieldClick('specificHours', value)}
                        disabled={infosDisabled.specificHours}
                    />
                    <ImportField
                        name={t('business_opening_date')}
                        hasWarning={businessFieldsWarningsValue.openingDate}
                        value={businessInfos.openingDate}
                        onChange={value => onFieldClick('openingDate', value)}
                        disabled={infosDisabled.openingDate}
                    />
                    <ImportField
                        name={t('description_long')}
                        hasWarning={businessFieldsWarningsValue.description}
                        value={businessInfos.description}
                        onChange={value => onFieldClick('description', value)}
                        disabled={infosDisabled.description}
                    />
                    <ImportField
                        name={t('phone')}
                        hasWarning={businessFieldsWarningsValue.phone}
                        value={businessInfos.phone}
                        onChange={value => onFieldClick('phone', value)}
                        disabled={infosDisabled.phone}
                    />
                    <ImportField
                        name={t('website_extended')}
                        hasWarning={businessFieldsWarningsValue.website}
                        value={businessInfos.website}
                        onChange={value => onFieldClick('website', value)}
                        disabled={infosDisabled.website}
                    />
                    <ImportField
                        name={t('additional_urls')}
                        hasWarning={businessFieldsWarningsValue.additionalUrls}
                        value={businessInfos.additionalUrls}
                        onChange={value => onFieldClick('additionalUrls', value)}
                        disabled={infosDisabled.additionalUrls}
                    />
                </ImportFieldSectionFields>
            )}
        </ImportFieldSection>
    );
};
