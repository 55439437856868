import React from 'react';

const GoogleIcon = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.128 10.736v2.714h3.775a3.227 3.227 0 01-1.4 2.117l2.267 1.76c1.327-1.221 2.092-3.02 2.092-5.156 0-.497-.045-.976-.128-1.435h-6.606z"
            fill="#3E82F1"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.128 16.237c-1.827 0-3.373-1.234-3.925-2.892L5.86 15.162a7.012 7.012 0 006.268 3.865c1.894 0 3.482-.629 4.642-1.7l-2.266-1.76c-.629.421-1.432.67-2.376.67z"
            fill="#32A753"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.983 12.012c0-.462.08-.912.22-1.332L5.86 8.862a7.012 7.012 0 000 6.3l2.343-1.817c-.14-.42-.22-.87-.22-1.333z"
            fill="#F9BB00"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.128 7.788c1.03 0 1.955.354 2.681 1.049l2.013-2.012c-1.215-1.132-2.803-1.827-4.694-1.827A7.012 7.012 0 005.86 8.862l2.343 1.818c.552-1.658 2.098-2.892 3.925-2.892z"
            fill="#E74133"
        />
    </svg>
);

export default GoogleIcon;
