import { useMutation, useQueryClient } from 'react-query';

import {
    freeFormServiceUpdateQueryParams,
    servicesTypeEnum,
    structuredServiceUpdateQueryParams,
} from 'app/api/types/services';
import Services from 'app/api/v2/api_calls/servicesApiCalls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import {
    ServiceModalDataType,
    ServiceModalFormType,
} from 'app/businessEditV2/sections/ServicesSection/ServicesListForm/modals/EditServiceModal';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { GET_SERVICES } from 'app/common/data/queryKeysConstants';

export default function useEditService(
    onSuccess: () => void = () => undefined,
    onError: (ServicesGetErrorType) => void = () => undefined,
) {
    const businessId = useGetBusinessId();
    const queryClient = useQueryClient();
    return useMutation(
        (options: { data: ServiceModalDataType; formData: ServiceModalFormType }) => {
            if (options.data.service?.type === servicesTypeEnum.STRUCTURED_SERVICE_ITEM) {
                const queryParams = structuredServiceUpdateQueryParams(
                    options.data.service?.service_id,
                    options.formData.description,
                    (options?.formData?.price ? options.formData.price.toString() : '').replace(
                        ',',
                        '.',
                    ),
                );
                return Services.updateStructuredService(businessId, queryParams);
            }
            const queryParams = freeFormServiceUpdateQueryParams(
                options.data.service?.service_id!,
                options.formData.name,
                options.formData.description,
                (options?.formData?.price ? options.formData.price.toString() : '').replace(
                    ',',
                    '.',
                ),
            );
            return Services.updateFreeFormService(businessId, queryParams);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(invalidatesAny(GET_SERVICES)).then(onSuccess);
            },
            onError,
        },
    );
}
