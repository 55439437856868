import React from 'react';

import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import EditSection from 'app/businessEditV2/components/EditSection';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import UnsavedModal from 'app/businessEditV2/components/UnsavedModal';
import useDisplayAttributes from 'app/businessEditV2/hooks/editV2utils/displaySections/useDisplayAttributes';
import useIsLoadingAttributes from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingAttributes';
import useAttributesSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useAttributesSectionColor';
import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import AttributesSectionContent from 'app/businessEditV2/sections/AttributesSection/AttributesSectionContent';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import AttributesForm from './AttributesForm';

const AttributesSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const {
        showModal,
        setShowModal,
        closeSafeModal,
        showUnsavedModal,
        setShowUnsavedModal,
        useDirtyUpdate,
    } = useUnsavedModal(SectionNames.ATTRIBUTES);

    const displayAttributes = useDisplayAttributes();
    const sectionColor = useAttributesSectionColor();
    const { t } = useTranslation();

    const isLoading = useIsLoadingAttributes();

    if (isLoading) {
        return <EditSectionLoader />;
    }

    if (!displayAttributes) {
        return null;
    }

    return (
        <div
            ref={ref}
            data-track="visibility_location__section_attributes"
            data-intercom-target="visibility_location__section_attributes"
        >
            <EditSection
                title={t('attributes')}
                description={
                    <AttributesSectionContent colorState={sectionColor} />
                }
                onClick={() => setShowModal(true)}
                colorState={sectionColor}
                sectionName={SectionNames.ATTRIBUTES}
            />
            <FullScreenModal
                show={showModal}
                childrenRight={
                    <EditFormDescription
                        title={t('business_edit_title_attributes')}
                        description={t('business_edit_description_attributes')}
                        advice={t('business_edit_advice_attributes')}
                    />
                }
                childrenLeft={
                    <>
                        <AttributesForm
                            closeModal={() => setShowModal(false)}
                            useDirtyUpdate={useDirtyUpdate}
                        />
                        <UnsavedModal
                            show={showUnsavedModal}
                            cancel={() => setShowUnsavedModal(false)}
                            validate={() => setShowModal(false)}
                        />
                    </>
                }
                onHideClick={closeSafeModal}
            />
        </div>
    );
});

export default AttributesSection;
