import { UseFormWatch, useController } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { PasswordField as PasswordFieldComponent } from 'app/common/designSystem/components/atoms/PasswordField/PasswordField';
import { ControlledPasswordField } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm/hookControllerWrapper/ControlledPasswordField';
import { UpdatePasswordField } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm/PasswordSubform/PasswordSubform.styled';

import { FlexContainer, PasswordFieldsContainer } from './PasswordField.styled';

type ControlledComponent = {
    control: Control<any>;
    watch: UseFormWatch<any>;
    errors: any;
};

const PasswordField = ({ control, watch, errors }: ControlledComponent) => {
    const { t } = useTranslation();
    const {
        field: { value: oldPassword, onChange: onUpdateOldPassword },
    } = useController({ control, name: 'oldPassword' });
    const {
        field: { value: updatePassWord, onChange: onUpdatePassword },
    } = useController({ control, name: 'updatePassword' });

    return (
        <>
            <UpdatePasswordField onClick={() => onUpdatePassword(!updatePassWord)}>
                <i className="fa-solid fa-key" />
                {t(updatePassWord ? t('do_not_change_password') : t('change_password'))}
                {updatePassWord ? (
                    <i className="fas fa-angle-down" />
                ) : (
                    <i className="fas fa-angle-right" />
                )}
            </UpdatePasswordField>

            {watch('updatePassword') && (
                <PasswordFieldsContainer>
                    <PasswordFieldComponent
                        dataTrackId="updatePassword"
                        value={oldPassword}
                        onChange={onUpdateOldPassword}
                        label={t('current_password')}
                        required
                        error={!!errors?.old_password}
                        notice={errors?.old_password ? t(errors.old_password) : ''}
                    />
                    <FlexContainer>
                        <ControlledPasswordField
                            dataTrackId="password"
                            name="password"
                            watch={watch}
                            control={control}
                            required
                        />
                    </FlexContainer>
                </PasswordFieldsContainer>
            )}
        </>
    );
};

export default PasswordField;
