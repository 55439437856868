import styled from '@emotion/styled';

export const ScrollSpyClickContainer = styled.a`
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &:hover {
        cursor: pointer;
    }
`;
