import { css } from '@emotion/react';
import styled from '@emotion/styled';

// This clip path was generated from the flattened clipping mask. It is _specific_ to 40px icons.
// [! NOTE] You will need to update this clip path if you change the size of the profile picture/icon.
const CLIPPING_PATH =
    'M27.309 38.622A19.948 19.948 0 0 1 20 40C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20c0 2.58-.488 5.045-1.378 7.309A8 8 0 0 0 27.31 38.622Z';

type ProfileIconProps = {
    clipped: boolean;
};

export const ConsumerIconWithPartnerContainer = styled.div`
    position: relative;

    .partner-icon {
        position: absolute;
        bottom: 0;
        line-height: 0;
        right: 0;
    }
    .profile-icon {
        ${({ clipped }: ProfileIconProps) =>
            clipped &&
            css`
                clip-path: path('${CLIPPING_PATH}');
            `}
    }
`;
