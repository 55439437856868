import styled from '@emotion/styled';

import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import {
    RatingGridArea,
    TextGridArea,
    TitleGridArea,
} from './ReplyTemplateCard.styled';

export const StyledLoadingFlag = styled(LoadingRectangle)`
    grid-area: flag;
    width: 26px;
    height: 26px;
    border: 1px lightgray solid;
    border-radius: 50%;
    overflow: hidden;

    & > img {
        margin-left: -4px;
        height: 24px;
    }
`;

export const StyledLoadingTitle = TitleGridArea.withComponent(LoadingRectangle);
export const StyledLoadingText = TextGridArea.withComponent(LoadingRectangle);
export const StyledLoadingRating =
    RatingGridArea.withComponent(LoadingRectangle);
