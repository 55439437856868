import styled from '@emotion/styled';
import { Switch, Text } from '@partoohub/ui';

export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ToggleText = styled(Text)`
    margin: 0 16px;
`;

export const StyledSwitch = styled(Switch)`
    margin-top: 2px;
`;
