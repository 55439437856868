import styled from '@emotion/styled';
import { Bar } from 'react-chartjs-2';

export const OverrideBar = styled(Bar)`
    margin-top: 16px;
    border: 1px solid #dddfe3;
    border-radius: 4px;
    padding: 10px;
    max-height: 350px;
`;
