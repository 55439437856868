import { V2Subscriptions } from 'app/api/types';
import { AppDataContextType } from 'app/common/contexts/appData';

import { PushNotificationsWrapper } from '../../pushNotifications/pushNotificationsWrapper';

export const handleIdentifyUserOnLogin = (
    { me, organization }: AppDataContextType,
    subscriptions: V2Subscriptions,
) => {
    return new Promise(resolve => {
        if (me.shadow_user_id) return;

        const { id, first_name, last_name, email, lang, role, created_at, group_id, org_id } = me;
        const { organization_type, name, provider, last_org_api_call } = organization;
        const { subscribed_to_bm, subscribed_to_pm, subscribed_to_rb, subscribed_to_rm } =
            subscriptions;
        const createdAt = new Date(created_at * 1000).toString();

        const pushNotificationsWrapper = PushNotificationsWrapper.getInstance();
        pushNotificationsWrapper.updateProfile({
            firstName: first_name,
            lastName: last_name,
            email,
            userId: id,
            lang,
            role,
            createdAt,
            groupId: group_id ?? undefined,
            orgId: org_id,
            organizationType: organization_type,
            orgName: name,
            orgProvider: provider,
            orgPmSubscribed: subscribed_to_pm,
            orgRmSubscribed: subscribed_to_rm,
            orgRbSubscribed: subscribed_to_rb,
            orgBmSubscribed: subscribed_to_bm,
            lastOrgApiCall: last_org_api_call,
        });

        resolve('User identified on login');
    });
};
