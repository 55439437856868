import { connect } from 'react-redux';

import { V2FormattedBusinessData } from 'app/api/types/business';
import SmallBusinessCard from 'app/common/components/businessCards/SmallBusinessCard/SmallBusinessCard';
import {
    addFilteredBusinesses,
    isBusinessSelected,
} from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';

type OwnProps = {
    business: V2FormattedBusinessData;
};

const mapStateToProps = ({ filterByBusiness }, { business }: OwnProps) => ({
    selected: isBusinessSelected(filterByBusiness, business),
});

const mapDispatchToProps = (dispatch: any, { business }: OwnProps) => ({
    handleClick: (selected: boolean) =>
        dispatch(addFilteredBusinesses([business.id], selected)),
});

const mergeProps = (stateProps, dispatchProps, ownProps: OwnProps) => ({
    ...ownProps,
    ...stateProps,
    handleClick: () => dispatchProps.handleClick(!stateProps.selected),
});

const SmallBusinessCardComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(SmallBusinessCard);

export default SmallBusinessCardComponent;
