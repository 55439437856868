import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { AccountPartner } from 'app/api/types/account';
import api from 'app/api/v2/api_calls';
import { useRefreshOAuthAccountProgress, useTaskContext } from 'app/common/contexts/taskListener';

import { META_RELOGIN } from 'app/common/data/queryKeysConstants';
import {
    FACEBOOK,
    GOOGLE_MY_BUSINESS,
} from 'app/states/knowledge/reducers/businessConnection/accounts';

const PARTNER_REFRESH_CALL_MAPPING = {
    [GOOGLE_MY_BUSINESS]: api.account.refreshGMBAccounts,
    [FACEBOOK]: api.account.refreshFacebookAccounts,
};

interface UseRefreshOAuthAccountArgs {
    oauthAccountId: string;
    partner: AccountPartner;
    startRefreshAtMount?: boolean;
    timeout?: number;
    fetch_origin?: string;
}

export function useRefreshOAuthAccount({
    oauthAccountId,
    partner,
    startRefreshAtMount = true,
    timeout = 60 * 1000,
    fetch_origin,
}: UseRefreshOAuthAccountArgs) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const [startRefresh, setStartRefresh] = useState(startRefreshAtMount);

    const [isTimeout, setIsTimeout] = useState(false);
    const timerRef = useRef<string | number | NodeJS.Timeout>();

    const { oauthAccounts, tasks } = useTaskContext();
    const { downloadLocationsOAuthAccounts } = oauthAccounts;

    const oauthAccountInfo =
        oauthAccountId in downloadLocationsOAuthAccounts
            ? downloadLocationsOAuthAccounts[oauthAccountId]
            : undefined;

    const {
        isLoading,
        percentageProgress,
        accountsProgress,
        accountsTotal,
        locationsProgress,
        instagramAccountsProgress,
        errorMessage,
        setRefreshOAuthAccountProgress,
    } = useRefreshOAuthAccountProgress(oauthAccountInfo);

    const {
        data,
        mutate,
        isSuccess: isMutationSuccess,
        isError: isMutationError,
    } = useMutation(
        async () => PARTNER_REFRESH_CALL_MAPPING[partner](oauthAccountId, fetch_origin),
        {
            mutationKey: [partner, oauthAccountId],
        },
    );

    const isSuccess = isMutationSuccess && isLoading === false && accountsTotal !== undefined;

    const clearData = () => {
        setRefreshOAuthAccountProgress({
            percentageProgress: 0,
            accountsProgress: 0,
            locationsProgress: 0,
            instagramAccountsProgress: 0,
            isLoading: undefined,
            errorMessage: null,
        });
    };

    const restartTimer = () => {
        setIsTimeout(false);

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            clearData();
            setRefreshOAuthAccountProgress({
                isLoading: false,
                errorMessage: `${t('timeout_error_response')}!`,
            });

            delete downloadLocationsOAuthAccounts[oauthAccountId];

            setStartRefresh(false);
            setIsTimeout(true);
        }, timeout);
    };

    useEffect(() => {
        if (startRefresh) {
            restartTimer();
            clearData();
            mutate();

            return () => {
                clearData();
                setStartRefresh(false);

                delete downloadLocationsOAuthAccounts[oauthAccountId];
                clearTimeout(timerRef.current);
            };
        }
    }, [startRefresh]);

    useEffect(() => {
        // if we receive an event, we restart timer to avoid an error
        if (oauthAccountId in downloadLocationsOAuthAccounts) restartTimer();
    }, [tasks]);

    useEffect(() => {
        if (!isLoading) {
            delete downloadLocationsOAuthAccounts[oauthAccountId];
        }
        if (!errorMessage && isSuccess) {
            clearTimeout(timerRef.current);
            queryClient.invalidateQueries([META_RELOGIN]);
        }
    }, [isMutationSuccess, isLoading, errorMessage]);

    return {
        isSuccess,
        isLoading,
        isStarted: startRefresh,
        isError: !!errorMessage || isMutationError || isTimeout || data?.status === 'error',
        errorMessage: errorMessage,
        accountsTotal,
        percentageProgress,
        accountsProgress,
        locationsProgress,
        instagramAccountsProgress,
        setStartRefresh,
    };
}
