import styled from '@emotion/styled';

export const HolidaysSuggestionHeaderContainer = styled.div`
    display: flex;
    height: 56px;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    &:hover {
        cursor: pointer;
    }

    > i {
        margin-right: 8px;
    }
`;
