import LoadingView from 'app/common/components/LoadingView';
import NoBusiness from 'app/common/components/placeholder/NoBusiness.container';
import { NoReviewBooster } from 'app/common/components/placeholder/ReviewBooster/NoReviewBooster';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import { useReviewBoosterInitialDataLoad } from 'app/pages/conversations/ReviewBooster/hooks/useReviewBoosterInitialDataLoad';

import ReviewBoosterForm from './ReviewBoosterForm';
import { StyledReviewBooster } from './ReviewBoosterWithNewSidebar.styled';

export const ReviewBoosterWithNewSidebar = () => {
    const {
        isLoading: subscriptionsLoading,
        hasBusinesses,
        subscribedToRB,
    } = useBusinessSubscriptions();
    const { isLoading: isInitialDataLoadLoading } = useReviewBoosterInitialDataLoad();

    if (subscriptionsLoading || isInitialDataLoadLoading) {
        return <LoadingView />;
    }

    if (!hasBusinesses) {
        return <NoBusiness />;
    }

    if (!subscribedToRB) {
        return <NoReviewBooster />;
    }

    return (
        <StyledReviewBooster>
            <ReviewBoosterForm />
        </StyledReviewBooster>
    );
};
