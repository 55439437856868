import { useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { PlatFormSelectionModal } from 'app/businessConnection/components/businessConnectionHeader/headerActions/PlatformSelectionModal';

const AccountConnectionButton = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <div>
            <IconButton
                appearance="outlined"
                variant="default"
                dataTrackId="select_platform"
                icon={[FontAwesomeIconsPartooUsed.faPlus]}
                onClick={() => setShowModal(true)}
                tooltip={t('front-partner_connection-my_account-add_button')}
            />
            <Modal isOpen={showModal} closeModal={() => setShowModal(false)}>
                <PlatFormSelectionModal onHideClick={() => setShowModal(false)} />
            </Modal>
        </div>
    );
};

export default AccountConnectionButton;
