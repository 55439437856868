import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import { TodoCompletionRate } from 'app/pages/Homepage/sections/HomepageTodos/todos/TodoCompletionRate';
import { Item } from 'app/pages/Homepage/utils/typing';

export const useTodoCompletionRateItem = (): Item<SmallTodoProps> => {
    return {
        order: 4,
        isAvailable: true, // Completion rate available for all users for now
        component: TodoCompletionRate,
        key: 'todo-completion-rate',
    };
};
