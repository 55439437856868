import { useEffect } from 'react';

import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';
import { useImportOAuthAccount } from 'app/businessConnection/hooks/useImportOAuthAccount';

export const useImportPartnerConnectionOAuthAccount = (connectionLink: string) => {
    const { setOAuthAccountId, setPartnerName, displayImportModal, setDisplayImportModal } =
        usePartnerConnectionContext();
    const { startOAuthFlow, oauthAccountId, partnerName } = useImportOAuthAccount(connectionLink);

    useEffect(() => {
        if (oauthAccountId && partnerName) {
            setOAuthAccountId(oauthAccountId);
            setPartnerName(partnerName);
            setDisplayImportModal(true);
        }
    }, [oauthAccountId, partnerName]);

    return { startOAuthFlow, displayImportModal };
};
