import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const StyledTitle = styled.div`
    display: flex;
    align-items: center;

    .mdc-button {
        margin-left: 5px;
        background-color: rgba(215, 219, 223, 0.4) !important;
        color: ${({ theme }) => theme.colors.secondary.initial} !important;
        pointer-events: none;
        min-width: initial;
    }
`;

export const StyledText = styled(Text)`
    display: flex;
    align-items: center;
    text-align: end;
`;

export const StyledIcon = styled.i`
    color: #dddfe3;
    margin: 7px 6px 5px 6px !important;
    font-size: 12px;
`;
