import { Button, List } from '@partoohub/ui';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';

import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';

import { AutomaticMessagesConfigurationModal } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/AutomaticMessagesConfigurationModal';

import { useConversationStarterCreate } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarterCreate';

import { ConversationStarterCard } from './ConversationStartersCard';
import useConversationStarters from './hooks/useConversationStarters';

export const ConversationStarters = () => {
    const { t } = useTranslation();
    const { data: conversationStarters, isLoading } = useConversationStarters();
    const { search } = useLocation();
    const onConversationStarterCreationMutation = useConversationStarterCreate();
    const lastCardIndex = conversationStarters ? conversationStarters.length - 1 : -1;

    if (!!qs.parse(search).conversationStarterId) {
        return <AutomaticMessagesConfigurationModal show />;
    }

    const onClick = () => {
        onConversationStarterCreationMutation.mutate();
    };

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('conversation_starters_settings_header')}
                    description={t('conversation_starters_settings_description')}
                    actionsButton={[
                        <Button
                            key={'conversation_starters--create--button'}
                            dataTrackId={'conversation_starters--create--button'}
                            icon={['fa-plus']}
                            onClick={onClick}
                            variant="primary"
                        >
                            {t('conversation_starters_settings_button')}
                        </Button>,
                    ]}
                />
            }
        >
            <List
                columns={[
                    { label: t('title') },
                    { label: t('messages_welcome_message_title') },
                    { label: t('conversation_starters_settings_items_header') },
                    { label: t('businesses'), width: '160px' },
                ]}
                loading={isLoading}
            >
                {conversationStarters?.map((conversationStarter, index) => (
                    <ConversationStarterCard
                        key={`conversation-starter-${conversationStarter.id}`}
                        conversationStarter={conversationStarter}
                        isLastCard={lastCardIndex === index}
                    />
                ))}
            </List>
        </MenuListContentTemplate>
    );
};
