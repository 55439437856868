import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

export const getCustomColumnStyle = (size: string, margin: string) => {
    return css`
        flex-basis: ${size};
        margin: ${margin};
    `;
};

export const ApiKeyCardContainer = styled.div`
    margin: 16px 0px;
    height: 80px;
    display: flex;
    flex-direction: row;
`;

export const ApiKeyCardMarkup = styled.div<{ isActive: boolean }>`
    border-right: 0;
    border-top-left-radius: ${({ theme }) => theme.radius.large};
    border-bottom-left-radius: ${({ theme }) => theme.radius.large};
    width: 5px;
    min-width: 5px;
    background: ${({ isActive }) => (isActive ? HEX_COLORS.success : HEX_COLORS.secondary)};
`;

export const ApiKeyCardContent = styled.div`
    width: 100%;
    border: solid 1px ${({ theme }) => theme.colors.default.alpha};
    border-left: 0;
    border-top-right-radius: ${({ theme }) => theme.radius.large};
    border-bottom-right-radius: ${({ theme }) => theme.radius.large};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
export const ApiKeyCardLabel = styled.div`
    white-space: nowrap;
    font-size: 14px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    ${getCustomColumnStyle('175%', '0 16px')};
`;

export const ApiKeyCardIp = styled.div`
    color: ${greyColorObject.initial};
    ${getCustomColumnStyle('25%', '0 16px')};
    .api-key-card__tooltip::before {
        white-space: normal;
        min-width: 256px;
    }
`;

export const ApiKeyCardOwner = styled.div`
    display: flex;
    flex-direction: column;
    ${getCustomColumnStyle('175%', '0 16px')};
`;

export const ApiKeyCardDatetimeBox = styled.div<{ isValidDate: boolean | '' }>`
    display: flex;
    flex-direction: column;
    ${getCustomColumnStyle('100%', '0 16px')}
    ${({ isValidDate, theme }) =>
        !isValidDate &&
        css`
            font-size: 14px;
            color: ${theme.colors.secondary.initial};
            font-weight: bold;
        `}
`;

export const ApiKeyCardShow = styled.div`
    ${getCustomColumnStyle('100%', '0')}
    color: ${({ theme }) => theme.colors.secondary.initial} !important;
    text-align: center;
`;

export const ApiKeyCardEllipsis = styled.div`
    min-width: 40px;
    margin-right: 32px;
    color: ${({ theme }) => theme.colors.secondary.initial} !important;
    .api-key-card__elipsis-menu {
        right: 0;
        bottom: -10px;
    }
`;
