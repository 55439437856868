import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import IS_IFRAME from 'app/common/data/iframe';
import { useSettingsHeader } from 'app/pages/settingsV2/hooks/Common/useSettingsHeader';

/**
 * Map used to coolect the right translation key for the Settings header label in mobile view.
 * Each key corresponds to the last URL path element, and its value represents the translation key.
 */
const mapHeaderKey: Record<string, string> = {
    settings: 'settings',
    main: 'settings_menu_general',
    profile: 'settings_menu_general_profile',
    notifications: 'settings_menu_general_notifications',
    invoices: 'settings_menu_general_invoices',
    team: 'settings_menu_team',
    users: 'settings_menu_team_users',
    permissions: 'settings_menu_team_permissions',
    groups: 'settings_menu_team_groups',
    messages: 'settings_menu_messages',
    'chat-preferences': 'settings_menu_messages_chat_preferences',
    'reply-templates': 'settings_menu_messages_reply_templates',
    'conversations-starters': 'settings_menu_messages_automatic_messages',
    chatbot: 'settings_menu_messages_chatbot',
    whatsapp: 'settings_menu_messages_whatsapp',
    reviews: 'settings_menu_reviews',
    'without-comment': 'settings_menu_reviews_without_comment',
    'with-comment': 'settings_menu_reviews_with_comment',
    platforms: 'settings_menu_platforms',
    connection: 'settings_menu_platforms_connections',
    'google-verification': 'settings_menu_platforms_google_verification',
    integrations: 'settings_menu_integrations',
    'custom-fields': 'settings_menu_integrations_custom_fields',
    'api-keys': 'settings_menu_integrations_api_keys',
};

export const HeaderPrincipalPage = () => {
    const { t } = useTranslation();
    const { headerKey, handleRedirect } = useSettingsHeader();

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="left"
            onClick={IS_IFRAME ? undefined : handleRedirect}
            style={{ cursor: IS_IFRAME ? 'default' : 'pointer', width: 'fit-content' }}
        >
            {!IS_IFRAME && (
                <Icon
                    icon={[FontAwesomeIconsPartooUsed.faArrowLeft, IconPrefix.SOLID]}
                    iconSize="24px"
                />
            )}
            <Text variant="heading3" as="span">
                {t(mapHeaderKey?.[headerKey] ? mapHeaderKey?.[headerKey] : 'settings')}
            </Text>
        </Stack>
    );
};
