import React from 'react';

import { Text } from '@partoohub/ui';
import { startCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { DayString } from 'app/api/types';
import { OpenHourHeaderContainer } from 'app/common/components/OpenHour/OpenHour.styled';

type Props = {
    day: DayString;
};

const DayHeader = ({ day }: Props) => {
    const { t } = useTranslation();
    return (
        <OpenHourHeaderContainer>
            <Text variant="bodyMBold">{startCase(t(day))}</Text>
        </OpenHourHeaderContainer>
    );
};

export default DayHeader;
