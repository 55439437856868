import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const AnalyticsBoxTextStatStyled = styled.div`
    flex: 1;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 16px;
    display: flex;
    flex-flow: column nowrap;

    &:first-of-type {
        border-right: 1px solid ${greyColorObject.initial};
    }
`;

export const ValueStyled = styled.div`
    font-weight: bold;
    line-height: 1em;
    font-size: ${props => (props.isSmall ? '30' : '48')}px;
`;
