import { Control, useController } from 'react-hook-form';

import { UseFormSetValue } from 'react-hook-form/dist/types/form';

import {
    AddressFullInput,
    Props,
} from 'app/common/components/form/AddressFullInput/AddressFullInput';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    setValue: UseFormSetValue<any>;
};

type OmitProps =
    | 'onChange'
    | 'value'
    | 'onCityChange'
    | 'onZipcodeChange'
    | 'onCountryChange'
    | 'onLatLongChange';

/**
 * Be sure that the 'name' props for city/zipcode/country/manualLat/manualLong are the same as the name in the form
 */
const ControlledAddressFullInput = ({
    name,
    control,
    setValue,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => {
    const options = { shouldDirty: true };

    const {
        field: { ref: _ref, onChange, ...usedFields },
    } = useController({ control, name });

    return (
        <AddressFullInput
            {...props}
            {...usedFields}
            onChange={onChange}
            onCityChange={city => {
                setValue('city', { label: city, value: city }, options);
            }}
            onZipcodeChange={zipcode => {
                setValue('zipcode', zipcode, options);
            }}
            onCountryChange={country => {
                setValue('country', country, options);
            }}
            onLatLongChange={(lat, long) => {
                setValue('manualLat', lat, options);
                setValue('manualLong', long, options);
            }}
        />
    );
};

export default ControlledAddressFullInput;
