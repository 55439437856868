import React, { useContext, useEffect, useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Button, FontAwesomeIconsPartooUsed, IconButton, List, Text, Tooltip } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { V2FormattedLocationData } from 'app/api/types/account';
import { BusinessLocation, BusinessLocationAddress } from 'app/api/types/business';
import { renderGoogleVerificationStatus } from 'app/businessConnection/components/common/LocationCard/LocationCard';
import PartnerIcon from 'app/businessConnection/components/common/PartnerIcon';
import FunnelContext from 'app/common/components/funnel/FunnelContext';
import {
    EditButton,
    LocationCardAddressRow,
    LocationCardAddressRowContainer,
    PartnerImg,
    StyledCell,
    StyledText,
} from 'app/onboarding/AddAddressesPage/components/LocationCardAddress/LocationCardAddress.styled';

import AddressDrawer from 'app/onboarding/AddressDrawer';

const buildLocationAddressText = (locationAddress: BusinessLocationAddress): string => {
    const { address_full: addressFull, address2, zipcode, city, country } = locationAddress;
    const addressComponents = [addressFull, address2, city, zipcode].filter(Boolean);

    let concatenatedAddress = addressComponents.join(' ');
    concatenatedAddress += `, ${country}`;

    return concatenatedAddress;
};

const formatLocationToBusinessLocation = (location: V2FormattedLocationData) => {
    return {
        id: location.id,
        address: {
            address_full: location.addressDetails,
            zipcode: location.postalCode,
            city: location.locality,
            country: location.regionCode,
        },
    };
};

function findMatchingBusinessLocation(
    location: V2FormattedLocationData,
    businessLocations?: BusinessLocation[],
): BusinessLocation {
    let businessLocation;

    if (businessLocations) {
        const businessLocationMap = new Map<string, BusinessLocation>();
        businessLocations.forEach(location => {
            businessLocationMap.set(location.id, location);
        });
        businessLocation = businessLocationMap.get(location.id);
    }

    return businessLocation ?? formatLocationToBusinessLocation(location);
}

type Props = {
    location: V2FormattedLocationData;
    setToEditLocations: React.Dispatch<React.SetStateAction<Array<V2FormattedLocationData>>>;
    setDisplayRequired: React.Dispatch<React.SetStateAction<boolean>>;
    displayRequired: boolean;
};

const LocationCardAddress = ({
    location,
    setToEditLocations,
    setDisplayRequired,
    displayRequired,
}: Props) => {
    const { t } = useTranslation();

    const funnel = useContext(FunnelContext);
    const { editedLocations = [] } = funnel.store;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [businessLocation, setBusinessLocation] = useState<BusinessLocation>(
        findMatchingBusinessLocation(location, editedLocations),
    );

    useEffect(() => {
        if (businessLocation.address?.address_full) {
            // update funnel store, so we can retrieve data if we come back on this page
            const updatedLocations = editedLocations.filter(
                (editedLocation: BusinessLocation) => editedLocation.id !== businessLocation.id,
            );
            updatedLocations.push(businessLocation);

            funnel.store = {
                ...funnel.store,
                editedLocations: updatedLocations,
            };

            // do this so parent component always get information up to date in funnel store
            setToEditLocations(currentLocations => [...currentLocations]);
        }
    }, [businessLocation]);

    const handleRemoveLocation = () => {
        // update funnel store, so when sending API request we do not get the deleted data
        funnel.store = {
            ...funnel.store,
            editedLocations: editedLocations.filter(
                (toDeleteLocation: BusinessLocation) => toDeleteLocation.id !== location.id,
            ),
        };

        setToEditLocations((currentLocations: Array<V2FormattedLocationData>) => {
            return currentLocations.filter(
                (toDeleteLocation: V2FormattedLocationData) => toDeleteLocation.id !== location.id,
            );
        });

        // to avoid displaying tooltip after deletion
        setDisplayRequired(false);
    };

    const isRequired = displayRequired && !businessLocation.address?.address_full && !isOpen;

    return (
        <LocationCardAddressRowContainer isRequired={isRequired}>
            <AddressDrawer
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                businessLocation={businessLocation}
                setBusinessLocation={setBusinessLocation}
            />
            <LocationCardAddressRow
                dataTrackId="location_card_address_row_onboarding"
                id={location.id}
                borderColor={isOpen ? 'primary' : undefined}
            >
                <List.Cell>
                    <PartnerImg>{PartnerIcon('google')}</PartnerImg>
                </List.Cell>
                <List.Cell>
                    <Text variant="bodyMBold">{location.locationName}</Text>
                    {renderGoogleVerificationStatus(t, location)}
                </List.Cell>
                <StyledCell>
                    {businessLocation.address?.address_full ? (
                        <EditButton
                            dataTrackId="edit_address_onboarding"
                            variant="secondary"
                            appearance="outlined"
                            shape="cube"
                            icon={[FontAwesomeIconsPartooUsed.faPenField]}
                            onClick={() => setIsOpen(true)}
                            iconPosition="right"
                        >
                            <Text variant="bodyMSemibold" as="span" color={HEX_COLORS.blackberry}>
                                {buildLocationAddressText(businessLocation.address)}
                            </Text>
                        </EditButton>
                    ) : (
                        <Tooltip
                            placement="left"
                            text={t('address_required')}
                            variant="danger"
                            zIndex={10000}
                            visible={isRequired}
                        >
                            <Button
                                dataTrackId="add_address_onboarding"
                                variant={isRequired ? 'danger' : 'primary'}
                                appearance="outlined"
                                shape="cube"
                                icon={[FontAwesomeIconsPartooUsed.faPlus]}
                                onClick={() => setIsOpen(true)}
                            >
                                <StyledText
                                    variant="bodyMBold"
                                    color={isRequired ? 'danger' : 'primary'}
                                    oneLine
                                    noTooltip
                                >
                                    {t('onboarding_add_addresses_page_add_address_button')}
                                </StyledText>
                            </Button>
                        </Tooltip>
                    )}
                </StyledCell>
                <List.Cell>
                    <IconButton
                        dataTrackId="remove_address_card_onboarding"
                        appearance="outlined"
                        icon={['fa-close']}
                        onClick={handleRemoveLocation}
                        tooltip={t('delete')}
                    />
                </List.Cell>
            </LocationCardAddressRow>
        </LocationCardAddressRowContainer>
    );
};

export default LocationCardAddress;
