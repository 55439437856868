import React from 'react';

import { ColoredPath } from 'app/common/components/icons/Sidebar/ColoredPath.styled';

type Props = {
    isActive?: boolean;
};

const ConversationsIconSidebar = React.forwardRef<SVGSVGElement, Props>(
    ({ isActive = false }, ref) => (
        <svg
            ref={ref}
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            width="40px"
            height="40px"
        >
            {isActive ? (
                <ColoredPath d="M29.5591 29.5913L30.1794 30.646H23.4039C20.1092 30.646 17.2647 28.5845 16.215 25.5352C16.21 25.5269 16.207 25.5163 16.2044 25.5083H16.6252C21.385 25.5083 25.3642 21.6596 25.5133 16.8734V16.3727C25.5157 16.3034 25.5133 16.2342 25.5133 16.1703C26.0804 16.3514 26.6213 16.6017 27.1327 16.916C28.821 17.9548 30.0039 19.5875 30.462 21.5158C30.9225 23.4441 30.6031 25.4364 29.5617 27.1276C29.1007 27.8894 29.1007 28.8349 29.5591 29.5913ZM30.7014 28.907C30.499 28.5714 30.499 28.1532 30.6988 27.823C31.9242 25.8307 32.2995 23.4818 31.7591 21.2073C31.2157 18.9324 29.8198 17.0068 27.8305 15.7816C27.0448 15.2968 26.1949 14.9453 25.3003 14.7269C24.9034 13.0835 24.0218 11.5627 22.7913 10.4068C20.9935 8.68887 18.5431 7.82059 16.0688 8.031C13.7383 8.21211 11.6182 9.29347 10.0998 11.07C8.57897 12.8465 7.84413 15.1077 8.02764 17.4382C8.13444 18.8286 8.58163 20.1922 9.31407 21.3668C9.51676 21.7051 9.51676 22.1232 9.31141 22.4615L8.56325 23.7133C8.39812 23.9903 8.34991 24.3152 8.43008 24.6268C8.50998 24.9385 8.70708 25.2024 8.98407 25.3646C9.16759 25.4738 9.38066 25.5324 9.59933 25.5324L14.8194 25.5353C15.925 29.3571 19.3768 31.9779 23.4039 31.9779H30.4005C31.0663 31.9779 31.607 31.4372 31.607 30.7714C31.607 30.5557 31.5487 30.3452 31.4395 30.1588L30.7014 28.907Z" />
            ) : (
                <path
                    d="M29.5591 29.5913L30.1794 30.646H23.4039C20.1092 30.646 17.2647 28.5845 16.215 25.5352C16.21 25.5269 16.207 25.5163 16.2044 25.5083H16.6252C21.385 25.5083 25.3642 21.6596 25.5133 16.8734V16.3727C25.5157 16.3034 25.5133 16.2342 25.5133 16.1703C26.0804 16.3514 26.6213 16.6017 27.1327 16.916C28.821 17.9548 30.0039 19.5875 30.462 21.5158C30.9225 23.4441 30.6031 25.4364 29.5617 27.1276C29.1007 27.8894 29.1007 28.8349 29.5591 29.5913V29.5913ZM14.5027 24.2035L9.82038 24.2006L10.4543 23.1459C10.9121 22.3868 10.9121 21.4413 10.4489 20.6715C9.82544 19.6674 9.44723 18.5141 9.35428 17.3342C9.1998 15.3606 9.82278 13.4403 11.1119 11.9355C12.3983 10.4279 14.1961 9.51439 16.1753 9.35725C18.2741 9.18146 20.3489 9.9139 21.8748 11.3735C22.7833 12.2284 23.4649 13.3098 23.8511 14.4923C23.9496 14.7907 24.0269 15.0969 24.0855 15.4086V15.4192L24.0961 15.4565C24.0961 15.4592 24.1896 15.8321 24.1816 16.4047V16.4074L24.1867 16.4633L24.1896 16.8788C24.0615 20.9299 20.6763 24.2035 16.6252 24.2035H14.5027ZM30.7014 28.907C30.499 28.5714 30.499 28.1532 30.6988 27.823C31.9242 25.8307 32.2995 23.4818 31.7591 21.2073C31.2157 18.9324 29.8198 17.0068 27.8305 15.7816C27.0448 15.2968 26.1949 14.9453 25.3003 14.7269C24.9034 13.0835 24.0218 11.5627 22.7913 10.4068C20.9935 8.68887 18.5431 7.82059 16.0688 8.031C13.7383 8.21211 11.6182 9.29347 10.0998 11.07C8.57897 12.8465 7.84413 15.1077 8.02764 17.4382C8.13444 18.8286 8.58163 20.1922 9.31407 21.3668C9.51676 21.7051 9.51676 22.1232 9.31141 22.4615L8.56325 23.7133C8.39812 23.9903 8.34991 24.3152 8.43008 24.6268C8.50998 24.9385 8.70708 25.2024 8.98407 25.3646C9.16759 25.4738 9.38066 25.5324 9.59933 25.5324L14.8194 25.5353C15.925 29.3571 19.3768 31.9779 23.4039 31.9779H30.4005C31.0663 31.9779 31.607 31.4372 31.607 30.7714C31.607 30.5557 31.5487 30.3452 31.4395 30.1588L30.7014 28.907Z"
                    fill="white"
                />
            )}
        </svg>
    ),
);

export default ConversationsIconSidebar;
