import { format, subMonths } from 'date-fns';
import { isEqual } from 'lodash-es';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { ReviewAnalyticsData } from 'app/api/types/review';
import api from 'app/api/v2/api_calls';
import { filterByBusinessSelector } from 'app/common/components/businessModalDeprecated/reducers';
import { enableNewGroupsGenerator } from 'app/common/hooks/queries/useNewGroups';
import { Saga } from 'app/common/types';
import {
    ReviewFiltersState,
    activeReviewFiltersSelector as activeFilters,
    dateFilterSelector,
    reviewFiltersSelector,
} from 'app/states/reviews/filters';

import { ISO_DATETIME_FORMAT_WITH_TIMEZONE } from 'app/utils/dates';

import {
    DATA_IS_OUTDATED,
    GET_REVIEWS_EVOLUTION_REQUEST,
    GetReviewsEvolutionRequestType,
    getReviewsEvolutionFailureAction,
    getReviewsEvolutionSuccessAction,
} from '../actions';
import { ReviewAnalyticsState } from '../reducers';
import { isPeriodSelector, reviewAnalyticsSelector } from '../selectors';
import { filterQueryParams } from '../services';

function* searchReviewsEvolution({ isQualitative, isPeriod }: GetReviewsEvolutionRequestType) {
    try {
        const reviewFiltersState = yield select(reviewFiltersSelector) as any as ReviewFiltersState;

        const reviewAnalyticsState = yield select(
            reviewAnalyticsSelector,
        ) as any as ReviewAnalyticsState;

        const filterByBusiness = yield select(filterByBusinessSelector);

        // TODO RICK: remove saga and use useBusinessModalFilters() instead of this
        const enableNewGroups = yield* enableNewGroupsGenerator();
        const filters = filterQueryParams(
            isPeriod
                ? reviewFiltersState
                : {
                      ...reviewFiltersState,
                      date: {
                          from: null,
                          to: null,
                      },
                  },
            reviewAnalyticsState,
            filterByBusiness,
            enableNewGroups,
        );

        const date = dateFilterSelector(reviewFiltersState);

        // If "In the period" and no date is set
        // we take the last 6 months
        if (!(date.from || date.to) && isPeriod) {
            filters.update_date__gte = format(
                subMonths(new Date(), 6),
                ISO_DATETIME_FORMAT_WITH_TIMEZONE,
            );
        }

        // @ts-ignore
        const data = yield call(
            isQualitative
                ? api.review.getReviewsQualitativeEvolution
                : api.review.getReviewsQuantitativeEvolution,
            filters,
        ) as Array<ReviewAnalyticsData>;

        const newReviewAnalyticsState = yield select(
            reviewAnalyticsSelector,
        ) as any as ReviewAnalyticsState;

        const newReviewFilters = yield select(reviewFiltersSelector) as any as ReviewFiltersState;

        if (
            isPeriodSelector(reviewAnalyticsState) === isPeriodSelector(newReviewAnalyticsState) &&
            isEqual(activeFilters(reviewFiltersState), activeFilters(newReviewFilters))
        ) {
            yield put(getReviewsEvolutionSuccessAction(data, isQualitative, isPeriod));
        } else {
            throw new Error(DATA_IS_OUTDATED);
        }
    } catch (error) {
        yield put(getReviewsEvolutionFailureAction(error));
    }
}

function* searchReviewsEvolutionSaga(): Saga {
    yield takeEvery(GET_REVIEWS_EVOLUTION_REQUEST, searchReviewsEvolution);
}

export default searchReviewsEvolutionSaga;
