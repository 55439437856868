import { css } from '@emotion/react';
import styled from '@emotion/styled';

type GoogleMapsContainerProps = {
    disabled: boolean;
};

export const GoogleMapsMain = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 300px;

    ${({ disabled }: GoogleMapsContainerProps) =>
        disabled &&
        css`
            opacity: 0.25;
        `}
`;

export const GoogleMapsContent = styled.div`
    display: flex;
    flex: 1;
    position: relative;
`;

export const GoogleMapsView = styled.div`
    width: 100%;
`;

export const LoadingElement = styled.div`
    width: 50px;
    height: 50px;
    margin: 100px auto;
    background-color: white;

    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
    background-color: ${({ theme }) => theme.colors.secondary.initial};
`;
