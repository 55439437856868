/**
 * Returns true if the app is running inside an iframe.
 * Taken from https://stackoverflow.com/a/326076/837471
 * @returns {boolean}
 */
export default function isInsideIFrame(): boolean {
    try {
        return window.self !== window.top;
    } catch (e) {
        // Apparently if the iframe is embedded in a cross-origin page,
        // the above check might break. We can safely assume that it is
        // in an iframe.
        return true;
    }
}
