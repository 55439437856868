import React from 'react';

import { V2FormattedAccountData } from 'app/api/types/account';
import { Choice } from 'app/api/types/user';

import AccountBadgeOption from '../components/AccountBadgeOption/AccountBadgeOption';

export const convertAccountToChoice = (
    account: V2FormattedAccountData,
): Choice => {
    const badge = (
        <AccountBadgeOption
            photoUrl={account.picture}
            name="google"
            accountInitials={(account.email && account.email[0]) || ''}
            tooltip={account.email}
        />
    );
    return {
        label: account.email,
        value: account.id,
        icon: badge,
    };
};
