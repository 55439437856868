import { SerializedStyles, css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const stickyHeaderAnimationIn = keyframes`
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0%);
    }
`;

const stickyHeaderAnimationOut = keyframes`
    from {
        transform: translateY(0%);
    }

    to {
        transform: translateY(-100%);
    }
`;

export const stickyHeader = css`
    position: absolute;
    left: 0;
    top: 0;
    border-bottom: 1px solid #e6e8ed;
    background-color: white;
    z-index: 10;
    width: 100%;
    padding: 24px 40px 16px;
    transform: translateY(-100%);
    -webkit-animation: forwards;
    animation: forwards;
    transition: none;
`;

export const stickyHeaderIn = css`
    ${stickyHeader};
    animation-duration: 0.3s;
    animation-name: ${stickyHeaderAnimationIn};
`;

export const stickyHeaderOut = styled.div`
    ${stickyHeader};
    animation-duration: 0.3s;
    animation-name: ${stickyHeaderAnimationOut};
`;

export const PageHeaderContainer = styled.div<{ css?: SerializedStyles }>`
    ${({ css }) => css};
`;

export const PageHeaderComponentTop = styled.div`
    display: flex;
    min-height: 48px;
    justify-content: space-between;
    margin-bottom: 24px;
`;

export const PageHeaderComponentTopLeft = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    gap: 16px;
`;

export const PageHeaderComponentTopPageButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const PageHeaderComponentFiltersContainer = styled.div`
    display: flex;
`;

export const PageHeaderComponentFilters = styled.div`
    margin-bottom: 8px;
    display: flex;
    align-items: center;
`;

export const PageHeaderComponentCounter = styled.div`
    margin-left: auto;
    height: 62px;
    span {
        white-space: nowrap;
        transform: translate(0px, 13px);
        font-weight: bold;
        display: block;
    }
`;

export const PageHeaderDescription = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-weight: bold;
    // Hack but the PageHeader is bad so..
    margin-top: -12px;
    margin-bottom: 24px;
`;

export const PageHeaderSideTitle = styled.div`
    font-size: 18px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin-top: 8px;
`;
