import { useEffect, useState } from 'react';

import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useDebouncedPublisherErrors from 'app/businessEditV2/hooks/publisherErrors/useDebouncedPublisherErrors';

import useFormatPublisherErrors from 'app/businessEditV2/hooks/publisherErrors/useFormatPublisherErrors';
import i18n from 'app/common/translations/i18n';

export default function useFormFieldFormatter(
    section: string,
    field: string,
    watch,
    formatPayload,
    errors,
) {
    const [prechecks, setPrechecks] = useState({});
    const isClosed = useIsBusinessClosed();

    const disabled = !useFieldMetadata(section, field)?.enabled || isClosed;

    const inputErrorMessage = errors?.[field];
    const tempPrechecks = useDebouncedPublisherErrors(watch, formatPayload);

    // Won't reset prechecks if the API call is in error
    useEffect(() => {
        if (tempPrechecks !== undefined) {
            setPrechecks(tempPrechecks);
        }
    }, [tempPrechecks, prechecks]);

    const formattedPublisherErrors = useFormatPublisherErrors(prechecks, section, field);

    return {
        ...formattedPublisherErrors,
        errorMessage: section !== 'openHours' ? i18n.t(inputErrorMessage) : '',
        hasError: !!inputErrorMessage,
        hasWarning: Object.keys(formattedPublisherErrors.publisherErrors).length > 0,
        disabled,
        textTooltip: disabled ? i18n.t('field_read_only') : '',
    };
}
