import { TextInput } from '@partoohub/ui';

import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const VALID_URL_REGEX =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

type Props = {
    disabled?: boolean;
};

export const ControlledCustomLinkInput = ({ disabled }: Props) => {
    const { t } = useTranslation();
    const { formState, control } = useFormContext();

    const {
        field: { ref: _ref, ...usedFields },
        fieldState: { error },
    } = useController({
        name: 'custom_link',
        control,
        rules: {
            required: true,
            pattern: VALID_URL_REGEX,
        },
    });

    return (
        <div style={{ paddingBottom: '16px' }}>
            <TextInput
                {...usedFields}
                dataTrackId="custom_link"
                label={t('your_link')}
                required
                error={!!error}
                disabled={!!disabled}
                notice={formState.errors.custom_link?.type === 'pattern' && t('url_invalid')}
            />
        </div>
    );
};
