import styled from '@emotion/styled';

import { TextInput } from '@partoohub/ui';

export const StyledTextInput = styled(TextInput)`
    width: 100%;
`;
export const BusinessModalFilterSelectButton = styled.div`
    margin-left: 16px;
    min-width: 248px;
`;
