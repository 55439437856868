import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

export const ImagePreviewListContainer = styled.ul`
    list-style-type: none;
    display: flex;
    margin-bottom: 8px;
    max-width: 100%;

    & > li {
        margin-right: 8px;
        position: relative;

        .remove-button {
            position: absolute;
            right: -8px;
            top: -8px;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

export const ImagePreviewItem = styled.img`
    height: 56px;
    width: 56px;
    border-radius: 16px;
    border: 1px solid ${greyColorObject.initial};
    object-fit: cover;
`;

export const ImageInputIconButtonWrapper = styled.div`
    path {
        fill: ${({ theme }) => theme.colors.secondary.initial};
    }
    &:hover,
    &:focus {
        path {
            fill: ${({ theme }) => theme.colors.primary.initial};
        }
    }
`;

export const ImagePreviewRemoveButton = styled.button`
    width: 24px;
    height: 24px;
    background: ${HEX_COLORS.white};
    border: none;
    border-radius: 50%;
    padding: 5px;
    margin: 0;
    line-height: 1;

    &:hover,
    &:focus {
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    }

    svg {
        fill: ${({ theme }) => theme.colors.secondary.initial};
    }
`;
