import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { BUSINESS, PLACE_ACTION_LINKS } from 'app/common/data/queryKeysConstants';

export default function useBusinessPlaceActionLinks() {
    const businessId = useGetBusinessId();
    const { data: business } = useBusiness();
    return useQuery(
        [BUSINESS, PLACE_ACTION_LINKS, { businessId }],
        () => api.business.businessPlaceActionLinksGet(businessId),
        {
            enabled: !!business,
        },
    );
}
