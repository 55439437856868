import {
    FoodMenuGetDataType,
    FoodMenuSectionType,
} from 'app/api/types/food_menu';

import ApiResourceBaseClass from './baseApiCalls';
import { RESOURCE_URI as BUSINESS_RESOURCE_URI } from './businessApiCalls';

const RESOURCE_URI = 'food_menus';

class FoodMenu extends ApiResourceBaseClass {
    static isFoodMenusAvailable(businessId: string): Promise<boolean> {
        return FoodMenu.get(
            `${BUSINESS_RESOURCE_URI}/${businessId}/is_food_menus_available`,
        ).then(({ data }) => data);
    }

    static getFoodMenu(businessId: string): Promise<FoodMenuGetDataType> {
        return FoodMenu.get(
            `${BUSINESS_RESOURCE_URI}/${businessId}/${RESOURCE_URI}`,
        ).then(({ data }) => data);
    }

    static createUpdateFoodMenu(
        businessId: string,
        sections: Array<FoodMenuSectionType>,
    ): Promise<FoodMenuGetDataType> {
        return FoodMenu.post(
            `${BUSINESS_RESOURCE_URI}/${businessId}/${RESOURCE_URI}`,
            { menus: sections },
        ).then(({ data }) => data);
    }
}

export default FoodMenu;
