import React from 'react';

import { SpecificHourFormData } from 'app/api/types/business';
import { Holiday } from 'app/api/types/holidays';
import { useFormatDate } from 'app/common/hooks/useFormatDate';
import dataLayer from 'app/common/utils/dataLayer';

import {
    HolidayDate,
    HolidayIcon,
    HolidayItemContainer,
    HolidayItemLeft,
    HolidayName,
} from './HolidayItem.styled';

type Props = {
    onChange: (specificHours: SpecificHourFormData) => void;
    holiday: Holiday;
    specificHours: SpecificHourFormData;
    scrollToRef: () => void;
};

const HolidayItem = ({ onChange, holiday, specificHours, scrollToRef }: Props) => {
    const generateDate = useFormatDate({
        year: 'numeric',
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
    });

    const onItemClick = (holiday: Holiday) => {
        dataLayer.pushDict('select_bank_holiday', {
            device: 'web',
        });

        const result = [...specificHours];
        result.unshift({
            starts_at: holiday.date,
            ends_at: holiday.date,
            open_hours: [],
            open: false,
        });
        onChange(result);
        scrollToRef();
    };

    return (
        <HolidayItemContainer onClick={() => onItemClick(holiday)}>
            <HolidayItemLeft>
                <HolidayName variant="bodyMBold">{holiday.name}</HolidayName>
                <HolidayDate>{generateDate(holiday.date)}</HolidayDate>
            </HolidayItemLeft>
            <HolidayIcon>
                <i className="fa-solid fa-plus" />
            </HolidayIcon>
        </HolidayItemContainer>
    );
};

export default HolidayItem;
