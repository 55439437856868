import { useState } from 'react';

import { useImagesUploaderContext } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/hooks/useImagesUploaderContext';

import { ImageUploaderRowWrapper } from './ImageUploaderRow.styled';
import { ImageUploaderRowBody } from './ImageUploaderRowBody';
import { ImageUploaderRowHeader } from './ImageUploaderRowHeader';

type ImageUploaderRowProps = {
    imageIndex: number;
};

export const ImageUploaderRow = ({ imageIndex }: ImageUploaderRowProps) => {
    const { textFields } = useImagesUploaderContext();
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        if (textFields.length !== 0) setOpen(!open);
    };

    return (
        <ImageUploaderRowWrapper gap="16px">
            <ImageUploaderRowHeader imageIndex={imageIndex} toggleOpen={toggleOpen} />
            {open && <ImageUploaderRowBody imageIndex={imageIndex} />}
        </ImageUploaderRowWrapper>
    );
};
