import { css } from '@emotion/react';
import { List } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { V2BusinessStatus } from 'app/api/types/business';
import BusinessCard from 'app/businessConnection/components/common/BusinessCard';
import { CLOSED } from 'app/common/data/businessStatuses';
import useBusiness from 'app/common/hooks/queries/useBusiness';
import formatBusiness from 'app/common/services/formatBusiness';
import {
    FACEBOOK,
    GOOGLE_MY_BUSINESS,
} from 'app/states/knowledge/reducers/businessConnection/accounts';

import { BusinessRowContainer, ClosedBusinessText } from './BusinessRow.styled';
import LocationItem from './LocationItem';

type Props = {
    businessId: string;
    googleLocationId: string | null;
    facebookPageId: string | null;
    businessStatus: V2BusinessStatus;
};

const BusinessRow = ({ businessId, googleLocationId, facebookPageId, businessStatus }: Props) => {
    const { t } = useTranslation();
    const { data: business } = useBusiness(businessId);

    return (
        <BusinessRowContainer
            dataTrackId="business_row"
            id={businessId}
            rowCssContainer={css`
                padding: 8px 16px;
            `}
        >
            <List.Cell>
                <BusinessCard business={business ? formatBusiness(business) : undefined} noBorder />
            </List.Cell>
            {businessStatus !== CLOSED ? (
                <>
                    <List.Cell>
                        <LocationItem
                            partner={GOOGLE_MY_BUSINESS}
                            locationId={googleLocationId}
                            businessId={businessId}
                        />
                    </List.Cell>
                    <List.Cell>
                        <LocationItem
                            partner={FACEBOOK}
                            locationId={facebookPageId}
                            businessId={businessId}
                        />
                    </List.Cell>
                </>
            ) : (
                <ClosedBusinessText variant="bodyMBold" color="danger">
                    {t('this_business_is_closed')}
                </ClosedBusinessText>
            )}
        </BusinessRowContainer>
    );
};

export default BusinessRow;
