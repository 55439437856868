import { CommentData } from 'app/api/types/comments';
import { Partner } from 'app/businessEditV2/hooks/useIsConnectedToPartner';
import { useCanReplyClientReviews } from 'app/pages/customerExperience/useCanReplyClientReviews';
import { ReviewObject } from 'app/states/reviews';

export const useShowReviewReplyField = ({
    review,
    comments,
}: {
    review: ReviewObject;
    comments: Array<CommentData>;
}) => {
    const { canReplyClientReviews } = useCanReplyClientReviews();
    const userHasPermission = canReplyClientReviews[review.reviewObjectType];

    if (!userHasPermission) {
        return false;
    }

    if (review.partner === Partner.Facebook) {
        return review.state !== 'deleted';
    }

    return review.state !== 'deleted' && !comments.length;
};
