import { Avatar, IconPrefix } from '@partoohub/ui';

import { BusinessUser } from 'app/api/types/user';

import { getInitials, getInitialsAndBackgroundColours } from './helper';
import { InitialsContainer } from './UserAvatar.styled';
import { UserNotFoundAvatar } from '../UserNotFoundAvatar';

interface UserAvatarProps {
    user: BusinessUser | null;
    size: number;
    textSize: number;
}

export const UserAvatar = ({ user, size, textSize }: UserAvatarProps) => {
    if (!user) return <UserNotFoundAvatar />;
    const { firstName, lastName, id } = user;
    const userInitials = getInitials(firstName.trim(), lastName.trim());
    const truncatedUserId = id.substring(0, 5);
    const [initialsColor, backgroundColor] = getInitialsAndBackgroundColours(truncatedUserId);
    const areInitialsEmpty = userInitials === '';

    return (
        // To override MenuList selection.
        <InitialsContainer textColor={initialsColor}>
            {areInitialsEmpty ? (
                <EmojiAvatar backgroundColor={backgroundColor} size={size} />
            ) : (
                <Avatar
                    text={userInitials}
                    textColor={initialsColor}
                    size={size}
                    textSize={textSize}
                    backgroundColor={backgroundColor}
                />
            )}
        </InitialsContainer>
    );
};

interface EmojiAvatarProps {
    backgroundColor: string;
    size: number;
}

export const EmojiAvatar = ({ backgroundColor, size }: EmojiAvatarProps) => {
    return (
        <Avatar
            image={['fa-font-case', IconPrefix.REGULAR]}
            size={size}
            iconSize={'12px'}
            backgroundColor={backgroundColor}
        />
    );
};
