import { connect } from 'react-redux';

import { BusinessInfosImportType } from 'app/api/types/business';
import getBusinessFieldsDisabled from 'app/businessConnection/services/getBusinessFieldsDisabled';
import { businessFieldsSelector } from 'app/common/reducers/businessEditFields';
import { meRoleSelector } from 'app/common/reducers/me';
import { businessByIdSelector } from 'app/common/reducers/newBusinesses';
import { getGooglePostStatus } from 'app/common/reducers/orgs';
import {
    businessImportSelector,
    selectedBusinessIdSelector,
    updateBusinessImport,
} from 'app/states/knowledge/reducers/businessConnection/connection';
import {
    AppState,
    businessesEditFieldsSelector,
    connectionSelector,
    meSelector,
} from 'app/states/reducers';

import { ImportSection as ImportSectionComponent } from '../../ModalCommonComponents/ImportSection';

const mapStateToProps = (state: AppState) => {
    const role = meRoleSelector(meSelector(state));
    const businessEditFields = businessesEditFieldsSelector(state);
    const businessFields = businessFieldsSelector(businessEditFields);
    const businessFieldsDisabled = getBusinessFieldsDisabled(businessFields);
    const businessId = selectedBusinessIdSelector(state.businessConnection.page);
    const business = businessByIdSelector(state.businesses, businessId || '');
    const googlePostStatus = getGooglePostStatus(state.orgs, business?.orgId);

    return {
        businessImport: businessImportSelector(connectionSelector(state)),
        // warnings
        businessWarning: {
            businessInfos: {
                name: true,
                code: true,
                categories: true,
                addressFull: true,
                coordinates: true,
                openHours: true,
                moreHours: true,
                specificHours: true,
                description: true,
                phone: true,
                website: true,
                additionalUrls: true,
                openingDate: true,
            },
            photos: false,
            attributes: true,
            services:
                businessFields.filter(value => value.name === 'services')[0]?.fields[0]?.enabled ||
                false,
            googlePosts: false,
        },
        businessFieldsDisabled,
        role,
        googlePostStatus,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onChange: (name: string, value: BusinessInfosImportType | boolean) => {
        dispatch(updateBusinessImport(name, value));
    },
});

const ImportSection = connect(mapStateToProps, mapDispatchToProps)(ImportSectionComponent);

export default ImportSection;
