import { useLocation } from 'react-router-dom';

import { POSTS_PATH } from 'app/routing/routeIds';

/**
 * Hook to check if the current page is a posts page
 */
export const useIsPosts = (): boolean => {
    const { pathname } = useLocation();
    return pathname.startsWith(POSTS_PATH);
};
