import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

const ORDER_BY_DEFAULT_VALUE = '';

export const useGetOrderBy = () => {
    const [orderBy] = useStateQueryParams('order_by');

    return {
        order_by: orderBy || ORDER_BY_DEFAULT_VALUE,
    };
};
