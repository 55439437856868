import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { useKeywordFilter } from './useKeywordFilter';
import { FiltersEnum } from '../constant/filtersEnum';
import { formatDate } from '../utils/formatDate';

export const useDateFilter = (): [Date, (date: Date) => void] => {
    const [date, setDate] = useStateQueryParams(FiltersEnum.DATE);
    const [keyword] = useKeywordFilter();

    const setDateFilter = (date: Date) => setDate(formatDate(date));

    if (date) {
        return [new Date(date), setDateFilter];
    }

    if (keyword?.dates?.length > 0) {
        const lastDate = keyword.dates[keyword.dates.length - 1];
        if (lastDate) return [lastDate, setDateFilter];
    }

    return [new Date(), setDateFilter];
};
