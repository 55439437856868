import { useState } from 'react';

import { Button, Chip, Text, TextArea, toast } from '@partoohub/ui';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    ChatbotCommunicationContainer,
    ChatbotCommunicationSection,
    ChatbotCommunicationSectionTitle,
    ChipContainer,
    DescriptionText,
    IconContainer,
    ToneOfVoiceCard,
    ToneOfVoiceCardList,
} from 'app/pages/settings/Chatbot/components/Communication/ChatbotCommunication.styled';

import { ChatbotInstructionsButtonContainer } from 'app/pages/settings/Chatbot/components/Instructions/ChatbotInstruction.styled';
import { useChatbot } from 'app/pages/settings/Chatbot/hooks/useChatbot';
import { useChatbotUpdate } from 'app/pages/settings/Chatbot/hooks/useChatbotUpdate';
import { useToneOfVoices } from 'app/pages/settings/Chatbot/hooks/useToneOfVoices';
import { CASUAL_TONE, ChatbotToneOfVoiceMapping } from 'app/pages/settings/Chatbot/type/chatbot';

interface Props {
    onClose: () => void;
    setHasSucceeded?: (state: boolean) => void;
}

type FormProps = {
    welcome_message: string;
};

export const ChatbotCommunication = ({ onClose, setHasSucceeded }: Props) => {
    const { t } = useTranslation();
    const chatbot = useChatbot();
    const toneOfVoices = useToneOfVoices();
    const ChatbotUpdateMutation = useChatbotUpdate();
    const { control, handleSubmit, reset, formState } = useForm<FormProps>({
        defaultValues: {
            welcome_message: chatbot.data?.welcome_message,
        },
    });
    const [selectedToneOfVoice, setSelectedToneOfVoice] = useState(chatbot.data?.tone_of_voice_id);

    const handleOnClose = () => {
        onClose();
        reset();
    };

    const handleOnSubmit = (data: FormProps) => {
        onClose();
        ChatbotUpdateMutation.mutate({ ...data, tone_of_voice_id: selectedToneOfVoice });
        if (setHasSucceeded) {
            setHasSucceeded(true);
            toast.success(
                t('chatbot_snackbar_changes_saved_text'),
                t('chatbot_snackbar_changes_saved_title'),
            );
        }
    };

    const welcome_message_error_mapping: { [key: string]: string } = {
        required: t('chatbot_field_is_required_error'),
        maxLength: t('chatbot_field_welcome_message_max_length_error'),
    };

    return (
        <ChatbotCommunicationContainer>
            <ChatbotCommunicationSection>
                <ChatbotCommunicationSectionTitle>
                    <Chip dataTrackId="">1</Chip>
                    <Text variant="bodyLBold">
                        {t('chatbot_communication_tone_of_voice_title')}
                    </Text>
                </ChatbotCommunicationSectionTitle>
                <ToneOfVoiceCardList>
                    {toneOfVoices?.data?.map(toneOfVoice => (
                        <ToneOfVoiceCard
                            key={ChatbotToneOfVoiceMapping[toneOfVoice.label].title}
                            onClick={() => setSelectedToneOfVoice(toneOfVoice.id)}
                            selected={selectedToneOfVoice === toneOfVoice.id}
                        >
                            <IconContainer as="div">
                                {ChatbotToneOfVoiceMapping[toneOfVoice.label].icon}
                            </IconContainer>
                            <Text variant="bodyLBold">
                                {t(ChatbotToneOfVoiceMapping[toneOfVoice.label].title)}
                            </Text>
                            <DescriptionText color="secondary">
                                {t(ChatbotToneOfVoiceMapping[toneOfVoice.label].description)}
                            </DescriptionText>
                            {toneOfVoice.label === CASUAL_TONE && (
                                <ChipContainer>
                                    <Text color="primary" variant="bodySBold">
                                        {t('chatbot_communication_tone_of_voice_popular')}
                                    </Text>
                                </ChipContainer>
                            )}
                        </ToneOfVoiceCard>
                    ))}
                </ToneOfVoiceCardList>
            </ChatbotCommunicationSection>
            <ChatbotCommunicationSection>
                <ChatbotCommunicationSectionTitle>
                    <Chip dataTrackId="">2</Chip>
                    <Text variant="bodyLBold">
                        {t('chatbot_communication_welcome_message_title')}
                    </Text>
                </ChatbotCommunicationSectionTitle>
                <Controller
                    control={control}
                    rules={{ required: true, maxLength: 400 }}
                    name="welcome_message"
                    render={({ field: { ref: _ref, ...usedFields } }) => (
                        <TextArea
                            dataTrackId=""
                            {...usedFields}
                            label={t('chatbot_communication_welcome_message_title')}
                            error={['required', 'maxLength'].includes(
                                formState.errors.welcome_message?.type ?? '',
                            )}
                            notice={
                                welcome_message_error_mapping[
                                    formState.errors.welcome_message?.type ?? ''
                                ] ?? undefined
                            }
                            maxRows={50}
                            rows={3}
                        />
                    )}
                />
            </ChatbotCommunicationSection>
            <ChatbotInstructionsButtonContainer>
                <Button
                    onClick={handleOnClose}
                    dataTrackId="chatbot_instruction_cancel"
                    appearance="outlined"
                    variant="secondary"
                    shape="cube"
                    size="large"
                >
                    {t('cancel')}
                </Button>
                <Button
                    onClick={handleSubmit(data => handleOnSubmit(data))}
                    dataTrackId="chatbot_instruction_confirm"
                    appearance="contained"
                    shape="cube"
                    variant="primary"
                    size="large"
                    full
                >
                    {t('chatbot_form_confirm_button')}
                </Button>
            </ChatbotInstructionsButtonContainer>
        </ChatbotCommunicationContainer>
    );
};
