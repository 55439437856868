import useBusinessAttributes from 'app/businessEditV2/hooks/attributes/attributesBusiness/useBusinessAttributes';
import { ATTRIBUTE_URL_TO_IGNORE } from 'app/businessEditV2/hooks/attributes/attributesMetadata/useFormattedAttributesUrl';
import { ATTRIBUTES_URL_TYPES } from 'app/businessEditV2/utils/attributes';

export default function useFormattedBusinessAttributesUrl() {
    const { data: businessAttributes } = useBusinessAttributes();

    return (
        businessAttributes?.attributes.filter(attribute => {
            return (
                ATTRIBUTES_URL_TYPES.includes(attribute.type) &&
                !ATTRIBUTE_URL_TO_IGNORE.includes(attribute.gmb_id)
            );
        }) ?? []
    );
}
