import styled from '@emotion/styled';

export const UserManagementFiltersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
`;

export const UserManagementFiltersTop = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    > div:first-of-type {
        flex: 1;
    }
`;
