export const truncate = (st, limit = 25) => {
    if (st && st.length >= limit) {
        return `${st.substring(0, limit)}...`;
    }

    return st;
};

export const randomString = (length: number): string => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let rnd = '';

    for (let i = 1; i <= length; i += 1) {
        const rndIndex = Math.floor(Math.random() * letters.length);
        rnd += letters.substring(rndIndex, rndIndex + 1);
    }

    return rnd;
};

export const intWithSpaces = int => Number(int).toLocaleString('fr');

// @ts-ignore

Set.prototype.intersection = function (setB) {
    const intersection = new Set();

    for (const elem of setB) {
        if (this.has(elem)) {
            intersection.add(elem);
        }
    }

    return intersection;
};

// @ts-ignore

Set.prototype.isSubset = function (subsetB) {
    // @ts-ignore

    for (const elem of this) {
        if (!subsetB.has(elem)) {
            return false;
        }
    }

    return true;
};

export default Set;
