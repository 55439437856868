import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import IntercomWrapper from 'app/common/services/intercomWrapper';

const MissingAccessBanner = () => {
    const { t } = useTranslation();

    const handleButtonClick = () => {
        IntercomWrapper.show();
    };

    return (
        <Banner
            dataTrackId="facebook_error_banner"
            variant="danger"
            withCloseIcon={false}
            actionButtonText={t('messaging_messenger_error_global_banner_button')}
            onAction={handleButtonClick}
        >
            {t('messaging_messenger_error_global_banner')}
        </Banner>
    );
};

export default MissingAccessBanner;
