import styled from '@emotion/styled';

export const LoadingElement = styled.div`
    width: 20px;
    height: 20px;
    margin: 100px auto;
    background-color: white;

    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
    background-color: ${({ theme }) => theme.colors.secondary.initial};
`;
