import { useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton, Modal, Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import DailyReportSliderModal from 'app/settingsManagement/components/modal/DailyReportSliderModal';

import { Title } from './DailyReportTitle.styled';

export const DailyReportTitle = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            <Stack gap="4px" direction="row" alignItems="center">
                <Title>{t('daily_report')}</Title>
                <IconButton
                    dataTrackId="daily_report__icon_button"
                    appearance="outlined"
                    variant="default"
                    tooltip={t('more_info')}
                    onClick={toggleModal}
                    size="small"
                    icon={[FontAwesomeIconsPartooUsed.faCircleQuestion]}
                />
            </Stack>
            <Modal isOpen={showModal} closeModal={toggleModal}>
                <DailyReportSliderModal />
            </Modal>
        </>
    );
};
