import { useEffect, useState } from 'react';

import {
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    Switch,
    TextInput,
} from '@partoohub/ui';
import { cloneDeep, isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { customFieldsType as types } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/services';

import {
    PossibleValueContainer,
    PossibleValueContainerWrapper,
    PossibleValueIcons,
    PossibleValueOption,
    SwitchContainer,
} from './FormType.styled';
import { SelectButton } from '../CustomFieldForm.styled';

type Props = {
    params: Record<string, any>;
    updateParams: (typeParams: Record<string, any>) => void;
    editMode: boolean;
    duplicates: Record<string, any>;
};

export const FormTypeSelect = ({
    params: oldParams,
    updateParams,
    editMode,
    duplicates,
}: Props) => {
    const { t } = useTranslation();

    const params = cloneDeep(oldParams);

    const [autofocusOnNewOption, setAutofocusOnNewOption] = useState<boolean>(false);

    // Format data in the first render
    useEffect(() => {
        if (!params.type || params.type === types.SELECT) {
            updateParams({
                ...params,
                type: types.SINGLE_SELECT,
                possible_values: [''],
            }); // If EDITION mode, we need to transform our data
        } else if (editMode && params.editValues === undefined) {
            updateParams({
                ...params,
                editValues: transformPossibleValuesForEdit(params.possible_values),
            });
        }
    }, []);

    // From old component, not sure what it does but it's necessary
    useEffect(() => {
        if (editMode && params.editValues === undefined) {
            updateParams({
                ...params,
                editValues: transformPossibleValuesForEdit(params.possible_values),
            });
            setAutofocusOnNewOption(false);
        }
    });

    /*
      Transform something like ['a', 'b'] to:
      [
          { oldValue: 'a', newValue: 'a', indexToDisplay: 1 },
          { oldValue: 'b', newValue: 'b', indexToDisplay: 2 },
      ]
      For the edit.
      To delete, simply set newValue to null
    */
    const transformPossibleValuesForEdit = (possibleValues: Array<string>) => {
        return possibleValues.map((value, index) => ({
            oldValue: value,
            newValue: value,
            indexToDisplay: index + 1,
        }));
    };

    const onChange = (value: any, constraint: string) => {
        updateParams({ ...params, [constraint]: value });
    };

    const onSwitchClick = (type: string) => {
        const newType = type === types.SINGLE_SELECT ? types.MULTIPLE_SELECT : types.SINGLE_SELECT;
        onChange(newType, 'type');
    };

    // Change the value of an option in CREATION mode
    const changeTextFieldValue = (value: string, index: number) => {
        const possibleValues = params.possible_values;
        possibleValues[index] = value;
        onChange(possibleValues, 'possible_values');
    };

    // Add an option in CREATION mode
    const addOption = () => {
        const newPossibleValues =
            params.possible_values === undefined ? [] : params.possible_values;
        newPossibleValues.push('');
        setAutofocusOnNewOption(true);
        onChange(newPossibleValues, 'possible_values');
    };

    // Delete an option in CREATION mode
    const removeOption = (index: number) => {
        const possibleValues = params.possible_values;
        possibleValues.splice(index, 1);
        onChange(possibleValues, 'possible_values');
    };

    // Add an option in EDITION mode
    const addOptionEdit = () => {
        const editValues = params.editValues === undefined ? [] : params.editValues;
        editValues.push({
            oldValue: null,
            newValue: '',
            indexToDisplay: editValues.filter(value => value.newValue !== null).length + 1,
        });
        setAutofocusOnNewOption(true);
        onChange(editValues, 'editValues');
    };

    // Remove an option in EDITION mode, set newValue to null if it's an existing option
    const removeOptionEdit = (index: number) => {
        const { editValues } = params;

        if (editValues[index].oldValue === null) {
            editValues.splice(index, 1);
        } else {
            editValues[index].newValue = null;
        }

        let idx = 1;
        editValues.forEach(obj => {
            if (obj.newValue !== null) {
                // eslint-disable-next-line no-param-reassign
                obj.indexToDisplay = idx;
                idx += 1;
            }
        });
        onChange(editValues, 'editValues');
    };

    // Change the newValue of an option in EDITION mode
    const changeTextFieldValueEdit = (value: string, index: number) => {
        const { editValues } = params;
        editValues[index].newValue = value;
        onChange(editValues, 'editValues');
    };

    return (
        <>
            <SwitchContainer>
                <TooltipWrapper
                    text={editMode ? t('custom_fields_edit_switch_disabled_tooltip') : ''}
                >
                    <Switch
                        dataTrackId="custom_fields_select__switch"
                        name="custom_fields_select"
                        checked={params.type === types.MULTIPLE_SELECT}
                        onChange={() => {
                            if (!editMode) onSwitchClick(params.type);
                        }}
                        disabled={editMode}
                        label={t('multiple_selection')}
                    />
                </TooltipWrapper>
            </SwitchContainer>

            <PossibleValueContainerWrapper>
                {!editMode &&
                    params.possible_values &&
                    params.possible_values.map((value, index) => {
                        const hasError =
                            !isEmpty(duplicates) && duplicates[index.toString()] === value;
                        return (
                            /* CREATION */
                            <PossibleValueContainer key={index}>
                                <PossibleValueOption>
                                    <TextInput
                                        dataTrackId="custom_fields_select__create_option"
                                        value={value}
                                        onChange={newValue => changeTextFieldValue(newValue, index)}
                                        label={`${t('option')} ${index + 1}`}
                                        autoFocus={autofocusOnNewOption}
                                        error={hasError}
                                        notice={
                                            hasError
                                                ? t('custom_fields_error_duplicated_options')
                                                : ''
                                        }
                                    />
                                </PossibleValueOption>
                                <PossibleValueIcons>
                                    <IconButton
                                        appearance="outlined"
                                        variant="danger"
                                        onClick={() => removeOption(index)}
                                        icon={[
                                            FontAwesomeIconsPartooUsed.faTrash,
                                            IconPrefix.REGULAR,
                                        ]}
                                        dataTrackId="custom_fields_select__delete_option"
                                    />
                                </PossibleValueIcons>
                            </PossibleValueContainer>
                        );
                    })}
                {editMode &&
                    params.editValues &&
                    params.editValues.map((value, index) => {
                        const hasError =
                            !isEmpty(duplicates) && duplicates[index.toString()] === value.newValue;
                        return value.newValue !== null ? (
                            /* EDITION, display option if newValue not null */
                            <PossibleValueContainer key={index}>
                                <PossibleValueOption>
                                    <TextInput
                                        dataTrackId="custom_fields_select__edit_option"
                                        value={value.newValue}
                                        onChange={newValue =>
                                            changeTextFieldValueEdit(newValue, index)
                                        }
                                        label={`${t('option')} ${value.indexToDisplay}`}
                                        autoFocus={autofocusOnNewOption}
                                        error={hasError}
                                        notice={
                                            hasError ? t('custom_fields_option_already_exist') : ''
                                        }
                                    />
                                </PossibleValueOption>
                                <PossibleValueIcons>
                                    <IconButton
                                        appearance="outlined"
                                        variant="danger"
                                        onClick={() => removeOptionEdit(index)}
                                        icon={[
                                            FontAwesomeIconsPartooUsed.faTrash,
                                            IconPrefix.REGULAR,
                                        ]}
                                        dataTrackId="custom_fields_select__delete_option"
                                    />
                                </PossibleValueIcons>
                            </PossibleValueContainer>
                        ) : null;
                    })}
            </PossibleValueContainerWrapper>

            <SelectButton
                size="medium"
                variant="primary"
                appearance="outlined"
                shape="cube"
                onClick={editMode ? addOptionEdit : addOption}
                icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                dataTrackId="custom_fields_select__add_option"
            >
                {t('add_option')}
            </SelectButton>
        </>
    );
};
