import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';

import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { SpecificHourFormData, SpecificHourFormPeriod } from 'app/api/types/business';

import useHolidays from 'app/businessEditV2/hooks/useHolidays';
import { SpecificHoursAddButton } from 'app/businessEditV2/sections/SpecificHoursSection/SpecificHoursForm/SpecificHoursForm.styled';
import SpecificOpenHour from 'app/common/components/OpenHour/SpecificOpenHour';

type Props = {
    specificHours: SpecificHourFormData;
    onChange: any;
    errors: Record<string, any>;
    disabled: boolean;
    resetBusinessApiResponse: () => void;
    timeSlotReference?: Array<string>;
    openTimeSlotReferenceModal: () => void;
    scrollToRef: () => void;
};

const SpecificHours = ({
    specificHours,
    onChange,
    errors,
    disabled,
    resetBusinessApiResponse,
    timeSlotReference,
    openTimeSlotReferenceModal,
    scrollToRef,
}: Props) => {
    const { t } = useTranslation();

    const { data: holidays } = useHolidays();

    // Specific Hours
    const addSpecificHour = () => {
        specificHours.unshift({
            starts_at: '',
            ends_at: '',
            open_hours: [],
            open: false,
        });
        onChange(specificHours);
        resetBusinessApiResponse();
        scrollToRef();
    };
    const deleteSpecificHour = (specificHourIndex: number) => {
        specificHours.splice(specificHourIndex, 1);
        onChange(specificHours);
        resetBusinessApiResponse();
    };
    const duplicateSpecificHour = (specificHourIndex: number) => {
        const specificHourToDuplicate = specificHours[specificHourIndex];
        const specificHourDuplicate = {
            ...specificHourToDuplicate,
            ...{ open_hours: [...specificHourToDuplicate.open_hours] },
        };
        specificHours.splice(specificHourIndex, 0, specificHourDuplicate);
        onChange(specificHours);
        resetBusinessApiResponse();
    };

    // Controlled component
    const onSpecificHourChange = (specificHour: SpecificHourFormPeriod, index: number) => {
        const result = [...specificHours];
        result[index] = specificHour;
        onChange(result);
    };

    const applyDefaultHours = (index: number, defaultHours: Array<string>) => {
        if (!defaultHours || !openTimeSlotReferenceModal) {
            return;
        }
        if (defaultHours?.[0]?.length) {
            const result = [...specificHours];
            result[index].open_hours = defaultHours;
            result[index].open = true;
            onChange(result);
        } else {
            openTimeSlotReferenceModal();
        }
    };

    // Errors
    const mapErrorsToTimeWindowIndexes = (errors): Array<any> => {
        if (isEmpty(errors)) {
            return [];
        }

        const newErrors: Array<string> = [];
        let index = 0;
        let openIndex = 0;
        let closedIndex = 0;
        for (const specificHour of specificHours) {
            if (specificHour.open) {
                if (errors?.open?.[openIndex]) {
                    newErrors[index] = errors.open[openIndex];
                }
                openIndex++;
            } else {
                if (errors?.close?.[closedIndex]) {
                    newErrors[index] = errors.close[closedIndex];
                }
                closedIndex++;
            }
            index++;
        }

        return newErrors;
    };
    const errorsByIndex = mapErrorsToTimeWindowIndexes(errors);

    return (
        <div>
            {!disabled && (
                <SpecificHoursAddButton
                    icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                    variant="primary"
                    appearance="outlined"
                    shape="cube"
                    size="medium"
                    onClick={addSpecificHour}
                    disabled={disabled}
                    tooltip={disabled ? t('field_read_only') : ''}
                    dataTrackId="visibility_location__form_specific_hours__add_specific_hours_button"
                >
                    {t('add_business_specific_hours')}
                </SpecificHoursAddButton>
            )}
            {specificHours.map((specificHour, index) => {
                const bankHolidayName = holidays?.holidays.find(
                    holiday =>
                        holiday.date === specificHour.starts_at &&
                        holiday.date === specificHour.ends_at,
                )?.name;
                return (
                    <SpecificOpenHour
                        key={`new_specific_hour_${index}`}
                        disabled={disabled}
                        bankHolidayName={bankHolidayName}
                        specificHour={specificHour}
                        duplicateSpecificHour={() => duplicateSpecificHour(index)}
                        deleteSpecificHour={() => deleteSpecificHour(index)}
                        applyDefaultHours={() => applyDefaultHours(index, timeSlotReference ?? [])}
                        onChange={specificHour => onSpecificHourChange(specificHour, index)}
                        errors={errorsByIndex[index] || {}}
                    />
                );
            })}
        </div>
    );
};

export default SpecificHours;
