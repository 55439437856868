import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba } from '@partoohub/branding';

import { HEADER_SECTION_HEIGHT } from 'app/businessEditV2/components/BusinessEditV2Content/BusinessEditV2Content.styled';

export const BusinessStatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: ${HEADER_SECTION_HEIGHT}px;
    min-height: ${HEADER_SECTION_HEIGHT}px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.theme.background};
    box-shadow: 0 2px 10px ${convertToRgba(HEX_COLORS.black, 0.15)};
`;

export const BusinessStatusBottom = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    padding: 20px 24px;
    align-items: center;
    position: relative;
`;
