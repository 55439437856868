import React from 'react';

export const GoogleStore = () => {
    return (
        <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_ii_27377_145238)">
                <path
                    d="M1.09748 1.12969C0.865581 1.37609 0.728515 1.75849 0.728515 2.25369V19.9465C0.728515 20.4425 0.865581 20.8241 1.09748 21.0705L1.15645 21.1281L11.0291 11.2169V11.1001V10.9833L1.15645 1.07129L1.09748 1.12969Z"
                    fill="url(#paint0_linear_27377_145238)"
                />
                <path
                    d="M14.3197 14.5211L11.0293 11.2163V11.0995V10.9827L14.3204 7.67871L14.3946 7.72111L18.2937 9.94511C19.407 10.5803 19.407 11.6195 18.2937 12.2555L14.3946 14.4795L14.3197 14.5211Z"
                    fill="url(#paint1_linear_27377_145238)"
                />
                <g filter="url(#filter1_i_27377_145238)">
                    <path
                        d="M14.3946 14.4793L11.0285 11.1001L1.09766 21.0705C1.46423 21.4609 2.07066 21.5089 2.75359 21.1201L14.3946 14.4793Z"
                        fill="url(#paint2_linear_27377_145238)"
                    />
                </g>
                <path
                    d="M14.3946 7.72087L2.75359 1.08087C2.07066 0.691272 1.46423 0.740072 1.09766 1.13047L11.0293 11.1009L14.3946 7.72087Z"
                    fill="url(#paint3_linear_27377_145238)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_ii_27377_145238"
                    x="0.728516"
                    y="0.812012"
                    width="18.4004"
                    height="20.5767"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="-0.101123" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_27377_145238"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.101123" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_innerShadow_27377_145238"
                        result="effect2_innerShadow_27377_145238"
                    />
                </filter>
                <filter
                    id="filter1_i_27377_145238"
                    x="1.09766"
                    y="11.1001"
                    width="13.2969"
                    height="10.2886"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="-0.101123" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_27377_145238"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_27377_145238"
                    x1="10.154"
                    y1="2.06649"
                    x2="-3.27153"
                    y2="15.4398"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00A0FF" />
                    <stop offset="0.0066" stopColor="#00A1FF" />
                    <stop offset="0.2601" stopColor="#00BEFF" />
                    <stop offset="0.5122" stopColor="#00D2FF" />
                    <stop offset="0.7604" stopColor="#00DFFF" />
                    <stop offset="1" stopColor="#00E3FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_27377_145238"
                    x1="19.7429"
                    y1="11.0995"
                    x2="0.461428"
                    y2="11.0995"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE000" />
                    <stop offset="0.4087" stopColor="#FFBD00" />
                    <stop offset="0.7754" stopColor="#FFA500" />
                    <stop offset="1" stopColor="#FF9C00" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_27377_145238"
                    x1="12.5666"
                    y1="12.9352"
                    x2="-5.64009"
                    y2="31.0711"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF3A44" />
                    <stop offset="1" stopColor="#C31162" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_27377_145238"
                    x1="-1.40346"
                    y1="-4.75929"
                    x2="6.72632"
                    y2="3.33888"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#32A071" />
                    <stop offset="0.0685" stopColor="#2DA771" />
                    <stop offset="0.4762" stopColor="#15CF74" />
                    <stop offset="0.8009" stopColor="#06E775" />
                    <stop offset="1" stopColor="#00F076" />
                </linearGradient>
            </defs>
        </svg>
    );
};
