import styled from '@emotion/styled';

export const Spacer = styled.div`
    height: 16px;
`;

export const AccountImportModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
`;
