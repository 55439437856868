import { useMemo } from 'react';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

export const TOPICS_FILTER_PARAMS = 'topics';

const topicToApiFilter = {
    news: 'standard',
    covid: 'alert',
    offer: 'offer',
    event: 'event',
};

export type TopicsObjectType = {
    news: boolean;
    covid: boolean;
    offer: boolean;
    event: boolean;
};

const defaultTopics: TopicsObjectType = {
    news: false,
    covid: false,
    offer: false,
    event: false,
};

export default function useTopicsFilter() {
    const [value, setValue] = useStateQueryParams(TOPICS_FILTER_PARAMS);

    return useMemo(() => {
        const parsedValue: TopicsObjectType = value
            ? value.split(',').reduce(
                  (topicsObject, type) => {
                      topicsObject[type] = true;
                      return topicsObject;
                  },
                  { ...defaultTopics },
              )
            : { ...defaultTopics };

        const searchParamSetter = (topicsObject: TopicsObjectType) => {
            setValue(
                Object.keys(topicsObject)
                    .filter(t => topicsObject[t])
                    .toString(),
            );
        };

        return [
            parsedValue,
            searchParamSetter,
            value
                ? {
                      topic_type__in: Object.keys(parsedValue)
                          .filter(t => parsedValue[t])
                          .filter(t => topicToApiFilter[t])
                          .map(t => topicToApiFilter[t])
                          .toString(),
                  }
                : {},
        ];
    }, [value]);
}
