import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import { useDispatch } from 'react-redux';

import { AccountPartner } from 'app/api/types/account';
import AccountAPI from 'app/api/v2/api_calls/accountApiCalls';
import { useImportPartnerConnectionOAuthAccount } from 'app/businessConnection/hooks/useImportPartnerConnectionOAuthAccount';
import { partnerToPartnerName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import { NUMBER_IMPORTED_OAUTH_ACCOUNTS } from 'app/common/data/queryKeysConstants';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import { platformToOAuthUrl } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/screens/TypePlatform/ConnectionBanner';

import { displayConnectionModal } from 'app/states/knowledge/reducers/businessConnection/connection';

import { PartnerButton } from './ConnectionButton.styled';
import { AccountPartnerName } from '../../../../admin/src/content/tools/pages/DiffusionCleaner/components/PartnerIcon';
import ConnectionButtonLogo from '../commonOld/ConnectionButtonLogo';

interface Props {
    partner: AccountPartner;
    loading?: boolean;
    disabled?: boolean;
    message?: string;
    businessId?: string;
    onHideClick?: () => void;
}

const ConnectionButton = ({
    partner,
    loading = false,
    disabled = false,
    message = 'connection_required',
    businessId,
    onHideClick,
}: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { data, isLoading } = useQuery(
        NUMBER_IMPORTED_OAUTH_ACCOUNTS,
        AccountAPI.getNumberImportedOAuthAccounts,
    );

    const partnerName = partnerToPartnerName[partner];
    const connectionLink = platformToOAuthUrl[partnerName as AccountPartnerName];
    const { startOAuthFlow, displayImportModal } =
        useImportPartnerConnectionOAuthAccount(connectionLink);

    useEffect(() => {
        if (displayImportModal) if (onHideClick) onHideClick();
    }, [displayImportModal]);

    if (isLoading) {
        return <></>;
    }

    const numberImportedOAuthAccounts = {
        facebook: data?.facebook || 0,
        google: data?.google_my_business || 0,
    };

    const handleClick = () => {
        const hasOAuthAccounts = numberImportedOAuthAccounts[partnerName as AccountPartnerName] > 0;

        if (onHideClick || !hasOAuthAccounts) startOAuthFlow(undefined, ['connection_settings']);
        if (businessId) dispatch(displayConnectionModal(businessId, partner));
    };

    return (
        <TooltipWrapper text={disabled ? t(`no_${partnerName}_account_tooltip`) : ''}>
            <PartnerButton
                dataTrackId="connection_button"
                size="medium"
                variant="secondary"
                shape="cube"
                disabled={disabled}
                partner={partnerName}
                onClick={handleClick}
                icon={
                    <ConnectionButtonLogo
                        loading={loading}
                        partnerName={partnerName}
                        disabled={disabled}
                    />
                }
                appearance="outlined"
                full
            >
                {loading ? t('loading') : t(message || '')}
            </PartnerButton>
        </TooltipWrapper>
    );
};

export default ConnectionButton;
