import { useCallback } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import { APP_PRODUCTS, PRESENCE_MANAGEMENT, REVIEW_MANAGEMENT } from 'app/common/data/productIds';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { CompetitiveBenchmarkPage } from 'app/pages/analytics/CompetitiveBenchmark';
import { useHasReviewAnalyticsV2 } from 'app/pages/analytics/hooks';
import { ReviewAnalyticsPage } from 'app/pages/analytics/ReviewAnalytics';
import { ReviewAnalyticsV2Page } from 'app/pages/analytics/ReviewAnalyticsV2';
import { VisibilityAnalyticsV2Page } from 'app/pages/analytics/VisibilityAnalyticsV2';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import {
    ANALYTICS_REVIEW_PATH,
    ANALYTICS_VISIBILITY_PATH,
    COMPETITIVE_BENCHMARK_PATH_TOKEN,
    NOT_FOUND_PATH,
    REVIEWS_PATH_TOKEN,
    VISIBILITY_PATH_TOKEN,
} from 'app/routing/routeIds';

import { AnalyticsHeader } from './headers';

export const AnalyticsRoutes = () => {
    const { data: me } = useMe();
    const userData = me ?? null;
    const meRole = me ? me.role : null;

    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);

    const hasReviewAnalyticsV2 = useHasReviewAnalyticsV2();

    const hasPresenceManagement = !!userData?.sidebar_products?.includes(
        APP_PRODUCTS[PRESENCE_MANAGEMENT],
    );
    const hasReviewManagement = !!userData?.sidebar_products?.includes(
        APP_PRODUCTS[REVIEW_MANAGEMENT],
    );

    const getDefaultAnalyticsPath = useCallback(() => {
        if (hasPresenceManagement) {
            return ANALYTICS_VISIBILITY_PATH;
        }
        if (hasReviewManagement) {
            return ANALYTICS_REVIEW_PATH;
        }
        return ANALYTICS_VISIBILITY_PATH;
    }, []);

    const routes = [
        {
            index: true,
            element: <Navigate replace to={getDefaultAnalyticsPath()} />,
        },
        {
            path: VISIBILITY_PATH_TOKEN,
            element: (
                <ProtectedRoute userRole={meRole}>
                    <VisibilityAnalyticsV2Page />
                </ProtectedRoute>
            ),
        },
        {
            path: REVIEWS_PATH_TOKEN,
            element: (
                <ProtectedRoute userRole={meRole}>
                    <ReviewAnalyticsPage meRole={meRole} userData={userData} />
                </ProtectedRoute>
            ),
        },
        ...(!me?.sidebar_excluded_pages.includes('competitive_benchmark')
            ? [
                  {
                      path: COMPETITIVE_BENCHMARK_PATH_TOKEN,
                      element: <CompetitiveBenchmarkPage />,
                  },
              ]
            : []),
        ...(hasReviewAnalyticsV2
            ? [
                  {
                      path: `${REVIEWS_PATH_TOKEN}/v2`,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <ReviewAnalyticsV2Page />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
        {
            path: '*',
            element: <Navigate replace to={NOT_FOUND_PATH} />,
        },
    ];

    const routesWithHeader = [
        {
            path: '/',
            element: <AnalyticsHeader />,
            children: routes,
        },
    ];
    return useRoutes(isNewSidebarEnabled ? routesWithHeader : routes);
};
