import { HEX_COLORS } from '@partoohub/branding';
import { Dialog, Modal, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { CardTitle } from 'app/pages/analytics/VisibilityAnalyticsV2/components/CardTitle/CardTitle';
import {
    CardTitleContainer,
    ExportAdviceContainer,
    ExportAdviceDescription,
    KeywordsCardContainer,
    ListItem,
    ListItemKeyContainer,
    ListItemValue,
    TooltipModalSubtitle,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/Keywords/KeywordsCard/QueriesCard/ViewMore/ViewMoreModal/ViewMoreModal.styled';
import { Keyword } from 'app/pages/analytics/VisibilityAnalyticsV2/types/keyword';

type Props = {
    keywords: Array<Keyword>;
    showModal: boolean;
    closeModal: () => void;
};

export const ViewMoreModalModal = ({ showModal, closeModal, keywords }: Props) => {
    const { t } = useTranslation();
    const MAX_NUMBER_TO_DISPLAY = 10;
    const colorCard = {
        hex: HEX_COLORS.primary,
        rgba: 'rgba(0, 133, 242, 0.12)',
    };

    return (
        <Modal isOpen={showModal} closeModal={closeModal}>
            <Dialog dataTrackId="analytics_visibility__view_more__dialog" title={t('queries')}>
                <TooltipModalSubtitle variant="bodyMSemibold" as="span" color="secondary">
                    {t('search_terms')}
                </TooltipModalSubtitle>
                <CardTitleContainer>
                    <CardTitle color={colorCard} icon={<i className={'fa fa-fas fa-trophy'}></i>}>
                        {t('top_searched_keywords')}
                    </CardTitle>
                </CardTitleContainer>
                <KeywordsCardContainer>
                    {keywords.slice(0, MAX_NUMBER_TO_DISPLAY).map((keyword, index) => (
                        <ListItem key={keyword.keyword}>
                            <ListItemKeyContainer>
                                <Text variant="bodyMRegular" as="span">{`${index + 1}. `}</Text>
                                <Text
                                    variant="bodyMSemibold"
                                    as="span"
                                >{`${keyword.keyword}`}</Text>
                            </ListItemKeyContainer>
                            <ListItemValue variant="bodyMSemibold" as="span" color="secondary">
                                {keyword.count ? keyword.count : null}
                            </ListItemValue>
                        </ListItem>
                    ))}
                </KeywordsCardContainer>
                <ExportAdviceContainer>
                    <i className="fa fa-fas fa-info-circle"></i>
                    <ExportAdviceDescription>
                        {t('export_analytics_full_list')}
                    </ExportAdviceDescription>
                </ExportAdviceContainer>
            </Dialog>
        </Modal>
    );
};
