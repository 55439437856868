import React from 'react';

import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { CustomFieldsQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';
import { customFieldsType as types } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/services';

import {
    CatalogModalContainer,
    CatalogModalGrid,
    CatalogModalSubtitle,
    CatalogModalTitle,
} from './CatalogModal.styled';
import TypeItem from './TypeItem';

export const catalogTypes = [
    types.BOOLEAN,
    types.NUMBER,
    types.TEXT,
    types.SELECT,
    types.MULTIPLE_SELECT_IMAGE,
    types.IMAGES_UPLOADER,
];

const CatalogModal = () => {
    const { t } = useTranslation();
    const [, setCreateCustomField] = useStateQueryParams(CustomFieldsQueryKeys.CREATE);

    const onClick = (type: string) => {
        setCreateCustomField(type);
    };

    return (
        <CatalogModalContainer>
            <CatalogModalTitle variant="heading4">
                {t('cf_empty_placeholder_button')}
            </CatalogModalTitle>

            <CatalogModalSubtitle color="secondary">
                {t('custom_fields_create_modal_subtitle')}
            </CatalogModalSubtitle>

            <CatalogModalGrid>
                {catalogTypes.map(type => (
                    <TypeItem type={type} onClick={onClick} key={type} />
                ))}
            </CatalogModalGrid>
        </CatalogModalContainer>
    );
};

export default CatalogModal;
