import { useEffect, useRef, useState } from 'react';

import { IconPrefix, Option, Section } from '@partoohub/ui';

import {
    CommentMenuButton,
    StyledMenu,
    StyledMenuContainer,
    StyledMenuLabel,
    StyledReviewCardMenu,
} from './ReviewCardMenu.styled';

interface Props {
    dataTrackId: string;
    sections: Array<Section>;
    onOptionClick: (option: Option) => void;
    tooltip?: string;
}

const ReviewCardMenuComponent = ({ dataTrackId, sections, tooltip, onOptionClick }: Props) => {
    const [isMenuShown, setIsMenuShown] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const menuButtonRef = useRef<HTMLButtonElement>(null);

    const closeMenu = (e: Event) => {
        if (
            menuRef.current &&
            menuButtonRef.current &&
            !menuRef.current.contains(e.target as Node) &&
            !menuButtonRef.current.contains(e.target as Node)
        ) {
            setIsMenuShown(false);
        }
    };

    useEffect(() => {
        if (isMenuShown) document.addEventListener('mousedown', closeMenu);
        return () => {
            document.removeEventListener('mousedown', closeMenu);
        };
    }, [isMenuShown]);

    return (
        <StyledReviewCardMenu>
            <CommentMenuButton
                ref={menuButtonRef}
                dataTrackId={dataTrackId}
                appearance="outlined"
                onClick={() => setIsMenuShown(!isMenuShown)}
                icon={['fa-ellipsis-v', IconPrefix.SOLID]}
                tooltip={tooltip}
                size="small"
            />
            {isMenuShown && (
                <StyledMenuContainer ref={menuRef}>
                    <StyledMenu
                        sections={sections}
                        isChecked={() => false}
                        onChange={onOptionClick}
                        position="bottom"
                    />
                </StyledMenuContainer>
            )}
        </StyledReviewCardMenu>
    );
};

export const ReviewCardMenu = Object.assign(ReviewCardMenuComponent, {
    Label: StyledMenuLabel,
});
