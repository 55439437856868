import styled from '@emotion/styled';

/**
 * Container for a stat item, no styling applied.
 * Use this to wrap your shortcut content.
 */
export const StatContainer = styled.div`
    display: flex;
    height: 310px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    transition: all ease 0.15s;
    background-color: white;
    box-shadow: 0 2px 16px 0 rgba(127, 142, 164, 0.12);

    &:hover {
        box-shadow: 0 2px 16px 0 rgba(127, 142, 164, 0.24);
    }
`;

/**
 * Default content for a stat item.
 * You can use a new one if you want a new specific style.
 */
export const StatContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px;

    &:hover {
        cursor: pointer;
    }
`;

export const StatHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 24px;
`;

export const StatProductTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const StatIconSeeMore = styled.div`
    margin-right: -12px;
`;

export const StatData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
`;

type StatDescriptionProps = {
    small?: boolean;
};

export const StatDescription = styled.div<StatDescriptionProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    height: ${({ small }) => (small ? '104px' : '86px')};
    border-radius: 8px;
    border: solid 1px ${({ theme }) => theme.colors.default.alpha};
    text-align: center;
    padding: 16px;
`;

type BannerProps = {
    bannerUrl: string;
    small?: boolean;
};

export const StatsBanner = styled.div<BannerProps>`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${({ small }) => (small ? '50px' : '70px')};
    background: url(${({ bannerUrl }) => bannerUrl}) no-repeat bottom;
    background-size: 100% ${({ small }) => (small ? '50px' : '70px')};
`;
