import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { Icon } from '@partoohub/ui';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

export const StyledMultiImagePhoto = styled.div<{
    url: string;
    isDragging: boolean;
}>`
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    background-image: url('${props => props.url}');
    background-position: center center;
    background-size: contain;
    background-color: ${HEX_COLORS.black};
    background-repeat: no-repeat;
    aspect-ratio: 1 / 1;
    cursor: ${props => (props.isDragging ? 'grabbing' : 'pointer')};
`;

export const StyledPhotoOverlayContent = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const StyledPhotoOverlayBackground = styled.div<{ visible: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 8px 8px 0 8px;
    border-radius: 4px;

    ${({ visible }) =>
        visible &&
        css`
            background: rgba(0, 0, 0, 0.75);
        `}
`;

export const StyledMainPhotoChip = styled.div<{ visible: boolean }>`
    display: flex;
    word-break: break-word;
    gap: 8px;
    align-items: center;
    border-radius: 4px;
    padding: 4px 8px;
    background: rgba(0, 0, 0, 0.75);

    ${({ visible }) =>
        !visible &&
        css`
            width: 0px;
            visibility: hidden;
            text-wrap: nowrap;
            padding: 4px 0;
        `}
`;

export const StyledAutoCroppedChip = styled.div`
    border-radius: 4px;
    padding: 4px 8px;
    margin: 0 -8px;
    background: rgba(0, 0, 0, 0.75);
    text-align: center;
`;

export const StyledTrashIcon = styled(Icon)`
    cursor: pointer;
    &:hover {
        color: ${({ theme }) => theme.colors.danger.initial};
    }
`;

export const StyledCropIcon = styled(Icon)`
    cursor: pointer;
    &:hover {
        color: ${({ theme }) => theme.colors.primary.initial};
    }
`;

export const StyledTooltip = styled(TooltipWrapper)<{ visible: boolean }>`
    ${({ visible }) =>
        !visible &&
        css`
            display: none;
        `}
`;
