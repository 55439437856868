import { useTranslation } from 'react-i18next';

import { useTodosItems } from 'app/pages/Homepage/hooks/todos/useTodosItems';

import {
    HomepageTodosContainer,
    HomepageTodosGrid,
    HomepageTodosTitle,
} from './HomepageTodos.styled';

export const HomepageTodos = () => {
    const { t } = useTranslation();
    const todosItems = useTodosItems();
    const smallItems = todosItems.length === 3;

    if (!todosItems.length) {
        return null;
    }

    return (
        <HomepageTodosContainer>
            <HomepageTodosTitle variant="heading6">{t('homepage_todo_title')}</HomepageTodosTitle>

            <HomepageTodosGrid small={smallItems}>
                {todosItems.map(({ component: Component, key }) => (
                    <Component key={key} small={smallItems} />
                ))}
            </HomepageTodosGrid>
        </HomepageTodosContainer>
    );
};
