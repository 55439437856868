import { STATIC_BASE_URL } from 'app/config';

import {
    AccountSpinner,
    PartnerConnectionButtonIcon,
} from './ConnectionButtonLogo.styled';

interface Props {
    loading?: boolean;
    disabled?: boolean;
    partnerName: string;
}

const imgName = {
    google: 'google_white_background',
    facebook: 'facebook',
};

const ConnectionButtonLogo = ({
    loading = false,
    partnerName,
    disabled = false,
}: Props) => (
    <PartnerConnectionButtonIcon>
        {loading || !partnerName ? (
            <AccountSpinner />
        ) : (
            <img
                src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/${
                    imgName[partnerName]
                }${disabled ? '_disabled' : ''}.svg`}
                alt=""
            />
        )}
    </PartnerConnectionButtonIcon>
);

export default ConnectionButtonLogo;
