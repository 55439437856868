import React, { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { useQuery, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls/camel';

import { WEBSOCKET_LIVE_UPDATES_URL } from 'app/config';

import { AUTH_TOKEN } from '../data/queryKeysConstants';

const useRetryOnClose = (websocket: WebSocket | null) => {
    // we don't want to render when retryCount changes, use a ref
    const retryCount = useRef<number>(0);
    const queryClient = useQueryClient();

    // If the connection is closed while the component is mounted, reopen it.
    useEffect(() => {
        if (websocket === null) {
            return;
        }

        let cancel = false;

        const listener = async () => {
            if (retryCount.current > 3) {
                console.error('Max retry for websocket connection exceeded.');
                return;
            }

            setTimeout(
                () => {
                    if (cancel) {
                        return;
                    }

                    retryCount.current += 1;

                    // Re-fetch auth token which will trigger the creation of a new connection
                    queryClient.invalidateQueries(AUTH_TOKEN);
                },
                1000 * retryCount.current * retryCount.current,
            );
        };

        const resetRetryCount = () => {
            retryCount.current = 0;
        };

        websocket.addEventListener('close', listener);
        websocket.addEventListener('open', resetRetryCount);

        return () => {
            cancel = true;
            websocket.removeEventListener('close', listener);
            websocket.removeEventListener('open', resetRetryCount);
        };
    }, [websocket]);
};

export const WebsocketConnectionContext = React.createContext<WebSocket | null>(null);

export const WebsocketConnectionProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [websocket, setWebsocket] = useState<WebSocket | null>(null);

    const websocketConnectionContextValue = useMemo(() => websocket, [websocket]);

    const { data, isFetching } = useQuery(AUTH_TOKEN, api.authToken.getAuthToken, {
        onError: error => console.error(error),
        enabled: true,
    });

    useRetryOnClose(websocket);

    const token = data?.token;

    // On mount, create a new websocket connection.
    // When token changes, create a new websocket connection and close the previous one.
    // On unmount, close the websocket connection.
    useEffect(() => {
        if (token === undefined || isFetching) {
            // token not fetched yet or fetching a new one
            return;
        }

        const ws = new WebSocket(`${WEBSOCKET_LIVE_UPDATES_URL}?token=${token}`);
        ws.addEventListener('error', console.error); // log on Sentry
        setWebsocket(ws);

        return () => ws.close();
    }, [token, isFetching]);

    return (
        <WebsocketConnectionContext.Provider value={websocketConnectionContextValue}>
            {children}
        </WebsocketConnectionContext.Provider>
    );
};
