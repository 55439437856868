import { Dialog, Loader, Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import EmptyWithShadowImage from 'app/common/designSystem/components/atoms/svg/modalImages/emptyWithShadow';

import { StyledHeader } from './PostPublishingModal.styled';

type Props = {
    isOpen: boolean;
};

export default function PostPublishingModal({ isOpen }: Props) {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} closeModal={() => undefined}>
            <Dialog
                dataTrackId=""
                header={
                    <StyledHeader>
                        <EmptyWithShadowImage />
                        <Loader variant="spinner" size="100px" />
                    </StyledHeader>
                }
                title={t('post_publish_processing')}
                size="medium"
            />
        </Modal>
    );
}
