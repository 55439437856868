import React from 'react';

import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import EditSection from 'app/businessEditV2/components/EditSection';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import UnsavedModal from 'app/businessEditV2/components/UnsavedModal';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useDescriptionSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useDescriptionSectionColor';
import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import DescriptionsForm from './DescriptionsForm';
import DescriptionsSectionContent from './DescriptionsSectionContent';

const DescriptionsSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const {
        showModal,
        setShowModal,
        closeSafeModal,
        showUnsavedModal,
        setShowUnsavedModal,
        useDirtyUpdate,
    } = useUnsavedModal(SectionNames.DESCRIPTIONS);

    const sectionColor = useDescriptionSectionColor();
    const { t } = useTranslation();

    const { isLoading } = useBusiness();

    if (isLoading) {
        return <EditSectionLoader />;
    }

    return (
        <div
            ref={ref}
            data-track="visibility_location__section_descriptions"
            data-intercom-target="visibility_location__section_descriptions"
        >
            <EditSection
                title={t('business_description')}
                description={
                    <DescriptionsSectionContent colorState={sectionColor} />
                }
                onClick={() => setShowModal(true)}
                colorState={sectionColor}
                sectionName={SectionNames.DESCRIPTIONS}
            />
            <FullScreenModal
                show={showModal}
                childrenRight={
                    <EditFormDescription
                        title={t('business_edit_title_descriptions')}
                        description={t(
                            'business_edit_description_descriptions',
                        )}
                        advice={t('business_edit_advice_descriptions')}
                    />
                }
                childrenLeft={
                    <>
                        <DescriptionsForm
                            closeModal={() => setShowModal(false)}
                            useDirtyUpdate={useDirtyUpdate}
                        />
                        <UnsavedModal
                            show={showUnsavedModal}
                            cancel={() => setShowUnsavedModal(false)}
                            validate={() => setShowModal(false)}
                        />
                    </>
                }
                onHideClick={closeSafeModal}
            />
        </div>
    );
});

export default DescriptionsSection;
