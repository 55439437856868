import { replace } from '@lagunovsky/redux-react-router';
import { call, put } from 'redux-saga/effects';

import { ConnectionLogoutData } from 'app/api/types/connection';
import api from 'app/api/v2/api_calls';
import storeError from 'app/common/sagas/storeError';
import getLang from 'app/common/services/getLang';
import IntercomWrapper from 'app/common/services/intercomWrapper';
import i18n from 'app/common/translations/i18n';
import { Saga } from 'app/common/types';
import { CONNECTION_PATH } from 'app/routing/routeIds';
import { sdkBridge } from 'app/SDKBridge';
import {
    logoutRequest,
    logoutRequestFailure,
    logoutRequestSuccess,
    logoutResetReducersData,
} from 'app/states/connection/reducers/login';

export const logoutCleanSaga = function* (): Saga {
    yield put(logoutResetReducersData());
    IntercomWrapper.shutdown(false);
    sdkBridge.sendUserIsLoggedOut();
};

const logoutSaga = function* (): Saga {
    yield put(logoutRequest());

    try {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { next_url }: ConnectionLogoutData = yield call(api.connection.logout);
        yield put(logoutRequestSuccess());
        yield* logoutCleanSaga();

        // @ts-ignore
        if (window.gapi) {
            // @ts-ignore
            const auth2 = window.gapi.auth2.getAuthInstance();

            if (auth2) {
                auth2
                    .signOut()
                    .then(() => {
                        auth2
                            .disconnect()
                            .then(() => {
                                console.warn('Google OAuth Logout');
                            })
                            .catch(err => console.error(err));
                    })
                    .catch(err => console.error(err));
            }
        }

        if (!next_url || next_url === '') {
            const browserLang = getLang();

            if (i18n.language !== browserLang) {
                i18n.changeLanguage(browserLang);
            }

            yield put(replace(CONNECTION_PATH));
        } else {
            window.location.href = next_url;
        }
        /* eslint-enable */
    } catch (error) {
        yield* storeError(logoutRequestFailure, error);
    }
};

export default logoutSaga;
