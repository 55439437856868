import styled from '@emotion/styled';

import Flexbox from 'app/styles/utils/flexbox';

export const ButtonSeparator = styled.div`
    height: 16px;
    border-left: 1px solid #7f8ea41f;
    margin-top: 12px;
`;

export const StyledButtonSection = styled(Flexbox)<{ disabled: boolean }>`
    gap: 4px;
    ${({ disabled = false }) =>
        disabled &&
        `
        pointer-events: none;
        opacity: 0.5;
        ${ButtonSeparator} {
            opacity: 1;
        }
    `}
`;
