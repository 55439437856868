import React from 'react';

import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import LostPasswordForm from 'app/connection/components/LostPasswordForm';

export const LostPasswordPage = () => {
    const { t } = useTranslation();
    return (
        <>
            <DynamicPageHeader title={t('send_reset_password_link')} />
            <LostPasswordForm />
        </>
    );
};
