import { FontAwesomeIconsPartooUsed, IconPrefix, Modal } from '@partoohub/ui';

import { STATIC_BASE_URL } from 'app/config';

import { FullScreen, LeftPart, RightPart, StyledIconButton } from './FullScreenModal.styled';

type Props = {
    childrenLeft: JSX.Element;
    childrenRight?: JSX.Element;
    show: boolean;
    onHideClick: () => void;
};

const FullScreenModal = ({ show, onHideClick, childrenLeft, childrenRight }: Props) => (
    <Modal isOpen={show} closeModal={onHideClick} placement="center" animation="fadeIn">
        <FullScreen>
            <LeftPart>{childrenLeft}</LeftPart>
            {childrenRight && (
                <>
                    <RightPart
                        backgroundUrl={`${STATIC_BASE_URL}/images/app/funnel/background.png`}
                    >
                        {childrenRight}
                    </RightPart>
                    <StyledIconButton
                        appearance="outlined"
                        onClick={onHideClick}
                        icon={[FontAwesomeIconsPartooUsed.faXMarkLarge, IconPrefix.SOLID]}
                        dataTrackId="close_fullscreen_modal_button"
                    />
                </>
            )}
        </FullScreen>
    </Modal>
);

export default FullScreenModal;
