import { call, put, takeEvery } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import { Saga } from 'app/common/types';

import {
    BUSINESS_EDIT_FIELDS_REQUEST,
    GetBusinessEditFieldsAction,
    getBusinessEditFieldsFailure,
    getBusinessEditFieldsSuccess,
} from '../reducers/businessEditFields';

function* BusinessEditFields({ businessId }: GetBusinessEditFieldsAction) {
    try {
        const businessFields = yield call(
            api.business.getBusinessEditFields,
            businessId,
        );
        yield put(getBusinessEditFieldsSuccess(businessFields));
    } catch (error) {
        yield put(getBusinessEditFieldsFailure(error));
    }
}

function* BusinessEditFieldsSaga(): Saga {
    yield takeEvery(BUSINESS_EDIT_FIELDS_REQUEST, BusinessEditFields);
}

export default BusinessEditFieldsSaga;
