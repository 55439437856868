import qs from 'query-string';

import { AppState } from 'app/states/reducers';
import { locationSelector } from 'app/states/router';

const DISPLAY_CONNECTION_SEARCH_BAR = 'DISPLAY_CONNECTION_SEARCH_BAR';
const HIDE_CONNECTION_SEARCH_BAR = 'HIDE_CONNECTION_SEARCH_BAR';

export const ALL_TAB = 'all';
export const TO_CONNECT_TO_GMB = 'google';
export const TO_CONNECT_TO_FACEBOOK = 'facebook';
export const CONNECTIONS_PUBLISHERS = [ALL_TAB, TO_CONNECT_TO_GMB, TO_CONNECT_TO_FACEBOOK];

export type NavValue = 'all' | 'google' | 'facebook';

// ACTION FLOW TYPES
type UpdateConnectionNavigationAction = {
    type: 'UPDATE_CONNECTION_NAVIGATION';
    navTab: NavValue;
};

type DisplayConnectionSearchBarAction = {
    type: 'DISPLAY_CONNECTION_SEARCH_BAR';
};

type HideConnectionSearchBarAction = {
    type: 'HIDE_CONNECTION_SEARCH_BAR';
};

export const displayConnectionSearchBar = (): DisplayConnectionSearchBarAction => ({
    type: DISPLAY_CONNECTION_SEARCH_BAR,
});

type FormNavigationAction =
    | UpdateConnectionNavigationAction
    | DisplayConnectionSearchBarAction
    | HideConnectionSearchBarAction;

export type ConnectionNavigationState = {
    displaySearchBar: boolean;
};

const initialState = {
    displaySearchBar: false,
};

const connectionNavReducer = (
    state: ConnectionNavigationState = initialState,
    action: FormNavigationAction,
): ConnectionNavigationState => {
    switch (action.type) {
        case DISPLAY_CONNECTION_SEARCH_BAR:
            return { ...state, displaySearchBar: true };

        case HIDE_CONNECTION_SEARCH_BAR:
            return { ...state, displaySearchBar: false };

        default:
            return state;
    }
};

// HOURS SELECTORS
export const navTabSelector = (state: AppState): NavValue => {
    const location = locationSelector(state);
    const { publisher: publisherSearchParam } = qs.parse(location.search);

    // @ts-ignore
    if (!CONNECTIONS_PUBLISHERS.includes(publisherSearchParam)) {
        return ALL_TAB;
    }

    // @ts-ignore
    return publisherSearchParam;
};

export default connectionNavReducer;
