import { ChartOptions, Tooltip } from 'chart.js';

import { COLORS_FLOW } from 'app/styles/constants/colors_flow';

// @ts-expect-error: Allow tooltip position to follow the cursor
// See: https://www.chartjs.org/docs/latest/configuration/tooltip.html#custom-position-modes
Tooltip.positioners.cursorPositioner = (_, eventPosition) => {
    return { x: eventPosition.x, y: eventPosition.y };
};

const BASE_CHART_OPTIONS: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    clip: false, // Prevent line and point to be clipped when at the edges of the chart
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                padding: 24,
                boxWidth: 16,
                boxHeight: 16,
                useBorderRadius: true,
                borderRadius: 4,
                font: {
                    size: 12,
                    weight: 600,
                },
                color: COLORS_FLOW.others.secondary,
            },
        },
    },
};

export const BAR_CHART_OPTIONS: ChartOptions = {
    ...BASE_CHART_OPTIONS,
    plugins: {
        ...BASE_CHART_OPTIONS.plugins,
        tooltip: {
            // @ts-expect-error
            position: 'cursorPositioner',
            mode: 'index', // Group points from the same abscissa into one tooltip
            backgroundColor: COLORS_FLOW.shaded.blackberry.initial,
            filter: ({ raw }) => {
                return !!raw;
            },
            callbacks: {
                label: ({ dataset, raw }) => {
                    return `${dataset.label}: ${(raw as number).toFixed(2)}%`;
                },
            },
        },
    },
    scales: {
        x: {
            stacked: true,
            grid: { display: false },
        },
        y: {
            stacked: true,
            grid: { display: false },
            min: 0,
            max: 100,
        },
    },
} as const;

export const LINE_CHART_OPTIONS: ChartOptions = {
    ...BASE_CHART_OPTIONS,
    plugins: {
        ...BASE_CHART_OPTIONS.plugins,
        tooltip: {
            intersect: false, // Display tooltip even if the cursor is not right on the point
            mode: 'index', // Group points from the same abscissa into one tooltip
            position: 'nearest', // Tooltip is placed at the point closest to the cursor
            backgroundColor: COLORS_FLOW.shaded.blackberry.initial,
            callbacks: {
                title: () => '',
                label: ({ datasetIndex, label, raw }) => {
                    const labels = label.split(',');
                    return `${labels[datasetIndex]}: ${raw}`;
                },
            },
        },
    },
    scales: {
        x: {
            grid: { display: false },
            ticks: {
                callback: function (value) {
                    // @ts-expect-error: https://www.chartjs.org/docs/latest/axes/labelling.html#creating-custom-tick-formats
                    return this.getLabelForValue(value)[0];
                },
            },
        },
        y: {
            grid: { display: false },
        },
    },
} as const;
