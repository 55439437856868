import { Modal } from '@partoohub/ui';

import { FunnelBulkInAppStepsEnum } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/data/funnelSteps';
import { useFunnelModalContext } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/hooks/FunnelModalContext';

import { AddPhotosPage } from './FunnelPages/AddPhotosPage/AddPhotosPage';
import { SelectFieldPage } from './FunnelPages/SelectFieldPage/SelectFieldPage';

export const FunnelBulkInApp = () => {
    const { showFunnel, setShowFunnel, currentStep, setCurrentStep } = useFunnelModalContext();
    const onClose = () => {
        setShowFunnel(false);
        setCurrentStep(FunnelBulkInAppStepsEnum.SELECT_FIELD);
    };

    if (!showFunnel) {
        return;
    }

    let content;

    switch (currentStep) {
        case FunnelBulkInAppStepsEnum.SELECT_FIELD:
            content = <SelectFieldPage />;
            break;
        case FunnelBulkInAppStepsEnum.ADD_PHOTOS:
            content = <AddPhotosPage />;
            break;
        default:
            return;
    }

    return (
        <Modal isOpen={showFunnel} closeModal={onClose} placement="center" animation="fadeIn">
            {content}
        </Modal>
    );
};
