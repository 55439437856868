import React from 'react';

import { Button, ButtonVariants, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { UseMutationResult } from 'react-query/types/react/types';

import { SuccessResponse } from 'app/api/types';
import { BusinessFieldsType } from 'app/api/types/business';
import { PermissionsFormType } from 'app/pages/settingsV2/subPages/Team/components/Permissions/PermissionsSettingsV1';

type Props = {
    form: PermissionsFormType;
    updatePermissions: UseMutationResult<SuccessResponse, unknown, BusinessFieldsType, unknown>;
    isHeader?: boolean;
};

const PermissionsSaveButton = ({ form, updatePermissions, isHeader = false }: Props) => {
    const { t } = useTranslation();

    const { handleSubmit } = form;

    const isLoading = updatePermissions.isLoading;
    const hasError = updatePermissions.isError;

    const getVariant = (): [string, ButtonVariants] => {
        if (isLoading) {
            return ['loading', 'secondary'];
        }
        if (hasError) {
            return ['update_error', 'danger'];
        }
        return ['save', 'primary'];
    };

    const [text, variant] = getVariant();

    const onSubmit = () => {
        if (variant !== 'secondary') {
            handleSubmit(data => {
                updatePermissions.mutate(data.businessFields);
            })();
        }
    };

    return (
        <Button
            size={isHeader ? 'medium' : 'large'}
            variant={variant}
            appearance="contained"
            shape="cube"
            dataTrackId="save-permissions"
            icon={[FontAwesomeIconsPartooUsed.faCheck, IconPrefix.SOLID]}
            onClick={onSubmit}
            disabled={variant === 'secondary'}
            full={!isHeader}
        >
            {t(text)}
        </Button>
    );
};

export default PermissionsSaveButton;
