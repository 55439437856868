import InfiniteScroll from 'react-infinite-scroller';

import { V2BusinessData } from 'app/api/types/business';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import useBusinessSearch from 'app/pages/visibility/BusinessListV2/hooks/businesses/useBusinessSearch';
import useLocationsFromBusinesses from 'app/pages/visibility/BusinessListV2/hooks/locations/useLocationsFromBusinesses';
import { BusinessListSectionContainer } from 'app/pages/visibility/BusinessListV2/sections/BusinessListSection/BusinessListSection.styled';

import BusinessCard from './BusinessCard';
import BusinessLoadingCards from './BusinessLoadingCards/BusinessLoadingCards';
import NoBusinessPlaceholder from './NoBusinessPlaceholder';

const BusinessListSection = () => {
    const enableNewGroups = useNewGroups();
    const { isFetchingNextPage, fetchNextPage, hasNextPage, isLoading, businesses, data } =
        useBusinessSearch(!enableNewGroups);

    const locations = useLocationsFromBusinesses(data);

    return (
        <BusinessListSectionContainer
            data-track="visibility_locations__list"
            data-intercom-target="visibility_locations__list"
        >
            <InfiniteScroll
                hasMore={hasNextPage && !isFetchingNextPage}
                loadMore={() => fetchNextPage()}
                useWindow={false}
                getScrollParent={() => document.getElementById('react-content')}
            >
                {businesses.map((business: V2BusinessData) => (
                    <BusinessCard
                        key={business.id}
                        business={business}
                        location={locations[business.google_location_id ?? '']}
                    />
                ))}
            </InfiniteScroll>

            {(isLoading || isFetchingNextPage) && <BusinessLoadingCards count={10} />}

            {!(isLoading || isFetchingNextPage) && !businesses.length && <NoBusinessPlaceholder />}
        </BusinessListSectionContainer>
    );
};

export default BusinessListSection;
