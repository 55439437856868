import { FontAwesomeIconsPartooUsed, LeftElementType } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import FilterSelect from 'app/common/components/FilterSelect';
import LanguagesMultiSelect from 'app/common/components/LanguagesMultiSelect';
import { useSettingsPageContext } from 'app/pages/settings/AutoReply/hooks/useSettingsPageContext';

import { StyledFiltersBar, StyledTextInput } from './FiltersBar.styled';

const FiltersBar = () => {
    const { t } = useTranslation();

    const {
        languages,
        filters,
        selectedRatings,
        onLanguageFilterChange,
        onRatingFilterChange,
        onSearchChange,
    } = useSettingsPageContext();

    const ratingFilterOptions = selectedRatings.map(({ value, label, icon }) => ({
        value,
        label: t(label),
        icon,
    }));

    const handleSearchReset = () => {
        onSearchChange('');
    };

    return (
        <StyledFiltersBar>
            <StyledTextInput
                dataTrackId=""
                value={filters.search}
                label={t('auto_reply_template_select_search_placeholder')}
                onChange={onSearchChange}
                hasOldTextFieldHeight
                leftElement={[FontAwesomeIconsPartooUsed.faSearch]}
                leftElementType={LeftElementType.Icon}
                isReset
                onReset={handleSearchReset}
            />
            <LanguagesMultiSelect
                availableLanguages={languages}
                onChange={onLanguageFilterChange}
                selectedOptions={filters.languages}
            />
            <FilterSelect
                title={t('auto_reply_review_types')}
                options={ratingFilterOptions}
                selectedOptions={filters.ratings}
                onChange={onRatingFilterChange}
            />
        </StyledFiltersBar>
    );
};

export default FiltersBar;
