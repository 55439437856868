import React, { MouseEvent, useContext, useRef, useState } from 'react';

import { css } from '@emotion/react';
import { IconPrefix } from '@partoohub/ui';
import { Interweave } from 'interweave';
import { uniqueId } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';
import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';
import { ChipMatcher } from 'app/common/services/Matchers';
import { TemplateFormContext } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/components/MessagesReplyTemplates.context';
import {
    MessageReplyTemplate,
    messageReplyTemplatePlaceholders,
} from 'app/states/messageReplyTemplate/dataTypes';

import MessageReplyTemplateForm from './MessageReplyTemplateForm';
import {
    HighlightedText,
    TemplateCard,
    TemplateCardHeader,
    TemplateCardIcon,
    TemplateCardMenu,
    TemplateCardPreview,
    TemplateCardTitle,
} from './MRTemplateCard.styled';
import useCreateMessageReplyTemplate from '../hooks/useCreateMessageReplyTemplate';
import useDeleteMessageReplyTemplate from '../hooks/useDeleteMessageReplyTemplate';
import { generateNewDuplicateTitle, getTemplateContent } from '../services/utils';

type Props = {
    template: MessageReplyTemplate;
};

export const MessageReplyTemplateCard: React.FC<Props> = ({ template }) => {
    const { t } = useTranslation();
    const content = getTemplateContent(template);
    const { mutate: deleteMessageTemplate } = useDeleteMessageReplyTemplate();
    const { mutate: createMessageTemplate } = useCreateMessageReplyTemplate();
    const textMatchers: Array<any> = messageReplyTemplatePlaceholders.map(element =>
        ChipMatcher(`\\{{${element.id}\\}}`, t(element.display)),
    );
    const { setUpdateItem, setCreatingMode } = useContext(TemplateFormContext);
    const optionButtonRef = useRef<HTMLDivElement>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const updateItem = (template: MessageReplyTemplate) => {
        setUpdateItem(template);
        setEditMode(true);
        setCreatingMode(false);
    };

    const onMenuItemClick = (option: string) => {
        if (option === 'edit') {
            // Dispatch the task for Edit action.
            updateItem(template);
        } else if (option === 'duplicate') {
            createMessageTemplate({
                title: generateNewDuplicateTitle(template.title),
                icon: template.icon,
                content: content.text,
            });
        } else if (option === 'delete') {
            // Make an api call, and remove this item from the query cache.
            setShowDeleteModal(true);
        }
    };

    const { filteringTitle } = useContext(TemplateFormContext);

    // Highlight query in template titles
    const highlightQuery = (templateTitle: string) => {
        if (!filteringTitle) return templateTitle;

        const regex = new RegExp(`(${filteringTitle})`, 'gi');
        const parts = templateTitle.split(regex);
        return parts.map((part, _) => {
            if (part.toLowerCase() === filteringTitle.toLowerCase()) {
                return <HighlightedText key={uniqueId()}>{part}</HighlightedText>;
            } else {
                return <span key={uniqueId()}>{part}</span>;
            }
        });
    };

    const [editMode, setEditMode] = useState(false);

    const onCardClick = (e: MouseEvent<HTMLDivElement>) => {
        if (optionButtonRef.current && !optionButtonRef.current.contains(e.target as Node)) {
            // emit an edit action for template.
            updateItem(template);
        }
    };

    if (editMode) {
        return <MessageReplyTemplateForm updatedTemplate={template} setEditMode={setEditMode} />;
    }

    return (
        <div>
            <ConfirmModal
                show={showDeleteModal}
                title={t('delete_reply_template')}
                content={t('delete_reply_template_modal_text')}
                confirmLabel={t('delete')}
                modalFor="delete"
                onConfirm={() => {
                    deleteMessageTemplate(template.id);
                    setShowDeleteModal(false);
                }}
                onHide={() => setShowDeleteModal(false)}
                trackId="messaging_template__delete_modal"
            />

            <TemplateCard
                onClick={onCardClick}
                data-track="messaging_template__template_card"
                data-intercom-target="messaging_template__template_card"
            >
                <TemplateCardHeader>
                    {template.icon && <TemplateCardIcon>{template.icon}</TemplateCardIcon>}
                    <TemplateCardTitle>{highlightQuery(template.title)}</TemplateCardTitle>
                </TemplateCardHeader>
                <TemplateCardPreview>
                    <Interweave content={content.text} matchers={textMatchers} />
                </TemplateCardPreview>
                <TemplateCardMenu
                    ref={optionButtonRef}
                    data-track="messaging_template__template_card_menu"
                    data-intercom-target="messaging_template__template_card_menu"
                >
                    <ActionButtonWithMenu
                        dataTrackId="mr_template_messaging__action_button"
                        icon={['fa-ellipsis-v', IconPrefix.SOLID]}
                        appearance="outlined"
                        options={[
                            {
                                name: 'edit',
                                label: (
                                    <MenuLabelItem
                                        text={t('edit')}
                                        icon={['fa-marker', IconPrefix.REGULAR]}
                                    />
                                ),
                                value: 'edit',
                                dataTrack: 'messaging_template__click__template_edit_button',
                            },
                            {
                                name: 'duplicate',
                                label: (
                                    <MenuLabelItem
                                        text={t('duplicate')}
                                        icon={['fa-clone', IconPrefix.REGULAR]}
                                    />
                                ),
                                value: 'duplicate',
                                dataTrack: 'messaging_template__click__template_duplicate_button',
                            },
                            {
                                name: 'delete',
                                label: (
                                    <MenuLabelItem
                                        text={t('delete')}
                                        icon={['fa-trash', IconPrefix.REGULAR]}
                                        color="danger"
                                    />
                                ),
                                value: 'delete',
                                dataTrack: 'messaging_template__click__template_delete_button',
                            },
                        ]}
                        onMenuClick={onMenuItemClick}
                        cssMenu={css`
                            top: 50px;
                            right: 0px;
                            left: unset;
                        `}
                        tooltip={t('more')}
                    />
                </TemplateCardMenu>
            </TemplateCard>
        </div>
    );
};
