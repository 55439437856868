import { useMutation, useQueryClient } from 'react-query';

import { FoodMenuSectionType } from 'app/api/types/food_menu';
import FoodMenu from 'app/api/v2/api_calls/foodMenuApiCalls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { BUSINESS } from 'app/common/data/queryKeysConstants';

export default function useMenuSectionUpdate(
    onSuccess: () => void = () => undefined,
    onError: (ServicesGetErrorType) => void = () => undefined,
    ignoreInvalidateQueries = false,
) {
    const queryClient = useQueryClient();
    return useMutation(
        ({
            businessId: mutationBusinessId,
            sections: mutationSections,
        }: {
            businessId: string;
            sections: Array<FoodMenuSectionType>;
        }) =>
            FoodMenu.createUpdateFoodMenu(mutationBusinessId, mutationSections),
        {
            onSuccess: () => {
                if (ignoreInvalidateQueries) {
                    return onSuccess();
                }
                queryClient
                    .invalidateQueries(invalidatesAny(BUSINESS))
                    .then(onSuccess);
            },
            onError,
        },
    );
}
