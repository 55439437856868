import { toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { REVIEW_BOOSTER_GET_EXPEDITOR_NAME } from 'app/common/data/queryKeysConstants';

type ReviewBoosterBusinessData = {
    expeditor_name: string;
    review_url: string;
};

export default function useReviewBoosterBusinessData(business) {
    const { t } = useTranslation();

    return useQuery(
        [REVIEW_BOOSTER_GET_EXPEDITOR_NAME, { businessId: business?.id }],
        (): Promise<ReviewBoosterBusinessData> => {
            return Promise.all([
                api.reviewBooster.getExpeditorName(business?.id),
                api.reviewBooster.getReviewUrl(business?.id),
            ]).then(([expeditorData, reviewUrlData]) => {
                return {
                    expeditor_name: expeditorData.expeditor_name,
                    review_url: reviewUrlData.review_url,
                };
            });
        },
        {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            enabled: !!business,
            onError: () => {
                toast.error(t('unexpected_error'), t('error'));
            },
        },
    );
}
