import { ReactNode } from 'react';

type Props = {
    size: 'large' | 'medium' | 'small' | 'mobile';
    mobileFill?: boolean;
    children: ReactNode;
    className?: string;
};

const ModalTemplate = ({ size, mobileFill = false, children, className = '' }: Props) => (
    <div
        className={`mdc-modal--${size} ${
            mobileFill ? 'mdc-modal--mobile-full-screen' : ''
        } ${className}`}
    >
        {children}
    </div>
);

export default ModalTemplate;
