import camelcasify from 'camelcase-keys';

import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';
import { Camel } from 'app/utils/types';

import api from './index';

/**
 * @deprecated
 * As of 30/03/2023, it is no longer recommended to use the camelsification
 * wrappers for API. Instead use the default APIs with snake_cased keys from
 * `app/api/v2/api_calls`. */
const camelApi = {} as CamelApi;

// Below we iterate over each api fetcher methods and create a `camelApi` object that has the same shape,
// except that each API call return data is automatically camelcasified.
for (const [key, klass] of Object.entries(api)) {
    class CamelKlass extends ApiResourceBaseClass {}

    const staticMethods = Object.getOwnPropertyNames(klass).filter(
        property => typeof klass[property] === 'function',
    );

    for (const staticMethod of staticMethods) {
        const oldMethod = klass[staticMethod];
        CamelKlass[staticMethod] = (...args) =>
            oldMethod(...args).then(data => camelcasify(data, { deep: true }));
    }

    camelApi[key] = CamelKlass;
}

// Here we simply reconstruct the `api` type while applying a camelcasifier on the returned data types.
type CamelApi = {
    [key in keyof typeof api]: {
        [attr in keyof (typeof api)[key]]: (typeof api)[key][attr] extends (
            ...args: any[]
        ) => Promise<infer R>
            ? (...args: any[]) => Promise<Camel<R>>
            : never;
    };
};

export default camelApi;
