import React from 'react';

import { ArrowWrapper } from './ArrowButton.styled';

type ArrowButtonProps = {
    arrowDirection: string;
    goToNextSlide: () => void;
    goToPreviousSlide: () => void;
};

const ArrowButton = ({
    arrowDirection,
    goToNextSlide,
    goToPreviousSlide,
}: ArrowButtonProps) => (
    <ArrowWrapper
        onClick={arrowDirection === 'left' ? goToPreviousSlide : goToNextSlide}
        role="button"
        key={arrowDirection}
        direction={arrowDirection}
    >
        <i className={`fas fa-arrow-${arrowDirection}`} />
    </ArrowWrapper>
);

export default ArrowButton;
