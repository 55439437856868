import { useState } from 'react';

import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack, Text, Tooltip } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import type { KeywordType, OverviewType } from 'app/api/types/competitiveBenchmark';

import useLocalBusinessIdCompetitiveBenchmark from 'app/common/components/businessModal/hooks/birdOnly/useLocalBusinessIdCompetitiveBenchmark';
import {
    BenchmarkContainer,
    BenchmarkContent,
    LocalTitleContainer,
} from 'app/pages/analytics/CompetitiveBenchmark/components/CompetitiveBenchmarkSection/CompetitiveBenchmarkSection.styled';

import { Overview } from 'app/pages/analytics/CompetitiveBenchmark/components/OverviewSection/Overview';
import { OverviewSelection } from 'app/pages/analytics/CompetitiveBenchmark/components/OverviewSection/OverviewSelection';

import { useGetCompetitorRankings } from '../../hooks/useGetCompetitorRanks';
import { buildRowOptions } from '../../utils/buildTableRowOptions';
import { getCompetitorColors } from '../../utils/getCompetitiveColors';
import { RowOptions } from '../CompetitorTableSection/CompetitorRow/CompetitorRow';
import { CompetitorTableSection } from '../CompetitorTableSection/CompetitorTableSection';

type CompetitiveBenchmarkSectionProps = {
    keyword: KeywordType;
    currentDate: Date;
    comparisonDate: Date;
    businessIds: string[];
};

const TableHeaderTitle = () => {
    const { t } = useTranslation();
    return (
        <LocalTitleContainer>
            <Stack direction="row" alignItems="center">
                <Text variant="heading6" as={'span'}>
                    {t('comparison_with_competitors_word')}
                </Text>
                <Tooltip text={t('tooltip_appearance_rate')}>
                    <Icon
                        icon={[FontAwesomeIconsPartooUsed.faCircleInfo, IconPrefix.REGULAR]}
                        color="secondary"
                        iconSize="16px"
                    />
                </Tooltip>
            </Stack>
        </LocalTitleContainer>
    );
};

const useCompetitiveTableRows = (
    keyword: string,
    date: Date,
    comparisonDate: Date,
): { loading: boolean; rows: Array<RowOptions>; colors: Record<string, string> } => {
    const { competitorRankings, clientRanking, isLoading } = useGetCompetitorRankings(
        keyword,
        date,
    );
    const { competitorRankings: lastRankings, isLoading: lastIsLoading } = useGetCompetitorRankings(
        keyword,
        comparisonDate,
    );

    if (isLoading || lastIsLoading) return { loading: true, rows: [], colors: {} };

    const lastRankingsMap = Object.fromEntries(lastRankings.map(rank => [rank.name, rank]));
    const colors = getCompetitorColors(
        competitorRankings.map(competitor => competitor.name),
        clientRanking.name,
    );
    const rows = competitorRankings.map(rank =>
        buildRowOptions(rank, clientRanking, lastRankingsMap[rank.name], colors),
    );

    return { loading: false, rows: rows, colors };
};

export const CompetitiveBenchmarkSection = ({
    keyword,
    currentDate,
    comparisonDate,
}: CompetitiveBenchmarkSectionProps) => {
    const [selection, setSelection] = useState<OverviewType>('rank');
    const { t } = useTranslation();
    const localBusinessId = useLocalBusinessIdCompetitiveBenchmark();

    const { loading, rows, colors } = useCompetitiveTableRows(
        keyword.keyword,
        currentDate,
        comparisonDate,
    );
    const columns = [
        {
            label: t('name'),
            width: '400px',
        },
        {
            label: t('ranking_word'),
            orderOn: 'rank',
        },
        {
            label: t('reviews'),
            orderOn: 'reviews',
        },
        {
            label: t('note'),
            orderOn: 'rating',
        },
    ];
    return (
        <BenchmarkContainer>
            {!localBusinessId && (
                <OverviewSelection selection={selection} setSelection={setSelection} />
            )}
            <BenchmarkContent>
                {!localBusinessId && (
                    <Overview
                        keyword={keyword}
                        currentDate={currentDate}
                        selection={selection}
                        colors={colors}
                    />
                )}
                {localBusinessId && <TableHeaderTitle />}
                <CompetitorTableSection rows={rows} isLoading={loading} columns={columns} />
            </BenchmarkContent>
        </BenchmarkContainer>
    );
};
