import React, { useState } from 'react';

import { Button, IconPrefix, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useIsMaxBusinessesLimitCheck } from 'app/pages/visibility/BusinessCreate/hooks/useIsMaxBusinessesLimitCheck';
import MaxBusinessReachedModal from 'app/pages/visibility/BusinessCreate/sections/BusinessCreateContent/MaxBusinessReachedModal';
import { VISIBILITY_LOCATION_ADD_PATH } from 'app/routing/routeIds';

const AddBusinessButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showMaxBusinessModal, setShowMaxBusinessModal] = useState<boolean>(false);
    const isMaxBusinessesLimitReached = useIsMaxBusinessesLimitCheck();

    const handleClick = () => {
        if (isMaxBusinessesLimitReached) {
            setShowMaxBusinessModal(true);
            return;
        }
        navigate(VISIBILITY_LOCATION_ADD_PATH);
    };

    return (
        <>
            <Button
                dataTrackId="add_local_business__button"
                shape="cube"
                variant="primary"
                icon={['fa-plus', IconPrefix.SOLID]}
                onClick={handleClick}
            >
                {t('add_local_business')}
            </Button>

            <Modal isOpen={showMaxBusinessModal} closeModal={() => setShowMaxBusinessModal(false)}>
                <MaxBusinessReachedModal closeModal={() => setShowMaxBusinessModal(false)} />
            </Modal>
        </>
    );
};

export default AddBusinessButton;
