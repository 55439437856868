import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COLLAPSED_SIDEBAR_WIDTH } from '@partoohub/modular-components';
import { Button } from '@partoohub/ui';

type AlertBannerWrapperProps = {
    fixed: boolean;
};

type AlertBannerContainerProps = {
    color: string;
};

export const AlertBannerWrapper = styled.div`
    ${(props: AlertBannerWrapperProps) =>
        props.fixed &&
        css`
            bottom: 0;
            position: absolute;
            left: 0;
        `};
    z-index: 2;
    width: 100%;
`;

export const AlertBannerWrapperWithNewSidebar = styled.div`
    ${(props: AlertBannerWrapperProps) =>
        props.fixed &&
        css`
            bottom: 0;
            position: absolute;
            left: ${COLLAPSED_SIDEBAR_WIDTH}px;
        `};
    z-index: 2;
    width: calc(100% - ${COLLAPSED_SIDEBAR_WIDTH}px);
`;

export const StyledButton = styled(Button)`
    background-color: white;
`;
export const AlertBannerContainer = styled.div`
    margin: 24px;
    ${(props: AlertBannerContainerProps) =>
        props.color &&
        css`
            background-color: ${props.color};
        `};
    border-radius: 8px;
    position: relative;
    padding: 16px;
`;

export const AlertBannerTitle = styled.div`
    padding-bottom: 4px;
    font-size: 18px;
    font-weight: bold;
    color: white;
`;

export const AlertBannerContent = styled.div`
    color: white;
`;

export const AlertBannerButton = styled.div`
    button {
        ${props =>
            props.color &&
            css`
                color: ${props.color} !important;
            `};
        background-color: white !important;
        margin-top: 16px;
    }
`;

export const BackgroundIcon = styled.div`
    content: '\\f05a';
    font-family: 'Font Awesome 6 Pro';
    right: -12px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-weight: 900;
    font-size: 106px;
    color: rgba(255, 255, 255, 0.1);
`;
