import { LiteralRating } from 'app/api/types/review';
import { LanguageCodeType } from 'app/common/services/languages';

import { PaginatedResponse } from '../v2/api_calls/utils';

export const ALL_TEMPLATES = 'all';
export const POSITIVE_REVIEWS = 'positive';
export const NEUTRAL_REVIEWS = 'neutral';
export const NEGATIVE_REVIEWS = 'negative';

export type ReplyTemplateApi = {
    id: number;
    language: LanguageCodeType;
    title: string;
    text: string;
    positive: boolean;
    neutral: boolean;
    negative: boolean;
    creation_date: string;
    update_date?: string;
    updated_by?: string;
    org_id?: number;
    is_auto_reply: boolean;
};

export type FormattedReplyTemplate = {
    id: number;
    language: LanguageCodeType;
    title: string;
    text: string;
    orgId?: number;
    updatedBy?: string;
    isAutoReply: boolean;
    ratings: Array<LiteralRating>;
    creationDate: string;
    updateDate?: string;
};

export type SaveReplyTemplatePayload = {
    id?: number;
    language: LanguageCodeType;
    title: string;
    text: string;
    positive: boolean;
    neutral: boolean;
    negative: boolean;
};

export type PaginatedReplyTemplatesResponse = PaginatedResponse<{
    reply_templates: Array<ReplyTemplateApi>;
}>;

export type TemplateHasAutoReplySettingsResponse = {
    has_auto_reply_settings: boolean;
};
