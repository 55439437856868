import {
    ChangeEventHandler,
    Ref,
    TextareaHTMLAttributes,
    useEffect,
    useRef,
    useState,
} from 'react';

import { StyledHiddenTextArea, StyledTextArea } from './AutosizedTextArea.styled';

type Props = {
    value: string;
    onChange: ChangeEventHandler<HTMLTextAreaElement>;
    className?: string;
    textAreaRef?: Ref<HTMLTextAreaElement>;
    minRows?: number;
    maxRows?: number;
    // Use this prop to change the command used to create a line break, (default: Enter key)
    lineBreakCommand?: (event: KeyboardEvent) => boolean;
    fontSize?: number;
    lineHeight?: number;
    disabled?: boolean;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export default function AutosizedTextArea({
    value,
    onChange,
    className,
    textAreaRef,
    minRows = 1,
    maxRows = 10,
    onKeyDown,
    lineBreakCommand,
    fontSize = 14,
    lineHeight = 18,
    disabled = false,
    ...textAreaAttributes
}: Props) {
    const hiddenInputRef = useRef<HTMLTextAreaElement>(null);
    const [currentInputHeight, setCurrentInputHeight] = useState(lineHeight);

    let textAreaElement: HTMLTextAreaElement | null | undefined;
    const textAreaInputRef =
        typeof textAreaRef === 'function'
            ? (element: HTMLTextAreaElement) => {
                  textAreaElement = element;
                  textAreaRef(element);
              }
            : (() => {
                  textAreaElement = textAreaRef?.current;
                  return textAreaRef;
              })();

    useEffect(() => {
        // Adjust the height of the input.
        // ...get the height from hidden element and it will be applied to visible text area.
        setCurrentInputHeight(hiddenInputRef.current?.scrollHeight || lineHeight);
    }, [value]);

    const handleKeyDown = (event: any) => {
        if (lineBreakCommand ? lineBreakCommand(event) : event.key === 'Enter') {
            event.preventDefault();
            const idx = event.target.selectionStart;
            event.target.value = [value.slice(0, idx), value.slice(idx)].join('\n');
            onChange(event);
            // call the ref to set cursor new position
            textAreaElement?.setSelectionRange(idx + 1, idx + 1);

            if (currentInputHeight > maxRows * lineHeight) {
                // Hack to scroll to the cursor position if the textarea cannot expand,
                // otherwise the cursor would not stay visible if it moves to the last line
                textAreaElement?.blur();
                textAreaElement?.focus();
            }
        } else if (onKeyDown) {
            // Handle custom keydown events
            onKeyDown(event);
        }
    };

    return (
        <>
            <StyledHiddenTextArea
                value={value}
                ref={hiddenInputRef}
                fontSize={fontSize}
                lineHeight={lineHeight}
                disabled={disabled}
                readOnly
            />
            <StyledTextArea
                className={className}
                value={value}
                onChange={onChange}
                ref={textAreaInputRef}
                minHeight={minRows * lineHeight}
                maxHeight={maxRows * lineHeight}
                onKeyDown={handleKeyDown}
                disabled={disabled}
                {...textAreaAttributes}
                height={Math.max(currentInputHeight, minRows * lineHeight)}
                fontSize={fontSize}
                lineHeight={lineHeight}
            />
        </>
    );
}
