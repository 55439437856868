import { useTranslation } from 'react-i18next';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';

type Props = {
    show: boolean;
    cancel: () => void;
    confirm: () => void;
};

export default function PostPublishOnInstagramConfirmModal({ show, cancel, confirm }: Props) {
    const { t } = useTranslation();

    return (
        <ConfirmModal
            modalFor="warning"
            trackId="post_publish_on_instagram"
            show={show}
            title={t('post_publish_on_instagram_modal_title')}
            content={t('post_publish_on_instagram_modal_content')}
            confirmLabel={t('post_publish_on_instagram_modal_publish')}
            cancelLabel={t('post_publish_on_instagram_modal_verify')}
            onConfirm={confirm}
            onHide={cancel}
            confirmDisabled={false}
            waitToConfirm={5}
        />
    );
}
