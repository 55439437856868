import { List } from '@partoohub/ui';

import LoadingView from 'app/common/components/LoadingView';
import { useSettingIntegrationsSummary } from 'app/pages/settingsV2/hooks/Integrations/useSettingIntegrationsSummary';
import { useSettingMainSummary } from 'app/pages/settingsV2/hooks/Main/useSettingMainSummary';
import { useSettingMessagesSummary } from 'app/pages/settingsV2/hooks/Messages/useSettingMessagesSummary';
import { useSettingPlatformsSummary } from 'app/pages/settingsV2/hooks/Platforms/useSettingPlatformsSummary';
import { useSettingReviewsSummary } from 'app/pages/settingsV2/hooks/Reviews/useSettingReviewsSummary';
import { useSettingTeamSummary } from 'app/pages/settingsV2/hooks/Team/useSettingSummaryTeam';

import { useIsLoadingSettingsSummary } from '../../hooks/Common/useIsLoadingSettingsSummary';
import { SettingsSummaryItem } from '../SettingsSummaryItem/SettingsSummaryItem';

const settingsColumns = [
    {
        label: 'Icon',
        width: '40px',
    },
    {
        label: 'Description',
    },
    {
        label: '',
        width: '40px',
    },
];

export const SettingsSummary = () => {
    const isLoading = useIsLoadingSettingsSummary();

    const { settingData: settingMainData } = useSettingMainSummary();
    const { settingData: settingTeamData } = useSettingTeamSummary();
    const { settingData: settingReviewsData } = useSettingReviewsSummary();
    const { settingData: settingMessagesData } = useSettingMessagesSummary();
    const { settingData: settingPlatformsData } = useSettingPlatformsSummary();
    const { settingData: settingIntegrationsData } = useSettingIntegrationsSummary();

    if (isLoading) {
        return <LoadingView />;
    }

    return (
        <List columns={settingsColumns} showHeader={false}>
            <SettingsSummaryItem settingData={settingMainData} />
            <SettingsSummaryItem settingData={settingTeamData} />
            <SettingsSummaryItem settingData={settingReviewsData} />
            <SettingsSummaryItem settingData={settingMessagesData} />
            <SettingsSummaryItem settingData={settingPlatformsData} />
            <SettingsSummaryItem settingData={settingIntegrationsData} />
        </List>
    );
};
