import { useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DownloadLocationOAuthAccountTaskInfo } from 'app/api/types/task/download_task';
import { OAuthAccountTaskInfo, TaskInfo } from 'app/api/types/task/task';
import { TASK_UPDATE } from 'app/common/data/websocket';
import useWebsocket from 'app/common/hooks/useWebsocket';
import { businessesSelector } from 'app/states/reducers';

import {
    commonOAuthMessage,
    downloadLocationMessage,
    postLocationImportMessage,
} from './taskHandler';

export const useTaskListener = (storage?: Record<string, any>) => {
    // used to trigger render manually
    const [, updateEventData] = useState({});
    const dispatch = useDispatch();
    const businessesState = useSelector(businessesSelector);

    // state storage variables
    let oauthAccounts = useRef<Record<string, OAuthAccountTaskInfo>>({});
    let downloadLocationsOAuthAccounts = useRef<
        Record<string, DownloadLocationOAuthAccountTaskInfo>
    >({});
    const tasks = useRef<Record<string, TaskInfo>>({});
    let importTasks = useRef<Record<string, TaskInfo>>({});
    const businessIdsToImport = useRef<Array<string> | undefined>(undefined);

    if (storage?.businessIds) {
        businessIdsToImport.current = storage.businessIds;
    }

    useWebsocket(TASK_UPDATE, (eventData: TaskInfo) => {
        const taskID = eventData.id;
        if (!taskID) return;

        // websockets order is not guaranteed, so we use timestamp to ignore older events
        const currentTask = tasks.current[taskID];

        if (currentTask && currentTask.timestamp > eventData.timestamp) {
            return;
        }

        tasks.current = {
            ...tasks.current,
            [taskID]: {
                ...eventData,
            },
        };

        const rerender = () => updateEventData({ ...eventData });

        switch (eventData.type) {
            case 'download_locations_by_google_oauth_account':
            case 'download_locations_by_google_account':
            case '_download_google_locations':
            case 'download_facebook_location_pages_by_facebook_oauth_account':
            case 'download_facebook_location_pages_by_brand_page':
            case '_download_facebook_location_pages': {
                downloadLocationsOAuthAccounts = downloadLocationMessage(
                    eventData,
                    downloadLocationsOAuthAccounts,
                    rerender,
                );
                break;
            }
            case 'download_google_accounts':
            case 'download_facebook_brand_pages': {
                if (eventData?.status) {
                    oauthAccounts = commonOAuthMessage(
                        eventData,
                        oauthAccounts,
                        rerender,
                    );
                }
                break;
            }
            case 'post_location_import': {
                importTasks = postLocationImportMessage(
                    eventData,
                    importTasks,
                    businessIdsToImport,
                    dispatch,
                    businessesState,
                );
                break;
            }
            default: {
                break;
            }
        }
    });

    return {
        oauthAccounts: {
            commonOAuthAccounts: oauthAccounts.current,
            downloadLocationsOAuthAccounts:
                downloadLocationsOAuthAccounts.current,
        },
        tasks: tasks.current,
    };
};
