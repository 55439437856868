import { useRef } from 'react';

import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { numberFormatter } from 'app/common/services/formatNumber';
import getLang from 'app/common/services/getLang';
import seasonalityDateFormatter from 'app/common/services/seasonalityDateFormatter';
import { GraphTypes } from 'app/utils/chartjs/graphTypes';
import { toggleLegend } from 'app/utils/chartjs/helpers';
import { buildOptionsForGraphic } from 'app/utils/chartjs/options';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

type Props = {
    dates: Array<string>;
    added: Array<number>;
    cumSum: Array<number>;
    isPeriod: boolean;
    seasonalityChoice: string;
    hasReviews: boolean;
};

const QuantitativeChart = ({
    dates,
    added,
    cumSum,
    isPeriod,
    seasonalityChoice,
    hasReviews,
}: Props) => {
    const { t } = useTranslation();
    const barRef = useRef<ChartJS>(null);
    const type = GraphTypes.BAR;

    const tooltip = {
        callbacks: {
            label: tooltipItem => {
                const { label } = tooltipItem.dataset;
                const separator = getLang() === 'fr' ? ' : ' : ': ';
                const value = tooltipItem.raw;
                return `${label}${separator}${numberFormatter(value)}`;
            },
            title: tooltipItem => {
                if (seasonalityChoice === 'week')
                    return `${t('week_of')} ${tooltipItem[0].label}`;
                return tooltipItem[0].label;
            },
            afterBody: tooltipItems => {
                let sum = 0;

                tooltipItems.forEach(function (tooltipItem) {
                    sum += tooltipItem.parsed.y;
                });
                const separator = getLang() === 'fr' ? ' : ' : ': ';
                return `     ${t(
                    'total_my_reviews',
                )}${separator}${numberFormatter(sum)}`;
            },
        },
    };

    const data = {
        type,
        labels: seasonalityDateFormatter(dates, seasonalityChoice),
        datasets: [
            {
                label: t('cumulated_reviews'),
                backgroundColor: 'rgba(255, 144, 20, 0.25)',
                data: cumSum,
                hoverBorderWidth: 0,
                hidden: isPeriod,
                borderColor: 'rgba(255, 146, 20, 0)',
                borderWidth: 1,
            },
            {
                label: t('new_reviews'),
                data: added,
                backgroundColor: '#ff9014',
                hoverBorderWidth: 0,
                borderWidth: 1,
            },
            {
                label: t('no_reviews_found'),
                data: null,
                showLine: false,
                pointHitRadius: 10,
                pointRadius: 5,
                pointHoverRadius: 10,
                borderWidth: 1,
                fill: false,
            },
        ],
        hasReviews,
        seasonalityChoice,
    };

    const buildOptions: any = () => {
        const options: any = buildOptionsForGraphic(type);
        options.plugins.tooltip = tooltip;
        options.scales.x.barPercentage = 0.9;
        options.scales.y.barPercentage = 0.9;
        options.plugins.legend.labels.filter = item =>
            hasReviews && item.text !== t('no_reviews_found') ? true : false;
        options.plugins.legend.onClick = (event, legendItem) =>
            toggleLegend(legendItem, barRef);
        options.aspectRatio = 1;
        options.maintainAspectRatio = false;
        return options;
    };

    return <Bar ref={barRef} options={buildOptions()} data={data} />;
};

export default QuantitativeChart;
