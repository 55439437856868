import { isEqual } from 'lodash-es';
import { connect } from 'react-redux';

import {
    applyFilterByBusinessFilters,
    hideFilterByBusiness,
} from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import ModalValidationRibbon from 'app/common/components/ModalValidationRibbon';
import { closeBusinessModal } from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupFakeSaga';

import {
    currentFilteredBusinessesSelector,
    pendingFilteredBusinessesSelector,
} from '../reducers/filterByBusiness';

type OwnProps = {
    active: boolean;
    text: string;
    enabledIcon: string;
};

const mapStateToProps = ({ filterByBusiness }, { active }: OwnProps) => {
    const activeRibbon =
        active ||
        !isEqual(
            pendingFilteredBusinessesSelector(filterByBusiness),
            currentFilteredBusinessesSelector(filterByBusiness),
        );
    return {
        state: activeRibbon ? 'enabled' : 'disabled',
        text: 'validate',
        enabledIcon: 'fa fa-check',
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    handleClick: () => {
        dispatch(hideFilterByBusiness());
        dispatch(applyFilterByBusinessFilters());
        dispatch(closeBusinessModal());
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    handleClick: () => dispatchProps.handleClick(),
});

const FilterByBusinessRibbon = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(ModalValidationRibbon);

export default FilterByBusinessRibbon;
