import { PublishersStateStats } from 'app/api/types/publisher_state';

// ACTION TYPES
export const GET_PUBLISHER_STATES_REQUEST = 'GET_PUBLISHER_STATES_REQUEST';
const GET_PUBLISHER_STATES_REQUEST_SUCCESS =
    'GET_PUBLISHER_STATES_REQUEST_SUCCESS';
const GET_PUBLISHER_STATES_REQUEST_FAILURE =
    'GET_PUBLISHER_STATES_REQUEST_FAILURE';

// ACTION FLOW TYPES
export type GetPublisherStatesAction = {
    type: 'GET_PUBLISHER_STATES_REQUEST';
};

type GetPublisherStatesSuccessAction = {
    type: 'GET_PUBLISHER_STATES_REQUEST_SUCCESS';
    publisherStates: PublishersStateStats;
    businessCount: number;
};

type GetPublisherStatesFailureAction = {
    type: 'GET_PUBLISHER_STATES_REQUEST_FAILURE';
    error: Record<string, any>;
};

type PublisherStatesAction =
    | GetPublisherStatesAction
    | GetPublisherStatesSuccessAction
    | GetPublisherStatesFailureAction;

// ACTION CREATORS
export const getPublisherStates = (): GetPublisherStatesAction => ({
    type: GET_PUBLISHER_STATES_REQUEST,
});

export const getPublisherStatesSuccess = (
    publisherStates: PublishersStateStats,
    businessCount: number,
) => ({
    type: 'GET_PUBLISHER_STATES_REQUEST_SUCCESS',
    publisherStates,
    businessCount,
});

export const getPublisherStatesFailure = (
    error: Record<string, any>,
): GetPublisherStatesFailureAction => ({
    type: GET_PUBLISHER_STATES_REQUEST_FAILURE,
    error,
});

export type PublisherStatesState = {
    isFetching: boolean;
    publisherStates: PublishersStateStats;
    businessCount: number;
    error: Record<string, any>;
};

// INITIAL STATE
const initialState = {
    isFetching: false,
    publisherStates: {},
    businessCount: 0,
    error: {},
};

// REDUCER
const PublisherStatesReducer = (
    state: PublisherStatesState = initialState,
    action: PublisherStatesAction,
): PublisherStatesState => {
    switch (action.type) {
        case GET_PUBLISHER_STATES_REQUEST:
            return { ...state, isFetching: true, error: {} };

        case GET_PUBLISHER_STATES_REQUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                publisherStates: action.publisherStates,
                businessCount: action.businessCount,
            };

        case GET_PUBLISHER_STATES_REQUEST_FAILURE:
            return { ...state, isFetching: false, error: action.error };

        default:
            return state;
    }
};

export default PublisherStatesReducer;
