import { useMemo } from 'react';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

export const PLATFORMS_FILTER_PARAM = 'platforms';

export default function usePlatformsFilter() {
    const [value, setValue] = useStateQueryParams(PLATFORMS_FILTER_PARAM);

    return useMemo(() => {
        const parsedValue: Record<string, boolean> = value
            ? value.split(',').reduce((carry, platform) => {
                  carry[platform] = true;
                  return carry;
              }, {})
            : {};

        const searchParamSetter = (parsedValue: Record<string, boolean>) => {
            setValue(
                Object.keys(parsedValue)
                    .filter(platform => parsedValue[platform])
                    .toString(),
            );
        };

        return [
            parsedValue,
            searchParamSetter,
            {
                platform__in: value ? value : undefined,
            },
        ];
    }, [value]);
}
