import { V2LocationData } from 'app/api/types/account';
import { VERIFICATION_STATUS_TO_SEARCH_IN } from 'app/settingsManagement/components/GoogleVerification/utils/useLocationSearch';

export const doesLocationRequiresVerification = (location?: V2LocationData | null) => {
    return (
        location &&
        location.address_details &&
        VERIFICATION_STATUS_TO_SEARCH_IN.includes(location.google_verifications?.status || '')
    );
};
