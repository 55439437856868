import React, { useState } from 'react';

import { css } from '@emotion/react';
import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Modal, Option, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';
import { ADMIN, GROUP_MANAGER, PROVIDER } from 'app/common/data/roles';
import { ButtonWithMenu } from 'app/common/designSystem/components/molecules/ButtonWithMenu/ButtonWithMenu';
import useMe from 'app/common/hooks/queries/useMe';
import FunnelOnboarding from 'app/onboarding/FunnelOnboarding';
import { useIsMaxBusinessesLimitCheck } from 'app/pages/visibility/BusinessCreate/hooks/useIsMaxBusinessesLimitCheck';
import MaxBusinessReachedModal from 'app/pages/visibility/BusinessCreate/sections/BusinessCreateContent/MaxBusinessReachedModal';
import useHasGroupPuller from 'app/pages/visibility/BusinessListV2/hooks/groups/useHasGroupPuller';
import CreateGroupModal from 'app/pages/visibility/BusinessListV2/sections/BusinessListHeader/OldHeaderButton/GroupModals/CreateGroupModal';

const CREATE_GROUP = 'create_group';
const ONBOARDING = 'onboarding_business';

const AddMenuButton = () => {
    const { t } = useTranslation();
    const { data: me } = useMe();
    const [showFunnel, setShowFunnel] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showMaxBusinessModal, setShowMaxBusinessModal] = useState<boolean>(false);
    const isMaxBusinessesLimitReached = useIsMaxBusinessesLimitCheck();
    const hasGroupPuller = useHasGroupPuller();

    const options: Array<Option> = [
        {
            name: ONBOARDING,
            label: (
                <MenuLabelItem
                    text={t('add_local_business')}
                    icon={['fa-plus', IconPrefix.SOLID]}
                />
            ),
            value: ONBOARDING,
        },
        {
            name: CREATE_GROUP,
            label: (
                <MenuLabelItem
                    text={me?.role === GROUP_MANAGER ? t('add_sub_group') : t('add_group_business')}
                    icon={['fa-folder-plus', IconPrefix.SOLID]}
                />
            ),
            value: CREATE_GROUP,
            disabled: [ADMIN, PROVIDER].includes(me?.role || '') || hasGroupPuller,
        },
    ];

    const onClickMenu = (value: string) => {
        if (value === CREATE_GROUP) {
            setShowCreateModal(true);
        } else if (value === ONBOARDING) {
            if (isMaxBusinessesLimitReached) {
                setShowMaxBusinessModal(true);
                return;
            }
            setShowFunnel(true);
        }
    };

    return (
        <>
            <ButtonWithMenu
                dataTrackId="business_list__old_actions__button_menu"
                variant="primary"
                text={
                    <Text variant="bodyMBold" as="span" color="theme">
                        <div
                            style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                            }}
                        >
                            <Icon
                                icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                                iconSize="14px"
                                color="white"
                            />
                            {t('add')}
                        </div>
                    </Text>
                }
                options={options}
                onMenuClick={onClickMenu}
                cssMenu={css`
                    top: 50px;
                    right: 0px;
                    left: unset;
                `}
            />

            <Modal isOpen={showCreateModal} closeModal={() => setShowCreateModal(false)}>
                <CreateGroupModal closeModal={() => setShowCreateModal(false)} />
            </Modal>

            <Modal isOpen={showMaxBusinessModal} closeModal={() => setShowMaxBusinessModal(false)}>
                <MaxBusinessReachedModal closeModal={() => setShowMaxBusinessModal(false)} />
            </Modal>

            <FunnelOnboarding showFunnel={showFunnel} setShowFunnel={setShowFunnel} />
        </>
    );
};

export default AddMenuButton;
