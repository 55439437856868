import { connect } from 'react-redux';

import DesktopHeader from 'app/businessConnection/components/PageHeader/DesktopHeader/DesktopHeader';

import { meRoleSelector } from 'app/common/reducers/me';
import { countByNavSelector } from 'app/states/knowledge/reducers/businessConnection/connectionsStats';
import {
    ALL_TAB,
    NavValue,
    TO_CONNECT_TO_FACEBOOK,
    TO_CONNECT_TO_GMB,
    navTabSelector,
} from 'app/states/knowledge/reducers/businessConnection/nav';
import { AppState, connectionStatsSelector } from 'app/states/reducers';

const getCurrentTitle = (tab: NavValue) => {
    switch (tab) {
        case ALL_TAB:
            return 'all_connections_title';

        case TO_CONNECT_TO_GMB:
            return 'to_be_connect_to_gmb_title';

        case TO_CONNECT_TO_FACEBOOK:
            return 'to_connect_to_facebook_title';

        default:
            return 'all_connections_title';
    }
};

const mapStateToProps = (state: AppState) => {
    const connectionStatsState = connectionStatsSelector(state);
    const navTab = navTabSelector(state);
    const displayFilters = meRoleSelector(state.me) === 'ADMIN';
    return {
        displayFilters,
        currentNavTabTitle: getCurrentTitle(navTab),
        count: countByNavSelector(navTab, connectionStatsState),
    };
};

const BusinessConnectionHeader = connect(mapStateToProps, null)(DesktopHeader);

export default BusinessConnectionHeader;
