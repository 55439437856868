import React from 'react';

import { useTranslation } from 'react-i18next';

import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import { trackSelectBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import {
    EditSectionContainer,
    EditSectionDescription,
    EditSectionIcon,
    EditSectionText,
    EditSectionTitle,
} from './EditSection.styled';

type Props = {
    title: string;
    description?: React.ReactNode;
    onClick?: () => void;
    colorState?: ColorState;
    sectionName?: SectionNames;
};

const EditSection = ({
    title,
    description,
    onClick = () => undefined,
    colorState = ColorState.OK,
    sectionName,
}: Props) => {
    const { t } = useTranslation();

    const onClickWithGTM = () => {
        if (sectionName) {
            trackSelectBusinessEditSection(sectionName, colorState);
        }
        onClick();
    };

    return (
        <TooltipWrapper
            text={
                colorState === ColorState.DISABLED ? t('field_read_only') : ''
            }
        >
            <EditSectionContainer
                colorState={colorState}
                onClick={onClickWithGTM}
            >
                <EditSectionIcon colorState={colorState}>
                    {colorState === ColorState.OK ? (
                        <i className="fa-solid fa-check-circle" />
                    ) : (
                        <i className="fa-solid fa-circle-exclamation" />
                    )}
                </EditSectionIcon>
                <EditSectionText>
                    <EditSectionTitle
                        disabled={[
                            ColorState.DISABLED,
                            ColorState.CLOSED,
                        ].includes(colorState)}
                    >
                        {title}
                    </EditSectionTitle>
                    <EditSectionDescription
                        disabled={[
                            ColorState.DISABLED,
                            ColorState.CLOSED,
                        ].includes(colorState)}
                    >
                        {description}
                    </EditSectionDescription>
                </EditSectionText>
                <i className="fa-solid fa-angle-right" />
            </EditSectionContainer>
        </TooltipWrapper>
    );
};

export default EditSection;
