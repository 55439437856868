import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Text } from '@partoohub/ui';

import { ContentLoader } from 'app/pages/Homepage/utils/ContentLoader';

import {
    TodoAction,
    TodoContainer,
    TodoContent,
    TodoLeftSection,
    TodoRightSection,
    TodoTitle,
} from './DefaultTodoComponent.styled';

export type SmallTodoProps = {
    small?: boolean;
};

type Props = {
    title: string;
    actionText?: string;
    description?: string;
    icon?: React.ReactNode;
    onClick?: () => void;
    isLoading?: boolean;
};

export const DefaultTodoComponent = ({
    title,
    actionText,
    description,
    icon = (
        <Icon
            icon={[FontAwesomeIconsPartooUsed.faRocket, IconPrefix.REGULAR]}
            color="primary"
            iconSize="96px"
        />
    ),
    onClick = () => undefined,
    isLoading,
    small = false,
}: Props & SmallTodoProps) => {
    if (isLoading) {
        return (
            <TodoContainer>
                <ContentLoader />
            </TodoContainer>
        );
    }

    return (
        <TodoContainer>
            <TodoContent onClick={onClick}>
                <TodoLeftSection small={small}>{icon}</TodoLeftSection>

                <TodoRightSection small={small}>
                    <TodoTitle variant={small ? 'heading5' : 'heading3'}>{title}</TodoTitle>
                    {actionText && (
                        <TodoAction>
                            <Text variant="bodyMBold" color="primary">
                                {actionText}
                            </Text>
                            <Icon
                                icon={[FontAwesomeIconsPartooUsed.faArrowRight, IconPrefix.REGULAR]}
                                color="primary"
                                iconSize="14px"
                            />
                        </TodoAction>
                    )}
                    {description && (
                        <Text variant="bodyMRegular" color="secondary">
                            {description}
                        </Text>
                    )}
                </TodoRightSection>
            </TodoContent>
        </TodoContainer>
    );
};
