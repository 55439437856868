import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba, greyColorObject } from '@partoohub/branding';

import { Text } from '@partoohub/ui';

import { LocationDetailsBrandBadge } from 'app/businessConnection/components/commonOld/LocationDetails.styled';

export const ServiceAreaComponent = styled.div`
    display: flex;
    align-items: center;

    i {
        vertical-align: middle;
    }

    & > span.fa-small {
        width: 14px;
        margin-right: 4px;
        font-size: 0.6em;
    }
`;

interface LocationCardContainerProps {
    hasError?: boolean;
    isSelected?: boolean;
    noBorder?: boolean;
}

export const LocationCardContainer = styled.div<LocationCardContainerProps>`
    display: flex;
    flex: 1;
    align-items: center;
    border: 1px solid ${greyColorObject.light};
    border-radius: 4px;
    padding: 16px;
    height: 96px;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);

    ${props =>
        props.noBorder &&
        css`
            max-width: calc(50% - 40px);
            padding: 0;
            border: none;
            border-radius: 0;
        `}

    ${({ noBorder, isSelected, theme }) =>
        !noBorder &&
        isSelected &&
        css`
            border-color: ${theme.colors.primary.initial};
            box-shadow:
                0 0 0 1px ${theme.colors.primary.initial},
                0 2px 10px ${convertToRgba(HEX_COLORS.black, 0.15)};
        `}

    ${({ noBorder, isSelected, hasError, theme }) =>
        !noBorder &&
        !isSelected &&
        hasError &&
        css`
            border-color: ${theme.colors.danger.initial};
            box-shadow:
                0 0 0 1px ${theme.colors.danger.initial},
                0 2px 10px ${convertToRgba(HEX_COLORS.black, 0.15)};
        `}
`;

export const PartnerIconContainer = styled.div`
    z-index: 2;
    border-radius: 2px;
    height: 64px;
    width: 64px;
    margin-right: 16px;
    min-width: 64px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const InfosContainer = styled.div`
    width: 100%;
    overflow: hidden;
`;

export const LocationNameContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const LocationBrandBadge = styled(LocationDetailsBrandBadge)`
    margin-left: 8px;
    align-self: flex-start;
`;

export const LocationAddressContainer = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    white-space: pre-line;
    margin: 2px 0;
`;

export const LocationCodeContainer = styled.div`
    display: flex;
    font-size: 12px;
    line-height: 14px;
    color: ${HEX_COLORS.blackberry};
    font-weight: bold;

    & > span {
        color: ${({ theme }) => theme.colors.secondary.initial};
        margin-left: 2px;
    }
`;

export const LocationStatusText = styled(Text)`
    display: flex;
    align-items: center;
    line-height: 14px;
    margin-top: 2px;

    & > i {
        margin-right: 4px;
    }
`;
