import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

import { STATIC_APP_ASSETS_URL } from 'app/config';

export const ChatbotInstructionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const ChatbotInstructionSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ChatbotInstructionsButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

export const ImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
    height: 230px;
`;

export const ImageContainerBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-image: url('${STATIC_APP_ASSETS_URL}/common/chatbot_instruction_background.svg');
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const ImageWrapper = styled.div`
    position: relative;
    z-index: 1;
`;

export const PhoneImage = styled.img`
    width: 300px;
`;

export const PhoneImageBackground = styled.div`
    position: absolute;
    border-radius: 34px 34px 0 0;
    width: 263px;
    height: 217px;
    bottom: 0;
    margin: 0 18px;
    background: white;
    z-index: 1;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const PhoneImageShadow = styled.div`
    bottom: 0;
    position: absolute;
    left: 18px;
    right: 18px;
    height: 32px;
    opacity: 0.1;
    background: linear-gradient(180deg, white 0%, black 100%);
    z-index: 2;
`;

export const ConversationContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const MessageBubbleContainer = styled.div`
    border-radius: 20px;
    padding: 12px 16px;
    background: ${({ theme }) => theme.colors.slate.alpha};
    height: fit-content;
    width: 100%;
`;

export const MessageBubble = styled(Text)`
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    word-break: break-word;
    white-space: pre-line;
`;

export const LogoIconContainer = styled.div`
    display: flex;
    background: ${({ theme }) => theme.colors.slate.alpha};
    border-radius: 50px;
    padding: 8px;
    height: fit-content;
`;

export const HandoverInfoMessage = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin: 0 auto;
    align-items: center;
`;

export const CustomInstructionsCheckbox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    margin: 0 auto;
    width: 100%;
`;
