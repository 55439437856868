import React from 'react';

import { Modal } from '@partoohub/ui';

import useUser from 'app/common/hooks/queries/useUser';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { UserListQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

import UserReinviteContent from './UserReinviteContent';

const UserReinviteModal = () => {
    const [userInvite, setUserInvite] = useStateQueryParams(UserListQueryKeys.USER_INVITE);
    const { data: user } = useUser(userInvite, {
        enabled: !!userInvite && userInvite !== 'all',
    });

    return (
        <Modal isOpen={!!userInvite && userInvite !== 'all'} closeModal={() => setUserInvite('')}>
            <UserReinviteContent user={user} closeModal={() => setUserInvite('')} />
        </Modal>
    );
};

export default UserReinviteModal;
