import styled from '@emotion/styled';

import { oneColumnTable, threeColumnsTable } from 'app/deprecated.styled';

export const ThreeRowScrollerTable = styled.div`
    height: 244px;
    overflow-y: auto;
    overflow-anchor: none;

    @media (max-width: 769px) {
        height: 100%;
        overflow-y: initial;
    }
`;

export const GridInfiniteScroll = styled.div<{ isMobile?: boolean }>`
    ${({ isMobile }) => (isMobile ? oneColumnTable : threeColumnsTable)};
`;
