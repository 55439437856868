import styled from '@emotion/styled';

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    button {
        flex: 1;
    }
`;

export const IllustrationContainer = styled.div`
    padding-top: 24px;
`;
