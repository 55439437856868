import styled from '@emotion/styled';
import { Button } from '@partoohub/ui';

export const ShowMoreButton = styled(Button)`
    justify-content: flex-start;
`;

export const ShowLessButton = styled(Button)`
    justify-content: flex-start;
`;
