import styled from '@emotion/styled';

export const CarouselContainer = styled.div`
    position: relative;
    overflow: hidden;
`;

export const CarouselScroller = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;

    left: 0%;
    transition-property: left;
    transition-duration: 250ms;
`;

export const CarouselItem = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
`;
