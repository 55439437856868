import { useCallback } from 'react';

import { endOfMonth, format } from 'date-fns';

import BusinessModalButton from 'app/common/components/businessModal/BusinessModalButton';
import { FilterBusinessModalButton } from 'app/common/components/businessModal/components/FilterBusinessModalButton/FilterBusinessModalButton';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { ComparisonPeriodSelect } from 'app/pages/analytics/VisibilityAnalyticsV2/components/ComparisonPeriodSelect/ComparisonPeriodSelect';
import { FiltersSectionContainer } from 'app/pages/analytics/VisibilityAnalyticsV2/components/FiltersSection/FiltersSection.styled';
import { MonthYearPicker } from 'app/pages/analytics/VisibilityAnalyticsV2/components/MonthYearPicker/MonthYearPicker';
import {
    DATE_DEFAULT_VALUE,
    useGetDate,
} from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/params/useGetDate';
import { MetricsApiKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/types/query';
import {
    MIN_RETENTION_DATE,
    MONTH_FORMAT,
} from 'app/pages/analytics/VisibilityAnalyticsV2/utils/date';
import { AnalyticsQueryKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queryKeys';
import { DATE_FORMAT } from 'app/utils/dates';

export const FiltersSection = () => {
    const dates = useGetDate();
    const [dateFrom, setDateFrom] = useStateQueryParams(AnalyticsQueryKeys.DATE_FROM);
    const [dateTo, setDateTo] = useStateQueryParams(AnalyticsQueryKeys.DATE_TO);

    const currentDate = new Date();

    const minDate = MIN_RETENTION_DATE;
    const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const handleStartDate = useCallback(
        date => {
            setDateFrom(date ? format(date, DATE_FORMAT) : '');
        },
        [setDateFrom],
    );

    const handleEndDate = useCallback(
        date => {
            setDateTo(date ? format(endOfMonth(date), DATE_FORMAT) : '');
        },
        [setDateTo],
    );

    const handleReset = useCallback(() => {
        handleStartDate(null);
        handleEndDate(null);
    }, [handleStartDate, handleEndDate]);

    return (
        <FiltersSectionContainer>
            <BusinessModalButton
                key="business-modal-key"
                dataTrack="analytics_visiblity__business_filter"
                button={
                    <div>
                        <FilterBusinessModalButton />
                    </div>
                }
            />
            <MonthYearPicker
                startDate={dateFrom ? new Date(dateFrom) : new Date(dates.filter_date__gte)}
                endDate={dateTo ? new Date(dateTo) : new Date(dates.filter_date__lte)}
                handleReset={handleReset}
                monthFormat={MONTH_FORMAT}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                minDate={minDate}
                maxDate={maxDate}
                canReset={
                    dates.filter_date__gte !== DATE_DEFAULT_VALUE[MetricsApiKeys.FILTER_DATE_GTE] ||
                    dates.filter_date__lte !== DATE_DEFAULT_VALUE[MetricsApiKeys.FILTER_DATE_LTE]
                }
                dataTrack="analytics_visiblity__month_year_filter"
            />
            <ComparisonPeriodSelect dataTrack="analytics_visiblity__comparison_period_filter" />
        </FiltersSectionContainer>
    );
};
