import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

export const WelcomeMessage = styled(Text)`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines you want to display */
    -webkit-box-orient: vertical;
    line-height: 20px; /* Height of one line of text */
    max-height: 60px; /* Height = line-height * number of lines */
    text-overflow: ellipsis;
`;

export const ConversationStarterItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    max-width: 270px;
    max-height: 64px;
`;

export const ConversationStarterItems = styled.div<{ order: number }>`
    display: ${({ order }) => (order == 3 ? 'none' : 'flex')};
    padding: 4.5px 8px;
    align-items: center;
    border-radius: 20px;
    background: ${greyColorObject.light};
    white-space: nowrap;
    order: ${({ order }) => order};
`;
