import { ChangeEventHandler } from 'react';

import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { SelectButtonContainer, StyledSelect } from './SelectButton.styled';

interface Props {
    onBucketChange: ChangeEventHandler<HTMLSelectElement> | undefined;
    selectedBucket: Array<string>;
    options: Array<Record<string, any>>;
    text?: string;
}

export const SelectButton = ({ selectedBucket, onBucketChange, options, text }: Props) => {
    const { t } = useTranslation();
    return (
        <SelectButtonContainer>
            <Text variant="bodySBold" as="span" color="secondary">
                {text ? text : t('display_by')}: &nbsp;
            </Text>
            <StyledSelect name="bucket" value={selectedBucket} onChange={onBucketChange}>
                {options.map(option => (
                    <option
                        value={option.value}
                        disabled={option.disabled}
                        key={option.value + option.label}
                    >
                        {t(option.label)}
                    </option>
                ))}
            </StyledSelect>
        </SelectButtonContainer>
    );
};

export default SelectButton;
