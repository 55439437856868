import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { LiteralRating } from 'app/api/types/review';
import {
    NEGATIVE_REVIEWS,
    NEUTRAL_REVIEWS,
    POSITIVE_REVIEWS,
} from 'app/api/types/reviewReplyTemplates';
import { ChoiceWithSubtitle } from 'app/api/types/user';
import NegativeIcon from 'app/common/components/icons/NegativeIcon';
import NeutralIcon from 'app/common/components/icons/NeutralIcon';
import PositiveIcon from 'app/common/components/icons/PositiveIcon';

import { IconContainer, SelectButton, TextContainer } from './RatingButton.styled';

export const allRatingsOptions: Array<ChoiceWithSubtitle<LiteralRating>> = [
    {
        value: POSITIVE_REVIEWS,
        label: 'positive',
        subtitle: 'auto_reply_form_positive_review_description',
        icon: <PositiveIcon />,
    },
    {
        value: NEUTRAL_REVIEWS,
        label: 'neutral',
        subtitle: 'auto_reply_form_neutral_review_description',
        icon: <NeutralIcon />,
    },
    {
        value: NEGATIVE_REVIEWS,
        label: 'negative',
        subtitle: 'auto_reply_form_negative_review_description',
        icon: <NegativeIcon />,
    },
];

type Props = {
    option: ChoiceWithSubtitle<LiteralRating>;
    selected?: boolean;
    onClick: () => void;
};

const RatingButton = ({ option: { icon, label, subtitle }, selected, onClick }: Props) => {
    const { t } = useTranslation();

    return (
        <SelectButton onClick={onClick} selected={selected}>
            <IconContainer>{icon}</IconContainer>
            <TextContainer>
                <Text variant="bodyMBold">{t(label)}</Text>
                {subtitle && <Text color={'secondary'}>({t(subtitle)})</Text>}
            </TextContainer>
        </SelectButton>
    );
};

export default RatingButton;
