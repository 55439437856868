import { useState } from 'react';

import { validate as isValidEmail } from 'email-validator';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledTextInput } from 'app/businessEditV2/hookControllerWrapper/ControlledTextInput';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { UseConfirmModal } from 'app/common/components/ConfirmModalV2/useConfirmModal';
import useMe from 'app/common/hooks/queries/useMeUncamel';

import Flexbox from 'app/styles/utils/flexbox';

export const DownloadModal = ({
    confirm,
    modalProps,
    title,
    content,
}: UseConfirmModal<{ email: string }> & { title: string; content: string }) => {
    const { t } = useTranslation();
    const { data: me } = useMe();
    const [email, setEmail] = useState(me?.email ?? '');

    const {
        control,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({ defaultValues: { email: email } });
    const handleEmailOnChangeValue = (value: string | undefined) => {
        if (!value) {
            clearErrors('email');
            setEmail('');
        } else if (value && !isValidEmail(value)) {
            setError('email', { message: t('email_invalid') });
        } else {
            clearErrors('email');
            setEmail(value);
        }
        return value;
    };
    return (
        <ConfirmModal
            {...modalProps}
            title={title}
            content={content}
            modalFor="download"
            confirmLabel={t('send_email')}
            onConfirm={() =>
                confirm({
                    email,
                })
            }
            confirmDisabled={!email || !!errors.email}
        >
            <Flexbox flexDirection="column" gap="16px">
                <ControlledTextInput
                    name="email"
                    type="email"
                    control={control}
                    label={t('email_extended')}
                    dataTrackId="email_extended"
                    errorMessage={errors.email?.message}
                    hasError={!!errors.email}
                    onChangeValue={handleEmailOnChangeValue}
                />
            </Flexbox>
        </ConfirmModal>
    );
};
