import React from 'react';

import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import CloseIcon from 'app/common/components/icons/CloseIcon';
import MobilePageHeader from 'app/common/components/pageHeaders/MobilePageHeader';
import { useIsMobile } from 'app/common/services/screenDimensions';

import { BusinessTitle } from './BusinessModal.styled';
import FilterByBusinessChip from './FilterByBusinessChip.container';

type Props = {
    onHide: () => void;
    headerTitle: string;
};

const FilterByBusinessTitle = ({ onHide, headerTitle }: Props) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    return (
        <div>
            {isMobile ? (
                <MobilePageHeader
                    leftContent={<CloseIcon onClick={onHide} />}
                    title={t('businesses')}
                    rightContent={
                        <FilterByBusinessChip chipClass="left_reverse_margin" isMobile={isMobile} />
                    }
                />
            ) : (
                <>
                    <BusinessTitle>
                        <Text variant="heading4">{t(headerTitle)}</Text>
                    </BusinessTitle>
                    <FilterByBusinessChip />
                </>
            )}
        </div>
    );
};

export default FilterByBusinessTitle;
