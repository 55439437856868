import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';

type InvoicePlaceholderProps = {
    is_default: boolean;
    resetFilter?: () => void;
};

const InvoicePlaceholder = ({ resetFilter, is_default }: InvoicePlaceholderProps) => {
    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };
    return (
        <NotFoundTemplate
            show
            imgSrc={illustrationElement}
            title={is_default ? 'invoice_default_placeholder_title' : 'invoice_placeholder_title'}
            subtitle={
                is_default
                    ? 'invoice_default_placeholder_description'
                    : 'invoice_placeholder_description'
            }
            buttonText="invoice_placeholder_reset_btn_title"
            withButton={!is_default}
            handleClick={resetFilter}
        />
    );
};

export default InvoicePlaceholder;
