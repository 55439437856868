import { ConfirmDeleteCommentModal } from './ConfirmDeleteCommentModal';
import { DownloadReviewsModal } from './DownloadReviewsModal';

export const ReviewListModals = () => {
    return (
        <>
            <ConfirmDeleteCommentModal />
            <DownloadReviewsModal />
        </>
    );
};
