import { useTranslation } from 'react-i18next';

import Advice from './Advice';
import ErrorBanner from './ErrorBanner';
import FiltersBar from './FiltersBar';
import ReplyTemplateList from './ReplyTemplateList';
import { useSettingsPageContext } from '../../../hooks/useSettingsPageContext';

const TemplateSelectStep = () => {
    const { t } = useTranslation();
    const { forReviewsWithComment } = useSettingsPageContext();

    return (
        <>
            <Advice>
                {forReviewsWithComment
                    ? t('auto_reply_with_comment_template_select_advice')
                    : t('auto_reply_template_select_advice')}
            </Advice>
            <ErrorBanner />
            <FiltersBar />
            <ReplyTemplateList />
        </>
    );
};

export default TemplateSelectStep;
