import styled from '@emotion/styled';

export const EmojiPickerWrapper = styled.div<{ showOnLeft: boolean }>`
    position: absolute;
    z-index: 99;
    transform: translate(${({ showOnLeft }) => (showOnLeft ? '-280' : '40')}px, -40px);
    em-emoji-picker {
        height: 330px;
        border-radius: 4px;
        box-shadow:
            0 3px 14px 2px rgba(0, 0, 0, 0.12),
            0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 5px 5px -3px rgba(0, 0, 0, 0.2);
    }
`;
