import React, { useState } from 'react';

import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';

import { StyledShowMoreButton } from './ShowMoreText.styled';

type Props = {
    text: string;
    shortTextLength: number;
    keywordList: Array<RegExp>;
};

export default function ShowMoreText({ text, shortTextLength, keywordList }: Props) {
    const { t } = useTranslation();
    const [isShortened, setIsShortened] = useState(true);

    const expandText = () => setIsShortened(false);

    const shortenedText = text.slice(0, shortTextLength);
    const textIsTooLong = !!text.slice(shortTextLength);
    const showMoreButton = textIsTooLong && isShortened;

    return (
        <>
            <Highlighter
                highlightClassName="highlight_text"
                searchWords={keywordList}
                textToHighlight={isShortened ? shortenedText : text}
            />
            {showMoreButton && (
                <span>
                    {isShortened && '...'}
                    &nbsp;&nbsp;
                    <StyledShowMoreButton onClick={expandText}>
                        {t('show_more')}
                    </StyledShowMoreButton>
                </span>
            )}
        </>
    );
}
