import {
    ALERT_TYPE,
    COVID_TYPE,
    EVENT_TYPE,
    NEWS_TYPE,
    OFFER_TYPE,
} from 'app/presence/googlePosts/variables/googlePostType';

export const mapTypeToIcon = {
    [NEWS_TYPE]: 'fas fa-certificate',
    [OFFER_TYPE]: 'fas fa-tag',
    [EVENT_TYPE]: 'fas fa-calendar-day',
    [COVID_TYPE]: 'fas fa-first-aid',
    [ALERT_TYPE]: 'fas fa-first-aid',
};
