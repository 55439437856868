import { useTranslation } from 'react-i18next';

import {
    ConversationMessage,
    MESSAGE_CONTENT_TYPE,
    MESSAGE_SENDER_TYPE,
    MessageStoryReplyType,
} from 'app/states/messaging';

import { ImageBubble } from './ImageBubble';
import { MediaBubble } from './MediaBubble';
import { TextBubble } from './TextBubble';

import { StoryDetailHeader, StoryReplySpacer } from '../MessageBubble.styled';

interface IgStoryReplyBubbleProps {
    message: ConversationMessage;
    consumerName: string;
    handleBubbleClick: () => void;
}

export const IgStoryReplyBubble = ({
    message,
    consumerName,
    handleBubbleClick,
}: IgStoryReplyBubbleProps) => {
    const { t } = useTranslation();
    const isConsumerMessage = message.sender_type === MESSAGE_SENDER_TYPE.CONSUMER;
    const translationKey = isConsumerMessage
        ? 'messaging_meta_story_reply'
        : 'messaging_meta_story_reply_echo';
    const content = message.content as MessageStoryReplyType;

    return (
        <>
            <StoryDetailHeader variant="bodySRegular" as="span" isConsumer={isConsumerMessage}>
                {t(translationKey, { name: consumerName })}
            </StoryDetailHeader>
            <MediaBubble
                url={content.url}
                isConsumerMessage={isConsumerMessage}
                onClick={handleBubbleClick}
                isIgStory={true}
            />
            <StoryReplySpacer />
            {content.content_type === MESSAGE_CONTENT_TYPE.IMAGE ? (
                <ImageBubble
                    url={content.content}
                    isConsumerMessage={isConsumerMessage}
                    onClick={handleBubbleClick}
                />
            ) : (
                <TextBubble
                    text={content.content}
                    isConsumerMessage={isConsumerMessage}
                    onClick={handleBubbleClick}
                    isIgStory={true}
                />
            )}
        </>
    );
};
