import { Option } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import type { OverviewType } from 'app/api/types/competitiveBenchmark';

import { OverviewMenuList, OverviewSelectionContainer } from './OverviewSelection.styled';

type OverviewSelectionProps = {
    selection: OverviewType;
    setSelection: (option: OverviewType) => void;
};

export const OverviewSelection = ({ selection, setSelection }: OverviewSelectionProps) => {
    const { t } = useTranslation();
    const options: Option[] = [
        { name: 'rank', value: 'rank', label: t('ranking_word') },
        { name: 'review_count', value: 'review_count', label: t('reviews_count') },
        { name: 'average_rating', value: 'average_rating', label: t('average_grade') },
    ];

    return (
        <OverviewSelectionContainer>
            <OverviewMenuList
                sections={[{ options }]}
                itemType="radio"
                isChecked={option => option.value === selection}
                onChange={option => setSelection(option.value as OverviewType)}
            />
        </OverviewSelectionContainer>
    );
};
