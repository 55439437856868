import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import allowedSeasonalityOptions from 'app/common/services/allowedSeasonalityOptions';
import { Select } from 'app/pages/analytics/components/Select/Select';
import { CardTitle } from 'app/pages/analytics/VisibilityAnalyticsV2/components/CardTitle/CardTitle';
import { ErrorPlaceholder } from 'app/pages/analytics/VisibilityAnalyticsV2/components/ErrorPlaceholder/ErrorPlaceholder';
import { LoaderView } from 'app/pages/analytics/VisibilityAnalyticsV2/components/LoaderView/LoaderView';
import {
    MetricsTemplateChartContainer,
    MetricsTemplateChartGraph,
    MetricsTemplateChartHeader,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/Metrics/MetricsChart/MetricsTemplateChart/MetricsTemplateChart.styled';
import { NoAnalytics } from 'app/pages/analytics/VisibilityAnalyticsV2/components/NoAnalytics/NoAnalytics';
import { TrendChart } from 'app/pages/analytics/VisibilityAnalyticsV2/components/TrendChart/TrendChart';
import { useGetDate } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/params/useGetDate';
import { DimensionsDict } from 'app/pages/analytics/VisibilityAnalyticsV2/types/dimensions';
import { actionsColor } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/actions';
import { viewsColor } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/view';

type Props = {
    name: string;
    icon: string;
    dimensionKey: string;
    orderBykey: string;
    data: any;
    isLoading: boolean;
    refetch: () => void;
    error: unknown;
    dataTrack?: string;
};
export const MetricsTemplateChart = ({
    name,
    dimensionKey,
    orderBykey,
    data,
    isLoading,
    refetch,
    error,
    icon,
    dataTrack,
}: Props) => {
    const { t } = useTranslation();
    const [dimension, setDimension] = useStateQueryParams(dimensionKey);
    const [, setOrderBy] = useStateQueryParams(orderBykey);

    const [selectedData, setSelectedData] = useState(dimension || DimensionsDict.MONTH);

    const userDates = useGetDate();

    const dates = useMemo(
        () => ({
            from: userDates.filter_date__gte || new Date(),
            to: userDates.filter_date__lte || new Date(),
        }),
        [userDates],
    );

    const periodOptions = allowedSeasonalityOptions(dates);

    const [options, setOptions] = useState(periodOptions);

    const handleClick = useCallback((value: string) => {
        setDimension(value);
        setOrderBy(value);
        setSelectedData(value);
    }, []);

    const refetchChart = () => {
        refetch();
    };

    useEffect(() => {
        const newOptions = allowedSeasonalityOptions({
            from: userDates.filter_date__gte,
            to: userDates.filter_date__lte,
        });
        setOptions(newOptions);
        if (newOptions.find(option => option.value === selectedData)?.disabled) {
            setSelectedData(DimensionsDict.MONTH);
            setDimension(DimensionsDict.MONTH);
            setOrderBy(DimensionsDict.MONTH);
        }
    }, [userDates.filter_date__gte, userDates.filter_date__lte]);

    const getElementToReturn = () => {
        if (isLoading) {
            return <LoaderView />;
        }
        if (error) {
            return (
                <ErrorPlaceholder message={t('error_page_placeholder_title_app')}>
                    <StrikeThroughButton
                        color="primary"
                        onClick={refetchChart}
                        text={t('try_agin')}
                        icon={<i className="fa-solid fa-redo" />}
                    />
                </ErrorPlaceholder>
            );
        }
        return (
            <>
                {data ? (
                    <TrendChart
                        data={{
                            key: name,
                            userDates: dates,
                            ...data,
                            selectedBucket: selectedData,
                        }}
                    />
                ) : (
                    <NoAnalytics />
                )}
            </>
        );
    };

    return (
        <MetricsTemplateChartContainer data-track={dataTrack} data-intercom-target={dataTrack}>
            <MetricsTemplateChartHeader>
                <CardTitle
                    color={name === 'views' ? viewsColor : actionsColor}
                    icon={<i className={`fa fa-fas ${icon}`}></i>}
                >
                    {t(name)}
                </CardTitle>
                {!error && (
                    <Select
                        label={`${t('display_by')} : `}
                        handleClick={handleClick}
                        options={options}
                        selectedValue={selectedData}
                    />
                )}
            </MetricsTemplateChartHeader>
            <MetricsTemplateChartGraph>{getElementToReturn()}</MetricsTemplateChartGraph>
        </MetricsTemplateChartContainer>
    );
};
