import { useTranslation } from 'react-i18next';

import { V2FormattedBusinessData } from 'app/api/types/business';
import {
    BusinessAddressContainer,
    BusinessCardContainer,
    BusinessCodeContainer,
    BusinessImage,
    BusinessNameContainer,
    InfosContainer,
} from 'app/businessConnection/components/common/BusinessCard/BusinessCard.styled';
import { LoadingBusinessCard } from 'app/businessConnection/components/commonOld/LoadingBusinessCard';
import { STATIC_BASE_URL } from 'app/config';

type Props = {
    business?: V2FormattedBusinessData;
    noBorder?: boolean;
};

const BusinessCard = ({ business, noBorder }: Props) => {
    const { t } = useTranslation();

    if (!business) {
        return <LoadingBusinessCard />;
    }

    return (
        <BusinessCardContainer noBorder={noBorder}>
            <BusinessImage
                alt={business.name}
                src={
                    business.logoUrl || `${STATIC_BASE_URL}/images/app/square_image_placeholder.png`
                }
            />
            <InfosContainer>
                <BusinessNameContainer>{business.name}</BusinessNameContainer>
                <BusinessAddressContainer>
                    <>
                        <div>{business.addressFull}</div>
                        <div>
                            {business.zipcode} {business.city}
                        </div>
                    </>
                </BusinessAddressContainer>
                {business.code && (
                    <BusinessCodeContainer>
                        {`${t('store_code')}: `}
                        <span>{business.code}</span>
                    </BusinessCodeContainer>
                )}
            </InfosContainer>
        </BusinessCardContainer>
    );
};

export default BusinessCard;
