import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import useGroupIds from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupIds';
import useGroups from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroups';

const GROUP_DEFAULT_VALUE = 'all';

export enum BusinessGroupApiKey {
    GROUP_ID = 'group_id',
    GROUP_ID_IN = 'group_id__in',
    GROUP_ID_IS_NULL = 'group_id__is_null',
}

export const useGetGroupFilter = () => {
    // Old groups
    const { groups: oldGroups } = useGroups();
    const [currentGroupId, currentSubgroupId] = useGroupIds();

    const enableNewGroups = useNewGroups();

    // New groups
    const { groups } = useBusinessModalFilters();

    // Delete everything else once new groups are released
    if (enableNewGroups) {
        return { ...(groups ? { groups } : {}) };
    }

    const query = {};

    // In case groups are not loaded yet
    if (
        (!isNaN(Number(currentGroupId)) || !isNaN(Number(currentSubgroupId))) &&
        !oldGroups.length
    ) {
        return query;
    }

    // Ungrouped
    if (currentGroupId === 'ungrouped') {
        query[BusinessGroupApiKey.GROUP_ID_IS_NULL] = true;
    }

    // Group (with and without subgroups)
    else if (currentGroupId && !currentSubgroupId && currentGroupId !== GROUP_DEFAULT_VALUE) {
        const group = oldGroups.find(group => group.groupId.toString() === currentGroupId);
        const groupId = group?.groupId.toString();
        const subgroups = group?.subgroups?.map(subgroup => subgroup.groupId.toString()) || [];
        query[BusinessGroupApiKey.GROUP_ID_IN] = [groupId, ...subgroups].join(',');

        // Has no subgroups
        if (subgroups?.length === 0) {
            query[BusinessGroupApiKey.GROUP_ID] = groupId;
        }

        // Subgroups
    } else if (currentGroupId && currentSubgroupId) {
        if (currentGroupId === currentSubgroupId) {
            query[BusinessGroupApiKey.GROUP_ID] = currentSubgroupId;
        } else {
            query[BusinessGroupApiKey.GROUP_ID_IN] = currentSubgroupId;
        }
    }

    return query;
};
