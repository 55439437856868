import { useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import ExportModal from '../../ExportModal/ExportModal';

export const ExportButton = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <IconButton
                dataTrackId="competitive_benchmark__export__icon_button"
                appearance="outlined"
                variant="default"
                tooltip={t('download_data')}
                onClick={handleClick}
                icon={[FontAwesomeIconsPartooUsed.faArrowDownToLine]}
            />
            <ExportModal isOpen={isOpen} toggle={handleClick} />
        </>
    );
};
