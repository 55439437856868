import { FontAwesomeIconsPartooUsed, IconButton } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import dataLayer from 'app/common/utils/dataLayer';

import { useSettingReviewsSummary } from 'app/pages/settingsV2/hooks/Reviews/useSettingReviewsSummary';
import { AnalyticsButton } from 'app/reviewManagement/reviewList/sections/PageHeader/DesktopHeader/components/AnalyticsButton';
import { DownloadButton } from 'app/reviewManagement/reviewList/sections/PageHeader/DesktopHeader/components/DownloadButton';
import { PageHeaderWithAvatar } from 'app/routing/components/PageHeaderWithAvatar';
import { SETTINGS_REVIEWS_PATHNAME } from 'app/routing/routeIds';

export const ReviewListPageHeader = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        settingData: { display: displayReviewsSetting },
    } = useSettingReviewsSummary();

    const onViewReviewsSettingsClick = (): void => {
        dataLayer.pushDict('click_auto_reply_settings_button');
        navigate(`${SETTINGS_REVIEWS_PATHNAME}`);
    };

    const getPageButtons = () => {
        const buttonList = [];

        if (displayReviewsSetting) {
            buttonList.unshift(
                <IconButton
                    key="auto-reply-button"
                    dataTrackId="review_header_button_to_auto_reply_settings"
                    appearance="outlined"
                    variant="default"
                    tooltip={t('reviews_settings_tooltip')}
                    icon={[FontAwesomeIconsPartooUsed.faGear]}
                    tooltipPlacement="bottom"
                    onClick={onViewReviewsSettingsClick}
                />,
            );
        }

        buttonList.unshift(<AnalyticsButton key="review-analytics-button" />);
        buttonList.unshift(<DownloadButton key="review-download-button" />);

        return buttonList;
    };

    return <PageHeaderWithAvatar title={t('reviews')} actions={getPageButtons()} />;
};
