import React from 'react';

import GoogleVerificationCardList from '../GoogleVerificationCardList/GoogleVerificationCardList';
import GoogleVerificationListingHeader from '../GoogleVerificationListingHeader/GoogleVerificationListingHeader';

const GoogleVerificationSection = () => (
    <>
        <GoogleVerificationListingHeader />
        <GoogleVerificationCardList />
    </>
);

export default GoogleVerificationSection;
