import { MouseEvent, useState } from 'react';

import { Text } from '@partoohub/ui';

import { ChatbotKnowledgeForm } from 'app/pages/settings/Chatbot/components/Knowledge/ChatbotKnowledgeForm';
import {
    ChatbotKnowledgeItemAnswer,
    ChatbotKnowledgeItemContainer,
    ChatbotKnowledgeItemContent,
    ChatbotKnowledgeItemDeleteButton,
} from 'app/pages/settings/Chatbot/components/Knowledge/ChatbotKnowledgeItem.styled';
import { ChatbotKnowledgeQAContentItem } from 'app/pages/settings/Chatbot/type/chatbot';

interface Props {
    knowledgeItem: ChatbotKnowledgeQAContentItem;
    index: number;
    onUpdate: (data: ChatbotKnowledgeQAContentItem, index: number) => void;
    onDelete: (index: number) => void;
}

export const ChatbotKnowledgeItem = ({ knowledgeItem, index, onUpdate, onDelete }: Props) => {
    const [isClicked, setIsClicked] = useState(false);
    const onSubmit = (newKnowledgeItem: ChatbotKnowledgeQAContentItem) => {
        onUpdate(newKnowledgeItem, index);
        setIsClicked(false);
    };

    const onDeleteClick = (e: MouseEvent) => {
        e.stopPropagation();
        onDelete(index);
    };

    return (
        <>
            {!isClicked ? (
                <ChatbotKnowledgeItemContainer onClick={() => setIsClicked(true)}>
                    <ChatbotKnowledgeItemContent>
                        <Text variant="bodyMBold">{knowledgeItem.question}</Text>
                        <ChatbotKnowledgeItemAnswer>
                            {knowledgeItem.answer}
                        </ChatbotKnowledgeItemAnswer>
                    </ChatbotKnowledgeItemContent>
                    <ChatbotKnowledgeItemDeleteButton
                        dataTrackId="chatbot_knowledge_item_delete"
                        onClick={onDeleteClick}
                        icon={['fa-trash']}
                        appearance="outlined"
                        size="small"
                    />
                </ChatbotKnowledgeItemContainer>
            ) : (
                <ChatbotKnowledgeForm
                    knowledgeItem={knowledgeItem}
                    onCancel={() => setIsClicked(false)}
                    onSubmit={onSubmit}
                />
            )}
        </>
    );
};
