export const HOURS = 'hours';
export const CATEGORIES = 'categories';
export const DESCRIPTIONS = 'descriptions';
export const CONTACT = 'contact';
export const URLS = 'urls';

export const ALL_SECTIONS = [HOURS, CATEGORIES, DESCRIPTIONS, CONTACT, URLS];

export const SECTIONS_TEXT = {
    hours: 'bulk_category_hours',
    categories: 'categories',
    descriptions: 'bulk_category_description',
    contact: 'contact',
    urls: 'urls',
};
