import { connect } from 'react-redux';

import { emailSelector } from 'app/states/connection/reducers/login';
import { AppState } from 'app/states/reducers';

import LoginSsoFormComponent from './LoginSsoForm';

const mapStateToProps = ({ login }: AppState) => ({
    defaultEmail: emailSelector(login),
});

const LoginSsoForm = connect(mapStateToProps)(LoginSsoFormComponent);

export default LoginSsoForm;
