import { HEX_COLORS } from '@partoohub/branding';

export const viewsColor = {
    hex: HEX_COLORS.ratings,
    rgba: 'rgba(247, 194, 0, 0.12)',
};

export const viewsChartColor = {
    light: '#ffdd63',
    normal: '#f7c200',
    dark: '#c69b00',
};

export const viewsHelperContent = [
    {
        key: 'views-mobile',
        icon: 'fa-mobile',
        title: 'mobile_word',
        description: 'analytics_mobile_description',
        color: viewsColor,
    },
    {
        key: 'views-desktop',
        icon: 'fa-desktop',
        title: 'desktop_word',
        description: 'analytics_desktop_description',
        color: viewsColor,
    },
];
