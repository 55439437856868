import { useRef, useState } from 'react';

import { ArcElement, Chart as ChartJS } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { ChartDoughnutOverlay } from 'app/pages/visibility/Diffusion/sections/DiffusionChart/DoughnutLegend/DougnhutLegend.styled';
import { publisherStateStatsLabels } from 'app/presence/management/constants/publisherStateStatsLabels';
import { useHasPublisherStates } from 'app/presence/management/hooks/useHasPublisherStates';
import { usePublishersStateStatsTotal } from 'app/presence/management/hooks/usePublishersStateStatsTotal';
import { getDoughnutDatasets } from 'app/presence/management/utils/getDoughnutDatasets';
import { GraphTypes } from 'app/utils/chartjs/graphTypes';
import { buildOptionsForGraphic } from 'app/utils/chartjs/options';

import { VisibilityChartGraph } from './VisibilityChart.styled';
import { DoughnutLegend } from '../DoughnutLegend/DoughnutLegend';
import { DoughnutOverlay } from '../DoughnutOverlay/DoughnutOverlay';

ChartJS.register(ArcElement);

export const VisibilityChart = () => {
    const hasPublisherStates = useHasPublisherStates();
    const { t } = useTranslation();
    const chartRef = useRef<ChartJS>(null);
    const type = GraphTypes.DOUGHNUT;
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const { publishersStateStatsTotal, isLoading } = usePublishersStateStatsTotal();

    const { averageGrade, percentage, color, datasets, labelValues } = getDoughnutDatasets({
        publishersStateStatsTotal,
        hoveredIndex,
    });

    const handleHover = (_: any, event: any) => {
        setHoveredIndex(event?.[0] != null ? event[0].index : null);
    };

    const options: any = {
        ...buildOptionsForGraphic(type),
        cutout: '62%',
        onHover: handleHover,
    };

    const data: any = {
        labels: publisherStateStatsLabels.map(label => t(label)),
        datasets,
    };

    return (
        <VisibilityChartGraph>
            <ChartDoughnutOverlay
                data-track="visibility_diffusion__graph"
                data-intercom-target="visibility_diffusion__graph"
            >
                <Doughnut ref={chartRef as any} data={data} options={options} />
                <DoughnutOverlay
                    averageGrade={averageGrade}
                    percentage={percentage}
                    color={color ?? 'success'}
                    isLoading={isLoading}
                    hasPublisherStates={hasPublisherStates}
                />
            </ChartDoughnutOverlay>
            <DoughnutLegend labelValues={labelValues} isLoading={isLoading} />
        </VisibilityChartGraph>
    );
};
