import { InfiniteData, useQueryClient } from 'react-query';

import { InteractionTagType } from 'app/api/types/interactionTag';

import { REVIEW_LIST } from 'app/common/data/queryKeysConstants';

import { ReviewListQueryData, useReviewListQueryKey } from '../useGetReviews';

export const useUpdateReviewTagsQueryData = () => {
    const queryClient = useQueryClient();
    const queryKey = useReviewListQueryKey();

    const updateReviewTagsQueryData = (tag: InteractionTagType, toDelete: boolean = false) => {
        /*
         ** Function to apply changes in tag list on all reviews displayed.
         ** toDelete should be set to true for a tag deletion, false for an update
         */

        const currentData = queryClient.getQueryData<InfiniteData<ReviewListQueryData>>(queryKey, {
            exact: true,
        });
        if (!currentData) return;

        const newPages = currentData.pages.map(page => {
            const newItems = page.items.map(item => {
                const itemTagIdx = item.tags.findIndex(itemTag => itemTag.id === tag.id);
                if (itemTagIdx !== -1) {
                    if (toDelete) {
                        return { ...item, tags: item.tags.toSpliced(itemTagIdx, 1) };
                    }
                    return { ...item, tags: item.tags.toSpliced(itemTagIdx, 1, tag) };
                }
                return item;
            });
            return { ...page, items: newItems };
        });

        queryClient.setQueryData<InfiniteData<ReviewListQueryData>>(queryKey, {
            ...currentData,
            pages: newPages,
        });
        queryClient.invalidateQueries({
            queryKey: [REVIEW_LIST],
            refetchActive: false,
        });
    };

    return updateReviewTagsQueryData;
};
