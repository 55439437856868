import { css } from '@emotion/react';

import {
    ActionButtonWithMenu as BaseActionButtonWithMenu,
    Props,
} from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';

export const ActionButtonWithMenu = (props: Props) => {
    return (
        <BaseActionButtonWithMenu
            {...props}
            cssMenu={css`
                min-width: 160px;
            `}
        />
    );
};
