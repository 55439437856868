import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import UserEditForm from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm';
import {
    USER_ID_CREATE,
    UserListQueryKeys,
} from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';
import { SETTINGS_MAIN_PROFILE_PATHNAME } from 'app/routing/routeIds';

const UserEditModal = () => {
    const { t } = useTranslation();
    const { data: me } = useMe();
    const [userId, setUserId] = useStateQueryParams(UserListQueryKeys.USER_EDIT);
    const navigate = useNavigate();

    if (me === undefined) {
        return null;
    }

    if (me.id === userId) {
        navigate(SETTINGS_MAIN_PROFILE_PATHNAME);
    }

    const childrenLeft = <UserEditForm currentUser={me} />;
    const isCreation = userId === USER_ID_CREATE;

    // TODO MORTY USERS: move EditFormDescription in common ?
    const childrenRight = (
        <EditFormDescription
            title={t('user_advice_title')}
            description={t(isCreation ? 'user_creation_advice' : 'user_edit_advice')}
            advice={t(isCreation ? 'user_creation_tip' : 'user_edit_tip')}
        />
    );

    return (
        <FullScreenModal
            childrenLeft={childrenLeft}
            childrenRight={childrenRight}
            show={!!userId}
            onHideClick={() => setUserId('')}
        />
    );
};

export default UserEditModal;
