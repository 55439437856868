import { useState } from 'react';

import {
    DynamicFilters,
    FilterCategory,
    FilterName,
    Filters as FiltersType,
    PartialFilters,
} from '@partoohub/modular-components';
import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { SelectableContentFilters } from 'app/common/components/SelectableContentFilters/SelectableContentFilters';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { useHideIntercom } from 'app/hooks/useHideIntercom';
import {
    ConnectionEnum,
    ConnectionQueryKeys,
} from 'app/pages/settingsV2/subPages/Platforms/components/Connection/utils/enums';
import { SelectableContentOption } from 'app/pages/visibility/BusinessListV2/utils/enums';
import {
    concatTrueValues,
    createFilterMap,
} from 'app/pages/visibility/BusinessListV2/utils/helpers';

const Filters = () => {
    const { t } = useTranslation();

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [publisher, setPublisher] = useStateQueryParams(ConnectionQueryKeys.PUBLISHER);

    const filters = {
        [FilterName.SOCIAL_MEDIA]: createFilterMap(publisher),
    };

    const setFilters = (filters: PartialFilters) => {
        setPublisher(concatTrueValues(filters, FilterName.SOCIAL_MEDIA));
    };

    const generateFilterOptions = () => {
        const publisherOptionValues: Array<SelectableContentOption> = [
            {
                name: ConnectionEnum.FACEBOOK,
                icon: [FontAwesomeIconsPartooUsed.faFacebook, IconPrefix.BRANDS],
                text: 'Facebook',
                value: ConnectionEnum.FACEBOOK,
            },
            {
                name: ConnectionEnum.GOOGLE,
                icon: [FontAwesomeIconsPartooUsed.faGoogle, IconPrefix.BRANDS],
                text: 'Google',
                value: ConnectionEnum.GOOGLE,
            },
        ];

        return publisherOptionValues.map(option => ({
            name: option.name,
            label: <SelectableContentFilters icon={option.icon} text={t(option.text)} />,
            value: option.value,
        }));
    };

    const optionsPublisher = generateFilterOptions();

    const schema: FiltersType = [
        {
            id: ConnectionQueryKeys.PUBLISHER,
            property: FilterName.SOCIAL_MEDIA,
            sectionName: t('connections_filter_publisher'),
            filterCategory: FilterCategory.SELECTABLE_RADIO,
            options: optionsPublisher,
        },
    ];

    useHideIntercom(isFilterOpen);

    return (
        <DynamicFilters
            schema={schema}
            filters={filters as PartialFilters}
            onApply={setFilters}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            resetText={t('reset')}
            applyText={t('apply')}
            title={t('filters')}
            buttonText={t('filters')}
        />
    );
};

export default Filters;
