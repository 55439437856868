import React from 'react';

import { useTranslation } from 'react-i18next';

import useFormattedBusinessAttributesUrl from 'app/businessEditV2/hooks/attributes/attributesBusiness/useFormattedBusinessAttributesUrl';
import useFormattedAttributesUrl from 'app/businessEditV2/hooks/attributes/attributesMetadata/useFormattedAttributesUrl';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import { AdditionalUrls } from 'app/businessEditV2/sections/LinksSection/LinksSectionContent.styled';

type Props = {
    colorState?: ColorState;
};

const LinksSectionContent = ({ colorState = ColorState.OK }: Props) => {
    const { t } = useTranslation();
    const { data: business } = useBusiness();
    const attributesUrls = useFormattedAttributesUrl();
    const businessAttributesUrl = useFormattedBusinessAttributesUrl();

    const businessAttributesUrlSetLength = businessAttributesUrl.filter(
        attribute => attribute.value !== null,
    ).length;

    switch (colorState) {
        case ColorState.PRECHECK_ERRORS:
            return <>{t('business_edit_content_correct_this_field')}</>;
        case ColorState.MISSING_WARNING:
        case ColorState.DISABLED:
        case ColorState.CLOSED:
            return <>{t('business_edit_content_links_add_socials')}</>;
        case ColorState.OK:
            return (
                <>
                    <i className="fa-solid fa-globe" />
                    <span>{business?.website_url ? 1 : 0}</span>
                    <i className="fa-brands fa-facebook" />
                    <span>{business?.facebook_url ? 1 : 0}</span>
                    <i className="fa-brands fa-twitter" />
                    <span>{business?.twitter_url ? 1 : 0}</span>
                    {attributesUrls.length > 0 && (
                        <AdditionalUrls>
                            <i className="fa-solid fa-link" />
                            <span>
                                {t('business_edit_additional_links_count', {
                                    count: businessAttributesUrlSetLength,
                                })}
                            </span>
                        </AdditionalUrls>
                    )}
                </>
            );
        default:
            return null;
    }
};

export default LinksSectionContent;
