import { useDispatch } from 'react-redux';

import { AnyAction } from 'redux';

import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import {
    APPLY_FILTER_BY_BUSINESS_FILTERS,
    FilterByBusinessState,
    citySelector,
    excludedIdsSelector,
    filterByBusinessSelector,
    includedIdsSelector,
    modeSelector,
    orgSelector,
    querySelector,
    resetBusinessFilter,
    resetPendingFilter,
    setIncludedIdsFilter,
    showFilterByBusiness,
} from 'app/common/components/businessModalDeprecated/reducers';
import { BUSINESSES, GROUPS } from 'app/common/data/queryKeysConstants';
import { useReduxEffect } from 'app/common/hooks/useReduxEffect';
import { UNGROUPED_ID } from 'app/common/reducers/groups';
import {
    applyBusinessPendingFilter,
    resetBusinessState,
    setBusinessGroupIdFilter,
    setBusinessGroupIdInFilter,
    updateSearchQuery,
    updateSelectedCity,
} from 'app/common/reducers/newBusinesses';
import useGroupData from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupData';
import queryClient from 'app/states/queryClient';
import { AppState } from 'app/states/reducers';

const LIST_V2_OPEN_BUSINESS_MODAL = 'LIST_V2_OPEN_BUSINESS_MODAL';
const LIST_V2_CLOSE_BUSINESS_MODAL = 'LIST_V2_CLOSE_BUSINESS_MODAL';

type OpenManageModalType = {
    type: 'LIST_V2_OPEN_BUSINESS_MODAL';
    isUpdate: boolean;
};

type CloseBusinessModalType = {
    type: 'LIST_V2_CLOSE_BUSINESS_MODAL';
};

// Custom action to setup and open the business modal
export const openManageModal = (isUpdate): OpenManageModalType => ({
    type: LIST_V2_OPEN_BUSINESS_MODAL,
    isUpdate,
});

// Custom action indicating the business modal is closing
export const closeBusinessModal = (): CloseBusinessModalType => ({
    type: LIST_V2_CLOSE_BUSINESS_MODAL,
});

/**
 * Cannot use the filters generated by the business modal directly because the page still use the old business modal
 * Please kill it with fire with new groups
 */
const filterQueryParams = (filterByBusiness: FilterByBusinessState) => {
    const mode = modeSelector(filterByBusiness);
    const query = querySelector(filterByBusiness);
    const city = citySelector(filterByBusiness);
    const orgId = orgSelector(filterByBusiness);
    let businessesIn: any = includedIdsSelector(filterByBusiness);

    if (mode === 'select' && !businessesIn && !(query || city)) {
        businessesIn = [];
    }

    let businessesNotIn: any = excludedIdsSelector(filterByBusiness);

    if (mode === 'unselect' && !businessesNotIn && !(query || city)) {
        businessesNotIn = [];
    }

    return {
        business__in: businessesIn,
        business__notin: businessesNotIn,
        business_query: query,
        business_city: city,
        business_org_id: orgId,
    };
};

export default function useHandleGroupFakeSagas() {
    const dispatch = useDispatch();
    const currentGroup = useGroupData();

    // Called to setup and open the business modal
    const openBusinessModal = async (action: OpenManageModalType) => {
        if (!currentGroup) return;

        // No idea what we are doing here
        dispatch(resetPendingFilter('all'));

        if (action.isUpdate && currentGroup.groupId) {
            const response = await api.groups.getBusinessInGroup(currentGroup.groupId);
            dispatch(setIncludedIdsFilter(response.ids));
        }

        // Filters for the business search in the business modal
        dispatch(setBusinessGroupIdFilter(currentGroup?.parentId ?? UNGROUPED_ID));
        dispatch(setBusinessGroupIdInFilter(null));

        // Open the modal
        dispatch(showFilterByBusiness());
    };

    useReduxEffect(
        (action: OpenManageModalType) => {
            openBusinessModal(action);
        },
        LIST_V2_OPEN_BUSINESS_MODAL,
        [dispatch, currentGroup],
    );

    // Called when closing the business modal
    useReduxEffect(
        () => {
            setTimeout(function () {
                dispatch(resetBusinessFilter());
                dispatch(updateSelectedCity(''));
                dispatch(updateSearchQuery(''));
                dispatch(applyBusinessPendingFilter());
            }, 1000);
        },
        LIST_V2_CLOSE_BUSINESS_MODAL,
        [dispatch],
    );

    // Update businesses in a group. Handle when validating the business modal
    const handleApplyFilterByBusinessFilters = async (_: AnyAction, state: AppState) => {
        const filterByBusiness = filterByBusinessSelector(state);
        const payload = filterQueryParams(filterByBusiness);
        const groupId = currentGroup?.groupId;

        // @ts-ignore
        await api.groups.updateBusinessInGroup(payload, groupId);

        dispatch(resetBusinessFilter());

        // Reset byIds and ids in redux
        dispatch(resetBusinessState());
        queryClient.invalidateQueries(invalidatesAny(GROUPS));

        // Important to reset, to not save cached data in redux by the hook business search
        queryClient.resetQueries(invalidatesAny(BUSINESSES));
    };

    useReduxEffect(
        (_, state) => {
            handleApplyFilterByBusinessFilters(_, state);
        },
        APPLY_FILTER_BY_BUSINESS_FILTERS,
        [dispatch, currentGroup],
    );
}
