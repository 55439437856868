import { KeyboardEvent, useState } from 'react';

import { Button, TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { GROUP_MANAGER } from 'app/common/data/roles';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import useMe from 'app/common/hooks/queries/useMe';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { KeyCode } from 'app/common/types/keyboard';
import { useGroupContext } from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupContext';
import useGroupCreate from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupCreate';
import { openManageModal } from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupFakeSaga';
import useGroupIds from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupIds';
import useGroups from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroups';
import { BusinessListQueryKeys } from 'app/pages/visibility/BusinessListV2/utils/enums';

import { ModalButtons, ModalContainer, ModalSubtitle, ModalTitle } from './CreateGroupModal.styled';

type Props = {
    closeModal: () => void;
};

const CreateGroupModal = ({ closeModal }: Props) => {
    const { t } = useTranslation();
    const { groups } = useGroups();
    const { createGroup } = useGroupContext();
    const [currentGroupId] = useGroupIds();
    const { data: me } = useMe();
    const [, setGroupUrl] = useStateQueryParams(BusinessListQueryKeys.GROUP);
    const dispatch = useDispatch();

    const [name, setName] = useState<string>('');

    const groupCreate = useGroupCreate(group => {
        createGroup(groups, group);
        closeModal();

        // Navigate to created group
        setGroupUrl(group.parentId ? `${group.parentId},${group.groupId}` : group.groupId);

        dispatch(openManageModal(false));
    });

    const errorMessage = groupCreate.error?.response?.data?.errors?.json;

    const parentGroupId = me?.role === GROUP_MANAGER && me?.groupId ? me.groupId : currentGroupId;

    const onSubmit = () => {
        if (!groupCreate.isLoading) {
            groupCreate.mutate({
                name,
                parent_id: isSubGroupCreation ? parentGroupId : null,
            });
        }
    };

    const isSubGroupCreation =
        me?.role === GROUP_MANAGER ||
        ![null, undefined, '', 'all', 'ungrouped'].includes(currentGroupId);

    const handlePressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyCode.Enter) {
            onSubmit();
        }
    };
    return (
        <ModalContainer>
            <ModalTitle>{isSubGroupCreation ? t('add_sub_group') : t('create_a_group')}</ModalTitle>
            <ModalSubtitle>
                {isSubGroupCreation
                    ? t('create_subgroup_modal_explanation')
                    : t('create_group_modal_explanation')}
            </ModalSubtitle>
            <TextInput
                dataTrackId={
                    isSubGroupCreation ? t('name_of_the_subgroup') : t('name_of_the_group')
                }
                value={name}
                label={isSubGroupCreation ? t('name_of_the_subgroup') : t('name_of_the_group')}
                onChange={name => setName(name ?? '')}
                onKeyUp={handlePressEnter}
                error={!!errorMessage}
                notice={!!errorMessage && t(errorMessage)}
            />
            <ModalButtons>
                <StrikeThroughButton text={t('cancel')} color={'secondary'} onClick={closeModal} />
                <Button
                    dataTrackId="old_create_group__button"
                    variant="primary"
                    shape="cube"
                    full
                    onClick={onSubmit}
                    className="margin_left--simple"
                    disabled={!name}
                >
                    {t('apply')}
                </Button>
            </ModalButtons>
        </ModalContainer>
    );
};

export default CreateGroupModal;
