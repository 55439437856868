import React from 'react';

import { Modal } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import useCustomFields from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useCustomFields';
import { CustomFieldsQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

import CustomFieldsDeleteContent from './CustomFieldsDeleteContent';

const CustomFieldsDeleteModal = () => {
    const customFields = useCustomFields();
    const [customFieldDeleteId, setCustomFieldDeleteId] = useStateQueryParams(
        CustomFieldsQueryKeys.DELETE,
    );

    const customField = customFields.find(cf => cf.id?.toString() === customFieldDeleteId);
    const closeModal = () => setCustomFieldDeleteId('');

    return (
        <Modal isOpen={!!(customFieldDeleteId && customField)} closeModal={closeModal}>
            {!!customField && (
                <CustomFieldsDeleteContent customField={customField} closeModal={closeModal} />
            )}
        </Modal>
    );
};

export default CustomFieldsDeleteModal;
