import React from 'react';

const BackgroundDetail = React.forwardRef<SVGSVGElement>((_, ref) => (
    <svg
        ref={ref}
        width="165"
        height="167"
        viewBox="0 0 165 167"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.6"
            d="M51.8171 87.9991C37.6572 102.037 43.965 127.777 27.0636 138.357C-1.44559 156.204 -57.3769 126.721 -41.2589 70.236C-33.883 44.3876 -64.9018 42.8238 -55.791 17.5494C-45.8995 -9.89083 -4.32203 -7.07647 20.569 -22.2796C49.0074 -39.6494 62.2223 -74.9685 91.7755 -59.524C111.25 -49.3468 124.04 -36.9365 126.073 -27.4675C129.141 -13.175 122.68 2.3799 116.777 21.9374C110.681 42.1301 119.854 57.7921 101.904 68.8659C82.9081 80.5856 67.6675 72.2854 51.8171 87.9991Z"
            stroke="#98F4D2"
            strokeWidth="0.8"
        />
        <g filter="url(#filter0_d_22641_119183)">
            <path
                d="M74.302 79.2429C72.9085 76.6203 73.9049 73.3646 76.5275 71.9711C79.1501 70.5776 82.4058 71.5739 83.7993 74.1965C85.1928 76.8191 84.1965 80.0749 81.5739 81.4684C78.9513 82.8619 75.6956 81.8655 74.302 79.2429Z"
                fill="#98F4D2"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_22641_119183"
                x="63.6721"
                y="61.3413"
                width="30.7571"
                height="30.7568"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.597353 0 0 0 0 0.956465 0 0 0 0 0.823346 0 0 0 1 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_22641_119183"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_22641_119183"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
));

export default BackgroundDetail;
