import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

import formatParams from './utils';

const RESOURCE_URI = 'supported_administrative_regions';

class AdministrativeRegions extends ApiResourceBaseClass {
    static getCountries(): Promise<Record<string, any>> {
        return AdministrativeRegions.get(`${RESOURCE_URI}`).then(
            ({ data }) => data,
        );
    }

    static getRegions(countryCode: string): Promise<Record<string, any>> {
        if (countryCode) {
            return AdministrativeRegions.get(
                `${RESOURCE_URI}`,
                formatParams({
                    code: countryCode,
                }),
            ).then(({ data }) => data);
        }

        // @ts-ignore
        return [];
    }
}

export default AdministrativeRegions;
