import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { BUSINESS_EDIT_FIELDS, CUSTOM_FIELDS } from 'app/common/data/queryKeysConstants';

export default function usePermissions() {
    const businessId = useGetBusinessId();
    return useQuery(
        [BUSINESS_EDIT_FIELDS, CUSTOM_FIELDS, { businessId }],
        () => api.business.getBusinessEditFields(businessId),
        {
            enabled: !!businessId,
        },
    );
}
