import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls/camel';
import { MESSAGING_REPLY_TEMPLATES } from 'app/common/data/queryKeysConstants';
import { SortingOptions, USAGE } from 'app/states/messageReplyTemplate/dataTypes';

const useGetMessageReplyTemplates = (
    orderBy: SortingOptions = USAGE,
    onlyUserCreated = false,
    enabled = true,
) => {
    return useQuery(
        [MESSAGING_REPLY_TEMPLATES, orderBy, onlyUserCreated],
        () => api.messagingReplyTemplates.getReplyTemplates(orderBy, onlyUserCreated),
        {
            enabled: enabled,
        },
    );
};

export default useGetMessageReplyTemplates;
