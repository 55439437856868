import { Button } from '@partoohub/ui';

import { SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';

import dataLayer from 'app/common/utils/dataLayer';

type FormData = {
    isLaunched: boolean;
    usesBusinessHours: boolean;
    welcomeMessage: string;
    offlineMessage: string;
    customChatHours: object;
};

type Props = {
    handleSubmit: UseFormHandleSubmit<FormData>;
    updateSettingMutation: UseMutationResult;
    hasError: boolean;
    disabled: boolean;
};

export const SaveButton = ({ handleSubmit, updateSettingMutation, hasError, disabled }: Props) => {
    const { t } = useTranslation();
    const onClick = () => {
        handleSubmit(updateSettingMutation.mutate as SubmitHandler<FormData>)();
        // Google Tag Manager
        dataLayer.pushDict('save_chat_settings');
    };

    const error = hasError || updateSettingMutation.isError;

    return (
        <Button
            type="button"
            dataTrackId="save_settings__configuration_modal__button"
            onClick={onClick}
            size="large"
            appearance="contained"
            full
            shape="cube"
            variant={error ? 'danger' : 'primary'}
            disabled={disabled}
        >
            {error ? t('conv_settings_update_error') : t('save')}
        </Button>
    );
};
