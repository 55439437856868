import React, { ReactNode } from 'react';

import {
    GoogleMapsPlaceHolderMain,
    GoogleMapsPlaceHolderMessage,
} from './GoogleMapsPlaceHolder.styled';

type Props = {
    message: string;
    icon?: ReactNode;
};

const GoogleMapsPlaceHolder = ({ message, icon = null }: Props) => (
    <GoogleMapsPlaceHolderMain>
        {icon}
        <GoogleMapsPlaceHolderMessage>{message}</GoogleMapsPlaceHolderMessage>
    </GoogleMapsPlaceHolderMain>
);

export default GoogleMapsPlaceHolder;
