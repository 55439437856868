import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

export const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    max-width: 700px;
    max-height: calc(100vh - 75px);
    overflow: auto;
`;

export const StyledTitle = styled(Text)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
