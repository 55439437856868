import { useRef } from 'react';

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { numberFormatter } from 'app/common/services/formatNumber';
import getLang from 'app/common/services/getLang';
import seasonalityDateFormatter from 'app/common/services/seasonalityDateFormatter';
import { averageGraphColors, gradeColors } from 'app/states/reviewAnalytics';
import { GraphTypes } from 'app/utils/chartjs/graphTypes';
import { toggleLegend } from 'app/utils/chartjs/helpers';
import { buildOptionsForGraphic } from 'app/utils/chartjs/options';

type Props = {
    cumAverage: Array<number>;
    dates: Array<string>;
    cumSum: Array<string>;
    added: Array<string>;
    isPeriod: boolean;
    seasonalityChoice: string;
    hasReviews: boolean;
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

const QualitativeChart = ({
    cumAverage,
    dates,
    cumSum,
    added,
    isPeriod,
    seasonalityChoice,
    hasReviews,
}: Props) => {
    const { t } = useTranslation();
    const lineChartRef = useRef<ChartJS>(null);
    const type = GraphTypes.LINE;
    const tooltip = {
        callbacks: {
            title: tooltipItem => {
                return seasonalityChoice === 'week'
                    ? `${t('week_of')} ${tooltipItem[0].label}`
                    : tooltipItem[0].label;
            },
            label: tooltipItem => {
                const { label } = tooltipItem.dataset;
                const separator = getLang() === 'fr' ? ' : ' : ': ';
                const value = tooltipItem.raw;
                if (t('average_grade') === label) {
                    return `${label}${separator}${numberFormatter(
                        Math.round(value * 10) / 10,
                    )}/5`;
                }
                return `${label}${separator}${numberFormatter(value)}`;
            },
        },
    };

    const buildOptions: any = () => {
        const options: any = buildOptionsForGraphic(type);
        options.plugins.tooltip = tooltip;
        options.plugins.legend.labels.filter = () =>
            hasReviews ? false : true;
        options.plugins.legend.onClick = (event, legendItem) =>
            toggleLegend(legendItem, lineChartRef);
        return options;
    };

    const data: any = {
        type,
        labels: seasonalityDateFormatter(dates, seasonalityChoice),
        datasets: [
            {
                fill: false,
                label: t('average_grade'),
                backgroundColor: gradeColors.lineColor,
                pointBackgroundColor: averageGraphColors(cumAverage),
                pointHitRadius: 10,
                pointRadius: 5,
                borderWidth: 0,
                data: cumAverage,
                // @ts-ignore
                showLine: !cumAverage.includes(null) || !isPeriod,
            },
            {
                fill: false,
                label: isPeriod ? t('new_reviews') : t('cumulated_reviews'),
                showLine: false,
                pointRadius: 0,
                pointHoverRadius: 0,
                data: isPeriod ? added : cumSum,
            },
            {
                label: t('no_reviews_found'),
                data: null,
                showLine: false,
                pointHitRadius: 10,
                pointRadius: 5,
                pointHoverRadius: 10,
                fill: false,
            },
        ],
        hasReviews,
        seasonalityChoice,
    };

    return <Line ref={lineChartRef} options={buildOptions()} data={data} />;
};

export default QualitativeChart;
