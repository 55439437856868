import { Dispatch, MutableRefObject } from 'react';

import {
    DownloadLocationMeta,
    DownloadLocationOAuthAccountTaskInfo,
} from 'app/api/types/task/download_task';
import { CommonMeta, OAuthAccountTaskInfo, TaskInfo, TaskStatus } from 'app/api/types/task/task';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { BUSINESSES } from 'app/common/data/queryKeysConstants';
import {
    BusinessesState,
    searchBusinesses,
    searchQuerySelector,
} from 'app/common/reducers/newBusinesses';
import {
    BusinessImportToastType,
    buildToastWithProgress,
} from 'app/onboarding/ToastWithProgress/ToastWithProgress';
import queryClient from 'app/states/queryClient';

export const downloadLocationMessage = (
    eventData: TaskInfo,
    oauthAccounts: MutableRefObject<Record<string, DownloadLocationOAuthAccountTaskInfo>>,
    rerender: () => void,
) => {
    const metadata = eventData?.meta as DownloadLocationMeta;
    if (metadata) {
        const oauthAccountId = metadata.oauth_account_id;
        const taskState = oauthAccounts.current[oauthAccountId];
        const taskID = taskState?.taskID || metadata.origin_task_id;
        let status = TaskStatus.PENDING;
        const accountsTotal = taskState?.accountsTotal || metadata?.total_accounts;
        let accountsProgress = taskState?.accountsProgress || 0;

        if (accountsTotal == 0) status = TaskStatus.SUCCESS;
        else if ([TaskStatus.SUCCESS, TaskStatus.FAILURE].includes(eventData?.status)) {
            accountsProgress++;
            status = accountsTotal === accountsProgress ? TaskStatus.SUCCESS : TaskStatus.PENDING;
        }

        const locationsProgress =
            (taskState?.locationsProgress ?? 0) + (metadata?.location_updated ?? 0);

        const instagramAccountsProgress =
            (taskState?.instagramAccountsProgress ?? 0) + (metadata?.instagram_account_found ?? 0);

        // If taskState undefined initialize with metadata variables
        const currentOAuthAccountTaskInfo = {
            taskID,
            oauthAccountId,
            status,
            accountsProgress,
            accountsTotal,
            locationsProgress,
            instagramAccountsProgress,
            eventType: eventData.type,
        } as DownloadLocationOAuthAccountTaskInfo;
        oauthAccounts.current = {
            ...oauthAccounts.current,
            [oauthAccountId]: currentOAuthAccountTaskInfo,
        };
        rerender();
    }
    return oauthAccounts;
};

export const commonOAuthMessage = (
    eventData: TaskInfo,
    oauthAccounts: MutableRefObject<Record<string, OAuthAccountTaskInfo>>,
    rerender: () => void,
) => {
    const taskID = eventData.id;
    const metadata = eventData?.meta as CommonMeta;
    let oauthAccountId = metadata?.oauth_account_id;

    // in case we do not have oauthAccountId sent in event : FAILURE for example,
    // we try to get it from history with the taskID
    if (!oauthAccountId) {
        oauthAccountId = Object.keys(oauthAccounts.current).find(
            key => oauthAccounts.current[key].taskID === taskID,
        ) as string;
    }

    if (oauthAccountId) {
        const currentOAuthAccountTaskInfo = {
            taskID,
            oauthAccountId,
            status: eventData.status,
            progress: metadata?.progress,
            total: metadata?.total,
            eventType: eventData.type,
        } as OAuthAccountTaskInfo;

        oauthAccounts.current = {
            ...oauthAccounts.current,
            [oauthAccountId]: currentOAuthAccountTaskInfo,
        };

        rerender();
    }
    return oauthAccounts;
};

export const postLocationImportMessage = (
    eventData: TaskInfo,
    importTasks: MutableRefObject<Record<string, TaskInfo>>,
    businessIdsToImport: MutableRefObject<string[] | undefined>,
    dispatch: Dispatch<any>,
    businessesState: BusinessesState,
) => {
    const taskID = eventData.id;
    const metadata = eventData?.meta as CommonMeta;
    let businessId = metadata?.business_id;

    // in case we do not have business_id sent in event, we try to get it from history
    if (!businessId) {
        businessId = Object.keys(importTasks.current).find(
            key => importTasks.current[key].id === taskID,
        ) as string;
    }

    if (
        businessIdsToImport.current &&
        businessId &&
        businessIdsToImport.current.includes(businessId)
    ) {
        const total = businessIdsToImport.current?.length;
        const isNew = !importTasks.current[businessId];

        importTasks.current = {
            ...importTasks.current,
            [businessId]: {
                ...eventData,
            },
        };

        const businessTasksStatus = businessIdsToImport.current.map(businessIdToImport =>
            importTasks.current[businessIdToImport]
                ? importTasks.current[businessIdToImport].status
                : TaskStatus.STARTED,
        );

        const businessTasksFailed = businessTasksStatus.filter(
            status => status === TaskStatus.FAILURE,
        );
        const businessTasksCompleted = businessTasksStatus.filter(
            status => status !== TaskStatus.STARTED,
        );
        const isDone = businessTasksCompleted.length === total;
        const isError = businessTasksFailed.length === total && isDone;
        const isWarning = businessTasksFailed.length && isDone;

        let toastType = 'loading' as BusinessImportToastType;
        if (isError) toastType = 'error';
        else if (isWarning) toastType = 'warning';
        else if (isDone) toastType = 'success';

        const hasFinishedStatus = isError || isWarning || isDone;

        buildToastWithProgress({
            type: toastType,
            completed: businessTasksCompleted.length,
            failed: businessTasksFailed.length,
            total,
            isNew,
            hasFinishedStatus,
        });

        if (isDone) {
            businessIdsToImport.current.forEach(
                businessIdToImport => delete importTasks.current[businessIdToImport],
            );
        }

        if (toastType !== 'loading') {
            const searchQuery = searchQuerySelector(businessesState);
            dispatch(searchBusinesses(1, searchQuery, true));

            // For Business List V2, important to reset and not invalidate
            queryClient.resetQueries(invalidatesAny(BUSINESSES));
        }
    }
    return importTasks;
};
