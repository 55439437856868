import { ActiveConversation } from 'app/reviewManagement/messaging/activeConversation/ActiveConversation';
import InboxHeader from 'app/reviewManagement/messaging/InboxHeader';
import {
    LeftPanelContent,
    MessagingContentContainer,
} from 'app/reviewManagement/messaging/MessagingContent.styled';
import { MessagingEmptyPlaceholder } from 'app/reviewManagement/messaging/MessagingEmptyPlaceholder';

import { useGetConversations } from './hooks/useGetConversations';
import Inbox from './inbox/Inbox';

export const MessagingContent = () => {
    const { conversations, isLoading } = useGetConversations();

    return (
        <MessagingContentContainer flexDirection="row" alignItems="flex-start">
            <LeftPanelContent>
                <InboxHeader />
                <Inbox />
            </LeftPanelContent>
            {conversations.length > 0 || isLoading ? (
                <ActiveConversation />
            ) : (
                <MessagingEmptyPlaceholder />
            )}
        </MessagingContentContainer>
    );
};
