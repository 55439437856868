import useKeywordsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useKeywordsAnalytics';
import { useKeywordsQueryCount } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/keywords/count/useKeywordsQueryCount';

export const useKeywordsErrorCount = () => {
    const { queryCurrent, queryPrevious } = useKeywordsQueryCount();

    const { error: errorCountCurrent, refetch: refetchCountCurrent } =
        useKeywordsAnalytics(queryCurrent);
    const { error: errorCountPrevious, refetch: refetchCountPrevious } =
        useKeywordsAnalytics(queryPrevious);

    return {
        hasError: errorCountCurrent || errorCountPrevious,
        refetchCountCurrent,
        refetchCountPrevious,
    };
};
