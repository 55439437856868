import { push } from '@lagunovsky/redux-react-router';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import headers from 'app/api/v2/headers';
import { USE_HOMEPAGE } from 'app/common/data/featureFlags';
import { API_KEY, CONNECTION_TOKEN } from 'app/common/data/headers';
import IS_IFRAME from 'app/common/data/iframe';
import {
    API_KEY_SEARCH_PARAM,
    CONNECTION_TOKEN_SEARCH_PARAM,
    NEXT_PAGE_SEARCH_PARAM,
    extractOidcParam,
    extractSearchParam,
} from 'app/common/data/routeIds';
import { getMeDataOnceAvailable, getMeSaga } from 'app/common/sagas/getMeSaga';
import storeError from 'app/common/sagas/storeError';
import isPasswordValid from 'app/common/services/passwordChecker';
import { Saga } from 'app/common/types';
import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';
import { sdkBridge } from 'app/SDKBridge';
import {
    LOGIN_REQUEST,
    LOGIN_WITH_JWT_TOKEN_REQUEST,
    LoginRequestAction,
    LoginState,
    emailSelector,
    loginFormReset,
    loginRequest,
    loginRequestFailure,
    loginRequestSuccess,
    loginWithJWTTokenRequest,
    passwordSelector,
    redirectLoginPage,
    showUnsafePasswordModal,
} from 'app/states/connection/reducers/login';
import { renewJwtTokenRequest } from 'app/states/jwtToken/actions/actions';
import { loginSelector } from 'app/states/reducers';

const getNextPageUrl = (): string => {
    const nextPage = extractSearchParam(NEXT_PAGE_SEARCH_PARAM);
    const defaultPage = USE_HOMEPAGE && !IS_IFRAME ? '/' : `${VISIBILITY_LOCATION_PATH}?group=all`;

    return decodeURI(nextPage === '' ? defaultPage : nextPage);
};

function* redirect() {
    sdkBridge.sendUserIsLoggedIn();
    yield put(loginRequestSuccess());
    yield put(loginFormReset());
    yield put(push(getNextPageUrl()));
}

const login = function* ({
    apiKey,
    connectionToken,
    idToken,
    oidcParams,
}: LoginRequestAction): Saga {
    let loginData = {};
    let loginHeaders = {};
    let shouldUpdatePassword = false;

    try {
        // If login SSO OIDC
        if (oidcParams) {
            const { redirect_url: redirectUrl } = yield call(
                api.connection.oidcAuth,
                extractSearchParam('sso_alias'),
                oidcParams,
            );
            // Another redirection needed for mobile sso
            // ignoreOldQueryParams to not keep previous query params in history.ts
            if (redirectUrl) {
                yield put(push(redirectUrl, { ignoreOldQueryParams: true }));
            }
        } else {
            if (apiKey) {
                loginHeaders = {
                    [API_KEY]: apiKey,
                };
            } else if (connectionToken) {
                loginHeaders = {
                    [CONNECTION_TOKEN]: connectionToken,
                };
            } else if (idToken) {
                loginData = {
                    id_token: idToken,
                };
            } else {
                const loginState: LoginState = yield select(loginSelector);
                const email = emailSelector(loginState);
                const password = passwordSelector(loginState);
                shouldUpdatePassword = !isPasswordValid(password);
                loginData = {
                    email,
                    password,
                };
            }

            yield call(api.connection.login, loginData, loginHeaders);
        }

        if (shouldUpdatePassword) {
            yield put(redirectLoginPage(getNextPageUrl()));
            yield put(showUnsafePasswordModal());
        } else {
            yield* getMeSaga(true);
        }
    } catch (error) {
        yield* storeError(loginRequestFailure, error);
    }
};

const loginWithJWTToken = function* ({ apiKey, connectionToken }: LoginRequestAction): Saga {
    try {
        const loginHeaders = apiKey
            ? {
                  [API_KEY]: apiKey,
              }
            : {
                  [CONNECTION_TOKEN]: connectionToken,
              };
        const { token } = yield call(api.authToken.getAuthToken, loginHeaders);
        headers.jwtToken.injectJwtToken(token);
        yield* getMeSaga(true);
        yield put(renewJwtTokenRequest());
    } catch (error) {
        yield* storeError(loginRequestFailure, error);
    }
};

export const initialConnectionRequestSaga = function* (): Saga {
    const apiKey = extractSearchParam(API_KEY_SEARCH_PARAM);
    const connectionToken = extractSearchParam(CONNECTION_TOKEN_SEARCH_PARAM);
    const oidcParams = extractOidcParam();

    if (IS_IFRAME && (apiKey || connectionToken)) {
        yield put(loginWithJWTTokenRequest(apiKey, connectionToken));
    } else if (oidcParams) {
        yield put(loginRequest(undefined, undefined, undefined, oidcParams));
    } else if (apiKey || connectionToken) {
        yield put(loginRequest(apiKey, connectionToken));
    }

    const me = yield* getMeDataOnceAvailable();
    if (me) yield* redirect();
};

const loginSaga = function* (): Saga {
    yield takeEvery(LOGIN_REQUEST, login);
    yield takeEvery(LOGIN_WITH_JWT_TOKEN_REQUEST, loginWithJWTToken);
};

export default loginSaga;
