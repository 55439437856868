import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { CONTAINED } from 'app/common/designSystem/constants/type';
import { useProductUpsale } from 'app/common/hooks/queries/useProductUpsale';

export const NoReviewBooster = () => {
    const { refetch } = useProductUpsale('review_booster');

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.ReviewBoosterUnsubscribed,
    };

    return (
        <NotFoundTemplate
            show={true}
            imgSrc={illustrationElement}
            title="rb_oops_havnt_subscribed"
            subtitle="rb_explanation"
            buttonText="contact_us_to_get_more_information"
            buttonStatus={CONTAINED}
            handleClick={refetch}
        />
    );
};
