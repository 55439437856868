import { useEffect, useState } from 'react';

import useBusinessSearch from './useBusinessSearch';

export default function useBusinessCount(): number {
    const { count } = useBusinessSearch();
    const [definedCount, setDefinedCount] = useState(count);

    useEffect(() => {
        if (count !== undefined) {
            setDefinedCount(count);
        }
    }, [count]);

    return definedCount ?? 0;
}
