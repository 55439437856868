import { put, select, takeEvery } from 'redux-saga/effects';

import {
    BusinessesState,
    businessByIdSelector,
} from 'app/common/reducers/newBusinesses';
import { getOrg } from 'app/common/reducers/orgs';
import { Saga } from 'app/common/types';
import { GOOGLE_MY_BUSINESS } from 'app/states/knowledge/reducers/businessConnection/accounts';
import {
    DISPLAY_CONNECTION_MODAL,
    DisplayConnectionModalAction,
} from 'app/states/knowledge/reducers/businessConnection/connection';
import { businessesSelector } from 'app/states/reducers';

function* getBusinessInfo({
    businessId,
    partner,
}: DisplayConnectionModalAction): Saga {
    if (partner === GOOGLE_MY_BUSINESS) {
        const businessesState = (yield select(
            businessesSelector,
        ) as any) as BusinessesState;
        const business = businessByIdSelector(businessesState, businessId);
        yield put(getOrg(business.orgId));
    }
}

function* getBusinessInfoSaga(): Saga {
    yield takeEvery(DISPLAY_CONNECTION_MODAL, getBusinessInfo);
}

export default getBusinessInfoSaga;
