import styled from '@emotion/styled';

import Flexbox from 'app/styles/utils/flexbox';

export const MessagingFlexContainer = styled(Flexbox)`
    height: 100%;
    width: 100%;
`;

export const MessagingBodyContainer = styled.div`
    background: ${({ theme }) => theme.colors.theme.initial};
    width: calc(100% + 80px);
    margin: 0 -40px -40px -40px;
    flex: 1;
    overflow: hidden;
    @media (max-width: 1024px) {
        width: calc(100% + 40px);
        margin: 0 -20px -20px -20px;
    }
`;
