import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useCustomFieldsSectionsWithOrg } from 'app/businessEditV2/hooks/editV2utils/useCustomFieldsSectionsWithOrg';
import usePermissions from 'app/businessEditV2/hooks/permissions/usePermissions';

export function useIsLoadingCustomFields(): boolean {
    const { isLoading: isLoadingBusiness } = useBusiness();
    const { isLoading: isLoadingPermissions } = usePermissions();
    const { isLoading: isLoadingCustomFieldsSectionsWithOrg } = useCustomFieldsSectionsWithOrg();

    return isLoadingBusiness || isLoadingPermissions || isLoadingCustomFieldsSectionsWithOrg;
}
