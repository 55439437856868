import { useTranslation } from 'react-i18next';

import { ReviewReplyTemplatesPage } from 'app/pages/settings/ReviewReplyTemplates';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';
import { CreateReplyTemplateButton } from 'app/reviewManagement/replyTemplate/subComponents/CreateReplyTemplateButton/CreateReplyTemplateButton';

export const ReplyTemplates = () => {
    const { t } = useTranslation();
    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('reply_templates')}
                    description={t('reply_templates_description')}
                    actionsButton={[
                        <CreateReplyTemplateButton key="create_reply_template_button" />,
                    ]}
                />
            }
        >
            <MenuListContentContainer>
                <ReviewReplyTemplatesPage />
            </MenuListContentContainer>
        </MenuListContentTemplate>
    );
};
