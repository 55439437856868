import { camelCase, mapKeys } from 'lodash-es';

import { V2AccountData, V2FormattedAccountData } from 'app/api/types/account';

const formatAccount = (account: V2AccountData): V2FormattedAccountData => {
    const camelCasedAccount: any = mapKeys(account, (v, key) => camelCase(key));
    camelCasedAccount.creationDate = new Date(camelCasedAccount.creationDate);
    camelCasedAccount.updateDate = new Date(camelCasedAccount.updateDate);
    return camelCasedAccount;
};

export default formatAccount;
