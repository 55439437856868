import { Dispatch, SetStateAction, createContext, useEffect, useMemo, useState } from 'react';

export const CREATION_MODE_ITEM_ID = 'CREATION_MODE_ITEM_ID';

type ActiveConversationStarterItemType = {
    title: string | null;
    reply_message: string | null;
};

interface ConversationStartersContextProps {
    activeConversationStarterItemId: string | null;
    setActiveConversationStarterItemId: (itemId: string | null) => void;
    activeConversationStarterItem: ActiveConversationStarterItemType | null;
    setActiveConversationStarterItem: (item: ActiveConversationStarterItemType) => void;
    isEnabled: boolean;
    setIsEnabled: Dispatch<SetStateAction<boolean>>;
    currentWelcomeMessage: string;
    setCurrentWelcomeMessage: (welcomeMessage: string) => void;
}

export const ConversationStartersContext = createContext<ConversationStartersContextProps>({
    activeConversationStarterItemId: null,
    setActiveConversationStarterItemId: () => {},
    activeConversationStarterItem: null,
    setActiveConversationStarterItem: () => {},
    isEnabled: true,
    setIsEnabled: () => {},
    currentWelcomeMessage: '',
    setCurrentWelcomeMessage: () => {},
});

export const ConversationStartersContextProvider = ({ children }) => {
    const [activeConversationStarterItemId, setActiveConversationStarterItemId] =
        useState<string>(null);
    const [activeConversationStarterItem, setActiveConversationStarterItem] =
        useState<ActiveConversationStarterItemType>(null);
    const [isEnabled, setIsEnabled] = useState<boolean>(true);
    const [currentWelcomeMessage, setCurrentWelcomeMessage] = useState<string>('');

    useEffect(() => {
        setActiveConversationStarterItem(null);
    }, [activeConversationStarterItemId]);

    const contextValue = useMemo(
        () => ({
            activeConversationStarterItemId,
            setActiveConversationStarterItemId,
            activeConversationStarterItem,
            setActiveConversationStarterItem,
            isEnabled,
            setIsEnabled,
            currentWelcomeMessage,
            setCurrentWelcomeMessage,
        }),
        [
            activeConversationStarterItemId,
            activeConversationStarterItem,
            isEnabled,
            currentWelcomeMessage,
        ],
    );

    return (
        <ConversationStartersContext.Provider value={contextValue}>
            {children}
        </ConversationStartersContext.Provider>
    );
};
