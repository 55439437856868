import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { useGetCompletionFilter } from 'app/pages/visibility/BusinessListV2/hooks/queryParams/params/useGetCompletionFilter';
import { useGetGroupFilter } from 'app/pages/visibility/BusinessListV2/hooks/queryParams/params/useGetGroupFilter';
import { useGetStatusFilter } from 'app/pages/visibility/BusinessListV2/hooks/queryParams/params/useGetStatusFilter';

import { useGetBusinessProductFilter } from './useGetBusinessProductFilter';
import { useGetBusinessQuery } from './useGetBusinessQuery';

export const useTransformBusinessFilters = (): BusinessModalFiltersType => {
    return {
        ...useGetGroupFilter(),
        ...useGetStatusFilter(),
        ...useGetCompletionFilter(),
        ...useGetBusinessQuery(),
        ...useGetBusinessProductFilter(),
    };
};
