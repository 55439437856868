import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { DropdownMenuButton } from 'app/common/components/buttons/DropdownMenuButton/DropdownMenuButton';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import dataLayer from 'app/common/utils/dataLayer';

import { AssignFilterOption, StatusFilterOption, URLParams } from 'app/states/messaging/dataTypes';

import queryClient from 'app/states/queryClient';

import { DropdownMenuContainer } from './ConversationStatusFilter.styled';

import { getMenuLabels, getOptionsLabels } from './helper';
import { getConversationsKey } from '../../hooks/useGetConversations';
import { MessagingContext } from '../../MessagingContext';

const ConversationStatusFilter = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { setActiveConversation } = useContext(MessagingContext);

    const options = getOptionsLabels(t);
    const menuLabels = getMenuLabels(t);
    const section = [{ options: options }];

    const [statusFilter, setStatusFilter] = useStateQueryParams<StatusFilterOption>(
        URLParams.STATUS,
    );
    const [assignFilter] = useStateQueryParams<AssignFilterOption>(URLParams.ASSIGN);
    const businessFilters = useBusinessModalFilters();

    const handleStatusFilter = (status: StatusFilterOption) => {
        const conversationsKey = getConversationsKey(status, assignFilter, businessFilters);

        dataLayer.pushDict('messaging_filter', { status: status });
        setStatusFilter(status);
        setActiveConversation(undefined);
        queryClient.invalidateQueries(conversationsKey);
        navigate({ search: '?conversationId=' }, { replace: true });
    };

    return (
        <DropdownMenuContainer>
            <DropdownMenuButton
                selectedOptionName={statusFilter}
                sections={section}
                onMenuClick={handleStatusFilter}
                menuLabels={menuLabels}
            />
        </DropdownMenuContainer>
    );
};

export default ConversationStatusFilter;
