import { useContext, useEffect, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import { ConversationCard } from 'app/reviewManagement/messaging/inbox/ConversationCard/ConversationCard';
import { useMessagingUsers } from 'app/reviewManagement/messaging/LoadedUsersProvider';

import LazyLoadingConversationsCards from './ConversationCard/LazyLoadingConversationsCards';
import { ConversationCardsContainer, InboxStyled } from './Inbox.styled';
import { useGetConversations } from '../hooks/useGetConversations';
import { MessagingContext } from '../MessagingContext';

const Inbox = () => {
    const { conversations, hasNextPage, isLoading, isFetching, fetchNextPage } =
        useGetConversations();
    const { activeConversation } = useContext(MessagingContext);

    const [shouldAutoScrollToCard, setShouldAutoScrollToCard] = useState<boolean>(true);
    const { loadedAssignedUserIds, setLoadedAssignedUserIds } = useMessagingUsers();

    const { isRequestingNewConversations } = useContext(MessagingContext);

    useEffect(() => {
        const conversationsAssignedUserIds = conversations
            .map(conversation => conversation.assigned_user_id)
            .filter((userId): userId is string => userId !== undefined && userId !== null);

        if (conversations.length > 0 && conversationsAssignedUserIds.length > 0) {
            const ids = new Set([...loadedAssignedUserIds, ...conversationsAssignedUserIds]);
            setLoadedAssignedUserIds([...ids]);
        }
    }, [JSON.stringify(conversations)]);

    /*
        When switching filters from the use of the textarea (reopening, self-assignment):
        - conversations from the old filter are removed except the active one, hence the length.
        - conversations of the new filters are fetched, `isRequestingNewConversations` will be true during the
          post message request, `isFetching` will be true during the post message success.
    */
    const isSwitchingFiltersFromTextarea =
        (isRequestingNewConversations || isFetching) && conversations.length === 1;

    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);

    return (
        <ConversationCardsContainer isNewSidebarEnabled={isNewSidebarEnabled}>
            {isLoading && conversations.length === 0 ? (
                <LazyLoadingConversationsCards />
            ) : (
                <InboxStyled id="inbox" flexDirection="column">
                    <InfiniteScroll
                        dataLength={conversations.length}
                        next={fetchNextPage}
                        hasMore={!!hasNextPage}
                        loader={<LazyLoadingConversationsCards />}
                        scrollableTarget="inbox"
                    >
                        {conversations.map(conversation => (
                            <ConversationCard
                                key={conversation.id}
                                conversation={conversation}
                                isActive={activeConversation?.id === conversation.id}
                                shouldAutoScrollToCard={shouldAutoScrollToCard}
                                setShouldAutoScroll={setShouldAutoScrollToCard}
                            />
                        ))}
                    </InfiniteScroll>

                    {isSwitchingFiltersFromTextarea && <LazyLoadingConversationsCards />}
                </InboxStyled>
            )}
        </ConversationCardsContainer>
    );
};

export default Inbox;
