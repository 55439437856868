import { FontAwesomeIconsPartooUsed, LeftElementType } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import BusinessModal, {
    Props as ModalProps,
} from 'app/common/components/businessModalDeprecated/components/BusinessModal';
import AsyncSingleSelect from 'app/common/designSystem/components/molecules/AsyncSingleSelect';

import { BusinessModalFilters } from './BusinessModal.styled';
import { BusinessModalFilterSelectButton, StyledTextInput } from './BusinessModalWithFilter.styled';

type Props = ModalProps & {
    query: string;
    onSearchInputChange: (arg0: string) => void;
    selectProps: Record<string, any>;
    headerTitle?: string;
    activeRibbon?: boolean;
};

const BusinessModalWithFilter = (props: Props) => {
    const { t } = useTranslation();

    const {
        query,
        selectProps,
        onSearchInputChange,
        headerTitle = 'manage_my_businesses',
        activeRibbon = false,
    } = props;

    return (
        <BusinessModal {...props} headerTitle={headerTitle} activeRibbon={activeRibbon}>
            <BusinessModalFilters>
                <StyledTextInput
                    dataTrackId="search_business_field"
                    value={query}
                    onChange={q => onSearchInputChange(q)}
                    placeholder={t('search_a_business')}
                    leftElementType={LeftElementType.Icon}
                    leftElement={[FontAwesomeIconsPartooUsed.faSearch]}
                    hasOldTextFieldHeight
                />
                <BusinessModalFilterSelectButton>
                    <AsyncSingleSelect {...selectProps} />
                </BusinessModalFilterSelectButton>
            </BusinessModalFilters>
        </BusinessModal>
    );
};

export default BusinessModalWithFilter;
