import styled from '@emotion/styled';

export const MetricsTemplateChartContainer = styled.div`
    border: 1px solid #dddfe3;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 24px;
`;

export const MetricsTemplateChartHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const MetricsTemplateChartGraph = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 350px;
`;
