import styled from '@emotion/styled';

export const BusinessClosedTexts = styled.div`
    display: flex;
    flex: 1;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.danger.alpha};
    color: ${({ theme }) => theme.colors.danger.initial};
    padding: 12px 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const BusinessClosedButton = styled.div`
    & .strike_through_button {
        font-size: 12px;
        font-weight: normal;
        margin-left: 8px;
    }
`;

export const BusinessClosedText = styled.div`
    display: flex;
    flex: 1;
    font-weight: bold;
`;
