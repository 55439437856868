import { ShortcutCreateBusiness } from 'app/pages/Homepage/sections/HomepageShortcuts/shortcuts/ShortcutCreateBusiness';
import { Item } from 'app/pages/Homepage/utils/typing';
import { useCanCreateBusiness } from 'app/pages/visibility/BusinessListV2/hooks/useCanCreateBusiness';

export const useShortcutCreateBusinessItem = (): Item => {
    const isAvailable = useCanCreateBusiness();

    return {
        order: 4,
        isAvailable,
        component: ShortcutCreateBusiness,
        key: 'shortcut-create-business',
    };
};
