import { useLocation, useNavigate } from 'react-router-dom';

import { SectionNames } from 'app/businessEditV2/utils/utils';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

const MODAL_KEY = 'section';

export default function useShowModal(
    section: SectionNames,
): [boolean, (boolean) => void] {
    const navigate = useNavigate();
    const location = useLocation();
    const [value, setValue] = useStateQueryParams(MODAL_KEY, true);

    const showModal = value === section;
    const setShowModal = (newValue: boolean) => {
        if (!newValue && location?.state?.isOnApp) {
            navigate(-1);
        } else {
            setValue(newValue ? section : '');
        }
    };

    return [showModal, setShowModal];
}
