import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, blackColorObject } from '@partoohub/branding';

type FilterProps = {
    selectedNumber: number;
};

export const NewFilterButtonContainer = styled.div<FilterProps>`
    display: flex;
    height: 56px;
    padding-inline: 16px;
    min-width: 150px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: solid 1px ${blackColorObject['10']};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 12px;
    position: relative;
    transition: all ease 0.15s;

    &:hover {
        cursor: pointer;
        border-color: ${({ theme }) => theme.colors.default.initial};
    }

    ${({ selectedNumber, theme }) =>
        selectedNumber > 0 &&
        css`
            border: solid 1px ${theme.colors.primary.initial};
            outline: solid 1px ${theme.colors.primary.initial};
            color: ${theme.colors.primary.initial};

            &::after {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: ${theme.colors.primary.initial};
                color: ${HEX_COLORS.white};
                border-radius: 16px;
                font-size: 10px;

                height: 16px;
                width: 16px;
                position: absolute;
                content: '${selectedNumber}';

                top: -8px;
                right: -8px;
                outline: solid 1px;
            }
        `}
`;
