import { useQuery } from 'react-query';

import type { CompetitiveOverview } from 'app/api/types/competitiveBenchmark';
import api from 'app/api/v2/api_calls';
import { OVERVIEW_DEFAULT } from 'app/api/v2/api_calls/competitiveBenchmark';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { COMPETITIVE_OVERVIEW } from 'app/common/data/queryKeysConstants';

export const useCompetitiveOverview = (
    keyword: string,
    startDate: Date,
    endDate: Date,
): {
    overview: CompetitiveOverview;
    isLoading: boolean;
} => {
    const businessFilters = useBusinessModalFilters();

    const { data, isPreviousData, isLoading } = useQuery(
        [COMPETITIVE_OVERVIEW, keyword, startDate, endDate, businessFilters],
        () => api.competitiveBenchmark.getOverview(keyword, startDate, endDate, businessFilters),
        { keepPreviousData: true },
    );
    return { overview: data ?? OVERVIEW_DEFAULT, isLoading: isLoading || isPreviousData };
};
