import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationVariousElement,
    IllustrationVariousEnum,
} from '@partoohub/iconography';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useBusinessGoogleConnectionsErrors } from 'app/pages/Homepage/hooks/todos/todosItems/partnerCo/useBusinessGoogleConnectionsErrors';
import { DefaultTodoComponent } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent';
import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import { SETTINGS_PLATFORMS_CONNECTION_PATHNAME } from 'app/routing/routeIds';

export const TodoPartnerCo = (props: SmallTodoProps) => {
    const { t } = useTranslation();
    const { data, isLoading } = useBusinessGoogleConnectionsErrors();
    const countError = data?.partner_connections_errors_count ?? 0;

    const navigate = useNavigate();

    const illustrationElement: IllustrationVariousElement = {
        type: IllustrationCategoryEnum.Various,
        name: IllustrationVariousEnum.PartnerConnection,
    };

    return (
        <DefaultTodoComponent
            {...props}
            title={t('homepage_todo_partner_connection_title', {
                count: countError,
            })}
            actionText={t('homepage_todo_partner_connection_description')}
            icon={
                <Illustration
                    dataTrackId="homepage_example__illustration"
                    illustration={illustrationElement}
                    height="98"
                    width="120"
                />
            }
            isLoading={isLoading}
            onClick={() => navigate(SETTINGS_PLATFORMS_CONNECTION_PATHNAME)}
            dataTrack="click_homepage_to_do_partner_co"
        />
    );
};
