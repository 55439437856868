import React, { FC, useState } from 'react';

import { AxiosError } from 'axios';
import { Interweave } from 'interweave';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { ExpeditorNameResponse, UpdateExpeditorData } from 'app/api/types/review_booster';
import api from 'app/api/v2/api_calls';

import { REVIEW_BOOSTER_GET_EXPEDITOR_NAME } from 'app/common/data/queryKeysConstants';
import { UrlMatcher } from 'app/common/services/Matchers';
import getHelper, {
    MAX_AUTHORIZED_CHARACTER_COUNT_MESSAGE,
} from 'app/common/services/smsTemplateHelper';
import EditSmsTemplateModal from 'app/pages/conversations/ReviewBooster/components/EditSmsTemplateModal';
import queryClient from 'app/states/queryClient';

import { ExpeditorNameFormControl } from './ExpeditorNameFormControl';
import {
    EditButton,
    EditTemplateSymbol,
    ErrorBlock,
    ExpeditorNameContainer,
    ExpeditorNameStaticFieldContainer,
    ExpeditorNameStaticFieldWrapper,
    ExpeditorNameTitle,
    ExpeditorNameValue,
    SmsContainer,
} from './SMSFormControl.styled';

type Props = {
    expeditorName?: string;
    setExpeditorName: (value: string) => void;
    expeditorNameHasError: boolean;
    smsFieldHasError: boolean;
    disabledField?: boolean;
    smsContent: string;
    selectedBusinessId: string;
    url: string;
    editable?: boolean;
};

const SMSFormControl: FC<Props> = ({
    expeditorName,
    setExpeditorName,
    expeditorNameHasError,
    smsFieldHasError,
    disabledField,
    smsContent,
    selectedBusinessId,
    url,
    editable = true,
}) => {
    const { t } = useTranslation();

    const [focus, setFocus] = useState(false);
    const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
    const openTemplateModal = (): void => setIsTemplateModalOpen(true);
    const closeTemplateModal = (): void => setIsTemplateModalOpen(false);

    const [isPreviewOnHover, setIsPreviewOnHover] = useState(false);
    const onMouseEnterPreview = (): void => setIsPreviewOnHover(true);
    const onMouseLeavePreview = (): void => setIsPreviewOnHover(false);

    const { count } = getHelper();
    const textCount: number = count(smsContent);

    const expeditorMutation = useMutation<ExpeditorNameResponse, AxiosError, UpdateExpeditorData>(
        data => api.reviewBooster.updateExpeditorName(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    REVIEW_BOOSTER_GET_EXPEDITOR_NAME,
                    { businessId: selectedBusinessId },
                ]);
            },
        },
    );

    const onBlur = () => {
        if (!expeditorNameHasError) {
            setFocus(false);
            expeditorMutation.mutate({
                business_id: selectedBusinessId,
                expeditor_name: expeditorName?.trim() || '',
            });
        }
    };

    const onClick = () => {
        if (selectedBusinessId) {
            setFocus(true);
        }
    };

    return (
        <>
            <ExpeditorNameContainer>
                {focus ? (
                    <ExpeditorNameFormControl
                        value={expeditorName}
                        onChange={setExpeditorName}
                        autoFocus
                        onBlur={onBlur}
                        hasError={expeditorNameHasError}
                        disabled={disabledField}
                    />
                ) : (
                    <ExpeditorNameStaticFieldWrapper
                        role="button"
                        selectedBusinessId={selectedBusinessId}
                        disabled={disabledField}
                        {...(!disabledField && {
                            onClick,
                        })}
                    >
                        <ExpeditorNameStaticFieldContainer>
                            <ExpeditorNameTitle disabled={disabledField}>
                                {t('expeditor_name')} :
                            </ExpeditorNameTitle>
                            <ExpeditorNameValue disabled={disabledField}>
                                {expeditorName || t('rb_new_review_title')}
                            </ExpeditorNameValue>
                        </ExpeditorNameStaticFieldContainer>
                        {selectedBusinessId && (
                            <EditButton disabled={disabledField}>
                                <i className="fa-regular fa-pen-clip" />
                            </EditButton>
                        )}
                    </ExpeditorNameStaticFieldWrapper>
                )}
            </ExpeditorNameContainer>
            <SmsContainer
                onMouseEnter={onMouseEnterPreview}
                onMouseLeave={onMouseLeavePreview}
                hasError={smsFieldHasError}
                disabled={disabledField}
                editable={editable}
                {...(!disabledField &&
                    editable && {
                        onClick: openTemplateModal,
                    })}
            >
                {editable && (
                    <EditTemplateSymbol
                        onHover={isPreviewOnHover}
                        hasError={smsFieldHasError}
                        disabled={disabledField}
                    >
                        <i className="fa-regular fa-pen-clip" />
                    </EditTemplateSymbol>
                )}
                <Interweave
                    content={smsContent}
                    matchers={[
                        UrlMatcher(
                            'https://pto.sh/u/WXYZA',
                            'https://pto.sh/u/WXYZA',
                            url,
                            true,
                            disabledField,
                        ),
                    ]}
                />
            </SmsContainer>
            {smsFieldHasError && (
                <ErrorBlock>
                    <p>{t('message_too_long')}</p>
                    <div className={'mentions_danger__explanation'}>
                        {`${textCount} / ${MAX_AUTHORIZED_CHARACTER_COUNT_MESSAGE}`}
                    </div>
                </ErrorBlock>
            )}
            <EditSmsTemplateModal show={isTemplateModalOpen} close={closeTemplateModal} />
        </>
    );
};

export default SMSFormControl;
