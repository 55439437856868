import { useMemo, useState } from 'react';

import { FunnelObject, FunnelScreen } from './FunnelContext';

const useInitFunnel = (initScreens: FunnelScreen[], setShow: Function, startScreen?: number) => {
    const [currentScreenIndex, setScreenIndex] = useState<number>(startScreen || 0);
    return useMemo(() => {
        const funnelObject = new FunnelObject(
            initScreens,
            {},
            currentScreenIndex,
            setScreenIndex,
            setShow,
        );

        return funnelObject;
    }, [startScreen, initScreens]);
};

export default useInitFunnel;
