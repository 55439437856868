import { useState } from 'react';

import { toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import useUsersStats from 'app/common/hooks/queries/useUsersStats';
import useReinviteAllUsers from 'app/pages/settingsV2/subPages/Team/components/UserManagement/hooks/useReinviteAllUsers';

import BaseReinviteModalContent from '../BaseReinviteModalContent';

type Props = {
    closeModal: () => void;
};

const UserReinviteAllContent = ({ closeModal }: Props) => {
    const { t } = useTranslation();
    const { data: userStats } = useUsersStats();
    const [success, setSuccess] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const usersReinviteAll = useReinviteAllUsers(
        () => {
            closeModal();
            setSuccess(true);
            toast.success(null, t('toast__users_invited__success'));
        },
        () => {
            setHasError(true);
        },
    );

    const invitedCount = userStats?.invited_count ?? 0;

    let error = 're_invite_user_error';
    if (hasError) {
        // @ts-ignore
        if (usersReinviteAll.error?.response?.data?.errors?.json?.type === 'no_users') {
            error = 'no_users_invited';
        }
    }

    return (
        <BaseReinviteModalContent
            mutate={usersReinviteAll.mutate}
            success={success}
            error={hasError ? t(error) : ''}
            isLoading={usersReinviteAll.isLoading}
            headerTitle={t('re_invite_user__modal__title')}
            text={t('re_invite_users__modal__body', {
                invitedCount,
                count: invitedCount,
            })}
        />
    );
};

export default UserReinviteAllContent;
