import { MouseEvent } from 'react';

import { FontAwesomeIconsPartooUsed, Icon, IconPrefix } from '@partoohub/ui';

import { NewFilterButtonContainer } from './NewFilterButton.styled';

type Props = {
    text: string;
    onClick: () => void;
    selectedNumber: number;
    onErase?: () => void;
};

const NewFilterButton = ({ text, onClick, selectedNumber, onErase }: Props) => {
    const onEraseClick = (e: MouseEvent<SVGSVGElement>) => {
        e.stopPropagation();
        onErase?.();
    };

    return (
        <NewFilterButtonContainer onClick={onClick} selectedNumber={selectedNumber}>
            {text}
            {selectedNumber > 0 && !!onErase ? (
                <Icon
                    icon={[FontAwesomeIconsPartooUsed.faClose, IconPrefix.SOLID]}
                    onClick={onEraseClick}
                    color="primary"
                    iconSize="12px"
                />
            ) : (
                <Icon
                    icon={[FontAwesomeIconsPartooUsed.faCaretDown, IconPrefix.SOLID]}
                    color="secondary"
                    iconSize="12px"
                />
            )}
        </NewFilterButtonContainer>
    );
};

export default NewFilterButton;
