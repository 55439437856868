import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const TimerContainer = styled.div`
    display: flex;
    flex-direction: row;

    height: 16px;
    width: 100%;
    justify-content: center;
    gap: 2px;
    flex-wrap: wrap;
    align-content: center;
    padding: 0 5px;
`;

export const TimerBackground = styled.div`
    border-radius: 2px;

    flex-grow: 1;
    height: 2px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${({ theme }) => theme.colors.default.alpha};
`;

const progressFill = keyframes`
  from {
    width: 0%;
  }

  to {
    width: 100%;
}
`;

export const TipTimer = styled.div<{ time: number }>`
    border-radius: 2px;

    height: 2px;
    flex-shrink: 0;
    border-radius: 2px;
    background: ${({ theme }) => theme.colors.default.light};
    animation: ${progressFill} ${({ time }) => `${time}s linear;`};
`;
