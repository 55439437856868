import { QueryKey, useQuery } from 'react-query';

import { CompetitorRank } from 'app/api/types/competitiveBenchmark';
import api from 'app/api/v2/api_calls';
import useLocalBusinessIdCompetitiveBenchmark from 'app/common/components/businessModal/hooks/birdOnly/useLocalBusinessIdCompetitiveBenchmark';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { COMPETITIVE_BENCHMARK_RANKINGS } from 'app/common/data/queryKeysConstants';

import useBusiness from 'app/common/hooks/queries/useBusiness';
import useMe from 'app/common/hooks/queries/useMeUncamel';

import useOrganization from 'app/common/hooks/queries/useOrganization';

export type CompetitorRankingsResponse = {
    competitorRankings: CompetitorRank[];
    clientRanking: CompetitorRank;
    isLoading: boolean;
};

export const useGetCompetitorRankings = (
    keyword: string,
    date: string | Date,
): CompetitorRankingsResponse => {
    const dateStr = typeof date === 'string' ? date : date.toISOString().split('T')[0];

    const { data: user } = useMe();
    const localBusinessId = useLocalBusinessIdCompetitiveBenchmark();
    const clientOrg = useOrganization(user?.org_id ?? 0);
    const { data: business } = useBusiness(localBusinessId);
    const businessFilters = useBusinessModalFilters();

    const queryKey: QueryKey = [
        COMPETITIVE_BENCHMARK_RANKINGS,
        dateStr,
        keyword,
        localBusinessId ? localBusinessId : businessFilters,
    ];
    const { data, isLoading } = useQuery(queryKey, () =>
        localBusinessId
            ? api.competitiveBenchmark.getCompetitorLocalRankings(keyword, dateStr, localBusinessId)
            : api.competitiveBenchmark.getCompetitorRankings(keyword, dateStr, businessFilters),
    );
    const clientRanking =
        (data && data.filter(rank => rank.highlight === true)[0]) ||
        ({
            name: clientOrg.data?.name,
            highlight: true,
            rank: 'N/A',
            address: business?.address_full,
        } as CompetitorRank);

    return {
        competitorRankings: data || [],
        clientRanking,
        isLoading,
    };
};
