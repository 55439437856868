import { useTranslation } from 'react-i18next';

import { V2FormattedBusinessData } from 'app/api/types/business';
import { CLOSED, CLOSED_TEMPORARILY } from 'app/common/data/businessStatuses';
import { STATIC_BASE_URL } from 'app/config';

import {
    BusinessCardImageSmall,
    BusinessCardLines,
    SmallBusinessCardContainer,
    StyledIconTickBox,
    StyledText,
    TickBox,
} from './SmallBusinessCard.styled';

type Props = {
    business: V2FormattedBusinessData;
    handleClick?: () => void;
    selected: boolean;
};

const SmallBusinessCard = ({ business, handleClick, selected }: Props) => {
    const { t } = useTranslation();

    return (
        <SmallBusinessCardContainer
            onClick={handleClick}
            onKeyPress={handleClick}
            role="button"
            tabIndex={-1}
            selected={selected}
            clickable={!!handleClick}
        >
            {selected && (
                <TickBox>
                    <StyledIconTickBox dataTrackId="" icon={['fa-check']} color="theme" />
                </TickBox>
            )}
            <BusinessCardImageSmall
                url={
                    business.logoUrl ?? `${STATIC_BASE_URL}/images/app/square_image_placeholder.png`
                }
            />
            <BusinessCardLines>
                <StyledText variant="bodySRegular" as="span" color="secondary" oneLine>
                    {business.name}
                </StyledText>
                <StyledText variant="bodySRegular" as="span" color="secondary" oneLine>
                    {business.addressFull}
                </StyledText>
                <StyledText
                    variant="bodySRegular"
                    as="span"
                    color="secondary"
                    oneLine
                >{`${business.zipcode} ${business.city}`}</StyledText>
                {business.status === CLOSED && (
                    <StyledText variant="bodyXSBold" as="span" color="danger">
                        {` ${t('business_closed')} `}
                    </StyledText>
                )}
                {business.status === CLOSED_TEMPORARILY && (
                    <StyledText variant="bodyXSBold" as="span" color="danger">
                        {` ${t(CLOSED_TEMPORARILY)} `}
                    </StyledText>
                )}
            </BusinessCardLines>
        </SmallBusinessCardContainer>
    );
};

export default SmallBusinessCard;
