import { connect } from 'react-redux';

import { InfoSectionExtensionGMB as InfoSectionExtensionGMBComponent } from 'app/businessConnection/components/genericQuickConnect/InfoSection/Extensions/GMB/InfoSectionExtensionGMB';
import { businessImportSelector } from 'app/states/knowledge/reducers/businessConnection/connection';
import { AppState, connectionSelector } from 'app/states/reducers';

const mapStateToProps = (state: AppState) => ({
    businessImport: businessImportSelector(connectionSelector(state)),
});

const InfoSectionExtensionGMB = connect(mapStateToProps)(InfoSectionExtensionGMBComponent);

export default InfoSectionExtensionGMB;
