import { call, put, takeEvery } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import { Saga } from 'app/common/types';
import {
    GET_CONNECTIONS_STATS_REQUEST,
    GetConnectionsStatsAction,
    getConnectionsStatsFailure,
    getConnectionsStatsSuccess,
} from 'app/states/knowledge/reducers/businessConnection/connectionsStats';

function* getConnectionsStats(action: GetConnectionsStatsAction): Saga {
    try {
        const connectionStatsData = yield call(
            api.business.getUserBusinessConnectionsStats,
            action.params,
        );
        yield put(
            getConnectionsStatsSuccess(
                connectionStatsData.count,
                connectionStatsData.linked_to_gmb_count,
                connectionStatsData.linked_to_facebook_count,
            ),
        );
    } catch (error) {
        yield put(getConnectionsStatsFailure(error));
    }
}

function* getConnectionsStatsSaga(): Saga {
    yield takeEvery(GET_CONNECTIONS_STATS_REQUEST, getConnectionsStats);
}

export default getConnectionsStatsSaga;
