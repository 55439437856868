import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

import {
    CHECKBOX_BM_POSITION,
    CHECKBOX_GM_POSITION,
} from 'app/pages/settingsV2/subPages/Team/components/Permissions/sections/PermissionsPageContent/PermissionsRoleBanner/PermissionsRoleBanner.styled';

export const PermissionsSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.radius.large};
    box-shadow: ${({ theme }) => theme.shadows.light};
    transition:
        box-shadow 200ms ease-in-out 0s,
        transform 200ms ease-in-out 0s;
    &:hover {
        transform: scale(1.015);
        box-shadow: ${({ theme }) => theme.shadows.dark};
    }
`;

export const PermissionsSectionHeader = styled.div`
    display: flex;
    height: 80px;
    justify-content: space-between;
`;

export const PermissionsSectionClickable = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding-left: 24px;

    &:hover {
        cursor: pointer;
    }
`;

export const PermissionsSectionExpand = styled(Text)`
    display: flex;
    align-items: center;
    margin-top: 2px;

    > i {
        margin-left: 5px;
    }
`;

export const PermissionsSectionCheckbox = styled.div`
    display: flex;
    align-items: center;

    // First child
    > *:first-of-type {
        // Hardcoded value to align with the role banner
        margin-right: ${CHECKBOX_GM_POSITION - CHECKBOX_BM_POSITION}px;
        // To compensate the 2 checkboxes width
        transform: translateX(150%);
    }

    // Second child
    > *:nth-of-type(2) {
        // Hardcoded value to align with the role banner
        margin-right: ${CHECKBOX_BM_POSITION}px;
        transform: translateX(50%);
    }
`;
