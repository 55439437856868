import React from 'react';

import { useTranslation } from 'react-i18next';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';

type Props = {
    colorState?: ColorState;
};

const AdvancedSettingsSectionContent = ({
    colorState = ColorState.OK,
}: Props) => {
    const { t } = useTranslation();
    const { data: business } = useBusiness();

    switch (colorState) {
        case ColorState.PRECHECK_ERRORS:
            return <>{t('business_edit_content_correct_this_field')}</>;
        case ColorState.MISSING_WARNING:
        case ColorState.DISABLED:
            return (
                <>{t('business_edit_content_advanced_settings_fill_siret')}</>
            );
        case ColorState.OK:
        case ColorState.CLOSED:
            if (!business?.national_identification_number) {
                return (
                    <>
                        {t(
                            'business_edit_content_advanced_settings_fill_siret',
                        )}
                    </>
                );
            }
            return (
                <>
                    <i className="fa-solid fa-circle-info" />
                    <span>{t('national_identification_number')}</span>
                </>
            );
        default:
            return null;
    }
};

export default AdvancedSettingsSectionContent;
