import { useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useSettingsPageContext } from 'app/pages/settings/AutoReply/hooks/useSettingsPageContext';

import { StyledButton, StyledSaveBar } from './SaveBar.styled';
import ToggleConfirmModal from '../TitleBar/AutoReplyToggle/ToggleConfirmModal';

const SaveBar = () => {
    const { t } = useTranslation();
    const [showToggleModal, setShowToggleModal] = useState(false);

    const {
        forReviewsWithComment,
        selectedRatings,
        selectedTemplates,
        formChanged,
        loadedSettings,
        saveHelpers: { save, saveAndDeactivate, saveAndActivate, checkIfCanActivate, isSaving },
    } = useSettingsPageContext();

    const showActivateButton = !loadedSettings?.enabled_at;

    const handleSave = () => {
        if (!showActivateButton && loadedSettings?.enabled) {
            saveAndActivate(true);
        } else if (!loadedSettings?.id) {
            saveAndDeactivate(true);
        } else {
            save();
        }
    };

    const handleActivate = () => {
        if (forReviewsWithComment && checkIfCanActivate()) {
            setShowToggleModal(true);
        } else {
            saveAndActivate();
        }
    };

    const handleClose = () => setShowToggleModal(false);

    return (
        <StyledSaveBar>
            <StyledButton
                dataTrackId="auto_reply_settings_save"
                size="large"
                appearance={'contained'}
                variant={'primary'}
                onClick={handleSave}
                disabled={!formChanged || isSaving}
                full
            >
                <Text variant="bodyLBold" color={HEX_COLORS.white}>
                    {t('auto_reply_form_save')}
                </Text>
            </StyledButton>
            {showActivateButton && (
                <>
                    <StyledButton
                        dataTrackId="auto_reply_settings_save_and_activate"
                        size="large"
                        variant="success"
                        disabled={!selectedRatings.length || !selectedTemplates.length || isSaving}
                        onClick={handleActivate}
                        full
                    >
                        <Text variant="bodyLBold" color={HEX_COLORS.white}>
                            {t('auto_reply_form_save_and_activate')}
                        </Text>
                    </StyledButton>
                    <ToggleConfirmModal show={showToggleModal} onHide={handleClose} />
                </>
            )}
        </StyledSaveBar>
    );
};

export default SaveBar;
