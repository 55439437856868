import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { PUBLISHER_INFOS } from 'app/common/data/queryKeysConstants';

export default function usePublisherInfos() {
    return useQuery([PUBLISHER_INFOS], () =>
        api.publisher.getPublishersForUser(),
    );
}
