import styled from '@emotion/styled';
import { ColorVariants, MainColorVariants } from '@partoohub/branding';
import { Icon, List } from '@partoohub/ui';

export const settingsSummaryColors: Array<Partial<ColorVariants | 'ratings'>> = [
    'default',
    'primary',
    'success',
    'warning',
    'ratings',
    'secondary',
];

export const StyledIcon = styled(Icon)<{ color: MainColorVariants }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: ${({ theme, color }) => theme.colors[color].alpha};
    border-radius: 4px;
    color: ${({ theme, color }) => theme.colors[color].background};
`;

export const StyledRow = styled(List.Row)`
    gap: 16px;
    padding: 16px 0;
`;
