import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    LOCAL_STORAGE_CONVERSATION_FILTERS,
    StatusFilterOption,
    URLParams,
} from 'app/states/messaging';

export const useStatusParam = () => {
    const [statusParam] = useStateQueryParams(URLParams.STATUS);
    const localStorageFilters = localStorage.getItem(LOCAL_STORAGE_CONVERSATION_FILTERS);
    const localStatusFilter = localStorageFilters
        ? JSON.parse(localStorageFilters)?.statusFilter
        : '';

    const defaultParam = isStatusParamValid(localStatusFilter)
        ? localStatusFilter
        : StatusFilterOption.OPEN;

    return statusParam && isStatusParamValid(statusParam) ? statusParam : defaultParam;
};

const isStatusParamValid = (statusParam: string) => {
    const statusOptions = Object.values(StatusFilterOption);

    return statusOptions.includes(statusParam as StatusFilterOption);
};
