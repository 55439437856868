const FRONT_MATCHER = /@\[(.+?)]\((client_name|business_name|url)\)/g;
const BACK_MATCHER = /\${(client_name|business_name|url)}/g;
const SPECIAL_CHARACTER_MATCHER = /[€[\\\]^{}~|]/g;

const frontReplacer = (found: string): string => {
    const idMatch = found.match(/\([^)]+\)/g);
    if (idMatch) {
        const id: string = idMatch[0].slice(1, -1);
        return `\${${id}}`;
    }
    return '';
};

const getBackReplacer =
    (dict: SmsTemplatePlaceholder[]) =>
    (found: string): string => {
        const id: string = /{([^}]+)}/g.exec(found)![1];
        const index: number = dict.findIndex(item => item.id === id);
        return `@[${
            index > -1 ? dict[index].display : 'MissingTranslation'
        }](${id})`;
    };

export const MAX_AUTHORIZED_CHARACTER_COUNT_TEMPLATE = 250;
export const MAX_AUTHORIZED_CHARACTER_COUNT_MESSAGE = 306;

export interface SmsTemplatePlaceholder {
    id: 'client_name' | 'business_name' | 'url';
    display: string;
}

// \r\n is generated when we save on dbadmin and it messes up the mention text area
export const cleanUp = (template: string): string =>
    template?.replace(/\r\n/g, '\n');

const getHelper = (t: (string) => string = (x: string) => x) => {
    const placeholders: SmsTemplatePlaceholder[] = [
        { id: 'client_name', display: t('sms_template_client_name') },
        { id: 'business_name', display: t('sms_template_business_name') },
        { id: 'url', display: t('sms_template_short_url') },
    ];

    const convertForComponent = (template: string): string =>
        cleanUp(template).replace(BACK_MATCHER, getBackReplacer(placeholders));

    const convertForApi = (template: string): string =>
        template.replace(FRONT_MATCHER, frontReplacer);

    const count = (template = ''): number => {
        const rawTemplate: string = template.replace(FRONT_MATCHER, '');
        let specialCharacterCount = 0;
        rawTemplate.replace(SPECIAL_CHARACTER_MATCHER, (found: string) => {
            specialCharacterCount += 1;
            return found;
        });
        return rawTemplate.length + specialCharacterCount;
    };

    return {
        BACK_MATCHER,
        placeholders,
        convertForComponent,
        convertForApi,
        count,
    };
};

export default getHelper;
