import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';

import useKeywordsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useKeywordsAnalytics';
import useMetricsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useMetricsAnalytics';
import {
    METRICS_VIEWS,
    sumKeyInTab,
} from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/metrics/count/useMetricsDataCount';
import { MetricsAttributesResponse } from 'app/pages/analytics/VisibilityAnalyticsV2/types/metrics';
import { MetricsApiKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/types/query';
import { DATE_FORMAT } from 'app/utils/dates';

// Const
const PRESENCE_STAT_MONTH_PERIOD = 6;
const LAST_N_MONTHS = {
    [MetricsApiKeys.FILTER_DATE_GTE]: format(
        subMonths(startOfMonth(new Date()), PRESENCE_STAT_MONTH_PERIOD - 1), // n - 1 offset
        DATE_FORMAT,
    ),
    [MetricsApiKeys.FILTER_DATE_LTE]: format(endOfMonth(new Date()), DATE_FORMAT),
};

const LAST_N_MONTHS_PREVIOUS = {
    [MetricsApiKeys.FILTER_DATE_GTE]: format(
        subMonths(startOfMonth(new Date()), 2 * PRESENCE_STAT_MONTH_PERIOD - 1),
        DATE_FORMAT,
    ),
    [MetricsApiKeys.FILTER_DATE_LTE]: format(
        subMonths(endOfMonth(new Date()), PRESENCE_STAT_MONTH_PERIOD),
        DATE_FORMAT,
    ),
};

// Hooks
export const useHomepagePresenceStatsViews = () => {
    return useMetricsAnalytics({
        ...LAST_N_MONTHS, // Last 6 months
        [MetricsApiKeys.METRICS]: METRICS_VIEWS.join(','),
    });
};

export const useHomepagePresenceStatsSearches = () => {
    return useKeywordsAnalytics({
        ...LAST_N_MONTHS, // Last 6 months
        [MetricsApiKeys.METRICS]: MetricsAttributesResponse.COUNT,
    });
};

export const useHomepagePresenceStatsSearchesPrevious = () => {
    return useKeywordsAnalytics({
        ...LAST_N_MONTHS_PREVIOUS, // Last 6 months
        [MetricsApiKeys.METRICS]: MetricsAttributesResponse.COUNT,
    });
};

export const useRetrieveTotalViews = (data: Record<string, any> | undefined): number => {
    return data?.metrics?.length > 0
        ? METRICS_VIEWS.reduce((acc, key) => acc + sumKeyInTab(data?.metrics, key), 0)
        : 0;
};

export const useRetrieveTotalSearches = (data: Record<string, any> | undefined): number => {
    return data?.keywords?.length > 0
        ? data?.keywords.reduce((acc, month) => acc + month.count, 0)
        : 0;
};
