import React, { useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix, Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { APP_PRODUCTS } from 'app/common/data/productIds';
import { SidebarButtonWithMenu } from 'app/common/designSystem/components/molecules/Sidebar/Sidebar/ProductSidebar/SidebarButtonWithMenu/SidebarButtonWithMenu';
import { productSelector } from 'app/common/reducers/me';
import dataLayer from 'app/common/utils/dataLayer';
import FunnelOnboarding from 'app/onboarding/FunnelOnboarding';
import { useIsMaxBusinessesLimitCheck } from 'app/pages/visibility/BusinessCreate/hooks/useIsMaxBusinessesLimitCheck';
import MaxBusinessReachedModal from 'app/pages/visibility/BusinessCreate/sections/BusinessCreateContent/MaxBusinessReachedModal';
import {
    CUSTOMER_EXPERIENCE_SEND_INVITE_PATH,
    SETTINGS_TEAM_USER_ADD_PATHNAME,
} from 'app/routing/routeIds';

import { meSelector } from 'app/states/reducers';

enum SidebarFabOptions {
    ONBOARDING_BUSINESS = 'onboarding_business',
    CREATE_USER = 'create_user',
    SEND_INVITATION = 'send_invitation',
}

export const SidebarFAB = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showFunnel, setShowFunnel] = useState(false);
    const [showMaxBusinessModal, setShowMaxBusinessModal] = useState<boolean>(false);
    const isMaxBusinessesLimitReached = useIsMaxBusinessesLimitCheck();

    const me = useSelector(meSelector);
    const sidebarProduct = productSelector(me);

    const options = [
        {
            name: SidebarFabOptions.ONBOARDING_BUSINESS,
            label: t('add_local_business'),
            value: SidebarFabOptions.ONBOARDING_BUSINESS,
            dataTrack: 'sidebar__fab__create_business',
            disabled: false,
        },
        {
            name: SidebarFabOptions.CREATE_USER,
            label: t('add_user'),
            value: SidebarFabOptions.CREATE_USER,
            dataTrack: 'sidebar__fab__create_user',
            disabled: false,
        },
        ...(sidebarProduct.includes(APP_PRODUCTS.REVIEW_BOOSTER)
            ? [
                  {
                      name: SidebarFabOptions.SEND_INVITATION,
                      label: t('rb_send_an_invitation'),
                      value: SidebarFabOptions.SEND_INVITATION,
                      dataTrack: 'sidebar__fab__send_invitation',
                      disabled: false,
                  },
              ]
            : []),
    ];

    const handleClick = (value: string): void => {
        if (value === SidebarFabOptions.ONBOARDING_BUSINESS) {
            dataLayer.pushDict('create_import_location');
            if (isMaxBusinessesLimitReached) {
                setShowMaxBusinessModal(true);
                return;
            }
            setShowFunnel(true);
        } else if (value === SidebarFabOptions.CREATE_USER) {
            dataLayer.pushDict('click_create_new_user');
            navigate(SETTINGS_TEAM_USER_ADD_PATHNAME);
        } else if (value === SidebarFabOptions.SEND_INVITATION) {
            dataLayer.pushDict('click_send_invitation_sidebar');
            navigate(CUSTOMER_EXPERIENCE_SEND_INVITE_PATH);
        }
    };

    return (
        <>
            <SidebarButtonWithMenu
                icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                onMenuClick={handleClick}
                options={options}
                dataTrack="sidebar__fab"
            />

            <Modal isOpen={showMaxBusinessModal} closeModal={() => setShowMaxBusinessModal(false)}>
                <MaxBusinessReachedModal closeModal={() => setShowMaxBusinessModal(false)} />
            </Modal>

            <FunnelOnboarding showFunnel={showFunnel} setShowFunnel={setShowFunnel} />
        </>
    );
};
