import styled from '@emotion/styled';

export const ProviderLogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: 70px;
    }
`;
