import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const EmptyPlaceholderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 42px;
    margin-inline: auto;
`;

export const Title = styled.div`
    margin: 24px 0 8px 0;
    color: ${HEX_COLORS.blackberry};
    font-size: 30px;
    font-weight: bold;
`;

export const Subtitle = styled.div`
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;
