import React, { ReactNode } from 'react';

import { Placement } from '@popperjs/core';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import SectionBody from './SectionBody';
import {
    SectionFooter,
    SectionGlobal,
    SectionHeader,
    SectionHeaderMenu,
    SectionSubtitle,
    SectionTitle,
    Suggestion,
} from './SectionContainer.styled';

type BodyContent = {
    mainLabel: string;
    description?: string;
    optionalLabel?: string | null;
    menuElem?: ReactNode | null;
    disabled?: boolean;
};

type SuggestionComponent = {
    key: string;
    component: ReactNode;
};

type Props = {
    title: string;
    disabled: boolean;
    // Optional props
    tooltipText?: string;
    tooltipPosition?: Placement;
    subtitleSuggestions?: SuggestionComponent[];
    bodyContentList?: BodyContent[];
    footerContent?: SuggestionComponent[];
    headerMenuElem?: ReactNode | null;
};

const SectionContainer = ({
    title,
    disabled,
    tooltipText,
    tooltipPosition,
    subtitleSuggestions,
    bodyContentList,
    footerContent,
    headerMenuElem,
}: Props) => {
    const section = (
        <SectionGlobal>
            <SectionHeader>
                <SectionTitle
                    className={disabled ? 'text-field__disabled' : ''}
                >
                    {title}
                </SectionTitle>
                {subtitleSuggestions && !!subtitleSuggestions.length && (
                    <SectionSubtitle>
                        {subtitleSuggestions.map(({ key, component }) => (
                            <Suggestion key={key}>{component}</Suggestion>
                        ))}
                    </SectionSubtitle>
                )}
                {headerMenuElem && (
                    <SectionHeaderMenu>{headerMenuElem}</SectionHeaderMenu>
                )}
            </SectionHeader>

            {bodyContentList &&
                bodyContentList.map(elem => (
                    <SectionBody
                        key={elem.mainLabel}
                        id={elem.mainLabel}
                        mainLabel={elem.mainLabel}
                        description={elem.description}
                        optionalLabel={elem.optionalLabel}
                        menuElem={elem.menuElem}
                        disabled={elem.disabled ? elem.disabled : disabled}
                    />
                ))}

            <SectionFooter>
                {footerContent && !!footerContent.length && (
                    <SectionSubtitle>
                        {footerContent.map(({ key, component }) => (
                            <Suggestion key={key}>{component}</Suggestion>
                        ))}
                    </SectionSubtitle>
                )}
            </SectionFooter>
        </SectionGlobal>
    );

    if (tooltipText) {
        return (
            <TooltipWrapper
                text={tooltipText}
                position={tooltipPosition || 'top-start'}
            >
                {section}
            </TooltipWrapper>
        );
    }

    return section;
};

export default SectionContainer;
