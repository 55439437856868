import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const GoogleVerificationSortSectionContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    position: relative;
    background: ${greyColorObject.light};
    border-radius: 4px;
    padding: 0 24px;
`;

export const SortTitlesContainer = styled.div`
    display: flex;
    width: 100%;
    margin-left: 80px;
`;
