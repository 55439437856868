import { CompetitiveBenchmarkContainer } from 'app/pages/analytics/CompetitiveBenchmark/CompetitiveBenchmark.styled';
import { HeaderSection } from 'app/pages/analytics/CompetitiveBenchmark/components';

import { CompetitiveBenchmark } from './CompetitiveBenchmark';
import { AnalyticsTabs } from '../VisibilityAnalyticsV2/components/AnalyticsTabs';

export default () => {
    return (
        <CompetitiveBenchmarkContainer>
            <HeaderSection />
            <AnalyticsTabs />
            <CompetitiveBenchmark />
        </CompetitiveBenchmarkContainer>
    );
};
