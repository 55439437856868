import { FC, useState } from 'react';

import { Button, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import WhatsappApiCalls from 'app/api/v2/api_calls/whatsappApiCalls';
import { WHATSAPP_ACCOUNT_COUNT } from 'app/common/data/queryKeysConstants';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import dataLayer from 'app/common/utils/dataLayer';

import { WhatsappOnboardingModal } from './WhatsappOnboardingModal';

interface Props {
    isInPlaceholder?: boolean;
}

const WhatsappAccountCreationButton: FC<Props> = ({ isInPlaceholder = false }) => {
    const { t } = useTranslation();
    const { data: me } = useMe();

    const [isWhatsappOnboardingModalVisible, setIsWhatsappOnboardingModalVisible] =
        useState<boolean>(false);

    const { data, isLoading: isWhatsappAccountsCountLoading } = useQuery(
        [WHATSAPP_ACCOUNT_COUNT],
        () => WhatsappApiCalls.getAccountsCount(),
        {},
    );

    const buttonDisabled =
        isWhatsappAccountsCountLoading || (data && data?.nb_waba_created >= data?.max_waba_allowed);
    const tooltipText = buttonDisabled
        ? t('messaging_settings_whatsapp_button_create_account_disabled')
        : '';

    const onClick = () => {
        const gtmEvent = isInPlaceholder ? 'connect_wa_no_account' : 'connect_wa_with_account';
        dataLayer.pushDict(gtmEvent, {
            partoo_user_id: me?.id,
            org_id: me?.org_id,
        });
        setIsWhatsappOnboardingModalVisible(true);
    };

    const button = isInPlaceholder ? (
        <Button
            onClick={onClick}
            shape="cube"
            size="medium"
            variant="primary"
            appearance="contained"
            icon={['fa-whatsapp', IconPrefix.BRANDS]}
            dataTrackId={''}
            disabled={buttonDisabled}
            tooltip={tooltipText}
        >
            {t('whatsapp_settings_no_accounts_placeholder_button_text')}
        </Button>
    ) : (
        <Button
            onClick={onClick}
            shape="cube"
            size="medium"
            variant="primary"
            appearance="contained"
            icon={['fa-plus']}
            dataTrackId={''}
            disabled={buttonDisabled}
            tooltip={tooltipText}
        >
            {t('messaging_settings_whatsapp_button_create_account')}
        </Button>
    );

    return (
        <>
            {button}
            {isWhatsappOnboardingModalVisible && (
                <WhatsappOnboardingModal
                    onClose={() => setIsWhatsappOnboardingModalVisible(false)}
                />
            )}
        </>
    );
};

export default WhatsappAccountCreationButton;
