import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import NoBusiness from 'app/common/components/placeholder/NoBusiness.container';
import { NoPresenceManagement } from 'app/common/components/placeholder/PresenceManagement/NoPresenceManagement';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import { requestPossibleCities, searchBusinesses } from 'app/common/reducers/newBusinesses';
import { searchOrgs } from 'app/common/reducers/orgs';
import {
    ContentSection,
    FiltersSection,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components';
import { BannerSection } from 'app/pages/analytics/VisibilityAnalyticsV2/components/BannerSection/BannerSection';
import { VisibilityContainer } from 'app/pages/analytics/VisibilityAnalyticsV2/VisibilityWithNewSidebar.styled';

export const VisibilityWithNewSidebar = () => {
    const { isLoading, hasBusinesses, subscribedToPM } = useBusinessSubscriptions();
    const dispatch = useDispatch();

    // To be sure to have data inside store for the business in mobile mode
    useEffect(() => {
        dispatch(searchBusinesses(1, ''));
        dispatch(requestPossibleCities());
        dispatch(searchOrgs(1, ''));
    }, []);

    if (!subscribedToPM && !isLoading) {
        return <NoPresenceManagement />;
    }

    if (!hasBusinesses && !isLoading) {
        return <NoBusiness />;
    }

    return (
        <VisibilityContainer>
            <BannerSection />
            <FiltersSection />
            <ContentSection />
        </VisibilityContainer>
    );
};
