import { camelCase, mapKeys } from 'lodash-es';

import { USER_STATUS_ACTIVE } from 'app/api/types';
import { V2FormattedUserData, V2UserData } from 'app/api/types/user';
import { BUSINESS_MANAGER } from 'app/common/data/roles';

const initialUserData: V2FormattedUserData = {
    id: '',
    shadowUserId: '',
    shadowUserRole: '',
    shadowUserIntercomHash: '',
    createdAt: null,
    firstName: '',
    lastName: '',
    lang: '',
    orgId: -1,
    phone: '',
    role: BUSINESS_MANAGER,
    email: '',
    status: USER_STATUS_ACTIVE,
    businessIds: [],
    provider: '',
    providerName: '',
    sidebarProducts: [],
    sidebarExcludedPages: [],
    // @ts-ignore
    reports: [],
    theme: 'blueberry',
    providerLogo: '',
    createdBy: '',
    groupId: null,
    hasActiveApiKeys: false,
    rmLastSeenDate: null,
    receiveReviewsEmailNotifications: true,
};

const formatUser = (user: V2UserData): V2FormattedUserData => ({
    ...initialUserData,
    ...mapKeys(user, (v, key) => camelCase(key)),
});

export default formatUser;
