import { BusinessCreateContainer } from './BusinessCreate.styled';
import BusinessCreateContent from './sections/BusinessCreateContent';
import BusinessCreateHeader from './sections/BusinessCreateHeader';

const BusinessCreate = () => {
    return (
        <BusinessCreateContainer>
            <BusinessCreateHeader />
            <BusinessCreateContent />
        </BusinessCreateContainer>
    );
};

export default BusinessCreate;
