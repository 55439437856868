import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import { TodoMessages } from 'app/pages/Homepage/sections/HomepageTodos/todos/TodoMessages';
import { Item } from 'app/pages/Homepage/utils/typing';

import { useIsAvailableTodoMessagesItem } from './useIsAvailableTodoMessagesItem';

export const useTodoMessagesItem = (): Item<SmallTodoProps> => {
    const isAvailable = useIsAvailableTodoMessagesItem();

    return {
        order: 1,
        isAvailable,
        component: TodoMessages,
        key: 'todo-messages',
    };
};
