import useDisplayCustomFields from 'app/businessEditV2/hooks/editV2utils/displaySections/useDisplayCustomFields';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import usePermissions from 'app/businessEditV2/hooks/permissions/usePermissions';

import { ColorState } from './types';

export default function useCustomFieldsSectionColor(): ColorState {
    // Hooks
    const { data: permissions } = usePermissions();
    const isClosed = useIsBusinessClosed();
    const display = useDisplayCustomFields();

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // If no custom fields for the org
    if (!display) {
        return ColorState.OK;
    }

    // Disabled
    const metadata = permissions?.find(section => section.name === 'custom_fields')?.fields ?? [];
    const hasEnabledCustomFields = metadata.filter(customField => customField.enabled).length > 0;
    if (!hasEnabledCustomFields) {
        return ColorState.DISABLED;
    }

    // Publisher Errors and Missing Fields not handled for Custom Fields

    // OK
    return ColorState.OK;
}
