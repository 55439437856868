import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

import Flexbox from 'app/styles/utils/flexbox';

export const InboxStyled = styled(Flexbox)`
    background: ${HEX_COLORS.white};
    overflow: auto;
`;

export const ConversationCardsContainer = styled.div`
    flex: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 4px 0px;
`;
