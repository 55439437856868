import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { GroupsSettingsQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

import EditGroupContent from './EditGroupContent';

const EditGroupModal = () => {
    const { t } = useTranslation();
    const [edit, setEdit] = useStateQueryParams(GroupsSettingsQueryKeys.EDIT, true);

    const [sectionId, groupId] = edit.split(',');

    const childrenRight = (
        <EditFormDescription
            title={t('group_explanation_title')}
            description={t('group_explanation')}
            advice={t('group_advice')}
        />
    );

    return (
        <FullScreenModal
            show={!!sectionId && !!groupId}
            onHideClick={() => setEdit('')}
            childrenLeft={<EditGroupContent closeModal={() => setEdit('')} />}
            childrenRight={childrenRight}
        />
    );
};

export default EditGroupModal;
