import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const HomepageTodosContainer = styled.div`
    margin-bottom: 32px;
`;

export const HomepageTodosTitle = styled(Text)`
    margin-bottom: 16px;
`;

type HomepageTodosItemProps = {
    small: boolean;
};

export const HomepageTodosGrid = styled.div<HomepageTodosItemProps>`
    display: grid;
    grid-template-columns: repeat(${props => (props.small ? 3 : 2)}, minmax(0, 1fr));
    gap: 24px;
`;
