import { css } from '@emotion/react';
import { IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';
import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';

interface Props {
    onMenuClick: (value: string) => void;
    disabled: boolean;
}

export const ServiceMenu = ({ onMenuClick, disabled }: Props) => {
    const { t } = useTranslation();

    return (
        <ActionButtonWithMenu
            dataTrackId="service_menu__action_button"
            icon={['fa-ellipsis-v', IconPrefix.SOLID]}
            appearance="outlined"
            disabled={disabled}
            options={[
                {
                    name: 'edit',
                    label: (
                        <MenuLabelItem text={t('edit')} icon={['fa-pen-alt', IconPrefix.REGULAR]} />
                    ),
                    value: 'edit',
                },
                {
                    name: 'delete',
                    label: (
                        <MenuLabelItem
                            text={t('delete')}
                            icon={['fa-trash-alt', IconPrefix.REGULAR]}
                            color="danger"
                        />
                    ),
                    value: 'delete',
                },
            ]}
            onMenuClick={(value: string) => onMenuClick(value)}
            cssMenu={css`
                top: 50px;
                right: 0px;
                left: unset;
            `}
        />
    );
};
