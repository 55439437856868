import React from 'react';

import { Interweave } from 'interweave';
import { useTranslation } from 'react-i18next';

import { ChipMatcher } from 'app/common/services/Matchers';
import { ConversationStarterPlaceholders } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';

type Props = {
    text: string;
};

const ConversationStarterItemText = ({ text }: Props) => {
    const { t } = useTranslation();
    const textMatchers: Array<any> = ConversationStarterPlaceholders.map(element =>
        ChipMatcher(`@\\[${t(element.display)}]\\(${element.id}\\)`, t(element.display)),
    );
    return <Interweave content={text} matchers={textMatchers} />;
};

export default ConversationStarterItemText;
