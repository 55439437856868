import Bowser from 'bowser';

const isBrowserOutdated = (): boolean =>
    Bowser.getParser(window.navigator.userAgent).satisfies({
        chrome: '<67',
        edge: '<44',
        firefox: '<60',
        uc: '<12.13.2',
    }) || false;

export default isBrowserOutdated;
