import { TextInput } from '@partoohub/ui';

interface Props {
    value: string;
    label?: string;
    placeholder?: string;
    onChange: (value: string) => void;
    error?: boolean;
    notice?: string;
    required?: boolean;
}
export const EmailInput = ({
    value,
    onChange,
    label,
    placeholder,
    error,
    notice,
    required = false,
}: Props) => {
    return (
        <TextInput
            dataTrackId="email_input"
            type="email"
            label={label}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            error={!!error}
            notice={notice}
            required={required}
        />
    );
};
