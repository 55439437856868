import { FontAwesomeIconsPartooUsed } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { SettingSummaryItemValues } from 'app/pages/settingsV2/subComponents/SettingsSummaryItem/SettingsSummaryItem';
import { MESSAGES_PATH_TOKEN } from 'app/routing/routeIds';

import { useCanAccessMessagesSetting } from './useCanAccessMessagesSetting';

export const useSettingMessagesSummary = (): SettingSummaryItemValues => {
    const { t } = useTranslation();
    const { canAccess: canAccessMessages, isLoading: isLoadingMessage } =
        useCanAccessMessagesSetting();
    return {
        settingData: {
            id: '2',
            icon: FontAwesomeIconsPartooUsed.faMessage,
            title: t('settings_summary_messages_title'),
            description: t('settings_summary_messages_description'),
            url: MESSAGES_PATH_TOKEN,
            display: canAccessMessages,
        },
        isLoading: isLoadingMessage ?? false,
    };
};
