import styled from '@emotion/styled';

import Clickable from 'app/common/components/buttons/Clickable';

export const StyledWarningBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    background-color: #f5f7fb;
    gap: 4px;
    border-radius: 4px;
`;

export const StyledLink = styled(Clickable)`
    margin-top: 8px;
`;
