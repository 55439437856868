import useMe from 'app/common/hooks/queries/useMeUncamel';

export enum CEUserProducts {
    RM = 'review_management',
    RB = 'review_booster',
    FBM = 'feedback_management',
}

export const useHasCEUserProducts = (): {
    userProducts: Record<CEUserProducts, boolean | undefined>;
    isLoading: boolean;
} => {
    const { data: me, isLoading } = useMe();

    return {
        userProducts: {
            [CEUserProducts.RM]: me?.sidebar_products.includes('review_management'),
            [CEUserProducts.RB]: me?.sidebar_products.includes('review_booster'),
            [CEUserProducts.FBM]: me?.sidebar_products.includes('feedback_management'),
        },
        isLoading,
    };
};
