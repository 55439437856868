import React from 'react';

import LoadingSpinner from 'app/common/components/loaders/LoadingSpinner';
import {
    DoughnutOverlayBigStyled,
    DoughnutOverlayStyled,
} from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/AverageGrade/DoughnutOverlay.styled';

type Props = {
    averageGrade: number;
    percentage: string | null;
    color: string;
    hasReviews: boolean;
    isLoading: boolean;
};

const DoughnutOverlay = ({
    averageGrade,
    percentage,
    color,
    hasReviews,
    isLoading,
}: Props) => {
    const overlay = hasReviews ? (
        <DoughnutOverlayStyled>
            {percentage ? (
                <span className={`color--${color}`}>{percentage}%</span>
            ) : (
                <div className="color--secondary">
                    <span className={`color--${color}`}>{averageGrade}</span>
                </div>
            )}
        </DoughnutOverlayStyled>
    ) : (
        <DoughnutOverlayBigStyled className="color--light">
            <i className="fa-regular fa-circle" />
        </DoughnutOverlayBigStyled>
    );

    return isLoading ? (
        <DoughnutOverlayBigStyled>
            <LoadingSpinner fontSize="1em" />
        </DoughnutOverlayBigStyled>
    ) : (
        overlay
    );
};

export default DoughnutOverlay;
