import { useMutation } from 'react-query';

import { CustomFieldsSectionCreationPayload } from 'app/api/types/business';
import { CreateCustomFieldSectionResponse } from 'app/api/types/custom_fields';
import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { CUSTOM_FIELDS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

export function useCreateCustomFieldSection(
    onSuccess: (data: CreateCustomFieldSectionResponse) => void = () => undefined,
    onError: () => void = () => undefined,
) {
    return useMutation(
        (payload: CustomFieldsSectionCreationPayload) =>
            api.customFieldsSections.createCustomFieldsSection(payload),
        {
            onSuccess: data => {
                queryClient
                    .invalidateQueries(invalidatesAny(CUSTOM_FIELDS))
                    .then(() => onSuccess(data));
            },
            onError,
        },
    );
}
