import { Outlet, useRoutes } from 'react-router-dom';

import { BusinessEditV2PageHeader } from 'app/businessEditV2/components/BusinessEditV2Header/BusinessEditV2PageHeader';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { BusinessCreatePageHeader } from 'app/pages/visibility/BusinessCreate/sections/BusinessCreateHeader/BusinessCreatePageHeader';
import { BusinessSelectionProvider } from 'app/pages/visibility/BusinessListV2/hooks/businessSelection/useBusinessSelectionContext';
import { BusinessListV2PageHeader } from 'app/pages/visibility/BusinessListV2/sections/BusinessListHeader/BusinessListV2PageHeader';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';

import { ADD_PATH_TOKEN, BUSINESS_ID, LOCATIONS_PATH_TOKEN } from 'app/routing/routeIds';

const VisibilityHeaderRoutes = () => {
    const { data: me } = useMe();
    const meRole = me ? me.role : null;

    const routes = [
        {
            path: LOCATIONS_PATH_TOKEN,
            children: [
                {
                    index: true,
                    element: (
                        <ProtectedRoute userRole={meRole}>
                            <BusinessSelectionProvider>
                                <BusinessListV2PageHeader />
                            </BusinessSelectionProvider>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ADD_PATH_TOKEN,
                    element: (
                        <ProtectedRoute userRole={meRole}>
                            <BusinessCreatePageHeader />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `:${BUSINESS_ID}`,
                    element: (
                        <ProtectedRoute userRole={meRole}>
                            <BusinessSelectionProvider>
                                <BusinessEditV2PageHeader />
                            </BusinessSelectionProvider>
                        </ProtectedRoute>
                    ),
                },
            ],
        },
    ];

    return useRoutes(routes);
};

export const VisibilityHeader = () => {
    return (
        <>
            <VisibilityHeaderRoutes />
            <Outlet />
        </>
    );
};
