import { useEffect } from 'react';

import { css } from '@emotion/react';
import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ButtonWithMenu } from 'app/common/designSystem/components/molecules/ButtonWithMenu/ButtonWithMenu';
import useGroupsV2 from 'app/common/hooks/queries/useGroupsV2';

import { StyledSectionSelectButtonIcons } from './SectionSelect.styled';
import { useInternalRankingParameters } from '../hooks/useInternalRankingParameters';

export const SectionSelect = () => {
    const { t } = useTranslation();

    const {
        query: { hasNextPage, fetchNextPage, isLoading: isGroupsLoading },
        sections,
    } = useGroupsV2();
    const options = [
        { label: t('businesses'), value: '' as const, name: '' },
        ...(sections ?? []).map(s => {
            return {
                label: s.name,
                value: `${s.id}`,
                name: s.name,
            };
        }),
    ];

    const {
        section: [selectedSection, setSection],
        resetParameters,
    } = useInternalRankingParameters();

    useEffect(() => {
        if (!isGroupsLoading) resetParameters({ section: options });
    }, [isGroupsLoading]);

    const onDimensionChange = (v: string) => {
        setSection(v);
    };

    if (isGroupsLoading) return undefined;

    return (
        <ButtonWithMenu
            dataTrackId=""
            size="small"
            options={options}
            onMenuClick={onDimensionChange}
            icon={
                <StyledSectionSelectButtonIcons>
                    <Icon icon={[FontAwesomeIconsPartooUsed.faCaretUp, IconPrefix.SOLID]} />
                    <Icon icon={[FontAwesomeIconsPartooUsed.faCaretDown, IconPrefix.SOLID]} />
                </StyledSectionSelectButtonIcons>
            }
            iconPosition="right"
            variant="secondary"
            appearance="outlined"
            text={
                <>
                    <Text as="span" color="secondary" variant="bodyMBold">
                        {t('display_by')}
                        {' : '}
                    </Text>
                    <Text as="span" variant="bodyMBold">
                        {options.find(dim => dim.value === selectedSection)?.label ?? ''}
                    </Text>
                </>
            }
            cssMenu={css`
                top: 40px;
                right: 0px;
                left: unset;
            `}
            hasMore={hasNextPage}
            loadMore={fetchNextPage}
        />
    );
};
