import { useGetCompletionFilter } from './params/useGetCompletionFilter';
import { useGetGroupFilter } from './params/useGetGroupFilter';
import { useGetOrderBy } from './params/useGetOrderBy';
import { useGetProductFilter } from './params/useGetProductFilter';
import { useGetQuery } from './params/useGetQuery';
import { useGetStatusFilter } from './params/useGetStatusFilter';

export const useBusinessSearchQueryParams = () => ({
    ...useGetQuery(),
    ...useGetGroupFilter(),
    ...useGetStatusFilter(),
    ...useGetProductFilter(),
    ...useGetCompletionFilter(),
    ...useGetOrderBy(),
    partoo_ui: true,
    is_onboarding: false,
});
