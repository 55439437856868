import Clickable from 'app/common/components/buttons/Clickable';
import { Color } from 'app/common/designSystem/constants/color';

type Props = {
    color: Color;
    onClick?: (arg0: Event) => void;
    onBlur?: (arg0: Event) => void;
    className?: string;
    text?: string;
    icon?: JSX.Element;
    disabled?: boolean;
};

const StrikeThroughButton = ({
    color,
    onClick = () => undefined,
    onBlur = undefined,
    text = '',
    className = '',
    icon = undefined,
    disabled = false,
}: Props) => (
    <Clickable
        onClick={disabled ? () => undefined : onClick}
        onBlur={onBlur}
        className={`strike_through_button clickable strike_through_button--${
            disabled ? 'disabled' : color
        } ${className}`}
    >
        {icon ? (
            <div>
                {icon}
                &nbsp;
                {text}
            </div>
        ) : (
            text
        )}
    </Clickable>
);

export default StrikeThroughButton;
