import { RealtimeUpdatesState } from './types';
import * as actions from '../actions';

export const initialState: RealtimeUpdatesState = {
    reviews: [],
};
// REDUCER
export const realtimeUpdatesReducer = (
    state: RealtimeUpdatesState = initialState,
    action: actions.RealtimeUpdatesActionType,
): RealtimeUpdatesState => {
    switch (action.type) {
        case actions.REALTIME_UPDATE_REVIEW_RECEIVED:
            // we store the raw realtime update, we will process it later
            return { ...state, reviews: [action.review, ...state.reviews] };

        case actions.CLEAR_ALL_REALTIME_REVIEWS:
            return { ...state, reviews: [] };

        default:
            return { ...state };
    }
};
export default realtimeUpdatesReducer;
