import { OUTLINED, Type } from 'app/common/designSystem/constants/type';
import {
    ConversationMessage,
    MESSAGE_SENDER_TYPE,
    QuickRepliesMessageContent,
} from 'app/states/messaging';

import { TextBubble } from './TextBubble';
import { TextBubbleContainer } from '../MessageBubbleContent.styled';

interface QuickRepliesProps {
    message: ConversationMessage;
    handleBubbleClick: () => void;
}

export const QuickRepliesBubble = ({ message, handleBubbleClick }: QuickRepliesProps) => {
    const isConsumerMessage = message.sender_type === MESSAGE_SENDER_TYPE.CONSUMER;
    const quickRepliesContent = message.content as QuickRepliesMessageContent;
    const quickRepliesList = quickRepliesContent.quick_replies;

    return (
        <TextBubbleContainer>
            {quickRepliesContent.text && (
                <TextBubble
                    text={quickRepliesContent.text}
                    isConsumerMessage={isConsumerMessage}
                    onClick={handleBubbleClick}
                />
            )}
            {quickRepliesList.map(quickReply => (
                <TextBubble
                    text={quickReply.title}
                    isConsumerMessage={isConsumerMessage}
                    bubbleType={OUTLINED as Type}
                    onClick={() => undefined}
                />
            ))}
        </TextBubbleContainer>
    );
};
