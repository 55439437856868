import { useContext, useEffect } from 'react';

import { toast } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import i18n from 'app/common/translations/i18n';
import { URLParams } from 'app/states/messaging';

import { useGetConversations } from './useGetConversations';
import { MessagingContext } from '../MessagingContext';

/**
 * Deeplink: if a query param is set, the conversation is set to active
 * If the conversationId in the query params is not found on the first page
 * we load More until we find it or toast error at the last page if not found
 * @TODO JAGUAR -> Use new deeplinking strategy
 */
export const useConversationDeeplink = () => {
    const { conversations, hasNextPage, fetchNextPage } = useGetConversations();
    const { activeConversation, setActiveConversation } = useContext(MessagingContext);
    const [conversationParamId] = useStateQueryParams(URLParams.CONVERSATION_ID);
    const [statusFilter] = useStateQueryParams(URLParams.STATUS);
    const [assignFilter] = useStateQueryParams(URLParams.ASSIGN);

    useEffect(() => {
        if (
            conversations.length > 0 &&
            conversationParamId &&
            activeConversation?.id !== +conversationParamId
        ) {
            const conversation = conversations.find(
                conversation => conversation.id === +conversationParamId,
            );
            if (conversation) {
                setActiveConversation(conversation);
            } else if (hasNextPage) {
                fetchNextPage();
            } else {
                toast.error(null, i18n.t('api_key_manager_snackbar_error'));
            }
        }
    }, [
        conversationParamId,
        statusFilter,
        assignFilter,
        hasNextPage,
        fetchNextPage,
        setActiveConversation,
        activeConversation,
        JSON.stringify(conversations),
    ]);
};
