import produce from 'immer';

import { BusinessWithSelected } from 'app/common/components/businessModal/hooks/privateHooks/useBusinessModalDisplayedBusinesses';
import env from 'app/common/services/getEnvironment';

export type Namespace = 'org' | 'city' | 'group';

export type Value = CityValue | GroupValue;

// we need to store a label along with the id, because the label may come from
// server side data far away inside an infinite scroll
type CityValue = { id: string; label: string };
type GroupValue = {
    id: Array<string>; // we store the group id and its subgroups ids here
    label: string;
};
type OrgValue = { id: string; label: string };

export type CityFilter = ['city', CityValue];
export type GroupFilter = ['group', GroupValue];
export type OrgFilter = ['org', OrgValue];

export type Filter = CityFilter | GroupFilter | OrgFilter;

export const getCities = (filters: Array<Filter>) =>
    filters.filter(([ns]) => ns === 'city').map(([, { id }]) => id as string);
export const getGroupIds = (filters: Array<Filter>) =>
    filters
        .filter(([ns]) => ns === 'group')
        .map(([, { id }]) => id as Array<string>)
        .flat(1);
export const getOrgIds = (filters: Array<Filter>) =>
    filters.filter(([ns]) => ns === 'org').map(([, { id }]) => id as string);

export interface BusinessModalFilters {
    // filters that determine what businesses are shown in the infinite scroll
    // (not necessarily the ones that are selected)
    filters: Array<Filter>;
    query: string;

    // filters that determine what businesses are selected
    // (not necessarily the ones shown in the infinite scroll)
    selection: {
        query: string;
        selectAll: boolean;

        // businesses that have been selected manually in addition to "select all" button
        selectedIds: Array<string>;

        // businesses that were selected through "select all" button then deselected manually
        unselectedIds: Array<string>;

        filters: Array<Filter>;
    };
}

export const businessModalFiltersInitialState: BusinessModalFilters = {
    filters: [],
    query: '',
    selection: {
        query: '',
        selectAll: false,
        selectedIds: [],
        unselectedIds: [],
        filters: [],
    },
};

export const SET_QUERY = 'SET_QUERY';
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const TOGGLE_SELECT_ALL = 'TOGGLE_SELECT_ALL';
export const TOGGLE_BUSINESS = 'TOGGLE_BUSINESS';
export const RESET = 'RESET';

// Business modal outside actions
export const BUSINESS_MODAL_SET_SELECTED_BUSINESSES =
    'BUSINESS_MODAL_SET_SELECTED_BUSINESSES';

export type BusinessModalFiltersAction =
    | { type: typeof SET_QUERY; value: string }
    | { type: typeof TOGGLE_SELECT_ALL }
    | {
          type: typeof TOGGLE_BUSINESS;
          business: BusinessWithSelected;
          noBusinessLimit: boolean;
      }
    | {
          type: typeof ADD_FILTER;
          payload: Filter;
          selectAll: boolean;
          useNewGroups: boolean;
      }
    | {
          type: typeof REMOVE_FILTER;
          payload: Filter;
          selectAll: boolean;
          useNewGroups: boolean;
      }
    | {
          type: typeof BUSINESS_MODAL_SET_SELECTED_BUSINESSES;
          payload: Array<string>;
      }
    | { type: typeof RESET };

export const getFilterIndex = (state: BusinessModalFilters, filter: Filter) =>
    state.filters.findIndex(
        ([ns, v]) =>
            ns === filter[0] &&
            JSON.stringify(v.id) === JSON.stringify(filter[1].id),
    );

// Limit the number of businesses selected or unselected manually because
// we cannot put an arbitrary large number of business ids in urls when
// doing API calls.
export const BUSINESSES_MANUAL_SELECTION_LIMIT = 50;

// We use a reducer here because multiple fields are changing together when performing one
// action (such as clicking "select all"), so this is a good use-case for a reducer.
export const businessModalFiltersReducer = produce<
    BusinessModalFilters,
    [BusinessModalFiltersAction]
>((state = businessModalFiltersInitialState, action) => {
    if (env.isDev()) {
        // eslint-disable-next-line no-console
        console.log('[BUSINESS MODAL] Action dispatched', action);
    }

    switch (action.type) {
        case RESET:
            return businessModalFiltersInitialState;
        case SET_QUERY:
            state.query = action.value;
            break;
        case ADD_FILTER:
            // [Temporary] currently we don't have a business filter cities__in that supports
            //             a list of cities, so when we add a city here it replaces any exising
            //             one.
            // TODO: add cities__in filter, update state management and remove this
            if (action.payload[0] === 'city') {
                state.filters = state.filters.filter(f => f[0] !== 'city');
            }

            // Remove org and group filters if org filter is added
            if (action.payload[0] === 'org') {
                state.filters = state.filters.filter(
                    f => !['org', 'group'].includes(f[0]),
                );

                if (action.useNewGroups) {
                    // Remove group selections if org filter is added, as multi-org groups filter is not supported
                    state.selection.filters = state.selection.filters.filter(
                        f => !['group'].includes(f[0]),
                    );
                }
            }

            const index = getFilterIndex(state, action.payload);
            if (index === -1) {
                // insert filter
                state.filters.push(action.payload);

                // Force selectAll in some cases
                if (action.selectAll) {
                    state.selection.selectAll = true;
                    state.selection.filters = state.filters;
                }
            }
            break;
        case REMOVE_FILTER:
            // Remove group filters and selection if org filter is removed
            if (action.useNewGroups && action.payload[0] === 'org') {
                state.filters = state.filters.filter(
                    f => !['group'].includes(f[0]),
                );

                state.selection.filters = state.selection.filters.filter(
                    f => !['group'].includes(f[0]),
                );
            }

            const i = getFilterIndex(state, action.payload);
            if (i !== -1) {
                // remove filter
                state.filters.splice(i, 1);

                // Force selectAll in some cases
                if (action.selectAll) {
                    state.selection.selectAll = true;
                    state.selection.filters = state.filters;
                }
            }
            break;
        case TOGGLE_SELECT_ALL:
            if (!state.selection.selectAll) {
                // select all
                state.selection.selectAll = true;
                state.selection.selectedIds = [];
                state.selection.unselectedIds = [];

                // apply filters to selection
                state.selection.query = state.query;
                state.selection.filters = state.filters;
                break;
            } else {
                // erase
                state.selection = {
                    query: '',
                    selectAll: false,
                    selectedIds: [],
                    unselectedIds: [],
                    filters: [],
                };
                state.filters = [];
                state.query = '';
                break;
            }
        case TOGGLE_BUSINESS:
            const { business, noBusinessLimit } = action;
            const selectedIndex = state.selection.selectedIds.findIndex(
                id => id === business.id,
            );
            const unselectedIndex = state.selection.unselectedIds.findIndex(
                id => id === business.id,
            );

            // TODO maybe if no businesses remain selected, toggle off the "select all"
            if (business.selected) {
                // the business is selected, let's unselect it
                if (selectedIndex !== -1) {
                    // business has been selected manually, remove it from the list
                    state.selection.selectedIds.splice(selectedIndex, 1);
                } else {
                    // business was selected via "select all"
                    if (
                        state.selection.unselectedIds.length >=
                            BUSINESSES_MANUAL_SELECTION_LIMIT &&
                        !noBusinessLimit
                    ) {
                        break;
                    }
                    state.selection.unselectedIds.push(business.id);
                }
            } else if (unselectedIndex !== -1) {
                // business was unselected manually
                state.selection.unselectedIds.splice(unselectedIndex, 1);
            } else {
                // business was not part of the "select all"
                if (
                    state.selection.selectedIds.length >=
                        BUSINESSES_MANUAL_SELECTION_LIMIT &&
                    !noBusinessLimit
                ) {
                    break;
                }
                // Remove org filter if manual business is selected
                if (
                    state.selection.selectAll &&
                    state.selection.filters.some(f => f[0] === 'org')
                ) {
                    state.selection.filters = [];
                    state.selection.selectAll = false;
                }

                state.selection.selectedIds.push(business.id);
            }
            break;
        case BUSINESS_MODAL_SET_SELECTED_BUSINESSES:
            return {
                ...businessModalFiltersInitialState,
                selection: {
                    ...businessModalFiltersInitialState.selection,
                    selectedIds: action.payload,
                },
            };
        default:
            break;
    }
});
