import { useContext } from 'react';

import { CommentData } from 'app/api/types/comments';
import Flexbox from 'app/styles/utils/flexbox';

import { StyledUserLogo, UserLogoSelectorId } from './Comment.styled';
import CommentContent from './CommentContent';
import CommentIcon from './CommentIcon';
import { StyledCommentReply } from './CommentReply.styled';
import { ReviewCardContext, ReviewCardContextType } from '../../ReviewCard';

type Props = {
    parentId?: number;
    comment?: CommentData;
    isReplying: boolean;
    setIsReplying?: (isReplying: boolean) => void;
};

export default function CommentReply({
    parentId,
    comment = {} as CommentData,
    isReplying,
    setIsReplying,
}: Props) {
    const { review } = useContext<ReviewCardContextType>(ReviewCardContext);
    const hasLogo = !!parentId;

    return (
        <StyledCommentReply>
            <StyledUserLogo data-css-selector={UserLogoSelectorId}>
                {hasLogo && (
                    <CommentIcon
                        hasLink={false}
                        reviewState={review.state}
                        authorName={comment?.author_name}
                        isReplySuggestion={comment?.is_reply_suggestion}
                    />
                )}
            </StyledUserLogo>
            <Flexbox flex="1" flexDirection="column">
                <CommentContent
                    parentId={parentId}
                    isEditing={isReplying}
                    setIsEditing={setIsReplying}
                />
            </Flexbox>
        </StyledCommentReply>
    );
}
