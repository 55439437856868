import React from 'react';

import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import PostcardSentImage from './images/PostcardSentImage';
import { StatusScreenImageWrapper, StatusScreenWrapper } from './StatusScreens.styled';
import { ConfirmButton } from '../../Fields/ConfirmButton.styled';
import { FloatingFooter } from '../../Fields/FloatingFooter.styled';

type Props = {
    address: string;
    daysToDeliver: string;
    handleClose: () => void;
};

const PostcardSentScreen: React.FC<Props> = ({ address, daysToDeliver, handleClose }) => {
    const { t } = useTranslation();

    return (
        <>
            <StatusScreenWrapper>
                <StatusScreenImageWrapper>
                    <PostcardSentImage />
                </StatusScreenImageWrapper>
                <Text variant="heading5">
                    {t('google_verification__postcard_sent_screen__title', {
                        address: address,
                    })}
                </Text>
                <Text variant="bodyMRegular">
                    <div>
                        {t('google_verification__postcard_sent_screen__first_line', {
                            daysToDeliver: daysToDeliver,
                        })}
                    </div>
                    <div>{t('google_verification__postcard_sent_screen__second_line')}</div>
                </Text>
            </StatusScreenWrapper>
            <FloatingFooter>
                <ConfirmButton
                    dataTrackId="google_verification__methods__postcard_confirm_button"
                    variant="success"
                    size="large"
                    shape="cube"
                    onClick={handleClose}
                >
                    {t('google_verification__status_screen__okay')}
                </ConfirmButton>
            </FloatingFooter>
        </>
    );
};

export default PostcardSentScreen;
