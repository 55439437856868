// @ts-nocheck

import React, { Component } from 'react';

// https://github.com/aholachek/react-flip-toolkit/blob/master/packages/flip-toolkit/src/flip/animateFlippedElements/index.ts#L118
export const rectInViewport = ({ top, bottom, left, right }) =>
    top < window.innerHeight && bottom > 0 && left < window.innerWidth && right > 0;

type State = any;

type Props = {
    animationKey?: any;
    velocity: number;
    duration: number;
    easing: string;
};
/**
 * TODO: cancel pending animations each time we schedule new animations
 */

class FlipAnimated extends Component<State, Props> {
    // eslint-disable react/destructuring-assignment
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    static defaultProps = {
        duration: 1000,
        easing: 'ease-out',
    };

    shouldTrigger(prevProps: Props) {
        // trigger only if animationKey is not set (no optimisation), or
        // if it's been set and has changed
        const prevAnimationKey = prevProps.animationKey;

        return (
            (prevAnimationKey === undefined && this.animationKey === undefined) ||
            prevAnimationKey !== this.animationKey
        );
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (
            this.shouldTrigger(prevProps) &&
            this.ref.current &&
            this.ref.current.getBoundingClientRect
        ) {
            return this.ref.current.getBoundingClientRect();
        }

        return null;
    }

    /**
     * @param snapshot
     * the value returned by getSnapshotBeforeUpdate above
     * https://en.reactjs.org/docs/react-component.html#getsnapshotbeforeupdate
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot === null) {
            return;
        }

        if (
            this.shouldTrigger(prevProps) &&
            this.ref.current &&
            this.ref.current.getBoundingClientRect
        ) {
            const first = snapshot;
            const last = this.ref.current.getBoundingClientRect();

            // don't animate elements outside of the user's viewport
            // https://github.com/aholachek/react-flip-toolkit/blob/master/packages/flip-toolkit/src/flip/animateFlippedElements/index.ts#L216
            if (!rectInViewport(first) && !rectInViewport(last)) {
                return;
            }

            const invert = {
                top: first.top - last.top,
                left: first.left - last.left,
            };

            if (invert.top === 0 && invert.left === 0) {
                return;
            }

            const { velocity, duration, easing } = this.props;
            const computedDuration = velocity
                ? Math.sqrt(invert.top ** 2 + invert.left ** 2) / velocity
                : duration;

            if ('animate' in this.ref.current) {
                // TODO: WAAPI polyfill
                this.ref.current.animate(
                    [
                        {
                            transform: `translate(${invert.left}px, ${invert.top}px)`,
                        },
                        {
                            transform: 'translate(0,0)',
                        },
                    ],
                    {
                        duration: computedDuration,
                        easing,
                    },
                );
            }
        }
    }

    render() {
        const { children: child } = this.props;

        if (typeof child === 'function') {
            return child({
                innerRef: this.ref,
            });
        }

        return React.cloneElement(child, {
            ref: this.ref,
        });
    }
}

export default FlipAnimated;
