import React, { FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { cloneDeep, uniqBy } from 'lodash';

import { GroupData } from 'app/common/reducers/groups';
import useGroupIds from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupIds';

interface BusinessListGroupContextType {
    groups: Array<GroupData>;
    reset: () => void;
    renameGroup: (currentGroups: Array<GroupData>, groupName: string) => void;
    createGroup: (currentGroups: Array<GroupData>, subgroup: GroupData) => void;
    deleteGroup: (groupId: number) => void;
}

export const BusinessListGroupContext = React.createContext<BusinessListGroupContextType>({
    groups: [],
    renameGroup: () => undefined,
    createGroup: () => undefined,
    deleteGroup: () => undefined,
    reset: () => undefined,
});

interface Props {
    children: ReactNode;
}

/**
 * Provider to handle hardcoded groups data
 */
export const BusinessListGroupProvider: FC<Props> = ({ children }) => {
    const [currentGroupId, currentSubgroupId] = useGroupIds();
    const [groups, setGroups] = useState<Array<GroupData>>([]);

    // Rename a group/subgroup
    const renameGroup = useCallback(
        (currentGroups: Array<GroupData>, groupName: string) => {
            const editedGroup = cloneDeep(
                currentGroups.find(group => group.groupId.toString() === currentGroupId),
            );
            if (!editedGroup) return;

            if (currentSubgroupId && currentSubgroupId !== currentGroupId) {
                editedGroup.subgroups =
                    editedGroup.subgroups?.map(subgroup => {
                        if (subgroup.groupId.toString() === currentSubgroupId) {
                            return {
                                ...subgroup,
                                groupName,
                            };
                        } else {
                            return subgroup;
                        }
                    }) ?? [];
            } else {
                editedGroup.groupName = groupName;
            }

            setGroups(uniqBy([editedGroup, ...groups], group => group.groupId));
        },
        [currentGroupId, currentSubgroupId, groups, setGroups],
    );

    // Create a group/subgroup
    const createGroup = useCallback(
        (currentGroups: Array<GroupData>, newGroup: GroupData) => {
            const parentGroup = cloneDeep(
                currentGroups.find(group => group.groupId === newGroup.parentId),
            );

            // Subgroup
            if (parentGroup) {
                setGroups(
                    uniqBy(
                        [
                            {
                                ...parentGroup,
                                subgroups: uniqBy(
                                    [newGroup, ...(parentGroup.subgroups ?? [])],
                                    sub => sub.groupId,
                                ),
                            },
                            ...groups,
                        ],
                        group => group.groupId,
                    ),
                );
            }

            // Group
            else {
                setGroups(uniqBy([newGroup, ...groups], group => group.groupId));
            }
        },
        [groups, setGroups],
    );

    // Delete a group/subgroup
    const deleteGroup = useCallback(
        (groupId: number) => {
            setGroups(
                groups
                    .filter(group => group.groupId !== groupId)
                    .map(group => ({
                        ...group,
                        subgroups:
                            group.subgroups?.filter(subgroup => subgroup.groupId !== groupId) ?? [],
                    })),
            );
        },
        [groups, setGroups],
    );

    // Reset the hardcoded groups
    const reset = useCallback(() => setGroups([]), [setGroups]);

    const value = useMemo(
        () => ({
            groups,
            renameGroup,
            createGroup,
            deleteGroup,
            reset,
        }),
        [groups, renameGroup, createGroup, deleteGroup, reset],
    );

    return (
        <BusinessListGroupContext.Provider value={value}>
            {children}
        </BusinessListGroupContext.Provider>
    );
};

export const useGroupContext = () => useContext(BusinessListGroupContext);
