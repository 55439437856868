import styled from '@emotion/styled';

export const ChatbotKnowledgeFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    border-radius: 8px;
    gap: 16px;
`;

export const ChatbotKnowledgeFormButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: end;
`;
