import { useLayoutEffect, useState } from 'react';

const getScreenDimensions = () => window.innerWidth;

/** @deprecated use useIsMobile() instead */
export const isMobile = () => getScreenDimensions() < 769;

export const useIsMobile = (): boolean => {
    const [isMobileState, setIsMobileState] = useState<boolean>(isMobile());

    // when going from landscape to portrait mode, isMobileState might be
    // true for a split second, thus we use useLayoutEffect to avoid any
    // flickering.
    useLayoutEffect(() => {
        const handleResize = () => {
            const isMobileValue = isMobile();
            if (isMobileValue !== isMobileState) {
                setIsMobileState(isMobileValue);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isMobileState, setIsMobileState]);

    return isMobileState;
};
