import { useTranslation } from 'react-i18next';

import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';
import { useFormatNumber } from 'app/common/hooks/useFormatNumber';
import {
    DoughnutLegendBodyStyled,
    DoughnutLegendColorStyled,
    DoughnutLegendCount,
    DoughnutLegendStyled,
} from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/AverageGrade/DoughnutLegend.styled';

interface Props {
    label: string;
    color: string;
    count: string;
    isLoading: boolean;
}

const labelTranslation = {
    satisfied: 'positive',
    neutral: 'neutral',
    dissatisfied: 'negative',
};

export const DoughnutLegend = ({ label, color, count, isLoading }: Props) => {
    const { t } = useTranslation();
    const formattedCount = useFormatNumber(+count);
    if (isLoading) {
        return <LoadingRectangle recWidth="120px" recHeight="10px" recMargin="0 0 12px 0" />;
    }

    return (
        <DoughnutLegendStyled>
            <DoughnutLegendBodyStyled className="color--secondary">
                <DoughnutLegendColorStyled className={`bg-${color}`} />
                {t(labelTranslation[label] ?? label)}
            </DoughnutLegendBodyStyled>
            <DoughnutLegendCount className={`bg-${color}--lighten color--${color}`}>
                {formattedCount}
            </DoughnutLegendCount>
        </DoughnutLegendStyled>
    );
};
