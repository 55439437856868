import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { DropZoneWrapper } from 'app/common/designSystem/components/molecules/DropZone/DropZone.styled';

export const PhotoFormModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 8px 8px 8px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 4px 0 16px 0;
`;

export const ReturnButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: none;
    font-size: 18px;
    font-weight: bold;
    color: ${HEX_COLORS.blackberry};
    margin-left: 8px;

    > i {
        margin-right: 8px;
    }
`;

type DropZoneContainerProps = {
    hideBorder: boolean;
    large?: boolean;
};

export const DropZoneContainer = styled.div<DropZoneContainerProps>`
    margin: 0 40px 40px 40px;
    border-radius: ${props => (props.large ? 8 : 400)}px;
    overflow: hidden;

    ${DropZoneWrapper} {
        width: ${props => (props.large ? 700 : 400)}px;
        height: ${props => (props.large ? 250 : 400)}px;
        cursor: pointer;
        border-radius: ${props => (props.large ? 8 : 400)}px;
        padding: 0;

        // Hack to hide the border of the dropzone
        ${props =>
            props.hideBorder &&
            css`
                border: none;
            `}

        &:hover,
        &:hover .drop_zone_text_wrapper {
            border-color: ${HEX_COLORS.blackberry};
            color: ${HEX_COLORS.blackberry};
        }
    }
`;

type PhotoPreviewProps = {
    opacity: number;
    url: string;
    large?: boolean;
};

export const PhotoPreview = styled.div<PhotoPreviewProps>`
    opacity: ${props => props.opacity};
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: ${props => (props.large ? 8 : 400)}px;
    background-image: url('${props => props.url}');
    background-position: center center;
    background-size: cover;
    background-color: ${greyColorObject.light};
    background-repeat: no-repeat;
    border: solid 3px ${({ theme }) => theme.colors.primary.initial};
    overflow: hidden;
`;

type PhotoPreviewOverlayProps = {
    disabled: boolean;
};

export const PhotoPreviewOverlay = styled.div<PhotoPreviewOverlayProps>`
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 22, 31, 0.5);
    transition: all ease 0.2s;

    > i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background-color: ${HEX_COLORS.white};
        color: ${({ theme }) => theme.colors.secondary.initial};
        font-size: 16px;
    }

    &:hover {
        ${props =>
            props.disabled
                ? css`
                      cursor: not-allowed;
                  `
                : css`
                      opacity: 1;
                      cursor: pointer;
                  `}
    }
`;

export const ActionButton = styled.div`
    width: 100%;
    margin-top: 12px;
`;
