import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { CONVERSATION_STARTERS_ITEMS } from 'app/common/data/queryKeysConstants';
import { formatPlaceholderToMention } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/services/utils';
import { ConversationStarterItemPostback } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';

export default function useConversationStartersItems(conversationStarterId: string | undefined) {
    const { t } = useTranslation();
    return useQuery(
        [CONVERSATION_STARTERS_ITEMS, conversationStarterId],
        () => api.conversationStarters.getConversationStartersItems(conversationStarterId),
        {
            enabled: !!conversationStarterId,
            select: data => ({
                items: data.items.map((item: ConversationStarterItemPostback) => ({
                    ...item,
                    reply_message: formatPlaceholderToMention(item.reply_message, t),
                })),
            }),
        },
    );
}
