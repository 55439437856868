import { useLanguage } from '../services/getLang';

export const useFormatDate = (
    options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
    },
): ((date: string) => string) => {
    const lang = useLanguage();

    const toTitleCase = (date: string) => {
        return date
            .split(' ')
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
    };

    return (date: string) => {
        const dateObject = new Date(date);
        const dateString = dateObject.toLocaleDateString(lang, options).replace(/\./g, '');

        return toTitleCase(dateString);
    };
};
