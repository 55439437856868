import { useContext } from 'react';

import { css } from '@emotion/react';
import { IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { OpenedModalEnum } from 'app/businessConnection/components/genericQuickConnect/QuickConnect';
import QuickConnectContext from 'app/businessConnection/components/genericQuickConnect/QuickConnect.context';
import { ButtonWithMenu } from 'app/common/designSystem/components/molecules/ButtonWithMenu/ButtonWithMenu';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { platformOptions } from './publishers';

export const QuickConnectButton = () => {
    const { t } = useTranslation();
    const { setPublisher, setShow } = useContext(QuickConnectContext);
    const [, setShowModal] = useStateQueryParams('modal');
    const [, setPublisherUrl] = useStateQueryParams('publisher');
    const setPublisherOpenModal = (value: string) => {
        setPublisher(value);
        setPublisherUrl(value);
        setShow(true);
        setShowModal(OpenedModalEnum.QUICK_CONNECT);
    };

    return (
        <ButtonWithMenu
            dataTrackId=""
            text={t('quick_connect_button')}
            variant="primary"
            icon={['fa-bolt', IconPrefix.SOLID]}
            options={platformOptions}
            onMenuClick={value => setPublisherOpenModal(value)}
            cssMenu={css`
                top: 50px;
                right: 0px;
                left: unset;
            `}
        />
    );
};
