import { useState } from 'react';

import WhatsappAccountCreatedModal from './WhatsappAccountCreatedModal';
import { WhatsappEmbeddedSignupModal } from './WhatsappEmbeddedSignupModal';

type Props = {
    onClose: () => void;
};

export const WhatsappOnboardingModal = ({ onClose }: Props) => {
    const [isOnboardingFinished, setIsOnboardingFinished] = useState<boolean>(false);
    const onEmbeddedSignupFinished = () => {
        setIsOnboardingFinished(true);
    };
    return isOnboardingFinished ? (
        <WhatsappAccountCreatedModal onClose={onClose} />
    ) : (
        <WhatsappEmbeddedSignupModal
            onClose={onClose}
            onEmbeddedSignupFinished={onEmbeddedSignupFinished}
        />
    );
};
