import { useMutation } from 'react-query';

import { Chatbots } from 'app/api/v2/api_calls/chatbotsApiCalls';
import { CHATBOTS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

export const useCreateChatbot = () => {
    return useMutation(() => Chatbots.createChatbot(), {
        onSuccess: () => {
            queryClient.invalidateQueries([CHATBOTS]);
        },
    });
};
