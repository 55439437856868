import getLang from 'app/common/services/getLang';
import { RTL_LANGUAGES } from 'app/common/services/languages';
import { handleLiveroom, removeLiveroomScript } from 'app/common/services/scripts/handleLiveroom';

import { handleIntercom, removeIntercomScript } from './handleIntercom';

export const setupApp = async () => {
    const lang = getLang();
    const isRtl = RTL_LANGUAGES.includes(lang);

    document.documentElement.lang = getLang();
    document.documentElement.dir = isRtl ? 'rtl' : 'ltr';

    return await Promise.allSettled([handleIntercom(), handleLiveroom()]);
};

export const removeScripts = () => {
    removeIntercomScript();
    removeLiveroomScript();
};
