import styled from '@emotion/styled';

export const GlobalContentRightSideContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 40px;
`;

export const PreviewText = styled.h3`
    font-weight: bold;
    align-self: flex-start;
    margin-top: 72px;
    margin-bottom: 36px;
`;

export const InfoCardTitleIcon = styled.span`
    margin-right: 4px;
`;
