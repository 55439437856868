import AIReplyLogo from 'app/common/designSystem/components/atoms/svg/AIReplyLogo';
import AutoReplyLogo from 'app/common/designSystem/components/atoms/svg/AutoReplyLogo';
import UserLogo from 'app/common/designSystem/components/atoms/svg/UserLogo';
import { ReviewObject } from 'app/states/reviews';
import { PENDING_AUTO_REPLY_REVIEWS, TREATED_AUTO_REPLY_REVIEWS } from 'app/states/reviews/filters';

import { AuthorInitialsIcon, StyledPartnerCommentSeparator } from './CommentIcon.styled';

type Props = {
    hasLink: boolean;
    reviewState: ReviewObject['state'];
    authorName?: string;
    isReplySuggestion?: boolean;
};

export default function CommentIcon({
    hasLink,
    reviewState,
    authorName,
    isReplySuggestion,
}: Props) {
    const isAutoReply = [TREATED_AUTO_REPLY_REVIEWS, PENDING_AUTO_REPLY_REVIEWS].includes(
        reviewState,
    );

    function getIcon() {
        if (isAutoReply) {
            return <AutoReplyLogo />;
        }
        if (isReplySuggestion) {
            return <AIReplyLogo />;
        }
        if (authorName && authorName.trim()) {
            return <AuthorInitialsIcon>{authorName.trim().charAt(0)}</AuthorInitialsIcon>;
        }
        return <UserLogo />;
    }

    return (
        <>
            {getIcon()}
            {hasLink && (
                <StyledPartnerCommentSeparator>
                    <div className="separator_min_height--vertical" />
                </StyledPartnerCommentSeparator>
            )}
        </>
    );
}
