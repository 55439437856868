import { useMemo } from 'react';

import { BusinessFieldArrayType } from 'app/api/types/business';
import useOrgPermissions from 'app/pages/settingsV2/subPages/Team/components/Permissions/hooks/useOrgPermissions';

export default function useCustomFields(): BusinessFieldArrayType {
    const { data: orgPermissions } = useOrgPermissions();

    return useMemo(
        () => orgPermissions?.filter(fields => fields.name === 'custom_fields')[0].fields ?? [],
        [orgPermissions],
    );
}
