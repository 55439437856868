import { useContext, useState } from 'react';

import { Option, Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ReviewObjectType } from 'app/api/types/review';
import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import { ADMIN } from 'app/common/data/roles';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useIsMobile } from 'app/common/services/screenDimensions';
import { useReviewDateFormatter } from 'app/reviewManagement/reviewList/hooks/ReviewCard/useReviewDateFormatter';
import useBusinessAddress from 'app/reviewManagement/reviewList/hooks/useBusinessAddress';
import useBusinessCode from 'app/reviewManagement/reviewList/hooks/useBusinessCode';
import TagSection from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/TagSection/TagSection';

import { ReviewCardAvatar } from './ReviewCardAvatar';
import { StyledAddress, StyledReviewAddress, StyledReviewAuthor } from './ReviewCardHeader.styled';

import { useDeleteFeedbackResult } from '../CommentSection/CommentTable/hooks/useDeleteFeedbackResult';
import { ReviewToggle } from '../CommentSection/ReviewToggle';
import { ReviewCardContext, ReviewCardContextType } from '../ReviewCard';
import { ReviewCardMenu } from '../ReviewCardMenu';

interface Props {
    displayTags: boolean;
}

export const ReviewCardHeader = ({ displayTags }: Props) => {
    const { t } = useTranslation();
    const { review } = useContext<ReviewCardContextType>(ReviewCardContext);
    const address = useBusinessAddress(review.businessId);
    const businessCode = useBusinessCode(review.businessId);
    const isMobile = useIsMobile();
    const { data: me } = useMe();

    const isShadowUser = !!me?.shadow_user_id && me?.shadow_user_role === ADMIN;

    const formatDate = useReviewDateFormatter();
    const formattedCreateDate = formatDate(review.date, {
        showHour: review.state !== 'deleted' ? !isMobile : false,
        showDay: !isMobile,
    });

    const initialTooltip = t('review_copy_store_code');
    const [storeCodeCopyTooltip, setStoreCodeCopyTooltip] = useState(initialTooltip);

    const [deleteFeedbackResult] = useDeleteFeedbackResult();
    const showMenu = isShadowUser && review.reviewObjectType === ReviewObjectType.FEEDBACK_RESULT;
    const onMenuClick = (option: Option) => {
        if (option.value === 'delete') {
            deleteFeedbackResult(review.id);
        }
    };

    return (
        <>
            <ReviewCardAvatar />
            <StyledReviewAuthor>
                <Stack direction="row" gap="0">
                    {review.state === 'deleted'
                        ? `${t('created_on')} ${formattedCreateDate}`
                        : review.authorName}
                    {showMenu && (
                        <ReviewCardMenu
                            dataTrackId=""
                            sections={[
                                {
                                    options: [
                                        {
                                            label: (
                                                <ReviewCardMenu.Label as="div" color="danger">
                                                    {t('delete')}
                                                </ReviewCardMenu.Label>
                                            ),
                                            name: 'delete',
                                            value: 'delete',
                                        },
                                    ],
                                },
                            ]}
                            onOptionClick={onMenuClick}
                        />
                    )}
                </Stack>
                <StyledReviewAddress>
                    {address ? (
                        <TooltipWrapper
                            text={storeCodeCopyTooltip}
                            position="bottom"
                            onHide={() => setStoreCodeCopyTooltip(initialTooltip)}
                        >
                            <StyledAddress
                                onClick={() => {
                                    if (!businessCode) return;
                                    navigator.clipboard.writeText(businessCode).then(() => {
                                        setStoreCodeCopyTooltip(t('review_store_code_copied'));
                                    });
                                }}
                            >
                                {address}
                            </StyledAddress>
                        </TooltipWrapper>
                    ) : (
                        <LoadingRectangle recWidth="320px" recHeight="10px" />
                    )}
                </StyledReviewAddress>
            </StyledReviewAuthor>

            {review.state !== 'deleted' && (
                <>
                    {displayTags && <TagSection />}
                    <ReviewToggle />
                </>
            )}
        </>
    );
};
