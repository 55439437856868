import { useContext, useMemo, useState } from 'react';

import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { POSTS } from 'app/common/data/queryKeysConstants';
import FacebookRefreshModal from 'app/presence/googlePosts/components/googlePostList/FacebookRefreshModal';
import { PostsQueryContext } from 'app/presence/googlePosts/components/googlePostList/PostsList';
import useFacebookConnectWindow from 'app/presence/googlePosts/hooks/useFacebookConnectWindow';

export function InstagramInsightsWarningBanner() {
    const { t } = useTranslation();
    const { data } = useContext(PostsQueryContext);

    const showInstagramInsightBanner = useMemo(() => {
        return (
            data?.pages
                .map(p => p.scopes.instagram ?? {})
                .some(igScope => {
                    return Object.values(igScope).some(scopes => {
                        return !scopes?.includes('post_insights');
                    });
                }) ?? false
        );
    }, [data]);

    const queryClient = useQueryClient();
    const [oauthAccountId, setOauthAccountId] = useState<string | null>();
    const openInstagramOauthDialog = useFacebookConnectWindow(oauthAccountId => {
        setOauthAccountId(oauthAccountId);
    });

    return (
        <>
            {showInstagramInsightBanner ? (
                <Banner
                    actionButtonText={t('connect_all')}
                    withCloseIcon={false}
                    onAction={openInstagramOauthDialog}
                    variant={'info'}
                    dataTrackId={''}
                >
                    {t('post_instagram_insights_warning_banner')}
                </Banner>
            ) : null}

            {oauthAccountId && (
                <FacebookRefreshModal
                    oauthAccountId={oauthAccountId}
                    onSuccess={() => {
                        queryClient.invalidateQueries(POSTS);
                        setOauthAccountId(null);
                    }}
                />
            )}
        </>
    );
}
