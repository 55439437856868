import { useState } from 'react';

import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Modal, Text, TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { BusinessSearchParams } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { usePostPublisherStatesExport } from 'app/presence/management/hooks/usePostPublisherStatesExport';
import { usePublishersStates } from 'app/presence/management/hooks/usePublishersStates';
import { DOWNLOADABLE_MAX } from 'app/states/reviews';

import {
    FooterContainer,
    IllustrationContainer,
} from './ConfirmEmailModalPresenceManagement.styled';

export const DIFFUSION_SEARCH_PARAMS: BusinessSearchParams = {
    subscribed_to_pm: true,
};

type ConfirmEmailModalPresenceManagementProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

export const ConfirmEmailModalPresenceManagement = ({
    isOpen,
    setIsOpen,
}: ConfirmEmailModalPresenceManagementProps) => {
    const { data } = usePublishersStates();
    const { data: me } = useMe();
    const [email, setEmail] = useState(me?.email);
    const { refetch } = usePostPublisherStatesExport(email);
    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    const businessCount = data?.business_count;
    const isBusinessCountLessDownloadableMax = businessCount < DOWNLOADABLE_MAX;
    const handleOnConfirm = () => {
        if (isBusinessCountLessDownloadableMax) {
            refetch();
        }
        handleOnCloseModal();
    };

    const handleOnCloseModal = () => {
        setIsOpen(false);
    };

    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Download,
    };

    return (
        <Modal closeModal={handleOnCloseModal} isOpen={isOpen}>
            <Dialog
                dataTrackId="export_publisher_states_modal"
                header={
                    <IllustrationContainer>
                        <Illustration
                            dataTrackId="confirm_email__illustration"
                            illustration={illustrationElement}
                            width="320"
                            height="140"
                        />
                    </IllustrationContainer>
                }
                subTitle={t('export_sent_to_this_email')}
                title={t('presence_management_report')}
            >
                <TextInput
                    dataTrackId="inputer_email"
                    label={t('email_extended')}
                    onChange={setEmail}
                    value={email ?? ''}
                    required
                />
                <FooterContainer>
                    <Button
                        dataTrackId="export_publisher_states_modal_cancel_button"
                        variant="secondary"
                        size="large"
                        shape="cube"
                        appearance="outlined"
                        onClick={handleOnCloseModal}
                    >
                        <Text variant="bodyLBold" as="span">
                            {t('cancel')}
                        </Text>
                    </Button>
                    <Button
                        dataTrackId="export_publisher_states_modal_send_email_button"
                        variant="primary"
                        size="large"
                        shape="cube"
                        onClick={handleOnConfirm}
                    >
                        <Text variant="bodyLBold" as="span" color="theme">
                            {t('send_email')}
                        </Text>
                    </Button>
                </FooterContainer>
            </Dialog>
        </Modal>
    );
};
