import { APP_PRODUCTS, BUSINESS_MESSAGE } from 'app/common/data/productIds';
import { ORG_ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useIsConversationStartersPageEnabled = (): boolean => {
    const { data: me } = useMe();

    if (me === undefined) {
        return false;
    }

    if (!me.sidebar_products.includes(APP_PRODUCTS[BUSINESS_MESSAGE]) || me?.role !== ORG_ADMIN) {
        return false;
    }

    return true;
};
