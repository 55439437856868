import { V2UserData } from 'app/api/types/user';
import { ADMIN, BUSINESS_MANAGER, GROUP_MANAGER, ORG_ADMIN, PROVIDER } from 'app/common/data/roles';

class AuthorizationService {
    user: V2UserData | null | undefined;

    constructor(user: V2UserData | null | undefined) {
        this.user = user;
    }

    isAdmin(): boolean {
        if (!this.user) {
            return false;
        }

        return this.user.role === ADMIN;
    }

    isOrgAdmin(): boolean {
        if (!this.user) {
            return false;
        }

        return this.user.role === ORG_ADMIN;
    }

    isProvider(): boolean {
        if (!this.user) {
            return false;
        }

        return this.user.role === PROVIDER;
    }

    isGroupManager(): boolean {
        if (!this.user) {
            return false;
        }

        return this.user.role === GROUP_MANAGER;
    }

    canUpdateUser(user: V2UserData): boolean {
        if (this.user && this.user.id === user.id) {
            return true;
        }

        if (this.isOrgAdmin() || this.isProvider()) {
            return (
                user.role === ORG_ADMIN ||
                user.role === GROUP_MANAGER ||
                user.role === BUSINESS_MANAGER
            );
        }

        if (this.isGroupManager()) {
            return user.role === BUSINESS_MANAGER;
        }

        if (this.isAdmin()) {
            return (
                user.role === PROVIDER ||
                user.role === ORG_ADMIN ||
                user.role === GROUP_MANAGER ||
                user.role === BUSINESS_MANAGER
            );
        }

        return false;
    }

    canDeleteUser(user: V2UserData, enableNewGroups = false): boolean {
        if (this.user && this.user.id === user.id) {
            return false;
        }

        if (this.isOrgAdmin() || this.isProvider()) {
            return (
                user.role === ORG_ADMIN ||
                user.role === GROUP_MANAGER ||
                user.role === BUSINESS_MANAGER
            );
        }

        if (this.isGroupManager()) {
            if (enableNewGroups) {
                return user.role === BUSINESS_MANAGER && !!this.user;
            } else {
                return (
                    user.role === BUSINESS_MANAGER &&
                    !!this.user &&
                    this.user.id === user.created_by
                );
            }
        }

        if (this.isAdmin()) {
            return (
                user.role === PROVIDER ||
                user.role === ORG_ADMIN ||
                user.role === GROUP_MANAGER ||
                user.role === BUSINESS_MANAGER
            );
        }

        return false;
    }
}

export default AuthorizationService;
