import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const CatalogModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    width: 524px;
`;

export const CatalogModalTitle = styled(Text)`
    text-align: center;
    margin-bottom: 8px;
`;

export const CatalogModalSubtitle = styled(Text)`
    text-align: center;
    margin-bottom: 30px;
`;

export const CatalogModalGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;
`;
