import { Icon, IconElement, Text, TextVariant } from '@partoohub/ui';

import { LabelWithIconContainer } from './LabelWithIcon.styled';

interface LabelWithIconProps {
    text: string;
    icon: IconElement;
    variant: TextVariant;
    iconSize?: string;
}

export const LabelWithIcon = ({ text, icon, variant, iconSize = 'small' }: LabelWithIconProps) => (
    <LabelWithIconContainer>
        <Icon icon={icon} iconSize={iconSize} />
        <Text as="span" variant={variant}>
            {text}
        </Text>
    </LabelWithIconContainer>
);
