import styled from '@emotion/styled';

export const FullScreen = styled.div<{ backgroundUrl: string }>`
    width: 100vw;
    height: 100vh;
    background: whitesmoke ${({ backgroundUrl }) => `url(${backgroundUrl})`};
    background-size: cover;
    display: flex;
    flex-direction: column;
    border-top: 8px rgba(0, 133, 242, 0.12) solid;
`;

export const Header = styled.div`
    height: 8%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 21px 24px 35px;

    img.logo {
        width: 100px;
        height: 30px;
    }

    .mdc-icon-button img {
        width: 15px;
        height: 15px;
    }
`;

export const HeaderActions = styled.div`
    width: calc(100% / 3);
    display: flex;
    gap: 16px;
    justify-content: ${(props: { place: string }) =>
        props.place === 'left' ? 'flex-start' : 'flex-end'};

    button {
        box-shadow: rgb(0 0 0 / 15%) 0 2px 2px;
        color: rgb(127, 142, 164) !important;
        background: white !important;

        &:hover {
            background-color: rgb(211, 211, 211, 0.5) !important;
        }
    }
`;

export const PartooLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    width: calc(100% / 3);
`;

export const Loading = styled.div<{ progress: number }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.primary.initial};
    width: ${({ progress }) => `${progress}%`};
    transition: width 0.3s;
`;

export const Body = styled.div`
    height: 85%;
    display: flex;
    flex: 1 1 0;
    align-items: center;
`;

export const Footer = styled.div`
    display: none;
`;

export const Title = styled.h1`
    font-weight: bold;
    font-size: 38px;
    line-height: 48px;
    text-align: center;
    color: ${({ theme }) => theme.colors.default.initial};
    margin: 0 0 8px;
`;

export const SubTitle = styled.div`
    width: 500px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin-bottom: 30px;
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 4%;
`;

export const FullPageContainer = styled(Container)`
    flex-direction: column;
    overflow: auto;

    & > img {
        width: 384px;
        height: 168px;
        margin-bottom: 17px;
    }
`;
