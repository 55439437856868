import { useEffect } from 'react';

import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { AccountPartner } from 'app/api/types/account';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';

import { partnerNameToPartner } from 'app/businessConnection/services/PartnerToAccountsMethods';
import { useRefreshOAuthAccount } from 'app/common/contexts/taskListener/useRefreshOAuthAccount';

import { NUMBER_IMPORTED_OAUTH_ACCOUNTS, OAUTH_ACCOUNTS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

import { AccountImportModalContainer, Spacer } from './AccountImportModal.styled';

import { AccountImportRow } from './elements/AccountImportRow';

export const AccountImportModal = () => {
    const { t } = useTranslation();
    const { oauthAccountId, partnerName, displayImportModal, setDisplayImportModal } =
        usePartnerConnectionContext();

    const onHide = () => {
        setDisplayImportModal(false);
        setStartRefresh(false);
    };

    const {
        isSuccess,
        isLoading,
        isError,
        accountsProgress,
        accountsTotal,
        locationsProgress,
        instagramAccountsProgress,
        setStartRefresh,
    } = useRefreshOAuthAccount({
        oauthAccountId,
        partner: partnerNameToPartner[partnerName] as AccountPartner,
        startRefreshAtMount: false,
        timeout: 2 * 60 * 1000,
        fetch_origin: 'connection_settings',
    });

    useEffect(() => {
        if (displayImportModal) setStartRefresh(true);
    }, [displayImportModal]);

    const getVariant = () => {
        if (isError) return 'danger';
        if (isSuccess) return 'success';
        return 'primary';
    };

    const getIllustration = () => {
        if (isError) return IllustrationOldEnum.Error;
        if (isSuccess) return IllustrationOldEnum.Success;
        return IllustrationOldEnum.Loading;
    };

    const variant = getVariant();
    const isDone = isError || isSuccess || isLoading === false;

    useEffect(() => {
        if (isSuccess) {
            queryClient.invalidateQueries([OAUTH_ACCOUNTS]);
            queryClient.invalidateQueries([NUMBER_IMPORTED_OAUTH_ACCOUNTS]);
        }
    }, [isSuccess]);

    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: getIllustration(),
    };

    return (
        <Modal isOpen={displayImportModal} closeModal={onHide}>
            <Dialog
                dataTrackId="import_account_modal"
                header={
                    <div style={{ paddingTop: '24px' }}>
                        <Illustration
                            dataTrackId="account_import_modal__illustration"
                            illustration={illustrationElement}
                            width="320"
                            height="140"
                        />
                    </div>
                }
                subTitle={t(`importing_${partnerName}_account_helper`)}
                title={t(`importing_${partnerName}_account`)}
            >
                <AccountImportModalContainer>
                    <div>
                        <AccountImportRow
                            t={t}
                            doneCount={accountsProgress}
                            totalCount={accountsTotal}
                            explanationKey={`recovering_${partnerName}_accounts_in_progress`}
                            variant={variant}
                            isDone={isDone}
                        />
                        <Spacer />
                        <AccountImportRow
                            t={t}
                            doneCount={locationsProgress}
                            explanationKey={`recovering_${partnerName}_locations_in_progress`}
                            variant={variant}
                            isDone={isDone}
                        />
                        {partnerName === 'facebook' && (
                            <>
                                <Spacer />
                                <AccountImportRow
                                    t={t}
                                    doneCount={instagramAccountsProgress}
                                    explanationKey="recovering_instagram_accounts_in_progress"
                                    variant={variant}
                                    isDone={isDone}
                                />
                            </>
                        )}
                    </div>
                    <Button
                        dataTrackId="validate_import_account_modal"
                        disabled={!isDone}
                        variant={variant}
                        size="large"
                        shape="cube"
                        full
                        onClick={onHide}
                    >
                        {t(isDone ? 'validate' : 'import_in_progress')}
                    </Button>
                </AccountImportModalContainer>
            </Dialog>
        </Modal>
    );
};
