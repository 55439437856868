import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { Text } from '@partoohub/ui';

import { CONTAINED, OUTLINED, Type } from 'app/common/designSystem/constants/type';
import EASINGS from 'app/styles/constants/easings';
import Flexbox from 'app/styles/utils/flexbox';

export const MessageBubbleWrapperStyled = styled.div`
    margin-left: 8px;
    display: flex;
    justify-content: end;
    border-radius: inherit;

    ${(props: { isIgStory: boolean }) =>
        props.isIgStory &&
        css`
            margin-left: 0px;
        `}
`;

type MessageBubbleStyledProps = {
    bubbleType: Type;
    isConsumer: boolean;
    consumerBackgroundColor: string;
};

export const MessageBubbleStyled = styled(Text)`
    border-radius: 20px;
    text-align: left;
    padding: 12px 16px;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    hyphens: auto;
    cursor: pointer;
    a {
        text-decoration: underline;
        color: ${HEX_COLORS.white};
    }
    ${(props: MessageBubbleStyledProps) =>
        props.bubbleType === CONTAINED &&
        css`
            color: ${HEX_COLORS.white};
            background: linear-gradient(126.5deg, #03d2f1 14.42%, ${HEX_COLORS.primary} 85.42%);
        `};
    ${(props: MessageBubbleStyledProps) =>
        props.bubbleType === OUTLINED &&
        css`
            background:
                linear-gradient(white, white) padding-box,
                linear-gradient(126.5deg, #03d2f1 14.42%, ${HEX_COLORS.primary} 85.42%) border-box;
            color: ${HEX_COLORS.primary};
            cursor: default;
        `};
    ${(props: MessageBubbleStyledProps) =>
        props.isConsumer &&
        css`
            color: ${HEX_COLORS.black};
            background: ${props.consumerBackgroundColor};
            cursor: auto;
            a {
                color: ${HEX_COLORS.blackberry};
                text-decoration: underline;
            }
        `};
`;

export const NoticeBubbleStyled = styled(Text)`
    padding: 4.5px 8px;
    border-style: solid;
    border-width: 1px;
    border-radius: 500px;
    border-color: ${greyColorObject.initial};
    background-color: ${HEX_COLORS.white};

    ${(props: { isConsumer: boolean }) =>
        props.isConsumer &&
        css`
            margin-left: 8px;
        `}
`;

export const Separator = styled.div`
    align-self: stretch;
    width: 3px;
    margin: 0px 16px;
    background-color: ${greyColorObject.initial};
    border-radius: 50px;
`;

export const MessageBubbleFlexbox = styled(Flexbox)`
    width: 70%;
`;

export const MessageBubbleFlexboxWrapper = styled.div`
    display: flex;
    justify-content: end;
    flex-direction: row-reverse;
    min-width: 80%;

    ${(props: { isConsumer: boolean }) =>
        props.isConsumer &&
        css`
            justify-content: start;
            flex-direction: row;
        `};
`;

export const StoryStateHeaderStyledWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 4px;
`;

export const StoryUnavailableHeader = styled(Text)``;

const mediaBubbleCommonStyles = (props: { isConsumer: boolean }) => css`
    display: inline;
    cursor: pointer;
    border-radius: 20px 20px 0 20px;
    border: 1px solid ${greyColorObject.initial};
    box-sizing: border-box;

    ${props.isConsumer &&
    css`
        cursor: auto;
        border-radius: 20px 20px 20px 0;
    `};
`;

export const MessageBubbleImageStyled = styled.img`
    max-height: 400px;
    max-width: 100%;
    background-color: ${HEX_COLORS.white};

    ${mediaBubbleCommonStyles};
`;

export const MessageBubbleLoaderStyled = styled.img`
    width: 250px;
    height: 200px;
    background-color: ${greyColorObject.initial};

    ${mediaBubbleCommonStyles};
`;

export const MessageBubbleVideoStyled = MessageBubbleImageStyled.withComponent('video');

export const StoryDetailHeader = styled(Text)`
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    text-align: right;

    ${(props: { isConsumer: boolean }) =>
        props.isConsumer &&
        css`
            text-align: left;
            margin-right: 0;
        `};
`;

export const StoryReplySpacer = styled.div`
    content: '';
    height: 8px;
`;

export const MessageSenderDetail = styled(Text)`
    flex-direction: column;
    opacity: 0;
    cursor: default;
    height: 0px;
    visibility: hidden;
    white-space: nowrap;
    text-align: right;
    transition:
        opacity 0.3s ${EASINGS.easeInOutQuart} 0.05s,
        height 0.3s ${EASINGS.easeInOutQuart},
        margin-top 0.3s ${EASINGS.easeInOutQuart},
        visibility 0s linear 0.35s;
    ${(props: { show: boolean }) =>
        props.show &&
        css`
            opacity: 1;
            height: 14px;
            margin-top: 4px;
            cursor: auto;
            visibility: visible;
            transition:
                opacity 0.3s ${EASINGS.easeInOutQuart} 0.05s,
                height 0.3s ${EASINGS.easeInOutQuart},
                margin-top 0.3s ${EASINGS.easeInOutQuart},
                visibility 0s linear;
        `};
`;

export const MessageErrorWrapper = styled(Flexbox)`
    cursor: pointer;
`;

export const MessageErrorRetry = styled(Text)`
    margin-top: 4px;
    height: 14px;
    text-align: right;
    & > i {
        margin-right: 4px;
        vertical-align: middle;
    }
`;
