import { useDateFilter } from './useDateFilter';
import { useGetKeywordData } from './useGetKeywordData';
import { useGetKeywordsCompetitive } from './useGetKeywordsCompetitive';
import { usePeriodFilter } from './usePeriodFilter';

export const useFiltersAreLoading = () => {
    const { isLoading: keywordsIsLoading } = useGetKeywordsCompetitive();
    const { isLoading: keywordDataIsLoading } = useGetKeywordData();
    const [period] = usePeriodFilter();
    const [date] = useDateFilter();

    return keywordsIsLoading || keywordDataIsLoading || !period || !date;
};
