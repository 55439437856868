import useKeywordsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useKeywordsAnalytics';
import { useKeywordsQueryKpis } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/keywords/kpis/useKeywordsQueryKpis';

export const useKeywordsLoadingKpis = () => {
    const { queryCount, queryThreshold } = useKeywordsQueryKpis();

    const { isLoading: isLoadingCount } = useKeywordsAnalytics(queryCount);
    const { isLoading: isLoadingThreshold } = useKeywordsAnalytics(queryThreshold);

    return isLoadingCount || isLoadingThreshold;
};
