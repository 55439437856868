import { useState } from 'react';

import { css } from '@emotion/react';
import { Icon, IconButton, Text } from '@partoohub/ui';
import { Trans, useTranslation } from 'react-i18next';

import { JimIcon } from 'app/pages/settings/Chatbot/assets/jim-icon';
import {
    BannerContainer,
    BulletsPoints,
    Content,
} from 'app/pages/settings/Chatbot/components/PageContent/Banner.styled';

export const ChatbotBanner = () => {
    const { t } = useTranslation();
    const [showBanner, setShowBanner] = useState(true);
    if (!showBanner) {
        return null;
    }
    return (
        <BannerContainer>
            <Text
                variant="heading6"
                css={css`
                    z-index: 1;
                `}
            >
                <Trans
                    t={t}
                    i18nKey={'chatbot_banner_heading'}
                    components={{
                        strong: <Text as="span" variant="heading6" color="slate" />,
                    }}
                />
            </Text>
            <Content>
                <BulletsPoints>
                    <li>
                        <Icon icon={['fa-flag']} />
                        <Text
                            variant="bodyMRegular"
                            css={css`
                                z-index: 1;
                            `}
                        >
                            {t('chatbot_banner_jim_languages')}
                        </Text>
                    </li>
                    <li>
                        <Icon icon={['fa-message-check']} />
                        <Text
                            variant="bodyMRegular"
                            css={css`
                                z-index: 1;
                            `}
                        >
                            {t('chatbot_banner_jim_discussion')}
                        </Text>
                    </li>
                    <li>
                        <Icon icon={['fa-face-laugh']} />
                        <Text
                            variant="bodyMRegular"
                            css={css`
                                z-index: 1;
                            `}
                        >
                            {t('chatbot_banner_jim_humane')}
                        </Text>
                    </li>
                </BulletsPoints>
                <JimIcon size={64} />
            </Content>
            <aside>
                <IconButton
                    dataTrackId=""
                    icon={['fa-times']}
                    size="medium"
                    appearance="outlined"
                    onClick={() => setShowBanner(false)}
                />
            </aside>
        </BannerContainer>
    );
};
