import { useEffect } from 'react';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import useCustomFields from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useCustomFields';
import { CustomFieldsQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

import CustomFieldForm from '../CustomFieldForm';

type Props = {
    closeModal: () => void;
};

const CustomFieldsEditContent = ({ closeModal }: Props) => {
    const [editCustomFieldId] = useStateQueryParams(CustomFieldsQueryKeys.EDIT);
    const customFields = useCustomFields();

    const customField = customFields.find(({ id }) => id?.toString() === editCustomFieldId);

    useEffect(() => {
        if (!customField) {
            closeModal();
        }
    }, [customField, closeModal]);

    return (
        <CustomFieldForm
            customFieldsType={customField?.type ?? ''}
            order={customField?.order ?? 1}
            editMode
            closeModal={closeModal}
            oldParams={customField ?? {}}
        />
    );
};

export default CustomFieldsEditContent;
