import { FilterName, PartialFilters } from '@partoohub/modular-components';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { ReviewsQueryKeys } from 'app/reviewManagement/reviewList/hooks/useGetReviewsFilters';
import { LIVE_REVIEWS } from 'app/states/reviews/filters';

const getStateQueryFromFilter = (filters: PartialFilters, filterName: FilterName) => {
    if (!(filterName in filters && filters[filterName] instanceof Map)) {
        return '';
    }
    const trueEntries = [...filters[filterName].entries()].filter(([, v]) => v === true);
    const trueKeys = trueEntries.map(([k]) => k);

    return trueKeys.join(',');
};

/**
 *  DynamicFilters
 */
export const useSetStateQueryFromFilters = () => {
    const [, setPlatformsFilter] = useStateQueryParams(ReviewsQueryKeys.PLATFORMS);
    const [, setRatingFilter] = useStateQueryParams(ReviewsQueryKeys.RATING);
    const [, setStatusFilter] = useStateQueryParams(ReviewsQueryKeys.STATUS);
    const [, setMessageFilter] = useStateQueryParams(ReviewsQueryKeys.COMMENTS);
    const [, setTagFilter] = useStateQueryParams(ReviewsQueryKeys.TAG);
    const [, setReviewObjectTypeFilter] = useStateQueryParams(ReviewsQueryKeys.REVIEW_OBJECT);

    const setFilters = (filters: PartialFilters) => {
        setPlatformsFilter(getStateQueryFromFilter(filters, FilterName.SOCIAL_MEDIA));
        setRatingFilter(getStateQueryFromFilter(filters, FilterName.REVIEW_TYPE));
        setStatusFilter(getStateQueryFromFilter(filters, FilterName.STATUS) || LIVE_REVIEWS);
        setMessageFilter(getStateQueryFromFilter(filters, FilterName.CONTENT));
        setTagFilter(getStateQueryFromFilter(filters, FilterName.TAGS));
        setReviewObjectTypeFilter(getStateQueryFromFilter(filters, FilterName.REVIEW_OBJECT_TYPE));
    };

    return setFilters;
};
