import { IconPrefix, Modal } from '@partoohub/ui';

import IS_IFRAME from 'app/common/data/iframe';

import {
    StyledCloseButton,
    StyledModalContent,
    StyledPreviewIframe,
} from './FeedbackFormPreviewModal.styled';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    previewUrl: string | undefined;
}

export const FeedbackFormPreviewModal = ({ previewUrl, isOpen, onClose }: Props) => {
    if (!previewUrl) return undefined;

    return (
        <>
            {isOpen && (
                <StyledCloseButton
                    dataTrackId=""
                    icon={['fa-close', IconPrefix.REGULAR]}
                    onClick={onClose}
                />
            )}

            <Modal
                isOpen={isOpen}
                closeModal={onClose}
                placement="center"
                overlayAppearance={IS_IFRAME ? 'iframe' : 'dark'}
                hideOnClickOutside={false}
            >
                <StyledModalContent>
                    <StyledPreviewIframe src={previewUrl} />
                </StyledModalContent>
            </Modal>
        </>
    );
};
