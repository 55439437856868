import { omit, uniq } from 'lodash-es';

import { V2FormattedLocationData } from 'app/api/types/account';

// REQUEST STATUS
const RUNNING = 'RUNNING';
const FAILED = 'FAILED';
const DONE = 'DONE';

// ACTION TYPES
export const GET_GMB_LOCATION_REQUEST = 'GET_GMB_LOCATION_REQUEST';
const GET_GMB_LOCATION_REQUEST_SUCCESS = 'GET_GMB_LOCATION_REQUEST_SUCCESS';
const GET_GMB_LOCATION_REQUEST_FAILURE = 'GET_GMB_LOCATION_REQUEST_FAILURE';
export const FUZZY_SEARCH_GMB_LOCATIONS = 'FUZZY_SEARCH_GMB_LOCATIONS';
const FUZZY_SEARCH_GMB_LOCATIONS_SUCCESS = 'FUZZY_SEARCH_GMB_LOCATIONS_SUCCESS';
const FUZZY_SEARCH_GMB_LOCATIONS_FAILURE = 'FUZZY_SEARCH_GMB_LOCATIONS_FAILURE';

// ACTION FLOW TYPES
export type GetGmbLocationAction = {
    type: 'GET_GMB_LOCATION_REQUEST';
    locationId: string;
};

type GetGmbLocationSuccessAction = {
    type: 'GET_GMB_LOCATION_REQUEST_SUCCESS';
    location: V2FormattedLocationData;
};

type GetGmbLocationFailureAction = {
    type: 'GET_GMB_LOCATION_REQUEST_FAILURE';
    error: Record<string, any>;
    locationId: string;
};

export type FuzzySearchGmbLocationsAction = {
    type: 'FUZZY_SEARCH_GMB_LOCATIONS';
    searchInput: string;
};

type FuzzySearchGmbLocationsSuccessAction = {
    type: 'FUZZY_SEARCH_GMB_LOCATIONS_SUCCESS';
    searchResultsById: Record<string, V2FormattedLocationData>;
    searchResultsIds: Array<string>;
};

type FuzzySearchGmbLocationsFailureAction = {
    type: 'FUZZY_SEARCH_GMB_LOCATIONS_FAILURE';
    error: Record<string, any>;
};

export type GMBLocationsState = {
    isSearchingOnServer: boolean;
    searchInput: string;
    searchErrors: Record<string, any>;
    searchResultsIds: Array<string>;
    searchResultsById: Record<string, any>;
    ids: Array<string>;
    byId: Record<string, any>;
    statusById: Record<string, any>;
    getRequests: {
        statuses: Record<string, 'RUNNING' | 'DONE' | 'FAILED'>;
        errors: Record<string, Record<string, any> | null>;
    };
};

export type GmbResourceAction =
    | FuzzySearchGmbLocationsAction
    | FuzzySearchGmbLocationsSuccessAction
    | FuzzySearchGmbLocationsFailureAction
    | GetGmbLocationAction
    | GetGmbLocationSuccessAction
    | GetGmbLocationFailureAction;

// ACTION CREATORS
export const getGmbLocation = (locationId: string): GetGmbLocationAction => ({
    type: GET_GMB_LOCATION_REQUEST,
    locationId,
});

export const getGmbLocationSuccess = (
    location: V2FormattedLocationData,
): GetGmbLocationSuccessAction => ({
    type: GET_GMB_LOCATION_REQUEST_SUCCESS,
    location,
});

export const getGmbLocationFailure = (
    locationId: string,
    error: Record<string, any>,
): GetGmbLocationFailureAction => ({
    type: GET_GMB_LOCATION_REQUEST_FAILURE,
    locationId,
    error,
});

export const fuzzySearchGmbLocations = (searchInput: string): FuzzySearchGmbLocationsAction => ({
    type: FUZZY_SEARCH_GMB_LOCATIONS,
    searchInput,
});

export const fuzzySearchGmbLocationsFailure = (
    error: Record<string, any>,
): FuzzySearchGmbLocationsFailureAction => ({
    type: FUZZY_SEARCH_GMB_LOCATIONS_FAILURE,
    error,
});

export const fuzzySearchGmbLocationsSuccess = (
    searchResultsById: Record<string, V2FormattedLocationData>,
    searchResultsIds: Array<string>,
): FuzzySearchGmbLocationsSuccessAction => ({
    type: FUZZY_SEARCH_GMB_LOCATIONS_SUCCESS,
    searchResultsById,
    searchResultsIds,
});

// INITIAL STATES
const initialState = {
    isSearchingOnServer: false,
    searchInput: '',
    searchErrors: {},
    searchResultsById: {},
    searchResultsIds: [],
    byId: {},
    ids: [],
    statusById: {},
    getRequests: {
        statuses: {},
        errors: {},
    },
};

// REDUCER
const gmbResourcesReducer = (
    state: GMBLocationsState = initialState,
    action: GmbResourceAction,
): GMBLocationsState => {
    switch (action.type) {
        case GET_GMB_LOCATION_REQUEST:
            return {
                ...state,
                getRequests: {
                    ...state.getRequests,
                    statuses: {
                        ...state.getRequests.statuses,
                        [action.locationId]: RUNNING,
                    },
                    errors: {
                        ...omit(state.getRequests.errors, [action.locationId]),
                    },
                },
            };

        case GET_GMB_LOCATION_REQUEST_SUCCESS:
            return {
                ...state,
                byId: { ...state.byId, [action.location.id]: action.location },
                ids: uniq([...state.ids, action.location.id]),
                getRequests: {
                    ...state.getRequests,
                    statuses: {
                        ...state.getRequests.statuses,
                        [action.location.id]: DONE,
                    },
                },
            };

        case GET_GMB_LOCATION_REQUEST_FAILURE:
            return {
                ...state,
                getRequests: {
                    ...state.getRequests,
                    statuses: {
                        ...state.getRequests.statuses,
                        [action.locationId]: FAILED,
                    },
                    errors: {
                        ...state.getRequests.errors,
                        [action.locationId]: action.error,
                    },
                },
            };

        case FUZZY_SEARCH_GMB_LOCATIONS:
            return {
                ...state,
                isSearchingOnServer: true,
                searchInput: action.searchInput,
                searchErrors: {},
                searchResultsIds: [],
                searchResultsById: {},
            };

        case FUZZY_SEARCH_GMB_LOCATIONS_SUCCESS:
            return {
                ...state,
                isSearchingOnServer: false,
                searchResultsIds: uniq(action.searchResultsIds),
                searchResultsById: action.searchResultsById,
            };

        case FUZZY_SEARCH_GMB_LOCATIONS_FAILURE:
            return {
                ...state,
                isSearchingOnServer: false,
                searchErrors: action.error,
            };

        default:
            return state;
    }
};

// SELECTORS
export const isSearchingOnServerSelector = (state: GMBLocationsState): boolean =>
    state.isSearchingOnServer;

export const allGmbLocationsSearchResultsSelector = (
    state: GMBLocationsState,
): Array<V2FormattedLocationData> => state.searchResultsIds.map(id => state.searchResultsById[id]);

export const gmbLocationSelector = (
    state: GMBLocationsState,
    locationId: string,
): V2FormattedLocationData | void => state.byId[locationId];

export const gmbLocationSearchResultsSelector = (
    state: GMBLocationsState,
    locationId: string,
): V2FormattedLocationData | void => state.searchResultsById[locationId];

export const gmbLocationGetRequestErrorSelector = (
    state: GMBLocationsState,
    locationId: string,
): Record<string, any> | null => state.getRequests.errors[locationId];

export default gmbResourcesReducer;
