import styled from '@emotion/styled';
import { TransparencyEnum, convertToRgba } from '@partoohub/branding';
import { Button, Text } from '@partoohub/ui';

export const DetailCardsContainer = styled.div`
    flex-shrink: 1;
    min-width: 300px;
    flex-grow: 1;
    flex-basis: 400px;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces[2]};
`;

export const DetailCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spaces[2]};
    border: 1px solid
        ${({ theme }) => convertToRgba(theme.colors.theme.textOnBackground, TransparencyEnum.ALPHA)};
    padding: ${({ theme }) => theme.spaces[2]};
    border-radius: ${({ theme }) => theme.radius.large};

    @media (max-width: 768px) {
        padding: ${({ theme }) => theme.spaces[3]};
        flex-direction: column;
        align-items: center;
        background: linear-gradient(
            108deg,
            #e6f1f7 0%,
            #f3f3fc 23.96%,
            #f6f4f1 50%,
            #f6f2ec 73.96%,
            #f6f8fc 100%
        );
        border: none;
    }
`;

interface DetailCardImageProps {
    src: string;
}

export const DetailCardImage = styled.div`
    flex-shrink: 1;
    flex-basis: 271px;
    max-width: 271px;
    min-width: 100px;
    height: 145px;
    border-radius: ${({ theme }) => theme.radius.large};
    background:
        url('${({ src }: DetailCardImageProps) => src}') center no-repeat,
        linear-gradient(
            108deg,
            #e6f1f7 0%,
            #f3f3fc 23.96%,
            #f6f4f1 50%,
            #f6f2ec 73.96%,
            #f6f8fc 100%
        );

    @media (max-width: 768px) {
        flex-basis: auto;
        width: 219px;
        background: url('${({ src }: DetailCardImageProps) => src}') center no-repeat;
    }
`;

export const DetailStack = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 200px;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces[2]};
    margin: auto 0;

    @media (max-width: 768px) {
        flex-basis: auto;
        align-items: center;
        width: 100%;
    }
`;

export const CopyCartouche = styled.div`
    min-width: 0;
    border: 1px solid
        ${({ theme }) => convertToRgba(theme.colors.theme.textOnBackground, TransparencyEnum.ALPHA)};
    background-color: ${({ theme }) => theme.colors.secondary.alpha};
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    padding: ${({ theme }) => theme.spaces[1]} ${({ theme }) => theme.spaces[2]};
    border-radius: ${({ theme }) => theme.radius.large};
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const CartoucheText = styled(Text)`
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
`;

export const DetailButton = styled(Button)`
    min-width: 0;
    border: 1px solid
        ${({ theme }) => convertToRgba(theme.colors.theme.textOnBackground, TransparencyEnum.ALPHA)};
`;

export const BodyContainer = styled.div`
    display: flex;
    gap: 40px;
`;

export const BannerContainer = styled.div`
    display: flex;
    gap: 19px;
    flex-direction: column;
`;
