import { FC } from 'react';

import { ProductIcon, ProductIconsAppEnum } from '@partoohub/iconography';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { useIsMobile } from 'app/common/services/screenDimensions';

import { LOG_OUT_PATH } from 'app/routing/routeIds';

import {
    ProductButtonContainer,
    ProductButtonHover,
    ProductButtonTooltip,
} from './ProductSidebar/ProductButton.styled';

export const LogoutButton: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const content = (
        <ProductButtonContainer
            data-track="sidemenu_settings__logout"
            data-cy="product-sidebar-product-button"
            data-intercom-target="sidemenu_settings__logout"
            onClick={() => navigate(LOG_OUT_PATH)}
        >
            <ProductButtonHover>
                <ProductIcon name={ProductIconsAppEnum.Logout} color="white" />
            </ProductButtonHover>
        </ProductButtonContainer>
    );

    if (!isMobile) {
        return (
            <TooltipWrapper tooltip={ProductButtonTooltip} text={t('logout')} position="right">
                {content}
            </TooltipWrapper>
        );
    }

    return content;
};
