import { ReactNode } from 'react';

import { Text } from '@partoohub/ui';

import {
    MobilePageSubHeaderActions,
    MobilePageSubHeaderContainer,
} from './MobilePageSubHeader.styled';

interface Props {
    title: string;
    subtitle?: string;
    actions?: Array<ReactNode> | null;
}

const MobilePageSubHeader = ({ title, subtitle, actions }: Props) => {
    return (
        <MobilePageSubHeaderContainer>
            <div>
                <Text variant="heading2">{title}</Text>
                {subtitle && (
                    <>
                        <Text variant="bodyMBold" color="primary">
                            {subtitle}
                        </Text>
                    </>
                )}
            </div>
            <MobilePageSubHeaderActions>
                {actions && actions.map(a => a)}
            </MobilePageSubHeaderActions>
        </MobilePageSubHeaderContainer>
    );
};

export default MobilePageSubHeader;
