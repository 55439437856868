import { useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ADMIN, ORG_ADMIN, PROVIDER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useSsoInfo from 'app/common/hooks/queries/useSsoInfo';

import useMeUpdate from './useMeUpdate';

/**
 * Handles all the logic for the Profile settings page
 * So it is reusable for Settings V2
 */
export default function useProfileSettingsPageContent() {
    const { t } = useTranslation();
    const { data: me, isLoading } = useMe();

    const isSsoAdmin = [ADMIN, PROVIDER, ORG_ADMIN].includes(me?.role ?? '');

    const { data } = useSsoInfo(me?.org_id ?? 0, {
        enabled: me && isSsoAdmin,
    });
    const orgHasSso = !!data?.sso_type;

    const setFormValue = () => ({
        firstName: me?.first_name || '',
        lastName: me?.last_name || '',
        email: me?.email || '',
        lang: {
            label: t(`original-${me?.lang}`),
            value: me?.lang ?? '',
        },
        ssoOnly: me?.sso_only || false,

        updatePassword: false,
        oldPassword: '',
        password: '',
    });

    const form = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    const { handleSubmit, watch, reset } = form;

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const ssoOnly = watch('ssoOnly');

    const meUpdate = useMeUpdate(() => {
        reset({
            ...watch(),
            updatePassword: false,
            oldPassword: '',
            password: '',
        });
    });

    // @ts-ignore
    const errors = meUpdate.error?.response?.data?.errors?.json;

    const formatPayload = payload => ({
        last_name: payload.lastName,
        first_name: payload.firstName,
        email: payload.email,
        lang: payload.lang.value,

        ...(orgHasSso && isSsoAdmin && { sso_only: payload.ssoOnly }),

        ...(!payload.ssoOnly &&
            payload.updatePassword &&
            !!(payload.oldPassword || payload.password) && {
                old_password: payload.oldPassword,
                password: payload.password,
            }),
    });

    const onSubmit = payload => {
        meUpdate.mutate(payload);
    };

    const submitForm = () => {
        handleSubmit(payload => onSubmit(formatPayload(payload)))();
    };

    return {
        form,
        meUpdate,
        errors,
        submitForm,
        orgHasSso,
        ssoOnly,
        isSsoAdmin,
    };
}
