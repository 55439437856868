import React, { PureComponent } from 'react';

import { Choice } from 'app/api/types/user';
import Clickable from 'app/common/components/buttons/Clickable';
import Menu from 'app/common/designSystem/components/atoms/CommonSelectMenu/CommonSelectMenu';
import explanationHOC from 'app/common/designSystem/components/atoms/ExplanationHOC';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

type Props = {
    placeholder: string;
    onChange: (arg0: Array<Choice>) => void;
    options: Array<Choice>;
    selectedOptions: Array<Choice>;
    // Optional props
    suggestedOptions?: Array<Choice>;
    suggestedOptionsHeader?: string;
    explanation?: string;
    disabled?: boolean;
    textTooltip?: string;
    // text of the tooltip
    hasError?: boolean;
    selectedOptionsLimit?: number; // limit length of selected options
};

type State = {
    wrapperRef: HTMLElement | null;
    isMenuShown: boolean;
};

class MultipleSelect extends PureComponent<Props, State> {
    static defaultProps = {
        explanation: undefined,
        disabled: false,
        textTooltip: undefined,
        hasError: false,
        selectedOptionsLimit: undefined,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            wrapperRef: null,
            isMenuShown: false,
        };
    }

    handleClickOutside = (event: Event) => {
        const { isMenuShown, wrapperRef } = this.state;

        if (
            isMenuShown &&
            wrapperRef &&
            event.target instanceof Node &&
            !wrapperRef.contains(event.target)
        ) {
            this.toggleMenu();
        }
    };

    toggleMenu = () => {
        const { isMenuShown: isOldMenuShown } = this.state;

        if (isOldMenuShown) {
            document.removeEventListener('mousedown', this.handleClickOutside);
        } else {
            document.addEventListener('mousedown', this.handleClickOutside);
        }

        this.setState(({ isMenuShown }) => ({
            isMenuShown: !isMenuShown,
        }));
    };

    setWrapperRef = (ref: HTMLElement | null) => {
        this.setState({
            wrapperRef: ref,
        });
    };

    reset = () => {
        const { onChange } = this.props;
        onChange([]);
    };

    onItemClick = (item: Choice, e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        const { onChange, selectedOptions, selectedOptionsLimit } = this.props;
        const limitExceeded = selectedOptionsLimit
            ? selectedOptions.length >= selectedOptionsLimit
            : false;
        const filtered = item
            ? selectedOptions.filter(option => option.value !== item.value)
            : selectedOptions;

        if (filtered.length === selectedOptions.length && !limitExceeded) {
            onChange([...selectedOptions, item]);
        } else {
            onChange(filtered);
        }
    };

    render() {
        const {
            placeholder,
            options,
            selectedOptions,
            disabled,
            textTooltip,
            hasError,
            suggestedOptions,
            suggestedOptionsHeader,
        } = this.props;
        const { isMenuShown } = this.state;
        let modifier = '';

        if (isMenuShown && hasError) {
            modifier = '--focused_error';
        } else if (hasError) {
            modifier = '--error';
        } else if (isMenuShown) {
            modifier = '--focused';
        } else {
            modifier = '--unfocused';
        }

        const component = (
            <div
                className={`
                    multiple_select
                    ${disabled ? 'multiple_select--disabled' : ''}
                `}
                ref={this.setWrapperRef}
            >
                <Clickable
                    className={`
                        multiple_select__field
                        multiple_select__field${modifier}
                        ${disabled ? 'multiple_select__field--disabled' : ''}
                    `}
                    disabled={disabled}
                    onClick={this.toggleMenu}
                >
                    <div
                        className={`
                            multiple_select__placeholder
                            ${
                                isMenuShown
                                    ? 'multiple_select__placeholder--focused'
                                    : ''
                            }
                            ${
                                hasError
                                    ? 'multiple_select__placeholder--error'
                                    : ''
                            }
                            ${
                                selectedOptions.length > 0 || isMenuShown
                                    ? 'multiple_select__placeholder--top'
                                    : ''
                            }
                        `}
                    >
                        <div>{placeholder}</div>
                    </div>
                    <div className="multiple_select__field_items">
                        {selectedOptions.map(option => (
                            <div
                                className="multiple_select__field_items_item font-small-bold"
                                key={option.value}
                            >
                                {option.icon && (
                                    <i
                                        className={`${option.icon}  margin_right--simple`}
                                    />
                                )}
                                {option.label}
                                <button
                                    type="button"
                                    className="multiple_select__field_items_item_delete"
                                    // @ts-ignore
                                    onClick={e => this.onItemClick(option, e)}
                                >
                                    <i className="fa-solid fa-times-circle" />
                                </button>
                            </div>
                        ))}
                    </div>
                    {selectedOptions.length > 0 ? (
                        <button
                            type="button"
                            className="multiple_select__field__icon_button"
                            onClick={this.reset}
                        >
                            <i
                                className={`fas fa-times ${
                                    hasError
                                        ? 'multiple_select__field__icon_button--error'
                                        : ''
                                }`}
                            />
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="multiple_select__field__icon_button"
                        >
                            <i
                                className={`fas fa-caret-down ${
                                    hasError
                                        ? 'multiple_select__field__icon_button--error'
                                        : ''
                                }`}
                            />
                        </button>
                    )}
                </Clickable>
                {isMenuShown && (
                    <Menu
                        options={options}
                        selectedOptions={selectedOptions}
                        onClick={this.onItemClick}
                        closeMenu={this.toggleMenu}
                        suggestedOptions={suggestedOptions}
                        suggestedOptionsHeader={suggestedOptionsHeader}
                    />
                )}
            </div>
        );
        return (
            <div className="flex--fill flex--col">
                {textTooltip ? (
                    <TooltipWrapper text={textTooltip} position="bottom-start">
                        {component}
                    </TooltipWrapper>
                ) : (
                    component
                )}
            </div>
        );
    }
}

export default explanationHOC(MultipleSelect);
