import styled from '@emotion/styled';

export const PromptContainer = styled.div`
    padding: 8px;
    max-width: 500px;
    text-align: center;

    & > .title {
        font-size: 28px;
        font-weight: bold;
        margin: 10px 32px; // In total, with parent padding this comes to 40px on sides.
    }

    & > .description {
        color: ${({ theme }) => theme.colors.secondary.initial};
        margin: 0 32px;
    }
`;

export const BannerImage = styled.img`
    width: 320px;
    height: 140px;
    margin: 16px auto 6px auto; // Top margin from parent will be 24px, bottom margin to text is 16px.
`;

export const CancelButtonContainer = styled.div`
    margin-top: 28px;
    margin-bottom: 12px;
`;
