import styled from '@emotion/styled';

export const PasswordFieldsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 18px;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;
