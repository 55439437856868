import WHITELIST_PROVIDERS from 'app/common/data/providers';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';
import { ENTERPRISE } from 'app/common/reducers/orgs';

export const useHasAssignConversationFeature = () => {
    const { data: me } = useMe();

    const orgId = me?.org_id;
    const { data: org, isLoading } = useOrganization(orgId ?? 0, !!orgId);

    if (!org) return { hasAssignConversationFeature: false, isLoading };

    const { organization_type: orgType, provider } = org;
    const providerIsWhitelisted = WHITELIST_PROVIDERS.includes(provider);

    return {
        hasAssignConversationFeature: orgType === ENTERPRISE && providerIsWhitelisted,
        isLoading,
    };
};
