import React from 'react';

import { DayString } from 'app/api/types';
import { OpenHoursData } from 'app/api/types/business';
import { DAYS } from 'app/businessEditV2/oldFromEditV1/data/constants';
import { findFirstString } from 'app/businessEditV2/utils/utils';
import OpenHourDay from 'app/common/components/OpenHour/OpenHourDay';

type Props = {
    value: OpenHoursData;
    onChange: (value: OpenHoursData) => void;
    errors: Record<string, any>;
    disabled: boolean;
    timeSlotReference?: Array<string>;
    openTimeSlotReferenceModal: () => void;
};

const OpenHours = ({
    value,
    onChange,
    errors,
    disabled,
    timeSlotReference,
    openTimeSlotReferenceModal,
}: Props) => {
    const onOpenHoursDayChange = (day: DayString, dayValue: Array<string>) => {
        const newOpenHoursValue = {
            ...value,
            [day]: dayValue,
        };
        onChange(newOpenHoursValue);
    };

    const applyDefaultHours = (
        day: DayString,
        timeSlotReference: Array<string>,
    ) => {
        if (!timeSlotReference || !openTimeSlotReferenceModal) {
            return;
        }

        if (timeSlotReference?.[0]?.length) {
            onOpenHoursDayChange(day, timeSlotReference ?? []);
        } else {
            openTimeSlotReferenceModal();
        }
    };

    return (
        <>
            {DAYS.map((day: DayString) => (
                <OpenHourDay
                    key={`open_hours_${day}`}
                    disabled={disabled}
                    day={day}
                    dayValue={value?.[day] ?? []}
                    onChange={dayValue => onOpenHoursDayChange(day, dayValue)}
                    applyDefaultHours={() =>
                        applyDefaultHours(day, timeSlotReference ?? [])
                    }
                    hoursError={findFirstString(errors?.[day]) ?? ''}
                />
            ))}
        </>
    );
};

export default OpenHours;
