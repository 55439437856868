import { useContext } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { useLocation, useNavigate } from 'react-router-dom';

import FunnelModal from 'app/common/components/FunnelModal';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { useAppDataContext } from 'app/common/contexts/appData';
import IS_IFRAME from 'app/common/data/iframe';

import { RouterContext } from 'app/routing/context/RouterContext';
import { NOT_FOUND_PATH, VISIBILITY_BULK_UPDATE_PATH, VISIBILITY_PATH } from 'app/routing/routeIds';

import { BulkUpdate } from './BulkUpdate';

export const BulkUpdateModal = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { routes } = useContext(RouterContext);
    const { me, organization } = useAppDataContext();
    const isAllowedToUseFeature =
        me?.can_use_bulk_modification && (organization?.has_bulk_modification ?? false);

    const displayBulkUpdate = pathname.startsWith(VISIBILITY_BULK_UPDATE_PATH);

    const getFirstValidPath = () => {
        const validPath = routes.find(
            route => !route.startsWith(VISIBILITY_BULK_UPDATE_PATH) && route !== NOT_FOUND_PATH,
        );
        return validPath ?? VISIBILITY_PATH;
    };

    const handleRedirect = () => {
        navigate(getFirstValidPath());
    };

    if (!isAllowedToUseFeature) {
        const illustrationElement: IllustrationLaptopElement = {
            type: IllustrationCategoryEnum.Laptop,
            name: IllustrationLaptopEnum.Sad,
        };

        return (
            <NotFoundTemplate
                show
                imgSrc={illustrationElement}
                title="no_access_title_bulk_modification"
                subtitle="no_access_subtitle_bulk_modification"
                withButton={false}
            />
        );
    }

    return (
        <FunnelModal
            isOpen={displayBulkUpdate}
            onClose={IS_IFRAME ? () => undefined : handleRedirect}
        >
            <BulkUpdate />
        </FunnelModal>
    );
};
