import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledRecommendations = styled.span<{
    recommendation: 'positive' | 'negative';
}>`
    flex: 1;
    .rating_logo {
        margin-bottom: 3px;
    }

    ${({ recommendation, theme }) =>
        recommendation === 'positive'
            ? css`
                  color: ${theme.colors.success.initial};
              `
            : css`
                  color: ${theme.colors.danger.initial};
              `}
`;
