import { useMemo } from 'react';

import { Option, SingleSelect } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useGetKeywordsCompetitive } from '../../hooks/useGetKeywordsCompetitive';
import { useKeywordFilter } from '../../hooks/useKeywordFilter';

export const KeywordDropDownSelection = () => {
    const { t } = useTranslation();
    const [keyword, setKeyword] = useKeywordFilter();
    const { availableKeywords: availableKeywords } = useGetKeywordsCompetitive();

    const buildSelectOption = (keyword: string): Option => ({
        value: keyword,
        name: keyword,
        label: keyword,
    });

    const sections = useMemo(() => {
        return [
            {
                options: availableKeywords.map(keyword => buildSelectOption(keyword.keyword)),
            },
        ];
    }, [availableKeywords]);

    const onChangeKeyword = (option?: Option) => {
        setKeyword(option?.value ?? '');
    };

    const selectedKeyword = useMemo(() => {
        if (!keyword) {
            return sections[0]?.options[0];
        }
        return buildSelectOption(keyword.keyword);
    }, [keyword]);

    return (
        <SingleSelect
            label={t('keyword_filter_label')}
            maxHeight={150}
            dataTrackId="page_competitive_benchmark__select_keywords"
            onChange={onChangeKeyword}
            disableReset
            selectedValue={selectedKeyword}
            sections={sections}
            readOnly
        />
    );
};
