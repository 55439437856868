import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import {
    Banner,
    Button,
    Drawer,
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    Text,
} from '@partoohub/ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BusinessLocation, BusinessLocationAddress } from 'app/api/types/business';
import ControlledAddressFullInput from 'app/businessEditV2/hookControllerWrapper/ControlledAddressFullInput';
import ControlledAsyncSingleSelect from 'app/businessEditV2/hookControllerWrapper/ControlledAsyncSingleSelect';
import { ControlledCityField } from 'app/businessEditV2/hookControllerWrapper/ControlledCityField';
import { ControlledTextInput } from 'app/businessEditV2/hookControllerWrapper/ControlledTextInput';
import useCountry from 'app/businessEditV2/hooks/business/useCountry';

import { FloatingFooter } from 'app/settingsManagement/components/GoogleVerification/Fields/FloatingFooter.styled';

import { AddressContainer, AddressGrid, BannerContainer } from './AddressDrawer.styled';

interface Props {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    businessLocation: BusinessLocation;
    setBusinessLocation: Dispatch<SetStateAction<BusinessLocation>>;
}

const AddressDrawer: FC<Props> = ({ isOpen, setIsOpen, businessLocation, setBusinessLocation }) => {
    const { t } = useTranslation();

    const { data: countryData } = useCountry();
    const [formErrors, setFormErrors] = useState({
        hasAddressFullError: false,
        hasCityError: false,
        hasCountryError: false,
    });
    const { hasAddressFullError, hasCityError, hasCountryError } = formErrors;

    const getDefaultFormValues = () => ({
        addressFull: businessLocation.address?.address_full ?? '',
        zipcode: businessLocation.address?.zipcode ?? '',
        city: {
            label: businessLocation.address?.city ?? '',
            value: businessLocation.address?.city ?? '',
        },
        country: { label: '', value: '' },
    });

    useEffect(() => {
        if (countryData) {
            setValue(
                'country',
                countryData.find(
                    country => country.value === businessLocation.address?.country,
                ) ?? { label: '', value: '' },
                { shouldDirty: true },
            );
        }
    }, [countryData]);

    const form = useForm({
        defaultValues: useMemo(() => getDefaultFormValues(), []),
    });

    const { control, handleSubmit, watch, setValue } = form;

    const formatAddressPayload = payload => ({
        id: businessLocation.id,
        address: {
            address_full: payload?.addressFull ?? '',
            address2: payload?.address2 ?? '',
            zipcode: payload?.zipcode ?? '',
            city: payload?.city?.value ?? '',
            country: payload?.country?.value ?? '',
        },
    });

    const checkAddressErrors = (address: BusinessLocationAddress) => {
        return {
            hasAddressFullError: !address.address_full,
            hasCityError: !address.city,
            hasCountryError:
                !address.country ||
                !countryData?.some(country => country.value === address.country),
        };
    };

    const submitForm = () => {
        const addressPayload = formatAddressPayload({ ...watch() });
        const addressErrors = checkAddressErrors(addressPayload.address);
        setFormErrors(addressErrors);

        if (!Object.values(addressErrors).some(error => error)) {
            handleSubmit(() => setBusinessLocation(addressPayload))();
            setIsOpen(false);
        }
    };

    const addressFull = watch('addressFull');
    const city = watch('city');
    const country = watch('country')?.value;

    useEffect(() => {
        if (addressFull || city || country) {
            setFormErrors({
                hasAddressFullError: false,
                hasCityError: false,
                hasCountryError: false,
            });
        }
    }, [addressFull, city, country]);

    return (
        <Drawer
            dataTrackId="address_drawer_onboarding"
            onHide={() => setIsOpen(false)}
            isOpen={isOpen}
            width="500px"
        >
            <Text variant="heading5">{t('onboarding_address_drawer_title')}</Text>
            <BannerContainer>
                <Banner
                    dataTrackId="address_drawer_explanation_onboarding"
                    icon={[FontAwesomeIconsPartooUsed.faCircleInfo, IconPrefix.SOLID]}
                    withCloseIcon={false}
                >
                    {t('onboarding_address_drawer_explanation')}
                </Banner>
            </BannerContainer>

            <AddressContainer>
                <ControlledAddressFullInput
                    dataTrackId="addressFull"
                    name="addressFull"
                    control={control}
                    setValue={setValue}
                    hasError={hasAddressFullError}
                    errorMessage={hasAddressFullError ? t('cannot be empty') : ''}
                    isMandatory
                />
                <ControlledTextInput
                    name="address2"
                    control={control}
                    label={t('business_address_complement')}
                    dataTrackId="address2"
                />
                <AddressGrid>
                    <ControlledTextInput
                        dataTrackId="zipcode"
                        name="zipcode"
                        control={control}
                        label={t('zipcode')}
                    />
                    <ControlledCityField
                        name="city"
                        control={control}
                        renderSelect={country === 'FR'}
                        hasWarning={false}
                        hasError={hasCityError}
                        errorMessage={hasCityError ? t('cannot be empty') : ''}
                        isMandatory
                    />
                </AddressGrid>
                <ControlledAsyncSingleSelect
                    name="country"
                    control={control}
                    placeholder={t('country')}
                    options={countryData || []}
                    hasError={hasCountryError}
                    hideCross
                    isMandatory
                />
            </AddressContainer>

            <FloatingFooter>
                <Button
                    dataTrackId="save_address_onboarding"
                    size="large"
                    shape="cube"
                    variant="primary"
                    onClick={submitForm}
                    full
                >
                    {t('save')}
                </Button>
            </FloatingFooter>
        </Drawer>
    );
};

export default AddressDrawer;
