import { Stack } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import BusinessOpeningSoon from 'app/businessEditV2/components/BusinessEditV2Header/BusinessOpeningSoon';
import CTAButtons from 'app/businessEditV2/components/BusinessEditV2Header/CTAButtons';
import PageHeaderBusinessClosed from 'app/businessEditV2/components/BusinessEditV2Header/PageHeaderBusinessClosed';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useBusinessStatus } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import { isBusinessOpeningSoon } from 'app/businessEditV2/utils/businessOpeningSoon';
import { OPEN } from 'app/common/data/businessStatuses';
import IS_IFRAME from 'app/common/data/iframe';

import { DiffusionListButton } from 'app/pages/visibility/BusinessListV2/sections/BusinessListHeader/HeaderButtons/Buttons/DiffusionListButton';
import { MetaRelogin } from 'app/pages/visibility/subComponents/MetaRelogin/MetaRelogin';
import { PageHeaderWithAvatar } from 'app/routing/components/PageHeaderWithAvatar';

export const BusinessEditV2PageHeader = () => {
    const { t } = useTranslation();

    const { data: business, isLoading } = useBusiness();
    const isOpen = useBusinessStatus() === OPEN;
    const isOpeningSoon = isBusinessOpeningSoon(business);

    const buttons =
        !isLoading &&
        (isOpen ? (
            <Stack
                gap="8px"
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                key="cta_buttons"
            >
                <CTAButtons />
                {!IS_IFRAME && <DiffusionListButton />}
            </Stack>
        ) : (
            <PageHeaderBusinessClosed key="business_closed" />
        ));
    return (
        <PageHeaderWithAvatar
            title={t('business_edit_my_business')}
            actions={[
                buttons,
                <MetaRelogin key="meta_relogin" />,
                isOpeningSoon && <BusinessOpeningSoon />,
            ]}
        />
    );
};
