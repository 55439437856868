import React, { useContext } from 'react';

import { Banner, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { V2FormattedLocationData } from 'app/api/types/account';
import FunnelContext from 'app/common/components/funnel/FunnelContext';
import { STATIC_BASE_URL } from 'app/config';
import {
    ContinueButton,
    Description,
    InformationsContainer,
    OverrideFullButtonWrapper,
    SkipButton,
    SkipText,
    StartAddAddressContainer,
    Title,
} from 'app/onboarding/StartAddAddressPage/StartAddAddressPage.styled';

const StartAddAddressPage = () => {
    const { t } = useTranslation();
    const funnel = useContext(FunnelContext);
    const { selectedLocations = [] } = funnel.store;
    const locationsWithoutAddress = selectedLocations.filter(
        (location: V2FormattedLocationData) => !location.addressDetails,
    );

    const onSkipClick = () => {
        funnel.store = {
            ...funnel.store,
            editedLocations: [],
        };
        funnel.navigate('summary_page');
    };

    return (
        <StartAddAddressContainer>
            <img
                src={`${STATIC_BASE_URL}/images/app/funnel/address-clouds.svg`}
                alt="add-addresses"
            />

            <InformationsContainer>
                <Title variant="heading3">
                    {t('onboarding_start_add_address_page_title', {
                        count: locationsWithoutAddress.length,
                    })}
                </Title>
                <Description variant="bodyLRegular" color={'secondary'}>
                    {t('onboarding_start_add_address_page_description_first')}
                </Description>
                <Description variant="bodyLRegular" color={'secondary'}>
                    {t('onboarding_start_add_address_page_description_second')}
                </Description>

                <Banner
                    dataTrackId={'onboarding_informations_banner'}
                    key="info"
                    variant="info"
                    withCloseIcon={false}
                >
                    {t('onboarding_start_add_address_page_banner_information')}
                </Banner>
            </InformationsContainer>

            <OverrideFullButtonWrapper>
                <ContinueButton
                    dataTrackId="continue_from_add_addresses_onboarding"
                    onClick={() => funnel.navigate('add_addresses_page')}
                    icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                    shape="cube"
                    variant="primary"
                    size="large"
                >
                    {t('onboarding_start_add_address_page_add_addresses_button')}
                </ContinueButton>
                {selectedLocations.length > locationsWithoutAddress.length && (
                    <SkipButton
                        dataTrackId="skip_from_add_addresses_onboarding"
                        variant="secondary"
                        appearance="outlined"
                        shape="cube"
                        size="large"
                        onClick={onSkipClick}
                    >
                        <SkipText variant="bodyMBold" oneLine noTooltip>
                            {t('skip')}
                        </SkipText>
                    </SkipButton>
                )}
            </OverrideFullButtonWrapper>
        </StartAddAddressContainer>
    );
};

export default StartAddAddressPage;
