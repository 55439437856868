import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import { Notifications } from 'app/settingsManagement/components/Notifications/Notifications';

export const NotificationsPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('notifications')} />
            <Notifications />
        </>
    );
};
