import React, { useEffect, useMemo, useState } from 'react';

import { Stack } from '@partoohub/ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditForm from 'app/businessEditV2/components/EditForm';

import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import ControlledOpeningDate from 'app/businessEditV2/hookControllerWrapper/ControlledOpeningDate';
import { ControlledTextInput } from 'app/businessEditV2/hookControllerWrapper/ControlledTextInput';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import useAdvancedSettingsSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useAdvancedSettingsSectionColor';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useFormFieldFormatter from 'app/businessEditV2/hooks/publisherErrors/useFormFieldFormatter';

import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';

import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

import { AdvancedSettingsTitle } from './AdvancedSettingsForm.styled';
import Status from './Status';

type Props = {
    closeModal: () => void;
    useDirtyUpdate: (bool: boolean) => void;
};

export type FormValues = {
    code?: string;
    national_identification_number?: string;
    opening_date?: string;
    status?: string;
};

const AdvancedSettingsForm = ({ closeModal, useDirtyUpdate }: Props) => {
    const { t } = useTranslation();
    const colorState = useAdvancedSettingsSectionColor();

    // Business hooks
    const { data: business, isLoading: businessFetchIsLoading } = useBusiness();
    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const businessUpdate = useBusinessUpdate(() => {
        businessUpdateCompletionRate.mutate();
        closeModal();
    });

    // Business status
    const businessIsOpen = !useIsBusinessClosed();

    // Permission hooks
    const codePermission = useFieldMetadata('advanced_settings', 'code')?.enabled && businessIsOpen;
    const siretPermission =
        useFieldMetadata('advanced_settings', 'national_identification_number')?.enabled &&
        businessIsOpen;
    const openingDatePermission =
        useFieldMetadata('advanced_settings', 'status')?.enabled && businessIsOpen;

    // Form
    const setFormValue = () => ({
        code: business?.code ?? '',
        national_identification_number: business?.national_identification_number ?? '',
        opening_date: business?.opening_date ?? '',
    });
    const {
        control,
        watch,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm<FormValues>({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    useDirtyUpdate(isDirty);

    useEffect(() => {
        if (!businessFetchIsLoading) {
            reset(setFormValue());
        }
    }, [businessFetchIsLoading]);

    // Form submission
    const onSubmit = (data: FormValues) => {
        businessUpdate.mutate(data);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_ADVANCED_SETTINGS_UPDATED_EVENT, {
            ...data,
            businessId: business?.id,
        });
    };
    const formatPayload = (data: FormValues) => ({
        ...(codePermission && { code: data?.code }),
        ...(siretPermission && {
            national_identification_number: data.national_identification_number,
        }),
        ...(openingDatePermission && { opening_date: data.opening_date }),
    });

    const submitForm = () => {
        handleSubmit((data: FormValues) => onSubmit(formatPayload(data)))();
        trackSaveBusinessEditSection(SectionNames.ADVANCED_SETTINGS, colorState);
    };

    // Error handling
    const errors = businessUpdate.error?.response?.data?.errors?.json;

    // Form field formatting
    const codeFormatting = useFormFieldFormatter(
        'advanced_settings',
        'code',
        watch,
        formatPayload,
        // Error response for the code field seems a bit weird, hence the "||"
        errors || businessUpdate.error?.response?.data?.errors,
    );
    const siretFormatting = useFormFieldFormatter(
        'advanced_settings',
        'national_identification_number',
        watch,
        formatPayload,
        errors,
    );

    const openingDateFormatting = useFormFieldFormatter(
        'advanced_settings',
        'opening_date',
        watch,
        formatPayload,
        errors,
    );

    // Confirmation modals
    const [displayCodeWarningModal, setDisplayCodeWarningModal] = useState<boolean>(false);
    const [codeWarningModalHasBeenViewed, setCodeWarningModalHasBeenViewed] =
        useState<boolean>(false);

    return (
        <>
            <EditForm
                title={t('advanced_settings')}
                colorState={colorState}
                description={t('business_edit_content_advanced_settings_fill_opening_date')}
            >
                <ControlledOpeningDate
                    name="opening_date"
                    control={control}
                    disabled={!openingDatePermission}
                    errorMessage={openingDateFormatting.errorMessage}
                />

                <Status onSubmit={onSubmit} />

                <div>
                    <AdvancedSettingsTitle variant="heading6">
                        {t('business_edit_content_advanced_settings_fill_siret')}
                    </AdvancedSettingsTitle>
                    <Stack>
                        <ControlledTextInput
                            name="code"
                            control={control}
                            label={t('store_code')}
                            dataTrackId="visibility_location__form_advanced_settings__code"
                            onFocus={() => setDisplayCodeWarningModal(true)}
                            {...codeFormatting}
                        />
                        <ControlledTextInput
                            name="national_identification_number"
                            control={control}
                            label={t('national_identification_number')}
                            dataTrackId="visibility_location__form_advanced_settings__siret"
                            {...siretFormatting}
                        />
                    </Stack>
                </div>
                {/* Information modal for "Store Code" */}
                <ConfirmModal
                    title={t('edit_store_code')}
                    content={t('edit_store_code_content')}
                    show={!codeWarningModalHasBeenViewed && displayCodeWarningModal}
                    modalFor="warning"
                    trackId="visibility_location__form_advanced_settings__code_warning_modal"
                    onConfirm={() => setCodeWarningModalHasBeenViewed(true)}
                    onHide={() => setCodeWarningModalHasBeenViewed(true)}
                    confirmLabel={t('Ok')}
                />
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={businessUpdate.isLoading}
                    hasError={!!businessUpdate.error}
                    onClick={submitForm}
                    dataTrackId="visibility_location__form_advanced_settings__save_button"
                />
            </EditFormFooter>
        </>
    );
};
export default AdvancedSettingsForm;
