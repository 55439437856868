import React, { MouseEvent } from 'react';

import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UseInfiniteQueryResult } from 'react-query/types/react/types';

import { V2GroupData, V2PaginatedGroups } from 'app/api/types/groups';
import { Menu } from 'app/common/designSystem/components/molecules/AsyncSubMenuMultipleSelect';

import { MenuItem, SectionContainer } from './Section.styled';

interface GroupsSectionProps {
    query: UseInfiniteQueryResult<V2PaginatedGroups>;
    items: Array<V2GroupData> | undefined;
    onClickItem: (
        event: MouseEvent<HTMLDivElement>,
        group: V2GroupData,
    ) => void;
}

const GroupsSection = ({ query, items, onClickItem }: GroupsSectionProps) => {
    const { t } = useTranslation();

    return (
        <SectionContainer>
            <Menu.Section title={t('business_drawer_groups')}>
                <InfiniteScroll
                    next={() => query.fetchNextPage()}
                    hasMore={!!query.hasNextPage}
                    loader={null}
                    dataLength={items?.length ?? 0}
                    scrollableTarget="scrollable-business-menu"
                >
                    {items?.map(group => (
                        <MenuItem
                            key={group.id}
                            onClick={event => onClickItem(event, group)}
                        >
                            {group.name}
                        </MenuItem>
                    ))}
                </InfiniteScroll>
            </Menu.Section>
        </SectionContainer>
    );
};

export default GroupsSection;
