import { List } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useChatbots } from 'app/pages/settings/Chatbot/hooks/useChatbots';
import { ChatbotCard } from 'app/pages/settingsV2/subPages/Messages/components/Chatbots/ChatbotCard';

import { ChatbotListContainer } from 'app/pages/settingsV2/subPages/Messages/components/Chatbots/ChatbotList.styled';

export const ChatbotList = () => {
    const { t } = useTranslation();
    const { data, isLoading } = useChatbots();
    const lastCardIndex = data ? data.length - 1 : -1;

    return (
        <ChatbotListContainer>
            <List
                columns={[
                    { label: t('title') },
                    { label: t('status') },
                    { label: t('chatbot_configuration_last_modification') },
                    { label: t('chatbot_configuration_created_at') },
                    { label: t('businesses') },
                ]}
                loading={isLoading}
            >
                {data?.map((chatbot, index) => (
                    <ChatbotCard
                        key={`chatbot-${chatbot.id}`}
                        chatbot={chatbot}
                        isLastCard={lastCardIndex === index}
                    />
                ))}
            </List>
        </ChatbotListContainer>
    );
};
