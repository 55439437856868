import React from 'react';

import { Modal } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    GroupsSettingsQueryKeys,
    SECTION,
} from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

import CreateGroupContent from './CreateGroupContent';

const CreateGroupModal = () => {
    const [create, setCreate] = useStateQueryParams(GroupsSettingsQueryKeys.CREATE, true);

    return (
        <Modal isOpen={!!create && create !== SECTION} closeModal={() => setCreate('')}>
            <CreateGroupContent closeModal={() => setCreate('')} />
        </Modal>
    );
};

export default CreateGroupModal;
