import styled from '@emotion/styled';

export const DoughnutStyled = styled.div`
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 50%;
    min-width: 290px;
    max-width: 100%;

    @media screen and (max-width: 768px) {
        min-width: 400px;
    }

    @media screen and (max-width: 550px) {
        min-width: 290px;
    }
`;

export const DoughnutWithOverlayStyled = styled.div`
    flex: 1 1 0;
    max-width: 50%;
    position: relative;
    height: 100%;
    min-height: 0;
    padding: 8px 0;
    min-width: 0;
    canvas {
        height: 155px; // if removed, mobile reloads have a 5px height difference
        max-width: 100%;
    }
`;

export const DoughnutLegendsStyled = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
`;
