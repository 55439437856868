import styled from '@emotion/styled';

export const ChartDoughnutLegends = styled.div`
    max-width: 100%;
    margin: auto;
    padding: 8px;
    align-content: center;
`;

export const ChartDoughnutOverlay = styled.div`
    flex: 1 1 0;
    max-width: 50%;
    position: relative;
    height: 100%;
    min-height: 0;
    padding: 8px 0;
    min-width: 0;

    canvas {
        height: 260px !important; // if removed, mobile reloads have a 5px height difference
        max-width: 100%;
    }
`;
