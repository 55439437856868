import { connect } from 'react-redux';

import LocationsListComponent from 'app/businessConnection/components/modals/ConnectionModal/SearchLocationsSection/FoundLocationsSection/FoundLocations/LocationsList/LocationsList';
import { connectionErrorSelector } from 'app/states/knowledge/reducers/businessConnection/connection';
import { AppState, connectionSelector } from 'app/states/reducers';

const mapStateToProps = (state: AppState) => {
    const connection = connectionSelector(state);

    return {
        error: connectionErrorSelector(connection),
    };
};

const LocationsList = connect(mapStateToProps)(LocationsListComponent);

export default LocationsList;
