import { FC } from 'react';

import { LeftElementType } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { StyledTextInput } from './ExpeditorNameFormControl.styled';

type Props = {
    value: string | undefined;
    onChange: (value: string) => void;
    hasError: boolean;
    disabled?: boolean;
    autoFocus: boolean;
    onBlur: () => void;
};

const EXPEDITOR_NAME_MAX_LENGTH = 11;

export const ExpeditorNameFormControl: FC<Props> = ({
    value,
    onChange,
    hasError,
    disabled = false,
    autoFocus,
    onBlur,
}) => {
    const { t } = useTranslation();

    return (
        <StyledTextInput
            dataTrackId="expeditor_name__text_input"
            value={value}
            onChange={onChange}
            label={t('rb_expeditor_name_input_placeholder')}
            leftElementType={LeftElementType.Icon}
            leftElement={['fa-align-left']}
            error={hasError}
            autoFocus={autoFocus}
            onBlur={onBlur}
            characterCount={EXPEDITOR_NAME_MAX_LENGTH}
            notice={hasError ? t('rb_expeditor_name_input_error') : ''}
            disabled={disabled}
        />
    );
};
