import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { CommentData, CommentParams } from 'app/api/types/comments';
import { ReviewObjectType } from 'app/api/types/review';
import API from 'app/api/v2/api_calls';

import dataLayer from 'app/common/utils/dataLayer';
import { useUpdateReviewQueryData } from 'app/reviewManagement/reviewList/hooks/queryData/useUpdateReviewQueryData';
import { useShowReviewErrorToast } from 'app/reviewManagement/reviewList/hooks/ReviewCard/useShowReviewErrorToast';
import { ReviewObject } from 'app/states/reviews';

import { NOT_TREATED_REVIEWS } from 'app/states/reviews/filters';

import { useNotifyOnComment } from './useNotifyOnComment';
import { CommentOptions } from '../CommentForm';
import { createReviewCommentData } from '../utils/createReviewCommentData';

export const useCreateComment = (
    review: ReviewObject,
    comment?: CommentData,
    parentId?: number,
) => {
    const dispatch = useDispatch();
    const notifyOnComment = useNotifyOnComment();
    const updateReviewQueryData = useUpdateReviewQueryData();
    const showReviewErrorToast = useShowReviewErrorToast();

    const createCommentForReviewMutation = useMutation(
        ({ content, commentOptions }: { content: string; commentOptions: CommentOptions }) => {
            const commentParams: CommentParams = {
                review_id: review.id,
                parent_id: parentId || undefined,
                content,
                ...commentOptions,
            };
            return API.comments.createComment(commentParams);
        },
        {
            onSuccess: (data, templateId) => {
                dataLayer.pushDict(templateId ? 'reply_review_with_template' : 'reply_to_review');
                const newReview = createReviewCommentData(review, data);

                if (review.state === NOT_TREATED_REVIEWS) {
                    updateReviewQueryData(newReview, {
                        markReviewAs: 'treated',
                    });
                } else {
                    updateReviewQueryData(newReview);
                }
                notifyOnComment(review, comment);
            },
            onError: (error: Record<string, any>) => {
                showReviewErrorToast(review, error, dispatch);
            },
        },
    );

    const createCommentForFeedbackResultMutation = useMutation(
        ({ content }: { content: string }) => {
            return API.feedbackResults.replyFeedbackResult(review.id, { content });
        },
        {
            onSuccess: data => {
                const newReview = createReviewCommentData(review, data);
                dataLayer.pushDict('reply_to_review');
                if (review.state === NOT_TREATED_REVIEWS) {
                    updateReviewQueryData(newReview, {
                        markReviewAs: 'treated',
                    });
                } else {
                    updateReviewQueryData(newReview);
                }
                notifyOnComment(review, comment);
            },
            onError: (error: Record<string, any>) => {
                showReviewErrorToast(review, error, dispatch);
            },
        },
    );

    return review.reviewObjectType === ReviewObjectType.REVIEW
        ? createCommentForReviewMutation
        : createCommentForFeedbackResultMutation;
};
