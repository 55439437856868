import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

export enum ReviewsQueryKeys {
    REVIEW_OBJECT = 'review-object',
    DATE_FROM = 'date-from',
    DATE_TO = 'date-to',
    SEARCH = 'keywords',
    RATING = 'rating',
    PLATFORMS = 'platforms',
    STATUS = 'status',
    COMMENTS = 'comments',
    TAG = 'tag',
}

export type ReviewFiltersKeys =
    | 'dateFrom'
    | 'dateTo'
    | 'search'
    | 'rating'
    | 'platforms'
    | 'status'
    | 'comments'
    | 'tags'
    | 'reviewObjectType';

export type ReviewFiltersType = Record<ReviewFiltersKeys, string>;

export const useGetReviewsFilters = (): ReviewFiltersType => {
    const [dateFrom] = useStateQueryParams(ReviewsQueryKeys.DATE_FROM);
    const [dateTo] = useStateQueryParams(ReviewsQueryKeys.DATE_TO);
    const [search] = useStateQueryParams(ReviewsQueryKeys.SEARCH);
    const [rating] = useStateQueryParams(ReviewsQueryKeys.RATING);
    const [platforms] = useStateQueryParams(ReviewsQueryKeys.PLATFORMS);
    const [status] = useStateQueryParams(ReviewsQueryKeys.STATUS);
    const [comments] = useStateQueryParams(ReviewsQueryKeys.COMMENTS);
    const [tags] = useStateQueryParams(ReviewsQueryKeys.TAG);
    const [reviewObjectType] = useStateQueryParams(ReviewsQueryKeys.REVIEW_OBJECT);

    return {
        reviewObjectType,
        dateFrom,
        dateTo,
        search,
        rating,
        platforms,
        status,
        comments,
        tags,
    };
};
