import styled from '@emotion/styled';

export const ProductSidebarContainer = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 100;
    position: relative;
    display: flex;
    background-color: var(--pto-primary);
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 64px;
`;

export const ProductSidebarHeaderTitle = styled.div`
    margin-top: -8px;

    color: white;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
`;
