import { Modal, Text, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { API_KEY_VALUE } from 'app/common/data/queryKeysConstants';

import { useApiKeyContext } from 'app/settingsManagement/components/ApiKey/ApiKey.context';

import { ApiKeyShowModalContainer, StyledButton, StyledTextInput } from './ApiKeyShowModal.styled';

const ApiKeyShowModal = () => {
    const { t } = useTranslation();
    const { keyValue, showApiKeyModal, setApiKeyShowModal } = useApiKeyContext();

    const { data: apiKeyValue, isLoading } = useQuery(
        [API_KEY_VALUE, keyValue],
        () => api.apiKeys.getApiKeyKeyValue(keyValue),
        { enabled: !!keyValue },
    );

    const onHideClick = () => {
        setApiKeyShowModal(false);
    };

    const copyKeyToClipboard = () => {
        navigator.clipboard
            .writeText(apiKeyValue.api_key)
            .then(() => toast.success(null, t('api_key_manager_displaykey_popin_copied')));
        onHideClick();
    };

    return (
        <Modal isOpen={showApiKeyModal} closeModal={onHideClick}>
            <ApiKeyShowModalContainer>
                <Text variant="heading4" as="h4">
                    {t('api_key_manager_displaykey_popin_title')}
                </Text>
                <Text variant="bodyMRegular" as="span" color="secondary">
                    {t('api_key_manager_displaykey_popin_message')}
                </Text>
                <StyledTextInput
                    dataTrackId="api_key_manager_displaykey_popin_fieldname"
                    value={apiKeyValue?.api_key}
                    loading={isLoading}
                    onChange={() => undefined}
                    placeholder={t('api_key_manager_displaykey_popin_fieldname')}
                />
                <StyledButton
                    dataTrackId="api-key-show-key-modal__button"
                    variant="primary"
                    onClick={copyKeyToClipboard}
                    isLoading={isLoading}
                    disabled={isLoading}
                    shape="cube"
                    size="large"
                >
                    {t('api_key_manager_displaykey_popin_button')}
                </StyledButton>
            </ApiKeyShowModalContainer>
        </Modal>
    );
};

export default ApiKeyShowModal;
