import styled from '@emotion/styled';
import { Text, TextInput } from '@partoohub/ui';

export const MyAccountTitle = styled(Text)`
    margin-bottom: 24px;
`;

export const FieldContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-bottom: 24px;
`;

export const RoleInput = styled(TextInput)`
    margin-bottom: 24px;
`;
