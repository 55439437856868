const WHITELIST_PROVIDERS = ['partoo', 'localoo', 'partoo_it', 'demo', 'partoo_tech_test'];

export const NPS_WHITELIST_PROVIDERS = [
    ...WHITELIST_PROVIDERS,
    'Fil',
    'Point Fort Fichet',
    'Five Pizza Original',
    'Aloy',
    'Tilli',
    'Renovation Man',
    'Apollo Sporting Club',
    'Atout France',
    'Bosch Car Service',
    'Carmila',
    'Nexus Automotive International',
    'L\'Oréal',
    'Cyclofix',
    'Cmonjardinier',
    'Kandbaz',
    'Gîtes de France de Bretagne',
    'husqvarna',
    'Husqvarna ES-PT',
    'husqvarna uk',
    'Husqvarna-RO',
    'Husqvarna Germany',
    'Fercad',
    'CC Lomagne Tarn et Garonnaise',
];

/*
 * This enum maps the provider names to their corresponding domains for the connection page
 * Use this enum for replacing the favicon and logo on the login page.
 */
export enum ProviderDomainMapping {
    Partoo = 'app.partoo.co',
    Localoo = 'app.localoo.co',
    Mediapost = 'app.mavisibiliteweb.fr',
}

export default WHITELIST_PROVIDERS;
