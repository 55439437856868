import React from 'react';

import { useTranslation } from 'react-i18next';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';

type Props = {
    colorState?: ColorState;
};

const DescriptionsSectionContent = ({ colorState = ColorState.OK }: Props) => {
    const { t } = useTranslation();
    const { data: business } = useBusiness();

    const descriptionsLength =
        (business?.description_short ? 1 : 0) +
        (business?.description_long ? 1 : 0);

    switch (colorState) {
        case ColorState.PRECHECK_ERRORS:
            return <>{t('business_edit_content_correct_this_field')}</>;
        case ColorState.MISSING_WARNING:
        case ColorState.CLOSED:
        case ColorState.DISABLED:
            return <>{t('business_edit_descriptions_disabled')}</>;
        case ColorState.OK:
            return (
                <>
                    <i className="fa-solid fa-align-left" />
                    <span>
                        {t('business_edit_descriptions_count', {
                            count: descriptionsLength,
                        })}
                    </span>
                </>
            );
        default:
            return null;
    }
};

export default DescriptionsSectionContent;
