import styled from '@emotion/styled';
import { IconButton } from '@partoohub/ui';

import { GetMaxWidthContentPage } from 'app/global.styled';

export const FunnelModalContentWrapper = styled.div`
    background: ${({ theme }) => theme.colors.theme.background};
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
    align-self: baseline;
    ${GetMaxWidthContentPage};
`;

export const FunnelModalRightButton = styled(IconButton)`
    position: fixed;
    top: 16px;
    left: 16px;
`;

export const FunnelModalLeftButton = styled(IconButton)`
    position: fixed;
    top: 16px;
    right: 16px;
`;

export const FunnelModalHeader = styled.div`
    position: sticky;
    top: -16px;
    padding: 16px 24px;
    animation: all 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors.theme.background};
    border-radius: 16px 16px 0 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.default.alpha};
    z-index: 2;
`;

export const FunnelModalFooter = styled.div`
    position: sticky;
    bottom: -16px;
    padding: 16px 24px;
    animation: all 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors.theme.background};
    border-radius: 0 0 16px 16px;
    border-top: 1px solid ${({ theme }) => theme.colors.default.alpha};
    z-index: 2;
`;

export const FunnelModalBody = styled.div`
    padding: 24px;
`;

export const FullScreen = styled.div`
    display: flex;
    justify-content: center;
    width: 100vw;
    max-height: 100vh;
    gap: 32px;
    background: ${({ theme }) => theme.colors.theme.backgroundTheme};
    padding: 16px 72px;
    overflow: auto;
`;
