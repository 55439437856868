import { useTranslation } from 'react-i18next';

import { useIsInvoicesPageEnabled } from 'app/pages/settingsV2/hooks/Integrations/pagesEnabled/useIsInvoicesPageEnabled';
import { SettingMenuItemsData } from 'app/pages/settingsV2/subComponents/SettingsMenuList/SettingsMenuList';
import {
    SETTINGS_MAIN_INVOICES_PATHNAME,
    SETTINGS_MAIN_NOTIFICATIONS_PATHNAME,
    SETTINGS_MAIN_PATHNAME,
    SETTINGS_MAIN_PROFILE_PATHNAME,
} from 'app/routing/routeIds';

export const useSettingMainMenu = (): SettingMenuItemsData => {
    const { t } = useTranslation();
    const isInvoicesPageEnabled = useIsInvoicesPageEnabled();

    return {
        menus: [
            {
                label: t('settings_menu_general'),
                pathname: SETTINGS_MAIN_PATHNAME,
                options: [
                    {
                        name: 'profile',
                        value: 'profile',
                        label: t('settings_menu_general_profile'),
                        pathname: SETTINGS_MAIN_PROFILE_PATHNAME,
                    },
                    {
                        name: 'notifications',
                        value: 'notifications',
                        label: t('settings_menu_general_notifications'),
                        pathname: SETTINGS_MAIN_NOTIFICATIONS_PATHNAME,
                    },
                    {
                        name: 'invoices',
                        value: 'invoices',
                        label: t('settings_menu_general_invoices'),
                        pathname: SETTINGS_MAIN_INVOICES_PATHNAME,
                        display: !!isInvoicesPageEnabled,
                    },
                ],
            },
        ],
    };
};
