import { useState } from 'react';

import { Button, Modal, Text, TextInput, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import { API_KEYS_LIST } from 'app/common/data/queryKeysConstants';
import { STATIC_BASE_URL } from 'app/config';

import { useApiKeyContext } from 'app/settingsManagement/components/ApiKey/ApiKey.context';

import queryClient from 'app/states/queryClient';

import {
    ApiKeyRevokeModalContainer,
    ApiKeyRevokeModalContent,
    RevokeButtonContainer,
    RevokeInputContainer,
    StyledDescriptionText,
} from './ApiKeyRevokeModal.styled';

const VALIDATION_STR = 'revoke';

const ApiKeyRevokeModal = () => {
    const { t } = useTranslation();
    const { apiKeyId, setApiKeyId, setApiKeyLabel, apiKeyLabel, showApiKeyModalForm } =
        useApiKeyContext();
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState(false);

    const mutation = useMutation(() => api.apiKeys.revokeApiKey(apiKeyId), {
        onSuccess: () => {
            queryClient.invalidateQueries([API_KEYS_LIST]);
        },
        onError: () => {
            toast.error(null, t('api_key_manager_snackbar_error'));
        },
    });

    const onHideClick = () => {
        setApiKeyId(0);
        setApiKeyLabel(null);
    };

    const resetState = () => {
        setInputValue('');
        setInputError(false);
    };

    const handleRevokeClick = () => {
        mutation.mutate();
        resetState();
        onHideClick();
    };

    const handleHide = () => {
        resetState();
        onHideClick();
    };

    const handleChangeInputValidation = (val: string) => {
        setInputValue(val);
    };

    return (
        <Modal isOpen={apiKeyId !== 0 && !showApiKeyModalForm} closeModal={handleHide}>
            <ApiKeyRevokeModalContainer>
                <ApiKeyRevokeModalContent>
                    <img
                        src={`${STATIC_BASE_URL}/images/app/delete_modal_image.svg`}
                        alt="revoke_image"
                    />
                    <Text variant="heading4" as="h4">
                        {t('api_key_manager_revoke_popin_title')}
                    </Text>
                    <StyledDescriptionText variant="bodyMRegular" as="span" color="secondary">
                        {t('api_key_manager_revoke_popin_message', {
                            apiKeyLabel: apiKeyLabel != null ? apiKeyLabel : 'No label',
                        })}
                    </StyledDescriptionText>
                    <RevokeInputContainer>
                        <TextInput
                            dataTrackId="api_key_manager_revoke_popin"
                            placeholder={t('api_key_manager_revoke_popin_placeholder')}
                            id="revoke-validation-input"
                            onChange={value => handleChangeInputValidation(value)}
                            value={inputValue || ''}
                            error={inputError}
                            notice={
                                inputError
                                    ? t('api_key_manager_revoke_popin_error_message')
                                    : undefined
                            }
                        />
                    </RevokeInputContainer>
                </ApiKeyRevokeModalContent>
                <Button
                    dataTrackId="api-key-revoke-modal__cancel"
                    onClick={handleHide}
                    appearance="text"
                    variant="secondary"
                    className="api-key-revoke-modal__cancel"
                >
                    {t('api_key_manager_revoke_popin_cancel')}
                </Button>
                <RevokeButtonContainer>
                    <Button
                        dataTrackId="api_key_manager_revoke_key_button"
                        onClick={handleRevokeClick}
                        full
                        shape="cube"
                        size="large"
                        disabled={inputValue.toLowerCase() !== VALIDATION_STR}
                        variant="danger"
                    >
                        {t('api_key_manager_revoke_popin_button')}
                    </Button>
                </RevokeButtonContainer>
            </ApiKeyRevokeModalContainer>
        </Modal>
    );
};

export default ApiKeyRevokeModal;
