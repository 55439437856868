import { ErrorBoundary } from '@partoohub/ui';
import { Navigate, Route, Routes } from 'react-router-dom';

import PageErrorPlaceholder from 'app/common/components/errors/PageErrorPlaceholder';
import { Layout } from 'app/common/components/Layout';
import LoadingView from 'app/common/components/LoadingView';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import { useCanAccessCustomerExperiencePage } from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';

import {
    ANALYTICS_PATH,
    CONVERSATIONS_PATH,
    CUSTOMER_EXPERIENCE_PATH_TOKEN,
    DEFAULT_PAGE_PATH,
    HOMEPAGE_PATH,
    NOT_FOUND_PATH,
    POSTS_PATH,
    SETTINGS_PATH,
    VISIBILITY_PATH,
} from 'app/routing/routeIds';
import { AnalyticsRoutes } from 'app/routing/subRoutes/Analytics';
import { ConversationsRoutes } from 'app/routing/subRoutes/Conversations';
import { CustomerExperienceRoutes } from 'app/routing/subRoutes/CustomerExperience';
import { HomeRoutes } from 'app/routing/subRoutes/Home';
import { SettingsRoutes } from 'app/routing/subRoutes/settings';
import { VisibilityRoutes } from 'app/routing/subRoutes/Visibility';

import { PostsRoutes } from './subRoutes/Posts';

const CommonRoutes = (
    <>
        {/* DEFAULT PAGE */}
        <Route index element={<Navigate replace to={DEFAULT_PAGE_PATH} />} />
        <Route path={HOMEPAGE_PATH} element={<HomeRoutes />} />
        <Route path={`${VISIBILITY_PATH}/*`} element={<VisibilityRoutes />} />
        {/* CONVERSATIONS ROUTES */}
        <Route path={`${CONVERSATIONS_PATH}/*`} element={<ConversationsRoutes />} />
        <Route
            path={`${CUSTOMER_EXPERIENCE_PATH_TOKEN}/*`}
            element={<CustomerExperienceRoutes />}
        />
        <Route path={`${ANALYTICS_PATH}/*`} element={<AnalyticsRoutes />} />
        <Route path={`${POSTS_PATH}/*`} element={<PostsRoutes />} />
        <Route path={`${SETTINGS_PATH}/*`} element={<SettingsRoutes />} />
        {/* NOT FOUND ROUTES */}
        <Route path="*" element={<Navigate replace to={NOT_FOUND_PATH} />} />
    </>
);

export const App = () => {
    const { data: me } = useMe();
    // Make sure the data is here before loading the app

    const enableNewGroups = useNewGroups();
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);

    const { isLoading: customerExperiencePageAccessIsLoading } =
        useCanAccessCustomerExperiencePage();

    if (!me || enableNewGroups === undefined || customerExperiencePageAccessIsLoading) {
        return <LoadingView />;
    }

    return (
        <ErrorBoundary dataTrackId="" FallbackComponent={PageErrorPlaceholder}>
            <Routes>
                {isNewSidebarEnabled ? (
                    <Route path="/" element={<Layout />}>
                        {CommonRoutes}
                    </Route>
                ) : (
                    CommonRoutes
                )}
            </Routes>
        </ErrorBoundary>
    );
};

export const AppRoutes = App;
