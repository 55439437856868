import { addDays, format } from 'date-fns';
import { connect } from 'react-redux';

import { FormattedGooglePost } from 'app/api/types/google_post';
import { ORG_ADMIN } from 'app/common/data/roles';
import PostCardComponent, {
    PUBLISHER_FACEBOOK,
    PUBLISHER_GOOGLE,
    PUBLISHER_INSTAGRAM,
} from 'app/common/designSystem/components/molecules/GooglePostCard/PostCard';
import { meRoleSelector } from 'app/common/reducers/me';
import i18n from 'app/common/translations/i18n';
import { AppState, meSelector } from 'app/states/reducers';

type OwnProps = {
    post: FormattedGooglePost | any;
    onDelete: (isBulk: boolean) => void;
    onEdit: (isBulk: boolean) => void;
    showOnFacebook?: () => void;
    showOnGoogle?: () => void;
    onDuplicate?: () => void;
};

export enum ButtonsToDisable {
    showOnGoogle = 'showOnGoogle',
    showOnFacebook = 'showOnFacebook',
    showOnInstagram = 'showOnInstagram',
    duplicate = 'duplicate',
    edit = 'edit',
    delete_bulk = 'delete_bulk',
    delete = 'delete',
    edit_bulk = 'edit_bulk',
}

export const getDisabledButtons = (
    post: Record<string, any>,
    userRole: string | null,
): ButtonsToDisable[] => {
    /**
     * By default all actions (except delete) is forbiden
     * add your rule when something needs to be activated
     * and not when something needs to be de-activated
     * Don't worry about duplicates, Sets are unique, imposible to add value twice
     */
    const { postStatus, bulkId } = post;
    // first time using sets in prod, life is good 🌻
    const disabledButtons = new Set([
        ButtonsToDisable.showOnGoogle,
        ButtonsToDisable.showOnFacebook,
        ButtonsToDisable.showOnInstagram,
        ButtonsToDisable.duplicate,
        ButtonsToDisable.edit,
        ButtonsToDisable.delete,
        ButtonsToDisable.delete_bulk,
        ButtonsToDisable.edit_bulk,
    ]);

    postStatus.forEach(({ name, state, link }) => {
        switch (state) {
            case 'live':
                disabledButtons.delete(ButtonsToDisable.duplicate);
                if (name === PUBLISHER_INSTAGRAM) {
                    if (!!link) {
                        disabledButtons.delete(ButtonsToDisable.showOnInstagram);
                    } else {
                        disabledButtons.delete(ButtonsToDisable.delete);
                    }
                }
                if (name === PUBLISHER_GOOGLE) {
                    if (!!link) {
                        disabledButtons.delete(ButtonsToDisable.showOnGoogle);
                    }
                    disabledButtons.delete(ButtonsToDisable.edit);
                    disabledButtons.delete(ButtonsToDisable.delete);
                }
                if (name === PUBLISHER_FACEBOOK) {
                    if (!!link) {
                        disabledButtons.delete(ButtonsToDisable.showOnFacebook);
                    }
                    disabledButtons.delete(ButtonsToDisable.edit);
                    disabledButtons.delete(ButtonsToDisable.delete);
                }
                break;
            case 'pending':
                if (name !== PUBLISHER_INSTAGRAM) {
                    disabledButtons.delete(ButtonsToDisable.delete);
                }
                break;
            case 'expired':
                disabledButtons.delete(ButtonsToDisable.duplicate);
                if (name !== PUBLISHER_INSTAGRAM || !link) {
                    disabledButtons.delete(ButtonsToDisable.delete);
                }
                break;
            case 'error':
            case 'to_configure':
            case 'scheduled':
                disabledButtons.delete(ButtonsToDisable.edit);
                disabledButtons.delete(ButtonsToDisable.delete);
                break;
            default:
                break;
        }
    });

    if (bulkId && userRole === ORG_ADMIN) {
        const publishers = postStatus.map(status => status.name);
        const hasOnlyInstagram = publishers.length === 1 && publishers[0] === PUBLISHER_INSTAGRAM;
        if (!hasOnlyInstagram) {
            disabledButtons.delete(ButtonsToDisable.delete_bulk);
        }
        if (!disabledButtons.has(ButtonsToDisable.edit)) {
            disabledButtons.delete(ButtonsToDisable.edit_bulk);
        }
    }

    return Array.from(disabledButtons);
};

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
    const { post } = ownProps;
    const userRole = meRoleSelector(meSelector(state));

    const formatNewsDateForCard = (date: Date) => {
        const formattedStartDate = format(date, `dd MMM yyyy '${i18n.t('at')}' p`);
        // Google used to expire google post of type news after 1 week, but it's not the case since January 19th 2021
        if (date < new Date('2021-01-19')) {
            const endDate = addDays(date, 7);
            const formattedEndDate = format(endDate, `dd MMM yyyy '${i18n.t('at')}' p`);
            return `${formattedStartDate} - ${formattedEndDate}`;
        }
        return formattedStartDate;
    };

    return {
        postId: post.id,
        postBulkId: post.bulkId,
        topicType: post.postType,
        businessInfo: post.businessInfo,
        postStatus: post.postStatus,
        postInsight: post.postInsight,
        searchUrl: '',
        photoUrl: post.postMedias[0]?.mediaUrl,
        title: post.title,
        dates: formatNewsDateForCard(post.scheduleTime || post.createdAt),
        summary: post.summary,
        disabledValues: getDisabledButtons(post, userRole),
        businessId: post.businessId,
    };
};

const mapDispatchToProps = (_: any, ownProps: OwnProps) => {
    const { post, onDelete, onEdit, onDuplicate } = ownProps;
    const { postStatus } = post;

    let googleLink = '';
    let facebookLink = '';
    let instagramLink = '';
    postStatus.forEach(({ name, state, link }) => {
        if (name === PUBLISHER_GOOGLE && state === 'live') {
            googleLink = link;
        }
        if (name === PUBLISHER_FACEBOOK && state === 'live') {
            facebookLink = link;
        }
        if (name === PUBLISHER_INSTAGRAM && state === 'live') {
            instagramLink = link;
        }
    });
    return {
        actionCallbacks: {
            delete: () => onDelete(false),
            delete_bulk: () => onDelete(true),
            edit: () => onEdit(false),
            edit_bulk: () => onEdit(true),
            showOnFacebook: () => {
                if (facebookLink) window.open(facebookLink, '_blank');
            },
            showOnGoogle: () => {
                if (googleLink) window.open(googleLink, '_blank');
            },
            showOnInstagram: () => {
                if (instagramLink) window.open(instagramLink, '_blank');
            },
            duplicate: () => {
                onDuplicate?.();
            },
        },
    };
};

const PostCard = connect(mapStateToProps, mapDispatchToProps)(PostCardComponent);
export default PostCard;
