import { HEX_COLORS, convertToRgba, darkenColor } from '@partoohub/branding';

import { ColorProgress } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';

export const COLORS_PROGRESS = {
    [ColorProgress.LOW]: {
        default: 'rgba(255, 66, 110, 0.12)',
        gradient: {
            start: '#ffa1b7',
            middle: HEX_COLORS.danger,
            end: '#cc3558',
        },
    },
    [ColorProgress.MEDIUM]: {
        default: convertToRgba(HEX_COLORS.warning, 0.12),
        gradient: {
            start: '#ffc88a',
            middle: HEX_COLORS.warning,
            end: '#cc7310',
        },
    },
    [ColorProgress.HIGH]: {
        default: 'rgba(64, 219, 162, 0.12)',
        gradient: {
            start: '#98f4d2',
            middle: HEX_COLORS.success,
            end: darkenColor(HEX_COLORS.success, 0.4),
        },
    },
    [ColorProgress.NONE]: {
        default: convertToRgba(HEX_COLORS.secondary, 0.12),
        gradient: {
            start: convertToRgba(HEX_COLORS.secondary, 0.12),
            middle: convertToRgba(HEX_COLORS.secondary, 0.12),
            end: HEX_COLORS.secondary,
        },
    },
};

export const LOW_RATE = 33;
export const MID_RATE = 66;
export const HIGH_RATE = 99;

export enum BusinessStatusRate {
    LOW_RATE,
    MID_RATE,
    HIGH_RATE,
}
