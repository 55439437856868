export const REGEX_HTML_CHARACTER = /[&<>'"]/g;
export const REGEX_BRACKETS_CHARACTER = /[<>]/g;

export const removeHtmlSpecialCharacters = (value: string) => {
    return value.replace(REGEX_HTML_CHARACTER, '');
};

export const removeBracketsCharacters = (value: string) => {
    return value.replace(REGEX_BRACKETS_CHARACTER, '');
};

export const escapeHTML = function (unsafe: string) {
    return unsafe.replace(REGEX_HTML_CHARACTER, function (m) {
        switch (m) {
            case '&':
                return '&amp;';
            case '<':
                return '&lt;';
            case '>':
                return '&gt;';
            case '"':
                return '&quot;';
            default:
                return '&#039;';
        }
    });
};
