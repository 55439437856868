import { useState } from 'react';

import { Button, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { FunnelModalTemplate } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/components/FunnelModalTemplate/FunnelModalTemplate';
import { FunnelBulkInAppStepsEnum } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/data/funnelSteps';
import { useFunnelModalContext } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/hooks/FunnelModalContext';

import {
    FieldName,
    FieldOptionCard,
    FieldOptionIconWrapper,
    RightRadio,
    SelectFieldPageContainer,
} from './SelectFieldPage.styled';

interface FieldOption {
    name: string;
    icon: string;
    nextStep?: FunnelBulkInAppStepsEnum;
}

const FIELDS_OPTIONS: FieldOption[] = [
    {
        name: 'photos',
        icon: FontAwesomeIconsPartooUsed.faCamera,
        nextStep: FunnelBulkInAppStepsEnum.ADD_PHOTOS,
    },
    {
        name: 'business_hours',
        icon: FontAwesomeIconsPartooUsed.faClock,
    },
    {
        name: 'business_specific_hours',
        icon: FontAwesomeIconsPartooUsed.faTimer,
    },
];

export const SelectFieldPage = () => {
    const { t } = useTranslation();
    const [selectedField, setSelectedField] = useState('photos');
    const { setCurrentStep } = useFunnelModalContext();

    const onSelect = (field: FieldOption) => {
        if (field.nextStep) {
            setSelectedField(field.name);
        }
    };

    const onSubmit = () => {
        const nextStep = FIELDS_OPTIONS.find(option => option.name === selectedField)?.nextStep;
        if (!nextStep) {
            return;
        }
        setCurrentStep(nextStep);
    };

    return (
        <FunnelModalTemplate
            footer={
                <Button
                    dataTrackId="bulk_in_app__select_field_page__save"
                    variant="primary"
                    onClick={onSubmit}
                    iconPosition="right"
                    icon={['fa-arrow-right']}
                    size="large"
                    full
                >
                    {t('next')}
                </Button>
            }
        >
            <SelectFieldPageContainer gap="16px">
                <Text as="span" variant="heading6">
                    {t('bulk_in_app_funnel__select_field_page__question')}
                </Text>
                {FIELDS_OPTIONS.map(field => (
                    <FieldOptionCard
                        key={field.name}
                        direction="row"
                        gap="16px"
                        alignItems="center"
                        active={selectedField === field.name}
                        disabled={!field.nextStep}
                        onClick={() => onSelect(field)}
                    >
                        <FieldOptionIconWrapper>
                            <Icon icon={[field.icon, IconPrefix.REGULAR]} />
                        </FieldOptionIconWrapper>
                        <FieldName variant="heading6">
                            {t(field.name)} {!field.nextStep ? `(${t('coming_soon')})` : ''}
                        </FieldName>
                        <RightRadio
                            dataTrackId="bulk_in_app__select_field_page__select_field"
                            checked={selectedField === field.name}
                            value={field.name}
                            onChange={() => {}}
                        />
                    </FieldOptionCard>
                ))}
            </SelectFieldPageContainer>
        </FunnelModalTemplate>
    );
};
