import { ReactNode, useEffect, useRef, useState } from 'react';

import {
    FontAwesomeIconsPartooUsed,
    Icon,
    IconPrefix,
    MenuList,
    Option,
    Section,
    theme,
} from '@partoohub/ui';

import {
    Container,
    MenuLabelContainer,
    MenuListWrapper,
    OutlinedContainer,
} from './DropdownMenuButton.styled';

type LabelType = string | ReactNode;

interface DropdownMenuButtonProps {
    onMenuClick: (value: string) => void;
    sections: Array<Section>;
    selectedOptionName?: string;
    staticLabel?: string; // always displays regardless of choice
    customPosition?: 'left' | 'right';
    // to have a different menu label than the ones in the 'MenuList'
    menuLabels?: Map<string, LabelType>;
    outlinedButtonStyle?: boolean;
    color?: string;
}

const DEFAULT_TOP_POSITION = 40;

export const DropdownMenuButton = ({
    onMenuClick,
    sections,
    selectedOptionName = '',
    staticLabel = '',
    customPosition = 'left',
    menuLabels = new Map<string, LabelType>(),
    outlinedButtonStyle = false,
    color = theme.colors.theme.initial,
}: DropdownMenuButtonProps) => {
    const [isMenuShown, setIsMenuShown] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = (event: Event) => {
        if (!wrapperRef.current?.contains(event.target as Node)) {
            setIsMenuShown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside, wrapperRef]);

    const handleChange = (option: Option) => {
        onMenuClick(option.value);
        setIsMenuShown(!isMenuShown);
    };

    const handleToggleMenu = () => {
        setIsMenuShown(!isMenuShown);
    };

    const handleIsChecked = (option: Option) => {
        return option.name === selectedOptionName;
    };

    const getMenuLabel = (
        sections: Array<Section>,
        selectedOptionName: string,
        menuLabels: Map<string, LabelType>,
    ) => {
        const alternativeLabel = menuLabels.get(selectedOptionName);
        if (alternativeLabel) {
            return alternativeLabel;
        }

        const selectedOption = sections
            .flatMap(section => section.options)
            .find(option => option.name === selectedOptionName);
        return selectedOption ? selectedOption.label : sections[0].options[0].label;
    };
    const menuLabel = getMenuLabel(sections, selectedOptionName, menuLabels);

    const chevronIcon = isMenuShown
        ? FontAwesomeIconsPartooUsed.faChevronUp
        : FontAwesomeIconsPartooUsed.faChevronDown;

    const outlinedButtonStyleChevronIcon = isMenuShown
        ? FontAwesomeIconsPartooUsed.faCaretUp
        : FontAwesomeIconsPartooUsed.faCaretDown;

    const wrapperHeight =
        wrapperRef?.current?.getBoundingClientRect()?.height ?? DEFAULT_TOP_POSITION;
    return (
        <>
            {outlinedButtonStyle ? (
                <OutlinedContainer ref={wrapperRef} onClick={handleToggleMenu} color={color}>
                    <MenuLabelContainer>
                        {staticLabel || menuLabel}
                        <Icon
                            icon={[outlinedButtonStyleChevronIcon, IconPrefix.SOLID]}
                            color={color}
                        />
                    </MenuLabelContainer>
                    {isMenuShown && (
                        <MenuListWrapper customPosition={customPosition} height={wrapperHeight}>
                            <MenuList
                                sections={sections}
                                isChecked={handleIsChecked}
                                onChange={handleChange}
                            />
                        </MenuListWrapper>
                    )}
                </OutlinedContainer>
            ) : (
                <Container ref={wrapperRef} onClick={handleToggleMenu}>
                    <MenuLabelContainer>
                        {staticLabel || menuLabel}
                        <Icon icon={[chevronIcon]} iconSize="13px" />
                    </MenuLabelContainer>
                    {isMenuShown && (
                        <MenuListWrapper customPosition={customPosition} height={wrapperHeight}>
                            <MenuList
                                sections={sections}
                                isChecked={handleIsChecked}
                                onChange={handleChange}
                            />
                        </MenuListWrapper>
                    )}
                </Container>
            )}
        </>
    );
};
