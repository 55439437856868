import React, { useEffect, useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix, Modal } from '@partoohub/ui';
import { useSelector } from 'react-redux';

import ProviderLogo from 'app/common/components/ProviderLogo/ProviderLogo';
import UnsavedChangesModal from 'app/common/components/UnsavedChangesModal/UnsavedChangesModal';

import useNavigationBlocker from 'app/common/hooks/useNavigationBlocker';
import { STATIC_BASE_URL } from 'app/config';

import FunnelContext, { FunnelScreen } from './FunnelContext';
import useInitFunnel from './FunnelHook';

import {
    Body,
    Footer,
    FullScreen,
    Header,
    HeaderActions,
    Loading,
    PartooLogo,
} from './FunnelModal.styled';

type Props = {
    show: boolean;
    setShow: Function;
    initScreens: FunnelScreen[];
    startScreen?: number;
};

const FunnelModal = ({ show, setShow, initScreens, startScreen }: Props) => {
    const funnel = useInitFunnel(initScreens, setShow, startScreen);
    const { editedLocations = [] } = funnel.store;

    const providerLabel = useSelector((state: any) => state.me?.data?.provider);
    const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
    const [triggerUnsavedModal, setTriggerUnsavedModal] = useState<boolean>(false);

    const whenBlocker = show && !!funnel.currentScreen().displayUnsavedAlert;
    const { blocker, confirmNavigation, rejectNavigation } = useNavigationBlocker({
        when: whenBlocker,
    });

    const handleAbort = () => {
        resetUnsavedTrigger();
        funnel.navigate('add_location_page');
        funnel.abort();
    };

    const resetUnsavedTrigger = () => {
        setTriggerUnsavedModal(false);
        setShowUnsavedModal(false);
    };

    useEffect(() => {
        if (triggerUnsavedModal) {
            if (funnel.currentScreen().displayUnsavedAlert && editedLocations.length > 0) {
                setShowUnsavedModal(true);
            } else {
                handleAbort();
            }
        }
    }, [triggerUnsavedModal]);

    const handleModalClose = () => {
        // do this so component always get information up to date from children
        setTriggerUnsavedModal(true);
    };

    return (
        <FunnelContext.Provider value={funnel}>
            <UnsavedChangesModal
                show={showUnsavedModal || !!blocker}
                onConfirm={() => {
                    handleAbort();
                    confirmNavigation();
                }}
                onHide={() => {
                    resetUnsavedTrigger();
                    rejectNavigation();
                }}
            />

            <Modal
                isOpen={show}
                closeModal={handleModalClose}
                placement="center"
                animation="fadeIn"
            >
                <FullScreen backgroundUrl={`${STATIC_BASE_URL}/images/app/funnel/background.png`}>
                    {show && (
                        <>
                            <Loading progress={funnel.getProgressBar()} />

                            <Header>
                                <HeaderActions place="left">
                                    {funnel.currentScreen().backButtonAction ? (
                                        <IconButton
                                            dataTrackId="back_button_action"
                                            appearance="outlined"
                                            variant="primary"
                                            icon={[
                                                FontAwesomeIconsPartooUsed.faArrowLeft,
                                                IconPrefix.SOLID,
                                            ]}
                                            onClick={() => funnel.backButtonAction()}
                                        />
                                    ) : (
                                        <div />
                                    )}
                                </HeaderActions>
                                <PartooLogo>
                                    {providerLabel !== 'partoo' ? (
                                        <ProviderLogo providerLabel={providerLabel} />
                                    ) : (
                                        <img
                                            className={'logo'}
                                            src={`${STATIC_BASE_URL}/images/providers_asset/partoo/partoo_logo.png`}
                                            alt="partoo-logo"
                                        />
                                    )}
                                </PartooLogo>
                                <HeaderActions place="right">
                                    {funnel.showAdditionButtonsActions()}

                                    {funnel.showCloseButton() && (
                                        <IconButton
                                            dataTrackId="close_button_action"
                                            appearance="outlined"
                                            variant="primary"
                                            icon={[
                                                FontAwesomeIconsPartooUsed.faClose,
                                                IconPrefix.SOLID,
                                            ]}
                                            onClick={handleModalClose}
                                        />
                                    )}
                                </HeaderActions>
                            </Header>
                            <Body>{funnel.currentScreen().component}</Body>
                            {/* the footer is hidden with a display none, since not used in the durrent design */}
                            <Footer />
                        </>
                    )}
                </FullScreen>
            </Modal>
        </FunnelContext.Provider>
    );
};

export default FunnelModal;
