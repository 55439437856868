import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const ReviewCardSeparatorArea = styled.div`
    grid-area: separator;
    margin: 8px auto;
`;

export const StyledSeparator = styled.div`
    height: 100%;
    width: 3px;
    background-color: ${greyColorObject.initial};
`;
