import React, { useState } from 'react';

import { IconButton, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { STATIC_BASE_URL } from 'app/config';
import ConversationStarterItemText from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/ConversationStarterItemText';
import useConversationStarterItemDelete from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarterItemDelete';
import { ConversationStarterItemPostback } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';

import {
    ConversationStarterItemCardContainer,
    ConversationStarterItemCardContent,
    ConversationStarterItemCardReponse,
    IconListContainer,
} from './ConversationStarterItemCard.styled';

type Props = {
    conversationStarterItem: ConversationStarterItemPostback;
    onEditClick: () => void;
};

const ConversationStarterItemCard = ({ conversationStarterItem, onEditClick }: Props) => {
    const { t } = useTranslation();
    const deleteMutation = useConversationStarterItemDelete(
        conversationStarterItem.conversation_starter_id,
        conversationStarterItem.id,
    );
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    return (
        <>
            <ConversationStarterItemCardContainer onClick={onEditClick}>
                <ConversationStarterItemCardContent>
                    <Text variant="bodyMBold" color="default">
                        {conversationStarterItem.title}
                    </Text>
                    <ConversationStarterItemCardReponse variant="bodyMRegular" color="default">
                        <ConversationStarterItemText text={conversationStarterItem.reply_message} />
                    </ConversationStarterItemCardReponse>
                </ConversationStarterItemCardContent>
                <IconListContainer>
                    <IconButton
                        appearance="outlined"
                        dataTrackId="conversation_starters_edit"
                        icon={['fa-pen-clip']}
                        size="small"
                        onClick={onEditClick}
                    />
                    <IconButton
                        appearance="outlined"
                        dataTrackId="conversation_starters_delete"
                        icon={['fa-trash']}
                        size="small"
                        variant="danger"
                        onClick={e => {
                            e.stopPropagation();
                            setShowDeleteModal(true);
                        }}
                    />
                </IconListContainer>
            </ConversationStarterItemCardContainer>
            <ConfirmModal
                modalFor="delete"
                show={showDeleteModal}
                title={t('conversation_starters_delete_modal_title')}
                content={t('conversation_starters_delete_modal_text')}
                image={<img src={`${STATIC_BASE_URL}/images/app/confirm_category_remap.svg`} />}
                onConfirm={() => {
                    setShowDeleteModal(false);
                    deleteMutation.mutate();
                }}
                onHide={() => setShowDeleteModal(false)}
                trackId="conversation_starters_delete_model"
            />
        </>
    );
};

export default ConversationStarterItemCard;
