import {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';

import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { formatAutoReplySettingsTemplate } from 'app/api/formatters/response/reviewReplyTemplateFormatter';
import { LiteralRating } from 'app/api/types/review';
import { ReviewAutoReplySettings } from 'app/api/types/reviewAutoReplySettings';
import { FormattedReplyTemplate } from 'app/api/types/reviewReplyTemplates';
import { ChoiceWithSubtitle } from 'app/api/types/user';

import { setSelectedBusinessIdsModalActions } from 'app/common/components/businessModal/context/businessModalCustomActions';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import {
    filterByBusinessSelector,
    modeSelector,
    resetBusinessFilter,
} from 'app/common/components/businessModalDeprecated/reducers';

import { useBusinessesLanguages } from './queries/useBusinessesLanguages';
import { useGetInitialBusinesses } from './queries/useGetInitialBusinesses';
import useLoadSettings from './queries/useLoadSettings';
import useSaveSettingsHelpers, { SaveSettingsHelpers } from './useSaveSettingsHelpers';
import { allRatingsOptions } from '../components/SettingsPageForm/RatingsSelectStep/RatingButton/RatingButton';

export type ReplyTemplateSelectFilters = {
    search: string;
    languages: Array<ChoiceWithSubtitle>;
    ratings: Array<ChoiceWithSubtitle<LiteralRating>>;
};

type SettingsPageContextProps = {
    forReviewsWithComment: boolean;
    selectedRatings: Array<ChoiceWithSubtitle<LiteralRating>>;
    selectedTemplates: Array<FormattedReplyTemplate>;
    formChanged: boolean;
    filters: ReplyTemplateSelectFilters;
    languages: Array<string>;
    loadedSettings: ReviewAutoReplySettings | undefined;
    showSuccessModal: boolean;
    isInitialized: boolean;
    saveHelpers: SaveSettingsHelpers;
    setShowSuccessModal: (show: boolean) => void;
    onRatingSelect: (option: ChoiceWithSubtitle<LiteralRating>) => void;
    onLanguageFilterChange: (selectedLanguages: Array<ChoiceWithSubtitle>) => void;
    onTemplatesSelect: (value: boolean, ...templates: Array<FormattedReplyTemplate>) => void;
    onRatingFilterChange: (ratings: Array<ChoiceWithSubtitle<LiteralRating>>) => void;
    onSearchChange: (text: string) => void;
};

export const SettingsPageContext = createContext<SettingsPageContextProps>({
    forReviewsWithComment: false,
    selectedRatings: [],
    selectedTemplates: [],
    formChanged: false,
    filters: {
        search: '',
        languages: [],
        ratings: [],
    },
    languages: [],
    loadedSettings: undefined,
    showSuccessModal: false,
    isInitialized: false,
    saveHelpers: {
        errorBannerRef: { current: null },
        hasTriedToActivate: false,
        checkIfCanActivate: () => false,
        getAutoCheckErrors: () => [],
        setRatingsForAutoCheck: () => undefined,
        setHasTriedToActivate: () => undefined,
        save: () => undefined,
        saveAndDeactivate: () => undefined,
        saveAndActivate: () => undefined,
        isSaving: false,
    },
    setShowSuccessModal: () => undefined,
    onRatingSelect: () => undefined,
    onTemplatesSelect: () => undefined,
    onLanguageFilterChange: () => undefined,
    onRatingFilterChange: () => undefined,
    onSearchChange: () => undefined,
});

type Props = {
    forReviewsWithComment: boolean;
};

const initialFilters: ReplyTemplateSelectFilters = {
    search: '',
    languages: [],
    ratings: [],
};

export const SettingsPageProvider = ({
    forReviewsWithComment,
    children,
}: PropsWithChildren<Props>) => {
    const dispatch = useDispatch();

    const [selectedRatings, setSelectedRatings] = useState<
        Array<ChoiceWithSubtitle<LiteralRating>>
    >([]);
    const [selectedTemplates, setSelectedTemplates] = useState<Array<FormattedReplyTemplate>>([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [filters, setFilters] = useState(initialFilters);

    useLayoutEffect(() => {
        // Reset form when switching between with and without comment
        setSelectedRatings([]);
        setSelectedTemplates([]);
        dispatch(resetBusinessFilter());
        setFilters(initialFilters);
    }, [forReviewsWithComment]);

    const [loadedSettings, getLoadedSettingsQuery] = useLoadSettings(forReviewsWithComment);
    const initialRatings = allRatingsOptions.filter(
        option => loadedSettings?.[option.value] === true,
    );

    const initialTemplates = loadedSettings?.templates.map(formatAutoReplySettingsTemplate) ?? [];
    useLayoutEffect(() => {
        setSelectedTemplates(initialTemplates);
        setSelectedRatings(initialRatings);
    }, [loadedSettings]);

    const [businessesLoaded, setBusinessesLoaded] = useState(false);
    const { businessIds: initialBusinesses, businessLanguages: initialBusinessesLanguages } =
        useGetInitialBusinesses(loadedSettings?.id);
    useEffect(() => {
        if (initialBusinesses) {
            dispatch(setSelectedBusinessIdsModalActions(initialBusinesses));
            setBusinessesLoaded(true);
        }
    }, [initialBusinesses]);
    const businessesFilters = useBusinessModalFilters();
    const filterByBusiness = useSelector(filterByBusinessSelector);
    const businessModalMode = modeSelector(filterByBusiness);

    const businessesChanged =
        !isEqual(new Set(businessesFilters.business__in?.split(',')), new Set(initialBusinesses)) ||
        businessModalMode !== 'select';
    const ratingsChanged = !isEqual(initialRatings, selectedRatings);
    const templatesChanged = !isEqual(initialTemplates, selectedTemplates);

    const onLanguageFilterChange = (selectedLanguages: Array<ChoiceWithSubtitle>) => {
        setFilters({
            ...filters,
            languages: selectedLanguages,
        });
    };

    const onRatingFilterChange = (ratings: Array<ChoiceWithSubtitle<LiteralRating>>) => {
        setFilters({ ...filters, ratings });
    };

    const languages = useBusinessesLanguages(businessesFilters, initialBusinessesLanguages);
    useEffect(() => {
        setFilters({
            ...filters,
            languages: filters.languages.filter(filterLang => languages.includes(filterLang.value)),
        });
    }, [languages]);

    const saveHelpers = useSaveSettingsHelpers({
        forReviewsWithComment,
        loadedSettings,
        languages,
        selectedRatings,
        templates: selectedTemplates,
        setSelectedTemplates,
        setShowSuccessModal,
    });

    const onRatingSelect = (rating: ChoiceWithSubtitle<LiteralRating>) => {
        let unselected = false;
        setSelectedRatings(selection => {
            const selectedValues = selection.map(selected => selected.value);
            const selectedOptionIdx = selectedValues.findIndex(
                selectedValue => selectedValue === rating.value,
            );
            if (selectedOptionIdx !== -1) {
                selectedValues.splice(selectedOptionIdx, 1);
                unselected = true;
            } else {
                selectedValues.push(rating.value);
            }

            // Keep the selection ordered as in allRatingsOptions
            const newRatings = allRatingsOptions.filter(rating =>
                selectedValues.includes(rating.value),
            );
            if (unselected) {
                // If the rating is unselected, stop checking for errors on the fly
                saveHelpers.setRatingsForAutoCheck(checkRatings =>
                    checkRatings.filter(checkRating => checkRating.value !== rating.value),
                );
            }
            return newRatings;
        });

        setFilters(prevFilters => {
            const newFilters = prevFilters.ratings;
            const selectedOptionIdx = newFilters.findIndex(
                selected => selected.value === rating.value,
            );
            if (selectedOptionIdx !== -1) {
                newFilters.splice(selectedOptionIdx, 1);
                return { ...filters, ratings: newFilters };
            }
            return prevFilters;
        });
    };

    const onTemplatesSelect = (value: boolean, ...templates: Array<FormattedReplyTemplate>) => {
        setSelectedTemplates(selection => {
            if (!value) {
                return selection.filter(
                    selected => !templates.map(template => template.id).includes(selected.id),
                );
            }

            const templatesToAdd = templates.filter(
                template => !selection.map(selected => selected.id).includes(template.id),
            );
            return [...selection, ...templatesToAdd];
        });
    };

    const onSearchChange = (text: string) => setFilters({ ...filters, search: text ?? '' });

    const isInitialized =
        getLoadedSettingsQuery.isFetched && (!loadedSettings?.id || businessesLoaded);

    const formChanged = businessesChanged || ratingsChanged || templatesChanged;

    const values = useMemo(
        () => ({
            forReviewsWithComment,
            selectedRatings,
            formChanged,
            selectedTemplates,
            filters,
            languages,
            loadedSettings,
            showSuccessModal,
            saveHelpers,
            isInitialized,
            setShowSuccessModal,
            onRatingSelect,
            onTemplatesSelect,
            onLanguageFilterChange,
            onRatingFilterChange,
            onSearchChange,
        }),
        [
            forReviewsWithComment,
            selectedRatings,
            formChanged,
            selectedTemplates,
            filters,
            languages,
            loadedSettings,
            showSuccessModal,
            saveHelpers,
            isInitialized,
            setShowSuccessModal,
            onRatingSelect,
            onTemplatesSelect,
            onLanguageFilterChange,
            onRatingFilterChange,
            onSearchChange,
        ],
    );

    return <SettingsPageContext.Provider value={values}>{children}</SettingsPageContext.Provider>;
};

export const useSettingsPageContext = () => useContext(SettingsPageContext);
