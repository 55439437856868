import { FC, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

import { useTransformBusinessFilters } from './useTransformBusinessFilters';
import useBusinessCount from '../businesses/useBusinessCount';

interface BusinessSelectionContextType {
    /**
     * Is this business selected?
     * @param id id of the business
     */
    isSelected: (id: string) => boolean;

    /**
     * The number of selected businesses
     */
    selectedNumber: number;

    /**
     * Filters object that describe the current selection. This object is
     * designed to be directly used in the body of the HTTP POST request that
     * will update many businesses at once.
     */
    filters: BusinessModalFiltersType;

    /**
     * The state of the "select all" checkbox
     * This checkbox can have three states: checked, unchecked or indeterminate
     */
    checkboxState: boolean | 'indeterminate';

    /**
     * If all businesses are selected, deselect them all.
     * Otherwise, select them all.
     */
    selectOrUnselectAll: () => void;

    /**
     * Unselect all the businesses
     */
    resetBusinessSelection: () => void;

    /**
     * Toggle the selection of the given business:
     * - if it's selected, deselects it
     * - if it's not selected, selects it
     * @param id id of the business
     */
    toggle: (id: string) => void;
}

const BusinessSelectionContext = createContext<BusinessSelectionContextType>({
    isSelected: _id => false,
    selectedNumber: 0,
    filters: {},
    checkboxState: false,

    selectOrUnselectAll: () => undefined,
    resetBusinessSelection: () => undefined,
    toggle: (_id: string) => undefined,
});

/**
 * Keep track of what the user selects
 */
export const useBusinessSelectionContext = () =>
    useContext<BusinessSelectionContextType>(BusinessSelectionContext);

interface Props {
    children: ReactNode;
}

export const BusinessSelectionProvider: FC<Props> = ({ children }) => {
    const [businessIds, setBusinessIds] = useState<string[]>([]);
    const [selectByInclusion, setSelectByInclusion] = useState<boolean>(true);
    const total = useBusinessCount();
    const filtersForSelectAll = useTransformBusinessFilters();

    const selectByExclusion = !selectByInclusion;

    const filters: BusinessModalFiltersType = {
        ...(selectByExclusion
            ? { ...filtersForSelectAll, business__notin: businessIds.join() }
            : { business__in: businessIds.join() }),
        partoo_ui: true,
    };

    const allBusinessSelected =
        (selectByInclusion && businessIds.length == total) ||
        (selectByExclusion && businessIds.length == 0);

    const selectedNumber = selectByInclusion ? businessIds.length : total - businessIds.length;
    const checkboxState =
        selectedNumber > 0 ? (!allBusinessSelected ? 'indeterminate' : true) : false;

    const location = useLocation();

    useEffect(() => {
        if (selectByExclusion) {
            resetBusinessSelection();
        }
    }, [location]);

    const isSelected = (id: string) => {
        if (selectByInclusion) {
            return businessIds.includes(id);
        } else {
            return !businessIds.includes(id);
        }
    };

    const selectOrUnselectAll = () => {
        if (allBusinessSelected) {
            setBusinessIds([]);
            setSelectByInclusion(true);
        } else {
            setBusinessIds([]);
            setSelectByInclusion(false);
        }
    };

    const resetBusinessSelection = () => {
        setBusinessIds([]);
        setSelectByInclusion(true);
    };

    const toggle = (id: string) => {
        if (businessIds.includes(id)) {
            setBusinessIds(businessIds.filter(id2 => id !== id2));
        } else {
            setBusinessIds([...businessIds, id]);
        }
    };

    const value: BusinessSelectionContextType = useMemo(
        () => ({
            isSelected,
            selectedNumber,
            filters,
            selectOrUnselectAll,
            resetBusinessSelection,
            toggle,
            checkboxState,
        }),
        [
            isSelected,
            selectedNumber,
            filters,
            selectOrUnselectAll,
            resetBusinessSelection,
            toggle,
            checkboxState,
        ],
    );

    return (
        <BusinessSelectionContext.Provider value={value}>
            {children}
        </BusinessSelectionContext.Provider>
    );
};
