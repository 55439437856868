export const BulbIcon = () => (
    <svg width="88" height="76" viewBox="0 0 88 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_18683_258830)">
            <rect x="0.0283203" width="76" height="76" rx="16" fill="#0085F2" fillOpacity="0.12" />
            <path
                d="M75.3333 40.4515C75.8411 41.8536 74.7914 43.5439 74.203 44.2138L78.4584 49.0891C78.7481 49.074 79.4579 47.856 80.7524 47.5288C82.0469 47.2015 83.2022 46.2468 84.6117 44.6744C84.857 44.2648 85.9358 42.9471 85.7769 41.5483C85.6187 40.1494 82.9274 37.1464 81.434 36.8273C80.2394 36.5721 78.2133 38.2674 77.5308 39.2508C77.2377 38.3449 76.7987 37.8297 75.9383 36.9454C75.334 36.5319 74.2601 36.4627 74.0331 37.1511C73.8794 37.6174 74.0378 38.1288 74.3696 38.4955C74.8405 39.0157 74.994 39.5149 75.3333 40.4515Z"
                fill="#EFCB96"
            />
            <path
                d="M35.8666 28.1785L37.0118 32.3214C37.5844 34.3929 35.8666 36.8409 31.0949 36.8409C27.2775 36.8409 26.7049 33.3257 26.8958 31.5681L27.8501 26.8603C26.8958 25.9187 26.1323 25.3537 26.1323 20.8342C26.1323 15.1847 29.1862 13.1133 32.8127 13.1133C36.4392 13.1133 39.493 16.1231 39.4932 21.5874C39.4933 26.2953 36.9482 27.8018 35.8666 28.1785Z"
                fill="#EFCB96"
            />
            <path
                d="M32.4309 34.7697C28.9189 34.7697 27.1502 32.5099 26.7048 31.38L26.6866 31.38C23.313 31.38 17.8168 31.38 12.199 36.4645C6.57104 41.5582 1.87181 48.705 -2.37987 61.4283C-4.04533 66.4123 -4.23148 73.3005 -3.7225 75.9997H53.787C60.2764 75.9997 65.4235 74.8557 67.2137 72.688C71.4128 67.6035 80.3504 52.2725 79.9686 50.3894C79.5869 48.5062 74.8155 42.5387 73.4794 43.1037C72.4106 43.5556 66.99 47.5193 61.3957 51.7141C54.9063 34.7697 41.5925 31.1289 37.2026 31.38C37.0753 32.5099 35.9428 34.7697 32.4309 34.7697Z"
                fill="#577098"
            />
            <path
                d="M45.2199 24.2242C48.1211 27.2373 47.9556 32.5101 47.5103 34.7699C42.6186 31.8741 38.8462 31.3153 37.2178 31.3802C37.2178 31.6991 36.9902 32.3555 36.8771 32.3714L35.8673 28.1788C37.8992 27.3291 39.3029 25.7307 39.4938 21.3995C39.4938 18.0202 37.9032 15.5618 37.0125 14.8085C33.9586 14.6202 32.4316 18.3865 31.4773 19.893C30.7138 21.0982 30.1412 20.8973 29.9504 20.6462C28.996 18.3865 27.2782 18.1981 26.8965 20.6462C26.5911 22.6047 28.0417 23.5965 28.8052 23.8476L26.8259 31.8672L26.6556 31.531C23.2484 31.531 18.6586 32.5851 16.9713 33.2633C16.3987 27.2373 21.1704 25.1658 22.6974 21.3995C24.2243 17.6332 22.1248 15.7501 23.6517 13.302C24.7835 11.4875 26.8965 11.2305 28.2325 10.8539C29.5686 10.4773 30.3321 8.97073 31.859 8.21747C34.5312 7.2759 37.0761 9.59845 37.776 11.2305C38.2213 11.1677 39.4938 11.3435 41.0207 12.5487C45.4107 15.5617 41.5934 20.4579 45.2199 24.2242Z"
                fill="#142542"
            />
            <path
                d="M14.627 53.0391C12.8368 61.4287 10.7749 73.2778 11.0466 76.0001H8.36132C8.26448 70.7477 13.0605 55.9092 14.627 53.0391Z"
                fill="#142542"
            />
            <path
                d="M31.8586 27.9902C34.149 28.5928 35.4851 28.3669 35.8668 28.1785L36.4395 30.25C33.9963 30.5513 32.3676 28.869 31.8586 27.9902Z"
                fill="#142542"
            />
            <path
                d="M52.668 50.6104C54.2791 57.322 55.2786 70.3333 55.577 75.9999H57.3672C57.0092 66.8155 54.0852 55.2467 52.668 50.6104Z"
                fill="#142542"
            />
            <path
                d="M66.542 73.1298C60.6344 71.7168 57.5165 72.541 56.696 73.1298L57.1435 75.9999C61.619 75.9999 65.2739 74.0865 66.542 73.1298Z"
                fill="#142542"
            />
        </g>
        <defs>
            <clipPath id="clip0_18683_258830">
                <rect x="0.0283203" width="87.2066" height="76" rx="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
