import { useEffect, useState } from 'react';

import {
    Button,
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    NumberInput,
    Stack,
    TextInput,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { MAX_NUMBER_OF_TEXT_FIELDS } from './data';
import { TrashIcon } from './FormTypeImagesUploader.styled';

type Props = {
    params: Record<string, any>;
    updateParams: (typeParams: Record<string, any>) => void;
    duplicates: string[];
};

/*
    Transform text fields (ex: ['a', 'b']) and max lengths (ex: [20, 30]) to:
    [
    { oldValue: { text_field: 'a', max_length: 20 }, newValue: { text_field: 'a', max_length: 20 }, indexToDisplay: 1 },
    { oldValue: { text_field: 'b', max_length: 30 }, newValue: { text_field: 'b', max_length: 30 }, indexToDisplay: 2 },
    ]
    To delete, simply set newValue to null
*/
const transformTextFieldsDataForEdit = (textFields: string[], maxLengths: number[]) => {
    return textFields.map((value, index) => ({
        oldValue: { text_field: value, max_length: maxLengths[index] },
        newValue: { text_field: value, max_length: maxLengths[index] },
        indexToDisplay: index + 1,
    }));
};

export const FormTypeImagesUploaderEdit = ({ params, updateParams, duplicates }: Props) => {
    const { t } = useTranslation();
    const [autofocusOnNewOption, setAutofocusOnNewOption] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>(duplicates);

    const currentTextFields = params.editValues?.filter((value: any) => value.newValue !== null);

    useEffect(() => {
        if (params.editValues === undefined) {
            updateParams({
                ...params,
                editValues: transformTextFieldsDataForEdit(params.text_fields, params.max_lengths),
            });
            setErrors([]);
        }
    }, [params]);

    useEffect(() => {
        setErrors(duplicates);
    }, [duplicates]);

    const addField = () => {
        if (currentTextFields.length >= MAX_NUMBER_OF_TEXT_FIELDS) return;
        const editValues = params.editValues === undefined ? [] : params.editValues;
        editValues.push({
            oldValue: null,
            newValue: { text_field: '', max_length: null },
            indexToDisplay: currentTextFields.length + 1,
        });
        setAutofocusOnNewOption(true);
        updateParams({
            ...params,
            editValues,
        });
    };

    const onChange = (
        value: string | number,
        index: number,
        constraint: 'text_field' | 'max_length',
    ) => {
        const { editValues } = params;
        editValues[index].newValue[constraint] = value === '' ? null : value;
        updateParams({
            ...params,
            editValues,
        });
    };

    const onDeleteField = (index: number) => {
        const { editValues } = params;

        if (editValues[index].oldValue === null) {
            // if it is a newly created field, just delete it
            editValues.splice(index, 1);
        } else {
            editValues[index].newValue = null;
        }

        let idx = 1;
        editValues.forEach((obj: any) => {
            if (obj.newValue !== null) {
                obj.indexToDisplay = idx;
                idx += 1;
            }
        });
        updateParams({
            ...params,
            editValues,
        });
    };

    return (
        <Stack direction="column" gap="16px">
            {params.editValues &&
                params.editValues.map((value: any, index: number) => {
                    if (value.newValue === null) return;
                    const hasError = errors?.includes(value.newValue.text_field);
                    return (
                        <Stack key={index} direction="row" gap="16px" alignItems="top">
                            <TextInput
                                dataTrackId="custom_fields__images_uploader__text_field_name"
                                label={`${t('field')} #${params.editValues[index].indexToDisplay}`}
                                value={value.newValue.text_field}
                                onChange={newValue => onChange(newValue, index, 'text_field')}
                                error={hasError}
                                notice={hasError ? t('custom_fields_error_duplicated_options') : ''}
                                autoFocus={autofocusOnNewOption}
                            />
                            <NumberInput
                                dataTrackId="custom_fields__images_uploader__max_length"
                                label={t('maximum_length')}
                                value={
                                    value.newValue.max_length === null
                                        ? ''
                                        : value.newValue.max_length
                                }
                                onChange={value => onChange(+value || '', index, 'max_length')}
                                max={1000}
                                min={1}
                            />
                            <TrashIcon
                                dataTrackId="custom_fields__images_uploader__delete_field"
                                icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                                appearance="outlined"
                                variant="danger"
                                onClick={() => onDeleteField(index)}
                            />
                        </Stack>
                    );
                })}
            {currentTextFields?.length < MAX_NUMBER_OF_TEXT_FIELDS && (
                <Button
                    size="medium"
                    variant="primary"
                    appearance="outlined"
                    shape="cube"
                    onClick={addField}
                    icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                    dataTrackId="custom_fields__images_uploader__add_field"
                >
                    {t('add_text_field')}
                </Button>
            )}
        </Stack>
    );
};
