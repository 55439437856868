import {
    CEOrgFeatures,
    useHasCEOrgFeatures,
} from 'app/pages/customerExperience/settings/useHasCEOrgFeatures';

import { CEUserProducts, useHasCEUserProducts } from './settings/useHasCEUserProducts';

export enum CEPages {
    ClientReviews = 'ClientReviews',
    RB = 'RB',
    FBM = 'FBM',
}

export const useCanAccessCustomerExperiencePage = (): {
    canAccessCustomerExperiencePage: Record<CEPages, boolean | undefined>;
    isLoading: boolean;
} => {
    const { orgFeatures, isLoading: orgFeatureLoading } = useHasCEOrgFeatures();
    const { userProducts, isLoading: userProductsLoading } = useHasCEUserProducts();
    const canAccessFBM =
        orgFeatures[CEOrgFeatures.FeedbackManagement] && userProducts[CEUserProducts.FBM];

    return {
        canAccessCustomerExperiencePage: {
            [CEPages.FBM]: canAccessFBM,
            [CEPages.ClientReviews]: userProducts[CEUserProducts.RM] || canAccessFBM,
            [CEPages.RB]: userProducts[CEUserProducts.RB],
        },
        isLoading: userProductsLoading || orgFeatureLoading,
    };
};
