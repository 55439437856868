import React from 'react';

import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { Choice } from 'app/api/types/user';
import NewMultipleSelect from 'app/common/designSystem/components/molecules/MultipleSelect';

type Props = {
    value: Array<string>;
    name: string;
    onChange: (newValue: Array<string> | null) => void;
    possibleValues: Array<Choice>;
    disabled: boolean;
    displayName?: string;
};

const onChangeMultipleSelect = (values, onChange) => {
    const newValue = values.map(option => option.value);
    return onChange(newValue.length ? newValue : null);
};

const Component = ({
    value,
    name,
    onChange,
    possibleValues,
    disabled,
    displayName,
}: Props) => {
    const { t } = useTranslation();

    return (
        <NewMultipleSelect
            // @ts-ignore
            placeholder={displayName || name}
            // Get the right label for the value
            selectedOptions={
                value
                    ? value.map(option => ({
                          label: possibleValues.filter(
                              possibleOption => possibleOption.value === option,
                          )[0].label,
                          value: option,
                      }))
                    : []
            }
            options={possibleValues}
            onChange={e => onChangeMultipleSelect(e, onChange)}
            textTooltip={disabled ? t('field_read_only') : ''}
            disabled={disabled}
        />
    );
};

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'onChange' | 'value';

const CustomFieldsTypeMultipleSelect = ({
    name,
    control,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { ref: _ref, ...usedFields } }) => (
            <Component {...props} {...usedFields} />
        )}
    />
);

export default CustomFieldsTypeMultipleSelect;
