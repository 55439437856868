import { DateCriteria, DatePickerType, RangeDate } from '@partoohub/ui';

import { format, parse } from 'date-fns';

import { useTranslation } from 'react-i18next';

import { LocaleDatePicker } from 'app/common/components/LocaleDatePicker/LocaleDatePicker';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { DATE_FORMAT } from 'app/utils/dates';

export const DateFilter = () => {
    const { t } = useTranslation();
    const [from, setFrom] = useStateQueryParams('date-from');
    const [to, setTo] = useStateQueryParams('date-to');

    const rangeDate: RangeDate = [
        from ? parse(from, DATE_FORMAT, new Date()) : null,
        to ? parse(to, DATE_FORMAT, new Date()) : null,
    ];

    const onChange = (newRange: RangeDate | ((prevState: RangeDate) => RangeDate)) => {
        const range = typeof newRange === 'function' ? newRange(rangeDate) : newRange;
        const [startDate, endDate] = range;
        setFrom(startDate ? format(startDate, DATE_FORMAT) : '');
        setTo(endDate ? format(endDate, DATE_FORMAT) : '');
    };

    return (
        <LocaleDatePicker
            type={DatePickerType.DateRangePicker}
            dataTrackId=""
            dates={rangeDate}
            onChange={onChange}
            labels={{ startDate: t('start_date'), endDate: t('end_date') }}
            placeholders={{
                startDate: t('date_placeholder'),
                endDate: t('date_placeholder'),
            }}
            withShortcut
            shortcuts={[
                {
                    name: DateCriteria.LAST_MONTH,
                    label: t('date_criteria_last_month'),
                },
                {
                    name: DateCriteria.LAST_YEAR,
                    label: t('date_criteria_last_year'),
                },
            ]}
        />
    );
};
