import { useMutation } from 'react-query';

import { BusinessUpdateRequestData } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import { useBusinessSelectionContext } from 'app/pages/visibility/BusinessListV2/hooks/businessSelection/useBusinessSelectionContext';

export default function useApplyBulkInApp(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const { filters } = useBusinessSelectionContext();

    return useMutation(
        (data: BusinessUpdateRequestData) => api.business.applyBulkInApp(filters, data),
        {
            onSuccess,
            onError,
        },
    );
}
