import { HEX_COLORS } from '@partoohub/branding';
import { IconButton, Text } from '@partoohub/ui';

import { PopInContainer, PopInContent, PopInIcon } from './MessagingReplyTemplatesPopIn.styled';

type Props = {
    content: string;
    dataTrackId: string;
    onClick: () => void;
    placeArrowAtEnd?: boolean;
};

const MessagingReplyTemplatesPopIn = ({
    content,
    dataTrackId,
    onClick,
    placeArrowAtEnd = false,
}: Props) => {
    return (
        <PopInContainer placeArrowAtEnd={placeArrowAtEnd}>
            <PopInContent>
                <Text variant="bodyMRegular" color={HEX_COLORS.white}>
                    {content}
                </Text>
            </PopInContent>
            <PopInIcon>
                <IconButton
                    dataTrackId={dataTrackId}
                    appearance="outlined"
                    icon={['fa-close']}
                    size="small"
                    onClick={onClick}
                />
            </PopInIcon>
        </PopInContainer>
    );
};

export default MessagingReplyTemplatesPopIn;
