import { useMetaRelogin } from 'app/pages/visibility/subComponents/MetaRelogin/MetaReloginContext';

import { DefaultDialog } from './Default';
import { ErrorDialog } from './Error';
import { LoadingDialog } from './Loading';
import { SuccessDialog } from './Success';

export const MetaReloginDialog = () => {
    const context = useMetaRelogin();

    if (!context) return null;

    const { status } = context;

    const errorStatuses = ['error_wrong_account', 'error_permission', 'error_connection_failed'];

    switch (status) {
        case 'success':
            return <SuccessDialog />;
        case 'loading':
            return <LoadingDialog />;
        case 'default':
            return <DefaultDialog />;
        default:
            if (errorStatuses.includes(status)) {
                return <ErrorDialog type={status} />;
            }
            return null;
    }
};
