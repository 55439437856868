import dayjs from 'dayjs';

import { InfiniteData, QueryClient } from 'react-query';

import { ConversationMessage } from 'app/states/messaging';

import { getMessagesKey } from '../hooks/useGetMessages';

export const addNewMessage = (
    queryClient: QueryClient,
    conversationId: number,
    newMessage: ConversationMessage,
) => {
    const queryDataKey = getMessagesKey(conversationId);
    const messagePages: InfiniteData<Record<string, any>> | undefined =
        queryClient.getQueryData(queryDataKey);

    if (messagePages) {
        const firstPageMessages = messagePages.pages[0].messages;

        firstPageMessages.push(newMessage);
        firstPageMessages.sort(datePredicate);
        queryClient.setQueryData(queryDataKey, messagePages);
    }
};

export const updateMessage = (
    queryClient: QueryClient,
    conversationId: number,
    updatedMessage: ConversationMessage,
) => {
    const queryDataKey = getMessagesKey(conversationId);
    const messagePages: InfiniteData<Record<string, any>> | undefined =
        queryClient.getQueryData(queryDataKey);

    if (messagePages) {
        messagePages.pages.forEach(page => {
            const updatedMessages = page.messages.map((message: ConversationMessage) => {
                if (message.id === updatedMessage.id || message.date === updatedMessage?.date) {
                    return updatedMessage;
                }
                return message;
            });

            page.messages = updatedMessages;
        });
        queryClient.setQueryData(queryDataKey, messagePages);
    }
};

export const moveMessageToHead = (
    queryClient: QueryClient,
    conversationId: number,
    retryMessage: ConversationMessage,
) => {
    const queryDataKey = getMessagesKey(conversationId);
    const messagePages: InfiniteData<Record<string, any>> | undefined =
        queryClient.getQueryData(queryDataKey);

    if (messagePages) {
        messagePages.pages.forEach(page => {
            const retryMessageIndex = page.messages.findIndex((message: ConversationMessage) =>
                retryMessage.id
                    ? message.id === retryMessage.id
                    : message.date === retryMessage.date,
            );
            if (retryMessageIndex !== -1) {
                page.messages.splice(retryMessageIndex, 1);
            }
        });
        const firstPageMessages = messagePages.pages[0].messages;

        firstPageMessages.push(retryMessage);
        firstPageMessages.sort(datePredicate);
        queryClient.setQueryData(queryDataKey, messagePages);
    }
};

export const doesMessageExists = (
    queryClient: QueryClient,
    conversationId: number,
    checkedMessage: ConversationMessage,
) => {
    const queryDataKey = getMessagesKey(conversationId);
    const messagePages: InfiniteData<Record<string, any>> | undefined =
        queryClient.getQueryData(queryDataKey);

    if (messagePages) {
        return messagePages.pages.some(page => {
            return page.messages.some(
                (message: ConversationMessage) =>
                    message.id === checkedMessage.id || message.date === checkedMessage?.date,
            );
        });
    }
    return false;
};

const datePredicate = (a: ConversationMessage, b: ConversationMessage) => {
    return dayjs(a.date).isAfter(dayjs(b.date)) ? -1 : 1;
};
