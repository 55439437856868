import React from 'react';

import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import {
    Card,
    LocationAddress,
    LocationDetailsContainer,
    LocationName,
    PartnerIconContainer,
    VerificationButtonContainer,
    VerificationStatusContainer,
} from './VerificationCard.styled';

export const LoadingVerificationCard: React.FC = () => (
    <Card>
        <PartnerIconContainer>
            <LoadingRectangle
                recMargin="0px 8px"
                recRadius="50%"
                recHeight="48px"
                recWidth="48px"
            />
        </PartnerIconContainer>
        <LocationDetailsContainer>
            <LocationName>
                <LoadingRectangle
                    recMargin="3px 0px 0px 0px"
                    recRadius="4px"
                    recHeight="14px"
                    recWidth="140px"
                />
            </LocationName>
            <LocationAddress>
                <LoadingRectangle
                    recMargin="3px 0px 0px 0px"
                    recRadius="4px"
                    recHeight="14px"
                    recWidth="120px"
                />
            </LocationAddress>
            <LocationAddress>
                <LoadingRectangle
                    recMargin="3px 0px 0px 0px"
                    recRadius="4px"
                    recHeight="14px"
                    recWidth="200px"
                />
            </LocationAddress>
        </LocationDetailsContainer>
        <VerificationStatusContainer>
            <LoadingRectangle
                recRadius="4px"
                recHeight="100%"
                recWidth="100%"
            />
        </VerificationStatusContainer>
        <VerificationButtonContainer></VerificationButtonContainer>
    </Card>
);

export default LoadingVerificationCard;
