import styled from '@emotion/styled';

import { ControlledTextInput } from 'app/businessEditV2/hookControllerWrapper/ControlledTextInput';

export const MapContainer = styled.div`
    display: flex;
    height: 300px;
    margin-bottom: 40px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
`;

export const MapDescription = styled.div`
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    margin-bottom: 24px;
    position: relative;
`;

export const BusinessNameInput = styled(ControlledTextInput)``;
