import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import IS_IFRAME from 'app/common/data/iframe';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import useLocalStorage from 'app/common/hooks/useLocalStorage';
import IntercomWrapper from 'app/common/services/intercomWrapper';
import dataLayer from 'app/common/utils/dataLayer';
import WhatsappAvailableModal from 'app/pages/settings/Whatsapp/WhatsappAvailableModal';
import {
    reportHasError,
    reportHasSuccess,
    useInstagramOnboardingReport,
    useMessengerOnboardingReport,
} from 'app/reviewManagement/messaging/messenger/useOnboardingReport';

import AddInstagramModal, { InstagramAddedModal, InstagramFailureModal } from './AddInstagramModal';
import { AddMessengerModal, MessengerAddedModal, MessengerErrorModal } from './AddMessengerModal';
import { instagramReminderToast, messengerReminderToast } from './AddPartnerReminderToast';
import { AddInstagramIcon, AddMessengerIcon, OnboardedInstagramIcon } from './PartnerIcons';
import { useShowPartnersButtons } from '../hooks/useShowMessengerButton';

export interface OnboardingReportData {
    success_count: number | null;
    error_count: number | null;
}

interface MessengerModalProps {
    showMessengerButton: boolean;
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    hide: () => void;
}

const MessengerModal: FC<MessengerModalProps> = ({
    showMessengerButton,
    showModal,
    setShowModal,
    hide,
}) => {
    const { t } = useTranslation();
    const titleToast = t('messaging_add_messenger_information_modale_later_snackbar_title');
    const [timestamp, setNextDateToShowModal] = useLocalStorage<Date | null>(
        'nextDateToShowMessengerModal',
        null,
    );
    const nextDateToShowModal = timestamp ? new Date(timestamp) : null;

    useEffect(() => {
        if (!nextDateToShowModal || nextDateToShowModal < new Date()) {
            setShowModal(true);
        }
    }, []);

    const [report, resetReport] = useMessengerOnboardingReport(() => {
        setShowModal(true);
    });

    const done = report !== null;
    const hasError = reportHasError(report);
    const handleHideClickErrorModal = () => {
        setShowModal(false);
        resetReport();
    };

    const handleClickMessengerAddedModal = () => {
        setShowModal(false);
        resetReport();
        hide();
    };

    const showReminderToast = () => {
        messengerReminderToast(titleToast);
    };

    const computeNextDateToShowModal = useCallback(() => {
        setShowModal(false);
        const dateTmp = new Date();
        if (nextDateToShowModal) {
            dateTmp.setDate(dateTmp.getDate() + 15);
        } else {
            dateTmp.setDate(dateTmp.getDate() + 2);
        }
        setNextDateToShowModal(dateTmp);
        showReminderToast();
    }, [setShowModal, nextDateToShowModal, setNextDateToShowModal, showReminderToast]);

    return (
        <>
            {!done && showMessengerButton && (
                <AddMessengerModal
                    show={showModal}
                    onHideClick={computeNextDateToShowModal}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
            )}
            {done && !hasError && (
                <MessengerAddedModal
                    show={showModal}
                    onHideClick={handleClickMessengerAddedModal}
                    onButtonClick={handleClickMessengerAddedModal}
                />
            )}
            {done && hasError && (
                <MessengerErrorModal
                    show={showModal}
                    onHideClick={handleHideClickErrorModal}
                    onButtonClick={() => {
                        handleHideClickErrorModal();
                        IntercomWrapper.show();
                    }}
                    report={report}
                />
            )}
        </>
    );
};

interface InstagramModalProps {
    showInstagramButton: boolean;
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    hide: () => void;
}

const InstagramModal: FC<InstagramModalProps> = ({
    showInstagramButton,
    showModal,
    setShowModal,
    hide,
}) => {
    const { t } = useTranslation();
    const titleToast = t('messaging_add_instagram_information_modale_later_snackbar_title');
    const [timestamp, setNextDateToShowModal] = useLocalStorage<Date | null>(
        'nextDateToShowInstagramModal',
        null,
    );
    const nextDateToShowModal = timestamp ? new Date(timestamp) : null;

    const [report, resetReport] = useInstagramOnboardingReport(() => {
        setShowModal(true);
    });
    const done = report !== null;
    const hasSuccess = reportHasSuccess(report);
    const handleHideClickErrorModal = () => {
        setShowModal(false);
        resetReport();
        dataLayer.pushDict('click_discard_instagram_setup_error');
    };

    useEffect(() => {
        if (showInstagramButton && (!nextDateToShowModal || nextDateToShowModal < new Date())) {
            setShowModal(true);
        }
    }, [nextDateToShowModal]);

    const handleClickInstagramAddedModal = () => {
        setShowModal(false);
        resetReport();
        hide();
        dataLayer.pushDict('click_discard_instagram_setup_success');
    };

    const computeNextDateToShowModal = useCallback(() => {
        setShowModal(false);
        const dateTmp = new Date();
        if (nextDateToShowModal) {
            dateTmp.setDate(dateTmp.getDate() + 15);
        } else {
            dateTmp.setDate(dateTmp.getDate() + 2);
        }
        setNextDateToShowModal(dateTmp);
        instagramReminderToast(titleToast);
    }, [nextDateToShowModal, setShowModal, setNextDateToShowModal]);

    return (
        <>
            {!done && showInstagramButton && (
                <AddInstagramModal
                    show={showModal}
                    onHideClick={computeNextDateToShowModal}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
            )}
            {done && hasSuccess && (
                <InstagramAddedModal
                    show={showModal}
                    onHideClick={handleClickInstagramAddedModal}
                    onButtonClick={handleClickInstagramAddedModal}
                />
            )}
            {done && !hasSuccess && (
                <InstagramFailureModal
                    show={showModal}
                    onHideClick={handleHideClickErrorModal}
                    onButtonClick={() => {
                        handleHideClickErrorModal();
                        IntercomWrapper.show();
                    }}
                />
            )}
        </>
    );
};

const AddPartnersButtons: FC = () => {
    const { t } = useTranslation();
    const {
        messenger: [showMessengerButton, hideMessengerButton],
        instagram: [showInstagramButton, hideInstagramButton],
        instagram_onboarded: [showInstagramOnboardedButton],
        whatsapp_available: [showWhatsappAvailable],
    } = useShowPartnersButtons();
    const [showMessengerModal, setShowMessengerModal] = useState<boolean>(false);
    const [showInstagramModal, setShowInstagramModal] = useState<boolean>(false);

    const handleClickInstagramIcon = useCallback(() => {
        setShowInstagramModal(true);
        dataLayer.pushDict('click_instagram_self_onboarding_icon');
    }, [setShowInstagramModal, dataLayer]);

    return (
        <>
            {showMessengerButton && (
                <TooltipWrapper text={t('messaging_add_messenger_icon_tooltip')} yOffset={5}>
                    <AddMessengerIcon onClick={() => setShowMessengerModal(true)} />
                </TooltipWrapper>
            )}
            {!IS_IFRAME && showInstagramButton && (
                <TooltipWrapper text={t('messaging_add_instagram_icon_tooltip')} yOffset={5}>
                    <AddInstagramIcon onClick={handleClickInstagramIcon} />
                </TooltipWrapper>
            )}
            {!IS_IFRAME && showInstagramOnboardedButton && (
                <TooltipWrapper text={t('messaging_instagram_onboarded_icon_tooltip')} yOffset={5}>
                    <OnboardedInstagramIcon />
                </TooltipWrapper>
            )}
            <MessengerModal
                showMessengerButton={showMessengerButton}
                showModal={showMessengerModal}
                setShowModal={setShowMessengerModal}
                hide={hideMessengerButton}
            />
            {!IS_IFRAME && (
                <InstagramModal
                    showInstagramButton={showInstagramButton}
                    showModal={showInstagramModal}
                    setShowModal={setShowInstagramModal}
                    hide={hideInstagramButton}
                />
            )}
            {!IS_IFRAME && <WhatsappAvailableModal showWhatsappAvailable={showWhatsappAvailable} />}
        </>
    );
};

export default AddPartnersButtons;
