import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const ProviderLogoLinkWithInnerSvg = styled(Link)`
    height: 40px;
    width: 40px;
    margin: 12px;

    svg {
        width: 40px;
        height: 40px;
    }
`;
