import { useTranslation } from 'react-i18next';

import { MessagingSetting } from 'app/api/types/business_message_settings';
import { Choice } from 'app/api/types/user';
import AsyncSingleSelect from 'app/common/designSystem/components/molecules/AsyncSingleSelect';

import { SingleSelectContainer } from './SettingSingleSelect.styled';

type Props = {
    settingsChoices: Array<Choice>;
    selectedSetting: MessagingSetting | undefined;
    onChangeSelect: (value: Choice) => void;
    hasMoreSettings: boolean;
    fetchNextPage: () => void;
    setSettingSingleSelectQuery: (query: string) => void;
    settingSingleSelectQuery: string;
};

const SettingSingleSelect = ({
    settingsChoices,
    selectedSetting,
    onChangeSelect,
    hasMoreSettings,
    fetchNextPage,
    setSettingSingleSelectQuery,
    settingSingleSelectQuery,
}: Props) => {
    const { t } = useTranslation();
    const loadMore = (value: string) => {
        // loadMore is triggered in 2 cases:
        //  - scrolled to bottom of list and hasMore is true
        //  - user typed an input
        if (value === settingSingleSelectQuery) fetchNextPage();
        else setSettingSingleSelectQuery(value);
    };

    return (
        <SingleSelectContainer>
            <AsyncSingleSelect
                options={settingsChoices}
                selectedValue={
                    selectedSetting
                        ? {
                              label: `${selectedSetting.businessName} - ${selectedSetting.businessAddress}`,
                              value: selectedSetting.id.toString(),
                          }
                        : null
                }
                placeholder={t('message_settings_business')}
                onChange={onChangeSelect}
                hasMore={hasMoreSettings}
                loadMore={loadMore}
                isMandatory
                hideCross
            />
        </SingleSelectContainer>
    );
};

export default SettingSingleSelect;
