import { Tooltip } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { DoughnutLegendStyled } from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/AverageGrade/DoughnutLegend.styled';

import { DoughnutLegend } from './DoughnutLegend';

type Props = {
    label: string;
    color: string;
    count: string;
    isLoading: boolean;
};

export const DoughnutLegendTooltip = ({ label, color, count, isLoading }: Props) => {
    const { t } = useTranslation();

    return (
        <Tooltip
            id="tooltip"
            key={label + count}
            text={t(`Tooltip_RA_${label}`)}
            caret={false}
            placement="bottom"
        >
            <DoughnutLegendStyled>
                <DoughnutLegend label={label} color={color} count={count} isLoading={isLoading} />
            </DoughnutLegendStyled>
        </Tooltip>
    );
};
