import { useTranslation } from 'react-i18next';

import {
    BusinessImportFieldsType,
    BusinessInfosImportType,
    GooglePostStatusType,
} from 'app/api/types/business';
import { RoleValue } from 'app/api/types/user';
import { BusinessImportFieldSection } from 'app/businessConnection/components/modals/ModalCommonComponents/BusinessImportFieldSection';
import { SimpleImportFieldSection } from 'app/businessConnection/components/modals/ModalCommonComponents/SimpleImportFieldSection';
import { PROVIDER } from 'app/common/data/roles';

import { ImportSectionContainer } from './ImportSection.styled';

type Props = {
    businessImport: BusinessImportFieldsType;
    onChange: (arg0: string, arg1: BusinessInfosImportType | boolean) => void;
    role: RoleValue;
    businessFieldsDisabled: BusinessImportFieldsType;
    googlePostStatus: GooglePostStatusType;
    // Optional props
    businessWarning?: BusinessImportFieldsType;
    calledFromQuickConnect?: boolean;
    locationHasError?: boolean;
};

const businessWarningDefault = {
    businessInfos: {
        name: false,
        code: false,
        categories: false,
        addressFull: false,
        openHours: false,
        specificHours: false,
        description: false,
        phone: false,
        website: false,
        additionalUrls: false,
    },
    photos: false,
    attributes: false,
    services: false,
    googlePosts: false,
};

export const ImportSection = ({
    businessImport,
    onChange,
    role,
    businessFieldsDisabled,
    // @ts-ignore
    businessWarning = businessWarningDefault,
    calledFromQuickConnect = false,
    googlePostStatus,
    locationHasError = false,
}: Props) => {
    const { t } = useTranslation();
    if (locationHasError) {
        Object.keys(businessFieldsDisabled.businessInfos).forEach(
            v => (businessFieldsDisabled.businessInfos[v] = true),
        );
    }

    return (
        <ImportSectionContainer>
            <BusinessImportFieldSection
                businessInfos={businessImport.businessInfos}
                businessWarning={businessWarning.businessInfos}
                onChange={value => onChange('businessInfos', value)}
                infosDisabled={businessFieldsDisabled.businessInfos}
            />
            <SimpleImportFieldSection
                name={t('photos')}
                isChecked={businessImport.photos}
                isFilled={businessWarning.photos}
                onChange={value => onChange('photos', value)}
                disabled={locationHasError || businessFieldsDisabled.photos}
            />
            <SimpleImportFieldSection
                name={t('attributes')}
                isChecked={businessImport.attributes}
                isFilled={businessWarning.attributes}
                onChange={value => onChange('attributes', value)}
                description={
                    businessImport.attributes && !businessImport.businessInfos.categories
                        ? t('partner_connection_attributes_warning')
                        : ''
                }
                disabled={locationHasError || businessFieldsDisabled.attributes}
            />
            <SimpleImportFieldSection
                name={t('services')}
                isChecked={businessImport.services}
                isFilled={businessWarning.services}
                onChange={value => onChange('services', value)}
                disabled={locationHasError || businessFieldsDisabled.services}
            />
            <SimpleImportFieldSection
                name={t('menu')}
                isChecked={businessImport.foodMenu}
                isFilled={businessWarning.foodMenu}
                onChange={value => onChange('foodMenu', value)}
                disabled={locationHasError || businessFieldsDisabled.foodMenu}
            />
            {googlePostStatus.displayGooglePost && (
                <SimpleImportFieldSection
                    name={t('google_posts')}
                    isChecked={businessImport.googlePosts}
                    isFilled={businessWarning.googlePosts}
                    onChange={value => onChange('googlePosts', value)}
                    disabled={locationHasError || !googlePostStatus.hasGooglePost}
                    description={
                        businessImport.googlePosts && role === PROVIDER && calledFromQuickConnect
                            ? t('partner_connection_provider_warning')
                            : ''
                    }
                />
            )}
        </ImportSectionContainer>
    );
};
