import styled from '@emotion/styled';

export const LocationListContainer = styled.div`
    .fullscreen_connection_modal__locations_list {
        margin-top: 16px;

        &--grid {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-gap: 16px;
        }

        > *:last-child {
            margin-bottom: 8px;
        }
    }
`;
