import { useTranslation } from 'react-i18next';

import { SettingMenuItemsData } from 'app/pages/settingsV2/subComponents/SettingsMenuList/SettingsMenuList';
import {
    SETTINGS_PLATFORMS_CONNECTION_PATHNAME,
    SETTINGS_PLATFORMS_GOOGLE_VERIFICATION_PATHNAME,
    SETTINGS_PLATFORMS_PATHNAME,
} from 'app/routing/routeIds';

export const useSettingPlatformsMenu = (): SettingMenuItemsData => {
    const { t } = useTranslation();
    return {
        menus: [
            {
                label: t('settings_menu_platforms'),
                pathname: SETTINGS_PLATFORMS_PATHNAME,
                options: [
                    {
                        name: 'connection',
                        value: 'connection',
                        label: t('settings_menu_platforms_connections'),
                        pathname: SETTINGS_PLATFORMS_CONNECTION_PATHNAME,
                    },
                    {
                        name: 'google-verification',
                        value: 'google-verification',
                        label: t('settings_menu_platforms_google_verification'),
                        pathname: SETTINGS_PLATFORMS_GOOGLE_VERIFICATION_PATHNAME,
                    },
                ],
            },
        ],
    };
};
