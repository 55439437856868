import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import NotAccessibleForAdmin from 'app/common/components/placeholder/NotAccessibleForAdmin';
import { ADMIN } from 'app/common/data/roles';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import ApiKey from 'app/settingsManagement/components/ApiKey';

export const ApiKeysPage = () => {
    const { t } = useTranslation();
    const { data: me } = useMe();

    return (
        <>
            <DynamicPageHeader title={t('api_key_manager_title')} />
            {me?.role !== ADMIN ? <ApiKey /> : <NotAccessibleForAdmin />}
        </>
    );
};
