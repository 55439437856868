import { call, put, select, takeLatest } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import {
    FilterByBusinessState,
    filterByBusinessSelector,
} from 'app/common/components/businessModalDeprecated/reducers';
import {
    BusinessesState,
    groupIdFilterSelector,
    groupIdInFilterSelector,
    searchQuerySelector,
} from 'app/common/reducers/newBusinesses';
import {
    GET_SUBSCRIPTIONS_REQUEST,
    getSubscriptionsFailure,
    getSubscriptionsSuccess,
} from 'app/common/reducers/subscriptions';
import getGroupIdFilterParams from 'app/common/services/getGroupIdParams';
import { Saga } from 'app/common/types';

import { businessesSelector } from 'app/states/reducers';

import { V2Subscriptions } from '../../api/types';

export function* getSubscriptions(): Saga {
    try {
        const businessState: BusinessesState = yield select(businessesSelector);
        const filterByBusiness: FilterByBusinessState = yield select(
            filterByBusinessSelector,
        );

        const status = filterByBusiness.searchFilters?.status ?? null;
        const query = businessState
            ? searchQuerySelector(businessState)
            : undefined;
        const groupId = businessState
            ? groupIdFilterSelector(businessState)
            : null;
        const groupIdIn = businessState
            ? groupIdInFilterSelector(businessState)
            : null;

        const statsData: V2Subscriptions = yield call(
            api.business.getSubscriptions,
            {
                status,
                query,
                group_id__in: groupIdIn ? groupIdIn.toString() : null,
                ...getGroupIdFilterParams(groupId),
            },
        ) as any;

        yield put(
            getSubscriptionsSuccess(
                statsData.has_business,
                statsData.subscribed_to_pm,
                statsData.subscribed_to_rm,
                statsData.subscribed_to_rb,
                statsData.subscribed_to_bm,
            ),
        );
    } catch (error) {
        yield put(getSubscriptionsFailure(error));
    }
}

function* getSubscriptionsSaga(): Saga {
    yield takeLatest(GET_SUBSCRIPTIONS_REQUEST, getSubscriptions);
}

/**
 * Since we send subscriptions data to the Push Notifications Provider
 * we need to load subscriptions at boot
 */
export function* loadSubscriptionsAtBootSaga(): Saga {
    yield getSubscriptions();
}

export default getSubscriptionsSaga;
