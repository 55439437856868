import React from 'react';

import { push } from '@lagunovsky/redux-react-router';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { STATIC_BASE_URL } from 'app/config';
import {
    LoginFormButton,
    LoginFormContainer,
    LoginFormSuccessImage,
    LoginFormSuccessMessage,
} from 'app/connection/components/LoginForm/LoginForms.styled';
import { CONNECTION_PATH } from 'app/routing/routeIds';
import { resetLostPasswordForm } from 'app/states/connection/reducers/lostPassword';

import Form from './Form';

type Props = {
    isSuccess: boolean;
};

const LostPasswordForm = ({ isSuccess }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    if (!isSuccess) {
        return <Form />;
    }

    return (
        <LoginFormContainer>
            <LoginFormSuccessImage
                src={`${STATIC_BASE_URL}/images/app/send.svg`}
                alt="success"
            />
            <LoginFormSuccessMessage>
                <img
                    src={`${STATIC_BASE_URL}/images/common/custom-svg/success.svg`}
                    alt="success_pico"
                />
                {t('lost_password_success')}
            </LoginFormSuccessMessage>

            <LoginFormButton
                variant="primary"
                shape="cube"
                size="large"
                full
                onClick={() => {
                    dispatch(resetLostPasswordForm());
                    dispatch(push(CONNECTION_PATH));
                }}
                dataTrackId="lost_password_success_button"
                type="submit"
            >
                {t('connection_to_app')}
            </LoginFormButton>
        </LoginFormContainer>
    );
};

export default LostPasswordForm;
