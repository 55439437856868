import { ReviewObjectType } from 'app/api/types/review';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { useReviewsQueryContext } from 'app/reviewManagement/reviewList/hooks/useReviewsQueryContext';
import { ReviewObject } from 'app/states/reviews';

const FEEDBACK_RESULT_PANEL_ID = 'feedback_result_id';

const getFeedbackResult = (reviews: Array<ReviewObject>, feedbackId: number) =>
    reviews.find(
        ({ id, reviewObjectType }) =>
            reviewObjectType === ReviewObjectType.FEEDBACK_RESULT && feedbackId === id,
    );

export const useFeedbackResultPanel = () => {
    const { reviews } = useReviewsQueryContext();
    const [feedbackPanelId, setFeedbackPanelId] = useStateQueryParams(FEEDBACK_RESULT_PANEL_ID);

    const openPanel = (feedbackId: number) => {
        if (getFeedbackResult(reviews, feedbackId)) setFeedbackPanelId(`${feedbackId}`);
    };

    const closePanel = () => setFeedbackPanelId('');

    return {
        feedbackResult: getFeedbackResult(reviews, Number(feedbackPanelId)),
        openPanel,
        closePanel,
    };
};
