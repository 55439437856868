import { FC } from 'react';

import { Checkbox } from '@partoohub/ui';

import {
    ArrowLink,
    CheckableBarWrapper,
    CheckboxWrapper,
    LeftIconWrapper,
    LinkWrapper,
    TitleWrapper,
} from './CheckableBar.styled';

type IconProps = {
    disabled?: boolean;
};

interface CheckableBarProps {
    title: string;
    subLinkText: string;
    subLinkURL: string;
    leftIcon?: FC<IconProps>;
    toggleCheckbox: () => void;
    checked: boolean;
    disabled?: boolean;
    dataTrack?: string;
}
const CheckableBar = ({
    title,
    subLinkText,
    subLinkURL,
    leftIcon,
    toggleCheckbox,
    checked,
    disabled,
    dataTrack,
}: CheckableBarProps) => {
    const LeftIcon = leftIcon;

    const preventPropagation = event => {
        event.stopPropagation();
    };

    const onClick = () => {
        if (!disabled) toggleCheckbox();
    };

    return (
        <CheckableBarWrapper onClick={onClick} disabled={disabled}>
            {LeftIcon && (
                <LeftIconWrapper>
                    <LeftIcon disabled={disabled} />
                </LeftIconWrapper>
            )}
            <TitleWrapper disabled={disabled}>
                <p>{title}</p>
                <LinkWrapper onClick={preventPropagation} disabled={disabled}>
                    {subLinkText && subLinkURL && (
                        <a
                            href={subLinkURL}
                            target="_blank"
                            rel="noreferrer"
                            data-track={dataTrack}
                            data-intercom-target={dataTrack}
                        >
                            {subLinkText}
                            <ArrowLink className="fa-regular fa-arrow-right" />
                        </a>
                    )}
                </LinkWrapper>
            </TitleWrapper>
            <CheckboxWrapper>
                <Checkbox
                    dataTrackId="checkable_bar__checkbox"
                    aria-label=""
                    checked={checked}
                    onChange={onClick}
                    disabled={disabled}
                />
            </CheckboxWrapper>
        </CheckableBarWrapper>
    );
};

export default CheckableBar;
