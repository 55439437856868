import { greyColorObject } from '@partoohub/branding';
import { Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

export const NoPreview = () => {
    const { t } = useTranslation();
    return (
        <Stack justifyContent="center" alignItems="center" style={{ width: '100%' }}>
            <Text variant="heading3" color={greyColorObject.initial}>
                {t('no_preview')}
            </Text>
        </Stack>
    );
};
