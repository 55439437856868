import { useEffect, useRef, useState } from 'react';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import {
    AssignFilterOption,
    Conversation,
    StatusFilterOption,
    URLParams,
} from 'app/states/messaging';

import { useAssignParam } from './query/useAssignParam';
import { useStatusParam } from './query/useStatusParam';
import { useGetConversation } from './useGetConversation';
import { setConversationFiltersLocalStorage } from '../ConversationUtils';

export const useConversationFiltersSetup = () => {
    const { data: me } = useMe();
    const statusFilterParam = useStatusParam();
    const assignFilterParam = useAssignParam();
    const [, setStatusFilter] = useStateQueryParams(URLParams.STATUS);
    const [, setAssignFilter] = useStateQueryParams(URLParams.ASSIGN);

    const [conversationParamId, setConversationParamId] = useStateQueryParams(
        URLParams.CONVERSATION_ID,
    );
    const initialConversationParamId = useRef(conversationParamId);
    const { data: conversation, isLoading: hasFetchedInitialConversation } = useGetConversation(
        parseInt(initialConversationParamId.current),
    );
    const [hasSetupConversationFilters, setHasSetupConversationFilters] = useState<boolean>(
        conversationParamId === undefined || conversationParamId === '-1',
    );

    useEffect(() => {
        setStatusFilter(statusFilterParam);
        setAssignFilter(assignFilterParam);
        setConversationFiltersLocalStorage(statusFilterParam, assignFilterParam);
    }, [statusFilterParam, assignFilterParam]);

    useEffect(() => {
        if (hasFetchedInitialConversation) {
            return;
        }

        if (conversation && me) {
            const { conversationsStatusFilter, conversationsAssignFilter } =
                setupFetchConversationsFilters(conversation, assignFilterParam, me.id);
            setStatusFilter(conversationsStatusFilter);
            setAssignFilter(conversationsAssignFilter);
            setHasSetupConversationFilters(true);
        } else if (conversationParamId !== '-1') {
            setConversationParamId('');
            setHasSetupConversationFilters(true);
        }
    }, [hasFetchedInitialConversation]);

    return hasSetupConversationFilters;
};

const setupFetchConversationsFilters = (
    conversation: Conversation,
    defaultAssignFilter: AssignFilterOption,
    userId: string,
) => {
    const isClosed = conversation.is_expired || conversation.is_solved;
    const conversationsStatusFilter = isClosed
        ? StatusFilterOption.CLOSED
        : StatusFilterOption.OPEN;

    const conversationAssignFilter =
        userId === conversation.assigned_user_id
            ? AssignFilterOption.ME
            : AssignFilterOption.UNASSIGNED;

    const conversationsAssignFilter =
        defaultAssignFilter === AssignFilterOption.ALL
            ? defaultAssignFilter
            : conversationAssignFilter;

    return { conversationsStatusFilter, conversationsAssignFilter };
};
