import { UseQueryOptions, useQuery } from 'react-query';

import { V2Subscriptions } from 'app/api/types';
import { SubscriptionsStatsParams } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import { BUSINESS_SUBSCRIPTIONS } from 'app/common/data/queryKeysConstants';

export default function useBusinessSubscriptions(
    params: SubscriptionsStatsParams = {},
    useQueryOptions: UseQueryOptions<V2Subscriptions> = {},
) {
    const { data, isLoading } = useQuery(
        [BUSINESS_SUBSCRIPTIONS, params],
        () => api.business.getSubscriptions(params),
        useQueryOptions,
    );
    return {
        isLoading,
        hasBusinesses: data?.has_business ?? false,
        subscribedToRM: data?.subscribed_to_rm ?? false,
        subscribedToRB: data?.subscribed_to_rb ?? false,
        subscribedToBM: data?.subscribed_to_bm ?? false,
        subscribedToPM: data?.subscribed_to_pm ?? false,
    };
}
