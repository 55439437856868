import { useTranslation } from 'react-i18next';

import LoadingView from 'app/common/components/LoadingView';
import { useSettingMessagesMenu } from 'app/pages/settingsV2/hooks/Messages/useSettingMessagesMenu';

import { HeaderSubPages } from 'app/pages/settingsV2/subComponents';
import { SettingSkeleton } from 'app/pages/settingsV2/subComponents/SettingSkeleton';
import { Template } from 'app/pages/settingsV2/subComponents/Template/Template';

export const SettingMessages = () => {
    const { t } = useTranslation();
    const { menus, isLoading } = useSettingMessagesMenu();

    return (
        <Template header={<HeaderSubPages>{t('settings_menu_messages')}</HeaderSubPages>}>
            {isLoading ? <LoadingView /> : <SettingSkeleton sections={menus} />}
        </Template>
    );
};
