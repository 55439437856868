import styled from '@emotion/styled';

export const UserListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .infinite-scroll-component {
        overflow: inherit !important;
    }
`;
