import { V2BusinessData } from 'app/api/types/business';
import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';
import {
    getCities,
    getGroupIds,
    getOrgIds,
} from 'app/common/components/businessModal/context/businessModalFiltersReducer';
import useOrgFilteredGroupsV2 from 'app/common/components/businessModal/hooks/privateHooks/useOrgFilteredGroupsV2';
import useReduxFilteredBusinesses from 'app/common/components/businessModal/hooks/useReduxFilteredBusinesses';
import businessIsSelected from 'app/common/components/businessModal/utils/businessIsSelected';
import { useSectionMapping } from 'app/common/hooks/queries/useGroupsV2';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';

export interface BusinessWithSelected extends V2BusinessData {
    selected: boolean;
}

/**
 * Businesses to be displayed in the Business Modal.
 */
export default function useBusinessModalDisplayedBusinesses() {
    const enableNewGroups = useNewGroups();

    const { filters, selection, debouncedQuery, variation } =
        useBusinessModalContext();

    const cities = getCities(filters);
    const groupIds = getGroupIds(filters);
    const orgIds = getOrgIds(filters);

    // query for visible businesses
    const { query, businesses } = useReduxFilteredBusinesses({
        city: cities[0] ?? null,
        group_id__in:
            !enableNewGroups && groupIds.length ? groupIds.join(',') : null,
        groups: enableNewGroups && groupIds.length ? groupIds.join(',') : null,
        org_id__in: orgIds.length ? orgIds.join(',') : null,
        query: debouncedQuery,
    });

    const selectedCities = getCities(selection.filters);
    const selectedGroupIds = getGroupIds(selection.filters);
    const selectedOrgIds = getOrgIds(selection.filters);

    // query for selected businesses, only used to know if a business is selected...
    const { businesses: loadedSelectedBusinesses } = useReduxFilteredBusinesses(
        {
            city: selectedCities[0] ?? null,
            group_id__in:
                !enableNewGroups && selectedGroupIds.length
                    ? selectedGroupIds.join(',')
                    : null,
            groups:
                enableNewGroups && selectedGroupIds.length
                    ? selectedGroupIds.join(',')
                    : null,
            org_id__in: selectedOrgIds.length ? selectedOrgIds.join(',') : null,
            query: selection.query,

            // both queries are ordered by name so that we have some
            // chance to find the same businesses in visible and
            // selected businesses so we know they are selected without
            // relying on client side ts-query
        },
        {
            enabled: selection.selectAll, // useful only if we selected a whole query
        },
    );

    const foundSelectedBusinessIds =
        loadedSelectedBusinesses?.map(business => business.id) ?? [];

    // Generate groupV2 to section mapping
    const { sections } = useOrgFilteredGroupsV2(
        {},
        { enabled: variation === 'all' && enableNewGroups },
    );
    const groupV2ToSection = useSectionMapping(sections);

    // Add selected flag to businesses
    const returnedBusinesses: Array<BusinessWithSelected> | undefined =
        businesses?.map(business => ({
            ...business,
            selected: businessIsSelected(
                selection,
                business,
                selection.selectAll ? foundSelectedBusinessIds : [],
                groupV2ToSection,
                !!enableNewGroups,
            ),
        }));

    return { query, businesses: returnedBusinesses };
}
