import { lowerCase } from 'lodash-es';
import { connect } from 'react-redux';

import { V2FormattedAccountData } from 'app/api/types/account';
import { partnerToPartnerResourceName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import {
    accountResourcesDetailsSelector,
    getAccountResourcesDetails,
    requestStatusSelector,
} from 'app/states/knowledge/reducers/businessConnection/accounts';
import { AppState } from 'app/states/reducers';

import AccountRowComponent from './AccountRow';

type OwnProps = {
    oauthAccount: V2FormattedAccountData;
};

const mapStateToProps = ({ accounts }: AppState, { oauthAccount }: OwnProps) => {
    const details = accountResourcesDetailsSelector(accounts, oauthAccount.id);
    return {
        accountsCount: details ? details.accountsCount : 0,
        locationsCount: details ? details.locationsCount : 0,
        status_is_connected: details ? lowerCase(details.status) === 'ok' : false,
        isFetchingDetails: requestStatusSelector(accounts, oauthAccount.id) === 'RUNNING',
        partner: oauthAccount.partner,
    };
};

const mapDispatchToProps = (dispatch: any, { oauthAccount }: OwnProps) => ({
    fetchDetails: () =>
        dispatch(
            getAccountResourcesDetails(
                oauthAccount.id,
                // @ts-ignore
                partnerToPartnerResourceName[oauthAccount.partner],
            ),
        ),
});

const AccountRow = connect(mapStateToProps, mapDispatchToProps)(AccountRowComponent);

export default AccountRow;
