import { BusinessDetailsData } from 'app/api/types/publisher_state';
import { FormattedPublisherBusinessDetails } from 'app/states/presence/reducers/listing/businessDetails/reducer';

export const businessDetailsFormatter = (
    businessDetails: BusinessDetailsData,
): FormattedPublisherBusinessDetails => ({
    businessId: businessDetails.business_id,
    businessName: businessDetails.business_name,
    businessFormattedAddress: businessDetails.business_formatted_address,
    errorType: businessDetails.error_type || undefined,
    businessUrl: businessDetails.business_url || undefined,
});
