import { useEffect, useState } from 'react';

import { Stack, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import BusinessModalButton from 'app/common/components/businessModal';
import { setSelectedBusinessIdsModalActions } from 'app/common/components/businessModal/context/businessModalCustomActions';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import useSelectedBusinessesTitle from 'app/common/components/businessModal/hooks/useSelectedBusinessesTitle';
import ButtonSelect from 'app/common/designSystem/components/molecules/ButtonSelect';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import useEditGroup from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/hooks/groups/useEditGroup';
import useGroup from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/hooks/groups/useGroup';
import useSection from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/hooks/sections/useSection';
import { GroupsSettingsQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';
import groupsTracking from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/groupsTracking';

import EditForm from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm/EditForm';
import { EditFormFooter } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm/EditForm/EditForm.styled';

import { GridContainer } from './EditGroupContent.styled';

type Props = {
    closeModal: () => void;
};

const EditGroupContent = ({ closeModal }: Props) => {
    const { t } = useTranslation();
    const { data: me } = useMe();

    const [edit] = useStateQueryParams(GroupsSettingsQueryKeys.EDIT);
    const [sectionId, groupId] = edit.split(',');

    const { data: section } = useSection(sectionId);
    const { data: group } = useGroup(sectionId, groupId);

    const { title } = useSelectedBusinessesTitle(true);
    const businessesCount = useSelectedBusinessesCount();

    const [groupName, setGroupName] = useState<string>(group?.name ?? '');

    useEffect(() => {
        setGroupName(group?.name ?? '');
    }, [group?.name]);

    useEffect(() => {
        if (section && !section.can_edit) {
            closeModal();
        }
    }, [section?.can_edit]);

    const dispatch = useDispatch();

    // Change the selected businesses in the business modal
    useEffect(() => {
        if (group?.business_ids) {
            dispatch(setSelectedBusinessIdsModalActions(group.business_ids));
        }
    }, [group?.business_ids]);

    const editGroup = useEditGroup(sectionId, groupId, () => {
        closeModal();
        groupsTracking.saveGroupModal(me?.role, businessesCount ?? 0);
    });
    // @ts-ignore
    const error = editGroup.error?.response?.data?.errors?.json?.name;

    const businessFilters = useBusinessModalFilters(true);

    const editGroupAction = () => {
        editGroup.mutate({
            ...businessFilters,
            name: groupName,
        });
    };

    return (
        <>
            <EditForm
                title={groupName}
                subtitle={`${section?.name ?? ''} · ${
                    group?.business_ids
                        ? t('business_modal_button_business_count_label', {
                              count: group?.business_count ?? 0,
                          })
                        : ''
                }`}
                icon={<i className="fa-light fa-folder" />}
                description={t('group_explanation_subtitle')}
            >
                <GridContainer>
                    <TextInput
                        dataTrackId="group_modal_field"
                        label={t('group_modal_field')}
                        value={groupName}
                        onChange={value => setGroupName(value ?? '')}
                        error={!!error}
                        notice={error ?? ''}
                    />
                    <TextInput
                        dataTrackId="section_modal_field"
                        label={t('section_modal_field')}
                        value={section?.name ?? ''}
                        onChange={() => undefined}
                        disabled
                    />
                </GridContainer>
                <Stack style={{ marginTop: '24px' }}>
                    <BusinessModalButton
                        noBusinessLimit
                        isSelectionMode
                        button={<ButtonSelect label={title} placeholder={t('select_businesses')} />}
                    />
                </Stack>
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={editGroup.isLoading}
                    hasError={!!editGroup.error}
                    onClick={editGroupAction}
                    dataTrackId="edit_group_save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default EditGroupContent;
