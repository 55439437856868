import i18n from 'app/common/translations/i18n';

// markup should be of the form '@[toto](Toto)'
export function markupToDisplay(markupText: string) {
    // match '(Toto)' part of the markups
    const displayedTextMatch = /\[[^\]]+\]/g.exec(markupText);

    if (displayedTextMatch) {
        const displayedTextMatchValue = displayedTextMatch[0];
        return `@${displayedTextMatchValue.slice(1, -1)}`;
    }
    return '';
}

// textAreaPosition is the position of the caret in the <textarea>
// valueText is the value of the text we pass to the third party react-mentions
// Note that react-mentions transforms the markups of the form '@[id](display)' to
// '  @display  ' before rendering it in the <textarea>. See MentionTextArea.jsx
export function textAreaPositionToValuePosition(
    textAreaPosition: number,
    valueText: string,
    trigger: string,
) {
    // match markups of the form '@[toto](Toto)'
    const markupRegexp = new RegExp(`${trigger}\\[.+?\\]\\(.+?\\)`, 'g');
    let valueTextPosition = 0;
    let textAreaCharactersRemaining = textAreaPosition;

    while (textAreaCharactersRemaining > 0) {
        const markupMatch = markupRegexp.exec(valueText);

        if (
            markupMatch === null ||
            markupMatch.index >= valueTextPosition + textAreaCharactersRemaining
        ) {
            return valueTextPosition + textAreaCharactersRemaining;
        }

        const displayedText = markupToDisplay(markupMatch[0]);
        textAreaCharactersRemaining -=
            displayedText.length + (markupMatch.index - valueTextPosition);
        valueTextPosition = markupMatch.index + markupMatch[0].length;
    }

    return valueTextPosition;
}

export function translateMarkup(markupText: string, trigger = '@') {
    const markupRegexp = new RegExp(`${trigger}\\[(.+?)\\]\\(.+?\\)`, 'g');

    // p1 is the first group in the regex, here, the part between []
    function translateDisplay(match, p1) {
        return match.replace(p1, i18n.t(p1));
    }

    return markupText.replace(markupRegexp, translateDisplay);
}
