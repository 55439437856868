import { connect } from 'react-redux';

import { testPath } from 'app/common/data/routeIds';
import { isSidebarCollapsed } from 'app/common/reducers/global';
import { getGroupWithoutCount } from 'app/common/reducers/groups';
import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';
import { AppState } from 'app/states/reducers';
import { locationSelector } from 'app/states/router';

import ReactPage from './ReactPage';

const mapStateToProps = (state: AppState) => {
    const { global } = state;
    return {
        isSidebarCollapsed: isSidebarCollapsed(global),
        pathname: locationSelector(state).pathname,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    loadUserGroupsData: pathname => {
        // For every page but Business List, we need to load the groups without count
        if (!testPath(VISIBILITY_LOCATION_PATH, pathname)) {
            dispatch(getGroupWithoutCount());
        }
    },
});

const ReactPageComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReactPage);

export default ReactPageComponent;
