import React from 'react';

import { useController } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import Section from 'app/businessEditV2/oldFromEditV1/Section';
import AccessGroupModalButton from 'app/common/components/AccessGroupModal';

type Props = {
    name: string;
    control: Control<any>;
};

const NewGroupsSection = ({ name, control }: Props) => {
    const { t } = useTranslation();
    const {
        field: { value, onChange },
    } = useController({ control, name });

    return (
        <Section title={t('create_business_affiliated_groups')}>
            <AccessGroupModalButton value={value} onChange={onChange} />
        </Section>
    );
};

export default NewGroupsSection;
