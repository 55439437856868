import { Button, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { cloneDeep } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { PlaceActionLinkUrlTypeForm } from 'app/api/types/placeActionLinks';

import { PlaceActionLinkUrl } from './PlaceActionLinkUrl';

import { PlaceActionLinkUrlsContainer } from './PlaceActionLinkUrls.styled';

type Props = {
    urls: Array<PlaceActionLinkUrlTypeForm>;
    onChange: (urls: Array<PlaceActionLinkUrlTypeForm>) => void;
    errors?: string | Record<string, string>;
    disabled?: boolean;
};

export const PlaceActionLinkUrls = ({ urls, onChange, errors, disabled }: Props) => {
    const { t } = useTranslation();

    const urlsToDisplay = urls.length
        ? urls
        : [{ value: '', is_preferred: false, is_editable: true }];

    const onUrlChange = (url: PlaceActionLinkUrlTypeForm, index: number) => {
        const result = cloneDeep(urlsToDisplay);
        result[index] = url;
        onChange(result);
    };

    const onChangeIsPreferred = (currentIndex: number) => {
        let result = cloneDeep(urlsToDisplay);
        result = result.map((url, index) => ({
            ...url,
            is_preferred: index === currentIndex,
        }));

        onChange(result);
    };

    const onUrlDelete = (index: number) => {
        const result = cloneDeep(urlsToDisplay);
        result.splice(index, 1);
        onChange(result);
    };

    const onUrlAdd = () => {
        const result = cloneDeep(urlsToDisplay);
        const newUrl = { value: '', is_preferred: false, is_editable: true };
        result.push(newUrl);
        onChange(result);
    };

    const getErrorMessage = (errors, index) => {
        if (typeof errors === 'string') {
            return t(errors);
        }

        if (typeof errors === 'object' && errors !== null) {
            const error = errors[index]?.value || '';
            return error.startsWith('URL invalid') ? t('invalid_url_error') : t(error);
        }

        return '';
    };

    return (
        <PlaceActionLinkUrlsContainer>
            {urlsToDisplay.map((url, index) => (
                <PlaceActionLinkUrl
                    url={url}
                    onChange={url => onUrlChange(url, index)}
                    onUrlDelete={() => onUrlDelete(index)}
                    onChangeIsPreferred={() => onChangeIsPreferred(index)}
                    index={index}
                    key={index}
                    error={getErrorMessage(errors, index)}
                    disabled={disabled}
                />
            ))}
            {!disabled && (
                <Button
                    dataTrackId="add_link__button"
                    onClick={onUrlAdd}
                    variant="primary"
                    shape="cube"
                    icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                    appearance="outlined"
                    disabled={disabled}
                >
                    {t('add_link')}
                </Button>
            )}
        </PlaceActionLinkUrlsContainer>
    );
};
