import styled from '@emotion/styled';
import { Skeleton } from '@partoohub/ui/dist/components/Skeleton';

export const AutomaticMessageNameInput = styled.input`
    border: 0;
    font-size: 26px;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: -1px;
    border-bottom: 1px solid;
    border-bottom-color: ${({ theme }) => theme.colors.default.dark};
    outline: none;
    padding: 0;
    // Prevent the input from growing too big.
    max-width: 300px;
    // ...or too small.
    min-width: 2ch;
`;

export const HiddenAutomaticMessageName = styled.span`
    position: absolute;
    left: -9999px;
    visibility: hidden;
    // This is needed to handle whitespaces correctly.
    white-space-collapse: preserve;
`;

export const NameSkeleton = styled(Skeleton)`
    height: 32px;
    > div {
        height: 32px;
    }
`;
