import { useMemo } from 'react';

import { TextInput, TextInputProps } from '@partoohub/ui';

import { Control, useController } from 'react-hook-form';

import { ExplanationExtended } from 'app/common/components/form/ExplanationComponent/ExplanationComponent';
import {
    PublisherErrors,
    PublishersInfo,
} from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithPublisherList';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    onChangeValue?: (value?: string) => string | void | null;
    textTooltip?: string;
    errorMessage?: string;
    hasError?: boolean;
    hasWarning?: boolean;
    publisherErrors?: PublisherErrors;
    publishersInfo?: PublishersInfo;
};

type OmitProps = 'onChange' | 'value';

export const ControlledTextInput = ({
    name,
    control,
    onChangeValue = value => value,
    disabled = false,
    textTooltip,
    errorMessage,
    publisherErrors = {},
    publishersInfo = {},
    hasError,
    hasWarning,
    ...props
}: Omit<TextInputProps<any>, OmitProps> & ControlledComponent) => {
    const {
        field: { ref: _ref, onChange, ...usedFields },
    } = useController({ control, name });

    const handleChange = (value: string) => {
        onChange(onChangeValue(value));
    };

    const ExplanationComponent = useMemo(
        () => (
            <ExplanationExtended
                hasWarning={!!hasWarning}
                publisherErrors={publisherErrors}
                publishersInfo={publishersInfo}
                errorMessage={errorMessage}
                disabled={!!disabled}
            />
        ),
        [hasWarning, publisherErrors, publishersInfo, errorMessage, disabled],
    );

    const InputComponent = (
        <TextInput
            {...props}
            {...usedFields}
            disabled={disabled}
            onChange={handleChange}
            error={hasError}
            warning={hasWarning}
            notice={ExplanationComponent}
        />
    );

    return (
        <>
            {textTooltip ? (
                <TooltipWrapper text={textTooltip} position="bottom-start">
                    <div style={{ flex: 1 }}>{InputComponent}</div>
                </TooltipWrapper>
            ) : (
                InputComponent
            )}
        </>
    );
};
