import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';

export default function useSubscriptionRequest(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    return useMutation(() => api.subscription.subscriptionUpsellPost(), {
        onSuccess,
        onError,
    });
}
