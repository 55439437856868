import { Filters, Option } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import { InteractionTagType } from 'app/api/types/interactionTag';
import api from 'app/api/v2/api_calls';
import { REVIEW_TAGS } from 'app/common/data/queryKeysConstants';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { WITHOUT_TAG_AS_STRING } from 'app/states/reviews';

export const TagFilter = () => {
    const { t } = useTranslation();
    const [tagFilter, setTagFilter] = useStateQueryParams('tags');
    const selectedTags =
        tagFilter !== ''
            ? tagFilter.split(',').reduce(
                  (allTags, tag) => {
                      return { ...allTags, [tag]: true };
                  },
                  {} as Record<string, boolean>,
              )
            : {};

    const { data: { tags } = { tags: [] }, isFetched } = useQuery(
        REVIEW_TAGS,
        // Getting all tags at once
        // (there are max 100 tags per orgs, therefore we set the perPage param to 100)
        () => api.interactionTag.getInteractionTags('', 1, 100),
    );

    const choices: Array<Option> = tags
        ? [
              {
                  label: t('review_tag_no_tag_filter'),
                  name: WITHOUT_TAG_AS_STRING,
                  value: WITHOUT_TAG_AS_STRING,
              },
              ...tags.map((tag: InteractionTagType) => ({
                  label: `${tag.label}`,
                  name: tag.id.toString(),
                  value: tag.id.toString(),
              })),
          ]
        : [];

    const onFilterChange = (selected: Record<string, boolean>) => {
        setTagFilter(
            Object.entries(selected)
                .filter(([, value]) => !!value)
                .map(([tag]) => tag)
                .join(','),
        );
    };

    return isFetched && tags.length > 0 ? (
        <div style={{ width: '100px' }}>
            <Filters
                multiple
                dataTrackId=""
                placeholder="Tags"
                position="bottom"
                menuListArray={[{ options: choices }]}
                selectedItems={selectedTags}
                maxHeight={300}
                onChange={onFilterChange}
            />
        </div>
    ) : (
        <></>
    );
};
