import { reduce } from 'lodash-es';

import { V2FormattedLocationData, V2LocationData } from 'app/api/types/account';

import formatLocation from './formatLocation';

type FormattedLocations = {
    byId: Record<string, V2FormattedLocationData>;
    ids: Array<string>;
};

const formatLocations = (
    locations: Array<V2LocationData>,
): FormattedLocations =>
    reduce(
        locations,
        (obj: FormattedLocations, location: V2LocationData) => ({
            byId: { ...obj.byId, [location.id]: formatLocation(location) },
            ids: [...obj.ids, location.id],
        }),
        {
            byId: {},
            ids: [],
        },
    );

export default formatLocations;
