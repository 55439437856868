import { css } from '@emotion/react';
import { FontAwesomeIconsPartooUsed, IconPrefix, Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { sumValues } from 'app/common/utils/sumValues';

import { StyledCard } from './Cards.styled';
import { StyledNegativeIcon, StyledPositiveIcon, StyledTag } from './Tags.styled';
import { useReviewTagMetrics } from '../../../hooks/useMetrics';
import { useReviewAnalyticsDefaultFilter } from '../../../hooks/useReviewAnalyticsDefaultFilter';

export const TagsCard = () => {
    const { t } = useTranslation();
    const metrics = useReviewTagMetrics();
    const { isDefaultDateFilter } = useReviewAnalyticsDefaultFilter();

    const tagScores =
        metrics?.data
            .map(({ dimension, metrics }) => {
                return {
                    tag: dimension,
                    score: metrics.average_rating * sumValues(metrics.rating_distribution),
                };
            })
            .sort((a, b) => a.score - b.score) ?? [];

    return (
        <StyledCard>
            <Text variant="bodyMBold">
                {t('review_analytics_graph_tags')}{' '}
                {isDefaultDateFilter && (
                    <Text as="span" color="secondary">
                        {' '}
                        ({t('date_criteria_last_twelve_months')})
                    </Text>
                )}
            </Text>

            <Stack
                css={css`
                    width: 100%;
                `}
                gap="8px"
            >
                <Stack direction="row" alignItems="center" gap="8px">
                    <StyledPositiveIcon
                        icon={[FontAwesomeIconsPartooUsed.faFaceLaugh, IconPrefix.REGULAR]}
                        color="success"
                    />
                    <StyledTag variant="bodyMBold">
                        {tagScores.length >= 1 ? tagScores[0].tag.name : '-'}
                    </StyledTag>
                    <StyledTag variant="bodyMBold">
                        {tagScores.length >= 3 ? tagScores[1].tag.name : '-'}
                    </StyledTag>
                </Stack>
                <Stack direction="row" alignItems="center" gap="8px">
                    <StyledNegativeIcon
                        icon={[FontAwesomeIconsPartooUsed.faFaceAngry, IconPrefix.REGULAR]}
                        color="danger"
                    />
                    <StyledTag variant="bodyMBold">
                        {tagScores.length >= 2 ? tagScores[tagScores.length - 1].tag.name : '-'}
                    </StyledTag>
                    <StyledTag variant="bodyMBold">
                        {tagScores.length >= 4 ? tagScores[tagScores.length - 2].tag.name : '-'}
                    </StyledTag>
                </Stack>
            </Stack>
        </StyledCard>
    );
};
