import styled from '@emotion/styled';

export const SelectButtonContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 12px;
    padding: 8px 16px;
    width: auto;
    height: 30px;
    border-radius: 15px;
    background-color: #f4f5f9;
    font-weight: bold;
    justify-content: center;
`;

export const StyledSelect = styled.select`
    height: 17px;
    color: ${({ theme }) => theme.colors.default.initial};
    border: none;
    background-color: transparent;
    margin-left: -5px;

    cursor: pointer;
    user-select: none;

    &:focus {
        outline: 0;
    }
`;
