import styled from '@emotion/styled';

export const StyledReviewCardContent = styled.div`
    grid-area: content;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 8px 0 16px 0;
`;

export const StyledReviewComment = styled.div`
    white-space: pre-wrap;
`;

export const StyledSeeDetailsButton = styled.div`
    margin: 4px 0 -8px 0;
`;
