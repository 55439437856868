import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const SwitchBox = styled.div<{ isScheduled: boolean }>`
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 24px;
    margin-bottom: ${({ isScheduled }) => (isScheduled ? '0' : '24px')};
`;

export const SwitchBoxDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const PostPublishScheduleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    > div:first-of-type {
        width: 75%;
    }
    gap: 16px;
`;
