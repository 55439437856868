import { List } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import { FeedbackFormListItem } from './FeedbackFormListItem';
import { useFeedbackFormList } from './hooks';

export const FeedbackFormList = () => {
    const { t } = useTranslation();
    const [forms, { isLoading }] = useFeedbackFormList();

    if (isLoading) {
        return (
            <List columns={[{ label: t('feedback_form_list__column_title') }, { label: '' }]}>
                {Array(5)
                    .fill(undefined)
                    .map(() => (
                        <List.Row dataTrackId="" id="0" disableAnimationOnHover>
                            <LoadingRectangle recWidth="100%" recHeight="18px" />
                            <LoadingRectangle recWidth="100%" recHeight="18px" />
                        </List.Row>
                    ))}
            </List>
        );
    }

    return (
        <List columns={[{ label: t('feedback_form_list__column_title') }, { label: '' }]}>
            {forms.map(form => (
                <FeedbackFormListItem form={form} key={form.id} />
            ))}
        </List>
    );
};
