import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

import { FunnelModalBody } from 'app/common/components/FunnelModal/FunnelModal.styled';

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 28px;
    max-width: 100%;
`;

export const TextContainer = styled.div`
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 300px;
    min-width: 300px;
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 48px;
`;

export const Picto = styled.img`
    width: 254px;
    margin: auto;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`;

export const DescriptionTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
`;

export const ModalBodyContainer = styled(FunnelModalBody)`
    display: flex;
`;

export const CenteredBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    margin: auto;
`;

export const CircleContainer = styled(Text)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background-color: ${({ theme }) => theme.colors.primary.alpha};
`;
