import React from 'react';

import { Button } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'app/config';

import {
    DeleteModalButtons,
    DeleteModalContainer,
    DeleteModalDescription,
    DeleteModalImage,
    DeleteModalTitle,
} from '../../DeleteModals.styled';

type Props = {
    closeModal: () => void;
    error: string;
};

const DeleteGroupErrorContent = ({ closeModal, error }: Props) => {
    const { t } = useTranslation();

    return (
        <DeleteModalContainer>
            <DeleteModalImage>
                <img
                    src={`${STATIC_BASE_URL}/images/app/confirm_category_remap.svg`}
                    alt="error_section"
                />
            </DeleteModalImage>
            <DeleteModalTitle>{t('delete_group_modal_error_title')}</DeleteModalTitle>
            <DeleteModalDescription>{t(error)}</DeleteModalDescription>
            <DeleteModalButtons>
                <Button
                    dataTrackId="delete_group_error_ok"
                    variant="primary"
                    onClick={closeModal}
                    size="large"
                    shape="cube"
                >
                    {t('delete_modal_error_button')}
                </Button>
            </DeleteModalButtons>
        </DeleteModalContainer>
    );
};

export default DeleteGroupErrorContent;
