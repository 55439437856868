import { UseFormWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { useTranslation } from 'react-i18next';

import { ControlledPasswordField } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm/hookControllerWrapper/ControlledPasswordField';
import ControlledToggle from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm/hookControllerWrapper/ControlledToggle';

import AuthorizationService from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/authorization';

import { PasswordSubformContainer, ToggleContainer, ToggleText } from './PasswordSubform.styled';

type Props = {
    control: Control<any>;
    watch: UseFormWatch<any>;
    authorizationService: AuthorizationService;
    displaySsoToggle: boolean;
    errorMessage?: string;
    hasError?: boolean;
};

const PasswordCreationSubform = ({
    control,
    watch,
    authorizationService,
    displaySsoToggle,
    ...props
}: Props) => {
    const { t } = useTranslation();

    const sendInvitation = watch('sendInvitation');
    const ssoOnly = watch('ssoOnly');

    const displaySendInvitation = !displaySsoToggle || !ssoOnly;
    const displayPassword = displaySendInvitation && !sendInvitation;

    return (
        <PasswordSubformContainer>
            {displaySsoToggle && (
                <ToggleContainer>
                    <ControlledToggle name="ssoOnly" control={control} />
                    <ToggleText>{t('user_connect_with_sso_only')}</ToggleText>
                </ToggleContainer>
            )}
            {displaySendInvitation && (
                <ToggleContainer>
                    <ControlledToggle name="sendInvitation" control={control} />
                    <ToggleText>
                        {sendInvitation
                            ? t('invitation_is_going_to_be_sent')
                            : t('invitation_is_not_going_to_be_sent')}
                    </ToggleText>
                </ToggleContainer>
            )}
            {displayPassword && (
                <ControlledPasswordField
                    dataTrackId="password"
                    name="password"
                    watch={watch}
                    control={control}
                    required
                    {...props}
                />
            )}
        </PasswordSubformContainer>
    );
};

export default PasswordCreationSubform;
