import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, TransparencyEnum, greyColorObject } from '@partoohub/branding';

import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

type DropZoneWrapperProps = {
    fullSize?: boolean;
    isCompact?: boolean;
    inFoodMenuModal?: boolean;
    disabled?: boolean;
    hasError?: boolean;
};

type UploadIconWrapperProps = {
    isSuccessful?: boolean;
    hasError?: boolean;
};

export const DropzoneWrapperSelectorId = 'DropzoneWrapper';

export const ReactDropZoneStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    border: 'none',
};

export const DropZoneWrapper = styled.div<DropZoneWrapperProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: ${props => (props.inFoodMenuModal || props.isCompact ? '160px;' : '250px;')};
    padding: 10px;
    background-color: ${greyColorObject.light};
    border-width: 1px;
    border-style: dashed;
    border-radius: 4px;
    color: ${greyColorObject.initial};
    font-size: 14px;
    text-align: center;
    transition:
        color ease-in-out 0.3s,
        border-color ease-in-out 0.3s;

    ${props =>
        props.inFoodMenuModal &&
        css`
            height: 160px;
            width: 160px;
            min-width: 160px;
            max-height: 160px;
        `}

    ${props =>
        props.isCompact &&
        css`
            height: 100%;
        `}

    ${({ disabled, theme, hasError }) =>
        disabled
            ? css`
                  opacity: 0.4;
                  border-color: ${DISABLED_COLOR};
                  cursor: initial;
              `
            : css`
                  border-color: ${hasError ? theme.colors.danger.initial : greyColorObject.initial};
                  &:hover {
                      border-color: ${theme.colors.primary.initial};
                      cursor: pointer;
                  }
              `}

    ${props =>
        props.fullSize &&
        css`
            flex-direction: column;
            height: 100%;
            background-color: ${HEX_COLORS.white};
            cursor: pointer;
            user-select: none;
        `}
`;

export const DroppedFiles = styled.span<DropZoneWrapperProps>`
    max-width: 75%;
    color: ${({ theme }) => theme.colors.primary.initial};
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: ${TransparencyEnum.DISABLED};
            pointer-events: none;
        `};
`;

export const DropZoneLabel = styled.div<DropZoneWrapperProps>`
    max-width: 75%;
    margin: 10px 0;
    margin: ${props => (props.inFoodMenuModal || props.hasError ? '10px 0 0 0;' : '10px 0;')};
    font-weight: bold;
    font-size: ${props => (props.inFoodMenuModal ? '13px;' : '18px;')};
    color: ${({ hasError, theme }) => (hasError ? theme.colors.danger.initial : HEX_COLORS.black)};
    ${props => (props.disabled ? null : 'cursor: pointer;')};
`;

export const DropZoneExplications = styled.div`
    max-width: 75%;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const DropZoneErrors = styled.div`
    max-width: 75%;
    margin: 10px 0;

    .drop_zone_text_wrapper {
        color: ${({ theme }) => theme.colors.danger.initial} !important;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
`;

export const DropZoneTextWrapper = styled.div`
    transition: color ease-in-out 0.3s;
`;

export const FileExtensions = styled.span`
    text-transform: uppercase;
`;

export const UploadIconWrapper = styled.div<UploadIconWrapperProps>`
    width: 56px;
    height: 56px;

    svg {
        transition: fill ease-in-out 0.3s;
    }

    ${({ isSuccessful, hasError, theme }) => {
        if (isSuccessful) {
            return css`
                color: ${theme.colors.success.initial};

                svg * {
                    fill: currentColor;
                }
            `;
        } else if (hasError) {
            return css`
                color: ${theme.colors.danger.initial};

                svg * {
                    fill: currentColor;
                }
            `;
        }
    }}
`;

export const SuccessMessage = styled.div`
    max-width: 75%;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.success.initial};
    font-weight: bold;
`;
