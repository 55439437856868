import React, { useContext, useEffect, useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix, List } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { V2FormattedLocationData } from 'app/api/types/account';
import FunnelContext from 'app/common/components/funnel/FunnelContext';
import {
    FullPageContainer,
    SubTitle,
    Title,
} from 'app/common/components/funnel/FunnelModal.styled';
import { LocationCardAddressContainer } from 'app/onboarding/AddAddressesPage/AddAddressesPage.styled';

import { FullButton, FullButtonWrapper } from 'app/onboarding/SelectionPage/SelectionPage.styled';

import LocationCardAddress from './components/LocationCardAddress';

const AddAddressesPage = () => {
    const { t } = useTranslation();
    const funnel = useContext(FunnelContext);

    const { selectedLocations = [], editedLocations = [] } = funnel.store;
    const locationsWithoutAddress = selectedLocations.filter(
        (location: V2FormattedLocationData) => !location.addressDetails,
    );
    const [toEditLocations, setToEditLocations] =
        useState<Array<V2FormattedLocationData>>(locationsWithoutAddress);
    const [displayRequired, setDisplayRequired] = useState<boolean>(false);

    if (toEditLocations.length === 0) {
        funnel.navigate('no_address_page');
    }

    const onContinueClick = () => {
        if (toEditLocations.length == editedLocations.length) {
            funnel.navigate('summary_page');
        } else {
            setDisplayRequired(true);
        }
    };

    useEffect(() => {
        if (toEditLocations.length == editedLocations.length) {
            setDisplayRequired(false);
        }
    }, [editedLocations]);

    return (
        <FullPageContainer>
            <Title>{t('onboarding_add_addresses_page_title')}</Title>
            <SubTitle>{t('onboarding_add_addresses_page_sub_title')}</SubTitle>

            <LocationCardAddressContainer>
                <List
                    columns={[
                        { label: 'Logo', width: '80px' },
                        { label: 'Location' },
                        { label: 'Add address', width: '258px' },
                        { label: 'Remove', width: '40px' },
                    ]}
                    loading={false}
                    showHeader={false}
                >
                    {toEditLocations.map((location: V2FormattedLocationData) => {
                        return (
                            <LocationCardAddress
                                key={location.id}
                                location={location}
                                setToEditLocations={setToEditLocations}
                                setDisplayRequired={setDisplayRequired}
                                displayRequired={displayRequired}
                            />
                        );
                    })}
                </List>
            </LocationCardAddressContainer>

            <FullButtonWrapper>
                <FullButton
                    dataTrackId="continue_from_addresses_onboarding"
                    onClick={onContinueClick}
                    icon={[FontAwesomeIconsPartooUsed.faArrowRight, IconPrefix.SOLID]}
                    shape="cube"
                    variant="primary"
                    size="large"
                >
                    {t('continue')}
                </FullButton>
            </FullButtonWrapper>
        </FullPageContainer>
    );
};

export default AddAddressesPage;
