import { useState } from 'react';

import { GroupSection } from 'app/api/types/groupsV2';

import Collapsible from 'app/common/components/Collapsible';

import Group from './Group';
import NewGroupButton from './NewGroupButton';
import {
    GroupsList,
    SectionBottom,
    SectionContainer,
    SectionName,
    SectionTop,
    SectionTopInfos,
    SectionTopRightActions,
} from './Section.styled';
import SectionActionButton from './SectionActionButton';

type Props = {
    section: GroupSection;
};

const Section = ({ section }: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    return (
        <SectionContainer>
            <SectionTop>
                <SectionTopInfos>
                    <SectionName onClick={() => setIsOpen(!isOpen)}>
                        {section.name}
                        {isOpen ? (
                            <i className="fa-solid fa-chevron-down" />
                        ) : (
                            <i className="fa-solid fa-chevron-right" />
                        )}
                    </SectionName>
                    {section.can_edit && (
                        <SectionTopRightActions>
                            <NewGroupButton sectionId={section.id} />
                            <SectionActionButton section={section} />
                        </SectionTopRightActions>
                    )}
                </SectionTopInfos>
            </SectionTop>
            <SectionBottom>
                <Collapsible collapsed={!isOpen} deps={[section.groups]}>
                    <GroupsList>
                        {section.groups.map(group => (
                            <Group
                                key={group.id}
                                group={group}
                                sectionId={section.id}
                                canEdit={section.can_edit}
                            />
                        ))}
                    </GroupsList>
                </Collapsible>
            </SectionBottom>
        </SectionContainer>
    );
};

export default Section;
