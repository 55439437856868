import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { GROUP_DELETABLE } from 'app/common/data/queryKeysConstants';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import useGroupCheckDelete from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupCheckDelete';
import { useGroupContext } from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupContext';
import useGroupDelete from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupDelete';
import useGroupIds from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupIds';

import useIsSubgroup from 'app/pages/visibility/BusinessListV2/hooks/groups/useIsSubgroup';
import { BusinessListQueryKeys } from 'app/pages/visibility/BusinessListV2/utils/enums';
import { SETTINGS_TEAM_USERS_PATHNAME } from 'app/routing/routeIds';
import queryClient from 'app/states/queryClient';

type Props = {
    showModal: boolean;
    closeModal: () => void;
};

const DeleteGroupModal = ({ showModal, closeModal }: Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (showModal) {
            queryClient.resetQueries(invalidatesAny(GROUP_DELETABLE));
        }
    }, [showModal]);

    const isSubgroup = useIsSubgroup();
    const [currentGroupId, currentSubgroupId] = useGroupIds();
    const groupId = Number(currentSubgroupId ?? currentGroupId);
    const { data: checkDeleteData } = useGroupCheckDelete(groupId);
    const { deleteGroup } = useGroupContext();
    const navigate = useNavigate();
    const [, setGroupUrl] = useStateQueryParams(BusinessListQueryKeys.GROUP);

    const groupDelete = useGroupDelete(groupId, () => {
        deleteGroup(groupId);
        setGroupUrl('');
        closeModal();
    });

    const canDeleteGroup = checkDeleteData?.deletable;
    if (canDeleteGroup === undefined) {
        return null;
    }

    if (canDeleteGroup) {
        return (
            <ConfirmModal
                show={showModal}
                title={isSubgroup ? t('delete_subgroup') : t('delete_group')}
                content={
                    isSubgroup ? t('delete_subgroup_modal_text') : t('delete_group_modal_text')
                }
                confirmLabel={t('delete')}
                modalFor="delete"
                onConfirm={groupDelete.mutate}
                onHide={closeModal}
                trackId="delete-group"
            />
        );
    } else {
        return (
            <ConfirmModal
                show={showModal}
                title={
                    isSubgroup
                        ? t('title_warning_delete_subgroup')
                        : t('title_warning_delete_group')
                }
                content={
                    isSubgroup
                        ? t('content_warning_delete_subgroup')
                        : t('content_warning_delete_group')
                }
                confirmLabel={t('redirect_team_management')}
                modalFor="warning"
                onConfirm={() => {
                    navigate(SETTINGS_TEAM_USERS_PATHNAME);
                }}
                onHide={closeModal}
                trackId="warning-delete-group"
            />
        );
    }
};

export default DeleteGroupModal;
