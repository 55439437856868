import React, { useMemo } from 'react';

import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { useTranslation } from 'react-i18next';

import AsyncSingleSelect, {
    Props,
} from 'app/common/designSystem/components/molecules/AsyncSingleSelect/AsyncSingleSelect';
import useAvailableLanguages from 'app/common/hooks/queries/useAvailableLanguages';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    translateLabel?: boolean;
};

type OmitProps =
    | 'onChange'
    | 'options'
    | 'isLoading'
    | 'selectedValue'
    | 'placeholder'
    | 'hideCross';

const ControlledLanguageSelect = ({
    name,
    control,
    translateLabel = true,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => {
    const { t } = useTranslation();
    const { data: rawOptions, isLoading } = useAvailableLanguages();

    const options = useMemo(() => {
        return (
            (translateLabel
                ? rawOptions
                : rawOptions?.map(opt => ({
                      label: t(`original-${opt.value}`),
                      value: opt.value,
                  }))) ?? []
        );
    }, [rawOptions]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <AsyncSingleSelect
                    {...props}
                    placeholder={t('language')}
                    onChange={onChange}
                    selectedValue={value}
                    options={options || []}
                    isLoading={isLoading}
                    hideCross
                />
            )}
        />
    );
};

export default ControlledLanguageSelect;
