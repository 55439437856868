import { connect } from 'react-redux';

import { V2FormattedBusinessData } from 'app/api/types/business';
import {
    displayModalSelector,
    hideFilterByBusiness,
    resetPendingFilter,
    selectedPendingBusinessCount,
} from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import {
    businessesCurrentPageSelector,
    businessesListSelector,
    businessesMaxPageSelector,
    isFetchingSelector,
    orgIdFilterSelector,
    totalBusinessCountSelector,
} from 'app/common/reducers/newBusinesses';
import basicFilter from 'app/common/services/filterBusinesses/index';
import { closeBusinessModal } from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupFakeSaga';

import BusinessModalWithFilter from './BusinessModalWithFilter';

type Props = {
    businessesFilter: (arg0: Array<V2FormattedBusinessData>) => Array<V2FormattedBusinessData>;
    getCanSelectAll: (arg0: boolean) => boolean;
    handleBusinessesSelectClick: (arg0: boolean) => void;
    query: string;
    onSearchInputChange: (arg0: string) => void;
    selectProps: Record<string, any>;
    searchBusinessesByPage: (arg0: number) => void;
    headerTitle: string;
    activeRibbon: boolean;
};

const mapStateToProps = ({ filterByBusiness, businesses }) => ({
    filterByBusiness,
    businesses,
});

const mapDispatchToProps = (dispatch: any) => ({
    dispatch: action => dispatch(action),
});

const mergeProps = (
    { filterByBusiness, businesses },
    { dispatch },
    {
        businessesFilter,
        searchBusinessesByPage,
        getCanSelectAll,
        handleBusinessesSelectClick,
        query,
        onSearchInputChange,
        selectProps,
        headerTitle,
        activeRibbon,
    }: Props,
) => {
    const businessesRawList = businessesListSelector(businesses);
    const orgIdInQueryFilter = orgIdFilterSelector(businesses);
    const businessesAreBeingFetched = isFetchingSelector(businesses);
    const currentPage = businessesCurrentPageSelector(businesses);
    const maxPage = businessesMaxPageSelector(businesses);
    const totalBusinessCount = totalBusinessCountSelector(businesses);
    const countSelectedPendingBusiness = selectedPendingBusinessCount(
        filterByBusiness,
        totalBusinessCount,
    );
    const show = displayModalSelector(filterByBusiness);
    // BodyTitle
    const allBusinessesAreSelected = totalBusinessCount === countSelectedPendingBusiness;
    const title = 'all_businesses';
    const isLoading = !totalBusinessCount || businessesAreBeingFetched;
    const canSelectAll = getCanSelectAll(allBusinessesAreSelected);

    const selectButtonHandleClick = () => handleBusinessesSelectClick(allBusinessesAreSelected);

    // BusinessTable
    const businessesBasicFiltered = basicFilter(
        businessesRawList,
        // @ts-ignore
        {
            orgId: orgIdInQueryFilter,
        },
    );
    const filteredBusinesses = businessesFilter(businessesBasicFiltered);
    const businessLoadingCards = Math.min(8, totalBusinessCount || 8);

    const allBusinessesHaveBeenFetched = currentPage && maxPage && currentPage >= maxPage;

    const fetchNextBusinesses = () => {
        if (!businessesAreBeingFetched) {
            searchBusinessesByPage(currentPage + 1);
        }
    };

    return {
        headerTitle,
        activeRibbon,
        // BodyFilter
        onSearchInputChange,
        query,
        selectProps,
        // BodyTitle
        title,
        canSelectAll,
        selectButtonHandleClick,
        isLoading,
        // BusinessTable
        businessesList: filteredBusinesses,
        fetchNextBusinesses,
        allBusinessesHaveBeenFetched,
        businessLoadingCards,
        // BusinessModal
        show,
        onHide: () => {
            dispatch(hideFilterByBusiness());
            dispatch(closeBusinessModal());
            dispatch(resetPendingFilter('all'));
        },
    };
};

const options = {
    areStatePropsEqual: (next: Record<string, any>, prev: Record<string, any>) => {
        if (next.show !== prev.show) {
            const intercom: HTMLElement | null = document.querySelector('#intercom-container');
            if (intercom) intercom.style.display = next.show ? 'none' : 'initial';
        }

        return next.show === prev.show;
    },
};
const BusinessModalWithFilterComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    options,
)(BusinessModalWithFilter);

// Still only used by Business List V2 for now (for groups V1 handling)
export default BusinessModalWithFilterComponent;
