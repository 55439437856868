import i18n from 'app/common/translations/i18n';
import { MetricsAttributesResponse } from 'app/pages/analytics/VisibilityAnalyticsV2/types/metrics';
import { NormalizrActionsDatesType } from 'app/pages/analytics/VisibilityAnalyticsV2/types/queryParams';
import { actionsChartColor } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/actions';
import { formatXAxisLabels } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/formatTooltip';

export const normalizrActions = (data, dates: NormalizrActionsDatesType) => {
    const datesReturned = data.map(item => item.date);
    const labels = data.map(item => formatXAxisLabels(item.bucket, item.date, dates.from));
    const datasets = [
        {
            label: i18n.t('website_visits'),
            backgroundColor: actionsChartColor.dark,
            data: data.map(item => item[MetricsAttributesResponse.WEBSITE_CLICKS]),
        },
        {
            label: i18n.t('calls'),
            backgroundColor: actionsChartColor.normal,
            data: data.map(item => item[MetricsAttributesResponse.CALL_CLICKS]),
        },
        {
            label: i18n.t('pa_actions_directions'),
            backgroundColor: actionsChartColor.light,
            data: data.map(item => item[MetricsAttributesResponse.BUSINESS_DIRECTION_REQUESTS]),
        },
    ];

    return { labels, datasets, datesReturned };
};
