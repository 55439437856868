import styled from '@emotion/styled';

export const StyledInternalRankingSection = styled.div`
    background-color: white;
    border-radius: 8px;
`;

export const StyledInternalRankingHeader = styled.div`
    padding: 24px;
`;

export const TagsRankingContainer = styled.div`
    margin-top: -24px;
`;
