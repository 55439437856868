import React from 'react';

import CreateGroupModal from './modals/CreateGroupModal';
import CreateSectionModal from './modals/CreateSectionModal';
import DeleteGroupModal from './modals/DeleteGroupModal';
import DeleteSectionModal from './modals/DeleteSectionModal';
import EditGroupModal from './modals/EditGroupModal';
import EditSectionModal from './modals/EditSectionModal';

const GroupsSettingsModals = () => {
    return (
        <>
            <CreateGroupModal />
            <CreateSectionModal />
            <DeleteGroupModal />
            <DeleteSectionModal />
            <EditGroupModal />
            <EditSectionModal />
        </>
    );
};

export default GroupsSettingsModals;
