import { Button, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import StrikeTroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';

import { PushNotificationsWrapper } from 'app/common/services/pushNotifications/pushNotificationsWrapper';

import { STATIC_BASE_URL } from 'app/config';

import {
    BannerImage,
    CancelButtonContainer,
    PromptContainer,
} from './PushNotificationsOptInPrompt.styled';

type PushNotificationsOptInPromptProps = {
    isVisible: boolean;
    onClose: () => void;
};

export const PushNotificationsOptInPrompt = ({
    isVisible,
    onClose,
}: PushNotificationsOptInPromptProps) => {
    const { t } = useTranslation();
    const pushNotificationsWrapper = PushNotificationsWrapper.getInstance();
    const onAccept = () => {
        pushNotificationsWrapper.showOptInPrompt();
        onClose();
    };

    const onDecline = () => {
        // We've already noted the prompt time for this user. We can just close
        // this modal.
        // Note that this action also runs when the user clicks on backdrop.
        onClose();
    };

    return (
        <Modal isOpen={isVisible} closeModal={onDecline}>
            <PromptContainer>
                <BannerImage
                    src={`${STATIC_BASE_URL}/images/app/app_messaging/allow_notifications_banner.svg`}
                />
                <h1 className="title">{t('web_push_notification_optin_customprompt_title')}</h1>
                <p className="description">
                    {t('web_push_notification_optin_customprompt_subtitle')}
                </p>
                <CancelButtonContainer>
                    <StrikeTroughButton
                        text={t('web_push_notification_optin_customprompt_decline')}
                        color="secondary"
                        onClick={onDecline}
                    />
                </CancelButtonContainer>
                <Button
                    dataTrackId="web_push_notification_optin_customprompt_accept"
                    variant="primary"
                    shape="cube"
                    full
                    size="large"
                    onClick={onAccept}
                >
                    {t('web_push_notification_optin_customprompt_accept')}
                </Button>
            </PromptContainer>
        </Modal>
    );
};
