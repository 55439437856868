import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import { SettingsV2 } from 'app/pages/settingsV2/SettingsV2';

export const SettingsV2Page = () => {
    const { t } = useTranslation();
    return (
        <>
            <DynamicPageHeader title={t('settings')} />
            <SettingsV2 />
        </>
    );
};
