import { useContext, useEffect } from 'react';

import { useImportOAuthAccount } from 'app/businessConnection/hooks/useImportOAuthAccount';
import FunnelContext from 'app/common/components/funnel/FunnelContext';
import { GOOGLE_OAUTH2_START_PATH } from 'app/routing/routeIds';

export const useImportFunnelOAuthAccount = () => {
    const funnel = useContext(FunnelContext);
    const { startOAuthFlow, oauthAccountId } = useImportOAuthAccount(GOOGLE_OAUTH2_START_PATH);

    useEffect(() => {
        if (oauthAccountId) {
            funnel.store = {
                ...funnel.store,
                oauthAccountId,
            };

            funnel.navigate('loading_locations');
        }
    }, [oauthAccountId]);

    return { startOAuthFlow };
};
