import { Stack } from '@partoohub/ui';

import { LoadingBusinessCard } from 'app/businessConnection/components/commonOld/LoadingBusinessCard';
import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import { LoadingBusinessRowContainer } from '../BusinessRow/BusinessRow.styled';

const LoadingBusinessRow = () => (
    <LoadingBusinessRowContainer>
        <LoadingBusinessCard />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <LoadingRectangle
                recRadius="8px"
                recHeight="40px"
                recWidth="230px"
                recMargin="0 20px 0 0"
            />
            <LoadingRectangle
                recRadius="8px"
                recHeight="40px"
                recWidth="230px"
                recMargin="0 20px 0 0"
            />
        </Stack>
    </LoadingBusinessRowContainer>
);

export default LoadingBusinessRow;
