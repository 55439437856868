import React from 'react';

import { LoadingLocationCardContainer } from 'app/businessConnection/components/commonOld/LoadingLocationCard.styled';

import { LoadingBusinessCard } from './LoadingBusinessCard';

const LoadingLocationCard = () => (
    <LoadingLocationCardContainer>
        <LoadingBusinessCard />
    </LoadingLocationCardContainer>
);

export default LoadingLocationCard;
