export const toggleLegend = (legendItem, ref) => {
    if (!ref.current) return;
    const indexLegend = legendItem.datasetIndex;
    // @ts-ignore
    const ci = ref.current;
    // Get a list of the other legends'indexes
    const otherIndexes = Array.from(
        Array(ci.legend.legendItems.length).keys(),
    ).filter(x => x !== indexLegend);
    // Get a list of the others legends'hidden state
    const otherStates = otherIndexes.map(
        legendIndex => ci.legend.legendItems[legendIndex].hidden,
    );
    // if toggled legend is hidden it can always be displayed :
    // if it's visible it can only be hidden if not all others legend are already hidden
    if (legendItem.hidden) {
        ci.getDatasetMeta(indexLegend).hidden = false;
    } else {
        ci.getDatasetMeta(indexLegend).hidden = !otherStates.every(
            x => x === true,
        );
    }
    ci.update();
};
