import { useMemo } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { YEAR } from 'app/common/services/allowedSeasonalityOptions';
import { useGetDate } from 'app/pages/analytics/VisibilityAnalyticsV2//hooks/queryParams/params/useGetDate';
import { calculateRangeDate } from 'app/pages/analytics/VisibilityAnalyticsV2//utils/date';
import {
    DeltaContainer,
    DeltaContent,
    DeltaIcon,
    DeltaNumber,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/Delta/Delta.styled';
import { AnalyticsQueryKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queryKeys';

type Props = {
    delta: number | null;
};
export const Delta = ({ delta }: Props) => {
    const { t } = useTranslation();

    const [comparison] = useStateQueryParams(AnalyticsQueryKeys.COMPARISON);

    const dataDelta = useMemo(() => {
        if (!delta) return;
        return delta >= 0
            ? {
                  icon: 'fa-arrow-up',
                  color: HEX_COLORS.success,
                  sign: '+',
              }
            : {
                  icon: 'fa-arrow-down',
                  color: HEX_COLORS.danger,
                  sign: '-',
              };
    }, [delta]);

    const dates = useGetDate();
    const data = useMemo(
        () =>
            calculateRangeDate(
                comparison,
                dates.filter_date__gte,
                dates.filter_date__lte,
                'dd/MM/yyyy',
            ),
        [comparison, dates.filter_date__gte, dates.filter_date__lte],
    );
    let formattedDates = '';

    if (delta) {
        formattedDates = t('tooltip_comparison_dates', {
            dates: `${data?.dateFrom} - ${data?.dateTo}`,
        });
    }

    return (
        <DeltaContainer>
            {delta && dataDelta ? (
                <TooltipWrapper text={formattedDates} position="top">
                    <DeltaContent>
                        <DeltaIcon color={dataDelta.color}>
                            <i className={`fa ${dataDelta.icon}`} />
                        </DeltaIcon>
                        <DeltaNumber color={dataDelta.color}>
                            {`${dataDelta.sign} ${Math.abs(delta)}%`}
                        </DeltaNumber>
                        {comparison && comparison === YEAR
                            ? t('pa_comparison_text_year')
                            : t('pa_comparison_text_period')}
                    </DeltaContent>
                </TooltipWrapper>
            ) : (
                t('pa_no_comparison')
            )}
        </DeltaContainer>
    );
};
