import { BusinessFieldsType } from 'app/api/types/business';

// TODO ANNIE: Remove this saga/redux usage in parner co / import section

// ACTION TYPES
export const BUSINESS_EDIT_FIELDS_REQUEST = 'BUSINESS_EDIT_FIELDS_REQUEST';
export const BUSINESS_EDIT_FIELDS_REQUEST_SUCCESS =
    'BUSINESS_EDIT_FIELDS_REQUEST_SUCCESS';
export const BUSINESS_EDIT_FIELDS_REQUEST_FAILURE =
    'BUSINESS_EDIT_FIELDS_REQUEST_FAILURE';
// ACTION FLOW TYPES
export type GetBusinessEditFieldsAction = {
    type: 'BUSINESS_EDIT_FIELDS_REQUEST';
    businessId: string;
};
type GetBusinessEditFieldsSuccessAction = {
    type: 'BUSINESS_EDIT_FIELDS_REQUEST_SUCCESS';
    businessFields: BusinessFieldsType;
};
type GetBusinessEditFieldsFailureAction = {
    type: 'BUSINESS_EDIT_FIELDS_REQUEST_FAILURE';
    error: Record<string, any>;
};
// ACTIONS
export const getBusinessEditFields = (
    businessId: string,
): GetBusinessEditFieldsAction => ({
    type: BUSINESS_EDIT_FIELDS_REQUEST,
    businessId,
});
export const getBusinessEditFieldsSuccess = (
    businessFields: BusinessFieldsType,
): GetBusinessEditFieldsSuccessAction => ({
    type: BUSINESS_EDIT_FIELDS_REQUEST_SUCCESS,
    businessFields,
});
export const getBusinessEditFieldsFailure = (
    error: Record<string, any>,
): GetBusinessEditFieldsFailureAction => ({
    type: BUSINESS_EDIT_FIELDS_REQUEST_FAILURE,
    error,
});
// TYPES
type BusinessEditFieldsAction =
    | GetBusinessEditFieldsAction
    | GetBusinessEditFieldsSuccessAction
    | GetBusinessEditFieldsFailureAction;
export type BusinessEditFieldsState = {
    businessFields: BusinessFieldsType;
    isFetchingData: boolean;
    initialDataLoaded: boolean;
};
export const initialState: BusinessEditFieldsState = {
    businessFields: [],
    isFetchingData: false,
    initialDataLoaded: false,
};

// REDUCER
const businessEditFieldsReducer = (
    state: BusinessEditFieldsState = initialState,
    action: BusinessEditFieldsAction,
): BusinessEditFieldsState => {
    switch (action.type) {
        case BUSINESS_EDIT_FIELDS_REQUEST:
            return { ...state, businessFields: [], isFetchingData: true };

        case BUSINESS_EDIT_FIELDS_REQUEST_SUCCESS:
            return {
                ...state,
                businessFields: action.businessFields,
                isFetchingData: false,
                initialDataLoaded: true,
            };

        case BUSINESS_EDIT_FIELDS_REQUEST_FAILURE:
            return { ...state, isFetchingData: false };

        default:
            return state;
    }
};

// SELECTORS
export const businessFieldsSelector = (
    state: BusinessEditFieldsState,
): BusinessFieldsType => state.businessFields;
export default businessEditFieldsReducer;
