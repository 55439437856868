import styled from '@emotion/styled';
import { IconButton, Stack } from '@partoohub/ui';

export const AutomaticMessagesConfigurationContainer = styled.div`
    display: flex;
    overflow: auto;
    gap: 40px;
    padding: 16px;
    height: 100%;
    background: var(
        --Theme-Gradient,
        linear-gradient(
            108deg,
            #e6f1f7 0%,
            #f3f3fc 23.96%,
            #f6f4f1 50%,
            #f6f2ec 73.96%,
            #f6f8fc 100%
        )
    );
`;

export const BackIconButton = styled(IconButton)`
    padding: 8px;
    position: sticky;
    top: 8px;
`;

export const AutomaticMessagesConfigurationSectionContainer = styled.div`
    background: white;
    padding: 24px;
    height: fit-content;
    flex: 1;
    border-radius: 16px;
`;

export const AutomaticMessagesHeaderContainer = styled(Stack)`
    margin-bottom: 16px;
`;

export const AutomaticMessagesPreviewContainer = styled.div`
    margin: 36px 70px 0;
    position: sticky;
    top: 36px;
`;
