import { connect } from 'react-redux';

import { V2BusinessStatus } from 'app/api/types/business';
import { businessByIdSelector } from 'app/common/reducers/newBusinesses';
import { AppState } from 'app/states/reducers';

import BusinessRowComponent from './BusinessRow';

type OwnProps = {
    businessId: string;
    businessStatus: V2BusinessStatus;
};

const mapStateToProps = ({ businesses }: AppState, { businessId, businessStatus }: OwnProps) => {
    const business = businessByIdSelector(businesses, businessId);
    return {
        businessId,
        googleLocationId: business ? business.googleLocationId : null,
        facebookPageId: business ? business.facebookPageId : null,
        businessStatus,
    };
};

const BusinessRow = connect(mapStateToProps)(BusinessRowComponent);

export default BusinessRow;
