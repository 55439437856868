import { useAppDataContext } from 'app/common/contexts/appData';
import { APP_PRODUCTS } from 'app/common/data/productIds';

export const useProductsAccess = () => {
    const { me } = useAppDataContext();

    const hasRmAccess = me.sidebar_products.includes(APP_PRODUCTS.REVIEW_MANAGEMENT);
    const hasPmAccess = me.sidebar_products.includes(APP_PRODUCTS.PRESENCE_MANAGEMENT);
    const hasRbAccess = me.sidebar_products.includes(APP_PRODUCTS.REVIEW_BOOSTER);
    const hasBmAccess = me.sidebar_products.includes(APP_PRODUCTS.BUSINESS_MESSAGE);

    return {
        hasRmAccess,
        hasPmAccess,
        hasRbAccess,
        hasBmAccess,
    };
};
