import { call, put, select, takeEvery } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import storeError from 'app/common/sagas/storeError';
import { Saga } from 'app/common/types';
import { getResetTokenValue } from 'app/connection/services/getTokenValue';
import {
    RESET_PASSWORD_REQUEST,
    ResetPasswordState,
    passwordConfirmationSelector,
    passwordSelector,
    resetPasswordRequestFailure,
    resetPasswordRequestSuccess,
} from 'app/states/connection/reducers/resetPassword';
import { pathNameSelector, resetPasswordSelector } from 'app/states/reducers';

const resetPassword = function* (): Saga {
    const resetPasswordState: ResetPasswordState = yield select(
        resetPasswordSelector,
    );
    const password = passwordSelector(resetPasswordState);
    const pathName: string = yield select(pathNameSelector);

    /* eslint-disable camelcase */
    const passwordConfirmation =
        passwordConfirmationSelector(resetPasswordState);

    try {
        yield call(api.connection.resetPassword, {
            password,
            password_confirmation: passwordConfirmation,
            token: getResetTokenValue(pathName),
        });

        /* eslint-enable camelcase */
        yield put(resetPasswordRequestSuccess());
    } catch (error) {
        yield* storeError(resetPasswordRequestFailure, error);
    }
};

function* resetPasswordSaga(): Saga {
    yield takeEvery(RESET_PASSWORD_REQUEST, resetPassword);
}

export default resetPasswordSaga;
