import React from 'react';

import { useTranslation } from 'react-i18next';

import {
    EmailDomain,
    Input,
    InputWrapper,
    Label,
    Wrapper,
} from './EmailInput.styled';

export type Props = {
    placeholder: string;
    value: string | undefined;
    domain: string;
    onChange: React.Dispatch<React.SetStateAction<string>>;
};

export const EmailInput = ({ placeholder, value, onChange, domain }: Props) => {
    const { t } = useTranslation();

    const emailUser = value?.substring(0, value.indexOf('@')) ?? '';
    const handleChange = (val: string) => {
        const formattedVal = val.replace(/[^a-zA-Z0-9._%+-]/g, '');
        onChange(formattedVal + domain);
    };

    return (
        <Wrapper>
            <InputWrapper>
                <Input
                    type="text"
                    value={emailUser}
                    onChange={e => handleChange(e.target.value)}
                />
                <EmailDomain>{domain}</EmailDomain>
            </InputWrapper>
            <Label $filled={emailUser !== undefined && emailUser.length !== 0}>
                {t(placeholder)}
            </Label>
        </Wrapper>
    );
};
