// ROOT LEVEL
import addSearchFilterSaga from 'app/common/components/businessModalDeprecated/sagas/addSearchFilter';
import loadInitialFilterDataSaga from 'app/common/components/businessModalDeprecated/sagas/loadInitialFilterData';
import authInterceptorSaga from 'app/common/sagas/authInterceptorSaga';
import businessEditFieldsSaga from 'app/common/sagas/businessEditFieldsSaga';
import fuzzySearchBusinessesSaga from 'app/common/sagas/fuzzySearchBusinesses';
import getAllCitiesSaga from 'app/common/sagas/getAllCities';
import getGroupSaga from 'app/common/sagas/getGroupSaga';
import { forceGetMeSaga, getMeSaga } from 'app/common/sagas/getMeSaga';
import getOrgSaga from 'app/common/sagas/getOrgSaga';
import getSubscriptionsSaga, {
    loadSubscriptionsAtBootSaga,
} from 'app/common/sagas/getSubscriptions';
import groupFilterSaga from 'app/common/sagas/groupFilterSaga';
import searchGroupsByOrgIdSaga from 'app/common/sagas/searchGroupsByOrgIdSaga';
import searchOrgsSaga from 'app/common/sagas/searchOrgsSaga';
import { handlePushNotificationsOptInPromptActivationSaga } from 'app/states/pushNotifs/sagas';

import connectionPageSagas from './connection/sagas';
import loginSaga from './connection/sagas/loginSaga';
import { renewJwtTokenSaga } from './jwtToken/sagas';
import { pageSagas as visibilityPageSagas } from './knowledge/sagas';
import { pageSagas as presenceAnalyticsPageSagas } from './presence/sagas';
import reviewAnalyticsPageSagas from './reviewAnalytics/sagas';
import { pageSagas as settingsPageSagas } from './settings/sagas';

export const sharedSagas = [
    getSubscriptionsSaga,
    getMeSaga,
    loginSaga,
    renewJwtTokenSaga,
    authInterceptorSaga,
    getGroupSaga,
    fuzzySearchBusinessesSaga, // Settings sagas
    // Knowledge sagas
    businessEditFieldsSaga,
    getAllCitiesSaga,
    loadInitialFilterDataSaga,
    addSearchFilterSaga,
    groupFilterSaga,
    getOrgSaga, // Team sagas
    searchGroupsByOrgIdSaga,
    searchOrgsSaga, // Reviews saga
    loadSubscriptionsAtBootSaga,
    handlePushNotificationsOptInPromptActivationSaga,

    // Only to update me/org after an update of user
    // Should be removed when all other pages are not using sagas anymore !
    forceGetMeSaga,
];
// ⚠️ WARNING: The order matters
export const pageSagas = {
    ...presenceAnalyticsPageSagas,
    ...settingsPageSagas,
    ...visibilityPageSagas,
    ...reviewAnalyticsPageSagas,
    ...connectionPageSagas,
};
