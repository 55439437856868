import React from 'react';

import { Modal } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { UserListQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

import UserReinviteAllContent from './UserReinviteAllContent';

const UserReinviteAllModal = () => {
    const [userInvite, setUserInvite] = useStateQueryParams(UserListQueryKeys.USER_INVITE);

    return (
        <Modal isOpen={userInvite === 'all'} closeModal={() => setUserInvite('')}>
            <UserReinviteAllContent closeModal={() => setUserInvite('')} />
        </Modal>
    );
};

export default UserReinviteAllModal;
