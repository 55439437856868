import { NavigateFunction } from 'react-router-dom';

import { MESSAGING_CONVERSATION_STATS } from 'app/common/data/queryKeysConstants';

import { CONVERSATIONS_MESSAGING_PATH } from 'app/routing/routeIds';
import { AssignFilterOption, Conversation, MessagingFilters } from 'app/states/messaging';
import { AssignmentData } from 'app/states/messaging/conversationUpdateTypes';
import queryClient from 'app/states/queryClient';

import { getNextActiveConversation } from '../../ConversationUtils';

import { ConversationsData } from '../../hooks/useConversationUpdate';
import {
    addConversation,
    removeConversation,
    updateConversation,
} from '../../services/handleConversationsCache';
import { addNewMessage } from '../../services/handleMessagesCache';

export const handleConversationAssignment = (
    assignmentData: AssignmentData,
    conversationsData: ConversationsData,
    filtersData: MessagingFilters,
    navigate: NavigateFunction,
    meId: string,
) => {
    const { conversation } = assignmentData;
    const { conversations, activeConversation, setActiveConversation } = conversationsData;
    const { assignFilter } = filtersData;

    const isOnActiveConversation = activeConversation?.id === conversation.id;
    const shouldUpdateAssignment =
        !isOnActiveConversation ||
        activeConversation?.assigned_user_id !== conversation.assigned_user_id;

    if (assignFilter === AssignFilterOption.ALL && shouldUpdateAssignment) {
        assignConversation(
            isOnActiveConversation,
            assignmentData,
            setActiveConversation,
            filtersData,
        );
        return;
    }

    const conversationWithWebsocketConversationId = conversations.find(
        loadedConversation => loadedConversation.id === conversation.id,
    );
    const isInRightFilter =
        (assignFilter === AssignFilterOption.ME && conversation.assigned_user_id === meId) ||
        (assignFilter === AssignFilterOption.UNASSIGNED && conversation.assigned_user_id === null);

    const shouldRemoveConversation =
        conversationWithWebsocketConversationId &&
        conversationWithWebsocketConversationId.assigned_user_id !== conversation.assigned_user_id;

    if (shouldRemoveConversation) {
        removeConversationFromAssignment(assignmentData, filtersData);
        if (isOnActiveConversation)
            switchToNextActiveConversation(conversation.id, conversationsData, navigate);
        return;
    }

    const shouldAddConversation = !conversationWithWebsocketConversationId && isInRightFilter;

    if (shouldAddConversation) {
        addNewConversationFromAssignment(assignmentData, filtersData);
    }
};

const assignConversation = (
    isOnActiveConversation: boolean,
    { conversation, assignment_message }: AssignmentData,
    setActiveConversation: (activeConversation: Conversation) => void,
    { statusFilter, assignFilter, businessFilters }: MessagingFilters,
) => {
    updateConversation(conversation, statusFilter, assignFilter, businessFilters);
    addNewMessage(queryClient, conversation.id, assignment_message);
    queryClient.invalidateQueries([MESSAGING_CONVERSATION_STATS]);

    if (isOnActiveConversation) {
        setActiveConversation(conversation);
    }
};

const removeConversationFromAssignment = (
    { conversation, assignment_message }: AssignmentData,
    { statusFilter, assignFilter, businessFilters }: MessagingFilters,
) => {
    addNewMessage(queryClient, conversation.id, assignment_message);
    removeConversation(conversation.id, statusFilter, assignFilter, businessFilters);
    queryClient.invalidateQueries([MESSAGING_CONVERSATION_STATS]);
};

const switchToNextActiveConversation = (
    conversationId: number,
    { conversations, activeConversation, setActiveConversation }: ConversationsData,
    navigate: NavigateFunction,
) => {
    const nextConversation = getNextActiveConversation(
        conversations,
        activeConversation,
        conversationId,
    );

    setActiveConversation(nextConversation);
    if (nextConversation)
        navigate(`${CONVERSATIONS_MESSAGING_PATH}?conversationId=${nextConversation.id}`);
};

const addNewConversationFromAssignment = (
    { conversation, assignment_message }: AssignmentData,
    { statusFilter, assignFilter, businessFilters }: MessagingFilters,
) => {
    addConversation(conversation, statusFilter, assignFilter, businessFilters);
    addNewMessage(queryClient, conversation.id, assignment_message);
    queryClient.invalidateQueries([MESSAGING_CONVERSATION_STATS]);
};
