import { PropsWithChildren, createContext, useContext } from 'react';

import { useConfirmModal } from 'app/common/components/ConfirmModalV2';
import { UseConfirmModal } from 'app/common/components/ConfirmModalV2/useConfirmModal';

export enum ReviewListModal {
    DOWNLOAD_REVIEWS = 'download_reviews',
    DOWNLOAD_FEEDBACKS = 'download_feedbacks',
}

type ReviewListModalsContextType = Record<ReviewListModal, UseConfirmModal<{ email: string }>>;
const ReviewListModalsContext = createContext({} as ReviewListModalsContextType);

export const ReviewListModalsProvider = ({ children }: PropsWithChildren) => {
    const downloadReviewsModal = useConfirmModal<{
        email: string;
    }>(ReviewListModal.DOWNLOAD_REVIEWS);

    const downloadFeedbacksModal = useConfirmModal<{
        email: string;
    }>(ReviewListModal.DOWNLOAD_FEEDBACKS);

    return (
        <ReviewListModalsContext.Provider
            value={{
                [ReviewListModal.DOWNLOAD_REVIEWS]: downloadReviewsModal,
                [ReviewListModal.DOWNLOAD_FEEDBACKS]: downloadFeedbacksModal,
            }}
        >
            {children}
        </ReviewListModalsContext.Provider>
    );
};

export const useReviewListModalsContext = () => useContext(ReviewListModalsContext);
