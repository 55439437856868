import { V2FormattedBusinessData } from 'app/api/types/business';

const presenceManagementFilter = (
    businesses: Array<V2FormattedBusinessData>,
    subscribed: boolean,
): Array<V2FormattedBusinessData> =>
    businesses.filter(
        ({ subscriptions }) =>
            !!subscriptions?.presence_management?.active === subscribed,
    );

export default presenceManagementFilter;
