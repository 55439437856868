import type {
    WhatsAppBusinessAccountsCount,
    WhatsAppBusinessAccountsData,
    WhatsAppNumberAuthorization,
    WhatsappProfile,
} from 'app/api/types/whatsapp';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

const SIGNUP_URI = 'whatsapp/signup';
const GET_ACCOUNTS_URI = 'whatsapp/accounts';
const NUMBER_URI = 'whatsapp/number';
const GET_PROFILE = 'whatsapp/profile';

interface WhatsappSignupResponse {
    status: 'success';
}

class WhatsappApiCalls extends ApiResourceBaseClass {
    static signup(
        code: string,
        need_partoo_number: boolean,
        userId?: string,
    ): Promise<WhatsappSignupResponse> {
        return WhatsappApiCalls.post(SIGNUP_URI, {
            code: code,
            meta_user_id: userId,
            need_partoo_number,
        }).then(({ data }) => data);
    }

    static getAccountsCount(): Promise<WhatsAppBusinessAccountsCount> {
        return WhatsappApiCalls.get(`${GET_ACCOUNTS_URI}/count?`).then(({ data }) => data);
    }

    static getAccountsList(): Promise<WhatsAppBusinessAccountsData> {
        return WhatsappApiCalls.get(`${GET_ACCOUNTS_URI}?`).then(({ data }) => data);
    }

    static getNumberAuthorization(): Promise<WhatsAppNumberAuthorization> {
        return WhatsappApiCalls.get(`${NUMBER_URI}/authorization?`).then(({ data }) => data);
    }

    static getProfile(phone_number_id: number): Promise<WhatsappProfile> {
        return WhatsappApiCalls.get(`${GET_PROFILE}/${phone_number_id}`).then(({ data }) => data);
    }
}

export default WhatsappApiCalls;
