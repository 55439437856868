import styled from '@emotion/styled';

export const MenuListContentTemplateContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MenuListContentHeader = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.default.alpha};
    min-height: 88px;
`;

export const MenuListContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;
`;

export const MenuListContent = styled.div<{ withoutPadding: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${({ withoutPadding }) => (withoutPadding ? '0' : '24px')};
    border-radius: 8px;
`;

export const MenuListContentFooter = styled.div<{ isBelowProvidedDevices: boolean }>`
    display: flex;
    padding: 16px;
    position: sticky;
    z-index: 2;
    bottom: ${({ isBelowProvidedDevices }) => (isBelowProvidedDevices ? '-20px' : '-40px')};
    background: ${({ theme }) => theme.colors.theme.initial};
    border-radius: 0 0 8px 8px;
    border-top: 1px solid ${({ theme }) => theme.colors.default.alpha};
`;
