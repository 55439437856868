import {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useMemo,
    useState,
} from 'react';

import { Conversation } from 'app/states/messaging';

interface MessagingContextType {
    activeConversation: Conversation | undefined;
    setActiveConversation: Dispatch<SetStateAction<Conversation | undefined>>;
    isRequestingNewConversations: boolean;
    setIsRequestingNewConversations: (val: boolean) => void;
}

export const MessagingContext = createContext<MessagingContextType>({
    activeConversation: undefined,
    setActiveConversation: () => undefined,
    isRequestingNewConversations: false,
    setIsRequestingNewConversations: () => undefined,
});

export const MessagingProvider = ({ children }: PropsWithChildren) => {
    const [activeConversation, setActiveConversation] = useState<Conversation | undefined>(
        undefined,
    );
    const [isRequestingNewConversations, setIsRequestingNewConversations] =
        useState<boolean>(false);

    const value = useMemo(
        () => ({
            activeConversation,
            setActiveConversation,
            isRequestingNewConversations,
            setIsRequestingNewConversations,
        }),
        [activeConversation, isRequestingNewConversations],
    );

    return <MessagingContext.Provider value={value}>{children}</MessagingContext.Provider>;
};
