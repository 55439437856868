import React, {
    FC,
    ReactNode,
    createContext,
    useContext,
    useMemo,
    useState,
} from 'react';

import { DownloadLocationOAuthAccountTaskInfo } from 'app/api/types/task/download_task';
import { OAuthAccountTaskInfo, TaskInfo } from 'app/api/types/task/task';

import { useTaskListener } from './useTaskListener';

interface OauthAccountsObjectType {
    commonOAuthAccounts: Record<string, OAuthAccountTaskInfo>;
    downloadLocationsOAuthAccounts: Record<
        string,
        DownloadLocationOAuthAccountTaskInfo
    >;
}

type TaskContextType = {
    oauthAccounts: OauthAccountsObjectType;
    tasks: Record<string, TaskInfo>;
    storage: Record<string, any>;
    setStorage: any;
};

export const TaskContext = createContext({} as TaskContextType);

export const TaskProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [storage, setStorage] = useState({});
    const { oauthAccounts, tasks } = useTaskListener(storage);

    const contextValue = useMemo(
        () => ({
            oauthAccounts,
            tasks,
            storage,
            setStorage,
        }),
        [oauthAccounts, tasks, storage],
    );

    return (
        <TaskContext.Provider value={contextValue}>
            {children}
        </TaskContext.Provider>
    );
};

export const useTaskContext = () => useContext(TaskContext);
