import { Chart, ChartType } from '@partoohub/ui';

export const ReplyMeansGraph = () => {
    return (
        <Chart
            dataTrackId=""
            type={ChartType.Bar}
            data={{
                datasets: [
                    {
                        label: 'Non répondu',
                        data: [100, 356, 678, 546, 1000, 123, 1509],
                        backgroundColor: 'rgba(255, 92, 154, 0.8)',
                    },
                    {
                        label: 'Plus de 2 jours',
                        data: [1034, 2940, 678, 246, 239, 562, 589],
                        backgroundColor: 'rgba(247, 194, 0, 0.55)',
                    },
                    {
                        label: '2 jours',
                        data: [1034, 656, 678, 246, 560, 562, 1202],
                        backgroundColor: 'rgba(44, 184, 132, 0.8)',
                    },
                ],
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            }}
        />
    );
};
