import React, { useState } from 'react';

import { Controller, UseFormWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { V2BusinessData } from 'app/api/types/business';
import AsyncMultipleSelect from 'app/common/designSystem/components/molecules/AsyncMultipleSelect';
import { Props } from 'app/common/designSystem/components/molecules/AsyncMultipleSelect/AsyncMultipleSelect';
import { formatBusinessToChoice } from 'app/common/hooks/queries/useBusiness';
import { useBusinesses } from 'app/common/hooks/queries/useBusinesses';
import useDebounce from 'app/common/hooks/useDebounce';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    watch: UseFormWatch<any>;
};

type OmitProps =
    | 'onChange'
    | 'onInputChange'
    | 'value'
    | 't'
    | 'options'
    | 'isLoading'
    | 'selectedOptions'
    | 'placeholder';

const ControlledBusinessSelect = ({
    name,
    control,
    watch,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const queryDebounced = useDebounce(query);

    const getFilters = () => {
        const filters: any = { org_id: watch('org')?.value };

        const groupId = watch('group')?.value;
        if (groupId) {
            filters.group_id = groupId;
        }
        if (queryDebounced) {
            filters.query = queryDebounced;
        }

        return filters;
    };

    const { query: queryBusinesses, businesses } = useBusinesses(getFilters());
    const options = businesses?.map(business => formatBusinessToChoice(business)) ?? [];

    const loadMore = (searchInput: string) => {
        if (searchInput != query) {
            setQuery(searchInput);
        } else {
            queryBusinesses.fetchNextPage();
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return (
                    <AsyncMultipleSelect
                        options={options}
                        selectedOptions={value.map((business: V2BusinessData) =>
                            formatBusinessToChoice(business),
                        )}
                        onChange={newValue => {
                            // @ts-ignore
                            const newBusinesses: Array<V2BusinessData> = newValue
                                .map(choice =>
                                    (businesses ?? [])
                                        .concat(value)
                                        .find(business => business.id === choice.value),
                                )
                                .filter(Boolean);
                            onChange(newBusinesses);
                        }}
                        hasMore={queryBusinesses.hasNextPage}
                        loadMore={loadMore}
                        placeholder={t('businesses')}
                        mustFilterOptions={false}
                        {...props}
                    />
                );
            }}
        />
    );
};

export default ControlledBusinessSelect;
