import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import { POSTS_BUSINESS_SCOPES } from 'app/common/data/queryKeysConstants';

import { Platform } from '../components/services/constants';

export default function useBusinessScopeData(platforms: Platform[]) {
    const businessFilterParams = useBusinessModalFilters();
    const selectedBusinessesCount = useSelectedBusinessesCount();
    const enabled = !!selectedBusinessesCount && platforms?.length > 0;

    const { data: businessScopeData } = useQuery(
        [POSTS_BUSINESS_SCOPES, businessFilterParams, platforms],
        () => {
            return api.scopes.getScopes({
                ...businessFilterParams,
                publisher__in: platforms
                    .join()
                    .replace('google', 'google_my_business'),
                partoo_ui: true,
            });
        },
        { enabled },
    );

    return businessScopeData;
}
