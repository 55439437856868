import { FontAwesomeIconsPartooUsed } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { SettingSummaryItemValues } from 'app/pages/settingsV2/subComponents/SettingsSummaryItem/SettingsSummaryItem';
import { REVIEWS_PATH_TOKEN } from 'app/routing/routeIds';

import { useCanAccessReviewsSetting } from './useCanAccessReviewsSetting';

export const useSettingReviewsSummary = (): SettingSummaryItemValues => {
    const { t } = useTranslation();

    const {
        canAccess: canAccessReviews,
        isLoading: isLoadingReviews,
        menuAccess,
    } = useCanAccessReviewsSetting();

    const hasAtLeastOne = Object.values(menuAccess).some(option => option);

    return {
        settingData: {
            id: '3',
            icon: FontAwesomeIconsPartooUsed.faStar,
            title: t('settings_summary_reviews_title'),
            description: t('settings_summary_reviews_description'),
            url: REVIEWS_PATH_TOKEN,
            display: canAccessReviews && hasAtLeastOne,
        },
        isLoading: isLoadingReviews,
    };
};
