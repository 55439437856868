import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, shadows } from '@partoohub/branding';

import { IconButton } from '@partoohub/ui';

import { STATIC_APP_ASSETS_URL } from 'app/config';

export const ChatbotFrame = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 24px;
    width: 300px;
    height: 540px;
    background: ${HEX_COLORS.white};
    border-radius: 16px;
    box-shadow: ${shadows.light};
`;

// HEADER
export const ChatbotFrameHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px 16px;
`;

export const JimHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`;

// CONTENT
export const ChatbotFrameContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: 0px 16px 16px 16px;
    flex-grow: 1;
    gap: 4px;
    overflow-y: auto;
    overflow-x: hidden;
`;

type MessageBubbleProps = {
    isConsumer?: boolean;
};

export const MessageBubbleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${({ isConsumer }: MessageBubbleProps) =>
        isConsumer ? 'flex-end' : 'flex-start'};
    width: 100%;
`;

export const MessageBubble = styled.div<MessageBubbleProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
    padding: 11px 16px;
    border-radius: 20px;
    white-space: preserve;
    background: ${({ isConsumer, theme }) =>
        isConsumer ? theme.colors.primary.initial : theme.colors.secondary.alpha};
    ${({ isConsumer, theme }) =>
        isConsumer &&
        css`
            background: linear-gradient(
                126.5deg,
                #03d2f1 14.42%,
                ${theme.colors.primary.initial} 85.42%
            );
        `};
    color: ${({ isConsumer, theme }) =>
        isConsumer ? HEX_COLORS.white : theme.colors.default.initial};
    max-width: 85%;
    overflow-wrap: break-word;
    word-break: break-word;

    a {
        text-decoration: underline;
        color: ${HEX_COLORS.black};
    }
    ${({ isConsumer }) =>
        isConsumer &&
        css`
            a {
                color: ${HEX_COLORS.white};
            }
        `};

    animation: 0.3s ease bubbleTransition;

    @keyframes bubbleTransition {
        0% {
            transform: translateY(100%) scale(0);
        }
        100% {
            transform: translateY(0px) scale(1);
        }
    }
`;

// FOOTER
export const ChatbotFrameFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 16px;
    border-top: 1px solid ${({ theme }) => theme.colors.default.alpha};
    gap: 8px;
    textarea {
        border-radius: 24px;
        background-color: #f6f8fc;
        padding: 8px 16px;
        border: none;
        flex-grow: 1;
        max-height: 80px;
        ::placeholder {
            color: ${HEX_COLORS.secondary};
        }
        :focus {
            outline: unset;
        }
    }
`;

export const PreloadTypingIndicator = styled.div`
    width: 0;
    height: 0;
    overflow: hidden;
    background-image: url('${STATIC_APP_ASSETS_URL}/chatbot/bulle_step_1.svg'),
        url('${STATIC_APP_ASSETS_URL}/chatbot/bulle_step_2.svg'),
        url('${STATIC_APP_ASSETS_URL}/chatbot/bulle_step_3.svg');
`;

export const TypingIndicator = styled.div`
    min-width: 69px;
    min-height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
    transform-origin: 0% 100%;

    animation:
        imageCycle 0.7s infinite,
        scaleBubble 0.3s ease;

    @keyframes imageCycle {
        0%,
        33% {
            background-image: url('${STATIC_APP_ASSETS_URL}/chatbot/bulle_step_1.svg');
        }
        34%,
        66% {
            background-image: url('${STATIC_APP_ASSETS_URL}/chatbot/bulle_step_2.svg');
        }
        67%,
        100% {
            background-image: url('${STATIC_APP_ASSETS_URL}/chatbot/bulle_step_3.svg');
        }
    }

    @keyframes scaleBubble {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
`;

export const SendMessageButton = styled(IconButton)`
    margin: auto auto 0;
    height: 36px;
    > div {
        height: 32px;
        width: 36px;
    }
`;
