import { useOrgFieldMetadata } from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useMe from 'app/common/hooks/queries/useMeUncamel';

interface DisplayBulkCheckboxType {
    displayBulkCheckbox: boolean;
    secondaryPermission: boolean;
    logoPermission: boolean;
    coverPermission: boolean;
}
export default function useDisplayBulkCheckbox(): DisplayBulkCheckboxType {
    const secondaryPermission = !!useOrgFieldMetadata('photos', 'secondary')?.enabled;
    const logoPermission = !!useOrgFieldMetadata('photos', 'logo')?.enabled;
    const coverPermission = !!useOrgFieldMetadata('photos', 'cover')?.enabled;

    const { data: me } = useMe();
    const allowedRole =
        !!me?.role && ['BUSINESS_MANAGER', 'GROUP_MANAGER', 'ORG_ADMIN'].includes(me.role);

    return {
        displayBulkCheckbox:
            allowedRole && (secondaryPermission || logoPermission || coverPermission),
        secondaryPermission,
        logoPermission,
        coverPermission,
    };
}
