import { createSelector } from 'reselect';

import { RoleValue, V2FormattedUserData } from 'app/api/types/user';
import { BUSINESS_MESSAGE } from 'app/common/data/productIds';
import { ADMIN } from 'app/common/data/roles';
import { AppState } from 'app/states/reducers';

// ACTION TYPES
export const GET_ME_REQUEST = 'GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'GET_ME_FAILURE';
export const SET_ME_DATA = 'SET_ME_DATA';

export type GetMeAction = {
    type: 'GET_ME_REQUEST';
};

export type GetMeSuccessAction = {
    type: 'GET_ME_SUCCESS';
    user: V2FormattedUserData;
};

export type GetMeFailureAction = {
    type: 'GET_ME_FAILURE';
    error: Record<string, any>;
};

type SetMeDataType = {
    type: typeof SET_ME_DATA;
    data: Object;
};

// ACTION FLOW TYPES
export type MeState = {
    data: V2FormattedUserData | null;
    isFetching: boolean;
    errors: Record<string, any>;
};

export type UserAction = GetMeAction | GetMeFailureAction | GetMeSuccessAction | SetMeDataType;

// ACTION CREATORS
export const getMe = (): GetMeAction => ({
    type: GET_ME_REQUEST,
});

export const getMeSuccess = (user: V2FormattedUserData): GetMeSuccessAction => ({
    type: GET_ME_SUCCESS,
    user,
});

export const getMeFailure = (error: Record<string, any>): GetMeFailureAction => ({
    type: GET_ME_FAILURE,
    error,
});

export const setMeData = (data: Object): SetMeDataType => ({
    type: SET_ME_DATA,
    data,
});

export const initialState = {
    data: null,
    isFetching: false,
    errors: {},
};

// REDUCER
const meReducer = (state: MeState = initialState, action: UserAction): MeState => {
    switch (action.type) {
        case GET_ME_REQUEST:
            return { ...state, errors: {}, isFetching: true };

        case GET_ME_FAILURE:
            return { ...state, errors: action.error, isFetching: false };

        case GET_ME_SUCCESS:
            return {
                ...state,
                data: action.user,
                isFetching: false,
            };
        case SET_ME_DATA:
            return {
                ...state,
                // @ts-ignore
                data: {
                    ...state.data,
                    ...action.data,
                },
            };
        default:
            return state;
    }
};

// SELECTORS
export const dataSelector = ({ data }: MeState): V2FormattedUserData | null => data;

export const isFetchingSelector = ({ isFetching }: MeState): boolean => isFetching;

export const meRoleSelector = ({ data }: MeState): RoleValue | null => (data ? data.role : null);

export const meOrgIdSelector = ({ data }: MeState): number | null => (data ? data.orgId : null);

export const productSelector = ({ data }: MeState): Array<string> =>
    data ? data.sidebarProducts : [];

export const meIdSelector = ({ data }: MeState): string | null => (data ? data.id : null);

export const meLangSelector = ({ data }: MeState): string | null => (data ? data.lang : null);

export const meEmailSelector = ({ data }: MeState): string | null => (data ? data.email : null);

export const meEmailFullSelector = ({ me }: AppState) => me.data?.email;

export const meProviderSelector = ({ data }: MeState): string | null =>
    data ? data.provider : null;

export const meProviderNameSelector = ({ data }: MeState): string | null =>
    data ? data.providerName : null;

export const meLabelSelector = createSelector(dataSelector, data =>
    data ? `${data.firstName} ${data.lastName}` : null,
);

export const isShadowUserSelector = createSelector(
    (state: AppState) => dataSelector(state.me),
    data => !!data?.shadowUserId,
);

export const isAdminShadowUserSelector = createSelector(
    (state: AppState) => dataSelector(state.me),
    data => !!data?.shadowUserId && data?.shadowUserRole === 'ADMIN',
);

export const canSendClevertapEventsSelector = createSelector(
    (state: AppState) => dataSelector(state.me),
    data => !!data?.id && !data?.shadowUserId && data?.role !== 'ADMIN',
);

export const hasAccessToMessagingSelector = createSelector(
    (state: AppState) => productSelector(state.me),
    sidebarProducts => sidebarProducts.includes(BUSINESS_MESSAGE.toLowerCase()),
);

export const hasAccessToApiKeysSelector = createSelector(
    (state: AppState) => dataSelector(state.me),
    userData =>
        (userData && !userData.sidebarExcludedPages.includes('api_key_manager')) ||
        (userData?.role && [ADMIN].includes(userData.role)),
);

export default meReducer;
