import { useCallback, useEffect, useMemo } from 'react';

import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Dialog, Stack, toast } from '@partoohub/ui';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import StatefulButton from 'app/common/components/buttons/StatefulButton';
import useMe from 'app/common/hooks/queries/useMe';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    DefaultControllerContainer,
    ModalButtonsContainer,
    PeriodicityContainer,
    StyledControlledMonthYearPicker,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/Export/ExportModalForm/ExportModalForm.styled';
import { ControlledFilters } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Form/ControlledFilters';
import { ControlledTextField } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Form/ControlledTextField';
import { useExportKeywords } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/export/useExportKeywords';
import { useExportMetrics } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/export/useExportMetrics';
import {
    DATE_DEFAULT_VALUE,
    useGetDate,
} from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/params/useGetDate';
import { DimensionsDict } from 'app/pages/analytics/VisibilityAnalyticsV2/types/dimensions';
import {
    categoryOptions,
    periodOptions,
} from 'app/pages/analytics/VisibilityAnalyticsV2/types/export';
import { MetricsApiKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/types/query';
import {
    MIN_RETENTION_DATE,
    MONTH_FORMAT,
} from 'app/pages/analytics/VisibilityAnalyticsV2/utils/date';
import { AnalyticsQueryKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queryKeys';
import { DATE_FORMAT } from 'app/utils/dates';

type Props = {
    closeModal: () => void;
};

export const ExportModalForm = ({ closeModal }: Props) => {
    const { t } = useTranslation();
    const periodOptionsTranslated = periodOptions.map(period => ({
        ...period,
        label: t(period.label),
    }));
    const categoryOptionsTranslated = categoryOptions.map(category => ({
        ...category,
        label: t(category.label),
    }));
    const { data, isLoading } = useMe();
    const dates = useGetDate();
    const businessFilters = useBusinessModalFilters();
    const [dateFrom, setDateFrom] = useStateQueryParams(AnalyticsQueryKeys.DATE_FROM);
    const [dateTo, setDateTo] = useStateQueryParams(AnalyticsQueryKeys.DATE_TO);
    const startDate = dateFrom ? new Date(dateFrom) : new Date(dates.filter_date__gte);
    const endDate = dateTo ? new Date(dateTo) : new Date(dates.filter_date__lte);

    const currentDate = new Date();
    const minDate = MIN_RETENTION_DATE;
    const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const handleStartDate = (date: any) => {
        setDateFrom(date ? format(date, DATE_FORMAT) : '');
        setValue('dateRange', { startDate: date, endDate });
    };

    const handleEndDate = (date: any) => {
        setDateTo(date ? format(endOfMonth(date), DATE_FORMAT) : '');
        setValue('dateRange', { startDate, endDate: date });
    };

    const handleReset = useCallback(() => {
        handleStartDate(null);
        handleEndDate(null);
    }, []);

    const [comparison] = useStateQueryParams(AnalyticsQueryKeys.COMPARISON);
    const defaultFormPeriod = useMemo(
        () =>
            comparison && comparison === 'year'
                ? periodOptionsTranslated[3]
                : periodOptionsTranslated[2],
        [comparison],
    );

    const setFormValue = () => ({
        email: data?.email || '',
        category: {
            [categoryOptionsTranslated[0].name]: true,
        },
        period: {
            [defaultFormPeriod.name]: true,
        },
        dateRange: {
            startDate,
            endDate,
        },
    });

    const form = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    const { control, handleSubmit, watch, setValue, reset } = form;

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const watchCategoryCheck = watch('category');
    const showPeriodicitySelector = useMemo(() => {
        const isQueriesOptionSelected = watchCategoryCheck['queries'];
        return !isQueriesOptionSelected;
    }, [watchCategoryCheck]);

    const exportMetrics = useExportMetrics();
    const exportKeywords = useExportKeywords();

    const formatPayload = payload => {
        const selectedItem = Object.entries(payload.period).find(([, value]) => value === true);
        const selectedItemString = selectedItem?.[0];

        if (!selectedItemString) return;

        return {
            ...businessFilters,
            [MetricsApiKeys.DIMENSIONS]: `${DimensionsDict.BUSINESS},${selectedItemString}`,
            [MetricsApiKeys.ORDER_BY]: selectedItemString,
            [MetricsApiKeys.FILTER_DATE_GTE]: `${format(startOfMonth(payload.dateRange.startDate), DATE_FORMAT)}`,
            [MetricsApiKeys.FILTER_DATE_LTE]: `${format(endOfMonth(payload.dateRange.endDate), DATE_FORMAT)}`,
            email: payload.email,
        };
    };

    const onSubmit = payload => {
        const promises = [];
        const query = formatPayload(payload);

        if (!query) {
            return;
        }

        if (['all', 'views_actions'].some(item => payload.category[item])) {
            promises.push(exportMetrics.mutateAsync(query));
        }
        if (['all', 'queries'].some(item => payload.category[item])) {
            promises.push(exportKeywords.mutateAsync(query));
        }
        Promise.all(promises)
            .then(() => {
                closeModal();
                toast.success(null, t('your_analytics_will_be_sent_by_email'));
            })
            .catch(error => {
                closeModal();
                toast.error(error.message, t('request_issue'));
            });
    };

    const submitForm = () => {
        handleSubmit(onSubmit)();
    };

    const illustrationElement: IllustrationElement = {
        name: IllustrationOldEnum.Download,
        type: IllustrationCategoryEnum.Old,
    };

    return (
        <Dialog
            dataTrackId="visibility_export_modal"
            header={
                <div style={{ paddingTop: '24px' }}>
                    <Illustration
                        dataTrackId=""
                        height="140"
                        illustration={illustrationElement}
                        width="320"
                    />
                </div>
            }
            onClose={closeModal}
            title={t('export_data')}
            subTitle={t('export_description')}
        >
            <Stack gap="20px" direction="column">
                <DefaultControllerContainer>
                    <StyledControlledMonthYearPicker
                        name="dateRange"
                        control={control}
                        startDate={startDate}
                        endDate={endDate}
                        handleReset={handleReset}
                        monthFormat={MONTH_FORMAT}
                        handleStartDate={handleStartDate}
                        handleEndDate={handleEndDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        canReset={
                            dates.filter_date__gte !==
                                DATE_DEFAULT_VALUE[MetricsApiKeys.FILTER_DATE_GTE] ||
                            dates.filter_date__lte !==
                                DATE_DEFAULT_VALUE[MetricsApiKeys.FILTER_DATE_LTE]
                        }
                    />
                    <ControlledFilters
                        name="category"
                        menuListArray={[{ options: categoryOptionsTranslated }]}
                        control={control}
                        placeholder={t('category_word')}
                        disableReset
                    />
                </DefaultControllerContainer>
                <PeriodicityContainer>
                    <ControlledTextField
                        dataTrackId="export_analytics__email__text_input"
                        name="email"
                        control={control}
                        label={t('email_input_placeholder')}
                        required
                        type="email"
                        width={showPeriodicitySelector ? '290px' : '100%'}
                    />
                    {showPeriodicitySelector && (
                        <ControlledFilters
                            name="period"
                            menuListArray={[{ options: periodOptionsTranslated }]}
                            control={control}
                            placeholder={t('periodicity_option')}
                            disableReset
                        />
                    )}
                </PeriodicityContainer>
            </Stack>
            <ModalButtonsContainer>
                <StatefulButton label={t('cancel')} status="cancel" onClick={closeModal} />
                <StatefulButton
                    label={t('send_email')}
                    status="tosave"
                    onClick={submitForm}
                    hideIcon
                />
            </ModalButtonsContainer>
        </Dialog>
    );
};
