import { IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DefaultShortcutComponent } from 'app/pages/Homepage/sections/HomepageShortcuts/DefaultShortcutComponent';
import { POSTS_PATH } from 'app/routing/routeIds';

export const ShortcutCreatePost = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <DefaultShortcutComponent
            title={t('create_post')}
            variant="slate"
            iconElement={['fa-newspaper', IconPrefix.SOLID]}
            onClick={() => navigate(POSTS_PATH)}
        />
    );
};
