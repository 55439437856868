import { Dispatch, SetStateAction } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';

import { StyledCloseButton } from './ActionModal.styled';

type Props = {
    isOpenConfirmModal: boolean;
    onConfirm: () => void;
    onHide: () => void;
    email: string;
    type: 'export' | 'import';
    setEmail: Dispatch<SetStateAction<string>>;
};

export const ActionModal = ({
    isOpenConfirmModal,
    onConfirm,
    onHide,
    email,
    setEmail,
    type,
}: Props) => {
    const { t } = useTranslation();

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const isError = !emailRegex.exec(email);
    const notice = isError ? t('email_not_valid') : '';

    return (
        <ConfirmModal
            modalFor="download"
            trackId={`bulk_reply_template_${type}__modal`}
            show={isOpenConfirmModal}
            title={t(`bulk_${type}_report`)}
            content={t(`${type}_sent_to_this_email`)}
            confirmLabel={t('send_email')}
            onHide={onHide}
            onConfirm={onConfirm}
            hideOnClickOutside={false}
            confirmDisabled={isError}
        >
            <TextInput
                dataTrackId="template_data__email_textinput"
                label={t('email_input_placeholder')}
                onChange={setEmail}
                value={email}
                required
                type="email"
                error={isError}
                notice={notice}
            />
            <StyledCloseButton
                appearance="outlined"
                dataTrackId={`${type}_modal_close_button`}
                icon={[FontAwesomeIconsPartooUsed.faClose, IconPrefix.REGULAR]}
                onClick={onHide}
            />
        </ConfirmModal>
    );
};
