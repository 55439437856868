import { KeyboardEvent, useState } from 'react';

import { Button, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { KeyCode } from 'app/common/types/keyboard';
import useCreateSection from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/hooks/sections/useCreateSection';
import groupsTracking from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/groupsTracking';

import {
    ModalButtons,
    ModalDescription,
    ModalTitle,
    SmallModalContainer,
} from '../../GroupsSettingsModals.styled';

type Props = {
    closeModal: () => void;
};

const CreateSectionContent = ({ closeModal }: Props) => {
    const { t } = useTranslation();
    const { data: me } = useMe();

    const [sectionName, setSectionName] = useState<string>('');

    const createSection = useCreateSection(() => {
        closeModal();
        groupsTracking.clickSectionCreation(me?.role);
    });
    // @ts-ignore
    const error = createSection.error?.response?.data?.errors?.json?.name;

    const createSectionAction = () => {
        createSection.mutate(sectionName);
    };

    const handlePressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyCode.Enter) {
            createSectionAction();
        }
    };

    return (
        <SmallModalContainer>
            <ModalTitle>🚀 {t('section_modal_title')}</ModalTitle>
            <ModalDescription>{t('section_modal_subtitle')}</ModalDescription>
            <TextInput
                dataTrackId={sectionName}
                value={sectionName}
                onChange={value => {
                    setSectionName(value ?? '');
                }}
                label={t('section_modal_field')}
                error={error}
                notice={error ? t(error) : ''}
                onKeyUp={handlePressEnter}
                autoFocus
            />
            <ModalButtons>
                <Button
                    appearance="outlined"
                    variant="secondary"
                    dataTrackId="create_section_cancel"
                    onClick={closeModal}
                >
                    {t('cancel')}
                </Button>
                <Button
                    dataTrackId="create_section_create"
                    variant="primary"
                    onClick={createSectionAction}
                    disabled={!sectionName}
                >
                    {t('section_modal_create_button')}
                </Button>
            </ModalButtons>
        </SmallModalContainer>
    );
};

export default CreateSectionContent;
