import { UNGROUPED_ID } from 'app/common/reducers/groups';

type GroupIdParams = {
    group_id?: number | null;
    group_id__is_null?: boolean;
};

const getGroupIdParams = (groupId: number | null): GroupIdParams => ({
    group_id: groupId === UNGROUPED_ID ? undefined : groupId,
    group_id__is_null: groupId === UNGROUPED_ID ? true : undefined,
});

export default getGroupIdParams;
