import { useEffect } from 'react';

import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import LoadingView from 'app/common/components/LoadingView';
import { BUSINESSES, GROUPS } from 'app/common/data/queryKeysConstants';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';

import queryClient from 'app/states/queryClient';

import { BusinessListContainer } from './BusinessListWithNewSidebar.styled';
import useIsLoadingBusinessEditRedirection from './hooks/businesses/useIsLoadingBusinessEditRedirection';
import { useGroupContext } from './hooks/groups/useGroupContext';
import BusinessAdviceSection from './sections/BusinessAdviceSection';
import BusinessFiltersSection from './sections/BusinessFiltersSection';
import BusinessListGroupBanner from './sections/BusinessListGroupBanner';
import BusinessManagementModal from './sections/BusinessListHeader/OldHeaderButton/GroupModals/BusinessManagementModal';
import BusinessListSection from './sections/BusinessListSection';
import BusinessSortSection from './sections/BusinessSortSection';
import { FloatingBar } from './sections/FloatingBar';

export const BusinessListWithNewSidebar = () => {
    const { reset } = useGroupContext();

    // Reset queries cache
    useEffect(() => {
        document.getElementById('react-content')?.scrollTo(0, 0);
        // important to reset and not invalidate
        queryClient.resetQueries(invalidatesAny(BUSINESSES));
        queryClient.invalidateQueries(invalidatesAny(GROUPS));
        reset();
    }, []);

    // Redirect to Business Edit if user only has 1 business
    const isLoading = useIsLoadingBusinessEditRedirection();

    const enableNewGroups = useNewGroups();

    if (isLoading) {
        return <LoadingView />;
    }

    return (
        <>
            <FloatingBar />
            <BusinessListContainer>
                {!enableNewGroups && (
                    <>
                        {/* Business modal for Groups. Uses redux */}
                        {/* Only here to keep groups alive. Please kill it with fire once groups are deleted */}
                        <BusinessManagementModal />
                    </>
                )}
                {/* Banner for Groups if has puller*/}
                <BusinessListGroupBanner />
                <BusinessAdviceSection />
                <BusinessFiltersSection />
                <BusinessSortSection />
                <BusinessListSection />
            </BusinessListContainer>
        </>
    );
};
