import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TransparencyEnum } from '@partoohub/branding';
import { Checkbox, Stack } from '@partoohub/ui';

export const StyledCheckbox = styled(Checkbox)`
    margin: 0;
`;

export const ImportFieldSection = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: solid 1px ${({ theme }) => theme.colors.secondary.alpha};
    margin-bottom: 16px;
    background-color: ${({ theme }) => theme.colors.theme.initial};
`;

export const ImportFieldSectionTop = styled.div`
    display: flex;
    height: 80px;
    padding: 0 24px;
`;

export const StyledStack = styled(Stack)<{ disabled?: boolean }>`
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: ${TransparencyEnum.DISABLED};
            pointer-events: none;
        `};
`;

export const ImportFieldSectionFields = styled.div`
    border-top: solid 1px ${({ theme }) => theme.colors.secondary.alpha};
`;
