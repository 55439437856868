import { List } from '@partoohub/ui';

import { FeedbackFormData } from 'app/api/types/feedbackForm';

import { FeedbackFormActions } from './FeedbackFormActions';
import { FeedbackFormTitle } from './FeedbackFormTitle';

interface Props {
    form: FeedbackFormData;
}

export const FeedbackFormListItem = ({ form }: Props) => {
    return (
        <List.Row dataTrackId="" id={form.id} disableAnimationOnHover>
            <FeedbackFormTitle form={form} />
            <FeedbackFormActions form={form} />
        </List.Row>
    );
};
