import { FC } from 'react';

import {
    REVIEWS_COMMENTS,
    REVIEWS_DATE_FROM,
    REVIEWS_DATE_TO,
    REVIEWS_KEYWORDS,
    REVIEWS_PLATFORMS,
    REVIEWS_RATING,
    REVIEWS_TAG,
} from 'app/common/data/routeIds';
import { useGetParamReduxSync } from 'app/common/hooks/useGetParamReduxSync';
import { AppState } from 'app/states/reducers';

export const GetParamsReduxSync: FC = () => {
    useGetParamReduxSync(
        REVIEWS_DATE_FROM,
        [(state: AppState) => state.reviews.filters.date, 'from'],
        [(state: AppState) => state.reviews.filters.pending.date, 'from'],
    );

    useGetParamReduxSync(
        REVIEWS_DATE_TO,
        [(state: AppState) => state.reviews.filters.date, 'to'],
        [(state: AppState) => state.reviews.filters.pending.date, 'to'],
    );

    useGetParamReduxSync(
        REVIEWS_PLATFORMS,
        [(state: AppState) => state.reviews.filters, 'platform'],
        [(state: AppState) => state.reviews.filters.pending, 'platform'],
    );

    useGetParamReduxSync(
        REVIEWS_RATING,
        [(state: AppState) => state.reviews.filters, 'rating'],
        [(state: AppState) => state.reviews.filters.pending, 'rating'],
    );

    useGetParamReduxSync(
        REVIEWS_COMMENTS,
        [(state: AppState) => state.reviews.filters, 'message'],
        [(state: AppState) => state.reviews.filters.pending, 'message'],
    );

    useGetParamReduxSync(
        REVIEWS_KEYWORDS,
        [(state: AppState) => state.reviews.filters, 'keyword'],
        [(state: AppState) => state.reviews.filters.pending, 'keyword'],
    );

    useGetParamReduxSync(
        REVIEWS_TAG,
        [(state: AppState) => state.reviews.filters, REVIEWS_TAG],
        [(state: AppState) => state.reviews.filters.pending, REVIEWS_TAG],
    );

    return null;
};
