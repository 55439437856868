import styled from '@emotion/styled';

import { GetMaxWidthContentPage } from 'app/global.styled';

export const CompetitiveBenchmarkContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    ${GetMaxWidthContentPage}
    height: 100%;
`;

export const BenchmarkContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    position: relative;
    gap: 24px;
`;
