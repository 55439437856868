import React from 'react';

import { useTranslation } from 'react-i18next';

import useCompletionRate from 'app/businessEditV2/hooks/business/useCompletionRate';
import {
    HIGH_RATE,
    LOW_RATE,
    MID_RATE,
} from 'app/businessEditV2/utils/businessBanner';

import {
    BadgeHigh,
    BadgeLow,
    BadgeMid,
    LineHigh,
    LineLow,
    LineMid,
} from './BannerIcons';
import {
    BannerMessage,
    BusinessBannerContainer,
    BusinessBannerImages,
} from './BusinessBanner.styled';

type BannerInfosType = {
    message: string;
    IconLeft: React.ReactNode;
    IconRight: React.ReactNode;
};

const BusinessBanner = () => {
    const { t } = useTranslation();
    const completionRate = useCompletionRate();

    const getBannerInfos = (): BannerInfosType => {
        if (completionRate <= LOW_RATE) {
            return {
                message: t('business_edit_banner_message_low'),
                IconLeft: <BadgeLow />,
                IconRight: <LineLow />,
            };
        }
        if (completionRate > LOW_RATE && completionRate <= MID_RATE) {
            return {
                message: t('business_edit_banner_message_mid'),
                IconLeft: <BadgeMid />,
                IconRight: <LineMid />,
            };
        }
        if (completionRate > MID_RATE && completionRate <= HIGH_RATE) {
            return {
                message: t('business_edit_banner_message_high'),
                IconLeft: <BadgeHigh />,
                IconRight: <LineHigh />,
            };
        }
        if (completionRate > HIGH_RATE) {
            return {
                message: t('business_edit_banner_message_perfect'),
                IconLeft: <BadgeHigh />,
                IconRight: <LineHigh />,
            };
        }
        return {
            message: '',
            IconLeft: null,
            IconRight: null,
        };
    };

    const { message, IconLeft, IconRight } = getBannerInfos();

    return (
        <BusinessBannerContainer>
            <BannerMessage>{message}</BannerMessage>
            <BusinessBannerImages>
                {IconLeft}
                {IconRight}
            </BusinessBannerImages>
        </BusinessBannerContainer>
    );
};

export default BusinessBanner;
