import { useState } from 'react';

import { Button, toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import PhotosTable from 'app/businessEditV2/sections/PhotosSection/PhotosForm/PhotosTable';
import getAllPhotos from 'app/businessEditV2/utils/photos';
import { useBusinessSelectionContext } from 'app/pages/visibility/BusinessListV2/hooks/businessSelection/useBusinessSelectionContext';
import useDisplayBulkCheckbox from 'app/pages/visibility/BusinessListV2/hooks/businessSelection/useDisplayBulkCheckbox';
import { FunnelModalTemplate } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/components/FunnelModalTemplate/FunnelModalTemplate';
import { FunnelBulkInAppStepsEnum } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/data/funnelSteps';
import { useFunnelModalContext } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/hooks/FunnelModalContext';
import useApplyBulkInApp from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/hooks/useApplyBulkInApp';

import { AddPhotosConfirmationModal } from './AddPhotosConfirmationModal';

export const AddPhotosPage = () => {
    const { t } = useTranslation();
    const { setCurrentStep, setShowFunnel, photos, setPhotos } = useFunnelModalContext();
    const { secondaryPermission, logoPermission, coverPermission } = useDisplayBulkCheckbox();
    const { resetBusinessSelection } = useBusinessSelectionContext();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { mutate } = useApplyBulkInApp(
        () => {
            resetBusinessSelection();
            setShowConfirmationModal(false);
            setShowFunnel(false);
            setCurrentStep(FunnelBulkInAppStepsEnum.SELECT_FIELD);
            toast.success(
                t('bulk_in_app_funnel__add_photos_page__success_toast_desc'),
                t('bulk_in_app_funnel__add_photos_page__success_toast_title'),
            );
        },
        () => {
            setShowConfirmationModal(false);
            toast.error(
                t('bulk_in_app_funnel__add_photos_page__error_toast_desc'),
                t('bulk_in_app_funnel__add_photos_page__error_toast_title'),
            );
        },
    );

    const disabled = !getAllPhotos(photos).length;

    return (
        <FunnelModalTemplate
            onBack={() => setCurrentStep(FunnelBulkInAppStepsEnum.SELECT_FIELD)}
            footer={
                <Button
                    dataTrackId="bulk_in_app__add_photos_page__save"
                    variant="primary"
                    iconPosition="left"
                    icon={['fa-save']}
                    size="large"
                    onClick={() => setShowConfirmationModal(true)}
                    disabled={disabled}
                    full
                >
                    {t('save')}
                </Button>
            }
        >
            <PhotosTable
                coverPermission={coverPermission}
                logoPermission={logoPermission}
                secondaryPermission={secondaryPermission}
                value={photos}
                onChange={setPhotos}
            />
            <AddPhotosConfirmationModal
                show={showConfirmationModal}
                setShow={setShowConfirmationModal}
                onSave={() => mutate({ photos })}
            />
        </FunnelModalTemplate>
    );
};
