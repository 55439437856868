export const sectionsTranslations = {
    infos: 'main_infos',
    description: 'business_description',
    attributes: 'attributes',
    links: 'links',
    contact: 'contact',
    photos: 'photos',
    services: 'services',
    food_menu: 'food_menus',
    hours: 'business_hours',
    advanced_settings: 'advanced_settings',
    custom_fields: 'custom_fields',
    review_booster: 'rb_invite_title',
    review_management: 'review_management',
    posts: 'permission_section_posts',
    feedback_management: 'feedback_management',
};
export const fieldsTranslations = {
    national_identification_number: 'siret',
    status: 'status',
    email: 'email_extended',
    full_name: 'complete_contact_name',
    phone_number: 'phone',
    fax: 'fax',
    description_short: 'description_short',
    description_long: 'description_long',
    categories: 'categories',
    website_url: 'website_extended',
    facebook_url: 'facebook_page',
    twitter_url: 'twitter_profile',
    name: 'business_name',
    country: 'country',
    city: 'city',
    zipcode: 'zipcode',
    address_full: 'address_full',
    address2: 'business_address_complement',
    coordinates: 'location',
    logo: 'logo',
    cover: 'primary_photo',
    secondary: 'additional_photos',
    open_hours: 'business_open_hours',
    specific_hours: 'business_specific_hours',
    code: 'store_code',
    attributes: 'attributes',
    attributes_url: 'additional_urls',
    services: 'services',
    food_menu: 'food_menus',
    review_booster_expeditor_name: 'expeditor_name',
    review_booster_sms_template: 'edit_sms_template_title',
    more_hours: 'more_hours',
    review_assign_tag: 'permission_tag_review',
    review_reply_suggestion: 'permission_ai_suggestion',
    manage_posts: 'permission_posts_manage',
    review_reply: 'permission_review_reply',
    review_create_reply_template: 'permission_reply_template_manage',
    feedback_result_reply: 'feedback_result_reply',
    feedback_result_reply_suggestion: 'feedback_result_reply_suggestion',
};
