import { useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { attributesEnumType } from 'app/api/types/attributes';
import EditForm from 'app/businessEditV2/components/EditForm';
import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import useFormattedBusinessAttributes from 'app/businessEditV2/hooks/attributes/attributesBusiness/useFormattedBusinessAttributes';
import useFormattedAttributes from 'app/businessEditV2/hooks/attributes/attributesMetadata/useFormattedAttributes';
import useAttributesUpdate from 'app/businessEditV2/hooks/attributes/useAttributesUpdate';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import useIsLoadingAddress from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingAddress';
import useAttributesSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useAttributesSectionColor';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import AttributesTypeRepeatableEnum from 'app/businessEditV2/sections/AttributesSection/AttributesComponents/AttributesTypeRepeatableEnum';
import AttributesIndex from 'app/businessEditV2/sections/AttributesSection/AttributesIndex';
import ScrollSpyLink from 'app/businessEditV2/sections/AttributesSection/ScrollSpy/ScrollSpyLink';
import ScrollSpyProvider from 'app/businessEditV2/sections/AttributesSection/ScrollSpy/ScrollSpyProvider';
import CustomFieldsTypeBoolean from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeBoolean';
import CustomFieldsTypeSingleSelect from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeSingleSelect';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

import {
    AttributesFormContainer,
    AttributesFormLeft,
    AttributesSection,
    AttributesSectionName,
} from './AttributesForm.styled';

type Props = {
    closeModal: () => void;
    useDirtyUpdate: (boolean: boolean) => void;
};

export const ATTRIBUTES_CONTAINER_ID = 'attributes-form-modal';
export const TOP_FIXED_INDEX = 56;
export const TRIGGER_FIXED_POSITION = 185 - TOP_FIXED_INDEX;

const AttributesForm = ({ closeModal, useDirtyUpdate }: Props) => {
    const { t } = useTranslation();
    const colorState = useAttributesSectionColor();
    const { data: business } = useBusiness();
    const businessAttributes = useFormattedBusinessAttributes();
    const attributes = useFormattedAttributes();
    const isLoading = useIsLoadingAddress();
    const navigate = useNavigate();

    useEffect(
        () =>
            function () {
                navigate({ hash: '' }, { replace: true });
            },
        [],
    );

    const setFormValue = () => {
        const result: Record<string, any> = {};
        businessAttributes.forEach(attribute => {
            result[attribute.gmb_id] = attribute.value;
        });
        return result;
    };

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: useMemo(() => setFormValue(), [businessAttributes]),
    });

    useDirtyUpdate(isDirty);

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const attributesUpdate = useAttributesUpdate(() => {
        businessUpdateCompletionRate.mutate();
        closeModal();
    });

    const onSubmit = payload => {
        attributesUpdate.mutate(payload);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_ATTRIBUTES_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
    };

    // Permission
    const businessIsOpen = !useIsBusinessClosed();
    const disabled = !useFieldMetadata('attributes', 'attributes')?.enabled && businessIsOpen;

    // Remove all non url attributes
    const formatPayload = payload => {
        if (disabled) {
            return { attributes: [] };
        }

        return {
            attributes: Object.entries(payload).map(([key, value]) => {
                const attribute = businessAttributes.find(attr => attr.gmb_id === key);
                return {
                    gmb_id: key,
                    type: attribute?.type,
                    value,
                };
            }),
        };
    };

    const submitForm = () => {
        handleSubmit(payload => onSubmit(formatPayload(payload)))();
        trackSaveBusinessEditSection(SectionNames.ATTRIBUTES, colorState);
    };

    return (
        <>
            <ScrollSpyProvider offset={56}>
                <EditForm
                    id={ATTRIBUTES_CONTAINER_ID}
                    title={t('attributes')}
                    description={t('business_edit_attributes_description')}
                    colorState={colorState}
                >
                    <AttributesFormContainer>
                        <AttributesIndex categories={attributes.map(group => group.name)} />
                        <AttributesFormLeft>
                            {attributes.map(group => (
                                <ScrollSpyLink
                                    id={group.name}
                                    key={`attributes_group_${group.name}`}
                                >
                                    <AttributesSection>
                                        <AttributesSectionName>{group.name}</AttributesSectionName>
                                        {group.attributes.map((attribute, index) => {
                                            const businessAttribute = businessAttributes.find(
                                                data => data.gmb_id === attribute.gmb_id,
                                            );
                                            const name = businessAttribute?.gmb_id ?? '';
                                            const displayName = attribute?.name;
                                            const key = `attribute_${name}_${index}`;
                                            switch (attribute.type) {
                                                case attributesEnumType.BOOL:
                                                    return (
                                                        <CustomFieldsTypeBoolean
                                                            name={name}
                                                            control={control}
                                                            key={key}
                                                            disabled={disabled}
                                                            displayName={displayName}
                                                        />
                                                    );

                                                case attributesEnumType.ENUM:
                                                    return attribute.options ? (
                                                        <CustomFieldsTypeSingleSelect
                                                            name={name}
                                                            control={control}
                                                            key={key}
                                                            possibleValues={attribute.options.map(
                                                                option => ({
                                                                    label: option.name,
                                                                    value: option.id,
                                                                }),
                                                            )}
                                                            disabled={disabled}
                                                            displayName={displayName}
                                                        />
                                                    ) : null;

                                                case attributesEnumType.REPEATABLE_ENUM:
                                                    return attribute.options ? (
                                                        <AttributesTypeRepeatableEnum
                                                            name={name}
                                                            control={control}
                                                            key={key}
                                                            possibleValues={attribute.options.map(
                                                                option => ({
                                                                    label: option.name,
                                                                    value: option.id,
                                                                }),
                                                            )}
                                                            disabled={disabled}
                                                            displayName={displayName}
                                                        />
                                                    ) : null;

                                                default:
                                                    return null;
                                            }
                                        })}
                                    </AttributesSection>
                                </ScrollSpyLink>
                            ))}
                        </AttributesFormLeft>
                    </AttributesFormContainer>
                </EditForm>
            </ScrollSpyProvider>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={attributesUpdate.isLoading}
                    hasError={!!attributesUpdate.error}
                    onClick={submitForm}
                    dataTrackId="visibility_location__form_attributes__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default AttributesForm;
