import { BusinessCreateContainer } from './BusinessCreateWithNewSidebar.styled';
import BusinessCreateContent from './sections/BusinessCreateContent';

export const BusinessCreateWithNewSidebar = () => {
    return (
        <BusinessCreateContainer>
            <BusinessCreateContent />
        </BusinessCreateContainer>
    );
};
