import { useTranslation } from 'react-i18next';

import { SettingMenuItemsData } from 'app/pages/settingsV2/subComponents/SettingsMenuList/SettingsMenuList';
import {
    SETTINGS_REVIEWS_AUTO_REPLY_PATHNAME,
    SETTINGS_REVIEWS_AUTO_REPLY_WITHOUT_COMMENT_PATHNAME,
    SETTINGS_REVIEWS_AUTO_REPLY_WITH_COMMENT_PATHNAME,
    SETTINGS_REVIEWS_REPLY_TEMPLATES_PATHNAME,
    SETTINGS_REVIEWS_SURVEYS_PATHNAME,
} from 'app/routing/routeIds';

import { ReviewSettingsMenu, useCanAccessReviewsSetting } from './useCanAccessReviewsSetting';

export const useSettingReviewsMenu = (): SettingMenuItemsData => {
    const { t } = useTranslation();
    const { menuAccess } = useCanAccessReviewsSetting();

    return {
        menus: [
            {
                label: t('settings_menu_reviews_templates'),
                pathname: SETTINGS_REVIEWS_REPLY_TEMPLATES_PATHNAME,
                options: [
                    {
                        name: 'reply-templates',
                        value: 'reply-templates',
                        label: t('settings_menu_reviews_reply_templates'),
                        pathname: SETTINGS_REVIEWS_REPLY_TEMPLATES_PATHNAME,
                        display: menuAccess[ReviewSettingsMenu.REPLY_TEMPLATES],
                    },
                ],
            },
            {
                label: t('settings_menu_reviews_automatic_messages'),
                pathname: SETTINGS_REVIEWS_AUTO_REPLY_PATHNAME,
                options: [
                    {
                        name: 'without-comment',
                        value: 'without-comment',
                        label: t('settings_menu_reviews_without_comment'),
                        pathname: SETTINGS_REVIEWS_AUTO_REPLY_WITHOUT_COMMENT_PATHNAME,
                        display: menuAccess[ReviewSettingsMenu.AUTO_REPLY_WITHOUT_COMMENT],
                    },
                    {
                        name: 'with-comment',
                        value: 'with-comment',
                        label: t('settings_menu_reviews_with_comment'),
                        pathname: SETTINGS_REVIEWS_AUTO_REPLY_WITH_COMMENT_PATHNAME,
                        display: menuAccess[ReviewSettingsMenu.AUTO_REPLY_WITH_COMMENT],
                    },
                ],
            },
            {
                label: t('feedback_forms'),
                pathname: SETTINGS_REVIEWS_SURVEYS_PATHNAME,
                options: [
                    {
                        name: 'share-surveys',
                        value: 'share-surveys',
                        label: t('feedback_forms_share'),
                        pathname: SETTINGS_REVIEWS_SURVEYS_PATHNAME,
                        display: Boolean(menuAccess[ReviewSettingsMenu.FBM]),
                    },
                ],
            },
        ],
    };
};
