import { createContext, useEffect, useMemo, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import useReduxFilteredBusinesses from 'app/common/components/businessModal/hooks/useReduxFilteredBusinesses';
import useTotalBusinessesCount from 'app/common/components/businessModal/hooks/useTotalBusinessesCount';
import {
    applyFilterByBusinessFilters,
    resetBusinessFilter,
    setIncludedIdsFilter,
} from 'app/common/components/businessModalDeprecated/reducers';
import LoadingView from 'app/common/components/LoadingView';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { NoPresenceManagement } from 'app/common/components/placeholder/PresenceManagement/NoPresenceManagement';
import { CONNECTION_STATS, POSTS_BUSINESS_SCOPES } from 'app/common/data/queryKeysConstants';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import { FilterBar } from 'app/presence/googlePosts/components/googlePostList/FilterBar';
import PostCreationModalComponent from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/PostCreationModalComponent';
import GooglePostDeleteConfirmModal from 'app/presence/googlePosts/components/googlePostList/PostDeleteConfirmModal';
import { PostsListStyled } from 'app/presence/googlePosts/components/googlePostList/PostsList.styled';
import PostsTable from 'app/presence/googlePosts/components/googlePostList/PostsTable';
import usePosts from 'app/presence/googlePosts/hooks/usePosts';

import { PostListBanners } from './Banners';
import { TitleBar } from './TitleBar';
import { usePostsListIsLoading } from '../../hooks/usePostsListIsLoading';

export const EditPostsContext = createContext<EditPostsContextType>({} as any);

export type EditPostsContextType = {
    postCreationModalEditMode: boolean;
    postId: string;
    bulkId: number | null;
    postToEditData: Record<string, any>;
    setPostCreationModalEditMode: (value: boolean) => void;
    setPostId: (value: string) => void;
    setBulkId: (value: number | null) => void;
    setPostToEditData: (post: Record<string, any>) => void;
    showCreationModal: boolean;
    setShowCreationModal: (value: boolean) => void;
    duplicateMode: boolean;
    setDuplicateMode: (value: boolean) => void;
    duplicateModeData: any;
    setDuplicateModeData: (value: any) => void;
};

export const PostsQueryContext = createContext<ReturnType<typeof usePosts>>({} as any);

export default function PostsList() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showCreationModal, setShowCreationModal] = useState(false);
    const [postId, setPostId] = useState('');
    const [postCount, setPostCount] = useState(0);
    const [postCreationModalEditMode, setPostCreationModalEditMode] = useState(false);
    const [postToEditData, setPostToEditData] = useState({});
    const [duplicateMode, setDuplicateMode] = useState(false);
    const [duplicateModeData, setDuplicateModeData] = useState({});
    const [bulkId, setBulkId] = useState<number | null>(null);
    const { subscribedToPM } = useBusinessSubscriptions();
    const totalBusinesses = useTotalBusinessesCount();

    const { businesses } = useReduxFilteredBusinesses();
    const businessId = businesses?.length === 1 ? businesses[0].id : null;
    const handleCreatePost = () => {
        if (businessId) {
            dispatch(setIncludedIdsFilter([businessId]));
            dispatch(applyFilterByBusinessFilters());
        } else {
            dispatch(resetBusinessFilter());
        }

        setShowCreationModal(true);
    };

    const editPostsContext: EditPostsContextType = useMemo(
        () => ({
            postCreationModalEditMode,
            postId,
            bulkId,
            postToEditData,
            setPostCreationModalEditMode,
            setPostId,
            setBulkId,
            setPostToEditData,
            showCreationModal,
            setShowCreationModal,
            duplicateMode,
            setDuplicateMode,
            duplicateModeData,
            setDuplicateModeData,
        }),
        [
            postCreationModalEditMode,
            postId,
            bulkId,
            postToEditData,
            duplicateMode,
            duplicateModeData,
        ],
    );

    const postsQueryContext = usePosts(!postId && !showCreationModal);
    const queryClient = useQueryClient();

    useEffect(() => {
        // TODO: Check if needed
        const reactContent = document.getElementById('react-content');
        if (reactContent) {
            reactContent.scrollTo({
                top: 0,
            });
        }

        return () => {
            dispatch(resetBusinessFilter());

            queryClient.invalidateQueries({
                queryKey: CONNECTION_STATS,
                refetchActive: false,
            });

            queryClient.invalidateQueries({
                queryKey: POSTS_BUSINESS_SCOPES,
                refetchActive: false,
            });
        };
    }, []);

    const onDeleteClick = (googlePostId: string, bulkId: number | null): void => {
        setPostId(googlePostId);
        setBulkId(bulkId);
        setShowConfirmationModal(true);
    };

    const onHideDeleteModal = () => {
        setShowConfirmationModal(false);
        setPostId('');
        setBulkId(null);
    };

    const onHideCreationModal = () => setShowCreationModal(false);
    const isLoading = usePostsListIsLoading();
    let illustrationElement: IllustrationLaptopElement;
    switch (true) {
        case isLoading:
            return <LoadingView />;
        case !subscribedToPM:
            return <NoPresenceManagement />;

        case totalBusinesses === 0:
            illustrationElement = {
                type: IllustrationCategoryEnum.Laptop,
                name: IllustrationLaptopEnum.Monocle,
            };
            return (
                <NotFoundTemplate
                    withButton
                    show
                    title={t('all_businesses_unable_to_create_posts')}
                    imgSrc={illustrationElement}
                    buttonText={t('contact_our_support')}
                    handleClick={() => {
                        try {
                            // @ts-ignore
                            window.Intercom('showNewMessage');
                        } catch (e) {
                            console.error(e.message);
                        }
                    }}
                />
            );
        default:
            return (
                <EditPostsContext.Provider value={editPostsContext}>
                    <PostsQueryContext.Provider value={postsQueryContext}>
                        <PostsListStyled>
                            <GooglePostDeleteConfirmModal
                                show={showConfirmationModal}
                                onHide={onHideDeleteModal}
                            />
                            {showCreationModal &&
                                ReactDOM.createPortal(
                                    <PostCreationModalComponent
                                        show={showCreationModal}
                                        onHide={onHideCreationModal}
                                        isEditing={postCreationModalEditMode}
                                        postEditingData={postToEditData}
                                        isDuplicating={duplicateMode}
                                        duplicatePostData={duplicateModeData}
                                    />,
                                    document.body,
                                )}
                            <TitleBar onCreationButtonClick={handleCreatePost} />
                            <PostListBanners />
                            <FilterBar count={postCount} />
                            <PostsTable
                                onDeletePost={(postId, bulkId) =>
                                    onDeleteClick(postId + '', bulkId)
                                }
                                onPostCountUpdate={(count: number) => setPostCount(count)}
                            />
                        </PostsListStyled>
                    </PostsQueryContext.Provider>
                </EditPostsContext.Provider>
            );
    }
}
