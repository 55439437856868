import styled from '@emotion/styled';

export const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 16px;
`;

export const SectionContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 40px; // To be aligned with title of IconTitle
`;
