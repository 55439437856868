import React from 'react';

import InfiniteScroll from 'react-infinite-scroller';

import { useGoogleVerificationContext } from 'app/settingsManagement/components/GoogleVerification/GoogleVerification.context';

import LocationsResults from '../LocationsResults';

const GoogleVerificationCardList = () => {
    const { locationQuery } = useGoogleVerificationContext();

    const loadMore = async () => {
        if (!locationQuery.isLoading && locationQuery.hasNextPage) {
            await locationQuery.fetchNextPage();
        }
    };

    return (
        <InfiniteScroll
            hasMore={locationQuery.hasNextPage}
            loadMore={loadMore}
            threshold={250}
            useWindow={false}
            getScrollParent={() => document.getElementById('react-content')}
        >
            <LocationsResults />
        </InfiniteScroll>
    );
};

export default GoogleVerificationCardList;
