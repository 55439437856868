import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useCountry from 'app/businessEditV2/hooks/business/useCountry';

export default function useBusinessCountry() {
    const { data: business } = useBusiness();
    const { data: countries } = useCountry();

    return (
        countries?.find(data => data?.value === business?.country)?.label ?? ''
    );
}
