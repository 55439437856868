import { indexOf, split } from 'lodash-es';

import { BusinessFieldsType } from 'app/api/types/business';
import {
    DAYS,
    DIGIT_SEPARATOR,
    HOUR_SEPARATOR,
} from 'app/businessEditV2/oldFromEditV1/data/constants';

const AFTER_MIDNIGHT_MAX_HOUR = 7;

const extractHours = (openHourWindow: string) => {
    const [startTime, endTime] = split(openHourWindow, HOUR_SEPARATOR);
    return {
        startHour: split(startTime, DIGIT_SEPARATOR)[0],
        endHour: split(endTime, DIGIT_SEPARATOR)[0],
    };
};

export const isMidnightOpenHourWindow = (openHourWindow: string) => {
    const { startHour, endHour } = extractHours(openHourWindow);
    return (
        parseInt(endHour, 10) <= AFTER_MIDNIGHT_MAX_HOUR &&
        parseInt(startHour, 10) > parseInt(endHour, 10)
    );
};

export const getNextDay = (day: string) => {
    const dayIndex = indexOf(DAYS, day);
    return DAYS.length > dayIndex + 1 ? DAYS[dayIndex + 1] : DAYS[0];
};

export const isBusinessFieldDisabled = (
    businessFields: BusinessFieldsType,
    sectionName: string,
    fieldName: string,
): boolean => {
    if (businessFields === undefined) {
        return true;
    }

    const sections = businessFields.filter(
        section => section.name === sectionName,
    );

    if (sections.length === 0) {
        return true;
    }

    const fields = sections[0].fields.filter(field => field.name === fieldName);

    if (fields.length === 0) {
        return true;
    }

    return !fields[0].enabled;
};
