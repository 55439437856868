import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { useMetricsSearchQueryParams } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/useMetricsSearchQueryParams';
import { MetricsAttributesResponse } from 'app/pages/analytics/VisibilityAnalyticsV2/types/metrics';
import { Period } from 'app/pages/analytics/VisibilityAnalyticsV2/types/period';
import { MetricsApiKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/types/query';
import {
    URL_MONTH_FORMAT,
    calculateRangeDate,
} from 'app/pages/analytics/VisibilityAnalyticsV2/utils/date';
import { AnalyticsQueryKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queryKeys';

const METRICS_ACTIONS = [
    MetricsAttributesResponse.WEBSITE_CLICKS,
    MetricsAttributesResponse.CALL_CLICKS,
    MetricsAttributesResponse.BUSINESS_DIRECTION_REQUESTS,
];

const METRICS_VIEWS = [
    MetricsAttributesResponse.BUSINESS_IMPRESSIONS_DESKTOP_MAPS,
    MetricsAttributesResponse.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH,
    MetricsAttributesResponse.BUSINESS_IMPRESSIONS_MOBILE_MAPS,
    MetricsAttributesResponse.BUSINESS_IMPRESSIONS_MOBILE_SEARCH,
];

export const useMetricsQueryCount = () => {
    const queryCurrent = {
        ...useMetricsSearchQueryParams(),
        [MetricsApiKeys.METRICS]: [...METRICS_ACTIONS, ...METRICS_VIEWS].join(
            ',',
        ),
        [MetricsApiKeys.DIMENSIONS]: 'month',
    };

    const [comparison] =
        useStateQueryParams(AnalyticsQueryKeys.COMPARISON) || Period.PERIOD;

    const dateRange = calculateRangeDate(
        comparison,
        queryCurrent.filter_date__gte,
        queryCurrent.filter_date__lte,
        URL_MONTH_FORMAT,
    );

    const queryPrevious = {
        ...queryCurrent,
        [MetricsApiKeys.FILTER_DATE_GTE]: dateRange?.dateFrom,
        [MetricsApiKeys.FILTER_DATE_LTE]: dateRange?.dateTo,
    };

    return {
        queryCurrent,
        queryPrevious,
    };
};
