import { DateCriteria, DatePickerType } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { LocaleDatePicker } from 'app/common/components/LocaleDatePicker/LocaleDatePicker';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { useDatesReviewAnalyticsFilters } from 'app/reviewManagement/common/hooks/useDatesReviewAnalyticsFilters';

interface Props {
    hasError?: string;
    disabled?: boolean;
}
export const DateFilterSelector = ({ hasError, disabled = false }: Props) => {
    const { t } = useTranslation();
    const now = new Date();
    const { datesFormatted, onChange, resetDate } = useDatesReviewAnalyticsFilters();

    return (
        <TooltipWrapper text={disabled ? t('field_read_only') : ''} position="bottom-start">
            <LocaleDatePicker
                dataTrackId=""
                type={DatePickerType.DateRangePicker}
                disabled={disabled}
                error={hasError}
                dates={datesFormatted}
                labels={{
                    startDate: t('date_begin'),
                    endDate: t('date_end'),
                }}
                placeholders={{
                    startDate: t('date_placeholder'),
                    endDate: t('date_placeholder'),
                }}
                onChange={onChange}
                reset={resetDate}
                maxDate={now}
                withShortcut
                shortcuts={[
                    {
                        name: DateCriteria.LAST_MONTH,
                        label: t('date_criteria_last_month'),
                    },
                    {
                        name: DateCriteria.CURRENT_MONTH,
                        label: t('date_criteria_current_month'),
                    },
                    {
                        name: DateCriteria.CURRENT_YEAR,
                        label: t('date_criteria_current_year'),
                    },
                    {
                        name: DateCriteria.LAST_YEAR,
                        label: t('date_criteria_last_year'),
                    },
                ]}
            />
        </TooltipWrapper>
    );
};
