import React from 'react';

import { useTranslation } from 'react-i18next';

import EditSection from 'app/businessEditV2/components/EditSection';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useAdvancedSettingsSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useAdvancedSettingsSectionColor';
import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import { SectionNames } from 'app/businessEditV2/utils/utils';

import AdvancedSettingsModal from './AdvancedSettingsModal';
import AdvancedSettingsSectionContent from './AdvancedSettingsSectionContent';

const AdvancedSettingsSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const {
        showModal,
        setShowModal,
        closeSafeModal,
        showUnsavedModal,
        setShowUnsavedModal,
        useDirtyUpdate,
    } = useUnsavedModal(SectionNames.ADVANCED_SETTINGS);

    const sectionColor = useAdvancedSettingsSectionColor();
    const { t } = useTranslation();

    const { isLoading } = useBusiness();

    if (isLoading) {
        return <EditSectionLoader />;
    }

    return (
        <div
            ref={ref}
            data-track="visibility_location__section_advanced_settings"
            data-intercom-target="visibility_location__section_advanced_settings"
        >
            <EditSection
                title={t('advanced_settings')}
                description={
                    <AdvancedSettingsSectionContent colorState={sectionColor} />
                }
                onClick={() => setShowModal(true)}
                colorState={sectionColor}
                sectionName={SectionNames.ADVANCED_SETTINGS}
            />
            <AdvancedSettingsModal
                showModal={showModal}
                setShowModal={setShowModal}
                closeSafeModal={closeSafeModal}
                showUnsavedModal={showUnsavedModal}
                setShowUnsavedModal={setShowUnsavedModal}
                useDirtyUpdate={useDirtyUpdate}
            />
        </div>
    );
});

export default AdvancedSettingsSection;
