import React, { useCallback, useContext } from 'react';

import { Icon, IconButton, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormattedReplyTemplate } from 'app/api/types/reviewReplyTemplates';
import { Choice } from 'app/api/types/user';
import FLAG from 'app/common/flags.json';
import { useReplyTemplates } from 'app/common/hooks/queries/ReviewReplyTemplates/useReplyTemplates';
import useDebounce from 'app/common/hooks/useDebounce';
import { LANGUAGES_MAP } from 'app/common/services/languages';
import dataLayer from 'app/common/utils/dataLayer';
import useBusiness from 'app/reviewManagement/reviewList/hooks/useBusiness';
import { SETTINGS_REVIEWS_REPLY_TEMPLATES_PATHNAME } from 'app/routing/routeIds';

import {
    useAvailableLanguages,
    useReplyTemplateButtonFilters,
    useShowSettingsButton,
} from './hooks';
import { StyledButtonWithMenu } from './ReplyTemplateButton.styled';
import { ReviewCardContext, ReviewCardContextType } from '../../ReviewCard';

type Props = {
    onHoverTemplate: (value: FormattedReplyTemplate | undefined) => void;
    onClickTemplate: (value: FormattedReplyTemplate) => void;
    onOpen: () => void;
    onClose: () => void;
};

export function mapTemplateToChoice({ title, id, language }: FormattedReplyTemplate): Choice {
    const country = LANGUAGES_MAP[language]?.country;

    return {
        label: title,
        value: id.toString(),
        icon: React.createElement('img', {
            src: FLAG[country],
            alt: country,
        }),
    };
}

export function ReplyTemplateButton({
    onHoverTemplate,
    onClickTemplate,
    onOpen = () => undefined,
    onClose = () => undefined,
}: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { review } = useContext<ReviewCardContextType>(ReviewCardContext);
    const business = useBusiness(review.businessId);

    const showSettingsButton = useShowSettingsButton();

    const {
        language: [currentLanguage, setCurrentLanguage],
        titleSearch: [titleSearch, setTitleSearch],
        literalRating,
    } = useReplyTemplateButtonFilters(review);
    const debouncedTitleSearch = useDebounce(titleSearch, 500);

    const { availableLanguagesAsOptions } = useAvailableLanguages(literalRating);

    const [templates, { fetchNextPage, hasNextPage }] = useReplyTemplates(
        {
            languages: currentLanguage ? [currentLanguage] : undefined,
            ratings: literalRating ? [literalRating] : undefined,
            titleSearch: debouncedTitleSearch,
        },
        { enabled: !!currentLanguage },
    );

    function loadMore(inputSearch: string) {
        if (inputSearch !== titleSearch) {
            setTitleSearch(inputSearch);
        } else if (hasNextPage) {
            fetchNextPage().then(() => undefined);
        }
    }

    /**
     * Callback when hovering a template
     * @param templateId
     */
    function onHover(templateId: string | null) {
        const template = templates.find(({ id }) => id.toString() === templateId);
        onHoverTemplate(template);
    }

    /**
     * Callback when clicking a template
     * @param value
     */
    function onTemplateClick(value: Choice[] | Choice | null) {
        // temporary ts-ignore to while having the previous version of reply template selection
        // @ts-expect-error
        const { value: templateId } = value instanceof Array ? value[0] : value;
        const template = templates.find(({ id }) => id.toString() === templateId);
        if (template) {
            onClickTemplate?.(template);
        }
    }

    const onRedirect = useCallback(() => {
        if (!showSettingsButton) return;
        // GTM
        dataLayer.pushDict('click_manage_template');
        navigate(`${SETTINGS_REVIEWS_REPLY_TEMPLATES_PATHNAME}?languages=${currentLanguage}`);
    }, [currentLanguage, showSettingsButton]);

    return (
        <div>
            <StyledButtonWithMenu
                scrollOnOpen={false}
                disabled={!business}
                onChangePreselectedValue={onHover}
                input={
                    <IconButton
                        data-intercom-target="reviews__reply_templates_button"
                        dataTrackId="reviews__reply_templates_button"
                        appearance="outlined"
                        icon={['fa-bolt-lightning', IconPrefix.REGULAR]}
                        onClick={() => undefined}
                        tooltip={t('reply_template_button_tooltip')}
                    />
                }
                options={templates.map(mapTemplateToChoice)}
                hasHeader
                menuTitle={t('rm_use_template')}
                menuIcon={<Icon icon={['fa-globe', IconPrefix.SOLID]} />}
                menuIconTooltip={t('rt_tooltip_globe_reply_language')}
                filtersOptions={availableLanguagesAsOptions}
                onFilterClick={(lang: string) => {
                    // When clicking on the language flag
                    setCurrentLanguage(lang);
                }}
                onChange={onTemplateClick}
                loadMore={loadMore}
                hasMore={hasNextPage}
                selectedOptions={[]}
                loadMoreOnOpenMenu
                placeholder={t('search')}
                position={'left'}
                redirectButton={
                    showSettingsButton ? (
                        <Icon icon={['fa-pen-clip', IconPrefix.SOLID]} />
                    ) : undefined
                }
                redirectButtonTooltip={t('rt_manage_reply_templates')}
                onRedirectButtonClick={showSettingsButton ? onRedirect : undefined}
                onOpen={onOpen}
                onClose={onClose}
            />
        </div>
    );
}
