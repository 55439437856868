import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';
import {
    PostCardFooter,
    PostContainer,
} from 'app/common/designSystem/components/molecules/GooglePostCard/PostCard.styled';
import { PostCardCoverContainer } from 'app/common/designSystem/components/molecules/GooglePostCard/PostCardCover.styled';
import { PostCardMainContainer } from 'app/common/designSystem/components/molecules/GooglePostCard/PostCardMain.styled';

export default function LoadingComponent() {
    return (
        <PostContainer>
            <PostCardCoverContainer onClick={() => undefined}>
                <LoadingRectangle flexFill recHeight="150px" />
            </PostCardCoverContainer>

            <PostCardMainContainer>
                <LoadingRectangle recHeight="8px" recWidth="50%" />
                <LoadingRectangle
                    recHeight="8px"
                    recWidth="100%"
                    recMargin="16px 0 0 0"
                />
                <LoadingRectangle
                    recHeight="8px"
                    recWidth="100%"
                    recMargin="4px 0 0 0"
                />
            </PostCardMainContainer>
            <PostCardFooter>
                <LoadingRectangle
                    recHeight="8px"
                    recWidth="50%"
                    recMargin="0 16px 0 0"
                />
                <LoadingRectangle recHeight="8px" recWidth="50%" />
            </PostCardFooter>
        </PostContainer>
    );
}
