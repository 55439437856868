import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import {
    StyledInternalRankingHeader,
    StyledInternalRankingSection,
    TagsRankingContainer,
} from './InternalRankingSection.styled';
import { EReputationRanking, ReplyMeansRanking, ReplyTimeRanking, TagsRanking } from './Rankings';
import { useGraphSelection } from '../../hooks/useGraphSelection';

export const InternalRankingSection = () => {
    const { t } = useTranslation();
    const [graph] = useGraphSelection();

    const RankingComponent = {
        average_rating: EReputationRanking,
        review_count: EReputationRanking,
        rating_distribution: EReputationRanking,
        reply_means: ReplyMeansRanking,
        reply_time: ReplyTimeRanking,
        tags: TagsRanking,
    }[graph];

    if (!RankingComponent) return undefined;

    if (graph === 'tags') {
        return (
            <TagsRankingContainer>
                <RankingComponent />
            </TagsRankingContainer>
        );
    }

    return (
        <StyledInternalRankingSection>
            <StyledInternalRankingHeader>
                <Text variant="heading6">{t('review_analytics_internal_ranking')}</Text>
            </StyledInternalRankingHeader>
            <RankingComponent />
        </StyledInternalRankingSection>
    );
};
