import { useMutation } from 'react-query';

import { GeocodingParams, GeocodingType } from 'app/api/types/geocoding';
import api from 'app/api/v2/api_calls';

export default function useBusinessGeocoding(
    onSuccess: (result: GeocodingType) => void = () => undefined,
    onError: () => void = () => undefined,
) {
    return useMutation((payload: GeocodingParams) => api.geocoding.getGeocoding(payload), {
        onSuccess,
        onError,
    });
}
