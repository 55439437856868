export type ReceivedOpenHours = {
    monday: Array<string>;
    tuesday: Array<string>;
    wednesday: Array<string>;
    thursday: Array<string>;
    friday: Array<string>;
    saturday: Array<string>;
    sunday: Array<string>;
};

export type ReceivedPartnerData = {
    name: string;
    formatted_name: string;
    pm_latency: string;
    status: string;
    order: number;
    pm_method: string;
    pm_gateway_partner: string;
    updated: 0 | 1;
    transmitted: 0 | 1;
    pending: 0 | 1;
    config_required: 0 | 1;
    fields_required: 0 | 1;
    error: 0 | 1;
    included_country_codes: Array<string>;
    pm_siret_required: boolean;
    pm_description_short_required: boolean;
    pm_phone_required: boolean;
    pm_fields: Array<string>;
};

export type PriceRange = {
    max: number;
    min: number;
};

export type News = {
    title: string;
    content: string;
    created_at: number;
};

export const USER_STATUS_ACTIVE = 'active';

export const REVIEW_MANAGEMENT_DAILY = 'review_management_daily';

export const REVIEW_MANAGEMENT_WEEKLY = 'review_management_weekly';

export const REVIEW_MANAGEMENT_MONTHLY = 'review_management_monthly';

export type ProductReport =
    | 'review_management_daily'
    | 'review_management_weekly'
    | 'review_management_monthly';

export type V2Subscriptions = {
    has_business: boolean;
    subscribed_to_pm: boolean;
    subscribed_to_rm: boolean;
    subscribed_to_rb: boolean;
    subscribed_to_bm: boolean;
};

export type InputEvent = {
    target: HTMLInputElement;
} & Event;

export type DayString =
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday';

export type ErrorObject = Record<string, any>;

export type StatusType = {
    status: string;
};

export type SuccessResponse = {
    status: 'success';
};
