import { css } from '@emotion/react';
import { Option } from '@partoohub/ui';
import { IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';
import useGetTemplatePlaceholders from 'app/pages/settings/AutoReply/hooks/queries/useGetTemplatePlaceholders';

interface Props {
    options?: Array<Option>;
    children?: JSX.Element;
    onMenuClick: (value: string) => void;
    onMenuEnter?: (value: string) => void;
    onMenuLeave?: (value: string) => void;
    onMenuToggle?: (show: boolean) => void;
    className?: string;
    menuClassName?: string;
}

function PlaceholderSelector(props: Props) {
    const placeholders = useGetTemplatePlaceholders() ?? [];
    const { t } = useTranslation();

    return (
        <ActionButtonWithMenu
            dataTrackId="review-reply-placeholder-selector"
            appearance="outlined"
            icon={['fa-at', IconPrefix.REGULAR]}
            options={placeholders.map(({ label, ...rest }) => ({
                ...rest,
                label: t(label),
                name: rest.value,
            }))}
            {...props}
            cssMenu={css`
                top: 50px;
                right: unset;
                left: 0px;
            `}
            cssContainer={css`
                align-items: start;
            `}
            tooltip={t('review_reply_shortcut')}
        />
    );
}

export default PlaceholderSelector;
