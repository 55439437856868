import { useTranslation } from 'react-i18next';

import PageHeader from 'app/common/designSystem/components/molecules/PageHeader';

export default function DesktopReviewHeader() {
    const { t } = useTranslation();

    return (
        <>
            <PageHeader title="reviews" sideTitle={t('title_analytics_only_google')} />
        </>
    );
}
