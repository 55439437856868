import { DatePickerType, DateRangeLabels, RangeDate } from '@partoohub/ui';
import { Control, useController } from 'react-hook-form';

import { LocaleDatePicker } from 'app/common/components/LocaleDatePicker/LocaleDatePicker';

import { DatePickerContainer } from '../PartialEventForm.styled';

interface Props {
    control: Control<any>;
    name: string;
    hasError: boolean;
    placeholders: DateRangeLabels;
    labels: DateRangeLabels;
    minDate?: Date;
    maxDate?: Date;
    error?: string;
}

export const ControlledDatepickerField = ({
    control,
    name,
    hasError,
    placeholders,
    labels,
    minDate,
    maxDate,
    error,
}: Props) => {
    const rules = { required: true };
    const {
        field: { value, onChange },
    } = useController({ control, name, rules });

    const handleChange = (dates: RangeDate) => {
        onChange(dates[0]);
    };

    return (
        <DatePickerContainer hasError={hasError}>
            <LocaleDatePicker
                dataTrackId={`${name}`}
                type={DatePickerType.DateSelector}
                dates={[value, null]}
                placeholders={placeholders}
                onChange={handleChange}
                labels={labels}
                minDate={minDate}
                maxDate={maxDate}
                required
                error={error}
                full
            />
        </DatePickerContainer>
    );
};
