import React from 'react';

import { useController } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import GoogleMaps from 'app/common/components/form/GoogleMaps';

type Props = {
    control: Control<any>;
};

const ControlledGeocodingInput = ({ control }: Props) => {
    const {
        field: { value: manualLat, onChange: onChangeManualLat },
    } = useController({ control, name: 'manualLat' });
    const {
        field: { value: manualLong, onChange: onChangeManualLong },
    } = useController({ control, name: 'manualLong' });

    const onDrag = (lat: number, long: number) => {
        onChangeManualLat(lat);
        onChangeManualLong(long);
    };

    if (manualLat == null && manualLong == null) {
        return null;
    }

    return (
        <div style={{ marginBottom: '36px' }}>
            <GoogleMaps lat={manualLat} long={manualLong} onDrag={onDrag} />
        </div>
    );
};

export default ControlledGeocodingInput;
