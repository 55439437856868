import { reduce, uniq } from 'lodash-es';

import { V2AccountData, V2FormattedAccountData } from 'app/api/types/account';

import formatAccount from './formatAccount';

type FormattedAccountsObj = {
    accountsById: Record<string, V2FormattedAccountData>;
    accountIdsByPartner: Record<string, Array<string>>;
};

const formatAccounts = (accounts: Array<V2AccountData>): FormattedAccountsObj =>
    reduce(
        accounts,
        (obj, account: V2AccountData) => ({
            accountsById: {
                ...obj.accountsById,
                [account.id]: formatAccount(account),
            },
            accountIdsByPartner: {
                ...obj.accountIdsByPartner,
                [account.partner]: uniq([
                    ...(obj.accountIdsByPartner[account.partner] || []),
                    account.id,
                ]),
            },
        }),
        {
            accountsById: {},
            accountIdsByPartner: {},
        },
    );

export default formatAccounts;
