import useMetricsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useMetricsAnalytics';
import { useMetricsQueryCount } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/metrics/count/useMetricsQueryCount';

export const useMetricsErrorCount = () => {
    const { queryCurrent, queryPrevious } = useMetricsQueryCount();

    const { error: errorCountMetricsCurrent, refetch: refetchCountMetricsCurrent } =
        useMetricsAnalytics(queryCurrent);
    const { error: errorCountMetricsPrevious, refetch: refetchCountMetricsPrevious } =
        useMetricsAnalytics(queryPrevious);

    return {
        hasError: errorCountMetricsCurrent || errorCountMetricsPrevious,
        refetchCountMetricsCurrent,
        refetchCountMetricsPrevious,
    };
};
