import styled from '@emotion/styled';
import { Checkbox } from '@partoohub/ui';

export const MultipleSelectCardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px;
`;

export const StyledCheckbox = styled(Checkbox)`
    margin: 0;
`;
