import { RoleValue } from 'app/api/types/user';
import dataLayer from 'app/common/utils/dataLayer';

/**
 * Diverse tracking functions about the group management
 */
const groupsTracking = {
    clickSectionCreation: (userRole: RoleValue | undefined) => {
        dataLayer.pushDict('click_section_creation', {
            user_role: userRole,
        });
    },

    clickGroupCreation: (userRole: RoleValue | undefined) => {
        dataLayer.pushDict('click_group_creation', {
            user_role: userRole,
        });
    },

    saveGroupModal: (userRole: RoleValue | undefined, businessNumber: number) => {
        dataLayer.pushDict('save_group_modal', {
            user_role: userRole,
            business_number: businessNumber,
        });
    },

    saveEditUserPage: (groupNumber: number, accessNumber: number) => {
        dataLayer.pushDict('save_edit_user_page', {
            group_number: groupNumber,
            access_number: accessNumber,
        });
    },

    saveCreateUserPage: (groupNumber: number, accessNumber: number) => {
        dataLayer.pushDict('save_create_user_page', {
            group_number: groupNumber,
            access_number: accessNumber,
        });
    },
};

export default groupsTracking;
