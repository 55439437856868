import { FC, useContext } from 'react';

import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';
import {
    Conversation,
    ConversationIssue,
    ConversationStatus,
    ISSUE_TRANSLATION_MAP,
    MessagingPartnerEnum,
} from 'app/states/messaging';

import {
    ActiveConversationContainer,
    ConversationWithIssuesPlaceholder,
    ExpiredConversationContainer,
} from './ActiveConversation.styled';
import ConversationHeader from './ConversationHeader';
import ConversationHeaderLoader from './ConversationHeaderLoader';

import ConversationLayout from './ConversationLayout';
import MessageForm from './messages/MessageForm';
import MessageLoader from './messages/MessageLoader';
import { MessagingContext } from '../MessagingContext';

export const ActiveConversation = () => {
    const { activeConversation } = useContext(MessagingContext);

    if (!activeConversation) {
        return (
            <ActiveConversationContainer>
                <ConversationHeaderLoader />
                <MessageLoader />
            </ActiveConversationContainer>
        );
    }

    return (
        <ActiveConversationContainer>
            <ConversationHeader activeConversation={activeConversation} />
            <ConversationLayout
                activeConversation={activeConversation}
                notice={<ConversationNotice conversation={activeConversation} />}
            />
            {canShowMessageForm(activeConversation) && (
                <MessageForm
                    conversation={activeConversation}
                    disabled={activeConversation.messaging_partner === MessagingPartnerEnum.DELIGHT}
                />
            )}
        </ActiveConversationContainer>
    );
};

interface ConversationNoticeProps {
    conversation: Conversation;
}

const ConversationNotice: FC<ConversationNoticeProps> = ({ conversation }) => {
    const { t } = useTranslation();
    const issueName = getConversationIssue(conversation);

    if (issueName) {
        const issueTranslationKey = ISSUE_TRANSLATION_MAP.get(issueName);

        return (
            <ConversationWithIssuesPlaceholder>
                <i className="fa-solid fa-exclamation-circle" />
                <p>{t(issueTranslationKey!)}</p>
            </ConversationWithIssuesPlaceholder>
        );
    }

    if (conversationIsOpen(conversation)) return null;

    return <ExpiredConversation conversation={conversation} />;
};

const getConversationIssue = (conversation: Conversation): ConversationIssue | null =>
    conversation.issues.length === 0 ? null : conversation.issues[0];

const canShowMessageForm = (conversation: Conversation): boolean =>
    conversationIsOpen(conversation) && !getConversationIssue(conversation);

const conversationIsOpen = (conversation: Conversation): boolean =>
    conversation.status === ConversationStatus.OPEN;

interface ExpiredConversationProps {
    conversation: Conversation;
}

const ExpiredConversation = ({ conversation }: ExpiredConversationProps) => {
    const { data: me } = useMe();
    const { data: org } = useOrganization(me?.org_id ?? 0, !!me?.org_id);
    const { t } = useTranslation();

    const provider = org !== undefined ? org.provider : '';

    const learnMoreURL =
        'https://help.partoo.co/fr/articles/5745352-quelles-sont-les-bonnes-pratiques-pour-repondre-a-ses-messages';
    const learnMoreComponent =
        provider === 'partoo' ? (
            <a href={learnMoreURL}>({t('cta_learn_more').toLowerCase()})</a>
        ) : null;

    const expirationMessageTrad =
        conversation.messaging_partner === MessagingPartnerEnum.WHATSAPP
            ? t('messaging_whatsapp_conversation_closed_explanation_text')
            : t('messaging_conversation_closed_explanation_text').trim();
    const expirationMessage = learnMoreComponent
        ? expirationMessageTrad
        : expirationMessageTrad.concat(' ');

    return (
        <ExpiredConversationContainer>
            <Text variant="bodyMBold" as="span" color={'secondary'} oneLine={true}>
                <i className="far fa-circle-exclamation-check"></i>
                {expirationMessage}
                {learnMoreComponent}.
            </Text>
            <Text variant="bodyMRegular" as="span" color={'secondary'} oneLine={true}>
                {t('messaging_conversation_closed_explanation_subtext')}
            </Text>
        </ExpiredConversationContainer>
    );
};
