import React, { useMemo } from 'react';

import InfiniteScroll from 'react-infinite-scroller';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import useSearchUsers from 'app/common/hooks/queries/useSearchUsers';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { UserListContainer } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserList/UserList.styled';
import AuthorizationService from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/authorization';

import {
    UserListQueryKeys,
    UserStatus,
} from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

import LoadingState from './LoadingState';

import UserRow from './UserRow';

const UserList = () => {
    const { data: me } = useMe();
    const [query] = useStateQueryParams(UserListQueryKeys.QUERY);
    const [status] = useStateQueryParams(UserListQueryKeys.STATUS);

    // TODO MORTY: remove the useMemo and handle everything in the custom hook
    const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
        // TODO MORTY USERS: Handle query and status directly in the hook
        useSearchUsers(
            query || '',
            [UserStatus.ACTIVE, UserStatus.INVITED].includes(status) ? status : null,
        );

    const users = useMemo(() => {
        return (
            data?.pages
                ?.map(page => page.users)
                ?.flat()
                .filter(user => user.id !== me?.id) ?? []
        );
    }, [data, me]);

    const authorizationService = new AuthorizationService(me);
    const displayActions =
        authorizationService.isAdmin() ||
        authorizationService.isOrgAdmin() ||
        authorizationService.isProvider() ||
        authorizationService.isGroupManager();

    return (
        <UserListContainer>
            <InfiniteScroll
                hasMore={hasNextPage && !isFetchingNextPage}
                loadMore={() => fetchNextPage()}
                useWindow={false}
                getScrollParent={() => document.getElementById('react-content')}
            >
                {!!me && <UserRow user={me} displayActions={displayActions} isUser />}
                {users.map(user => (
                    <UserRow key={user.id} user={user} displayActions={displayActions} />
                ))}
            </InfiniteScroll>

            {(isLoading || isFetchingNextPage) && <LoadingState />}
        </UserListContainer>
    );
};

export default UserList;
