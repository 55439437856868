import { startOfDay } from 'date-fns';

import { useDateFilter } from 'app/presence/googlePosts/hooks/filters/useDateFilter';

const DATE_FROM_FILTER_PARAM = 'date-from';

export function useDateFromFilter() {
    return useDateFilter(DATE_FROM_FILTER_PARAM, 'start_datetime', (date: Date) => {
        return startOfDay(date);
    });
}
