import { useSelector } from 'react-redux';

import { meSelector, orgsSelector } from 'app/states/reducers';

export default function useHasReviewTagFeature(): boolean {
    const me = useSelector(meSelector);
    const orgs = useSelector(orgsSelector);
    const orgId = me.data?.orgId;

    return Boolean(orgId && orgs.byId[orgId] && orgs.byId[orgId].hasReviewTag);
}
