import { useContext, useEffect } from 'react';

import { SortableContext } from '@dnd-kit/sortable';
import { ImageFile } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import AddImageIcon from 'app/common/components/icons/AddImageIcon';
import DropZone from 'app/common/designSystem/components/molecules/DropZone';

import {
    FileError,
    ImageUploadErrorDetails,
    getErrorsFromFiles,
} from 'app/common/designSystem/components/molecules/DropZone/helpers';
import usePrevious from 'app/common/hooks/usePrevious';

import { loadNewImages, recropImages } from './ImageCropper';
import { getClampedRatio } from './ImageCropper/helpers/getClampedRatio';
import {
    DeleteButton,
    MultiImageDropzoneContainer,
    Overlay,
    OverlayCssSelector,
    SingleImageDisplayContainer,
    SingleImageDropzoneContainer,
    StyledLoader,
} from './ImageDropzone.styled';
import { MultiImagePhoto } from './MultiImagePhoto';
import { mergeAcceptedFiles } from '../../components/PostImageInfo/PostImageInfo';
import { ImageListType, NewPostContext } from '../../context/NewPost';
import useImageUploadErrors, {
    MAX_FILE_SIZE,
    MAX_FILE_SIZE_FB,
    MIN_FILE_SIZE,
} from '../../hooks/useImageUploadErrors';
import usePlatformSelection from '../../hooks/usePlatformSelection';

type Props = {
    maxPhotoCount: number;
    updateFormFiles: (files: ImageListType) => void;
    isRecropping: boolean;
    setIsRecropping: (value: boolean) => void;
    loadingImages: number;
    setLoadingImages: (value: number) => void;
    onUploadImageErrorsUpdate: (errors: ImageUploadErrorDetails[]) => void;
};

export const ImageDropzone = ({
    maxPhotoCount,
    updateFormFiles,
    isRecropping,
    setIsRecropping,
    loadingImages,
    setLoadingImages,
    onUploadImageErrorsUpdate,
}: Props) => {
    const { t } = useTranslation();
    const { formFields } = useContext(NewPostContext);

    const { platforms, containsInstagram, hasOnlyFacebook } = usePlatformSelection();

    const maxSize = hasOnlyFacebook ? MAX_FILE_SIZE_FB : MAX_FILE_SIZE;
    const minSize = MIN_FILE_SIZE;

    const acceptedFileExtensions = mergeAcceptedFiles(platforms);
    const acceptedFiles = acceptedFileExtensions.map(ext => ({
        extension: ext,
        memeType: `image/${ext}`,
    }));

    const postImages = formFields.postImage;
    const prevMainPhoto = usePrevious(postImages[0]);
    const mainAspectRatio =
        postImages.length > 0 ? postImages[0]?.width / postImages[0]?.height : undefined;

    const { getErrors } = useImageUploadErrors();
    const acceptMultiImages = maxPhotoCount > 1;

    useEffect(() => {
        // On instagram, if the main photo has changed, recrop all images to the main original photo
        const newMainPhoto = postImages[0];
        if (!containsInstagram || prevMainPhoto === undefined || newMainPhoto === undefined) return;

        const hasMainPhotoChanged =
            prevMainPhoto?.original.data_url !== newMainPhoto.original.data_url;

        if (hasMainPhotoChanged) {
            const newRatio = getClampedRatio(
                newMainPhoto.original.width,
                newMainPhoto.original.height,
            );
            setIsRecropping(true);
            recropImages(postImages, newRatio)
                .then(updateFormFiles)
                .finally(() => setIsRecropping(false));
        }
    }, [postImages[0]]);

    if (!acceptMultiImages && postImages.length > 0) {
        return (
            <SingleImageDisplayContainer>
                <img src={postImages[0].data_url} alt="" />
                <Overlay data-css-selector={OverlayCssSelector}>
                    <DeleteButton
                        onClick={() => {
                            onUploadImageErrorsUpdate([]);
                            updateFormFiles([]);
                        }}
                    >
                        <i className={'fas fa-trash fa-lg'} />
                    </DeleteButton>
                </Overlay>
            </SingleImageDisplayContainer>
        );
    }

    const DropzoneContainer =
        (postImages.length > 0 || loadingImages > 0) && acceptMultiImages
            ? MultiImageDropzoneContainer
            : SingleImageDropzoneContainer;

    const onCrop =
        (index: number) => (croppedFile: Blob, cropperData: Cropper.Data, isMainPhoto: boolean) => {
            const newImages = [...postImages];
            const imageToCrop = newImages[index];

            const imgURL = URL.createObjectURL(croppedFile);
            const recroppedImage = {
                ...imageToCrop,
                data_url: imgURL,
                file: new File(
                    [croppedFile],
                    imageToCrop.original.file instanceof File ? imageToCrop.original.file.name : '',
                    { type: 'image/jpeg' },
                ),
                cropperData,
                width: cropperData.width,
                height: cropperData.height,
                autoCropped: false,
            };
            const isRecropped =
                cropperData.width / cropperData.height !== imageToCrop.width / imageToCrop.height;

            if (isMainPhoto && isRecropped) {
                // If the main photo was recropped, recrop all the images so the ratio remain identical
                const otherImages = newImages.slice(1);
                setIsRecropping(true);
                recropImages(otherImages, cropperData.width / cropperData.height)
                    .then(recropped => {
                        updateFormFiles([recroppedImage, ...recropped]);
                    })
                    .finally(() => setIsRecropping(false));
            } else {
                newImages.splice(index, 1, recroppedImage);
                updateFormFiles(newImages);
            }
        };

    const handleOnDropSuccess = async (files: Array<ImageFile>) => {
        const filesRemaining = maxPhotoCount - postImages.length;
        if (filesRemaining < 1) return;
        const filesToUpload = files.slice(0, filesRemaining);
        setLoadingImages(filesToUpload.length);

        try {
            const { images: newImages, errors } = await loadNewImages(
                filesToUpload,
                getErrors,
                containsInstagram,
                mainAspectRatio,
            );
            updateFormFiles([...postImages, ...newImages]);
            errors.length > 0 && onUploadImageErrorsUpdate(errors);
        } catch {
            onUploadImageErrorsUpdate([{ errorTypes: new Set(['unexpected']) }]);
        } finally {
            setLoadingImages(0);
        }
    };

    const handleOnDropError = (files: File[]) => {
        const errors: FileError[] = getErrorsFromFiles(files, { maxSize, minSize });
        onUploadImageErrorsUpdate(
            errors.map(({ filename, errorType }) => ({
                imageName: filename,
                errorTypes: new Set([errorType]),
            })),
        );
    };

    const handleOnDropOver = () => onUploadImageErrorsUpdate([]);

    return (
        <DropzoneContainer>
            <DropZone
                files={[]}
                maxFiles={maxPhotoCount}
                disabled={postImages.length >= maxPhotoCount}
                handleDropSuccess={handleOnDropSuccess}
                handleDropError={handleOnDropError}
                label={
                    postImages.length > 0 || loadingImages > 0
                        ? t('post_photo_add')
                        : t('post_drag_and_drop_here', {
                              count: maxPhotoCount,
                          })
                }
                uploadIcon={<AddImageIcon />}
                acceptedFiles={acceptedFiles}
                isCompact
                useCompactError
                childrenPosition="bottom"
                minSize={minSize}
                maxSize={maxSize}
                onDrop={handleOnDropOver}
            />
            <SortableContext items={postImages.map(postImage => postImage.data_url)}>
                {postImages.map((postImage, idx) => (
                    <MultiImagePhoto
                        key={postImage.original.data_url}
                        isMainPhoto={idx === 0}
                        mainPhotoAspectRatio={mainAspectRatio}
                        postImage={postImage}
                        onDelete={() => {
                            const newImages = [...postImages];
                            newImages.splice(idx, 1);
                            updateFormFiles(newImages);
                            onUploadImageErrorsUpdate([]);
                        }}
                        onCrop={onCrop(idx)}
                        disabled={isRecropping}
                    />
                ))}
            </SortableContext>
            {Array(loadingImages)
                .fill(null)
                .map((_, idx) => (
                    <StyledLoader key={idx} recRadius="4px" />
                ))}
        </DropzoneContainer>
    );
};
