import { FilterMap, FilterName, PartialFilters } from '@partoohub/modular-components';

import { FontAwesomeIconsPartooUsed } from '@partoohub/ui';

import { CLOSED, CLOSED_TEMPORARILY, OPEN, OPENING_SOON } from 'app/common/data/businessStatuses';
import { PM, RB, RM } from 'app/common/data/products';

import { CompletionRateValues, SelectableContentOption } from './enums';

export const concatTrueValues = (obj: PartialFilters, key: FilterName): string => {
    if (!(key in obj && obj[key] instanceof Map)) {
        return '';
    }
    const trueEntries = [...obj[key].entries()].filter(([, v]) => v === true);
    const trueKeys = trueEntries.map(([k]) => k);
    return trueKeys.join(',');
};

export const createFilterMap = (values: string): FilterMap => {
    if (!values) {
        return new Map();
    }
    const keys = values.split(',');
    const map = new Map(keys.map(key => [key, true]));
    return map;
};

export const textNamesDico = {
    [FilterName.STATUS]: 'filter_by_status',
    [FilterName.SUBSCRIPTIONS]: 'business_list_subscriptions',
    [FilterName.COMPLETION]: 'business_list_completion',
};

export const statusOptionValues: Array<SelectableContentOption> = [
    { name: OPEN, icon: [FontAwesomeIconsPartooUsed.faDoorOpen], text: OPEN, value: OPEN },
    {
        name: CLOSED,
        icon: [FontAwesomeIconsPartooUsed.faDoorClosed],
        text: 'business_closed',
        value: CLOSED,
    },
    {
        name: OPENING_SOON,
        icon: [FontAwesomeIconsPartooUsed.faCalendar],
        text: 'business_opening_soon',
        value: OPENING_SOON,
    },
    {
        name: CLOSED_TEMPORARILY,
        icon: [FontAwesomeIconsPartooUsed.faClock],
        text: CLOSED_TEMPORARILY,
        value: CLOSED_TEMPORARILY,
    },
];

export const completionOptionValues: Array<SelectableContentOption> = [
    {
        name: CompletionRateValues.LOW,
        icon: ['fa-gauge-min'],
        text: 'business_edit_status_popup_low',
        value: CompletionRateValues.LOW,
    },
    {
        name: CompletionRateValues.MID,
        icon: ['fa-gauge'],
        text: 'business_edit_status_popup_good',
        value: CompletionRateValues.MID,
    },
    {
        name: CompletionRateValues.HIGH,
        icon: ['fa-gauge-max'],
        text: 'business_edit_status_popup_very_good',
        value: CompletionRateValues.HIGH,
    },
];
export const productsOptionValues: Array<SelectableContentOption> = [
    {
        name: 'presence',
        icon: ['fa-shield-check'],
        text: PM,
        value: 'presence',
    },
    { name: 'review', icon: ['fa-star'], text: RM, value: 'review' },
    {
        name: 'review-booster',
        icon: ['fa-rocket'],
        text: RB,
        value: 'review-booster',
    },
];
