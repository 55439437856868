import styled from '@emotion/styled';

export const Card = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    box-shadow: ${({ theme }) => theme.shadows.light};
    border-radius: ${({ theme }) => theme.radius.large};
    padding: 16px;
    height: 80px;
    width: 100%;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    gap: 16px;
`;

export const PartnerIconContainer = styled.div`
    border-radius: 2px;
    height: 48px;
    width: 48px;
    margin-right: 10px;
    min-width: 64px;

    > img {
        width: 100%;
        height: 100%;
    }
`;

export const LocationDetailsContainer = styled.div`
    height: 52px;
    width: 228px;
    padding-right: 32px;
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    flex-basis: 252px;
`;

export const LocationName = styled.div`
    width 100%;
    height:18px;
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
`;

export const LocationAddress = styled.div`
    width 100%;
    height: 18px;
    font-style: normal;
    font-weight:400;
    font-size: 14px;
    white-space: nowrap;
    overflow:hidden;
    color: ${({ theme }) => theme.colors.secondary.initial};
    text-overflow: ellipsis;
`;

export const VerificationStatusContainer = styled.div`
    height: 24px;
    width: 200px;
    display: flex;
    flex-direction: row;
    gap: 9px;
    flex-grow: 2;
`;

export const VerificationStatusIcon = styled.i`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.danger.initial};
`;

export const VerificationStatusText = styled.div`
    width 100%;
    height:18px;
    font-weight: 700;
    font-size: 14px;
`;

export const VerificationButtonContainer = styled.div`
    position: relative;

    margin-left: auto;
    margin-right: 4px;

    min-width: 140px;
`;
