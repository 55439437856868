import styled from '@emotion/styled';

export const AutomatedIconWrapper = styled.div<{ size: number }>`
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.slate.alpha};
    color: ${({ theme }) => theme.colors.slate.initial};
    box-sizing: border-box;
    ${({ size }) => `width: ${size}px; height: ${size}px;`}
    display: flex;
    justify-content: center;
    align-items: center;
`;
