import { V2FormattedBusinessData } from 'app/api/types/business';
import {
    PARTOO_BUSINESS_TERMS_TO_SEARCH_IN as FUZZY_TERMS,
    businessIsSearched,
} from 'app/common/services/fuzzySearch';

const businessPassesFilter = (
    business: V2FormattedBusinessData,
    queries: Array<string>,
    cities: Array<string>,
    orgs: Array<string>,
    groupIdIn: Array<number>,
): boolean =>
    (!!queries.length ||
        !!cities.length ||
        !!orgs.length ||
        !!groupIdIn.length) &&
    (!cities.length || cities.includes(business.city)) &&
    (!orgs.length || orgs.includes(business.orgId.toString())) &&
    // @ts-ignore
    (!groupIdIn.length || groupIdIn.includes(business.groupId)) &&
    (!queries.length ||
        businessIsSearched(business, FUZZY_TERMS, queries.join(',')));

export default businessPassesFilter;
