import React from 'react';

import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import EditSection from 'app/businessEditV2/components/EditSection';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import UnsavedModal from 'app/businessEditV2/components/UnsavedModal';
import { useIsLoadingLinks } from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingLinks';
import useLinksSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useLinksSectionColor';
import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import LinksSectionContent from 'app/businessEditV2/sections/LinksSection/LinksSectionContent';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import LinksForm from './LinksForm';

const LinksSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const {
        showModal,
        setShowModal,
        closeSafeModal,
        showUnsavedModal,
        setShowUnsavedModal,
        useDirtyUpdate,
    } = useUnsavedModal(SectionNames.LINKS);

    const sectionColor = useLinksSectionColor();
    const { t } = useTranslation();

    const isLoading = useIsLoadingLinks();

    if (isLoading) {
        return <EditSectionLoader />;
    }

    return (
        <div
            ref={ref}
            data-track="visibility_location__section_links"
            data-intercom-target="visibility_location__section_links"
        >
            <EditSection
                title={t('links')}
                description={<LinksSectionContent colorState={sectionColor} />}
                onClick={() => setShowModal(true)}
                colorState={sectionColor}
                sectionName={SectionNames.LINKS}
            />
            <FullScreenModal
                show={showModal}
                childrenRight={
                    <EditFormDescription
                        title={t('business_edit_title_links')}
                        description={t('business_edit_description_links')}
                        advice={t('business_edit_advice_links')}
                    />
                }
                childrenLeft={
                    <>
                        <LinksForm
                            closeModal={() => setShowModal(false)}
                            useDirtyUpdate={useDirtyUpdate}
                        />
                        <UnsavedModal
                            show={showUnsavedModal}
                            cancel={() => setShowUnsavedModal(false)}
                            validate={() => setShowModal(false)}
                        />
                    </>
                }
                onHideClick={closeSafeModal}
            />
        </div>
    );
});

export default LinksSection;
