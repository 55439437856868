import { useEffect, useState } from 'react';

import { Button, Text, toast } from '@partoohub/ui';
import { UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import GoogleMaps from 'app/common/components/form/GoogleMaps';
import LoadingView from 'app/common/components/LoadingView';
import useBusinessGeocoding from 'app/pages/visibility/BusinessCreate/hooks/useBusinessGeocoding';

import { ConfirmAddressModalContainer } from './ConfirmAddressModal.styled';

type Props = {
    watch: UseFormWatch<any>;
    confirmAddress: (lat: number, long: number) => void;
};

const ConfirmAddressModal = ({ watch, confirmAddress }: Props) => {
    const { t } = useTranslation();

    const [lat, setLat] = useState<number | null>(null);
    const [long, setLong] = useState<number | null>(null);

    const geocode = useBusinessGeocoding(
        result => {
            setLat(result.lat);
            setLong(result.long);
        },
        () => {
            toast.error(t('geocoding_google_error_message'), t('geocoding_google_error_title'));
            setLat(0);
            setLong(0);
        },
    );

    useEffect(() => {
        geocode.mutate({
            zipcode: watch('zipCode'),
            city: watch('city')?.value,
            country: watch('country')?.value,
            address: watch('addressFull'),
        });
    }, []);

    if (!lat || !long) {
        return (
            <ConfirmAddressModalContainer>
                <LoadingView />
            </ConfirmAddressModalContainer>
        );
    }

    const onDrag = (lat: number, long: number) => {
        setLat(lat);
        setLong(long);
    };

    return (
        <ConfirmAddressModalContainer>
            <Text variant="heading4">{t('location')}</Text>
            <Text variant="bodyMRegular" color="secondary">
                {t('location_description_modal')}
            </Text>
            <GoogleMaps lat={lat} long={long} onDrag={onDrag} />
            <Button
                dataTrackId="confirm_address"
                variant="primary"
                size="large"
                full
                onClick={() => confirmAddress(lat, long)}
            >
                {t('confirm_my_address')}
            </Button>
        </ConfirmAddressModalContainer>
    );
};

export default ConfirmAddressModal;
