import React from 'react';

export const GoogleMessagingIcon = () => (
    <svg
        width="14"
        height="14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 14"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14Z"
            fill="#F5F7FB"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.1 6.19v1.935h2.691a2.3 2.3 0 0 1-.998 1.51l1.616 1.254c.946-.87 1.491-2.153 1.491-3.675 0-.355-.032-.696-.09-1.023H7.1Z"
            fill="#3E82F1"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.1 10.111c-1.302 0-2.405-.88-2.798-2.061l-1.67 1.295A4.998 4.998 0 0 0 7.1 12.1c1.35 0 2.482-.448 3.31-1.211L8.792 9.634c-.448.3-1.02.477-1.693.477"
            fill="#32A753"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.145 7.1c0-.33.057-.65.157-.95l-1.67-1.295a4.998 4.998 0 0 0 0 4.49l1.67-1.295c-.1-.3-.157-.62-.157-.95"
            fill="#F9BB00"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.1 4.089c.734 0 1.393.252 1.911.747l1.434-1.434C9.58 2.596 8.448 2.1 7.1 2.1a4.998 4.998 0 0 0-4.468 2.755l1.67 1.295C4.695 4.968 5.798 4.09 7.1 4.09"
            fill="#E74133"
        />
    </svg>
);
