import React from 'react';

import {
    PostCardMainBusinessIcon,
    PostCardMainBusinessName,
    PostCardMainContainer,
    PostCardMainDescription,
} from 'app/common/designSystem/components/molecules/GooglePostCard/PostCardMain.styled';

type Props = {
    businessInfo: string;
    isExpired: boolean;
    // Optional props
    summary?: string;
};

const PostCardMain = ({ businessInfo, summary, isExpired }: Props) => (
    <PostCardMainContainer isExpired={isExpired}>
        <PostCardMainBusinessName>
            <PostCardMainBusinessIcon className="fa-solid fa-map-marker-alt" />
            {businessInfo}
        </PostCardMainBusinessName>
        <PostCardMainDescription title={summary}>
            {summary}
        </PostCardMainDescription>
    </PostCardMainContainer>
);

export default PostCardMain;
