import { FC, useState } from 'react';

import { MessageBubbleImageStyled } from 'app/reviewManagement/messaging/activeConversation/messages/MessageBubble.styled';
import IMAGES from 'app/styles/constants/images';

interface MediaBubbleProps {
    url: string;
    isConsumerMessage: boolean;
    onClick: () => void;
}

export const ImageBubble: FC<MediaBubbleProps> = ({ url, isConsumerMessage, onClick }) => {
    const [brokenLink, setBrokenLink] = useState<boolean>(false);

    return (
        <MessageBubbleImageStyled
            src={brokenLink ? IMAGES.brokenLink : url}
            onError={e => {
                // @ts-ignore
                e.target.onerror = null;
                setBrokenLink(true);
            }}
            loading="lazy"
            isConsumer={isConsumerMessage}
            onClick={onClick}
        />
    );
};
