import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { CommonButton } from 'app/common/components/funnel/Buttons.styled';
import FunnelContext from 'app/common/components/funnel/FunnelContext';
import {
    FullPageContainer,
    SubTitle,
    Title,
} from 'app/common/components/funnel/FunnelModal.styled';
import { STATIC_BASE_URL } from 'app/config';

const ErrorPage = () => {
    const { t } = useTranslation();
    const funnel = useContext(FunnelContext);

    return (
        <FullPageContainer>
            <img
                src={`${STATIC_BASE_URL}/images/app/confirm_category_remap.svg`}
                alt="confirm_remap"
            />
            <Title>{t('onboarding_error_page_title')}</Title>
            <SubTitle>{t('onboarding_error_page_sub_title')}</SubTitle>
            <CommonButton
                dataTrackId="contact_support_onboarding"
                shape="cube"
                size="large"
                appearance="contained"
                variant="primary"
                onClick={() => {
                    funnel.navigate('selection_page');
                }}
            >
                {t('onboarding_error_page_contact_button')}
            </CommonButton>
        </FullPageContainer>
    );
};

export default ErrorPage;
