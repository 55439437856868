import { useEffect, useState } from 'react';

import { Checkbox, Text, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { BusinessFieldType } from 'app/api/types/business';
import ModalValidationRibbon from 'app/common/components/ModalValidationRibbon';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import DeleteImage from 'app/common/designSystem/components/atoms/svg/modalImages/delete';
import WarningImage from 'app/common/designSystem/components/atoms/svg/modalImages/warning';
import { STATIC_BASE_URL } from 'app/config';
import useDeleteCustomField from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useDeleteCustomField';
import {
    CustomFieldsWarningModalCheckbox,
    CustomFieldsWarningModalContent,
    CustomFieldsWarningModalContentTop,
    CustomFieldsWarningModalTitle,
    CustomFieldsWarningModalTopDescription,
    CustomFieldsWarningModalWarningDescription,
    CustomFieldsWarningModalWarningDescriptionIcon,
    CustomFieldsWarningModalWarningDescriptionText,
    ModalUndo,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/sections/CustomFieldsModals/CustomFieldsModals.styled';

type Props = {
    customField: BusinessFieldType | undefined;
    closeModal: () => void;
};

const CustomFieldsDeleteContent = ({ customField, closeModal }: Props) => {
    const { t } = useTranslation();
    const [warningMode, setWarningMode] = useState<boolean>(false);
    const [confirmCheckbox, setConfirmCheckbox] = useState<boolean>(false);

    const deleteCustomField = useDeleteCustomField(() => {
        toast.success(
            t('custom_fields_toast_delete_successful_description'),
            t('custom_fields_toast_delete_successful', { name: customField?.name ?? '' }),
        );
        closeModal();
    });

    // @ts-ignore
    const errors = deleteCustomField.error?.response?.data?.errors?.json;

    // Needs to be in a useState because we want to keep the warning mode even after another api call
    useEffect(() => {
        if (errors?.businesses_count) {
            setWarningMode(true);
        }
    }, [errors]);

    const loading = deleteCustomField.isLoading;

    const onDelete = () => {
        deleteCustomField.mutate({
            customFieldId: customField?.id ?? 0,
            forcedUpdate: !!(errors && confirmCheckbox),
        });
    };

    return (
        <CustomFieldsWarningModalContent>
            <CustomFieldsWarningModalContentTop>
                {warningMode ? <WarningImage /> : <DeleteImage />}
                <CustomFieldsWarningModalTitle variant="heading4">
                    {warningMode
                        ? t('custom_fields_deletion_warning_title', {
                              name: customField?.name ?? '',
                          })
                        : t('custom_fields_deletion_title', {
                              name: customField?.name ?? '',
                          })}
                </CustomFieldsWarningModalTitle>
                {warningMode ? (
                    <CustomFieldsWarningModalWarningDescription>
                        <CustomFieldsWarningModalWarningDescriptionIcon
                            src={`${STATIC_BASE_URL}/images/app/custom_fields/icon_guillaume.png`}
                            alt=""
                        />
                        <CustomFieldsWarningModalWarningDescriptionText color="secondary">
                            {t('custom_fields_deletion_warning_description', {
                                count: errors?.businesses_count ?? 0,
                                number: errors?.businesses_count ?? 0,
                            })}
                        </CustomFieldsWarningModalWarningDescriptionText>
                    </CustomFieldsWarningModalWarningDescription>
                ) : (
                    <CustomFieldsWarningModalTopDescription color="secondary">
                        {t('custom_fields_deletion_description')}
                    </CustomFieldsWarningModalTopDescription>
                )}
                {warningMode && (
                    <CustomFieldsWarningModalCheckbox
                        onClick={() => setConfirmCheckbox(!confirmCheckbox)}
                    >
                        <Checkbox
                            dataTrackId="deletion_modal__checkbox"
                            onChange={() => undefined}
                            checked={confirmCheckbox}
                        >
                            <Text variant="bodyMBold" color="secondary">
                                {t('custom_fields_deletion_confirmation_checkbox')}
                            </Text>
                        </Checkbox>
                    </CustomFieldsWarningModalCheckbox>
                )}
            </CustomFieldsWarningModalContentTop>

            <ModalUndo>
                <StrikeThroughButton color="secondary" text={t('undo')} onClick={closeModal} />
            </ModalUndo>

            <ModalValidationRibbon
                state={loading || (warningMode && !confirmCheckbox) ? 'loading' : 'danger'}
                text={warningMode ? 'confirm_deletion' : 'delete'}
                noIcon
                handleClick={onDelete}
            />
        </CustomFieldsWarningModalContent>
    );
};

export default CustomFieldsDeleteContent;
