import styled from '@emotion/styled';

import { GetMaxWidthContentPage } from 'app/global.styled';

export const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 24px;
    ${GetMaxWidthContentPage};
`;
export const BodyContent = styled.div``;
export const BodyFooter = styled.div``;
