import { useState } from 'react';

import { toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { V2UserData } from 'app/api/types/user';
import useReinviteUser from 'app/pages/settingsV2/subPages/Team/components/UserManagement/hooks/useReinviteUser';

import BaseReinviteModalContent from '../BaseReinviteModalContent';

type Props = {
    closeModal: () => void;
    user: V2UserData | undefined;
};

const UserReinviteContent = ({ user, closeModal }: Props) => {
    const { t } = useTranslation();
    const [success, setSuccess] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const userReinvite = useReinviteUser(
        user?.id ?? '',
        () => {
            closeModal();
            setSuccess(true);
            toast.success(null, t('toast__user_invited__success'));
        },
        () => {
            setHasError(true);
        },
    );

    return (
        <BaseReinviteModalContent
            mutate={userReinvite.mutate}
            isLoading={userReinvite.isLoading}
            // TODO MORTY USERS: display toast like before on error ?
            error={hasError ? t('re_invite_user_error') : ''}
            success={success}
            headerTitle={t('re_invite_user__modal__title')}
            text={t('re_invite_user__modal__body', {
                email: user?.email ?? '',
            })}
        />
    );
};

export default UserReinviteContent;
