import { useEffect, useState } from 'react';

import { Button, CustomFile, Dropzone, DropzoneTypeEnum, Stack, Text } from '@partoohub/ui';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { useTranslation } from 'react-i18next';

import usePhotosUpload from 'app/businessEditV2/hooks/usePhotosUpload';

import {
    ImagesUploaderProvider,
    useImagesUploaderContext,
} from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/hooks/useImagesUploaderContext';

import { useCustomFieldsSectionContext } from 'app/businessEditV2/sections/CustomFieldsSection/hooks/useCustomFieldsDisabledSaveButton';

import {
    CustomFieldsTypeComponentName,
    DescriptionWrapper,
} from './CustomFieldsTypeImagesUploader.styled';

import { ImageUploaderRow } from './ImageUploaderRow/ImageUploaderRow';

const ACCEPTED_FILES = {
    ['image/png']: ['.png'],
    ['image/jpeg']: ['.jpg', '.jpeg'],
};

type Props = {
    name: string;
    displayName?: string;
    disabled: boolean;
    maxLengths: Array<number>;
    textFields: Array<string>;
    onChange: (newValue: Array<Record<string, any>>) => void;
};

const Component = ({ name, displayName, disabled, textFields, onChange }: Props) => {
    const { t } = useTranslation();
    const { images, setImages } = useImagesUploaderContext();
    const [files, setFiles] = useState<CustomFile[]>([]); // "files" is used temporarily to upload photos and then reset

    const { setDisabled } = useCustomFieldsSectionContext();

    const { mutate: uploadPhotos, isLoading } = usePhotosUpload(im => {
        const texts: { [key: string]: string } = {};
        for (const textFieldName of textFields) {
            texts[textFieldName] = '';
        }
        setImages([
            ...images,
            ...im.result.uploads.map((image, index) => ({
                url: image,
                texts: { ...texts },
                size: files[index]?.size,
                name: files[index]?.name,
            })),
        ]);
        setFiles([]);
    });

    const onDropSuccess = (newFiles: CustomFile[]) => {
        setFiles(newFiles);
    };

    useEffect(() => {
        if (files?.length) {
            uploadPhotos(files);
        }
    }, [files]);

    useEffect(() => {
        onChange(images);
    }, [images]);

    useEffect(() => {
        setDisabled(isLoading);
    }, [isLoading]);

    return (
        <>
            <CustomFieldsTypeComponentName variant="bodySBold" color="secondary">
                {displayName ?? name}
            </CustomFieldsTypeComponentName>
            <Stack gap="16px">
                <Dropzone
                    title={t(
                        'business_edit_custom_fields_type_images_uploader_dropzone_description',
                    )}
                    acceptedDescription={
                        <DescriptionWrapper>
                            <Text as="span" variant="bodyMBold" color="secondary">
                                {t(
                                    'business_edit_custom_fields_type_images_uploader_dropzone_size',
                                )}
                            </Text>
                            <Button
                                dataTrackId="business_edit_custom_fields_type_images_uploader_dropzone_button"
                                appearance="outlined"
                                variant="secondary"
                            >
                                {t('business_edit_custom_fields_type_images_uploader_button_text')}
                            </Button>
                        </DescriptionWrapper>
                    }
                    accept={ACCEPTED_FILES}
                    type={DropzoneTypeEnum.Photo}
                    files={files}
                    multiple
                    onDropSuccess={onDropSuccess}
                    onDelete={() => {}}
                    maxSize={5_000_000} // 5Mo
                    disabled={disabled}
                    displayPreview={false}
                    loading={isLoading}
                />
                {images.map((image, index) => (
                    <ImageUploaderRow key={`${image.name}-${index}`} imageIndex={index} />
                ))}
            </Stack>
        </>
    );
};

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'onChange' | 'value';

export const CustomFieldsTypeImagesUploader = ({
    name,
    control,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { ref: _ref, ...usedFields } }) => {
            const { textFields, maxLengths } = props;
            return (
                <ImagesUploaderProvider
                    initialValue={usedFields.value}
                    textFields={textFields}
                    maxLengths={maxLengths}
                >
                    <Component {...props} {...usedFields} />
                </ImagesUploaderProvider>
            );
        }}
    />
);
