import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { CUSTOM_FIELDS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

export default function useDeleteCustomFieldSection(
    id: number,
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    return useMutation(() => api.customFieldsSections.deleteCustomFieldSection(id), {
        onSuccess: () => {
            queryClient.invalidateQueries(invalidatesAny(CUSTOM_FIELDS)).then(onSuccess);
        },
        onError,
    });
}
