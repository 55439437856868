import { IconElement } from '@partoohub/ui';

export enum BusinessListQueryKeys {
    QUERY = 'query',
    NAME = 'name',
    STATUS = 'status',
    PRODUCTS = 'products',
    GROUP = 'group',
    ORDER_BY = 'order_by',
    COMPLETION_RATE = 'completion_rate',
}

export enum CompletionRateValues {
    LOW = 'low',
    MID = 'mid',
    HIGH = 'high',
}

export const CompletionRateArrayValues: Array<CompletionRateValues> =
    Object.values(CompletionRateValues);

export type SelectableContentOption = {
    name: string;
    icon: IconElement;
    text: string;
    value: string;
};
