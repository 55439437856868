import { useEffect, useRef } from 'react';

// TODO: Replace by InfiniteScroll from 'react-infinite-scroll-component'
import InfiniteScroll from 'react-infinite-scroller';

import FlipAnimated from 'app/common/designSystem/components/atoms/FlipAnimated';

import { useReviewsQueryContext } from 'app/reviewManagement/reviewList/hooks/useReviewsQueryContext';

import { ReviewTableContainer } from './ReviewTable.styled';
import { FeedbackResultPanel } from '../FeedbackResultPanel';
import { ReviewCard } from '../ReviewCard/ReviewCard';
import ReviewCardLoader from '../ReviewCard/ReviewCardLoader';

export const ReviewTable = () => {
    const { reviews, fetchNextPage, hasNextPage, isLoading } = useReviewsQueryContext();

    const prevReviewIds = useRef(new Set());

    useEffect(() => {
        prevReviewIds.current = new Set(reviews.map(({ id }) => id));
    }, [reviews]);

    return (
        <InfiniteScroll
            loadMore={() => fetchNextPage()}
            hasMore={hasNextPage && !isLoading}
            useWindow={false}
            getScrollParent={() => document.getElementById('react-content')}
            threshold={1000}
        >
            <ReviewTableContainer>
                {reviews.map(review => (
                    <FlipAnimated
                        key={`${review.reviewObjectType}_${review.id}`}
                        animationKey={reviews}
                        velocity={4}
                    >
                        {({ innerRef }) => (
                            <ReviewCard
                                review={review}
                                innerRef={innerRef}
                                animateEnter={!prevReviewIds.current.has(review.id)}
                            />
                        )}
                    </FlipAnimated>
                ))}
                {isLoading && <ReviewCardLoader key="loading_review_card" />}
                <FeedbackResultPanel />
            </ReviewTableContainer>
        </InfiniteScroll>
    );
};
