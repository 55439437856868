import React from 'react';

import { ActionsChart } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Metrics/MetricsChart/ActionsChart/ActionsChart';
import { ViewsChart } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Metrics/MetricsChart/ViewsChart/ViewsChart';

export const MetricsChart = () => {
    return (
        <>
            <ViewsChart />
            <ActionsChart />
        </>
    );
};
