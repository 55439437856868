import styled from '@emotion/styled';

export const DropdownBusinessDetailContainer = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    cursor: pointer;
    &:hover {
        * {
            color: ${({ theme }) => theme.colors.default.initial};
        }
    }
`;
