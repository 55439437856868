import {
    V2BusinessStatus,
    V2FormattedBusinessData,
} from 'app/api/types/business';

import businessStatusFilter from './businessStatusFilter';
import facebookFilter from './facebookFilter';
import gmbFilter from './gmbFilter';
import groupIdFilter from './groupIdFilter';
import groupIdInFilter from './groupIdInFilter';
import orgIdFilter from './orgIdFilter';
import orgIdInFilter from './orgIdInFilter';
import presenceManagementFilter from './presenceManagementFilter';
import reviewBoosterFilter from './reviewBoosterFilter';
import reviewManagementFilter from './reviewManagementFilter';

type BusinessFiltersParams = {
    gmbConnection: boolean | null;
    facebookConnection: boolean | null;
    presenceManagement: boolean | null;
    reviewManagement: boolean | null;
    reviewBooster: boolean | null;
    orgId: number | null;
    orgIdIn: Array<number> | null;
    groupId: number | null;
    groupIdIn: Array<number> | null;
    status: V2BusinessStatus | null;
};

type BusinessFilters = {
    gmbConnection: (
        businesses: Array<V2FormattedBusinessData>,
        arg1: boolean,
    ) => Array<V2FormattedBusinessData>;
    facebookConnection: (
        businesses: Array<V2FormattedBusinessData>,
        arg1: boolean,
    ) => Array<V2FormattedBusinessData>;
    presenceManagement: (
        businesses: Array<V2FormattedBusinessData>,
        arg1: boolean,
    ) => Array<V2FormattedBusinessData>;
    reviewManagement: (
        businesses: Array<V2FormattedBusinessData>,
        arg1: boolean,
    ) => Array<V2FormattedBusinessData>;
    reviewBooster: (
        businesses: Array<V2FormattedBusinessData>,
        arg1: boolean,
    ) => Array<V2FormattedBusinessData>;
    orgId: (
        businesses: Array<V2FormattedBusinessData>,
        arg1: number,
    ) => Array<V2FormattedBusinessData>;
    orgIdIn: (
        businesses: Array<V2FormattedBusinessData>,
        arg1: number,
    ) => Array<V2FormattedBusinessData>;
    status: (
        businesses: Array<V2FormattedBusinessData>,
        arg1: V2BusinessStatus,
    ) => Array<V2FormattedBusinessData>;
    groupId: (
        businesses: Array<V2FormattedBusinessData>,
        arg1: number,
    ) => Array<V2FormattedBusinessData>;
    groupIdIn: (
        businesses: Array<V2FormattedBusinessData>,
        arg1: number,
    ) => Array<V2FormattedBusinessData>;
};

const businessFilters: BusinessFilters = {
    gmbConnection: gmbFilter,
    facebookConnection: facebookFilter,
    presenceManagement: presenceManagementFilter,
    reviewManagement: reviewManagementFilter,
    reviewBooster: reviewBoosterFilter,
    orgId: orgIdFilter,
    // @ts-ignore
    orgIdIn: orgIdInFilter,
    status: businessStatusFilter,
    groupId: groupIdFilter,
    // @ts-ignore
    groupIdIn: groupIdInFilter,
};

const filterBusinesses = (
    businesses: Array<V2FormattedBusinessData>,
    filterParams: Record<
        keyof BusinessFiltersParams,
        V2BusinessStatus | number | Array<number> | boolean | null
    >,
): Array<V2FormattedBusinessData> =>
    Object.keys(businessFilters).reduce((businessList, filterName) => {
        if (filterName in filterParams) {
            const paramValue = filterParams[filterName];

            if (paramValue === null || paramValue === undefined) {
                return businessList;
            }

            return businessFilters[filterName](businessList, paramValue);
        }

        return businessList;
    }, businesses);

export default filterBusinesses;
