import { IconButton, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import dataLayer from 'app/common/utils/dataLayer';
import { IS_BELOW_MOBILE, useDeviceType } from 'app/hooks';

type DownloadInvoiceButtonProps = {
    invoiceId: string;
};

const DownloadInvoiceButton = ({ invoiceId }: DownloadInvoiceButtonProps) => {
    const { t } = useTranslation();
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_MOBILE);

    const { data: me } = useMe();

    const sendTrackingData = (status: string): void => {
        dataLayer.pushDict('click_billing_settings', {
            status: status,
            user_id: me?.id,
            shadow_user_id: me?.shadow_user_id,
            invoice_id: invoiceId,
        });
    };

    const mutation = useMutation(api.invoice.downloadInvoice, {
        onSuccess: (url: string) => {
            sendTrackingData('success');

            if (!isBelowProvidedDevices) {
                toast.success(t('invoice_success_download_message'), t('success'), {
                    icon: false,
                });
            }
            window.location = url;
        },
        onError: () => {
            sendTrackingData('failure');

            if (!isBelowProvidedDevices) {
                toast.error(t('invoice_failure_download_message'), t('error'), {
                    icon: false,
                });
            }
        },
    });

    return (
        <IconButton
            dataTrackId="download_invoice_button"
            appearance="outlined"
            icon={['fa-arrow-down-to-line']}
            onClick={() => mutation.mutate(invoiceId)}
        />
    );
};

export default DownloadInvoiceButton;
