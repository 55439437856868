import { IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { PBox } from 'app/common/components/PBox/components/PBox';

import { AverageGradeSectionStyled } from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/AverageGrade/AverageGradeStyled';

import PeriodDoughnut from './PeriodDoughnut.container';
import TotalDoughnut from './TotalDoughnut.container';

const AverageGrade = () => {
    const { t } = useTranslation();
    return (
        <PBox
            title={t('average_grade')}
            icon={['fa-heart', IconPrefix.SOLID]}
            content={
                <AverageGradeSectionStyled>
                    <PeriodDoughnut />
                    <TotalDoughnut />
                </AverageGradeSectionStyled>
            }
        />
    );
};

export default AverageGrade;
