import { useMemo, useState } from 'react';

import { DynamicFilters, Filter, FilterCategory, Filters } from '@partoohub/modular-components';

import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { SelectableContentFilters } from 'app/common/components/SelectableContentFilters/SelectableContentFilters';
import { DeviceType, useDeviceType } from 'app/hooks';
import usePlatformsFilter, {
    PLATFORMS_FILTER_PARAM,
} from 'app/presence/googlePosts/hooks/filters/usePlatformsFilter';

import useStatusFilter, {
    STATUS_FILTER_PARAM,
} from 'app/presence/googlePosts/hooks/filters/useStatusFilter';
import useTopicsFilter, {
    TOPICS_FILTER_PARAMS,
} from 'app/presence/googlePosts/hooks/filters/useTopicsFilter';
import {
    ERROR_GOOGLE_POSTS,
    EXPIRED_POSTS,
    PENDING_GOOGLE_POSTS,
    PUBLISHED_GOOGLE_POSTS,
    TO_CONFIGURE_POSTS,
} from 'app/presence/googlePosts/variables/googlePostStatus';

export function FilterButton() {
    const { t } = useTranslation();
    const isDesktop = useDeviceType().device === DeviceType.Desktop;

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const [platformsFilter, setPlatformsFilter] = usePlatformsFilter();
    const [topicsFilter, setTopicsFilter] = useTopicsFilter();
    const [statusFilter, setStatusFilter] = useStatusFilter();

    const filters = {
        [PLATFORMS_FILTER_PARAM]: new Map(Object.entries(platformsFilter)),
        [TOPICS_FILTER_PARAMS]: new Map(Object.entries(topicsFilter)),
        [STATUS_FILTER_PARAM]: new Map(Object.entries(statusFilter)),
    };

    const setFilters = filters => {
        setPlatformsFilter(Object.fromEntries(filters[PLATFORMS_FILTER_PARAM].entries()));
        setTopicsFilter(Object.fromEntries(filters[TOPICS_FILTER_PARAMS].entries()));
        setStatusFilter(Object.fromEntries(filters[STATUS_FILTER_PARAM].entries()));
    };

    const schema: Filters = useMemo(() => {
        const PLATFORM_FILTER: Filter = {
            id: PLATFORMS_FILTER_PARAM,
            property: PLATFORMS_FILTER_PARAM,
            sectionName: t('filter_by_platform'),
            filterCategory: FilterCategory.SELECTABLE,
            options: [
                {
                    name: 'facebook',
                    value: 'facebook',
                    label: (
                        <SelectableContentFilters
                            icon={[FontAwesomeIconsPartooUsed.faFacebook, IconPrefix.BRANDS]}
                            text={'Facebook'}
                        />
                    ),
                },
                {
                    name: 'google_my_business',
                    value: 'google_my_business',
                    label: (
                        <SelectableContentFilters
                            icon={[FontAwesomeIconsPartooUsed.faGoogle, IconPrefix.BRANDS]}
                            text={'Google'}
                        />
                    ),
                },
                {
                    name: 'instagram',
                    value: 'instagram',
                    label: (
                        <SelectableContentFilters
                            icon={[FontAwesomeIconsPartooUsed.faInstagram, IconPrefix.BRANDS]}
                            text={'Instagram'}
                        />
                    ),
                },
            ],
            numberPerLine: 3,
        };

        const STATUS_FILTER: Filter = {
            id: STATUS_FILTER_PARAM,
            property: STATUS_FILTER_PARAM,
            sectionName: t('filter_by_status'),
            filterCategory: FilterCategory.SELECTABLE_RADIO,
            options: [
                {
                    name: PUBLISHED_GOOGLE_POSTS,
                    value: PUBLISHED_GOOGLE_POSTS,
                    label: (
                        <SelectableContentFilters
                            icon={[FontAwesomeIconsPartooUsed.faCheck]}
                            text={t('published_google_posts')}
                        />
                    ),
                },
                {
                    name: EXPIRED_POSTS,
                    value: EXPIRED_POSTS,
                    label: (
                        <SelectableContentFilters
                            icon={[FontAwesomeIconsPartooUsed.faBoxArchive]}
                            text={t('expired_google_posts')}
                        />
                    ),
                },
                {
                    name: PENDING_GOOGLE_POSTS,
                    value: PENDING_GOOGLE_POSTS,
                    label: (
                        <SelectableContentFilters
                            icon={[FontAwesomeIconsPartooUsed.faHourglassHalf]}
                            text={t('pending_google_posts')}
                        />
                    ),
                },
                {
                    name: TO_CONFIGURE_POSTS,
                    value: TO_CONFIGURE_POSTS,
                    label: (
                        <SelectableContentFilters
                            icon={[FontAwesomeIconsPartooUsed.faWarning]}
                            text={t('to_configure_google_posts')}
                        />
                    ),
                },
                {
                    name: ERROR_GOOGLE_POSTS,
                    value: ERROR_GOOGLE_POSTS,
                    label: (
                        <SelectableContentFilters
                            icon={[FontAwesomeIconsPartooUsed.faCircleExclamation]}
                            text={t('error_google_posts')}
                        />
                    ),
                },
            ],
        };

        const TOPIC_FILTER: Filter = {
            id: TOPICS_FILTER_PARAMS,
            property: TOPICS_FILTER_PARAMS,
            sectionName: t('google_post_filter_types'),
            filterCategory: FilterCategory.SELECTABLE,
            options: [
                {
                    name: 'news',
                    value: 'news',
                    label: (
                        <SelectableContentFilters
                            icon={['fa-certificate', IconPrefix.REGULAR]}
                            text={t('google_post_news')}
                        />
                    ),
                },
                {
                    name: 'event',
                    value: 'event',
                    label: (
                        <SelectableContentFilters
                            icon={[FontAwesomeIconsPartooUsed.faCalendar, IconPrefix.REGULAR]}
                            text={t('google_post_event')}
                        />
                    ),
                },
                {
                    name: 'offer',
                    value: 'offer',
                    label: (
                        <SelectableContentFilters
                            icon={[FontAwesomeIconsPartooUsed.faTag, IconPrefix.REGULAR]}
                            text={t('google_post_offer')}
                        />
                    ),
                },
                {
                    name: 'covid',
                    value: 'covid',
                    label: (
                        <SelectableContentFilters
                            icon={['fa-first-aid', IconPrefix.REGULAR]}
                            text={t('google_post_covid')}
                        />
                    ),
                },
            ],
        };

        return [TOPIC_FILTER, PLATFORM_FILTER, STATUS_FILTER];
    }, []);

    return (
        <DynamicFilters
            schema={schema}
            filters={filters}
            onApply={setFilters}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            resetText={t('reset')}
            applyText={t('apply')}
            title={t('filters')}
            buttonText={isDesktop ? t('filters') : ''}
        />
    );
}
