import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import PairedButtons from 'app/common/designSystem/components/molecules/PairedButtons';

import { CustomFieldsTypeComponentName } from './CustomFieldsTypeBoolean.styled';

type Props = {
    value: number;
    name: string;
    onChange: (newValue: boolean | null) => void;
    disabled: boolean;
    displayName?: string;
};

const mapValue = {
    true: 'yes',
    false: 'no',
    null: 'not_specified',
};

const Component = ({ value, name, onChange, disabled, displayName }: Props) => {
    const { t } = useTranslation();

    return (
        <div>
            <CustomFieldsTypeComponentName
                disabled={disabled}
                variant={'bodySBold'}
                color={'secondary'}
            >
                {displayName || name}
            </CustomFieldsTypeComponentName>
            <PairedButtons
                selectedButton={t(mapValue[value])}
                options={[
                    {
                        label: t('yes'),
                        value: true,
                    },
                    {
                        label: t('no'),
                        value: false,
                    },
                    {
                        label: t('not_specified'),
                        value: null,
                    },
                ]}
                onClick={onChange}
                disabled={disabled}
                textTooltip={disabled ? t('field_read_only') : ''}
            />
        </div>
    );
};

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'onChange' | 'value';

const CustomFieldsTypeBoolean = ({
    name,
    control,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { ref: _ref, ...usedFields } }) => (
            <Component {...props} {...usedFields} />
        )}
    />
);

export default CustomFieldsTypeBoolean;
