import Clickable from 'app/common/components/buttons/Clickable';
import { STATIC_BASE_URL } from 'app/config';

type Props = {
    onClick: (arg0: Event) => void;
    className?: string;
};

const CloseIcon = ({ onClick, className = '' }: Props) => (
    <Clickable onClick={onClick} className={className || 'flex--inherit'}>
        <img src={`${STATIC_BASE_URL}/images/app/icons/menu.svg`} alt="" />
    </Clickable>
);

export default CloseIcon;
