import { Option, Section, SingleSelect } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

type Props = {
    allAvailableKeywords: Section[];
    currentKeyword?: Option;
    setCurrentKeyword: (keyword?: Option) => void;
};

export const KeywordDropDownSelection = ({
    currentKeyword,
    allAvailableKeywords,
    setCurrentKeyword,
}: Props) => {
    const { t } = useTranslation();

    const onChangeKeyword = (option?: Option) => {
        setCurrentKeyword(option);
    };

    return (
        <SingleSelect
            label={t('keyword_filter_label')}
            maxHeight={150}
            dataTrackId="page_competitive_benchmark__select_keywords"
            onChange={onChangeKeyword}
            disableReset
            selectedValue={currentKeyword}
            sections={allAvailableKeywords}
            readOnly
        />
    );
};
