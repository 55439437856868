import { TipType } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/data/funnelSteps';

import { AdviceIcon } from './Advice';
import { BulbIcon } from './Bulb';

interface TipIconProps {
    type: TipType;
}

export const TipIcon = ({ type }: TipIconProps) => {
    switch (type) {
        case 'info':
            return <BulbIcon />;
        case 'advice':
            return <AdviceIcon />;
    }
};
