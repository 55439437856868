import { FontAwesomeIconsPartooUsed, IconPrefix, LeftElementType, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { updateSearchQuery } from 'app/common/reducers/newBusinesses';
import { UserListQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

const ConnectionSearch = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [value, setValue] = useStateQueryParams(UserListQueryKeys.QUERY);

    const handleSearchReset = () => {
        setValue('');
        dispatch(updateSearchQuery(''));
    };

    return (
        <TextInput
            dataTrackId="connection-search"
            label={t('settings_menu_platforms_connections_searchfield')}
            value={value}
            onChange={v => {
                setValue(v);
                dispatch(updateSearchQuery(v ?? ''));
            }}
            leftElement={[FontAwesomeIconsPartooUsed.faSearch, IconPrefix.REGULAR]}
            leftElementType={LeftElementType.Icon}
            isReset
            onReset={handleSearchReset}
        />
    );
};

export default ConnectionSearch;
