import { useContext, useEffect, useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Modal, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import api from 'app/api/v2/api_calls';
import { TaskContext } from 'app/common/contexts/taskListener';
import {
    MarginHolder,
    ModalContent,
} from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/screens/TypePlatform/InstagramConnectModal.styled';

type Props = {
    onSuccess: () => void;
    oauthAccountId: string;
};

export default function FacebookRefreshModal({ onSuccess, oauthAccountId }: Props) {
    const { t } = useTranslation();
    const { oauthAccounts } = useContext(TaskContext);
    const { downloadLocationsOAuthAccounts } = oauthAccounts;
    const [refreshProgress, setRefreshProgress] = useState<number | null>(null);

    useEffect(() => {
        if (oauthAccountId && downloadLocationsOAuthAccounts[oauthAccountId]) {
            const oauthAccount = downloadLocationsOAuthAccounts[oauthAccountId];
            const { accountsProgress, accountsTotal, status } = oauthAccount;
            setRefreshProgress(Math.round((accountsProgress * 100) / accountsTotal) || 0);

            if (status == 'SUCCESS') {
                delete downloadLocationsOAuthAccounts[oauthAccountId];
                onSuccess();
            }
        }
    }, [downloadLocationsOAuthAccounts, oauthAccountId]);

    useEffect(() => {
        delete downloadLocationsOAuthAccounts[oauthAccountId];
        api.account.refreshFacebookAccounts(oauthAccountId);
    }, [oauthAccountId]);

    return (
        <Modal isOpen={true} closeModal={() => undefined} hideOnClickOutside={false}>
            <ModalContent>
                <Text as="h4" color={HEX_COLORS.blackberry} variant="heading4">
                    {t('fetching_facebook_accounts_in_progress')}
                </Text>
                <MarginHolder height={14}></MarginHolder>
                <Text color={'secondary'} variant="bodyMRegular">
                    <span className={'fa fa-spin fa-loader'}></span>
                    &nbsp;
                    {refreshProgress === null && <span>{t('loading')}</span>}
                    {refreshProgress !== null && <span>{refreshProgress}%</span>}
                </Text>
            </ModalContent>
        </Modal>
    );
}
