import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import NavSection from 'app/common/designSystem/components/atoms/NavSection/NavSection';
import { CollapsibleSidebarTitle } from 'app/common/designSystem/components/molecules/Sidebar/Sidebar/CollapsibleSidebar.styled';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';

const Visibility: FC = () => {
    const { t } = useTranslation();

    const enableNewGroups = useNewGroups();

    return (
        <>
            <CollapsibleSidebarTitle data-cy="collapsible-sidebar-title">
                {t('sidebar_visibility')}
            </CollapsibleSidebarTitle>

            {!enableNewGroups && (
                <NavSection
                    title={t('locations')}
                    path={VISIBILITY_LOCATION_PATH}
                    to={`${VISIBILITY_LOCATION_PATH}?group=all`}
                    dataTrack="sidemenu_visibility__locations"
                    exact
                />
            )}

            {enableNewGroups && (
                <NavSection
                    title={t('locations')}
                    path={VISIBILITY_LOCATION_PATH}
                    dataTrack="sidemenu_visibility__locations"
                />
            )}
        </>
    );
};

export default Visibility;
