import React, { useState } from 'react';

import { isEmpty } from 'lodash-es';

import { AccountPartnerName } from 'app/api/types/account';

import { STATIC_BASE_URL } from 'app/config';

import {
    AccountBadgeContainer,
    AccountBadgeEmpty,
    AccountBadgeImg,
    AccountBadgeImgContainer,
    AccountContainer,
    AccountImg,
} from './AccountBadgeOption.styled';

type Props = {
    photoUrl: string;
    accountInitials: string;
    tooltip: string;
    name: AccountPartnerName | '';
    onClick?: () => void;
};

const AccountBadgeOption = ({
    photoUrl: rawPhotoUrl,
    accountInitials,
    tooltip,
    name,
    onClick,
}: Props) => {
    const imgName = {
        google: 'google_white_background',
        facebook: 'facebook-white-background',
    };
    const [photoUrl, setPhotoUrl] = useState(rawPhotoUrl);

    return (
        <AccountContainer>
            <AccountBadgeContainer onClick={onClick} role="button">
                {isEmpty(photoUrl) ? (
                    <AccountBadgeEmpty title={tooltip}>
                        {accountInitials.toUpperCase()}
                    </AccountBadgeEmpty>
                ) : (
                    <AccountImg
                        onError={() => setPhotoUrl('')}
                        src={rawPhotoUrl}
                        alt="google profile"
                        style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                        title={tooltip}
                    />
                )}
            </AccountBadgeContainer>
            <AccountBadgeImgContainer>
                <AccountBadgeImg
                    publisher={name}
                    src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/${imgName[name]}.svg`}
                    alt="partner account"
                />
            </AccountBadgeImgContainer>
        </AccountContainer>
    );
};

export default AccountBadgeOption;
