import { useEffect, useState } from 'react';

import { Banner, Button, Icon, Switch, Text, Tooltip, toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { KnowledgeIllustration } from 'app/pages/settings/Chatbot/assets/knowlege-illustration';
import {
    BannerIllustrationWrapper,
    BusinessVariablesList,
    ChatbotKnowledgeButtonContainer,
    ChatbotKnowledgeContainer,
    KnowledgeBannerContainer,
    QAKnowledgeListContainer,
    QAKnowledgeListHeader,
} from 'app/pages/settings/Chatbot/components/Knowledge/ChatbotKnowledge.styled';
import { ChatbotKnowledgeForm } from 'app/pages/settings/Chatbot/components/Knowledge/ChatbotKnowledgeForm';
import { ChatbotKnowledgeItem } from 'app/pages/settings/Chatbot/components/Knowledge/ChatbotKnowledgeItem';
import { useChatbot } from 'app/pages/settings/Chatbot/hooks/useChatbot';
import { useChatbotKnowledgeUpdate } from 'app/pages/settings/Chatbot/hooks/useChatbotKnowledgeUpdate';
import {
    ChatbotContentTypeEnum,
    ChatbotKnowledgeQAContentItem,
    ChatbotType,
} from 'app/pages/settings/Chatbot/type/chatbot';

import { KNOWLEDGE_MAX_TOKENS } from '../../constants/constants';
import { useChatbotUpdate } from '../../hooks/useChatbotUpdate';

interface Props {
    onClose: () => void;
    setHasSucceeded?: (state: boolean) => void;
}

const BusinessVariablesInfo = ({ chatbot }: { chatbot: ChatbotType }) => {
    const { t } = useTranslation();
    const ChatbotUpdateMutation = useChatbotUpdate();

    const [isServiceEnabled, setIsServiceEnabled] = useState(chatbot.enable_services);
    const [isAttributesEnabled, setIsAttributeEnabled] = useState(chatbot.enable_attributes);

    useEffect(() => {
        ChatbotUpdateMutation.mutate({ enable_services: isServiceEnabled });
    }, [isServiceEnabled]);

    useEffect(() => {
        ChatbotUpdateMutation.mutate({ enable_attributes: isAttributesEnabled });
    }, [isAttributesEnabled]);

    return (
        <div>
            <Text variant="bodyLBold">{t('chatbot_knowledge_business_variables_title')}</Text>
            <BusinessVariablesList>
                <li>
                    <Switch dataTrackId="" name="business_hours" checked disabled />{' '}
                    {t('business_hours')}
                </li>
                <li>
                    <Switch dataTrackId="" name="contact" checked disabled /> {t('contact')}
                </li>
                <li>
                    <Switch
                        dataTrackId=""
                        name="enable_attributes"
                        checked={isAttributesEnabled}
                        onChange={() =>
                            setIsAttributeEnabled(isAttributesEnabled => !isAttributesEnabled)
                        }
                    />{' '}
                    {t('attributes')}
                </li>
                <li>
                    <Switch dataTrackId="" name="business_description" checked disabled />{' '}
                    {t('business_description')}
                </li>
                <li>
                    <Switch dataTrackId="" name="links" checked disabled /> {t('links')}
                </li>
                <li>
                    <Switch
                        dataTrackId="enable_services"
                        name="enable_services"
                        checked={isServiceEnabled}
                        onChange={() => setIsServiceEnabled(isServiceEnabled => !isServiceEnabled)}
                    />{' '}
                    {t('gmb_services_business_edit')}
                    <Tooltip text={t('chatbot_enable_services_tooltip')} placement="right">
                        <Icon icon={['fa-info-circle']} color="secondary" />
                    </Tooltip>
                </li>
            </BusinessVariablesList>
        </div>
    );
};

const KnowledgeBanner = () => {
    const { t } = useTranslation();
    return (
        <KnowledgeBannerContainer>
            <Text variant="bodyLSemibold" className="knowledge_banner_text">
                {t('chatbot_knowledge_info_banner_text')}
            </Text>
            <BannerIllustrationWrapper>
                <KnowledgeIllustration />
            </BannerIllustrationWrapper>
        </KnowledgeBannerContainer>
    );
};

export const ChatbotKnowledge = ({ onClose, setHasSucceeded }: Props) => {
    const { t } = useTranslation();
    const { data: chatData } = useChatbot();
    const knowledgeData = chatData?.knowledge[0];
    const chatbotKnowledgePatchMutation = useChatbotKnowledgeUpdate();
    const [isShownKnowledgeQAForm, setIsShownKnowledgeQAForm] = useState(false);

    const tokenLimitReached = () => {
        if (knowledgeData?.content_type === ChatbotContentTypeEnum.qa) {
            const knowledgeTokens = knowledgeData?.content.items
                .map(item => item.question + item.answer)
                .join('').length;
            return knowledgeTokens > KNOWLEDGE_MAX_TOKENS * 4;
        }
        return false;
    };

    const onUpdate = (newKnowledgeItem: ChatbotKnowledgeQAContentItem, index: number) => {
        if (knowledgeData?.content_type === ChatbotContentTypeEnum.qa) {
            knowledgeData.content.items[index] = newKnowledgeItem;
            chatbotKnowledgePatchMutation.mutate(knowledgeData, {
                onSuccess: () => {
                    if (tokenLimitReached()) {
                        toast.error(
                            t('chatbot_snackbar_qna_token_limit_error_text'),
                            t('chatbot_snackbar_qna_token_limit_error_title'),
                        );
                    } else {
                        toast.success(
                            t('chatbot_snackbar_changes_saved_text'),
                            t('chatbot_snackbar_changes_saved_title'),
                        );
                    }
                },
            });
        }
    };

    const onCreate = (newKnowledgeItem: ChatbotKnowledgeQAContentItem) => {
        if (knowledgeData?.content_type === ChatbotContentTypeEnum.qa) {
            knowledgeData.content.items.push(newKnowledgeItem);
            chatbotKnowledgePatchMutation.mutate(knowledgeData);
            setIsShownKnowledgeQAForm(false);
        }
        if (tokenLimitReached()) {
            toast.error(
                t('chatbot_snackbar_token_limit_error_text'),
                t('chatbot_snackbar_token_limit_error_title'),
            );
        }
    };

    const onDelete = (index: number) => {
        if (knowledgeData?.content_type === ChatbotContentTypeEnum.qa) {
            knowledgeData.content.items.splice(index, 1);
            chatbotKnowledgePatchMutation.mutate(knowledgeData);
        }
    };

    const handleOnConfirm = () => {
        onClose();
        if (knowledgeData?.content_type === ChatbotContentTypeEnum.qa) {
            chatbotKnowledgePatchMutation.mutate(knowledgeData);
        }
        if (setHasSucceeded && !tokenLimitReached()) {
            setHasSucceeded(true);
            toast.success(
                t('chatbot_snackbar_changes_saved_text'),
                t('chatbot_snackbar_changes_saved_title'),
            );
        }
    };

    return (
        <ChatbotKnowledgeContainer>
            <KnowledgeBanner />
            {chatData && <BusinessVariablesInfo chatbot={chatData} />}
            <QAKnowledgeListContainer>
                <QAKnowledgeListHeader>
                    <Text variant="bodyLBold">{t('chatbot_knowledge_qa_header_title')}</Text>
                    <Button
                        dataTrackId={'chatbot-knowledge-qa-add'}
                        variant="primary"
                        appearance="outlined"
                        shape="cube"
                        onClick={() => setIsShownKnowledgeQAForm(true)}
                        size="medium"
                        icon={['fa-plus']}
                    >
                        {t('chatbot_knowledge_add_qa')}
                    </Button>
                </QAKnowledgeListHeader>
                {isShownKnowledgeQAForm && (
                    <ChatbotKnowledgeForm
                        onCancel={() => setIsShownKnowledgeQAForm(false)}
                        onSubmit={onCreate}
                    />
                )}

                {tokenLimitReached() && (
                    <Banner
                        dataTrackId=""
                        variant="danger"
                        icon={<Icon icon={['fa-warning']} color="danger" iconSize="14px" />}
                        withCloseIcon={false}
                    >
                        <Text variant="bodyMRegular" color="danger">
                            {t('chatbot_instruction_qna_token_limit_error')}
                        </Text>
                    </Banner>
                )}

                {knowledgeData?.content_type === ChatbotContentTypeEnum.qa &&
                    knowledgeData?.content.items.map((item, index) => (
                        <ChatbotKnowledgeItem
                            key={`knowledge_item${index}`}
                            knowledgeItem={item}
                            index={index}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                        />
                    ))}
            </QAKnowledgeListContainer>
            <ChatbotKnowledgeButtonContainer>
                <Button
                    onClick={onClose}
                    dataTrackId="chatbot_knowledge_cancel"
                    appearance="outlined"
                    variant="secondary"
                    shape="cube"
                    size="large"
                >
                    {t('cancel')}
                </Button>
                <Button
                    onClick={handleOnConfirm}
                    dataTrackId="chatbot_knowledge_confirm"
                    appearance="contained"
                    shape="cube"
                    variant="primary"
                    size="large"
                    disabled={tokenLimitReached()}
                    full
                >
                    {t('chatbot_form_confirm_button')}
                </Button>
            </ChatbotKnowledgeButtonContainer>
        </ChatbotKnowledgeContainer>
    );
};
