import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';

import { ColorState } from './types';

export default function useLogoSectionColors(): ColorState {
    // Hooks
    const { data: business } = useBusiness();
    const logoUrl = business?.photos?.LOGO ?? '';

    const { data: publisherErrors } = usePublisherErrors({});

    const logoPermission = useFieldMetadata('photos', 'logo')?.enabled;
    const photosPermission = useFieldMetadata('photos', 'secondary')?.enabled;

    const isClosed = useIsBusinessClosed();

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // Disabled
    if (!(logoPermission && photosPermission)) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (publisherErrors?.photos?.logo) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields
    if (!logoUrl) {
        return ColorState.MISSING_WARNING;
    }

    // OK
    return ColorState.OK;
}
