import { Drawer } from '@partoohub/ui';

import { FeedbackResultFields } from './FeedbackResultFields';
import { FeedbackResultPanelContainer } from './FeedbackResultPanel.styled';
import { FeedbackResultPanelHeader } from './FeedbackResultPanelHeader';
import { useFeedbackResultPanel } from './hooks';

export const FeedbackResultPanel = () => {
    const { feedbackResult, closePanel } = useFeedbackResultPanel();

    return (
        <Drawer
            width="600px"
            onHide={closePanel}
            isOpen={!!feedbackResult}
            dataTrackId="feedback-result-panel"
        >
            <FeedbackResultPanelContainer>
                <FeedbackResultPanelHeader />
                <FeedbackResultFields />
            </FeedbackResultPanelContainer>
        </Drawer>
    );
};
