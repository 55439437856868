import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import {
    ChatbotBusinessValidationResponse,
    ChatbotContentTypeEnum,
    ChatbotKnowledgeType,
    ChatbotMessage,
    ChatbotPatchPayload,
    ChatbotPreviewResponse,
    ChatbotType,
    ToneOfVoice,
} from 'app/pages/settings/Chatbot/type/chatbot';

export class Chatbots extends ApiResourceBaseClass {
    static getChatbots(): Promise<Array<ChatbotType>> {
        return Chatbots.get('chatbots').then(({ data }) => data);
    }

    static getChatbot(chatbot_id?: number): Promise<ChatbotType> {
        return Chatbots.get(`chatbots/${chatbot_id}`).then(({ data }) => data);
    }

    static createChatbot(): Promise<ChatbotType> {
        return Chatbots.post('chatbots').then(({ data }) => data);
    }

    static patchChatbot(payload: ChatbotPatchPayload, chatbot_id?: number): Promise<ChatbotType> {
        return Chatbots.patch(`chatbots/${chatbot_id}`, payload).then(({ data }) => data);
    }

    static deleteChatbot(chatbot_id: number): Promise<ChatbotType> {
        return Chatbots.delete(`chatbots/${chatbot_id}`).then(({ data }) => data);
    }

    static patchChatbotBusinessSelection(
        businessModal: BusinessModalFiltersType,
        chatbot_id?: number,
    ): Promise<ChatbotType> {
        const payload = {
            business_search_params: businessModal,
        };
        return Chatbots.patch(`chatbots/${chatbot_id}/business-selection`, payload).then(
            ({ data }) => data,
        );
    }

    static getChatbotKnowledge(
        knowledge_id?: number,
        chatbot_id?: number,
    ): Promise<ChatbotKnowledgeType> {
        return Chatbots.get(`chatbots/${chatbot_id}/knowledges/${knowledge_id}`).then(
            ({ data }) => data,
        );
    }

    static patchChatbotKnowledge(
        knowledge: ChatbotKnowledgeType,
        chatbot_id?: number,
    ): Promise<ChatbotKnowledgeType> {
        return Chatbots.patch(`chatbots/${chatbot_id}/knowledges/${knowledge.id}`, {
            content: knowledge.content,
            content_type: ChatbotContentTypeEnum.qa,
        }).then(({ data }) => data);
    }

    static postChatbotPreviewMessage(
        messages: ChatbotMessage[],
        business_id?: string,
        chatbot_id?: number,
    ): Promise<ChatbotPreviewResponse> {
        return Chatbots.post('chatbots/chat-preview', {
            messages,
            business_id,
            chatbot_id,
        }).then(({ data }) => data);
    }

    static patchChatbotActivation(enable: boolean, chatbot_id?: number): Promise<ChatbotType> {
        return Chatbots.patch(`chatbots/${chatbot_id}/activation`, {
            enable,
        }).then(({ data }) => data);
    }

    static getToneOfVoices(): Promise<Array<ToneOfVoice>> {
        return Chatbots.get('chatbots/tone-of-voices').then(({ data }) => data);
    }

    static duplicateChatbot(chatbot_id?: number): Promise<ChatbotType> {
        return Chatbots.post('chatbots', { duplicate_chatbot_id: chatbot_id }).then(
            ({ data }) => data,
        );
    }

    static validateBusinesses(
        chatbotId: number,
        businessSearchFilter: BusinessModalFiltersType,
    ): Promise<ChatbotBusinessValidationResponse> {
        const payload = {
            business_search_params: businessSearchFilter,
        };
        return Chatbots.post(`chatbots/${chatbotId}/validate-business-selection`, payload).then(
            ({ data }) => data,
        );
    }
}
