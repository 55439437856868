import styled from '@emotion/styled';
import { blackColorObject, greyColorObject } from '@partoohub/branding';

import { MenuWrapper } from 'app/common/designSystem/components/molecules/ButtonWithSearchMenuFilterSelection/ButtonWithSearchMenuFilterSelection.styled';

export const TagEditModal = styled(MenuWrapper)`
    padding: 10px;
    gap: 15px;
    max-height: unset;

    .mdc-text-field-helper-line {
        padding: 5px 10px 0px;
        margin: 0;
        min-height: initial;
    }

    .mdc-notched-outline__notch {
        display: none;
    }
`;

export const TagEditPreviousButton = styled.div`
    color: ${blackColorObject['35']};
    font-size: 20px;
    cursor: pointer;
`;

export const TagEditColorSection = styled.div`
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const TagEditColorTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
`;

export const TagEditColorList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
`;

export const TagEditColor = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid white;
    box-sizing: content-box;
    ${({ color }) => `background-color: ${color};`}
    cursor: pointer;

    &.selected {
        ${({ color }) => `outline: 1px solid ${color};`}
        cursor: initial;
    }
`;

export const TagEditButtonSection = styled.div`
    display: flex;
    justify-content: right;
    gap: 10px;
`;

export const TagEditHr = styled.div`
    margin: 0px -10px;
    height: 1px;
    background-color: ${greyColorObject.initial};
    flex-shrink: 0;
`;
