import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

export const AttributeTypeRepeatableEnumContainer = styled.div`
    margin-bottom: 36px;
    display: flex;
`;

export const AttributeTypeRepeatableEnumName = styled(Text)`
    margin-bottom: 8px;
`;

export const SubSection = styled.div`
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    padding: 16px 16px 8px 16px;
`;

export const SubSectionPair = styled.div`
    margin-bottom: 16px;
`;
