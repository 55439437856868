import styled from '@emotion/styled';

export const DiffusionChartContainer = styled.div`
    border-radius: ${({ theme }) => theme.radius.large};
    border: 1px solid #e6e8ed;
    padding: 8px;

    @media screen and (min-width: 768px) {
        padding: 2 * 8px;
    }

    margin-bottom: 24px;
`;
