import { RefObject, createContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { ReviewObjectType } from 'app/api/types/review';
import { businessByIdSelector, isSubscribedToRmSelector } from 'app/common/reducers/newBusinesses';
import useHasReviewTagFeature from 'app/reviewManagement/common/hooks/useHasReviewTagFeature';
import ReviewCardLoader from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/ReviewCardLoader';
import { businessesSelector } from 'app/states/reducers';
import { ReviewObject } from 'app/states/reviews';

import { ReviewCardCommentsSection } from './CommentSection/ReviewCardCommentsSection';
import { StyledReviewCard } from './ReviewCard.styled';
import ReviewCardContent from './ReviewCardContent/ReviewCardContent';
import { ReviewCardHeader } from './ReviewCardHeader/ReviewCardHeader';

import ReviewCardRating from './ReviewCardRating';
import ReviewCardSeparator from './ReviewCardSeparator';

type Props = {
    review: ReviewObject;
    innerRef?: RefObject<any>;
    animateEnter?: boolean;
    onReply?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export type ReviewCardContextType = {
    review: ReviewObject;
    cardRef: RefObject<HTMLDivElement> | undefined;
    handleReply: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ReviewCardContext = createContext<ReviewCardContextType>({
    review: {} as ReviewObject,
    cardRef: undefined,
    handleReply: () => undefined,
});

export function ReviewCard({
    review,
    innerRef,
    animateEnter,
    onReply = (_: React.MouseEvent<HTMLButtonElement>) => undefined,
}: Props) {
    const { businessId, state, pendingAutoReply } = review;
    const busienssList = useSelector(businessesSelector);
    const business = businessByIdSelector(busienssList, businessId);
    const rmActivated = business ? isSubscribedToRmSelector(busienssList, businessId) : true;
    const comments = review.comments;

    const reviewCardContextValue = useMemo(
        () => ({ review, cardRef: innerRef, handleReply: onReply }),
        [review, innerRef, onReply],
    );

    const hasReviewTagFeature = useHasReviewTagFeature();
    const displayTags = hasReviewTagFeature && review.reviewObjectType === ReviewObjectType.REVIEW;
    const hasReply = state !== 'deleted' && (comments.length > 0 || !!pendingAutoReply);

    if (!rmActivated) {
        return <ReviewCardLoader key="loading_review_card" />;
    }

    return (
        <ReviewCardContext.Provider value={reviewCardContextValue}>
            <StyledReviewCard
                animateEnter={animateEnter}
                isDeleted={state === 'deleted'}
                displayTags={displayTags}
                ref={innerRef}
            >
                <ReviewCardHeader displayTags={displayTags} />
                <ReviewCardRating comments={comments} />
                <ReviewCardSeparator reviewHasReply={hasReply} />
                <ReviewCardContent />
                <ReviewCardCommentsSection comments={comments} />
            </StyledReviewCard>
        </ReviewCardContext.Provider>
    );
}
