import { useInfiniteQuery } from 'react-query';

import { MessagingSetting } from 'app/api/types/business_message_settings';
import api from 'app/api/v2/api_calls/camel';
import { MESSAGING_SETTINGS } from 'app/common/data/queryKeysConstants';

export const useMessagingSettings = (
    settingSingleSelectQuery: string,
    business_id?: string,
): [Array<MessagingSetting> | [], () => void, boolean, boolean] => {
    const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
        [MESSAGING_SETTINGS, { query: settingSingleSelectQuery, business_id: business_id }],
        ({ pageParam = 1 }) =>
            api.messagingSettings.bmSettings(pageParam, settingSingleSelectQuery, business_id),
        {
            getNextPageParam: lastPage =>
                lastPage.page < lastPage.maxPage ? lastPage.page + 1 : undefined,
            staleTime: 0,
        },
    );

    const allSettings = data?.pages.reduce((a, b) => [...a, ...b.settings], []) || [];

    return [allSettings, fetchNextPage, hasNextPage ?? false, isLoading];
};
