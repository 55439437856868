import { SyntheticEvent, createContext, useCallback, useEffect, useMemo, useState } from 'react';

import {
    Button,
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    LeftElementType,
    Stack,
    toast,
} from '@partoohub/ui';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { isEqual } from 'lodash-es';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { V2BusinessData } from 'app/api/types/business';
import { SendInvitationData, SendInvitationResponse } from 'app/api/types/review_booster';
import api from 'app/api/v2/api_calls';
import Clickable from 'app/common/components/buttons/Clickable';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import {
    BUSINESS,
    BUSINESSES,
    BUSINESS_EDIT_FIELDS,
    REVIEW_BOOSTER_SMS_TEMPLATE,
} from 'app/common/data/queryKeysConstants';
import { BUSINESS_ID_PARAM } from 'app/common/data/routeIds';
import { useCanSendClevertapEvents } from 'app/common/hooks/queries/useCanSendClevertapEvents';
import usePrevious from 'app/common/hooks/usePrevious';
import { PushNotifsEvent } from 'app/common/services/pushNotifications/events';
import { PushNotificationsWrapper } from 'app/common/services/pushNotifications/pushNotificationsWrapper';
import { isMobile } from 'app/common/services/screenDimensions';
import getHelper, {
    MAX_AUTHORIZED_CHARACTER_COUNT_MESSAGE,
} from 'app/common/services/smsTemplateHelper';

import dataLayer from 'app/common/utils/dataLayer';
import SMSFormControl from 'app/pages/conversations/ReviewBooster/components/SMSFormControl';
import useReviewBoosterBusinessData from 'app/pages/conversations/ReviewBooster/hooks/useReviewBoosterBusinessData';

import { escapeHTML } from 'app/utils/regex';

import BusinessSelect from './BusinessSelect';
import DesktopAlertBanner from './DesktopAlertBanner';
import MobileAlertBanner from './MobileAlertBanner';
import PhoneInput from './PhoneInput';
import {
    ClickableContainer,
    FormTitle,
    FormWrapper,
    LegalInformation,
    LegalMention,
    ReviewBoosterFormContainer,
    ReviewBoosterFormElement,
    SmsTitle,
    SmsWrapper,
    StyledCheckbox,
    StyledTextInput,
} from './ReviewBoosterForm.styled';

export const SelectedBusinessContext = createContext<SelectBusinessContextType>({} as any);
export type SelectBusinessContextType = {
    selectedBusiness: V2BusinessData | undefined;
    setSelectedBusiness: (business: V2BusinessData | undefined) => void;
};

const EXPEDITOR_NAME_MIN_LENGTH = 3;
const EXPEDITOR_NAME_MAX_LENGTH = 11;

const handleError = (error: any, t: TFunction) => {
    if (error.response?.data?.errors) {
        if (error.response.data.errors.json?.target_phone) {
            toast.error(null, t('rb_send_sms_invalid_phone_number'));
        } else if (
            error.response.data.errors.authorization ===
            'This business is not subscribed to review booster'
        ) {
            toast.error(null, t('rb_send_sms_business_not_subscribed'));
        } else if (error.response.data.errors.json?.general?.invitation_status) {
            toast.error(null, t('rb_send_sms_sending_failed'));
        } else if (
            error.response.data.errors.json?.general?.duplicate ===
            'A message has already been sent to this customer'
        ) {
            toast.error(t('rb_invitation_duplicate_message'), t('rb_invitation_not_sent'));
        } else if (error.response.data.errors.json?.general?.errorTerm) {
            toast.error(null, t(error.response.data.errors.json.general.errorTerm));
        } else {
            toast.error(null, t('unknown error'));
        }
    } else {
        toast.error(null, t('unknown error'));
    }
};

const ReviewBoosterForm = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const { [BUSINESS_ID_PARAM]: businessIdToFilter } = qs.parse(location.search);

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [phoneHasChangedOnce, setPhoneHasChangedOnce] = useState(false);
    const [expeditorName, setExpeditorName] = useState<string>('');
    const [selectedBusiness, setSelectedBusiness] = useState<V2BusinessData | undefined>(undefined);
    const [customerName, setCustomerName] = useState<string>('');
    const [showModal, setShowModal] = useState(false);
    const [legalMentionIsChecked, setLegalMentionIsChecked] = useState(true);

    const prevSelectedBusiness = usePrevious(selectedBusiness);
    const canSendClevertapEvents = useCanSendClevertapEvents();

    const onPhoneChange = useCallback(
        (value: string) => {
            setPhoneNumber(value);
            if (!phoneHasChangedOnce) setPhoneHasChangedOnce(true);
        },
        [setPhoneNumber, phoneHasChangedOnce, setPhoneHasChangedOnce],
    );
    let phoneNumberError = '';
    if (!phoneNumber) {
        phoneNumberError = t('field_is_necessary');
    } else if (!isValidPhoneNumber(phoneNumber)) {
        phoneNumberError = t('phone_format_not_correct');
    }

    // Rewrite url when `business_id` is included and business selected change
    useEffect(() => {
        if (
            !!businessIdToFilter &&
            typeof businessIdToFilter === 'string' &&
            !!prevSelectedBusiness &&
            !isEqual(prevSelectedBusiness, selectedBusiness)
        ) {
            const newSearchParams = location.search.replace(businessIdToFilter, '');
            navigate({ search: newSearchParams }, { replace: true });
        }
    }, [businessIdToFilter, selectedBusiness, prevSelectedBusiness]);

    // Did not use useQuery because this call has the same key as the one called in the business field
    const { isLoading: businessIsLoading, data: businessesData } = useInfiniteQuery(
        [BUSINESSES, { query: '' }],
        () => api.business.searchBusinesses({ page: 1 }),
        {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );
    const businesses = businessesData?.pages?.[0]?.businesses;

    const { data: businessToFilterData } = useQuery(
        [BUSINESS, { businessId: businessIdToFilter }],
        () => api.business.getBusiness(businessIdToFilter as string),
        {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            enabled:
                !!businessIdToFilter &&
                typeof businessIdToFilter === 'string' &&
                businesses &&
                businesses.length > 1,
        },
    );

    useEffect(() => {
        if (!selectedBusiness && businesses && businesses.length === 1)
            setSelectedBusiness(businessesData.pages[0].businesses[0]);

        if (!selectedBusiness && businessToFilterData) setSelectedBusiness(businessToFilterData);
    }, [selectedBusiness, businesses, businessToFilterData]);

    const { data: templateData } = useQuery(
        REVIEW_BOOSTER_SMS_TEMPLATE,
        () => api.reviewBooster.getTemplate(),
        {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );

    const reviewBoosterBusinessQuery = useReviewBoosterBusinessData(selectedBusiness);

    useEffect(() => {
        setExpeditorName(reviewBoosterBusinessQuery.data?.expeditor_name ?? '');
    }, [reviewBoosterBusinessQuery.data]);

    const { data: businessEditFieldsData } = useQuery(
        [BUSINESS_EDIT_FIELDS, { businessId: selectedBusiness?.id }],
        () => api.business.getBusinessEditFields(selectedBusiness?.id as string),
        {
            enabled: !!selectedBusiness && !!selectedBusiness.google_place_id,
        },
    );

    const editable = !!businessEditFieldsData
        ?.find(({ name }) => name === 'review_booster')
        ?.fields?.find(({ name }) => name === 'review_booster_sms_template')?.enabled;

    const template = templateData?.template;

    const selectedBusinessContext: SelectBusinessContextType = useMemo(
        () => ({
            selectedBusiness,
            setSelectedBusiness,
        }),
        [selectedBusiness],
    );

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const toggleIsChecked = () => {
        setLegalMentionIsChecked(!legalMentionIsChecked);
    };

    const setCheckBoxAsTrue = () => {
        setLegalMentionIsChecked(true);
    };

    const smsMutation = useMutation<SendInvitationResponse, AxiosError, SendInvitationData>(
        data => api.reviewBooster.sendInvitation(data),
        {
            onSuccess: () => {
                dataLayer.pushDict('send_rb_invite');
                toast.success(t('rb_toast_success_description'), t('rb_toast_success_title'));
            },
        },
    );

    const trimmed = expeditorName.trim();

    const expeditorNameHasError =
        !expeditorName ||
        !trimmed.match(/^[ a-z0-9A-Z]*$/i) ||
        trimmed.length < EXPEDITOR_NAME_MIN_LENGTH ||
        trimmed.length > EXPEDITOR_NAME_MAX_LENGTH;

    const sendEventToPushNotificationsProvider = () => {
        if (canSendClevertapEvents) {
            PushNotificationsWrapper.getInstance().sendEvent(PushNotifsEvent.SEND_SMS);
        }
    };

    const handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        // We track the 'Send sms' event in the Push Notifications provider user profile
        sendEventToPushNotificationsProvider();

        if (templateData) {
            smsMutation.mutate(
                {
                    business_id: selectedBusiness?.id || '',
                    template_id: templateData.template_id,
                    target_name: customerName,
                    target_phone: phoneNumber,
                },
                {
                    onError: error => handleError(error, t),
                    onSuccess: () => {
                        setPhoneNumber('');
                        setPhoneHasChangedOnce(false);
                        setCustomerName('');
                    },
                },
            );
        } else {
            handleError({}, t);
        }
    };

    const businessHasReviewBooster = !!selectedBusiness?.subscriptions.review_booster?.active;
    const businessHasReviewUrl =
        !!selectedBusiness?.google_location_id && !!reviewBoosterBusinessQuery.data?.review_url;
    const disabled =
        !(businessHasReviewBooster && businessHasReviewUrl) || reviewBoosterBusinessQuery.isError;

    const { count, BACK_MATCHER } = getHelper();
    let smsContent = '';
    if (template) {
        smsContent = template.replace(BACK_MATCHER, matcher => {
            switch (matcher.slice(2, -1)) {
                case 'business_name':
                    return selectedBusiness?.name ? escapeHTML(selectedBusiness.name) : '';
                case 'client_name':
                    return customerName;
                case 'url':
                    return 'https://pto.sh/u/WXYZA';
                default:
                    return matcher;
            }
        });
    }
    const textCount: number = count(smsContent);

    const smsFieldHasError = textCount > MAX_AUTHORIZED_CHARACTER_COUNT_MESSAGE;

    return (
        <SelectedBusinessContext.Provider value={selectedBusinessContext}>
            <ReviewBoosterFormElement onSubmit={handleSubmit}>
                <Stack gap="32px">
                    <ReviewBoosterFormContainer>
                        <FormWrapper>
                            <FormTitle>{t('rb_form_title')}</FormTitle>
                            <Stack gap="24px">
                                {!businessIsLoading && businesses && businesses.length > 1 && (
                                    <BusinessSelect />
                                )}
                                {selectedBusiness && (
                                    <>
                                        {template?.match(/\${client_name}/g) && (
                                            <StyledTextInput
                                                dataTrackId="client_name__text_input"
                                                label={t('rb_customer_name_input_placeholder')}
                                                value={customerName}
                                                onChange={value => {
                                                    if (value !== undefined) {
                                                        setCustomerName(value);
                                                    }
                                                }}
                                                leftElement={[FontAwesomeIconsPartooUsed.faUser]}
                                                leftElementType={LeftElementType.Icon}
                                                disabled={disabled}
                                            />
                                        )}

                                        <div>
                                            <PhoneInput
                                                phoneNumber={phoneNumber}
                                                setPhoneNumber={onPhoneChange}
                                                explanation={
                                                    phoneHasChangedOnce ? phoneNumberError : ''
                                                }
                                                disabled={disabled}
                                            />
                                        </div>
                                    </>
                                )}
                            </Stack>
                        </FormWrapper>
                        {selectedBusiness && (
                            <SmsWrapper>
                                <SmsTitle>{t('rb_my_sms')}</SmsTitle>
                                <SMSFormControl
                                    expeditorName={expeditorName}
                                    setExpeditorName={setExpeditorName}
                                    expeditorNameHasError={expeditorNameHasError}
                                    smsFieldHasError={smsFieldHasError}
                                    disabledField={disabled}
                                    editable={editable}
                                    smsContent={smsContent || ''}
                                    url={reviewBoosterBusinessQuery.data?.review_url ?? ''}
                                    selectedBusinessId={selectedBusiness?.id ?? ''}
                                />
                            </SmsWrapper>
                        )}
                    </ReviewBoosterFormContainer>
                    {selectedBusiness && (
                        <LegalMention disabled={disabled}>
                            <StyledCheckbox
                                dataTrackId="legal-information"
                                onChange={toggleIsChecked}
                                checked={legalMentionIsChecked}
                                disabled={disabled}
                            >
                                <LegalInformation
                                    variant="bodyMSemibold"
                                    as="span"
                                    color="secondary"
                                >
                                    {t('rb_legal_information')}
                                </LegalInformation>
                            </StyledCheckbox>
                            <ClickableContainer>
                                <Clickable
                                    className="info-question fas fa-question-circle"
                                    onClick={toggleModal}
                                    disabled={disabled}
                                />
                            </ClickableContainer>
                            <ConfirmModal
                                show={showModal}
                                title={t('rb_legal_information_title')}
                                content={t('rb_legal_information_content')}
                                confirmLabel={t('rb_legal_information_button')}
                                modalFor="warning"
                                onConfirm={() => {
                                    setCheckBoxAsTrue();
                                    toggleModal();
                                }}
                                onHide={toggleModal}
                                hideCancel
                                trackId="legal-information"
                            />
                        </LegalMention>
                    )}
                    <div>
                        <Button
                            type="submit"
                            full
                            dataTrackId="review_booster_form_send"
                            size="large"
                            appearance="contained"
                            shape="cube"
                            variant="primary"
                            disabled={
                                !!phoneNumberError ||
                                !selectedBusiness?.id ||
                                expeditorNameHasError ||
                                smsFieldHasError ||
                                !legalMentionIsChecked ||
                                smsMutation.isLoading ||
                                disabled
                            }
                            icon={['fa-check', IconPrefix.REGULAR]}
                        >
                            {smsMutation.isLoading ? t('loading') : t('rb_send_the_invitation')}
                        </Button>
                    </div>
                </Stack>

                {reviewBoosterBusinessQuery.isSuccess &&
                    (isMobile() ? (
                        <MobileAlertBanner
                            business={selectedBusiness}
                            businessHasReviewBooster={businessHasReviewBooster}
                            businessHasReviewUrl={businessHasReviewUrl}
                        />
                    ) : (
                        <DesktopAlertBanner
                            business={selectedBusiness}
                            businessHasReviewBooster={businessHasReviewBooster}
                            businessHasReviewUrl={businessHasReviewUrl}
                        />
                    ))}
            </ReviewBoosterFormElement>
        </SelectedBusinessContext.Provider>
    );
};

export default ReviewBoosterForm;
