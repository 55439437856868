import { useContext, useEffect, useRef, useState } from 'react';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { css } from '@emotion/react';
import {
    Button,
    FontAwesomeIconsPartooUsed,
    IconButton,
    LeftElementType,
    TextInput,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import MentionTextArea from 'app/common/designSystem/components/atoms/MentionTextArea';
import { MentionTextAreaImperativeHandle } from 'app/common/designSystem/components/atoms/MentionTextArea/MentionTextArea';
import { ButtonWithMenu } from 'app/common/designSystem/components/molecules/ButtonWithMenu/ButtonWithMenu';
import { useCanSendClevertapEvents } from 'app/common/hooks/queries/useCanSendClevertapEvents';
import { useLanguage } from 'app/common/services/getLang';
import { EMOJI_LANGUAGE } from 'app/common/services/languages';
import { PushNotifsEvent } from 'app/common/services/pushNotifications/events';
import { PushNotificationsWrapper } from 'app/common/services/pushNotifications/pushNotificationsWrapper';
import { TemplateFormContext } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/components/MessagesReplyTemplates.context';
import {
    MessageReplyTemplate,
    messageReplyTemplatePlaceholders,
} from 'app/states/messageReplyTemplate/dataTypes';

import {
    ButtonsContainer,
    CardContainer,
    CardWrapper,
    MessageReplyTemplateContainer,
    PickerContainer,
    PlaceholderButtonContainer,
    SaveButton,
    TextAreaPlaceholder,
    TextContainer,
    TitleContainer,
} from './MessageReplyTemplateForm.styled';

import useCreateMessageReplyTemplate from '../hooks/useCreateMessageReplyTemplate';
import useUpdateMessageReplyTemplate from '../hooks/useUpdateMessageReplyTemplate';
import { getTemplateContent } from '../services/utils';

type Props = {
    updatedTemplate?: MessageReplyTemplate;
    setEditMode?: (editMode: boolean) => void;
};

const MessageReplyTemplateForm = ({ updatedTemplate, setEditMode }: Props) => {
    const formRef = useRef<HTMLDivElement>(null);

    const canSendClevertapEvents = useCanSendClevertapEvents();

    const LIMIT_CHARACTERS = 2000;
    const { t } = useTranslation();

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const textRef = useRef<MentionTextAreaImperativeHandle>(null);
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const pickerRef = useRef<HTMLDivElement>(null);
    const [templateIcon, setTemplateIcon] = useState<string>();
    const language = useLanguage();

    const onChangeTitle = (value: string) => {
        setTitle(value);
    };

    const onChangeText = (value: string) => {
        setText(value);
    };

    const limitCharReached = text.length > LIMIT_CHARACTERS;

    const disableApply = !(title && text && !limitCharReached);

    const { creatingMode, setUpdateItem, setCreatingMode } = useContext(TemplateFormContext);

    const translateDisplay = (_: string, p1: string): string => {
        return `@[${t(p1)}](${p1})`;
    };
    const placeholderToMention = (value: string): string => {
        return value.replace(/{{(\w+)}}/g, translateDisplay);
    };

    useEffect(() => {
        // We are updating an item? In that case we keep a reference to it,
        // and we scroll the form into view.
        if (updatedTemplate) {
            setTitle(updatedTemplate.title || '');
            setText(placeholderToMention(getTemplateContent(updatedTemplate).text) || '');
            setTemplateIcon(updatedTemplate.icon);
            setCreatingMode(false);
            if (formRef.current) {
                formRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'nearest',
                });
            }
        }
    }, [updatedTemplate]);

    useEffect(() => {
        // If "create" button was clicked, close the existing forms.
        if (creatingMode && setEditMode) {
            setEditMode(false);
        }
    }, [creatingMode]);

    const onCancel = () => {
        setTitle('');
        setText('');
        setTemplateIcon(undefined);
        setUpdateItem(null);
        if (setEditMode) {
            setEditMode(false);
            return;
        }
        setCreatingMode(false);
    };

    const sendSaveTemplateEvent = (isNewTemplate: boolean) => {
        if (canSendClevertapEvents) {
            PushNotificationsWrapper.getInstance().sendEvent(
                PushNotifsEvent.SAVE_MESSAGE_TEMPLATE,
                {
                    is_new_template: isNewTemplate,
                },
            );
        }
    };

    const { mutate: createMessageTemplate, isLoading: createIsLoading } =
        useCreateMessageReplyTemplate(() => sendSaveTemplateEvent(true));
    const { mutate: updateMessageTemplate, isLoading: updateIsLoading } =
        useUpdateMessageReplyTemplate(() => sendSaveTemplateEvent(false));

    const createOrUpdateTemplate = () => {
        if (updatedTemplate) {
            // We're in "update" context.
            updateMessageTemplate({
                templateId: updatedTemplate.id,
                payload: { title, content: text, icon: templateIcon },
            });
        } else {
            createMessageTemplate({
                title: title,
                content: text,
                icon: templateIcon,
            });
        }
        setTitle('');
        setText('');
        setCreatingMode(false);
        setUpdateItem(null);
        if (setEditMode) {
            setEditMode(false);
        }
    };

    const translatedPlaceholders = messageReplyTemplatePlaceholders.map(placeholder => ({
        ...placeholder,
        display: t(placeholder.display),
    }));
    const translatedPlaceholdersOptions = messageReplyTemplatePlaceholders.map(placeholder => ({
        value: placeholder.id,
        label: t(placeholder.display),
        name: placeholder.id,
    }));

    const addPlaceholder = (value: string) => {
        if (!textRef.current) {
            return;
        }
        const selectedPlaceholder = messageReplyTemplatePlaceholders.find(
            placeholder => placeholder.id == value,
        );

        if (selectedPlaceholder) {
            textRef.current.addPlaceholder(t(selectedPlaceholder.display), value);
        }
    };

    const onIconClick = () => {
        setShowPicker(true);
    };
    const handleClickOutside = (event: Event) => {
        if (
            showPicker &&
            pickerRef &&
            pickerRef.current &&
            !pickerRef.current.contains(event.target as HTMLDivElement)
        ) {
            setShowPicker(false);
        }
    };
    const handleEmojiClick = (value?: { native: string }) => {
        if (value) {
            setTemplateIcon(value.native);
            setShowPicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <MessageReplyTemplateContainer ref={formRef}>
            <CardWrapper>
                <CardContainer>
                    <TitleContainer>
                        <TextInput
                            dataTrackId="message_reply_template__text_inut"
                            value={title}
                            placeholder={t('title')}
                            onChange={onChangeTitle}
                            leftElement={
                                <>
                                    <IconButton
                                        dataTrackId="template_icon__icon_button"
                                        onClick={onIconClick}
                                        appearance="outlined"
                                        size={'medium'}
                                        icon={
                                            templateIcon ? <> {templateIcon} </> : ['fa-face-smile']
                                        }
                                    />
                                    {showPicker && (
                                        <PickerContainer ref={pickerRef}>
                                            <Picker
                                                data={data}
                                                onEmojiSelect={handleEmojiClick}
                                                i18n={EMOJI_LANGUAGE[language]}
                                                previewPosition={'none'}
                                                navPosition={'bottom'}
                                                perLine={7}
                                                theme={'light'}
                                            />
                                        </PickerContainer>
                                    )}
                                </>
                            }
                            leftElementType={LeftElementType.Clickable}
                        />
                    </TitleContainer>
                    <PlaceholderButtonContainer>
                        <ButtonWithMenu
                            dataTrackId="messaging-template-add-placeholder"
                            appearance="text"
                            variant="primary"
                            icon={[FontAwesomeIconsPartooUsed.faAt]}
                            text={t('add_a_placeholder')}
                            options={translatedPlaceholdersOptions}
                            onMenuClick={(value: string) => addPlaceholder(value)}
                            cssMenu={css`
                                top: 30px;
                                right: -2px;
                                left: unset;
                            `}
                        />
                    </PlaceholderButtonContainer>
                    <TextContainer>
                        <TextAreaPlaceholder>{t('your_reply_template')}</TextAreaPlaceholder>

                        <MentionTextArea
                            value={text}
                            choices={translatedPlaceholders}
                            onChange={onChangeText}
                            hasError={limitCharReached}
                            ref={textRef}
                            explanation={limitCharReached ? t('max_nb_char_reached') : undefined}
                        />
                    </TextContainer>

                    <ButtonsContainer>
                        <Button
                            variant="secondary"
                            shape="cube"
                            appearance="outlined"
                            onClick={onCancel}
                            dataTrackId="messaging-template-create-cancel"
                        >
                            {t('cancel')}
                        </Button>
                        <SaveButton
                            dataTrackId="messaging-template-create"
                            onClick={createOrUpdateTemplate}
                            variant="primary"
                            shape="cube"
                            disabled={disableApply && !(createIsLoading || updateIsLoading)}
                        >
                            {t('save')}
                        </SaveButton>
                    </ButtonsContainer>
                </CardContainer>
            </CardWrapper>
        </MessageReplyTemplateContainer>
    );
};

export default MessageReplyTemplateForm;
