import { Icon, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useLanguage } from 'app/common/services/getLang';
import { JimIcon } from 'app/pages/settings/Chatbot/assets/jim-icon';
import { ConversationMessage, MESSAGE_CONTENT_TYPE } from 'app/states/messaging/dataTypes';

import { MetadataMessageContainer } from './MetadataMessage.styled';

import { formatMessageDate, formatMessageTime } from '../../services/dateAndTimeConverter';

type MetadataMessageProps = {
    message: ConversationMessage;
    consumerName: string;
};

function metadataMessageAttributes(message: ConversationMessage): {
    icon: React.ReactNode;
    translationKey: string;
    params: any;
} {
    const assignationIcon = <Icon icon={['fa-user-circle']} color="secondary" />;

    switch (message.content_type) {
        case MESSAGE_CONTENT_TYPE.SOLVING:
            return {
                icon: <i className={'conv-status-msg-icon far fa-circle-check'}></i>,
                translationKey: 'message_tracker_action_closed',
                params: {},
            };
        case MESSAGE_CONTENT_TYPE.REOPENING:
            return {
                icon: <Icon icon={['fa-inbox-out']} color="secondary" />,
                translationKey: 'message_tracker_action_reopened',
                params: {},
            };
        case MESSAGE_CONTENT_TYPE.ASSIGNING:
            if (message.sender_label === message.content) {
                return {
                    icon: assignationIcon,
                    translationKey: 'messaging_assign_metadata_message_assign_to_self',
                    params: {},
                };
            } else {
                return {
                    icon: assignationIcon,
                    translationKey: 'messaging_assign_metada_message_assign_activity',
                    params: {
                        assignee_name: message.content,
                    },
                };
            }
        case MESSAGE_CONTENT_TYPE.UNASSIGNING:
            return {
                icon: assignationIcon,
                translationKey: 'messaging_assign_metada_message_unassign_activity',
                params: {},
            };
        case MESSAGE_CONTENT_TYPE.SPAM:
            return {
                icon: <Icon icon={['fa-bars-filter']} color="secondary" />,
                translationKey: 'messaging_spam_metadata_message',
                params: {},
            };
        case MESSAGE_CONTENT_TYPE.HANDOVER:
            return {
                icon: <JimIcon size={12} />,
                translationKey: 'messaging_handover_metadata_message',
                params: {},
            };
        default:
            return { icon: null, translationKey: '', params: {} };
    }
}

const MetadataMessage = ({ message, consumerName }: MetadataMessageProps) => {
    const lang = useLanguage();
    const { t } = useTranslation();

    const name = message.user_id ? message.sender_label : consumerName;

    const { icon, translationKey, params } = metadataMessageAttributes(message);

    const translationParameters = {
        ...params,
        user_name: name,
        date: formatMessageDate(message.date, lang),
        hours: formatMessageTime(message.date, lang),
    };

    return (
        <MetadataMessageContainer>
            {icon}
            <Text as="span" color={'secondary'} variant="bodySSemibold">
                {t(translationKey, translationParameters)}
            </Text>
        </MetadataMessageContainer>
    );
};

export default MetadataMessage;
