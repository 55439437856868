import { AppIcon, AppIconsValuesEnum } from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useReviewsStats from 'app/common/hooks/queries/reviews/useReviewsStats';
import { STATIC_APP_ASSETS_URL } from 'app/config';
import { useIsLoadinReviewsStats } from 'app/pages/Homepage/hooks/stats/statsItems/reviews/useIsLoadingReviewsStats';
import { DefaultStatComponent } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent';
import { SmallStatProps } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/DefaultStatComponent';
import { ANALYTICS_REVIEW_PATH } from 'app/routing/routeIds';

import { StatReviewsChip } from './StatReviewsChip';

export const StatReviews = (props: SmallStatProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data } = useReviewsStats();
    const isLoading = useIsLoadinReviewsStats();

    const average = data?.average ?? 0;

    const noStats = average === 0 || isNaN(average);

    return (
        <DefaultStatComponent
            {...props}
            productTitle={t('homepage_stats_reviews_title')}
            statTitle={`${average.toFixed(1)}/5`}
            statSubtitle={t('homepage_stats_reviews_subtitle')}
            description={t('homepage_stats_reviews_description')}
            productIcon={
                <AppIcon
                    dataTrackId="stat_review__app_icon"
                    name={AppIconsValuesEnum.GetChosen}
                    width="24"
                    height="24"
                />
            }
            isLoading={isLoading}
            onIconClick={() => navigate(ANALYTICS_REVIEW_PATH)}
            bannerUrl={`${STATIC_APP_ASSETS_URL}/homepage/ReputationBanner.svg`}
            displayEmptyPlaceholder={!isLoading && noStats}
            statChip={<StatReviewsChip />}
            dataTrack="click_homepage_stats_reviews"
        />
    );
};
