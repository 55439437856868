import { ReactNode } from 'react';

import { useVisibilityContext } from './DesktopHeader.context';
import { DesktopHeaderContainer } from './DesktopHeader.styled';

interface DesktopHeaderProps {
    header: ReactNode;
}

export const DesktopHeader = ({ header }: DesktopHeaderProps) => {
    const { isHeaderVisible } = useVisibilityContext();
    return <DesktopHeaderContainer isVisible={isHeaderVisible}>{header}</DesktopHeaderContainer>;
};
