import { toast } from '@partoohub/ui';
import { uniqWith } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { MessagingPartner, MessagingPartnerEnum } from 'app/states/messaging';

import { ImageFile, ImageInputButton } from './ImageInput';
import { IconButtonWithLabelWrapper } from './MessageForm.styled';

// Defines the maximum images the user is allowed to upload per message.
const MAX_IMAGES_PER_MESSAGE = 5;
// Defines the maximum file size of each image user has uploaded.
const MAX_IMAGE_FILE_SIZE = 5e6;

interface ImagePickerProps {
    images: Array<ImageFile>;
    setImages: (images: Array<ImageFile>) => void;
    messagingPartner: MessagingPartner;
}

const ImagePicker = ({ images, setImages, messagingPartner }: ImagePickerProps) => {
    const { t } = useTranslation();

    if (messagingPartner == MessagingPartnerEnum.SMS) return null;

    const text = t('messaging_conversation_pictures_button_label');
    const onPickImages = (files: Array<ImageFile>) => {
        const nextImages = [...images, ...files];
        // validate the image array.
        // ensure only unique items are taken:
        const uniqueImages = uniqWith(
            nextImages,
            (a, b) => a.lastModified === b.lastModified && a.name === b.name,
        );

        const validImages = uniqueImages.filter(img => img.size <= MAX_IMAGE_FILE_SIZE);

        if (validImages.length !== uniqueImages.length) {
            // Some files were skipped because they failed validation.
            // Show a toast to signify that.
            toast.error(null, t('messaging_error_image_maxsize_reached'));
        }

        if (validImages.length > MAX_IMAGES_PER_MESSAGE) {
            // Show error on file count.
            const params = {
                MAX_IMAGES_PER_MESSAGE: MAX_IMAGES_PER_MESSAGE.toString(),
            };
            toast.error(null, t('messaging_error_image_maxnb_reached', params));
        }
        setImages(validImages.slice(0, 5));
    };

    return (
        <TooltipWrapper text={text} position="top">
            <IconButtonWithLabelWrapper>
                <ImageInputButton onPick={onPickImages} />
            </IconButtonWithLabelWrapper>
        </TooltipWrapper>
    );
};

export default ImagePicker;
