import { toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { BUSINESS } from 'app/common/data/queryKeysConstants';
import { GMB_PHOTOS_UPDATE_EVENT } from 'app/common/data/websocket';
import useWebsocket from 'app/common/hooks/useWebsocket';
import queryClient from 'app/states/queryClient';

type FetchGmbPhotosNotificationData = {
    business_id: string;
    status: 'success' | 'error';
};

export const IMPORT_IN_PROGRESS_TOAST_ID = 'IMPORT_IN_PROGRESS_TOAST';

export default function useGmbPhotosUpdate(cleanFetchStatus: () => void) {
    const businessId = useGetBusinessId();
    const { t } = useTranslation();

    return useWebsocket(GMB_PHOTOS_UPDATE_EVENT, (data: FetchGmbPhotosNotificationData) => {
        if (data?.business_id === businessId) {
            toast.dismiss(IMPORT_IN_PROGRESS_TOAST_ID);

            if (data?.status === 'success') {
                queryClient.invalidateQueries(invalidatesAny(BUSINESS));
                toast.success(null, t('business_edit_photo_import_done'));
            } else {
                toast.error(null, t('business_edit_photo_import_failed'));
            }

            cleanFetchStatus();
        }
    });
}
