import useMe from 'app/common/hooks/queries/useMeUncamel';
import dataLayer from 'app/common/utils/dataLayer';
import {
    MetaReloginContextProps,
    useMetaRelogin,
} from 'app/pages/visibility/subComponents/MetaRelogin/MetaReloginContext';

export const useSendTrackingData = () => {
    const { data: me } = useMe();
    const { oauthAccountId } = useMetaRelogin() as MetaReloginContextProps;

    return (eventName: string) => {
        dataLayer.pushDict(eventName, {
            user_id: me?.id,
            shadow_user_id: me?.shadow_user_id,
            org_id: me?.org_id,
            oauthAccountId: oauthAccountId,
        });
    };
};
