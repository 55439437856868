import React, { ReactNode } from 'react';

import {
    BusinessEditV2ContentDescriptionContainer,
    Title,
} from './BusinessEditV2ContentDescription.styled';

type Props = {
    title: string;
    children?: ReactNode;
};

const BusinessEditV2ContentDescription = ({ title, children }: Props) => (
    <BusinessEditV2ContentDescriptionContainer>
        <Title>{title}</Title>
        {children}
    </BusinessEditV2ContentDescriptionContainer>
);

export default BusinessEditV2ContentDescription;
