import { Item } from 'app/pages/Homepage/utils/typing';

import { useAllShortcutsItems } from './useAllShortcutsItems';

const MAX_SHORTCUTS_ITEMS = 4;

export const useShortcutsItems = (): Array<Item> => {
    const allShortcutsItems = useAllShortcutsItems();
    return allShortcutsItems
        .filter(item => item.isAvailable)
        .sort((a, b) => a.order - b.order)
        .slice(0, MAX_SHORTCUTS_ITEMS);
};
