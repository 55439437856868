import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { STATIC_APP_ASSETS_URL } from 'app/config';

import {
    PlaceholderPicto,
    PlaceholderText,
    PlaceholderTitle,
    PlaceholderWrapper,
} from './WhatsappAccountListPlaceholder.styled';

const WhatsappAccountListPlaceholder: FC = () => {
    const { t } = useTranslation();

    return (
        <PlaceholderWrapper>
            <PlaceholderPicto
                src={`${STATIC_APP_ASSETS_URL}/whatsapp/no_accounts_placeholder_picto.svg`}
            />
            <PlaceholderTitle variant="heading3">
                {t('whatsapp_settings_no_accounts_placeholder_title')}
            </PlaceholderTitle>
            <PlaceholderText variant="bodyMRegular" color="secondary">
                {t('whatsapp_settings_no_accounts_placeholder_text')}
            </PlaceholderText>
        </PlaceholderWrapper>
    );
};

export default WhatsappAccountListPlaceholder;
