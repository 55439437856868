import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const TextContainer = styled.h2`
    font-size: 18px;
    color: ${HEX_COLORS.blackberry};
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 0 0 24px 0;

    p {
        margin: 0;
        margin-left: 4px;
    }
`;
