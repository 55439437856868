import { useState } from 'react';

import { Button, Text, TextInput, toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import dataLayer from 'app/common/utils/dataLayer';
import { useCreateCustomFieldSection } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useCreateCustomFieldsSection';

import {
    CustomFieldsCreateSectionModalButtons,
    CustomFieldsCreateSectiongModalContainer,
} from './CustomFieldsCreateSectionModal.styled';

type Props = {
    closeModal: () => void;
    onSuccess?: (section_id: number) => void;
    calledFromEditModal?: boolean;
};

export const CustomFieldsCreateSectionContent = ({
    closeModal,
    onSuccess,
    calledFromEditModal = false,
}: Props) => {
    const { t } = useTranslation();
    const createCustomFieldSection = useCreateCustomFieldSection(response => {
        toast.success(
            t('custom_fields_sections_toast_create_successful_description'),
            t('custom_fields_sections_toast_create_successful', { name: newSectionName }),
        );
        dataLayer.pushDict('click_create_custom_field_section', {
            called_from: calledFromEditModal
                ? 'custom_fields_edit_page'
                : 'custom_fields_listing_page',
        });
        if (onSuccess) {
            onSuccess(response.section_id);
        }
        closeModal();
    });
    const [newSectionName, setNewSectionName] = useState<string>('');

    const getButtonStatus = () => {
        if (createCustomFieldSection.isLoading) {
            return 'secondary';
        }
        if (createCustomFieldSection.error) {
            return 'danger';
        }
        return 'primary';
    };

    // @ts-ignore
    const errorMessage = t(createCustomFieldSection.error?.response?.data?.errors?.json?.name);

    return (
        <CustomFieldsCreateSectiongModalContainer>
            <Text variant="heading4">🚀 {t('custom_fields_section_create_title')}</Text>

            <Text color="secondary">{t('custom_fields_section_create_description')}</Text>

            <TextInput
                dataTrackId="custom_field_create_section_modal__section_name_input"
                label={t('custom_fields_section_name_label')}
                onChange={value => setNewSectionName(value)}
                error={!!createCustomFieldSection.error}
                notice={errorMessage}
            />

            <CustomFieldsCreateSectionModalButtons>
                <Button
                    appearance="outlined"
                    shape="rounded"
                    variant="secondary"
                    onClick={closeModal}
                    dataTrackId="custom_field_create_section_modal__cancel"
                >
                    {t('cancel')}
                </Button>
                <Button
                    shape="rounded"
                    variant={getButtonStatus()}
                    onClick={() => createCustomFieldSection.mutate({ name: newSectionName })}
                    disabled={!newSectionName}
                    dataTrackId="custom_field_create_section_modal__save"
                >
                    {t('create')}
                </Button>
            </CustomFieldsCreateSectionModalButtons>
        </CustomFieldsCreateSectiongModalContainer>
    );
};
