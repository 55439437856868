import { Filters } from '@partoohub/ui';
import { useController, useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { getCustomLinkOptions } from '../PostConstants';

export const ControlledGoogleCustomLink = () => {
    const { t } = useTranslation();
    const { resetField, control, setValue } = useFormContext();

    const options = getCustomLinkOptions(t);

    const {
        field,
        fieldState: { error },
    } = useController({
        name: 'google_custom_link',
        control,
    });

    const onChangeWithReset = (record: Record<string, boolean>): void => {
        const recordValue = Object.keys(record).find(key => !!record[key]);
        resetField('custom_link', {
            keepDirty: false,
        });
        if (!record || recordValue === 'call') {
            resetField('content_link', {
                keepDirty: false,
            });
        } else {
            setValue('content_link', 'use_business_url');
        }
        field.onChange(recordValue);
    };

    const sections = [{ options }];

    return (
        <Filters
            {...field}
            dataTrackId="posts_add_button_step"
            menuListArray={sections}
            selectedItems={field.value ? { [field.value]: true } : {}}
            onChange={onChangeWithReset}
            maxHeight={300}
            placeholder={t('posts_add_button_step')}
            disableReset
            position="top"
            error={!!error}
            notice={error}
        />
    );
};
