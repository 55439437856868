import { PublisherStateStats } from 'app/api/types/publisher_state';
import sumValuesByKey from 'app/common/services/objects/sumValuesByKey';
import { defaultPublisherStateStats } from 'app/presence/management/constants/defaultPublisherStateStats';
import { usePublishersStates } from 'app/presence/management/hooks/usePublishersStates';

export const usePublishersStateStatsTotal = () => {
    const { data, isLoading } = usePublishersStates();

    const publishersStateStatsTotal = data
        ? Object.values(data.publisher_state_stats).reduce(
              (seq, publisherStats) =>
                  sumValuesByKey(seq, publisherStats) as PublisherStateStats,

              defaultPublisherStateStats,
          )
        : defaultPublisherStateStats;

    return { publishersStateStatsTotal, isLoading };
};
