import { Text } from '@partoohub/ui';

import { StyledFeedbackResultField } from './FeedbackResultField.styled';

interface Props {
    question: string;
    content: string | null;
}

export const FeedbackResultTextAreaField = ({ question, content }: Props) => {
    return (
        <StyledFeedbackResultField columns={2}>
            <Text variant="bodyMSemibold">{question}</Text>
            <Text>{content}</Text>
        </StyledFeedbackResultField>
    );
};
