import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import {
    LoadingBusinessCardContainer,
    LoadingBusinessCardInfoContainer,
} from './LoadingBusinessCard.styled';

export const LoadingBusinessCard = () => (
    <LoadingBusinessCardContainer>
        <LoadingRectangle recRadius="4px" recHeight="64px" recWidth="64px" recMargin="0 16px 0 0" />
        <LoadingBusinessCardInfoContainer>
            <LoadingRectangle recHeight="10px" recWidth="70%" />
            <LoadingRectangle recHeight="8px" recWidth="40%" />
            <LoadingRectangle recHeight="8px" recWidth="40%" />
            <LoadingRectangle recHeight="10px" recWidth="60%" />
        </LoadingBusinessCardInfoContainer>
    </LoadingBusinessCardContainer>
);
