import { COLORS_FLOW } from 'app/styles/constants/colors_flow';

export const getRatingColor = (rating: number): string => {
    const ratingThreshold = Math.floor(rating);
    const colorMapping: Record<number | 'default', string> = {
        5: COLORS_FLOW.shaded.success.initial,
        4: COLORS_FLOW.shaded.success.initial,
        3: COLORS_FLOW.shaded.warning.initial,
        default: COLORS_FLOW.shaded.danger.initial,
    };

    return colorMapping[ratingThreshold] || colorMapping.default;
};
