import React, { useContext, useEffect } from 'react';

import { Button, toast } from '@partoohub/ui';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import useDebounce from 'app/common/hooks/useDebounce';
import ConversationStarterItemFormMessage from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/ConversationStarterItemFormMessage';
import ConversationStarterItemFormTitle from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/ConversationStarterItemFormTitle';
import { ConversationStartersContext } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/contexts/ConversationStartersContext';
import {
    ConversationStarterItem,
    ConversationStarterItemPostback,
    ConversationStarterItemTypeEnum,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';

import {
    ConversationStarterItemFormContainer,
    ConversationStarterItemFormFooter,
} from './ConversationStarterItemForm.styled';

type Props = {
    closeForm: () => void;
    onFormSubmit: (data: ConversationStarterItemPostback) => void;
    conversationStarterItem?: ConversationStarterItem;
};

const ConversationStarterItemForm = ({
    closeForm,
    onFormSubmit,
    conversationStarterItem,
}: Props) => {
    const { t } = useTranslation();
    const { setActiveConversationStarterItem } = useContext(ConversationStartersContext);

    const setFormValue = () => ({
        title: conversationStarterItem?.title ?? '',
        reply_message:
            conversationStarterItem?.type === ConversationStarterItemTypeEnum.postback
                ? conversationStarterItem.reply_message
                : '',
    });

    const { control, handleSubmit, reset, formState, watch } = useForm({
        defaultValues: setFormValue(),
    });

    const debouncedTitle = useDebounce(watch('title'));
    const debouncedMessage = useDebounce(watch('reply_message'));

    useEffect(() => {
        setActiveConversationStarterItem({
            title: debouncedTitle,
            reply_message: debouncedMessage,
        });
    }, [debouncedTitle, debouncedMessage]);

    const handleOnCancel = () => {
        reset();
        closeForm();
    };

    const handleOnSubmit = (data: ConversationStarterItemPostback) => {
        data.type = ConversationStarterItemTypeEnum.postback;
        onFormSubmit(data);
        toast.success(
            t('conv_starter_item_snackbar_changes_saved_text'),
            t('conv_starter_item_snackbar_changes_saved_title'),
        );
    };

    return (
        <ConversationStarterItemFormContainer>
            <ConversationStarterItemFormTitle control={control} formState={formState} />
            <ConversationStarterItemFormMessage control={control} formState={formState} />
            <ConversationStarterItemFormFooter>
                <Button
                    onClick={handleOnCancel}
                    dataTrackId={'conversation_starter_item_creation_cancel'}
                    appearance="outlined"
                    variant={'secondary'}
                >
                    {t('cancel')}
                </Button>
                <Button
                    onClick={handleSubmit(data => handleOnSubmit(data))}
                    dataTrackId={'conversation_starter_item_create'}
                    variant={'primary'}
                >
                    {t('save')}
                </Button>
            </ConversationStarterItemFormFooter>
        </ConversationStarterItemFormContainer>
    );
};

export default ConversationStarterItemForm;
