import { Table } from '@partoohub/ui';

import { StyledCell, StyledTable } from './Rankings.styled';

export const ReplyMeansRanking = () => {
    return (
        <StyledTable
            columns={[
                {
                    label: '#',
                    width: '300px',
                },
                {
                    label: 'Établissement',
                    width: '3000px',
                },
                {
                    label: 'Manuel',
                    width: '1000px',
                },
                {
                    label: 'Modèles',
                    width: '1000px',
                },
                {
                    label: 'Auto',
                    width: '1000px',
                },
                {
                    label: 'IA',
                    width: '1000px',
                },
            ]}
        >
            <Table.Body>
                <Table.Row dataTrackId="" id="6">
                    <StyledCell>-</StyledCell>
                    <StyledCell>Test org</StyledCell>
                    <StyledCell>10%</StyledCell>
                    <StyledCell>30%</StyledCell>
                    <StyledCell>-</StyledCell>
                    <StyledCell>60%</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="7">
                    <StyledCell>-</StyledCell>
                    <StyledCell>Test org</StyledCell>
                    <StyledCell>10%</StyledCell>
                    <StyledCell>30%</StyledCell>
                    <StyledCell>-</StyledCell>
                    <StyledCell>60%</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="8">
                    <StyledCell>-</StyledCell>
                    <StyledCell>Test org</StyledCell>
                    <StyledCell>10%</StyledCell>
                    <StyledCell>30%</StyledCell>
                    <StyledCell>-</StyledCell>
                    <StyledCell>60%</StyledCell>
                </Table.Row>
            </Table.Body>
        </StyledTable>
    );
};
