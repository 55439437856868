import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Icon, IconPrefix, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useInfiniteQuery, useMutation } from 'react-query';

import { useSelector } from 'react-redux';

import { InteractionTagType, ReviewTagMatch } from 'app/api/types/interactionTag';
import { REVIEW_PERMISSION_ASSIGN_TAG } from 'app/api/types/review';
import api from 'app/api/v2/api_calls/camel';
import ReviewTagAPI from 'app/api/v2/api_calls/reviewTagApiCalls';
import { displayModalSelector } from 'app/common/components/ConfirmModal/reducers/confirm';
import { REVIEW_LIST, REVIEW_TAGS } from 'app/common/data/queryKeysConstants';
import { ADMIN, ORG_ADMIN } from 'app/common/data/roles';
import { PillBody as BasePillBody } from 'app/common/designSystem/components/molecules/AsyncSubMenuMultipleSelect/components/SearchBox.styled';
import {
    InputClickable,
    InputIcon,
    InputPlaceholder,
    InputWrapper,
    ItemsContainer,
    Loader,
    MenuItemContainer,
    MenuItemIcon,
    MenuItemsContainer,
} from 'app/common/designSystem/components/molecules/ButtonWithSearchMenuFilterSelection/ButtonWithSearchMenuFilterSelection.styled';

import useDebounce from 'app/common/hooks/useDebounce';
import { canSendClevertapEventsSelector, meRoleSelector } from 'app/common/reducers/me';
import { PushNotifsEvent } from 'app/common/services/pushNotifications/events';
import { PushNotificationsWrapper } from 'app/common/services/pushNotifications/pushNotificationsWrapper';
import dataLayer from 'app/common/utils/dataLayer';
import { useUpdateReviewQueryData } from 'app/reviewManagement/reviewList/hooks/queryData/useUpdateReviewQueryData';
import { useHasReviewPermission } from 'app/reviewManagement/reviewList/hooks/useReviewPermissions';
import TagEditModal from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/TagSection/TagEditModal';
import queryClient from 'app/states/queryClient';
import { confirmModalSelector, meSelector } from 'app/states/reducers';
import { WITHOUT_TAG } from 'app/states/reviews';
import { reviewFiltersSelector, tagFilterSelector } from 'app/states/reviews/filters';
import { truncate } from 'app/utils';

import {
    CreateMenuItemLabel,
    CreateTagMenuItemWrapper,
    IconContainer,
    MenuItemLabel,
    MenuWrapper,
    TagMenuItemWrapper,
    TagsInputContainer,
} from './AddTagButton.styled';
import TagList from './TagList';
import { TagPillBodyText } from './TagList.styled';
import {
    ButtonWithMenuContainer,
    ButtonWithMenuWrapper,
    ColorContainer,
    CreateTagPillBody,
} from './TagSection.styled';

import { ReviewCardContext, ReviewCardContextType } from '../ReviewCard';

type Props = {
    setIsOpen: (isOpen: boolean) => void;
    isOpen: boolean;
};

export default function AddTagButton({ setIsOpen, isOpen }: Props) {
    const { t } = useTranslation();
    const { review } = useContext<ReviewCardContextType>(ReviewCardContext);
    const tagFilter = tagFilterSelector(useSelector(reviewFiltersSelector));
    const [currentSearch, setCurrentSearch] = useState('');
    const debouncedSearch = useDebounce(currentSearch);
    const userIsTyping = currentSearch !== debouncedSearch;
    const [editTag, setEditTag] = useState<InteractionTagType | false>(false);
    const wrapperRef = useRef<HTMLInputElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const userRole = meRoleSelector(useSelector(meSelector));
    const isAllowedToCreateTag = [ORG_ADMIN, ADMIN].includes(userRole ?? '');
    const isDeleteModalActive = displayModalSelector(
        useSelector(confirmModalSelector),
        'delete_tag_modal',
    );
    const hasAssignTagPermission = useHasReviewPermission(REVIEW_PERMISSION_ASSIGN_TAG);
    const canSendClevertapEvents = useSelector(canSendClevertapEventsSelector);
    const notifyAssignTag = useCallback((): void => {
        if (canSendClevertapEvents) {
            PushNotificationsWrapper.getInstance().sendEvent(PushNotifsEvent.TAG_REVIEW, {});
        }
    }, [review, canSendClevertapEvents]);
    const updateReviewQueryData = useUpdateReviewQueryData();

    const { data, fetchNextPage, hasNextPage, isFetched } = useInfiniteQuery(
        [REVIEW_TAGS, debouncedSearch],
        ({ pageParam = 1 }) => api.interactionTag.getInteractionTags(debouncedSearch, pageParam),
        {
            getNextPageParam: fetchedData =>
                fetchedData.maxPage > fetchedData.page ? fetchedData.page + 1 : undefined,
        },
    );
    const options =
        data?.pages.reduce((prevPageTags, { tags }) => [...prevPageTags, ...tags], []) ?? [];
    const isFetchingTags = userIsTyping || !isFetched;

    const showCreateTag =
        !isFetchingTags &&
        (!debouncedSearch ||
            !options.some(
                ({ label: tagLabel }: InteractionTagType) =>
                    tagLabel.toLowerCase() === debouncedSearch.toLowerCase(),
            ));

    const onSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const search = event.target.value;
        // Removing forbidden characters
        const value = search.replace(/,/g, '');
        setCurrentSearch(value);
    }, []);

    const loadMore = () => fetchNextPage();

    const openMenu = useCallback(() => {
        setIsOpen(true);
    }, []);

    const closeMenu = useCallback(
        e => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setIsOpen(false);
                setEditTag(false);
                setCurrentSearch('');

                const hasTagOnWithoutTagFilter =
                    tagFilter.length > 0 &&
                    tagFilter.includes(WITHOUT_TAG) &&
                    review.tags.length > 0;
                const noMoreTagIncludedInTagFilter =
                    tagFilter.length > 0 &&
                    !review.tags.some(tag => tagFilter.includes(tag.id)) &&
                    !tagFilter.includes(WITHOUT_TAG);

                if (hasTagOnWithoutTagFilter || noMoreTagIncludedInTagFilter) {
                    updateReviewQueryData(review, { hideReview: true });
                }
            }
        },
        [review.tags],
    );

    const handleKey = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && showCreateTag && debouncedSearch && isAllowedToCreateTag) {
            createTag();
        }
    };

    const handleCreateTagError = (errorType: string | undefined | null) => {
        switch (errorType) {
            case 'cannot_create_more_tags_error':
                toast.warning(t('review_tag_toast_creation_limit_message'), t('error'));
                break;
            case 'label_already_exists_error':
            default:
                toast.error(t('creation_error'), t('error'));
        }
    };

    // Create tag mutation, assigning takes place at the same time
    const { mutate: createTagMutation, isLoading: isCreatingTag } = useMutation(
        () => {
            return api.interactionTag.create({ label: debouncedSearch });
        },
        {
            onSuccess: ({ id, ...rest }: InteractionTagType) => {
                const tags = [...review.tags, { id, ...rest }];
                updateReviewQueryData({
                    ...review,
                    tags,
                });
                return ReviewTagAPI.assign([{ interaction_tag_id: id, review_id: review.id }]);
            },
            onError: (e: any) => {
                const errorType =
                    e.response?.status === 400 ? (e.response?.data?.errors?.json?.type ?? '') : '';
                handleCreateTagError(errorType);
            },
            onSettled: () => {
                queryClient.invalidateQueries([REVIEW_TAGS]);
                queryClient.invalidateQueries({
                    queryKey: [REVIEW_LIST],
                    refetchActive: false,
                });
                setCurrentSearch('');
            },
        },
    );

    const createTag = () => {
        if (!isCreatingTag) createTagMutation();
    };

    const onEditTagClick = useCallback((e: React.MouseEvent, tag: InteractionTagType) => {
        // prevents assigning/unassigning tags
        e.stopPropagation();
        setEditTag(tag);
    }, []);

    const onEditTagModalHide = useCallback(() => {
        setEditTag(false);
    }, []);

    // Adding event handler to hide the modal when users click outside
    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', closeMenu);
            return () => {
                document.removeEventListener('mousedown', closeMenu);
            };
        }
    }, [isOpen, review.tags]);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleKey);
            return () => {
                document.removeEventListener('keydown', handleKey);
            };
        }
    }, [showCreateTag, currentSearch, isAllowedToCreateTag, isFetchingTags, isDeleteModalActive]);

    // Preventing menu from being closed when delete modal is shown
    // Cleanup function will re-activate the event handler when the delete modal is closed
    useEffect(() => {
        if (isDeleteModalActive && isOpen) {
            document.removeEventListener('mousedown', closeMenu);
            return () => {
                document.addEventListener('mousedown', closeMenu);
            };
        }
    }, [isDeleteModalActive, isOpen, review.tags]);

    // Assign tag mutation
    const { mutate: assignTagToReview, isLoading: isAssigning } = useMutation(
        (tagId: number) => {
            // GTM
            dataLayer.pushDict('assign_review_tag');

            const body: ReviewTagMatch[] = [{ review_id: review.id, interaction_tag_id: tagId }];
            return ReviewTagAPI.assign(body);
        },
        {
            onSuccess: (_, tagId) => {
                notifyAssignTag();
                const newAddedTag = options.find(({ id }: InteractionTagType) => id === tagId);

                if (newAddedTag) {
                    const tags = [...review.tags, newAddedTag];
                    updateReviewQueryData({
                        ...review,
                        tags,
                    });
                }
            },
        },
    );

    // Unassign tag mutation
    const { mutate: unassignTagFromReview, isLoading: isUnassigning } = useMutation(
        (tagId: number) => {
            const body: ReviewTagMatch[] = [{ review_id: review.id, interaction_tag_id: tagId }];
            return ReviewTagAPI.unassign(body);
        },
        {
            onSuccess: (_, tagId) => {
                const tags = review.tags.filter(({ id }: InteractionTagType) => id !== tagId);
                updateReviewQueryData({
                    ...review,
                    tags,
                });
            },
        },
    );

    const onOptionClick = useCallback(
        (isAssigned: boolean, tagId: number): (() => void) => {
            if (isUnassigning || isAssigning || !hasAssignTagPermission) {
                return () => undefined;
            }

            return () => (isAssigned ? unassignTagFromReview(tagId) : assignTagToReview(tagId));
        },
        [isUnassigning, isAssigning, hasAssignTagPermission],
    );

    const renderTagInput = () => {
        return (
            <InputWrapper>
                <InputClickable onClick={() => undefined}>
                    <InputPlaceholder>
                        {t(
                            isAllowedToCreateTag
                                ? 'review_select_and_create_tag'
                                : 'review_select_tag',
                        )}
                    </InputPlaceholder>
                    <ItemsContainer>
                        <TagsInputContainer>
                            <TagList />
                            <input
                                className={'tagSearch'}
                                type="text"
                                onChange={onSearchChange}
                                value={currentSearch}
                                maxLength={30}
                                autoFocus
                            />
                        </TagsInputContainer>
                    </ItemsContainer>
                    <InputIcon type="button">
                        <i className={'fas fa-search'} />
                    </InputIcon>
                </InputClickable>
            </InputWrapper>
        );
    };

    const renderCreateTagItem = () => {
        if (!(showCreateTag && currentSearch && isAllowedToCreateTag)) {
            return null;
        }

        return (
            <CreateTagMenuItemWrapper
                type="button"
                className={`multiple_select__menu__item ${isFetchingTags && 'disabled'}`}
                onClick={createTag}
            >
                <MenuItemContainer>
                    <CreateMenuItemLabel>{t('review_create_new_tag')}</CreateMenuItemLabel>
                    <CreateTagPillBody>
                        <TagPillBodyText>{currentSearch}</TagPillBodyText>
                    </CreateTagPillBody>
                </MenuItemContainer>
                <IconContainer>
                    <i className="fa-solid fa-arrow-turn-down-left" />
                </IconContainer>
            </CreateTagMenuItemWrapper>
        );
    };

    const renderMenuTagItem = (tag: InteractionTagType) => {
        const { id, color, label } = tag;
        const isAssigned = review.tags.some(({ id: tagId }) => tagId === id);
        return (
            <TagMenuItemWrapper
                type="button"
                className={`multiple_select__menu__item ${isAssigned && 'selected'}`}
                key={id}
                onClick={onOptionClick(isAssigned, id)}
            >
                <MenuItemContainer>
                    <MenuItemIcon>
                        <ColorContainer color={color}>
                            {isAssigned && <i className="fa-sharp fa-solid fa-check" />}
                        </ColorContainer>
                    </MenuItemIcon>
                    <MenuItemLabel>{truncate(label, 20)}</MenuItemLabel>
                </MenuItemContainer>
                {isAllowedToCreateTag && (
                    <Icon
                        icon={['fa-pen-clip', IconPrefix.SOLID]}
                        onClick={e => onEditTagClick(e, tag)}
                    />
                )}
            </TagMenuItemWrapper>
        );
    };

    const renderMenuTagItemsList = () => {
        return (
            <MenuItemsContainer ref={wrapperRef}>
                <InfiniteScroll
                    loadMore={loadMore}
                    hasMore={hasNextPage}
                    initialLoad={false}
                    getScrollParent={() => wrapperRef.current}
                    useWindow={false}
                    threshold={100}
                >
                    {options.map((tag: InteractionTagType) => renderMenuTagItem(tag))}
                    {isFetchingTags && (
                        <Loader>
                            <i className="fa-solid fa-circle-notch fa-spin" />
                        </Loader>
                    )}
                </InfiniteScroll>
            </MenuItemsContainer>
        );
    };

    return (
        <ButtonWithMenuWrapper
            data-track="reviews__add_tag_button"
            data-intercom-target="reviews__add_tag_button"
        >
            <ButtonWithMenuContainer>
                <BasePillBody onClick={openMenu}>
                    <TagPillBodyText>{t('review_add_tag_button')}</TagPillBodyText>
                </BasePillBody>
                <div ref={menuRef}>
                    {isOpen && editTag === false && (
                        <MenuWrapper hasHeader position="right">
                            <>
                                {renderTagInput()}
                                {renderCreateTagItem()}
                                {renderMenuTagItemsList()}
                            </>
                        </MenuWrapper>
                    )}
                    {editTag !== false && (
                        <TagEditModal tag={editTag} onHide={onEditTagModalHide} />
                    )}
                </div>
            </ButtonWithMenuContainer>
        </ButtonWithMenuWrapper>
    );
}
