import { useState } from 'react';

import { FormattedReplyTemplate } from 'app/api/types/reviewReplyTemplates';

import ReplyTemplateCard from './ReplyTemplateCard';
import { ReplyTemplateForm } from './replyTemplateForm/ReplyTemplateForm';

type Props = {
    template: FormattedReplyTemplate;
    onClickDuplicate: () => void;
};

export function EditableReplyTemplateCard({ template, onClickDuplicate }: Props) {
    const [isEdit, setIsEdit] = useState<boolean>(false);

    if (isEdit) {
        return <ReplyTemplateForm initialValues={template} onClose={() => setIsEdit(false)} />;
    }

    return (
        <ReplyTemplateCard
            template={template}
            onClickEdit={() => setIsEdit(true)}
            onClickDuplicate={onClickDuplicate}
        />
    );
}
