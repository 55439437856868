import { createSelector } from 'reselect';

import { RoleValue, V2FormattedUserData } from 'app/api/types/user';
import { AppState } from 'app/states/reducers';

// ACTION TYPES
export const GET_ME_REQUEST = 'GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'GET_ME_FAILURE';

export type GetMeAction = {
    type: 'GET_ME_REQUEST';
};

export type GetMeSuccessAction = {
    type: 'GET_ME_SUCCESS';
    user: V2FormattedUserData;
};

export type GetMeFailureAction = {
    type: 'GET_ME_FAILURE';
    error: Record<string, any>;
};

// ACTION FLOW TYPES
export type MeState = {
    data: V2FormattedUserData | null;
    isFetching: boolean;
    errors: Record<string, any>;
};

export type UserAction = GetMeAction | GetMeFailureAction | GetMeSuccessAction;

// ACTION CREATORS
export const getMe = (): GetMeAction => ({
    type: GET_ME_REQUEST,
});

export const getMeSuccess = (user: V2FormattedUserData): GetMeSuccessAction => ({
    type: GET_ME_SUCCESS,
    user,
});

export const getMeFailure = (error: Record<string, any>): GetMeFailureAction => ({
    type: GET_ME_FAILURE,
    error,
});

export const initialState = {
    data: null,
    isFetching: false,
    errors: {},
};

// REDUCER
const meReducer = (state: MeState = initialState, action: UserAction): MeState => {
    switch (action.type) {
        case GET_ME_REQUEST:
            return { ...state, errors: {}, isFetching: true };

        case GET_ME_FAILURE:
            return { ...state, errors: action.error, isFetching: false };

        case GET_ME_SUCCESS:
            return {
                ...state,
                data: action.user,
                isFetching: false,
            };
        default:
            return state;
    }
};

// SELECTORS
/** @deprecated use useMe() instead */
export const dataSelector = ({ data }: MeState): V2FormattedUserData | null => data;

/** @deprecated use useMe() instead */
export const meRoleSelector = ({ data }: MeState): RoleValue | null => (data ? data.role : null);

/** @deprecated use useMe() instead */
export const meOrgIdSelector = ({ data }: MeState): number | null => (data ? data.orgId : null);

/** @deprecated use useMe() instead */
export const isShadowUserSelector = createSelector(
    (state: AppState) => dataSelector(state.me),
    data => !!data?.shadowUserId,
);

export default meReducer;
