import { Button, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDataContext } from 'app/common/contexts/appData';
import { ADMIN } from 'app/common/data/roles';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import dataLayer from 'app/common/utils/dataLayer';
import { useHasPostPermission } from 'app/presence/googlePosts/hooks/useHasPostPermission';
import {
    ERROR_GOOGLE_POSTS,
    EXPIRED_POSTS,
    GooglePostState,
    PENDING_GOOGLE_POSTS,
    PUBLISHED_GOOGLE_POSTS,
    TO_CONFIGURE_POSTS,
} from 'app/presence/googlePosts/variables/googlePostStatus';
import { PageHeaderWithAvatar } from 'app/routing/components/PageHeaderWithAvatar';
import { POSTS_CREATE_PATH } from 'app/routing/routeIds';

const getPageTitle = (state: GooglePostState) => {
    switch (state) {
        case PENDING_GOOGLE_POSTS:
            return 'google_post_title_pending';

        case PUBLISHED_GOOGLE_POSTS:
            return 'google_post_title_published';

        case TO_CONFIGURE_POSTS:
            return 'google_post_title_to_configure';

        case ERROR_GOOGLE_POSTS:
            return 'google_post_title_error';

        case EXPIRED_POSTS:
            return 'google_post_title_expired';

        default:
            return 'google_post_title_all';
    }
};

export const GooglePostsListPageHeader = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [statusParam] = useStateQueryParams('status');
    const hasPostPermission = useHasPostPermission();

    const { me } = useAppDataContext();
    const isNotAdmin = me.role !== ADMIN;

    const title = `${t(getPageTitle(statusParam as GooglePostState))}`;

    const pageButtons = !hasPostPermission
        ? []
        : [
              <Button
                  dataTrackId="post_list_create_post"
                  shape="cube"
                  variant="primary"
                  icon={['fa-plus', IconPrefix.SOLID]}
                  onClick={() => {
                      dataLayer.pushDict('click_create_post');
                      navigate(POSTS_CREATE_PATH);
                  }}
                  key="create-post-button"
              >
                  {t('create_new_post')}
              </Button>,
          ];

    return isNotAdmin && <PageHeaderWithAvatar title={t(title)} actions={pageButtons} />;
};
