import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

const GoogleVerificationContext = createContext<Record<string, any>>({});

export type GoogleVerificationContextProps = {
    store: Record<string, any>;
    setStore: Dispatch<SetStateAction<Record<string, any>>>;
    locationQuery: UseInfiniteQueryResult<any>;
};
export default GoogleVerificationContext;

export const useGoogleVerificationContext = () =>
    useContext(GoogleVerificationContext);
