import React from 'react';

import { useTranslation } from 'react-i18next';

import useBusinessCategories from 'app/businessEditV2/hooks/business/useBusinessCategories';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';

type Props = {
    colorState?: ColorState;
};

const CategoriesSectionContent = ({ colorState = ColorState.OK }: Props) => {
    const { t } = useTranslation();
    const { data: categories } = useBusinessCategories();

    const mainCategoryName = categories?.[0]?.label;
    const numberOfAdditionalCategories =
        categories.length > 1 ? categories.length - 1 : 0;

    switch (colorState) {
        case ColorState.PRECHECK_ERRORS:
            return <>{t('business_edit_content_correct_this_field')}</>;
        case ColorState.MISSING_WARNING:
        case ColorState.CLOSED:
        case ColorState.DISABLED:
            return <>{t('business_edit_content_add_additional_categories')}</>;
        case ColorState.OK:
            return (
                <>
                    <i className="fa-solid fa-tag" />
                    <span>{mainCategoryName}</span>
                    <i className="fa-solid fa-tags" />
                    <span>
                        {t('business_edit_additional_categories_count', {
                            count: numberOfAdditionalCategories,
                        })}
                    </span>
                </>
            );
        default:
            return null;
    }
};

export default CategoriesSectionContent;
