import { useEffect, useMemo } from 'react';

import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { UseInfiniteQueryResult } from 'react-query/types/react/types';

import { V2BusinessData } from 'app/api/types/business';
import { UserBusinessesReceivedData } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import { BUSINESSES, USERS } from 'app/common/data/queryKeysConstants';

export const useUserBusinesses = (
    userId: string,
    options: UseInfiniteQueryOptions<UserBusinessesReceivedData> = {},
): [UseInfiniteQueryResult<UserBusinessesReceivedData>, Array<V2BusinessData> | undefined] => {
    const query = useInfiniteQuery(
        [USERS, BUSINESSES, userId],
        ({ pageParam = 1 }) => api.user.getUserBusiness(userId, pageParam),
        {
            getNextPageParam: prevPage =>
                prevPage.page < prevPage.max_page ? prevPage.page + 1 : undefined,
            ...options,
        },
    );

    const userBusinesses = useMemo(
        () => query.data?.pages?.map(page => page.businesses)?.flat(),
        [query.data],
    );

    return [query, userBusinesses];
};

export const useUserAllBusinesses = (
    userId: string,
    options: UseInfiniteQueryOptions<UserBusinessesReceivedData> = {},
): [UseInfiniteQueryResult<UserBusinessesReceivedData>, Array<V2BusinessData> | undefined] => {
    const [query, userBusinesses] = useUserBusinesses(userId, options);

    useEffect(() => {
        if (query.hasNextPage) {
            query.fetchNextPage();
        }
    }, [userId, userBusinesses]);

    return [query, userBusinesses];
};
