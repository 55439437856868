import { toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls/camel';
import {
    MESSAGE_REPLY_TEMPLATE_UPDATE,
    MESSAGING_REPLY_TEMPLATES,
} from 'app/common/data/queryKeysConstants';
import { MessageReplyTemplateData } from 'app/states/messageReplyTemplate/dataTypes';

export const useUpdateMessageReplyTemplate = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const query = useMutation(
        (params: { templateId: string; payload: MessageReplyTemplateData }) =>
            api.messagingReplyTemplates.updateMessageReplyTemplate(
                params.templateId,
                params.payload,
            ),
        {
            mutationKey: MESSAGE_REPLY_TEMPLATE_UPDATE,
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [MESSAGING_REPLY_TEMPLATES],
                });
                toast.success(null, t('success'));

                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
    return query;
};

export default useUpdateMessageReplyTemplate;
