import React from 'react';

import { Modal } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { CustomFieldsSectionQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

import CustomFieldsSectionRenameContent from './CustomFieldsSectionRenameContent';

const CustomFieldsSectionRenameModal = () => {
    const [renameSection, setRenameSection] = useStateQueryParams(
        CustomFieldsSectionQueryKeys.RENAME,
    );

    const closeModal = () => setRenameSection('');

    return (
        <Modal
            isOpen={!!renameSection}
            closeModal={closeModal}
            placement="center"
            animation="fadeIn"
        >
            <CustomFieldsSectionRenameContent closeModal={closeModal} />
        </Modal>
    );
};

export default CustomFieldsSectionRenameModal;
