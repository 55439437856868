import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const RowInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;

    .success {
        color: ${({ theme }) => theme.colors.success.initial};
    }

    .danger {
        color: ${({ theme }) => theme.colors.danger.initial};
    }
`;

export const IconText = styled.div`
    display: flex;
    align-items: center;
`;

export const Icon = styled.div`
    color: ${HEX_COLORS.primary};
    margin-right: 8px;
`;

export const CountProgress = styled.div`
    font-weight: bold;
`;
