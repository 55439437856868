import { ImageFile } from 'react-dropzone';

import { ImageUploadErrorDetails } from 'app/common/designSystem/components/molecules/DropZone/helpers';

import { getClampedRatio } from './getClampedRatio';
import { recropImages } from './recropImages';
import { ImageListType, ImageType } from '../../../../context/NewPost';
import { ImageUploadError } from '../../../../hooks/useImageUploadErrors';

export const loadNewImages = async (
    imagesFiles: Array<ImageFile>,
    getErrors: (img: HTMLImageElement) => ImageUploadError,
    shouldCrop?: boolean,
    aspectRatio?: number,
) => {
    const errorsOnLoad: ImageUploadErrorDetails[] = [];

    const loadedImages = await Promise.all(
        imagesFiles.map(
            imageFile =>
                new Promise<ImageType | undefined>((resolve, reject) => {
                    const image = new Image();

                    image.onload = () => {
                        const fileErrors = getErrors(image);

                        if (fileErrors.size === 0) {
                            const original = {
                                data_url: imageFile.preview ?? '',
                                file: imageFile,
                                width: image.width,
                                height: image.height,
                            };
                            const loadedImage = {
                                original,
                                ...original,
                            };
                            resolve(loadedImage);
                        } else {
                            errorsOnLoad.push({
                                imageName: imageFile.name,
                                errorTypes: fileErrors,
                            });
                            resolve(undefined);
                        }
                    };

                    image.onerror = () => {
                        errorsOnLoad.push({
                            imageName: imageFile.name,
                            errorTypes: new Set(['unexpected']) as ImageUploadError,
                        });
                        reject(new Error('unexpected'));
                    };

                    image.src = imageFile.preview ?? '';
                }),
        ),
    );

    let newImages = loadedImages.filter(image => image !== undefined) as ImageListType;
    if (shouldCrop && newImages.length > 0) {
        newImages = await recropImages(
            newImages,
            aspectRatio ?? getClampedRatio(newImages[0].width, newImages[0].height),
        );
    }
    return { images: newImages, errors: errorsOnLoad };
};
