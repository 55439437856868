import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { ATTRIBUTES } from 'app/common/data/queryKeysConstants';
import { useLanguage } from 'app/common/services/getLang';

export default function useAttributes() {
    const { data: business } = useBusiness();
    const lang = useLanguage();

    return useQuery(
        [
            ATTRIBUTES,
            { country: business?.country },
            { category: business?.categories[0] },
            lang,
        ],
        () =>
            api.attributes.getAttributes({
                category_name: business ? business.categories[0] : '',
                country: business ? business.country : null,
            }),
        {
            enabled: !!business?.categories?.[0],
            retry: false,
            retryOnMount: false,
        },
    );
}
