import React, { useRef } from 'react';

import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import { useTranslation } from 'react-i18next';

import * as pCol from 'app/common/designSystem/constants/color';
import { numberFormatter } from 'app/common/services/formatNumber';
import getLang from 'app/common/services/getLang';
import { OverrideBar } from 'app/pages/analytics/VisibilityAnalyticsV2/components/TrendChart/TrendChart.styled';
import { formatTooltipTitle } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/formatTooltip';
import { GraphTypes } from 'app/utils/chartjs/graphTypes';
import { toggleLegend } from 'app/utils/chartjs/helpers';
import { buildOptionsForGraphic } from 'app/utils/chartjs/options';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type Props = {
    data: any;
};

export const TrendChart = ({ data }: Props) => {
    const { t } = useTranslation();
    const type = GraphTypes.BAR;
    const ref = useRef(null);

    const tooltip = {
        callbacks: {
            label: tooltipItem => {
                const { label } = tooltipItem.dataset;
                const separator = getLang() === 'fr' ? ' : ' : ': ';
                const value = tooltipItem.raw;
                return `${label}${separator}${numberFormatter(Math.round(value))}`;
            },
            title: tooltipItems =>
                data.key !== 'queries'
                    ? formatTooltipTitle(
                          data.selectedBucket,
                          data.userDates,
                          data.datesReturned[tooltipItems?.[0]?.dataIndex],
                      )
                    : data.labels[tooltipItems?.[0]?.dataIndex],
            afterBody: tooltipItems => {
                let sum = 0;
                tooltipItems.forEach(function (tooltipItem) {
                    sum += tooltipItem.parsed.y;
                });
                if (tooltipItems.length > 1) {
                    return `     ${t('total_in_tooltip')} ${numberFormatter(sum)}`;
                } else {
                    return '';
                }
            },
        },
    };

    const buildOptions: any = () => {
        const options: any = buildOptionsForGraphic(type);
        options.plugins.tooltip = tooltip;
        if (data.key === 'queries') {
            options.plugins.legend.display = false;
        }
        options.plugins.legend.onClick = (event, legendItem) => toggleLegend(legendItem, ref);
        options.scales.y.ticks.callback = value => numberFormatter(value, getLang(), true);
        options.plugins.legend.labels.fontStyle = 'bold';
        options.plugins.legend.labels.fontColor = pCol.SECONDARY;
        options.elements = {
            borderWidth: 0,
            borderRadius: 5,
        };
        options.title = {
            borderWidth: 1,
            fontSize: 18,
            fontFamily: 'Outfit',
        };
        options.aspectRatio = 1;
        options.maintainAspectRatio = false;
        return options;
    };

    return <OverrideBar ref={ref} data={data} options={buildOptions()} />;
};
