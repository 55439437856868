import { ReactNode } from 'react';

import { StyledTextInput } from './PasswordField.styled';

export interface Props {
    value: string;
    onChange: (value: string) => void;
    label?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    error?: boolean;
    notice?: string | ReactNode;
    onFocus?: () => void;
    onBlur?: () => void;
    dataTrackId: string;
}

export const PasswordField = ({
    dataTrackId,
    label,
    placeholder,
    required = false,
    error,
    disabled = false,
    notice,
    value,
    onChange,
    onFocus,
    onBlur,
}: Props) => {
    return (
        <StyledTextInput
            dataTrackId={dataTrackId}
            type="password"
            label={label}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            required={required}
            value={value}
            onChange={onChange}
            notice={notice}
            error={error}
        />
    );
};
