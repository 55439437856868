import React, { useState } from 'react';

import ReactDropZone from 'react-dropzone';

import explanationHOC from 'app/common/designSystem/components/atoms/ExplanationHOC';

import { DropZoneWrapper, DropzoneWrapperSelectorId, ReactDropZoneStyle } from './DropZone.styled';
import DropZoneDescription from './DropZoneDescription';
import DropZoneLoading from './DropZoneLoading';
import DropZoneSuccess from './DropZoneSuccess';
import { AcceptedFile, DEFAULT_ACCEPTED_FILES, FileError, getErrorsFromFiles } from './helpers';

type DropZoneProps = {
    disabled?: boolean;
    files: File[] | null;
    errors?: FileError[] | null;
    label?: string;
    acceptedFiles?: AcceptedFile[];
    minSize?: number;
    maxSize?: number;
    maxFiles?: number;
    uploadIcon?: React.ReactNode;
    errorIcon?: React.ReactNode;
    handleDropSuccess: (files: File[]) => void;
    handleDropError?: (files: File[]) => void;
    handleDropClear?: () => void;
    isCompact?: boolean;
    hideAcceptedFiles?: boolean;
    inFoodMenuModal?: boolean;
    isLoading?: boolean;
    children?: React.ReactNode;
    childrenPosition?: 'top' | 'bottom';
    // TODO MORTY photo errors: When refactoring photo errors, we shouldn't need more than 1 prop
    isBusinessEditV2?: boolean;
    useCompactError?: boolean;
    onDrop?: () => void;
};

const DropZone = ({
    disabled = false,
    files,
    errors,
    label,
    acceptedFiles = DEFAULT_ACCEPTED_FILES,
    minSize = 0,
    maxSize = 5 * 1_000_000, // 5Mo
    maxFiles = 0,
    uploadIcon,
    errorIcon,
    handleDropSuccess,
    handleDropError,
    handleDropClear,
    isCompact = false,
    hideAcceptedFiles = false,
    inFoodMenuModal = false,
    isLoading = false,
    children,
    childrenPosition = 'top',
    isBusinessEditV2 = false,
    useCompactError = false,
    onDrop,
}: DropZoneProps) => {
    const [dropErrors, setDropErrors] = useState<FileError[]>([]);
    const acceptedExtensions = acceptedFiles.map(({ extension }) => extension);
    const acceptedMemeTypes = acceptedFiles.map(({ memeType }) => memeType);

    const isSuccessful = Boolean(files?.length && !errors?.length);

    // Handle errors internally if no `handleDropError` is provided
    const onDropRejected = (newFiles: File[]) => {
        if (handleDropError) {
            handleDropError(newFiles);
            return;
        }

        if (!newFiles?.length) {
            setDropErrors([]);
            return;
        }

        setDropErrors(getErrorsFromFiles(newFiles, { maxSize, minSize }));
    };

    const onDropClear = () => {
        setDropErrors([]);

        if (handleDropClear) {
            handleDropClear();
        }
    };

    return (
        <DropZoneWrapper
            data-css-selector={DropzoneWrapperSelectorId}
            isCompact={isCompact}
            inFoodMenuModal={inFoodMenuModal}
            hasError={!!(errors?.length || dropErrors.length)}
            disabled={disabled}
        >
            <ReactDropZone
                // @ts-ignore
                style={ReactDropZoneStyle}
                onDropAccepted={handleDropSuccess}
                onDropRejected={onDropRejected}
                minSize={minSize}
                maxSize={maxSize}
                maxfiles={maxFiles}
                accept={acceptedMemeTypes}
                multiple={maxFiles !== 1}
                disabled={disabled}
                onDrop={onDrop}
            >
                {childrenPosition === 'top' && children}
                {isLoading ? (
                    <DropZoneLoading />
                ) : (
                    <>
                        <DropZoneDescription
                            files={files || []}
                            errors={errors || dropErrors || []}
                            label={label}
                            acceptedExtensions={acceptedExtensions}
                            maxFiles={maxFiles}
                            minSize={minSize}
                            maxSize={maxSize}
                            uploadIcon={uploadIcon}
                            errorIcon={errorIcon}
                            isSuccessful={isSuccessful}
                            isCompact={isCompact}
                            isBusinessEditV2={isBusinessEditV2}
                            useCompactError={useCompactError}
                            hideAcceptedFiles={hideAcceptedFiles}
                            inFoodMenuModal={inFoodMenuModal}
                        />

                        {isSuccessful && (
                            <DropZoneSuccess files={files!} handleDropClear={onDropClear} />
                        )}
                    </>
                )}
                {childrenPosition === 'bottom' && children}
            </ReactDropZone>
        </DropZoneWrapper>
    );
};

export const DropzoneWithExplanation = explanationHOC<DropZoneProps>(DropZone);

export default DropZone;
