import { useMemo, useState } from 'react';

import { MessageReplyTemplate as MessageReplyTemplateType } from 'app/states/messageReplyTemplate/dataTypes';

interface TemplateFormContextProps {
    creatingMode: boolean;
    updateItem: MessageReplyTemplateType | null;
    filteringTitle: string;
    setCreatingMode: (_value: boolean) => void;
    setUpdateItem: (_value: MessageReplyTemplateType) => void;
    setFilteringTitle: (_value: string) => void;
}

export const useMessagesReplyTemplatesContext = () => {
    const [creatingMode, setCreatingMode] = useState(false);
    const [updateItem, setUpdateItem] = useState<MessageReplyTemplateType | null>(null);
    const [filteringTitle, setFilteringTitle] = useState('');

    const templateFormValue: TemplateFormContextProps = useMemo(() => {
        return {
            creatingMode,
            updateItem,
            filteringTitle,
            setCreatingMode,
            setUpdateItem,
            setFilteringTitle,
        };
    }, [
        creatingMode,
        updateItem,
        filteringTitle,
        setCreatingMode,
        setUpdateItem,
        setFilteringTitle,
    ]);

    return templateFormValue;
};
