import React, { useEffect, useRef, useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Icon, IconPrefix } from '@partoohub/ui';
import { uniqueId } from 'lodash';

import { useTranslation } from 'react-i18next';

import { usePopper } from 'react-popper';
import { useNavigate } from 'react-router-dom';

import IS_IFRAME from 'app/common/data/iframe';
import { BUSINESS_MANAGER, GROUP_MANAGER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMe';
import dataLayer from 'app/common/utils/dataLayer';
import useGetMessageReplyTemplates from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/hooks/useGetMessageReplyTemplates';
import MessagingReplyTemplatesPopIn from 'app/reviewManagement/messaging/activeConversation/messages/MessagingReplyTemplatesPopIn';
import { SETTINGS_MESSAGES_REPLY_TEMPLATES_PATHNAME } from 'app/routing/routeIds';
import { MessageReplyTemplate } from 'app/states/messageReplyTemplate/dataTypes';
import { Conversation } from 'app/states/messaging';

import {
    NoMatchesText,
    PopoverContainer,
    PopoverGearIcon,
    PopoverHeader,
    PopoverMenuItem,
    PopoverMenuList,
    PopoverSearchBar,
    PopoverSearchBarIcon,
    PopoverSearchBarInput,
    PopoverSearchBarPlaceholder,
    PopoverSortingExplanation,
} from './MessagingReplyTemplatesPopover.styled';
import NoTemplateScreen from './NoTemplateScreen';

type MessagingReplyTemplatesPopoverProps = {
    setState: (value: boolean) => void;
    setMessageContentAsReplyTemplateContent: (
        eventType: string,
        template?: MessageReplyTemplate,
    ) => void;
    conversation: Conversation;
    showConversationReplyTemplatePopIn: boolean;
    onHideConversationReplyTemplatePopIn: () => void;
};

const MessagingReplyTemplatesPopover = ({
    setState,
    setMessageContentAsReplyTemplateContent,
    conversation,
    showConversationReplyTemplatePopIn,
    onHideConversationReplyTemplatePopIn,
}: MessagingReplyTemplatesPopoverProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const popoverRef = useRef<HTMLInputElement>(null);
    const { data, isLoading } = useGetMessageReplyTemplates();
    // Search bar to find a template
    const [query, setQuery] = useState('');
    const filteredData = (data || []).filter(item => {
        const templateText = JSON.parse(item.content).text;
        return (
            item.title.toLowerCase().includes(query.toLowerCase()) ||
            templateText.toLowerCase().includes(query.toLowerCase()) ||
            item.icon?.includes(query)
        );
    });

    const [selectionIndex, setSelectionIndex] = useState<number>(-1);
    const menuListRef = useRef<HTMLInputElement>(null);
    const userRole = useMe()?.data?.role;
    const showRedirectionIcon =
        !IS_IFRAME && userRole && ![GROUP_MANAGER, BUSINESS_MANAGER].includes(userRole);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const elementRef = useRef<HTMLDivElement>(null);

    const { styles, attributes } = usePopper(elementRef.current, tooltipRef.current, {
        placement: 'top-end',
    });

    const handleClickOutside = e => {
        if (popoverRef.current && !popoverRef.current.contains(e.target)) {
            setState(false);
            // Also clear the input as click outside is not an action to select the templates.
            setMessageContentAsReplyTemplateContent('mouseleave');
            onHideConversationReplyTemplatePopIn();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    // Blink animation when template is selected
    const [triggerAnimation, setTriggerAnimation] = useState(false);

    const onTemplateClick = (index: number) => {
        setTriggerAnimation(true);
        setSelectionIndex(index);
    };

    const onAnimationEnd = (replyTemplate: MessageReplyTemplate) => {
        setTriggerAnimation(false);
        setMessageContentAsReplyTemplateContent('mousedown', replyTemplate);
    };

    const onMouseOverItemHandler = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        // Remove behaviour during animation
        if (triggerAnimation) {
            return;
        }
        event.preventDefault();
        setSelectionIndex(index);

        if (filteredData && index >= 0) {
            setMessageContentAsReplyTemplateContent(event.type, filteredData[index]);
        }
    };

    const setSelectionAndScroll = (selection: number) => {
        setSelectionIndex(selection);
        if (menuListRef.current) {
            // Scroll into view the current selected option.
            menuListRef.current.children[selection]?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
    };

    const handleKeyboardNav = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // Remove behaviour during animation
        if (triggerAnimation) {
            return;
        }
        if (['Enter', 'Escape', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
            // These keys are always prevented from bubbling.
            event.preventDefault();
        }

        if (event.key === 'Enter' && filteredData && filteredData[selectionIndex]) {
            // we send the currently selected item as selected template.
            onTemplateClick(selectionIndex);
        } else if (event.key === 'Escape') {
            // We close the popover if escape key is pressed and then restore
            // the original text in the input.
            setState(false);
            setMessageContentAsReplyTemplateContent('mouseleave');
            onHideConversationReplyTemplatePopIn();
        } else if (event.key === 'ArrowDown') {
            // Highlight next item, and cycle back to top if needed.
            const nextSelection =
                selectionIndex === filteredData.length - 1 ? 0 : selectionIndex + 1;
            setSelectionAndScroll(nextSelection);
        } else if (event.key === 'ArrowUp') {
            // Highlight previous item.
            const prevSelection =
                selectionIndex <= 0 ? filteredData.length - 1 : selectionIndex - 1;
            setSelectionAndScroll(prevSelection);
        }
    };

    useEffect(() => {
        if (filteredData && filteredData[selectionIndex]) {
            setMessageContentAsReplyTemplateContent('mouseenter', filteredData[selectionIndex]);
        }
    }, [selectionIndex, query]);

    // Highlight query in template titles
    const highlightQuery = (templateTitle: string) => {
        if (!query) return templateTitle;

        const regex = new RegExp(`(${query})`, 'gi');
        const parts = templateTitle.split(regex);
        return parts.map((part, _) => {
            if (part.toLowerCase() === query.toLowerCase()) {
                // sonar issue: no index keys for array. Not relevant here.
                return <b key={uniqueId()}>{part}</b>;
            } else {
                return <span key={uniqueId()}>{part}</span>;
            }
        });
    };

    const onGearIconClick = () => {
        dataLayer.pushDict('click_on_settings_icon_within_reply_template_modal', {
            messaging_channel: conversation.messaging_partner,
            business_id: conversation.business,
        });
        window.open(`${SETTINGS_MESSAGES_REPLY_TEMPLATES_PATHNAME}`);
    };

    const onNoTemplateButtonClick = () => {
        onHideConversationReplyTemplatePopIn();
        navigate(`${SETTINGS_MESSAGES_REPLY_TEMPLATES_PATHNAME}`);
    };

    // Special popover screen when no template has been created yet.
    if (data?.length === 0 && !isLoading) {
        return (
            <PopoverContainer ref={popoverRef}>
                <NoTemplateScreen onButtonClick={onNoTemplateButtonClick} />
            </PopoverContainer>
        );
    }

    return (
        <PopoverContainer ref={popoverRef}>
            <PopoverHeader>
                <PopoverSearchBar>
                    <PopoverSearchBarPlaceholder>
                        {t('messaging_reply_template_popover_search_bar_placeholder')}
                    </PopoverSearchBarPlaceholder>

                    <PopoverSearchBarInput
                        type="text"
                        value={query}
                        onChange={e => {
                            setQuery(e.target.value);
                            setSelectionIndex(0);
                        }}
                        autoFocus
                        onKeyDown={handleKeyboardNav}
                    />

                    <PopoverSearchBarIcon>
                        <Icon icon={['fa-search']} iconSize="16px" color={HEX_COLORS.secondary} />
                    </PopoverSearchBarIcon>
                </PopoverSearchBar>
                {showRedirectionIcon && (
                    <>
                        <PopoverGearIcon
                            ref={elementRef}
                            onClick={onGearIconClick}
                            data-track="messaging_template__template_page_redirection"
                            data-intercom-target="messaging_template__template_page_redirection"
                            contained={showConversationReplyTemplatePopIn}
                        >
                            <Icon
                                icon={['fa-gear', IconPrefix.SOLID]}
                                iconSize="16px"
                                color={HEX_COLORS.secondary}
                            />
                        </PopoverGearIcon>
                        {showConversationReplyTemplatePopIn && (
                            <div ref={tooltipRef} style={styles.popper} {...attributes.popper}>
                                <MessagingReplyTemplatesPopIn
                                    content={t(
                                        'messaging_conversation_reply_templates_redirection_button_pop_in',
                                    )}
                                    onClick={onHideConversationReplyTemplatePopIn}
                                    dataTrackId={
                                        'messaging_template__template_redirection_button_pop_in'
                                    }
                                    placeArrowAtEnd
                                />
                            </div>
                        )}
                    </>
                )}
            </PopoverHeader>

            <PopoverMenuList ref={menuListRef}>
                <PopoverSortingExplanation color="secondary" variant="bodySBold" as="span">
                    {t('messaging_reply_template_sorting_order_explanation')}
                </PopoverSortingExplanation>

                {filteredData.map((replyTemplate, index) => (
                    <PopoverMenuItem
                        id={String(index)}
                        key={replyTemplate.id}
                        onMouseEnter={e => onMouseOverItemHandler(e, index)}
                        onMouseLeave={e => onMouseOverItemHandler(e, index)}
                        onClick={() => onTemplateClick(index)}
                        onAnimationEnd={() => onAnimationEnd(replyTemplate)}
                        selected={index === selectionIndex}
                        triggerAnimation={triggerAnimation}
                    >
                        <span>
                            {replyTemplate.icon} {highlightQuery(replyTemplate.title)}{' '}
                        </span>
                        <Icon
                            icon={['fa-arrow-turn-down-left', IconPrefix.REGULAR]}
                            iconSize="16px"
                            color={HEX_COLORS.secondary}
                        />
                    </PopoverMenuItem>
                ))}
                {filteredData.length === 0 && query && (
                    <NoMatchesText variant="bodyMRegular" color="secondary" as="span">
                        {t('no-result-found')}
                    </NoMatchesText>
                )}
            </PopoverMenuList>
        </PopoverContainer>
    );
};

export default MessagingReplyTemplatesPopover;
