import React from 'react';

import { useTranslation } from 'react-i18next';

import { HOUR_SEPARATOR } from 'app/businessEditV2/oldFromEditV1/data/constants';

import { ErrorWarningText } from 'app/common/components/OpenHour/OpenHour.styled';

import HoursItem from './HoursItem';

type Props = {
    disabled: boolean;
    openHours: Array<string>;
    onTimeWindowChange: (modifiedOpenHours: Array<string>) => void;
    hoursError: string;
};

const HoursList = ({ disabled, openHours, onTimeWindowChange, hoursError }: Props) => {
    const { t } = useTranslation();
    const updateTimeWindow = (index: number, start: string, end: string) => {
        const result: Array<string> = openHours;
        result[index] = `${start}-${end}`;
        onTimeWindowChange(result);
    };

    const addTimeWindow = () => {
        const result: Array<string> = openHours;
        result.push('');
        onTimeWindowChange(result);
    };

    const deleteTimeWindow = (timeWindowIndex: number) => {
        const result: Array<string> = openHours;
        result.splice(timeWindowIndex, 1);
        onTimeWindowChange(result);
    };

    return (
        <>
            {openHours.map((openHour, index) => {
                const [startHour, endHour] = openHour.length
                    ? openHour.split(HOUR_SEPARATOR)
                    : ['', ''];
                return (
                    <HoursItem
                        key={`specific_hour_${index}`}
                        disabled={disabled}
                        startHour={startHour}
                        endHour={endHour}
                        isFirstOpenHour={index === 0}
                        updateTimeWindow={(start: string, end: string) =>
                            updateTimeWindow(index, start, end)
                        }
                        addTimeWindow={addTimeWindow}
                        deleteTimeWindow={() => deleteTimeWindow(index)}
                        hoursError={hoursError}
                    />
                );
            })}
            {!!hoursError && (
                <ErrorWarningText variant="bodySRegular" color="danger">
                    {t(hoursError)}
                </ErrorWarningText>
            )}
        </>
    );
};

export default HoursList;
