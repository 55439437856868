import styled from '@emotion/styled';

export const BannerContainer = styled.div`
    margin: 32px 0;
`;

export const AddressContainer = styled.div`
    .mdc-text-field-helper-line {
        margin-bottom: 6px;
        min-height: 0;
    }
`;

export const AddressGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
`;
