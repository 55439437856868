import styled from '@emotion/styled';

import Flexbox from 'app/styles/utils/flexbox';

export const ActiveConversationContainer = styled.div`
    flex: 1.5;
    min-width: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const ExpiredConversationContainer = styled.div`
    margin: 56px auto 32px auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    i {
        margin-right: 0.5em;
    }
`;

export const ConversationWithIssuesPlaceholder = styled(Flexbox)`
    flex: 1;
    flex-direction: column;
    margin: 56px auto 32px auto;
    justify-content: center;
    align-items: center;
    text-align: center;

    i {
        font-size: 24px;
        margin: 4px;
        color: ${({ theme }) => theme.colors.danger.initial};
    }

    p {
        color: ${({ theme }) => theme.colors.secondary.initial};
        font-weight: bold;
    }
`;
