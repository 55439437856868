import { useQueries } from 'react-query';

import api from 'app/api/v2/api_calls';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { BUSINESS_CATEGORY } from 'app/common/data/queryKeysConstants';
import { useLanguage } from 'app/common/services/getLang';

export default function useBusinessCategories() {
    const lang = useLanguage();
    const { data: business } = useBusiness();

    const queries = useQueries(
        business?.categories.map(category => ({
            queryKey: [BUSINESS_CATEGORY, { category }, lang],
            queryFn: () => api.categories.getCategory(category, lang),
            enabled: !!business,
        })) ?? [],
    );

    const isLoading = business ? queries.some(query => query.isLoading) : true;

    const data = !isLoading
        ? queries.map(query => ({
              label: query.data?.name ?? '',
              value: query.data?.gmb_id ?? '',
          }))
        : [];

    return { queries, data, isLoading };
}
