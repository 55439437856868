import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import LoadingView from 'app/common/components/LoadingView';
import {
    BUSINESSES,
    HOMEPAGE_MESSAGING_STATS,
    METRICS_ANALYTICS,
    REVIEW_LIST,
} from 'app/common/data/queryKeysConstants';
import { useHasHomepage } from 'app/pages/Homepage/hooks/useHasHomepage';
import { VISIBILITY_PATH } from 'app/routing/routeIds';
import queryClient from 'app/states/queryClient';

import { HomepageContainer } from './Homepage.styled';
import HomepageHeader from './sections/HomepageHeader';
import HomepageShortcuts from './sections/HomepageShortcuts';
import HomepageStats from './sections/HomepageStats';
import HomepageTodos from './sections/HomepageTodos';

export const Homepage = () => {
    const { hasHomepage, isLoading } = useHasHomepage();
    const navigate = useNavigate();

    // Redirect to visibility page if user doesn't have homepage
    useEffect(() => {
        if (!hasHomepage && !isLoading) {
            navigate(VISIBILITY_PATH, { replace: true });
        }
    }, [hasHomepage, isLoading]);

    // Reset queries cache
    useEffect(() => {
        document.getElementById('react-content')?.scrollTo(0, 0);
        queryClient.invalidateQueries(
            invalidatesAny(BUSINESSES, HOMEPAGE_MESSAGING_STATS, REVIEW_LIST, METRICS_ANALYTICS),
        );
    }, []);

    if (!hasHomepage || isLoading) {
        return <LoadingView />;
    }

    return (
        <HomepageContainer>
            <HomepageHeader />
            <HomepageShortcuts />
            <HomepageTodos />
            <HomepageStats />
        </HomepageContainer>
    );
};
