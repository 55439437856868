import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import {
    DropdownContainer,
    DropdownList,
    DropdownListContainer,
    DropdownTextContainer,
    IconsContainer,
    ListItem,
    ListItemText,
} from 'app/pages/analytics/components/Select/Select.styled';
import { capitalize } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/string';

type Props = {
    label: string;
    handleClick: any;
    selectedValue: string;
    options: Array<OptionItem>;
    dataTrack?: string;
};

type OptionItem = {
    value: any;
    label?: string;
    disabled?: boolean;
    dataTrack?: string;
};

export const Select = ({ label, selectedValue, handleClick, options, dataTrack }: Props) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const selectedOption = options.find(x => x.value === selectedValue);
    const selectedValueLabel =
        selectedOption && selectedOption.label ? selectedOption.label : selectedValue;
    const [isOpen, setIsOpen] = useState(false);

    const toggling = useCallback(() => setIsOpen(!isOpen), [isOpen]);

    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const clickOnOption = useCallback(
        value => () => {
            handleClick(value);
            setIsOpen(false);
        },
        [],
    );

    return (
        <DropdownContainer data-intercom-target={dataTrack}>
            <Button
                dataTrackId={dataTrack || ''}
                size={'small'}
                shape={'cube'}
                variant={'secondary'}
                type={'button'}
                appearance="outlined"
                onClick={toggling}
                icon={
                    <IconsContainer>
                        <Icon icon={[FontAwesomeIconsPartooUsed.faCaretUp, IconPrefix.SOLID]} />
                        <Icon icon={[FontAwesomeIconsPartooUsed.faCaretDown, IconPrefix.SOLID]} />
                    </IconsContainer>
                }
                iconPosition="right"
            >
                <DropdownTextContainer>
                    <Text variant={'bodySBold'} color={'secondary'}>
                        {label}
                    </Text>
                    <Text variant={'bodySBold'}>{capitalize(t(selectedValueLabel))}</Text>
                </DropdownTextContainer>
            </Button>

            {isOpen && (
                <DropdownListContainer ref={ref}>
                    <DropdownList>
                        {options.map(option => (
                            <ListItem
                                disabled={option.disabled}
                                active={option.value === selectedValue}
                                onClick={clickOnOption(option.value)}
                                key={option.value}
                                data-track={option?.dataTrack}
                                data-intercom-target={option?.dataTrack}
                            >
                                <ListItemText active={option.value === selectedValue}>
                                    {capitalize(t(option.label || option.value))}
                                </ListItemText>
                                {option.value === selectedValue && (
                                    <i className="fa fa-fas fa-check" />
                                )}
                            </ListItem>
                        ))}
                    </DropdownList>
                </DropdownListContainer>
            )}
        </DropdownContainer>
    );
};
