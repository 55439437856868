import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { MESSAGING_CONVERSATION_STATS } from 'app/common/data/queryKeysConstants';
import { ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useConversationStats = () => {
    const { data: me } = useMe();
    const filters = useBusinessModalFilters();
    return useQuery(
        [MESSAGING_CONVERSATION_STATS, filters],
        () => api.messaging.getConversationsStats(filters),
        { enabled: !!me && me.role !== ADMIN },
    );
};
