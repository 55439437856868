import { WHATSAPP_EMBEDDED_SIGNUP_CONFIG_ID } from 'app/config';

export interface FBLoginCallbackAuthResponse {
    code: string;
    user_id?: string;
}

interface FBLoginCallbackProps {
    authResponse?: FBLoginCallbackAuthResponse;
}

export const launchEmbeddedSignup = (
    onSuccess: (response: FBLoginCallbackAuthResponse) => void,
    onError: () => void,
    onlyWabaSharing?: boolean,
    defaultName?: string,
) => {
    FB.login(
        function (response: FBLoginCallbackProps) {
            if (response.authResponse) {
                onSuccess(response.authResponse);
                FB.logout();
            } else {
                onError();
            }
        },
        {
            config_id: WHATSAPP_EMBEDDED_SIGNUP_CONFIG_ID,
            response_type: 'code',
            override_default_response_type: true,
            extras: {
                setup: {
                    business: {
                        name: defaultName,
                    },
                    phone: {
                        displayName: defaultName,
                    },
                },
                featureType: onlyWabaSharing ? 'only_waba_sharing' : undefined,
            },
        },
    );
};
