import styled from '@emotion/styled';

export const VisibilityChartGraph = styled.div`
    display: flex;
    max-width: 100%;
    align-items: center;

    @media screen and (max-width: 768px) {
        justify-content: space-between;
    }
`;
