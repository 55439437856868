import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import { Text } from '@partoohub/ui';

export const ReviewReportContainer = styled.div`
    margin-bottom: 24px;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    border-radius: ${({ theme }) => theme.radius.large};
    > .toggle-box-container,
    > .expandable-toggle-box--container {
        border: none;
    }
    > .toggle-box-container:not(:first-of-type),
    > .expandable-toggle-box--container {
        border-top: 1px solid ${greyColorObject.initial};
        border-radius: 0;
    }
    .toggle-box-icon {
        display: initial;
    }
`;

export const EmailReportDescription = styled(Text)`
    margin-bottom: 24px;
`;
