import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Clickable from 'app/common/components/buttons/Clickable';

export const SelectButton = styled(Clickable)<{ selected?: boolean }>`
    ${({ selected, theme }) =>
        selected
            ? css`
                  border: 2px solid ${theme.colors.primary.initial};
                  padding: 18px 0;
              `
            : css`
                  border: 1px solid rgba(20, 37, 66, 0.12);
                  padding: 19px 0;
                  &:hover {
                      border: 1px solid ${theme.colors.secondary.initial};
                      background: rgba(215, 219, 223, 0.12);
                  }
              `}

    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 33%;
    @media (max-width: 769px) {
        width: 100%;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    width: 24px;
    margin-right: 8px;
`;

export const TextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;
