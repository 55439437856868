import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const BusinessAdviceDescriptionContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 178px;
    color: ${HEX_COLORS.blackberry};
    background: linear-gradient(99.13deg, #eaf2f9 1.63%, #f8f0ff 100%);
    border-radius: 16px;
    padding: 18px 32px;
    flex-grow: 1;
`;
