import { useEffect } from 'react';

import { css } from '@emotion/react';
import { Stack } from '@partoohub/ui';

import { useGraphSelection } from './hooks/useGraphSelection';
import {
    CardsSection,
    FilterSection,
    GraphSection,
    GraphSelectorSection,
    InternalRankingSection,
} from './Sections';

export const ReviewAnalyticsV2 = () => {
    const [, setSelectedGraph] = useGraphSelection();

    useEffect(() => {
        setSelectedGraph('average_rating');
    }, []);

    return (
        <Stack>
            <FilterSection />
            <CardsSection />
            <Stack direction="row">
                <GraphSelectorSection />
                <Stack
                    css={css`
                        min-width: calc(75% - 16px);
                    `}
                >
                    <GraphSection />
                    <InternalRankingSection />
                </Stack>
            </Stack>
        </Stack>
    );
};
