import { UseQueryOptions, useQuery } from 'react-query';

import { LiteralRating } from 'app/api/types/review';
import api from 'app/api/v2/api_calls';
import { REPLY_TEMPLATE_LANGUAGE } from 'app/common/data/queryKeysConstants';

/*
 *   Get all languages in which templates with rating `rating` are written
 */
const useReplyTemplatesLanguages = (
    rating: LiteralRating | undefined,
    options?: UseQueryOptions<Array<string>>,
) => {
    const query = useQuery(
        [REPLY_TEMPLATE_LANGUAGE, rating],
        () => api.review.getTemplateLanguages(rating),
        { ...options },
    );

    return [query.data, query] as const;
};

export default useReplyTemplatesLanguages;
