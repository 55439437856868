import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react';

import { AccountPartnerName } from 'app/api/types/account';

interface PartnerConnectionContextValue {
    selectedLocationId: string;
    setSelectedLocationId: React.Dispatch<React.SetStateAction<string>>;
    owningBusinessId: string;
    setOwningBusinessId: React.Dispatch<React.SetStateAction<string>>;
    oauthAccountId: string;
    setOAuthAccountId: React.Dispatch<React.SetStateAction<string>>;
    partnerName: AccountPartnerName;
    setPartnerName: React.Dispatch<React.SetStateAction<AccountPartnerName>>;
    displayImportModal: boolean;
    setDisplayImportModal: React.Dispatch<React.SetStateAction<boolean>>;
    showConnectionModal: boolean;
    setShowConnectionModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface PartnerConnectionProviderProps {
    children: ReactNode;
}

const PartnerConnectionContext = createContext<PartnerConnectionContextValue>({
    selectedLocationId: '',
    setSelectedLocationId: () => {},
    owningBusinessId: '',
    setOwningBusinessId: () => {},
    oauthAccountId: '',
    setOAuthAccountId: () => {},
    partnerName: 'google',
    setPartnerName: () => {},
    displayImportModal: false,
    setDisplayImportModal: () => {},
    showConnectionModal: false,
    setShowConnectionModal: () => {},
});

export const PartnerConnectionProvider: React.FC<PartnerConnectionProviderProps> = ({
    children,
}) => {
    const [selectedLocationId, setSelectedLocationId] = useState<string>('');
    const [owningBusinessId, setOwningBusinessId] = useState<string>('');
    const [oauthAccountId, setOAuthAccountId] = useState<string>('');
    const [partnerName, setPartnerName] = useState<AccountPartnerName>('google');
    const [displayImportModal, setDisplayImportModal] = useState<boolean>(false);
    const [showConnectionModal, setShowConnectionModal] = useState<boolean>(false);

    const ctxValue = useMemo(
        () => ({
            selectedLocationId,
            setSelectedLocationId,
            owningBusinessId,
            setOwningBusinessId,
            oauthAccountId,
            setOAuthAccountId,
            partnerName,
            setPartnerName,
            displayImportModal,
            setDisplayImportModal,
            showConnectionModal,
            setShowConnectionModal,
        }),
        [
            selectedLocationId,
            owningBusinessId,
            oauthAccountId,
            partnerName,
            displayImportModal,
            showConnectionModal,
            setShowConnectionModal,
        ],
    );

    return (
        <PartnerConnectionContext.Provider value={ctxValue}>
            {children}
        </PartnerConnectionContext.Provider>
    );
};

export const usePartnerConnectionContext = () => {
    const context = useContext(PartnerConnectionContext);

    if (!context) {
        throw new Error('Context must be used within a PartnerConnectionProvider');
    }

    return context;
};
