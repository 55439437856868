import { camelCase } from 'lodash-es';

import { ObjectOrArray } from 'app/common/types';

const formatObjectKeysToCamelCase = (obj: ObjectOrArray): Record<string, any> => {
    if (Array.isArray(obj)) {
        return obj.map(formatObjectKeysToCamelCase);
    }

    if (typeof obj === 'object') {
        return Object.keys(obj).reduce(
            (formattedObj, key) => ({
                ...formattedObj,
                [camelCase(key)]: obj[key] && formatObjectKeysToCamelCase(obj[key]),
            }),
            {},
        );
    }

    return obj;
};

export default formatObjectKeysToCamelCase;
