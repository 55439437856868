import { UseQueryOptions, useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { KEYWORDS_ANALYTICS } from 'app/common/data/queryKeysConstants';

export default function useKeywordsAnalytics(
    params: Record<string, any>,
    options?: UseQueryOptions<Record<string, any>>,
) {
    // TODO BIRD: type the queryParams and the return value

    return useQuery(
        [KEYWORDS_ANALYTICS, { params }],
        () => api.presenceAnalytics.getKeywordsAnalytics(params),
        options,
    );
}
