import {
    BusinessImportFieldsType,
    BusinessInfosImportType,
    FormattedBusinessImportFieldsType,
    FormattedBusinessInfosImportType,
} from 'app/api/types/business';

const businessInfosImportFormatter = (
    businessImportInfos: BusinessInfosImportType,
): FormattedBusinessInfosImportType => ({
    name: businessImportInfos.name,
    code: businessImportInfos.code,
    categories: businessImportInfos.categories,
    address_full: businessImportInfos.addressFull,
    coordinates: businessImportInfos.coordinates,
    description: businessImportInfos.description,
    phone: businessImportInfos.phone,
    website: businessImportInfos.website,
    open_hours: businessImportInfos.openHours,
    more_hours: businessImportInfos.moreHours,
    specific_hours: businessImportInfos.specificHours,
    opening_date: businessImportInfos.openingDate,
    additional_urls: businessImportInfos.additionalUrls,
});

const businessImportFormatter = (
    businessImportFields: BusinessImportFieldsType,
    isBulkImport: boolean,
    isQuickImport: boolean,
): FormattedBusinessImportFieldsType => ({
    business_infos: businessInfosImportFormatter(businessImportFields.businessInfos),
    attributes: businessImportFields.attributes,
    services: businessImportFields.services,
    google_posts: businessImportFields.googlePosts,
    food_menu: businessImportFields.foodMenu,
    photos: businessImportFields.photos,
    is_bulk_import: isBulkImport,
    is_quick_import: isQuickImport,
});

export default businessImportFormatter;
