import { combineReducers } from 'redux';

import page, { PartnerConnectionState } from './connection';
import stats, { ConnectionsStatsState } from './connectionsStats';
import nav, { ConnectionNavigationState } from './nav';

export type BusinessConnectionState = {
    stats: ConnectionsStatsState;
    nav: ConnectionNavigationState;
    page: PartnerConnectionState;
};

export default combineReducers({
    stats,
    nav,
    page,
});
