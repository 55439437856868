import { useState } from 'react';

import { Button, FontAwesomeIconsPartooUsed, IconPrefix, Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { BusinessMoreHoursType, MoreHourValue, MoreHoursType } from 'app/api/types/more_hours';

import AddMoreHourModal from './AddMoreHourModal';
import MoreHour from './MoreHour/MoreHour';
import { MoreHoursList } from './MoreHour/MoreHour.styled';
import { MoreHoursSectionOpenModalButton } from './MoreHoursSection.styled';

type Props = {
    moreHours: MoreHoursType;
    businessMoreHours: BusinessMoreHoursType;
    onChange: (moreHours: BusinessMoreHoursType) => void;
    errors: Record<string, any>;
    disabled: boolean;
};

const MoreHours = ({ moreHours, businessMoreHours, onChange, errors, disabled }: Props) => {
    const { t } = useTranslation();
    const moreHoursToDisplay = businessMoreHours
        .filter(moreHour => moreHour.value)
        .map(moreHour => ({
            ...moreHour,
            name: moreHours.find(el => el.gmb_id === moreHour.gmb_id)?.name ?? '',
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const moreHoursForModal = moreHours.filter(
        moreHour =>
            !moreHoursToDisplay.find(
                moreHourBusiness => moreHourBusiness.gmb_id === moreHour.gmb_id,
            ),
    );

    const onMoreHoursChanged = (gmbId: string, value: MoreHourValue) => {
        onChange(
            businessMoreHours.map(moreHour => {
                if (moreHour.gmb_id === gmbId) {
                    return {
                        ...moreHour,
                        value,
                    };
                }
                return moreHour;
            }),
        );
    };

    const addMoreHour = (gmbId: string) => {
        onMoreHoursChanged(gmbId, {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
        });
        setShowModal(false);
        setOpened({ [gmbId]: true });
    };

    const [showModal, setShowModal] = useState<boolean>(false);
    const [opened, setOpened] = useState<Record<string, boolean | undefined>>({});

    return (
        <div>
            {!disabled && (
                <MoreHoursSectionOpenModalButton>
                    {moreHoursForModal.length > 0 && (
                        <Button
                            icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                            onClick={() => setShowModal(true)}
                            disabled={disabled}
                            appearance="outlined"
                            shape="cube"
                            variant="primary"
                            size="medium"
                            dataTrackId="edit_business__more_hours__open_more_hours_modal"
                        >
                            {t('add_business_more_hours_button')}
                        </Button>
                    )}
                </MoreHoursSectionOpenModalButton>
            )}
            <MoreHoursList>
                {moreHoursToDisplay.length > 0 &&
                    moreHoursToDisplay.map(moreHour => (
                        <MoreHour
                            key={`more_hour_${moreHour.gmb_id}`}
                            moreHour={moreHour}
                            onChange={value => onMoreHoursChanged(moreHour.gmb_id, value)}
                            opened={opened}
                            setOpened={setOpened}
                            errors={errors[moreHour.gmb_id] ?? {}}
                            disabled={disabled}
                        />
                    ))}
            </MoreHoursList>
            <Modal isOpen={showModal} closeModal={() => setShowModal(false)}>
                <AddMoreHourModal moreHours={moreHoursForModal} onClick={addMoreHour} />
            </Modal>
        </div>
    );
};

export default MoreHours;
