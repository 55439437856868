import { Text } from '@partoohub/ui';

import LoadingSpinner from 'app/common/components/loaders/LoadingSpinner';
import {
    AnalyticsBoxTextStatStyled,
    ValueStyled,
} from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/analyticsCards/AnalyticsBoxTextStat.styled';

type Props = {
    analyticStat: string;
    analyticColor: 'blueberry' | 'warning';
    analyticText: string;
    isSmall: boolean;
    isLoading: boolean;
};

const AnalyticsBoxTextStat = ({
    analyticStat,
    analyticColor,
    analyticText,
    isSmall,
    isLoading,
}: Props) => (
    <AnalyticsBoxTextStatStyled>
        <Text variant="bodyMBold" as="span" color={'secondary'}>
            {analyticText}
        </Text>
        {isLoading ? (
            <LoadingSpinner fontSize="34px" />
        ) : (
            <ValueStyled isSmall={isSmall} className={`color--${analyticColor}`}>
                {analyticStat}
            </ValueStyled>
        )}
    </AnalyticsBoxTextStatStyled>
);

export default AnalyticsBoxTextStat;
