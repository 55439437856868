import { Buffer } from 'buffer';

type BodyRequest = {
    edits?: Partial<{
        resize: {
            width: number;
            height: number;
            fit: string;
            background?: {
                r: number;
                g: number;
                b: number;
                alpha: number;
            };
        };
        flatten?: boolean;
        grayscale?: boolean;
        flip?: boolean;
        flop?: boolean;
        negate?: boolean;
        normalise?: boolean;
        tint?: {
            r: number;
            g: number;
            b: number;
        };
        smartCrop?: {
            faceIndex: number;
            padding: number;
        };
    }>;
};

type ImageData = {
    bucket: string;
    key: string;
};

const BUCKET_NAME_SUPPORTED = ['partoo-business-photos-test', 'partoo-media'];
const BUCKET_REGEX = /\.s3\.amazonaws\.com/i;

const CLOUDFRONT_DNS = 'https://image-resize.partoo.co';

export const getMappedUrl = (url: string, mapping?: Record<string, string | undefined>): string => {
    if (!mapping) {
        return url;
    }

    return mapping[url] ?? url;
};

const parseImageUrl = (urlString: string): ImageData | null => {
    try {
        const decodedURI = decodeURIComponent(urlString);
        if (!decodedURI) return null;
        const parsedUrl = new URL(decodedURI);
        const bucket = parsedUrl.host?.replace(BUCKET_REGEX, '') ?? '';
        const key = parsedUrl.pathname?.replace(/^\//, '') ?? '';
        const imageExtensions = ['.png', '.jpeg', '.jpg'];
        if (
            !bucket ||
            !BUCKET_NAME_SUPPORTED.includes(bucket) ||
            !imageExtensions.some(ext => key.toLowerCase().endsWith(ext))
        ) {
            return null;
        }
        return { bucket, key };
    } catch (error) {
        return null;
    }
};

/**
 * Description:
 * The "resizeImage()" function takes in two parameters, "bodyRequest" and "image".
 * It parses the image URL using "parseImageUrl()" function
 * and merges the parsed image object with the "bodyRequest" object.
 * It then encodes the resulting JSON object to base64
 * and returns a new URL by concatenating the encoded string with a predefined "CLOUDFRONT_DNS" URL.
 * Currently we only support these buckets 'partoo-business-photos-test', 'partoo-media'.
 * If you want add a new one, please add it inside BUCKET_NAME_SUPPORTED.
 *
 * @param bodyRequest This is an object containing the parameters needed to resize the image.
 * @param image This is a string containing the URL of the image to be resized.
 */
const resizeImageRequest = (bodyRequest: BodyRequest, image: string): string => {
    const object = parseImageUrl(image);

    if (!object) return image;
    const json = JSON.stringify({ ...object, ...bodyRequest });
    const base64 = Buffer.from(json).toString('base64');

    return `${CLOUDFRONT_DNS}/${base64}`;
};

export const resizeImage = (image: string, width = 1920, height = 1080, fit = 'inside'): string => {
    const bodyRequest = {
        edits: {
            resize: {
                width: width,
                height: height,
                fit: fit,
            },
        },
    };

    return resizeImageRequest(bodyRequest, image);
};
