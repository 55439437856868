import styled from '@emotion/styled';

export const ConsumerImageLoader = styled.div`
    margin-right: 10px;
    margin-bottom: 10px;
`;

export const ConsumerNameLoader = styled.div`
    margin-top: 6px;
`;

export const BusinessNameLoader = styled.div`
    margin-top: 13px;
`;

export const LastMessageLoader = styled.div`
    margin-top: 4px;
    margin-bottom: 4px;
`;
