import React, { FC, ReactElement, useEffect, useMemo, useRef } from 'react';

import { debounce } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router-dom';

interface ScrollSpyContextType {
    register: (id: string, ref: HTMLElement) => void;
    unregister: (id: string) => void;
    refs: Map<string, HTMLElement>;
    scrollToRef: (e: React.MouseEvent, ref?: HTMLElement) => void;
}

// Disable the scrollHandler when a ScrollSpyClick is clicked
let disabledHandleScroll = false;
const activateScroll = debounce(() => {
    disabledHandleScroll = false;
}, 250);

export const ScrollSpyContext = React.createContext<ScrollSpyContextType>(
    null as any,
);

type Props = {
    children: ReactElement;
    offset?: number;
};

/**
 * Provider that handle all the refs registered
 * On the child scroll, update the url hash to the closest ref
 */
const ScrollSpyProvider: FC<Props> = ({ children, offset = 8 }) => {
    const containerRef = useRef<HTMLElement>(null);
    const refs = useRef<Map<string, HTMLElement>>(new Map());
    const navigate = useNavigate();
    const { hash } = useLocation();

    // Scroll to the ref, set the fragment in the url and temporarily disable the scrollHandler
    const scrollToRef = (e: React.MouseEvent, ref: HTMLElement) => {
        e.preventDefault();
        if (ref) {
            disabledHandleScroll = true;
            navigate({ hash: `#${ref.id}` }, { replace: true });
            ref.scrollIntoView({ behavior: 'smooth' });
            activateScroll();
        }
    };

    const value = useMemo(
        () => ({
            register: (id: string, r: HTMLElement) => {
                refs.current.set(id, r);
            },
            unregister: id => {
                refs.current.delete(id);
            },
            refs: refs.current,
            scrollToRef,
        }),
        [refs],
    );

    const setUrlHashToClosestScrollSpyLinkId = () => {
        // Keep the scrollHandler disabled while it's scrolling
        if (disabledHandleScroll) activateScroll();

        if (containerRef.current !== null && !disabledHandleScroll) {
            const containerY = containerRef.current.getBoundingClientRect().y;

            let rightAbove: HTMLElement | null = null;
            let greatestNegative = -Infinity;
            let rightBelow: HTMLElement | null = null;
            let smallestPositive = Infinity;
            for (const [, r] of refs.current) {
                const dist = r.getBoundingClientRect().y - containerY;
                if (dist <= offset && dist > greatestNegative) {
                    rightAbove = r;
                    greatestNegative = dist;
                } else if (dist > offset && dist < smallestPositive) {
                    rightBelow = r;
                    smallestPositive = dist;
                }
            }
            const current = rightAbove || rightBelow;

            if (current?.id && decodeURIComponent(hash) !== `#${current.id}`) {
                navigate({ hash: `#${current.id}` }, { replace: true });
            }
        }
    };

    useEffect(() => {
        if (containerRef.current !== null) {
            containerRef.current.addEventListener(
                'scroll',
                setUrlHashToClosestScrollSpyLinkId,
            );
        }
        return () => {
            if (containerRef.current !== null) {
                containerRef.current.removeEventListener(
                    'scroll',
                    setUrlHashToClosestScrollSpyLinkId,
                );
            }
        };
    }, [hash]);

    return (
        <ScrollSpyContext.Provider value={value}>
            {React.cloneElement(children, { ref: containerRef })}
        </ScrollSpyContext.Provider>
    );
};

export default ScrollSpyProvider;
