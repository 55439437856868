import { useEffect, useMemo, useRef, useState } from 'react';

import { FontAwesomeIconsPartooUsed, LeftElementType, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { Choice } from 'app/api/types/user';
import {
    PublisherErrors,
    PublishersInfo,
} from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithPublisherList';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import { ExplanationExtended } from '../ExplanationComponent/ExplanationComponent';

export type Props = {
    dataTrackId: string;
    onChange: (value?: string) => void;
    onZipcodeChange: (value: string) => void;
    onCityChange: (value: string) => void;
    onCountryChange: (option: Choice | null) => void;
    onLatLongChange: (lat: number, long: number) => void;
    value: string;
    disabled?: boolean;
    textTooltip?: string;
    isMandatory?: boolean;
    errorMessage?: string;
    hasError?: boolean;
    hasWarning?: boolean;
    publisherErrors?: PublisherErrors;
    publishersInfo?: PublishersInfo;
};

const options = {
    types: ['address'],
    fields: ['address_components', 'formatted_address', 'name', 'geometry'],
};

export const AddressFullInput = ({
    dataTrackId = 'address',
    value,
    onChange,
    onZipcodeChange,
    onCityChange,
    onCountryChange,
    onLatLongChange,
    isMandatory = false,
    disabled = false,
    textTooltip,
    errorMessage,
    publisherErrors = {},
    publishersInfo = {},
    hasError,
    hasWarning,
}: Props) => {
    const { t } = useTranslation();
    const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    // State to manage the loading of the Google Maps script
    const [mapsApiLoaded, setMapsApiLoaded] = useState(false);

    const handleScrollFixSuggestionPosition = () => {
        const autocompleteDropdowns = document.querySelectorAll('.pac-container');
        autocompleteDropdowns.forEach(dropdown => {
            const el = dropdown as HTMLDivElement;
            el.style.minWidth = '295px';
            el.style.marginLeft = '-40px';
            el.style.marginTop = '16px';
        });
    };

    useEffect(() => {
        // Function to load the Google Maps API
        const checkMapsApi = () => {
            if (window?.google?.maps?.places) {
                setMapsApiLoaded(true);
            } else {
                setTimeout(checkMapsApi, 500); // Retry every 500ms
            }
        };

        checkMapsApi();
    }, []);

    useEffect(() => {
        const handlePlaceSelect = () => {
            const place = autoCompleteRef.current?.getPlace();
            if (!place?.geometry) return;

            onChange(place.name);
            const components = place.address_components || [];
            let zipcode = '',
                city = '',
                country: Choice | null = null;

            components.forEach((component: google.maps.GeocoderAddressComponent) => {
                const componentType = component.types[0];
                if (componentType === 'postal_code') zipcode = component.long_name;
                else if (componentType === 'locality') city = component.long_name;
                else if (componentType === 'country')
                    country = { label: component.long_name, value: component.short_name };
            });

            onZipcodeChange(zipcode);
            onCityChange(city);
            onCountryChange(country);
            if (place.geometry.location) {
                onLatLongChange(place.geometry.location.lat(), place.geometry.location.lng());
            }
        };

        if (mapsApiLoaded && !autoCompleteRef.current && inputRef.current) {
            autoCompleteRef.current = new google.maps.places.Autocomplete(
                inputRef.current,
                options,
            );
            autoCompleteRef.current.addListener('place_changed', handlePlaceSelect);
            inputRef.current.addEventListener('focus', handleScrollFixSuggestionPosition);
        }

        const resizeObserver = new ResizeObserver(handleScrollFixSuggestionPosition);
        if (inputRef.current) {
            resizeObserver.observe(inputRef.current);
        }

        window.addEventListener('resize', handleScrollFixSuggestionPosition);

        return () => {
            if (inputRef.current) {
                resizeObserver.unobserve(inputRef.current);
            }
            window.removeEventListener('resize', handleScrollFixSuggestionPosition);
        };
    }, [handleScrollFixSuggestionPosition, autoCompleteRef, inputRef, mapsApiLoaded]);

    const ExplanationComponent = useMemo(
        () => (
            <ExplanationExtended
                hasWarning={!!hasWarning}
                publisherErrors={publisherErrors}
                publishersInfo={publishersInfo}
                errorMessage={errorMessage}
                disabled={disabled}
            />
        ),
        [hasWarning, publisherErrors, publishersInfo, errorMessage, disabled],
    );

    const InputComponent = (
        <TextInput
            dataTrackId={dataTrackId ?? 'address'}
            ref={inputRef}
            value={value}
            label={t('address')}
            onChange={val => onChange(val || '')}
            required={isMandatory}
            disabled={disabled}
            leftElement={[FontAwesomeIconsPartooUsed.faSearch]}
            leftElementType={LeftElementType.Icon}
            error={hasError}
            notice={ExplanationComponent}
            warning={hasWarning}
            hasOldTextFieldHeight
        />
    );

    return (
        <>
            {textTooltip ? (
                <TooltipWrapper text={textTooltip} position="bottom-start">
                    <div style={{ flex: 1 }}>{InputComponent}</div>
                </TooltipWrapper>
            ) : (
                InputComponent
            )}
        </>
    );
};
