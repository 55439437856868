const MINUTES_IN_AN_HOUR = 60;
const MINUTES_IN_A_DAY = 24 * MINUTES_IN_AN_HOUR;
const MINUTES_IN_A_MONTH = 30 * MINUTES_IN_A_DAY;
const MINUTES_IN_A_YEAR = 365 * MINUTES_IN_A_DAY;

type TimeUnitPair = {
    translationKey: string;
    condition?: boolean;
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
};

type TimeUnit = {
    isNegative: boolean;
    years: number;
    months: number;
    days: number;
    hours: number;
    minutes: number;
};

export const formatResponseTime = (inputMinutes: number): TimeUnit => {
    const isNegative = inputMinutes < 0;
    const absMinutes = Math.abs(inputMinutes);

    // Calculate time components
    const years = Math.floor(absMinutes / MINUTES_IN_A_YEAR);
    const remainingAfterYears = absMinutes % MINUTES_IN_A_YEAR;

    const months = Math.floor(remainingAfterYears / MINUTES_IN_A_MONTH);
    const remainingAfterMonths = remainingAfterYears % MINUTES_IN_A_MONTH;

    const days = Math.floor(remainingAfterMonths / MINUTES_IN_A_DAY);
    const remainingAfterDays = remainingAfterMonths % MINUTES_IN_A_DAY;

    const hours = Math.floor(remainingAfterDays / MINUTES_IN_AN_HOUR);
    const minutes = Math.floor(remainingAfterDays % MINUTES_IN_AN_HOUR);

    return {
        isNegative,
        years,
        months,
        days,
        hours,
        minutes,
    };
};

export const getResponseTimeTranslation = ({
    years,
    months,
    days,
    hours,
    minutes,
    isNegative,
}: TimeUnit): TimeUnitPair => {
    // Define pairs of time units and their conditions
    const pairs: TimeUnitPair[] = [
        {
            translationKey:
                months > 0
                    ? 'homepage_stats_messages_response_time_years_months'
                    : 'homepage_stats_messages_response_time_years',
            years: isNegative ? -years : years,
            ...(months > 0 ? { months: months } : {}),
            condition: years > 0,
        },
        {
            translationKey:
                days > 0
                    ? 'homepage_stats_messages_response_time_months_days'
                    : 'homepage_stats_messages_response_time_months',
            months: isNegative ? -months : months,
            ...(days > 0 ? { days: days } : {}),
            condition: months > 0,
        },
        {
            translationKey:
                hours > 0
                    ? 'homepage_stats_messages_response_time_days_hours'
                    : 'homepage_stats_messages_response_time_days',
            days: isNegative ? -days : days,
            ...(hours > 0 ? { hours: hours } : {}),
            condition: days > 0,
        },
        {
            translationKey:
                minutes > 0
                    ? 'homepage_stats_messages_response_time_hours_minutes'
                    : 'homepage_stats_messages_response_time_hours',
            hours: isNegative ? -hours : hours,
            ...(minutes > 0 ? { minutes: minutes } : {}),
            condition: hours > 0,
        },
        {
            translationKey: 'homepage_stats_messages_response_time_minutes',
            minutes: isNegative ? -minutes : minutes,
            condition: hours === 0 && minutes >= 0,
        },
    ];

    // Find the pair with the first true condition
    const selectedPair = pairs.find(pair => pair.condition) ?? {
        translationKey: 'homepage_stats_messages_response_time_minutes',
        minutes: 0,
    };

    // Return the selected pair object without the condition field
    const { condition, ...result } = selectedPair;

    return result;
};

export const calculateResponseTimeEvolution = (from: number, to: number): number => {
    if (from === 0) {
        return 0;
    }

    const diffInMinutes = to - from;
    const result = Math.round(diffInMinutes);

    // Handle edge cases
    if (isNaN(result) || result === Infinity || result === -Infinity) {
        return 0;
    }

    return result;
};
