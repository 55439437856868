import { useEffect, useState } from 'react';

import { DatePickerType, RangeDate } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { LocaleDatePicker } from 'app/common/components/LocaleDatePicker/LocaleDatePicker';
import { getMissingMonthsFromBoundaries } from 'app/pages/analytics/CompetitiveBenchmark/utils/getMissingMonthsFromBoundaries';

type Props = {
    availableDates: Date[];
    currentDate: Date;
    setCurrentDate: (date: Date) => void;
};

export const MonthPicker = ({ availableDates, currentDate, setCurrentDate }: Props) => {
    const { t } = useTranslation();
    const [dates, setDates] = useState<RangeDate>([currentDate, null]);

    useEffect(() => {
        if (dates[0]) setCurrentDate(dates[0]);
    }, [dates]);

    const onChangeDate = (newDates: RangeDate | ((prevState: RangeDate) => RangeDate)) => {
        if (Array.isArray(newDates) && newDates[0] === null) {
            newDates[0] = availableDates[availableDates.length - 1];
        }
        setDates(newDates);
    };

    return (
        <LocaleDatePicker
            dataTrackId="page_competitive_benchmark__select_month"
            type={DatePickerType.MonthSelector}
            format={{
                weekday: undefined,
                year: 'numeric',
                month: 'long',
            }}
            dates={dates}
            minDate={new Date(availableDates[0])}
            maxDate={new Date(availableDates[availableDates.length - 1])}
            onChange={onChangeDate}
            excludeDates={getMissingMonthsFromBoundaries(availableDates)}
            labels={{ startDate: t('select_month') }}
            placeholders={{ startDate: t('select_month') }}
            isReset={false}
        />
    );
};
