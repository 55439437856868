import React, { ReactElement, useEffect, useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Expandable, FontAwesomeIconsPartooUsed, IconElement } from '@partoohub/ui';

import {
    ChatbotSectionChevronIcon,
    ChatbotSectionContainer,
    ChatbotSectionExpandableContent,
    ChatbotSectionHeader,
    ChatbotSectionHeaderCheck,
    ChatbotSectionHeaderLeft,
    ChatbotSectionHeaderTitle,
} from 'app/pages/settings/Chatbot/components/PageContent/ChatbotSection.styled';
import { useChatbot } from 'app/pages/settings/Chatbot/hooks/useChatbot';

interface Props {
    children: ReactElement;
    title: string;
    onClick: () => void;
    isOpenedId: string;
    sectionId: string;
    icon?: IconElement;
    isCompleted: boolean;
}

export const ChatbotSection = ({
    children,
    title,
    onClick,
    isOpenedId,
    sectionId,
    icon = [FontAwesomeIconsPartooUsed.faCheck],
    isCompleted,
}: Props) => {
    const [hasSucceeded, setHasSucceeded] = useState(isCompleted ?? false);
    const isOpened = isOpenedId === sectionId;
    const chatbot = useChatbot();

    useEffect(() => {
        setHasSucceeded(isCompleted);
    }, [isCompleted]);

    return (
        <ChatbotSectionContainer isOpened={isOpened}>
            <ChatbotSectionHeader onClick={onClick}>
                <ChatbotSectionHeaderLeft>
                    <ChatbotSectionHeaderCheck
                        icon={hasSucceeded ? [FontAwesomeIconsPartooUsed.faCheck] : icon}
                        color={hasSucceeded ? HEX_COLORS.success : '#1425421F'}
                        hasSucceeded={hasSucceeded}
                    />
                    <ChatbotSectionHeaderTitle variant="heading6">
                        {title}
                    </ChatbotSectionHeaderTitle>
                </ChatbotSectionHeaderLeft>
                <ChatbotSectionChevronIcon
                    icon={[FontAwesomeIconsPartooUsed.faChevronRight]}
                    isOpened={isOpened}
                    color="secondary"
                />
            </ChatbotSectionHeader>
            <Expandable opened={isOpened}>
                <ChatbotSectionExpandableContent>
                    {chatbot.data &&
                        React.cloneElement(children, { setHasSucceeded: setHasSucceeded })}
                </ChatbotSectionExpandableContent>
            </Expandable>
        </ChatbotSectionContainer>
    );
};
