import { Button, Modal, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { ResponsiveHeaderBanner } from 'app/common/components/ResponsiveSettingsModal/ResponsiveHeaderBanner/ResponsiveHeaderBanner';
import {
    EditSettingsContainer,
    EditSettingsItem,
    ResponsiveBody,
    ResponsiveFooter,
    ResponsiveHeader,
    ResponsiveModalContainer,
} from 'app/common/components/ResponsiveSettingsModal/ResponsiveSettingsModal.styled';

type Props = {
    showModal: boolean;
    closeModal: () => void;
};

export const ResponsiveSettingsModal = ({ showModal, closeModal }: Props) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={showModal} closeModal={closeModal} hideOnClickOutside={false}>
            <ResponsiveModalContainer>
                <ResponsiveHeader>
                    <ResponsiveHeaderBanner />
                </ResponsiveHeader>
                <ResponsiveBody>
                    <Text variant="heading4" as="h4">
                        {t('web_banner_degraded_mode_small_devices_modal_title')}
                    </Text>
                    <Text variant="bodyLRegular" as="p" color={'secondary'}>
                        {t('web_banner_degraded_mode_small_devices_modal_desc')}
                    </Text>
                    <EditSettingsContainer>
                        <EditSettingsItem>
                            <i className="fa-solid fa-circle-check"></i>
                            <Text variant="bodyLRegular" as="p" color={'secondary'}>
                                {t('web_banner_degraded_mode_small_devices_modal_steps_go')}
                            </Text>
                        </EditSettingsItem>
                        <EditSettingsItem>
                            <i className="fa-solid fa-circle-check"></i>
                            <Text variant="bodyLRegular" as="p" color={'secondary'}>
                                {t('web_banner_degraded_mode_small_devices_modal_steps_set')}
                            </Text>
                        </EditSettingsItem>
                    </EditSettingsContainer>
                </ResponsiveBody>
                <ResponsiveFooter>
                    <Button
                        dataTrackId="web_banner_degraded_mode_small_devices_modal_confirm__button"
                        onClick={closeModal}
                        full
                    >
                        {t('web_banner_degraded_mode_small_devices_modal_confirm_button')}
                    </Button>
                </ResponsiveFooter>
            </ResponsiveModalContainer>
        </Modal>
    );
};
