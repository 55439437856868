import { DatePickerType, OverrideDatePicker, RangeDate } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useLocaleDatepicker } from 'app/common/hooks/useLocaleDatepicker';
import { getMissingMonthsFromBoundaries } from 'app/pages/analytics/CompetitiveBenchmark/utils/getMissingMonthsFromBoundaries';

import { OverrideDatePickerContainer } from './MonthPicker.styled';
import { useDateFilter } from '../../hooks/useDateFilter';
import { useKeywordFilter } from '../../hooks/useKeywordFilter';

export const MonthPicker = () => {
    const { t } = useTranslation();
    const locale = useLocaleDatepicker();
    const [currentDate, setCurrentDate] = useDateFilter();
    const [keyword] = useKeywordFilter();
    const onChangeDate = (newDates: RangeDate | ((prevState: RangeDate) => RangeDate)) => {
        if (Array.isArray(newDates) && newDates[0] !== null) {
            setCurrentDate(newDates[0]);
        }
    };

    return (
        <OverrideDatePickerContainer>
            <OverrideDatePicker
                dataTrackId="page_competitive_benchmark__select_month"
                format={{
                    weekday: undefined,
                    year: 'numeric',
                    month: 'long',
                }}
                localeDatePicker={locale}
                type={DatePickerType.MonthSelector}
                dates={[currentDate, null]}
                onChange={onChangeDate}
                labels={{ startDate: t('select_month') }}
                placeholders={{ startDate: t('select_month') }}
                isReset={false}
                minDate={keyword.dates[0]}
                maxDate={keyword.dates[keyword.dates.length - 1]}
                excludeDates={getMissingMonthsFromBoundaries(keyword.dates)}
            />
        </OverrideDatePickerContainer>
    );
};
