import styled from '@emotion/styled';
import { Button } from '@partoohub/ui';

import { StyledPartnerCommentSeparator } from './CommentIcon.styled';
import { REVIEW_CARD_AVATAR_WIDTH } from '../../ReviewCardHeader/ReviewCardAvatar';

export const PARTNER_LOGO_WIDTH_SMALL = 25;

export const StyledComment = styled.div`
    display: grid;
    flex-flow: row nowrap;
    grid-template-columns: 40px auto;
    grid-gap: 8px;
    margin: auto 0;
`;

export const StyledUserLogo = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: ${REVIEW_CARD_AVATAR_WIDTH}px;
`;

export const StyledButton = styled(Button)`
    margin-right: auto;
    margin-top: 8px;
`;
export const StyledCommentBottomSpacing = styled.div`
    margin-bottom: 4px;
`;

export const StyledSecondaryLevelComments = styled.div`
    margin-top: 8px;

    ${StyledCommentBottomSpacing} {
        margin-bottom: 16px;
    }

    ${StyledUserLogo} {
        width: ${PARTNER_LOGO_WIDTH_SMALL}px;
        img {
            height: ${PARTNER_LOGO_WIDTH_SMALL}px;
        }
    }

    ${StyledPartnerCommentSeparator} {
        width: ${PARTNER_LOGO_WIDTH_SMALL + 8}px;
    }
`;

export const StyledCommentAnswerButton = styled.div`
    padding-bottom: 8px;
`;
