import styled from '@emotion/styled';

export const ContentLoaderContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    > svg {
        width: 50px;
        height: 50px;
    }
`;
