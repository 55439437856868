import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import { Item } from 'app/pages/Homepage/utils/typing';

import { useAllTodosItems } from './useAllTodosItems';

const MAX_TODOS_ITEMS = 4;

export const useTodosItems = (): Array<Item<SmallTodoProps>> => {
    const allShortcutsItems = useAllTodosItems();
    return allShortcutsItems
        .filter(item => item.isAvailable)
        .sort((a, b) => a.order - b.order)
        .slice(0, MAX_TODOS_ITEMS);
};
