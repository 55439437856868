import { useMutation } from 'react-query';

import { UpdateGroupParams } from 'app/api/types/groups';
import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { GROUPS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

export default function useGroupUpdate(
    groupId: number,
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    return useMutation(
        (groupData: UpdateGroupParams) => api.groups.updateGroup(groupData, groupId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(invalidatesAny(GROUPS)).then(onSuccess);
            },
            onError,
        },
    );
}
