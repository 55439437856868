import { RadialBar, Text } from '@partoohub/ui';

import { Trans, useTranslation } from 'react-i18next';

import { sumValues } from 'app/common/utils/sumValues';

import { StyledCard } from './Cards.styled';
import { StyledCardContent } from './ReviewReply.styled';
import { useReviewTotalMetrics } from '../../../hooks/useMetrics';
import { useReviewAnalyticsDefaultFilter } from '../../../hooks/useReviewAnalyticsDefaultFilter';

export const ReviewReplyCard = () => {
    const { t } = useTranslation();
    const metrics = useReviewTotalMetrics();
    const { isDefaultDateFilter } = useReviewAnalyticsDefaultFilter();

    const reviewsCount =
        metrics?.data
            .map(d => sumValues(d.metrics.reply_time))
            .reduce((prev, curr) => prev + curr, 0) ?? 0;

    const reviewsReplied =
        reviewsCount -
        (metrics?.data
            .map(d => d.metrics.reply_time.not_replied)
            .reduce((prev, curr) => prev + curr, 0) ?? 0);

    const reviewsRepliedFast =
        metrics?.data.map(d => d.metrics.reply_time.fast).reduce((prev, curr) => prev + curr, 0) ??
        0;

    return (
        <StyledCard>
            <Text variant="bodyMBold">
                {t('review_analytics_graph_section_review_reply')}{' '}
                {isDefaultDateFilter && (
                    <Text as="span" color="secondary">
                        {' '}
                        ({t('date_criteria_last_twelve_months')})
                    </Text>
                )}
            </Text>
            <StyledCardContent>
                <RadialBar
                    value={reviewsCount ? Math.round((100 * reviewsReplied) / reviewsCount) : 0}
                >
                    <Text color="secondary">
                        <Trans
                            t={t}
                            i18nKey={'review_analytics_card_review_reply_fast_percentage'}
                            components={{
                                b: <Text as="span" variant="bodyMBold" color="secondary" />,
                            }}
                            values={{
                                percentage: reviewsCount
                                    ? Math.round((100 * reviewsRepliedFast) / reviewsCount)
                                    : 0,
                            }}
                        />
                    </Text>
                </RadialBar>
            </StyledCardContent>
        </StyledCard>
    );
};
