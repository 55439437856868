import { useMutation } from 'react-query';

import { Chatbots } from 'app/api/v2/api_calls/chatbotsApiCalls';
import { CHATBOT, CHATBOTS } from 'app/common/data/queryKeysConstants';
import { useChatbot } from 'app/pages/settings/Chatbot/hooks/useChatbot';
import { ChatbotKnowledgeType } from 'app/pages/settings/Chatbot/type/chatbot';
import queryClient from 'app/states/queryClient';

export const useChatbotKnowledgeUpdate = () => {
    const { data: chatbotData } = useChatbot();
    return useMutation(
        (knowledge: ChatbotKnowledgeType) =>
            Chatbots.patchChatbotKnowledge(knowledge, chatbotData?.id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([CHATBOTS]);
                queryClient.invalidateQueries([CHATBOT, chatbotData?.id]);
            },
        },
    );
};
