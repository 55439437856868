import React, { useCallback, useEffect, useRef, useState } from 'react';

import { IconButton } from '@partoohub/ui';

// TODO: Replace by InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { InputEvent } from 'app/api/types';
import { Choice } from 'app/api/types/user';

import {
    ButtonWithMenuContainer,
    ButtonWithMenuWrapper,
    FilterSelectionItem,
    FilterSelectionItemContainer,
    FilterSelectionItemLabel,
    FilterSelectionMenu,
    FilterSelectionMenuContainer,
    FilterSelectionTitle,
    FilterSelectionTitleContainer,
    FilterSelectionTitleIcon,
    ImageContainer,
    InputClickable,
    InputContainer,
    InputIcon,
    InputPlaceholder,
    InputWrapper,
    ItemEmptyPlaceholder,
    ItemsContainer,
    Loader,
    MenuIcon,
    MenuItemContainer,
    MenuItemIcon,
    MenuItemLabel,
    MenuItemWrapper,
    MenuItemsContainer,
    MenuTitle,
    MenuTitleContainer,
    MenuWrapper,
    MenuWrapperSelectorId,
} from './ButtonWithSearchMenuFilterSelection.styled';

export type Position = 'left' | 'right';

type Props = {
    options: Array<Choice>;
    selectedOptions: Array<Choice>;
    onChange: (arg0: Array<Choice> | null) => void;

    loadMore?: (arg0: string) => void;
    hasMore?: boolean;
    isLoading?: boolean;
    onChangePreselectedValue?: (value?: string | null) => void;
    disabled?: boolean;
    placeholder: string;
    menuTitle?: string;
    menuIcon?: JSX.Element;
    menuIconTooltip?: string;
    hasHeader: boolean;
    singleSelect?: boolean;
    loadMoreOnOpenMenu?: boolean;
    filtersOptions?: Array<Choice>;
    onFilterClick?: (value: string) => void;
    input?: JSX.Element;
    position?: Position;
    redirectButton?: JSX.Element;
    redirectButtonTooltip?: string;
    onRedirectButtonClick?: () => void;
    onOpen?: () => void;
    onClose?: () => void;
    className?: string;
    scrollOnOpen?: boolean;
};

const ButtonWithSearchMenuFilterSelection = ({
    options,
    onChange,
    selectedOptions,
    loadMore = () => undefined,
    hasMore = false,
    isLoading = false,
    disabled = false,
    onChangePreselectedValue,
    placeholder,
    hasHeader,
    singleSelect = true,
    loadMoreOnOpenMenu = false,
    menuTitle,
    menuIcon,
    menuIconTooltip,
    filtersOptions,
    onFilterClick,
    input,
    position = 'left',
    redirectButton = undefined,
    onRedirectButtonClick,
    redirectButtonTooltip,
    onOpen = () => undefined,
    onClose = () => undefined,
    className,
    scrollOnOpen = true,
}: Props) => {
    const { t } = useTranslation();
    const wrapperRef = useRef<HTMLInputElement>(null);
    const menuWrapperRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const isOpenRef = useRef(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const hiddenSpanRef = useRef<HTMLInputElement>(null);
    const [currentSearch, setCurrentSearch] = useState('');
    const [isLanguageMenu, setIsLanguageMenu] = useState(false);
    const [preSelectedValue, setPreSelectedValue] = useState('');

    const onButtonClick = useCallback(() => {
        if (!!redirectButton && !!onRedirectButtonClick) onRedirectButtonClick();
    }, [redirectButton, onRedirectButtonClick]);

    const buttonsRef: Array<HTMLButtonElement | null> = [];

    const toggleMenu = () => {
        setIsLanguageMenu(!isLanguageMenu);
    };

    const closeMenu = () => {
        document.removeEventListener('mousedown', handleClickOutside, false);
        setCurrentSearch('');
        setIsOpen(false);
        onClose?.();
        setIsFocused(false);

        if (loadMore) loadMore('');
    };

    const onFilterItemClick = value => {
        toggleMenu();
        if (onFilterClick) onFilterClick(value);
    };

    useEffect(() => {
        isOpenRef.current = isOpen;
    }, [isOpen]);

    const handleClickOutside = (event: Event) => {
        if (
            isOpenRef.current &&
            menuWrapperRef &&
            event.target instanceof Node &&
            !!menuWrapperRef.current &&
            !menuWrapperRef.current.contains(event.target)
        ) {
            closeMenu();
        }
    };

    const updateCurrentValue = (value: Choice, e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        const filtered = value
            ? selectedOptions.filter(option => option.value !== value.value)
            : selectedOptions;

        if (filtered.length === selectedOptions.length) {
            if (singleSelect) {
                onChange([value]);
            } else {
                onChange([...selectedOptions, value]);
            }
        } else {
            onChange(filtered);
        }

        closeMenu();
    };

    const onInput = (e: InputEvent) => {
        if (hiddenSpanRef && hiddenSpanRef.current && inputRef && inputRef.current) {
            hiddenSpanRef.current.textContent = e.target.value;
            inputRef.current.style.width = '100%';
        }

        setCurrentSearch(e.target.value);
        setIsFocused(true);
        loadMore(e.target.value);
    };

    const openMenu = () => {
        if (!isOpenRef.current) {
            document.addEventListener('mousedown', handleClickOutside, false);
            setCurrentSearch('');
            setIsOpen(true);
            onOpen?.();
            setIsFocused(true);
        }

        if (loadMoreOnOpenMenu && loadMore) {
            loadMore('');
        }
    };

    useEffect(() => {
        if (isFocused) {
            inputRef?.current?.focus({ preventScroll: !scrollOnOpen });
        }
    }, [isFocused, inputRef]);

    const onPreSelectedValue = (value: string) => {
        setPreSelectedValue(value);

        if (onChangePreselectedValue) {
            onChangePreselectedValue(value);
        }
    };

    const unsetPreSelectedValue = (value?: string) => {
        if (value === preSelectedValue) {
            setPreSelectedValue('');
            if (onChangePreselectedValue) {
                onChangePreselectedValue('');
            }
        }
    };

    const clonedElementWithMoreProps =
        input &&
        React.cloneElement(input, {
            onClick: openMenu,
        });

    return (
        <ButtonWithMenuWrapper className={className}>
            <ButtonWithMenuContainer>
                {clonedElementWithMoreProps}
                {isOpen && (
                    <MenuWrapper
                        ref={menuWrapperRef}
                        hasHeader={hasHeader}
                        position={position}
                        data-css-selector={MenuWrapperSelectorId}
                    >
                        {!isLanguageMenu ? (
                            <>
                                {hasHeader && (
                                    <MenuTitleContainer>
                                        <MenuTitle>{menuTitle}</MenuTitle>
                                        {!!redirectButton && (
                                            <MenuIcon>
                                                <IconButton
                                                    dataTrackId="button_with_search_menu__redirect__icon__button"
                                                    appearance="outlined"
                                                    icon={redirectButton}
                                                    onClick={onButtonClick}
                                                    tooltip={redirectButtonTooltip}
                                                />
                                            </MenuIcon>
                                        )}
                                        {!!menuIcon && (
                                            <MenuIcon>
                                                <IconButton
                                                    dataTrackId="button_with_search_menu__menu__icon__button"
                                                    appearance="outlined"
                                                    onClick={toggleMenu}
                                                    icon={menuIcon}
                                                    tooltip={menuIconTooltip}
                                                />
                                            </MenuIcon>
                                        )}
                                    </MenuTitleContainer>
                                )}
                                <InputWrapper>
                                    <InputClickable onClick={openMenu} disabled={disabled}>
                                        <InputPlaceholder>{placeholder}</InputPlaceholder>
                                        <ItemsContainer>
                                            {isFocused && (
                                                <InputContainer>
                                                    <span ref={hiddenSpanRef} />
                                                    <input
                                                        ref={inputRef}
                                                        type="text"
                                                        // @ts-expect-error
                                                        onInput={onInput}
                                                    />
                                                </InputContainer>
                                            )}
                                        </ItemsContainer>
                                        <InputIcon type="button">
                                            <i className={'fas fa-search'} />
                                        </InputIcon>
                                    </InputClickable>
                                </InputWrapper>
                                <MenuItemsContainer ref={wrapperRef}>
                                    <InfiniteScroll
                                        loadMore={() => loadMore(currentSearch)}
                                        hasMore={hasMore}
                                        loader={
                                            <Loader key={0}>
                                                <i className="fa-solid fa-circle-notch fa-spin" />
                                            </Loader>
                                        }
                                        initialLoad={false}
                                        getScrollParent={() => wrapperRef.current}
                                        useWindow={false}
                                    >
                                        {options.map((option, index) => (
                                            <MenuItemWrapper
                                                ref={ref => {
                                                    buttonsRef[index] = ref;
                                                }}
                                                type="button"
                                                // prettier-ignore
                                                className={'multiple_select__menu__item'}
                                                onClick={e => {
                                                    updateCurrentValue(
                                                        option,
                                                        // @ts-expect-error
                                                        e,
                                                    );
                                                    unsetPreSelectedValue(option.value);
                                                    closeMenu();
                                                }}
                                                key={option.value}
                                                onMouseOver={() => onPreSelectedValue(option.value)}
                                                onFocus={() => onPreSelectedValue(option.value)}
                                                onMouseOut={() =>
                                                    unsetPreSelectedValue(option.value)
                                                }
                                                onBlur={() => unsetPreSelectedValue(option.value)}
                                            >
                                                <MenuItemContainer>
                                                    <MenuItemIcon>
                                                        <ImageContainer>
                                                            {option.icon}
                                                        </ImageContainer>
                                                    </MenuItemIcon>
                                                    <MenuItemLabel>{option.label}</MenuItemLabel>
                                                </MenuItemContainer>
                                                {selectedOptions.filter(
                                                    opt => opt.value === option.value,
                                                ).length > 0 && <i className="fa-solid fa-check" />}
                                            </MenuItemWrapper>
                                        ))}
                                        {!options.length && !isLoading && (
                                            <ItemEmptyPlaceholder type="button" onClick={closeMenu}>
                                                {t('no-result-found')}
                                            </ItemEmptyPlaceholder>
                                        )}
                                        {isLoading && hasMore && (
                                            <Loader>
                                                <i className="fa-solid fa-circle-notch fa-spin" />
                                            </Loader>
                                        )}
                                    </InfiniteScroll>
                                </MenuItemsContainer>
                            </>
                        ) : (
                            <>
                                <FilterSelectionTitleContainer role="button" onClick={toggleMenu}>
                                    <FilterSelectionTitleIcon>
                                        <i className="fa-solid fa-arrow-left" />
                                    </FilterSelectionTitleIcon>
                                    <FilterSelectionTitle>{t('back')}</FilterSelectionTitle>
                                </FilterSelectionTitleContainer>
                                <FilterSelectionMenuContainer>
                                    <FilterSelectionMenu>
                                        {filtersOptions?.map(option => (
                                            <FilterSelectionItemContainer
                                                role="button"
                                                onClick={() => onFilterItemClick(option.value)}
                                                key={option.value}
                                            >
                                                <FilterSelectionItem>
                                                    <ImageContainer>{option.icon}</ImageContainer>
                                                    <FilterSelectionItemLabel>
                                                        {option.label}
                                                    </FilterSelectionItemLabel>
                                                </FilterSelectionItem>
                                            </FilterSelectionItemContainer>
                                        ))}
                                    </FilterSelectionMenu>
                                </FilterSelectionMenuContainer>
                            </>
                        )}
                    </MenuWrapper>
                )}
            </ButtonWithMenuContainer>
        </ButtonWithMenuWrapper>
    );
};

export default ButtonWithSearchMenuFilterSelection;
