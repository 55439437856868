import { Dispatch, FC, SetStateAction, useState } from 'react';

import { Button, FontAwesomeIconsPartooUsed, IconPrefix, Modal, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { FoodMenuSectionType } from 'app/api/types/food_menu';
import { ButtonWrapper } from 'app/businessEditV2/sections/MenuSection/components/MenuSections.styled';
import StrikeTroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';

import LoadingMenuSections from './LoadingMenuSections';
import MenuItems from './MenuItems';
import {
    CancelButton,
    ModalContent,
    ModalSubtitle,
    ModalTitle,
    ModalWrapper,
} from './modals/modal.styled';

interface MenuSectionsProps {
    isLoading?: boolean;
    value: Array<FoodMenuSectionType>;
    onChange: Dispatch<SetStateAction<Array<FoodMenuSectionType>>>;
    disabled: boolean;
}

const MenuSections: FC<MenuSectionsProps> = ({ isLoading = false, value, onChange, disabled }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState<boolean>(false);
    const [name, setName] = useState<string>('');

    const openModal = () => {
        setShow(true);
    };

    const closeModal = () => {
        setName('');
        setShow(false);
    };

    const isFormInvalid = () =>
        name.length === 0 || name.length > 140 || name?.replace(/\s/g, '').length === 0;

    const onApply = () => {
        const maxOrderValue = Math.max(...value.map(section => section.order), value.length) + 1;
        const newSection = {
            name,
            items: [],
            order: maxOrderValue,
        };
        onChange([...value, newSection]);
        setShow(false);
        setName('');
    };

    if (isLoading) {
        return <LoadingMenuSections />;
    }

    return (
        <>
            <Modal isOpen={show} closeModal={closeModal}>
                <ModalWrapper>
                    <ModalContent>
                        <ModalTitle>{t('add_food_menu_section_name_modal_title')}</ModalTitle>
                        <ModalSubtitle>
                            {t('add_food_menu_section_name_modal_subtitle')}
                        </ModalSubtitle>
                        <TextInput
                            dataTrackId="food_menu_section_name"
                            placeholder={t('food_menu_section_name')}
                            required
                            value={name}
                            onChange={value => setName(value || '')}
                            characterCount={140}
                        />
                    </ModalContent>

                    <CancelButton>
                        <StrikeTroughButton
                            text={t('cancel')}
                            color="secondary"
                            onClick={closeModal}
                        />
                    </CancelButton>
                    <Button
                        dataTrackId="menu_validate_form__button"
                        full
                        size="large"
                        variant="primary"
                        shape="cube"
                        disabled={isFormInvalid()}
                        onClick={onApply}
                    >
                        {t('validate')}
                    </Button>
                </ModalWrapper>
            </Modal>
            {value.map((section, index) => (
                <MenuItems
                    key={`${section.name}_${section.order}`}
                    sections={value}
                    section={section}
                    setSections={onChange}
                    disabled={disabled}
                    first={index === 0}
                    last={index === value.length - 1}
                />
            ))}
            {!disabled && (
                <ButtonWrapper>
                    <Button
                        dataTrackId="create_menu__button"
                        onClick={openModal}
                        disabled={disabled}
                        shape="cube"
                        appearance="outlined"
                        variant="primary"
                        icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                    >
                        {value.length > 0
                            ? t('menu_add_section_button_text')
                            : t('menu_create_button_text')}
                    </Button>
                </ButtonWrapper>
            )}
        </>
    );
};

export default MenuSections;
