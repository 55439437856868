import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import { Item } from 'app/pages/Homepage/utils/typing';

import { useTodoMessagesItem } from './todosItems/messages/useTodoMessagesItem';
import { useTodoPartnerCo } from './todosItems/partnerCo/useTodoPartnerCo';
import { useTodoReviewsItem } from './todosItems/reviews/useTodoReviewsItem';
import { useTodoCompletionRateItem } from './todosItems/useTodoCompletionRateItem';

export const useAllTodosItems = (): Array<Item<SmallTodoProps>> => {
    return [
        useTodoMessagesItem(),
        useTodoReviewsItem(),
        useTodoPartnerCo(),
        useTodoCompletionRateItem(),
    ];
};
