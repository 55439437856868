export function formatGPSCoordinates(pmFields) {
    if (
        (pmFields.includes('lat') && pmFields.includes('long')) ||
        (pmFields.includes('manual_lat') && pmFields.includes('manual_long'))
    ) {
        return 'gps_coordinates';
    }

    return '';
}

export function formatHours(pmFields) {
    if (pmFields.includes('open_hours')) {
        if (pmFields.includes('specific_hours')) {
            return 'common_and_specific_hours_synced';
        }

        return 'common_hours_synced';
    }

    return 'hours_not_synced';
}

export function formatPhotos(pmFields) {
    if (pmFields.includes('media')) {
        return 'photos_synced';
    }

    return 'photos_not_synced';
}

export function formatPublisherPMFields(pmFields) {
    return {
        main_info: {
            business_name: pmFields.includes('name') ? 'business_name' : '',
            address_elements: pmFields.includes('address_full')
                ? 'address_elements'
                : '',
            phone: pmFields.includes('contacts.phone_numbers') ? 'phone' : '',
            gps_coord: formatGPSCoordinates(pmFields),
            categories: pmFields.includes('categories') ? 'categories' : '',
            description_long: pmFields.includes('description_long')
                ? 'description_long'
                : '',
            description_short: pmFields.includes('description_short')
                ? 'description_short'
                : '',
            website_url: pmFields.includes('website_url') ? 'website_url' : '',
        },
        open_hours: formatHours(pmFields),
        photos: formatPhotos(pmFields),
    };
}

export default formatPublisherPMFields;
