import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0 19px 0;
    padding-inline: 24px;
    height: 40px;
    flex-shrink: 0;
`;

export const Title = styled.div`
    font-weight: 700;
    font-size: 22px;
    color: ${HEX_COLORS.blackberry};
`;

export const CrossClickArea = styled.div`
    border-radius: 50%;
    width: 40px;
    height: 40px;

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary.alpha};
    }

    &:hover > svg > path {
        fill: ${({ theme }) => theme.colors.primary.initial};
    }

    svg > path {
        transform-origin: center;
        transform: scale(0.7);
    }

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
`;
