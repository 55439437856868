import styled from '@emotion/styled';

export const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1680px;
    gap: 24px;
`;
export const BodyContent = styled.div``;
export const BodyFooter = styled.div``;
