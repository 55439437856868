import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { USERS } from 'app/common/data/queryKeysConstants';

export default function useReinviteAllUsers(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const queryClient = useQueryClient();
    return useMutation(() => api.user.reInvinteAllUsers(), {
        onSuccess: () => {
            queryClient.invalidateQueries(invalidatesAny(USERS)).then(onSuccess);
        },
        onError,
    });
}
