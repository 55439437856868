import styled from '@emotion/styled';

export const RatingsSelectStepContainer = styled.div`
    display: flex;
    margin-top: 16px;
    gap: 16px;

    @media (max-width: 769px) {
        flex-direction: column;
    }
`;
