import styled from '@emotion/styled';
import { IconButton } from '@partoohub/ui';

export const StyledPreviewIframe = styled.iframe`
    width: 80vw;
    height: 80vh;
`;

export const StyledModalContent = styled.div`
    border-radius: 16px;
    padding: 24px;
`;

export const StyledCloseButton = styled(IconButton)`
    position: fixed;
    right: 16px;
    top: 16px;
    z-index: 2000;
`;
