import styled from '@emotion/styled';

export const HelperCardDescription = styled.div`
    margin: 16px 0;
`;

export const NoDataContainer = styled.div`
    width: 178px;
    height: 84px;
`;
