import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import { UserManagement as UserManagementSettingsV2 } from './UserManagement';

export const UserManagement = () => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('team_management_list')} />
            <UserManagementSettingsV2 />
        </>
    );
};
