export type TimeUnit = 'day' | 'week' | 'month';
export type TimeUnitDico = {
    day: string;
    week: string;
    month: string;
};

export const translationsMap: TimeUnitDico = {
    day: 'day_count',
    week: 'week_count',
    month: 'month_count',
};
