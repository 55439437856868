import { IconButton, IconPrefix } from '@partoohub/ui';
import Dropzone from 'react-dropzone';

import { CrossIcon } from 'app/reviewManagement/common/icon/activeConversation';

import {
    ImageInputIconButtonWrapper,
    ImagePreviewItem,
    ImagePreviewListContainer,
    ImagePreviewRemoveButton,
} from './ImageInput.styled';

const ACCEPTED_FILE_TYPES = ['image/png', 'image/jpeg', 'image/jpg', '.png', '.jpeg', '.jpg'];

export type ImageFile = File & { preview: string };

type ImagePreviewListProps = {
    files: Array<ImageFile>;
    onRemove: (index: number) => void;
};

type ImageInputButtonProps = {
    onPick: (files: Array<ImageFile>) => void;
};

export const ImagePreviewList = ({ files, onRemove }: ImagePreviewListProps) => (
    <ImagePreviewListContainer>
        {files.map((file, index) => (
            <li key={file.name}>
                <div className="remove-button">
                    <ImagePreviewRemoveButton onClick={() => onRemove(index)}>
                        <CrossIcon />
                    </ImagePreviewRemoveButton>
                </div>
                <ImagePreviewItem src={file.preview} />
            </li>
        ))}
    </ImagePreviewListContainer>
);

export const ImageInputButton = ({ onPick }: ImageInputButtonProps) => {
    const onDrop = (files: Array<ImageFile>) => onPick(files);

    return (
        <Dropzone onDrop={onDrop} style={{}} accept={ACCEPTED_FILE_TYPES.join(', ')}>
            <ImageInputIconButtonWrapper>
                <IconButton appearance="outlined" icon={['fa-image', IconPrefix.REGULAR]} />
            </ImageInputIconButtonWrapper>
        </Dropzone>
    );
};
