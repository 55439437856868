import { useTheme } from '@emotion/react';
import { HEX_COLORS, convertToRgba } from '@partoohub/branding';
import { AvatarMenuItem, getInitial } from '@partoohub/modular-components';
import { Avatar, FontAwesomeIconsPartooUsed, IconPrefix, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppDataContext } from 'app/common/contexts/appData';
import { SETTINGS_MAIN_PROFILE_PATHNAME } from 'app/routing/routeIds';

export const AvatarProfilMeItem = () => {
    const { me } = useAppDataContext();
    const theme = useTheme();
    const { first_name, last_name } = me;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(SETTINGS_MAIN_PROFILE_PATHNAME);
    };
    return (
        <AvatarMenuItem callback={handleClick}>
            {first_name || last_name ? (
                <Avatar
                    size={24}
                    text={me ? getInitial(me) : ''}
                    textSize={8}
                    backgroundColor={`${theme.colors.default.background}`}
                />
            ) : (
                <Avatar
                    size={24}
                    image={[FontAwesomeIconsPartooUsed.faUser, IconPrefix.SOLID]}
                    textColor={HEX_COLORS.blackberry}
                    iconSize="10px"
                    backgroundColor={convertToRgba(HEX_COLORS.blackberry, 0.12)}
                />
            )}
            <Text variant="bodyMMedium" as="span">
                {t('my_profile')}
            </Text>
        </AvatarMenuItem>
    );
};
