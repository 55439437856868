import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const HeaderContainer = styled(Stack)`
    padding: 24px;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.default.alpha};
`;

export const HeaderIconWrapper = styled.div`
    width: 24px;
    height: 24px;
    background-color: #e2e4e8;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FooterContainer = styled(Stack)`
    padding: 24px;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.default.alpha};
`;
