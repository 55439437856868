import { MouseEventHandler, useCallback, useState } from 'react';

import { Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { useFormatNumber } from 'app/common/hooks/useFormatNumber';
import { CardTitle } from 'app/pages/analytics/VisibilityAnalyticsV2/components/CardTitle/CardTitle';
import { Count } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Count/Count';
import { Delta } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Delta/Delta';
import { ErrorPlaceholder } from 'app/pages/analytics/VisibilityAnalyticsV2/components/ErrorPlaceholder/ErrorPlaceholder';
import { HelperCard } from 'app/pages/analytics/VisibilityAnalyticsV2/components/HelperCard/HelperCard';
import { HelperContent } from 'app/pages/analytics/VisibilityAnalyticsV2/components/HelperCard/HelperContent/HelperContent';
import {
    ListContainer,
    ListItem,
    ListItemKey,
    ListItemValue,
    SearchKeywordsContainer,
    SuptitleBoxIcon,
    SuptitleBoxIconWrapper,
    SuptitleContainer,
    SuptitleText,
    SuptitleValue,
    ViewsCardContainer,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/Metrics/MetricsCard/ViewsCard/ViewsCard.styled';
import {
    viewsColor,
    viewsHelperContent,
} from 'app/pages/analytics/VisibilityAnalyticsV2/utils/view';

import { StyledSubtitle } from '../../../HelperCard/HelperCard.styled';

type Props = {
    data: {
        business_impressions_desktop_search: string;
        business_impressions_desktop_maps: string;
        business_impressions_mobile_search: string;
        business_impressions_mobile_maps: string;
    };
    count: number;
    delta: number | null;
    hasError: unknown;
    refetch?: () => void;
};

export const ViewsCard = ({ data, count, delta, hasError, refetch }: Props) => {
    const { t } = useTranslation();
    const [showDetailsDesktop, setShowDetailsDesktop] = useState(false);
    const [showDetailsMobile, setShowDetailsMobile] = useState(false);
    const { hex, rgba } = viewsColor;

    const togglingDesktop: MouseEventHandler<HTMLDivElement> = useCallback(e => {
        e.preventDefault();
        setShowDetailsDesktop(prev => !prev);
    }, []);
    const togglingMobile: MouseEventHandler<HTMLDivElement> = useCallback(e => {
        e.preventDefault();
        setShowDetailsMobile(prev => !prev);
    }, []);

    const ViewsCardTitle = (
        <CardTitle color={viewsColor} icon={<i className="fa fa-fas fa-eye"></i>}>
            {t('views')}
        </CardTitle>
    );

    const businessImpressionsMobileSearch = parseInt(data.business_impressions_mobile_search ?? 0);
    const businessImpressionsMobileMaps = parseInt(data.business_impressions_mobile_maps ?? 0);
    const formattedBusinessImpressionsMobileSearch = useFormatNumber(
        businessImpressionsMobileSearch,
    );
    const formattedBusinessImpressionsMobileMaps = useFormatNumber(businessImpressionsMobileMaps);
    const formattedBusinessImpressionsMobile = useFormatNumber(
        businessImpressionsMobileMaps + businessImpressionsMobileSearch,
    );

    const businessImpressionsDesktopSearch = parseInt(
        data.business_impressions_desktop_search ?? 0,
    );
    const businessImpressionsDesktopMaps = parseInt(data.business_impressions_desktop_maps ?? 0);
    const formattedBusinessImpressionsDesktopSearch = useFormatNumber(
        businessImpressionsDesktopSearch,
    );
    const formattedBusinessImpressionsDesktopMaps = useFormatNumber(businessImpressionsDesktopMaps);
    const formattedBusinessImpressionsDesktop = useFormatNumber(
        businessImpressionsDesktopMaps + businessImpressionsDesktopSearch,
    );
    return (
        <ViewsCardContainer
            data-track="analytics_visiblity__card_views"
            data-intercom-target="analytics_visiblity__card_views"
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                {ViewsCardTitle}
                <HelperCard title={t('views')}>
                    <StyledSubtitle variant="bodyMSemibold" as="span" color="secondary">
                        {t('explanation-views')}
                    </StyledSubtitle>
                    <HelperContent content={viewsHelperContent} />
                </HelperCard>
            </Stack>

            {hasError ? (
                <ErrorPlaceholder message={t('error_page_placeholder_title_app')}>
                    <StrikeThroughButton
                        color="primary"
                        onClick={refetch}
                        text={t('try_agin')}
                        icon={<i className="fa-solid fa-redo" />}
                    />
                </ErrorPlaceholder>
            ) : (
                <>
                    <Count count={count} />
                    <Delta delta={delta} />

                    <SearchKeywordsContainer>
                        <SuptitleContainer>
                            <SuptitleBoxIconWrapper>
                                <SuptitleBoxIcon hex={hex} rgba={rgba}>
                                    <i className="fa fa-mobile"></i>
                                </SuptitleBoxIcon>
                                <SuptitleText>{t('mobile_word')}</SuptitleText>
                            </SuptitleBoxIconWrapper>
                            <SuptitleValue onClick={togglingMobile}>
                                {formattedBusinessImpressionsMobile}
                                {showDetailsMobile ? (
                                    <i className="fa fa-fas fa-caret-up" />
                                ) : (
                                    <i className="fa fa-fas fa-caret-down" />
                                )}
                            </SuptitleValue>
                        </SuptitleContainer>
                        {showDetailsMobile && (
                            <ListContainer>
                                <ListItem>
                                    <ListItemKey>{t('pa_views_search')}</ListItemKey>
                                    <ListItemValue>
                                        {formattedBusinessImpressionsMobileSearch}
                                    </ListItemValue>
                                </ListItem>
                                <ListItem>
                                    <ListItemKey>{t('pa_views_maps')}</ListItemKey>
                                    <ListItemValue>
                                        {formattedBusinessImpressionsMobileMaps}
                                    </ListItemValue>
                                </ListItem>
                            </ListContainer>
                        )}
                        <SuptitleContainer>
                            <SuptitleBoxIconWrapper>
                                <SuptitleBoxIcon hex={hex} rgba={rgba}>
                                    <i className="fa fa-desktop"></i>
                                </SuptitleBoxIcon>
                                <SuptitleText>{t('desktop_word')}</SuptitleText>
                            </SuptitleBoxIconWrapper>
                            <SuptitleValue onClick={togglingDesktop}>
                                {formattedBusinessImpressionsDesktop}
                                {showDetailsDesktop ? (
                                    <i className="fa fa-fas fa-caret-up" />
                                ) : (
                                    <i className="fa fa-fas fa-caret-down" />
                                )}
                            </SuptitleValue>
                        </SuptitleContainer>
                        {showDetailsDesktop && (
                            <ListContainer>
                                <ListItem>
                                    <ListItemKey>{t('pa_views_search')}</ListItemKey>
                                    <ListItemValue>
                                        {formattedBusinessImpressionsDesktopSearch}
                                    </ListItemValue>
                                </ListItem>
                                <ListItem>
                                    <ListItemKey>{t('pa_views_maps')}</ListItemKey>
                                    <ListItemValue>
                                        {formattedBusinessImpressionsDesktopMaps}
                                    </ListItemValue>
                                </ListItem>
                            </ListContainer>
                        )}
                    </SearchKeywordsContainer>
                </>
            )}
        </ViewsCardContainer>
    );
};
