import { useMutation, useQueryClient } from 'react-query';

import { BusinessFieldsType } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { ORG_BUSINESS_EDIT_FIELDS } from 'app/common/data/queryKeysConstants';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export default function useOrgPermissionsUpdate(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const { data: me } = useMe();
    const queryClient = useQueryClient();

    return useMutation(
        (payload: BusinessFieldsType) => api.org.updateBusinessEditFields(me?.org_id ?? 0, payload),
        {
            onSuccess: () => {
                queryClient
                    .invalidateQueries(invalidatesAny(ORG_BUSINESS_EDIT_FIELDS))
                    .then(onSuccess);
            },
            onError,
        },
    );
}
