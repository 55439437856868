import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const ModalBody = styled.div`
    text-align: center;
    padding: 32px 8px 8px;
    white-space: pre-wrap;
    width: 500px;
`;

export const ModalTitle = styled(Text)``;

export const ModalText = styled(Text)``;

export const TimeSlotTable = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    align-items: center;
`;

export const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.colors.danger.initial};
`;

export const ModalButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 8px;
`;
