import { useMutation } from 'react-query';

import { V2BusinessData } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { getBusinessRequestKey } from 'app/businessEditV2/utils/utils';
import queryClient from 'app/states/queryClient';

export default function useBusinessUpdateCompletionRate(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const businessId = useGetBusinessId();
    return useMutation(
        () => api.business.updateBusinessCompletionRate(businessId),
        {
            onSuccess: data => {
                const completionRate = data.completion_rate;
                const previousBusiness: V2BusinessData | undefined =
                    queryClient.getQueryData(getBusinessRequestKey(businessId));

                if (businessId) {
                    queryClient.setQueryData(
                        getBusinessRequestKey(businessId),
                        {
                            ...previousBusiness,
                            completion_rate: completionRate,
                        },
                    );
                }

                return onSuccess();
            },
            onError,
        },
    );
}
