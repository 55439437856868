import { UseFormReturn } from 'react-hook-form/dist/types';

import isPasswordValid from 'app/common/services/passwordChecker';
import { SettingSaveButton } from 'app/pages/settingsV2/subComponents/SettingSaveButton/SettingSaveButton';
import useMeUpdate from 'app/pages/settingsV2/subPages/Main/components/ProfileSettings/hooks/useMeUpdate';

type Props = {
    form: UseFormReturn<any>;
    meUpdate: ReturnType<typeof useMeUpdate>;
    submitForm: () => void;
};

const ProfileSettingsSaveButton = ({ form, meUpdate, submitForm }: Props) => {
    const { watch } = form;
    // We don't disable or send the password if the user is not updating it
    const disabled =
        !!watch('updatePassword') &&
        !isPasswordValid(watch('password')) &&
        (!!watch('oldPassword') || !!watch('password'));
    return (
        <SettingSaveButton
            isLoading={meUpdate.isLoading}
            hasError={!!meUpdate.error}
            onClick={submitForm}
            dataTrackId="save-user-info"
            disabled={disabled}
        />
    );
};

export default ProfileSettingsSaveButton;
