import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

interface BusinessCardContainerProps {
    noBorder?: boolean;
}

export const BusinessCardContainer = styled.div<BusinessCardContainerProps>`
    display: flex;
    flex: 1;
    align-items: center;
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    padding: 16px;
    height: 96px;
    overflow: hidden;

    ${props =>
        props.noBorder &&
        css`
            padding: 0;
            border: none;
            border-radius: 0;
        `}
`;

export const BusinessImage = styled.img`
    border-radius: ${({ theme }) => theme.radius.large};
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    height: 64px;
    width: 64px;
    min-width: 64px;
    margin-right: 16px;
    object-fit: cover;
    overflow: hidden;
`;

export const InfosContainer = styled.div`
    overflow: hidden;
    padding-right: 8px;
`;

export const BusinessNameContainer = styled.div`
    font-size: 14px;
    line-height: 16px;
    color: #333333;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const BusinessAddressContainer = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin: 2px 0;
    overflow: hidden;

    & > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const BusinessCodeContainer = styled.div`
    display: flex;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
    font-weight: bold;

    & > span {
        color: ${({ theme }) => theme.colors.secondary.initial};
        margin-left: 2px;
    }
`;
