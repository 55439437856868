import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const NoTemplateScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 16px;
`;

export const NoTemplateScreenTitle = styled(Text)`
    margin-bottom: 16px;
    text-align: 'center';
`;

export const OverrideText = styled(Text)`
    text-align: 'center';
`;

export const GenericTemplate = styled.div<{ dashed?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    width: 100%;
    margin: 4px 0px;
    padding: 16px 8px;
    border: 2px solid ${({ theme }) => theme.colors.default.alpha};
    ${({ dashed, theme }) =>
        dashed &&
        css`
            border: 2px dotted ${theme.colors.default.alpha};
        `}
    border-radius: 4px;
`;

export const TitleOutline = styled.div`
    width: 200px;
    height: 8px;
    margin-left: 12px;
    background-color: ${({ theme }) => theme.colors.default.alpha};
    border-radius: 3px;
`;
