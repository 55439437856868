import { useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import FunnelOnboarding from 'app/onboarding/FunnelOnboarding';
import { DefaultShortcutComponent } from 'app/pages/Homepage/sections/HomepageShortcuts/DefaultShortcutComponent/DefaultShortcutComponent';
import { useIsMaxBusinessesLimitCheck } from 'app/pages/visibility/BusinessCreate/hooks/useIsMaxBusinessesLimitCheck';
import MaxBusinessReachedModal from 'app/pages/visibility/BusinessCreate/sections/BusinessCreateContent/MaxBusinessReachedModal';

export const ShortcutCreateBusiness = () => {
    const { t } = useTranslation();

    const [showFunnel, setShowFunnel] = useState(false);
    const [showMaxBusinessModal, setShowMaxBusinessModal] = useState<boolean>(false);
    const isMaxBusinessesLimitReached = useIsMaxBusinessesLimitCheck();

    const onClick = () => {
        if (isMaxBusinessesLimitReached) {
            setShowMaxBusinessModal(true);
            return;
        }
        setShowFunnel(true);
    };

    return (
        <>
            <DefaultShortcutComponent
                title={t('homepage_shortcut_business')}
                variant="primary"
                iconElement={[FontAwesomeIconsPartooUsed.faStore, IconPrefix.SOLID]}
                onClick={onClick}
                dataTrack="click_homepage_shortcut_businesses"
            />

            <Modal isOpen={showMaxBusinessModal} closeModal={() => setShowMaxBusinessModal(false)}>
                <MaxBusinessReachedModal closeModal={() => setShowMaxBusinessModal(false)} />
            </Modal>

            <FunnelOnboarding showFunnel={showFunnel} setShowFunnel={setShowFunnel} />
        </>
    );
};
