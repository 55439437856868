import { useEffect, useMemo, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { BusinessMoreHoursPostType, BusinessMoreHoursType } from 'app/api/types/more_hours';
import EditForm from 'app/businessEditV2/components/EditForm';
import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useIsLoadingMoreHours from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingMoreHours';
import useMoreHoursSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useMoreHoursSectionColor';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useBusinessMoreHours from 'app/businessEditV2/hooks/moreHours/useBusinessMoreHours';
import useMoreHours from 'app/businessEditV2/hooks/moreHours/useMoreHours';
import useMoreHoursUpdate from 'app/businessEditV2/hooks/moreHours/useMoreHoursUpdate';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import { formatOpenHoursFields } from 'app/businessEditV2/oldFromEditV1/services/formatDataForAPI';
import MoreHours from 'app/businessEditV2/sections/MoreHoursSection/MoreHours';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames, findFirstString } from 'app/businessEditV2/utils/utils';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

type Props = {
    closeModal: () => void;
    useDirtyUpdate: (boolean) => void;
};

function formatDataForMoreHoursUpdateAPI(data: BusinessMoreHoursType, disabled: boolean) {
    if (disabled) {
        return {
            more_hours: [],
        };
    }

    return {
        more_hours: data.map(more_hour => ({
            gmb_id: more_hour.gmb_id,
            value: more_hour.value ? formatOpenHoursFields(more_hour.value) : more_hour.value,
        })),
    };
}

const MoreHoursForm = ({ closeModal, useDirtyUpdate }: Props) => {
    const { t } = useTranslation();
    const colorState = useMoreHoursSectionColor();
    const isLoading = useIsLoadingMoreHours();
    const { data: business } = useBusiness();
    const { data: moreHoursData } = useMoreHours();
    const { data: businessMoreHoursData } = useBusinessMoreHours();
    const [moreHoursOrderOnSave, setMoreHoursOrderOnSave] = useState<Array<number>>([]);

    const setFormValue = () => ({
        moreHours: businessMoreHoursData?.more_hours ?? [],
    });

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    useDirtyUpdate(isDirty);

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const moreHoursUpdate = useMoreHoursUpdate(() => {
        closeModal();
    });

    const errorsData = moreHoursUpdate.error?.response?.data?.errors?.json?.more_hours ?? {};

    const onSubmit = payload => {
        setMoreHoursOrderOnSave(payload.more_hours.map(moreHour => moreHour.gmb_id));
        moreHoursUpdate.mutate(payload);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_MORE_HOURS_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
    };

    const formatErrors = errors => {
        const formattedErrors = {};
        for (const [key, value] of Object.entries(errors)) {
            const errorValue = {};
            for (const [key2, value2] of Object.entries(value?.value)) {
                errorValue[key2] = findFirstString(value2);
            }
            formattedErrors[moreHoursOrderOnSave[key]] = errorValue;
        }
        return formattedErrors;
    };

    const errors = formatErrors(errorsData);

    const formatPayload = (payload): BusinessMoreHoursPostType => {
        return formatDataForMoreHoursUpdateAPI(payload.moreHours, !moreHoursPermission);
    };

    const submitForm = () => {
        handleSubmit(payload => onSubmit(formatPayload(payload)))();
        trackSaveBusinessEditSection(SectionNames.MORE_HOURS, colorState);
    };

    // Permission
    const businessIsOpen = !useIsBusinessClosed();
    const moreHoursPermission = useFieldMetadata('hours', 'more_hours')?.enabled && businessIsOpen;

    return (
        <>
            <EditForm
                title={t('more_hours')}
                description={t('business_edit_more_hours_description')}
                colorState={colorState}
            >
                <Controller
                    name="moreHours"
                    control={control}
                    render={({ field: { ref: _ref, value, ...usedFields } }) => (
                        <MoreHours
                            {...usedFields}
                            errors={errors || {}}
                            disabled={!moreHoursPermission}
                            moreHours={moreHoursData?.more_hours ?? []}
                            businessMoreHours={value}
                        />
                    )}
                />
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={moreHoursUpdate.isLoading}
                    hasError={!!moreHoursUpdate.error}
                    onClick={submitForm}
                    dataTrackId="visibility_location__form_more_hours__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default MoreHoursForm;
