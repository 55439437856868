import { forwardRef, useContext, useMemo } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { FontAwesomeIconsPartooUsed, IconPrefix, Tooltip, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { ReviewObjectType } from 'app/api/types/review';
import api from 'app/api/v2/api_calls';
import { useUpdateReviewQueryData } from 'app/reviewManagement/reviewList/hooks/queryData/useUpdateReviewQueryData';
import {
    API_NOT_TREATED_REVIEWS,
    NOT_TREATED_REVIEWS,
    PENDING_AUTO_REPLY_REVIEWS,
    TREATED_AUTO_REPLY_REVIEWS,
    TREATED_REVIEWS,
    navTabSelector,
} from 'app/states/reviews/filters';

import { formatReviewApiStateToInternalState } from 'app/states/reviews/services/reviewFormatter';

import { ReviewToggleIcon, StyledIcon, StyledReviewToggle } from './ReviewToggle.styled';
import { ReviewCardContext, ReviewCardContextType } from '../ReviewCard';

export const ReviewToggle = forwardRef<HTMLDivElement>(function (_, ref) {
    const { t } = useTranslation();
    const updateReviewQueryData = useUpdateReviewQueryData();
    const { review } = useContext<ReviewCardContextType>(ReviewCardContext);

    const { mutate: updateReview } = useMutation(
        () => {
            return api.review.updateReview({
                id: review.id,
                state: review.state === TREATED_REVIEWS ? API_NOT_TREATED_REVIEWS : TREATED_REVIEWS,
            });
        },
        {
            onSuccess: data => {
                const navTab = navTabSelector();
                const newReview = formatReviewApiStateToInternalState(data);

                updateReviewQueryData(newReview, {
                    hideReview: [TREATED_REVIEWS, NOT_TREATED_REVIEWS].includes(navTab),
                });
            },
            onError: () => {
                toast.error(null, t('reply_error'));
            },
        },
    );

    const isActive = [
        TREATED_REVIEWS,
        PENDING_AUTO_REPLY_REVIEWS,
        TREATED_AUTO_REPLY_REVIEWS,
    ].includes(review.state);

    const isClickable = useMemo(() => {
        if (review.reviewObjectType === ReviewObjectType.FEEDBACK_RESULT) return false;
        if ([PENDING_AUTO_REPLY_REVIEWS, TREATED_AUTO_REPLY_REVIEWS].includes(review.state))
            return false;

        return true;
    }, [review]);

    const onClick = () => {
        if (!isClickable) return;
        updateReview();
    };

    return (
        <StyledReviewToggle
            data-track="reviews__treated_toggle"
            data-intercom-target="reviews__treated_toggle"
        >
            <ReviewToggleIcon ref={ref}>
                {isClickable ? (
                    <Tooltip
                        text={t(
                            review.state === TREATED_REVIEWS
                                ? 'toggle_to_untreated'
                                : 'toggle_to_treated',
                        )}
                    >
                        <StyledIcon
                            icon={[
                                FontAwesomeIconsPartooUsed.faMessageCheck,
                                isActive ? IconPrefix.SOLID : IconPrefix.REGULAR,
                            ]}
                            onClick={onClick}
                            color={isActive ? HEX_COLORS.success : HEX_COLORS.secondary}
                        />
                    </Tooltip>
                ) : (
                    <StyledIcon
                        icon={[
                            FontAwesomeIconsPartooUsed.faMessageCheck,
                            isActive ? IconPrefix.SOLID : IconPrefix.REGULAR,
                        ]}
                        color={isActive ? HEX_COLORS.success : HEX_COLORS.secondary}
                    />
                )}
            </ReviewToggleIcon>
        </StyledReviewToggle>
    );
});
