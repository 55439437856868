import React from 'react';

import {
    ErrorMessage,
    ErrorPlaceholderContainer,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/ErrorPlaceholder/ErrorPlaceholder.styled';

type Props = {
    message?: string;
    children: React.ReactNode;
};

export const ErrorPlaceholder = ({ message, children }: Props) => (
    <ErrorPlaceholderContainer>
        {message && <ErrorMessage>{message}</ErrorMessage>}
        {children}
    </ErrorPlaceholderContainer>
);
