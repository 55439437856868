import { useContext } from 'react';

import { Stack } from '@partoohub/ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledContentLinkInput } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledContentLinkInput';
import { ControlledCustomLinkInput } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledCustomLinkInput';
import { ControlledGoogleCustomLink } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledGoogleCustomLink';
import { PartialEventForm } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/forms/PartialEventForm';

import { NoLinkMessage } from './News.styled';
import NextBackButtons from '../components/NextBackButtons/NextBackButtons';
import { NewPostContext, NewPostContextType } from '../context/NewPost';
import { useHasPartialEventDatesErrors } from '../hooks/useHasPartialEventDatesErrors';
import usePlatformSelection from '../hooks/usePlatformSelection';
import { getLinkOptions } from '../PostConstants';

export default function EventForm() {
    const { t } = useTranslation();
    const { formState, watch, handleSubmit } = useFormContext();

    const { setPreviousStep, setNextStep } = useContext<NewPostContextType>(NewPostContext);

    const { hasOnlyGoogle } = usePlatformSelection();

    const showLinkInput = watch('content_link') === 'custom_link';

    const customGoogleButton = watch('google_custom_link');

    const shouldDisplayCustomPhoneWarningGoogle = customGoogleButton === 'call';

    const showLinkField = !hasOnlyGoogle || (!!customGoogleButton && customGoogleButton !== 'call');

    const { hasErrors: hasErrorsDate } = useHasPartialEventDatesErrors();

    return (
        <Stack>
            <PartialEventForm />

            {hasOnlyGoogle && <ControlledGoogleCustomLink />}

            {shouldDisplayCustomPhoneWarningGoogle && (
                <NoLinkMessage>{t('posts_custom_google_field_link_save')}</NoLinkMessage>
            )}

            {showLinkField && (
                <ControlledContentLinkInput
                    options={getLinkOptions(t).filter(({ value }) =>
                        hasOnlyGoogle ? value !== 'no_link' : true,
                    )}
                    isMandatory
                />
            )}

            {showLinkInput && <ControlledCustomLinkInput />}

            <NextBackButtons
                disabled={!formState.isValid || hasErrorsDate}
                onNext={() => {
                    handleSubmit(setNextStep)();
                }}
                onPrevious={() => {
                    setPreviousStep?.();
                }}
            />
        </Stack>
    );
}
