import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import { ProfileSettings as ProfileSettingsV2 } from './ProfileSettings';

export const ProfileSettings = () => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('my_account')} />
            <ProfileSettingsV2 />
        </>
    );
};
