import { useContext, useEffect, useState } from 'react';

import { Banner, Button, Icon, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import DragDropContextWrapper from 'app/common/components/DragAndDrop/DragDropContextWrapper';
import DraggableWrapper from 'app/common/components/DragAndDrop/DraggableWrapper';
import DroppableWrapper from 'app/common/components/DragAndDrop/DroppableWrapper';
import usePrevious from 'app/common/hooks/usePrevious';
import ConversationStarterItemCard from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/ConversationStarterItemCard';
import ConversationStarterItemForm from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/ConversationStarterItemForm';
import { ConversationStarterItemsList } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/ConversationStarterItemsSection.styled';
import {
    CREATION_MODE_ITEM_ID,
    ConversationStartersContext,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/contexts/ConversationStartersContext';
import useConversationStarterItemCreate from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarterItemCreate';
import useConversationStarterItemUpdate from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarterItemUpdate';
import useConversationStarterItemUpdateOrder from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarterItemUpdateOrder';
import {
    CONVERSATION_STARTER_ITEM_MAXIMUM_ENTRY,
    ConversationStarterItemPostBackCreatePayload,
    ConversationStarterItemPostback,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';

type Props = {
    messageSettingId?: number;
    conversationStarterId: string;
    conversationStarterItems: Array<ConversationStarterItemPostback>;
    onConversationStarterItemCreation?: () => void;
};

const moveItem = (
    list: Array<ConversationStarterItemPostback>,
    fromIndex: number,
    toIndex: number,
) => {
    const [removed] = list.splice(fromIndex, 1);
    list.splice(toIndex, 0, removed);
    return list;
};

export const ConversationStarterItemsSectionList = ({
    messageSettingId,
    conversationStarterId,
    conversationStarterItems,
    onConversationStarterItemCreation,
}: Props) => {
    const { t } = useTranslation();
    const { activeConversationStarterItemId, setActiveConversationStarterItemId } = useContext(
        ConversationStartersContext,
    );

    const prevConversationStarterId = usePrevious(conversationStarterId);

    const updateMutation = useConversationStarterItemUpdate(conversationStarterId);

    const onItemFormUpdate = (itemId: string, data: ConversationStarterItemPostback) => {
        updateMutation.mutate({ ...data, id: itemId });
        setActiveConversationStarterItemId(null);
    };

    useEffect(() => {
        setActiveConversationStarterItemId(null);
    }, [messageSettingId]);

    useEffect(() => {
        // keep add item form when creating conversation starter for the bm setting
        if (prevConversationStarterId) {
            setActiveConversationStarterItemId(null);
        }
    }, [conversationStarterId]);

    const createConversationStarterItem = () => {
        if (onConversationStarterItemCreation) {
            onConversationStarterItemCreation();
        }
        setActiveConversationStarterItemId(CREATION_MODE_ITEM_ID);
    };

    const createMutation = useConversationStarterItemCreate(conversationStarterId, () => {
        setActiveConversationStarterItemId(null);
    });

    const onItemFormCreate = (data: ConversationStarterItemPostBackCreatePayload) => {
        createMutation.mutate(data);
    };

    // optimistic update
    const [conversationStartItemsNewOrder, setConversationStartItemsNewOrder] =
        useState<Array<ConversationStarterItemPostback>>(conversationStarterItems);

    useEffect(
        () => setConversationStartItemsNewOrder(conversationStarterItems),
        [conversationStarterItems],
    );

    const { mutate: updateItemOrder } =
        useConversationStarterItemUpdateOrder(conversationStarterId);

    const reorderItems = (destination: any, source: any) => {
        const reorderedConversationStarterItems = moveItem(
            Array.from(conversationStartItemsNewOrder),
            source.index,
            destination.index,
        ).map((cs_item, index) => ({ ...cs_item, order_index: index }));

        setConversationStartItemsNewOrder(reorderedConversationStarterItems);
        updateItemOrder(reorderedConversationStarterItems.map(cs_item => cs_item.id));
    };

    return (
        <>
            <DragDropContextWrapper onDragEndCallback={reorderItems}>
                <DroppableWrapper droppableId="droppable">
                    <ConversationStarterItemsList>
                        {conversationStartItemsNewOrder?.map((item, index) => {
                            if (activeConversationStarterItemId === item.id) {
                                return (
                                    <DraggableWrapper
                                        key={item.id}
                                        index={index}
                                        draggableId={item.id}
                                    >
                                        <ConversationStarterItemForm
                                            key={item.id + '_form'}
                                            conversationStarterItem={item}
                                            closeForm={() =>
                                                setActiveConversationStarterItemId(null)
                                            }
                                            onFormSubmit={data => onItemFormUpdate(item.id, data)}
                                        />
                                    </DraggableWrapper>
                                );
                            }
                            return (
                                <DraggableWrapper key={item.id} index={index} draggableId={item.id}>
                                    <ConversationStarterItemCard
                                        key={item.id}
                                        conversationStarterItem={item}
                                        onEditClick={() =>
                                            setActiveConversationStarterItemId(item.id)
                                        }
                                    />
                                </DraggableWrapper>
                            );
                        })}
                    </ConversationStarterItemsList>
                </DroppableWrapper>
            </DragDropContextWrapper>

            {activeConversationStarterItemId === CREATION_MODE_ITEM_ID && conversationStarterId && (
                <ConversationStarterItemForm
                    closeForm={() => setActiveConversationStarterItemId(null)}
                    onFormSubmit={onItemFormCreate}
                />
            )}

            {conversationStartItemsNewOrder?.length > 4 && (
                <Banner
                    dataTrackId=""
                    variant="default"
                    icon={
                        <Icon icon={['fa-solid fa-circle-info']} color="default" iconSize="14px" />
                    }
                    withCloseIcon={false}
                >
                    <Text variant="bodySSemibold" color="default">
                        {t('conversation_starter_instagram_limit_banner')}
                    </Text>
                </Banner>
            )}

            <Button
                dataTrackId="add_conversation_started"
                appearance="text"
                variant="primary"
                onClick={createConversationStarterItem}
                disabled={
                    conversationStartItemsNewOrder?.length >=
                    CONVERSATION_STARTER_ITEM_MAXIMUM_ENTRY
                }
                icon={['fa-circle-plus']}
            >
                {t('conversation_starters_add_item')}
            </Button>
        </>
    );
};
