import { V2BusinessStatus } from 'app/api/types/business';
import { CLOSED, CLOSED_TEMPORARILY, OPEN, OPENING_SOON } from 'app/common/data/businessStatuses';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { BusinessListQueryKeys } from 'app/pages/visibility/BusinessListV2/utils/enums';

export type UseGetStatusFilterReturn = { status: V2BusinessStatus } | {};

export const useGetStatusFilter = (): UseGetStatusFilterReturn => {
    const [statusString] = useStateQueryParams(BusinessListQueryKeys.STATUS);
    const statusArray = statusString.split(',');

    const includesAll = (arr: string[], values: string[]) => values.every(v => arr.includes(v));
    const statusValues = [OPEN, CLOSED, CLOSED_TEMPORARILY, OPENING_SOON];
    const hasStatus = statusString && !includesAll(statusArray, statusValues);
    const status = statusArray.join(',');

    return hasStatus ? { status } : {};
};
