import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import { Messaging } from 'app/reviewManagement/messaging/MessagingPage';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

type Props = { hasBusinessMessage: boolean };

export const MessagingPage = ({ hasBusinessMessage }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('messaging_title')} />
            {hasBusinessMessage ? <Messaging /> : <Navigate replace to={NOT_FOUND_PATH} />}
        </>
    );
};
