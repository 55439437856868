import { FontAwesomeIconsPartooUsed } from '@partoohub/ui';

import { TFunction } from 'i18next';

import { StatusFilterOption } from 'app/states/messaging';

import { LabelWithIcon } from '../LabelWithIcon/LabelWithIcon';

export const getOptionsLabels = (t: TFunction<'translation', undefined>) => {
    return getLabels().map(({ key, icon, value }) => ({
        label: <LabelWithIcon text={t(key)} icon={[`far ${icon}`]} variant="bodyMMedium" />,
        value: value,
        name: value,
    }));
};

export const getMenuLabels = (t: TFunction<'translation', undefined>) => {
    const menuLabels = new Map();

    getLabels().forEach(({ key, icon, value }) =>
        menuLabels.set(
            value,
            <LabelWithIcon text={t(key)} icon={[`far ${icon}`]} variant="bodySBold" />,
        ),
    );

    return menuLabels;
};

const getLabels = () => {
    return [
        {
            key: 'message_filter_open_conversations',
            icon: 'fa-inbox',
            value: StatusFilterOption.OPEN,
        },
        {
            key: 'message_filter_closed_conversations',
            icon: FontAwesomeIconsPartooUsed.faCircleCheck,
            value: StatusFilterOption.CLOSED,
        },
    ];
};
