import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

import { Text } from '@partoohub/ui';

export const ConversationStarterItemFormMessageContainer = styled.div``;

export const ConversationStarterItemFormMessageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .mdc-menu {
        right: 0;
        top: 32px;
        white-space: nowrap;
    }
    .menu_over_button__container {
        margin-top: auto;
    }
`;

export const TextAreaContainer = styled.div`
    position: relative;
    p {
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
    &:focus-within {
        p {
            color: ${({ theme }) => theme.colors.primary.initial};
        }
    }
`;

export const TextAreaFieldName = styled(Text)`
    position: absolute;
    transform: translateY(-60%);
    top: 0;
    left: 14px;
    background-color: ${HEX_COLORS.white};
    padding: 0 4px;
`;
