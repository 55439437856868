import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, theme } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { DropdownMenuButton } from 'app/common/components/buttons/DropdownMenuButton/DropdownMenuButton';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    CustomFieldsCreationKeys,
    CustomFieldsQueryKeys,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

import { LabelWithIconContainer } from './AddCustomFieldOrSectionButton.styled';

export const AddCustomFieldOrSectionDropDown = () => {
    const { t } = useTranslation();

    const [, setCreateCustomField] = useStateQueryParams(CustomFieldsQueryKeys.CREATE);

    const createCustomFieldLabel = (
        <LabelWithIconContainer>
            <Icon icon={[FontAwesomeIconsPartooUsed.faGridRound2Plus, IconPrefix.REGULAR]} />
            {t('custom_field')}
        </LabelWithIconContainer>
    );
    const createCustomFieldSectionLabel = (
        <LabelWithIconContainer>
            <Icon icon={[FontAwesomeIconsPartooUsed.faFolder, IconPrefix.REGULAR]} />
            {t('custom_fields_section')}
        </LabelWithIconContainer>
    );

    const sections = [
        {
            options: [
                {
                    label: createCustomFieldLabel,
                    value: CustomFieldsCreationKeys.CATALOG,
                    name: CustomFieldsCreationKeys.CATALOG,
                },
                {
                    label: createCustomFieldSectionLabel,
                    value: CustomFieldsCreationKeys.SECTION,
                    name: CustomFieldsCreationKeys.SECTION,
                },
            ],
        },
    ];

    return (
        <DropdownMenuButton
            onMenuClick={value => setCreateCustomField(value)}
            sections={sections}
            staticLabel={t('create')}
            color={theme.colors.primary.initial}
            outlinedButtonStyle
            customPosition="right"
        />
    );
};
