import { push } from '@lagunovsky/redux-react-router';
import { connect } from 'react-redux';

import BottomLinkComponent from 'app/connection/components/common/BottomLink';
import { CONNECTION_LOST_PASSWORD_PATH } from 'app/routing/routeIds';
import { emailSelector } from 'app/states/connection/reducers/login';
import { updateLostPasswordEmail } from 'app/states/connection/reducers/lostPassword';
import { AppState } from 'app/states/reducers';

const mapStateToProps = ({ login }: AppState) => ({
    email: emailSelector(login),
});

const mapDispatchToProps = (dispatch: any) => ({
    onClick: (email: string) => {
        dispatch(updateLostPasswordEmail(email));
        dispatch(push(CONNECTION_LOST_PASSWORD_PATH));
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onClick: () => {
        dispatchProps.onClick(stateProps.email);
    },
});

const LostPasswordLink = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(BottomLinkComponent);

export default LostPasswordLink;
