import { MouseEvent } from 'react';

import {
    Chip,
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    Image,
    Stack,
    Text,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useImagesUploaderContext } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/hooks/useImagesUploaderContext';

import { ClickableRow, ImageUploaderRowHeaderRight, TextWrapper } from './ImageUploaderRow.styled';

const formatSizeUnits = (bytes: number) => {
    if (bytes >= 1073741824) {
        return (bytes / 1_073_741_824).toFixed(2) + ' GB';
    } else if (bytes >= 1_048_576) {
        return (bytes / 1_048_576).toFixed(2) + ' MB';
    } else if (bytes >= 1_024) {
        return (bytes / 1_024).toFixed(2) + ' KB';
    } else if (bytes > 1) {
        return bytes + ' bytes';
    } else if (bytes == 1) {
        return bytes + ' byte';
    } else {
        return '0 bytes';
    }
};

type ImageUploaderRowHeaderProps = {
    imageIndex: number;
    toggleOpen: () => void;
};

export const ImageUploaderRowHeader = ({ imageIndex, toggleOpen }: ImageUploaderRowHeaderProps) => {
    const { t } = useTranslation();
    const { images, textFields, deleteImage } = useImagesUploaderContext();
    const image = images[imageIndex];

    const nonEmptyFieldsCount = Object.values(image.texts).filter(text => !!text).length;
    const hasTextFields = !!textFields.length;

    const onDelete = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        deleteImage(imageIndex);
    };

    return (
        <ClickableRow
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={toggleOpen}
        >
            <Image src={image.url} width={40} height={40} />
            <ImageUploaderRowHeaderRight
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <TextWrapper collapsable={hasTextFields}>
                    <Text variant="bodyMSemibold" oneLine>
                        {image.name}
                    </Text>
                    <Text variant="bodySRegular" oneLine>
                        {formatSizeUnits(image.size)}
                    </Text>
                </TextWrapper>
                <Stack direction="row" gap="16px" alignItems="center">
                    {hasTextFields && (
                        <Chip
                            dataTrackId={`visibility_location__form_custom_fields__chip__${imageIndex}`}
                            chipShape="cube"
                        >
                            {`${nonEmptyFieldsCount} / ${textFields.length} ${t('business_edit_custom_fields_type_images_uploader_required_fields')}`}
                        </Chip>
                    )}
                    <Stack direction="row" gap="0px">
                        <IconButton
                            dataTrackId=""
                            icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                            appearance="outlined"
                            variant="danger"
                            onClick={onDelete}
                        />
                        {hasTextFields && (
                            <IconButton
                                icon={[FontAwesomeIconsPartooUsed.faAngleDown]}
                                dataTrackId={`visibility_location__form_custom_fields__expand__${imageIndex}`}
                                appearance="outlined"
                            />
                        )}
                    </Stack>
                </Stack>
            </ImageUploaderRowHeaderRight>
        </ClickableRow>
    );
};
