import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const getCssContainer = () => css`
    padding: 0;
    width: auto; // Product Sidebar + Collapsible width
    border-radius: 0;
`;

export const StyledStack = styled(Stack)`
    gap: 0;
    flex-flow: row nowrap;
    height: 100vh;
`;
