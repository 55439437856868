import { connect } from 'react-redux';

import { AppState } from 'app/states/reducers';
import {
    averageGradeTotalLabelValueSelector,
    averageGradeTotalLabelsSelector,
    averageGradeTotalSelector,
    isFetchingTotalSelector,
    totalReviewsSelector,
} from 'app/states/reviewAnalytics';

import Doughnut from './Doughnut';

const mapStateToProps = (state: AppState) => {
    const { reviewAnalytics } = state;
    const labels = averageGradeTotalLabelsSelector(reviewAnalytics);
    const labelValues = labels.map(a =>
        averageGradeTotalLabelValueSelector(reviewAnalytics, a),
    );

    return {
        title: 'in_total',
        averageGrade: averageGradeTotalSelector(reviewAnalytics).toFixed(1),
        labels,
        labelValues,
        labelPercentages: labelValues.map(
            a => a / labelValues.reduce((b, c) => b + c, 0),
        ),
        hasReviews: !!totalReviewsSelector(reviewAnalytics),
        isLoading: isFetchingTotalSelector(reviewAnalytics),
    };
};

// @ts-ignore
const TotalDoughnut = connect(mapStateToProps)(Doughnut);

export default TotalDoughnut;
