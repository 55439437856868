import { ForwardedRef, forwardRef, useEffect, useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix, LeftElementType, TextInput } from '@partoohub/ui';

import { convert24hTimeToLocaleFormat, convertTimeTo24hourFormat } from './utils';

interface Props {
    value: string;
    onChange: (value: string | undefined) => void;
    dataTrackId: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    error?: boolean;
    required?: boolean;
}

export const LocaleTimeField = forwardRef(
    (
        { dataTrackId, onChange, value, disabled, label, placeholder, error, required }: Props,
        ref: ForwardedRef<HTMLInputElement>,
    ) => {
        const [typedValue, setTypedValue] = useState(convert24hTimeToLocaleFormat(value));

        useEffect(() => {
            setTypedValue(convert24hTimeToLocaleFormat(value));
        }, [value]);

        const onBlur = () => {
            const typedValue24hour = convertTimeTo24hourFormat(typedValue);

            if (typedValue24hour !== undefined) {
                setTypedValue(convert24hTimeToLocaleFormat(typedValue24hour));
            }
            onChange(typedValue24hour);
        };

        return (
            <TextInput
                ref={ref}
                dataTrackId={dataTrackId}
                disabled={disabled}
                label={label}
                placeholder={placeholder}
                value={typedValue}
                onChange={setTypedValue}
                onBlur={onBlur}
                leftElement={[FontAwesomeIconsPartooUsed.faClock, IconPrefix.SOLID]}
                leftElementType={LeftElementType.Icon}
                error={error}
                required={required}
            />
        );
    },
);
