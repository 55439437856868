import {
    CONNECTION_INVITE_PATH,
    CONNECTION_LOST_PASSWORD_PATH,
    CONNECTION_PATH,
    CONNECTION_RESET_PASSWORD_TEMPLATE_PATH,
    LOG_OUT_PATH,
} from 'app/routing/routeIds';

import completeInvitationSaga from './completeInvitationSaga';
import { initialConnectionRequestSaga } from './loginSaga';
import logoutSaga from './logoutSaga';
import lostPasswordSaga from './lostPasswordSaga';
import resetPasswordSaga from './resetPasswordSaga';

const pageSagas = {
    [CONNECTION_INVITE_PATH]: [completeInvitationSaga],
    [CONNECTION_LOST_PASSWORD_PATH]: [lostPasswordSaga],
    [CONNECTION_RESET_PASSWORD_TEMPLATE_PATH]: [resetPasswordSaga],
    [CONNECTION_PATH]: [initialConnectionRequestSaga],
    [LOG_OUT_PATH]: [logoutSaga],
};
export default pageSagas;
