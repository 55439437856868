import React, { ReactNode } from 'react';

import { Text } from '@partoohub/ui';

import ExplanationWithPublisherList, {
    PublisherErrors,
    PublishersInfo,
} from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithPublisherList';

import {
    BusinessEditSectionContainer,
    BusinessEditSectionContent,
    BusinessEditSectionHeader,
    BusinessEditSectionPublisher,
    BusinessEditSectionTitle,
} from './Section.styled';

type Props = {
    title: string;
    // Optional props
    description?: string;
    children?: ReactNode;
    noPadding?: boolean;
    disabled?: boolean;
    button?: ReactNode;
    publisherErrors?: PublisherErrors;
    publishersInfo?: PublishersInfo;
};

// Used by business creation and admin
const Section = ({
    title,
    description = '',
    children,
    disabled = false,
    noPadding = false,
    button = undefined,
    publisherErrors,
    publishersInfo,
}: Props) => (
    <BusinessEditSectionContainer>
        <BusinessEditSectionHeader childrenExist={!!children}>
            <div>
                <BusinessEditSectionTitle>
                    <Text disabled={disabled} variant="heading6" color={'dark'}>
                        {title}
                    </Text>
                    {publisherErrors && publishersInfo && (
                        <BusinessEditSectionPublisher>
                            <ExplanationWithPublisherList
                                publisherErrors={publisherErrors}
                                publishersInfo={publishersInfo}
                                disabled={disabled}
                            />
                        </BusinessEditSectionPublisher>
                    )}
                </BusinessEditSectionTitle>
                {description && (
                    <Text disabled={disabled} variant={'bodyMBold'} color={'secondary'}>
                        {description}
                    </Text>
                )}
            </div>
            {button !== undefined && <div>{button}</div>}
        </BusinessEditSectionHeader>
        <BusinessEditSectionContent childrenExist={!!children} noPadding={noPadding}>
            {children}
        </BusinessEditSectionContent>
    </BusinessEditSectionContainer>
);

export default Section;
