import { PARTOO_OAUTH_ACCOUNT } from 'app/businessConnection/hooks/useImportOAuthAccount';
import { usePopupWindow } from 'app/common/hooks/usePopupWindow';
import { FACEBOOK_OAUTH2_START_PATH } from 'app/routing/routeIds';

export default function useFacebookConnectWindow(onClose: (oauthAccountId: string | null) => void) {
    const openWindow = usePopupWindow(`${FACEBOOK_OAUTH2_START_PATH}`, {
        onClose: () => {
            const account = localStorage.getItem(PARTOO_OAUTH_ACCOUNT);
            const oauthAccountId = account ? account.split('-')[1] : null;
            localStorage.removeItem(PARTOO_OAUTH_ACCOUNT);

            onClose(oauthAccountId);
        },
    });

    return () => {
        localStorage.removeItem(PARTOO_OAUTH_ACCOUNT);
        openWindow();
    };
}
