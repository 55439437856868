import React from 'react';

import { useTranslation } from 'react-i18next';

import { useLanguage } from 'app/common/services/getLang';
import {
    IconMonthPicker,
    IconReset,
    IconResetContainer,
    MonthYearPickerContainer,
    MonthYearPickerFrom,
    MonthYearPickerTo,
    OverrideDatePicker,
    Separator,
    Text,
    TextBetweenMonthPicker,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/MonthYearPicker/MonthYearPicker.styled';
import { getUTCDate } from 'app/utils/dates';

export type Props = {
    startDate: Date;
    endDate: Date;
    handleStartDate: any;
    handleEndDate: any;
    handleReset: () => void;
    monthFormat: string;
    minDate: any;
    maxDate: Date | null;
    canReset?: boolean;
    dataTrack?: string;
};

export const MonthYearPicker = ({
    startDate,
    endDate,
    handleStartDate,
    handleEndDate,
    handleReset,
    monthFormat,
    minDate,
    maxDate,
    canReset,
    dataTrack,
}: Props) => {
    const { t } = useTranslation();
    const language = useLanguage();

    const commonRenderMonthContent = (monthNumber: number) => {
        const firstDayOfPreviousMonth = new Date();
        firstDayOfPreviousMonth.setDate(1);
        firstDayOfPreviousMonth.setMonth(monthNumber);
        const month = firstDayOfPreviousMonth.toLocaleString(language, {
            month: 'short',
        });
        return month;
    };

    const startDateUTC = getUTCDate(startDate);
    const endDateUTC = getUTCDate(endDate);

    return (
        <MonthYearPickerContainer data-track={dataTrack} data-intercom-target={dataTrack}>
            <IconMonthPicker className="fa fa-fas fa-calendar" />
            <Separator />
            <MonthYearPickerFrom>
                <OverrideDatePicker
                    placeholderText={t('start_date')}
                    className="react-datepicker-from"
                    selected={startDateUTC}
                    onChange={handleStartDate}
                    selectsStart
                    dateFormat={monthFormat}
                    minDate={minDate}
                    maxDate={endDateUTC ? endDateUTC : maxDate}
                    showMonthYearPicker
                    renderMonthContent={commonRenderMonthContent}
                >
                    <Text>{t('select_month')}</Text>
                </OverrideDatePicker>
            </MonthYearPickerFrom>
            <TextBetweenMonthPicker>-</TextBetweenMonthPicker>
            <MonthYearPickerTo>
                <OverrideDatePicker
                    placeholderText={t('end_date')}
                    className="react-datepicker-to"
                    selected={endDateUTC}
                    onChange={value => handleEndDate(value)}
                    selectsEnd
                    dateFormat={monthFormat}
                    minDate={startDateUTC ? startDateUTC : minDate}
                    maxDate={maxDate}
                    showMonthYearPicker
                    renderMonthContent={commonRenderMonthContent}
                >
                    <Text>{t('select_month')}</Text>
                </OverrideDatePicker>
            </MonthYearPickerTo>
            <IconResetContainer>
                {(startDateUTC || endDateUTC) && canReset && (
                    <IconReset className="fa fa-fas fa-circle-xmark" onClick={handleReset} />
                )}
            </IconResetContainer>
        </MonthYearPickerContainer>
    );
};
