import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
    PostCardMainBusinessIcon,
    PostCardMainBusinessInfo,
    PostCardMainBusinessName,
    PostCardMainContainer,
    PostCardMainDescription,
    ReadMoreButton,
} from 'app/common/designSystem/components/molecules/PreviewPostCard/PreviewPostCardMain.styled';

type Props = {
    summary: string;
    businessInfo: string;
};

const CHARACTERS_TO_COLLAPSE = 300;

function calculateCollapsedString(summary, collapsed, needsCollapse, t) {
    if (needsCollapse) {
        if (collapsed) {
            return summary;
        }
        return `${summary.substring(0, CHARACTERS_TO_COLLAPSE)} ...`;
    }

    if (!summary || summary.length === 0) {
        return t('post_start_typing_preview');
    }

    return summary;
}

const PostCardMain = ({ summary, businessInfo }: Props) => {
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const needsCollapse: boolean = summary?.length > CHARACTERS_TO_COLLAPSE;
    const collapsedText: string = calculateCollapsedString(
        summary,
        collapsed,
        needsCollapse,
        t,
    );

    const onCollapseToggle = (): void => {
        setCollapsed(!collapsed);
    };

    return (
        <PostCardMainContainer>
            {businessInfo && (
                <PostCardMainBusinessName>
                    <PostCardMainBusinessIcon className="fa-solid fa-map-marker-alt" />
                    <PostCardMainBusinessInfo>
                        {businessInfo}
                    </PostCardMainBusinessInfo>
                </PostCardMainBusinessName>
            )}
            <PostCardMainDescription>{collapsedText}</PostCardMainDescription>
            {needsCollapse && (
                <ReadMoreButton onClick={onCollapseToggle}>
                    <span>
                        {t(`post_see_${collapsed ? 'less' : 'more'}_preview`)}
                    </span>
                    <i
                        className={`fa fa-chevron-${collapsed ? 'up' : 'down'}`}
                    />
                </ReadMoreButton>
            )}
        </PostCardMainContainer>
    );
};

export default PostCardMain;
