import { CompetitorCentralRank } from 'app/api/types/competitiveBenchmark';

import useMe from 'app/common/hooks/queries/useMeUncamel';

import useOrganization from 'app/common/hooks/queries/useOrganization';

import { useComparisonDateFilter } from './useComparisonDateFilter';
import { useCompetitiveOverview } from './useCompetitiveOverview';
import { useGlobalRankings } from './useGlobalRankings';

export type CompetitorsColors = Record<CompetitorCentralRank['name'], CompetitiveBenchmarkColor>;

export const COMPETITIVE_COLORS = [
    '#0085F2',
    '#BFA6F8',
    '#FBDD73',
    '#00BAFF',
    '#947400',
    '#992842',
    '#FF5C9A',
    '#FF9014',
    '#2CB884',
    '#005091',
    '#D13C1F',
    '#B2C7E6',
] as const;

export const CLIENT_COLOR = '#101E35';

export const DEFAULT_COLOR = 'grey';

export type CompetitiveBenchmarkColor =
    | typeof CLIENT_COLOR
    | typeof DEFAULT_COLOR
    | (typeof COMPETITIVE_COLORS)[number];

export const useCompetitorColors = (): Record<string, CompetitiveBenchmarkColor> => {
    const [currentDate] = useComparisonDateFilter();
    const { data: globalRanks } = useGlobalRankings(currentDate);
    const { overview } = useCompetitiveOverview();
    const { data: user } = useMe();
    const { data: org } = useOrganization(user?.org_id ?? 0);

    const data = Object.fromEntries(globalRanks.map(rank => [rank.name, rank.highlight]));
    for (const business of overview.businesses) {
        if (!(business.name in data)) {
            data[business.name] = org?.name === business.name;
        }
    }
    return Object.keys(data)
        .sort((a, b) => a.localeCompare(b))
        .reduce((acc, name, index) => {
            acc[name] = data[name]
                ? CLIENT_COLOR
                : COMPETITIVE_COLORS[index % COMPETITIVE_COLORS.length];
            if (index >= COMPETITIVE_COLORS.length) {
                acc[name] = DEFAULT_COLOR;
            }
            return acc;
        }, {} as CompetitorsColors);
};
