import { useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton, Modal, Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import ReviewReportModal from 'app/settingsManagement/components/modal/ReviewReportModal';

import { Title } from './ReviewReportTitle.styled';

export const ReviewReportTitle = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            <Stack gap="0" direction="row" alignItems="center">
                <Title>{t('review_report')}</Title>
                <IconButton
                    dataTrackId="review_report__icon_button"
                    appearance="outlined"
                    variant="default"
                    tooltip={t('more_info')}
                    onClick={toggleModal}
                    size="small"
                    icon={[FontAwesomeIconsPartooUsed.faCircleQuestion]}
                />
            </Stack>
            <Modal isOpen={showModal} closeModal={toggleModal}>
                <ReviewReportModal />
            </Modal>
        </>
    );
};
