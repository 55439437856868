import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { V2UserData } from 'app/api/types/user';
import api from 'app/api/v2/api_calls/camel';
import { ME } from 'app/common/data/queryKeysConstants';
import { Camel } from 'app/utils/types';

interface Options {
    enabled?: boolean;
    onSuccess?: (data: Camel<V2UserData>) => void;
    onError?: (err: AxiosError) => void;
    retry?: boolean;
    refetchOnReconnect?: boolean;
    refetchOnWindowFocus?: boolean;
}

/**
 * @deprecated
 * Please use the uncamel me instead
 */
export default function useMe(options?: Options) {
    return useQuery([ME], () => api.user.getMe(), options);
}
