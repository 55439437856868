import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

import { Container } from 'app/common/components/funnel/FunnelModal.styled';
import { FullButton, FullButtonWrapper } from 'app/onboarding/SelectionPage/SelectionPage.styled';

export const StartAddAddressContainer = styled(Container)`
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-top: 6%;
    gap: 40px;
`;

export const InformationsContainer = styled.div`
    width: 532px;
`;

export const Title = styled(Text)`
    margin-bottom: 8px;
`;

export const Description = styled(Text)`
    margin-bottom: 24px;
`;

export const OverrideFullButtonWrapper = styled(FullButtonWrapper)`
    padding: 0 15%;
`;

export const ContinueButton = styled(FullButton)`
    flex-grow: 1;
`;

export const SkipButton = styled(FullButton)`
    width: 150px;
    padding: 16px;
`;

export const SkipText = styled(Text)`
    width: 118px;
`;
