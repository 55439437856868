import { useEffect, useMemo, useRef, useState } from 'react';

import { Modal } from '@partoohub/ui';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SpecificHourFormData } from 'app/api/types/business';
import EditForm from 'app/businessEditV2/components/EditForm';

import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import useSpecificHoursSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useSpecificHoursSectionColor';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useFormFieldFormatter from 'app/businessEditV2/hooks/publisherErrors/useFormFieldFormatter';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import ExplanationWithPublisherList from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithPublisherList';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

import HolidaysSuggestion from './HolidaysSuggestion';
import SpecificHours from './SpecificHours';
import { mergeSpecificHours } from '../../../utils/specificHours';
import {
    OpenHoursDescription,
    OpenHoursDescriptionText,
    OpenHoursPrechecks,
} from '../../OpenHoursSection/OpenHoursForm/OpenHoursForm.styled';

import TimeSlotReferenceModalContent from '../../OpenHoursSection/OpenHoursForm/TimeSlotReferenceModal';

type Props = {
    closeModal: () => void;
    useDirtyUpdate: (value: boolean) => void;
};

const SpecificHoursForm = ({ closeModal, useDirtyUpdate }: Props) => {
    const { t } = useTranslation();

    // Business Hooks
    const colorState = useSpecificHoursSectionColor();
    const { data: business, isLoading } = useBusiness();

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();

    const businessUpdate = useBusinessUpdate(() => {
        businessUpdateCompletionRate.mutate();
        closeModal();
    });
    // Form Hooks
    const setFormValue = () => ({
        specificHours: mergeSpecificHours(business?.specific_hours ?? {}),
    });
    const {
        control,
        handleSubmit,
        formState: { isDirty },
        watch,
        reset,
        setValue,
    } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    const refAddSpecificHour = useRef<HTMLElement>(null);

    const scrollToRef = () => {
        setTimeout(() => {
            refAddSpecificHour.current?.scrollIntoView({ behavior: 'smooth' });
        }, 50);
    };

    const setSpecificHours = (specificHours: SpecificHourFormData) => {
        setValue('specificHours', specificHours, { shouldDirty: true });
    };

    useDirtyUpdate(isDirty);

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const onSubmit = payload => {
        businessUpdate.mutate(payload);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_SPECIFIC_HOURS_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
    };
    const submitForm = () => {
        handleSubmit(payload => {
            onSubmit(formatPayload(payload));
        })();
        trackSaveBusinessEditSection(SectionNames.SPECIFIC_HOURS, colorState);
    };

    const [showTimeSlotReferenceModal, setShowTimeSlotReferenceModal] = useState<boolean>(false);
    const timeSlotReference = business?.time_slot_reference;

    const formatPayload = payload => {
        if (!specificHoursPermission) {
            return {};
        }

        return {
            specific_hours: {
                open: payload.specificHours
                    .filter(period => period.open)
                    .map(period => ({
                        starts_at: period.starts_at,
                        ends_at: period.ends_at,
                        open_hours: period.open_hours,
                    })),
                close: payload.specificHours
                    .filter(period => !period.open)
                    .map(period => ({
                        starts_at: period.starts_at,
                        ends_at: period.ends_at,
                    })),
            },
        };
    };

    const errors = businessUpdate.error?.response?.data?.errors?.json?.specific_hours;

    // Permission
    const businessIsOpen = !useIsBusinessClosed();
    const specificHoursPermission =
        useFieldMetadata('hours', 'specific_hours')?.enabled && businessIsOpen;

    // Publisher errors
    const formatter = useFormFieldFormatter(
        'hours',
        'specific_hours',
        watch,
        formatPayload,
        errors,
    );

    return (
        <>
            <EditForm
                title={t('business_specific_hours')}
                description={
                    <OpenHoursDescription>
                        <OpenHoursDescriptionText>
                            {t('app-infos-business_specific_hours_explanation')}
                        </OpenHoursDescriptionText>
                        {formatter.hasWarning && (
                            <OpenHoursPrechecks>
                                <ExplanationWithPublisherList
                                    publisherErrors={formatter.publisherErrors}
                                    publishersInfo={formatter.publishersInfo}
                                    disabled={formatter.disabled}
                                />
                            </OpenHoursPrechecks>
                        )}
                    </OpenHoursDescription>
                }
                descriptionRight={
                    !!specificHoursPermission ? (
                        <StrikeThroughButton
                            icon={<i className="fa-solid fa-cog" />}
                            text={t(
                                (timeSlotReference ?? []).length === 0
                                    ? 'time_slot_reference'
                                    : 'update_time_slot_reference',
                            )}
                            color={'secondary'}
                            onClick={() => setShowTimeSlotReferenceModal(true)}
                            disabled={!specificHoursPermission}
                        />
                    ) : undefined
                }
                colorState={colorState}
            >
                {!!specificHoursPermission && (
                    <HolidaysSuggestion
                        specificHours={watch('specificHours') as SpecificHourFormData}
                        onChange={setSpecificHours}
                        scrollToRef={scrollToRef}
                    />
                )}

                <div ref={refAddSpecificHour}>
                    <Controller
                        name="specificHours"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <SpecificHours
                                onChange={onChange}
                                errors={errors || {}}
                                disabled={!specificHoursPermission}
                                specificHours={value}
                                resetBusinessApiResponse={businessUpdate.reset}
                                timeSlotReference={timeSlotReference}
                                openTimeSlotReferenceModal={() =>
                                    setShowTimeSlotReferenceModal(true)
                                }
                                scrollToRef={scrollToRef}
                            />
                        )}
                    />
                </div>
                <Modal
                    isOpen={showTimeSlotReferenceModal}
                    closeModal={() => setShowTimeSlotReferenceModal(false)}
                >
                    <TimeSlotReferenceModalContent
                        closeModal={() => setShowTimeSlotReferenceModal(false)}
                    />
                </Modal>
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={businessUpdate.isLoading}
                    hasError={!!businessUpdate.error}
                    onClick={submitForm}
                    dataTrackId="visibility_location__form_specific_hours__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default SpecificHoursForm;
