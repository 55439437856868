import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const grow = keyframes`
    from { transform: scale(0) }
    to { transform: scale(1) }
`;

const drawCheck = keyframes`
    to { stroke-dashoffset: 0 }
`;

const backCircle = keyframes`
    from {
        transform: scale(0.9);
        opacity: 0;
    }
    1% {
        transform: scale(0.9);
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
    to {
        transform: scale(1.4);
        opacity: 0;
    }
`;

const float = keyframes`
    0% { transform: translatey(-8px) }
    100% { transform: translatey(8px) }
`;

export const StatusWrapper = styled.div`
    position: absolute;
    
    width: 100%;
    max-width: 312px;
    height: 100%
    max-height: 142px;
`;

export const StatusCircle = styled.div`
    position: absolute;
    top: 87px;
    left: 189px;

    border-radius: 50%;

    height: 34px;
    width: 34px;
`;

export const StatusCircleOne = styled(StatusCircle)`
    background-color: #c7f1e0;

    animation: ${grow} 0.4s ease-in 0.2s backwards;
`;

export const StatusCircleTwo = styled(StatusCircle)`
    background-color: #40dba2;

    animation: ${grow} 0.4s ease-in 0.5s backwards;
`;

export const StatusCircleBack = styled(StatusCircle)`
    background-color: #40dba2;

    animation: ${backCircle} 0.8s ease-out 1.3s backwards;
`;

export const StatusCheckWrapper = styled(StatusCircle)`
    svg {
        stroke-dasharray: 21;
        stroke-dashoffset: -21;

        animation: ${drawCheck} 0.5s linear 0.9s forwards;
    }
`;

export const DecorationHovering = styled.div`
    position: absolute;
    top: 0;
`;

export const DecorationUp = styled(DecorationHovering)`
    animation: ${float} 3s ease-in-out infinite alternate;
`;

export const DecorationDown = styled(DecorationHovering)`
    animation: ${float} 3s ease-in-out infinite alternate-reverse;
`;
