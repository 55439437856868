import React from 'react';

import { Controller } from 'react-hook-form';

import { Control } from 'react-hook-form/dist/types';

import AsyncSingleSelect, {
    Props,
} from 'app/common/designSystem/components/molecules/AsyncSingleSelect/AsyncSingleSelect';

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'onChange';

const ControlledAsyncSingleSelect = ({
    name,
    control,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { ref: _ref, value, ...usedFields } }) => (
            <AsyncSingleSelect
                selectedValue={value}
                {...props}
                {...usedFields}
            />
        )}
    />
);

export default ControlledAsyncSingleSelect;
