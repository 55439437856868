import { useMemo } from 'react';

import useMetricsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useMetricsAnalytics';
import { useKeywordsSearchQueryParams } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/useKeywordsSearchQueryParams';
import { useGetDimension } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/views/useGetDimensions';
import { normalizrViews } from 'app/pages/analytics/VisibilityAnalyticsV2/services/charts/normalizrViews';
import { MetricsAttributesResponse } from 'app/pages/analytics/VisibilityAnalyticsV2/types/metrics';
import { MetricsApiKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/types/query';

const METRICS_VIEWS = [
    MetricsAttributesResponse.BUSINESS_IMPRESSIONS_DESKTOP_MAPS,
    MetricsAttributesResponse.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH,
    MetricsAttributesResponse.BUSINESS_IMPRESSIONS_MOBILE_MAPS,
    MetricsAttributesResponse.BUSINESS_IMPRESSIONS_MOBILE_SEARCH,
];

export const useMetricsViewsChart = () => {
    const queryTmp = useKeywordsSearchQueryParams();
    const { dimensions } = useGetDimension();
    const dates = useMemo(
        () => ({
            from: queryTmp.filter_date__gte || new Date(),
            to: queryTmp.filter_date__lte || new Date(),
        }),
        [queryTmp.filter_date__gte, queryTmp.filter_date__lte],
    );
    const query = {
        ...queryTmp,
        [MetricsApiKeys.METRICS]: METRICS_VIEWS.join(','),
        [MetricsApiKeys.DIMENSIONS]: dimensions,
        [MetricsApiKeys.ORDER_BY]: dimensions,
    };

    const { data, isLoading, error, refetch } = useMetricsAnalytics(query);

    const dataChart = useMemo(() => {
        if (!data || data?.metrics?.length <= 0) return null;
        return {
            ...normalizrViews(data?.metrics, dates),
        };
    }, [data]);

    return {
        isLoading,
        data: dataChart,
        error,
        refetch,
    };
};
