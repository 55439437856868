import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

import {
    FunnelModalBody,
    FunnelModalFooter,
    FunnelModalHeader,
} from 'app/common/components/FunnelModal/FunnelModal.styled';

export const StyledBoxIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: rgba(0, 133, 242, 0.12);
    border-radius: 4px;
    margin-right: 8px;
`;

export const StyledFunnelModalHeader = styled(FunnelModalHeader)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
`;

export const StyledRowsContainer = styled(Stack)`
    gap: 40px;
    margin-bottom: 16px;
`;

export const StyledFunnelModalBody = styled(FunnelModalBody)`
    padding: 24px 24px 8px 24px;
`;

export const StyledFunnelModalFooter = styled(FunnelModalFooter)`
    border-top: 0px;
`;
