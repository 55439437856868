import React, { useContext } from 'react';

import { V2FormattedLocationData } from 'app/api/types/account';
import { V2FormattedBusinessData } from 'app/api/types/business';
import BusinessCard from 'app/businessConnection/components/common/BusinessCard/BusinessCard';
import LocationCard from 'app/businessConnection/components/common/LocationCard/LocationCard';
import QuickConnectContext from 'app/businessConnection/components/genericQuickConnect/QuickConnect.context';
import { ClickableWrapper } from 'app/businessConnection/components/genericQuickConnect/SuggestionSection/SuggestionRow.styled';

type Props = {
    businessInfo: V2FormattedBusinessData;
    locationInfo: V2FormattedLocationData;
    isSelected: boolean;
    onClick: (businessId: any) => void;
    isLoading: boolean;
    hasError: boolean;
};

const SuggestionRow = ({
    businessInfo,
    locationInfo,
    isSelected,
    onClick,
    isLoading,
    hasError,
}: Props) => {
    let modifier = '';

    if (isSelected) {
        modifier = isLoading ? 'loading' : 'selected';
    } else if (hasError) {
        modifier = 'error';
    }

    const { publisher: ctxPublisher } = useContext(QuickConnectContext);
    return (
        <ClickableWrapper
            modifier={modifier}
            onClick={() => {
                if (!isLoading)
                    onClick({
                        businessInfo,
                        locationInfo,
                    });
            }}
        >
            <BusinessCard business={businessInfo} noBorder />
            <div className="suggestions_list_row_arrow">
                <i className="fa-solid fa-arrow-right fa-lg" />
            </div>
            <LocationCard location={locationInfo} partnerName={ctxPublisher} noBorder />
        </ClickableWrapper>
    );
};

export default SuggestionRow;
