import React, { FC, ReactNode } from 'react';

import { Global, ThemeProvider } from '@emotion/react';
import { darkTheme, theme } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import env from 'app/common/services/getEnvironment';
import { global, globalWithScrollbarStyling } from 'app/global.styled';

import { useIsMac } from './common/hooks/useIsMac';

type Props = {
    children?: ReactNode;
};

const USE_DARK_THEME = env.isDev() || env.isStaging();
const PARTOO_THEME_KEY = 'partooTheme';

/**
 * Handle everything related to the theme of the app
 */
export const Theme: FC<Props> = ({ children }) => {
    const [partooTheme] = useStateQueryParams(PARTOO_THEME_KEY);
    const selectedTheme = partooTheme === 'dark' && USE_DARK_THEME ? darkTheme : theme;
    const isMac = useIsMac();
    const globalStyle = isMac ? global : globalWithScrollbarStyling;

    return (
        <>
            <Global styles={globalStyle} />
            <ThemeProvider theme={selectedTheme}>{children}</ThemeProvider>
        </>
    );
};
