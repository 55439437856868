import { useMutation } from 'react-query';

import { useNavigate } from 'react-router';

import { MessagingSetting } from 'app/api/types/business_message_settings';
import api from 'app/api/v2/api_calls';
import { CONVERSATION_STARTERS } from 'app/common/data/queryKeysConstants';
import useMessageSettingsConversationStarterIdUpdate from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useMessageSettingsConversationStarterIdUpdate';
import { ConversationStarter } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';
import queryClient from 'app/states/queryClient';

export default function useOldConversationStarterCreate(selectedSetting: MessagingSetting | null) {
    const updateBMSettingsMutation = useMessageSettingsConversationStarterIdUpdate(selectedSetting);
    return useMutation(() => api.conversationStarters.createConversationStarter(), {
        onSuccess: (data: ConversationStarter) => {
            updateBMSettingsMutation.mutate(data.id);
        },
    });
}

export function useConversationStarterCreate() {
    const navigate = useNavigate();
    return useMutation(() => api.conversationStarters.createConversationStarter(), {
        onSuccess: (data: ConversationStarter) => {
            const search = '?conversationStarterId=' + data.id;
            navigate({ search }, { replace: true });
            queryClient.invalidateQueries([CONVERSATION_STARTERS]);
        },
    });
}
