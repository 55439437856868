import { useMutation, useQueryClient } from 'react-query';

import { FormattedUserData } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { USERS } from 'app/common/data/queryKeysConstants';

export function useEditUserOld(
    userId: string,
    formatData: FormattedUserData,
    onSuccess: () => void = () => undefined,
    onError: (error: Record<string, any>) => void = () => undefined,
) {
    const queryClient = useQueryClient();

    return useMutation(() => api.user.editUser(userId, formatData), {
        onSuccess: () => {
            queryClient.invalidateQueries(invalidatesAny(USERS)).then(onSuccess);
        },
        onError,
    });
}

export default function useEditUser(
    userId: string,
    onSuccess: () => void = () => undefined,
    onError: (error: Record<string, any>) => void = () => undefined,
) {
    return useMutation((payload: FormattedUserData) => api.user.editUser(userId, payload), {
        onSuccess,
        onError,
    });
}
