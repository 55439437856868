import { HEX_COLORS } from '@partoohub/branding';
import { useTranslation } from 'react-i18next';

import { V2BusinessData } from 'app/api/types/business';
import AlertBanner from 'app/common/designSystem/components/molecules/AlertBanner';

type Props = {
    business: V2BusinessData | undefined;
    businessHasReviewBooster: boolean;
    businessHasReviewUrl: boolean;
};

const MobileAlertBanner = ({ business, businessHasReviewBooster, businessHasReviewUrl }: Props) => {
    const { t } = useTranslation();

    if (!business) return null;
    return (
        <>
            {!businessHasReviewBooster && (
                <AlertBanner
                    title={t('rb_business_not_subscribed_to_rb_banner_title')}
                    color={HEX_COLORS.primary}
                />
            )}

            {!businessHasReviewUrl && (
                <AlertBanner
                    title={t('rb_connect_location_banner_title_mobile')}
                    color={HEX_COLORS.warning}
                />
            )}
        </>
    );
};

export default MobileAlertBanner;
