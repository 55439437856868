import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const StyledStars = styled.span`
    flex: 1;
    font-size: 14px;
    @media (max-width: 980px) {
        font-size: 12px;
        line-height: 2;
    }
    color: ${HEX_COLORS.ratings};
`;
