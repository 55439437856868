import { useMemo } from 'react';

import { uniqBy } from 'lodash-es';
import { useInfiniteQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { BUSINESSES } from 'app/common/data/queryKeysConstants';
import { useBusinessSearchWithPmQueryParams } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/useBusinessWithPmSearchQueryParams';
import { BusinessServicesApiKey } from 'app/pages/visibility/BusinessListV2/hooks/queryParams/params/useGetProductFilter';

export default function useBusinessWithPmSearch() {
    const query = useBusinessSearchWithPmQueryParams() || {
        [BusinessServicesApiKey.PRESENCE]: 'true',
    };
    const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(
        [BUSINESSES, { query }],
        ({ pageParam = 1 }) =>
            api.business.searchBusinesses({
                ...query,
                page: pageParam,
            }),
        {
            getNextPageParam: lastPage => {
                // Return undefined to indicate last page
                return lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined;
            },
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );

    const businesses = useMemo(
        () => uniqBy(data?.pages?.flatMap(page => page.businesses) ?? [], business => business.id),
        [data],
    );

    return {
        businesses,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetchingNextPage,
        count: data?.pages?.[0]?.count,
    };
}
