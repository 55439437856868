import { useMemo } from 'react';

import { parseISO } from 'date-fns';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { parseDate, stringifyDate } from 'app/utils/dates';

export function useDateFilter(
    paramName: string,
    apiParamName: string,
    apiDateFormatter: (date: Date | null) => Date,
) {
    const [value, setValue] = useStateQueryParams(paramName);

    return useMemo(() => {
        const parsedValue = value ? parseDate(value) : null;
        const apiValue = value ? apiDateFormatter(parseISO(value)).toISOString() : undefined;

        return [
            parsedValue,
            (parsedValue: Date | null) => setValue(stringifyDate(parsedValue)),
            {
                [apiParamName]: apiValue,
            },
        ] as const;
    }, [value]);
}
