import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';

export enum CEOrgFeatures {
    RB = 'RB',
    RM = 'RM',
    FeedbackManagement = 'FeedbackManagement', // Base FBM org feature
    DownloadFBM = 'DownloadFBM',
    FBMReplySuggestion = 'FBMReplySuggestion',
    AutoReplyWithoutComments = 'AutoReplyWithoutComments',
    AutoReplyWithComments = 'AutoReplyWithComments',
}

export const useHasCEOrgFeatures = (): {
    orgFeatures: Record<CEOrgFeatures, boolean | undefined>;
    isLoading: boolean;
} => {
    const { data: me, isLoading: meIsLoading } = useMe();
    const { data: org, isLoading: orgIsLoading } = useOrganization(me?.org_id ?? 0, !!me?.org_id);
    const {
        subscribedToRB,
        subscribedToRM,
        isLoading: businessSubscriptionsLoading,
    } = useBusinessSubscriptions();

    return {
        orgFeatures: {
            [CEOrgFeatures.RB]: subscribedToRB,
            [CEOrgFeatures.RM]: subscribedToRM,
            [CEOrgFeatures.FeedbackManagement]:
                org?.customer_experience_features?.['FeedbackManagement'],
            [CEOrgFeatures.DownloadFBM]: org?.customer_experience_features?.['DownloadFBM'],
            [CEOrgFeatures.FBMReplySuggestion]:
                org?.customer_experience_features?.['FBMReplySuggestion'],
            [CEOrgFeatures.AutoReplyWithoutComments]:
                org?.customer_experience_features?.['AutoReplyWithoutComments'],
            [CEOrgFeatures.AutoReplyWithComments]:
                org?.customer_experience_features?.['AutoReplyWithComments'],
        },
        isLoading: meIsLoading || orgIsLoading || businessSubscriptionsLoading,
    };
};
