import styled from '@emotion/styled';

export const OverflowLeftModal = styled.div`
    overflow-y: auto;
    width: 100%;
    position: relative;
    flex: 1;
`;

export const OverflowRightModal = styled.div`
    overflow-y: auto;
    width: 100%;
`;

type ProgressType = {
    progress: number;
};

export const ProgressContainer = styled.div`
    width: 100%;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.primary.initial};
    position: absolute;
    top: 0;
    left: 0;
`;

export const ProgressContent = styled.div`
    ${(props: ProgressType) => `width: ${Math.min(100, Math.round(props.progress * 100))}%;`}
    background-color: ${({ theme }) => theme.colors.primary.initial};
    height: 100%;
`;
