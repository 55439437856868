import { connect } from 'react-redux';

import { AppState } from 'app/states/reducers';
import {
    averageGradePeriodLabelValueSelector,
    averageGradePeriodLabelsSelector,
    averageGradePeriodSelector,
    isFetchingPeriodSelector,
    periodReviewsSelector,
} from 'app/states/reviewAnalytics';
import {
    dateFilterSelector,
    reviewFiltersSelector,
} from 'app/states/reviews/filters';

import Doughnut from './Doughnut';

const mapStateToProps = (state: AppState) => {
    const { reviewAnalytics } = state;
    const reviewFilters = reviewFiltersSelector(state);
    const labels = averageGradePeriodLabelsSelector(reviewAnalytics);
    const labelValues = labels.map(a =>
        averageGradePeriodLabelValueSelector(reviewAnalytics, a),
    );
    const dateFilters = dateFilterSelector(reviewFilters);

    return {
        title:
            dateFilters.from || dateFilters.to
                ? 'period_reviews'
                : 'last_six_months',
        averageGrade: averageGradePeriodSelector(reviewAnalytics).toFixed(1),
        labels,
        labelValues,
        labelPercentages: labelValues.map(
            a => a / labelValues.reduce((b, c) => b + c, 0),
        ),
        hasReviews: !!periodReviewsSelector(reviewAnalytics),
        isLoading: isFetchingPeriodSelector(reviewAnalytics),
    };
};

// @ts-ignore
const PeriodDoughnut = connect(mapStateToProps)(Doughnut);

export default PeriodDoughnut;
