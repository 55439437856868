import dayjs from 'dayjs';

import { V2UserData } from 'app/api/types/user';
import {
    Conversation,
    ConversationMessage,
    MESSAGE_CONTENT_TYPE,
    MESSAGE_SENDER_TYPE,
    MESSAGE_STATUS,
    MessageContentType,
} from 'app/states/messaging';

export const createStatusMessage = (
    conversation: Conversation,
    contentType: MessageContentType,
    me?: V2UserData,
): ConversationMessage => {
    const partialMessage = createPartialMessage(conversation, me);

    return {
        ...partialMessage,
        content_type: contentType,
    };
};

export const createAssignmentMessage = (
    conversation: Conversation,
    content: string,
    me?: V2UserData,
): ConversationMessage => {
    const partialMessage = createPartialMessage(conversation, me);
    const contentType = content ? MESSAGE_CONTENT_TYPE.ASSIGNING : MESSAGE_CONTENT_TYPE.UNASSIGNING;

    return {
        ...partialMessage,
        content: content || '',
        content_type: contentType,
    };
};

const createPartialMessage = (conversation: Conversation, me: V2UserData | undefined) => {
    const date = dayjs().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSSZ');
    const userId = me?.id ?? '';
    const senderLabel = `${me?.first_name} ${me?.last_name}`;

    return {
        parentConversation: conversation,
        content: '',
        date: date,
        is_deleted: false,
        sender_type: MESSAGE_SENDER_TYPE.METADATA,
        status: MESSAGE_STATUS.SUCCESS,
        is_automated: false,
        user_id: userId,
        sender_label: senderLabel,
    };
};
