import { LiteralRating } from 'app/api/types/review';

export const WITHOUT_TAG = -1;
export const WITHOUT_TAG_AS_STRING = WITHOUT_TAG.toString();

export const ratingToRange = (ratings: Array<LiteralRating>) => {
    const rangeByRating = {
        negative: [1, 2],
        neutral: [3],
        positive: [4, 5],
    } as Record<LiteralRating, number[]>;

    const range = ratings.map(rating => rangeByRating[rating] ?? []).flat();
    return range.length ? range : null;
};

export const ratingToRecommendation = (ratings: Array<LiteralRating>) => {
    const recommendationByRating = {
        negative: false,
        positive: true,
    } as Record<LiteralRating, boolean>;

    const range = ratings.map(rating => recommendationByRating[rating] ?? []).flat();
    return range.length ? range : null;
};

export const ratingToNps = (ratings: Array<LiteralRating>): number[] => {
    const npsByRating = {
        negative: [0, 1, 2, 3, 4, 5, 6],
        neutral: [7, 8],
        positive: [9, 10],
    } as Record<LiteralRating, number[]>;

    return ratings.map(rating => npsByRating[rating] ?? []).flat();
};
