import { FC, useEffect, useState } from 'react';

import { Avatar } from '@partoohub/ui';

import { Conversation } from 'app/states/messaging';

import ConsumerImage from './ConsumerImage';

const ColorMap = [
    '#0085F2', // Primary
    '#F7C200', // Rating
    '#FF426E', // Danger
    '#8A5DF2', // KM
    '#FF9014', // Warning
    '#40DBA2', // Success
    '#142542', // Blackberry
];

type ConsumerIconProps = {
    conversation: Conversation;
    size?: number;
    textSize?: number;
};

const ConsumerIcon: FC<ConsumerIconProps> = ({ conversation, size = 40, textSize = 18 }) => {
    // Renders a consumer icon based on given conversation.
    // If the conversation does not have a consumer image, that is, its value
    // is empty, then we render a placeholder icon.
    // Otherwise, we will render the image itself. However, if that image url
    // is incorrect, or the image can't be loaded, we will fall back to the
    // placeholder icon.
    const imageUrl = conversation.consumer_img;
    const [imageDidNotLoad, setImageDidNotLoad] = useState(false);
    const isIntroductionMessage = conversation.id === -1;
    const color = ColorMap[isIntroductionMessage ? 0 : (conversation.id ?? 0) % ColorMap.length];

    useEffect(() => {
        setImageDidNotLoad(false);
    }, [conversation]);

    if (!imageUrl || imageDidNotLoad) {
        // We do not have an image url, so render the placeholder icon instead.
        return (
            <Avatar
                size={size}
                backgroundColor={color}
                textSize={textSize}
                textColor="white"
                text={conversation.consumer_name || '?'}
            />
        );
    }
    return (
        <ConsumerImage
            imageUrl={imageUrl}
            size={size}
            onImageLoadError={() => setImageDidNotLoad(true)}
        />
    );
};

export default ConsumerIcon;
