import type { CompetitiveOverview, OverviewType } from 'app/api/types/competitiveBenchmark';

import { DEFAULT_COLOR } from '../components/constant/competitors_colors';

export function computeOverviewChartData(
    overview: CompetitiveOverview,
    selection: OverviewType,
    language: Intl.LocalesArgument,
    colors: Record<string, string>,
) {
    return {
        labels: overview.dates.map(date => date.toLocaleString(language, { month: 'short' })),
        datasets: overview.businesses.map(business => ({
            label: business.name,
            data: business.data.map(d => (d ? d[selection] : d)),
            borderColor: colors[business.name] ?? DEFAULT_COLOR,
            backgroundColor: colors[business.name] ?? DEFAULT_COLOR,
        })),
    };
}

export function getOneYearDateRange(date: Date): [Date, Date] {
    const startDate = new Date(date);
    startDate.setFullYear(startDate.getFullYear() - 1);
    startDate.setDate(1);

    const endDate = new Date(date);
    endDate.setMonth(date.getMonth() + 1);
    endDate.setDate(0);
    return [startDate, endDate];
}

export function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}
