import React, { useContext } from 'react';

import MessageReplyTemplateForm from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/components/MessageReplyTemplateForm';
import { MessageReplyTemplateListContainer } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/components/MessageReplyTemplateTable.styled';

import { TemplateFormContext } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/components/MessagesReplyTemplates.context';

import MessageTemplateList from './MRTemplateList';

export const MessageReplyTemplateTable = () => {
    const { creatingMode } = useContext(TemplateFormContext);

    return (
        <MessageReplyTemplateListContainer>
            {creatingMode && <MessageReplyTemplateForm />}
            <MessageTemplateList />
        </MessageReplyTemplateListContainer>
    );
};

export default MessageReplyTemplateTable;
