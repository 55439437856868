import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import ToggleBox from 'app/common/designSystem/components/molecules/ToggleBox/ToggleBox';
import { ConversationRoutingIllustration } from 'app/pages/settings/ConversationRouting/assets/conversation_routing_illustration';
import { ConversationRouterActivationContainer } from 'app/pages/settings/ConversationRouting/ConversationRouter.styled';
import { useConversationRouter } from 'app/pages/settings/ConversationRouting/hooks/useConversationRouter';
import { useConversationRouterActivation } from 'app/pages/settings/ConversationRouting/hooks/useConversationRouterActivation';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';

export const ConversationRouter = () => {
    const { t } = useTranslation();
    const { data: conversationRouterData } = useConversationRouter();
    const [isRouterEnabled, setIsRouterEnabled] = useState(
        conversationRouterData?.enabled ?? false,
    );

    const ConversationRouterActivationMutation = useConversationRouterActivation();

    const onClick = () => {
        ConversationRouterActivationMutation.mutate(!isRouterEnabled);
    };

    useEffect(() => {
        if (conversationRouterData?.enabled !== undefined) {
            setIsRouterEnabled(conversationRouterData?.enabled);
        }
    }, [conversationRouterData]);

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('conversation_router_title')}
                    description={t('conversation_router_description')}
                />
            }
        >
            <ConversationRouterActivationContainer>
                <ConversationRoutingIllustration />
                <ToggleBox
                    text={t('conversation_router_activation_title')}
                    description={t('conversation_router_activation_description')}
                    checked={isRouterEnabled}
                    onClick={onClick}
                />
            </ConversationRouterActivationContainer>
        </MenuListContentTemplate>
    );
};
