const useUnauthorizedBusiness = (
    unauthorizedBusinessCount: Record<string, number>,
) => {
    const hasUnauthorizedBusinessInAnyPlatform =
        Object.values(unauthorizedBusinessCount).reduce(
            (acc, unauthorizedCount) => acc + unauthorizedCount,
            0,
        ) > 0;

    return { hasUnauthorizedBusinessInAnyPlatform };
};

export default useUnauthorizedBusiness;
