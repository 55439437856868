import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from 'react-query';

import { FacebookOauthAccountToReauthenticateData } from 'app/api/types/account';
import api from 'app/api/v2/api_calls';
import { useImportOAuthAccount } from 'app/businessConnection/hooks/useImportOAuthAccount';
import { useRefreshOAuthAccount } from 'app/common/contexts/taskListener/useRefreshOAuthAccount';
import { META_RELOGIN } from 'app/common/data/queryKeysConstants';
import useLocalStorage from 'app/common/hooks/useLocalStorage';
import { FACEBOOK_OAUTH2_START_PATH } from 'app/routing/routeIds';
import { FACEBOOK } from 'app/states/knowledge/reducers/businessConnection/accounts';

type StatusType =
    | 'default'
    | 'success'
    | 'loading'
    | 'error_permission'
    | 'error_wrong_account'
    | 'error_connection_failed';

export interface MetaReloginState {
    status: StatusType;
    errorMessage?: string;
    email?: string;
    name?: string;
    missingScopes: string[];
    showBanner: boolean;
    showModal: boolean;
    oauthAccountId: string;
    percentageProgress: number;
    errorDuringRefresh: boolean;
}

export interface MetaReloginContextProps extends MetaReloginState {
    data: FacebookOauthAccountToReauthenticateData | undefined;
    setStatus: (status: StatusType) => void;
    setShowModal: (show: boolean) => void;
    startOAuthFlow: (scopes: string[] | undefined, state: string[] | undefined) => void;
    setOAuthAccountId: (id: string) => void;
    setShowBanner: (show: boolean) => void;
}

const MetaReloginContext = createContext<MetaReloginContextProps | null>(null);

const MetaReloginProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [status, setStatus] = useState<StatusType>('default');
    const [showBanner, setShowBanner] = useLocalStorage('metaReloginShowBanner', true);
    const [showModal, setShowModal] = useState(false);
    const [missingScopes, setMissingScopes] = useState<string[]>([]);
    const [mainOAuthAccountId, setMainOAuthAccountId] = useState<string>('');

    const updateOAuthData = (data: FacebookOauthAccountToReauthenticateData | undefined) => {
        if (data && Object.keys(data).length > 0) {
            setMissingScopes(data.missing_scopes);
            setMainOAuthAccountId(data.id);
        }
    };

    const handleSuccess = (data: FacebookOauthAccountToReauthenticateData) => {
        if (status === 'loading') {
            if (data && (Object.keys(data).length === 0 || data.id !== mainOAuthAccountId)) {
                setStatus('success');
            } else {
                setStatus('error_permission');
            }
            setShowModal(true);
        }

        updateOAuthData(data);
    };

    const { data, refetch } = useQuery(
        META_RELOGIN,
        () => api.account.getFacebookOauthAccountToReauthenticate(),
        {
            enabled: showBanner,
            refetchOnWindowFocus: showBanner,
            onSuccess: handleSuccess,
        },
    );

    const { startOAuthFlow, oauthAccountId, setOAuthAccountId } = useImportOAuthAccount(
        FACEBOOK_OAUTH2_START_PATH,
    );

    const {
        setStartRefresh,
        isSuccess,
        percentageProgress,
        isError: errorDuringRefresh,
    } = useRefreshOAuthAccount({
        oauthAccountId,
        partner: FACEBOOK,
        startRefreshAtMount: false,
        fetch_origin: 'facebook_lost',
    });

    useEffect(() => {
        if (oauthAccountId) {
            if (mainOAuthAccountId !== oauthAccountId) {
                setStatus('error_wrong_account');
            } else {
                setStartRefresh(true);
                setStatus('loading');
            }
            setShowModal(true);
        }
    }, [oauthAccountId]);

    useEffect(() => {
        if (isSuccess && !errorDuringRefresh) {
            setStartRefresh(false);
            refetch();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (errorDuringRefresh && !isSuccess) {
            setStartRefresh(false);
            setStatus('error_connection_failed');
        }
    }, [errorDuringRefresh]);

    useEffect(() => {
        updateOAuthData(data);
    }, [data]);

    const contextValue = useMemo(
        () => ({
            data,
            errorDuringRefresh,
            missingScopes,
            oauthAccountId,
            percentageProgress,
            status,
            setStatus,
            showBanner,
            setShowBanner,
            showModal,
            setShowModal,
            startOAuthFlow,
            setOAuthAccountId,
        }),
        [
            data,
            errorDuringRefresh,
            missingScopes,
            oauthAccountId,
            percentageProgress,
            status,
            setStatus,
            showBanner,
            setShowBanner,
            showModal,
            setShowModal,
            startOAuthFlow,
            setOAuthAccountId,
        ],
    );

    return (
        <MetaReloginContext.Provider value={contextValue}>{children}</MetaReloginContext.Provider>
    );
};

const useMetaRelogin = () => useContext(MetaReloginContext);

export { MetaReloginProvider, useMetaRelogin };
