import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const AverageCompletionValueContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const WhiteContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 84px;
    background-color: ${HEX_COLORS.white};
    border-radius: 100%;
    color: ${HEX_COLORS.blackberry};
    font-weight: bold;
`;

export const AverageValue = styled.div`
    font-size: 26px;
    line-height: 100%;
`;

export const Completed = styled.div`
    font-size: 10px;
    line-height: 13px;
    text-transform: lowercase;
`;
