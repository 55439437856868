import { useQuery, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';
import { MESSAGING_INFOS } from 'app/common/data/queryKeysConstants';
import { MessagingInfos } from 'app/states/messaging';

type ShowAddPartnerButtonKeys =
    | 'show_add_messenger_button'
    | 'show_add_instagram_button'
    | 'show_instagram_onboarded_button';

interface UseShowPartnerButtonsInfos {
    messenger: [boolean, () => void];
    instagram: [boolean, () => void];
    instagram_onboarded: [boolean, () => void];
    whatsapp_available: [boolean];
}

export const useShowPartnersButtons = (): UseShowPartnerButtonsInfos => {
    const queryClient = useQueryClient();
    const { data } = useQuery(MESSAGING_INFOS, api.messaging.infos);

    const hidePartnerButton = (partnerKey: ShowAddPartnerButtonKeys) => () => {
        queryClient.setQueryData<MessagingInfos>(MESSAGING_INFOS, messagingInfos => ({
            show_missing_access_banner: messagingInfos?.show_missing_access_banner || false,
            show_add_messenger_button: messagingInfos?.show_add_messenger_button || false,
            show_add_instagram_button: messagingInfos?.show_add_instagram_button || false,
            show_instagram_onboarded_button:
                messagingInfos?.show_instagram_onboarded_button || false,
            [partnerKey]: false,
        }));
    };

    return {
        messenger: [
            !!data?.show_add_messenger_button,
            hidePartnerButton('show_add_messenger_button'),
        ],
        instagram: [
            !!data?.show_add_instagram_button,
            hidePartnerButton('show_add_instagram_button'),
        ],
        instagram_onboarded: [
            !!data?.show_instagram_onboarded_button,
            hidePartnerButton('show_instagram_onboarded_button'),
        ],
        // The whatsapp modal is temporarily disabled
        whatsapp_available: [false],
    };
};
