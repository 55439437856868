import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { ORGANIZATIONS } from 'app/common/data/queryKeysConstants';

export default function useOrganization(
    orgId: number,
    enabled = true,
) {
    return useQuery([ORGANIZATIONS, orgId], () => api.org.getOrg(orgId), {
        enabled: enabled,
    });
}
