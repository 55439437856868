import { Chart, ChartType } from '@partoohub/ui';

export const ReviewCountGraph = () => {
    return (
        <Chart
            dataTrackId=""
            type={ChartType.Line}
            data={{
                datasets: [
                    {
                        backgroundColor: 'white',
                        borderColor: 'rgb(53, 162, 235)',
                        data: [3, 4, 5, 6, 3, 4, 5, 8],
                        label: 'Période sélectionnée',
                        pointBorderWidth: 2,
                        pointRadius: [0, 0, 0, 0, 0, 0, 0, 4],
                    },
                ],
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'],
            }}
        />
    );
};
