import { SuccessResponse } from 'app/api/types';
import AdminApiResourceBaseClass from 'app/api/v2/api_calls/baseAdminApiCalls';
import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

import GroupV2 from './groupsV2ApiCalls';

const RESOURCE_URI = 'bulk';

class BulkImportAdminApiCall extends AdminApiResourceBaseClass {
    static bulkBusinessImport(
        file: Blob,
        makeModification: boolean,
        email?: string,
    ): Promise<SuccessResponse> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('makeModification', String(makeModification));
        if (email) formData.append('email', email);

        return BulkImportAdminApiCall.post(
            `${RESOURCE_URI}/business/importer`,
            formData,
        ).then(({ data }) => data);
    }

    static bulkUserImport(
        file: Blob,
        makeModification: boolean,
        sendInvitation: boolean,
        email?: string,
    ): Promise<SuccessResponse> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('makeModification', String(makeModification));
        formData.append('sendInvitation', String(sendInvitation));
        if (email) formData.append('email', email);

        return BulkImportAdminApiCall.post(
            `${RESOURCE_URI}/user/importer`,
            formData,
        ).then(({ data }) => data);
    }
}

export class BulkImportClientApiCall extends ApiResourceBaseClass {
    static bulkBusinessImport(
        file: Blob,
        email?: string,
    ): Promise<SuccessResponse> {
        const formData = new FormData();
        formData.append('file', file);
        if (email) formData.append('email', email);

        return BulkImportClientApiCall.post(
            `${RESOURCE_URI}/business/importer`,
            formData,
        ).then(({ data }) => data);
    }
}

export class MigrateGroupsApiCall extends AdminApiResourceBaseClass {
    // TODO Morty Groups: delete when all orgs have successfully migrated to new groups system
    static migrateGroups(
        file: Blob,
        email?: string,
        makeModification?: boolean,
    ): Promise<SuccessResponse> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('makeModification', String(makeModification));
        if (email) formData.append('email', email);

        return GroupV2.post('migrate-groups', formData).then(
            ({ data }) => data,
        );
    }
}

export default BulkImportAdminApiCall;
