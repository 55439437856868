import { camelCase, mapKeys } from 'lodash-es';

import {
    V2BusinessData,
    V2FormattedBusinessData,
} from 'app/api/types/business';

const formatBusiness = (provider: V2BusinessData): V2FormattedBusinessData =>
    // @ts-ignore
    mapKeys(provider, (v, key) => camelCase(key));

export default formatBusiness;
