import React from 'react';

import Filters from './Filters';
import UserCount from './UserCount';
import {
    UserManagementFiltersContainer,
    UserManagementFiltersTop,
} from './UserManagementFilters.styled';
import { UserSearch } from './UserSearch/UserSearch';

const UserManagementFilters = () => (
    <UserManagementFiltersContainer>
        <UserManagementFiltersTop>
            <UserSearch />
            <Filters />
        </UserManagementFiltersTop>
        <UserCount />
    </UserManagementFiltersContainer>
);

export default UserManagementFilters;
