import React from 'react';

import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import EditSection from 'app/businessEditV2/components/EditSection';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import UnsavedModal from 'app/businessEditV2/components/UnsavedModal';
import useBusinessCategories from 'app/businessEditV2/hooks/business/useBusinessCategories';
import useCategoriesSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useCategoriesSectionColor';
import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import CategoriesForm from './CategoriesForm';
import {
    CategoriesSectionDescription,
    CategoriesSectionDescriptionRow,
} from './CategoriesSection.styled';
import CategoriesSectionContent from './CategoriesSectionContent';

const CategoriesSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const {
        showModal,
        setShowModal,
        closeSafeModal,
        showUnsavedModal,
        setShowUnsavedModal,
        useDirtyUpdate,
    } = useUnsavedModal(SectionNames.CATEGORIES);

    const sectionColor = useCategoriesSectionColor();
    const { t } = useTranslation();

    const { isLoading } = useBusinessCategories();

    if (isLoading) {
        return <EditSectionLoader />;
    }

    return (
        <div
            ref={ref}
            data-track="visibility_location__section_categories"
            data-intercom-target="visibility_location__section_categories"
        >
            <EditSection
                title={t('categories')}
                description={
                    <CategoriesSectionContent colorState={sectionColor} />
                }
                onClick={() => setShowModal(true)}
                colorState={sectionColor}
                sectionName={SectionNames.CATEGORIES}
            />
            <FullScreenModal
                show={showModal}
                childrenRight={
                    <EditFormDescription
                        title={t('business_edit_title_categories')}
                        description={t('business_edit_description_categories')}
                        advice={t('business_edit_advice_categories')}
                    >
                        <CategoriesSectionDescription>
                            <CategoriesSectionDescriptionRow>
                                <i className="fa-solid fa-star" />
                                <span>
                                    {t(
                                        'business_edit_description_categories_subpoint_1',
                                    )}
                                </span>
                            </CategoriesSectionDescriptionRow>
                            <CategoriesSectionDescriptionRow>
                                <i className="fa-solid fa-stars" />
                                <span>
                                    {t(
                                        'business_edit_description_categories_subpoint_2',
                                    )}
                                </span>
                            </CategoriesSectionDescriptionRow>
                        </CategoriesSectionDescription>
                    </EditFormDescription>
                }
                childrenLeft={
                    <>
                        <CategoriesForm
                            closeModal={() => setShowModal(false)}
                            useDirtyUpdate={useDirtyUpdate}
                        />
                        <UnsavedModal
                            show={showUnsavedModal}
                            cancel={() => setShowUnsavedModal(false)}
                            validate={() => setShowModal(false)}
                        />
                    </>
                }
                onHideClick={closeSafeModal}
            />
        </div>
    );
});

export default CategoriesSection;
