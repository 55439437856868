import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { Button, Text } from '@partoohub/ui';

export const ConvHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    outline: none;
    border-bottom: 1px solid ${greyColorObject.initial};
    background-color: ${HEX_COLORS.white};
    padding: 0 8px;
    height: 48px;
`;

export const ConvHeaderInfo = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
`;

export const ConvHeaderConsumerName = styled(Text)`
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
`;
export const ConvHeaderConsumerPhoneNumber = styled(Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
    margin-left: 4px;
`;

export const ConvHeaderBusinessInfo = styled(Text)`
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    min-width: 0;
    margin-right: 8px;
`;

export const ConvIconContainer = styled.div`
    margin: 8px;
`;

export const DotContainer = styled(Text)`
    margin: auto 8px;
`;

export const ConvBusinessInfoContainer = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
`;

export const OverrideButton = styled(Button)`
    margin-right: 8px;
    svg {
        fill: ${({ theme }) => theme.colors.default.initial};
    }
`;

export const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;
