import { isNull, omitBy } from 'lodash-es';
import queryString from 'query-string';

import ApiResourceBaseClass from './baseApiCalls';
import { StatusType, SuccessResponse } from '../../types';
import {
    CreateGroupParams,
    GroupStatsReceivedData,
    UpdateBusinessInGroupPayload,
    UpdateGroupParams,
    UpdateGroupResponse,
    V2GroupData,
    V2PaginatedGroups,
} from '../../types/groups';

const RESOURCE_URI = 'groups';

class Group extends ApiResourceBaseClass {
    static getGroup(groupId: number | null = null): Promise<V2GroupData> {
        return Group.get(`${RESOURCE_URI}/${groupId}`).then(({ data }) => data);
    }

    static getGroups(
        page: number | null,
        searchQuery: string | null,
        orgId: string | null = null,
    ): Promise<V2PaginatedGroups> {
        const queryParams = omitBy(
            {
                page,
                name__contains: searchQuery,
                org_id: orgId,
            },
            isNull,
        );
        const query = queryString.stringify(queryParams);
        return Group.get(`${RESOURCE_URI}?${query}`).then(({ data }) => data);
    }

    static getGroupStats(
        filters: Record<string, any>,
    ): Promise<Array<GroupStatsReceivedData>> {
        return Group.get(`${RESOURCE_URI}/stats`, filters).then(
            ({ data }) => data,
        );
    }

    static createGroup(groupData: CreateGroupParams): Promise<V2GroupData> {
        return Group.post(`${RESOURCE_URI}`, groupData).then(
            ({ data }) => data,
        );
    }

    static updateGroup(
        groupData: UpdateGroupParams,
        groupId: number,
    ): Promise<UpdateGroupResponse> {
        return Group.put(`${RESOURCE_URI}/${groupId}`, groupData).then(
            ({ data }) => data,
        );
    }

    static deleteGroup(groupId: number): Promise<SuccessResponse> {
        return Group.delete(`${RESOURCE_URI}/${groupId}`).then(
            ({ data }) => data,
        );
    }

    static updateBusinessInGroup(
        payload: UpdateBusinessInGroupPayload,
        groupId: number,
    ): Promise<UpdateGroupResponse> {
        return Group.put(`${RESOURCE_URI}/${groupId}/businesses`, payload).then(
            ({ data }) => data,
        );
    }

    static getBusinessInGroup(groupId: number): Promise<Record<string, any>> {
        return Group.get(`${RESOURCE_URI}/${groupId}/businesses`).then(
            ({ data }) => data,
        );
    }

    static removeFromGroup(userId: string): Promise<StatusType> {
        return Group.post(`${RESOURCE_URI}/remove/${userId}`).then(
            ({ data }) => data,
        );
    }

    static checkDeletable(groupId: number): Promise<{
        deletable: boolean;
    }> {
        return Group.get(`${RESOURCE_URI}/${groupId}/deletable`).then(
            ({ data }) => data,
        );
    }
}

export default Group;
