import { useInfiniteQuery } from 'react-query';

import {
    PublisherStateKeysEnum,
    PublishersStatesBusinessesInfoData,
} from 'app/api/types/publisher_state';
import api from 'app/api/v2/api_calls';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { PUBLISHERS_STATES_BUSINESSES_INFO } from 'app/common/data/queryKeysConstants';

type UsePublishersStatesBusinessesInfoProps = {
    publisherName: string;
    state: PublisherStateKeysEnum;
    enabled: boolean;
};

export const usePublishersStatesBusinessesInfo = ({
    publisherName,
    state,
    enabled,
}: UsePublishersStatesBusinessesInfoProps) => {
    const filters = useBusinessModalFilters();

    const queryKey = [
        PUBLISHERS_STATES_BUSINESSES_INFO,
        publisherName,
        state,
        filters,
    ];

    const fetchBusinessesInfo = ({ pageParam = 1 }) =>
        api.publisherState.getPublishersStatesBusinessesInfo({
            ...filters,
            publisher_name: publisherName,
            state,
            page: pageParam,
        });

    const queryConfig = {
        getNextPageParam: (param: PublishersStatesBusinessesInfoData) => {
            const publisherStateBusinessInfo = param[publisherName]?.[state];

            if (
                publisherStateBusinessInfo?.max_page === undefined ||
                publisherStateBusinessInfo?.page === undefined
            ) {
                return undefined;
            }

            return publisherStateBusinessInfo.max_page >
                publisherStateBusinessInfo.page
                ? publisherStateBusinessInfo.page + 1
                : undefined;
        },
        enabled,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    };

    return useInfiniteQuery(queryKey, fetchBusinessesInfo, queryConfig);
};
