import { Navigate, useRoutes } from 'react-router-dom';

import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks/useDeviceType';
import { findFirstDisplayPathname } from 'app/pages/settingsV2/helpers';
import { useSettingPlatformsMenu } from 'app/pages/settingsV2/hooks/Platforms/useSettingPlatformsMenu';
import { Connection } from 'app/pages/settingsV2/subPages/Platforms/components/Connection/Connection';
import { GoogleVerification } from 'app/pages/settingsV2/subPages/Platforms/components/GoogleVerification/GoogleVerification';
import { SettingPlatforms } from 'app/pages/settingsV2/subPages/Platforms/SettingPlatforms';
import {
    CONNECTION_PATH_TOKEN,
    GOOGLE_VERIFICATIO_PATH_TOKEN,
    SETTINGS_PLATFORMS_PATHNAME,
} from 'app/routing/routeIds';

export const PlatformsRoutes = () => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const { menus: settingsPlatformsMenu } = useSettingPlatformsMenu();
    const defaultSettingPlatformsPathname = findFirstDisplayPathname(settingsPlatformsMenu);

    const indexRoute = isBelowProvidedDevices
        ? SETTINGS_PLATFORMS_PATHNAME
        : defaultSettingPlatformsPathname;

    const routes = [
        {
            element: <SettingPlatforms />,
            children: [
                {
                    index: true,
                    element: <Navigate replace to={indexRoute} />,
                },
                {
                    path: CONNECTION_PATH_TOKEN,
                    element: <Connection />,
                },
                {
                    path: GOOGLE_VERIFICATIO_PATH_TOKEN,
                    element: <GoogleVerification />,
                },
            ],
        },
    ];

    return useRoutes(routes);
};
