import { useTranslation } from 'react-i18next';

import { Navigate } from 'react-router-dom';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import NotAccessibleForAdmin from 'app/common/components/placeholder/NotAccessibleForAdmin';
import { PRESENCE_MANAGEMENT } from 'app/common/data/productIds';
import { ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMe';
import { LoaderView } from 'app/pages/analytics/VisibilityAnalyticsV2/components/LoaderView/LoaderView';
import { Visibility } from 'app/pages/analytics/VisibilityAnalyticsV2/Visibility';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

export const VisibilityAnalyticsV2Page = () => {
    const { t } = useTranslation();
    const { data: me, isLoading } = useMe();
    const hasRights = ![ADMIN].includes(me?.role || '');
    const hasPmAccess = !!me?.sidebarProducts.includes(PRESENCE_MANAGEMENT.toLowerCase());

    return (
        <>
            <DynamicPageHeader title={t('analytics')} />
            {!isLoading ? (
                <>
                    {hasPmAccess && hasRights && <Visibility />}
                    {hasPmAccess && !hasRights && <NotAccessibleForAdmin />}
                    {!hasPmAccess && <Navigate replace to={NOT_FOUND_PATH} />}
                </>
            ) : (
                <LoaderView height={100} />
            )}
        </>
    );
};
