import { useWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { MessagingSetting, SettingFormReturn } from 'app/api/types/business_message_settings';
import { STATIC_BASE_URL } from 'app/config';
import {
    IPhoneTemplate,
    PreviewBusinessLabel,
    PreviewConsumerMessageBubble,
    PreviewMessages,
    PreviewPanel,
    PreviewPanelWrapper,
    PreviewSettingIcon,
    PreviewStack,
    PreviewTitleTextV2,
    PreviewWelcomeMessageBubble,
    PreviewWelcomeMessageLine,
    TextPlaceholder,
} from 'app/reviewManagement/messaging/configurationModal/PreviewPanel.styled';

type Props = {
    selectedSetting: MessagingSetting | null;
    control: Control<SettingFormReturn>;
};

export const PreviewPanelContainer = ({ selectedSetting, control }: Props) => {
    const { t } = useTranslation();

    const watchedWelcomeMessage = useWatch({ name: 'welcomeMessage', control });
    const watchIsLaunched = useWatch({ name: 'isLaunched', control });

    return (
        <PreviewStack direction="column">
            <PreviewTitleTextV2 variant="heading6" as="span">
                {t('message_settings_mobile_preview_title')}
            </PreviewTitleTextV2>

            <PreviewPanelWrapper>
                <PreviewPanel direction="column" gap="0">
                    {selectedSetting && watchIsLaunched && (
                        <IPhoneTemplate>
                            <img
                                src={`${STATIC_BASE_URL}/images/app/app_review/mobile_preview_template.svg`}
                                alt="iphone-preview"
                            />
                            <PreviewMessages>
                                <PreviewBusinessLabel variant="bodyXSBold" color="secondary">
                                    {selectedSetting.businessName}
                                </PreviewBusinessLabel>
                                <PreviewWelcomeMessageLine gap={'0'} direction="row">
                                    <PreviewSettingIcon
                                        src={`${STATIC_BASE_URL}/images/app/app_review/mobile_preview_agent_icon.png`}
                                        alt="agent-icon"
                                    />
                                    <PreviewWelcomeMessageBubble>
                                        {watchedWelcomeMessage}
                                    </PreviewWelcomeMessageBubble>
                                </PreviewWelcomeMessageLine>
                                <PreviewConsumerMessageBubble>
                                    {t('conv_settings_mobile_preview_consumer_reply')}
                                </PreviewConsumerMessageBubble>
                            </PreviewMessages>
                        </IPhoneTemplate>
                    )}
                    {selectedSetting && !watchIsLaunched && (
                        <TextPlaceholder variant="heading6" as="p">
                            {t('message_settings_preview_text_messages_disabled')}
                        </TextPlaceholder>
                    )}
                    {!selectedSetting && (
                        <TextPlaceholder variant="heading6" as="p">
                            {t('message_settings_mobile_placeholder')}
                        </TextPlaceholder>
                    )}
                </PreviewPanel>
            </PreviewPanelWrapper>
        </PreviewStack>
    );
};
