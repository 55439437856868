import React, { FC, ReactElement, useContext, useEffect, useRef } from 'react';

import { ScrollSpyContext } from './ScrollSpyProvider';

/**
 * Register the child in the ScrollSpyProvider
 * Unregister when unmounted
 */
const ScrollSpyLink: FC<{ children: ReactElement; id: string }> = ({
    children,
    id,
}) => {
    const ref = useRef<HTMLElement>(null);
    const { register, unregister } = useContext(ScrollSpyContext);

    useEffect(() => {
        const r = ref.current;
        if (r !== null) {
            register(id, r);
        }
        return () => {
            if (r !== null) {
                unregister(id);
            }
        };
    }, [ref.current, id]);

    return React.cloneElement(children, { ref, id });
};

export default ScrollSpyLink;
