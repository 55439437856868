import { useState } from 'react';

import { greyColorObject } from '@partoohub/branding';
import {
    FontAwesomeIconsPartooUsed,
    Icon,
    IconButton,
    IconPrefix,
    List,
    Modal,
    Stack,
    Text,
} from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { PublisherRawData } from 'app/api/types/publisher';
import { PublisherStateStats } from 'app/api/types/publisher_state';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';
import { usePublisherByName } from 'app/presence/management/hooks/usePublisherByName';

import { usePublishersStatesBusinessInfo } from 'app/presence/management/hooks/usePublishersStatesBusinessInfo';
import { usePublisherStatesByName } from 'app/presence/management/hooks/usePublisherStatesByName';

import { getColor, getType } from 'app/presence/management/utils/publisher';

import { PublisherRowContainer } from './PublisherRow.styled';
import { CountByStatus } from '../CountByStatus/CountByStatus';
import { getStatusObject } from '../helpers/helpers';
import PublisherBroadcastModal from '../Modal/PublisherBroadcastModal';
import { PlatformCell } from '../PlatformCell/PlatformCell';
import ClickItem from '../Row/Dropdown/ClickItem';
import { PublisherRowDropDown } from '../Row/Dropdown/PublisherRowDropDown';
import { Status } from '../Status/Status';
import { TimeUnit, translationsMap } from '../utils/timeUnitTranslationsMap';

interface PublisherRowProps {
    publisherName: string;
    businessCount: number;
}

interface ActionProps {
    publisher: PublisherRawData;
    publisherStates: PublisherStateStats;
}

export const Action = ({ publisher, publisherStates }: ActionProps) => {
    const { data: businessInfo, isLoading } = usePublishersStatesBusinessInfo();
    const type = getType(publisherStates);
    if (isLoading || !businessInfo) return <></>;

    const publisherBusinessInfo =
        type === 'unavailable'
            ? undefined
            : businessInfo?.[publisher.name]?.[type]?.publishers_business?.[0];

    return (
        <Stack direction="row" justifyContent="flex-end">
            <ClickItem
                status={type}
                businessDetails={publisherBusinessInfo}
                publisherName={publisher.name}
                publisherFormattedName={publisher.formatted_name}
            />
        </Stack>
    );
};

export const PublisherRow = ({ publisherName, businessCount }: PublisherRowProps) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const { publisher, isLoading: isLoadingPublisher } = usePublisherByName(publisherName);
    const { publisherStates, isLoading: isLoadingPublisherStates } =
        usePublisherStatesByName(publisherName);
    const { expandedRowIds } = List.useListContext();

    const handleHideModal = () => {
        setShowModal(false);
    };

    const onClickTooltip = (event: MouseEvent) => {
        event.stopPropagation();
        setShowModal(true);
    };

    if (!publisher || isLoadingPublisher) {
        return null;
    }

    const [count, unit] = publisher.pm_latency.split('_');

    const hasOnlyOneSelectedBusiness = businessCount === 1;

    return (
        <>
            <Modal isOpen={showModal} closeModal={handleHideModal}>
                <PublisherBroadcastModal publisher={publisher} onHide={handleHideModal} />
            </Modal>
            <PublisherRowContainer
                dataTrackId={`row_${publisherName}`}
                key={publisherName}
                id={publisherName}
                expandable={
                    hasOnlyOneSelectedBusiness ? undefined : (
                        <PublisherRowDropDown publisherName={publisherName} />
                    )
                }
                borderColor={
                    hasOnlyOneSelectedBusiness ? getColor(getType(publisherStates)) : undefined
                }
                disabled={publisher.disabled}
                disableAnimationOnHover={publisher.disabled}
            >
                <PlatformCell publisher={publisher} onClick={onClickTooltip} />
                {!isBelowProvidedDevices && (
                    <List.Cell>
                        <Text variant="bodyMSemibold" color="secondary">
                            {t(translationsMap[unit as TimeUnit], { count: parseInt(count) })}
                        </Text>
                    </List.Cell>
                )}
                {businessCount === 1 ? (
                    <List.Cell>
                        {isLoadingPublisherStates ? (
                            <Stack direction="row">
                                <Icon
                                    icon={['fa-circle-notch', IconPrefix.SOLID]}
                                    color={greyColorObject.initial}
                                    className="fa-spin"
                                    iconSize="24px"
                                />
                            </Stack>
                        ) : (
                            <Status
                                status={getType(publisherStates)}
                                isIndirect={publisher.is_indirect}
                            />
                        )}
                    </List.Cell>
                ) : (
                    Object.entries(publisherStates).map(([status, count]) => (
                        <List.Cell key={status}>
                            {isLoadingPublisherStates ? (
                                <Stack direction="row">
                                    <Icon
                                        icon={['fa-circle-notch', IconPrefix.SOLID]}
                                        color={greyColorObject.initial}
                                        className="fa-spin"
                                        iconSize="24px"
                                    />
                                </Stack>
                            ) : (
                                <CountByStatus {...getStatusObject(status)}>{count}</CountByStatus>
                            )}
                        </List.Cell>
                    ))
                )}
                {businessCount === 1 ? (
                    <List.Cell>
                        <Action publisher={publisher} publisherStates={publisherStates} />
                    </List.Cell>
                ) : (
                    <List.Cell>
                        <IconButton
                            dataTrackId=""
                            icon={[
                                expandedRowIds[publisher.name]
                                    ? FontAwesomeIconsPartooUsed.faChevronDown
                                    : FontAwesomeIconsPartooUsed.faChevronRight,
                            ]}
                            appearance="outlined"
                        />
                    </List.Cell>
                )}
            </PublisherRowContainer>
        </>
    );
};
