import { hideableComponentsNames, withHideableComponent } from 'app/SDKBridge';

import { ProductSidebar } from './Sidebar/ProductSidebar';
import { FlexRow } from './Sidebar.styled';

const Sidebar = () => (
    <FlexRow>
        <ProductSidebar />
    </FlexRow>
);

export default withHideableComponent(Sidebar, hideableComponentsNames.APP_SIDEBAR);
