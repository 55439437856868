import { PureComponent } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';

import { InputIcon } from 'app/common/designSystem/components/molecules/ButtonWithSearchMenuFilterSelection/ButtonWithSearchMenuFilterSelection.styled';

import {
    AsyncInputLabelBox,
    AsyncInputLabelContainer,
    AsyncInputLabelError,
    AsyncInputLabelInput,
    AsyncInputLabelText,
    AsyncInputLabelUnderlined,
    DefaultIcon,
    StyledIcon,
} from './AsyncInputLabel.styled';

type Props = {
    label: string;
    onLabelChange: (value: string) => void;
    isLoading: boolean;
    placeholder: string;
    className: string;
    disabledPlaceholder?: string | null;
    disabled?: boolean;
    errorMessage?: string | null;
    dataField?: string | null;
    validation: (value: string) => string | null;
    // hide cross to remove selected value
    hideCross?: boolean;
    searchIcon?: boolean;
};
type State = {
    isTyping: boolean;
    showInput: boolean;
    value: string | null;
    timeoutId: number;
    inputRef: Record<string, any> | null;
    validationError: string | null;
};

class AsyncInputLabel extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isTyping: false,
            showInput: false,
            value: props.label,
            timeoutId: 0,
            inputRef: null,
            validationError: null,
        };
    }

    static defaultProps = {
        disabled: false,
        className: '',
        disabledPlaceholder: null,
        errorMessage: null,
        dataField: '',
        hideCross: false,
        searchIcon: false,
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { showInput, inputRef } = this.state;
        const { label } = this.props;
        // focus and move cursor input on click
        if (showInput && inputRef && prevState.inputRef !== inputRef) this.focusInput();
        if (prevProps.label !== label) {
            this.setState({
                value: label,
            });
        }
    }

    focusInput() {
        const { inputRef, value } = this.state;

        if (inputRef) {
            const valueLength = value ? value.length : 0;
            inputRef.focus();
            inputRef.selectionStart = valueLength;
            inputRef.selectionEnd = valueLength;
        }
    }

    toggleShowInput() {
        const { showInput } = this.state;
        this.setState({
            showInput: !showInput,
        });
    }

    onValueChange(value: string) {
        const { timeoutId } = this.state;
        const { onLabelChange, validation } = this.props;
        const errorMessage = validation ? validation(value) : null;
        if (timeoutId) clearTimeout(timeoutId);

        if (errorMessage) {
            this.setState({
                isTyping: false,
                validationError: errorMessage,
                value: value && value.length ? value : null,
            });
        } else {
            this.setState({
                // @ts-ignore
                timeoutId: setTimeout(() => {
                    onLabelChange(value);
                    this.setState({
                        isTyping: false,
                    });
                }, 500),
                isTyping: true,
                value: value && value.length ? value : null,
                validationError: null,
            });
        }
    }

    onBlurInput() {
        const { isTyping, inputRef } = this.state;
        const { isLoading, errorMessage } = this.props;
        if (!isTyping && !isLoading && !errorMessage) this.toggleShowInput();
        else if (inputRef) inputRef.focus();
    }

    renderIcon() {
        const { errorMessage, isLoading } = this.props;
        const { isTyping, validationError } = this.state;
        const error = validationError || errorMessage;
        if (isTyping || isLoading) return <DefaultIcon className="fas fa-sync-alt fa-spin" />;
        if (error)
            return (
                <StyledIcon
                    icon={[FontAwesomeIconsPartooUsed.faCircleExclamation, IconPrefix.SOLID]}
                    iconSize="10px"
                    color="error"
                />
            );
        return (
            <StyledIcon
                icon={[FontAwesomeIconsPartooUsed.faCircleCheck, IconPrefix.SOLID]}
                iconSize="10px"
                color="success"
            />
        );
    }

    reset = () => {
        const { onLabelChange } = this.props;
        onLabelChange('');
    };

    render() {
        const {
            isLoading,
            disabledPlaceholder,
            placeholder,
            disabled,
            className,
            errorMessage,
            dataField,
            hideCross,
            searchIcon,
        } = this.props;
        const { value, isTyping, showInput, validationError } = this.state;
        const noValue = !value || !value.length;
        const label = disabled ? value || disabledPlaceholder : value || placeholder;
        const error = validationError || errorMessage;
        const hasError = showInput && !isTyping && !isLoading && error;
        return (
            <AsyncInputLabelContainer disabled={disabled} className={className}>
                <AsyncInputLabelError variant="bodySRegular" color="danger" />
                <AsyncInputLabelUnderlined
                    showInput={showInput}
                    hasError={hasError}
                    disabled={disabled}
                >
                    {!showInput ? (
                        <AsyncInputLabelText
                            noValue={noValue}
                            disabled={disabled}
                            tabIndex={0}
                            onKeyPress={() => this.toggleShowInput()}
                            onClick={() => this.toggleShowInput()}
                            data-field={dataField || null}
                        >
                            {label}
                            {!hideCross && value && !validationError && (
                                <InputIcon type="button" onClick={this.reset}>
                                    <i className={'fas fa-times'} />
                                </InputIcon>
                            )}
                            {((searchIcon && !value) || validationError) && (
                                <InputIcon type="button">
                                    <i className={'fas fa-search'} />
                                </InputIcon>
                            )}
                        </AsyncInputLabelText>
                    ) : (
                        <AsyncInputLabelBox>
                            <AsyncInputLabelInput
                                value={value || ''}
                                type="text"
                                onChange={event => this.onValueChange(event.target.value)}
                                onBlur={() => this.onBlurInput()}
                                ref={ref =>
                                    this.setState({
                                        inputRef: ref,
                                    })
                                }
                                placeholder={placeholder}
                            />
                            {this.renderIcon()}
                        </AsyncInputLabelBox>
                    )}
                </AsyncInputLabelUnderlined>
                <AsyncInputLabelError variant="bodySRegular" color="danger">
                    {hasError && error}
                </AsyncInputLabelError>
            </AsyncInputLabelContainer>
        );
    }
}

export default AsyncInputLabel;
