import { useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { ExportModal } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Export/ExportModal/ExportModal';

export const Export = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleClick = () => {
        setShowModal(true);
    };

    return (
        <>
            <IconButton
                dataTrackId="analytics_visibility__export__icon_button"
                appearance="outlined"
                variant="default"
                tooltip={t('download_data')}
                onClick={handleClick}
                icon={[FontAwesomeIconsPartooUsed.faArrowDownToLine]}
            />
            <ExportModal showModal={showModal} closeModal={() => setShowModal(false)} />
        </>
    );
};
