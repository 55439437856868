import { useCallback } from 'react';

import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useIsTablet } from 'app/hooks/useIsTablet';
import { DesktopHeader } from 'app/pages/analytics/VisibilityAnalyticsV2/components/HeaderSection/PageHeader/DesktopHeader/DesktopHeader';
import { MobileHeader } from 'app/pages/analytics/VisibilityAnalyticsV2/components/HeaderSection/PageHeader/MobileHeader/MobileHeader';

import { BannerContainer } from './HeaderSection.styled';

export const HeaderSection = () => {
    const isTablet = useIsTablet();
    const { t } = useTranslation();
    const showInfoBanner = useCallback((): boolean => {
        return new Date().getDate() <= 10;
    }, []);

    return (
        <>
            {isTablet ? <MobileHeader /> : <DesktopHeader />}
            {showInfoBanner() && (
                <BannerContainer>
                    <Banner
                        dataTrackId="analytics_keyword_fetch_banner"
                        variant="info"
                        badge={t('info_term')}
                        hideIcon
                        withCloseIcon={false}
                    >
                        {t('analytics_keyword_fetch_bewteen_five_and_ten_content')}
                    </Banner>
                </BannerContainer>
            )}
        </>
    );
};
