import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import {
    ActivatedDialog,
    ActivatedModelButton,
} from 'app/pages/settings/Chatbot/components/Activation/ChatbotActivation.styled';

interface DialogProps {
    onClose: () => void;
}

export const ActivationDialog = ({ onClose }: DialogProps) => {
    const { t } = useTranslation();
    return (
        <ActivatedDialog>
            <Text as="h4" variant="heading4" color="#fff">
                {t('chatbot_activation_modal_jim_activated_title')}
            </Text>
            <Text as="p" variant="bodyMRegular" color="#fff">
                {t('chatbot_activation_modal_jim_activated_subtitle')}
            </Text>
            <ActivatedModelButton
                dataTrackId="chatbot-activation-modal-button"
                appearance="contained"
                shape="rounded"
                onClick={onClose}
            >
                {t('chatbot_activation_modal_jim_activated_dismiss_button')}
            </ActivatedModelButton>
        </ActivatedDialog>
    );
};
