import { Stack } from '@partoohub/ui';

import { ReplyPermissionDeniedBanner } from './ReplyPermissionDeniedBanner';

export const BannerSection = () => {
    return (
        <Stack direction="column">
            <ReplyPermissionDeniedBanner />
        </Stack>
    );
};
