import { useTranslation } from 'react-i18next';

import { DownloadModal } from 'app/common/components/DownloadModal';
import {
    ReviewListModal,
    useReviewListModalsContext,
} from 'app/reviewManagement/reviewList/sections/ReviewListModals/ReviewListModals.context';

export const DownloadReviewsModal = () => {
    const { t } = useTranslation();
    const {
        [ReviewListModal.DOWNLOAD_REVIEWS]: downloadReviewsModal,
        [ReviewListModal.DOWNLOAD_FEEDBACKS]: downloadFeedbacksModal,
    } = useReviewListModalsContext();

    return (
        <>
            <DownloadModal
                key="download-reviews-modal"
                {...downloadReviewsModal}
                title={t('download_reviews_title')}
                content={t('download_reviews_sent_to_this_email')}
            />
            <DownloadModal
                key="download-feedbacks-modal"
                {...downloadFeedbacksModal}
                title={t('download_reviews_feedback_results_title')}
                content={t('download_reviews_feedback_results_sent_to_this_email')}
            />
        </>
    );
};
