import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import NewFilterButton from 'app/common/components/buttons/NewFilterButton';
import useGroups from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroups';
import useGroupTitle from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupTitle';
import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';

import BusinessOldGroupsModal from './BusinessOldGroupsModal';

const MAX_GROUP_TITLE_LENGTH = 40;

const BusinessOldGroupsFilter = () => {
    const { t } = useTranslation();
    const { groups: groupsData } = useGroups();
    const navigate = useNavigate();

    const [opened, setOpened] = useState<boolean>(false);

    const closeModal = () => setOpened(false);
    const erase = () => navigate(`${VISIBILITY_LOCATION_PATH}?group=all`);

    const title = useGroupTitle();

    let groupTitle = t('business_drawer_groups');
    let selectedNumber = 0;

    if (title !== t('all_businesses_title')) {
        groupTitle = title;
        selectedNumber = 1;
    }

    if (!groupsData.length) {
        return null;
    }

    return (
        <>
            <NewFilterButton
                onClick={() => setOpened(true)}
                text={
                    groupTitle.length > MAX_GROUP_TITLE_LENGTH
                        ? `${groupTitle.slice(0, MAX_GROUP_TITLE_LENGTH)}...`
                        : groupTitle
                }
                selectedNumber={selectedNumber}
                onErase={erase}
            />

            <BusinessOldGroupsModal opened={opened} closeModal={closeModal} erase={erase} />
        </>
    );
};

export default BusinessOldGroupsFilter;
