import { useEffect, useState } from 'react';

import useShowModal from 'app/businessEditV2/hooks/editV2utils/useShowModal';
import { SectionNames } from 'app/businessEditV2/utils/utils';

type UseUnsavedModalReturnType = {
    showModal: boolean;
    setShowModal: (boolean) => void;
    showUnsavedModal: boolean;
    setShowUnsavedModal: (boolean) => void;
    closeSafeModal: () => void;
    useDirtyUpdate: (boolean) => void;
};

export default function useUnsavedModal(
    section: SectionNames,
): UseUnsavedModalReturnType {
    const [showModal, setShowModal] = useShowModal(section);
    const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const closeSafeModal = () => {
        if (!isDirty) {
            setShowModal(false);
        } else {
            setShowUnsavedModal(true);
        }
    };

    useEffect(() => {
        if (!showModal) {
            setShowUnsavedModal(false);
        }
    }, [showModal]);

    const useDirtyUpdate = (dirty: boolean) =>
        useEffect(() => {
            setIsDirty(dirty);
        }, [dirty]);

    return {
        showModal,
        setShowModal,
        showUnsavedModal,
        setShowUnsavedModal,
        closeSafeModal,
        useDirtyUpdate,
    };
}
