import { connect } from 'react-redux';

import SelectButton from 'app/common/designSystem/components/atoms/SelectButton';
import allowedSeasonalityOptions from 'app/common/services/allowedSeasonalityOptions';
import { AppState } from 'app/states/reducers';
import {
    isPeriodSelector,
    updateBucketAction,
} from 'app/states/reviewAnalytics';
import {
    dateFilterSelector,
    reviewFiltersSelector,
} from 'app/states/reviews/filters';

const mapStateToProps = (state: AppState) => {
    const { reviewAnalytics } = state;
    const reviewFilters = reviewFiltersSelector(state);

    return {
        options: allowedSeasonalityOptions(
            isPeriodSelector(reviewAnalytics)
                ? dateFilterSelector(reviewFilters)
                : undefined,
        ),
    };
};

const matchDispatchToProps = (dispatch: any) => ({
    onBucketChange: e => dispatch(updateBucketAction(e.target.value)),
});

const ReviewsEvolution = connect(
    mapStateToProps,
    matchDispatchToProps,
    // @ts-ignore
)(SelectButton);

export default ReviewsEvolution;
