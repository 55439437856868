import { useTranslation } from 'react-i18next';

import { useSettingIntegrationsMenu } from 'app/pages/settingsV2/hooks/Integrations/useSettingIntegrationsMenu';

import { HeaderSubPages } from '../../subComponents';
import { SettingSkeleton } from '../../subComponents/SettingSkeleton';
import { Template } from '../../subComponents/Template/Template';

export const SettingIntegrations = () => {
    const { t } = useTranslation();
    const { menus } = useSettingIntegrationsMenu();

    return (
        <Template header={<HeaderSubPages>{t('settings_menu_integrations')}</HeaderSubPages>}>
            <SettingSkeleton sections={menus} />
        </Template>
    );
};
