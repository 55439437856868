import { AvatarMenuItem } from '@partoohub/modular-components';
import { FontAwesomeIconsPartooUsed, Icon, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import getLang from 'app/common/services/getLang';

import { IconContainer } from './AvatarHelpCenterItem.styled';

export const AvatarHelpCenterItem = () => {
    const { t } = useTranslation();
    return (
        <Link to={`https://help.partoo.co/${getLang()}/`} target="_blank">
            <AvatarMenuItem>
                <IconContainer>
                    <Icon icon={[FontAwesomeIconsPartooUsed.faMessageQuestion]} />
                </IconContainer>
                <Text variant="bodyMMedium" as="span">
                    {t('help_center')}
                </Text>
            </AvatarMenuItem>
        </Link>
    );
};
