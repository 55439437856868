import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 8px 8px 8px;
    width: 500px;
    text-align: center;
    overflow: hidden;
`;

export const Title = styled.div`
    margin: 8px 32px 0 32px;
    color: ${HEX_COLORS.blackberry};
    font-weight: bold;
    font-size: 26px;
`;

export const Description = styled.div`
    margin: 8px 32px 0 32px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 14px;
`;

export const Buttons = styled.div`
    margin-top: 36px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    width: 100%;

    > button {
        overflow: hidden;
    }
`;
