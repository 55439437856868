// This function is used to adjust the date from the local timezone to UTC
export const getAdjustedDate = (str: string | null): Date | null => {
    if (!str) {
        return null;
    }

    const date = new Date(str);
    const timeOffsetInMS = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
};
