import { delay } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import headers from 'app/api/v2/headers';
import storeError from 'app/common/sagas/storeError';
import { Saga } from 'app/common/types';
import { renewJwtTokenFailure } from 'app/states/jwtToken/actions/actions';
import { RENEW_JWT_TOKEN_REQUEST } from 'app/states/jwtToken/actions/constants';

const MINUTES_TO_RENEW_JWT_TOKEN = 15;

const getDefaultJwtRenewDuration = (): number => {
    const currentTime = Date.now();
    const msToRenewJwtToken = MINUTES_TO_RENEW_JWT_TOKEN * 60 * 1000;
    return currentTime + msToRenewJwtToken;
};

const renewJwtToken = function* (): Saga {
    let timestamp = getDefaultJwtRenewDuration();

    while (timestamp) {
        // We check every minutes, if we need to renew jwt token
        yield delay(60000);

        if (Date.now() >= timestamp) {
            try {
                const { token } = yield call(api.authToken.getAuthToken);
                headers.jwtToken.injectJwtToken(token);
                timestamp = getDefaultJwtRenewDuration();
            } catch (error) {
                // @ts-ignore
                timestamp = undefined;
                yield* storeError(renewJwtTokenFailure, error);
            }
        }
    }
};

const renewJwtTokenSaga = function* (): Saga {
    yield takeEvery(RENEW_JWT_TOKEN_REQUEST, renewJwtToken);
};

export default renewJwtTokenSaga;
