import styled from '@emotion/styled';

export const RowContainer = styled.div`
    height: 80px;
    display: flex;
    justify-content: flex-start;
    border: 1px solid #dfe3ec;
    font-size: 12px;
    margin: 0;
    padding: 0 10px;
    align-items: center;
    background-color: white;
    border-radius: 4px;
`;

export const RowInfoContainer = styled.div`
    text-align: left;
    margin-left: 10px;
    width: 270px;
    overflow: hidden;
`;

export const RowInfoName = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
`;

export const RowInfoEmail = styled.div`
    font-size: 12px;
    color: #7f8ea4;
`;

export const RowInfoCount = styled.div`
    font-size: 12px;
    color: #7f8ea4;
    font-weight: bold;
`;

export const RowInfoIcons = styled.div`
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-left: auto;
    margin-right: 0;
`;

export const NotClickableContainer = styled.div`
    pointer-events: none;
`;
