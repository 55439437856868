import { Stack } from '@partoohub/ui';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';

import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { CardTitle } from 'app/pages/analytics/VisibilityAnalyticsV2/components/CardTitle/CardTitle';
import { Count } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Count/Count';
import { Delta } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Delta/Delta';
import { ErrorPlaceholder } from 'app/pages/analytics/VisibilityAnalyticsV2/components/ErrorPlaceholder/ErrorPlaceholder';
import { HelperCard } from 'app/pages/analytics/VisibilityAnalyticsV2/components/HelperCard/HelperCard';
import { HelperContent } from 'app/pages/analytics/VisibilityAnalyticsV2/components/HelperCard/HelperContent/HelperContent';
import { NoDataIcon } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Icons';
import {
    NoDataContainer,
    NoDataKeywordsText,
    NoDataPlacement,
    QueriesCardContainer,
    SearchKeywordsContainer,
    SuptitleBoxIcon,
    SuptitleContainer,
    SuptitleText,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/Keywords/KeywordsCard/QueriesCard/QueriesCard.styled';
import { QueriesCardContent } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Keywords/KeywordsCard/QueriesCard/QueriesCardContent/QueriesCardContent';
import { KpiCardLoader } from 'app/pages/analytics/VisibilityAnalyticsV2/components/KpiCardLoader/KpiCardLoader';
import { useKeywordsDataCount } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/keywords/count/useKeywordsDataCount';
import { useKeywordsErrorCount } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/keywords/count/useKeywordsErrorCount';
import { useKeywordsLoadingCount } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/keywords/count/useKeywordsLoadingCount';
import {
    queriesColor,
    queriesHelperContent,
} from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queries';
import { AnalyticsQueryKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queryKeys';

import { DATE_FORMAT } from 'app/utils/dates';

import { StyledSubtitle } from '../../../HelperCard/HelperCard.styled';

export const QueriesCard = () => {
    const { t } = useTranslation();

    const [dateFrom] = useStateQueryParams(AnalyticsQueryKeys.DATE_FROM);
    const [dateTo] = useStateQueryParams(AnalyticsQueryKeys.DATE_TO);

    const now = new Date();
    const displayNoDataForTimeRange =
        dateFrom === format(startOfMonth(now), DATE_FORMAT) &&
        (!dateTo || dateTo === format(endOfMonth(now), DATE_FORMAT));

    const { hex, rgba } = queriesColor;

    const isLoading = useKeywordsLoadingCount();
    const { refetchCountCurrent, refetchCountPrevious, hasError } = useKeywordsErrorCount();
    const { count, delta } = useKeywordsDataCount();

    const handleClick = () => {
        refetchCountCurrent();
        refetchCountPrevious();
    };

    const QueriesCardTitle = (
        <CardTitle color={queriesColor} icon={<i className="fa fa-fas fa-keyboard"></i>}>
            {t('queries')}
        </CardTitle>
    );

    if (isLoading) {
        return <KpiCardLoader />;
    }

    return (
        <QueriesCardContainer
            data-track="analytics_visiblity__card_queries"
            data-intercom-target="analytics_visiblity__card_queries"
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                {QueriesCardTitle}
                <HelperCard title={t('queries')}>
                    <StyledSubtitle variant="bodyMSemibold" as="span" color="secondary">
                        {t('explanation-queries')}
                    </StyledSubtitle>
                    <HelperContent content={queriesHelperContent} />
                </HelperCard>
            </Stack>
            {hasError ? (
                <ErrorPlaceholder message={t('error_page_placeholder_title_app')}>
                    <StrikeThroughButton
                        color="primary"
                        onClick={handleClick}
                        text={t('try_agin')}
                        icon={<i className="fa-solid fa-redo" />}
                    />
                </ErrorPlaceholder>
            ) : (
                <>
                    {!displayNoDataForTimeRange ? (
                        <>
                            <Count count={count} />
                            <Delta delta={delta} />
                            <SearchKeywordsContainer>
                                <SuptitleContainer>
                                    <SuptitleBoxIcon hex={hex} rgba={rgba}>
                                        <i className="fa fa-fas fa-trophy"></i>
                                    </SuptitleBoxIcon>
                                    <SuptitleText>{t('top_searched_keywords')}</SuptitleText>
                                </SuptitleContainer>
                                <QueriesCardContent />
                            </SearchKeywordsContainer>
                        </>
                    ) : (
                        <NoDataPlacement>
                            <NoDataContainer>
                                <NoDataIcon />
                                <NoDataKeywordsText>
                                    {t('no_data_available_time_range')}
                                </NoDataKeywordsText>
                            </NoDataContainer>
                        </NoDataPlacement>
                    )}
                </>
            )}
        </QueriesCardContainer>
    );
};
