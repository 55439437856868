import {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useContext,
    useMemo,
    useState,
} from 'react';

interface SidebarContextType {
    extended: boolean;
    setExtended: Dispatch<SetStateAction<boolean>>;
    theme: string;
    setTheme: Dispatch<SetStateAction<string>>;
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider = ({ children }: PropsWithChildren) => {
    const [extended, setExtended] = useState(false);
    const [theme, setTheme] = useState('light');

    const value = useMemo(
        () => ({
            extended,
            setExtended,
            theme,
            setTheme,
        }),
        [extended, setExtended, theme, setTheme],
    );

    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};

export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (context === undefined) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};
