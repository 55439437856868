import styled from '@emotion/styled';

export const PostImageInfoCriteriaContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    width: 50%;
`;

export const PostImageInfoCriteriaIcon = styled.div`
    margin-right: 16px;
    > i {
        color: ${({ theme }) => theme.colors.primary.initial};
        font-size: 32px;
    }
`;

export const PostImageInfoCriteriaDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2px 0;
`;
