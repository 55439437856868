import { useTranslation } from 'react-i18next';

import { getUnsupportedMessage } from 'app/reviewManagement/messaging/services/getUnsupportedMessage';
import { ConversationMessage, MESSAGE_SENDER_TYPE, UnsupportedContent } from 'app/states/messaging';

import { MessageBubbleWrapperStyled, NoticeBubbleStyled } from '../MessageBubble.styled';

interface UnsupportedBubbleProps {
    message: ConversationMessage;
    consumerName: string;
    handleBubbleClick: () => void;
}

export const UnsupportedBubble = ({
    message,
    consumerName,
    handleBubbleClick,
}: UnsupportedBubbleProps) => {
    const { t } = useTranslation();
    const isConsumerMessage = message.sender_type === MESSAGE_SENDER_TYPE.CONSUMER;

    return (
        <MessageBubbleWrapperStyled onClick={handleBubbleClick} isIgStory={false}>
            <NoticeBubbleStyled
                variant="bodySMedium"
                as="span"
                italic
                color={'secondary'}
                isConsumer={isConsumerMessage}
            >
                {getUnsupportedMessage(
                    message.content as UnsupportedContent,
                    isConsumerMessage,
                    consumerName,
                    t,
                )}
            </NoticeBubbleStyled>
        </MessageBubbleWrapperStyled>
    );
};
