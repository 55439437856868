import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import {
    CHECKBOX_BM_POSITION,
    CHECKBOX_GM_POSITION,
} from 'app/pages/settingsV2/subPages/Team/components/Permissions/sections/PermissionsPageContent/PermissionsRoleBanner/PermissionsRoleBanner.styled';

export const PermissionsFieldContainer = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
    border-top: solid 1px ${greyColorObject.initial};
    padding-left: 24px;
`;

export const PermissionsFieldInfoIcon = styled.i`
    margin-inline: 8px;
`;

export const PermissionsFieldCheckbox = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;

    // First child
    > *:first-of-type {
        // Hardcoded value to align with the role banner
        margin-right: ${CHECKBOX_GM_POSITION - CHECKBOX_BM_POSITION}px;
        // To compensate the 2 checkboxes width
        transform: translateX(150%);
    }

    // Second child
    > *:nth-of-type(2) {
        // Hardcoded value to align with the role banner
        margin-right: ${CHECKBOX_BM_POSITION}px;
        transform: translateX(50%);
    }
`;
