import { useState } from 'react';

import { Button, TextInput, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { USERS } from 'app/common/data/queryKeysConstants';
import useUser from 'app/common/hooks/queries/useUser';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { STATIC_BASE_URL } from 'app/config';
import useDeleteUser from 'app/pages/settingsV2/subPages/Team/components/UserManagement/hooks/useDeleteUser';
import {
    UserListQueryKeys,
    UserStatus,
} from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

import {
    UserDeleteButton,
    UserDeleteCancel,
    UserDeleteContentContainer,
    UserDeleteContentTop,
    UserDeleteImg,
    UserDeleteInput,
    UserDeleteSubtitle,
    UserDeleteTitle,
    UserDeleteWarning,
} from './UserDeleteContent.styled';

type Props = {
    userId: string;
    closeModal: () => void;
};

const UserDeleteContent = ({ userId, closeModal }: Props) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState<string>('');
    const [status] = useStateQueryParams(UserListQueryKeys.STATUS);
    const [query] = useStateQueryParams(UserListQueryKeys.QUERY);

    const { data: user } = useUser(userId, {
        enabled: !!userId,
    });

    const queryClient = useQueryClient();

    // TODO MORTY USERS: Handle query and status directly in the hook
    const deleteUser = useDeleteUser(
        user?.id ?? '',
        query,
        [UserStatus.ACTIVE, UserStatus.INVITED].includes(status) ? status : null,
        {
            onSuccess: () => {
                queryClient.invalidateQueries(invalidatesAny(USERS));
                toast.success(null, t('toast__user_deleted__success'));
            },
        },
    );

    const onChangeInputValidation = (value: string) => {
        setInputValue(value);
    };

    const onClickDeleteUser = () => {
        deleteUser.mutate();
        closeModal();
    };

    return (
        <UserDeleteContentContainer>
            <UserDeleteContentTop>
                <UserDeleteImg
                    src={`${STATIC_BASE_URL}/images/app/delete_modal_image.svg`}
                    alt="delete_image"
                    className="user-delete-modal__img"
                />
                <UserDeleteTitle>{t('delete_user__modal__title')}</UserDeleteTitle>
                <UserDeleteSubtitle>
                    {t('delete_user__modal__body', {
                        email: user?.email ?? '',
                    })}
                </UserDeleteSubtitle>
                {user?.has_active_api_keys && (
                    <UserDeleteWarning>
                        <i className="fa-solid fa-exclamation-triangle" />
                        {t('delete_user_warning_api_keys')}
                    </UserDeleteWarning>
                )}
                <UserDeleteInput>
                    <TextInput
                        dataTrackId="email"
                        type="email"
                        label={t('email_extended')}
                        value={inputValue}
                        onChange={value => onChangeInputValidation(value ?? '')}
                    />
                </UserDeleteInput>
                <UserDeleteCancel onClick={closeModal} className="user-delete-modal__cancel">
                    {t('api_key_manager_revoke_popin_cancel')}
                </UserDeleteCancel>
            </UserDeleteContentTop>
            <UserDeleteButton>
                <Button
                    dataTrackId="user_delete_button"
                    onClick={() => onClickDeleteUser()}
                    full
                    size="large"
                    shape="cube"
                    disabled={
                        !(
                            inputValue &&
                            user?.email &&
                            inputValue.toLowerCase() === user.email.toLowerCase()
                        )
                    }
                    variant="danger"
                >
                    {t('delete_user')}
                </Button>
            </UserDeleteButton>
        </UserDeleteContentContainer>
    );
};

export default UserDeleteContent;
