import React, { FC } from 'react';

import {
    AlertBannerButton,
    AlertBannerContainer,
    AlertBannerContent,
    AlertBannerTitle,
    AlertBannerWrapper,
    BackgroundIcon,
    StyledButton,
} from './AlertBanner.styled';

export type Props = {
    title: string;
    color: string;
    onClick?: () => void;
    buttonText?: string;
    fixed?: boolean;
    children?: React.ReactNode;
};

const AlertBanner: FC<Props> = ({
    title,
    color,
    onClick = undefined,
    buttonText = '',
    fixed = true,
    children,
}) => (
    <AlertBannerWrapper fixed={fixed}>
        <AlertBannerContainer color={color}>
            <div>
                <AlertBannerTitle>{title}</AlertBannerTitle>
                {children && (
                    <AlertBannerContent>{children}</AlertBannerContent>
                )}
                {onClick && (
                    <AlertBannerButton color={color}>
                        <StyledButton
                            dataTrackId="alert_banner__button"
                            appearance="outlined"
                            variant="primary"
                            onClick={onClick}
                        >
                            {buttonText}
                        </StyledButton>
                    </AlertBannerButton>
                )}
            </div>
            <BackgroundIcon>
                <i className="fa-solid fa-info-circle" />
            </BackgroundIcon>
        </AlertBannerContainer>
    </AlertBannerWrapper>
);

export default AlertBanner;
