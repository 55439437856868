import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon, Stack } from '@partoohub/ui';

interface AutomaticMessagesConfigurationSectionHeaderChevronIconProps {
    isOpened: boolean;
}

interface AutomaticMessagesConfigurationContentContainerProps {
    isOpened: boolean;
}

export const AutomaticMessagesConfigurationContentWrapper = styled(Stack)`
    width: 100%;
`;

export const AutomaticMessagesConfigurationSectionContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #1425421f;
`;

export const AutomaticMessagesConfigurationSectionHeader = styled.div`
    padding: 24px;
    cursor: pointer;
`;

export const AutomaticMessagesConfigurationSectionHeaderChevronIcon = styled(Icon)`
    display: flex;
    align-items: center;
    ${(props: AutomaticMessagesConfigurationSectionHeaderChevronIconProps) =>
        props.isOpened &&
        css`
            transform: rotate(90deg);
        `}
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    cursor: pointer;
`;

export const AutomaticMessagesConfigurationContentContainer = styled.div<AutomaticMessagesConfigurationContentContainerProps>`
    border-radius: 8px;
    border: 1px solid #1425421f;
`;

export const AutomaticMessagesConfigurationContentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
`;

export const AutomaticMessagesConfigurationContentExpandableContent = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-top: 1px solid #1425421f;
`;

export const SaveActivationButtonContainer = styled(Stack)`
    padding: 16px;
    margin: 0 -24px -16px -24px;
    border-top: 1px solid var(--Blackberry-Alpha, rgba(20, 37, 66, 0.12));
`;

export const BusinessInputContainer = styled.div`
    position: relative;
    .chevron-button {
        position: absolute;
        right: 24px;
        top: 20px;
    }

    .no-selected-businesses input {
        color: ${({ theme }) => theme.colors.secondary.text};
        font-weight: 600;
    }
`;
