import { useMemo } from 'react';

import { RangeDate } from '@partoohub/ui';
import { useDispatch, useSelector } from 'react-redux';

import { useLocaleDatepicker } from 'app/common/hooks/useLocaleDatepicker';
import {
    resetReviewFilterAction,
    reviewFiltersSelector,
    setDatesFilterAction,
} from 'app/states/reviews/filters';

export const useDatesReviewAnalyticsFilters = () => {
    const localeDatePicker = useLocaleDatepicker();

    const reviewFilters = useSelector(reviewFiltersSelector);

    const dispatch = useDispatch();

    const resetDate = () => {
        dispatch(resetReviewFilterAction('date'));
    };

    const onChange = (dates: RangeDate) => {
        dispatch(setDatesFilterAction(dates));
    };

    const datesFormatted: RangeDate = useMemo(() => {
        const fromDate = reviewFilters?.date?.from ? reviewFilters.date.from : null;
        const toDate = reviewFilters?.date?.to ? reviewFilters.date.to : null;
        return [fromDate, toDate];
    }, [reviewFilters.date.from, reviewFilters.date.to]);

    return {
        datesFormatted,
        onChange,
        resetDate,
        localeDatePicker,
    };
};
