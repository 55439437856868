import React, { FC, PropsWithChildren, useEffect } from 'react';

import { compose } from 'redux';

import {
    FlexRow,
    NewReactContentContainer,
    ReactContentContainer,
    ReactPageContainer,
} from 'app/common/components/ReactPage.styled';

import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { TaskProvider } from 'app/common/contexts/taskListener';
import { WebsocketConnectionProvider } from 'app/common/contexts/websocketConnection';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import Sidebar from 'app/common/designSystem/components/molecules/Sidebar';
import { MobileSidebar } from 'app/common/designSystem/components/molecules/Sidebar/mobile/MobileSidebar';

import { useIsTablet } from 'app/hooks/useIsTablet';
import { LoaderView } from 'app/pages/analytics/VisibilityAnalyticsV2/components/LoaderView/LoaderView';
import { useIsConversation } from 'app/pages/conversations/Messaging/hooks/useIsConversation';

type Props = {
    isLoading: boolean;
    pathname: string;
    loadUserGroupsData: (arg0: string) => void;
};

const NewReactPage = ({
    children,
    isLoading = false,
    loadUserGroupsData,
    pathname,
}: PropsWithChildren<Props>) => {
    const isConversation = useIsConversation();
    const applyNewBackground = !isConversation;

    useEffect(() => {
        loadUserGroupsData(pathname);
    }, [loadUserGroupsData, pathname]);

    return (
        <FlexRow>
            <ReactPageContainer id="react-page" applyNewBackground={applyNewBackground}>
                {isLoading && <LoaderView height={100} />}
                <NewReactContentContainer id="react-content">{children}</NewReactContentContainer>
            </ReactPageContainer>
        </FlexRow>
    );
};

const OldReactPage = ({
    children,
    isLoading = false,
    loadUserGroupsData,
    pathname,
}: PropsWithChildren<Props>) => {
    const isTablet = useIsTablet();
    const isConversation = useIsConversation();

    const applyNewBackground = !isConversation;

    useEffect(() => {
        loadUserGroupsData(pathname);
    }, [loadUserGroupsData, pathname]);

    return (
        <FlexRow>
            {isTablet ? <MobileSidebar /> : <Sidebar />}
            <ReactPageContainer id="react-page" applyNewBackground={applyNewBackground}>
                {isLoading && <LoaderView height={100} />}
                <ReactContentContainer id="react-content">{children}</ReactContentContainer>
            </ReactPageContainer>
        </FlexRow>
    );
};

const ReactPage = (props: Props) => {
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);

    return isNewSidebarEnabled ? <NewReactPage {...props} /> : <OldReactPage {...props} />;
};

// A helper HOC to put WebsocketConnectionProvider above the consumer hook below
const withWebsocketConnection =
    <P extends JSX.IntrinsicAttributes>(WrappedComponent: React.ComponentType<P>): FC<P> =>
    props => (
        <WebsocketConnectionProvider>
            <WrappedComponent {...props} />
        </WebsocketConnectionProvider>
    );

// HOC to catch task updates based on websocket events above
const withJobEventsListner =
    <P extends JSX.IntrinsicAttributes>(WrappedComponent: React.ComponentType<P>): FC<P> =>
    props => (
        <TaskProvider>
            <WrappedComponent {...props} />
        </TaskProvider>
    );

export default compose(withWebsocketConnection, withJobEventsListner)(ReactPage);
