import { HEX_COLORS } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

import useBusinessAddress from 'app/reviewManagement/reviewList/hooks/useBusinessAddress';

import { FEEDBACK_PANEL_AVATAR_WIDTH } from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/ReviewCardHeader/ReviewCardAvatar/common';

import {
    AvatarContainer,
    StyledFeedbackResultPanelHeader,
} from './FeedbackResultPanelHeader.styled';
import { FeedbackResultCardAvatar } from '../../ReviewCard/ReviewCardHeader/ReviewCardAvatar';
import { useFeedbackResultPanel } from '../hooks';

export const FeedbackResultPanelHeader = () => {
    const { feedbackResult } = useFeedbackResultPanel();
    const address = useBusinessAddress(feedbackResult?.businessId ?? '');

    if (!feedbackResult) return undefined;

    return (
        <StyledFeedbackResultPanelHeader>
            <AvatarContainer>
                <FeedbackResultCardAvatar size={FEEDBACK_PANEL_AVATAR_WIDTH} />
            </AvatarContainer>
            <div>
                <Text variant="heading4">{feedbackResult.authorName}</Text>
                <Text variant="bodyLSemibold" color={HEX_COLORS.secondary}>
                    {address}
                </Text>
            </div>
        </StyledFeedbackResultPanelHeader>
    );
};
