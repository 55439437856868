import { Stack } from '@partoohub/ui';
import { cloneDeep } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { Choice } from 'app/api/types/user';
import useSuggestedCategories from 'app/businessEditV2/hooks/business/useSuggestedCategories';
import {
    BusinessPage,
    trackClickAdditionalCategoriesField,
    trackClickCategoriesSuggestions,
} from 'app/businessEditV2/utils/tracking';
import {
    PublisherErrors,
    PublishersInfo,
} from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithPublisherList';
import AsyncMultipleSelect from 'app/common/designSystem/components/molecules/AsyncMultipleSelect';
import AsyncSingleSelect from 'app/common/designSystem/components/molecules/AsyncSingleSelect';

export type Props = {
    onChange: (arg0: Array<Choice>, reloadAttribute?: boolean) => void;
    options: Array<Choice>;
    selectedOption: Array<Choice>;
    // Optional props
    textTooltip?: string;
    // text of the tooltip
    disabled?: boolean;
    onInputChange?: (arg0: string) => void;
    isLoading?: boolean;
    // withExplanationProps
    errorMessage?: string;
    publisherErrors?: PublisherErrors;
    publishersInfo?: PublishersInfo;
    hasError?: boolean;
    hasWarning?: boolean;
    hideExplanation?: boolean; // explanation texts are removed for Business Edit V2
    isMandatory?: boolean;
    page?: BusinessPage;
};

const CategorySelectSplit = ({
    onChange,
    options,
    selectedOption,
    textTooltip = '',
    disabled = false,
    onInputChange = () => undefined,
    isLoading = false,
    errorMessage,
    publisherErrors,
    publishersInfo,
    hasError,
    hasWarning,
    hideExplanation = false,
    isMandatory = false,
    page,
}: Props) => {
    const onMainCategoryChange = (choice: Choice | null) => {
        if (choice) {
            const mainCategory = options.find(option => option.value === choice.value);

            if (mainCategory) {
                const newCategories = selectedOption.filter(
                    (option, index) => index !== 0 && option.value !== choice.value,
                );
                onChange([mainCategory].concat(newCategories));
            }
        }
    };

    const onAdditionalCategoryChange = (values: Array<Choice>) => {
        const mainCategory = selectedOption[0];
        onChange([mainCategory].concat(values), false);
    };

    const selectedOptions = cloneDeep(selectedOption);
    const mainCategory = selectedOptions.shift();
    const optionsFiltered = mainCategory
        ? options.filter(option => option.value !== mainCategory.value)
        : [];

    const { t } = useTranslation();
    const { data: suggestedOptionsData } = useSuggestedCategories(mainCategory?.value || '');

    const suggestedOptions =
        selectedOption.length < 10
            ? suggestedOptionsData?.categories.map(data => {
                  return { label: data.name, value: data.gmb_id };
              })
            : [];

    const onClickAdditionalCategories = () => {
        if (page) {
            trackClickAdditionalCategoriesField(page, mainCategory?.value);
        }
    };
    const onClickSuggestion = () => {
        if (page) {
            trackClickCategoriesSuggestions(page, mainCategory?.value);
        }
    };

    return (
        <Stack gap="24px">
            <AsyncSingleSelect
                isMandatory={isMandatory}
                loadMore={onInputChange}
                placeholder={t('main_category')}
                options={options}
                selectedValue={mainCategory}
                onChange={onMainCategoryChange}
                disabled={disabled}
                textTooltip={t(textTooltip)}
                hasEmptyValue={false}
                mustFilterOptions={false}
                hideCross // withExplanationProps
                hasError={hasError}
                hasWarning={hasWarning}
                explanation={hideExplanation ? '' : t('main_category_description')}
                errorMessage={errorMessage}
                publisherErrors={publisherErrors}
                publishersInfo={publishersInfo}
                dataTrack="visibility_location__form_categories__main_category"
            />
            <AsyncMultipleSelect
                onChange={onAdditionalCategoryChange}
                options={optionsFiltered}
                selectedOptions={selectedOptions}
                disabled={disabled}
                loadMore={onInputChange}
                placeholder={t('additional_categories')}
                searchPlaceholder={t('categories_search_placeholder')}
                textTooltip={t(textTooltip)}
                explanation={hideExplanation ? '' : t('all_categories_description')}
                isLoading={isLoading}
                mustFilterOptions={false}
                selectedOptionsLimit={9} // withExplanationProps
                errorMessage={errorMessage}
                hasError={hasError}
                suggestedOptions={suggestedOptions}
                suggestedOptionsHeader={t('suggestions')}
                onClickSuggestion={onClickSuggestion}
                onOpenMenu={onClickAdditionalCategories}
                dataTrack="visibility_location__form_categories__additional_categories"
            />
        </Stack>
    );
};

export default CategorySelectSplit;
