import { ReactNode } from 'react';

import { Avatar, Icon, IconPrefix, Text, TextVariant } from '@partoohub/ui';

import { TFunction } from 'i18next';

import { V2UserData } from 'app/api/types/user';
import { STATIC_APP_ASSETS_URL } from 'app/config';
import { JimIconCircled } from 'app/pages/settings/Chatbot/assets/jim-icon';
import { AssignConversationStats, AssignFilterOption } from 'app/states/messaging';

import { StyledStack } from './AssignedConversationFilter.styled';
import { UserAvatar } from '../../UserAvatar/UserAvatar';

export const getOptionsLabels = (
    t: TFunction<'translation', undefined>,
    stats: AssignConversationStats,
    hasAccessToAssignation: boolean,
    hasChatbot: boolean,
    me?: V2UserData,
) => {
    const allText = t('messaging_assign_filter_all');
    const meText = t('messaging_assign_filter_me');
    const unassignedText = t('messaging_assign_filter_unassigned');
    const chatbotText = t('messaging_assign_filter_chatbot');

    const allLabelText = makeLabelText(allText, stats.all);
    const meLabelText = makeLabelText(meText, stats.assigned_to_me);
    const unassignedLabelText = makeLabelText(unassignedText, stats.not_assigned);
    const automatedLabelText = makeLabelText(chatbotText, stats.automated);

    const textVariant = 'bodyMMedium';

    const options = new Map<string, JSX.Element>();
    options.set(
        AssignFilterOption.ALL,
        makeFilterLabel(allIcon(), allLabelText, textVariant, '12px', '4px'),
    );

    if (hasAccessToAssignation) {
        options.set(AssignFilterOption.ME, makeFilterLabel(meAvatar(me), meLabelText, textVariant));
        options.set(
            AssignFilterOption.UNASSIGNED,
            makeFilterLabel(unassignedAvatar(), unassignedLabelText, textVariant),
        );
    }
    if (hasChatbot) {
        options.set(
            AssignFilterOption.AUTOMATED,
            makeFilterLabel(chatbotAvatar(), automatedLabelText, textVariant),
        );
    }

    return options;
};

export const getMenuLabels = (t: TFunction<'translation', undefined>, me?: V2UserData) => {
    const allText = t('messaging_assign_filter_all');
    const meText = t('messaging_assign_filter_me');
    const unassignedText = t('messaging_assign_filter_unassigned');
    const chatbotText = t('messaging_assign_filter_chatbot');

    const textVariant = 'bodySBold';

    return new Map([
        [AssignFilterOption.ALL, makeFilterLabel(allIcon(), allText, textVariant)],
        [AssignFilterOption.ME, makeFilterLabel(meAvatar(me), meText, textVariant)],
        [
            AssignFilterOption.UNASSIGNED,
            makeFilterLabel(unassignedAvatar(), unassignedText, textVariant),
        ],
        [AssignFilterOption.AUTOMATED, makeFilterLabel(chatbotAvatar(), chatbotText, textVariant)],
    ]);
};

const makeFilterLabel = (
    image: ReactNode,
    text: string,
    textVariant: TextVariant,
    gap: string = '8px',
    paddingLeft: string = '0px',
) => {
    return (
        <StyledStack direction="row" gap={gap} alignItems="center" paddingLeft={paddingLeft}>
            {image}
            <Text as="span" variant={textVariant}>
                {text}
            </Text>
        </StyledStack>
    );
};

const allIcon = () => {
    return <Icon icon={['fa-bars', IconPrefix.SOLID]} iconSize="small" />;
};

const meAvatar = (me?: V2UserData) => {
    const user = me
        ? {
              firstName: me.first_name,
              lastName: me.last_name,
              role: me.role,
              id: me.id,
          }
        : null;

    return <UserAvatar user={user} size={24} textSize={10} />;
};

const unassignedAvatar = () => {
    return <Avatar image={`${STATIC_APP_ASSETS_URL}/common/unassigned_icon.svg`} size={24} />;
};

const chatbotAvatar = () => {
    return <JimIconCircled size={24} />;
};

const makeLabelText = (text: string, count: number) => {
    const makeCountText = (count: number) => {
        return count <= 99 ? `${count}` : '99+';
    };

    return `${text} (${makeCountText(count)})`;
};

export const getOptions = (labels: Map<string, JSX.Element>) => {
    return Array.from(labels.entries(), ([name, label]) =>
        Object.create({ label: label, value: name, name: name }),
    );
};
