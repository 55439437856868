import { useEffect, useState } from 'react';

export function useAnimateCounter(
    target: number,
    from: number = 0,
    duration: number = 1000,
    onAnimationComplete?: () => void,
) {
    const [count, setCount] = useState(from);

    useEffect(() => {
        if (count === target) return;

        const step = (target - count) / (duration / 10);

        const intervalId = setInterval(() => {
            setCount((currentValue: number) => {
                let newCountValue = currentValue + step;
                if (newCountValue < 0) newCountValue = 0;

                if (
                    (step > 0 && newCountValue >= target) ||
                    (step < 0 && newCountValue <= target)
                ) {
                    clearInterval(intervalId);
                    onAnimationComplete && onAnimationComplete();
                    return target;
                }

                return newCountValue;
            });
        }, 10);

        return () => {
            clearInterval(intervalId);
            onAnimationComplete && onAnimationComplete();
        };
    }, [target, duration, count]);

    return Math.round(count);
}

interface AnimatedCounterProps {
    to: number;
    from?: number;
    onAnimationComplete?: () => void;
    duration?: number;
}

export const AnimatedCounter: React.FC<AnimatedCounterProps> = ({
    to,
    from = 0,
    duration = 1000,
    onAnimationComplete,
}) => {
    const animatedCount = useAnimateCounter(to, from, duration, onAnimationComplete);
    return <span>{animatedCount}</span>;
};
