import { useInfiniteQuery } from 'react-query';

import CamelApi from 'app/api/v2/api_calls/camel';
import { BUSINESS_LOCATIONS } from 'app/common/data/queryKeysConstants';
import { useLocationSearchQueryParams } from 'app/settingsManagement/components/GoogleVerification/utils/queryParams/useLocationSearchQueryParams';

export const VERIFICATION_STATUS_TO_SEARCH_IN = ['unverified', 'pending'];

export default function useLocationSearch() {
    const queryParams = useLocationSearchQueryParams();
    const minCharactersToSearch = 3;
    const locationsByPage = 20;

    return useInfiniteQuery(
        [
            BUSINESS_LOCATIONS,
            queryParams?.query?.length >= minCharactersToSearch
                ? queryParams.query
                : '',
            queryParams.order_by,
        ],
        ({ pageParam = 1 }) =>
            CamelApi.account.verificationsFuzzySearchLocations({
                ...queryParams,
                page: pageParam,
                verification_status: VERIFICATION_STATUS_TO_SEARCH_IN,
            }),
        {
            getNextPageParam: lastPage =>
                lastPage.locations.length == locationsByPage
                    ? lastPage.page + 1
                    : undefined,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );
}
