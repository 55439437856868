import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { CLOSED } from 'app/common/data/businessStatuses';

export function useBusinessStatus(): string {
    const { data: business } = useBusiness();
    return business?.status || '';
}

export function useIsBusinessClosed(): boolean {
    const status = useBusinessStatus();
    return status === CLOSED;
}
