import { Stack, Switch, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ToggleBoxContainer } from './ToggleBox.styled';

export type Props = {
    text: string;
    description: string;
    checked?: boolean;
    onClick: (e: MouseEvent) => void;
};

const ToggleBox = ({ checked = true, onClick, text, description }: Props) => {
    const { t } = useTranslation();
    return (
        <ToggleBoxContainer gap={'4px'} direction="row" alignItems="center">
            <Stack gap="4px">
                <Text variant="bodyLBold">{t(text)}</Text>
                <Text variant="bodyMRegular" color="secondary">
                    {t(description)}
                </Text>
            </Stack>
            <div className="toggle-box-icon">
                <Switch
                    dataTrackId="toggle_box__switch"
                    name="toggle_box"
                    checked={checked}
                    onChange={onClick}
                />
            </div>
        </ToggleBoxContainer>
    );
};
export default ToggleBox;
