import { css } from '@emotion/react';

export const oneColumnTable = css`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;
    grid-auto-rows: auto;
`;

export const twoColumnsTable = css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
    grid-auto-rows: auto;
`;

export const threeColumnsTable = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    grid-auto-rows: auto;
    padding-bottom: 24px;
`;

/**
 * @deprecated reliquat scss migration use Theme directly
 */
const deprecatedColors = {
    primary: '#0085f2',
    secondary: '#7f8ea4',
    tertiary: '#ec4b88',
    quaternary: '#f7c200',
    sidebar: '#2b3e51',
    success: '#40dba2',
    warning: '#ff9014',
    danger: '#ff426e',
    info: '#d0d2db',
    dark: '#333333',
    light: '#e6e8ed',
    black: '#000000',
    white: '#ffffff',
    background: '#f4f5f9',
    shadowLight: 'rgba(0, 0, 0, 0.15)',
    overlay: 'rgba(15, 22, 31, 0.55)',
    transparent: 'rgba(255, 255, 255, 0.1)',
    borderColor: 'rgba(51, 51, 51, 0.11)',
    disabled: 'rgba(127, 142, 164, 0.25)',
    disabledOpaque: '#e0e3eb',
    grey: 'rgb(161, 161, 161)',
    sidebarColor: '#f5f7fb',
    rating: '#f7c200',
    successTransparent: 'rgba(64, 219, 162, 0.12)',
    warningTransparent: 'rgba(255, 144, 20, 0.12)',
    pendingTransparent: 'rgba(3, 159, 241, 0.12)',
    dangerTransparent: 'rgba(255, 66, 110, 0.12)',
    secondaryTransparent: 'rgba(127, 142, 164, 0.12)',
};

/**
 * @deprecated reliquat scss migration avoid to use this class
 */
export const deprecatedClassColors = css`
    .color--primary {
        color: ${deprecatedColors.primary};
    }

    .color--blueberry {
        color: ${deprecatedColors.primary};
    }

    .color--pending {
        color: ${deprecatedColors.primary};
    }

    .color--secondary {
        color: ${deprecatedColors.secondary};
    }

    .color--tertiary {
        color: ${deprecatedColors.tertiary};
    }

    .color--quaternary {
        color: ${deprecatedColors.quaternary};
    }

    .color--sidebar {
        color: ${deprecatedColors.sidebar};
    }

    .color--success {
        color: ${deprecatedColors.success};
    }

    .color--warning {
        color: ${deprecatedColors.warning};
    }

    .color--danger {
        color: ${deprecatedColors.danger};
    }

    .color--info {
        color: ${deprecatedColors.info};
    }

    .color--dark {
        color: ${deprecatedColors.dark};
    }

    .color--light {
        color: ${deprecatedColors.light};
    }

    .color--black {
        color: ${deprecatedColors.black};
    }

    .color--white {
        color: ${deprecatedColors.white} !important;
    }

    .color--background {
        color: ${deprecatedColors.background};
    }

    .color--shadow {
        color: ${deprecatedColors.shadowLight};
    }

    .color--overlay {
        color: ${deprecatedColors.overlay};
    }

    .color--transparent {
        color: ${deprecatedColors.transparent};
    }

    .color--border {
        color: ${deprecatedColors.borderColor};
    }

    .color--disable {
        color: ${deprecatedColors.disabled};
    }

    .color--grey {
        color: ${deprecatedColors.grey};
    }

    .color--danger_important {
        color: ${deprecatedColors.danger} !important;
    }

    .bg-primary,
    .background-color--primary {
        background-color: ${deprecatedColors.primary};
    }

    .bg-secondary,
    .background-color--secondary {
        background-color: ${deprecatedColors.secondary};
    }

    .bg-tertiary,
    .background-color--tertiary {
        background-color: ${deprecatedColors.tertiary};
    }

    .bg-quaternary,
    .background-color--quaternary {
        background-color: ${deprecatedColors.quaternary};
    }

    .bg-sidebar,
    .background-color--sidebar {
        background-color: ${deprecatedColors.sidebar};
    }

    .bg-success,
    .background-color--success {
        background-color: ${deprecatedColors.success};
    }

    .bg-warning,
    .background-color--warning {
        background-color: ${deprecatedColors.warning};
    }

    .bg-pending,
    .background-color--pending {
        background-color: ${deprecatedColors.primary};
    }

    .bg-danger,
    .background-color--danger {
        background-color: ${deprecatedColors.danger};
    }

    .bg-info,
    .background-color--info {
        background-color: ${deprecatedColors.info};
    }

    .bg-dark,
    .background-color--dark {
        background-color: ${deprecatedColors.dark};
    }

    .bg-light,
    .background-color--light {
        background-color: ${deprecatedColors.light};
    }

    .bg-black,
    .background-color--black {
        background-color: ${deprecatedColors.black};
    }

    .bg-white,
    .background-color--white {
        background-color: ${deprecatedColors.white};
    }

    .bg-background-color,
    .background-color--background {
        background-color: ${deprecatedColors.white};
    }

    .bg-shadow,
    .background-color--shadow {
        background-color: ${deprecatedColors.shadowLight};
    }

    .bg-overlay,
    .background-color--overlay {
        background-color: ${deprecatedColors.overlay};
    }

    .bg-transparent,
    .background-color--transparent {
        background-color: ${deprecatedColors.transparent};
    }

    .bg-border,
    .background-color--border {
        background-color: ${deprecatedColors.borderColor};
    }

    .bg-disable,
    .background-color--disable {
        background-color: ${deprecatedColors.disabled};
    }

    .bg-grey,
    .background-color--grey {
        background-color: ${deprecatedColors.grey};
    }

    .bg-primary--lighten {
        background-color: ${deprecatedColors.primary};
    }
`;

/**
 * @deprecated reliquat scss migration - please don't use these classes
 */
export const deprecatedUtilsStyles = css`
    .search-area-size {
        input {
            font-size: 24px !important;
            font-weight: bold;
        }
    }

    .separator--horizontal {
        width: 100%;
        height: 1px;
        background-color: #e6e8ed;
    }

    .separator--vertical {
        height: 100%;
        width: 3px;
        background-color: #e6e8ed;
    }

    .separator_min_height--vertical {
        height: 100%;
        width: 3px;
        background-color: #e6e8ed;
        min-height: 10px;
    }

    .flex--col {
        display: flex;
        flex-flow: column nowrap;
    }

    .flex--row {
        display: flex;
        flex-flow: row nowrap;
    }

    .flex--fill {
        display: flex;
        flex: 1;
    }

    .flex--justify_end {
        display: flex;
        justify-content: flex-end;
    }

    .flex--justify_center {
        display: flex;
        justify-content: center;
    }

    .flex--justify_between {
        display: flex;
        justify-content: space-between;
    }

    .flex--justify_around {
        display: flex;
        justify-content: space-around;
    }

    .flex--align_items_center {
        display: flex;
        align-items: center;
    }

    .flex--align_items_end {
        display: flex;
        align-items: flex-end;
    }

    .flex--inherit {
        display: flex;
        justify-content: inherit;
        align-items: inherit;
    }

    .flex-1 {
        flex: 1;
    }

    .children_margin--left {
        > * {
            margin-left: 8px;
        }
    }

    .children_margin--bottom {
        > * {
            margin-bottom: 8px;
        }
    }

    .children_margin--right {
        > * {
            margin-right: 8px;
        }
    }

    .children_margin_double--left {
        > * {
            margin-left: #{2 * 8px};
        }
    }

    .children_but_last_margin--right {
        > *:not(:last-child) {
            margin-right: 8px;
        }
    }

    .highlight_text {
        background-color: #ff0;
    }

    .top-margin {
        margin-top: 8px;
    }

    .padding--simple {
        padding: 8px;
    }

    .padding_bottom--double {
        padding-bottom: 2 * 8px;
    }

    .padding_top--double {
        padding-top: 2 * 8px;
    }

    .margin_left--half {
        margin-left: calc(8px / 2);
    }

    .margin_left--simple {
        margin-left: 8px;
    }

    .margin_right--simple {
        margin-right: 8px;
    }

    .margin_bottom--simple {
        margin-bottom: 8px;
    }

    .margin_top--simple {
        margin-top: 8px;
    }

    .margin_left--double {
        margin-left: 2 * 8px;
    }

    .margin_right--double {
        margin-right: 2 * 8px;
    }

    .margin_bottom--double {
        margin-bottom: 2 * 8px;
    }

    .margin_top--double {
        margin-top: 2 * 8px;
    }

    .margin_right--triple {
        margin-right: 3 * 8px;
    }

    .margin_bottom--triple {
        margin-bottom: 3 * 8px;
    }

    .margin_top--triple {
        margin-top: 3 * 8px;
    }

    .width--medium {
        width: 200px;
    }

    .min_width-zero {
        min-width: 0;
    }

    textarea {
        resize: none;
    }

    .clickable {
        cursor: pointer;
        user-select: none;

        &:focus {
            outline: 0;
        }
    }

    .clickable[disabled] {
        pointer-events: none;
        opacity: 0.7;
    }
`;

/**
 * @deprecated reliquat scss migration - linked to old components
 */
export const deprecatedOldComponentsStyle = css`
    .font-button {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
    }

    .font-regular {
        font-size: 14px;
        color: #333333;
    }

    .font-small-bold {
        font-size: 12px;
        font-weight: bold;
    }

    .font-small {
        font-size: 12px;
    }

    .cursor_not_allowed {
        cursor: not-allowed;
    }

    // tooltip
    .tooltip-position {
        &:before {
            position: absolute;
            content: attr(content);
            text-align: center;
            font-size: 12px;
            padding: 4px 8px;
            background: #2b3e51;
            color: white;
            white-space: pre;
            text-overflow: ellipsis;
            max-width: 500px;
            overflow: hidden;
            border-radius: 4px;
            opacity: 0;
            pointer-events: none;
        }

        &:hover:before {
            z-index: 1;
            opacity: 1;
        }

        &__top-start:before {
            transform: translate(0%, -100%);
        }

        &__top:before {
            transform: translate(-50%, -100%);
            left: 50%;
        }

        &__top-end:before {
            transform: translate(0%, -100%);
            right: 0;
        }

        &__bottom-start:before {
            top: 100%;
        }

        &__bottom:before {
            top: 100%;
            left: 50%;
            transform: translate(-50%, 0);
        }

        &__bottom-end:before {
            top: 100%;
            right: 0;
        }

        &__left-start:before {
            right: 100%;
        }

        &__left:before {
            right: 100%;
            top: 50%;
            transform: translate(0, -50%);
        }

        &__left-end:before {
            right: 100%;
            bottom: 0;
        }

        &__right-start:before {
            left: 100%;
        }

        &__right:before {
            left: 100%;
            top: 50%;
            transform: translate(0, -50%);
        }

        &__right-end:before {
            left: 100%;
            bottom: 0;
        }

        &__center:before {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .tooltip-position__button {
        border-radius: 50px;
    }

    /*-------------------------TEXT FIELD-------------------------*/

    .text-field__disabled {
        color: rgba(127, 142, 164, 0.25) !important;
        pointer-events: none !important;
    }

    div.text-field__background__readonly {
        background-color: #f5f7fb;
    }

    .img__disabled {
        opacity: 0.25;
    }

    .arrow__disabled {
        color: rgba(127, 142, 164, 0.25) !important;
    }

    .text-area___resize--vertical {
        resize: vertical;
    }

    .collapsible_sidebar {
        background-color: #f5f7fb;
        width: 230px;
        height: 100%;
        padding: 32px 16px;
    }

    .collapsible_sidebar--collapsed {
        z-index: -1;
        width: 0;
        padding: 32px 0;
    }

    .collapsible_sidebar-title {
        font-size: 18px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 32px;
    }

    .collapsible_sidebar-page {
        margin-top: 24px;
    }

    .collapsible_sidebar-page-title {
        font-size: 14px;
        font-weight: bold;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .collapsible_sidebar-page-title:hover,
    .collapsible_sidebar-page-title--active {
        color: var(--pto-primary);
    }

    .collapsible_sidebar-menu_items {
        margin-left: 8px;
        opacity: 1;
        max-height: calc(100vh - 225px);
        display: flex;
        flex-direction: column;
    }

    .collapsible_sidebar-menu_items_scroll {
        max-height: inherit;
        overflow-y: auto;

        div {
            height: auto;
        }

        div:not(.collapsible_sidebar-menu_items--collapsed) {
            max-height: none;
        }
    }

    .collapsible_sidebar-menu_items--collapsed {
        z-index: -1;
        max-height: 0;
        overflow: hidden;
        opacity: 0.5;
    }

    .collapsible_sidebar-menu_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 22px;
        margin-top: 8px;

        &.clickable {
            margin-top: 4px;
        }
    }

    .collapsible_sidebar-menu_item--active {
        font-weight: bold;
    }

    .collapsible-filter__chevron {
        font-size: 12px;
        color: #7f8ea4;
    }

    /*-------------------------STRIKETHROUGHBUTTON-------------------------*/
    .strike_through_button {
        cursor: pointer;
        display: inline-block;
        border: none;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        position: relative;
        padding: 0 2px;

        &:hover {
            text-decoration: none;

            &:before {
                opacity: 0.35;
                height: 4px !important;
                transform: translateY(4px);
            }
        }

        &:focus {
            outline: none;
        }

        &:before {
            opacity: 0.15;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transition: all ease-in-out 0.3s;
            height: 5px;
        }

        &.strike_through_button--primary {
            color: #0085f2;

            &:before {
                background: #0085f2;
            }
        }

        &.strike_through_button--secondary {
            color: #7f8ea4;

            &:before {
                background: #7f8ea4;
            }
        }

        &.strike_through_button--success {
            color: #40dba2;

            &:before {
                background: #40dba2;
            }
        }

        &.strike_through_button--danger {
            color: #ff426e;

            &:before {
                background: #ff426e;
            }
        }

        &.strike_through_button--warning {
            color: #ff9014;

            &:before {
                background: #ff9014;
            }
        }

        &.strike_through_button--disabled {
            color: rgba(127, 142, 164, 0.25);
            cursor: not-allowed;

            &:before {
                background: rgba(127, 142, 164, 0.25);
            }
        }
    }

    /*-------------------------MENTIONS-TEXTAREA------------------------*/

    .mentions__mention_preview {
        border-radius: 10px;
        color: #7f8ea4;
        background: rgba(127, 142, 164, 0.1);
        font-size: 12px;
        font-weight: bold;
    }

    /*-------------------------PARTOO-CARDS------------------------*/

    .partoo_card--hover {
        transition: all ease-in-out 0.2s;

        &:hover {
            box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
        }
    }

    .partoo_card {
        transition: all ease-in-out 0.2s;

        &:hover {
            box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
        }

        display: flex;
        flex: 1;
        border-radius: 4px;
        border: solid 1px rgba(51, 51, 51, 0.11);
        min-height: 20px;

        &:hover {
            background: rgba(3, 159, 241, 0.12);
        }
    }

    .partoo_card__no_hover {
        &:hover {
            background: none;
        }
    }

    .partoo_card__message_template_form {
        border: solid 1px #0085f2;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    }

    .mentions_danger {
        border-color: #ff426e !important;
    }

    .mentions_normal__explanation {
        color: #7f8ea4;
        height: 20px;
    }

    .mentions_danger__explanation {
        color: #ff426e;
        height: 20px;
    }

    /*-------------------------ERROR-MESSAGE------------------------*/

    .error-placeholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .error-placeholder-message {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
        }

        .error-placeholder-button {
            margin-top: 3px;
        }
    }

    /*-------------------------TOOGLE-BOX---------------------------*/

    .toggle-box-container {
        display: flex;
        padding: 16px;
        border: 1px solid #e6e8ed;
        border-radius: 4px;
    }

    .toggle-box-title {
        display: flex;
        font-size: 18px;
        font-weight: bold;
    }

    .toggle-box-description {
        color: #7f8ea4;
        font-size: 14px;
        font-weight: bold;
    }

    .toggle-box-icon {
        display: flex;
        margin: auto 0 auto auto;
    }

    /*-------------------------SINGLE SELECT-------------------------*/
    .single-select__selected_item {
        margin-bottom: 8px;

        &--focused {
            position: absolute;
            top: 2px;
            color: #7f8ea4;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 95%;
        }
    }

    /*-------------------------MULTIPLE SELECT-------------------------*/

    .multiple_select {
        display: flex;
        position: relative;
        min-height: 46px;
        background: white;
        border-radius: 4px;

        &--disabled {
            cursor: not-allowed;

            div,
            button,
            i {
                color: rgba(127, 142, 164, 0.25);
                border-color: rgba(127, 142, 164, 0.25);
                background-color: white;
            }
        }

        &__search_placeholder {
            position: absolute;
            top: 13px;
            left: 19px;
            color: #7f8ea4;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
        }

        &__field {
            display: flex;
            flex: 1;
            align-items: center;
            width: 100%;
            border: solid 1px #e6e8ed;
            border-radius: 4px;
            padding: 10px 16px;
            transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
            cursor: pointer;

            &--async {
                cursor: text !important;
            }

            &--unfocused {
                &:hover {
                    border-color: #333333;
                }
            }

            &--focused {
                border-color: #0085f2;
                box-shadow: 0 0 0 1px #0085f2;

                &:hover {
                    border-color: #0085f2;
                }
            }

            &--error {
                border-color: #ff426e;

                &:hover {
                    border-color: #ff426e;
                }
            }

            &--warning {
                border-color: #ff9014;

                &:hover {
                    border-color: #ff9014;
                }
            }

            &--focused_error {
                border-color: #ff426e;
                box-shadow: 0 0 0 1px #ff426e;

                &:hover {
                    border-color: #ff426e;
                }
            }

            &--focused_warning {
                border-color: #ff9014;
                box-shadow: 0 0 0 1px #ff9014;

                &:hover {
                    border-color: #ff9014;
                }
            }

            &--disabled {
                pointer-events: none;
            }

            &:focus {
                outline: none;
            }

            &__icon_button {
                color: #7f8ea4;
                border: none;
                padding: 0;
                font: inherit;
                cursor: pointer;
                outline: none;
                background: inherit;

                &:hover {
                    color: #333333;
                }

                &--error {
                    color: #ff426e;

                    &:hover {
                        color: #ff426e;
                    }
                }

                &--warning {
                    color: #ff9014;

                    &:hover {
                        color: #ff9014;
                    }
                }
            }

            &_items {
                display: flex;
                flex: 1;
                flex-wrap: wrap;
                margin-bottom: -8px;

                &--relative {
                    position: relative;
                }

                .async-input {
                    height: 24px;
                    margin-bottom: 8px;

                    &--single {
                        width: 97%;
                    }

                    > span {
                        position: absolute;
                        height: 0;
                        overflow: hidden;
                        white-space: pre;
                        letter-spacing: normal;
                    }

                    > input {
                        height: 24px;
                        border: none;
                        letter-spacing: normal;
                        width: 5px;
                        outline: none;
                    }
                }

                &_item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 24px;
                    padding: 0 8px;
                    border-radius: 12px;
                    border: none;
                    background: inherit;
                    margin-right: 4px;
                    margin-bottom: 8px;
                    color: #0085f2;
                    background-color: rgba(3, 159, 241, 0.12);

                    &_delete {
                        transition: all 0.15s;
                        border: none;
                        background: transparent;
                        padding: 0;
                        margin-left: 8px;
                        color: #0085f2;

                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }

                &--one_line {
                    width: 95%;

                    // Needed to display the selected option on one single line with an ellipsis
                    .single-select__selected_item {
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 95%;
                    }
                }
            }
        }

        &__placeholder {
            position: absolute;
            color: #7f8ea4;
            transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.15s;
            top: 13px;
            left: 17px;

            &--focused {
                color: #0085f2;
            }

            &--error {
                color: #ff426e;
            }

            &--warning {
                color: #ff9014;
            }

            &--top {
                padding: 0 4px;
                background-color: white;
                top: -1px;
                left: 11px;

                div {
                    font-weight: bold;
                    font-size: 12px;
                    margin-top: -9px;
                }
            }
        }

        &__menu {
            position: absolute;
            margin-top: 46px;
            width: 100%;
            padding: 8px;
            background: white;
            border-radius: 4px;
            max-height: 300px;
            box-shadow:
                0 3px 14px 2px rgba(0, 0, 0, 0.12),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 5px 5px -3px rgba(0, 0, 0, 0.2);
            z-index: 3;
            overflow-y: scroll;

            &--async {
                top: 100%;
                margin-top: 4px;
            }

            &__item {
                display: flex;
                align-items: center;
                text-align: left;
                justify-content: space-between;
                min-height: 38px;
                padding: 8px;
                border-radius: 4px;
                border: none;
                background-color: inherit;
                width: 100%;
                font-size: 14px;
                color: #333333;
                cursor: pointer;
                margin-bottom: 4px;
                transition: background-color ease 0.15s;

                &:hover {
                    background-color: #f5f7fb;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &_content {
                    display: flex;
                    align-items: center;
                    column-gap: 12px;
                    width: 95%;
                }

                &_selected {
                    color: #0085f2;
                    font-weight: bold;
                    background-color: rgba(3, 159, 241, 0.12);

                    &:hover {
                        background-color: var(--pto-primary-hover);
                    }
                }

                &_selected_key_over {
                    color: #0085f2;
                    font-weight: bold;
                    background-color: var(--pto-primary-hover);

                    &:hover {
                        background-color: var(--pto-primary-hover);
                    }
                }

                &_key_over {
                    background-color: #f5f7fb;
                }

                &__empty_placeholder {
                    display: flex;
                    align-items: center;
                    cursor: default;
                    height: 38px;
                    padding: 8px;
                    width: 100%;
                    font-size: 14px;
                    color: #7f8ea4;
                    background-color: inherit;
                    border: none;
                }
            }
        }

        &--explanation_error {
            color: #ff426e;
        }
    }

    .async-select__size {
        width: auto;
        max-width: 100%;
        min-width: 30%;
    }

    /*---------------------MULTIPLE SELECT IMAGE-----------------------*/
    .multiple_select_image_container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 36px;

        .multiple_select_image_list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .multiple_select_card {
                border: 1px solid #e6e8ed;
                border-radius: 4px;
                margin-right: 12px;
                margin-bottom: 12px;
                width: 208px;
                background-color: #f5f7fb;

                @media screen and (min-width: 1921px) {
                    width: calc(calc(100% / 7) - 13px * 6 / 7);

                    &:nth-of-type(7n + 7) {
                        margin-right: 0;
                    }
                }

                @media screen and (min-width: 1681px) and (max-width: 1920px) {
                    width: calc(calc(100% / 6) - 12px * 5 / 6);

                    &:nth-of-type(6n + 6) {
                        margin-right: 0;
                    }
                }

                @media screen and (min-width: 1441px) and (max-width: 1680px) {
                    width: calc(20% - 12px * 4 / 5);

                    &:nth-of-type(5n + 5) {
                        margin-right: 0;
                    }
                }

                @media screen and (min-width: 1181px) and (max-width: 1440px) {
                    width: calc(25% - 12px * 3 / 4);

                    &:nth-of-type(4n + 4) {
                        margin-right: 0;
                    }
                }

                @media screen and (min-width: 981px) and (max-width: 1180px) {
                    width: calc(calc(100% / 3) - 12px * 2 / 3);

                    &:nth-of-type(3n + 3) {
                        margin-right: 0;
                    }
                }

                @media screen and (min-width: 641px) and (max-width: 980px) {
                    width: calc(50% - 12px * 1 / 2);

                    &:nth-of-type(even) {
                        margin-right: 0;
                    }
                }

                @media screen and (max-width: 640px) {
                    width: 100%;
                    margin-right: 0;
                }

                &--disabled {
                    div {
                        color: rgba(127, 142, 164, 0.25);
                        border-color: rgba(127, 142, 164, 0.25);
                    }
                }

                .multiple_select_image_clickable {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    height: 134px;
                    background-color: #ffffff;

                    .multiple_select_image {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }

                    .multiple_select_image_broken {
                        width: 60px;
                        height: 60px;
                    }

                    .multiple_select_image_preview {
                        position: absolute;
                        top: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        color: #ffffff;
                        opacity: 0;
                        background-color: rgba(0, 0, 0, 0.75);
                        transition: opacity ease 0.3s;

                        &:hover {
                            opacity: 1;
                        }

                        &_text {
                            display: flex;
                            align-items: center;
                            color: #ffffff;
                            border: solid 1px #ffffff;
                            border-radius: 20px;
                            height: 40px;
                            padding: 0 32px;
                        }
                    }
                }

                .multiple_select_card_footer {
                    padding: 8px 16px 8px 16px;
                    color: #333333;
                    line-height: normal;
                    letter-spacing: normal;
                    font-weight: bold;
                    font-size: 14px;
                    min-height: 14px;

                    .multiple_select_card_image_label {
                        cursor: pointer;
                        display: inline-block;
                    }
                }
            }
        }
    }

    .multiple-select-image-info-body {
        display: flex;
        flex-direction: column;
        padding: 0 24px;
        overflow: scroll;

        .image-info-label-container {
            display: flex;
            flex-direction: row;
            margin-top: 44px;
            color: #7f8ea4;

            i {
                padding-top: 1px;
            }

            .image-info-label {
                display: flex;
                flex-direction: column;
                margin-left: 16px;
            }
        }

        .image-info-add_to_selection_button {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-left: 21px;
            padding-right: 6px;
            margin-top: 24px;
            height: 64px;
            border-radius: 4px;
            transition: all ease 0.2s;

            &--unselected {
                border: solid 1px #e6e8ed;
                background-color: #f5f7fb;
            }

            &--selected {
                border: solid 1px #0085f2;
                background-color: rgba(3, 159, 241, 0.12);
            }

            &--disabled {
                border: solid 1px rgba(127, 142, 164, 0.25);
                background-color: #f4f5f9;
                cursor: not-allowed;

                div {
                    color: rgba(127, 142, 164, 0.25);
                }
            }
        }

        .image-info-section {
            margin-top: 24px;

            &-title {
                text-decoration: underline #7f8ea4;
                color: #7f8ea4;
            }

            &-content {
                margin-top: 16px;
                color: #7f8ea4;

                &-info {
                    margin-left: 16px;

                    &-json {
                        display: flex;
                        margin-bottom: 8px;

                        &-label {
                            font-size: 13px;
                            font-weight: bold;
                            color: #333333;
                            width: 100px;
                            min-width: 100px;
                            margin-right: 10px;
                            word-break: break-all;
                        }

                        &-data {
                            font-size: 13px;
                            color: #333333;
                            word-break: break-all;
                        }
                    }

                    &-data {
                        color: #333333;
                        line-break: anywhere;
                    }
                }
            }
        }
    }

    /*---------------------FULL SCREEN VIEW IMAGE-----------------------*/

    .full-screen-image-container {
        display: flex;
        width: 100vw;
        height: 100vh;

        .full-screen-image {
            position: relative;
            z-index: 1;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: #000000;

            img {
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
                object-fit: contain;
            }

            &:hover {
                .full-screen-image-overlay {
                    opacity: 1;
                }
            }

            &-overlay {
                opacity: 0;
                transition: opacity ease 0.2s;

                > div {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100px;
                    height: 100%;
                    top: 0;
                }

                &-left {
                    background-image: linear-gradient(
                        to right,
                        rgba(0, 0, 0, 0.5),
                        rgba(0, 0, 0, 0)
                    );
                    left: 0;
                }

                &-right {
                    background-image: linear-gradient(
                        to left,
                        rgba(0, 0, 0, 0.5),
                        rgba(0, 0, 0, 0)
                    );
                    right: 0;
                }
            }
        }

        .image-info-container {
            display: flex;
            flex-direction: column;
            width: 368px;
            background-color: #ffffff;

            .image-info-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 89px;
                min-height: 89px;
                padding: 0 24px;
                border-bottom: 1px solid #e6e8ed;

                &__icon {
                    img {
                        height: 20px !important;
                        width: 20px !important;
                    }
                }
            }
        }
    }

    /*---------------------Search field input-----------------------*/

    .search-field-input {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding-bottom: 4px;
        border-bottom: 2px solid #e6e8ed;
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);

        &:focus-within {
            border-bottom-color: #0085f2;
        }

        &:hover:not(:focus-within) {
            border-bottom-color: #333333;
        }

        &__lefticon {
            color: #7f8ea4;
            font-size: 16px;
            margin-right: 8px;
        }

        &__righticon {
            color: #7f8ea4;
            font-size: 16px;
            margin-left: 8px;

            &:hover {
                color: #333333;
            }
        }

        &__input {
            flex: 1;

            input {
                width: 100%;
                border: none;
                font-size: 18px;
                font-weight: bold;

                &::placeholder {
                    color: #e6e8ed;
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }
`;
