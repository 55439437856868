import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';

import { useNavigate } from 'react-router-dom';

import { DefaultShortcutComponent } from 'app/pages/Homepage/sections/HomepageShortcuts/DefaultShortcutComponent';
import { JIM_HARDCODED_NAME } from 'app/pages/settingsV2/subPages/Messages/components/Chatbots/ChatbotCard';
import { SETTINGS_CHATBOT_PATH } from 'app/routing/routeIds';

export const ShortcutJim = () => {
    const navigate = useNavigate();

    return (
        <DefaultShortcutComponent
            title={JIM_HARDCODED_NAME}
            variant="slate"
            iconElement={[FontAwesomeIconsPartooUsed.faMessageBot, IconPrefix.SOLID]}
            onClick={() => navigate(SETTINGS_CHATBOT_PATH)}
        />
    );
};
