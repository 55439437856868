import { Filters } from '@partoohub/ui';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
    options: any[];
    disabled?: boolean;
    isMandatory?: boolean;
    onChange?: Function;
};

export const ControlledContentLinkInput = ({
    options,
    disabled = false,
    onChange,
    isMandatory = false,
}: Props) => {
    const { t } = useTranslation();
    const { resetField, control } = useFormContext();

    const {
        field,
        fieldState: { error },
    } = useController({
        name: 'content_link',
        control,
        rules: { required: isMandatory },
        defaultValue: 'no_link',
    });

    const sections = [{ options }];

    const onChangeWithReset = (record: Record<string, boolean>): void => {
        const recordValue = Object.keys(record).find(key => !!record[key]);
        if (!recordValue && isMandatory) {
            return;
        }

        resetField('custom_link', { keepDirty: false });
        onChange?.();
        field.onChange(recordValue);
        field.onBlur();
    };

    return (
        <Filters
            {...field}
            dataTrackId="link"
            position="bottom"
            maxHeight={300}
            menuListArray={sections}
            onChange={onChangeWithReset}
            selectedItems={field.value ? { [field.value]: true } : {}}
            placeholder={t('link')}
            disabled={disabled}
            disableReset
            required={isMandatory}
            error={!!error}
            notice={error ? t('required_field') : undefined}
        />
    );
};
