import { format, startOfDay } from 'date-fns';

import { ReviewObjectType } from 'app/api/types/review';
import {
    CEPages,
    useCanAccessCustomerExperiencePage,
} from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';

import { ReviewsQueryKeys } from 'app/reviewManagement/reviewList/hooks/useGetReviewsFilters';
import { CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH } from 'app/routing/routeIds';
import { NOT_TREATED_REVIEWS } from 'app/states/reviews/filters';

import { useTodoReviewsData } from './useTodoReviewsData';

export const useTodoReviewsNavigationPath = (): string => {
    const sixMonthsAgo = startOfDay(new Date(new Date().setMonth(new Date().getMonth() - 6)));
    const today = startOfDay(new Date());

    const { data: dataReviews } = useTodoReviewsData();

    const { canAccessCustomerExperiencePage } = useCanAccessCustomerExperiencePage();

    const queryParams = new URLSearchParams({
        [ReviewsQueryKeys.DATE_FROM]: format(sixMonthsAgo, 'yyyy-MM-dd'),
        [ReviewsQueryKeys.DATE_TO]: format(today, 'yyyy-MM-dd'),
    });

    if (dataReviews?.count) {
        queryParams.append(ReviewsQueryKeys.STATUS, NOT_TREATED_REVIEWS);
    }

    if (canAccessCustomerExperiencePage[CEPages.FBM]) {
        queryParams.set(ReviewsQueryKeys.REVIEW_OBJECT, ReviewObjectType.REVIEW);
    }

    return `${CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH}?${queryParams.toString()}`;
};
