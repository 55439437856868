import { useMemo } from 'react';

import { uniqBy } from 'lodash-es';
import { useInfiniteQuery } from 'react-query';

import { useDispatch } from 'react-redux';

import { SearchBusinessesReceivedData } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import { BUSINESSES } from 'app/common/data/queryKeysConstants';
import { UNGROUPED_ID } from 'app/common/reducers/groups';
import { forceUpdateBusinesses } from 'app/common/reducers/newBusinesses';
import byIdFormatter from 'app/common/services/byIdFormatter';
import { useBusinessSearchQueryParams } from 'app/pages/visibility/BusinessListV2/hooks/queryParams/useBusinessSearchQueryParams';

export default function useBusinessSearch(updateStore = false) {
    const query = useBusinessSearchQueryParams() || {};
    const dispatch = useDispatch();

    const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(
        [BUSINESSES, { query }],
        ({ pageParam = 1 }) =>
            api.business.searchBusinesses({
                ...query,
                page: pageParam,
            }),
        {
            getNextPageParam: (lastPage: SearchBusinessesReceivedData) => {
                // Sync to redux for the groups business modal. Please kill with fire with groups
                if (updateStore) {
                    const { byId, ids } = byIdFormatter(lastPage.businesses, obj => ({
                        ...obj,
                        groupId: obj.groupId === null ? UNGROUPED_ID : obj.groupId, // Bad but necessary to make it work like before
                    }));

                    // Hack to make it not block the render...
                    setTimeout(() => {
                        dispatch(forceUpdateBusinesses(byId, ids));
                    }, 1);
                }

                // Return undefined to indicate last page
                return lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined;
            },
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );

    const businesses = useMemo(
        () => uniqBy(data?.pages?.flatMap(page => page.businesses) ?? [], business => business.id),
        [data],
    );

    return {
        data,
        businesses,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetchingNextPage,
        count: data?.pages?.[0]?.count,
    };
}
