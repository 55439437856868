import { useState } from 'react';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { PUBLISHER_CONNECTION_REQUIRED, PUBLISHER_SCOPE_MISSING } from 'app/api/types/post';
import api from 'app/api/v2/api_calls';
import { POST_STATE } from 'app/common/data/queryKeysConstants';
import PartnerStatus from 'app/common/designSystem/components/molecules/GooglePostCard/PartnerStatus';
import {
    PostCardFooter,
    PostContainer,
} from 'app/common/designSystem/components/molecules/GooglePostCard/PostCard.styled';
import PostCardCover from 'app/common/designSystem/components/molecules/GooglePostCard/PostCardCover';
import PostCardMain from 'app/common/designSystem/components/molecules/GooglePostCard/PostCardMain';
import {
    getPartnerConnectionLink,
    openPartnerScopesModal,
} from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/screens/TypePlatform/ConnectionBanner';

type TopicTypeEnum = 'news' | 'event' | 'offer';

type Props = {
    actionCallbacks: Record<string, (arg0: string) => void>;
    businessInfo: string;
    dates?: string;
    disabledValues: Array<string>;
    photoUrl?: string;
    postId?: string;
    postBulkId?: number;
    postInsight?: Array<Record<string, string | number>>;
    postStatus?: Array<Record<string, string>>;
    searchUrl: string;
    summary?: string;
    title?: string;
    topicType: TopicTypeEnum;
    businessId: string;
};

const mapStatusToSidebarState = {
    rejected: 'to_configure',
    live: 'success',
    processing: 'pending',
    pending: 'pending',
    scheduled: 'scheduled',
    to_configure: 'to_configure',
    expired: 'expired',
    error: 'error',
};

export const PUBLISHER_GOOGLE = 'google_my_business__posts';
export const PUBLISHER_FACEBOOK = 'facebook__posts';
export const PUBLISHER_INSTAGRAM = 'instagram__posts';
export const ALL_PUBLISHERS = [PUBLISHER_GOOGLE, PUBLISHER_INSTAGRAM, PUBLISHER_FACEBOOK] as const;

export const PUBLISHERS_WITH_INSIGHTS = [PUBLISHER_FACEBOOK, PUBLISHER_INSTAGRAM];

const mapPlatformToIcon = {
    [PUBLISHER_GOOGLE]: 'google_white_background',
    [PUBLISHER_FACEBOOK]: 'facebook',
    [PUBLISHER_INSTAGRAM]: 'instagram_logo',
};

const PostCard = ({
    actionCallbacks,
    businessInfo,
    dates,
    disabledValues,
    photoUrl,
    postId,
    postBulkId,
    postInsight = [],
    postStatus = [],
    searchUrl,
    summary,
    title,
    topicType,
    businessId,
}: Props) => {
    const navigate = useNavigate();
    const isExpired = postStatus.some(item => item.state === 'expired');
    const [enabledFetchState, setEnabledFetchState] = useState(false);

    const { data: postState } = useQuery(
        [POST_STATE, postId],
        async () => {
            if (!postId) return;
            return api.post.getPostState(postId);
        },
        {
            enabled: !!postId && enabledFetchState,
        },
    );

    const publisherList = ALL_PUBLISHERS.map(publisherName => {
        const index: number = postStatus.findIndex(({ name }) => publisherName === name);

        if (index > -1) {
            const { state } = postStatus[index];
            const mappedState = mapStatusToSidebarState[state];
            // Get relative insight data
            const insightIndex = postInsight.findIndex(({ name }) => publisherName === name);
            const insight = insightIndex > -1 ? postInsight[insightIndex] : {};

            return {
                name: publisherName,
                status: mappedState,
                icon: mapPlatformToIcon[publisherName],
                isActivated: true,
                showInsights: PUBLISHERS_WITH_INSIGHTS.includes(publisherName),
                partner: publisherName,
                ...insight,
            };
        } else {
            return {
                status: '',
                partner: publisherName,
                icon: mapPlatformToIcon[publisherName],
                isActivated: false,
            };
        }
    });

    const hasToConfigure = publisherList.some(item => item.status === 'to_configure');

    const onPublisherStatusClick = (publisher: (typeof ALL_PUBLISHERS)[number]) => async () => {
        if (!postState) return;
        const expectedError = postState[publisher].expected_error_message;

        if (expectedError === PUBLISHER_CONNECTION_REQUIRED) {
            navigate(getPartnerConnectionLink(`included=${businessId}`));
        } else if (expectedError === PUBLISHER_SCOPE_MISSING) {
            openPartnerScopesModal(publisher === PUBLISHER_GOOGLE ? 'google' : 'facebook');
        }
    };

    return (
        <PostContainer>
            <PostCardCover
                postId={postId}
                postBulkId={postBulkId}
                topicType={topicType}
                dates={dates}
                title={title}
                photoUrl={photoUrl}
                onClick={!disabledValues.includes('edit') ? actionCallbacks.edit : () => undefined}
                disableClickable={
                    disabledValues.includes('edit') &&
                    postStatus.every(status => status.state !== 'live')
                    // Use live status to make the photo cover appear enabled for instagram only posts as they can't be edited
                }
                onMenuClick={postId ? value => actionCallbacks[value](searchUrl) : () => undefined}
                disabledValues={disabledValues}
                isExpired={isExpired}
                hasAlsoInstagram={
                    postStatus.length > 1 &&
                    postStatus.findIndex(({ name }) => PUBLISHER_INSTAGRAM === name) !== -1
                }
                canEditInstagram={postStatus.some(
                    ({ name, state }) => PUBLISHER_INSTAGRAM === name && state != 'live',
                )}
            />
            <PostCardMain businessInfo={businessInfo} summary={summary} isExpired={isExpired} />
            <PostCardFooter
                onMouseEnter={
                    hasToConfigure && !enabledFetchState
                        ? () => setEnabledFetchState(true)
                        : undefined
                }
            >
                {publisherList.map(item => (
                    <PartnerStatus
                        key={`partner-status-${item.icon}-${postId}`}
                        status={item.status}
                        showInsights={item.showInsights || false}
                        count={item.viewCount || 0}
                        clickCount={item.clickCount || 0}
                        partnerIcon={item.icon}
                        disabled={!item.isActivated}
                        onPartnerStatusClick={
                            postState && 'name' in item
                                ? onPublisherStatusClick(item.name)
                                : undefined
                        }
                        partner={item.partner}
                    />
                ))}
            </PostCardFooter>
        </PostContainer>
    );
};

export default PostCard;
