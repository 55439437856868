import { FooterButton } from './FunnelModalFooterButton.styled';

type Props = {
    onClick: () => void;
    disabled: boolean;
    buttonText: string;
    icon: Array<string>;
    iconPosition: string;
    isLoading: boolean;
};

export const FunnelModalFooterButton = ({
    onClick,
    disabled,
    buttonText = '',
    icon,
    iconPosition = 'left',
    isLoading = false,
}: Props) => {
    return (
        <FooterButton
            onClick={onClick}
            disabled={disabled}
            isLoading={isLoading}
            shape="cube"
            size="large"
            variant="primary"
            appearance="contained"
            dataTrackId={''}
            icon={icon}
            iconPosition={iconPosition}
        >
            {buttonText}
        </FooterButton>
    );
};
