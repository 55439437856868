import { endOfDay, isValid, startOfDay } from 'date-fns';
import * as history from 'history';
import { matchPath as _matchPath, useLocation } from 'react-router-dom';

import { STATUSES } from 'app/common/data/businessStatuses';
import { AppProduct } from 'app/common/data/productIds';
import {
    BUSINESSES_CITIES,
    BUSINESSES_GROUPS,
    BUSINESSES_ORGS,
    BUSINESSES_QUERIES,
    BUSINESSES_SELECT_ALL,
    BUSINESS_LIST_PRODUCTS,
    BUSINESS_LIST_STATUS,
    EXCLUDED_BUSINESSES,
    INCLUDED_BUSINESSES,
    POSTS_DATE_FROM,
    POSTS_DATE_TO,
    POSTS_TOPICS,
    POST_PLATFORMS,
    REVIEWS_COMMENTS,
    REVIEWS_DATE_FROM,
    REVIEWS_DATE_TO,
    REVIEWS_KEYWORDS,
    REVIEWS_PLATFORMS,
    REVIEWS_RATING,
    REVIEWS_TAG,
    REVIEW_ANALYTICS_DATE_FROM,
    REVIEW_ANALYTICS_DATE_TO,
    REVIEW_ANALYTICS_KEYWORDS,
    REVIEW_ANALYTICS_TAGS,
} from 'app/common/data/routeIds';
import { getSearchParam } from 'app/common/hooks/useSearchParam';
import {
    ANALYTICS_REVIEW_PATH,
    CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH,
    POSTS_PATH,
    VISIBILITY_LOCATION_PATH,
} from 'app/routing/routeIds';
import {
    parseDateFns,
    parseNumberArray,
    parseStringArray,
    stringifyDateFns,
    stringifyStringArray,
} from 'app/utils/queryString';

/** TEMPORARY SHIM
 *  Currently, vitejs doesn't grab react-router 5 but react-router 6 when running this file.
 *  This is not normal because the closest node_modules in parent folders in the one from
 *  app containing react-router 5. In react-router 6 matchPath doesn't accept '*' as pattern
 *  to match all routes, so we implement the feature here.
 *  */
const matchPath = (pathName: string, pattern: string): boolean => {
    if (pattern === '*') {
        return true;
    }
    return !!_matchPath(pathName, pattern);
};

interface ParamFunctions<T = any> {
    parse: (value: string) => T;
    stringify: (value: T) => string;
}

export const useParseFunction = (paramName: string) =>
    getParamFunctions(useLocation().pathname, paramName).parse;

export const useParsedSearchParam = (paramName: string) =>
    getParsedSearchParam(useLocation(), useLocation().pathname, paramName);

export const useStringifyFunction = (paramName: string) =>
    getParamFunctions(useLocation().pathname, paramName).stringify;

export const useAvailableSearchParams = () => getAvailableSearchParams(useLocation());

/** Returns an object of the available search params for the given location */
const getAvailableSearchParams = (
    location: history.Location | Location,
): Record<string, string> => {
    const available = {};
    for (const path of Object.keys(paramsData)) {
        if (matchPath(location.pathname, path)) {
            for (const p of Object.keys(paramsData[path])) {
                available[p] = getSearchParam(location.search, p);
            }
        }
    }
    return available;
};

const reviewsParsedSearchParam = {
    [REVIEWS_DATE_FROM]: {
        parse: parseDateFns,
        stringify: stringifyDateFns,
    },
    [REVIEWS_DATE_TO]: {
        parse: d => (isValid(parseDateFns(d)) ? endOfDay(parseDateFns(d)) : null),
        stringify: stringifyDateFns,
    },
    [REVIEWS_PLATFORMS]: {
        parse: value => {
            const arr = parseStringArray(value);
            return [
                arr.includes('google_my_business') && 'google_my_business',
                arr.includes('facebook') && 'facebook',
                arr.includes('tripadvisor') && 'tripadvisor',
            ].filter(Boolean);
        },
        stringify: value =>
            stringifyStringArray(
                [
                    value.includes('google_my_business') && 'google_my_business',
                    value.includes('facebook') && 'facebook',
                    value.includes('tripadvisor') && 'tripadvisor',
                ].filter(Boolean),
            ),
    },
    [REVIEWS_RATING]: {
        parse: parseStringArray,
        stringify: stringifyStringArray,
    },
    [REVIEWS_COMMENTS]: {
        parse: value => {
            const arr = parseStringArray(value);
            return [
                arr.includes('with') && 'with_message',
                arr.includes('without') && 'without_message',
            ].filter(Boolean);
        },
        stringify: value =>
            stringifyStringArray(
                [
                    value.includes('with_message') && 'with',
                    value.includes('without_message') && 'without',
                ].filter(Boolean),
            ),
    },
    [REVIEWS_KEYWORDS]: {
        parse: parseStringArray,
        stringify: stringifyStringArray,
    },
    [REVIEWS_TAG]: {
        parse: parseNumberArray,
        stringify: stringifyStringArray,
    },
};

/**
 * Imperative helper (ie. to be used only in sagas or global scope).
 * @param location current location
 * @param pathName path associated with the search param (matched against location.pathname using matchPath)
 * @param paramName
 */
export const getParsedSearchParam = (
    location: history.Location | Location,
    pathName: string,
    paramName: string,
) => {
    const match = matchPath(location.pathname, pathName);
    // if we're not on the right path associated with the search param, its value is ignored
    const paramValue = match ? getSearchParam(location.search, paramName) : '';
    return getParamFunctions(pathName, paramName).parse(paramValue);
};

/** Collection of parsing and stringifying functions to convert url search
 *  params values into the store values and vice versa.
 *
 *  Each search param is under a url path. It should be parsed only if
 *  that given url path matches the current path (using matchPath from
 *  react-router v5 so regexes are accepted too).
 *
 *  This is an opaque structure (ie. not exported), only the helpers defined
 *  above should be used outside of this file.
 *
 *  TODO: remove these when transitioned out of Redux
 */
const paramsData: Record<string, Record<string, ParamFunctions>> = {
    '*': {
        // business modal
        [BUSINESSES_SELECT_ALL]: {
            parse: value => (value === 'true' ? 'unselect' : 'select'),
            stringify: value => (value === 'select' ? '' : 'true'),
        },
        [INCLUDED_BUSINESSES]: {
            parse: parseStringArray,
            stringify: stringifyStringArray,
        },
        [EXCLUDED_BUSINESSES]: {
            parse: parseStringArray,
            stringify: stringifyStringArray,
        },
        [BUSINESSES_QUERIES]: {
            parse: parseStringArray,
            stringify: stringifyStringArray,
        },
        [BUSINESSES_CITIES]: {
            parse: parseStringArray,
            stringify: stringifyStringArray,
        },
        [BUSINESSES_GROUPS]: {
            parse: parseNumberArray,
            stringify: stringifyStringArray,
        },
        [BUSINESSES_ORGS]: {
            parse: parseNumberArray,
            stringify: stringifyStringArray,
        },
    },

    // business list
    [VISIBILITY_LOCATION_PATH]: {
        [BUSINESS_LIST_STATUS]: {
            parse: values => parseStringArray(values).filter(value => STATUSES.includes(value)),
            stringify: stringifyStringArray,
        },
        [BUSINESS_LIST_PRODUCTS]: {
            parse: value => {
                const arr = parseStringArray(value);
                return [
                    arr.includes('presence') && 'presence_management',
                    arr.includes('review') && 'review_management',
                    arr.includes('review-booster') && 'review_booster',
                ].filter(Boolean) as Array<AppProduct>;
            },
            stringify: value =>
                [
                    value.includes('presence_management') && 'presence',
                    value.includes('review_management') && 'review',
                    value.includes('review_booster') && 'review-booster',
                ]
                    .filter(Boolean)
                    .join(','),
        },
    },

    // review analytics
    [ANALYTICS_REVIEW_PATH]: {
        [REVIEW_ANALYTICS_DATE_FROM]: {
            parse: d => {
                const parsedDate = parseDateFns(d || '');
                return parsedDate ? startOfDay(parsedDate) : null;
            },
            stringify: stringifyDateFns,
        },
        [REVIEW_ANALYTICS_DATE_TO]: {
            parse: d => {
                const parsedDate = parseDateFns(d || '');
                return parsedDate ? endOfDay(parsedDate) : null;
            },
            stringify: stringifyDateFns,
        },
        [REVIEW_ANALYTICS_KEYWORDS]: {
            parse: parseStringArray,
            stringify: stringifyStringArray,
        },
        [REVIEW_ANALYTICS_TAGS]: {
            parse: parseNumberArray,
            stringify: stringifyStringArray,
        },
    },

    // posts
    [POSTS_PATH]: {
        [POSTS_DATE_FROM]: {
            parse: parseDateFns,
            stringify: stringifyDateFns,
        },
        [POSTS_DATE_TO]: {
            parse: d => (isValid(parseDateFns(d)) ? endOfDay(parseDateFns(d)) : null),
            stringify: stringifyDateFns,
        },
        [POSTS_TOPICS]: {
            parse: value => {
                const arr = parseStringArray(value);
                return {
                    news: arr.includes('news'),
                    event: arr.includes('event'),
                    offer: arr.includes('offer'),
                    covid: arr.includes('covid'),
                };
            },
            stringify: value =>
                Object.entries(value)
                    .filter(([, v]) => !!v)
                    .map(([k]) => k)
                    .join(','),
        },
        [POST_PLATFORMS]: {
            parse: value => new Set(parseStringArray(value)),
            stringify: platformSet => Array.from(platformSet).join(','),
        },
    },

    // review list
    [CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH]: reviewsParsedSearchParam,
};

const getParamFunctions = (pathName: string, paramName: string): ParamFunctions => {
    for (const path of Object.keys(paramsData)) {
        if (matchPath(pathName, path)) {
            for (const p of Object.keys(paramsData[path])) {
                if (p === paramName) {
                    return paramsData[path][p];
                }
            }
        }
    }
    throw new Error(
        `Search parameter ${paramName} doesn't exist on path ${pathName}. ` +
            `Probably, some code under ${pathName} tries to parse ${paramName} ` +
            'but no parser was configured for that url. Try to add parse and ' +
            'stringify functions in routeIds.parser.ts, or make sure this ' +
            'function is not called on that page.',
    );
};
