import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { CONVERSATION_STARTERS } from 'app/common/data/queryKeysConstants';

export default function useActivateConversationStarter(conversationStarterId: string) {
    const queryClient = useQueryClient();
    return useMutation(
        (enabled: boolean) => {
            return api.conversationStarters.activateConversationStarter(
                conversationStarterId,
                enabled,
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(invalidatesAny(CONVERSATION_STARTERS));
            },
        },
    );
}
