import { ReviewObjectType } from 'app/api/types/review';

import { CEPermissions, useHasCEPermissions } from './settings/useHasCEPermissions';

export const useCanReplyClientReviews = (): {
    canReplyClientReviews: Record<ReviewObjectType, boolean | undefined>;
    isLoading: boolean;
} => {
    const { permissions, isLoading: permissionsLoading } = useHasCEPermissions();

    return {
        canReplyClientReviews: {
            [ReviewObjectType.REVIEW]: permissions[CEPermissions.RMReply] ?? false,
            [ReviewObjectType.FEEDBACK_RESULT]: permissions[CEPermissions.FBMReply] ?? false,
        },
        isLoading: permissionsLoading,
    };
};
