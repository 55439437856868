// @ts-nocheck
import {
    BusinessFieldArrayType,
    BusinessFieldType,
    CustomFieldsCreationPayload,
    CustomFieldsEditPayload,
    CustomFieldsSectionsGetData,
} from 'app/api/types/business';
import i18n from 'app/common/translations/i18n';
import {
    CUSTOM_FIELD_SECTION_TYPE,
    CUSTOM_FIELD_TYPE,
    CustomFieldListType,
    CustomFieldOrSectionType,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/typing';

export const customFieldsType = {
    TEXT: 'TEXT',
    BOOLEAN: 'BOOLEAN',
    NUMBER: 'NUMBER',
    INTEGER: 'INTEGER',
    FLOAT: 'FLOAT',
    SELECT: 'SELECT',
    SINGLE_SELECT: 'SINGLE_SELECT',
    MULTIPLE_SELECT: 'MULTIPLE_SELECT',
    MULTIPLE_SELECT_IMAGE: 'MULTIPLE_SELECT_IMAGE',
    IMAGES_UPLOADER: 'IMAGES_UPLOADER',
};

export const getIconName = (type?: string | null): string => {
    switch (type) {
        case customFieldsType.TEXT:
            return 'icon_text.svg';

        case customFieldsType.BOOLEAN:
            return 'icon_boolean.svg';

        case customFieldsType.INTEGER:
        case customFieldsType.FLOAT:
        case customFieldsType.NUMBER:
            return 'icon_number.svg';

        case customFieldsType.SINGLE_SELECT:
        case customFieldsType.MULTIPLE_SELECT:
        case customFieldsType.SELECT:
            return 'icon_select.svg';

        case customFieldsType.MULTIPLE_SELECT_IMAGE:
            return 'icon_images.svg';

        case customFieldsType.IMAGES_UPLOADER:
            return 'icon_uploader.svg';

        default:
            return 'icon_text.svg';
    }
};

export const getTypeTranslation = (type?: string | null): string => {
    switch (type) {
        case customFieldsType.TEXT:
            return i18n.t('text');

        case customFieldsType.BOOLEAN:
            return `${i18n.t('yes')} / ${i18n.t('no')}`;

        case customFieldsType.INTEGER:
            return i18n.t('integer');

        case customFieldsType.FLOAT:
            return i18n.t('float');

        case customFieldsType.SINGLE_SELECT:
            return i18n.t('single_select');

        case customFieldsType.MULTIPLE_SELECT:
            return i18n.t('multiple_select');

        case customFieldsType.MULTIPLE_SELECT_IMAGE:
            return i18n.t('multiple_select_image');

        case customFieldsType.NUMBER:
            return i18n.t('number');

        case customFieldsType.SELECT:
            return i18n.t('custom_fields_type_select');

        case customFieldsType.IMAGES_UPLOADER:
            return i18n.t('custom_fields_type_images_uploader');

        default:
            return i18n.t('custom_fields');
    }
};

export const getDescriptionTranslation = (type?: string | null): string => {
    switch (type) {
        case customFieldsType.TEXT:
            return i18n.t('custom_fields_description_text');

        case customFieldsType.BOOLEAN:
            return i18n.t('custom_fields_description_boolean');

        case customFieldsType.INTEGER:
        case customFieldsType.FLOAT:
        case customFieldsType.NUMBER:
            return i18n.t('custom_fields_description_number');

        case customFieldsType.SINGLE_SELECT:
        case customFieldsType.MULTIPLE_SELECT:
        case customFieldsType.SELECT:
            return i18n.t('custom_fields_description_select');

        case customFieldsType.MULTIPLE_SELECT_IMAGE:
            return i18n.t('custom_fields_description_select_image');

        case customFieldsType.IMAGES_UPLOADER:
            return i18n.t('custom_fields_description_images_uploader');

        default:
            return 'icon_text.svg';
    }
};

export const getEditWarningModalCheckboxTranslation = (type: string): string => {
    switch (type) {
        case customFieldsType.TEXT:
        case customFieldsType.INTEGER:
        case customFieldsType.FLOAT:
            return 'custom_fields_edition_confirmation_checkbox_delete';

        case customFieldsType.SINGLE_SELECT:
        case customFieldsType.MULTIPLE_SELECT:
        case customFieldsType.MULTIPLE_SELECT_IMAGE:
        case customFieldsType.IMAGES_UPLOADER:
            return 'custom_fields_edition_confirmation_checkbox_edit';

        default:
            return '';
    }
};

export const getEditWarningModalDescriptionTranslation = (type: string): string => {
    switch (type) {
        case customFieldsType.TEXT:
        case customFieldsType.INTEGER:
        case customFieldsType.FLOAT:
        case customFieldsType.IMAGES_UPLOADER:
            return 'custom_fields_edition_warning_description_delete';

        case customFieldsType.SINGLE_SELECT:
        case customFieldsType.MULTIPLE_SELECT:
        case customFieldsType.MULTIPLE_SELECT_IMAGE:
            return 'custom_fields_edition_warning_description_edit';

        default:
            return '';
    }
};

export const formatPayloadCreation = (
    payload: Record<string, any>,
): CustomFieldsCreationPayload => {
    switch (payload.type) {
        case customFieldsType.TEXT:
            return {
                ...payload,
                max_len: Number.isNaN(parseInt(payload.max_len, 10))
                    ? null
                    : parseInt(payload.max_len, 10),
            };

        case customFieldsType.BOOLEAN:
            return payload;

        case customFieldsType.INTEGER:
            return {
                ...payload,
                min: Number.isNaN(parseInt(payload.min, 10)) ? null : parseInt(payload.min, 10),
                max: Number.isNaN(parseInt(payload.max, 10)) ? null : parseInt(payload.max, 10),
            };

        case customFieldsType.FLOAT:
            return {
                ...payload,
                min: Number.isNaN(parseFloat(payload.min)) ? null : parseFloat(payload.min),
                max: Number.isNaN(parseFloat(payload.max)) ? null : parseFloat(payload.max),
            };

        case customFieldsType.SINGLE_SELECT:
        case customFieldsType.MULTIPLE_SELECT:
            return {
                ...payload,
                possible_values: payload.possible_values ? payload.possible_values : [],
            };

        case customFieldsType.MULTIPLE_SELECT_IMAGE:
            return {
                ...payload,
                possible_values: payload.possible_values ? payload.possible_values : [],
                possible_images_urls: payload.possible_images_urls
                    ? payload.possible_images_urls
                    : [],
                possible_images_labels: payload.possible_images_labels
                    ? payload.possible_images_labels.map(label => (label === '' ? null : label))
                    : [],
            };

        case customFieldsType.IMAGES_UPLOADER:
            return {
                ...payload,
                text_fields: payload.text_fields ?? [],
                max_lengths: payload.max_lengths ?? [],
            };

        default:
            return payload;
    }
};

export const formatPayloadEdition = (payload: Record<string, any>): CustomFieldsEditPayload => {
    const corePayload = {
        id: payload.id,
        name: payload.name,
        type: payload.type,
        order: payload.order,
        forced_update: payload.forced_update,
        section_id: payload.section_id,
    };

    switch (payload.type) {
        case customFieldsType.TEXT:
            return {
                ...corePayload,
                max_len: Number.isNaN(parseInt(payload.max_len, 10))
                    ? null
                    : parseInt(payload.max_len, 10),
            };

        case customFieldsType.BOOLEAN:
            return corePayload;

        case customFieldsType.INTEGER:
            return {
                ...corePayload,
                min: Number.isNaN(parseInt(payload.min, 10)) ? null : parseInt(payload.min, 10),
                max: Number.isNaN(parseInt(payload.max, 10)) ? null : parseInt(payload.max, 10),
            };

        case customFieldsType.FLOAT:
            return {
                ...payload,
                min: Number.isNaN(parseFloat(payload.min)) ? null : parseFloat(payload.min),
                max: Number.isNaN(parseFloat(payload.max)) ? null : parseFloat(payload.max),
            };

        case customFieldsType.SINGLE_SELECT:
        case customFieldsType.MULTIPLE_SELECT:
            return {
                ...corePayload,
                created_values:
                    payload.editValues === undefined
                        ? []
                        : payload.editValues
                              .filter(obj => obj.oldValue === null)
                              .map(obj => obj.newValue),
                deleted_values:
                    payload.editValues === undefined
                        ? []
                        : payload.editValues
                              .filter(obj => obj.newValue === null)
                              .map(obj => obj.oldValue),
                renamed_values:
                    payload.editValues === undefined
                        ? []
                        : payload.editValues
                              .filter(
                                  obj =>
                                      obj.oldValue !== null &&
                                      obj.newValue !== null &&
                                      obj.newValue !== obj.oldValue,
                              )
                              .map(obj => ({
                                  old_value: obj.oldValue,
                                  new_value: obj.newValue,
                              })),
            };

        case customFieldsType.MULTIPLE_SELECT_IMAGE:
            return {
                ...corePayload,
                created_values:
                    payload.editValues === undefined
                        ? []
                        : payload.editValues
                              .filter(obj => obj.oldValues === null)
                              .map(obj => obj.newValues),
                deleted_values:
                    payload.editValues === undefined
                        ? []
                        : payload.editValues
                              .filter(obj => obj.newValues === null)
                              .map(obj => obj.oldValues.value),
                edited_values:
                    payload.editValues === undefined
                        ? []
                        : payload.editValues
                              .filter(
                                  obj =>
                                      obj.oldValues !== null &&
                                      obj.newValues !== null &&
                                      (obj.newValues.label !== obj.oldValues.label ||
                                          obj.newValues.url !== obj.oldValues.url ||
                                          obj.newValues.value !== obj.oldValues.value),
                              )
                              .map(obj => ({
                                  ...obj.newValues,
                                  old_value: obj.oldValues.value,
                              })),
            };

        case customFieldsType.IMAGES_UPLOADER:
            return {
                ...corePayload,
                created_values:
                    payload.editValues === undefined
                        ? []
                        : payload.editValues
                              .filter(obj => obj.oldValue === null)
                              .map(obj => obj.newValue),
                deleted_values:
                    payload.editValues === undefined
                        ? []
                        : payload.editValues
                              .filter(obj => obj.newValue === null)
                              .map(obj => obj.oldValue.text_field),
                edited_values:
                    payload.editValues === undefined
                        ? []
                        : payload.editValues
                              .filter(
                                  obj =>
                                      obj.oldValue !== null &&
                                      obj.newValue !== null &&
                                      (obj.newValue.text_field !== obj.oldValue.text_field ||
                                          obj.newValue.max_length !== obj.oldValue.max_length),
                              )
                              .map(obj => ({
                                  old_value: obj.oldValue,
                                  new_value: obj.newValue,
                              })),
            };

        default:
            return payload;
    }
};

export const canCreateCustomFields = (params: Record<string, any>) => {
    switch (params.type) {
        case customFieldsType.TEXT:
        case customFieldsType.BOOLEAN:
        case customFieldsType.INTEGER:
        case customFieldsType.FLOAT:
            return true;

        case customFieldsType.SINGLE_SELECT:
        case customFieldsType.MULTIPLE_SELECT:
            if (!params.possible_values || params.possible_values.length === 0) return false;
            return !params.possible_values.includes('');

        case customFieldsType.MULTIPLE_SELECT_IMAGE:
            if (!params.possible_images_urls || params.possible_images_urls.length === 0)
                return false;
            return !(
                params.possible_images_urls.includes('') || params.possible_values.includes('')
            );

        case customFieldsType.IMAGES_UPLOADER:
            if (params.text_fields === undefined && params.max_lengths === undefined) {
                return true;
            }
            if (params.text_fields.length !== params.max_lengths.length) {
                return false;
            }
            return !params.text_fields.some(text => !text);

        default:
            return false;
    }
};

export const canEditCustomFields = (params: Record<string, any>) => {
    let values = [];
    let urls = [];

    switch (params.type) {
        case customFieldsType.TEXT:
        case customFieldsType.BOOLEAN:
        case customFieldsType.INTEGER:
        case customFieldsType.FLOAT:
            return true;

        case customFieldsType.SINGLE_SELECT:
        case customFieldsType.MULTIPLE_SELECT:
            if (params.editValues === undefined) return false;
            values = params.editValues
                .filter(obj => obj.newValue !== null)
                .map(obj => obj.newValue);
            if (values.length === 0) return false;
            return !values.includes('');

        case customFieldsType.MULTIPLE_SELECT_IMAGE:
            if (params.editValues === undefined) return false;
            values = params.editValues
                .filter(obj => obj.newValues !== null)
                .map(obj => obj.newValues.value);
            urls = params.editValues
                .filter(obj => obj.newValues !== null)
                .map(obj => obj.newValues.url);
            if (values.length === 0) return false;
            return !values.includes('') && !urls.includes('');

        case customFieldsType.IMAGES_UPLOADER:
            if (params.editValues === undefined) return false;
            const textFields = params.editValues
                .filter(obj => obj.newValue !== null)
                .map(obj => obj.newValue.text_field);
            return !textFields.includes('');

        default:
            return false;
    }
};

export const isJsonParsable = (string: string) => {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }

    return true;
};

export const getCleanData = (
    customFields: BusinessFieldArrayType,
    customFieldsSections?: CustomFieldsSectionsGetData,
): CustomFieldListType => {
    const cleanCFData = {};
    function compareOrder(a: CustomFieldOrSectionType, b: CustomFieldOrSectionType) {
        return a.obj.order - b.obj.order;
    }

    function compareCustomFieldsOrder(a: BusinessFieldType, b: BusinessFieldType) {
        return a.order - b.order;
    }

    customFieldsSections?.sections.forEach(section => {
        cleanCFData['section_' + section?.id] = {
            dndId: 'section_' + section?.id,
            type: CUSTOM_FIELD_SECTION_TYPE,
            obj: section,
            customFields: [],
        };
    });

    customFields.forEach(field => {
        if (field?.section_id) {
            // Add the custom field to its section
            cleanCFData['section_' + field?.section_id]?.customFields.push({
                dndId: 'cf_' + field?.id,
                ...field,
            });
        } else {
            // Add the custom field at root level
            cleanCFData['cf_' + field?.id] = {
                dndId: 'cf_' + field?.id,
                type: CUSTOM_FIELD_TYPE,
                obj: field,
            };
        }
    });

    // Sort in sections
    for (const [key] of Object.entries(cleanCFData)) {
        if (cleanCFData[key].type === CUSTOM_FIELD_SECTION_TYPE) {
            cleanCFData[key].customFields.sort(compareCustomFieldsOrder);
        }
    }

    // Sort at root level, cast to array and return
    return Object.values(cleanCFData).sort(compareOrder);
};
