import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import { Stack } from '@partoohub/ui';

export const ApiKeyLoadingContainer = styled(Stack)`
    margin: 16px 0px;
    height: 80px;
    background-color: ${({ theme }) => theme.colors.theme.initial};
`;

export const ApiKeyCardLoadingContent = styled.div`
    width: 100%;
    border: solid 1px ${({ theme }) => theme.colors.default.alpha};
    border-left: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;

export const ApiKeyCardMarkup = styled.div`
    border-right: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 5px;
    min-width: 5px;
    background-color: ${greyColorObject.dark};
`;
