import { useEffect, useState } from 'react';

import { Expandable, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import {
    StyledExpandableToggle,
    StyledGraphSelector,
    StyledSectionText,
    StyledSelectableCard,
} from './GraphSelectorSection.styled';
import { ReviewAnalyticsGraphType, useGraphSelection } from '../../hooks/useGraphSelection';

export const GraphSelectorSection = () => {
    const { t } = useTranslation();
    const [selectedGraph, setSelectedGraph] = useGraphSelection();
    const [openedSections, setOpenedSections] = useState<Array<string>>([]);

    const sections: Array<{ name: string; graphs: Array<ReviewAnalyticsGraphType> }> = [
        {
            name: 'ereputation',
            graphs: ['average_rating', 'review_count', 'rating_distribution'],
        },
        {
            name: 'review_reply',
            graphs: ['reply_time', 'reply_means'],
        },
        {
            name: 'tags',
            graphs: ['tags'],
        },
    ];

    useEffect(() => {
        // After selecting a graph, make sure its section is opened
        if (!selectedGraph) {
            setSelectedGraph('average_rating');
        }

        const selectedSection = sections.find(sect => sect.graphs.includes(selectedGraph))?.name;

        if (selectedSection && !openedSections.find(opened => opened === selectedSection)) {
            setOpenedSections([...openedSections, selectedSection]);
        }
    }, [selectedGraph]);

    useEffect(() => {
        // On first render, set graph to average rating if the requested graph does not exist
        if (!sections.flatMap(section => section.graphs).includes(selectedGraph)) {
            setSelectedGraph('average_rating');
        }
    }, []);

    const toggleSection = (section: string) => {
        const idx = openedSections.findIndex(opened => opened === section);
        if (idx !== -1) {
            setOpenedSections(openedSections.toSpliced(idx, 1));
        } else {
            setOpenedSections([...openedSections, section]);
        }
    };

    return (
        <StyledGraphSelector>
            {sections.map(({ name, graphs }) => {
                if (graphs.length > 1)
                    return (
                        <div key={name}>
                            <StyledExpandableToggle onClick={() => toggleSection(name)}>
                                <StyledSectionText variant="bodyLSemibold">
                                    {t(`review_analytics_graph_section_${name}`)}
                                </StyledSectionText>
                                <Icon
                                    icon={[
                                        openedSections.includes(name)
                                            ? FontAwesomeIconsPartooUsed.faChevronDown
                                            : FontAwesomeIconsPartooUsed.faChevronRight,
                                        IconPrefix.REGULAR,
                                    ]}
                                />
                            </StyledExpandableToggle>
                            <Expandable key={name} opened={openedSections.includes(name)}>
                                {graphs.map(graph => (
                                    <StyledSelectableCard
                                        key={graph}
                                        label={
                                            <Text as="span" variant="bodyMBold" color="secondary">
                                                {t(`review_analytics_graph_${graph}`)}
                                            </Text>
                                        }
                                        dataTrackId=""
                                        value={graph}
                                        checked={selectedGraph === graph}
                                        onChange={() => setSelectedGraph(graph)}
                                    />
                                ))}
                            </Expandable>
                        </div>
                    );
                return (
                    <StyledSelectableCard
                        key={graphs[0]}
                        label={
                            <Text as="span" variant="bodyLSemibold">
                                {t(`review_analytics_graph_${graphs[0]}`)}
                            </Text>
                        }
                        dataTrackId=""
                        value={graphs[0]}
                        checked={selectedGraph === graphs[0]}
                        onChange={() => setSelectedGraph(graphs[0])}
                    />
                );
            })}
        </StyledGraphSelector>
    );
};
