import React from 'react';

import { HEX_COLORS } from '@partoohub/branding';

const style = {
    width: '1.3em',
    height: '1.3em',
    display: 'inline-block',
    color: HEX_COLORS.secondary,
};

function TripAdvisorIcon() {
    return (
        <span style={style}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 800 800"
                enableBackground="new 0 0 800 800"
            >
                <path
                    fill="currentColor"
                    d="M270.986,440.52c0,39.164-31.749,70.912-70.913,70.912c-39.163,0-70.912-31.748-70.912-70.912  s31.748-70.912,70.912-70.912l0,0C239.237,369.608,270.986,401.355,270.986,440.52z M599.835,369.608  c-39.164-0.022-70.93,31.707-70.953,70.871c-0.021,39.164,31.708,70.931,70.872,70.953c39.164,0.021,70.932-31.707,70.953-70.871  c0-0.016,0-0.027,0-0.041C670.707,401.371,638.983,369.63,599.835,369.608z M799.798,440.52  c0,110.394-89.568,199.881-199.963,199.881c-50.366,0.073-98.892-18.939-135.802-53.209l-64.039,69.656l-64.077-69.729  c-36.913,34.314-85.466,53.355-135.863,53.281C89.66,640.4,0.173,550.913,0.173,440.52c-0.059-56.236,23.632-109.884,65.234-147.726  L0,221.628h145.354c153.774-104.635,355.884-104.635,509.658,0H800l-65.419,71.166C776.179,330.638,799.861,384.286,799.798,440.52  L799.798,440.52z M335.349,440.52c0.006-74.71-60.554-135.278-135.265-135.285C125.373,305.23,64.804,365.789,64.799,440.5  s60.555,135.279,135.265,135.285c0.003,0,0.007,0,0.01,0C274.778,575.778,335.338,515.224,335.349,440.52L335.349,440.52z   M550.104,238.581c-96.075-40.037-204.162-40.037-300.237,0c85.381,32.647,150.127,109.025,150.127,198.045  C399.995,347.616,464.732,271.239,550.104,238.581z M735.1,440.52c0-74.704-60.559-135.265-135.265-135.265  c-74.704,0-135.265,60.561-135.265,135.265s60.561,135.265,135.265,135.265C674.537,575.778,735.094,515.222,735.1,440.52z"
                />
            </svg>
        </span>
    );
}

export default TripAdvisorIcon;
