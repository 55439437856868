import { Text } from '@partoohub/ui';

import { StyledFeedbackRatingField } from './FeedbackResultRatingField.styled';
import { RatingStars } from '../../../ReviewCard/ReviewCardRating/RatingNumber';

interface Props {
    question: string;
    rating: number | null;
}

export const FeedbackResultRatingField = ({ question, rating }: Props) => {
    return (
        <StyledFeedbackRatingField>
            <Text variant="bodyMSemibold">{question}</Text>
            <div>
                <RatingStars rating={rating ?? 0} />
            </div>
        </StyledFeedbackRatingField>
    );
};
