import { useState } from 'react';

import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useMe from 'app/common/hooks/queries/useMe';
import { getOrgInfos } from 'app/common/reducers/orgs';
import env from 'app/common/services/getEnvironment';
import { isPartooUser, isPartooUserConnectAs } from 'app/common/utils/isPartooUser';
import { DeviceType, useDeviceType } from 'app/hooks';
import { orgsSelector } from 'app/states/reducers';

import { EnvBannerContainer } from './EnvBanner.styled';

const EnvBanner = () => {
    const { t } = useTranslation();
    const { device } = useDeviceType();
    const { data: me } = useMe();
    const orgId = me?.orgId ?? null;
    const orgs = useSelector(orgsSelector);
    const meOrgInfos = getOrgInfos(orgs, orgId);
    const [isClosed, setIsClosed] = useState<boolean>(false);

    const canDisplayBanner =
        !isClosed &&
        !!me &&
        env.isProdEnv() &&
        device !== DeviceType.Mobile &&
        (isPartooUser(me) || isPartooUserConnectAs(me));

    const email = me?.email ?? '';
    const translation = t('connectas_banner');
    const text = `${translation} ${email} - (${meOrgInfos})`;

    const onClose = () => {
        setIsClosed(true);
    };

    if (!canDisplayBanner) return null;

    return (
        <EnvBannerContainer isDesktop={device === DeviceType.Desktop}>
            <Banner dataTrackId="env_banner" onClose={onClose}>
                {text}
            </Banner>
        </EnvBannerContainer>
    );
};

export default EnvBanner;
