import { call, put, takeEvery } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import formatOrg from 'app/businessConnection/services/formatOrg';
import {
    GET_ORGANISATION_REQUEST,
    GetOrgAction,
    getOrgFailure,
    getOrgSuccess,
} from 'app/common/reducers/orgs';
import { Saga } from 'app/common/types';

function* getOrg({ orgId }: GetOrgAction): Saga {
    try {
        const data = yield call(api.org.getOrg, orgId);
        const formattedLocation = formatOrg(data);
        yield put(getOrgSuccess(formattedLocation));
    } catch (error) {
        yield put(getOrgFailure(orgId, error));
    }
}

function* getOrgSaga(): Saga {
    yield takeEvery(GET_ORGANISATION_REQUEST, getOrg);
}

export default getOrgSaga;
