import { Dispatch, SetStateAction, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ALL_SECTIONS } from 'app/common/data/exportBusinessSections';
import ButtonSelect from 'app/common/designSystem/components/molecules/ButtonSelect/ButtonSelect';

import { SectionsSelectionModal } from './SectionsSelectionModal';

type Props = {
    sections: Array<string>;
    setSections: Dispatch<SetStateAction<Array<string>>>;
    businessCount: number;
};

export const SectionsSelect = ({ sections, setSections, businessCount }: Props) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ButtonSelect
                placeholder={t('select_sections_to_export')}
                label={
                    sections.length && sections.length !== ALL_SECTIONS.length
                        ? t('sections_with_count', { count: sections.length })
                        : t('all_sections')
                }
                onClick={openModal}
            />

            <SectionsSelectionModal
                show={showModal}
                handleClose={closeModal}
                sections={sections}
                setSections={setSections}
                businessCount={businessCount}
            />
        </>
    );
};
