import { connect } from 'react-redux';

import FoundLocationsSectionComponent from 'app/businessConnection/components/modals/ConnectionModal/SearchLocationsSection/FoundLocationsSection/FoundLocationsSection';
import getPartnerMethods from 'app/businessConnection/services/PartnerMethods';
import { partnerToPartnerName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import fuzzySearch, {
    GMB_LOCATIONS_TERMS_TO_SEARCH_IN,
} from 'app/common/services/fuzzySearch';
import { FACEBOOK } from 'app/states/knowledge/reducers/businessConnection/accounts';
import {
    searchLocationInputSelector,
    selectedPartnerSelector,
} from 'app/states/knowledge/reducers/businessConnection/connection';
import { AppState, connectionSelector } from 'app/states/reducers';

const mapStateToProps = (state: AppState) => {
    const connection = connectionSelector(state);
    const searchInput = searchLocationInputSelector(connection);
    const partner = selectedPartnerSelector(connection);
    const partnerMethods = getPartnerMethods[partner];

    if (!searchInput || searchInput.length < 4) {
        return {
            locations: [],
            partnerName: partnerToPartnerName[partner],
        };
    }

    const allLocations =
        partnerMethods.allLocationsSearchResultsSelector(state);
    const locations = fuzzySearch(
        allLocations,
        GMB_LOCATIONS_TERMS_TO_SEARCH_IN,
        searchInput,
    );

    return {
        locations: partner === FACEBOOK ? allLocations : locations,
        partnerName: partnerToPartnerName[partner],
        isFetching: partnerMethods.isSearchingOnServerSelector(state),
    };
};

const FoundLocationsSection = connect(mapStateToProps)(
    FoundLocationsSectionComponent,
);

export default FoundLocationsSection;
