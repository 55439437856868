import React, { ReactNode } from 'react';

import { MWrapper } from './MarginWrapper.styled';

type Props = {
    children: ReactNode;
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
};

const MarginWrapper = React.forwardRef<HTMLDivElement, Props>(
    ({ children, top, right, bottom, left }: Props, ref) => (
        <MWrapper ref={ref} top={top} right={right} bottom={bottom} left={left}>
            {children}
        </MWrapper>
    ),
);

export default MarginWrapper;
