import React from 'react';

import Loader from 'app/common/designSystem/components/atoms/svg/Loader';
import { LoaderViewContainer } from 'app/pages/analytics/VisibilityAnalyticsV2/components/LoaderView/LoaderView.styled';

type Props = {
    height?: number;
    unit?: string;
};

export const LoaderView = ({ height = 50, unit = 'px' }: Props) => (
    <LoaderViewContainer height={height} unit={unit}>
        <Loader />
    </LoaderViewContainer>
);
