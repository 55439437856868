import React from 'react';

import { Button } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    GroupsSettingsQueryKeys,
    SECTION,
} from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

import {
    EmptyPlaceholderContainer,
    Subtitle,
    Title,
} from './GroupsSettingsEmptyPlaceholder.styled';
import Placeholder from './Placeholder';

const GroupsSettingsEmptyPlaceholder = () => {
    const { t } = useTranslation();
    const [, setCreateSection] = useStateQueryParams(GroupsSettingsQueryKeys.CREATE, true);

    return (
        <EmptyPlaceholderContainer>
            <Placeholder />
            <Title>{t('group_placeholder_title')}</Title>
            <Subtitle>{t('group_placeholder_subtitle')}</Subtitle>
            <Button
                dataTrackId="groups-settings-empty-placeholder-create-group-button"
                onClick={() => setCreateSection(SECTION)}
            >
                {t('group_placeholder_button')}
            </Button>
        </EmptyPlaceholderContainer>
    );
};

export default GroupsSettingsEmptyPlaceholder;
