import styled from '@emotion/styled';

export const HomepageShortcutsContainer = styled.div`
    margin-bottom: 32px;
`;

export const HomepageShortcutsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 16px;
`;
