import { Stack } from '@partoohub/ui';
import { Control, UseFormClearErrors } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { SettingFormReturn } from 'app/api/types/business_message_settings';
import { ControlledToggleBox } from 'app/common/designSystem/components/molecules/ToggleBox/ControlledToggleBox';
import { MessagingSettingsFormErrors } from 'app/reviewManagement/messaging/types';

type Props = {
    control: Control;
    errors: MessagingSettingsFormErrors;
    clearErrors: UseFormClearErrors<SettingFormReturn>;
};

export const SettingsForm = ({ control }: Props) => {
    const { t } = useTranslation();
    return (
        <Stack direction="column">
            <ControlledToggleBox
                text={t('messages_settings_activate_messages')}
                description={t('messages_settings_activate_messages_description')}
                name={'isLaunched'}
                control={control}
            />
        </Stack>
    );
};
