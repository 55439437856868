import { UNGROUPED_ID } from 'app/common/reducers/groups';

const getAggregateSum = (countById, ids) => {
    const reducer = (acc, curr) => {
        if (Object.prototype.hasOwnProperty.call(countById, curr)) {
            return acc + countById[curr];
        }

        return acc + 0;
    };

    return ids.reduce(reducer, 0);
};

const aggregateGroupCountService = (groupData, countById) => {
    const mapper = {};
    const aggregateCountById = {};
    groupData.forEach(group => {
        const subGroupIds = group.subgroups
            ? group.subgroups.map(sub => sub.groupId)
            : [];
        subGroupIds.push(group.groupId);
        if (group.groupId !== UNGROUPED_ID) mapper[group.groupId] = subGroupIds;
    });
    Object.keys(mapper).forEach(key => {
        aggregateCountById[key] = getAggregateSum(countById, mapper[key]);
    });
    return { ...countById, ...aggregateCountById };
};

export default aggregateGroupCountService;
