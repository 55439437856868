import React from 'react';

import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import BusinessCreate from './BusinessCreate';

export const BusinessCreatePage = () => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('add_business_title')} />
            <BusinessCreate />
        </>
    );
};
