import { useQuery } from 'react-query';

import API from 'app/api/v2/api_calls/index';

import { REVIEW_TAGS } from 'app/common/data/queryKeysConstants';

export const useReviewTags = () => {
    const query = useQuery(
        REVIEW_TAGS,
        // Getting all tags at once
        // (there are max 100 tags per orgs, therefore we set the perPage param to 100)
        () => API.interactionTag.getInteractionTags('', 1, 100),
    );
    return [query.data?.tags ?? [], query] as const;
};
