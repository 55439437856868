import React, { useContext, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { AnimatedCounter } from 'app/businessConnection/components/AccountsList/AccountRow/AnimatedCounter';
import FunnelContext from 'app/common/components/funnel/FunnelContext';
import { SubTitle, Title } from 'app/common/components/funnel/FunnelModal.styled';
import ProviderLogo from 'app/common/components/ProviderLogo/ProviderLogo';
import { useRefreshOAuthAccount } from 'app/common/contexts/taskListener/useRefreshOAuthAccount';
import { NUMBER_IMPORTED_OAUTH_ACCOUNTS, OAUTH_ACCOUNTS } from 'app/common/data/queryKeysConstants';
import { STATIC_BASE_URL } from 'app/config';

import { GOOGLE_MY_BUSINESS } from 'app/states/knowledge/reducers/businessConnection/accounts';

import queryClient from 'app/states/queryClient';

import { ImageTitle, LoadingLocationsContainer } from './LoadingLocations.styled';

export const LoadingLocations = () => {
    const { t } = useTranslation();
    const funnel = useContext(FunnelContext);
    const providerLabel = useSelector((state: any) => state.me.data.provider);

    const { oauthAccountId } = funnel.store;
    const { isSuccess, isError, accountsTotal, percentageProgress } = useRefreshOAuthAccount({
        oauthAccountId,
        partner: GOOGLE_MY_BUSINESS,
        fetch_origin: 'onboarding_funnel',
    });

    const prevPercentageProgress = useRef(0);

    useEffect(() => {
        prevPercentageProgress.current = percentageProgress;
    }, [percentageProgress]);

    useEffect(() => {
        if (isError) {
            funnel.navigate('error_page');
        } else if (isSuccess) {
            queryClient.invalidateQueries([OAUTH_ACCOUNTS]);
            queryClient.invalidateQueries([NUMBER_IMPORTED_OAUTH_ACCOUNTS]);

            if (accountsTotal !== undefined && accountsTotal > 0) {
                (async () => {
                    setTimeout(() => funnel.navigate('selection_page'), 3000);
                })();
            } else {
                funnel.navigate('no_location_page');
            }
        }
    }, [isSuccess, isError]);

    return (
        <LoadingLocationsContainer>
            {providerLabel !== 'partoo' ? (
                <ProviderLogo providerLabel={providerLabel} />
            ) : (
                <ImageTitle
                    src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/partoo.svg`}
                    alt="partoo_logo"
                />
            )}
            <Title>{t('syncing_your_account')}</Title>
            <SubTitle>
                <AnimatedCounter from={prevPercentageProgress.current} to={percentageProgress} /> %
            </SubTitle>
        </LoadingLocationsContainer>
    );
};

export default LoadingLocations;
