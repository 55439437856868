import React from 'react';

import { AccountImportModal } from 'app/businessConnection/components/modals/AccountImportModal/AccountImportModal';
import DesktopHeader from 'app/businessConnection/components/PageHeader/DesktopHeader/DesktopHeader.container';
import { PartnerConnectionsContainer } from 'app/businessConnection/components/PartnerConnections.styled';

import { useIsTablet } from 'app/hooks/useIsTablet';

import ConnectionsTable from './connectionsTable';
import ConnectionModal from './modals/ConnectionModal';

import MobileHeader from './PageHeader/MobileHeader/MobileHeader.container';

const PartnerConnections = () => {
    const isTablet = useIsTablet();
    return (
        <PartnerConnectionsContainer>
            <AccountImportModal />
            <ConnectionModal />
            {isTablet ? <MobileHeader /> : <DesktopHeader />}
            <ConnectionsTable />
        </PartnerConnectionsContainer>
    );
};

export default PartnerConnections;
