import { connect } from 'react-redux';

import StatefulButtonComponent, {
    ERROR,
    LOADING,
    SUCCESSFUL,
} from 'app/common/components/buttons/StatefulButton';
import {
    disconnectBusinessFromLocation,
    disconnectionInProgressSelector,
    disconnectionSuccessSelector,
} from 'app/states/knowledge/reducers/businessConnection/connection';
import { AppState } from 'app/states/reducers';

const mapStateToProps = ({ businessConnection: { page } }: AppState) => {
    const disconnectionInProgress = disconnectionInProgressSelector(page);
    const disconnectionSuccess = disconnectionSuccessSelector(page);

    let label = 'disconnect';
    let status = ERROR;

    if (disconnectionInProgress) {
        label = 'disconnection_in_progress';
        status = LOADING;
    } else if (disconnectionSuccess) {
        label = 'disconnection_successful';
        status = SUCCESSFUL;
    }

    return {
        label,
        status,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onClick: () => dispatch(disconnectBusinessFromLocation()),
});

const StatefulDisconnectButton = connect(
    mapStateToProps,
    mapDispatchToProps,
)(StatefulButtonComponent);

export default StatefulDisconnectButton;
