import { PartooProviderLogo } from 'app/common/components/icons/PartooProviderLogo';
import { ProviderLogoLinkWithInnerSvg } from 'app/common/designSystem/components/molecules/Sidebar/Sidebar/ProductSidebar/ProviderLogo.styled';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const ProviderLogo = () => {
    const { data: me } = useMe();
    if (!me?.provider_logo) {
        return (
            <ProviderLogoLinkWithInnerSvg to="/" data-cy="product-sidebar-logo">
                <PartooProviderLogo />
            </ProviderLogoLinkWithInnerSvg>
        );
    }
    return (
        <ProviderLogoLinkWithInnerSvg
            to="/"
            data-cy="product-sidebar-logo"
            {...{
                dangerouslySetInnerHTML: {
                    __html: me.provider_logo,
                },
            }}
        />
    );
};
