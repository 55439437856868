import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    ReviewAnalyticsAnswerRateStyled,
    ReviewAnalyticsAverageGradeStyled,
    ReviewAnalyticsLayoutStyled,
    ReviewAnalyticsMyReviewsStyled,
    ReviewAnalyticsOverTimeStyled,
    ReviewAnalyticsTagsStyled,
} from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/ReviewAnalytics.styled';
import TagStatSection from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/TagStatSection/TagStatSection';
import useHasReviewTagFeature from 'app/reviewManagement/common/hooks/useHasReviewTagFeature';

import { applyFiltersAction } from 'app/states/reviews/filters';

import AnswerRate from './analyticsCards/AnswerRate.container';
import Ratings from './analyticsCards/Ratings.container';
import AverageGrade from './AverageGrade';
import ReviewsEvolution from './ReviewsEvolution/ReviewsEvolution.container';

export default function ReviewAnalyticsSection() {
    const dispatch = useDispatch();
    const hasReviewTagFeature = useHasReviewTagFeature();
    const [tagQuery] = useStateQueryParams('tag');
    const [dateFrom] = useStateQueryParams('date-from');
    const [dateTo] = useStateQueryParams('date-to');
    const filteredTagIds = tagQuery ? tagQuery.split(',') : [];
    const showTagSection = hasReviewTagFeature && filteredTagIds.length === 0;

    useEffect(() => {
        // TODO: Get rid of applyDataFilters saga and remove this
        dispatch(applyFiltersAction());
    }, [tagQuery, dateFrom, dateTo]);

    return (
        <ReviewAnalyticsLayoutStyled showTags={showTagSection}>
            <ReviewAnalyticsMyReviewsStyled
                data-track="analytics_reviews__section_my_reviews"
                data-intercom-target="analytics_reviews__section_my_reviews"
            >
                <Ratings />
            </ReviewAnalyticsMyReviewsStyled>
            <ReviewAnalyticsAverageGradeStyled
                data-track="analytics_reviews__section_average_grade"
                data-intercom-target="analytics_reviews__section_average_grade"
            >
                <AverageGrade />
            </ReviewAnalyticsAverageGradeStyled>
            <ReviewAnalyticsAnswerRateStyled
                data-track="analytics_reviews__section_answer_rate"
                data-intercom-target="analytics_reviews__section_answer_rate"
            >
                <AnswerRate />
            </ReviewAnalyticsAnswerRateStyled>

            {showTagSection && (
                <ReviewAnalyticsTagsStyled
                    data-track="analytics_reviews__section_tag"
                    data-intercom-target="analytics_reviews__section_tag"
                >
                    <TagStatSection />
                </ReviewAnalyticsTagsStyled>
            )}

            <ReviewAnalyticsOverTimeStyled
                data-track="analytics_reviews__section_evolution"
                data-intercom-target="analytics_reviews__section_evolution"
            >
                <ReviewsEvolution />
            </ReviewAnalyticsOverTimeStyled>
        </ReviewAnalyticsLayoutStyled>
    );
}
