import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const DropdownMenuContainer = styled.div`
    padding-right: 24px;
    position: relative;

    > div {
        position: unset;
    }
`;

export const StyledStack = styled(Stack)<{ paddingLeft: string }>`
    padding-left: ${props => props.paddingLeft};
`;
