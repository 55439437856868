import { HEX_COLORS } from '@partoohub/branding';

export const queriesColor = {
    hex: HEX_COLORS.primary,
    rgba: 'rgba(0, 133, 242, 0.12)',
};

export const queriesChartColor = {
    light: '#69cbff',
    normal: '#039ff1',
    dark: '#027fc1',
};

export const queriesHelperContent = [
    {
        key: 'keywords',
        icon: 'fa-trophy',
        title: 'top_searched_keywords',
        description: 'queries_explanation',
        color: queriesColor,
    },
];
