import React, { useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import ReactDropZone from 'react-dropzone';

import usePhotosUpload from 'app/businessEditV2/hooks/usePhotosUpload';
import { businessEditMaxPhotoSize } from 'app/businessEditV2/utils/photos';
import AddImageIcon from 'app/common/components/icons/AddImageIcon';
import { DropZoneLoadingSpinner } from 'app/common/designSystem/components/molecules/DropZone/DropZoneLoading.styled';
import useDebounce from 'app/common/hooks/useDebounce';
import {
    DropZoneContainer,
    PhotoPreview,
    PhotoPreviewOverlay,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/sections/CustomFieldsModals/modals/CustomFieldForm/SpecificFormType/FormTypeSelectImage/SelectImageDropzone/SelectImageDropzone.styled';
import { resizeImage } from 'app/utils/resizeImage';

type Props = {
    url: string;
    setUrl: (url: string) => void;
};

/**
 * Recoding the Dropzone because the other ones and the DS are not the same at all
 * This one is very simple and does not display any text or error message
 */
const SelectImageDropzone = ({ url, setUrl }: Props) => {
    const [hasError, setHasError] = useState<boolean>(false);
    const uploadPhotos = usePhotosUpload(
        images => {
            const newLogo = images.result.uploads[0];
            if (newLogo) {
                setUrl(newLogo);
            }
            setHasError(false);
        },
        () => {
            setUrl('');
            setHasError(true);
        },
    );

    const displayPreview = !!(url && !uploadPhotos.isLoading);

    const debouncedUrl = useDebounce(url, 500);

    return (
        <DropZoneContainer hideBorder={displayPreview} hasError={hasError}>
            <ReactDropZone
                onDrop={files => {
                    setHasError(false);
                    uploadPhotos.mutate(files);
                }}
                onDropRejected={() => {
                    setUrl('');
                    setHasError(true);
                }}
                minSize={0}
                maxSize={businessEditMaxPhotoSize}
                maxfiles={1}
                accept={['image/jpeg', 'image/jpg', 'image/png']}
                multiple={false}
                disabled={false}
            >
                {uploadPhotos.isLoading ? (
                    <DropZoneLoadingSpinner size={24} />
                ) : (
                    <AddImageIcon
                        width={24}
                        height={24}
                        color={hasError ? HEX_COLORS.danger : undefined}
                    />
                )}

                <PhotoPreview url={resizeImage(debouncedUrl)} opacity={+displayPreview}>
                    <PhotoPreviewOverlay>
                        <i className="fa-solid fa-camera" />
                    </PhotoPreviewOverlay>
                </PhotoPreview>
            </ReactDropZone>
        </DropZoneContainer>
    );
};

export default SelectImageDropzone;
