import styled from '@emotion/styled';

export const StyledFeedbackResultField = styled.div<{ columns?: number }>`
    display: flex;
    flex-direction: column;
    grid-column: span ${({ columns }) => columns ?? 1};
    padding: 16px;
    gap: 8px;
    background-color: #f6f8fc;
    border-radius: 16px;
`;
