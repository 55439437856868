import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { BusinessListQueryKeys } from 'app/pages/visibility/BusinessListV2/utils/enums';

export enum BusinessServices {
    PRESENCE = 'presence',
    REVIEW = 'review',
    REVIEW_BOOSTER = 'review-booster',
}

export enum BusinessServicesApiKey {
    PRESENCE = 'subscribed_to_pm',
    REVIEW = 'subscribed_to_rm',
    REVIEW_BOOSTER = 'subscribed_to_rb',
}

export const useGetProductFilter = () => {
    const [products] = useStateQueryParams(BusinessListQueryKeys.PRODUCTS);
    const productsArray = products?.split(',') ?? [];

    return {
        ...(productsArray.includes(BusinessServices.PRESENCE)
            ? { [BusinessServicesApiKey.PRESENCE]: 'true' }
            : {}),
        ...(productsArray.includes(BusinessServices.REVIEW)
            ? { [BusinessServicesApiKey.REVIEW]: 'true' }
            : {}),
        ...(productsArray.includes(BusinessServices.REVIEW_BOOSTER)
            ? { [BusinessServicesApiKey.REVIEW_BOOSTER]: 'true' }
            : {}),
    };
};
