import { Dispatch, SetStateAction, useCallback, useState } from 'react';

const getItem = <T>(key: string, fallback: T) => {
    try {
        const item = window.localStorage.getItem(key);
        return item !== null ? JSON.parse(item) : fallback;
    } catch (error) {
        console.error(error);
        return fallback;
    }
};

const setItem = <T>(key: string, value: T) => {
    window.localStorage.setItem(key, JSON.stringify(value));
};

const delItem = (key: string) => {
    window.localStorage.removeItem(key);
};

interface UseLocalStorageOptions {
    maxAge: number;
}

interface StoredValue<T> {
    created: string;
    value: T;
}

/**
 * A hook to declare a state persisted in localStorage. It has the same
 * prototype as useState(), except it takes a key as first parameter to
 * keep track of the stored data in the localStorage.
 *
 * Adapted from https://usehooks.com/useLocalStorage/
 *
 * It actually starts to store some data in the localStorage when the
 * state is set to a different value than its initial value.
 *
 * @param {number} options.maxAge
 * The number seconds the state should stay in a non initial value. If
 * the component is mounted while this delay has been passed, the entry
 * in localStorage is deleted and the state comes back to its initial
 * value.
 *
 * @example
 * const [count, setCount] = useLocalStorage("count", 1, {maxAge: 24 * 3600})
 */
const useLocalStorage = <T>(
    key: string,
    initialValue: T,
    options?: UseLocalStorageOptions,
): [T, Dispatch<SetStateAction<T>>] => {
    const init = useCallback(() => {
        const now = new Date().toISOString();
        const initial = { value: initialValue, created: now };
        const current = getItem(key, initial);

        if (
            options?.maxAge !== undefined &&
            (Date.parse(now) - Date.parse(current.created)) / 1000 > options.maxAge
        ) {
            delItem(key);
            return initial;
        }
        return current;
    }, [initialValue, options]);

    const [stored, setStored] = useState<StoredValue<T>>(init);

    // A wrapper around setStored, that updates the localStorage along with the component state
    const setValue = (value: T | ((v: T) => T)) => {
        try {
            // support updater function, ie setCount(prevCount => prevCount + 1)
            const newValue = value instanceof Function ? value(stored.value) : value;
            const newStored = { value: newValue, created: stored.created };
            setItem(key, newStored);
            setStored(newStored);
        } catch (error) {
            console.error(error);
        }
    };

    return [stored.value, setValue];
};

export default useLocalStorage;
