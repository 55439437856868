import { differenceInMonths, endOfMonth, format, startOfMonth, subMonths } from 'date-fns';

import { Period } from 'app/pages/analytics/VisibilityAnalyticsV2/types/period';
import { getUTCDate } from 'app/utils/dates';

const PERIOD_DEFAULT_MONTH = 6;
export const URL_MONTH_FORMAT = 'yyyy-MM-dd';
export const MONTH_FORMAT = 'MM/yyyy';
export const COMPARISON_PERIOD_FORMAT = 'dd/MM/yyyy';
// October 2021 is the earliest date when have data about keywords analytics
export const MIN_RETENTION_DATE = new Date(2021, 9, 1);

export const calculateRangeDate = (
    period: string,
    startDate: string,
    endDate: string,
    formatData = URL_MONTH_FORMAT,
) => {
    const cloneStartDate = startOfMonth(getUTCDate(new Date(startDate)));
    const cloneEndDate = endOfMonth(getUTCDate(new Date(endDate)));

    if (!cloneStartDate || !cloneEndDate) return null;

    if (period === 'year') {
        return {
            dateFrom: format(startOfMonth(subMonths(cloneStartDate, 12)), formatData),
            dateTo: format(endOfMonth(subMonths(cloneEndDate, 12)), formatData),
        };
    }

    const diffInMonths = Math.abs(differenceInMonths(cloneStartDate, cloneEndDate));

    return {
        dateFrom: format(startOfMonth(subMonths(cloneStartDate, diffInMonths + 1)), formatData),
        dateTo: format(endOfMonth(subMonths(cloneEndDate, diffInMonths + 1)), formatData),
    };
};

export const calculateRange = (period = `${Period.PERIOD}`) => {
    if (period === Period.YEAR) {
        return 12;
    }
    return PERIOD_DEFAULT_MONTH;
};

export const formatComparisonDates = (
    date: any,
    formatData: string,
    formatTypes: Array<string>,
): string => {
    let usedFormat = formatData;

    if (!formatTypes.includes(formatData)) {
        usedFormat = COMPARISON_PERIOD_FORMAT;
    }

    const from = date.from != null ? format(new Date(date.from), usedFormat) : '';
    const to = date.to != null ? format(new Date(date.to), usedFormat) : '';

    return `${from} - ${to}`;
};
