import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Mention, MentionsInput } from 'react-mentions';

type StyledMentionProps = {
    noBackground: boolean;
};

export const StyledMention = styled(Mention, {
    shouldForwardProp: prop => !['noBackground'].includes(prop),
})<StyledMentionProps>`
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.primary.initial} !important;
    ${({ noBackground, theme }) =>
        !noBackground &&
        css`
            background: ${theme.colors.primary.alpha};
        `}
    position: relative;
    pointer-events: none;
    z-index: 1;
`;

type StyledMentionsInputProps = {
    noSuggestions: boolean;
    hasCustomAbsoluteButtons: boolean;
};

export const StyledMentionsInput = styled(MentionsInput, {
    shouldForwardProp: prop => prop !== 'noSuggestions',
})<StyledMentionsInputProps>`
    min-height: 134px;
    border: 1px solid;
    border-radius: 4px;
    border-color: ${({ theme }) => theme.colors.default.alpha};
    line-height: 18px;
    font-family: 'Outfit', sans-serif;

    .mentions__textarea__highlighter {
        border: none !important;
    }
    .mentions__textarea__highlighter,
    .mentions__textarea__input {
        padding: 12px 16px;
        ${props =>
            props.hasCustomAbsoluteButtons &&
            css`
                padding-bottom: 48px;
            `};
    }

    &:hover {
        border-radius: 4px;
        border-color: #000000;
    }

    &:focus-within {
        border-width: 2px;
        border-color: ${({ theme }) => theme.colors.primary.initial};

        .mentions__textarea__highlighter,
        .mentions__textarea__input {
            padding: 11px 15px;
            ${props =>
                props.hasCustomAbsoluteButtons &&
                css`
                    padding-bottom: 47px;
                `};
        }
    }

    & .mentions__textarea__suggestions__list {
        ${props =>
            props.noSuggestions
                ? css`
                      display: none;
                  `
                : css`
                      border-radius: 4px;
                      box-shadow:
                          0 3px 14px 2px rgba(0, 0, 0, 0.12),
                          0 8px 10px 1px rgba(0, 0, 0, 0.14),
                          0 5px 5px -3px rgba(0, 0, 0, 0.2);
                  `}
    }
`;

export const MentionTextAreaExplanation = styled.div`
    margin: 4px 0px;
    padding: 0px 16px;
`;
