import { css } from '@emotion/react';

import { HEX_COLORS } from '@partoohub/branding';
import { Icon, IconPrefix, Image, toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';
import { ADMIN } from 'app/common/data/roles';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import {
    PostCardActionButton,
    PostCardCoverContainer,
    PostCardCoverDate,
    PostCardCoverPhoto,
    PostCardCoverPhotoBottom,
    PostCardCoverTitle,
} from 'app/common/designSystem/components/molecules/GooglePostCard/PostCardCover.styled';
import PostTypeLabel from 'app/common/designSystem/components/molecules/GooglePostCard/PostTypeLabel';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { STATIC_BASE_URL } from 'app/config';
import { useHasPostPermission } from 'app/presence/googlePosts/hooks/useHasPostPermission';
import { mapTypeToIcon } from 'app/presence/googlePosts/services/mapTypeToIcon';
import { TopicTypeEnum } from 'app/presence/googlePosts/variables/googlePostType';

import { ActionButtonWithMenu } from '../ActionButtonWithMenu/ActionButtonWithMenu';

type Props = {
    topicType: TopicTypeEnum;
    onClick?: () => void;
    disableClickable: boolean;
    onMenuClick: (value: string) => void;
    isExpired: boolean;
    // Optional props
    dates?: string;
    title?: string;
    photoUrl?: string;
    disabledValues?: Array<string>;
    hasAlsoInstagram: boolean;
    // Whether user can edit a single instagram post
    canEditInstagram: boolean;
    postId: string;
    postBulkId: number;
};

const COPY = 'copy';
const COPY_BULK = 'copy_bulk';

const PostCardCover = ({
    topicType,
    dates,
    title,
    photoUrl,
    onClick,
    onMenuClick,
    disabledValues,
    isExpired,
    disableClickable,
    hasAlsoInstagram,
    canEditInstagram,
    postId,
    postBulkId,
}: Props) => {
    const { t } = useTranslation();
    const { data: me } = useMe();
    const hasPostPermission = useHasPostPermission();

    const isShadowUserAdmin = me && me.shadow_user_role == ADMIN;

    const copyOptions = [
        {
            name: COPY,
            value: COPY,
            label: 'Copy Post Id',
        },
    ];

    if (postBulkId) {
        copyOptions.push({
            name: COPY_BULK,
            value: COPY_BULK,
            label: 'Copy Bulk Id',
        });
    }

    const onCopyClick = (el: string) => {
        const optionMapping: Record<string, Record<string, string>> = {
            [COPY]: {
                clipboardValue: String(postId),
                successText: 'Post Id copied successfully',
            },
            [COPY_BULK]: {
                clipboardValue: String(postBulkId),
                successText: 'Bulk Id copied successfully',
            },
        };

        if (optionMapping.hasOwnProperty(el)) {
            const { clipboardValue, successText } = optionMapping[el];
            navigator.clipboard
                .writeText(clipboardValue)
                .then(() => toast.success(null, successText));
        }
    };

    const editPostOptions = hasPostPermission
        ? [
              {
                  name: 'edit',
                  value: 'edit',
                  label: (
                      <MenuLabelItem
                          text={t('edit')}
                          icon={['fa-pen-clip', IconPrefix.REGULAR]}
                          subtitle={
                              hasAlsoInstagram && !canEditInstagram
                                  ? t('post_except_instagram')
                                  : undefined
                          }
                      />
                  ),
              },
              {
                  name: 'edit_bulk',
                  value: 'edit_bulk',
                  label: (
                      <MenuLabelItem
                          text={t('post_edit_bulk')}
                          icon={
                              <Image src={`${STATIC_BASE_URL}/images/app/icons/edit-double.svg`} />
                          }
                          subtitle={hasAlsoInstagram ? t('post_except_instagram') : undefined}
                      />
                  ),
              },
          ]
        : [];

    const duplicatePostOptions = hasPostPermission
        ? [
              {
                  name: 'duplicate',
                  value: 'duplicate',
                  label: (
                      <MenuLabelItem text={t('duplicate')} icon={['fa-copy', IconPrefix.REGULAR]} />
                  ),
              },
          ]
        : [];

    const deletePostOptions = hasPostPermission
        ? [
              {
                  name: 'delete',
                  value: 'delete',
                  label: (
                      <MenuLabelItem
                          text={t('delete')}
                          icon={['fa-trash', IconPrefix.REGULAR]}
                          color="danger"
                          subtitle={
                              hasAlsoInstagram && !canEditInstagram
                                  ? t('post_except_instagram')
                                  : undefined
                          }
                      />
                  ),
              },
              {
                  name: 'delete_bulk',
                  value: 'delete_bulk',
                  label: (
                      <MenuLabelItem
                          text={t(t('post_delete_bulk'))}
                          icon={
                              <Image src={`${STATIC_BASE_URL}/images/app/icons/trash-double.svg`} />
                          }
                          color="danger"
                          subtitle={hasAlsoInstagram ? t('post_except_instagram') : undefined}
                      />
                  ),
              },
          ]
        : [];

    const showPostOptions = [
        {
            name: 'showOnGoogle',
            value: 'showOnGoogle',
            label: (
                <MenuLabelItem
                    text={t('show_google_post')}
                    icon={['fa-google', IconPrefix.BRANDS]}
                />
            ),
        },
        {
            name: 'showOnFacebook',
            value: 'showOnFacebook',
            label: (
                <MenuLabelItem
                    text={t('show_post_on_facebook')}
                    icon={['fa-facebook', IconPrefix.BRANDS]}
                />
            ),
        },
        {
            name: 'showOnInstagram',
            value: 'showOnInstagram',
            label: (
                <MenuLabelItem
                    text={t('show_post_on_instagram')}
                    icon={['fa-instagram', IconPrefix.BRANDS]}
                />
            ),
        },
    ];

    const options = [
        ...editPostOptions,
        ...duplicatePostOptions,
        ...showPostOptions,
        ...deletePostOptions,
    ];
    const filteredOptions = options.filter(
        option => !disabledValues || !disabledValues.includes(option.value),
    );

    return (
        <TooltipWrapper
            text={isExpired ? t('expired') : ''}
            className="tooltip-position__button"
            position="bottom"
        >
            <PostCardCoverContainer>
                <PostCardCoverPhoto
                    photoUrl={photoUrl}
                    onClick={onClick || (() => undefined)}
                    isExpired={isExpired}
                    disabled={!hasPostPermission || disableClickable}
                />
                <PostTypeLabel
                    className={'type'}
                    icon={mapTypeToIcon[topicType]}
                    text={t(`post_type_name_${topicType}`)}
                    isExpired={isExpired}
                />
                <PostCardActionButton>
                    {isShadowUserAdmin && (
                        <ActionButtonWithMenu
                            dataTrackId="copy_post__action_button"
                            icon={
                                <Icon
                                    icon={['fa-paste', IconPrefix.SOLID]}
                                    css={css`
                                        color: ${HEX_COLORS.white};
                                    `}
                                />
                            }
                            appearance="outlined"
                            size="small"
                            options={copyOptions}
                            onMenuClick={onCopyClick}
                            cssMenu={css`
                                top: 36px;
                                right: 0px;
                                left: unset;
                            `}
                        />
                    )}
                    {!!filteredOptions.length && (
                        <ActionButtonWithMenu
                            dataTrackId="ellipsis__action_button"
                            icon={
                                <Icon
                                    icon={['fa-ellipsis-v', IconPrefix.SOLID]}
                                    css={css`
                                        color: ${HEX_COLORS.white};
                                    `}
                                />
                            }
                            appearance="outlined"
                            size="small"
                            options={filteredOptions}
                            onMenuClick={onMenuClick}
                            cssMenu={css`
                                top: 36px;
                                right: 0px;
                                left: unset;
                            `}
                        />
                    )}
                </PostCardActionButton>
                {dates && (
                    <PostCardCoverPhotoBottom>
                        <PostCardCoverTitle>{title}</PostCardCoverTitle>
                        <PostCardCoverDate>{dates}</PostCardCoverDate>
                    </PostCardCoverPhotoBottom>
                )}
            </PostCardCoverContainer>
        </TooltipWrapper>
    );
};

export default PostCardCover;
