import useAttributes from 'app/businessEditV2/hooks/attributes/attributesMetadata/useAttributes';
import {
    ATTRIBUTES_URL_TYPES,
    ATTRIBUTE_URL_FACEBOOK,
    ATTRIBUTE_URL_TWITTER,
} from 'app/businessEditV2/utils/attributes';

export const ATTRIBUTE_URL_TO_IGNORE = [
    ATTRIBUTE_URL_FACEBOOK,
    ATTRIBUTE_URL_TWITTER,
];

export default function useFormattedAttributesUrl() {
    const { data: attributesData } = useAttributes();

    return (
        attributesData?.groups
            ?.flatMap(group => group.attributes)
            .filter(
                attribute =>
                    ATTRIBUTES_URL_TYPES.includes(attribute.type) &&
                    !ATTRIBUTE_URL_TO_IGNORE.includes(attribute.gmb_id),
            ) ?? []
    );
}
