import { Table } from '@partoohub/ui';

import { StyledCell } from './Rankings.styled';

export const TagsRanking = () => {
    return (
        <Table
            columns={[
                {
                    label: 'Tag',
                    width: '3000px',
                },
                {
                    label: '#Avis tagués',
                    width: '3000px',
                },
                {
                    label: 'Note moyenne',
                    width: '3000px',
                },
            ]}
        >
            <Table.Body>
                <Table.Row dataTrackId="" id="6">
                    <StyledCell>Tag 1</StyledCell>
                    <StyledCell>3881</StyledCell>
                    <StyledCell>3.4</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="7">
                    <StyledCell>Tag 2</StyledCell>
                    <StyledCell>2311</StyledCell>
                    <StyledCell>4.4</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="8">
                    <StyledCell>Tag 3</StyledCell>
                    <StyledCell>121</StyledCell>
                    <StyledCell>3.9</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="8">
                    <StyledCell>Tag 4</StyledCell>
                    <StyledCell>121</StyledCell>
                    <StyledCell>3.9</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="8">
                    <StyledCell>Tag 5</StyledCell>
                    <StyledCell>121</StyledCell>
                    <StyledCell>3.9</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="8">
                    <StyledCell>Tag 6</StyledCell>
                    <StyledCell>121</StyledCell>
                    <StyledCell>3.9</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="8">
                    <StyledCell>Tag 7</StyledCell>
                    <StyledCell>121</StyledCell>
                    <StyledCell>3.9</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="8">
                    <StyledCell>Tag 8</StyledCell>
                    <StyledCell>121</StyledCell>
                    <StyledCell>3.9</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="8">
                    <StyledCell>Tag 9</StyledCell>
                    <StyledCell>121</StyledCell>
                    <StyledCell>3.9</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="8">
                    <StyledCell>Tag 10</StyledCell>
                    <StyledCell>121</StyledCell>
                    <StyledCell>3.9</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="8">
                    <StyledCell>Tag 11</StyledCell>
                    <StyledCell>121</StyledCell>
                    <StyledCell>3.9</StyledCell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};
