import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { SuccessResponse } from 'app/api/types';
import api from 'app/api/v2/api_calls';

type ImportFileApiType = {
    file: Blob;
    email: string;
};

export const useDownloadTemplatesMutation = () => {
    const { t } = useTranslation();

    return useMutation<SuccessResponse, Error, string>(
        (email: string) => api.review.downloadTemplates(email),
        {
            onSuccess: () => {
                toast.loading(
                    t('hours_update_progress_export_progress_report'),
                    t('hours_update_progress_export_progress'),
                );
            },
            onError: (error: Error) => {
                toast.error(error.message, t('request_issue'));
            },
        },
    );
};

export const useUploadTemplatesMutation = () => {
    const { t } = useTranslation();
    return useMutation(
        ({ file, email }: ImportFileApiType) => {
            return api.review.uploadTemplates(file, email);
        },
        {
            onSuccess: () => {
                toast.success(
                    t('import_reply_template_completed_content'),
                    t('import_reply_template_completed_title'),
                );
            },
            onError: (error: Error) => {
                toast.error(error.message, t('request_issue'));
            },
        },
    );
};
