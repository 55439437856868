import { useEffect } from 'react';

import BusinessEditV2Content from 'app/businessEditV2/components/BusinessEditV2Content';
import useWebsocketResetPublisherErrors from 'app/businessEditV2/hooks/publisherErrors/useWebsocketResetPublisherErrors';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { BUSINESS } from 'app/common/data/queryKeysConstants';

import queryClient from 'app/states/queryClient';

import { BusinessEditV2Container } from './BusinessEditV2WithNewSidebar.styled';

export const BusinessEditV2WithNewSidebar = () => {
    // Because react-router keeps the scroll position

    useEffect(() => {
        document.getElementById('react-content')?.scrollTo(0, 0);
        queryClient.invalidateQueries(invalidatesAny(BUSINESS));
    }, []);

    // Update publisher errors after post check updates
    useWebsocketResetPublisherErrors();

    return (
        <BusinessEditV2Container>
            <BusinessEditV2Content />
        </BusinessEditV2Container>
    );
};
