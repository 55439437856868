import { push } from '@lagunovsky/redux-react-router';
import { connect } from 'react-redux';

import BottomLinkComponent from 'app/connection/components/common/BottomLink';
import { CONNECTION_PATH } from 'app/routing/routeIds';
import { resetLostPasswordForm } from 'app/states/connection/reducers/lostPassword';

const mapDispatchToProps = (dispatch: any) => ({
    onClick: () => {
        dispatch(resetLostPasswordForm());
        dispatch(push(CONNECTION_PATH));
    },
});

const ReturnToLoginFormLink = connect(
    null,
    mapDispatchToProps,
)(BottomLinkComponent);

export default ReturnToLoginFormLink;
