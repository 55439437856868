import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { greyColorObject } from '@partoohub/branding';

import Clickable from 'app/common/components/buttons/Clickable';

import { Position } from './ButtonWithSearchMenuFilterSelection';

type MenuWrapperProps = {
    hasHeader: boolean;
    position: Position;
};

export const MenuWrapperSelectorId = 'MenuWrapper';

export const ButtonWithMenuWrapper = styled.div`
    display: flex;
    width: auto;
`;

export const ButtonWithMenuContainer = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    min-height: 40px;
    background: white;
`;

export const InputClickable = styled(Clickable)`
    display: flex;
    flex: 1;
    align-items: center;
    width: 100%;
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    padding: 10px 16px;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    cursor: text;
    border-color: ${({ theme }) => theme.colors.primary.initial};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary.initial};

    &:hover {
        border-color: ${({ theme }) => theme.colors.primary.initial};
    }
`;

export const InputPlaceholder = styled.div`
    position: absolute;
    color: ${({ theme }) => theme.colors.primary.initial};
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.15s;
    font-size: 12px;
    font-weight: bold;
    padding: 0 4px;
    background-color: white;
    top: -9px;
    left: 11px;
`;

export const ItemsContainer = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-bottom: -8px;
    position: relative;
    width: 95%;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    margin-bottom: 8px;
    width: 97%;
    > span {
        position: absolute;
        height: 0;
        overflow: hidden;
        white-space: pre;
        letter-spacing: normal;
    }

    > input {
        height: 24px;
        border: none;
        letter-spacing: normal;
        width: 5px;
        outline: none;
        &.tagSearch {
            min-width: 36px;
            flex-grow: 1;
        }
    }
`;

export const InputIcon = styled.button`
    color: ${({ theme }) => theme.colors.secondary.initial};
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
    background: inherit;
`;

export const Loader = styled.div`
    display: flex;
    justify-content: center;
    padding: 8px;
    & i {
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
`;

export const MenuWrapper = styled.div<MenuWrapperProps>`
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-top: 1px;
    width: calc(100% - 16px);
    min-width: 200px;
    padding: 8px;
    background: white;
    border-radius: 4px;
    max-height: 300px;
    box-shadow:
        0 3px 14px 2px rgba(0, 0, 0, 0.12),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 5px 5px -3px rgba(0, 0, 0, 0.2);
    z-index: 3;
    overflow: hidden;
    top: 100%;
    ${props =>
        props.hasHeader &&
        css`
            min-width: 300px;
        `}
    ${props =>
        props.position &&
        css`
            ${props.position}: 0px;
        `}
`;

export const MenuTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 4px;
`;

export const MenuTitle = styled.div`
    font-weight: bold;
    font-size: 18px;
`;

export const MenuIcon = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    &:not(:last-child) {
        margin-left: auto;
    }
    & button {
        &:hover {
            color: var(--pto-primary);
            background-color: var(--pto-primary-transparent);
        }
    }
`;

export const MenuItemsContainer = styled.div`
    max-height: 238px;
    overflow-y: scroll;
`;

export const MenuItemWrapper = styled.button`
    display: flex;
    justify-content: space-between;
    margin-bottom: unset;
    & + button {
        margin-top: 2px;
    }
`;

export const MenuItemContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const MenuItemIcon = styled.div`
    margin-right: 8px;
`;

export const MenuItemLabel = styled.div``;

export const InputWrapper = styled.div`
    position: relative;
    margin: 8px 0;
`;

export const ItemEmptyPlaceholder = styled.button`
    display: flex;
    align-items: center;
    cursor: default;
    height: 38px;
    padding: 8px;
    width: 100%;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    background-color: inherit;
    border: none;
`;

export const FilterSelectionTitleContainer = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin: 16px 0;
    display: flex;
    flex-direction: row;
`;

export const FilterSelectionTitleIcon = styled.div`
    margin-right: 8px;
    cursor: pointer;
`;

export const FilterSelectionTitle = styled.div`
    cursor: pointer;
`;

export const FilterSelectionMenuContainer = styled.div`
    overflow-y: scroll;
`;

export const FilterSelectionMenu = styled.div`
    display: flex;
    flex-wrap: wrap;

    & > div:nth-of-type(odd) {
        margin-right: 8px;
    }

    & > div {
        margin-bottom: 8px;
    }
`;

export const FilterSelectionItemContainer = styled.div`
    width: calc(50% - 8px);
    display: flex;
    border: ${greyColorObject.initial} solid 1px;
    border-radius: 8px;
    cursor: pointer;
`;

export const FilterSelectionItem = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    padding: 8px;
`;

export const FilterSelectionItemLabel = styled.div`
    font-weight: bold;
    font-size: 14px;
`;

export const ImageContainer = styled.div`
    width: 26px;
    height: 26px;
    border: 1px lightgray solid;
    border-radius: 50%;
    overflow: hidden;
    & > img {
        height: 24px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
`;
