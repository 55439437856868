import { useState } from 'react';

import {
    DynamicFilters,
    FilterCategory,
    FilterName,
    Filters as FiltersType,
    PartialFilters,
} from '@partoohub/modular-components';
import { FontAwesomeIconsPartooUsed } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { SelectableContentFilters } from 'app/common/components/SelectableContentFilters/SelectableContentFilters';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { useHideIntercom } from 'app/hooks/useHideIntercom';
import {
    ApiKeyStatusEnum,
    ApiKeyTypeEnum,
    ApiKeysQueryKeys,
} from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/utils/enums';
import { SelectableContentOption } from 'app/pages/visibility/BusinessListV2/utils/enums';
import {
    concatTrueValues,
    createFilterMap,
} from 'app/pages/visibility/BusinessListV2/utils/helpers';
import { translationApiKeyLabels } from 'app/settingsManagement/components/ApiKey/ApiKey';

const typeOptionValues: Array<SelectableContentOption> = [
    {
        name: ApiKeyTypeEnum.BOT,
        icon: ['fa-laptop-code'],
        text: translationApiKeyLabels.bot,
        value: ApiKeyTypeEnum.BOT,
    },
    {
        name: ApiKeyTypeEnum.USER,
        icon: ['fa-user-gear'],
        text: translationApiKeyLabels.user,
        value: ApiKeyTypeEnum.USER,
    },
];
const statusOptionValues: Array<SelectableContentOption> = [
    {
        name: ApiKeyStatusEnum.ACTIVE,
        icon: [FontAwesomeIconsPartooUsed.faCircleCheck],
        text: translationApiKeyLabels.active,
        value: ApiKeyStatusEnum.ACTIVE,
    },
    {
        name: ApiKeyStatusEnum.INACTIVE,
        icon: [FontAwesomeIconsPartooUsed.faCircleXmark],
        text: translationApiKeyLabels.inactive,
        value: ApiKeyStatusEnum.INACTIVE,
    },
];

const Filters = () => {
    const { t } = useTranslation();

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [status, setStatus] = useStateQueryParams(ApiKeysQueryKeys.STATUS);
    const [type, setType] = useStateQueryParams(ApiKeysQueryKeys.TYPE);

    const filters = {
        [FilterName.STATUS]: createFilterMap(status),
        [FilterName.TYPE]: createFilterMap(type),
    };

    const setFilters = (filters: PartialFilters) => {
        setStatus(concatTrueValues(filters, FilterName.STATUS));
        setType(concatTrueValues(filters, FilterName.TYPE));
    };

    const generateFilterOptions = () => {
        const optionMapping = (optionValues: Array<SelectableContentOption>) => {
            return optionValues.map(option => ({
                name: option.name,
                label: <SelectableContentFilters icon={option.icon} text={t(option.text)} />,
                value: option.value,
            }));
        };

        return {
            optionType: optionMapping(typeOptionValues),
            optionsStatus: optionMapping(statusOptionValues),
        };
    };

    const { optionType, optionsStatus } = generateFilterOptions();

    const schema: FiltersType = [
        {
            id: ApiKeysQueryKeys.TYPE,
            property: FilterName.TYPE,
            sectionName: t('api_keys_filter_type'),
            filterCategory: FilterCategory.SELECTABLE_RADIO,
            options: optionType,
        },
        {
            id: ApiKeysQueryKeys.STATUS,
            property: FilterName.STATUS,
            sectionName: t('status'),
            filterCategory: FilterCategory.SELECTABLE_RADIO,
            options: optionsStatus,
        },
    ];

    useHideIntercom(isFilterOpen);

    return (
        <DynamicFilters
            schema={schema}
            filters={filters as PartialFilters}
            onApply={setFilters}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            resetText={t('reset')}
            applyText={t('apply')}
            title={t('filters')}
            buttonText={t('filters')}
        />
    );
};

export default Filters;
