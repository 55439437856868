import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const StatusContainer = styled.div`
    margin-bottom: 32px;
`;

export const StatusTitle = styled(Text)`
    margin-bottom: 16px;
`;

export const ButtonsGroupContainer = styled.div`
    > div {
        width: 100%;

        > button {
            flex-basis: 100%;
        }
    }
`;
