import { Avatar, Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { V2BusinessData } from 'app/api/types/business';
import { BusinessUser } from 'app/api/types/user';

import { STATIC_APP_ASSETS_URL } from 'app/config';
import { JimIconCircled } from 'app/pages/settings/Chatbot/assets/jim-icon';

import {
    AvatarContainer,
    BusinessAvatar,
    BusinessMenuChoiceContainer,
    IconNameContainer,
    IconWrapper,
    MeLabelContainer,
    OverridenMenuListText,
    TextContainer,
} from './AssignConversationButton.styled';
import { UserAvatar } from '../../UserAvatar/UserAvatar';

export const UnassignedMenuChoice = () => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" alignItems="center" gap="8px">
            <Avatar image={`${STATIC_APP_ASSETS_URL}/common/unassigned_icon.svg`} size={24} />
            <Text variant="bodyMMedium">{t('messaging_assign_cta_unassigned')}</Text>
        </Stack>
    );
};

export const ChatbotMenuChoice = () => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" alignItems="center" gap="8px">
            <JimIconCircled size={24} />
            <Text variant="bodyMMedium">{t('messaging_assign_filter_chatbot')}</Text>
        </Stack>
    );
};

interface UserMenuChoiceProps {
    user: BusinessUser;
    isMe: boolean;
}

export const UserMenuChoice = ({ user, isMe }: UserMenuChoiceProps) => {
    const { t } = useTranslation();
    const userLabel = `${user.firstName} ${user.lastName}`;
    const meLabel = `(${t('messaging_assign_filter_me')})`;

    return (
        <Stack direction="row" alignItems="center" gap="8px">
            <AvatarContainer>
                <UserAvatar user={user} size={24} textSize={10} />
            </AvatarContainer>

            <MeLabelContainer>
                <OverridenMenuListText as="span" variant="bodyMMedium">
                    {userLabel}
                </OverridenMenuListText>
                {isMe && (
                    <Text as="span" variant="bodyMMedium">
                        {meLabel}
                    </Text>
                )}
            </MeLabelContainer>
        </Stack>
    );
};

interface BusinessMenuChoiceProps {
    business: V2BusinessData;
}

export const BusinessMenuChoice = ({ business }: BusinessMenuChoiceProps) => {
    const businessName = business.name;

    return (
        <BusinessMenuChoiceContainer
            direction="row"
            alignItems="center"
            gap="8px"
            justifyContent="space-between"
        >
            <IconNameContainer direction="row" alignItems="center" gap="8px">
                <BusinessAvatar>
                    <Avatar image={`${STATIC_APP_ASSETS_URL}/common/business_icon.svg`} size={24} />
                </BusinessAvatar>
                <TextContainer variant="bodyMMedium">{businessName}</TextContainer>
            </IconNameContainer>
            <IconWrapper icon={['fa-chevron-right']}></IconWrapper>
        </BusinessMenuChoiceContainer>
    );
};
