import { ReviewObjectType } from 'app/api/types/review';

import { CEOrgFeatures, useHasCEOrgFeatures } from './settings/useHasCEOrgFeatures';
import { CEPermissions, useHasCEPermissions } from './settings/useHasCEPermissions';

export const useCanReplySuggestionClientReviews = (): {
    canReplySuggestionClientReviews: Record<ReviewObjectType, boolean | undefined>;
    isLoading: boolean;
} => {
    const { orgFeatures, isLoading: orgFeatureLoading } = useHasCEOrgFeatures();
    const { permissions, isLoading: permissionsLoading } = useHasCEPermissions();

    return {
        canReplySuggestionClientReviews: {
            [ReviewObjectType.REVIEW]: permissions[CEPermissions.RMReplySuggestion] ?? false,
            [ReviewObjectType.FEEDBACK_RESULT]:
                orgFeatures[CEOrgFeatures.FBMReplySuggestion] &&
                (permissions[CEPermissions.FBMReplySuggestion] ?? false),
        },
        isLoading: orgFeatureLoading || permissionsLoading,
    };
};
