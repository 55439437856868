import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

interface AttributesIndexContainerProps {
    topFixedIndex: number;
    isSticky?: boolean;
}

export const IndexCategory = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-weight: bold;
`;

export const AttributesIndexContainer = styled.div<AttributesIndexContainerProps>`
    ${props =>
        props.isSticky
            ? css`
                  position: fixed;
                  top: ${props.topFixedIndex}px;
              `
            : css`
                  position: absolute;
              `}
    padding: 16px;
    background-color: #fafafc;
    width: 200px;
    min-height: 200px;
    border: solid 1px ${({ theme }) => theme.colors.secondary.alpha};
    border-radius: 8px;

    > a {
        display: flex;
        align-items: center;
        height: 26px;
        padding: 6px;
        border-radius: 2px;
        transition: background-color 0.1s ease;

        &.selected {
            background-color: ${({ theme }) => theme.colors.primary.alpha};

            ${IndexCategory} {
                color: ${({ theme }) => theme.colors.primary.initial};
            }
        }
    }
`;

export const IndexTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: ${HEX_COLORS.blackberry};
    margin-bottom: 8px;
`;
