import { StyledText } from './CardLine.styled';

interface Props {
    text: string;
    loading?: boolean;
}

export const CardLine = ({ text, loading = false }: Props) => (
    <StyledText variant="bodySBold" loading={loading} oneLine>
        {text}
    </StyledText>
);
