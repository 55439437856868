import { HEADER_SECTION_HEIGHT } from 'app/businessEditV2/components/BusinessEditV2Content/BusinessEditV2Content.styled';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import useIsLoadingAddress from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingAddress';
import { UseBusinessEditRef } from 'app/businessEditV2/hooks/editV2utils/useBusinessEditRefs';
import { SectionNames } from 'app/businessEditV2/utils/utils';

import { AddressSectionContainer, BottomPart } from './AddressSection.styled';
import BusinessAddressSection from './BusinessAddressSection';
import PhotoCoverSection from './PhotoCoverSection';
import PhotoLogoSection from './PhotoLogoSection';

type Props = {
    refs: { [key in SectionNames]: UseBusinessEditRef };
};

const AddressSection = ({ refs }: Props) => {
    const isLoading = useIsLoadingAddress();

    return (
        <AddressSectionContainer
            data-track="visibility_location__section_address"
            data-intercom-target="visibility_location__section_address"
        >
            {isLoading ? (
                <EditSectionLoader height={HEADER_SECTION_HEIGHT} />
            ) : (
                <>
                    <PhotoCoverSection ref={refs[SectionNames.COVER].ref} />
                    <BottomPart>
                        <PhotoLogoSection ref={refs[SectionNames.LOGO].ref} />
                        <BusinessAddressSection ref={refs[SectionNames.ADDRESS].ref} />
                    </BottomPart>
                </>
            )}
        </AddressSectionContainer>
    );
};

export default AddressSection;
