import { V2GroupData } from 'app/api/types/groups';
import { GroupData } from 'app/common/reducers/groups';

const formatGroupDataService = ({
    id,
    name,
    org_id,
    provider,
    created,
    updated,
    subgroups = [],
    parent_id,
    puller_id,
}: V2GroupData): GroupData => ({
    groupId: id,
    groupName: name,
    orgId: org_id,
    provider,
    created,
    updated,
    parentId: parent_id,
    pullerId: puller_id,
    ...(subgroups.length && {
        // @ts-ignore
        subgroups: subgroups.map(group => formatGroupDataService(group)),
    }),
});

export default formatGroupDataService;
