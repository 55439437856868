import React from 'react';

import { useBusinessModalContext } from 'app/common/components/businessModal/context/BusinessModalContext';

import useHideGroupsForAdmins from 'app/common/components/businessModal/hooks/privateHooks/useHideGroupsForAdmins';
import useOrgFilteredGroupsV2 from 'app/common/components/businessModal/hooks/privateHooks/useOrgFilteredGroupsV2';

import { hasAnyGroupWithBusinessCount } from 'app/common/hooks/queries/useGroupsV2';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';

import BusinessesContent from './BusinessesContent';
import { BusinessModalContentContainer, LeftPart, RightPart } from './BusinessModalContent.styled';
import Footer from './Footer';
import GroupsContent from './GroupsContent';

type Props = {
    closeModal: () => void;
};

const BusinessModalContent = ({ closeModal }: Props) => {
    const enableNewGroups = useNewGroups();
    const hideGroups = useHideGroupsForAdmins();

    const { variation } = useBusinessModalContext();
    const { sections } = useOrgFilteredGroupsV2(
        {},
        { enabled: variation === 'all' && enableNewGroups },
    );

    return (
        <BusinessModalContentContainer>
            {variation === 'all' && hasAnyGroupWithBusinessCount(sections) && !hideGroups && (
                <LeftPart>
                    <GroupsContent />
                </LeftPart>
            )}
            <RightPart>
                {variation === 'group-only' ? <GroupsContent /> : <BusinessesContent />}
                <Footer closeModal={closeModal} />
            </RightPart>
        </BusinessModalContentContainer>
    );
};

export default BusinessModalContent;
