import styled from '@emotion/styled';
import { HEX_COLORS, blackColorObject, convertToRgba } from '@partoohub/branding';

import Flexbox from 'app/styles/utils/flexbox';

export const ConversationLayoutStyled = styled(Flexbox)`
    overflow-y: auto;
    padding: 16px 16px 24px 16px;
    flex: 1;
`;

export const ScrollToBottomContainer = styled.div`
    position: absolute;
    bottom: 150px; // Refers to normal height of the input box.
    right: 28px;
    border-radius: 50%;
    backdrop-filter: blur(5px) brightness(1.2);
    box-shadow: 0 0 6px -2px ${convertToRgba(HEX_COLORS.black, 0.25)};
    background-color: rgba(255, 255, 255, 0.5);
`;

export const DelightConversationBanner = styled.p`
    width: 100%;
    font-weight: 600;
    text-align: center;
    color: ${blackColorObject['35']};
    margin: 20px 0;
`;
