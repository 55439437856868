import React from 'react';

import { useTranslation } from 'react-i18next';

import { LiteralRating } from 'app/api/types/review';
import { Choice } from 'app/api/types/user';
import FLAG from 'app/common/flags.json';
import useReplyTemplatesLanguages from 'app/common/hooks/queries/ReviewReplyTemplates/useReplyTemplatesLanguages';
import { LANGUAGES_MAP, getTranslationKey } from 'app/common/services/languages';

function mapLanguageToChoice(t: (text: string) => string): (language: string) => Choice {
    return function (language: string): Choice {
        const country = LANGUAGES_MAP[language]?.country;

        return {
            label: t(getTranslationKey(LANGUAGES_MAP[language])),
            value: language,
            icon: React.createElement('img', {
                src: FLAG[country],
                alt: country,
            }),
        };
    };
}

export const useAvailableLanguages = (rating: LiteralRating | undefined) => {
    const { t } = useTranslation();

    const [availableLanguages] = useReplyTemplatesLanguages(rating, {
        refetchOnMount: true,
    });

    const availableLanguagesAsOptions: Choice[] = availableLanguages
        ? availableLanguages
              .map(mapLanguageToChoice(t))
              .sort(({ label: a }, { label: b }) => a.localeCompare(b))
        : [];

    return {
        availableLanguages,
        availableLanguagesAsOptions,
    };
};
