import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    gap: 4px;
    padding: 8px 0;
    position: relative;
    cursor: pointer;
    align-items: center;
`;

type OutlinedContainerProps = {
    color: string;
};

export const OutlinedContainer = styled.div<OutlinedContainerProps>`
    display: flex;
    gap: 8px;
    padding: 8px 24px;
    height: 40px;
    font-weight: 700;
    color: ${({ color }) => color};
    border: 1px solid ${({ color }) => color};
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    align-items: center;
`;

export const MenuContainer = styled.div`
    position: absolute;
`;

export const MenuLabelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

type OverriddenMenuListProps = {
    customPosition: 'left' | 'right';
};

const getPositionStyles = ({ customPosition }: OverriddenMenuListProps) => {
    switch (customPosition) {
        case 'left':
            return 'left: 0px;';
        case 'right':
            return 'right: 0px; left: unset;';
        default:
            return 'right: unset;';
    }
};

export const MenuListWrapper = styled.div<OverriddenMenuListProps & { height: number }>`
    position: absolute;
    min-width: 100%;
    z-index: 10;
    top: ${({ height }) => (height > 0 ? `${height}px` : '33px')};
    ${props => getPositionStyles(props)}

    label {
        margin-bottom: 0;
    }
`;
