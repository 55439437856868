import {
    APP_PRODUCTS,
    BUSINESS_MESSAGE,
    PRESENCE_MANAGEMENT,
    REVIEW_MANAGEMENT,
} from 'app/common/data/productIds';
import { BUSINESS_MANAGER, GROUP_MANAGER, ORG_ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useHasHomepage = (): {
    hasHomepage: boolean;
    isLoading: boolean;
} => {
    const { data: me, isLoading } = useMe();

    const hasPM = me?.sidebar_products.includes(APP_PRODUCTS[PRESENCE_MANAGEMENT]) ?? false;
    const hasRM = me?.sidebar_products.includes(APP_PRODUCTS[REVIEW_MANAGEMENT]) ?? false;
    const hasBM = me?.sidebar_products.includes(APP_PRODUCTS[BUSINESS_MESSAGE]) ?? false;

    const hasOnlyPM = hasPM && !hasRM && !hasBM;
    const hasAtLeastOneProduct = hasPM || hasRM || hasBM;
    const hasCorrectRole = [BUSINESS_MANAGER, GROUP_MANAGER, ORG_ADMIN].includes(me?.role ?? '');

    return {
        hasHomepage: hasAtLeastOneProduct && hasCorrectRole && !hasOnlyPM,
        isLoading,
    };
};
