import { differenceInDays, differenceInYears, format, subMonths } from 'date-fns';

import { DATE_FORMAT } from 'app/utils/dates';

const MAX_DATE_RANGE = {
    from: format(new Date(2008, 0, 1), DATE_FORMAT),
    to: format(new Date(), DATE_FORMAT),
};

export const DAY = 'day';
export const WEEK = 'week';
export const MONTH = 'month';
export const YEAR = 'year';
export const ON_THE_PERIOD = 'on_the_period';
export const PERIOD = 'period';

export type DateChoices = 'day' | 'week' | 'month' | 'year';

export type ExportDateChoices = 'day' | 'week' | 'month' | 'year' | 'on_the_period';

export type ComparisonFilterType = 'period' | 'year';

// All the period values are in days
const MAX_RANGE_FOR_DAYS = 180;
const MIN_RANGE_FOR_WEEKS = 7;
const MAX_RANGE_FOR_WEEKS = 1277;
const MIN_RANGE_FOR_MONTHS = 28;
const MAX_RANGE_FOR_MONTHS = 5475;
const MIN_RANGE_FOR_YEARS = 365;

const WEEK_TRESHOLD = 14;
const MONTH_TRESHOLD = 182;
const YEAR_TRESHOLD = 730;

export type SeasonalityOption = {
    value: string;
    label: string;
    disabled: boolean;
};

export const DateRangeInDays = (dates: Record<string, any>): number => {
    const dateTo = dates.to ? new Date(dates.to) : new Date();
    const dateFrom = dates.from ? new Date(dates.from) : new Date('2008-01-01');
    return Math.abs(differenceInDays(dateTo, dateFrom)) + 1;
};

export const setDefaultDateIfEmpty = (dates: Record<string, any>) => {
    if (!dates.from && !dates.to) {
        return {
            from: subMonths(new Date(), 6),
            to: null,
        };
    }

    return dates;
};

export const recommendedSeasonality = (dates: Record<string, any> = MAX_DATE_RANGE): string => {
    const period = DateRangeInDays(setDefaultDateIfEmpty(dates));
    if (period < WEEK_TRESHOLD) return DAY;
    if (period >= WEEK_TRESHOLD && period < MONTH_TRESHOLD) return WEEK;
    if (period >= MONTH_TRESHOLD && period < YEAR_TRESHOLD) return MONTH;
    return YEAR;
};

const allowedSeasonalityOptions = (
    dates: Record<string, any> = MAX_DATE_RANGE,
): Array<SeasonalityOption> => {
    const period = DateRangeInDays(setDefaultDateIfEmpty(dates));
    return [
        {
            value: DAY,
            label: 'day_capitalized',
            disabled: period >= MAX_RANGE_FOR_DAYS,
        },
        {
            value: WEEK,
            label: 'week_capitalized',
            disabled: period < MIN_RANGE_FOR_WEEKS || period >= MAX_RANGE_FOR_WEEKS,
        },
        {
            value: MONTH,
            label: 'month_capitalized',
            disabled: period < MIN_RANGE_FOR_MONTHS || period >= MAX_RANGE_FOR_MONTHS,
        },
        {
            value: YEAR,
            label: 'year_capitalized',
            disabled: period < MIN_RANGE_FOR_YEARS,
        },
    ];
};

export const chooseBucket = (currentBucket: string, dates: Record<string, any>) => {
    // if current bucket is not allowed, set to recommended
    const allowed = allowedSeasonalityOptions(dates);
    let ind = 0;

    for (ind; ind <= allowed.length; ind += 1) {
        if (allowed[ind].value === currentBucket) {
            return allowed[ind].disabled ? recommendedSeasonality(dates) : currentBucket;
        }
    }

    return currentBucket;
};

export const datesRangeOneYear = (dates: { from: Date | null; to: Date | null }) => {
    const dateTo = dates.to ? dates.to : new Date();
    const dateFrom = dates.from ? dates.from : new Date('2008-01-01');
    return Math.abs(differenceInYears(dateTo, dateFrom)) > 0;
};

export default allowedSeasonalityOptions;
