import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';

import usePostBulkCount from 'app/presence/googlePosts/hooks/usePostBulkCount';

import {
    EditPostsContext,
    EditPostsContextType,
} from './PostCreationModal/hooks/useEditPostContext';

type Props = {
    show: boolean;
    cancel: () => void;
    confirm: () => void;
};

export default function ({ show, cancel, confirm }: Props) {
    const { t } = useTranslation();
    const { bulkId } = useContext<EditPostsContextType>(EditPostsContext);
    const { bulkCount } = usePostBulkCount(bulkId);

    return (
        <ConfirmModal
            modalFor="warning"
            trackId="post_edit_bulk"
            show={show}
            title={t('post_edit_bulk_modal_title')}
            content={t('post_edit_bulk_modal_content', { count: bulkCount })}
            confirmLabel={t('post_edit_bulk_modal_confirm')}
            onConfirm={confirm}
            onHide={cancel}
        />
    );
}
