import { useMemo } from 'react';

import {
    Filter,
    FilterCategory,
    FilterName,
    Filters,
    PartialFilters,
} from '@partoohub/modular-components';
import { FontAwesomeIconsPartooUsed, IconElement, IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { InteractionTagType } from 'app/api/types/interactionTag';
import { ReviewObjectType } from 'app/api/types/review';
import NegativeIcon from 'app/common/components/icons/NegativeIcon';
import NeutralIcon from 'app/common/components/icons/NeutralIcon';
import PositiveIcon from 'app/common/components/icons/PositiveIcon';
import { SelectableContentFilters } from 'app/common/components/SelectableContentFilters/SelectableContentFilters';
import TripAdvisorIcon from 'app/common/designSystem/components/atoms/svg/TripAdvisorIcon';
import usePartnersConnected from 'app/common/hooks/queries/usePartnersConnected';
import { useCanShowClientReviewCards } from 'app/pages/customerExperience/useCanShowClientReviewCards';
import { ReviewsQueryKeys } from 'app/reviewManagement/reviewList/hooks/useGetReviewsFilters';
import { useReviewTags } from 'app/reviewManagement/reviewList/hooks/useReviewTags';
import { WITHOUT_TAG } from 'app/states/reviews';
import { DELETED_REVIEWS, NOT_TREATED_REVIEWS, TREATED_REVIEWS } from 'app/states/reviews/filters';

import { StyledPillColor } from '../FilterButton.styled';

const makeOptions = (
    options: Array<{
        value: string;
        label: string;
        icon?: IconElement;
        exclusive?: boolean;
    }>,
    threeWide?: boolean,
) => {
    return options.map(({ value, label, icon, exclusive }) => ({
        value,
        name: value,
        label: (
            <SelectableContentFilters
                text={label}
                icon={icon}
                maxWidth={threeWide ? '78px' : '130px'}
            />
        ),
        exclusive,
    }));
};

export const useFilterSchema = () => {
    const { t } = useTranslation();
    const { hasTripAdvisor } = usePartnersConnected();
    const {
        canShowClientReviewCards: {
            [ReviewObjectType.REVIEW]: canShowReviewCards,
            [ReviewObjectType.FEEDBACK_RESULT]: canShowFeedbackResultCards,
        },
    } = useCanShowClientReviewCards();
    const [tags] = useReviewTags();

    const PLATFORM_FILTER: Filter = {
        id: ReviewsQueryKeys.PLATFORMS,
        property: FilterName.SOCIAL_MEDIA,
        sectionName: t('filter_by_platform'),
        filterCategory: FilterCategory.SELECTABLE,
        options: (() => {
            const optionsData: Array<{
                value: string;
                label: string;
                icon?: IconElement;
            }> = [
                {
                    value: 'facebook',
                    label: 'Facebook',
                    icon: [FontAwesomeIconsPartooUsed.faFacebook, IconPrefix.BRANDS],
                },
                {
                    value: 'google_my_business',
                    label: 'Google',
                    icon: [FontAwesomeIconsPartooUsed.faGoogle, IconPrefix.BRANDS],
                },
            ];
            if (hasTripAdvisor) {
                optionsData.push({
                    value: 'tripadvisor',
                    label: 'TripAdvisor',
                    icon: <TripAdvisorIcon />,
                });
            }
            return makeOptions(optionsData);
        })(),
    };

    const RATING_FILTER: Filter = {
        id: ReviewsQueryKeys.RATING,
        property: FilterName.REVIEW_TYPE,
        sectionName: t('filter_by_rating'),
        filterCategory: FilterCategory.SELECTABLE,
        options: makeOptions(
            [
                {
                    value: 'positive',
                    label: t('positive'),
                    icon: <PositiveIcon />,
                },
                {
                    value: 'neutral',
                    label: t('neutral'),
                    icon: <NeutralIcon />,
                },
                {
                    value: 'negative',
                    label: t('negative'),
                    icon: <NegativeIcon />,
                },
            ],
            true,
        ),
        numberPerLine: 3,
    };

    const STATUS_FILTER: Filter = {
        id: ReviewsQueryKeys.STATUS,
        property: FilterName.STATUS,
        sectionName: t('filter_by_status'),
        filterCategory: FilterCategory.SELECTABLE_RADIO,
        options: makeOptions([
            {
                label: t('treated_reviews_with_count'),
                icon: ['fa-message'],
                value: TREATED_REVIEWS,
            },
            {
                label: t('untreated_reviews_with_count'),
                icon: ['fa-message-check'],
                value: NOT_TREATED_REVIEWS,
            },
            {
                label: t('deleted_reviews_with_count'),
                icon: ['fa-trash'],
                value: DELETED_REVIEWS,
            },
        ]),
        numberPerLine: 3,
    };

    const COMMENTS_FILTER: Filter = {
        id: ReviewsQueryKeys.COMMENTS,
        property: FilterName.CONTENT,
        sectionName: t('filter_by_message'),
        filterCategory: FilterCategory.SELECTABLE,
        options: makeOptions([
            {
                label: t('with_message'),
                icon: ['fa-comment-lines', IconPrefix.REGULAR],
                value: 'with',
            },
            {
                label: t('without_message'),
                icon: ['fa-comment-slash', IconPrefix.REGULAR],
                value: 'without',
            },
        ]),
    };

    const TAG_FILTER: Filter = {
        id: ReviewsQueryKeys.TAG,
        property: FilterName.TAGS,
        sectionName: t('review_filter_tags_button'),
        filterCategory: FilterCategory.SELECTABLE,
        options: makeOptions([
            { label: t('review_tag_no_tag_filter'), value: `${WITHOUT_TAG}`, exclusive: true },
            ...tags.map((tag: InteractionTagType) => ({
                label: tag.label,
                icon: (
                    <div>
                        <StyledPillColor color={tag.color} />
                    </div>
                ),
                value: `${tag.id}`,
            })),
        ]),
    };

    const REVIEW_OBJECT_TYPE_FILTER: Filter = {
        id: ReviewsQueryKeys.REVIEW_OBJECT,
        property: FilterName.REVIEW_OBJECT_TYPE,
        sectionName: t('review_filter_object_type'),
        filterCategory: FilterCategory.SELECTABLE,
        options: makeOptions([
            {
                label: t('review_object_type_reviews'),
                icon: ['fa-star', IconPrefix.REGULAR],
                value: ReviewObjectType.REVIEW,
            },
            {
                label: t('review_object_type_feedback_results'),
                icon: ['fa-square-poll-horizontal', IconPrefix.REGULAR],
                value: ReviewObjectType.FEEDBACK_RESULT,
            },
        ]),
    };

    const initialSchema: Filters = useMemo(() => {
        let enabledFilters: Filters = [];

        if (canShowReviewCards) {
            enabledFilters = [STATUS_FILTER, RATING_FILTER, PLATFORM_FILTER, COMMENTS_FILTER];
            if (tags.length) {
                enabledFilters.push(TAG_FILTER);
            }
        } else {
            enabledFilters = [
                {
                    ...STATUS_FILTER,
                    options: STATUS_FILTER.options?.map(opt =>
                        opt.value !== DELETED_REVIEWS ? opt : { ...opt, disabled: true },
                    ),
                },
                RATING_FILTER,
            ];
        }

        if (canShowReviewCards && canShowFeedbackResultCards) {
            enabledFilters.unshift(REVIEW_OBJECT_TYPE_FILTER);
        }

        return enabledFilters;
    }, [hasTripAdvisor, canShowReviewCards, canShowFeedbackResultCards, tags]);

    const getVisibleSchema = (initialSchema: Filters, preFilters: PartialFilters) => {
        if (
            Array.from(preFilters.review_object_type ?? []).filter(([_, v]) => !!v).length === 1 &&
            !!preFilters.review_object_type.get(ReviewObjectType.FEEDBACK_RESULT)
        ) {
            return [
                REVIEW_OBJECT_TYPE_FILTER,
                {
                    ...STATUS_FILTER,
                    options: STATUS_FILTER.options?.map(opt =>
                        opt.value !== DELETED_REVIEWS ? opt : { ...opt, disabled: true },
                    ),
                },
                RATING_FILTER,
            ];
        }
        return initialSchema;
    };

    return { initialSchema, getVisibleSchema };
};
