import { useMutation } from 'react-query';

import { Chatbots } from 'app/api/v2/api_calls/chatbotsApiCalls';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { CHATBOT, CHATBOTS } from 'app/common/data/queryKeysConstants';
import { useChatbot } from 'app/pages/settings/Chatbot/hooks/useChatbot';
import queryClient from 'app/states/queryClient';

export const useChatbotBusinessSelectionUpdate = () => {
    const { data: chatbotData } = useChatbot();
    return useMutation(
        (businessModal: BusinessModalFiltersType) => {
            return Chatbots.patchChatbotBusinessSelection(businessModal, chatbotData?.id);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([CHATBOTS]);
                queryClient.invalidateQueries([CHATBOT, chatbotData?.id]);
            },
        },
    );
};
