import { connect } from 'react-redux';

import {
    isSubmittingSelector,
    showModalPasswordSelector,
} from 'app/states/connection/reducers/login';
import { AppState } from 'app/states/reducers';

import LoginView from './LoginView';

const mapStateToProps = ({ login }: AppState) => ({
    isSubmitting: isSubmittingSelector(login),
    showUnsafePasswordModal: showModalPasswordSelector(login),
});

const LoginViewComponent = connect(mapStateToProps)(LoginView);

export default LoginViewComponent;
