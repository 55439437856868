import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { GROUPS_V2 } from 'app/common/data/queryKeysConstants';

export default function useCreateSection(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const queryClient = useQueryClient();
    return useMutation((name: string) => api.groupsV2.createSection({ name }), {
        onSuccess: () => {
            queryClient.invalidateQueries(invalidatesAny(GROUPS_V2)).then(onSuccess);
        },
        onError,
    });
}
