import { RTL_LANGUAGES } from 'app/common/services/languages';

const handleRTL = (language: string) => {
    const html = document.documentElement;
    if (html) {
        const htmlDir = html.dir;
        if (htmlDir === 'ltr' && RTL_LANGUAGES.includes(language)) {
            html.dir = 'rtl';
        } else if (htmlDir === 'rtl' && !RTL_LANGUAGES.includes(language)) {
            html.dir = 'ltr';
        }
    }
};

export default handleRTL;
