import { useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';
import { REGEX_SETTINGS_CATEGORY_EXACT_PATH } from 'app/pages/settingsV2/helpers';
import { RouterContext } from 'app/routing/context/RouterContext';
import {
    NOT_FOUND_PATH,
    ROOT_PATH,
    SETTINGS_INTEGRATIONS_PATHNAME,
    SETTINGS_MAIN_PATHNAME,
    SETTINGS_MESSAGES_PATHNAME,
    SETTINGS_PATH,
    SETTINGS_PLATFORMS_PATHNAME,
    SETTINGS_REVIEWS_PATHNAME,
    SETTINGS_TEAM_PATHNAME,
} from 'app/routing/routeIds';

const DEFAULT_HEADER_KEY = 'settings';

export const useSettingsHeader = () => {
    const navigate = useNavigate();
    const { routes } = useContext(RouterContext);
    const { pathname } = useLocation();
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);

    const [headerTranslationKey, setHeaderTranslationKey] = useState<string>(DEFAULT_HEADER_KEY);
    const getFirstValidPath = () => {
        const validPath = routes?.find(
            path => !path.startsWith(SETTINGS_PATH) && path !== NOT_FOUND_PATH,
        );
        return validPath ?? ROOT_PATH;
    };
    const [redirectUrl, setRedirectUrl] = useState(getFirstValidPath());

    const settingsHubCategoryPath = [
        SETTINGS_MAIN_PATHNAME,
        SETTINGS_TEAM_PATHNAME,
        SETTINGS_MESSAGES_PATHNAME,
        SETTINGS_REVIEWS_PATHNAME,
        SETTINGS_PLATFORMS_PATHNAME,
        SETTINGS_INTEGRATIONS_PATHNAME,
    ];

    const startsWithSettingsSubpath = () => {
        return settingsHubCategoryPath.some(path => pathname.startsWith(path));
    };

    const matchSettingsCategoryPath = REGEX_SETTINGS_CATEGORY_EXACT_PATH.test(pathname);

    const findMatchingCategoryPath = () => {
        return settingsHubCategoryPath.find(path => pathname.startsWith(path));
    };

    const handleRedirect = () => {
        navigate(redirectUrl);
    };

    useEffect(() => {
        if (isBelowProvidedDevices) {
            if (startsWithSettingsSubpath()) {
                const values = pathname.split('/');
                matchSettingsCategoryPath
                    ? setRedirectUrl(SETTINGS_PATH)
                    : setRedirectUrl(findMatchingCategoryPath() ?? SETTINGS_PATH);
                const subPagePathname = values[values.length - 1];
                setHeaderTranslationKey(subPagePathname);
            }
        } else {
            setHeaderTranslationKey(DEFAULT_HEADER_KEY);
            if (startsWithSettingsSubpath()) {
                setRedirectUrl(SETTINGS_PATH);
            }
        }
    }, [pathname, isBelowProvidedDevices, matchSettingsCategoryPath]);

    return {
        headerKey: headerTranslationKey,
        handleRedirect,
    };
};
