import styled from '@emotion/styled';

export const LoadingElement = styled.div`
    width: 50px;
    height: 50px;
    margin: 100px auto;
    background-color: white;

    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
    background-color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const StatefulButtonWrapper = styled.span`
    .stateful_button {
        color: #ffffff;
        font-weight: 700;
        font-size: 18px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 8px;
        padding: 16px 24px;
        border: 1px solid;
        letter-spacing: inherit;
        height: 60px;
        justify-content: flex-end;
        cursor: pointer;

        .account_spinner {
            width: 20px;
            height: 20px;
            background-color: #7f8ea4;
        }

        &.stateful_button--outlined {
            background-color: #ffffff !important;
        }

        &:not(&--outlined) {
            border: 1px solid transparent !important;
        }

        &__icon {
            margin-right: 10px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 980px) {
                display: none;
            }
        }

        &__label {
            width: 100%;
        }

        &--tosave,
        &--noIcon {
            color: #0085f2;
            border-color: #0085f2;

            &.stateful_button--contained {
                color: #ffffff !important;
                background: #0085f2;
            }
        }

        &--error {
            color: #ff426e;
            border-color: #ff426e;

            &.stateful_button--contained {
                color: #ffffff !important;
                background: #ff426e;
            }
        }

        &--successful {
            color: #40dba2;
            border-color: #40dba2;

            &.stateful_button--contained {
                color: #ffffff !important;
                background: #40dba2;
            }
        }

        &--loading,
        &--disabled {
            color: #7f8ea4;
            border-color: #7f8ea4;
            cursor: not-allowed !important;

            &.stateful_button--contained {
                color: #ffffff !important;
                background: rgba(127, 142, 164, 0.25);
            }
        }

        &--modify {
            background-color: transparent;
            color: #0085f2;
            border-color: #0085f2;
        }
    }

    .stateful_button--enabled--confirm_modal {
        background: #00b3fd;

        &:hover {
            background: #0098d7;
        }

        margin: 8px 0px 8px 0px;
    }

    .stateful_button--diasabled--confirm_modal,
    .stateful_button--loading--confirm_modal {
        background: #919191;
        cursor: not-allowed;

        &:hover {
            background: #919191;
        }

        margin: 8px 0px 8px 0px;
    }

    .stateful_button--success--confirm_modal {
        background: #40dba2;

        &:hover {
            background: #40dba2;
        }

        margin: 8px 0px 8px 0px;
    }

    .stateful_button--danger--confirm_modal {
        background: #ff426e;
        margin: 8px 0px 8px 0px;
    }

    .stateful_button--tosave--confirm_modal,
    .stateful_button--noIcon--confirm_modal {
        background-color: #0085f2;
        margin: 8px 0px 8px 0px;
    }

    .stateful_button--cancel {
        background-color: #f5f7fb;
        color: #7f8ea4;
    }
`;
