import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { filterByBusinessSelector } from 'app/common/components/businessModalDeprecated/reducers';

import useNewGroups from 'app/common/hooks/queries/useNewGroups';

import generateFiltersFromBusinessModal, {
    BusinessModalFiltersType,
} from '../utils/generateFiltersFromBusinessModal';

/**
 * Generates the filters from the business modal for the BusinessSearchCompanion
 * It's parsing redux data for now
 */
export default function useBusinessModalFilters(
    isSelectionMode = false,
): BusinessModalFiltersType {
    const enableNewGroups = useNewGroups();

    //NOSONAR
    const filterByBusiness = useSelector(filterByBusinessSelector);
    //NOSONAR
    return useMemo(
        () =>
            generateFiltersFromBusinessModal(
                filterByBusiness,
                isSelectionMode,
                enableNewGroups,
            ),
        [filterByBusiness],
    );
}
