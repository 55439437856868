import { HEX_COLORS } from '@partoohub/branding';
import { Icon, IconElement, Stack } from '@partoohub/ui';

import { PublisherStateColors } from 'app/presence/management/types/Publishers.types';

interface PublisherIconProps {
    icon: IconElement;
    className?: string;
    color: PublisherStateColors;
}
export const PublisherIcon = ({ icon, color, className }: PublisherIconProps) => {
    if (color === 'primary') {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                style={{
                    backgroundColor: HEX_COLORS.primary,
                    borderRadius: '50%',
                    height: '24px',
                    width: '24px',
                }}
            >
                <Icon icon={icon} color="white" className={className} iconSize={'14px'} />
            </Stack>
        );
    }
    return <Icon icon={icon} color={color} className={className} iconSize={'24px'} />;
};
