import {
    ColorState,
    ColorStateToText,
} from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import dataLayer from 'app/common/utils/dataLayer';

export enum BusinessPage {
    EDIT = 'edit',
    CREATION = 'creation',
}

// BUSINESS EDIT V2

export const trackSelectBusinessEditSection = (
    section: SectionNames,
    state: ColorState,
) => {
    dataLayer.pushDict('select_business_edit_section', {
        business_edit_section: section,
        business_edit_section_state: ColorStateToText[state],
    });
};

export function trackSaveBusinessEditSection(
    section: SectionNames,
    state: ColorState,
    additionalCategoriesNumber?: number,
) {
    dataLayer.pushDict('save_business_edit_section', {
        business_edit_section: section,
        business_edit_section_state: ColorStateToText[state],
        ...(additionalCategoriesNumber !== undefined
            ? {
                  additional_categories_number: additionalCategoriesNumber,
              }
            : {}),
    });
}

// BUSINESS CREATION

export const trackSaveBusinessCreation = (
    additionalCategoriesNumber: number,
) => {
    dataLayer.pushDict('save_business_creation', {
        additional_categories_number: additionalCategoriesNumber,
    });
};

// CATEGORIES

export const trackClickAdditionalCategoriesField = (
    page: BusinessPage,
    mainCategoryGmbId?: string,
) => {
    dataLayer.pushDict('clic_additional_categories_field', {
        business_page: page,
        main_category_gmb_id: mainCategoryGmbId ?? '',
    });
};

export const trackClickCategoriesSuggestions = (
    page: BusinessPage,
    mainCategoryGmbId?: string,
) => {
    dataLayer.pushDict('clic_categories_suggestions', {
        business_page: page,
        main_category_gmb_id: mainCategoryGmbId ?? '',
    });
};
