import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledDate = styled.span<{ highlight: boolean }>`
    align-self: center;

    ${({ highlight }) =>
        highlight &&
        css`
            padding: 3.5px 12px;
            background: rgba(255, 144, 20, 0.12);
            border-radius: 50px;
            color: #ff9014;
        `}
`;
