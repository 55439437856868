import { Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { useFormatNumber } from 'app/common/hooks/useFormatNumber';
import { CardTitle } from 'app/pages/analytics/VisibilityAnalyticsV2/components/CardTitle/CardTitle';
import { Count } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Count/Count';
import { Delta } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Delta/Delta';
import { ErrorPlaceholder } from 'app/pages/analytics/VisibilityAnalyticsV2/components/ErrorPlaceholder/ErrorPlaceholder';
import { HelperCard } from 'app/pages/analytics/VisibilityAnalyticsV2/components/HelperCard/HelperCard';
import { HelperContent } from 'app/pages/analytics/VisibilityAnalyticsV2/components/HelperCard/HelperContent/HelperContent';
import {
    ActionsCardContainer,
    SearchKeywordsContainer,
    SuptitleBoxIcon,
    SuptitleBoxIconWrapper,
    SuptitleContainer,
    SuptitleText,
    SuptitleValue,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/Metrics/MetricsCard/ActionsCard/ActionsCard.styled';
import {
    actionsColor,
    actionsHelperContent,
} from 'app/pages/analytics/VisibilityAnalyticsV2/utils/actions';

import { StyledSubtitle } from '../../../HelperCard/HelperCard.styled';

type Props = {
    data: {
        website_clicks: string;
        call_clicks: string;
        business_direction_requests: string;
    };
    count: number;
    delta: number | null;
    hasError: unknown;
    refetch: () => void;
};

export const ActionsCard = ({ data, count, delta, hasError, refetch }: Props) => {
    const { t } = useTranslation();
    const { hex, rgba } = actionsColor;

    const ActionsCardTitle = (
        <CardTitle color={actionsColor} icon={<i className="fa fa-fas fa-arrow-pointer"></i>}>
            {t('actions')}
        </CardTitle>
    );

    const formattedWesiteClicks = useFormatNumber(parseInt(data.website_clicks ?? 0));
    const formattedCallClicks = useFormatNumber(parseInt(data.call_clicks ?? 0));
    const formattedDirectionRequests = useFormatNumber(
        parseInt(data.business_direction_requests ?? 0),
    );
    return (
        <ActionsCardContainer
            data-track="analytics_visiblity__card_actions"
            data-intercom-target="analytics_visiblity__card_actions"
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                {ActionsCardTitle}
                <HelperCard title={t('actions')}>
                    <StyledSubtitle variant="bodyMSemibold" as="span" color="secondary">
                        {t('explanation-actions')}
                    </StyledSubtitle>
                    <HelperContent content={actionsHelperContent} />
                </HelperCard>
            </Stack>
            {hasError ? (
                <ErrorPlaceholder message={t('error_page_placeholder_title_app')}>
                    <StrikeThroughButton
                        color="primary"
                        onClick={refetch}
                        text={t('try_agin')}
                        icon={<i className="fa-solid fa-redo" />}
                    />
                </ErrorPlaceholder>
            ) : (
                <>
                    <Count count={count} />
                    <Delta delta={delta} />

                    <SearchKeywordsContainer>
                        <SuptitleContainer>
                            <SuptitleBoxIconWrapper>
                                <SuptitleBoxIcon hex={hex} rgba={rgba}>
                                    <i className="fa fa-fas fa-earth-americas"></i>
                                </SuptitleBoxIcon>
                                <SuptitleText>{t('pa_actions_visit')}</SuptitleText>
                            </SuptitleBoxIconWrapper>
                            <SuptitleValue>{formattedWesiteClicks}</SuptitleValue>
                        </SuptitleContainer>
                        <SuptitleContainer>
                            <SuptitleBoxIconWrapper>
                                <SuptitleBoxIcon hex={hex} rgba={rgba}>
                                    <i className="fa fa-fas fa-phone"></i>
                                </SuptitleBoxIcon>
                                <SuptitleText>{t('pa_actions_call')}</SuptitleText>
                            </SuptitleBoxIconWrapper>
                            <SuptitleValue>{formattedCallClicks}</SuptitleValue>
                        </SuptitleContainer>
                        <SuptitleContainer>
                            <SuptitleBoxIconWrapper>
                                <SuptitleBoxIcon hex={hex} rgba={rgba}>
                                    <i className="fa fa-fas fa-diamond-turn-right"></i>
                                </SuptitleBoxIcon>
                                <SuptitleText>{t('pa_actions_directions')}</SuptitleText>
                            </SuptitleBoxIconWrapper>
                            <SuptitleValue>{formattedDirectionRequests}</SuptitleValue>
                        </SuptitleContainer>
                    </SearchKeywordsContainer>
                </>
            )}
        </ActionsCardContainer>
    );
};
