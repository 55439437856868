import useNewGroups from 'app/common/hooks/queries/useNewGroups';

import {
    BusinessModalFilters,
    getCities,
    getGroupIds,
    getOrgIds,
} from '../../context/businessModalFiltersReducer';
import useReduxFilteredBusinesses from '../useReduxFilteredBusinesses';

/**
 * Count from a local selection object, should not be used outside the Business Modal
 */
export default function useCalculateBusinessCount(
    selection: BusinessModalFilters['selection'],
): number | undefined {
    const cities = getCities(selection.filters);
    const groupIds = getGroupIds(selection.filters);
    const orgIds = getOrgIds(selection.filters);

    const enableNewGroups = useNewGroups();

    const { query } = useReduxFilteredBusinesses({
        city: cities[0] ?? null,
        group_id__in:
            !enableNewGroups && groupIds.length ? groupIds.join(',') : null,
        groups: enableNewGroups && groupIds.length ? groupIds.join(',') : null,
        org_id__in: orgIds.length ? orgIds.join(',') : null,
        query: selection.query,
    });

    if (!selection.selectAll) {
        // we just selected some businesses manually
        return selection.selectedIds.length;
    }

    // here, we selected a whole query by clicking "select all", we depend
    // on server side data queryCount that may not be fetched yet

    const queryCount = query.data?.pages?.[0]?.count;

    if (queryCount === undefined) {
        return undefined; // we don't know yet
    }

    return (
        queryCount +
        selection.selectedIds.length -
        selection.unselectedIds.length
    );
}
