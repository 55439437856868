import React from 'react';

import { useTranslation } from 'react-i18next';

import useBusinessAttributes from 'app/businessEditV2/hooks/attributes/attributesBusiness/useBusinessAttributes';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';

import { ATTRIBUTES_URL_TYPES } from 'app/businessEditV2/utils/attributes';

type Props = {
    colorState?: ColorState;
};

const AttributesSectionContent = ({ colorState = ColorState.OK }: Props) => {
    const { t } = useTranslation();
    const { data } = useBusinessAttributes();
    const businessAttributes = data?.attributes ?? [];

    const businessAttributesSetLength = businessAttributes.filter(
        attribute =>
            !ATTRIBUTES_URL_TYPES.includes(attribute.type) &&
            attribute.value !== null,
    ).length;

    switch (colorState) {
        case ColorState.PRECHECK_ERRORS:
            return <>{t('business_edit_content_correct_this_field')}</>;
        case ColorState.MISSING_WARNING:
        case ColorState.CLOSED:
        case ColorState.DISABLED:
            return <>{t('business_edit_content_add_attributes')} </>;
        case ColorState.OK:
            return (
                <>
                    <i className="fa-solid fa-align-left" />
                    <span>
                        {t('business_edit_attributes_count', {
                            count: businessAttributesSetLength,
                        })}
                    </span>
                </>
            );
        default:
            return null;
    }
};

export default AttributesSectionContent;
