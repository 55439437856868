import { useState } from 'react';

import { Button } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import useGroupsV2 from 'app/common/hooks/queries/useGroupsV2';
import FunnelOnboarding from 'app/onboarding/FunnelOnboarding';

import { SETTINGS_TEAM_GROUPS_PATHNAME } from 'app/routing/routeIds';

const ManageGroupsButton = () => {
    const { t } = useTranslation();
    const { sections } = useGroupsV2();
    const [showFunnel, setShowFunnel] = useState(false);

    if (sections === undefined) {
        return null;
    }

    return (
        <>
            <NavLink to={SETTINGS_TEAM_GROUPS_PATHNAME} style={{ textDecoration: 'none' }}>
                <Button
                    shape="cube"
                    dataTrackId="business_list_manage_groups"
                    icon={['fa-folder-gear']}
                    onClick={() => undefined}
                    appearance="outlined"
                    variant="secondary"
                >
                    {sections.length > 0
                        ? t('business_list_manage_group')
                        : t('business_list_create_group')}
                </Button>
            </NavLink>

            <FunnelOnboarding showFunnel={showFunnel} setShowFunnel={setShowFunnel} />
        </>
    );
};

export default ManageGroupsButton;
