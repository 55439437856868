export const getMissingMonthsFromBoundaries = (availableDates: Date[]): Date[] => {
    const availableMonthsSet = new Set(
        availableDates.map(d => `${d.getFullYear()}-${d.getMonth()}`),
    );
    const allTimestamps = availableDates.map(date => date.getTime());
    const firstDate = new Date(Math.min(...allTimestamps));
    const lastDate = new Date(Math.max(...allTimestamps));
    const missingMonths = [];
    const currentDate = firstDate;
    while (currentDate < lastDate) {
        const month = currentDate.getMonth();
        if (!availableMonthsSet.has(`${currentDate.getFullYear()}-${month}`)) {
            missingMonths.push(new Date(currentDate));
        }
        currentDate.setMonth(month + 1);
    }
    return missingMonths;
};
