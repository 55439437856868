import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { IllustrationContainer, ModalContainer } from './MaxBusinessReachedModal.styled';

type Props = {
    closeModal: () => void;
};
const MaxBusinessReachedModal = ({ closeModal }: Props) => {
    const { t } = useTranslation();

    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.AddLocation,
    };

    return (
        <ModalContainer>
            <Dialog
                dataTrackId="max_businesses_reached_modal"
                header={
                    <IllustrationContainer>
                        <Illustration
                            dataTrackId="confirm_email__illustration"
                            illustration={illustrationElement}
                            width="320"
                            height="140"
                        />
                    </IllustrationContainer>
                }
                title={t('onboarding_add_location_page_title')}
                subTitle={t('max_businesses_limit_reached_error')}
            >
                <Stack direction="row">
                    <Button
                        dataTrackId="max_businesses_reached_modal_cancel"
                        variant="primary"
                        size="large"
                        onClick={closeModal}
                        appearance="contained"
                        full
                    >
                        {t('message_close_conv_button')}
                    </Button>
                </Stack>
            </Dialog>
        </ModalContainer>
    );
};

export default MaxBusinessReachedModal;
