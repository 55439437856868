import React, { useContext, useEffect, useMemo, useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix, LeftElementType, TextInput } from '@partoohub/ui';
import { TFunction } from 'i18next';
import { differenceBy, union } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { useInfiniteQuery } from 'react-query';

import { useSelector } from 'react-redux';

import { V2FormattedLocationData } from 'app/api/types/account';
import { Choice } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import AccessGroupModal from 'app/common/components/AccessGroupModal/AccessGroupModal';
import FunnelContext from 'app/common/components/funnel/FunnelContext';
import { SubTitle, Title } from 'app/common/components/funnel/FunnelModal.styled';
import { useTaskContext } from 'app/common/contexts/taskListener';
import { BUSINESS_LOCATIONS } from 'app/common/data/queryKeysConstants';
import { GROUP_MANAGER } from 'app/common/data/roles';
import AsyncSingleSelect from 'app/common/designSystem/components/molecules/AsyncSingleSelect/AsyncSingleSelect';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';

import useDebounce from 'app/common/hooks/useDebounce';
import { isAdminShadowUserSelector } from 'app/common/reducers/me';
import SelectionPageResults, {
    convertLocationsToCamelCase,
} from 'app/onboarding/SelectionPage/components/SelectionPageResults/SelectionPageResults';
import { convertGmbAccountToChoice } from 'app/onboarding/SelectionPage/utils/convertGmbAccountToChoice';

import { createBusinessesFromLocations } from 'app/onboarding/SummaryPage/SummaryPage';

import SelectionPageContext from './SelectionPage.context';
import {
    CheckField,
    FiltersContainer,
    FullButton,
    FullButtonWrapper,
    IconButtonPageContainer,
    InputFieldContainer,
    ParametersWrapper,
    Scroller,
    SelectEraseButton,
    SelectFieldContainer,
    SelectionContainer,
    SelectionContentContainer,
} from './SelectionPage.styled';
import { convertAccountToChoice } from './utils/convertAccountToChoice';

const accountStatusOptions = (t: TFunction) => {
    const availableStatus = [
        'verified',
        'unverified',
        'pending',
        'in_review',
        'has_duplicate',
        'disabled',
        'suspended',
    ];

    return availableStatus.map(status => ({
        label: t(`google_verification_location_status_${status}`),
        value: status,
    }));
};

const SelectionPage = () => {
    const { t } = useTranslation();

    // Handle accesses for GM on new groups
    const { data: me } = useMe();
    const enableNewGroups = useNewGroups();
    const displayGroupsSelect = enableNewGroups && me?.role === GROUP_MANAGER;
    const [showGroupModal, setShowGroupModal] = useState<boolean>(false);

    const funnel = useContext(FunnelContext);
    const { storage, setStorage } = useTaskContext();

    const isShadowAdmin = useSelector(isAdminShadowUserSelector);
    const [selectedLocations, setSelectedLocations] = useState<Array<V2FormattedLocationData>>([]);
    const [account, setAccount] = useState<Choice | undefined>(undefined);
    const [accountOptions, setAccountOptions] = useState<Array<Choice>>([]);
    const [gmbAccount, setGmbAccount] = useState<Choice | undefined>(undefined);
    const [gmbAccountOptions, setGmbAccountOptions] = useState<Array<Choice>>([]);
    const [searchQuery, setSearchQuery] = useState<string | undefined>('');
    const debouncedSearchQuery = useDebounce(searchQuery, 500);
    const [accountStatus, setAccountStatus] = useState<Choice | undefined>(undefined);
    const [disabledLocations, setDisabledLocations] = useState<Array<V2FormattedLocationData>>([]);

    const hasAnyFilterActivated = !!(searchQuery || accountStatus || account || gmbAccount);

    const locationsByPage = 30;
    const locationQuery = useInfiniteQuery(
        [BUSINESS_LOCATIONS, account, accountStatus, gmbAccount, debouncedSearchQuery],
        ({ pageParam = 1 }) =>
            api.account.fuzzySearchLocations(
                'gmb',
                debouncedSearchQuery ?? '',
                pageParam,
                account?.value,
                gmbAccount?.value,
                undefined,
                true,
                accountStatus?.value === undefined ? undefined : [accountStatus.value],
            ),
        {
            cacheTime: 0,
            getNextPageParam: lastPage =>
                lastPage.locations.length == locationsByPage ? lastPage.page + 1 : undefined,
        },
    );

    let locations: Array<V2FormattedLocationData> = [];
    let adminOwnedLocations = false;
    if (locationQuery.data) {
        locationQuery.data.pages.forEach(page => {
            locations = union(locations, convertLocationsToCamelCase(page.locations));
        });
        if (locations) {
            adminOwnedLocations = locationQuery.data.pages[0].admin_owned_poa;
        }
    }

    useEffect(() => {
        loadAccounts('').then(result => setAccountOptions(result || []));
    }, []);

    useEffect(() => {
        loadGmbAccounts('').then(result => setGmbAccountOptions(result || []));
    }, [account]);

    useEffect(() => {
        const filteredDisabledLocations = locations.filter(location =>
            selectedLocations.some(
                selectedLoc =>
                    selectedLoc.storeCode === location.storeCode &&
                    selectedLoc.id !== location.id &&
                    !!selectedLoc.storeCode,
            ),
        );
        setDisabledLocations(filteredDisabledLocations);
    }, [selectedLocations.length, locations.length]);

    const loadAccounts = async (value: string) => {
        const accountQuery = await api.account.searchAccount(
            'GOOGLE_MY_BUSINESS',
            value,
            isShadowAdmin,
        );
        const result = accountQuery.accounts
            .map(convertAccountToChoice)
            .filter(account => account.label);
        setAccountOptions(result);
        return result;
    };

    const loadGmbAccounts = async (value: string) => {
        if (account?.value) {
            const gmbAccountQuery = await api.account.searchGmbAccounts(
                account?.value,
                value,
                true,
                isShadowAdmin,
            );
            return gmbAccountQuery.gmb_accounts.map(convertGmbAccountToChoice);
        }
    };

    const importBusinessesFromLocations = (groups?: Array<number>) => {
        // detect locations without address
        const locationsWithoutAddress = selectedLocations.filter(
            location => !location.addressDetails,
        );

        const mixedFiltersLocations =
            selectedLocations.some(location => location.hasFilterAppended) &&
            selectedLocations.some(location => !location.hasFilterAppended);

        if (locationsWithoutAddress.length > 0) {
            funnel.store = {
                ...funnel.store,
                selectedLocations,
                editedLocations: [],
            };

            setSelectedLocations([]);
            editSelectAll();
            funnel.navigate('start_add_address_page');
        } else if (
            mixedFiltersLocations ||
            (hasAnyFilterActivated && selectedLocations.length > 0)
        ) {
            funnel.store = {
                ...funnel.store,
                selectedLocations,
            };
            setSelectedLocations([]);
            editSelectAll();
            funnel.navigate('summary_page_direct_from_selection_page');
        } else {
            createBusinessesFromLocations({
                t,
                toImportLocations: selectedLocations.map(location => ({
                    id: location.id,
                })),
                groups,
            }).then(businessIds => setStorage({ ...storage, businessIds }));

            setSelectedLocations([]);
            editSelectAll();
            funnel.navigate('congratulations_page');
        }
    };

    const areAllLocationsSelected = () => {
        return locations.every(location =>
            selectedLocations.some(selected => selected.id === location.id),
        );
    };

    const editSelectAll = () => {
        let newLocations = differenceBy(locations, selectedLocations, 'id');
        if (hasAnyFilterActivated) {
            newLocations = newLocations.map(location => ({
                ...location,
                hasFilterAppended: true,
            }));
        }

        const filteredLocations = [...selectedLocations, ...newLocations].reduce(
            (acc, location) => {
                if (!acc.some(loc => loc.storeCode === location.storeCode)) {
                    acc.push(location);
                }
                return acc;
            },
            [] as V2FormattedLocationData[],
        );

        setSelectedLocations(filteredLocations);
    };

    const selectionPageContext = useMemo(
        () => ({
            locations,
            selectedLocations,
            setSelectedLocations,
            locationQuery,
            adminOwnedLocations,
            disabledLocations,
        }),
        [locations, selectedLocations, locationQuery, adminOwnedLocations, disabledLocations],
    );

    const onImportClick = () => {
        if (displayGroupsSelect) {
            setShowGroupModal(true);
        } else {
            importSelectedLocations();
        }
    };

    const importSelectedLocations = (groups?: Array<number>) => {
        // Just in case something goes wrong
        if (displayGroupsSelect && !groups?.length) {
            return;
        }

        importBusinessesFromLocations(groups);
    };

    const handleSearchReset = () => setSearchQuery('');

    const handleActionIcon = () => {
        setSelectedLocations([]);
        setGmbAccount(undefined);
        setSearchQuery('');
        setAccount(undefined);
        setAccountStatus(undefined);
    };

    return (
        <>
            <SelectionContainer id="react-content-12">
                <SelectionContentContainer>
                    <Scroller
                        hasMore={locationQuery.hasNextPage}
                        loadMore={() => locationQuery.fetchNextPage()}
                        threshold={500}
                        useWindow={false}
                        getScrollParent={() => document.getElementById('react-content-12')}
                    >
                        <Title>{t('onboarding_selection_page_title')}</Title>
                        <SubTitle>{t('onboarding_selection_page_subtitle')}</SubTitle>
                        <ParametersWrapper>
                            <FiltersContainer>
                                <InputFieldContainer>
                                    <TextInput
                                        dataTrackId="onboarding_selection_page_input"
                                        label={t('search')}
                                        value={searchQuery}
                                        onChange={setSearchQuery}
                                        leftElement={[FontAwesomeIconsPartooUsed.faSearch]}
                                        leftElementType={LeftElementType.Icon}
                                        isReset
                                        onReset={handleSearchReset}
                                    />
                                </InputFieldContainer>
                                <SelectFieldContainer>
                                    <AsyncSingleSelect
                                        placeholder={t('google_status')}
                                        options={accountStatusOptions(t)}
                                        onChange={(option: Choice) => {
                                            setAccountStatus(option);
                                        }}
                                        selectedValue={accountStatus}
                                        titleOnOptions
                                        displaySelectedOptionOneLine
                                    />
                                </SelectFieldContainer>
                                <SelectFieldContainer>
                                    <AsyncSingleSelect
                                        placeholder={t('google_account')}
                                        options={accountOptions}
                                        onChange={(option: Choice) => {
                                            setAccount(option);
                                            setGmbAccount(undefined);
                                        }}
                                        selectedValue={account}
                                        loadMore={loadAccounts}
                                        titleOnOptions
                                        displaySelectedOptionOneLine
                                    />
                                </SelectFieldContainer>
                                {account?.value !== undefined && (
                                    <SelectFieldContainer>
                                        <AsyncSingleSelect
                                            placeholder={t('google_group')}
                                            options={gmbAccountOptions}
                                            onChange={(option: Choice) => {
                                                setGmbAccount(option);
                                            }}
                                            selectedValue={gmbAccount}
                                            loadMore={loadGmbAccounts}
                                            disabled={account?.value === undefined}
                                            titleOnOptions
                                            displaySelectedOptionOneLine
                                        />
                                    </SelectFieldContainer>
                                )}
                            </FiltersContainer>
                            <CheckField>
                                <SelectEraseButton
                                    dataTrackId="select_all_onboarding"
                                    size="large"
                                    variant="secondary"
                                    appearance="outlined"
                                    shape="cube"
                                    onClick={editSelectAll}
                                    disabled={areAllLocationsSelected()}
                                >
                                    {t('onboarding_selection_page_select_all_visible')}
                                </SelectEraseButton>
                                {selectedLocations.length > 0 && (
                                    <IconButtonPageContainer
                                        size="small"
                                        appearance="contained"
                                        variant="danger"
                                        onClick={handleActionIcon}
                                        icon={[
                                            FontAwesomeIconsPartooUsed.faTrash,
                                            IconPrefix.REGULAR,
                                        ]}
                                        dataTrackId=""
                                    />
                                )}
                            </CheckField>
                        </ParametersWrapper>
                        <SelectionPageContext.Provider value={selectionPageContext}>
                            <SelectionPageResults hasAnyFilterActivated={hasAnyFilterActivated} />
                        </SelectionPageContext.Provider>
                    </Scroller>
                </SelectionContentContainer>
            </SelectionContainer>

            {selectedLocations.length > 0 && (
                <FullButtonWrapper>
                    <FullButton
                        dataTrackId="import_locations_onboarding"
                        onClick={onImportClick}
                        icon={[FontAwesomeIconsPartooUsed.faArrowRight, IconPrefix.SOLID]}
                        shape="cube"
                        variant="primary"
                        size="large"
                    >
                        {displayGroupsSelect
                            ? t('create_user_assign_group_field')
                            : t('onboarding_selection_page_import', {
                                  count: selectedLocations.length,
                              })}
                    </FullButton>
                </FullButtonWrapper>
            )}

            <AccessGroupModal
                opened={showGroupModal}
                closeModal={() => setShowGroupModal(false)}
                value={[]}
                onChange={importSelectedLocations}
            />
        </>
    );
};

export default SelectionPage;
