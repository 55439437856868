import { Button, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import PageHeader from 'app/common/designSystem/components/molecules/PageHeader';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import dataLayer from 'app/common/utils/dataLayer';
import { DeviceType, useDeviceType } from 'app/hooks';
import { useHasPostPermission } from 'app/presence/googlePosts/hooks/useHasPostPermission';
import {
    ERROR_GOOGLE_POSTS,
    EXPIRED_POSTS,
    GooglePostState,
    PENDING_GOOGLE_POSTS,
    PUBLISHED_GOOGLE_POSTS,
    TO_CONFIGURE_POSTS,
} from 'app/presence/googlePosts/variables/googlePostStatus';

import { MobileHeader } from './MobileHeader';
import { StyledTitleBar } from './TitleBar.styled';

export const getPageTitle = (state: GooglePostState) => {
    switch (state) {
        case PENDING_GOOGLE_POSTS:
            return 'google_post_title_pending';

        case PUBLISHED_GOOGLE_POSTS:
            return 'google_post_title_published';

        case TO_CONFIGURE_POSTS:
            return 'google_post_title_to_configure';

        case ERROR_GOOGLE_POSTS:
            return 'google_post_title_error';

        case EXPIRED_POSTS:
            return 'google_post_title_expired';

        default:
            return 'google_post_title_all';
    }
};

type Props = {
    onCreationButtonClick: () => void;
};

export const TitleBar = ({ onCreationButtonClick }: Props) => {
    const { t } = useTranslation();
    const isDesktop = useDeviceType().device === DeviceType.Desktop;

    const [statusParam] = useStateQueryParams('status');

    const title = `${t(getPageTitle(statusParam as GooglePostState))}`;

    const hasPostPermission = useHasPostPermission();

    const pageButtons = !hasPostPermission
        ? []
        : [
              <Button
                  dataTrackId="post_list_create_post"
                  shape="cube"
                  variant="primary"
                  icon={['fa-plus', IconPrefix.SOLID]}
                  onClick={() => {
                      dataLayer.pushDict('click_create_post');
                      onCreationButtonClick();
                  }}
                  key="create-post-button"
              >
                  {t('create_new_post')}
              </Button>,
          ];

    return (
        <StyledTitleBar>
            {!isDesktop && <MobileHeader />}
            <PageHeader title={title} pageButtons={pageButtons} />
        </StyledTitleBar>
    );
};
