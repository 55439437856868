import React from 'react';

import { ColoredPath } from 'app/common/components/icons/Sidebar/ColoredPath.styled';

type Props = {
    isActive?: boolean;
};

const AnalyticsIconSidebar = React.forwardRef<SVGSVGElement, Props>(
    ({ isActive = false }, ref) => (
        <svg
            ref={ref}
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            width="40px"
            height="40px"
        >
            {isActive ? (
                <>
                    <ColoredPath d="M22.0416 31.9996C19.8961 31.9996 17.8527 31.328 16.1323 30.0575L15.567 29.64L21.3384 21.8165V12.0827H22.0416C27.5327 12.0827 32 16.55 32 22.0412C32 27.5323 27.5327 31.9996 22.0416 31.9996Z" />
                    <ColoredPath d="M12.6152 29.0305L12.0493 28.6128C10.8211 27.7061 9.80261 26.5115 9.10398 25.1581C8.38173 23.7588 7.99998 22.1814 7.99998 20.5964C7.99998 18.2095 8.85732 15.9009 10.414 14.0961L10.8732 13.5637L18.911 20.4961L12.6152 29.0305ZM11.0417 15.5659C9.98275 17.0211 9.40623 18.7823 9.40623 20.5964C9.40623 23.0646 10.4899 25.4247 12.3359 27.0401L17.0058 20.7099L11.0417 15.5659Z" />
                    <ColoredPath d="M19.2223 17.8336L11.7771 11.4124L12.2362 10.88C13.8144 9.04972 16.1044 8 18.5192 8H19.2223V17.8336Z" />
                </>
            ) : (
                <>
                    <path
                        d="M22.0416 31.9996C19.8961 31.9996 17.8527 31.328 16.1323 30.0575L15.567 29.64L21.3384 21.8165V12.0827H22.0416C27.5327 12.0827 32 16.55 32 22.0412C32 27.5323 27.5327 31.9996 22.0416 31.9996ZM17.5499 29.3209C18.8965 30.1554 20.4356 30.5934 22.0416 30.5934C26.7572 30.5934 30.5937 26.7568 30.5937 22.0412C30.5937 17.5621 27.1329 13.8762 22.7447 13.5176V22.2791L17.5499 29.3209Z"
                        fill="white"
                    />
                    <path
                        d="M12.6152 29.0305L12.0493 28.6128C10.8211 27.7061 9.80261 26.5115 9.10398 25.1581C8.38173 23.7588 7.99998 22.1814 7.99998 20.5964C7.99998 18.2095 8.85732 15.9009 10.414 14.0961L10.8732 13.5637L18.911 20.4961L12.6152 29.0305ZM11.0417 15.5659C9.98275 17.0211 9.40623 18.7823 9.40623 20.5964C9.40623 23.0646 10.4899 25.4247 12.3359 27.0401L17.0058 20.7099L11.0417 15.5659Z"
                        fill="white"
                    />
                    <path
                        d="M19.2223 17.8336L11.7771 11.4124L12.2362 10.88C13.8144 9.04972 16.1044 8 18.5192 8H19.2223V17.8336ZM13.7874 11.2892L17.816 14.7638V9.44216C16.3065 9.5967 14.8908 10.2457 13.7874 11.2892Z"
                        fill="white"
                    />
                </>
            )}
        </svg>
    ),
);

export default AnalyticsIconSidebar;
