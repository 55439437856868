import styled from '@emotion/styled';

export const FilterContainer = styled.div`
    position: relative;

    > div {
        min-width: 150px;
    }
`;

export const MenuContainer = styled.div`
    position: absolute;
    width: 100%;
`;
