import styled from '@emotion/styled';

export const InvoiceContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const MobileInvoiceContainer = styled(InvoiceContainer)`
    div[role='grid'] > div[role='row']:first-of-type {
        min-height: 0;
        padding: 0;
        background: none;
    }
`;
