import { useContext } from 'react';

import { Avatar } from '@partoohub/ui';

import { ReviewObjectType } from 'app/api/types/review';
import { STATIC_APP_ASSETS_URL, STATIC_BASE_URL } from 'app/config';

import { REVIEW_CARD_AVATAR_WIDTH } from './common';
import { ReviewCardContext } from '../../ReviewCard';

export const FeedbackResultCardAvatar = ({ size = REVIEW_CARD_AVATAR_WIDTH }) => (
    <Avatar
        image={`${STATIC_APP_ASSETS_URL}/reviews/feedback-result-logo.png`}
        size={size}
        border={false}
    />
);

export const ReviewCardAvatar = () => {
    const { review } = useContext(ReviewCardContext);

    if (review.reviewObjectType === ReviewObjectType.FEEDBACK_RESULT) {
        return <FeedbackResultCardAvatar />;
    }
    return (
        <Avatar
            image={`${STATIC_BASE_URL}/images/app/app_review/${review.partner}.png`}
            size={REVIEW_CARD_AVATAR_WIDTH}
            border={false}
        />
    );
};
