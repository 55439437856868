import { useEffect } from 'react';

import { AccountPartnerName, V2FormattedLocationData } from 'app/api/types/account';
import LoadingLocationCard from 'app/businessConnection/components/commonOld/LoadingLocationCard';
import LocationSelectionCard from 'app/businessConnection/components/modals/ConnectionModal/LocationCardSelection';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';

import { LocationListContainer } from './LocationList.styled';

type Props = {
    locations: Array<V2FormattedLocationData>;
    partnerName: AccountPartnerName;
    isFetching: boolean;
    error: Record<string, any>;
};

const LocationsList = ({ locations, partnerName, isFetching, error }: Props) => {
    const { selectedLocationId, setSelectedLocationId, setOwningBusinessId } =
        usePartnerConnectionContext();
    const editLocationSelected = (locationId?: string, owningBusinessId?: string) => {
        if (selectedLocationId === locationId) {
            setSelectedLocationId('');
            setOwningBusinessId('');
        } else {
            setSelectedLocationId(locationId || '');
            setOwningBusinessId(owningBusinessId || '');
        }
    };

    useEffect(() => {
        if (!isFetching && locations) {
            const locationIds = locations.map(location => location.id);
            if (!locationIds.includes(selectedLocationId)) {
                setSelectedLocationId('');
                setOwningBusinessId('');
            }
        }
    }, [isFetching]);

    return (
        <LocationListContainer>
            <div
                className={`
                    fullscreen_connection_modal__locations_list
                    fullscreen_connection_modal__locations_list${
                        locations && locations.length > 1 ? '--grid' : ''
                    }
                `}
            >
                {locations.map((location: V2FormattedLocationData, index: number) => (
                    <LocationSelectionCard
                        isSelected={selectedLocationId === location.id}
                        partnerName={partnerName}
                        locationStatus={location.partnerConnection.status}
                        owningBusinessId={location.partnerConnection.owningBusinessId}
                        editLocationsSelected={editLocationSelected}
                        hasError={error[location.id]}
                        location={location}
                        key={location.id}
                        tooltipPlacement={index % 2 === 0 ? 'right' : 'left'}
                    />
                ))}
            </div>
            {isFetching && locations.length < 3 && <LoadingLocationCard />}
        </LocationListContainer>
    );
};

export default LocationsList;
