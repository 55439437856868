import { useRef } from 'react';

import { DownloadLocationOAuthAccountTaskInfo } from 'app/api/types/task/download_task';
import { TaskStatus } from 'app/api/types/task/task';

type UseRefreshOAuthAccountProgressArgs = {
    percentageProgress?: number;
    accountsProgress?: number;
    locationsProgress?: number;
    instagramAccountsProgress?: number;
    isLoading?: boolean;
    errorMessage?: string | null;
};

export const useRefreshOAuthAccountProgress = (
    oauthAccountInfo?: DownloadLocationOAuthAccountTaskInfo,
) => {
    const percentageProgress = useRef(0);
    const accountsProgress = useRef(0);
    const accountsTotal = useRef<number | undefined>(undefined);
    const locationsProgress = useRef(0);
    const instagramAccountsProgress = useRef(0);

    // isLoading is a tristate
    //     undefined => not started
    //     false => finished
    //     true => loading
    const isLoading = useRef<boolean | undefined>(undefined);
    const errorMessage = useRef<string | null>(null);

    // setter is a simple function offering the possibility to edit statuses outside this hook,
    // sometimes needed to reinit the hook statuses
    const setCurrentProgress = (status: UseRefreshOAuthAccountProgressArgs) => {
        if ('percentageProgress' in status)
            percentageProgress.current = status.percentageProgress || 0;
        if ('accountsProgress' in status) accountsProgress.current = status.accountsProgress || 0;
        if ('locationsProgress' in status)
            locationsProgress.current = status.locationsProgress || 0;
        if ('instagramAccountsProgress' in status)
            instagramAccountsProgress.current = status.instagramAccountsProgress || 0;
        if ('isLoading' in status) isLoading.current = status.isLoading;
        if ('errorMessage' in status) errorMessage.current = status.errorMessage || null;
    };

    const getPercentageProgress = () => {
        let currentProgress: number;

        if (!oauthAccountInfo) currentProgress = 1;
        else {
            const { accountsProgress, accountsTotal } = oauthAccountInfo;

            // case we do not have locations on this oauth account
            if (accountsTotal === 0) currentProgress = 1;
            else if (accountsProgress && accountsTotal) {
                currentProgress = accountsProgress / accountsTotal;
            } else {
                currentProgress = accountsProgress || 0;
            }
        }

        return currentProgress ? Math.round(currentProgress * 100) : 0;
    };

    // we don't want to update anything if we had an error on any tasks :
    // whether it happened on accounts or locations
    if (!errorMessage.current && oauthAccountInfo) {
        const hasError = oauthAccountInfo.status === TaskStatus.FAILURE;
        setCurrentProgress({
            errorMessage: hasError ? 'server responded with error !' : null,
        });

        if (oauthAccountInfo.accountsTotal !== undefined) {
            accountsTotal.current = oauthAccountInfo.accountsTotal;
        }

        const percentageCurrentProgress = getPercentageProgress();
        const accountsCurrentProgress = oauthAccountInfo?.accountsProgress;
        const locationsCurrentProgress = oauthAccountInfo?.locationsProgress;
        const instagramAccountsCurrentProgress = oauthAccountInfo?.instagramAccountsProgress;
        const isPending = oauthAccountInfo.status === TaskStatus.PENDING;

        setCurrentProgress({
            percentageProgress:
                !hasError && percentageCurrentProgress ? percentageCurrentProgress : 0,
            accountsProgress: !hasError && accountsCurrentProgress ? accountsCurrentProgress : 0,
            locationsProgress: !hasError && locationsCurrentProgress ? locationsCurrentProgress : 0,
            instagramAccountsProgress:
                !hasError && instagramAccountsCurrentProgress
                    ? instagramAccountsCurrentProgress
                    : 0,
            isLoading: !hasError && isPending,
        });
    }

    return {
        isLoading: isLoading.current,
        percentageProgress: percentageProgress.current,
        accountsProgress: accountsProgress.current,
        accountsTotal: accountsTotal.current,
        locationsProgress: locationsProgress.current,
        instagramAccountsProgress: instagramAccountsProgress.current,
        errorMessage: errorMessage.current,
        setRefreshOAuthAccountProgress: setCurrentProgress,
    };
};
