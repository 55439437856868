import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { FiltersEnum } from '../constant/filtersEnum';

const DEFAULT_PERIOD = 'month';

export const usePeriodFilter = (): [period: string, setPeriod: (period: string) => void] => {
    const [period, setPeriod] = useStateQueryParams(FiltersEnum.PERIOD);

    return [period || DEFAULT_PERIOD, setPeriod];
};
