import styled from '@emotion/styled';
import { Icon, Text } from '@partoohub/ui';

import Carousel from 'app/common/components/Carousel';
import { CarouselItem } from 'app/common/components/Carousel/Carousel.styled';

export const TipsHeader = styled.div`
    width: 100%;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const TipsArrows = styled.div`
    display: flex;
    flex-direction: row;

    padding: 0px 8px;
    justify-content: flex-end;
    gap: 8px;
`;

export const TipsArrow = styled(Icon)`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const TipsContainer = styled.div`
    @media (max-width: 768px) {
        display: none;
    }

    flex-shrink: 1;
    min-width: 200px;
    max-width: 307px;
    flex-grow: 1;
    flex-basis: 300px;
    display: flex;
    flex-direction: column;
    min-height: 500px;
    align-items: flex-start;
    border-radius: 16px;
    background: linear-gradient(
        108deg,
        #e6f1f7 0%,
        #f3f3fc 23.96%,
        #f6f4f1 50%,
        #f6f2ec 73.96%,
        #f6f8fc 100%
    );
`;

export const TipsCarousel = styled(Carousel)`
    width: 100%;
    flex-grow: 1;
`;

export const TipsCarouselItem = styled(CarouselItem)`
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
`;

export const TipsImage = styled.img`
    height: 220px;
    width: calc(100% + 40px);
    max-width: 314px;
    margin: -15px 0 -40px 0;
`;

export const TipsTitle = styled(Text)`
    text-align: center;
`;

export const LinkWrapper = styled.a`
    margin-top: 16px;
    width: 100%;
    padding: 0 20px 20px 20px;
`;
