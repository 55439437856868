const AUTO_CROPPED_THRESHOLD = 0.05;
// If the aspect ratio varied more than this value (+/- 5%) after cropping,
// then the image will be considered cropped automatically

export function autoCrop(image: HTMLImageElement, aspectRatio: number) {
    const inputWidth = image.naturalWidth;
    const inputHeight = image.naturalHeight;
    const inputImageAspectRatio = inputWidth / inputHeight;

    let outputWidth = inputWidth;
    let outputHeight = inputHeight;
    if (inputImageAspectRatio > aspectRatio) {
        outputWidth = inputHeight * aspectRatio;
    } else if (inputImageAspectRatio < aspectRatio) {
        outputHeight = inputWidth / aspectRatio;
    }

    // calculate the position to draw the image at
    const outputX = (outputWidth - inputWidth) * 0.5;
    const outputY = (outputHeight - inputHeight) * 0.5;

    const outputImage = document.createElement('canvas');
    outputImage.width = outputWidth;
    outputImage.height = outputHeight;

    const ctx = outputImage.getContext('2d');
    ctx!.drawImage(image, outputX, outputY);

    return {
        canvas: outputImage,
        autoCropped:
            Math.abs(aspectRatio / inputImageAspectRatio - 1) >
            AUTO_CROPPED_THRESHOLD,
    };
}
