import { FC, SVGProps } from 'react';

export const PartooProviderLogo: FC<SVGProps<SVGSVGElement>> = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 82.76 85.82"
        {...props}
    >
        <title>Logo-Partoo-2020</title>
        <path
            d="M26.73,23.23A31.4,31.4,0,0,1,51.28,11.44a32.28,32.28,0,0,1,4.75.35A31.47,31.47,0,0,0,2.15,42.91,31.51,31.51,0,0,1,26.73,23.23Z"
            style={{
                opacity: '0.25',
            }}
            fill="#fff"
        />
        <path
            d="M26.73,62.59A31.51,31.51,0,0,1,2.15,42.91,31.47,31.47,0,0,0,56,74a32.28,32.28,0,0,1-4.75.36A31.4,31.4,0,0,1,26.73,62.59Z"
            style={{
                opacity: '0.25',
            }}
            fill="#fff"
        />
        <path
            d="M26.73,62.59a31.44,31.44,0,0,1,0-39.36A31.51,31.51,0,0,0,2.15,42.91,31.51,31.51,0,0,0,26.73,62.59Z"
            style={{
                opacity: '0.5',
            }}
            fill="#fff"
        />
        <path
            d="M82.76,42.91A31.49,31.49,0,0,0,56,11.79,31.47,31.47,0,0,1,60.8,42.91,31.47,31.47,0,0,1,56,74,31.49,31.49,0,0,0,82.76,42.91Z"
            style={{
                opacity: '0.25',
            }}
            fill="#fff"
        />
        <path
            d="M26.73,23.23a31,31,0,0,1,4.75-.36,31.48,31.48,0,0,1,29.32,20A31.47,31.47,0,0,0,56,11.79a32.28,32.28,0,0,0-4.75-.35A31.4,31.4,0,0,0,26.73,23.23Z"
            style={{
                opacity: '0.5',
            }}
            fill="#fff"
        />
        <path
            d="M60.8,42.91A31.48,31.48,0,0,1,31.48,63a31,31,0,0,1-4.75-.36,31.4,31.4,0,0,0,24.55,11.8A32.28,32.28,0,0,0,56,74,31.47,31.47,0,0,0,60.8,42.91Z"
            style={{
                opacity: '0.5',
            }}
            fill="#fff"
        />
        <path
            d="M47.73,27.39a2.26,2.26,0,0,0-.26-.16c-.32-.19-.64-.37-1-.54,0,0,0,0,0,0a31.47,31.47,0,0,0-19.72-3.43A31.2,31.2,0,0,0,19.83,42c0,.31,0,.63,0,.95s0,.64,0,1a31.2,31.2,0,0,0,6.9,18.73A31.45,31.45,0,0,0,46,59.4c.16-.08.31-.15.46-.24.54-.29,1.07-.6,1.59-.92A31.58,31.58,0,0,0,60.8,42.91,31.62,31.62,0,0,0,47.73,27.39ZM38.09,52.47a9.55,9.55,0,0,1-9.56-9.55A9.86,9.86,0,0,1,29,39.83a4.25,4.25,0,0,0,2.35.71,4.32,4.32,0,0,0,4.32-4.32,4.24,4.24,0,0,0-.7-2.34,9.22,9.22,0,0,1,3.08-.52,9.56,9.56,0,1,1,0,19.11Z"
            style={{
                opacity: '0.75',
            }}
            fill="#fff"
        />
        <path
            d="M47.73,27.39a2.26,2.26,0,0,0-.26-.16c-.32-.19-.64-.37-1-.54,0,0,0,0,0,0A18.27,18.27,0,0,0,19.83,42c0,.31,0,.63,0,.95s0,.64,0,1A18.29,18.29,0,0,0,46,59.4c.16-.08.31-.15.46-.24A14,14,0,0,0,48,58.24a18.28,18.28,0,0,0-.31-30.85ZM38.09,52.47a9.55,9.55,0,0,1-9.56-9.55A9.86,9.86,0,0,1,29,39.83a4.25,4.25,0,0,0,2.35.71,4.32,4.32,0,0,0,4.32-4.32,4.24,4.24,0,0,0-.7-2.34,9.22,9.22,0,0,1,3.08-.52,9.56,9.56,0,1,1,0,19.11Z"
            fill="#fff"
        />
    </svg>
);
