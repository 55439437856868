import { FC, ReactNode, createContext, useContext, useMemo, useState } from 'react';

export interface ImagesUploaderValue {
    url: string;
    texts: Record<string, string>;
    size: number;
    name: string;
}

interface ImagesUploaderContextType {
    textFields: Array<string>;
    maxLengths: Array<number>;
    images: ImagesUploaderValue[];
    setImages: (value: ImagesUploaderValue[]) => void;
    deleteImage: (index: number) => void;
    updateTextField: (index: number, name: string, value: string) => void;
}
const ImagesUploaderContext = createContext<ImagesUploaderContextType>({
    textFields: [],
    maxLengths: [],
    images: [],
    setImages: (_value: ImagesUploaderValue[]) => undefined,
    deleteImage: (_index: number) => undefined,
    updateTextField: (_index: number, _name: string, _value: string) => undefined,
});

export const useImagesUploaderContext = () =>
    useContext<ImagesUploaderContextType>(ImagesUploaderContext);

interface Props {
    children: ReactNode;
    initialValue?: ImagesUploaderValue[];
    textFields: Array<string>;
    maxLengths: Array<number>;
}

export const ImagesUploaderProvider: FC<Props> = ({
    children,
    initialValue = [],
    textFields,
    maxLengths,
}) => {
    const [images, setImages] = useState<ImagesUploaderValue[]>(initialValue ?? []);

    const deleteImage = (index: number) => {
        setImages([...images.slice(0, index), ...images.slice(index + 1)]);
    };

    const updateTextField = (index: number, name: string, value: string) => {
        const imageToUpdate = images[index];
        imageToUpdate.texts[name] = value;
        setImages([...images.slice(0, index), imageToUpdate, ...images.slice(index + 1)]);
    };

    const value: ImagesUploaderContextType = useMemo(
        () => ({
            textFields,
            maxLengths,
            images,
            setImages,
            deleteImage,
            updateTextField,
        }),
        [images, setImages, deleteImage, updateTextField],
    );

    return (
        <ImagesUploaderContext.Provider value={value}>{children}</ImagesUploaderContext.Provider>
    );
};
