import { APP_PRODUCTS, BUSINESS_MESSAGE } from 'app/common/data/productIds';
import WHITELIST_PROVIDERS from 'app/common/data/providers';
import { BUSINESS_MANAGER, GROUP_MANAGER, ORG_ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';

const useHasWhatsappAccess = (): boolean => {
    const { data: me } = useMe();

    if (me === undefined) {
        return false;
    }

    if (!me.sidebar_products.includes(APP_PRODUCTS[BUSINESS_MESSAGE])) {
        return false;
    }

    const allowed_roles = WHITELIST_PROVIDERS.includes(me.provider)
        ? [ORG_ADMIN]
        : [ORG_ADMIN, GROUP_MANAGER, BUSINESS_MANAGER];
    return allowed_roles.includes(me.role);
};

export default useHasWhatsappAccess;
