import { FC } from 'react';

import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

interface IconProps {
    disabled?: boolean;
}

const LinkIcon: FC<IconProps> = ({ disabled }) => (
    <svg width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
            cx="23"
            cy="23.5"
            r="23"
            fill={disabled ? DISABLED_COLOR : HEX_COLORS.primary}
            fillOpacity="0.12"
        />
        <circle
            cx="23"
            cy="23.5"
            r="18"
            fill={disabled ? greyColorObject.light : HEX_COLORS.white}
        />
        <path
            d="M21.95 30.5H16C14.0633 30.5 12.4125 29.8175 11.0475 28.4525C9.6825 27.0875 9 25.4367 9 23.5C9 21.5633 9.6825 19.9125 11.0475 18.5475C12.4125 17.1825 14.0633 16.5 16 16.5H21.95V18.6H16C14.6467 18.6 13.4917 19.0783 12.535 20.035C11.5783 20.9917 11.1 22.1467 11.1 23.5C11.1 24.8533 11.5783 26.0083 12.535 26.965C13.4917 27.9217 14.6467 28.4 16 28.4H21.95V30.5ZM17.575 24.55V22.45H28.425V24.55H17.575ZM24.05 30.5V28.4H30C31.3533 28.4 32.5083 27.9217 33.465 26.965C34.4217 26.0083 34.9 24.8533 34.9 23.5C34.9 22.1467 34.4217 20.9917 33.465 20.035C32.5083 19.0783 31.3533 18.6 30 18.6H24.05V16.5H30C31.9367 16.5 33.5875 17.1825 34.9525 18.5475C36.3175 19.9125 37 21.5633 37 23.5C37 25.4367 36.3175 27.0875 34.9525 28.4525C33.5875 29.8175 31.9367 30.5 30 30.5H24.05Z"
            fill={disabled ? DISABLED_COLOR : HEX_COLORS.primary}
        />
    </svg>
);

export default LinkIcon;
