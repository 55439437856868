import React, { useContext } from 'react';

import { Tooltip } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { V2FormattedLocationData, V2LocationData } from 'app/api/types/account';
import formatObjectKeysToCamelCase from 'app/businessEditV2/oldFromEditV1/services/formatObjectKeysToCamelCase';
import { GoogleButton } from 'app/common/components/funnel/Buttons';

import { STATIC_BASE_URL } from 'app/config';

import {
    AdminLocationLogo,
    AdminLocationLogoBorderGradient,
    AdminLocationLogoContainer,
    AdminLocationPartnerLogoBorderGradient,
    Card,
    CardContainer,
    CardsContainer,
    LoadingPlaceholder,
    LocationCardStyled,
    NoLocationContainer,
    NoLocationSubtitle,
    NoLocationTitle,
    SelectionPageResultsContainer,
} from './SelectionPageResults.styled';
import SelectionPageContext from '../../SelectionPage.context';

export const convertLocationToCamelCase = (location: V2LocationData): V2FormattedLocationData => {
    return formatObjectKeysToCamelCase(location) as V2FormattedLocationData;
};

export const convertLocationsToCamelCase = (
    locations: Array<V2LocationData>,
): Array<V2FormattedLocationData> => {
    return formatObjectKeysToCamelCase(locations) as Array<V2FormattedLocationData>;
};

type NoLocationProps = {
    title: string;
    subtitle: string;
    buttonText: string;
};

type SelectionPageResultsProps = {
    hasAnyFilterActivated: boolean;
};

const NoLocation = ({ title, subtitle, buttonText }: NoLocationProps) => (
    <>
        <img src={`${STATIC_BASE_URL}/images/app/funnel/business.svg`} alt="business" />
        <NoLocationTitle>{title}</NoLocationTitle>
        <NoLocationSubtitle>{subtitle}</NoLocationSubtitle>
        <GoogleButton text={buttonText} />
    </>
);

const SelectionPageResults = ({ hasAnyFilterActivated }: SelectionPageResultsProps) => {
    const { t } = useTranslation();

    const {
        locations,
        selectedLocations,
        setSelectedLocations,
        locationQuery,
        adminOwnedLocations,
        disabledLocations,
    } = useContext(SelectionPageContext);

    const isLocationSelected = (id: string) =>
        selectedLocations.some(
            (selectedLocation: V2FormattedLocationData) => selectedLocation.id === id,
        );

    const editLocationsSelected = (location: V2FormattedLocationData) => {
        if (isLocationSelected(location.id)) {
            const newSelectedLocations = selectedLocations.filter(
                (selectedLocation: V2FormattedLocationData) => selectedLocation.id !== location.id,
            );
            setSelectedLocations(newSelectedLocations);
        } else {
            if (hasAnyFilterActivated) location.hasFilterAppended = true;
            setSelectedLocations([...selectedLocations, location]);
        }
    };

    if (
        locationQuery.isError ||
        (locations.length === 0 && !locationQuery.isLoading && !locationQuery.isFetchingNextPage)
    ) {
        return (
            <NoLocation
                title={t('no-result-found')}
                subtitle={t('onboarding_selection_page_no_result_subtitle')}
                buttonText={t('onboarding_selection_page_use_another_account')}
            />
        );
    }

    // TODO AT-793: Fix it backend side on fuzzy search locations endpoint
    // remove duplicates from locations
    const filteredLocations = [
        ...new Map(
            locations.map((filteredLocation: V2FormattedLocationData) => [
                filteredLocation.id,
                filteredLocation,
            ]),
        ).values(),
    ] as any;

    return (
        <SelectionPageResultsContainer hasSelected={selectedLocations.length > 0}>
            <CardsContainer>
                {filteredLocations.map((location: V2LocationData) => {
                    const locationId = location.id;
                    const locationSelected = isLocationSelected(locationId);
                    const formattedLocation = convertLocationToCamelCase(location);
                    const isDisabled = disabledLocations.some(
                        (disabledLocation: V2FormattedLocationData) =>
                            disabledLocation.id === locationId,
                    );
                    return (
                        <CardContainer key={locationId}>
                            <Card
                                isSelected={locationSelected}
                                onClick={() =>
                                    !isDisabled && editLocationsSelected(formattedLocation)
                                }
                                isDisabled={isDisabled}
                            >
                                <Tooltip
                                    text={
                                        isDisabled && t('onboarding_selection_page_same_store_code')
                                    }
                                    caret={false}
                                    placement="top"
                                >
                                    <LocationCardStyled
                                        partnerName="google"
                                        location={formattedLocation}
                                    />
                                    {adminOwnedLocations && (
                                        <>
                                            <AdminLocationPartnerLogoBorderGradient />
                                            <AdminLocationLogoBorderGradient>
                                                <AdminLocationLogoContainer>
                                                    <AdminLocationLogo
                                                        src={`${STATIC_BASE_URL}/images/providers_asset/partoo/partoo_favicon.png`}
                                                        alt="partoo-logo"
                                                    />
                                                </AdminLocationLogoContainer>
                                            </AdminLocationLogoBorderGradient>
                                        </>
                                    )}
                                </Tooltip>
                            </Card>
                        </CardContainer>
                    );
                })}
            </CardsContainer>
            <NoLocationContainer>
                {locationQuery.isLoading || locationQuery.isFetchingNextPage ? (
                    <LoadingPlaceholder>
                        <h3>{t('loading')}</h3>
                    </LoadingPlaceholder>
                ) : (
                    <NoLocation
                        title={t('onboarding_selection_page_no_more_location')}
                        subtitle={t('onboarding_selection_page_no_more_location_subtitle')}
                        buttonText={t('onboarding_selection_page_use_another_account')}
                    />
                )}
            </NoLocationContainer>
        </SelectionPageResultsContainer>
    );
};

export default SelectionPageResults;
