import { useContext } from 'react';

import { IconButton, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ReviewIconButtonWrapper, SuggestionIndexBox } from './SuggestionMenubar.styled';
import { ReviewReplyContext, ReviewReplyContextType } from '../CommentTable/CommentForm';

export type SuggestionIndex = 0 | 1 | 2;

type Props = {
    onClear?: () => void;
    onReport?: () => void;
    onSlide?: (i: SuggestionIndex) => void;
};

function SuggestionMenubar({
    onClear = () => undefined,
    onReport = () => undefined,
    onSlide = _ => undefined,
}: Props) {
    const { t } = useTranslation();

    const { currentSuggestion: { index, report } = { index: 0, report: null } } =
        useContext<ReviewReplyContextType>(ReviewReplyContext);

    const onChevronClick = function (next = false): void {
        const nextIndex = (index + (next ? 1 : -1)) as SuggestionIndex;
        onSlide?.(nextIndex);
    };

    return (
        <>
            <ReviewIconButtonWrapper className={index === 0 ? 'disabled' : ''}>
                <IconButton
                    dataTrackId="review-ai-suggestion-previous"
                    appearance="outlined"
                    icon={['fa-chevron-left', IconPrefix.REGULAR]}
                    onClick={() => onChevronClick()}
                />
            </ReviewIconButtonWrapper>
            <SuggestionIndexBox>{index + 1} / 3</SuggestionIndexBox>
            <ReviewIconButtonWrapper className={index === 2 ? 'disabled' : ''}>
                <IconButton
                    dataTrackId="review-ai-suggestion-next"
                    appearance="outlined"
                    icon={['fa-chevron-right', IconPrefix.REGULAR]}
                    onClick={() => onChevronClick(true)}
                    tooltip={t('review_ai_suggestion_refetch_tooltip')}
                />
            </ReviewIconButtonWrapper>
            <ReviewIconButtonWrapper style={{ opacity: report ? 0.5 : 1 }}>
                <IconButton
                    dataTrackId="review-ai-suggestion-report"
                    appearance="outlined"
                    icon={['fa-flag', IconPrefix.REGULAR]}
                    onClick={onReport}
                    disabled={!!report}
                    tooltip={t(
                        report
                            ? 'report_suggestion_reported_tooltip'
                            : 'report_suggestion_button_tooltip',
                    )}
                />
            </ReviewIconButtonWrapper>
            <ReviewIconButtonWrapper>
                <IconButton
                    dataTrackId="review-ai-suggestion-clear"
                    appearance="outlined"
                    icon={['fa-circle-xmark', IconPrefix.REGULAR]}
                    onClick={onClear}
                    tooltip={t('review_ai_suggestion_clear_tooltip')}
                />
            </ReviewIconButtonWrapper>
        </>
    );
}

export default SuggestionMenubar;
