import { Outlet, useRoutes } from 'react-router-dom';

import IS_IFRAME from 'app/common/data/iframe';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { HomepagePageHeader } from 'app/pages/Homepage/sections/HomepageHeader/HomepagePageHeader';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';

export const HomeHeaderRoutes = () => {
    const { data: me } = useMe();
    const meRole = me ? me.role : null;

    const hasIndex = !IS_IFRAME;

    const routes = [
        ...(hasIndex
            ? [
                  {
                      index: true,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <HomepagePageHeader />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
    ];

    return useRoutes(routes);
};

export const HomeHeader = () => {
    return (
        <>
            <HomeHeaderRoutes />
            <Outlet />
        </>
    );
};
