import {
    BusinessFieldArrayType,
    BusinessFieldType,
    CustomFieldsSectionData,
} from 'app/api/types/business';

export const CUSTOM_FIELD_TYPE = 'field';
export const CUSTOM_FIELD_SECTION_TYPE = 'section';

export type CustomFieldType = {
    dndId: string;
    type: typeof CUSTOM_FIELD_TYPE;
    obj: BusinessFieldType;
};

export type CustomFieldSectionType = {
    dndId: string;
    type: typeof CUSTOM_FIELD_SECTION_TYPE;
    obj: CustomFieldsSectionData;
    customFields: BusinessFieldArrayType;
};

export type CustomFieldOrSectionType = CustomFieldType | CustomFieldSectionType;

export type CustomFieldListType = Array<CustomFieldOrSectionType>;
