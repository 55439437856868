import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { useGetDate } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/params/useGetDate';
import { useGetMetrics } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/params/useGetMetrics';
import { useGetQuery } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/params/useGetQuery';

export const useMetricsSearchQueryParams = () => ({
    ...useGetDate(),
    ...useBusinessModalFilters(),
    ...useGetMetrics(),
    ...useGetQuery(),
});
