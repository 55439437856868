import { css } from '@emotion/react';
import { IconPrefix, Option } from '@partoohub/ui';

import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';

interface Props {
    onMenuClick: (value: string) => void;
    disabled: boolean;
    options: Array<Option>;
}

export const FoodMenuMenu = ({ onMenuClick, disabled, options }: Props) => (
    <ActionButtonWithMenu
        dataTrackId="manage_button__action_button"
        icon={['fa-ellipsis-v', IconPrefix.SOLID]}
        disabled={disabled}
        appearance="outlined"
        options={options}
        onMenuClick={(value: string) => onMenuClick(value)}
        cssMenu={css`
            top: 50px;
            right: 0px;
            left: unset;
        `}
    />
);
