import { FormattedReplyTemplate, ReplyTemplateApi } from 'app/api/types/reviewReplyTemplates';

import { formatAutoReplySettingsTemplate } from './reviewReplyTemplateFormatter';

/**
 * Format and camelCasify a ReplyTemplate provided by the api:
 */
export const formatReplyTemplate = (t: ReplyTemplateApi): FormattedReplyTemplate => {
    return {
        ...formatAutoReplySettingsTemplate(t),
        isAutoReply: t.is_auto_reply,
    };
};
