import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const StyledGraphSection = styled(Stack)`
    padding: 24px;
    background-color: white;
    border-radius: 8px;
`;

export const StyledGraphContainer = styled.div`
    min-height: 275px;
`;
