import styled from '@emotion/styled';

export const NoAnalyticsContainer = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
`;

export const NoAnalyticsImgContainer = styled.div``;

export const NoAnalyticsTitle = styled.h2`
    font-size: 18px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.default.initial};
`;
