import { Dispatch, SetStateAction, createContext } from 'react';

import { Option } from '@partoohub/ui';
import { UseMutationResult } from 'react-query/types/react/types';

import { Platform } from '../../../services/constants';
import { FormSteps } from '../PostCreationModal';

interface OriginalImageType {
    data_url: string;
    file: File | {};
    height: number;
    width: number;
}
export interface ImageType extends OriginalImageType {
    original: OriginalImageType;
    cropperData?: Cropper.Data;
    autoCropped?: boolean;
}

export declare type ImageListType = Array<ImageType>;

export const initialValues = {
    type: 'news',
    platform: [],
    isScheduled: false,
    scheduledDate: null,
    scheduledTime: null,
    postImage: [],
    buttonTypeGoogle: null,
};

export const changeFieldsReducer = (currentFields, { field, value }) => ({
    ...currentFields,
    [field]: value,
});

export type FormFields = {
    type: string;
    platform: Array<Platform>;
    isScheduled: boolean;
    scheduledDate: Date | null;
    scheduledTime: string | null;
    postImage: ImageListType;
    buttonTypeGoogle: Option | null;
};

export type NewPostContextType = {
    connectionStats?: Record<string, number>;
    unauthorizedBusinessCounts: Record<string, number>;
    allSteps: FormSteps;
    setAllSteps: Dispatch<SetStateAction<FormSteps>>;
    step: number;
    show: boolean;
    formFields: FormFields;
    postMutation?: UseMutationResult<Record<string, any>, unknown, void, unknown>;
    resetAndClose: () => void;
    setNextStep: () => void;
    setPreviousStep: () => void;
    setStep: (number: number) => void;
    updateField: (value: Record<string, any>) => void;
    isEditing: boolean;
    resetFormFields: () => void;
};

export const initialContextStates: NewPostContextType = {
    connectionStats: {},
    unauthorizedBusinessCounts: {},
    allSteps: [],
    setAllSteps: () => undefined,
    step: 1,
    show: false,
    formFields: initialValues,
    resetAndClose: () => undefined,
    setNextStep: () => undefined,
    setPreviousStep: () => undefined,
    setStep: () => undefined,
    updateField: () => undefined,
    isEditing: false,
    resetFormFields: () => undefined,
};

export const NewPostContext = createContext<NewPostContextType>(initialContextStates);
