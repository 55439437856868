import { Navigate, useRoutes } from 'react-router-dom';

import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import IS_IFRAME from 'app/common/data/iframe';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { Homepage } from 'app/pages/Homepage';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

import { HomeHeader } from './headers';

export const HomeRoutes = () => {
    const { data: me } = useMe();
    const meRole = me ? me.role : null;

    const hasIndex = !IS_IFRAME;
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);

    const routes = [
        ...(hasIndex
            ? [
                  {
                      index: true,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <Homepage />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
        {
            path: '*',
            element: <Navigate replace to={NOT_FOUND_PATH} />,
        },
    ];

    const routesWithHeader = [
        {
            path: '/',
            element: <HomeHeader />,
            children: routes,
        },
    ];
    return useRoutes(isNewSidebarEnabled ? routesWithHeader : routes);
};
