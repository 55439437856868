import { format } from 'date-fns';

import i18n from 'app/common/translations/i18n';
import { queriesChartColor } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queries';
import { MONTH_YEAR_FORMAT, getUTCDate } from 'app/utils/dates';

interface DataProps {
    date: string;
    bucket: string;
    count: number;
}

export const normalizrQueries = (data: DataProps[]) => {
    const dates = data.map(item => new Date(item.date));

    const labels = dates.map(item => {
        const dateUTC = getUTCDate(item);
        return format(dateUTC, MONTH_YEAR_FORMAT);
    });

    const datasets = [
        {
            label: i18n.t('queries'),
            backgroundColor: queriesChartColor.normal,
            data: data.map(item => item.count || 0),
        },
    ];

    return { labels, datasets, dates };
};
