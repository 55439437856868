import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSettingsPageContext } from 'app/pages/settings/AutoReply/hooks/useSettingsPageContext';
import { SETTINGS_REVIEWS_REPLY_TEMPLATES_PATHNAME } from 'app/routing/routeIds';

import { StyledLink, StyledWarningBox } from './WarningBox.styled';

type Props = {
    title: string;
    description: string;
    showLink?: boolean;
};

const WarningBox = ({ title, description, showLink }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        saveHelpers: { save },
    } = useSettingsPageContext();

    return (
        <StyledWarningBox>
            <Text variant="bodyMBold">{title}</Text>
            <Text variant="bodyMSemibold" color={'secondary'}>
                {description}
            </Text>
            {!!showLink && (
                <StyledLink
                    onClick={() => {
                        save();
                        navigate(SETTINGS_REVIEWS_REPLY_TEMPLATES_PATHNAME);
                    }}
                >
                    <Text variant="bodyMBold" color={'primary'}>
                        {t('auto_reply_template_select_add_template_link')}
                    </Text>
                </StyledLink>
            )}
        </StyledWarningBox>
    );
};

export default WarningBox;
