import styled from '@emotion/styled';

export const StyledHeader = styled.div`
    position: relative;
    padding-top: 24px;
    width: 500px;
    text-align: center;

    > div {
        // Loader
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
