import { Button } from '@partoohub/ui';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useCreateChatbot } from 'app/pages/settings/Chatbot/hooks/useCreateChatbot';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';

import { ChatbotConfigurationModal } from 'app/pages/settingsV2/subPages/Messages/components/Chatbots/ChatbotConfigurationModal';
import { ChatbotList } from 'app/pages/settingsV2/subPages/Messages/components/Chatbots/ChatbotList';

const CreateChatbotButton = () => {
    const { t } = useTranslation();
    const createChatbotMutation = useCreateChatbot();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleClick = () => {
        createChatbotMutation.mutate(undefined, {
            onSuccess: chatbot => {
                searchParams.set('chatbot_id', chatbot.id.toString());
                setSearchParams(searchParams);
            },
        });
    };

    return (
        <Button
            variant="primary"
            appearance="contained"
            dataTrackId="chatbot_create_chatbot"
            icon={['fa-plus']}
            onClick={handleClick}
        >
            {t('chatbot_configuration_create_chatbot')}
        </Button>
    );
};

export const Chatbots = () => {
    const { t } = useTranslation();
    const { search } = useLocation();

    if (!!qs.parse(search).chatbot_id) {
        return <ChatbotConfigurationModal show />;
    }

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('chatbot_configuration_title')}
                    description={t('chatbot_configuration_description')}
                    actionsButton={[<CreateChatbotButton />]}
                />
            }
            withoutPadding
        >
            <MenuListContentContainer>
                <ChatbotList />
            </MenuListContentContainer>
        </MenuListContentTemplate>
    );
};
