import { List } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { IS_BELOW_MOBILE, useDeviceType } from 'app/hooks';
import { usePublishers } from 'app/presence/management/hooks/usePublishers';

import { usePublishersStates } from 'app/presence/management/hooks/usePublishersStates';

import { DiffusionPublishersContainer } from './DiffusionPublishers.styled';
import { getStatusObject } from './PublisherTable/helpers/helpers';
import { usePublisherColumns } from './PublisherTable/hooks/usePublisherColumn';
import { PublisherIcon } from './PublisherTable/PublisherIcon/PublisherIcon';
import { PublisherRow } from './PublisherTable/PublisherRow/PublisherRow';

export const DiffusionPublishers = () => {
    const { t } = useTranslation();
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_MOBILE);
    const { data: publishers, isLoading } = usePublishers();
    const { data: publishersStates } = usePublishersStates();
    const businessCount = publishersStates?.business_count ?? 0;
    const { columns } = usePublisherColumns();

    const transformedColumns = columns.map(column => {
        if (isBelowProvidedDevices) {
            if (['success', 'pending', 'to_configure'].includes(column.label)) {
                return {
                    ...column,
                    label: <PublisherIcon {...getStatusObject(column.label)} />,
                };
            }
        }
        if (column.label === 'success') {
            return {
                ...column,
                label: `${t('transmitted')}`,
            };
        }
        return {
            ...column,
            label: t(column.label),
        };
    });

    return (
        <DiffusionPublishersContainer>
            {transformedColumns.length > 0 && (
                <List
                    columns={transformedColumns}
                    autoCollapse
                    loading={!transformedColumns || isLoading}
                    hasBorderColor={businessCount === 1}
                >
                    {publishers?.map(publisher => (
                        <PublisherRow
                            key={publisher.name}
                            publisherName={publisher.name}
                            businessCount={businessCount}
                        />
                    ))}
                </List>
            )}
        </DiffusionPublishersContainer>
    );
};
