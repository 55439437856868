import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

export const DefaultImageLoader = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    background-color: #f6f8fc;
    color: #dddfe3;
    font-size: 24px;
`;

export const StyledIcon = styled.i`
    color: #dddfe3;
    font-size: 24px;
`;

export const InitialDiv = styled.div`
    height: 100px;
`;
export const ImageFallbackContainer = styled.div<{
    css?: SerializedStyles;
}>`
    display: inline-block;
    position: relative;
    overflow: hidden;

    ${({ css }) => css};

    img {
        width: 100%; /* fill completely parent div */
        height: 100%; /* fill completely parent div */
        object-fit: cover;
    }
`;
