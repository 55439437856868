import { useEffect, useMemo, useState } from 'react';

import { Button } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { FakeProgress } from 'app/common/components/FakeProgress';
import FunnelModal from 'app/common/components/FunnelModal';
import { FunnelModalFooter } from 'app/common/components/FunnelModal/FunnelModal.styled';
import { WHATSAPP, WHATSAPP_ACCOUNT_COUNT } from 'app/common/data/queryKeysConstants';
import { WHATSAPP_ONBOARDING_REPORT } from 'app/common/data/websocket';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useWebsocket from 'app/common/hooks/useWebsocket';
import dataLayer from 'app/common/utils/dataLayer';
import { STATIC_APP_ASSETS_URL } from 'app/config';
import queryClient from 'app/states/queryClient';

import {
    CenteredBody,
    ConfettiModalBody,
    ContentContainer,
    DescriptionContainer,
    DescriptionTextContainer,
    HeaderContainer,
    TextContainer,
    TitleContainer,
    TitleTextContainer,
} from './WhatsappAccountCreatedModal.styled';

interface WhatsappOnboardingReport {
    status: 'success';
    whatsapp_phone_number_id: number;
}

type Props = {
    onClose: () => void;
};

const TIMEOUT_DELAY = 90_000; // 1m30s in ms

const WhatsappAccountCreatedModal = ({ onClose }: Props) => {
    const { t } = useTranslation();
    const { data: me } = useMe();

    const navigate = useNavigate();

    const [whatsappPhoneNumberId, setWhatsappPhoneNumberId] = useState<number>();
    const [hasOnboardingTimeouted, setHasOnboardingTimeouted] = useState<boolean>(false);

    const isOnboardingSuccessful = !!whatsappPhoneNumberId;
    const onboardingStartedAt = useMemo(() => new Date(), []);

    useWebsocket<WhatsappOnboardingReport>(
        WHATSAPP_ONBOARDING_REPORT,
        ({ whatsapp_phone_number_id }) => {
            setWhatsappPhoneNumberId(whatsapp_phone_number_id);
        },
    );

    useEffect(() => {
        const now = new Date();
        const onboardingStartedSince = now.getTime() - onboardingStartedAt.getTime(); // in ms

        // not already timeouted
        if (onboardingStartedSince < TIMEOUT_DELAY) {
            const timeUntilTimeout = TIMEOUT_DELAY - onboardingStartedSince;

            const timer = setTimeout(() => {
                setHasOnboardingTimeouted(true);
            }, timeUntilTimeout);
            return () => clearTimeout(timer);
        }
    }, [setHasOnboardingTimeouted, onboardingStartedAt]);

    const handleCloseModal = () => {
        queryClient.invalidateQueries({
            queryKey: [WHATSAPP],
        });
        queryClient.invalidateQueries({
            queryKey: [WHATSAPP_ACCOUNT_COUNT],
        });
        if (isOnboardingSuccessful) {
            navigate(whatsappPhoneNumberId.toString());
        }
        onClose();
    };

    let emoji = '⚙️️';
    let title = t('whatsapp_account_created_modal_title_loading');
    let description = t('whatsapp_account_created_modal_subtitle_loading');
    let hasConfetti = false;
    let gtmEvent = 'wa_account_creation_progress';
    if (isOnboardingSuccessful) {
        emoji = '🚀';
        title = t('whatsapp_account_created_modal_title');
        description = t('whatsapp_account_created_modal_subtitle');
        hasConfetti = true;
        gtmEvent = 'wa_account_creation_done';
    } else if (hasOnboardingTimeouted) {
        emoji = '🤔';
        title = t('whatsapp_account_created_modal_title_timeout');
        description = t('whatsapp_account_created_modal_subtitle_timeout');
        gtmEvent = 'wa_account_creation_delay';
    }
    useEffect(() => {
        if (me) {
            dataLayer.pushDict(gtmEvent, {
                partoo_user_id: me?.id,
                org_id: me?.org_id,
            });
        }
    }, [me, gtmEvent]);

    return (
        <FunnelModal isOpen={true} onClose={handleCloseModal}>
            <ConfettiModalBody hasConfetti={hasConfetti}>
                <CenteredBody>
                    <HeaderContainer>
                        <TitleContainer>
                            <TitleTextContainer variant="heading3" color="blackberry">
                                {emoji}
                            </TitleTextContainer>
                            <TitleTextContainer variant="heading3" color="blackberry">
                                {title}
                            </TitleTextContainer>
                        </TitleContainer>

                        <DescriptionTextContainer variant="bodyLRegular" color="secondary">
                            {description}
                        </DescriptionTextContainer>
                    </HeaderContainer>

                    {!(isOnboardingSuccessful || hasOnboardingTimeouted) && (
                        <FakeProgress duration={90} target={90} />
                    )}
                    {!hasOnboardingTimeouted && (
                        <ContentContainer>
                            <DescriptionContainer>
                                <img
                                    src={`${STATIC_APP_ASSETS_URL}/whatsapp/whatsapp_account_created_image_1.svg`}
                                    alt="whatsapp account created description 1"
                                />
                                <TextContainer variant="bodyMSemibold" color="blackberry">
                                    {t('whatsapp_account_created_modal_description_1')}
                                </TextContainer>
                            </DescriptionContainer>
                            <DescriptionContainer>
                                <img
                                    src={`${STATIC_APP_ASSETS_URL}/whatsapp/whatsapp_account_created_image_2.svg`}
                                    alt="whatsapp account created description 2"
                                />

                                <TextContainer variant="bodyMSemibold" color="blackberry">
                                    {t('whatsapp_account_created_modal_description_2')}
                                </TextContainer>
                            </DescriptionContainer>

                            <DescriptionContainer>
                                <img
                                    src={`${STATIC_APP_ASSETS_URL}/whatsapp/whatsapp_account_created_image_3.svg`}
                                    alt="whatsapp account created description 3"
                                />

                                <TextContainer variant="bodyMSemibold" color="blackberry">
                                    {t('whatsapp_account_created_modal_description_3')}
                                </TextContainer>
                            </DescriptionContainer>
                        </ContentContainer>
                    )}
                </CenteredBody>
            </ConfettiModalBody>

            {(isOnboardingSuccessful || hasOnboardingTimeouted) && (
                <FunnelModalFooter>
                    <Button
                        onClick={handleCloseModal}
                        shape="cube"
                        size="large"
                        variant="primary"
                        appearance="contained"
                        full
                        dataTrackId={''}
                    >
                        {t('whatsapp_account_created_modal_close')}
                    </Button>
                </FunnelModalFooter>
            )}
        </FunnelModal>
    );
};

export default WhatsappAccountCreatedModal;
