import { Notification, Tabs } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import IS_IFRAME from 'app/common/data/iframe';
import { useNewReviewsCount } from 'app/reviewManagement/reviewList/hooks/useNewReviewsCount';
import {
    CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH,
    CUSTOMER_EXPERIENCE_SEND_INVITE_PATH,
} from 'app/routing/routeIds';

import { CustomerExperienceTabsContainer } from './CustomerExperienceTabs.styled';
import {
    CEPages,
    useCanAccessCustomerExperiencePage,
} from '../../useCanAccessCustomerExperiencePage';

export const CustomerExperienceTabs = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const { canAccessCustomerExperiencePage } = useCanAccessCustomerExperiencePage();
    const newReviewsCount = useNewReviewsCount();

    const displayReviewTab = canAccessCustomerExperiencePage[CEPages.ClientReviews];
    const displayReviewBoosterTab = canAccessCustomerExperiencePage[CEPages.RB];

    const trueCount = [displayReviewTab, displayReviewBoosterTab].filter(Boolean).length;

    const handleNavigateTabLink = (path: string) => {
        navigate(path);
    };

    if (IS_IFRAME || trueCount <= 1) {
        return null;
    }

    return (
        <CustomerExperienceTabsContainer isNewSidebar={isNewSidebarEnabled}>
            <Tabs
                selected={pathname}
                dataTrackId="customer_experience_tabs"
                onChange={value => {
                    handleNavigateTabLink(value as string);
                }}
            >
                {displayReviewTab && (
                    <Tabs.Tab
                        value={CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH}
                        badge={
                            newReviewsCount > 0 ? (
                                <Notification
                                    dataTrackId=""
                                    text={newReviewsCount.toString()}
                                    size="small"
                                />
                            ) : undefined
                        }
                    >
                        {t('clients_reviews')}
                    </Tabs.Tab>
                )}

                {displayReviewBoosterTab && (
                    <Tabs.Tab value={CUSTOMER_EXPERIENCE_SEND_INVITE_PATH}>
                        {t('rb_send_an_invitation')}
                    </Tabs.Tab>
                )}
            </Tabs>
        </CustomerExperienceTabsContainer>
    );
};
