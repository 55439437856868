import { useTranslation } from 'react-i18next';

import { useAppDataContext } from 'app/common/contexts/appData';
import { REVIEW_MANAGEMENT } from 'app/common/data/productIds';
import { ADMIN } from 'app/common/data/roles';
import { PageHeaderWithAvatar } from 'app/routing/components/PageHeaderWithAvatar';

export const ReviewsPageHeader = () => {
    const { t } = useTranslation();
    const { me } = useAppDataContext();

    // TODO: Tricia - TT-318
    const isNotAdmin = me.role !== ADMIN;
    const hasRmAccess = me.sidebar_products.includes(REVIEW_MANAGEMENT.toLowerCase());
    const showHeader = isNotAdmin && hasRmAccess;

    return showHeader && <PageHeaderWithAvatar title={t('analytics')} />;
};
