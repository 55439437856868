import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';

import { ColorState } from './types';

export default function useCategoriesSectionColor(): ColorState {
    // Hooks
    const { data: publisherErrors } = usePublisherErrors({});
    const categoriesPermission = useFieldMetadata('infos', 'categories')?.enabled;
    const isClosed = useIsBusinessClosed();

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // Disabled
    if (!categoriesPermission) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (publisherErrors?.infos?.categories) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields (no additional categories) not handled anymore

    // OK
    return ColorState.OK;
}
