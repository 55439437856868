import Storage from 'app/common/utils/localStorageWrapper';

enum BrowserStateKeys {
    PUSH_NOTIFICATIONS_PROMPTS = 'pushNotificationsPrompts',
}

export default class PushNotificationsBrowserState extends Storage<BrowserStateKeys> {
    private static instance: PushNotificationsBrowserState;

    private constructor() {
        super();
    }

    public static getInstance(): PushNotificationsBrowserState {
        if (!PushNotificationsBrowserState.instance) {
            PushNotificationsBrowserState.instance =
                new PushNotificationsBrowserState();
        }
        return PushNotificationsBrowserState.instance;
    }

    /**
     * Returns the previously stored prompts.
     * A prompt is when we ask the user to enable push notifications.
     * As soon as we ask, we store the time of prompt along with the user id.
     * The data is stored as json in local storage.
     */
    public getPrompts(): Record<string, number> {
        return this.getJSON(BrowserStateKeys.PUSH_NOTIFICATIONS_PROMPTS) || {};
    }

    /**
     * Adds a prompt to existing object of prompts.
     * Data is a mapping of user id to time of prompt.
     */
    public addPrompt(data: Record<string, number>) {
        const prompts = this.getPrompts();

        this.setJSON(BrowserStateKeys.PUSH_NOTIFICATIONS_PROMPTS, {
            ...prompts,
            ...data,
        });
    }
}
