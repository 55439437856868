import React from 'react';

import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { AvailableGoogleVerificationMethod } from 'app/api/types/account';
import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import { OptionCardWrapper, OptionContent, OptionIcon } from './Options.styled';

type OptionCardProps = {
    name: string;
    description: string;
    icon: string;
    color: string;
    selected: boolean;
    clickable: boolean;
    onClick?: () => void;
};

type CustomOptionCardProps = {
    clickable: boolean;
    selectedMethod?: AvailableGoogleVerificationMethod;
    onClick?: () => void;
};

export const OptionCard: React.FC<OptionCardProps> = ({
    name,
    description,
    icon,
    color,
    selected,
    clickable,
    onClick,
}) => (
    <OptionCardWrapper selected={selected} clickable={clickable} onClick={onClick}>
        <OptionIcon color={color}>
            <i className={'fa-solid ' + icon}></i>
        </OptionIcon>
        <OptionContent>
            <Text variant="bodyLBold">{name}</Text>
            <Text variant="bodyMRegular">{description}</Text>
        </OptionContent>
    </OptionCardWrapper>
);

const OptionCardSMS: React.FC<CustomOptionCardProps> = ({ clickable, selectedMethod, onClick }) => {
    const { t } = useTranslation();

    return (
        <OptionCard
            name={t('google_verification__methods__sms_name')}
            description={t('google_verification__methods__sms_description')}
            icon="fa-comment"
            color="#FF9014"
            selected={selectedMethod === 'SMS'}
            clickable={clickable}
            onClick={onClick}
        ></OptionCard>
    );
};

const OptionCardPhone: React.FC<CustomOptionCardProps> = ({
    clickable,
    selectedMethod,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <OptionCard
            name={t('google_verification__methods__phone_call_name')}
            description={t('google_verification__methods__phone_call_description')}
            icon="fa-phone"
            color="#8A5DF2"
            selected={selectedMethod === 'PHONE_CALL'}
            clickable={clickable}
            onClick={onClick}
        ></OptionCard>
    );
};

const OptionCardAddress: React.FC<CustomOptionCardProps> = ({
    clickable,
    selectedMethod,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <OptionCard
            name={t('google_verification__methods__address_name')}
            description={t('google_verification__methods__address_description')}
            icon="fa-envelopes-bulk"
            color="#40DBA2"
            selected={selectedMethod === 'ADDRESS'}
            clickable={clickable}
            onClick={onClick}
        ></OptionCard>
    );
};

const OptionCardEmail: React.FC<CustomOptionCardProps> = ({
    clickable,
    selectedMethod,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <OptionCard
            name={t('google_verification__methods__email_name')}
            description={t('google_verification__methods__email_description')}
            icon="fa-at"
            color="#0085F2"
            selected={selectedMethod === 'EMAIL'}
            clickable={clickable}
            onClick={onClick}
        ></OptionCard>
    );
};

const OptionCardBusinessVideo: React.FC<CustomOptionCardProps> = ({
    clickable,
    selectedMethod,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <OptionCard
            name={t('google_verification__methods__business_video_name')}
            description={t('google_verification__methods__business_video_description')}
            icon="fa-video"
            color="#8A5DF2"
            selected={selectedMethod === 'BUSINESS_VIDEO'}
            clickable={clickable}
            onClick={onClick}
        ></OptionCard>
    );
};

export const getCardByMethod = (
    method: AvailableGoogleVerificationMethod,
    clickable: boolean,
    selectedMethod?: AvailableGoogleVerificationMethod,
    onClick?: () => void,
) => {
    switch (method) {
        case 'SMS':
            return (
                <OptionCardSMS
                    key={method}
                    clickable={clickable}
                    selectedMethod={selectedMethod}
                    onClick={onClick}
                />
            );
        case 'PHONE_CALL':
            return (
                <OptionCardPhone
                    key={method}
                    clickable={clickable}
                    selectedMethod={selectedMethod}
                    onClick={onClick}
                />
            );
        case 'ADDRESS':
            return (
                <OptionCardAddress
                    key={method}
                    clickable={clickable}
                    selectedMethod={selectedMethod}
                    onClick={onClick}
                />
            );
        case 'EMAIL':
            return (
                <OptionCardEmail
                    key={method}
                    clickable={clickable}
                    selectedMethod={selectedMethod}
                    onClick={onClick}
                />
            );
        case 'BUSINESS_VIDEO':
            return (
                <OptionCardBusinessVideo
                    key={method}
                    clickable={clickable}
                    selectedMethod={selectedMethod}
                    onClick={onClick}
                />
            );
    }
};

export const LoadingOptionCard: React.FC = () => (
    <OptionCardWrapper selected={false} clickable={false}>
        <OptionIcon color="#F5F7FB">
            <LoadingRectangle recRadius="50%" recHeight="100%" recWidth="100%" />
        </OptionIcon>
        <OptionContent>
            <LoadingRectangle
                recMargin="2px 0px 0px 0px"
                recRadius="4px"
                recHeight="20px"
                recWidth="60px"
            />
            <LoadingRectangle recRadius="4px" recHeight="18px" recWidth="240px" />
        </OptionContent>
    </OptionCardWrapper>
);
