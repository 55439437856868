import { setDefaultOptions } from 'date-fns';
import { call, put, takeEvery } from 'redux-saga/effects';

import { V2UserData } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import formatOrg from 'app/businessConnection/services/formatOrg';
import { getMe, getMeFailure, getMeSuccess } from 'app/common/reducers/me';
import formatUser from 'app/common/services/formatUser';
import i18n from 'app/common/translations/i18n';
import { Saga } from 'app/common/types';

import { getOrgSuccess } from '../reducers/orgs';
import { getDateFnsLocaleFromLocale } from '../utils/dateFns';

export const getMeSaga = function* (): Saga {
    try {
        yield put(getMe());
        const data: V2UserData = yield call(api.user.getMe);
        const formattedUser = formatUser(data);

        // Load the org data on login
        const dataOrg = yield call(api.org.getOrg, data.org_id);
        const formattedLocation = formatOrg(dataOrg);
        yield put(getOrgSuccess(formattedLocation));

        yield put(getMeSuccess(formattedUser));

        if (formattedUser.lang !== i18n.language) {
            i18n.changeLanguage(formattedUser.lang);
        }

        // What is this ?? We should just use useMe hook to retrieve the language
        // Setting the user language in the DOM
        const html = document.getElementsByTagName('html');

        if (html && html[0]) {
            html[0].setAttribute('lang', formattedUser.lang);
            const locale = getDateFnsLocaleFromLocale(formattedUser.lang);
            setDefaultOptions(locale);
        }
    } catch (error) {
        yield put(getMeFailure(error));
    }
};

// Only to update me/org after an update of user
// Should be removed when all other pages are not using sagas anymore !
const FORCE_GET_ME_ACTION = 'FORCE_GET_ME_ACTION';
export const forceGetMe = () => ({ type: FORCE_GET_ME_ACTION });
export function* forceGetMeSaga(): Saga {
    yield takeEvery(FORCE_GET_ME_ACTION, getMeSaga);
}
