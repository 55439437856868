import { KeywordType } from 'app/api/types/competitiveBenchmark';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { useGetKeywordsCompetitive } from './useGetKeywordsCompetitive';
import { FiltersEnum } from '../constant/filtersEnum';

export const useKeywordFilter = (): [KeywordType, (keyword: string) => void] => {
    const [keyword, setKeyword] = useStateQueryParams(FiltersEnum.KEYWORD);
    const { availableKeywords } = useGetKeywordsCompetitive();

    let foundKeyword = availableKeywords.find(k => k.keyword === keyword);

    if (!foundKeyword) {
        const firstKeyword = availableKeywords[0] ?? { keyword: '', dates: [] };
        foundKeyword = firstKeyword;
    }

    return [foundKeyword, setKeyword];
};
