import { useEffect } from 'react';

import { Drawer } from '@partoohub/ui';
import { useDispatch, useSelector } from 'react-redux';

import IS_IFRAME from 'app/common/data/iframe';
import { collapseSidebar, isSidebarCollapsed, resetSidebar } from 'app/common/reducers/global';

import { AppState } from 'app/states/reducers';

import { StyledStack, getCssContainer } from './ModalSidebar.styled';
import { ProductSidebar } from '../Sidebar/ProductSidebar';

export const MobileSidebar = () => {
    const { global } = useSelector((state: AppState) => state);

    useEffect(() => {
        dispatch(resetSidebar());
    }, []);

    const dispatch = useDispatch();
    const openSidebar = () => dispatch(collapseSidebar());
    return (
        <Drawer
            dataTrackId="mobile_sidebar__drawer"
            isOpen={isSidebarCollapsed(global)}
            onHide={openSidebar}
            hideOnClose={false}
            direction="left"
            cssContainer={getCssContainer()}
            hideOnEsc={!IS_IFRAME}
        >
            <StyledStack>
                <ProductSidebar />
            </StyledStack>
        </Drawer>
    );
};
