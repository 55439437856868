import { Choice } from 'app/api/types/user';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import { PairButtonContainer, PairedButtonsContainer } from './PairedButtons.styled';

type Props = {
    selectedButton: string;
    options: Array<Choice>;
    onClick: (value: any) => void;
    disabled?: boolean;
    textTooltip?: string; // text of the tooltip on text value hover
};

const PairedButtons = ({
    disabled = false,
    textTooltip = '',
    selectedButton,
    options,
    onClick,
}: Props) => {
    const PairedButtonField = (
        <PairedButtonsContainer disabled={disabled}>
            {options.map(option => (
                <PairButton
                    isActive={selectedButton === option.label}
                    label={option.label}
                    value={option.value}
                    onClick={onClick}
                    key={option.label}
                    disabled={disabled}
                    textTooltip={textTooltip}
                />
            ))}
        </PairedButtonsContainer>
    );

    return textTooltip ? (
        <TooltipWrapper text={textTooltip} position="bottom-start">
            {PairedButtonField}
        </TooltipWrapper>
    ) : (
        PairedButtonField
    );
};

type PairButtonProps = {
    isActive: boolean;
    label: string;
    value: any;
    onClick: (value: any) => void;
    disabled: boolean;
    textTooltip: string; // text of the tooltip on text value hover
};

const PairButton = ({ isActive, label, value, onClick, disabled }: PairButtonProps) => (
    <PairButtonContainer isActive={isActive} onClick={() => onClick(value)} disabled={disabled}>
        {label}
    </PairButtonContainer>
);

export default PairedButtons;
