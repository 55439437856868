import api from 'app/api/v2/api_calls';
import InfoSectionExtensionGMB from 'app/businessConnection/components/genericQuickConnect/InfoSection/Extensions/GMB/ImportSectionExtensionGMB.container';

export const PUBLISHERS = {
    google: {
        name: 'google',
        label: 'Google',
        value: 'google',
        partnerResourceName: 'gmb',
        icon: 'fab fa-google quick_connect_icon quick_connect_google_icon',
        endpoints: {
            suggestion: api.businessModule.publisherConnection.getGmbAutoMatchSuggestions,
            connection: api.businessModule.publisherConnection.connectBusinessToLocation,
            import: api.business.importGMBInfos,
        },
        texts: {
            headerTitle: 'google_suggestion_header_title',
            importSectionTitle: 'google_suggestion_import_section_title',
            loadingSuggestionsMessage: 'google_suggestion_loading_message',
            infoSectionHeaderTitle: 'google_info_section_header_title',
            infoSectionHeaderDescription: 'google_info_section_description',
            buttonWithSuggestions: 'google_suggestion_import_button',
            buttonImportInProgress: 'quick_connect_import_in_progress',
        },
        extensionInfoSection: InfoSectionExtensionGMB,
    },
    facebook: {
        name: 'facebook',
        label: 'Facebook',
        value: 'facebook',
        partnerResourceName: 'facebook',
        icon: 'fab fa-facebook quick_connect_icon quick_connect_facebook_icon',
        endpoints: {
            suggestion: api.businessModule.publisherConnection.getFacebookAutoMatchSuggestions,
            connection: api.businessModule.publisherConnection.connectBusinessToLocation,
            import: () => undefined,
        },
        texts: {
            headerTitle: 'facebook_suggestion_header_title',
            importSectionTitle: 'facebook_suggestion_import_section_title',
            loadingSuggestionsMessage: 'facebook_suggestion_loading_message',
            infoSectionHeaderTitle: 'facebook_info_section_header_title',
            infoSectionHeaderDescription: 'facebook_info_section_description',
            buttonWithSuggestions: 'facebook_suggestion_import_button',
            buttonImportInProgress: 'quick_connect_import_in_progress',
        },
        extensionInfoSection: () => null,
    },
};

export const platformOptions = Object.values(PUBLISHERS).map(x => {
    const { name, label, value } = x;
    return {
        name,
        label,
        value,
    };
});
