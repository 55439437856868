import { endOfDay, startOfDay, subMonths } from 'date-fns';

import { useMessagingStats } from 'app/pages/Homepage/hooks/stats/statsItems/messages/useMessagingStats';

export const MESSAGING_STATS_PERIOD_MONTHS = 1;

export const useHomepageMessagingStats = (
    fromNMMonthsAgo: number = 6,
    toNMonthsAgo: number = 0,
) => {
    const from = startOfDay(subMonths(new Date(), fromNMMonthsAgo));
    const to = endOfDay(subMonths(new Date(), toNMonthsAgo));

    return useMessagingStats({
        created_after: from.toISOString(),
        conversations_created_before: to.toISOString(),
    });
};
