import { APP_PRODUCTS, BUSINESS_MESSAGE } from 'app/common/data/productIds';
import { ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useIsAvailableTodoMessagesItem = (): boolean => {
    const { data: me } = useMe();
    const isAvailable = me?.sidebar_products.includes(APP_PRODUCTS[BUSINESS_MESSAGE]) ?? false;

    return isAvailable && me?.role !== ADMIN;
};
