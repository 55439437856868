import { useQuery } from 'react-query';

import { SsoInfoResponse } from 'app/api/types/sso';
import api from 'app/api/v2/api_calls';
import { SSO_INFOS } from 'app/common/data/queryKeysConstants';

interface Options {
    enabled?: boolean;
    onSuccess?: (data: SsoInfoResponse) => void;
    onError?: (err: Record<string, any>) => void;
}

export default function useSsoInfo(orgId: number, options?: Options) {
    return useQuery(
        [SSO_INFOS, { orgId }],
        () => api.org.getSsoInfo(orgId),
        options,
    );
}
