import { CardContentGrid, StyledCard } from './ReplyTemplateCard.styled';
import {
    StyledLoadingFlag,
    StyledLoadingRating,
    StyledLoadingText,
    StyledLoadingTitle,
} from './TemplateCardLoader.styled';

export default function TemplateCardLoader() {
    return (
        <StyledCard>
            <CardContentGrid>
                <StyledLoadingFlag
                    recWidth="26px"
                    recHeight="26px"
                    recRadius="50%"
                />
                <StyledLoadingTitle
                    as="div"
                    recWidth="100px"
                    recHeight="26px"
                    recRadius="5%"
                >
                    <></>
                </StyledLoadingTitle>
                <StyledLoadingText
                    as="div"
                    recWidth="500px"
                    recHeight="20px"
                    recRadius="5%"
                >
                    <></>
                </StyledLoadingText>
                <StyledLoadingRating
                    recWidth="100px"
                    recHeight="20px"
                    recRadius="5%"
                />
            </CardContentGrid>
        </StyledCard>
    );
}
