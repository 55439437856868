import { useRef, useState } from 'react';

import { Button, IconButton, IconPrefix, Modal } from '@partoohub/ui';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { useTranslation } from 'react-i18next';

import { MAX_ASPECT_RATIO, MIN_ASPECT_RATIO } from './helpers/getClampedRatio';
import { StyledBody, StyledTitle } from './ImageCropperModal.styled';
import { ImageType } from '../../../context/NewPost';
import 'cropperjs/dist/cropper.css';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onApply: (croppedFile: Blob, cropperData: Cropper.Data, isMainPhoto: boolean) => void;
    photo: ImageType;
    isMainPhoto: boolean;
    initialAspectRatio: number | undefined;
};

export const ImageCropperModal = ({
    isOpen,
    onClose,
    onApply,
    photo,
    isMainPhoto,
    initialAspectRatio,
}: Props) => {
    const { t } = useTranslation();
    const cropperRef = useRef<ReactCropperElement>(null);
    const [isCropping, setIsCropping] = useState(false);

    const onCropMove = () => {
        const cropper = cropperRef.current?.cropper;
        if (!cropper) return;

        const cropBoxData = cropper.getCropBoxData();
        if (!cropBoxData) return;

        const { width, height } = cropBoxData;
        const requiredAspectRatio = width / height;

        if (requiredAspectRatio < MIN_ASPECT_RATIO) {
            const newWidth = Math.min(
                cropBoxData.height * MIN_ASPECT_RATIO,
                cropper.getImageData().width,
            );
            cropper.setCropBoxData({
                width: newWidth,
                height: newWidth / MIN_ASPECT_RATIO,
            });
        } else if (requiredAspectRatio > MAX_ASPECT_RATIO) {
            cropper.setCropBoxData({
                width: cropBoxData.height * MAX_ASPECT_RATIO,
            });
        }
    };

    const onSave = async () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            setIsCropping(true);
            const blob = await new Promise<Blob | null>(resolve =>
                cropper.getCroppedCanvas().toBlob(resolve, 'image/jpeg'),
            );
            if (blob) {
                onApply(blob, cropper.getData(), isMainPhoto);
            }
            setIsCropping(false);
        }
        onClose();
    };

    return (
        <Modal isOpen={isOpen} closeModal={onClose} hideOnClickOutside={false}>
            <StyledBody>
                <StyledTitle variant="heading4">
                    {t('post_photo_cropper_modal_title')}
                    <IconButton
                        dataTrackId=""
                        icon={['fa-close', IconPrefix.SOLID]}
                        onClick={onClose}
                    />
                </StyledTitle>
                <Cropper
                    src={photo.original.data_url}
                    style={{ height: 400, backgroundColor: '#000' }}
                    viewMode={1}
                    // Cropper.js options
                    initialAspectRatio={initialAspectRatio}
                    aspectRatio={isMainPhoto ? undefined : initialAspectRatio}
                    guides={false}
                    cropmove={onCropMove}
                    ref={cropperRef}
                    movable={false}
                    rotatable={false}
                    zoomable={false}
                    autoCropArea={1}
                    data={photo.cropperData}
                    background={false}
                />
                <Button
                    dataTrackId="post_photo_cropper_modal_apply"
                    size="large"
                    variant="primary"
                    shape="cube"
                    onClick={onSave}
                    full
                    isLoading={isCropping}
                    disabled={isCropping}
                >
                    {t('post_photo_cropper_modal_apply')}
                </Button>
            </StyledBody>
        </Modal>
    );
};
