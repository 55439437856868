import React from 'react';

import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import {
    SortTextClickable,
    SortTextContainer,
} from './SortSectionTitle.styled';

type Props = {
    title: string;
    orderByKey?: OrderBy;
};

export enum OrderBy {
    LISTING = 'listing',
    STATUS = 'status',
}

const SortSectionTitle = ({ title, orderByKey }: Props) => {
    const { t } = useTranslation();
    const [orderBy, setOrderBy] = useStateQueryParams('order_by');
    const handleClick = () => {
        if (!orderByKey) {
            return;
        }

        if (orderBy === orderByKey) {
            setOrderBy(`-${orderByKey}`);
        } else if (orderBy === `-${orderByKey}`) {
            setOrderBy('');
        } else {
            setOrderBy(orderByKey);
        }
    };

    const orderIcon = (): React.ReactNode => {
        if (!orderByKey) {
            return null;
        }

        if (!orderBy?.includes(orderByKey)) {
            return <i className="fa-sharp fa-solid fa-sort" />;
        }

        if (orderBy.startsWith('-')) {
            return <i className="fa-duotone fa-sort rotate_icon" />;
        }

        return <i className="fa-duotone fa-sort" />;
    };

    return (
        <SortTextContainer title={orderByKey}>
            <SortTextClickable clickable={!!orderByKey} onClick={handleClick}>
                <div>{t(title)}</div>
                {orderIcon()}
            </SortTextClickable>
        </SortTextContainer>
    );
};

export default SortSectionTitle;
