import {
    PaginatedMetricsResponse,
    ReviewAnalyticsDimension,
    ReviewAnalyticsFiltersType,
    ReviewAnalyticsMetric,
} from 'app/api/types/review_analytics';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'review_analytics';

export class ReviewAnalytics extends ApiResourceBaseClass {
    static async getMetrics<
        T extends ReviewAnalyticsDimension,
        U extends readonly ReviewAnalyticsMetric[],
    >(
        dimensions: T,
        metrics: U,
        filters: ReviewAnalyticsFiltersType,
    ): Promise<PaginatedMetricsResponse<T, U>> {
        const queryParams = {
            dimensions,
            metrics: metrics.join(','),
            ...filters,
        };
        const { data } = await ReviewAnalytics.get(`${RESOURCE_URI}/metrics`, queryParams);
        return data;
    }
}
