import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BannerContainer = styled.div<{ hasBanner?: boolean | number }>`
    ${({ hasBanner }) =>
        hasBanner &&
        css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 16px;
        `}
`;
