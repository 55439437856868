export const BUSINESS_SELECTION_ID = 'BUSINESS_SELECTION_ID';
export const KNOWLEDGE_SECTION_ID = 'KNOWLEDGE_SECTION_ID';
export const COMMUNICATION_SECTION_ID = 'COMMUNICATION_SECTION_ID';
export const INSTRUCTION_SECTION_ID = 'INSTRUCTION_SECTION_ID';
export type FRIENDLY_TONE_TYPE = 'Friendly';
export type CASUAL_TONE_TYPE = 'Casual';
export type PROFESSIONAL_TONE_TYPE = 'Professional';
export const CASUAL_TONE = 'Casual';

export enum ChatbotContentTypeEnum {
    text = 'text',
    qa = 'q&a',
}

export const ChatbotToneOfVoiceMapping = {
    Friendly: {
        title: 'chatbot_communication_friendly_tone_title',
        description: 'chatbot_communication_friendly_tone_description',
        icon: '✌',
    },
    Casual: {
        title: 'chatbot_communication_casual_tone_title',
        description: 'chatbot_communication_casual_tone_description',
        icon: '👕',
    },
    Professional: {
        title: 'chatbot_communication_professional_tone_title',
        description: 'chatbot_communication_professional_tone_description',
        icon: '👔',
    },
};

export type ChatbotType = {
    id: number;
    knowledge: Array<ChatbotKnowledgeType>;
    system_prompt_id: number;
    custom_prompt: string;
    tone_of_voice_id: number;
    handover_message: string;
    welcome_message: string;
    custom_prompt_enabled: boolean;
    enable_services: boolean;
    enable_attributes: boolean;
    name: string;

    enabled: boolean;
    created_at: string;
    updated_at?: string;
    activated_at?: string;
    business_ids: Array<string>;
};

export type ChatbotPatchPayload = {
    name?: string;
    handover_message?: string;
    custom_prompt_enabled?: boolean;
    custom_prompt?: string;
    tone_of_voice_id?: number;
    welcome_message?: string;
    enable_services?: boolean;
    enable_attributes?: boolean;
};

export type ChatbotKnowledgeQAContentItem = {
    question: string;
    answer: string;
};

export type ChatbotKnowledgeQAContent = {
    _version: string;
    items: Array<ChatbotKnowledgeQAContentItem>;
};

export type ChatbotKnowledgeQA = {
    id: number;
    content: ChatbotKnowledgeQAContent;
    content_type: ChatbotContentTypeEnum.qa;
    created_at: string;
    updated_at?: string;
};

export type ChatbotKnowledgeText = {
    id: number;
    content: string;
    content_type: ChatbotContentTypeEnum.text;
    created_at: string;
    updated_at?: string;
};

export type ChatbotKnowledgeType = ChatbotKnowledgeQA | ChatbotKnowledgeText;

export type ChatbotMessage = {
    role: 'user' | 'system' | 'assistant';
    content: string;
};

export type ChatbotPreviewResponse = {
    uuid: string;
};

export type ChatbotPreviewWSEvent = {
    uuid: string;
    response: ChatbotMessage;
};

export type ToneOfVoice = {
    id: number;
    label: FRIENDLY_TONE_TYPE | CASUAL_TONE_TYPE | PROFESSIONAL_TONE_TYPE;
    created_at: string;
    updated_at?: string;
};

export type BusinessValidationEntry = {
    business_id: string;
    business_name?: string;
    chatbot_id: string;
    chatbot_name?: string;
};

export type ChatbotBusinessValidationResponse = {
    businesses: Array<BusinessValidationEntry>;
};
