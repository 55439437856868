import styled from '@emotion/styled';
import { theme } from '@partoohub/ui';

import ControlledExpandableToggleBox from 'app/common/designSystem/components/molecules/ExpandableToggleBox/ControlledExpandableToggleBox';

export const StyledControlledExpandableToggleBox = styled(ControlledExpandableToggleBox)`
    border-radius: 0;
    border: unset;
    border-top: 1px solid ${theme.colors.default.alpha};
`;

export const PartnerCheckBoxes = styled.div`
    padding: 8px 16px;
    .boolean-box--container {
        margin: 8px 0;
    }
`;
