const retrieveQueryParam = (queryParam: string): string | null => {
    // @ts-ignore
    const url = new URL(window.location);
    const searchParams = new URLSearchParams(url.search);

    if (searchParams.has(queryParam)) {
        return searchParams.get(queryParam);
    }

    return null;
};

export default retrieveQueryParam;
