import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { V2FormattedUserData } from 'app/api/types/user';
import { applyFilterByBusinessFilters } from 'app/common/components/businessModalDeprecated/reducers';
import AnalyticsIconSidebar from 'app/common/components/icons/Sidebar/AnalyticsIconSidebar';
import ConversationsIconSideBar from 'app/common/components/icons/Sidebar/ConversationsIconSideBar';
import { CustomerExperienceIconSidebar } from 'app/common/components/icons/Sidebar/CustomerExperienceIconSidebar';
import HomepageIconSidebar from 'app/common/components/icons/Sidebar/HomepageIconSidebar';
import PostsIconSidebar from 'app/common/components/icons/Sidebar/PostsIconSidebar';
import SettingsIconSidebar from 'app/common/components/icons/Sidebar/SettingsIconSidebar';
import VisibilityIconSidebar from 'app/common/components/icons/Sidebar/VisibilityIconSidebar';
import { USE_HOMEPAGE } from 'app/common/data/featureFlags';
import IS_IFRAME from 'app/common/data/iframe';
import {
    BUSINESS_MESSAGE,
    CUSTOMER_EXPERIENCE,
    HOMEPAGE,
    KNOWLEDGE_MANAGEMENT,
    PRESENCE_MANAGEMENT,
    REVIEW_MANAGEMENT,
    SETTINGS,
} from 'app/common/data/productIds';
import WHITELIST_PROVIDERS from 'app/common/data/providers';
import { ADMIN, GROUP_MANAGER, ORG_ADMIN, PROVIDER } from 'app/common/data/roles';
import ProductButton from 'app/common/designSystem/components/molecules/Sidebar/Sidebar/ProductSidebar/ProductButton';
import { ProviderLogo } from 'app/common/designSystem/components/molecules/Sidebar/Sidebar/ProductSidebar/ProviderLogo';
import { SidebarFAB } from 'app/common/designSystem/components/molecules/Sidebar/Sidebar/ProductSidebar/SidebarFAB';
import {
    ProductSidebarContainer,
    ProductSidebarHeaderTitle,
} from 'app/common/designSystem/components/molecules/Sidebar/Sidebar/ProductSidebar.styled';
import env from 'app/common/services/getEnvironment';
import { useIsMobile } from 'app/common/services/screenDimensions';
import { useCanAccessCustomerExperiencePage } from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import { useHasHomepage } from 'app/pages/Homepage/hooks/useHasHomepage';
import { useConversationStats } from 'app/reviewManagement/messaging/hooks/useConversationStats';
import { useConversationUpdate } from 'app/reviewManagement/messaging/hooks/useConversationUpdate';
import { useNewReviewsCount } from 'app/reviewManagement/reviewList/hooks/useNewReviewsCount';
import { useReviewListWebsocket } from 'app/reviewManagement/reviewList/hooks/useReviewListWebsocket';
import {
    ANALYTICS_PATH,
    ANALYTICS_REVIEW_PATH,
    CONVERSATIONS_MESSAGING_PATH,
    CONVERSATIONS_PATH,
    CUSTOMER_EXPERIENCE_PATH,
    HOMEPAGE_PATH,
    POSTS_PATH,
    SETTINGS_PATH,
    VISIBILITY_DIFFUSION_PATH,
    VISIBILITY_LOCATION_PATH,
    VISIBILITY_PATH,
} from 'app/routing/routeIds';

import { HelpCenterButton } from './HelpCenterButton';
import { LogoutButton } from './LogoutButton';
import QuickActionButton from './QuickActionButton';

type Props = {
    sidebarProducts: Array<string>;
    role: string | null | undefined;
    userData: V2FormattedUserData | null;
};

const ProductSidebar: FC<Props> = ({ sidebarProducts, role, userData }) => {
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { data: conversationStats } = useConversationStats();
    const messagingNotification = conversationStats && conversationStats.unread_open > 0;

    const sidebarPartooProducts: Array<string> = sidebarProducts.map(s => s.toUpperCase());

    const hasAccessToMessaging =
        sidebarPartooProducts.includes(BUSINESS_MESSAGE) && role !== PROVIDER;
    const hasAccessToPosts = !userData?.sidebarExcludedPages.includes('google_post');
    useConversationUpdate();
    useReviewListWebsocket();

    const newReviewsCount = useNewReviewsCount();

    const { canAccessCustomerExperiencePage } = useCanAccessCustomerExperiencePage();

    const { hasHomepage } = useHasHomepage();

    return (
        <ProductSidebarContainer>
            <ProviderLogo />
            {!env.isProd() && <ProductSidebarHeaderTitle>{env.getEnv()}</ProductSidebarHeaderTitle>}
            {!isMobile &&
                !IS_IFRAME &&
                role &&
                [ADMIN, PROVIDER, ORG_ADMIN, GROUP_MANAGER].includes(role) && <SidebarFAB />}
            {USE_HOMEPAGE && !IS_IFRAME && hasHomepage && (
                <ProductButton
                    id={HOMEPAGE}
                    to={HOMEPAGE_PATH}
                    activePath={HOMEPAGE_PATH}
                    tooltip={t('homepage_title')}
                    logo={HomepageIconSidebar}
                />
            )}
            <ProductButton
                id={KNOWLEDGE_MANAGEMENT}
                to={VISIBILITY_LOCATION_PATH}
                activePath={VISIBILITY_PATH}
                tooltip={t('sidebar_visibility')}
                logo={VisibilityIconSidebar}
                onResetFilters={() => {
                    // ugly, but the business filters on this page are not
                    // implemented in a declarative manner
                    if (matchPath(pathname, VISIBILITY_DIFFUSION_PATH)) {
                        // trigger the refresh on the next tick, otherwise the
                        // Redux state is not yet up-to-date :/
                        setTimeout(() => {
                            dispatch(applyFilterByBusinessFilters());
                        }, 0);
                    }
                }}
            />
            {hasAccessToMessaging && (
                <ProductButton
                    id={REVIEW_MANAGEMENT}
                    to={CONVERSATIONS_MESSAGING_PATH}
                    activePath={CONVERSATIONS_PATH}
                    tooltip={t('sidebar_product_conversations')}
                    logo={ConversationsIconSideBar}
                    showRedDot={messagingNotification}
                />
            )}
            {Object.values(canAccessCustomerExperiencePage).some(Boolean) && (
                <ProductButton
                    id={CUSTOMER_EXPERIENCE}
                    to={CUSTOMER_EXPERIENCE_PATH}
                    activePath={CUSTOMER_EXPERIENCE_PATH}
                    tooltip={t('customer_experience')}
                    logo={CustomerExperienceIconSidebar}
                    showRedDot={
                        sidebarPartooProducts.includes(REVIEW_MANAGEMENT) && !!newReviewsCount
                    }
                />
            )}
            {[PRESENCE_MANAGEMENT, REVIEW_MANAGEMENT].some(element =>
                sidebarPartooProducts.includes(element),
            ) && (
                <ProductButton
                    id={PRESENCE_MANAGEMENT}
                    to={ANALYTICS_PATH}
                    activePath={ANALYTICS_PATH}
                    tooltip={t('analytics')}
                    logo={AnalyticsIconSidebar}
                    onResetFilters={() => {
                        // ugly, but some filters on theses pages are not implemented
                        // in a declarative manner
                        if (matchPath(pathname, ANALYTICS_REVIEW_PATH)) {
                            // same here
                            setTimeout(() => {
                                dispatch(applyFilterByBusinessFilters());
                            }, 0);
                        }
                    }}
                />
            )}
            {hasAccessToPosts && (
                <ProductButton
                    id={SETTINGS}
                    to={POSTS_PATH}
                    activePath={POSTS_PATH}
                    tooltip={t('bar_tooltip_posts')}
                    logo={PostsIconSidebar}
                />
            )}
            {!IS_IFRAME && (
                <ProductButton
                    id={SETTINGS}
                    to={SETTINGS_PATH}
                    activePath={SETTINGS_PATH}
                    tooltip={t('bar_tooltip_settings')}
                    logo={SettingsIconSidebar}
                />
            )}
            {!IS_IFRAME && <QuickActionButton />}
            {(!IS_IFRAME ||
                (userData?.providerName &&
                    WHITELIST_PROVIDERS.includes(userData.providerName))) && <HelpCenterButton />}
            {!IS_IFRAME && <LogoutButton />}
        </ProductSidebarContainer>
    );
};

export default ProductSidebar;
