import { useEffect, useRef } from 'react';

interface Options {
    onClose?: () => void;
    windowName?: string;
    windowFeatures?: string;
    intervalMs?: number;
}

/**
 * Utility hook to spawn a popup window.
 *
 * It keeps track of when the popup is closed.
 * https://stackoverflow.com/a/48240128/9307290
 *
 * @example
 * const open = usePopupWindow("/oauth/start", {onClose: () => { alert('closed !') }})
 *
 * return (
 *   <button onClick={() => open()}/>
 * )
 */
export const usePopupWindow = (
    url: string,
    options?: Options,
): (() => void) => {
    const {
        onClose,
        windowName = '',
        windowFeatures = 'height=600, width=800',
        intervalMs = 500,
    } = options || {};

    const mounted = useRef(true);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    });

    return () => {
        const win = window.open(url, windowName, windowFeatures);

        if (onClose !== undefined) {
            const interval = setInterval(() => {
                if (win?.closed) {
                    clearInterval(interval);

                    // Usually we don't want side effects to happen if the component
                    // is unmounted while the popup is closed. Otherwise, typically
                    // if onClose contains state updates it will show a warning.
                    if (mounted.current) {
                        onClose();
                    }
                }
            }, intervalMs);
        }
    };
};
