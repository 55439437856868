import { ReactNode, useContext, useEffect } from 'react';

import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';
import { useIsTablet } from 'app/hooks/useIsTablet';
import usePlatformSelection from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/hooks/usePlatformSelection';
import PostFormTypePlatform from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/screens/TypePlatform/PostFormTypePlatform';
import { ALERT_TYPE } from 'app/presence/googlePosts/variables/googlePostType';

import PostCreationModalTemplate from './components/ModalTemplate/PostCreationModalTemplate';
import PostFormTypePlatformInfo from './components/PostConfigInfoRightPart/PostFormTypePlatformInfo';
import { PostImageInfo } from './components/PostImageInfo/PostImageInfo';
import PostPreview from './components/PostPreviewRightPart/PostPreview';
import { NewPostContext, NewPostContextType } from './context/NewPost';
import {
    OverflowLeftModal,
    OverflowRightModal,
    ProgressContainer,
    ProgressContent,
} from './PostCreationModal.styled';
import CustomField from './screens/CustomField/CustomField';
import PostFormGlobalContent from './screens/GlobalContent/PostFromGlobalContent';
import { ImageUpload } from './screens/ImageUpload/ImageUpload';
import { PostFormPublish } from './screens/Publish/PostFormPublish';

type FormStep = {
    left: ReactNode;
    right: ReactNode;
};

export type FormSteps = Array<FormStep>;

const platformSelectionStep: FormStep = {
    left: <PostFormTypePlatform />,
    right: <PostFormTypePlatformInfo />,
};

const imageSelectionStep: FormStep = {
    left: <ImageUpload />,
    right: <PostImageInfo />,
};

export const infoStep: FormStep = {
    left: <PostFormGlobalContent />,
    right: <PostPreview />,
};

const customFieldStep: FormStep = {
    left: <CustomField />,
    right: <PostPreview />,
};

const publishStep: FormStep = {
    left: <PostFormPublish />,
    right: <PostPreview />,
};

const getFormSteps = (
    hasMultiplatform: boolean,
    containsGoogle: boolean,
    hasOnlyGoogle: boolean,
    isEditing: boolean,
    type: string,
): FormSteps => {
    const flowSteps: FormSteps = [];
    flowSteps.push(platformSelectionStep);
    if ((!isEditing || hasOnlyGoogle) && type !== ALERT_TYPE) {
        flowSteps.push(imageSelectionStep);
    }

    flowSteps.push(infoStep);

    if (containsGoogle && hasMultiplatform) {
        flowSteps.push(customFieldStep);
    }

    flowSteps.push(publishStep);

    return flowSteps;
};

export default function PostCreationModal() {
    const {
        step = 0,
        show = false,
        resetAndClose,
        isEditing,
        formFields: { type },
        setStep,
        allSteps,
        setAllSteps,
    } = useContext<NewPostContextType>(NewPostContext);

    const { hasMultiplatform, containsGoogle, hasOnlyGoogle } = usePlatformSelection();

    useEffect(() => {
        setAllSteps(getFormSteps(hasMultiplatform, containsGoogle, hasOnlyGoogle, isEditing, type));
    }, [hasMultiplatform, containsGoogle, hasOnlyGoogle, isEditing, type]);

    const isTablet = useIsTablet();

    useEffect(() => {
        if (isEditing) {
            setStep(allSteps.indexOf(infoStep));
        }
    }, [allSteps, isEditing, type, hasMultiplatform, hasOnlyGoogle]);

    return (
        <FullScreenModal
            show={show}
            onHideClick={resetAndClose}
            childrenLeft={
                <OverflowLeftModal>
                    <ProgressContainer>
                        <ProgressContent progress={(step + 1) / allSteps.length} />
                    </ProgressContainer>
                    <PostCreationModalTemplate onHideClick={resetAndClose}>
                        {allSteps[step]?.left}
                    </PostCreationModalTemplate>
                </OverflowLeftModal>
            }
            childrenRight={
                !isTablet ? (
                    <OverflowRightModal>{allSteps[step]?.right}</OverflowRightModal>
                ) : undefined
            }
        />
    );
}
