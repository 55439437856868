import styled from '@emotion/styled';
import { Icon, Text } from '@partoohub/ui';

export const InnerTextButtonContainer = styled(Text)`
    display: flex;
    align-items: center;
    column-gap: 8px;
`;

export const StyledIcon = styled(Icon)`
    position: absolute;
    bottom: 12px;
    right: -2px;
`;

export const CTAButtonsContainer = styled.div`
    display: flex;
    column-gap: 8px;

    > svg {
        width: 40px;
        height: 40px;
    }
`;
