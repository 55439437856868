import { useOrgFieldMetadata } from 'app/businessEditV2/hooks/permissions/useFieldMetadata';

export enum CEPermissions {
    RMReply = 'review_reply',
    FBMReply = 'feedback_result_reply',
    RMReplySuggestion = 'review_reply_suggestion',
    FBMReplySuggestion = 'feedback_result_reply_suggestion',
}

export const useHasCEPermissions = (): {
    permissions: Record<CEPermissions, boolean | undefined>;
    isLoading: boolean;
} => {
    const permissions = {
        [CEPermissions.RMReply]: useOrgFieldMetadata('review_management', 'review_reply')?.enabled,
        [CEPermissions.FBMReply]: useOrgFieldMetadata(
            'feedback_management',
            'feedback_result_reply',
        )?.enabled,
        [CEPermissions.RMReplySuggestion]: useOrgFieldMetadata(
            'review_management',
            'review_reply_suggestion',
        )?.enabled,
        [CEPermissions.FBMReplySuggestion]: useOrgFieldMetadata(
            'feedback_management',
            'feedback_result_reply_suggestion',
        )?.enabled,
    };

    return {
        permissions,
        isLoading: Object.values(permissions).some(permission => permission === undefined),
    };
};
