import { Modal } from '@partoohub/ui';

import { ExportModalForm } from '../ExportModalForm/ExportModalForm';

type Props = {
    showModal: boolean;
    closeModal: () => void;
};

export const ExportModal = ({ closeModal, showModal }: Props) => {
    return (
        <Modal isOpen={showModal} closeModal={closeModal}>
            <ExportModalForm closeModal={closeModal} />
        </Modal>
    );
};
