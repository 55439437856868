import { FC } from 'react';

import { Text } from '@partoohub/ui';

import { TipsCarouselItem, TipsImage, TipsTitle } from './Tips.styled';

interface Props {
    image: string;
    title: string;
    description: string;
}

const TipsItem: FC<Props> = ({ image, title, description }) => {
    return (
        <TipsCarouselItem>
            <TipsImage src={image} />
            <TipsTitle variant="heading5">{title}</TipsTitle>
            <Text variant="bodyMRegular">{description}</Text>
        </TipsCarouselItem>
    );
};

export default TipsItem;
