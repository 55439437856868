import React, { useEffect } from 'react';

import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import { AppFeatureFlagsProvider } from 'app/common/contexts/featureFlags';
import { LanguageContextProvider } from 'app/common/services/getLang';
import { removeScripts, setupApp } from 'app/common/services/scripts';
import { BusinessListGroupProvider } from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupContext';
import { RoutesComponent } from 'app/routing';
import { CustomRouter } from 'app/routing/components/CustomRouter';
import RTLCacheProvider from 'app/RTLCacheProvider';
import { sdkBridge } from 'app/SDKBridge';
import configureStore from 'app/states/configureStore';

import queryClient from 'app/states/queryClient';
import { Theme } from 'app/Theme';

const MemoizedRoutes = React.memo(RoutesComponent);
const store: any = configureStore();

const App = () => {
    sdkBridge.registerStore(store);

    useEffect(() => {
        const loadScripts = async () => {
            const results = await setupApp();
            results.forEach(result => {
                if (result.status === 'rejected') {
                    console.error(result.reason.message);
                } else {
                    // eslint-disable-next-line no-console
                    result?.value && console.log(result.value);
                }
            });
        };

        loadScripts();

        return () => {
            removeScripts();
        };
    }, []);

    return (
        <Provider store={store}>
            <CustomRouter>
                <QueryClientProvider client={queryClient}>
                    <Theme>
                        <AppFeatureFlagsProvider>
                            <RTLCacheProvider>
                                <BusinessListGroupProvider>
                                    <LanguageContextProvider>
                                        <MemoizedRoutes />
                                    </LanguageContextProvider>
                                </BusinessListGroupProvider>
                            </RTLCacheProvider>
                            <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
                        </AppFeatureFlagsProvider>
                    </Theme>
                </QueryClientProvider>
            </CustomRouter>
        </Provider>
    );
};

export default App;
