import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

export const ProductTogglesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-bottom: 24px;
`;

export const ToggleContainer = styled.div`
    display: flex;
`;

type ToggleNameProps = {
    disabled: boolean;
};

export const ToggleName = styled.div<ToggleNameProps>`
    color: ${HEX_COLORS.blackberry};
    font-weight: bold;
    margin-left: 8px;

    ${props =>
        props.disabled &&
        css`
            color: ${DISABLED_COLOR};
        `};
`;
