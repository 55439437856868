import { css } from '@emotion/react';

import { FontAwesomeIconsPartooUsed, IconPrefix, Option } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { Group as GroupType } from 'app/api/types/groupsV2';
import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';
import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { GroupsSettingsQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

interface Props {
    group: GroupType;
    sectionId: number;
}

export const GroupActionButton = ({ group, sectionId }: Props) => {
    const { t } = useTranslation();
    const [, setEditGroup] = useStateQueryParams(GroupsSettingsQueryKeys.EDIT, true);
    const [, setDeleteGroup] = useStateQueryParams(GroupsSettingsQueryKeys.DELETE, true);

    const options: Array<Option> = [
        {
            name: GroupsSettingsQueryKeys.EDIT,
            value: GroupsSettingsQueryKeys.EDIT,
            label: <MenuLabelItem text={t('edit')} icon={['fa-pen-clip', IconPrefix.REGULAR]} />,
            disabled: false,
        },
        {
            name: GroupsSettingsQueryKeys.DELETE,
            value: GroupsSettingsQueryKeys.DELETE,
            label: (
                <MenuLabelItem
                    text={t('delete')}
                    tooltipText={group.has_puller ? t('delete_group_contact_support') : undefined}
                    icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                    color="danger"
                />
            ),
            disabled: group.has_puller,
        },
    ];

    const onMenuClick = (value: string) => {
        if (value === GroupsSettingsQueryKeys.EDIT) {
            setEditGroup(`${sectionId},${group.id}`);
        } else if (value === GroupsSettingsQueryKeys.DELETE) {
            setDeleteGroup(`${sectionId},${group.id}`);
        }
    };

    return (
        <ActionButtonWithMenu
            dataTrackId=""
            icon={['fa-ellipsis-v', IconPrefix.SOLID]}
            appearance="outlined"
            options={options}
            onMenuClick={onMenuClick}
            cssMenu={css`
                min-width: 170px;
                top: 60px;
                right: -24px;
                left: unset;
            `}
            tooltip={t('more')}
        />
    );
};
