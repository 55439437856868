import React from 'react';

import { TextArea } from '@partoohub/ui';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { errorMaxCharacters } from 'app/businessEditV2/utils/utils';

import { ErrorWarningText, TextFieldWrapper } from './CustomFieldsTypeText.styled';

type Props = {
    value: string;
    name: string;
    onChange: (newValue: string | null) => void;
    disabled: boolean;
    error: string | null | undefined;
    maxLen?: number | null;
    displayName?: string;
};

const onChangeWithFilter = (value, onChange) => {
    const newValue = value === '' ? null : value;
    return onChange(newValue);
};

const Component = ({ value, name, onChange, disabled, maxLen, error, displayName }: Props) => {
    return (
        <TextFieldWrapper>
            <TextArea
                value={value ?? ''}
                onChange={e => onChangeWithFilter(e, onChange)}
                label={displayName ?? name}
                characterCount={maxLen ?? undefined}
                disabled={disabled}
                error={!!error || errorMaxCharacters(value, maxLen)}
                dataTrackId={'custom-fields-type-text'}
                rows={1}
            />
            {!!error && (
                <ErrorWarningText variant="bodySRegular" color="danger">
                    {error}
                </ErrorWarningText>
            )}
        </TextFieldWrapper>
    );
};

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'onChange' | 'value';

const CustomFieldsTypeText = ({
    name,
    control,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { ref: _ref, ...usedFields } }) => (
            <Component {...props} {...usedFields} />
        )}
    />
);

export default CustomFieldsTypeText;
