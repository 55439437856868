import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { CustomFieldsQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

import CustomFieldForm from '../CustomFieldForm';

type Props = {
    closeModal: () => void;
};

const CustomFieldsCreateContent = ({ closeModal }: Props) => {
    const [createCustomField] = useStateQueryParams(CustomFieldsQueryKeys.CREATE);

    return (
        <CustomFieldForm
            customFieldsType={createCustomField}
            editMode={false}
            closeModal={closeModal}
        />
    );
};

export default CustomFieldsCreateContent;
