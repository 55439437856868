import { connect } from 'react-redux';

import { ORG_ADMIN, PROVIDER } from 'app/common/data/roles';
import { meOrgIdSelector, meRoleSelector } from 'app/common/reducers/me';
import { orgByIdSelector } from 'app/common/reducers/orgs';
import { AppState } from 'app/states/reducers';

import HeaderActionsComponent from './HeaderActions';

const mapStateToProps = ({ me, orgs }: AppState) => {
    const role = meRoleSelector(me);
    const orgId = meOrgIdSelector(me);
    const meOrg = orgByIdSelector(orgs, orgId);
    // meOrg is undefined until the infos we need in the state are loaded
    const displayQuickConnectButton =
        meOrg && // Only users to have access to quick connect are PROVIDER and ORG_ADMIN of enterprises with at least 10 businesses
        (role === PROVIDER || role === ORG_ADMIN);
    return {
        displayQuickConnectButton,
    };
};

const HeaderActions = connect(mapStateToProps)(HeaderActionsComponent);

export default HeaderActions;
