import styled from '@emotion/styled';

export const StyledAddress = styled.span`
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const StyledReviewAuthor = styled.div`
    grid-area: author;
    align-self: flex-start;
    font-size: 14px;
    font-weight: 700;
    color: #333333;
    span {
        white-space: nowrap;
        @media (max-width: 1200px) {
            white-space: pre-wrap;
        }
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const StyledReviewAddress = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 12px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
