import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import API from 'app/api/v2/api_calls';

import {
    displayModalSelector,
    hideConfirm,
    pendingParamsSelector,
} from 'app/common/components/ConfirmModal/reducers/confirm';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import dataLayer from 'app/common/utils/dataLayer';
import { useUpdateReviewQueryData } from 'app/reviewManagement/reviewList/hooks/queryData/useUpdateReviewQueryData';
import { useShowReviewErrorToast } from 'app/reviewManagement/reviewList/hooks/ReviewCard/useShowReviewErrorToast';

import { confirmModalSelector } from 'app/states/reducers';

import { deleteReviewCommentData } from '../../ReviewTableSection/ReviewCard/CommentSection/CommentTable/utils/deleteReviewCommentData';

export const ConfirmDeleteCommentModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const updateReviewQueryData = useUpdateReviewQueryData();
    const showReviewErrorToast = useShowReviewErrorToast();

    const showModal = displayModalSelector(
        useSelector(confirmModalSelector),
        'delete_review_modal',
    );
    const { review, commentId } = pendingParamsSelector(useSelector(confirmModalSelector));

    const { mutate: deleteCommentMutate } = useMutation(
        (commentId: string) => {
            return API.comments.deleteComment(commentId);
        },
        {
            onSuccess: () => {
                dataLayer.pushDict('delete_review_reply');
                updateReviewQueryData(deleteReviewCommentData(review, commentId), {
                    markReviewAs: 'not-treated',
                });
            },
            onError: (error: Record<string, any>) => {
                showReviewErrorToast(review, error, dispatch);
            },
        },
    );

    const handleConfirm = () => {
        if (!!commentId) {
            deleteCommentMutate(commentId);
        }
        dispatch(hideConfirm());
    };

    const handleHide = () => {
        dispatch(hideConfirm());
    };

    return (
        <ConfirmModal
            show={showModal}
            modalFor="delete"
            title={t('delete_reply')}
            content={t('delete_reply_modal_text')}
            onConfirm={handleConfirm}
            onHide={handleHide}
            trackId="delete-reply"
        />
    );
};
