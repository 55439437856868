import React from 'react';

import { useTranslation } from 'react-i18next';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';

type Props = {
    colorState?: ColorState;
};

const ContactSectionContent = ({ colorState = ColorState.OK }: Props) => {
    const { t } = useTranslation();
    const { data: business } = useBusiness();

    switch (colorState) {
        case ColorState.PRECHECK_ERRORS:
            return <>{t('business_edit_content_correct_this_field')}</>;
        case ColorState.MISSING_WARNING:
        case ColorState.CLOSED:
        case ColorState.DISABLED:
            return <>{t('business_edit_content_contact_fill_coordinates')}</>;
        case ColorState.OK:
            return (
                <>
                    <i className="fa-solid fa-envelope" />
                    <span>{business?.contacts[0]?.email ? '1' : '0'}</span>
                    <i className="fa-solid fa-phone" />
                    <span>
                        {business?.contacts[0]?.phone_numbers[0] ? '1' : '0'}
                    </span>
                    <i className="fa-solid fa-fax" />
                    <span>{business?.contacts[0]?.fax ? '1' : '0'}</span>
                </>
            );
        default:
            return null;
    }
};

export default ContactSectionContent;
