import React from 'react';

import { HeaderContainer, Title } from './Header.styled';

type Props = {
    title: string;
};

const Header = ({ title }: Props) => {
    return (
        <HeaderContainer>
            <Title>{title}</Title>
        </HeaderContainer>
    );
};

export default Header;
