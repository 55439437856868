import React from 'react';

import { Controller, UseFormWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { useTranslation } from 'react-i18next';

import { ControlledPasswordField } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm/hookControllerWrapper/ControlledPasswordField';
import ControlledToggle from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm/hookControllerWrapper/ControlledToggle';

import AuthorizationService from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/authorization';

import {
    PasswordSubformContainer,
    ToggleContainer,
    ToggleText,
    UpdatePasswordField,
} from './PasswordSubform.styled';

type Props = {
    control: Control<any>;
    watch: UseFormWatch<any>;
    authorizationService: AuthorizationService;
    displaySsoToggle: boolean;
    errorMessage?: string;
    hasError?: boolean;
};

const PasswordEditionSubform = ({
    control,
    watch,
    authorizationService,
    displaySsoToggle,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const ssoOnly = watch('ssoOnly');
    const displayPassword = !(displaySsoToggle && ssoOnly);
    const updatePassword = watch('updatePassword');

    return (
        <PasswordSubformContainer>
            {displaySsoToggle && (
                <ToggleContainer>
                    <ControlledToggle name="ssoOnly" control={control} />
                    <ToggleText>{t('user_connect_with_sso_only')}</ToggleText>
                </ToggleContainer>
            )}
            {displayPassword && (
                <>
                    <Controller
                        name="updatePassword"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <UpdatePasswordField onClick={() => onChange(!value)}>
                                    <i className="fa-solid fa-key" />
                                    {t(value ? t('do_not_change_password') : t('change_password'))}
                                    {value ? (
                                        <i className="fas fa-angle-down" />
                                    ) : (
                                        <i className="fas fa-angle-right" />
                                    )}
                                </UpdatePasswordField>
                            );
                        }}
                    />
                    {updatePassword && (
                        <ControlledPasswordField
                            dataTrackId="password"
                            name="password"
                            watch={watch}
                            control={control}
                            required
                            {...props}
                        />
                    )}
                </>
            )}
        </PasswordSubformContainer>
    );
};

export default PasswordEditionSubform;
