import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const PlaceActionLinksContainer = styled.div`
    margin-bottom: 24px;
`;

export const PlaceActionLinkName = styled(Text)`
    margin-bottom: 16px;
`;

export const PlaceActionLinkUrlContainer = styled.div`
    display: flex;
    gap: 16px;

    div:first-of-type {
        flex-grow: 1;
    }
`;

export const IconButtonsContainer = styled.div`
    margin-top: 8px;
    display: flex;
`;

export const PlaceActionLinkUrlsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
