import { useEffect, useRef } from 'react';

import { TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useImagesUploaderContext } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/hooks/useImagesUploaderContext';
import {
    ImagesUploaderErrorEnum,
    getErrors,
} from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/utils';
import { errorMaxCharacters } from 'app/businessEditV2/utils/utils';

type ImageUploaderTextFieldProps = {
    imageIndex: number;
    field: string;
    maxLength: number;
};

export const ImageUploaderTextField = ({
    imageIndex,
    field,
    maxLength,
}: ImageUploaderTextFieldProps) => {
    const { t } = useTranslation();
    const { images, errors, updateTextField } = useImagesUploaderContext();
    const value = images?.[imageIndex]?.texts[field] || '';
    const error = getErrors(errors, imageIndex).find(
        err => err.message === ImagesUploaderErrorEnum.MAX_LEN && !!err.data?.[field],
    );

    const myRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (error) {
            myRef?.current?.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
            });
        }
    }, [error]);

    return (
        <TextInput
            ref={myRef}
            dataTrackId="visibility_location__form_custom_fields__images_uploader_text_field"
            label={field}
            value={value}
            onChange={v => updateTextField(imageIndex, field, v)}
            error={errorMaxCharacters(value, maxLength)}
            notice={!!error && (t(error.message, error.data[field]) as string)}
            characterCount={maxLength}
        />
    );
};
