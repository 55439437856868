import React from 'react';

const NegativeIcon = () => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.083 12.3125C10.7705 12.5938 10.3018 12.5938 10.0205 12.2812C9.80176 12.0312 9.39551 11.75 8.83301 11.75C8.23926 11.75 7.83301 12.0312 7.61426 12.2812C7.33301 12.5938 6.86426 12.5938 6.55176 12.3125C6.23926 12.0312 6.23926 11.5625 6.52051 11.25C6.95801 10.7812 7.73926 10.25 8.83301 10.25C9.89551 10.25 10.6768 10.7812 11.1143 11.25C11.3955 11.5625 11.3955 12.0312 11.083 12.3125ZM5.33301 7.5C5.33301 7.25 5.42676 7 5.61426 6.8125L4.64551 6.5C4.39551 6.40625 4.27051 6.125 4.33301 5.84375C4.42676 5.59375 4.70801 5.4375 4.98926 5.53125L7.98926 6.53125C8.23926 6.625 8.36426 6.90625 8.30176 7.1875C8.20801 7.4375 7.92676 7.5625 7.64551 7.5L7.33301 7.375C7.33301 7.40625 7.33301 7.46875 7.33301 7.5C7.33301 8.0625 6.89551 8.5 6.33301 8.5C5.77051 8.5 5.33301 8.0625 5.33301 7.5ZM12.333 7.5C12.333 8.0625 11.8955 8.5 11.333 8.5C10.7705 8.5 10.333 8.0625 10.333 7.5C10.333 7.46875 10.333 7.40625 10.333 7.375L9.98926 7.5C9.70801 7.5625 9.42676 7.4375 9.33301 7.1875C9.27051 6.90625 9.39551 6.625 9.64551 6.53125L12.6455 5.53125C12.9268 5.4375 13.208 5.59375 13.3018 5.84375C13.3643 6.125 13.2393 6.40625 12.9893 6.5L12.0205 6.8125C12.208 6.96875 12.333 7.25 12.333 7.5ZM0.833008 8C0.833008 3.59375 4.39551 0 8.83301 0C13.2393 0 16.833 3.59375 16.833 8C16.833 12.4375 13.2393 16 8.83301 16C4.39551 16 0.833008 12.4375 0.833008 8ZM8.83301 14.5C12.3955 14.5 15.333 11.5938 15.333 8C15.333 4.4375 12.3955 1.5 8.83301 1.5C5.23926 1.5 2.33301 4.4375 2.33301 8C2.33301 11.5938 5.23926 14.5 8.83301 14.5Z"
            fill="#FF426E"
        />
    </svg>
);

export default NegativeIcon;
