import { useTheme } from '@emotion/react';
import { Icon, IconPrefix, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { EmptyPlaceholderContainer } from './EmptyPlaceholder.styled';

export const EmptyPlaceholder = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <EmptyPlaceholderContainer>
            <Icon
                icon={['fa-chart-simple', IconPrefix.SOLID]}
                color={theme.colors.default.alpha}
                iconSize="26px"
            />
            <Text variant="bodyMRegular" color="secondary">
                {t('homepage_stats_empty_placeholder')}
            </Text>
        </EmptyPlaceholderContainer>
    );
};
