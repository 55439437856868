import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';

import { AccountPartnerName, V2FormattedLocationData } from 'app/api/types/account';
import LocationsList from 'app/businessConnection/components/modals/ConnectionModal/SearchLocationsSection/FoundLocationsSection/FoundLocations/LocationsList';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';

type Props = {
    locations: Array<V2FormattedLocationData>;
    isFetching: boolean;
    partnerName: AccountPartnerName;
};

const FoundLocationsSection = ({ locations, isFetching, partnerName }: Props) => {
    const { t } = useTranslation();
    if (locations.length > 0 || isFetching) {
        return (
            <LocationsList
                locations={locations}
                partnerName={partnerName}
                isFetching={isFetching}
            />
        );
    }

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };

    return (
        <div className="margin_top--triple margin_bottom--triple">
            <NotFoundTemplate
                show
                small
                title={t('no_location_page_found')}
                subtitle={t(`no_${partnerName}_location_found_description`)}
                imgSrc={illustrationElement}
                withButton={false}
            />
        </div>
    );
};

export default FoundLocationsSection;
