export const IllustrationSlide4 = () => {
    return (
        <svg
            width="157"
            height="95"
            viewBox="0 0 157 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_0_2143)">
                <rect
                    width="157"
                    height="95"
                    rx="8"
                    fill="url(#paint0_linear_0_2143)"
                    fillOpacity="0.12"
                />
                <g filter="url(#filter0_d_0_2143)">
                    <g clipPath="url(#clip1_0_2143)">
                        <rect
                            x="78.6864"
                            y="14.4121"
                            width="55.9201"
                            height="55.9201"
                            rx="5.59201"
                            transform="rotate(13.5132 78.6864 14.4121)"
                            fill="white"
                        />
                        <rect
                            x="135.034"
                            y="8.61151"
                            width="3.25593"
                            height="111.281"
                            transform="rotate(46.6586 135.034 8.61151)"
                            fill="#CFCFCF"
                        />
                        <rect
                            x="163.054"
                            y="53.7573"
                            width="3.09032"
                            height="62.4245"
                            transform="rotate(103.513 163.054 53.7573)"
                            fill="#CFCFCF"
                        />
                        <rect
                            x="100.289"
                            y="42.4876"
                            width="3.09032"
                            height="62.4245"
                            transform="rotate(103.513 100.289 42.4876)"
                            fill="#CFCFCF"
                        />
                        <rect
                            x="114.178"
                            y="30.9413"
                            width="3.09032"
                            height="62.4245"
                            transform="rotate(133.513 114.178 30.9413)"
                            fill="#CFCFCF"
                        />
                        <rect
                            x="128.019"
                            y="100.779"
                            width="3.09032"
                            height="62.4245"
                            transform="rotate(143.76 128.019 100.779)"
                            fill="#CFCFCF"
                        />
                        <circle
                            cx="70.554"
                            cy="36.6128"
                            r="13.5974"
                            transform="rotate(13.5132 70.554 36.6128)"
                            stroke="#CFCFCF"
                            strokeWidth="2.47226"
                        />
                        <path
                            d="M78.4788 39.1006L91.0183 29.1199C91.6456 28.6206 92.5227 28.5811 93.1924 29.0218L119.67 46.4483C120.323 46.8779 120.637 47.6706 120.454 48.4304L118.559 56.3149C118.39 57.017 117.829 57.5573 117.121 57.6995L89.471 63.2536C88.625 63.4236 87.7736 62.9875 87.4172 62.2017L77.9449 41.3173C77.5939 40.5434 77.8139 39.6299 78.4788 39.1006Z"
                            fill="#0085F2"
                            fillOpacity="0.12"
                            stroke="#0085F2"
                            strokeWidth="1.23613"
                        />
                    </g>
                </g>
                <g filter="url(#filter1_d_0_2143)">
                    <path
                        d="M118.568 63.4375C110.929 63.4375 104.714 69.6522 104.714 77.2917C104.714 84.9312 110.929 91.1458 118.568 91.1458C126.208 91.1458 132.423 84.9312 132.423 77.2917C132.423 69.6522 126.208 63.4375 118.568 63.4375Z"
                        fill="#2CB884"
                    />
                    <path
                        d="M125.456 74.3558L117.951 81.86C117.726 82.0852 117.431 82.1985 117.135 82.1985C116.84 82.1985 116.544 82.0852 116.319 81.86L112.567 78.1079C112.115 77.6566 112.115 76.9269 112.567 76.4755C113.018 76.024 113.748 76.024 114.199 76.4755L117.135 79.4114L123.823 72.7234C124.275 72.2719 125.004 72.2719 125.456 72.7234C125.907 73.1748 125.907 73.9043 125.456 74.3558Z"
                        fill="white"
                    />
                </g>
                <g filter="url(#filter2_d_0_2143)">
                    <rect
                        x="22.8854"
                        y="27.9716"
                        width="41.9735"
                        height="57.0087"
                        rx="7.51763"
                        transform="rotate(-15 22.8854 27.9716)"
                        fill="white"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M50.4834 49.1136C45.2936 50.5042 39.9612 47.4322 38.5732 42.2521C37.1852 37.072 40.2672 31.7454 45.457 30.3548C50.6468 28.9642 55.9792 32.0361 57.3672 37.2163C58.7552 42.3964 55.6732 47.723 50.4834 49.1136Z"
                    fill="#F6F8FC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M47.814 38.6141L48.5084 41.2057L52.1207 40.2378C52.1896 41.1169 51.9068 41.9531 51.3233 42.6187L53.9429 43.7184C54.8995 42.212 55.1715 40.2983 54.6248 38.258C54.4975 37.7828 54.3325 37.3375 54.1356 36.9202L47.814 38.6141Z"
                    fill="#3E82F1"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M49.2215 43.8672C47.4733 44.3356 45.6778 43.5536 44.7257 42.1114L42.9484 44.4482C44.6395 46.3419 47.3116 47.235 49.9355 46.5319C51.7478 46.0463 53.1064 45.0392 53.9428 43.7183L51.3232 42.6186C50.8298 43.1817 50.1247 43.6252 49.2215 43.8672"
                    fill="#32A753"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M44.1741 40.895C44.0558 40.4535 44.017 40.0036 44.0435 39.5658L41.336 38.4307C41.1237 39.4569 41.1385 40.5497 41.4282 41.6308C41.7179 42.7118 42.2514 43.6656 42.9484 44.4482L44.7257 42.1114C44.4837 41.7455 44.2924 41.3365 44.1741 40.895"
                    fill="#F9BB00"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M47.0592 35.7973C48.0446 35.5332 49.02 35.6342 49.8935 36.1117L51.3039 33.6742C49.8517 32.9047 48.1545 32.6479 46.3452 33.1327C43.7213 33.8358 41.8538 35.9452 41.336 38.4308L44.0436 39.5658C44.147 37.8408 45.311 36.2657 47.0592 35.7973"
                    fill="#E74133"
                />
                <path
                    d="M39.7575 61.8922C39.5541 61.1331 40.0046 60.3528 40.7637 60.1494L65.2452 53.5896C66.0043 53.3862 66.7846 53.8367 66.988 54.5958L67.2243 55.4775C67.4277 56.2366 66.9772 57.0169 66.218 57.2203L41.7366 63.7801C40.9774 63.9835 40.1971 63.533 39.9937 62.7739L39.7575 61.8922Z"
                    fill="#0085F2"
                    fillOpacity="0.12"
                />
                <path
                    d="M42.0271 70.3639C41.8237 69.6048 42.2742 68.8245 43.0334 68.6211L67.5148 62.0613C68.2739 61.8579 69.0542 62.3084 69.2577 63.0675L69.4939 63.9492C69.6973 64.7083 69.2468 65.4886 68.4877 65.692L44.0062 72.2518C43.2471 72.4552 42.4668 72.0047 42.2634 71.2456L42.0271 70.3639Z"
                    fill="#0085F2"
                    fillOpacity="0.12"
                />
            </g>
            <rect x="4" y="4" width="21" height="21" rx="10.5" fill="#0085F2" fillOpacity="0.12" />
            <path
                d="M11.104 15.796L13.888 10.264H15.94L13.096 15.796H11.104ZM11.104 16.852V15.796L11.728 15.256H17.896V16.852H11.104ZM15.1 18.736V13.492H16.948V18.736H15.1Z"
                fill="#0085F2"
            />
            <defs>
                <filter
                    id="filter0_d_0_2143"
                    x="43.0894"
                    y="-2.35257"
                    width="112.402"
                    height="111.945"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="5.65172" />
                    <feGaussianBlur stdDeviation="11.1792" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_0_2143"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_0_2143"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_0_2143"
                    x="88.6648"
                    y="51.445"
                    width="59.8072"
                    height="59.8072"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4.05694" />
                    <feGaussianBlur stdDeviation="8.02472" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_0_2143"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_0_2143"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_d_0_2143"
                    x="6.50014"
                    y="5.29124"
                    width="88.0687"
                    height="98.7003"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4.56844" />
                    <feGaussianBlur stdDeviation="9.03648" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_0_2143"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_0_2143"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_0_2143"
                    x1="78.5"
                    y1="0"
                    x2="78.5"
                    y2="124.292"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C182FF" />
                    <stop offset="1" stopColor="#0085F2" stopOpacity="0" />
                </linearGradient>
                <clipPath id="clip0_0_2143">
                    <rect width="157" height="95" rx="8" fill="white" />
                </clipPath>
                <clipPath id="clip1_0_2143">
                    <rect
                        x="78.4459"
                        y="14.3542"
                        width="56.2439"
                        height="55.6258"
                        rx="9.11811"
                        transform="rotate(13.5132 78.4459 14.3542)"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
