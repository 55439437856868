import React, { useEffect } from 'react';

// TODO: Replace by InfiniteScroll from 'react-infinite-scroll-component'
import { List } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { useDispatch } from 'react-redux';

import { BusinessConnectionList } from 'app/api/types/business';
import useBusinessConnectionSearchQueryParams from 'app/businessConnection/hooks/useBusinessConnectionSearchQueryParams';
import NoBusinessToConnect from 'app/common/components/placeholder/KnowledgeManagement/NoBusinessToConnect.container';
import NoBusiness from 'app/common/components/placeholder/NoBusiness.container';
import { FACEBOOK, GOOGLE_MY_BUSINESS } from 'app/common/data/publisher';
import { getConnectionsStats } from 'app/states/knowledge/reducers/businessConnection/connectionsStats';
import { ALL_TAB, NavValue } from 'app/states/knowledge/reducers/businessConnection/nav';

import BusinessRow from './BusinessRow';
import { ConnectionsTableLoadingState } from './ConnectionsTableLoadingState/ConnectionsTableLoadingState';

type Props = {
    businessesInfos: Array<BusinessConnectionList>;
    fetchNextBusinesses: (additionalParams: Record<string, string>) => void;
    allBusinessesHaveBeenFetched: boolean;
    navTab: NavValue;
    setConnectedToFacebookLocationFilterByFilterId: (arg0: NavValue) => void;
    setConnectedToGmbLocationFilterByFilterId: (arg0: NavValue) => void;
    fetchPublisherSuccess: (arg0: string) => void;
};

const ConnectionsTable = ({
    businessesInfos,
    fetchNextBusinesses,
    allBusinessesHaveBeenFetched,
    navTab,
    setConnectedToFacebookLocationFilterByFilterId,
    setConnectedToGmbLocationFilterByFilterId,
    fetchPublisherSuccess,
}: Props) => {
    const { t } = useTranslation();
    const businessSearchParams = useBusinessConnectionSearchQueryParams();
    const businessCompanionSearchParams = useBusinessConnectionSearchQueryParams(true);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getConnectionsStats(businessCompanionSearchParams));
    }, []);

    useEffect(() => {
        setConnectedToFacebookLocationFilterByFilterId(navTab);
        setConnectedToGmbLocationFilterByFilterId(navTab);
        fetchPublisherSuccess(FACEBOOK);
        fetchPublisherSuccess(GOOGLE_MY_BUSINESS);
    }, [navTab]);

    if (allBusinessesHaveBeenFetched && !businessesInfos.length) {
        if (navTab === ALL_TAB) {
            return <NoBusiness />;
        }
        return <NoBusinessToConnect />;
    }

    const columns = [
        { label: t('locations') },
        { label: 'Google', width: '170px' },
        { label: 'Facebook', width: '170px' },
    ];

    return (
        <InfiniteScroll
            loadMore={() => fetchNextBusinesses(businessSearchParams)}
            hasMore={!allBusinessesHaveBeenFetched}
            loader={<ConnectionsTableLoadingState key="loading" />}
            threshold={1500}
            useWindow={false}
            getScrollParent={() => document.getElementById('react-content')}
        >
            <List columns={columns}>
                {businessesInfos.map(business => (
                    <BusinessRow
                        key={business.business_id}
                        businessId={business.business_id}
                        businessStatus={business.status}
                    />
                ))}
            </List>
        </InfiniteScroll>
    );
};

export default ConnectionsTable;
