import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react';

import { ApiKeyPayload } from 'app/api/types/api_keys';

interface ApiKeyProviderProps {
    children: ReactNode;
}

const ApiKeyContext = createContext<Record<string, any>>({});

export const ApiKeyProvider: React.FC<ApiKeyProviderProps> = ({ children }) => {
    const [showApiKeyModal, setApiKeyShowModal] = useState<boolean>(false);
    const [showApiKeyModalForm, setApiKeyShowModalForm] = useState<boolean>(false);
    const [keyValue, setKeyValue] = useState<number>();
    const [apiKeyId, setApiKeyId] = useState<number>(0);
    const [apiKeyLabel, setApiKeyLabel] = useState<string | null>(null);
    const [defaultFormValues, setDefaultFormValues] = useState<ApiKeyPayload>({
        expiration_date: null,
        ip_whitelist: [],
        label: '',
    });

    const apiKeyContextValue = useMemo(
        () => ({
            showApiKeyModal,
            setApiKeyShowModal,
            keyValue,
            setKeyValue,
            apiKeyId,
            setApiKeyId,
            apiKeyLabel,
            setApiKeyLabel,
            showApiKeyModalForm,
            setApiKeyShowModalForm,
            defaultFormValues,
            setDefaultFormValues,
        }),
        [
            showApiKeyModal,
            setApiKeyShowModal,
            keyValue,
            setKeyValue,
            apiKeyId,
            setApiKeyId,
            apiKeyLabel,
            setApiKeyLabel,
            showApiKeyModalForm,
            setApiKeyShowModalForm,
            defaultFormValues,
            setDefaultFormValues,
        ],
    );

    return <ApiKeyContext.Provider value={apiKeyContextValue}>{children}</ApiKeyContext.Provider>;
};

export const useApiKeyContext = () => {
    const context = useContext(ApiKeyContext);

    if (!context) {
        throw new Error('useApiKeyContext must be used within a ApiKeyProvider');
    }

    return context;
};
