import { useLocation } from 'react-router-dom';

import { ANALYTICS_PATH } from 'app/routing/routeIds';

// TODO Tricia: remove this once every sidebar has been migrated
export const useIsPageMigratedToTabs = () => {
    const { pathname } = useLocation();
    const isPageMigratedToTabs = pathname.startsWith(ANALYTICS_PATH);

    return isPageMigratedToTabs;
};
