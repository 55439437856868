import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import MenuIcon from 'app/common/components/icons/MenuIcon';
import MobilePageHeader from 'app/common/components/pageHeaders/MobilePageHeader';
import MobilePageSubHeader from 'app/common/components/pageHeaders/MobilePageSubHeader';
import IS_IFRAME from 'app/common/data/iframe';
import PageHeader from 'app/common/designSystem/components/molecules/PageHeader';

import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import { collapseSidebar } from 'app/common/reducers/global';
import { useIsTablet } from 'app/hooks/useIsTablet';
import useGroupTitle from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupTitle';
import { MetaRelogin } from 'app/pages/visibility/subComponents/MetaRelogin/MetaRelogin';

import { OverrideTablet } from './BusinessListHeader.styled';
import HeaderButtons from './HeaderButtons';
import { DiffusionListButton } from './HeaderButtons/Buttons/DiffusionListButton';
import OldHeaderButton from './OldHeaderButton';

const BusinessListHeader = () => {
    const dispatch = useDispatch();
    const isTablet = useIsTablet();
    const titleDesktop = useGroupTitle();
    const openSidebar = () => dispatch(collapseSidebar());
    const { t } = useTranslation();

    const enableNewGroups = useNewGroups();

    const headerButtons = enableNewGroups ? (
        <HeaderButtons />
    ) : (
        <>
            <OldHeaderButton />
            {!IS_IFRAME && <DiffusionListButton />}
        </>
    );

    return (
        <>
            {isTablet ? (
                <>
                    <MobilePageHeader
                        leftContent={<MenuIcon onClick={openSidebar} />}
                        title={t('sidebar_visibility')}
                        hideLeftContentOnIframe
                    />
                    <MobilePageSubHeader
                        title={titleDesktop}
                        actions={[
                            <OverrideTablet key="business-list-header-buttons">
                                {headerButtons}
                            </OverrideTablet>,
                        ]}
                    />
                </>
            ) : (
                <PageHeader title={titleDesktop} pageButtons={headerButtons} />
            )}
            <MetaRelogin />
        </>
    );
};

export default BusinessListHeader;
