import styled from '@emotion/styled';

export const GroupsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-inline: 24px;
    width: 100%;
    overflow: auto;
`;
