import styled from '@emotion/styled';
import { ColorVariants } from '@partoohub/branding';

export const ProgressContainer = styled.div`
    overflow: hidden;
    position: relative;
    height: 4px;
    width: 270px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 200px;
    background-color: ${({ theme }) => theme.colors.secondary.alpha};
`;

export const Filled = styled('div', {
    shouldForwardProp: prop => !['variant', 'value', 'duration'].includes(prop),
})<{ variant: string; value: number; duration: number }>`
    border-radius: ${({ theme }) => theme.radius.default};
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: ${({ theme, variant }) =>
        theme.colors[variant as ColorVariants].background ?? 'inherit'};
    transition: ${({ duration }) => duration}s width;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    width: ${({ value }) => Math.max(0, Math.min(100, value))}%;
`;
