import {
    FunnelModalBody,
    FunnelModalHeader,
} from 'app/common/components/FunnelModal/FunnelModal.styled';

import { BulkUpdateForm } from './sections/BulkUpdateForm/BulkUpdateForm';
import { BulkUpdateHeader } from './sections/BulkUpdateHeader/BulkUpdateHeader';

export const BulkUpdate = () => {
    return (
        <>
            <FunnelModalHeader>
                <BulkUpdateHeader />
            </FunnelModalHeader>
            <FunnelModalBody>
                <BulkUpdateForm />
            </FunnelModalBody>
        </>
    );
};
