import React from 'react';

import { useTranslation } from 'react-i18next';

import MenuPhotos from 'app/businessEditV2/sections/MenuSection/components/MenuPhotos';
import {
    SectionGlobal,
    SectionHeader,
    SectionPhotoBody,
    SectionPhotoCount,
    SectionTitle,
} from 'app/businessEditV2/sections/MenuSection/MenuForm/MenuPhotosForm.styled';

type Props = {
    value: any;
    onChange: (any) => void;
    disabled: boolean;
};

const MenuPhotosForm = ({ value, onChange, disabled }: Props) => {
    const { t } = useTranslation();

    return (
        <SectionGlobal>
            <SectionHeader>
                <SectionTitle>{t('photos')}</SectionTitle>
                <SectionPhotoCount>
                    {t('menu_photos_count', {
                        count: value.length,
                    })}
                </SectionPhotoCount>
            </SectionHeader>
            <SectionPhotoBody>
                <MenuPhotos
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
            </SectionPhotoBody>
        </SectionGlobal>
    );
};

export default MenuPhotosForm;
