import { useTranslation } from 'react-i18next';

import BusinessEditV2 from 'app/businessEditV2';
import { BusinessEditV2WithNewSidebar } from 'app/businessEditV2/BusinessEditV2WithNewSidebar';
import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';

export const BusinessEditV2Page = () => {
    const { t } = useTranslation();
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const BusinessEditV2Component = isNewSidebarEnabled
        ? BusinessEditV2WithNewSidebar
        : BusinessEditV2;

    return (
        <>
            <DynamicPageHeader title={t('info_view_title')} />
            <BusinessEditV2Component />
        </>
    );
};
