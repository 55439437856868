import { MouseEvent, useEffect, useRef, useState } from 'react';

import { css } from '@emotion/react';
import { Chip, IconPrefix, List, Stack, Switch, Text, toast } from '@partoohub/ui';

import { Interweave } from 'interweave';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';
import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';
import { ChipMatcher } from 'app/common/services/Matchers';
import { useWindowSize } from 'app/hooks';

import { useConversationStarterActivate } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarterActivate';
import { useConversationStarterDelete } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarterDelete';
import { useConversationStarterDuplicate } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarterDuplicate';

import {
    ConversationStarterItems,
    ConversationStarterItemsContainer,
    WelcomeMessage,
} from './ConversationStartersCard.styled';
import {
    ConversationStarter,
    ConversationStarterAutomaticMessagesPlaceholders,
} from './types/conversationStarters';

interface Props {
    conversationStarter: ConversationStarter;
    isLastCard: boolean;
}

export const ConversationStarterCard = ({ conversationStarter, isLastCard }: Props) => {
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const containerRef = useRef<HTMLDivElement>(null);

    const [hiddenItemsCount, setHiddenItemsCount] = useState<number>(1);
    const [indexOfFirstHiddenItem, setIndexOfFirstHiddenItem] = useState<number>(6);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const optionButtonRef = useRef<HTMLDivElement>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const conversationStarterDeleteMutation = useConversationStarterDelete(conversationStarter.id);
    const conversationStarterDuplicateMutation = useConversationStarterDuplicate(
        conversationStarter.id,
    );
    const conversationStarterActivateMutation = useConversationStarterActivate(
        conversationStarter.id,
    );

    const options = [
        {
            dataTrack: 'automatic_messages__click__chatbot_edit_button',
            name: 'edit',
            label: <MenuLabelItem text={t('edit')} icon={['fa-marker', IconPrefix.REGULAR]} />,
            value: 'edit',
        },
        {
            dataTrack: 'automatic_messages__click__chatbot_duplicate_button',
            name: 'duplicate',
            label: <MenuLabelItem text={t('duplicate')} icon={['fa-clone', IconPrefix.REGULAR]} />,
            value: 'duplicate',
        },
        {
            dataTrack: 'automatic_messages__click__chatbot_delete_button',
            name: 'delete',
            label: (
                <MenuLabelItem
                    text={t('delete')}
                    icon={['fa-trash', IconPrefix.REGULAR]}
                    color="danger"
                />
            ),
            value: 'delete',
        },
    ];

    const textMatchers = ConversationStarterAutomaticMessagesPlaceholders.map(element =>
        ChipMatcher(`@\\[${t(element.display)}]\\(${element.id}\\)`, t(element.display)),
    );

    const onEdit = () => {
        searchParams.set('conversationStarterId', conversationStarter.id.toString());
        setSearchParams(searchParams);
    };

    const onCardClick = (e: MouseEvent<HTMLDivElement>) => {
        if (optionButtonRef.current && !optionButtonRef.current.contains(e.target as Node)) {
            onEdit();
        }
    };

    const onToggle = () => {
        if (conversationStarter.automatic_message_enabled) {
            setShowDeactivateModal(true);
        } else {
            conversationStarterActivateMutation.mutate(
                !conversationStarter.automatic_message_enabled,
            );
        }
    };

    const toastSuccess = () => {
        toast.success(null, t('success'));
    };

    const onMenuItemClick = (option: string) => {
        switch (option) {
            case 'edit':
                onEdit();
                break;
            case 'duplicate':
                conversationStarterDuplicateMutation.mutate(undefined, { onSuccess: toastSuccess });
                break;
            case 'delete':
                setShowDeleteModal(true);
                break;
        }
    };

    const checkOverflow = (element: HTMLElement) => {
        // Get the bounding rectangle of the element
        const elementRect = element.getBoundingClientRect();
        // Get the bounding rectangle of the parent element
        const parentRect = containerRef.current?.getBoundingClientRect();

        if (!parentRect) return false;

        // Determine if the element overflows the parent
        const isOverflowing = {
            right: elementRect.right > parentRect.right,
            bottom: elementRect.bottom > parentRect.bottom,
            bottom_right:
                elementRect.bottom < parentRect.bottom && elementRect.right + 40 > parentRect.right,
        };

        // Return true if any side overflows
        return isOverflowing.right || isOverflowing.bottom_right || isOverflowing.bottom;
    };

    const updateCSItemsDisplay = () => {
        /**
         * Iterates through the child nodes of the container, identifies and counts the child nodes that overflow the container.
         * Updates state variables to keep track of the first hidden item index and the total number of hidden items.
         * Special case: If the 'hidden-item-count' element itself overflows,
         * the function removes the last item that is not overflowing to make space for the count to be displayed.
         */
        if (!containerRef) return;

        if (containerRef.current?.childNodes) {
            const childNodes = containerRef.current.childNodes;

            let flag = false;
            setHiddenItemsCount(0);
            setIndexOfFirstHiddenItem(6);
            for (let index = 0; index < childNodes.length; index++) {
                const child = childNodes[index] as HTMLDivElement;

                if (child.id === 'hidden-item-count') {
                    continue;
                }

                if (checkOverflow(child)) {
                    if (!flag) {
                        setIndexOfFirstHiddenItem(index);
                        flag = true;
                    }

                    setHiddenItemsCount(count => count + 1);
                }
            }
        }
    };

    useEffect(() => {
        updateCSItemsDisplay();
    }, [width]);

    return (
        <>
            <div onClick={onCardClick}>
                <List.Row
                    id={conversationStarter.id}
                    dataTrackId=""
                    rowCssContainer={css`
                        height: 100%;
                        overflow-y: unset;
                        &:hover {
                            transform: unset;
                        }
                        cursor: pointer;
                    `}
                >
                    <List.Cell>
                        <Text variant="bodyMSemibold">{conversationStarter.name}</Text>
                    </List.Cell>

                    <List.Cell
                        css={css`
                            max-height: 62px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        `}
                    >
                        <WelcomeMessage variant="bodyMRegular" color="secondary">
                            <Interweave
                                content={conversationStarter.welcome_message}
                                matchers={textMatchers}
                            />
                        </WelcomeMessage>
                    </List.Cell>

                    <List.Cell
                        css={css`
                            height: 62px;
                            overflow: hidden;
                        `}
                    >
                        {conversationStarter.conv_starters_enabled && (
                            <ConversationStarterItemsContainer ref={containerRef}>
                                {conversationStarter.item_titles?.map((item, index) => (
                                    <ConversationStarterItems
                                        key={item}
                                        order={index < indexOfFirstHiddenItem ? 1 : 3}
                                    >
                                        {item}
                                    </ConversationStarterItems>
                                ))}
                                {hiddenItemsCount > 0 && (
                                    <ConversationStarterItems order={2} id="hidden-item-count">
                                        +{hiddenItemsCount}
                                    </ConversationStarterItems>
                                )}
                            </ConversationStarterItemsContainer>
                        )}
                    </List.Cell>

                    <List.Cell>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Chip dataTrackId="" chipShape="cube" variant="theme">
                                <Text>{conversationStarter.business_ids.length}</Text>
                            </Chip>
                            <Stack direction="row" gap="4px">
                                <Switch
                                    dataTrackId="automatic_messages_activation"
                                    name="chatbot-activation"
                                    checked={conversationStarter.automatic_message_enabled}
                                    onChange={onToggle}
                                    disabled={!conversationStarter.business_ids.length}
                                />
                                <div ref={optionButtonRef}>
                                    <ActionButtonWithMenu
                                        dataTrackId="automatic_messages_action_button"
                                        icon={['fa-ellipsis-v', IconPrefix.SOLID]}
                                        appearance="outlined"
                                        options={options}
                                        onMenuClick={onMenuItemClick}
                                        cssMenu={css`
                                            top: 50px;
                                            right: 0;
                                            left: unset;
                                            ${isLastCard && 'bottom: 50px; top: unset;'}
                                        `}
                                        tooltip={t('more')}
                                    />
                                </div>
                            </Stack>
                        </Stack>
                    </List.Cell>
                </List.Row>
            </div>
            <ConfirmModal
                show={showDeleteModal}
                title={t('automatic_messages_delete_modal_title')}
                content={t('automatic_messages_delete_modal_content')}
                confirmLabel={t('delete')}
                modalFor="delete"
                onConfirm={() => {
                    conversationStarterDeleteMutation.mutate(undefined, {
                        onSuccess: toastSuccess,
                    });
                    setShowDeleteModal(false);
                }}
                onHide={() => setShowDeleteModal(false)}
                trackId="automatic_messages__delete_modal"
            />
            <ConfirmModal
                show={showDeactivateModal}
                title={t('automatic_messages_deactivate_modal_title')}
                content={t('automatic_messages_deactivate_modal_content')}
                confirmLabel={t('automatic_messages_deactivate_button')}
                modalFor="warning"
                onConfirm={() => {
                    conversationStarterActivateMutation.mutate(false);
                    setShowDeactivateModal(false);
                }}
                onHide={() => setShowDeactivateModal(false)}
                trackId="automatic_messages__deactivate_modal"
            />
        </>
    );
};
