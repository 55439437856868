import useDisplayServices from 'app/businessEditV2/hooks/editV2utils/displaySections/useDisplayServices';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';

import { ColorState } from './types';
import useServices from '../../services/useServices';

export default function useServicesSectionColor(): ColorState {
    // Hooks
    const { data: publisherErrors } = usePublisherErrors({});
    const servicesPermission = useFieldMetadata('services', 'services')?.enabled;
    const { data: services } = useServices();
    const isClosed = useIsBusinessClosed();
    const display = useDisplayServices();

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // If no services for the business
    if (!display) {
        return ColorState.OK;
    }

    // Disabled
    if (!servicesPermission) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (publisherErrors?.services) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields
    if (services?.services.length === 0) {
        return ColorState.MISSING_WARNING;
    }

    // OK
    return ColorState.OK;
}
