import { useBusinessGoogleConnectionsErrors } from 'app/pages/Homepage/hooks/todos/todosItems/partnerCo/useBusinessGoogleConnectionsErrors';
import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import { TodoPartnerCo } from 'app/pages/Homepage/sections/HomepageTodos/todos/TodoPartnerCo';
import { Item } from 'app/pages/Homepage/utils/typing';

export const useTodoPartnerCo = (): Item<SmallTodoProps> => {
    const { data } = useBusinessGoogleConnectionsErrors();
    const countError = data?.partner_connections_errors_count ?? 0;

    return {
        order: 3,
        isAvailable: countError > 0,
        component: TodoPartnerCo,
        key: 'todo-partner-co',
    };
};
