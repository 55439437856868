import { useChatbot } from 'app/pages/settings/Chatbot/hooks/useChatbot';
import { InboxHeaderContainer } from 'app/reviewManagement/messaging/InboxHeader.styled';

import { useHasAssignConversationFeature } from './hooks/useHasAssignConversationFeature';

import AssignConversationFilter from './inbox/AssignConversationFilter/AssignConversationFilter';
import ConversationStatusFilter from './inbox/ConversationStatusFilter/ConversationStatusFilter';

const InboxHeader = () => {
    const { data: chatbot } = useChatbot();
    const { hasAssignConversationFeature } = useHasAssignConversationFeature();
    // For SMBs, we show the assign filters when there is a chatbot in the org.
    const showFilter = hasAssignConversationFeature || chatbot;
    return (
        <InboxHeaderContainer flexDirection="row" alignItems="center">
            <ConversationStatusFilter />
            {showFilter && <AssignConversationFilter />}
        </InboxHeaderContainer>
    );
};

export default InboxHeader;
