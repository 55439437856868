import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

export type InternalRankingAvailableParameters = {
    section?: Array<{ value: string }>;
    dimension?: Array<{ value: string }>;
};

export const useInternalRankingParameters = () => {
    const [currSection, setSection] = useStateQueryParams('internalRankingSection');
    const [currDimension, setDimension] = useStateQueryParams('internalRankingDimension');

    const resetParameters = (availableParameters: InternalRankingAvailableParameters) => {
        const newSection = (availableParameters.section ?? [])
            .map(s => s.value)
            .includes(currSection)
            ? currSection
            : (availableParameters.section?.[0].value ?? '');

        const newDimension = (availableParameters.dimension ?? [])
            .map(d => d.value)
            .includes(currDimension)
            ? currDimension
            : (availableParameters.dimension?.[0].value ?? '');

        setSection(newSection);
        setDimension(newDimension);
    };

    return {
        section: [currSection, setSection] as const,
        dimension: [currDimension, setDimension] as const,
        resetParameters,
    };
};
