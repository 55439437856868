import React from 'react';

import { KpiCardLoader } from 'app/pages/analytics/VisibilityAnalyticsV2/components/KpiCardLoader/KpiCardLoader';
import { ActionsCard } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Metrics/MetricsCard/ActionsCard/ActionsCard';
import { ViewsCard } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Metrics/MetricsCard/ViewsCard/ViewsCard';
import { useMetricsDataCount } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/metrics/count/useMetricsDataCount';
import { useMetricsErrorCount } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/metrics/count/useMetricsErrorCount';
import { useMetricsLoadingCount } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/metrics/count/useMetricsLoadingCount';

export const MetricsCard = () => {
    const isLoading = useMetricsLoadingCount();
    const {
        hasError,
        refetchCountMetricsCurrent,
        refetchCountMetricsPrevious,
    } = useMetricsErrorCount();
    const { data, count, delta } = useMetricsDataCount();

    if (isLoading) {
        return (
            <>
                <KpiCardLoader />
                <KpiCardLoader />
            </>
        );
    }

    const handleClick = () => {
        refetchCountMetricsCurrent();
        refetchCountMetricsPrevious();
    };

    return (
        <>
            <ViewsCard
                data={data}
                count={count.views}
                delta={delta.views}
                hasError={hasError}
                refetch={handleClick}
            />
            <ActionsCard
                data={data}
                count={count.actions}
                delta={delta.actions}
                hasError={hasError}
                refetch={handleClick}
            />
        </>
    );
};
