import styled from '@emotion/styled';

export const StyledSectionSelectButtonIcons = styled.div`
    display: flex;
    font-size: 14px;
    flex-direction: column;
    > i {
        margin: -5px 0;
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
`;
