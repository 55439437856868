import ConversationCardLoader from './ConversationCardLoader';

const LazyLoadingConversationsCards = () => (
    <>
        <ConversationCardLoader key="loading_conversation_card_1" />
        <ConversationCardLoader key="loading_conversation_card_2" />
    </>
);

export default LazyLoadingConversationsCards;
