import { toUpper } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { BusinessDetailsData } from 'app/api/types/publisher_state';
import Clickable from 'app/common/components/buttons/Clickable';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { useIsMobile } from 'app/common/services/screenDimensions';
import onBusinessRedirectClick from 'app/presence/management/services/onBusinessRedirect';
import { PublisherState } from 'app/presence/management/types/Publishers.types';
import { hideableComponentsNames, withHideableComponent } from 'app/SDKBridge';

import PartnerConnectionButton from './ConnexionModal.container';

type Props = {
    businessDetails: BusinessDetailsData;
    status: PublisherState;
    publisherName: string;
    publisherFormattedName: string;
};

const ClickItem = ({ businessDetails, status, publisherName, publisherFormattedName }: Props) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const handleClick = () => {
        if (status === 'to_configure') {
            if (!isMobile) {
                if (businessDetails.error_type === 'USER_INPUT') {
                    onBusinessRedirectClick(businessDetails.business_id, publisherName);
                }
            }
        } else if ((status === 'success' || status === 'pending') && businessDetails.business_url) {
            window.open(businessDetails.business_url, '_blank');
        }
    };

    const renderClickableItem = () => {
        switch (status) {
            case 'to_configure':
                return renderConfigureBusinessItem();
            case 'success':
            case 'pending':
                return renderExternalLinkItem();
            default:
        }
    };

    const renderConfigureBusinessItem = () => {
        if (isMobile) {
            return;
        }

        switch (businessDetails.error_type) {
            case 'PUBLISHER_CONNEXION':
                return (
                    <PartnerConnectionButton
                        text={t('connection_required')}
                        color={'primary'}
                        // @ts-ignore
                        partner={toUpper(publisherName)}
                        businessId={businessDetails.business_id}
                        loading={!businessDetails.business_id}
                    />
                );
            case 'ADMIN_INPUT':
            case 'EXTERNAL':
            case undefined:
                return (
                    <TooltipWrapper text={t('no_action_needed')} position="top-end">
                        <i className="info-question fas fa-question-circle" />
                    </TooltipWrapper>
                );
            default:
                return (
                    <TooltipWrapper text={t('correct_faulty_fields')} position="top-end">
                        <i
                            className="fa-solid fa-pen-alt"
                            data-track="presence_analytics__configure_business_button"
                            data-intercom-target="presence_analytics__configure_business_button"
                        />
                    </TooltipWrapper>
                );
        }
    };

    const renderExternalLinkItem = () => {
        if (businessDetails.business_url) {
            if (isMobile) {
                return (
                    <div>
                        <i className="fa-solid fa-external-link-alt" />
                    </div>
                );
            }
            return (
                <TooltipWrapper
                    text={`${t('go_to_page_on_publisher')} ${publisherFormattedName}`}
                    position="top-end"
                >
                    <i className="fa-solid fa-external-link-alt" />
                </TooltipWrapper>
            );
        }

        return isMobile ? undefined : (
            <TooltipWrapper text={t('no_link_tooltip')} position="top-end">
                <i className="info-question fas fa-external-link-alt" />
            </TooltipWrapper>
        );
    };

    return <Clickable onClick={() => handleClick()}>{renderClickableItem()}</Clickable>;
};

export default withHideableComponent(
    ClickItem,
    hideableComponentsNames.KNOWLEDGE_EDIT_SELECT_BUSINESS_BUTTON,
);
