import { useState } from 'react';

import { useController } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import useCities from 'app/businessEditV2/hooks/business/useCities';
import { CityField, Props } from 'app/common/components/form/CityField';

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'onChange' | 'onTextFieldChange' | 'value' | 't' | 'options' | 'onSearchChange';

export const ControlledCityField = ({
    name,
    control,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => {
    const [query, setQuery] = useState<string>('');
    const options = useCities(query);

    const {
        field: { onChange, value, ref, ...usedFields },
    } = useController({
        name,
        control,
    });

    return (
        <CityField
            {...props}
            {...usedFields}
            onChange={onChange}
            onTextFieldChange={value => onChange({ label: value, value })}
            selectedValue={value}
            onSearchChange={search => setQuery(search ?? '')}
            options={options}
        />
    );
};
