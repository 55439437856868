import { useSelector } from 'react-redux';

import { businessByIdSelector } from 'app/common/reducers/newBusinesses';
import { businessesSelector } from 'app/states/reducers';

export default function (businessId: string): string | null {
    const businessState = useSelector(businessesSelector);
    const business = businessByIdSelector(businessState, businessId);
    if (business) {
        return business.code;
    }

    return null;
}
