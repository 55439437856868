import React, { useState } from 'react';

import { Controller, UseFormWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { useTranslation } from 'react-i18next';

import { GROUP_MANAGER } from 'app/common/data/roles';
import AsyncSingleSelect from 'app/common/designSystem/components/molecules/AsyncSingleSelect';
import { Props } from 'app/common/designSystem/components/molecules/AsyncSingleSelect/AsyncSingleSelect';
import { formatGroupToChoice, useGroups } from 'app/common/hooks/queries/useGroups';
import useDebounce from 'app/common/hooks/useDebounce';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    watch: UseFormWatch<any>;
};

type OmitProps =
    | 'onChange'
    | 'onInputChange'
    | 'value'
    | 't'
    | 'options'
    | 'isLoading'
    | 'selectedOption'
    | 'placeholder';

const ControlledGroupSelect = ({
    name,
    control,
    watch,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => {
    const { t } = useTranslation();
    const orgId = watch('org')?.value;
    const isGroupManager = watch('role')?.value === GROUP_MANAGER;
    const [query, setQuery] = useState('');
    const queryDebounced = useDebounce(query);
    const [groupsQuery, groups] = useGroups({
        search: queryDebounced,
        org_id: orgId?.toString(),
    });
    const [, allGroups] = useGroups({
        search: '',
        org_id: orgId?.toString(),
    });

    // Hide group field if there are no group in the org for a BM
    if (!isGroupManager && !(allGroups ?? []).length) {
        return null;
    }

    const filteredOptions = groups?.map(group => formatGroupToChoice(group)) ?? [];

    const loadMore = (searchInput: string) => {
        if (searchInput != query) {
            setQuery(searchInput);
        } else {
            groupsQuery.fetchNextPage();
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return (
                    <AsyncSingleSelect
                        options={filteredOptions}
                        selectedValue={value}
                        onChange={onChange}
                        hasMore={groupsQuery.hasNextPage}
                        loadMore={loadMore}
                        placeholder={t('group')}
                        isMandatory={isGroupManager}
                        mustFilterOptions
                        {...props}
                    />
                );
            }}
        />
    );
};

export default ControlledGroupSelect;
