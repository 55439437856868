import { useState } from 'react';

import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import WhatsappApiCalls from 'app/api/v2/api_calls/whatsappApiCalls';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';
import dataLayer from 'app/common/utils/dataLayer';

import { FunnelModalFooterButton } from './FunnelModalFooterButton';
import { FBLoginCallbackAuthResponse, launchEmbeddedSignup } from './launchEmbeddedSignup';

type Props = {
    onEmbeddedSignupFinished: () => void;
    onClose: () => void;
    need_partoo_number?: boolean;
    gtmEvent: string;
};

export const WhatsappEmbeddedSignUpButton = ({
    onEmbeddedSignupFinished,
    onClose,
    need_partoo_number = false,
    gtmEvent,
}: Props) => {
    const { t } = useTranslation();
    const { data: me } = useMe();
    const { data: org } = useOrganization(me?.org_id ?? 0, !!me?.org_id);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const signupMutation = useMutation(
        ({
            code,
            metaUserId,
            need_partoo_number,
        }: {
            code: string;
            metaUserId?: string;
            need_partoo_number: boolean;
        }) => {
            return WhatsappApiCalls.signup(code, need_partoo_number, metaUserId);
        },
    );
    const onClick = () => {
        dataLayer.pushDict(gtmEvent, {
            partoo_user_id: me?.id,
            org_id: org?.org_id,
            partoo_number: gtmEvent === 'validate_wa_phone_choice' ? need_partoo_number : undefined,
        });

        setIsLoading(true);
        launchEmbeddedSignup(
            (response: FBLoginCallbackAuthResponse) => {
                setIsLoading(false);
                const code = response.code;
                const metaUserId = response.user_id;
                onEmbeddedSignupFinished();
                signupMutation.mutate({ code, metaUserId, need_partoo_number });
            },
            () => {
                dataLayer.pushDict('onboarding_wa_flow_crash', {
                    partoo_user_id: me?.id,
                    org_id: org?.org_id,
                });
                toast.error(
                    t('whatsapp_embedded_signup_cancelled_secondary'),
                    t('whatsapp_embedded_signup_cancelled_main'),
                );
                onClose();
            },
            need_partoo_number,
            org?.name,
        );
    };

    return (
        <FunnelModalFooterButton
            onClick={onClick}
            disabled={isLoading}
            isLoading={false}
            icon={['fa-brands', 'fa-facebook']}
            buttonText={t('whatsapp_sign_up')}
        />
    );
};
