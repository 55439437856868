import {
    Button,
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    NumberInput,
    Stack,
    TextInput,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import {
    ImagesUploaderMetadata,
    MAX_NUMBER_OF_CHARACTERS,
    MAX_NUMBER_OF_TEXT_FIELDS,
} from './data';
import { TrashIcon } from './FormTypeImagesUploader.styled';

type Props = {
    params: { text_fields: ImagesUploaderMetadata[] };
    updateParams: (typeParams: { text_fields: ImagesUploaderMetadata[] }) => void;
    duplicates: string[];
};

export const FormTypeImagesUploaderCreate = ({ params, updateParams, duplicates }: Props) => {
    const { t } = useTranslation();

    const addField = () => {
        if (params.text_fields?.length >= MAX_NUMBER_OF_TEXT_FIELDS) {
            return;
        }
        const newTextFields = params.text_fields ?? [];
        newTextFields.push({ text_field: '', max_length: MAX_NUMBER_OF_CHARACTERS });
        updateParams({ text_fields: newTextFields });
    };

    const onChange = (
        value: string | number,
        index: number,
        constraint: 'text_field' | 'max_length',
    ) => {
        const data = params.text_fields[index];
        if (constraint === 'text_field') {
            data.text_field = value as string;
        } else {
            data.max_length = value as number;
        }
        updateParams({
            text_fields: params.text_fields,
        });
    };

    const onNumberBlur = (index: number) => {
        const value = params.text_fields[index]['max_length'] || MAX_NUMBER_OF_CHARACTERS;
        onChange(Math.round(value), index, 'max_length');
    };

    const onDeleteField = (index: number) => {
        updateParams({
            ...params,
            text_fields: [
                ...params.text_fields.slice(0, index),
                ...params.text_fields.slice(index + 1),
            ],
        });
    };

    return (
        <Stack direction="column" gap="16px">
            {params.text_fields &&
                (params.text_fields as ImagesUploaderMetadata[]).map((value, index) => {
                    const hasError = duplicates?.includes(value.text_field);
                    return (
                        <Stack key={index} direction="row" gap="16px" alignItems="top">
                            <TextInput
                                dataTrackId="custom_fields__images_uploader__text_field_name"
                                label={`${t('field')} #${index + 1}`}
                                value={params.text_fields[index].text_field}
                                onChange={v => onChange(v, index, 'text_field')}
                                error={hasError}
                                notice={hasError ? t('custom_fields_error_duplicated_options') : ''}
                                autoFocus={true}
                                required
                            />
                            <NumberInput
                                dataTrackId="custom_fields__images_uploader__max_length"
                                label={t('maximum_length')}
                                value={
                                    params.text_fields[index].max_length === null
                                        ? MAX_NUMBER_OF_CHARACTERS
                                        : params.text_fields[index].max_length
                                }
                                onChange={v => onChange(+v || '', index, 'max_length')}
                                onBlur={() => onNumberBlur(index)}
                                max={MAX_NUMBER_OF_CHARACTERS}
                                min={1}
                                notice={`Max: ${MAX_NUMBER_OF_CHARACTERS}`}
                            />
                            <TrashIcon
                                dataTrackId=""
                                icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                                appearance="outlined"
                                variant="danger"
                                onClick={() => onDeleteField(index)}
                            />
                        </Stack>
                    );
                })}
            {(!params.text_fields || params.text_fields.length < MAX_NUMBER_OF_TEXT_FIELDS) && (
                <Button
                    size="medium"
                    variant="primary"
                    appearance="outlined"
                    shape="cube"
                    onClick={addField}
                    icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                    dataTrackId="custom_fields__images_uploader__add_field"
                >
                    {t('add_text_field')}
                </Button>
            )}
        </Stack>
    );
};
