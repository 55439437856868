import React from 'react';

import { Modal } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { GroupsSettingsQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

import DeleteGroupContent from './DeleteGroupContent';

const DeleteGroupModal = () => {
    const [deleteGroup, setDeleteSection] = useStateQueryParams(
        GroupsSettingsQueryKeys.DELETE,
        true,
    );

    const [sectionId, groupId] = deleteGroup.split(',');

    const closeModal = () => {
        setDeleteSection('');
    };

    return (
        <Modal isOpen={!!sectionId && !!groupId} closeModal={() => setDeleteSection('')}>
            <DeleteGroupContent closeModal={closeModal} onDelete={closeModal} />
        </Modal>
    );
};

export default DeleteGroupModal;
