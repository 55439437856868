import { FC } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { PublisherRawData } from 'app/api/types/publisher';
import { SectionWithIcon } from 'app/common/designSystem/components/atoms/SectionWithIcon/SectionWithIcon';
import { formatPublisherPMFields } from 'app/presence/management/formatPublisherPmFieldsService';

import { PublisherBroadcastModalContent } from './PublisherBroadcastModal.styled';

type Props = {
    publisher: PublisherRawData;
};

export const SyncedFieldsComponent: FC<Props> = ({ publisher }) => {
    const { t } = useTranslation();

    const includedCountryCodes = publisher.included_country_codes.map(code => t(`${code}_country`));
    const excludedCountryCodes = publisher.excluded_country_codes.map(code => t(`${code}_country`));
    const calculatedCountryCodes = publisher.calculated_country_codes.map(code =>
        t(`${code}_country`),
    );
    const formattedPublisherPMFields = formatPublisherPMFields(publisher.published_fields);

    const countryCodeItems =
        calculatedCountryCodes.length === includedCountryCodes.length
            ? [
                  [
                      'only_business_in',
                      `${t('only_business_in')} ${includedCountryCodes.join(
                          ', ',
                      )} ${t('are_synced')}`,
                  ],
              ]
            : [
                  [
                      'all_business_except_in',
                      `${t('all_business_except_in')} ${excludedCountryCodes.join(', ')}`,
                  ],
              ];
    const tmp = [['all_businesses_synced', t('all_businesses_synced')]];

    return (
        <PublisherBroadcastModalContent>
            <SectionWithIcon
                iconName={['fa-info', IconPrefix.SOLID]}
                title={t('main_information')}
                items={Object.entries(formattedPublisherPMFields.main_info)
                    .filter(item => item[1] !== '')
                    .map(([key, value]) => [`pm_field_${key}`, t(value)])}
            />
            <SectionWithIcon
                iconName={[FontAwesomeIconsPartooUsed.faAlarmClock, IconPrefix.SOLID]}
                title={t('business_open_hours')}
                items={[['open_hours', t(formattedPublisherPMFields.open_hours)]]}
            />
            <SectionWithIcon
                iconName={['fa-tags', IconPrefix.SOLID]}
                title={t('attributes')}
                items={
                    ['partoo', 'google_my_business'].includes(publisher.name)
                        ? [['attributes_synced', t('attributes_synced')]]
                        : [['attributes_not_synced', t('attributes_not_synced')]]
                }
            />
            <SectionWithIcon
                iconName={['fa-images', IconPrefix.SOLID]}
                title={t('photos')}
                items={[['photos', t(formattedPublisherPMFields.photos)]]}
            />
            <SectionWithIcon
                iconName={['fa-map-location-dot', IconPrefix.SOLID]}
                title={t('countries')}
                items={
                    includedCountryCodes.length === 0 && excludedCountryCodes.length === 0
                        ? tmp
                        : countryCodeItems
                }
            />
        </PublisherBroadcastModalContent>
    );
};
