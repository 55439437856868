import styled from '@emotion/styled';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { ModalActions } from 'app/common/components/ConfirmModalV2/ConfirmModal.styled';

export const StyledConfirmModal = styled(ConfirmModal)`
    ${ModalActions} {
        button {
            &:first-of-type {
                flex-basis: 35%;
            }

            &:last-of-type {
                flex-basis: 65%;
            }
        }
    }
`;
