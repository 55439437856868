import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const PostFormTypePlatformInfoWrapper = styled.div`
    overflow-y: auto;
    padding: 84px 40px;
    width: 100%;
`;

export const PostFormTypePlatformInfoTitle = styled.div`
    color: ${HEX_COLORS.blackberry};
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 30px;
`;

export const PostFormTypePlatformInfoDescription = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 30px;
`;

export const PostFormTypePlatformInfoContainer = styled.div`
    background-color: ${HEX_COLORS.white};
    border: 1px solid #dfe3ec;
    border-radius: 8px;
    padding: 0 16px;
`;

export const PostCreationDetailWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 16px;
    > .type_icon {
        width: 32px;
        height: 32px;
        margin-right: 16px;
        > i {
            font-size: 32px;
            color: ${({ theme }) => theme.colors.primary.initial};
        }
        + div {
            flex-grow: 1;
            font-weight: 700;
            > .type_name {
                color: ${HEX_COLORS.blackberry};
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 1px;
                margin-bottom: 2px;
                text-transform: uppercase;
            }
            > .type_description {
                color: ${({ theme }) => theme.colors.secondary.initial};
                font-size: 12px;
                line-height: 14px;
                &:first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }
`;

export const PostContainerTitle = styled.div`
    color: ${HEX_COLORS.blackberry};
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin: 16px 0 24px 0;
    &:first-letter {
        text-transform: uppercase;
    }
    > i.fa-solid {
        font-size: 12px;
        margin-right: 4px;
    }
`;
