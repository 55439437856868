import { Icon, LeftElementType, Option, SingleSelect } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

type Props = {
    currentComparisonPeriod?: Option;
    setCurrentComparisonPeriod: (newComparisonValue?: Option) => void;
};

export const ComparisonPeriodSelect = ({
    currentComparisonPeriod,
    setCurrentComparisonPeriod,
}: Props) => {
    const { t } = useTranslation();

    const options: Option[] = [
        {
            value: 'month',
            name: t('previous_month'),
            label: t('previous_month'),
        },
        {
            value: 'semester',
            name: t('previous_semester'),
            label: t('previous_semester'),
        },
        {
            value: 'year',
            name: t('previous_year'),
            label: t('previous_year'),
        },
    ];

    const handleChangePeriod = (newValue?: Option) => {
        setCurrentComparisonPeriod(newValue);
    };

    return (
        <SingleSelect
            label={t('compare_period_button_title')}
            leftElement={<Icon icon={['fa-chart-simple']} iconSize="16px" color="secondary" />}
            leftElementType={LeftElementType.Icon}
            dataTrackId="page_competitive_benchmark__select_comparison_period"
            placeholder={t('comparison_filter')}
            selectedValue={currentComparisonPeriod}
            maxHeight={150}
            onChange={handleChangePeriod}
            disableReset
            sections={[{ options }]}
        />
    );
};
