import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { formatDate } from 'app/pages/analytics/CompetitiveBenchmark/utils/overview';

import ApiResourceBaseClass from './baseApiCalls';
import {
    CompetitiveKeywords,
    CompetitiveOverview,
    CompetitorCentralRank,
    CompetitorLocalRank,
    CompetitorLocalRankRawData,
    CompetitorRankRawData,
    KeywordType,
} from '../../types/competitiveBenchmark';

const RESOURCE_URI = 'competitive-benchmark';

export const OVERVIEW_DEFAULT: CompetitiveOverview = { dates: [], businesses: [] };

class CompetitiveBenchmark extends ApiResourceBaseClass {
    static async getKeywordsCompetitive(): Promise<CompetitiveKeywords> {
        const { data } = await CompetitiveBenchmark.get(`${RESOURCE_URI}/keywords`);
        const parsedAvailableKeywords: KeywordType[] = data
            ? data.available_keywords.map((availableKeyword: any) => {
                  return {
                      keyword: availableKeyword.keyword,
                      dates: availableKeyword.dates
                          .map((date: string) => new Date(date))
                          .sort((a: Date, b: Date) => a.getTime() - b.getTime()),
                  };
              })
            : [];

        return { available_keywords: parsedAvailableKeywords };
    }

    static async getOverview(
        keyword: string,
        startDate: Date,
        endDate: Date,
        businessFilters: BusinessModalFiltersType,
    ): Promise<CompetitiveOverview> {
        const start_date = formatDate(startDate);
        const end_date = formatDate(endDate);
        const { data } = await CompetitiveBenchmark.get(`${RESOURCE_URI}/overview`, {
            keyword,
            start_date,
            end_date,
            ...businessFilters,
        });
        if (!data) return OVERVIEW_DEFAULT;
        const dates: Date[] = data.overview.dates
            .map((date: string) => new Date(date))
            .sort((a: Date, b: Date) => a.getTime() - b.getTime());
        return { dates, businesses: data.overview.businesses };
    }

    static async getCompetitorRankings(
        keyword: string,
        date: string,
        businessFilters: BusinessModalFiltersType,
    ): Promise<CompetitorCentralRank[]> {
        const { data } = await CompetitiveBenchmark.get(`${RESOURCE_URI}/central`, {
            keyword,
            date,
            ...businessFilters,
        });

        const ranks = data?.ranks ?? [];

        return ranks.map(
            (data: CompetitorRankRawData): CompetitorCentralRank => ({
                name: data.name,
                highlight: data.highlight,
                apparitionPercentage: data.apparition_percentage,
                rank: data.rank,
                rating: data.average_rating,
                reviewCount: data.average_reviews,
            }),
        );
    }

    static async getCompetitorLocalRankings(
        keyword: string,
        date: string,
        businessId: string,
    ): Promise<CompetitorCentralRank[]> {
        const { data } = await CompetitiveBenchmark.get(`${RESOURCE_URI}/local`, {
            keyword,
            date,
            business: businessId,
        });

        const ranks = data?.ranks ?? [];

        return ranks.map(
            (data: CompetitorLocalRankRawData): CompetitorLocalRank => ({
                name: data.name,
                highlight: data.highlight,
                rank: data.rank,
                rating: data.rating,
                reviewCount: data.review_count,
                address: data.address,
            }),
        );
    }
}

export default CompetitiveBenchmark;
