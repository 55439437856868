import React, { useState } from 'react';

import {
    Button,
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    TextInput,
} from '@partoohub/ui';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import {
    PossibleValueContainerWrapper,
    PossibleValueIcons,
    PossibleValueImageContainer,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/sections/CustomFieldsModals/modals/CustomFieldForm/SpecificFormType/FormType.styled';

type Props = {
    value: string;
    saveValue: (arg0: string) => void;
};

type ObjectValue = {
    key: string;
    value: string;
};

const SelectImageValueJsonEdit = ({ value, saveValue }: Props) => {
    const { t } = useTranslation();

    const [objectValue, setObjectValue] = useState<ObjectValue[]>(() => {
        const result = [];

        try {
            const parsedValue = JSON.parse(value);
            for (const key in parsedValue) {
                result.push({ key, value: parsedValue[key] });
            }
            return result;
        } catch {
            return [{ key: '', value: '' }];
        }
    });

    const save = (objectValue: ObjectValue[]) => {
        const result = objectValue.reduce((acc, val) => {
            if (val.key !== '' || val.value !== '') {
                acc[val.key] = val.value;
            }
            return acc;
        }, {});

        if (
            isEmpty(result) ||
            (Object.keys(result).length === 1 &&
                result[Object.keys(result)[0]] === '' &&
                Object.keys(result)[0] === '')
        ) {
            saveValue('');
            return;
        }

        saveValue(JSON.stringify(result));
    };

    return (
        <>
            <PossibleValueContainerWrapper>
                {objectValue.map((item, index) => (
                    <PossibleValueImageContainer key={index}>
                        <TextInput
                            label={t('custom_fields__json_edit__key')}
                            value={item.key}
                            onChange={e => {
                                const newObjectValue = [...objectValue];
                                newObjectValue[index].key = e ?? '';
                                setObjectValue(newObjectValue);
                                save(newObjectValue);
                            }}
                            dataTrackId="custom_fields_select_image__key"
                        />
                        <TextInput
                            label={t('custom_fields__json_edit__value')}
                            value={item.value}
                            onChange={e => {
                                const newObjectValue = [...objectValue];
                                newObjectValue[index].value = e ?? '';
                                setObjectValue(newObjectValue);
                                save(newObjectValue);
                            }}
                            dataTrackId="custom_fields_select_image__value"
                        />
                        <PossibleValueIcons>
                            <IconButton
                                appearance="outlined"
                                variant="danger"
                                onClick={() => {
                                    let newObjectValue = [...objectValue];
                                    newObjectValue.splice(index, 1);

                                    if (newObjectValue.length === 0) {
                                        newObjectValue = [{ key: '', value: '' }];
                                    }

                                    setObjectValue(newObjectValue);
                                    save(newObjectValue);
                                }}
                                icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                                dataTrackId="custom_fields_select_image__remove_option"
                            />
                        </PossibleValueIcons>
                    </PossibleValueImageContainer>
                ))}
            </PossibleValueContainerWrapper>
            <Button
                size="medium"
                variant="primary"
                appearance="outlined"
                shape="cube"
                onClick={() => {
                    setObjectValue([...objectValue, { key: '', value: '' }]);
                }}
                icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                dataTrackId="custom_fields_select__add_option"
            >
                {t('custom_fields__json_edit__add')}
            </Button>
        </>
    );
};

export default SelectImageValueJsonEdit;
