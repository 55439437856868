import { Dialog, Loader, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useMetaRelogin } from 'app/pages/visibility/subComponents/MetaRelogin/MetaReloginContext';

export const LoadingDialog = () => {
    const { t } = useTranslation();
    const context = useMetaRelogin();

    if (!context) return null;

    const { percentageProgress } = context;

    return (
        <Dialog dataTrackId={t('meta_relogin_modal')}>
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ width: '300px', height: '300px' }}
            >
                <Loader
                    size="medium"
                    label={t('loading')}
                    text={`${percentageProgress}%`}
                    percentage={percentageProgress}
                />
                <Text variant="heading3">{t('loading')}</Text>
            </Stack>
        </Dialog>
    );
};
