import BusinessModalButton from 'app/common/components/businessModal';
import PageHeader from 'app/common/designSystem/components/molecules/PageHeader';
import AddPartnersButtons from 'app/reviewManagement/messaging/messenger/AddPartnersButtons';

export const DesktopHeader = () => {
    return (
        <div>
            <PageHeader
                title="messaging_title"
                pageButtons={[
                    <AddPartnersButtons key="modal" />,
                    <BusinessModalButton key="business-modal-key" />,
                ].filter(Boolean)}
            />
        </div>
    );
};
