import { AvatarMenuItem } from '@partoohub/modular-components';
import { FontAwesomeIconsPartooUsed, Icon, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SETTINGS_PATH } from 'app/routing/routeIds';

import { IconContainer } from './AvatarSettingsItem.styled';

export const AvatarSettingsItem = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(SETTINGS_PATH);
    };
    return (
        <AvatarMenuItem callback={handleClick}>
            <IconContainer>
                <Icon icon={[FontAwesomeIconsPartooUsed.faGear]} />
            </IconContainer>
            <Text variant="bodyMMedium" as="span">
                {t('settings')}
            </Text>
        </AvatarMenuItem>
    );
};
