import styled from '@emotion/styled';

import { Button } from '@partoohub/ui';

export const StyledReviewCardCommentsSection = styled.div`
    grid-area: comments;
    position: relative;
    display: grid;
    grid-gap: 0 8px;
    grid-template-columns: auto min-content;
    grid-template-areas:
        'comments comments'
        '. toggle_comments';

    .mdc-text-field-helper-line:empty {
        min-height: 0px;
        padding: 0px;
    }
`;

export const StyleToggleCommentsButton = styled(Button)`
    grid-area: toggle_comments;
    white-space: nowrap;
`;

export const StyledReplyButton = styled(Button)`
    margin-right: auto;
    margin-left: 52px;
`;
