import { useMemo, useState } from 'react';

import {
    DynamicFilters,
    FilterCategory,
    FilterName,
    Filters,
    PartialFilters,
} from '@partoohub/modular-components';
import { Stack } from '@partoohub/ui';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { ChoiceWithSubtitle } from 'app/api/types/user';
import { SelectableContentFilters } from 'app/common/components/SelectableContentFilters/SelectableContentFilters';
import FLAG from 'app/common/flags.json';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    LANGUAGES_MAP,
    LanguageType,
    getTranslationKey,
    languagesData,
} from 'app/common/services/languages';
import { useHideIntercom } from 'app/hooks/useHideIntercom';
import { allRatingsOptions } from 'app/pages/settings/AutoReply/components/SettingsPageForm/RatingsSelectStep/RatingButton/RatingButton';
import { FlagContainer } from 'app/pages/settings/AutoReply/components/SettingsPageForm/TemplateSelectStep/ReplyTemplateList/ReplyTemplateCard.styled';
import {
    concatTrueValues,
    createFilterMap,
} from 'app/pages/visibility/BusinessListV2/utils/helpers';

import { StyledCountryName } from './ReplyTemplatesFiltersButton.styled';

enum ReplyTemplatesQueryParams {
    RATING = 'rating',
    LANGUAGES = 'languages',
}

const createSelectableContentOptionFromChoices = (
    choices: readonly ChoiceWithSubtitle[],
    t: TFunction,
) => {
    return choices.map(({ label, value, icon }) => ({
        name: value,
        label: <SelectableContentFilters icon={<>{icon}</>} text={t(label)} />,
        value,
    }));
};

const createSelectableContentOptionFromLanguages = (
    languages: readonly LanguageType[],
    t: TFunction,
) => {
    return languages.map(lang => {
        const { code } = lang;

        return {
            name: code,
            label: (
                <Stack gap="0px" direction="column" justifyContent="center" alignItems="center">
                    <FlagContainer>
                        <img src={FLAG[LANGUAGES_MAP[code]?.country]} alt={`flag_${code}`} />
                    </FlagContainer>
                    <StyledCountryName variant="bodySBold" as="span">
                        <>{t(getTranslationKey(lang))}</>
                    </StyledCountryName>
                </Stack>
            ),
            value: code,
        };
    });
};

const BusinessFiltersButton = () => {
    const { t } = useTranslation();
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [rating, setRating] = useStateQueryParams(ReplyTemplatesQueryParams.RATING);
    const [language, setLanguage] = useStateQueryParams(ReplyTemplatesQueryParams.LANGUAGES);
    const sortedLanguagesData = useMemo(
        () =>
            [...languagesData].sort((lang1, lang2) =>
                t(getTranslationKey(lang1)).localeCompare(t(getTranslationKey(lang2))),
            ),
        [languagesData, getTranslationKey, t],
    );

    const filters = {
        review_type: createFilterMap(rating === 'all' ? '' : rating),
        language: createFilterMap(language),
    };

    const setFilters = (filters: PartialFilters) => {
        setRating(concatTrueValues(filters, FilterName.REVIEW_TYPE));
        setLanguage(concatTrueValues(filters, FilterName.LANGUAGE));
    };

    const ratingOptions = createSelectableContentOptionFromChoices(allRatingsOptions, t);
    const languageOptions = createSelectableContentOptionFromLanguages(sortedLanguagesData, t);

    const schema: Filters = [
        {
            id: ReplyTemplatesQueryParams.RATING,
            property: FilterName.REVIEW_TYPE,
            sectionName: t('auto_reply_review_types'),
            filterCategory: FilterCategory.SELECTABLE,
            options: ratingOptions,
            numberPerLine: 3,
        },
        {
            id: ReplyTemplatesQueryParams.LANGUAGES,
            property: FilterName.LANGUAGE,
            sectionName: t('language'),
            filterCategory: FilterCategory.SELECTABLE,
            options: languageOptions,
        },
    ];

    useHideIntercom(isFilterOpen);

    return (
        <DynamicFilters
            schema={schema}
            filters={filters as PartialFilters}
            onApply={setFilters}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            resetText={t('reset')}
            applyText={t('apply')}
            title={t('filters')}
            buttonText={t('filters')}
        />
    );
};

export default BusinessFiltersButton;
