import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const ErrorWarningText = styled(Text)`
    margin-left: 16px;
    margin-bottom: 8px;
`;

export const TextFieldWrapper = styled.div`
    margin-bottom: 16px;
`;
