import { useEffect, useRef, useState } from 'react';

import { SerializedStyles } from '@emotion/react';
import {
    Button,
    ButtonAppearance,
    ButtonProps,
    ButtonShape,
    ButtonSize,
    ButtonVariants,
    IconElement,
    MenuList,
    Option,
} from '@partoohub/ui';

import { MenuListProps } from '@partoohub/ui/dist/components/MenuList/MenuList.types';

import { Container, MenuListWrapper } from './ButtonWithMenu.styled';

type Props = {
    dataTrackId: string;
    text: string | JSX.Element;
    onMenuClick: (value: string) => void;
    options: Array<Option>;
    // Optional props
    appearance?: ButtonAppearance;
    variant?: ButtonVariants;
    icon?: IconElement;
    iconPosition?: ButtonProps['iconPosition'];
    selectedOptionName?: string;
    size?: ButtonSize;
    shape?: ButtonShape;
    cssMenu?: SerializedStyles;
    loadMore?: MenuListProps['loadMore'];
    hasMore?: MenuListProps['hasMore'];
};

export const ButtonWithMenu = ({
    dataTrackId,
    text,
    onMenuClick,
    options,
    shape = 'cube',
    appearance = 'contained',
    variant,
    icon,
    iconPosition,
    size,
    selectedOptionName = '',
    cssMenu,
    loadMore,
    hasMore,
}: Props) => {
    const wrapperRef = useRef<HTMLElement | null>(null);
    const [isMenuShown, setIsMenuShown] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (
                isMenuShown &&
                wrapperRef.current &&
                event.target instanceof Node &&
                !wrapperRef.current.contains(event.target)
            ) {
                toggleMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuShown]);

    const onClick = (value: Option) => {
        toggleMenu();
        onMenuClick(value.value);
    };

    const toggleMenu = () => {
        setIsMenuShown(prevIsMenuShown => !prevIsMenuShown);
    };

    const handleIsChecked = (option: Option) => {
        return option.name === selectedOptionName;
    };

    return (
        <Container ref={ref => (wrapperRef.current = ref)}>
            <Button
                dataTrackId={dataTrackId}
                icon={icon}
                iconPosition={iconPosition}
                shape={shape}
                appearance={appearance}
                variant={variant}
                onClick={toggleMenu}
                size={size}
            >
                {text}
            </Button>
            {isMenuShown && (
                <MenuListWrapper css={cssMenu}>
                    <MenuList
                        sections={[{ options: options }]}
                        isChecked={handleIsChecked}
                        onChange={value => {
                            if (value) onClick(value);
                        }}
                        loadMore={loadMore}
                        hasMore={hasMore}
                    />
                </MenuListWrapper>
            )}
        </Container>
    );
};
