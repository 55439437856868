import { V2UserData } from 'app/api/types/user';
import IntercomWrapper from 'app/common/services/intercomWrapper';

export const handleIntercomPostLogin = (user: V2UserData) => {
    return new Promise(resolve => {
        /**
         * We use Intercom identity verification if the backend sends us the intercom user Hash
         * Uses the shadow user intercom hash instead of the user if it exists
         */
        if (user.shadow_user_intercom_hash) {
            IntercomWrapper.updateUserHash(
                user.shadow_user_intercom_hash,
                user.shadow_user_id,
                window.location.pathname,
            );
        } else if (user.intercom_user_hash) {
            IntercomWrapper.updateUserHash(
                user.intercom_user_hash,
                user.id,
                window.location.pathname,
            );
        }

        resolve('[Intercom] User hash updated successfully');
    });
};

export const removeIntercomPostLogin = () => {
    IntercomWrapper.shutdown(false);
};
