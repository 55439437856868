import { ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useHasPostPermission } from 'app/presence/googlePosts/hooks/useHasPostPermission';

export const useCanCreatePost = (): boolean => {
    const { data: me } = useMe();
    const hasPostPage = !(me?.sidebar_excluded_pages.includes('google_post') ?? true);
    const canCreatePost = useHasPostPermission() ?? false;

    return hasPostPage && canCreatePost && me?.role !== ADMIN;
};
