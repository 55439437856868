import { LOGOUT_RESET_REDUCERS_DATA } from 'app/states/connection/reducers/login';
import { getStateId } from 'app/states/reviews';

import { CommentsState } from './types';
import * as actions from '../actions';
import { byReviewIdUpdater } from '../services';

export const initialState: CommentsState = {
    byReviewId: {},
};

// REDUCER
export const commentsReducer = (
    state: CommentsState = initialState,
    action: actions.CommentActionType,
): CommentsState => {
    switch (action.type) {
        case actions.FETCH_COMMENTS_SUCCESS:
            return {
                ...state,
                byReviewId: { ...state.byReviewId, ...action.comments },
            };

        case actions.DELETE_COMMENT_SUCCESS:
        case actions.CREATE_COMMENT_SUCCESS:
        case actions.UPDATE_COMMENT_SUCCESS:
            const reviewId = getStateId(action.review);

            return {
                ...state,
                byReviewId: byReviewIdUpdater(
                    state.byReviewId,
                    reviewId,
                    action.commentId,
                    action.parentId,
                    action.comment,
                ),
            };

        case LOGOUT_RESET_REDUCERS_DATA:
            return { ...initialState };

        default:
            return state;
    }
};

export default commentsReducer;
