import styled from '@emotion/styled';

import Clickable from 'app/common/components/buttons/Clickable';

export const StyledShowMoreButton = styled(Clickable)`
    display: inline-flex;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary.initial};
    &:hover {
        color: ${({ theme }) => theme.colors.primary.dark};
    }
`;
