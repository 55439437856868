import { ImageUploadError } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/hooks/useImageUploadErrors';

export interface AcceptedFile {
    memeType: string;
    extension: string;
}

export interface SupportedSizes {
    minimum: number; // Values in Ko
    maximum: number; // Values in Mo
}

export interface FileError {
    filename: string;
    errorType: 'too_big' | 'too_small' | 'wrong_format';
}

export interface ImageUploadErrorDetails {
    imageName?: string;
    errorTypes: ImageUploadError;
}

export const DEFAULT_ACCEPTED_FILES = [
    {
        memeType: 'application/vnd.ms-excel',
        extension: 'xls',
    },
    {
        memeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        extension: 'xlsx',
    },
];

export const getErrorsFromFiles = (
    files: File[],
    { maxSize, minSize }: { maxSize: number; minSize: number },
): FileError[] =>
    files.map(file => {
        const error = { filename: file.name };

        if (maxSize && file.size > maxSize) {
            return { ...error, errorType: 'too_big' };
        }

        if (minSize > 0 && file.size < minSize) {
            return { ...error, errorType: 'too_small' };
        }

        return { ...error, errorType: 'wrong_format' };
    });
