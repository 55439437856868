import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { BUSINESS_DISABLED_DIFFUSERS } from 'app/common/data/queryKeysConstants';

function useBusinessDisabledDiffusers() {
    const businessId = useGetBusinessId();

    return useQuery(
        [BUSINESS_DISABLED_DIFFUSERS, { businessId }],
        () => api.business.businessDisabledDiffusersGet(businessId),
        {
            enabled: !!businessId,
            retry: false,
            retryOnMount: false,
        },
    );
}

export default function useIsFacebookDisabled() {
    const { data, isLoading } = useBusinessDisabledDiffusers();
    return {
        isLoading,
        data: !!data?.diffusers?.includes('facebook'),
    };
}
