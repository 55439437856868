import { Checkbox, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import i18n from 'app/common/translations/i18n';
import { fieldsTranslations } from 'app/pages/settingsV2/subPages/Team/components/Permissions/utils/translations';

import {
    PermissionsFieldCheckbox,
    PermissionsFieldContainer,
    PermissionsFieldInfoIcon,
} from './PermissionsField.styled';

type Props = {
    fieldName: string;
    onCheckboxClick: (gm: boolean) => void;
    checkedGM: boolean;
    checkedBM: boolean;
    disabled: boolean;
    isCustomField: boolean;
};

const descriptionKeys = {
    status: 'business_fields_status_info',
};

const checkboxDescriptionKeys = {
    secondary: 'business_fields_secondary_info',
    logo: 'business_fields_logo_cover_info',
    cover: 'business_fields_logo_cover_info',
};

const getTooltipTextToDisplay = (fieldName: string, disabled: boolean, isCustomField: boolean) => {
    if (disabled) {
        return i18n.t('field_read_only');
    }

    if (!disabled && !isCustomField) {
        return i18n.t(checkboxDescriptionKeys[fieldName as keyof typeof checkboxDescriptionKeys]);
    }

    return '';
};

const PermissionsField = ({
    fieldName,
    onCheckboxClick,
    checkedGM,
    checkedBM,
    disabled,
    isCustomField,
}: Props) => {
    const { t } = useTranslation();

    return (
        <PermissionsFieldContainer>
            <Text variant="bodyMBold">
                {isCustomField
                    ? fieldName
                    : t(fieldsTranslations[fieldName as keyof typeof fieldsTranslations])}
            </Text>
            {!isCustomField && fieldName in descriptionKeys && (
                <TooltipWrapper
                    text={t(descriptionKeys[fieldName as keyof typeof descriptionKeys])}
                    position="right"
                >
                    <PermissionsFieldInfoIcon className="info-question fas fa-question-circle" />
                </TooltipWrapper>
            )}

            <PermissionsFieldCheckbox>
                <TooltipWrapper
                    text={getTooltipTextToDisplay(fieldName, disabled, isCustomField)}
                    position="left"
                >
                    <Checkbox
                        dataTrackId="permission_field_gm__checkbox"
                        checked={checkedGM}
                        onChange={() => onCheckboxClick(true)}
                        disabled={disabled}
                    />
                </TooltipWrapper>
                <TooltipWrapper
                    text={getTooltipTextToDisplay(fieldName, disabled, isCustomField)}
                    position="left"
                >
                    <Checkbox
                        dataTrackId="permission_field_bm__checkbox"
                        checked={checkedBM}
                        onChange={() => onCheckboxClick(false)}
                        disabled={disabled}
                    />
                </TooltipWrapper>
            </PermissionsFieldCheckbox>
        </PermissionsFieldContainer>
    );
};

export default PermissionsField;
