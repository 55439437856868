import { Avatar, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { BusinessUser } from 'app/api/types/user';
import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';
import { STATIC_APP_ASSETS_URL } from 'app/config';
import { JimIconWithBackground } from 'app/pages/settings/Chatbot/assets/jim-icon';

import { UserLabel } from './UserLabel';

interface DisplayedLabelProps {
    isLoading: boolean;
    isAssigned: boolean;
    assignedUser: BusinessUser | null;
    isAutomated: boolean;
}

export const DisplayedLabel = ({
    isLoading,
    isAssigned,
    assignedUser,
    isAutomated,
}: DisplayedLabelProps) => {
    const { t } = useTranslation();

    if (isLoading) return <LoadingRectangle recWidth="100px" recHeight="8px" />;

    if (isAutomated) {
        return (
            <Stack direction="row" alignItems="center" gap="8px">
                <JimIconWithBackground size={16} />
                <Text variant="bodySSemibold">{t('messaging_assign_filter_chatbot')}</Text>
            </Stack>
        );
    }

    if (!isAssigned)
        return (
            <Stack direction="row" alignItems="center" gap="8px">
                <Avatar image={`${STATIC_APP_ASSETS_URL}/common/unassigned_icon.svg`} size={16} />
                <Text variant="bodySSemibold">{t('messaging_assign_cta_unassigned')}</Text>
            </Stack>
        );

    return <UserLabel assignedUser={assignedUser} />;
};
