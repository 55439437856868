import { combineReducers } from 'redux';

import locations, { FacebookLocationsState } from './facebookLocations';

export type FacebookState = {
    locations: FacebookLocationsState;
};

export default combineReducers({
    locations,
});
