import React from 'react';

import { LeftElementType, TextInput } from '@partoohub/ui';

import formatHours from 'app/businessEditV2/oldFromEditV1/services/formatHours';

export type Props = {
    value?: string;
    disabled?: boolean;
    placeholder?: string;
    hasError?: boolean;
    icon?: JSX.Element;
    isMandatory?: boolean;
    onChange: (inputValue?: string) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const HourField = ({
    value,
    disabled,
    placeholder = '00:00',
    hasError = false,
    icon,
    isMandatory,
    onChange,
    onBlur = () => {},
}: Props) => (
    <TextInput
        dataTrackId="hour_field"
        value={value}
        leftElement={icon}
        leftElementType={LeftElementType.Icon}
        disabled={disabled}
        placeholder={placeholder}
        required={isMandatory}
        error={hasError}
        onChange={onChange}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            onBlur(e);
            e.target.value = formatHours(e.target.value ?? '');
            onChange(e.target.value);
        }}
    />
);
export default HourField;
