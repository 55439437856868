import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

export const LogoButtonContainer = styled.div<{ disabled?: boolean; active?: boolean }>`
    font-size: 16px;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    font-weight: bold;
    width: 100%;
    height: 56px;
    padding: 3px 2 * 8px 3px 2 * 8px;
    white-space: nowrap;

    ${({ active, theme }) =>
        active
            ? css`
                  border: none;
                  background-color: rgba(3, 159, 241, 0.12);
                  color: ${theme.colors.primary.initial} !important;
              `
            : css`
                  border: solid 1px rgba(51, 51, 51, 0.11);
                  color: ${theme.colors.secondary.initial};
              `};

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
            pointer-events: none;
        `}
`;

export const StyledText = styled(Text)`
    text-transform: capitalize;
`;
