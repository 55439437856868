import { useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import UnsavedModal from 'app/businessEditV2/components/UnsavedModal';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useCountry from 'app/businessEditV2/hooks/business/useCountry';
import useIsLoadingAddress from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingAddress';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import { useIsBusinessGlobal } from 'app/businessEditV2/hooks/editV2utils/useIsBusinessGlobal';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import AddressFormLeft from 'app/businessEditV2/sections/AddressSection/AddressFormModal/AddressForm/AddressForm';
import GoogleMapsStreetView from 'app/common/components/form/GoogleMaps/GoogleMapsStreetView';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import { StreetViewContainer } from './AddressFormModal.styled';

type Props = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    closeModal: () => void;
    showUnsavedModal: boolean;
    setShowUnsavedModal: (showUnsaved: boolean) => void;
    useDirtyUpdate: (isDirty: boolean) => void;
};

const AddressFormModal = ({
    showModal,
    setShowModal,
    closeModal,
    showUnsavedModal,
    setShowUnsavedModal,
    useDirtyUpdate,
}: Props) => {
    const isLoading = useIsLoadingAddress();
    const { data: business } = useBusiness();
    const { data: countryData } = useCountry();
    const { t } = useTranslation();
    const isBusinessGlobal = useIsBusinessGlobal();

    const setFormValue = () => ({
        name: business?.name ?? '',
        addressFull: business?.address_full ?? '',
        address2: business?.address2 ?? '',
        zipcode: business?.zipcode ?? '',
        city: { label: business?.city ?? '', value: business?.city ?? '' },
        country: countryData?.find(country => country.value === business?.country) ?? {
            label: '',
            value: '',
        },
        lat: business?.lat ?? null,
        long: business?.long ?? null,
        manualLat: business?.manual_lat ?? null,
        manualLong: business?.manual_long ?? null,
    });

    const businessIsOpen = !useIsBusinessClosed();
    const coordinatesPermission =
        useFieldMetadata('infos', 'coordinates')?.enabled && businessIsOpen;

    const form = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    useDirtyUpdate(form.formState.isDirty);

    useEffect(() => {
        if (!isLoading) {
            form.reset(setFormValue());
        }
    }, [isLoading]);

    const onDrag = (lat: number, long: number) => {
        form.setValue('manualLat', lat, { shouldDirty: true });
        form.setValue('manualLong', long, { shouldDirty: true });
    };

    const [watchLat, watchManualLat] = form.watch(['lat', 'manualLat']);
    const lat = watchManualLat || watchLat;

    const [watchLong, watchManualLong] = form.watch(['long', 'manualLong']);
    const long = watchManualLong || watchLong;

    return (
        <FullScreenModal
            show={showModal}
            childrenRight={
                isBusinessGlobal ? undefined : (
                    <EditFormDescription
                        title={t('business_edit_title_main_info')}
                        description={t('business_edit_description_main_info')}
                        advice={t('business_edit_advice_main_info')}
                    >
                        <StreetViewContainer
                            data-track="visibility_location__form_address__street_view"
                            data-intercom-target="visibility_location__form_address__street_view"
                        >
                            <GoogleMapsStreetView
                                lat={lat}
                                long={long}
                                onDrag={onDrag}
                                disabled={!coordinatesPermission}
                            />
                        </StreetViewContainer>
                    </EditFormDescription>
                )
            }
            childrenLeft={
                <>
                    <AddressFormLeft form={form} closeModal={() => setShowModal(false)} />
                    <UnsavedModal
                        show={showUnsavedModal}
                        cancel={() => setShowUnsavedModal(false)}
                        validate={() => setShowModal(false)}
                    />
                </>
            }
            onHideClick={closeModal}
            forceDisplayExitButton
        />
    );
};

export default AddressFormModal;
