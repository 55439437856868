import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { BusinessListQueryKeys } from 'app/pages/visibility/BusinessListV2/utils/enums';

const ORDER_BY_DEFAULT_VALUE = '-created';

export const useGetOrderBy = () => {
    const [orderBy] = useStateQueryParams(BusinessListQueryKeys.ORDER_BY);

    return {
        order_by: orderBy || ORDER_BY_DEFAULT_VALUE,
    };
};
