import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';
import { Icon, Text } from '@partoohub/ui';

export const AsyncInputLabelContainer = styled.div<{ disabled?: boolean }>`
    font-weight: bold;
    ${({ disabled }) =>
        disabled &&
        css`
            * {
                cursor: not-allowed;
            }
        `}
`;

export const AsyncInputLabelError = styled(Text)`
    height: 16px;
`;

export const AsyncInputLabelUnderlined = styled.div<{
    showInput: boolean;
    disabled?: boolean;
    hasError?: string | false | null;
}>`
    border-bottom: solid 1px ${greyColorObject.initial};
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: ${({ theme }) => theme.colors.primary.initial};
        left: 0;
        bottom: -1px;
        transform: scaleX(0);
        transition:
            transform 0.18s cubic-bezier(0.4, 0, 0.2, 1),
            opacity 0.18s cubic-bezier(0.4, 0, 0.2, 1);
    }

    ${({ showInput }) =>
        showInput &&
        css`
            &::after {
                opacity: 1;
                transform: scaleX(1);
            }
        `}

    ${({ hasError, theme }) =>
        hasError &&
        css`
            &::after {
                background: ${theme.colors.danger.initial} !important;
            }
        `}

    &:hover {
        border-bottom: solid 1px black;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            border-bottom: solid 1px ${greyColorObject.initial}!important;
        `}
`;

export const AsyncInputLabelText = styled.div<{
    noValue: boolean;
    disabled?: boolean;
}>`
    display: flex;
    justify-content: space-between;
    color: ${HEX_COLORS.black};
    padding: 4px 0;
    cursor: pointer;
    outline: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    ${({ noValue, theme }) =>
        noValue &&
        css`
            color: ${theme.colors.primary.initial};
        `}

    ${({ disabled, theme }) =>
        disabled &&
        css`
            color: ${theme.colors.secondary.initial} !important;
            pointer-events: none;
        `}
`;

export const AsyncInputLabelBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    padding-right: 2px;
`;

export const AsyncInputLabelInput = styled.input`
    all: unset;
    padding: 4px 6px 4px 0;
    min-width: 1px;
`;

export const StyledIcon = styled(Icon)`
    margin-left: 8px;
`;

export const DefaultIcon = styled.i`
    margin-left: 8px;
    font-size: 10px;
    color: ${greyColorObject.initial};
`;
