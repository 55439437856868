import { useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Icon, IconPrefix, Modal, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from 'react-query';

import { POSTS_BUSINESS_SCOPES } from 'app/common/data/queryKeysConstants';
import CheckableBar from 'app/common/designSystem/components/molecules/CheckableBar';
import FacebookRefreshModal from 'app/presence/googlePosts/components/googlePostList/FacebookRefreshModal';
import useFacebookConnectWindow from 'app/presence/googlePosts/hooks/useFacebookConnectWindow';
import LinkIcon from 'app/reviewManagement/messaging/messenger/InstagramLinkedToFbIcon';

import { MarginHolder, ModalContent, StyledButton } from './InstagramConnectModal.styled';

type Props = {
    onClose: () => void;
};

export default function InstagramConnectModal({ onClose }: Props) {
    const { t } = useTranslation();
    const [checked, setChecked] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const [oauthAccountId, setOauthAccountId] = useState<string | null>(null);
    const openInstagramOauthDialog = useFacebookConnectWindow(oauthAccountId => {
        setOauthAccountId(oauthAccountId);

        // If facebook window was closed before re-connecting account
        // we close the instagram modal
        if (!oauthAccountId) {
            onClose();
        }
    });

    return (
        <>
            <Modal isOpen={!oauthAccountId} closeModal={onClose}>
                <ModalContent>
                    <Text as="h4" color={HEX_COLORS.blackberry} variant="heading4">
                        {t('post_instagram_setup_modal_title')}
                    </Text>
                    <MarginHolder height={14}></MarginHolder>
                    <Text color={'secondary'} variant="bodyMRegular">
                        <span>{t('post_instagram_setup_modal_steps')}</span>
                        <ol
                            style={{
                                listStylePosition: 'inside',
                                padding: '0',
                            }}
                        >
                            <li>{t('post_instagram_setup_modal_step_connect')}</li>
                            <li>{t('post_instagram_setup_modal_step_reimport')}</li>
                        </ol>
                    </Text>
                    <MarginHolder height={14}></MarginHolder>
                    <CheckableBar
                        title={t(
                            'messaging_add_instagram_tutorial_modale_step_2_title', // Same to messaging
                        )}
                        subLinkText={t(
                            'messaging_add_instagram_tutorial_modale_step_2_link', // Same to messaging
                        )}
                        subLinkURL={t(
                            'messaging_add_instagram_tutorial_modale_step_2_url', // Same to messaging
                        )}
                        leftIcon={LinkIcon}
                        toggleCheckbox={() => setChecked(!checked)}
                        checked={checked}
                        dataTrack=""
                    />
                    <MarginHolder height={8}></MarginHolder>
                    <Text as="span" color={'secondary'} variant="bodyMRegular">
                        {`*${t('post_instagram_setup_modal_check_after_connection')}`}
                    </Text>
                    <StyledButton
                        appearance="contained"
                        shape="cube"
                        size="large"
                        variant="primary"
                        dataTrackId={''}
                        onClick={openInstagramOauthDialog}
                        disabled={!checked}
                        icon={
                            <Icon
                                icon={['fa-facebook', IconPrefix.BRANDS]}
                                iconSize="16px"
                                color={HEX_COLORS.secondary}
                            />
                        }
                    >
                        {t('continue_with_facebook')}
                    </StyledButton>
                </ModalContent>
            </Modal>
            {oauthAccountId && (
                <FacebookRefreshModal
                    oauthAccountId={oauthAccountId}
                    onSuccess={() => {
                        queryClient.invalidateQueries(POSTS_BUSINESS_SCOPES);
                        setOauthAccountId(null);
                        onClose();
                    }}
                />
            )}
        </>
    );
}
