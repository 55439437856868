import { useState } from 'react';

import { Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { MessagingSetting } from 'app/api/types/business_message_settings';
import { Choice } from 'app/api/types/user';
import { usePrompt } from 'app/hooks/usePrompt';

import { ConfigurationModalContainer, FormPanelContainer } from './ConfigurationModal.styled';
import { PreviewPanelContainer } from './PreviewPanel';
import { SettingsForm } from './SettingsForm';
import SettingSingleSelect from './SettingSingleSelect';
import { useMessagingSettings } from '../hooks/useMessagingSettings';
import { SettingFormData } from '../hooks/useSettingForm';
import { useUpdateSetting } from '../hooks/useUpdateSetting';
import { MessagingSettingsFormErrors } from '../types';

type Props = {
    toggleShowModal?: () => void;
    formData: SettingFormData;
    onSelectionChange: (setting: MessagingSetting) => void;
};

export const ConfigurationModal = ({ toggleShowModal, formData, onSelectionChange }: Props) => {
    const { t } = useTranslation();

    const {
        control,
        selectedSetting,
        clearErrors,
        formState: { errors, isDirty },
    } = formData;

    const [settingSingleSelectQuery, setSettingSingleSelectQuery] = useState<string>('');

    const [allSettings, fetchNextPage, hasNextPage] =
        useMessagingSettings(settingSingleSelectQuery);

    const updateSettingMutation = useUpdateSetting(selectedSetting?.id, toggleShowModal);

    const settingsChoices = allSettings.map((setting: MessagingSetting) => ({
        label: `${setting.businessName} - ${setting.businessAddress}`,
        value: setting.id.toString(),
    }));

    const onChangeSelect = (choice: Choice) => {
        if (choice?.value && allSettings.length > 0) {
            onSelectionChange(allSettings.find(element => element?.id === parseInt(choice.value)));
        }
    };

    const formIsDirty = isDirty && (updateSettingMutation.isIdle || updateSettingMutation.isError);

    usePrompt(t('unsaved_changes'), formIsDirty);

    return (
        <ConfigurationModalContainer gap="24px" direction="row" style={{ flex: 1 }}>
            <Stack direction="column" style={{ width: '100%' }}>
                <FormPanelContainer direction="column" style={{ height: '100%' }}>
                    <Text variant="bodyLBold" as="span">
                        {t('chat_settings_business_selection_section_title')}
                    </Text>
                    {selectedSetting && (
                        <SettingSingleSelect
                            settingsChoices={settingsChoices}
                            selectedSetting={selectedSetting}
                            onChangeSelect={onChangeSelect}
                            hasMoreSettings={hasNextPage}
                            fetchNextPage={fetchNextPage}
                            setSettingSingleSelectQuery={setSettingSingleSelectQuery}
                            settingSingleSelectQuery={settingSingleSelectQuery}
                        />
                    )}

                    <form>
                        {!!selectedSetting && (
                            <SettingsForm
                                control={control}
                                errors={errors as MessagingSettingsFormErrors}
                                clearErrors={clearErrors}
                            />
                        )}
                    </form>
                </FormPanelContainer>
            </Stack>
            {selectedSetting && (
                <PreviewPanelContainer selectedSetting={selectedSetting} control={control} />
            )}
        </ConfigurationModalContainer>
    );
};
