import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';

export default function useFetchGmbImages(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const businessId = useGetBusinessId();
    return useMutation(() => api.business.fetchGmbImages(businessId ?? ''), {
        onSuccess,
        onError,
    });
}
