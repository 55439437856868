const AIReplyLogo = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
            fill="#8A5DF2"
            fillOpacity="0.12"
        />
        <path
            d="M22.2188 14.6875L24 14L24.6562 12.25C24.6875 12.0938 24.8438 12 25 12C25.125 12 25.2812 12.0938 25.3125 12.25L26 14L27.75 14.6875C27.9062 14.7188 28 14.875 28 15C28 15.1562 27.9062 15.3125 27.75 15.3438L26 16L25.3125 17.7812C25.2812 17.9062 25.125 18 25 18C24.8438 18 24.6875 17.9062 24.6562 17.7812L24 16L22.2188 15.3438C22.0938 15.3125 22 15.1562 22 15C22 14.875 22.0938 14.7188 22.2188 14.6875ZM18.4062 14.3125L20.0312 17.875L23.5938 19.5C23.7812 19.5938 23.9062 19.7812 23.9062 19.9688C23.9062 20.1562 23.7812 20.3438 23.5938 20.4062L20.0312 22.0625L18.4062 25.625C18.3125 25.8125 18.125 25.9375 17.9375 25.9375C17.75 25.9375 17.5625 25.8125 17.5 25.625L15.8438 22.0625L12.2812 20.4375C12.0938 20.3438 12 20.1562 12 19.9688C12 19.7812 12.0938 19.5938 12.2812 19.5L15.8438 17.875L17.5 14.3125C17.5625 14.125 17.75 14 17.9375 14C18.125 14 18.3125 14.125 18.4062 14.3125ZM24 24L24.6562 22.25C24.6875 22.0938 24.8438 22 25 22C25.125 22 25.2812 22.0938 25.3125 22.25L26 24L27.75 24.6875C27.9062 24.7188 28 24.875 28 25C28 25.1562 27.9062 25.3125 27.75 25.3438L26 26L25.3125 27.7812C25.2812 27.9062 25.125 28 25 28C24.8438 28 24.6875 27.9062 24.6562 27.7812L24 26L22.2188 25.3438C22.0938 25.3125 22 25.1562 22 25C22 24.875 22.0938 24.7188 22.2188 24.6875L24 24Z"
            fill="#8A5DF2"
        />
        <path
            d="M39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C9.23045 39.5 0.5 30.7696 0.5 20C0.5 9.23045 9.23045 0.5 20 0.5C30.7696 0.5 39.5 9.23045 39.5 20Z"
            stroke="black"
            strokeOpacity="0.12"
        />
    </svg>
);

export default AIReplyLogo;
