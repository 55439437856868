import React from 'react';

import { Chip, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { OpenHourHeaderContainer } from 'app/common/components/OpenHour/OpenHour.styled';
import { useFormatDate } from 'app/common/hooks/useFormatDate';

type Props = {
    starts_at: string;
    ends_at: string;
    bankHolidayName?: string;
};

const DateHeader = ({ starts_at, ends_at, bankHolidayName }: Props) => {
    const { t } = useTranslation();

    const generateDate = useFormatDate({
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
    });

    const formatHeaderDate = (starts_at: string, ends_at: string) => {
        if (!(starts_at && ends_at)) {
            return t('specific_hours__new_date');
        }
        if (starts_at == ends_at) {
            return generateDate(starts_at);
        } else {
            return generateDate(starts_at) + ' - ' + generateDate(ends_at);
        }
    };

    const name = bankHolidayName ?? formatHeaderDate(starts_at, ends_at);

    return (
        <OpenHourHeaderContainer>
            <Text variant="bodyMBold">{name}</Text>
            {bankHolidayName && (
                <Chip dataTrackId={'bank-holiday-tag'} variant={'secondary'} chipShape={'cube'}>
                    {t('bank_holidays__bank_holiday')}
                </Chip>
            )}
        </OpenHourHeaderContainer>
    );
};

export default DateHeader;
