import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

export const PostContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.theme.background};
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: 50px 100px minmax(80px, 1fr) 40px;
    grid-template-areas:
        'photo photo photo'
        'photo photo photo'
        'description description description'
        'partner partner partner';
    transition: all ease-in-out 0.2s;
    &:hover {
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    }
`;

export const PostCardFooter = styled.div`
    grid-area: partner;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 10px;
    background-color: ${HEX_COLORS.white};
    color: ${({ theme }) => theme.colors.secondary.initial};
    border: 1px solid ${greyColorObject.initial};
    border-radius: 0 0 4px 4px;
    gap: 10px;
`;
