import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import { FunnelBulkInApp } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/components/FunnelBulkInApp';
import { FunnelModalContextProvider } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/hooks/FunnelModalContext';

import BusinessList from './BusinessList';

export const BusinessListV2Page = () => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('businesses_list')} />
            <FunnelModalContextProvider>
                <BusinessList />
                <FunnelBulkInApp />
            </FunnelModalContextProvider>
        </>
    );
};
