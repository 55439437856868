import uniqBy from 'lodash/uniqBy';

import { useTranslation } from 'react-i18next';
import { QueryKey, useInfiniteQuery } from 'react-query';

import Messaging from 'app/api/v2/api_calls/messagingApiCalls';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import WHITELIST_PROVIDERS from 'app/common/data/providers';
import { MESSAGING_CONVERSATIONS } from 'app/common/data/queryKeysConstants';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { AssignFilterOption, StatusFilterOption, URLParams } from 'app/states/messaging';

import { conversationsSort } from '../ConversationUtils';
import { generateDelightConversation } from '../utils/generateDelightConversation';

export const useGetConversations = () => {
    const [statusFilter] = useStateQueryParams<StatusFilterOption>(URLParams.STATUS);
    const [assignFilter] = useStateQueryParams<AssignFilterOption>(URLParams.ASSIGN);
    const { t } = useTranslation();

    const businessFilters = useBusinessModalFilters();
    const { data: me } = useMe();

    const queryKey = getConversationsKey(statusFilter, assignFilter, businessFilters);
    const assignedTo = me?.id ? getAssignedTo(assignFilter, me.id) : null;
    const fetchConversations = ({ pageParam = null }) => {
        return Messaging.getConversations(businessFilters, pageParam, statusFilter, assignedTo);
    };

    const { data, hasNextPage, isLoading, isFetching, fetchNextPage } = useInfiniteQuery(
        queryKey,
        fetchConversations,
        {
            getNextPageParam: lastPage => lastPage.next_cursor || null,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );

    const formattedConversations =
        data?.pages.flatMap(data => data.conversations).sort(conversationsSort) || [];
    const shouldIncludeDelight =
        !hasNextPage &&
        !isLoading &&
        me?.provider &&
        WHITELIST_PROVIDERS.includes(me.provider) &&
        statusFilter === StatusFilterOption.OPEN &&
        assignedTo === null;

    const conversations = uniqBy(
        shouldIncludeDelight
            ? [...formattedConversations, generateDelightConversation(t)]
            : formattedConversations,
        'id',
    );

    return { conversations, hasNextPage, isLoading, isFetching, fetchNextPage };
};

const getAssignedTo = (assign: string, id: string) => {
    switch (assign) {
        case AssignFilterOption.ME:
            return id;
        case AssignFilterOption.UNASSIGNED:
            return 'unassigned';
        case AssignFilterOption.AUTOMATED:
            return 'automated';
        default:
            return null;
    }
};

export const getConversationsKey = (
    statusFilter: StatusFilterOption,
    assignFilter: AssignFilterOption,
    businessFilters: BusinessModalFiltersType,
): QueryKey => [MESSAGING_CONVERSATIONS, { statusFilter, assignFilter, businessFilters }];
