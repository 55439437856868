import { useState } from 'react';

import { useQuery } from 'react-query';

import WhatsappApiCalls from 'app/api/v2/api_calls/whatsappApiCalls';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { WA_DEDICATED_NUMBER_ONBOARDING } from 'app/common/data/featureFlags';
import { SMB } from 'app/common/data/organization';
import { WHATSAPP_NUMBER_ALLOWED } from 'app/common/data/queryKeysConstants';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';

import WhatsappAccountCreationModalPrerequirements from './WhatsappAccountCreationModalPrerequirements';
import WhatsappAccountCreationNumberChoiceModal from './WhatsappAccountCreationNumberChoiceModal';

type Props = {
    onClose: () => void;
    onEmbeddedSignupFinished: () => void;
};

export const WhatsappEmbeddedSignupModal = ({ onClose, onEmbeddedSignupFinished }: Props) => {
    const [isFirstStep, setIsFirstStep] = useState<boolean>(true);

    const { data: me } = useMe();
    const { data: org } = useOrganization(me?.org_id ?? 0, !!me?.org_id);
    const isSmb: boolean = org?.organization_type === SMB;
    const { data, isLoading } = useQuery(
        [WHATSAPP_NUMBER_ALLOWED],
        () => WhatsappApiCalls.getNumberAuthorization(),
        {},
    );
    const isDedicatedNumberOnboardingEnabled = useFeatureFlag(WA_DEDICATED_NUMBER_ONBOARDING);

    return isFirstStep ? (
        <WhatsappAccountCreationModalPrerequirements
            isOpen={isFirstStep}
            onClose={onClose}
            onEmbeddedSignupFinished={onEmbeddedSignupFinished}
            isNumberChoiceAllowed={isDedicatedNumberOnboardingEnabled && data?.is_allowed}
            isLoading={isLoading}
            onClick={() => setIsFirstStep(false)}
            isSmb={isSmb}
        />
    ) : (
        <WhatsappAccountCreationNumberChoiceModal
            isOpen={!isFirstStep}
            onClose={onClose}
            onBack={() => setIsFirstStep(true)}
            onEmbeddedSignupFinished={onEmbeddedSignupFinished}
            isSmb={isSmb}
        />
    );
};
