import { useState } from 'react';

import { Stack } from '@partoohub/ui';
import { Control, UseFormWatch, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PasswordChecks } from 'app/common/components/PasswordChecks/PasswordChecks';
import {
    PasswordField,
    Props,
} from 'app/common/designSystem/components/atoms/PasswordField/PasswordField';
import { passWordChecker } from 'app/common/services/passwordChecker';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    watch: UseFormWatch<any>;
};

type OmitProps = 'value' | 'placeholder' | 'onChange' | 'onFocus' | 'onBlur' | 't';

export const ControlledPasswordField = ({
    name,
    control,
    watch,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => {
    const { t } = useTranslation();
    const { field } = useController({
        name,
        control,
    });
    const [showPasswordChecker, setShowPasswordChecker] = useState<boolean>(false);

    return (
        <Stack>
            <PasswordField
                value={field.value}
                label={t('password')}
                onChange={field.onChange}
                onFocus={() => setShowPasswordChecker(true)}
                onBlur={() => setShowPasswordChecker(false)}
                {...props}
            />
            <PasswordChecks
                displayChecker={showPasswordChecker}
                {...passWordChecker(watch('password') ?? '')}
            />
        </Stack>
    );
};
