import { all, put, select } from 'redux-saga/effects';

import { resetBusinessFilter } from 'app/common/components/businessModalDeprecated/reducers';
import { MeState, meOrgIdSelector } from 'app/common/reducers/me';
import {
    resetBusinessState,
    updateSearchQuery,
} from 'app/common/reducers/newBusinesses';
import { getOrg } from 'app/common/reducers/orgs';
import retrieveQueryParam from 'app/common/services/retrieveQueryParam';
import { Saga } from 'app/common/types';
import { initialDataLoadRequest } from 'app/states/knowledge/reducers/businessConnection/initialDataLoad';
import { displayConnectionSearchBar } from 'app/states/knowledge/reducers/businessConnection/nav';
import { meSelector } from 'app/states/reducers';

import loadPartnerAccounts from './loadPartnerAccounts';

function* loadInitialDataSaga(): Saga {
    yield put(initialDataLoadRequest());
    const param = retrieveQueryParam('search');
    // resetBusinessFilter will cause the 1st page to be fetched twice on initial renders
    // TODO: A partial redesign of the business reducer is in order in order to fix that without adding complexity.
    yield all([put(resetBusinessState()), put(resetBusinessFilter())]);
    yield* loadPartnerAccounts();

    if (param) {
        yield put(updateSearchQuery(param));
        yield put(displayConnectionSearchBar());
    }

    const me = (yield select(meSelector) as any) as MeState;
    const orgId = meOrgIdSelector(me);
    if (orgId) yield put(getOrg(orgId));
}

export default loadInitialDataSaga;
