import { useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePhotosUpload from 'app/businessEditV2/hooks/usePhotosUpload';
import {
    businessEditMaxPhotoSize,
    businessEditMinPhotoSize,
    getFirstPhotoUploadFileError,
} from 'app/businessEditV2/utils/photos';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';

import AddImageIcon from 'app/common/components/icons/AddImageIcon';

import ImageErrorIcon from 'app/common/components/icons/ImageErrorIcon';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import DropZone from 'app/common/designSystem/components/molecules/DropZone';
import {
    FileError,
    getErrorsFromFiles,
} from 'app/common/designSystem/components/molecules/DropZone/helpers';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

import { getMappedUrl, resizeImage } from 'app/utils/resizeImage';

import {
    ActionButton,
    DropZoneContainer,
    Header,
    PhotoFormModalContainer,
    PhotoPreview,
    PhotoPreviewOverlay,
    ReturnButton,
} from './PhotoFormModal.styled';

type Props = {
    closeModal: () => void;
};

const LogoForm = ({ closeModal }: Props) => {
    const { t } = useTranslation();
    const { data: business, isLoading } = useBusiness();
    const [fileErrors, setFileErrors] = useState<FileError[]>([]);
    const defaultPhotos = business?.photos?.secondary ?? [];
    const defaultLogo = business?.photos?.LOGO ?? '';

    const logoPermission = useFieldMetadata('photos', 'logo')?.enabled;
    const photosPermission = useFieldMetadata('photos', 'secondary')?.enabled;

    const businessIsOpen = !useIsBusinessClosed();
    const permission = logoPermission && photosPermission && businessIsOpen;

    const setFormValue = () => ({
        photos: defaultPhotos,
        logo: defaultLogo,
    });

    const formatPayload = payload => {
        if (!permission) {
            return {};
        }

        return {
            photos: {
                secondary: payload.photos,
                LOGO: payload.logo,
            },
        };
    };

    const submitForm = () => {
        handleSubmit(payload => onSubmit(formatPayload(payload)))();
        trackSaveBusinessEditSection(
            SectionNames.LOGO,
            defaultLogo ? ColorState.OK : ColorState.MISSING_WARNING,
        );
    };

    const { handleSubmit, watch, setValue, reset } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const onSubmit = payload => {
        businessUpdate.mutate(payload);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_LOGO_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
    };

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const businessUpdate = useBusinessUpdate(() => {
        businessUpdateCompletionRate.mutate();
        closeModal();
    });

    const currentLogo = watch('logo');
    const currentPhotos = watch('photos');

    const setNewLogo = (newLogo: string, shouldDirty = true) => {
        if (permission) {
            if (currentLogo === defaultLogo) {
                setValue('photos', [...currentPhotos, currentLogo], {
                    shouldDirty,
                });
            }
            setValue('logo', newLogo, { shouldDirty });
        }
    };

    const uploadPhotos = usePhotosUpload(
        images => {
            const newLogo = images.result.uploads[0];
            if (newLogo) {
                setNewLogo(newLogo);
            }
        },
        error => {
            setNewLogo('');
            const fileErrors = getFirstPhotoUploadFileError(error);
            if (fileErrors) {
                setFileErrors([fileErrors]);
            }
        },
    );

    const displayPreview = !!(currentLogo && !uploadPhotos.isLoading);

    return (
        <PhotoFormModalContainer>
            <Header>
                <ReturnButton onClick={closeModal}>
                    <i className="fa-solid fa-arrow-left" />
                    {t('logo')}
                </ReturnButton>
            </Header>
            <DropZoneContainer hideBorder={displayPreview}>
                <DropZone
                    files={[]}
                    handleDropSuccess={files => {
                        setFileErrors([]);
                        uploadPhotos.mutate(files);
                    }}
                    handleDropError={files => {
                        setNewLogo('', false);
                        const errors = getErrorsFromFiles(files, {
                            maxSize: businessEditMaxPhotoSize,
                            minSize: businessEditMinPhotoSize,
                        });
                        setFileErrors(errors);
                    }}
                    label={t('menu_photo_dropzone_label')}
                    uploadIcon={<AddImageIcon />}
                    errorIcon={<ImageErrorIcon />}
                    acceptedFiles={[
                        {
                            memeType: 'image/jpeg',
                            extension: 'JPEG',
                        },
                        {
                            memeType: 'image/jpg',
                            extension: 'JPG',
                        },
                        {
                            memeType: 'image/png',
                            extension: 'PNG',
                        },
                    ]}
                    maxSize={businessEditMaxPhotoSize}
                    maxFiles={1}
                    disabled={uploadPhotos.isLoading || !permission}
                    isLoading={uploadPhotos.isLoading}
                    isBusinessEditV2
                    errors={fileErrors}
                >
                    <PhotoPreview
                        url={resizeImage(getMappedUrl(currentLogo, business?.media_mapping))}
                        opacity={+displayPreview}
                        data-track="visibility_location__form_photo__logo"
                        data-intercom-target="visibility_location__form_photo__logo"
                    >
                        <PhotoPreviewOverlay disabled={!permission}>
                            {permission && <i className="fa-solid fa-camera" />}
                        </PhotoPreviewOverlay>
                    </PhotoPreview>
                </DropZone>
            </DropZoneContainer>
            {permission && currentLogo && (
                <StrikeThroughButton
                    color={'danger'}
                    onClick={() => setNewLogo('')}
                    text={t('remove_photo')}
                    icon={<i className="fa-solid fa-trash-alt" />}
                />
            )}
            {permission && !!(defaultLogo || currentLogo) && (
                <ActionButton>
                    {!fileErrors.length && (
                        <EditFormStatefulButton
                            isLoading={businessUpdate.isLoading || uploadPhotos.isLoading}
                            hasError={!!(businessUpdate.error || fileErrors.length)}
                            onClick={submitForm}
                            dataTrackId="edit_business__logo_form__save_button"
                            hideIcon
                        />
                    )}
                </ActionButton>
            )}
        </PhotoFormModalContainer>
    );
};

export default LogoForm;
