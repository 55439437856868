import { useContext } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import FunnelModal from 'app/common/components/FunnelModal';
import LoadingView from 'app/common/components/LoadingView';
import NoBusiness from 'app/common/components/placeholder/NoBusiness.container';
import { NoPresenceManagement } from 'app/common/components/placeholder/PresenceManagement/NoPresenceManagement';
import IS_IFRAME from 'app/common/data/iframe';
import { useGetSubscriptions } from 'app/presence/management/hooks/useGetSubscriptions';

import { RouterContext } from 'app/routing/context/RouterContext';
import { NOT_FOUND_PATH, VISIBILITY_DIFFUSION_PATH, VISIBILITY_PATH } from 'app/routing/routeIds';

import { DiffusionList } from './DiffusionList';

export const DiffusionListModal = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { routes } = useContext(RouterContext);
    const { data: subscriptions, isLoading } = useGetSubscriptions();

    const displayDiffusionList = pathname.startsWith(VISIBILITY_DIFFUSION_PATH);

    const getFirstValidPath = () => {
        const validPath = routes.find(
            route => !route.startsWith(VISIBILITY_DIFFUSION_PATH) && route !== NOT_FOUND_PATH,
        );
        return validPath ?? VISIBILITY_PATH;
    };
    const handleRedirect = () => {
        navigate(getFirstValidPath());
    };

    if (isLoading) {
        return <LoadingView />;
    }

    if (!subscriptions?.has_business) {
        return <NoBusiness />;
    }

    if (!subscriptions?.subscribed_to_pm) {
        return <NoPresenceManagement />;
    }

    return (
        <FunnelModal
            isOpen={displayDiffusionList}
            onClose={IS_IFRAME ? () => undefined : handleRedirect}
        >
            <DiffusionList />
        </FunnelModal>
    );
};
