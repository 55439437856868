import { css } from '@emotion/react';
import styled from '@emotion/styled';

type Props = {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
};
export const MWrapper = styled.div`
    margin: 0;
    ${(props: Props) =>
        props.top &&
        css`
            margin-top: ${props.top};
        `};
    ${(props: Props) =>
        props.right &&
        css`
            margin-right: ${props.right};
        `};
    ${(props: Props) =>
        props.bottom &&
        css`
            margin-bottom: ${props.bottom};
        `};
    ${(props: Props) =>
        props.left &&
        css`
            margin-left: ${props.left};
        `};
`;
