import { KeyboardEvent, useState } from 'react';

import { Button, TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { KeyCode } from 'app/common/types/keyboard';
import { useGroupContext } from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupContext';
import useGroupData from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupData';
import useGroupUpdate from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupRename';
import useGroups from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroups';
import useIsSubgroup from 'app/pages/visibility/BusinessListV2/hooks/groups/useIsSubgroup';

import { ModalButtons, ModalContainer, ModalSubtitle, ModalTitle } from './RenameGroupModal.styled';

type Props = {
    closeModal: () => void;
};

const RenameGroupModal = ({ closeModal }: Props) => {
    const { t } = useTranslation();
    const groupData = useGroupData();
    const isSubgroup = useIsSubgroup();
    const { groups } = useGroups();
    const { renameGroup } = useGroupContext();

    const [name, setName] = useState<string>(groupData?.groupName ?? '');

    const groupUpdate = useGroupUpdate(groupData?.groupId || -1, () => {
        renameGroup(groups, name);
        closeModal();
    });

    const errorMessage = groupUpdate.error?.response?.data?.errors?.json;

    const onSubmit = () => {
        if (!groupUpdate.isLoading) {
            groupUpdate.mutate({ name });
        }
    };

    const handlePressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyCode.Enter) {
            onSubmit();
        }
    };

    return (
        <ModalContainer>
            <ModalTitle>{t(isSubgroup ? 'rename_subgroup' : 'rename_group')}</ModalTitle>
            <ModalSubtitle>
                {t(
                    isSubgroup
                        ? 'create_subgroup_modal_explanation'
                        : 'create_group_modal_explanation',
                )}
            </ModalSubtitle>
            <TextInput
                dataTrackId={isSubgroup ? 'name_of_the_subgroup' : 'name_of_the_group'}
                value={name}
                label={t(isSubgroup ? 'name_of_the_subgroup' : 'name_of_the_group')}
                onChange={name => setName(name ?? '')}
                onKeyDown={handlePressEnter}
                error={!!errorMessage}
                notice={!!errorMessage && t(errorMessage)}
            />
            <ModalButtons>
                <StrikeThroughButton text={t('cancel')} color={'secondary'} onClick={closeModal} />
                <Button
                    dataTrackId="rename_group__button"
                    variant="primary"
                    shape="cube"
                    full
                    onClick={onSubmit}
                    className="margin_left--simple"
                    disabled={!name}
                >
                    {t('apply')}
                </Button>
            </ModalButtons>
        </ModalContainer>
    );
};

export default RenameGroupModal;
