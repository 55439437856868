import styled from '@emotion/styled';

import { BUSINESS_LIST_BREAKPOINT } from 'app/pages/visibility/BusinessListV2/BusinessList.styled';

export const BusinessAdviceSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;
    gap: 12px;

    @media (${BUSINESS_LIST_BREAKPOINT}) {
        flex-direction: row;
        gap: 24px;
    }
`;

export const BusinessBadges = styled.div`
    display: flex;
    gap: 12px;
`;
