import React from 'react';

import ChangePasswordModal from './ChangePasswordModal';
import LoginForm from './LoginForm.container';
import { LoginFormContainer } from './LoginForms.styled';
import LoginLoadingView from './LoginLoadingView';

type Props = {
    isSubmitting: boolean;
    showUnsafePasswordModal: boolean;
};

const LoginView = ({ isSubmitting, showUnsafePasswordModal }: Props) => (
    <LoginFormContainer>
        {isSubmitting ? <LoginLoadingView /> : <LoginForm />}

        <ChangePasswordModal show={showUnsafePasswordModal} />
    </LoginFormContainer>
);

export default LoginView;
