import styled from '@emotion/styled';

export const ContentSectionContainer = styled.div``;

export const ChartCardsContainer = styled.div`
    margin-top: 16px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    min-height: 322px;
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(1, 1fr);
        > div {
            min-height: 322px;
        }
    }
`;

export const ChartGraphContainer = styled.div`
    height: 410px;
    > div {
        margin-top: 16px;
        min-height: 375px;
    }
`;
