import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

export const HolidayItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px ${({ theme }) => theme.colors.default.alpha};
    border-radius: 8px;
    padding: 0 16px;
    height: 52px;
    transition: all ease 0.2s;

    &:hover {
        border-color: ${({ theme }) => theme.colors.default.initial};
        box-shadow: 0 5px 10px 0 rgba(20, 37, 66, 0.16);
        cursor: pointer;
    }
`;

export const HolidayItemLeft = styled.div`
    overflow: hidden;
    white-space: nowrap;
`;

export const HolidayName = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const HolidayDate = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const HolidayIcon = styled.div`
    margin-left: 12px;
    color: ${({ theme }) => theme.colors.primary.initial};
`;
