import { KeywordType } from 'app/api/types/competitiveBenchmark';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { useGetKeywordsCompetitive } from './useGetKeywordsCompetitive';

export const useGetKeywordData = (): { keyword: KeywordType | null; isLoading: boolean } => {
    const [keyword] = useStateQueryParams('keyword');
    const { availableKeywords, isLoading } = useGetKeywordsCompetitive();

    if (isLoading) {
        return { keyword: null, isLoading };
    }

    let foundKeyword = availableKeywords.find(k => k.keyword === keyword);

    if (!foundKeyword && availableKeywords.length > 0) {
        foundKeyword = availableKeywords[0];
    }

    return { keyword: foundKeyword || null, isLoading };
};
