import { useTranslation } from 'react-i18next';

import PartnerConnections from 'app/businessConnection/components/PartnerConnections';
import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

export const PartnerConnectionsPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('partner_connections')} />
            <PartnerConnections />
        </>
    );
};
