import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import {
    FULL_HEIGHT_SECTION,
    FULL_SECTION_PADDING,
} from 'app/businessEditV2/components/EditSectionFullHeight/EditSectionFullHeight.styled';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

export const MARGIN = 8;
export const PHOTO_WIDTH = (FULL_HEIGHT_SECTION - 2 * FULL_SECTION_PADDING - MARGIN) / 2;

export const PhotosPreviewContainer = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${PHOTO_WIDTH}px, 1fr));
    grid-gap: ${MARGIN}px ${MARGIN}px;
`;

type PhotoProps = {
    url: string;
    content?: string;
};

export const Photo = styled.div<PhotoProps>`
    position: relative;
    width: ${PHOTO_WIDTH}px;
    height: ${PHOTO_WIDTH}px;
    border: solid 1px ${DISABLED_COLOR};
    border-radius: 4px;
    background-image: url('${props => props.url}');
    background-position: center center;
    background-size: cover;
    background-color: ${greyColorObject.light};
    background-repeat: no-repeat;

    ${({ content, theme }) =>
        content &&
        css`
            &:after {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;

                left: -1px;
                top: -1px;
                width: ${PHOTO_WIDTH}px;
                height: ${PHOTO_WIDTH}px;

                border: inherit;
                background-color: ${theme.colors.overlay};
                color: white;
                font-weight: bold;
                font-size: 32px;
                border-radius: 4px;

                content: '${content}';
            }
        `};
`;

export const AddPhotosTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: ${HEX_COLORS.blackberry};
`;

export const AddPhotosSubtitle = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    transition: color ease-in-out 0.3s;
`;

type NoPhotosPreviewProps = {
    sectionColor: ColorState;
};

export const AddPhotos = styled.div<NoPhotosPreviewProps>`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    width: ${PHOTO_WIDTH}px;
    height: ${PHOTO_WIDTH}px;
    border-radius: 4px;

    color: ${({ theme }) => theme.colors.primary.initial};
    font-size: 22px;

    border: dashed 1px ${({ theme }) => theme.colors.secondary.initial};
    background-color: ${greyColorObject.light};

    ${({ sectionColor, theme }) =>
        [ColorState.PRECHECK_ERRORS, ColorState.MISSING_WARNING].includes(sectionColor) &&
        css`
            background-color: rgba(215, 219, 223, 0.12);
            border-color: ${theme.colors.warning.initial};
        `};
`;

export const PhotosPreviewContainerNoPhotos = styled.div<NoPhotosPreviewProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    border-radius: 4px;

    color: ${({ theme }) => theme.colors.primary.initial};
    font-size: 22px;
    transition: all ease-in-out 0.3s;

    border: dashed 1px ${({ theme }) => theme.colors.secondary.initial};
    background-color: ${greyColorObject.light};

    &:hover {
        border-color: ${HEX_COLORS.blackberry};
        cursor: pointer;
    }

    ${({ sectionColor, theme }) =>
        [ColorState.PRECHECK_ERRORS, ColorState.MISSING_WARNING].includes(sectionColor) &&
        css`
            background-color: rgba(215, 219, 223, 0.12);
            border-color: ${theme.colors.warning.initial};

            &:hover {
                border-color: ${theme.colors.warning.initial};
            }

            svg {
                * {
                    fill: ${theme.colors.warning.initial};
                }
            }
        `};

    ${({ sectionColor, theme }) =>
        [ColorState.DISABLED, ColorState.CLOSED].includes(sectionColor) &&
        css`
            background-color: rgba(215, 219, 223, 0.12);
            border-color: ${theme.colors.secondary.alpha};

            * {
                color: ${theme.colors.secondary.alpha} !important;
            }

            &:hover {
                border-color: ${theme.colors.secondary.alpha};
            }

            svg {
                * {
                    fill: ${theme.colors.secondary.alpha};
                }
            }
        `};
`;

export const AddPhotosContent = styled.div`
    position: relative;
    margin-top: 13px;
    margin-right: -6px;
`;
