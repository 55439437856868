import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls/camel';
import { MESSAGING_SETTINGS_STATS } from 'app/common/data/queryKeysConstants';

export const useMessagingSettingsStats = () => {
    const { data, isLoading } = useQuery(
        MESSAGING_SETTINGS_STATS,
        api.messagingSettings.messagingSettingsStats,
    );
    return [
        data?.settingsCount,
        data?.displayActivateMessagingBanner,
        isLoading,
    ];
};
