import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { SUBSCRIPTIONS_STATS } from 'app/common/data/queryKeysConstants';

export const useGetSubscriptions = () => {
    const { data, ...rest } = useQuery([SUBSCRIPTIONS_STATS], () =>
        api.business.getSubscriptions({}),
    );

    return {
        data,
        ...rest,
    };
};
