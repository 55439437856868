import styled from '@emotion/styled';

const AccountSize = 40;

export const AccountContainer = styled.div`
    position: relative;
    margin-right: 2px;
`;

export const AccountBadgeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${AccountSize + 12}px;
    height: ${AccountSize + 8}px;
`;

export const AccountBadge = styled.img`
    border-radius: 50%;
    width: ${AccountSize}px;
    height: ${AccountSize}px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AccountBadgeEmpty = styled.div`
    border-radius: 50%;
    width: ${AccountSize}px;
    height: ${AccountSize}px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    font-size: 20px;
    background-color: ${({ theme }) => theme.colors.primary.initial};
    color: white;
`;

export const AccountBadgeImgContainer = styled.div`
    background-color: white;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    font-size: 8px;
    font-weight: 500;
    position: absolute;
    right: 1px;
    bottom: 1px;
    z-index: 2;
`;

export const AccountBadgeImg = styled.img`
    border-radius: 50%;
    width: ${(props: { publisher: string }) => (props.publisher === 'google' ? '28px' : '27px')};
    height: ${(props: { publisher: string }) => (props.publisher === 'google' ? '28px' : '27px')};
    display: flex;
    align-items: center;
    justify-content: center;
`;
