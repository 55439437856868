import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

const mapStatusToColor = {
    to_configure: HEX_COLORS.warning,
    pending: HEX_COLORS.primary,
    scheduled: HEX_COLORS.primary,
    error: HEX_COLORS.danger,
};

type PartnerStatusIconType = {
    status?: string;
    onClick?: () => void;
};

export const PartnerStatusContainer = styled.div`
    display: flex;
    gap: 6px;
    flex-direction: row;
    align-items: center;
    flex-basis: 50%;
    justify-content: center;
    img.disabled {
        filter: grayscale(1);
        opacity: 0.25;
    }
`;

export const PartnerStatusIconContainer = styled.div`
    gap: 3px;
    display: flex;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const PartnerStatusIcon = styled.i<PartnerStatusIconType>`
    display: flex;
    margin: auto 0px;
    font-size: 11px;
    ${({ status, onClick, theme }) => css`
        ${(status === 'to_configure' || status === 'error' || status === 'scheduled') &&
        css`
            background-color: ${HEX_COLORS.white};
            color: ${mapStatusToColor[status]};
            border-radius: 10px;
        `};
        ${status === 'pending' &&
        css`
            font-size: 7px;
            background-color: ${mapStatusToColor[status]};
            color: ${HEX_COLORS.white};
            border-radius: 8px;
            padding-right: 1px;
            height: 14px;
            width: 14px;
            align-items: center;
            justify-content: center;
        `};
        ${status === 'expired' &&
        css`
            filter: grayscale(100%);
        `};
        ${status === 'success' &&
        css`
            color: ${theme.colors.success.initial};
        `};
        ${!!onClick &&
        css`
            cursor: pointer;
        `}
    `};
`;

export const PartnerStatusCount = styled.div`
    font-size: 11px;
    font-weight: bold;
`;
