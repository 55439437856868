import { Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { PublisherState } from '../../../../../../../presence/management/types/Publishers.types';
import { getColor } from '../../../../../../../presence/management/utils/publisher';
import { getStatusObject } from '../helpers/helpers';
import { PublisherIcon } from '../PublisherIcon/PublisherIcon';

type Props = {
    isIndirect: boolean;
    status: PublisherState;
    count?: number;
    hideStatus?: boolean;
};

export const Status = ({ status, count, isIndirect = false, hideStatus = false }: Props) => {
    const { t } = useTranslation();
    let formattedStatus = status;

    if (status === 'success') {
        formattedStatus = isIndirect ? 'transmitted' : 'updated';
    }

    return (
        <Stack direction="row" alignItems="center">
            {status !== 'unavailable' && !hideStatus && (
                <PublisherIcon {...getStatusObject(status)} />
            )}
            <Text variant="bodyMBold" as="span" color={getColor(status)}>
                {typeof count === 'number' ? count : t(formattedStatus)}
            </Text>
        </Stack>
    );
};
