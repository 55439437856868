import { useTranslation } from 'react-i18next';

import { Navigate } from 'react-router-dom';

import { RoleValue, V2UserData } from 'app/api/types/user';
import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import NotAccessibleForAdmin from 'app/common/components/placeholder/NotAccessibleForAdmin';
import { REVIEW_MANAGEMENT } from 'app/common/data/productIds';
import { ADMIN } from 'app/common/data/roles';
import { ReviewAnalytics } from 'app/pages/analytics/ReviewAnalytics/ReviewAnalytics';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

type Props = {
    meRole: RoleValue | null;
    userData: V2UserData | null;
};

export const ReviewAnalyticsPage = ({ meRole, userData }: Props) => {
    const { t } = useTranslation();

    const hasRmAccess = !!userData?.sidebar_products.includes(REVIEW_MANAGEMENT.toLowerCase());

    return (
        <>
            <DynamicPageHeader title={t('analytics')} />
            {hasRmAccess && meRole !== ADMIN && <ReviewAnalytics />}
            {hasRmAccess && meRole === ADMIN && <NotAccessibleForAdmin />}
            {!hasRmAccess && <Navigate replace to={NOT_FOUND_PATH} />}
        </>
    );
};
