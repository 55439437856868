import { TFunction } from 'i18next';
import { UseQueryOptions, useQuery } from 'react-query';

import { Choice, V2UsersAvailableRoles } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import { AVAILABLE_ROLES } from 'app/common/data/queryKeysConstants';

export default function useAvailableRoles(
    options: UseQueryOptions<V2UsersAvailableRoles> = {},
) {
    return useQuery(AVAILABLE_ROLES, api.user.getAvailableRoles, options);
}

export function formatRoleToChoice(role: string, t: TFunction): Choice {
    return {
        value: role,
        label: t(role) + ' - ' + t(role + '_explanation'),
    };
}
