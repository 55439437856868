import {
    CLIENT_COLOR,
    COMPETITIVE_COLORS,
    DEFAULT_COLOR,
} from 'app/pages/analytics/CompetitiveBenchmark/components/constant/competitors_colors';

export const getCompetitorColors = (competitorNames: string[], clientOrgName: string) => {
    const colorsMap = competitorNames
        .sort((a: string, b: string) => a.localeCompare(b))
        .reduce(
            (acc, name, index) => {
                acc[name] =
                    name === clientOrgName
                        ? CLIENT_COLOR
                        : COMPETITIVE_COLORS[index % COMPETITIVE_COLORS.length];
                if (index >= COMPETITIVE_COLORS.length) {
                    acc[name] = DEFAULT_COLOR;
                }
                return acc;
            },
            {} as Record<string, string>,
        );

    return colorsMap;
};
