import { useMemo } from 'react';

import { format, parse } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { UseQueryOptions, useQuery } from 'react-query';

import {
    PaginatedMetricsResponse,
    ReviewAnalyticsDimension,
    ReviewAnalyticsFiltersType,
    ReviewAnalyticsMetric,
} from 'app/api/types/review_analytics';
import api from 'app/api/v2/api_calls';
import { REVIEW_ANALYTICS_METRICS } from 'app/common/data/queryKeysConstants';

import { useDateDimension } from './useDateDimension';
import { useInternalRankingDimension } from './useInternalRankingDimension';
import { useInternalRankingSection } from './useInternalRankingSection';
import { usePreviousPeriodFilters, useReviewAnalyticsFilters } from './useReviewAnalyticsFilters';

const useMetrics = <T extends ReviewAnalyticsDimension, U extends readonly ReviewAnalyticsMetric[]>(
    dimensions: T,
    metrics: U,
    filters: ReviewAnalyticsFiltersType,
    options?: UseQueryOptions<PaginatedMetricsResponse<T, U>>,
) => {
    const { data } = useQuery<PaginatedMetricsResponse<T, U>>(
        [REVIEW_ANALYTICS_METRICS, dimensions, metrics, filters],
        () => api.reviewAnalytics.getMetrics<T, U>(dimensions, metrics, filters),
        {
            keepPreviousData: true,
            ...options,
        },
    );
    return data;
};

export const useReviewTotalMetrics = () => {
    const filters = useReviewAnalyticsFilters();
    const dimensions = null;
    const metrics = ['average_rating', 'rating_distribution', 'reply_time'] as const;

    return useMetrics(dimensions, metrics, filters);
};

export const useReviewTagMetrics = () => {
    const filters = useReviewAnalyticsFilters();
    const dimensions = 'tag';
    const metrics = ['average_rating', 'rating_distribution'] as const;

    return useMetrics(dimensions, metrics, filters);
};

export const useReviewMetricsByDate = () => {
    const filters = useReviewAnalyticsFilters();
    const previousMetricsFilters = usePreviousPeriodFilters();

    const dimensions = useDateDimension();
    const metrics = ['rating_distribution', 'reply_time', 'reply_means', 'average_rating'] as const;

    const metricsResult = useMetrics(dimensions, metrics, filters);
    const previousMetricsResult = useMetrics(dimensions, metrics, previousMetricsFilters, {
        keepPreviousData: false,
        enabled: !isEmpty(previousMetricsFilters),
    });

    const formatMetricsResultData = (result: NonNullable<typeof metricsResult>) => {
        if (dimensions === 'day') {
            return result.data.map(data => ({
                ...data,
                dimension: format(parse(data.dimension, 'yyyy-MM-dd', new Date()), 'P'),
            }));
        }
        if (dimensions === 'month') {
            return result.data.map(data => ({
                ...data,
                dimension: format(parse(data.dimension, 'yyyy-MM', new Date()), 'MMM yyyy'),
            }));
        }

        return result.data;
    };

    const formattedData = useMemo(() => {
        if (!metricsResult) return [];
        return formatMetricsResultData(metricsResult);
    }, [metricsResult]);

    const formattedPreviousData = useMemo(() => {
        if (!previousMetricsResult) return undefined;
        return formatMetricsResultData(previousMetricsResult);
    }, [previousMetricsResult]);

    return metricsResult
        ? {
              ...metricsResult,
              data: formattedData,
              previousData: formattedPreviousData,
          }
        : undefined;
};

export const useEReputationInternalRanking = () => {
    const filters = useReviewAnalyticsFilters();
    const [section] = useInternalRankingSection();

    const dimensions = section ? 'group' : 'business';
    const metrics = ['average_rating', 'rating_distribution'] as const;

    return useMetrics(dimensions, metrics, filters);
};

export const useReplyMeansInternalRanking = () => {
    const filters = useReviewAnalyticsFilters();
    const dimensions = useInternalRankingDimension();
    const metrics = ['reply_means'] as const;

    return useMetrics(dimensions, metrics, filters);
};

export const useReplyTimeInternalRanking = () => {
    const filters = useReviewAnalyticsFilters();
    const dimensions = useInternalRankingDimension();
    const metrics = ['reply_time'] as const;

    return useMetrics(dimensions, metrics, filters);
};
