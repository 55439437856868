import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const ImageUploaderRowWrapper = styled(Stack)`
    border-radius: ${({ theme }) => theme.radius.large};
    box-shadow: ${({ theme }) => theme.shadows.light};
`;

export const ImageUploaderRowHeaderRight = styled(Stack)`
    flex-grow: 1;
    max-width: calc(100% - 56px);
`;

export const TextWrapper = styled.div<{ collapsable: boolean }>`
    max-width: ${({ collapsable }) => (collapsable ? 'calc(100% - 250px)' : 'calc(100% - 56px)')};
`;

export const ClickableRow = styled(Stack)`
    cursor: pointer;
    padding: 16px;
`;

export const ImageUploaderBodyWrapper = styled(Stack)`
    padding: 0px 16px 16px 16px;
`;
