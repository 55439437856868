import { useDateFilter } from './useDateFilter';
import { usePeriodFilter } from './usePeriodFilter';

export const useComparisonDateFilter = (): [Date, Date, number] => {
    const [date] = useDateFilter();
    const [period] = usePeriodFilter();

    let monthDelta = 1;

    if (period === 'semester') {
        monthDelta = 6;
    } else if (period === 'year') {
        monthDelta = 12;
    }

    const previousDate = new Date(date);
    previousDate.setMonth(date.getMonth() - monthDelta);

    return [date, previousDate, monthDelta];
};
