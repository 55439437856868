import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const DropZoneLoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const dropZoneLoadingAnimation = keyframes`
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
`;

type DropZoneLoadingSpinnerProps = {
    size?: number;
};

export const DropZoneLoadingSpinner = styled.div<DropZoneLoadingSpinnerProps>`
    width: ${({ size }) => size ?? 80}px;
    height: ${({ size }) => size ?? 80}px;
    margin: 0;
    background-color: ${({ theme }) => theme.colors.primary.initial};
    border-radius: 100%;
    -webkit-animation: ${dropZoneLoadingAnimation} 1s infinite ease-in-out;
    animation: ${dropZoneLoadingAnimation} 1s infinite ease-in-out;
`;
