import { useEffect, useMemo, useState } from 'react';

import { Loader } from '@partoohub/ui';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    CompetitiveBenchmarkContainer,
    LoaderContainer,
} from 'app/pages/analytics/CompetitiveBenchmark/CompetitiveBenchmark.styled';
import { FiltersSection, HeaderSection } from 'app/pages/analytics/CompetitiveBenchmark/components';
import { CompetitiveBenchmarkSection } from 'app/pages/analytics/CompetitiveBenchmark/components/CompetitiveBenchmarkSection/CompetitiveBenchmarkSection';
import { useGetKeywordsCompetitive } from 'app/pages/analytics/CompetitiveBenchmark/hooks/useGetKeywordsCompetitive';

import { NotFoundPlaceholder } from './components/NotFound/NotFoundDataPlaceholder';
import { AnalyticsTabs } from '../VisibilityAnalyticsV2/components/AnalyticsTabs';

const periods: Record<string, number> = { month: 1, semester: 6, year: 12 };

export const CompetitiveBenchmark = () => {
    const { available_keywords, isLoading } = useGetKeywordsCompetitive();
    const { business__in } = useBusinessModalFilters();

    const [currentKeyword] = useStateQueryParams('keyword');
    const [period] = useStateQueryParams('period');
    const currentCompetitiveKeyword = useMemo(
        () =>
            available_keywords.find(keyword => keyword.keyword === currentKeyword) ??
            available_keywords[0],
        [currentKeyword, available_keywords],
    );
    const [currentDate, setCurrentDate] = useState<Date>(new Date());

    const currentComparisonPeriod = useMemo(() => {
        const currentMonth = currentDate.getMonth();
        const tempDate = new Date(currentDate);

        if (periods[period]) {
            tempDate.setMonth(currentMonth - periods[period]);
        }

        return { date: tempDate, period };
    }, [period]);

    useEffect(() => {
        if (available_keywords.length) {
            setCurrentDate(available_keywords[0].dates[available_keywords[0].dates.length - 1]);
        }
    }, [available_keywords]);

    if (isLoading) {
        return (
            <LoaderContainer>
                <Loader size="medium" />
            </LoaderContainer>
        );
    }

    if (!available_keywords[0]) {
        return (
            <NotFoundPlaceholder
                title={'competitive_benchmark_no_results_title'}
                subtitle={'competitive_benchmark_no_keywords_text'}
            />
        );
    }

    if (available_keywords[0].dates.length === 0) {
        return (
            <NotFoundPlaceholder
                title={'competitive_benchmark_no_results_title'}
                subtitle={'competitive_benchmark_no_results_text'}
            />
        );
    }

    return (
        <CompetitiveBenchmarkContainer>
            <HeaderSection />
            <AnalyticsTabs />
            <FiltersSection
                allAvailableKeywords={available_keywords}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
            />
            <CompetitiveBenchmarkSection
                keyword={currentCompetitiveKeyword}
                currentDate={currentDate}
                comparisonDate={currentComparisonPeriod.date}
                businessIds={business__in?.split(',') || []}
            />
        </CompetitiveBenchmarkContainer>
    );
};
