import { APP_PRODUCTS, PRESENCE_MANAGEMENT } from 'app/common/data/productIds';
import { ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useIsAvailableStatPresenceItem = (): boolean => {
    const { data: me } = useMe();
    const isAvailable = me?.sidebar_products.includes(APP_PRODUCTS[PRESENCE_MANAGEMENT]) ?? false;

    return isAvailable && me?.role !== ADMIN;
};
