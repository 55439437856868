import { FontAwesomeIconsPartooUsed, IconElement, IconPrefix } from '@partoohub/ui';

import { PublisherStateColors } from 'app/presence/management/types/Publishers.types';

export type StatusPublisher = {
    color: PublisherStateColors;
    icon: IconElement;
    className?: string;
};

export const getStatusObject = (status: string): StatusPublisher => {
    if (status === 'pending') {
        return {
            color: 'primary',
            icon: [FontAwesomeIconsPartooUsed.faArrowsRotate, IconPrefix.SOLID],
            className: 'fa-spin',
        };
    }
    if (status === 'to_configure') {
        return {
            color: 'warning',
            icon: [FontAwesomeIconsPartooUsed.faCircleExclamation, IconPrefix.SOLID],
        };
    }
    return {
        color: 'success',
        icon: [FontAwesomeIconsPartooUsed.faCircleCheck, IconPrefix.SOLID],
    };
};
