import env from 'app/common/services/getEnvironment';
import { PushNotifsEvent } from 'app/common/services/pushNotifications/events';
import { NotificationPermissionType } from 'app/common/services/pushNotifications/permissionTypes';

/**
 * Defines clevertap object needed on window.clevertap
 */

interface CleverTapSdkState {
    event: any;
    profile: Array<Record<string, any>>;
    account: Array<Record<string, any>>;
    onUserLogin: Array<Record<string, any>>;
    notifications: Array<Record<string, any>>;
    privacy: Array<Record<string, any>>;
}

/**
 * Defines User properties that are pushed on a Clevertap profile
 */
export interface PushNotificationsProfileInfo {
    firstName: string;
    lastName: string;
    userId: string;
    lang: string;
    email: string;
    role: string;
    createdAt: string;
    groupId: number;
    orgId: number;
    organizationType: string;
    orgName: string;
    orgProvider: string;
    apiCustomer: boolean;
    orgPmSubscribed: boolean;
    orgRmSubscribed: boolean;
    orgRbSubscribed: boolean;
    orgBmSubscribed: boolean;
    lastOrgApiCall: string;
}

/**
 * Additional authorizations sent to a profile
 */
interface PushNotificationsProfileAuthorizations {
    'MSG-email': boolean; // Email notifications
    'MSG-push': boolean; // Web push notifications
    'MSG-sms': boolean; // Sms notifications
    'MSG-whatsapp': boolean; // WhatsApp notifications
}

/**
 * Sets clevertap object on window as read only
 * in order to prevent the use of window.clevertap
 * for the use of PushNotificationsWrapper
 */
declare global {
    interface Window {
        readonly clevertap: CleverTapSdkState;
        fbAsyncInit: any;
    }
}

/**
 * The Wrapper around Clevertap for Push Notifications
 * Singleton instance
 */
export class PushNotificationsWrapper {
    private static instance: PushNotificationsWrapper;

    /**
     * Authorizations are not meant to change for now
     */
    private static authorizations: PushNotificationsProfileAuthorizations = {
        'MSG-email': true, // Enable email notifications
        'MSG-push': true, // Enable push notifications
        'MSG-sms': false, // Disable sms no tifications
        'MSG-whatsapp': false, // Disable WhatsApp notifications
    };

    /**
     * Initializes the clevertap state that will be
     * referenced on window.clevertap
     */
    private clevertapSdkState: CleverTapSdkState = {
        event: [],
        profile: [],
        account: [],
        onUserLogin: [],
        notifications: [],
        privacy: [],
    };

    /**
     * Initializes the needed values to communicate with clevertap and
     * loads the async clevertap script.
     * The constructor is private to respect the Singleton pattern
     */
    private constructor() {
        (window as any).clevertap = this.clevertapSdkState;
        this.clevertapSdkState.account.push({
            id: PushNotificationsWrapper.getCleverTapClientID(),
        });
        this.clevertapSdkState.privacy.push({ optOut: false });
        this.clevertapSdkState.privacy.push({ useIP: false });
        PushNotificationsWrapper.loadCleverTapScript();
    }

    /**
     * Gets client ID of CleverTap project based on environment
     * @TODO: Use process.env instead of this
     */
    private static getCleverTapClientID() {
        switch (env.getEnv()) {
            case 'prod':
                return '449-685-9K6Z';
            case 'preprod':
                return 'TEST-R7W-597-K46Z';
            default:
                return 'TEST-549-685-9K6Z';
        }
    }

    /**
     * Loads clevertap script in the DOM
     */
    private static async loadCleverTapScript() {
        const wzrk = document.createElement('script');
        wzrk.type = 'text/javascript';
        wzrk.async = true;
        wzrk.onload = () => {
            PushNotificationsWrapper.getInstance().clevertapSdkState =
                window.clevertap;
        };
        wzrk.src = `${
            document.location.protocol === 'https:'
                ? 'https://d2r1yp2w7bby2u.cloudfront.net'
                : 'http://static.clevertap.com'
        }/js/clevertap.min.js?v=0`;
        const s = document.getElementsByTagName('script')[0];
        s?.parentNode?.insertBefore(wzrk, s);
    }

    /**
     * Gets the singleton instance or creates it if not exist.
     */
    public static getInstance(): PushNotificationsWrapper {
        if (!PushNotificationsWrapper.instance) {
            PushNotificationsWrapper.instance = new PushNotificationsWrapper();
        }

        return PushNotificationsWrapper.instance;
    }

    /**
     * Sends an event to the Pushs Notifications provider
     */
    public async sendEvent(
        event: PushNotifsEvent,
        properties?: { [key: string]: any },
    ) {
        this.clevertapSdkState.event.push(event, properties ?? {});
    }

    /**
     * Creates or updates a user profile
     */
    public updateProfile(
        userProfileInfo: Partial<PushNotificationsProfileInfo>,
    ) {
        /**
         * Mapping for right namings on CleverTap interface
         */
        this.clevertapSdkState.onUserLogin.push({
            Site: {
                ...PushNotificationsWrapper.authorizations,
                Name: `${userProfileInfo.firstName} ${userProfileInfo.lastName}`,
                Email: userProfileInfo.email,
                Identity: userProfileInfo.userId,
                'First Name': userProfileInfo.firstName,
                'Last Name': userProfileInfo.lastName,
                Language: userProfileInfo.lang,
                Role: userProfileInfo.role,
                'Create Time': userProfileInfo.createdAt,
                'Group Id': userProfileInfo.groupId,
                'Org Id': userProfileInfo.orgId,
                'Org type': userProfileInfo.organizationType,
                'Org name': userProfileInfo.orgName,
                'Org provider': userProfileInfo.orgProvider,
                'Api customer': userProfileInfo.apiCustomer,
                'Org PM subscribed': userProfileInfo.orgPmSubscribed,
                'Org RM subscribed': userProfileInfo.orgRmSubscribed,
                'Org RB subscribed': userProfileInfo.orgRbSubscribed,
                'Org BM subscribed': userProfileInfo.orgBmSubscribed,
                'Last org API call': userProfileInfo.lastOrgApiCall,
            },
        });
    }

    /**
     * Calls CleverTap SDK to show browser prompt for notifications.
     */
    public showOptInPrompt() {
        this.clevertapSdkState.notifications.push({
            serviceWorkerPath: '/sw.js',
            skipDialog: true,
        });
    }

    public static getOptIn() {
        if (!('Notification' in window)) {
            // This browser does not support notifications.
            return NotificationPermissionType.UNDEFINED;
        }
        switch (Notification.permission) {
            case 'denied':
                // The user has previously denied notifications.
                return NotificationPermissionType.DENIED;
            case 'granted':
                // The user has previously granted notifications.
                return NotificationPermissionType.GRANTED;
            default:
                // The user has not yet responded to the notification permission prompt.
                return NotificationPermissionType.DEFAULT;
        }
    }
}
