import React, { FC, ReactNode, useContext, useMemo } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import {
    FeatureFlag,
    FeatureFlags,
    useFeatureFlagsValues,
} from '../data/featureFlags';

export const FeatureFlagsContext = React.createContext(undefined as any);

interface Props {
    children: ReactNode;
    search: Record<string, string | null | (string | null)[]>;
    values: FeatureFlags;
}

export const FeatureFlagsProvider: FC<Props> = ({
    children,
    values,
    search,
}) => {
    // whatever the values are, it can be overriden with the url
    const overridenValues = useMemo(() => {
        const v = { ...values };
        for (const flagName of Object.keys(v)) {
            if (
                typeof search[flagName] === 'string' &&
                ['true', 'false'].includes(search[flagName] as string)
            ) {
                v[flagName] = search[flagName] === 'true';
            }
        }
        return v;
    }, [search, values]);

    return (
        <FeatureFlagsContext.Provider value={overridenValues}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};

interface AppFeatureFlagsProviderProps {
    children?: ReactNode;
}

export const AppFeatureFlagsProvider: FC<AppFeatureFlagsProviderProps> = ({
    children,
}) => {
    const values = useFeatureFlagsValues();
    const location = useLocation();
    const search = qs.parse(location.search);

    return (
        <FeatureFlagsProvider search={search} values={values}>
            {children}
        </FeatureFlagsProvider>
    );
};

AppFeatureFlagsProvider.displayName = 'FeatureFlags';

export const useFeatureFlag = (featureFlag: FeatureFlag): boolean => {
    const flagsState = useContext(FeatureFlagsContext);

    return flagsState[featureFlag];
};
