import { useContext } from 'react';

import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ReportSuggestionConfirmModal, ReportSuggestionText } from './ReportSuggestionModal.styled';
import { ReviewReplyContext, ReviewReplyContextType } from '../CommentTable/CommentForm';

type Props = {
    show?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
};

export default function ReportSuggestionModal({
    show = false,
    onConfirm = () => undefined,
    onCancel = () => undefined,
}: Props) {
    const { t } = useTranslation();
    const { currentSuggestion } = useContext<ReviewReplyContextType>(ReviewReplyContext);

    return (
        <ReportSuggestionConfirmModal
            modalFor="warning"
            title={t('report_suggestion_modal_title')}
            show={show}
            onConfirm={onConfirm}
            onHide={onCancel}
            cancelLabel={t('report_suggestion_modal_cancel')}
            confirmLabel={t('report_suggestion_modal_submit')}
            trackId={'report_suggestion_modal'} // To confirm
        >
            <ReportSuggestionText as="div" color={'secondary'}>
                {currentSuggestion?.content ?? ''}
            </ReportSuggestionText>
            <Text color={'secondary'}>{t('report_suggestion_modal_content')}</Text>
        </ReportSuggestionConfirmModal>
    );
}
