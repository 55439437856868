import { Banner, Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { MetaReloginDialog } from 'app/pages/visibility/subComponents/MetaRelogin/Dialogs';
import {
    MetaReloginContextProps,
    MetaReloginProvider,
    useMetaRelogin,
} from 'app/pages/visibility/subComponents/MetaRelogin/MetaReloginContext';

import { BannerContainer } from './MetaRelogin.styled';

const MetaReloginComponent = () => {
    const { t } = useTranslation();
    const {
        data,
        showModal,
        setShowModal,
        showBanner,
        setShowBanner,
        status,
        setStatus,
        setOAuthAccountId,
    } = useMetaRelogin() as MetaReloginContextProps;

    const handleClose = () => {
        setShowModal(false);
    };

    const handleAction = () => {
        setShowModal(true);
        setStatus('default');
        setOAuthAccountId('');
    };

    if (!showBanner) return null;

    return (
        <>
            {data && Object.keys(data).length > 0 && (
                <BannerContainer>
                    <Banner
                        key="info"
                        actionButtonText={t('meta_relogin_reconnect_now_banner_button')}
                        variant="danger"
                        dataTrackId="meta_relogin_banner"
                        onAction={handleAction}
                        onClose={() => setShowBanner(false)}
                    >
                        {t('meta_relogin_inner_text_banner')}
                    </Banner>
                </BannerContainer>
            )}
            <Modal
                isOpen={showModal}
                closeModal={() => status !== 'loading' && handleClose()}
                animation="fadeIn"
                hideOnClickOutside={status !== 'loading'}
            >
                <MetaReloginDialog />
            </Modal>
        </>
    );
};

export const MetaRelogin = () => (
    <MetaReloginProvider>
        <MetaReloginComponent />
    </MetaReloginProvider>
);
