import { useState } from 'react';

import {
    DynamicFilters,
    FilterCategory,
    FilterName,
    Filters,
    PartialFilters,
} from '@partoohub/modular-components';
import { IconElement } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { SelectableContentFilters } from 'app/common/components/SelectableContentFilters/SelectableContentFilters';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { useHideIntercom } from 'app/hooks/useHideIntercom';
import {
    BusinessListQueryKeys,
    SelectableContentOption,
} from 'app/pages/visibility/BusinessListV2/utils/enums';
import {
    completionOptionValues,
    concatTrueValues,
    createFilterMap,
    productsOptionValues,
    statusOptionValues,
    textNamesDico,
} from 'app/pages/visibility/BusinessListV2/utils/helpers';

const BusinessFiltersButton = () => {
    const { t } = useTranslation();
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [status, setStatus] = useStateQueryParams(BusinessListQueryKeys.STATUS);
    const [products, setProducts] = useStateQueryParams(BusinessListQueryKeys.PRODUCTS);
    const [completions, setCompletions] = useStateQueryParams(
        BusinessListQueryKeys.COMPLETION_RATE,
    );

    const filters = {
        [FilterName.STATUS]: createFilterMap(status),
        [FilterName.SUBSCRIPTIONS]: createFilterMap(products),
        [FilterName.COMPLETION]: createFilterMap(completions),
    };

    const setFilters = (filters: PartialFilters) => {
        setStatus(concatTrueValues(filters, FilterName.STATUS));
        setProducts(concatTrueValues(filters, FilterName.SUBSCRIPTIONS));
        setCompletions(concatTrueValues(filters, FilterName.COMPLETION));
    };

    const createSelectableContentOption = (
        property: BusinessListQueryKeys,
        name: string,
        icon: IconElement,
        text: string,
        value: string,
    ) => ({
        name,
        label: (
            <SelectableContentFilters
                icon={icon}
                text={t(text)}
                maxWidth={property === BusinessListQueryKeys.STATUS ? '130px' : '78px'}
            />
        ),
        value,
    });

    const generateFilterOptions = () => {
        const mapOptions = (
            options: Array<SelectableContentOption>,
            property: BusinessListQueryKeys,
        ) =>
            options.map(option =>
                createSelectableContentOption(
                    property,
                    option.name,
                    option.icon,
                    t(option.text),
                    option.value,
                ),
            );
        const optionsStatus = mapOptions(statusOptionValues, BusinessListQueryKeys.STATUS);
        const optionsCompletionRate = mapOptions(
            completionOptionValues,
            BusinessListQueryKeys.COMPLETION_RATE,
        );
        const optionsSubscriptions = mapOptions(
            productsOptionValues,
            BusinessListQueryKeys.PRODUCTS,
        );

        return {
            optionsStatus,
            optionsCompletionRate,
            optionsSubscriptions,
        };
    };

    const { optionsStatus, optionsSubscriptions, optionsCompletionRate } = generateFilterOptions();

    const schema: Filters = [
        {
            id: BusinessListQueryKeys.STATUS,
            property: FilterName.STATUS,
            sectionName: t(textNamesDico[FilterName.STATUS]),
            filterCategory: FilterCategory.SELECTABLE,
            options: optionsStatus,
        },
        {
            id: BusinessListQueryKeys.PRODUCTS,
            property: FilterName.SUBSCRIPTIONS,
            sectionName: t(textNamesDico[FilterName.SUBSCRIPTIONS]),
            filterCategory: FilterCategory.SELECTABLE,
            options: optionsSubscriptions,
            numberPerLine: 3,
        },
        {
            id: BusinessListQueryKeys.COMPLETION_RATE,
            property: FilterName.COMPLETION,
            sectionName: t(textNamesDico[FilterName.COMPLETION]),
            filterCategory: FilterCategory.SELECTABLE,
            options: optionsCompletionRate,
            numberPerLine: 3,
        },
    ];

    useHideIntercom(isFilterOpen);

    return (
        <DynamicFilters
            schema={schema}
            filters={filters as PartialFilters}
            onApply={setFilters}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            resetText={t('reset')}
            applyText={t('apply')}
            title={t('filters')}
            buttonText={t('filters')}
        />
    );
};

export default BusinessFiltersButton;
