import { PageHeader, PageHeaderTypes } from '@partoohub/modular-components';

import { useAppDataContext } from 'app/common/contexts/appData';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';

import IS_IFRAME from 'app/common/data/iframe';

import { avatarMenuItems } from '../PageHeaderWithAvatar';

const PageSettingsHeader = (commonProps: PageHeaderTypes) => {
    return <PageHeader {...commonProps} />;
};

export const PageSettingsHeaderWithAvatar = (props: PageHeaderTypes) => {
    const { me } = useAppDataContext();
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const displayAvatar = isNewSidebarEnabled && !IS_IFRAME;
    return isNewSidebarEnabled ? (
        <PageSettingsHeader
            displayAvatar={displayAvatar}
            avatarMenuItems={avatarMenuItems}
            user={me}
            {...props}
        />
    ) : (
        <PageSettingsHeader displayAvatar={false} {...props} />
    );
};
