import { FontAwesomeIconsPartooUsed, IconPrefix, LeftElementType, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { UserListQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

export const UserSearch = () => {
    const { t } = useTranslation();
    const [value, setValue] = useStateQueryParams(UserListQueryKeys.QUERY);

    const handleReset = () => {
        setValue('');
    };

    return (
        <TextInput
            dataTrackId="user-search"
            label={t('quick_action__modal__input_text')}
            value={value}
            onChange={setValue}
            leftElement={[FontAwesomeIconsPartooUsed.faSearch, IconPrefix.REGULAR]}
            leftElementType={LeftElementType.Icon}
            isReset
            onReset={handleReset}
        />
    );
};
