import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

type CollapsibleContainerProps = {
    collapsed: boolean;
    maxHeight: number;
};

const animationKeyframe = keyframes`
    from, 0%, 99.9999% {
        overflow: hidden;
    }

    100% {
        overflow: visible;
    }
`;

export const CollapsibleContainer = styled.div<CollapsibleContainerProps>`
    width: 100%;
    overflow: visible;
    max-height: ${props => props.maxHeight + 16}px;
    transition: all 0.2s ease;

    ${props =>
        !props.collapsed &&
        css`
            animation: ${animationKeyframe} 0.2s ease 1;
        `}

    ${props =>
        props.collapsed &&
        css`
            max-height: 0;
            overflow: hidden;
        `}
`;
