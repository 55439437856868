import {
    BusinessCreationErrorsObject,
    ReceivedBusinessCreationErrorJSON,
} from 'app/api/types/business';

const businessCreationErrorsFormatter = (
    businessCreationErrors: ReceivedBusinessCreationErrorJSON,
): BusinessCreationErrorsObject => ({
    name: businessCreationErrors.name,
    zipcode: businessCreationErrors.zipcode,
    addressFull: businessCreationErrors.address_full,
    country: businessCreationErrors.country,
    city: businessCreationErrors.city,
    categories: businessCreationErrors.categories,
    phoneNumber:
        businessCreationErrors.contacts && businessCreationErrors.contacts[0].phone_numbers
            ? businessCreationErrors.contacts[0].phone_numbers[0]
            : undefined,
    code: businessCreationErrors.code,
    openingDate: businessCreationErrors.opening_date,
});

export default businessCreationErrorsFormatter;
