import { HEX_COLORS } from '@partoohub/branding';
import { Chart, ChartType } from '@partoohub/ui';

import produce from 'immer';
import { useTranslation } from 'react-i18next';

import { COLORS_FLOW } from 'app/styles/constants/colors_flow';

import { LINE_CHART_OPTIONS } from './common';
import { useReviewMetricsByDate } from '../../../hooks/useMetrics';

const AVERAGE_RATING_OPTIONS = produce(LINE_CHART_OPTIONS, options => {
    options.scales!['y']!.max = 5;
    options.plugins!.tooltip!.callbacks!.label = ({ datasetIndex, label, raw }) => {
        const labels = label.split(',');
        return `${labels[datasetIndex]}: ${(raw as number).toFixed(2)}`;
    };
});

export const AverageRatingGraph = () => {
    const { t } = useTranslation();
    const metrics = useReviewMetricsByDate();

    if (!metrics) return undefined;

    const { data, previousData } = metrics;

    const datasets = [
        {
            backgroundColor: HEX_COLORS.primary,
            borderColor: HEX_COLORS.primary,
            data: data.map(d => d.metrics.average_rating),
            label: t('review_analytics_graphs_selected_period'),
            pointBorderWidth: 2,
            pointRadius: data.length > 0 ? [...Array(data.length - 1).fill(0), 4] : [],
        },
    ];
    if (previousData) {
        datasets.push({
            backgroundColor: COLORS_FLOW.shaded.blackberry.transparent,
            borderColor: COLORS_FLOW.shaded.blackberry.transparent,
            data: previousData.map(d => d.metrics.average_rating) ?? [],
            label: t('review_analytics_graphs_previous_period'),
            pointBorderWidth: 2,
            pointRadius:
                previousData.length > 0 ? [...Array(previousData.length - 1).fill(0), 4] : [],
        });
    }

    return (
        <Chart
            dataTrackId=""
            type={ChartType.Line}
            options={AVERAGE_RATING_OPTIONS}
            data={{
                datasets,
                labels: data.map((d, idx) => [d.dimension, previousData?.[idx]?.dimension ?? '']),
            }}
        />
    );
};
