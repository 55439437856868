import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

export const SidebarCounterWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5px 7px;
    border-radius: 100px;
    height: 20px;
    background: ${({ theme }) => theme.colors.danger.initial};
`;

export const SidebarCounterText = styled(Text)`
    font-size: 11px;
`;
