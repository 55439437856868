import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';

export function useIsMaxBusinessesLimitCheck(): boolean {
    const { data: me } = useMe();
    const { data: org } = useOrganization(me?.org_id ?? 0, !!me?.org_id);

    if (org && typeof org.max_businesses === 'number' && typeof org.businesses_count === 'number') {
        // Check if businesses_count exceeds or equals max_businesses limit
        return org.businesses_count >= org.max_businesses;
    }

    return false;
}
