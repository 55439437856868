import { useIsAvailableTodoReviewsItem } from 'app/pages/Homepage/hooks/todos/todosItems/reviews/useIsAvailableTodoReviewsItem';
import { SmallStatProps } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/DefaultStatComponent';
import { StatReviews } from 'app/pages/Homepage/sections/HomepageStats/stats/StatReviews';
import { Item } from 'app/pages/Homepage/utils/typing';

export const useStatReviewsItem = (): Item<SmallStatProps> => {
    const isAvailable = useIsAvailableTodoReviewsItem();

    return {
        order: 2,
        isAvailable,
        component: StatReviews,
        key: 'stat-reviews',
    };
};
