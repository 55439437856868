import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';
import { MESSAGING_CONVERSATION_STATS } from 'app/common/data/queryKeysConstants';

export default function useUpdateConversationAssignedUser(
    conversationId: number,
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const queryClient = useQueryClient();

    return useMutation(
        (payload: Record<string, any>) =>
            api.messaging.updateConversationAssignedUser(
                conversationId,
                payload,
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([MESSAGING_CONVERSATION_STATS]);
                return onSuccess();
            },
            onError: (_: Error) => {
                return onError();
            },
        },
    );
}
