import { toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import api_calls from 'app/api/v2/api_calls';
import { MESSAGING_CONVERSATION_STATS } from 'app/common/data/queryKeysConstants';

export default function useUpdateConversationStatus(
    conversationId: number,
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation(
        (payload: Record<string, any>) =>
            api_calls.messaging.updateConversationStatus(conversationId, payload),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([MESSAGING_CONVERSATION_STATS]);
                return onSuccess();
            },
            onError: (error: Error) => {
                toast.error(error.message, t('request_issue'));
                return onError();
            },
        },
    );
}
