import React from 'react';

import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'app/config';
import {
    NoAnalyticsContainer,
    NoAnalyticsImgContainer,
    NoAnalyticsTitle,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/NoAnalytics/NoAnalytics.styled';

export const NoAnalytics = () => {
    const { t } = useTranslation();
    const imgSrc = `${STATIC_BASE_URL}/images/app/no_business_in_list.svg`;
    return (
        <NoAnalyticsContainer>
            <NoAnalyticsImgContainer>
                <img src={imgSrc} alt="no-result-found" />
            </NoAnalyticsImgContainer>
            <NoAnalyticsTitle>{t('pm_no_analytics')}</NoAnalyticsTitle>
        </NoAnalyticsContainer>
    );
};
