import { PageHeader, PageHeaderTypes } from '@partoohub/modular-components';
import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';

import { useAppDataContext } from 'app/common/contexts/appData';
import { useSidebar } from 'app/common/contexts/sidebar';
import IS_IFRAME from 'app/common/data/iframe';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';

import { AvatarHelpCenterItem } from './components/AvatarHelpCenterItem';
import { AvatarLogoutItem } from './components/AvatarLogoutItem';
import { AvatarProfilMeItem } from './components/AvatarProfilMeItem';
import { AvatarSettingsItem } from './components/AvatarSettingsItem';

export const avatarMenuItems = [
    {
        id: 'profil',
        value: 'profil',
        label: <AvatarProfilMeItem />,
    },
    {
        id: 'settings',
        value: 'settings',
        label: <AvatarSettingsItem />,
    },
    {
        id: 'helpCenter',
        value: 'helpCenter',
        label: <AvatarHelpCenterItem />,
    },
    {
        id: 'logout',
        value: 'logout',
        label: <AvatarLogoutItem />,
    },
];

const PageHeaderMobile = (commonProps: PageHeaderTypes) => {
    const { setExtended } = useSidebar();

    const handleOnClickTitleIcon = () => {
        setExtended(true);
    };

    return (
        <PageHeader
            {...commonProps}
            titleIcon={
                !IS_IFRAME ? [FontAwesomeIconsPartooUsed.faBars, IconPrefix.SOLID] : undefined
            }
            onClickTitleIcon={!IS_IFRAME ? handleOnClickTitleIcon : undefined}
        />
    );
};

const PageHeaderDesktop = (commonProps: PageHeaderTypes) => {
    return <PageHeader {...commonProps} />;
};

export const PageHeaderWithAvatar = (props: PageHeaderTypes) => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const HeaderComponent = isBelowProvidedDevices ? PageHeaderMobile : PageHeaderDesktop;
    const { me } = useAppDataContext();

    return (
        <HeaderComponent
            displayAvatar={!IS_IFRAME}
            avatarMenuItems={avatarMenuItems}
            user={me}
            {...props}
        />
    );
};
