import React from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

const PhotoPreviewIcon = ({
    width = 56,
    height = 56,
    color = '#142542',
}: Props) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 57 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.1129 20.9999C14.6903 20.9999 16.7796 18.9105 16.7796 16.3332C16.7796 13.7559 14.6903 11.6666 12.1129 11.6666C9.53562 11.6666 7.44629 13.7559 7.44629 16.3332C7.44629 18.9105 9.53562 20.9999 12.1129 20.9999Z"
            fill={color}
        />
        <path
            d="M22.6129 40.8337C22.6129 31.7383 29.1556 24.1527 37.7796 22.5077V7.00036C37.7796 3.14103 34.6389 0.000366211 30.7796 0.000366211H7.44628C3.58695 0.000366211 0.446289 3.14103 0.446289 7.00036V39.667C0.446289 43.5263 3.58695 46.667 7.44628 46.667H23.5603C22.9536 44.8283 22.6129 42.873 22.6129 40.8337ZM11.5063 28.4203C9.96628 26.8803 7.25962 26.8803 5.71962 28.4203L5.11295 29.0247V7.00036C5.11295 5.71236 6.15829 4.66703 7.44628 4.66703H30.7796C32.0676 4.66703 33.1129 5.71236 33.1129 7.00036V22.0247L31.3396 20.2513C29.7436 18.6553 27.1466 18.6553 25.5529 20.2513L14.4463 31.358L11.5063 28.4203Z"
            fill={color}
        />
        <path
            d="M41.2796 25.6666C32.9169 25.6666 26.1129 32.4706 26.1129 40.8332C26.1129 49.1959 32.9169 55.9999 41.2796 55.9999C49.6422 55.9999 56.4462 49.1959 56.4462 40.8332C56.4462 32.4706 49.6422 25.6666 41.2796 25.6666ZM47.6962 43.1666H43.6129V47.2499C43.6129 48.5402 42.5699 49.5832 41.2796 49.5832C39.9892 49.5832 38.9462 48.5402 38.9462 47.2499V43.1666H34.8629C33.5726 43.1666 32.5296 42.1236 32.5296 40.8332C32.5296 39.5429 33.5726 38.4999 34.8629 38.4999H38.9462V34.4166C38.9462 33.1262 39.9892 32.0832 41.2796 32.0832C42.5699 32.0832 43.6129 33.1262 43.6129 34.4166V38.4999H47.6962C48.9866 38.4999 50.0296 39.5429 50.0296 40.8332C50.0296 42.1236 48.9866 43.1666 47.6962 43.1666Z"
            fill={color}
        />
    </svg>
);

export default PhotoPreviewIcon;
