import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { MESSAGING_CONVERSATION_STATS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

export const useConversationRead = () => {
    const businessFilters = useBusinessModalFilters();
    return useMutation(
        (conversationId: number) =>
            api.messaging.setConversationRead(conversationId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([
                    MESSAGING_CONVERSATION_STATS,
                    businessFilters,
                ]);
            },
            onError: (err, newData, context) => {
                if (context?.previousData) {
                    // If the mutation fails, use the context returned from onMutate to roll back
                    queryClient.setQueryData<any>(
                        [MESSAGING_CONVERSATION_STATS],
                        context.previousData,
                    );
                }
            },
        },
    );
};
