import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ChatbotActivation } from 'app/pages/settings/Chatbot/components/Activation/ChatbotActivation';
import { ChatbotCommunication } from 'app/pages/settings/Chatbot/components/Communication/ChatbotCommunication';
import { ChatbotInstruction } from 'app/pages/settings/Chatbot/components/Instructions/ChatbotInstruction';
import { ChatbotKnowledge } from 'app/pages/settings/Chatbot/components/Knowledge/ChatbotKnowledge';
import { ChatbotSection } from 'app/pages/settings/Chatbot/components/PageContent/ChatbotSection';
import { ChatbotSectionsContainer } from 'app/pages/settings/Chatbot/components/PageContent/ChatbotSections.styled';
import { useChatbot } from 'app/pages/settings/Chatbot/hooks/useChatbot';
import {
    BUSINESS_SELECTION_ID,
    COMMUNICATION_SECTION_ID,
    INSTRUCTION_SECTION_ID,
    KNOWLEDGE_SECTION_ID,
} from 'app/pages/settings/Chatbot/type/chatbot';

import { ChatbotBusinessSelection } from '../BusinessSelection/ChatbotBusinessSelection';

export const ChatbotSections = () => {
    const { t } = useTranslation();
    const { data: chatbot } = useChatbot();
    const [isOpenedId, setIsOpenedId] = useState('');

    const handleIsOpenedId = (sectionId: string) => {
        if (sectionId === isOpenedId) {
            setIsOpenedId('');
        } else {
            setIsOpenedId(sectionId);
        }
    };

    return (
        <ChatbotSectionsContainer>
            <ChatbotSection
                onClick={() => handleIsOpenedId(BUSINESS_SELECTION_ID)}
                title={t('chatbot_business_selection_section_title')}
                isOpenedId={isOpenedId}
                sectionId={BUSINESS_SELECTION_ID}
                isCompleted={!!chatbot?.business_ids.length}
            >
                <ChatbotBusinessSelection onClose={() => handleIsOpenedId(BUSINESS_SELECTION_ID)} />
            </ChatbotSection>
            <ChatbotSection
                onClick={() => handleIsOpenedId(KNOWLEDGE_SECTION_ID)}
                title={t('chatbot_knowledge_section_title')}
                isOpenedId={isOpenedId}
                sectionId={KNOWLEDGE_SECTION_ID}
                isCompleted={!!chatbot?.knowledge[0].updated_at}
            >
                <ChatbotKnowledge onClose={() => handleIsOpenedId(KNOWLEDGE_SECTION_ID)} />
            </ChatbotSection>
            <ChatbotSection
                onClick={() => handleIsOpenedId(COMMUNICATION_SECTION_ID)}
                title={t('chatbot_communication_section_title')}
                isOpenedId={isOpenedId}
                sectionId={COMMUNICATION_SECTION_ID}
                isCompleted={!!chatbot?.updated_at}
            >
                <ChatbotCommunication onClose={() => handleIsOpenedId(COMMUNICATION_SECTION_ID)} />
            </ChatbotSection>
            <ChatbotSection
                onClick={() => handleIsOpenedId(INSTRUCTION_SECTION_ID)}
                title={t('chatbot_instruction_section_title')}
                isOpenedId={isOpenedId}
                sectionId={INSTRUCTION_SECTION_ID}
                isCompleted={!!chatbot?.updated_at}
            >
                <ChatbotInstruction onClose={() => handleIsOpenedId(INSTRUCTION_SECTION_ID)} />
            </ChatbotSection>
            <ChatbotActivation />
        </ChatbotSectionsContainer>
    );
};
