import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface InitialsContainerProps {
    textColor: string;
}

export const InitialsContainer = styled.div`
    ${(props: InitialsContainerProps) => css`
        span {
            color: ${props.textColor} !important;
        }

        i {
            color: ${props.textColor} !important;
        }
    `}
`;
