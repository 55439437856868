import React from 'react';

import { useTranslation } from 'react-i18next';

import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';

type Props = {
    onClick: () => void;
    translationKey: string;
    translationParams?: Record<string, any>;
};

const LostPasswordLink = ({
    onClick,
    translationKey,
    translationParams = {},
}: Props) => {
    const { t } = useTranslation();

    return (
        <div className="flex--justify_center">
            <StrikeThroughButton
                color="secondary"
                onClick={onClick}
                text={t(translationKey, translationParams)}
            />
        </div>
    );
};

export default LostPasswordLink;
