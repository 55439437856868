import React from 'react';

import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import EditSection from 'app/businessEditV2/components/EditSection';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import useDisplayServices from 'app/businessEditV2/hooks/editV2utils/displaySections/useDisplayServices';
import useIsLoadingServices from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingServices';
import useServicesSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useServicesSectionColor';
import useShowModal from 'app/businessEditV2/hooks/editV2utils/useShowModal';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import ServicesForm from './ServicesForm';
import ServicesSectionContent from './ServicesSectionContent';

const ServicesSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const [displayModal, setDisplayModal] = useShowModal(SectionNames.SERVICES);
    const displayServices = useDisplayServices();
    const sectionColor = useServicesSectionColor();
    const { t } = useTranslation();

    const isLoading = useIsLoadingServices();

    if (isLoading) {
        return <EditSectionLoader />;
    }

    if (!displayServices) {
        return null;
    }

    return (
        <div
            ref={ref}
            data-track="visibility_location__section_services"
            data-intercom-target="visibility_location__section_services"
        >
            <EditSection
                title={t('gmb_services_business_edit')}
                description={
                    <ServicesSectionContent colorState={sectionColor} />
                }
                onClick={() => setDisplayModal(true)}
                colorState={sectionColor}
                sectionName={SectionNames.SERVICES}
            />
            <FullScreenModal
                show={displayModal}
                childrenRight={
                    <EditFormDescription
                        title={t('business_edit_title_services')}
                        description={t('business_edit_description_services')}
                        advice={t('business_edit_advice_services')}
                    />
                }
                childrenLeft={
                    <ServicesForm closeModal={() => setDisplayModal(false)} />
                }
                onHideClick={() => setDisplayModal(false)}
            />
        </div>
    );
});

export default ServicesSection;
