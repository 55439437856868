import { css } from '@emotion/react';
import { blackColorObject } from '@partoohub/branding';

import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { reset } from '@partoohub/ui';

import { MAX_WIDTH_CONTENT_PAGE, MAX_WIDTH_CONTENT_PAGE_BIG_SCREEN } from './constants/constants';
import {
    deprecatedClassColors,
    deprecatedOldComponentsStyle,
    deprecatedUtilsStyles,
} from './deprecated.styled';

const resetStyle = css`
    ${reset}
    * {
        margin: 0;
    }
    body {
        font-size: 14px;
        line-height: 1.42857143;
        color: ${HEX_COLORS.blackberry};
        -webkit-font-smoothing: antialiased;
    }
`;

const googleMapsAutoCompleteOverride = css`
    // Used by AddressFullInput in Business Edit and Business Creation
    .pac-container {
        z-index: 1150;
        padding: 8px;
        border-radius: 4px;
        font-family: inherit;
        box-shadow:
            0 3px 14px 2px rgba(0, 0, 0, 0.12),
            0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 5px 5px -3px rgba(0, 0, 0, 0.2);

        .pac-icon-marker {
            display: none;
        }

        .pac-item {
            display: flex;
            min-height: 38px;
            align-items: center;
            padding: 0 8px;
            border-radius: 4px;
            border: none;
            font-size: 12px;
            color: ${HEX_COLORS.secondary};

            .pac-item-query {
                color: ${HEX_COLORS.blackberry};
                font-size: 14px;
            }

            &:hover {
                background-color: ${greyColorObject.light};
                cursor: pointer;
            }
        }

        .pac-item-selected {
            background-color: ${greyColorObject.light};
        }

        // Remove the "powered by Google" for the autocomplete suggestion
        &:after {
            display: none;
        }
    }
`;

const globalAnimation = css`
    @-webkit-keyframes sk-scaleout {
        0% {
            -webkit-transform: scale(0);
        }

        100% {
            -webkit-transform: scale(1);
            opacity: 0;
        }
    }

    @keyframes sk-scaleout {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 0;
        }
    }

    // Use infinite scroll
    @keyframes react-placeholder-pulse {
        0% {
            opacity: 0.6;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0.6;
        }
    }
`;

const scrollbarStyling = css`
    body {
        @supports not selector(::-webkit-scrollbar) {
            scrollbar-width: thin;
            scrollbar-color: ${blackColorObject['20']} #f6f8fc;
        }
    }

    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-track {
        background: #f6f8fc;

        :hover {
            background: ${greyColorObject.light};
        }
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${blackColorObject['20']};
        border-radius: 10px;
        border: 3px solid #f6f8fc;

        :hover {
            background-color: ${blackColorObject['35']};
        }
    }
`;

const globalApp = css`
    :root {
        --pto-font-family: 'Outfit';
        --pto-primary: #0085f2;
        --pto-primary-light: #42b7f5;
        --pto-primary-dark: #027fc1;
        --pto-primary-transparent: rgba(3, 159, 241, 0.12);
        --pto-primary-medium: rgba(3, 159, 241, 0.8);
        --pto-primary-hover: rgba(3, 159, 241, 0.08);
        --pto-blackberry-transparent: rgba(20, 33, 58, 0.12);
    }

    #react_app {
        width: 100%;
        height: 100%;
    }

    #react_app > div {
        height: 100%;
    }

    a:focus,
    a:active {
        outline: 0 !important;
        border-radius: 0;
        background-color: transparent;
    }

    input[type='checkbox']:focus,
    input[type='checkbox']:active {
        outline: 0 !important;
    }

    input[type='time']::-webkit-calendar-picker-indicator {
        background: none;
    }

    .checkbox label::before,
    .radio label::before {
        outline: 0 !important;
    }

    .navbar {
        border-radius: 0;
        border: none;
    }

    .noselect {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Chrome/Safari/Opera */
        -khtml-user-select: none;
        /* Konqueror */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently not supported by any browser */
    }

    span.mandatory-field {
        margin-left: 2px;
        color: #ff426e;
    }

    // Use inside jssdk
    .hide {
        display: none !important;
    }

    input[type='number'] {
        -moz-appearance: textfield; /* This removes the spinner from Firefox */

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; /* This removes the spinner from WebKit browsers like Chrome and Safari */
        }
    }

    ${globalAnimation};
`;

export const global = css`
    ${resetStyle};
    ${globalApp};
    ${deprecatedClassColors};
    ${deprecatedUtilsStyles}
    ${deprecatedOldComponentsStyle}

    html {
        height: 100%;
    }

    body {
        height: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: var(--pto-font-family), Helvetica, sans-serif;
        font-variant: no-common-ligatures;
    }

    select {
        letter-spacing: inherit;
    }

    // reset : useful for correctly positioning the ball of the switch component
    input[type='checkbox'],
    input[type='radio'] {
        margin: 0;
    }

    a,
    a:hover,
    a:focus {
        text-decoration: none;
    }

    ${googleMapsAutoCompleteOverride};

    label {
        margin-bottom: 0;
    }
`;

export const globalWithScrollbarStyling = css`
    ${global}
    ${scrollbarStyling}
`;

export const GetMaxWidthContentPage = css`
    max-width: ${MAX_WIDTH_CONTENT_PAGE}px;

    @media (min-width: 1601px) {
        max-width: ${MAX_WIDTH_CONTENT_PAGE_BIG_SCREEN}px;
    }
`;
