import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { GROUPS_V2 } from 'app/common/data/queryKeysConstants';

export default function useSection(sectionId: string) {
    return useQuery([GROUPS_V2, { sectionId }], () => api.groupsV2.getSection(sectionId), {
        enabled: !!sectionId,
    });
}
