import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { ViewMoreContainer } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Keywords/KeywordsCard/QueriesCard/ViewMore/ViewMore.styled';
import { ViewMoreModalModal } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Keywords/KeywordsCard/QueriesCard/ViewMore/ViewMoreModal/ViewMoreModal';
import { Keyword } from 'app/pages/analytics/VisibilityAnalyticsV2/types/keyword';

type Props = {
    keywords: Array<Keyword>;
};
export const ViewMore = ({ keywords }: Props) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);

    const onClick = () => {
        setShowModal(true);
    };

    return (
        <ViewMoreContainer>
            <StrikeThroughButton color={'primary'} text={t('view_full_list')} onClick={onClick} />
            <ViewMoreModalModal
                keywords={keywords}
                showModal={showModal}
                closeModal={() => setShowModal(false)}
            />
        </ViewMoreContainer>
    );
};
