import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

type ListItemProps = {
    active: boolean;
    disabled: boolean;
};
type ListItemTextProps = {
    active: boolean;
};

type DropdownContainerProps = {
    isDesktop: boolean;
};

export const DropdownContainer = styled.div<DropdownContainerProps>`
    display: flex;
    position: relative;
    width: ${({ isDesktop }) => (isDesktop ? '320px' : '290px')};
`;

export const DropdownHeaderContainer = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    height: 46px;
    padding: 8px 16px;
    background-color: ${({ theme }) => theme.colors.theme.background};
    border: 1px solid #dddfe3;
    border-radius: 4px;
    > i {
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
    :hover {
        cursor: pointer;
    }
`;

export const Separator = styled.div`
    width: 1px;
    height: 30px;
    background-color: #dddfe3;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DropdownTitle = styled.div`
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.default.initial};
`;

export const DropdownSubTitle = styled.div`
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const DropdownListContainer = styled.div`
    background-color: ${HEX_COLORS.white};
    box-shadow: 0px 5px 10px rgba(20, 37, 66, 0.16);
    border-radius: 4px;
    width: 210px;
    position: absolute;
    top: 54px;
    z-index: 100;
`;

export const DropdownList = styled.ul`
    margin: 8px 0;
    padding: 0 8px;
`;

export const ListItem = styled.button<ListItemProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    background-color: ${props => (props.active ? 'rgba(0, 133, 242, 0.12)' : HEX_COLORS.white)};
    color: ${({ active, theme }) =>
        active ? theme.colors.primary.initial : theme.colors.default.initial};
    border-radius: 4px;
    border: none;
    padding: 10px 8px;
    list-style: none;
    transition: all 0.4s;
    width: 100%;
    cursor: pointer;
    &:disabled {
        background-color: rgba(215, 219, 223, 0.4);
        color: rgba(215, 219, 223, 0.8);
        cursor: not-allowed;
    }
    > i {
        color: ${({ theme }) => theme.colors.primary.initial};
    }
    &:hover {
        background-color: ${props => (props.active ? 'rgba(0, 133, 242, 0.12)' : '#F6F8FC')};
    }
    &:last-child {
        margin-bottom: 0;
    }
`;

export const ListItemText = styled.span<ListItemTextProps>`
    font-weight: ${props => (props.active ? 700 : 300)};
    font-size: 14px;
    line-height: 18px;
`;

export const CaretContainer = styled.div`
    align-self: center;
    width: 8.75px;
    right: 16px;
    > i {
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;
