import { customFieldsType } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/services/index';

const checkEditDuplicateErrorSelects = (selectOptions: any, error: Array<string>) => {
    const duplicatedErrors = {};
    // Loop on errors
    error.forEach(value => {
        const valueDuplicates = {};
        const indexes = [] as Array<number>;
        // Loop on options
        selectOptions.forEach((valueOption, index) => {
            if (value === valueOption) {
                // retrieve every occurence of the duplicate error
                valueDuplicates[index.toString()] = value;
                indexes.push(index);
            }
        });
        // Remove the original value from the duplicate list
        delete valueDuplicates[Math.min(...indexes).toString()];
        // Store the errors
        Object.assign(duplicatedErrors, valueDuplicates);
    });
    return duplicatedErrors;
};

const DuplicatedOptionChecker = (
    selectOptions: Record<string, any>,
    error: Array<string>,
    editMode: boolean,
    type: string,
) => {
    // Case Single or Multiple Select
    if (type === customFieldsType.MULTIPLE_SELECT || type === customFieldsType.SINGLE_SELECT) {
        // Creation Mode
        if (!editMode) {
            return checkEditDuplicateErrorSelects(selectOptions.possible_values, error);
        }

        // Edit Mode
        return checkEditDuplicateErrorSelects(
            selectOptions.editValues ? selectOptions.editValues.map(value => value.newValue) : [],
            error,
        );
    }

    if (type === customFieldsType.IMAGES_UPLOADER) {
        if (!editMode) {
            return checkEditDuplicateErrorSelects(selectOptions.text_fields, error);
        }
        return checkEditDuplicateErrorSelects(
            selectOptions.editValues
                ? selectOptions.editValues.map((value: any) => value.newValue?.text_field)
                : [],
            error,
        );
    }

    // case Multiple Select Image
    // Creation Mode
    if (!editMode) {
        return checkEditDuplicateErrorSelects(selectOptions.possible_values, error);
    }

    // Edition Mode
    return checkEditDuplicateErrorSelects(
        selectOptions.editValues.map(value => value.newValues?.value),
        error,
    );
};

export default DuplicatedOptionChecker;
