import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';

import { Homepage as OldHomePage } from './Homepage';
import { HomepageWithNewSidebar } from './HomepageWithNewSidebar';

export const Homepage = () => {
    const { t } = useTranslation();

    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const HomepageComponent = isNewSidebarEnabled ? HomepageWithNewSidebar : OldHomePage;

    return (
        <>
            <DynamicPageHeader title={t('homepage_title')} />
            <HomepageComponent />
        </>
    );
};
