import React from 'react';

export const FacebookMessengerIcon = () => (
    <svg
        width="14"
        height="14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 14"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 0C3.057 0 0 2.888 0 6.79c0 2.04.836 3.804 2.198 5.022a.56.56 0 0 1 .188.4l.039 1.245a.56.56 0 0 0 .785.495l1.39-.614a.559.559 0 0 1 .374-.027c.638.175 1.318.269 2.026.269 3.943 0 7-2.889 7-6.79C14 2.888 10.943 0 7 0Z"
            fill="url(#a)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m2.797 8.776 2.056-3.263a1.05 1.05 0 0 1 1.518-.28L8.006 6.46a.42.42 0 0 0 .506-.002l2.21-1.676c.294-.224.679.13.482.442L9.146 8.487a1.05 1.05 0 0 1-1.518.28L5.993 7.54a.42.42 0 0 0-.505.002l-2.21 1.676c-.294.224-.679-.13-.481-.442Z"
            fill="#fff"
        />
        <defs>
            <radialGradient
                id="a"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(2.695 13.925) scale(15.2543)"
            >
                <stop stopColor="#09F" />
                <stop offset=".61" stopColor="#A033FF" />
                <stop offset=".935" stopColor="#FF5280" />
                <stop offset="1" stopColor="#FF7061" />
            </radialGradient>
        </defs>
    </svg>
);
