import {
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    LeftElementType,
    TextInput,
    Tooltip,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { PlaceActionLinkUrlTypeForm } from 'app/api/types/placeActionLinks';

import { IconButtonsContainer, PlaceActionLinkUrlContainer } from './PlaceActionLinkUrls.styled';

type Props = {
    url: PlaceActionLinkUrlTypeForm;
    onChange: (url: PlaceActionLinkUrlTypeForm) => void;
    onUrlDelete: () => void;
    onChangeIsPreferred: () => void;
    index: number;
    error?: string;
    disabled?: boolean;
};

export const PlaceActionLinkUrl = ({
    url,
    onChange,
    onUrlDelete,
    onChangeIsPreferred,
    index,
    error,
    disabled,
}: Props) => {
    const { t } = useTranslation();

    const onClickIsPreferred = () => {
        if (!url.is_preferred) {
            onChangeIsPreferred();
        }
    };

    const onChangeUrlValue = (urlValue: string) => {
        const result = { ...url };
        result.value = urlValue;
        onChange(result);
    };

    const disabledPal: boolean = !url.is_editable || !!disabled;

    const linkLabel = `${t('link')} ${t('num_abbrev', { number: index + 1 })}`;
    const isPreferredDisabled = disabledPal || !url.value;
    const disabledTooltip = disabledPal ? t('place_action_link_not_editable') : '';
    const isPreferredButtonTooltip = disabledPal
        ? t('place_action_link_not_editable')
        : url.value
          ? t('place_action_link_mark_as_is_preferred')
          : '';

    return (
        <PlaceActionLinkUrlContainer>
            <Tooltip text={disabledTooltip} placement={'top'}>
                <TextInput
                    value={url.value || ''}
                    onChange={onChangeUrlValue}
                    label={linkLabel}
                    leftElement={[FontAwesomeIconsPartooUsed.faLink, IconPrefix.SOLID]}
                    leftElementType={LeftElementType.Icon}
                    disabled={disabledPal}
                    dataTrackId="visibility_location__form_links__additional_repeatable_urls"
                    error={!!error}
                    notice={error}
                />
            </Tooltip>
            <IconButtonsContainer>
                <IconButton
                    appearance="outlined"
                    variant="ratings"
                    onClick={onClickIsPreferred}
                    tooltip={isPreferredButtonTooltip}
                    disabled={isPreferredDisabled}
                    icon={[
                        FontAwesomeIconsPartooUsed.faStar,
                        url.is_preferred ? IconPrefix.SOLID : IconPrefix.REGULAR,
                    ]}
                    dataTrackId="delete_link__button"
                />
                <IconButton
                    appearance="outlined"
                    variant="danger"
                    onClick={onUrlDelete}
                    tooltip={disabledPal ? t('place_action_link_not_editable') : t('delete')}
                    disabled={disabledPal}
                    icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                    dataTrackId="delete_link__button"
                />
            </IconButtonsContainer>
        </PlaceActionLinkUrlContainer>
    );
};
