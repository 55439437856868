import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import { useMessagesReplyTemplatesContext } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/hooks/useMessagesReplyTemplatesContext';

import { ReplyTemplates as MessageReplyTemplateV2 } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/ReplyTemplates';

import { TemplateFormContext } from './components/MessagesReplyTemplates.context';

export const MessagesReplyTemplates = () => {
    const { t } = useTranslation();
    const templateFormValue = useMessagesReplyTemplatesContext();

    return (
        <TemplateFormContext.Provider value={templateFormValue}>
            <DynamicPageHeader title={t('messaging_reply_template_settings_header')} />
            <MessageReplyTemplateV2 />
        </TemplateFormContext.Provider>
    );
};
