import { useBusinesses } from 'app/common/hooks/queries/useBusinesses';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { stringifyDate } from 'app/utils/dates';

export const useGeneratePreviewUrl = (surveyLink: string) => {
    const { data: me } = useMe();
    const { businesses } = useBusinesses();

    const businessCode = businesses?.find(b => b.code)?.code;

    if (!me || !businessCode) return undefined;

    const { email, first_name, last_name } = me;
    return `${surveyLink}?email=${email}&firstname=${first_name}&lastname=${last_name}&store_code=${businessCode}&date=${stringifyDate(new Date())}&preview=True`;
};
