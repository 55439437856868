import styled from '@emotion/styled';

export const ModalWrapper = styled.div`
    padding: 40px 8px 8px;

    width: 500px;
`;

export const ModalContent = styled.div`
    padding: 0 16px;
`;

export const ModalTitle = styled.div`
    margin-bottom: 8px;

    text-align: center;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
`;

export const EditModalTitle = styled(ModalTitle)`
    margin-bottom: 32px;
`;

export const ModalSubtitle = styled.div`
    margin-bottom: 24px;

    text-align: center;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
`;

export const CancelButton = styled.div`
    margin-bottom: 16px;

    text-align: center;
`;

export const StructuredServiceName = styled.div`
    margin-bottom: 32px;
`;

export const StructuredServiceLabel = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
`;

export const StructuredServiceValue = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
`;
