import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';

type PhotoCoverProps = {
    url: string;
};

export const PhotoCover = styled.div<PhotoCoverProps>`
    position: relative;
    width: 100%;
    height: 90px;
    background-image: url('${props => props.url}');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &:hover {
        cursor: pointer;
    }

    ${props =>
        props.url &&
        css`
            background-color: ${greyColorObject.light};
        `}
`;

export const PhotoCoverOverlay = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    opacity: 0;
    background-color: rgba(15, 22, 31, 0.5);
    position: absolute;
    transition: all ease 0.2s;
    content: '';
    width: 100%;
    height: 100%;

    &:hover {
        opacity: 1;
    }

    > i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background-color: ${HEX_COLORS.white};
        color: ${({ theme }) => theme.colors.secondary.initial};
        font-size: 16px;
    }
`;

type PhotoCoverTextProps = {
    sectionColor: ColorState;
};

export const PhotoCoverText = styled.div<PhotoCoverTextProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: bold;
    color: ${HEX_COLORS.blackberry};
    transition: all ease 0.2s;
    background-color: ${({ theme }) => theme.colors.secondary.alpha};

    > i {
        margin-left: 4px;
    }

    &:hover {
        color: ${({ theme }) => theme.colors.warning.alpha};
    }

    ${({ sectionColor, theme }) =>
        [ColorState.PRECHECK_ERRORS, ColorState.MISSING_WARNING].includes(sectionColor) &&
        css`
            color: ${theme.colors.warning.initial};
            background-color: ${theme.colors.warning.alpha};
        `};

    ${({ sectionColor, theme }) =>
        [ColorState.DISABLED, ColorState.CLOSED].includes(sectionColor) &&
        css`
            color: ${theme.colors.secondary.alpha};

            &:hover {
                color: ${theme.colors.secondary.alpha};
            }
        `};
`;
