import { connect } from 'react-redux';

import { isSuccessSelector } from 'app/states/connection/reducers/lostPassword';
import { AppState } from 'app/states/reducers';

import LostPasswordFormComponent from './LostPasswordForm';

const mapDispatchToProps = ({ lostPassword }: AppState) => ({
    isSuccess: isSuccessSelector(lostPassword),
});

const LostPasswordForm = connect(mapDispatchToProps)(LostPasswordFormComponent);

export default LostPasswordForm;
