import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { ReceivedFeedbackFormData } from 'app/api/types/feedbackForm';
import api from 'app/api/v2/api_calls';
import { FEEDBACK_FORM } from 'app/common/data/queryKeysConstants';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useFeedbackFormList = (options: UseQueryOptions<ReceivedFeedbackFormData> = {}) => {
    const { data: me } = useMe();

    const orgId = me?.org_id ?? 0;

    const query = useQuery(
        [FEEDBACK_FORM, orgId] as QueryKey,
        () => api.feedbackForm.getFeedbackForm(orgId),
        { ...options, enabled: !!orgId && (options.enabled ?? true) },
    );

    const forms = query?.data?.items ?? [];

    return [forms, query] as const;
};
