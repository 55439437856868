import React from 'react';

import Decoration from './Decoration';

const PostcardImage = React.forwardRef<SVGSVGElement>(() => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 312 143"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M235.61 77.5812C237.938 106.893 181.489 126.012 132.903 112.67C84.3163 99.3267 62.0271 47.2214 85.2118 19.4577C108.396 -8.30607 233.283 48.2692 235.61 77.5812Z"
            fill="#40DBA2"
            fillOpacity="0.12"
        />
        <g clipPath="url(#clip0_2455_39464)">
            <path
                d="M187.99 25.75H133.958V18.1667H146.281C147.851 18.1667 149.125 16.8927 149.125 15.3229V5.84375C149.125 4.274 147.851 3 146.281 3H130.167C128.074 3 126.375 4.69488 126.375 6.79167V27.3577C119.69 30.2924 115 36.9544 115 44.7083V71.25C115 73.3468 116.699 75.0417 118.792 75.0417H149.125C149.292 75.0417 149.436 74.9658 149.599 74.9469C149.758 74.9734 149.906 75.0417 150.073 75.0417H160.5V90.2083C160.5 92.3051 162.199 94 164.292 94C166.385 94 168.083 92.3051 168.083 90.2083V75.0417H203.156C204.726 75.0417 206 73.7677 206 72.1979V43.7604C206 33.83 197.92 25.75 187.99 25.75ZM145.333 67.4583H122.583V44.7083C122.583 38.4369 127.687 33.3333 133.958 33.3333C140.23 33.3333 145.333 38.4369 145.333 44.7083V67.4583Z"
                fill="#40DBA2"
            />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M155.9 143C183.459 143 205.8 140.314 205.8 137C205.8 133.686 183.459 131 155.9 131C128.341 131 106 133.686 106 137C106 140.314 128.341 143 155.9 143Z"
            fill="#142542"
            fillOpacity="0.05"
        />
    </svg>
));

const PostcardSentImage: React.FC = () => (
    <>
        <Decoration color="#40DBA2" />
        <PostcardImage />
    </>
);

export default PostcardSentImage;
