import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const CustomFieldsTypeComponentName = styled(Text)`
    margin-bottom: 8px;
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
`;
