import { call, put, select, takeEvery } from 'redux-saga/effects';

import {
    SearchBusinessParams,
    SearchBusinessesReceivedData,
} from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import { filterByBusinessSelector } from 'app/common/components/businessModalDeprecated/reducers';
import {
    ADD_SEARCH_FILTER,
    AddSearchFilterAction,
    addSearchFilterFailure,
    addSearchFilterSuccess,
    citiesHaveChanged,
    groupsHaveChanged,
    pendingGroupIdInSelector,
    queriesHaveChanged,
} from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import {
    BusinessesState,
    connectedToFacebookLocationSelector,
    connectedToGmbLocationSelector,
} from 'app/common/reducers/newBusinesses';
import booleanToQueryParam from 'app/common/services/booleanToQueryParams';
import { Saga } from 'app/common/types';
import { businessesSelector } from 'app/states/reducers';

import { orgsHaveChanged } from '../reducers/filterByBusiness';

function* addSearchFilter({
    subqueries,
    cities,
    orgs,
    groupIdIn,
    groupIsNull,
}: AddSearchFilterAction) {
    try {
        const businessState = yield select(
            businessesSelector,
        ) as any as BusinessesState;
        // @ts-ignore
        const filterByBusiness = yield select(filterByBusinessSelector);
        const connectedToGMBLocation =
            connectedToGmbLocationSelector(businessState);
        const connectedToFbLocation =
            connectedToFacebookLocationSelector(businessState);
        const oldGroupIdIn = pendingGroupIdInSelector(filterByBusiness);
        // @ts-ignore
        const allGroups = [...new Set([...oldGroupIdIn, ...groupIdIn])];
        const newGroupIdIn = groupsHaveChanged(filterByBusiness, allGroups)
            ? allGroups
            : [];
        const newQueries =
            subqueries.length &&
            queriesHaveChanged(filterByBusiness, subqueries)
                ? subqueries
                : [];
        const newCities =
            cities.length && citiesHaveChanged(filterByBusiness, cities)
                ? cities
                : [];
        const newOrgs =
            orgs.length && orgsHaveChanged(filterByBusiness, orgs) ? orgs : [];
        let data = {
            count: 0,
        };

        if (
            newQueries.length ||
            newCities.length ||
            newOrgs.length ||
            groupIdIn.length
        ) {
            const searchParams: SearchBusinessParams = {
                query: newQueries.join(',') || undefined,
                city: newCities.join(',') || null,
                // @ts-ignore
                org_id: newOrgs.join(',') || null,
                group_id__in: newGroupIdIn.join(',') || null,
                group_id__is_null: groupIsNull || undefined,
                connected_to_gmb_location: booleanToQueryParam(
                    connectedToGMBLocation,
                ),
                connected_to_facebook_location: booleanToQueryParam(
                    connectedToFbLocation,
                ),
            };

            data = (yield call(
                api.business.searchBusinessesCount,
                searchParams,
            ) as any) as SearchBusinessesReceivedData;
        }

        yield put(
            addSearchFilterSuccess(
                data.count,
                newQueries,
                newCities,
                newOrgs,
                newGroupIdIn,
                groupIsNull,
            ),
        );
    } catch (error) {
        yield put(addSearchFilterFailure(error));
    }
}

function* addSearchFilterSaga(): Saga {
    yield takeEvery(ADD_SEARCH_FILTER, addSearchFilter);
}

export default addSearchFilterSaga;
