import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { HEX_COLORS } from '@partoohub/branding';
import { NavLink } from 'react-router-dom';

import Clickable from 'app/common/components/buttons/Clickable';

type NavItemContainerProps = {
    isChild?: boolean;
};

export const NavItemContainer = styled.div<NavItemContainerProps>`
    ${({ isChild }) =>
        !isChild
            ? css`
                  margin-bottom: 24px;
              `
            : css`
                  * {
                      font-weight: normal !important;
                  }
              `};
`;

export const NavItemTitleContainer = styled.div`
    display: flex;
    font-weight: bold;
    align-items: center;
    color: ${HEX_COLORS.blackberry};
    padding: 3px 0;
`;

type NavItemNavLinkProps = {
    selected: boolean;
};

export const NavItemNavLink = styled(NavLink, {
    shouldForwardProp: prop => prop !== 'selected',
})<NavItemNavLinkProps>`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${HEX_COLORS.blackberry} !important; // override bootstrap :hover
    text-decoration: none !important; // override bootstrap
    ${({ selected, theme }) =>
        selected &&
        css`
            font-weight: bold;
            color: ${theme.colors.primary.initial} !important; // override bootstrap
        `}
`;

export const ChevronItemIcon = styled.i`
    margin-left: 8px;
    &::before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: 'Font Awesome 6 Pro';
        content: '\\f054';
        color: ${({ theme }) => theme.colors.secondary.initial};
        font-size: 10px;
        font-weight: 900;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
`;

type ClickableWrapperProps = {
    chevronDown: boolean;
};

export const ClickableWrapper = styled(Clickable)<ClickableWrapperProps>`
    ${props =>
        props.chevronDown &&
        css`
            i::before {
                transform: rotate(90deg);
            }
        `};
`;

export const NavItemChildren = styled.div`
    margin: 8px 0 0 16px;

    display: flex;
    flex-direction: column;
    gap: 8px;
`;
