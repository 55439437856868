import useTotalBusinessesCount from 'app/common/components/businessModal/hooks/useTotalBusinessesCount';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';

import { useHasPostPermission } from './useHasPostPermission';

export const usePostsListIsLoading = () => {
    const { isLoading: subscriptionsLoading } = useBusinessSubscriptions();
    const totalBusinesses = useTotalBusinessesCount();
    const hasPostPermission = useHasPostPermission();

    return (
        hasPostPermission === undefined || (subscriptionsLoading && totalBusinesses === undefined)
    );
};
