import { useState } from 'react';

import { css } from '@emotion/react';
import { IconPrefix, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { ADMIN, PROVIDER } from 'app/common/data/roles';
import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';
import useMe from 'app/common/hooks/queries/useMe';
import { openManageModal } from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupFakeSaga';
import useHasGroupPuller from 'app/pages/visibility/BusinessListV2/hooks/groups/useHasGroupPuller';
import useIsSubgroup from 'app/pages/visibility/BusinessListV2/hooks/groups/useIsSubgroup';
import DeleteGroupModal from 'app/pages/visibility/BusinessListV2/sections/BusinessListHeader/OldHeaderButton/GroupModals/DeleteGroupModal';
import RenameGroupModal from 'app/pages/visibility/BusinessListV2/sections/BusinessListHeader/OldHeaderButton/GroupModals/RenameGroupModal';

const DELETE = 'delete';
const RENAME = 'rename';
const MANAGE = 'manage';

export const ManageGroupButton = () => {
    const { t } = useTranslation();
    const { data: me } = useMe();
    const isSubgroup = useIsSubgroup();
    const hasGroupPuller = useHasGroupPuller();
    const dispatch = useDispatch();

    const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const onMenuClick = (value: string) => {
        if (value === DELETE) {
            setShowDeleteModal(true);
        } else if (value === RENAME) {
            setShowRenameModal(true);
        } else if (value === MANAGE) {
            dispatch(openManageModal(true));
        }
    };

    const options = [
        {
            name: MANAGE,
            value: MANAGE,
            label: t('manage_business'),
            disabled: hasGroupPuller || (me?.role && [ADMIN, PROVIDER].includes(me.role)),
        },
        {
            name: RENAME,
            value: RENAME,
            label: t(isSubgroup ? 'rename_subgroup' : 'rename_group'),
        },
        {
            name: DELETE,
            value: DELETE,
            label: t(isSubgroup ? 'delete_subgroup' : 'delete_group'),
            disabled: hasGroupPuller,
        },
    ];

    return (
        <>
            <ActionButtonWithMenu
                dataTrackId="manage_button__action_button"
                icon={['fa-ellipsis-v', IconPrefix.SOLID]}
                appearance="outlined"
                variant="primary"
                options={options}
                onMenuClick={value => onMenuClick(value)}
                cssMenu={css`
                    top: 50px;
                    right: 0px;
                    left: unset;
                `}
            />
            <Modal isOpen={showRenameModal} closeModal={() => setShowRenameModal(false)}>
                <RenameGroupModal closeModal={() => setShowRenameModal(false)} />
            </Modal>

            <DeleteGroupModal
                showModal={showDeleteModal}
                closeModal={() => setShowDeleteModal(false)}
            />
        </>
    );
};
