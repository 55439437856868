import { IconElement, IconPrefix } from '@partoohub/ui';

import { t } from 'i18next';

import { CompetitorRank } from 'app/api/types/competitiveBenchmark';
import { isCompetitorCentral } from 'app/pages/analytics/CompetitiveBenchmark/utils/getTypeFromCompetitor';

import { RowOptions } from '../components/CompetitorTableSection/CompetitorRow/CompetitorRow';

export const buildRowOptions = (
    competitor: CompetitorRank,
    clientRanking: CompetitorRank,
    previousRanking: CompetitorRank | null,
    colorsMap: Record<string, string>,
): RowOptions => {
    const defaultOptions = {
        name: competitor.name,
        highlight: competitor.name === clientRanking.name,
        reviews: competitor.reviewCount,
        rating: competitor.rating,
        rank: competitor.rank,
        previousRank: previousRanking?.rank as number,
    };

    if (isCompetitorCentral(competitor)) {
        return {
            ...defaultOptions,
            subLabels: [
                {
                    label: `${competitor.apparitionPercentage}${t('appearance_rate_word')}`,
                    suffixIcon: ['fa-info-circle', IconPrefix.REGULAR] as IconElement,
                    suffixIconTooltip: 'tooltip_appearance_rate',
                },
            ],
            color: colorsMap[competitor.name],
        };
    }

    // Local
    return {
        ...defaultOptions,
        subLabels: [
            {
                label: competitor.address,
                prefixIcon: ['fa-map-marker', IconPrefix.SOLID] as IconElement,
            },
        ],
    };
};
