import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const LoadingRectangleContainer = styled.div<{ flexFill?: boolean }>`
    ${({ flexFill }) =>
        flexFill &&
        css`
            display: flex;
            flex: 1;
        `}
    background-color: ${greyColorObject.initial};
    animation: react-placeholder-pulse 1.5s infinite;
    border: none;
`;
