import { SmallStatProps } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/DefaultStatComponent';
import { Item } from 'app/pages/Homepage/utils/typing';

import { useStatMessagesItem } from './statsItems/messages/useStatMessagesItem';
import { useStatPresenceItem } from './statsItems/presence/useStatPresenceItem';
import { useStatReviewsItem } from './statsItems/reviews/useStatReviewsItem';

export const useAllStatsItems = (): Array<Item<SmallStatProps>> => {
    return [useStatPresenceItem(), useStatReviewsItem(), useStatMessagesItem()];
};
