import { useContext } from 'react';

import { ReviewObjectType } from 'app/api/types/review';

import { ReviewCardContext } from '../../sections/ReviewTableSection/ReviewCard/ReviewCard';

export const useShowReplyTemplatesButton = () => {
    const { review } = useContext(ReviewCardContext);

    return review.reviewObjectType === ReviewObjectType.REVIEW;
};
