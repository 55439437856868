import React from 'react';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { BusinessListQueryKeys } from 'app/pages/visibility/BusinessListV2/utils/enums';

import { SortTextClickable, SortTextContainer } from './BusinessSortText.styled';

type Props = {
    title: string;
    orderByKey?: OrderBy;
};

export enum OrderBy {
    NAME = 'name',
    COMPLETION_RATE = 'completion_rate',
}

const BusinessSortText = ({ title, orderByKey }: Props) => {
    const [orderBy, setOrderBy] = useStateQueryParams(BusinessListQueryKeys.ORDER_BY);

    const handleClick = () => {
        if (!orderByKey) {
            return;
        }

        if (orderBy === orderByKey) {
            setOrderBy(`-${orderByKey}`);
        } else if (orderBy === `-${orderByKey}`) {
            setOrderBy('');
        } else {
            setOrderBy(orderByKey);
        }
    };

    const orderIcon = (): React.ReactNode => {
        if (!orderByKey) {
            return null;
        }

        if (!orderBy || !orderBy.includes(orderByKey)) {
            return <i className="fa-sharp fa-solid fa-sort" />;
        }

        if (orderBy.startsWith('-')) {
            return <i className="fa-duotone fa-sort rotate_icon" />;
        }

        return <i className="fa-duotone fa-sort" />;
    };

    return (
        <SortTextContainer>
            <SortTextClickable clickable={!!orderByKey} onClick={handleClick}>
                {title}
                {orderIcon()}
            </SortTextClickable>
        </SortTextContainer>
    );
};

export default BusinessSortText;
