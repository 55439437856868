import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from '@partoohub/ui';

export const StyledReviewToggle = styled.div`
    grid-area: toggle;
    align-self: flex-start;
`;

export const ReviewToggleIcon = styled.div`
    margin-left: 8px;
`;

export const StyledIcon = styled(Icon)<{ onClick?: () => void }>`
    font-size: 18px;
    vertical-align: middle;
    position: relative;
    top: 1px;
    ${({ onClick }) =>
        !!onClick &&
        css`
            cursor: pointer;
        `};
`;
