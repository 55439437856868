import styled from '@emotion/styled';

export const ReviewAnalyticsLayoutStyled = styled.div<{ showTags: boolean }>`
    margin-bottom: 30px;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;

    ${({ showTags }) =>
        showTags
            ? `
        grid-template-rows: 200px minmax(230px, 1fr) minmax(190px, auto) auto;
        grid-template-areas:
            'A C'
            'B B'
            'E E'
            'D D';
    `
            : `
        grid-template-rows: 200px minmax(230px, 1fr) auto;
        grid-template-areas:
            'A C'
            'B B'
            'D D';
    `}

    @media screen and (max-width: 1140px) {
        ${({ showTags }) =>
            showTags
                ? `
            grid-template-rows: 230px minmax(230px, 1fr) minmax(190px, auto) auto;
        `
                : `
            grid-template-rows: 230px minmax(230px, 1fr) auto;
        `}
    }

    @media screen and (max-width: 1000px) {
        grid-template-columns: 100%;

        ${({ showTags }) =>
            showTags
                ? `
            grid-template-rows: auto auto minmax(230px, 1fr) auto auto;
            grid-template-areas:
                'A'
                'C'
                'B'
                'E'
                'D';
        `
                : `
            grid-template-areas:
                'A'
                'C'
                'B'
                'D';

            grid-template-rows: auto auto minmax(230px, 1fr) auto;
        `}
    }
`;

export const ReviewAnalyticsMyReviewsStyled = styled.div`
    grid-area: A;
`;

export const ReviewAnalyticsAverageGradeStyled = styled.div`
    grid-area: B;
    max-width: 100%;
    min-width: 0;
`;

export const ReviewAnalyticsAnswerRateStyled = styled.div`
    grid-area: C;
`;

export const ReviewAnalyticsTagsStyled = styled.div`
    grid-area: E;
    max-width: 100%;
`;

export const ReviewAnalyticsOverTimeStyled = styled.div`
    grid-area: D;

    @media screen and (max-width: 768px) {
        min-width: 400px;
    }

    @media screen and (max-width: 550px) {
        min-width: 290px;
    }
`;
