import styled from '@emotion/styled';

import { COLORS_FLOW } from 'app/styles/constants/colors_flow';

export const TableContainer = styled.div`
    box-shadow: 0px -12px 20px -18px ${COLORS_FLOW.shaded.black.transparent};
    th:not(:first-child) {
        div:first-child {
            justify-content: center;
        }
    }
`;
