import React, { ComponentType, FC, ReactNode } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import FlipAnimated from 'app/common/designSystem/components/atoms/FlipAnimated';
import {
    ChevronIcon,
    DisabledNavSectionTitleLink,
    NavSectionChevronLink,
    NavSectionContainer,
    NavSectionContent,
    NavSectionCounterContainer,
    NavSectionTitleContainer,
    NavSectionTitleLink,
    NavSectionTitleWrapper,
} from 'app/common/designSystem/components/atoms/NavSection/NavSection.styled';
import SidebarCounter from 'app/common/designSystem/components/atoms/SidebarCounter';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { useIsMobile } from 'app/common/services/screenDimensions';
import i18n from 'app/common/translations/i18n';
import { rotate90 } from 'app/styles';
import EASINGS from 'app/styles/constants/easings';

type DisabledNavSectionTitleLinkProps = {
    children: ReactNode;
    message?: string;
};

const DisabledNavSectionTitle: FC<DisabledNavSectionTitleLinkProps> = ({
    children,
    message = 'not_accessible_for_an_admin',
}) => (
    <TooltipWrapper text={i18n.t(message)} position="bottom-end">
        <DisabledNavSectionTitleLink data-cy="collapsible-sidebar-section-title">
            {children}
        </DisabledNavSectionTitleLink>
    </TooltipWrapper>
);

type NavSectionTitleProps = {
    children: ReactNode;
    to: string;
    path: string;
    chevron: boolean;
    active: boolean;
    disabled: boolean;
    replace: boolean;
    match: boolean;
    exact?: boolean;
    isSubNavSection?: boolean;
    dataTrack?: string;
};

const NavSectionTitle: FC<NavSectionTitleProps> = ({
    children,
    to,
    path,
    chevron,
    active,
    disabled,
    match,
    replace = false,
    exact = true,
    isSubNavSection = false,
    dataTrack,
}) => {
    const isMobile = useIsMobile();
    const isExternal = !!to.match(/^https?:\/\//g);
    const navProps = isExternal
        ? {
              href: to,
              target: '_blank',
              rel: 'noopener noreferrer',
          }
        : {
              to,
              replace,
          };

    return (
        <NavSectionTitleContainer>
            {!disabled ? (
                <NavSectionTitleLink
                    as={isExternal ? 'a' : NavLink}
                    // @ts-ignore
                    active={!isMobile && active}
                    bold={isSubNavSection ? match : true}
                    data-cy="collapsible-sidebar-section-title"
                    data-track={dataTrack}
                    data-intercom-target={dataTrack}
                    {...navProps}
                >
                    {children}
                </NavSectionTitleLink>
            ) : (
                <DisabledNavSectionTitle>{children}</DisabledNavSectionTitle>
            )}
            {!isMobile && !disabled && chevron && (
                <NavSectionChevronLink
                    to={exact ? to : path}
                    replace={replace}
                    end={exact}
                    css={active ? rotate90 : ''}
                >
                    <ChevronIcon />
                </NavSectionChevronLink>
            )}
        </NavSectionTitleContainer>
    );
};

type NavSectionProps = {
    path: string;
    to?: string;
    replace?: boolean;
    title: string;
    component?: ComponentType;
    disabled?: boolean;
    count?: number;
    shouldHideCounterOnMatch?: boolean;
    marginTop?: string;
    isSubNavSection?: boolean; // Use if inside NavSection
    isOverNavSection?: boolean; // Use if other NavSections are inside
    dataTrack?: string;
};

const NavSection: FC<NavSectionProps> = ({
    path,
    title,
    component,
    to,
    replace = false,
    disabled,
    count,
    shouldHideCounterOnMatch = false,
    marginTop,
    isSubNavSection = false,
    isOverNavSection = false,
    dataTrack,
}) => {
    const isMobile = useIsMobile();
    const { key, pathname } = useLocation();
    const match = pathname.includes(path);
    const nodeRef = React.useRef(null);

    // avoid render prop to avoid having a different prop reference on each render
    const Child = component;
    return (
        <FlipAnimated animationKey={key} easing={EASINGS.easeOutStrong} duration={300}>
            <NavSectionContainer
                data-cy="collapsible-sidebar-section"
                isSubNavSection={isSubNavSection}
                marginTop={marginTop}
            >
                <NavSectionTitleWrapper>
                    <NavSectionTitle
                        to={to || path}
                        replace={replace}
                        chevron={!!component}
                        active={!Child && !!match}
                        disabled={!!disabled}
                        exact={!isOverNavSection}
                        path={path}
                        isSubNavSection={isSubNavSection}
                        match={!!match}
                        dataTrack={dataTrack}
                    >
                        {/* @ts-ignore */}
                        {title}
                    </NavSectionTitle>
                    {!!count && count > 0 && (
                        <NavSectionCounterContainer
                            match={!!match}
                            shouldHideCounterOnMatch={shouldHideCounterOnMatch}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <SidebarCounter count={count} />
                        </NavSectionCounterContainer>
                    )}
                </NavSectionTitleWrapper>
                {!isMobile && !disabled && component && (
                    <CSSTransition
                        nodeRef={nodeRef}
                        in={!!match}
                        classNames="fade"
                        timeout={300}
                        unmountOnExit
                    >
                        <NavSectionContent ref={nodeRef} match={!!match}>
                            <Child />
                        </NavSectionContent>
                    </CSSTransition>
                )}
            </NavSectionContainer>
        </FlipAnimated>
    );
};

export default React.memo<NavSectionProps>(NavSection);
