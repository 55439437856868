import { SmallStatProps } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/DefaultStatComponent';
import { StatPresence } from 'app/pages/Homepage/sections/HomepageStats/stats/StatPresence';
import { Item } from 'app/pages/Homepage/utils/typing';

import { useIsAvailableStatPresenceItem } from './useIsAvailableStatPresenceItem';

export const useStatPresenceItem = (): Item<SmallStatProps> => {
    const isAvailable = useIsAvailableStatPresenceItem();

    return {
        order: 1,
        isAvailable: isAvailable,
        component: StatPresence,
        key: 'stat-presence',
    };
};
