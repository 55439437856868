import { Chart, ChartType, ScatterOptions } from '@partoohub/ui';

import { useReviewTagMetrics } from '../../../hooks/useMetrics';

export const TagsGraph = () => {
    const metrics = useReviewTagMetrics();

    if (!metrics) return undefined;

    const { data: rawData } = metrics;

    const data = rawData.map(d => {
        const x = Object.values(d.metrics.rating_distribution).reduce((sum, curr) => sum + curr, 0);
        const y = d.metrics.average_rating;
        return { x, y };
    });

    const xValue = data.map(sd => sd.x);
    const minReviewQuantity = !xValue.length ? 0 : Math.min(...xValue) - 0.001;
    const maxReviewQuantity = !xValue.length ? 1 : Math.max(...xValue) + 0.001;
    const middleReviewQuantity = (minReviewQuantity + maxReviewQuantity) * 0.5;

    const minStar = 1;
    const maxStar = 5;
    const middleStar = 3;

    const leftQuadrantsMiddleTextXValue = (minReviewQuantity + middleReviewQuantity) * 0.5;
    const rightQuadrantsMiddleTextXValue = (maxReviewQuantity + middleReviewQuantity) * 0.5;

    const leftTopQuadrantTextPosition = {
        xValue: leftQuadrantsMiddleTextXValue,
        yValue: maxStar + 0.2,
    };
    const rightTopQuadrantTextPosition = {
        xValue: rightQuadrantsMiddleTextXValue,
        yValue: maxStar + 0.2,
    };

    const leftBottomQuadrantTextPosition = {
        xValue: leftQuadrantsMiddleTextXValue,
        yValue: minStar - 0.2,
    };
    const rightBottomQuadrantTextPosition = {
        xValue: rightQuadrantsMiddleTextXValue,
        yValue: minStar - 0.2,
    };
    const leftXTextPosition = {
        xValue: minReviewQuantity,
        yValue: middleStar,
    };
    const rightXTextPosition = {
        xValue: maxReviewQuantity,
        yValue: middleStar,
    };
    const topYTextPosition = {
        xValue: middleReviewQuantity,
        yValue: maxStar + 0.2,
    };
    const bottomYTextPosition = {
        xValue: middleReviewQuantity,
        yValue: minStar - 0.2,
    };

    const rightTopData = data.filter(
        data =>
            (data.x >= middleReviewQuantity && data.y > middleStar) ||
            (data.x === middleReviewQuantity && data.y === middleStar),
    );
    const leftTopData = data.filter(data => data.x < middleReviewQuantity && data.y >= middleStar);
    const leftBottomData = data.filter(
        data => data.x <= middleReviewQuantity && data.y < middleStar,
    );
    const rightBottomData = data.filter(
        data => data.x > middleReviewQuantity && data.y <= middleStar,
    );

    const quadrants = {
        datasets: [
            {
                data: rightTopData,
                backgroundColor: 'rgb(44,184,132)',
            },
            {
                data: leftTopData,
                backgroundColor: 'rgb(0,133,242)',
            },
            {
                data: leftBottomData,
                backgroundColor: 'rgba(255, 144, 20, 1)',
            },
            {
                data: rightBottomData,
                backgroundColor: 'rgba(255, 66, 110, 1)',
            },
        ],
    };

    const options: ScatterOptions = {
        scales: {
            x: {
                type: 'linear',
                position: 'center',
                display: false,
                min: minReviewQuantity - (maxReviewQuantity - minReviewQuantity) * 0.05,
                max: maxReviewQuantity + (maxReviewQuantity - minReviewQuantity) * 0.05,
            },
            y: {
                type: 'linear',
                position: 'center',
                display: false,
                min: minStar - 0.5,
                max: maxStar + 0.5,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        xMin: middleReviewQuantity,
                        yMin: middleStar + 0.1,
                        xMax: middleReviewQuantity,
                        yMax: maxStar,
                        borderColor: 'rgba(20, 37, 66, 0.12)',
                        borderWidth: 1,
                    },
                    line2: {
                        type: 'line',
                        xMin: middleReviewQuantity,
                        yMin: minStar,
                        xMax: middleReviewQuantity,
                        yMax: middleStar - 0.1,
                        borderColor: 'rgba(20, 37, 66, 0.12)',
                        borderWidth: 1,
                    },
                    line3: {
                        type: 'line',
                        xMin: minReviewQuantity + (maxReviewQuantity - minReviewQuantity) * 0.02,
                        yMin: middleStar,
                        xMax: maxReviewQuantity - (maxReviewQuantity - minReviewQuantity) * 0.03,
                        yMax: middleStar,
                        borderColor: 'rgba(20, 37, 66, 0.12)',
                        borderWidth: 1,
                    },
                    label1: {
                        type: 'label',
                        color: 'rgba(127, 142, 164, 1)',
                        ...leftXTextPosition,
                        content: ['Rare'],
                        font: {
                            size: 12,
                        },
                    },
                    label2: {
                        type: 'label',
                        color: 'rgba(127, 142, 164, 1)',
                        ...rightXTextPosition,
                        content: ['Fréquent'],
                        font: {
                            size: 12,
                        },
                    },
                    label3: {
                        type: 'label',
                        color: 'rgba(127, 142, 164, 1)',
                        ...topYTextPosition,
                        content: ['5 étoiles'],
                        font: {
                            size: 12,
                        },
                    },
                    label4: {
                        type: 'label',
                        color: 'rgba(127, 142, 164, 1)',
                        ...bottomYTextPosition,
                        content: ['1 étoile'],
                        font: {
                            size: 12,
                        },
                    },
                    label5: {
                        type: 'label',
                        color: 'rgba(127, 142, 164, 1)',
                        ...leftTopQuadrantTextPosition,
                        content: ['Peu pertinents'],
                        font: {
                            size: 18,
                        },
                    },
                    label6: {
                        type: 'label',
                        color: 'rgba(127, 142, 164, 1)',
                        ...rightTopQuadrantTextPosition,
                        content: ['Points forts'],
                        font: {
                            size: 18,
                        },
                    },
                    label7: {
                        type: 'label',
                        color: 'rgba(127, 142, 164, 1)',
                        ...leftBottomQuadrantTextPosition,
                        content: ['A surveiller'],
                        font: {
                            size: 18,
                        },
                    },
                    label8: {
                        type: 'label',
                        color: 'rgba(127, 142, 164, 1)',
                        ...rightBottomQuadrantTextPosition,
                        content: ['Points faibles'],
                        font: {
                            size: 18,
                        },
                    },
                    background1: {
                        type: 'box',
                        borderWidth: 0,
                        xMin: minReviewQuantity,
                        xMax: middleReviewQuantity,
                        yMin: middleStar,
                        yMax: maxStar,
                        backgroundColor: 'rgba(0, 133, 242, 0.05)',
                    },
                    background2: {
                        type: 'box',
                        borderWidth: 0,
                        xMin: middleReviewQuantity,
                        xMax: maxReviewQuantity,
                        yMin: middleStar,
                        yMax: maxStar,
                        backgroundColor: 'rgba(64, 219, 162, 0.05)',
                    },
                    background3: {
                        type: 'box',
                        borderWidth: 0,
                        xMin: minReviewQuantity,
                        xMax: middleReviewQuantity,
                        yMin: minStar,
                        yMax: middleStar,
                        backgroundColor: 'rgba(255, 144, 20, 0.05)',
                    },
                    background4: {
                        type: 'box',
                        borderWidth: 0,
                        xMin: middleReviewQuantity,
                        xMax: maxReviewQuantity,
                        yMin: minStar,
                        yMax: middleStar,
                        backgroundColor: 'rgba(255, 66, 110, 0.05)',
                    },
                },
            },
        },
    };

    return <Chart dataTrackId="" data={quadrants} options={options} type={ChartType.Quadrant} />;
};
