import { Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { TipType } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/data/funnelSteps';
import { useFunnelModalContext } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/hooks/FunnelModalContext';
import { TipIcon } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/icons/TipIcon';

import { FunnelModalTips, Tip, TipDescription } from './FunnelModalTemplate.styled';

export const TipContainer = () => {
    const { t } = useTranslation();
    const {
        currentStepInfo: { tipTitle, tipDescription, tipType, tipText },
    } = useFunnelModalContext();

    return (
        <FunnelModalTips direction="column" gap="24px">
            <Text as="span" variant="heading6">
                {t(tipTitle)}
            </Text>
            <TipDescription as="span" variant="bodyLRegular" color="secondary">
                {t(tipDescription)}
            </TipDescription>
            <Tip direction="row" gap="16px" alignItems="center">
                <TipIcon type={tipType} />
                <Stack direction="column" gap="6px">
                    <Text as="span" variant="bodyMBold" color="primary">
                        {tipType == TipType.ADVICE ? t('our_advice') : t('to_know')}
                    </Text>
                    <Text as="span" variant="bodyMRegular" color="secondary">
                        {t(tipText)}
                    </Text>
                </Stack>
            </Tip>
        </FunnelModalTips>
    );
};
