import { useContext } from 'react';

import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { BusinessImportFieldsType } from 'app/api/types/business';
import ImportSection from 'app/businessConnection/components/genericQuickConnect/InfoSection/Extensions/GMB/ImportSection.container';
import QuickConnectContext from 'app/businessConnection/components/genericQuickConnect/QuickConnect.context';

import { InfoSectionExtensionGMBContainer } from './InfoSectionExtensionGMB.styled';

interface Props {
    businessImport: BusinessImportFieldsType;
}

export const InfoSectionExtensionGMB = ({ businessImport }: Props) => {
    const { t } = useTranslation();
    const { selectedSuggestions } = useContext(QuickConnectContext);
    const anyBusinessSelected = Object.values(businessImport.businessInfos).includes(true);
    return (
        <>
            {selectedSuggestions.length > 0 &&
                (businessImport.attributes || anyBusinessSelected) && (
                    <Text variant="bodyMBold" color="warning">
                        <Icon
                            icon={[
                                FontAwesomeIconsPartooUsed.faCircleExclamation,
                                IconPrefix.SOLID,
                            ]}
                            color="warning"
                            iconSize="12px"
                        />{' '}
                        {t('gmb_overwrite_information')}
                    </Text>
                )}

            <InfoSectionExtensionGMBContainer>
                <ImportSection />
            </InfoSectionExtensionGMBContainer>
        </>
    );
};
