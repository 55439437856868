import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import { UseConfirmModal } from 'app/common/components/ConfirmModalV2/useConfirmModal';
import { PermissionsSectionType } from 'app/pages/settingsV2/subPages/Team/components/Permissions/utils/typing';
import { checkboxSection } from 'app/pages/settingsV2/subPages/Team/components/Permissions/utils/utils';

type Props = {
    value: PermissionsSectionType;
    onChange: (value: PermissionsSectionType) => void;
    askConfirmation: UseConfirmModal<boolean>['askConfirmation'];
};

export const usePermissionsChange = ({ value, askConfirmation, onChange }: Props) => {
    const { t } = useTranslation();

    const checkboxValueGM = checkboxSection(value.fields, true);
    const checkboxValueBM = checkboxSection(value.fields, false);

    const confirmAndChange = async (
        newPermissions: PermissionsSectionType,
        confirmationRequired: boolean,
    ) => {
        onChange(newPermissions);
        if (confirmationRequired) {
            const confirmed = await askConfirmation({
                content: t('permission_reply_template_manage_confirm_modal_text'),
            });
            // If it is not confirmed, then revert the changes...
            if (!confirmed) onChange(value);
        }
    };

    const onSectionPermissionChange = (gm: boolean) => {
        let confirmationRequired = false;
        const result = cloneDeep(value);
        result.fields.forEach(field => {
            if (gm) {
                field.enabled_for_gm = checkboxValueGM === false;
                confirmationRequired ||=
                    field.name === 'review_create_reply_template' && field.enabled_for_gm;
            } else {
                field.enabled_for_bm = checkboxValueBM === false;
                confirmationRequired ||=
                    field.name === 'review_create_reply_template' && field.enabled_for_bm;
            }
        });

        confirmAndChange(result, confirmationRequired);
    };

    const onFieldPermissionChange = (fieldName: string, gm: boolean) => {
        let confirmationRequired = false;
        const result = cloneDeep(value);
        const field = result.fields.find(field => field.name === fieldName);

        // Update field first
        if (field) {
            if (gm) {
                field.enabled_for_gm = !field.enabled_for_gm;
                confirmationRequired =
                    field.name === 'review_create_reply_template' && field.enabled_for_gm;
            } else {
                field.enabled_for_bm = !field.enabled_for_bm;
                confirmationRequired =
                    field.name === 'review_create_reply_template' && field.enabled_for_bm;
            }
        }

        const conditionSecondary = gm ? field?.enabled_for_gm : field?.enabled_for_bm;

        // Enable secondary if logo or cover are enable
        if (
            value.name === 'photos' &&
            ['logo', 'cover'].includes(fieldName) &&
            conditionSecondary
        ) {
            const secondary = result.fields.find(field => field.name === 'secondary');
            if (secondary) {
                if (gm) {
                    secondary.enabled_for_gm = true;
                } else {
                    secondary.enabled_for_bm = true;
                }
            }
        }

        const conditionLogoCover = gm ? !field?.enabled_for_gm : !field?.enabled_for_bm;

        // Disable logo and primary if secondary is disabled
        if (value.name === 'photos' && fieldName === 'secondary' && conditionLogoCover) {
            const logo = result.fields.find(field => field.name === 'logo');
            const cover = result.fields.find(field => field.name === 'cover');
            if (logo && cover) {
                if (gm) {
                    logo.enabled_for_gm = false;
                    cover.enabled_for_gm = false;
                } else {
                    logo.enabled_for_bm = false;
                    cover.enabled_for_bm = false;
                }
            }
        }

        confirmAndChange(result, confirmationRequired);
    };

    return { onSectionPermissionChange, onFieldPermissionChange };
};
