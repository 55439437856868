// Enum
export const attributesEnumType = {
    BOOL: 'BOOL',
    URL: 'URL',
    REPEATABLE_URL: 'REPEATABLE_URL',
    ENUM: 'ENUM',
    REPEATABLE_ENUM: 'REPEATABLE_ENUM',
};

// Attributes
type AttributeOptionType = {
    name: string;
    id: string;
};

export type AttributeMetadataType = {
    type: string;
    gmb_id: string;
    name: string;
    order: number;
    options?: Array<AttributeOptionType>;
};

export type AttributeMetadataList = Array<AttributeMetadataType>;

type AttributesGroupsType = {
    attributes: AttributeMetadataList;
    name: string;
    order: number;
};

export type AttributesType = Array<AttributesGroupsType>;

export type AttributesGetParamsType = {
    category_name: string;
    country: string | null;
};

export type AttributesGetDataType = {
    groups: AttributesType;
};

export type BusinessAttributesGetParamsType = {
    category_name?: string;
    country?: string;
};

export type AttributeBusinessType = {
    gmb_id: string;
    type: string;
    value: Record<string, any>;
};

export type BusinessAttributesPostParamsType = {
    attributes: Array<AttributeBusinessType>;
};

export type BusinessAttributeEnumValueType = {
    set_values: Array<string>;
    unset_values: Array<string>;
};

export type BusinessAttributeType = {
    type: string;
    gmb_id: string;
    value:
        | boolean
        | string
        | Array<string>
        | BusinessAttributeEnumValueType
        | null;
};

export type BusinessAttributesType = Array<BusinessAttributeType>;

export type BusinessAttributesGetDataType = {
    attributes: BusinessAttributesType;
};
