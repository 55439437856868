/**
 *  NEW_REVIEW: new reviews and reviews that have been modified by the author
 *  REVIEW_UPDATED: reviews that have been through
 *   - the action of treatment (treated => not treated or not treated => treated)
 *   - any action linked to comments (creation, edition or deletion)
 *  REVIEW_DOWNLOAD_FAILED: when the download of reviews has failed
 */
export const NEW_REVIEW = 'NEW_REVIEW';
export const REVIEW_UPDATED = 'REVIEW_UPDATED';
export const REVIEW_DOWNLOAD_FAILED = 'REVIEW_DOWNLOAD_FAILED';

/**
 * Messaging Live Events
 */
export const NEW_CONVERSATION = 'NEW_CONVERSATION';
export const NEW_CONVERSATION_MESSAGE = 'NEW_CONVERSATION_MESSAGE';
export const NEW_MESSAGE_STATUS = 'NEW_MESSAGE_STATUS';
export const MESSAGE_DELETED = 'MESSAGE_DELETED';
export const MESSENGER_ONBOARDING_REPORT = 'MESSENGER_ONBOARDING_REPORT';
export const INSTAGRAM_ONBOARDING_REPORT = 'INSTAGRAM_ONBOARDING_REPORT';
export const WHATSAPP_ONBOARDING_REPORT = 'WHATSAPP_ONBOARDING_REPORT';
export const CONVERSATION_STATUS_CHANGE = 'CONVERSATION_STATUS_CHANGE';
export const CONVERSATION_ASSIGNMENT = 'CONVERSATION_ASSIGNMENT';
export const CONVERSATION_IS_AUTOMATED_CHANGE = 'CONVERSATION_IS_AUTOMATED_CHANGE';
export const CONVERSATION_HANDOVER = 'CONVERSATION_HANDOVER';
export const CONVERSATION_UPDATE = 'CONVERSATION_UPDATE';

/**
 * WebsocketTask Live Events
 */
export const TASK_UPDATE = 'TASK_UPDATE';

/**
 * Fetch GMB Photos Events
 */
export const GMB_PHOTOS_UPDATE_EVENT = 'GMB_PHOTOS_UPDATE';
