import React from 'react';

import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { BUSINESS_MANAGER, GROUP_MANAGER } from 'app/common/data/roles';

import { PermissionsRoleBannerContainer, TextBM, TextGM } from './PermissionsRoleBanner.styled';

const PermissionsRoleBanner = () => {
    const { t } = useTranslation();

    return (
        <PermissionsRoleBannerContainer>
            <Text variant="bodyXSSemibold" color="secondary" as="span">
                {t('business_fields_title')}
            </Text>

            <TextGM variant="bodyXSSemibold" color="secondary" as="span">
                {t(GROUP_MANAGER)}
            </TextGM>
            <TextBM variant="bodyXSSemibold" color="secondary" as="span">
                {t(BUSINESS_MANAGER)}
            </TextBM>
        </PermissionsRoleBannerContainer>
    );
};

export default PermissionsRoleBanner;
