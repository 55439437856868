import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const TemplateListHeader = styled(Stack)`
    margin-top: 8px;
`;

export const NoSearchResult = styled.div`
    margin: 25px 0;
`;
