import React from 'react';

import { useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import {
    PartnerStatusContainer,
    PartnerStatusCount,
    PartnerStatusIcon,
    PartnerStatusIconContainer,
} from 'app/common/designSystem/components/molecules/GooglePostCard/PartnerStatus.styled';

import { STATIC_BASE_URL } from 'app/config';

const mapStatusToIcon = {
    success: 'fas fa-circle-check',
    pending: 'fas fa-sync-alt',
    scheduled: 'fas fa-clock',
    to_configure: 'fas fa-exclamation-circle',
    expired: 'fas fa-circle-check',
    error: 'fas fa-times-circle',
};

const mapStatusToTooltip = {
    success: 'success_google_posts',
    pending: 'pending_google_posts',
    scheduled: 'scheduled_google_post',
    to_configure: 'to_configure_google_posts',
    expired: 'expired_google_posts',
    error: 'error',
};

type Props = {
    className?: string;
    showInsights: boolean;
    status: string;
    count?: number;
    clickCount?: number;
    partnerIcon?: string;
    disabled?: boolean;
    onPartnerStatusClick?: () => void;
    partner: string;
};

const PartnerStatus = ({
    className,
    showInsights,
    count,
    clickCount,
    partnerIcon,
    status,
    partner,
    disabled = false,
    onPartnerStatusClick,
}: Props) => {
    const { t } = useTranslation();
    const viewTooltip =
        partner === 'instagram__posts' ? 'post_reach' : 'google_post_views';
    const clickTooltip =
        partner === 'instagram__posts'
            ? 'post_interaction'
            : 'google_post_clicks';

    return (
        <PartnerStatusContainer className={className}>
            {partnerIcon && (
                <img
                    src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/${partnerIcon}.svg`}
                    alt={''}
                    className={disabled ? 'disabled' : ''}
                />
            )}

            {(status === 'success' || status === 'expired') && showInsights ? (
                <>
                    <TooltipWrapper
                        text={t(viewTooltip)}
                        className="tooltip-position__button"
                        position="bottom-start"
                    >
                        <PartnerStatusIconContainer>
                            <PartnerStatusIcon className="fa fa-eye" />
                            <PartnerStatusCount>{count}</PartnerStatusCount>
                        </PartnerStatusIconContainer>
                    </TooltipWrapper>

                    <TooltipWrapper
                        text={t(clickTooltip)}
                        className="tooltip-position__button"
                        position="bottom-start"
                    >
                        <PartnerStatusIconContainer>
                            <PartnerStatusIcon className="fa-regular fa-hand-pointer" />
                            <PartnerStatusCount>
                                {clickCount}
                            </PartnerStatusCount>
                        </PartnerStatusIconContainer>
                    </TooltipWrapper>
                </>
            ) : (
                <TooltipWrapper
                    text={t(mapStatusToTooltip[status])}
                    className="tooltip-position__button"
                    position="bottom-start"
                >
                    <PartnerStatusIconContainer>
                        <PartnerStatusIcon
                            className={mapStatusToIcon[status]}
                            status={status}
                            onClick={onPartnerStatusClick}
                        />
                    </PartnerStatusIconContainer>
                </TooltipWrapper>
            )}
        </PartnerStatusContainer>
    );
};

export default PartnerStatus;
