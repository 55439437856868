import LoadingView from 'app/common/components/LoadingView';
import NoBusiness from 'app/common/components/placeholder/NoBusiness.container';
import NotAccessibleForAdmin from 'app/common/components/placeholder/NotAccessibleForAdmin';
import { ADMIN } from 'app/common/data/roles';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import useMe from 'app/common/hooks/queries/useMeUncamel';

import { useIsLoadingMessaging } from 'app/pages/conversations/Messaging/hooks/useIsLoadingMessaging';
import MessagingComponent from 'app/reviewManagement/messaging/Messaging';

import { useConversationFiltersSetup } from './hooks/useConversationFiltersSetup';
import { MessagingProvider } from './MessagingContext';

export const Messaging = () => {
    const isLoading = useIsLoadingMessaging();
    const { data: me } = useMe();
    const { hasBusinesses, subscribedToBM } = useBusinessSubscriptions();
    const hasSetupConversationFilters = useConversationFiltersSetup();

    if (isLoading || !hasSetupConversationFilters) {
        return <LoadingView />;
    }

    if (subscribedToBM && me?.role === ADMIN) {
        return <NotAccessibleForAdmin />;
    }

    if (!hasBusinesses) {
        return <NoBusiness />;
    }

    return (
        <MessagingProvider>
            <MessagingComponent />
        </MessagingProvider>
    );
};
