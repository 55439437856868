import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba } from '@partoohub/branding';

import { Text } from '@partoohub/ui';

type PopoverMenuItemProps = {
    selected: boolean;
    triggerAnimation: boolean;
};

export const PopoverContainer = styled.div`
    position: absolute;
    width: 327px;
    max-height: 280px;
    right: 16px;
    bottom: 90px;
    background-color: ${HEX_COLORS.white};
    box-shadow: 0 5px 10px rgba(20, 37, 66, 0.16);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 20;
`;

export const PopoverHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 16px 8px 8px;
    position: relative;
    gap: 8px;
`;

export const PopoverSearchBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 46px;
    border-radius: 4px;
    position: relative;
    flex: 1;
`;

export const PopoverSearchBarPlaceholder = styled.div`
    position: absolute;
    top: -8px;
    left: 16px;
    font-size: 12px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary.initial};
    background-color: ${HEX_COLORS.white};
    padding: 0 2px;
`;

export const PopoverSearchBarInput = styled.input`
    height: 46px;
    padding: 0 40px 0 16px;
    min-width: 100%;
    box-sizing: border-box;
    display: block;

    border: 2px solid ${({ theme }) => theme.colors.primary.initial};
    border-radius: 4px;

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ theme }) => theme.colors.secondary.initial};
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ theme }) => theme.colors.secondary.initial};
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
`;

export const PopoverSearchBarIcon = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
`;

export const PopoverGearIcon = styled.div<{ contained: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    border-radius: 4px;
    height: 46px;
    width: 46px;
    cursor: pointer;

    &:hover {
        border: none;
        background-color: ${({ theme }) => convertToRgba(theme.colors.primary.initial, 0.08)};
        i {
            color: ${({ theme }) => theme.colors.primary.initial};
        }
    }

    ${({ contained, theme }) =>
        contained &&
        css`
            background-color: ${theme.colors.primary.initial};
            border: none;
            i {
                color: ${HEX_COLORS.white};
            }
            &:hover {
                background-color: ${theme.colors.primary.initial};
                i {
                    color: ${HEX_COLORS.white};
                }
            }
        `}
`;

export const PopoverSortingExplanation = styled(Text)`
    padding: 4px 0px 10px 16px;
`;

export const PopoverMenuList = styled.div`
    display: flex;
    overflow-y: auto;
    scrollbar-color: ${({ theme }) => theme.colors.default.alpha} ${HEX_COLORS.white};
    max-height: inherit;
    flex-direction: column;
    padding: 0 8px 8px;
`;

export const PopoverMenuItem = styled.div`
    padding: 12px 16px;
    border-radius: 4px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    i {
        opacity: 0;
    }

    ${(props: PopoverMenuItemProps) =>
        props.selected &&
        css`
            cursor: pointer;
            background: #f6f8fc;
            i {
                opacity: 1;
            }
        `}

    ${(props: PopoverMenuItemProps) =>
        props.triggerAnimation &&
        props.selected &&
        css`
            animation: blinkAnimation 0.2s;
            @keyframes blinkAnimation {
                0% {
                    background: #f6f8fc;
                }
                45% {
                    background: ${HEX_COLORS.white};
                }
                55% {
                    background: #f6f8fc;
                }
            }
        `}

    ${(props: PopoverMenuItemProps) =>
        props.triggerAnimation &&
        css`
            pointer-events: none;
        `}
`;

export const NoMatchesText = styled(Text)`
    padding: 24px 16px;
    text-align: center;
`;
