import styled from '@emotion/styled';

export const QuickConnectModalHeader = styled.div`
    margin-bottom: 40px;
`;

export const QuickConnectModalHeaderTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 30px;
    line-height: 35px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 18px;

    img {
        height: 24px;
        margin-right: 8px;
    }
`;

export const QuickConnectModalHeaderDescription = styled.div`
    color: #7f8ea4;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;

    i {
        margin-right: 8px;
    }
`;

export const QuickConnectModalFooter = styled.div`
    padding-top: 42px;
    padding-bottom: 24px;
`;

export const QuickConnectForm = styled.form`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 42px 42px 0 42px;
    overflow: auto;
`;
