import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const TagStatCard = styled.div`
    display: grid;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 16px rgba(20, 37, 66, 0.08);
    grid-template-areas:
        'A A'
        'B C';
    min-width: 175px;
`;

export const TagStatCardTitle = styled.div`
    font-size: 1.1em;
    font-weight: 600;
    grid-area: A;
    margin-bottom: 10px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: hidden;

    > span {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

export const TagStatCardLabel = styled.div`
    grid-area: B;

    .review_rating_stars {
        white-space: nowrap;
    }
`;

export const TagStatCardRating = styled.div<{ rating: number }>`
    grid-area: C;
    align-items: end;
    justify-content: right;
    display: flex;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;

    ${props => {
        let color;
        switch (true) {
            case props.rating >= 4:
                color = HEX_COLORS.success;
                break;
            case props.rating >= 3:
                color = HEX_COLORS.warning;
                break;
            default:
                color = HEX_COLORS.danger;
                break;
        }

        return color ? `color: ${color}` : '';
    }}
`;
