import { HEX_COLORS } from '@partoohub/branding';
import { Chart, ChartType } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { COLORS_FLOW } from 'app/styles/constants/colors_flow';

import { LINE_CHART_OPTIONS } from './common';
import { useReviewMetricsByDate } from '../../../hooks/useMetrics';

export const ReviewCountGraph = () => {
    const { t } = useTranslation();
    const metrics = useReviewMetricsByDate();

    if (!metrics) return undefined;

    const { data, previousData } = metrics;

    const getReviewCounts = (data: (typeof metrics)['data']) =>
        data.map(d =>
            Object.values(d.metrics.rating_distribution).reduce((sum, curr) => sum + curr, 0),
        );

    const datasets = [
        {
            backgroundColor: HEX_COLORS.primary,
            borderColor: HEX_COLORS.primary,
            data: getReviewCounts(data),
            label: t('review_analytics_graphs_selected_period'),
            pointBorderWidth: 2,
            pointRadius: data.length > 0 ? [...Array(data.length - 1).fill(0), 4] : [],
        },
    ];
    if (previousData) {
        datasets.push({
            backgroundColor: COLORS_FLOW.shaded.blackberry.transparent,
            borderColor: COLORS_FLOW.shaded.blackberry.transparent,
            data: getReviewCounts(previousData),
            label: t('review_analytics_graphs_previous_period'),
            pointBorderWidth: 2,
            pointRadius:
                previousData.length > 0 ? [...Array(previousData.length - 1).fill(0), 4] : [],
        });
    }

    return (
        <Chart
            dataTrackId=""
            type={ChartType.Line}
            options={LINE_CHART_OPTIONS}
            data={{
                datasets,
                labels: data.map((d, idx) => [d.dimension, previousData?.[idx]?.dimension ?? '']),
            }}
        />
    );
};
