import React from 'react';

import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';
import {
    KpiCard,
    KpiCardContent,
    KpiCardFooter,
    KpiCardHeader,
    KpiCardTitle,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/KpiCardLoader/KpiCardLoader.styled';

export const KpiCardLoader = () => (
    <KpiCard>
        <KpiCardHeader>
            <KpiCardTitle>
                <LoadingRectangle
                    recClass="kpi_card_icon--none"
                    recWidth="32px"
                    recHeight="32px"
                    recRadius="50%"
                />
                <LoadingRectangle
                    recWidth="80px"
                    recHeight="8px"
                    recRadius="2px"
                />
            </KpiCardTitle>
            <LoadingRectangle
                recClass="kpi_card_icon"
                recWidth="24px"
                recHeight="24px"
                recRadius="50%"
            />
        </KpiCardHeader>
        <KpiCardContent>
            <LoadingRectangle
                recWidth="200px"
                recHeight="40px"
                recRadius="2px"
                recClass="kpi_card_content--loader"
            />
            <LoadingRectangle
                recWidth="200px"
                recHeight="15px"
                recRadius="2px"
                recClass="kpi_card_content--loader"
            />
        </KpiCardContent>
        <KpiCardFooter>
            <LoadingRectangle
                recWidth="270px"
                recHeight="15px"
                recRadius="2px"
                recClass="kpi_card_footer--loader"
            />
            <LoadingRectangle
                recWidth="270px"
                recHeight="15px"
                recRadius="2px"
                recClass="kpi_card_footer--loader"
            />
            <LoadingRectangle
                recWidth="270px"
                recHeight="15px"
                recRadius="2px"
                recClass="kpi_card_footer--loader"
            />
        </KpiCardFooter>
    </KpiCard>
);
