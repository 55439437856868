import useDebounce from 'app/common/hooks/useDebounce';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

export const useGetQuery = () => {
    const [query] = useStateQueryParams('query');

    const debouncedQuery = useDebounce(query, 250);

    return {
        query: debouncedQuery,
    };
};
