import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const ConversationStarterItemCardContainer = styled.div`
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--blackberry-alpha, rgba(20, 37, 66, 0.12));
    background: var(--white-initial, #fff);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
`;

export const ConversationStarterItemCardContent = styled.div`
    gap: 4px;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const ConversationStarterItemCardReponse = styled(Text)`
    overflow: auto;
    word-break: break-word;
`;

export const IconListContainer = styled.div`
    display: flex;
    align-items: center;
`;
