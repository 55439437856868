import React, { createContext, useMemo, useState } from 'react';

import { BusinessSearchParams } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { ALL_STATUSES_EXCEPT_CLOSED } from 'app/common/data/businessStatuses';
import PostsListPage from 'app/presence/googlePosts/components/googlePostList/PostsList';

export const POSTS_SEARCH_PARAMS: BusinessSearchParams = {
    subscribed_to_pm: true,
    status: ALL_STATUSES_EXCEPT_CLOSED,
};

export const RefreshPostsContext = createContext<RefreshPostsContextType>({
    refreshPosts: false,
    setRefreshPosts: () => {},
});
export interface RefreshPostsContextType {
    refreshPosts: boolean;
    setRefreshPosts: React.Dispatch<React.SetStateAction<boolean>>;
}

const GooglePostsList = () => {
    const [refreshPosts, setRefreshPosts] = useState(false);
    const refreshPostsContext: RefreshPostsContextType = useMemo(
        () => ({
            refreshPosts,
            setRefreshPosts,
        }),
        [refreshPosts],
    );

    return (
        <RefreshPostsContext.Provider value={refreshPostsContext}>
            <PostsListPage />
        </RefreshPostsContext.Provider>
    );
};

export default GooglePostsList;
