import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { CONVERSATION_STARTERS } from 'app/common/data/queryKeysConstants';
import { formatPlaceholderToMention } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/services/utils';

export default function useConversationStarters() {
    const { t } = useTranslation();
    return useQuery(
        [CONVERSATION_STARTERS],
        () => api.conversationStarters.getConversationStarters(),
        {
            select: data => {
                return data.map(conversationStarter => ({
                    ...conversationStarter,
                    welcome_message: conversationStarter.welcome_message
                        ? formatPlaceholderToMention(conversationStarter.welcome_message, t)
                        : '',
                }));
            },
        },
    );
}
