import { FontAwesomeIconsPartooUsed, IconPrefix, Modal, Text } from '@partoohub/ui';
import { Trans, useTranslation } from 'react-i18next';

import { STATIC_APP_ASSETS_URL } from 'app/config';

import { StyledCloseButton } from './ActionModal.styled';
import {
    StyledModalBody,
    StyledModalImage,
    StyledTextContent,
    StyledTitle,
} from './InstructionModal.styled';

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

const actionKeys = [
    'prepare_data_action_do_nothing',
    'prepare_data_action_create',
    'prepare_data_action_update',
    'prepare_data_action_delete',
];

export const InstructionModal = ({ isOpen, onClose }: Props) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={isOpen} closeModal={onClose}>
            <StyledModalBody>
                <StyledModalImage
                    src={`${STATIC_APP_ASSETS_URL}/reviews/bulk-template-instruction.png`}
                    alt={t('prepare_data_title')}
                />
                <StyledTextContent>
                    <StyledTitle variant="heading3" as="span">
                        {t('prepare_data_title')}
                    </StyledTitle>
                    <div>
                        <Text variant="bodyMRegular" as="span" color="secondary">
                            {t('prepare_data_action_first_line')}
                        </Text>
                        <ul>
                            {actionKeys.map(key => (
                                <li key={key}>
                                    <Text variant="bodyMRegular" as="span" color="secondary">
                                        <Trans i18nKey={key} components={{ strong: <strong /> }} />
                                    </Text>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <Text variant="bodyMRegular" as="span" color="secondary" italic>
                        {t('prepare_data_warning')}
                    </Text>
                </StyledTextContent>
            </StyledModalBody>
            <StyledCloseButton
                dataTrackId="export_modal_close_button"
                icon={[FontAwesomeIconsPartooUsed.faClose, IconPrefix.REGULAR]}
                onClick={onClose}
            />
        </Modal>
    );
};
