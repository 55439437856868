import { useState } from 'react';

import { Checkbox, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import Clickable from 'app/common/components/buttons/Clickable';
import MarginWrapper from 'app/common/designSystem/components/atoms/MarginWrapper';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { isJsonParsable } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/services';

interface Props {
    imageIndex: number | null;
    possibleValues: Array<string>;
    possibleImagesLabels: Array<string>;
    selectedOptions: Array<string>;
    onItemClick: (arg0: string) => void;
    disabled: boolean;
}

const MultipleSelectImageRightChild = ({
    imageIndex,
    possibleValues,
    possibleImagesLabels,
    selectedOptions,
    onItemClick,
    disabled,
}: Props) => {
    const { t } = useTranslation();
    const [showDetails, setShowDetails] = useState(false);
    const checked =
        imageIndex !== null ? selectedOptions.includes(possibleValues[imageIndex]) : false;
    let modifier = '--disabled';
    if (!disabled) modifier = checked ? '--selected' : '--unselected';

    return (
        <div className="multiple-select-image-info-body">
            <div className="image-info-label-container">
                <i className="fa fa-image" />
                <div className="image-info-label">
                    <div className="font-small-bold">{t('label')}</div>
                    <Text variant="bodyMBold">
                        {imageIndex !== null ? possibleImagesLabels[imageIndex] : ''}
                    </Text>
                </div>
            </div>
            <TooltipWrapper text={disabled ? t('field_read_only') : ''} position="bottom">
                <Clickable
                    className={`
                        image-info-add_to_selection_button
                        image-info-add_to_selection_button${modifier}
                    `}
                    onClick={() => {
                        if (imageIndex !== null && !disabled) {
                            onItemClick(possibleValues[imageIndex]);
                        }
                    }}
                >
                    <Text variant="heading6">{t('add_to_selection')}</Text>
                    <MarginWrapper right="4px">
                        <Checkbox
                            dataTrackId="multiple_select_image_right__checkbox"
                            aria-label=""
                            checked={checked}
                            onChange={() => undefined}
                            disabled={disabled}
                        />
                    </MarginWrapper>
                </Clickable>
            </TooltipWrapper>
            <div className="image-info-section">
                <Clickable
                    className="font-button image-info-section-title"
                    onClick={() => setShowDetails(!showDetails)}
                >
                    {showDetails ? t('hide_details') : t('see_details')}
                </Clickable>
                {showDetails && (
                    <div className="image-info-section-content">
                        <div className="image-info-section-content-info">
                            {imageIndex != null && isJsonParsable(possibleValues[imageIndex]) ? (
                                Object.entries(JSON.parse(possibleValues[imageIndex])).map(
                                    ([key, value]) => (
                                        <div
                                            key={key}
                                            className="image-info-section-content-info-json"
                                        >
                                            <div className="image-info-section-content-info-json-label">
                                                {key}
                                            </div>
                                            <div className="image-info-section-content-info-json-data">
                                                {typeof value === 'object'
                                                    ? JSON.stringify(value)
                                                    : String(value)}
                                            </div>
                                        </div>
                                    ),
                                )
                            ) : (
                                <div className="font-regular image-info-section-content-info-data">
                                    {imageIndex !== null ? possibleValues[imageIndex] : ''}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MultipleSelectImageRightChild;
