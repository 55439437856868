import styled from '@emotion/styled';

export const DropdownMenuContainer = styled.div`
    padding-left: 24px;
    position: relative;

    > div {
        position: unset;
    }
`;
