import { TFunction } from 'i18next';

import {
    AnswerMessage,
    ButtonMessageContent,
    Conversation,
    ConversationStarterMessageContent,
    MESSAGE_CONTENT_TYPE,
    MESSAGE_SENDER_TYPE,
    MessagingPartnerEnum,
    QuickRepliesMessageContent,
    UnsupportedContent,
} from 'app/states/messaging';

import { getUnsupportedMessage } from './getUnsupportedMessage';

export const lastMessageContent = (conversation: Conversation, t: TFunction) => {
    if (conversation.last_message?.is_deleted) {
        return t('messaging_conversation_message_deleted');
    }
    const consumerName =
        conversation.consumer_name || t('messaging_conversation_consumer_name_unknown');
    const isConsumer = conversation.last_message?.sender_type === MESSAGE_SENDER_TYPE.CONSUMER;
    switch (conversation.last_message?.content_type) {
        case MESSAGE_CONTENT_TYPE.IMAGE:
            return conversation.last_message?.sender_type !== MESSAGE_SENDER_TYPE.CONSUMER
                ? t('preview_sentence_client_image_sent')
                : t('messaging_conversation_last_message_image', {
                      consumerName,
                  });
        case MESSAGE_CONTENT_TYPE.TEXT: {
            if (conversation.messaging_partner === MessagingPartnerEnum.DELIGHT) {
                return t(conversation.last_message?.content);
            }
            return isConsumer
                ? `${conversation.last_message?.content}`
                : `${t(
                      'messages_conv_preview_latest_message_sent_by_user',
                  )} ${conversation.last_message?.content}`;
        }
        case MESSAGE_CONTENT_TYPE.ANSWER: {
            const answerContent = conversation.last_message?.content as AnswerMessage;
            return isConsumer
                ? `${answerContent.text}`
                : `${t('messages_conv_preview_latest_message_sent_by_user')} ${answerContent.text}`;
        }
        case MESSAGE_CONTENT_TYPE.QUICK_REPLIES: {
            const quickRepliesContent = conversation.last_message
                ?.content as QuickRepliesMessageContent;
            return isConsumer
                ? `${quickRepliesContent.text}`
                : `${t('messages_conv_preview_latest_message_sent_by_user')} ${
                      quickRepliesContent.text
                  }`;
        }
        case MESSAGE_CONTENT_TYPE.BUTTONS: {
            const buttonsContent = conversation.last_message?.content as ButtonMessageContent;
            return isConsumer
                ? `${buttonsContent.text}`
                : `${t('messages_conv_preview_latest_message_sent_by_user')} ${
                      buttonsContent.text
                  }`;
        }
        case MESSAGE_CONTENT_TYPE.CONVERSATION_STARTER: {
            const conversationStarterContent = conversation.last_message
                ?.content as ConversationStarterMessageContent;
            return conversationStarterContent.text
                ? `${t('messages_conv_preview_latest_message_sent_by_user')} ${
                      conversationStarterContent.text
                  }`
                : `${t('messages_conv_preview_latest_message_sent_by_user')} ${
                      conversationStarterContent.buttons[0].title
                  }`;
        }
        case MESSAGE_CONTENT_TYPE.IG_STORY_MENTION: {
            return isConsumer
                ? t('messaging_meta_story_mention', { name: consumerName })
                : t('messaging_meta_story_mention_echo', {
                      name: consumerName,
                  });
        }
        case MESSAGE_CONTENT_TYPE.IG_STORY_REPLY:
            return isConsumer
                ? t('messaging_meta_story_reply', { name: consumerName })
                : t('messaging_meta_story_reply_echo', {
                      name: consumerName,
                  });
        case MESSAGE_CONTENT_TYPE.UNSUPPORTED:
            return getUnsupportedMessage(
                conversation.last_message.content as UnsupportedContent,
                isConsumer,
                consumerName,
                t,
            );

        default:
            return null;
    }
};
