import { upperFirst } from 'lodash-es';

import {
    SET_LOCATION_ID,
    SetLocationIdAction,
    UNSET_BUSINESS_LOCATION_ID,
    UnsetLocationIdAction,
} from 'app/common/reducers/newBusinesses';
import {
    LOGOUT_RESET_REDUCERS_DATA,
    LogoutResetReducersDataAction,
} from 'app/states/connection/reducers/login';
import {
    ALL_TAB,
    NavValue,
    TO_CONNECT_TO_FACEBOOK,
    TO_CONNECT_TO_GMB,
} from 'app/states/knowledge/reducers/businessConnection/nav';

// ACTION TYPES
export const GET_CONNECTIONS_STATS_REQUEST = 'GET_CONNECTIONS_STATS_REQUEST';
const GET_CONNECTIONS_STATS_REQUEST_SUCCESS = 'GET_CONNECTIONS_STATS_SUCCESS';
const GET_CONNECTIONS_STATS_REQUEST_FAILURE = 'GET_CONNECTIONS_STATS_FAILURE';

// ACTION FLOW TYPES
export type GetConnectionsStatsAction = {
    type: 'GET_CONNECTIONS_STATS_REQUEST';
    params?: Record<string, string>;
};
type GetConnectionsStatsSuccessAction = {
    type: 'GET_CONNECTIONS_STATS_SUCCESS';
    count: number;
    linkedToGmbCount: number;
    linkedToFacebookCount: number;
};
type GetConnectionsStatsFailureAction = {
    type: 'GET_CONNECTIONS_STATS_FAILURE';
    error: Object;
};

export type ConnectionsStatsState = {
    isFetching: boolean;
    count: number;
    linkedToGoogleCount: number;
    linkedToFacebookCount: number;
    error: Object;
};

type ConnectionsStatsAction =
    | GetConnectionsStatsAction
    | GetConnectionsStatsSuccessAction
    | GetConnectionsStatsFailureAction
    | SetLocationIdAction
    | UnsetLocationIdAction
    | LogoutResetReducersDataAction;

// ACTION CREATORS
export const getConnectionsStats = (
    params: Record<string, string> = {},
): GetConnectionsStatsAction => ({
    type: GET_CONNECTIONS_STATS_REQUEST,
    params,
});

export const getConnectionsStatsSuccess = (
    count: number,
    linkedToGmbCount: number,
    linkedToFacebookCount: number,
): GetConnectionsStatsSuccessAction => ({
    type: GET_CONNECTIONS_STATS_REQUEST_SUCCESS,
    count,
    linkedToGmbCount,
    linkedToFacebookCount,
});
export const getConnectionsStatsFailure = (
    error: Object,
): GetConnectionsStatsFailureAction => ({
    type: GET_CONNECTIONS_STATS_REQUEST_FAILURE,
    error,
});

// INITIAL STATES
const initialState = {
    isFetching: false,
    count: 0,
    linkedToGoogleCount: 0,
    linkedToFacebookCount: 0,
    error: {},
};

// REDUCER
const connectionsStatsReducer = (
    state: ConnectionsStatsState = initialState,
    action: ConnectionsStatsAction,
): ConnectionsStatsState => {
    switch (action.type) {
        case GET_CONNECTIONS_STATS_REQUEST:
            return { ...state, isFetching: true, error: {} };

        case GET_CONNECTIONS_STATS_REQUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                count: action.count,
                linkedToGoogleCount: action.linkedToGmbCount,
                linkedToFacebookCount: action.linkedToFacebookCount,
            };

        case GET_CONNECTIONS_STATS_REQUEST_FAILURE:
            return { ...state, isFetching: false, error: action.error };

        case SET_LOCATION_ID:
            return {
                ...state,
                [`linkedTo${upperFirst(action.partnerName)}Count`]:
                    state[`linkedTo${upperFirst(action.partnerName)}Count`] + 1,
            };

        case UNSET_BUSINESS_LOCATION_ID:
            return {
                ...state,
                [`linkedTo${upperFirst(action.partnerName)}Count`]:
                    state[`linkedTo${upperFirst(action.partnerName)}Count`] - 1,
            };

        case LOGOUT_RESET_REDUCERS_DATA:
            return { ...initialState };

        default:
            return state;
    }
};

// SELECTORS
export const linkedToGmbCountSelector = (
    state: ConnectionsStatsState,
): number => state.linkedToGoogleCount;

export const linkedToFacebookCountSelector = (
    state: ConnectionsStatsState,
): number => state.linkedToFacebookCount;

export const toLinkToGmbCountSelector = (
    state: ConnectionsStatsState,
): number => Math.max(state.count - state.linkedToGoogleCount, 0);

export const toLinkToFacebookCountSelector = (
    state: ConnectionsStatsState,
): number => Math.max(state.count - state.linkedToFacebookCount, 0);

export const countSelector = (state: ConnectionsStatsState): number =>
    state.count;

export const countByNavSelector = (
    tab: NavValue,
    state: ConnectionsStatsState,
) => {
    switch (tab) {
        case ALL_TAB:
            return countSelector(state);
        case TO_CONNECT_TO_GMB:
            return toLinkToGmbCountSelector(state);
        case TO_CONNECT_TO_FACEBOOK:
            return toLinkToFacebookCountSelector(state);
        default:
            return countSelector(state);
    }
};

export const isFetchingSelector = (state: ConnectionsStatsState): boolean =>
    state.isFetching;

export default connectionsStatsReducer;
