import { useMutation } from 'react-query';

import { FormattedUserData, UserReceivedData } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';

interface Options {
    onSuccess?: (data: UserReceivedData) => void;
    onError?: (err: Record<string, any>) => void;
}

export default function useCreateUser(options?: Options) {
    return useMutation((payload: FormattedUserData) => api.user.createUser(payload), options);
}
