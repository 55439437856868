import React, { FC } from 'react';

import { Route, Routes } from 'react-router-dom';

import { CUSTOMER_EXPERIENCE_PATH, VISIBILITY_PATH } from 'app/routing/routeIds';

import { CollapsibleContainer, PositionAbsoluteContainer } from './CollapsibleSidebar.styled';
import { CustomerExperience } from './Products/CustomerExperience';
import Visibility from './Products/Visibility';

/**
 *  Having a different component for Routes, since React Router Routes are not memoized
 *  (they re-render and change the reference on the `match` prop each time their parent
 *  component renders, even when the url doesn't change).
 */
const CollapsibleSidebarRoutes: FC = () => (
    <Routes>
        <Route path={`${VISIBILITY_PATH}/*`} element={<Visibility />} />
        <Route path={`${CUSTOMER_EXPERIENCE_PATH}/*`} element={<CustomerExperience />} />
    </Routes>
);

const MemoizedCollapsibleSidebarRoutes = React.memo(CollapsibleSidebarRoutes);

type CollapsibleSidebarProps = {
    sidebarCollapsed: boolean;
};

const CollapsibleSidebar: FC<CollapsibleSidebarProps> = ({ sidebarCollapsed }) => {
    return (
        <CollapsibleContainer collapsed={sidebarCollapsed} data-cy="collapsible-sidebar">
            <PositionAbsoluteContainer>
                <MemoizedCollapsibleSidebarRoutes />
            </PositionAbsoluteContainer>
        </CollapsibleContainer>
    );
};

export default CollapsibleSidebar;
