import { Icon, IconPrefix, Text } from '@partoohub/ui';

import { StatChipContainer } from './StatChip.styled';

export enum ChipColor {
    GREEN = 'success',
    GREY = 'secondary',
    RED = 'danger',
}

export enum ChipEvolution {
    INCREASE = 'increase',
    DECREASE = 'decrease',
    NONE = 'none',
}

type Props = {
    text: string;
    chipColor?: ChipColor;
    chipEvolution?: ChipEvolution;
};

export const StatChip = ({
    text,
    chipColor = ChipColor.GREY,
    chipEvolution = ChipEvolution.NONE,
}: Props) => {
    return (
        <StatChipContainer chipColor={chipColor}>
            {chipEvolution !== ChipEvolution.NONE && (
                <Icon
                    icon={[
                        chipEvolution === ChipEvolution.INCREASE
                            ? 'fa-arrow-trend-up'
                            : 'fa-arrow-trend-down',
                        IconPrefix.REGULAR,
                    ]}
                    color={chipColor}
                    iconSize="10px"
                />
            )}
            <Text variant="bodySRegular" color={chipColor}>
                {text}
            </Text>
        </StatChipContainer>
    );
};
