import { useQuery } from 'react-query';

import ReviewAutoReply from 'app/api/v2/api_calls/reviewAutoReplyApiCalls';
import { REVIEW_AUTO_REPLY_SETTINGS } from 'app/common/data/queryKeysConstants';

const useLoadSettings = (forReviewsWithComment: boolean) => {
    const query = useQuery(
        [REVIEW_AUTO_REPLY_SETTINGS, forReviewsWithComment],
        () => ReviewAutoReply.getSettings(forReviewsWithComment),
    );
    return [query.data?.review_auto_reply_settings?.[0], query] as const;
};

export default useLoadSettings;
