import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

import { PublisherStateColors } from 'app/presence/management/types/Publishers.types';

export const DoughnutLegendRowContainer = styled(Stack)`
    &:not(:last-child) {
        margin-bottom: 8px;
    }
`;

export const DoughnutLegendRowTitle = styled(Stack)``;

export const BadgeCount = styled.div<{ badgeColor?: PublisherStateColors }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    border-radius: ${({ theme }) => theme.radius.rounded};
    background-color: ${({ theme, badgeColor }) => theme.colors[badgeColor ?? 'primary'].alpha};
`;
