import { useState } from 'react';

import { REVIEW_PERMISSION_ASSIGN_TAG } from 'app/api/types/review';

import useReviewPermissions, {
    useHasReviewPermission,
} from 'app/reviewManagement/reviewList/hooks/useReviewPermissions';
import AddTagButton from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/TagSection/AddTagButton';

import TagList from './TagList';

import { TagListContainer, TagListSection } from './TagSection.styled';

export default function TagSection() {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { isFetched } = useReviewPermissions();
    const hasAssignTagPermission = useHasReviewPermission(REVIEW_PERMISSION_ASSIGN_TAG);

    return (
        <TagListContainer>
            <TagListSection>
                {isFetched && <TagList setIsOpen={setIsOpen} collapsedList />}
            </TagListSection>
            {hasAssignTagPermission && <AddTagButton setIsOpen={setIsOpen} isOpen={isOpen} />}
        </TagListContainer>
    );
}
