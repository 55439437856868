import React, { MouseEventHandler } from 'react';

import { Color } from 'app/common/designSystem/constants/color';

import { ButtonWrapper } from './TextButton.styled';

type Props = {
    text: React.ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    color?: Color;
    icon?: JSX.Element;
    iconPositionRight?: boolean;
    disabled?: boolean;
};

const TextButton = ({
    text,
    onClick,
    color = 'primary',
    icon = undefined,
    iconPositionRight = false,
    disabled = false,
}: Props) => {
    return (
        <ButtonWrapper
            color={color}
            onClick={onClick}
            iconPositionRight={iconPositionRight}
            disabled={disabled}
        >
            {!iconPositionRight && icon}
            {text}
            {iconPositionRight && icon}
        </ButtonWrapper>
    );
};

export default TextButton;
