import { STATIC_BASE_URL } from 'app/config';

export const GOOGLE = 'google';
export const FACEBOOK = 'facebook';
export const INSTAGRAM = 'instagram';
export const PLATFORMS = [GOOGLE, INSTAGRAM, FACEBOOK] as const;

export type Platform = (typeof PLATFORMS)[number];

export const PLATFORMS_IMAGE = {
    [GOOGLE]: `${STATIC_BASE_URL}/images/common/partners_sprite/circle/google_white_background_without_margins.svg`,
    [FACEBOOK]: `${STATIC_BASE_URL}/images/common/partners_sprite/circle/facebook.svg`,
    [INSTAGRAM]: `${STATIC_BASE_URL}/images/common/partners_sprite/circle/instagram_logo.svg`,
};

export const PLATFORMS_ACCEPTED_PHOTOS_EXTENSIONS = {
    [FACEBOOK]: ['jpeg', 'jpg', 'png', 'gif'],
    [GOOGLE]: ['jpeg', 'jpg', 'png'],
    [INSTAGRAM]: ['jpeg', 'jpg'],
};

export const PLATFORM_CONNECTION = {
    [GOOGLE]: 'linkedToGmbCount',
    [FACEBOOK]: 'linkedToFacebookCount',
    [INSTAGRAM]: 'linkedToFacebookCount', // Todo: Use proper count for instagram
};

export const POST_TYPES_CREATABLE = ['news', 'offer', 'event'];

export const POST_STATES_TO_HIDE = ['pending_deleted'];

export const TYPE_ICONS: { [key: string]: string } = {
    news: 'certificate',
    offer: 'tag',
    event: 'calendar-day',
    covid: 'first-aid',
};

type PlatformCriteria = {
    description: string;
    value: string;
    icon?: string;
    imgIcon?: string;
};

const GOOGLE_CRITERIA: Array<PlatformCriteria> = [
    {
        icon: 'fa-light fa-weight-hanging',
        description: 'post_image_criteria_max_weight',
        value: '5MB',
    },
    // `imgIcon` is a temporary solution for svg based icon
    {
        icon: '',
        description: 'post_image_criteria_reco_size',
        value: '600 × 300px',
        imgIcon: 'reco-size',
    },
    {
        icon: '',
        description: 'post_image_criteria_min_size',
        value: '250 × 250px',
        imgIcon: 'min-size',
    },
    {
        icon: '',
        description: 'post_image_criteria_max_size',
        value: '10000 × 10000px',
        imgIcon: 'min-size',
    },
];

const FACEBOOK_CRITERIA: Array<PlatformCriteria> = [
    {
        icon: 'fa-light fa-weight-hanging',
        description: 'post_image_criteria_max_weight',
        value: '10MB',
    },
];

const INSTAGRAM_CRITERIA: Array<PlatformCriteria> = [
    {
        icon: 'fa-light fa-weight-hanging',
        description: 'post_image_criteria_max_weight',
        value: '5MB',
    },
];

export const PLATFORMS_IMAGE_CRITERIA = {
    GOOGLE: GOOGLE_CRITERIA,
    FACEBOOK: FACEBOOK_CRITERIA,
    INSTAGRAM: INSTAGRAM_CRITERIA,
};
