import React from 'react';

import Loader from 'app/common/designSystem/components/atoms/svg/Loader';

import { ContentLoaderContainer } from './ContentLoader.styled';

type Props = {
    noStroke?: boolean;
};

export const ContentLoader = ({ noStroke = false }: Props) => (
    <ContentLoaderContainer>
        <Loader noStroke={noStroke} />
    </ContentLoaderContainer>
);
