import { useQuery } from 'react-query';

import { PublisherRawData } from 'app/api/types/publisher';
import publisherApiCalls from 'app/api/v2/api_calls/publisherApiCalls';
import { PUBLISHERS } from 'app/common/data/queryKeysConstants';

const shouldIncludePublisher = (publisher: PublisherRawData) => {
    return !publisher.hidden_by_default || publisher.status === 'unavailable';
};

export const usePublishers = () => {
    const { data, ...rest } = useQuery([PUBLISHERS], () =>
        publisherApiCalls.getPublishersForUser(),
    );

    const filteredPublishers = data?.filter(shouldIncludePublisher);

    /*
        TODO: BIRD temporary fix to allow a publisher to be named Apple, 
        issue is there an existing publisher named Apple (but disabled)
        that could lead to a lot of conflicts on the back end,
        hence why it's like this for now
     */
    const replacedAppleBusinessConnectNamePublishers = filteredPublishers?.map(publisher => {
        return publisher.name === 'apple_business_connect'
            ? { ...publisher, formatted_name: 'Apple' }
            : publisher;
    });

    return {
        data: replacedAppleBusinessConnectNamePublishers,
        ...rest,
    };
};
