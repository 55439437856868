import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { CUSTOM_FIELDS, ORG_BUSINESS_EDIT_FIELDS } from 'app/common/data/queryKeysConstants';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export default function useOrgPermissions(orgId?: number) {
    const { data: me } = useMe();
    const orgIdToUse = orgId ?? me?.org_id;

    return useQuery(
        [ORG_BUSINESS_EDIT_FIELDS, CUSTOM_FIELDS, { orgId: orgIdToUse }],
        () => api.org.getBusinessEditFields(orgIdToUse ?? 0),
        {
            enabled: !!orgIdToUse,
        },
    );
}
