import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationVariousElement,
    IllustrationVariousEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useHomepageMessagingStats } from 'app/pages/Homepage/hooks/stats/statsItems/messages/useHomepageMessagingStats';
import { DefaultTodoComponent } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent';
import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import { CONVERSATIONS_MESSAGING_PATH } from 'app/routing/routeIds';

export const TodoMessages = (props: SmallTodoProps) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { data, isLoading } = useHomepageMessagingStats(6, 0);

    const unansweredConversations = data?.unansweredConversations ?? 0;

    const illustrationElement: IllustrationVariousElement = {
        type: IllustrationCategoryEnum.Various,
        name: unansweredConversations
            ? IllustrationVariousEnum.Messages
            : IllustrationVariousEnum.MessagesTreated,
    };

    return (
        <DefaultTodoComponent
            {...props}
            {...(unansweredConversations
                ? {
                      title: `${t('homepage_todo_messages_unanswered_title', {
                          count: unansweredConversations,
                      })}`,
                      actionText: `${t('homepage_todo_unanswered_description')}`,
                  }
                : {
                      title: `${t('homepage_todo_messages_answered_title')}`,
                      description: `${t('homepage_todo_messages_answered_description')}`,
                  })}
            icon={
                <Illustration
                    dataTrackId="homepage_messages__illustration"
                    illustration={illustrationElement}
                    height="98"
                    width="120"
                />
            }
            isLoading={isLoading}
            onClick={() => navigate(CONVERSATIONS_MESSAGING_PATH)}
        />
    );
};
