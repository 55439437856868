import { DatePickerType, RangeDate } from '@partoohub/ui';
import { addYears, endOfYear } from 'date-fns';

import { useTranslation } from 'react-i18next';

import { LocaleDatePicker } from 'app/common/components/LocaleDatePicker/LocaleDatePicker';
import { useDateFromFilter } from 'app/presence/googlePosts/hooks/filters/useDateFromFilter';
import { useDateToFilter } from 'app/presence/googlePosts/hooks/filters/useDateToFilter';

export function DateFilter({ isMobile }: { isMobile?: boolean }) {
    const { t } = useTranslation();
    const [from, setFrom] = useDateFromFilter();
    const [to, setTo] = useDateToFilter();

    const rangeDate: RangeDate = [from ?? null, to ?? null];

    const onChange = (newRange: RangeDate) => {
        const startDate = newRange[0] ? new Date(newRange[0]) : null;
        const endDate = newRange[1] ? new Date(newRange[1]) : null;
        setFrom(startDate);
        setTo(endDate);
    };

    return (
        <LocaleDatePicker
            type={DatePickerType.DateRangePicker}
            dataTrackId="post_filter_date_range"
            dates={rangeDate}
            onChange={onChange}
            labels={{ startDate: t('start_date'), endDate: t('end_date') }}
            placeholders={{
                startDate: t('date_placeholder'),
                endDate: t('date_placeholder'),
            }}
            maxDate={endOfYear(addYears(new Date(), 1))}
            full={isMobile}
        />
    );
}
