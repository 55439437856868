import styled from '@emotion/styled';

type PopInWrapperProps = {
    top: number;
    left: number;
};

export const ReplyTemplateButtonWrapper = styled.div`
    position: relative;
`;

export const PopInWrapper = styled.div<PopInWrapperProps>`
    width: 280px;
    position: absolute;
    top: ${({ top }) => top}px;
    left: ${({ left }) => left}px;
`;
