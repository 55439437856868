import React, { useState } from 'react';

import { SpecificHourFormData } from 'app/api/types/business';

import useHolidays from 'app/businessEditV2/hooks/useHolidays';

import { dateInRange } from 'app/businessEditV2/utils/specificHours';

import { HolidaysSuggestionContainer } from './HolidaysSuggestion.styled';
import HolidaysSuggestionHeader from './HolidaysSuggestionHeader';
import HolidaysSuggestionList from './HolidaysSuggestionList';

type Props = {
    onChange: (specificHours: SpecificHourFormData) => void;
    specificHours: SpecificHourFormData;
    scrollToRef: () => void;
};

const HolidaysSuggestion = ({
    onChange,
    specificHours,
    scrollToRef,
}: Props) => {
    const [headerCollapsed, setHeaderCollapsed] = useState<boolean>(false);
    const [seeMoreCollapsed, setSeeMoreCollapsed] = useState<boolean>(true);

    const { data: holidays } = useHolidays();
    const allHolidays =
        holidays?.holidays.filter(
            holiday =>
                !specificHours.some(specificHour =>
                    dateInRange(
                        specificHour.starts_at,
                        specificHour.ends_at,
                        holiday.date,
                    ),
                ),
        ) ?? [];

    if (!allHolidays.length) {
        return null;
    }

    const onClickHeader = () => {
        setHeaderCollapsed(!headerCollapsed);
        setSeeMoreCollapsed(true);
    };

    const onClickSeeMore = () => {
        setSeeMoreCollapsed(!seeMoreCollapsed);
    };

    return (
        <HolidaysSuggestionContainer>
            <HolidaysSuggestionHeader
                onClick={onClickHeader}
                headerCollapsed={headerCollapsed}
            />
            <HolidaysSuggestionList
                holidays={allHolidays}
                onChange={onChange}
                headerCollapsed={headerCollapsed}
                seeMoreCollapsed={seeMoreCollapsed}
                specificHours={specificHours}
                onClick={onClickSeeMore}
                scrollToRef={scrollToRef}
            />
        </HolidaysSuggestionContainer>
    );
};

export default HolidaysSuggestion;
