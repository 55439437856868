import { connect } from 'react-redux';

import { numberFormatter } from 'app/common/services/formatNumber';
import { AppState } from 'app/states/reducers';
import {
    isFetchingPeriodSelector,
    isFetchingTotalSelector,
    periodAnswerRateSelector,
    periodAnswerTimeSelector,
    totalAnswerRateSelector,
    totalAnswerTimeSelector,
} from 'app/states/reviewAnalytics';
import {
    dateFilterSelector,
    reviewFiltersSelector,
} from 'app/states/reviews/filters';

import AnswerRateComponent from './AnswerRate';

const mapStateToProps = (state: AppState) => {
    const { reviewAnalytics } = state;
    const reviewFilters = reviewFiltersSelector(state);
    const dateFilters = dateFilterSelector(reviewFilters);

    return {
        totalAnswerRate: totalAnswerRateSelector(reviewAnalytics),
        periodAnswerRate: periodAnswerRateSelector(reviewAnalytics),
        inputPeriod: dateFilters.from || dateFilters.to,
        totalAnswerTime: numberFormatter(
            parseFloat(
                Math.round(
                    totalAnswerTimeSelector(reviewAnalytics) / 86400,
                ).toFixed(1),
            ),
        ),
        periodAnswerTime: numberFormatter(
            parseFloat(
                Math.round(
                    periodAnswerTimeSelector(reviewAnalytics) / 86400,
                ).toFixed(1),
            ),
        ),
        leftLoading: isFetchingPeriodSelector(reviewAnalytics),
        rightLoading: isFetchingTotalSelector(reviewAnalytics),
    };
};

// @ts-ignore
const AnswerRate = connect(mapStateToProps)(AnswerRateComponent);
export default AnswerRate;
