import { useMemo } from 'react';

import useKeywordsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useKeywordsAnalytics';
import { useKeywordsQueryCount } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/keywords/count/useKeywordsQueryCount';
import { forcedDelta } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/metrics/count/useMetricsDataCount';

export const useKeywordsDataCount = () => {
    const { queryCurrent, queryPrevious } = useKeywordsQueryCount();

    const { data: dataCurrent } = useKeywordsAnalytics(queryCurrent);
    const { data: dataPrevious } = useKeywordsAnalytics(queryPrevious);

    const countCurrent =
        dataCurrent?.keywords?.length > 0
            ? dataCurrent?.keywords.reduce((acc, month) => acc + month.count, 0)
            : 0;
    const countPrevious =
        dataPrevious?.keywords?.length > 0
            ? dataPrevious?.keywords.reduce((acc, month) => acc + month.count, 0)
            : 0;

    const countMonthCurrentPeriod = useMemo(() => dataCurrent?.count || 0, [dataCurrent?.count]);

    const countMonthPreviousPeriod = useMemo(() => dataPrevious?.count || 0, [dataPrevious?.count]);

    const delta =
        countPrevious <= 0 || countMonthCurrentPeriod != countMonthPreviousPeriod
            ? null
            : ((countCurrent - countPrevious) / countPrevious) * 100;

    return { count: countCurrent, delta: forcedDelta(delta) };
};
