import { cloneDeep } from 'lodash-es';

import { PermissionsSectionType } from 'app/pages/settingsV2/subPages/Team/components/Permissions/utils/typing';

import { PermissionsListSectionsContainer } from './PermissionsListSections.styled';
import PermissionsSection from './PermissionsSection';

type Props = {
    value: Array<PermissionsSectionType>;
    onChange: (value: Array<PermissionsSectionType>) => void;
};

const PermissionsListSections = ({ value, onChange }: Props) => {
    const onChangeSection = (section: PermissionsSectionType) => {
        const result = cloneDeep(value);
        const index = result.findIndex(s => s.name === section.name);
        result[index] = section;
        onChange(result);
    };

    return (
        <PermissionsListSectionsContainer>
            {value?.map(section => (
                <PermissionsSection
                    key={`section_${section.name}`}
                    value={section}
                    onChange={onChangeSection}
                />
            ))}
        </PermissionsListSectionsContainer>
    );
};

export default PermissionsListSections;
