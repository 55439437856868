import { call, put, takeEvery } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import {
    GET_GROUP_BY_ORG_ID_REQUEST,
    GetGroupByOrgIdRequestType,
    getGroupByOrgIdFailureAction,
    getGroupByOrgIdSuccessAction,
} from 'app/common/reducers/groups';
import formatGroupData from 'app/common/services/formatGroupDataService';
import { Saga } from 'app/common/types';

function* searchGroupsByOrgId(action: GetGroupByOrgIdRequestType): Saga {
    try {
        // @ts-ignore
        const groupData = yield call(
            api.groups.getGroups,
            null,
            null,
            action.orgId,
        );
        const formattedGroupData = groupData.groups.map(group =>
            formatGroupData(group),
        );
        yield put(
            getGroupByOrgIdSuccessAction(
                formattedGroupData,
                groupData.max_page,
            ),
        );
    } catch (error) {
        yield put(getGroupByOrgIdFailureAction(error));
    }
}

function* searchGroupsByOrgIdSaga(): Saga {
    yield takeEvery(GET_GROUP_BY_ORG_ID_REQUEST, searchGroupsByOrgId);
}

export default searchGroupsByOrgIdSaga;
