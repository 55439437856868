import { Component } from 'react';

import { upperFirst } from 'lodash-es';
import { withTranslation } from 'react-i18next';

import { CONTAINED, Type } from 'app/common/designSystem/constants/type';

import { LoadingElement, StatefulButtonWrapper } from './StatefulButton.styled';

export const LOADING = 'loading';
export const DISABLED = 'disabled';
export const SUCCESSFUL = 'successful';
export const ERROR = 'error';
export const TOSAVE = 'tosave';
export const NOICON = 'noIcon';
export const MODIFY = 'modify';
export const CANCEL = 'cancel';

export type ButtonStatus =
    | 'loading'
    | 'disabled'
    | 'successful'
    | 'error'
    | 'tosave'
    | 'noIcon'
    | 'modify'
    | 'cancel';

export type StatusCases = {
    dataHasChanged?: (arg0: any) => boolean;
    hasErrors?: (arg0: any) => boolean;
    isLoading?: (arg0: any) => boolean;
    isSuccessful?: (arg0: any) => boolean;
    isDisabled?: (arg0: any) => boolean;
};

// container service
const genStatefulButtonStatus = (input: any, cases: StatusCases): ButtonStatus => {
    if (cases.isLoading && cases.isLoading(input)) {
        return LOADING;
    }

    if (cases.isDisabled && cases.isDisabled(input)) {
        return DISABLED;
    }

    if (cases.dataHasChanged && cases.dataHasChanged(input)) {
        return TOSAVE;
    }

    if (cases.hasErrors && cases.hasErrors(input)) {
        return ERROR;
    }

    if (cases.isSuccessful && cases.isSuccessful(input)) {
        return SUCCESSFUL;
    }

    return SUCCESSFUL;
};

export type StatefulButtonParams = {
    input: any;
    cases: StatusCases;
    labels: (arg0: ButtonStatus) => string;
    onClick: () => void;
};

export const genStatefulButtonProps = ({ input, cases, labels, onClick }: StatefulButtonParams) => {
    const status = genStatefulButtonStatus(input, cases);
    const label = labels(status);
    return {
        status,
        label,
        onClick,
    };
};

// components
export type Props = {
    t: (text: string, obj?: Record<string, any>) => string;
    label: string;
    status: ButtonStatus;
    // Optional props
    type?: Type;
    width?: string | null;
    justifyContent?: 'flex-start' | 'center' | null;
    buttonType?: 'button' | 'submit';
    onClick?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    buttonClass?: string;
    preventClick?: boolean;
    labelParams?: Record<string, any>;
    hideIcon?: boolean;
    successIcon?: string;
    errorIcon?: string;
    saveIcon?: string;
    dataTrack?: string;
};

class StatefulButton extends Component<Props> {
    static defaultProps = {
        preventClick: false,
        type: CONTAINED as Type,
        buttonType: 'button' as 'button' | 'submit',
        labelParams: {},
        hideIcon: false,
        successIcon: 'fa fa-check',
        errorIcon: 'fa fa-frown',
        saveIcon: 'fa fa-save',
        buttonClass: undefined,
        justifyContent: null,
        width: null,
        onClick: () => undefined,
        onBlur: () => undefined,
        onFocus: () => undefined,
    };

    onClick = () => {
        const { preventClick, onClick } = this.props;

        if (!this.isDisabled() && !preventClick && onClick) {
            onClick();
        }
    };

    isDisabled = () => {
        const { status } = this.props;
        return status === DISABLED || status === LOADING;
    };

    render() {
        const {
            status,
            type = CONTAINED,
            buttonType,
            onFocus,
            onBlur,
            buttonClass,
            width,
            justifyContent,
            hideIcon,
            saveIcon,
            successIcon,
            errorIcon,
            label,
            labelParams,
            dataTrack,
            t,
        } = this.props;
        return (
            <StatefulButtonWrapper>
                <button
                    type={buttonType === 'button' ? 'button' : 'submit'}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.onClick();
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={`stateful_button stateful_button--${status} ${
                        type ? `stateful_button--${type}` : ''
                    } ${buttonClass || ''}`}
                    style={{
                        width: width || '100%',
                        justifyContent: justifyContent || 'center',
                    }}
                    disabled={this.isDisabled()}
                    data-track={dataTrack}
                    data-intercom-target={dataTrack}
                >
                    {![NOICON, MODIFY].includes(status) && !hideIcon && (
                        <div className="stateful_button__icon">
                            {status === TOSAVE && <span className={saveIcon} />}
                            {status === LOADING && <LoadingElement />}
                            {status === SUCCESSFUL && <i className={successIcon} />}
                            {status === ERROR && <i className={errorIcon} />}
                        </div>
                    )}
                    <div className="stateful_button__label">
                        {upperFirst(t(label, labelParams))}
                    </div>
                </button>
            </StatefulButtonWrapper>
        );
    }
}

export default withTranslation()(StatefulButton);
