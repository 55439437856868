import { useTranslation } from 'react-i18next';

import { GoogleVerificationPage } from 'app/pages/settings/GoogleVerification';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';

export const GoogleVerification = () => {
    const { t } = useTranslation();

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('google_verification')}
                    description={t('google_verification_settings_description')}
                />
            }
        >
            <GoogleVerificationPage />
        </MenuListContentTemplate>
    );
};
