import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls/camel';
import { CONNECTION_STATS } from 'app/common/data/queryKeysConstants';

type UseTripAdvisorType = {
    isFetched: boolean;
    hasTripAdvisor: boolean;
    hasFacebook: boolean;
    hasGoogle: boolean;
};

const usePartnersConnected = (): UseTripAdvisorType => {
    const {
        data: {
            linkedToTripadvisorCount,
            linkedToFacebookCount,
            linkedToGmbCount,
        } = {
            linkedToTripadvisorCount: 0,
            linkedToFacebookCount: 0,
            linkedToGmbCount: 0,
        },
        isFetched,
    } = useQuery([CONNECTION_STATS], () =>
        api.business.getUserBusinessConnectionsStats(),
    );

    return {
        isFetched,
        hasTripAdvisor: linkedToTripadvisorCount > 0,
        hasFacebook: linkedToFacebookCount > 0,
        hasGoogle: linkedToGmbCount > 0,
    };
};

export default usePartnersConnected;
