import React, { useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { useSelector } from 'react-redux';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { BUSINESS_MANAGER } from 'app/common/data/roles';
import { CONTAINED } from 'app/common/designSystem/constants/type';
import { meRoleSelector } from 'app/common/reducers/me';
import FunnelOnboarding from 'app/onboarding/FunnelOnboarding';
import { meSelector } from 'app/states/reducers';

const NoBusiness = () => {
    const [showFunnel, setShowFunnel] = useState(false);
    const me = useSelector(meSelector);
    const role = meRoleSelector(me);
    const handleShowFunnel = () => setShowFunnel(true);

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };

    return (
        <>
            <NotFoundTemplate
                show
                imgSrc={illustrationElement}
                title={'no_business'}
                subtitle={'no_business_description'}
                buttonText={'add_local_business'}
                buttonStatus={CONTAINED}
                withButton={role !== BUSINESS_MANAGER}
                handleClick={handleShowFunnel}
            />

            <FunnelOnboarding showFunnel={showFunnel} setShowFunnel={setShowFunnel} />
        </>
    );
};

export default NoBusiness;
