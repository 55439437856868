import { PhotosData } from 'app/api/types/business';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';
import { LOGO_PHOTO, PRIMARY_PHOTO } from 'app/businessEditV2/oldFromEditV1/data/photoConsts';
import getAllPhotos from 'app/businessEditV2/utils/photos';

import { ColorState } from './types';

export default function usePhotosSectionColor(): ColorState {
    // Hooks
    const { data: publisherErrors } = usePublisherErrors({});
    const { data: business } = useBusiness();

    const photos = getAllPhotos(business?.photos ?? ({} as PhotosData)).filter(
        photo => ![PRIMARY_PHOTO, LOGO_PHOTO].includes(photo.category),
    );

    const photosPermission = useFieldMetadata('photos', 'secondary')?.enabled;
    const isClosed = useIsBusinessClosed();

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // Disabled
    if (!photosPermission) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (publisherErrors?.photos?.secondary) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields
    if (!photos.length) {
        return ColorState.MISSING_WARNING;
    }

    // OK
    return ColorState.OK;
}
