import { uniq } from 'lodash-es';

import { GooglePostStatusType } from 'app/api/types/business';
import { V2FormattedOrgData } from 'app/api/types/org';
import { DONE, FAILED, RUNNING } from 'app/common/data/requestStatuses';

// CONSTANTS
export const SMB = 'small_medium_business';
export const ENTERPRISE = 'enterprise';

// ACTION TYPES
export const SEARCH_ORGANISATIONS = 'SEARCH_ORGANISATIONS';
const SEARCH_ORGANISATIONS_SUCCESS = 'SEARCH_ORGANISATIONS_SUCCESS';
const SEARCH_ORGANISATIONS_FAILURE = 'SEARCH_ORGANISATIONS_FAILURE';
export const GET_ORGANISATION_REQUEST = 'GET_ORGANISATION_REQUEST';
export const GET_ORGANISATION_SUCCESS = 'GET_ORGANISATION_SUCCESS';
const GET_ORGANISATION_FAILURE = 'GET_ORGANISATION_FAILURE';

export type SearchOrgsAction = {
    type: 'SEARCH_ORGANISATIONS';
    page: number;
    query: string;
    provider: string;
};

type SearchOrgsFailureAction = {
    type: 'SEARCH_ORGANISATIONS_FAILURE';
    errors: Record<string, any>;
};

type SearchOrgsSuccessAction = {
    type: 'SEARCH_ORGANISATIONS_SUCCESS';
    // @ts-ignore
    byId: Record<number, V2FormattedOrgData>;
    ids: Array<number>;
    maxPage: number;
};

export type GetOrgAction = {
    type: 'GET_ORGANISATION_REQUEST';
    orgId: number;
};

type GetOrgSuccessAction = {
    type: 'GET_ORGANISATION_SUCCESS';
    org: V2FormattedOrgData;
};

type GetOrgFailureAction = {
    type: 'GET_ORGANISATION_FAILURE';
    orgId: number;
    error: Record<string, any>;
};

// ACTION FLOW TYPES
export type OrgsState = {
    // @ts-ignore
    byId: Record<number, V2FormattedOrgData>;
    ids: Array<number>;
    isFetching: boolean;
    errors: Record<string, any>;
    currentPage: number;
    maxPage: number;
    searchQuery: string;
    getRequests: {
        // @ts-ignore
        statuses: Record<number, string>;
        // @ts-ignore
        errors: Record<number, Record<string, any>>;
    };
    filters: {
        provider: string | null;
    };
};

export type OrgAction =
    | SearchOrgsAction
    | SearchOrgsFailureAction
    | SearchOrgsSuccessAction
    | GetOrgAction
    | GetOrgSuccessAction
    | GetOrgFailureAction;

// ACTION CREATORS
export const searchOrgs = (
    page: number,
    query: string,
    provider = '',
): SearchOrgsAction => ({
    type: SEARCH_ORGANISATIONS,
    page,
    query,
    provider,
});

export const searchOrgsFailure = (
    errors: Record<string, any>,
): SearchOrgsFailureAction => ({
    type: SEARCH_ORGANISATIONS_FAILURE,
    errors,
});

export const searchOrgsSuccess = (
    // @ts-ignore
    byId: Record<number, V2FormattedOrgData>,
    ids: Array<number>,
    maxPage: number,
): SearchOrgsSuccessAction => ({
    type: SEARCH_ORGANISATIONS_SUCCESS,
    byId,
    ids,
    maxPage,
});

export const getOrg = (orgId: number): GetOrgAction => ({
    type: GET_ORGANISATION_REQUEST,
    orgId,
});

export const getOrgSuccess = (
    org: V2FormattedOrgData,
): GetOrgSuccessAction => ({
    type: GET_ORGANISATION_SUCCESS,
    org,
});

export const getOrgFailure = (
    orgId: number,
    error: Record<string, any>,
): GetOrgFailureAction => ({
    type: GET_ORGANISATION_FAILURE,
    orgId,
    error,
});

const initialState = {
    byId: {},
    ids: [],
    isFetching: false,
    errors: {},
    currentPage: 0,
    maxPage: Infinity,
    getRequests: {
        statuses: {},
        errors: {},
    },
    searchQuery: '',
    filters: {
        provider: null,
    },
};

// REDUCER
const orgsReducer = (
    state: OrgsState = initialState,
    action: OrgAction,
): OrgsState => {
    switch (action.type) {
        case SEARCH_ORGANISATIONS:
            return {
                ...state,
                isFetching: true,
                currentPage: action.page,
                searchQuery: action.query,
            };

        case SEARCH_ORGANISATIONS_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                maxPage: 0,
            };

        case SEARCH_ORGANISATIONS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                byId: { ...state.byId, ...action.byId },
                ids: uniq([...state.ids, ...action.ids]),
                maxPage: action.maxPage,
            };

        case GET_ORGANISATION_REQUEST:
            return {
                ...state,
                getRequests: {
                    ...state.getRequests,
                    statuses: {
                        ...state.getRequests.statuses,
                        [action.orgId]: RUNNING,
                    },
                    errors: { ...state.getRequests.errors, [action.orgId]: {} },
                },
            };

        case GET_ORGANISATION_FAILURE:
            return {
                ...state,
                getRequests: {
                    ...state.getRequests,
                    statuses: {
                        ...state.getRequests.statuses,
                        [action.orgId]: FAILED,
                    },
                    errors: {
                        ...state.getRequests.errors,
                        [action.orgId]: action.error,
                    },
                },
            };

        case GET_ORGANISATION_SUCCESS:
            return {
                ...state,
                byId: { ...state.byId, [action.org.orgId]: action.org },
                ids: uniq([...state.ids, action.org.orgId]),
                getRequests: {
                    ...state.getRequests,
                    statuses: {
                        ...state.getRequests.statuses,
                        [action.org.orgId]: DONE,
                    },
                },
            };

        default:
            return state;
    }
};

// SELECTORS
export const orgByIdSelector = (
    { byId }: OrgsState,
    orgId: number | null,
): V2FormattedOrgData | null => {
    if (orgId) {
        return byId[orgId] || null;
    }
    return null;
};
export const getOrgInfos = (orgs: OrgsState, orgId: number | null): string => {
    if (orgId === null) {
        return '';
    }

    const selectedOrg = orgByIdSelector(orgs, orgId);
    let formattedName = orgId.toString();

    if (selectedOrg) {
        formattedName = `${orgId} - ${selectedOrg.name}`;
    }

    return formattedName;
};
export const getGooglePostStatus = (
    { byId }: OrgsState,
    orgId: number | null,
): GooglePostStatusType => {
    if (orgId) {
        const Org = byId[orgId];

        if (Org) {
            return {
                hasGooglePost: Org.hasGooglePost,
                displayGooglePost: Org.displayGooglePost,
            };
        }
    }

    return {
        hasGooglePost: false,
        displayGooglePost: false,
    };
};

export default orgsReducer;
