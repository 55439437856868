import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import {
    ApiKeyCardLoadingContent,
    ApiKeyCardMarkup,
    ApiKeyLoadingContainer,
} from './ApiKeyLoadingCard.styled';

const ApiKeyLoadingCard = () => (
    <ApiKeyLoadingContainer direction="row" gap="0">
        <ApiKeyCardMarkup />
        <ApiKeyCardLoadingContent>
            <div style={{ flexBasis: '25% !important', margin: '0 16px' }}>
                <LoadingRectangle recHeight="20px" recWidth="20px" recRadius="50%" />
            </div>
            <div style={{ flexBasis: '175%', margin: '0 16px' }}>
                <LoadingRectangle recWidth="100%" recHeight="20px" recRadius="4px" />
            </div>
            <div style={{ flexBasis: '175%', margin: '0 16px' }}>
                <LoadingRectangle recWidth="100%" recHeight="20px" recRadius="4px" />
            </div>
            <div style={{ flexBasis: '100%', margin: '0 16px' }}>
                <LoadingRectangle recWidth="100%" recHeight="20px" recRadius="4px" />
            </div>
            <div style={{ flexBasis: '100%', margin: '0 16px' }}>
                <LoadingRectangle recWidth="100%" recHeight="20px" recRadius="4px" />
            </div>
            <div style={{ flexBasis: '100%', margin: '0 16px' }}>
                <LoadingRectangle recWidth="100%" recHeight="20px" recRadius="4px" />
            </div>
            <div style={{ flexBasis: '100%', margin: '0 16px' }}>
                <LoadingRectangle recHeight="20px" recWidth="20px" recRadius="50%" />
            </div>
            <div style={{ flexBasis: '100%', margin: '0 16px' }}>
                <LoadingRectangle recHeight="20px" recWidth="20px" recRadius="50%" />
            </div>
        </ApiKeyCardLoadingContent>
    </ApiKeyLoadingContainer>
);

export default ApiKeyLoadingCard;
