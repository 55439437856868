import { useTranslation } from 'react-i18next';

import { Slide, SlideTitle, SlidesContainer } from './router_illustration.styled';

import { IllustrationSlide1 } from './slide-1-whatsapp';
import { IllustrationSlide2 } from './slide-2-robot';
import { IllustrationSlide3 } from './slide-3-search';
import { IllustrationSlide4 } from './slide-4-google';
import { IllustrationSlide5 } from './slide-5-notification';

export const ConversationRoutingIllustration = () => {
    const { t } = useTranslation();
    return (
        <SlidesContainer>
            <Slide>
                <IllustrationSlide1 />
                <SlideTitle as="p" variant="bodyMMedium">
                    {t('chatbot_router_slide_1_title')}
                </SlideTitle>
            </Slide>
            <Slide>
                <IllustrationSlide2 />
                <SlideTitle as="p" variant="bodyMMedium">
                    {t('chatbot_router_slide_2_title')}
                </SlideTitle>
            </Slide>
            <Slide>
                <IllustrationSlide3 />
                <SlideTitle as="p" variant="bodyMMedium">
                    {t('chatbot_router_slide_3_title')}
                </SlideTitle>
            </Slide>
            <Slide>
                <IllustrationSlide4 />
                <SlideTitle as="p" variant="bodyMMedium">
                    {t('chatbot_router_slide_4_title')}
                </SlideTitle>
            </Slide>
            <Slide>
                <IllustrationSlide5 />
                <SlideTitle as="p" variant="bodyMMedium">
                    {t('chatbot_router_slide_5_title')}
                </SlideTitle>
            </Slide>
        </SlidesContainer>
    );
};
