import BusinessModalButton from 'app/common/components/businessModal/BusinessModalButton';
import { FilterBusinessModalButton } from 'app/common/components/businessModal/components/FilterBusinessModalButton/FilterBusinessModalButton';
import { FiltersContainerStyled, FiltersStyled } from 'app/common/components/FilterSection.styled';

import { IS_BELOW_MOBILE, useDeviceType } from 'app/hooks';

import { POSTS_SEARCH_PARAMS } from 'app/presence/googlePosts/components/googlePostList';
import Flexbox from 'app/styles/utils/flexbox';

import { FilterButton } from './FilterButton';
import { DateFilter } from './filters/DateFilter';
import { SearchFilter } from './filters/SearchFilter';

export const FilterSection = () => {
    const { isBelowProvidedDevices: isMobile } = useDeviceType(IS_BELOW_MOBILE);

    return (
        <FiltersContainerStyled>
            {!isMobile ? (
                <FiltersStyled>
                    <BusinessModalButton
                        key="business-modal-key"
                        searchFilters={POSTS_SEARCH_PARAMS}
                        button={
                            <div>
                                <FilterBusinessModalButton />
                            </div>
                        }
                    />
                    <DateFilter />
                    <SearchFilter />
                    <FilterButton />
                </FiltersStyled>
            ) : (
                <Flexbox flexDirection="column" gap="8px" flex="1">
                    <BusinessModalButton
                        key="business-modal-key"
                        searchFilters={POSTS_SEARCH_PARAMS}
                        button={
                            <div>
                                <FilterBusinessModalButton />
                            </div>
                        }
                    />
                    <SearchFilter />
                    <Flexbox gap="8px" flex="1">
                        <DateFilter isMobile={isMobile} />
                        <FilterButton />
                    </Flexbox>
                </Flexbox>
            )}
        </FiltersContainerStyled>
    );
};
