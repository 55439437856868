import { css } from '@emotion/react';
import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationVariousEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Icon, IconPrefix, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import {
    FooterContainer,
    StyledText,
} from 'app/pages/visibility/subComponents/MetaRelogin/MetaRelogin.styled';

import {
    MetaReloginContextProps,
    useMetaRelogin,
} from 'app/pages/visibility/subComponents/MetaRelogin/MetaReloginContext';

import { useSendTrackingData } from 'app/pages/visibility/subComponents/MetaRelogin/utils';

import { StyledStack } from './Dialogs.styled';

export const DefaultDialog = () => {
    const { t } = useTranslation();
    const { data, setShowBanner, setShowModal, startOAuthFlow, missingScopes } =
        useMetaRelogin() as MetaReloginContextProps;
    const sendTrackingData = useSendTrackingData();

    const handleForceModalCloseAndHideBanner = () => {
        sendTrackingData('meta_relogin_hide_banner');
        setShowBanner(false);
        setShowModal(false);
    };

    const handleMetaRelogin = () => {
        sendTrackingData('meta_relogin');
        startOAuthFlow(missingScopes, ['facebook_lost']);
        setShowModal(false);
    };

    return (
        <Dialog
            dataTrackId={t('meta_relogin_modal')}
            title={t('meta_relogin_modal_title')}
            subTitle={t('meta_relogin_modal_text')}
            header={
                <Stack style={{ paddingTop: '24px' }}>
                    <Illustration
                        dataTrackId="meta_relogin_modal_default_illustration"
                        illustration={{
                            type: IllustrationCategoryEnum.Various,
                            name: IllustrationVariousEnum.LostConnectionFacebook,
                        }}
                        height="140"
                        width="320"
                    />
                </Stack>
            }
            onClose={() => setShowModal(false)}
        >
            <Stack gap="30px">
                <StyledText variant="bodyMSemibold" color="secondary">
                    {data?.name && `${data.name} — `}
                    {data?.email}
                </StyledText>
                <StyledStack gap="16px" status="default">
                    {t('meta_relogin_modal_description_text_1')}
                    <br />
                    <Text variant="bodyMRegular" as="span" color="warning">
                        <Icon
                            icon={['fa-warning', IconPrefix.SOLID]}
                            color="warning"
                            iconSize="14px"
                            css={css`
                                margin-right: 6px;
                            `}
                        />
                        {t('meta_relogin_modal_description_text_2')}
                        <Text variant="bodyMBold" as="span" color="warning">
                            {t('meta_relogin_modal_description_text_3')}
                        </Text>
                    </Text>
                </StyledStack>
            </Stack>
            <FooterContainer>
                <Button
                    key="meta_relogin_dont_show_again_modal_button"
                    dataTrackId="meta_relogin_dont_show_again_modal_button"
                    variant="secondary"
                    size="large"
                    shape="cube"
                    appearance="outlined"
                    onClick={handleForceModalCloseAndHideBanner}
                >
                    <Text variant="bodyLBold" color="default">
                        {t('meta_relogin_dont_show_again_modal_button')}
                    </Text>
                </Button>
                <Button
                    key="meta_relogin_reconnect_now_modal_button"
                    dataTrackId="meta_relogin_reconnect_now_modal_button"
                    variant="danger"
                    size="large"
                    shape="cube"
                    onClick={handleMetaRelogin}
                >
                    <Text variant="bodyLBold" color="theme">
                        {t('meta_relogin_reconnect_now_modal_button')}
                    </Text>
                </Button>
            </FooterContainer>
        </Dialog>
    );
};
