import { FC } from 'react';

import {
    FacebookMessengerIcon,
    GoogleMessagingIcon,
    InstagramMessagingIcon,
    SMSMessagingIcon,
    WhatsappMessagingIcon,
} from 'app/reviewManagement/common/icon/messagingPartners';
import { Conversation, MessagingPartnerEnum } from 'app/states/messaging';

import ConsumerIcon from './ConsumerIcon';
import { ConsumerIconWithPartnerContainer } from './ConsumerIconWithPartner.styled';

type ConsumerIconWithPartnerProps = {
    conversation: Conversation;
};

const PartnerIconsMap = {
    Google: GoogleMessagingIcon,
    Facebook: FacebookMessengerIcon,
    Instagram: InstagramMessagingIcon,
    SMS: SMSMessagingIcon,
    Whatsapp: WhatsappMessagingIcon,
};

const ConsumerIconWithPartner: FC<ConsumerIconWithPartnerProps> = ({ conversation }) => {
    let partnerIcon = null;

    if (conversation.messaging_partner !== MessagingPartnerEnum.DELIGHT) {
        const PartnerIconComponent = PartnerIconsMap[conversation.messaging_partner];
        partnerIcon = <PartnerIconComponent />;
    }

    return (
        <ConsumerIconWithPartnerContainer clipped={!!partnerIcon}>
            <div className="profile-icon">
                <ConsumerIcon conversation={conversation} size={40} />
            </div>
            <div className="partner-icon">{partnerIcon}</div>
        </ConsumerIconWithPartnerContainer>
    );
};

export default ConsumerIconWithPartner;
