import { useTranslation } from 'react-i18next';

import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';

import { FeedbackFormList } from './FeedbackFormList';

export const FeedbackFormListPage = () => {
    const { t } = useTranslation();

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('feedback_forms_share')}
                    description={t('feedback_form_list_page_subtitle')}
                />
            }
        >
            <MenuListContentContainer>
                <FeedbackFormList />
            </MenuListContentContainer>
        </MenuListContentTemplate>
    );
};
