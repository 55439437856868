import React, { useContext, useState } from 'react';

import { useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useDispatch } from 'react-redux';

import businessImportFormatter from 'app/api/formatters/request/businessImportFormatter';
import { PUBLISHERS } from 'app/businessConnection/components/genericQuickConnect/publishers';
import QuickConnectContext from 'app/businessConnection/components/genericQuickConnect/QuickConnect.context';
import { useButtonStatus } from 'app/businessConnection/components/genericQuickConnect/SuggestionSection/hooks/useButtonStatus';
import { useSuggestions } from 'app/businessConnection/components/genericQuickConnect/SuggestionSection/hooks/useSuggestions';
import {
    QuickConnectForm,
    QuickConnectModalFooter,
    QuickConnectModalHeader,
    QuickConnectModalHeaderDescription,
    QuickConnectModalHeaderTitle,
} from 'app/businessConnection/components/genericQuickConnect/SuggestionSection/SuggestionSectionForm.styled';
import SuggestionTable from 'app/businessConnection/components/genericQuickConnect/SuggestionSection/SuggestionTable';
import StatefulButton, { LOADING, TOSAVE } from 'app/common/components/buttons/StatefulButton';
import { setLocationId } from 'app/common/reducers/newBusinesses';
import { STATIC_BASE_URL } from 'app/config';
import { businessImportSelector } from 'app/states/knowledge/reducers/businessConnection/connection';

const SuggestionSectionForm = ({ t, connectionState }) => {
    const { publisher, selectedSuggestions, setSelectedSuggestions } =
        useContext(QuickConnectContext);
    const [isBulk, setIsBulk] = useState(false);

    const dispatch = useDispatch();

    const { formState, handleSubmit } = useForm();

    const {
        state: [suggestions, setSuggestions],
        isLoading,
    } = useSuggestions([]);

    const [buttonStatus] = useButtonStatus(TOSAVE);

    const [failedConnections, setFailedConnections] = useState<string[]>([]);

    const connectRequest = useMutation((suggestion: any) =>
        PUBLISHERS[publisher].endpoints.connection(
            suggestion?.businessInfo.id,
            suggestion?.locationInfo.id,
            PUBLISHERS[publisher].partnerResourceName,
            { is_bulk_import: isBulk, is_quick_connect: true },
        ),
    );

    const importRequest = useMutation((suggestion: any) =>
        PUBLISHERS[publisher].endpoints.import(
            suggestion?.businessInfo.id,
            businessImportFormatter(businessImportSelector(connectionState), isBulk, true),
        ),
    );

    const onSubmit = async () => {
        setIsBulk(selectedSuggestions.length >= 10);
        for (const suggestion of selectedSuggestions) {
            const key = `${suggestion.businessInfo.id}_${suggestion.locationInfo.id}`;

            try {
                // eslint-disable-next-line no-await-in-loop
                await connectRequest.mutateAsync(suggestion);
                dispatch(
                    setLocationId(
                        suggestion.businessInfo.id,
                        suggestion.locationInfo.id,
                        publisher,
                    ),
                );
                setSelectedSuggestions(sugg =>
                    sugg.filter(
                        element => `${element.businessInfo.id}_${element.locationInfo.id}` !== key,
                    ),
                );
                setSuggestions(sugg =>
                    sugg.filter(
                        element => `${element.businessInfo.id}_${element.locationInfo.id}` !== key,
                    ),
                );
                // eslint-disable-next-line no-await-in-loop
                await importRequest.mutateAsync(suggestion);
            } catch (error) {
                // @ts-ignore
                setFailedConnections(sugg => [...sugg, key]);
            }
        }

        setSelectedSuggestions([]);
    };

    return (
        <QuickConnectForm onSubmit={handleSubmit(onSubmit)}>
            <QuickConnectModalHeader>
                <QuickConnectModalHeaderTitle>
                    <img
                        src={`${STATIC_BASE_URL}/images/app/partner_connections/quick_connect.svg`}
                        alt="quick_connect"
                        className="noselect"
                    />
                    {t(PUBLISHERS[publisher].texts.headerTitle)}
                </QuickConnectModalHeaderTitle>
                <QuickConnectModalHeaderDescription>
                    <i className="fa-solid fa-info-circle" />
                    {t('quick_connect_modal_description')}
                </QuickConnectModalHeaderDescription>
            </QuickConnectModalHeader>

            <SuggestionTable
                suggestions={suggestions}
                failedConnections={failedConnections}
                isLoading={isLoading}
                isSubmitting={formState.isSubmitting}
            />
            {!isLoading && suggestions?.length > 0 && (
                <QuickConnectModalFooter>
                    {/* @ts-ignore */}
                    <StatefulButton
                        buttonType="submit"
                        label={
                            formState.isSubmitting
                                ? t(PUBLISHERS[publisher].texts.buttonImportInProgress)
                                : t(PUBLISHERS[publisher].texts.buttonWithSuggestions)
                        }
                        status={formState.isSubmitting ? LOADING : buttonStatus}
                        buttonClass="padding_bottom--double padding_top--double"
                        onClick={onSubmit}
                    />
                </QuickConnectModalFooter>
            )}
        </QuickConnectForm>
    );
};

export default withTranslation()(SuggestionSectionForm);
