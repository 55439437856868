export const AdviceIcon = () => (
    <svg width="76" height="77" viewBox="0 0 76 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_260_6783)">
            <rect y="0.751221" width="76" height="76" rx="16" fill="#0085F2" fill-opacity="0.12" />
            <g clip-path="url(#clip1_260_6783)">
                <path
                    d="M38.0214 13.3222C29.1916 13.3106 21.9967 20.4958 21.9968 29.3257C21.9969 35.1955 25.1577 40.3264 29.8697 43.1112C30.7064 43.6057 31.2129 44.512 31.2129 45.484V49.382C31.3678 49.3172 31.5377 49.2813 31.716 49.2813H44.2843C44.4627 49.2813 44.6326 49.3172 44.7875 49.382V45.4839C44.7875 44.5005 45.3128 43.5969 46.1584 43.0948C50.8551 40.3062 54.0034 35.184 54.0034 29.3256C54.0035 20.4942 46.8501 13.3337 38.0214 13.3222Z"
                    fill="#FFE181"
                />
                <path
                    d="M35.4425 45.484C35.4425 44.5121 34.9361 43.6057 34.0993 43.1112C29.3873 40.3263 26.2265 35.1955 26.2264 29.3257C26.2263 21.2186 32.2918 14.4988 40.119 13.4618C39.4325 13.371 38.7325 13.3232 38.0215 13.3222C29.1917 13.3106 21.9967 20.4957 21.9968 29.3257C21.9969 35.1955 25.1577 40.3264 29.8697 43.1112C30.7065 43.6057 31.2129 44.5121 31.2129 45.484V49.382C31.3678 49.3172 31.5377 49.2813 31.716 49.2813H35.4425V45.484Z"
                    fill="#FEC458"
                />
                <path
                    d="M40.0042 50.0487C39.5804 50.0487 39.2368 49.7051 39.2368 49.2813V42.4129C39.2368 41.7226 39.4692 41.0417 39.8911 40.4954L40.9893 39.0735C41.2048 38.7945 41.3235 38.4468 41.3235 38.0943V30.9265C41.3235 30.5026 41.6671 30.159 42.0909 30.159C42.5148 30.159 42.8584 30.5026 42.8584 30.9265V38.0943C42.8584 38.7846 42.6259 39.4655 42.2041 40.0118L41.1058 41.4336C40.8903 41.7127 40.7717 42.0603 40.7717 42.4129V49.2813C40.7717 49.7051 40.4281 50.0487 40.0042 50.0487Z"
                    fill="#FFB640"
                />
                <path
                    d="M35.9954 50.0487C35.5716 50.0487 35.228 49.7051 35.228 49.2813V42.4129C35.228 42.0604 35.1093 41.7127 34.8939 41.4336L33.7955 40.0118C33.3737 39.4656 33.1412 38.7846 33.1412 38.0943V30.9265C33.1412 30.5026 33.4848 30.159 33.9087 30.159C34.3325 30.159 34.6761 30.5026 34.6761 30.9265V38.0943C34.6761 38.4468 34.7948 38.7945 35.0103 39.0734L36.1086 40.4953C36.5306 41.0416 36.7629 41.7226 36.7629 42.4128V49.2812C36.7629 49.7051 36.4193 50.0487 35.9954 50.0487Z"
                    fill="#FFB640"
                />
                <path
                    d="M49.1639 26.1921C48.3334 23.0972 46.1997 20.5094 43.3099 19.0924C42.9294 18.9058 42.7722 18.446 42.9587 18.0655C43.1452 17.685 43.6053 17.5278 43.9856 17.7143C47.2732 19.3263 49.701 22.2714 50.6463 25.7943C50.7562 26.2036 50.5133 26.6246 50.104 26.7344C49.6949 26.8443 49.2738 26.6017 49.1639 26.1921Z"
                    fill="#FFEAC8"
                />
                <path
                    d="M34.9005 60.5188V61.081C34.9005 62.7856 36.2951 64.1803 37.9996 64.1803C39.7042 64.1803 41.0988 62.7857 41.0988 61.081V60.5188H34.9005Z"
                    fill="#8479C2"
                />
                <path
                    d="M37.6748 61.081V60.5188H34.9005V61.081C34.9005 62.7856 36.2951 64.1803 37.9996 64.1803C38.4976 64.1803 38.9687 64.0606 39.3868 63.8495C38.374 63.3382 37.6748 62.2876 37.6748 61.081Z"
                    fill="#6E60B8"
                />
                <path
                    d="M42.8581 32.5451H33.1415C32.7177 32.5451 32.3741 32.2015 32.3741 31.7776C32.3741 31.3538 32.7177 31.0102 33.1415 31.0102H42.8581C43.2819 31.0102 43.6255 31.3538 43.6255 31.7776C43.6255 32.2015 43.2819 32.5451 42.8581 32.5451Z"
                    fill="#FEC458"
                />
                <path
                    d="M18.2737 29.6833H15.6335C15.2097 29.6833 14.8661 29.3397 14.8661 28.9159C14.8661 28.492 15.2097 28.1484 15.6335 28.1484H18.2737C18.6975 28.1484 19.0411 28.492 19.0411 28.9159C19.0411 29.3396 18.6975 29.6833 18.2737 29.6833Z"
                    fill="#FEC458"
                />
                <path
                    d="M19.0273 24.813L16.5588 23.8762C16.1626 23.7258 15.9633 23.2826 16.1136 22.8864C16.2641 22.4901 16.7072 22.291 17.1035 22.4412L19.5719 23.378C19.9681 23.5284 20.1675 23.9716 20.0171 24.3678C19.8668 24.7638 19.424 24.9633 19.0273 24.813Z"
                    fill="#FEC458"
                />
                <path
                    d="M16.1136 34.9452C15.9633 34.5489 16.1626 34.1057 16.5588 33.9553L19.0273 33.0185C19.4233 32.8681 19.8667 33.0674 20.0171 33.4637C20.1675 33.86 19.9681 34.3032 19.5719 34.4536L17.1035 35.3904C16.7072 35.5408 16.2641 35.3415 16.1136 34.9452Z"
                    fill="#FEC458"
                />
                <path
                    d="M60.3657 29.6833H57.7256C57.3017 29.6833 56.9581 29.3397 56.9581 28.9159C56.9581 28.492 57.3017 28.1484 57.7256 28.1484H60.3657C60.7896 28.1484 61.1332 28.492 61.1332 28.9159C61.1332 29.3396 60.7896 29.6833 60.3657 29.6833Z"
                    fill="#FEC458"
                />
                <path
                    d="M55.9824 24.3677C55.832 23.9715 56.0314 23.5282 56.4276 23.3778L58.896 22.441C59.2921 22.2907 59.7355 22.4899 59.8859 22.8862C60.0363 23.2825 59.8369 23.7257 59.4407 23.8761L56.9724 24.813C56.5759 24.9633 56.1329 24.764 55.9824 24.3677Z"
                    fill="#FEC458"
                />
                <path
                    d="M58.896 35.3905L56.4276 34.4537C56.0314 34.3033 55.832 33.8601 55.9824 33.4639C56.1329 33.0676 56.5759 32.8683 56.9723 33.0186L59.4407 33.9554C59.8369 34.1058 60.0363 34.5491 59.8859 34.9453C59.7355 35.3412 59.2927 35.5408 58.896 35.3905Z"
                    fill="#FEC458"
                />
                <path
                    d="M43.2615 56.7473H32.7386C32.0043 56.7473 31.4069 57.3447 31.4069 58.079V59.2126C31.4069 59.9468 32.0043 60.5442 32.7386 60.5442H43.2616C43.9959 60.5442 44.5932 59.9468 44.5932 59.2126V58.079C44.5932 57.3447 43.9959 56.7473 43.2615 56.7473Z"
                    fill="#EFECEF"
                />
                <path
                    d="M43.2615 58.6459H32.7386C32.1373 58.6459 31.6283 58.245 31.4635 57.6967C31.427 57.818 31.4069 57.9462 31.4069 58.0791V59.2127C31.4069 59.947 32.0043 60.5444 32.7386 60.5444H43.2616C43.9959 60.5444 44.5932 59.947 44.5932 59.2127V58.0791C44.5932 57.9461 44.5731 57.8179 44.5366 57.6967C44.3718 58.245 43.8629 58.6459 43.2615 58.6459Z"
                    fill="#E2DFE2"
                />
                <path
                    d="M44.2842 53.0015H31.7158C30.9815 53.0015 30.3841 53.5989 30.3841 54.3332V55.4668C30.3841 56.2011 30.9815 56.7985 31.7158 56.7985H44.2842C45.0186 56.7985 45.616 56.2011 45.616 55.4668V54.3332C45.616 53.5989 45.0186 53.0015 44.2842 53.0015Z"
                    fill="#EFECEF"
                />
                <path
                    d="M44.2843 54.9001H31.7159C31.1145 54.9001 30.6055 54.4992 30.4408 53.9507C30.4043 54.072 30.3841 54.2003 30.3841 54.3332V55.4668C30.3841 56.2011 30.9815 56.7985 31.7158 56.7985H44.2842C45.0186 56.7985 45.616 56.2011 45.616 55.4668V54.3332C45.616 54.2002 45.5958 54.072 45.5593 53.9507C45.3946 54.4992 44.8857 54.9001 44.2843 54.9001Z"
                    fill="#E2DFE2"
                />
                <path
                    d="M44.2842 49.2556H31.7158C30.9815 49.2556 30.3841 49.853 30.3841 50.5874V51.7209C30.3841 52.4552 30.9815 53.0526 31.7158 53.0526H44.2842C45.0186 53.0526 45.616 52.4552 45.616 51.7209V50.5874C45.616 49.853 45.0186 49.2556 44.2842 49.2556Z"
                    fill="#EFECEF"
                />
                <path
                    d="M44.2843 51.1542H31.7159C31.1145 51.1542 30.6055 50.7533 30.4408 50.205C30.4043 50.3262 30.3841 50.4545 30.3841 50.5874V51.721C30.3841 52.4552 30.9815 53.0526 31.7158 53.0526H44.2842C45.0186 53.0526 45.616 52.4552 45.616 51.721V50.5874C45.616 50.4544 45.5958 50.3261 45.5593 50.205C45.3946 50.7533 44.8857 51.1542 44.2843 51.1542Z"
                    fill="#E2DFE2"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_260_6783">
                <rect y="0.751221" width="76" height="76" rx="16" fill="white" />
            </clipPath>
            <clipPath id="clip1_260_6783">
                <rect
                    width="50.8582"
                    height="50.8582"
                    fill="white"
                    transform="translate(12.5709 13.3221)"
                />
            </clipPath>
        </defs>
    </svg>
);
