// By using a separate reducer to store all isFetching/isLoading flags
// spares the developer from creating and handling this state at each
// reducer level => cleaner code.
// Your action constants must end with one of the following patterns:
// REQUEST|SUCCESS|FAILURE
export const loadingReducer = (state: any = {}, action: any) => {
    const { type } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
    if (!matches) return state;
    const [, requestName, requestState] = matches;
    return { ...state, [requestName]: requestState === 'REQUEST' };
};

// To select a specific isLoading from the state use `createLoadingSelector`:
// Example:
//     const loadingSelector = createLoadingSelector(['GET_REVIEWS_STATS']);
// Where 'GET_REVIEWS_STATS' represents the root name of your action constant.
export const createLoadingSelector = (actions: any) => (state: any) =>
    actions.some(action => state[action]);
