import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import {
    ChildrenContainerSelectorId,
    ModalActionsSelectorId,
} from 'app/common/components/ConfirmModalV2/ConfirmModal.styled';

export const ReportSuggestionConfirmModal = styled(ConfirmModal)`
    [data-css-selector=${`${ChildrenContainerSelectorId}`}] {
        margin-top: 16px;
    }
    [data-css-selector=${`${ModalActionsSelectorId}`}] {
        button {
            flex: 0 1 50%;
            &:first-of-type {
                border-color: #1425421f;
            }
        }
    }
`;

export const ReportSuggestionText = styled(Text)`
    background: hsla(258, 85%, 66%, 0.06);
    padding: 16px 8px;
    margin-bottom: 8px;
    border-radius: 8px;
`;
