import { useCallback, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { useBlocker } from 'app/hooks/useBlocker';

type Props = {
    when: boolean;
};

export default function useNavigationBlocker({ when }: Props) {
    const [blocker, setBlocker] = useState();
    const location = useLocation();

    useBlocker(
        useCallback(
            tx => {
                if (location.pathname !== tx.location.pathname) {
                    setBlocker(tx);
                } else {
                    tx.retry();
                }
            },
            [location],
        ),
        when,
    );

    return {
        blocker,
        confirmNavigation: () => {
            blocker?.retry();
            setBlocker(null);
        },
        rejectNavigation: () => {
            setBlocker(null);
        },
    };
}
