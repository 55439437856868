import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, List, Stack, Tooltip } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { PublisherRawData } from 'app/api/types/publisher';
import { STATIC_BASE_URL } from 'app/config';
import { IS_BELOW_MOBILE, useDeviceType } from 'app/hooks';

import { PublisherImage, PublisherTextName } from './PlatformCell.styled';

interface PlatformCellProps {
    publisher: PublisherRawData;
    onClick: (e: any) => void;
}
export const PlatformCell = ({ publisher, onClick }: PlatformCellProps) => {
    const { t } = useTranslation();
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_MOBILE);
    const imgSrc = `${STATIC_BASE_URL}/images/common/partners_sprite/circle/${publisher.name}.svg`;

    return (
        <List.Cell>
            <Stack direction="row" alignItems="center">
                <PublisherImage src={imgSrc} alt={publisher.name} />
                {!isBelowProvidedDevices && (
                    <PublisherTextName variant="heading6" as="span" oneLine>
                        {publisher.formatted_name}
                    </PublisherTextName>
                )}
                <Tooltip text={t('synced_fields_tooltip_message')} placement="top">
                    <Icon
                        icon={[FontAwesomeIconsPartooUsed.faCircleQuestion, IconPrefix.SOLID]}
                        onClick={onClick}
                    />
                </Tooltip>
            </Stack>
        </List.Cell>
    );
};
