import { useQuery } from 'react-query';

import { AccountPartner } from 'app/api/types/account';
import api from 'app/api/v2/api_calls';
import { BUSINESS_LOCATIONS } from 'app/common/data/queryKeysConstants';

export default function useLocation(
    partner: AccountPartner,
    locationId: string,
) {
    return useQuery(
        [BUSINESS_LOCATIONS, partner, locationId],
        () => api.account.getLocationDetail(locationId, partner),
        {
            enabled: !!locationId,
        },
    );
}
