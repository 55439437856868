import { FontAwesomeIconsPartooUsed, IconPrefix, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { SectionWithIcon } from 'app/common/designSystem/components/atoms/SectionWithIcon/SectionWithIcon';
import ModalTemplate from 'app/common/designSystem/components/templates/ModalTemplate';
import { SMALL, Type } from 'app/common/designSystem/constants/size';
import usePartnersConnected from 'app/common/hooks/queries/usePartnersConnected';

import {
    ModalTemplateContent,
    ModalTemplateContentDescription,
} from './ReviewReportModal.styled';

const DailyReportSliderModal = () => {
    const { t } = useTranslation();
    const { hasTripAdvisor } = usePartnersConnected();
    const description = {
        negative: [
            ['google_from_one_to_two_stars', t('google_from_one_to_two_stars')],
            hasTripAdvisor
                ? [
                      'tripadvisor_from_one_to_two_stars',
                      t('tripadvisor_from_one_to_two_stars'),
                  ]
                : [],
            ['not_recommended_reviews', t('not_recommended_reviews')],
        ],
        neutral: [
            ['google_three_stars', t('google_three_stars')],
            hasTripAdvisor
                ? ['tripadvisor_three_stars', t('tripadvisor_three_stars')]
                : [],
            ['not_recommended_reviews', t('not_recommended_reviews')],
        ],
        positive: [
            [
                'google_from_four_to_five_stars',
                t('google_from_four_to_five_stars'),
            ],
            hasTripAdvisor
                ? [
                      'tripadvisor_from_four_to_five_stars',
                      t('tripadvisor_from_four_to_five_stars'),
                  ]
                : [],
            ['recommended_reviews', t('recommended_reviews')],
        ],
    };

    return (
        <ModalTemplate size={SMALL as Type}>
            <ModalTemplateContent>
                <Text variant="heading4" as="h4">
                    {t('type_of_reviews')}
                </Text>
                <Text variant="bodyMRegular" as="span" color="secondary">
                    {t('what_are_the_different_type_of_reviews')}
                </Text>
                <ModalTemplateContentDescription>
                    <SectionWithIcon
                        variant="danger"
                        iconName={[
                            FontAwesomeIconsPartooUsed.faFaceAngry,
                            IconPrefix.SOLID,
                        ]}
                        title={t('negative')}
                        items={description.negative}
                    />
                    <SectionWithIcon
                        variant="secondary"
                        iconName={[
                            FontAwesomeIconsPartooUsed.faFaceMeh,
                            IconPrefix.SOLID,
                        ]}
                        title={t('neutral')}
                        items={description.neutral}
                    />
                    <SectionWithIcon
                        variant="success"
                        iconName={[
                            FontAwesomeIconsPartooUsed.faFaceMeh,
                            IconPrefix.SOLID,
                        ]}
                        title={t('positive')}
                        items={description.positive}
                    />
                </ModalTemplateContentDescription>
            </ModalTemplateContent>
        </ModalTemplate>
    );
};

export default DailyReportSliderModal;
