import { FC, useCallback, useState } from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';

import { MessageBubbleContent } from 'app/reviewManagement/messaging/activeConversation/messages/MessageBubbleContent';
import { usePostMessage } from 'app/reviewManagement/messaging/hooks/usePostMessage';

import {
    Conversation,
    ConversationMessage,
    MESSAGE_CONTENT_TYPE,
    MESSAGE_SENDER_TYPE,
    MESSAGE_STATUS,
} from 'app/states/messaging/dataTypes';

import {
    MessageBubbleFlexbox,
    MessageBubbleFlexboxWrapper,
    MessageErrorRetry,
    MessageErrorWrapper,
    MessageSenderDetail,
    Separator,
} from './MessageBubble.styled';
import { usePostMessageRetry } from '../../hooks/usePostMessageRetry';

dayjs.extend(customParseFormat);

type MessageBubbleProps = {
    message: ConversationMessage;
    conversationId: number;
    conversation: Conversation;
    consumerName: string;
};
export const MessageBubble: FC<MessageBubbleProps> = ({
    message,
    conversationId,
    conversation,
    consumerName,
}) => {
    const { t } = useTranslation();
    const [showName, setShowName] = useState(false);

    const isConsumerMessage = message.sender_type === MESSAGE_SENDER_TYPE.CONSUMER;

    const retrySendMessageMutation = usePostMessageRetry(conversationId, message);
    const postMessageMutation = usePostMessage(conversation, true);

    const retrySendMessage = () => {
        if (message.id) {
            retrySendMessageMutation.mutate();
        } else {
            postMessageMutation.mutate(message);
        }
    };

    const handleBubbleClick = useCallback(() => {
        if (message.status === MESSAGE_STATUS.FAIL) {
            retrySendMessage();
        } else if (!isConsumerMessage) {
            setShowName(!showName);
        }
    }, [message.status, isConsumerMessage, showName]);

    return (
        <MessageBubbleFlexboxWrapper isConsumer={isConsumerMessage}>
            {(message.content_type == MESSAGE_CONTENT_TYPE.IG_STORY_REPLY ||
                message.content_type == MESSAGE_CONTENT_TYPE.IG_STORY_MENTION) && <Separator />}

            <MessageBubbleFlexbox
                flexDirection="column"
                alignItems={isConsumerMessage ? 'flex-start' : 'flex-end'}
            >
                <MessageBubbleContent
                    message={message}
                    consumerName={consumerName}
                    conversation={conversation}
                    handleBubbleClick={handleBubbleClick}
                />
                {
                    <MessageSenderDetail
                        color="secondary"
                        variant="bodySRegular"
                        as="span"
                        show={message.status !== MESSAGE_STATUS.FAIL && showName}
                    >
                        {`${t('messaging_conversation_sender_user_name')} ${message.sender_label}`}
                    </MessageSenderDetail>
                }
                {message.status === MESSAGE_STATUS.FAIL && !isConsumerMessage && (
                    <MessageErrorWrapper justifyContent={'flex-end'} onClick={retrySendMessage}>
                        <MessageErrorRetry variant="bodySRegular" as="span" color={'danger'}>
                            <i className="fa-solid fa-exclamation-circle" />
                            {`${t('messaging_conversation_send_error_retry')}`}
                        </MessageErrorRetry>
                    </MessageErrorWrapper>
                )}
            </MessageBubbleFlexbox>
        </MessageBubbleFlexboxWrapper>
    );
};
