import { isEqual } from 'lodash-es';

import {
    GET_ME_FAILURE,
    GET_ME_SUCCESS,
    GetMeFailureAction,
    GetMeSuccessAction,
} from 'app/common/reducers/me';

// ACTION TYPES
const UPDATE_LOGIN_PASSWORD = 'UPDATE_LOGIN_PASSWORD';
const UPDATE_LOGIN_EMAIL = 'UPDATE_LOGIN_EMAIL';
export const LOGOUT_RESET_REDUCERS_DATA = 'LOGOUT_RESET_REDUCERS_DATA';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_WITH_JWT_TOKEN_REQUEST = 'LOGIN_WITH_JWT_TOKEN_REQUEST';
const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE';
const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
const LOGOUT_REQUEST_FAILURE = 'LOGOUT_REQUEST_FAILURE';
const LOGOUT_REQUEST_SUCCESS = 'LOGOUT_REQUEST_SUCCESS';
const LOGIN_FORM_RESET = 'LOGIN_FORM_RESET';
const SHOW_UNSAFE_PASSWORD_MODAL = 'SHOW_UNSAFE_PASSWORD_MODAL';
const REDIRECT_LOGIN_PAGE = 'REDIRECT_PAGE';

// ACTION FLOW TYPES
export type LogoutResetReducersDataAction = {
    type: 'LOGOUT_RESET_REDUCERS_DATA';
};

export type UpdatePasswordAction = {
    type: 'UPDATE_LOGIN_PASSWORD';
    password: string;
};

export type UpdateEmailAction = {
    type: 'UPDATE_LOGIN_EMAIL';
    email: string;
};

export type LoginRequestAction = {
    type: 'LOGIN_REQUEST';
    apiKey?: string;
    connectionToken?: string;
    idToken?: string;
    oidcParams?: Record<string, any>;
};

type LoginWithJWTTokenRequestAction = {
    type: 'LOGIN_WITH_JWT_TOKEN_REQUEST';
    apiKey?: string;
    connectionToken?: string;
};

type LoginRequestFailureAction = {
    type: 'LOGIN_REQUEST_FAILURE';
    errors: Record<string, any>;
};

type LoginRequestSuccessAction = {
    type: 'LOGIN_REQUEST_SUCCESS';
};

export type LogoutRequestAction = {
    type: 'LOGOUT_REQUEST';
};

type LogoutRequestFailureAction = {
    type: 'LOGOUT_REQUEST_FAILURE';
    errors: Record<string, any>;
};

type LogoutRequestSuccessAction = {
    type: 'LOGOUT_REQUEST_SUCCESS';
};

type LoginFormResetAction = {
    type: 'LOGIN_FORM_RESET';
};

type ShowUnsafePasswordModalAction = {
    type: 'SHOW_UNSAFE_PASSWORD_MODAL';
};

type RedirectLoginPageAction = {
    type: 'REDIRECT_PAGE';
    url: string;
};

export const updatePassword = (password: string): UpdatePasswordAction => ({
    type: UPDATE_LOGIN_PASSWORD,
    password,
});

export const updateEmail = (email: string): UpdateEmailAction => ({
    type: UPDATE_LOGIN_EMAIL,
    email,
});

export const loginRequest = (
    apiKey?: string,
    connectionToken?: string,
    idToken?: string,
    oidcParams?: Record<string, any>,
): LoginRequestAction => ({
    type: LOGIN_REQUEST,
    apiKey,
    connectionToken,
    idToken,
    oidcParams,
});

export const loginWithJWTTokenRequest = (
    apiKey?: string,
    connectionToken?: string,
): LoginWithJWTTokenRequestAction => ({
    type: LOGIN_WITH_JWT_TOKEN_REQUEST,
    apiKey,
    connectionToken,
});

export const loginRequestFailure = (errors: Record<string, any>): LoginRequestFailureAction => ({
    type: LOGIN_REQUEST_FAILURE,
    errors,
});

export const loginRequestSuccess = (): LoginRequestSuccessAction => ({
    type: LOGIN_REQUEST_SUCCESS,
});

export const logoutRequest = (): LogoutRequestAction => ({
    type: LOGOUT_REQUEST,
});

export const logoutRequestFailure = (errors: Record<string, any>): LogoutRequestFailureAction => ({
    type: LOGOUT_REQUEST_FAILURE,
    errors,
});

export const logoutRequestSuccess = (): LogoutRequestSuccessAction => ({
    type: LOGOUT_REQUEST_SUCCESS,
});

export const logoutResetReducersData = (): LogoutResetReducersDataAction => ({
    type: LOGOUT_RESET_REDUCERS_DATA,
});

export const loginFormReset = (): LoginFormResetAction => ({
    type: LOGIN_FORM_RESET,
});

export const showUnsafePasswordModal = (): ShowUnsafePasswordModalAction => ({
    type: SHOW_UNSAFE_PASSWORD_MODAL,
});

export const redirectLoginPage = (url: string): RedirectLoginPageAction => ({
    type: REDIRECT_LOGIN_PAGE,
    url,
});

export type LoginState = {
    email: string;
    password: string;
    errors: Record<string, any>;
    isSubmitting: boolean;
    isSuccess: boolean;
    isLoginOut: boolean;
    showModalPassword: boolean;
    modalRedirectionUrl: string;
};

type LoginAction =
    | UpdatePasswordAction
    | UpdateEmailAction
    | LoginRequestAction
    | LoginWithJWTTokenRequestAction
    | LoginRequestFailureAction
    | LoginRequestSuccessAction
    | LogoutRequestAction
    | LogoutRequestFailureAction
    | LogoutRequestSuccessAction
    | LogoutResetReducersDataAction
    | LoginFormResetAction
    | ShowUnsafePasswordModalAction
    | RedirectLoginPageAction
    | GetMeFailureAction
    | GetMeSuccessAction;

const initialState = {
    email: '',
    password: '',
    errors: {},
    isSubmitting: true,
    isSuccess: false,
    isLoginOut: false,
    showModalPassword: false,
    modalRedirectionUrl: '',
};

const loginReducer = (state: LoginState = initialState, action: LoginAction): LoginState => {
    switch (action.type) {
        case UPDATE_LOGIN_PASSWORD:
            return { ...state, password: action.password };

        case UPDATE_LOGIN_EMAIL:
            return { ...state, email: action.email };

        case LOGIN_REQUEST:
            return { ...state, isSubmitting: true, errors: {} };

        case LOGIN_WITH_JWT_TOKEN_REQUEST:
            return { ...state, isSubmitting: true, errors: {} };

        case LOGIN_REQUEST_FAILURE:
            return { ...state, isSubmitting: false, errors: action.errors };

        case LOGIN_REQUEST_SUCCESS:
            return { ...state, isSubmitting: false, isSuccess: true };

        case SHOW_UNSAFE_PASSWORD_MODAL:
            return { ...state, showModalPassword: true };

        case REDIRECT_LOGIN_PAGE:
            return { ...state, modalRedirectionUrl: action.url };

        case LOGOUT_REQUEST:
            return { ...state, isLoginOut: true, errors: {} };

        case LOGOUT_REQUEST_FAILURE:
            return { ...state, isLoginOut: false, errors: action.errors };

        case LOGOUT_REQUEST_SUCCESS:
            return { ...state, isLoginOut: false };

        case LOGOUT_RESET_REDUCERS_DATA:
            return { ...initialState, isSubmitting: false };

        case LOGIN_FORM_RESET:
            return { ...initialState, isSubmitting: false };

        case GET_ME_FAILURE:
            return { ...state, isSubmitting: false };

        case GET_ME_SUCCESS:
            return { ...state, isSubmitting: false };

        default:
            return state;
    }
};

export const emailSelector = ({ email }: LoginState): string => email;

export const passwordSelector = ({ password }: LoginState): string => password;

export const isSubmittingSelector = ({ isSubmitting }: LoginState): boolean => isSubmitting;

export const isSuccessSelector = ({ isSuccess }: LoginState): boolean => isSuccess;

export const globalErrorSelector = ({ errors: { global } }: LoginState): string | void => global;

export const hasGoogleError = ({ errors }: LoginState): boolean =>
    [
        'Invalid google id_token',
        'Something went wrong with google sign in',
        'Invalid user',
    ].includes(errors.global);

export const emailErrorSelector = ({ errors: { email } }: LoginState): string | void => email;

export const passwordErrorSelector = ({ errors: { password } }: LoginState): string | void =>
    password;

export const showModalPasswordSelector = ({ showModalPassword }: LoginState): boolean =>
    showModalPassword;

export const modalRedirectionUrlSelector = ({ modalRedirectionUrl }: LoginState): string =>
    modalRedirectionUrl;

export const hasErrors = ({ errors }: LoginState) => !isEqual(errors, {});

export default loginReducer;
