import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { CompletionBadgeAnimation } from 'app/businessEditV2/BusinessEditV2.styled';

export const TooltipModalContent = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 556px;
    padding: 40px;
`;

export const TooltipModalTitle = styled.h2`
    width: 298px;
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 16px;
`;

export const TooltipModalSubtitle = styled.p`
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    width: 320px;
    margin-bottom: 32px;
`;

export const BadgeCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
    align-self: normal;
`;

export const BadgeCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.default.initial};
    border-radius: 16px;
    padding: 16px;
    width: 148px;

    &:hover {
        > svg {
            ${CompletionBadgeAnimation({})}
        }
    }
`;

export const BadgeCardTitle = styled.div`
    color: ${HEX_COLORS.white};
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: -20px;
`;

export const BadgeCardDescription = styled.div`
    color: ${greyColorObject.initial};
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0;
    text-align: center;
    margin-top: -20px;
`;

export const BackgroundContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`;

export const CloseButtonContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`;
