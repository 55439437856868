import styled from '@emotion/styled';
import { Button, Text } from '@partoohub/ui';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
`;

export const ContentText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;
`;

export const TextContainer = styled(Text)`
    text-align: center;
    font-feature-settings:
        'clig' off,
        'liga' off;
`;

export const ContentButton = styled.div`
    display: flex;
    gap: 16px;
    align-self: stretch;
`;

export const ButtonContainer = styled(Button)`
    display: flex;
    padding: 16px;
`;
