import { Controller } from 'react-hook-form';

import { PermissionsFormType } from 'app/pages/settingsV2/subPages/Team/components/Permissions/PermissionsSettingsV1';

import PermissionsListSections from './PermissionsListSections';
import PermissionsRoleBanner from './PermissionsRoleBanner';

type Props = {
    form: PermissionsFormType;
};

const PermissionsPageContent = ({ form }: Props) => {
    const { control } = form;

    return (
        <>
            <PermissionsRoleBanner />
            <Controller
                control={control}
                name="businessFields"
                render={({ field: { value, onChange } }) => (
                    <PermissionsListSections value={value} onChange={onChange} />
                )}
            />
        </>
    );
};

export default PermissionsPageContent;
