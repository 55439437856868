import React, { useEffect, useState } from 'react';

import { Banner, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import { AccountPartner, AccountPartnerName, V2FormattedLocationData } from 'app/api/types/account';
import CamelApi from 'app/api/v2/api_calls/camel';
import PartnerIcon from 'app/businessConnection/components/common/PartnerIcon';
import { BUSINESS_LOCATIONS } from 'app/common/data/queryKeysConstants';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';
import { OUTLINED, Type } from 'app/common/designSystem/constants/type';

import useBusiness from 'app/common/hooks/queries/useBusiness';
import formatBusiness from 'app/common/services/formatBusiness';
import {
    BROKEN_LOCATION_STATUS,
    MISSING_LINK_LOCATION_STATUS,
} from 'app/states/knowledge/reducers/businessConnection/connection';

import {
    FullscreenConnectionModal,
    FullscreenConnectionModalFooter,
    FullscreenConnectionModalHeader,
    FullscreenConnectionModalHeaderDescription,
    FullscreenConnectionModalHeaderTitle,
    FullscreenConnectionModalInfos,
    FullscreenConnectionModalScrollable,
    LoadingError,
    LocationCardStyled,
} from './ConnectionModal.styled';
import InfoSection from './InfoSection';
import SearchLocationsSection from './SearchLocationsSection';
import StatefulButton from './StatefulButton.container';
import StatefulDisconnectButton from './StatefulDisconnectButton.container';
import BusinessCard from '../../common/BusinessCard';

interface Props {
    show: boolean;
    closeModal: () => void;
    connectedLocationId: string;
    state: 'connected' | 'searching';
    partnerName: AccountPartnerName;
    partner: AccountPartner;
    businessId: string;
    getBusinessEditFields: (businessId: string) => void;
}

const ConnectionModal = ({
    show,
    closeModal,
    connectedLocationId,
    state,
    partnerName,
    partner,
    businessId,
    getBusinessEditFields,
}: Props) => {
    const { t } = useTranslation();
    const { data: business } = useBusiness(businessId);
    const {
        isLoading,
        isError,
        data: location,
    } = useQuery(
        [BUSINESS_LOCATIONS, { connectedLocationId }],
        () => CamelApi.account.getLocationDetail(connectedLocationId, partner),
        {
            enabled: show && state === 'connected',
        },
    );

    useEffect(() => {
        if (show && partnerName === 'google') {
            getBusinessEditFields(businessId);
        }
    }, [show]);

    const locationHasError = [MISSING_LINK_LOCATION_STATUS, BROKEN_LOCATION_STATUS].includes(
        location?.partnerConnection.status || '',
    );

    const content = (
        <FullscreenConnectionModal>
            <FullscreenConnectionModalScrollable>
                <FullscreenConnectionModalHeader>
                    <FullscreenConnectionModalHeaderTitle>
                        {PartnerIcon(partnerName)}
                        <Text variant="heading3" as="h3">
                            {t(`connection_${partnerName}_location__title`)}
                        </Text>
                    </FullscreenConnectionModalHeaderTitle>
                    <FullscreenConnectionModalHeaderDescription>
                        <Icon
                            icon={[FontAwesomeIconsPartooUsed.faCircleInfo, IconPrefix.SOLID]}
                            color="secondary"
                            iconSize="14px"
                        />
                        <Text variant="bodyMBold" color="secondary">
                            {t(`connection_modal_description_${partnerName}`)}
                        </Text>
                    </FullscreenConnectionModalHeaderDescription>
                    <ConnectionModalBanner
                        isBannerShown={state === 'connected' && locationHasError}
                        partnerName={partnerName}
                    />
                </FullscreenConnectionModalHeader>
                <FullscreenConnectionModalInfos>
                    <Text variant="heading6">{t('your_business')}</Text>
                    {businessId && (
                        <BusinessCard business={business ? formatBusiness(business) : undefined} />
                    )}
                </FullscreenConnectionModalInfos>
                {location && state === 'connected' && (
                    <div className="margin_bottom--triple">
                        <LocationCardStyled
                            location={location as V2FormattedLocationData}
                            partnerName={partnerName}
                            isSelected
                        />
                    </div>
                )}
                {isLoading && <LoadingError>{t('loading')}</LoadingError>}
                {isError && <LoadingError>{t('error')}</LoadingError>}
                {state === 'searching' && show && <SearchLocationsSection />}
            </FullscreenConnectionModalScrollable>
            <FullscreenConnectionModalFooter>
                <Stack direction="row">
                    <StatefulButton />
                    {state === 'connected' && (
                        <StatefulDisconnectButton type={OUTLINED as Type} hideIcon />
                    )}
                </Stack>
            </FullscreenConnectionModalFooter>
        </FullscreenConnectionModal>
    );
    return (
        <FullScreenModal
            show={show}
            childrenLeft={content}
            childrenRight={<InfoSection locationHasError={locationHasError} />}
            onHideClick={closeModal}
        />
    );
};

interface ConnectionModalBannerProps {
    isBannerShown: boolean;
    partnerName: AccountPartnerName;
}

const ConnectionModalBanner = ({ isBannerShown, partnerName }: ConnectionModalBannerProps) => {
    const { t } = useTranslation();
    const [isShown, setIsShown] = useState<boolean>(isBannerShown);

    useEffect(() => {
        setIsShown(isBannerShown);
    }, [isBannerShown]);

    const onClose = () => {
        setIsShown(false);
    };

    if (!isShown) return null;

    return (
        <Banner
            dataTrackId={`${partnerName}_connection_modal_banner`}
            variant="danger"
            onClose={onClose}
        >
            {t(`${partnerName}_connection_modal_banner__permission_lost`)}
        </Banner>
    );
};

export default ConnectionModal;
