import { useCallback, useEffect, useMemo } from 'react';

import { UseFormReturn, useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import {
    CustomChatHours,
    MessagingSetting,
    SettingFormReturn,
} from 'app/api/types/business_message_settings';
import { DAYS } from 'app/businessEditV2/oldFromEditV1/data/constants';
import usePrevious from 'app/common/hooks/usePrevious';

export type SettingFormData = UseFormReturn<SettingFormReturn> & {
    selectedSetting?: MessagingSetting;
};

export const useSettingForm = (selectedSetting?: MessagingSetting) => {
    const { t } = useTranslation();
    const customChatHours = !!selectedSetting?.customChatHours
        ? DAYS.reduce(
              (a, b) => ({
                  ...a,
                  [b]: selectedSetting.customChatHours[b].map((window: string) => ({
                      startHour: window.slice(0, 5),
                      endHour: window.slice(-5),
                  })),
              }),
              {} as CustomChatHours,
          )
        : [];
    const previousSelectedSetting = usePrevious(selectedSetting);
    const defaultValues = useMemo(
        () => ({
            isLaunched: selectedSetting?.isLaunched,
            usesBusinessHours: !selectedSetting?.usesBusinessHours,
            welcomeMessage:
                selectedSetting?.welcomeMessage ??
                t('default_welcome_message_settingsV2', {
                    agent_name: selectedSetting?.businessName ?? '',
                }),
            offlineMessage:
                selectedSetting?.offlineMessage ??
                t('default_offline_message_settingsV2', {
                    agent_name: selectedSetting?.businessName ?? '',
                }),
            customChatHours: customChatHours,
        }),
        [selectedSetting],
    );

    const form = useForm({
        mode: 'onSubmit',
        defaultValues,
        shouldUnregister: false,
        reValidateMode: 'onChange',
    });

    const resetForm = useCallback(() => {
        form.reset(defaultValues, {
            keepValues: false,
            keepErrors: false,
            keepIsValid: false,
            keepDirtyValues: true,
        });
    }, [defaultValues, form.reset]);

    useEffect(() => {
        if (
            selectedSetting &&
            (form.formState.isSubmitSuccessful || selectedSetting === previousSelectedSetting)
        ) {
            resetForm();
        } else if (selectedSetting) {
            form.reset(defaultValues);
        }
    }, [selectedSetting, form.formState.isSubmitSuccessful, previousSelectedSetting]);

    /*
     * When watchUsesBusinessHours becomes false,
     * the onChange function is called to reset the value of customChatHours to its default value.
     */
    useEffect(() => {
        if (!form.getValues('usesBusinessHours')) {
            form.setValue('customChatHours', customChatHours);
        }
    }, [form.getValues('usesBusinessHours')]);

    return {
        ...form,
        selectedSetting,
    };
};
