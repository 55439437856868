import React, { useEffect, useRef, useState } from 'react';

import { uniqueId } from 'lodash-es';
import ProgressBar from 'progressbar.js';

import { ColorProgressProps } from 'app/businessEditV2/sections/BusinessStatusSection/BusinessProgressBar/BusinessProgressBar';

import useBusinessSearchStats from 'app/pages/visibility/BusinessListV2/hooks/businesses/useBusinessSearchStats';

import { CircleProgressBar } from './AverageProgressBar.styled';

type Props = {
    value: number;
    color: ColorProgressProps;
};

const AverageProgressBar = ({ value, color }: Props) => {
    const circleRef = useRef<HTMLDivElement>(null);
    const refBar = useRef<any>(null);
    const DURATION_IN_MS = 1400;

    const { isLoading } = useBusinessSearchStats();

    const [progressBarId] = useState(uniqueId('progress-bar-id-'));

    useEffect(() => {
        if (circleRef.current && !refBar.current) {
            refBar.current = new ProgressBar.Circle(circleRef?.current, {
                color: 'url(#gradient)',
                trailColor: color.default,
                strokeWidth: 13,
                trailWidth: 13,
                easing: 'easeInOut',
                strokeLinecap: 'round',
                duration: DURATION_IN_MS,
            });
        }
    }, [circleRef, refBar, color]);

    useEffect(() => {
        const animateCircleProgressBar = () => {
            if (refBar.current) {
                refBar.current.trail.setAttribute('stroke-linecap', 'round');
                refBar.current.trail.setAttribute('stroke', color.default);
                refBar.current.trail.setAttribute('trail-color', color.default);

                document.getElementById(progressBarId)?.remove();

                const linearGradient = `
                    <defs id="${progressBarId}">
                        <linearGradient
                            id="gradient"
                            gradientTransform="rotate(90)"
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="0%"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0%" stop-color="${color.gradient.start}"/>
                            <stop offset="20%" stop-color="${color.gradient.middle}"/>
                            <stop offset="90%" stop-color="${color.gradient.middle}"/>
                            <stop offset="100%" stop-color="${color.gradient.end}"/>
                        </linearGradient>
                    </defs>
                `;

                refBar.current.svg.insertAdjacentHTML('afterbegin', linearGradient);

                // Hack for weird chrome behavior with progressbar.js
                if (!isLoading) {
                    refBar.current.animate(value / 100);
                }
            }
        };

        if (circleRef.current) {
            animateCircleProgressBar();
        }
    }, [value, circleRef, refBar, color, isLoading]);

    return <CircleProgressBar ref={circleRef} />;
};

export default AverageProgressBar;
