import { useContext, useEffect, useState } from 'react';

import { useQuery, useQueryClient } from 'react-query';

import { PUBLISHERS } from 'app/businessConnection/components/genericQuickConnect/publishers';
import QuickConnectContext from 'app/businessConnection/components/genericQuickConnect/QuickConnect.context';
import { SUGGESTIONS } from 'app/common/data/queryKeysConstants';

export const useSuggestions = initialState => {
    const { publisher, setSelectedSuggestions } =
        useContext(QuickConnectContext);

    const [suggestions, setSuggestions] = useState(initialState);

    const { isLoading, data: suggestionData } = useQuery(
        [SUGGESTIONS, publisher],
        PUBLISHERS[publisher].endpoints.suggestion,
    );

    useEffect(() => {
        // @ts-ignore
        if (!isLoading) setSuggestions(suggestionData.results || []);
    }, [suggestionData]);

    const queryClient = useQueryClient();

    useEffect(
        // @ts-ignore
        () => () =>
            queryClient.cancelQueries(SUGGESTIONS).then(() => {
                setSelectedSuggestions([]);
            }),
        [],
    );

    return {
        state: [suggestions, setSuggestions],
        isLoading,
    };
};
