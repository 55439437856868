import { ReactNode } from 'react';

import { Text } from '@partoohub/ui';

import Clickable from 'app/common/components/buttons/Clickable';
import IframeMobilePageHeaderSideBarButton, {
    MobilePageHeaderSideBarButton,
} from 'app/common/components/pageHeaders/MobilePageHeaderSideBarButton';

import {
    MobilePageHeaderContainer,
    MobilePageHeaderTitle,
    MobilePageLeftHeaderAction,
    StyledClickable,
} from './MobilePageHeader.styled';

type Props = {
    title: string;
    // Optional props
    subtitle?: string;
    titleAction?: () => void;
    leftIcon?: string;
    leftAction?: () => void;
    leftContent?: ReactNode;
    rightIcon?: string;
    rightAction?: () => void;
    rightContent?: ReactNode;
    hideLeftContentOnIframe?: boolean;
};

const MobilePageHeader = ({
    leftIcon,
    leftAction,
    leftContent,
    title,
    subtitle,
    titleAction,
    rightIcon,
    rightAction,
    rightContent,
    hideLeftContentOnIframe = false,
}: Props) => (
    <MobilePageHeaderContainer>
        {hideLeftContentOnIframe ? (
            <IframeMobilePageHeaderSideBarButton
                content={leftContent}
                onClick={leftAction}
                icon={leftIcon}
            />
        ) : (
            <MobilePageHeaderSideBarButton
                content={leftContent}
                onClick={leftAction}
                icon={leftIcon}
            />
        )}
        {titleAction ? (
            <StyledClickable onClick={titleAction}>
                <Text variant="heading6" oneLine>
                    {title}
                </Text>
                {subtitle && (
                    <Text className="bodySRegular" color="primary" oneLine>
                        {subtitle}
                    </Text>
                )}
            </StyledClickable>
        ) : (
            <MobilePageHeaderTitle>
                <Text variant="heading6" oneLine>
                    {title}
                </Text>
                {subtitle && (
                    <Text className="bodySRegular" color="primary" oneLine>
                        {subtitle}
                    </Text>
                )}
            </MobilePageHeaderTitle>
        )}
        <MobilePageLeftHeaderAction variant="heading2">
            {rightContent || (
                <Clickable className="flex--justify_end" onClick={rightAction || (() => undefined)}>
                    {rightIcon && <i className={rightIcon} />}
                </Clickable>
            )}
        </MobilePageLeftHeaderAction>
    </MobilePageHeaderContainer>
);

export default MobilePageHeader;
