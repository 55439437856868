import { format } from 'date-fns/format';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { DATE_FORMAT } from 'app/utils/dates';

import {
    REVIEW_ANALYTICS_FILTER_DATE_FROM,
    REVIEW_ANALYTICS_FILTER_DATE_TO,
} from './useReviewAnalyticsFilters';

export const useReviewAnalyticsDefaultFilter = () => {
    const [from] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_DATE_FROM);
    const [to] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_DATE_TO);

    const now = new Date();

    const defaultFrom = format(
        new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() - 12, 1)),
        DATE_FORMAT,
    );
    const defaultTo = format(
        new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 0)),
        DATE_FORMAT,
    );

    const isDefaultDateFilter = from === defaultFrom && to === defaultTo;

    const queryParams = new URLSearchParams({
        [REVIEW_ANALYTICS_FILTER_DATE_FROM]: defaultFrom,
        [REVIEW_ANALYTICS_FILTER_DATE_TO]: defaultTo,
    });
    const defaultDateQuery = queryParams.toString();

    return { defaultDateQuery, isDefaultDateFilter };
};
