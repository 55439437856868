import { CommentData } from 'app/api/types/comments';

import { Comment } from './Comment';
import CommentReply from './CommentReply';
import { StyledCommentTable } from './CommentTable.styled';

type Props = {
    parentId?: number;
    comments: Array<CommentData>;
    isReplying: boolean;
    setIsReplying?: (isReplying: boolean) => void;
};

export function CommentTable({ comments, parentId, isReplying, setIsReplying }: Props) {
    return (
        <StyledCommentTable>
            {comments.map((comment, index) => (
                <Comment
                    key={comment.id}
                    parentId={parentId}
                    comment={comment}
                    hasNoLink={index + 1 === comments.length && !isReplying}
                />
            ))}
            {isReplying && (
                <CommentReply
                    parentId={parentId}
                    isReplying={isReplying}
                    setIsReplying={setIsReplying}
                />
            )}
        </StyledCommentTable>
    );
}
