import { toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls/camel';
import {
    MESSAGE_REPLY_TEMPLATE_CREATE,
    MESSAGING_REPLY_TEMPLATES,
} from 'app/common/data/queryKeysConstants';

export type MessageReplyTemplateData = {
    title: string;
    icon?: string;
    content: string;
};

export const useCreateMessageReplyTemplate = (onSuccess?: () => void) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const query = useMutation(
        (replyMessageTemplateData: MessageReplyTemplateData) =>
            api.messagingReplyTemplates.createMessageReplyTemplate(replyMessageTemplateData),
        {
            mutationKey: MESSAGE_REPLY_TEMPLATE_CREATE,
            // Should be useful
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [MESSAGING_REPLY_TEMPLATES],
                });
                toast.success(null, t('success'));

                if (onSuccess) {
                    onSuccess();
                }
            },
        },
    );
    return query;
};

export default useCreateMessageReplyTemplate;
