export type ColorShades = {
    transparent: string;
    light: string;
    initial: string;
    dark: string;
};

export type ColorDisabled = {
    shape: string;
    text: string;
};

export type ColorSocials = {
    facebook: string;
    foursquare: string;
    google: string;
    tripAdvisor: string;
    twitter: string;
};

export type ColorProducts = {
    knowledge: ColorShades;
    reviewBooster: ColorShades;
};

export type ColorsWithShades = {
    primary: ColorShades;
    success: ColorShades;
    warning: ColorShades;
    danger: ColorShades;
    ratings: ColorShades;
    blackberry: ColorShades;
    blueberry: ColorShades;
    banana: ColorShades;
    rasberry: ColorShades;
    grape: ColorShades;
    orange: ColorShades;
    black: ColorShades;
};

export type Colors = {
    shaded: ColorsWithShades;
    others: {
        secondary: string;
        light: string;
        info: string;
        white: string;
        black: string;
    };
    disabled: ColorDisabled;
    social: ColorSocials;
    products: ColorProducts;
};

export const COLORS_FLOW: Colors = {
    shaded: {
        primary: {
            transparent: 'rgba(0, 133, 242, 0.12)',
            light: 'rgba(105, 203, 255, 1)',
            initial: 'rgba(0, 133, 242, 1)',
            dark: 'rgba(0, 99, 179, 1)',
        },
        success: {
            transparent: 'rgba(64, 219, 162, 0.12)',
            light: 'rgba(152, 244, 210, 1)',
            initial: 'rgba(64, 219, 162, 1)',
            dark: 'rgba(51, 175, 130, 1)',
        },
        warning: {
            transparent: 'rgba(255, 144, 20, 0.12)',
            light: 'rgba(255, 200, 138, 1)',
            initial: 'rgba(255, 144, 20, 1)',
            dark: 'rgba(204, 115, 16, 1)',
        },
        danger: {
            transparent: 'rgba(255, 66, 110, 0.12)',
            light: 'rgba(255, 161, 183, 1)',
            initial: 'rgba(255, 66, 110, 1)',
            dark: 'rgba(204, 53, 88, 1)',
        },
        ratings: {
            transparent: 'rgba(247, 194, 0, 0.12)',
            light: 'rgba(255, 221, 99, 1)',
            initial: 'rgba(247, 194, 0, 1)',
            dark: 'rgba(198, 155, 0, 1)',
        },
        blackberry: {
            transparent: 'rgba(20, 37, 66, 0.12)',
            light: 'rgba(43, 59, 85, 1)',
            initial: 'rgba(20, 37, 66, 1)',
            dark: 'rgba(16, 29, 52, 1)',
        },
        blueberry: {
            transparent: 'rgba(3, 159, 241, 0.12)',
            light: 'rgba(105, 203, 255, 1)',
            initial: 'rgba(0, 133, 242, 1)',
            dark: 'rgba(2, 127, 193, 1)',
        },
        banana: {
            transparent: 'rgba(247, 194, 0, 0.12)',
            light: 'rgba(255, 221, 99, 1)',
            initial: 'rgba(247, 194, 0, 1)',
            dark: 'rgba(198, 155, 0, 1)',
        },
        rasberry: {
            transparent: 'rgba(255, 66, 110, 0.12)',
            light: 'rgba(255, 161, 183, 1)',
            initial: 'rgba(255, 66, 110, 1)',
            dark: 'rgba(204, 53, 88, 1)',
        },
        grape: {
            transparent: 'rgba(138, 93, 242, 0.12)',
            light: 'rgba(197, 174, 249, 1)',
            initial: 'rgba(138, 93, 242, 1)',
            dark: 'rgba(110, 74, 193, 1)',
        },
        orange: {
            transparent: 'rgba(255, 144, 20, 0.12)',
            light: 'rgba(255, 200, 138, 1)',
            initial: 'rgba(255, 144, 20, 1)',
            dark: 'rgba(204, 115, 16, 1)',
        },
        black: {
            transparent: 'rgba(0, 0, 0, 0.37)',
            light: 'rgba(0, 0, 0, 1)',
            initial: 'rgba(51, 51, 51, 1)',
            dark: 'rgba(255, 255, 255, 1)',
        },
    },
    others: {
        secondary: 'rgba(127, 142, 164, 1)',
        light: 'rgba(223, 227, 236, 1)',
        info: 'rgba(208, 210, 219, 1)',
        white: 'rgba(255, 255, 255, 1)',
        black: 'rgba(0, 0, 0, 1)',
    },
    disabled: {
        shape: 'rgba(127, 142, 164, 0.12)',
        text: 'rgba(127, 142, 164, 0.25)',
    },
    social: {
        facebook: 'rgba(24, 119, 242, 1)',
        foursquare: 'rgba(236, 75, 136, 1)',
        google: 'rgba(209, 60, 31, 1)',
        tripAdvisor: 'rgba(52, 224, 161, 1)',
        twitter: 'rgba(29, 161, 242, 1)',
    },
    products: {
        knowledge: {
            transparent: 'rgba(138, 93, 242, 0.12)',
            light: 'rgba(197, 174, 249, 1)',
            initial: 'rgba(138, 93, 242, 1)',
            dark: 'rgba(110, 74, 193, 1)',
        },
        reviewBooster: {
            transparent: 'rgba(52, 188, 95, 0.12)',
            light: 'rgba(154, 222, 175, 1)',
            initial: 'rgba(52, 188, 95, 1)',
            dark: 'rgba(42, 150, 76, 1)',
        },
    },
};
