import {
    IllustrationCategoryEnum,
    IllustrationNetworkElement,
    IllustrationNetworkEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { DeviceType, useDeviceType } from 'app/hooks';
import { useHideIntercom } from 'app/hooks/useHideIntercom';

type Props = {
    onClick: () => void;
};

export const NotFoundPage = ({ onClick }: Props) => {
    const { t } = useTranslation();
    const { device } = useDeviceType();
    const isMobile = device === DeviceType.Mobile || device === DeviceType.MobileApp;

    useHideIntercom(isMobile);

    const illustrationElement: IllustrationNetworkElement = {
        type: IllustrationCategoryEnum.Network,
        name: IllustrationNetworkEnum.NotFound,
    };

    return (
        <NotFoundTemplate
            show
            title={t('page_not_found_title')}
            subtitle={t('page_not_found_description')}
            imgSrc={illustrationElement}
            buttonText={t('page_not_found_button')}
            handleClick={onClick}
            withButton={!isMobile}
        />
    );
};
