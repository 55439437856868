import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { ALL_PUBLISHERS } from 'app/common/designSystem/components/molecules/GooglePostCard/PostCard';
import { TopicTypeEnum } from 'app/presence/googlePosts/variables/googlePostType';

export enum PostDiffuserName {
    INSTAGRAM = 'instagram__posts',
    FACEBOOK = 'facebook__posts',
    GOOGLE = 'google_my_business__posts',
}

export type BusinessQueryParams = {
    business__in: Array<string>;
};

type PostLink = {
    use_business_url: boolean;
    custom_url: string;
};

export type CreatePostBody = {
    post_type: TopicTypeEnum;
    summary?: string;
    schedule_time?: string;
    image_url?: Array<string>;
    title?: string;
    link?: PostLink;
    cta_type?: string;
};

export type UpdatePostBody = {
    summary?: string;
    title?: string;
    link?: PostLink;
    image_url?: string;
    cta_type?: string;
};

export type PostInsight = {
    name: PostDiffuserName;
    click_count: number;
    view_count: number;
};

export type PostDiffuser = {
    name: PostDiffuserName;
    state: string;
    link: string;
};

export type PostMedia = {
    media_url: string;
    media_type: string;
    media_format: string;
    order: number;
};

export type Post = {
    id: number;
    business_id: string;
    bulk_id: number;
    post_type: string;
    language: string;
    title: string;
    summary: string;
    cta_type: string;
    cta_link: string;
    start_at: string;
    end_at: string;
    schedule_time: string;
    facebook_link: string;
    post_insight: PostInsight[];
    post_status: PostDiffuser[];
    post_medias: PostMedia[];
    offer_terms: string;
    offer_code: string;
    created_at: string;
    updated_at: string;
};

export type PostResponse = {
    max_page: number;
    count: number;
    page: number;
    posts: Post[];
};

export type PostFilterParams = BusinessModalFiltersType & {
    page: number;
    topic_type__in: Array<string>;
    state__in: Array<string>;
    platform__in: Array<string>;
    title: string;
    summary: string;
    start_datetime: Date;
    end_datetime: Date;
};

export const BUSINESS_IS_DRAFT = 'business_is_draft';
export const BUSINESS_IS_NOT_SUBSCRIBED_TO_PM = 'business_is_not_subscribed_to_pm';
export const PUBLISHER_CONNECTION_REQUIRED = 'publisher_connection_required';
export const PUBLISHER_SCOPE_MISSING = 'publisher_scope_missing';
export const BUSINESS_IS_NOT_DIFFUSIBLE = 'business_not_diffusible';
export const BUSINESS_IS_CLOSED = 'this_business_is_closed';

const postStateErrorMessages = [
    BUSINESS_IS_DRAFT,
    BUSINESS_IS_NOT_SUBSCRIBED_TO_PM,
    PUBLISHER_CONNECTION_REQUIRED,
    PUBLISHER_SCOPE_MISSING,
    BUSINESS_IS_NOT_DIFFUSIBLE,
    BUSINESS_IS_CLOSED,
] as const;
export type PostStateErrorMessage = (typeof postStateErrorMessages)[number];

export type PostState = {
    [k in (typeof ALL_PUBLISHERS)[number]]: {
        state: string;
        error_message: PostStateErrorMessage;
        expected_error_message: PostStateErrorMessage;
    };
};
