import { useContext, useEffect } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';
// TODO: Replace by InfiniteScroll from 'react-infinite-scroll-component'
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { TPost } from 'app/api/v2/api_calls/postApiCalls';
import useReduxFilteredBusinesses from 'app/common/components/businessModal/hooks/useReduxFilteredBusinesses';
import {
    applyFilterByBusinessFilters,
    setIncludedIdsFilter,
} from 'app/common/components/businessModalDeprecated/reducers';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { RefreshPostsContext } from 'app/presence/googlePosts/components/googlePostList/index';
import LoadingCard from 'app/presence/googlePosts/components/googlePostList/LoadingPostCard';
import PostCard from 'app/presence/googlePosts/components/googlePostList/PostCard.container';
import { PostsTableStyled } from 'app/presence/googlePosts/components/googlePostList/PostsTable.styled';
import { POST_STATES_TO_HIDE } from 'app/presence/googlePosts/components/services/constants';
import { STATUS_FILTER_PARAM } from 'app/presence/googlePosts/hooks/filters/useStatusFilter';
import { ALL_GOOGLE_POSTS } from 'app/presence/googlePosts/variables/googlePostStatus';

import {
    EditPostsContext,
    EditPostsContextType,
} from './PostCreationModal/hooks/useEditPostContext';
import { usePostQueryContext } from './PostCreationModal/hooks/usePostQueryContext';

type Props = {
    onDeletePost: (postId: number, bulkId: number) => void;
    onPostCountUpdate: (count: number) => void;
};

export default function PostsTable({ onDeletePost, onPostCountUpdate }: Props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { refreshPosts, setRefreshPosts } = useContext(RefreshPostsContext);
    const {
        setPostId,
        setBulkId,
        setPostCreationModalEditMode,
        setShowCreationModal,
        setPostToEditData,
        setDuplicateMode,
        setDuplicateModeData,
    } = useContext<EditPostsContextType>(EditPostsContext);

    const { data, hasNextPage, isFetching, isLoading, fetchNextPage, refetch } =
        usePostQueryContext();

    const [searchParams] = useSearchParams();

    const hasActiveFilters =
        searchParams.size > 1 ||
        (searchParams.has(STATUS_FILTER_PARAM) &&
            searchParams.get(STATUS_FILTER_PARAM) !== ALL_GOOGLE_POSTS);

    useEffect(() => {
        if (data) {
            const { count } = data.pages[0];
            onPostCountUpdate(count);
        }
    }, [data]);

    useEffect(() => {
        if (refreshPosts) {
            refetch();
            setRefreshPosts(false);
        }
    }, [refreshPosts]);

    const postList = data
        ? data.pages
              // @ts-ignore
              .reduce((acc, { posts }) => [...acc, ...posts], [])
              // @ts-ignore
              .filter(post => !POST_STATES_TO_HIDE.includes(post.state))
              .map(post => {
                  // Temporary solution, need to be unified
                  let { postType } = post;
                  if (post.postType === 'standard') {
                      postType = 'news';
                  }
                  if (post.postType === 'alert') {
                      postType = 'covid';
                  }
                  return { ...post, postType };
              })
        : [];

    const onLoadMore = (): void => {
        if (isFetching) {
            return;
        }
        fetchNextPage();
    };

    const onEditClicked = async (post: TPost, isBulk: boolean): Promise<void> => {
        setPostId(post.id);
        setBulkId(isBulk ? post.bulkId : null);
        setPostCreationModalEditMode(true);
        setShowCreationModal(true);
        setPostToEditData(post);

        dispatch(setIncludedIdsFilter([post.businessId]));
        dispatch(applyFilterByBusinessFilters());
    };

    const { businesses } = useReduxFilteredBusinesses();
    const onDuplicate = (post: TPost): void => {
        setDuplicateMode(true);
        setDuplicateModeData(post);
        setShowCreationModal(true);

        // Handling user only having access to 1 business
        if (businesses?.length === 1) {
            dispatch(setIncludedIdsFilter([businesses[0].id]));
            dispatch(applyFilterByBusinessFilters());
        }
    };

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };

    return (
        <div>
            <InfiniteScroll
                hasMore={hasNextPage}
                loadMore={onLoadMore}
                useWindow={false}
                getScrollParent={() => document.getElementById('react-content')}
                threshold={500}
            >
                <PostsTableStyled>
                    {postList.map(post => (
                        <PostCard
                            post={post}
                            key={`post-${post.id}`}
                            onDelete={isBulk => onDeletePost(post.id, isBulk ? post.bulkId : null)}
                            onEdit={isBulk => onEditClicked(post, isBulk)}
                            onDuplicate={() => onDuplicate(post)}
                        />
                    ))}
                    {isLoading && <LoadingCard />}
                </PostsTableStyled>
            </InfiniteScroll>

            {!isLoading && postList.length === 0 && (
                <NotFoundTemplate
                    withButton={false}
                    show
                    title={t('google_post_empty_message')}
                    subtitle={
                        hasActiveFilters
                            ? t('google_post_update_filters_message')
                            : t('google_post_invite_to_create_message')
                    }
                    imgSrc={illustrationElement}
                />
            )}
        </div>
    );
}
