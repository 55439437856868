import { Link, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import FunnelModal from 'app/common/components/FunnelModal';

import {
    FunnelModalFooter,
    FunnelModalHeader,
} from 'app/common/components/FunnelModal/FunnelModal.styled';

import useMe from 'app/common/hooks/queries/useMeUncamel';

import dataLayer from 'app/common/utils/dataLayer';

import { STATIC_APP_ASSETS_URL } from 'app/config';

import { FunnelModalFooterButton } from './FunnelModalFooterButton';
import {
    CenteredBody,
    CircleContainer,
    ContentContainer,
    DescriptionContainer,
    DescriptionTextContainer,
    ModalBodyContainer,
    Picto,
    TextContainer,
    TitleContainer,
} from './WhatsappAccountCreationModalPrerequirements.styled';
import { WhatsappEmbeddedSignUpButton } from './WhatsappEmbeddedSignUpButton';

const META_BUSINESS_SUITE_URL = 'https://business.facebook.com/';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onEmbeddedSignupFinished: () => void;
    isNumberChoiceAllowed?: boolean;
    isLoading: boolean;
    onClick: () => void;
    isSmb: boolean;
};

const WhatsappAccountCreationModalPrerequirements = ({
    isOpen,
    onClose,
    onEmbeddedSignupFinished,
    isNumberChoiceAllowed,
    isLoading,
    onClick,
    isSmb,
}: Props) => {
    const { t } = useTranslation();
    const step2_description = isSmb
        ? t('whatsapp_account_creation_modal_step2_description')
        : t('whatsapp_account_creation_modal_step2_description_enterprise');

    const { data: me } = useMe();

    const handleClick = () => {
        dataLayer.pushDict('next_prerequisites_wa', {
            partoo_user_id: me?.id,
            org_id: me?.org_id,
        });
        onClick();
    };

    const footerButton =
        isLoading || isNumberChoiceAllowed ? (
            <FunnelModalFooterButton
                onClick={handleClick}
                disabled={isLoading}
                isLoading={isLoading}
                icon={['fa-arrow-right']}
                iconPosition={'right'}
                buttonText={
                    isLoading ? '' : t('whatsapp_account_creation_prerequirement_next_button')
                }
            />
        ) : (
            <WhatsappEmbeddedSignUpButton
                onEmbeddedSignupFinished={onEmbeddedSignupFinished}
                gtmEvent={'next_meta_prerequisites_wa'}
                onClose={onClose}
            />
        );

    return (
        <FunnelModal isOpen={isOpen} onClose={onClose}>
            <FunnelModalHeader>
                <Text variant="heading6" color="blackberry">
                    {t('whatsapp_account_creation_modal_header')}
                </Text>
            </FunnelModalHeader>

            <ModalBodyContainer>
                <CenteredBody>
                    <TitleContainer>
                        <Text variant="heading3" color="blackberry">
                            ☝
                        </Text>
                        <Text variant="heading3" color="blackberry">
                            {t('whatsapp_account_creation_modal_title')}
                        </Text>
                    </TitleContainer>

                    <ContentContainer>
                        <Picto
                            src={`${STATIC_APP_ASSETS_URL}/whatsapp/whatsapp_account_creation_modal_image.svg`}
                        />
                        <TextContainer>
                            <DescriptionContainer>
                                <CircleContainer variant="heading4" color="primary">
                                    1
                                </CircleContainer>
                                <DescriptionTextContainer>
                                    <Text variant="heading6" color="blackberry">
                                        {t('whatsapp_account_creation_modal_step1')}
                                    </Text>
                                    <Text variant="bodyLRegular" color="secondary">
                                        {t('whatsapp_account_creation_modal_step1_description')}
                                        &nbsp;
                                        <Link
                                            href={META_BUSINESS_SUITE_URL}
                                            target="_blank"
                                            textVariant="bodyLRegular"
                                        >
                                            {META_BUSINESS_SUITE_URL}
                                        </Link>
                                    </Text>
                                </DescriptionTextContainer>
                            </DescriptionContainer>
                            <DescriptionContainer>
                                <CircleContainer variant="heading4" color="primary">
                                    2
                                </CircleContainer>

                                <DescriptionTextContainer>
                                    <Text variant="heading6" color="blackberry">
                                        {t('whatsapp_account_creation_modal_step2')}
                                    </Text>
                                    <Text variant="bodyLRegular" color="secondary">
                                        {step2_description}
                                    </Text>
                                </DescriptionTextContainer>
                            </DescriptionContainer>
                        </TextContainer>
                    </ContentContainer>
                </CenteredBody>
            </ModalBodyContainer>

            <FunnelModalFooter>{footerButton}</FunnelModalFooter>
        </FunnelModal>
    );
};

export default WhatsappAccountCreationModalPrerequirements;
