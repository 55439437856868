import React from 'react';

import { LoadingViewContainer } from 'app/common/components/LoadingView.container';
import Loader from 'app/common/designSystem/components/atoms/svg/Loader';

const LoginLoadingView = () => (
    <LoadingViewContainer>
        <Loader />
    </LoadingViewContainer>
);

export default LoginLoadingView;
