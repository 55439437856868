import { useState } from 'react';

import { IconButton, Tooltip } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useIsMobile } from 'app/common/services/screenDimensions';
import dataLayer from 'app/common/utils/dataLayer';

interface Props {
    text: string;
    tooltipText: string;
    gtmEvent: string;
}

const CopyButton = ({ text, tooltipText, gtmEvent }: Props) => {
    const { t } = useTranslation();
    const { data: me } = useMe();
    const isMobile = useIsMobile();

    const [lastClickedAt, setLastClickedAt] = useState<number>(0);
    const copiedMessageDelay = 1000; // 1s

    const handleClick = () => {
        dataLayer.pushDict(gtmEvent, {
            partoo_user_id: me?.id,
            org_id: me?.org_id,
        });

        setLastClickedAt(Date.now());
        navigator.clipboard.writeText(text);
        setTimeout(() => {
            setLastClickedAt(previousValue => previousValue - 1);
        }, copiedMessageDelay);
    };

    const buttonJustClicked = Date.now() - lastClickedAt < copiedMessageDelay;
    const hoverTooltip = isMobile ? '' : tooltipText;
    const actualTooltipText = buttonJustClicked
        ? t('whatsapp_account_list__link_copied')
        : hoverTooltip;

    return (
        <Tooltip text={actualTooltipText} placement="top">
            <IconButton
                dataTrackId={''}
                icon={['fa-copy']}
                appearance="outlined"
                onClick={handleClick}
            />
        </Tooltip>
    );
};

export default CopyButton;
