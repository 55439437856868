import {
    Button,
    ButtonAppearance,
    ButtonProps,
    ButtonSize,
    FontAwesomeIconsPartooUsed,
    IconPrefix,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

type Props = {
    isLoading: boolean;
    hasError: boolean;
    onClick: () => void;
    dataTrackId: string;
    size?: ButtonSize;
    full?: boolean;
    appearance?: ButtonAppearance;
    overrideLabel?: string;
    disabled?: boolean;
    hideIcon?: boolean;
};

const EditFormStatefulButton = ({
    isLoading,
    hasError,
    onClick,
    dataTrackId,
    size = 'large',
    full = true,
    appearance = 'contained',
    overrideLabel = '',
    disabled = false,
    hideIcon = false,
}: Props) => {
    const { t } = useTranslation();

    const getButtonProps = (): ButtonProps => {
        const commonButtonProps = {
            size,
            appearance,
            full,
            dataTrackId,
            onClick,
            shape: 'cube',
            iconPosition: 'left',
        } as const; // Fixes typing issues
        if (isLoading) {
            return {
                ...commonButtonProps,
                variant: 'secondary',
            };
        }
        if (hasError) {
            return {
                ...commonButtonProps,
                variant: 'danger',
                icon: [FontAwesomeIconsPartooUsed.faFrown, IconPrefix.SOLID],
            };
        }
        return {
            ...commonButtonProps,
            variant: 'primary',
            icon: [FontAwesomeIconsPartooUsed.faFloppyDisk, IconPrefix.SOLID],
        };
    };
    const getButtonLabel = () => {
        if (isLoading) {
            return 'loading';
        }
        if (hasError) {
            return 'update_error';
        }
        return 'save';
    };

    const buttonProps = getButtonProps();

    if (hideIcon) {
        buttonProps.icon = undefined;
    }

    return (
        <Button {...buttonProps} disabled={disabled} isLoading={isLoading}>
            {overrideLabel || t(getButtonLabel())}
        </Button>
    );
};

export default EditFormStatefulButton;
