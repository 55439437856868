import React, { ReactNode, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import Clickable from 'app/common/components/buttons/Clickable';

export type Props = {
    value: string;
    onChange: (...args: Array<any>) => any;
    // optional props
    id?: string;
    name?: string;
    initValue?: string;
    rightIcon?: ReactNode;
    leftIcon?: ReactNode;
    placeholder?: string;
    rightAction?: ((...args: Array<any>) => any) | null;
    focusOnInput?: boolean;
};

const SearchField = ({
    value,
    onChange,
    id,
    name,
    initValue,
    rightIcon = <i className="fa-solid fa-times" />,
    leftIcon,
    placeholder = '',
    rightAction,
    focusOnInput,
}: Props) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);

    useEffect(() => {
        if (initValue !== undefined) {
            onChange(initValue);
        }

        if (focusOnInput) {
            // @ts-ignore
            setTimeout(() => inputRef.current && inputRef.current.focus(), 200);
        }
    }, [initValue]);

    return (
        <div className="search-field-input">
            {leftIcon && (
                <div className="search-field-input__lefticon">{leftIcon}</div>
            )}
            <div className="search-field-input__input">
                <input
                    id={id || name}
                    name={name}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    placeholder={t(placeholder)}
                    ref={inputRef}
                />
            </div>
            {value && rightIcon && (
                <Clickable
                    onClick={rightAction || (() => onChange(''))}
                    className="search-field-input__righticon"
                >
                    {rightIcon}
                </Clickable>
            )}
        </div>
    );
};

export default SearchField;
