import { ChangeEvent, Dispatch, SetStateAction, useContext } from 'react';

import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { Option, Section, Stack } from '@partoohub/ui';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { V2BusinessData } from 'app/api/types/business';
import { BusinessUser } from 'app/api/types/user';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { BUSINESS_MANAGER, GROUP_MANAGER, ORG_ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import dataLayer from 'app/common/utils/dataLayer';
import { useChatbots } from 'app/pages/settings/Chatbot/hooks/useChatbots';
import { ChatbotType } from 'app/pages/settings/Chatbot/type/chatbot';
import { CONVERSATIONS_MESSAGING_PATH } from 'app/routing/routeIds';
import {
    AssignFilterOption,
    Conversation,
    StatusFilterOption,
    URLParams,
} from 'app/states/messaging';

import queryClient from 'app/states/queryClient';

import { CenteredText, OverridenMenuList } from './AssignConversationButton.styled';
import {
    BusinessMenuChoice,
    ChatbotMenuChoice,
    UnassignedMenuChoice,
    UserMenuChoice,
} from './AssignConversationMenuChoices';

import { getNextActiveConversation } from '../../ConversationUtils';
import { useGetConversations } from '../../hooks/useGetConversations';
import useUpdateConversationAssignedUser from '../../hooks/useUpdateConversationAssignedUser';
import { MessagingContext } from '../../MessagingContext';
import { createAssignmentMessage } from '../../services/createMetadataMessage';
import { removeConversation, updateConversation } from '../../services/handleConversationsCache';
import { addNewMessage } from '../../services/handleMessagesCache';

const DATATRACK_UNASSIGN = 'unassign';
const DATATRACK_ME = 'assign to me';
const DATATRACK_USER = 'assign to user';

type Interface = {
    users: Array<BusinessUser>;
    businesses: Array<V2BusinessData> | undefined;
    searchValue: string;
    setIsMenuShown: Dispatch<SetStateAction<boolean>>;
    activeConversation: Conversation;
    assignedUserId: string | null;
    maxHeight?: number;
    handleClickBusiness: (businessId: string) => void;
    isBusinessUsersMenuShown: boolean;
};

const NoResultsPanel = () => {
    const { t } = useTranslation();

    const label = t('messaging_assign_cta_search_no_result_found');

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Monocle,
    };

    return (
        <Stack direction="column" alignItems="center" style={{ padding: '8px 0' }}>
            <Illustration
                dataTrackId="no_result__illustration"
                illustration={illustrationElement}
                width="308"
                height="135"
            />
            <CenteredText variant="heading6" as="span">
                {label}
            </CenteredText>
        </Stack>
    );
};

export const AssignConversationMenuList = ({
    users,
    businesses,
    searchValue,
    setIsMenuShown,
    activeConversation,
    assignedUserId,
    maxHeight,
    handleClickBusiness,
    isBusinessUsersMenuShown,
}: Interface) => {
    const { data: me } = useMe();
    const { data: chatbots } = useChatbots();
    const { t } = useTranslation();
    const activeConversationId = activeConversation.id;
    const { setActiveConversation } = useContext(MessagingContext);
    const updateConversationAssignedUser = useUpdateConversationAssignedUser(activeConversationId);
    const navigate = useNavigate();

    const [statusFilter] = useStateQueryParams<StatusFilterOption>(URLParams.STATUS);
    const [assignFilter] = useStateQueryParams<AssignFilterOption>(URLParams.ASSIGN);
    const businessFilters = useBusinessModalFilters();

    const { conversations } = useGetConversations();

    const getSections = () => {
        const usersExceptMe = users.filter(user => user.id !== me?.id);
        const usersSortedByRole = getUsersSortedByRole(usersExceptMe);
        const roleSections = Object.entries(usersSortedByRole).reduce(
            (acc, [role, usersByRole]) => {
                if (usersByRole.length === 0) {
                    return acc;
                }

                const options = usersByRole.map(user => createUserMenuChoice(t, user)) as Option[];
                acc.push({ label: t(role), options: options });

                return acc;
            },
            [] as Array<Section>,
        );

        const unassignedUser = createUnassignedMenuChoice(t);
        const chatbotUser = createChatbotMenuChoice(t, activeConversation, chatbots);
        const meUser = users.find(user => user.id === me?.id);
        const meChoice = meUser && createUserMenuChoice(t, meUser, true);
        const unlabeledSection: Section = {
            options: [unassignedUser, meChoice, chatbotUser].filter(Boolean) as Option[],
        };

        const sections = [unlabeledSection].concat(roleSections);

        let businessOption = {};
        if (businesses) {
            businessOption = businesses.map(business =>
                createBusinessMenuChoice(business),
            ) as Option[];
            sections.push({ label: 'Etablissement', options: businessOption });
        }
        const filteredSections = searchValue
            ? sections.reduce((filteredSections: Array<Section>, section: Section) => {
                  const filteredUsers = section.options.filter(option =>
                      option.name.toLowerCase().includes(searchValue.toLowerCase()),
                  );
                  if (filteredUsers.length !== 0)
                      filteredSections.push({ label: section.label, options: filteredUsers });
                  return filteredSections;
              }, [])
            : sections;

        return filteredSections;
    };

    const handleChange = (option: Option, e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.type !== 'checkbox') return;
        const newAssignedUserId = option.value;
        if (option.isBusiness) {
            handleClickBusiness(option.value);
        } else {
            setIsMenuShown(false);

            if (
                !(!assignedUserId && newAssignedUserId === AssignFilterOption.UNASSIGNED) &&
                assignedUserId !== newAssignedUserId
            ) {
                updateConversationAssignedUser.mutate({
                    assignedUserId: newAssignedUserId,
                });
                sendDatatrack(me?.id!, newAssignedUserId);

                const updatedConversation = {
                    ...activeConversation,
                    assigned_user_id:
                        newAssignedUserId === AssignFilterOption.UNASSIGNED
                            ? null
                            : newAssignedUserId,
                    is_automated: AssignFilterOption.AUTOMATED === option.value,
                };
                updateConversation(
                    updatedConversation,
                    statusFilter,
                    assignFilter,
                    businessFilters,
                );
                setActiveConversation(updatedConversation);

                const senderLabel = `${me?.first_name} ${me?.last_name}`;
                const isSelfAssigning = me?.id === option.value;
                // To remove the me suffix associated to the connected user.
                const assignToName = isSelfAssigning ? senderLabel : option.name;
                const assignedToLabel =
                    option.value === AssignFilterOption.UNASSIGNED ? '' : assignToName;
                const metadataMessage = createAssignmentMessage(
                    activeConversation,
                    assignedToLabel,
                    me,
                );
                addNewMessage(queryClient, activeConversationId, metadataMessage);

                if (
                    assignFilter === AssignFilterOption.UNASSIGNED ||
                    assignFilter === AssignFilterOption.ME ||
                    assignFilter === AssignFilterOption.AUTOMATED
                ) {
                    removeCurrentConversationFromStack();
                }
            }
        }
    };

    const removeCurrentConversationFromStack = () => {
        const nextConversation = getNextActiveConversation(
            conversations,
            activeConversation,
            activeConversationId,
        );
        removeConversation(activeConversationId, statusFilter, assignFilter, businessFilters);

        setActiveConversation(nextConversation);
        if (nextConversation)
            navigate(`${CONVERSATIONS_MESSAGING_PATH}?conversationId=${nextConversation.id}`);
    };

    const handleChecked = (option: Option) => {
        return (
            option.value === assignedUserId ||
            (assignedUserId === null &&
                !activeConversation.is_automated &&
                option.value === AssignFilterOption.UNASSIGNED) ||
            (activeConversation.is_automated && option.value === AssignFilterOption.AUTOMATED)
        );
    };

    return (
        <OverridenMenuList
            sections={
                isBusinessUsersMenuShown
                    ? getBusinessUsers(t, users, searchValue, me?.id)
                    : getSections()
            }
            isChecked={handleChecked}
            onChange={handleChange}
            emptyMessage={<NoResultsPanel />}
            maxHeight={maxHeight}
        />
    );
};

const createUserMenuChoice = (t: TFunction, user: BusinessUser, me = false) => {
    const meLabel = `(${t('messaging_assign_filter_me')})`;
    const userLabel = `${user.firstName} ${user.lastName}`;
    const name = (me && `${userLabel} (${meLabel})`) || userLabel;

    return {
        label: <UserMenuChoice user={user} isMe={me} />,
        value: user.id,
        name,
    };
};

const createUnassignedMenuChoice = (t: TFunction) => {
    const name = t('messaging_assign_cta_unassigned');
    return {
        label: <UnassignedMenuChoice />,
        value: AssignFilterOption.UNASSIGNED,
        name,
    };
};

const createBusinessMenuChoice = (business: V2BusinessData) => {
    return {
        label: <BusinessMenuChoice business={business} />,
        value: business.id,
        name: business.name,
        isBusiness: true,
    };
};

const createChatbotMenuChoice = (
    t: TFunction,
    activeConversation: Conversation,
    chatbots?: ChatbotType[],
) => {
    // Check if any chatbot is related to the conversation and if it is enabled.
    const conversation_related_chatbot = chatbots?.find(chatbot =>
        chatbot.business_ids.includes(activeConversation.business.id),
    );

    if (!conversation_related_chatbot?.enabled) return null;
    const name = t('messaging_assign_chatbot');
    return {
        label: <ChatbotMenuChoice />,
        value: AssignFilterOption.AUTOMATED,
        name,
    };
};

const getUsersSortedByRole = (users: Array<BusinessUser>) => {
    const roles = [ORG_ADMIN, GROUP_MANAGER, BUSINESS_MANAGER];
    const usersByRole: { [role: string]: Array<BusinessUser> } = roles.reduce(
        (acc, role) => {
            acc[role] = [];
            return acc;
        },
        {} as Record<string, Array<BusinessUser>>,
    );
    const sortedUsers = users.reduce((acc, user) => {
        const role = user.role;
        acc[role].push(user);
        return acc;
    }, usersByRole);

    return sortedUsers;
};

const getBusinessUsers = (
    t: TFunction,
    users: Array<BusinessUser>,
    searchValue: string,
    meId?: string,
) => {
    const roles = [GROUP_MANAGER, BUSINESS_MANAGER];

    const usersExceptMe = users.filter(user => user.id !== meId);
    const usersWithAllowedRole = usersExceptMe.filter(user => roles.includes(user.role));

    const usersOptions = usersWithAllowedRole.map(user =>
        createUserMenuChoice(t, user),
    ) as Option[];
    const filteredOptions = searchValue
        ? usersOptions.filter(option =>
              option.name.toLowerCase().includes(searchValue.toLowerCase()),
          )
        : usersOptions;
    if (filteredOptions.length === 0) {
        return [] as Array<Section>;
    }
    return [{ label: '', options: filteredOptions }];
};

const sendDatatrack = (meId: string, newAssignedUserId: string) => {
    const getDataTrackValue = (meId: string, newAssignedUserId: string) => {
        if (meId === newAssignedUserId) return DATATRACK_ME;
        if (newAssignedUserId === AssignFilterOption.UNASSIGNED) return DATATRACK_UNASSIGN;
        return DATATRACK_USER;
    };

    const datatrackValue = getDataTrackValue(meId, newAssignedUserId);
    dataLayer.pushDict('messaging_action_assignation', {
        status: datatrackValue,
    });
};
