import { camelCase, mapKeys, reduce } from 'lodash-es';

import { V2FormattedOrgData, V2OrgData } from 'app/api/types/org';

type FormattedOrgs = {
    // @ts-ignore
    byId: Record<number, V2FormattedOrgData>;
    ids: Array<number>;
};

const formatOrgs = (users: Array<V2OrgData>): FormattedOrgs =>
    // @ts-ignore
    reduce(
        users,
        (obj: FormattedOrgs, org: V2OrgData) => ({
            byId: {
                ...obj.byId,
                [org.org_id]: mapKeys(org, (v, key) => camelCase(key)),
            },
            ids: [...obj.ids, org.org_id],
        }),
        {
            byId: {},
            ids: [],
        },
    );

export default formatOrgs;
