import styled from '@emotion/styled';

export const BUSINESS_LIST_BREAKPOINT = 'min-width: 1180px';

export const BusinessListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1100px;
`;
