import React from 'react';

import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import dataLayer from 'app/common/utils/dataLayer';
import {
    BusinessListQueryKeys,
    CompletionRateValues,
} from 'app/pages/visibility/BusinessListV2/utils/enums';

import {
    Badge,
    BusinessBadgeContainer,
    Content,
    Count,
    FilterButton,
    FilterButtonContent,
    Title,
} from './BusinessCompletionBadge.styled';

type Props = {
    title: string;
    icon: React.ReactNode;
    completionRateFilter: CompletionRateValues;
    count?: number;
};

const BusinessCompletionBadge = ({ title, count, icon, completionRateFilter }: Props) => {
    const { t } = useTranslation();
    const [completions, setCompletions] = useStateQueryParams(
        BusinessListQueryKeys.COMPLETION_RATE,
    );

    const isActive: boolean = completions.split(',').filter(Boolean).includes(completionRateFilter);

    const toggleActive = () => {
        const completionsValues = completions.split(',').filter(Boolean);

        if (isActive) {
            completionsValues.splice(completionsValues.indexOf(completionRateFilter), 1);
        } else {
            completionsValues.push(completionRateFilter);
            dataLayer.pushDict('click_badges_completion', {
                completion_score_badge: completionRateFilter,
            });
        }

        setCompletions(completionsValues.join(','));
    };

    return (
        <BusinessBadgeContainer
            selected={isActive}
            onClick={toggleActive}
            data-track={`visibility_locations__completion_badge_${completionRateFilter}`}
            data-intercom-target={`visibility_locations__completion_badge_${completionRateFilter}`}
        >
            <Title>{title}</Title>
            <Badge>{icon}</Badge>
            <Content>
                <FilterButton>
                    <FilterButtonContent>{t('business_list_filter')}</FilterButtonContent>
                </FilterButton>
                <Count>{count ?? '-'}</Count>
            </Content>
        </BusinessBadgeContainer>
    );
};

export default BusinessCompletionBadge;
