import React from 'react';

import { IconButton, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import {
    EmptyButton,
    EmptyTextInput,
    HoursContainer,
    OpenHourAllDayContainer,
    OpenHourIconButtons,
} from 'app/common/components/OpenHour/OpenHour.styled';

type Props = {
    startHourTextValue?: string;
    applyDefaultHours?: () => void;
    disabled: boolean;
};

const HoursItemPlaceHolder = ({ startHourTextValue, applyDefaultHours, disabled }: Props) => {
    const { t } = useTranslation();

    return (
        <HoursContainer>
            <OpenHourAllDayContainer>
                <div>
                    {startHourTextValue && (
                        <TextInput
                            dataTrackId="start_hour_text"
                            disabled
                            value={startHourTextValue}
                            valueContainer=""
                        />
                    )}
                </div>
                {/* Empty div for spacing */}
                <EmptyTextInput />
            </OpenHourAllDayContainer>
            <OpenHourIconButtons>
                <>
                    {/* Empty button for spacing */}
                    <EmptyButton />
                    {applyDefaultHours && (
                        <IconButton
                            appearance="outlined"
                            icon={['fa-bolt-lightning']}
                            dataTrackId="open-hour-placeholder-apply-default-hours"
                            onClick={applyDefaultHours}
                            disabled={disabled}
                            tooltip={t('apply_time_slot_reference')}
                        />
                    )}
                </>
            </OpenHourIconButtons>
        </HoursContainer>
    );
};

export default HoursItemPlaceHolder;
