import { Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { CardTitle } from 'app/pages/analytics/VisibilityAnalyticsV2/components/CardTitle/CardTitle';
import {
    HelperCardDescription,
    NoDataContainer,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/HelperCard/HelperContent/HelperContent.styled';
import { NoDataIcon } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Icons';

type ColorProps = {
    hex: string;
    rgba: string;
};
type HelperCardContent = {
    key: string;
    icon: string;
    color: ColorProps;
    title: string;
    description: string;
};
type Props = {
    content: Array<HelperCardContent>;
};
export const HelperContent = ({ content }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            {content && content.length > 0 ? (
                content.map(element => (
                    <Stack
                        gap="8px"
                        justifyContent="center"
                        alignItems="center"
                        key={`helper-content-${element.key}`}
                    >
                        <CardTitle
                            color={element.color}
                            icon={<i className={`fa fa-fas ${element.icon}`}></i>}
                        >
                            {t(element.title)}
                        </CardTitle>
                        <HelperCardDescription>
                            <Text
                                variant="bodyMRegular"
                                children={t(element.description)}
                                color={'secondary'}
                            ></Text>
                        </HelperCardDescription>
                    </Stack>
                ))
            ) : (
                <NoDataContainer>
                    <NoDataIcon />
                </NoDataContainer>
            )}
        </>
    );
};
