import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationVariousElement,
    IllustrationVariousEnum,
} from '@partoohub/iconography';
import { startOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useOrgFieldMetadata } from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useReviews from 'app/common/hooks/queries/reviews/useReviews';
import { useTodoReviewsNavigationPath } from 'app/pages/Homepage/hooks/todos/todosItems/reviews/useTodoReviewsNavigationPath';
import { DefaultTodoComponent } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent';
import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import useOrgPermissions from 'app/pages/settingsV2/subPages/Team/components/Permissions/hooks/useOrgPermissions';
import { API_NOT_TREATED_REVIEWS } from 'app/states/reviews/filters';

export const TodoReviews = (props: SmallTodoProps) => {
    const { t } = useTranslation();

    const sixMonthsAgo = startOfDay(new Date(new Date().setMonth(new Date().getMonth() - 6)));

    const { data: dataReviews, isLoading } = useReviews({
        // Only reviews that are not treated
        state__in: [API_NOT_TREATED_REVIEWS],
        // Reviews from the last 6 months
        update_date__gte: sixMonthsAgo.toISOString(),
    });

    const unTreatedReviews = dataReviews?.count ?? 0;

    const { isLoading: isLoadingPermissions } = useOrgPermissions();
    const hasReviewsPermission =
        useOrgFieldMetadata('review_management', 'review_reply')?.enabled ?? false;

    const navigate = useNavigate();
    const navigateTo = useTodoReviewsNavigationPath();

    const illustrationElement: IllustrationVariousElement = {
        type: IllustrationCategoryEnum.Various,
        name: unTreatedReviews
            ? IllustrationVariousEnum.Reviews
            : IllustrationVariousEnum.ReviewsTreated,
    };

    return (
        <DefaultTodoComponent
            {...props}
            {...(unTreatedReviews
                ? {
                      title: `${t('homepage_todo_reviews_unanswered_title', {
                          count: unTreatedReviews,
                      })}`,
                      actionText: hasReviewsPermission
                          ? `${t('homepage_todo_unanswered_description')}`
                          : `${t('homepage_todo_unanswered_description_no_permission')}`,
                  }
                : {
                      title: `${t('homepage_todo_reviews_answered_title')}`,
                      description: `${t('homepage_todo_reviews_answered_description')}`,
                  })}
            icon={
                <Illustration
                    dataTrackId="homepage_reviews__illustration"
                    illustration={illustrationElement}
                    height="98"
                    width="120"
                />
            }
            isLoading={isLoading || isLoadingPermissions}
            onClick={() => navigate(navigateTo)}
        />
    );
};
