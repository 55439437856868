import { delay } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import {
    partnerToPartnerName,
    partnerToPartnerResourceName,
} from 'app/businessConnection/services/PartnerToAccountsMethods';
import { unsetLocationId } from 'app/common/reducers/newBusinesses';
import { Saga } from 'app/common/types';
import {
    DISCONNECT_BUSINESS_FROM_LOCATION,
    PartnerConnectionState,
    disconnectBusinessFromLocationFailure,
    disconnectBusinessFromLocationSuccess,
    hideConnectionModal,
    resetConnectionModal,
    selectedBusinessIdSelector,
    selectedPartnerSelector,
} from 'app/states/knowledge/reducers/businessConnection/connection';
import { connectionSelector } from 'app/states/reducers';

function* disconnectBusinessFromLocation(): Saga {
    try {
        const connectionState = (yield select(
            connectionSelector,
        ) as any) as PartnerConnectionState;
        const businessId = selectedBusinessIdSelector(connectionState);
        const partnerResourceName =
            partnerToPartnerResourceName[
                selectedPartnerSelector(connectionState)
            ];
        const partnerName =
            partnerToPartnerName[selectedPartnerSelector(connectionState)];

        if (businessId) {
            yield call(
                api.businessModule.publisherConnection
                    .disconnectBusinessFromLocation,
                businessId,
                partnerResourceName,
            );
            // @ts-ignore
            yield put(unsetLocationId(businessId, partnerName));
        }

        yield put(disconnectBusinessFromLocationSuccess());
        yield call(delay, 1000);
        yield put(hideConnectionModal());
        yield put(resetConnectionModal());
    } catch (error) {
        yield put(disconnectBusinessFromLocationFailure(error));
    }
}

function* disconnectBusinessFromLocationSaga(): Saga {
    yield takeEvery(
        DISCONNECT_BUSINESS_FROM_LOCATION,
        disconnectBusinessFromLocation,
    );
}

export default disconnectBusinessFromLocationSaga;
