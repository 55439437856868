import { useTranslation } from 'react-i18next';

import { StyledConfirmModal } from 'app/common/components/UnsavedChangesModal/UnsavedChangesModalStyled';

type Props = {
    show: boolean;
    onConfirm: () => void;
    onHide: () => void;
};

export default function UnsavedChangesModal({
    show,
    onConfirm,
    onHide,
}: Props) {
    const { t } = useTranslation();

    return (
        <StyledConfirmModal
            modalFor="danger"
            trackId="unsaved_changes_modal"
            title={t('unsaved_changes_modal_title')}
            content={t('unsaved_changes_modal_description')}
            show={show}
            onConfirm={onConfirm}
            onHide={onHide}
            cancelLabel={t('unsaved_changes_modal_stay')}
            confirmLabel={t('unsaved_changes_modal_leave')}
        />
    );
}
