import { useContext } from 'react';

import { Icon, IconPrefix, Text } from '@partoohub/ui';
import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';

import PostImageInfoCriteria from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/PostImageInfo/PostImageInfoCriteria';
import {
    NewPostContext,
    NewPostContextType,
} from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/context/NewPost';
import {
    GOOGLE,
    INSTAGRAM,
    PLATFORMS_ACCEPTED_PHOTOS_EXTENSIONS,
    PLATFORMS_IMAGE_CRITERIA,
    Platform,
} from 'app/presence/googlePosts/components/services/constants';

import {
    PostImageInfoContainer,
    PostImageInfoContentContainer,
    PostImageInfoContentTitleContainer,
    PostImageInfoCriteriaListContainer,
    PostImageInfoHeader,
    PostMultiPhotoWarningContainer,
} from './PostImageInfo.styled';
import usePlatformSelection from '../../hooks/usePlatformSelection';
import { MULTI_IMAGE_MAX_PHOTO_COUNT } from '../../screens/ImageUpload/ImageUpload';

export const mergeAcceptedFiles = (platforms: Array<Platform>) =>
    intersection(...platforms.map(p => PLATFORMS_ACCEPTED_PHOTOS_EXTENSIONS[p] ?? []));

const getCriteria = (selectedPlatforms: Array<Platform>) => {
    let criteria;
    if (selectedPlatforms.includes(GOOGLE)) criteria = PLATFORMS_IMAGE_CRITERIA.GOOGLE;
    else if (!selectedPlatforms.includes(INSTAGRAM)) criteria = PLATFORMS_IMAGE_CRITERIA.FACEBOOK;
    else criteria = PLATFORMS_IMAGE_CRITERIA.INSTAGRAM;

    if (selectedPlatforms.includes(INSTAGRAM)) {
        criteria = [
            ...criteria,
            {
                icon: '',
                imgIcon: 'aspect-ratio',
                description: 'post_image_criteria_ratio',
                value: 'MIN 4:5 - MAX 17:9',
            },
        ];
    }

    return [
        ...criteria,
        // Add format criteria
        {
            icon: 'fa-light fa-image-polaroid',
            description: 'post_image_criteria_format',
            value: mergeAcceptedFiles(selectedPlatforms)
                .map(ext => ext.toUpperCase())
                .join(', '),
        },
    ];
};

export function PostImageInfo() {
    const { t } = useTranslation();
    const {
        formFields: { platform },
    } = useContext<NewPostContextType>(NewPostContext);
    const { hasOnlyGoogle, containsGoogle, containsInstagram } = usePlatformSelection();
    const canAcceptMultiImages = !hasOnlyGoogle;

    const postInfoDescription = canAcceptMultiImages
        ? t('post_multi_photo_info_description__min_max', {
              min: 1,
              max: MULTI_IMAGE_MAX_PHOTO_COUNT,
          })
        : t('post_image_info_description');
    return (
        <PostImageInfoContainer>
            <PostImageInfoHeader>
                <Text variant="heading5">
                    {t('post_image_info_title', {
                        count: canAcceptMultiImages ? MULTI_IMAGE_MAX_PHOTO_COUNT : 1,
                    })}
                </Text>
                <Text variant="bodyMRegular">{postInfoDescription}</Text>
                {canAcceptMultiImages && containsGoogle && (
                    <PostMultiPhotoWarningContainer>
                        <Icon icon={['fa-circle-exclamation', IconPrefix.SOLID]} color="warning" />
                        <Text variant="bodyMBold" color="warning">
                            {t('post_multi_photo_unavailable_on_google_warning')}
                        </Text>
                    </PostMultiPhotoWarningContainer>
                )}
                {containsInstagram && (
                    <PostMultiPhotoWarningContainer>
                        <Icon icon={['fa-circle-exclamation', IconPrefix.SOLID]} color="warning" />
                        <Text variant="bodyMBold" color="warning">
                            {t('post_multi_photo_ratio_on_instagram_warning')}
                        </Text>
                    </PostMultiPhotoWarningContainer>
                )}
            </PostImageInfoHeader>

            <PostImageInfoContentContainer>
                <PostImageInfoContentTitleContainer>
                    <Icon icon={['fa-info-circle', IconPrefix.SOLID]} iconSize="12px" />
                    <Text variant="bodyMBold">{t('post_image_info_content_title')}</Text>
                </PostImageInfoContentTitleContainer>
                <PostImageInfoCriteriaListContainer>
                    {getCriteria(platform).map(criteria => (
                        <PostImageInfoCriteria
                            key={criteria.value}
                            icon={criteria.icon || criteria?.imgIcon || ''}
                            description={t(criteria.description)}
                            value={t(criteria.value)}
                            imgIcon={criteria?.imgIcon}
                        />
                    ))}
                </PostImageInfoCriteriaListContainer>
            </PostImageInfoContentContainer>
        </PostImageInfoContainer>
    );
}
