import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const PublisherImage = styled.img`
    height: 45px;
    width: auto;
    min-width: 45px;
`;

export const PublisherTextName = styled(Text)`
    max-width: 110px;
`;
