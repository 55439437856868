import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { CommonButton } from 'app/common/components/funnel/Buttons.styled';
import FunnelContext from 'app/common/components/funnel/FunnelContext';
import {
    FullPageContainer,
    SubTitle,
    Title,
} from 'app/common/components/funnel/FunnelModal.styled';
import { STATIC_BASE_URL } from 'app/config';

const CongratulationsPage = () => {
    const { t } = useTranslation();
    const funnel = useContext(FunnelContext);

    return (
        <FullPageContainer>
            <img
                src={`${STATIC_BASE_URL}/images/app/success.svg`}
                alt="success"
            />
            <Title>{t('import_in_progress')}</Title>
            <SubTitle>
                {t('onboarding_congratulations_page_sub_title')}
            </SubTitle>
            <CommonButton
                dataTrackId="complete_onboarding"
                shape="cube"
                size="large"
                appearance="contained"
                variant="primary"
                onClick={() => {
                    funnel.navigate('add_location_page');
                    funnel.done();
                }}
            >
                {t('onboarding_congratulations_page_button')}
            </CommonButton>
        </FullPageContainer>
    );
};

export default CongratulationsPage;
