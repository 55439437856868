import { ConversationRouterData } from 'app/pages/settings/ConversationRouting/type/ConversationRouter';

import ApiResourceBaseClass from './baseApiCalls';

export class ConversationRouter extends ApiResourceBaseClass {
    static getConversationRouter(): Promise<ConversationRouterData> {
        return ConversationRouter.get('messaging/settings/router').then(({ data }) => data);
    }

    static patchConversationRouter(enabled: boolean): Promise<ConversationRouterData> {
        return ConversationRouter.patch('messaging/settings/router', { enabled }).then(
            ({ data }) => data,
        );
    }
}
