import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { connect } from 'react-redux';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';

const mapStateToProps = () => {
    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.VeryHappySuccess,
    };
    return {
        show: true,
        withButton: false,
        imgSrc: illustrationElement,
        title: 'no_business_to_connect',
        subtitle: 'no_business_to_connect_description',
    };
};

const NoBusinessToConnect = connect(mapStateToProps)(NotFoundTemplate);

export default NoBusinessToConnect;
