import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import { CONVERSATION_STARTERS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

export function useConversationStarterDuplicate(conversationStarterId: string) {
    return useMutation(
        () => api.conversationStarters.duplicateConversationStarter(conversationStarterId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([CONVERSATION_STARTERS]);
            },
        },
    );
}
