import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

export const NoBusinessPlaceholderContainer = styled.div`
    background-color: ${greyColorObject.light};
    text-align: center;
    padding: 64px 0;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
`;

export const PlaceholderTitle = styled.div`
    color: ${HEX_COLORS.blackberry};
`;

export const PlaceholderDescription = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin-bottom: 12px;
`;

export const PlaceholderButton = styled.button`
    color: ${({ theme }) => theme.colors.primary.initial};
    border: none;
    background: none;

    &:hover {
        color: ${({ theme }) => theme.colors.primary.dark};
    }
`;
