import styled from '@emotion/styled';

import { Button, TextInput } from '@partoohub/ui';

export const ApiKeyShowModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 8px 8px;
`;

export const StyledTextInput = styled(TextInput)`
    width: 352px;
    margin: 13px 24px 32px 24px;
`;

export const StyledButton = styled(Button)`
    width: 100%;
`;
