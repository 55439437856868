import styled from '@emotion/styled';

export const FloatingFooter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    position: absolute;
    bottom: 0;
    left: 0;

    padding: 24px;

    width: 100%;
`;

export const FooterChangeMethodWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 3px;
`;
