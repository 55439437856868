import styled from '@emotion/styled';
import { HEX_COLORS, blackColorObject, greyColorObject } from '@partoohub/branding';

import { PhotoSizeCss } from 'app/businessEditV2/sections/PhotosSection/PhotosForm/PhotosTable/PhotosTable.styled';
import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

type PhotoProps = {
    url: string;
    content?: string;
};

export const PhotoContainer = styled.div<PhotoProps>`
    border: solid 1px ${DISABLED_COLOR};
    background-image: url('${props => props.url}');
    background-position: center center;
    background-size: cover;
    background-color: ${greyColorObject.light};
    background-repeat: no-repeat;
    cursor: pointer;

    ${PhotoSizeCss};
`;

export const PhotoOverlay = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
    color: ${HEX_COLORS.white};
    padding: 16px;
    font-size: 12px;

    &:hover {
        opacity: 1;
    }
`;

export const EditIcon = styled.div`
    transition: all ease-in-out 0.2s;
    margin-right: 6px;

    &:hover {
        color: ${blackColorObject['35']};
    }
`;

export const DeleteIcon = styled.div`
    transition: all ease-in-out 0.2s;

    &:hover {
        color: ${({ theme }) => theme.colors.danger.initial};
    }
`;

export const SelectedPhotoOverlay = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    top: 0;
    left: 0;
    border-radius: 4px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    color: ${HEX_COLORS.white};
    margin: 12px;
    font-size: 12px;
    font-weight: bold;
    height: 24px;
    padding: 0 8px;
    transition: all ease-in-out 0.2s;

    &:hover {
        color: ${blackColorObject['35']};
    }
`;
