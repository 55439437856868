import { useEffect, useMemo, useState } from 'react';

import { Banner, Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { LiteralRating } from 'app/api/types/review';
import { ALL_TEMPLATES } from 'app/api/types/reviewReplyTemplates';
import LoadingView from 'app/common/components/LoadingView';
import { NoReviewManagement } from 'app/common/components/placeholder/reviewMangement/NoReviewManagement';
import { APP_PRODUCTS } from 'app/common/data/productIds';
import { ADMIN } from 'app/common/data/roles';
import { useReplyTemplates } from 'app/common/hooks/queries/ReviewReplyTemplates/useReplyTemplates';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrgLanguages from 'app/common/hooks/queries/useOrgLanguages';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { LANGUAGES_MAP } from 'app/common/services/languages';
import useGetTemplatePlaceholders from 'app/pages/settings/AutoReply/hooks/queries/useGetTemplatePlaceholders';
import AlertTemplateHasAutoReplySettingsModal from 'app/reviewManagement/replyTemplate/modal/AlertTemplateHasAutoReplySettings';
import ConfirmDeleteReplyTemplateModal from 'app/reviewManagement/replyTemplate/modal/ConfirmDeleteReplyTemplateModal';

import { ReplyTemplateFilters } from './pageHeader/ReplyTemplateFilters';
import { ReplyTemplateTable } from './ReplyTemplateTable';

export function ReplyTemplates() {
    const { subscribedToRM, isLoading: subscriptionsLoading } = useBusinessSubscriptions();

    const [rating] = useStateQueryParams('rating');
    const [search] = useStateQueryParams('search');
    const [creatingTemplate, setCreatingTemplate] = useStateQueryParams('creatingTemplate', false);
    const [languages, setLanguages] = useStateQueryParams('languages', false);
    const [pageIsReady, setPageIsReady] = useState(false);

    const {
        data: { languages: orgLanguages } = { languages: [] },
        isLoading: languagesLoading,
        isFetched,
    } = useOrgLanguages({ product: APP_PRODUCTS.REVIEW_MANAGEMENT });

    const [replyTemplates, { data, isLoading, hasNextPage, fetchNextPage }] = useReplyTemplates(
        {
            languages: languages ? languages?.split(',') : [],
            ratings:
                rating && rating !== ALL_TEMPLATES ? (rating.split(',') as LiteralRating[]) : [],
            search,
            titleSearch: search,
        },
        { enabled: pageIsReady },
    );
    const replyTemplatesCount = data?.pages[0].count ?? 0;

    const hasUnavailableLanguage = useMemo(() => {
        return orgLanguages.some(langCode => !LANGUAGES_MAP[langCode]);
    }, [orgLanguages]);

    useEffect(() => {
        if (!languages && isFetched && !hasUnavailableLanguage) {
            setLanguages(orgLanguages.join());
        }

        setPageIsReady(true);
    }, [isFetched]);

    const placeholders = useGetTemplatePlaceholders();

    if (subscriptionsLoading || languagesLoading || !placeholders || !pageIsReady) {
        return <LoadingView />;
    }

    if (!subscribedToRM) return <NoReviewManagement />;

    const handleNotCreatingTemplate = () => {
        setCreatingTemplate('false');
    };

    return (
        <>
            <ConfirmDeleteReplyTemplateModal />
            <AlertTemplateHasAutoReplySettingsModal />
            <Stack direction="column">
                <ReviewTemplateWarningBanner />
                <ReplyTemplateFilters templateCount={replyTemplatesCount} />
                <ReplyTemplateTable
                    replyTemplates={replyTemplates}
                    isLoading={isLoading}
                    hasNextPage={!!hasNextPage}
                    fetchNextPage={fetchNextPage}
                    creatingTemplate={creatingTemplate === 'true'}
                    onAfterCreateTemplate={handleNotCreatingTemplate}
                />
            </Stack>
        </>
    );
}

const ReviewTemplateWarningBanner = () => {
    const { t } = useTranslation();
    const role = useMe().data?.role;

    const isAdmin = role === ADMIN;
    const [isShown, setIsShown] = useState<boolean>(isAdmin);

    if (!isShown) return null;

    const onClose = () => {
        setIsShown(false);
    };

    return (
        <Banner dataTrackId="" variant="warning" onClose={onClose}>
            {t('review_template_warning_message')}
        </Banner>
    );
};
