import { useMemo } from 'react';

import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { ReplySuggestion, ReviewObjectType } from 'app/api/types/review';
import api from 'app/api/v2/api_calls/';
import queryClient from 'app/states/queryClient';
import { ReviewObject } from 'app/states/reviews';

export const useReportSuggestionMutation = (
    review: ReviewObject,
    currentSuggestion: ReplySuggestion | undefined,
    setCurrentSuggestion: (suggestion: ReplySuggestion) => void,
    closeReportModal: () => void,
) => {
    const { t } = useTranslation();

    const queryKey = useMemo(
        () => ['GET_REPLY_SUGGESTION', review.id, currentSuggestion?.index],
        [review.id, currentSuggestion?.index],
    );

    const reportSuggestionMutation = useMutation(
        () => {
            if (!currentSuggestion)
                return Promise.reject('Cannot report a suggestion without a suggestion...');

            const apis = {
                [ReviewObjectType.FEEDBACK_RESULT]: api.feedbackResults,
                [ReviewObjectType.REVIEW]: api.review,
            };
            return apis[review.reviewObjectType].reportSuggestion(currentSuggestion.id);
        },
        {
            onMutate: async () => {
                closeReportModal();
                // Update context
                setCurrentSuggestion({
                    ...currentSuggestion,
                    report: {},
                } as ReplySuggestion);
                // Update ReactQuery cache
                await queryClient.cancelQueries({ queryKey });
                queryClient.setQueryData<ReplySuggestion>(
                    queryKey,
                    prev => ({ ...prev, report: {} }) as ReplySuggestion, // !!{} -> true
                );
            },
            onSuccess: () => {
                toast.success(
                    t('report_suggestion_success_content'),
                    t('report_suggestion_success_title'),
                );
            },
            onError: () => {
                toast.error(t('unexpected_error'), t('error')); // To customize
                // Reset context and cache
                setCurrentSuggestion({
                    ...currentSuggestion,
                    report: null,
                } as ReplySuggestion);
                queryClient.setQueryData<ReplySuggestion>(
                    queryKey,
                    curr => ({ ...curr, report: null }) as ReplySuggestion,
                );
            },
            onSettled: () => {
                queryClient.invalidateQueries({ queryKey });
            },
        },
    );

    return reportSuggestionMutation;
};
