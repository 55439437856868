import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TransparencyEnum } from '@partoohub/branding';

import { Text } from '@partoohub/ui';
import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

export const PhoneInputStyled = styled(PhoneInput)`
    display: flex;
    flex: 1;
    padding: 0 16px;
    .PhoneInputCountryIcon--border {
        box-shadow: none;
        background-color: transparent;
        border: none;
    }
    input {
        padding: 0 8px;
        height: 100%;
        border: none;
        outline: none;
    }
`;

export const StyledLabel = styled.label<{
    disabled?: boolean;
    hasError: boolean;
    focused: boolean;
}>`
    align-content: center;
    position: absolute;
    left: 42px;
    padding: 0 16px;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 100%;
    transition: padding-top 150ms;

    > span {
        transition: 150ms;
    }
    color: ${({ theme }) => theme.colors.secondary.initial};
    ${({ focused, theme }) =>
        focused &&
        css`
            transform: translateY(-22px); /* Adjust the translateY value as needed */
            font-size: 12px; /* Adjust the font size as needed */
            padding-top: 8px; /* Adjust the padding as needed */
            color: ${theme.colors.primary.initial}; /* Change the color or add any other styling */
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: ${TransparencyEnum.DISABLED};
        `}
    ${({ hasError, theme }) =>
        hasError &&
        css`
            color: ${theme.colors.danger.initial};
        `}
`;

// NEW

export const PhoneInputContainer = styled.div<{
    disabled?: boolean;
    hasError: boolean;
}>`
    display: flex;
    position: relative;
    width: inherit;
    min-height: 56px;
    margin: 0;
    display: flex;
    transition:
        border-color 0.2s ease,
        box-shadow 0.2s ease;
    background-color: white;
    background-image: none;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    border-radius: 8px;
    box-shadow: 0px 0px 0px 0px rgba(0, 133, 242, 0.12);
    cursor: text;
    &:hover {
        border-color: ${({ hasError, theme }) =>
            hasError ? theme.colors.danger.initial : theme.colors.default.initial};
    }
    &:focus-within {
        box-shadow: 0px 0px 0px 3px rgba(0, 133, 242, 0.12);
        border: 2px solid ${({ theme }) => theme.colors.primary.initial};
    }
    ${({ hasError, theme }) =>
        hasError &&
        css`
            border-color: ${theme.colors.danger.initial};
            &:focus-within {
                box-shadow: 0px 0px 0px 3px ${theme.colors.danger.alpha};
                border: 2px solid ${theme.colors.danger.initial};
            }
        `}
    ${({ disabled }) =>
        disabled &&
        css`
            cursor: default;
            pointer-events: none;
            opacity: 0.3;
        `}
`;

export const ExplanationText = styled(Text)`
    padding: 8px 16px;
    min-height: 28px;
`;
