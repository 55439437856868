import { css } from '@emotion/react';
import styled from '@emotion/styled';

// Temporary fixes, need to update OverrideDatePicker component in partoo-ui
type OverrideDatePickerWithErrorProps = {
    error: boolean;
};

export const OverrideDatePickerWithError = styled.div<OverrideDatePickerWithErrorProps>`
    ${({ error, theme }) =>
        error &&
        css`
            div {
                border-color: ${theme.colors.danger.initial};

                &:hover {
                    border-color: ${theme.colors.danger.initial};
                }
            }
        `}
`;
