import { Switch, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { isOpenAllDay } from 'app/businessEditV2/utils/specificHours';
import { OpenHourTogglesContainer } from 'app/common/components/OpenHour/OpenHour.styled';

type Props = {
    disabled: boolean;
    dayValue: Array<string>;
    onChange: (dayValue: Array<string>) => void;
};

const Toggles = ({ disabled, dayValue, onChange }: Props) => {
    const { t } = useTranslation();

    const isOpen = !!dayValue.length;

    const toggleOpenClose = () => {
        if (isOpen) {
            onChange([]);
        } else {
            onChange(['-']);
        }
    };

    const toggleAllDay = () => {
        onChange(isOpenAllDay(dayValue) ? [''] : ['00:00-23:59']);
    };

    return (
        <OpenHourTogglesContainer>
            <Switch
                disabled={disabled}
                checked={isOpen}
                dataTrackId="specific-hours-open-close-switch"
                name="open-hours-open-close-switch"
                onChange={toggleOpenClose}
                label={
                    <Text variant="bodyMBold" color={'secondary'}>
                        {isOpen ? t('open') : t('closed')}
                    </Text>
                }
            />
            <Switch
                disabled={disabled}
                checked={isOpenAllDay(dayValue)}
                dataTrackId="open-hours-24/7-switch"
                name="open-hours-24/7-switch"
                onChange={toggleAllDay}
                label={
                    <Text variant="bodyMBold" color={'secondary'}>
                        {t('specific_hours__twenty_four_seven')}
                    </Text>
                }
            />
        </OpenHourTogglesContainer>
    );
};

export default Toggles;
