import { extractPathParam } from 'app/common/data/routeIds';
import {
    CONNECTION_INVITE_PATH,
    CONNECTION_RESET_PASSWORD_TEMPLATE_PATH,
    PATH_PARAM_TOKEN,
} from 'app/routing/routeIds';

export const getInvitationTokenValue = (pathname: string): string =>
    extractPathParam(pathname, CONNECTION_INVITE_PATH, PATH_PARAM_TOKEN);

export const getResetTokenValue = (pathname: string): string =>
    extractPathParam(
        pathname,
        CONNECTION_RESET_PASSWORD_TEMPLATE_PATH,
        PATH_PARAM_TOKEN,
    );

export default getInvitationTokenValue;
