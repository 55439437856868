import { DiffusionChartContainer } from './DiffusionChart.styled';
import { VisibilityChart } from './VisibilityChart/VisibilityChart';

export const DiffusionChart = () => {
    return (
        <DiffusionChartContainer>
            <VisibilityChart />
        </DiffusionChartContainer>
    );
};
