import React from 'react';

import { useTranslation } from 'react-i18next';

import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';
import GroupsSettingsContent from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/sections/GroupsSettingsContent';
import GroupsSettingsModals from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/sections/GroupsSettingsModals';

import NewSectionButton from './sections/GroupsSettingsContent/SectionList/Section/NewSectionButton';

export const GroupsSettings = () => {
    const { t } = useTranslation();

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('group_page_title')}
                    description={t('group_page_subtitle')}
                    actionsButton={[<NewSectionButton key="new_section_button" />]}
                />
            }
        >
            <MenuListContentContainer>
                <GroupsSettingsContent />
                <GroupsSettingsModals />
            </MenuListContentContainer>
        </MenuListContentTemplate>
    );
};
