import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { SOCIAL_COLORS } from '@partoohub/branding';
import { Button } from '@partoohub/ui';

export const partnerStyles = (partner: string, theme: Theme) => {
    if (partner === 'facebook') {
        return css`
            border-color: ${SOCIAL_COLORS.facebook};
            div {
                color: ${SOCIAL_COLORS.facebook};
            }
        `;
    }
    if (partner === 'google') {
        return css`
            border-color: ${theme.colors.secondary.initial};
            div {
                color: ${theme.colors.secondary.initial};
            }
        `;
    }
    return css`
        border-color: ${theme.colors.secondary.initial};
        div {
            color: ${theme.colors.secondary.initial};
        }
    `;
};
export const PartnerButton = styled(Button)<{ partner: string }>`
    ${({ partner, theme }) => partnerStyles(partner, theme)}
`;
