import { FC } from 'react';

import { Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { FilterSection } from './FilterSection';

type Props = {
    count: number;
};

export const FilterBar: FC<Props> = ({ count }) => {
    const { t } = useTranslation();

    return (
        <Stack direction="column">
            <FilterSection />
            <Text variant="heading6">{t('posts__count', { count })}</Text>
        </Stack>
    );
};
