import { useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { useMessagingUsers } from 'app/reviewManagement/messaging/LoadedUsersProvider';

import { UserAvatar } from '../UserAvatar/UserAvatar';

export type AssignedUserAvatarProps = {
    userId: string;
    size: number;
    textSize: number;
};

const AssignedUserAvatar = ({ userId, size, textSize }: AssignedUserAvatarProps) => {
    const { t } = useTranslation();
    const { loadedUsers } = useMessagingUsers();

    const user = loadedUsers[userId];
    const userFullName =
        (user && `${user.firstName} ${user.lastName}`) || t('messaging_assign_icon_user_not_found');

    return (
        <TooltipWrapper text={userFullName} position="top-start">
            <div>
                <UserAvatar user={user} size={size} textSize={textSize} />
            </div>
        </TooltipWrapper>
    );
};

export default AssignedUserAvatar;
