import React from 'react';

import { StyledContent } from 'app/pages/privacyPolicy/PrivacyPolicy.styled';
import { MessagingPrivacyPolicyData } from 'app/pages/privacyPolicy/types';

type Props = {
    data: MessagingPrivacyPolicyData;
};

const PrivacyPolicyTemplateFr = ({ data }: Props) => (
    <StyledContent>
        <h1>
            <strong>POLITIQUE DE CONFIDENTIALITÉ</strong>
        </h1>
        <br />
        La protection des données personnelles et le respect de la vie privée
        revêtent une très grande importance à nos yeux. La présente Politique de
        confidentialité vise à vous informer de la manière dont nous collectons,
        utilisons et transmettons vos données personnelles lorsque vous
        communiquez avec nous <i>via</i> le Chat (le « <strong>Chat</strong> »)
        et ce, conformément à la réglementation en vigueur en France telle
        qu’issue de la loi n°78-17 du 6 janvier 1978 relative à l'informatique,
        aux fichiers et aux libertés modifiée (« <strong>LIL</strong> ») et du
        Règlement UE 2016/679 du 27 avril 2016 sur la protection des données
        personnelles (« <strong>RGPD</strong> »). <br />
        <br /> Il est rappelé que l’utilisation de vos Données personnelles est
        également régie par la Politique de confidentialité du Chat utilisé
        (exemple pour Google :{' '}
        <a href="https://policies.google.com/privacy?gl=FR&hl=fr">
            https://policies.google.com/privacy
        </a>
        ).
        <br />
        <br /> Toute modification ou mise à jour de la présente Politique de
        confidentialité sera dûment portée à votre connaissance.
        <br />
        <br />
        <h3>
            <strong>1. Définitions</strong>
        </h3>
        « <strong>Chat</strong> » désigne le service de messagerie instantanée
        que vous utilisez pour communiquer avec vous.
        <br />
        <br /> « <strong>Données</strong> » désigne toute information transmise
        par l’Utilisateur à la Société <i>via</i> le Chat y compris des Données
        personnelles.
        <br />
        <br /> « <strong>Donnée personnelle</strong> » ou «{' '}
        <strong>Donnée à caractère personnel</strong> » désigne toute
        information relative à une personne physique identifiée ou identifiable,
        directement ou indirectement, par référence à un numéro d’identification
        ou à un ou plusieurs éléments qui lui sont propres.
        <br />
        <br /> « <strong>Données personnelles sensibles</strong> » désigne
        toutes informations concernant l'origine raciale ou ethnique, les
        opinions politiques, philosophiques ou religieuses, l'appartenance à un
        syndicat, la santé ou la vie sexuelle ainsi que les données génétiques
        ou biométriques. En principe, le traitement des Données sensibles est
        interdit sauf notamment si la personne concernée a donné son
        consentement exprès. <br />
        <br /> « <strong>Responsable de traitement</strong> » désigne la
        personne physique ou morale qui détermine les finalités et les moyens
        d’un Traitement de données, et dans ce cas précis, la Société.
        <br />
        <br /> « <strong>Sous-traitant</strong> » désigne la personne physique
        ou morale qui traite des Données personnelles pour notre compte et selon
        nos instructions, dans le cadre de l’utilisation du Chat.
        <br />
        <br /> « <strong>Traitement</strong> » désigne toute opération, ou
        ensemble d’opérations, portant sur des Données personnelles, quel que
        soit le procédé utilisé (collecte, enregistrement organisation,
        conservation adaptation, modification, extraction consultation,
        utilisation, communication par transmission ou diffusion ou toute autre
        forme de mise à disposition, rapprochement) réalisé par la Société dans
        le cadre de l’utilisation du Chat.
        <br />
        <br /> « <strong>Utilisateur</strong> » ou «{' '}
        <strong>Vous/vos/votre</strong> » désigne toute personne physique
        entrant en contact avec la Société <i>via</i> le Chat.
        <br />
        <br />
        <h3>
            <strong>
                2. Qui est responsable du traitement de vos données personnelles
                ?
            </strong>
        </h3>
        Le Responsable de traitement, qui collecte et gère vos données, est la
        société {data.companyName}, société {data.companyType} au capital de{' '}
        {data.shareCapital} euros dont le siège social est situé{' '}
        {data.companyAddress}, immatriculée au registre du Commerce de{' '}
        {data.companyRegistrationCity}, sous le numéro{' '}
        {data.companyRegistrationNumber} (la « <strong>Société</strong> »),
        représentée par son Président.
        <br />
        <br />
        <h3>
            <strong>3. Catégories de Données personnelles collectées</strong>
        </h3>
        Lors de l’Utilisation du Chat, nous pouvons collecter les catégories de
        Données personnelles suivantes :<br />
        <ul>
            <li>
                <strong>Etat Civil</strong> : Nom, prénom,
            </li>
            <li>
                <strong>Image</strong> (photo de profil de votre compte sur le
                Chat utilisé).
            </li>
        </ul>
        Nous pouvons également collecter toute Donnée personnelle que vous nous
        fournirez, de votre propre initiative, lors de nos échanges <i>via</i>{' '}
        le Chat (numéro de commande, coordonnées postales et/ou téléphoniques,
        photographies etc.). Veuillez noter que nous ne vous demanderons jamais
        de communiquer des Données personnelles sensibles <i>via</i> le Chat et
        nous vous recommandons vivement de ne pas en communiquer de votre plein
        gré et ce afin d’assurer une plus grande sécurité des informations
        échangées.
        <br />
        <br /> Vous vous engagez à fournir des Données personnelles actualisées,
        valides et garantissez ne faire aucune fausse déclaration et ne fournir
        aucune information erronée. Vous ne devez communiquer aucune Donnée
        personnelle relative à des tiers <i>via</i> le Chat.
        <br />
        <br />
        <h3>
            <strong>
                4. Caractère obligatoire et facultatif des Données personnelles
                collectées
            </strong>
        </h3>
        Certaines Données sont nécessaires au traitement de votre demande. Le
        caractère obligatoire des informations demandées vous est signalé lors
        de la collecte. Le défaut de communication desdites Données nous
        empêchera de répondre de manière adéquate à votre demande.
        <br />
        <br /> Toutes Données qui ne sont pas présentées comme obligatoires sont
        facultatives. Vous êtes donc libre de les renseigner ou pas. Toutefois,
        elles peuvent nous permettre de répondre de manière plus précise à votre
        demande et permettent d’améliorer votre expérience lors de nos échanges.
        <br />
        <br />
        <h3>
            <strong>5. Finalités de la collecte de Données personnelles</strong>
        </h3>
        La Société collecte Vos Données Personnelles, dans le cadre du Chat,
        pour les finalités suivantes :<br />
        <ul>
            <li>
                Lire les messages envoyés et y répondre de manière optimale,
            </li>
            <li>
                Permettre l'accès à l'historique des conversations entre la
                Société et les Utilisateurs,
            </li>
            <li>
                Améliorer le service que nous vous fournissons <i>via</i> le
                Chat (réduction des temps de réponse, etc.),
            </li>
            <li>Établir des statistiques et des mesures d’audiences,</li>
            <li>Lutter contre la fraude et assurer la sécurité des Données,</li>
            <li>
                Exécution des dispositions légales, réglementaires et
                administratives en vigueur.
            </li>
        </ul>
        Selon les cas, la Société traite Vos Données personnelles sur la base de
        l’un des fondements juridiques suivants:
        <br />
        <ul>
            <li>
                Votre consentement préalable lorsque vous avez exprimé votre
                accord pour le traitement de Vos Données,
            </li>
            <li>L’exécution d’un contrat vous liant à nous, </li>
            <li>Nos obligations légales, </li>
            <li>
                Notre « intérêt légitime » au sens de la législation applicable
                en matière de protection des Données Personnelles. Dans ce cas,
                nous tiendrons compte de vos intérêts et droits fondamentaux
                pour déterminer si le traitement est légitime et licite.
            </li>
        </ul>
        <br />
        <h3>
            <strong>6. Qui a accès à Vos Données personnelles ?</strong>
        </h3>
        <h4>
            <strong>6.1. Notre personnel</strong>
        </h4>
        Les Données personnelles collectées sont traitées par les personnes
        dûment habilitées au sein de la Société afin de répondre à vos demandes{' '}
        <i>via</i> le Chat.
        <br />
        <br />
        <h4>
            <strong>6.2. Nos Sous-traitants</strong>
        </h4>
        Dans le cadre du Chat, la Société a recours à des Sous-traitants qui
        traitent les Données personnelles pour son compte et sur ses
        instructions. Afin d’assurer la sécurité et la confidentialité de Vos
        données et conformément aux dispositions du RGPD, nous exigeons de nos
        Sous-traitants qu’ils présentent des garanties suffisantes et
        appropriées tant sur le plan technique qu’organisationnel.
        <br />
        <br /> La Société a recours à des sous-traitants situés dans des pays
        offrant un niveau de protection adéquat soit parce qu’ils sont soumis
        aux dispositions du RGPD, soit, conformément à l’article 45 du RGPD,
        parce qu’ils bénéficient d’une décision d’adéquation de la Commission
        européenne. A défaut, la Société s’engage à mettre en œuvre tout
        mécanisme approprié afin d’assurer un niveau de protection adéquat des
        Données et notamment les Clauses contractuelles types établies par la
        Commission Européenne.
        <br />
        <br /> Vous pouvez obtenir de plus amples informations sur les garanties
        offertes par nos Sous-traitants sur simple demande à l’adresse suivante
        : {data.dpoAddress}.<br />
        <br />
        <h4>
            <strong>6.3. Autres destinataires éventuels</strong>
        </h4>
        La Société, s'il y a lieu, peut être amenée à transmettre Vos Données
        personnelles à des organisations, tiers, autorités administratives ou
        judiciaires dans les cas suivants :<br />
        <ul>
            <li>
                Pour exécuter les obligations légales et réglementaires qui nous
                incombent,
            </li>
            <li>
                Identifier, éviter ou traiter les activités frauduleuses, les
                failles de sécurité ou tout autre problème technique,
            </li>
            <li>Pour faire valoir un droit en justice.</li>
        </ul>
        Soyez assurés que la Société ne transmettra pas Vos données personnelles
        à des tiers partenaires afin d’effectuer des opérations commerciales
        sans votre autorisation préalable.
        <br />
        <br />
        <h3>
            <strong>
                7. Combien de temps Vos Données personnelles sont-elles
                conservées ?
            </strong>
        </h3>
        La Société conserve vos Données personnelles, sous une forme permettant
        de vous identifier, en base active le temps strictement nécessaire à
        l’accomplissement des finalités poursuivies et pour lesquelles elles
        sont collectées et traitées, à savoir 1 an maximum à compter de notre
        dernier échange <i>via</i> le Chat.
        <br />
        <br /> Vos Données personnelles peuvent également faire l’objet d’un
        archivage impliquant un accès encadré, limité et justifié, le temps
        nécessaire (i) au respect des obligations légales, fiscales, comptables
        et réglementaires de la Société et/ou (ii) pour nous permettre de faire
        valoir un droit en justice, avant d’être définitivement supprimées.
        <br />
        <br />
        <h3>
            <strong>
                8. Comment nous assurons la sécurité et la confidentialité de
                vos Données personnelles ?{' '}
            </strong>
        </h3>
        La Société s’engage à traiter vos Données personnelles de manière
        licite, loyale, transparente, proportionnée, pertinente, dans le strict
        cadre des finalités poursuivies et annoncées, pour la durée nécessaire
        aux traitements mis en place et de façon sécurisée.
        <br />
        <br /> La Société met en place et actualise les mesures techniques et
        organisationnelles appropriées pour assurer la sécurité et la
        confidentialité de vos Données personnelles en empêchant qu’elles soient
        déformées, détruites, endommagées ou communiquées à des tiers non
        autorisés. A ce titre, la Société a pris des mesures de protection
        physique, électroniques et organisationnelles pour empêcher toute perte,
        utilisation abusive, accès ou distribution non autorisée, modification
        ou destruction de Données personnelles. Toutes les Données Personnelles
        étant confidentielles, l'accès est limité aux employés, prestataires de
        services et Sous-traitants de la Société ayant strictement besoin d'en
        connaître dans le cadre de leur mission. Toutes les personnes ayant
        accès à Vos Données Personnelles sont liées par une obligation de
        confidentialité et risquent des mesures disciplinaires et / ou des
        sanctions en cas de manquement à ces obligations.
        <br />
        <br /> Cependant, il est important que vous veilliez à empêcher tout
        accès non autorisé à vos Données personnelles en ne communiquant que les
        Données strictement nécessaires au traitement de votre demande{' '}
        <i>via</i> le Chat.
        <br />
        <br />
        <h3>
            <strong>
                9. Quels sont vos droits sur vos Données personnelles ?{' '}
            </strong>
        </h3>
        Il vous est possible, sur simple demande écrite envoyée à l’adresse
        suivante : {data.dpoAddress}, d’accéder aux Données personnelles vous
        concernant, de demander leur rectification ou leur limitation, leur
        effacement ou leur portabilité, ou d’exiger de ne plus figurer dans la
        base de données de la Société.
        <br />
        <br /> Au titre du <strong>droit d’accès</strong>, vous êtes autorisé,
        conformément à l’article 15 du RGPD, à nous interroger en vue d’obtenir
        la confirmation que vos Données personnelles font ou ne font plus
        l'objet d'un Traitement ainsi que la communication d’informations
        relatives au traitement (notamment ses finalités, les catégories de
        Données personnelles traitées et les destinataires auxquels les Données
        personnelles sont communiquées, la durée de conservation des Données ou
        bien les critères utilisés pour déterminer cette durée, vos droits sur
        les Données personnelles et le droit de formuler une réclamation auprès
        d’une autorité de contrôle).
        <br />
        <br />
        Conformément à l’article 16 du RGPD, le{' '}
        <strong>droit de rectification</strong> vous confère le droit d’exiger
        de notre part que soient rectifiées, complétées ou mises à jour les
        Données personnelles vous concernant lorsqu’elles sont inexactes,
        incomplètes, équivoques ou périmées.
        <br />
        <br /> Dans les conditions prévues à l’article 17 du RGPD, vous pouvez
        également demander <strong>l’effacement</strong> des Données
        personnelles vous concernant.
        <br />
        <br />
        Vous pouvez, en outre, demander que le traitement de vos données
        personnelles soit <strong>limité</strong> dans les conditions de
        l’article 18 du RGPD, c’est à dire que vos Données à caractère personnel
        soient uniquement conservées aux fins de :<br />
        <ul>
            <li>
                vérifier l’exactitude des Données personnelles qui font l’objet
                d’une contestation,
            </li>
            <li>
                vous servir dans le cadre de la constatation, l’exercice ou la
                défense de vos droits en justice et ce bien que la Société n’en
                n’ait plus l’utilité,
            </li>
            <li>
                vérifier si les motifs légitimes poursuivis par la Société
                prévalent sur les vôtres dans l’hypothèse où vous vous
                opposeriez au traitement fondé sur l’intérêt légitime de la
                Société,{' '}
            </li>
            <li>
                satisfaire votre demande de limitation de l’utilisation de vos
                données - plutôt qu’un effacement - dans l’hypothèse où le
                traitement de vos données est illicite.
            </li>
        </ul>
        En vertu de votre <strong>droit à la portabilité</strong> et dans les
        circonstances prévues à l’article 20 du RGPD, vous pouvez récupérer
        auprès de la Société les Données personnelles que vous nous avez
        fournies, dans un format structuré, couramment utilisé et lisible par
        machine, aux fins de les transmettre à un autre responsable de
        traitement.
        <br />
        <br /> Conformément à l’article 21 du RGPD, vous avez la possibilité de
        vous <strong>opposer</strong>, à tout moment, au traitement de vos
        Données personnelles à des fins de prospection commerciale. Pour exercer
        vos droits, il vous suffit d’adresser votre demande, au choix :<br />
        <ul>
            <li>
                par courrier électronique à l’adresse suivante : {data.dpoEmail}
                ,
            </li>
            <li>
                par courrier postal à l’adresse suivante : {data.dpoAddress}.
            </li>
        </ul>
        La Société répondra à la demande dans les 30 jours suivant sa réception.
        <br />
        <br /> L’exercice de ces droits est gratuit. Toutefois, en cas de
        demande manifestement infondée ou excessive, en particulier eu égard à
        leur caractère répétitif, la Société se réserve la possibilité (i)
        d’exiger le paiement de frais tenant compte des coûts administratifs, ou
        (ii) de refuser de donner suite à la demande.
        <br />
        <br />
        <h3>
            <strong>
                10. Que se passe-t-il en cas d’atteinte causée à vos Données
                personnelles ?
            </strong>
        </h3>
        En cas de violation de vos Données personnelles susceptible d’engendrer
        un risque pour vos droits et libertés, la Société en notifie la
        violation à la CNIL dans les meilleurs délais, et, si possible 72 heures
        au plus tard après en avoir pris connaissance. Lorsqu’une violation de
        vos Données personnelles est susceptible d’engendrer un risque élevé
        pour les droits et libertés d’un Utilisateur, la Société en informe
        l’Utilisateur, dans les meilleurs délais, sous réserve des exceptions
        prévues à l’article 34 du RGPD. <br />
        <br /> Sans préjudice de tout autre recours administratif ou
        juridictionnel, si Vous estimez que le traitement de Vos Données
        personnelles constitue une violation des dispositions de la législation
        en vigueur, vous pouvez introduire une réclamation auprès d’une autorité
        de contrôle compétente telle que la CNIL à l’adresse suivante :
        Commission Nationale de l'Informatique et des Libertés, 3 Place de
        Fontenoy - TSA 80715 - 75334 Paris Cedex 07.
        <br />
        <br />
        <h3>
            <strong>11. A qui poser vos questions ? </strong>
        </h3>
        En cas de question relative au Traitement de Vos Données personnelles,
        Vous pouvez à tout moment prendre contact avec le référent données
        personnelles ou le DPO de la société : {data.dpoName}, au sujet de
        toutes les questions relatives au traitement de vos Données personnelles
        et à l’exercice de leurs droits, à l’adresse suivante : {data.dpoEmail}.
        <br />
        <br />
    </StyledContent>
);

export default PrivacyPolicyTemplateFr;
