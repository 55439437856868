import {
    V2BusinessStatus,
    V2FormattedBusinessData,
} from 'app/api/types/business';

const businessStatusFilter = (
    businesses: Array<V2FormattedBusinessData>,
    statusToCheck: V2BusinessStatus,
): Array<V2FormattedBusinessData> =>
    businesses.filter(({ status }) => status === statusToCheck);

export default businessStatusFilter;
