import styled from '@emotion/styled';

export const MobilePageSubHeaderContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 70px 0 16px 0;
`;

export const MobilePageSubHeaderActions = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
