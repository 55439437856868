import { Dialog, Stack } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import ConnectionButton from 'app/businessConnection/components/common/ConnectionButton';
import ModalTemplate from 'app/common/designSystem/components/templates/ModalTemplate';
import { SMALL, Type } from 'app/common/designSystem/constants/size';

interface Props {
    onHideClick: () => void;
}

export const PlatFormSelectionModal = ({ onHideClick }: Props) => {
    const { t } = useTranslation();

    return (
        <ModalTemplate size={SMALL as Type}>
            <Dialog
                dataTrackId=""
                title={t('select_your_platform_title')}
                subTitle={t('select_your_platform_subtitle')}
            >
                <Stack justifyContent="center" alignItems="center">
                    <ConnectionButton
                        partner="GOOGLE_MY_BUSINESS"
                        message="continue_with_google"
                        onHideClick={() => onHideClick()}
                    />
                    <ConnectionButton
                        partner="FACEBOOK"
                        message="continue_with_facebook"
                        onHideClick={() => onHideClick()}
                    />
                </Stack>
            </Dialog>
        </ModalTemplate>
    );
};
