import { HEX_COLORS } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import {
    StyledFeedbackResultLinearScaleField,
    StyledLinearScale,
    StyledLinearScaleButton,
    StyledLinearScaleButtons,
    StyledLinearScaleLabels,
} from './FeedbackResultLinearScaleField.styled';
import { getNPSColor } from '../../../ReviewCard/ReviewCardRating/ReviewRating';

interface LinearScaleProps {
    rating: number;
}

const LinearScale = ({ rating }: LinearScaleProps) => {
    const { t } = useTranslation();
    return (
        <StyledLinearScale>
            <StyledLinearScaleButtons>
                {Array(11)
                    .fill(undefined)
                    .map((_, index) => (
                        <StyledLinearScaleButton
                            key={index}
                            color={getNPSColor(index)}
                            selected={index === rating}
                        >
                            <Text
                                as="span"
                                variant="bodyMSemibold"
                                color={index === rating ? HEX_COLORS.white : getNPSColor(index)}
                            >
                                {index}
                            </Text>
                        </StyledLinearScaleButton>
                    ))}
            </StyledLinearScaleButtons>
            <StyledLinearScaleLabels>
                <Text as="span" variant="bodySSemibold" color={HEX_COLORS.secondary}>
                    {t('feedback_result_nps_low_probability')}
                </Text>
                <Text as="span" variant="bodySSemibold" color={HEX_COLORS.secondary}>
                    {t('feedback_result_medium_probability')}
                </Text>
                <Text as="span" variant="bodySSemibold" color={HEX_COLORS.secondary}>
                    {t('feedback_result_nps_high_probability')}
                </Text>
            </StyledLinearScaleLabels>
        </StyledLinearScale>
    );
};

interface Props {
    question: string;
    rating: number | null;
}

export const FeedbackResultLinearScaleField = ({ rating, question }: Props) => {
    return (
        <StyledFeedbackResultLinearScaleField>
            <Text variant="bodyLSemibold">{question}</Text>
            <LinearScale rating={rating ?? 0} />
        </StyledFeedbackResultLinearScaleField>
    );
};
