import { Navigate, useRoutes } from 'react-router-dom';

import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { ReviewBoosterPage } from 'app/pages/conversations/ReviewBooster';
import { ReviewListPage } from 'app/pages/conversations/ReviewList';
import {
    CEPages,
    useCanAccessCustomerExperiencePage,
} from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import { useDefaultCustomerExperiencePath } from 'app/routing/hooks/useDefaultCustomerExperiencePath';
import { NOT_FOUND_PATH, REVIEWS_PATH_TOKEN, SEND_INVITE_PATH_TOKEN } from 'app/routing/routeIds';

import { CustomerExperienceHeader } from './headers';

export const CustomerExperienceRoutes = () => {
    const { data: me } = useMe();
    const meRole = me ? me.role : null;
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);

    const { canAccessCustomerExperiencePage } = useCanAccessCustomerExperiencePage();

    const canAccessToClientReviews = canAccessCustomerExperiencePage[CEPages.ClientReviews];
    const canAccessToRB = canAccessCustomerExperiencePage[CEPages.RB];
    const defaultCustomerExperiencePath = useDefaultCustomerExperiencePath();

    const routes = [
        {
            index: true,
            element: <Navigate replace to={defaultCustomerExperiencePath} />,
        },
        ...(canAccessToClientReviews
            ? [
                  {
                      path: REVIEWS_PATH_TOKEN,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <ReviewListPage />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
        ...(canAccessToRB
            ? [
                  {
                      path: SEND_INVITE_PATH_TOKEN,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <ReviewBoosterPage />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
        {
            path: '*',
            element: <Navigate replace to={NOT_FOUND_PATH} />,
        },
    ];

    const routesWithHeader = [
        {
            path: '/',
            element: <CustomerExperienceHeader />,
            children: routes,
        },
    ];

    return useRoutes(isNewSidebarEnabled ? routesWithHeader : routes);
};
