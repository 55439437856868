import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, TransparencyEnum, greyColorObject } from '@partoohub/branding';

import MentionTextArea from 'app/common/designSystem/components/atoms/MentionTextArea';
import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

type ExpeditorNameStaticFieldWrapperProps = {
    selectedBusinessId?: string;
    disabled?: boolean;
};

type EditTemplateSymbolProps = {
    onHover: boolean;
    hasError: boolean;
    disabled?: boolean;
};

type SmsContainerProps = {
    hasError: boolean;
    disabled?: boolean;
    editable?: boolean;
};

export const SMSMentionTextArea = styled(MentionTextArea)`
    border-color: ${greyColorObject.initial};
`;

export const ExpeditorNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 16px;
`;

type ExpeditorNameTitleProps = {
    disabled?: boolean;
};

export const ExpeditorNameTitle = styled.div<ExpeditorNameTitleProps>`
    font-weight: bold;
    margin-right: 4px;

    ${props =>
        props.disabled &&
        css`
            color: ${DISABLED_COLOR};
        `}
`;

type ExpeditorNameValueProps = {
    disabled?: boolean;
};

export const ExpeditorNameValue = styled.div<ExpeditorNameValueProps>`
    color: ${({ theme }) => theme.colors.secondary.initial};

    ${props =>
        props.disabled &&
        css`
            color: ${DISABLED_COLOR};
        `}
`;

export const ExpeditorNameStaticFieldWrapper = styled.span<ExpeditorNameStaticFieldWrapperProps>`
    display: flex;
    background-color: ${({ theme }) => theme.colors.secondary.alpha};
    border-radius: 8px;
    padding: 12px 12px 12px 16px;
    width: 100%;
    justify-content: space-between;
    ${({ disabled, theme }) =>
        !disabled &&
        css`
            &:hover i {
                background-color: ${theme.colors.primary.alpha};
            }
        `}
    line-height: 22px;
    margin-bottom: 24px;
    ${props =>
        props.selectedBusinessId &&
        css`
            cursor: ${props.disabled ? 'default' : 'pointer'};
            padding: 12px 8px 12px 16px;
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: ${TransparencyEnum.DISABLED};
        `}
`;

export const ExpeditorNameStaticFieldContainer = styled.div`
    display: flex;
    margin: auto 0;
`;

type EditButtonProps = {
    disabled?: boolean;
};

export const EditButton = styled.div<EditButtonProps>`
    margin-right: 8px;
    & > i {
        color: ${({ theme }) => theme.colors.secondary.initial};
        padding: 8px;
        border-radius: 16px;
        font-size: 16px;

        ${props =>
            props.disabled &&
            css`
                color: ${DISABLED_COLOR};
            `}
    }
`;

export const ErrorBlock = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.danger.initial};

    p {
        padding-left: 16px;
    }

    div {
        padding-right: 16px;
    }
`;

export const SmsContainer = styled.div<SmsContainerProps>`
    color: ${HEX_COLORS.white};
    background-color: ${({ theme }) => theme.colors.primary.initial};
    border-radius: 24px 24px 0 24px;
    padding: 16px 64px 16px 16px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    word-wrap: break-word;

    a {
        font-weight: bold;
        color: ${HEX_COLORS.white};
    }

    ${props =>
        !props.editable &&
        css`
            cursor: default;
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: ${TransparencyEnum.DISABLED};
            cursor: default;

            a {
                cursor: default;

                &:hover {
                    text-decoration: none;
                }
            }
        `}

    ${({ hasError, theme }) =>
        hasError &&
        css`
            background-color: ${theme.colors.danger.initial};
        `};
`;

export const EditTemplateSymbol = styled('div', {
    shouldForwardProp: (propName: PropertyKey) =>
        !['onHover', 'hasError'].includes(propName.toLocaleString()),
})<EditTemplateSymbolProps>`
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 16px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.primary.initial};
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ onHover, theme }) =>
        onHover &&
        css`
            background-color: ${HEX_COLORS.white};
            color: ${theme.colors.primary.initial};
        `}

    ${({ hasError, theme }) =>
        hasError &&
        css`
            background-color: ${theme.colors.danger.initial};
        `}

    ${({ hasError, onHover, theme }) =>
        hasError &&
        onHover &&
        css`
            background-color: ${HEX_COLORS.white};
            color: ${theme.colors.danger.initial};
        `}

    ${props =>
        props.disabled &&
        css`
            background-color: transparent;
        `}

    & > i {
        ${props =>
            props.disabled &&
            css`
                color: ${DISABLED_COLOR};
            `}
    }
`;
