import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import Flexbox from 'app/styles/utils/flexbox';

export const InboxHeaderContainer = styled(Flexbox)`
    min-height: 48px;
    border-bottom: 1px solid ${greyColorObject.initial};
    font-weight: bold;
    line-height: normal;
    justify-content: space-between;
`;
