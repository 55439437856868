import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';
import { Icon, Text } from '@partoohub/ui';

import { DRAGGING_OPACITY } from '../CustomFieldsList.styled';

export const SectionCustomFieldsListContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 24px;

    > div {
        width: 100%;
    }
`;

export const CustomFieldSectionContainer = styled.div`
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;
`;

export const CustomFieldSectionDraggable = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%;
    padding-left: 24px;

    &:hover {
        cursor: grab;
    }

    &:active {
        cursor: grabbing;
    }
`;

type SectionListItemContainerProps = {
    isDragging?: boolean;
};

export const SectionListItemContainer = styled.div<SectionListItemContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: ${({ theme }) => theme.radius.large};
    border: solid 1px ${({ theme }) => theme.colors.default.alpha};
    padding-right: 16px;
    // If transparent, it will be weird with the drag and drop
    background-color: ${HEX_COLORS.white};

    opacity: ${({ isDragging }) => (isDragging ? DRAGGING_OPACITY : 1)};
`;

export const GrabIcon = styled(Icon)`
    margin-right: 21px;
`;

export const SectionName = styled(Text)`
    display: flex;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 21px;
`;
