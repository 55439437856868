import React from 'react';

import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';

import UserList from './sections/UserList';
import UserManagementFilters from './sections/UserManagementFilters';
import UserManagementHeader from './sections/UserManagementHeader';
import UserManagementModals from './sections/UserManagementModals';

export const UserManagement = () => {
    return (
        <MenuListContentTemplate headerContent={<UserManagementHeader />}>
            <UserManagementFilters />
            <UserList />

            <UserManagementModals />
        </MenuListContentTemplate>
    );
};
