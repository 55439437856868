import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const AttributesFormContainer = styled.div`
    position: relative;
`;

export const AttributesFormLeft = styled.div`
    padding-left: 240px;
`;

export const AttributesSection = styled.div`
    margin-bottom: 52px;
`;

export const AttributesSectionName = styled.div`
    font-weight: bold;
    font-size: 18px;
    color: ${HEX_COLORS.blackberry};
    margin-bottom: 16px;
`;
