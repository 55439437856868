import { PropsWithChildren, ReactNode, forwardRef, useContext } from 'react';

import { Loader, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { FormattedReplyTemplate } from 'app/api/types/reviewReplyTemplates';
import AutosizedTextArea from 'app/common/components/AutosizedTextArea/AutosizedTextArea';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useReduxBusiness from 'app/reviewManagement/common/hooks/useReduxBusiness';
import {
    ActionButtonsContainer,
    InputsContainer,
    Label,
    ReplySuggestionLoader,
} from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/CommentSection/CommentTable/CommentFormTextArea.styled';
import { fillTemplateService } from 'app/states/reviews';

import Flexbox from 'app/styles/utils/flexbox';

import { ReviewReplyContext, ReviewReplyContextType } from './CommentForm';

import { ReviewCardContext, ReviewCardContextType } from '../../ReviewCard';

type Props = {
    textValue?: string;
    preselectedTemplate?: FormattedReplyTemplate;
    tempValue?: string | null;
    children: ReactNode;
    loading?: boolean;
    onChange: (arg0: string) => void;
};

export const CommentFormTextArea = forwardRef<HTMLTextAreaElement, PropsWithChildren<Props>>(
    function (
        {
            textValue = undefined,
            preselectedTemplate,
            tempValue,
            onChange,
            loading = false,
            children,
        }: Props,
        ref,
    ) {
        const { review } = useContext<ReviewCardContextType>(ReviewCardContext);
        const { onSuggestionMode, showTemplateModal } =
            useContext<ReviewReplyContextType>(ReviewReplyContext);
        const { t } = useTranslation();
        const business = useReduxBusiness(review.businessId);
        const { data: meData } = useMe();
        const preselectedValue =
            preselectedTemplate &&
            meData &&
            fillTemplateService(preselectedTemplate, review, business, meData);
        const preselectWarning = preselectedValue && !review.authorName;
        const deletedReviewWarning =
            !!review.seqUnfoundCount && review.partner == 'google_my_business';
        const explanation =
            (preselectWarning && t('author_name_missing_on_review')) ||
            (deletedReviewWarning && t('review_reply_google_error')) ||
            undefined;

        const onInputsContainerFocused = () => {
            if (ref && !loading) {
                // @ts-expect-error
                ref?.current?.focus();
            }
        };

        const value = tempValue || preselectedValue || textValue || '';

        return (
            <Flexbox flexDirection={'column'} style={{ gap: '4px' }}>
                <InputsContainer
                    isSuggestionMode={onSuggestionMode}
                    flexDirection="column"
                    tabIndex={0}
                    onFocus={onInputsContainerFocused}
                >
                    {loading ? (
                        <ReplySuggestionLoader>
                            <Loader variant="flatdot" />
                        </ReplySuggestionLoader>
                    ) : (
                        <>
                            {onSuggestionMode && (
                                <Label htmlFor={`reply_textarea_${review.id}`}>
                                    {t('review_ai_suggestion_title')}
                                </Label>
                            )}
                            <AutosizedTextArea
                                value={value}
                                id={`reply_textarea_${review.id}`}
                                data-track="reviews__comment_input"
                                textAreaRef={ref}
                                placeholder={t('your_reply') + '...'}
                                minRows={showTemplateModal ? 5 : 1}
                                maxRows={showTemplateModal ? 5 : 10}
                                onChange={e => {
                                    onChange(e.target.value);
                                }}
                            />
                        </>
                    )}
                    <ActionButtonsContainer onFocus={e => e.stopPropagation()}>
                        {children}
                    </ActionButtonsContainer>
                </InputsContainer>
                {explanation ? (
                    <Text color={'warning'} variant={'bodySRegular'}>
                        {explanation}
                    </Text>
                ) : null}
            </Flexbox>
        );
    },
);
