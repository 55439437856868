import { TFunction } from 'i18next';

import { Conversation, MessagingPartnerEnum } from 'app/states/messaging';

import { generateDelightMessages } from './generateDelightMessages';

export const generateDelightConversation = (
    t: TFunction<'translation', undefined>,
): Conversation => {
    return {
        id: -1,
        consumer_img: 'https://static.partoo.co/images/apple-touch-icon.png',
        consumer_name: 'Partoo',
        business: {
            id: '0',
            name: 'Partoo',
            formatted_address: '190 Rue Championnet',
            zipcode: '75018',
            city: 'Paris',
            country: 'France',
        },
        is_read: true,
        status: 'Open',
        last_message: generateDelightMessages()[0],
        created_at: '2023-06-07 14:56:05.042699+00:00',
        has_one_user_message: false,
        messaging_partner: MessagingPartnerEnum.DELIGHT,
        issues: [],
        first_message_date: '2023-06-07 15:56:05.042699+00:00',
        is_solved: false,
        is_expired: false,
        is_automated: false,
        assigned_user_id: null,
        display_name: t('messaging_conversation_delight_automated_conversation'),
    };
};
