import { IconElement, NotificationColorsVariants, Text } from '@partoohub/ui';

import { IconTitle } from 'app/common/designSystem/components/atoms/IconTitle/IconTitle';

import { SectionContent, SectionWrapper } from './SectionWithIcon.styled';

type Props = {
    iconName: IconElement;
    title: string;
    items: Array<Array<string>>;
    variant?: NotificationColorsVariants;
};

export const SectionWithIcon = ({
    iconName,
    title,
    items,
    variant = 'primary',
}: Props) => (
    <SectionWrapper>
        <IconTitle icon={iconName} variant={variant}>
            {title}
        </IconTitle>
        <SectionContent>
            {items &&
                items.map(([key, value]) => (
                    <Text
                        key={key}
                        as="span"
                        color="secondary"
                        variant="bodyMRegular"
                    >
                        {value}
                    </Text>
                ))}
        </SectionContent>
    </SectionWrapper>
);
