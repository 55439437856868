import { ReactNode } from 'react';

type Props = {
    children: ReactNode;
    className?: string;
    dataTracker?: string;
};

const Card = ({ children, className = '', dataTracker }: Props) => (
    <div
        className={`partoo_card ${className}`}
        data-track={dataTracker}
        data-intercom-target={dataTracker}
    >
        {children}
    </div>
);

export default Card;
