import React from 'react';

import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import EditSection from 'app/businessEditV2/components/EditSection';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import UnsavedModal from 'app/businessEditV2/components/UnsavedModal';
import useDisplayMoreHours from 'app/businessEditV2/hooks/editV2utils/displaySections/useDisplayMoreHours';
import useIsLoadingMoreHours from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingMoreHours';
import useMoreHoursSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useMoreHoursSectionColor';
import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import MoreHoursForm from 'app/businessEditV2/sections/MoreHoursSection/MoreHoursForm';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import MoreHourSectionContent from './MoreHourSectionContent';

const MoreHoursSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const {
        showModal,
        setShowModal,
        closeSafeModal,
        showUnsavedModal,
        setShowUnsavedModal,
        useDirtyUpdate,
    } = useUnsavedModal(SectionNames.MORE_HOURS);

    const displayMoreHours = useDisplayMoreHours();
    const sectionColor = useMoreHoursSectionColor();

    const isLoading = useIsLoadingMoreHours();

    const { t } = useTranslation();

    if (isLoading) {
        return <EditSectionLoader />;
    }

    if (!displayMoreHours) {
        return null;
    }

    return (
        <div
            ref={ref}
            data-track="visibility_location__section_more_hours"
            data-intercom-target="visibility_location__section_more_hours"
        >
            <EditSection
                title={t('more_hours')}
                description={
                    <MoreHourSectionContent colorState={sectionColor} />
                }
                onClick={() => setShowModal(true)}
                colorState={sectionColor}
                sectionName={SectionNames.MORE_HOURS}
            />
            <FullScreenModal
                show={showModal}
                childrenRight={
                    <EditFormDescription
                        title={t('business_edit_title_more_hours')}
                        description={t('business_edit_description_more_hours')}
                        advice={t('business_edit_advice_more_hours')}
                    />
                }
                childrenLeft={
                    <>
                        <MoreHoursForm
                            closeModal={() => setShowModal(false)}
                            useDirtyUpdate={useDirtyUpdate}
                        />
                        <UnsavedModal
                            show={showUnsavedModal}
                            cancel={() => setShowUnsavedModal(false)}
                            validate={() => setShowModal(false)}
                        />
                    </>
                }
                onHideClick={closeSafeModal}
            />
        </div>
    );
});

export default MoreHoursSection;
