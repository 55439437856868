import { useEffect, useRef } from 'react';

export const useIsFirstRender = () => {
    const isFirstRenderRef = useRef<boolean>();

    if (isFirstRenderRef.current === undefined) {
        isFirstRenderRef.current = true;
    }

    useEffect(() => {
        isFirstRenderRef.current = false;
    }, []);

    return isFirstRenderRef.current;
};
