import styled from '@emotion/styled';

export const ModalButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 16px;
`;

export const SingleSelectContainer = styled.div`
    width: 100%;

    > :first-child {
        max-width: unset;
    }
`;
