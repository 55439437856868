import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import { MetricsApiKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/types/query';

export const useExportKeywords = () => {
    return useMutation((payload: Record<string, any>) => {
        const query = {
            ...payload,
            [MetricsApiKeys.DIMENSIONS]: 'business,keyword',
            [MetricsApiKeys.ORDER_BY]: 'business',
            [MetricsApiKeys.METRICS]: 'count',
        };
        return api.presenceAnalytics.exportKeywords(query);
    });
};
