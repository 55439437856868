import { LiteralRating, ReviewObject } from 'app/api/types/review';
import {
    NEGATIVE_REVIEWS,
    NEUTRAL_REVIEWS,
    POSITIVE_REVIEWS,
} from 'app/api/types/reviewReplyTemplates';

export const ratingQuantityToLiteral = (rating: number | boolean): LiteralRating | undefined => {
    switch (true) {
        case typeof rating === 'boolean':
            return rating ? POSITIVE_REVIEWS : NEGATIVE_REVIEWS;
        case +rating < 3:
            return NEGATIVE_REVIEWS;
        case +rating === 3:
            return NEUTRAL_REVIEWS;
        case +rating > 3:
            return POSITIVE_REVIEWS;
        default:
            return undefined;
    }
};

export const useReviewRating = (review: ReviewObject) => {
    const literalRating: LiteralRating | undefined = ratingQuantityToLiteral(
        review.rating || review.recommended,
    );
    return literalRating;
};
