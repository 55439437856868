import { FC, useEffect } from 'react';

import { Button, Loader, Text } from '@partoohub/ui';
import { useSearchParams } from 'react-router-dom';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';
import {
    FBLoginCallbackAuthResponse,
    launchEmbeddedSignup,
} from 'app/pages/settings/Whatsapp/launchEmbeddedSignup';

import { LoaderWrapper } from './WhatsappAccountImport.styled';

export const WhatsappAccountImportPage: FC = () => {
    const [params] = useSearchParams();
    const only_waba_sharing = params.get('only_waba_sharing');

    const { data: me } = useMe();
    const { data: org } = useOrganization(me?.org_id!, !!me);
    const is_ready_for_signup = me && org;

    const triggerEmbeddedSignup = () => {
        launchEmbeddedSignup(
            (response: FBLoginCallbackAuthResponse) => {
                localStorage.setItem('PartooWhatsappAccountImportData', JSON.stringify(response));
                window.open(
                    `partoo://whatsapp/success/${response.code}/${response.user_id}`,
                    '_self',
                );
            },
            () => {
                window.open('partoo://whatsapp/error', '_self');
            },
            only_waba_sharing === 'true',
            org!.name,
        );
    };

    useEffect(() => {
        if (is_ready_for_signup) {
            triggerEmbeddedSignup();
        }
    }, [is_ready_for_signup]);

    return (
        <LoaderWrapper>
            <Text as={'div'} variant="heading3">
                Importing your Whatsapp account
            </Text>
            <Text as={'div'} variant="bodyMRegular">
                Do not close this page until the import is completed
            </Text>
            <Loader size="medium" variant="spinner" active={true} animationDuration={2000} />
            {is_ready_for_signup && (
                <Button dataTrackId={''} onClick={triggerEmbeddedSignup}>
                    Click here if the Meta pop-up did not open
                </Button>
            )}
        </LoaderWrapper>
    );
};
