import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationVariousElement,
    IllustrationVariousEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useOrgFieldMetadata } from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import dataLayer from 'app/common/utils/dataLayer';
import { useTodoReviewsData } from 'app/pages/Homepage/hooks/todos/todosItems/reviews/useTodoReviewsData';
import { useTodoReviewsNavigationPath } from 'app/pages/Homepage/hooks/todos/todosItems/reviews/useTodoReviewsNavigationPath';
import { DefaultTodoComponent } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent';
import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import useOrgPermissions from 'app/pages/settingsV2/subPages/Team/components/Permissions/hooks/useOrgPermissions';

export const TodoReviews = (props: SmallTodoProps) => {
    const { t } = useTranslation();

    const { data: dataReviews, isLoading } = useTodoReviewsData();

    const unTreatedReviews = dataReviews?.count ?? 0;

    const { isLoading: isLoadingPermissions } = useOrgPermissions();
    const hasReviewsPermission =
        useOrgFieldMetadata('review_management', 'review_reply')?.enabled ?? false;

    const navigate = useNavigate();
    const navigateTo = useTodoReviewsNavigationPath();

    const illustrationElement: IllustrationVariousElement = {
        type: IllustrationCategoryEnum.Various,
        name: unTreatedReviews
            ? IllustrationVariousEnum.Reviews
            : IllustrationVariousEnum.ReviewsTreated,
    };

    const onClick = () => {
        dataLayer.pushDict('click_homepage_to_do_reviews', {
            reviews_status: unTreatedReviews ? 'pending_reviews' : 'no_reviews',
        });
        navigate(navigateTo);
    };

    return (
        <DefaultTodoComponent
            {...props}
            {...(unTreatedReviews
                ? {
                      title: `${t('homepage_todo_reviews_unanswered_title', {
                          count: unTreatedReviews,
                      })}`,
                      actionText: hasReviewsPermission
                          ? `${t('homepage_todo_unanswered_description')}`
                          : `${t('homepage_todo_unanswered_description_no_permission')}`,
                  }
                : {
                      title: `${t('homepage_todo_reviews_answered_title')}`,
                      description: `${t('homepage_todo_reviews_answered_description')}`,
                  })}
            icon={
                <Illustration
                    dataTrackId="homepage_reviews__illustration"
                    illustration={illustrationElement}
                    height="98"
                    width="120"
                />
            }
            isLoading={isLoading || isLoadingPermissions}
            onClick={onClick}
            dataTrack="click_homepage_to_do_reviews"
        />
    );
};
