import styled from '@emotion/styled';

import { HEX_COLORS } from '@partoohub/branding';

import { REVIEW_CARD_AVATAR_WIDTH } from '../../ReviewCardHeader/ReviewCardAvatar';

export const AuthorInitialsIcon = styled.div`
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${HEX_COLORS.white};
    background-color: ${({ theme }) => theme.colors.default.initial};
`;

export const StyledPartnerCommentSeparator = styled.div`
    display: flex;
    justify-content: center;
    width: ${REVIEW_CARD_AVATAR_WIDTH + 8}px;
    padding: 8px 8px 8px 0;
    flex: 1;
`;
