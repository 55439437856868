import { useMemo, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import GoogleVerificationContext, {
    GoogleVerificationContextProps,
} from 'app/settingsManagement/components/GoogleVerification/GoogleVerification.context';
import GoogleVerificationListingSearch from 'app/settingsManagement/components/GoogleVerification/GoogleVerificationListingSearch';
import useLocationSearch from 'app/settingsManagement/components/GoogleVerification/utils/useLocationSearch';

import ExplanationBanner from './ExplanationBanner/ExplanationBanner';
import { GoogleVerificationContainer } from './GoogleVerification.styled';
import GoogleVerificationSection from './GoogleVerificationSection/GoogleVerificationSection';

const GoogleVerification = () => {
    const { t } = useTranslation();
    const locationQuery = useLocationSearch();
    const [searchValue] = useStateQueryParams('query');
    const [store, setStore] = useState<Record<string, any>>({});

    const locations = locationQuery.data?.pages.flatMap(page => page.locations) ?? [];

    const hasNoLocations =
        !searchValue &&
        (locationQuery.isError ||
            (locations.length === 0 &&
                locationQuery.status !== 'idle' &&
                !locationQuery.isLoading &&
                !locationQuery.isFetchingNextPage));

    const googleVerificationContext = useMemo<GoogleVerificationContextProps>(
        () => ({ store, setStore, locationQuery }),
        [store, setStore, locationQuery],
    );

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Happy,
    };

    return (
        <GoogleVerificationContext.Provider value={googleVerificationContext}>
            <GoogleVerificationContainer>
                {hasNoLocations ? (
                    <NotFoundTemplate
                        show
                        imgSrc={illustrationElement}
                        title={t('google_verification__no_google_listing_found')}
                        withButton={false}
                    />
                ) : (
                    <>
                        <ExplanationBanner />
                        <GoogleVerificationListingSearch />
                        <GoogleVerificationSection />
                    </>
                )}
            </GoogleVerificationContainer>
        </GoogleVerificationContext.Provider>
    );
};

export default GoogleVerification;
