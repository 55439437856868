import { combineReducers } from 'redux';

import locations, { GMBLocationsState } from './gmbLocations';

export type GmbState = {
    locations: GMBLocationsState;
};

export default combineReducers({
    locations,
});
