import { GROUP_MANAGER, ORG_ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';

export const useIsGroupsSettingsPageEnabled = (): boolean => {
    const enableNewGroups = useNewGroups();
    const { data: me } = useMe();

    return !!enableNewGroups && [ORG_ADMIN, GROUP_MANAGER].includes(me?.role ?? '');
};
