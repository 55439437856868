import React, { useMemo } from 'react';

import { uniqueId } from 'lodash';

import {
    BusinessAddress,
    BusinessCardGridContainer,
    BusinessCardGridItemContainer,
    BusinessFullAddress,
    BusinessImage,
    BusinessTitle,
} from './BusinessLoadingCards.styled';

const BusinessLoadingCard = () => (
    <BusinessCardGridContainer>
        <BusinessCardGridItemContainer>
            <BusinessImage />
            <BusinessFullAddress>
                <BusinessTitle />
                <BusinessAddress />
            </BusinessFullAddress>
        </BusinessCardGridItemContainer>
    </BusinessCardGridContainer>
);

type Props = {
    count: number;
};

const BusinessLoadingCards = ({ count }: Props) => {
    const businessLoadingCardArray = useMemo(() => {
        return Array.from(Array(count || 6));
    }, [count]);
    return (
        <>
            {businessLoadingCardArray.map(_ => (
                <BusinessLoadingCard key={uniqueId()} />
            ))}
        </>
    );
};

export default BusinessLoadingCards;
