import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessCategories from 'app/businessEditV2/hooks/business/useBusinessCategories';
import useIsServicesAvailable from 'app/businessEditV2/hooks/services/useIsServicesAvailable';
import useServices from 'app/businessEditV2/hooks/services/useServices';
import useServicesSuggestions from 'app/businessEditV2/hooks/services/useServicesSuggestions';

export default function useIsLoadingServices(): boolean {
    const { isLoading: isLoadingBusiness } = useBusiness();
    const { isLoading: isLoadingIsServicesAvailable } =
        useIsServicesAvailable();
    const { isLoading: isLoadingServices } = useServices();
    const { isLoading: isLoadingBusinessCategories } = useBusinessCategories();
    const { isLoading: isLoadingServicesSuggestions } =
        useServicesSuggestions();

    return (
        isLoadingBusiness ||
        isLoadingIsServicesAvailable ||
        isLoadingServices ||
        isLoadingBusinessCategories ||
        isLoadingServicesSuggestions
    );
}
