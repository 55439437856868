import React, { useContext } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ReviewObjectType } from 'app/api/types/review';

import RatingNumber from './RatingNumber';
import RatingRecommendations from './RatingRecommendations';
import { StyledNoRating, StyledRating } from './ReviewRating.styled';
import { ReviewCardContext } from '../ReviewCard';

export const getNPSColor = (rating: number) => {
    if (rating < 7) {
        return HEX_COLORS.danger;
    } else if (rating < 9) {
        return HEX_COLORS.warning;
    }
    return HEX_COLORS.success;
};

export const ReviewRating = () => {
    const { t } = useTranslation();
    const {
        review: { rating, recommended, partner, reviewObjectType },
    } = useContext(ReviewCardContext);

    if (reviewObjectType === ReviewObjectType.FEEDBACK_RESULT) {
        return (
            <StyledRating>
                <Text variant="bodySSemibold" color={getNPSColor(rating)}>
                    {`${t('nps')} ${rating}`}
                </Text>
            </StyledRating>
        );
    }
    return (
        <StyledRating>
            {rating === -1 ? (
                <StyledNoRating>{t('no_rating')}</StyledNoRating>
            ) : rating ? (
                <RatingNumber rating={rating} partner={partner} />
            ) : (
                <RatingRecommendations recommendation={recommended ? 'positive' : 'negative'} />
            )}
        </StyledRating>
    );
};
