import { endOfDay, format, subMonths } from 'date-fns';

import useReviewsStats from 'app/common/hooks/queries/reviews/useReviewsStats';
import { ISO_DATETIME_FORMAT_WITH_TIMEZONE } from 'app/utils/dates';

const PREVIOUS_STAT_MONTHS_PERIOD = 6;

export const useReviewStatsPrevious = () => {
    const nMonthsAgo = format(
        endOfDay(subMonths(new Date(), PREVIOUS_STAT_MONTHS_PERIOD)),
        ISO_DATETIME_FORMAT_WITH_TIMEZONE,
    );
    return useReviewsStats({
        update_date__lte: nMonthsAgo,
    });
};
