import { Fragment, useEffect, useState } from 'react';

import {
    Expandable,
    FontAwesomeIconsPartooUsed,
    Icon,
    Stack,
    Text,
    capitalize,
} from '@partoohub/ui';

import { useLocation } from 'react-router-dom';

import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';

import { SettingsMenuListContainer, SettingsMenuListContent } from './SettingsMenuList.styled';
import { SettingMenuItem } from '../SettingMenuItem/SettingMenuItem';

export type SettingMenuItemsData = {
    menus: SettingMenuItem[];
    isLoading?: boolean;
};

export type SettingMenuItem = {
    label: string;
    pathname: string;
    options: Array<{
        name: string;
        value: string;
        label: string;
        pathname: string;
        display?: boolean;
    }>;
};
interface SettingsMenuListProps {
    sections: SettingMenuItem[];
}

export const SettingsMenuList = ({ sections }: SettingsMenuListProps) => {
    const { pathname } = useLocation();
    const [toggledSections, setToggledSections] = useState<{ [key: string]: boolean }>({});
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);

    const isActiveSection = (section: SettingMenuItem) =>
        section.options.some(option => option.pathname === pathname);

    // Function to toggle the state for a specific section
    const toggleSection = (section: SettingMenuItem) => {
        if (!isActiveSection(section)) {
            setToggledSections(prev => ({ ...prev, [section.label]: !prev[section.label] }));
        }
    };

    const countValidSections = () => {
        return sections.reduce((count, section) => {
            return count + (section.options.some(option => option.display !== false) ? 1 : 0);
        }, 0);
    };

    const shouldNotRender = (section: SettingMenuItem) =>
        section.options.every(option => option.display === false);

    useEffect(() => {
        const sectionCurrentSetting = sections.find(section =>
            pathname.startsWith(section.pathname),
        );

        setToggledSections(
            sectionCurrentSetting?.label ? { [sectionCurrentSetting.label]: true } : {},
        );
    }, [sections, pathname]);

    const isOpen = (section: SettingMenuItem) => {
        return (
            countValidSections() === 1 ||
            isBelowProvidedDevices ||
            isActiveSection(section) ||
            toggledSections[section.label]
        );
    };

    return (
        <SettingsMenuListContainer isBelowTablet={isBelowProvidedDevices}>
            {sections?.map(section =>
                section.options.length === 0 || shouldNotRender(section) ? null : (
                    <SettingsMenuListContent
                        key={section.label}
                        isBelowTablet={isBelowProvidedDevices}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap="4px"
                            onClick={
                                isBelowProvidedDevices ? undefined : () => toggleSection(section)
                            }
                            style={{
                                padding: '6px 8px',
                                cursor:
                                    isBelowProvidedDevices && isActiveSection(section)
                                        ? 'default'
                                        : 'pointer',
                            }}
                        >
                            <Text variant="bodyLSemibold" as="span">
                                {capitalize(section.label)}
                            </Text>
                            {countValidSections() > 1 && !isBelowProvidedDevices && (
                                <Icon
                                    icon={[
                                        toggledSections[section.label]
                                            ? FontAwesomeIconsPartooUsed.faChevronDown
                                            : FontAwesomeIconsPartooUsed.faChevronRight,
                                    ]}
                                    iconSize="16px"
                                />
                            )}
                        </Stack>
                        <Expandable opened={isOpen(section)}>
                            <Stack gap="0" direction="column" justifyContent="center">
                                {section?.options?.length > 0 &&
                                    section?.options?.map((option, index) =>
                                        option.display || option.display === undefined ? (
                                            <SettingMenuItem
                                                data-track={index}
                                                item={option}
                                                key={option.name}
                                            />
                                        ) : (
                                            <Fragment key={option.name}></Fragment>
                                        ),
                                    )}
                            </Stack>
                        </Expandable>
                    </SettingsMenuListContent>
                ),
            )}
        </SettingsMenuListContainer>
    );
};
