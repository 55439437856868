import { HEX_COLORS } from '@partoohub/branding';

import { Color } from 'app/common/designSystem/constants/color';

type Props = {
    width?: number;
    height?: number;
    color?: Color;
};

const AddImageIcon = ({ width = 56, height = 56, color = HEX_COLORS.blackberry }: Props) => (
    <svg width={width} height={height} viewBox="0 0 56 56" fill="none">
        <path
            d="M11.6667 20.9996C14.244 20.9996 16.3333 18.9102 16.3333 16.3329C16.3333 13.7556 14.244 11.6663 11.6667 11.6663C9.08933 11.6663 7 13.7556 7 16.3329C7 18.9102 9.08933 20.9996 11.6667 20.9996Z"
            fill={color}
        />
        <path
            d="M22.1667 40.8333C22.1667 31.738 28.7093 24.1523 37.3333 22.5073V6.99999C37.3333 3.14066 34.1926 0 30.3333 0H7C3.14066 0 0 3.14066 0 6.99999V39.6666C0 43.526 3.14066 46.6666 7 46.6666H23.114C22.5073 44.828 22.1667 42.8726 22.1667 40.8333ZM11.06 28.42C9.51999 26.88 6.81333 26.88 5.27333 28.42L4.66666 29.0243V6.99999C4.66666 5.71199 5.712 4.66666 7 4.66666H30.3333C31.6213 4.66666 32.6666 5.71199 32.6666 6.99999V22.0243L30.8933 20.251C29.2973 18.655 26.7003 18.655 25.1067 20.251L14 31.3576L11.06 28.42Z"
            fill={color}
        />
        <path
            d="M40.8336 25.6663C32.471 25.6663 25.667 32.4703 25.667 40.8329C25.667 49.1956 32.471 55.9996 40.8336 55.9996C49.1963 55.9996 56.0003 49.1956 56.0003 40.8329C56.0003 32.4703 49.1963 25.6663 40.8336 25.6663ZM47.2503 43.1663H43.167V47.2496C43.167 48.5399 42.124 49.5829 40.8336 49.5829C39.5433 49.5829 38.5003 48.5399 38.5003 47.2496V43.1663H34.417C33.1267 43.1663 32.0837 42.1233 32.0837 40.8329C32.0837 39.5426 33.1267 38.4996 34.417 38.4996H38.5003V34.4163C38.5003 33.1259 39.5433 32.0829 40.8336 32.0829C42.124 32.0829 43.167 33.1259 43.167 34.4163V38.4996H47.2503C48.5406 38.4996 49.5836 39.5426 49.5836 40.8329C49.5836 42.1233 48.5406 43.1663 47.2503 43.1663Z"
            fill={color}
        />
    </svg>
);

export default AddImageIcon;
