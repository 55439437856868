import { startOfDay } from 'date-fns';

import useReviews from 'app/common/hooks/queries/reviews/useReviews';
import { API_NOT_TREATED_REVIEWS } from 'app/states/reviews/filters';

export const useTodoReviewsData = () => {
    const sixMonthsAgo = startOfDay(new Date(new Date().setMonth(new Date().getMonth() - 6)));

    return useReviews({
        // Only reviews that are not treated
        state__in: [API_NOT_TREATED_REVIEWS],
        // Reviews from the last 6 months
        update_date__gte: sixMonthsAgo.toISOString(),
    });
};
