import {
    ConnectionsStatsState,
    countSelector,
    linkedToGmbCountSelector,
} from 'app/states/knowledge/reducers/businessConnection/connectionsStats';
import {
    NavValue,
    TO_CONNECT_TO_GMB,
} from 'app/states/knowledge/reducers/businessConnection/nav';

const filteredBusinessCount = (
    state: ConnectionsStatsState,
    connectedTo: NavValue | null,
): number => {
    if (connectedTo === TO_CONNECT_TO_GMB) {
        return linkedToGmbCountSelector(state);
    }

    return countSelector(state);
};

export default filteredBusinessCount;
