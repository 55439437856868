import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import api from 'app/api/v2/api_calls';

import { BUSINESSES } from 'app/common/data/queryKeysConstants';
import { BUSINESS_MANAGER, GROUP_MANAGER, ORG_ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMe';
import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';

export default function useIsLoadingBusinessEditRedirection() {
    const { data: me } = useMe();
    const navigate = useNavigate();

    // To prevent the page to be displayed during the redirection :/
    const [isRedirecting, setIsRedirecting] = useState<boolean>(true);
    const [firstLoading, setFirstLoading] = useState<boolean>(true);

    const doApiCall = !!me?.role && [ORG_ADMIN, GROUP_MANAGER, BUSINESS_MANAGER].includes(me?.role);

    const { data, isLoading } = useQuery(
        [BUSINESSES, {}],
        () => api.business.searchBusinesses({}),
        {
            enabled: doApiCall && firstLoading,
        },
    );

    useEffect(() => {
        if (data && firstLoading) {
            setFirstLoading(false);
            if (data?.count === 1) {
                navigate(`${VISIBILITY_LOCATION_PATH}/${data.businesses[0].id}`, {
                    replace: true,
                });
            } else {
                setIsRedirecting(false);
            }
        }
    }, [data]);

    // Display loading until role is retrieved
    if (!me?.role) {
        return true;
    }

    // No loading if no api call needed
    if (!doApiCall) {
        return false;
    }

    // Only display loading the first time on the page
    return (isLoading && firstLoading) || isRedirecting;
}
