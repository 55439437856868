import { connect } from 'react-redux';

import ProductSidebar from 'app/common/designSystem/components/molecules/Sidebar/Sidebar/ProductSidebar';
import { dataSelector, productSelector } from 'app/common/reducers/me';
import { AppState } from 'app/states/reducers';

const mapStateToProps = (state: AppState) => ({
    role: state?.me?.data?.role,
    sidebarProducts: productSelector(state.me),
    userData: dataSelector(state.me),
});

export default connect(mapStateToProps)(ProductSidebar);
