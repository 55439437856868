import { useTranslation } from 'react-i18next';

import { V2FormattedLocationData } from 'app/api/types/account';
import { ServiceAreaButton } from 'app/businessConnection/components/commonOld/ConnectionButtonLogo.styled';
import { LocationDetailsContainer } from 'app/businessConnection/components/commonOld/LocationDetails.styled';

import { CardLine } from './CardLine';

type Props = {
    location: V2FormattedLocationData;
};

const LocationDetails = ({ location }: Props) => {
    const { t } = useTranslation();
    return (
        <LocationDetailsContainer>
            {!location.addressDetails && (
                <ServiceAreaButton>{t('google_location_service_area')}</ServiceAreaButton>
            )}

            {location.addressDetails && (
                <>
                    <CardLine text={location.addressDetails} />
                    <CardLine text={`${location.postalCode} ${location.locality}`} />
                </>
            )}
        </LocationDetailsContainer>
    );
};

export default LocationDetails;
