import styled from '@emotion/styled';

import { threeColumnsTable } from 'app/deprecated.styled';

import { ModalContent } from '../../ExportDataModal.styled';

export const SectionsFilterModalContent = styled(ModalContent)`
    width: 500px;
`;

export const SectionButtonWrapper = styled.div`
    margin-top: 24px;
    padding-bottom: 0px;
    ${threeColumnsTable};
`;
