import { IconButton, IconPrefix, Tooltip } from '@partoohub/ui';
import { Interweave } from 'interweave';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { FormattedReplyTemplate } from 'app/api/types/reviewReplyTemplates';
import api from 'app/api/v2/api_calls';
import { showConfirm } from 'app/common/components/ConfirmModal/reducers/confirm';
import FLAG from 'app/common/flags.json';
import { LANGUAGES_MAP } from 'app/common/services/languages';
import { ChipMatcher } from 'app/common/services/Matchers';
import { FlagContainer } from 'app/pages/settings/AutoReply/components/SettingsPageForm/TemplateSelectStep/ReplyTemplateList/ReplyTemplateCard.styled';
import useGetTemplatePlaceholders from 'app/pages/settings/AutoReply/hooks/queries/useGetTemplatePlaceholders';

import ReplyTemplateBadge from './ReplyTemplateBadge';
import {
    ButtonContainer,
    CardContentGrid,
    RatingGridArea,
    StyledCard,
    TextGridArea,
    TitleGridArea,
} from './ReplyTemplateCard.styled';

type Props = {
    template: FormattedReplyTemplate;
    onClickEdit: () => void;
    onClickDuplicate: () => void;
};

function ReplyTemplateCard({ template, onClickEdit, onClickDuplicate }: Props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { language = '', title = '', text = '', orgId, ratings = [] } = template;
    const placeholders = useGetTemplatePlaceholders() ?? [];
    const textMatchers = placeholders.map(element =>
        ChipMatcher(`@\\[${element.label}]\\(${element.value}\\)`, t(element.label)),
    );

    function onDelete() {
        api.review
            .getTemplateHasAutoReplySettings(`${template.id}`)
            .then(({ has_auto_reply_settings }) => {
                if (has_auto_reply_settings) {
                    dispatch(showConfirm('reply_template_has_auto_reply_settings_modal', {}));
                } else {
                    dispatch(
                        showConfirm(
                            'delete_reply_template_modal',
                            {
                                templateId: `${template.id}`,
                            },
                            [],
                        ),
                    );
                }
            });
    }

    return (
        <StyledCard>
            <CardContentGrid tabIndex={-1} role="link" onKeyDown={() => undefined}>
                <RatingGridArea>
                    {ratings.map((rating: string) => (
                        <ReplyTemplateBadge key={rating} literalRating={rating} />
                    ))}
                    {!orgId && <ReplyTemplateBadge key="original" literalRating={'original'} />}
                    <ButtonContainer>
                        <Tooltip text={t('edit')} placement="top">
                            <IconButton
                                dataTrackId="reply-template-card-edit"
                                appearance="outlined"
                                icon={['fa-pen-clip', IconPrefix.REGULAR]}
                                onClick={onClickEdit}
                            />
                        </Tooltip>
                        <Tooltip text={t('duplicate')} placement="top">
                            <IconButton
                                dataTrackId="reply-template-card-duplicate"
                                appearance="outlined"
                                icon={['fa-copy', IconPrefix.REGULAR]}
                                onClick={onClickDuplicate}
                            />
                        </Tooltip>
                        <Tooltip text={t('delete')} placement="top">
                            <IconButton
                                dataTrackId="reply-template-card-delete"
                                appearance="outlined"
                                icon={['fa-trash', IconPrefix.REGULAR]}
                                onClick={onDelete}
                            />
                        </Tooltip>
                    </ButtonContainer>
                </RatingGridArea>
                <FlagContainer>
                    <img src={FLAG[LANGUAGES_MAP[language]?.country]} alt={language} />
                </FlagContainer>
                <TitleGridArea variant="heading6">{title}</TitleGridArea>
                <TextGridArea color={'secondary'}>
                    <Interweave content={text} matchers={textMatchers} />
                </TextGridArea>
            </CardContentGrid>
        </StyledCard>
    );
}

export default ReplyTemplateCard;
