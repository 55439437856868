import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import LoadingView from 'app/common/components/LoadingView';
import { NoReviewManagement } from 'app/common/components/placeholder/reviewMangement/NoReviewManagement';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import SettingsPageForm from 'app/pages/settings/AutoReply/components/SettingsPageForm';
import SaveBar from 'app/pages/settings/AutoReply/components/SettingsPageForm/SaveBar';
import TitleBar from 'app/pages/settings/AutoReply/components/SettingsPageForm/TitleBar';
import { SettingsPageProvider } from 'app/pages/settings/AutoReply/hooks/useSettingsPageContext';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';

export const WithoutComment = () => {
    const { t } = useTranslation();
    const { subscribedToRM, isLoading } = useBusinessSubscriptions();

    if (isLoading) {
        return <LoadingView />;
    }

    if (!subscribedToRM) {
        return <NoReviewManagement />;
    }

    return (
        <SettingsPageProvider forReviewsWithComment={false}>
            <MenuListContentTemplate headerContent={<TitleBar />} footerContent={<SaveBar />}>
                <MenuListContentContainer>
                    <>
                        <DynamicPageHeader title={t('auto_reply_reviews')} />
                        <SettingsPageForm />
                    </>
                </MenuListContentContainer>
            </MenuListContentTemplate>
        </SettingsPageProvider>
    );
};
