import { Button, Dialog, Modal, Stack } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useBusinessSelectionContext } from 'app/pages/visibility/BusinessListV2/hooks/businessSelection/useBusinessSelectionContext';

interface AddPhotosConfirmationModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    onSave: () => void;
}

export const AddPhotosConfirmationModal = ({
    show,
    setShow,
    onSave,
}: AddPhotosConfirmationModalProps) => {
    const { t } = useTranslation();
    const { selectedNumber } = useBusinessSelectionContext();
    const closeModal = () => setShow(false);

    return (
        <Modal isOpen={show} closeModal={closeModal} placement="center">
            <Dialog
                dataTrackId=""
                key="example-with-illustration"
                title={t('bulk_in_app_funnel__add_photos_page__confirmation_modal_title')}
                subTitle={t('bulk_in_app_funnel__add_photos_page__confirmation_modal_subtitle', {
                    count: selectedNumber,
                })}
            >
                <Stack direction="row">
                    <Button
                        dataTrackId="bulk_in_app__add_photos_page__confirmation_modal_cancel"
                        variant="secondary"
                        size="large"
                        onClick={closeModal}
                        appearance="outlined"
                        full
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        dataTrackId="bulk_in_app__add_photos_page__confirmation_modal_save"
                        variant="primary"
                        size="large"
                        onClick={onSave}
                        full
                    >
                        {t('confirm')}
                    </Button>
                </Stack>
            </Dialog>
        </Modal>
    );
};
