import { useContext, useState } from 'react';

import { IconPrefix } from '@partoohub/ui';

import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { CommentData } from 'app/api/types/comments';
import { useShowReviewReplyField } from 'app/reviewManagement/reviewList/hooks/ReviewCard';
import { PENDING_AUTO_REPLY_REVIEWS } from 'app/states/reviews/filters';

import CommentPendingAutoReply from './CommentTable/CommentPendingAutoReply';
import { CommentTable } from './CommentTable/CommentTable';
import {
    StyleToggleCommentsButton,
    StyledReplyButton,
    StyledReviewCardCommentsSection,
} from './ReviewCardCommentsSection.styled';
import { ReviewCardContext, ReviewCardContextType } from '../ReviewCard';

type Props = {
    comments: Array<CommentData>;
};

export function ReviewCardCommentsSection({ comments }: Props) {
    const { t } = useTranslation();
    const { review } = useContext<ReviewCardContextType>(ReviewCardContext);
    const [showAllComments, setShowAllComments] = useState<boolean>(true);
    const visibleComments = comments
        .sort((a, b) => parseISO(a.date).valueOf() - parseISO(b.date).valueOf())
        .slice(0, showAllComments ? undefined : 1);

    const canRespond = useShowReviewReplyField({ review, comments });

    const onToggleComments = () => setShowAllComments(!showAllComments);

    return (
        <StyledReviewCardCommentsSection>
            {review.state === PENDING_AUTO_REPLY_REVIEWS ? (
                <CommentPendingAutoReply
                    commentContent={review.pendingAutoReply?.content}
                    commentDate={review.pendingAutoReply?.scheduledAt}
                />
            ) : (
                <>
                    {(comments.length > 0 || review.partner !== 'tripadvisor') && (
                        <CommentTable comments={visibleComments} isReplying={canRespond} />
                    )}
                    {!comments.length &&
                        review.state !== 'deleted' &&
                        review.partner === 'tripadvisor' && (
                            <StyleToggleCommentsButton
                                dataTrackId="reply__button"
                                variant="primary"
                                appearance="text"
                                icon={['fa-reply', IconPrefix.SOLID]}
                                onClick={() => window.open(review.link || undefined)}
                            >
                                {t('reply')}
                            </StyleToggleCommentsButton>
                        )}
                    {comments.length > 1 && (
                        <StyledReplyButton
                            dataTrackId="toggle_comments__button"
                            variant="primary"
                            appearance="text"
                            onClick={() => onToggleComments()}
                        >
                            {showAllComments ? t('hide_comments') : t('show_comments')}
                        </StyledReplyButton>
                    )}
                </>
            )}
        </StyledReviewCardCommentsSection>
    );
}
