import { Banner, Button, Icon, Switch, Text, TextArea, toast } from '@partoohub/ui';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'app/config';
import { JimIcon } from 'app/pages/settings/Chatbot/assets/jim-icon';
import {
    ChatbotInstructionContainer,
    ChatbotInstructionSection,
    ChatbotInstructionsButtonContainer,
    ConversationContainer,
    CustomInstructionsCheckbox,
    HandoverInfoMessage,
    ImageContainer,
    ImageContainerBackground,
    ImageWrapper,
    LogoIconContainer,
    MessageBubble,
    MessageBubbleContainer,
    PhoneImage,
    PhoneImageBackground,
    PhoneImageShadow,
} from 'app/pages/settings/Chatbot/components/Instructions/ChatbotInstruction.styled';
import { CUSTOM_PROMPT_MAX_TOKENS } from 'app/pages/settings/Chatbot/constants/constants';
import { useChatbot } from 'app/pages/settings/Chatbot/hooks/useChatbot';
import { useChatbotUpdate } from 'app/pages/settings/Chatbot/hooks/useChatbotUpdate';

interface Props {
    onClose: () => void;
    setHasSucceeded?: (state: boolean) => void;
}

type FormProps = {
    handover_message: string;
    custom_prompt: string;
    custom_prompt_enabled: boolean;
};

export const ChatbotInstruction = ({ onClose, setHasSucceeded }: Props) => {
    const { t } = useTranslation();
    const { data } = useChatbot();
    const ChatbotUpdateMutation = useChatbotUpdate();
    const { control, handleSubmit, reset, formState } = useForm<FormProps>({
        defaultValues: {
            handover_message: data?.handover_message,
            custom_prompt: data?.custom_prompt,
            custom_prompt_enabled: data?.custom_prompt_enabled,
        },
    });
    const handover_message = useWatch({ control, name: 'handover_message' });
    const custom_prompt_enabled = useWatch({ control, name: 'custom_prompt_enabled' });
    const custom_prompt_error_mapping: { [key: string]: string } = {
        required: t('chatbot_field_is_required_error'),
        maxLength: t('chatbot_field_custom_prompt_max_length_error'),
    };
    const handover_error_mapping: { [key: string]: string } = {
        required: t('chatbot_field_is_required_error'),
        maxLength: t('chatbot_field_handover_message_max_length_error'),
    };

    const handleOnClose = () => {
        onClose();
        reset();
    };

    const handleOnSubmit = (data: FormProps) => {
        onClose();
        ChatbotUpdateMutation.mutate(data);
        if (setHasSucceeded) {
            setHasSucceeded(true);
            toast.success(
                t('chatbot_snackbar_changes_saved_text'),
                t('chatbot_snackbar_changes_saved_title'),
            );
        }
    };

    return (
        <ChatbotInstructionContainer>
            <ChatbotInstructionSection>
                <Text variant="bodyLBold">{t('chatbot_instruction_section_subtitle')}</Text>
                <ImageContainer>
                    <ImageWrapper>
                        <PhoneImage
                            src={`${STATIC_BASE_URL}/images/app/app_review/mobile_preview_template.svg`}
                            alt="iphone-preview"
                        />
                        <PhoneImageBackground>
                            <ConversationContainer>
                                <LogoIconContainer>
                                    <JimIcon size={16} />
                                </LogoIconContainer>
                                <MessageBubbleContainer>
                                    <MessageBubble variant="bodyMRegular">
                                        {handover_message}
                                    </MessageBubble>
                                </MessageBubbleContainer>
                            </ConversationContainer>
                            <HandoverInfoMessage>
                                <Icon
                                    icon={['fa-circle-xmark']}
                                    color="secondary"
                                    iconSize="12px"
                                />
                                <Text color="secondary" variant="bodySMedium">
                                    {t('chatbot_instruction_conversation_handover_message')}
                                </Text>
                            </HandoverInfoMessage>
                        </PhoneImageBackground>
                        <PhoneImageShadow />
                    </ImageWrapper>
                    <ImageContainerBackground />
                </ImageContainer>
                <Controller
                    control={control}
                    rules={{ required: true, maxLength: 400 }}
                    name="handover_message"
                    render={({ field: { ref: _ref, ...usedFields } }) => (
                        <TextArea
                            dataTrackId=""
                            {...usedFields}
                            label={t('chatbot_instruction_handover_message_title')}
                            error={['required', 'maxLength'].includes(
                                formState.errors.handover_message?.type ?? '',
                            )}
                            notice={
                                handover_error_mapping[
                                    formState.errors.handover_message?.type ?? ''
                                ] ?? undefined
                            }
                            maxRows={50}
                            rows={3}
                        />
                    )}
                />
            </ChatbotInstructionSection>
            <ChatbotInstructionSection>
                <Controller
                    control={control}
                    name="custom_prompt_enabled"
                    render={({ field: { ref: _ref, onChange, value } }) => (
                        <CustomInstructionsCheckbox>
                            <Text variant="bodyLBold">
                                {t('chatbot_instruction_custom_prompt_enabled_label')}
                            </Text>
                            <Switch
                                dataTrackId=""
                                onChange={onChange}
                                checked={value}
                                name="custom_prompt_enabled"
                            />
                        </CustomInstructionsCheckbox>
                    )}
                />
                {custom_prompt_enabled && (
                    <Controller
                        control={control}
                        rules={{ required: true, maxLength: CUSTOM_PROMPT_MAX_TOKENS }}
                        name="custom_prompt"
                        render={({ field: { ref: _ref, ...usedFields } }) => (
                            <>
                                <Banner
                                    dataTrackId=""
                                    variant="warning"
                                    icon={
                                        <Icon
                                            icon={['fa-warning']}
                                            color="warning"
                                            iconSize="14px"
                                        />
                                    }
                                    withCloseIcon={false}
                                >
                                    <Text variant="bodyMRegular" color="warning">
                                        {t('chatbot_instruction_custom_prompt_warning_information')}
                                    </Text>
                                </Banner>
                                <TextArea
                                    dataTrackId=""
                                    {...usedFields}
                                    label={t('chatbot_instruction_custom_prompt_title')}
                                    error={['required', 'maxLength'].includes(
                                        formState.errors.custom_prompt?.type ?? '',
                                    )}
                                    notice={
                                        custom_prompt_error_mapping[
                                            formState.errors.custom_prompt?.type ?? ''
                                        ] ?? undefined
                                    }
                                    maxRows={50}
                                    rows={3}
                                />
                            </>
                        )}
                    />
                )}
            </ChatbotInstructionSection>
            <ChatbotInstructionsButtonContainer>
                <Button
                    onClick={handleOnClose}
                    dataTrackId="chatbot_instruction_cancel"
                    appearance="outlined"
                    variant="secondary"
                    shape="cube"
                    size="large"
                >
                    {t('cancel')}
                </Button>
                <Button
                    onClick={handleSubmit(data => handleOnSubmit(data))}
                    dataTrackId="chatbot_instruction_confirm"
                    appearance="contained"
                    shape="cube"
                    variant="primary"
                    size="large"
                    full
                >
                    {t('chatbot_form_confirm_button')}
                </Button>
            </ChatbotInstructionsButtonContainer>
        </ChatbotInstructionContainer>
    );
};
