import React from 'react';

import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import getLang from 'app/common/services/getLang';
import useUserCount from 'app/pages/settingsV2/subPages/Team/components/UserManagement/hooks/useUserCount';

const UserCount = () => {
    const { t } = useTranslation();

    const count = useUserCount();
    const formattedCount = `${count.toLocaleString(getLang())} ${t('header_counter_users_list', {
        count,
    })}`;

    return <Text variant="heading6">{formattedCount}</Text>;
};

export default UserCount;
