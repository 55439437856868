import { useEffect, useState } from 'react';

export const useIntersectionObserver = (target: any, options: any) => {
    const [intersecting, setIntersecting] = useState<boolean>();
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            setIntersecting(entries[0].isIntersecting);
        }, options);
        observer.observe(target.current);
        return () => observer.disconnect();
    }, []);
    return intersecting;
};
