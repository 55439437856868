import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

import { CompletionBadgeAnimation } from 'app/businessEditV2/BusinessEditV2.styled';
import { BUSINESS_LIST_BREAKPOINT } from 'app/pages/visibility/BusinessListV2/BusinessList.styled';

type BusinessBadgeProps = {
    selected: boolean;
};

export const Count = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 1;
`;

export const FilterButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
`;

export const FilterButtonContent = styled.div`
    padding: 8px 16px;
    border: solid 1px ${HEX_COLORS.white};
    border-radius: 100px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const Badge = styled.div`
    position: absolute;
`;

export const BusinessBadgeContainer = styled.div<BusinessBadgeProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-basis: 100%;
    height: 178px;
    color: ${HEX_COLORS.white};
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.default.initial};
    border-radius: 16px;
    padding: 16px 0;
    transition: transform ease 0.2s;

    @media (${BUSINESS_LIST_BREAKPOINT}) {
        width: 140px;
    }

    ${({ selected, theme }) =>
        selected &&
        css`
            &:after {
                opacity: 1;
                position: absolute;
                content: '';
                box-shadow: ${theme.colors.primary.initial} 0 0 0 2px;
                min-width: calc(100% + 4px);
                min-height: calc(100% + 4px);
                top: -2px;
                border-radius: inherit;
                pointer-events: none;
            }
        `}

    &:hover {
        cursor: pointer;
        transform: scale(1.04);

        ${Count} {
            opacity: 0;
        }

        ${FilterButton} {
            opacity: 1;
        }

        ${Badge} {
            svg {
                ${CompletionBadgeAnimation({})}
            }
        }
    }
`;

export const Title = styled.div`
    font-size: 12px;
`;

export const Content = styled.div`
    width: 100%;
    height: 43px;
    position: relative;
`;
