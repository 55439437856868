import styled from '@emotion/styled';

import { STATIC_APP_ASSETS_URL } from 'app/config';

export const BannerContainer = styled.div`
    width: 100%;
    padding: 24px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    gap: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        background-image: url('${STATIC_APP_ASSETS_URL}/common/chatbot-banner-background.png');
        background-size: cover;
        background-position-y: center;
    }

    aside {
        position: absolute;
        top: 0;
        right: 0;
        padding: 8px;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
`;

export const BulletsPoints = styled.ol`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 4px;
    i {
        height: 24px;
        width: 24px;
    }
    li {
        display: flex;
    }
`;
