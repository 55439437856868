import React from 'react';

import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { HolidaysSuggestionHeaderContainer } from './HolidaysSuggestionHeader.styled';

type Props = {
    onClick: () => void;
    headerCollapsed: boolean;
};

const HolidaysSuggestionHeader = ({ onClick, headerCollapsed }: Props) => {
    const { t } = useTranslation();

    const getCurrentAndNextYear = (): [number, number] => {
        const currentYear: number = new Date().getFullYear();
        const nextYear: number = currentYear + 1;
        return [currentYear, nextYear];
    };

    const [currentYear, nextYear] = getCurrentAndNextYear();

    return (
        <HolidaysSuggestionHeaderContainer onClick={onClick}>
            <Text variant="bodyLBold">
                {t('bank_holidays__upcoming_bank_holidays', {
                    current_year: currentYear,
                    next_year: nextYear,
                })}
            </Text>

            {headerCollapsed ? (
                <i className="fa-solid fa-chevron-down" />
            ) : (
                <i className="fa-solid fa-chevron-up" />
            )}
        </HolidaysSuggestionHeaderContainer>
    );
};

export default HolidaysSuggestionHeader;
