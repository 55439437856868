import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix } from '@partoohub/ui';

import { DayString } from 'app/api/types';
import { MoreHourType, MoreHourValue } from 'app/api/types/more_hours';
import { DAYS } from 'app/businessEditV2/oldFromEditV1/data/constants';
import { findFirstString } from 'app/businessEditV2/utils/utils';
import OpenHourDay from 'app/common/components/OpenHour/OpenHourDay';

import {
    MoreHourAngleIcon,
    MoreHourButtons,
    MoreHourContainer,
    MoreHourContent,
    MoreHourHeader,
    MoreHourName,
    MoreHourOpenHourDay,
} from './MoreHour.styled';

type Props = {
    moreHour: MoreHourType & { value: MoreHourValue };
    onChange: (value: MoreHourValue) => void;
    opened: Record<string, boolean | undefined>;
    setOpened: (value: Record<string, boolean | undefined>) => void;
    errors: Record<string, string>;
    disabled: boolean;
};

const MoreHour = ({ moreHour, onChange, opened, setOpened, errors, disabled }: Props) => {
    const onMoreHourDayChanged = (day: DayString, dayValue: Array<string>) => {
        const newMoreHourValue = {
            ...moreHour.value,
            [day]: dayValue,
        };
        onChange(newMoreHourValue as MoreHourValue);
    };

    const isOpen = !!opened[moreHour.gmb_id];

    const [contentHeight, setContentHeight] = useState<number>(0);

    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentRef.current) setContentHeight(contentRef.current.scrollHeight);
    }, [contentRef.current, moreHour]);

    return (
        <MoreHourContainer>
            <MoreHourHeader
                onClick={() => {
                    setOpened({
                        ...opened,
                        [moreHour.gmb_id]: !isOpen,
                    });
                }}
            >
                <MoreHourName disabled={disabled}>{moreHour.name}</MoreHourName>
                <MoreHourButtons>
                    {!disabled && (
                        <IconButton
                            appearance="outlined"
                            variant="danger"
                            icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                            onClick={e => {
                                e.stopPropagation();
                                onChange(null);
                            }}
                            dataTrackId="delete_more_hour_button"
                        />
                    )}
                    <MoreHourAngleIcon>
                        {isOpen ? (
                            <i className="fas fa-angle-down" />
                        ) : (
                            <i className="fas fa-angle-right" />
                        )}
                    </MoreHourAngleIcon>
                </MoreHourButtons>
            </MoreHourHeader>
            <MoreHourContent ref={contentRef} collapsed={!isOpen} maxHeight={contentHeight}>
                {DAYS.map((day: DayString) => (
                    <MoreHourOpenHourDay key={`${moreHour.gmb_id}_${day}`}>
                        <OpenHourDay
                            disabled={disabled}
                            day={day}
                            dayValue={moreHour?.value?.[day] ?? []}
                            onChange={dayValue => onMoreHourDayChanged(day, dayValue)}
                            hoursError={findFirstString(errors?.[day]) ?? ''}
                        />
                    </MoreHourOpenHourDay>
                ))}
            </MoreHourContent>
        </MoreHourContainer>
    );
};

export default MoreHour;
