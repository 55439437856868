import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

import Card from 'app/common/designSystem/components/templates/Card';

export const ButtonContainer = styled.div`
    display: flex;
    margin-left: auto;
`;

export const StyledCard = styled(Card)`
    border-radius: ${({ theme }) => theme.radius.large};
    &:hover {
        background-color: transparent;
    }
`;

export const CardContentGrid = styled.div`
    display: grid;
    grid-gap: 8px 8px;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
        'rating rating menu'
        'flag title menu'
        'text text menu';

    width: 100%;
    margin: 16px 0 16px 24px;
    outline: none;
`;

export const RatingGridArea = styled.div`
    grid-area: rating;
    display: flex;
    flex-flow: row wrap;
    font-weight: bold;
    align-items: center;
    gap: 8px;
`;

export const TitleGridArea = styled(Text)`
    grid-area: title;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const TextGridArea = styled(Text)`
    grid-area: text;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: inherit;
`;
