import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useDisplayMenu from 'app/businessEditV2/hooks/editV2utils/displaySections/useDisplayMenu';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useBusinessFoodMenus from 'app/businessEditV2/hooks/menu/useBusinessFoodMenus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';
import { businessHasNoMenu } from 'app/businessEditV2/utils/menu';

import { ColorState } from './types';

export default function useMenuSectionColor(): ColorState {
    // Hooks
    const foodMenuPermission = useFieldMetadata('food_menu', 'food_menu')?.enabled;
    const { data: business } = useBusiness();
    const { data: businessFoodMenu } = useBusinessFoodMenus();
    const isClosed = useIsBusinessClosed();
    const display = useDisplayMenu();
    const { data: publisherErrors } = usePublisherErrors({});

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // If no menu for the business
    if (!display) {
        return ColorState.OK;
    }

    // Disabled
    if (!foodMenuPermission) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (publisherErrors?.food_menu?.food_menu) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields
    if (businessHasNoMenu(business, businessFoodMenu)) {
        return ColorState.MISSING_WARNING;
    }

    // OK
    return ColorState.OK;
}
