import { LoadingSpinnerContainer } from './LoadingSpinner.styled';

interface Props {
    fontSize?: string;
}

const LoadingSpinner = ({ fontSize = '12px' }: Props) => (
    <LoadingSpinnerContainer
        style={{
            fontSize,
        }}
    >
        <i className="fa-solid fa-circle-notch fa-spin" />
    </LoadingSpinnerContainer>
);

export default LoadingSpinner;
