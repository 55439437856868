import { PureComponent } from 'react';

import { NumberInput } from '@partoohub/ui';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { withTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

type Props = {
    type: 'integer' | 'float';
    value: string;
    name: string;
    onChange: (newValue: any) => void;
    disabled: boolean;
    error: string;
    t: (key: string, args?: Record<string, any> | null) => string;
    min?: number | null;
    max?: number | null;
    displayName?: string;
};

type State = {
    errorMessage: string;
};

class Component extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errorMessage: '',
        };
    }

    generateMinMaxError = (value: string | null) => {
        const { min, max, t } = this.props;
        let minMaxError = '';

        if (value === null || value === '') {
            this.setState({
                errorMessage: minMaxError,
            });
            return;
        }

        if (
            min !== undefined &&
            min !== null &&
            !Number.isNaN(parseFloat(value)) &&
            parseFloat(value) < min
        ) {
            minMaxError = t('does_not_respect_the_constraint_min', {
                min,
                value,
            });
        } else if (
            max !== undefined &&
            max !== null &&
            !Number.isNaN(parseFloat(value)) &&
            parseFloat(value) > max
        ) {
            minMaxError = t('does_not_respect_the_constraint_max', {
                max,
                value,
            });
        }

        this.setState({
            errorMessage: minMaxError,
        });
    };

    onChangeFloat = (stringValue: string | null) => {
        const { onChange, type } = this.props;
        this.generateMinMaxError(stringValue);

        let newValue; // Variable to hold the new parsed value

        const cleanValue = stringValue?.replace(/[^0-9.,+-]/g, '') ?? '';

        if (type === 'integer') {
            newValue = cleanValue === '' ? null : parseInt(cleanValue, 10);
        } else if (type === 'float') {
            newValue = cleanValue === '' ? null : parseFloat(cleanValue);
        }

        onChange(newValue);
    };

    render() {
        const { value, name, disabled, min, max, error, t, displayName } = this.props;
        const { errorMessage } = this.state;
        return (
            <TooltipWrapper text={disabled ? t('field_read_only') : ''}>
                <NumberInput
                    dataTrackId=""
                    value={value}
                    min={min ?? undefined}
                    max={max ?? undefined}
                    onChange={stringValue =>
                        // Just in case, not sure about the return type of stringValue
                        this.onChangeFloat(
                            typeof stringValue === 'number' ? stringValue.toString() : stringValue,
                        )
                    }
                    label={displayName || name}
                    disabled={disabled}
                    error={!!(error || errorMessage)}
                    notice={error || errorMessage}
                />
            </TooltipWrapper>
        );
    }
}

const TranslatedComponent = withTranslation()(Component);

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'onChange' | 'value' | 't';

const CustomFieldsTypeNumber = ({
    name,
    control,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { ref: _ref, onBlur, ...usedFields } }) => (
            <TranslatedComponent {...props} {...usedFields} />
        )}
    />
);

export default CustomFieldsTypeNumber;
