import React, { useCallback, useEffect } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix, Stack } from '@partoohub/ui';

import UnsavedModal from 'app/businessEditV2/components/UnsavedModal';
import { KeyCode } from 'app/common/types/keyboard';
import { useFunnelModalContext } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/hooks/FunnelModalContext';

import {
    FullScreen,
    FunnelBody,
    FunnelModalContentWrapper,
    FunnelModalLeftButton,
    FunnelModalRightButton,
    FunnelPageBody,
    FunnelPageFooter,
} from './FunnelModalTemplate.styled';
import { FunnelPageHeader } from './FunnelPageHeader';
import { TipContainer } from './TipContainer';

type FunnelModalTemplateProps = {
    onBack?: () => void;
    children: React.ReactNode;
    footer: React.ReactNode;
};

export const FunnelModalTemplate = ({ onBack, children, footer }: FunnelModalTemplateProps) => {
    const { setShowFunnel, showUnsavedModal, setShowUnsavedModal, onClose, photos } =
        useFunnelModalContext();

    const handleKeyDown = useCallback(
        event => {
            if (event.key === KeyCode.Escape) {
                onClose();
            }
        },
        [showUnsavedModal, photos],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <FullScreen>
            <Stack direction="row">
                {onBack && (
                    <FunnelModalLeftButton
                        dataTrackId="back_button_action"
                        appearance="contained"
                        variant="default"
                        icon={[FontAwesomeIconsPartooUsed.faArrowLeft, IconPrefix.SOLID]}
                        onClick={onBack}
                    />
                )}
                <FunnelModalRightButton
                    dataTrackId="close_button_action"
                    appearance="contained"
                    variant="default"
                    icon={[FontAwesomeIconsPartooUsed.faClose, IconPrefix.SOLID]}
                    onClick={onClose}
                />
                <FunnelBody direction="row" gap="40px" alignItems="space-between">
                    <FunnelModalContentWrapper direction="column" gap="0px">
                        <FunnelPageHeader />
                        <FunnelPageBody>{children}</FunnelPageBody>
                        <FunnelPageFooter>{footer}</FunnelPageFooter>
                    </FunnelModalContentWrapper>
                    <TipContainer />
                </FunnelBody>
                <UnsavedModal
                    show={showUnsavedModal}
                    cancel={() => setShowUnsavedModal(false)}
                    validate={() => setShowFunnel(false)}
                />
            </Stack>
        </FullScreen>
    );
};
