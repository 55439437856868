import { push } from '@lagunovsky/redux-react-router';
import { put, select, takeEvery } from 'redux-saga/effects';

import getPath, {
    NEXT_PAGE_SEARCH_PARAM,
    testPath,
} from 'app/common/data/routeIds';
import { Saga } from 'app/common/types';
import {
    CONNECTION_PATH,
    LOG_OUT_PATH,
    MESSAGING_PRIVACY_POLICY_PATH,
} from 'app/routing/routeIds';
import { logoutCleanSaga } from 'app/states/connection/sagas/logoutSaga';
import { locationSelector } from 'app/states/router';

export const API_AUTH_EXCEPTION = 'API_AUTH_EXCEPTION';

const authInterceptor = function* () {
    const currentPath = getPath();
    const { search: currentSearch } = yield select(locationSelector);

    if (currentPath) {
        if (
            !(
                testPath(CONNECTION_PATH, currentPath) ||
                testPath(MESSAGING_PRIVACY_POLICY_PATH, currentPath)
            )
        ) {
            yield* logoutCleanSaga();

            if (testPath(LOG_OUT_PATH, currentPath)) {
                yield put(push(`${CONNECTION_PATH}`));
            } else {
                yield put(
                    push(
                        `${CONNECTION_PATH}?${NEXT_PAGE_SEARCH_PARAM}=${encodeURI(
                            currentPath + currentSearch,
                        )}`,
                    ),
                );
            }
        }
    }
};

const authInterceptorSaga = function* (): Saga {
    yield takeEvery(API_AUTH_EXCEPTION, authInterceptor);
};

export default authInterceptorSaga;
