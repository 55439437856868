import { Navigate, useRoutes } from 'react-router-dom';

import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks/useDeviceType';
import { findFirstDisplayPathname } from 'app/pages/settingsV2/helpers';
import { useSettingMainMenu } from 'app/pages/settingsV2/hooks/Main/useSettingMainMenu';

import { Invoices } from 'app/pages/settingsV2/subPages/Main/components/Invoices/Invoices';
import { Notifications } from 'app/pages/settingsV2/subPages/Main/components/Notifications/Notifications';
import { ProfileSettings } from 'app/pages/settingsV2/subPages/Main/components/ProfileSettings';
import { SettingMain } from 'app/pages/settingsV2/subPages/Main/SettingMain';
import {
    INVOICES_PATH_TOKEN,
    NOTIFICATIONS_PATH_TOKEN,
    PROFILE_PATH_TOKEN,
    SETTINGS_MAIN_PATHNAME,
} from 'app/routing/routeIds';

export const MainRoutes = () => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const { menus: settingsMainMenu } = useSettingMainMenu();
    const defaultSettingMainPathname = findFirstDisplayPathname(settingsMainMenu);

    const indexRoute = isBelowProvidedDevices ? SETTINGS_MAIN_PATHNAME : defaultSettingMainPathname;

    const routes = [
        {
            element: <SettingMain />,
            children: [
                {
                    index: true,
                    element: <Navigate replace to={indexRoute} />,
                },
                {
                    path: PROFILE_PATH_TOKEN,
                    element: <ProfileSettings />,
                },
                {
                    path: NOTIFICATIONS_PATH_TOKEN,
                    element: <Notifications />,
                },
                {
                    path: INVOICES_PATH_TOKEN,
                    element: <Invoices />,
                },
            ],
        },
    ];

    return useRoutes(routes);
};
