import { DatePickerType, RangeDate } from '@partoohub/ui';
import { format, parse } from 'date-fns';

import { useTranslation } from 'react-i18next';

import { LocaleDatePicker } from 'app/common/components/LocaleDatePicker/LocaleDatePicker';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { ReviewsQueryKeys } from 'app/reviewManagement/reviewList/hooks/useGetReviewsFilters';

const DATE_FORMAT = 'yyyy-MM-dd';

interface Props {
    isMobile?: boolean;
}

export const DateFilter = ({ isMobile }: Props) => {
    const { t } = useTranslation();

    const [from, setFrom] = useStateQueryParams(ReviewsQueryKeys.DATE_FROM);
    const [to, setTo] = useStateQueryParams(ReviewsQueryKeys.DATE_TO);

    const rangeDate: RangeDate = [
        from ? parse(from, DATE_FORMAT, new Date()) : null,
        to ? parse(to, DATE_FORMAT, new Date()) : null,
    ];

    const onChange = (newRange: RangeDate) => {
        const [startDate, endDate] = newRange;

        setFrom(startDate ? format(startDate, DATE_FORMAT) : '');
        setTo(endDate ? format(endDate, DATE_FORMAT) : '');
    };

    return (
        <LocaleDatePicker
            type={DatePickerType.DateRangePicker}
            dataTrackId="post_filter_date_range"
            dates={rangeDate}
            onChange={onChange}
            labels={{ startDate: t('start_date'), endDate: t('end_date') }}
            placeholders={{
                startDate: t('date_placeholder'),
                endDate: t('date_placeholder'),
            }}
            full={!!isMobile}
        />
    );
};
