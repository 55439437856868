import { useMemo } from 'react';

import { useQuery } from 'react-query';

import ReviewAutoReply from 'app/api/v2/api_calls/reviewAutoReplyApiCalls';
import { REVIEW_AUTO_REPLY_SELECTED_BUSINESSES } from 'app/common/data/queryKeysConstants';

export const useGetInitialBusinesses = (settingsId?: string) => {
    const query = useQuery([REVIEW_AUTO_REPLY_SELECTED_BUSINESSES, settingsId], () => {
        if (!settingsId) return undefined;
        return ReviewAutoReply.getBusinessesLanguages(settingsId);
    });

    return useMemo(() => {
        if (!query?.data) return { businessIds: undefined, businessLanguages: undefined };
        return { businessIds: Object.keys(query.data), businessLanguages: query.data };
    }, [query.data]);
};
