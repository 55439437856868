import { Text } from '@partoohub/ui';

import { Card, CardList, CenteredText, ChipContainer, IconContainer } from './RadioCardList.styled';

type CardId = string | number;

interface CardProps<T> {
    id: T;
    title: string;
    description: string;
    icon: string;
    label?: string;
}

interface Props<T> {
    cards: Array<CardProps<T>>;
    value?: T;
    onChange: (id: T) => void;
}

export const RadioCardList = <T extends CardId>({ cards, value, onChange }: Props<T>) => {
    return (
        <CardList>
            {cards.map(card => (
                <Card key={card.id} onClick={() => onChange(card.id)} selected={card.id === value}>
                    <IconContainer as="div">{card.icon}</IconContainer>
                    <CenteredText variant="bodyLBold">{card.title}</CenteredText>
                    <CenteredText color="secondary">{card.description}</CenteredText>
                    {card.label && (
                        <ChipContainer>
                            <Text color="primary" variant="bodySBold">
                                {card.label}
                            </Text>
                        </ChipContainer>
                    )}
                </Card>
            ))}
        </CardList>
    );
};
