import React from 'react';

import {
    DropZoneLoadingContainer,
    DropZoneLoadingSpinner,
} from './DropZoneLoading.styled';

const PhotosDropZoneLoading = () => (
    <DropZoneLoadingContainer>
        <DropZoneLoadingSpinner />
    </DropZoneLoadingContainer>
);

export default PhotosDropZoneLoading;
