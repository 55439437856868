import { ReactNode, useEffect } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'app/config';

type Props = {
    imageIndex: number | null;
    possibleImagesUrls: Array<string>;
    onHideClick: () => void;
    onNewImage: (right: boolean) => void;
    show: boolean;
    // Optionnal prop
    rightChild?: ReactNode;
    brokenImages?: Array<boolean>;
};

const FullScreenImage = ({
    imageIndex,
    possibleImagesUrls,
    brokenImages = [],
    onNewImage,
    rightChild,
    show,
    onHideClick,
}: Props) => {
    const { t } = useTranslation();

    useEffect(() => {
        const handleKeys = (event: KeyboardEvent) => {
            if (show) {
                switch (event.key) {
                    case 'ArrowLeft':
                        if (possibleImagesUrls.length > 1) {
                            onNewImage(false);
                        }

                        break;

                    case 'ArrowRight':
                        if (possibleImagesUrls.length > 1) {
                            onNewImage(true);
                        }

                        break;

                    default:
                        break;
                }
            }
        };

        document.addEventListener('keydown', handleKeys, false);
        return () => {
            document.removeEventListener('keydown', handleKeys, false);
        };
    }, [show, possibleImagesUrls, onNewImage]);

    const handleHideClick = () => {
        onHideClick();
    };

    return (
        <div className="full-screen-image-container">
            <div className="full-screen-image">
                <img
                    alt={imageIndex !== null ? possibleImagesUrls[imageIndex] : ''}
                    src={
                        imageIndex !== null && !brokenImages[imageIndex]
                            ? possibleImagesUrls[imageIndex]
                            : `${STATIC_BASE_URL}/images/app/icons/broken_file_light.svg`
                    }
                />
                {possibleImagesUrls.length > 1 && (
                    <div className="full-screen-image-overlay">
                        <div className="full-screen-image-overlay-left">
                            <IconButton
                                dataTrackId="fullscreen-image__arrow-left"
                                onClick={() => onNewImage(false)}
                                icon={[FontAwesomeIconsPartooUsed.faArrowLeft, IconPrefix.SOLID]}
                            />
                        </div>
                        <div className="full-screen-image-overlay-right">
                            <IconButton
                                dataTrackId="fullscreen-image__arrow-right"
                                onClick={() => onNewImage(true)}
                                icon={[FontAwesomeIconsPartooUsed.faArrowRight, IconPrefix.SOLID]}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="image-info-container">
                <div className="image-info-header">
                    <Text variant="heading2" as="span">
                        {t('infos')}
                    </Text>
                    <IconButton
                        dataTrackId=""
                        onClick={handleHideClick}
                        icon={<img src={`${STATIC_BASE_URL}/images/app/icons/close.svg`} alt="" />}
                    />
                </div>
                {rightChild}
            </div>
        </div>
    );
};

export default FullScreenImage;
