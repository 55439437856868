import { Stack } from '@partoohub/ui';

import AccountsList from 'app/businessConnection/components/AccountsList/AccountsList';
import AccountConnectionButton from 'app/businessConnection/components/businessConnectionHeader/headerActions/AccountConnectionButton';
import QuickConnect from 'app/businessConnection/components/genericQuickConnect/QuickConnect';

type Props = {
    displayQuickConnectButton: boolean;
};

const HeaderActions = ({ displayQuickConnectButton }: Props) => (
    <Stack direction="row" alignItems="center">
        <AccountConnectionButton />
        <AccountsList />
        {displayQuickConnectButton && <QuickConnect />}
    </Stack>
);

export default HeaderActions;
