import React, { ReactNode, createContext, useContext, useState } from 'react';

interface VisibilityContextType {
    isHeaderVisible: boolean;
    setHeaderVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface VisibilityProviderProps {
    children: ReactNode;
}

const VisibilityContext = createContext<VisibilityContextType | undefined>({
    isHeaderVisible: true,
    setHeaderVisible: () => {},
});

export const VisibilityContextProvider: React.FC<VisibilityProviderProps> = ({ children }) => {
    const [isHeaderVisible, setHeaderVisible] = useState(true);

    return (
        <VisibilityContext.Provider value={{ isHeaderVisible, setHeaderVisible }}>
            {children}
        </VisibilityContext.Provider>
    );
};

export const useVisibilityContext = (): VisibilityContextType => {
    const context = useContext(VisibilityContext);

    if (!context) {
        throw new Error('useVisibilityContext must be used within a VisibilityContextProvider');
    }

    return context;
};
