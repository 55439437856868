import { Button, TextArea, TextInput } from '@partoohub/ui';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ChatbotKnowledgeQAContentItem } from 'app/pages/settings/Chatbot/type/chatbot';

import {
    ChatbotKnowledgeFormButtonContainer,
    ChatbotKnowledgeFormContainer,
} from './ChatbotKnowledgeForm.styled';

interface Props {
    knowledgeItem?: ChatbotKnowledgeQAContentItem;
    onCancel: () => void;
    onSubmit: (data: ChatbotKnowledgeQAContentItem) => void;
}

type FormProps = {
    question: string;
    answer: string;
};

export const ChatbotKnowledgeForm = ({ knowledgeItem, onCancel, onSubmit }: Props) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, formState } = useForm<FormProps>({
        defaultValues: {
            question: knowledgeItem?.question ?? '',
            answer: knowledgeItem?.answer ?? '',
        },
    });

    const handleOnCancel = () => {
        reset();
        onCancel();
    };

    return (
        <ChatbotKnowledgeFormContainer>
            <Controller
                control={control}
                rules={{ required: true }}
                name="question"
                render={({ field: { ref: _ref, ...usedFields } }) => (
                    <TextInput
                        dataTrackId=""
                        {...usedFields}
                        label={t('chatbot_knowledge_form_question_input')}
                        error={['required'].includes(formState.errors.question?.type ?? '')}
                        notice={
                            ['required'].includes(formState.errors.question?.type ?? '')
                                ? t('chatbot_field_is_required_error')
                                : undefined
                        }
                    />
                )}
            />

            <Controller
                control={control}
                rules={{ required: true }}
                name="answer"
                render={({ field: { ref: _ref, ...usedFields } }) => (
                    <TextArea
                        dataTrackId=""
                        {...usedFields}
                        label={t('chatbot_knowledge_form_answer_input')}
                        error={['required'].includes(formState.errors.answer?.type ?? '')}
                        notice={
                            ['required'].includes(formState.errors.answer?.type ?? '')
                                ? t('chatbot_field_is_required_error')
                                : undefined
                        }
                        maxRows={50}
                        rows={3}
                    />
                )}
            />

            <ChatbotKnowledgeFormButtonContainer>
                <Button
                    onClick={handleOnCancel}
                    dataTrackId={'chatbot_knowledge_creation_cancel'}
                    appearance="outlined"
                    variant="secondary"
                    shape="cube"
                >
                    {t('cancel')}
                </Button>
                <Button
                    onClick={handleSubmit(data => onSubmit(data))}
                    dataTrackId={'chatbot_knowledge_create'}
                    appearance="contained"
                    shape="cube"
                    variant="primary"
                >
                    {t('save')}
                </Button>
            </ChatbotKnowledgeFormButtonContainer>
        </ChatbotKnowledgeFormContainer>
    );
};
