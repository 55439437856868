import { useMutation } from 'react-query';

import { Chatbots } from 'app/api/v2/api_calls/chatbotsApiCalls';
import { useChatbot } from 'app/pages/settings/Chatbot/hooks/useChatbot';
import { ChatbotMessage } from 'app/pages/settings/Chatbot/type/chatbot';

export const useChatbotPreviewRequest = (business_id?: string) => {
    const { data: chatbotData } = useChatbot();
    return useMutation((messages: ChatbotMessage[]) => {
        return Chatbots.postChatbotPreviewMessage(messages, business_id, chatbotData?.id);
    });
};
