import styled from '@emotion/styled';

type Props = {
    color: string;
};

export const PercentageValue = styled.div<Props>`
    position: absolute;
    top: 30px;
    width: 116px;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    color: ${props => props.color};
    font-weight: 700;
`;
