import { createContext } from 'react';

import { MessageReplyTemplate as MessageReplyTemplateType } from 'app/states/messageReplyTemplate/dataTypes';

type TemplateFormContextProps = {
    creatingMode: boolean | null;
    updateItem: MessageReplyTemplateType | null;
    setCreatingMode: (creatingMode: boolean) => void;
    setUpdateItem: (updateItem: MessageReplyTemplateType | null) => void;
    filteringTitle: string;
    setFilteringTitle: (filteringTitle: string) => void;
};

export const TemplateFormContext = createContext<TemplateFormContextProps>({
    creatingMode: null,
    updateItem: null,
    setCreatingMode: () => {},
    setUpdateItem: () => {},
    filteringTitle: '',
    setFilteringTitle: () => {},
});
