import styled from '@emotion/styled';

export const MetadataMessageContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 17px;
    gap: 5px;
    align-items: center;

    .conv-status-msg-icon {
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
`;
