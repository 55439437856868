import { PermissionsFieldType } from './typing';

export const checkboxSection = (
    fields: Array<PermissionsFieldType>,
    gm: boolean,
): boolean | 'indeterminate' => {
    const numberEnabledForSubRoles = fields.filter(field =>
        gm ? field.enabled_for_gm : field.enabled_for_bm,
    ).length;
    const fieldsLength = fields.length;

    if (numberEnabledForSubRoles === fieldsLength) {
        return true;
    } else if (numberEnabledForSubRoles === 0) {
        return false;
    } else {
        return 'indeterminate';
    }
};
