import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

interface Props {
    businessCount: number;
}

const RatingsFooter = ({ businessCount }: Props) => {
    const { t } = useTranslation();

    return (
        <Text variant="bodySRegular" as="span" color="secondary" italic>
            {t('over_selected')}
            &nbsp;
            <strong className="color--blueberry">
                {t('businesses_with_count_plural', {
                    count: businessCount,
                })}
            </strong>
            &nbsp;
            {t('selected')}
        </Text>
    );
};

export default RatingsFooter;
