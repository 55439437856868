import { useMemo } from 'react';

import useKeywordsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useKeywordsAnalytics';
import { useKeywordsSearchQueryParams } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/useKeywordsSearchQueryParams';
import { normalizrQueries } from 'app/pages/analytics/VisibilityAnalyticsV2/services/charts/normalizrQueries';
import { DimensionsDict } from 'app/pages/analytics/VisibilityAnalyticsV2/types/dimensions';
import { MetricsAttributesResponse } from 'app/pages/analytics/VisibilityAnalyticsV2/types/metrics';
import { MetricsApiKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/types/query';

export const useKeywordsChart = () => {
    const queryTmp = useKeywordsSearchQueryParams();
    const query = {
        ...queryTmp,
        [MetricsApiKeys.METRICS]: `${MetricsAttributesResponse.COUNT}`,
        [MetricsApiKeys.DIMENSIONS]: DimensionsDict.MONTH,
        [MetricsApiKeys.ORDER_BY]: DimensionsDict.MONTH,
    };

    const { data, isLoading, error, refetch } = useKeywordsAnalytics(query);

    const dataChart = useMemo(() => {
        if (!data || data?.keywords?.length <= 0) return null;
        return {
            ...normalizrQueries(data?.keywords ?? []),
        };
    }, [data]);

    return {
        isLoading,
        data: dataChart,
        error,
        refetch,
    };
};
