import { Button, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    GroupsSettingsQueryKeys,
    SECTION,
} from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

const NewSectionButton = () => {
    const { t } = useTranslation();
    const [, setCreateSection] = useStateQueryParams(GroupsSettingsQueryKeys.CREATE, true);

    return (
        <Button
            variant="primary"
            shape="cube"
            icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
            onClick={() => setCreateSection(SECTION)}
            dataTrackId="new_section_button"
        >
            {t('new_section_button')}
        </Button>
    );
};

export default NewSectionButton;
