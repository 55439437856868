import { useContext } from 'react';

import { useCanReplySuggestionClientReviews } from 'app/pages/customerExperience/useCanReplySuggestionClientReviews';

import { ReviewCardContext } from '../../sections/ReviewTableSection/ReviewCard/ReviewCard';

export const useShowReplySuggestionButton = (parentId?: string) => {
    const { review } = useContext(ReviewCardContext);
    const comments = review.comments;

    const { canReplySuggestionClientReviews } = useCanReplySuggestionClientReviews();
    const userHasPermission = canReplySuggestionClientReviews[review.reviewObjectType];

    // No suggestion if there is no comment
    if (!review.content) return false;

    // Only available for first level comment
    if (parentId) return false;

    // Only if the review has not been replied by suggestion feature
    if (comments.some(comment => comment.is_reply_suggestion)) return false;

    // Check user has permission:
    return userHasPermission;
};
