import { useTranslation } from 'react-i18next';

import { SettingSummaryItemValues } from 'app/pages/settingsV2/subComponents/SettingsSummaryItem/SettingsSummaryItem';
import { PLATFORMS_PATH_TOKEN } from 'app/routing/routeIds';

export const useSettingPlatformsSummary = (): SettingSummaryItemValues => {
    const { t } = useTranslation();
    return {
        settingData: {
            id: '4',
            icon: 'fa-link-simple',
            title: t('settings_summary_platforms_title'),
            description: t('settings_summary_platforms_description'),
            url: PLATFORMS_PATH_TOKEN,
            display: true, // This section does not have any access restrictions. And at least one option inside `useSettingMainMenu` is set to true or undefined.
        },
        isLoading: false,
    };
};
