import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface OptionCardWrapperProps {
    selected: boolean;
    clickable: boolean;
}

interface OptionIconProps {
    color: string;
}

export const OptionListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const OptionCardWrapper = styled.div<OptionCardWrapperProps>`
    display: flex;
    gap: 16px;

    border: 2px solid #ffffff;
    border-radius: 8px;
    padding: 16px;

    width: 100%;
    height: 78px;

    box-shadow: 0px 2px 16px rgba(20, 37, 66, 0.08);

    transition:
        box-shadow 0.2s,
        border 0.2s;

    ${props =>
        props.clickable &&
        css`
            cursor: pointer;

            &:hover {
                box-shadow: 0px 5px 10px rgba(20, 37, 66, 0.16);
            }
        `}

    ${({ selected, clickable, theme }) =>
        selected &&
        clickable &&
        css`
            border: 2px solid ${theme.colors.primary.initial};
        `}
`;

export const OptionIcon = styled.div<OptionIconProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    min-width: 38px;
    height: 38px;

    background-color: ${props => props.color + '1F'};

    color: ${props => props.color};
    font-size: 19px;
`;

export const OptionContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
