import { useState } from 'react';

import { Checkbox, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import ModalValidationRibbon from 'app/common/components/ModalValidationRibbon';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { STATIC_BASE_URL } from 'app/config';
import {
    CustomFieldsWarningModalCheckbox,
    CustomFieldsWarningModalContent,
    CustomFieldsWarningModalContentTop,
    CustomFieldsWarningModalDescription,
    CustomFieldsWarningModalIconTop,
    CustomFieldsWarningModalIconTopDescription,
    CustomFieldsWarningModalIconTopImage,
    CustomFieldsWarningModalTitle,
    ModalUndo,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/sections/CustomFieldsModals/CustomFieldsModals.styled';
import {
    getEditWarningModalCheckboxTranslation,
    getEditWarningModalDescriptionTranslation,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/services';

type Props = {
    name: string;
    type: string;
    editCustomField: () => void;
    closeModal: () => void;
    loading: boolean;
};

const WarningEditModal = ({ name, type, editCustomField, closeModal, loading }: Props) => {
    const { t } = useTranslation();

    const [confirmCheckbox, setConfirmCheckbox] = useState<boolean>(false);

    const clickCheckbox = () => {
        setConfirmCheckbox(!confirmCheckbox);
    };

    return (
        <CustomFieldsWarningModalContent>
            <CustomFieldsWarningModalIconTop>
                <CustomFieldsWarningModalIconTopImage
                    src={`${STATIC_BASE_URL}/images/app/custom_fields/icon_guillaume.png`}
                    alt=""
                />
                <CustomFieldsWarningModalIconTopDescription variant="bodyXSBold" color="secondary">
                    {t('custom_fields_customer_success')}
                </CustomFieldsWarningModalIconTopDescription>
            </CustomFieldsWarningModalIconTop>

            <CustomFieldsWarningModalContentTop>
                <CustomFieldsWarningModalTitle variant="heading4">
                    {t('modifications_to_confirm')}
                </CustomFieldsWarningModalTitle>
                <CustomFieldsWarningModalDescription color="secondary">
                    {t(getEditWarningModalDescriptionTranslation(type), {
                        name,
                    })}
                </CustomFieldsWarningModalDescription>
                <CustomFieldsWarningModalCheckbox onClick={clickCheckbox}>
                    <Checkbox
                        dataTrackId="warning_edit_modal__checkbox"
                        onChange={() => undefined}
                        checked={confirmCheckbox}
                    >
                        <Text variant="bodyMBold" color="secondary">
                            {t(getEditWarningModalCheckboxTranslation(type), {
                                name,
                            })}
                        </Text>
                    </Checkbox>
                </CustomFieldsWarningModalCheckbox>
            </CustomFieldsWarningModalContentTop>

            <ModalUndo>
                <StrikeThroughButton color="secondary" text={t('undo')} onClick={closeModal} />
            </ModalUndo>

            <ModalValidationRibbon
                state={loading || !confirmCheckbox ? 'loading' : 'danger'}
                text={t('confirm')}
                noIcon
                handleClick={editCustomField}
            />
        </CustomFieldsWarningModalContent>
    );
};

export default WarningEditModal;
