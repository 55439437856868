import { setDefaultOptions } from 'date-fns';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import { V2UserData } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import formatOrg from 'app/businessConnection/services/formatOrg';
import {
    API_KEY_SEARCH_PARAM,
    CONNECTION_TOKEN_SEARCH_PARAM,
    extractSearchParam,
} from 'app/common/data/routeIds';
import {
    GET_ME_SUCCESS,
    dataSelector,
    getMe,
    getMeFailure,
    getMeSuccess,
} from 'app/common/reducers/me';
import formatUser from 'app/common/services/formatUser';
import handleRTL from 'app/common/services/handleRTL';
import IntercomWrapper from 'app/common/services/intercomWrapper';
import i18n from 'app/common/translations/i18n';
import { Saga } from 'app/common/types';
import { meSelector } from 'app/states/reducers';

import { getOrgSuccess } from '../reducers/orgs';
import { getDateFnsLocaleFromLocale } from '../utils/dateFns';

export const getMeSaga = function* (calledFromLoginSaga = false): Saga {
    const apiKey = extractSearchParam(API_KEY_SEARCH_PARAM);
    const connectionToken = extractSearchParam(CONNECTION_TOKEN_SEARCH_PARAM);
    const ssoAlias = extractSearchParam('sso_alias');

    // Prevent the initial getMe for Connect As, except from calling from login
    // Prevents from loading data from the user that was previously connected in the browser
    if ((!apiKey && !connectionToken) || !ssoAlias || calledFromLoginSaga) {
        try {
            yield put(getMe());
            const data: V2UserData = yield call(api.user.getMe);
            const formattedUser = formatUser(data);

            // Load the org data on login
            const dataOrg = yield call(api.org.getOrg, data.org_id);
            const formattedLocation = formatOrg(dataOrg);
            yield put(getOrgSuccess(formattedLocation));

            yield put(getMeSuccess(formattedUser));

            /**
             * We use Intercom identity verification if the backend sends us the intercom user Hash
             * Uses the shadow user intercom hash instead of the user if it exists
             */
            if (data.shadow_user_intercom_hash) {
                IntercomWrapper.updateUserHash(
                    data.shadow_user_intercom_hash,
                    data.shadow_user_id,
                    window.location.pathname,
                );
            } else if (formattedUser.intercomUserHash) {
                IntercomWrapper.updateUserHash(
                    formattedUser.intercomUserHash,
                    formattedUser.id,
                    window.location.pathname,
                );
            }

            if (formattedUser.lang !== i18n.language) {
                i18n.changeLanguage(formattedUser.lang);
            }

            handleRTL(formattedUser.lang);

            // What is this ?? We should just use useMe hook to retrieve the language
            // Setting the user language in the DOM
            const html = document.getElementsByTagName('html');

            if (html && html[0]) {
                html[0].setAttribute('lang', formattedUser.lang);
                const locale = getDateFnsLocaleFromLocale(formattedUser.lang);
                setDefaultOptions(locale);
            }
        } catch (error) {
            yield put(getMeFailure(error));
        }
    }
};

export const getMeDataOnceAvailable = function* (): Saga {
    let user = dataSelector(yield select(meSelector));

    while (!user) {
        ({ user } = yield take(GET_ME_SUCCESS));
    }

    return user;
};

// Only to update me/org after an update of user
// Should be removed when all other pages are not using sagas anymore !
export const FORCE_GET_ME_SAGA = 'FORCE_GET_ME_SAGA';
export function* forceGetMeSaga(): Saga {
    yield takeEvery(FORCE_GET_ME_SAGA, getMeSaga);
}

export default getMeSaga;
