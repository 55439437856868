import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { HEX_COLORS } from '@partoohub/branding';
import { Button, IconButton } from '@partoohub/ui';
import InfiniteScroll from 'react-infinite-scroller';

import { Container } from 'app/common/components/funnel/FunnelModal.styled';

export const SelectionContainer = styled(Container)`
    padding-top: 0;
    overflow-y: auto;
    justify-content: center;
    align-items: normal;
`;

export const SelectionContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 100%;
`;

export const Scroller = styled(InfiniteScroll)`
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
`;

export const ParametersWrapper = styled.div`
    align-items: center;
    width: 100%;
    display: flex;
    flex-flow: row;
    column-gap: 8px;
    margin-bottom: 11px;
`;

export const FiltersContainer = styled.div`
    align-items: center;
    width: 100%;
    display: flex;
    flex-flow: row;
    flex: 1;
`;

export const InputFieldContainer = styled.div`
    flex: 1;
    min-width: 0;
`;

type SelectFieldContainerProps = {
    hide?: boolean;
};

export const SelectFieldContainer = styled.div<SelectFieldContainerProps>`
    height: 55px;
    flex: 1;
    margin-left: 8px;
    min-width: 0;

    & div.multiple_select__placeholder {
        width: calc(100% - 45px);
        top: 17px;

        & > div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &--top {
            top: -1px;
        }
    }

    & div.clickable {
        height: 55px;
    }

    ${props =>
        props.hide &&
        css`
            width: 0;
            margin: 0;

            & > div {
                display: none;
            }
        `}
`;

export const CheckField = styled.div`
    height: 46px;
    display: flex;
    align-items: center;
    width: 212px;
    flex-shrink: 0;
    position: relative;
`;

export const IconButtonPageContainer = styled(IconButton)`
    position: absolute;
    top: -20px;
    right: -15px;
`;

export const SelectEraseButton = styled(Button)`
    width: 100%;
    & span {
        white-space: normal;
    }
`;

export const FullButtonWrapper = styled.div`
    width: 100%;
    height: 95px;
    position: absolute;
    background-color: ${HEX_COLORS.white};
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    z-index: 1000;
    gap: 16px;
`;

export const FullButton = styled(Button)`
    font-weight: bold;
    position: relative;
    width: 70%;
    overflow: hidden;

    & > div > i {
        position: absolute;
        right: 24px;
        width: 24px;
    }

    & > div > i,
    & > div > span {
        font-size: 18px;
    }
`;
