import { useInfiniteQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { USERS } from 'app/common/data/queryKeysConstants';

type Status = 'invited' | 'active' | null;

export default function useSearchUsers(query: string, status: Status) {
    return useInfiniteQuery(
        [USERS, { query, status }],
        ({ pageParam = 1 }) =>
            api.user.searchUsers({
                query,
                status,
                page: pageParam,
            }),
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page
                    ? lastPage.page + 1
                    : undefined,
        },
    );
}
