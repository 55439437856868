export const publishersToPlatformMapping = {
    google_my_business__posts: 'google',
    facebook__posts: 'facebook',
    instagram__posts: 'instagram',
};

export default publishers =>
    publishers.map(({ name }) => {
        if (Object.hasOwnProperty.call(publishersToPlatformMapping, name)) {
            return publishersToPlatformMapping[name];
        } else {
            throw new Error(`Invalid publisher ${name}`); // something went very wrong, check api publisher response
        }
    });
