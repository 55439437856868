import React from 'react';

type Props = {
    width?: number;
    height?: number;
};

const LineLow = React.forwardRef<SVGSVGElement>(
    ({ width = 101, height = 83 }: Props, ref) => (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.6"
                d="M84.667 24.3323C95.2 34.7744 90.5079 53.9214 103.08 61.7916C124.287 75.067 165.892 53.1358 153.902 11.1191C148.416 -8.10842 171.489 -9.2717 164.712 -28.0723C157.354 -48.4839 126.427 -46.3904 107.911 -57.6993C86.7571 -70.62 76.9271 -96.8924 54.9437 -85.4039C40.4576 -77.8335 30.9437 -68.602 29.4315 -61.5584C27.1491 -50.9268 31.9548 -39.3562 36.3462 -24.8082C40.8803 -9.7877 34.0575 1.86261 47.4093 10.1C61.5398 18.8178 72.8766 12.6436 84.667 24.3323Z"
                stroke="#FFA1B7"
                strokeWidth="0.3"
            />
            <g filter="url(#filter0_d_22294_158596)">
                <path
                    d="M67.9415 17.8191C68.9781 15.8683 68.237 13.4465 66.2861 12.4099C64.3353 11.3733 61.9135 12.1145 60.8769 14.0653C59.8403 16.0162 60.5815 18.438 62.5323 19.4746C64.4832 20.5111 66.9049 19.77 67.9415 17.8191Z"
                    fill="#FFA1B7"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_22294_158596"
                    x="50.4084"
                    y="1.94141"
                    width="28.0016"
                    height="28.0016"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.631373 0 0 0 0 0.717647 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22294_158596"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22294_158596"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    ),
);

export default LineLow;
