import { DatePickerType, RangeDate } from '@partoohub/ui';

import { format, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { SpecificHourFormPeriod } from 'app/api/types/business';
import { getAdjustedDate } from 'app/businessEditV2/utils/dateFormatting';
import { LocaleDatePicker } from 'app/common/components/LocaleDatePicker/LocaleDatePicker';
import { OpenHourStartEndContainer } from 'app/common/components/OpenHour/OpenHour.styled';

import { DATE_FORMAT } from 'app/utils/dates';

import { OverrideDatePickerWithError } from './DatePickerStartEnd.styled';

type Props = {
    disabled: boolean;
    specificHour: SpecificHourFormPeriod;
    onChange: (specificHour: SpecificHourFormPeriod) => void;
    hasDateError: boolean;
};

const DatePickerStartEnd = ({ disabled, specificHour, onChange, hasDateError }: Props) => {
    const startDate = getAdjustedDate(specificHour.starts_at);
    const endDate = getAdjustedDate(specificHour.ends_at);

    const { t } = useTranslation();

    const updateStartDate = (selectedDate: RangeDate) => {
        const result: SpecificHourFormPeriod = { ...specificHour };
        result.starts_at =
            selectedDate?.[0] && isValid(selectedDate[0])
                ? format(selectedDate[0], DATE_FORMAT)
                : '';
        onChange(result);
    };

    const updateEndDate = (selectedDate: RangeDate) => {
        const result: SpecificHourFormPeriod = { ...specificHour };
        result.ends_at =
            selectedDate?.[0] && isValid(selectedDate[0])
                ? format(selectedDate[0], DATE_FORMAT)
                : '';
        onChange(result);
    };

    return (
        <OpenHourStartEndContainer>
            <OverrideDatePickerWithError error={hasDateError}>
                <LocaleDatePicker
                    dataTrackId="month-year-range-picker-start"
                    type={DatePickerType.DateSelector}
                    labels={{ startDate: t('date_begin') }}
                    placeholders={{ startDate: t('date_placeholder') }}
                    minDate={new Date()}
                    dates={[startDate, null]}
                    disabled={disabled}
                    onChange={updateStartDate}
                />
            </OverrideDatePickerWithError>
            <OverrideDatePickerWithError error={hasDateError}>
                <LocaleDatePicker
                    dataTrackId="month-year-range-picker-end"
                    type={DatePickerType.DateSelector}
                    labels={{ startDate: t('date_end') }}
                    placeholders={{ startDate: t('date_placeholder') }}
                    minDate={startDate ?? undefined}
                    dates={[endDate, null]}
                    disabled={disabled}
                    onChange={updateEndDate}
                />
            </OverrideDatePickerWithError>
        </OpenHourStartEndContainer>
    );
};

export default DatePickerStartEnd;
