import { AppDataProvider } from 'app/common/contexts/appData';

import { AppPage } from './page';

export const AppContexts = () => {
    return (
        <AppDataProvider>
            <AppPage />
        </AppDataProvider>
    );
};
