import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationVariousElement,
    IllustrationVariousEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DefaultTodoComponent } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent';
import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import useCompletionStats from 'app/pages/visibility/BusinessListV2/hooks/businesses/useCompletionStats';
import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';

export const TodoCompletionRate = (props: SmallTodoProps) => {
    const { t } = useTranslation();
    const stats = useCompletionStats();
    const isLoading = stats === undefined;
    const navigate = useNavigate();

    const illustrationElement: IllustrationVariousElement = {
        type: IllustrationCategoryEnum.Various,
        name: IllustrationVariousEnum.GoogleListingNotVisible,
    };

    return (
        <DefaultTodoComponent
            {...props}
            title={t('homepage_todo_completion_score_title', {
                completionScore: stats?.average_completion_rate,
            })}
            actionText={t('update')}
            icon={
                <Illustration
                    dataTrackId="homepage_example__illustration"
                    illustration={illustrationElement}
                    height="98"
                    width="120"
                />
            }
            isLoading={isLoading}
            onClick={() => navigate(VISIBILITY_LOCATION_PATH)}
        />
    );
};
