import { useTheme } from '@emotion/react';
import { Stack, Text } from '@partoohub/ui';

import { Control } from 'react-hook-form';

import ControlledToggle from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm/hookControllerWrapper/ControlledToggle';

import { getMonthlyWeeklyToggleStyle } from './MonthlyWeeklyReportToggle.styled';

interface Props {
    control: Control;
    text: string;
    name: string;
}

export const MonthlyWeeklyReportToggle = ({ control, text, name }: Props) => {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={getMonthlyWeeklyToggleStyle(theme)}
        >
            <Stack>
                <Text variant="bodyLBold" as="span">
                    {text}
                </Text>
            </Stack>
            <Stack>
                <ControlledToggle name={name} control={control} />
            </Stack>
        </Stack>
    );
};
