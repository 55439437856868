import { useMemo } from 'react';

import { TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { Choice } from 'app/api/types/user';
import {
    PublisherErrors,
    PublishersInfo,
} from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithPublisherList';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import AsyncSingleSelect from 'app/common/designSystem/components/molecules/AsyncSingleSelect';

import { ExplanationExtended } from './ExplanationComponent/ExplanationComponent';

export type Props = {
    renderSelect: boolean;
    onChange: (arg0: Choice | null) => void;
    onTextFieldChange: (value?: string) => void;
    options: Array<Choice>;
    onSearchChange: (search?: string) => void;
    // Optional props
    hasMore?: boolean;
    selectedValue?: Choice | null;
    isMandatory?: boolean;
    disabled?: boolean;
    textTooltip?: string;
    // withExplanationProps
    errorMessage?: string;
    hasError: boolean;
    hasWarning: boolean;
    publisherErrors?: PublisherErrors;
    publishersInfo?: PublishersInfo;
    dataTrack?: string;
};

export const CityField = ({
    options,
    selectedValue,
    renderSelect,
    onChange,
    onTextFieldChange,
    errorMessage,
    onSearchChange,
    hasMore = false,
    isMandatory = false,
    disabled = false,
    textTooltip = undefined,
    hasError,
    hasWarning,
    publisherErrors,
    publishersInfo,
    dataTrack,
}: Props) => {
    const { t } = useTranslation();

    const ExplanationComponent = useMemo(
        () => (
            <ExplanationExtended
                hasWarning={hasWarning}
                publisherErrors={publisherErrors}
                publishersInfo={publishersInfo}
                errorMessage={errorMessage}
                disabled={disabled}
            />
        ),
        [hasWarning, publisherErrors, publishersInfo, errorMessage, disabled],
    );

    if (renderSelect && !disabled) {
        return (
            <AsyncSingleSelect
                options={options}
                selectedValue={selectedValue}
                placeholder={t('city')}
                onChange={onChange}
                loadMore={onSearchChange}
                hasMore={hasMore}
                isMandatory={isMandatory}
                textTooltip={t(textTooltip || '')}
                mustFilterOptions={false}
                hideCross // withExplanation props
                errorMessage={errorMessage}
                hasError={hasError}
                hasWarning={hasWarning}
                publisherErrors={publisherErrors}
                publishersInfo={publishersInfo}
                dataTrack={dataTrack}
            />
        );
    } else {
        const InputComponent = (
            <TextInput
                dataTrackId={dataTrack ?? 'city_field__text_input'}
                value={selectedValue ? selectedValue.value : undefined}
                placeholder={t('city')}
                onChange={onTextFieldChange}
                disabled={disabled}
                required={isMandatory}
                hasOldTextFieldHeight
                notice={ExplanationComponent}
                error={hasError}
                warning={hasWarning}
            />
        );
        return (
            <>
                {textTooltip ? (
                    <TooltipWrapper text={t(textTooltip || '')} position="bottom-start">
                        <div style={{ flex: 1 }}>{InputComponent}</div>
                    </TooltipWrapper>
                ) : (
                    InputComponent
                )}
            </>
        );
    }
};
