import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

import Clickable from 'app/common/components/buttons/Clickable';

export const MobilePageLeftHeaderAction = styled(Text)`
    position: absolute;
    min-width: 10%;
    right: 16px;
`;

export const MobilePageHeaderContainer = styled.div`
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2 * 8px;
    background-color: white;
    border-bottom: solid 1px rgba(51, 51, 51, 0.11);
`;

export const MobilePageHeaderTitle = styled.div`
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-width: 0;
    padding: 0 calc(10% + 8px);
`;

export const StyledClickable = styled(Clickable)`
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-width: 0;
    padding: 0 calc(10% + 8px);
`;
