import React from 'react';

import { ReviewCardSeparatorArea, StyledSeparator } from './ReviewCardSeparator.styled';

type Props = {
    reviewHasReply: boolean;
};

export default function ReviewCardSeparator({ reviewHasReply }: Props) {
    return (
        <ReviewCardSeparatorArea>{reviewHasReply && <StyledSeparator />}</ReviewCardSeparatorArea>
    );
}
