import { connect } from 'react-redux';

import { BusinessInfosImportType } from 'app/api/types/business';
import { ImportSection as ImportSectionComponent } from 'app/businessConnection/components/genericQuickConnect/InfoSection/Extensions/GMB/ImportSection';
import { PROVIDER } from 'app/common/data/roles';
import { meOrgIdSelector, meRoleSelector } from 'app/common/reducers/me';
import { getGooglePostStatus } from 'app/common/reducers/orgs';
import {
    businessImportSelector,
    updateBusinessImport,
} from 'app/states/knowledge/reducers/businessConnection/connection';
import { AppState, connectionSelector, meSelector } from 'app/states/reducers';

const mapStateToProps = (state: AppState) => {
    const role = meRoleSelector(meSelector(state));
    const businessImport = businessImportSelector(connectionSelector(state));
    let googlePostStatus = {
        hasGooglePost: true,
        displayGooglePost: true,
    };

    if (role !== PROVIDER) {
        googlePostStatus = getGooglePostStatus(state.orgs, meOrgIdSelector(state.me));
    }

    return {
        role,
        googlePostStatus,
        businessImport,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onChange: (name: string, value: BusinessInfosImportType | boolean) => {
        dispatch(updateBusinessImport(name, value));
    },
});

const ImportSection = connect(mapStateToProps, mapDispatchToProps)(ImportSectionComponent);

export default ImportSection;
