import { connect } from 'react-redux';

import {
    hasGoogleError,
    loginRequest,
} from 'app/states/connection/reducers/login';
import { AppState } from 'app/states/reducers';

import GoogleSignIn from './GoogleSignIn';

const mapStateToProps = ({ login }: AppState) => ({
    displayGoogleLoginError: hasGoogleError(login),
});

const matchDispatchToProps = (dispatch: any) => ({
    onGoogleSubmit: (idToken: string) =>
        dispatch(loginRequest(undefined, undefined, idToken)),
});

const GoogleSignInComponent = connect(
    mapStateToProps,
    matchDispatchToProps,
)(GoogleSignIn);

export default GoogleSignInComponent;
