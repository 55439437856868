import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const ConfigurationModalContainer = styled(Stack)`
    height: 100%;
    min-height: 325px;
    .multiple_select__menu {
        max-height: 220px;
    }
`;

export const FormPanelContainer = styled(Stack)`
    width: 100%;
    height: 100%;
    padding: '0px';
    gap: 16px;
`;
