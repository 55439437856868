import { ReactNode } from 'react';

import { IconButton } from '@partoohub/ui';

import { MobilePageRightHeaderAction } from 'app/common/components/pageHeaders/MobilePageHeaderSideBarButton.styled';
import { hideableComponentsNames, withHideableComponent } from 'app/SDKBridge';

type Props = {
    content?: ReactNode;
    onClick?: () => void;
    icon?: string;
};

export const MobilePageHeaderSideBarButton = ({
    content = null,
    onClick = () => undefined,
    icon = '',
}: Props) => {
    const iconNode = <i className={icon} />;
    return (
        <MobilePageRightHeaderAction variant="heading2">
            {content || (
                <IconButton
                    dataTrackId="mobile_page_header_sidebar__icon_button"
                    appearance="outlined"
                    icon={iconNode}
                    onClick={onClick || (() => undefined)}
                />
            )}
        </MobilePageRightHeaderAction>
    );
};

export default withHideableComponent(
    MobilePageHeaderSideBarButton,
    hideableComponentsNames.APP_SIDEBAR,
);
