import { ReviewAnalyticsInternalRankingDimension } from 'app/api/types/review_analytics';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

export const useInternalRankingDimension = () => {
    const [internalRankingDimension] = useStateQueryParams<
        ReviewAnalyticsInternalRankingDimension | ''
    >('internalRankingDimension');

    return internalRankingDimension || null;
};
