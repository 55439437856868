import { useEffect, useState } from 'react';

import { SearchBusinessesStatsData } from 'app/api/types/business';
import useBusinessSearchStats from 'app/pages/visibility/BusinessListV2/hooks/businesses/useBusinessSearchStats';

export default function useCompletionStats(): SearchBusinessesStatsData | undefined {
    const { data } = useBusinessSearchStats();
    const [definedStats, setDefinedStats] = useState(data);

    useEffect(() => {
        if (data !== undefined) {
            setDefinedStats(data);
        }
    }, [data]);

    return definedStats;
}
