import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

type ContentProps = {
    activeHoverBackground: string;
    isActive: boolean;
};

export const ConversationCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    outline: none;
    padding: 4px 8px;
    margin-left: 1px;
`;

export const Content = styled.div<ContentProps>`
    min-width: 0;
    display: flex;
    flex-direction: column;
    padding: 8px;
    cursor: pointer;
    flex: 1;
    border-radius: 8px;

    &:hover {
        background-color: ${({ theme }) => theme.colors.secondary.alpha};
        transition: background-color ease-in-out 0.2s;
    }

    ${({ isActive, activeHoverBackground, theme }) =>
        isActive &&
        css`
            background-color: ${theme.colors.primary.alpha};
            &:hover {
                background-color: ${activeHoverBackground};
                transition: background-color ease-in-out 0.2s;
            }
        `};
`;

export const MainInfo = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 0;
    gap: 10px;
    align-items: center;
`;

export const ConsumerInfoAndMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    overflow-x: hidden;
    max-width: 180px;
`;

export const ConsumerName = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
`;

export const BusinessInfo = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const LastMessage = styled(Text)`
    line-height: 20px;
    max-width: 245px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    margin: 0;
    margin-right: 6px;
`;

type DotProps = {
    isRead: boolean;
};

export const IsReadDot = styled.div<DotProps>`
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    margin-right: 6px;
    margin-bottom: 2px;
    ${({ isRead, theme }) =>
        !isRead &&
        css`
            background-color: ${theme.colors.primary.initial};
        `};
`;

export const AutomatedIconWrapper = styled.div`
    border-radius: 100px;
    color: ${({ theme }) => theme.colors.slate.initial};
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
`;
