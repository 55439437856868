import { Checkbox, Icon, IconButton, IconPrefix, Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useFunnelModalContext } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/hooks/FunnelModalContext';

import {
    ClickableArea,
    FloatingBarContainer,
    FloatingBarContent,
    StyledButton,
} from './FloatingBar.styled';
import { useBusinessSelectionContext } from '../../hooks/businessSelection/useBusinessSelectionContext';

export const FloatingBar = () => {
    const { selectedNumber, resetBusinessSelection, checkboxState, selectOrUnselectAll } =
        useBusinessSelectionContext();
    const { setShowFunnel } = useFunnelModalContext();
    const { t } = useTranslation();

    return (
        <FloatingBarContainer show={selectedNumber > 0}>
            <FloatingBarContent direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row">
                    <ClickableArea direction="row" onClick={selectOrUnselectAll}>
                        <Checkbox
                            checked={checkboxState}
                            onChange={selectOrUnselectAll}
                            dataTrackId="business_list__floating_bar__select_or_unselect_all"
                            aria-label=""
                        />
                        <Text as="p" variant="bodyMBold" color="white">
                            {checkboxState === true
                                ? t('business_list__floating_bar__unselect_all')
                                : t('business_list__floating_bar__select_all')}
                        </Text>
                    </ClickableArea>
                    <Text as="p" variant="bodyMSemibold" color="secondary">
                        ·
                    </Text>
                    <Text as="p" variant="bodyMSemibold" color="secondary">
                        {t('business_list__floating_bar__number_selected', {
                            count: selectedNumber,
                        })}
                    </Text>
                </Stack>
                <Stack direction="row" alignItems="center" gap="8px">
                    <StyledButton
                        shape="rounded"
                        appearance="outlined"
                        variant="secondary"
                        onClick={() => setShowFunnel(true)}
                        dataTrackId="business_list__floating_bar__edit"
                    >
                        <Stack direction="row" gap="8px">
                            <Icon icon={['fa-pen-alt', IconPrefix.REGULAR]} color="white" />
                            <Text as="p" variant="bodyMBold" color="white">
                                {t('business_list__floating_bar__add_photos')}
                            </Text>
                        </Stack>
                    </StyledButton>
                    <IconButton
                        dataTrackId="business_list__floating_bar__close"
                        appearance="outlined"
                        onClick={resetBusinessSelection}
                        variant="white"
                        icon={['fa-xmark', IconPrefix.REGULAR]}
                    />
                </Stack>
            </FloatingBarContent>
        </FloatingBarContainer>
    );
};
