import { DeviceType, IS_BELOW_TABLET, useDeviceType } from 'app/hooks';
import { usePublishersStates } from 'app/presence/management/hooks/usePublishersStates';

export const usePublisherColumns = () => {
    const { isBelowProvidedDevices, device } = useDeviceType(IS_BELOW_TABLET);
    const { data } = usePublishersStates();

    if (!data) {
        return {
            columns: [],
        };
    }

    const businessCount = data?.business_count;

    const platformColumn = [
        {
            label: 'platform',
            width: [DeviceType.Mobile, DeviceType.MobileApp].includes(device)
                ? '90px'
                : device === DeviceType.Tablet
                  ? '200px'
                  : '250px',
        },
    ];
    const multipleBusinessColumnsStatus = [
        {
            label: 'success',
        },
        {
            label: 'pending',
        },
        {
            label: 'to_configure',
        },
    ];
    const oneBusinessColumnsStatus = [{ label: 'status' }];
    const delayColumn = [{ label: 'average_latency', width: '120px' }];

    const statusColums =
        businessCount === 1 ? oneBusinessColumnsStatus : multipleBusinessColumnsStatus;

    const averageLatencyColumn = isBelowProvidedDevices ? [] : delayColumn;

    const actionColumn = [
        {
            label: '',
            width: businessCount === 1 ? '80px' : '40px',
        },
    ];

    return {
        columns: [...platformColumn, ...averageLatencyColumn, ...statusColums, ...actionColumn],
    };
};
