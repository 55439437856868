import { CompetitorCentralRank, CompetitorLocalRank } from 'app/api/types/competitiveBenchmark';

export function isCompetitorCentral(
    competitor: CompetitorCentralRank | CompetitorLocalRank,
): competitor is CompetitorCentralRank {
    return 'apparitionPercentage' in competitor;
}

export function isCompetitorLocal(
    competitor: CompetitorCentralRank | CompetitorLocalRank,
): competitor is CompetitorLocalRank {
    return 'address' in competitor;
}
