import { HEX_COLORS } from '@partoohub/branding';
import { Modal, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ModalBody, ModalContentContainer, StyledButton } from './ActivationSuccessModal.styled';

type Props = {
    show: boolean;
    onHide: () => void;
    title: string;
    text: string;
};

const ActivationSuccessModal = ({ show, onHide, title, text }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={show} closeModal={onHide}>
            <ModalContentContainer alignItems="center" justifyContent="center">
                <ModalBody>
                    <Text variant="heading4" color={HEX_COLORS.white}>
                        {title}
                    </Text>
                    <Text color={HEX_COLORS.white}>{text}</Text>
                </ModalBody>
                <StyledButton
                    dataTrackId="auto_reply_activation_modal_button"
                    onClick={() => onHide()}
                    variant="theme"
                >
                    <Text variant="bodyMBold">{t('auto_reply_success_modal_confirm')}</Text>
                </StyledButton>
            </ModalContentContainer>
        </Modal>
    );
};

export default ActivationSuccessModal;
