import { useSelector } from 'react-redux';

import { NoReviews } from 'app/common/components/placeholder/reviewMangement/NoReviews';

import { NoReviewsFound } from 'app/common/components/placeholder/reviewMangement/NoReviewsFound';

import {
    activeToolbarReviewFiltersSelector,
    reviewFiltersSelector,
} from 'app/states/reviews/filters';

import { ReviewTable } from './ReviewTable/ReviewTable';
import { ReviewTableSectionContainer } from './ReviewTableSection.styled';
import { useGetReviews } from '../../hooks/useGetReviews';
import { LoadRealtimeUpdatesButton } from '../../realtimeUpdates/LoadRealtimeUpdatesButton';

export const ReviewTableSection = () => {
    const { reviews, isLoading } = useGetReviews();

    const hasToolbarFilters = Boolean(
        Object.keys(activeToolbarReviewFiltersSelector(useSelector(reviewFiltersSelector))).length,
    );

    return (
        <ReviewTableSectionContainer>
            <LoadRealtimeUpdatesButton />
            {!isLoading && !reviews.length && !hasToolbarFilters && <NoReviews />}
            {!isLoading && !reviews.length && hasToolbarFilters && <NoReviewsFound />}
            <ReviewTable />
        </ReviewTableSectionContainer>
    );
};
