import { useContext } from 'react';

import { InfoSection } from 'app/businessConnection/components/genericQuickConnect/InfoSection/InfoSection';
import { OpenedModalEnum } from 'app/businessConnection/components/genericQuickConnect/QuickConnect';
import QuickConnectContext from 'app/businessConnection/components/genericQuickConnect/QuickConnect.context';
import SuggestionSection from 'app/businessConnection/components/genericQuickConnect/SuggestionSection/SuggestionSectionForm.container';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

const QuickConnectModal = () => {
    const { setShow, setPublisher } = useContext(QuickConnectContext);
    const [showModal, setShowModal] = useStateQueryParams('modal');
    const [publisherInUrl] = useStateQueryParams('publisher');

    return (
        <FullScreenModal
            show={showModal === OpenedModalEnum.QUICK_CONNECT}
            childrenLeft={<SuggestionSection />}
            childrenRight={<InfoSection />}
            onHideClick={() => {
                setShow(false);
                setPublisher(publisherInUrl);
                setShowModal(OpenedModalEnum.NONE);
            }}
        />
    );
};

export default QuickConnectModal;
