import { HEX_COLORS } from '@partoohub/branding';

export const actionsColor = {
    hex: HEX_COLORS.success,
    rgba: 'rgba(64, 219, 162, 0.12)',
};

export const actionsChartColor = {
    light: '#98f4d2',
    normal: '#40dba2',
    dark: '#33af82',
};

export const actionsHelperContent = [
    {
        key: 'website',
        icon: 'fa-earth-americas',
        title: 'pa_actions_visit',
        description: 'explanation-visits',
        color: actionsColor,
    },
    {
        key: 'calls',
        icon: 'fa-phone',
        title: 'pa_actions_call',
        description: 'explanation-phone',
        color: actionsColor,
    },
    {
        key: 'directions',
        icon: 'fa-diamond-turn-right',
        title: 'pa_actions_directions',
        description: 'explanation-directions',
        color: actionsColor,
    },
];
