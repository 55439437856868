import styled from '@emotion/styled';

export const DoughnutOverlayStyled = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
    line-height: 90%;
    pointer-events: none;
    user-select: none;
    font-size: 1.7rem;

    span {
        font-size: 3rem;
    }

    div {
        span {
            font-size: 3.6rem;
        }
    }
`;

export const DoughnutOverlayBigStyled = styled(DoughnutOverlayStyled)`
    font-size: ${props => (props.isMobile ? '6' : '11')}em;
`;
