import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

type Props = {
    url: string;
};

type ColorProps = {
    color: string;
};

interface BusinessCardContainerProps {
    displayBulkCheckbox?: boolean;
    isSelected?: boolean;
}

export const BusinessCardContainer = styled.div<BusinessCardContainerProps>`
    display: flex;
    align-items: center;
    gap: 16px;
    overflow: hidden;

    width: 100%;
    height: 80px;

    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0 26px 0 0;
    background-color: ${({ theme }) => theme.colors.theme.background};
    box-shadow: 0 2px 16px rgba(20, 37, 66, 0.08);

    transition: box-shadow 0.15s ease;

    ${({ isSelected, theme }) =>
        isSelected &&
        css`
            border: 2px solid ${theme.colors.primary.initial};
            padding-right: 24px;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
        `}

    &:hover {
        box-shadow: 0 5px 10px rgba(20, 37, 66, 0.16);
    }
`;

export const BusinessCardColor = styled.div<BusinessCardContainerProps>`
    background-color: ${({ color }) => color};
    height: 100%;
    margin-right: ${({ displayBulkCheckbox }) => (displayBulkCheckbox ? '0px' : '10px')};

    ${({ isSelected }) =>
        isSelected
            ? css`
                  width: 3px;
              `
            : css`
                  border-radius: 4px 0 0 4px;
                  width: 5px;
              `};
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px -8px;
    padding: 8px;
`;

export const BusinessImage = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 4px;
    height: 50px;
    width: 50px;
    background-color: ${props => (props.url ? 'none' : '#F6F8FC')};
    color: #dddfe3;
    font-size: 24px;
`;

export const BusinessFullAddress = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
`;

export const BusinessTitle = styled.div`
    font-weight: bold;
    font-size: 14px;
    color: ${HEX_COLORS.blackberry};

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const BusinessReach = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 2px;
    height: 24px;
    padding: 4px 8px;
    border: solid 1px ${({ theme }) => theme.colors.default.alpha};
    border-radius: 4px;
    width: fit-content;

    > * {
        text-transform: uppercase !important;
    }
`;

export const BusinessAddress = styled.div`
    font-weight: 300;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    > i {
        font-size: 12px;
        margin-right: 6px;
    }
`;

export const BusinessStatusChip = styled.div`
    transform: translateX(50%);
    margin-right: clamp(10px, 10%, 140px);
`;

export const IconContainer = styled.div`
    display: flex;
    gap: 4px;
`;

export const BusinessStatusLogo = styled.div`
    margin-right: 4px;
    border-radius: 50%;

    height: 20px;
    width: 20px;

    background-color: #f6f8fc;
`;

export const BusinessCompletionRate = styled.div<ColorProps>`
    display: flex;
    width: 40px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.color};
    justify-content: center;
    margin-right: 36px;
`;

export const BusinessCaret = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin-left: 28px;
`;

export const BusinessSubscriptionsButton = styled.div`
    display: flex;
    align-items: center;

    margin-right: clamp(10px, 10%, 140px);
    transform: translateX(50%);
`;

export const BusinessVerificationButton = styled.div`
    margin: 0 16px;
`;
