import { useEffect, useState } from 'react';

import { Stack, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { MessagingSetting } from 'app/api/types/business_message_settings';
import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import LoadingView from 'app/common/components/LoadingView';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';
import { ConfigurationModal } from 'app/reviewManagement/messaging/configurationModal/ConfigurationModal';
import { SaveButton } from 'app/reviewManagement/messaging/configurationModal/SaveButton';
import { useMessagingSettings } from 'app/reviewManagement/messaging/hooks/useMessagingSettings';
import { useSettingForm } from 'app/reviewManagement/messaging/hooks/useSettingForm';
import { useUpdateSetting } from 'app/reviewManagement/messaging/hooks/useUpdateSetting';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

export const ChatPreferences = () => {
    const { t } = useTranslation();
    const [allSettings, , , isLoading] = useMessagingSettings('');

    const [value, setValue] = useState<MessagingSetting>();
    const formData = useSettingForm(value);

    useEffect(() => {
        if (allSettings.length > 0 && !value) {
            setValue(allSettings[0]);
        }
    }, [allSettings, value]);

    const {
        handleSubmit,
        formState: { isDirty },
        selectedSetting,
    } = formData;

    const handleSuccessForm = () => {
        toast.success(null, t('success'));
    };

    const updateSettingMutation = useUpdateSetting(selectedSetting?.id, handleSuccessForm);
    const disabledButton = !selectedSetting || !isDirty;

    const renderChildren = () => {
        if (isLoading) {
            return (
                <Stack justifyContent="center" alignItems="center">
                    <LoadingView />
                </Stack>
            );
        }
        if (allSettings.length === 0) {
            return <Navigate replace to={NOT_FOUND_PATH} />;
        }
        return <ConfigurationModal formData={formData} onSelectionChange={setValue} />;
    };

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader title={t('settings_menu_messages_chat_preferences')} />
            }
            footerContent={
                <SaveButton
                    handleSubmit={handleSubmit}
                    updateSettingMutation={updateSettingMutation}
                    disabled={disabledButton}
                />
            }
        >
            <MenuListContentContainer>
                <>
                    <DynamicPageHeader title={t('settings_menu_messages_chat_preferences')} />
                    {renderChildren()}
                </>
            </MenuListContentContainer>
        </MenuListContentTemplate>
    );
};
