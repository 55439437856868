import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const HomepageHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
`;

export const HomepageHeaderTitle = styled(Text)``;

export const HomepageHeaderDescription = styled(Text)``;
