import { useContext } from 'react';

import { Stack } from '@partoohub/ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledContentLinkInput } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledContentLinkInput';
import { ControlledCustomLinkInput } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledCustomLinkInput';
import { PartialEventForm } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/forms/PartialEventForm';

import { ControlledCouponCode } from './ControlledField/ControlledCouponCode';
import { ControlledTermsConditions } from './ControlledField/ControlledTemsConditions';

import NextBackButtons from '../components/NextBackButtons/NextBackButtons';
import { NewPostContext, NewPostContextType } from '../context/NewPost';
import usePlatformSelection from '../hooks/usePlatformSelection';
import { getLinkOptions } from '../PostConstants';

export const OfferForm = () => {
    const { t } = useTranslation();
    const { formState, watch, control, handleSubmit } = useFormContext();

    const { setPreviousStep, setNextStep } = useContext<NewPostContextType>(NewPostContext);

    const { hasOnlyGoogle } = usePlatformSelection();

    const showLinkInput = watch('content_link') === 'custom_link';

    const customGoogleButton = watch('google_custom_link');

    const shouldDisableLinkInputWithOnlyGoogle = hasOnlyGoogle && customGoogleButton === 'call';

    return (
        <Stack>
            <PartialEventForm />

            <ControlledCouponCode control={control} />

            <ControlledContentLinkInput
                options={getLinkOptions(t)}
                disabled={shouldDisableLinkInputWithOnlyGoogle}
            />

            {showLinkInput && <ControlledCustomLinkInput />}

            <ControlledTermsConditions control={control} />

            <NextBackButtons
                disabled={!formState.isValid}
                onNext={() => {
                    handleSubmit(setNextStep)();
                }}
                onPrevious={() => {
                    setPreviousStep?.();
                }}
            />
        </Stack>
    );
};
