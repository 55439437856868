import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import usePhotosUpload from 'app/businessEditV2/hooks/usePhotosUpload';
import AddImageIcon from 'app/common/components/icons/AddImageIcon';
import DropZone from 'app/common/designSystem/components/molecules/DropZone';

import {
    MenuPhotosImageWrapper,
    MenuPhotosImagesWrapper,
} from './MenuPhotos.styled';
import MenuPhotosCarousel from './MenuPhotosCarousel';

interface MenuPhotosProps {
    value: Array<string>;
    onChange: (photosMenu: Array<string>) => void;
    disabled: boolean;
}

const MenuPhotos: FC<MenuPhotosProps> = ({ value, onChange, disabled }) => (
    <MenuPhotosImagesWrapper>
        {!disabled && <MenuPhotoDropZone photos={value} onChange={onChange} />}
        <MenuPhotosCarousel
            disabled={disabled}
            value={value}
            onChange={onChange}
        />
    </MenuPhotosImagesWrapper>
);

interface MenuPhotoDropZoneProps {
    photos: Array<string>;
    onChange: (photosMenu: Array<string>) => void;
}

const MenuPhotoDropZone: FC<MenuPhotoDropZoneProps> = ({
    photos,
    onChange,
}) => {
    const { t } = useTranslation();
    const uploadPhotos = usePhotosUpload(images => {
        onChange([...photos, ...images.result.uploads]);
    });

    const uploadPhoto = files => {
        uploadPhotos.mutate(files);
    };
    return (
        <MenuPhotosImageWrapper>
            <DropZone
                files={[]}
                handleDropSuccess={files => uploadPhoto(files)}
                label={t('menu_photo_dropzone_label')}
                uploadIcon={<AddImageIcon />}
                acceptedFiles={[
                    {
                        memeType: 'image/jpeg',
                        extension: 'JPEG',
                    },
                    {
                        memeType: 'image/png',
                        extension: 'PNG',
                    },
                ]}
                maxSize={1_000_000_000} // 1Go
                isCompact
                disabled={uploadPhotos.isLoading}
                isLoading={uploadPhotos.isLoading}
            />
        </MenuPhotosImageWrapper>
    );
};

export default MenuPhotos;
