import {
    getSurvicateInstance,
    initSurvicate,
} from '@survicate/survicate-web-surveys-wrapper/widget_wrapper';

import dayjs from 'dayjs';

import { V2OrgData } from 'app/api/types/org';
import { V2UserData } from 'app/api/types/user';
import {
    BUSINESS_MESSAGE,
    PRESENCE_MANAGEMENT,
    REVIEW_BOOSTER,
    REVIEW_MANAGEMENT,
} from 'app/common/data/productIds';
import { NPS_WHITELIST_PROVIDERS } from 'app/common/data/providers';
import env from 'app/common/services/getEnvironment';

export const handleSurvicate = (me: V2UserData, organization: V2OrgData) => {
    return new Promise((resolve, reject) => {
        if (!NPS_WHITELIST_PROVIDERS.includes(me.provider)) return;

        const key = '6f1c1a6b82242e68067aaaf9407aebc1';
        const sidebarPartooProducts = me.sidebar_products.map(s => s.toUpperCase());
        const createdAt = (me.created_at && new Date(me.created_at * 1000)) || null;
        const subscriptionDate = createdAt?.toISOString().split('T')[0] ?? '';
        const daysSinceSub = (createdAt && dayjs().diff(createdAt, 'day')) || 0;
        const traits = {
            env: env.getEnv(),
            userId: me.id,
            email: me.email,
            name: `${me.first_name} ${me.last_name}`,
            orgType: organization.organization_type,
            role: me.role,
            hasPresenceManagement: String(sidebarPartooProducts.includes(PRESENCE_MANAGEMENT)),
            hasReviewManagement: String(sidebarPartooProducts.includes(REVIEW_MANAGEMENT)),
            hasReviewBooster: String(sidebarPartooProducts.includes(REVIEW_BOOSTER)),
            hasBusinessMessages: String(sidebarPartooProducts.includes(BUSINESS_MESSAGE)),
            subscriptionDate: subscriptionDate,
            daysSinceSubscription: daysSinceSub,
            language: me.lang,
            isShadowUser: !!me.shadow_user_id,
        };
        initSurvicate({ workspaceKey: key, traits: traits })
            .then(() => resolve('[Survicate] Initialized successfully.'))
            .catch(() => reject(new Error('[Survicate] Error initializing.')));
    });
};

export const removeSurvicateScript = (): void => {
    const survicate = getSurvicateInstance();
    if (survicate) survicate.destroyVisitor();
};
