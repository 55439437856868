// ACTION TYPES
import { ApplyFilterByBusinessFiltersAction } from 'app/common/components/businessModalDeprecated/reducers';
import { PublisherBusinessesState } from 'app/states/presence/reducers/listing/businessDetails/reducer';

export const GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST =
    'GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST';
export const GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST_SUCCESS =
    'GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST_SUCCESS';
export const GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST_FAILURE =
    'GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST_FAILURE';

export const GET_BUSINESS_BY_PUBLISHER_STATES_EMPTIED =
    'GET_BUSINESS_BY_PUBLISHER_STATES_EMPTIED';
export const REFRESH_BUSINESS_BY_PUBLISHER_STATE =
    'REFRESH_BUSINESS_BY_PUBLISHER_STATE';

// ACTION FLOW TYPES
export type GetBusinessPublisherStatesAction = {
    type: 'GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST';
    publisherName: string;
    state: string;
    page: number | undefined;
};

type GetBusinessPublisherStatesSuccessAction = {
    // @ts-ignore
    type: 'GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST_SUCCESS';
    [key: string]: PublisherBusinessesState;
};

type GetBusinessPublisherStatesFailureAction = {
    // @ts-ignore
    type: 'GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST_FAILURE';
    [key: string]: PublisherBusinessesState;
};

type GetBusinessPublisherStatesEmptiedAction = {
    type: 'GET_BUSINESS_BY_PUBLISHER_STATES_EMPTIED';
};

type RefreshBusinessPublisherStatesAction = {
    type: 'REFRESH_BUSINESS_BY_PUBLISHER_STATE';
    publisherName: string;
};

export type PublisherStatesAction =
    | GetBusinessPublisherStatesAction
    | GetBusinessPublisherStatesSuccessAction
    | GetBusinessPublisherStatesFailureAction
    | GetBusinessPublisherStatesEmptiedAction
    | RefreshBusinessPublisherStatesAction
    | ApplyFilterByBusinessFiltersAction;

// ACTION CREATORS
export const getBusinessPublisherStates = (
    publisherName: string,
    state: string,
    page?: number,
): GetBusinessPublisherStatesAction => ({
    type: GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST,
    publisherName,
    state,
    page,
});

export const getBusinessPublisherStatesSuccess = (
    businessIdsByPublisherNames: Record<string, PublisherBusinessesState>,
): GetBusinessPublisherStatesSuccessAction =>
    // @ts-ignore
    ({
        type: GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST_SUCCESS,
        ...businessIdsByPublisherNames,
    });

export const getBusinessPublisherStatesFailure = (
    businessIdsByPublisherNames: Record<string, PublisherBusinessesState>,
): GetBusinessPublisherStatesFailureAction =>
    // @ts-ignore
    ({
        type: GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST_FAILURE,
        ...businessIdsByPublisherNames,
    });

export const getBusinessPublisherStatesEmptied =
    (): GetBusinessPublisherStatesEmptiedAction => ({
        type: GET_BUSINESS_BY_PUBLISHER_STATES_EMPTIED,
    });

export const refreshBusinessPublisherStates = (
    publisherName: string,
): RefreshBusinessPublisherStatesAction => ({
    type: REFRESH_BUSINESS_BY_PUBLISHER_STATE,
    publisherName,
});
