import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

import { STATIC_APP_ASSETS_URL } from 'app/config';

export const BannerContainer = styled.div`
    margin-bottom: 20px;
`;

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    margin-top: 6px;
    button {
        flex: 1;
    }
`;

export const StyledText = styled(Text)`
    margin: -10px auto;
`;

export const SuccessDialogContainer = styled.div`
    background: transparent url('${STATIC_APP_ASSETS_URL}/common/confetti_background.gif');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 16px;
    > div {
        background-color: transparent;
        & button {
            background: white;
        }
    }
`;
