import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import ModalValidationRibbon from 'app/common/components/ModalValidationRibbon';
import { ALL_SECTIONS, SECTIONS_TEXT } from 'app/common/data/exportBusinessSections';

import { SectionButton } from './SectionButton';
import { SectionButtonWrapper, SectionsFilterModalContent } from './SectionsSelectionModal.styled';
import { ModalSubtitle, ModalTitle } from '../../ExportDataModal.styled';

type Props = {
    show: boolean;
    handleClose: () => void;
    sections: Array<string>;
    setSections: Dispatch<SetStateAction<Array<string>>>;
    businessCount: number;
};

export const SectionsSelectionModal = ({
    show,
    handleClose,
    sections,
    setSections,
    businessCount,
}: Props) => {
    const { t } = useTranslation();

    const [currentSelection, setCurrentSelection] = useState<Array<string>>([]);

    const isSectionActive = (section: string): boolean => currentSelection.includes(section);

    const toggleSection = (section: string) => {
        if (isSectionActive(section)) {
            currentSelection.splice(currentSelection.indexOf(section), 1);
            setCurrentSelection([...currentSelection]);
        } else {
            setCurrentSelection([...currentSelection, section]);
        }
    };

    const onApply = () => {
        setSections([...currentSelection]);
        handleClose();
    };

    const close = () => {
        setCurrentSelection([...sections]);
        handleClose();
    };

    useEffect(() => setCurrentSelection([...sections]), [sections]);

    return (
        <Modal isOpen={show} closeModal={close}>
            <>
                <SectionsFilterModalContent>
                    <ModalTitle>{t('sections_to_export')}</ModalTitle>
                    <ModalSubtitle>
                        {businessCount > 0
                            ? t(
                                  'bulk_update_export_section_filter_modal_subtitle_with_business_count',
                                  { count: businessCount },
                              )
                            : t('bulk_update_export_section_filter_modal_subtitle_all_business')}
                    </ModalSubtitle>

                    <SectionButtonWrapper>
                        {ALL_SECTIONS.map(section => (
                            <SectionButton
                                text={t(SECTIONS_TEXT[section])}
                                section={section}
                                active={isSectionActive(section)}
                                handleClick={toggleSection}
                                disabled={false}
                                key={section}
                            />
                        ))}
                    </SectionButtonWrapper>
                </SectionsFilterModalContent>
                <ModalValidationRibbon
                    handleClick={onApply}
                    state={'enabled'}
                    text={t('validate')}
                    noIcon
                />
            </>
        </Modal>
    );
};
