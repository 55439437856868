import styled from '@emotion/styled';
import { Icon, Text } from '@partoohub/ui';

/**
 * Container for a shortcut item, no styling applied.
 * Use this to wrap your shortcut content.
 */
export const ShortcutContainer = styled.div`
    display: flex;
    height: 82px;
    overflow: hidden;
    border-radius: 8px;
    border: solid 1px ${({ theme }) => theme.colors.default.alpha};
`;

/**
 * Default content for a shortcut item.
 * You can use a new one if you want a new specific style.
 */
type ShortcutContentProps = {
    hoverColor: string;
};

export const ShortcutContent = styled.div<ShortcutContentProps>`
    display: flex;
    flex: 1;
    align-items: center;
    transition: all ease 0.15s;
    padding-inline: 24px;

    &:hover {
        cursor: pointer;
        background-color: ${({ hoverColor }) => hoverColor};
    }
`;

export const ShortcutIcon = styled(Icon)`
    margin-right: 16px;
`;

export const ShortcutTitle = styled(Text)`
    margin-right: auto;
`;
