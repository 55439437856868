import { useTranslation } from 'react-i18next';

import { useLanguage } from 'app/common/services/getLang';

import { MessageDateContainer, MessageDateText } from './MessageDate.styled';
import { formatMessageDate, formatMessageTime } from '../../services/dateAndTimeConverter';

type MessageDateProps = {
    date: string;
};

const MessageDate = ({ date }: MessageDateProps) => {
    const { t } = useTranslation();
    const lang = useLanguage();
    const showTodayIfSameDay = true;
    return (
        <MessageDateContainer>
            <MessageDateText variant="bodySBold" as="span" color={'secondary'}>
                {formatMessageDate(date, lang, t, showTodayIfSameDay)}
            </MessageDateText>
            <MessageDateText variant="bodySRegular" as="span" color={'secondary'}>
                {formatMessageTime(date, lang)}
            </MessageDateText>
        </MessageDateContainer>
    );
};

export default MessageDate;
