import { FontAwesomeIconsPartooUsed, IconPrefix, Modal } from '@partoohub/ui';

import { STATIC_BASE_URL } from 'app/config';

import { FullScreen, LeftPart, RightPart, StyledIconButton } from './FullScreenModal.styled';

type Props = {
    childrenLeft: JSX.Element;
    childrenRight?: JSX.Element;
    show: boolean;
    onHideClick: () => void;
    forceDisplayExitButton?: boolean;
};

const FullScreenModal = ({
    show,
    onHideClick,
    childrenLeft,
    childrenRight,
    forceDisplayExitButton = false,
}: Props) => {
    const closeButton = (
        <StyledIconButton
            appearance="outlined"
            onClick={onHideClick}
            icon={[FontAwesomeIconsPartooUsed.faXMarkLarge, IconPrefix.SOLID]}
            dataTrackId="close_fullscreen_modal_button"
        />
    );

    return (
        <Modal isOpen={show} closeModal={onHideClick} placement="center" animation="fadeIn">
            <FullScreen>
                <LeftPart>{childrenLeft}</LeftPart>
                {childrenRight && (
                    <>
                        <RightPart
                            backgroundUrl={`${STATIC_BASE_URL}/images/app/funnel/background.png`}
                        >
                            {childrenRight}
                        </RightPart>
                        {closeButton}
                    </>
                )}
                {!childrenRight && forceDisplayExitButton && closeButton}
            </FullScreen>
        </Modal>
    );
};

export default FullScreenModal;
