import { useTranslation } from 'react-i18next';

import useGroupIds from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupIds';
import useGroups from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroups';

export default function useGroupTitle(): string {
    const { groups } = useGroups();
    const [currentGroupId, currentSubgroupId] = useGroupIds();
    const { t } = useTranslation();

    if (currentGroupId === 'ungrouped') {
        return t('other_group_title');
    }

    if (currentSubgroupId) {
        const subgroup = groups
            .flatMap(group => group.subgroups)
            .find(sub => sub?.groupId.toString() === currentSubgroupId);
        if (subgroup) {
            return subgroup.groupName;
        }
        return t('other_group_title');
    }

    const group = groups.find(item => item.groupId.toString() === currentGroupId);
    if (group) return group.groupName;

    return t('all_businesses_title');
}
