import React, { useCallback, useMemo, useRef, useState } from 'react';

import useResizeObserver from '@react-hook/resize-observer';

import Carousel from 'app/common/designSystem/components/molecules/Carousel';

import DeleteMenuPhoto from './DeleteMenuPhoto';
import {
    MenuPhotoSlide,
    MenuPhotosCarousel as MenuPhotosCarouselStyled,
    MenuPhotosDeleteClickable,
} from './MenuPhotos.styled';

const onePhotoWidth = 206 + 16; // photo + margin-right in pixels

interface MenuPhotosCarouselProps {
    disabled?: boolean;
    value: Array<string>;
    onChange: (photos: Array<string>) => void;
}

const MenuPhotosCarousel: React.FC<MenuPhotosCarouselProps> = ({
    disabled,
    value,
    onChange,
}) => {
    const carouselWrapperRef = useRef(null);
    const [nbPhotosDisplayed, setNbPhotosDisplayed] = useState<number>(1);
    useResizeObserver(carouselWrapperRef, entry => {
        const { width } = entry.contentRect;
        const maxPhotosDisplayed = Math.trunc(width / onePhotoWidth);
        if (maxPhotosDisplayed !== nbPhotosDisplayed) {
            setNbPhotosDisplayed(maxPhotosDisplayed + 1);
        }
    });

    const photosMenu = useMemo(() => {
        const menuPhotos: Array<Array<string>> = [];
        for (let i = 0; i < value.length; i += 1) {
            menuPhotos.push(value.slice(i, i + 1));
        }

        return menuPhotos;
    }, [value, nbPhotosDisplayed]);

    const deletePhoto = useCallback(
        photoUrl => () => {
            const newPhotosMenu = value.filter(url => url !== photoUrl);
            onChange(newPhotosMenu);
        },
        [value, onChange],
    );

    return (
        <MenuPhotosCarouselStyled ref={carouselWrapperRef}>
            {nbPhotosDisplayed ? (
                <Carousel
                    arrowPosition="middle"
                    showDots={false}
                    showGradient
                    infiniteScrolling={false}
                    slideWidth={`${onePhotoWidth}px`}
                    nbOfSlideDisplayed={nbPhotosDisplayed}
                >
                    {photosMenu.map(slidePhotos => (
                        <MenuPhotoSlide
                            key={`menu_photo_slide_${nbPhotosDisplayed}_${slidePhotos[0]}`}
                        >
                            {slidePhotos.map(item => (
                                <MenuPhotosDeleteClickable
                                    photoUrl={item}
                                    key={item}
                                >
                                    {!disabled && (
                                        <DeleteMenuPhoto
                                            onClick={deletePhoto(item)}
                                            height="206px"
                                        />
                                    )}
                                </MenuPhotosDeleteClickable>
                            ))}
                        </MenuPhotoSlide>
                    ))}
                </Carousel>
            ) : null}
        </MenuPhotosCarouselStyled>
    );
};

export default MenuPhotosCarousel;
