import { useDroppable } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/react';
import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix, Option } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { BusinessFieldArrayType, CustomFieldsSectionData } from 'app/api/types/business';
import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';
import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import CustomFieldsListItem from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/sections/CustomFieldsContent/CustomFieldsList/CustomFieldsListItem';
import { CustomFieldsSectionQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

import {
    CustomFieldSectionContainer,
    CustomFieldSectionDraggable,
    GrabIcon,
    SectionCustomFieldsListContainer,
    SectionListItemContainer,
    SectionName,
} from './CustomFieldsSectionsListItem.styled';

import { FieldDraggable } from '../CustomFieldsList.styled';

export enum CustomFieldSectionActions {
    RENAME = 'rename',
    DELETE = 'delete',
}

type Props = {
    section: CustomFieldsSectionData;
    customFields: BusinessFieldArrayType;
    isOpened: boolean | undefined;
    setIsOpened: (isOpened: boolean) => void;
};

export const CustomFieldsSectionsListItem = ({
    section,
    customFields,
    isOpened,
    setIsOpened,
}: Props) => {
    const { t } = useTranslation();

    const [, renameCustomFieldSection] = useStateQueryParams(CustomFieldsSectionQueryKeys.RENAME);
    const [, deleteCustomFieldSection] = useStateQueryParams(CustomFieldsSectionQueryKeys.DELETE);

    const options: Array<Option> = [
        {
            name: CustomFieldSectionActions.RENAME,
            value: CustomFieldSectionActions.RENAME,
            label: (
                <MenuLabelItem
                    text={t('rename_section')}
                    icon={['fa-pen-clip', IconPrefix.REGULAR]}
                />
            ),
        },
        {
            name: CustomFieldSectionActions.DELETE,
            value: CustomFieldSectionActions.DELETE,
            label: (
                <MenuLabelItem
                    text={t('delete')}
                    icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                    color="danger"
                />
            ),
        },
    ];

    const onMenuClick = (value: string) => {
        if (value === CustomFieldSectionActions.RENAME) {
            renameCustomFieldSection(section.id?.toString() ?? '');
        } else if (value === CustomFieldSectionActions.DELETE) {
            deleteCustomFieldSection(section.id?.toString() ?? '');
        }
    };

    const { attributes, listeners, setNodeRef, isDragging, transition, transform } = useSortable({
        id: 'section_' + section.id,
    });

    const { setNodeRef: setNodeRefDrop } = useDroppable({
        id: 'droppableSection__' + section.id,
        disabled: !isOpened,
    });

    // No animation when section is open because it's not working properly
    const style = isOpened
        ? {}
        : {
              transform: CSS.Translate.toString(transform),
              transition,
          };

    return (
        <SectionListItemContainer ref={setNodeRef} isDragging={isDragging} style={style}>
            <CustomFieldSectionContainer>
                <CustomFieldSectionDraggable {...attributes} {...listeners}>
                    <GrabIcon icon={[FontAwesomeIconsPartooUsed.faFolder, IconPrefix.REGULAR]} />
                    <SectionName variant="bodyMBold">{section.name}</SectionName>
                </CustomFieldSectionDraggable>

                {customFields.length > 0 && (
                    <IconButton
                        appearance="outlined"
                        dataTrackId=""
                        icon={[
                            isOpened
                                ? FontAwesomeIconsPartooUsed.faChevronUp
                                : FontAwesomeIconsPartooUsed.faChevronDown,
                        ]}
                        onClick={() => setIsOpened(!isOpened)}
                    />
                )}
                <ActionButtonWithMenu
                    dataTrackId=""
                    icon={[FontAwesomeIconsPartooUsed.faEllipsisVertical, IconPrefix.SOLID]}
                    options={options}
                    // Align menu to the right
                    cssMenu={css`
                        min-width: 100px;
                        top: 60px;
                        right: -8px;
                        left: unset;
                    `}
                    onMenuClick={onMenuClick}
                    tooltip={t('more')}
                />
            </CustomFieldSectionContainer>

            <SectionCustomFieldsListContainer>
                {!!customFields.length && isOpened && (
                    <SortableContext
                        id={'section_' + section.id}
                        items={customFields.map(cf => 'cf_' + cf.id)}
                    >
                        <div ref={setNodeRefDrop}>
                            {customFields.map(customField => (
                                <FieldDraggable key={customField.id}>
                                    <CustomFieldsListItem customField={customField} />
                                </FieldDraggable>
                            ))}
                        </div>
                    </SortableContext>
                )}
            </SectionCustomFieldsListContainer>
        </SectionListItemContainer>
    );
};
