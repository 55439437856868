import styled from '@emotion/styled';

export const CircleProgressBar = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
        fill: none;
        stroke-linecap: round;

        path {
            transition:
                stroke 0.7s ease,
                trail-color 0.7s ease;
        }
    }
`;
