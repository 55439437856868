import { Choice } from 'app/api/types/user';

export type BusinessCreateFormValues = {
    name: string;
    code: string;
    phoneNumber: string;
    categories: Array<Choice>;
    openingDate: string;
    addressFull: string;
    addressComplement: string;
    zipcode: string;
    city: Choice | null;
    country: Choice;
    groups: Array<number>;
    manualLat: number | null;
    manualLong: number | null;
};

const getChoiceValue = (choice: Choice | null): string => (choice ? choice.value : '');

export const formatBusinessCreatePayload = (
    data: BusinessCreateFormValues,
    enableNewGroups: boolean | undefined,
) => ({
    name: data.name,
    address_full: data.addressFull,
    address2: data.addressComplement,
    city: getChoiceValue(data.city),
    country: getChoiceValue(data.country),
    categories: data.categories.map(getChoiceValue),
    zipcode: data.zipcode,
    contacts: [
        {
            phone_numbers: [data.phoneNumber],
        },
    ],
    code: data.code || undefined,
    manual_lat: data.manualLat,
    manual_long: data.manualLong,
    opening_date: data.openingDate,
    ...(enableNewGroups && { groups: data.groups }),
});
