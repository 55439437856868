import { Button, FontAwesomeIconsPartooUsed, IconButton, IconPrefix, Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import BackgroundDetail from './BackgroundDetail';
import {
    BackgroundContainer,
    BadgeCard,
    BadgeCardContainer,
    BadgeCardDescription,
    BadgeCardTitle,
    CloseButtonContainer,
    TooltipModalContent,
    TooltipModalSubtitle,
    TooltipModalTitle,
} from './BusinessTooltipModalContent.styled';
import { BadgeHigh, BadgeLow, BadgeMid } from '../../BusinessBanner/BannerIcons';

type Props = {
    showModal: boolean;
    closeModal: () => void;
};

const BusinessTooltipModal = ({ showModal, closeModal }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={showModal} closeModal={closeModal}>
            <TooltipModalContent>
                <TooltipModalTitle>
                    {t('business_edit_status_popup_improve_completion_score')}
                </TooltipModalTitle>
                <TooltipModalSubtitle>
                    {t('business_edit_status_popup_complete_information_explanation')}
                </TooltipModalSubtitle>
                <BadgeCardContainer>
                    <BadgeCard>
                        <BadgeCardTitle>{t('business_edit_status_popup_low')}</BadgeCardTitle>
                        <BadgeLow />
                        <BadgeCardDescription>
                            {t('business_edit_status_popup_complete_information')}
                        </BadgeCardDescription>
                    </BadgeCard>
                    <BadgeCard>
                        <BadgeCardTitle>{t('business_edit_status_popup_good')}</BadgeCardTitle>
                        <BadgeMid />
                        <BadgeCardDescription>
                            {t('business_edit_status_popup_improve_score')}
                        </BadgeCardDescription>
                    </BadgeCard>
                    <BadgeCard>
                        <BadgeCardTitle>{t('business_edit_status_popup_very_good')}</BadgeCardTitle>
                        <BadgeHigh />
                        <BadgeCardDescription>
                            {t('business_edit_status_popup_continue')}
                        </BadgeCardDescription>
                    </BadgeCard>
                </BadgeCardContainer>
                <Button
                    onClick={closeModal}
                    size="medium"
                    appearance="outlined"
                    shape="cube"
                    dataTrackId="edit_business__dismiss_completion_score_tooltip_modal"
                >
                    {t('lets_go')}
                </Button>

                <BackgroundContainer>
                    <BackgroundDetail />
                </BackgroundContainer>
                <CloseButtonContainer>
                    <IconButton
                        appearance="outlined"
                        onClick={closeModal}
                        icon={[FontAwesomeIconsPartooUsed.faXMarkLarge, IconPrefix.SOLID]}
                        dataTrackId="edit_business__dismiss_completion_score_tooltip_modal"
                    />
                </CloseButtonContainer>
            </TooltipModalContent>
        </Modal>
    );
};

export default BusinessTooltipModal;
