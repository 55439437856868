import styled from '@emotion/styled';

export const CategoriesSectionDescriptionRow = styled.div`
    display: flex;
    margin-bottom: 8px;

    span {
        margin-left: 12px;
    }
`;

export const CategoriesSectionDescription = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 16px;
    margin-bottom: 16px;

    // Align the stars
    ${CategoriesSectionDescriptionRow}:nth-of-type(2) {
        padding-left: 2px;
    }
`;
