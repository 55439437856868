import { useLocation } from 'react-router-dom';

import { VISIBILITY_PATH } from 'app/routing/routeIds';

/**
 * Hook to check if the current page is a visibility page
 */
export const useIsVisibility = (): boolean => {
    const { pathname } = useLocation();
    return pathname.startsWith(VISIBILITY_PATH);
};
