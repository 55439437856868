import { FC } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';

import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';

interface NotAccessibleForAdmin {
    callback?: () => void;
    buttonText?: string;
}

const NotAccessibleForAdmin: FC<NotAccessibleForAdmin> = ({
    callback,
    buttonText = 'page_not_found_button',
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleDefaultRedirect = () => navigate(VISIBILITY_LOCATION_PATH);

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };
    return (
        <NotFoundTemplate
            handleClick={callback ?? handleDefaultRedirect}
            buttonText={t(buttonText)}
            withButton={true}
            show
            title="not_available_for_admin"
            imgSrc={illustrationElement}
        />
    );
};

export default NotAccessibleForAdmin;
