import { useMemo } from 'react';

import { InfiniteData, UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';

import { formatReplyTemplate } from 'app/api/formatters/response/reviewAutoReplyFormatter';
import { LiteralRating, SearchTemplatesParams } from 'app/api/types/review';
import {
    FormattedReplyTemplate,
    NEGATIVE_REVIEWS,
    NEUTRAL_REVIEWS,
    POSITIVE_REVIEWS,
    PaginatedReplyTemplatesResponse,
    ReplyTemplateApi,
} from 'app/api/types/reviewReplyTemplates';
import api from 'app/api/v2/api_calls';
import { REPLY_TEMPLATE_LIST } from 'app/common/data/queryKeysConstants';

type UseReplyTemplatesFilters = {
    languages?: Array<string>;
    ratings?: Array<LiteralRating>;
    search?: string;
    titleSearch?: string;
    isAutoReply?: boolean;
    orderBy?: string;
};

const mergeFetchedTemplates = (
    data: InfiniteData<PaginatedReplyTemplatesResponse> | undefined,
    filterResponse: (responseData: ReplyTemplateApi) => boolean = () => true,
) => {
    return (
        data?.pages.reduce((prevTemplates, { reply_templates }) => {
            const nextTemplates = reply_templates.filter(filterResponse).map(formatReplyTemplate);
            return [...prevTemplates, ...nextTemplates];
        }, [] as Array<FormattedReplyTemplate>) ?? []
    );
};

export const useReplyTemplates = (
    filters: UseReplyTemplatesFilters,
    options?: UseInfiniteQueryOptions<PaginatedReplyTemplatesResponse>,
    filterResponse?: (responseData: ReplyTemplateApi) => boolean,
) => {
    const { languages, ratings, titleSearch, search, isAutoReply, orderBy = 'title' } = filters;

    const query = useInfiniteQuery(
        [REPLY_TEMPLATE_LIST, filters],
        ({ pageParam = 1 }) => {
            const queryParams: SearchTemplatesParams = {
                page: pageParam,
                title_query: titleSearch,
                text_query: search,
                positive: ratings?.includes(POSITIVE_REVIEWS) || undefined,
                negative: ratings?.includes(NEGATIVE_REVIEWS) || undefined,
                neutral: ratings?.includes(NEUTRAL_REVIEWS) || undefined,
                language__in: languages?.length ? languages : undefined,
                is_auto_reply: isAutoReply,
                order_by: orderBy,
            };
            return api.review.searchTemplates(queryParams);
        },
        {
            getNextPageParam: prevData =>
                prevData.max_page > prevData.page ? prevData.page + 1 : undefined,
            ...options,
        },
    );
    const { data } = query;
    const replyTemplates = useMemo(() => mergeFetchedTemplates(data, filterResponse), [data]);

    return [replyTemplates, query] as const;
};
