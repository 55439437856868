import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { InteractionTagType } from 'app/api/types/interactionTag';
import { Choice } from 'app/api/types/user';
import API from 'app/api/v2/api_calls/index';
import { REVIEW_TAGS } from 'app/common/data/queryKeysConstants';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import dataLayer from 'app/common/utils/dataLayer';
import { ReviewsQueryKeys } from 'app/reviewManagement/reviewList/hooks/useGetReviewsFilters';
import { WITHOUT_TAG_AS_STRING } from 'app/states/reviews/services';

import FilterAdapter from '../FilterAdapter';

export default function TagFilterSelector() {
    const { t } = useTranslation();
    const [queryValue, setQueryValue] = useStateQueryParams(ReviewsQueryKeys.TAG);
    const selectedTagIds = queryValue !== '' ? queryValue.split(',') : [];

    const { data: { tags } = { tags: [] }, isFetched } = useQuery(
        REVIEW_TAGS,
        // Getting all tags at once
        // (there are max 100 tags per orgs, therefore we set the perPage param to 100)
        () => API.interactionTag.getInteractionTags('', 1, 100),
    );

    const choices: Array<Choice> = tags
        ? [
              {
                  label: t('review_tag_no_tag_filter'),
                  value: WITHOUT_TAG_AS_STRING,
              },
              ...tags.map((tag: InteractionTagType) => ({
                  label: `${tag.label}`,
                  value: tag.id.toString(),
              })),
          ]
        : [];

    function onFilterChange(newSelectedTagIds: string[]) {
        // GTM
        dataLayer.pushDict('filter_review_by_tag');
        let tagsToSelect: Array<string>;
        if (
            newSelectedTagIds.includes(WITHOUT_TAG_AS_STRING) &&
            !selectedTagIds.includes(WITHOUT_TAG_AS_STRING)
        ) {
            tagsToSelect = [WITHOUT_TAG_AS_STRING];
        } else {
            tagsToSelect = newSelectedTagIds.filter(value => value !== WITHOUT_TAG_AS_STRING);
        }
        setQueryValue(tagsToSelect.join(','));
    }

    return isFetched && tags.length > 0 ? (
        <FilterAdapter
            multiple
            title={t('review_filter_tags_button')}
            choices={choices}
            selectedValues={selectedTagIds}
            onChange={onFilterChange}
            getActiveButtonText={options =>
                options.length > 2
                    ? t('tags_selected_with_count', {
                          count: options.length,
                      })
                    : options.map(option => option.label).join(', ')
            }
            minWidth={200}
        />
    ) : (
        <></>
    );
}
