import { useTranslation } from 'react-i18next';

import { Group as GroupType } from 'app/api/types/groupsV2';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { GroupActionButton } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/sections/GroupsSettingsContent/SectionList/Section/Group/GroupActionButton';
import { GroupsSettingsQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

import { GroupContainer, GroupCount, GroupLeft, GroupName } from './Group.styled';

type Props = {
    group: GroupType;
    sectionId: number;
    canEdit?: boolean;
};

const Group = ({ group, sectionId, canEdit = true }: Props) => {
    const { t } = useTranslation();
    const [, setEdit] = useStateQueryParams(GroupsSettingsQueryKeys.EDIT, true);

    const onClick = () => {
        if (canEdit) {
            setEdit(`${sectionId},${group.id}`);
        }
    };

    return (
        <GroupContainer canEdit={canEdit}>
            <GroupLeft onClick={onClick}>
                <GroupName>
                    <i className="fa-regular fa-folder" />
                    {group.name}
                </GroupName>
                <GroupCount variant="bodyMBold" color="secondary">
                    {t('business_modal_button_business_count_label', {
                        count: group.business_count,
                    })}
                </GroupCount>
            </GroupLeft>

            {canEdit && <GroupActionButton group={group} sectionId={sectionId} />}
        </GroupContainer>
    );
};

export default Group;
