import styled from '@emotion/styled';
import { MenuList } from '@partoohub/ui';

export const OverviewSelectionContainer = styled.div`
    width: 215px;
    height: 215px;
    position: relative;
`;

export const OverviewMenuList = styled(MenuList)`
    top: unset;
    padding: 12px;
    width: 80%;
    position: relative;
    flex: 1;
    z-index: unset;
`;
