import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    ApiKeyStatusEnum,
    ApiKeyTypeEnum,
    ApiKeysQueryKeys,
} from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/utils/enums';

import { ApiKeyListingHeaderContainer, StyledItemText } from './ApiKeyListingHeader.styled';

const ApiKeyListingHeader = () => {
    const { t } = useTranslation();
    const [typeQuery] = useStateQueryParams(ApiKeysQueryKeys.TYPE);
    const [statusQuery] = useStateQueryParams(ApiKeysQueryKeys.STATUS);

    return (
        <ApiKeyListingHeaderContainer>
            {statusQuery === ApiKeyStatusEnum.ACTIVE && (
                <StyledItemText small={true}>{t('api_key_manager_table_ip')}</StyledItemText>
            )}
            <StyledItemText big={true}>{t('api_key_manager_table_label')}</StyledItemText>
            <StyledItemText big={true}>
                {typeQuery === ApiKeyTypeEnum.USER
                    ? t('api_key_manager_table_user')
                    : t('api_key_manager_table_creator')}
            </StyledItemText>
            <StyledItemText>{t('api_key_manager_table_creationdate')}</StyledItemText>
            <StyledItemText>{t('api_key_manager_table_lastuse')}</StyledItemText>
            <StyledItemText>{t('api_key_manager_table_expirationdate')}</StyledItemText>
            <StyledItemText isKey={true}>
                {statusQuery === ApiKeyStatusEnum.ACTIVE ? t('api_key_manager_table_key') : ''}
            </StyledItemText>
        </ApiKeyListingHeaderContainer>
    );
};

export default ApiKeyListingHeader;
