import React from 'react';

import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import SuccessImage from './images/SuccessImage';
import { StatusScreenImageWrapper, StatusScreenWrapper } from './StatusScreens.styled';
import { ConfirmButton } from '../../Fields/ConfirmButton.styled';
import { FloatingFooter } from '../../Fields/FloatingFooter.styled';

type Props = {
    handleClose: () => void;
};

type SuccessScreenProps = {
    title: string;
    content: string;
    handleClose: () => void;
};

const SuccessScreen: React.FC<SuccessScreenProps> = ({ title, content, handleClose }) => {
    const { t } = useTranslation();

    return (
        <>
            <StatusScreenWrapper>
                <StatusScreenImageWrapper>
                    <SuccessImage />
                </StatusScreenImageWrapper>
                <Text variant="heading5">{title}</Text>
                <Text variant="bodyMRegular">{content}</Text>
            </StatusScreenWrapper>
            <FloatingFooter>
                <ConfirmButton
                    dataTrackId="google_verification__methods__success_confirm_button"
                    size="large"
                    shape="cube"
                    variant="success"
                    onClick={handleClose}
                >
                    {t('google_verification__status_screen__okay')}
                </ConfirmButton>
            </FloatingFooter>
        </>
    );
};

export const VerifiedScreen: React.FC<Props> = ({ handleClose }) => {
    const { t } = useTranslation();

    return (
        <SuccessScreen
            title={t('google_verification__success_screen__success_title')}
            content={t('google_verification__success_screen__success_content')}
            handleClose={handleClose}
        ></SuccessScreen>
    );
};

export const InReviewScreen: React.FC<Props> = ({ handleClose }) => {
    const { t } = useTranslation();

    return (
        <SuccessScreen
            title={t('google_verification__success_screen__in_review_title')}
            content={t('google_verification__success_screen__in_review_content')}
            handleClose={handleClose}
        ></SuccessScreen>
    );
};

export const AlreadyVerifiedScreen: React.FC<Props> = ({ handleClose }) => {
    const { t } = useTranslation();

    return (
        <SuccessScreen
            title={t('google_verification__success_screen__already_verified_title')}
            content={t('google_verification__success_screen__already_verified_content')}
            handleClose={handleClose}
        ></SuccessScreen>
    );
};
