import { useTranslation } from 'react-i18next';

import { ConversationMessage, MESSAGE_SENDER_TYPE } from 'app/states/messaging';

import { MediaBubble } from './MediaBubble';
import { StoryDetailHeader } from '../MessageBubble.styled';

interface IgStoryMentionBubbleProps {
    message: ConversationMessage;
    consumerName: string;
    handleBubbleClick: () => void;
}

export const IgStoryMentionBubble = ({
    message,
    consumerName,
    handleBubbleClick,
}: IgStoryMentionBubbleProps) => {
    const { t } = useTranslation();
    const isConsumerMessage = message.sender_type === MESSAGE_SENDER_TYPE.CONSUMER;
    const translationKey = isConsumerMessage
        ? 'messaging_meta_story_mention'
        : 'messaging_meta_story_mention_echo';

    return (
        <>
            <StoryDetailHeader variant="bodySRegular" as="span" isConsumer={isConsumerMessage}>
                {t(translationKey, { name: consumerName })}
            </StoryDetailHeader>
            <MediaBubble
                url={message.content as string}
                isConsumerMessage={isConsumerMessage}
                onClick={handleBubbleClick}
                isIgStory={true}
            />
        </>
    );
};
