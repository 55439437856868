import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';
import { CONVERSATION_STARTERS_ITEMS } from 'app/common/data/queryKeysConstants';

export default function useConversationStarterItemUpdateOrder(conversationStarterId: string) {
    const queryClient = useQueryClient();
    return useMutation(
        (reorderedConversationStarterItemIds: Array<string>) =>
            api.conversationStarters.updateConversationStarterItemOrder(
                conversationStarterId,
                reorderedConversationStarterItemIds,
            ),
        {
            onSuccess: (conversationStarterId: string) => {
                queryClient.invalidateQueries([CONVERSATION_STARTERS_ITEMS, conversationStarterId]);
            },
        },
    );
}
