import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationVariousEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { SuccessDialogContainer } from 'app/pages/visibility/subComponents/MetaRelogin/MetaRelogin.styled';

import { useMetaRelogin } from 'app/pages/visibility/subComponents/MetaRelogin/MetaReloginContext';

export const SuccessDialog = () => {
    const { t } = useTranslation();
    const context = useMetaRelogin();

    if (!context) return null;

    const { setShowModal } = context;

    return (
        <SuccessDialogContainer>
            <Dialog
                dataTrackId="meta_relogin_modal_success"
                header={
                    <Stack style={{ paddingTop: '24px' }}>
                        <Illustration
                            dataTrackId="meta_relogin_modal_success_illustration"
                            illustration={{
                                type: IllustrationCategoryEnum.Various,
                                name: IllustrationVariousEnum.FacebookWithCheckmark,
                            }}
                            height="140"
                            width="320"
                        />
                    </Stack>
                }
            >
                <Stack alignItems="center">
                    <Text variant="heading4" color="white">
                        {t('meta_relogin_modal_success_title')}
                    </Text>
                    <Text variant="bodyMRegular" color="white">
                        {t('meta_relogin_modal_success_text')}
                    </Text>
                    <Button
                        dataTrackId=""
                        variant="secondary"
                        appearance="outlined"
                        shape="rounded"
                        onClick={() => setShowModal(false)}
                    >
                        {t('meta_relogin_modal_success_button')}
                    </Button>
                </Stack>
            </Dialog>
        </SuccessDialogContainer>
    );
};
