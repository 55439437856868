export const WhatsappMessagingIcon = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="#32BF20"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.4061 9.84933C15.4056 11.7315 14.4103 13.4733 12.789 14.4293C11.1677 15.3854 9.16189 15.4135 7.51452 14.5031L4.57355 15.4484L5.53302 12.5921C4.37278 10.6665 4.54865 8.21939 5.97234 6.47955C7.39602 4.73971 9.75992 4.08303 11.877 4.83925C13.9941 5.59548 15.407 7.60124 15.4061 9.84933ZM14.518 9.84933C14.5217 7.92951 13.2882 6.22589 11.4631 5.63027C9.63799 5.03465 7.63697 5.68266 6.5075 7.23508C5.37803 8.78751 5.3773 10.8908 6.50571 12.444L5.94888 14.0872L7.65549 13.5445C9.01458 14.4425 10.7573 14.521 12.1915 13.7487C13.6258 12.9764 14.5196 11.4783 14.518 9.84933ZM12.8528 11.3295C12.8547 11.2937 12.8499 11.2579 12.8387 11.2238C12.7894 11.1392 11.6405 10.5401 11.5233 10.5427C11.4344 10.5427 11.3063 10.702 11.1738 10.8669C11.0318 11.0435 10.8847 11.2264 10.7753 11.2264C10.7202 11.2241 10.6666 11.207 10.6202 11.1771C10.2305 10.9963 9.87118 10.7561 9.55505 10.4652C9.26202 10.1854 9.01889 9.85761 8.8361 9.49602C8.81503 9.4646 8.80286 9.42807 8.80086 9.3903C8.80086 9.3191 8.89467 9.22002 8.99989 9.1089C9.13425 8.96702 9.2872 8.80549 9.2872 8.65726C9.2872 8.58677 8.92773 7.6273 8.87839 7.50748C8.80791 7.32422 8.77267 7.26783 8.58236 7.26783C8.53387 7.26783 8.48903 7.26234 8.44507 7.25695C8.40276 7.25177 8.36125 7.24668 8.31804 7.24668C8.17967 7.24668 8.04717 7.30261 7.95064 7.40175C7.61231 7.72334 7.44315 8.05021 7.42905 8.51629V8.57267C7.42201 9.0599 7.66518 9.54536 7.92949 9.94096C8.53742 10.837 9.16297 11.6194 10.1735 12.074C10.4766 12.2185 11.1744 12.5145 11.5136 12.5145C11.9154 12.5145 12.5709 12.2608 12.733 11.8661C12.8045 11.6957 12.845 11.514 12.8528 11.3295Z"
            fill="white"
        />
    </svg>
);
