import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const EditSmsTemplateTextareaWrapper = styled.div`
    text-align: initial;
    padding: 0 10px;
    .mentions_danger__explanation,
    .mentions_normal__explanation {
        text-align: right;
    }
`;

export const EditSmsTemplateButtonWrapper = styled.div`
    width: fit-content;
    margin-left: auto;
    margin-bottom: 5px;
    padding-right: 10px;
    .mdc-select__menu {
        top: 2em;
        left: 0;
    }
`;

type EditSmsTemplateTextCountProps = {
    isTextTooLong: boolean;
};

export const EditSmsTemplateTextCount = styled.div<EditSmsTemplateTextCountProps>`
    width: 100%;
    text-align: right;
    color: ${({ theme }) => theme.colors.secondary.initial};

    ${({ isTextTooLong, theme }) =>
        isTextTooLong &&
        css`
            color: ${theme.colors.danger.initial};
        `}
`;
