import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const StyledModalBody = styled.div`
    max-width: 500px;
    padding: 0 auto;
    overflow: hidden;
    border-radius: 8px;
`;

export const StyledModalImage = styled.img`
    width: 100%;
`;

export const StyledTextContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 24px 24px 24px;
`;

export const StyledTitle = styled(Text)`
    text-align: center;
`;
