import { useEffect } from 'react';

export const useWaitForGoogleScripts = (callback: () => void) => {
    useEffect(() => {
        // Wait for the Google Maps script to be loaded
        const waitFor = conditionFunction => {
            const poll = resolve => {
                if (conditionFunction()) resolve();
                else setTimeout(() => poll(resolve), 400);
            };
            return new Promise(poll);
        };

        waitFor(() => window.google).then(callback);
    }, []);
};
