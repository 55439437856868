import React from 'react';

type Props = {
    width?: number;
    height?: number;
};

const BadgeMid = React.forwardRef<SVGSVGElement>(
    ({ width = 128, height = 130 }: Props, ref) => (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 124 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M56.4555 17L78.1201 20.3206L68.0479 48.1914L85.6364 22.7557L99.5637 35.8168L76.3009 54.2036L103.195 42.6794L108.185 60.168L79.4533 63.9314L108.185 70.5726L101.111 89.8322L76.3009 73.6592L94.2581 98.023L76.3009 107.321L68.0479 79.6713L69.0563 110.42L47.6127 109.092L57.8466 79.6713L39.6543 105.771L24.9531 90.7177L49.5935 73.6592L20.4214 84.0764L16 61.7176L46.4412 63.9314L17.1053 53.3054L26.3902 35.8168L49.5935 54.2036L31.9169 29.6183L47.6127 19.2137L57.8466 48.1914L56.4555 17Z"
                fill="url(#paint0_radial_22641_119207)"
            />
            <g
                style={{ mixBlendMode: 'color-burn' }}
                filter="url(#filter0_f_22641_119207)"
            >
                <path
                    d="M78.1699 49.1533C81.3314 51.1852 83.1687 54.0923 83.2763 57.8622C83.4004 62.2135 83.4025 66.5732 83.2659 70.9245C83.1521 74.5537 81.439 77.4649 78.3458 79.3706C74.6878 81.6218 70.9594 83.7675 67.1876 85.8221C63.8771 87.6222 60.4198 87.6222 57.1094 85.8221C53.3375 83.7695 49.6092 81.6218 45.9511 79.3727C42.9159 77.5043 41.1758 74.653 41.0496 71.0921C40.8903 66.6311 40.8965 62.1556 41.0372 57.6925C41.151 54.0612 42.9531 51.2348 45.9863 49.2506C47.5464 48.2305 57.0514 42.2508 59.5674 41.3135C61.2784 40.6141 62.9937 40.6182 64.7089 41.2886C67.5869 42.588 76.6513 48.1746 78.172 49.1512L78.1699 49.1533Z"
                    fill="#F7BC7E"
                />
                <path
                    d="M78.7575 64.4792C78.7575 66.4014 78.7368 68.3257 78.7637 70.2479C78.7968 72.5528 77.8202 74.3033 75.865 75.462C72.4139 77.5104 68.9214 79.4906 65.4599 81.5224C63.2585 82.8136 61.0715 82.826 58.8722 81.5493C55.4003 79.534 51.9223 77.5291 48.465 75.4889C46.5036 74.3323 45.5228 72.5942 45.5332 70.2934C45.5497 66.3352 45.5373 62.3749 45.5373 58.4167C45.5373 56.1489 46.4022 54.3674 48.4174 53.2067C51.9927 51.1458 55.5576 49.0685 59.1287 46.9973C61.1729 45.8117 63.1943 45.8696 65.2427 47.0345C69.0641 49.2071 72.896 51.3569 76.4857 53.9122C77.9899 54.984 78.7596 56.3683 78.7554 58.2015C78.7534 60.2934 78.7554 62.3873 78.7554 64.4792H78.7575Z"
                    fill="#FCEAD1"
                />
                <path
                    d="M63.3619 76.5939C61.866 76.5939 61.5784 74.92 61.0612 73.9289C58.8577 66.9291 51.912 67.5457 51.3637 64.4792C51.2788 63.5543 52.1292 63.2336 52.7748 62.8467C57.0307 61.1521 59.9232 58.1457 61.684 53.8626C62.644 51.2928 64.4854 52.6646 65.2406 54.6261C66.7303 58.7292 69.7097 61.2535 73.6532 62.8798C78.8113 65.9504 68.1745 67.6326 67.3944 70.5376C66.0661 72.3646 65.3978 76.5918 63.3599 76.5918L63.3619 76.5939Z"
                    fill="#F7BD7F"
                />
                <path
                    d="M62.2281 75.7952C60.7901 75.7952 60.5129 74.2 60.0143 73.2564C57.8935 66.5877 51.2126 67.1733 50.685 64.2538C50.6044 63.3723 51.4216 63.0661 52.0423 62.6999C56.1369 61.0839 58.9197 58.2202 60.6143 54.1399C61.5371 51.6901 63.3081 52.9999 64.0364 54.8662C65.4703 58.7747 68.3359 61.1811 72.1304 62.7309C77.094 65.6566 66.8606 67.2602 66.1096 70.0286C64.833 71.7708 64.1875 75.7973 62.2281 75.7973V75.7952Z"
                    fill="#FFD792"
                />
                <path
                    d="M71.5863 53.0598C71.8801 55.3296 73.2394 56.1531 75.2609 56.5297C73.1691 56.9601 71.9442 58.036 71.6339 60.2417C71.1518 58.1457 70.1276 56.8608 67.8807 56.6208C69.7635 56.0683 70.9511 54.9965 71.5842 53.0598H71.5863Z"
                    fill="#F9C691"
                />
                <path
                    d="M55.4728 71.4335C55.8286 72.7308 56.3728 73.5274 57.6142 73.7509C56.6107 74.3674 55.7769 74.9282 55.4728 76.3848C54.8727 75.1165 54.3058 74.2516 52.7251 73.9185C54.2313 73.4343 54.8603 72.6025 55.4728 71.4335Z"
                    fill="#F9C691"
                />
                <path
                    d="M63.1736 82.4039C61.7398 82.6667 60.3081 82.3832 58.8701 81.5494C55.3983 79.532 51.9202 77.5291 48.4629 75.4889C46.5015 74.3323 45.5228 72.5942 45.5332 70.2934C45.5497 66.3331 45.5373 62.3749 45.5373 58.4146C45.5373 56.1469 46.4001 54.3675 48.4174 53.2046C51.9927 51.1438 55.5597 49.0664 59.1287 46.9952C60.4777 46.2131 61.8164 45.9731 63.1592 46.2338C62.5074 46.3621 61.8536 46.6145 61.1977 46.9952C57.6287 49.0664 54.0617 51.1438 50.4864 53.2046C48.4691 54.3675 47.6063 56.1469 47.6063 58.4146C47.6063 62.3749 47.6188 66.3331 47.6022 70.2934C47.5919 72.5942 48.5705 74.3323 50.5319 75.4889C53.9893 77.5291 57.4673 79.532 60.9391 81.5494C61.684 81.9818 62.4288 82.2673 63.1736 82.4039Z"
                    fill="#F7807F"
                />
            </g>
            <g clipPath="url(#clip0_22641_119207)">
                <path
                    d="M78.1699 49.1533C81.3314 51.1852 83.1687 54.0923 83.2763 57.8622C83.4004 62.2135 83.4025 66.5732 83.2659 70.9245C83.1521 74.5537 81.439 77.465 78.3458 79.3706C74.6878 81.6218 70.9594 83.7675 67.1876 85.8221C63.8771 87.6222 60.4198 87.6222 57.1094 85.8221C53.3375 83.7695 49.6092 81.6218 45.9511 79.3727C42.9159 77.5043 41.1758 74.653 41.0496 71.0921C40.8903 66.6311 40.8965 62.1556 41.0372 57.6925C41.151 54.0612 42.9531 51.2348 45.9863 49.2506C47.5464 48.2305 57.0514 42.2508 59.5674 41.3135C61.2784 40.6141 62.9937 40.6182 64.7089 41.2886C67.5869 42.588 76.6513 48.1746 78.172 49.1512L78.1699 49.1533Z"
                    fill="#F7BC7E"
                />
                <path
                    d="M78.7576 64.4792C78.7576 66.4014 78.7369 68.3257 78.7638 70.2479C78.7969 72.5529 77.8203 74.3033 75.8651 75.462C72.414 77.5104 68.9215 79.4906 65.46 81.5224C63.2586 82.8136 61.0716 82.826 58.8723 81.5493C55.4004 79.534 51.9224 77.5291 48.4651 75.4889C46.5037 74.3323 45.5229 72.5942 45.5333 70.2934C45.5498 66.3352 45.5374 62.3749 45.5374 58.4167C45.5374 56.1489 46.4023 54.3674 48.4175 53.2067C51.9928 51.1458 55.5577 49.0685 59.1288 46.9973C61.173 45.8117 63.1944 45.8696 65.2428 47.0345C69.0643 49.2071 72.8961 51.3569 76.4858 53.9122C77.99 54.984 78.7597 56.3683 78.7555 58.2015C78.7535 60.2934 78.7555 62.3873 78.7555 64.4792H78.7576Z"
                    fill="#FCEAD1"
                />
                <g style={{ mixBlendMode: 'multiply' }}>
                    <path
                        d="M63.362 76.5939C61.8661 76.5939 61.5785 74.92 61.0613 73.9289C58.8578 66.9291 51.9121 67.5457 51.3638 64.4793C51.279 63.5544 52.1293 63.2337 52.7749 62.8467C57.0308 61.1521 59.9233 58.1457 61.6841 53.8627C62.6441 51.2928 64.4855 52.6646 65.2407 54.6262C66.7304 58.7292 69.7098 61.2535 73.6533 62.8798C78.8114 65.9504 68.1746 67.6326 67.3946 70.5376C66.0663 72.3646 65.398 76.5918 63.36 76.5918L63.362 76.5939Z"
                        fill="#F7BD7F"
                    />
                </g>
                <path
                    d="M62.2283 75.7953C60.7903 75.7953 60.5131 74.2 60.0144 73.2565C57.8937 66.5877 51.2128 67.1733 50.6852 64.2538C50.6045 63.3723 51.4218 63.0661 52.0425 62.6999C56.1371 61.0839 58.9199 58.2202 60.6145 54.1399C61.5372 51.6901 63.3083 52.9999 64.0366 54.8662C65.4704 58.7747 68.336 61.1811 72.1306 62.7309C77.0942 65.6566 66.8608 67.2602 66.1098 70.0286C64.8332 71.7708 64.1877 75.7973 62.2283 75.7973V75.7953Z"
                    fill="#FFD792"
                />
                <path
                    d="M71.5862 53.0598C71.88 55.3296 73.2394 56.1531 75.2608 56.5297C73.169 56.9601 71.9442 58.036 71.6338 60.2417C71.1517 58.1457 70.1276 56.8608 67.8806 56.6208C69.7634 56.0683 70.951 54.9965 71.5842 53.0598H71.5862Z"
                    fill="#F9C691"
                />
                <path
                    d="M55.4728 71.4335C55.8286 72.7308 56.3728 73.5274 57.6142 73.7509C56.6107 74.3675 55.7769 74.9282 55.4728 76.3848C54.8727 75.1165 54.3058 74.2516 52.7251 73.9185C54.2313 73.4343 54.8603 72.6025 55.4728 71.4335Z"
                    fill="#F9C691"
                />
                <g style={{ mixBlendMode: 'color-burn' }}>
                    <path
                        d="M63.1738 82.4039C61.7399 82.6667 60.3082 82.3832 58.8702 81.5494C55.3984 79.532 51.9204 77.5291 48.463 75.4889C46.5016 74.3323 45.5229 72.5942 45.5333 70.2934C45.5498 66.3331 45.5374 62.3749 45.5374 58.4146C45.5374 56.1469 46.4002 54.3674 48.4175 53.2046C51.9928 51.1438 55.5598 49.0664 59.1288 46.9952C60.4778 46.2131 61.8165 45.9731 63.1593 46.2338C62.5075 46.3621 61.8537 46.6145 61.1978 46.9952C57.6288 49.0664 54.0618 51.1438 50.4865 53.2046C48.4692 54.3674 47.6064 56.1469 47.6064 58.4146C47.6064 62.3749 47.6189 66.3331 47.6023 70.2934C47.592 72.5942 48.5706 74.3323 50.532 75.4889C53.9894 77.5291 57.4674 79.532 60.9392 81.5494C61.6841 81.9818 62.4289 82.2673 63.1738 82.4039V82.4039Z"
                        fill="#F7807F"
                    />
                </g>
            </g>
            <g filter="url(#filter1_d_22641_119207)">
                <path
                    d="M89.9221 81.3558L91.0965 84.5297L94.2705 85.7041L91.0965 86.8786L89.9221 90.0525L88.7476 86.8786L85.5737 85.7041L88.7476 84.5297L89.9221 81.3558Z"
                    fill="#FFC88A"
                />
            </g>
            <g filter="url(#filter2_d_22641_119207)">
                <path
                    d="M68.4347 91.7354L67.646 93.5556L68.6101 95.2892L66.6638 94.5663L64.7981 95.4774L65.5868 93.6572L64.6226 91.9236L66.569 92.6465L68.4347 91.7354Z"
                    fill="#FFC88A"
                />
            </g>
            <g filter="url(#filter3_d_22641_119207)">
                <path
                    d="M36.0023 82.2255L36.4721 83.495L37.7416 83.9648L36.4721 84.4346L36.0023 85.7042L35.5325 84.4346L34.2629 83.9648L35.5325 83.495L36.0023 82.2255Z"
                    fill="#FFC88A"
                />
            </g>
            <g filter="url(#filter4_d_22641_119207)">
                <path
                    d="M86.0087 39.6115L86.5959 41.1984L88.1828 41.7856L86.5959 42.3729L86.0087 43.9598L85.4214 42.3729L83.8345 41.7856L85.4214 41.1984L86.0087 39.6115Z"
                    fill="#FFC88A"
                />
            </g>
            <g filter="url(#filter5_d_22641_119207)">
                <path
                    d="M52.116 35.5153L51.5902 36.7288L52.233 37.8845L50.9354 37.4026L49.6916 38.01L50.2174 36.7966L49.5747 35.6408L50.8722 36.1228L52.116 35.5153Z"
                    fill="#FFC88A"
                />
            </g>
            <g filter="url(#filter6_d_22641_119207)">
                <path
                    d="M36.0024 37.8721L36.9419 40.4112L39.4811 41.3508L36.9419 42.2903L36.0024 44.8295L35.0628 42.2903L32.5237 41.3508L35.0628 40.4112L36.0024 37.8721Z"
                    fill="#FFC88A"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_22641_119207"
                    x="0.930908"
                    y="0.787354"
                    width="122.438"
                    height="126.385"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="20"
                        result="effect1_foregroundBlur_22641_119207"
                    />
                </filter>
                <filter
                    id="filter1_d_22641_119207"
                    x="82.1481"
                    y="77.9301"
                    width="15.5481"
                    height="15.548"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.784314 0 0 0 0 0.541176 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119207"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119207"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_d_22641_119207"
                    x="61.1969"
                    y="88.3097"
                    width="10.8388"
                    height="10.5934"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.784314 0 0 0 0 0.541176 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119207"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119207"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter3_d_22641_119207"
                    x="30.8373"
                    y="78.7998"
                    width="10.3301"
                    height="10.33"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.784314 0 0 0 0 0.541176 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119207"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119207"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter4_d_22641_119207"
                    x="80.4088"
                    y="36.1858"
                    width="11.1997"
                    height="11.1997"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.784314 0 0 0 0 0.541176 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119207"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119207"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter5_d_22641_119207"
                    x="46.1491"
                    y="32.0896"
                    width="9.5095"
                    height="9.34605"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.784314 0 0 0 0 0.541176 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119207"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119207"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter6_d_22641_119207"
                    x="29.098"
                    y="34.4464"
                    width="13.8086"
                    height="13.8087"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.71282" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.784314 0 0 0 0 0.541176 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_22641_119207"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_22641_119207"
                        result="shape"
                    />
                </filter>
                <radialGradient
                    id="paint0_radial_22641_119207"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(62.8664 63.9314) rotate(90.9535) scale(46.4951 45.3252)"
                >
                    <stop stopColor="#FFC88A" stopOpacity="0.32" />
                    <stop offset="1" stopColor="#FFC88A" stopOpacity="0" />
                </radialGradient>
                <clipPath id="clip0_22641_119207">
                    <rect
                        width="42.7038"
                        height="46.7536"
                        fill="white"
                        transform="translate(40.7395 40.5856)"
                    />
                </clipPath>
            </defs>
        </svg>
    ),
);

export default BadgeMid;
