import { CommentData } from 'app/api/types/comments';
import { Partner } from 'app/businessEditV2/hooks/useIsConnectedToPartner';
import { useCanReplyClientReviews } from 'app/pages/customerExperience/useCanReplyClientReviews';
import { ReviewObject } from 'app/states/reviews';

interface Props {
    review: ReviewObject;
    comment: CommentData | undefined;
}

export const useShowCommentReplyActions = ({ review, comment }: Props) => {
    const { canReplyClientReviews } = useCanReplyClientReviews();
    const userHasPermission = canReplyClientReviews[review.reviewObjectType];

    if (!userHasPermission) {
        return {
            canReply: false,
            canEdit: false,
            canDelete: false,
        };
    }

    return {
        canEdit: review.state !== 'deleted' && comment?.can_edit,
        canReply: review.state !== 'deleted' && review.partner === Partner.Facebook,
        canDelete:
            review.state !== 'deleted' && !!review.partner && review.partner !== Partner.Facebook,
    };
};
