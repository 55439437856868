import { useTranslation } from 'react-i18next';

import NavSection from 'app/common/designSystem/components/atoms/NavSection/NavSection';
import { CollapsibleSidebarTitle } from 'app/common/designSystem/components/molecules/Sidebar/Sidebar/CollapsibleSidebar.styled';
import {
    CEPages,
    useCanAccessCustomerExperiencePage,
} from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import { useNewReviewsCount } from 'app/reviewManagement/reviewList/hooks/useNewReviewsCount';
import {
    CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH,
    CUSTOMER_EXPERIENCE_SEND_INVITE_PATH,
} from 'app/routing/routeIds';

export const CustomerExperience = () => {
    const { t } = useTranslation();
    const { canAccessCustomerExperiencePage } = useCanAccessCustomerExperiencePage();
    const newReviewsCount = useNewReviewsCount();

    return (
        <>
            <CollapsibleSidebarTitle data-cy="collapsible-sidebar-title">
                {t('customer_experience')}
            </CollapsibleSidebarTitle>

            {canAccessCustomerExperiencePage[CEPages.ClientReviews] && (
                <NavSection
                    title={t('clients_reviews')}
                    path={CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH}
                    dataTrack="sidemenu_customer_experience__client_reviews"
                    count={newReviewsCount}
                />
            )}

            {canAccessCustomerExperiencePage[CEPages.RB] && (
                <NavSection
                    title={t('rb_send_an_invitation')}
                    path={CUSTOMER_EXPERIENCE_SEND_INVITE_PATH}
                    dataTrack="sidemenu_customer_experience__send_invitation"
                />
            )}
        </>
    );
};
