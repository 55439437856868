import { useContext, useMemo } from 'react';

import { Banner } from '@partoohub/ui';

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import IS_IFRAME from 'app/common/data/iframe';
import { STATIC_BASE_URL } from 'app/config';
import { BusinessServicesApiKey } from 'app/pages/visibility/BusinessListV2/hooks/queryParams/params/useGetProductFilter';
import {
    NewPostContext,
    NewPostContextType,
} from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/context/NewPost';
import useBusinessConnectionStats from 'app/presence/googlePosts/hooks/useBusinessConnectionStats';
import useBusinessScopeData from 'app/presence/googlePosts/hooks/useBusinessScopeData';
import {
    FACEBOOK_OAUTH2_START_PATH,
    GOOGLE_OAUTH2_START_PATH,
    SETTINGS_PLATFORMS_CONNECTION_PATHNAME,
} from 'app/routing/routeIds';

import Flexbox from 'app/styles/utils/flexbox';
import { businessParams } from 'app/utils/queryString';

type PublisherType = 'google' | 'facebook' | 'instagram';

const publishers: Array<PublisherType> = ['google', 'facebook', 'instagram'];

const scopePlatformToPublisher: Record<string, PublisherType> = {
    google_my_business: 'google',
    facebook: 'facebook',
    instagram: 'instagram',
};

const publisherToName = {
    google: 'Google',
    facebook: 'Facebook',
    instagram: 'Instagram',
};

const platformToConnectString = {
    google: 'continue_with_google',
    facebook: 'continue_with_facebook',
    instagram: 'continue_with_facebook',
};

const imgName = {
    google: 'google_white_background',
    facebook: 'facebook',
    instagram: 'facebook',
};

export const platformToOAuthUrl = {
    google: GOOGLE_OAUTH2_START_PATH,
    facebook: FACEBOOK_OAUTH2_START_PATH,
    instagram: null, // No specific OAuth for IG, instead use facebook or the InstagramConnectModal
};

export const getPartnerConnectionLink = (businessParams: string, publisher?: string) => {
    return {
        pathname: SETTINGS_PLATFORMS_CONNECTION_PATHNAME,
        search: `${businessParams}&status=open&${BusinessServicesApiKey.PRESENCE}=true${
            publisher ? `&publisher=${publisher}` : ''
        }`,
    };
};

export const openPartnerScopesModal = (platform: 'google' | 'facebook') => {
    let connectionLinkURL = platformToOAuthUrl[platform];

    if (IS_IFRAME) {
        const jwtToken = axios.defaults.headers.common.Authorization;
        if (jwtToken) {
            connectionLinkURL = `${connectionLinkURL}?jwt_token=${jwtToken}`;
        }
    }
    window.open(connectionLinkURL, 'Login with social account', 'height=600, width=800');
};

const getPartnerConnectionMissingData = (connectionStats: {
    count: number;
    linked_to_gmb_count: number;
    linked_to_facebook_count: number;
}) => {
    const count = connectionStats.count;
    const google = count - connectionStats.linked_to_gmb_count;
    const facebook = count - connectionStats.linked_to_facebook_count;
    const instagram = facebook;

    return { google, facebook, instagram };
};

type Props = {
    onInstagramBannerClick: () => void;
};

export default function ConnectionBanner({ onInstagramBannerClick }: Props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        formFields: { platform = [] },
    } = useContext<NewPostContextType>(NewPostContext);
    const connectionData = useBusinessConnectionStats(platform);
    const businessScopeData = useBusinessScopeData(platform);
    const onClick = (platform: keyof typeof platformToOAuthUrl) => {
        if (platform === 'instagram') {
            onInstagramBannerClick();
        } else {
            openPartnerScopesModal(platform);
        }
    };

    const banners = useMemo(() => {
        const banners: Array<JSX.Element> = [];
        if (!(connectionData && businessScopeData)) {
            return banners;
        }

        const partnerCoMissingData = getPartnerConnectionMissingData(connectionData);
        const hasInstagramAndFacebook =
            platform.includes('facebook') && platform.includes('instagram');

        publishers.forEach((publisher: PublisherType) => {
            // Platform is not selected in the Post creation form
            if (!platform.includes(publisher)) {
                return;
            }

            const count = connectionData.count;
            const unconnected = partnerCoMissingData[publisher];

            if (unconnected > 0) {
                // No need to display the banner to connect to facebook twice
                // (instagram uses facebook partner connection)
                if (publisher === 'instagram' && hasInstagramAndFacebook) {
                    return;
                }

                const publisherName =
                    publisher === 'facebook' && hasInstagramAndFacebook
                        ? [publisherToName.facebook, publisherToName.instagram].join(' / ')
                        : publisherToName[publisher];

                banners.push(
                    <Banner
                        key={publisher}
                        hideIcon
                        actionButtonText={t('connect_all')}
                        withCloseIcon={false}
                        onAction={() => {
                            navigate(
                                getPartnerConnectionLink(
                                    businessParams(),
                                    publisher === 'instagram' ? 'facebook' : publisher,
                                ),
                            );
                        }}
                        variant={unconnected === count ? 'danger' : 'warning'}
                        dataTrackId={''}
                    >
                        <span>
                            {t('post_message_warning_disconnected', {
                                count: unconnected,
                                partner: publisherName,
                            })}
                        </span>
                    </Banner>,
                );
            }
        });

        // Adding banners for prompting users to login to partners and give access to partoo
        Object.keys(businessScopeData).forEach(key => {
            const publisher = scopePlatformToPublisher[key];
            // Platform is not selected in the Post creation form
            if (!platform.includes(publisher)) {
                return;
            }

            const businessesNotConnectedOrWithScopeError = Object.keys(
                businessScopeData[key],
            ).filter((businessId: string) => {
                const scope = businessScopeData[key][businessId];

                return !scope.includes('posts');
            });

            // We need to substract the number of non connected business (business without a partner connection)
            // to get the actual number of businesses with a scope error
            // (because the /api/v2/scopes endpoint does not make the distinction between non connected businesses
            // and business with a scope error)
            const scopeErrorCount =
                businessesNotConnectedOrWithScopeError.length - partnerCoMissingData[publisher];

            if (scopeErrorCount > 0) {
                const platformName = publisherToName[publisher];

                banners.push(
                    <Banner
                        key={`${platformName}_connection`}
                        hideIcon
                        // @ts-ignore
                        actionButtonText={
                            <>
                                <img
                                    src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/${imgName[publisher]}.svg`}
                                    alt=""
                                />
                                &nbsp;&nbsp;
                                {t(platformToConnectString[publisher])}
                            </>
                        }
                        withCloseIcon={false}
                        onAction={() => {
                            onClick(publisher);
                        }}
                        variant={
                            scopeErrorCount === businessesNotConnectedOrWithScopeError.length
                                ? 'danger'
                                : 'warning'
                        }
                    >
                        <span>
                            {t('post_message_warning', {
                                count: scopeErrorCount,
                                platform: platformName,
                            })}
                        </span>
                    </Banner>,
                );
            }
        });

        return banners;
    }, [connectionData, businessScopeData, platform]);

    return banners.length ? (
        <Flexbox flexDirection="column" gap="8px">
            {banners}
        </Flexbox>
    ) : (
        <></>
    );
}
