import { useSelector } from 'react-redux';

import { businessByIdSelector } from 'app/common/reducers/newBusinesses';
import { businessesSelector } from 'app/states/reducers';

const useReduxBusiness = (businessId: string) => {
    return businessByIdSelector(useSelector(businessesSelector), businessId);
};

export default useReduxBusiness;
