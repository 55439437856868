import { useInfiniteQuery, useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import {
    BUSINESSES,
    REVIEW_BOOSTER_GET_EXPEDITOR_NAME,
    REVIEW_BOOSTER_SMS_TEMPLATE,
} from 'app/common/data/queryKeysConstants';
import { useIsFirstRender } from 'app/common/hooks/useIsFirstRender';

export const useReviewBoosterInitialDataLoad = () => {
    const isFirstRender = useIsFirstRender();

    const businessesQuery = useInfiniteQuery(
        [BUSINESSES, { query: '' }],
        () => api.business.searchBusinesses({ page: 1 }),
        { enabled: isFirstRender },
    );
    const firstBusiness = businessesQuery?.data?.pages?.[0]?.businesses?.[0];
    const templateQuery = useQuery(
        REVIEW_BOOSTER_SMS_TEMPLATE,
        () => api.reviewBooster.getTemplate(),
        { enabled: isFirstRender },
    );
    const expeditorNameQuery = useQuery(
        [REVIEW_BOOSTER_GET_EXPEDITOR_NAME, { businessId: firstBusiness?.id }],
        () => api.reviewBooster.getExpeditorName(firstBusiness?.id as string),
        { enabled: isFirstRender && !!firstBusiness?.id },
    );

    return {
        isLoading: [businessesQuery, templateQuery, expeditorNameQuery].every(
            ({ isLoading }) => isLoading,
        ),
    };
};
