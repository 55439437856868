import styled from '@emotion/styled';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    padding: 12.5px 16px;
    border: 1px solid var(--grey-initial, #dddfe3);
    border-radius: 8px;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    flex-direction: row;
    &:focus-within {
        label {
            transform: translate(2px, -16px) scale(0.8);
        }
    }
`;

export const Label = styled.label<{ $filled: boolean }>`
    position: absolute;
    margin-top: 12px;
    pointer-events: none;
    transform: ${props =>
        props.$filled ? 'translate(2px, -16px) scale(0.8)' : 'translate(0, 0) scale(1)'};
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
`;

export const InputWrapper = styled.div`
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 12px;
`;

export const Input = styled.input`
    width: 60%;
    border: none;
    font-size: 14px;
    line-height: 1;
    outline: none;
    box-shadow: none;
`;

export const EmailDomain = styled.div`
    font-size: 14px;
    max-width: 40%;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
