import React, { useEffect } from 'react';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import useDeleteSection from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/hooks/sections/useDeleteSection';
import useSection from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/hooks/sections/useSection';
import { GroupsSettingsQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

import DeleteSectionErrorContent from './contents/DeleteSectionErrorContent';
import DeleteSectionWarningContent from './contents/DeleteSectionWarningContent';

type Props = {
    closeModal: () => void;
};

const DeleteSectionContent = ({ closeModal }: Props) => {
    const [sectionId] = useStateQueryParams(GroupsSettingsQueryKeys.DELETE);
    const { data: section } = useSection(sectionId);

    useEffect(() => {
        if (section && !section.can_edit) {
            closeModal();
        }
    }, [section?.can_edit]);

    const deleteSection = useDeleteSection(sectionId, closeModal);

    // @ts-ignore
    const error = deleteSection.error?.response?.data.errors;

    const deleteSectionAction = () => {
        deleteSection.mutate();
    };

    if (error) {
        return <DeleteSectionErrorContent closeModal={closeModal} error={error} />;
    } else {
        return (
            <DeleteSectionWarningContent
                closeModal={closeModal}
                deleteSection={deleteSectionAction}
            />
        );
    }
};

export default DeleteSectionContent;
