import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReportUserPreferencesResponse } from 'app/api/types/user';
import LoadingView from 'app/common/components/LoadingView';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import usePartnersConnected from 'app/common/hooks/queries/usePartnersConnected';
import { NotificationsPage } from 'app/pages/settings/Notifications';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';
import { SettingSaveButton } from 'app/pages/settingsV2/subComponents/SettingSaveButton/SettingSaveButton';

import { useUpdateUserReportPreferences, useUserReportPreferences } from './hooks';
import { formStateToReportUserPreferences } from './utils/formStateToUserPreferences';
import { reportUserPreferencesToFormState } from './utils/reportUserPreferencesToFormState';
import { NotificationsFormState } from './utils/types';

interface NotificationsFormProviderProps {
    userId: string;
    hasPartners: { hasTripAdvisor: boolean; hasFacebook: boolean; hasGoogle: boolean };
    reportPreferences: ReportUserPreferencesResponse;
    subscribedToRM: boolean;
}

const NotificationsFormProvider = ({
    userId,
    hasPartners,
    reportPreferences,
    subscribedToRM,
}: NotificationsFormProviderProps) => {
    const { t } = useTranslation();

    const [updateReportPreferences, mutation] = useUpdateUserReportPreferences();

    const form = useForm<NotificationsFormState>({
        defaultValues: reportUserPreferencesToFormState(reportPreferences, hasPartners),
    });
    useEffect(() => {
        form.reset(reportUserPreferencesToFormState(reportPreferences, hasPartners));
    }, [reportPreferences]);

    const handleSave = () => {
        updateReportPreferences({
            userId,
            payload: formStateToReportUserPreferences(form.getValues(), hasPartners),
        });
    };

    return (
        <FormProvider {...form}>
            <MenuListContentTemplate
                headerContent={
                    <DefaultMenuListHeader
                        title={t('notifications')}
                        description={t('rm_review_management_email_reports_description')}
                    />
                }
                footerContent={
                    !!subscribedToRM && (
                        <SettingSaveButton
                            onClick={handleSave}
                            isLoading={mutation.isLoading}
                            hasError={mutation.isError}
                            dataTrackId={'save_notificaton'}
                        />
                    )
                }
            >
                <MenuListContentContainer>
                    <NotificationsPage />
                </MenuListContentContainer>
            </MenuListContentTemplate>
        </FormProvider>
    );
};

export const Notifications = () => {
    const { data: me } = useMe();
    const { isLoading: subscriptionsLoading, subscribedToRM } = useBusinessSubscriptions();
    const hasPartners = usePartnersConnected();

    const [reportPreferences, { isLoading: preferencesLoading }] = useUserReportPreferences();
    const isLoading = preferencesLoading || subscriptionsLoading || !hasPartners.isFetched;

    if (!me || isLoading || !reportPreferences) {
        return <LoadingView />;
    }

    return (
        <NotificationsFormProvider
            userId={me.id}
            hasPartners={hasPartners}
            reportPreferences={reportPreferences}
            subscribedToRM={subscribedToRM}
        />
    );
};
