import { css } from '@emotion/react';
import styled from '@emotion/styled';

type ConsumerImageContainerProps = {
    size: number;
};

export const ConsumerImageContainer = styled.img`
    ${(props: ConsumerImageContainerProps) => css`
        width: ${props.size}px;
        height: ${props.size}px;
    `}
    object-fit: cover;
    border-radius: 50%;
`;
