import { NavigateFunction } from 'react-router-dom';

import { MESSAGING_CONVERSATION_STATS } from 'app/common/data/queryKeysConstants';

import {
    getNextActiveConversation,
    isConversationInRightAssignFilter,
} from 'app/reviewManagement/messaging/ConversationUtils';
import { CONVERSATIONS_MESSAGING_PATH } from 'app/routing/routeIds';
import { MESSAGE_CONTENT_TYPE, MessagingFilters, StatusFilterOption } from 'app/states/messaging';

import { StatusChangeData } from 'app/states/messaging/conversationUpdateTypes';
import queryClient from 'app/states/queryClient';

import { ConversationsData } from '../../hooks/useConversationUpdate';
import { addConversation, removeConversation } from '../../services/handleConversationsCache';
import { addNewMessage } from '../../services/handleMessagesCache';

export const handleConversationStatusChange = (
    statusChangeData: StatusChangeData,
    conversationsData: ConversationsData,
    filtersData: MessagingFilters,
    navigate: NavigateFunction,
    meId: string,
) => {
    const { conversation, status_message } = statusChangeData;
    const { conversations, activeConversation } = conversationsData;
    const { statusFilter, assignFilter } = filtersData;

    const isReopening = status_message.content_type === MESSAGE_CONTENT_TYPE.REOPENING;

    const conversationIndex = conversations.findIndex(
        loadedConversation => loadedConversation.id === conversation.id,
    );
    const hasConversation = conversationIndex !== -1;

    const shouldRemoveConversation =
        hasConversation &&
        ((isReopening && statusFilter == StatusFilterOption.CLOSED) ||
            (!isReopening && statusFilter === StatusFilterOption.OPEN));

    if (shouldRemoveConversation) {
        removeConversationStatusChange(statusChangeData, filtersData);

        const isOnActiveConversation = activeConversation?.id === conversation.id;

        if (isOnActiveConversation) {
            switchToNextActiveConversation(conversation.id, conversationsData, navigate);
        }
        return;
    }

    const shouldAddConversation =
        !hasConversation &&
        isConversationInRightAssignFilter(conversation, assignFilter, meId) &&
        ((isReopening && filtersData.statusFilter === StatusFilterOption.OPEN) ||
            (!isReopening && filtersData.statusFilter === StatusFilterOption.CLOSED));

    if (shouldAddConversation) {
        addConversationStatusChange(statusChangeData, filtersData);
    }
};

const removeConversationStatusChange = (
    { conversation, status_message }: StatusChangeData,
    { statusFilter, assignFilter, businessFilters }: MessagingFilters,
) => {
    addNewMessage(queryClient, conversation.id, status_message);
    removeConversation(conversation.id, statusFilter, assignFilter, businessFilters);
    queryClient.invalidateQueries([MESSAGING_CONVERSATION_STATS]);
};

const switchToNextActiveConversation = (
    conversationId: number,
    { conversations, activeConversation, setActiveConversation }: ConversationsData,
    navigate: NavigateFunction,
) => {
    const nextConversation = getNextActiveConversation(
        conversations,
        activeConversation,
        conversationId,
    );

    setActiveConversation(nextConversation);
    if (nextConversation)
        navigate(`${CONVERSATIONS_MESSAGING_PATH}?conversationId=${nextConversation.id}`);
};

const addConversationStatusChange = (
    { conversation, status_message }: StatusChangeData,
    { statusFilter, assignFilter, businessFilters }: MessagingFilters,
) => {
    addConversation(conversation, statusFilter, assignFilter, businessFilters);

    addNewMessage(queryClient, conversation.id, status_message);
    queryClient.invalidateQueries([MESSAGING_CONVERSATION_STATS]);
};
