import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { useDispatch } from 'react-redux';

import {
    SELECT_MODE,
    setFilterMode,
} from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { resetReviewFilterAction } from 'app/states/reviews/filters';

export const NoReviewsFound = () => {
    const dispatch = useDispatch();

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.ReviewManagementUnsubscribed,
    };

    return (
        <NotFoundTemplate
            show={true}
            title="no_reviews_found"
            subtitle="remove_filters_to_see_reviews"
            buttonText="see_all_reviews"
            imgSrc={illustrationElement}
            buttonStatus="outlined"
            handleClick={() => {
                dispatch(resetReviewFilterAction('all'));
                dispatch(setFilterMode(SELECT_MODE));
            }}
        />
    );
};
