import { SerializedStyles, Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { TransparencyEnum } from '@partoohub/branding';

import Clickable from 'app/common/components/buttons/Clickable';

export const textColor = (
    theme: Theme,
    isActive?: boolean,
    disabled?: boolean,
): SerializedStyles => {
    if (disabled) {
        if (isActive) {
            return css`
                background-color: ${theme.colors.primary.initial};
                color: white;
                border-color: ${theme.colors.primary.initial};
                opacity: 0.25 !important;

                &:last-child {
                    border-color: ${theme.colors.primary.initial};
                }
            `;
        }

        return css`
            opacity: ${TransparencyEnum.DISABLED};
            pointer-events: none !important;
        `;
    }

    if (isActive) {
        return css`
            background-color: ${theme.colors.primary.initial};
            color: white;
            border-color: ${theme.colors.primary.initial};

            &:last-child {
                border-color: ${theme.colors.primary.initial};
            }
        `;
    }

    return css``;
};

export const PairButtonContainer = styled(Clickable)<{ isActive?: boolean; disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: solid 1px #e6e8ed;
    border-right: none;
    padding: 4px;
    text-align: center;
    font-weight: bold;
    min-width: 100px;
    color: #7f8ea4;
    cursor: pointer;
    font-size: 12px;

    &:first-of-type {
        border-radius: 4px 0 0 4px;
    }

    &:last-child {
        border-radius: 0 4px 4px 0;
        border-right: solid 1px #e6e8ed;
    }
    ${({ theme, isActive, disabled }) => textColor(theme, isActive, disabled)};
`;

export const PairedButtonsContainer = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex-flow: row nowrap;
    height: 32px;

    @media (max-width: 1000px) {
        flex: 1;

        > * {
            flex: 1;
        }
    }
    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
        `}
`;
