import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import { TodoReviews } from 'app/pages/Homepage/sections/HomepageTodos/todos/TodoReviews';
import { Item } from 'app/pages/Homepage/utils/typing';

import { useIsAvailableTodoReviewsItem } from './useIsAvailableTodoReviewsItem';

export const useTodoReviewsItem = (): Item<SmallTodoProps> => {
    const isAvailable = useIsAvailableTodoReviewsItem();

    return {
        order: 2,
        isAvailable,
        component: TodoReviews,
        key: 'todo-reviews',
    };
};
