import { FocusEventHandler, MouseEventHandler } from 'react';

import { ImageContainer } from 'app/common/designSystem/components/molecules/ButtonWithSearchMenuFilterSelection/ButtonWithSearchMenuFilterSelection.styled';
import FLAG from 'app/common/flags.json';
import { LANGUAGES_MAP, LanguageCodeType } from 'app/common/services/languages';

type Props = {
    language: LanguageCodeType;
    onBlur?: FocusEventHandler;
    onClick?: MouseEventHandler;
};

export default function FlagSelectButton({
    language,
    onBlur = () => undefined,
    onClick = () => undefined,
}: Props) {
    return (
        <button
            type="button"
            onClick={onClick}
            onBlur={onBlur}
            style={{
                border: 'none',
                background: 'none',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <ImageContainer>
                <img
                    src={FLAG[LANGUAGES_MAP[language]?.country]}
                    alt={language}
                />
            </ImageContainer>
            <i className="color--secondary margin_left--half fas fa-caret-down" />
        </button>
    );
}
