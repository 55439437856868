import { Stack } from '@partoohub/ui';

import BusinessModalButton from 'app/common/components/businessModal';

import { DateFilter, TagFilter } from './Filters';

export const FilterSection = () => {
    return (
        <Stack direction="row" alignItems="center" gap="8px">
            <BusinessModalButton />
            <DateFilter />
            <TagFilter />
        </Stack>
    );
};
