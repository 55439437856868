import { createRouterReducer } from '@lagunovsky/redux-react-router';
import { combineReducers } from 'redux';

import filterByBusiness, {
    FilterByBusinessState,
} from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import confirmModal, { ConfirmState } from 'app/common/components/ConfirmModal/reducers/confirm';
import { actionReduxEffectReducer } from 'app/common/hooks/useReduxEffect';
import businessEditFields, {
    BusinessEditFieldsState,
} from 'app/common/reducers/businessEditFields';
import global, { GlobalState } from 'app/common/reducers/global';
import group, { GroupState } from 'app/common/reducers/groups';
import me, { MeState } from 'app/common/reducers/me';
import businesses, { BusinessesState } from 'app/common/reducers/newBusinesses';
import orgs, { OrgsState } from 'app/common/reducers/orgs';
import subscriptions, { SubscriptionsState } from 'app/common/reducers/subscriptions';
// COMMON reducers
import hideableComponents, { HideableComponentsState } from 'app/SDKBridge/reducers';
import { CommentsState } from 'app/states/comments';
import { errorReducer as error } from 'app/states/errors';
import { loadingReducer as loading } from 'app/states/loading';
// Hideable component reducers
// Knowledge connections reducers
import { RealtimeUpdatesState } from 'app/states/realtimeUpdates';
import { ReviewAnalyticsState } from 'app/states/reviewAnalytics';
import { ReviewsState } from 'app/states/reviews';

import comments from './comments/reducers/reducers';
import invitation, { InvitationState } from './connection/reducers/invitation';
import login, { LoginState } from './connection/reducers/login';
import lostPassword, { LostPasswordState } from './connection/reducers/lostPassword';
import resetPassword, { ResetPasswordState } from './connection/reducers/resetPassword';
import businessConnection, {
    BusinessConnectionState,
} from './knowledge/reducers/businessConnection';
import accounts, { AccountsState } from './knowledge/reducers/businessConnection/accounts';
import { PartnerConnectionState } from './knowledge/reducers/businessConnection/connection';
import { ConnectionsStatsState } from './knowledge/reducers/businessConnection/connectionsStats';
import facebook, { FacebookState } from './knowledge/reducers/businessConnection/facebook';
import { FacebookLocationsState } from './knowledge/reducers/businessConnection/facebook/facebookLocations';
import gmb, { GmbState } from './knowledge/reducers/businessConnection/gmb';
import { GMBLocationsState } from './knowledge/reducers/businessConnection/gmb/gmbLocations';
// KNOWLEDGE Business connection
// SETTINGS reducer

import {
    GooglePostListState,
    googlePostListReducer as googlePostList,
} from './presence/googlePost/googlePostList';
import publisherBusinesses, {
    PublisherBusinessesState,
} from './presence/reducers/listing/businessDetails/reducer';
import publishers, { PublishersState } from './presence/reducers/listing/publishers/reducer';
import publisherStates, { PublisherStatesState } from './presence/reducers/listing/publisherStates';
import pushNotifications, { PushNotificationsStateType } from './pushNotifs/reducers';
import realtimeUpdates from './realtimeUpdates/reducers/reducers';
import reviewAnalytics from './reviewAnalytics/reducers/reducers';
import reviews from './reviews/reducers/reducers';

export type AppState = {
    me: MeState;
    // Common reducers
    businesses: BusinessesState;
    subscriptions: SubscriptionsState;
    apiKeys: ApiKeysState;
    apiKey: ApiKeyState;
    confirmModal: ConfirmState;
    global: GlobalState;
    group: GroupState;
    filterByBusiness: FilterByBusinessState;
    error: any;
    loading: any;
    router: any;
    businessEditFields: BusinessEditFieldsState;
    orgs: OrgsState;
    // KNOWLEDGE reducers
    accounts: AccountsState;
    gmb: GmbState;
    facebook: FacebookState;
    businessConnection: BusinessConnectionState;
    // REVIEWS reducers
    reviews: ReviewsState;
    reviewAnalytics: ReviewAnalyticsState;
    comments: CommentsState;
    realtimeUpdates: RealtimeUpdatesState;
    // GOOGLE POST reducers
    googlePostList: GooglePostListState;
    // PRESENCE MANAGEMENT reducers
    publisherStates: PublisherStatesState;
    publishers: PublishersState;
    publisherBusinesses: PublisherBusinessesState;
    // CONNECTION reducers,
    login: LoginState;
    resetPassword: ResetPasswordState;
    lostPassword: LostPasswordState;
    invitation: InvitationState;
    // HIDEABLE components reducers
    hideableComponents: HideableComponentsState;
    // Business Messages reducers
    // Push Notifications reducers
    pushNotifications: PushNotificationsStateType;
};

export const groupStateSelector = (state: AppState): GroupState => state.group;

export const publishersSelector = (state: AppState): PublishersState => state.publishers;

export const confirmModalSelector = (state: AppState): any => state.confirmModal;

export const businessesSelector = (state: AppState): BusinessesState => state.businesses;

export const businessesEditFieldsSelector = (state: AppState): BusinessEditFieldsState =>
    state.businessEditFields;

export const meSelector = (state: AppState): MeState => state.me;

export const orgsSelector = (state: AppState): OrgsState => state.orgs;

export const connectionSelector = (state: AppState): PartnerConnectionState =>
    state.businessConnection.page;

export const connectionStatsSelector = (state: AppState): ConnectionsStatsState =>
    state.businessConnection.stats;

export const gmbLocationsSelector = (state: AppState): GMBLocationsState => state.gmb.locations;

export const facebookLocationsSelector = (state: AppState): FacebookLocationsState =>
    state.facebook.locations;

// SETTINGS selector
export const subscriptionsSelector = (state: AppState): SubscriptionsState => state.subscriptions;

export const publisherBusinessesStateSelector = (state: AppState): PublisherBusinessesState =>
    state.publisherBusinesses;

// CONNECTION selectors
export const loginSelector = (state: AppState): LoginState => state.login;

export const resetPasswordSelector = (state: AppState): ResetPasswordState => state.resetPassword;

export const lostPasswordSelector = (state: AppState): LostPasswordState => state.lostPassword;

export const pathNameSelector = (state: AppState): string => state.router.location.pathname;

export const invitationSelector = (state: AppState): InvitationState => state.invitation;

export default (history: any) => {
    const combinedReducers = {
        router: createRouterReducer(history),
        // Common reducers
        me,
        businesses,
        subscriptions,
        global,
        confirmModal,
        group,
        filterByBusiness,
        loading,
        businessEditFields,
        error,
        orgs,
        // KNOWLEDGE reducers
        accounts,
        gmb,
        facebook,
        businessConnection,
        // SETTINGS reducers,
        // REVIEWS reducers
        reviews,
        reviewAnalytics,
        comments,
        realtimeUpdates,
        // GOOGLE POST reducers
        googlePostList,
        // PRESENCE MANAGEMENT reducers
        publisherStates,
        publishers,
        publisherBusinesses,
        // CONNECTION reducers
        login,
        resetPassword,
        lostPassword,
        invitation,
        // HIDEABLE COMPONENTS reducers
        hideableComponents,
        // PUSH NOTIFICATIONS reducer
        pushNotifications,
        // useReduxEffect
        actionReduxEffectReducer,
    };

    const reducersSorted = Object.keys(combinedReducers).sort((a, b) => a.localeCompare(b));

    return combineReducers(
        reducersSorted.reduce((acc, c) => {
            acc[c] = combinedReducers[c];
            return acc;
        }, {}),
    );
};
