import { useEffect, useState } from 'react';

import { Button, Modal, TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { FoodMenuSectionType } from 'app/api/types/food_menu';
import StrikeTroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';

import {
    CancelButton,
    ModalContent,
    ModalSubtitle,
    ModalTitle,
    ModalWrapper,
} from './modal.styled';

type Props = {
    show: boolean;
    handleClose: () => void;
    section: FoodMenuSectionType;
    sections: Array<FoodMenuSectionType>;
    setSections: (sections: Array<FoodMenuSectionType>) => void;
};

const EditSectionModal = ({ show, handleClose, section, sections, setSections }: Props) => {
    const [name, setName] = useState<string>(section.name);
    const { t } = useTranslation();

    useEffect(() => {
        if (show) {
            setName(section.name);
        }
    }, [show]);

    const onApply = () => {
        const sectionsToUpdate = [...sections];
        const index = sectionsToUpdate.findIndex(obj => obj.order === section.order);
        sectionsToUpdate[index] = {
            name,
            order: sectionsToUpdate[index].order,
            items: sectionsToUpdate[index].items,
        } as FoodMenuSectionType;
        setSections([...sectionsToUpdate]);
        handleClose();
    };

    const isFormInvalid = (): boolean =>
        name.length === 0 || name.length > 140 || name?.replace(/\s/g, '').length === 0;

    return (
        <Modal isOpen={show} closeModal={handleClose}>
            <ModalWrapper>
                <ModalContent>
                    <ModalTitle>{t('edit_food_menu_section_name_modal_title')}</ModalTitle>
                    <ModalSubtitle>{t('edit_food_menu_section_name_modal_subtitle')}</ModalSubtitle>
                    <TextInput
                        dataTrackId="food_menu_section_name"
                        label={t('food_menu_section_name')}
                        required
                        value={name}
                        onChange={value => setName(value || '')}
                        characterCount={140}
                    />
                </ModalContent>

                <CancelButton>
                    <StrikeTroughButton
                        text={t('cancel')}
                        color="secondary"
                        onClick={handleClose}
                    />
                </CancelButton>
                <Button
                    dataTrackId="edit_section_menu__button"
                    full
                    size="large"
                    shape="cube"
                    variant="primary"
                    disabled={isFormInvalid()}
                    onClick={onApply}
                >
                    {t('validate')}
                </Button>
            </ModalWrapper>
        </Modal>
    );
};

export default EditSectionModal;
