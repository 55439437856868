import { Drawer } from '@partoohub/ui';

import { BUSINESS_MODAL_DRAWER_CSS } from 'app/common/components/businessModal/components/BusinessModal/BusinessModal.styled';
import { useHideIntercom } from 'app/hooks/useHideIntercom';

import BusinessOldGroupsContent from './BusinessOldGroupsContent';

type Props = {
    opened: boolean;
    closeModal: () => void;
    erase: () => void;
};

const BusinessOldGroupsModal = ({ opened, closeModal, erase }: Props) => {
    // Hide intercom when drawer is open
    useHideIntercom(opened);

    return (
        <Drawer
            dataTrackId="old-group-modal"
            isOpen={opened}
            onHide={closeModal}
            cssContainer={BUSINESS_MODAL_DRAWER_CSS}
        >
            <BusinessOldGroupsContent closeModal={closeModal} erase={erase} />
        </Drawer>
    );
};

export default BusinessOldGroupsModal;
