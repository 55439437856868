import React, { useContext } from 'react';

import { useLocation } from 'react-router-dom';

import { ScrollSpyClickContainer } from './ScrollSpyClick.styled';
import { ScrollSpyContext } from '../ScrollSpyProvider';

type Props = {
    children: React.ReactNode;
    idScrollTo: string;
    defaultActiveId?: string; // default id active if no anchor in the url
    classIsActive?: string;
};

/**
 * On click, scroll to a component registered by the ScrollSpyProvider
 */
const ScrollSpyClick = ({
    children,
    idScrollTo,
    classIsActive = 'selected',
    defaultActiveId,
}: Props) => {
    const location = useLocation();
    const { refs, scrollToRef } = useContext(ScrollSpyContext);
    let isActive = defaultActiveId === idScrollTo;
    if (location.hash) {
        isActive = decodeURIComponent(location.hash) === `#${idScrollTo}`;
    }

    return (
        <ScrollSpyClickContainer
            className={isActive ? classIsActive : ''}
            onClick={e => scrollToRef(e, refs.get(idScrollTo))}
        >
            {children}
        </ScrollSpyClickContainer>
    );
};

export default ScrollSpyClick;
