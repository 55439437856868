import { FeedbackFormFieldType } from 'app/api/types/feedbackResults';

import { FeedbackResultLinearScaleField } from './FeedbackResultLinearScaleField';
import { FeedbackResultRatingField } from './FeedbackResultRatingField';
import { FeedbackResultTextAreaField } from './FeedbackResultTextAreaField';

interface Props {
    field: {
        type: FeedbackFormFieldType;
        question: string;
        rating: number | null;
        content: string | null;
    };
}

export const FeedbackResultField = ({ field }: Props) => {
    const { type, question, rating, content } = field;

    const Field = {
        [FeedbackFormFieldType.CSAT]: FeedbackResultRatingField,
        [FeedbackFormFieldType.FREE_FORM]: FeedbackResultTextAreaField,
        [FeedbackFormFieldType.NPS]: FeedbackResultLinearScaleField,
    }[type];

    return <Field rating={rating} content={content} question={question} />;
};
