import { useContext, useEffect } from 'react';

import camelcasify from 'camelcase-keys';

import { WebsocketConnectionContext } from '../contexts/websocketConnection';

interface Options {
    /**
     * @deprecated
     * camelsification of websocket results is not recommended
     * */
    camel: boolean;
}

const useWebsocket = <T>(
    type: string,
    cb: (message: T) => void,
    dependencies: any[] = [],
    options?: Options,
) => {
    const { camel } = options || {};
    const websocket = useContext(WebsocketConnectionContext);

    // Add a listener on the websocket object when the hook is mounted.
    // Remove the listener when the hook is unmounted.
    useEffect(() => {
        if (websocket === null) {
            return;
        }

        const listener = (event: MessageEvent) => {
            const message = JSON.parse(event.data);
            if (message.type === type) {
                const data = camel ? camelcasify(message.data, { deep: true }) : message.data;
                cb(data);
            }
        };

        websocket.addEventListener('message', listener);

        return () => websocket.removeEventListener('message', listener);
    }, [websocket, ...dependencies]);
};

export default useWebsocket;
