export const GOOGLE_MY_BUSINESS = 'google_my_business';
export const JUSTACOTE = 'justacote';
export const LE_118K = 'le118000';
export const HOODSPOT = 'hoodspot';
export const FACEBOOK = 'facebook';
export const BING_GLOBAL = 'bing_global';
export const APPLE_BUSINESS_CONNECT = 'apple_business_connect';

export const DIRECT_PUBLISHERS = [
    GOOGLE_MY_BUSINESS,
    FACEBOOK,
    JUSTACOTE,
    LE_118K,
    HOODSPOT,
    BING_GLOBAL,
    APPLE_BUSINESS_CONNECT,
];

export const PUBLISHERS_TEXT: { [key: string]: string } = {
    google_my_business: 'google_my_business',
    justacote: 'justacote',
    le118000: 'le118000',
    hoodspot: 'hoodspot',
    facebook: 'facebook',
    bing_global: 'bing_global',
    apple_business_connect: 'apple_business_connect',
};
