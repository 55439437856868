import React from 'react';

import { Switch } from '@partoohub/ui';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    disabled?: boolean;
};

const ControlledToggle = ({ name, control, disabled }: ControlledComponent) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return (
                    <Switch
                        name={name}
                        dataTrackId={name}
                        disabled={disabled}
                        checked={value}
                        onChange={() => onChange(!value)}
                    />
                );
            }}
        />
    );
};

export default ControlledToggle;
