import styled from '@emotion/styled';
import { whiteColorObject } from '@partoohub/branding';

export const SettingSkeletonContainer = styled.div<{ isBelowTablet: boolean }>`
    display: flex;
    flex-direction: ${({ isBelowTablet }) => (isBelowTablet ? 'column' : 'row')};
    gap: 24px;
`;

export const MenuListContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.theme.background};
    height: 100%;
`;

export const ScrollToBottomContainer = styled.div`
    position: absolute;
    z-index: 101;
    bottom: 96px;
    right: 24px;
    border-radius: 50%;
    backdrop-filter: blur(5px) brightness(1.2);
    box-shadow: ${({ theme }) => theme.shadows.block};
    background-color: ${whiteColorObject['50']};
`;
