import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';
import { Button, Stack } from '@partoohub/ui';

import { STATIC_BASE_URL } from 'app/config';

export const ModalContentContainer = styled(Stack)`
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 100%;
    padding: 24px;
    background-image: url(${STATIC_BASE_URL}/images/app/auto_reply_activation_success_background.gif);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 16px;
`;

export const ModalImage = styled.img`
    width: 100%;
    border-radius: 8px;
    margin-bottom: 36px;
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    padding: 150px 45px 0;
`;

export const StyledButton = styled(Button)`
    margin-top: 16px;

    /* TODO: Add variant in the DS */
    background-color: ${HEX_COLORS.white};
    &:hover {
        background: ${({ theme }) => theme.colors.default.initial};
        div {
            color: ${HEX_COLORS.white} !important;
        }
    }
`;
