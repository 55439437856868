import { FC, useEffect, useState } from 'react';

import { greyColorObject } from '@partoohub/branding';
import { useTranslation } from 'react-i18next';

import { EyeSlashIcon } from 'app/reviewManagement/common/icon/activeConversation/eyeSlash';
import {
    MessageBubbleImageStyled,
    MessageBubbleLoaderStyled,
    MessageBubbleVideoStyled,
    StoryStateHeaderStyledWrapper,
    StoryUnavailableHeader,
} from 'app/reviewManagement/messaging/activeConversation/messages/MessageBubble.styled';
import IMAGES from 'app/styles/constants/images';

interface MediaBubbleProps {
    url: string;
    isConsumerMessage: boolean;
    onClick: () => void;
    isIgStory?: boolean;
}

const MediaBubble: FC<MediaBubbleProps> = ({ url, isConsumerMessage, isIgStory, onClick }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [contentType, setContentType] = useState<string | null>(null);
    const [brokenLink, setBrokenLink] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        setBrokenLink(false);
        const inspectUrl = async () => {
            const response = await fetch(url, { method: 'HEAD' });
            if (response.status != 200) {
                setBrokenLink(true);
                throw new Error('Invalid Response Status');
            }
            const typeValue = response.headers.get('content-type') ?? '';
            if (typeValue.startsWith('video/')) {
                setContentType('video');
            } else if (typeValue.startsWith('image/')) {
                setContentType('image');
            }
        };
        inspectUrl()
            .then(() => setLoading(false))
            .catch(() => {
                // Apparently can also happen if this is an instagram story image url...
                // In this case the cors request fails. So we will try to render it as
                // an image (which will determine if it's a broken image link).
                setLoading(false);
                setContentType(null);
            });
    }, [url]);

    if (loading) {
        return <MessageBubbleLoaderStyled isConsumer={isConsumerMessage} />;
    }

    if (contentType === 'video') {
        return (
            <MessageBubbleVideoStyled
                controls
                src={url}
                preload="metadata"
                isConsumer={isConsumerMessage}
                onClick={onClick}
            />
        );
    }

    if (isIgStory && brokenLink) {
        return (
            <StoryStateHeaderStyledWrapper>
                <EyeSlashIcon />
                <StoryUnavailableHeader
                    variant="bodySRegular"
                    as="span"
                    color={greyColorObject.initial}
                >
                    {t('messaging_meta_story_expired_placeholder')}
                </StoryUnavailableHeader>
            </StoryStateHeaderStyledWrapper>
        );
    }

    return (
        <MessageBubbleImageStyled
            src={brokenLink ? IMAGES.brokenLink : url}
            onError={e => {
                // @ts-ignore
                e.target.onerror = null;
                setBrokenLink(true);
            }}
            loading="lazy"
            isConsumer={isConsumerMessage}
            onClick={onClick}
        />
    );
};

export default MediaBubble;
