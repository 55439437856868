import { connect } from 'react-redux';

import {
    FilterMode,
    SELECT_MODE,
    selectedPendingBusinessCount,
    setFilterMode,
} from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import Chip from 'app/common/components/Chip';
import {
    isFetchingSelector,
    setBusinessOrgIdFilter,
    totalBusinessCountSelector,
    updateSearchQuery,
    updateSelectedCity,
} from 'app/common/reducers/newBusinesses';

type OwnProps = {
    active: boolean;
    translationParams?: Record<string, any>;
    text: string;
    handleClick: () => void;
    loading: boolean;
    isMobile: boolean;
};

const mapStateToProps = (
    { filterByBusiness, businesses },
    { isMobile }: OwnProps,
) => {
    const total = totalBusinessCountSelector(businesses);
    const pendingCount = selectedPendingBusinessCount(filterByBusiness, total);
    // case when click unselect all button it's reset the state of filterByBusiness
    // so selectedPendingBusinessCount return count of all businesses
    const selected = pendingCount > total ? 0 : pendingCount;
    return {
        loading: isFetchingSelector(filterByBusiness),
        text: isMobile
            ? 'nb_businesses_selected_mobile'
            : 'nb_businesses_selected_plural',
        translationParams: {
            total,
            selected,
        },
        active: !!selected,
        hide: !(selected || !isMobile),
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    handleClick: (mode: FilterMode) => {
        dispatch(setFilterMode(mode));
        dispatch(updateSearchQuery(''));
        dispatch(updateSelectedCity(''));
        dispatch(setBusinessOrgIdFilter(null));
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    handleClick: () =>
        stateProps.active ? dispatchProps.handleClick(SELECT_MODE) : null,
});

const FilterByBusinessChip = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(Chip);

export default FilterByBusinessChip;
