import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const PopInContainer = styled.div<{ placeArrowAtEnd: boolean }>`
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.default.initial};
    margin-bottom: 16px;
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        width: 14.77px;
        height: 14.77px;
        border-radius: 2px;
        transform: matrix(-0.72, 0.72, -0.67, -0.72, 0, 0);
        bottom: 13px;
        background-color: ${({ theme }) => theme.colors.default.initial};
    }
    ${({ placeArrowAtEnd = true }) =>
        placeArrowAtEnd &&
        css`
            &:before {
                right: 13px;
            }
        `}
`;

export const PopInContent = styled.div`
    max-width: 240px;
    overflow-wrap: break-word;
    z-index: 2;
`;

export const PopInIcon = styled.div`
    i {
        color: ${HEX_COLORS.white};
        &:hover {
            color: ${HEX_COLORS.white};
        }
    }
`;
