import React, { useEffect, useState } from 'react';

import {
    Arrow,
    CarouselWrapper,
    Control,
    ControlDot,
    GradientRight,
    Slide,
    SlidesContainer,
} from './Carousel.styled';
import { ArrowDirection, ArrowPosition, createSlidesFromChildren } from './helpers';

type CarouselProps = {
    showArrows?: boolean;
    showDots?: boolean;
    showGradient?: boolean;
    infiniteScrolling?: boolean;
    automaticScrolling?: boolean;
    timeBetweenSlidesInMs?: number;
    slideAnimationTimeInMs?: number;
    arrowPosition?: ArrowPosition;
    slideWidth?: string;
    children: React.ReactElement[];
    nbOfSlideDisplayed?: number;
};

enum SlidingDirection {
    LEFT = -1,
    RIGHT = 1,
}

const UnguardedCarousel = ({
    showArrows = true,
    showDots = true,
    showGradient = false,
    infiniteScrolling = true,
    automaticScrolling = false,
    timeBetweenSlidesInMs = 500,
    slideAnimationTimeInMs = 500,
    arrowPosition = 'middle',
    slideWidth = '100%',
    nbOfSlideDisplayed = 1,
    children,
}: CarouselProps) => {
    const startingKey = infiniteScrolling ? 1 : 0; // Start at 1 because the slide at index 0 is a copy of the last slide
    const endingKeyWithoutInfiniteScrolling =
        slideWidth === '100%'
            ? children.length - nbOfSlideDisplayed
            : children.length - nbOfSlideDisplayed + 1;
    const endingKey = infiniteScrolling ? children.length : endingKeyWithoutInfiniteScrolling;

    const [slides, setSlides] = useState(createSlidesFromChildren(children, infiniteScrolling));
    const [currentIndex, setCurrentIndex] = useState(startingKey);
    const [isSliding, setIsSliding] = useState(false);
    const [slidingDirection, setSlidingDirection] = useState<SlidingDirection>(
        SlidingDirection.RIGHT,
    );

    const checkIfIsOutbound = (newIndex: number) => newIndex < startingKey || newIndex > endingKey;

    const changeSlide = (direction: SlidingDirection) => {
        // Prevent slide change if carousel is already sliding
        if (isSliding) return;

        setIsSliding(true);

        const newIndex = currentIndex + direction;

        if (checkIfIsOutbound(newIndex) && !infiniteScrolling) {
            return;
        }

        setCurrentIndex(newIndex);
    };

    const goToPreviousSlide = () => {
        changeSlide(SlidingDirection.LEFT);
    };

    const goToNextSlide = () => {
        changeSlide(SlidingDirection.RIGHT);
    };

    const goToSlide = (index: number) => {
        if (checkIfIsOutbound(index)) return;

        setIsSliding(true);
        setCurrentIndex(index);
    };

    const automaticSliding = () => {
        setCurrentIndex(prevCurrentIndex => {
            if (!automaticScrolling && (showArrows || showDots)) {
                return prevCurrentIndex;
            }

            setIsSliding(true);

            const nextIndex = prevCurrentIndex + slidingDirection;

            if (checkIfIsOutbound(nextIndex)) {
                if (infiniteScrolling) {
                    // Keep going, direction won't ever change in infinite scrolling
                    return nextIndex;
                }

                const newDirection = -slidingDirection;
                setSlidingDirection(newDirection);

                // Go back to the new direction, until the next time we hit the carousel boundaries
                return prevCurrentIndex + newDirection;
            }

            return nextIndex;
        });
    };

    /**
     * This ensure the carousel will go back on the correct tracks
     * after going out of bounds, ie., when hitting the "fake" first
     * and last slides.
     */
    const clearAfterSlide = () => {
        setIsSliding(false);

        if (currentIndex > endingKey) {
            setCurrentIndex(startingKey);
        } else if (currentIndex < startingKey) {
            setCurrentIndex(endingKey);
        }
    };

    const isArrowVisible = (arrowDirection: ArrowDirection) => {
        if (infiniteScrolling) {
            return true;
        }

        if (arrowDirection === 'left') {
            return currentIndex > startingKey;
        }

        return currentIndex < endingKey;
    };

    const isDotActive = (dotIndex: number) => {
        if (checkIfIsOutbound(currentIndex)) {
            // Tricky case #1: we are on the last slide, which is really a copy of the first one
            if (currentIndex === endingKey + 1) {
                return dotIndex === startingKey;
            }

            // Tricky case #2: we are on the first slide, which is really a copy of the last one
            return dotIndex === endingKey;
        }

        // The most intuitive case
        return dotIndex === currentIndex;
    };

    useEffect(() => {
        if (!isSliding) return;

        setTimeout(clearAfterSlide, slideAnimationTimeInMs);
    }, [isSliding]);

    useEffect(() => {
        setSlides(createSlidesFromChildren(children, infiniteScrolling));
    }, [children]);

    useEffect(() => {
        const interval = setInterval(
            automaticSliding,
            timeBetweenSlidesInMs + slideAnimationTimeInMs,
        );

        return () => clearInterval(interval);
    });

    const transition = () => {
        const width = slideWidth.split(/(?=px|%)/g);
        return -(currentIndex * Number(width[0])) + width[1];
    };

    return (
        <CarouselWrapper>
            <SlidesContainer
                position={transition()}
                isSliding={isSliding}
                slideAnimationTimeInMs={slideAnimationTimeInMs}
            >
                {slides.map((slide, index) => (
                    <Slide key={index} width={slideWidth}>
                        {slide}
                    </Slide>
                ))}
            </SlidesContainer>
            {showGradient && <GradientRight />}
            {showArrows &&
                ['left', 'right'].map((arrowDirection: ArrowDirection) => (
                    <Arrow
                        onClick={arrowDirection === 'left' ? goToPreviousSlide : goToNextSlide}
                        role="button"
                        direction={arrowDirection}
                        position={arrowPosition}
                        key={arrowDirection}
                        isVisible={isArrowVisible(arrowDirection)}
                    >
                        <i className={`fas fa-arrow-${arrowDirection}`} />
                    </Arrow>
                ))}
            {showDots && (
                <Control>
                    {}
                    {children.map((_, index) => (
                        <ControlDot
                            title={`#${index}`}
                            role="button"
                            key={`carousel_control_${index}`}
                            isActive={isDotActive(infiniteScrolling ? index + 1 : index)} // Add 1 to account for the fake slides
                            onClick={() => goToSlide(infiniteScrolling ? index + 1 : index)} // Add 1 to account for the fake slides
                        />
                    ))}
                </Control>
            )}
        </CarouselWrapper>
    );
};

const Carousel = (props: CarouselProps) => {
    if (!props.children || props.nbOfSlideDisplayed === 0) {
        return null;
    }

    // There is no point in a carousel with only one slide, but at least it won't break
    if (!Array.isArray(props.children)) {
        return (
            <CarouselWrapper>
                <SlidesContainer position={'0'} slideAnimationTimeInMs={0}>
                    <Slide width={'100%'}>{props.children}</Slide>
                </SlidesContainer>
            </CarouselWrapper>
        );
    }

    return <UnguardedCarousel {...props} />;
};

export default Carousel;
