import React from 'react';

import { SpecificHourFormPeriod } from 'app/api/types/business';
import { isOpenAllDay } from 'app/businessEditV2/utils/specificHours';

import { HoursContentContainer } from 'app/common/components/OpenHour/OpenHour.styled';

import HoursAllDay from './HoursAllDay';
import HoursList from './HoursList';

type Props = {
    disabled: boolean;
    specificHour: SpecificHourFormPeriod;
    hoursError: string;
    onChange: (specificHour: SpecificHourFormPeriod) => void;
};

const HoursContent = ({
    disabled,
    specificHour,
    hoursError,
    onChange,
}: Props) => {
    const onTimeWindowChange = (modifiedOpenHours: Array<string>) => {
        const result: SpecificHourFormPeriod = { ...specificHour };
        result.open_hours = modifiedOpenHours;
        onChange(result);
    };

    return (
        <HoursContentContainer>
            {!isOpenAllDay(specificHour.open_hours) ? (
                <HoursList
                    disabled={disabled}
                    openHours={specificHour.open_hours}
                    onTimeWindowChange={onTimeWindowChange}
                    hoursError={hoursError}
                />
            ) : (
                <HoursAllDay />
            )}
        </HoursContentContainer>
    );
};

export default HoursContent;
