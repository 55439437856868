import { omit } from 'lodash-es';

import qs from 'query-string';
import { createSelector } from 'reselect';

import { ReviewFilters } from 'app/api/types/review';
import { ReactDatesType } from 'app/common/data/datetime';

import * as constants from '../actions/constants';
import { LIVE_REVIEWS, REVIEW_STATUSES } from '../actions/constants';
import { ReviewFiltersState } from '../reducers/types';

export const reviewFiltersSelector = (
    state: any, // TODO: Fix typing
): ReviewFiltersState => state.reviews.filters;

export const dateFilterSelector = ({
    pending: r,
}: ReviewFiltersState): ReactDatesType => r.date;

export const tagFilterPendingSelector = ({
    pending: { tag },
}: ReviewFiltersState): Array<number> => tag;

export const tagFilterSelector = ({ tag }: ReviewFiltersState): Array<number> =>
    tag;

export const keywordFilterSelector = ({
    keyword,
}: ReviewFiltersState): Array<string> => keyword;

export const keywordRegexFilterSelector: (
    arg0: ReviewFiltersState,
) => Array<RegExp> = createSelector(keywordFilterSelector, keywords =>
    keywords.map(keyword => new RegExp(`\\b${keyword}\\b`)),
);

export const activeReviewFiltersSelector = ({
    date: { from, to },
    platform,
    message,
    keyword,
    rating,
    tag,
}: ReviewFiltersState): ReviewFilters => {
    const activeReviewFilters: any = {};

    if (from) activeReviewFilters.dateFrom = from;
    if (to) activeReviewFilters.dateTo = to;
    if (platform.length) activeReviewFilters.platform = platform;
    if (message.length && message.length !== 2)
        activeReviewFilters.message = message;
    if (keyword.length) activeReviewFilters.keyword = keyword;
    if (tag.length) activeReviewFilters.tag = tag;
    if (rating.length) activeReviewFilters.rating = rating;
    activeReviewFilters.reviewState = navTabSelector();
    return activeReviewFilters;
};

export const activeToolbarReviewFiltersSelector = (
    r: ReviewFiltersState,
): ReviewFilters =>
    // @ts-ignore
    omit(activeReviewFiltersSelector(r), 'reviewState');

export const keywordsQuerySelector = ({
    keyword,
}: ReviewFiltersState): string | null =>
    keyword.length ? keyword.join(',') : null;

export const tagQuerySelector = ({ tag }: ReviewFiltersState): string | null =>
    tag.length ? tag.join(',') : null;

export const pendingReviewFormSelector = (r: ReviewFiltersState) =>
    omit(r.pending, ['date.focusedInput']);

export const currentReviewFormSelector = (r: ReviewFiltersState) =>
    omit(r, ['pending', 'date.focusedInput']);

// HOURS SELECTORS
export const navTabSelector = (): constants.NavValue => {
    // TODO:
    //  - make this selector have global state as single argument
    //  - use state.router.location to access current location
    //  - remove window.location patch in its unit tests
    const { location } = window;
    const { status } = qs.parse(location.search);

    // @ts-ignore
    if (!REVIEW_STATUSES.includes(status)) {
        return LIVE_REVIEWS;
    }

    // @ts-ignore
    return status;
};

export const navTabPendingSelector = ({
    pending: { navTab },
}: ReviewFiltersState): constants.NavValue => navTab;

export const displaySearchBarSelector = ({
    displaySearchBar,
}: ReviewFiltersState): boolean => displaySearchBar;

// MOBILE
export const showReviewFilterModalSelector = ({
    mobileShow,
}: ReviewFiltersState): boolean => mobileShow;
