import { isEqual } from 'lodash-es';

import {
    LOGOUT_RESET_REDUCERS_DATA,
    LogoutResetReducersDataAction,
} from './login';

// ACTION TYPES
const UPDATE_LOST_PASSWORD_EMAIL = 'UPDATE_LOST_PASSWORD_EMAIL';
export const LOST_PASSWORD_REQUEST = 'LOST_PASSWORD_REQUEST';
const LOST_PASSWORD_REQUEST_FAILURE = 'LOST_PASSWORD_REQUEST_FAILURE';
const LOST_PASSWORD_REQUEST_SUCCESS = 'LOST_PASSWORD_REQUEST_SUCCESS';
const RESET_LOST_PASSWORD_FORM = 'RESET_LOST_PASSWORD_FORM';
// ACTION FLOW TYPES
type UpdateLostPasswordEmailAction = {
    type: 'UPDATE_LOST_PASSWORD_EMAIL';
    email: string;
};
export type LostPasswordRequestAction = {
    type: 'LOST_PASSWORD_REQUEST';
};
type LostPasswordRequestFailureAction = {
    type: 'LOST_PASSWORD_REQUEST_FAILURE';
    errors: Record<string, any>;
};
type LostPasswordRequestSuccessAction = {
    type: 'LOST_PASSWORD_REQUEST_SUCCESS';
};
type ResetLostPasswordFormAction = {
    type: 'RESET_LOST_PASSWORD_FORM';
};
export const updateLostPasswordEmail = (
    email: string,
): UpdateLostPasswordEmailAction => ({
    type: UPDATE_LOST_PASSWORD_EMAIL,
    email,
});
export const lostPasswordRequest = (): LostPasswordRequestAction => ({
    type: LOST_PASSWORD_REQUEST,
});
export const lostPasswordRequestFailure = (
    errors: Record<string, any>,
): LostPasswordRequestFailureAction => ({
    type: LOST_PASSWORD_REQUEST_FAILURE,
    errors,
});
export const lostPasswordRequestSuccess =
    (): LostPasswordRequestSuccessAction => ({
        type: LOST_PASSWORD_REQUEST_SUCCESS,
    });
export const resetLostPasswordForm = (): ResetLostPasswordFormAction => ({
    type: RESET_LOST_PASSWORD_FORM,
});
export type LostPasswordState = {
    email: string;
    errors: Record<string, any>;
    isSubmitting: boolean;
    isSuccess: boolean;
};
type LostPasswordAction =
    | UpdateLostPasswordEmailAction
    | LostPasswordRequestAction
    | LostPasswordRequestFailureAction
    | LostPasswordRequestSuccessAction
    | ResetLostPasswordFormAction
    | LogoutResetReducersDataAction;
const initialState = {
    email: '',
    errors: {},
    isSubmitting: false,
    isSuccess: false,
};

const lostPasswordReducer = (
    state: LostPasswordState = initialState,
    action: LostPasswordAction,
): LostPasswordState => {
    switch (action.type) {
        case UPDATE_LOST_PASSWORD_EMAIL:
            return { ...state, email: action.email };

        case LOST_PASSWORD_REQUEST:
            return { ...state, isSubmitting: true, errors: {} };

        case LOST_PASSWORD_REQUEST_FAILURE:
            return { ...state, isSubmitting: false, errors: action.errors };

        case LOST_PASSWORD_REQUEST_SUCCESS:
            return { ...state, isSubmitting: false, isSuccess: true };

        case LOGOUT_RESET_REDUCERS_DATA:
        case RESET_LOST_PASSWORD_FORM:
            return { ...initialState };

        default:
            return state;
    }
};

export const emailSelector = ({ email }: LostPasswordState): string => email;
export const isSubmittingSelector = ({
    isSubmitting,
}: LostPasswordState): boolean => isSubmitting;
export const isSuccessSelector = ({ isSuccess }: LostPasswordState): boolean =>
    isSuccess;
export const hasErrors = ({ errors }: LostPasswordState): boolean =>
    !isEqual({}, errors);
export const emailErrorSelector = ({
    errors: { email },
}: LostPasswordState): string | void => email;
export default lostPasswordReducer;
