import { URL_SEARCH_PARAMS } from 'app/businessEditV2/oldFromEditV1/data/constants';
import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

const onBusinessRedirectClick = (
    businessId: string,
    publisherName: string,
): void => {
    sdkBridge.linkFunctionToEvent(
        () =>
            window.open(
                `${VISIBILITY_LOCATION_PATH}/${businessId}?${URL_SEARCH_PARAMS.DISPLAY_PUBLISHER_ERROR}=${publisherName}`,
                '_blank',
            ),
        PARTOO_APP_EVENT_IDS.PM_GO_TO_EDIT_CLICK_EVENT,
        true,
    )();
};

export default onBusinessRedirectClick;
