import { HEX_COLORS } from '@partoohub/branding';
import { Chip, Icon, Stack, Text, theme } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { PageHeaderStepCategory } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/data/funnelSteps';
import { useFunnelModalContext } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/hooks/FunnelModalContext';

import { HeaderContainer, HeaderIconWrapper } from './FunnelPageHeader.styled';

export const FunnelPageHeader = () => {
    const { t } = useTranslation();
    const { currentStepInfo } = useFunnelModalContext();

    const isActive = (step: PageHeaderStepCategory) => {
        return step === currentStepInfo.stepCategory;
    };

    return (
        <HeaderContainer direction="row" justifyContent="space-between" alignItems="center">
            <Stack gap="8px" direction="row" alignItems="center">
                <HeaderIconWrapper>
                    <Icon
                        color={HEX_COLORS.blackberry}
                        icon={currentStepInfo.icon}
                        iconSize="10px"
                    />
                </HeaderIconWrapper>
                <Text as="span" variant="heading6">
                    {t(currentStepInfo.pageTitle)}
                </Text>
            </Stack>
            <Stack gap="8px" direction="row" alignItems="center">
                <Chip
                    dataTrackId=""
                    chipShape="cube"
                    variant={isActive(PageHeaderStepCategory.INFORMATION) ? 'primary' : 'info'}
                    disabled={!isActive(PageHeaderStepCategory.INFORMATION)}
                >
                    <Text
                        as="span"
                        variant="bodyMBold"
                        color={isActive(PageHeaderStepCategory.INFORMATION) ? 'primary' : 'default'}
                    >
                        {t('bulk_in_app_funnel__step_type__information')}
                    </Text>
                </Chip>
                <Icon icon={['fa-chevron-right']} color={theme.colors.secondary.initial} />
                <Chip
                    dataTrackId=""
                    chipShape="cube"
                    variant={isActive(PageHeaderStepCategory.MODIFICATION) ? 'primary' : 'info'}
                    disabled={!isActive(PageHeaderStepCategory.MODIFICATION)}
                >
                    <Text
                        as="span"
                        variant="bodyMBold"
                        color={
                            isActive(PageHeaderStepCategory.MODIFICATION) ? 'primary' : 'default'
                        }
                    >
                        {t('bulk_in_app_funnel__step_type__modification')}
                    </Text>
                </Chip>
            </Stack>
        </HeaderContainer>
    );
};
