/**
 * Action constants.
 */
export const SHOW_PUSH_NOTIFICATIONS_OPT_IN_PROMPT =
    'SHOW_PUSH_NOTIFICATIONS_OPT_IN_PROMPT';
export const HIDE_PUSH_NOTIFICATIONS_OPT_IN_PROMPT =
    'HIDE_PUSH_NOTIFICATIONS_OPT_IN_PROMPT';
export const MAYBE_SHOW_PUSH_NOTIFICATIONS_OPT_IN_PROMPT =
    'MAYBE_SHOW_PUSH_NOTIFICATIONS_OPT_IN_PROMPT';

/**
 * Action types.
 */
export type ShowPushNotificationsOptInPromptAction = {
    type: typeof SHOW_PUSH_NOTIFICATIONS_OPT_IN_PROMPT;
};
export type HidePushNotificationsOptInPromptAction = {
    type: typeof HIDE_PUSH_NOTIFICATIONS_OPT_IN_PROMPT;
};
export type MaybeShowPushNotificationsOptInPromptAction = {
    type: typeof MAYBE_SHOW_PUSH_NOTIFICATIONS_OPT_IN_PROMPT;
};

export type PushNotificationsOptInPromptActionType =
    | ShowPushNotificationsOptInPromptAction
    | HidePushNotificationsOptInPromptAction
    | MaybeShowPushNotificationsOptInPromptAction;

/**
 * Action creators.
 */
export const showPushNotificationsOptInPrompt =
    (): ShowPushNotificationsOptInPromptAction => ({
        type: SHOW_PUSH_NOTIFICATIONS_OPT_IN_PROMPT,
    });
export const hidePushNotificationsOptInPrompt =
    (): HidePushNotificationsOptInPromptAction => ({
        type: HIDE_PUSH_NOTIFICATIONS_OPT_IN_PROMPT,
    });
export const maybeShowPushNotificationsOptInPrompt =
    (): MaybeShowPushNotificationsOptInPromptAction => ({
        type: MAYBE_SHOW_PUSH_NOTIFICATIONS_OPT_IN_PROMPT,
    });
