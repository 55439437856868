import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import MenuIcon from 'app/common/components/icons/MenuIcon';
import MobilePageHeader from 'app/common/components/pageHeaders/MobilePageHeader';
import MobilePageSubHeader from 'app/common/components/pageHeaders/MobilePageSubHeader';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { collapseSidebar } from 'app/common/reducers/global';

export const MobileHeader = () => {
    const dispatch = useDispatch();
    const { data: me } = useMe();
    const openSidebar = useCallback(() => dispatch(collapseSidebar()), []);

    const { t } = useTranslation();

    return (
        <>
            <MobilePageHeader
                leftContent={<MenuIcon onClick={openSidebar} />}
                title={t('homepage_title')}
                hideLeftContentOnIframe
            />
            <MobilePageSubHeader
                title={
                    me?.first_name
                        ? t('homepage_greeting_with_name', { name: me?.first_name })
                        : t('homepage_greeting')
                }
                subtitle={t('homepage_header_description')}
            />
        </>
    );
};
