import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';
import { DropZoneWrapper } from 'app/common/designSystem/components/molecules/DropZone/DropZone.styled';

export const ErrorsContainer = styled.div`
    color: ${({ theme }) => theme.colors.danger.initial};
`;

export const Overlay = styled.div`
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-out;
`;

export const DeleteButton = styled.div`
    background-color: ${HEX_COLORS.white};
    color: ${({ theme }) => theme.colors.danger.initial};
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const SingleImageDisplayContainer = styled.div`
    width: 100%;
    height: 400px;
    position: relative;
    display: inline-block;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &:hover ${Overlay} {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        visibility: visible;
    }
`;

type UploadContainerProps = {
    isDragging?: boolean;
};

export const SingleImageDropzoneContainer = styled.div`
    width: 100%;
    height: 400px;
    margin-bottom: 36px;
    background-color: ${(props: UploadContainerProps) =>
        props.isDragging ? 'white' : greyColorObject.light};
    border: 1px dashed ${({ theme }) => theme.colors.secondary.initial};
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const MultiImageDropzoneContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
    grid-gap: 8px;
    padding: 10px;
    box-sizing: border-box;

    ${DropZoneWrapper} {
        border-color: ${({ theme }) => theme.colors.secondary.initial};
        aspect-ratio: 1 / 1;
    }
`;

export const StyledLoader = styled(LoadingRectangle)`
    aspect-ratio: 1 / 1;
`;
