import styled from '@emotion/styled';

export const ReplySuggestionButtonWrapper = styled.div`
    i {
        color: ${({ theme }) => theme.colors.slate.initial};
    }
    &:hover {
        i,
    }
`;
