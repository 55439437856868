import { delay } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
    AccountPartner,
    FuzzySearchLocationsReceivedData,
} from 'app/api/types/account';
import api from 'app/api/v2/api_calls';
import formatLocations from 'app/businessConnection/services/formatLocations';
import {
    partnerToLocationFuzzyFailure,
    partnerToLocationFuzzySuccess,
    partnerToPartnerResourceName,
} from 'app/businessConnection/services/PartnerToAccountsMethods';
import { Saga } from 'app/common/types';
import {
    FACEBOOK,
    GOOGLE_MY_BUSINESS,
} from 'app/states/knowledge/reducers/businessConnection/accounts';
import { FUZZY_SEARCH_FACEBOOK_LOCATIONS } from 'app/states/knowledge/reducers/businessConnection/facebook/facebookLocations';
import {
    FUZZY_SEARCH_GMB_LOCATIONS,
    FuzzySearchGmbLocationsAction,
} from 'app/states/knowledge/reducers/businessConnection/gmb/gmbLocations';

function* fuzzySearchLocations(
    partner: AccountPartner,
    { searchInput }: FuzzySearchGmbLocationsAction,
) {
    try {
        yield call(delay, 1000);
        const data = (yield call(
            api.account.fuzzySearchLocations,
            partnerToPartnerResourceName[partner],
            searchInput,
            1,
        ) as any) as FuzzySearchLocationsReceivedData;
        const { byId, ids } = formatLocations(data.locations);
        yield put(partnerToLocationFuzzySuccess[partner](byId, ids));
    } catch (error) {
        yield put(partnerToLocationFuzzyFailure[partner](error));
    }
}

function* partnerConnectionsFuzzySearchLocationsSaga(): Saga {
    yield takeLatest(
        FUZZY_SEARCH_GMB_LOCATIONS,
        fuzzySearchLocations,
        GOOGLE_MY_BUSINESS,
    );
    yield takeLatest(
        FUZZY_SEARCH_FACEBOOK_LOCATIONS,
        fuzzySearchLocations,
        FACEBOOK,
    );
}

export default partnerConnectionsFuzzySearchLocationsSaga;
