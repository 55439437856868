import React from 'react';

import { Helmet } from 'react-helmet';

import env from 'app/common/services/getEnvironment';

type Props = {
    title: string;
};

const DynamicPageHeader = ({ title }: Props) => {
    const currentEnv = env.getEnv();
    const titleText =
        currentEnv === 'prod'
            ? title
            : `[${currentEnv.toLocaleUpperCase()}] ${title}`;
    return (
        <Helmet>
            <title>{titleText}</title>
        </Helmet>
    );
};

export default DynamicPageHeader;
