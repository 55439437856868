import { extractSearchParam } from 'app/common/data/routeIds';

export const getPKCEParams = () => {
    const code = extractSearchParam('code');
    const state = extractSearchParam('state');

    return {
        code,
        state,
    };
};
