import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';

import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';

export const PageHeader = () => {
    const { isBelowProvidedDevices: isTablet } = useDeviceType(IS_BELOW_TABLET);

    return isTablet ? <MobileHeader /> : <DesktopHeader />;
};
