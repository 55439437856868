import React from 'react';

import { Modal } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { CustomFieldsQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

import CustomFieldsEditContent from './CustomFieldsEditContent';

const CustomFieldsEditModal = () => {
    const [editCustomField, setEditCustomField] = useStateQueryParams(CustomFieldsQueryKeys.EDIT);

    const closeModal = () => setEditCustomField('');

    return (
        <Modal
            isOpen={!!editCustomField}
            closeModal={closeModal}
            placement="center"
            animation="fadeIn"
        >
            <CustomFieldsEditContent closeModal={closeModal} />
        </Modal>
    );
};

export default CustomFieldsEditModal;
