import useDisplayMoreHours from 'app/businessEditV2/hooks/editV2utils/displaySections/useDisplayMoreHours';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';

import { ColorState } from './types';

export default function useMoreHoursSectionColor(): ColorState {
    // Hooks
    const moreHoursPermission = useFieldMetadata('hours', 'more_hours')?.enabled;
    const isClosed = useIsBusinessClosed();
    const display = useDisplayMoreHours();

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // If no more hours for the category/country
    if (!display) {
        return ColorState.OK;
    }

    // Disabled
    if (!moreHoursPermission) {
        return ColorState.DISABLED;
    }

    // Publisher Errors and Missing Fields not handled for More Hours

    // OK
    return ColorState.OK;
}
