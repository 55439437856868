import useDebounce from 'app/common/hooks/useDebounce';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { BusinessListQueryKeys } from 'app/pages/visibility/BusinessListV2/utils/enums';

export const useGetQuery = () => {
    const [query] = useStateQueryParams(BusinessListQueryKeys.QUERY);

    const debouncedQuery = useDebounce(query, 300);

    return {
        ...(query ? { query: debouncedQuery } : {}),
    };
};
