import { connect } from 'react-redux';

import { partnerToPartnerName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import { getBusinessEditFields } from 'app/common/reducers/businessEditFields';
import {
    connectedLocationIdSelector,
    hideConnectionModal,
    resetConnectionModal,
    selectedBusinessIdSelector,
    selectedPartnerSelector,
    showConnectionModalSelector,
} from 'app/states/knowledge/reducers/businessConnection/connection';
import { AppState } from 'app/states/reducers';

import ConnectionModalComponent from './ConnectionModal';

const mapStateToProps = ({ businessConnection: { page } }: AppState) => ({
    show: showConnectionModalSelector(page),
    connectedLocationId: connectedLocationIdSelector(page),
    partnerName: partnerToPartnerName[selectedPartnerSelector(page)],
    partner: selectedPartnerSelector(page),
    businessId: selectedBusinessIdSelector(page),
});

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => {
        dispatch(hideConnectionModal());
        dispatch(resetConnectionModal());
    },
    getBusinessEditFields: businessId => {
        dispatch(getBusinessEditFields(businessId));
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    state: stateProps.connectedLocationId ? 'connected' : 'searching',
});

const ConnectionModal = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(ConnectionModalComponent);

export default ConnectionModal;
