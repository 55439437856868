import { DatePickerType, RangeDate } from '@partoohub/ui';

import { format, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { getAdjustedDate } from 'app/businessEditV2/utils/dateFormatting';
import { LocaleDatePicker } from 'app/common/components/LocaleDatePicker/LocaleDatePicker';
import { DATE_FORMAT } from 'app/utils/dates';

import {
    OpeningDateContainerError,
    OpeningDateErrorMessage,
    OverrideDatePickerWithError,
} from './OpeningDate.styled';

type Props = {
    openingDate: string;
    onChange: (newOpeningDate: string) => void;
    errorMessage: string | undefined;
    disabled?: boolean;
};
const OpeningDate = ({ openingDate, onChange, errorMessage, disabled }: Props) => {
    const { t } = useTranslation();

    const adjustedOpeningDate = getAdjustedDate(openingDate);

    const updateOpeningDate = (selectedDate: RangeDate) => {
        const result =
            selectedDate?.[0] && isValid(selectedDate[0])
                ? format(selectedDate[0], DATE_FORMAT)
                : '';
        onChange(result);
    };

    return (
        <OpeningDateContainerError>
            <OverrideDatePickerWithError error={!!errorMessage}>
                <LocaleDatePicker
                    dataTrackId="opening-date-selector"
                    type={DatePickerType.DateSelector}
                    labels={{ startDate: t('business_opening_date') }}
                    placeholders={{ startDate: t('date_placeholder') }}
                    dates={[adjustedOpeningDate, null]}
                    disabled={disabled}
                    onChange={updateOpeningDate}
                />
            </OverrideDatePickerWithError>
            {!!errorMessage && (
                <OpeningDateErrorMessage variant="bodySRegular" color="danger">
                    {t(errorMessage)}
                </OpeningDateErrorMessage>
            )}
        </OpeningDateContainerError>
    );
};

export default OpeningDate;
