import styled from '@emotion/styled';
import { Table, Text } from '@partoohub/ui';

export const CompetitorCellStyled = styled(Table.Cell)`
    text-align: left;
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const CompetitorNameText = styled(Text)`
    font-size: 16px;
    text-transform: capitalize;
`;

export const ColorTileStyled = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 4px;
    flex-shrink: 0;
    background-color: ${({ color }) => color};
`;

export const CompetitorSubLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
