import { Button, FontAwesomeIconsPartooUsed } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { GroupsSettingsQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

type Props = {
    sectionId: number;
};

const NewGroupButton = ({ sectionId }: Props) => {
    const { t } = useTranslation();
    const [, setCreateGroup] = useStateQueryParams(GroupsSettingsQueryKeys.CREATE, true);

    return (
        <Button
            appearance="text"
            variant="primary"
            dataTrackId="groups-add-group-button"
            onClick={() => setCreateGroup(sectionId.toString())}
            icon={[FontAwesomeIconsPartooUsed.faPlus]}
        >
            {t('new_group_button')}
        </Button>
    );
};

export default NewGroupButton;
