import React from 'react';

import { STATIC_BASE_URL } from 'app/config';

import { Info, InfoIcon, InfoText } from './Message.styled';

export type MessageType = {
    type?: 'warning' | 'error' | 'sync' | 'success';
    description?: string;
};

export const Message = ({ type, description }: MessageType) => {
    const iconType = type || 'info';
    return (
        <Info>
            <InfoIcon toRotate={type === 'sync'}>
                <img
                    src={`${STATIC_BASE_URL}/images/common/custom-svg/${iconType}.svg`}
                    alt=""
                />
            </InfoIcon>
            <InfoText>{description}</InfoText>
        </Info>
    );
};
