import React from 'react';

import { Text } from '@partoohub/ui';

import { STATIC_BASE_URL } from 'app/config';
import {
    getDescriptionTranslation,
    getIconName,
    getTypeTranslation,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/services';

import { TypeItemContainer, TypeItemImage, TypeItemInfo, TypeItemTitle } from './TypeItem.styled';

type Props = {
    type: string;
    onClick: (arg0: string) => void;
};

const TypeItem = ({ type, onClick }: Props) => (
    <TypeItemContainer onClick={() => onClick(type)}>
        <TypeItemImage
            src={`${STATIC_BASE_URL}/images/app/custom_fields/${getIconName(type)}`}
            alt=""
        />
        <TypeItemInfo>
            <TypeItemTitle variant="bodyMBold">{getTypeTranslation(type)}</TypeItemTitle>
            <Text variant="bodySRegular" color="secondary">
                {getDescriptionTranslation(type)}
            </Text>
        </TypeItemInfo>
    </TypeItemContainer>
);

export default TypeItem;
