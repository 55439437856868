import { useController } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import OpeningDate from 'app/businessEditV2/sections/AdvancedSettingsSection/AdvancedSettingsForm/OpeningDate';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    disabled?: boolean;
    errorMessage?: string;
};

const ControlledOpeningDate = ({ control, name, disabled, errorMessage }: ControlledComponent) => {
    const {
        field: { value, onChange },
    } = useController({ control, name });

    return (
        <OpeningDate
            openingDate={value}
            onChange={onChange}
            errorMessage={errorMessage}
            disabled={disabled}
        />
    );
};

export default ControlledOpeningDate;
