import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';
import { ChatbotConfiguration } from 'app/pages/settingsV2/subPages/Messages/components/Chatbots/ChatbotConfiguration';

interface Props {
    show: boolean;
}

export const ChatbotConfigurationModal = ({ show }: Props) => (
    <FullScreenModal show={show} childrenLeft={<ChatbotConfiguration />} onHideClick={() => null} />
);
