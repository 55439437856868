import React, { useEffect, useRef } from 'react';

import { IconButton, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroll-component';

import { DeviceType, useDeviceType } from 'app/hooks';
import { MessagesList } from 'app/reviewManagement/messaging/activeConversation/messages/MessagesList';
import { Conversation } from 'app/states/messaging';
import { MessagingPartnerEnum } from 'app/states/messaging/dataTypes';

import {
    ConversationLayoutStyled,
    DelightConversationBanner,
    ScrollToBottomContainer,
} from './ConversationLayout.styled';

import MessageLoader from './messages/MessageLoader';
import { useGetMessages } from '../hooks/useGetMessages';

type ConversationLayoutProps = {
    activeConversation: Conversation;
    notice: React.ReactNode;
};

const ConversationLayout = ({ activeConversation, notice }: ConversationLayoutProps) => {
    const { messages, hasNextPage, isLoading, fetchNextPage } = useGetMessages(
        activeConversation.id,
    );
    const { t } = useTranslation();
    const hasMessages = !!messages.length;
    const bottomDivRef: React.RefObject<HTMLDivElement> | null = useRef(null);
    const [isAtBottom, setIsAtBottom] = React.useState(true);
    const { device } = useDeviceType();
    const isDesktop = device === DeviceType.Desktop;

    useEffect(() => {
        bottomDivRef.current?.scrollIntoView();
    }, [messages?.[0]?.id]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsAtBottom(entry.isIntersecting);
            },
            { rootMargin: '20px' },
        );
        observer.observe(bottomDivRef.current!);
        return () => observer.disconnect();
    }, []);

    return (
        <>
            {isLoading && <MessageLoader />}
            <ConversationLayoutStyled id="conversationLayoutDiv" flexDirection="column-reverse">
                <div ref={bottomDivRef} />
                {activeConversation.messaging_partner === MessagingPartnerEnum.DELIGHT && (
                    <DelightConversationBanner>
                        {t('messaging_conversation_delight_cannot_reply')}
                    </DelightConversationBanner>
                )}

                {!isAtBottom && (
                    <ScrollToBottomContainer>
                        <IconButton
                            dataTrackId="scroll_bottom__conversation_layout__icon_button"
                            icon={['fa-chevron-down', IconPrefix.SOLID]}
                            onClick={() => {
                                bottomDivRef.current?.scrollIntoView({
                                    behavior: 'smooth',
                                });
                            }}
                        />
                    </ScrollToBottomContainer>
                )}
                <InfiniteScroll
                    dataLength={messages.length}
                    next={fetchNextPage}
                    style={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        ...(isDesktop ? { overflow: 'unset' } : {}),
                    }}
                    inverse
                    hasMore={hasNextPage ?? false}
                    loader={<MessageLoader />}
                    scrollableTarget="conversationLayoutDiv"
                >
                    {notice}
                    {hasMessages && (
                        // @ts-ignore
                        <MessagesList messages={messages} activeConversation={activeConversation} />
                    )}
                </InfiniteScroll>
            </ConversationLayoutStyled>
        </>
    );
};

export default ConversationLayout;
