import React from 'react';

import {
    GoogleVerificationStartData,
    V2FormattedLocationData,
} from 'app/api/types/account';

import {
    ConfirmationMethodInformationAddress,
    ConfirmationMethodInformationEmail,
    ConfirmationMethodInformationPhone,
    ConfirmationMethodInformationSMS,
} from './ConfirmationMethodInformation';
import { getCardByMethod } from '../../Options/OptionCard';

type Props = {
    location: V2FormattedLocationData;
    optionSelected?: GoogleVerificationStartData;
    setOptionSelected: React.Dispatch<
        React.SetStateAction<GoogleVerificationStartData>
    >;
};

const renderConfirmationMethodInformation = (
    optionSelected,
    location,
    setOptionSelected,
) => {
    switch (optionSelected.method) {
        case 'SMS':
            return (
                <ConfirmationMethodInformationSMS
                    optionSelected={optionSelected}
                />
            );
        case 'PHONE_CALL':
            return (
                <ConfirmationMethodInformationPhone
                    optionSelected={optionSelected}
                />
            );
        case 'ADDRESS':
            return (
                <ConfirmationMethodInformationAddress
                    location={location}
                    optionSelected={optionSelected}
                    setOptionSelected={setOptionSelected}
                />
            );
        case 'EMAIL':
            return (
                <ConfirmationMethodInformationEmail
                    optionSelected={optionSelected}
                    setOptionSelected={setOptionSelected}
                />
            );
        default:
            return <></>;
    }
};

const ConfirmationScreen: React.FC<Props> = ({
    location,
    optionSelected,
    setOptionSelected,
}) => (
    <>
        {getCardByMethod(optionSelected!.method, true, optionSelected!.method)}
        {renderConfirmationMethodInformation(
            optionSelected,
            location,
            setOptionSelected,
        )}
    </>
);

export default ConfirmationScreen;
