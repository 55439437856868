import { QueryClient } from 'react-query';

export default new QueryClient({
    /**
     *  Global queries options.
     *  They can be overridden on a pre-query basis.
     */
    defaultOptions: {
        queries: {
            /** @name staleTime
             * We put the staleTime to Infinity because we don't want
             * any automatic refresh happening in the background to avoid
             * over-fetching.
             */
            staleTime: Infinity,

            refetchInterval: false,
            retry: false,

            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
});
