import React, { ReactNode } from 'react';

import {
    Body,
    BodyComponent,
    BodyContent,
    BodyDescription,
    BodyLabel,
    BodyMain,
    BodyOptionalElements,
    BodyOptionalLabel,
    BodySubLabel,
    LeftIcon,
} from './SectionBody.styled';

type Props = {
    id: string;
    mainLabel: string;
    disabled: boolean;
    // Optional props
    description?: string;
    leftIcon?: boolean;
    leftIconElem?: ReactNode;
    optionalLabel?: ReactNode;
    menuElem?: ReactNode;
    subLabel?: string;
};

const SectionBody = ({
    id,
    mainLabel,
    disabled,
    description,
    leftIcon,
    leftIconElem,
    optionalLabel,
    menuElem,
    subLabel,
}: Props) => (
    <Body id={id}>
        {leftIcon &&
            (leftIconElem || <LeftIcon className="fa-solid fa-bars" />)}
        <BodyContent>
            <BodyMain>
                <BodyLabel className={disabled ? 'text-field__disabled' : ''}>
                    {mainLabel}
                </BodyLabel>
                {subLabel && (
                    <BodySubLabel
                        className={disabled ? 'text-field__disabled' : ''}
                    >
                        {subLabel}
                    </BodySubLabel>
                )}
                {description && (
                    <BodyDescription
                        className={disabled ? 'text-field__disabled' : ''}
                    >
                        {description}
                    </BodyDescription>
                )}
            </BodyMain>
            <BodyOptionalElements>
                {optionalLabel && (
                    <BodyOptionalLabel
                        className={disabled ? 'text-field__disabled' : ''}
                    >
                        {optionalLabel}
                    </BodyOptionalLabel>
                )}
                <BodyComponent>{menuElem}</BodyComponent>
            </BodyOptionalElements>
        </BodyContent>
    </Body>
);
export default SectionBody;
