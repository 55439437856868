import { PENDING_AUTO_REPLY_REVIEWS } from 'app/states/reviews/filters';

import Flexbox from 'app/styles/utils/flexbox';

import { StyledComment } from './Comment.styled';
import CommentAuthorMessage from './CommentAuthorMessage';
import { StyledCommentAuthorMessage } from './CommentAuthorMessage.styled';
import CommentIcon from './CommentIcon';
import { StyledCommentTable } from './CommentTable.styled';

type Props = {
    commentDate?: string;
    commentContent?: string;
};

export default function ({ commentDate, commentContent }: Props) {
    return (
        <StyledCommentTable>
            <StyledComment>
                <CommentIcon hasLink={false} reviewState={PENDING_AUTO_REPLY_REVIEWS} />
                <Flexbox flex="1" flexDirection="column" alignSelf={'center'}>
                    <StyledCommentAuthorMessage>
                        <CommentAuthorMessage
                            commentDate={commentDate}
                            message={commentContent}
                            reviewState={PENDING_AUTO_REPLY_REVIEWS}
                            canEdit={false}
                        />
                    </StyledCommentAuthorMessage>
                </Flexbox>
            </StyledComment>
        </StyledCommentTable>
    );
}
