import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PageHeader from 'app/common/designSystem/components/molecules/PageHeader';
import { collapseSidebar } from 'app/common/reducers/global';

export const DesktopHeader = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <div>
            <PageHeader
                title={t('rb_invite_title')}
                description={t('rb_description')}
                collapseSidebar={() => dispatch(collapseSidebar())}
            />
        </div>
    );
};
