import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { V2FormattedBusinessData } from 'app/api/types/business';
import { Choice } from 'app/api/types/user';
import BusinessModalWithFilterContainer from 'app/common/components/businessModalDeprecated/components/BusinessModalWithFilter.container';
import {
    SELECT_MODE,
    UNSELECT_MODE,
    addSearchFilter,
    citiesHaveChanged,
    filterByBusinessSelector,
    queriesHaveChanged,
    setFilterMode,
} from 'app/common/components/businessModalDeprecated/reducers';
import { useCities } from 'app/common/hooks/queries/useCities';
import { UNGROUPED_ID } from 'app/common/reducers/groups';
import {
    searchBusinesses,
    searchQuerySelector,
    selectedCitySelector,
    updateSearchQuery,
    updateSelectedCity,
} from 'app/common/reducers/newBusinesses';
import fuzzySearch, {
    PARTOO_BUSINESS_SEARCH_IN_CITY,
    PARTOO_BUSINESS_TERMS_TO_SEARCH_IN,
    commaSplitter,
} from 'app/common/services/fuzzySearch';
import i18n from 'app/common/translations/i18n';
import useGroupData from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupData';
import useHandleGroupFakeSagas from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupFakeSaga';
import { businessesSelector } from 'app/states/reducers';

/*
    Component to "mock" the container in the Business List V1 business modal...
    Kill it with fire once groups are deleted
    Not sure exactly what's happening here
 */
const BusinessManagementModal = () => {
    // Fake the group sagas for the business modal.
    useHandleGroupFakeSagas();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [{ isLoading: isLoadingCities }, cities] = useCities();
    const currentGroup = useGroupData();

    const filterByBusiness = useSelector(filterByBusinessSelector);
    const businesses = useSelector(businessesSelector);
    const selectedCity = selectedCitySelector(businesses);

    const selectedCityValue = selectedCity || t('all_cities');
    const searchQuery = searchQuerySelector(businesses);

    const onSearchInputChange = (query: string) => {
        dispatch(updateSearchQuery(query));

        if (query && selectedCityValue) {
            dispatch(updateSelectedCity(''));
        }
    };

    const headerTitle = currentGroup?.parentId
        ? `${t('add_business_to_subgroup')} (${currentGroup?.groupName})`
        : `${t('add_business_to_group')} (${currentGroup?.groupName})`;

    const selectProps = {
        selectedValue: {
            label: selectedCityValue,
            value: selectedCityValue,
        },
        isLoading: isLoadingCities,
        options: [
            {
                label: i18n.t('all_cities'),
                value: 'all_cities',
            },
            ...(cities ?? []).map(city => ({
                label: city,
                value: city,
            })),
        ],
        placeholder: i18n.t('city'),
        onChange: (choice: Choice | null) => {
            const value = choice ? choice.value : '';
            dispatch(updateSearchQuery(''));
            dispatch(updateSelectedCity(value === 'all_cities' ? '' : value));
        },
    };

    const selectCities: Array<string> = selectedCity ? [selectedCity] : [];

    const queries = commaSplitter(searchQuerySelector(businesses).toLowerCase());

    const handleBusinessesSelectClick = (allSelected: boolean) => {
        if (currentGroup && (queries.length || selectedCity)) {
            return dispatch(
                addSearchFilter(
                    queries,
                    selectCities,
                    [],
                    // @ts-ignore
                    currentGroup.groupId ? [currentGroup.parentId, currentGroup.groupId] : [],
                    !currentGroup.parentId,
                ),
            );
        }

        return dispatch(setFilterMode(allSelected ? SELECT_MODE : UNSELECT_MODE));
    };

    const searchBusinessesByPage = (page: number) => dispatch(searchBusinesses(page, searchQuery));

    const getCanSelectAll = (allSelected: boolean) =>
        queries.length || selectCities.length // (to be able to EXCLUDE SEARCHES) use:  selectMode(fbb) ===
            ? queriesHaveChanged(filterByBusiness, queries) ||
              citiesHaveChanged(filterByBusiness, selectCities)
            : !allSelected;

    const businessesFilter = (businessesList: Array<V2FormattedBusinessData>): any => {
        const filteredByCity = selectedCity
            ? businessesList.filter(a => a.city === selectedCity)
            : Array.from(
                  new Set(
                      fuzzySearch(
                          businessesList,
                          searchQuery
                              ? PARTOO_BUSINESS_TERMS_TO_SEARCH_IN
                              : PARTOO_BUSINESS_SEARCH_IN_CITY,
                          searchQuery || selectedCity,
                      ),
                  ),
              );
        const subgroupIds = currentGroup?.subgroups?.map(sub => sub.groupId) ?? [];
        const groupIdsToKeep = [
            currentGroup?.groupId,
            currentGroup?.parentId ?? UNGROUPED_ID,
            ...subgroupIds,
        ];

        return filteredByCity.filter((business: any) => groupIdsToKeep.includes(business.groupId));
    };

    return (
        <BusinessModalWithFilterContainer
            activeRibbon
            businessesFilter={businessesFilter}
            getCanSelectAll={getCanSelectAll}
            handleBusinessesSelectClick={handleBusinessesSelectClick}
            headerTitle={headerTitle}
            onSearchInputChange={onSearchInputChange}
            query={searchQuery}
            searchBusinessesByPage={searchBusinessesByPage}
            selectProps={selectProps}
        />
    );
};

export default BusinessManagementModal;
