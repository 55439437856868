import { useRef } from 'react';

import { css } from '@emotion/react';
import { Text } from '@partoohub/ui';

import { Controller, FieldValues, FormState } from 'react-hook-form';

import { Control } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import MentionTextArea from 'app/common/designSystem/components/atoms/MentionTextArea';
import { MentionTextAreaImperativeHandle } from 'app/common/designSystem/components/atoms/MentionTextArea/MentionTextArea';
import { ButtonWithMenu } from 'app/common/designSystem/components/molecules/ButtonWithMenu/ButtonWithMenu';
import {
    ConversationStarterItemFormMessageContainer,
    ConversationStarterItemFormMessageHeader,
    TextAreaContainer,
    TextAreaFieldName,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/ConversationStarterItemFormMessage.styled';

import {
    CONVERSATION_STARTER_ITEM_REPLY_MESSAGE_MAXIMUM_CHARACTERS,
    ConversationStarterPlaceholders,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';

type Props = {
    control: Control<FieldValues, any>;
    formState: FormState<FieldValues>;
};

const ConversationStarterItemFormMessage = ({ control, formState }: Props) => {
    const { t } = useTranslation();

    const textRef = useRef<MentionTextAreaImperativeHandle>(null);

    const addPlaceholder = (value: string) => {
        if (!textRef.current) {
            return;
        }
        const selectedPlaceholder = ConversationStarterPlaceholders.find(
            placeholder => placeholder.id == value,
        );

        if (selectedPlaceholder) {
            textRef.current.addPlaceholder(t(selectedPlaceholder.display), value);
        }
    };

    const translatedPlaceholdersOptions = ConversationStarterPlaceholders.map(placeholder => ({
        value: placeholder.id,
        label: t(placeholder.display),
        name: placeholder.id,
    }));

    const translatedPlaceholders = ConversationStarterPlaceholders.map(placeholder => ({
        ...placeholder,
        display: t(placeholder.display),
    }));

    return (
        <ConversationStarterItemFormMessageContainer>
            <ConversationStarterItemFormMessageHeader>
                <Text variant="bodyMSemibold" color="default">
                    {t('conversation_starters_item_reply_message_header')}
                </Text>
                <ButtonWithMenu
                    dataTrackId="conversation_start_item__button_menu"
                    text={t('add_a_placeholder')}
                    appearance="text"
                    variant="primary"
                    icon={<i className="fa-solid fa-at" />}
                    options={translatedPlaceholdersOptions}
                    onMenuClick={(value: string) => addPlaceholder(value)}
                    cssMenu={css`
                        top: 30px;
                        right: -2px;
                        left: unset;
                    `}
                />
            </ConversationStarterItemFormMessageHeader>
            <TextAreaContainer>
                <Controller
                    name="reply_message"
                    control={control}
                    rules={{
                        required: true,
                        maxLength: CONVERSATION_STARTER_ITEM_REPLY_MESSAGE_MAXIMUM_CHARACTERS,
                    }}
                    render={({ field: { ref: _ref, ...usedFields } }) => (
                        <MentionTextArea
                            {...usedFields}
                            ref={textRef}
                            isMandatory
                            characterCount={
                                CONVERSATION_STARTER_ITEM_REPLY_MESSAGE_MAXIMUM_CHARACTERS
                            }
                            choices={translatedPlaceholders}
                            explanation={
                                usedFields.value.length >
                                CONVERSATION_STARTER_ITEM_REPLY_MESSAGE_MAXIMUM_CHARACTERS
                                    ? t('conversation_starters_item_reply_message_error')
                                    : ''
                            }
                            hasError={['required', 'maxLength'].includes(
                                formState.errors.reply_message?.type,
                            )}
                        />
                    )}
                />
                <TextAreaFieldName as="p" variant="bodySBold">
                    {t('conversation_starters_item_reply_message')}
                </TextAreaFieldName>
            </TextAreaContainer>
        </ConversationStarterItemFormMessageContainer>
    );
};

export default ConversationStarterItemFormMessage;
