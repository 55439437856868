import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import { BulkUpdateModal } from './BulkUpdateModal';

export const BulkUpdatePage = () => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('bulk_update_page_title')} />
            <BulkUpdateModal />
        </>
    );
};
