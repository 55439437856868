import { useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { Chip, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack } from '@partoohub/ui';
import { getAllInfoByISO } from 'iso-country-currency';

import { useTranslation } from 'react-i18next';

import { ServiceSuggestionType, ServiceType, servicesTypeEnum } from 'app/api/types/services';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessCategories from 'app/businessEditV2/hooks/business/useBusinessCategories';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useServiceDelete from 'app/businessEditV2/hooks/services/useServiceDelete';
import useServices from 'app/businessEditV2/hooks/services/useServices';
import useServicesSuggestions from 'app/businessEditV2/hooks/services/useServicesSuggestions';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import SectionContainer from 'app/common/designSystem/components/molecules/SectionContainer';

import LoadingServicesList from './LoadingServicesList';
import AddServiceModal, {
    ServiceModalDataType as AddServiceModalDataType,
} from './modals/AddServiceModal';
import EditServiceModal, {
    ServiceModalDataType as EditServiceModalDataType,
} from './modals/EditServiceModal';
import NotConnectedToGmbTemplate from './NotConnectedToGmbTemplate';
import {
    ServiceHelperContainer,
    ServiceHelperLabel,
    ServiceHelperText,
} from './ServiceHelper.styled';
import { ServiceMenu } from './ServiceMenu';

const DELETE_MODAL = 'delete';
const EDIT_MODAL = 'edit';
const CREATE_MODAL = 'create';
const CLOSE_MODAL = 'close';

const ServicesList = () => {
    const { t } = useTranslation();
    const [openedModal, setOpenedModal] = useState<string>(CLOSE_MODAL);
    const [modalData, setModalData] = useState<AddServiceModalDataType | EditServiceModalDataType>(
        {},
    );
    const [serviceToDelete, setServiceToDelete] = useState<ServiceType>();

    const { data: categories } = useBusinessCategories();

    const { data: business } = useBusiness();
    const country = business?.country ?? 'FR';
    const { currency } = getAllInfoByISO(country);

    const servicesQuery = useServices();

    const services: Array<ServiceType> = servicesQuery.data?.services || [];

    const suggestionsQuery = useServicesSuggestions();

    const servicesSuggestions: Array<ServiceSuggestionType> =
        suggestionsQuery.data?.services_suggestions || [];

    const closeModal = () => setOpenedModal('');

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const deleteMutation = useServiceDelete(() => {
        businessUpdateCompletionRate.mutate();
        closeModal();
    });

    const onMenuClick = (service: ServiceType, value: string) => {
        if (value === 'delete') openDeleteServiceModal(service);
        if (value === 'edit') {
            if (service.type === servicesTypeEnum.STRUCTURED_SERVICE_ITEM)
                openEditStructuredServiceModal(service);
            else if (service.type === servicesTypeEnum.FREE_FORM_SERVICE_ITEM)
                openEditFreeFormServiceModal(service);
        }
    };

    const openDeleteServiceModal = (service: ServiceType) => {
        setServiceToDelete(service);
        setOpenedModal(DELETE_MODAL);
    };

    const openAddStructuredServiceModal = (serviceName: string, serviceId: number) => {
        setModalData({
            serviceType: servicesTypeEnum.STRUCTURED_SERVICE_ITEM,
            serviceName,
            serviceId,
        });
        setOpenedModal(CREATE_MODAL);
    };

    const openAddFreeFormServiceModal = (categoryGmbName: string) => {
        setModalData({
            serviceType: servicesTypeEnum.FREE_FORM_SERVICE_ITEM,
            categoryGmbName,
        });
        setOpenedModal(CREATE_MODAL);
    };

    const openEditStructuredServiceModal = (service: ServiceType) => {
        setModalData({ service });
        setOpenedModal(EDIT_MODAL);
    };

    const openEditFreeFormServiceModal = (service: ServiceType) => {
        setModalData({ service });
        setOpenedModal(EDIT_MODAL);
    };

    const businessIsOpen = !useIsBusinessClosed();
    const servicesPermissions = useFieldMetadata('services', 'services')?.enabled && businessIsOpen;
    const disabled = !servicesPermissions;

    if (!business?.google_location_id) return <NotConnectedToGmbTemplate />;

    if (servicesQuery.isLoading) return <LoadingServicesList />;

    if (servicesQuery.isError) return <p>Error</p>;

    const servicesByCategory = {};
    for (const service of services) {
        if (!(service.category_gmb_name in servicesByCategory)) {
            servicesByCategory[service.category_gmb_name] = [];
        }
        servicesByCategory[service.category_gmb_name].push(service);
    }

    const servicesSuggestionsByCategory = {};
    for (const suggestion of servicesSuggestions) {
        if (!(suggestion.category_gmb_name in servicesSuggestionsByCategory)) {
            servicesSuggestionsByCategory[suggestion.category_gmb_name] = [];
        }
        servicesSuggestionsByCategory[suggestion.category_gmb_name].push(suggestion);
    }

    if (categories.length === 0) {
        const illustrationElement: IllustrationLaptopElement = {
            type: IllustrationCategoryEnum.Laptop,
            name: IllustrationLaptopEnum.Sad,
        };
        return (
            <NotFoundTemplate
                show
                imgSrc={illustrationElement}
                title={t('cant_modify_service_list_title')}
                subtitle={t('cant_modify_service_list_subtitle')}
                withButton={false}
            />
        );
    }

    return (
        <div>
            <ServiceHelperContainer>
                <ServiceHelperText>
                    <i className="fa-solid fa-info-circle" />
                    <ServiceHelperLabel>{t('services_depends_on_categories')}</ServiceHelperLabel>
                </ServiceHelperText>
            </ServiceHelperContainer>

            {categories.map(category => (
                <SectionContainer
                    key={`category_${category.value}`}
                    title={category.label}
                    disabled={disabled}
                    tooltipText={disabled ? t('business_service_disabled_tooltip_text') : undefined}
                    bodyContentList={(servicesByCategory[category.value] || []).map(service => ({
                        mainLabel: service.name,
                        description: service.description,
                        optionalLabel: service.price
                            ? `${service.price.toString().replace('.', ',')} ${currency}`
                            : null,
                        menuElem: (
                            <ServiceMenu
                                key={`service_menu_${service.name}_for_${category.value}`}
                                onMenuClick={(value: string) => {
                                    onMenuClick(service, value);
                                }}
                                disabled={disabled}
                            />
                        ),
                    }))}
                    footerContent={(servicesSuggestionsByCategory[category.value] || [])
                        .filter(
                            suggestion =>
                                !services.some(
                                    service => suggestion.service_id === service.service_id,
                                ),
                        )
                        .map(suggestion => ({
                            key: `chip_suggestion_${suggestion.name}_for_${category.value}`,
                            component: (
                                <Chip
                                    dataTrackId={`service-list__${suggestion.name}__chip_suggestion`}
                                    disabled={disabled}
                                    onClick={() => {
                                        if (disabled) return;
                                        openAddStructuredServiceModal(
                                            suggestion.name,
                                            suggestion.service_id,
                                        );
                                    }}
                                >
                                    <Stack gap="4px" direction="row" alignItems="center">
                                        <Icon
                                            icon={[
                                                FontAwesomeIconsPartooUsed.faPlus,
                                                IconPrefix.SOLID,
                                            ]}
                                            iconSize="12px"
                                            color="primary"
                                        />
                                        {suggestion.name}
                                    </Stack>
                                </Chip>
                            ),
                        }))
                        .concat({
                            key: `custom_for_${category.value}`,
                            component: (
                                <StrikeThroughButton
                                    color={'primary'}
                                    text={t('add_personalized_service_button')}
                                    disabled={disabled}
                                    icon={<i className="fa fa-plus-circle" />}
                                    onClick={() => openAddFreeFormServiceModal(category.value)}
                                />
                            ),
                        })}
                />
            ))}
            <ConfirmModal
                show={openedModal === DELETE_MODAL}
                title={t('delete_service_modal_title')}
                content={t('delete_service_modal_content', {
                    serviceName: serviceToDelete?.name ?? '',
                })}
                confirmLabel={t('delete')}
                modalFor="delete"
                onConfirm={() =>
                    serviceToDelete && deleteMutation.mutate(serviceToDelete.service_id)
                }
                onHide={closeModal}
                trackId="service-list__delete-service-modal"
            />

            <AddServiceModal
                show={openedModal === CREATE_MODAL}
                handleClose={closeModal}
                data={modalData as AddServiceModalDataType}
                currency={currency}
            />

            <EditServiceModal
                show={openedModal === EDIT_MODAL}
                handleClose={closeModal}
                data={modalData as EditServiceModalDataType}
                currency={currency}
            />
        </div>
    );
};

export default ServicesList;
