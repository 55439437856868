import { Choice, ChoiceWithSubtitle } from 'app/api/types/user';
import FilterSelect from 'app/common/components/FilterSelect';

type Props = {
    choices: Array<Choice>;
    title: string;
    multiple?: boolean;
    selectedValues: Array<string>;
    minWidth?: number;
    onChange: (values: Array<string>) => void;
    mandatory?: boolean;
    getActiveButtonText?: (options: Array<ChoiceWithSubtitle>) => string;
};

export default function FilterAdapter({
    choices,
    title,
    multiple,
    selectedValues,
    minWidth,
    onChange,
    mandatory,
    getActiveButtonText,
}: Props) {
    return (
        <div style={{ minWidth }}>
            <FilterSelect
                options={choices}
                title={title}
                isSingleSelect={!multiple}
                onChange={selectedItems => {
                    return onChange(selectedItems.map(item => item.value));
                }}
                selectedOptions={choices.filter(choice =>
                    selectedValues.includes(choice.value),
                )}
                mandatory={mandatory}
                getActiveButtonText={getActiveButtonText}
            />
        </div>
    );
}
