import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { CUSTOM_FIELDS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

type DeleteCustomFieldMutationProps = {
    customFieldId: number;
    forcedUpdate: boolean;
};

export default function useDeleteCustomField(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    return useMutation(
        (props: DeleteCustomFieldMutationProps) =>
            api.customFields.deleteCustomFields(props.customFieldId, {
                forced_update: props.forcedUpdate,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(invalidatesAny(CUSTOM_FIELDS)).then(onSuccess);
            },
            onError,
        },
    );
}
