import { useLocation } from 'react-router-dom';

import { ANALYTICS_REVIEW_PATH } from 'app/routing/routeIds';

/**
 * Hook to check if the current page is the analytics V2
 */
export const useIsAnalyticsV2Page = (): boolean => {
    const { pathname } = useLocation();
    return pathname.startsWith(`${ANALYTICS_REVIEW_PATH}/v2`);
};
