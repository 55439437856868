import { FC, useEffect, useState } from 'react';

import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { Outlet } from 'react-router-dom';

import DownloadMobileApp from 'app/common/components/DownloadMobileApp/DownloadMobileApp';
import ProviderLogo from 'app/common/components/ProviderLogo/ProviderLogo';
import { ResponsiveSettingsModal } from 'app/common/components/ResponsiveSettingsModal/ResponsiveSettingsModal';
import WHITELIST_PROVIDERS from 'app/common/data/providers';
import useLocalStorage from 'app/common/hooks/useLocalStorage';
import getLang from 'app/common/services/getLang';
import IntercomWrapper from 'app/common/services/intercomWrapper';
import i18n from 'app/common/translations/i18n';

import { STATIC_BASE_URL } from 'app/config';
import isBrowserOutdated from 'app/connection/services/browserOutdated';
import { DeviceType, useDeviceType } from 'app/hooks';

import {
    ConnectionPageContainer,
    ConnectionPageLeftPart,
    ConnectionPageLeftPartContent,
    ConnectionPageRightPart,
} from './ConnectionPage.styled';
import { useProviderFromUrl } from './hooks/useProviderFromUrl';

export const Connection: FC = () => {
    const provider = useProviderFromUrl();
    const isWhiteList = WHITELIST_PROVIDERS.includes(provider);
    const { device } = useDeviceType();

    const [visible, setVisible] = useLocalStorage('downloadMobileAppBanner', true, {
        maxAge: 3 * 24 * 3600, // 3 Days
    });

    const [showSettingsModal, setShowSettingsModal] = useState(false);

    useEffect(() => {
        const shouldShowSettingsModal =
            [DeviceType.Mobile, DeviceType.Tablet].includes(device) &&
            (!isWhiteList || (isWhiteList && !visible));
        setShowSettingsModal(shouldShowSettingsModal);
    }, [device, visible, isWhiteList]);

    useEffect(() => {
        IntercomWrapper.boot();

        const browserLang = getLang();
        if (i18n.language !== browserLang) {
            i18n.changeLanguage(browserLang);
        }
    }, []);

    return (
        <div>
            {device !== DeviceType.MobileApp && (
                <>
                    {isWhiteList && <DownloadMobileApp visible={visible} setVisible={setVisible} />}
                    <ResponsiveSettingsModal
                        showModal={showSettingsModal}
                        closeModal={() => setShowSettingsModal(false)}
                    />
                </>
            )}
            <ConnectionPageContainer>
                <ConnectionPageLeftPart>
                    <ConnectionPageLeftPartContent>
                        <ProviderLogo providerLabel={provider} />
                        <BrowserOutdatedBanner />
                        <Outlet />
                    </ConnectionPageLeftPartContent>
                </ConnectionPageLeftPart>
                {isWhiteList && (
                    <ConnectionPageRightPart>
                        <img
                            src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/partoo.svg`}
                            alt="partoo"
                        />
                    </ConnectionPageRightPart>
                )}
            </ConnectionPageContainer>
        </div>
    );
};

const BrowserOutdatedBanner = () => {
    const { t } = useTranslation();
    const [isShown, setIsShown] = useState<boolean>(isBrowserOutdated());

    if (!isShown) return null;

    const onClose = () => {
        setIsShown(false);
    };

    return (
        <Banner dataTrackId="browser_outdated_banner" variant="warning" onClose={onClose}>
            {t('warning_update_your_browser')}
        </Banner>
    );
};
