import { useDispatch } from 'react-redux';

import { LoginHeaders } from 'app/api/types/connection';
import headers from 'app/api/v2/headers';
import { useJwtLogin } from 'app/pages/Login/hooks/mutations/useJwtLogin';
import { useLoginContext } from 'app/pages/Login/hooks/useLoginContext';
import { useLoginRedirect } from 'app/pages/Login/hooks/useLoginRedirect';
import { renewJwtTokenRequest } from 'app/states/jwtToken/actions/actions';

/**
 * Only called from IFRAME
 */
export const useJwtLoginMethod = (jwtHeaders: LoginHeaders) => {
    const dispatch = useDispatch();
    const { setIsLoading } = useLoginContext();
    const loginRedirect = useLoginRedirect();

    const loginJwt = useJwtLogin(
        ({ token }) => {
            // Set the token directly in the headers on Axios level
            headers.jwtToken.injectJwtToken(token);

            // Not sure what this does, it will run the saga to renew the token continuously
            dispatch(renewJwtTokenRequest());

            loginRedirect();
        },
        () => {
            setIsLoading(false);
        },
    );

    return () => {
        setIsLoading(true);

        // This one create a session cookie
        loginJwt.mutate(jwtHeaders);
    };
};
