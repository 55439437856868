import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { CATEGORIES } from 'app/common/data/queryKeysConstants';
import { useLanguage } from 'app/common/services/getLang';

export default function useCategories(query: string, country: string | undefined) {
    const lang = useLanguage();

    return useQuery(
        [CATEGORIES, { query, country, lang }],
        () => api.categories.getCategories(query, country ?? '', lang),
        {
            enabled: !!country,
        },
    );
}
