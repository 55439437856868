import React from 'react';

import { useTranslation } from 'react-i18next';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';

type Props = {
    colorState?: ColorState;
};

const CustomFieldsSectionContent = ({ colorState = ColorState.OK }: Props) => {
    const { t } = useTranslation();
    const { data: business } = useBusiness();
    const customFields = business?.custom_fields ?? [];
    const customFieldsSet = customFields.filter(
        customField => customField.value !== null,
    ).length;

    switch (colorState) {
        case ColorState.PRECHECK_ERRORS:
            return <>{t('business_edit_content_correct_this_field')}</>;
        case ColorState.MISSING_WARNING:
        case ColorState.CLOSED:
        case ColorState.DISABLED:
            return <>{t('business_edit_content_custom_fields_fill')}</>;
        case ColorState.OK:
            if (customFieldsSet === 0) {
                return <>{t('business_edit_content_custom_fields_fill')}</>;
            }
            return (
                <>
                    <i className="fa-regular fa-pen-field" />
                    <span>
                        {t('business_edit_custom_fields_set_count', {
                            count: customFieldsSet,
                        })}
                    </span>
                </>
            );
        default:
            return null;
    }
};

export default CustomFieldsSectionContent;
