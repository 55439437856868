import { useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import { JimIconCircled } from 'app/pages/settings/Chatbot/assets/jim-icon';

import { AutomatedIconWrapper } from './ConversationCard.styled';
import { useHasAssignConversationFeature } from '../../hooks/useHasAssignConversationFeature';
import AssignedUserAvatar from '../AssignedUserAvatar';

interface AssignmentIconProps {
    isAutomated: boolean;
    assignedUserId: string | null;
}

export const AssignmentIcon = ({ isAutomated, assignedUserId }: AssignmentIconProps) => {
    const { t } = useTranslation();
    const { hasAssignConversationFeature } = useHasAssignConversationFeature();

    const isAssigned = assignedUserId && hasAssignConversationFeature;

    if (isAutomated) {
        return (
            <TooltipWrapper text={t('messages_automated_conversation')} position="top-start">
                <AutomatedIconWrapper>
                    <JimIconCircled size={16} />
                </AutomatedIconWrapper>
            </TooltipWrapper>
        );
    }

    if (isAssigned) {
        return <AssignedUserAvatar userId={assignedUserId} size={16} textSize={8} />;
    }

    return null;
};
