import IS_IFRAME from 'app/common/data/iframe';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import useGroupTitle from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupTitle';
import HeaderButtons from 'app/pages/visibility/BusinessListV2/sections/BusinessListHeader/HeaderButtons';
import { DiffusionListButton } from 'app/pages/visibility/BusinessListV2/sections/BusinessListHeader/HeaderButtons/Buttons/DiffusionListButton';
import OldHeaderButton from 'app/pages/visibility/BusinessListV2/sections/BusinessListHeader/OldHeaderButton';
import { PageHeaderWithAvatar } from 'app/routing/components/PageHeaderWithAvatar';

export const BusinessListV2PageHeader = () => {
    const titleDesktop = useGroupTitle();
    const enableNewGroups = useNewGroups();

    const headerButtons = enableNewGroups
        ? [<HeaderButtons key="header_buttons" />]
        : [<OldHeaderButton key="old_header_button" />, !IS_IFRAME && <DiffusionListButton />];

    return <PageHeaderWithAvatar title={titleDesktop} actions={headerButtons} />;
};
