import { PublisherStateStats } from 'app/api/types/publisher_state';

import { PublisherState } from '../types/Publishers.types';

export const getType = (publisherStateStats: PublisherStateStats): PublisherState => {
    return (Object.entries(publisherStateStats).find(([_, value]) => value === 1)?.[0] ??
        'unavailable') as PublisherState;
};

export const getColor = (type: PublisherState) => {
    switch (type) {
        case 'success':
            return 'success';
        case 'pending':
            return 'primary';
        case 'to_configure':
            return 'warning';
        default:
            return undefined;
    }
};
