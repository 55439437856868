import { Icon, IconPrefix, Stack, Text } from '@partoohub/ui';

import {
    BusinessSelect,
    ButtonSelectContainer,
    IconContainer,
    Notice,
    TextWrap,
} from './ButtonBusinessSelect.styled';

type Props = {
    label: string;
    placeholder?: string;
    onClick?: (arg0: Event) => void;
    isMandatory?: boolean;
    hideExplanation?: boolean;
    explanation?: string;
    error?: string;
    warning?: string;
    disabled?: boolean;
    readonly?: boolean;
};

export const ButtonBusinessSelect = ({
    label,
    placeholder,
    onClick = () => undefined,
    isMandatory,
    hideExplanation = false,
    explanation = '',
    error,
    warning,
    disabled = false,
    readonly = false,
}: Props) => {
    const getColorByContext = () => {
        if (error) {
            return 'danger';
        }
        if (warning) {
            return 'warning';
        }
        return 'default';
    };
    return (
        <ButtonSelectContainer>
            <BusinessSelect disabled={disabled} readonly={readonly} onClick={onClick}>
                <Stack gap="4px" direction="column">
                    <Stack gap="0px" direction="row">
                        <Text
                            variant={label ? 'bodyXSSemibold' : 'bodyMSemibold'}
                            color="secondary"
                        >
                            {placeholder} &nbsp;
                        </Text>
                        {isMandatory && (
                            <Icon
                                icon={['fa-asterisk', IconPrefix.SOLID]}
                                iconSize="8px"
                                color="danger"
                            />
                        )}
                    </Stack>
                    <Text variant="bodyMRegular">{label}</Text>
                </Stack>
                <IconContainer>
                    <Icon icon={['fa-caret-down', IconPrefix.SOLID]} color="secondary" />
                </IconContainer>
            </BusinessSelect>
            {!hideExplanation && !error && !warning && (
                <Notice variant="bodySRegular" color={getColorByContext()}>
                    {explanation}
                </Notice>
            )}
            {!!error && (
                <TextWrap className="padding_bottom--double color--danger">{error}</TextWrap>
            )}
            {!!warning && (
                <TextWrap className={'padding_bottom--double color--warning'}>{warning}</TextWrap>
            )}
        </ButtonSelectContainer>
    );
};
