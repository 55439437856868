import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import AutosizedTextArea from 'app/common/components/AutosizedTextArea/AutosizedTextArea';

import Flexbox from 'app/styles/utils/flexbox';

export const MessageFormContainer = styled.div`
    padding: 0 16px 16px 16px;
    position: relative;
    ${(props: { minHeight: number; isTemplatePreview: boolean }) => {
        // When the template is being previewed, the form element is positioned
        // absolutely. This would cause a visible layout change as the message list
        // moves in the space of the form. To avoid that we set a min-height to the
        // form when template is shown.
        if (props.isTemplatePreview) {
            return css`
                min-height: ${props.minHeight}px;
            `;
        } else {
            return css`
                min-height: 0px;
            `;
        }
    }}
`;

export const InputsContainer = styled(Flexbox)`
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${greyColorObject.initial};
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.05));
    background-color: ${HEX_COLORS.white};
    padding: 16px;
    cursor: text;
    ${(props: { disabled?: boolean }) => {
        return (
            props.disabled &&
            css`
                background-color: ${greyColorObject.light};
            `
        );
    }}
`;

export const MessageFormElement = styled.div`
    ${(props: { isTemplatePreview: boolean }) => {
        return (
            props.isTemplatePreview &&
            css`
                // Position the form absolutely during preview to
                // avoid the messages jumping in background.
                position: absolute;
                left: 16px;
                right: 16px;
                bottom: 16px;
            `
        );
    }}
`;

export const StyledAutosizedTextArea = styled(AutosizedTextArea)`
    ${(props: { isTemplatePreview: boolean }) => {
        if (props.isTemplatePreview) {
            return css`
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                @supports (-webkit-line-clamp: 7) {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: initial;
                    display: -webkit-box;
                    -webkit-line-clamp: 7;
                    -webkit-box-orient: vertical;
                }
            `;
        }
    }}
`;

export const TextAreaButtonsContainer = styled(Flexbox)`
    margin-top: 16px;
    gap: 12px;
`;

export const ActionButtonsContainer = styled(Flexbox)`
    gap: 4px;
`;

/*
Pass a button and an optional label components as children of this wrapper
*/
export const IconButtonWithLabelWrapper = styled.div<{ isBouncing?: boolean }>`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 100%;

    ${({ isBouncing = false }) =>
        isBouncing &&
        css(`
            button {
                animation: bounce 2s infinite linear;
            }
        `)}

    label {
        position: absolute;
        top: 32px;
        visibility: hidden;

        // center text when it overflows
        display: flex;
        justify-content: center;
    }

    &:hover,
    &:focus {
        label {
            visibility: visible;
        }
    }

    @keyframes bounce {
        0% {
            transform: translate(0px, 0px);
        }
        5% {
            transform: translate(0px, -10px);
        }
        10% {
            transform: translate(0px, 0px);
        }
        15% {
            transform: translate(0px, -5px);
        }
        20% {
            transform: translate(0px, 0px);
        }
    }
`;

export const DisabledOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
`;
