import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { AnalyticsQueryKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queryKeys';

const DIMENSION_DEFAULT_VALUE = 'month';

export const useGetDimension = () => {
    const [dimensions] = useStateQueryParams(
        AnalyticsQueryKeys.DIMENSIONS_VIEWS,
    );

    return {
        dimensions: dimensions || DIMENSION_DEFAULT_VALUE,
    };
};
