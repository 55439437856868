import { IconButton, IconPrefix, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ContentLoader } from 'app/pages/Homepage/utils/ContentLoader';

import {
    StatContainer,
    StatContent,
    StatData,
    StatDescription,
    StatHeader,
    StatIconSeeMore,
    StatProductTitle,
    StatsBanner,
} from './DefaultStatComponent.styled';
import { EmptyPlaceholder } from './EmptyPlaceholder';

export type SmallStatProps = {
    small?: boolean;
};

type Props = {
    productTitle: string;
    statTitle: string;
    statSubtitle: string;
    description: string;
    productIcon?: React.ReactNode;
    onIconClick?: () => void;
    isLoading?: boolean;
    bannerUrl?: string;
    displayEmptyPlaceholder?: boolean;
    statChip?: React.ReactNode;
    dataTrack?: string;
};

export const DefaultStatComponent = ({
    productTitle,
    statTitle,
    statSubtitle,
    description,
    productIcon,
    onIconClick,
    isLoading,
    bannerUrl,
    displayEmptyPlaceholder,
    statChip,
    small,
    dataTrack,
}: Props & SmallStatProps) => {
    const { t } = useTranslation();

    if (isLoading) {
        return (
            <StatContainer>
                <ContentLoader />
            </StatContainer>
        );
    }

    return (
        <StatContainer>
            <StatContent data-track={dataTrack}>
                <StatHeader>
                    <StatProductTitle>
                        {productIcon}
                        <Text variant="bodyMBold">{productTitle}</Text>
                    </StatProductTitle>
                    {!displayEmptyPlaceholder && onIconClick && (
                        <StatIconSeeMore>
                            <IconButton
                                icon={['fa-chart-line', IconPrefix.REGULAR]}
                                color="secondary"
                                appearance="outlined"
                                tooltip={t('post_see_more_preview')}
                                dataTrackId="stats_icon_button"
                                onClick={onIconClick}
                            />
                        </StatIconSeeMore>
                    )}
                </StatHeader>

                {displayEmptyPlaceholder ? (
                    <EmptyPlaceholder />
                ) : (
                    <>
                        <StatData>
                            <Text variant="heading3">{statTitle}</Text>
                            <Text variant="bodyMRegular" color="secondary">
                                {statSubtitle}
                            </Text>
                            {statChip}
                        </StatData>

                        <StatDescription small={small}>
                            <Text variant="bodyMRegular" color="secondary">
                                {description}
                            </Text>
                        </StatDescription>
                    </>
                )}

                {bannerUrl && <StatsBanner bannerUrl={bannerUrl} small={small} />}
            </StatContent>
        </StatContainer>
    );
};
