export const COMPETITIVE_COLORS = [
    '#73BCF8',
    '#BFA6F8',
    '#FBDD73',
    '#00BAFF',
    '#947400',
    '#992842',
    '#FF5C9A',
    '#FF9014',
    '#2CB884',
    '#005091',
    '#D13C1F',
    '#B2C7E6',
];

export const CLIENT_COLOR = '#101E35';

export const DEFAULT_COLOR = 'grey';
