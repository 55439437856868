import {
    Checkbox,
    FontAwesomeIconsPartooUsed,
    Icon,
    IconPrefix,
    Stack,
    Text,
    Tooltip,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ImportFieldElement } from './ImportField.styled';

interface Props {
    name: string;
    value: boolean;
    onChange: (arg0: boolean) => void;
    // Optional props
    hasWarning?: boolean;
    disabled?: boolean;
}

export const ImportField = ({
    name,
    value,
    onChange,
    hasWarning = false,
    disabled = false,
}: Props) => {
    const { t } = useTranslation();
    return (
        <ImportFieldElement>
            <Stack direction="row" alignItems="center">
                <Text variant="bodyMBold" disabled={disabled}>
                    {name}
                </Text>
                {hasWarning && (
                    <Tooltip text={t('data_will_be_overwritten_by_google')}>
                        <Icon
                            icon={[
                                FontAwesomeIconsPartooUsed.faCircleExclamation,
                                IconPrefix.SOLID,
                            ]}
                            color="warning"
                        />
                    </Tooltip>
                )}
            </Stack>
            <Tooltip text={disabled ? t('import_disabled') : ''}>
                <Checkbox
                    dataTrackId="import_field__checkbox"
                    aria-label=""
                    checked={value}
                    onChange={() => onChange(!value)}
                    disabled={disabled}
                />
            </Tooltip>
        </ImportFieldElement>
    );
};
