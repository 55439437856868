import React from 'react';

import { useTranslation } from 'react-i18next';

import { isOpenAllDay } from 'app/businessEditV2/utils/specificHours';

import { HoursContentContainer } from 'app/common/components/OpenHour/OpenHour.styled';
import HoursItemPlaceHolder from 'app/common/components/OpenHour/OpenHourDay/HoursContent/HoursItemPlaceHolder';

import HoursList from './HoursList';

type Props = {
    disabled: boolean;
    dayValue: Array<string>;
    onChange: (dayValue: Array<string>) => void;
    hoursError: string;
    applyDefaultHours?: () => void;
};

const HoursContent = ({
    disabled,
    dayValue,
    onChange,
    hoursError,
    applyDefaultHours,
}: Props) => {
    const { t } = useTranslation();
    const onTimeWindowChange = (dayValue: Array<string>) => {
        onChange(dayValue);
    };

    return (
        <HoursContentContainer>
            {!isOpenAllDay(dayValue) ? (
                <HoursList
                    disabled={disabled}
                    openHours={dayValue}
                    onTimeWindowChange={onTimeWindowChange}
                    hoursError={hoursError}
                    applyDefaultHours={applyDefaultHours}
                />
            ) : (
                <HoursItemPlaceHolder
                    startHourTextValue={t('specific_hours__twenty_four_seven')}
                    applyDefaultHours={applyDefaultHours}
                    disabled={disabled}
                />
            )}
        </HoursContentContainer>
    );
};

export default HoursContent;
