import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

import { Button } from '@partoohub/ui';

import Card from 'app/common/designSystem/components/templates/Card';
import Flexbox from 'app/styles/utils/flexbox';

export const MessageReplyTemplateContainer = styled(Flexbox)`
    width: 100%;
    margin-top: 16px;
`;

export const CardWrapper = styled(Card)`
    border: solid 1px ${({ theme }) => theme.colors.primary.initial};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    &:hover {
        background: none;
    }
`;

export const CardContainer = styled(Flexbox)`
    width: 100%;
    padding: 24px;

    flex-direction: column;
    align-items: flex-end;
`;

export const TitleContainer = styled.div`
    width: 100%;
    input {
        font-size: 18px !important;
        font-weight: bold;
    }
    display: flex;
    align-items: center;
`;

export const TextContainer = styled.div`
    width: 100%;
    .mentions__textarea__highlighter {
        overflow-wrap: anywhere !important;
    }
    p {
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
    &:focus-within {
        p {
            color: ${({ theme }) => theme.colors.primary.initial};
        }
    }
`;

export const TextAreaPlaceholder = styled.p`
    position: absolute;
    transform: translateY(-60%);
    left: 38px;
    font-weight: bold;
    font-size: 12px;
    background-color: ${HEX_COLORS.white};
    padding: 0px 2px;
    z-index: 1;
`;

export const ButtonsContainer = styled(Flexbox)`
    flex-direction: row;
    align-items: center;
`;

export const SaveButton = styled(Button)`
    margin-left: 16px;
`;
export const PlaceholderButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
    .mdc-menu-surface--open {
        display: block;
        right: 0;
        white-space: nowrap;
    }
`;

export const PickerContainer = styled.div`
    position: absolute;
    left: 68px;
    z-index: 15;
`;
