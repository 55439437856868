import React, { useState } from 'react';

import { Expandable, FontAwesomeIconsPartooUsed, IconButton } from '@partoohub/ui';
import { Control } from 'react-hook-form/dist/types';

import { BusinessFieldArrayType, CustomFieldsSectionData } from 'app/api/types/business';

import { namesMapping } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldFormWithSections';
import { CustomField } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomField';
import {
    ClickableCustomFieldSectionContainer,
    CustomFieldsListContainer,
    SectionContainer,
    SectionCustomFieldsListContainer,
    SectionName,
} from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldSection/CustomFieldSection.styled';

type Props = {
    section: CustomFieldsSectionData;
    customFields: BusinessFieldArrayType;
    namesMapping: namesMapping;
    errors: any;
    control: Control<any>;
};

export const CustomFieldSection = ({
    section,
    customFields,
    namesMapping,
    errors,
    control,
}: Props) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    if (customFields.length === 0) {
        return;
    }

    return (
        <SectionContainer>
            <ClickableCustomFieldSectionContainer onClick={() => setIsOpened(!isOpened)}>
                <SectionName variant="bodyMBold">{section.name}</SectionName>
                <IconButton
                    appearance="outlined"
                    dataTrackId=""
                    icon={[
                        isOpened
                            ? FontAwesomeIconsPartooUsed.faChevronUp
                            : FontAwesomeIconsPartooUsed.faChevronDown,
                    ]}
                ></IconButton>
            </ClickableCustomFieldSectionContainer>
            <SectionCustomFieldsListContainer>
                <Expandable opened={isOpened}>
                    <CustomFieldsListContainer gap="36px">
                        {customFields.map(customField => (
                            <CustomField
                                key={customField.id}
                                customField={customField}
                                namesMapping={namesMapping}
                                errors={errors}
                                control={control}
                            />
                        ))}
                    </CustomFieldsListContainer>
                </Expandable>
            </SectionCustomFieldsListContainer>
        </SectionContainer>
    );
};
