import { useContext } from 'react';

import { TextInput } from '@partoohub/ui';

import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EVENT_TYPE } from 'app/presence/googlePosts/variables/googlePostType';

import { NewPostContext, NewPostContextType } from '../../context/NewPost';

const TITLE_TEXT_LIMIT = 58;

export const ControlledEventTitleField = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const rules = {
        required: true,
        maxLength: TITLE_TEXT_LIMIT,
    };
    const {
        formFields: { type },
    } = useContext<NewPostContextType>(NewPostContext);

    const { field, formState } = useController({ name: 'event_title', control, rules });
    const hasErrorsEventTitleRequired = formState.errors.event_title?.type === 'required';
    const hasErrorsEventTitleLength = formState.errors.event_title?.type === 'maxLength';

    const handleChange = (value: string) => {
        field.onChange(value?.slice(0, TITLE_TEXT_LIMIT));
    };

    return (
        <TextInput
            {...field}
            dataTrackId={
                type === EVENT_TYPE ? t('google_post_event_title') : t('google_post_offer_title')
            }
            label={
                type === EVENT_TYPE ? t('google_post_event_title') : t('google_post_offer_title')
            }
            onChange={handleChange}
            required
            characterCount={TITLE_TEXT_LIMIT}
            error={hasErrorsEventTitleRequired || hasErrorsEventTitleLength}
            notice={hasErrorsEventTitleRequired && t('field_is_necessary')}
        />
    );
};
