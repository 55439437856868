import { ReactNode } from 'react';

import { StyledPBoxFooter } from './PBox.styled';

interface Props {
    boxLegend: string | ReactNode;
}

export const PBoxFooter = ({ boxLegend }: Props) => (
    <StyledPBoxFooter>{boxLegend}</StyledPBoxFooter>
);
