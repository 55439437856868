import { put } from 'redux-saga/effects';

import extractBadRequestErrors from 'app/common/services/extractBadRequestErrors';
import { Saga } from 'app/common/types';

const UNKNOWN_ERROR = 'unknown error';

const defaultFormatter = content => content;

function* storeError(
    failureActionCreator: (arg0: Record<string, any>) => Record<string, any>,
    error: Record<string, any>,
    formatter: (
        arg0: Record<string, any>,
    ) => Record<string, any> = defaultFormatter,
): Saga {
    if (error.response && error.response.status === 400) {
        const errorMessage = extractBadRequestErrors(error, formatter);

        if (typeof errorMessage === 'string') {
            yield put(
                // @ts-ignore
                failureActionCreator({
                    global: errorMessage,
                }),
            );
        } else {
            // @ts-ignore
            yield put(failureActionCreator(errorMessage));
        }
    } else {
        yield put(
            // @ts-ignore
            failureActionCreator({
                global: UNKNOWN_ERROR,
            }),
        );
    }
}

export default storeError;
