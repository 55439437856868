import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useHasAssignConversationFeature } from 'app/reviewManagement/messaging/hooks/useHasAssignConversationFeature';

export const useIsLoadingMessaging = () => {
    const { isLoading: meLoading } = useMe();
    const { isLoading: subscriptionsLoading } = useBusinessSubscriptions();
    const { isLoading: assignRightsLoading } = useHasAssignConversationFeature();

    return meLoading || subscriptionsLoading || assignRightsLoading;
};
