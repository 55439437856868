import { connect } from 'react-redux';

import { numberFormatter } from 'app/common/services/formatNumber';
import { isMobile } from 'app/common/services/screenDimensions';
import { AppState } from 'app/states/reducers';
import {
    isFetchingPeriodSelector,
    isFetchingTotalSelector,
    periodReviewsSelector,
    totalReviewsSelector,
} from 'app/states/reviewAnalytics';
import {
    dateFilterSelector,
    reviewFiltersSelector,
} from 'app/states/reviews/filters';

import RatingsComponent from './Ratings';

const mapStateToProps = (state: AppState) => {
    const { reviewAnalytics } = state;
    const reviewFilters = reviewFiltersSelector(state);
    const dateFilters = dateFilterSelector(reviewFilters);
    const totalRatings = numberFormatter(
        +totalReviewsSelector(reviewAnalytics),
    );
    const periodRatings = numberFormatter(
        +periodReviewsSelector(reviewAnalytics),
    );
    const isSmall = isMobile() && totalRatings.length > 5;

    return {
        totalRatings,
        periodRatings,
        inputPeriod: dateFilters.from || dateFilters.to,
        isSmall,
        leftLoading: isFetchingPeriodSelector(reviewAnalytics),
        rightLoading: isFetchingTotalSelector(reviewAnalytics),
    };
};

// @ts-ignore
const Ratings = connect(mapStateToProps)(RatingsComponent);

export default Ratings;
