import React from 'react';

import { withTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { AccountPartnerName } from 'app/api/types/account';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';
import StatefulButton, {
    ButtonStatus,
    LOADING,
    SUCCESSFUL,
    TOSAVE,
} from 'app/common/components/buttons/StatefulButton';

import { connectBusinessToLocation } from 'app/states/knowledge/reducers/businessConnection/connection';

type StatefulButtonComponentProps = {
    label: string;
    status: ButtonStatus;
    partnerResourceName: AccountPartnerName;
    hasPM: boolean;
    // Optional props
    preventClick?: boolean;
    saveIcon?: string;
};

const getLabel = (
    status: ButtonStatus,
    partnerResourceName: AccountPartnerName,
    hasPM: boolean,
) => {
    switch (status) {
        case LOADING:
            return `connection_to_${partnerResourceName}_location_in_progress`;

        case SUCCESSFUL:
            return `connection_to_${partnerResourceName}_location_successful`;

        case TOSAVE:
            return hasPM
                ? `connection_to_${partnerResourceName}_location_validate_location_and_push`
                : `connection_to_${partnerResourceName}_location_validate_location`;

        default:
            return `connection_to_${partnerResourceName}_location_select_location`;
    }
};

const StatefulButtonComponent = ({
    label,
    status,
    partnerResourceName,
    hasPM,
    saveIcon,
    preventClick,
}: StatefulButtonComponentProps) => {
    const dispatch = useDispatch();
    const { selectedLocationId, owningBusinessId } = usePartnerConnectionContext();

    let newStatus = status;
    let newLabel = label;

    if (selectedLocationId && status !== LOADING && status !== SUCCESSFUL && !label) {
        newStatus = TOSAVE;
    }

    if (!newLabel) {
        newLabel = getLabel(newStatus, partnerResourceName, hasPM);
    }

    return (
        <StatefulButton
            status={newStatus}
            label={newLabel}
            onClick={() =>
                dispatch(connectBusinessToLocation(selectedLocationId, owningBusinessId))
            }
            preventClick={preventClick}
            saveIcon={saveIcon}
        />
    );
};

export default withTranslation()(StatefulButtonComponent);
