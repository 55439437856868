import { useContext, useMemo } from 'react';

import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import InfoCard, {
    Content,
    ContentColumnComponent,
    Title,
} from 'app/common/designSystem/components/molecules/InfoCard/InfoCard';
import PreviewPostCard from 'app/common/designSystem/components/molecules/PreviewPostCard/PreviewPostCard';

import { STATIC_BASE_URL } from 'app/config';
import {
    EditPostsContext,
    EditPostsContextType,
} from 'app/presence/googlePosts/components/googlePostList/PostsList';

import usePostBulkCount from 'app/presence/googlePosts/hooks/usePostBulkCount';

import {
    GlobalContentRightSideContainer,
    InfoCardTitleIcon,
    PreviewText,
} from './PostPreview.styled';
import { NewPostContext, NewPostContextType } from '../../context/NewPost';
import useUnauthorizedBusiness from '../../hooks/useUnauthorizedBusiness';

export default function PostPreview() {
    const { t } = useTranslation();
    const {
        formFields: { platform, type = 'news', postImage },
        unauthorizedBusinessCounts = {},
    } = useContext<NewPostContextType>(NewPostContext);

    const {
        bulkId,
        postToEditData: { businessInfo },
    } = useContext<EditPostsContextType>(EditPostsContext);

    const { getValues } = useFormContext();
    const { hasUnauthorizedBusinessInAnyPlatform } = useUnauthorizedBusiness(
        unauthorizedBusinessCounts,
    );

    const { bulkCount } = usePostBulkCount(bulkId);

    const businessCountRedux = useSelectedBusinessesCount();
    const businessCount = bulkCount || businessCountRedux;
    const {
        post_description: postDescription,
        event_start_date: eventStartDate,
        event_end_date: eventEndDate,
        event_title: eventTitle,
    } = getValues();

    const dateString = `${eventStartDate ? format(eventStartDate, 'dd MMM') : ''}-${eventEndDate ? format(eventEndDate, 'dd MMM yyyy') : ''}`;

    const imageUrl = useMemo(() => {
        if (postImage.length > 0) {
            if (postImage[0]?.file && postImage[0]?.file instanceof Blob) {
                return URL.createObjectURL(postImage[0].file);
            }
            return postImage[0].data_url;
        }
        return `${STATIC_BASE_URL}/images/app/post_image_placeholder.png`;
    }, [postImage]);

    return (
        <GlobalContentRightSideContainer>
            <PreviewText>{t('preview')} </PreviewText>
            <PreviewPostCard
                topicType={type}
                photoUrl={imageUrl}
                title={eventTitle || ''}
                dates={dateString?.length > 1 ? dateString : ''}
                summary={postDescription}
                businessInfo={bulkId ? '' : businessInfo}
            />
            <InfoCard>
                <Title>
                    <InfoCardTitleIcon>
                        <i className="fa fa-info-circle fa-xs" />
                    </InfoCardTitleIcon>
                    {t('information')}
                </Title>
                <Content>
                    <ContentColumnComponent
                        title={t('platform_filter')}
                        text={platform.map(word => capitalize(word)).join(', ')}
                        iconClass="fa-light fa-share-nodes"
                    />
                    <ContentColumnComponent
                        showAlert={hasUnauthorizedBusinessInAnyPlatform}
                        title={t('locations')}
                        text={`${t('businesses_with_count_plural', {
                            count: businessCount || 0,
                        })}`}
                        iconClass="fa-light fa-store"
                    />
                </Content>
            </InfoCard>
        </GlobalContentRightSideContainer>
    );
}
