import styled from '@emotion/styled';
import { Button } from '@partoohub/ui';

export const StyledSaveBar = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    background: ${({ theme }) => theme.colors.theme.initial};
`;

export const StyledButton = styled(Button)`
    display: flex;
    flex: 1 1 50%;
`;
