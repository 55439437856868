import React from 'react';

import { ColoredPath } from 'app/common/components/icons/Sidebar/ColoredPath.styled';

type Props = {
    isActive?: boolean;
};

const HomepageIconSidebar = React.forwardRef<SVGSVGElement, Props>(({ isActive = false }, ref) => (
    <svg
        ref={ref}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="40px"
    >
        {isActive ? (
            <>
                <ColoredPath d="M16.0121 8.3335H10.8642C9.47148 8.3335 8.33398 9.471 8.33398 10.8637V16.0116C8.33398 17.4043 9.47148 18.5418 10.8642 18.5418H16.0121C17.4048 18.5418 18.5423 17.4043 18.5423 16.0116V10.8637C18.5423 9.471 17.4048 8.3335 16.0121 8.3335Z" />
                <ColoredPath d="M29.1371 9.79183C29.7277 9.79183 30.209 10.2731 30.209 10.8637V16.0116C30.209 16.6022 29.7277 17.0835 29.1371 17.0835H23.9892C23.3986 17.0835 22.9173 16.6022 22.9173 16.0116V10.8637C22.9173 10.2731 23.3986 9.79183 23.9892 9.79183H29.1371ZM29.1371 8.3335H23.9892C22.5965 8.3335 21.459 9.471 21.459 10.8637V16.0116C21.459 17.4043 22.5965 18.5418 23.9892 18.5418H29.1371C30.5298 18.5418 31.6673 17.4043 31.6673 16.0116V10.8637C31.6673 9.471 30.5298 8.3335 29.1371 8.3335Z" />
                <ColoredPath d="M16.0121 22.9168C16.6027 22.9168 17.084 23.3981 17.084 23.9887V29.1366C17.084 29.7272 16.6027 30.2085 16.0121 30.2085H10.8642C10.2736 30.2085 9.79232 29.7272 9.79232 29.1366V23.9887C9.79232 23.3981 10.2736 22.9168 10.8642 22.9168H16.0121ZM16.0121 21.4585H10.8642C9.47148 21.4585 8.33398 22.596 8.33398 23.9887V29.1366C8.33398 30.5293 9.47148 31.6668 10.8642 31.6668H16.0121C17.4048 31.6668 18.5423 30.5293 18.5423 29.1366V23.9887C18.5423 22.596 17.4048 21.4585 16.0121 21.4585Z" />
                <ColoredPath d="M29.1371 21.4585H23.9892C22.5965 21.4585 21.459 22.596 21.459 23.9887V29.1366C21.459 30.5293 22.5965 31.6668 23.9892 31.6668H29.1371C30.5298 31.6668 31.6673 30.5293 31.6673 29.1366V23.9887C31.6673 22.596 30.5298 21.4585 29.1371 21.4585Z" />
            </>
        ) : (
            <>
                <path
                    d="M16.0121 9.79183C16.6027 9.79183 17.084 10.2731 17.084 10.8637V16.0116C17.084 16.6022 16.6027 17.0835 16.0121 17.0835H10.8642C10.2736 17.0835 9.79232 16.6022 9.79232 16.0116V10.8637C9.79232 10.2731 10.2736 9.79183 10.8642 9.79183H16.0121ZM16.0121 8.3335H10.8642C9.47148 8.3335 8.33398 9.471 8.33398 10.8637V16.0116C8.33398 17.4043 9.47148 18.5418 10.8642 18.5418H16.0121C17.4048 18.5418 18.5423 17.4043 18.5423 16.0116V10.8637C18.5423 9.471 17.4048 8.3335 16.0121 8.3335Z"
                    fill="white"
                />
                <path
                    d="M29.1371 9.79183C29.7277 9.79183 30.209 10.2731 30.209 10.8637V16.0116C30.209 16.6022 29.7277 17.0835 29.1371 17.0835H23.9892C23.3986 17.0835 22.9173 16.6022 22.9173 16.0116V10.8637C22.9173 10.2731 23.3986 9.79183 23.9892 9.79183H29.1371ZM29.1371 8.3335H23.9892C22.5965 8.3335 21.459 9.471 21.459 10.8637V16.0116C21.459 17.4043 22.5965 18.5418 23.9892 18.5418H29.1371C30.5298 18.5418 31.6673 17.4043 31.6673 16.0116V10.8637C31.6673 9.471 30.5298 8.3335 29.1371 8.3335Z"
                    fill="white"
                />
                <path
                    d="M16.0121 22.9168C16.6027 22.9168 17.084 23.3981 17.084 23.9887V29.1366C17.084 29.7272 16.6027 30.2085 16.0121 30.2085H10.8642C10.2736 30.2085 9.79232 29.7272 9.79232 29.1366V23.9887C9.79232 23.3981 10.2736 22.9168 10.8642 22.9168H16.0121ZM16.0121 21.4585H10.8642C9.47148 21.4585 8.33398 22.596 8.33398 23.9887V29.1366C8.33398 30.5293 9.47148 31.6668 10.8642 31.6668H16.0121C17.4048 31.6668 18.5423 30.5293 18.5423 29.1366V23.9887C18.5423 22.596 17.4048 21.4585 16.0121 21.4585Z"
                    fill="white"
                />
                <path
                    d="M29.1371 22.9168C29.7277 22.9168 30.209 23.3981 30.209 23.9887V29.1366C30.209 29.7272 29.7277 30.2085 29.1371 30.2085H23.9892C23.3986 30.2085 22.9173 29.7272 22.9173 29.1366V23.9887C22.9173 23.3981 23.3986 22.9168 23.9892 22.9168H29.1371ZM29.1371 21.4585H23.9892C22.5965 21.4585 21.459 22.596 21.459 23.9887V29.1366C21.459 30.5293 22.5965 31.6668 23.9892 31.6668H29.1371C30.5298 31.6668 31.6673 30.5293 31.6673 29.1366V23.9887C31.6673 22.596 30.5298 21.4585 29.1371 21.4585Z"
                    fill="white"
                />
            </>
        )}
    </svg>
));

export default HomepageIconSidebar;
