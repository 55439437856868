import { CommentData } from 'app/api/types/comments';
import { ReviewObject } from 'app/states/reviews';

const deleteCommentData = (
    reviewComments: Array<CommentData>,
    commentId: number,
): Array<CommentData> => {
    let commentWasFound = false;

    return reviewComments
        .map(reviewComment => {
            if (commentWasFound) return reviewComment;

            if (reviewComment.id === commentId) {
                commentWasFound = true;
                return false;
            }

            if (reviewComment.replies) {
                return {
                    ...reviewComment,
                    replies: deleteCommentData(reviewComment.replies, commentId),
                };
            }

            return reviewComment;
        })
        .filter(Boolean) as Array<CommentData>;
};

export const deleteReviewCommentData = (review: ReviewObject, commentId: number) => {
    const { comments: reviewComments } = review;
    const newComments = deleteCommentData(reviewComments, commentId);
    return { ...review, comments: newComments };
};
