import { Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import Carousel from 'app/common/designSystem/components/molecules/Carousel';

import { STATIC_BASE_URL } from 'app/config';

import {
    ModalContent,
    ModalImage,
    ModalParagraph,
    ModalSlide,
    ModalText,
    ModalTitle,
} from './../BulkUpdateForm/BulkUpdatedForm.styled';

type Props = {
    show: boolean;
    handleClose: () => void;
};

const baseUrl = `${STATIC_BASE_URL}/images/common/bulk_update/bulk_import_instruction`;

export const InstructionModal = ({ show, handleClose }: Props) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={show} closeModal={handleClose}>
            <ModalContent>
                <Carousel arrowPosition="top">
                    {[1, 2].map(slideId => (
                        <ModalSlide key={slideId}>
                            <ModalImage
                                src={`${baseUrl}_${slideId}.png`}
                                alt={t(`bulk_update_import_instruction_${slideId}_title`)}
                            />
                            <ModalText>
                                <ModalTitle>
                                    {t(`bulk_update_import_instruction_${slideId}_title`)}
                                </ModalTitle>
                                <ModalParagraph>
                                    {t(`bulk_update_import_instruction_${slideId}_text`)}
                                </ModalParagraph>
                                <ModalParagraph isExample>
                                    {t(`bulk_update_import_instruction_${slideId}_example`)}
                                </ModalParagraph>
                            </ModalText>
                        </ModalSlide>
                    ))}
                </Carousel>
            </ModalContent>
        </Modal>
    );
};
