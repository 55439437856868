import { format } from 'date-fns';

import {
    ALERT_TYPE,
    COVID_TYPE,
    EVENT_TYPE,
    NEWS_TYPE,
    OFFER_TYPE,
} from 'app/presence/googlePosts/variables/googlePostType';

// todo do add this type
export default function mapApiResponseToFormState(post: any, isUseBusinessUrl?: boolean) {
    const contentLinkValue =
        post.ctaLink?.length > 0
            ? isUseBusinessUrl
                ? 'use_business_url'
                : 'custom_link'
            : 'no_link';
    const googleCtaLabel = post?.ctaType;

    switch (post.postType) {
        case EVENT_TYPE:
            return {
                event_title: post.title,
                post_description: post.summary,
                event_start_date: new Date(post.startAt),
                event_start_time: format(new Date(post.startAt), 'HH:mm'),
                event_end_date: new Date(post.endAt),
                event_end_time: format(new Date(post.endAt), 'HH:mm'),
                google_custom_link: googleCtaLabel,
                content_link: contentLinkValue,
                custom_link: post.ctaLink,
            };

        case NEWS_TYPE:
        case ALERT_TYPE:
        case COVID_TYPE:
            return {
                post_description: post.summary,
                content_link: contentLinkValue,
                custom_link: post.ctaLink,
                google_custom_link: googleCtaLabel,
            };

        case OFFER_TYPE:
            return {
                event_title: post.title,
                post_description: post.summary,
                event_start_date: new Date(post.startAt),
                event_start_time: format(new Date(post.startAt), 'HH:mm'),
                event_end_date: new Date(post.endAt),
                event_end_time: format(new Date(post.endAt), 'HH:mm'),
                content_link: contentLinkValue,
                custom_link: post.ctaLink,
                offer_coupon_code: post.offerCode,
                event_terms_and_conditions: post.offerTerms,
            };

        default:
            return {};
    }
}
