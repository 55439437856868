import { Button, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import dataLayer from 'app/common/utils/dataLayer';

import { PostCreationModalTemplateContainer } from './NextBackButtons.styled';

type Props = {
    disabled: boolean;
    nextButtonTextKey?: string;
    onNext?: () => void;
    onPrevious?: () => void;
    isLoading?: boolean;
};

export default function NextBackButtons({
    disabled = true,
    nextButtonTextKey,
    onNext = () => {},
    onPrevious,
    isLoading = false,
}: Props) {
    const { t } = useTranslation();
    return isLoading ? (
        <Button
            full
            dataTrackId="post_creation_modal_loader"
            appearance="contained"
            shape="cube"
            variant="primary"
            size="large"
            icon={['fa-loader', IconPrefix.SOLID]}
            disabled={true}
        >
            {t('loading')}
        </Button>
    ) : (
        <PostCreationModalTemplateContainer>
            {onPrevious && (
                <Button
                    dataTrackId="post_creation_modal_go_back"
                    appearance="outlined"
                    shape="cube"
                    variant="secondary"
                    size="large"
                    icon={['fa-arrow-left', IconPrefix.SOLID]}
                    onClick={onPrevious}
                >
                    {t('back')}
                </Button>
            )}
            <Button
                full
                dataTrackId="post_creation_modal_go_next"
                appearance="contained"
                shape="cube"
                variant="primary"
                size="large"
                icon={['fa-arrow-right', IconPrefix.SOLID]}
                disabled={disabled}
                onClick={() => {
                    if (nextButtonTextKey === 'publish_google_post') {
                        dataLayer.pushDict('click_publish_post');
                    }
                    onNext();
                }}
                iconPosition="right"
            >
                {t(nextButtonTextKey ?? 'next')}
            </Button>
        </PostCreationModalTemplateContainer>
    );
}
