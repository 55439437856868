import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';
import { Button, Text } from '@partoohub/ui';

export const CreationModalContainer = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
`;

export const CreationModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 42px 0;
    width: 698px;
    margin-inline: auto;
`;

export const CreationModalButtons = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 30px;
    right: 30px;
    gap: 16px;
`;

export const CreationModalTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    gap: 8px;
    overflow: hidden;
    flex-shrink: 0;
`;

export const CreationModalIcon = styled.img`
    width: 24px;
    height: 24px;
`;

export const CreationModalDescription = styled(Text)`
    margin-bottom: 24px;
`;

export const CreationModalForm = styled.div`
    padding: 30px 24px;
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
`;

export const CreationModalFormSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    > div {
        flex-grow: 1;
    }
`;

export const ParamsFormContainer = styled.div`
    margin-top: 24px;
`;

export const SelectButton = styled(Button)``;
