import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';
import { useShortcutsItems } from 'app/pages/Homepage/hooks/shortcuts/useShortcutsItems';

import { HomepageShortcutsContainer, HomepageShortcutsGrid } from './HomepageShortcuts.styled';

export const HomepageShortcuts = () => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const shortcutsItems = useShortcutsItems();

    if (!shortcutsItems.length) {
        return null;
    }

    return (
        <HomepageShortcutsContainer>
            <HomepageShortcutsGrid mobileView={isBelowProvidedDevices}>
                {shortcutsItems.map(({ component: Component, key }) => (
                    <Component key={key} />
                ))}
            </HomepageShortcutsGrid>
        </HomepageShortcutsContainer>
    );
};
