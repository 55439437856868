import { useContext, useState } from 'react';

import { TemplateFormContext } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/components/MessagesReplyTemplates.context';

export const useMessagesReplyTemplateFilter = () => {
    const [query, setQuery] = useState('');
    const { setFilteringTitle } = useContext(TemplateFormContext);

    const onChange = (input: string) => {
        setQuery(input);
        setFilteringTitle(input);
    };

    const onReset = () => {
        setQuery('');
        setFilteringTitle('');
    };

    return {
        query,
        onChange,
        onReset,
    };
};
