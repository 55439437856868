import { useCallback } from 'react';

import { IconButton, IconPrefix, toast } from '@partoohub/ui';
import { Option } from '@partoohub/ui/dist/types/components/menuList';
import { useTranslation } from 'react-i18next';

import { ReviewObjectType } from 'app/api/types/review';
import api from 'app/api/v2/api_calls';
import dataLayer from 'app/common/utils/dataLayer';
import { useCanDownloadClientReviews } from 'app/pages/customerExperience/useCanDownloadClientReviews';
import { useDownloadFeedbackResults } from 'app/reviewManagement/reviewList/hooks/useDownloadFeedbackResults';
import { useGetReviewsQueryParams } from 'app/reviewManagement/reviewList/hooks/useGetReviewsQueryParams';
import { useReviewListCount } from 'app/reviewManagement/reviewList/hooks/useReviewListCount';
import { ActionButtonWithMenu } from 'app/reviewManagement/reviewList/sections/PageHeader/DesktopHeader/components/ActionButtonWithMenu';
import {
    ReviewListModal,
    useReviewListModalsContext,
} from 'app/reviewManagement/reviewList/sections/ReviewListModals/ReviewListModals.context';
import { DOWNLOADABLE_MAX } from 'app/states/reviews';

export const DownloadButton = () => {
    const { t } = useTranslation();
    const modals = useReviewListModalsContext();
    const reviewsCountByType = useReviewListCount();

    const { canDownloadClientReviews } = useCanDownloadClientReviews();
    const reviewsQueryParams = useGetReviewsQueryParams();
    const downloadReviews = useCallback(
        ({ email }: { email: string }) => {
            // GTM
            dataLayer.pushDict('click_download_rm');

            api.review
                .downloadReviews({
                    ...reviewsQueryParams,
                    state__in: null, // Ignoring any state filter
                    email,
                })
                .then(() => {
                    toast.success(null, t('your_reviews_will_be_sent_by_email'));
                })
                .catch(() => {
                    toast.error(null, t('reply_error'));
                });
        },
        [reviewsQueryParams],
    );
    const downloadFeedbacks = useDownloadFeedbackResults();

    const configuration = {
        [ReviewObjectType.REVIEW]: {
            option: {
                value: ReviewObjectType.REVIEW,
                name: ReviewObjectType.REVIEW,
                label: t('review_object_type_reviews'),
                icon: ['fa-star'],
            },
            show:
                Boolean(canDownloadClientReviews[ReviewObjectType.REVIEW]) &&
                (reviewsCountByType[ReviewObjectType.REVIEW] ?? 0) > 0,
            modal: modals[ReviewListModal.DOWNLOAD_REVIEWS],
            download: downloadReviews,
            disabled: (reviewsCountByType[ReviewObjectType.REVIEW] ?? 0) >= DOWNLOADABLE_MAX,
        },
        [ReviewObjectType.FEEDBACK_RESULT]: {
            option: {
                value: ReviewObjectType.FEEDBACK_RESULT,
                name: ReviewObjectType.FEEDBACK_RESULT,
                label: t('review_object_type_feedback_results'),
                icon: ['fa-gauge'],
            },
            show:
                Boolean(canDownloadClientReviews[ReviewObjectType.FEEDBACK_RESULT]) &&
                (reviewsCountByType[ReviewObjectType.FEEDBACK_RESULT] ?? 0) > 0,
            modal: modals[ReviewListModal.DOWNLOAD_FEEDBACKS],
            download: downloadFeedbacks,
            disabled:
                (reviewsCountByType[ReviewObjectType.FEEDBACK_RESULT] ?? 0) >= DOWNLOADABLE_MAX,
        },
    };

    const menuItems = Object.values(configuration).reduce((menuItems, { show, option }) => {
        if (show) {
            menuItems.push(option as Option);
        }

        return menuItems;
    }, [] as Option[]);

    if (!menuItems.length) {
        return <></>;
    }

    const onMenuClick = async (value: ReviewObjectType) => {
        if (configuration[value].disabled) {
            return toast.warning(t('rm_can_not_download'), t('infos'));
        }

        const { modal, download } = configuration[value];
        const confirmed = await modal.askConfirmation();
        if (confirmed) {
            download(confirmed);
        }
    };

    if (menuItems.length === 1) {
        const value = menuItems[0].value as ReviewObjectType;
        const disabled = configuration[value].disabled;

        return (
            <IconButton
                dataTrackId="review-download-button"
                icon={['fa-download ', IconPrefix.SOLID]}
                tooltip={t(disabled ? 'rm_can_not_download' : 'download_reviews')}
                onClick={() => onMenuClick(value)}
                disabled={disabled}
            />
        );
    }

    return (
        <ActionButtonWithMenu
            dataTrackId="review-download-button"
            icon={['fa-download ', IconPrefix.SOLID]}
            appearance="contained"
            // @ts-expect-error
            onMenuClick={onMenuClick}
            options={menuItems}
            tooltip={t('download_reviews')}
        />
    );
};
