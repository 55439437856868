import ConnectionModal from 'app/businessConnection/components/modals/ConnectionModal';
import { PartnerConnectionProvider } from 'app/businessConnection/components/PartnerConnectionContext';
import { BusinessSearchParams } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

import {
    FunnelModalBody,
    FunnelModalHeader,
} from 'app/common/components/FunnelModal/FunnelModal.styled';

import { DiffusionChart } from './sections/DiffusionChart/DiffusionChart';
import { DiffusionHeader } from './sections/DiffusionHeader/DiffusionHeader';
import { DiffusionPublishers } from './sections/DiffusionPublishers/DiffusionPublishers';

export const DIFFUSION_SEARCH_PARAMS: BusinessSearchParams = {
    subscribed_to_pm: true,
};

export const DiffusionList = () => {
    return (
        <>
            <FunnelModalHeader>
                <DiffusionHeader />
            </FunnelModalHeader>
            <FunnelModalBody>
                <DiffusionChart />
                <DiffusionPublishers />
                <PartnerConnectionProvider>
                    <ConnectionModal />
                </PartnerConnectionProvider>
            </FunnelModalBody>
        </>
    );
};
