import React from 'react';

import { Switch } from '@partoohub/ui';
import { useController } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { SsoOnlyFieldContainer } from './SsoOnlyField.styled';

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

const SsoOnlyField = ({ control, name }: ControlledComponent) => {
    const { t } = useTranslation();
    const {
        field: { value, onChange },
    } = useController({ control, name });

    return (
        <SsoOnlyFieldContainer>
            <Switch
                checked={value}
                onChange={onChange}
                name="sso_only_toggle"
                dataTrackId="sso_only_toggle"
                label={t('user_connect_with_sso_only')}
            />
        </SsoOnlyFieldContainer>
    );
};

export default SsoOnlyField;
