import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const SlidesContainer = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-between;
`;

export const Slide = styled.div`
    display: flex;
    flex-direction: column;
    width: calc((100% / 5 - (8px * 4)));
    gap: 8px;
    & > svg {
        width: 100%;
        height: auto;
    }
`;

export const SlideTitle = styled(Text)`
    text-align: center;
`;
