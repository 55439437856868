import styled from '@emotion/styled';

export const QueriesChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.theme.background};
    border: 1px solid #dddfe3;
    border-radius: 4px;
    padding: 16px;
`;
