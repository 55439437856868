import { useMutation } from 'react-query';

import { SaveReplyTemplatePayload } from 'app/api/types/reviewReplyTemplates';
import api from 'app/api/v2/api_calls';
import { REPLY_TEMPLATE_LIST } from 'app/common/data/queryKeysConstants';
import dataLayer from 'app/common/utils/dataLayer';
import queryClient from 'app/states/queryClient';

export function useSaveTemplate(duplicated: boolean) {
    return useMutation(
        async (template: SaveReplyTemplatePayload) => {
            const { id, ...templateWithoutId } = template;

            let payload: SaveReplyTemplatePayload;
            if (duplicated || !id) {
                dataLayer.pushDict('create_template');
                payload = templateWithoutId;
            } else {
                dataLayer.pushDict('modify_template');
                payload = template;
            }

            return await api.review.saveTemplate(payload);
        },
        {
            onSuccess: () => queryClient.invalidateQueries([REPLY_TEMPLATE_LIST]),
        },
    );
}
