import styled from '@emotion/styled';

import { Button, Text } from '@partoohub/ui';

export const ModalContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface StyledModalContentProps {
    marginTop?: string;
}

export const StyledModalContent = styled.div<StyledModalContentProps>`
    margin-top: ${props => props.marginTop || '20px'};
    padding: 0 35px 16px 36px;
    text-align: center;
    width: 490px;
    box-sizing: border-box;
`;

export const StyledMessengerIcon = styled.img`
    width: 170px;
    position: absolute;
    transform: translate(-50%, 45px);
    left: 50%;
`;

export const StyledInstagramIcon = styled.img`
    width: 114px;
    position: absolute;
    transform: translate(-50%, 45px);
    left: 50%;
`;

export const StyledFailureIcon = styled.img`
    width: 70%;
    height: 65%;
    margin-top: 15px;
    margin-bottom: 24px;
`;

interface StyledHeadingProps {
    marginTop?: string;
}

export const StyledBodyText = styled(Text)`
    margin-top: 8px;
    text-align: center;
`;

export const StyledBodyText2 = styled(Text)`
    margin-top: 24px;
    text-align: center;
`;

export const StyledList = styled.ul`
    text-align: left;
    margin-top: 10px;
`;

export const StyledButtonContainer = styled.div`
    width: 100%;
    padding: 8px;
`;

export const StyledButton = styled(Button)`
    margin-bottom: 24px;
`;

export const StyledCancelButton = styled.div`
    text-align: center;
    margin-top: 28px;
    margin-bottom: 12px;
`;

export const StyledErrorBackground = styled.img`
    margin-top: 24px;
`;

export const LargeButton = styled(Button)`
    width: 100%;
    height: 60px;
    margin-top: 6px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 700;
`;

export const StyledPreviousButtonContainer = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    top: 8px;
    left: 8px;
`;

export const StyledCheckableBoxContainer = styled.div`
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 23px;
    margin-bottom: 13px;
    text-align: center;
`;

export const StyledInstagramSuccessCheckIcon = styled.div`
    position: absolute;
    top: 45px;

    .instagram-3d-icon {
        width: 114px;
    }
    .check-mark-3d {
        width: 60px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(40px, 4px);
    }
`;

export const StyledFailureHeading = styled(Text)<StyledHeadingProps>`
    text-align: center;
    width: 310px;
    margin-top: 0px;
    margin-bottom: 20px;
`;

export const StyledFailureFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 400px;
    margin-bottom: 20px;
`;
