import { AccountButton } from 'app/common/components/funnel/Buttons.styled';
import { STATIC_BASE_URL } from 'app/config';
import { useImportFunnelOAuthAccount } from 'app/onboarding/OnboardingHooks';

type Props = {
    text: string;
};

export const GoogleButton = ({ text }: Props) => {
    const { startOAuthFlow } = useImportFunnelOAuthAccount();

    return (
        <AccountButton
            dataTrackId="add_google_account_onboarding"
            onClick={() => startOAuthFlow(undefined, ['onboarding_funnel'])}
            size="large"
            variant="secondary"
            appearance="outlined"
            shape="cube"
            iconPosition="left"
            icon={
                <img
                    src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/google-white.svg`}
                    alt="google"
                />
            }
        >
            {text}
        </AccountButton>
    );
};

export default GoogleButton;
