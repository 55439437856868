import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TitleContainer = styled.h4`
    font-weight: bold;
`;

export const DateContainer = styled.section`
    display: flex;

    > div {
        margin: 0 8px;
    }

    > div:first-of-type {
        margin-left: 0;
    }

    > div:last-child {
        margin-right: 0;
    }
`;

export const DatePickerContainer = styled.div<{ hasError: boolean }>`
    position: relative;

    ${({ hasError, theme }) =>
        hasError &&
        css`
            > label {
                color: ${theme.colors.danger.initial};
            }
            > div {
                // SingleDatePicker
                border-color: ${theme.colors.danger.initial};
            }
        `}
`;

export const ValidationGlobalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 10px;
`;

export const RequiredFieldContainer = styled.div`
    color: ${({ theme }) => theme.colors.danger.initial};
    font-size: 12px;
    margin-top: auto;
    margin-bottom: auto;
`;

export const DateSection = styled.section``;
