import { StrictMode } from 'react';

import { setAutoFreeze } from 'immer';
import { createRoot } from 'react-dom/client';

import App from './App';
import './setupSentry';
import 'app/config';

// Unfortunately, states are mutated in several places in the codebase,
// so we disable the auto-freeze feature from immer, otherwise it would
// cause "cannot mutate readonly attribute" type of exceptions.
// https://immerjs.github.io/immer/freezing
setAutoFreeze(false);

const container = document.getElementById('react_app');
const root = createRoot(container!);

root.render(
    <StrictMode>
        <App />
    </StrictMode>,
);
