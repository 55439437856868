import { FontAwesomeIconsPartooUsed, IconPrefix, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { SectionWithIcon } from 'app/common/designSystem/components/atoms/SectionWithIcon/SectionWithIcon';
import ModalTemplate from 'app/common/designSystem/components/templates/ModalTemplate';
import { SMALL, Type } from 'app/common/designSystem/constants/size';

import {
    ModalTemplateContent,
    ModalTemplateContentDescription,
} from './ReviewReportModal.styled';

const ReviewReportModal = () => {
    const { t } = useTranslation();

    return (
        <ModalTemplate size={SMALL as Type}>
            <ModalTemplateContent>
                <Text variant="heading4" as="h4">
                    {t('review_report')}
                </Text>
                <Text variant="bodyMRegular" as="span" color="secondary">
                    {t('what_are_the_different_type_of_email_reports')}
                </Text>
                <ModalTemplateContentDescription>
                    <SectionWithIcon
                        iconName={['fa-calendar-day', IconPrefix.SOLID]}
                        title={t('daily_report')}
                        items={[
                            [
                                'daily_report_description',
                                t('daily_report_description'),
                            ],
                        ]}
                    />
                    <SectionWithIcon
                        iconName={['fa-calendar-week', IconPrefix.SOLID]}
                        title={t('weekly_report')}
                        items={[
                            [
                                'rm_weekly_report_description',
                                t('rm_weekly_report_description'),
                            ],
                        ]}
                        variant="secondary"
                    />
                    <SectionWithIcon
                        iconName={[
                            FontAwesomeIconsPartooUsed.faCalendarDays,
                            IconPrefix.SOLID,
                        ]}
                        title={t('monthly_report')}
                        items={[
                            [
                                'rm_monthly_report_description',
                                t('rm_monthly_report_description'),
                            ],
                        ]}
                        variant="default"
                    />
                </ModalTemplateContentDescription>
            </ModalTemplateContent>
        </ModalTemplate>
    );
};

export default ReviewReportModal;
