import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { ControlledPostDescription } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledPostDescription';

import {
    DateContainer,
    DateSection,
    RequiredFieldContainer,
    TitleContainer,
    ValidationGlobalContainer,
} from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/forms/PartialEventForm.styled';

import { ControlledDatepickerField } from './ControlledField/ControlledDatepickerField';
import { ControlledEventTitleField } from './ControlledField/ControlledEventTitle';
import { ControlledHourField } from './ControlledField/ControlledHourField';
import { useHasPartialEventDatesErrors } from '../hooks/useHasPartialEventDatesErrors';

export const PartialEventForm = () => {
    const { t } = useTranslation();
    const { formState, watch, control } = useFormContext();

    const eventStartDate = watch('event_start_date');
    const eventEndDate = watch('even_end_date');
    watch('event_title');
    watch('post_description');
    const {
        hasErrors: hasErrorDates,
        endDateIsAfterStartDateError,
        startDateIsBefore1970,
        endDateIsInvalid,
        endDateIsFutureError,
    } = useHasPartialEventDatesErrors();

    return (
        <>
            <TitleContainer>{t('post_global_content')}</TitleContainer>

            <ControlledEventTitleField />

            <DateSection>
                <DateContainer>
                    <ControlledDatepickerField
                        name="event_start_date"
                        control={control}
                        hasError={!!formState.errors.event_start_date}
                        labels={{ startDate: t('start_date') }}
                        placeholders={{ startDate: t('date_placeholder') }}
                        maxDate={eventEndDate}
                        error={hasErrorDates ? ' ' : undefined}
                    />

                    <ControlledHourField
                        name="event_start_time"
                        control={control}
                        label={t('start_time')}
                        error={hasErrorDates}
                    />

                    <ControlledDatepickerField
                        hasError={!!formState.errors.event_end_date}
                        labels={{ startDate: t('end_date') }}
                        placeholders={{ startDate: t('date_placeholder') }}
                        name="event_end_date"
                        control={control}
                        minDate={eventStartDate}
                        error={hasErrorDates ? ' ' : undefined}
                    />

                    <ControlledHourField
                        name="event_end_time"
                        control={control}
                        label={t('end_time')}
                        error={hasErrorDates}
                    />
                </DateContainer>

                <ValidationGlobalContainer>
                    {endDateIsAfterStartDateError && (
                        <RequiredFieldContainer>
                            {t('start_date_is_superior')}
                        </RequiredFieldContainer>
                    )}
                    {(endDateIsInvalid || endDateIsFutureError) && (
                        <RequiredFieldContainer>{t('end_date_is_passed')}</RequiredFieldContainer>
                    )}
                    {startDateIsBefore1970 && (
                        <RequiredFieldContainer>
                            {t('start_date_must_be_after_1970')}
                        </RequiredFieldContainer>
                    )}
                </ValidationGlobalContainer>
            </DateSection>

            <ControlledPostDescription />
        </>
    );
};
