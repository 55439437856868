import styled from '@emotion/styled';

export const BenchmarkContent = styled.div`
    position: relative;
    flex: 2;
    background-color: white;
    border-radius: 8px;
`;

export const BenchmarkContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    position: relative;
    gap: 24px;
`;

export const LocalTitleContainer = styled.div`
    padding: 24px;
    gap: 16px;
    display: flex;
`;
