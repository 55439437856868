import { useTranslation } from 'react-i18next';

import {
    POST_TYPES_CREATABLE,
    TYPE_ICONS,
} from 'app/presence/googlePosts/components/services/constants';

import {
    PostContainerTitle,
    PostCreationDetailWrapper,
    PostFormTypePlatformInfoContainer,
    PostFormTypePlatformInfoDescription,
    PostFormTypePlatformInfoTitle,
    PostFormTypePlatformInfoWrapper,
} from './PostFormTypePlatformInfo.styled';

export default function PostFormTypePlatformInfo() {
    const { t } = useTranslation();
    return (
        <PostFormTypePlatformInfoWrapper>
            <PostFormTypePlatformInfoTitle>{t('post_type_title')}</PostFormTypePlatformInfoTitle>
            <PostFormTypePlatformInfoDescription>
                {t('post_type_description')}
            </PostFormTypePlatformInfoDescription>
            <PostFormTypePlatformInfoContainer>
                <PostContainerTitle>
                    <i className="fa-solid fa-info-circle" />
                    {t('post_type_info')}
                </PostContainerTitle>
                {POST_TYPES_CREATABLE.map((type: string, index) => (
                    <PostCreationDetailWrapper key={`${type}-${index}`}>
                        <div className="type_icon">
                            <i className={`fa-light fa-${TYPE_ICONS[type]}`} />
                        </div>
                        <div>
                            <div className="type_name">{t(`post_type_name_${type}`)}</div>
                            <div className="type_description">
                                {t(`post_type_description_${type}`)}
                            </div>
                        </div>
                    </PostCreationDetailWrapper>
                ))}
            </PostFormTypePlatformInfoContainer>
        </PostFormTypePlatformInfoWrapper>
    );
}
