import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { GROUPS, GROUP_DELETABLE } from 'app/common/data/queryKeysConstants';

export default function useGroupCheckDelete(groupId: number) {
    return useQuery([GROUPS, groupId, GROUP_DELETABLE], () => api.groups.checkDeletable(groupId), {
        enabled: !!groupId,
    });
}
