import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';
import { ADMIN, ORG_ADMIN, PROVIDER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMe';

import { ColorState } from './types';

export default function useAdvancedSettingsSectionColor(): ColorState {
    // Hooks
    const { data: publisherErrors } = usePublisherErrors({});
    const userRole = useMe()?.data?.role;
    const showCode = [ORG_ADMIN, PROVIDER, ADMIN].includes(userRole || '');

    const codePermission =
        useFieldMetadata('advanced_settings', 'code')?.enabled && showCode;
    const statusPermission = useFieldMetadata('advanced_settings', 'status')
        ?.enabled;
    const siretPermission = useFieldMetadata(
        'advanced_settings',
        'national_identification_number',
    )?.enabled;

    // Disabled
    if (!codePermission && !statusPermission && !siretPermission) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (
        (publisherErrors?.advanced_settings?.code && showCode) ||
        publisherErrors?.advanced_settings?.national_identification_number
    ) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields not handled

    // OK
    return ColorState.OK;
}
