import React from 'react';

import { useTranslation } from 'react-i18next';

import {
    AddressCompletionHeader,
    BusinessEditV2ContentContainer,
    GridSection,
    GridSectionRows,
} from 'app/businessEditV2/components/BusinessEditV2Content/BusinessEditV2Content.styled';
import BusinessEditV2ContentDescription from 'app/businessEditV2/components/BusinessEditV2ContentDescription';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useBusinessEditRefs from 'app/businessEditV2/hooks/editV2utils/useBusinessEditRefs';
import AddressSection from 'app/businessEditV2/sections/AddressSection';
import AdvancedSettingsSection from 'app/businessEditV2/sections/AdvancedSettingsSection';
import AttributesSection from 'app/businessEditV2/sections/AttributesSection';
import BusinessStatusSection from 'app/businessEditV2/sections/BusinessStatusSection';
import CategoriesSection from 'app/businessEditV2/sections/CategoriesSection';
import ContactSection from 'app/businessEditV2/sections/ContactSection';
import CustomFieldsSection from 'app/businessEditV2/sections/CustomFieldsSection';
import DescriptionsSection from 'app/businessEditV2/sections/DescriptionsSection';
import LinksSection from 'app/businessEditV2/sections/LinksSection/LinksSection';
import MenuSection from 'app/businessEditV2/sections/MenuSection/MenuSection';
import MoreHoursSection from 'app/businessEditV2/sections/MoreHoursSection/MoreHoursSection';
import HoursSection from 'app/businessEditV2/sections/OpenHoursSection';
import PhotosSection from 'app/businessEditV2/sections/PhotosSection';
import ServicesSection from 'app/businessEditV2/sections/ServicesSection';
import SpecificHoursSection from 'app/businessEditV2/sections/SpecificHoursSection';
import { SectionNames } from 'app/businessEditV2/utils/utils';

const BusinessEditV2Content = () => {
    const { t } = useTranslation();
    const refs = useBusinessEditRefs();

    const onClick = () => {
        const keys = Object.keys(refs);
        // Scrolls to the first element that is missing or has a warning
        for (let i = 0; i < keys.length; i++) {
            if (
                [
                    ColorState.MISSING_WARNING,
                    ColorState.PRECHECK_ERRORS,
                ].includes(refs[keys[i]]?.color) &&
                refs[keys[i]]?.ref.current
            ) {
                refs[keys[i]].ref.current.scrollIntoView({
                    behavior: 'smooth',
                });
                return;
            }
        }
    };

    return (
        <BusinessEditV2ContentContainer>
            <AddressCompletionHeader>
                <AddressSection refs={refs} />
                <BusinessStatusSection onClick={onClick} />
            </AddressCompletionHeader>

            <BusinessEditV2ContentDescription
                title={t('business_edit_content_open_hours')}
            >
                <GridSection>
                    <HoursSection ref={refs[SectionNames.OPEN_HOURS].ref} />
                    <GridSectionRows>
                        <SpecificHoursSection
                            ref={refs[SectionNames.SPECIFIC_HOURS].ref}
                        />
                        <MoreHoursSection
                            ref={refs[SectionNames.MORE_HOURS].ref}
                        />
                    </GridSectionRows>
                </GridSection>
            </BusinessEditV2ContentDescription>

            <BusinessEditV2ContentDescription
                title={t('business_edit_content_presentation')}
            >
                <GridSection>
                    <PhotosSection ref={refs[SectionNames.PHOTOS].ref} />
                    <GridSectionRows>
                        <DescriptionsSection
                            ref={refs[SectionNames.DESCRIPTIONS].ref}
                        />
                    </GridSectionRows>
                </GridSection>
            </BusinessEditV2ContentDescription>

            <BusinessEditV2ContentDescription
                title={t('business_edit_content_activity_details')}
            >
                <GridSection>
                    <CategoriesSection
                        ref={refs[SectionNames.CATEGORIES].ref}
                    />
                    <AttributesSection
                        ref={refs[SectionNames.ATTRIBUTES].ref}
                    />
                    <ServicesSection ref={refs[SectionNames.SERVICES].ref} />
                    <MenuSection ref={refs[SectionNames.MENU].ref} />
                </GridSection>
            </BusinessEditV2ContentDescription>

            <BusinessEditV2ContentDescription
                title={t('business_edit_content_whole_profile')}
            >
                <GridSection>
                    <ContactSection ref={refs[SectionNames.CONTACT].ref} />
                    <AdvancedSettingsSection
                        ref={refs[SectionNames.ADVANCED_SETTINGS].ref}
                    />
                    <LinksSection ref={refs[SectionNames.LINKS].ref} />
                    <CustomFieldsSection
                        ref={refs[SectionNames.CUSTOM_FIELDS].ref}
                    />
                </GridSection>
            </BusinessEditV2ContentDescription>
        </BusinessEditV2ContentContainer>
    );
};

export default BusinessEditV2Content;
