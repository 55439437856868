import { useQuery } from 'react-query';

import FoodMenu from 'app/api/v2/api_calls/foodMenuApiCalls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import useIsFoodMenusAvailable from 'app/businessEditV2/hooks/menu/useIsFoodMenusAvailable';
import {
    BUSINESS,
    BUSINESS_FOODMENUS,
} from 'app/common/data/queryKeysConstants';

export default function useBusinessFoodMenus() {
    const businessId = useGetBusinessId();
    const { data } = useIsFoodMenusAvailable();

    return useQuery(
        [BUSINESS, BUSINESS_FOODMENUS, { businessId }],
        () => FoodMenu.getFoodMenu(businessId),
        {
            enabled: !!(businessId && data),
            retry: false,
            retryOnMount: false,
        },
    );
}
