import { connect } from 'react-redux';

import SuggestionSectionForm from 'app/businessConnection/components/genericQuickConnect/SuggestionSection/SuggestionSectionForm';
import { connectionSelector } from 'app/states/reducers';

const mapStateToProps = state => ({
    connectionState: connectionSelector(state),
});

const SuggestionSectionFormComponent = connect(mapStateToProps)(
    SuggestionSectionForm,
);

export default SuggestionSectionFormComponent;
