import React from 'react';

const UserLogo = () => (
    <svg viewBox="0 0 45 45" version="1.1">
        <defs>
            <circle id="path-1" cx="22.5" cy="22.5" r="22.5" />
        </defs>
        <g
            id="Review-Management"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Review-Management-/-Filtered-/-Platforms-/-Facebook-/-Opened"
                transform="translate(-306.000000, -509.000000)"
            >
                <g
                    id="Components-/-Card-/-Review-/-Facebook-/-Desktop-/-Thread-2"
                    transform="translate(290.000000, 256.000000)"
                >
                    <g
                        id="Answer-2"
                        transform="translate(16.000000, 253.000000)"
                    >
                        <g id="Components-/-Avatars-/-Empty">
                            <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1" />
                            </mask>
                            <use
                                id="Mask"
                                fill="#DFE3EC"
                                fillRule="evenodd"
                                xlinkHref="#path-1"
                            />
                            <rect
                                id="Body"
                                fill="#FFFFFF"
                                fillRule="evenodd"
                                opacity="0.7"
                                mask="url(#mask-2)"
                                x="7.2"
                                y="27.9"
                                width="30.6"
                                height="21.6"
                                rx="10.8"
                            />
                            <circle
                                id="Head"
                                fill="#FFFFFF"
                                fillRule="evenodd"
                                opacity="0.7"
                                mask="url(#mask-2)"
                                cx="22.5"
                                cy="17.1"
                                r="7.2"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default UserLogo;
