import { useEffect } from 'react';

import { AppDataContextType, useAppDataContext } from 'app/common/contexts/appData';

import { handleGoogleMap, removeGoogleMapScript } from './handleGoogleMap';
import { handleGoogleTagManager, removeGoogleTagManagerScript } from './handleGoogleTagManager';
import { handleIntercomPostLogin, removeIntercomPostLogin } from './handleIntercomPostLogin';
import { handleSurvicate, removeSurvicateScript } from './handleSurvicate';
import { handleWhatsappEmbeddedSignup } from './handleWhatsappEmbeddedSignupScript';

export const setupPostLoginScripts = async (appData: AppDataContextType) => {
    return await Promise.allSettled([
        handleGoogleMap(),
        handleWhatsappEmbeddedSignup(),
        handleSurvicate(appData.me, appData.organization),
        handleGoogleTagManager(appData.me, appData.organization),
        handleIntercomPostLogin(appData.me),
    ]);
};

export const removePostLoginScripts = () => {
    removeGoogleMapScript();
    removeSurvicateScript();
    removeGoogleTagManagerScript();
    removeIntercomPostLogin();
};

export const useHandlePostLoginScripts = () => {
    const appData = useAppDataContext();

    // Handle post login scripts
    useEffect(() => {
        setupPostLoginScripts(appData);
        return () => {
            removePostLoginScripts();
        };
    }, []);
};
