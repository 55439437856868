import {
    getCities,
    getGroupIds,
    getOrgIds,
} from 'app/common/components/businessModal/context/businessModalFiltersReducer';
import useReduxStateToLocalState from 'app/common/components/businessModal/hooks/privateHooks/useReduxStateToLocalState';
import useReduxFilteredBusinesses from 'app/common/components/businessModal/hooks/useReduxFilteredBusinesses';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';

/**
 * Bird Team usage only
 *
 * Retrieve the business_id of the selected business in the business modal
 * Returns undefined if more than one business available
 *
 * Please do not use this hook outside the Bird Team specific usage, this is a dirty temporary solution
 */
export default function useLocalBusinessIdCompetitiveBenchmark(): string | undefined {
    const { selection } = useReduxStateToLocalState();

    const cities = getCities(selection.filters);
    const groupIds = getGroupIds(selection.filters);
    const orgIds = getOrgIds(selection.filters);

    const enableNewGroups = useNewGroups();

    const { query } = useReduxFilteredBusinesses({
        city: cities[0] ?? null,
        group_id__in: !enableNewGroups && groupIds.length ? groupIds.join(',') : null,
        groups: enableNewGroups && groupIds.length ? groupIds.join(',') : null,
        org_id__in: orgIds.length ? orgIds.join(',') : null,
        query: selection.query,
    });

    if (!selection.selectAll) {
        return selection.selectedIds.length === 1 ? selection.selectedIds[0] : undefined;
    }

    // here, we selected a whole query by clicking "select all", we depend
    // on server side data queryCount that may not be fetched yet

    const queryCount = query.data?.pages?.[0]?.count;

    if (queryCount === undefined) {
        return undefined; // we don't know yet
    }

    const count = queryCount + selection.selectedIds.length - selection.unselectedIds.length;

    return count === 1 ? query.data?.pages?.[0]?.businesses?.[0]?.id : undefined;
}
