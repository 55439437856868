import React from 'react';

import {
    MenuPhotoDeleteIconButton,
    MenuPhotosDelete,
} from './MenuPhotos.styled';

type Props = {
    onClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
    height: string;
};

const DeleteMenuPhoto = ({ onClick, height }: Props) => (
    <MenuPhotosDelete
        className="photos_table__list_item__upper_bar_button_no_label"
        role="presentation"
        height={height}
    >
        <MenuPhotoDeleteIconButton onClick={onClick} onKeyPress={onClick}>
            <i className="fa fa-trash" />
        </MenuPhotoDeleteIconButton>
    </MenuPhotosDelete>
);

export default DeleteMenuPhoto;
