import { useContext, useState } from 'react';

import { Option, SingleSelect, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useInfiniteQuery } from 'react-query';

import { V2BusinessData, V2FormattedBusinessData } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import { BUSINESSES } from 'app/common/data/queryKeysConstants';
import useDebounce from 'app/common/hooks/useDebounce';
import formatBusiness from 'app/common/services/formatBusiness';
import { EmptyMessageWrapper } from 'app/pages/conversations/ReviewBooster/components/BusinessSelect.styled';
import { SelectedBusinessContext } from 'app/pages/conversations/ReviewBooster/components/ReviewBoosterForm';

const formatBusinessToChoice = (business: V2FormattedBusinessData): Option => {
    const { name, city, country, id, addressFull } = business;

    return {
        label: `${name} (${city}, ${country ? addressFull : ''})`,
        value: id,
        name: id,
    };
};

const choiceToBusiness = (
    choice: Option,
    businesses: Array<V2BusinessData>,
): V2BusinessData | undefined => {
    return businesses.find(({ id }) => id === choice?.value);
};

const BusinessSelect = () => {
    const { t } = useTranslation();
    const [businessSearch, setBusinessSearch] = useState('');
    const query = useDebounce(businessSearch);
    const { selectedBusiness, setSelectedBusiness } = useContext(SelectedBusinessContext);

    const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
        [BUSINESSES, { query }],
        ({ pageParam = 1 }) =>
            api.business.searchBusinesses({
                query,
                page: pageParam,
            }),
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
        },
    );

    const businesses: Array<V2BusinessData> = Array.prototype.concat.apply(
        [],
        data?.pages.map(page => page.businesses),
    );

    const businessOptions = [
        {
            options: businesses.map(business => formatBusinessToChoice(formatBusiness(business))),
        },
    ];

    const handleChangeBusiness = (choice?: Option) => {
        setSelectedBusiness(choice ? choiceToBusiness(choice, businesses) : undefined);
    };

    return (
        <SingleSelect
            dataTrackId={'review_booster__business'}
            label={t('business_selection')}
            selectedValue={
                selectedBusiness
                    ? formatBusinessToChoice(formatBusiness(selectedBusiness))
                    : undefined
            }
            sections={businessOptions}
            onChange={handleChangeBusiness}
            onSearch={setBusinessSearch}
            loadMore={fetchNextPage}
            hasMore={hasNextPage}
            required={true}
            emptyMessage={
                <EmptyMessageWrapper>
                    <Text variant="bodyMMedium">{t('no-result-found')}</Text>
                </EmptyMessageWrapper>
            }
        />
    );
};

export default BusinessSelect;
