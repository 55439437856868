import { useMemo, useRef, useState } from 'react';

import { css } from '@emotion/react';
import { HEX_COLORS } from '@partoohub/branding';
import { Checkbox, FontAwesomeIconsPartooUsed, Stack, Text, TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { FormattedReplyTemplate } from 'app/api/types/reviewReplyTemplates';
import { Choice } from 'app/api/types/user';
import MentionTextAreaComponent from 'app/common/designSystem/components/atoms/MentionTextArea';
import { MentionTextAreaImperativeHandle } from 'app/common/designSystem/components/atoms/MentionTextArea/MentionTextArea';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { ButtonWithMenu } from 'app/common/designSystem/components/molecules/ButtonWithMenu/ButtonWithMenu';
import ButtonWithSearchMenuFilterSelection from 'app/common/designSystem/components/molecules/ButtonWithSearchMenuFilterSelection';
import FLAG from 'app/common/flags.json';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import getLang from 'app/common/services/getLang';
import { LanguageCodeType, getTranslationKey, languagesData } from 'app/common/services/languages';

import { translateMarkup } from 'app/common/services/reactMentionsUtils';
import useGetTemplatePlaceholders from 'app/pages/settings/AutoReply/hooks/queries/useGetTemplatePlaceholders';
import { useSaveTemplate } from 'app/reviewManagement/replyTemplate/hooks/useSaveTemplate';

import EmojiPicker from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/CommentSection/EmojiPicker/EmojiPicker';

import FlagSelectButton from './FlagSelectButton';

import {
    ButtonContainer,
    FlagGridArea,
    FormTextGridArea,
    PlaceholderButtonGridArea,
    ReplyTemplateFormGrid,
    ReviewTypesGridArea,
    StyledCard,
    StyledReviewTypesText,
    StyledSaveButton,
    TextAreaTitleGridArea,
    TitleGridArea,
} from './ReplyTemplateForm.styled';

const MAX_CHAR_LIMIT = 2_000;

type Props = {
    onClose: () => void;
    initialValues?: FormattedReplyTemplate;
    duplicated?: boolean;
};

export function ReplyTemplateForm({ initialValues, duplicated = false, onClose }: Props) {
    const { t } = useTranslation();
    const me = useMe();
    const userLanguage = me.data?.lang || getLang();

    // Default values
    const defaultLanguage = initialValues?.language ?? userLanguage;
    const defaultTitle = `${duplicated ? 'Copy - ' : ''}${initialValues?.title ?? ''}`;
    const defaultText = initialValues ? translateMarkup(initialValues.text) : '';
    const defaultReviewTypesSelected = {
        positive: initialValues?.ratings?.includes('positive') ?? false,
        neutral: initialValues?.ratings?.includes('neutral') ?? false,
        negative: initialValues?.ratings?.includes('negative') ?? false,
    };

    const [language, setLanguage] = useState<LanguageCodeType>(defaultLanguage);
    const [title, setTitle] = useState<string>(defaultTitle);
    const [text, setText] = useState<string>(defaultText);
    const [reviewTypesSelected, setReviewTypesSelected] = useState<
        Record<'positive' | 'neutral' | 'negative', boolean>
    >(defaultReviewTypesSelected);

    const [search, setSearch] = useState<string>('');
    const [selectedOptions, setSelectedOptions] = useState<Array<Choice>>([]);
    const textArea = useRef<MentionTextAreaImperativeHandle>();

    const placeholders = useGetTemplatePlaceholders() ?? [];
    const placeholdersByValue = placeholders.reduce(
        (placeholdersByValue, current) => {
            placeholdersByValue[current.value] = current;
            return placeholdersByValue;
        },
        {} as Record<string, (typeof placeholders)[number]>,
    );

    const changeLanguage = (language: Choice[] | null) => {
        if (language) {
            setSelectedOptions(language);
            setLanguage(language[0].value);
        }
    };

    const addPlaceholder = (value: string) => {
        textArea.current?.addPlaceholder(t(placeholdersByValue[value].label), value);
    };

    const toggleReviewType = (type: string) => {
        setReviewTypesSelected({
            ...reviewTypesSelected,
            [type]: !reviewTypesSelected[type],
        });
    };

    const { mutate: saveTemplate } = useSaveTemplate(duplicated);

    const hasUnsavedChanges = useMemo(() => {
        return (
            language !== defaultLanguage ||
            title !== defaultTitle ||
            text !== defaultText ||
            !Object.keys(reviewTypesSelected).reduce(
                (accumulator, current) =>
                    accumulator &&
                    reviewTypesSelected[current] === defaultReviewTypesSelected[current],
                true,
            )
        );
    }, [language, title, text, reviewTypesSelected]);

    const languagesOptionsData = useMemo(() => {
        return languagesData
            .map(lang => ({
                value: lang.code,
                label: t(getTranslationKey(lang)),
                icon: <img src={FLAG[lang.country]} alt={lang.country} />,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
            .filter(lang => lang.label.toLowerCase().includes(search.toLowerCase()));
    }, [search]);

    const limitCharReached = text.length > MAX_CHAR_LIMIT;

    const formIsValid =
        title && text && Object.values(reviewTypesSelected).find(a => a) && !limitCharReached;

    function onEmojiClick(emoji: string): void {
        textArea.current?.insert(emoji, text);
    }

    return (
        <StyledCard>
            <ReplyTemplateFormGrid>
                <FlagGridArea>
                    <ButtonWithSearchMenuFilterSelection
                        input={<FlagSelectButton language={language} />}
                        options={languagesOptionsData}
                        hasHeader={false}
                        onChange={changeLanguage}
                        loadMore={setSearch}
                        selectedOptions={selectedOptions}
                        placeholder={t('language')}
                    />
                </FlagGridArea>
                <TitleGridArea>
                    <TextInput
                        dataTrackId="reply_template_title__text_input"
                        value={title}
                        label={t('title')}
                        onChange={setTitle}
                    />
                </TitleGridArea>
                <TextAreaTitleGridArea variant="bodyMBold" color={HEX_COLORS.blackberry}>
                    {t('your_reply_template')}
                </TextAreaTitleGridArea>
                <PlaceholderButtonGridArea>
                    <EmojiPicker onEmojiClick={onEmojiClick} onClick={() => undefined} showOnLeft />
                    <ButtonWithMenu
                        dataTrackId="reply_review_template_button__menu"
                        text={t('add_a_placeholder')}
                        variant={'primary'}
                        appearance="text"
                        icon={[FontAwesomeIconsPartooUsed.faAt]}
                        options={placeholders.map(placeholder => ({
                            ...placeholder,
                            label: t(placeholder.label),
                            name: `${placeholder.id}`,
                        }))}
                        onMenuClick={addPlaceholder}
                        cssMenu={css`
                            top: 30px;
                            right: -2px;
                            left: unset;
                        `}
                    />
                </PlaceholderButtonGridArea>
                <FormTextGridArea>
                    <MentionTextAreaComponent
                        value={text}
                        choices={placeholders.map(placeholder => ({
                            id: placeholder.value,
                            display: t(placeholder.label),
                        }))}
                        ref={textArea}
                        onChange={setText}
                        hasError={limitCharReached}
                        explanation={limitCharReached ? t('max_nb_char_reached') : ''}
                    />
                </FormTextGridArea>
                <ReviewTypesGridArea>
                    <StyledReviewTypesText variant="bodyMBold">
                        {t('apply_to_reviews')}
                    </StyledReviewTypesText>
                    {Object.entries(reviewTypesSelected).map(([reviewType, checked]) => (
                        <Stack gap="8px" key={reviewType}>
                            <Checkbox
                                dataTrackId="reply_template_form__checkbox"
                                aria-label=""
                                checked={checked}
                                onChange={() => toggleReviewType(reviewType)}
                            >
                                <Text variant="bodyMBold" as="span" color="secondary">
                                    {t(reviewType, { count: 2 })}
                                </Text>
                            </Checkbox>
                        </Stack>
                    ))}
                </ReviewTypesGridArea>
                <ButtonContainer>
                    <div>
                        <StrikeThroughButton
                            text={t('cancel')}
                            color={'secondary'}
                            onClick={onClose}
                        />
                        <StyledSaveButton
                            dataTrackId="reply_template__button"
                            variant="primary"
                            shape="cube"
                            appearance="contained"
                            onClick={() => {
                                onClose();
                                saveTemplate({
                                    title,
                                    text,
                                    language,
                                    ...reviewTypesSelected,
                                    id: initialValues?.id,
                                });
                            }}
                            disabled={!(hasUnsavedChanges && formIsValid)}
                        >
                            {t('save')}
                        </StyledSaveButton>
                    </div>
                </ButtonContainer>
            </ReplyTemplateFormGrid>
        </StyledCard>
    );
}
