import { FC, ForwardRefExoticComponent, RefAttributes } from 'react';

import qs from 'query-string';

import { Link, useMatch, useNavigate } from 'react-router-dom';

import { PartooProduct } from 'app/common/data/productIds';
import { useAvailableSearchParams } from 'app/common/data/routeIds.parsers';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { useIsMobile } from 'app/common/services/screenDimensions';

import {
    ProductButtonContainer,
    ProductButtonHover,
    ProductButtonTooltip,
    RedDot,
} from './ProductButton.styled';

type LogoProps = {
    isActive?: boolean;
};

type Props = {
    id: PartooProduct;
    logo: ForwardRefExoticComponent<RefAttributes<SVGSVGElement> & LogoProps>;
    to: string;
    tooltip: string;
    marginTop?: string;
    activePath?: string;
    showRedDot?: boolean;
    onResetFilters?: () => void;
};

const ProductButton: FC<Props> = ({
    id,
    logo,
    to,
    activePath,
    tooltip,
    marginTop,
    showRedDot = false,
    onResetFilters,
}) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    // Check if current url match with the product active path
    const match = useMatch(activePath ? `${activePath}/*` : '');
    const active = Boolean(activePath && !!match);

    const Logo = logo;

    const availableSearchParams = useAvailableSearchParams();

    const handleClick = event => {
        const hasSomeFilter = Object.values(availableSearchParams).some(
            value => value !== '',
        );

        // remove any url filters if we click on the active product button
        if (hasSomeFilter && active) {
            const emptySearchParams = Object.keys(availableSearchParams).reduce(
                (acc, cur) => {
                    acc[cur] = '';
                    return acc;
                },
                {},
            );

            onResetFilters?.();

            // can't put push here because some pages implement filters
            // imperatively and they don't get synced when doing previous page
            // or next page in the browser.
            navigate(
                { search: qs.stringify(emptySearchParams) },
                { replace: true },
            );

            // stay on the same page
            event.preventDefault();
        }
    };

    const content = (
        <ProductButtonContainer
            as={Link}
            marginTop={marginTop}
            active={active}
            cutAWhole={showRedDot}
            data-cy="product-sidebar-product-button"
            data-track={`sidebar__button__${id.toLowerCase()}`}
            data-intercom-target={`sidebar__button__${id.toLowerCase()}`}
            onClick={handleClick}
            to={to}
        >
            <ProductButtonHover>
                {showRedDot && <RedDot />}
                <Logo isActive={active} />
            </ProductButtonHover>
        </ProductButtonContainer>
    );

    if (!isMobile) {
        return (
            <TooltipWrapper
                tooltip={ProductButtonTooltip}
                text={tooltip}
                position="right"
            >
                {content}
            </TooltipWrapper>
        );
    }

    return content;
};

export default ProductButton;
