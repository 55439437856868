import styled from '@emotion/styled';

import { BlackTooltip } from 'app/common/designSystem/components/atoms/TooltipWrapper/TooltipWrapper.styled';

export const TagBlackTooltip = styled(BlackTooltip)`
    &::first-letter {
        text-transform: none;
    }
`;

export const TagPillBodyText = styled.div`
    max-width: 154px;
    overflow: hidden;
    text-overflow: ellipsis;
`;
