import { useMutation, useQueryClient } from 'react-query';

import { CustomChatHours } from 'app/api/types/business_message_settings';
import api from 'app/api/v2/api_calls';
import { CONVERSATION_STARTERS } from 'app/common/data/queryKeysConstants';
import { formatMentionToPlaceholder } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/services/utils';
import { ConversationStarterPatchPayload } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';

export default function useUpdateConversationStarter(conversationStarterId: string) {
    const queryClient = useQueryClient();
    return useMutation(
        (payload: ConversationStarterPatchPayload) => {
            if (payload.welcome_message !== undefined) {
                payload.welcome_message = formatMentionToPlaceholder(payload.welcome_message ?? '');
            }
            if (payload.offline_message !== undefined) {
                payload.offline_message = formatMentionToPlaceholder(payload.offline_message ?? '');
            }
            if (payload.chat_open_hours !== undefined) {
                for (const [day, windowList] of Object.entries(payload.chat_open_hours)) {
                    payload.chat_open_hours[day as keyof CustomChatHours] = (
                        windowList as Array<{
                            startHour: string;
                            endHour: string;
                        }>
                    ).map(({ startHour, endHour }) => `${startHour}-${endHour}`);
                }
            }
            return api.conversationStarters.updateConversationStarter(
                conversationStarterId,
                payload,
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([CONVERSATION_STARTERS]);
            },
        },
    );
}
