import { MESSAGING_CONVERSATION_STATS } from 'app/common/data/queryKeysConstants';
import {
    Conversation,
    MESSAGE_CONTENT_TYPE,
    MessagingFilters,
    StatusFilterOption,
} from 'app/states/messaging';
import { MessageData } from 'app/states/messaging/conversationUpdateTypes';
import queryClient from 'app/states/queryClient';

import { isConversationInRightAssignFilter } from '../../ConversationUtils';
import { ConversationsData } from '../../hooks/useConversationUpdate';
import { addConversation, updateConversation } from '../../services/handleConversationsCache';
import {
    addNewMessage,
    doesMessageExists,
    updateMessage,
} from '../../services/handleMessagesCache';

export const handleNewConversationMessage = (
    messageData: MessageData,
    conversationsData: ConversationsData,
    filtersData: MessagingFilters,
    meId: string,
) => {
    handleConversationMessageUpdate(messageData, meId);
    handleConversationUpdate(messageData.conversation, conversationsData, filtersData, meId);

    queryClient.invalidateQueries([MESSAGING_CONVERSATION_STATS, filtersData.businessFilters]);
};

const handleConversationMessageUpdate = (
    { conversation, message, status_message, assignment_message }: MessageData,
    meId: string,
) => {
    const isSender = meId === message.user_id;
    const shouldAddNewMessage =
        !isSender || !doesMessageExists(queryClient, conversation.id, message);

    if (shouldAddNewMessage) {
        if (status_message) addNewMessage(queryClient, conversation.id, status_message);
        if (assignment_message) addNewMessage(queryClient, conversation.id, assignment_message);
        addNewMessage(queryClient, conversation.id, message);
    } else if (message.content_type === MESSAGE_CONTENT_TYPE.IMAGE) {
        updateMessage(queryClient, conversation.id, message);
    }
};

const handleConversationUpdate = (
    conversation: Conversation,
    { activeConversation, setActiveConversation }: ConversationsData,
    { statusFilter, assignFilter, businessFilters }: MessagingFilters,
    meId: string,
) => {
    const hasUpdatedConversation = updateConversation(
        conversation,
        statusFilter,
        assignFilter,
        businessFilters,
    );

    if (hasUpdatedConversation) {
        if (activeConversation?.id === conversation.id) {
            setActiveConversation(conversation);
        }
        return;
    }

    const shouldAddConversation =
        statusFilter === StatusFilterOption.OPEN &&
        isConversationInRightAssignFilter(conversation, assignFilter, meId);

    if (shouldAddConversation) {
        addConversation(conversation, statusFilter, assignFilter, businessFilters);
    }
};
