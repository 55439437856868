import { Item } from 'app/pages/Homepage/utils/typing';

import { useShortcutCreatePostItem } from './shortcutsItems/createPost/useShortcutCreatePostItem';
import { useShortcutSendInviteItem } from './shortcutsItems/sendInvite/useShortcutSendInviteItem';
import { useShortcutCreateBusinessItem } from './shortcutsItems/useShortcutCreateBusinessItem';
import { useShortcutCreateUserItem } from './shortcutsItems/useShortcutCreateUserItem';
import { useShortcutJimItem } from './shortcutsItems/useShortcutJimItem';

export const useAllShortcutsItems = (): Array<Item> => {
    return [
        useShortcutSendInviteItem(),
        useShortcutCreatePostItem(),
        useShortcutJimItem(),
        useShortcutCreateBusinessItem(),
        useShortcutCreateUserItem(),
    ];
};
