import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { REVIEW_BOOSTER_GET_REVIEW_URL } from 'app/common/data/queryKeysConstants';

export const useGetReviewBoosterReviewUrl = (
    business_id: string,
    minifyUrl = false,
) =>
    useQuery([REVIEW_BOOSTER_GET_REVIEW_URL, business_id, minifyUrl], () => {
        return api.reviewBooster.getReviewUrl(business_id, minifyUrl);
    });
