// https://developer.mozilla.org/en-US/docs/Web/CSS/transition-timing-function#values
const EASINGS = {
    // https://cubic-bezier.com/#.16,.84,.44,1
    easeOutStrong: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
    // https://cubic-bezier.com/#.77,0,.17,1
    easeInOutQuart: 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',
    // https://cubic-bezier.com/#.24,.04,.26,1.79
    easeOutAndBack: 'cubic-bezier(0.240, 0.040, 0.260, 1.790)',
};

export default EASINGS;
