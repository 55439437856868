import { isEqual } from 'lodash-es';

import {
    LOGOUT_RESET_REDUCERS_DATA,
    LogoutResetReducersDataAction,
} from './login';

// ACTION TYPES
const UPDATE_RESET_PASSWORD = 'UPDATE_RESET_PASSWORD';
const UPDATE_RESET_PASSWORD_CONFIRMATION = 'UPDATE_RESET_PASSWORD_CONFIRMATION';
const DISPLAY_PASSWORD_CHECKER = 'DISPLAY_PASSWORD_CHECKER';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
const RESET_PASSWORD_REQUEST_FAILURE = 'RESET_PASSWORD_REQUEST_FAILURE';
const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS';
const RESET_RESET_PASSWORD_FORM = 'RESET_RESET_PASSWORD_FORM';
// ACTION FLOW TYPES
type UpdateResetPasswordAction = {
    type: 'UPDATE_RESET_PASSWORD';
    password: string;
};
type UpdateResetPasswordConfirmationAction = {
    type: 'UPDATE_RESET_PASSWORD_CONFIRMATION';
    password: string;
};
type DisplayPasswordCheckerAction = {
    type: 'DISPLAY_PASSWORD_CHECKER';
    displayed: boolean;
};
export type ResetPasswordRequestAction = {
    type: 'RESET_PASSWORD_REQUEST';
};
type ResetPasswordRequestFailureAction = {
    type: 'RESET_PASSWORD_REQUEST_FAILURE';
    errors: Record<string, any>;
};
type ResetPasswordRequestSuccessAction = {
    type: 'RESET_PASSWORD_REQUEST_SUCCESS';
};
type ResetResetPasswordFormAction = {
    type: 'RESET_RESET_PASSWORD_FORM';
};
export const updateResetPassword = (
    password: string,
): UpdateResetPasswordAction => ({
    type: UPDATE_RESET_PASSWORD,
    password,
});
export const updateResetPasswordConfirmation = (
    password: string,
): UpdateResetPasswordConfirmationAction => ({
    type: UPDATE_RESET_PASSWORD_CONFIRMATION,
    password,
});
export const displayPasswordChecker = (
    displayed: boolean,
): DisplayPasswordCheckerAction => ({
    type: DISPLAY_PASSWORD_CHECKER,
    displayed,
});
export const resetPasswordRequest = (): ResetPasswordRequestAction => ({
    type: RESET_PASSWORD_REQUEST,
});
export const resetPasswordRequestFailure = (
    errors: Record<string, any>,
): ResetPasswordRequestFailureAction => ({
    type: RESET_PASSWORD_REQUEST_FAILURE,
    errors,
});
export const resetPasswordRequestSuccess =
    (): ResetPasswordRequestSuccessAction => ({
        type: RESET_PASSWORD_REQUEST_SUCCESS,
    });
export const resetResetPasswordForm = (): ResetResetPasswordFormAction => ({
    type: RESET_RESET_PASSWORD_FORM,
});
export type ResetPasswordState = {
    password: string;
    passwordConfirmation: string;
    errors: Record<string, any>;
    isSubmitting: boolean;
    isSuccess: boolean;
    displayPasswordChecks: boolean;
};
type ResetPasswordAction =
    | UpdateResetPasswordAction
    | UpdateResetPasswordConfirmationAction
    | ResetPasswordRequestAction
    | ResetPasswordRequestFailureAction
    | ResetPasswordRequestSuccessAction
    | ResetResetPasswordFormAction
    | LogoutResetReducersDataAction
    | DisplayPasswordCheckerAction;
const initialState = {
    passwordConfirmation: '',
    password: '',
    errors: {},
    isSubmitting: false,
    isSuccess: false,
    displayPasswordChecks: false,
};

const resetPasswordReducer = (
    state: ResetPasswordState = initialState,
    action: ResetPasswordAction,
): ResetPasswordState => {
    switch (action.type) {
        case UPDATE_RESET_PASSWORD:
            return { ...state, password: action.password };

        case UPDATE_RESET_PASSWORD_CONFIRMATION:
            return { ...state, passwordConfirmation: action.password };

        case DISPLAY_PASSWORD_CHECKER:
            return { ...state, displayPasswordChecks: action.displayed };

        case RESET_PASSWORD_REQUEST:
            return { ...state, isSubmitting: true, errors: {} };

        case RESET_PASSWORD_REQUEST_FAILURE:
            return { ...state, isSubmitting: false, errors: action.errors };

        case RESET_PASSWORD_REQUEST_SUCCESS:
            return { ...state, isSubmitting: false, isSuccess: true };

        case LOGOUT_RESET_REDUCERS_DATA:
        case RESET_RESET_PASSWORD_FORM:
            return { ...initialState };

        default:
            return state;
    }
};

export const passwordConfirmationSelector = ({
    passwordConfirmation,
}: ResetPasswordState): string => passwordConfirmation;
export const passwordSelector = ({ password }: ResetPasswordState): string =>
    password;
export const isSubmittingSelector = ({
    isSubmitting,
}: ResetPasswordState): boolean => isSubmitting;
export const isSuccessSelector = ({ isSuccess }: ResetPasswordState): boolean =>
    isSuccess;

/* eslint-disable camelcase */
export const passwordConfirmationErrorSelector = ({
    errors: { password_confirmation },
}: ResetPasswordState): string | void => password_confirmation;
export const passwordChecksErrorSelector = ({
    errors: { password_rules },
}: ResetPasswordState): Array<string> => password_rules;

/* eslint-enable camelcase */
export const hasErrors = ({ errors }: ResetPasswordState): boolean =>
    !isEqual({}, errors);
export const globalErrorSelector = ({
    errors: { global },
}: ResetPasswordState): string | void => global;
export const passwordErrorSelector = ({
    errors: { password },
}: ResetPasswordState): string | void => password;
export const ErrorSelector = ({
    errors,
}: ResetPasswordState): Record<string, any> => errors;
export const displayCheckerSelector = ({
    displayPasswordChecks,
}: ResetPasswordState): boolean => displayPasswordChecks;
export default resetPasswordReducer;
