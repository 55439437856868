import { ReactNode } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

export const Container = styled.div`
    height: 46px;
    position: relative;
    border: none;
    outline: 0;
`;

export const AnyIcon = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 19px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    pointer-events: none;
    font-size: 16px;
`;

interface BorderProps {
    focused?: boolean;
}

export const Border = styled.div<BorderProps>`
    height: 100%;
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 4px;

    // this prevents pills from overflowing, but placeholder
    // is fine because it's in position absolute
    overflow: hidden;

    ${({ focused, theme }) =>
        focused
            ? css`
                  border: 2px solid ${theme.colors.primary.initial};
              `
            : css`
                  border: 1px solid ${greyColorObject.initial};

                  &:hover {
                      border: 1px solid ${HEX_COLORS.blackberry};
                  }
              `}
`;

interface PlaceholderProps {
    aside: boolean;
    color: string;
    children: ReactNode;
}

export const AnyPlaceholder = styled.div<PlaceholderProps>`
    position: absolute;
    z-index: 1000;
    ${props =>
        props.aside
            ? css`
                  top: -7px;
                  left: 13px;
              `
            : css`
                  top: 16px;
                  left: 13px;
              `}

    font-weight: ${props => (props.aside ? 700 : 400)};
    font-size: ${props => (props.aside ? 12 : 14)}px;
    line-height: 14px;
    color: ${props => props.color};
    background-color: ${HEX_COLORS.white};
    pointer-events: none;

    transition:
        top 0.15s linear,
        color 0.15s linear;
    will-change: top, color;
`;

interface AnyInputProps {
    cursor: string;
}

export const AnyInput = styled.input<AnyInputProps>`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: 0;
    padding-left: 13px;
    ${props =>
        props.cursor &&
        css`
            cursor: ${props.cursor};
        `}
`;

export const PillBody = styled.div`
    background-color: ${({ theme }) => theme.colors.primary.alpha};
    height: 24px;
    line-height: 24px;
    border-radius: 500px;

    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;

    font-weight: 700;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.primary.initial};
    white-space: nowrap;

    margin: 0 3px;

    z-index: 1;
    cursor: pointer;
`;

export const PillBodyText = styled.div`
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const PillCross = styled.div`
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.primary.initial};

    &:hover {
        transform: scale(1.2);
    }
    transform-origin: center;
    will-change: transform;
    transition: transform 0.15s;
`;
