import styled from '@emotion/styled';
import { Icon, TextInput } from '@partoohub/ui';

export const ChatbotBusinessSelectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const BusinessSelectionInputContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const BusinessSelectionInput = styled(TextInput)`
    margin: 0 -10px 0 0;
`;

export const ChevronIcon = styled(Icon)`
    position: relative;
    font-weight: 900;
    right: 16px;
`;

export const ChatbotBusinessSelectionButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;
