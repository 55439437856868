import { GroupData } from 'app/common/reducers/groups';

const editGroupName = (
    data: Array<GroupData> | null,
    parentGroupId: number | null,
    groupId: number,
    groupName: string,
): Array<GroupData> | null => {
    if (data) {
        if (parentGroupId) {
            // Edit subgroup name
            return data.map(group => {
                if (group.groupId === parentGroupId) {
                    return {
                        ...group,
                        subgroups: group.subgroups?.map(subgroup => {
                            if (subgroup.groupId === groupId) {
                                return {
                                    ...subgroup,
                                    groupName,
                                };
                            }

                            return subgroup;
                        }),
                    };
                }

                return group;
            });
        }

        // Edit group name
        return data.map(group => {
            if (group.groupId === groupId) {
                return {
                    ...group,
                    groupName,
                };
            }

            return group;
        });
    }

    return data;
};

export default editGroupName;
