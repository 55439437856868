import { connect } from 'react-redux';

import { AppState } from 'app/states/reducers';
import {
    bucketSelector,
    getReviewsPeriodTotalRequestAction,
    isPeriodSelector,
    isQualitativeSelector,
    periodReviewsSelector,
    reviewEvolutionAnalyticsSelector,
    reviewEvolutionFetchingSelector,
    totalReviewsSelector,
    updateIsQualitativeAction,
} from 'app/states/reviewAnalytics';

import ReviewsEvolutionComponent from './ReviewsEvolution';

const mapStateToProps = ({ reviewAnalytics }: AppState) => {
    const isPeriod = isPeriodSelector(reviewAnalytics);
    const isQualitative = isQualitativeSelector(reviewAnalytics);
    const reviewEvolutionAnalytics = reviewEvolutionAnalyticsSelector(
        reviewAnalytics,
        isQualitative,
        isPeriod,
    );
    const { dates, added, cumSum, average, previousCumSum } =
        reviewEvolutionAnalytics.reduce(
            (seq, analytic) => ({
                dates: [...seq.dates, analytic.date],
                added: [...seq.added, analytic.added],
                average: [...seq.average, analytic.average],
                previousCumSum: [...seq.previousCumSum, analytic.previous_sum],
                cumSum: [...seq.cumSum, analytic.cum_sum],
            }),
            {
                dates: [],
                added: [],
                cumSum: [],
                average: [],
                previousCumSum: [],
            },
        );

    return {
        dates,
        added,
        cumSum,
        average,
        previousCumSum,
        isPeriod,
        isQualitative,
        isLoading: reviewEvolutionFetchingSelector(reviewAnalytics),
        hasReviews: isPeriod
            ? !!periodReviewsSelector(reviewAnalytics)
            : !!totalReviewsSelector(reviewAnalytics),
        selectedBucket: bucketSelector(reviewAnalytics),
    };
};

const matchDispatchToProps = (dispatch: any) => ({
    updateIsQualitative: (value: boolean) => {
        dispatch(updateIsQualitativeAction(value));
    },
    reviewsPeriodTotalRequest: (value: boolean) => {
        dispatch(getReviewsPeriodTotalRequestAction(value));
    },
});

const ReviewsEvolution = connect(
    mapStateToProps,
    matchDispatchToProps,
)(ReviewsEvolutionComponent);

export default ReviewsEvolution;
