import React from 'react';

import { useTranslation } from 'react-i18next';

import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useServices from 'app/businessEditV2/hooks/services/useServices';

type Props = {
    colorState?: ColorState;
};

const ServicesSectionContent = ({ colorState = ColorState.OK }: Props) => {
    const { t } = useTranslation();
    const { data: services } = useServices();
    const numberOfServices = services?.services?.length || 0;

    switch (colorState) {
        case ColorState.PRECHECK_ERRORS:
            return <>{t('business_edit_content_correct_this_field')}</>;
        case ColorState.MISSING_WARNING:
        case ColorState.CLOSED:
        case ColorState.DISABLED:
            return <>{t('business_edit_services_add')}</>;
        case ColorState.OK:
            return (
                <>
                    <i className="fa-solid fa-bell-concierge" />
                    <span>
                        {t('business_edit_services_count', {
                            count: numberOfServices,
                        })}
                    </span>
                </>
            );
        default:
            return null;
    }
};

export default ServicesSectionContent;
