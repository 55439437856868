import { FC } from 'react';

import { Button, FontAwesomeIconsPartooUsed, Icon, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ConnectionButtonLogo from 'app/businessConnection/components/commonOld/ConnectionButtonLogo';
import { InnerTextButtonContainer } from 'app/businessEditV2/components/BusinessEditV2Header/CTAButtons/CTAButton.styled';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useLocation from 'app/businessEditV2/hooks/locations/locations';
import { Partner, useIsConnectedToPartner } from 'app/businessEditV2/hooks/useIsConnectedToPartner';
import { doesLocationRequiresVerification } from 'app/businessEditV2/utils/locations';
import VerificationRequiredButton from 'app/common/components/buttons/VerificationRequiredButton';
import GoogleIcon from 'app/common/components/icons/provider/GoogleLogo';
import Loader from 'app/common/designSystem/components/atoms/svg/Loader';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import dataLayer from 'app/common/utils/dataLayer';
import { BusinessStatusLogo } from 'app/pages/visibility/BusinessListV2/sections/BusinessListSection/BusinessCard/BusinessCard.styled';
import { SETTINGS_PLATFORMS_GOOGLE_VERIFICATION_PATHNAME } from 'app/routing/routeIds';
import { GOOGLE_MY_BUSINESS } from 'app/states/knowledge/reducers/businessConnection/accounts';

type GoogleButtonProps = {
    connectionsPageLink: string;
};

const GoogleButton: FC<GoogleButtonProps> = ({ connectionsPageLink }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isConnected = useIsConnectedToPartner(Partner.Google);
    const { data: business } = useBusiness();
    const { data: me } = useMe();
    const { data: location, isLoading: isLoadingLocation } = useLocation(
        GOOGLE_MY_BUSINESS,
        business?.google_location_id ?? '',
    );

    const displayRequiresVerification = doesLocationRequiresVerification(location);
    const isValid = isConnected && !displayRequiresVerification;

    const onClick = (eventName: string) => {
        dataLayer.pushDict(eventName, {
            user_id: me?.id,
            shadow_user_id: me?.shadow_user_id,
            business_id: business?.id,
            location_id: location?.id,
        });

        if (!isConnected || isValid) navigate(connectionsPageLink);
        else {
            navigate(
                `${SETTINGS_PLATFORMS_GOOGLE_VERIFICATION_PATHNAME}?location_id=${
                    location?.id ?? ''
                }&query=${encodeURIComponent(
                    `${location?.location_name ?? ''} ${location?.locality ?? ''}`,
                )}`,
            );
        }
    };

    if (isLoadingLocation) {
        return <Loader />;
    }

    const googleLogo = (
        <ConnectionButtonLogo partnerName={Partner.Google} loading={false} disabled={false} />
    );

    const googleVerificationLogo = (
        <BusinessStatusLogo>
            <GoogleIcon />
        </BusinessStatusLogo>
    );

    return (
        <TooltipWrapper position="bottom-end" text={isValid ? t('pam_status_connected') : ''}>
            {isValid ? (
                <Button
                    appearance="outlined"
                    onClick={() => onClick('edit_business_google_button--valid')}
                    shape="cube"
                    size="medium"
                    variant="success"
                    icon={googleLogo}
                    dataTrackId="edit_business_google_button--valid"
                >
                    <InnerTextButtonContainer variant="bodyMBold" as="span" color="success">
                        {t('pam_status_connected')}
                        <Icon
                            icon={[FontAwesomeIconsPartooUsed.faCheck, IconPrefix.REGULAR]}
                            color="success"
                        />
                    </InnerTextButtonContainer>
                </Button>
            ) : displayRequiresVerification ? (
                <VerificationRequiredButton
                    eventName="verify_from_business_edit"
                    location={location}
                />
            ) : (
                <Button
                    appearance="outlined"
                    onClick={() => onClick('edit_business_google_button--not-connected')}
                    shape="cube"
                    size="medium"
                    variant="secondary"
                    icon={googleVerificationLogo}
                    dataTrackId="edit_business_google_button--not-connected"
                >
                    {t('connection_required')}
                </Button>
            )}
        </TooltipWrapper>
    );
};

export default GoogleButton;
