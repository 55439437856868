import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const DoughnutOverlayContainer = styled.div<{ color?: string }>`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
    font-size: 1.7rem;
    line-height: 90%;
    pointer-events: none;
    user-select: none;
    ${({ color }) =>
        color &&
        css`
            color: color;
        `}

    span {
        font-size: 3rem;
    }

    div {
        span {
            font-size: 3.6rem;
        }
    }
`;
