import { connect } from 'react-redux';

import {
    loginRequest,
    showModalPasswordSelector,
} from 'app/states/connection/reducers/login';
import { AppState } from 'app/states/reducers';

import LoginForm from './LoginForm';

const mapStateToProps = ({ login }: AppState) => ({
    showUnsafePasswordModal: showModalPasswordSelector(login),
});

const matchDispatchToProps = (dispatch: any) => ({
    onSubmit: () => dispatch(loginRequest()),
});

const LoginFormComponent = connect(
    mapStateToProps,
    matchDispatchToProps,
)(LoginForm);

export default LoginFormComponent;
