import { css } from '@emotion/react';

export const transitionBezier = css`
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    &::before {
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
`;

export const rotate90 = css`
    transform: rotate(90deg);
    i:before {
        transform: rotate(90deg);
    }
    &::before {
        transform: rotate(90deg);
    }
`;
