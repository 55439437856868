import React from 'react';

import { useTranslation } from 'react-i18next';

import {
    BadgeHigh,
    BadgeLow,
    BadgeMid,
} from 'app/businessEditV2/sections/BusinessStatusSection/BusinessBanner/BannerIcons';
import useCompletionStats from 'app/pages/visibility/BusinessListV2/hooks/businesses/useCompletionStats';

import { CompletionRateValues } from 'app/pages/visibility/BusinessListV2/utils/enums';

import BusinessAdviceDescription from './BusinessAdviceDescription';

import { BusinessAdviceSectionContainer, BusinessBadges } from './BusinessAdviceSection.styled';
import BusinessCompletionBadge from './BusinessCompletionBadge';

const BusinessAdviceSection = () => {
    const { t } = useTranslation();
    const stats = useCompletionStats();

    return (
        <BusinessAdviceSectionContainer>
            <BusinessAdviceDescription average={stats?.average_completion_rate} />
            <BusinessBadges>
                <BusinessCompletionBadge
                    title={t('business_edit_status_popup_low')}
                    completionRateFilter={CompletionRateValues.LOW}
                    icon={<BadgeLow />}
                    count={stats?.counts.low}
                />
                <BusinessCompletionBadge
                    title={t('business_edit_status_popup_good')}
                    completionRateFilter={CompletionRateValues.MID}
                    icon={<BadgeMid />}
                    count={stats?.counts.mid}
                />
                <BusinessCompletionBadge
                    title={t('business_edit_status_popup_very_good')}
                    completionRateFilter={CompletionRateValues.HIGH}
                    icon={<BadgeHigh />}
                    count={stats?.counts.high}
                />
            </BusinessBadges>
        </BusinessAdviceSectionContainer>
    );
};

export default BusinessAdviceSection;
