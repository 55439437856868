import React, { useMemo, useState } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import BusinessGroupsFilter from './BusinessOldGroupsFilter';

export const OpenedGroupsContext = React.createContext<OpenedGroupsContextType>(null as any);

export type OpenedGroupsContextType = {
    openedGroups: Array<number>;
    setOpenedGroups: (arg0: Array<number>) => void;
};

const GroupsFilterProvider = () => {
    /**
     * I have no idea why these awful context providers are here and not in the component that actually uses them
     * I just moved them here from the CollapsibleSidebar component to clean up the code
     * Let's just ignore this and wait for old groups to die
     */

    const location = useLocation();
    const { group } = qs.parse(location.search);
    // @ts-ignore
    const groupIdSearchParam = group?.split(',')[0];
    const initialOpenedGroups = groupIdSearchParam ? [Number(groupIdSearchParam)] : [];
    // state for opened knowledge management group
    // implemented here to keep state while hovering
    const [openedGroups, setOpenedGroups] = useState(initialOpenedGroups);
    const openedGroupsContext = useMemo(
        () => ({
            openedGroups,
            setOpenedGroups,
        }),
        [openedGroups, setOpenedGroups],
    );

    return (
        <OpenedGroupsContext.Provider value={openedGroupsContext}>
            <BusinessGroupsFilter />
        </OpenedGroupsContext.Provider>
    );
};

export default GroupsFilterProvider;
