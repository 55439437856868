import { useContext } from 'react';

import { toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import messagingApi from 'app/api/v2/api_calls/messagingApiCalls';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { MESSAGING_CONVERSATION_STATS } from 'app/common/data/queryKeysConstants';
import useMeUncamel from 'app/common/hooks/queries/useMeUncamel';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import dataLayer from 'app/common/utils/dataLayer';
import { STATIC_BASE_URL } from 'app/config';
import {
    AssignFilterOption,
    Conversation,
    ConversationMessage,
    ConversationStatus,
    MESSAGE_CONTENT_TYPE,
    MESSAGE_STATUS,
    PostMessageData,
    StatusFilterOption,
    URLParams,
} from 'app/states/messaging';

import { getConversationsKey } from './useGetConversations';
import { useHasAssignConversationFeature } from './useHasAssignConversationFeature';
import { MessagingContext } from '../MessagingContext';
import { createAssignmentMessage, createStatusMessage } from '../services/createMetadataMessage';

import {
    clearConversationsExceptActive,
    createConversationsCache,
    updateConversation,
} from '../services/handleConversationsCache';
import { addNewMessage, updateMessage } from '../services/handleMessagesCache';

export const usePostMessage = (conversation: Conversation, isRetry: boolean) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { setActiveConversation, setIsRequestingNewConversations } = useContext(MessagingContext);
    const { hasAssignConversationFeature } = useHasAssignConversationFeature();
    const { data: me } = useMeUncamel();

    const [statusFilter, setStatusFilter] = useStateQueryParams<StatusFilterOption>(
        URLParams.STATUS,
    );
    const [assignFilter, setAssignFilter] = useStateQueryParams<AssignFilterOption>(
        URLParams.ASSIGN,
    );
    const businessFilters = useBusinessModalFilters();
    const isSwitchingStatusFilter = statusFilter === StatusFilterOption.CLOSED;
    const isSwitchingAssignFilter = assignFilter === AssignFilterOption.UNASSIGNED;
    const newAssignFilter = isSwitchingAssignFilter ? AssignFilterOption.ME : assignFilter;

    const handleMessageRequest = (
        conversation: Conversation,
        message: ConversationMessage,
        isRetry: boolean,
    ) => {
        if (!isRetry && me) {
            const isAssigning =
                (!conversation.assigned_user_id || conversation.assigned_user_id !== me.id) &&
                hasAssignConversationFeature;

            if (isSwitchingStatusFilter) {
                reopeningAction();
            }
            if (isAssigning) {
                assigningAction();
            }

            // Replaced later on by the actual image.
            const placeHolderMessage = {
                ...message,
                content: `${STATIC_BASE_URL}/images/app/app_messaging/image_placeholder.svg`,
            };
            const newMessage =
                message.content_type === MESSAGE_CONTENT_TYPE.IMAGE ? placeHolderMessage : message;
            addNewMessage(queryClient, conversation.id, newMessage);

            handleConversationUpdate(isAssigning, newMessage);
        }
    };

    const reopeningAction = () => {
        const metadataMessage = createStatusMessage(
            conversation,
            MESSAGE_CONTENT_TYPE.REOPENING,
            me,
        );
        addNewMessage(queryClient, conversation.id, metadataMessage);
    };
    const assigningAction = () => {
        const senderLabel = `${me?.first_name} ${me?.last_name}`;
        const metadataMessage = createAssignmentMessage(conversation, senderLabel, me);
        addNewMessage(queryClient, conversation.id, metadataMessage);

        dataLayer.pushDict('messaging_conversation_self_assign_by_textarea');
    };

    const handleConversationUpdate = (isAssigning: boolean, newMessage: ConversationMessage) => {
        const updatedConversation = {
            ...conversation,
            assigned_user_id: me && isAssigning ? me.id : conversation.assigned_user_id,
            status: ConversationStatus.OPEN,
            last_message: newMessage,
            is_solved: false,
        };
        updateConversation(updatedConversation, statusFilter, assignFilter, businessFilters);

        setActiveConversation(updatedConversation);

        if (isSwitchingStatusFilter || isSwitchingAssignFilter) {
            setIsRequestingNewConversations(true);

            const newFiltersConversationsKey = getConversationsKey(
                StatusFilterOption.OPEN,
                newAssignFilter,
                businessFilters,
            );
            clearConversationsExceptActive(
                updatedConversation,
                StatusFilterOption.OPEN,
                newAssignFilter,
                businessFilters,
            );
            if (!queryClient.getQueryData(newFiltersConversationsKey)) {
                createConversationsCache(
                    updatedConversation,
                    StatusFilterOption.OPEN,
                    newAssignFilter,
                    businessFilters,
                );
            }

            const oldFiltersConversationsKey = getConversationsKey(
                statusFilter,
                assignFilter,
                businessFilters,
            );
            /*  If we do not remove it, the old and outdated active conversation will remain in the cache 
                and not be updated since we are only invalidating the cache of these params when we come back.
            */
            queryClient.removeQueries(oldFiltersConversationsKey);

            queryClient.invalidateQueries([MESSAGING_CONVERSATION_STATS]);
        }

        if (isSwitchingStatusFilter) setStatusFilter(StatusFilterOption.OPEN);
        if (isSwitchingAssignFilter) setAssignFilter(AssignFilterOption.ME);
    };

    const handleMessageSuccess = (
        conversationId: number,
        message: ConversationMessage,
        message_id: number,
    ) => {
        if (message.content_type === MESSAGE_CONTENT_TYPE.TEXT) {
            const updatedMessage = {
                ...message,
                id: message_id,
            };

            updateMessage(queryClient, conversationId, updatedMessage);
        }
        if (isSwitchingStatusFilter || isSwitchingAssignFilter) {
            const conversationsKey = getConversationsKey(
                StatusFilterOption.OPEN,
                newAssignFilter,
                businessFilters,
            );

            queryClient.invalidateQueries(conversationsKey);
            setIsRequestingNewConversations(false);
        }
    };

    const handleMessageFailure = (conversationId: number, message: ConversationMessage) => {
        if (message.content_type === MESSAGE_CONTENT_TYPE.TEXT) {
            toast.error(null, t('api_key_manager_snackbar_error'));

            const updatedMessage = {
                ...message,
                status: MESSAGE_STATUS.FAIL,
            };

            updateMessage(queryClient, conversationId, updatedMessage);
        }
    };

    return useMutation(
        (message: ConversationMessage) =>
            messagingApi.postMessage(
                conversation.id,
                message.content as string,
                message.date,
                message.content_type,
                message.template_id ?? null,
            ),
        {
            onMutate: (message: ConversationMessage) => {
                handleMessageRequest(conversation, message, isRetry);
            },
            onSuccess: ({ message_id }: PostMessageData, message: ConversationMessage) => {
                handleMessageSuccess(conversation.id, message, message_id);
            },
            onError: (message: ConversationMessage) => {
                handleMessageFailure(conversation.id, message);
            },
        },
    );
};
