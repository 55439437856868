import React from 'react';

type Props = {
    width?: number;
    height?: number;
    color?: string;
};

const DayPreviewIcon = ({
    width = 58,
    height = 58,
    color = '#142542',
}: Props) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 58 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22.6599 52.2693C22.5322 52.2693 22.7901 52.2926 22.6599 52.2693V52.2693ZM26.8681 5.06484C37.3991 5.06484 46.3488 12.5818 48.1487 22.9384C48.3555 24.1287 49.4882 24.9262 50.6783 24.719C51.8686 24.5121 52.6657 23.3795 52.4589 22.1894C50.5334 10.0549 39.178 0.493634 26.8679 0.690171C-4.9834 1.76834 -8.70427 45.9087 22.6599 52.2693C25.3041 52.2072 25.6558 48.4642 23.0421 47.9284C-2.66051 42.6973 0.644964 5.70542 26.8681 5.06484ZM29.0009 24.1657V12.6113C29.0009 11.4032 28.0216 10.4239 26.8135 10.4239C25.6054 10.4239 24.6261 11.4032 24.6261 12.6113V24.1657C23.8911 24.8144 23.45 25.8387 23.5444 26.8809L17.2946 33.0362C15.8978 34.3911 16.9084 36.803 18.8297 36.782C19.3839 36.782 19.9384 36.5726 20.3645 36.153L26.7242 29.8894C29.7761 29.9783 31.2543 26.1624 29.0009 24.1657Z"
            fill={color}
        />
        <path
            d="M42.7609 27.3532C34.3982 27.3532 27.5942 34.1572 27.5942 42.5199C27.5942 50.8825 34.3982 57.6865 42.7609 57.6865C51.1236 57.6865 57.9276 50.8825 57.9276 42.5199C57.9276 34.1572 51.1236 27.3532 42.7609 27.3532ZM49.1776 44.8532H45.0942V48.9365C45.0942 50.2269 44.0512 51.2699 42.7609 51.2699C41.4706 51.2699 40.4276 50.2269 40.4276 48.9365V44.8532H36.3442C35.0539 44.8532 34.0109 43.8102 34.0109 42.5199C34.0109 41.2295 35.0539 40.1865 36.3442 40.1865H40.4276V36.1032C40.4276 34.8129 41.4706 33.7699 42.7609 33.7699C44.0512 33.7699 45.0942 34.8129 45.0942 36.1032V40.1865H49.1776C50.4679 40.1865 51.5109 41.2295 51.5109 42.5199C51.5109 43.8102 50.4679 44.8532 49.1776 44.8532Z"
            fill={color}
        />
    </svg>
);

export default DayPreviewIcon;
