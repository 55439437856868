import { useState } from 'react';

import { Banner, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import FunnelModal from 'app/common/components/FunnelModal';

import {
    FunnelModalFooter,
    FunnelModalHeader,
} from 'app/common/components/FunnelModal/FunnelModal.styled';

import { RadioCardList } from 'app/common/components/RadioCardList';

import { TitleContainer } from './WhatsappAccountCreationModalPrerequirements.styled';
import {
    CenteredBody,
    ModalBodyContainer,
} from './WhatsappAccountCreationNumberChoiceModal.styled';
import { WhatsappEmbeddedSignUpButton } from './WhatsappEmbeddedSignUpButton';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onBack: () => void;
    onEmbeddedSignupFinished: () => void;
    isSmb: boolean;
};

const WhatsappAccountCreationNumberChoiceModal = ({
    isOpen,
    onClose,
    onBack,
    onEmbeddedSignupFinished,
    isSmb,
}: Props) => {
    const { t } = useTranslation();

    const [cardSelected, setCardSelected] = useState(0);

    const cards = [
        {
            id: 0,
            title: t('whatsapp_number_choice_partoo_number_title'),
            description: isSmb
                ? t('whatsapp_number_choice_partoo_number_description_smb')
                : t('whatsapp_number_choice_partoo_number_description'),
            icon: '📱',
            label: t('whatsapp_number_choice_own_number_label'),
        },
        {
            id: 1,
            title: t('whatsapp_number_choice_own_number_title'),
            description: isSmb
                ? t('whatsapp_number_choice_own_number_description_smb')
                : t('whatsapp_number_choice_own_number_description'),
            icon: '📲',
        },
    ];

    return (
        <FunnelModal isOpen={isOpen} onBack={onBack} onClose={onClose}>
            <FunnelModalHeader>
                <Text variant="heading6" color="blackberry">
                    {t('whatsapp_account_creation_modal_header')}
                </Text>
            </FunnelModalHeader>

            <ModalBodyContainer>
                <CenteredBody>
                    <TitleContainer>
                        <Text variant="heading3" color="blackberry">
                            📞
                        </Text>
                        <Text variant="heading3" color="blackberry">
                            {t('whatsapp_account_creation_number_choice_modal_title')}
                        </Text>
                    </TitleContainer>

                    <Banner variant="info" withCloseIcon={false} dataTrackId={''}>
                        {t('whatsapp_account_creation_number_choice_modal_banner')}
                    </Banner>

                    <RadioCardList<number>
                        cards={cards}
                        onChange={setCardSelected}
                        value={cardSelected}
                    />
                </CenteredBody>
            </ModalBodyContainer>

            <FunnelModalFooter>
                <WhatsappEmbeddedSignUpButton
                    onEmbeddedSignupFinished={onEmbeddedSignupFinished}
                    onClose={onClose}
                    need_partoo_number={cardSelected === 0}
                    gtmEvent={'validate_wa_phone_choice'}
                />
            </FunnelModalFooter>
        </FunnelModal>
    );
};

export default WhatsappAccountCreationNumberChoiceModal;
