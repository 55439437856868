import React, { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { PhotosData } from 'app/api/types/business';
import getAllPhotos from 'app/businessEditV2/utils/photos';
import {
    BULK_IN_APP_STEPS,
    BulkInAppStepInfo,
    FunnelBulkInAppStepsEnum,
} from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/data/funnelSteps';

const EMPTY_PHOTOS = {
    primary: null,
    LOGO: null,
    EXTERIOR: [],
    INTERIOR: [],
    PRODUCT: [],
    AT_WORK: [],
    FOOD_AND_DRINK: [],
    MENU: [],
    COMMON_AREAS: [],
    ROOMS: [],
    TEAM: [],
    secondary: [],
    preferred_photo: '',
};
interface FunnelModalContextType {
    showFunnel: boolean;
    setShowFunnel: (show: boolean) => void;
    currentStep: FunnelBulkInAppStepsEnum;
    setCurrentStep: (stepId: FunnelBulkInAppStepsEnum) => void;
    currentStepInfo: BulkInAppStepInfo;
    photos: PhotosData;
    setPhotos: (photos: PhotosData) => void;
    showUnsavedModal: boolean;
    setShowUnsavedModal: (show: boolean) => void;
    onClose: () => void;
}

export const FunnelModalContext = React.createContext<FunnelModalContextType>({
    showFunnel: false,
    setShowFunnel: () => undefined,
    currentStep: FunnelBulkInAppStepsEnum.SELECT_FIELD,
    setCurrentStep: () => undefined,
    currentStepInfo: BULK_IN_APP_STEPS[FunnelBulkInAppStepsEnum.SELECT_FIELD],
    photos: EMPTY_PHOTOS,
    setPhotos: () => undefined,
    showUnsavedModal: false,
    setShowUnsavedModal: () => undefined,
    onClose: () => undefined,
});

interface Props {
    children: ReactNode;
}

export const FunnelModalContextProvider: FC<Props> = ({ children }) => {
    const [showFunnel, setShowFunnel] = useState(false);
    const [currentStep, setCurrentStep] = useState<FunnelBulkInAppStepsEnum>(
        FunnelBulkInAppStepsEnum.SELECT_FIELD,
    );
    const [currentStepInfo, setCurrentStepInfo] = useState(
        BULK_IN_APP_STEPS[FunnelBulkInAppStepsEnum.SELECT_FIELD],
    );
    const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);

    const [photos, setPhotos] = useState<PhotosData>(EMPTY_PHOTOS);

    useEffect(() => {
        setCurrentStepInfo(BULK_IN_APP_STEPS[currentStep]);
    }, [currentStep]);

    useEffect(() => {
        if (!showFunnel) {
            // reset photos if the modal is closed
            setPhotos(EMPTY_PHOTOS);
            setCurrentStep(FunnelBulkInAppStepsEnum.SELECT_FIELD);
            setShowUnsavedModal(false);
        }
    }, [showFunnel]);

    const onClose = () => {
        if (currentStep === FunnelBulkInAppStepsEnum.ADD_PHOTOS && getAllPhotos(photos).length) {
            setShowUnsavedModal(true);
        } else {
            setShowFunnel(false);
            setCurrentStep(FunnelBulkInAppStepsEnum.SELECT_FIELD);
        }
    };

    const value = useMemo(
        () => ({
            showFunnel,
            setShowFunnel,
            currentStep,
            setCurrentStep,
            currentStepInfo,
            photos,
            setPhotos,
            showUnsavedModal,
            setShowUnsavedModal,
            onClose,
        }),
        [
            showFunnel,
            setShowFunnel,
            currentStep,
            setCurrentStep,
            currentStepInfo,
            photos,
            setPhotos,
            showUnsavedModal,
            setShowUnsavedModal,
            onClose,
        ],
    );

    return <FunnelModalContext.Provider value={value}>{children}</FunnelModalContext.Provider>;
};

export const useFunnelModalContext = () => useContext(FunnelModalContext);
