import React from 'react';

import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import ErrorImage from './images/ErrorImage';
import { StatusScreenImageWrapper, StatusScreenWrapper } from './StatusScreens.styled';
import { ConfirmButton } from '../../Fields/ConfirmButton.styled';
import { FloatingFooter } from '../../Fields/FloatingFooter.styled';

type Props = {
    handleClose: () => void;
    errorMessage?: string;
};

const ErrorScreen: React.FC<Props> = ({ errorMessage, handleClose }) => {
    const { t } = useTranslation();

    return (
        <>
            <StatusScreenWrapper>
                <StatusScreenImageWrapper>
                    <ErrorImage />
                </StatusScreenImageWrapper>
                <Text variant="heading5">{t('google_verification__error_screen__title')}</Text>
                <Text variant="bodyMRegular">
                    {t('google_verification__error_screen__content')}
                </Text>
                {errorMessage && (
                    <Text variant="bodyMRegular" italic>
                        {t(`google_verification__message_${errorMessage}`)}
                    </Text>
                )}
            </StatusScreenWrapper>
            <FloatingFooter>
                <ConfirmButton
                    dataTrackId="google_verification__methods__error_confirm_button"
                    size="large"
                    shape="cube"
                    variant="danger"
                    onClick={handleClose}
                >
                    {t('google_verification__status_screen__okay')}
                </ConfirmButton>
            </FloatingFooter>
        </>
    );
};

export default ErrorScreen;
