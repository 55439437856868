import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';

import BusinessCreate from './BusinessCreate';
import { BusinessCreateWithNewSidebar } from './BusinessCreateWithNewSidebar';

export const BusinessCreatePage = () => {
    const { t } = useTranslation();
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const BusinessCreateComponent = isNewSidebarEnabled
        ? BusinessCreateWithNewSidebar
        : BusinessCreate;

    return (
        <>
            <DynamicPageHeader title={t('add_business_title')} />
            <BusinessCreateComponent />
        </>
    );
};
