import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const QueriesCardContainer = styled.div`
    border: 1px solid #dddfe3;
    border-radius: 4px;
    padding: 10px;
    position: relative;
`;

export const KeywordsDataContainer = styled.div`
    text-align: center;
`;

export const ListContainer = styled.div``;

export const ListItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 5.5px 0;
    :not(:last-child) {
        border-bottom: 1px solid #f6f8fc;
    }
`;

export const ListItemKey = styled(Text)`
    text-align: left;
    font-style: normal;
    font-weight: 300;
    max-width: 200px;
`;

export const ListItemValue = styled(Text)`
    text-align: right;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
`;

export const NoDataKeywordsText = styled.div`
    margin: 10px;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #c7c9cc;
`;

export const NoDataContainer = styled.div`
    margin: 16px auto;
    width: 178px;
`;
