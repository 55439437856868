import { useEffect, useState } from 'react';

import { useImagesUploaderContext } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/hooks/useImagesUploaderContext';

import { getErrors } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/utils';
import DraggableWrapper from 'app/common/components/DragAndDrop/DraggableWrapper';

import { ImageUploaderRowWrapper } from './ImageUploaderRow.styled';
import { ImageUploaderRowBody } from './ImageUploaderRowBody/ImageUploaderRowBody';
import { ImageUploaderRowHeader } from './ImageUploaderRowHeader/ImageUploaderRowHeader';

type ImageUploaderRowProps = {
    imageIndex: number;
    draggableId: string;
};

export const ImageUploaderRow = ({ imageIndex, draggableId }: ImageUploaderRowProps) => {
    const { errors, textFields } = useImagesUploaderContext();
    const imageErrors = getErrors(errors, imageIndex);
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        if (textFields.length !== 0) setOpen(!open);
    };

    useEffect(() => {
        // open the card if there is an error
        if (imageErrors.length) setOpen(true);
    }, [errors]);

    return (
        <DraggableWrapper
            key={draggableId}
            index={imageIndex}
            draggableId={draggableId}
            withIcon={false}
        >
            <ImageUploaderRowWrapper gap="16px">
                <ImageUploaderRowHeader imageIndex={imageIndex} toggleOpen={toggleOpen} />
                {open && <ImageUploaderRowBody imageIndex={imageIndex} />}
            </ImageUploaderRowWrapper>
        </DraggableWrapper>
    );
};
