import { useQuery } from 'react-query';

import { CompetitorLocalRank } from 'app/api/types/competitiveBenchmark';
import api from 'app/api/v2/api_calls';
import { GetLocalRankingsParams } from 'app/api/v2/api_calls/competitiveBenchmark';
import useLocalBusinessIdCompetitiveBenchmark from 'app/common/components/businessModal/hooks/birdOnly/useLocalBusinessIdCompetitiveBenchmark';
import { COMPETITIVE_BENCHMARK_LOCAL_RANKINGS } from 'app/common/data/queryKeysConstants';

import useBusiness from 'app/common/hooks/queries/useBusiness';

import { useKeywordFilter } from './useKeywordFilter';
import { formatDate } from '../utils/formatDate';

export type LocalRankings = {
    data: CompetitorLocalRank[];
    isLoading: boolean;
};

export const useLocalRankings = (date: Date): LocalRankings => {
    const [keyword] = useKeywordFilter();
    const businessId = useLocalBusinessIdCompetitiveBenchmark() as string;
    const { data: business } = useBusiness(businessId);

    const params: GetLocalRankingsParams = {
        date: formatDate(date),
        keyword: keyword.keyword,
        business: businessId,
    };
    const { data, isLoading } = useQuery([COMPETITIVE_BENCHMARK_LOCAL_RANKINGS, params], () =>
        api.competitiveBenchmark.getCompetitorLocalRankings(params),
    );

    if (data && business && !data.find(rank => rank.highlight)) {
        data.push({
            name: business.name,
            highlight: true,
            address: business.address_full,
            rating: 0,
            reviewCount: 0,
        });
    }

    return { data: data || [], isLoading };
};
