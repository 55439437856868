import { useMutation, useQueryClient } from 'react-query';

import { invalidatesAny } from 'app/businessEditV2/utils/utils';

import { GROUPS_V2 } from 'app/common/data/queryKeysConstants';

import { CreateGroupParams } from '../../../../../../../../api/types/groupsV2';
import api from '../../../../../../../../api/v2/api_calls';

export default function useEditGroup(
    sectionId: string,
    groupId: string,
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const queryClient = useQueryClient();
    return useMutation(
        (params: CreateGroupParams) => api.groupsV2.updateGroup(sectionId, groupId, params),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(invalidatesAny(GROUPS_V2)).then(onSuccess);
            },
            onError,
        },
    );
}
