import React, { useCallback, useContext, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { Select } from 'app/pages/analytics/components/Select/Select';

import { TemplateFormContext } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/components/MessagesReplyTemplates.context';
import { RECENT, SortingOptions, USAGE } from 'app/states/messageReplyTemplate/dataTypes';

import { MessageReplyTemplateCard } from './MRTemplateCard';
import { NoSearchResult, TemplateListHeader } from './MRTemplateList.styled';
import { NoTemplateScreen } from './NoTemplateScreen';

import useGetMessageReplyTemplates from '../hooks/useGetMessageReplyTemplates';

const MessageTemplateList: React.FC = () => {
    const { t } = useTranslation();

    const { filteringTitle, creatingMode } = useContext(TemplateFormContext);
    // Handler of the sorting option
    const [sortingOption, setSortingOption] = useState<SortingOptions>(USAGE);
    const selectOptions = [
        {
            value: RECENT,
            label: t('messaging_reply_template_recent_sorting_option'),
            dataTrack: 'messaging_template__click__template_recent_order',
        },
        {
            value: USAGE,
            label: t('messaging_reply_template_usage_sorting_option'),
            dataTrack: 'messaging_template__click__template_usage_order',
        },
    ];
    const handleSelectClick = useCallback((value: SortingOptions) => {
        setSortingOption(value);
    }, []);

    const { data, isLoading } = useGetMessageReplyTemplates(sortingOption);

    if (data?.length === 0 && !isLoading && !creatingMode) {
        return <NoTemplateScreen />;
    }

    // find query inside content
    const dataFiltered = data?.filter(item => {
        const templateText = JSON.parse(item.content).text;
        return (
            item.title.toLowerCase().includes(filteringTitle.toLowerCase()) ||
            templateText.toLowerCase().includes(filteringTitle.toLowerCase()) ||
            item.icon?.includes(filteringTitle)
        );
    });

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };

    return (
        <div>
            <TemplateListHeader justifyContent="space-between" direction="row">
                <Text variant="heading6">
                    {`${data?.length} ${t('header_counter_reply_templates', {
                        count: data?.length,
                    })}
                        `}
                </Text>

                <Select
                    label={`${t('messaging_reply_template_settings_sorting_label')} : `}
                    handleClick={handleSelectClick}
                    options={selectOptions}
                    selectedValue={sortingOption}
                    dataTrack="messaging_template__click__template_order_button"
                />
            </TemplateListHeader>

            {dataFiltered?.map(template => (
                <MessageReplyTemplateCard template={template} key={template.id} />
            ))}
            {dataFiltered?.length === 0 && filteringTitle && (
                <NoSearchResult>
                    <NotFoundTemplate
                        show
                        title={t('no-result-found')}
                        subtitle=""
                        imgSrc={illustrationElement}
                        withButton={false}
                    />
                </NoSearchResult>
            )}
        </div>
    );
};

export default MessageTemplateList;
