import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'app/config';

import { Loader } from './MessageLoader.styled';

const MessageLoader = () => {
    const { t } = useTranslation();
    return (
        <Loader
            src={`${STATIC_BASE_URL}/images/app/app_messaging/loader.gif`}
            alt={t('loading')}
        />
    );
};
export default MessageLoader;
