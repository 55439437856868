import { keyBy } from 'lodash-es';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import {
    BusinessesInfoData,
    PublishersStatesBusinessesInfoData,
} from 'app/api/types/publisher_state';
import api from 'app/api/v2/api_calls';
import generateFiltersFromBusinessModal from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { filterByBusinessSelector } from 'app/common/components/businessModalDeprecated/reducers';
import { enableNewGroupsGenerator } from 'app/common/hooks/queries/useNewGroups';
import { Saga } from 'app/common/types';
import {
    GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST,
    GetBusinessPublisherStatesAction,
    getBusinessPublisherStatesFailure,
    getBusinessPublisherStatesSuccess,
} from 'app/states/presence/reducers/listing/businessDetails/actions';
import { PublisherBusinessesState } from 'app/states/presence/reducers/listing/businessDetails/reducer';
import { businessDetailsFormatter } from 'app/states/presence/services';

function toPublisherBusinessesState(
    data: PublishersStatesBusinessesInfoData,
    oneBusiness: boolean,
): PublisherBusinessesState {
    const businessIdsByPublisher: PublisherBusinessesState = {};

    if (oneBusiness) {
        businessIdsByPublisher.oneBusinessInfo = true;
    }

    Object.keys(data).forEach((publisherName: string) => {
        Object.keys(data[publisherName]).forEach((state: string) => {
            const toParse: BusinessesInfoData = data[publisherName][state];
            // @ts-ignore
            const toAdd: PublisherBusinessesState = {
                [publisherName]: {
                    [state]: {
                        ids: toParse.publishers_business.map(obj => obj.business_id),
                        byId: keyBy(
                            toParse.publishers_business.map(business =>
                                businessDetailsFormatter(business),
                            ),
                            'businessId',
                        ),
                        maxPage: toParse.max_page || 1,
                        page: toParse.page || 1,
                        isFetching: false,
                        error: 'None',
                    },
                },
            };
            Object.assign(businessIdsByPublisher, toAdd);
        });
    });
    return businessIdsByPublisher;
}

function* getBusinessesPublishersState({
    publisherName,
    state,
    page,
}: GetBusinessPublisherStatesAction) {
    try {
        // TODO BIRD: remove saga and use useBusinessModalFilters() instead of this
        const enableNewGroups = yield* enableNewGroupsGenerator();
        const filterByBusiness = yield select(filterByBusinessSelector);
        const query = {
            ...generateFiltersFromBusinessModal(filterByBusiness, false, enableNewGroups),
            state,
            publisher_name: publisherName,
            page,
        };
        // @ts-ignore
        const data = yield call(api.publisherState.getPublishersStatesBusinessesInfo, query);
        const businessIdsByPublisher: PublisherBusinessesState = toPublisherBusinessesState(
            data,
            false,
        );
        // @ts-ignore
        yield put(getBusinessPublisherStatesSuccess(businessIdsByPublisher));
    } catch (error) {
        // @ts-ignore
        const businessIdsByPublisher: PublisherBusinessesState = {
            [publisherName]: {
                [state]: {
                    isFetching: false,
                    error: error.message,
                },
            },
        };
        // @ts-ignore
        yield put(getBusinessPublisherStatesFailure(businessIdsByPublisher));
    }
}

function* getBusinessPublishersStateSaga(): Saga {
    yield takeEvery(GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST, getBusinessesPublishersState);
};

export default getBusinessPublishersStateSaga;
