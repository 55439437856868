import React, { useState } from 'react';

import AccessGroupButton from 'app/common/components/AccessGroupModal/AccessGroupButton/AccessGroupButton';

import AccessGroupModal from './AccessGroupModal';

type Props = {
    value: Array<number>;
    onChange: (value: Array<number>) => void;
    mandatory?: boolean;
    orgId?: number;
};

const AccessGroupModalButton = ({
    value,
    onChange,
    mandatory,
    orgId,
}: Props) => {
    const [opened, setOpened] = useState<boolean>(false);

    return (
        <>
            <AccessGroupModal
                opened={opened}
                closeModal={() => setOpened(false)}
                value={value}
                onChange={onChange}
                orgId={orgId}
            />

            <AccessGroupButton
                onClick={() => setOpened(true)}
                value={value}
                reset={() => onChange([])}
                orgId={orgId}
                mandatory={mandatory}
            />
        </>
    );
};

export default AccessGroupModalButton;
