import React from 'react';

const NeutralIcon = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.5 6.5C4.5 5.96875 4.9375 5.5 5.5 5.5C6.0625 5.5 6.5 5.96875 6.5 6.5C6.5 7.0625 6.0625 7.5 5.5 7.5C4.9375 7.5 4.5 7.0625 4.5 6.5ZM11.5 6.5C11.5 7.0625 11.0625 7.5 10.5 7.5C9.9375 7.5 9.5 7.0625 9.5 6.5C9.5 5.96875 9.9375 5.5 10.5 5.5C11.0625 5.5 11.5 5.96875 11.5 6.5ZM10.25 10.25C10.6562 10.25 11 10.5938 11 11C11 11.4375 10.6562 11.75 10.25 11.75H5.75C5.3125 11.75 5 11.4375 5 11C5 10.5938 5.3125 10.25 5.75 10.25H10.25ZM16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C4.40625 1.5 1.5 4.4375 1.5 8C1.5 11.5938 4.40625 14.5 8 14.5Z"
            fill="#FF9014"
        />
    </svg>
);

export default NeutralIcon;
