import { TextArea } from '@partoohub/ui';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
    control: Control<any>;
}

const MAX_TERMS_AND_CONDITIONS_CHARACTERS = 5000;

export const ControlledTermsConditions = ({ control }: Props) => {
    const { t } = useTranslation();
    const { field, formState } = useController({
        name: 'event_terms_and_conditions',
        control,
        rules: {
            maxLength: MAX_TERMS_AND_CONDITIONS_CHARACTERS,
            minLength: 0,
        },
    });

    const hasErrorTermsAndConditionsLength =
        formState.errors.event_terms_and_conditions?.type === 'maxLength';

    const handleChange = (value: string) => {
        field.onChange(value?.slice(0, MAX_TERMS_AND_CONDITIONS_CHARACTERS));
    };

    return (
        <TextArea
            {...field}
            dataTrackId="event_terms_and_conditions"
            label={t('google_post_terms_and_conditions')}
            onChange={handleChange}
            rows={1}
            error={hasErrorTermsAndConditionsLength}
            characterCount={MAX_TERMS_AND_CONDITIONS_CHARACTERS}
        />
    );
};
