import { css } from '@emotion/react';
import styled from '@emotion/styled';

type Align =
    | 'normal'
    | 'stretch'
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'self-start'
    | 'self-end'
    | 'first'
    | 'first baseline'
    | 'last baseline'
    | 'safe center'
    | 'unsafe center'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset';

export type FlexProps = {
    flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
    alignItems?: Align;
    alignSelf?: Align | 'auto';
    justifyContent?:
        | 'normal'
        | 'center'
        | 'start'
        | 'end'
        | 'flex-start'
        | 'flex-end'
        | 'left'
        | 'right'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'stretch'
        | 'safe center'
        | 'unsafe center'
        | 'inherit'
        | 'initial'
        | 'unset';
    flex?: string;
    flexBasis?: string;
    gap?: string;
};

const Flexbox = styled.div`
    display: flex;
    ${(props: FlexProps) =>
        props.flexDirection &&
        css`
            flex-direction: ${props.flexDirection};
        `};
    ${(props: FlexProps) =>
        props.flexWrap &&
        css`
            flex-wrap: ${props.flexWrap};
        `};
    ${(props: FlexProps) =>
        props.alignItems &&
        css`
            align-items: ${props.alignItems};
        `};
    ${(props: FlexProps) =>
        props.justifyContent &&
        css`
            justify-content: ${props.justifyContent};
        `};
    ${(props: FlexProps) =>
        props.flex &&
        css`
            flex: ${props.flex};
        `};
    ${(props: FlexProps) =>
        props.flexBasis &&
        css`
            flex-basis: ${props.flexBasis};
        `};
    ${(props: FlexProps) =>
        props.gap &&
        css`
            gap: ${props.gap};
        `}
    ${(props: FlexProps) =>
        props.alignSelf &&
        css`
            align-self: ${props.alignSelf};
        `}
`;

export default Flexbox;
