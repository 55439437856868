import styled from '@emotion/styled';

import { ChipColor } from '../StatChip';

type StatChipContainerProps = {
    chipColor: ChipColor;
};

export const StatChipContainer = styled.div<StatChipContainerProps>`
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    margin-top: 4px;
    padding: 2px 4px;
    background-color: ${({ theme, chipColor }) => theme.colors[chipColor].alpha};
`;
