import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import i18n from 'app/common/translations/i18n';

dayjs.extend(duration);

export const timeUnitConverter = (time_duration: number): string => {
    if (time_duration < 60000) {
        // display 1min when < 1 minute
        return `1 ${i18n.t('messaging_conversation_card_time_minutes')}`;
    }

    if (time_duration < 3600000) {
        // 1 hour
        return `${Math.floor(
            dayjs.duration(time_duration).asMinutes(),
        )} ${i18n.t('messaging_conversation_card_time_minutes')}`;
    }

    if (time_duration < 86400000) {
        // 1 day
        return `${Math.floor(dayjs.duration(time_duration).asHours())} ${i18n.t(
            'messaging_conversation_card_time_hours',
        )}`;
    }

    if (time_duration < 604800000) {
        // 1 week
        return `${Math.floor(dayjs.duration(time_duration).asDays())} ${i18n.t(
            'messaging_conversation_card_time_days',
        )}`;
    }

    if (time_duration < 31536000000) {
        // 1 year
        return `${Math.floor(dayjs.duration(time_duration).asWeeks())} ${i18n.t(
            'messaging_conversation_card_time_weeks',
        )}`;
    }

    if (time_duration < 63072000000) {
        // 2 years
        return `1 ${i18n.t('messaging_conversation_card_time_year')}`;
    }

    return `${Math.floor(dayjs.duration(time_duration).asYears())} ${i18n.t(
        'messaging_conversation_card_time_years',
    )}`;
};
