import { FC } from 'react';

import { greyColorObject } from '@partoohub/branding';

export const EyeSlashIcon: FC = () => (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.76768 2.41943C4.87518 1.55693 6.29518 0.900185 8.00018 0.900185C10.0202 0.900185 11.6377 1.82118 12.8152 2.91518C13.9852 4.00018 14.7677 5.27768 15.1377 6.19268C15.2202 6.39018 15.2202 6.61018 15.1377 6.80768C14.8027 7.61518 14.1302 8.75268 13.1402 9.76768L15.7702 11.8277C16.0302 12.0327 16.0777 12.4102 15.8727 12.6702C15.6677 12.9302 15.2902 12.9777 15.0302 12.7727L0.230079 1.17243C-0.0307214 0.967935 -0.0764464 0.590935 0.127979 0.330085C0.332429 0.0692847 0.709429 0.0235597 0.970429 0.227985L3.76768 2.41943ZM4.74518 3.18768L5.89518 4.08768C6.45768 3.59768 7.19518 3.30018 8.00018 3.30018C9.76768 3.30018 11.2002 4.73268 11.2002 6.50018C11.2002 7.03018 11.0727 7.52768 10.8452 7.96768L12.1902 9.02268C13.0277 8.17018 13.6477 7.17768 13.9652 6.50018C13.6027 5.72768 12.9602 4.68768 11.9977 3.79268C10.9702 2.84018 9.63018 2.07768 8.00018 2.07768C6.73768 2.07768 5.62768 2.54343 4.74518 3.18768ZM9.87268 7.20518C9.95518 6.98518 10.0002 6.74768 10.0002 6.47768C10.0002 5.39518 9.10518 4.47768 8.00018 4.47768C7.98268 4.47768 7.96768 4.50018 7.92768 4.50018C7.98268 4.62768 8.00018 4.76268 8.00018 4.87768C8.00018 5.15518 7.94018 5.39518 7.83518 5.60768L9.87268 7.20518ZM10.1077 10.4627L11.1552 11.2877C10.2477 11.7777 9.19518 12.1002 8.00018 12.1002C5.98018 12.1002 4.36268 11.1802 3.18518 10.0852C2.01568 8.97768 1.23368 7.70018 0.861679 6.80768C0.779679 6.61018 0.779679 6.39018 0.861679 6.19268C1.10018 5.62018 1.50743 4.88018 2.07743 4.13768L3.02018 4.88018C2.55268 5.46268 2.24418 6.04018 2.03643 6.47768C2.37568 7.25018 3.04018 8.31268 4.00268 9.20768C5.03018 10.1602 6.37018 10.9002 8.00018 10.9002C8.76768 10.9002 9.47018 10.7352 10.1077 10.4627ZM4.80018 6.47768C4.80018 6.42768 4.80268 6.35768 4.80768 6.28768L6.21018 7.39268C6.47268 7.92018 6.96268 8.31518 7.55018 8.42768L8.95518 9.55518C8.65268 9.62768 8.33268 9.70018 7.97768 9.70018C6.23268 9.70018 4.77768 8.26768 4.77768 6.47768H4.80018Z"
            fill={greyColorObject.initial}
        />
    </svg>
);
