import { useMutation, useQueryClient } from 'react-query';

import { BusinessMoreHoursPostType } from 'app/api/types/more_hours';
import api from 'app/api/v2/api_calls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { BUSINESS } from 'app/common/data/queryKeysConstants';

export default function useMoreHoursUpdate(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const businessId = useGetBusinessId();
    const queryClient = useQueryClient();
    return useMutation(
        (payload: BusinessMoreHoursPostType) =>
            api.business.updateBusinessMoreHours(businessId, payload),
        {
            onSuccess: () => {
                queryClient
                    .invalidateQueries(invalidatesAny(BUSINESS))
                    .then(onSuccess);
            },
            onError,
        },
    );
}
