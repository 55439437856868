import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
    IllustrationVariousElement,
    IllustrationVariousEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Link, Stack, Text } from '@partoohub/ui';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { StyledStack } from 'app/pages/visibility/subComponents/MetaRelogin/Dialogs/Dialogs.styled';
import {
    FooterContainer,
    StyledText,
} from 'app/pages/visibility/subComponents/MetaRelogin/MetaRelogin.styled';

import {
    MetaReloginContextProps,
    useMetaRelogin,
} from 'app/pages/visibility/subComponents/MetaRelogin/MetaReloginContext';
import { useSendTrackingData } from 'app/pages/visibility/subComponents/MetaRelogin/utils';

interface ErrorContent {
    title: string;
    text: JSX.Element | string;
    illustration: IllustrationOldElement | IllustrationVariousElement;
}

interface ErrorDialogProps {
    type: 'error_permission' | 'error_wrong_account' | 'error_connection_failed';
}

const illustrationDefaultElement: IllustrationOldElement = {
    type: IllustrationCategoryEnum.Old,
    name: IllustrationOldEnum.Error,
};

const illustrationPermissionElement: IllustrationVariousElement = {
    type: IllustrationCategoryEnum.Various,
    name: IllustrationVariousEnum.FacebookListingError,
};

const illustrationWrongAccountElement: IllustrationVariousElement = {
    type: IllustrationCategoryEnum.Various,
    name: IllustrationVariousEnum.WrongAccount,
};

const NEED_HELP_LINK =
    'https://help.partoo.co/articles/9691852-how-do-you-restore-the-connection-between-your-facebook-pages-and-partoo';

const getErrorContent = (type: ErrorDialogProps['type'], t: TFunction): ErrorContent => {
    switch (type) {
        case 'error_permission':
            return {
                title: t('meta_relogin_modal_error_wrong_permission_title'),
                text: (
                    <Text variant="bodyMRegular" as="span" color="secondary">
                        {t('meta_relogin_modal_error_wrong_permission_text_1')}
                        <br />
                        <Text variant="bodyMBold" as="span" color="secondary">
                            {t('meta_relogin_modal_description_text_3')}
                        </Text>
                        <br />
                        {t('meta_relogin_modal_error_wrong_permission_text_2')}
                        &nbsp;
                        <Link href={NEED_HELP_LINK} variant="primary" textVariant="bodyMBold">
                            {t('meta_relogin_need_help')}
                        </Link>
                    </Text>
                ),
                illustration: illustrationPermissionElement,
            };
        case 'error_wrong_account':
            return {
                title: t('meta_relogin_modal_error_wrong_account_title'),
                text: t('meta_relogin_modal_error_wrong_account_text'),
                illustration: illustrationWrongAccountElement,
            };
        default:
            return {
                title: t('meta_relogin_modal_error_connection_failed_title'),
                text: t('meta_relogin_modal_error_connection_failed_text'),
                illustration: illustrationDefaultElement,
            };
    }
};

export const ErrorDialog: React.FC<ErrorDialogProps> = ({ type }) => {
    const { t } = useTranslation();
    const { data, missingScopes, setShowModal, startOAuthFlow, setOAuthAccountId } =
        useMetaRelogin() as MetaReloginContextProps;
    const sendTrackingData = useSendTrackingData();

    const handleMetaReloginRetry = () => {
        sendTrackingData('meta_relogin_retry');
        setOAuthAccountId('');
        startOAuthFlow(missingScopes, ['facebook_lost']);
        setShowModal(false);
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const content = getErrorContent(type, t);

    return (
        <Dialog
            dataTrackId="meta_relogin_modal_error"
            title={content.title}
            subTitle={content.text}
            header={
                <Stack style={{ paddingTop: '24px' }}>
                    <Illustration
                        dataTrackId="meta_relogin_modal_error"
                        illustration={content.illustration}
                        height="140"
                        width="320"
                    />
                </Stack>
            }
            onClose={handleCancel}
        >
            {type === 'error_wrong_account' && (
                <StyledStack gap="8px" status="error">
                    {t('meta_relogin_modal_error_wrong_account_description')}
                    <StyledText variant="bodyMSemibold" color="blackberry">
                        {data?.name && `${data.name} — `}
                        {data?.email}
                    </StyledText>
                </StyledStack>
            )}
            <FooterContainer>
                <Button
                    key="meta_relogin_cancel_modal_button"
                    dataTrackId="meta_relogin_dont_show_again_modal_button"
                    variant="secondary"
                    size="large"
                    shape="cube"
                    appearance="outlined"
                    onClick={handleCancel}
                >
                    <Text variant="bodyLBold" color="default">
                        {t('cancel')}
                    </Text>
                </Button>
                <Button
                    key="meta_relogin_retry_modal_button"
                    dataTrackId="meta_relogin_reconnect_now_modal_button"
                    variant="danger"
                    size="large"
                    shape="cube"
                    onClick={handleMetaReloginRetry}
                >
                    <Text variant="bodyLBold" color="theme">
                        {t('meta_relogin_retry_modal_button')}
                    </Text>
                </Button>
            </FooterContainer>
        </Dialog>
    );
};
