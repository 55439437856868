import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { meSelector } from 'app/states/reducers';

import {
    GenericTemplate,
    NoTemplateScreenContainer,
    NoTemplateScreenTitle,
    OverrideText,
    TitleOutline,
} from './NoTemplateScreen.styled';
import { BdayIcon, HandIcon, MessageIcon } from './NoTemplateScreenIcons';

type Props = {
    onButtonClick: () => void;
};

const NoTemplateScreen = ({ onButtonClick }: Props) => {
    const { t } = useTranslation();
    const meState = useSelector(meSelector);

    return (
        <NoTemplateScreenContainer>
            <NoTemplateScreenTitle variant="bodyMBold" as="span">
                {t('messaging_reply_template_no_template_screen_title')}
            </NoTemplateScreenTitle>
            <GenericTemplate>
                <BdayIcon />
                <TitleOutline />
            </GenericTemplate>
            <GenericTemplate>
                <HandIcon />
                <TitleOutline />
            </GenericTemplate>
            <GenericTemplate dashed style={{ marginBottom: 16 }}>
                <MessageIcon />
                <TitleOutline />
            </GenericTemplate>
            <div>
                {meState.data?.role === 'ORG_ADMIN' ? (
                    <StrikeThroughButton
                        color="primary"
                        text={t('messaging_reply_template_no_template_screen_button')}
                        onClick={onButtonClick}
                    />
                ) : (
                    <OverrideText color={'secondary'} variant="bodyMBold" as="span">
                        {t('messaging_reply_template_no_template_screen_contact_admin')}
                    </OverrideText>
                )}
            </div>
        </NoTemplateScreenContainer>
    );
};

export default NoTemplateScreen;
