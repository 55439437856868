import { FC, useEffect, useState } from 'react';

import { Filled, ProgressContainer } from './FakeProgress.styled';

interface Props {
    duration: number;
    target: number;
}

export const FakeProgress: FC<Props> = ({ duration, target }) => {
    const [value, setValue] = useState<number>(0);

    useEffect(() => {
        const id = setTimeout(() => {
            setValue(target);
        }, 500); // 0.5 s

        return () => clearTimeout(id);
    }, []);

    return (
        <ProgressContainer>
            <Filled variant={'primary'} value={value} duration={duration}></Filled>
        </ProgressContainer>
    );
};
