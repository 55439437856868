import usePlatformSelection from './usePlatformSelection';

export type ImageUploadError = Set<
    | 'acceptType'
    | 'maxFileSize'
    | 'minFileSize'
    | 'minResolution'
    | 'maxResolution'
    | 'unexpected'
    | 'too_big'
    | 'too_small'
    | 'wrong_format'
>;

export const MIN_FILE_SIZE = 10_240; // 10.24 KB
export const MAX_FILE_SIZE = 5_000_000; // 5 MB
export const MAX_FILE_SIZE_FB = 10_000_000; // 10 MB

const useImageUploadErrors = () => {
    const { hasOnlyFacebook, containsGoogle } = usePlatformSelection();

    const maxAcceptedFileSize = hasOnlyFacebook ? MAX_FILE_SIZE_FB : MAX_FILE_SIZE;
    const minFileResolution = hasOnlyFacebook
        ? { width: 1, height: 1 } // no limit for facebook images
        : { width: 250, height: 250 };
    const maxFileResolution = containsGoogle
        ? { width: 10000, height: 10000 }
        : { width: Infinity, height: Infinity };

    const getErrors = (img: HTMLImageElement) => {
        const errors: ImageUploadError = new Set();
        const { width, height } = img;

        if (width < minFileResolution.width || height < minFileResolution.height) {
            errors.add('minResolution');
        }

        if (width > maxFileResolution.width || height > maxFileResolution.height) {
            errors.add('maxResolution');
        }

        return errors;
    };

    return {
        getErrors,
        maxAcceptedFileSize,
        minFileResolution,
        maxFileResolution,
    };
};

export default useImageUploadErrors;
