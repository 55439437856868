import {
    HIDE_PUSH_NOTIFICATIONS_OPT_IN_PROMPT,
    PushNotificationsOptInPromptActionType,
    SHOW_PUSH_NOTIFICATIONS_OPT_IN_PROMPT,
} from '../actions/optInPromptActions';

export type OptInPromptStateType = {
    isVisible: boolean;
};

export const initialState: OptInPromptStateType = {
    isVisible: false,
};

const optInPromptReducer = (
    state: OptInPromptStateType = initialState,
    action: PushNotificationsOptInPromptActionType,
) => {
    switch (action.type) {
        case SHOW_PUSH_NOTIFICATIONS_OPT_IN_PROMPT:
            return {
                ...state,
                isVisible: true,
            };
        case HIDE_PUSH_NOTIFICATIONS_OPT_IN_PROMPT:
            return {
                ...state,
                isVisible: false,
            };
        default:
            return state;
    }
};

export default optInPromptReducer;
