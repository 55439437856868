import React, { useContext } from 'react';

import {
    Button,
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    LeftElementType,
    TextInput,
} from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';
import MessageReplyTemplateTable from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/components/MessageReplyTemplateTable';

import { TemplateFormContext } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/components/MessagesReplyTemplates.context';
import { useMessagesReplyTemplateFilter } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/hooks/useMessagesReplyTemplateFilter';

export const ReplyTemplates = () => {
    const { t } = useTranslation();
    const { setCreatingMode } = useContext(TemplateFormContext);

    const onCreateTemplateClick = () => {
        setCreatingMode(true);
    };

    const { query, onChange, onReset } = useMessagesReplyTemplateFilter();

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('messaging_reply_template_settings_header')}
                    description={t('messaging_reply_template_settings_description')}
                    actionsButton={[
                        <Button
                            dataTrackId="messaging-template-header-create"
                            icon={[FontAwesomeIconsPartooUsed.faPlus]}
                            onClick={onCreateTemplateClick}
                            type="button"
                            shape="cube"
                            variant="primary"
                            key="messaging-template-header-create"
                        >
                            {t('create_reply_template')}
                        </Button>,
                    ]}
                />
            }
        >
            <MenuListContentContainer>
                <TextInput
                    dataTrackId="messaging_reply_template__search_field"
                    value={query}
                    label={t('auto_reply_template_select_search_placeholder')}
                    onChange={onChange}
                    isReset
                    onReset={onReset}
                    leftElement={['fa-search', IconPrefix.REGULAR]}
                    leftElementType={LeftElementType.Icon}
                />
                <MessageReplyTemplateTable />
            </MenuListContentContainer>
        </MenuListContentTemplate>
    );
};
