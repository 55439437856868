import { HEX_COLORS, greyColorObject } from '@partoohub/branding';
import { Avatar } from '@partoohub/ui';

export const UserNotFoundAvatar = () => {
    return (
        <Avatar
            text={'-'}
            size={20}
            textSize={10}
            textColor={HEX_COLORS.white}
            backgroundColor={greyColorObject.initial}
        />
    );
};
