import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

export type ReviewAnalyticsGraphType =
    | 'average_rating'
    | 'review_count'
    | 'rating_distribution'
    | 'reply_time'
    | 'reply_means'
    | 'tags';

export const useGraphSelection = () => {
    return useStateQueryParams<ReviewAnalyticsGraphType>('graph');
};
