import { useEffect, useState } from 'react';

import { ReviewObject } from 'app/api/types/review';
import { ENGLISH_LANGUAGE_CODE, localeToLanguage } from 'app/common/services/languages';
import useReduxBusiness from 'app/reviewManagement/common/hooks/useReduxBusiness';

import { useAvailableLanguages } from './useAvailableLanguages';
import { useReviewRating } from './useReviewRating';

export const useReplyTemplateButtonFilters = (review: ReviewObject) => {
    const literalRating = useReviewRating(review);
    const { availableLanguages } = useAvailableLanguages(literalRating);
    const business = useReduxBusiness(review.businessId);
    const defaultBusinessLanguage = localeToLanguage(business?.locale);

    const defaultTemplateLanguage = availableLanguages
        ? defaultBusinessLanguage && availableLanguages.includes(defaultBusinessLanguage)
            ? defaultBusinessLanguage
            : ENGLISH_LANGUAGE_CODE
        : null;

    const [currentLanguage, setCurrentLanguage] = useState(defaultTemplateLanguage);
    useEffect(() => {
        setCurrentLanguage(defaultTemplateLanguage);
    }, [availableLanguages]);

    return {
        language: [currentLanguage, setCurrentLanguage] as const,
        titleSearch: useState(''),
        literalRating,
    };
};
