import React, { useEffect, useRef, useState } from 'react';

import { IconButton, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import {
    AutomaticMessageNameInput,
    HiddenAutomaticMessageName,
    NameSkeleton,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/AutomaticMessagesName.styled';
import { useConversationStarter } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarter';
import useUpdateConversationStarter from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useUpdateConversationStarter';

export const AutomaticMessagesName = () => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const hiddenTextRef = useRef<HTMLSpanElement>(null);
    const { data, isLoading } = useConversationStarter();
    const [name, setName] = useState(data?.name ?? '');
    const [isEditing, setIsEditing] = useState(false);
    const updateConvStarter = useUpdateConversationStarter(data?.id ?? '');
    const iconRef = useRef<HTMLElement>(null);

    const onSubmit = () => {
        if (name.length >= 1) {
            // Only change the name if it contains a value.
            updateConvStarter.mutate({ name }, { onSuccess: () => setIsEditing(false) });
        }
    };

    useEffect(() => {
        setName(data?.name ?? '');
    }, [data]);

    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus();
        }
        if (inputRef.current) {
            inputRef.current.style.width = `${hiddenTextRef.current?.clientWidth}px`;
        }
    }, [isEditing, name]);

    const onClick = () => {
        if (isEditing) {
            onSubmit();
        } else {
            setIsEditing(true);
        }
    };
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!iconRef.current?.contains(event.relatedTarget as Node)) {
            onSubmit();
        }
    };

    if (isLoading && !data) {
        return <NameSkeleton variant="line" />;
    }

    // Name is save at either: blur, enter key or clicking the check icon.

    return (
        <Stack direction="row" alignItems="center">
            {isEditing ? (
                <>
                    <AutomaticMessageNameInput
                        value={name}
                        onChange={event => setName(event.target.value)}
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        ref={inputRef}
                        placeholder={t('conversation_starters_settings_header')}
                    />
                    <HiddenAutomaticMessageName ref={hiddenTextRef}>
                        <Text as="span" variant="heading4">
                            {name || data?.name || t('conversation_starters_settings_header')}
                        </Text>
                    </HiddenAutomaticMessageName>
                </>
            ) : (
                <div onClick={onClick} onKeyDown={onClick}>
                    <Text variant="heading4">
                        {name || data?.name || t('conversation_starters_settings_header')}
                    </Text>
                </div>
            )}
            <IconButton
                ref={iconRef}
                icon={[isEditing ? 'fa-check' : 'fa-pen-clip']}
                dataTrackId="chatbot-rename-button"
                variant={isEditing ? 'primary' : 'default'}
                size="xsmall"
                appearance="outlined"
                onClick={onClick}
            />
        </Stack>
    );
};
