// ACTION TYPES
export const GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST';
export const GET_SUBSCRIPTIONS_REQUEST_SUCCESS =
    'GET_SUBSCRIPTIONS_REQUEST_SUCCESS';
const GET_SUBSCRIPTIONS_REQUEST_FAILURE = 'GET_SUBSCRIPTIONS_REQUEST_FAILURE';
// ACTION FLOW TYPES
export type GetSubscriptionsAction = {
    type: 'GET_SUBSCRIPTIONS_REQUEST';
};
type GetSubscriptionsSuccessAction = {
    type: 'GET_SUBSCRIPTIONS_REQUEST_SUCCESS';
    hasBusiness: boolean;
    hasPm: boolean;
    hasRm: boolean;
    hasRb: boolean;
    hasBm: boolean;
};
type GetSubscriptionsFailureAction = {
    type: 'GET_SUBSCRIPTIONS_REQUEST_FAILURE';
    error: Record<string, any>;
};
export type SubscriptionsState = {
    isFetching: boolean;
    initialFetchDone: boolean;
    hasBusiness: boolean;
    hasPm: boolean;
    hasRm: boolean;
    hasRb: boolean;
    hasBm: boolean;
    error: Record<string, any>;
};
type SubscriptionsAction =
    | GetSubscriptionsAction
    | GetSubscriptionsSuccessAction
    | GetSubscriptionsFailureAction;
// ACTION CREATORS
export const getSubscriptions = (): GetSubscriptionsAction => ({
    type: GET_SUBSCRIPTIONS_REQUEST,
});
export const getSubscriptionsSuccess = (
    hasBusiness: boolean,
    hasPm: boolean,
    hasRm: boolean,
    hasRb: boolean,
    hasBm: boolean,
): GetSubscriptionsSuccessAction => ({
    type: GET_SUBSCRIPTIONS_REQUEST_SUCCESS,
    hasBusiness,
    hasPm,
    hasRm,
    hasRb,
    hasBm,
});
export const getSubscriptionsFailure = (
    error: Record<string, any>,
): GetSubscriptionsFailureAction => ({
    type: GET_SUBSCRIPTIONS_REQUEST_FAILURE,
    error,
});
// INITIAL STATES
export const initialState = {
    isFetching: false,
    initialFetchDone: false,
    hasBusiness: false,
    hasPm: false,
    hasRm: false,
    hasRb: false,
    hasBm: false,
    error: {},
};

// REDUCER
const subscriptionsReducer = (
    state: SubscriptionsState = initialState,
    action: SubscriptionsAction,
): SubscriptionsState => {
    switch (action.type) {
        case GET_SUBSCRIPTIONS_REQUEST:
            return {
                ...state,
                isFetching: true,
                initialFetchDone: false,
                error: {},
            };

        case GET_SUBSCRIPTIONS_REQUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hasBusiness: action.hasBusiness,
                hasPm: action.hasPm,
                hasRm: action.hasRm,
                hasRb: action.hasRb,
                hasBm: action.hasBm,
                initialFetchDone: true,
            };

        case GET_SUBSCRIPTIONS_REQUEST_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error,
                initialFetchDone: true,
            };

        default:
            return state;
    }
};

export const hasBusinessSelector = (state: SubscriptionsState): boolean =>
    state.hasBusiness;
export const initialFetchDoneSelector = (state: SubscriptionsState): boolean =>
    state.initialFetchDone;
export const isFetchingSelector = (state: SubscriptionsState): boolean =>
    state.isFetching; // SELECTORS

export default subscriptionsReducer;
