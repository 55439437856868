import styled from '@emotion/styled';

export const ResponsiveModalContainer = styled.div`
    max-width: 330px;
`;

export const ResponsiveHeader = styled.div`
    height: 169px;
    width: 330px;
`;

export const ResponsiveBody = styled.div`
    padding: 14px 26px;
`;

export const ResponsiveFooter = styled.div`
    padding: 8px;
    button {
        width: 100%;
        border-radius: 8px;
        transition: all 0.2s;
        background-color: ${({ theme }) => theme.colors.default.initial}!important;
        &:hover,
        &:active,
        &:focus {
            background-color: #0c1628 !important;
        }
    }
`;

export const EditSettingsContainer = styled.ul`
    margin: 0;
    padding: 0;
`;

export const EditSettingsItem = styled.li`
    display: flex;
    align-items: flex-start;
    > i,
    svg {
        display: flex;
        justify-content: top;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        overflow: hidden;
        margin: 0 4px;
        font-size: 12px;
        color: ${({ theme }) => theme.colors.primary.initial};
    }
`;
