import { GROUP_MANAGER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMe';
import { GroupData } from 'app/common/reducers/groups';
import useGroupIds from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupIds';
import useGroups from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroups';

export default function useGroupData(): GroupData | undefined {
    const { groups } = useGroups();
    const [currentGroupId, currentSubgroupId] = useGroupIds();
    const { data: me } = useMe();

    if (
        ['all', 'ungrouped', '', undefined, null].includes(currentGroupId) &&
        me?.role !== GROUP_MANAGER
    ) {
        return undefined;
    }

    const groupId = !!(me?.role === GROUP_MANAGER && me?.groupId)
        ? me.groupId.toString()
        : currentGroupId;

    if (currentSubgroupId && currentSubgroupId !== groupId) {
        return groups
            .flatMap(group => group.subgroups)
            .find(sub => sub?.groupId.toString() === currentSubgroupId);
    }

    return groups.find(group => group.groupId.toString() === groupId);
}
