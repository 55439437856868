import { call, put, select, takeLatest } from 'redux-saga/effects';

import { SearchBusinessesReceivedData } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import { UNGROUPED_ID } from 'app/common/reducers/groups';
import {
    BusinessesState,
    SEARCH_BUSINESSES,
    SearchBusinessesAction,
    importBusinessesSuccess,
    searchBusinessesFailure,
    searchBusinessesSuccess,
    setTotalBusinessCount,
} from 'app/common/reducers/newBusinesses';
import byIdFormatter from 'app/common/services/byIdFormatter';

import { Saga } from 'app/common/types';
import { businessesSelector } from 'app/states/reducers';

import formatBusinessStateToSearchBusinessParams from './formatters/formatBusinessStateToSearchBusinessParams';

function* searchBusinesses({ query, page, fromImport, additionalParams }: SearchBusinessesAction) {
    try {
        const businessState = yield select(businessesSelector) as any as BusinessesState;

        const { businesses, max_page, count } = (yield call(
            api.business.searchBusinesses,
            formatBusinessStateToSearchBusinessParams(businessState, query, page, additionalParams),
        ) as any) as SearchBusinessesReceivedData;
        const { byId, ids } = byIdFormatter(businesses, obj => ({
            ...obj,
            groupId: obj.groupId === null ? UNGROUPED_ID : obj.groupId,
        }));

        if (fromImport) {
            yield put(importBusinessesSuccess(byId, ids, max_page));
        } else {
            yield put(searchBusinessesSuccess(byId, ids, max_page));
        }
        if (!query) {
            yield put(setTotalBusinessCount(count));
        }
    } catch (error) {
        yield put(searchBusinessesFailure(error));
    }
}

/** @deprecated
 * Please use hooks and kill this Saga with fire
 */
function* searchBusinessesSaga(): Saga {
    yield takeLatest(SEARCH_BUSINESSES, searchBusinesses);
}

export default searchBusinessesSaga;
