import { ReviewObjectType } from 'app/api/types/review';

import useMe from 'app/common/hooks/queries/useMeUncamel';

import { CEOrgFeatures, useHasCEOrgFeatures } from './settings/useHasCEOrgFeatures';
import { useCanShowClientReviewCards } from './useCanShowClientReviewCards';

export const useCanDownloadClientReviews = (): {
    canDownloadClientReviews: Record<ReviewObjectType, boolean | undefined>;
    isLoading: boolean;
} => {
    const { data: me, isLoading: meIsLoading } = useMe();
    const { orgFeatures, isLoading: orgFeaturesLoading } = useHasCEOrgFeatures();
    const { canShowClientReviewCards, isLoading: canShowReviewCardsLoading } =
        useCanShowClientReviewCards();

    const isProvider = me?.role === 'PROVIDER';

    return {
        canDownloadClientReviews: {
            [ReviewObjectType.REVIEW]:
                canShowClientReviewCards[ReviewObjectType.REVIEW] && !isProvider,
            [ReviewObjectType.FEEDBACK_RESULT]:
                orgFeatures[CEOrgFeatures.DownloadFBM] &&
                canShowClientReviewCards[ReviewObjectType.FEEDBACK_RESULT] &&
                !isProvider,
        },
        isLoading: orgFeaturesLoading || canShowReviewCardsLoading || meIsLoading,
    };
};
