import { FontAwesomeIconsPartooUsed } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { SettingSummaryItemValues } from 'app/pages/settingsV2/subComponents/SettingsSummaryItem/SettingsSummaryItem';
import { MAIN_PATH_TOKEN } from 'app/routing/routeIds';

export const useSettingMainSummary = (): SettingSummaryItemValues => {
    const { t } = useTranslation();

    return {
        settingData: {
            id: '0',
            icon: FontAwesomeIconsPartooUsed.faUser,
            title: t('settings_summary_general_title'),
            description: t('settings_summary_general_description'),
            url: MAIN_PATH_TOKEN,
            display: true, // This section does not have any access restrictions. And at least one option inside `useSettingMainMenu` is set to true or undefined.
        },
        isLoading: false,
    };
};
