import styled from '@emotion/styled';

import { GetMaxWidthContentPage } from 'app/global.styled';

export const StyledReviewBooster = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    ${GetMaxWidthContentPage}
`;
