const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR';
type CollapseSidebarAction = {
    type: 'COLLAPSE_SIDEBAR';
};
export const collapseSidebar = (): CollapseSidebarAction => ({
    type: COLLAPSE_SIDEBAR,
});

const RESET_SIDEBAR = 'RESET_SIDEBAR';
type ResetSidebarAction = {
    type: 'RESET_SIDEBAR';
};
export const resetSidebar = (): ResetSidebarAction => ({
    type: RESET_SIDEBAR,
});

const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
type HideSidebarAction = {
    type: 'HIDE_SIDEBAR';
};
export const hideSidebar = (): HideSidebarAction => ({
    type: HIDE_SIDEBAR,
});

export type GlobalAction = CollapseSidebarAction | ResetSidebarAction | HideSidebarAction;
export type GlobalState = {
    collapsedSidebar: boolean;
};

const initialState = {
    collapsedSidebar: false,
};

const globalReducer = (state: GlobalState = initialState, action: GlobalAction): GlobalState => {
    if (action.type === COLLAPSE_SIDEBAR)
        return { ...state, collapsedSidebar: !state.collapsedSidebar };
    if (action.type === RESET_SIDEBAR) return { ...state, ...initialState };
    if (action.type === HIDE_SIDEBAR) return { ...state, collapsedSidebar: true };
    return state;
};

export const isSidebarCollapsed = ({ collapsedSidebar }: GlobalState): boolean => collapsedSidebar;
export const globalStateSelector = (state: any): GlobalState => state.global;
export default globalReducer;
