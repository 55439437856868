import { ButtonVariants } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { EmailInput } from 'app/common/components/form/EmailInput';
import {
    LoginFormButton,
    LoginFormContainer,
    LoginFormMessage,
} from 'app/connection/components/LoginForm/LoginForms.styled';

import {
    LostPasswordState,
    emailErrorSelector,
    emailSelector,
    hasErrors,
    isSubmittingSelector,
    lostPasswordRequest,
    updateLostPasswordEmail,
} from 'app/states/connection/reducers/lostPassword';
import { AppState } from 'app/states/reducers';

import ReturnToLoginFormLink from './ReturnToLoginFormLink.container';

const getStatusObj = (
    lostPassword: LostPasswordState,
): {
    variant: ButtonVariants;
    label: string;
} => {
    if (isSubmittingSelector(lostPassword)) {
        return {
            variant: 'secondary',
            label: 'loading',
        };
    }

    if (emailSelector(lostPassword) === '') {
        return {
            variant: 'secondary',
            label: 'send_reset_password_link',
        };
    }

    if (hasErrors(lostPassword)) {
        return {
            variant: 'danger',
            label: 'connection_error',
        };
    }

    return {
        variant: 'primary',
        label: 'send_reset_password_link',
    };
};

const LostPasswordForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const lostPassword = useSelector((state: AppState) => state.lostPassword);

    const value = emailSelector(lostPassword);
    const error = emailErrorSelector(lostPassword);

    const handleChange = (value: string) => dispatch(updateLostPasswordEmail(value));

    const { variant, label } = getStatusObj(lostPassword);

    return (
        <LoginFormContainer>
            <LoginFormMessage>
                <i className="fa-solid fa-info-circle" />
                {t('lost_password__explanation')}
            </LoginFormMessage>
            <EmailInput
                value={value}
                onChange={handleChange}
                label={t('email_extended')}
                error={!!error}
                notice={error && typeof error === 'string' ? error : undefined}
            />
            <LoginFormButton
                variant={variant}
                shape="cube"
                size="large"
                full
                onClick={() => dispatch(lostPasswordRequest())}
                dataTrackId="sso_sign_in_button"
                type="submit"
                disabled={variant === 'secondary'}
            >
                {t(label)}
            </LoginFormButton>
            <ReturnToLoginFormLink translationKey="return_to_login" />
        </LoginFormContainer>
    );
};

export default LostPasswordForm;
