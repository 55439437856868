import { useMemo } from 'react';

import { useInfiniteQuery } from 'react-query';

import { PostDiffuserName } from 'app/api/types/post';
import { GetScopesResponse } from 'app/api/types/scopes';
import api from 'app/api/v2/api_calls/camel';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { POSTS } from 'app/common/data/queryKeysConstants';
import { useSearchParam } from 'app/common/hooks/useSearchParam';
import { POSTS_SEARCH_PARAMS } from 'app/presence/googlePosts/components/googlePostList';
import { useDateFromFilter } from 'app/presence/googlePosts/hooks/filters/useDateFromFilter';
import { useDateToFilter } from 'app/presence/googlePosts/hooks/filters/useDateToFilter';
import usePlatformsFilter from 'app/presence/googlePosts/hooks/filters/usePlatformsFilter';
import useStatusFilter from 'app/presence/googlePosts/hooks/filters/useStatusFilter';
import useTopicsFilter from 'app/presence/googlePosts/hooks/filters/useTopicsFilter';

const usePostApiFilters = () => {
    const [dateFrom, , dateFromApiFilter] = useDateFromFilter();

    const [dateTo, , dateToApiFilter] = useDateToFilter();

    const [topics, , topicsApiFilter] = useTopicsFilter();

    const [status, , statusApiFilter] = useStatusFilter();

    const [platforms, , platformsApiFilter] = usePlatformsFilter();
    const search = useSearchParam('search');
    const businessFilters = useBusinessModalFilters();

    const filters = useMemo(() => {
        const searchFilter = search
            ? {
                  title: search,
                  summary: search,
              }
            : {};
        return {
            ...platformsApiFilter,
            ...topicsApiFilter,
            ...dateFromApiFilter,
            ...dateToApiFilter,
            ...statusApiFilter,
            ...searchFilter,
            ...businessFilters,
            ...POSTS_SEARCH_PARAMS,
        };
    }, [dateFrom, dateTo, topics, status, platforms, search, businessFilters]);

    return filters;
};

export default function usePosts(enabled: boolean) {
    const filters = usePostApiFilters();

    return useInfiniteQuery(
        [POSTS, filters],
        async ({ pageParam = 1 }) => {
            const postsResponse = await api.post.getPostList({
                ...filters,
                page: pageParam,
            });
            const instagramPosts = postsResponse.posts.filter(post => {
                return post.postStatus?.some(
                    pd => pd.name === PostDiffuserName.INSTAGRAM && pd.state === 'live',
                );
            });
            const businessesWithIgPosts = instagramPosts.reduce((businesses, post) => {
                businesses.add(post.businessId);
                return businesses;
            }, new Set());

            let scopes = {} as GetScopesResponse;
            if (businessesWithIgPosts.size > 0) {
                scopes = await api.scopes.getScopes({
                    publisher__in: 'instagram',
                    business__in: Array.from(businessesWithIgPosts).toString(),
                });
            }

            return { ...postsResponse, scopes };
        },
        {
            getNextPageParam: lastPage =>
                lastPage.maxPage > lastPage.page ? lastPage.page + 1 : undefined,
            enabled: enabled && filters !== undefined,
        },
    );
}
