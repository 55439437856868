import { keys, uniq } from 'lodash-es';

import {
    AccountPartner,
    AccountPartnerResourceName,
    V2FormattedAccountData,
    V2FormattedAccountDetails,
} from 'app/api/types/account';

import { INITIAL_DATA_LOAD_REQUEST, InitialDataLoadRequestAction } from './initialDataLoad';

// REQUEST STATUSES
const RUNNING = 'RUNNING';
const DONE = 'DONE';
const FAILED = 'FAILED';

// PARTNERS
export const GOOGLE_MY_BUSINESS = 'GOOGLE_MY_BUSINESS';
export const FACEBOOK = 'FACEBOOK';
export const FOURSQUARE = 'FOURSQUARE';

// ACTION TYPES
export const DISCONNECT_ACCOUNT = 'DISCONNECT_ACCOUNT';
const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
const GET_ACCOUNT_REQUEST_SUCCESS = 'GET_ACCOUNT_REQUEST_SUCCESS';
const GET_ACCOUNT_REQUEST_FAILURE = 'GET_ACCOUNT_REQUEST_FAILURE';
const LOAD_PARTNER_ACCOUNTS_REQUEST_SUCCESS = 'LOAD_PARTNER_ACCOUNTS_REQUEST_SUCCESS';
const LOAD_PARTNER_ACCOUNTS_REQUEST_FAILURE = 'LOAD_PARTNER_ACCOUNTS_REQUEST_FAILURE';
export const ACCOUNT_RESOURCES_DETAILS_REQUEST = 'ACCOUNT_RESOURCES_DETAILS_REQUEST';
const ACCOUNT_RESOURCES_DETAILS_REQUEST_SUCCESS = 'ACCOUNT_RESOURCES_DETAILS_REQUEST_SUCCESS';
const ACCOUNT_RESOURCES_DETAILS_REQUEST_FAILURE = 'ACCOUNT_RESOURCES_DETAILS_REQUEST_FAILURE';

// ACTION FLOW TYPES
type DisconnectAccountAction = {
    type: 'DISCONNECT_ACCOUNT';
    accountId: string;
};

type GetAccountsRequestAction = {
    type: 'LOAD_PARTNER_ACCOUNTS_REQUEST';
};

type GetAccountsRequestSuccessAction = {
    type: 'LOAD_PARTNER_ACCOUNTS_REQUEST_SUCCESS';
    accountsById: Record<string, V2FormattedAccountData>;
    accountIdsByPartner: Record<string, Array<string>>;
};

type GetAccountsRequestFailureAction = {
    type: 'LOAD_PARTNER_ACCOUNTS_REQUEST_FAILURE';
    error: Record<string, any>;
};

type GetAccountRequestAction = {
    type: 'GET_ACCOUNT_REQUEST';
};

type GetAccountRequestSuccessAction = {
    type: 'GET_ACCOUNT_REQUEST_SUCCESS';
    account: V2FormattedAccountData;
};

type GetAccountRequestFailureAction = {
    type: 'GET_ACCOUNT_REQUEST_FAILURE';
    error: Record<string, any>;
};

export type GetAccountResourcesDetailsAction = {
    type: 'ACCOUNT_RESOURCES_DETAILS_REQUEST';
    partnerResourceName: AccountPartnerResourceName;
    accountId: string;
};

type GetAccountResourcesDetailsSuccessAction = {
    type: 'ACCOUNT_RESOURCES_DETAILS_REQUEST_SUCCESS';
    accountId: string;
    resourcesDetails: V2FormattedAccountDetails;
};

type GetAccountResourcesDetailsFailureAction = {
    type: 'ACCOUNT_RESOURCES_DETAILS_REQUEST_FAILURE';
    accountId: string;
    error: Record<string, any>;
};

export type AccountsState = {
    byId: Record<string, V2FormattedAccountData>;
    idsByPartner: {
        GOOGLE_MY_BUSINESS: Array<string>;
        FACEBOOK: Array<string>;
    };
    resourcesDetailsById: Record<string, V2FormattedAccountDetails>;
    ids: Array<string>;
    isFetching: boolean;
    currentPartner: AccountPartner;
    errors: Record<string, any>;
    requests: {
        errors: Record<string, Record<string, any>>;
        statuses: Record<string, string>;
    };
};

export type AccountAction =
    | DisconnectAccountAction
    | InitialDataLoadRequestAction
    | GetAccountsRequestAction
    | GetAccountsRequestSuccessAction
    | GetAccountsRequestFailureAction
    | GetAccountRequestAction
    | GetAccountRequestSuccessAction
    | GetAccountRequestFailureAction
    | GetAccountResourcesDetailsAction
    | GetAccountResourcesDetailsSuccessAction
    | GetAccountResourcesDetailsFailureAction;

// ACTION CREATORS
export const getAccountRequest = (): GetAccountRequestAction => ({
    type: GET_ACCOUNT_REQUEST,
});

export const getAccountsRequestSuccess = (
    accountsById: Record<string, V2FormattedAccountData>,
    accountIdsByPartner: Record<string, Array<string>>,
): GetAccountsRequestSuccessAction => ({
    type: LOAD_PARTNER_ACCOUNTS_REQUEST_SUCCESS,
    accountsById,
    accountIdsByPartner,
});

export const getAccountsRequestFailure = (
    error: Record<string, any>,
): GetAccountsRequestFailureAction => ({
    type: LOAD_PARTNER_ACCOUNTS_REQUEST_FAILURE,
    error,
});

export const getAccountRequestSuccess = (
    account: V2FormattedAccountData,
): GetAccountRequestSuccessAction => ({
    type: GET_ACCOUNT_REQUEST_SUCCESS,
    account,
});

export const getAccountRequestFailure = (
    error: Record<string, any>,
): GetAccountRequestFailureAction => ({
    type: GET_ACCOUNT_REQUEST_FAILURE,
    error,
});
export const getAccountResourcesDetails = (
    accountId: string,
    partnerResourceName: AccountPartnerResourceName,
): GetAccountResourcesDetailsAction => ({
    type: ACCOUNT_RESOURCES_DETAILS_REQUEST,
    accountId,
    partnerResourceName,
});

export const getAccountResourcesDetailsSuccess = (
    accountId: string,
    resourcesDetails: V2FormattedAccountDetails,
): GetAccountResourcesDetailsSuccessAction => ({
    type: ACCOUNT_RESOURCES_DETAILS_REQUEST_SUCCESS,
    accountId,
    resourcesDetails,
});

export const getAccountResourcesDetailsFailure = (
    accountId: string,
    error: Record<string, any>,
): GetAccountResourcesDetailsFailureAction => ({
    type: ACCOUNT_RESOURCES_DETAILS_REQUEST_FAILURE,
    accountId,
    error,
});

export const initialState = {
    byId: {},
    ids: [],
    isFetching: false,
    errors: {},
    currentPartner: GOOGLE_MY_BUSINESS as AccountPartner,
    idsByPartner: {
        GOOGLE_MY_BUSINESS: [],
        FACEBOOK: [],
    },
    requests: {
        errors: {},
        statuses: {},
    },
    resourcesDetailsById: {},
};

// REDUCER
const accountsReducer = (
    state: AccountsState = initialState,
    action: AccountAction,
): AccountsState => {
    switch (action.type) {
        case INITIAL_DATA_LOAD_REQUEST:
            return { ...state, isFetching: true };

        case ACCOUNT_RESOURCES_DETAILS_REQUEST:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    errors: { ...state.requests, [action.accountId]: {} },
                    statuses: {
                        ...state.requests.statuses,
                        [action.accountId]: RUNNING,
                    },
                },
            };

        case ACCOUNT_RESOURCES_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                resourcesDetailsById: {
                    ...state.resourcesDetailsById,
                    [action.accountId]: action.resourcesDetails,
                },
                requests: {
                    ...state.requests,
                    statuses: {
                        ...state.requests.statuses,
                        [action.accountId]: DONE,
                    },
                },
            };

        case ACCOUNT_RESOURCES_DETAILS_REQUEST_FAILURE:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    statuses: {
                        ...state.requests.statuses,
                        [action.accountId]: FAILED,
                    },
                    errors: {
                        ...state.requests,
                        [action.accountId]: action.error,
                    },
                },
            };

        case DISCONNECT_ACCOUNT: {
            const ids = { ...state.byId };
            delete ids[action.accountId];
            return {
                ...state,
                ids: state.ids.filter(el => el !== action.accountId),
                byId: ids,
                idsByPartner: {
                    GOOGLE_MY_BUSINESS: state.idsByPartner.GOOGLE_MY_BUSINESS.filter(
                        el => el !== action.accountId,
                    ),
                    FACEBOOK: state.idsByPartner.FACEBOOK.filter(el => el !== action.accountId),
                },
            };
        }

        case GET_ACCOUNT_REQUEST:
            return { ...state, isFetching: true, errors: {} };

        case GET_ACCOUNT_REQUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                ids: uniq([...state.ids, action.account.id]),
                byId: { ...state.byId, [action.account.id]: action.account },
                idsByPartner: {
                    ...state.idsByPartner,
                    [action.account.partner]: uniq([
                        ...(state.idsByPartner[action.account.partner] || []),
                        action.account.id,
                    ]),
                },
            };

        case LOAD_PARTNER_ACCOUNTS_REQUEST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                ids: uniq([...state.ids, ...keys(action.accountsById)]),
                byId: { ...state.byId, ...action.accountsById },
                idsByPartner: {
                    ...state.idsByPartner,
                    ...action.accountIdsByPartner,
                },
            };

        case LOAD_PARTNER_ACCOUNTS_REQUEST_FAILURE:
        case GET_ACCOUNT_REQUEST_FAILURE:
            // @ts-ignore
            return { ...state, isFetching: false, error: { ...action.error } };

        default:
            return state;
    }
};

// SELECTORS
export const accountsByIdSelector = (
    state: AccountsState,
): Record<string, V2FormattedAccountData> => state.byId;

export const accountsCountSelector = (state: AccountsState): number => state.ids.length;

export const accountResourcesDetailsSelector = (
    state: AccountsState,
    accountId: string,
): V2FormattedAccountDetails | null =>
    state.resourcesDetailsById[accountId] ? state.resourcesDetailsById[accountId] : null;

export const requestStatusSelector = (state: AccountsState, accountId: string): string | null =>
    state.requests.statuses[accountId] ? state.requests.statuses[accountId] : null;

export default accountsReducer;
