import { omit } from 'lodash-es';

import { PublisherData } from 'app/api/types/publisher';

import {
    PUBLISHERS_REQUEST,
    PUBLISHERS_REQUEST_FAILURE,
    PUBLISHERS_REQUEST_SUCCESS,
    PublishersAction,
} from './actions';

export type PublisherDataByName = Record<string, PublisherData>;

export type PublishersState = {
    names: Array<string>;
    byName: PublisherDataByName;
};

export const initialState = {
    names: [],
    byName: {},
    isFetching: false,
};

const publishersReducer = (
    state: PublishersState = initialState,
    action: PublishersAction,
): PublishersState => {
    switch (action.type) {
        case PUBLISHERS_REQUEST:
            // @ts-ignore
            return { ...state, isFetching: true };

        case PUBLISHERS_REQUEST_SUCCESS:
            return {
                ...state,
                ...omit(action, 'type'),
                // @ts-ignore
                isFetching: initialState.isFetching,
            };

        case PUBLISHERS_REQUEST_FAILURE:
            // @ts-ignore
            return { ...state, isFetching: initialState.isFetching };

        default:
            return state;
    }
};

export default publishersReducer;
