import { call, put, takeEvery } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import {
    REQUEST_POSSIBLE_CITIES,
    requestPossibleCitiesFailure,
    requestPossibleCitiesSuccess,
} from 'app/common/reducers/newBusinesses';
import { Saga } from 'app/common/types';

function* getAllCities() {
    try {
        const cities = yield call(api.business.getAllCities);
        yield put(requestPossibleCitiesSuccess(cities.cities));
    } catch (error) {
        yield put(requestPossibleCitiesFailure(error));
    }
}

function* getAllCitiesSaga(): Saga {
    yield takeEvery(REQUEST_POSSIBLE_CITIES, getAllCities);
}

export default getAllCitiesSaga;
