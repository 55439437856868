import React from 'react';

import { useTranslation } from 'react-i18next';

import { SuccessMessage } from './DropZone.styled';
import StrikeTroughButton from '../../atoms/StrikeThroughButton';

type DropZoneSuccessProps = {
    files: File[];
    handleDropClear?: () => void;
};

const DropZoneSuccess = ({ files, handleDropClear }: DropZoneSuccessProps) => {
    const { t } = useTranslation();
    return (
        <>
            <SuccessMessage>
                {t('drop_zone_success_upload', {
                    count: files.length,
                })}
            </SuccessMessage>

            {handleDropClear && (
                <StrikeTroughButton
                    color={'secondary'}
                    text={t('drop_zone_clear_upload')}
                    onClick={e => {
                        e.stopPropagation();
                        handleDropClear();
                    }}
                    icon={<i className="fa-solid fa-trash-alt" />}
                />
            )}
        </>
    );
};

export default DropZoneSuccess;
