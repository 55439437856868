import React from 'react';

import { LeftElement, RightElement, ShadowElement } from './baseElements';

const DeleteImage = () => (
    <svg width="320px" height="140px" viewBox="0 0 320 140">
        <defs>
            <polygon
                id="path-1"
                points="1.28867925e-05 0.12663955 57.2122042 0.12663955 57.2122042 19.6275948 1.28867925e-05 19.6275948"
            />
            <polygon
                id="path-3"
                points="0.000412377362 0.12663955 57.2119722 0.12663955 57.2119722 19.6271887 0.000412377362 19.6271887"
            />
        </defs>
        <g
            id="Components-/-Illustrations-/-Delete"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <RightElement />
            <LeftElement />
            <ShadowElement />
            <g id="Icon" transform="translate(100.000000, 1.000000)">
                <path
                    d="M119,60 L120,60 C120,93.0852156 93.0851215,120 59.9997937,120 C26.9148135,120 0,93.0851506 0,60 C0,26.9151206 26.9149549,0 59.9997937,0 C93.0849802,0 120,26.9150557 120,60 L119,60 Z"
                    id="Circle"
                    stroke="#FF606C"
                    strokeWidth="2"
                    fillRule="nonzero"
                />
                <g id="Group-19" transform="translate(31.000000, 27.000000)">
                    <path
                        d="M14.4636826,63.9639754 L6.90808825,24.5441267 L52.0976423,24.5441267 L44.5416602,63.9639754 L14.4636826,63.9639754 Z M46.5393435,65.2658729 L54.5158566,23.6524284 C54.5772621,23.3316431 54.4915529,22.9995526 54.2818692,22.7478832 C54.0717977,22.4960854 53.7597283,22.3505308 53.4309825,22.3505308 L5.57487734,22.3505308 C5.24548518,22.3505308 4.93354511,22.4960854 4.7238614,22.7478832 C4.51430697,22.9995526 4.42808068,23.3316431 4.48948621,23.6524284 L12.46587,65.2658729 C12.5650238,65.7827008 13.0199418,66.1570574 13.5497099,66.1575712 L45.4555036,66.1575712 C45.9852717,66.1570574 46.4401897,65.7827008 46.5393435,65.2658729 L46.5393435,65.2658729 Z"
                        id="Fill-6"
                        fill="#FF606C"
                    />
                    <path
                        d="M14.6389929,26.7550527 C14.5083077,26.1905154 13.9477968,25.8327294 13.3762066,25.9482853 C12.8046164,26.0644658 12.4304389,26.6118884 12.5332999,27.1815476 L19.3305649,59.9403291 C19.4329223,60.4366572 19.8728201,60.7928192 20.3829707,60.7934438 C20.4553637,60.7934438 20.5272531,60.7860733 20.5981354,60.7709573 C21.1796717,60.6522783 21.5538493,60.0882406 21.4342434,59.5112108 L14.6389929,26.7550527 Z"
                        id="Fill-8"
                        fill="#FF606C"
                    />
                    <path
                        d="M39.2715046,50.9824541 C38.6221829,50.8575965 37.9870716,51.2482037 37.8532677,51.8552745 L35.7857092,61.2324749 C35.652046,61.8395457 36.0707635,62.4329479 36.7200852,62.5578055 C36.7992983,62.5737084 36.8796369,62.5819885 36.9605383,62.5814627 C37.5312098,62.5808056 38.022809,62.2061014 38.1366337,61.6839337 L40.2064435,52.3067333 C40.3389811,51.6996625 39.9208263,51.1074432 39.2715046,50.9824541"
                        id="Fill-10"
                        fill="#FF606C"
                    />
                    <path
                        d="M43.8027747,25.9496292 C43.1950918,25.8261364 42.6012348,26.2119539 42.4754846,26.8118689 L38.4606955,46.0771345 C38.3531166,46.6704199 38.7444565,47.2400466 39.3422637,47.3604196 C39.9405976,47.4811825 40.5268174,47.1084943 40.66297,46.5214486 L44.6777591,27.2604727 C44.8029826,26.6600377 44.4109843,26.073252 43.8027747,25.9496292"
                        id="Fill-12"
                        fill="#FF606C"
                    />
                    <path
                        d="M28.6086795,26.820637 C28.1149678,26.820637 27.7146582,27.3101275 27.7146582,27.9138282 L27.7146582,61.4882951 C27.7146582,62.0919958 28.1149678,62.5814863 28.6086795,62.5814863 C29.1023911,62.5814863 29.5027007,62.0919958 29.5027007,61.4882951 L29.5027007,27.9138282 C29.5027007,27.3101275 29.1023911,26.820637 28.6086795,26.820637"
                        id="Fill-14"
                        fill="#FF606C"
                    />
                    <path
                        d="M52.2609954,17.460485 L4.95138921,17.460485 C3.4326807,17.460485 2.2008322,16.2480192 2.2008322,14.7520736 C2.2008322,13.2561281 3.4326807,12.0436623 4.95138921,12.0436623 L52.2609954,12.0436623 C53.7802194,12.0436623 55.0115524,13.2561281 55.0115524,14.7520736 C55.0115524,16.2480192 53.7802194,17.460485 52.2609954,17.460485 M31.7967688,9.87695855 L25.4157446,9.87695855 C25.646676,8.59203663 26.3655013,6.62683953 28.6061279,6.62683953 C30.8319347,6.62683953 31.5570745,8.59203663 31.7967688,9.87695855 M28.6061279,2.29343209 C32.8577385,2.29812715 36.3028936,5.69062049 36.3077906,9.87695855 L34.0294056,9.87695855 C33.707107,7.57054527 32.327447,4.46013581 28.6061279,4.46013581 C24.8854531,4.46013581 23.5057931,7.57054527 23.1834944,9.87695855 L20.904594,9.87695855 C20.909491,5.69062049 24.3546461,2.29812715 28.6061279,2.29343209 M52.2609954,9.87695855 L38.5082104,9.87695855 C38.5082104,4.49185914 34.0750249,0.126601482 28.6061279,0.126601482 C23.1378752,0.126601482 18.7041742,4.49185914 18.7041742,9.87695855 L4.95138921,9.87695855 C2.2169407,9.87695855 0.000412377362,12.0595239 0.000412377362,14.7520736 C0.000412377362,17.4446233 2.2169407,19.6271887 4.95138921,19.6271887 L52.2609954,19.6271887 C54.9954439,19.6271887 57.2119722,17.4446233 57.2119722,14.7520736 C57.2119722,12.0595239 54.9954439,9.87695855 52.2609954,9.87695855"
                        id="Fill-16"
                        fill="#FF606C"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default DeleteImage;
