import { useEffect, useRef, useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { useTranslation } from 'react-i18next';

import { PhotosData } from 'app/api/types/business';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import PhotoPreviewIcon from 'app/businessEditV2/sections/PhotosSection/PhotosPreview/PhotoPreviewIcon';
import getAllPhotos from 'app/businessEditV2/utils/photos';

import { getMappedUrl, resizeImage } from 'app/utils/resizeImage';

import {
    AddPhotos,
    AddPhotosContent,
    AddPhotosSubtitle,
    AddPhotosTitle,
    MARGIN,
    PHOTO_WIDTH,
    Photo,
    PhotosPreviewContainer,
    PhotosPreviewContainerNoPhotos,
} from './PhotosPreview.styled';

const LINE_NUMBER = 2;

type Props = {
    sectionColor: ColorState;
};

const PhotosPreview = ({ sectionColor }: Props) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState<number>(0);

    const { data: business } = useBusiness();

    const handleResize = () => {
        if (ref.current) setWidth(ref.current.getBoundingClientRect().width);
    };

    useEffect(() => {
        if (ref.current) setWidth(ref.current.getBoundingClientRect().width);
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, [ref.current]);

    const photos = getAllPhotos(business?.photos ?? ({} as PhotosData)).map(photo => photo.url);

    // Permission
    const businessIsOpen = !useIsBusinessClosed();
    const photosPermission = useFieldMetadata('photos', 'secondary')?.enabled && businessIsOpen;

    const maxNumberOfPhotos = Math.min(
        LINE_NUMBER * Math.floor((width + MARGIN) / (PHOTO_WIDTH + MARGIN)) -
            (photosPermission ? 1 : 0),
        photos.length,
    );
    const photosLeft = photos.length - maxNumberOfPhotos;
    const photosToDisplay = [...photos].slice(0, maxNumberOfPhotos);

    if (photos.length === 0) {
        return (
            <PhotosPreviewContainerNoPhotos sectionColor={sectionColor}>
                <PhotoPreviewIcon />
                <AddPhotosTitle>{t('business_edit_photos_add_title')}</AddPhotosTitle>
                <AddPhotosSubtitle>
                    {t('drop_zone_accepted_files', {
                        count: 2,
                    })}
                    {' JPEG, PNG'}
                </AddPhotosSubtitle>
            </PhotosPreviewContainerNoPhotos>
        );
    }

    return (
        <PhotosPreviewContainer ref={ref}>
            {photosToDisplay.map((photo, index) => (
                <Photo
                    key={`photo_preview_${index}`}
                    url={resizeImage(getMappedUrl(photo, business?.media_mapping))}
                    content={
                        photosLeft && index === photosToDisplay.length - 1 ? `+${photosLeft}` : ''
                    }
                />
            ))}
            {photosPermission && (
                <AddPhotos sectionColor={sectionColor}>
                    <AddPhotosContent>
                        <PhotoPreviewIcon
                            height={30}
                            width={30}
                            color={
                                [ColorState.PRECHECK_ERRORS, ColorState.MISSING_WARNING].includes(
                                    sectionColor,
                                )
                                    ? HEX_COLORS.warning
                                    : undefined
                            }
                        />
                    </AddPhotosContent>
                </AddPhotos>
            )}
        </PhotosPreviewContainer>
    );
};

export default PhotosPreview;
