import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const ScrollContainer = styled.div`
    position: relative;
    display: flex;
    overflow: hidden;
    flex: 1;
`;

type ScrollElementProps = {
    scrollLeft: number;
    useArrow: boolean;
};

export const ScrollElement = styled.div<ScrollElementProps>`
    transform: ${props => `translateX(-${props.scrollLeft}px)`};
    transition: transform 0.5s ease-out;
    display: flex;
    ${props => !props.useArrow && 'overflow:auto;'}
`;

const Arrow = styled.div`
    position: absolute;
    display: flex;
    top: 50%;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: ${HEX_COLORS.white};
    transform: translateY(-50%);
    color: ${({ theme }) => theme.colors.secondary.initial};
    box-shadow: 0px 5px 10px rgba(20, 37, 66, 0.16);
    cursor: pointer;
    border-radius: 50%;
`;

export const ArrowLeft = styled(Arrow)`
    left: 8px;
`;

export const ArrowRight = styled(Arrow)`
    right: 8px;
`;

const Gradient = styled.div`
    position: absolute;
    height: 100%;
    top: 0;
    background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    width: 80px;
`;

export const GradientLeft = styled(Gradient)`
    left: 0;
    transform: matrix(-1, 0, 0, 1, 0, 0);
`;

export const GradientRight = styled(Gradient)`
    right: 0;
`;
