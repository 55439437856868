import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import BusinessModal from 'app/common/components/businessModal/components/BusinessModal';
import LoadingView from 'app/common/components/LoadingView';
import NavigationBlocker from 'app/common/components/UnsavedChangesModal/UnsavedChangesModalBlocker';
import { useSettingsPageContext } from 'app/pages/settings/AutoReply/hooks/useSettingsPageContext';

import SuccessModal from './ActivationSuccessModal';
import BusinessSelectStep from './BusinessSelectStep';
import RatingsSelectStep from './RatingsSelectStep';
import Step from './Step';
import TemplateSelectStep from './TemplateSelectStep';

const SUBSCRIBED_TO_RM = { subscribed_to_rm: true };
const SettingsPageForm = () => {
    const { t } = useTranslation();
    const [businessModalOpened, setBusinessModalOpened] = useState(false);

    const toggleBusinessModal = () => {
        setBusinessModalOpened(!businessModalOpened);
    };

    const {
        forReviewsWithComment,
        formChanged,
        selectedRatings,
        showSuccessModal,
        setShowSuccessModal,
        isInitialized,
    } = useSettingsPageContext();

    const showTemplateList = !!selectedRatings.length;

    return (
        <div>
            {!isInitialized ? (
                <LoadingView />
            ) : (
                <div>
                    <Step
                        title={t('auto_reply_form_step_business_title')}
                        subtitle={t('auto_reply_form_step_business_subtitle')}
                    >
                        <BusinessSelectStep toggleBusinessModal={toggleBusinessModal} />
                    </Step>
                    <Step
                        title={t('auto_reply_form_step_target_title')}
                        subtitle={t('auto_reply_form_step_target_subtitle')}
                    >
                        <RatingsSelectStep />
                    </Step>
                    {showTemplateList && (
                        <Step
                            title={t('auto_reply_form_step_template_title')}
                            subtitle={
                                forReviewsWithComment
                                    ? t('auto_reply_with_comment_form_step_template_subtitle')
                                    : t('auto_reply_form_step_template_subtitle')
                            }
                        >
                            <TemplateSelectStep />
                        </Step>
                    )}
                </div>
            )}

            <BusinessModal
                opened={businessModalOpened}
                closeModal={toggleBusinessModal}
                noBusinessLimit
                searchFilters={SUBSCRIBED_TO_RM}
                withUrlSync={false}
            />
            <SuccessModal
                onHide={() => setShowSuccessModal(false)}
                show={showSuccessModal}
                title={t('auto_reply_success_modal_title')}
                text={t('auto_reply_success_modal_text')}
            />

            <NavigationBlocker when={formChanged} />
        </div>
    );
};

export default SettingsPageForm;
