import React from 'react';

import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import { BodyTitleContainer, BodyTitleSelect } from './BusinessModal.styled';

type Props = {
    title: string;
    selectAll: boolean;
    handleClick: () => void;
    loading: boolean;
};

const BodyTitle = ({ title, selectAll, handleClick, loading }: Props) => {
    const { t } = useTranslation();

    return (
        <BodyTitleContainer>
            <Text variant="bodyLBold">{t(title)}</Text>
            {loading ? (
                <LoadingRectangle recHeight="15px" recWidth="110px" recRadius="2px" />
            ) : (
                <BodyTitleSelect onClick={handleClick}>
                    <Text color="primary" variant="bodyMBold">
                        {t(selectAll ? 'select_all' : 'unselect_all')}
                    </Text>
                </BodyTitleSelect>
            )}
        </BodyTitleContainer>
    );
};

export default BodyTitle;
