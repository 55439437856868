import { useMutation } from 'react-query';

import { LiteralRating } from 'app/api/types/review';
import { FormattedReplyTemplate } from 'app/api/types/reviewReplyTemplates';
import { ChoiceWithSubtitle } from 'app/api/types/user';
import ReviewAutoReply from 'app/api/v2/api_calls/reviewAutoReplyApiCalls';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

const ratingsOptionsToQueryData = (options: Array<ChoiceWithSubtitle<LiteralRating>>) => {
    return {
        positive: !!options.find(option => option.value === 'positive'),
        neutral: !!options.find(option => option.value === 'neutral'),
        negative: !!options.find(option => option.value === 'negative'),
    };
};

const useSaveSettingsMutation = () =>
    useMutation(
        ({
            forReviewsWithComment,
            activate,
            settingsId,
            ratings,
            templates,
            businessFilter,
        }: {
            forReviewsWithComment?: boolean;
            settingsId?: string;
            activate?: boolean;
            ratings: Array<ChoiceWithSubtitle<LiteralRating>>;
            templates: Array<FormattedReplyTemplate>;
            businessFilter?: BusinessModalFiltersType;
        }) => {
            const data = {
                is_with_content: forReviewsWithComment ?? false,
                enabled: activate ?? undefined,
                ...ratingsOptionsToQueryData(ratings),
                templates: templates.map(template => ({
                    id: template.id,
                })),
                business_search: businessFilter ?? null,
            };
            return settingsId
                ? ReviewAutoReply.updateSettings(settingsId, data)
                : ReviewAutoReply.saveSettings(data);
        },
    );

export default useSaveSettingsMutation;
