import { Text } from '@partoohub/ui';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DayString } from 'app/api/types';
import { DAYS } from 'app/businessEditV2/oldFromEditV1/data/constants';
import ControlledExpandableToggleBox from 'app/common/designSystem/components/molecules/ExpandableToggleBox/ControlledExpandableToggleBox';

import ControlledOpenHourDay from './ControlledOpenHourDay';
import { HourWindowList, MessagingSettingsFormErrors } from '../types';

type Props = {
    errors: MessagingSettingsFormErrors;
    control: Control;
    clearErrors: any;
};

export const CustomChatHoursToggleBox = ({ errors, control, clearErrors }: Props) => {
    const { t } = useTranslation();

    const businessHoursField = 'uses_business_hours';
    const chatHoursField = 'chat_open_hours';
    const watchUsesBusinessHours = useWatch({ name: businessHoursField, control });
    const watchCustomChatHours = useWatch({ name: chatHoursField, control });

    const openHoursDailyValidationRules = {
        checkNoEmptyHourFields: (hourWindows: HourWindowList) => {
            for (const hourWindow of hourWindows) {
                if (hourWindow.startHour === '' || hourWindow.endHour === '') {
                    return t('conv_settings_cannot_be_empty');
                }
            }
            return true;
        },
        checkEndHourHigherThanStartHour: (hourWindows: HourWindowList) => {
            for (const hourWindow of hourWindows) {
                if (hourWindow.startHour >= hourWindow.endHour) {
                    return t('conv_settings_start_time_higher_end_time');
                }
            }
            return true;
        },
        checkHourWindowsNoOverlap: (hourWindows: HourWindowList) => {
            for (const [i] of hourWindows.entries()) {
                for (const [j] of hourWindows.entries()) {
                    if (
                        i !== j &&
                        ((hourWindows[i].startHour >= hourWindows[j].startHour &&
                            hourWindows[i].startHour <= hourWindows[j].endHour) ||
                            (hourWindows[i].endHour >= hourWindows[j].startHour &&
                                hourWindows[i].endHour <= hourWindows[j].endHour))
                    ) {
                        return t('conv_settings_time_slots_are_overlapping');
                    }
                }
            }
            return true;
        },
    };
    const openHoursGeneralValidationRules = {
        checkDaysAreNotAllClosed: () =>
            watchUsesBusinessHours && DAYS.every(day => watchCustomChatHours?.[day]?.length === 0)
                ? t('message_settings_error_custom_hours_cannot_be_off')
                : true,
    };

    const handleResetBusinessHours = () => {
        clearErrors(businessHoursField);
    };

    const clearOpenHoursErrors = (day: DayString) => {
        clearErrors(`${chatHoursField}.${day}`);
        clearErrors(businessHoursField);
    };

    return (
        <ControlledExpandableToggleBox
            rules={{
                validate: openHoursGeneralValidationRules,
            }}
            onClick={handleResetBusinessHours}
            nodes={[
                <div key={'--custom-chat-hoours-toggle-box--'}>
                    {(DAYS as Array<DayString>).map(day => (
                        <ControlledOpenHourDay
                            key={day}
                            day={day}
                            name={`${chatHoursField}.${day}`}
                            control={control}
                            rules={{
                                validate: openHoursDailyValidationRules,
                            }}
                            errors={errors?.customChatHours?.[day]?.message ?? ''}
                            clearOpenHoursErrors={() => clearOpenHoursErrors(day)}
                        />
                    ))}
                    {errors?.usesBusinessHours && (
                        <Text variant="bodyMRegular" as="span" color={'danger'}>
                            {t('message_settings_error_custom_hours_cannot_be_off')}
                        </Text>
                    )}
                </div>,
            ]}
            name={businessHoursField}
            control={control}
        />
    );
};
