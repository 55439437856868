import { Link, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import BannerImage from './BannerPicture';
import { BannerWrapper, ContentWrapper, ImageWrapper } from './ExplanationBanner.styled';

const ExplanationBanner = () => {
    const { t } = useTranslation();

    return (
        <BannerWrapper>
            <ImageWrapper>
                <BannerImage />
            </ImageWrapper>
            <ContentWrapper>
                <Text variant="heading6" color="#FF426E">
                    {t('google_verification__explanation_banner__title')}
                </Text>
                <Text variant="bodyMMedium">
                    {t('google_verification__explanation_banner__content')}{' '}
                    <Link
                        href="https://help.partoo.co/fr/articles/7901761-google-business-profile-qu-est-ce-que-la-validation-des-fiches"
                        target="_blank"
                        variant="primary"
                    >
                        {t('google_verification__explanation_banner__link')}
                    </Link>
                </Text>
            </ContentWrapper>
        </BannerWrapper>
    );
};

export default ExplanationBanner;
