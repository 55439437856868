import useReviewsStats from 'app/common/hooks/queries/reviews/useReviewsStats';

import { useReviewStatsPrevious } from './useReviewStatsPrevious';

export const useIsLoadinReviewsStats = (): boolean => {
    const { isLoading } = useReviewsStats();
    const { isLoading: isLoadingPrevious } = useReviewStatsPrevious();

    return isLoading || isLoadingPrevious;
};
