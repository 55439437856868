import { hideableComponentsNames, withHideableComponent } from 'app/SDKBridge';

import CollapsibleSidebar from './Sidebar/CollapsibleSidebar';
import ProductSidebar from './Sidebar/ProductSidebar.container';
import { FlexRow } from './Sidebar.styled';

type Props = {
    collapsed: boolean;
};

const Sidebar = ({ collapsed }: Props) => (
    <FlexRow>
        <ProductSidebar />
        <CollapsibleSidebar sidebarCollapsed={collapsed} />
    </FlexRow>
);

export default withHideableComponent(Sidebar, hideableComponentsNames.APP_SIDEBAR);
