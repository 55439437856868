import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { CATEGORIES } from 'app/common/data/queryKeysConstants';
import { useLanguage } from 'app/common/services/getLang';

export default function useSuggestedCategories(categoryId: string) {
    const lang = useLanguage();
    return useQuery(
        [CATEGORIES, { categoryId, lang }],
        () => api.categories.getSuggestedCategories(categoryId, lang),
        {
            enabled: !!categoryId,
        },
    );
}
