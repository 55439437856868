import { Control, useController, useWatch } from 'react-hook-form';

import ToggleBox, { Props } from './ToggleBox';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    onClick?: (e: MouseEvent) => void;
};

export const ControlledToggleBox = ({
    name,
    control,
    onClick = () => undefined,
    ...props
}: ControlledComponent & Omit<Props, 'onClick' | 't'>) => {
    const value = useWatch({ control, name });

    const {
        field: { onChange, ...rest },
    } = useController({ control, name });

    return (
        <ToggleBox
            onClick={(e: MouseEvent) => {
                onChange(e);
                onClick && onClick(e);
            }}
            checked={value}
            {...rest}
            {...props}
        />
    );
};
