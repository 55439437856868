import { Option } from '@partoohub/ui';

export const getLinkOptions = (t: (text: string) => string): Option[] => [
    {
        label: t('without_link'),
        name: 'no_link',
        value: 'no_link',
    },
    {
        label: t('google_post_business_url'),
        name: 'use_business_url',
        value: 'use_business_url',
    },
    {
        label: t('type_manually'),
        name: 'custom_link',
        value: 'custom_link',
    },
];

export const getCustomLinkOptions = (t: (text: string) => string): Option[] => [
    {
        value: 'book',
        name: 'book',
        label: t('posts_book_custom_link_google'),
    },
    {
        value: 'order',
        name: 'order',
        label: t('posts_order_custom_link_google'),
    },
    {
        value: 'shop',
        name: 'shop',
        label: t('posts_shop_custom_link_google'),
    },
    {
        value: 'learn_more',
        name: 'learn_more',
        label: t('posts_learn_more_custom_link_google'),
    },
    {
        value: 'sign_up',
        name: 'sign_up',
        label: t('posts_sign_up_custom_link_google'),
    },
    {
        value: 'call',
        name: 'call',
        label: t('posts_call_custom_link_google'),
    },
];
