import { useContext } from 'react';

import { greyColorObject } from '@partoohub/branding';
import { Stack } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import {
    BusinessImportFieldsType,
    BusinessInfosImportType,
    GooglePostStatusType,
} from 'app/api/types/business';
import { RoleValue } from 'app/api/types/user';
import QuickConnectContext from 'app/businessConnection/components/genericQuickConnect/QuickConnect.context';
import { BusinessImportFieldSection } from 'app/businessConnection/components/modals/ModalCommonComponents/BusinessImportFieldSection';
import { SimpleImportFieldSection } from 'app/businessConnection/components/modals/ModalCommonComponents/SimpleImportFieldSection';
import getBusinessFieldsDisabled from 'app/businessConnection/services/getBusinessFieldsDisabled';
import { PROVIDER } from 'app/common/data/roles';
import useOrgPermissions from 'app/pages/settingsV2/subPages/Team/components/Permissions/hooks/useOrgPermissions';

import { ImportSectionContainer } from './ImportSection.styled';
import { ContentText } from '../../InfoSection.styled';

interface Props {
    businessImport: BusinessImportFieldsType;
    onChange: (arg0: string, arg1: BusinessInfosImportType | boolean) => void;
    role: RoleValue;
    googlePostStatus: GooglePostStatusType;
    // Optional props
    businessWarning?: BusinessImportFieldsType;
}

const businessWarningDefault = {
    businessInfos: {
        name: false,
        code: false,
        categories: false,
        addressFull: false,
        openHours: false,
        specificHours: false,
        description: false,
        phone: false,
        website: false,
        additionalUrls: false,
    },
    photos: false,
    attributes: false,
    services: false,
    googlePosts: false,
};

export const ImportSection = ({
    businessImport,
    onChange,
    role,
    // @ts-ignore
    businessWarning = businessWarningDefault,
    googlePostStatus,
}: Props) => {
    const { t } = useTranslation();
    const { selectedSuggestions } = useContext(QuickConnectContext);
    const { isLoading, data: businessFields } = useOrgPermissions();

    if (isLoading) {
        return <></>;
    }

    // @ts-ignore
    const businessFieldsDisabled = getBusinessFieldsDisabled(businessFields);

    if (selectedSuggestions.length === 0) {
        return (
            <Stack justifyContent="center" alignItems="center">
                <ContentText variant="heading3" color={greyColorObject.initial}>
                    {t('select_location_page')}
                </ContentText>
            </Stack>
        );
    }

    return (
        <ImportSectionContainer>
            <BusinessImportFieldSection
                businessInfos={businessImport.businessInfos}
                businessWarning={businessWarning.businessInfos}
                onChange={value => onChange('businessInfos', value)}
                infosDisabled={businessFieldsDisabled.businessInfos}
            />
            <SimpleImportFieldSection
                name={t('photos')}
                isChecked={businessImport.photos}
                isFilled={businessWarning.photos}
                onChange={value => onChange('photos', value)}
                disabled={businessFieldsDisabled.photos}
            />
            <SimpleImportFieldSection
                name={t('attributes')}
                isChecked={businessImport.attributes}
                isFilled={businessWarning.attributes}
                onChange={value => onChange('attributes', value)}
                description={
                    businessImport.attributes && !businessImport.businessInfos.categories
                        ? t('partner_connection_attributes_warning')
                        : ''
                }
                disabled={businessFieldsDisabled.attributes}
            />
            <SimpleImportFieldSection
                name={t('services')}
                isChecked={businessImport.services}
                isFilled={businessWarning.services}
                onChange={value => onChange('services', value)}
                description={
                    businessImport.services && !businessImport.businessInfos.categories
                        ? t('partner_connection_services_warning')
                        : ''
                }
                disabled={businessFieldsDisabled.services}
            />

            <SimpleImportFieldSection
                name={t('menu')}
                isChecked={businessImport.foodMenu}
                isFilled={businessWarning.foodMenu}
                onChange={value => onChange('foodMenu', value)}
                description={
                    businessImport.foodMenu && !businessImport.businessInfos.categories
                        ? t('partner_connection_food_menu_warning')
                        : ''
                }
                disabled={businessFieldsDisabled.foodMenu}
            />

            {googlePostStatus.displayGooglePost && (
                <SimpleImportFieldSection
                    name={t('google_posts')}
                    isChecked={businessImport.googlePosts}
                    isFilled={businessWarning.googlePosts}
                    onChange={value => onChange('googlePosts', value)}
                    disabled={!googlePostStatus.hasGooglePost}
                    description={
                        businessImport.googlePosts && role === PROVIDER
                            ? t('partner_connection_provider_warning')
                            : ''
                    }
                />
            )}
        </ImportSectionContainer>
    );
};
