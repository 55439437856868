import React from 'react';

import { StackStyled } from 'app/pages/settingsV2/subPages/Platforms/components/Connection/sections/ConnectionFilters/ConnectionFilters.styled';

import ConnectionSearch from './ConnectionSearch';
import Filters from './Filters';

const ConnectionFilters = () => (
    <StackStyled direction="row">
        <ConnectionSearch />
        <Filters />
    </StackStyled>
);

export default ConnectionFilters;
