import React, { useEffect, useRef, useState } from 'react';

import { uniqueId } from 'lodash-es';
import ProgressBar from 'progressbar.js';

import { SemiCircleProgressBar } from './BusinessProgressBar.styled';

type Props = {
    value: number;
    color: ColorProgressProps;
};

export type ColorProgressProps = {
    default: string;
    gradient: {
        start: string;
        middle: string;
        end: string;
    };
};

const BusinessProgressBar = ({ value, color }: Props) => {
    const semiCircleRef = useRef<HTMLDivElement>(null);
    const refBar = useRef<any>(null);
    const DURATION_IN_MS = 1400;

    const [progressBarId] = useState(uniqueId('progress-bar-id-'));

    useEffect(() => {
        if (semiCircleRef.current && !refBar.current) {
            refBar.current = new ProgressBar.SemiCircle(
                semiCircleRef?.current,
                {
                    color: 'url(#gradient)',
                    trailColor: color.default,
                    strokeWidth: 12,
                    trailWidth: 12,
                    easing: 'easeInOut',
                    strokeLinecap: 'round',
                    duration: DURATION_IN_MS,
                },
            );
        }
    }, [semiCircleRef, refBar, color]);

    useEffect(() => {
        const animateSemiCircleProgressBar = () => {
            if (refBar.current) {
                refBar.current.trail.setAttribute('stroke-linecap', 'round');
                refBar.current.trail.setAttribute('stroke', color.default);
                refBar.current.trail.setAttribute('trail-color', color.default);

                const linearGradient = `
                    <defs id="${progressBarId}">
                        <linearGradient
                            id="gradient"
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="0%"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0%" stop-color="${color.gradient.start}"/>
                            <stop offset="20%" stop-color="${color.gradient.middle}"/>
                            <stop offset="90%" stop-color="${color.gradient.middle}"/>
                            <stop offset="100%" stop-color="${color.gradient.end}"/>
                        </linearGradient>
                    </defs>
                `;

                document.getElementById(progressBarId)?.remove();

                refBar.current.svg.insertAdjacentHTML(
                    'afterbegin',
                    linearGradient,
                );

                refBar.current.animate(value / 100);
            }
        };

        if (semiCircleRef.current) {
            animateSemiCircleProgressBar();
        }
    }, [value, semiCircleRef, refBar, color]);

    return <SemiCircleProgressBar ref={semiCircleRef} />;
};
export default BusinessProgressBar;
