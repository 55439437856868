import { UseQueryOptions, useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import {
    MessagingStatsData,
    MessagingStatsQueryParams,
} from 'app/api/v2/api_calls/messagingStatsApiCalls';
import { HOMEPAGE_MESSAGING_STATS } from 'app/common/data/queryKeysConstants';

export const useMessagingStats = (
    params: MessagingStatsQueryParams,
    options?: UseQueryOptions<MessagingStatsData>,
) => {
    return useQuery(
        [HOMEPAGE_MESSAGING_STATS, { params }],
        () => api.messagingStats.getMessagingStats(params),
        options,
    );
};
