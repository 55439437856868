import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

type Props = {
    color: string;
};

export const BusinessStatusCompletionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 116px;
    position: relative;
`;

export const Badge = styled.div<Props>`
    position: absolute;
    height: 18px;
    top: 60px;
    font-size: 8px;
    font-weight: 700;
    line-height: 9px;
    padding: 5px 8px;
    text-align: center;
    text-transform: uppercase;
    background-color: ${props => props.color};
    border-radius: 50px;
    color: ${HEX_COLORS.white};
`;
