import { useTranslation } from 'react-i18next';

import { SettingMenuItemsData } from 'app/pages/settingsV2/subComponents/SettingsMenuList/SettingsMenuList';
import {
    SETTINGS_TEAM_GROUPS_PATHNAME,
    SETTINGS_TEAM_PATHNAME,
    SETTINGS_TEAM_PERMISSIONS_PATHNAME,
    SETTINGS_TEAM_USERS_PATHNAME,
} from 'app/routing/routeIds';

import { useIsGroupsSettingsPageEnabled } from './pagesEnabled/useIsGroupsSettingsPageEnabled';
import { useIsPermissionsPageEnabled } from './pagesEnabled/useIsPermissionsPageEnabled';

export const useSettingTeamMenu = (): SettingMenuItemsData => {
    const { t } = useTranslation();
    const isGroupsSettingsPageEnabled = useIsGroupsSettingsPageEnabled();
    const isPermissionsPageEnabled = useIsPermissionsPageEnabled();

    return {
        menus: [
            {
                label: t('settings_menu_team'),
                pathname: SETTINGS_TEAM_PATHNAME,
                options: [
                    {
                        name: 'users',
                        value: 'users',
                        label: t('settings_menu_team_users'),
                        pathname: SETTINGS_TEAM_USERS_PATHNAME,
                        display: true,
                    },
                    {
                        name: 'permissions',
                        value: 'permissions',
                        label: t('settings_menu_team_permissions'),
                        pathname: SETTINGS_TEAM_PERMISSIONS_PATHNAME,
                        display: isPermissionsPageEnabled,
                    },
                    {
                        name: 'groups',
                        value: 'groups',
                        label: t('settings_menu_team_groups'),
                        pathname: SETTINGS_TEAM_GROUPS_PATHNAME,
                        display: isGroupsSettingsPageEnabled,
                    },
                ],
            },
        ],
    };
};
