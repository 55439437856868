import React from 'react';

import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import MultipleSelectImage from 'app/common/designSystem/components/molecules/MultipleSelectImage';

type Props = {
    value: Array<string>;
    possibleImagesLabels: Array<string>;
    possibleImagesUrls: Array<string>;
    possibleValues: Array<string>;
    disabled: boolean;
    onChange: (newValue: Array<string> | null) => void;
    name: string;
    displayName?: string;
};

const onChangeMultipleSelectImage = (values, onChange) => {
    const newValue = values.map(option => option);
    return onChange(newValue.length ? newValue : null);
};

const Component = ({
    value,
    possibleImagesLabels,
    possibleImagesUrls,
    possibleValues,
    disabled,
    name,
    onChange,
    displayName,
}: Props) => (
    <MultipleSelectImage
        name={displayName || name}
        selectedOptions={value || []}
        possibleImagesLabels={possibleImagesLabels}
        possibleImagesUrls={possibleImagesUrls}
        onChange={e => onChangeMultipleSelectImage(e, onChange)}
        possibleValues={possibleValues}
        disabled={disabled}
    />
);

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'onChange' | 'value';

const CustomFieldsTypeMultipleSelectImage = ({
    name,
    control,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { ref: _ref, ...usedFields } }) => (
            <Component {...props} {...usedFields} />
        )}
    />
);

export default CustomFieldsTypeMultipleSelectImage;
