import { useState } from 'react';

import { STATIC_BASE_URL } from 'app/config';

import { ProviderLogoContainer } from './ProviderLogo.styled';

type Props = {
    providerLabel: string;
};

const ProviderLogo = ({ providerLabel }: Props) => {
    const [isImgFailed, setIsImgFailed] = useState(false);

    return !isImgFailed ? (
        <ProviderLogoContainer>
            <img
                src={`${STATIC_BASE_URL}/images/providers_asset/${providerLabel}/${providerLabel}_logo.png`}
                onError={() => setIsImgFailed(true)}
                className="logo"
                alt={`provider-logo-${providerLabel}`}
            />
        </ProviderLogoContainer>
    ) : (
        <></>
    );
};

export default ProviderLogo;
