import React, { FC, ReactNode, useEffect } from 'react';

import { compose } from 'redux';

import {
    FlexRow,
    ReactContentContainer,
    ReactPageContainer,
} from 'app/common/components/ReactPage.styled';
import { TaskProvider } from 'app/common/contexts/taskListener';
import { WebsocketConnectionProvider } from 'app/common/contexts/websocketConnection';
import Sidebar from 'app/common/designSystem/components/molecules/Sidebar';
import { MobileSidebar } from 'app/common/designSystem/components/molecules/Sidebar/mobile/MobileSidebar';

import { useIsPageMigratedToTabs } from 'app/hooks/useIsPageMigratedToTabs';
import { useIsTablet } from 'app/hooks/useIsTablet';
import { useIsAnalyticsV2Page } from 'app/pages/analytics/ReviewAnalyticsV2/hooks/useIsAnalyticsV2Page';
import { LoaderView } from 'app/pages/analytics/VisibilityAnalyticsV2/components/LoaderView/LoaderView';
import { useIsConversation } from 'app/pages/conversations/Messaging/hooks/useIsConversation';
import { useIsHomepage } from 'app/pages/Homepage/hooks/useIsHomepage';
import { useIsPosts } from 'app/pages/posts/hooks/useIsPosts';
import { useIsSetting } from 'app/pages/settingsV2/hooks/Common/useIsSetting';

type Props = {
    children: ReactNode;
    isLoading: boolean;
    isSidebarCollapsed: boolean;
    pathname: string;
    loadUserGroupsData: (arg0: string) => void;
};

const ReactPage = ({
    children,
    isLoading = false,
    isSidebarCollapsed,
    loadUserGroupsData,
    pathname,
}: Props) => {
    const isTablet = useIsTablet();
    const isSetting = useIsSetting();
    const isHomepage = useIsHomepage();
    const isPosts = useIsPosts();
    const isAnalyticsV2 = useIsAnalyticsV2Page();
    const isConversation = useIsConversation();

    const isPageMigratedToTabs = useIsPageMigratedToTabs();
    const collapsed = isSetting || isHomepage || isPosts || isSidebarCollapsed || isPageMigratedToTabs || isConversation;

    useEffect(() => {
        loadUserGroupsData(pathname);
    }, [loadUserGroupsData, pathname]);

    return (
        <FlexRow>
            {!isTablet && <Sidebar collapsed={collapsed} />}
            {isTablet && <MobileSidebar />}
            <ReactPageContainer
                id="react-page"
                applyNewBackground={isSetting || isHomepage || isAnalyticsV2}
            >
                {isLoading && <LoaderView height={100} />}
                <ReactContentContainer id="react-content">{children}</ReactContentContainer>
            </ReactPageContainer>
        </FlexRow>
    );
};

// A helper HOC to put WebsocketConnectionProvider above the consumer hook below
const withWebsocketConnection =
    <P extends JSX.IntrinsicAttributes>(WrappedComponent: React.ComponentType<P>): FC<P> =>
    props => (
        <WebsocketConnectionProvider>
            <WrappedComponent {...props} />
        </WebsocketConnectionProvider>
    );

// HOC to catch task updates based on websocket events above
const withJobEventsListner =
    <P extends JSX.IntrinsicAttributes>(WrappedComponent: React.ComponentType<P>): FC<P> =>
    props => (
        <TaskProvider>
            <WrappedComponent {...props} />
        </TaskProvider>
    );

export default compose(withWebsocketConnection, withJobEventsListner)(ReactPage);
