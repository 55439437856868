import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

export const isBusinessIdInFilteredBusinesses = (
    filteredBusinesses: BusinessModalFiltersType,
    businessId: string,
): boolean => {
    const includedBusinessesListIds =
        filteredBusinesses.business__in?.split(',') ?? [];
    const excludedBusinessesListIds =
        filteredBusinesses.business__notin?.split(',') ?? [];

    return (
        (includedBusinessesListIds.length === 0 &&
            excludedBusinessesListIds.length === 0) ||
        (includedBusinessesListIds.length > 0 &&
            includedBusinessesListIds.includes(businessId)) ||
        (excludedBusinessesListIds.length > 0 &&
            !excludedBusinessesListIds.includes(businessId))
    );
};
