import {
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    Stack,
    Table,
    Text,
} from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { sumValues } from 'app/common/utils/sumValues';

import { SectionSelect } from './common/SectionSelect';
import { StyledCell, StyledHeader } from './Rankings.styled';
import { useEReputationInternalRanking } from '../../../hooks/useMetrics';

export const EReputationRanking = () => {
    const { t } = useTranslation();

    const metrics = useEReputationInternalRanking();

    return (
        <Stack>
            <StyledHeader>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Text variant="heading6">{t('review_analytics_internal_ranking')}</Text>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <SectionSelect />
                        <IconButton
                            dataTrackId=""
                            appearance="outlined"
                            icon={[
                                FontAwesomeIconsPartooUsed.faArrowDownToLine,
                                IconPrefix.REGULAR,
                            ]}
                        />
                    </Stack>
                </Stack>
            </StyledHeader>
            <Table
                columns={[
                    {
                        label: '#',
                        width: '300px',
                    },
                    {
                        isOrdered: false,
                        label: t('review_analytics_rankings_businesses'),
                        orderDirection: 'asc',
                        width: '3000px',
                    },
                    {
                        isOrdered: true,
                        label: t('review_analytics_rankings_average_rating'),
                        width: '1000px',
                        orderDirection: 'asc',
                    },
                    {
                        label: t('review_analytics_rankings_reviews'),
                        width: '1000px',
                    },
                    {
                        label: t('review_analytics_rankings_5_stars_reviews'),
                        width: '1000px',
                    },
                    {
                        label: t('review_analytics_rankings_1_2_stars_reviews'),
                        width: '1000px',
                    },
                ]}
            >
                {!!metrics ? (
                    <Table.Body>
                        {metrics.data.map(({ dimension, dimension_name, metrics }) => (
                            <Table.Row dataTrackId="" id={dimension} key={dimension}>
                                <StyledCell></StyledCell>
                                <StyledCell>{dimension_name}</StyledCell>
                                <StyledCell>{metrics.average_rating}</StyledCell>
                                <StyledCell>{sumValues(metrics.rating_distribution)}</StyledCell>
                                <StyledCell>{metrics.rating_distribution[5]}</StyledCell>
                                <StyledCell>
                                    {metrics.rating_distribution[1] +
                                        metrics.rating_distribution[2]}
                                </StyledCell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                ) : undefined}
            </Table>
        </Stack>
    );
};
