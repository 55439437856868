import React from 'react';

import { Button, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    USER_ID_CREATE,
    UserListQueryKeys,
} from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

const AddUserButton = () => {
    const { t } = useTranslation();
    const [, setUserId] = useStateQueryParams(UserListQueryKeys.USER_EDIT);

    return (
        <Button
            size="medium"
            variant="primary"
            appearance="contained"
            shape="cube"
            icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
            onClick={() => setUserId(USER_ID_CREATE)}
            dataTrackId="add_user_button"
        >
            {t('add_user')}
        </Button>
    );
};

export default AddUserButton;
