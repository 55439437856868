import { Text } from '@partoohub/ui';

import { STATIC_BASE_URL } from 'app/config';

import {
    PostImageInfoCriteriaContainer,
    PostImageInfoCriteriaDescriptionContainer,
    PostImageInfoCriteriaIcon,
} from './PostImageInfoCriteria.styled';

type Props = {
    icon: string;
    description: string;
    value: string;
    imgIcon?: string;
};

export default function PostImageInfoCriteria({ icon, description, value, imgIcon = '' }: Props) {
    return (
        <PostImageInfoCriteriaContainer>
            <PostImageInfoCriteriaIcon>
                {imgIcon ? (
                    <img src={`${STATIC_BASE_URL}/images/app/icons/${imgIcon}.svg`} alt={imgIcon} />
                ) : (
                    <i className={`${icon}`} />
                )}
            </PostImageInfoCriteriaIcon>
            <PostImageInfoCriteriaDescriptionContainer>
                <Text variant="sort" color="secondary">
                    {description}
                </Text>
                <Text variant="bodySBold">{value}</Text>
            </PostImageInfoCriteriaDescriptionContainer>
        </PostImageInfoCriteriaContainer>
    );
}
