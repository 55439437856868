import { Button, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { V2BusinessData } from 'app/api/types/business';
import {
    Buttons,
    Description,
    ModalContent,
    Title,
} from 'app/businessEditV2/components/UnsavedModal/UnsavedModal.styled';
import IS_IFRAME from 'app/common/data/iframe';
import SubscriptionUpdate from 'app/common/designSystem/components/atoms/svg/modalImages/subscriptionUpdate';
import useSubscriptionRequest from 'app/pages/visibility/BusinessListV2/hooks/useSubscriptionRequest';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

type Props = {
    business: V2BusinessData;
    setShowModal: (boolean) => void;
};

const SubscriptionsModal = ({ business, setShowModal }: Props) => {
    const { t } = useTranslation();

    const subscriptionRequest = useSubscriptionRequest(
        () => {
            toast.success(t('contact_us_response'), t('request_sent_successfully'));
        },
        () => {
            toast.error(t('unexpected_error'), t('error'));
        },
    );

    const sendSubscriptionRequest = () => {
        subscriptionRequest.mutate();
        setShowModal(false);
        if (IS_IFRAME) {
            sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.SUBSCRIBE_EVENT, {
                productName: 'all',
                businesses: [
                    {
                        id: business.id,
                        org_id: business.org_id,
                        name: business.name,
                    },
                ],
            });
        }
    };

    return (
        <ModalContent>
            <SubscriptionUpdate />
            <Title>{t('update_subscriptions_title')}</Title>
            <Description>{t('update_subscriptions_description')}</Description>
            <Buttons>
                <Button
                    dataTrackId="business_list_subscription_modal--cancel"
                    onClick={() => setShowModal(false)}
                    full
                    shape="cube"
                    variant="secondary"
                    size="large"
                >
                    {t('cancel')}
                </Button>
                <Button
                    dataTrackId="business_list_subscription_modal--contact-us"
                    onClick={sendSubscriptionRequest}
                    full
                    shape="cube"
                    variant="primary"
                    size="large"
                >
                    {t('contact_us')}
                </Button>
            </Buttons>
        </ModalContent>
    );
};

export default SubscriptionsModal;
