import { uniqBy } from 'lodash-es';

import { NEW_REVIEW, REVIEW_UPDATED } from 'app/common/data/websocket';

import { RealtimeUpdatesState, UnformattedObject } from '../reducers';

const getNewReviewsIds = reviews =>
    uniqBy(
        reviews.filter(review => review.type === NEW_REVIEW),
        // @ts-ignore
        review => review.data.id,
        // @ts-ignore
    ).map(review => review.data.id);

export const realtimeUpdatesSelector = (state: any): RealtimeUpdatesState =>
    state.realtimeUpdates;

/**
 * Return new reviews independently if the review have been treated or not after
 */
export const realtimeUpdatesNewReviewsSelector = ({
    reviews,
}: RealtimeUpdatesState): Array<UnformattedObject> => {
    const newReviewsIds = getNewReviewsIds(reviews);
    return uniqBy(reviews, review => review.data.id)
        .filter(review => newReviewsIds.includes(review.data.id))
        .map(review => review.data);
};

/**
 * Return existing reviews updates which correspond to all the updates that are not on new reviews
 */
export const realtimeUpdatesExistingReviewsSelector = ({
    reviews,
}: RealtimeUpdatesState): Array<UnformattedObject> => {
    const newReviewsIds = getNewReviewsIds(reviews);
    return uniqBy(reviews, review => review.data.id)
        .filter(
            review =>
                review.type === REVIEW_UPDATED &&
                !newReviewsIds.includes(review.data.id),
        )
        .map(review => review.data);
};

/**
 * Return new reviews that were treated before that the user could see it
 */
export const realtimeUpdatesTreatedInRealtimeReviewsSelector = (
    realtimeUpdatesState: RealtimeUpdatesState,
): Array<UnformattedObject> =>
    realtimeUpdatesNewReviewsSelector(realtimeUpdatesState).filter(
        review => review.state === 'treated',
    );

/**
 * Returns the list of businesses ids in `reviews` which are not included in `currentBusinessesIds`.
 */
export const reviewsNewBusinessesIdsSelector = (
    reviews: Array<UnformattedObject>,
    currentBusinessesIds: string[],
): string[] =>
    reviews
        .map(review => review.business_id)
        .filter(id => !currentBusinessesIds.includes(id));
