import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';

import { toast } from '@partoohub/ui';
import { addDays, addHours, format, isAfter } from 'date-fns';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useDispatch } from 'react-redux';

import { V2FormattedBusinessData } from 'app/api/types/business';
import { CreatePostBody, UpdatePostBody } from 'app/api/types/post';
import { GetScopesResponse } from 'app/api/types/scopes';
import api from 'app/api/v2/api_calls/camel';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useReduxFilteredBusinesses from 'app/common/components/businessModal/hooks/useReduxFilteredBusinesses';
import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import { resetBusinessFilter } from 'app/common/components/businessModalDeprecated/reducers';
import UnsavedChangesModal from 'app/common/components/UnsavedChangesModal/UnsavedChangesModal';
import { CONNECTION_STATS, POSTS } from 'app/common/data/queryKeysConstants';

import useBusiness from 'app/common/hooks/queries/useBusiness';
import useNavigationBlocker from 'app/common/hooks/useNavigationBlocker';
import useBusinessScopeData from 'app/presence/googlePosts/hooks/useBusinessScopeData';
import { calculateDataPayloadCreatePost } from 'app/presence/googlePosts/utils/calculatePayloadCreatePost';
import { calculateDataPayloadEditPost } from 'app/presence/googlePosts/utils/calculatePayloadUpdatePost';

import { NewPostContext, changeFieldsReducer, initialValues } from './context/NewPost';
import { EditPostsContext, EditPostsContextType } from './hooks/useEditPostContext';
import usePostCreationModalNavigationState from './hooks/usePostCreationModalNavigationState';
import PostCreationModal from './PostCreationModal';
import extractPlatformFromPublisher from './utils/extractPlatformFromPublisher';
import mapApiResponseToFormState from './utils/mapApiResponseToFormState';
import { transformPlatformNametoApiName } from '../../../helpers/transformPlatforms';

type Props = {
    show: boolean;
    onHide: () => void;
    isCreating?: boolean;
    isEditing?: boolean;
    business?: V2FormattedBusinessData;
    postEditingData?: Record<string, any>;
};

export default function PostCreationModalComponent({
    isCreating = false,
    isEditing = false,
    postEditingData = {},
    show,
    onHide,
}: Props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // TODO MORTY: find a better way to reset the business modal
    const resetBusinessSearch = () => dispatch(resetBusinessFilter());

    const { allSteps, setAllSteps, step, resetStep, setStep, setNextStep, setPreviousStep } =
        usePostCreationModalNavigationState();
    const [formFields, updateField] = useReducer(changeFieldsReducer, initialValues);
    const [fieldsHaveChanged, setFieldsHaveChanged] = useState(false);
    const handleUpdateField: React.Dispatch<{
        field: any;
        value: any;
    }> = fieldValue => {
        if (!fieldsHaveChanged) setFieldsHaveChanged(true);
        return updateField(fieldValue);
    };

    const loadPostMediasToState = (postMedias: Array<{ mediaUrl: string }>) => {
        Promise.all(
            postMedias.map(
                media =>
                    new Promise<{
                        media: { mediaUrl: string };
                        img: HTMLImageElement;
                    }>(resolve => {
                        const img = new Image();
                        img.onload = () => resolve({ media, img });
                        img.src = media.mediaUrl;
                    }),
            ),
        ).then(filesToLoad => {
            updateField({
                field: 'postImage',
                value: filesToLoad.map(({ media, img }) => {
                    const postImage = {
                        data_url: media.mediaUrl,
                        file: {},
                        width: img.width,
                        height: img.height,
                    };
                    return {
                        original: postImage,
                        ...postImage,
                    };
                }),
            });
        });
    };

    const businessFilterParams = useBusinessModalFilters();

    const {
        postId = '',
        bulkId,
        setPostId,
        setBulkId,
        setPostCreationModalEditMode,
        setPostToEditData,
        duplicateMode,
        setDuplicateMode,
        duplicateModeData,
        setDuplicateModeData,
    } = useContext<EditPostsContextType>(EditPostsContext);

    const newsForm = useForm({
        defaultValues: {
            post_description: '',
            content_link: 'no_link',
            custom_link: '',
        },
        mode: 'onBlur',
    });

    const alertForm = useForm({
        defaultValues: {
            post_description: '',
            content_link: 'no_link',
            custom_link: '',
        },
        mode: 'onBlur',
    });

    const defaultEventValues = {
        post_description: '',
        event_title: '',
        event_start_date: new Date(),
        event_start_time: format(addHours(new Date(), 1), 'HH:mm'),
        event_end_date: addDays(new Date(), 1),
        event_end_time: format(addHours(new Date(), 1), 'HH:mm'),
    };

    const eventForm = useForm({
        defaultValues: defaultEventValues,
        mode: 'onBlur',
    });
    const offerForm = useForm({
        defaultValues: {
            ...defaultEventValues,
            offer_coupon_code: '',
            event_terms_and_conditions: '',
        },
        mode: 'onBlur',
    });

    const postCreationForms: Record<string, any> = {
        news: newsForm,
        alert: alertForm,
        event: eventForm,
        offer: offerForm,
        covid: alertForm,
    };

    const currentForm = postCreationForms[formFields.type];

    const queryClient = useQueryClient();
    const selectedBusinessesCount = useSelectedBusinessesCount() ?? 0;
    const businessScopeData = useBusinessScopeData(formFields?.platform);

    const { data: connectionStats } = useQuery([CONNECTION_STATS], () =>
        api.business.getUserBusinessConnectionsStats(),
    );

    const resetFormFields = (): void => {
        updateField({ field: 'type', value: 'news' });
        updateField({ field: 'platform', value: [] });
        updateField({ field: 'isScheduled', value: false });
        updateField({ field: 'scheduledDate', value: null });
        updateField({ field: 'scheduledTime', value: null });
        updateField({
            field: 'postImage',
            value: [],
        });
        updateField({
            field: 'buttonTypeGoogle',
            value: null,
        });
    };

    const resetAndClose = (): void => {
        const { reset } = currentForm;
        resetStep();
        reset({ keepDirty: false, keepDefaultValues: true });
        resetBusinessSearch();
        resetFormFields();
        // Reset context values
        setPostId('');
        setBulkId(null);
        setPostCreationModalEditMode(false);
        setPostToEditData({});
        setDuplicateMode(false);
        setDuplicateModeData({});
        onHide();
    };

    const { businesses } = useReduxFilteredBusinesses();
    const userDidSelectBusinesses = selectedBusinessesCount > 0 && businesses?.length !== 1;
    const formHasChanged = isEditing
        ? currentForm.formState.isDirty || fieldsHaveChanged
        : userDidSelectBusinesses || fieldsHaveChanged;

    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const resetAndCloseWithConfirm = () => {
        // Handles confirmation when clicking on the X button
        if (!formHasChanged) {
            resetAndClose();
            return;
        }

        setShowConfirm(true);
    };

    const postMutation = useMutation(
        async () => {
            const isEditRequest = isEditing && !!postId;
            const isBulkRequest = !!bulkId;

            if (!isEditRequest) {
                // create post request
                const postRequestBody: CreatePostBody = await calculateDataPayloadCreatePost(
                    formFields,
                    currentForm.getValues(),
                );

                return api.post.createPost(
                    businessFilterParams,
                    postRequestBody,
                    transformPlatformNametoApiName(formFields.platform),
                );
            }

            const postUpdateBody: UpdatePostBody = await calculateDataPayloadEditPost(
                formFields,
                currentForm.getValues(),
            );

            return isBulkRequest
                ? api.post.updatePostBulk(bulkId, postUpdateBody)
                : api.post.updatePost(postId, postUpdateBody);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries([POSTS]);
                resetAndClose();
            },
            onError: () => toast.error(null, t('unknown error')),
        },
    );

    const countUnauthorizedBusinesses = (scopeData: GetScopesResponse): Record<string, number> =>
        Object.entries(scopeData).reduce((res, businessScopeEntry) => {
            const [publisher, businessScopes] = businessScopeEntry;
            const count: number = Object.keys(businessScopes).reduce(
                (acc: number, cur: string) =>
                    acc + (!businessScopes[cur].includes('posts') ? 1 : 0),
                0,
            );
            return { ...res, [publisher]: count }; // Camel case property
        }, {});

    const unauthorizedBusinessCounts = businessScopeData
        ? countUnauthorizedBusinesses(businessScopeData)
        : {};

    useEffect(() => {
        if (isEditing) {
            const platforms = extractPlatformFromPublisher(postEditingData.postStatus);

            const isScheduled =
                !!postEditingData.scheduleTime &&
                isAfter(new Date(postEditingData.scheduleTime), new Date());

            updateField({ field: 'type', value: postEditingData?.postType });
            updateField({
                field: 'platform',
                value: platforms,
            });

            if (postEditingData.scheduleTime && isScheduled) {
                updateField({
                    field: 'isScheduled',
                    value: isScheduled,
                });
                updateField({
                    field: 'scheduledDate',
                    value: new Date(postEditingData.scheduleTime),
                });
                updateField({
                    field: 'scheduledTime',
                    value: format(new Date(postEditingData.scheduleTime), 'HH:mm'),
                });
            }

            if (postEditingData.postMedias?.length > 0) {
                loadPostMediasToState(postEditingData.postMedias);
            }

            if (postEditingData?.ctaType && platforms.length >= 2) {
                updateField({
                    field: 'buttonTypeGoogle',
                    value: postEditingData?.ctaType,
                });
            }
        }
    }, [isEditing]);

    const { data: business } = useBusiness(isEditing ? postEditingData.businessId : null);

    useEffect(() => {
        if (duplicateMode === true) {
            const platforms = extractPlatformFromPublisher(duplicateModeData.postStatus);

            setStep(0);
            updateField({ field: 'type', value: duplicateModeData.postType });
            updateField({
                field: 'platform',
                value: platforms,
            });

            if (duplicateModeData?.ctaType && platforms.length > 1) {
                // keeping cta type in react state for multiplatform
                // and in form state for just google
                updateField({
                    field: 'buttonTypeGoogle',
                    value: duplicateModeData?.ctaType,
                });
            }

            if (duplicateModeData?.postMedias?.length > 0) {
                loadPostMediasToState(duplicateModeData.postMedias);
            }
        }
    }, [duplicateMode]);

    useEffect(() => {
        if (formFields.type && duplicateMode === true) {
            const formStateFromApiResponse = mapApiResponseToFormState(duplicateModeData);

            currentForm?.reset(formStateFromApiResponse, {
                keepDirty: true,
                keepDefaultValues: true,
            });
        }

        if (formFields.type && isEditing === true && business) {
            const formStateFromApiResponse = mapApiResponseToFormState(
                postEditingData,
                business?.website_url === postEditingData.ctaLink,
            );

            currentForm?.reset(formStateFromApiResponse, {
                keepDirty: true,
                keepDefaultValues: true,
            });
        }
    }, [formFields.type, business]);

    const { blocker, confirmNavigation, rejectNavigation } = useNavigationBlocker({
        // FIXME: Disable navigation blocker when creating a post so that a popup does not appear when we
        // redirect the user to the post list (on success) or the previous page (on closing the modal)
        when: formHasChanged && !isCreating,
    });

    const contextValue = useMemo(
        () => ({
            isEditing,
            connectionStats,
            formFields,
            postMutation,
            show,
            allSteps,
            setAllSteps,
            step,
            unauthorizedBusinessCounts,
            resetAndClose: resetAndCloseWithConfirm,
            setNextStep,
            setPreviousStep,
            setStep,
            updateField: handleUpdateField,
            resetFormFields,
        }),
        [
            isEditing,
            connectionStats,
            formFields,
            postMutation,
            show,
            step,
            allSteps,
            setAllSteps,
            unauthorizedBusinessCounts,
        ],
    );

    return (
        <NewPostContext.Provider value={contextValue}>
            <FormProvider {...currentForm}>
                <PostCreationModal />
            </FormProvider>

            <UnsavedChangesModal
                show={showConfirm || !!blocker}
                onConfirm={() => {
                    if (blocker) {
                        return confirmNavigation();
                    }
                    return resetAndClose();
                }}
                onHide={() => {
                    rejectNavigation();
                    setShowConfirm(false);
                }}
            />
        </NewPostContext.Provider>
    );
}
