import { useMemo } from 'react';

import { uniqBy } from 'lodash-es';
import { useInfiniteQuery } from 'react-query';

import { useGroupContext } from './useGroupContext';
import api from '../../../../../api/v2/api_calls';
import { GROUPS } from '../../../../../common/data/queryKeysConstants';
import formatGroupData from '../../../../../common/services/formatGroupDataService';

export default function useGroups() {
    const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(
        ['BUSINESS_LIST', GROUPS],
        ({ pageParam = 1 }) => api.groups.getGroups(pageParam, null, null),
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );

    const { groups: hardcodedGroups } = useGroupContext();

    const groups = useMemo(() => {
        // Format the data coming from the API, and merge with the hardcoded groups in the context
        return uniqBy(
            [
                ...hardcodedGroups,
                ...(data?.pages?.flatMap(page => page.groups.map(formatGroupData)) ?? []),
            ],
            group => group.groupId,
        ).sort((a, b) => a.groupName.localeCompare(b.groupName));
    }, [data, hardcodedGroups]);

    return {
        groups,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetchingNextPage,
    };
}
