import ExplanationWithPublisherList, {
    PublisherErrors,
    PublishersInfo,
} from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithPublisherList';

interface ExplanationWithPublisherListProps {
    publisherErrors?: PublisherErrors;
    publishersInfo?: PublishersInfo;
    disabled: boolean;
}

interface ExplanationComponentProps extends ExplanationWithPublisherListProps {
    errorMessage?: string;
    hasWarning: boolean;
}

export const ExplanationExtended: React.FC<ExplanationComponentProps> = ({
    errorMessage,
    hasWarning,
    publisherErrors,
    publishersInfo,
    disabled,
}) => {
    if (errorMessage) {
        return errorMessage;
    } else if (
        hasWarning &&
        publisherErrors &&
        publishersInfo &&
        Object.keys(publisherErrors).length > 0
    ) {
        return (
            <ExplanationWithPublisherList
                publisherErrors={publisherErrors}
                publishersInfo={publishersInfo}
                disabled={disabled}
            />
        );
    }

    return null;
};
