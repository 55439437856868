import styled from '@emotion/styled';

type Props = {
    color: any;
};

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: ${({ theme }) => theme.colors.default.initial};
`;

export const BoxIcon = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: ${props => props.color.hex};
    font-size: 16px;
    background-color: ${props => props.color.rgba};
    border-radius: 50%;
    margin-right: 8px;
`;
