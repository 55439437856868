import React from 'react';

import { useTranslation } from 'react-i18next';

import { isOpenAllDay } from 'app/businessEditV2/utils/specificHours';

import {
    DayPreviewContainer,
    DayPreviewHours,
    DayPreviewName,
    HourMoreInfos,
    HourPreview,
} from './DayPreview.styled';

const MAX_HOURS_DISPLAYED = 2;

type Props = {
    day: string;
    hours: Array<string>;
};

const DayPreview = ({ day, hours }: Props) => {
    const { t } = useTranslation();

    return (
        <DayPreviewContainer>
            <DayPreviewName>{t(day)}</DayPreviewName>
            <DayPreviewHours>
                {hours.length > 0
                    ? hours
                          .slice(0, MAX_HOURS_DISPLAYED)
                          .map((hour, index) => (
                              <HourPreview key={`${day}_${index}`}>
                                  {!isOpenAllDay(hours)
                                      ? hour.replace('-', ' - ')
                                      : t('specific_hours__twenty_four_seven')}
                              </HourPreview>
                          ))
                    : t('closed')}
            </DayPreviewHours>
            {hours.length > MAX_HOURS_DISPLAYED && (
                <HourMoreInfos>
                    <i className="fa-solid fa-clock" /> +
                    {hours.length - MAX_HOURS_DISPLAYED}
                </HourMoreInfos>
            )}
        </DayPreviewContainer>
    );
};

export default DayPreview;
