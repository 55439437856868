import {
    Button,
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    NumberInput,
    Stack,
    TextInput,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { MAX_NUMBER_OF_TEXT_FIELDS } from './data';
import { TrashIcon } from './FormTypeImagesUploader.styled';

type Props = {
    params: Record<string, any>;
    updateParams: (typeParams: Record<string, any>) => void;
    duplicates: string[];
};

export const FormTypeImagesUploaderCreate = ({ params, updateParams, duplicates }: Props) => {
    const { t } = useTranslation();

    const addField = () => {
        if (params.text_fields?.length >= MAX_NUMBER_OF_TEXT_FIELDS) {
            return;
        }
        const newTextFields = params.text_fields ?? [];
        newTextFields.push('');
        const newMaxLengths = params.max_lengths ?? [];
        newMaxLengths.push(null);
        updateParams({
            text_fields: newTextFields,
            max_lengths: newMaxLengths,
        });
    };

    const onChange = (value: any, index: number, constraint: 'text_fields' | 'max_lengths') => {
        const data = params[constraint];
        data[index] = value === '' ? null : value;
        updateParams({
            ...params,
            [constraint]: data,
        });
    };

    const onDeleteField = (index: number) => {
        updateParams({
            ...params,
            text_fields: [
                ...params.text_fields.slice(0, index),
                ...params.text_fields.slice(index + 1),
            ],
            max_lengths: [
                ...params.max_lengths.slice(0, index),
                ...params.max_lengths.slice(index + 1),
            ],
        });
    };

    return (
        <Stack direction="column" gap="16px">
            {params.text_fields &&
                (params.text_fields as string[]).map((value, index) => {
                    const hasError = duplicates?.includes(value);
                    return (
                        <Stack key={index} direction="row" gap="16px" alignItems="top">
                            <TextInput
                                dataTrackId="custom_fields__images_uploader__text_field_name"
                                label={`${t('field')} #${index + 1}`}
                                value={params.text_fields[index]}
                                onChange={value => onChange(value, index, 'text_fields')}
                                error={hasError}
                                notice={hasError ? t('custom_fields_error_duplicated_options') : ''}
                                autoFocus={true}
                            />
                            <NumberInput
                                dataTrackId="custom_fields__images_uploader__max_length"
                                label={t('maximum_length')}
                                value={
                                    params.max_lengths[index] === null
                                        ? ''
                                        : params.max_lengths[index]
                                }
                                onChange={value => onChange(+value || '', index, 'max_lengths')}
                                max={1000}
                                min={1}
                            />
                            <TrashIcon
                                dataTrackId=""
                                icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                                appearance="outlined"
                                variant="danger"
                                onClick={() => onDeleteField(index)}
                            />
                        </Stack>
                    );
                })}
            {(!params.text_fields || params.text_fields.length < MAX_NUMBER_OF_TEXT_FIELDS) && (
                <Button
                    size="medium"
                    variant="primary"
                    appearance="outlined"
                    shape="cube"
                    onClick={addField}
                    icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                    dataTrackId="custom_fields__images_uploader__add_field"
                >
                    {t('add_text_field')}
                </Button>
            )}
        </Stack>
    );
};
