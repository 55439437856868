import { Button } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { FloatingFooter } from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/Footer/Footer.styled';

type Props = {
    closeModal: () => void;
    erase: () => void;
};

const OldGroupsFooter = ({ closeModal, erase }: Props) => {
    const { t } = useTranslation();

    const commonButtonProps = {
        shape: 'cube',
        size: 'large',
        full: true,
    } as const;

    return (
        <FloatingFooter>
            <Button
                {...commonButtonProps}
                appearance="outlined"
                onClick={erase}
                dataTrackId={'business_modal_accesss__erase_button'}
                variant="secondary"
            >
                {t('business_drawer_erase')}
            </Button>

            <Button
                {...commonButtonProps}
                appearance="contained"
                onClick={closeModal}
                variant="primary"
                dataTrackId="business_modal_accesss__confirm_button"
            >
                {t('business_drawer_confirm')}
            </Button>
        </FloatingFooter>
    );
};

export default OldGroupsFooter;
