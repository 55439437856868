import { UseQueryOptions, useQuery } from 'react-query';

import { ReceivedReviewData, ReviewQueryParams } from 'app/api/types/review';
import api from 'app/api/v2/api_calls';
import { REVIEW_LIST } from 'app/common/data/queryKeysConstants';

/**
 * Simple hook to retrieve reviews
 * Simpler that the monstrous useGetReviews that handle FeedbackManagement stuff too
 */
export default function useReviews(
    params: ReviewQueryParams = {},
    options?: UseQueryOptions<ReceivedReviewData>,
) {
    return useQuery([REVIEW_LIST, { params }], () => api.review.searchReviews(params), options);
}
