import styled from '@emotion/styled';
import { Checkbox } from '@partoohub/ui';

export const ChildrenContainerSelectorId = 'ChildrenContainer';
export const ModalActionsSelectorId = 'ModalActions';

export const ModalContentContainer = styled.div`
    width: 500px;
    max-width: 500px;
    padding: 24px 16px 16px 16px;
`;

export const ModalBody = styled.div`
    text-align: center;
    margin-bottom: 32px;
`;

export const ModalActions = styled.div`
    display: flex;
    gap: 16px;
    > * {
        flex: 1 1;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 16px;
    white-space: pre-line;
`;

export const ChildrenContainer = styled.div`
    margin-top: 32px;
`;

export const StyledCheckbox = styled(Checkbox)`
    justify-content: space-evenly;
`;
