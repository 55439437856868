import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { BUSINESSES, BUSINESS_SEARCH_STATS } from 'app/common/data/queryKeysConstants';
import { useBusinessSearchStatsQueryParams } from 'app/pages/visibility/BusinessListV2/hooks/queryParams/useBusinessSearchStatsQueryParams';

export default function useBusinessSearchStats() {
    const query = useBusinessSearchStatsQueryParams() || {};
    return useQuery(
        [BUSINESSES, { query }, BUSINESS_SEARCH_STATS],
        () => api.business.searchBusinessesStats(query),
        {
            retry: false,
            retryOnMount: false,
        },
    );
}
