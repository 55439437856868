import { useContext } from 'react';

import { Stack } from '@partoohub/ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledContentLinkInput } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledContentLinkInput';
import { ControlledCustomLinkInput } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledCustomLinkInput';
import { ControlledGoogleCustomLink } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledGoogleCustomLink';
import { ControlledPostDescription } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledPostDescription';

import { NoLinkMessage, TitleContainer } from './News.styled';
import NextBackButtons from '../components/NextBackButtons/NextBackButtons';
import { NewPostContext, NewPostContextType } from '../context/NewPost';
import usePlatformSelection from '../hooks/usePlatformSelection';
import { getLinkOptions } from '../PostConstants';

export default function NewsForm() {
    const { t } = useTranslation();
    const { formState, watch, handleSubmit } = useFormContext();

    const {
        formFields: { postImage },
        setNextStep,
        setPreviousStep,
        isEditing,
        updateField,
    } = useContext<NewPostContextType>(NewPostContext);

    const {
        hasMultiplatform,
        hasOnlyFacebook,
        hasOnlyGoogle,
        hasOnlyInstagram,
        containsInstagram,
    } = usePlatformSelection();

    const showLinkInput = watch('content_link') === 'custom_link';

    const customGoogleButton = watch('google_custom_link');

    watch('post_description');

    /**
     * Show the link field when :
     *   - Not only Google selected;
     *   - Not only Instagram selected
     *   - Only Google selected and button selected except call
     */
    const showLinkField =
        !hasOnlyInstagram &&
        (!hasOnlyGoogle || (!!customGoogleButton && customGoogleButton !== 'call'));

    const shouldDisplayCustomPhoneWarningGoogle = hasOnlyGoogle && customGoogleButton === 'call';

    const shouldDisableLinkInput = postImage.length > 0 && hasOnlyFacebook;

    const shouldDisableLinkInputInEditing = isEditing && (hasOnlyFacebook || hasMultiplatform);

    const shouldDisplayWarningAboutLink = postImage.length > 0 && hasMultiplatform;

    return (
        <Stack>
            <TitleContainer>{t('post_global_content')}</TitleContainer>
            <ControlledPostDescription />

            {hasOnlyGoogle && <ControlledGoogleCustomLink />}

            {shouldDisplayCustomPhoneWarningGoogle && (
                <NoLinkMessage>{t('posts_custom_google_field_link_save')}</NoLinkMessage>
            )}

            {showLinkField && (
                <>
                    <ControlledContentLinkInput
                        options={getLinkOptions(t).filter(({ value }) =>
                            hasOnlyGoogle ? value !== 'no_link' : true,
                        )}
                        disabled={shouldDisableLinkInput || shouldDisableLinkInputInEditing}
                        onChange={() => {
                            updateField({
                                field: 'buttonTypeGoogle',
                                value: null,
                            });
                        }}
                        isMandatory
                    />
                    {containsInstagram && (
                        <NoLinkMessage>{t('posts_no_link_on_instagram')}</NoLinkMessage>
                    )}
                </>
            )}

            {shouldDisableLinkInput && (
                <NoLinkMessage>{t('posts_no_link_allowed_if_photo_added')}</NoLinkMessage>
            )}

            {shouldDisplayWarningAboutLink && (
                <NoLinkMessage>{t('post_facebook_image_diffusion_message')}</NoLinkMessage>
            )}

            {showLinkInput && (
                <ControlledCustomLinkInput
                    disabled={shouldDisableLinkInput || shouldDisableLinkInputInEditing}
                />
            )}

            <NextBackButtons
                disabled={!formState.isValid}
                onNext={() => {
                    handleSubmit(setNextStep)();
                }}
                onPrevious={() => {
                    setPreviousStep?.();
                }}
            />
        </Stack>
    );
}
