import styled from '@emotion/styled';

type ColorProps = {
    hex: string;
    rgba: string;
};

export const QueriesCardContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.theme.background};
    border: 1px solid #dddfe3;
    height: 100%;
    border-radius: 4px;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const NoDataKeywordsText = styled.div`
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #c7c9cc;
    text-align: center;
`;

export const NoDataContainer = styled.div`
    width: 178px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
`;

export const SearchKeywordsContainer = styled.div`
    margin-top: 16px;
`;

export const NoDataPlacement = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 16px;
`;

export const SuptitleContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

export const SuptitleBoxIcon = styled.div<ColorProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    color: ${props => props.hex};
    font-size: 10px;
    padding: 7px;
    background-color: ${props => props.rgba};
    border-radius: 50%;
    margin-right: 5px;
`;

export const SuptitleText = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;
