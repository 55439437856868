import { Link, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import getLang from 'app/common/services/getLang';
import { translationApiKeyLabels } from 'app/settingsManagement/components/ApiKey/ApiKey';

import {
    ApiKeyManagerDocLinkV2,
    ApiKeyManagerHeaderSectionContainer,
} from './ApiKeyManagerHeaderSection.styled';

type Props = {
    count: number;
};

const PARTOO_REST_API_URL = 'https://developers.partoo.co/rest_api/v2/';

const ApiKeyManagerHeaderSection = ({ count }: Props) => {
    const { t } = useTranslation();
    return (
        <ApiKeyManagerHeaderSectionContainer>
            <ApiKeyManagerDocLinkV2 data-link="api_key_manager_documentation_link">
                <Text variant="heading6">
                    {`${count.toLocaleString(getLang())} ${t('header_counter_api_keys', {
                        count,
                    })}`}
                </Text>
                <Link
                    data-track="api_key_manager_header_section__link"
                    variant="primary"
                    textVariant="bodyMBold"
                    href={PARTOO_REST_API_URL}
                    target="_blank"
                >
                    {t(translationApiKeyLabels.doc)}
                </Link>
            </ApiKeyManagerDocLinkV2>
        </ApiKeyManagerHeaderSectionContainer>
    );
};

export default ApiKeyManagerHeaderSection;
