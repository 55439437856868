import { IconElement, IconPrefix } from '@partoohub/ui';

export enum FunnelBulkInAppStepsEnum {
    SELECT_FIELD = 'select_field',
    ADD_PHOTOS = 'add_photos',
}

export enum PageHeaderStepCategory {
    INFORMATION = 'information',
    MODIFICATION = 'modification',
}

export enum TipType {
    ADVICE = 'advice',
    INFO = 'info',
}

export interface BulkInAppStepInfo {
    pageTitle: string;
    icon: IconElement;
    stepCategory: PageHeaderStepCategory;
    tipTitle: string;
    tipDescription: string;
    tipType: TipType;
    tipText: string;
}

export const BULK_IN_APP_STEPS: { [key in FunnelBulkInAppStepsEnum]: BulkInAppStepInfo } = {
    [FunnelBulkInAppStepsEnum.SELECT_FIELD]: {
        icon: ['fa-store', IconPrefix.REGULAR],
        pageTitle: 'bulk_in_app_funnel__select_field_page__title',
        stepCategory: PageHeaderStepCategory.INFORMATION,
        tipTitle: 'bulk_in_app_funnel__select_field_page__help_title',
        tipDescription: 'bulk_in_app_funnel__select_field_page__help_desc',
        tipType: TipType.INFO,
        tipText: 'bulk_in_app_funnel__select_field_page__advice',
    },
    [FunnelBulkInAppStepsEnum.ADD_PHOTOS]: {
        pageTitle: 'bulk_in_app_funnel__add_photos_page__title',
        icon: ['fa-camera', IconPrefix.REGULAR],
        stepCategory: PageHeaderStepCategory.MODIFICATION,
        tipTitle: 'business_edit_title_photos',
        tipDescription: 'business_edit_description_photos',
        tipType: TipType.ADVICE,
        tipText: 'business_edit_advice_photos',
    },
};
