import React from 'react';

import { useTranslation } from 'react-i18next';

import LoadingView from 'app/common/components/LoadingView';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';

import useIsLoadingProfileSettings from './hooks/useIsLoadingProfileSettings';
import useProfileSettingsPageContent from './hooks/useProfileSettingsPageContent';
import ProfileSettingsForm from './sections/ProfileSettingsForm';
import ProfileSettingsSaveButton from './sections/ProfileSettingsSaveButton';

export const ProfileSettings = () => {
    const { t } = useTranslation();
    const isLoading = useIsLoadingProfileSettings();

    const { form, meUpdate, errors, submitForm, orgHasSso, ssoOnly, isSsoAdmin } =
        useProfileSettingsPageContent();

    if (isLoading) {
        return <LoadingView />;
    }

    return (
        <MenuListContentTemplate
            headerContent={<DefaultMenuListHeader title={t('my_account')} />}
            footerContent={
                <ProfileSettingsSaveButton
                    form={form}
                    meUpdate={meUpdate}
                    submitForm={submitForm}
                />
            }
        >
            <ProfileSettingsForm
                form={form}
                errors={errors}
                orgHasSso={orgHasSso}
                ssoOnly={ssoOnly}
                isSsoAdmin={isSsoAdmin}
            />
        </MenuListContentTemplate>
    );
};
