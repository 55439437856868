import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import { useDispatch } from 'react-redux';

import { BusinessModalContextProps } from 'app/common/components/businessModal/components/BusinessModal/BusinessModal';
import {
    ADD_FILTER,
    BUSINESS_MODAL_SET_SELECTED_BUSINESSES,
    BusinessModalFilters,
    Filter,
    REMOVE_FILTER,
    RESET,
    SET_QUERY,
    TOGGLE_BUSINESS,
    TOGGLE_SELECT_ALL,
    businessModalFiltersReducer,
} from 'app/common/components/businessModal/context/businessModalFiltersReducer';
import { BusinessWithSelected } from 'app/common/components/businessModal/hooks/privateHooks/useBusinessModalDisplayedBusinesses';
import useIsReduxStateEmpty from 'app/common/components/businessModal/hooks/privateHooks/useIsReduxStateEmpty';
import useReduxStateToLocalState from 'app/common/components/businessModal/hooks/privateHooks/useReduxStateToLocalState';
import { setBusinessFilters } from 'app/common/components/businessModalDeprecated/reducers';

import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import useDebounce from 'app/common/hooks/useDebounce';
import usePrevious from 'app/common/hooks/usePrevious';
import { useReduxEffect } from 'app/common/hooks/useReduxEffect';
import env from 'app/common/services/getEnvironment';

export interface BusinessModalActions {
    setQuery: (value: string) => void;
    addFilter: (filter: Filter) => void;
    removeFilter: (filter: Filter) => void;
    toggleSelectAll: () => void;
    toggleBusiness: (business: BusinessWithSelected) => void;
}

export type BusinessFiltersContextType = BusinessModalFilters &
    BusinessModalActions &
    BusinessModalContextProps & {
        debouncedQuery: string;
    };

const BusinessFiltersContext = React.createContext<BusinessFiltersContextType>(null as any);

type Props = BusinessModalContextProps & {
    children: ReactNode;
};

export const BusinessModalFiltersProvider = ({
    children,
    noBusinessLimit = false,
    variation = 'business-only',
    isSelectionMode = false,
}: Props) => {
    const reduxState = useReduxStateToLocalState();
    const enableNewGroups = useNewGroups();

    const [businessFilters, dispatch] = useReducer(businessModalFiltersReducer, reduxState);

    if (env.isDev()) {
        console.log('[BUSINESS MODAL] UseReducer State', businessFilters);
    }

    const reduxIsEmptyState = useIsReduxStateEmpty();
    const prevReduxIsEmptyState = usePrevious(reduxIsEmptyState);

    useEffect(() => {
        if (reduxIsEmptyState && prevReduxIsEmptyState === false) {
            // if the redux state went back to initial state, we reset
            // the local state too (this is when a user clicks the product
            // button).
            dispatch({ type: RESET });
        }
    }, [reduxIsEmptyState]);

    const debouncedQuery = useDebounce(businessFilters.query);

    const setQuery = useCallback((value: string) => dispatch({ type: SET_QUERY, value }), []);
    const addFilter = useCallback(
        (filter: Filter) =>
            dispatch({
                type: ADD_FILTER,
                payload: filter,
                selectAll: variation === 'group-only',
                useNewGroups: !!enableNewGroups,
            }),
        [variation],
    );
    const removeFilter = useCallback(
        (filter: Filter) =>
            dispatch({
                type: REMOVE_FILTER,
                payload: filter,
                selectAll: variation === 'group-only',
                useNewGroups: !!enableNewGroups,
            }),
        [variation],
    );
    const toggleSelectAll = useCallback(() => dispatch({ type: TOGGLE_SELECT_ALL }), []);
    const toggleBusiness = useCallback(
        (business: BusinessWithSelected) =>
            dispatch({ type: TOGGLE_BUSINESS, business, noBusinessLimit }),
        [noBusinessLimit],
    );

    const globalDispatch = useDispatch();

    // Sync SET_SELECTED_BUSINESSES actions with local reducer
    useReduxEffect(
        action => {
            dispatch(action);
            globalDispatch(
                setBusinessFilters({
                    mode: 'select',
                    includedIds: action.payload ?? [],
                    excludedIds: [],
                    queries: [],
                    cities: [],
                    groupIdIn: [],
                    orgs: [],
                }),
            );
        },
        BUSINESS_MODAL_SET_SELECTED_BUSINESSES,
        [],
    );

    const businessFiltersContext = useMemo<BusinessFiltersContextType>(
        () => ({
            ...businessFilters,
            debouncedQuery,
            setQuery,
            addFilter,
            removeFilter,
            toggleSelectAll,
            toggleBusiness,
            noBusinessLimit,
            variation,
            isSelectionMode,
        }),
        [
            businessFilters,
            debouncedQuery,
            setQuery,
            addFilter,
            removeFilter,
            toggleSelectAll,
            toggleBusiness,
            noBusinessLimit,
            variation,
            isSelectionMode,
        ],
    );

    return (
        <BusinessFiltersContext.Provider value={businessFiltersContext}>
            {children}
        </BusinessFiltersContext.Provider>
    );
};

export const useBusinessModalContext = () => useContext(BusinessFiltersContext);

/** Wrapper over the filters provider in case we'll need other providers later
 *  for the business modal. */
export const BusinessModalProvider = ({ children, ...props }: Props) => (
    <BusinessModalFiltersProvider {...props}>{children}</BusinessModalFiltersProvider>
);
