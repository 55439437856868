import { useState } from 'react';

import { Button, IconPrefix, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { ADMIN, PROVIDER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMe';
import useHasGroupPuller from 'app/pages/visibility/BusinessListV2/hooks/groups/useHasGroupPuller';
import CreateGroupModal from 'app/pages/visibility/BusinessListV2/sections/BusinessListHeader/OldHeaderButton/GroupModals/CreateGroupModal';

const AddSubGroupButton = () => {
    const { t } = useTranslation();
    const { data: me } = useMe();
    const hasGroupPuller = useHasGroupPuller();
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

    const disabled = (!!me?.role && [ADMIN, PROVIDER].includes(me?.role)) || hasGroupPuller;

    const onClick = () => {
        setShowCreateModal(true);
    };

    return (
        <>
            <Button
                dataTrackId="add_subgroup__button"
                shape="cube"
                variant="primary"
                icon={['fa-folder-plus', IconPrefix.SOLID]}
                onClick={onClick}
                disabled={disabled}
            >
                {t('add_sub_group')}
            </Button>

            <Modal isOpen={showCreateModal} closeModal={() => setShowCreateModal(false)}>
                <CreateGroupModal closeModal={() => setShowCreateModal(false)} />
            </Modal>
        </>
    );
};

export default AddSubGroupButton;
