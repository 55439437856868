import { useState } from 'react';

import { greyColorObject } from '@partoohub/branding';
import { useTranslation } from 'react-i18next';

import { StyledSwitch, ToggleContainer, ToggleText } from './AutoReplyToggle.styled';
import ToggleConfirmModal from './ToggleConfirmModal';
import { useSettingsPageContext } from '../../../../hooks/useSettingsPageContext';

const AutoReplyToggle = () => {
    const { t } = useTranslation();
    const {
        loadedSettings,
        saveHelpers: {
            checkIfCanActivate,
            hasTriedToActivate,
            setHasTriedToActivate,
            errorBannerRef,
        },
    } = useSettingsPageContext();
    const [showToggleModal, setShowToggleModal] = useState(false);
    const checked = !!loadedSettings?.enabled;

    return (
        <>
            <ToggleConfirmModal show={showToggleModal} onHide={() => setShowToggleModal(false)} />
            <ToggleContainer>
                <ToggleText variant="heading6" color={checked ? greyColorObject.dark : 'default'}>
                    {t('auto_reply_settings_paused')}
                </ToggleText>
                <StyledSwitch
                    dataTrackId="auto_reply_toggle__switch"
                    name="auto_reply_toggle"
                    onChange={() => {
                        if (!loadedSettings?.enabled && !checkIfCanActivate()) {
                            if (!hasTriedToActivate) setHasTriedToActivate(true);
                            errorBannerRef?.current?.scrollIntoView({
                                behavior: 'smooth',
                            });
                        } else {
                            setShowToggleModal(true);
                        }
                    }}
                    checked={checked}
                    label={
                        <ToggleText
                            variant="heading6"
                            color={checked ? 'default' : greyColorObject.dark}
                        >
                            {t('auto_reply_settings_running')}
                        </ToggleText>
                    }
                />
            </ToggleContainer>
        </>
    );
};

export default AutoReplyToggle;
