import useFormattedBusinessAttributes from 'app/businessEditV2/hooks/attributes/attributesBusiness/useFormattedBusinessAttributes';
import useDisplayAttributes from 'app/businessEditV2/hooks/editV2utils/displaySections/useDisplayAttributes';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';

import { ColorState } from './types';

export default function useAttributesSectionColor(): ColorState {
    // Hooks
    const { data: publisherErrors } = usePublisherErrors({});
    const businessAttributes = useFormattedBusinessAttributes();
    const isClosed = useIsBusinessClosed();
    const display = useDisplayAttributes();

    const attributesPermission = useFieldMetadata('attributes', 'attributes')?.enabled;

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // If no attribute available
    if (!display) {
        return ColorState.OK;
    }

    // Disabled
    if (!attributesPermission) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (publisherErrors?.attributes?.attributes) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields
    if (businessAttributes.every(attribute => attribute.value === null)) {
        return ColorState.MISSING_WARNING;
    }

    // OK
    return ColorState.OK;
}
