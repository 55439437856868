import { useTranslation } from 'react-i18next';

import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';

import { CustomFieldsContent } from './sections/CustomFieldsContent';
import { AddCustomFieldOrSectionDropDown } from './sections/CustomFieldsHeader/AddCustomFieldOrSectionButton';
import CustomFieldsModals from './sections/CustomFieldsModals';

export const CustomFields = () => {
    const { t } = useTranslation();

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('custom_fields')}
                    description={t('custom_fields_description')}
                    actionsButton={[
                        <AddCustomFieldOrSectionDropDown key="create_new_custom_field" />,
                    ]}
                />
            }
        >
            <MenuListContentContainer>
                <CustomFieldsContent />
                <CustomFieldsModals />
            </MenuListContentContainer>
        </MenuListContentTemplate>
    );
};
