import styled from '@emotion/styled';

export const ClickableLocationCardContainer = styled.div`
    width: 100%;
    overflow: hidden;

    & > div {
        width: 100%;
    }
`;
