import { useShortcutsItems } from 'app/pages/Homepage/hooks/shortcuts/useShortcutsItems';

import { HomepageShortcutsContainer, HomepageShortcutsGrid } from './HomepageShortcuts.styled';

export const HomepageShortcuts = () => {
    const shortcutsItems = useShortcutsItems();

    if (!shortcutsItems.length) {
        return null;
    }

    return (
        <HomepageShortcutsContainer>
            <HomepageShortcutsGrid>
                {shortcutsItems.map(({ component: Component, key }) => (
                    <Component key={key} />
                ))}
            </HomepageShortcutsGrid>
        </HomepageShortcutsContainer>
    );
};
