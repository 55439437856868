import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TransparencyEnum } from '@partoohub/branding';

export enum SliderPosition {
    FIRST = 'first',
    CENTRAL = 'central',
    LAST = 'last',
}

export const sliderElementStyle = css`
    margin: 0 10px;
`;

const getStyleSliderItem = (sliderPosition: SliderPosition) => {
    if (sliderPosition === SliderPosition.FIRST) {
        return css`
            text-align: left;
        `;
    }
    if (sliderPosition === SliderPosition.LAST) {
        return css`
            text-align: right;
        `;
    }
    return css`
        text-align: center;
    `;
};

export const DailyReportSliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 16px;
`;

export const DailyReportSliderHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DailyReportSliderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
`;

export const DailyReportSliderFacebook = styled.div<{
    sliderPosition: SliderPosition;
}>`
    display: flex;
    gap: 4px;
    ${({ sliderPosition }) =>
        sliderPosition === SliderPosition.FIRST
            ? css`
                  justify-content: flex-start;
              `
            : css`
                  justify-content: flex-end;
              `}
`;

export const DailyReportSliderItem = styled.div<{
    sliderPosition: SliderPosition;
    disabled?: boolean;
}>`
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 0px;
    padding: 0px;
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: ${TransparencyEnum.DISABLED};
        `};
    ${({ sliderPosition }) => getStyleSliderItem(sliderPosition)};
`;
