import { useContext } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Button, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { TemplateFormContext } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/components/MessagesReplyTemplates.context';

import { NoTemplateScreenContainer } from './NoTemplateScreen.styled';
import { OutlinedTemplateListIcon } from './OutlinedTemplateListIcon';

export const NoTemplateScreen = () => {
    const { t } = useTranslation();
    const { setCreatingMode } = useContext(TemplateFormContext);

    return (
        <NoTemplateScreenContainer>
            <div style={{ marginBottom: 24 }}>
                <OutlinedTemplateListIcon />
            </div>
            <div style={{ maxWidth: 520, marginBottom: 8, textAlign: 'center' }}>
                <Text variant="heading2" as="h2" color={HEX_COLORS.blackberry}>
                    {t('messaging_reply_template_no_template_screen_title')}
                </Text>
            </div>
            <div style={{ maxWidth: 414, marginBottom: 24, textAlign: 'center' }}>
                <Text variant="bodyMRegular" as="span">
                    {t('messaging_reply_template_no_template_screen_settings_description')}
                </Text>
            </div>
            <Button
                dataTrackId="messaging_reply_template_no_template_screen_button"
                shape="cube"
                variant="primary"
                onClick={() => setCreatingMode(true)}
            >
                {t('messaging_reply_template_no_template_screen_button')}
            </Button>
        </NoTemplateScreenContainer>
    );
};
