import { useTranslation } from 'react-i18next';

import { useBusinessStatus } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import { CLOSED } from 'app/common/data/businessStatuses';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';

import {
    BusinessClosedButton,
    BusinessClosedText,
    BusinessClosedTexts,
} from './PageHeaderBusinessClosed.styled';

const PageHeaderBusinessClosed = () => {
    const { t } = useTranslation();
    const { setShowModal } = useUnsavedModal(SectionNames.ADVANCED_SETTINGS);
    const businessStatus = useBusinessStatus();

    return (
        <BusinessClosedTexts>
            <BusinessClosedText>
                {businessStatus == CLOSED
                    ? t('this_business_is_closed')
                    : t('this_business_is_temporarily_closed')}
            </BusinessClosedText>
            <BusinessClosedButton>
                <StrikeThroughButton
                    color={'danger'}
                    text={t('advanced_settings')}
                    icon={<i className="fa-solid fa-gear" />}
                    onClick={() => setShowModal(true)}
                />
            </BusinessClosedButton>
        </BusinessClosedTexts>
    );
};

export default PageHeaderBusinessClosed;
