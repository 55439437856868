import { useMutation } from 'react-query';

import { CustomChatHours } from 'app/api/types/business_message_settings';
import api from 'app/api/v2/api_calls/camel';

export const useUpdateSetting = (selectedSettingId: number | undefined, handleSuccess: any) =>
    useMutation(
        (data: { customChatHours: CustomChatHours }) => {
            if ('customChatHours' in data)
                for (const [day, windowList] of Object.entries(data.customChatHours)) {
                    data.customChatHours[day] = (
                        windowList as Array<{
                            startHour: string;
                            endHour: string;
                        }>
                    ).map(({ startHour, endHour }) => `${startHour}-${endHour}`);
                }
            return api.messagingSettings.update(selectedSettingId, data);
        },
        {
            onSuccess: () => {
                handleSuccess();
            },
        },
    );
