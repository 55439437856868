import { omit } from 'lodash-es';
import { connect } from 'react-redux';

import { AccountPartner } from 'app/api/types/account';
import {
    partnerToLocationFuzzy,
    partnerToPartnerName,
} from 'app/businessConnection/services/PartnerToAccountsMethods';
import SearchField from 'app/common/designSystem/components/atoms/SearchField/SearchField';
import { businessByIdSelector } from 'app/common/reducers/newBusinesses';
import {
    searchLocationInputSelector,
    selectedBusinessIdSelector,
    selectedPartnerSelector,
    updateLocationSearchInput,
} from 'app/states/knowledge/reducers/businessConnection/connection';
import { AppState } from 'app/states/reducers';

const mapStateToProps = ({
    businessConnection: { page },
    businesses,
}: AppState) => {
    const businessId = selectedBusinessIdSelector(page);
    const business = businessId
        ? businessByIdSelector(businesses, businessId)
        : null;
    const searchLocationInput = business
        ? `${business.name} ${business.city}`
        : '';
    const partner = selectedPartnerSelector(page);

    return {
        value: searchLocationInputSelector(page),
        initValue: searchLocationInput,
        name: 'search_location',
        placeholder: `search_${partnerToPartnerName[partner]}_location_placeholder`,
        partner,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    onChange: (value: string, partner: AccountPartner) => {
        dispatch(updateLocationSearchInput(value));

        if (value.length > 3) {
            dispatch(partnerToLocationFuzzy[partner](value));
        }
    },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...omit(stateProps, 'partner'),
    onChange: value => dispatchProps.onChange(value, stateProps.partner),
    ...ownProps,
});

const LocationSearchBar = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(SearchField);

export default LocationSearchBar;
