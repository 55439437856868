import { PropsWithChildren } from 'react';

import { IconElement, Stack, Text } from '@partoohub/ui';

import { IS_BELOW_MOBILE, useDeviceType } from 'app/hooks';
import { PublisherStateColors } from 'app/presence/management/types/Publishers.types';

import { PublisherIcon } from '../PublisherIcon/PublisherIcon';

export interface CountByStatusProps {
    color: PublisherStateColors;
    icon: IconElement;
    className?: string;
}

export const CountByStatus = ({
    color,
    icon,
    children,
    className,
}: PropsWithChildren<CountByStatusProps>) => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_MOBILE);

    return (
        <Stack gap="8px" direction="row" alignItems="center">
            {!isBelowProvidedDevices && (
                <PublisherIcon icon={icon} color={color} className={className} />
            )}
            <Text variant="bodyMSemibold" as="span" color={color}>
                {children}
            </Text>
        </Stack>
    );
};
