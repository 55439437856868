import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

import { Text } from '@partoohub/ui';

import Clickable from 'app/common/components/buttons/Clickable';

export const SectionCustomFieldsListContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    > div {
        width: 100%;
    }
`;

export const CustomFieldsListContainer = styled.div`
    padding: 16px 16px 0 16px;
    border-top: 1px solid ${({ theme }) => theme.colors.default.alpha};
`;

export const ClickableCustomFieldSectionContainer = styled(Clickable)`
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;
    padding: 0 16px 0 16px;
`;

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 70px;
    border-radius: ${({ theme }) => theme.radius.large};
    box-shadow: 0 2px 16px 0 rgba(20, 37, 66, 0.08);
    margin-bottom: 36px;
    // If transparent, it will be weird with the drag and drop
    background-color: ${HEX_COLORS.white};
`;

export const SectionName = styled(Text)`
    display: flex;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 21px;
    font-size: 16px;
`;
