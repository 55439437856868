import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

type ColoredDotPropsType = {
    timeout: boolean;
};

// use styled(Text) once 'Tooltip' component is fixed
export const ColoredDot = styled.span<ColoredDotPropsType>`
    padding: 2px 7px;
    margin-left: 4px;
    border-radius: 50px;
    align-self: flex-start;
    flex: none;
    // to remove once 'Tooltip' component is fixed
    font-size: 12px;
    font-family: Outfit;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 15px;
    ${({ timeout, theme }) =>
        timeout
            ? css`
                  color: ${theme.colors.danger.initial};
                  background-color: ${theme.colors.danger.alpha};
              `
            : css`
                  color: ${theme.colors.warning.initial};
                  background-color: ${theme.colors.warning.alpha};
              `};
`;

export const DelayText = styled(Text)`
    flex-shrink: 0;
`;

export const OverridenTooltipWrapper = styled(TooltipWrapper)`
    max-width: 278px;

    &::before {
        white-space: normal;
        width: 190px;
    }
`;
