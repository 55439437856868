import { Button, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

export const CreateReplyTemplateButton = () => {
    const { t } = useTranslation();
    const [, setCreatingTemplate] = useStateQueryParams('creatingTemplate');
    return (
        <Button
            dataTrackId="create_template__web__button"
            onClick={() => setCreatingTemplate('true')}
            variant="primary"
            key="--create-template-button-key--"
            icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
            full
        >
            {t('create_reply_template')}
        </Button>
    );
};
