import React from 'react';

export const SMSMessagingIcon = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.20002 14C10.9556 14 14 10.9555 14 7.19999C14 3.44446 10.9556 0.399994 7.20002 0.399994C3.44449 0.399994 0.400024 3.44446 0.400024 7.19999C0.400024 10.9555 3.44449 14 7.20002 14Z"
            fill="url(#paint0_linear_198_1568)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.2334 7.14374C3.28374 4.93909 5.10818 3.19066 7.313 3.23413C9.51782 3.19066 11.3423 4.93909 11.3926 7.14376C11.3423 9.34842 9.51782 11.0968 7.313 11.0534C6.79617 11.0544 6.28355 10.9603 5.80082 10.7756L4.85333 11.3675C4.82632 11.3844 4.7951 11.3933 4.76325 11.3933C4.66937 11.3933 4.59326 11.3172 4.59326 11.2233V10.0539C3.73608 9.32947 3.23915 8.266 3.2334 7.14374ZM9.46673 7.2C9.46673 7.51297 9.21303 7.76667 8.90007 7.76667C8.5871 7.76667 8.3334 7.51297 8.3334 7.2C8.3334 6.88704 8.5871 6.63334 8.90007 6.63334C9.21303 6.63334 9.46673 6.88704 9.46673 7.2ZM7.20007 7.76667C7.51303 7.76667 7.76673 7.51297 7.76673 7.2C7.76673 6.88704 7.51303 6.63334 7.20007 6.63334C6.8871 6.63334 6.6334 6.88704 6.6334 7.2C6.6334 7.51297 6.8871 7.76667 7.20007 7.76667ZM6.06673 7.2C6.06673 7.51297 5.81303 7.76667 5.50007 7.76667C5.1871 7.76667 4.9334 7.51297 4.9334 7.2C4.9334 6.88704 5.1871 6.63334 5.50007 6.63334C5.81303 6.63334 6.06673 6.88704 6.06673 7.2Z"
            fill="white"
        />
        <defs>
            <linearGradient
                id="paint0_linear_198_1568"
                x1="-2.17738"
                y1="8.49257"
                x2="8.67919"
                y2="16.527"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6BD02" />
                <stop offset="1" stopColor="#F18A03" />
            </linearGradient>
        </defs>
    </svg>
);
