import React from 'react';

import {
    GoogleVerificationSortSectionContainer,
    SortTitlesContainer,
} from './GoogleVerificationListingHeader.styled';
import SortSectionTitle, {
    OrderBy,
} from '../SortSectionTitle/SortSectionTitle';

const GoogleVerificationListingHeader = () => {
    return (
        <GoogleVerificationSortSectionContainer>
            <SortTitlesContainer>
                <SortSectionTitle
                    title="google_verification_sort_title_listing"
                    orderByKey={OrderBy.LISTING}
                />
                <SortSectionTitle
                    title="google_verification_sort_title_status"
                    orderByKey={OrderBy.STATUS}
                />
            </SortTitlesContainer>
        </GoogleVerificationSortSectionContainer>
    );
};

export default GoogleVerificationListingHeader;
