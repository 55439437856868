import React from 'react';

import { useTranslation } from 'react-i18next';

import {
    PostCardCoverContainer,
    PostCardCoverDate,
    PostCardCoverPhoto,
    PostCardCoverPhotoBottom,
    PostCardCoverTitle,
} from 'app/common/designSystem/components/molecules/PreviewPostCard/PreviewPostCardCover.styled';
import { mapTypeToIcon } from 'app/presence/googlePosts/services/mapTypeToIcon';
import {
    ALERT_TYPE,
    NEWS_TYPE,
} from 'app/presence/googlePosts/variables/googlePostType';

import PostTypeLabel from './PreviewPostTypeLabel';

type Props = {
    topicType: string;
    // Optional props
    dates?: string;
    title?: string;
    photoUrl?: string;
};

const PostCardCover = ({ topicType, dates, title, photoUrl }: Props) => {
    const { t } = useTranslation();
    return (
        <PostCardCoverContainer>
            <PostCardCoverPhoto photoUrl={photoUrl} />
            <PostTypeLabel
                className={'type'}
                icon={mapTypeToIcon[topicType]}
                text={t(`post_type_name_${topicType}`)}
            />
            {dates && topicType !== NEWS_TYPE && topicType !== ALERT_TYPE && (
                <PostCardCoverPhotoBottom>
                    <PostCardCoverTitle>{title}</PostCardCoverTitle>
                    <PostCardCoverDate>{dates}</PostCardCoverDate>
                </PostCardCoverPhotoBottom>
            )}
        </PostCardCoverContainer>
    );
};

export default PostCardCover;
