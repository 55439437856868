import { uniq } from 'lodash-es';

import { AccountPartnerName } from 'app/api/types/account';
import { V2FormattedBusinessData } from 'app/api/types/business';
import {
    INITIAL_DATA_LOAD_REQUEST,
    InitialDataLoadRequestAction,
} from 'app/states/knowledge/reducers/businessConnection/initialDataLoad';
import {
    TO_CONNECT_TO_FACEBOOK,
    TO_CONNECT_TO_GMB,
} from 'app/states/knowledge/reducers/businessConnection/nav';

// ACTION TYPES
export const SEARCH_BUSINESSES = 'SEARCH_BUSINESSES';
export const IMPORT_BUSINESSES_SUCCESS = 'IMPORT_BUSINESSES_SUCCESS';
export const SEARCH_BUSINESSES_SUCCESS = 'SEARCH_BUSINESSES_SUCCESS';
const SEARCH_BUSINESSES_FAILURE = 'SEARCH_BUSINESSES_FAILURE';
const SET_TOTAL_BUSINESS_COUNT = 'SET_TOTAL_BUSINESS_COUNT';
export const SET_LOCATION_ID = 'SET_LOCATION_ID';
export const UNSET_BUSINESS_LOCATION_ID = 'UNSET_BUSINESS_LOCATION_ID';
export const UPDATE_BUSINESS_SEARCH_QUERY = 'UPDATE_BUSINESS_SEARCH_QUERY';
const UPDATE_BUSINESS_SELECTED_CITY = 'UPDATE_BUSINESS_SELECTED_CITY';
export const REQUEST_POSSIBLE_CITIES = 'REQUEST_POSSIBLE_CITIES';
const REQUEST_POSSIBLE_CITIES_SUCCESS = 'REQUEST_POSSIBLE_CITIES_SUCCESS';
const REQUEST_POSSIBLE_CITIES_FAILURE = 'REQUEST_POSSIBLE_CITIES_FAILURE';
const SET_CONNECTED_TO_GMB_LOCATION_FILTER = 'SET_CONNECTED_TO_GMB_LOCATION_FILTER';
const SET_CONNECTED_TO_FACEBOOK_LOCATION_FILTER = 'SET_CONNECTED_TO_FACEBOOK_LOCATION_FILTER';
const SET_BUSINESS_ORG_ID_FILTER = 'SET_BUSINESS_ORG_ID_FILTER';
const SET_BUSINESS_ORG_ID_IN_FILTER = 'SET_BUSINESS_ORG_ID_IN_FILTER';
export const SET_BUSINESS_GROUP_ID_FILTER = 'SET_BUSINESS_GROUP_ID_FILTER';
const SET_BUSINESS_GROUP_ID_IN_FILTER = 'SET_BUSINESS_GROUP_ID_IN_FILTER';
const APPLY_BUSINESS_PENDING_FILTER = 'APPLY_BUSINESS_PENDING_FILTER';
const RESET_BUSINESS_STATE = 'RESET_BUSINESS_STATE';
const FORCE_UPDATE_BUSINESSES = 'FORCE_UPDATE_BUSINESSES';

export type ForceUpdateBusinessesAction = {
    type: 'FORCE_UPDATE_BUSINESSES';
    byId: Record<string, V2FormattedBusinessData>;
    ids: Array<string>;
};
export type SearchBusinessesAction = {
    type: 'SEARCH_BUSINESSES';
    page: number;
    query: string;
    fromImport?: boolean;
    additionalParams?: Record<string, string>;
};
export type ImportBusinessesSuccessAction = {
    type: 'IMPORT_BUSINESSES_SUCCESS';
    byId: Record<string, V2FormattedBusinessData>;
    ids: Array<string>;
    maxPage: number;
};
type SearchBusinessesFailureAction = {
    type: 'SEARCH_BUSINESSES_FAILURE';
    errors: Record<string, any>;
};
export type SearchBusinessesSuccessAction = {
    type: 'SEARCH_BUSINESSES_SUCCESS';
    byId: Record<string, V2FormattedBusinessData>;
    ids: Array<string>;
    maxPage: number;
};
type SetTotalBusinessCountAction = {
    type: 'SET_TOTAL_BUSINESS_COUNT';
    count: number;
};
export type SetLocationIdAction = {
    type: 'SET_LOCATION_ID';
    locationId: string;
    businessId: string;
    partnerName: AccountPartnerName;
};
export type UnsetLocationIdAction = {
    type: 'UNSET_BUSINESS_LOCATION_ID';
    businessId: string;
    partnerName: AccountPartnerName;
};
type UpdateSearchQueryAction = {
    type: 'UPDATE_BUSINESS_SEARCH_QUERY';
    searchQuery: string;
};
type UpdateSelectedCityAction = {
    type: 'UPDATE_BUSINESS_SELECTED_CITY';
    selectedCity: string;
};
export type RequestPossibleCitiesAction = {
    type: 'REQUEST_POSSIBLE_CITIES';
};
export type RequestPossibleCitiesSuccessAction = {
    type: 'REQUEST_POSSIBLE_CITIES_SUCCESS';
    cities: Array<string>;
};
export type RequestPossibleCitiesFailureAction = {
    type: 'REQUEST_POSSIBLE_CITIES_FAILURE';
    error: Record<string, any>;
};
type SetConnectedToGmbLocationFilterAction = {
    type: 'SET_CONNECTED_TO_GMB_LOCATION_FILTER';
    value: boolean | null;
};
type SetConnectedToFacebookLocationFilterAction = {
    type: 'SET_CONNECTED_TO_FACEBOOK_LOCATION_FILTER';
    value: boolean | null;
};
type SetBusinessOrgIdFilterAction = {
    type: 'SET_BUSINESS_ORG_ID_FILTER';
    value: number | null;
};
type SetBusinessOrgIdInFilterAction = {
    type: 'SET_BUSINESS_ORG_ID_IN_FILTER';
    orgIds: Array<number> | null;
};
type SetBusinessGroupIdFilterAction = {
    type: 'SET_BUSINESS_GROUP_ID_FILTER';
    groupId: number | null;
};
type SetBusinessGroupIdInFilterAction = {
    type: 'SET_BUSINESS_GROUP_ID_IN_FILTER';
    groupIds: Array<number> | null;
};
type ApplyBusinessPendingFilterAction = {
    type: 'APPLY_BUSINESS_PENDING_FILTER';
};
type ResetBusinessStateAction = {
    type: 'RESET_BUSINESS_STATE';
};
type UpdateBusinessPhotosByIdAction = {
    type: 'UPDATE_BUSINESS_PHOTOS_BY_ID';
    businessUpdater: (arg0: V2FormattedBusinessData) => V2FormattedBusinessData;
    businessId: string;
};
// ACTION FLOW TYPES
export type BusinessesState = {
    byId: Record<string, V2FormattedBusinessData>;
    ids: Array<string>;
    isFetching: boolean;
    errors: Record<string, any>;
    currentPage: number;
    maxPage: number;
    searchQuery: string;
    cities: {
        selectedCity: string;
        possibleCities: Array<string>;
        error: Record<string, any>;
        isFetching: boolean;
    };
    totalBusinessCount: number;
    filters: {
        connectedToGMBLocation: boolean | null;
        connectedToFacebookLocation: boolean | null;
        orgId: number | null;
        orgIdIn: Array<number> | null;
        groupId: number | null;
        groupIdIn: Array<number> | null;
        isGooglePostApiDisabled: boolean | null;
        pending: Record<string, any>;
    };
    getRequests: {
        statuses: Record<string, string>;
        errors: Record<string, Record<string, any>>;
    };
};
export type BusinessAction =
    | InitialDataLoadRequestAction
    | SetLocationIdAction
    | UnsetLocationIdAction
    | SearchBusinessesAction
    | ImportBusinessesSuccessAction
    | SearchBusinessesFailureAction
    | SearchBusinessesSuccessAction
    | UpdateSearchQueryAction
    | UpdateSelectedCityAction
    | SetConnectedToGmbLocationFilterAction
    | SetConnectedToFacebookLocationFilterAction
    | SetBusinessOrgIdFilterAction
    | SetBusinessOrgIdInFilterAction
    | SetBusinessGroupIdFilterAction
    | SetBusinessGroupIdInFilterAction
    | SetTotalBusinessCountAction
    | RequestPossibleCitiesAction
    | RequestPossibleCitiesSuccessAction
    | RequestPossibleCitiesFailureAction
    | ApplyBusinessPendingFilterAction
    | UpdateBusinessPhotosByIdAction
    | ForceUpdateBusinessesAction
    | ResetBusinessStateAction;

const GOOGLE_MY_BUSINESS = 'google';
const FACEBOOK = 'facebook';

export const getPartnerLocationFieldName = {
    [GOOGLE_MY_BUSINESS]: 'googleLocationId',
    [FACEBOOK]: 'facebookPageId',
};
// ACTION CREATORS
export const forceUpdateBusinesses = (
    byId: Record<string, V2FormattedBusinessData>,
    ids: Array<string>,
): ForceUpdateBusinessesAction => ({
    type: FORCE_UPDATE_BUSINESSES,
    byId,
    ids,
});
export const searchBusinesses = (
    page: number,
    query: string,
    fromImport?: boolean,
    additionalParams?: Record<string, string>,
): SearchBusinessesAction => ({
    type: SEARCH_BUSINESSES,
    page,
    query,
    fromImport,
    additionalParams,
});
export const searchBusinessesFailure = (
    errors: Record<string, any>,
): SearchBusinessesFailureAction => ({
    type: SEARCH_BUSINESSES_FAILURE,
    errors,
});
export const searchBusinessesSuccess = (
    byId: Record<string, V2FormattedBusinessData>,
    ids: Array<string>,
    maxPage: number,
): SearchBusinessesSuccessAction => ({
    type: SEARCH_BUSINESSES_SUCCESS,
    byId,
    ids,
    maxPage,
});
export const importBusinessesSuccess = (
    byId: Record<string, V2FormattedBusinessData>,
    ids: Array<string>,
    maxPage: number,
): ImportBusinessesSuccessAction => ({
    type: IMPORT_BUSINESSES_SUCCESS,
    byId,
    ids,
    maxPage,
});
export const setTotalBusinessCount = (count: number): SetTotalBusinessCountAction => ({
    type: SET_TOTAL_BUSINESS_COUNT,
    count,
});
export const setLocationId = (
    businessId: string,
    locationId: string,
    partnerName: AccountPartnerName,
): SetLocationIdAction => ({
    type: SET_LOCATION_ID,
    businessId,
    locationId,
    partnerName,
});
export const unsetLocationId = (
    businessId: string,
    partnerName: AccountPartnerName,
): UnsetLocationIdAction => ({
    type: UNSET_BUSINESS_LOCATION_ID,
    businessId,
    partnerName,
});
export const updateSearchQuery = (searchQuery: string): UpdateSearchQueryAction => ({
    type: UPDATE_BUSINESS_SEARCH_QUERY,
    searchQuery,
});
export const updateSelectedCity = (selectedCity: string): UpdateSelectedCityAction => ({
    type: UPDATE_BUSINESS_SELECTED_CITY,
    selectedCity,
});
export const requestPossibleCities = (): RequestPossibleCitiesAction => ({
    type: REQUEST_POSSIBLE_CITIES,
});
export const requestPossibleCitiesSuccess = (
    cities: Array<string>,
): RequestPossibleCitiesSuccessAction => ({
    type: REQUEST_POSSIBLE_CITIES_SUCCESS,
    cities,
});
export const requestPossibleCitiesFailure = (
    error: Record<string, any>,
): RequestPossibleCitiesFailureAction => ({
    type: REQUEST_POSSIBLE_CITIES_FAILURE,
    error,
});
export const setConnectedToGmbLocationFilterByFilterId = (
    value: string,
): SetConnectedToGmbLocationFilterAction => ({
    type: SET_CONNECTED_TO_GMB_LOCATION_FILTER,
    value: value === TO_CONNECT_TO_GMB ? false : null,
});
export const setConnectedToFacebookLocationFilterByFilterId = (
    value: string,
): SetConnectedToFacebookLocationFilterAction => ({
    type: SET_CONNECTED_TO_FACEBOOK_LOCATION_FILTER,
    value: value === TO_CONNECT_TO_FACEBOOK ? false : null,
});
export const setConnectedToGmbLocationFilter = (
    value: boolean | null,
): SetConnectedToGmbLocationFilterAction => ({
    type: SET_CONNECTED_TO_GMB_LOCATION_FILTER,
    value,
});
export const setBusinessOrgIdFilter = (value: number | null): SetBusinessOrgIdFilterAction => ({
    type: SET_BUSINESS_ORG_ID_FILTER,
    value,
});
export const setBusinessOrgIdInFilter = (
    orgIds: Array<number> | null,
): SetBusinessOrgIdInFilterAction => ({
    type: SET_BUSINESS_ORG_ID_IN_FILTER,
    orgIds,
});
export const setBusinessGroupIdFilter = (
    groupId: number | null,
): SetBusinessGroupIdFilterAction => ({
    type: SET_BUSINESS_GROUP_ID_FILTER,
    groupId,
});
export const setBusinessGroupIdInFilter = (
    groupIds: Array<number> | null,
): SetBusinessGroupIdInFilterAction => ({
    type: SET_BUSINESS_GROUP_ID_IN_FILTER,
    groupIds,
});
export const applyBusinessPendingFilter = () => ({
    type: APPLY_BUSINESS_PENDING_FILTER,
});
export const resetBusinessState = (): ResetBusinessStateAction => ({
    type: RESET_BUSINESS_STATE,
});
export const actionGenerators = {
    requestPossibleCitiesFailure,
    setConnectedToGmbLocationFilter,
    setBusinessOrgIdFilter,
    setBusinessGroupIdFilter,
    resetBusinessFilters: resetBusinessState,
};

export const initialState: BusinessesState = {
    byId: {},
    ids: [],
    isFetching: false,
    errors: {},
    currentPage: 0,
    maxPage: Infinity,
    searchQuery: '',
    cities: {
        selectedCity: '',
        possibleCities: [],
        error: {},
        isFetching: false,
    },
    totalBusinessCount: 0,
    filters: {
        connectedToGMBLocation: null,
        connectedToFacebookLocation: null,
        orgId: null,
        orgIdIn: null,
        groupId: null,
        groupIdIn: null,
        isGooglePostApiDisabled: null,
        pending: {},
    },
    getRequests: {
        statuses: {},
        errors: {},
    },
};

const resetState = {
    ...initialState,
    filters: {
        ...initialState.filters,
        pending: {
            ...initialState.filters.pending,
        },
    },
};

// REDUCER
const businessesReducer = (
    state: BusinessesState = initialState,
    action: BusinessAction,
): BusinessesState => {
    switch (action.type) {
        case 'UPDATE_BUSINESS_PHOTOS_BY_ID':
            break;

        case INITIAL_DATA_LOAD_REQUEST:
            return { ...state, isFetching: true };

        case SEARCH_BUSINESSES:
            return { ...state, isFetching: true, currentPage: action.page };

        case SEARCH_BUSINESSES_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                maxPage: 0,
            };

        case SEARCH_BUSINESSES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                byId: { ...state.byId, ...action.byId },
                ids: uniq([...state.ids, ...action.ids]),
                maxPage: action.maxPage || state.maxPage,
            };

        case FORCE_UPDATE_BUSINESSES:
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
                ids: uniq([...state.ids, ...action.ids]),
            };

        case IMPORT_BUSINESSES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                byId: { ...state.byId, ...action.byId },
                ids: uniq([...action.ids, ...state.ids]),
                maxPage: action.maxPage || state.maxPage,
            };

        case SET_TOTAL_BUSINESS_COUNT:
            return { ...state, totalBusinessCount: action.count };

        // TODO redux -> react query (update of server state)
        case SET_LOCATION_ID:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.businessId]: {
                        ...state.byId[action.businessId],
                        [getPartnerLocationFieldName[action.partnerName]]: action.locationId,
                    },
                },
            };

        // TODO redux -> react query (update of server state)
        case UNSET_BUSINESS_LOCATION_ID:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.businessId]: {
                        ...state.byId[action.businessId],
                        [getPartnerLocationFieldName[action.partnerName]]: null,
                    },
                },
            };

        case UPDATE_BUSINESS_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: action.searchQuery,
                currentPage: initialState.currentPage,
                maxPage: Infinity,
            };

        case UPDATE_BUSINESS_SELECTED_CITY:
            return {
                ...state,
                cities: { ...state.cities, selectedCity: action.selectedCity },
                currentPage: initialState.currentPage,
                maxPage: Infinity,
            };

        case REQUEST_POSSIBLE_CITIES:
            return { ...state, cities: { ...state.cities, isFetching: true } };

        case REQUEST_POSSIBLE_CITIES_SUCCESS:
            return {
                ...state,
                cities: {
                    ...state.cities,
                    possibleCities: action.cities,
                    isFetching: false,
                },
            };

        case REQUEST_POSSIBLE_CITIES_FAILURE:
            return {
                ...state,
                cities: {
                    ...state.cities,
                    error: action.error,
                    isFetching: false,
                },
            };

        case SET_CONNECTED_TO_GMB_LOCATION_FILTER:
            return {
                ...state,
                currentPage: initialState.currentPage,
                maxPage: Infinity,
                filters: {
                    ...state.filters,
                    connectedToGMBLocation: action.value,
                },
            };

        case SET_CONNECTED_TO_FACEBOOK_LOCATION_FILTER:
            return {
                ...state,
                currentPage: initialState.currentPage,
                maxPage: Infinity,
                filters: {
                    ...state.filters,
                    connectedToFacebookLocation: action.value,
                },
            };

        case SET_BUSINESS_ORG_ID_FILTER:
            return {
                ...state,
                currentPage: initialState.currentPage,
                maxPage: Infinity,
                filters: { ...state.filters, orgId: action.value },
            };

        case SET_BUSINESS_ORG_ID_IN_FILTER:
            return {
                ...state,
                currentPage: initialState.currentPage,
                maxPage: Infinity,
                filters: {
                    ...state.filters,
                    orgId: null,
                    orgIdIn: action.orgIds,
                },
            };

        case SET_BUSINESS_GROUP_ID_FILTER:
            return {
                ...state,
                currentPage: initialState.currentPage,
                maxPage: Infinity,
                filters: { ...state.filters, groupId: action.groupId },
            };

        case SET_BUSINESS_GROUP_ID_IN_FILTER:
            return {
                ...state,
                currentPage: initialState.currentPage,
                maxPage: Infinity,
                filters: { ...state.filters, groupIdIn: action.groupIds },
            };

        case APPLY_BUSINESS_PENDING_FILTER:
            return {
                ...state,
                filters: { ...state.filters, ...state.filters.pending },
            };

        // TODO redux -> react query (update of server state)
        case RESET_BUSINESS_STATE:
            return { ...resetState };

        default:
            return state;
    }
};

// SELECTORS
export const searchQuerySelector = ({ searchQuery }: BusinessesState): string => searchQuery;
export const selectedCitySelector = ({ cities }: BusinessesState): string => cities.selectedCity;
export const isFetchingSelector = ({ isFetching }: BusinessesState): boolean => isFetching;
export const businessesByIdSelector = ({
    byId,
}: BusinessesState): Record<string, V2FormattedBusinessData> => byId;
export const businessByIdSelector = (
    { byId }: BusinessesState,
    businessId: string,
): V2FormattedBusinessData => byId[businessId];
export const businessesIdsSelector = ({ ids }: BusinessesState): Array<string> => ids;
export const businessesCurrentPageSelector = (state: BusinessesState): number => state.currentPage;
export const businessesMaxPageSelector = (state: BusinessesState): number => state.maxPage;
export const businessesListSelector = (state: BusinessesState): Array<V2FormattedBusinessData> => {
    const businessesById = businessesByIdSelector(state);
    const businessesIds = businessesIdsSelector(state);
    return businessesIds.map(id => businessesById[id]);
};
export const connectedToGmbLocationSelector = ({
    filters: { connectedToGMBLocation },
}: BusinessesState): null | boolean => connectedToGMBLocation;
export const connectedToFacebookLocationSelector = ({
    filters: { connectedToFacebookLocation },
}: BusinessesState): null | boolean => connectedToFacebookLocation;
export const googlePostApiDisabledSelector = ({
    filters: { isGooglePostApiDisabled },
}: BusinessesState): null | boolean => isGooglePostApiDisabled;
export const orgIdFilterSelector = ({ filters: { orgId } }: BusinessesState): null | number =>
    orgId;
export const orgIdInFilterSelector = ({
    filters: { orgIdIn },
}: BusinessesState): null | Array<number> => orgIdIn;
export const groupIdFilterSelector = ({ filters: { groupId } }: BusinessesState): null | number =>
    groupId;
export const groupIdInFilterSelector = ({
    filters: { groupIdIn },
}: BusinessesState): Array<number> | null => groupIdIn;
export const isSubscribedToRmSelector = ({ byId }: BusinessesState, businessId: string): boolean =>
    byId[businessId].subscriptions.review_management?.active;

/**
 * @deprecated
 * Do not use this anymore. Use useTotalBusinessesCount instead.
 * */
export const totalBusinessCountSelector = ({ totalBusinessCount }: BusinessesState): number =>
    totalBusinessCount;

export default businessesReducer;
