import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { openQuickAction } from 'app/addon/QuickAction/utils';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { useIsMac } from 'app/common/hooks/useIsMac';
import { useIsMobile } from 'app/common/services/screenDimensions';

import {
    ProductButtonContainer,
    ProductButtonHover,
    ProductButtonTooltip,
} from './ProductSidebar/ProductButton.styled';

const QuickActionButton: FC = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const isMAC = useIsMac();
    const tooltipText = t('quick_action__sidebar_button__tooltip');
    const tooltip = isMAC ? `${tooltipText} (⌘+K)` : `${tooltipText} (Ctrl+K)`;

    const content = (
        <ProductButtonContainer
            data-cy="product-sidebar-product-button"
            data-track="sidebar__button__quick_action"
            data-intercom-target="sidebar__button__quick_action"
            onClick={openQuickAction}
            marginTop="auto"
        >
            <ProductButtonHover>
                <i className="fa-light fa-xl fa-magnifying-glass" />
            </ProductButtonHover>
        </ProductButtonContainer>
    );

    if (!isMobile) {
        return (
            <TooltipWrapper tooltip={ProductButtonTooltip} text={tooltip} position="right">
                {content}
            </TooltipWrapper>
        );
    }

    return content;
};

export default QuickActionButton;
