import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Info = styled.div`
    background: #f6f8fc;
    width: 100%;
    border-radius: 8px;
    display: flex;
    padding: 5px 10px;
`;

export const InfoIcon = styled.i<{
    toRotate: boolean;
}>`
    margin: 16px;
    font-weight: 400;
    font-size: 16px;
    color: #7f8ea4;
    display: grid;
    place-items: center;

    ${props =>
        props.toRotate &&
        css`
            animation: rotation 2s infinite ease-in-out;

            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(359deg);
                }
            }
        `};
`;

export const InfoText = styled.div`
    margin: 16px 16px 16px 0;
`;
