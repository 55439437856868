import { Option } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'app/common/services/screenDimensions';

import { ReviewCardMenu } from '../../ReviewCardMenu';

type Props = {
    onClickEdit: () => void;
    onClickDelete: () => void;
    canDelete: boolean;
};

export function CommentMenu({ onClickEdit, onClickDelete, canDelete }: Props) {
    const { t } = useTranslation();
    const isDesktop = !useIsMobile();

    const tooltip = isDesktop
        ? canDelete
            ? t('edit_or_delete_your_reply')
            : t('edit_your_reply')
        : '';

    const onClick = (option: Option) => {
        if (option.value === 'edit') {
            onClickEdit();
        } else {
            onClickDelete();
        }
    };

    return (
        <ReviewCardMenu
            dataTrackId="comment-menu-open"
            tooltip={tooltip}
            sections={[
                {
                    options: [
                        {
                            label: (
                                <ReviewCardMenu.Label as="div">{t('modify')}</ReviewCardMenu.Label>
                            ),
                            name: 'modify',
                            value: 'edit',
                        },
                        ...(canDelete
                            ? [
                                  {
                                      label: (
                                          <ReviewCardMenu.Label as="div">
                                              {t('delete')}
                                          </ReviewCardMenu.Label>
                                      ),
                                      name: 'delete',
                                      value: 'delete',
                                  },
                              ]
                            : []),
                    ],
                },
            ]}
            onOptionClick={onClick}
        />
    );
}
