import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const MessageDateContainer = styled.div`
    margin: 8px 0;
    text-align: center;
`;

export const MessageDateText = styled(Text)`
    display: inline;
    margin-right: 2px;
    &:last-of-type {
        margin: 0;
    }
`;
