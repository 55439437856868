import { UseQueryOptions, useQuery } from 'react-query';

import { ReviewQueryParams, ReviewStatsResponse } from 'app/api/types/review';
import api from 'app/api/v2/api_calls';
import { REVIEWS_STATS } from 'app/common/data/queryKeysConstants';

export default function useReviewsStats(
    params: ReviewQueryParams = {},
    options?: UseQueryOptions<ReviewStatsResponse>,
) {
    return useQuery([REVIEWS_STATS, { params }], () => api.review.getReviewStats(params), options);
}
