import { Text, TextInput } from '@partoohub/ui';

import { Control, FieldValues, useController } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import {
    ConversationStarterItemFormTitleContainer,
    ConversationStarterItemFormTitleHeader,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/ConversationStarterItemFormTitle.styled';

import { CONVERSATION_STARTER_ITEM_TITLE_MAXIMUM_CHARACTERS } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';

type Props = {
    control: Control<FieldValues, any>;
};

const ConversationStarterItemFormTitle = ({ control }: Props) => {
    const { t } = useTranslation();

    const rules = {
        required: true,
        maxLength: CONVERSATION_STARTER_ITEM_TITLE_MAXIMUM_CHARACTERS,
    };

    const { field, formState } = useController({
        name: 'title',
        control,
        rules,
    });

    const hasErrorTitleRequired = formState.errors.title?.type === 'required';
    const hasErrorTitleLength = formState.errors.title?.type === 'maxLength';
    const hasError = !!(hasErrorTitleLength || hasErrorTitleRequired);
    const handleChange = (value: string) => {
        field.onChange(value?.slice(0, CONVERSATION_STARTER_ITEM_TITLE_MAXIMUM_CHARACTERS));
    };

    return (
        <ConversationStarterItemFormTitleContainer>
            <ConversationStarterItemFormTitleHeader>
                <Text variant="bodyMSemibold" color="default">
                    {t('conversation_starters_item_title_header')}
                </Text>
            </ConversationStarterItemFormTitleHeader>

            <TextInput
                {...field}
                dataTrackId="conversation_starters_item_title"
                label={t('conversation_starters_item_title')}
                required
                onChange={handleChange}
                characterCount={CONVERSATION_STARTER_ITEM_TITLE_MAXIMUM_CHARACTERS}
                notice={hasError ? t('conversation_starters_item_title_error') : ''}
                error={hasError}
            />
        </ConversationStarterItemFormTitleContainer>
    );
};

export default ConversationStarterItemFormTitle;
