import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ColorVariants } from '@partoohub/branding';
import { Icon } from '@partoohub/ui';

export const StyledIcon = styled(Icon)<{ color: ColorVariants | 'ratings' }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: ${({ theme, color }) => theme.colors[color].alpha};
    border-radius: 4px;
    ${({ color, theme }) =>
        color === 'ratings' &&
        css`
            color: ${theme.colors.ratings.initial};
        `};
`;
