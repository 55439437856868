import { useContext } from 'react';

import { isBefore } from 'date-fns';

import { CommentData } from 'app/api/types/comments';
import { useIsMobile } from 'app/common/services/screenDimensions';

import { useReviewDateFormatter } from 'app/reviewManagement/reviewList/hooks/ReviewCard/useReviewDateFormatter';

import { ReviewCardRatingContainer } from './ReviewCardRating.styled';

import { ReviewDate } from './ReviewDate';
import { ReviewRating } from './ReviewRating';
import { ReviewCardContext, ReviewCardContextType } from '../ReviewCard';

type Props = {
    comments: Array<CommentData>;
};

export default function ReviewCardRating({ comments }: Props) {
    const { review } = useContext<ReviewCardContextType>(ReviewCardContext);
    const modified = Math.abs(review.date - review.updateDate) > 60000;
    const isMobile = useIsMobile();

    const formatDate = useReviewDateFormatter();
    const formattedCreateDate = formatDate(review.date, {
        showHour: review.state !== 'deleted' ? !isMobile : false,
        showDay: !isMobile,
    });
    const highlightModifiedDate =
        modified &&
        review.state === 'not-treated' &&
        comments.every(comment => isBefore(comment.update_date ?? comment.date, review.updateDate));

    return (
        <ReviewCardRatingContainer>
            <ReviewRating />
            <ReviewDate
                // @ts-ignore
                modified={modified}
                date={
                    review.state === 'deleted' && review.deleteDate
                        ? review.deleteDate
                        : review.updateDate
                }
                highlight={highlightModifiedDate}
                formattedCreateDate={formattedCreateDate}
                state={review.state}
            />
        </ReviewCardRatingContainer>
    );
}
