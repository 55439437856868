import { useEffect, useState } from 'react';

import { ButtonVariants } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import api from 'app/api/v2/api_calls';
import { EmailInput } from 'app/common/components/form/EmailInput';
import {
    FormContainer,
    LoginFormButton,
    LoginFormContainer,
} from 'app/connection/components/LoginForm/LoginForms.styled';
import LoginLoadingView from 'app/connection/components/LoginForm/LoginLoadingView';

import { OverrideSubtitle } from 'app/connection/components/LoginSsoForm/LoginSsoForm.styled';

import ReturnToLoginFormLink from './ReturnToLoginFormLink.container';

type Props = {
    defaultEmail: string;
};

const getStatefulButtonProps = (email: string, error: string): [ButtonVariants, string] => {
    let status: ButtonVariants = 'primary';

    if (!email) {
        status = 'secondary';
    } else if (error) {
        status = 'danger';
    }

    return [status, 'connection_to_app'];
};

const LoginSsoForm = ({ defaultEmail }: Props) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setEmail(defaultEmail);
    }, []);
    const [variant, label] = getStatefulButtonProps(email, error);

    const onEmailChange = (newEmail: string) => {
        setError('');
        setEmail(newEmail);
    };

    const ssoLogin = () => {
        setIsLoading(true);
        api.user
            .getSsoInfo(email)
            .then(data => {
                const [ssoType, ssoAlias] = [data.sso_type, data.sso_alias];

                if (!ssoType || !ssoAlias) {
                    setError(t('sso_sign_in_error'));
                    setIsLoading(false);
                } else {
                    window.location = api.connection.generateSsoLoginUrl(ssoType, ssoAlias) as any;
                }
            })
            .catch(() => {
                setError(t('email_invalid'));
                setIsLoading(false);
            });
    };

    if (isLoading) {
        return <LoginLoadingView />;
    }

    return (
        <LoginFormContainer>
            <OverrideSubtitle variant="heading6" color="rgb(51, 51, 51)">
                {t('sso_sign_in_title')}
            </OverrideSubtitle>
            <FormContainer onSubmit={ssoLogin}>
                <EmailInput value={email} onChange={onEmailChange} error={!!error} notice={error} />
                <LoginFormButton
                    variant={variant}
                    shape="cube"
                    size="large"
                    full
                    onClick={ssoLogin}
                    dataTrackId="sso_sign_in_button"
                    type="submit"
                    disabled={variant === 'secondary'}
                >
                    {t(label)}
                </LoginFormButton>
            </FormContainer>
            <ReturnToLoginFormLink />
        </LoginFormContainer>
    );
};

export default LoginSsoForm;
