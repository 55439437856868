export const USAGE = 'usage';
export const RECENT = 'recent';

export type SortingOptions = typeof RECENT | typeof USAGE;

export type MessageReplyTemplateData = {
    title: string;
    icon?: string;
    content: string;
};

export type CreateMessageReplyTemplateResponse = {};

export type MessageReplyTemplate = {
    id: string;
    title: string;
    content: string;
    icon: string;
    usageCount: number;
};

export type MessageReplyTemplatePlaceholder = {
    id: string;
    display: string;
};

export const messageReplyTemplatePlaceholders: Array<MessageReplyTemplatePlaceholder> =
    [
        { id: 'client_full_name', display: 'client_full_name' },
        { id: 'client_first_name', display: 'client_first_name' },
        { id: 'business_name', display: 'business_name' },
        { id: 'my_first_name', display: 'my_first_name' },
        { id: 'rb_link', display: 'placeholder_rb_link' },
        { id: 'address', display: 'placeholder_address' },
        { id: 'website_url', display: 'placeholder_business_website_url' },
        { id: 'phone_number', display: 'placeholder_phone_number' },
    ];
