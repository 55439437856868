import { NumberInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

type Props = {
    params: Record<string, any>;
    updateParams: (typeParams: Record<string, any>) => void;
    error: Record<string, any>;
};

export const FormTypeText = ({ params, updateParams, error }: Props) => {
    const { t } = useTranslation();

    const onChange = (value: string, constraint: string) => {
        updateParams({ ...params, [constraint]: value });
    };

    return (
        <NumberInput
            dataTrackId="maximum_length"
            label={t('maximum_length')}
            value={params.max_len ?? ' '}
            onChange={value => onChange(value ?? '', 'max_len')}
            error={error.max_len}
            min={1}
            notice={error.max_len ? t('custom_fields_text_max_len_error') : ''}
        />
    );
};
