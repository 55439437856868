import { V2FormattedBusinessData } from 'app/api/types/business';

const groupIdFilter = (
    businesses: Array<V2FormattedBusinessData>,
    candidates: Array<any> | null,
): Array<V2FormattedBusinessData> =>
    businesses.filter(
        ({ groupId }) => candidates && candidates.includes(groupId),
    );

export default groupIdFilter;
