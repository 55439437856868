import React from 'react';

import { Navigate } from 'react-router-dom';

import { NOT_FOUND_PATH } from 'app/routing/routeIds';

interface Props {
    userRole: string | null;
    excludeRoles?: string[];
    children: any;
}

export const ProtectedRoute = ({ userRole, excludeRoles, children }: Props) => {
    if (excludeRoles && userRole && excludeRoles.includes(userRole)) {
        return <Navigate to={NOT_FOUND_PATH} />;
    }
    return children;
};
