import { MetaRelogin } from 'app/pages/visibility/subComponents/MetaRelogin/MetaRelogin';

import { InstagramInsightsWarningBanner } from './InstagramInsightsWarningBanner';
import { PermissionDeniedBanner } from './PermissionDeniedBanner';

export const PostListBanners = () => {
    return (
        <>
            <InstagramInsightsWarningBanner />
            <PermissionDeniedBanner />
            <MetaRelogin />
        </>
    );
};
