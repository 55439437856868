import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import useSelectedBusinessesTitle from 'app/common/components/businessModal/hooks/useSelectedBusinessesTitle';
import MenuIcon from 'app/common/components/icons/MenuIcon';
import MobilePageHeader from 'app/common/components/pageHeaders/MobilePageHeader';
import MobilePageSubHeader from 'app/common/components/pageHeaders/MobilePageSubHeader';
import { collapseSidebar } from 'app/common/reducers/global';

export const MobileReviewManagementHeader = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const openSidebar = useCallback(() => dispatch(collapseSidebar()), []);
    const { title, subtitle } = useSelectedBusinessesTitle();

    return (
        <>
            <MobilePageHeader
                leftContent={<MenuIcon onClick={openSidebar} />}
                title={title}
                subtitle={subtitle}
                hideLeftContentOnIframe
            />
            <MobilePageSubHeader title={t('review_analytics')} />
        </>
    );
};
