export const PRIMARY_PHOTO = 'primary';
export const LOGO_PHOTO = 'LOGO';
export const EXTERIOR_PHOTO = 'EXTERIOR';
export const INTERIOR_PHOTO = 'INTERIOR';
export const PRODUCT_PHOTO = 'PRODUCT';
export const AT_WORK_PHOTO = 'AT_WORK';
export const FOOD_AND_DRINK_PHOTO = 'FOOD_AND_DRINK';
export const MENU_PHOTO = 'MENU';
export const COMMON_AREAS_PHOTO = 'COMMON_AREAS';
export const ROOMS_PHOTO = 'ROOMS';
export const TEAM_PHOTO = 'TEAM';
export const SECONDARY_PHOTO = 'secondary';
