import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { useKeywordsSearchQueryParams } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/useKeywordsSearchQueryParams';
import { MetricsAttributesResponse } from 'app/pages/analytics/VisibilityAnalyticsV2/types/metrics';
import { Period } from 'app/pages/analytics/VisibilityAnalyticsV2/types/period';
import { MetricsApiKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/types/query';
import {
    URL_MONTH_FORMAT,
    calculateRangeDate,
} from 'app/pages/analytics/VisibilityAnalyticsV2/utils/date';
import { AnalyticsQueryKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queryKeys';

export const useKeywordsQueryCount = () => {
    const query = useKeywordsSearchQueryParams();
    const queryCurrent = {
        ...useBusinessModalFilters(),
        [MetricsApiKeys.FILTER_DATE_GTE]: query.filter_date__gte,
        [MetricsApiKeys.FILTER_DATE_LTE]: query.filter_date__lte,
        [MetricsApiKeys.METRICS]: MetricsAttributesResponse.COUNT,
        [MetricsApiKeys.DIMENSIONS]: 'month',
    };

    const [comparison] =
        useStateQueryParams(AnalyticsQueryKeys.COMPARISON) || Period.PERIOD;

    const dateRange = calculateRangeDate(
        comparison,
        queryCurrent.filter_date__gte,
        queryCurrent.filter_date__lte,
        URL_MONTH_FORMAT,
    );

    const queryPrevious = {
        ...queryCurrent,
        [MetricsApiKeys.FILTER_DATE_GTE]: dateRange?.dateFrom,
        [MetricsApiKeys.FILTER_DATE_LTE]: dateRange?.dateTo,
    };

    return {
        queryCurrent,
        queryPrevious,
    };
};
