import { Button, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { compose } from 'redux';

import getLang from 'app/common/services/getLang';
import { useIsTablet } from 'app/hooks/useIsTablet';
import { hideableComponentsNames } from 'app/SDKBridge';
import { hideableComponent } from 'app/SDKBridge/hideableComponent';

import { OverrideSearchIcon } from './DisplayedButton.styled';
import useSelectedBusinessesCount from '../../hooks/useSelectedBusinessesCount';
import useTotalBusinessesCount from '../../hooks/useTotalBusinessesCount';

type Props = {
    handleClick: () => void;
    isSelectionMode?: boolean;
};

const DisplayedButton = ({ handleClick, isSelectionMode }: Props) => {
    const { t } = useTranslation();
    const isTablet = useIsTablet();
    const lang = getLang();

    // Number of business selected in the business modal
    const businessCount = useSelectedBusinessesCount();

    // Number of businesses to display in the button
    const total = useTotalBusinessesCount();
    const totalText = total
        ? `${t('filter_by_business')} ( ${Number(total).toLocaleString(lang)} )`
        : t('filter_by_business');
    const text =
        (businessCount && businessCount >= 1) || isSelectionMode
            ? t('business_modal_button_business_count_label', {
                  count: businessCount ?? 0,
              })
            : totalText;

    return (
        <>
            {isTablet ? (
                <OverrideSearchIcon onClick={handleClick} />
            ) : (
                <Button
                    dataTrackId="open_business_modal_button"
                    type="button"
                    variant="secondary"
                    appearance="outlined"
                    shape="cube"
                    onClick={handleClick}
                    icon={[FontAwesomeIconsPartooUsed.faCaretDown, IconPrefix.SOLID]}
                    iconPosition="right"
                >
                    {text}
                </Button>
            )}
        </>
    );
};

export default compose(
    hideableComponent(hideableComponentsNames.SHOW_BUSINESS_MODAL_FILTERS_BUTTON),
)(DisplayedButton);
