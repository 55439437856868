import { useTranslation } from 'react-i18next';

import { RightPart } from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/BusinessModalContent.styled';
import Header from 'app/common/components/businessModal/components/BusinessModal/BusinessModalContent/Header';

import OldGroupsFooter from './OldGroupsFooter';
import OldGroupsList from './OldGroupsList';

type Props = {
    closeModal: () => void;
    erase: () => void;
};

const BusinessOldGroupsContent = ({ closeModal, erase }: Props) => {
    const { t } = useTranslation();

    return (
        <RightPart>
            <Header title={t('business_drawer_groups')} />
            <OldGroupsList />
            <OldGroupsFooter closeModal={closeModal} erase={erase} />
        </RightPart>
    );
};

export default BusinessOldGroupsContent;
