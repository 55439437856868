import { format, set } from 'date-fns';

import { CreatePostBody } from 'app/api/types/post';
import Image from 'app/api/v2/api_calls/imageApiCalls';
import formatImagesForApi from 'app/businessEditV2/oldFromEditV1/services/formatImagesForAPI';

import { formatTZDate } from 'app/utils/dates';

import {
    ALERT_TYPE,
    COVID_TYPE,
    EVENT_TYPE,
    NEWS_TYPE,
    OFFER_TYPE,
} from '../variables/googlePostType';

function handleLink(formState): Record<string, boolean | string> {
    if (formState.content_link === 'use_business_url') {
        return {
            use_business_url: true,
        };
    } else {
        return {
            custom_url: formState.custom_link, // in single platform (google) this state is in global content screen
        };
    }
}

export function createPayloadNews(formFields, formState) {
    const newsPayload: any = {};
    const postHasCtaType = formFields.buttonTypeGoogle || formState.google_custom_link;

    const isPostMultiPlatform = formFields.platform.length > 1;

    const postHasLink =
        formState.content_link === 'custom_link' || formState.content_link === 'use_business_url';

    newsPayload.summary = formState.post_description;

    if (postHasCtaType) {
        if (isPostMultiPlatform) {
            newsPayload.cta_type = formFields?.buttonTypeGoogle; // in multiplatform this state is in custom fields screen
        } else {
            newsPayload.cta_type = formState?.google_custom_link;
        }
    }

    if (postHasLink) {
        newsPayload.link = handleLink(formState);
    }

    return newsPayload;
}

export function createPayloadEvent(formFields, formState) {
    const eventPayload: any = {};

    const postHasLink =
        formState.content_link === 'custom_link' || formState.content_link === 'use_business_url';

    const isPostMultiPlatform = formFields.platform.length > 1;

    const postHasCtaType = formFields.buttonTypeGoogle || formState.google_custom_link;

    eventPayload.summary = formState.post_description;

    eventPayload.title = formState.event_title;

    eventPayload.start_at = formatTZDate(formState.event_start_date, formState.event_start_time);
    eventPayload.end_at = formatTZDate(formState.event_end_date, formState.event_end_time);

    if (postHasLink) {
        eventPayload.link = handleLink(formState);
    }

    if (postHasCtaType) {
        if (isPostMultiPlatform) {
            eventPayload.cta_type = formFields?.buttonTypeGoogle; // in multiplatform this state is in custom fields screen
        } else {
            eventPayload.cta_type = formState?.google_custom_link;
        }
    }

    return eventPayload;
}

export function createPayloadOffer(_formFields, formState) {
    const offerPayload: any = {};

    const postHasLink =
        formState.content_link === 'custom_link' || formState.content_link === 'use_business_url';

    offerPayload.summary = formState.post_description;
    offerPayload.title = formState.event_title;
    offerPayload.offer_terms = formState.event_terms_and_conditions;
    offerPayload.coupon_code = formState.offer_coupon_code;

    offerPayload.start_at = formatTZDate(formState.event_start_date, formState.event_start_time);
    offerPayload.end_at = formatTZDate(formState.event_end_date, formState.event_end_time);

    if (postHasLink) {
        offerPayload.link = handleLink(formState);
    }

    return offerPayload;
}

export function createPayloadAlert(_formFields, formState) {
    const alertPayload: any = {};
    const postHasCtaType = formState.google_custom_link;

    const postHasLink =
        formState.content_link === 'custom_link' || formState.content_link === 'use_business_url';

    alertPayload.summary = formState.post_description;

    if (postHasCtaType) {
        alertPayload.cta_type = formState?.google_custom_link;
    }

    if (postHasLink) {
        alertPayload.link = handleLink(formState);
    }

    return alertPayload;
}

export async function calculateDataPayloadCreatePost(formFields, formState) {
    /**
     * @param formFields<FormFields> stored in a local React Reducer
     * @param formState<News|Event> stored by react-hook-form switchable depending on selected post type
     * @returns dataPayload
     * Any state to be processed and merged before post creation should be done here
     * Since we keep 2 different states (react and react-form), we proceed to merge them depending on business requiriments
     * In calculateDataPayloadCreatePost calculate payload related to non-react-form screen
     */

    let dataPayload: CreatePostBody =
        formFields.type === ALERT_TYPE // temp fix while we find a way to standardize covid & alert naming type
            ? { post_type: COVID_TYPE }
            : { post_type: formFields.type };

    const postHasImages = formFields.postImage.length > 0;

    if (postHasImages) {
        const imagesToUpload: Array<any> = [];

        const imageUrls = formFields.postImage.map(postImage => {
            const { protocol: imageProtocol } = new URL(postImage?.data_url);
            const hasUploadedImage = imageProtocol === 'http:' || imageProtocol === 'https:';

            if (hasUploadedImage) {
                return postImage.data_url;
            }
            // upload image when no image URL provided
            imagesToUpload.push(postImage.file);
        });
        if (imagesToUpload.length) {
            const imagesData = formatImagesForApi(imagesToUpload);
            try {
                const { result } = await Image.uploadImage(imagesData);
                dataPayload.image_url = imageUrls.map(
                    imageUrl => imageUrl ?? result.uploads.shift(),
                );
            } catch (err) {
                console.warn('Error uploading post image');
            }
        } else {
            dataPayload.image_url = imageUrls;
        }
    }

    if (formFields.isScheduled) {
        const hours = formFields.scheduledTime.split(':')[0];
        const minutes = formFields.scheduledTime.split(':')[1];

        const date = set(formFields.scheduledDate, {
            hours,
            minutes,
        });
        dataPayload.schedule_time = format(date, 'yyyy-MM-dd\'T\'HH:mm:ssxxx');
    }

    switch (formFields.type) {
        case NEWS_TYPE:
            dataPayload = {
                ...dataPayload,
                ...createPayloadNews(formFields, formState),
            };

            break;
        case EVENT_TYPE:
            dataPayload = {
                ...dataPayload,
                ...createPayloadEvent(formFields, formState),
            };
            break;
        case OFFER_TYPE:
            dataPayload = {
                ...dataPayload,
                ...createPayloadOffer(formFields, formState),
            };
            break;

        case ALERT_TYPE:
        case COVID_TYPE:
            dataPayload = {
                ...dataPayload,
                ...createPayloadAlert(formFields, formState),
            };
            break;
        default:
            console.warn('Invalid news type selected for calculate payload');
    }

    return dataPayload;
}
