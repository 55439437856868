import styled from '@emotion/styled';
import { convertToRgba, greyColorObject } from '@partoohub/branding';

import {
    PillBody as BasePillBody,
    PillCross as BasePillCross,
} from 'app/common/designSystem/components/molecules/AsyncSubMenuMultipleSelect/components/SearchBox.styled';
import {
    ButtonWithMenuContainer as BaseButtonMenuContainer,
    ButtonWithMenuWrapper as BaseButtonWithMenuWrapper,
} from 'app/common/designSystem/components/molecules/ButtonWithSearchMenuFilterSelection/ButtonWithSearchMenuFilterSelection.styled';
import { TagMenuItemWrapper } from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/TagSection/AddTagButton.styled';

export const ColorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    i {
        color: white;
        font-size: 7px;
    }
    ${({ color }) => `background-color: ${color}`}
`;

export const ButtonWithMenuWrapper = styled(BaseButtonWithMenuWrapper)`
    align-self: flex-start;
`;

export const ButtonWithMenuContainer = styled(BaseButtonMenuContainer)`
    min-height: auto;

    ${TagMenuItemWrapper} {
        .fa-pen-clip {
            color: ${({ theme }) => theme.colors.secondary.initial};
            visibility: hidden;
        }

        &.selected {
            background-color: ${({ theme }) => convertToRgba(theme.colors.primary.initial, 0.08)};
            font-weight: bold;
            color: ${({ theme }) => theme.colors.primary.initial};

            &:hover {
                background-color: ${({ theme }) => theme.colors.primary.alpha};
            }

            .fa-pen-clip {
                color: ${({ theme }) => theme.colors.primary.initial};
            }
        }

        &:hover {
            .fa-pen-clip {
                visibility: initial;

                &:hover {
                    zoom: 1.2;
                }
            }
        }
    }
`;

export const PillCross = styled(BasePillCross)`
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin-left: 5px;
    display: block;
    cursor: pointer;
`;

export const PillBody = styled(BasePillBody)`
    background-color: white;
    font-weight: 600;
    border: 1px solid ${greyColorObject.initial};
    color: ${({ theme }) => theme.colors.secondary.initial};
    cursor: default;
    margin: unset;
`;

export const CreateTagPillBody = styled(PillBody)`
    display: grid;
    cursor: pointer;
    margin-left: 4px;
`;

export const PillColor = styled(ColorContainer)`
    width: 11px;
    height: 11px;
    margin-right: 5px;
`;

export const TagListContainer = styled.div`
    display: flex;
    justify-content: right;
    align-self: flex-start;
    align-items: flex-end;
    grid-area: tag;
    gap: 5px;

    ${BasePillBody} {
        margin: 0;
    }
`;

export const TagListSection = styled.div`
    display: flex;
    justify-content: right;
    align-items: flex-end;
    gap: 5px;
    flex-wrap: wrap;
`;
