import React from 'react';

import { IconButton, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { GROUP_MANAGER, ORG_ADMIN, PROVIDER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useUsersStats from 'app/common/hooks/queries/useUsersStats';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { useCanCreateUser } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/hooks/useCanCreateUser';
import useUserManagementPageTitle from 'app/pages/settingsV2/subPages/Team/components/UserManagement/hooks/useUserManagementPageTitle';
import { UserListQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

import AddUserButton from './AddUserButton';

const UserManagementHeader = () => {
    const { t } = useTranslation();
    const { data: me } = useMe();
    const { data: userStats } = useUsersStats();
    const [, setShowReinviteModal] = useStateQueryParams(UserListQueryKeys.USER_INVITE);

    const title = useUserManagementPageTitle();

    const isAllowedToInvite =
        [ORG_ADMIN, PROVIDER, GROUP_MANAGER].includes(me?.role ?? '') &&
        userStats &&
        userStats.invited_count > 0;

    const isAllowedToCreate = useCanCreateUser();

    const actions = [
        ...(isAllowedToInvite
            ? [
                  <IconButton
                      appearance="outlined"
                      variant="primary"
                      onClick={() => {
                          setShowReinviteModal('all');
                      }}
                      icon={['fa-sync-alt', IconPrefix.SOLID]}
                      tooltip={t('re_invite_users__tooltip')}
                      key="reinvite_all"
                      dataTrackId="reinvite_all_button"
                  />,
              ]
            : []),
        ...(isAllowedToCreate ? [<AddUserButton key="add_user" />] : []),
    ];

    return <DefaultMenuListHeader title={title} actionsButton={actions} />;
};

export default UserManagementHeader;
