import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Stack } from '@partoohub/ui';

export const NotFoundTemplateContainer = styled(Stack)<{ isNotFoundTemplate?: boolean }>`
    margin: auto;
    height: 100%;
    padding: 30px;
`;

export const StyledButton = styled(Button)`
    margin-top: 16px;
`;

const containerStyles = css`
    margin-bottom: 24px;

    img {
        height: 100%;
        width: 100%;

        @media (min-width: 768px) {
            min-width: 500px;
        }
    }
`;

const smallContainerStyles = css`
    img {
        max-height: 122px;
        max-width: 280px;
    }
`;

export const NoResultImageContainer = styled.div<{ small?: boolean }>`
    ${({ small }) => (small ? smallContainerStyles : containerStyles)};
`;
