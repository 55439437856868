import { FontAwesomeIconsPartooUsed, Icon, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import BusinessModalButton from 'app/common/components/businessModal';
import { ADMIN } from 'app/common/data/roles';

import useMe from 'app/common/hooks/queries/useMe';
import { DIFFUSION_SEARCH_PARAMS } from 'app/pages/visibility/Diffusion/DiffusionList';

import { IconContainer } from './DiffusionHeader.styled';
import ExportDataButton from './ExportDataButton/ExportDataButton';

export const DiffusionHeader = () => {
    const { t } = useTranslation();
    const meRole = useMe()?.data?.role;

    const pageButtons =
        meRole === ADMIN
            ? [
                  <BusinessModalButton
                      key="business-modal-key"
                      searchFilters={DIFFUSION_SEARCH_PARAMS}
                  />,
              ]
            : [
                  <ExportDataButton key="--download-reviews-button-key--" />,
                  <BusinessModalButton
                      key="business-modal-key"
                      searchFilters={DIFFUSION_SEARCH_PARAMS}
                  />,
              ];

    return (
        <Stack
            gap="8px"
            title={t('listing')}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
        >
            <Stack gap="8px" direction="row" alignItems="center">
                <IconContainer justifyContent="center" alignItems="center">
                    <Icon
                        icon={[FontAwesomeIconsPartooUsed.faEye]}
                        color="primary"
                        iconSize="12px"
                    />
                </IconContainer>
                <Text variant="heading6" as="span">
                    {t('listing')}
                </Text>
            </Stack>
            <Stack gap="8px" direction="row" alignItems="center">
                {pageButtons}
            </Stack>
        </Stack>
    );
};
