import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { useIsMobile } from 'app/common/services/screenDimensions';

import { resizeImage } from 'app/utils/resizeImage';

import { PartnerLinkImage } from './PartnerLinkButton.styled';
import {
    ProductButtonContainer,
    ProductButtonHover,
    ProductButtonTooltip,
} from '../ProductButton.styled';

type Props = {
    link_url: string;
    logo_url: string;
    tooltip: string;
};

const PartnerLinkButton = ({ link_url, logo_url, tooltip }: Props) => {
    const isMobile = useIsMobile();
    const content = (
        <ProductButtonContainer
            data-cy="product-sidebar-partner-link-button"
            data-track="sidebar__button__partner_link"
            data-intercom-target="sidebar__button__partner_link"
        >
            <ProductButtonHover>
                <a href={link_url} target="_blank" rel="noreferrer">
                    <PartnerLinkImage
                        src={resizeImage(logo_url, 100, 100)}
                        height={32}
                        width={32}
                    />
                </a>
            </ProductButtonHover>
        </ProductButtonContainer>
    );

    if (!isMobile) {
        return (
            <TooltipWrapper tooltip={ProductButtonTooltip} text={tooltip} position="right">
                {content}
            </TooltipWrapper>
        );
    }

    return content;
};

export default PartnerLinkButton;
