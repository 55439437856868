import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { createMarker, setPosition } from 'app/common/components/form/GoogleMaps/GoogleMapsMap';
import { useWaitForGoogleScripts } from 'app/common/hooks/useWaitForGoogleScript';

import { GoogleMapsContent, GoogleMapsView } from './GoogleMaps.styled';
import GoogleMapsPlaceHolder from './GoogleMapsPlaceHolder';

type Props = {
    lat: number | null;
    long: number | null;
    onDrag: (lat: number, long: number) => void;
    disabled?: boolean;
};

const RADIUS_LAT_LNG_SEARCH = 200;

const DEGREE_PER_RADIAN = 180 / Math.PI;

const GoogleMaps = ({ lat, long, onDrag, disabled = false }: Props) => {
    // This Component needs the Google Maps JavaScript API to work

    const { t } = useTranslation();

    // Ref used for google maps
    const streetView = useRef<google.maps.StreetViewPanorama>();
    const streetViewMarker = useRef<google.maps.Marker>();
    const streetViewService = useRef<google.maps.StreetViewService>();

    const streetRef = useRef<HTMLDivElement>(null);

    // Placeholders
    const [isStreetViewOK, setIsStreetViewOK] = useState<boolean>(true);

    const computeAngle = (endLatLng, startLatLng) => {
        // Compute the angle so that the street view panorama looks toward the pin

        const dlat = endLatLng.lat() - startLatLng.lat();
        const dlng = endLatLng.lng() - startLatLng.lng();

        const angle =
            Math.atan2(dlng * Math.cos(endLatLng.lat() / DEGREE_PER_RADIAN), dlat) *
            DEGREE_PER_RADIAN;
        // Modulo
        return ((angle % 360) + 360) % 360;
    };

    const updateStreetViewPanorama = latLng => {
        // Update the position and the camera orientation of the street view
        // Search for the nearest position available in a radius of RADIUS_LAT_LNG_SEARCH
        if (window?.google?.maps && streetViewService.current) {
            streetViewService.current.getPanorama(
                {
                    location: latLng,
                    radius: RADIUS_LAT_LNG_SEARCH,
                },
                (panoData, status) => {
                    if (
                        status === google.maps.StreetViewStatus.OK &&
                        panoData &&
                        panoData.location
                    ) {
                        const pov = {
                            heading: computeAngle(latLng, panoData.location.latLng),
                            pitch: 0,
                        };
                        setPosition(streetView, panoData.location.latLng, pov);
                        setIsStreetViewOK(true);
                    } else {
                        setIsStreetViewOK(false);
                    }
                },
            );
        }
    };

    const handleScriptLoad = () => {
        if (streetRef.current) {
            const latLng = new google.maps.LatLng(lat!, long!);
            streetViewService.current = new google.maps.StreetViewService();

            // Create street view
            streetView.current = new google.maps.StreetViewPanorama(streetRef.current, {
                addressControl: false,
                linksControl: false,
                enableCloseButton: false,
            });

            // Create markers (red pins)
            createMarker(streetViewMarker, streetView, latLng, onDrag, disabled);

            updateStreetViewPanorama(latLng);
        }
    };

    useWaitForGoogleScripts(handleScriptLoad);

    useEffect(() => {
        if (streetView.current && streetViewMarker.current) {
            const latLng = new google.maps.LatLng(lat!, long!);
            setPosition(streetViewMarker, latLng);
            updateStreetViewPanorama(latLng);
        }
    }, [lat, long]);

    return (
        <GoogleMapsContent>
            {!isStreetViewOK && (
                <GoogleMapsPlaceHolder
                    icon={<i className="fa-solid fa-map-marker-alt" />}
                    message={t('street_view_unavailable')}
                />
            )}
            <GoogleMapsView ref={streetRef} />
        </GoogleMapsContent>
    );
};

export default GoogleMaps;
