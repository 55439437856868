import { useMemo } from 'react';

import { InfiniteData, useQueries } from 'react-query';

import { FuzzySearchLocationsReceivedData, V2LocationData } from 'app/api/types/account';
import { SearchBusinessesReceivedData } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import { BUSINESS_LOCATIONS } from 'app/common/data/queryKeysConstants';
import { GOOGLE_MY_BUSINESS } from 'app/states/knowledge/reducers/businessConnection/accounts';

interface FuzzySearchGoogleLocationQueryResult {
    queryKey: (
        | string
        | {
              google_location_id__in: (string | null)[] | undefined;
          }
    )[];
    queryFn: () => Promise<FuzzySearchLocationsReceivedData>;
}

export default function useLocationsFromBusinesses(
    businessesData: InfiniteData<SearchBusinessesReceivedData> | undefined,
) {
    const getFuzzySearchGoogleLocationsQuery = (
        google_location_id__in?: Array<string | null>,
    ): FuzzySearchGoogleLocationQueryResult => ({
        queryKey: [
            'BUSINESS_LIST',
            BUSINESS_LOCATIONS,
            GOOGLE_MY_BUSINESS,
            { google_location_id__in },
        ],
        queryFn: () =>
            api.account.fuzzySearchLocations(
                'gmb',
                '',
                1,
                undefined,
                undefined,
                google_location_id__in,
            ),
    });

    const queries = useMemo(() => {
        const qs: FuzzySearchGoogleLocationQueryResult[] = [];
        businessesData?.pages.forEach(page => {
            const googleLocationIds: string[] = [];

            page.businesses.forEach(business => {
                if (business.google_location_id)
                    googleLocationIds.push(business.google_location_id);
            });

            if (googleLocationIds.length > 0)
                qs.push(getFuzzySearchGoogleLocationsQuery(googleLocationIds));
        });
        return qs;
    }, [businessesData?.pages]);

    const locationsResults = useQueries(queries ?? []);

    const locations = useMemo(() => {
        const locs: { [id: string]: V2LocationData } = {};
        locationsResults.forEach(result => {
            if (!result?.data?.locations) return;

            result.data?.locations.forEach(location => (locs[location.id] = location));
        });
        return locs;
    }, [locationsResults]);

    return locations;
}
