import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { BUSINESS_EDIT_BREAKPOINT } from 'app/businessEditV2/BusinessEditV2.styled';

export const HolidaysSuggestionCollapsibleContainer = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.default.alpha};
    padding: 24px;
`;

type HolidaysGridProps = {
    collapsed: boolean;
};

export const HolidaysGrid = styled.div<HolidaysGridProps>`
    display: flex;
    flex-direction: column;
    gap: 8px;

    transition: margin-top linear 0.2s;

    ${props =>
        !props.collapsed &&
        css`
            margin-top: 16px;
        `}

    @media (${BUSINESS_EDIT_BREAKPOINT}) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export const HolidaysGridSeeMore = styled.div`
    margin-top: 16px;
`;
