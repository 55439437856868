import { useContext, useState } from 'react';

import { IconPrefix } from '@partoohub/ui';

import { Text } from '@partoohub/ui';
import { upperFirst } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { CommentData } from 'app/api/types/comments';
import Clickable from 'app/common/components/buttons/Clickable';
import { PRIMARY } from 'app/common/designSystem/constants/color';
import { useShowCommentReplyActions } from 'app/reviewManagement/reviewList/hooks/ReviewCard';

import Flexbox from 'app/styles/utils/flexbox';

import {
    StyledButton,
    StyledComment,
    StyledCommentAnswerButton,
    StyledCommentBottomSpacing,
    StyledSecondaryLevelComments,
    StyledUserLogo,
} from './Comment.styled';
import CommentContent from './CommentContent';
import CommentIcon from './CommentIcon';
import { CommentTable } from './CommentTable';
import { ReviewCardContext, ReviewCardContextType } from '../../ReviewCard';

type Props = {
    parentId?: number;
    comment: CommentData;
    hasNoLink: boolean;
};

export function Comment({ parentId, comment = {} as CommentData, hasNoLink }: Props) {
    const { t } = useTranslation();
    const { review } = useContext<ReviewCardContextType>(ReviewCardContext);

    const [showReplies, setShowReplies] = useState(false);
    const repliesLength = comment?.replies?.length ?? 0;
    const visibleReplies = showReplies ? comment?.replies : [];
    const hideText = showReplies ? `${upperFirst(t('hide'))} ` : '';

    const { canEdit, canReply, canDelete } = useShowCommentReplyActions({ review, comment });

    const [isReplying, setIsReplying] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const handleToggle = () => setShowReplies(!showReplies);

    const handleReplyClick = () => {
        setIsReplying(true);
    };

    return (
        <StyledComment>
            <StyledUserLogo>
                <CommentIcon
                    hasLink={!hasNoLink}
                    reviewState={review.state}
                    authorName={comment?.author_name}
                    isReplySuggestion={comment?.is_reply_suggestion}
                />
            </StyledUserLogo>

            <Flexbox flex="1" flexDirection="column" alignSelf={'center'}>
                <CommentContent
                    // @ts-ignore
                    parentId={parentId}
                    canDelete={!!canDelete}
                    isEditing={isEditing}
                    setIsEditing={canEdit ? setIsEditing : undefined}
                    comment={comment}
                />
                {repliesLength ? (
                    <StyledButton
                        dataTrackId="comment_replies__button"
                        appearance="text"
                        variant="primary"
                        onClick={handleToggle}
                        icon={[
                            showReplies ? 'fa-chevron-up' : 'fa-reply fa-flip-both',
                            IconPrefix.SOLID,
                        ]}
                    >
                        {`${hideText}${comment?.replies.length} ${t(
                            repliesLength > 1 ? 'responses' : 'response',
                        ).toLowerCase()}`}
                    </StyledButton>
                ) : (
                    <StyledCommentBottomSpacing />
                )}
                {(!!repliesLength || isReplying) && (
                    <StyledSecondaryLevelComments>
                        <CommentTable
                            // @ts-ignore
                            comments={visibleReplies || []}
                            parentId={comment?.id}
                            isReplying={isReplying}
                            setIsReplying={setIsReplying}
                        />
                    </StyledSecondaryLevelComments>
                )}
                {!parentId && canReply && !isReplying && (
                    <StyledCommentAnswerButton>
                        <Clickable onClick={handleReplyClick}>
                            <Text as="span" variant={'bodyMBold'} color={PRIMARY}>
                                {t('reply')}
                            </Text>
                        </Clickable>
                    </StyledCommentAnswerButton>
                )}
            </Flexbox>
        </StyledComment>
    );
}
