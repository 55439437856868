import { WebsocketObject } from 'app/states/realtimeUpdates';

import * as constants from './constants';
import * as types from './types';

export const realtimeUpdateReviewReceivedAction = (
    review: WebsocketObject,
): types.RealtimeUpdateReviewReceivedType => ({
    type: constants.REALTIME_UPDATE_REVIEW_RECEIVED,
    review,
});

export const clearAllRealtimeReviewsAction = (): types.ClearAllRealtimeReviewsType => ({
    type: constants.CLEAR_ALL_REALTIME_REVIEWS,
});
