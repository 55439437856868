import { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { AssignFilterOption, StatusFilterOption, URLParams } from 'app/states/messaging';

import queryClient from 'app/states/queryClient';

import { getConversationsKey, useGetConversations } from './useGetConversations';

import { MessagingContext } from '../MessagingContext';

export const useHandleBusinessFiltersChange = () => {
    const { setActiveConversation } = useContext(MessagingContext);

    const [statusFilter] = useStateQueryParams<StatusFilterOption>(URLParams.STATUS);
    const [assignFilter] = useStateQueryParams<AssignFilterOption>(URLParams.ASSIGN);
    const businessFilters = useBusinessModalFilters();

    const navigate = useNavigate();
    const { isLoading } = useGetConversations();
    const [hasRendered, setHasRendered] = useState<boolean>(false);

    /* Triggers `useConversationSetup` upon business filters changes.
       `hasRendered` allows us to not remove conversationId from the URL at the beginning. 
       If we do, it will impact `useConversationDeeplink`. 
    */
    useEffect(() => {
        if (!hasRendered) {
            setHasRendered(true);
        } else {
            const conversationsKey = getConversationsKey(
                statusFilter,
                assignFilter,
                businessFilters,
            );

            if (!isLoading) queryClient.removeQueries(conversationsKey);

            navigate(
                {
                    search: '?conversationId=',
                },
                { replace: true },
            );
            setActiveConversation(undefined);
        }
    }, [businessFilters]);
};
