import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { SUPPORTED_LANGUAGES } from 'app/common/services/languages';
import { STATIC_BASE_URL } from 'app/config';

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        supportedLngs: SUPPORTED_LANGUAGES,
        ns: ['app'],
        defaultNS: 'app',
        returnEmptyString: false,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: `${STATIC_BASE_URL}/translations/{{lng}}/{{ns}}.json`,
            crossDomain: true,
        },
        debug: false,
        react: {
            useSuspense: false,
        },
    });

export default i18n;
