import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LoadingView from 'app/common/components/LoadingView';
import NotAccessibleForAdmin from 'app/common/components/placeholder/NotAccessibleForAdmin';
import { useCanAccessReviewsSetting } from 'app/pages/settingsV2/hooks/Reviews/useCanAccessReviewsSetting';
import { useSettingReviewsMenu } from 'app/pages/settingsV2/hooks/Reviews/useSettingReviewsMenu';

import { HeaderSubPages } from 'app/pages/settingsV2/subComponents';
import { SettingSkeleton } from 'app/pages/settingsV2/subComponents/SettingSkeleton';
import { Template } from 'app/pages/settingsV2/subComponents/Template/Template';
import { PageSettingsHeaderWithAvatar } from 'app/routing/components/PageSettingsHeaderWithAvatar';
import { SETTINGS_PATH } from 'app/routing/routeIds';

export const SettingReviews = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { menus } = useSettingReviewsMenu();
    const { canAccess, isLoading } = useCanAccessReviewsSetting();

    const handleCallback = () => {
        navigate(SETTINGS_PATH);
    };

    if (isLoading) {
        return <LoadingView />;
    }

    if (!canAccess) {
        return (
            <NotAccessibleForAdmin
                callback={handleCallback}
                buttonText={t('redirect_to_settings')}
            />
        );
    }

    return (
        <Template
            header={
                <PageSettingsHeaderWithAvatar
                    title={<HeaderSubPages>{t('settings_menu_reviews')}</HeaderSubPages>}
                />
            }
        >
            <SettingSkeleton sections={menus} />
        </Template>
    );
};
