import styled from '@emotion/styled';

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const AddressWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const EditEmailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const SpanExplanation = styled.div`
    font-size: 14px;
`;
