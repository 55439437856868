import { useMemo } from 'react';

import { Icon, LeftElementType, Option, SingleSelect } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { usePeriodFilter } from '../../hooks/usePeriodFilter';

export const ComparisonPeriodSelect = () => {
    const { t } = useTranslation();
    const [period, setPeriod] = usePeriodFilter();

    const buildSelectOption = (opt: string): Option => ({
        value: opt,
        name: t(`previous_${opt}`),
        label: t(`previous_${opt}`),
    });

    const selectedPeriod = useMemo(() => buildSelectOption(period), [period]);

    const handleChangePeriod = (option?: Option) => {
        setPeriod(option?.value ?? '');
    };

    const options: Option[] = [
        {
            value: 'month',
            name: t('previous_month'),
            label: t('previous_month'),
        },
        {
            value: 'semester',
            name: t('previous_semester'),
            label: t('previous_semester'),
        },
        {
            value: 'year',
            name: t('previous_year'),
            label: t('previous_year'),
        },
    ];

    return (
        <SingleSelect
            label={t('compare_period_button_title')}
            leftElement={<Icon icon={['fa-chart-simple']} iconSize="16px" color="secondary" />}
            leftElementType={LeftElementType.Icon}
            dataTrackId="page_competitive_benchmark__select_comparison_period"
            placeholder={t('comparison_filter')}
            selectedValue={selectedPeriod}
            maxHeight={150}
            onChange={handleChangePeriod}
            disableReset
            sections={[{ options }]}
        />
    );
};
