import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/de';
import 'dayjs/locale/ar';

dayjs.extend(LocalizedFormat);
dayjs.extend(customParseFormat);

export const formatMessageDate = (
    date: string,
    lang: string,
    t: ((text: string) => string) | null = null,
    showTodayIfSameDay = false,
) => {
    const messageLocalDateTime = getParsedDate(date, lang);

    if (messageLocalDateTime.isSame(dayjs(), 'day') && t && showTodayIfSameDay) {
        // received today
        return `${t('messaging_conversation_time_today')} `;
    }

    if (parseInt(messageLocalDateTime.format('YYYY'), 10) < dayjs().year()) {
        // Previous years
        const dayYearAndMonth = messageLocalDateTime.format('D MMMM YYYY');
        return `${dayYearAndMonth} `;
    }

    const dayAndMonth = messageLocalDateTime.format('D MMMM');
    return `${dayAndMonth} `;
};

export const formatMessageTime = (date: string, lang: string) => {
    const messageLocalDateTime = getParsedDate(date, lang);
    return messageLocalDateTime.format('LT');
};

const getParsedDate = (date: string, lang: string): dayjs.Dayjs => {
    const formattedDate = dayjs(date, 'YYYY-MM-DD HH:mm:ss.SSSSSSZ', lang);
    if (formattedDate.isValid()) return formattedDate;
    return dayjs(date, 'YYYY-MM-DD HH:mm:ssZ', lang);
};
