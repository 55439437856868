import { Modal } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import {
    CustomFieldsCreationKeys,
    CustomFieldsQueryKeys,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

import { CustomFieldsCreateSectionContent } from './CustomFieldsCreateSectionContent';

export const CustomFieldsCreateSectionModal = () => {
    const [createCustomFieldSection, setCreateCustomFieldSection] = useStateQueryParams(
        CustomFieldsQueryKeys.CREATE,
    );

    const closeModal = () => setCreateCustomFieldSection('');

    return (
        <Modal
            isOpen={createCustomFieldSection == CustomFieldsCreationKeys.SECTION}
            closeModal={closeModal}
        >
            <CustomFieldsCreateSectionContent closeModal={closeModal} />
        </Modal>
    );
};
