import { useEffect } from 'react';

import { useInfiniteQuery } from 'react-query';

import { QueryObserverOptions } from 'react-query/types/core/types';
import { UseInfiniteQueryResult } from 'react-query/types/react/types';

import { V2GroupData, V2PaginatedGroups } from 'app/api/types/groups';
import api from 'app/api/v2/api_calls';
import { GROUPS } from 'app/common/data/queryKeysConstants';

type Filters = {
    search?: string;
    org_id?: string;
};

export const useGroups = (
    filters: Filters = {},
    options: QueryObserverOptions = {},
): [
    UseInfiniteQueryResult<V2PaginatedGroups>,
    Array<V2GroupData> | undefined,
] => {
    const query = useInfiniteQuery(
        [GROUPS, filters],
        ({ pageParam = 1 }) =>
            api.groups.getGroups(
                pageParam,
                filters.search || null,
                filters.org_id || null,
            ),
        {
            retry: false,
            retryOnMount: false,
            getNextPageParam: prevPage =>
                prevPage.page < prevPage.max_page
                    ? prevPage.page + 1
                    : undefined,
            ...options,
        },
    );

    const groups = query.data?.pages?.map(({ groups }) => groups)?.flat();

    // flatten subgroups
    const groupsAndSubgroups = groups?.reduce<Array<V2GroupData>>(
        (acc, cur) => {
            acc.push(cur);
            if ('subgroups' in cur) {
                for (const subgroup of cur.subgroups as Array<V2GroupData>) {
                    // push subgroups after group
                    acc.push(subgroup);
                }
            }
            return acc;
        },
        [],
    );

    return [query, groupsAndSubgroups];
};

/**
 * A hook that fetches the whole infinite scroll of groups.
 * An org has at most ~100 groups so that shouldn't go through that many pages
 * (groups are created manually). In some cases we are forced to fetch all
 * of them, for example when we store a group id in the url and we want to
 * retrieve the group name.
 *
 * /!\ Disable this for admin users if you use it
 *
 * TODO: maybe store the group name in localstorage using react-query localstorage
 *       addon + /!\ make sure to add stale-time: 0 to the query in this case so that
 *       it gets re-fetched in the background when coming to the page
 */
export const useAllGroups = (): ReturnType<typeof useGroups> => {
    const [query, groups] = useGroups();

    useEffect(() => {
        if (query.hasNextPage) {
            query.fetchNextPage();
        }
    }, [groups]);

    return [query, groups];
};

export const formatGroupToChoice = (group: V2GroupData) => {
    return {
        value: group.id.toString(),
        label: group.name,
    };
};
