import { FontAwesomeIconsPartooUsed, LeftElementType, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

const GoogleVerificationListingSearch = () => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useStateQueryParams('query');

    const handleSearchReset = () => setSearchValue('');

    return (
        <TextInput
            dataTrackId="google_verification_search_by_input"
            value={searchValue}
            onChange={setSearchValue}
            label={t('google_verification_search_by')}
            leftElement={[FontAwesomeIconsPartooUsed.faSearch]}
            leftElementType={LeftElementType.Icon}
            isReset
            onReset={handleSearchReset}
        />
    );
};

export default GoogleVerificationListingSearch;
