import styled from '@emotion/styled';

import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { GetMaxWidthContentPage } from 'app/global.styled';

export const EditFormContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 40px 40px 0 40px;
    overflow: auto;
`;

export const EditFormBox = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    ${GetMaxWidthContentPage}
`;

export const EditFormHeader = styled.div`
    display: flex;
    margin-bottom: 32px;
    align-items: center;
`;

export const EditFormHeaderIcon = styled.div`
    > i,
    svg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        min-width: 56px;
        border-radius: 8px;
        overflow: hidden;
        margin-right: 16px;
        font-size: 26px;
        color: ${HEX_COLORS.blackberry};
        background-color: #f6f8fc;
    }
`;

export const EditFormBusiness = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const EditTitle = styled.div`
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    color: ${HEX_COLORS.blackberry};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const EditFormHeaderRight = styled.div`
    margin-left: auto;
`;

export const EditSubtitle = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const EditDescription = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: ${HEX_COLORS.blackberry};
    margin-bottom: 24px;
`;

export const EditFormFooter = styled.div`
    position: sticky;
    bottom: 0;
    background-color: ${HEX_COLORS.white};
    border-top: 1px solid ${greyColorObject.initial};

    // "auto" is to make sure footer is always at bottom even if the contents of the flex container doesn't take up 100% height
    margin-top: auto;
    padding: 16px;
`;
