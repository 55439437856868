import { Button, ButtonVariants } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    LoginState,
    emailErrorSelector,
    emailSelector,
    globalErrorSelector,
    hasErrors,
    hasGoogleError,
    isSubmittingSelector,
    isSuccessSelector,
    loginRequest,
    passwordErrorSelector,
    passwordSelector,
} from 'app/states/connection/reducers/login';
import { AppState } from 'app/states/reducers';

const LoginButton = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const login = useSelector((state: AppState) => state.login);

    const getStatusObj = (
        login: LoginState,
    ): {
        variant: ButtonVariants;
        label: string;
    } => {
        if (isSuccessSelector(login)) {
            return {
                variant: 'success',
                label: 'connection_to_app__success',
            };
        }

        if (isSubmittingSelector(login)) {
            return {
                variant: 'secondary',
                label: 'loading',
            };
        }

        if (passwordSelector(login) === '' || emailSelector(login) === '') {
            return {
                variant: 'secondary',
                label: 'connection_to_app',
            };
        }

        if (hasErrors(login) && !hasGoogleError(login)) {
            let label;
            if (emailErrorSelector(login)) {
                label = 'Email not valid';
            } else if (passwordErrorSelector(login)) {
                label = 'Password too short';
            } else if (globalErrorSelector(login) === 'admin_must_sign_in_with_google') {
                label = 'admin_must_sign_in_with_google';
            } else if (globalErrorSelector(login) === 'Too many login attempts') {
                label = 'too_many_login_attempts';
            } else {
                label = 'invalid credentials';
            }
            return {
                variant: 'danger',
                label,
            };
        }

        return {
            variant: 'primary',
            label: 'connection_to_app',
        };
    };

    const { variant, label } = getStatusObj(login);

    const onClick = () => {
        if (!['secondary', 'success'].includes(variant)) {
            dispatch(loginRequest());
        }
    };

    return (
        <Button
            type="submit"
            size="large"
            shape="cube"
            full
            variant={variant}
            dataTrackId="login_button"
            onClick={onClick}
            disabled={variant === 'secondary'}
        >
            {t(label)}
        </Button>
    );
};

export default LoginButton;
