import { useAppDataContext } from 'app/common/contexts/appData';
import MarginWrapper from 'app/common/designSystem/components/atoms/MarginWrapper';

import { ExportData } from './../ExportData/ExportData';
import { ImportData } from './../ImportData/ImportData';

export const BulkUpdateForm = () => {
    const { me, organization } = useAppDataContext();
    const orgId = organization?.org_id ?? null;
    const email = me?.email ?? null;

    return (
        <>
            {email && !!orgId && (
                <>
                    <MarginWrapper bottom="32px">
                        <ExportData email={email} />
                    </MarginWrapper>
                    <ImportData email={email} />
                </>
            )}
        </>
    );
};
