import { useIsMobile } from 'app/common/services/screenDimensions';

import { doughnutColors } from 'app/presence/management/constants/doughnutColors';
import { publisherStateStatsLabels } from 'app/presence/management/constants/publisherStateStatsLabels';

import { ChartDoughnutLegends, ChartDoughnutOverlay } from './DougnhutLegend.styled';
import { DoughnutLegendRow } from '../DoughnutLegendRow/DoughnutLegendRow';

type Props = {
    labelValues: Array<string>;
    isLoading: boolean;
};

export const DoughnutLegend = ({ labelValues, isLoading }: Props) => {
    const colors = Object.keys(doughnutColors);
    const isMobile = useIsMobile();

    const prioritizeGrowth = (component: any) =>
        isMobile ? component : <ChartDoughnutOverlay>{component}</ChartDoughnutOverlay>;

    return prioritizeGrowth(
        <ChartDoughnutLegends>
            {publisherStateStatsLabels.map((label, index) => (
                <DoughnutLegendRow
                    key={label + colors[index]}
                    label={label}
                    count={labelValues[index]}
                    isLoading={isLoading}
                />
            ))}
        </ChartDoughnutLegends>,
    );
};
