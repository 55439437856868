import { Navigate, useRoutes } from 'react-router-dom';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { GooglePostsListPage } from 'app/pages/visibility/GooglePostsList';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

export const PostsRoutes = () => {
    const { data: me } = useMe();
    const meRole = me ? me.role : null;

    const routes = useRoutes([
        {
            index: true,
            element: (
                <ProtectedRoute userRole={meRole}>
                    <GooglePostsListPage />
                </ProtectedRoute>
            ),
        },
        {
            path: '*',
            element: <Navigate replace to={NOT_FOUND_PATH} />,
        },
    ]);
    return routes;
};
