import { V2BusinessData } from 'app/api/types/business';
import { FoodMenuGetDataType } from 'app/api/types/food_menu';

export const businessHasNoMenu = (
    business?: V2BusinessData,
    businessFoodMenu?: FoodMenuGetDataType,
) => {
    const businessMenuPhoto = business?.photos.MENU ?? [];
    const businessMenus = businessFoodMenu?.menus ?? [];
    const hasItems =
        businessMenus.filter(menu => menu.items.length > 0).length > 0;

    return (
        businessMenuPhoto.length === 0 ||
        businessMenus.length === 0 ||
        !hasItems
    );
};
