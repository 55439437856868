import React from 'react';

import { useSettingsPageContext } from 'app/pages/settings/AutoReply/hooks/useSettingsPageContext';

import RatingButton from './RatingButton';
import { allRatingsOptions } from './RatingButton/RatingButton';
import { RatingsSelectStepContainer } from './RatingsSelectStep.styled';

const RatingsSelectStep = () => {
    const { selectedRatings, onRatingSelect } = useSettingsPageContext();

    return (
        <RatingsSelectStepContainer>
            {allRatingsOptions.map(option => (
                <RatingButton
                    key={option.value}
                    option={option}
                    selected={selectedRatings.some(
                        v => v.value === option.value,
                    )}
                    onClick={() => onRatingSelect(option)}
                />
            ))}
        </RatingsSelectStepContainer>
    );
};

export default RatingsSelectStep;
