import React from 'react';

const BannerImage = React.forwardRef<SVGSVGElement>(() => (
    <svg
        width="41"
        height="48"
        viewBox="0 0 41 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.9234 9.70985L18.7024 12.6862C18.7024 13.5881 19.2179 15.3919 21.28 15.3919C23.3421 15.3919 24.5812 14.2194 24.943 13.6332V11.3333C25.6213 11.3333 26.4353 10.3863 26.8423 9.70985C27.2493 9.03341 29.1486 4.97477 26.9779 2.40431C24.8073 -0.166161 21.8227 1.59258 19.9234 4.02776C18.4039 5.9759 19.2903 8.62754 19.9234 9.70985Z"
            fill="#EFCB96"
        />
        <path
            d="M3.1006 47.9963H16.1244H31.8618C34.3038 44.7494 35.9318 20.6682 34.4394 18.0977C32.0832 14.0391 27.3849 13.0921 25.2142 12.5509C24.8525 13.2274 23.8033 14.0391 21.9583 14.0391C20.1132 14.0391 18.9284 12.6862 18.5667 12.0098C15.9438 12.5058 10.0469 14.0661 7.44216 16.339C4.1862 19.18 1.74395 32.0324 0.794296 40.1496C0.0345733 46.6434 2.01528 48.0865 3.1006 47.9963Z"
            fill="#1C345C"
        />
        <path
            d="M13.8183 45.5612C11.105 44.4789 7.80386 38.7066 6.49243 35.9558C7.53253 39.8791 11.6477 47.352 14.768 46.7788C18.8922 46.0212 25.1689 37.3538 27.7918 33.1147C24.2645 37.7145 16.5316 46.6435 13.8183 45.5612Z"
            fill="#0C1628"
        />
        <path
            d="M18.8381 43.6671C15.9077 42.1518 12.1 42.5848 10.5625 42.9906C11.1504 44.118 12.7331 46.3187 14.3611 46.1022C15.9891 45.8858 18.0241 44.3886 18.8381 43.6671Z"
            fill="#0C1628"
        />
        <path
            d="M16.2604 30.8146L14.7681 26.2148L17.0744 30.1382C18.9195 29.597 21.687 28.2442 24.943 27.5677C21.9583 28.65 16.3509 31.8067 13.1401 33.6557L16.2604 30.8146Z"
            fill="#0C1628"
        />
        <path
            d="M26.7065 26.621C26.598 26.8375 26.0282 27.6131 25.7568 27.9739L27.6561 31.2208C27.8169 30.9953 28.2742 30.4632 28.8168 30.1385C29.4952 29.7326 31.5904 29.3268 31.5904 27.7033C31.5904 26.4045 30.686 25.3583 30.2338 24.9975C30.9573 24.2309 32.5401 22.9682 32.5401 22.5624C32.5401 22.2599 32.4044 21.7506 31.8618 21.8859C31.3191 22.0212 29.4198 24.1858 29.0128 24.3211C28.6058 24.4564 27.5205 24.3211 27.1135 24.5917C26.7065 24.8623 26.8422 26.3504 26.7065 26.621Z"
            fill="#EFCB96"
        />
        <path
            d="M21.8228 10.657C22.7995 11.4146 24.3099 11.4236 24.943 11.3334V12.2804C23.6407 12.2804 22.3202 11.1981 21.8228 10.657Z"
            fill="#0C1628"
        />
        <path
            d="M20.7372 8.62751C20.6287 9.06044 19.878 10.3412 19.5162 10.9274C18.4309 10.5215 18.1595 4.70417 18.8378 2.94542C19.3805 1.53843 21.4155 1.18669 22.2295 1.32197C22.7722 0.916109 23.4501 0.104383 27.52 0.780822C31.59 1.45726 31.59 6.05705 28.8767 6.19234C26.1634 6.32762 25.0785 4.97474 24.6715 5.11003C24.2645 5.24532 23.9932 5.5159 23.0435 6.73349C22.0939 7.95108 22.3652 7.95108 22.2295 6.73349C22.0939 5.5159 21.5512 5.5159 20.6016 6.19234C19.6519 6.86877 20.8729 8.08636 20.7372 8.62751Z"
            fill="#0C1628"
        />
        <path
            d="M40.0414 16.7601L38.1569 25.0287C38.1381 25.1111 38.0745 25.1759 37.9923 25.1964L34.7905 25.9925C34.6289 26.0327 34.4812 25.8896 34.5168 25.7273L36.2018 18.058C36.2176 17.9862 36.2676 17.9268 36.3357 17.8988L39.737 16.5033C39.9053 16.4343 40.0817 16.5831 40.0414 16.7601Z"
            fill="#142542"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.6516 16.296C39.9882 16.1579 40.3411 16.4555 40.2604 16.8094L38.376 25.0781C38.3384 25.2428 38.2111 25.3725 38.0467 25.4134L37.9924 25.1961L38.0467 25.4134L34.8449 26.2095C34.5217 26.2899 34.2263 26.0036 34.2976 25.6791L35.9826 18.0098C36.0141 17.8663 36.1142 17.7474 36.2504 17.6915L39.6516 16.296ZM39.8225 16.7102L36.4213 18.1056L34.7363 25.775L37.938 24.9788L39.8225 16.7102Z"
            fill="#0C1628"
        />
        <path
            d="M35.4699 26.3934V27.5633C36.8075 28.2262 39.4355 29.2011 39.2466 27.7973C39.0106 26.0425 39.7187 25.8085 40.0728 23.8197C40.3561 22.2287 39.6401 21.597 39.2466 21.48C39.168 21.7919 38.987 22.4861 38.8926 22.7668C38.7746 23.1178 37.5943 22.5329 36.6502 22.4159C35.706 22.2989 35.1158 24.0537 34.9978 24.9896C34.8798 25.9255 35.4699 26.0425 35.4699 26.3934Z"
            fill="#EFCB96"
        />
        <path
            d="M31.7262 39.3378C31.7262 41.0694 32.9019 42.1788 33.4898 42.5847L33.3541 43.3964C32.721 43.5317 31.3088 41.9082 31.1835 40.2848C30.9675 37.4846 33.3308 30.4333 34.5571 27.2093C34.5632 27.193 34.5692 27.1772 34.5751 27.1619C34.5692 27.1776 34.5631 27.1934 34.5571 27.2093C33.8592 29.0638 31.7262 37.192 31.7262 39.3378Z"
            fill="#0C1628"
        />
        <path
            d="M37.5913 27.5677C39.7621 27.5677 39.459 27.1619 40.0016 26.2148C40.0016 29.5068 41.2225 36.4967 40.68 39.2025C40.0018 42.5847 37.5913 43.8023 36.0991 43.5317C34.607 43.2611 32.1647 42.5847 31.7577 39.6083C31.4321 37.2273 33.7022 29.6872 34.878 26.2148C35.0136 26.5305 35.7177 27.5677 37.5913 27.5677Z"
            fill="#1C345C"
        />
        <path
            d="M40.1692 40.9614C36.8047 39.1215 33.3407 40.1046 32.0293 40.8261C32.3911 41.728 33.7115 43.5589 36.0992 43.6672C38.4869 43.7754 39.8074 41.9084 40.1692 40.9614Z"
            fill="#0C1628"
        />
    </svg>
));

export default BannerImage;
