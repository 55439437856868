import styled from '@emotion/styled';

import { IconButton } from '@partoohub/ui';

export const StyledIconButton = styled(IconButton)<{ disabled: boolean }>`
    background: ${({ theme }) => theme.colors.primary.initial}!important;

    i {
        color: white !important;
    }
`;
