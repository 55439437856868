import { useQuery } from 'react-query';

import { useSelector } from 'react-redux';

import { ReviewObjectType } from 'app/api/types/review';
import api from 'app/api/v2/api_calls';
import { UNSEEN_REVIEWS_COUNT } from 'app/common/data/queryKeysConstants';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useCanShowClientReviewCards } from 'app/pages/customerExperience/useCanShowClientReviewCards';
import {
    realtimeUpdatesNewReviewsSelector,
    realtimeUpdatesSelector,
} from 'app/states/realtimeUpdates/selectors';
import { API_NOT_TREATED_REVIEWS } from 'app/states/reviews/filters';

/*
 ** Returns the number of new reviews =
 **  reviews received from live fetch + reviews received since last time review list page has been seen
 */
export const useNewReviewsCount = () => {
    return useRealtimeUpdateReviewsCount() + useUnseenReviewsCount();
};

const useUnseenReviewsCount = () => {
    const { data: me } = useMe();
    const {
        canShowClientReviewCards: {
            [ReviewObjectType.REVIEW]: canShowClientReviewCards,
            [ReviewObjectType.FEEDBACK_RESULT]: showFeedbackResultCards,
        },
        isLoading,
    } = useCanShowClientReviewCards();

    const { data: count } = useQuery(
        [UNSEEN_REVIEWS_COUNT, { lastSeenDate: me?.rm_last_seen_date }],
        async () => {
            const promises = [];
            if (canShowClientReviewCards) {
                promises.push(
                    api.review.searchReviews({
                        state__in: [API_NOT_TREATED_REVIEWS],
                        update_date__gte: me?.rm_last_seen_date,
                        per_page: 1,
                    }),
                );
            }
            if (showFeedbackResultCards) {
                promises.push(
                    api.feedbackResults.searchFeedbackResults({
                        state__in: [API_NOT_TREATED_REVIEWS],
                        created__gte: me?.rm_last_seen_date,
                        optin: 1,
                        per_page: 1,
                    }),
                );
            }

            return (await Promise.all(promises)).reduce((total, data) => total + data.count, 0);
        },
        {
            enabled: !!me && !isLoading,
        },
    );

    return count ?? 0;
};

const useRealtimeUpdateReviewsCount = () => {
    return realtimeUpdatesNewReviewsSelector(useSelector(realtimeUpdatesSelector)).length;
};
