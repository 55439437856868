import styled from '@emotion/styled';

export const TemplateCardHeader = styled.div`
    grid-area: header;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
`;

export const TemplateCardTitle = styled.span`
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const TemplateCardPreview = styled.p`
    grid-area: preview;
    margin: 0;
    color: ${({ theme }) => theme.colors.secondary.initial};
    // The three properties together allow to show one line of preview.
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    // And these show two lines of text
    @supports (-webkit-line-clamp: 2) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;

export const TemplateCardMenu = styled.div`
    grid-area: menu;
    display: flex;
    align-self: center;
`;

export const TemplateCardIcon = styled.span`
    margin-right: 4px;
    font-size: 18px;
    line-height: 20px;
`;

export const TemplateCard = styled.div`
    border-radius: ${({ theme }) => theme.radius.large};
    padding: 12px;
    margin: 16px 0;
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.shadows.light};
    transition:
        box-shadow 200ms ease-in-out 0s,
        transform 200ms ease-in-out 0s;
    display: grid;
    grid-gap: 4px 8px;
    grid-template-columns: 16px auto 40px;
    grid-template-rows: 20px auto;
    grid-template-areas:
        'header header menu'
        'preview preview menu';

    &:hover {
        box-shadow: ${({ theme }) => theme.shadows.dark};
    }
`;

export const HighlightedText = styled.b`
    color: ${({ theme }) => theme.colors.primary.initial};
    background-color: ${({ theme }) => theme.colors.primary.alpha};
    padding: 0px 1px;
`;
