import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { BUSINESS, PUBLISHER_ERRORS } from 'app/common/data/queryKeysConstants';

export default function usePublisherErrors(payload = {}) {
    const businessId = useGetBusinessId();
    return useQuery(
        [BUSINESS, PUBLISHER_ERRORS, { businessId }, { payload }],
        () => api.publisherErrors.getPublisherErrorsV2(businessId, payload),
        {
            enabled: !!businessId,
            keepPreviousData: true,
        },
    );
}
