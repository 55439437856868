import BusinessModalButton from 'app/common/components/businessModal';
import ButtonSelect from 'app/common/designSystem/components/molecules/ButtonSelect/ButtonSelect';

type Props = {
    label: string;
    placeholder?: string;
};

export const BusinessesSelect = ({ label, placeholder }: Props) => (
    <BusinessModalButton button={<ButtonSelect label={label} placeholder={placeholder} />} />
);
