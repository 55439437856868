import React from 'react';

import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from 'react-query/types/react/types';

import { SuccessResponse } from 'app/api/types';
import { UserReceivedData } from 'app/api/types/user';
import ModalValidationRibbon, {
    RibbonStateType,
} from 'app/common/components/ModalValidationRibbon';
import {
    ModalBody,
    ModalContainer,
    ModalHeader,
} from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/BaseReinviteModalContent/BaseReinviteModalContent.styled';

interface Props {
    mutate: UseMutateFunction<UserReceivedData | SuccessResponse>;
    // mutation: UseMutationResult<UserReceivedData> | UseMutationResult<SuccessResponse>;
    success: boolean;
    isLoading: boolean;
    error: string;
    headerTitle: string;
    text: string;
}

const BaseReinviteModalContent = ({
    mutate,
    success,
    isLoading,
    error,
    headerTitle,
    text,
}: Props) => {
    const { t } = useTranslation();

    const getRibbonState = (): {
        state: RibbonStateType;
        text: string;
        enabledIcon?: string;
    } => {
        if (success) {
            return {
                state: 'success',
                text: t('success'),
            };
        }

        if (isLoading) {
            return {
                state: 'loading',
                text: t('re_invite_user'),
            };
        }

        if (error) {
            return {
                state: 'danger',
                text: error,
            };
        }

        return {
            state: 'enabled',
            text: t('re_invite_user'),
            enabledIcon: 'fas fa-sync-alt',
        };
    };

    return (
        <ModalContainer>
            <ModalHeader>
                <i className="fa-solid fa-sync-alt" /> {headerTitle}
            </ModalHeader>
            <ModalBody>{text}</ModalBody>
            <ModalValidationRibbon
                handleClick={() => {
                    mutate();
                }}
                {...getRibbonState()}
                noIcon={false}
            />
        </ModalContainer>
    );
};

export default BaseReinviteModalContent;
