import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const LoadRealtimeButton = styled.button<{ show: boolean }>`
    z-index: 1;
    opacity: 1;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 50px;
    box-shadow: none;
    outline: none;
    padding: 0 8px;
    height: 24px;
    line-height: 14.56px;
    background-color: ${({ theme }) => theme.colors.danger.initial};
    font-size: 12px;
    font-weight: bold;
    color: ${HEX_COLORS.white};

    ${({ show }) =>
        !show &&
        css`
            opacity: 0;
            transition: opacity 0.4s;
            pointer-events: none;
        `}

    & > .fas {
        margin: 0 7px;
    }

    i {
        margin-right: 6px;
    }
`;
