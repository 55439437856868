import { useEffect, useState } from 'react';

import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useCompletionRate from 'app/businessEditV2/hooks/business/useCompletionRate';
import { BusinessStatusRate, LOW_RATE, MID_RATE } from 'app/businessEditV2/utils/businessBanner';

import usePrevious from 'app/common/hooks/usePrevious';

import { STATIC_BASE_URL } from 'app/config';

import {
    CongratulationBadgeContainer,
    Description,
    Title,
} from './ModalCongratulationBadge.styled';

type CongratulationInfosType = {
    url: string;
    description: string;
    title: string;
};

const BusinessStatusCompletion = () => {
    const { t } = useTranslation();
    const completionRate = useCompletionRate();
    const previousCompletionRate = usePrevious(completionRate);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        const getStatusFromCompletionRate = (value: number): BusinessStatusRate => {
            const data = value || completionRate;
            if (data <= LOW_RATE) {
                return BusinessStatusRate.LOW_RATE;
            }
            if (data <= MID_RATE) {
                return BusinessStatusRate.MID_RATE;
            }
            return BusinessStatusRate.HIGH_RATE;
        };

        const isShowModal = (previousValue: number | undefined, nextValue: number): boolean =>
            previousValue && previousValue <= nextValue
                ? getStatusFromCompletionRate(previousValue) !==
                  getStatusFromCompletionRate(nextValue)
                : false;

        if (!showModal) {
            setShowModal(isShowModal(previousCompletionRate, completionRate));
        }
    }, [previousCompletionRate, completionRate]);

    const getCongratulationPopinInfos = (): CongratulationInfosType => {
        if (completionRate > LOW_RATE && completionRate <= MID_RATE) {
            return {
                url: `${STATIC_BASE_URL}/images/app/badge-low-medium.gif`,
                description: t('business_edit_popup_complete_for_visibility'),
                title: t('business_edit_popup_continue'),
            };
        }
        if (completionRate > MID_RATE) {
            return {
                url: `${STATIC_BASE_URL}/images/app/badge-medium-good.gif`,
                description: t('business_edit_popup_complete_for_100'),
                title: t('business_edit_popup_well_done'),
            };
        }
        return {
            url: '',
            description: '',
            title: '',
        };
    };

    const { url, description, title } = getCongratulationPopinInfos();

    const handleCloseModal = () => setShowModal(false);

    return (
        <Modal isOpen={showModal} closeModal={handleCloseModal}>
            <CongratulationBadgeContainer>
                <Icon
                    icon={[FontAwesomeIconsPartooUsed.faClose, IconPrefix.SOLID]}
                    color="secondary"
                    iconSize="24px"
                    onClick={handleCloseModal}
                />
                <Title>{title}</Title>
                <img src={url} alt="badge-completion-rate" />
                <Description>{description}</Description>
            </CongratulationBadgeContainer>
        </Modal>
    );
};

export default BusinessStatusCompletion;
