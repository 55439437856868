import { connect } from 'react-redux';

import { AccountPartner } from 'app/api/types/account';
import ConnectedButtonComponent from 'app/businessConnection/components/common/ConnectedButton';
import getPartnerMethods from 'app/businessConnection/services/PartnerMethods';
import { displayConnectionModal } from 'app/states/knowledge/reducers/businessConnection/connection';
import { getPublisherBusinessDetailsSelector } from 'app/states/presence/reducers/listing/businessDetails/selector';
import { AppState } from 'app/states/reducers';

type OwnProps = {
    partner: AccountPartner;
    locationId: string;
    businessId: string;
};

const mapStateToProps = (
    state: AppState,
    { partner, locationId, businessId }: OwnProps,
) => {
    const partnerMethods = getPartnerMethods[partner];
    const location = partnerMethods.locationSelector(state, locationId);
    const error = partnerMethods.locationGetRequestErrorSelector(
        state,
        locationId,
    );

    const { publisherBusinesses } = state;
    const publisherBusinessDetails = getPublisherBusinessDetailsSelector(
        publisherBusinesses,
        partner.toLowerCase(),
        'success',
        businessId,
    );
    const publisherStatus = publisherBusinessDetails?.businessId
        ? 'success'
        : 'error';

    const errorMessage =
        error || (location && publisherStatus === 'success')
            ? 'partner_connection_permission_denied_subject'
            : 'loading';

    if (location) {
        return {
            state: 'success',
            location,
            errorMessage,
            publisherStatus,
        };
    }

    return {
        state: error ? 'error' : 'loading',
        errorMessage,
        publisherStatus,
    };
};

const mapDispatchToProps = (
    dispatch: any,
    { partner, locationId, businessId }: OwnProps,
) => {
    const partnerMethods = getPartnerMethods[partner];
    return {
        onClick: () =>
            dispatch(displayConnectionModal(businessId, partner, locationId)),
        fetchLocation: () => dispatch(partnerMethods.getLocation(locationId)),
    };
};

const ConnectedButton = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConnectedButtonComponent);

export default ConnectedButton;
