import { useContext } from 'react';

import { Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { PUBLISHERS } from 'app/businessConnection/components/genericQuickConnect/publishers';
import QuickConnectContext from 'app/businessConnection/components/genericQuickConnect/QuickConnect.context';

import { ConnectionModalInfoSection, StyledDescription } from './InfoSection.styled';

export const InfoSection = () => {
    const { t } = useTranslation();
    const { publisher } = useContext(QuickConnectContext);
    const pubTexts = PUBLISHERS[publisher].texts;
    const ExtensionInfoSection = PUBLISHERS[publisher].extensionInfoSection;

    return (
        <ConnectionModalInfoSection>
            <Stack gap="32px">
                <Text variant="heading5">{t(pubTexts.infoSectionHeaderTitle)}</Text>
                <StyledDescription variant="bodyMRegular" color="secondary">
                    {t(pubTexts.infoSectionHeaderDescription)}
                </StyledDescription>
            </Stack>
            <ExtensionInfoSection />
        </ConnectionModalInfoSection>
    );
};
