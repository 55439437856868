import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/react';
import { greyColorObject } from '@partoohub/branding';
import { FontAwesomeIconsPartooUsed, IconPrefix, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { BusinessFieldType } from 'app/api/types/business';
import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';
import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { STATIC_BASE_URL } from 'app/config';
import { CustomFieldsQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';
import {
    getIconName,
    getTypeTranslation,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/services';

import {
    CustomFieldDraggable,
    CustomFieldName,
    CustomFieldType,
    CustomFieldsListItemContainer,
    GrabIcon,
} from './CustomFieldsListItem.styled';

type Props = {
    customField: BusinessFieldType;
};

const CustomFieldsListItem = ({ customField }: Props) => {
    const { t } = useTranslation();

    const [, editCustomField] = useStateQueryParams(CustomFieldsQueryKeys.EDIT);
    const [, deleteCustomField] = useStateQueryParams(CustomFieldsQueryKeys.DELETE);

    const { attributes, listeners, setNodeRef, isDragging, transition, transform } = useSortable({
        id: 'cf_' + customField.id,
    });

    const isInSection = !!customField.section_id;

    const onMenuClick = (value: string) => {
        if (value === 'edit') {
            editCustomField(customField.id?.toString() ?? '');
        } else if (value === 'delete') {
            deleteCustomField(customField.id?.toString() ?? '');
        }
    };

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <CustomFieldsListItemContainer
            ref={setNodeRef}
            isInSection={isInSection}
            isDragging={isDragging}
            style={style}
        >
            <CustomFieldDraggable isInSection={isInSection} {...attributes} {...listeners}>
                <GrabIcon icon={['fa-bars', IconPrefix.SOLID]} color={greyColorObject.initial} />
                <CustomFieldName variant="bodyMBold">{customField.name}</CustomFieldName>
                <CustomFieldType>
                    <img
                        src={`${STATIC_BASE_URL}/images/app/custom_fields/${getIconName(
                            customField.type,
                        )}`}
                        alt=""
                    />
                    <Text variant="bodyMBold" color="secondary">
                        {getTypeTranslation(customField.type)}
                    </Text>
                </CustomFieldType>
            </CustomFieldDraggable>

            <ActionButtonWithMenu
                dataTrackId=""
                icon={[FontAwesomeIconsPartooUsed.faEllipsisVertical, IconPrefix.SOLID]}
                options={[
                    {
                        name: 'edit',
                        value: 'edit',
                        label: (
                            <MenuLabelItem
                                text={t('edit')}
                                icon={['fa-pen-clip', IconPrefix.REGULAR]}
                            />
                        ),
                    },
                    {
                        name: 'delete',
                        value: 'delete',
                        label: (
                            <MenuLabelItem
                                text={t('delete')}
                                icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                                color="danger"
                            />
                        ),
                    },
                ]}
                // Align menu to the right
                cssMenu={css`
                    min-width: 100px;
                    top: 60px;
                    right: -8px;
                    left: unset;
                `}
                onMenuClick={onMenuClick}
            />
        </CustomFieldsListItemContainer>
    );
};

export default CustomFieldsListItem;
