import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

export function useValidateBusinesses(conversationStarterId: string) {
    return useMutation((businessSearchFilter: BusinessModalFiltersType) =>
        api.conversationStarters.validateBusinesses(conversationStarterId, businessSearchFilter),
    );
}
