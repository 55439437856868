import React from 'react';

import { Modal, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import ModalImage from 'app/common/designSystem/components/atoms/svg/modalImages/changePasswordImage';
import { SETTINGS_MAIN_PROFILE_PATHNAME } from 'app/routing/routeIds';

import {
    ChangePasswordModalContainer,
    ChangePasswordModalTop,
    UpdatePasswordButton,
} from './ChangePasswordModal.styled';

type Props = {
    show: boolean;
    modalRedirectionUrl: string;
};

const ChangePasswordModal = ({ show, modalRedirectionUrl }: Props) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={show} closeModal={() => undefined}>
            <ChangePasswordModalContainer>
                <ChangePasswordModalTop>
                    <ModalImage />

                    <Text variant="heading3">{t('password_not_secured')}</Text>

                    <Text variant="bodyMBold" color="secondary">
                        {t('password_not_secured_subtitle')}
                    </Text>

                    <StrikeThroughButton
                        text={t('ignore')}
                        color="secondary"
                        onClick={() => {
                            window.location.href = modalRedirectionUrl;
                        }}
                    />
                </ChangePasswordModalTop>

                <UpdatePasswordButton
                    size="large"
                    variant="danger"
                    shape="cube"
                    full
                    dataTrackId="login_form_change_password_modal__update_password_button"
                    onClick={() => {
                        window.location.href = SETTINGS_MAIN_PROFILE_PATHNAME;
                    }}
                >
                    {t('update_password')}
                </UpdatePasswordButton>
            </ChangePasswordModalContainer>
        </Modal>
    );
};

export default ChangePasswordModal;
