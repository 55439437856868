import {
    RENEW_JWT_TOKEN_FAILURE,
    RENEW_JWT_TOKEN_REQUEST,
    RENEW_JWT_TOKEN_SUCCESS,
} from './constants';
import {
    RenewJwtTokenFailureType,
    RenewJwtTokenRequestType,
    RenewJwtTokenSuccessType,
} from './types';

export const renewJwtTokenRequest = (): RenewJwtTokenRequestType => ({
    type: RENEW_JWT_TOKEN_REQUEST,
});

export const renewJwtTokenSuccess = (): RenewJwtTokenSuccessType => ({
    type: RENEW_JWT_TOKEN_SUCCESS,
});

export const renewJwtTokenFailure = (
    errors: Record<string, any>,
): RenewJwtTokenFailureType => ({
    type: RENEW_JWT_TOKEN_FAILURE,
    errors,
});
