import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    gap: 4px;
    position: relative;
    cursor: pointer;
    align-items: center;
`;

export const MenuListWrapper = styled.div<{ css?: SerializedStyles }>`
    position: absolute;
    min-width: 100%;
    z-index: 10;
    ${({ css }) => css};
`;
