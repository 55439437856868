import React from 'react';

import { StyledContent } from 'app/pages/privacyPolicy/PrivacyPolicy.styled';
import { MessagingPrivacyPolicyData } from 'app/pages/privacyPolicy/types';

type Props = {
    data: MessagingPrivacyPolicyData;
};

const PrivacyPolicyTemplateEs = ({ data }: Props) => (
    <StyledContent>
        <h1>
            <strong>POLÍTICA DE CONFIDENCIALIDAD</strong>
        </h1>
        <br />
        La protección de los datos personales y el respeto de la vida privada
        son de suma importancia para nosotros. La presente Política de
        confidencialidad tiene por objeto informarle de la forma en que
        recogemos, utilizamos y transmitimos sus datos personales cuando se
        comunica con nosotros a través del Chat (el « <strong>Chat</strong>») y
        ello, de conformidad con la normativa vigente en Francia tal como se
        desprende de la ley n°78-17 de 6 de enero de 1978 relativa a la
        informática, los archivos y las libertades modificada («{' '}
        <strong>LIL</strong> ») y del Reglamento UE 2016/679 de 27 de abril de
        2016 relativo a la protección de los datos personales («{' '}
        <strong>RGPD</strong> »). <br />
        <br /> Le recordamos que el uso de sus Datos personales también está
        regulado por la Política de confidencialidad del Chat utilizado (por
        ejemplo, para Google:{' '}
        <a href="https://policies.google.com/privacy?gl=ES&hl=es">
            https://policies.google.com/privacy
        </a>
        ).
        <br />
        <br /> Se le notificará cualquier modificación o actualización de esta
        Política de confidencialidad.
        <br />
        <br />
        <h3>
            <strong>1. Definiciones </strong>
        </h3>
        « <strong>Chat</strong> » significa el servicio de mensajería
        instantánea que puede utilizar para ponerse en contacto con nosotros.
        <br />
        <br /> « <strong>Datos</strong> » significa cualquier información
        transmitida por el Usuario a la Sociedad a través del Chat, incluidos
        los Datos personales.
        <br />
        <br /> « <strong>Dato personal</strong> » o «{' '}
        <strong>Dato de carácter personal</strong> » significa toda información
        relativa a una persona física identificada o identificable, directa o
        indirectamente, por referencia a un número de identificación o a uno o
        varios elementos propios de dicha persona.
        <br />
        <br /> « <strong>Datos personales sensibles</strong> » significa
        cualquier información relativa al origen racial o étnico, las opiniones
        políticas, filosóficas o religiosas, la pertenencia a un sindicato, la
        salud o la vida sexual, así como los datos genéticos o biométricos. En
        principio, el tratamiento de los Datos sensibles está prohibido a menos
        que la persona interesada haya dado su consentimiento expreso.
        <br />
        <br /> « <strong>Responsable del tratamiento</strong> » significa la
        persona física o jurídica que determina los fines y los medios de un
        Tratamiento de datos, y en este caso, la Sociedad.
        <br />
        <br /> « <strong>Subcontratista</strong> » significa la persona física o
        jurídica que procesa los Datos Personales por nuestra cuenta y conforme
        a nuestras instrucciones, en relación con el uso del Chat.
        <br />
        <br /> « <strong>Tratamiento</strong> » significa cualquier operación, o
        conjunto de operaciones, relativas a los Datos personales,
        independientemente del procedimiento utilizado (recogida, registro,
        organización, almacenamiento, adaptación, modificación, extracción,
        consulta, uso, comunicación por transmisión o difusión o cualquier otra
        forma de puesta a disposición, cotejo) llevado a cabo por la Sociedad en
        el contexto del uso del Chat.
        <br />
        <br /> « <strong>Usuario</strong> » o « <strong>Usted/su</strong> »
        significa cualquier persona física que se ponga en contacto con la
        Empresa a través del Chat.
        <br />
        <br />
        <h3>
            <strong>
                2. ¿Quién es el responsable del tratamiento de sus datos
                personales?
            </strong>
        </h3>
        El Responsable de tratamiento, que recoge y gestiona sus datos, es la
        sociedad {data.companyName}, una sociedad {data.companyType} con un
        capital de {data.shareCapital} euros, cuyo domicilio social se encuentra
        en {data.companyAddress}, inscrita en el registro mercantil de{' '}
        {data.companyRegistrationCity}, con el número{' '}
        {data.companyRegistrationNumber} (la « <strong>Sociedad</strong> »),
        representada por su Presidente.
        <br />
        <br />
        <h3>
            <strong>3. Categorías de Datos personales recogidos</strong>
        </h3>
        Al utilizar el Chat, podemos recoger las siguientes categorías de Datos
        personales:
        <br />
        <ul>
            <li>
                <strong>Estado Civil</strong> : Apellidos, nombre,
            </li>
            <li>
                <strong>Imagen</strong> (foto de perfil de su cuenta en el Chat
                utilizado).
            </li>
        </ul>
        También podemos recopilar cualquier Dato personal que nos proporcione,
        por iniciativa propia, durante nuestros contactos a través del Chat
        (número de pedido, datos postales y/o telefónicos, fotografías, etc.).
        Tenga en cuenta que nunca le pediremos que proporcione Datos personales
        sensibles a través del Chat y le recomendamos encarecidamente que no los
        proporcione voluntariamente para garantizar una mayor seguridad de la
        información intercambiada.
        <br />
        <br /> Usted se compromete a proporcionar Datos personales actualizados
        y válidos y garantiza que no hará ninguna declaración falsa ni
        proporcionará información errónea. No debe revelar ningún Dato personal
        relativo a terceros a través del Chat.
        <br />
        <br />
        <h3>
            <strong>
                4. Carácter obligatorio y opcional de los Datos personales
                recogidos
            </strong>
        </h3>
        Algunos Datos son necesarios para tramitar su solicitud. El carácter
        obligatorio de los datos solicitados se indica en el momento de la
        recogida. El hecho de no proporcionar dichos Datos nos impedirá
        responder adecuadamente a su solicitud.
        <br />
        <br /> Todos los Datos que no se presentan como obligatorios son
        opcionales. Por lo tanto, usted es libre de facilitarlos o no. Sin
        embargo, pueden ayudarnos a responder con mayor precisión a su solicitud
        y mejorar su experiencia en el trato con nosotros.
        <br />
        <br />
        <h3>
            <strong>5. Fines de la recogida de Datos personales </strong>
        </h3>
        La Sociedad recopila sus Datos personales, en el contexto del Chat, para
        los siguientes fines:
        <br />
        <ul>
            <li>Leer y responder de forma óptima a los mensajes enviados,</li>
            <li>
                Permitir el acceso al historial de conversaciones entre la
                Sociedad y los Usuarios,
            </li>
            <li>
                Mejorar el servicio que le proporcionamos a través del Chat
                (reduciendo los tiempos de respuesta, etc.),
            </li>
            <li>Establecer estadísticas y mediciones de audiencia,</li>
            <li>Combatir el fraude y garantizar la seguridad de los Datos,</li>
            <li>
                Ejecución de disposiciones legales, reglamentarias y
                administrativas vigentes.
            </li>
        </ul>
        En función de los casos, la Sociedad procesa Sus Datos personales a
        partir de uno de los siguientes fundamentos jurídicos:
        <br />
        <ul>
            <li>
                Su consentimiento previo cuando haya manifestado su conformidad
                con el tratamiento de Sus Datos,
            </li>
            <li>La ejecución de un contrato que le vincula a nosotros,</li>
            <li>Nuestras obligaciones legales,</li>
            <li>
                Nuestro "interés legítimo", tal y como se define en la
                legislación aplicable en materia de protección de Datos
                Personales. En este caso, tendremos en cuenta sus intereses y
                derechos fundamentales para determinar si el tratamiento es
                legítimo y legal.
            </li>
        </ul>
        <br />
        <h3>
            <strong>6. ¿Quién tiene acceso a Sus Datos personales?</strong>
        </h3>
        <h4>
            <strong>6.1. Nuestro personal</strong>
        </h4>
        Los Datos personales recogidos son tratados por personas debidamente
        habilitadas en la Sociedad para responder a sus solicitudes a través del
        Chat.
        <br />
        <br />
        <h4>
            <strong>6.2. Nuestros Subcontratistas</strong>
        </h4>
        En el contexto del Chat, la Sociedad recurre a Subcontratistas que
        tratan los Datos personales por su cuenta y conforme a sus
        instrucciones. Para garantizar la seguridad y la confidencialidad de Sus
        datos y de acuerdo con las disposiciones del RGPD, les exigimos a
        nuestros Subcontratistas que ofrezcan garantías técnicas y organizativas
        suficientes y adecuadas.
        <br />
        <br /> La Sociedad recurre a Subcontratistas situados en países que
        ofrecen un nivel de protección adecuado, ya sea porque están sujetos a
        las disposiciones del RGPD o, con arreglo al artículo 45 del RGPD,
        porque gozan de una decisión de adecuación de la Comisión Europea. En su
        defecto, la Sociedad se compromete a aplicar cualquier mecanismo
        apropiado para garantizar un nivel de protección adecuado de los Datos
        y, en particular, las Cláusulas contractuales estándar establecidas por
        la Comisión Europea.
        <br />
        <br /> Puede obtener información más detallada sobre las garantías que
        ofrecen nuestros Subcontratistas solicitándola en la siguiente
        dirección: {data.dpoAddress}.
        <br />
        <br />
        <h4>
            <strong>6.3. Otros eventuales destinatarios</strong>
        </h4>
        La Sociedad puede transmitir, si procede, Sus Datos personales a
        organizaciones, terceros, autoridades administrativas o judiciales en
        los siguientes casos:
        <br />
        <ul>
            <li>
                Para cumplir con nuestras obligaciones legales y reglamentarias,
            </li>
            <li>
                Identificar, prevenir o hacer frente a actividades fraudulentas,
                violaciones de la seguridad o cualquier otro problema técnico,
            </li>
            <li>Hacer valer un derecho ante los tribunales.</li>
        </ul>
        Le aseguramos que la Sociedad no transmitirá Sus Datos personales a
        terceros socios para realizar operaciones comerciales sin su
        consentimiento previo.
        <br />
        <br />
        <h3>
            <strong>
                7. ¿Cuánto tiempo se conservan Sus Datos personales?
            </strong>
        </h3>
        La Sociedad conserva sus Datos Personales, en una forma que permite su
        identificación, en una base de datos activa durante el tiempo
        estrictamente necesario para lograr los fines para los que se recogen y
        tratan, es decir, un máximo de 1 año a partir de la fecha de nuestro
        último intercambio a través del Chat.
        <br />
        <br /> Sus Datos Personales también pueden archivarse con acceso
        limitado y justificado durante el tiempo necesario (i) para cumplir con
        las obligaciones legales, fiscales, contables y reglamentarias de la
        Sociedad y/o (ii) para permitirnos hacer valer una reclamación legal,
        antes de eliminarse definitivamente.
        <br />
        <br />
        <h3>
            <strong>
                8. ¿Cómo garantizamos la seguridad y la confidencialidad de Sus
                Datos personales?
            </strong>
        </h3>
        La Sociedad se compromete a tratar sus Datos Personales de forma lícita,
        justa, transparente, proporcionada y pertinente, estrictamente de
        acuerdo con los fines perseguidos y anunciados, durante el tiempo
        necesario para el tratamiento en cuestión y de forma segura.
        <br />
        <br /> La Sociedad aplica y actualiza las medidas técnicas y
        organizativas apropiadas para garantizar la seguridad y la
        confidencialidad de Sus Datos personales, impidiendo que se
        distorsionen, destruyan, dañen o comuniquen a terceros no autorizados.
        Para ello, la Sociedad ha tomado medidas de seguridad físicas,
        electrónicas y organizativas para evitar la pérdida, el uso abusivo, el
        acceso o la distribución no autorizados, la modificación o la
        destrucción de los Datos personales. Como todos los Datos personales son
        confidenciales, el acceso está limitado a los empleados, proveedores de
        servicios y los Subcontratistas de la Sociedad que tengan una estricta
        necesidad de consultarlos para ejercer sus funciones. Todas las personas
        que tienen acceso a Sus Datos personales están sujetas a una obligación
        de confidencialidad y se arriesgan a que se tomen medidas disciplinarias
        y/o apliquen sanciones en caso de incumplimiento de estas obligaciones.
        <br />
        <br /> Sin embargo, es importante que evite cualquier acceso no
        autorizado a Sus Datos personales, proporcionando solo los Datos
        estrictamente necesarios para tramitar su solicitud a través del Chat.
        <br />
        <br />
        <h3>
            <strong>
                9. ¿Cuáles son sus derechos sobre sus Datos personales?
            </strong>
        </h3>
        Con solo solicitarlo por escrito en la siguiente dirección, podrá:{' '}
        {data.dpoAddress}, acceder a Sus Datos Personales, para solicitar su
        rectificación o limitación, su eliminación o portabilidad, o para exigir
        que dejen de figurar en la base de datos de la Sociedad.
        <br />
        <br /> Con arreglo al <strong>derecho de acceso</strong>, usted tiene
        derecho, de conformidad con el artículo 15 del RGPD, a consultarnos con
        el fin de obtener la confirmación de que sus Datos Personales se están
        sometiendo o no a un Tratamiento, así como la comunicación de datos
        relativos al tratamiento (incluidos sus fines, las categorías de Datos
        personales tratados y los destinatarios a los que se revelan los Datos
        personales, el período de tiempo durante el cual se conservan los Datos
        o los criterios utilizados para determinar dicho período, sus derechos
        con respecto a los Datos personales y el derecho a presentar una
        reclamación ante una autoridad de control).
        <br />
        <br />
        De conformidad con el artículo 16 del RGPD, el{' '}
        <strong>derecho de rectificación</strong> le otorga el derecho a exigir
        que rectifiquemos, completemos o actualicemos los Datos personales
        relativos a su persona si son inexactos, incompletos, ambiguos o están
        desactualizados.
        <br />
        <br /> Con arreglo a las condiciones establecidas en el artículo 17 del
        RGPD, también puede solicitar <strong>la eliminación de</strong> Sus
        Datos personales.
        <br />
        <br />
        Además, puede solicitar que se <strong>restrinja</strong> el tratamiento
        de Sus Datos personales con arreglo a las condiciones del artículo 18
        del RGPD, es decir, que Sus Datos personales se conserven únicamente
        para:
        <br />
        <ul>
            <li>
                verificar la exactitud de los Datos personales objeto de una
                impugnación,
            </li>
            <li>
                servirle para el establecimiento, el ejercicio o la defensa de
                sus derechos legales, aunque la Sociedad ya no haga ningún uso
                de ellos,
            </li>
            <li>
                comprobar si los motivos legítimos que persigue la Sociedad
                prevalecen sobre los suyos en caso de que usted se oponga al
                tratamiento fundamentado en el interés legítimo de la Sociedad,
            </li>
            <li>
                satisfacer su solicitud de limitación de uso de sus datos –en
                lugar de borrarlos– si el tratamiento de sus datos es ilegal.
            </li>
        </ul>
        Con arreglo a su <strong>derecho a la portabilidad</strong> y en las
        circunstancias establecidas en el artículo 20 del RGPD, usted puede
        recuperar de la Sociedad los Datos personales que nos haya
        proporcionado, en un formato estructurado, de uso común y legible por
        máquina, con el fin de transferirlos a otro responsable del tratamiento.
        <br />
        <br /> De conformidad con el artículo 21 del RGPD, usted tiene la
        posibilidad de <strong>oponerse</strong>, en cualquier momento, al
        tratamiento de sus Datos personales con fines de prospección comercial.
        Para ejercer sus derechos, solo tiene que enviar su solicitud:
        <br />
        <ul>
            <li>
                por correo electrónico a la siguiente dirección: {data.dpoEmail}
                ,
            </li>
            <li>
                por correo postal, escribiendo a la siguiente dirección:{' '}
                {data.dpoAddress}.
            </li>
        </ul>
        La Sociedad responderá a la solicitud en el plazo de los 30 días
        siguientes a su recepción.
        <br />
        <br /> El ejercicio de estos derechos es gratuito. No obstante, en caso
        de que la solicitud sea manifiestamente infundada o excesiva, en
        particular por su carácter repetitivo, la Sociedad se reserva el derecho
        a (i) exigir el pago de una tasa que tenga en cuenta los costes
        administrativos, o (ii) negarse a atender la solicitud.
        <br />
        <br />
        <h3>
            <strong>
                10. ¿Qué ocurre si sus Datos personales se ven comprometidos?
            </strong>
        </h3>
        En caso de que se produzca una violación de sus Datos personales que
        pueda suponer un riesgo para sus derechos y libertades, la Sociedad
        notificará su violación a la CNIL lo antes posible y, si es posible,
        como muy tarde 72 horas después de tener conocimiento de ella. Cuando
        una violación de sus Datos personales pueda suponer un alto riesgo para
        los derechos y libertades de un Usuario, la Sociedad informará al
        Usuario lo antes posible, sin perjuicio de las excepciones previstas en
        el artículo 34 del RGPD. <br />
        <br /> Sin perjuicio de cualquier otro recurso administrativo o
        judicial, si considera que el tratamiento de Sus Datos personales
        constituye una violación de las disposiciones de la legislación vigente,
        puede presentar una reclamación ante una autoridad de control
        competente, como la CNIL, en la siguiente dirección:
        <br /> Commission Nationale de l'Informatique et des Libertés, 3 Place
        de Fontenoy - TSA 80715 - 75334 Paris Cedex 07
        <br />
        <br />
        <h3>
            <strong>11. ¿A quién formular preguntas? </strong>
        </h3>
        En caso de que desee formular una pregunta relacionada con el
        Tratamiento de Sus Datos personales, puede ponerse en contacto en
        cualquier momento con la persona responsable de los datos personales o
        con el DPO de la Sociedad: {data.dpoName}, en relación con cualquier
        pregunta sobre el tratamiento de Sus Datos personales y el ejercicio de
        sus derechos, póngase en contacto con nosotros en la siguiente
        dirección: {data.dpoEmail}.
        <br />
        <br />
    </StyledContent>
);

export default PrivacyPolicyTemplateEs;
