import styled from '@emotion/styled';

export const GridColumn = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 16px;
`;

export const SwitchContainer = styled.div``;

// Values for select and select image

export const PossibleValueContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 24px 0;
`;

export const PossibleValueContainer = styled.div`
    display: flex;
`;

export const PossibleValueOption = styled.div`
    // Only for select, to get full width

    display: flex;
    flex: 1;
    margin-right: 8px;

    > * {
        display: flex;
        flex: 1;
    }
`;

export const PossibleValueIcons = styled.div`
    display: flex;
    flex-direction: row;
    margin: 7px 0 24px 0; // Hardcoding margin-top to align with the textfields...
`;

// Specific layout for select image

export const PossibleValueImageContainer = styled.div`
    display: flex;
    gap: 16px;

    > div:first-of-type {
        width: 235px;
        flex-shrink: 0;
    }

    > div:nth-of-type(2) {
        display: flex;
        flex: 1;
    }
`;
