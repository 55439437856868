import { useContext } from 'react';

import {
    FACEBOOK,
    GOOGLE,
    INSTAGRAM,
} from 'app/presence/googlePosts/components/services/constants';

import { NewPostContext, NewPostContextType } from '../context/NewPost';

export default function usePlatformSelection() {
    const context = useContext<NewPostContextType>(NewPostContext);

    if (context === undefined) {
        throw new Error(
            'usePlatformSelection should be used with NewPostContext',
        );
    }

    const platform = context?.formFields?.platform;

    const hasOnlyFacebook = platform.length === 1 && platform[0] === FACEBOOK;

    const hasOnlyGoogle = platform.length === 1 && platform[0] === GOOGLE;

    const hasMultiplatform = platform.length > 1;

    const containsFacebook = platform.includes(FACEBOOK);

    const containsGoogle = platform.includes(GOOGLE);

    const containsInstagram = platform.includes(INSTAGRAM);

    const hasOnlyInstagram = containsInstagram && platform.length === 1;

    return {
        platforms: platform,
        hasOnlyFacebook,
        hasOnlyGoogle,
        hasMultiplatform,
        containsFacebook,
        containsGoogle,
        containsInstagram,
        hasOnlyInstagram,
    };
}
