export const gradeColors = {
    lineColor: '#e6e8ed',
    satisfied: '#40dba2',
    neutral: '#f7c200',
    disatisfied: '#ff426e',
};

export const averageGraphColors = (gradeList: Array<number>) =>
    gradeList.map(averageGrade => {
        // @ts-ignore
        const grade = parseFloat(averageGrade);
        if (grade < 3) return gradeColors.disatisfied;
        if (grade >= 3 && grade < 4) return gradeColors.neutral;
        return gradeColors.satisfied;
    });

export default averageGraphColors;
