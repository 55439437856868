import { TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    LoginState,
    globalErrorSelector,
    passwordErrorSelector,
    passwordSelector,
    updatePassword,
} from 'app/states/connection/reducers/login';
import { AppState } from 'app/states/reducers';

export const PasswordInput = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const login = useSelector((state: AppState): LoginState => state.login);

    const value = passwordSelector(login);
    const error = passwordErrorSelector(login) ? 'Password too short' : '';
    const hasError = !!(passwordErrorSelector(login) || globalErrorSelector(login));

    const handleChange = (value: string) => dispatch(updatePassword(value));

    return (
        <TextInput
            dataTrackId="password_input"
            label={t('password')}
            type="password"
            onChange={handleChange}
            value={value}
            error={!!hasError}
            notice={error}
        />
    );
};
