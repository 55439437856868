import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import 'react-datepicker/dist/react-datepicker.css';
import {
    IconMonthPicker,
    IconReset,
    IconResetContainer,
    MonthYearPickerContainer,
    MonthYearPickerFrom,
    MonthYearPickerTo,
    OverrideDatePicker,
    Separator,
    Text,
    TextBetweenMonthPicker,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/MonthYearPicker/MonthYearPicker.styled';
import { getUTCDate } from 'app/utils/dates';

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'onChange';

export type Props = {
    startDate: Date;
    endDate: Date;
    handleStartDate: any;
    handleEndDate: any;
    handleReset: () => void;
    monthFormat: string;
    minDate: any;
    maxDate: any;
    canReset: boolean;
    className?: string;
};

export const ControlledMonthYearPicker = ({
    name,
    control,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => {
    const { t } = useTranslation();
    const {
        className,
        startDate,
        endDate,
        handleStartDate,
        handleEndDate,
        monthFormat,
        minDate,
        maxDate,
        handleReset,
        canReset,
    } = props;
    const transformedMaxdate = maxDate
        ? new Date(maxDate.getFullYear(), maxDate.getMonth() + 1, 0)
        : new Date();

    const startDateUTC = getUTCDate(startDate);
    const endDateUTC = getUTCDate(endDate);

    return (
        <MonthYearPickerContainer className={className}>
            <IconMonthPicker className="fa fa-fas fa-calendar" />
            <Separator />
            <MonthYearPickerFrom>
                <Controller
                    control={control}
                    name={`${name}.startDate`}
                    defaultValue={null}
                    render={({ field: { ref: _ref } }) => (
                        <OverrideDatePicker
                            placeholderText={t('start_date')}
                            className="react-datepicker-from"
                            selected={startDateUTC}
                            onChange={handleStartDate}
                            selectsStart
                            dateFormat={monthFormat}
                            minDate={minDate}
                            maxDate={endDateUTC ? endDateUTC : transformedMaxdate}
                            showMonthYearPicker
                        >
                            <Text>{t('select_month')}</Text>
                        </OverrideDatePicker>
                    )}
                />
            </MonthYearPickerFrom>
            <TextBetweenMonthPicker>{t('to')}</TextBetweenMonthPicker>
            <MonthYearPickerTo>
                <Controller
                    control={control}
                    name={`${name}.endDate`}
                    defaultValue={null}
                    render={({ field: { ref: _ref } }) => (
                        <OverrideDatePicker
                            placeholderText={t('end_date')}
                            className="react-datepicker-to"
                            selected={endDateUTC}
                            onChange={value => handleEndDate(value)}
                            selectsEnd
                            dateFormat={monthFormat}
                            minDate={startDateUTC ? startDateUTC : minDate}
                            maxDate={transformedMaxdate}
                            showMonthYearPicker
                        >
                            <Text>{t('select_month')}</Text>
                        </OverrideDatePicker>
                    )}
                />
            </MonthYearPickerTo>
            <IconResetContainer>
                {(startDateUTC || endDateUTC) && canReset && (
                    <IconReset className="fa fa-fas fa-circle-xmark" onClick={handleReset} />
                )}
            </IconResetContainer>
        </MonthYearPickerContainer>
    );
};
