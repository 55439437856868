import { FontAwesomeIconsPartooUsed } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { SettingSummaryItemValues } from 'app/pages/settingsV2/subComponents/SettingsSummaryItem/SettingsSummaryItem';
import { INTEGRATIONS_PATH_TOKEN } from 'app/routing/routeIds';

import { useIntegrationsSettingsEnabled } from './useIntegrationsSettingsEnabled';

export const useSettingIntegrationsSummary = (): SettingSummaryItemValues => {
    const { t } = useTranslation();
    const integrationsSettingsEnabled = useIntegrationsSettingsEnabled();
    return {
        settingData: {
            id: '5',
            icon: FontAwesomeIconsPartooUsed.faKey,
            title: t('settings_summary_integrations_title'),
            description: t('settings_summary_integrations_description'),
            url: INTEGRATIONS_PATH_TOKEN,
            display: integrationsSettingsEnabled,
        },
        isLoading: false,
    };
};
