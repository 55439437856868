import { OUTLINED, Type } from 'app/common/designSystem/constants/type';
import {
    ButtonMessageContent,
    ConversationMessage,
    MESSAGE_SENDER_TYPE,
} from 'app/states/messaging';

import { TextBubble } from './TextBubble';
import { TextBubbleContainer } from '../MessageBubbleContent.styled';

interface ButtonBubbleProps {
    message: ConversationMessage;
    handleBubbleClick: () => void;
}

export const ButtonsBubble = ({ message, handleBubbleClick }: ButtonBubbleProps) => {
    const isConsumerMessage = message.sender_type === MESSAGE_SENDER_TYPE.CONSUMER;
    const buttonsContent = message.content as ButtonMessageContent;

    return (
        <TextBubbleContainer>
            {buttonsContent.text && (
                <TextBubble
                    text={buttonsContent.text}
                    isConsumerMessage={isConsumerMessage}
                    onClick={handleBubbleClick}
                />
            )}
            {buttonsContent.buttons.map(button => (
                <TextBubble
                    text={button.title}
                    isConsumerMessage={isConsumerMessage}
                    bubbleType={OUTLINED as Type}
                    onClick={() => undefined}
                />
            ))}
        </TextBubbleContainer>
    );
};
