import LoadingRow from './LoadingRow';
import { ConnectionTableRowContainer } from '../ConnectionTable.styled';

export const ConnectionsTableLoadingState = () => (
    <ConnectionTableRowContainer>
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
    </ConnectionTableRowContainer>
);
