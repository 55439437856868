import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import WhatsappApiCalls from 'app/api/v2/api_calls/whatsappApiCalls';
import { WHATSAPP } from 'app/common/data/queryKeysConstants';
import WhatsappAccountCreationButton from 'app/pages/settings/Whatsapp/WhatsappAccountCreationButton';
import WhatsappAccountList from 'app/pages/settings/Whatsapp/WhatsappAccountList';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';

export const Whatsapp = () => {
    const { t } = useTranslation();
    const { data: whatsappAccountsData, isLoading: isWhatsappAccountsLoading } = useQuery(
        [WHATSAPP],
        WhatsappApiCalls.getAccountsList,
        {},
    );

    const actions = [<WhatsappAccountCreationButton key="whatsapp-header" />];

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('whatsapp_settings_title2')}
                    description={t('whatsapp_settings_description')}
                    actionsButton={actions}
                />
            }
        >
            <MenuListContentContainer>
                <WhatsappAccountList
                    isLoading={isWhatsappAccountsLoading}
                    accounts={whatsappAccountsData?.accounts}
                />
            </MenuListContentContainer>
        </MenuListContentTemplate>
    );
};
