import { call, put, takeLatest } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import {
    SEARCH_ORGANISATIONS,
    SearchOrgsAction,
    searchOrgsFailure,
    searchOrgsSuccess,
} from 'app/common/reducers/orgs';

import formatOrgs from 'app/common/services/formatOrgs';
import { Saga } from 'app/common/types';

import {
    FuzzySearchOrgsReceivedData,
    SearchOrgParams,
} from '../../api/types/org';

function* searchOrgs({ page, query, provider }: SearchOrgsAction): Saga {
    try {
        const Query: SearchOrgParams = {
            page,
            provider: provider || undefined,
            query,
        };
        const data: FuzzySearchOrgsReceivedData = yield call(
            api.org.searchOrgs,
            Query,
        ) as any;
        const { byId, ids } = formatOrgs(data.orgs);
        yield put(searchOrgsSuccess(byId, ids, data.max_page));
    } catch (error) {
        yield put(searchOrgsFailure(error));
    }
}

function* searchOrgsSaga(): Saga {
    yield takeLatest(SEARCH_ORGANISATIONS, searchOrgs);
}

export default searchOrgsSaga;
