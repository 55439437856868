import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

type ListItemProps = {
    active: boolean;
    disabled?: boolean;
};
type ListItemTextProps = {
    active: boolean;
};
export const DropdownContainer = styled.div`
    display: flex;
    position: relative;
`;
export const DropdownTextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2px;
`;
export const IconsContainer = styled.div`
    display: flex;
    font-size: 14px;
    flex-direction: column;
    > i {
        margin: -5px 0;
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
`;
export const DropdownListContainer = styled.div`
    background-color: ${HEX_COLORS.white};
    min-width: 130px;
    box-shadow: 0px 5px 10px rgba(20, 37, 66, 0.16);
    border-radius: 4px;
    position: absolute;
    top: 36px;
    right: 0;
    z-index: 100;
`;
export const DropdownList = styled.ul`
    margin: 8px 0;
    padding: 0 8px;
`;
export const ListItem = styled.button<ListItemProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    background-color: ${props => (props.active ? 'rgba(0, 133, 242, 0.12)' : HEX_COLORS.white)};
    color: ${({ active, theme }) =>
        active ? theme.colors.primary.initial : theme.colors.default.initial};
    border: none;
    border-radius: 4px;
    padding: 10px 8px;
    list-style: none;
    transition: all 0.4s;
    width: 100%;
    cursor: pointer;
    &:disabled {
        background-color: rgba(215, 219, 223, 0.4);
        color: rgba(215, 219, 223, 0.8);
        cursor: not-allowed;
    }
    > i {
        color: ${({ theme }) => theme.colors.primary.initial};
    }
    &:hover {
        background-color: ${props => (props.active ? 'rgba(0, 133, 242, 0.12)' : '#F6F8FC')};
    }
    &:last-child {
        margin-bottom: 0;
    }
`;
export const ListItemText = styled.span<ListItemTextProps>`
    margin-right: 8px;
    font-weight: ${props => (props.active ? 700 : 300)};
    font-size: 14px;
    line-height: 18px;
    text-align: left;
`;
