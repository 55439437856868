import { useTranslation } from 'react-i18next';

import { NoDataIcon } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Icons';
import {
    KeywordsDataContainer,
    ListContainer,
    ListItem,
    ListItemKey,
    ListItemValue,
    NoDataContainer,
    NoDataKeywordsText,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/Keywords/KeywordsCard/QueriesCard/QueriesCardContent/QueriesCardContent.styled';
import { ViewMore } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Keywords/KeywordsCard/QueriesCard/ViewMore/ViewMore';

import { LoaderView } from 'app/pages/analytics/VisibilityAnalyticsV2/components/LoaderView/LoaderView';
import { useKeywordsDataKpis } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/keywords/kpis/useKeywordsDataKpis';
import { useKeywordsLoadingKpis } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/keywords/kpis/useKeywordsLoadingKpis';

export const QueriesCardContent = () => {
    const MAX_NUMBER_TO_DISPLAY = 3;
    const { t } = useTranslation();
    const isLoading = useKeywordsLoadingKpis();
    const { data: dataKpis } = useKeywordsDataKpis();

    if (isLoading) {
        return <LoaderView height={50} />;
    }

    return (
        <>
            <KeywordsDataContainer>
                {dataKpis && dataKpis.length > 0 ? (
                    <>
                        <ListContainer>
                            {dataKpis
                                .slice(0, MAX_NUMBER_TO_DISPLAY)
                                .map(keyword => (
                                    <ListItem key={keyword.keyword}>
                                        <ListItemKey
                                            variant="bodyMRegular"
                                            as="span"
                                            color="secondary"
                                        >
                                            {keyword.keyword}
                                        </ListItemKey>
                                        <ListItemValue
                                            variant="bodyMRegular"
                                            as="span"
                                        >
                                            {keyword.count}
                                        </ListItemValue>
                                    </ListItem>
                                ))}
                        </ListContainer>
                        {dataKpis.length > MAX_NUMBER_TO_DISPLAY && (
                            <ViewMore keywords={dataKpis} />
                        )}
                    </>
                ) : (
                    <NoDataContainer>
                        <NoDataIcon />
                        <NoDataKeywordsText>
                            {t('no_data_available_time_range')}
                        </NoDataKeywordsText>
                    </NoDataContainer>
                )}
            </KeywordsDataContainer>
        </>
    );
};
