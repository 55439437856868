import React from 'react';

import { LoadingRowContainer, LoadingRowContent } from './LoadingRow.styled';

const LoadingRow = () => (
    <LoadingRowContainer>
        <LoadingRowContent />
        <LoadingRowContent />
        <LoadingRowContent />
        <LoadingRowContent />
    </LoadingRowContainer>
);

const LoadingState = () => (
    <>
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
    </>
);

export default LoadingState;
