import React, { ChangeEvent, MouseEvent, useRef, useState } from 'react';

import { css } from '@emotion/react';
import { Chip, IconPrefix, List, Switch, Text, toast } from '@partoohub/ui';
import { format, formatDistance } from 'date-fns';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';
import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';
import { useChatbotActivation } from 'app/pages/settings/Chatbot/hooks/useChatbotActivation';
import { useChatbotDelete } from 'app/pages/settings/Chatbot/hooks/useChatbotDelete';
import { useChatbotDuplicate } from 'app/pages/settings/Chatbot/hooks/useChatbotDuplicate';
import { ChatbotType } from 'app/pages/settings/Chatbot/type/chatbot';
import { ActionsContainer } from 'app/pages/settingsV2/subPages/Messages/components/Chatbots/ChatbotList.styled';

/**
 * Jim name doesn't change, we'll hardcode it for now instead of using a translation
 */
export const JIM_HARDCODED_NAME = 'Jim';

interface Props {
    chatbot: ChatbotType;
    isLastCard: boolean;
}

export const ChatbotCard = ({ chatbot, isLastCard }: Props) => {
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();
    const optionButtonRef = useRef<HTMLDivElement>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const ChatbotActivationMutation = useChatbotActivation(chatbot.id);
    const ChatbotDeleteMutation = useChatbotDelete(chatbot.id);
    const useChatbotDuplicateMutation = useChatbotDuplicate();

    const onEdit = (chatbot_id: number) => {
        searchParams.set('chatbot_id', chatbot_id.toString());
        setSearchParams(searchParams);
    };

    const onCardClick = (e: MouseEvent<HTMLDivElement>, chatbot_id: number) => {
        if (optionButtonRef.current && !optionButtonRef.current.contains(e.target as Node)) {
            onEdit(chatbot_id);
        }
    };

    const onToggle = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
            setShowDeactivateModal(true);
        } else {
            ChatbotActivationMutation.mutate(true);
        }
    };

    const toastSuccess = () => {
        toast.success(null, t('success'));
    };

    const onMenuItemClick = (option: string, chatbot_id: number) => {
        switch (option) {
            case 'edit':
                onEdit(chatbot_id);
                break;
            case 'duplicate':
                useChatbotDuplicateMutation.mutate(chatbot_id, { onSuccess: toastSuccess });
                break;
            case 'delete':
                setShowDeleteModal(true);
                break;
        }
    };

    return (
        <div>
            <div onClick={e => onCardClick(e, chatbot.id)}>
                <List.Row
                    id={'chatbot ' + chatbot.id}
                    dataTrackId=""
                    rowCssContainer={css`
                        height: 100%;
                        overflow-y: unset;
                        &:hover {
                            transform: unset;
                        }
                        cursor: pointer;
                    `}
                >
                    <List.Cell>
                        <Text variant="bodyMSemibold">{chatbot.name ?? JIM_HARDCODED_NAME}</Text>
                    </List.Cell>
                    <List.Cell>
                        {chatbot.enabled ? (
                            <Chip dataTrackId="" chipShape="cube" variant="emerald">
                                <Text color="emerald" variant="bodySBold">
                                    {t('chatbot_active_state')}
                                </Text>
                            </Chip>
                        ) : (
                            <Chip dataTrackId="" chipShape="cube" variant="warning">
                                <Text color="warning" variant="bodySBold">
                                    {t('chatbot_paused_state')}
                                </Text>
                            </Chip>
                        )}
                    </List.Cell>
                    <List.Cell>
                        <Text>
                            {chatbot.updated_at &&
                                formatDistance(new Date(chatbot.updated_at), new Date(), {
                                    addSuffix: true,
                                })}
                        </Text>
                    </List.Cell>
                    <List.Cell>
                        <Text>{format(chatbot.created_at, 'P')}</Text>
                    </List.Cell>
                    <List.Cell
                        css={css`
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                        `}
                    >
                        <Chip dataTrackId="" chipShape="cube" variant="theme">
                            <Text>{chatbot.business_ids.length}</Text>
                        </Chip>
                        <ActionsContainer>
                            <Switch
                                dataTrackId="chatbot_configuration_activation"
                                name="chatbot-activation"
                                checked={chatbot.enabled}
                                onChange={onToggle}
                                disabled={!chatbot.business_ids.length}
                            />
                            <div ref={optionButtonRef}>
                                <ActionButtonWithMenu
                                    dataTrackId="chatbot_configuration_action_button"
                                    icon={['fa-ellipsis-v', IconPrefix.SOLID]}
                                    appearance="outlined"
                                    options={[
                                        {
                                            name: 'edit',
                                            label: (
                                                <MenuLabelItem
                                                    text={t('edit')}
                                                    icon={['fa-marker', IconPrefix.REGULAR]}
                                                />
                                            ),
                                            value: 'edit',
                                            dataTrack:
                                                'chatbot_configuration__click__chatbot_edit_button',
                                        },
                                        {
                                            name: 'duplicate',
                                            label: (
                                                <MenuLabelItem
                                                    text={t('duplicate')}
                                                    icon={['fa-clone', IconPrefix.REGULAR]}
                                                />
                                            ),
                                            value: 'duplicate',
                                            dataTrack:
                                                'chatbot_configuration__click__chatbot_duplicate_button',
                                        },
                                        {
                                            name: 'delete',
                                            label: (
                                                <MenuLabelItem
                                                    text={t('delete')}
                                                    icon={['fa-trash', IconPrefix.REGULAR]}
                                                    color="danger"
                                                />
                                            ),
                                            value: 'delete',
                                            dataTrack:
                                                'chatbot_configuration__click__chatbot_delete_button',
                                        },
                                    ]}
                                    onMenuClick={option => onMenuItemClick(option, chatbot.id)}
                                    cssMenu={css`
                                        top: 50px;
                                        right: 0;
                                        left: unset;
                                        ${isLastCard && 'bottom: 50px; top: unset;'}
                                    `}
                                />
                            </div>
                        </ActionsContainer>
                    </List.Cell>
                </List.Row>
            </div>
            <ConfirmModal
                show={showDeleteModal}
                title={t('chatbot_configuration_delete_modal_title')}
                content={t('chatbot_configuration_delete_modal_content')}
                confirmLabel={t('delete')}
                modalFor="info"
                onConfirm={() => {
                    ChatbotDeleteMutation.mutate(undefined, { onSuccess: toastSuccess });
                    setShowDeleteModal(false);
                }}
                onHide={() => setShowDeleteModal(false)}
                trackId="chatbot_configuration__delete_modal"
            />
            <ConfirmModal
                show={showDeactivateModal}
                title={t('chatbot_configuration_deactivate_modal_title')}
                content={t('chatbot_configuration_deactivate_modal_content')}
                confirmLabel={t('chatbot_configuration_deactivate_button')}
                modalFor="warning"
                onConfirm={() => {
                    ChatbotActivationMutation.mutate(false);
                    setShowDeactivateModal(false);
                }}
                onHide={() => setShowDeactivateModal(false)}
                trackId="chatbot_configuration__deactivate_modal"
            />
        </div>
    );
};
