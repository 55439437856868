// CONSTANTS
export const DEFAULT_IS_QUALITATIVE = false;
export const DEFAULT_IS_PERIOD = true;
export const DATA_IS_OUTDATED = 'Received Data is not up to date';
export const GET_REVIEW_STATS_TOTAL_REQUEST = 'GET_REVIEW_STATS_TOTAL_REQUEST';
export const GET_REVIEW_STATS_TOTAL_SUCCESS = 'GET_REVIEW_STATS_TOTAL_SUCCESS';
export const GET_REVIEW_STATS_TOTAL_FAILURE = 'GET_REVIEW_STATS_TOTAL_FAILURE';
export const GET_REVIEW_STATS_PERIOD_REQUEST =
    'GET_REVIEW_STATS_PERIOD_REQUEST';
export const GET_REVIEW_STATS_PERIOD_SUCCESS =
    'GET_REVIEW_STATS_PERIOD_SUCCESS';
export const GET_REVIEW_STATS_PERIOD_FAILURE =
    'GET_REVIEW_STATS_PERIOD_FAILURE';
export const GET_REVIEWS_PERIOD_TOTAL_REQUEST =
    'GET_REVIEWS_PERIOD_TOTAL_REQUEST';
export const UPDATE_IS_QUALITATIVE = 'UPDATE_IS_QUALITATIVE';
export const GET_REVIEWS_EVOLUTION_REQUEST = 'GET_REVIEWS_EVOLUTION_REQUEST';
export const GET_REVIEWS_EVOLUTION_SUCCESS = 'GET_REVIEWS_EVOLUTION_SUCCESS';
export const GET_REVIEWS_EVOLUTION_FAILURE = 'GET_REVIEWS_EVOLUTION_FAILURE';
export const RESET_EVOLUTION_TOTAL_ANALYTICS =
    'RESET_EVOLUTION_TOTAL_ANALYTICS';
export const RESET_EVOLUTION_PERIOD_ANALYTICS =
    'RESET_EVOLUTION_PERIOD_ANALYTICS';
export const UPDATE_REVIEWS_EVOLUTION_BUCKET =
    'UPDATE_REVIEWS_EVOLUTION_BUCKET';
