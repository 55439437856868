import { isMobile } from 'app/common/services/screenDimensions';
import { parseSearchParams } from 'app/states/reviews/filters/parseSearchParams';

import { ReviewFiltersState } from './types';
import { ReviewFiltersActionType } from '../actions';
import * as constants from '../actions/constants';

const generateInitialState = (): ReviewFiltersState => {
    const {
        date: { from, to },
        keyword,
    } = parseSearchParams(window.location);

    return {
        date: {
            from,
            to,
        },
        rating: [],
        platform: [],
        message: [],
        keyword,
        tag: [],
        pending: {
            date: {
                from,
                to,
                focusedInput: null,
            },
            rating: [],
            platform: [],
            message: [],
            keyword,
            tag: [],
            navTab: constants.LIVE_REVIEWS,
            displaySearchBar: false,
        },
        displaySearchBar: false,
        mobileShow: false,
    };
};

export const initialState = generateInitialState();

// since the initial state is computed from search params, it may
// not have empty values for search params related attributes
const resetState = {
    ...initialState,
    date: {
        ...initialState.date,
        from: null,
        to: null,
    },
    keyword: [],
    pending: {
        ...initialState.pending,
        date: {
            ...initialState.pending.date,
            from: null,
            to: null,
        },
        keyword: [],
    },
};

export const reviewFiltersReducer = (
    state: ReviewFiltersState = initialState,
    action: ReviewFiltersActionType,
): ReviewFiltersState => {
    switch (action.type) {
        case constants.SET_DATE_FILTER:
            return {
                ...state,
                ...(!isMobile()
                    ? {
                          date: {
                              ...state.date,
                              from: action.from,
                              to: action.to,
                          },
                      }
                    : {}),
                pending: {
                    ...state.pending,
                    date: {
                        ...state.pending.date,
                        from: action.from,
                        to: action.to,
                    },
                },
            };

        case constants.SET_INPUT_DATE_FILTER:
            return {
                ...state,
                ...(!isMobile()
                    ? {
                          date: { ...state.date, focusedInput: action.input },
                      }
                    : {}),
                pending: {
                    ...state.pending,
                    date: { ...state.pending.date, focusedInput: action.input },
                },
            };

        case constants.REMOVE_TAG_FILTER:
            return {
                ...state,
                pending: {
                    ...state.pending,
                    tag: state.pending.tag.filter(t => t !== action.tag),
                },
            };

        case constants.RESET_REVIEW_FILTER:
            return action.name !== 'all'
                ? {
                      ...state,
                      [action.name]: resetState[action.name],
                      pending: {
                          ...state.pending,
                          ...(resetState.pending[action.name] && {
                              [action.name]: resetState[action.name],
                          }),
                      },
                  }
                : resetState;

        case constants.RE_INIT_REVIEW_FILTERS:
            return generateInitialState();

        case constants.RESET_REVIEW_TOOLBAR_FILTERS:
            return { ...state, ...resetState };

        case constants.RESET_PENDING_FILTER:
            return {
                ...state,
                pending:
                    action.name !== 'all'
                        ? {
                              ...state.pending,
                              [action.name]: resetState[action.name],
                          }
                        : state.pending,
            };

        case constants.DISPAY_REVIEW_MOBILE_FILTERS:
            return { ...state, mobileShow: true };

        case constants.HIDE_REVIEW_MOBILE_FILTERS:
            return { ...state, mobileShow: false };

        default:
            return state;
    }
};
export default reviewFiltersReducer;
