import React from 'react';

import { useTranslation } from 'react-i18next';

import useCompletionRate from 'app/businessEditV2/hooks/business/useCompletionRate';
import { ColorProgress } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import {
    COLORS_PROGRESS,
    LOW_RATE,
    MID_RATE,
} from 'app/businessEditV2/utils/businessBanner';

import {
    Badge,
    BusinessStatusCompletionContainer,
} from './BusinessStatusCompletion.styled';
import BusinessPercentageValue from '../BusinessPercentageValue/BusinessPercentageValue';
import BusinessProgressBar from '../BusinessProgressBar/BusinessProgressBar';

export const getBusinessStatusCompletionInfos = (
    completionRate: number | null,
) => {
    if (completionRate === null) {
        return COLORS_PROGRESS[ColorProgress.NONE];
    }
    if (completionRate <= LOW_RATE) {
        return COLORS_PROGRESS[ColorProgress.LOW];
    }
    if (completionRate > LOW_RATE && completionRate <= MID_RATE) {
        return COLORS_PROGRESS[ColorProgress.MEDIUM];
    }
    return COLORS_PROGRESS[ColorProgress.HIGH];
};

const BusinessStatusCompletion = () => {
    const { t } = useTranslation();
    const completionRate = useCompletionRate();
    const color = getBusinessStatusCompletionInfos(completionRate);

    return (
        <BusinessStatusCompletionContainer
            data-track="visibility_location__section_status__completion_score"
            data-intercom-target="visibility_location__section_status__completion_score"
        >
            <BusinessProgressBar value={completionRate} color={color} />
            <BusinessPercentageValue color={color.gradient.middle} />
            <Badge color={color.gradient.middle}>
                {t('business_edit_status_completion_completed')}
            </Badge>
        </BusinessStatusCompletionContainer>
    );
};
export default BusinessStatusCompletion;
