import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { HOLIDAYS } from 'app/common/data/queryKeysConstants';
import { useLanguage } from 'app/common/services/getLang';

export default function useHolidays() {
    const { data: business } = useBusiness();
    const lang = useLanguage();
    return useQuery(
        [HOLIDAYS, { country: business?.country, lang }],
        () => api.holidays.getHolidays(business?.country ?? 'FR', lang),
        {
            enabled: !!business,
        },
    );
}
