import styled from '@emotion/styled';

export const StyledFeedbackResultPanelHeader = styled.div`
    display: flex;
    gap: 16px;
`;

export const AvatarContainer = styled.div`
    flex-shrink: 0;
`;
