import React from 'react';

import { Checkbox, Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useBusinessCount from 'app/pages/visibility/BusinessListV2/hooks/businesses/useBusinessCount';
import useDisplayBulkCheckbox from 'app/pages/visibility/BusinessListV2/hooks/businessSelection/useDisplayBulkCheckbox';

import {
    BusinessCount,
    BusinessSortCompletion,
    BusinessSortSectionContainer,
} from './BusinessSortSection.styled';
import BusinessSortText, { OrderBy } from './BusinessSortText/BusinessSortText';
import { useBusinessSelectionContext } from '../../hooks/businessSelection/useBusinessSelectionContext';

const BusinessSortSection = () => {
    const { t } = useTranslation();
    const businessCount = useBusinessCount();
    const { displayBulkCheckbox } = useDisplayBulkCheckbox();
    const { selectOrUnselectAll, checkboxState } = useBusinessSelectionContext();

    return (
        <>
            <BusinessCount>
                {t('business_list_your_businesses')} ({businessCount})
            </BusinessCount>
            <BusinessSortSectionContainer displayBulkCheckbox={displayBulkCheckbox}>
                <Stack gap="16px" direction="row" alignItems="center">
                    {displayBulkCheckbox && (
                        <Checkbox
                            dataTrackId="business_list__bulk_select_all"
                            aria-label=""
                            checked={checkboxState}
                            onChange={selectOrUnselectAll}
                        />
                    )}
                    <BusinessSortText title={t('name')} orderByKey={OrderBy.NAME} />
                </Stack>
                <BusinessSortCompletion>
                    <BusinessSortText
                        title={t('business_list_completion')}
                        orderByKey={OrderBy.COMPLETION_RATE}
                    />
                </BusinessSortCompletion>
            </BusinessSortSectionContainer>
        </>
    );
};

export default BusinessSortSection;
