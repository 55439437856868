import React from 'react';

import FoundLocationsSection from 'app/businessConnection/components/modals/ConnectionModal/SearchLocationsSection/FoundLocationsSection';
import LocationSearchBar from 'app/businessConnection/components/modals/ConnectionModal/SearchLocationsSection/LocationSearchBar.container';

const SearchLocationsSection = () => (
    <div>
        <LocationSearchBar
            leftIcon={<i className="fa-solid fa-search" />}
            focusOnInput
        />
        <FoundLocationsSection />
    </div>
);

export default SearchLocationsSection;
