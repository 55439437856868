import { Button, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import BusinessModalButton from 'app/common/components/businessModal';
import PageHeader from 'app/common/designSystem/components/molecules/PageHeader';
import { collapseSidebar } from 'app/common/reducers/global';
import dataLayer from 'app/common/utils/dataLayer';
import {
    ReviewSettingsMenu,
    useCanAccessReviewsSetting,
} from 'app/pages/settingsV2/hooks/Reviews/useCanAccessReviewsSetting';
import { AnalyticsButton } from 'app/reviewManagement/reviewList/sections/PageHeader/DesktopHeader/components/AnalyticsButton';
import { DownloadButton } from 'app/reviewManagement/reviewList/sections/PageHeader/DesktopHeader/components/DownloadButton';
import {
    CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH,
    SETTINGS_REVIEWS_AUTO_REPLY_PATHNAME,
} from 'app/routing/routeIds';

import { navTabSelector } from 'app/states/reviews/filters';
import {
    DELETED_REVIEWS,
    LIVE_REVIEWS,
    NOT_TREATED_REVIEWS,
    TREATED_REVIEWS,
} from 'app/states/reviews/filters/actions/constants';

export function DesktopHeader() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        menuAccess: {
            [ReviewSettingsMenu.AUTO_REPLY_WITHOUT_COMMENT]: withoutCommentsSettings,
            [ReviewSettingsMenu.AUTO_REPLY_WITH_COMMENT]: withCommentsSettings,
        },
    } = useCanAccessReviewsSetting();
    const hasAutoReplySettingsAccess = withoutCommentsSettings || withCommentsSettings;

    const onViewAutoReplySettingsClick = (): void => {
        dataLayer.pushDict('click_auto_reply_settings_button');
        navigate(`${SETTINGS_REVIEWS_AUTO_REPLY_PATHNAME}`);
    };

    const getPageButtons = () => {
        const buttonList = [
            <BusinessModalButton
                key="business-modal-key"
                dataTrack="reviews__business_filter"
                resetReducerOnUnmount={false}
            />,
        ];

        if (hasAutoReplySettingsAccess) {
            buttonList.unshift(
                <Button
                    key="auto-reply-button"
                    dataTrackId="review_header_button_to_auto_reply_settings"
                    appearance="outlined"
                    shape="cube"
                    variant="theme"
                    icon={['fa-gear', IconPrefix.SOLID]}
                    onClick={onViewAutoReplySettingsClick}
                >
                    {t('auto_reply_short')}
                </Button>,
            );
        }

        buttonList.unshift(<DownloadButton key="review-download-button" />);
        buttonList.unshift(<AnalyticsButton key="review-analytics-button" />);

        return buttonList;
    };

    const getCurrentTitle = () => {
        if (location.pathname === CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH) {
            return t('clients_reviews');
        }

        const tab = navTabSelector();
        switch (tab) {
            case LIVE_REVIEWS:
                return t('live_reviews_title');

            case TREATED_REVIEWS:
                return t('treated_reviews_title');

            case NOT_TREATED_REVIEWS:
                return t('untreated_reviews_title');

            case DELETED_REVIEWS:
                return t('deleted_reviews_title');

            default:
                return t('live_reviews_title');
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <PageHeader
                title={getCurrentTitle()}
                collapseSidebar={() => dispatch(collapseSidebar())}
                pageButtons={getPageButtons()}
            />
        </div>
    );
}
