import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { ArrowPosition } from './helpers';

type SlidesContainerProps = {
    isSliding?: boolean;
    slideAnimationTimeInMs: number;
    position: string;
};

type SlidesProps = {
    width: string;
};

type ArrowProps = {
    position: ArrowPosition;
    direction: 'left' | 'right';
    isVisible: boolean;
};

type DotProps = {
    isActive: boolean;
};

export const CarouselWrapper = styled.div`
    overflow: hidden;
    position: relative;
`;

export const SlidesContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    transform: ${(props: SlidesContainerProps) => `translateX(${props.position})`};

    ${(props: SlidesContainerProps) =>
        props.isSliding &&
        css`
            transition: transform ${props.slideAnimationTimeInMs / 1000}s ease-in-out;
        `}
`;

export const Slide = styled.div`
    width: ${(props: SlidesProps) => props.width};
    flex-shrink: 0;
`;

const handleArrowPosition = (position: ArrowPosition) => {
    switch (position) {
        case 'top':
            return 25;
        case 'bottom':
            return 75;
        case 'middle':
        default:
            return 50;
    }
};

export const Arrow = styled.div`
    position: absolute;
    top: ${(props: ArrowProps) => handleArrowPosition(props.position)}%;
    display: ${(props: ArrowProps) => (props.isVisible ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: ${HEX_COLORS.white};
    transform: translateY(-50%);
    color: ${({ theme }) => theme.colors.secondary.initial};
    box-shadow: 1px 1px ${greyColorObject.initial};
    cursor: pointer;

    ${(props: ArrowProps) =>
        props.direction === 'left'
            ? css`
                  left: 0;
                  border-radius: 100px 100px 100px 100px;
                  margin-left: 8px;
              `
            : css`
                  right: 0;
                  border-radius: 100px 100px 100px 100px;
                  margin-right: 8px;
              `}
`;

export const Control = styled.div`
    display: flex;
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, -50%);
`;

export const ControlDot = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props: DotProps) =>
        props.isActive ? HEX_COLORS.blackberry : greyColorObject.light};
    cursor: pointer;

    &:not(:first-of-type) {
        margin-left: 4px;
    }

    &:not(:last-of-type) {
        margin-right: 4px;
    }
`;

export const GradientRight = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 101px;
    height: 206px;
    background: linear-gradient(270deg, #ffffff 1.6%, rgba(255, 255, 255, 0) 100%);
    transform: translateY(-100%);
    right: 0;
`;
