import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import PageHeader from 'app/common/designSystem/components/molecules/PageHeader';
import { collapseSidebar } from 'app/common/reducers/global';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';

import { ExportButton } from './ExportButton';

export const HeaderSection = () => {
    const dispatch = useDispatch();
    const toggleSidebar = useCallback(() => dispatch(collapseSidebar()), []);
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);

    return (
        <PageHeader
            title={'analytics'}
            collapseSidebar={isBelowProvidedDevices ? toggleSidebar : undefined}
            pageButtons={[<ExportButton key="--export-analytics-button-key--" />]}
        />
    );
};
