import { get, isArray, reduce, split } from 'lodash-es';

import { V2FormattedLocationData } from 'app/api/types/account';
import { V2FormattedBusinessData } from 'app/api/types/business';
import { V2FormattedOrgData } from 'app/api/types/org';

export const GMB_LOCATIONS_TERMS_TO_SEARCH_IN = [
    'locationName',
    'addressDetails',
    'locality',
    'postalCode',
    'storeCode',
];

export const PARTOO_BUSINESS_TERMS_TO_SEARCH_IN = [
    'name',
    'addressFull',
    'city',
    'zipcode',
    'code',
];
export const PARTOO_BUSINESS_SEARCH_IN_CITY = ['city'];
export const ORGS_TERMS_TO_SEARCH_IN = ['name', 'alias'];

const SPACE = ' ';
const COMMA = ',';

type ObjectToFilter =
    | V2FormattedBusinessData
    | V2FormattedLocationData
    | V2FormattedOrgData;

export const commaSplitter = (str: string) =>
    str
        .split(COMMA)
        .map(a => a.trim())
        .filter(a => a);

const valuesToCheckContainRegex = (
    valuesToCheck: Array<string>,
    regex: RegExp,
): boolean =>
    reduce(
        valuesToCheck,
        // @ts-ignore
        (check, value) => check || (value && value.toString().match(regex)),
        false,
    );

const eachQueryTokenIsPresent = (
    valuesToCheck: Array<string>,
    regexes: Array<RegExp>,
): boolean => {
    const regexesCount = regexes.length;

    for (let i = 0; i < regexesCount; i += 1) {
        if (!valuesToCheckContainRegex(valuesToCheck, regexes[i])) {
            return false;
        }
    }

    return true;
};

const getValuesToCheck = (
    obj: ObjectToFilter,
    keysToCheck: Array<string>,
): Array<string> =>
    keysToCheck.map(key => {
        const value = get(obj, key, '');

        if (isArray(value)) {
            return value.join(SPACE);
        }

        return value;
    });

const escapeStringForRegExp = (str: string): string =>
    str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping

const fuzzySearch = (
    objList: Array<Record<string, any>>,
    keysToCheck: Array<string>,
    query: string,
): Array<ObjectToFilter> => {
    const queryTokenRegexes = (split(query, SPACE) as any as Array<string>).map(
        value => new RegExp(escapeStringForRegExp(value), 'gi'),
    );
    // @ts-ignore
    return objList.filter(obj =>
        eachQueryTokenIsPresent(
            // @ts-ignore
            getValuesToCheck(obj, keysToCheck),
            queryTokenRegexes,
        ),
    );
};

/** @deprecated
 *  Client-side search is usually not needed, and can create performance
 *  problems in some cases.
 *
 *  On very rare occasions, client-side search may be needed. For example,
 *  in the business modal :
 *  - search for `Paris`
 *  - click `Select all`
 *  - search for `Par`
 *  - scroll down
 *  -> we are loading businesses matching `Par` but only the ones matching
 *     `Paris` must be shown as selected.
 *
 *  In this case use `useTsQuerySimple` which matches exactly the algorithm
 *  used in the backend.
 */
const commaFuzzySearch = (
    objList: Array<Record<string, any>>,
    keysToCheck: Array<string>,
    query: string,
) => [
    // @ts-ignore
    ...(query ? commaSplitter(query) : ['']).reduce(
        // @ts-ignore
        (set, a) => new Set([...set, ...fuzzySearch(objList, keysToCheck, a)]),
        new Set(),
    ),
];

export const businessIsSearched = (
    business: V2FormattedBusinessData,
    keysToCheck: Array<string>,
    query: string,
): boolean => !!commaFuzzySearch([business], keysToCheck, query).length;

export default commaFuzzySearch;
