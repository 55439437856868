import { useMemo } from 'react';

import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';
import useDebounce from 'app/common/hooks/useDebounce';

export default function useDebouncedPublisherErrors(watch, formatPayload) {
    const watchValues = watch();
    const memo = useMemo(() => watchValues, Object.values(watchValues));
    const debouncedForm = useDebounce(memo, 300);
    const { data: prechecksData } = usePublisherErrors(
        formatPayload(debouncedForm),
    );

    return prechecksData;
}
