import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls/camel';
import { REPLY_TEMPLATE_PLACEHOLDERS } from 'app/common/data/queryKeysConstants';

const useGetTemplatePlaceholders = () => {
    const { data: placeholders } = useQuery(REPLY_TEMPLATE_PLACEHOLDERS, () =>
        api.review.getTemplatePlaceholders(),
    );
    return placeholders?.map(placeholder => ({
        ...placeholder,
        name: placeholder.value,
    }));
};

export default useGetTemplatePlaceholders;
