import _ from 'lodash-es';

// By using a separate reducer to store all "error" flags
// spares the developer from creating and handling this state at each
// reducer level => cleaner code.
export const errorReducer = (state: any = {}, action: any) => {
    const { type, error } = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE|RESET)/.exec(type);
    if (!matches) return state;
    const [, requestName, requestState] = matches;
    return { ...state, [requestName]: requestState === 'FAILURE' ? error : {} };
};

// To select a specific error message from the state use `createErrorMessageSelector`:
// Example:
//     const errorSelector = createErrorMessageSelector(['DELETE_COMMENT']);
// Where 'DELETE_COMMENT' represents the root name of your action constant.
export const createErrorMessageSelector = (actions: any) => (state: any) =>
    _(actions)
        .map(action => state[action])
        .compact()
        .first() || {};
