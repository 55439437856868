import { useEffect, useState } from 'react';

import { IconPrefix, LeftElementType, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useDebounce from 'app/common/hooks/useDebounce';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { ReviewsQueryKeys } from 'app/reviewManagement/reviewList/hooks/useGetReviewsFilters';

export function SearchFilter() {
    const { t } = useTranslation();

    const [queryValue, setQueryValue] = useStateQueryParams(ReviewsQueryKeys.SEARCH);
    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

    const debouncedSearch = useDebounce(searchValue, 500);

    useEffect(() => {
        setSearchValue(queryValue);
    }, [queryValue]);

    useEffect(() => {
        setQueryValue(debouncedSearch);
    }, [debouncedSearch]);

    const resetSearch = () => {
        setQueryValue('');
    };

    return (
        <div style={{ flex: '1' }}>
            <TextInput
                dataTrackId="review_filter_search"
                label={t('review_filter_search')}
                onChange={setSearchValue}
                value={searchValue ?? ''}
                isReset
                onReset={resetSearch}
                leftElement={['fa-search', IconPrefix.REGULAR]}
                leftElementType={LeftElementType.Icon}
            />
        </div>
    );
}
