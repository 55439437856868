import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledToggleBox } from 'app/common/designSystem/components/molecules/ToggleBox/ControlledToggleBox';
import usePartnersConnected from 'app/common/hooks/queries/usePartnersConnected';

import { DailyReportExpandableToggle } from './DailyReportExpandableToggle';
import { MonthlyWeeklyReportToggle } from './MonthlyWeeklyReportToggle/MonthlyWeeklyReportToggle';
import { ReviewReportContainer } from './ReportsForm.styled';
import { ReviewReportTitle } from './ReviewReportTitle';

export const ReportsForm = () => {
    const { t } = useTranslation();
    const hasPartners = usePartnersConnected();
    const { control } = useFormContext();

    return (
        <div>
            <ReviewReportContainer>
                <div className="toggle-box-container">
                    <div className="togglebox-content">
                        <div className="toggle-box-title">
                            <ReviewReportTitle />
                        </div>
                        <div className="toggle-box-description">
                            {t('review_report_description')}
                        </div>
                    </div>
                </div>
                <DailyReportExpandableToggle control={control} hasPartners={hasPartners} />
                <MonthlyWeeklyReportToggle
                    text={t('weekly_report')}
                    control={control}
                    name="reports.selectRmWeeklyReport"
                />
                <MonthlyWeeklyReportToggle
                    text={t('monthly_report')}
                    control={control}
                    name="reports.selectRmMonthlyReport"
                />
            </ReviewReportContainer>
            <ControlledToggleBox
                text={t('negative_reviews_notification_title')}
                description={t('negative_reviews_notification_description')}
                control={control}
                name="receiveReviewsEmailNotifications"
            />
        </div>
    );
};
