import styled from '@emotion/styled';

type PermissionsListSectionsContainerProps = {
    withMargin?: boolean;
};

export const PermissionsListSectionsContainer = styled.div<PermissionsListSectionsContainerProps>`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
