import styled from '@emotion/styled';

export const ButtonSelectContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
`;

export const IconContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: end;
`;
