import { useState } from 'react';

import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Dialog, Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import AccountApi from 'app/api/v2/api_calls/accountApiCalls';

import IS_IFRAME from 'app/common/data/iframe';
import { NUMBER_IMPORTED_OAUTH_ACCOUNTS, OAUTH_ACCOUNTS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

import { AccountDisconnectButtonContainer } from './AccountDisconnect.styled';

type Props = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    accountID: string;
};

export const AccountDisconnect = ({ showModal, setShowModal, accountID }: Props) => {
    const { t } = useTranslation();
    const [textButton, setTextButton] = useState<string>(t('disconnect'));
    const mutation = useMutation(() => AccountApi.disconnect(accountID), {
        onSuccess: () => {
            queryClient.invalidateQueries([OAUTH_ACCOUNTS]);
            queryClient.invalidateQueries([NUMBER_IMPORTED_OAUTH_ACCOUNTS]);
            setShowModal(false);
        },
        onError: () => {
            setTextButton(t('error'));
        },
    });

    const handleClose = () => {
        setShowModal(false);
    };

    const handleDelete = () => {
        mutation.mutate(accountID);
    };

    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Delete,
    };

    return (
        <Modal
            isOpen={showModal}
            closeModal={handleClose}
            overlayAppearance={IS_IFRAME ? 'iframe' : 'dark'}
        >
            <Dialog
                dataTrackId="delete_account"
                header={
                    <div style={{ paddingTop: '24px' }}>
                        <Illustration
                            dataTrackId="delete_service_modal__illustration"
                            illustration={illustrationElement}
                            width="320"
                            height="140"
                        />
                    </div>
                }
                subTitle={t('disconnect_account_explanation')}
                title={t('disconnect_account')}
            >
                <AccountDisconnectButtonContainer>
                    <Button
                        dataTrackId=""
                        variant="secondary"
                        size="large"
                        shape="cube"
                        appearance="outlined"
                        onClick={handleClose}
                        full
                    >
                        {t('undo')}
                    </Button>
                    <Button
                        dataTrackId=""
                        disabled={mutation.isLoading}
                        variant="danger"
                        size="large"
                        shape="cube"
                        full
                        onClick={handleDelete}
                    >
                        {mutation.isLoading ? t('loading') : textButton}
                    </Button>
                </AccountDisconnectButtonContainer>
            </Dialog>
        </Modal>
    );
};
