import { greyColorObject } from '@partoohub/branding';
import { Icon, IconPrefix, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { transitionBezier } from 'app/styles';

import {
    PasswordChecksContainer,
    PasswordChecksRow,
    passwordChecksContainer,
    passwordChecksContainerHidden,
} from './PasswordChecks.styled';

interface Props {
    hasCorrectLength: boolean;
    hasSpecialCharacter: boolean;
    hasUpperLowerInt: boolean;
    notPartooLocaloo: boolean;
    displayChecker: boolean;
}

export const PasswordChecks = ({
    hasCorrectLength,
    hasSpecialCharacter,
    hasUpperLowerInt,
    notPartooLocaloo,
    displayChecker,
}: Props) => {
    const { t } = useTranslation();

    return (
        <PasswordChecksContainer
            css={[
                transitionBezier,
                displayChecker ? passwordChecksContainer : passwordChecksContainerHidden,
            ]}
        >
            <Text variant="bodyMBold" as="span">
                {t('password_conditions')}
            </Text>
            <PasswordChecksRow>
                <Icon
                    icon={['fa-check-circle', IconPrefix.SOLID]}
                    color={hasCorrectLength ? 'success' : greyColorObject.initial}
                    iconSize="14px"
                />
                <Text variant="bodyMRegular" as="span" color="secondary">
                    {t('password_min_length')}
                </Text>
            </PasswordChecksRow>
            <PasswordChecksRow>
                <Icon
                    icon={['fa-check-circle', IconPrefix.SOLID]}
                    color={hasUpperLowerInt ? 'success' : greyColorObject.initial}
                    iconSize="14px"
                />
                <Text variant="bodyMRegular" as="span" color="secondary">
                    {t('password_lowercase_uppercase_number')}
                </Text>
            </PasswordChecksRow>
            <PasswordChecksRow>
                <Icon
                    icon={['fa-check-circle', IconPrefix.SOLID]}
                    color={hasSpecialCharacter ? 'success' : greyColorObject.initial}
                    iconSize="14px"
                />
                <Text variant="bodyMRegular" as="span" color="secondary">
                    {t('password_special_char')}
                </Text>
            </PasswordChecksRow>
            {!notPartooLocaloo && (
                <PasswordChecksRow>
                    <Icon
                        icon={['fa-times-circle', IconPrefix.SOLID]}
                        color={'danger'}
                        iconSize="14px"
                    />
                    <Text variant="bodyMRegular" as="span" color="danger">
                        {t('partoo_localoo_not_in_password')}
                    </Text>
                </PasswordChecksRow>
            )}
        </PasswordChecksContainer>
    );
};
