import styled from '@emotion/styled';

import { GetMaxWidthContentPage } from 'app/global.styled';

export const HomepageContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    ${GetMaxWidthContentPage}
`;
