import InfiniteScroll from 'react-infinite-scroller';

import useGroupsV2 from 'app/common/hooks/queries/useGroupsV2';

import Section from './Section';
import { SectionListContainer } from './SectionList.styled';

const SectionList = () => {
    const { query, sections } = useGroupsV2();
    const { fetchNextPage, hasNextPage, isFetchingNextPage } = query;

    return (
        <SectionListContainer>
            <InfiniteScroll
                hasMore={hasNextPage && !isFetchingNextPage}
                loadMore={() => fetchNextPage()}
                useWindow={false}
                getScrollParent={() => document.getElementById('react-content')}
            >
                {(sections ?? []).map(section => (
                    <Section key={section.id} section={section} />
                ))}
            </InfiniteScroll>
        </SectionListContainer>
    );
};

export default SectionList;
