import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Clickable from 'app/common/components/buttons/Clickable';

export const StyledClickable = styled(Clickable)<{ active?: boolean }>`
    border-radius: 4px;
    font-size: 12px;
    padding: 3px 16px;
    height: 46px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    font-weight: bold;

    .clickable {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            flex: 1;
            padding-right: 8px;
        }
    }

    ${({ active, theme }) =>
        active
            ? css`
                  border: none;
                  background-color: rgba(3, 159, 241, 0.12);
                  color: ${theme.colors.primary.initial} !important;
                  ::placeholder {
                      color: ${theme.colors.primary.initial} !important;
                  }
              `
            : css`
                  border: solid 1px rgba(51, 51, 51, 0.11);
                  color: ${theme.colors.secondary.initial};
                  ::placeholder {
                      color: ${theme.colors.secondary.initial} !important;
                  }
              `};
`;
