import React, { useEffect } from 'react';

import { Tooltip, TooltipPlacement } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { AccountPartnerName, V2FormattedLocationData } from 'app/api/types/account';
import LocationCard from 'app/businessConnection/components/common/LocationCard';
import { ClickableLocationCardContainer } from 'app/businessConnection/components/modals/ConnectionModal/LocationCardSelection/LocationCardSelection.styled';
import Clickable from 'app/common/components/buttons/Clickable';
import {
    BROKEN_LOCATION_STATUS,
    LocationStatusType,
    MISSING_LINK_LOCATION_STATUS,
    OWNED_LOCATION_STATUS,
} from 'app/states/knowledge/reducers/businessConnection/connection';

type Props = {
    location: V2FormattedLocationData;
    isSelected: boolean;
    editLocationsSelected: (locationId?: string, owningBusinessId?: string) => void;
    partnerName: AccountPartnerName;
    locationStatus?: LocationStatusType;
    owningBusinessId?: string;
    hasError?: boolean;
    tooltipPlacement: TooltipPlacement;
};

const LocationCardSelection = ({
    location,
    isSelected,
    editLocationsSelected,
    partnerName,
    locationStatus,
    owningBusinessId,
    hasError = false,
    tooltipPlacement,
}: Props) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (hasError) {
            editLocationsSelected();
        }
    }, [hasError]);

    let tooltipTextKey = '';
    switch (locationStatus) {
        case MISSING_LINK_LOCATION_STATUS:
        case BROKEN_LOCATION_STATUS:
            tooltipTextKey = 'onboarding_selection_page_location_card_permission_lost';
            break;
        case OWNED_LOCATION_STATUS:
            tooltipTextKey = 'partner_connection_location_status_connected';
            break;
    }

    const clickableLocationCard = (
        <Clickable
            onClick={() => editLocationsSelected(location.id, owningBusinessId)}
            disabled={tooltipTextKey !== ''}
        >
            <LocationCard
                hasError={hasError}
                isSelected={isSelected}
                location={location}
                partnerName={partnerName}
            />
        </Clickable>
    );
    return tooltipTextKey !== '' ? (
        <ClickableLocationCardContainer>
            <Tooltip
                placement={tooltipPlacement}
                text={t(tooltipTextKey)}
                maxWidth={135}
                zIndex={2000}
            >
                {clickableLocationCard}
            </Tooltip>
        </ClickableLocationCardContainer>
    ) : (
        clickableLocationCard
    );
};

export default LocationCardSelection;
