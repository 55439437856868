import { useTranslation } from 'react-i18next';

import BusinessModalButton from 'app/common/components/businessModal';
import { useAppDataContext } from 'app/common/contexts/appData';
import { ADMIN } from 'app/common/data/roles';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import { useIsLoadingMessaging } from 'app/pages/conversations/Messaging/hooks/useIsLoadingMessaging';
import { useConversationFiltersSetup } from 'app/reviewManagement/messaging/hooks/useConversationFiltersSetup';
import AddPartnersButtons from 'app/reviewManagement/messaging/messenger/AddPartnersButtons';
import { PageHeaderWithAvatar } from 'app/routing/components/PageHeaderWithAvatar';

export const MessagingPageHeader = () => {
    const { t } = useTranslation();
    const { me } = useAppDataContext();
    const isLoading = useIsLoadingMessaging();
    const hasSetupConversationFilters = useConversationFiltersSetup();

    // TODO: Tricia - TT-318
    const isNotAdmin = me.role !== ADMIN;
    const { subscribedToBM } = useBusinessSubscriptions();
    const showHeader = isNotAdmin && subscribedToBM && !isLoading && hasSetupConversationFilters;

    return (
        showHeader && (
            <PageHeaderWithAvatar
                title={t('messaging_title')}
                actions={[
                    <AddPartnersButtons key="modal" />,
                    <BusinessModalButton key="business-modal-key" />,
                ].filter(Boolean)}
            />
        )
    );
};
