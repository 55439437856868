import CustomFieldsCatalogModal from './modals/CustomFieldsCatalogModal';
import CustomFieldsCreateModal from './modals/CustomFieldsCreateModal';
import CustomFieldsCreateSectionModal from './modals/CustomFieldsCreateSectionModal';
import CustomFieldsDeleteModal from './modals/CustomFieldsDeleteModal';
import CustomFieldsEditModal from './modals/CustomFieldsEditModal';
import CustomFieldsSectionDeleteModal from './modals/CustomFieldsSectionDeleteModal';
import CustomFieldsSectionRenameModal from './modals/CustomFieldsSectionRenameModal';

const CustomFieldsModals = () => {
    return (
        <>
            <CustomFieldsCatalogModal />
            <CustomFieldsCreateModal />
            <CustomFieldsCreateSectionModal />
            <CustomFieldsDeleteModal />
            <CustomFieldsEditModal />
            <CustomFieldsSectionRenameModal />
            <CustomFieldsSectionDeleteModal />
        </>
    );
};

export default CustomFieldsModals;
