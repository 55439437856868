import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    AssignFilterOption,
    LOCAL_STORAGE_CONVERSATION_FILTERS,
    URLParams,
} from 'app/states/messaging';

import { useHasAssignConversationFeature } from '../useHasAssignConversationFeature';

export const useAssignParam = () => {
    const [assignParam] = useStateQueryParams(URLParams.ASSIGN);
    const { hasAssignConversationFeature, isLoading } = useHasAssignConversationFeature();

    if (!hasAssignConversationFeature && !isLoading) return '';

    const localStorageFilters = localStorage.getItem(LOCAL_STORAGE_CONVERSATION_FILTERS);
    const localAssignFilter = localStorageFilters
        ? JSON.parse(localStorageFilters)?.assignFilter
        : '';

    const defaultParam = isAssignParamValid(localAssignFilter)
        ? localAssignFilter
        : AssignFilterOption.ALL;

    return assignParam && isAssignParamValid(assignParam) ? assignParam : defaultParam;
};

const isAssignParamValid = (assignParam: string) => {
    const assignOptions = Object.values(AssignFilterOption);

    return assignOptions.includes(assignParam as AssignFilterOption);
};
