import { DIGIT_SEPARATOR } from 'app/businessEditV2/oldFromEditV1/data/constants';

const SEPARATORS = [DIGIT_SEPARATOR, 'h', 'H'];
const ALLOWED_CHARS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ...SEPARATORS];

/** Takes any string as input and parses hours and minutes into a human
 *  readable string of format `${hours}${DIGIT_SEPARATOR}${minutes}` or
 *  an empty string if nothing could be parsed.
 */
function formatHours(value: string): string {
    const chars = value.split('');
    let cleaned = chars
        .filter(char => ALLOWED_CHARS.includes(char)) // keep only allowed characters
        // turn all separators into DIGIT_SEPARATOR
        .map(char => (SEPARATORS.includes(char) ? DIGIT_SEPARATOR : char))
        .join('');

    // if there's no meaningfull characters, we don't return any hour
    if (cleaned.length === 0) {
        return '';
    }

    // if no separator is present
    if (!cleaned.includes(DIGIT_SEPARATOR)) {
        const head = cleaned.substring(0, 4); // we take the first four characters

        if (head.length <= 2) {
            // if there's one or two characters, we consider it as the hour
            cleaned = head + DIGIT_SEPARATOR;
        } else {
            // if there's three or four characters, the first two are the hour
            cleaned = head.slice(0, 2) + DIGIT_SEPARATOR + head.slice(2);
        }
    }

    // define hours as the last two characters before the first occurrence of the separator
    let hours = cleaned.split(DIGIT_SEPARATOR)[0].slice(-2);
    // define minutes as the first two characters after the first occurrence of the separator
    let minutes = cleaned.split(DIGIT_SEPARATOR)[1].slice(0, 2);
    hours = hours.padStart(2, '0');
    minutes = minutes.padEnd(2, '0');

    if (parseInt(hours, 10) > 23) {
        hours = '00';
        minutes = '00';
    } else if (parseInt(minutes, 10) > 59) {
        minutes = '59';
    }

    return `${hours}${DIGIT_SEPARATOR}${minutes}`;
}

export default formatHours;
