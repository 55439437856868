import { useState } from 'react';

import { ButtonsGroup, Dialog, Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { PublisherRawData } from 'app/api/types/publisher';

import { RequiredFieldsComponent } from './RequiredFields';
import { SyncedFieldsComponent } from './SyncedFields';

type Props = {
    publisher: PublisherRawData;
    onHide: () => void;
};

const PublisherBroadcastModal = ({ publisher, onHide }: Props) => {
    const { t } = useTranslation();

    const [selected, setSelected] = useState(0);

    const tabs = [
        {
            id: 0,
            text: t('synced_fields'),
        },
        {
            id: 1,
            text: t('required_configuration'),
        },
    ];

    return (
        <Dialog dataTrackId="" onClose={onHide} title={publisher.formatted_name}>
            <Stack direction="row" justifyContent="center">
                <ButtonsGroup selected={selected} onChange={value => setSelected(value as number)}>
                    <ButtonsGroup.ButtonGroup key={`tabs-${tabs[0].id}`} value={tabs[0].id}>
                        {tabs[0].text}
                    </ButtonsGroup.ButtonGroup>
                    <ButtonsGroup.ButtonGroup key={`tabs-${tabs[1].id}`} value={tabs[1].id}>
                        {tabs[1].text}
                    </ButtonsGroup.ButtonGroup>
                </ButtonsGroup>
            </Stack>
            {selected === 0 ? (
                <SyncedFieldsComponent publisher={publisher} />
            ) : (
                <RequiredFieldsComponent publisher={publisher} />
            )}
        </Dialog>
    );
};

export default PublisherBroadcastModal;
