import React from 'react';

import { IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { PublisherRawData } from 'app/api/types/publisher';
import { SectionWithIcon } from 'app/common/designSystem/components/atoms/SectionWithIcon/SectionWithIcon';
import { formatPublisherPMFields } from 'app/presence/management/formatPublisherPmFieldsService';

import { PublisherBroadcastModalContent } from './PublisherBroadcastModal.styled';

type Props = {
    publisher: PublisherRawData;
};

export const RequiredFieldsComponent: React.FC<Props> = ({ publisher }) => {
    const { t } = useTranslation();

    const formattedPublisherPMFields = formatPublisherPMFields(publisher.required_fields);
    const mainInfo = Object.entries(formattedPublisherPMFields.main_info).filter(
        item => item[1] !== '',
    );
    const mainInfoIsEmpty = Object.values(formattedPublisherPMFields.main_info).every(
        x => x === null || x === '',
    );

    return (
        <PublisherBroadcastModalContent>
            <SectionWithIcon
                iconName={['fa-info', IconPrefix.SOLID]}
                title={t('main_information')}
                items={
                    mainInfoIsEmpty
                        ? [['no_pm_field', t('no_required_field_needed')]]
                        : mainInfo.map(([key, value]) => [key, t(value)])
                }
            />
        </PublisherBroadcastModalContent>
    );
};
