import styled from '@emotion/styled';

export const LoadingBusinessCardContainer = styled.div`
    display: flex;
    width: 340px;
    height: 100%;
    margin-right: 20px;
    align-items: center;
    min-width: 0;
    border-radius: 4px;
    overflow: hidden;
`;

export const LoadingBusinessCardInfoContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-flow: nowrap column;
    justify-content: space-between;
    height: 64px;
`;
