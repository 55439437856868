import { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';

import { Button } from '@partoohub/ui';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import useMeUncamel from 'app/common/hooks/queries/useMeUncamel';
import useLocalStorage from 'app/common/hooks/useLocalStorage';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { canSendClevertapEventsSelector } from 'app/common/reducers/me';
import { PushNotifsEvent } from 'app/common/services/pushNotifications/events';
import { PushNotificationsWrapper } from 'app/common/services/pushNotifications/pushNotificationsWrapper';
import dataLayer from 'app/common/utils/dataLayer';
import useGetMessageReplyTemplates from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates/hooks/useGetMessageReplyTemplates';
import { PushNotificationsOptInPrompt } from 'app/reviewManagement/common/pushNotifications/optInPrompt';
import { useGetBusiness } from 'app/reviewManagement/messaging/hooks/useGetBusiness';
import { useGetReviewBoosterReviewUrl } from 'app/reviewManagement/messaging/hooks/useGetReviewBoosterReviewUrl';
import { usePostMessage } from 'app/reviewManagement/messaging/hooks/usePostMessage';

import { MessageReplyTemplate, USAGE } from 'app/states/messageReplyTemplate/dataTypes';
import {
    Conversation,
    ConversationMessage,
    MESSAGE_CONTENT_TYPE,
    MESSAGE_SENDER_TYPE,
    MESSAGE_STATUS,
    MessagingPartner,
    MessagingPartnerEnum,
    URLParams,
} from 'app/states/messaging';
import { maybeShowPushNotificationsOptInPrompt } from 'app/states/pushNotifs/actions';
import { meSelector } from 'app/states/reducers';

import { ImageFile, ImagePreviewList } from './ImageInput';
import ImagePicker from './ImagePicker';
import {
    ActionButtonsContainer,
    DisabledOverlay,
    InputsContainer,
    MessageFormContainer,
    MessageFormElement,
    StyledAutosizedTextArea,
    TextAreaButtonsContainer,
} from './MessageForm.styled';
import MessagingReplyTemplatesPopover from './MessagingReplyTemplatesPopover/MessagingReplyTemplatesPopover';
import ReplyTemplateButton from './ReplyTemplateButton';
import ReviewBoosterButton from './ReviewBoosterButton';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

type MessageFormData = {
    message: string;
};

type MessageFormProps = {
    conversation: Conversation;
    disabled: boolean;
};

// Holds the "drafts" (unsent message) in MessagingForm, keyed by conversationIds.
const MESSAGE_DRAFTS: { [conversationId: number]: string } = {};

const getChannelTextAreaLength = (partner: MessagingPartner) => {
    const channelTextAreaLength = {
        [MessagingPartnerEnum.GOOGLE]: 3072,
        [MessagingPartnerEnum.FACEBOOK]: 2000,
        [MessagingPartnerEnum.INSTAGRAM]: 1000,
        [MessagingPartnerEnum.SMS]: 960,
        [MessagingPartnerEnum.DELIGHT]: 3072,
    };
    return channelTextAreaLength[partner];
};

const MessageForm = ({ conversation, disabled = false }: MessageFormProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { data: me } = useMeUncamel();
    const canSendClevertapEvents = useSelector(canSendClevertapEventsSelector);
    const [images, setImages] = useState<Array<ImageFile>>([]);
    // Popover states
    const [isReplyTemplatePopoverOpen, setIsReplyTemplatePopoverOpen] = useState<boolean>(false);
    const [draftBeforeTemplatePopoverOpen, setDraftBeforeTemplatePopoverOpen] =
        useState<string>('');
    const [popoverEvent, setPopoverEvent] = useState<string>('');

    const [statusFilter] = useStateQueryParams(URLParams.STATUS);
    const postMessageMutation = usePostMessage(conversation, false);

    const { register, handleSubmit, watch, reset, setValue } = useForm();
    let messageTextArea: any = null;

    const [replyTemplateId, setReplyTemplateId] = useState<string>();
    const { data: businessData } = useGetBusiness(conversation.business.id);
    const { data: reviewBoosterData } = useGetReviewBoosterReviewUrl(
        conversation.business.id,
        true,
    );
    const [lastFormHeight, setLastFormHeight] = useState<number | null>(null);
    const formElementRef = useRef<HTMLDivElement>(null);
    const [isTemplatePreview, setIsTemplatePreview] = useState<boolean>(false);
    const [showConversationReplyTemplatePopInCache, setShowConversationReplyTemplatePopInCache] =
        useLocalStorage('show_conversation_reply_template_pop_in', true);
    const showConversationReplyTemplatePopIn =
        showConversationReplyTemplatePopInCache && !isReplyTemplatePopoverOpen;
    const { data } = useGetMessageReplyTemplates(
        USAGE,
        true,
        showConversationReplyTemplatePopInCache,
    );

    /**
     * We want to use our own ref instead of the one created by
     * react-hook-form so we deconstruct the return of register
     * to get all the rest params needed to create our input
     */
    const { ref, ...messageInputParams } = register('message', {
        required: false,
    });

    /**
     * Callback ref for MessageInput
     * We also use the callback ref from useForm
     * so that the same ref is shared
     */
    const setMessageInputRef = (element: HTMLTextAreaElement | null) => {
        ref(element);
        messageTextArea = element;
    };

    /**
     * We use custom watch hook from useForm
     * to detect when the input is edited
     */
    const watchInput = watch('message');

    /**
     * Focus the Message Input when the Container is focused
     */
    const onInputsContainerFocused = () => {
        if (messageTextArea) messageTextArea.focus();
    };

    const submitMessage = (postMessagePayload: ConversationMessage) => {
        postMessageMutation.mutate(postMessagePayload);
    };

    const onSubmit = (formData: MessageFormData) => {
        if (disabled) return;

        const userId = me?.id;
        const userLabel = `${me?.first_name} ${me?.last_name}`;
        dispatch(maybeShowPushNotificationsOptInPrompt());

        if (userId && userLabel) {
            if (images.length) {
                for (const image of images) {
                    const postMessagePayload = {
                        status: MESSAGE_STATUS.SENDING,
                        user_id: userId,
                        sender_label: userLabel,
                        sender_type: MESSAGE_SENDER_TYPE.USER,
                        content_type: MESSAGE_CONTENT_TYPE.IMAGE,
                        content: image,
                        date: dayjs().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSSZ'),
                        is_deleted: false,
                        is_automated: false,
                    };
                    submitMessage(postMessagePayload);
                }
            }

            const trimmedMessage = formData.message.trim();
            if (trimmedMessage) {
                const postMessagePayload = {
                    status: MESSAGE_STATUS.SENDING,
                    user_id: userId,
                    sender_label: userLabel,
                    sender_type: MESSAGE_SENDER_TYPE.USER,
                    content_type: MESSAGE_CONTENT_TYPE.TEXT,
                    content: trimmedMessage,
                    template_id: replyTemplateId || undefined,
                    date: dayjs().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSSZ'),
                    is_deleted: false,
                    is_automated: false,
                };
                submitMessage(postMessagePayload);
            }
            setImages([]);
            reset({ message: '' });
            setReplyTemplateId('');

            /**
             * Send user event SEND_MESSAGE to Clevertap
             * Disabled for a shadow user
             */
            if (canSendClevertapEvents) {
                const pushNotificationsWrapper = PushNotificationsWrapper.getInstance();
                pushNotificationsWrapper.sendEvent(PushNotifsEvent.SEND_MESSAGE, {
                    messaging_channel: conversation.messaging_partner || 'unknown',
                });
            }

            // Google Tag Manager
            dataLayer.pushDict('send_message', {
                messaging_channel: conversation.messaging_partner,
            });
        }
    };

    useEffect(() => {
        if (conversation.id && messageTextArea) {
            messageTextArea.focus();
            const value = MESSAGE_DRAFTS[conversation.id] ? MESSAGE_DRAFTS[conversation.id] : '';
            setValue('message', value);

            // Reset image selection when conversationId changes.
            setImages([]);
        }
    }, [conversation.id]);

    useEffect(() => {
        if (watchInput !== undefined) {
            // When react-hook-form initializes, the default value is
            // undefined. So we only update drafts when there is a string
            // value in watchInput.
            MESSAGE_DRAFTS[conversation.id] = watchInput;
        }
        if (isReplyTemplatePopoverOpen && popoverEvent === 'mouseleave')
            setDraftBeforeTemplatePopoverOpen(watchInput);
        // if the message is erased, we reset the template id
        if (!watchInput) setReplyTemplateId('');
    }, [watchInput]);

    useEffect(() => {
        // When the popover is opened we save its height to apply the correct min-height offset.
        if (isReplyTemplatePopoverOpen)
            setLastFormHeight(formElementRef.current?.scrollHeight || null);
    }, [isReplyTemplatePopoverOpen]);

    useEffect(() => {
        // Send event to GTM when the message template pop in is shown
        if (showConversationReplyTemplatePopIn && data?.length == 0) {
            const data = {
                user_id: me?.id,
                org_id: me?.org_id,
            };
            dataLayer.pushDict('show_message_template_pop_in', data);
        }
    }, [showConversationReplyTemplatePopIn, data]);

    const onHideConversationReplyTemplatePopIn = () => {
        if (showConversationReplyTemplatePopInCache) {
            setShowConversationReplyTemplatePopInCache(false);
            const data = { user_id: me?.id, org_id: me?.org_id };
            dataLayer.pushDict('Hide message template pop in', data);
        }
    };

    const handleReplyTemplateButtonClick = () => {
        setIsReplyTemplatePopoverOpen(!isReplyTemplatePopoverOpen);
        dataLayer.pushDict('open_reply_templates_modal_in_inbox', {
            messaging_channel: conversation.messaging_partner,
            business_id: conversation.business,
        });
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            if (watchInput?.trim() || images.length) {
                event.preventDefault();
                onSubmit({ message: watchInput });
            }
        } else if (event.key === '/' && watchInput === '') {
            // If message input is empty and we type /, we open the popover.
            event.preventDefault();
            handleReplyTemplateButtonClick();
        }
    };

    const onRemoveImage = (index: number) => {
        setImages(images.filter((_, ix) => ix !== index));
    };

    const meState = useSelector(meSelector);
    const meFirstName = meState.data?.firstName;

    // All available placeholders with their current value
    const placeholders = useMemo(
        () => ({
            client_full_name: conversation?.consumer_name,
            client_first_name: conversation?.consumer_name.split(' ')[0],
            business_name: conversation.business.name,
            my_first_name: meFirstName,
            address: conversation.business.formatted_address,
            rb_link: reviewBoosterData?.review_url,
            website_url: businessData?.website_url,
            phone_number: businessData?.contacts[0]?.phone_numbers[0],
        }),
        [meFirstName, conversation, businessData, reviewBoosterData],
    );

    // Capture all placeholders such as "Hello {{client_full_name}} how are you?"
    const replacePlaceholders = (templateText: string | null) => {
        return templateText?.replace(
            /{{([^}]+?)}}/g,
            (_match, placeholder_value) => placeholders[placeholder_value] || '',
        );
    };

    // Save draft text before template popover is opened
    useEffect(() => {
        if (isReplyTemplatePopoverOpen) {
            setDraftBeforeTemplatePopoverOpen(watchInput ? watchInput : '');
            messageTextArea?.blur();
        } else {
            messageTextArea?.focus();
        }
    }, [isReplyTemplatePopoverOpen]);

    // If template is hovered, displays its text inside the input box.
    // If mouse pointer leaves the template, draft text is editable.
    const setMessageContentAsReplyTemplateContent = (
        eventType: string,
        template?: MessageReplyTemplate,
    ) => {
        const templateText = template ? JSON.parse(template.content).text : '';

        switch (eventType) {
            case 'mousedown': {
                setValue('message', replacePlaceholders(templateText));
                setReplyTemplateId(template?.id);
                setIsReplyTemplatePopoverOpen(false);
                onHideConversationReplyTemplatePopIn();
                setIsTemplatePreview(false);
                setPopoverEvent(eventType);
                messageTextArea?.focus();
                break;
            }
            case 'mouseleave': {
                setValue('message', draftBeforeTemplatePopoverOpen);
                setIsTemplatePreview(false);
                setPopoverEvent(eventType);
                break;
            }
            case 'mouseenter': {
                setValue('message', replacePlaceholders(templateText));
                setIsTemplatePreview(true);
                setPopoverEvent(eventType);
                break;
            }
        }
    };

    const inputPlaceholder = conversation.is_solved
        ? 'message_textarea_placeholder_reopen_conv'
        : 'messaging_conversation_input_placeholder';

    const dataTrackId =
        statusFilter === 'closed'
            ? 'messaging_active_conversation_reopen_conversation_by_textarea'
            : '';

    const replyTemplatePopover = isReplyTemplatePopoverOpen && (
        <MessagingReplyTemplatesPopover
            setState={setIsReplyTemplatePopoverOpen}
            setMessageContentAsReplyTemplateContent={setMessageContentAsReplyTemplateContent}
            showConversationReplyTemplatePopIn={
                showConversationReplyTemplatePopInCache && data?.length == 0
            }
            onHideConversationReplyTemplatePopIn={onHideConversationReplyTemplatePopIn}
            conversation={conversation}
        />
    );

    const textArea = (
        <StyledAutosizedTextArea
            value={watchInput}
            maxRows={7}
            maxLength={getChannelTextAreaLength(conversation.messaging_partner)}
            textAreaRef={setMessageInputRef}
            placeholder={t(inputPlaceholder)}
            onKeyDown={handleKeyPress}
            isTemplatePreview={isTemplatePreview}
            lineBreakCommand={(event: any) =>
                event.key === 'Enter' && (event.ctrlKey || event.altKey || event.shiftKey)
            }
            {...messageInputParams}
            disabled={disabled}
        />
    );

    return (
        <MessageFormContainer
            ref={formElementRef}
            isTemplatePreview={isTemplatePreview}
            minHeight={lastFormHeight || 0}
        >
            {replyTemplatePopover}
            <MessageFormElement
                id="messaging-form"
                className={conversation.messaging_partner}
                isTemplatePreview={isTemplatePreview}
            >
                <InputsContainer
                    flexDirection="column"
                    tabIndex={0}
                    onFocus={onInputsContainerFocused}
                >
                    {disabled && <DisabledOverlay />}
                    <ImagePreviewList files={images} onRemove={onRemoveImage} />
                    {textArea}
                    <TextAreaButtonsContainer flexDirection="row-reverse" alignItems="center">
                        <div className="submit-button">
                            <Button
                                dataTrackId={dataTrackId}
                                size="medium"
                                shape="cube"
                                variant="primary"
                                appearance="contained"
                                onClick={handleSubmit(onSubmit)}
                                disabled={disabled || (!watchInput?.trim() && !images.length)}
                            >
                                {t('messaging_conversation_send_button')}
                            </Button>
                        </div>
                        <ActionButtonsContainer flexDirection="row-reverse" alignItems="center">
                            <ImagePicker
                                images={images}
                                setImages={setImages}
                                messagingPartner={conversation.messaging_partner}
                            />
                            <ReplyTemplateButton
                                showPopInCache={showConversationReplyTemplatePopInCache}
                                isPopoverOpen={isReplyTemplatePopoverOpen}
                                onButtonClick={handleReplyTemplateButtonClick}
                                onPopInClick={onHideConversationReplyTemplatePopIn}
                            />
                            <ReviewBoosterButton
                                reviewUrl={reviewBoosterData?.review_url}
                                setValue={setValue}
                            />
                        </ActionButtonsContainer>
                    </TextAreaButtonsContainer>
                </InputsContainer>
            </MessageFormElement>
            <PushNotificationsOptInPrompt />
        </MessageFormContainer>
    );
};

export default MessageForm;
