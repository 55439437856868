import { sum, values } from 'lodash-es';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import {
    GET_GROUP_COUNT_REQUEST,
    GET_GROUP_REQUEST,
    GET_GROUP_REQUEST_WITHOUT_COUNT,
    GetGroupCountAction,
    UNGROUPED_ID,
    getGroupCount,
    getGroupCountFailure,
    getGroupCountSuccess,
    getGroupFailure,
    getGroupSuccess,
    groupDataSelector,
    pageSelector,
    searchQuerySelector,
    setCountBySubId,
} from 'app/common/reducers/groups';
import aggregateGroupCountService from 'app/common/services/aggregateGroupCount';
import formatGroupData from 'app/common/services/formatGroupDataService';
import { Saga } from 'app/common/types';
import { groupStateSelector } from 'app/states/reducers';

interface CountById {
    all?: number;
}

function* getGroupCounts({ filters }: GetGroupCountAction): Saga {
    const groupState = yield select(groupStateSelector);
    const groups = groupDataSelector(groupState);
    const countById: CountById = {};

    try {
        // add ungrouped business to count them
        const groupDatas = groups
            ? [
                  ...groups,
                  {
                      groupId: UNGROUPED_ID,
                  },
              ]
            : [];
        groupDatas.forEach(group => {
            countById[group.groupId] = 0;
        });
        const counts = yield call(api.groups.getGroupStats, filters);
        counts.forEach(item => {
            countById[item.id ? item.id : UNGROUPED_ID] = item.businesses;
        });
        countById.all = sum(values(countById));
        const aggregateCountById = aggregateGroupCountService(
            groupDatas,
            countById,
        );
        yield put(getGroupCountSuccess(aggregateCountById));
        yield put(setCountBySubId(countById));
    } catch (error) {
        yield put(getGroupCountFailure(error));
    }
}

function* getGroup({ withCount }): Saga {
    try {
        const groupState = yield select(groupStateSelector);
        const page = pageSelector(groupState);
        const searchQuery = searchQuerySelector(groupState);
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { groups, max_page } = yield call(
            api.groups.getGroups,
            page,
            searchQuery,
        );
        const formattedGroupData = groups.map(formatGroupData);
        yield put(getGroupSuccess(formattedGroupData, max_page));
        if (withCount) yield put(getGroupCount());
    } catch (error) {
        yield put(getGroupFailure(error));
    }
}

function* getGroupSaga(): Saga {
    yield takeEvery(GET_GROUP_REQUEST, getGroup, {
        withCount: true,
    });
    yield takeEvery(GET_GROUP_REQUEST_WITHOUT_COUNT, getGroup, {
        withCount: false,
    });
    yield takeEvery(GET_GROUP_COUNT_REQUEST, getGroupCounts);
}

export default getGroupSaga;
