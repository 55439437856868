const defaultFormater = json => json;

const extractBadRequestErrors = (
    errors: Record<string, any>,
    formatter: (
        arg0: Record<string, any>,
    ) => Record<string, any> = defaultFormater,
): Record<string, any> | string => {
    if (errors?.response?.data?.errors?.json) {
        return formatter(errors.response.data.errors.json);
    }

    if (errors?.response?.data?.errors?.authentication) {
        return formatter(errors.response.data.errors.authentication);
    }

    return {};
};

export default extractBadRequestErrors;
