import useFacebookAttributeUrl from 'app/businessEditV2/hooks/attributes/attributesBusiness/useFacebookAttributeUrl';
import useFormattedBusinessAttributesUrl from 'app/businessEditV2/hooks/attributes/attributesBusiness/useFormattedBusinessAttributesUrl';
import useTwitterAttributeUrl from 'app/businessEditV2/hooks/attributes/attributesBusiness/useTwitterAttributeUrl';
import useFormattedAttributesUrl from 'app/businessEditV2/hooks/attributes/attributesMetadata/useFormattedAttributesUrl';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';
import { isMissingAllAttributesUrls } from 'app/businessEditV2/utils/attributes';

import { ColorState } from './types';

export default function useLinksSectionColor(): ColorState {
    // Hooks
    const { data: business } = useBusiness();
    const { data: publisherErrors } = usePublisherErrors({});
    const attributesUrls = useFormattedAttributesUrl();
    const businessAttributes = useFormattedBusinessAttributesUrl();
    const isClosed = useIsBusinessClosed();

    const websitePermission = useFieldMetadata('links', 'website_url')?.enabled;
    const facebookPermission = useFieldMetadata('links', 'facebook_url')?.enabled;
    const twitterPermission = useFieldMetadata('links', 'twitter_url')?.enabled;
    const attributesUrlPermission = useFieldMetadata('links', 'attributes_url')?.enabled;

    const facebookAttributeUrl = useFacebookAttributeUrl();
    const twitterAttributeUrl = useTwitterAttributeUrl();

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // Disabled
    if (
        !websitePermission &&
        !facebookPermission &&
        !twitterPermission &&
        (attributesUrls.length > 0 ? !attributesUrlPermission : true)
    ) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (
        publisherErrors?.links?.website_url ||
        publisherErrors?.links?.facebook_url ||
        publisherErrors?.links?.twitter_url ||
        (attributesUrls.length > 0 ? publisherErrors?.links?.attributes_url : false)
    ) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields if no url is filled
    const missingAllAttributesUrls = isMissingAllAttributesUrls(businessAttributes, attributesUrls);

    if (
        !business?.website_url &&
        !(facebookAttributeUrl || business?.facebook_url) &&
        !(twitterAttributeUrl || business?.twitter_url) &&
        missingAllAttributesUrls
    ) {
        return ColorState.MISSING_WARNING;
    }

    // OK
    return ColorState.OK;
}
