import styled from '@emotion/styled';

export const ConvHeaderImgLoader = styled.div`
    margin: 8px;
`;

export const ConvHeaderNameLoader = styled.div`
    overflow: hidden;
    min-width: 0;
    max-width: 70%;
`;
