import { useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { CONVERSATIONS_MESSAGING_PATH } from 'app/routing/routeIds';
import { URLParams } from 'app/states/messaging';

import { useGetConversations } from './useGetConversations';
import { MessagingContext } from '../MessagingContext';

export const useConversationSetup = () => {
    const navigate = useNavigate();
    const [conversationParamId] = useStateQueryParams(URLParams.CONVERSATION_ID);
    const { conversations } = useGetConversations();
    const { activeConversation } = useContext(MessagingContext);
    useEffect(() => {
        /**
         * Checking for activeConversation is important since setting
         * an active conversation also changes the ref of conversations
         * It avoids running the effect twice
         */
        if (conversations.length && !activeConversation?.id && !conversationParamId) {
            navigate(`${CONVERSATIONS_MESSAGING_PATH}?conversationId=${conversations[0].id}`);
        }
    }, [JSON.stringify(conversations), activeConversation]);
};
