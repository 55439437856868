import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import { Text } from '@partoohub/ui';

export const ApiKeyListingHeaderContainer = styled.div`
    border-radius: 4px;
    background: ${greyColorObject.light};
    font-size: 10px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary.initial};
    padding: 10px 72px 10px 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const StyledKeyText = styled(Text)`
    text-align: center;
    margin: 0;
`;

export const StyledItemText = styled.div<{
    small?: boolean;
    big?: boolean;
    isKey?: boolean;
}>`
    margin: 0 16px;
    flex-basis: 100%;
    ${({ small }) =>
        small &&
        css`
            flex-basis: 25% !important;
        `};
    ${({ big }) =>
        big &&
        css`
            flex-basis: 175% !important;
        `};
    ${({ isKey }) =>
        isKey &&
        css`
            text-align: center;
            margin: 0;
        `};
`;
