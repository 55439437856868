import { LoadingRectangleContainer } from './LoadingRectangle.styled';

type Props = {
    recWidth?: string;
    recHeight?: string;
    recMargin?: string;
    recClass?: string;
    flexFill?: boolean;
    recRadius?: string;
    className?: string;
};

const LoadingRectangle = ({
    recWidth = '',
    recHeight = '',
    recMargin = '',
    recClass = '',
    recRadius = '',
    flexFill = false,
    className,
}: Props) => (
    <LoadingRectangleContainer
        flexFill={flexFill}
        className={`${recClass || ''} ${className}`}
        style={{
            width: recWidth,
            height: recHeight,
            margin: recMargin || '0',
            borderRadius: recRadius,
        }}
    />
);

export default LoadingRectangle;
