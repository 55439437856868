import { FilterMap, FilterName } from '@partoohub/modular-components';

import { useGetReviewsFilters } from 'app/reviewManagement/reviewList/hooks/useGetReviewsFilters';
import { WITHOUT_TAG } from 'app/states/reviews';
import { LIVE_REVIEWS } from 'app/states/reviews/filters';

const getFilterFromStateQuery = (
    stateQuery: string,
    filterValues?: (value: string) => boolean,
): FilterMap => {
    if (!stateQuery) {
        return new Map();
    }
    let values = stateQuery.split(',');
    if (filterValues) {
        values = values.filter(filterValues);
    }
    const map = new Map(values.map(value => [value, true]));
    return map;
};

export const useGetFiltersFromStateQuery = () => {
    const { platforms, rating, status, comments, tags, reviewObjectType } = useGetReviewsFilters();

    const filters = {
        [FilterName.SOCIAL_MEDIA]: getFilterFromStateQuery(platforms),
        [FilterName.REVIEW_TYPE]: getFilterFromStateQuery(rating),
        [FilterName.STATUS]: getFilterFromStateQuery(status, status => status !== LIVE_REVIEWS),
        [FilterName.CONTENT]: getFilterFromStateQuery(comments),
        [FilterName.TAGS]: getFilterFromStateQuery(
            tags.split(',').includes(`${WITHOUT_TAG}`) ? `${WITHOUT_TAG}` : tags,
        ),
        [FilterName.REVIEW_OBJECT_TYPE]: getFilterFromStateQuery(reviewObjectType),
    };

    return filters;
};
