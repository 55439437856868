import styled from '@emotion/styled';

export const QuickConnectModalLoading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
`;

export const LoadingStateExplanation = styled.div`
    color: #7f8ea4;
    font-size: 20px;
`;

export const LoadingStateIndicator = styled.div`
    width: 60px;
    height: 60px;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
`;

export const LoadingElement = styled.div`
    width: 50px;
    height: 50px;
    margin: 100px auto;
    background-color: white;

    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
    background-color: ${({ theme }) => theme.colors.primary.initial};
`;

export const QuickConnectModalPlaceholder = styled.div`
    height: auto;
    margin-bottom: 32px;
`;

export const QuickConnectModalSuggestions = styled.div`
    overflow-y: auto;
    margin-bottom: 32px;

    .suggestions_description {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        font-weight: bold;

        .suggestions_description_text {
            color: #333333;
            font-size: 18px;
            line-height: 21px;
        }
    }

    .suggestions_header {
        display: flex;
        height: 32px;
        align-items: center;
        background-color: #f5f7fb;
        margin-bottom: 16px;
        padding: 0 16px;
        font-size: 10px;
        line-height: 12px;
        color: #7f8ea4;
        letter-spacing: 0.909091px;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 4px;

        > span {
            padding: 0 40px;
        }
    }

    .suggestions_list {
        display: flex;
        flex-direction: column;

        > * {
            margin-bottom: 16px;
        }
    }
`;
