import { format } from 'date-fns';

import { ReviewEvolutionQueryParams } from 'app/api/types/review';
import generateFiltersFromBusinessModal from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { FilterByBusinessState } from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import { WITHOUT_TAG } from 'app/states/reviews';
import {
    ReviewFiltersState,
    activeReviewFiltersSelector,
    keywordsQuerySelector,
    tagFilterSelector,
} from 'app/states/reviews/filters';

import { ReviewAnalyticsState } from '../reducers';
import { bucketSelector, isPeriodSelector, isQualitativeSelector } from '../selectors';

export const filterQueryParams = (
    reviewFilters: ReviewFiltersState,
    reviewAnalytics: ReviewAnalyticsState,
    filterByBusiness: FilterByBusinessState,
    enableNewGroups: boolean = true,
): ReviewEvolutionQueryParams => {
    const { dateFrom, dateTo } = activeReviewFiltersSelector(reviewFilters);
    const tagsList = tagFilterSelector(reviewFilters);
    const handleTagFilters = (tagIds: number[]) => {
        const hasWithoutTagFilter = tagIds && tagIds.indexOf(WITHOUT_TAG) >= 0;
        let extractedTagsList = tagIds;
        if (hasWithoutTagFilter) {
            extractedTagsList = tagIds.filter(item => item != WITHOUT_TAG);
        }
        const res = {
            tags: extractedTagsList.join(',') || undefined,
            without_tag: hasWithoutTagFilter || undefined,
        };
        return res;
    };

    const dateFromFilter = dateFrom ? format(dateFrom, 'yyyy-MM-dd\'T\'HH:mm:ss') : null;
    const dateToFilter = dateTo ? format(dateTo, 'yyyy-MM-dd\'T\'HH:mm:ss') : null;
    return {
        ...generateFiltersFromBusinessModal(filterByBusiness, false, enableNewGroups),
        update_date__gte: dateFromFilter,
        update_date__lte: dateToFilter,
        cumulative: !(isPeriodSelector(reviewAnalytics) && isQualitativeSelector(reviewAnalytics)),
        bucket: reviewAnalytics.evolution ? bucketSelector(reviewAnalytics) : null,
        keywords: keywordsQuerySelector(reviewFilters),
        ...handleTagFilters(tagsList),
    };
};
