import { useState } from 'react';

import { Checkbox, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import Collapsible from 'app/common/components/Collapsible';

import { ConfirmModal, useConfirmModal } from 'app/common/components/ConfirmModalV2';
import { sectionsTranslations } from 'app/pages/settingsV2/subPages/Team/components/Permissions/utils/translations';
import { PermissionsSectionType } from 'app/pages/settingsV2/subPages/Team/components/Permissions/utils/typing';
import { checkboxSection } from 'app/pages/settingsV2/subPages/Team/components/Permissions/utils/utils';

import PermissionsField from './PermissionsField';

import {
    PermissionsSectionCheckbox,
    PermissionsSectionClickable,
    PermissionsSectionContainer,
    PermissionsSectionExpand,
    PermissionsSectionHeader,
} from './PermissionsSection.styled';
import { usePermissionsChange } from '../../../../hooks/usePermissionsChange';

type Props = {
    value: PermissionsSectionType;
    onChange: (value: PermissionsSectionType) => void;
};

const PermissionsSection = ({ value, onChange }: Props) => {
    const { t } = useTranslation();
    const [showMore, setShowMore] = useState(false);
    const { askConfirmation, confirm, modalProps } = useConfirmModal();
    const { onSectionPermissionChange, onFieldPermissionChange } = usePermissionsChange({
        value,
        onChange,
        askConfirmation,
    });

    const checkboxValueGM = checkboxSection(value.fields, true);
    const checkboxValueBM = checkboxSection(value.fields, false);

    return (
        <PermissionsSectionContainer>
            <ConfirmModal
                {...modalProps}
                modalFor="warning"
                trackId="permission-change-confirm"
                title={t('permission_confirm_modal_title')}
                onConfirm={() => confirm(true)}
            />
            <PermissionsSectionHeader>
                <PermissionsSectionClickable onClick={() => setShowMore(!showMore)}>
                    <Text variant="heading6">
                        {t(sectionsTranslations[value.name as keyof typeof sectionsTranslations])}
                    </Text>
                    <PermissionsSectionExpand variant="bodyMBold" color="secondary">
                        {t(showMore ? 'hide_details' : 'see_details')}
                        <i className={`fas fa-chevron-${showMore ? 'down' : 'right'} fa-xs`} />
                    </PermissionsSectionExpand>
                </PermissionsSectionClickable>

                <PermissionsSectionCheckbox>
                    <Checkbox
                        dataTrackId="permission_section_gm__checkbox"
                        checked={checkboxValueGM}
                        onChange={() => onSectionPermissionChange(true)}
                    />
                    <Checkbox
                        dataTrackId="permission_section_bm__checkbox"
                        checked={checkboxValueBM}
                        onChange={() => onSectionPermissionChange(false)}
                    />
                </PermissionsSectionCheckbox>
            </PermissionsSectionHeader>

            <Collapsible collapsed={!showMore} deps={[value.fields]}>
                {value.fields.map(field => (
                    <PermissionsField
                        key={`field_${field.name}`}
                        fieldName={field.name}
                        checkedGM={!!field.enabled_for_gm}
                        checkedBM={!!field.enabled_for_bm}
                        disabled={!field.enabled}
                        onCheckboxClick={gm => onFieldPermissionChange(field.name, gm)}
                        isCustomField={value.name === 'custom_fields'}
                    />
                ))}
            </Collapsible>
        </PermissionsSectionContainer>
    );
};

export default PermissionsSection;
