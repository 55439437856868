import { useCallback, useState } from 'react';

import { Button, TextInput, toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { InteractionTagType } from 'app/api/types/interactionTag';
import API from 'app/api/v2/api_calls';
import {
    displayModalSelector,
    hideConfirm,
    showConfirm,
} from 'app/common/components/ConfirmModal/reducers/confirm';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { REVIEW_LIST, REVIEW_TAGS } from 'app/common/data/queryKeysConstants';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { useUpdateReviewTagsQueryData } from 'app/reviewManagement/reviewList/hooks/queryData/useUpdateReviewTagsQueryData';
import { ReviewsQueryKeys } from 'app/reviewManagement/reviewList/hooks/useGetReviewsFilters';
import {
    TagEditButtonSection,
    TagEditColor,
    TagEditColorList,
    TagEditColorSection,
    TagEditColorTitle,
    TagEditHr,
    TagEditModal as TagEditModalStyled,
    TagEditPreviousButton,
} from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/TagSection/TagEditModal.styled';
import queryClient from 'app/states/queryClient';
import { confirmModalSelector } from 'app/states/reducers';

const AVAILABLE_TAG_COLORS = [
    '#808080',
    '#9B7CDB',
    '#F47FBE',
    '#4D4D4D',
    '#9E6957',
    '#2F8DE4',
    '#37CED0',
    '#53C944',
    '#B1DA34',
    '#F78234',
    '#F4BD38',
    '#992842',
];

type Props = {
    tag: InteractionTagType;
    onHide: () => void;
};

export default function TagEditModal({ tag, onHide }: Props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const updateReviewTagsQueryData = useUpdateReviewTagsQueryData();
    const [label, setLabel] = useState<string>(tag.label);
    const [color, setColor] = useState<string>(tag.color);
    const [errors, setErrors] = useState<any>({});

    const [queryValue, setQueryValue] = useStateQueryParams(ReviewsQueryKeys.TAG);

    const showModal = displayModalSelector(useSelector(confirmModalSelector), 'delete_tag_modal');

    const onLabelChange = (value: string) => {
        setLabel(value.replace(/,/g, ''));
    };

    const onDeleteClick = useCallback(() => {
        dispatch(showConfirm('delete_tag_modal', { tag }));
    }, [tag]);

    const { mutate: updateTagMutate } = useMutation(
        () => API.interactionTag.update(tag.id, { label, color }),
        {
            onError: (e: any) => {
                if (e.response && e.response.status === 400) {
                    return setErrors(e.response.data.errors.json);
                }
                toast.error(t('update_error'), t('error'));
            },
            onSuccess: (data: InteractionTagType) => {
                queryClient.invalidateQueries({ queryKey: [REVIEW_TAGS] });
                // Updating the tag on all visible reviews
                updateReviewTagsQueryData(data);
                queryClient.invalidateQueries({
                    queryKey: [REVIEW_LIST],
                    refetchActive: false,
                });
                onHide();
            },
        },
    );

    const { mutate: deleteTagMutate } = useMutation(() => API.interactionTag.deleteTag(tag.id), {
        onMutate: () => dispatch(hideConfirm()),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [REVIEW_TAGS] });
            // Query params treatment
            const tagIdsInQuery = queryValue.split(',').map(Number);
            const deletedTagIndex = tagIdsInQuery.indexOf(tag.id);
            if (deletedTagIndex > -1) {
                tagIdsInQuery.splice(deletedTagIndex, 1);
                setQueryValue(tagIdsInQuery.join(','));
            }
            // Deleting the tag on all visible reviews
            updateReviewTagsQueryData(tag, true);
            onHide(); // Edit tag modal
        },
    });

    return (
        <TagEditModalStyled hasHeader position={'right'}>
            <TagEditPreviousButton onClick={onHide}>
                <i className="fa-solid fa-arrow-left"></i>
            </TagEditPreviousButton>
            <TextInput
                value={label}
                error={errors.hasOwnProperty('label')}
                notice={errors.hasOwnProperty('label') && errors.label}
                onChange={onLabelChange}
                characterCount={30}
                dataTrackId="reviews__tags_modal_input"
                autoFocus
            />
            <TagEditHr />
            <TagEditColorSection>
                <TagEditColorTitle>{t('review_edit_tag_color')}</TagEditColorTitle>
                <TagEditColorList>
                    {AVAILABLE_TAG_COLORS.map(c => {
                        return (
                            <TagEditColor
                                key={c}
                                className={color === c ? 'selected' : ''}
                                color={c}
                                onClick={() => setColor(c)}
                            />
                        );
                    })}
                </TagEditColorList>
            </TagEditColorSection>

            <TagEditButtonSection>
                <Button
                    dataTrackId="delete_edit_modal__button"
                    key={'delete'}
                    variant="danger"
                    shape="cube"
                    full
                    onClick={onDeleteClick}
                >
                    {t('delete')}
                </Button>
                <Button
                    dataTrackId="apply_edit_modal__button"
                    key={'apply'}
                    variant="primary"
                    shape="cube"
                    full
                    onClick={() => updateTagMutate()}
                >
                    {t('apply')}
                </Button>
            </TagEditButtonSection>
            <ConfirmModal
                modalFor="delete"
                show={showModal}
                onHide={() => dispatch(hideConfirm())}
                onConfirm={deleteTagMutate}
                title={t('review_delete_tag_modal_title')}
                content={t('review_delete_tag_warning')}
                trackId="delete_tag"
            />
        </TagEditModalStyled>
    );
}
