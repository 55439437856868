import IS_IFRAME from 'app/common/data/iframe';
import {
    ReviewSettingsMenu,
    useCanAccessReviewsSetting,
} from 'app/pages/settingsV2/hooks/Reviews/useCanAccessReviewsSetting';

export const useShowSettingsButton = () => {
    const {
        menuAccess: { [ReviewSettingsMenu.REPLY_TEMPLATES]: canAccessReplyTemplatesSettings },
    } = useCanAccessReviewsSetting();

    return !IS_IFRAME && canAccessReplyTemplatesSettings;
};
