import { Button } from '@partoohub/ui';
import { UseFormWatch } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { GROUP_MANAGER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';

const getLabel = (
    isBusinessCreateLoading: boolean,
    hasErrors: boolean,
    addressConfirmed: boolean,
): string => {
    if (isBusinessCreateLoading) {
        return 'loading';
    }

    if (hasErrors) {
        return 'creation_error';
    }

    if (!addressConfirmed) {
        return 'confirm_address_to_continue';
    }

    return 'add_business';
};

type Props = {
    watch: UseFormWatch<any>;
    isBusinessCreateLoading: boolean;
    hasErrors: boolean;
    submitForm: () => void;
};

const BusinessCreateSaveButton = ({
    watch,
    isBusinessCreateLoading,
    hasErrors,
    submitForm,
}: Props) => {
    const { t } = useTranslation();
    const addressConfirmed = watch('manualLat') && watch('manualLong');
    const { data: me } = useMe();
    const enableNewGroups = useNewGroups();
    const requiredGroups = enableNewGroups && me?.role === GROUP_MANAGER;
    const buttonLabel = getLabel(isBusinessCreateLoading, hasErrors, addressConfirmed);

    const formFilled: boolean =
        !!watch('name') &&
        !!watch('categories').length &&
        !!watch('city') &&
        !!watch('addressFull') &&
        !!watch('phoneNumber') &&
        (!requiredGroups || !!watch('groups').length);

    return (
        <Button
            size="large"
            appearance="contained"
            shape="cube"
            full
            disabled={!formFilled}
            variant={hasErrors ? 'danger' : 'primary'}
            isLoading={isBusinessCreateLoading}
            onClick={submitForm}
            dataTrackId="visibility_location__form_create_business__save_button"
        >
            {t(buttonLabel)}
        </Button>
    );
};

export default BusinessCreateSaveButton;
