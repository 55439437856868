import { PropsWithChildren } from 'react';

import {
    Button,
    ButtonAppearance,
    ButtonProps,
    ButtonSize,
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    toast,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

type ToastMessage = {
    title: string;
    description: string;
};

type Props = {
    isLoading: boolean;
    hasError: boolean;
    onClick: () => Promise<void> | void;
    dataTrackId: string;
    size?: ButtonSize;
    full?: boolean;
    appearance?: ButtonAppearance;
    disabled?: boolean;
    hideIcon?: boolean;
    successToast?: ToastMessage;
    errorToast?: ToastMessage;
};

const DEFAULT_SUCCESS_TOAST: ToastMessage = {
    title: 'setting_success_snackbar_changes_saved_title',
    description: 'setting_success_snackbar_changes_saved_description',
};
const DEFAULT_ERROR_TOAST: ToastMessage = {
    title: 'setting_error_snackbar_changes_saved_title',
    description: 'setting_success_snackbar_changes_saved_description',
};

export const SettingSaveButton = ({
    isLoading,
    hasError,
    onClick,
    dataTrackId,
    size = 'large',
    full = true,
    appearance = 'contained',
    successToast = DEFAULT_SUCCESS_TOAST,
    errorToast = DEFAULT_ERROR_TOAST,
    disabled = false,
    hideIcon = false,
    children,
}: PropsWithChildren<Props>) => {
    const { t } = useTranslation();

    const handleClick = async () => {
        try {
            await onClick();
            toast.success(t(successToast.description), t(successToast.title));
        } catch (error) {
            toast.error(t(errorToast.description), t(errorToast.title));
        }
    };

    const buttonProps: ButtonProps = {
        size,
        appearance,
        full,
        dataTrackId,
        shape: 'cube',
        iconPosition: 'left',
        variant: hasError ? 'danger' : 'primary',
        icon: isLoading
            ? undefined
            : hasError
              ? [FontAwesomeIconsPartooUsed.faFrown, IconPrefix.SOLID]
              : [FontAwesomeIconsPartooUsed.faFloppyDisk, IconPrefix.SOLID],
        disabled: disabled || isLoading,
        onClick: handleClick,
    };

    const getButtonLabel = () => {
        if (isLoading) {
            return 'loading';
        }
        if (hasError) {
            return 'update_error';
        }
        return 'save';
    };

    if (hideIcon) {
        buttonProps.icon = undefined;
    }

    return (
        <Button {...buttonProps} isLoading={isLoading} onClick={handleClick}>
            {children ?? t(getButtonLabel())}
        </Button>
    );
};
