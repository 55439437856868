import { useMutation } from 'react-query';

import { ExportPayload } from 'app/api/types/competitiveBenchmark';
import api from 'app/api/v2/api_calls';

export const usePostExportTask = (): {
    postExportTask: (payload: ExportPayload) => Promise<void>;
    isLoading: boolean;
} => {
    const { mutate: postExportTask, isLoading } = useMutation(
        (payload: ExportPayload): Promise<void> => {
            return api.competitiveBenchmark.postExportTask(payload);
        },
    );

    return {
        postExportTask: postExportTask as (payload: ExportPayload) => Promise<void>,
        isLoading,
    };
};
