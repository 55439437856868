import { createContext, useMemo, useState } from 'react';

export type EditPostsContextType = {
    postCreationModalEditMode: boolean;
    postId: string;
    bulkId: number | null;
    postToEditData: Record<string, any>;
    setPostCreationModalEditMode: (value: boolean) => void;
    setPostId: (value: string) => void;
    setBulkId: (value: number | null) => void;
    setPostToEditData: (post: Record<string, any>) => void;
    showCreationModal: boolean;
    setShowCreationModal: (value: boolean) => void;
    duplicateMode: boolean;
    setDuplicateMode: (value: boolean) => void;
    duplicateModeData: any;
    setDuplicateModeData: (value: any) => void;
};
export const EditPostsContext = createContext<EditPostsContextType>({} as any);

export const useEditPostContext = () => {
    const [showCreationModal, setShowCreationModal] = useState(false);
    const [postId, setPostId] = useState('');
    const [postCreationModalEditMode, setPostCreationModalEditMode] = useState(false);
    const [postToEditData, setPostToEditData] = useState({});
    const [duplicateMode, setDuplicateMode] = useState(false);
    const [duplicateModeData, setDuplicateModeData] = useState({});
    const [bulkId, setBulkId] = useState<number | null>(null);

    const editPostsContext: EditPostsContextType = useMemo(
        () => ({
            postCreationModalEditMode,
            postId,
            bulkId,
            postToEditData,
            setPostCreationModalEditMode,
            setPostId,
            setBulkId,
            setPostToEditData,
            showCreationModal,
            setShowCreationModal,
            duplicateMode,
            setDuplicateMode,
            duplicateModeData,
            setDuplicateModeData,
        }),
        [
            postCreationModalEditMode,
            postId,
            bulkId,
            postToEditData,
            duplicateMode,
            duplicateModeData,
        ],
    );

    return editPostsContext;
};
