import { useState } from 'react';

import {
    DynamicFilters,
    FilterCategory,
    FilterName,
    Filters as FiltersType,
    PartialFilters,
} from '@partoohub/modular-components';
import { FontAwesomeIconsPartooUsed } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { SelectableContentFilters } from 'app/common/components/SelectableContentFilters/SelectableContentFilters';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { useHideIntercom } from 'app/hooks/useHideIntercom';
import {
    UserListQueryKeys,
    UserStatus,
} from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';
import {
    BusinessListQueryKeys,
    SelectableContentOption,
} from 'app/pages/visibility/BusinessListV2/utils/enums';
import {
    concatTrueValues,
    createFilterMap,
} from 'app/pages/visibility/BusinessListV2/utils/helpers';

const Filters = () => {
    const { t } = useTranslation();

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [status, setStatus] = useStateQueryParams(UserListQueryKeys.STATUS);

    const filters = {
        [FilterName.STATUS]: createFilterMap(status),
    };

    const setFilters = (filters: PartialFilters) => {
        setStatus(concatTrueValues(filters, FilterName.STATUS));
    };

    const generateFilterOptions = () => {
        const statusOptionValues: Array<SelectableContentOption> = [
            {
                name: UserStatus.ACTIVE,
                icon: [FontAwesomeIconsPartooUsed.faCircleCheck],
                text: t('activated'),
                value: UserStatus.ACTIVE,
            },
            {
                name: UserStatus.INVITED,
                icon: [FontAwesomeIconsPartooUsed.faArrowsRotate],
                text: t('invited'),
                value: UserStatus.INVITED,
            },
        ];

        return statusOptionValues.map(option => ({
            name: option.name,
            label: <SelectableContentFilters icon={option.icon} text={t(option.text)} />,
            value: option.value,
        }));
    };

    const optionsStatus = generateFilterOptions();

    const schema: FiltersType = [
        {
            id: BusinessListQueryKeys.STATUS,
            property: FilterName.STATUS,
            sectionName: t('filter_by_status'),
            filterCategory: FilterCategory.SELECTABLE,
            options: optionsStatus,
        },
    ];

    useHideIntercom(isFilterOpen);

    return (
        <DynamicFilters
            schema={schema}
            filters={filters as PartialFilters}
            onApply={setFilters}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            resetText={t('reset')}
            applyText={t('apply')}
            title={t('filters')}
            buttonText={t('filters')}
        />
    );
};

export default Filters;
