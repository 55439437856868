import React from 'react';

import { SpecificHourFormPeriod } from 'app/api/types/business';

import { findFirstString } from 'app/businessEditV2/utils/utils';

import {
    OpenHourContainer,
    OpenHourContainerLeftRight,
} from 'app/common/components/OpenHour/OpenHour.styled';

import DateHeader from './DateHeader';
import DatesWithButtons from './DatesWithButtons';
import HoursContent from './HoursContent';
import Toggles from './Toggles';

type Props = {
    disabled: boolean;
    specificHour: SpecificHourFormPeriod;
    onChange: (specificHour: SpecificHourFormPeriod) => void;
    bankHolidayName?: string;
    duplicateSpecificHour: () => void;
    deleteSpecificHour: () => void;
    applyDefaultHours: () => void;
    errors: any;
};

const SpecificOpenHour = ({
    disabled,
    specificHour,
    bankHolidayName,
    duplicateSpecificHour,
    deleteSpecificHour,
    applyDefaultHours,
    onChange,
    errors,
}: Props) => {
    // Errors
    const hasDateError = !!errors.starts_at || !!errors.ends_at;
    const hoursError = findFirstString(errors.open_hours) ?? '';

    return (
        <OpenHourContainer>
            <OpenHourContainerLeftRight>
                <DateHeader
                    starts_at={specificHour.starts_at}
                    ends_at={specificHour.ends_at}
                    bankHolidayName={bankHolidayName}
                />
                <Toggles
                    disabled={disabled}
                    specificHour={specificHour}
                    onChange={onChange}
                />
            </OpenHourContainerLeftRight>

            <OpenHourContainerLeftRight>
                <DatesWithButtons
                    disabled={disabled}
                    specificHour={specificHour}
                    onChange={onChange}
                    duplicateSpecificHour={duplicateSpecificHour}
                    deleteSpecificHour={deleteSpecificHour}
                    applyDefaultHours={applyDefaultHours}
                    hasDateError={hasDateError}
                />

                <HoursContent
                    disabled={disabled}
                    specificHour={specificHour}
                    onChange={onChange}
                    hoursError={hoursError}
                />
            </OpenHourContainerLeftRight>
        </OpenHourContainer>
    );
};

export default SpecificOpenHour;
