import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    ApiKeyTypeEnum,
    ApiKeysQueryKeys,
} from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/utils/enums';
import ApiKeyRevokeModal from 'app/settingsManagement/components/ApiKey/ApiKeyRevokeModal';
import ApiKeyShowModal from 'app/settingsManagement/components/ApiKey/ApiKeyShowModal';

import { ApiKeyProvider } from './ApiKey.context';
import ApiKeyFormModal from './ApiKeyFormModal';
import ApiKeyListing from './ApiKeyListing';

export const translationApiKeyLabels = {
    bot: 'api_key_manager_filter_bot',
    user: 'api_key_manager_filter_user',
    active: 'api_key_manager_filter_active',
    inactive: 'api_key_manager_filter_inactive',
    title: {
        user: 'api_key_manager_user_title',
        bot: 'api_key_manager_org_title',
    },
    doc: 'api_key_manager_documentation_link',
};

const ApiKey = () => {
    const { t } = useTranslation();
    const [typeQuery] = useStateQueryParams(ApiKeysQueryKeys.TYPE);

    useEffect(() => {
        document.title =
            typeQuery === ApiKeyTypeEnum.USER
                ? t('api_key_manager_user_title')
                : t('api_key_manager_org_title');
    }, [typeQuery]);

    return (
        <ApiKeyProvider>
            <div className="api_key_manager">
                <ApiKeyListing />
                <ApiKeyRevokeModal />
                <ApiKeyShowModal />
                <ApiKeyFormModal />
            </div>
        </ApiKeyProvider>
    );
};

export default ApiKey;
