import LoadingSpinner from 'app/common/components/loaders/LoadingSpinner';
import { DoughnutOverlayBigStyled } from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/AverageGrade/DoughnutOverlay.styled';

import { DoughnutOverlayContainer } from './DoughnutOverlay.styled';

type Props = {
    averageGrade: string;
    percentage: string | null;
    color: string;
    isLoading: boolean;
    hasPublisherStates: boolean;
};

export const DoughnutOverlay = ({
    averageGrade,
    percentage,
    color,
    isLoading,
    hasPublisherStates,
}: Props) => {
    const overlay = hasPublisherStates ? (
        <DoughnutOverlayContainer>
            <div className={`color--${color}`}>
                <span>{percentage ?? averageGrade}</span>
            </div>
        </DoughnutOverlayContainer>
    ) : (
        <DoughnutOverlayContainer style={{ fontSize: '11em' }}>
            <i className="fa-regular fa-circle color--light" />
        </DoughnutOverlayContainer>
    );

    return isLoading ? (
        <DoughnutOverlayBigStyled>
            <LoadingSpinner fontSize="1em" />
        </DoughnutOverlayBigStyled>
    ) : (
        overlay
    );
};
