import { HeaderPrincipalPage, SettingsSummary } from './subComponents';
import { Template } from './subComponents/Template/Template';

export const SettingsV2 = () => {
    return (
        <Template header={<HeaderPrincipalPage />}>
            <SettingsSummary />
        </Template>
    );
};
