import React, { RefObject } from 'react';

import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useAddressSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useAddressSectionColor';
import useAdvancedSettingsSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useAdvancedSettingsSectionColor';
import useAttributesSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useAttributesSectionColor';
import useCategoriesSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useCategoriesSectionColor';
import useContactSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useContactSectionColor';
import useCoverSectionColors from 'app/businessEditV2/hooks/editV2utils/sectionColors/useCoverSectionColors';
import useCustomFieldsSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useCustomFieldsSectionColor';
import useDescriptionSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useDescriptionSectionColor';
import useLinksSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useLinksSectionColor';
import useLogoSectionColors from 'app/businessEditV2/hooks/editV2utils/sectionColors/useLogoSectionColors';
import useMenuSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useMenuSectionColor';
import useMoreHoursSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useMoreHoursSectionColor';
import useOpenHoursSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useOpenHoursSectionColor';
import usePhotosSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/usePhotosSectionColor';
import useServicesSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useServicesSectionColor';
import useSpecificHoursSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useSpecificHoursSectionColor';
import { SectionNames } from 'app/businessEditV2/utils/utils';

export type UseBusinessEditRef = {
    color: ColorState;
    ref: RefObject<HTMLDivElement>;
};

type UseBusinessEditRefsReturnType = {
    [key in SectionNames]: UseBusinessEditRef;
};

export default function useBusinessEditRefs(): UseBusinessEditRefsReturnType {
    // Order matters
    return {
        [SectionNames.COVER]: {
            color: useCoverSectionColors(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.LOGO]: {
            color: useLogoSectionColors(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.ADDRESS]: {
            color: useAddressSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.OPEN_HOURS]: {
            color: useOpenHoursSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.SPECIFIC_HOURS]: {
            color: useSpecificHoursSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.MORE_HOURS]: {
            color: useMoreHoursSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.PHOTOS]: {
            color: usePhotosSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.DESCRIPTIONS]: {
            color: useDescriptionSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.CATEGORIES]: {
            color: useCategoriesSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.ATTRIBUTES]: {
            color: useAttributesSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.SERVICES]: {
            color: useServicesSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.MENU]: {
            color: useMenuSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.CONTACT]: {
            color: useContactSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.ADVANCED_SETTINGS]: {
            color: useAdvancedSettingsSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.LINKS]: {
            color: useLinksSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
        [SectionNames.CUSTOM_FIELDS]: {
            color: useCustomFieldsSectionColor(),
            ref: React.createRef<HTMLDivElement>(),
        },
    };
}
