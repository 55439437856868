import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessFoodMenus from 'app/businessEditV2/hooks/menu/useBusinessFoodMenus';
import useIsFoodMenusAvailable from 'app/businessEditV2/hooks/menu/useIsFoodMenusAvailable';

export default function useIsLoadingMenu(): boolean {
    const { isLoading: isLoadingBusiness } = useBusiness();
    const { isLoading: isLoadingBusinessFoodMenus } = useBusinessFoodMenus();
    const { isLoading: isLoadingIsFoodMenusAvailable } =
        useIsFoodMenusAvailable();

    return (
        isLoadingBusiness ||
        isLoadingBusinessFoodMenus ||
        isLoadingIsFoodMenusAvailable
    );
}
