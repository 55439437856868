import styled from '@emotion/styled';

export const ApiKeysFiltersContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    > div:first-of-type {
        flex: 1;
    }
`;
