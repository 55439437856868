import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { Stack, Text } from '@partoohub/ui';

export const PreviewPanelWrapper = styled.div`
    background: ${greyColorObject.light};
    padding: 48px;
    border-radius: 16px;
    position: sticky;
    top: 0;
`;

export const PreviewMessages = styled.div`
    position: absolute;
    top: 40px;
    max-height: 395px;
    padding: 0 20px 5px;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const PreviewWelcomeMessageBubble = styled.div`
    border: 1px solid ${greyColorObject.light};
    border-radius: 20px 20px 20px 0;
    color: ${HEX_COLORS.black};
    background: #f4f4f4;
    width: 100%;
    max-width: 120px;
    min-height: 43.14px;
    padding: 12px 16px;
    display: inline-block;
    font-size: 12px;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
`;

export const PreviewBusinessLabel = styled(Text)`
    text-align: center;
    width: 100%;
    max-width: 100%;
    word-wrap: break-word;
    padding-bottom: 8px;
`;

export const PreviewWelcomeMessageLine = styled(Stack)``;

export const PreviewConsumerMessageBubble = styled.div`
    border-radius: 20px 20px 0 20px;
    background: linear-gradient(
        126.5deg,
        #03d2f1 14.42%,
        ${({ theme }) => theme.colors.primary.initial} 85.42%
    );
    max-width: '110px';
    padding: 12px 16px;
    margin-top: 10px;
    margin-left: 45px;
    color: ${HEX_COLORS.white};
    font-size: 12px;
    word-break: break-word;
`;

export const PreviewSettingIcon = styled.img`
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-top: 16px;
    margin-right: 5px;
`;

export const PreviewPanel = styled(Stack)`
    width: 100%;
    background-color: ${greyColorObject.light};
`;

// Image size: 248 × 496 px
export const IPhoneTemplate = styled.image`
    width: auto;
    max-height: auto;
    margin-right: auto;
    margin-left: auto;
    position: relative;
`;

export const TextPlaceholder = styled(Text)`
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: ${greyColorObject.initial};
`;

export const PreviewTitleTextV2 = styled(Text)``;

export const PreviewStack = styled(Stack)`
    min-width: 344px;
    max-width: 344px;
`;
