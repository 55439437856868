import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const ReviewsEvolutionStyled = styled.div`
    flex: 1 1 0;
    max-width: 100%;
    position: relative;
    min-height: 0;
    min-width: 0;
    margin: 24px auto 0;

    canvas {
        height: 410px !important;
        width: 100% !important;

        @media (max-width: 768px) {
            height: 300px !important;
        }
    }
`;

export const MobileFooterStyled = styled.div`
    display: flex;
    flex-flow: row nowrap;
    padding: 8px;
    border-top: 1px solid ${greyColorObject.initial};
`;
