import styled from '@emotion/styled';

type LoaderContainerProps = {
    height: number;
    unit: string;
};

export const LoaderViewContainer = styled.div<LoaderContainerProps>`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;

    > svg {
        width: ${props => props.height}${props => props.unit};
        height: ${props => props.height}${props => props.unit};
    }
`;
