import { V2BusinessData } from 'app/api/types/business';

/** ts-query match algorithm as done in the backend here :
 *  web/datalayer/hooks/business.py:170
 */
export const businessTsQuerySimple = (
    business: V2BusinessData,
    query: string,
) => {
    const content = [
        business.name,
        business.address_full,
        business.city,
        business.zipcode,
        business.code,
    ]
        .filter(Boolean)
        .join(' ');

    return tsQuerySimple(content, query);
};

/** Utility function that reproduces the ts-query search algorithm defined in
 *  the backend here:
 *  web/datalayer/repositories/business_sql_repository.py:166.
 *
 *  Currently it is used only for business but it might be used for other
 *  items too, so we implement it as a reusable function here.
 *  TODO: see if we could uniformize ts-queries in the backend
 */
export const tsQuerySimple = (content: string, query: string) => {
    let cleaned = query.replaceAll('\'', ' ');
    cleaned = cleaned.replace(/[|<>&@!()*:]/g, '').trim();

    // Comma separates multiple queries from each other, as in backend.
    // Then each query contains multiple tokens.
    const queries: Array<Array<string>> = cleaned
        .split(',')
        .map(q => q.trim())
        .filter(Boolean)
        .map(q =>
            q
                .split(' ')
                .map(t => t.trim().toLowerCase())
                .filter(Boolean),
        );

    // Turn the content into a vector, as to_tsvector() would do
    // in psql. Note that the backend is using the `simple` text
    // search configuration, so there is no reduction happening
    // and words are kept as-is.
    // (see https://www.postgresql.org/docs/current/textsearch-controls.html)
    const vector = content
        .split(' ')
        .map(s => s.trim().toLowerCase())
        .filter(Boolean);

    // match the list of queries against the vector, as done in the backend
    // here : web/datalayer/repositories/business_sql_repository.py:168
    return queries.some(tokens =>
        tokens.every(t => vector.some(v => v.startsWith(t))),
    );
};
