import { useKeywordsSearchQueryParams } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/useKeywordsSearchQueryParams';
import { DimensionsDict } from 'app/pages/analytics/VisibilityAnalyticsV2/types/dimensions';
import { MetricsAttributesResponse } from 'app/pages/analytics/VisibilityAnalyticsV2/types/metrics';
import { MetricsApiKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/types/query';

const MAX_KEYWORDS = 10;
export const useKeywordsQueryKpis = () => {
    const queryCount = {
        ...useKeywordsSearchQueryParams(),
        [MetricsApiKeys.METRICS]: `${MetricsAttributesResponse.COUNT}`,
        [MetricsApiKeys.ORDER_BY]: `-${MetricsAttributesResponse.COUNT}`,
        [MetricsApiKeys.DIMENSIONS]: DimensionsDict.KEYWORD,
        [MetricsApiKeys.PER_PAGE]: `${MAX_KEYWORDS}`,
    };

    const queryThreshold = {
        ...useKeywordsSearchQueryParams(),
        [MetricsApiKeys.METRICS]: `${MetricsAttributesResponse.THRESHOLD}`,
        [MetricsApiKeys.ORDER_BY]: `-${MetricsAttributesResponse.THRESHOLD}`,
        [MetricsApiKeys.DIMENSIONS]: DimensionsDict.KEYWORD,
        [MetricsApiKeys.PER_PAGE]: `${MAX_KEYWORDS}`,
    };

    return {
        queryCount,
        queryThreshold,
    };
};
