import React, { ReactNode } from 'react';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessCountry from 'app/businessEditV2/hooks/business/useBusinessCountry';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';

import {
    EditFormBox,
    EditFormBusiness,
    EditFormBusinessAddress,
    EditFormBusinessName,
    EditFormContainer,
    EditFormDescription,
    EditFormDescriptionLeft,
    EditFormDescriptionRight,
    EditFormHeader,
    EditFormInfo,
} from './EditForm.styled';

type Props = {
    title: string;
    description?: ReactNode;
    descriptionRight?: ReactNode;
    children: ReactNode;
    colorState?: ColorState;
    id?: string;
    info?: ReactNode;
};

const EditForm = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            title,
            description,
            children,
            colorState = ColorState.OK,
            id,
            descriptionRight,
            info,
        }: Props,
        ref,
    ) => {
        const { data: business } = useBusiness();
        const country = useBusinessCountry();

        const businessString = `${business?.name}, ${business?.address_full}, ${country}`;

        return (
            <EditFormContainer id={id} ref={ref}>
                <EditFormBox>
                    <EditFormHeader colorState={colorState}>
                        {colorState === ColorState.OK ? (
                            <i className="fa-solid fa-check-circle" />
                        ) : (
                            <i className="fa-solid fa-circle-exclamation" />
                        )}
                        <EditFormBusiness>
                            <EditFormBusinessName>{title}</EditFormBusinessName>
                            <EditFormBusinessAddress>
                                {businessString}
                            </EditFormBusinessAddress>
                        </EditFormBusiness>
                    </EditFormHeader>
                    {info && <EditFormInfo>{info}</EditFormInfo>}
                    <EditFormDescription>
                        <EditFormDescriptionLeft>
                            {[
                                ColorState.PRECHECK_ERRORS,
                                ColorState.MISSING_WARNING,
                            ].includes(colorState) && (
                                <i className="fa-solid fa-circle-exclamation" />
                            )}
                            {description}
                        </EditFormDescriptionLeft>
                        {descriptionRight && (
                            <EditFormDescriptionRight>
                                {descriptionRight}
                            </EditFormDescriptionRight>
                        )}
                    </EditFormDescription>
                    {children}
                </EditFormBox>
            </EditFormContainer>
        );
    },
);

export default EditForm;
