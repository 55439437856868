import { FontAwesomeIconsPartooUsed, IconButton } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PRESENCE_MANAGEMENT } from 'app/common/data/productIds';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { VISIBILITY_DIFFUSION_PATH } from 'app/routing/routeIds';

export const DiffusionListButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: me } = useMe();
    const sidebarProducts = me?.sidebar_products;

    const sidebarPartooProducts: Array<string> = sidebarProducts?.map(s => s.toUpperCase()) ?? [];
    const hasPmAccess = sidebarPartooProducts.includes(PRESENCE_MANAGEMENT);

    const handleRedirect = () => {
        navigate(VISIBILITY_DIFFUSION_PATH);
    };
    if (!hasPmAccess) {
        return null;
    }
    return (
        <IconButton
            dataTrackId=""
            icon={[FontAwesomeIconsPartooUsed.faRadar]}
            onClick={handleRedirect}
            appearance="outlined"
            tooltip={t('listing')}
            tooltipPlacement="top"
        />
    );
};
