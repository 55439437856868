import { useEffect, useState } from 'react';

import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, Dialog, FontAwesomeIconsPartooUsed, Modal, Stack, toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import BusinessModal from 'app/common/components/businessModal/components/BusinessModal';
import { setSelectedBusinessIdsModalActions } from 'app/common/components/businessModal/context/businessModalCustomActions';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import { BusinessSearchParams } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { ALL_STATUSES_EXCEPT_CLOSED } from 'app/common/data/businessStatuses';

import {
    BusinessSelectionInput,
    BusinessSelectionInputContainer,
    ChatbotBusinessSelectionButtonContainer,
    ChatbotBusinessSelectionContainer,
    ChevronIcon,
} from './ChatbotBusinessSelection.styled';
import { useChatbot } from '../../hooks/useChatbot';
import { useChatbotBusinessSelectionUpdate } from '../../hooks/useChatbotBusinessSelectionUpdate';
import { useValidateBusinesses } from '../../hooks/useValidateChatbotBusinessSelection';

interface Props {
    onClose: () => void;
    setHasSucceeded?: (state: boolean) => void;
}

const SEARCH_PARAMS: BusinessSearchParams = {
    subscribed_to_bm: true,
    status: ALL_STATUSES_EXCEPT_CLOSED,
};

export const ChatbotBusinessSelection = ({ onClose, setHasSucceeded }: Props) => {
    const { t } = useTranslation();
    const [hasConflict, setHasConflict] = useState(false);
    const [businessModalIsOpen, setIsModalOpen] = useState<boolean>(false);
    const { data } = useChatbot();
    const validateBusinessSelection = useValidateBusinesses(data?.id);

    const businessesFilters = useBusinessModalFilters(true);
    const businessCount = useSelectedBusinessesCount();

    // GET businesses
    const dispatch = useDispatch();

    useEffect(() => {
        if (data?.business_ids) dispatch(setSelectedBusinessIdsModalActions(data?.business_ids));
    }, [data, dispatch]);

    useEffect(() => {
        if (data?.id) {
            validateBusinessSelection.mutate(businessesFilters, {
                onSuccess: result => {
                    if (result.businesses.length > 0) {
                        setHasConflict(true);
                    }
                },
            });
        }
    }, [businessesFilters, data?.id]);

    // PATCH businesses
    const { mutate } = useChatbotBusinessSelectionUpdate();

    const handleOnConfirm = () => {
        mutate(businessesFilters, {
            onSuccess: () => {
                toast.success(
                    t('chatbot_snackbar_changes_saved_text'),
                    t('chatbot_snackbar_changes_saved_title'),
                );
                if (setHasSucceeded) {
                    setHasSucceeded(businessCount ? businessCount > 0 : false);
                }
            },
        });
        onClose();
    };
    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Warning,
    };
    const modalHeaderImage = (
        <div style={{ paddingTop: '24px' }}>
            <Illustration
                dataTrackId="chatbot_business__illustration"
                illustration={illustrationElement}
                width="320"
                height="140"
            />
        </div>
    );
    const conflictModal = (
        <Modal closeModal={() => setHasConflict(false)} isOpen={hasConflict}>
            <Dialog
                dataTrackId=""
                title={t('chatbot_conflicting_business_selection_title')}
                subTitle={t('chatbot_conflicting_business_selection_subtitle')}
                header={modalHeaderImage}
            >
                <Stack direction="row">
                    <Button
                        dataTrackId=""
                        onClick={() => {
                            setIsModalOpen(true);
                            setHasConflict(false);
                        }}
                        appearance="outlined"
                        variant="secondary"
                        size="large"
                        full
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        dataTrackId=""
                        onClick={() => {
                            setHasConflict(false);
                        }}
                        variant="primary"
                        size="large"
                        full
                    >
                        {t('confirm')}
                    </Button>
                </Stack>
            </Dialog>
        </Modal>
    );

    return (
        <ChatbotBusinessSelectionContainer>
            <BusinessModal
                searchFilters={SEARCH_PARAMS}
                opened={businessModalIsOpen}
                closeModal={() => setIsModalOpen(false)}
                noBusinessLimit
                withUrlSync={false}
            />

            <BusinessSelectionInputContainer>
                <BusinessSelectionInput
                    dataTrackId=""
                    label={t('chatbot_business_selection_text_field_placeholder')}
                    onFocus={() => {
                        setIsModalOpen(true);
                    }}
                    onChange={() => {
                        /* noop */
                    }}
                    value={businessCount + ' ' + t('chatbot_business_selection_count_mention')}
                />
                <ChevronIcon icon={[FontAwesomeIconsPartooUsed.faChevronRight]} color="secondary" />
            </BusinessSelectionInputContainer>

            <ChatbotBusinessSelectionButtonContainer>
                <Button
                    onClick={onClose}
                    dataTrackId="chatbot_business_selection_cancel"
                    appearance="outlined"
                    variant="secondary"
                    shape="cube"
                    size="large"
                >
                    {t('cancel')}
                </Button>
                <Button
                    onClick={handleOnConfirm}
                    dataTrackId="chatbot_business_selection_confirm"
                    appearance="contained"
                    shape="cube"
                    variant="primary"
                    size="large"
                    full
                >
                    {t('chatbot_form_confirm_button')}
                </Button>
            </ChatbotBusinessSelectionButtonContainer>
            {conflictModal}
        </ChatbotBusinessSelectionContainer>
    );
};
