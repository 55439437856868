import { PureComponent, ReactNode } from 'react';

import { Text } from '@partoohub/ui';

import { upperFirst } from 'lodash-es';
import { withTranslation } from 'react-i18next';

import IS_IFRAME from 'app/common/data/iframe';

import {
    PageHeaderComponentCounter,
    PageHeaderComponentFilters,
    PageHeaderComponentFiltersContainer,
    PageHeaderComponentTop,
    PageHeaderComponentTopLeft,
    PageHeaderComponentTopPageButtons,
    PageHeaderContainer,
    PageHeaderDescription,
    PageHeaderSideTitle,
    stickyHeader,
    stickyHeaderIn,
    stickyHeaderOut,
} from './PageHeader.styled';
import PageHeaderSearchIcon from './PageHeaderSearchIcon';
import PageHeaderSideBarButton from './PageHeaderSideBarButton';

const STICKY_HEADER_HEIGHT = 400; // How many px we have to scroll down to enable sticky header

const STICKY_SENSITIVITY = 20; // Sensitivity in px to show/hide the sticky header

type Props = {
    title: string;
    description?: string;
    sideTitle?: string;
    pageButtons?: ReactNode;
    filters?: ReactNode;
    onSearchChange?: (search?: string) => void;
    collapseSidebar?: () => void;
    currentSearch?: string;
    stickyHeader?: boolean;
    counter?: ReactNode;
    t: (text: string, obj?: any) => string;
};

type State = {
    currentSearch?: string;
    displaySearchBar: boolean;
    sticky: boolean;
    // If we scrolled far enough so we can display the sticky header
    stickyShow: boolean | null; // If we show or not the sticky header, once we scrolled far enough
};

// Used to store data without using setState and thus rerendering the component
let lastScroll = 0; // Store the px height position of the last scroll

let scrollHook = 0;
let scrollUp = true;

class PageHeader extends PureComponent<Props, State> {
    static defaultProps = {
        leftTitleButton: undefined,
        rightTitleButton: undefined,
        pageButtons: undefined,
        filters: undefined,
        onSearchChange: undefined,
        collapseSidebar: undefined,
        description: undefined,
        sideTitle: undefined,
        currentSearch: undefined,
        stickyHeader: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            displaySearchBar: false,
            sticky: false,
            stickyShow: null,
        };
    }

    componentDidMount() {
        const { currentSearch } = this.props;
        const content = document.getElementById('react-content');

        if (content !== null) {
            content.addEventListener('scroll', this.handleScroll);
        }

        if (currentSearch) {
            this.setState({
                displaySearchBar: true,
            });
        }
    }

    componentWillUnmount() {
        const content = document.getElementById('react-content');

        if (content !== null) {
            content.removeEventListener('scroll', this.handleScroll);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { currentSearch } = this.props;
        if (currentSearch === undefined && prevProps.currentSearch !== currentSearch) {
            this.closeSearchBar();
        }
    }

    handleScroll = (event: Event) => {
        // @ts-ignore
        const scrollY = event.currentTarget.scrollTop;
        const { sticky, stickyShow } = this.state;

        // If we scrolled far enough
        if (scrollY >= STICKY_HEADER_HEIGHT) {
            if (!sticky) {
                this.setState({
                    sticky: true,
                });
            }
            if (scrollY < lastScroll) {
                // scroll up
                if (!scrollUp) {
                    scrollUp = true;
                    scrollHook = scrollY;
                }

                if (scrollHook - scrollY > STICKY_SENSITIVITY && !stickyShow) {
                    this.setState({
                        stickyShow: true,
                    });
                }
            } else {
                // scroll down
                if (scrollUp) {
                    scrollUp = false;
                    scrollHook = scrollY;
                }

                if (scrollY - scrollHook > STICKY_SENSITIVITY && stickyShow) {
                    this.setState({
                        stickyShow: false,
                    });
                }
            }
        } else if (
            scrollY < STICKY_HEADER_HEIGHT &&
            scrollY > STICKY_HEADER_HEIGHT - 100 &&
            sticky
        ) {
            this.setState({ stickyShow: false });
        } else if (scrollY < STICKY_HEADER_HEIGHT && sticky) {
            this.setState({ sticky: false, stickyShow: null });
        }

        lastScroll = scrollY;
    };

    updateCurrentSearch = (value?: string) => {
        const { onSearchChange } = this.props;
        if (typeof onSearchChange === 'function') {
            onSearchChange(value);
        }
    };

    openSearchBar = () => {
        this.setState({
            displaySearchBar: true,
        });
    };

    closeSearchBar = () => {
        const { currentSearch } = this.props;

        if (!currentSearch || !currentSearch.length) {
            this.setState({
                displaySearchBar: false,
            });
        }
    };

    header = (isSticky = false) => {
        const { openSearchBar } = this;
        const {
            title,
            description,
            pageButtons,
            filters,
            onSearchChange,
            collapseSidebar,
            t,
            sideTitle,
            counter,
        } = this.props;
        const { displaySearchBar, stickyShow } = this.state;
        let stickyStyle: any = stickyHeader;

        if (stickyShow !== null) {
            stickyStyle = stickyShow ? stickyHeaderIn : stickyHeaderOut;
        }

        return (
            <PageHeaderContainer css={isSticky ? stickyStyle : undefined}>
                <PageHeaderComponentTop>
                    <PageHeaderComponentTopLeft>
                        {collapseSidebar && (
                            <PageHeaderSideBarButton collapseSidebar={collapseSidebar} />
                        )}
                        <Text variant="heading3">{upperFirst(t(title))}</Text>
                        {sideTitle && !displaySearchBar && (
                            <PageHeaderSideTitle>{sideTitle}</PageHeaderSideTitle>
                        )}
                        {onSearchChange && !displaySearchBar && (
                            <PageHeaderSearchIcon onClick={openSearchBar} />
                        )}
                    </PageHeaderComponentTopLeft>
                    {pageButtons && (
                        <PageHeaderComponentTopPageButtons>
                            {pageButtons}
                        </PageHeaderComponentTopPageButtons>
                    )}
                </PageHeaderComponentTop>
                {description && <PageHeaderDescription>{description}</PageHeaderDescription>}

                {(filters || counter) && (
                    <PageHeaderComponentFiltersContainer>
                        <PageHeaderComponentFilters>{filters}</PageHeaderComponentFilters>
                        {counter && (
                            <PageHeaderComponentCounter>
                                <span>{counter}</span>
                            </PageHeaderComponentCounter>
                        )}
                    </PageHeaderComponentFiltersContainer>
                )}
            </PageHeaderContainer>
        );
    };

    render() {
        const { stickyHeader } = this.props;
        return (
            <div>
                {this.header()}
                {stickyHeader && !IS_IFRAME && this.header(true)}
            </div>
        );
    }
}

export default withTranslation()(PageHeader);
