import { useState } from 'react';

import { Button, Modal, NumberInput, Stack, TextArea, TextInput, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ServicesGetErrorType, servicesTypeEnum } from 'app/api/types/services';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import useCreateService from 'app/businessEditV2/hooks/services/useCreateService';
import { errorMaxCharacters } from 'app/businessEditV2/utils/utils';
import StrikeTroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';

import {
    CancelButton,
    ModalContent,
    ModalSubtitle,
    ModalTitle,
    ModalWrapper,
    StructuredServiceLabel,
    StructuredServiceName,
    StructuredServiceValue,
} from './modal.styled';

export type ServiceModalDataType = {
    serviceType?: string;
    serviceName?: string;
    serviceId?: number;
    categoryGmbName?: string;
};

export type ServiceModalFormType = {
    name: string;
    description: string;
    price?: number;
};

type Props = {
    show: boolean;
    handleClose: () => void;
    data: ServiceModalDataType;
    currency: string;
};

const AddServiceModal = ({ show, handleClose, data, currency }: Props) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<ServiceModalFormType>({
        name: '',
        description: '',
        price: undefined,
    });
    const [serviceWithSameNameAlreadyExist, setServiceWithSameNameAlreadyExist] =
        useState<boolean>(false);

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const addMutation = useCreateService(
        () => {
            setServiceWithSameNameAlreadyExist(false);
            businessUpdateCompletionRate.mutate();
            close();
        },
        (error: ServicesGetErrorType) => {
            if (
                error.response.status === 403 &&
                error.response.data.errors.json === 'A service with the same name already exist'
            ) {
                setServiceWithSameNameAlreadyExist(true);
            } else {
                toast.error(
                    t('error_adding_service_toast_description'),
                    t('error_adding_service_toast_title'),
                );
            }
        },
    );

    const onApply = () => {
        addMutation.mutate({ data, formData });
    };

    const close = () => {
        resetForm();
        handleClose();
    };

    const resetForm = () => {
        setFormData({ name: '', description: '', price: undefined });
        setServiceWithSameNameAlreadyExist(false);
    };

    const handlePriceChange = (value: number) => {
        setFormData({ ...formData, price: value });
    };

    const isFormInvalid = (): boolean =>
        (data.serviceType === servicesTypeEnum.FREE_FORM_SERVICE_ITEM && !formData.name) ||
        formData.description.length > 300 ||
        addMutation.isLoading ||
        (formData?.price && isNaN(formData.price)) ||
        (data.serviceType === servicesTypeEnum.FREE_FORM_SERVICE_ITEM &&
            formData.name?.replace(/\s/g, '').length === 0);

    return (
        <Modal isOpen={show} closeModal={close}>
            <ModalWrapper>
                <ModalContent>
                    <ModalTitle>{t('add_service_modal_title')}</ModalTitle>
                    <ModalSubtitle>{t('add_service_modal_subtitle')}</ModalSubtitle>
                    <Stack>
                        {data.serviceType === servicesTypeEnum.STRUCTURED_SERVICE_ITEM && (
                            <StructuredServiceName>
                                <StructuredServiceLabel>{t('service_name')}</StructuredServiceLabel>
                                <StructuredServiceValue>{data.serviceName}</StructuredServiceValue>
                            </StructuredServiceName>
                        )}
                        {data.serviceType === servicesTypeEnum.FREE_FORM_SERVICE_ITEM && (
                            <TextInput
                                dataTrackId="service_name"
                                label={t('service_name')}
                                required
                                value={formData.name}
                                onChange={value => {
                                    setFormData({
                                        ...formData,
                                        name: value || '',
                                    });
                                }}
                                error={serviceWithSameNameAlreadyExist}
                                notice={
                                    serviceWithSameNameAlreadyExist
                                        ? t('error_adding_service_already_exists_toast_description')
                                        : undefined
                                }
                            />
                        )}
                        <NumberInput
                            dataTrackId="service_price"
                            unit={`${currency}`}
                            label={`${t('service_price')} (${currency})`}
                            min={0}
                            value={formData?.price}
                            onChange={handlePriceChange}
                        />
                        <TextArea
                            characterCount={300}
                            error={errorMaxCharacters(formData.description, 300)}
                            rows={5}
                            maxRows={10}
                            value={formData.description}
                            onChange={value => {
                                setFormData({
                                    ...formData,
                                    description: value,
                                });
                            }}
                            label={t('service_description')}
                            dataTrackId={'service_description'}
                        />
                    </Stack>
                </ModalContent>

                <CancelButton>
                    <StrikeTroughButton text={t('cancel')} color="secondary" onClick={close} />
                </CancelButton>
                <Button
                    dataTrackId="add_service_modal"
                    full
                    size="large"
                    shape="cube"
                    variant="primary"
                    disabled={isFormInvalid()}
                    onClick={onApply}
                >
                    {t('validate')}
                </Button>
            </ModalWrapper>
        </Modal>
    );
};

export default AddServiceModal;
