import { put } from 'redux-saga/effects';

import { reInitBusinessFilter } from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import { loadInitialFilterDataRequest } from 'app/common/components/businessModalDeprecated/reducers/loadInitialFilterData';
import {
    applyBusinessPendingFilter,
    setBusinessGroupIdInFilter,
    updateSearchQuery,
    updateSelectedCity,
} from 'app/common/reducers/newBusinesses';
import { Saga } from 'app/common/types';
import { reInitReviewFilters } from 'app/states/reviews/filters';

const initialReviewAnalyticsDataLoadSaga = function* (): Saga {
    yield put(reInitBusinessFilter());
    yield put(reInitReviewFilters());
    yield put(loadInitialFilterDataRequest());
    yield put(updateSelectedCity(''));
    yield put(updateSearchQuery(''));
    yield put(setBusinessGroupIdInFilter(null));
    yield put(applyBusinessPendingFilter());
};

export default initialReviewAnalyticsDataLoadSaga;
