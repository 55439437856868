import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';
import { Button, Stack } from '@partoohub/ui';

export const FloatingBarContainer = styled.div<{ show: boolean }>`
    color: white;
    background: ${HEX_COLORS.black};
    width: 727px;
    height: 72px;
    border-radius: 8px;
    box-shadow: 0px 5px 10px 0px #00000033;
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    ${({ show }) =>
        show
            ? css`
                  bottom: 30px;
                  opacity: 1;
                  transition: bottom 0.3s ease-in-out;
              `
            : css`
                  bottom: -80px;
                  opacity: 0;
                  transition:
                      bottom 0.3s ease-in-out,
                      opacity 0s 0.3s;
              `}
`;

export const ClickableArea = styled(Stack)`
    cursor: pointer;
`;

export const FloatingBarContent = styled(Stack)`
    height: 100%;
    padding: 0px 24px;
`;

export const StyledButton = styled(Button)`
    border-color: white;
`;
