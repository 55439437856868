import { useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import dataLayer from 'app/common/utils/dataLayer';

import { BusinessTooltipContainer } from './BusinessTooltip.styled';
import BusinessTooltipModal from './BusinessTooltipModal';

const BusinessTooltip = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);

    const onClick = () => {
        setShowModal(true);
        dataLayer.pushDict('select_business_edit_tip');
    };

    return (
        <BusinessTooltipContainer>
            <IconButton
                dataTrackId="business_status_tooltip"
                icon={[FontAwesomeIconsPartooUsed.faCircleQuestion]}
                appearance="outlined"
                tooltip={t('business_status_tooltip')}
                onClick={onClick}
                variant="default"
                size="small"
            />
            <BusinessTooltipModal showModal={showModal} closeModal={() => setShowModal(false)} />
        </BusinessTooltipContainer>
    );
};

export default BusinessTooltip;
