import { SMB } from 'app/common/data/organization';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';

export const useIsInvoicesPageEnabled = (): boolean => {
    const { data: me } = useMe();
    const { data: org } = useOrganization(me?.org_id ?? 0, !!me?.org_id);

    if (!me || !org) {
        return false;
    }

    const isSmb: boolean = org?.organization_type === SMB;
    const isPartooProvider: boolean = org?.provider === 'partoo';

    return isSmb && isPartooProvider;
};
