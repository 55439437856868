import { Table } from '@partoohub/ui';

import { StyledCell } from './Rankings.styled';

export const EReputationRanking = () => {
    return (
        <Table
            columns={[
                {
                    label: '#',
                    width: '300px',
                },
                {
                    isOrdered: false,
                    label: 'Organisation',
                    orderDirection: 'asc',
                    width: '3000px',
                },
                {
                    isOrdered: true,
                    label: 'Note Moyenne',
                    width: '1000px',
                    orderDirection: 'asc',
                },
                {
                    label: 'Avis',
                    width: '1000px',
                },
                {
                    label: 'Avis 5 étoiles',
                    width: '1000px',
                },
                {
                    label: 'Avis 1 et 2 étoiles',
                    width: '1000px',
                },
            ]}
        >
            <Table.Body>
                <Table.Row dataTrackId="" id="6">
                    <StyledCell>1458</StyledCell>
                    <StyledCell>Test org</StyledCell>
                    <StyledCell>3.67</StyledCell>
                    <StyledCell>3</StyledCell>
                    <StyledCell>66,67%</StyledCell>
                    <StyledCell>33,33%</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="7">
                    <StyledCell>1458</StyledCell>
                    <StyledCell>Test org</StyledCell>
                    <StyledCell>3.67</StyledCell>
                    <StyledCell>3</StyledCell>
                    <StyledCell>66,67%</StyledCell>
                    <StyledCell>33,33%</StyledCell>
                </Table.Row>
                <Table.Row dataTrackId="" id="8">
                    <StyledCell>1458</StyledCell>
                    <StyledCell>Test org</StyledCell>
                    <StyledCell>3.67</StyledCell>
                    <StyledCell>3</StyledCell>
                    <StyledCell>66,67%</StyledCell>
                    <StyledCell>33,33%</StyledCell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
};
