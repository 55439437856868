import { FC } from 'react';

import {
    REVIEW_ANALYTICS_DATE_FROM,
    REVIEW_ANALYTICS_DATE_TO,
    REVIEW_ANALYTICS_TAGS,
} from 'app/common/data/routeIds';
import { useGetParamReduxSync } from 'app/common/hooks/useGetParamReduxSync';
import { AppState } from 'app/states/reducers';

export const GetParamsReduxSync: FC = () => {
    useGetParamReduxSync(
        REVIEW_ANALYTICS_DATE_FROM,
        [(state: AppState) => state.reviews.filters.date, 'from'],
        [(state: AppState) => state.reviews.filters.pending.date, 'from'],
    );

    useGetParamReduxSync(
        REVIEW_ANALYTICS_DATE_TO,
        [(state: AppState) => state.reviews.filters.date, 'to'],
        [(state: AppState) => state.reviews.filters.pending.date, 'to'],
    );

    useGetParamReduxSync(
        REVIEW_ANALYTICS_TAGS,
        [(state: AppState) => state.reviews.filters, 'tag'],
        [(state: AppState) => state.reviews.filters.pending, 'tag'],
    );

    return null;
};
