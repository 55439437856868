import React from 'react';

import ConversationCardLoader from './ConversationCardLoader';

export type LazyLoadingConversationsCardsProps = {
    isSidebarCollapsed: boolean;
};

const LazyLoadingConversationsCards = ({
    isSidebarCollapsed,
}: LazyLoadingConversationsCardsProps) => (
    <>
        <ConversationCardLoader
            key="loading_conversation_card_1"
            isSidebarCollapsed={isSidebarCollapsed}
        />
        <ConversationCardLoader
            key="loading_conversation_card_2"
            isSidebarCollapsed={isSidebarCollapsed}
        />
    </>
);

export default LazyLoadingConversationsCards;
