import { FC, useState } from 'react';

import { HEX_COLORS, convertToRgba } from '@partoohub/branding';
import { Icon } from '@partoohub/ui';
import Linkify from 'linkify-react';

import { CONTAINED, Type } from 'app/common/designSystem/constants/type';
import {
    MessageBubbleStyled,
    MessageBubbleWrapperStyled,
} from 'app/reviewManagement/messaging/activeConversation/messages/MessageBubble.styled';

import { IconWrapper, TextBubbleWrapper } from '../MessageBubbleContent.styled';

type SideIconParams = {
    name: string;
    onClickIcon: () => void;
};
interface TextBubbleProps {
    text: string;
    isConsumerMessage: boolean;
    onClick: () => void;
    isIgStory?: boolean;
    bubbleType?: Type;
    sideIcon?: SideIconParams;
}

export const TextBubble: FC<TextBubbleProps> = ({
    text,
    isConsumerMessage,
    onClick,
    isIgStory,
    bubbleType = CONTAINED as Type,
    sideIcon,
}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    return (
        <TextBubbleWrapper
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {sideIcon &&
                (isHovered ? (
                    <IconWrapper>
                        <Icon
                            icon={[sideIcon.name]}
                            iconSize="16px"
                            color={HEX_COLORS.secondary}
                            onClick={sideIcon.onClickIcon}
                        />
                    </IconWrapper>
                ) : (
                    // Prevent message shrinking
                    <div style={{ marginLeft: 16 }}></div>
                ))}

            <MessageBubbleWrapperStyled onClick={onClick} isIgStory={isIgStory || false}>
                <MessageBubbleStyled
                    isConsumer={isConsumerMessage}
                    bubbleType={bubbleType}
                    as="div"
                    consumerBackgroundColor={convertToRgba(HEX_COLORS.secondary, 0.12)}
                >
                    <Linkify
                        tagName="span"
                        options={{
                            target: {
                                url: '_blank',
                            },
                        }}
                    >
                        {text}
                    </Linkify>
                </MessageBubbleStyled>
            </MessageBubbleWrapperStyled>
        </TextBubbleWrapper>
    );
};
