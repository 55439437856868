import React from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { ORG_ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMe';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import useGroupData from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupData';
import { openManageModal } from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupFakeSaga';
import { BusinessListQueryKeys } from 'app/pages/visibility/BusinessListV2/utils/enums';

import {
    NoBusinessPlaceholderContainer,
    PlaceholderButton,
    PlaceholderDescription,
    PlaceholderTitle,
} from './NoBusinessPlaceholder.styled';

const NoBusinessPlaceholder = () => {
    const { t } = useTranslation();

    // For reset filters
    const [query, setQuery] = useStateQueryParams(BusinessListQueryKeys.QUERY);
    const [status, setStatus] = useStateQueryParams(BusinessListQueryKeys.STATUS);
    const [products, setProducts] = useStateQueryParams(BusinessListQueryKeys.PRODUCTS);
    const [completions, setCompletions] = useStateQueryParams(
        BusinessListQueryKeys.COMPLETION_RATE,
    );

    const resetFilters = () => {
        setQuery('');
        setStatus('');
        setProducts('');
        setCompletions('');
    };

    const displayFilterButton = [query, status, products, completions].some(Boolean);

    // For group business modal
    const { data: me } = useMe();
    const groupData = useGroupData();
    const dispatch = useDispatch();

    const displayGroupButton = !!groupData && me?.role === ORG_ADMIN;

    // Handle placeholder content
    let description: string = t('no_business_description');
    let button: React.ReactNode = null;

    if (displayFilterButton) {
        description = t('no_business_found_description');
        button = (
            <PlaceholderButton onClick={resetFilters}>{t('see_all_businesses')}</PlaceholderButton>
        );
    } else if (displayGroupButton) {
        description = t('empty_group');
        button = (
            <PlaceholderButton onClick={() => dispatch(openManageModal(true))}>
                {t('add_businesses_in_the_group')}
            </PlaceholderButton>
        );
    }

    return (
        <NoBusinessPlaceholderContainer>
            <PlaceholderTitle>{t('no_business_found')}</PlaceholderTitle>
            <PlaceholderDescription>{description}</PlaceholderDescription>
            {button}
        </NoBusinessPlaceholderContainer>
    );
};

export default NoBusinessPlaceholder;
