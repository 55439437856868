import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

export const CardLineContainer = styled.div<{ loading?: boolean }>``;

const reactPlaceholderPulse = keyframes`
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.6;
    }
`;

export const StyledText = styled(Text)<{ loading?: boolean }>`
    text-align: left;
    width: 100%;
    max-width: 130px;
    ${({ loading }) =>
        loading &&
        css`
            background-color: ${greyColorObject.initial};
            animation: ${reactPlaceholderPulse} 1.5s infinite;
            color: transparent;
            height: 100%;
        `};
`;
