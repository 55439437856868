import { useCallback, useState } from 'react';

import axios from 'axios';

import { AccountPartnerName } from 'app/api/types/account';
import IS_IFRAME from 'app/common/data/iframe';

const buildConnectionLinkUrl = (
    connectionLink: string,
    scopes: string[] | undefined,
    state: string[] | undefined,
) => {
    let connectionLinkURL = connectionLink;
    const queryParams = [];
    if (IS_IFRAME) {
        const jwtToken = axios.defaults.headers.common.Authorization;
        if (jwtToken) {
            queryParams.push(`jwt_token=${jwtToken}`);
        }
    }
    if (scopes && scopes.length > 0) {
        queryParams.push(`scopes=${scopes.join(',')}`);
    }
    if (state?.length > 0) {
        queryParams.push(`state=${state?.join(',')}`);
    }
    if (queryParams.length) {
        connectionLinkURL += (connectionLinkURL.includes('?') ? '&' : '?') + queryParams.join('&');
    }
    return connectionLinkURL;
};
export const PARTOO_OAUTH_ACCOUNT = 'partoo_oauth_account';

export const useImportOAuthAccount = (connectionLink: string) => {
    const [oauthAccountId, setOAuthAccountId] = useState<string>('');
    const [partnerName, setPartnerName] = useState<AccountPartnerName>('google');

    const startOAuthFlow = useCallback(
        (scopes: string[] | undefined, state: string[] | undefined) => {
            const cWindow = window.open(
                buildConnectionLinkUrl(connectionLink, scopes, state),
                'Login with social account',
                'height=600, width=800',
            );

            const checkLogin = setInterval(() => {
                const oauthAccount = localStorage.getItem(PARTOO_OAUTH_ACCOUNT);
                if (cWindow?.closed && oauthAccount) {
                    clearInterval(checkLogin);

                    const [storedPartnerName, storedOAuthAccountId] = oauthAccount.split('-');
                    localStorage.removeItem(PARTOO_OAUTH_ACCOUNT);
                    setOAuthAccountId(storedOAuthAccountId);
                    setPartnerName(storedPartnerName as AccountPartnerName);
                }
            }, 1000);

            return () => clearInterval(checkLogin);
        },
        [],
    );

    return { startOAuthFlow, oauthAccountId, partnerName, setOAuthAccountId };
};
