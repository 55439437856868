import { KeyboardEvent, useEffect, useState } from 'react';

import { Button, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { KeyCode } from 'app/common/types/keyboard';
import useEditSection from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/hooks/sections/useEditSection';
import useSection from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/hooks/sections/useSection';
import { GroupsSettingsQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

import {
    ModalButtons,
    ModalDescription,
    ModalTitle,
    SmallModalContainer,
} from '../../GroupsSettingsModals.styled';

type Props = {
    closeModal: () => void;
};

const EditSectionContent = ({ closeModal }: Props) => {
    const { t } = useTranslation();

    const [sectionId] = useStateQueryParams(GroupsSettingsQueryKeys.EDIT);
    const { data: section } = useSection(sectionId);
    const [sectionName, setSectionName] = useState<string>(section?.name ?? '');

    useEffect(() => {
        setSectionName(section?.name ?? '');
    }, [section?.name]);

    useEffect(() => {
        if (section && !section.can_edit) {
            closeModal();
        }
    }, [section?.can_edit]);

    const editSection = useEditSection(sectionId, closeModal);
    // @ts-ignore
    const error = editSection.error?.response?.data?.errors?.json?.name;

    const editSectionAction = () => {
        editSection.mutate(sectionName);
    };

    const handlePressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyCode.Enter) {
            editSectionAction();
        }
    };

    return (
        <SmallModalContainer>
            <ModalTitle>🚀 {t('rename_section_modal_title')}</ModalTitle>
            <ModalDescription>{t('section_modal_subtitle')}</ModalDescription>
            <TextInput
                dataTrackId={sectionName}
                value={sectionName}
                onChange={value => {
                    setSectionName(value ?? '');
                }}
                label={t('section_modal_field')}
                error={error}
                notice={error ? t(error) : ''}
                onKeyUp={handlePressEnter}
                autoFocus
            />
            <ModalButtons>
                <Button
                    appearance="outlined"
                    variant="secondary"
                    dataTrackId="edit_section_cancel"
                    onClick={closeModal}
                >
                    {t('cancel')}
                </Button>
                <Button
                    dataTrackId="edit_section_edit"
                    variant="primary"
                    onClick={editSectionAction}
                    disabled={!sectionName}
                >
                    {t('rename_section_modal_button')}
                </Button>
            </ModalButtons>
        </SmallModalContainer>
    );
};

export default EditSectionContent;
