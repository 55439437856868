import { PropsWithChildren, ReactNode } from 'react';

import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';

import {
    MenuListContent,
    MenuListContentFooter,
    MenuListContentHeader,
    MenuListContentTemplateContainer,
} from './MenuListContentTemplate.styled';

interface MenuListContentTemplateProps {
    headerContent?: ReactNode;
    footerContent?: ReactNode;
    withoutPadding?: boolean;
}

export const MenuListContentTemplate = ({
    headerContent,
    children,
    footerContent,
    withoutPadding = false,
}: PropsWithChildren<MenuListContentTemplateProps>) => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);

    return (
        <MenuListContentTemplateContainer>
            {headerContent && <MenuListContentHeader>{headerContent}</MenuListContentHeader>}
            <MenuListContent withoutPadding={withoutPadding}>{children}</MenuListContent>
            {footerContent && (
                <MenuListContentFooter isBelowProvidedDevices={isBelowProvidedDevices}>
                    {footerContent}
                </MenuListContentFooter>
            )}
        </MenuListContentTemplateContainer>
    );
};
