import styled from '@emotion/styled';

export const TitleContainer = styled.h4`
    font-weight: bold;
`;

export const ContentContainer = styled.div`
    textarea {
        height: 200px !important;
    }
`;

export const NoLinkMessage = styled.p`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin-left: 18px;
`;
