import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    CustomFieldsCreationKeys,
    CustomFieldsQueryKeys,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

const CustomFieldsEmptyPlaceholder = () => {
    const [, setCreateCustomField] = useStateQueryParams(CustomFieldsQueryKeys.CREATE);

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };

    return (
        <NotFoundTemplate
            show
            imgSrc={illustrationElement}
            title="cf_empty_placeholder_title"
            subtitle="cf_empty_placeholder_subtitle"
            buttonText="cf_empty_placeholder_button"
            handleClick={() => setCreateCustomField(CustomFieldsCreationKeys.CATALOG)}
        />
    );
};

export default CustomFieldsEmptyPlaceholder;
