import { useState } from 'react';

import { ConfirmModalProps } from './ConfirmModal';

type UseConfirmModalProps = Omit<ConfirmModalProps, 'onConfirm'>;
type OpenConfirmModalProps = Partial<UseConfirmModalProps>;
export type UseConfirmModal<ReturnValueT> = {
    /* Await this promise to open the ConfirmModal */
    askConfirmation: (openProps?: OpenConfirmModalProps) => Promise<ReturnValueT | undefined>;
    /* Call this function to close the modal, the parameter will be returned to askConfirmation */
    confirm: (returnValue?: ReturnValueT) => void;
    modalProps: UseConfirmModalProps;
};

/**
 *  Hook to allow imperative use of the ConfirmModal component
 *  @example
 *  const { askConfirmation, confirm, modalProps } = useConfirmModal();
 *  return (
 *      <>
 *          <button
 *              onClick={() => {
 *                  const confirmed = await askConfirmation({ content: 'Are you sure?' });
 *                  if (confirmed) {
 *                      save();
 *                  }
 *              }
 *          >
 *              Save
 *          </button>
 *          <ConfirmModal {...modalProps} onConfirm={() => confirm(true)}/>
 *      </>
 *  );
 */

export const useConfirmModal = <ReturnValueT = boolean>(
    modalName?: string,
): UseConfirmModal<ReturnValueT> => {
    const [openProps, setOpenProps] = useState<OpenConfirmModalProps>({});
    const [confirmPromise, setConfirmPromise] = useState<{
        resolve: (confirmed?: ReturnValueT) => void;
    } | null>(null);

    const openModal = async (openProps: OpenConfirmModalProps = {}) => {
        try {
            setOpenProps(openProps);
            return await new Promise<ReturnValueT | undefined>(resolve => {
                setConfirmPromise({ resolve });
            });
        } finally {
            setConfirmPromise(null);
            setOpenProps({});
        }
    };

    return {
        askConfirmation: openModal,
        confirm: (returnValue: ReturnValueT) => confirmPromise?.resolve(returnValue),
        modalProps: {
            trackId: `confirm_modal_${modalName}`,
            show: !!confirmPromise,
            onHide: () => confirmPromise?.resolve(undefined),
            ...openProps,
        },
    };
};
