import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const AddMoreHourModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    padding: 40px 24px 24px 24px;
`;

export const AddMoreHourTitle = styled.div`
    color: ${HEX_COLORS.blackberry};
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 8px;
    text-align: center;
`;

export const AddMoreHourDescription = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 16px;
`;

export const AddMoreHourContent = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
`;

export const AddMoreHourButton = styled.button`
    display: flex;
    align-items: center;
    height: 24px;
    margin: 4px;
    font-weight: bold;
    border-radius: 24px;
    border: none;
    outline: none;
    padding: 4px 8px;
    color: ${({ theme }) => theme.colors.primary.initial};
    background-color: ${({ theme }) => theme.colors.primary.alpha};

    > i {
        font-size: 12px;
        margin-right: 8px;
    }
`;
