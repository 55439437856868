import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';
import FlexContainer from 'app/styles/utils/flexbox';

import {
    ConsumerInfoAndMessageContainer,
    Content,
    ConversationCardContainer,
} from './ConversationCard.styled';
import {
    BusinessNameLoader,
    ConsumerImageLoader,
    ConsumerNameLoader,
    LastMessageLoader,
} from './ConversationCardLoader.styled';

const ConversationCardLoader = () => (
    <ConversationCardContainer>
        <Content>
            <FlexContainer flexDirection="row" justifyContent="space-between">
                <FlexContainer flexDirection="row">
                    <ConsumerImageLoader>
                        <LoadingRectangle recWidth="40px" recHeight="40px" recRadius="50%" />
                    </ConsumerImageLoader>
                    <ConsumerInfoAndMessageContainer>
                        <ConsumerNameLoader>
                            <LoadingRectangle recWidth="140px" recHeight="8px" />
                        </ConsumerNameLoader>
                        <BusinessNameLoader>
                            <LoadingRectangle recWidth="80px" recHeight="8px" />
                        </BusinessNameLoader>
                    </ConsumerInfoAndMessageContainer>
                </FlexContainer>
            </FlexContainer>
            <FlexContainer flexDirection="row" justifyContent="space-between" alignItems="center">
                <LastMessageLoader>
                    <LoadingRectangle recWidth="244px" recHeight="8px" />
                </LastMessageLoader>
            </FlexContainer>
        </Content>
    </ConversationCardContainer>
);

export default ConversationCardLoader;
