import useKeywordsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useKeywordsAnalytics';
import { useKeywordsQueryCount } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/keywords/count/useKeywordsQueryCount';

export const useKeywordsLoadingCount = () => {
    const { queryCurrent, queryPrevious } = useKeywordsQueryCount();

    const { isLoading: isLoadingCurrent } = useKeywordsAnalytics(queryCurrent);
    const { isLoading: isLoadingPrevious } = useKeywordsAnalytics(queryPrevious);

    return isLoadingCurrent || isLoadingPrevious;
};
