import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';
import { Radio, Stack, Text } from '@partoohub/ui';

export const SelectFieldPageContainer = styled(Stack)`
    width: 100%;
    justify-content: left;
`;

export const FieldOptionCard = styled(Stack)<{ active: boolean; disabled: boolean }>`
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    ${({ active, theme }) =>
        active &&
        css`
            border: 2px solid ${theme.colors.primary.initial};
        `}
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.3;
            cursor: not-allowed;
        `}
`;

export const FieldOptionIconWrapper = styled.div`
    background-color: #f6f8fc;
    color: ${HEX_COLORS.secondary};
    display: flex;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
`;

export const FieldName = styled(Text)`
    flex-grow: 1;
`;

export const RightRadio = styled(Radio)`
    position: relative;
    right: 0;
`;
