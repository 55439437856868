import { useMemo } from 'react';

import { uniq } from 'lodash-es';
import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { BUSINESSES_LANGUAGES } from 'app/common/data/queryKeysConstants';

export const useBusinessesLanguages = (
    businessFilters: BusinessModalFiltersType,
    initialBusinessesLanguages: Record<string, string> = {},
) => {
    /*
     ** When loading an existing settings, the languages its business should be already fetched
     ** so we just need to fetch languages of business the user adds.
     ** This is to reduce the size of the payload that might become too large
     ** for example when settings is applied for a group of hundreds+ business
     */
    const addedBusinessFilters = {
        ...businessFilters,
        business__in: businessFilters.business__in
            ?.split(',')
            .filter(b => !initialBusinessesLanguages.hasOwnProperty(b))
            .join(','),
    };
    const { data: addedData } = useQuery([BUSINESSES_LANGUAGES, addedBusinessFilters], () => {
        if (addedBusinessFilters.business__in === '') {
            return { languages: [] };
        }
        return api.business.getBusinessesLanguages(addedBusinessFilters);
    });

    return useMemo(() => {
        /*
         ** The result will then be the language of the business loaded that are still selected
         ** + the newly added businesses languages
         */
        return businessFilters.business__in
            ? uniq([
                  ...Object.entries(initialBusinessesLanguages)
                      .filter(([businessId]) =>
                          businessFilters.business__in?.split(',').includes(businessId),
                      )
                      .map(([, lang]) => lang),
                  ...(addedData?.languages ?? []),
              ])
            : addedData?.languages ?? [];
    }, [addedData, businessFilters, initialBusinessesLanguages]);
};
