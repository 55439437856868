import { useMutation } from 'react-query';

import { ConversationRouter } from 'app/api/v2/api_calls/conversationRouterApiCalls';
import { CONVERSATION_ROUTER } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

export const useConversationRouterActivation = () => {
    return useMutation(
        [CONVERSATION_ROUTER],
        (enabled: boolean) => ConversationRouter.patchConversationRouter(enabled),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([CONVERSATION_ROUTER]);
            },
        },
    );
};
