import { ReactNode, useLayoutEffect, useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Button, ButtonProps, Modal, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import {
    ChildrenContainer,
    ChildrenContainerSelectorId,
    ModalActions,
    ModalActionsSelectorId,
    ModalBody,
    ModalContentContainer,
    StyledCheckbox,
    TextContainer,
} from './ConfirmModal.styled';

type ConfirmModalFor = 'info' | 'download' | 'warning' | 'danger' | 'delete' | 'error';

export interface ConfirmModalProps {
    modalFor?: ConfirmModalFor;

    show: boolean;
    trackId: string;
    onConfirm: () => void;
    onHide: () => void;

    title?: string;
    image?: ReactNode;
    subtitle?: string;
    content?: string;
    cancelLabel?: string;
    confirmLabel?: string;
    children?: ReactNode;
    confirmDisabled?: boolean;
    className?: string;
    waitToConfirm?: number; // Number of seconds before the confirm button is enabled
    checkbox?: ReactNode; // If defined, a checkbox needs to be checked to enable the confirm button
    hideCancel?: boolean;
    hideOnClickOutside?: boolean;
}

const MODAL_DEFAULTS: {
    [k in ConfirmModalFor]:
        | {
              image?: IllustrationOldEnum;
              confirmVariant: ButtonProps['variant'];
              cancelVariant: ButtonProps['variant'];
              confirmLabelKey: string;
              cancelLabelKey: string;
          }
        | {
              image?: IllustrationOldEnum;
              confirmVariant: ButtonProps['variant'];
              confirmLabelKey: string;
          };
} = {
    info: {
        confirmVariant: 'primary',
        cancelVariant: 'secondary',
        confirmLabelKey: 'confirm',
        cancelLabelKey: 'cancel',
    },
    download: {
        image: IllustrationOldEnum.Download,
        confirmVariant: 'primary',
        cancelVariant: 'secondary',
        confirmLabelKey: 'confirm',
        cancelLabelKey: 'cancel',
    },
    delete: {
        image: IllustrationOldEnum.Delete,
        confirmVariant: 'danger',
        cancelVariant: 'secondary',
        confirmLabelKey: 'delete',
        cancelLabelKey: 'cancel',
    },
    warning: {
        image: IllustrationOldEnum.Warning,
        confirmVariant: 'primary',
        cancelVariant: 'secondary',
        confirmLabelKey: 'confirm',
        cancelLabelKey: 'cancel',
    },
    danger: {
        image: IllustrationOldEnum.Warning,
        confirmVariant: 'danger',
        cancelVariant: 'secondary',
        confirmLabelKey: 'confirm',
        cancelLabelKey: 'cancel',
    },
    error: {
        image: IllustrationOldEnum.Error,
        confirmVariant: 'danger',
        confirmLabelKey: 'confirm',
    },
};

export const ConfirmModal = ({
    modalFor = 'info',
    title,
    subtitle,
    content,
    image,
    show,
    children,
    cancelLabel,
    confirmLabel,
    trackId,
    confirmDisabled = false,
    waitToConfirm,
    checkbox,
    onConfirm,
    onHide,
    hideCancel,
    hideOnClickOutside,
}: ConfirmModalProps) => {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(false);
    const [blockCountdown, setBlockCountdown] = useState(0);

    const modalDefaults = MODAL_DEFAULTS[modalFor];
    const defaultIllustration = modalDefaults.image ? (
        <Illustration
            dataTrackId={modalDefaults?.image ?? 'illustration'}
            illustration={{
                type: IllustrationCategoryEnum.Old,
                name: modalDefaults.image,
            }}
            width="320"
            height="140"
        />
    ) : null;
    const modalImage = image ?? defaultIllustration;

    useLayoutEffect(() => {
        let timeout: NodeJS.Timeout;
        if (waitToConfirm && show) {
            setBlockCountdown(waitToConfirm);
            timeout = setInterval(
                () =>
                    setBlockCountdown(currentCountdown => {
                        const nextCountdown = currentCountdown - 1;
                        if (nextCountdown <= 0) {
                            clearInterval(timeout);
                            return 0;
                        } else {
                            return nextCountdown;
                        }
                    }),
                1000,
            );
        }
        return () => {
            clearInterval(timeout);
        };
    }, [waitToConfirm, show]);

    return (
        <Modal isOpen={show} closeModal={onHide} hideOnClickOutside={hideOnClickOutside}>
            <ModalContentContainer>
                <ModalBody>
                    {modalImage}
                    <TextContainer>
                        {title && <Text variant="heading4">{title}</Text>}
                        {subtitle && (
                            <Text variant="bodyMBold" color={HEX_COLORS.blackberry}>
                                {subtitle}
                            </Text>
                        )}
                        {content && <Text color={'secondary'}>{content}</Text>}
                        {checkbox && (
                            <StyledCheckbox
                                dataTrackId="confirm_modal_checkbox"
                                onChange={e => setChecked(e.target.checked)}
                                checked={checked}
                            >
                                <Text color={'secondary'}>{checkbox}</Text>
                            </StyledCheckbox>
                        )}
                    </TextContainer>
                    {children ? (
                        <ChildrenContainer data-css-selector={ChildrenContainerSelectorId}>
                            {children}
                        </ChildrenContainer>
                    ) : undefined}
                </ModalBody>

                <ModalActions data-css-selector={ModalActionsSelectorId}>
                    {'cancelVariant' in modalDefaults && !hideCancel && (
                        <Button
                            dataTrackId={`${trackId}_modal_cancel`}
                            shape="cube"
                            appearance="outlined"
                            size="large"
                            variant={modalDefaults.cancelVariant}
                            onClick={onHide}
                        >
                            {cancelLabel ?? t(modalDefaults.cancelLabelKey)}
                        </Button>
                    )}
                    <Button
                        dataTrackId={`${trackId}_modal_confirm`}
                        shape="cube"
                        size="large"
                        onClick={onConfirm}
                        variant={modalDefaults.confirmVariant}
                        disabled={confirmDisabled || !!blockCountdown || (!!checkbox && !checked)}
                    >
                        {blockCountdown
                            ? blockCountdown
                            : (confirmLabel ?? t(modalDefaults.confirmLabelKey))}
                    </Button>
                </ModalActions>
            </ModalContentContainer>
        </Modal>
    );
};
