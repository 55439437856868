import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

export const DayPreviewContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2px;
`;

export const DayPreviewName = styled.div`
    font-weight: bold;
    color: ${HEX_COLORS.blackberry};
    font-size: 14px;
    width: 96px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 4px;
    white-space: nowrap;
`;

export const HourPreview = styled.div`
    width: 104px;
    position: relative;
    color: ${HEX_COLORS.blackberry};
`;

export const DayPreviewHours = styled.div`
    display: flex;

    > ${HourPreview}:not(:first-of-type):before {
        display: flex;
        position: absolute;
        content: '';
        height: 10px;
        width: 1px;
        left: -12px;
        top: 5px;
        background-color: ${DISABLED_COLOR};
    }
`;

export const HourMoreInfos = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-weight: bold;

    > i {
        margin-right: 2px;
    }
`;
