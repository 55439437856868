import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon, Text } from '@partoohub/ui';

interface ChatbotSectionContainerProps {
    isOpened: boolean;
}

interface ChatbotSectionChevronIconProps {
    isOpened: boolean;
}

interface ChatbotSectionHeaderCheckProps {
    hasSucceeded: boolean;
}

export const ChatbotSectionContainer = styled.div<ChatbotSectionContainerProps>`
    border-radius: 8px;
    border: 1px solid #1425421f;
    ${({ isOpened, theme }) =>
        isOpened &&
        css`
            border: 1px solid ${theme.colors.primary.initial};
            box-shadow: ${theme.colors.primary.initial} 0 0 0 1px;
        `}
`;

export const ChatbotSectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    cursor: pointer;
`;

export const ChatbotSectionHeaderLeft = styled.div`
    display: flex;
    gap: 16px;
`;

export const ChatbotSectionHeaderCheck = styled(Icon)`
    border: 1px solid #1425421f;
    border-radius: 24px;
    display: flex;
    width: 32px;
    height: 32px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    ${(props: ChatbotSectionHeaderCheckProps) =>
        props.hasSucceeded &&
        css`
            border: none;
            background-color: #40dba21f;
        `}
`;

export const ChatbotSectionHeaderTitle = styled(Text)`
    display: flex;
    align-items: center;
`;

export const ChatbotSectionChevronIcon = styled(Icon)`
    display: flex;
    align-items: center;
    ${(props: ChatbotSectionChevronIconProps) =>
        props.isOpened &&
        css`
            transform: rotate(90deg);
        `}
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    cursor: pointer;
`;

export const ChatbotSectionExpandableContent = styled.div`
    padding: 8px 24px 24px 24px;
`;
