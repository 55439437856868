import { useMemo, useState } from 'react';

import { QuickConnectButton } from 'app/businessConnection/components/genericQuickConnect/QuickConnectButton';
import QuickConnectModal from 'app/businessConnection/components/genericQuickConnect/QuickConnectModal';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import QuickConnectContext from './QuickConnect.context';

export enum OpenedModalEnum {
    NONE = 'none',
    QUICK_CONNECT = 'quick_connect',
    BUSINESS_CONNECT = 'business_connect',
}

const QuickConnect = () => {
    const [show, setShow] = useState(false);
    const [publisher, setPublisher] = useState('google');
    const [showModal, setShowModal] = useStateQueryParams('modal');
    const [publisherUrl, setPublisherUrl] = useStateQueryParams('publisher');
    const [selectedSuggestions, setSelectedSuggestions] = useState([]);

    const quickConnectContext = useMemo(
        () => ({
            show,
            setShow,
            showModal,
            setShowModal,
            publisher,
            setPublisher,
            publisherUrl,
            setPublisherUrl,
            selectedSuggestions,
            setSelectedSuggestions,
        }),
        [show, showModal, publisher, selectedSuggestions],
    );

    return (
        <QuickConnectContext.Provider value={quickConnectContext}>
            <QuickConnectButton />

            <QuickConnectModal />
        </QuickConnectContext.Provider>
    );
};

export default QuickConnect;
