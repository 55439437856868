import { useState } from 'react';

const TICK_DELAY = 100;

/**
 * Creates a typewritten effect by creating slices that grows over time of an input text
 * @param setter A setter function to store the slices (e.g. a setState function)
 * @example
 * const [displayedText, setDisplayedText] = useState("");
 * const { typewrite } = useTypewriterEffect(setDisplayedText);
 * return (
 *     <>
 *       <button onClick={() => { typewrite(text) }}>Click to start typewriting</button>
 *       <textarea value={displayedText} />
 *     </>
 * )
 */
export default function useTypewriterEffect(setter: (text: string) => void) {
    const [cursor, setCursor] = useState(0);
    const [isWriting, setIsWriting] = useState(false);

    const typewrite = (content: string, wordsPerSecond = 30) => {
        /**
         * Creates a typewritten effect by returning slices that grows over time of an input text
         * @param content the text to typewrite
         * @param wordsPerSecond controls the typewriting speed (default: 30wps)
         *
         */
        setIsWriting(true);
        setCursor(0);
        setter('');
        const splittedText = content.split(' ');

        const updateCursors = () => {
            setCursor(prevCursor => {
                const nextCursor = Math.min(
                    prevCursor + (wordsPerSecond * TICK_DELAY) / 1000,
                    splittedText.length,
                );

                if (nextCursor < splittedText.length) {
                    setTimeout(updateCursors, TICK_DELAY);
                } else {
                    setIsWriting(false);
                }

                setter(splittedText.slice(0, nextCursor).join(' '));

                return nextCursor;
            });
            return cursor;
        };

        updateCursors();
    };

    return { typewrite, isWriting };
}
