import { useState } from 'react';

import {
    Button,
    ButtonVariants,
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    Modal,
    SingleSelect,
    Stack,
    Text,
    TextInput,
    toast,
} from '@partoohub/ui';
import { cloneDeep, isEmpty, isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { CustomFieldsCreationPayload } from 'app/api/types/business';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';

import { STATIC_BASE_URL } from 'app/config';
import useCreateCustomField from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useCreateCustomField';
import useCustomFieldsSections from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useCustomFieldsSections';
import useEditCustomField from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useEditCustomField';
import {
    DuplicatedOptionChecker,
    canCreateCustomFields,
    canEditCustomFields,
    formatPayloadCreation,
    formatPayloadEdition,
    getDescriptionTranslation,
    getIconName,
    getTypeTranslation,
    customFieldsType as types,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/services';

import {
    CreationModalButtons,
    CreationModalContainer,
    CreationModalContent,
    CreationModalDescription,
    CreationModalForm,
    CreationModalFormSection,
    CreationModalIcon,
    CreationModalTitle,
    ParamsFormContainer,
} from './CustomFieldForm.styled';

import { FormTypeImagesUploaderCreate } from './SpecificFormType/FormTypeImagesUploader/FormTypeImagesUploaderCreate';
import { FormTypeImagesUploaderEdit } from './SpecificFormType/FormTypeImagesUploader/FormTypeImagesUploaderEdit';
import { FormTypeNumber } from './SpecificFormType/FormTypeNumber';
import { FormTypeSelect } from './SpecificFormType/FormTypeSelect';
import { FormTypeSelectImage } from './SpecificFormType/FormTypeSelectImage/FormTypeSelectImage';
import { FormTypeText } from './SpecificFormType/FormTypeText';
import WarningEditModal from './WarningEditModal';
import { CustomFieldsCreateSectionContent } from '../CustomFieldsCreateSectionModal/CustomFieldsCreateSectionContent';

type Props = {
    customFieldsType: string;
    order?: number;
    closeModal: () => void;
    editMode: boolean;
    oldParams?: CustomFieldsCreationPayload;
};

const CustomFieldForm = ({ customFieldsType, order, closeModal, editMode, oldParams }: Props) => {
    const { t } = useTranslation();
    const { data: customFieldsSections } = useCustomFieldsSections();

    // State storing the current custom field form depending on the type
    const [typeParams, setTypeParams] = useState<Record<string, any>>(
        editMode ? cloneDeep(oldParams ?? {}) : {},
    );

    const [name, setName] = useState<string>(editMode ? (oldParams?.name ?? '') : '');
    const nameWarning = editMode && oldParams?.name && name && name !== oldParams?.name;

    const createCustomField = useCreateCustomField(() => {
        toast.success(
            t('custom_fields_toast_create_successful_description'),
            t('custom_fields_toast_create_successful', { name }),
        );
        closeModal();
    });

    const editCustomField = useEditCustomField(oldParams?.id ?? 0, () => {
        toast.success(
            t('custom_fields_toast_edit_successful_description'),
            t('custom_fields_toast_edit_successful', { name }),
        );
        closeModal();
    });

    const error = editMode
        ? // @ts-ignore
          (editCustomField.error?.response?.data?.errors?.json ?? {})
        : // @ts-ignore
          (createCustomField.error?.response?.data?.errors?.json ?? {});

    const loading = editMode ? editCustomField.isLoading : createCustomField.isLoading;

    const resetForm = () => {
        setName(editMode ? (oldParams?.name ?? '') : '');
        setSectionId(editMode ? (oldParams?.section_id ?? null) : null);
        setTypeParams(editMode ? cloneDeep(oldParams ?? {}) : {});
        createCustomField.reset();
    };

    const getCustomFieldsParamsForm = (type: string) => {
        switch (type) {
            case types.BOOLEAN:
                return null;

            case types.TEXT:
                return (
                    <ParamsFormContainer>
                        <FormTypeText
                            updateParams={setTypeParams}
                            params={typeParams}
                            error={error}
                        />
                    </ParamsFormContainer>
                );

            case types.NUMBER:
            case types.INTEGER:
            case types.FLOAT:
                return (
                    <ParamsFormContainer>
                        <FormTypeNumber
                            updateParams={setTypeParams}
                            editMode={editMode}
                            params={typeParams}
                        />
                    </ParamsFormContainer>
                );

            case types.SELECT:
            case types.SINGLE_SELECT:
            case types.MULTIPLE_SELECT:
                return (
                    <ParamsFormContainer>
                        <FormTypeSelect
                            updateParams={setTypeParams}
                            editMode={editMode}
                            params={typeParams}
                            duplicates={
                                error?.duplicated_options
                                    ? DuplicatedOptionChecker(
                                          typeParams,
                                          error?.duplicated_options,
                                          editMode,
                                          type,
                                      )
                                    : {}
                            }
                        />
                    </ParamsFormContainer>
                );

            case types.MULTIPLE_SELECT_IMAGE:
                return (
                    <ParamsFormContainer>
                        <FormTypeSelectImage
                            updateParams={setTypeParams}
                            editMode={editMode}
                            params={typeParams}
                            duplicates={
                                error?.duplicated_options
                                    ? DuplicatedOptionChecker(
                                          typeParams,
                                          error?.duplicated_options,
                                          editMode,
                                          type,
                                      )
                                    : {}
                            }
                        />
                    </ParamsFormContainer>
                );

            case types.IMAGES_UPLOADER:
                const FormTypeComponent = editMode
                    ? FormTypeImagesUploaderEdit
                    : FormTypeImagesUploaderCreate;
                return (
                    <ParamsFormContainer>
                        <FormTypeComponent
                            params={typeParams}
                            updateParams={setTypeParams}
                            duplicates={error?.duplicated_options ?? []}
                        />
                    </ParamsFormContainer>
                );

            default:
                return null;
        }
    };

    const dataHasChanged = () => {
        if (!editMode) {
            return false;
        }

        return !isEqual(
            formatPayloadEdition({
                type: customFieldsType,
                order,
                ...typeParams,
                name,
                section_id: sectionId,
            }),
            formatPayloadEdition(oldParams ?? {}),
        );
    };

    const validate = (forcedUpdate: boolean) => {
        if (editMode) {
            const payload = formatPayloadEdition({
                type: customFieldsType,
                order,
                ...typeParams,
                name,
                forced_update: forcedUpdate,
                section_id: sectionId ? sectionId.toString() : null,
            });
            editCustomField.mutate(payload);
        } else {
            const payload = formatPayloadCreation({
                type: customFieldsType,
                order,
                ...typeParams,
                name,
                section_id: sectionId ? sectionId.toString() : null,
            });
            createCustomField.mutate(payload);
        }
    };

    const statefulButtonStatus = (): ButtonVariants => {
        const canSave = editMode
            ? canEditCustomFields({
                  type: customFieldsType,
                  ...typeParams,
              })
            : canCreateCustomFields({
                  type: customFieldsType,
                  ...typeParams,
              });

        if (!canSave || !name) return 'secondary';

        if (!isEmpty(error)) {
            if (
                Object.keys(error).length === 1 &&
                Object.keys(error).includes('duplicated_options') &&
                isEmpty(
                    DuplicatedOptionChecker(
                        typeParams,
                        error?.duplicated_options,
                        editMode,
                        customFieldsType,
                    ),
                )
            ) {
                return 'primary';
            }

            return 'danger';
        }

        if (loading) return 'secondary';
        return 'primary';
    };

    const hideWarningEditModal = () => {
        createCustomField.reset();
        editCustomField.reset();
    };

    const errorExplanation =
        error?.name && error?.name === 'already_exists'
            ? t('custom_fields_name_already_exist')
            : t('custom_fields_name_too_long');
    const nameExplanation =
        name.length <= 50 && nameWarning ? t('custom_fields_name_has_changed_warning') : '';
    const explanation = error?.name ? errorExplanation : nameExplanation;

    // Custom Field Section
    const [sectionId, setSectionId] = useState<number | null>(
        editMode ? (oldParams?.section_id ?? null) : null,
    );
    const sectionSelectOptions =
        customFieldsSections?.sections?.map(section => ({
            value: section.id.toString(),
            name: section.id.toString(),
            label: section.name,
        })) ?? [];
    const selectedSectionName = sectionSelectOptions.find(
        section => section.value === sectionId?.toString(),
    )?.label;
    const selectedOption = selectedSectionName
        ? {
              value: sectionId?.toString() ?? '',
              name: sectionId?.toString() ?? '',
              label: selectedSectionName,
          }
        : undefined;

    // Create Section Modal
    const [createSectionModalIsOpen, setCreateSectionModalIsOpen] = useState<boolean>(false);
    const closeCreateSectionModal = () => setCreateSectionModalIsOpen(false);

    return (
        <CreationModalContainer>
            <CreationModalContent>
                <CreationModalButtons>
                    {editMode && dataHasChanged() && (
                        <StrikeThroughButton
                            text={t('drop_changes')}
                            color="secondary"
                            onClick={resetForm}
                            icon={
                                <img
                                    src={`${STATIC_BASE_URL}/images/app/icons/reload.svg`}
                                    alt=""
                                />
                            }
                        />
                    )}
                    <IconButton
                        appearance="outlined"
                        onClick={closeModal}
                        icon={[FontAwesomeIconsPartooUsed.faXMarkLarge, IconPrefix.SOLID]}
                        dataTrackId="close_custom_field_button"
                    />
                </CreationModalButtons>

                <CreationModalTitle>
                    <CreationModalIcon
                        className="custom_fields_creation_modal__title__icon"
                        src={`${STATIC_BASE_URL}/images/app/custom_fields/${getIconName(
                            customFieldsType,
                        )}`}
                        alt=""
                    />
                    <Text variant="heading3">{name || getTypeTranslation(customFieldsType)}</Text>
                </CreationModalTitle>

                <CreationModalDescription variant="bodyMBold" color="secondary">
                    {getDescriptionTranslation(customFieldsType)}
                </CreationModalDescription>
                <Stack gap="24px">
                    <CreationModalForm>
                        <TextInput
                            dataTrackId="custom_field__name"
                            value={name}
                            onChange={newName => setName(newName ?? '')}
                            label={t('name')}
                            autoFocus
                            warning={!!nameWarning}
                            error={!!error?.name}
                            characterCount={50}
                            notice={explanation}
                            required
                        />
                        <CreationModalFormSection>
                            <SingleSelect
                                dataTrackId="custom_field__section_id"
                                label={t('add_custom_field_to_section')}
                                selectedValue={selectedOption}
                                sections={[{ options: sectionSelectOptions }]}
                                onChange={value =>
                                    setSectionId(value?.value ? Number(value?.value) : null)
                                }
                            />
                            <IconButton
                                dataTrackId="custom_field__section_id_add_section"
                                onClick={() => setCreateSectionModalIsOpen(true)}
                                appearance="outlined"
                                icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                            />
                        </CreationModalFormSection>

                        {getCustomFieldsParamsForm(customFieldsType)}
                    </CreationModalForm>

                    <Button
                        size="large"
                        variant={statefulButtonStatus()}
                        appearance="contained"
                        shape="cube"
                        full
                        onClick={() => validate(false)}
                        disabled={statefulButtonStatus() === 'secondary'}
                        dataTrackId="save_custom_field_button"
                    >
                        {editMode ? t('save') : t('create_field')}
                    </Button>
                </Stack>
            </CreationModalContent>

            <Modal
                isOpen={editMode && error?.businesses_count !== undefined}
                closeModal={hideWarningEditModal}
            >
                <WarningEditModal
                    closeModal={hideWarningEditModal}
                    loading={loading}
                    name={name}
                    type={customFieldsType}
                    editCustomField={() => validate(true)}
                />
            </Modal>
            <Modal isOpen={createSectionModalIsOpen} closeModal={closeCreateSectionModal}>
                <CustomFieldsCreateSectionContent
                    closeModal={closeCreateSectionModal}
                    onSuccess={section_id => {
                        setSectionId(section_id);
                    }}
                    calledFromEditModal
                />
            </Modal>
        </CreationModalContainer>
    );
};

export default CustomFieldForm;
