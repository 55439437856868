import { css } from '@emotion/react';
import {
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    List,
    Stack,
    Text,
} from '@partoohub/ui';
import { Link } from 'react-router-dom';

import { StyledIcon } from './SettingsSummaryItem.styled';
import { StyledRow, settingsSummaryColors } from '../SettingsSummary/SettingsSummary.styled';

export type SettingSummaryItemValues = {
    settingData: SettingSummaryItemData;
    isLoading?: boolean;
};

export type SettingSummaryItemData = {
    id: string;
    url: string;
    icon: string | FontAwesomeIconsPartooUsed;
    title: string;
    description: string;
    display: boolean;
};

interface SettingsSummaryItemProps {
    settingData: SettingSummaryItemData;
}

export const SettingsSummaryItem = ({ settingData }: SettingsSummaryItemProps) => {
    const { id, url, icon, title, description, display } = settingData;

    if (!display) {
        return null;
    }

    return (
        <Link key={id} to={url}>
            <StyledRow dataTrackId={id} id={id}>
                <List.Cell>
                    <StyledIcon
                        color={settingsSummaryColors[parseInt(id)]}
                        icon={[icon, IconPrefix.REGULAR]}
                    />
                </List.Cell>
                <List.Cell>
                    <Stack direction="column" gap="4px">
                        <Text variant="bodyLBold">{title}</Text>
                        <Text variant="bodyMRegular" color="secondary">
                            {description}
                        </Text>
                    </Stack>
                </List.Cell>
                <List.Cell
                    css={css`
                        align-items: flex-end;
                        padding-right: 8px;
                    `}
                >
                    <IconButton
                        dataTrackId="setting_summary_item__dropdown"
                        icon={[FontAwesomeIconsPartooUsed.faChevronRight]}
                        color="secondary"
                        appearance="outlined"
                    />
                </List.Cell>
            </StyledRow>
        </Link>
    );
};
