import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { CONTAINED } from 'app/common/designSystem/constants/type';
import { useProductUpsale } from 'app/common/hooks/queries/useProductUpsale';

const illustrationElement: IllustrationLaptopElement = {
    type: IllustrationCategoryEnum.Laptop,
    name: IllustrationLaptopEnum.Sad,
};

const notFoundTemplateProps = {
    show: true,
    imgSrc: illustrationElement,
    title: 'suscribe_to_pm_title',
    subtitle: 'presence_management_description',
    buttonText: 'contact_us_to_get_more_information',
    buttonStatus: CONTAINED,
};

export const NoPresenceManagement = () => {
    const { refetch } = useProductUpsale('presence_management');
    return <NotFoundTemplate {...notFoundTemplateProps} handleClick={refetch} />;
};
