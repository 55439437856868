import { useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import NoBusiness from 'app/common/components/placeholder/NoBusiness.container';
import { NoPresenceManagement } from 'app/common/components/placeholder/PresenceManagement/NoPresenceManagement';
import { requestPossibleCities, searchBusinesses } from 'app/common/reducers/newBusinesses';
import { searchOrgs } from 'app/common/reducers/orgs';
import {
    ContentSection,
    FiltersSection,
    HeaderSection,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components';
import { AnalyticsTabs } from 'app/pages/analytics/VisibilityAnalyticsV2/components/AnalyticsTabs';
import useBusinessWithPmSearch from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/business/useBusinessWithPmSearch';
import { VisibilityContainer } from 'app/pages/analytics/VisibilityAnalyticsV2/Visibility.styled';

export const Visibility = () => {
    // TODO BIRD / MORTY clean this later...
    const { businesses, isLoading } = useBusinessWithPmSearch();
    const dispatch = useDispatch();

    // To be sure to have data inside store for the business in mobile mode
    useEffect(() => {
        dispatch(searchBusinesses(1, ''));
        dispatch(requestPossibleCities());
        dispatch(searchOrgs(1, ''));
    }, []);

    const hasPmBusinesses = useMemo(
        () =>
            businesses.some(
                business => business?.subscriptions?.presence_management?.active === true,
            ),
        [businesses],
    );

    if (!hasPmBusinesses && !isLoading) {
        return <NoPresenceManagement />;
    }

    if (!businesses && !isLoading) {
        return <NoBusiness />;
    }

    return (
        <VisibilityContainer>
            <HeaderSection />
            <AnalyticsTabs />
            <FiltersSection />
            <ContentSection />
        </VisibilityContainer>
    );
};
