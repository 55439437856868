import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const UserDeleteContentContainer = styled.div`
    border-radius: 8px;
    width: 500px;
`;

export const UserDeleteContentTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    padding: 24px 24px 0 24px;
`;

export const UserDeleteImg = styled.img`
    width: 80%;
`;

export const UserDeleteTitle = styled.div`
    margin-top: 14px;
    font-size: 26px;
    text-align: center;
    color: ${HEX_COLORS.blackberry};
    font-weight: bold;
    margin-bottom: 16px;
`;

export const UserDeleteSubtitle = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin-bottom: 16px;
`;

export const UserDeleteWarning = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    color: ${({ theme }) => theme.colors.warning.initial};
    background-color: ${({ theme }) => theme.colors.warning.alpha};
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    margin-bottom: 16px;
`;

export const UserDeleteInput = styled.div`
    width: 100%;
`;

export const UserDeleteCancel = styled.div`
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary.initial};

    &:hover {
        cursor: pointer;
    }
`;

export const UserDeleteButton = styled.div`
    margin: 16px;
    display: flex;
`;
