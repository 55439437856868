import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';

export default function useBusinessCalculateCompletionRate(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    return useMutation(
        (businessId: string) => api.business.updateBusinessCompletionRate(businessId),
        {
            onSuccess,
            onError,
        },
    );
}
