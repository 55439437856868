import { Ref, useEffect, useState } from 'react';

import { extractPartooSubdomain, isUrl } from 'app/common/utils/urls';
import { useIntersectionObserver } from 'app/hooks';
import { resizeImage } from 'app/utils/resizeImage';

export enum ImageStatus {
    Initial = 'initial',
    Loading = 'loading',
    Success = 'success',
    Error = 'error',
}

export function useImageWithFallback(
    target: Ref<HTMLDivElement>,
    src: string,
    width = 50,
    height = 50,
    shouldResize = true,
) {
    const [state, setState] = useState<ImageStatus>(ImageStatus.Initial);
    const [transformedSrc, setTransformedSrc] = useState<string>('');

    const intersecting = useIntersectionObserver(target, {
        root: document.body,
        rootMargin: '0px',
        threshold: 0,
    });

    useEffect(() => {
        const buildStaticBaseUrl = () => {
            if (extractPartooSubdomain() === '') {
                return 'https://static.partoo.co';
            }
            return `https://static.${extractPartooSubdomain()}.partoo.co`;
        };

        const getSrcImg = () => {
            if (isUrl(src) && shouldResize) {
                return resizeImage(src, width, height, 'inside');
            } else if (isUrl(src)) {
                return src;
            } else {
                return `${buildStaticBaseUrl()}/${src}`;
            }
        };
        setTransformedSrc(getSrcImg());
    }, []);

    useEffect(() => {
        if (intersecting && state === ImageStatus.Initial) {
            setState(ImageStatus.Loading);
            const img = new Image();
            img.onload = () => {
                setState(ImageStatus.Success);
            };
            img.onerror = () => {
                setState(ImageStatus.Error);
            };
            img.src = transformedSrc;
        }
    }, [intersecting, state, transformedSrc]);

    return { state, originalSrc: src, transformedSrc };
}
