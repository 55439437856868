const SCRIPT_1 = 'intercom-script-1';
const SCRIPT_2 = 'intercom-script-2';
const SCRIPT_3 = 'intercom-script-3';

const SCRIPT_IDS = [SCRIPT_1, SCRIPT_2, SCRIPT_3];

const DEFAULT_INTERCOM_APP_ID = 'qvipsi0n';
const PROD_INTERCOM_APP_ID = 'il04ga96';

const loadScript = (script: HTMLScriptElement, scriptName: string) => {
    return new Promise((resolve, reject) => {
        script.onload = () => {
            resolve(`[Intercom] ${scriptName} loaded successfully`);
        };

        script.onerror = () => {
            const error = new Error(`[Intercom] Error loading ${scriptName}`);
            reject(error);
        };
    });
};

export const handleIntercom = () => {
    // Determine the INTERCOM_APP_ID based on the pathname
    const INTERCOM_APP_ID = ['app.partoo.co', 'app.mavisibiliteweb.fr'].includes(
        window.location.hostname,
    )
        ? PROD_INTERCOM_APP_ID
        : DEFAULT_INTERCOM_APP_ID;

    // Create the intercomSettings object
    const intercomSettings = { api_base: 'https://api-iam.intercom.io', app_id: INTERCOM_APP_ID };

    // Create the script elements
    const script1 = document.createElement('script');
    script1.id = SCRIPT_1;
    script1.textContent = 'var INTERCOM_APP_ID = \'' + INTERCOM_APP_ID + '\';';

    const script2 = document.createElement('script');
    script2.id = SCRIPT_2;
    script2.type = 'text/javascript';
    script2.textContent = 'window.intercomSettings=' + JSON.stringify(intercomSettings) + ';';

    const srcIntercom = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`;
    const script3 = document.createElement('script');
    script3.id = SCRIPT_3;
    script3.textContent = `
        (function(){
            var w=window;
            var ic=w.Intercom;
            if(typeof ic==="function"){
                ic('reattach_activator');
                ic('update',w.intercomSettings);
            } else {
                var d=document;
                var i=function(){i.c(arguments);};
                i.q=[];
                i.c=function(args){i.q.push(args);};
                w.Intercom=i;
                var l=function(){
                    var s=d.createElement('script');
                    s.type='text/javascript';
                    s.async=true;
                    s.src='${srcIntercom}';
                    var x=d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s,x);
                };
                if(document.readyState==='complete'){
                    l();
                }
                else if(w.attachEvent){
                    w.attachEvent('onload',l);
                } else { 
                    w.addEventListener('load',l,false);
                }
            }
        })();
    `;

    const scriptPromises = [
        loadScript(script1, 'script 1'),
        loadScript(script2, 'script 2'),
        loadScript(script3, 'script 3'),
    ];

    // Append the scripts to the body
    document.body.appendChild(script1);
    document.body.appendChild(script2);
    document.body.appendChild(script3);

    return Promise.all(scriptPromises)
        .then(() => {
            // eslint-disable-next-line no-console
            console.log('[Intercom] All scripts loaded successfully');
        })
        .catch(() => {
            console.error('[Intercom] Error loading scripts');
        });
};

export const removeIntercomScript = () => {
    SCRIPT_IDS.forEach(id => {
        const script = document.getElementById(id);
        if (script) {
            script.remove();
        }
    });
};
