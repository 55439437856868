import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { STATIC_BASE_URL } from 'app/config';

export const CardContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
`;

export const CardItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 441px;
    max-height: 287px;
    background: ${HEX_COLORS.white};
    border: 1px solid ${greyColorObject.initial};
    border-radius: 8px;
    padding: 10px 0;

    img,
    span.add-location-icon {
        width: 72px;
        height: 72px;
        margin-bottom: 12px;
    }

    span.add-location-icon {
        background-image: url(${`${STATIC_BASE_URL}/images/app/funnel/icon-background.svg`});
        display: inherit;
        justify-content: center;
        align-items: center;

        i {
            width: 36px;
            height: 42px;
            mask: url(${`${STATIC_BASE_URL}/images/app/funnel/add-location-icon.svg`}) no-repeat;
            background-color: ${({ theme }) => theme.colors.primary.initial};
        }
    }

    &:hover {
        margin-top: -8px;
        transition: margin-top 100ms ease-in-out;
        box-shadow: 0 30px 40px rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    &:hover:after {
        // prevents flickering on hover
        position: absolute;
        content: '';
        left: 0;
        bottom: -10px;
        height: 10px;
        width: 100%;
        box-sizing: border-box;
    }
`;

export const CardItemTitle = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: ${HEX_COLORS.blackberry};
    margin-bottom: 6px;
`;

export const CardItemSubTitle = styled.div`
    width: 90%;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin-bottom: 16px;
`;

export const VerticalSeparator = styled.div`
    width: 24px;
`;
