import { useMutation, useQueryClient } from 'react-query';

import { FormattedUserData } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { ME, USERS } from 'app/common/data/queryKeysConstants';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export default function useMeUpdate(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const { data: me } = useMe();
    const queryClient = useQueryClient();

    return useMutation((payload: FormattedUserData) => api.user.editUser(me?.id ?? '', payload), {
        onSuccess: () => {
            // Invalidating the cache can trigger the getMeSaga from the AppDataProvider
            queryClient.invalidateQueries(invalidatesAny(ME, USERS)).then(onSuccess);
        },
        onError,
    });
}
