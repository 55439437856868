import { useContext, useEffect, useState } from 'react';

import { TextInput, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls/camel';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { RefreshPostsContext } from 'app/presence/googlePosts/components/googlePostList/index';
import { EditPostsContext } from 'app/presence/googlePosts/components/googlePostList/PostsList';
import usePostBulkCount from 'app/presence/googlePosts/hooks/usePostBulkCount';

type Props = {
    show: boolean;
    onHide: () => void;
};

export default function PostDeleteConfirmModal({ show, onHide }: Props) {
    const { t } = useTranslation();
    const { bulkId, postId } = useContext(EditPostsContext);
    const { setRefreshPosts } = useContext(RefreshPostsContext);
    const [confirmInputValue, setConfirmInputValue] = useState<string>('');
    const isConfirmDisabled = !!bulkId && confirmInputValue !== 'DELETE ALL';

    const { bulkCount, isLoading: isBulkCountLoading } = usePostBulkCount(bulkId);

    const { mutate, isLoading: isDeleteLoading } = useMutation(
        () => {
            if (bulkId) {
                return api.post.deletePostBulk(bulkId);
            }

            return api.post.deletePost(postId);
        },
        {
            onSuccess: () => {
                setRefreshPosts(true);
            },
            onError: () => {
                toast.error(null, t('error'));
            },
            onSettled: onHide,
        },
    );

    useEffect(() => {
        setConfirmInputValue('');
    }, [show]);

    const title = bulkId ? 'post_delete_bulk_modal_title' : 'delete_google_post';
    const content = bulkId ? 'post_delete_bulk_modal_content' : 'delete_google_post_modal_text';
    const confirm = bulkId ? 'post_delete_bulk_modal_confirm' : 'delete';

    return (
        <ConfirmModal
            modalFor="delete"
            trackId="post_delete"
            show={show && !isBulkCountLoading}
            onHide={onHide}
            title={t(title)}
            content={t(content, { count: bulkCount })}
            onConfirm={mutate}
            confirmLabel={isDeleteLoading ? t('loading') : t(confirm)}
            confirmDisabled={isConfirmDisabled || isDeleteLoading}
        >
            {bulkId ? (
                <TextInput
                    dataTrackId="post_delete_bulk_modal_input"
                    label={t('post_delete_bulk_modal_input')}
                    onChange={value => setConfirmInputValue(value ?? '')}
                    value={confirmInputValue}
                />
            ) : undefined}
        </ConfirmModal>
    );
}
