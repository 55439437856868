import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import { StyledReviewCardCommentsSection } from './CommentSection/ReviewCardCommentsSection.styled';
import { StyledReviewCard } from './ReviewCard.styled';
import { StyledReviewCardContent } from './ReviewCardContent/ReviewCardContent.styled';
import { StyledReviewAuthor } from './ReviewCardHeader/ReviewCardHeader.styled';
import { LoadingCommentTable, LoadingReviewToggle } from './ReviewCardLoader.styled';

export default function ReviewCardLoader() {
    return (
        <StyledReviewCard>
            <LoadingRectangle recWidth="40px" recHeight="40px" recRadius="50%" />
            <StyledReviewAuthor>
                <LoadingRectangle recWidth="150px" recHeight="15px" recMargin="0 0 5px" />
                <LoadingRectangle recWidth="200px" recHeight="15px" />
            </StyledReviewAuthor>
            <LoadingReviewToggle recWidth="50px" recHeight="15px" />
            <StyledReviewCardContent>
                <div />
                <LoadingRectangle recWidth="60%" recHeight="15px" recMargin="10px 0 24px 0px" />
            </StyledReviewCardContent>
            <StyledReviewCardCommentsSection>
                <LoadingCommentTable
                    recWidth="60%"
                    recHeight="40px"
                    recRadius="4px"
                    recMargin="0 0 0 48px"
                />
            </StyledReviewCardCommentsSection>
        </StyledReviewCard>
    );
}
