import { camelCase, mapKeys } from 'lodash-es';

import {
    V2AccountDetails,
    V2FormattedAccountDetails,
} from 'app/api/types/account';

const formatGmbDetails = (
    business: V2AccountDetails,
    // @ts-ignore
): V2FormattedAccountDetails => mapKeys(business, (v, key) => camelCase(key));

export default formatGmbDetails;
