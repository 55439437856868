import { FC, useCallback, useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Button, Modal, Text } from '@partoohub/ui';
import queryString from 'query-string';

import { useTranslation } from 'react-i18next';

import ArrowButton from 'app/common/designSystem/components/atoms/ArrowButton';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import CheckableBar from 'app/common/designSystem/components/molecules/CheckableBar';

import { usePopupWindow } from 'app/common/hooks/usePopupWindow';
import dataLayer from 'app/common/utils/dataLayer';
import { STATIC_BASE_URL } from 'app/config';
import { FACEBOOK_OAUTH2_START_PATH } from 'app/routing/routeIds';

import {
    ModalContainer,
    StyledBodyText,
    StyledBodyText2,
    StyledButtonContainer,
    StyledCancelButton,
    StyledCheckableBoxContainer,
    StyledFailureFooter,
    StyledFailureHeading,
    StyledFailureIcon,
    StyledInstagramIcon,
    StyledInstagramSuccessCheckIcon,
    StyledModalContent,
    StyledPreviousButtonContainer,
} from './AddPartnerModal.styled';
import BusinessIcon from './InstagramAsBusinessIcon';
import LinkIcon from './InstagramLinkedToFbIcon';

interface AddInstagramModalProps {
    show: boolean;
    onHideClick: () => void;
    onClose: () => void;
}

const InstagramIcon: FC = () => (
    <StyledInstagramIcon
        src={`${STATIC_BASE_URL}/images/app/app_messaging/instagram_3D_icon.png`}
        alt="messenger_icon"
    />
);

const Background: FC = () => (
    <img
        src={`${STATIC_BASE_URL}/images/app/app_messaging/messenger_modal_background.svg`}
        alt="background"
    />
);

const SuccessCheckIcon: FC = () => (
    <StyledInstagramSuccessCheckIcon>
        <img
            src={`${STATIC_BASE_URL}/images/app/app_messaging/instagram_3D_icon.png`}
            className="instagram-3d-icon"
            alt="instagram icon"
        />
        <img
            src={`${STATIC_BASE_URL}/images/app/app_messaging/check-perspective.png`}
            className="check-mark-3d"
            alt="check-3d"
        />
    </StyledInstagramSuccessCheckIcon>
);

const FailureIcon: FC = () => (
    <StyledFailureIcon
        src={`${STATIC_BASE_URL}/images/app/app_messaging/instagram_onboarding_failed.svg`}
        alt="onboarding_failed"
    />
);

interface StepOneProps {
    onCancel: () => void;
    onButtonClick: () => void;
}

const AddInstagramStepOne: FC<StepOneProps> = ({ onCancel, onButtonClick }) => {
    const { t } = useTranslation();
    return (
        <ModalContainer>
            <Background />
            <InstagramIcon />
            <StyledModalContent style={{ textAlign: 'center' }}>
                <Text variant="heading4" as="h4" color={HEX_COLORS.blackberry}>
                    {t('messaging_add_instagram_information_modale_title')}
                </Text>
                <StyledBodyText variant="bodyMRegular" as="span" color={'secondary'}>
                    {t('messaging_add_instagram_information_modale_text')}
                </StyledBodyText>
            </StyledModalContent>
            <StyledCancelButton>
                <StrikeThroughButton
                    text={t('messaging_add_instagram_information_modale_later_button')}
                    color="secondary"
                    onClick={onCancel}
                />
            </StyledCancelButton>
            <StyledButtonContainer>
                <Button
                    dataTrackId="add_instagram_modal__button"
                    variant="primary"
                    full
                    size="large"
                    shape="cube"
                    onClick={onButtonClick}
                >
                    {t('messaging_add_instagram_information_modale_button')}
                </Button>
            </StyledButtonContainer>
        </ModalContainer>
    );
};

interface StepTwoProps {
    onPreviousClick: () => void;
    onContinueClick: () => void;
}

const AddInstagramStepTwo: FC<StepTwoProps> = ({ onPreviousClick, onContinueClick }) => {
    const { t } = useTranslation();

    const [businessAccountChecked, setBusinessAccountChecked] = useState<boolean>(false);
    const [instagramLinkedToFBChecked, setInstagramLinkedToFBChecked] = useState<boolean>(false);

    const toggleInstagramAsBusinessAccount = () => {
        setBusinessAccountChecked(!businessAccountChecked);
    };

    const toggleInstagramLinkedToFB = () => {
        setInstagramLinkedToFBChecked(!instagramLinkedToFBChecked);
    };

    return (
        <ModalContainer>
            <StyledPreviousButtonContainer>
                <ArrowButton
                    arrowDirection="left"
                    goToNextSlide={onContinueClick}
                    goToPreviousSlide={onPreviousClick}
                />
            </StyledPreviousButtonContainer>

            <StyledModalContent style={{ textAlign: 'center' }}>
                <Text variant="heading4" as="h4" color={HEX_COLORS.blackberry}>
                    {t('messaging_add_instagram_tutorial_modale_title')}
                </Text>
                <StyledBodyText2 variant="bodyMRegular" as="span" color={'secondary'}>
                    {t('messaging_add_instagram_tutorial_modale_text')}
                </StyledBodyText2>
                <StyledCheckableBoxContainer>
                    <CheckableBar
                        title={t('messaging_add_instagram_tutorial_modale_step_1_title')}
                        subLinkText={t('messaging_add_instagram_tutorial_modale_step_1_link')}
                        subLinkURL={t('messaging_add_instagram_tutorial_modale_step_1_url')}
                        leftIcon={BusinessIcon}
                        toggleCheckbox={toggleInstagramAsBusinessAccount}
                        checked={businessAccountChecked}
                        dataTrack="instagram_self_onboarding_modale__step_1_tutorial_button"
                    />
                    <CheckableBar
                        title={t('messaging_add_instagram_tutorial_modale_step_2_title')}
                        subLinkText={t('messaging_add_instagram_tutorial_modale_step_2_link')}
                        subLinkURL={t('messaging_add_instagram_tutorial_modale_step_2_url')}
                        leftIcon={LinkIcon}
                        toggleCheckbox={toggleInstagramLinkedToFB}
                        checked={instagramLinkedToFBChecked}
                        disabled={!businessAccountChecked}
                        dataTrack="instagram_self_onboarding_modale__step_2_tutorial_button"
                    />
                </StyledCheckableBoxContainer>
            </StyledModalContent>
            <StyledButtonContainer>
                <Button
                    dataTrackId="messaging_add_instagram_tutorial_modale_login_button"
                    variant="primary"
                    full
                    size="large"
                    shape="cube"
                    onClick={onContinueClick}
                    disabled={!businessAccountChecked || !instagramLinkedToFBChecked}
                >
                    {t('messaging_add_instagram_tutorial_modale_login_button')}
                </Button>
            </StyledButtonContainer>
        </ModalContainer>
    );
};

interface InstagramAddedModalProps {
    show: boolean;
    onHideClick: () => void;
    onButtonClick: () => void;
}

export const InstagramAddedModal: FC<InstagramAddedModalProps> = ({
    show,
    onHideClick,
    onButtonClick,
}) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={show} closeModal={onHideClick}>
            <ModalContainer>
                <Background />
                <SuccessCheckIcon />
                <StyledModalContent style={{ textAlign: 'center' }}>
                    <Text variant="heading4" as="h4" color={HEX_COLORS.blackberry}>
                        {t('messaging_add_instagram_confirmation_modale_title')}
                    </Text>
                    <StyledBodyText variant="bodyMRegular" as="span" color={'secondary'}>
                        {t('messaging_add_instagram_confirmation_modale_text')}
                    </StyledBodyText>
                </StyledModalContent>
                <StyledButtonContainer>
                    <Button
                        dataTrackId="messaging_add_instagram_confirmation_modale_button"
                        shape="cube"
                        variant="primary"
                        full
                        size="large"
                        onClick={onButtonClick}
                    >
                        {t('messaging_add_instagram_confirmation_modale_button')}
                    </Button>
                </StyledButtonContainer>
            </ModalContainer>
        </Modal>
    );
};

export const InstagramFailureModal: FC<InstagramAddedModalProps> = ({
    show,
    onHideClick,
    onButtonClick,
}) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={show} closeModal={onHideClick}>
            <ModalContainer>
                <FailureIcon />
                <StyledFailureHeading variant="heading5" as="h5">
                    {t('messaging_add_instagram_failure_modale_title')}
                </StyledFailureHeading>
                <StyledFailureFooter>
                    <Button
                        dataTrackId="messaging_add_instagram_tutorial_modale_failure_url"
                        appearance="outlined"
                        shape="cube"
                        onClick={() =>
                            window.open(
                                t('messaging_add_instagram_tutorial_modale_failure_url'),
                                '_blank',
                            )
                        }
                    >
                        {t('messaging_add_instagram_failure_modale_tutorial_link_button')}
                    </Button>
                    <Button
                        dataTrackId="messaging_add_instagram_failure_modale_contact_button"
                        shape="cube"
                        onClick={onButtonClick}
                    >
                        {t('messaging_add_instagram_failure_modale_contact_button')}
                    </Button>
                </StyledFailureFooter>
            </ModalContainer>
        </Modal>
    );
};

const AddInstagramModal: FC<AddInstagramModalProps> = ({ show, onHideClick, onClose }) => {
    const [isFirstStep, setIsFirstStep] = useState<boolean>(true);

    const onPreviousClick = () => {
        setIsFirstStep(true);
    };

    const onContinueClick = () => {
        setIsFirstStep(false);
        dataLayer.pushDict('click_continue_instagram_self_onboarding_modale_step_1');
    };

    const handleHideClick = useCallback(() => {
        onHideClick();
        dataLayer.pushDict(
            `click_discard_instagram_self_onboarding_modale_step_${isFirstStep ? 1 : 2}`,
        );
    }, [onHideClick, dataLayer, isFirstStep]);

    const openOauthDialog = usePopupWindow(
        `${FACEBOOK_OAUTH2_START_PATH}?${queryString.stringify({
            state: 'messages,instagram',
        })}`,
        { onClose },
    );

    const handleContinueStep2 = () => {
        openOauthDialog();
        dataLayer.pushDict('click_continue_instagram_self_onboarding_modale_step_2');
    };

    return (
        <Modal isOpen={show} closeModal={handleHideClick}>
            {isFirstStep ? (
                <AddInstagramStepOne onCancel={handleHideClick} onButtonClick={onContinueClick} />
            ) : (
                <AddInstagramStepTwo
                    onContinueClick={handleContinueStep2}
                    onPreviousClick={onPreviousClick}
                />
            )}
        </Modal>
    );
};

export default AddInstagramModal;
