import { isValid, parse } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import {
    hasEndDateIsAfterStartDate,
    hasEndDateIsFuture,
    hasStartDateIsBefore1970,
} from '../utils/formValidity';

type PartialEventDatesError = {
    hasErrors: boolean;
    endDateIsAfterStartDateError: boolean;
    endDateIsFutureError: boolean;
    startDateIsBefore1970: boolean;
    endDateIsInvalid: boolean;
    startTimeError: boolean;
    endTimeError: boolean;
};
export const useHasPartialEventDatesErrors = (): PartialEventDatesError => {
    const { formState, watch } = useFormContext();

    const eventStartDate: Date = watch('event_start_date');
    const eventEndDate: Date = watch('event_end_date');
    const eventStartTime: string = watch('event_start_time');
    const eventEndTime: string = watch('event_end_time');

    const getTimeError = (time: string) => {
        return !time || !isValid(parse(time, 'HH:mm', new Date()));
    };

    const getDateErrors = () => {
        const errors: Record<string, boolean> = {};

        errors.startTimeIsInvalid = getTimeError(eventStartTime);
        errors.endTimeIsInvalid = getTimeError(eventEndTime);

        if (!errors.startTimeIsInvalid && !errors.endTimeIsInvalid) {
            if (!eventStartDate || !eventEndDate) {
                errors.required = true;
            }

            errors.startDateIsBefore1970 = hasStartDateIsBefore1970(eventStartDate, eventStartTime);
            errors.endDateIsAfterStartDate = !hasEndDateIsAfterStartDate(
                eventStartDate,
                eventStartTime,
                eventEndDate,
                eventEndTime,
            );
            errors.endDateIsFuture = !hasEndDateIsFuture(eventEndDate, eventEndTime);
        }
        return errors;
    };

    const errors = getDateErrors();

    const endDateIsInvalid = formState.errors.event_end_date?.type == 'required';
    const startDateIsBefore1970 = errors?.startDateIsBefore1970 ?? false;
    const endDateIsAfterStartDateError = errors?.endDateIsAfterStartDate ?? false;
    const endDateIsFutureError = errors?.endDateIsFuture ?? false;
    const startTimeError = errors?.startTimeIsInvalid ?? false;
    const endTimeError = errors?.endTimeIsInvalid ?? false;

    const hasErrors =
        startTimeError ||
        endTimeError ||
        endDateIsAfterStartDateError ||
        endDateIsFutureError ||
        startDateIsBefore1970 ||
        endDateIsInvalid;

    return {
        hasErrors,
        endDateIsAfterStartDateError,
        endDateIsFutureError,
        startDateIsBefore1970,
        endDateIsInvalid,
        startTimeError,
        endTimeError,
    };
};
