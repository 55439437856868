import { useTranslation } from 'react-i18next';

import { useStatsItems } from 'app/pages/Homepage/hooks/stats/useStatsItems';

import {
    HomepageStatsContainer,
    HomepageStatsGrid,
    HomepageStatsTitle,
} from './HomepageStats.styled';

export const HomepageStats = () => {
    const { t } = useTranslation();
    const statsItems = useStatsItems();
    const smallItems = statsItems.length === 3;

    if (!statsItems.length) {
        return null;
    }

    return (
        <HomepageStatsContainer>
            <HomepageStatsTitle variant="heading6">{t('homepage_stats_title')}</HomepageStatsTitle>

            <HomepageStatsGrid small={smallItems}>
                {statsItems.map(({ component: Component, key }) => (
                    <Component key={key} small={smallItems} />
                ))}
            </HomepageStatsGrid>
        </HomepageStatsContainer>
    );
};
