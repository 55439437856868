import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { FormattedUserData } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { ME, USERS } from 'app/common/data/queryKeysConstants';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { FORCE_GET_ME_SAGA } from 'app/common/sagas/getMeSaga';

export default function useMeUpdate(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const { data: me } = useMe();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    return useMutation((payload: FormattedUserData) => api.user.editUser(me?.id ?? '', payload), {
        onSuccess: () => {
            // Force the get me saga to update things like the language
            // Should be killed with fire once all pages don't use sagas anymore
            dispatch({ type: FORCE_GET_ME_SAGA });

            queryClient.invalidateQueries(invalidatesAny(ME, USERS)).then(onSuccess);
        },
        onError,
    });
}
