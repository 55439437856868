import { FC, useMemo } from 'react';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { Partner } from 'app/businessEditV2/hooks/useIsConnectedToPartner';
import useIsFacebookDisabled from 'app/businessEditV2/hooks/useIsFacebookDisabled';
import Loader from 'app/common/designSystem/components/atoms/svg/Loader';
import { SETTINGS_PLATFORMS_CONNECTION_PATHNAME } from 'app/routing/routeIds';

import CTAButton from './CTAButton';
import { CTAButtonsContainer } from './CTAButton.styled';

import GoogleButton from './GoogleButton';

const CTAButtons: FC = () => {
    const { data: business, isLoading: isLoadingBusiness } = useBusiness();
    const { data: facebookDisabled, isLoading: isLoadingFacebookDisabled } =
        useIsFacebookDisabled();

    const connectionsPageLink = useMemo(() => {
        return `${SETTINGS_PLATFORMS_CONNECTION_PATHNAME}?business_id=${business?.id}`;
    }, [business]);

    if (isLoadingBusiness || isLoadingFacebookDisabled) {
        return (
            <CTAButtonsContainer>
                <Loader />
            </CTAButtonsContainer>
        );
    }
    return (
        <CTAButtonsContainer>
            <GoogleButton connectionsPageLink={connectionsPageLink} key="google_button" />
            {!facebookDisabled && (
                <CTAButton
                    partner={Partner.Facebook}
                    connectionsPageLink={connectionsPageLink}
                    key="facebook_button"
                />
            )}
        </CTAButtonsContainer>
    );
};

export default CTAButtons;
