import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const StyledRating = styled.div`
    align-self: center;
    display: flex;
    grid-area: rating;
`;

export const StyledNoRating = styled.span`
    color: ${HEX_COLORS.ratings};
    grid-area: rating;
    flex: 1;
`;
