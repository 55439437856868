import { TFunction } from 'i18next';
import { useQuery } from 'react-query';

import { Choice, V2UserData } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import { USERS } from 'app/common/data/queryKeysConstants';

interface Options {
    enabled?: boolean;
    onSuccess?: (data: V2UserData) => void;
    onError?: (err: Record<string, any>) => void;
}

export default function useUser(userId: string, options?: Options) {
    return useQuery(
        [USERS, { userId }],
        () => api.user.getUser(userId),
        options,
    );
}

export function formatLanguageToChoice(lang: string, t: TFunction): Choice {
    return {
        label: t(lang),
        value: lang,
    };
}
