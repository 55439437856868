import styled from '@emotion/styled';

export const CountNumber = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 71px;
    letter-spacing: -1px;
    color: ${({ theme }) => theme.colors.default.initial};
`;
