import { useEffect, useMemo } from 'react';

import { Snackbar } from '@partoohub/ui';
import { Helmet } from 'react-helmet';

import QuickAction from 'app/addon/QuickAction';
import EnvBanner from 'app/common/components/EnvBanner';
import ReactPage from 'app/common/components/ReactPage.container';
import { useAppDataContext } from 'app/common/contexts/appData';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import IS_IFRAME from 'app/common/data/iframe';
import WHITELIST_PROVIDERS from 'app/common/data/providers';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import { useCanSendClevertapEvents } from 'app/common/hooks/queries/useCanSendClevertapEvents';
import { PushNotifsEvent } from 'app/common/services/pushNotifications/events';
import { PushNotificationsWrapper } from 'app/common/services/pushNotifications/pushNotificationsWrapper';
import { useHandlePostLoginScripts } from 'app/common/services/scripts/postLogin';
import { handleIdentifyUserOnLogin } from 'app/common/services/scripts/postLogin/handleIdentifyUserOnLogin';
import { STATIC_BASE_URL } from 'app/config';
import TCModal from 'app/pages/app/TCModal';
import { AppRoutes } from 'app/routing/App';

export const AppPage = () => {
    const canSendClevertapEvents = useCanSendClevertapEvents();
    const appData = useAppDataContext();

    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const { me } = appData;

    const {
        isLoading: isSubscriptionsLoading,
        hasBusinesses,
        subscribedToBM,
        subscribedToPM,
        subscribedToRB,
        subscribedToRM,
    } = useBusinessSubscriptions();
    const subscriptions = {
        has_business: hasBusinesses,
        subscribed_to_bm: subscribedToBM,
        subscribed_to_pm: subscribedToPM,
        subscribed_to_rb: subscribedToRB,
        subscribed_to_rm: subscribedToRM,
    };

    useHandlePostLoginScripts();

    // TODO Move this one in useHandlePostLoginScripts ?
    useEffect(() => {
        if (!isSubscriptionsLoading) handleIdentifyUserOnLogin(appData, subscriptions);
        if (canSendClevertapEvents) {
            PushNotificationsWrapper.getInstance().sendEvent(PushNotifsEvent.LAUNCH_WEB_APP);
        }
    }, [canSendClevertapEvents, isSubscriptionsLoading]);

    const faviconPath = useMemo(
        () =>
            me
                ? `${STATIC_BASE_URL}/images/providers_asset/${me.provider?.toLowerCase()}/${me.provider?.toLowerCase()}_favicon.png`
                : `${STATIC_BASE_URL}/images/providers_asset/partoo/partoo_favicon.png`,
        [me],
    );
    const appleTouchIconPath = useMemo(
        () =>
            me?.provider_name && WHITELIST_PROVIDERS.includes(me?.provider_name)
                ? `${STATIC_BASE_URL}/images/apple-touch-icon.png`
                : null,
        [me],
    );

    // ReactPage contains the SideBar and the app content once the user is logged in
    return (
        <ReactPage>
            <Helmet>
                <link rel="icon" type="image/png" href={faviconPath} />
                {appleTouchIconPath && <link rel="apple-touch-icon" href={appleTouchIconPath} />}
            </Helmet>
            {!IS_IFRAME && <TCModal />}
            {!isNewSidebarEnabled && <EnvBanner />}
            <AppRoutes />
            <Snackbar dataTrackId="app__snackbar" />
            {!IS_IFRAME && <QuickAction />}
        </ReactPage>
    );
};
