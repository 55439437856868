import { ReactNode } from 'react';

import { Stack, Text } from '@partoohub/ui';

import { IS_BELOW_MOBILE, useDeviceType } from 'app/hooks';

interface DefaultMenuListHeaderProps {
    title?: string;
    description?: string;
    actionsButton?: ReactNode[];
}

export const DefaultMenuListHeader = ({
    title,
    description,
    actionsButton,
}: DefaultMenuListHeaderProps) => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_MOBILE);
    return (
        <Stack
            gap="8px"
            direction={isBelowProvidedDevices ? 'column' : 'row'}
            alignItems={isBelowProvidedDevices ? 'flex-start' : 'center'}
            justifyContent="space-between"
            style={{ flex: 1 }}
        >
            <Stack gap="0">
                {title && (
                    <Text variant="heading6" as="span">
                        {title}
                    </Text>
                )}
                {description && (
                    <Text as="span" color="secondary">
                        {description}
                    </Text>
                )}
            </Stack>
            {actionsButton && (
                <Stack gap="16px" direction="row" alignItems="center">
                    {actionsButton}
                </Stack>
            )}
        </Stack>
    );
};
