import { isEmpty } from 'lodash-es';
import { connect } from 'react-redux';

import StatefulButtonComponent from 'app/businessConnection/components/modals/ModalCommonComponents/StatefulButton';
import { partnerToPartnerResourceName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import {
    DISABLED,
    ERROR,
    LOADING,
    SUCCESSFUL,
    TOSAVE,
} from 'app/common/components/buttons/StatefulButton';
import { businessByIdSelector } from 'app/common/reducers/newBusinesses';
import {
    businessImportSelector,
    connectedLocationIdSelector,
    connectionErrorSelector,
    connectionInProgressSelector,
    connectionSuccessSelector,
    selectedBusinessIdSelector,
    selectedPartnerSelector,
} from 'app/states/knowledge/reducers/businessConnection/connection';
import { AppState } from 'app/states/reducers';

const mapStateToProps = ({
    businessConnection: { page },
    businesses,
}: AppState) => {
    const connectionInProgress = connectionInProgressSelector(page);
    const connectionSuccess = connectionSuccessSelector(page);
    const connectedLocationId = connectedLocationIdSelector(page);
    const selectedBusinessId = selectedBusinessIdSelector(page);
    const partner = selectedPartnerSelector(page);
    const partnerResourceName = partnerToPartnerResourceName[partner];
    const business = selectedBusinessId
        ? businessByIdSelector(businesses, selectedBusinessId)
        : null;
    const hasPM = business
        ? business.subscriptions.presence_management.active
        : false;
    const hasError = !isEmpty(connectionErrorSelector(page));

    let label = '';
    let status = DISABLED;
    let preventClick = false;
    let saveIcon = '';

    if (connectionInProgress) {
        status = LOADING;
    } else if (connectionSuccess) {
        status = SUCCESSFUL;
        preventClick = true;
    } else if (hasError) {
        status = ERROR;
        preventClick = true;
    } else if (connectedLocationId) {
        // Checking if some import fields are checked in the right part
        const businessImport = businessImportSelector(page);

        if (
            Object.values(businessImport).includes(true) ||
            Object.values(businessImport.businessInfos).includes(true)
        ) {
            status = TOSAVE;
            saveIcon = 'fa fa-check';
        } else {
            status = DISABLED;
        }

        label = 'confirm';
    }

    return {
        label,
        status,
        saveIcon,
        preventClick,
        partnerResourceName,
        hasPM,
    };
};

const StatefulButton = connect(mapStateToProps)(StatefulButtonComponent);

export default StatefulButton;
