import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import {
    AverageRatingGraph,
    RatingDistributionGraph,
    ReplyMeansGraph,
    ReplyTimeGraph,
    ReviewCountGraph,
    TagsGraph,
} from './Graphs';
import { StyledGraphContainer, StyledGraphSection } from './GraphSection.styled';
import { useGraphSelection } from '../../hooks/useGraphSelection';

export const GraphSection = () => {
    const { t } = useTranslation();
    const [graph] = useGraphSelection();

    const GraphComponent = {
        average_rating: AverageRatingGraph,
        review_count: ReviewCountGraph,
        rating_distribution: RatingDistributionGraph,
        reply_means: ReplyMeansGraph,
        reply_time: ReplyTimeGraph,
        tags: TagsGraph,
    }[graph];

    return (
        <StyledGraphSection>
            <Text variant="heading6">{t(`review_analytics_graph_${graph}`)}</Text>
            <StyledGraphContainer>
                {GraphComponent ? <GraphComponent /> : undefined}
            </StyledGraphContainer>
        </StyledGraphSection>
    );
};
