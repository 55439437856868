import { PhotosData } from 'app/api/types/business';
import {
    AT_WORK_PHOTO,
    COMMON_AREAS_PHOTO,
    EXTERIOR_PHOTO,
    INTERIOR_PHOTO,
    LOGO_PHOTO,
    PRIMARY_PHOTO,
    PRODUCT_PHOTO,
    ROOMS_PHOTO,
    SECONDARY_PHOTO,
    TEAM_PHOTO,
} from 'app/businessEditV2/oldFromEditV1/data/photoConsts';
import { FileError } from 'app/common/designSystem/components/molecules/DropZone/helpers';

export const businessEditMaxPhotoSize = 10_000_000; // 10 MB
export const businessEditMinPhotoSize = 0;

export const getLabel = (category: string): string => {
    if (category === PRIMARY_PHOTO) {
        return 'primary_photo';
    }
    if (category === LOGO_PHOTO) {
        return 'logo';
    }
    return 'additional_photos';
};

export const addNewSecondaries = (
    photos: PhotosData,
    newSecondaries: Array<string>,
): PhotosData => ({
    ...photos,
    secondary: [...photos.secondary, ...newSecondaries],
});

export const handledPhotosArray: Array<string> = [
    SECONDARY_PHOTO,
    EXTERIOR_PHOTO,
    INTERIOR_PHOTO,
    PRODUCT_PHOTO,
    AT_WORK_PHOTO,
    COMMON_AREAS_PHOTO,
    ROOMS_PHOTO,
    TEAM_PHOTO,
];

export const deleteUrl = (photos: PhotosData, url: string): PhotosData => {
    const result = {
        ...photos,
        primary: photos.primary === url ? '' : photos.primary,
        LOGO: photos.LOGO === url ? '' : photos.LOGO,
    };

    handledPhotosArray.forEach(category => {
        result[category] =
            photos?.[category].filter(photo => photo !== url) ?? [];
    });

    return result;
};

export const setLogo = (photos: PhotosData, url: string): PhotosData => {
    const oldLogo = photos.LOGO;

    const result = {
        ...photos,
        primary: photos.primary === url ? '' : photos.primary,
        LOGO: url,
    };

    handledPhotosArray.forEach(category => {
        result[category] =
            photos?.[category].filter(photo => photo !== url) ?? [];
    });

    if (oldLogo) {
        result.secondary.push(oldLogo);
    }

    return result;
};

export const setCover = (photos: PhotosData, url: string): PhotosData => {
    const oldCover = photos.primary;

    const result = {
        ...photos,
        primary: url,
        LOGO: photos.LOGO === url ? '' : photos.LOGO,
    };

    handledPhotosArray.forEach(category => {
        result[category] =
            photos?.[category].filter(photo => photo !== url) ?? [];
    });

    if (oldCover) {
        result.secondary.push(oldCover);
    }

    return result;
};

type PhotoType = {
    category: string;
    url: string;
};

// Want to display specific categories in photos section, for now display menu only in menu section, display food and drink only in food menus
const getAllPhotos = (photos: PhotosData): Array<PhotoType> => {
    const result: Array<PhotoType> = [];
    if (photos.primary) {
        result.push({ category: PRIMARY_PHOTO, url: photos.primary });
    }
    if (photos.LOGO) {
        result.push({ category: LOGO_PHOTO, url: photos.LOGO });
    }
    handledPhotosArray.forEach(category => {
        result.push(
            ...(photos?.[category]?.map(url => ({ category, url })).reverse() ??
                []),
        );
    });
    return result.filter(
        ({ url }) => url && url !== 'PREFERRED_PHOTO_UNSPECIFIED',
    );
};

const getFirstPhotoError = (error): string => {
    if (!error) return '';
    const responseErrors = error?.response?.data?.errors?.json?.images;
    for (const errorMessage of Object.values(responseErrors))
        return errorMessage as string;
    return '';
};

export const getFirstPhotoUploadFileError = (error): FileError | undefined => {
    const errorMessage = getFirstPhotoError(error);
    if (errorMessage && errorMessage.startsWith('size exceeds')) {
        return { filename: '', errorType: 'too_big' };
    }
    if (errorMessage && errorMessage.startsWith('size under')) {
        return { filename: '', errorType: 'too_small' };
    }
    if (errorMessage === 'file has an invalid type') {
        return { filename: '', errorType: 'wrong_format' };
    }
};

export default getAllPhotos;
