import { snakeCase } from 'lodash-es';
import { delay } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import businessImportFormatter from 'app/api/formatters/request/businessImportFormatter';
import api from 'app/api/v2/api_calls';
import {
    partnerToPartnerName,
    partnerToPartnerResourceName,
} from 'app/businessConnection/services/PartnerToAccountsMethods';
import { setLocationId } from 'app/common/reducers/newBusinesses';
import { Saga } from 'app/common/types';
import {
    CONNECT_BUSINESS_TO_LOCATION,
    ConnectBusinessToLocationAction,
    PartnerConnectionState,
    businessImportSelector,
    connectBusinessToLocationFailure,
    connectBusinessToLocationSuccess,
    hideConnectionModal,
    resetConnectionModal,
    selectedBusinessIdSelector,
    selectedPartnerSelector,
} from 'app/states/knowledge/reducers/businessConnection/connection';
import {
    getBusinessPublisherStates,
    refreshBusinessPublisherStates,
} from 'app/states/presence/reducers/listing/businessDetails/actions';
import { connectionSelector } from 'app/states/reducers';

function* connectBusinessToLocationSaga({
    locationId,
    owningBusinessId,
}: ConnectBusinessToLocationAction): Saga {
    const connectionState = (yield select(connectionSelector) as any) as PartnerConnectionState;

    try {
        const businessId = selectedBusinessIdSelector(connectionState);
        const partnerName = partnerToPartnerName[selectedPartnerSelector(connectionState)];
        const partnerResourceName =
            partnerToPartnerResourceName[selectedPartnerSelector(connectionState)];

        // locationId is not defined for business already partner connected
        if (locationId && businessId) {
            yield call(
                api.businessModule.publisherConnection.connectBusinessToLocation,
                businessId,
                locationId,
                partnerResourceName,
                {
                    is_bulk_import: false,
                    is_quick_connect: false,
                },
            );
            // @ts-ignore
            yield put(setLocationId(businessId, locationId, partnerName));

            if (owningBusinessId && owningBusinessId !== businessId) {
                // @ts-ignore
                yield put(unsetLocationId(owningBusinessId, partnerName));
            }
        }

        if (partnerName === 'google') {
            const businessImportFormattedData = businessImportFormatter(
                businessImportSelector(connectionState),
                false,
                false,
            );
            yield call(api.business.importGMBInfos, businessId, businessImportFormattedData);
        }

        yield put(connectBusinessToLocationSuccess());
        yield call(delay, 1000);
        yield put(hideConnectionModal());
        yield put(resetConnectionModal());

        if (window.location.pathname.includes('presence_management')) {
            const publisher = snakeCase(selectedPartnerSelector(connectionState));

            yield put(refreshBusinessPublisherStates(publisher));
            yield put(getBusinessPublisherStates(publisher, 'success', 1));
            yield put(getBusinessPublisherStates(publisher, 'pending', 1));
            yield put(getBusinessPublisherStates(publisher, 'to_configure', 1));
        }
    } catch (error) {
        yield put(connectBusinessToLocationFailure(locationId || ''));
    }
}

function* partnerConnectionConnectBusinessToLocationSaga(): Saga {
    yield takeEvery(CONNECT_BUSINESS_TO_LOCATION, connectBusinessToLocationSaga);
}

export default partnerConnectionConnectBusinessToLocationSaga;
