const SCRIPT_ID = 'liveroom';

import env from 'app/common/services/getEnvironment';

export const handleLiveroom = () => {
    return new Promise((resolve, reject) => {
        if (env.isSandbox()) {
            const script = document.createElement('script');
            script.id = SCRIPT_ID;
            script.type = 'module';
            script.src =
                'https://cdn.jsdelivr.net/npm/liveroom-client-element@latest/dist/main.min.js';

            document.body.appendChild(script);
            script.onload = () => {
                // eslint-disable-next-line no-console
                resolve('[Liveroom] Script loaded successfully');
            };

            script.onerror = () => {
                reject(new Error('[Liveroom] Error loading the script'));
            };
        }
        resolve('');
    });
};

export const removeLiveroomScript = () => {
    const script = document.getElementById(SCRIPT_ID);
    if (script) {
        script.remove();
    }
};
