import { useContext, useEffect, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { Checkbox, IconPrefix, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { PUBLISHERS } from 'app/businessConnection/components/genericQuickConnect/publishers';

import { OpenedModalEnum } from 'app/businessConnection/components/genericQuickConnect/QuickConnect';
import QuickConnectContext from 'app/businessConnection/components/genericQuickConnect/QuickConnect.context';
import {
    LoadingElement,
    LoadingStateExplanation,
    LoadingStateIndicator,
    QuickConnectModalLoading,
    QuickConnectModalPlaceholder,
    QuickConnectModalSuggestions,
} from 'app/businessConnection/components/genericQuickConnect/SuggestionSection/SuggestionTable.styled';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import SuggestionRow from './SuggestionRow';

interface Props {
    isLoading: boolean;
    isSubmitting: boolean;
    suggestions: Array<Record<string, any>>;
    failedConnections: Array<string>;
}

const getCheckValue = (selectedLength: number, suggestedLength: number) => {
    if (selectedLength === 0) return false;
    if (selectedLength < suggestedLength) return 'indeterminate';
    return true;
};

const SuggestionsTable = ({ isLoading, isSubmitting, suggestions, failedConnections }: Props) => {
    const { t } = useTranslation();
    const { publisher, setPublisher, selectedSuggestions, setSelectedSuggestions, setShow } =
        useContext(QuickConnectContext);
    const [, setShowModal] = useStateQueryParams('modal');
    const [publisherUrl] = useStateQueryParams('publisher');
    const [check, setCheck] = useState<boolean | 'indeterminate'>(false);

    useEffect(() => {
        setPublisher(publisherUrl);
    }, [publisherUrl]);

    useEffect(() => {
        setCheck(getCheckValue(selectedSuggestions.length, suggestions.length));
    }, [selectedSuggestions, suggestions]);

    const pubTexts = PUBLISHERS[publisher].texts;

    const isSuggestionSelected = (id: string) =>
        selectedSuggestions.find(
            (item: Record<string, any>) => `${item.businessInfo.id}_${item.locationInfo.id}` === id,
        );

    const handleToggleCheck = () => {
        setCheck(!check);
    };
    const editCheck = () => {
        handleToggleCheck();
        if (check === false || check === 'indeterminate') {
            setSelectedSuggestions(suggestions);
        } else {
            setSelectedSuggestions([]);
        }
    };

    const editSuggestionsSelected = (key: string, obj: Record<string, any>) => {
        let indeterminateCheck: boolean | 'indeterminate' = 'indeterminate';

        if (
            selectedSuggestions.find(
                (item: Record<string, any>) =>
                    `${item.businessInfo.id}_${item.locationInfo.id}` === key,
            )
        ) {
            const newList = selectedSuggestions.filter(
                item => `${item.businessInfo.id}_${item.locationInfo.id}` !== key,
            );
            if (newList.length === 0) indeterminateCheck = false;
            setSelectedSuggestions(newList);
        } else {
            if (selectedSuggestions.length + 1 === suggestions.length) indeterminateCheck = true;
            setSelectedSuggestions([...selectedSuggestions, obj]);
        }

        setCheck(indeterminateCheck);
    };

    if (isLoading) {
        return (
            <QuickConnectModalLoading>
                <LoadingStateIndicator>
                    <LoadingElement />
                </LoadingStateIndicator>
                <LoadingStateExplanation>
                    {t(pubTexts.loadingSuggestionsMessage)}
                </LoadingStateExplanation>
            </QuickConnectModalLoading>
        );
    }

    if (suggestions.length === 0) {
        const handleClick = () => {
            setShow(false);
            setShowModal(OpenedModalEnum.NONE);
        };

        const illustrationElement: IllustrationLaptopElement = {
            type: IllustrationCategoryEnum.Laptop,
            name: IllustrationLaptopEnum.VeryHappySuccess,
        };

        return (
            <QuickConnectModalPlaceholder>
                <NotFoundTemplate
                    show
                    title={t('quick_connect_placeholder_title')}
                    subtitle={t('quick_connect_placeholder_subtitle')}
                    imgSrc={illustrationElement}
                    buttonText={t('quick_connect_placeholder_button')}
                    icon={['fa-arrow-alt-circle-left', IconPrefix.SOLID]}
                    handleClick={handleClick}
                />
            </QuickConnectModalPlaceholder>
        );
    }

    return (
        <QuickConnectModalSuggestions>
            <div className="suggestions_description">
                <div className="suggestions_description_text">{t('select_your_matchings')}</div>
                <Stack gap="8px" direction="row">
                    <Text
                        variant="bodyMSemibold"
                        as="span"
                        color="secondary"
                    >{`${selectedSuggestions.length} / ${suggestions.length}`}</Text>
                    <Checkbox
                        dataTrackId="suggestion_table__checkbox"
                        aria-label=""
                        checked={check}
                        onChange={editCheck}
                    />
                </Stack>
            </div>
            <div className="suggestions_header">
                <div className="flex--fill">{t('locations')}</div>
                <span />
                <div className="flex--fill">{t(pubTexts.importSectionTitle)}</div>
            </div>
            <div className="suggestions_list">
                {suggestions.map(({ businessInfo, locationInfo }) => {
                    const key = `${businessInfo.id}_${locationInfo.id}`;
                    const suggestionSelected = isSuggestionSelected(key);
                    return (
                        <SuggestionRow
                            key={key}
                            businessInfo={businessInfo}
                            locationInfo={locationInfo}
                            isSelected={suggestionSelected}
                            onClick={(elem: Record<string, any>) =>
                                !isSubmitting && editSuggestionsSelected(key, elem)
                            }
                            isLoading={isSubmitting && suggestionSelected}
                            hasError={failedConnections.includes(key)}
                        />
                    );
                })}
            </div>
        </QuickConnectModalSuggestions>
    );
};

export default SuggestionsTable;
