import React from 'react';

const NegativeIcon = () => (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.22925 9.8125C4.10425 9.40625 4.448 9 4.8855 9H11.5417C11.9792 9 12.323 9.40625 12.198 9.8125C11.6667 11.5312 10.1042 12.75 8.22925 12.75C6.35425 12.75 4.7605 11.5312 4.22925 9.8125ZM6.66675 6C6.66675 6.5625 6.22925 7 5.66675 7C5.10425 7 4.66675 6.5625 4.66675 6C4.66675 5.46875 5.10425 5 5.66675 5C6.22925 5 6.66675 5.46875 6.66675 6ZM9.66675 6C9.66675 5.46875 10.1042 5 10.6667 5C11.2292 5 11.6667 5.46875 11.6667 6C11.6667 6.5625 11.2292 7 10.6667 7C10.1042 7 9.66675 6.5625 9.66675 6ZM16.1667 8C16.1667 12.4375 12.573 16 8.16675 16C3.72925 16 0.166748 12.4375 0.166748 8C0.166748 3.59375 3.72925 0 8.16675 0C12.573 0 16.1667 3.59375 16.1667 8ZM8.16675 1.5C4.573 1.5 1.66675 4.4375 1.66675 8C1.66675 11.5938 4.573 14.5 8.16675 14.5C11.7292 14.5 14.6667 11.5938 14.6667 8C14.6667 4.4375 11.7292 1.5 8.16675 1.5Z"
            fill="#40DBA2"
        />
    </svg>
);

export default NegativeIcon;
