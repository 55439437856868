import { useTranslation } from 'react-i18next';

import { Navigate } from 'react-router-dom';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import LoadingView from 'app/common/components/LoadingView';
import ReviewBooster from 'app/pages/conversations/ReviewBooster/components/ReviewBooster';
import {
    CEPages,
    useCanAccessCustomerExperiencePage,
} from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

export const ReviewBoosterPage = () => {
    const { t } = useTranslation();

    const {
        canAccessCustomerExperiencePage: { [CEPages.RB]: hasAccess },
        isLoading: accessLoading,
    } = useCanAccessCustomerExperiencePage();

    if (accessLoading) {
        return <LoadingView />;
    }

    return (
        <>
            <DynamicPageHeader title={t('review_booster')} />
            {hasAccess ? <ReviewBooster /> : <Navigate replace to={NOT_FOUND_PATH} />}
        </>
    );
};
