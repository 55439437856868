import { RangeDate } from '@partoohub/ui';
import { endOfDay, startOfDay } from 'date-fns';

import * as constants from './constants';
import * as types from './types';

export const setDatesFilterAction = (dates: RangeDate): types.SetDateFilterType => ({
    type: constants.SET_DATE_FILTER,
    from: dates?.[0] ? startOfDay(dates?.[0]) : dates?.[0],
    to: dates?.[1] ? endOfDay(dates?.[1]) : dates?.[1],
});

export const setInputDateFilterAction = (
    input: 'startDate' | 'endDate' | null,
): types.SetInputDateFilterType => ({
    type: constants.SET_INPUT_DATE_FILTER,
    input,
});

export const applyFiltersAction = (): types.ApplyFiltersType => ({
    type: constants.APPLY_FILTERS,
});

export const resetPendingFilterAction = (
    name: constants.AllFilters,
): types.ResetPendingFilterType => ({
    type: constants.RESET_PENDING_FILTER,
    name,
});

export const resetReviewFilterAction = (
    name: constants.AllFilters,
): types.ResetReviewFilterType => ({
    type: constants.RESET_REVIEW_FILTER,
    name,
});

export const resetReviewToolbarFiltersAction = (): types.ResetReviewToolbarFiltersType => ({
    type: constants.RESET_REVIEW_TOOLBAR_FILTERS,
});

export const hideReviewMobileFiltersAction = (): types.HideReviewMobileFiltersType => ({
    type: constants.HIDE_REVIEW_MOBILE_FILTERS,
});

export const reInitReviewFilters = (): types.ReInitReviewFilters => ({
    type: constants.RE_INIT_REVIEW_FILTERS,
});
