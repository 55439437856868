import React, { useEffect, useState } from 'react';

import useCompletionRate from 'app/businessEditV2/hooks/business/useCompletionRate';

import { PercentageValue } from './BusinessPercentageValue.styled';

type Props = {
    color: string;
};

const BusinessPercentageValue = ({ color }: Props) => {
    const DURATION_TIMEOUT = 1000;
    const completionRate = useCompletionRate();
    const [displayedCompletionRate, setDisplayedCompletionRate] =
        useState<number>(0);

    useEffect(() => {
        const animate = () => {
            if (displayedCompletionRate === completionRate) return;
            let current = displayedCompletionRate;
            const increment = completionRate > displayedCompletionRate ? 1 : -1;
            const range = Math.abs(
                (displayedCompletionRate - completionRate) * 5,
            );
            const stepTime = Math.abs(Math.floor(DURATION_TIMEOUT / range));
            const timer = setTimeout(() => {
                current += increment;
                setDisplayedCompletionRate(current);
                if (current === completionRate) {
                    clearTimeout(timer);
                }
            }, stepTime);
        };
        if (displayedCompletionRate !== completionRate) {
            animate();
        }
    }, [displayedCompletionRate, completionRate]);

    return (
        <PercentageValue color={color}>
            {displayedCompletionRate}%
        </PercentageValue>
    );
};
export default BusinessPercentageValue;
