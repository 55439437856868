import { useCallback } from 'react';

import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { BannerContainer } from './BannerSection.styled';

export const BannerSection = () => {
    const { t } = useTranslation();
    const showInfoBanner = useCallback((): boolean => {
        return new Date().getDate() <= 10;
    }, []);

    return (
        <>
            {showInfoBanner() && (
                <BannerContainer>
                    <Banner
                        dataTrackId="analytics_keyword_fetch_banner"
                        variant="info"
                        badge={t('info_term')}
                        hideIcon
                        withCloseIcon={false}
                    >
                        {t('analytics_keyword_fetch_bewteen_five_and_ten_content')}
                    </Banner>
                </BannerContainer>
            )}
        </>
    );
};
