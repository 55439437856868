import { UseQueryOptions, useQuery } from 'react-query';

import { CustomFieldsSectionsGetData } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import {
    CUSTOM_FIELDS,
    CUSTOM_FIELDS_SECTION,
    ORG_BUSINESS_EDIT_FIELDS,
} from 'app/common/data/queryKeysConstants';

export default function useCustomFieldsSections(
    orgId?: number,
    options: UseQueryOptions<CustomFieldsSectionsGetData> = {},
) {
    return useQuery(
        [ORG_BUSINESS_EDIT_FIELDS, CUSTOM_FIELDS, CUSTOM_FIELDS_SECTION, { orgId }],
        () => api.customFieldsSections.getCustomFieldsSections(orgId),
        options,
    );
}
