import { useCallback, useMemo, useState } from 'react';

import {
    Button,
    CustomFile,
    Dropzone,
    DropzoneTypeEnum,
    Icon,
    IconPrefix,
    Stack,
    Text,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import FunnelModal from 'app/common/components/FunnelModal';
import { useAppDataContext } from 'app/common/contexts/appData';

import {
    StyledBoxIcon,
    StyledFunnelModalBody,
    StyledFunnelModalFooter,
    StyledFunnelModalHeader,
    StyledRowsContainer,
} from './BulkReplyTemplateModal.styled';
import {
    useDownloadTemplatesMutation,
    useUploadTemplatesMutation,
} from '../../hooks/useFileActions';
import { BulkReplyTemplateRow } from '../BulkReplyTemplateRow/BulkReplyTemplateRow';
import { ExportModal } from '../Modal/ExportModal';
import { ImportModal } from '../Modal/ImportModal';
import { InstructionModal } from '../Modal/InstructionModal';

type ModalType = 'export' | 'import' | 'instruction' | null;

type Props = {
    isOpen: boolean;
    onClose: () => void;
    setEditTemplate: (arg0: string) => void;
};

export const BulkReplyTemplateModal = ({ isOpen, onClose, setEditTemplate }: Props) => {
    const MAX_SIZE = 5 * 1000000;

    const ACCEPTED_EXTENSIONS = ['.xlsx'];
    const ACCEPTED_FILE_TYPES = {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ACCEPTED_EXTENSIONS,
    };

    const acceptedFileExtensions = ACCEPTED_EXTENSIONS.join(' ').replaceAll('.', '').toUpperCase();

    const { me } = useAppDataContext();
    const myEmail = me?.email;

    const { t } = useTranslation();

    const [openModal, setOpenModal] = useState<ModalType>(null);
    const [files, setFiles] = useState<CustomFile[]>([]);
    const [email, setEmail] = useState(myEmail);

    const isFormValid = files.length && email;

    const { mutate: downloadMutate } = useDownloadTemplatesMutation();
    const {
        mutate: uploadMutate,
        isLoading: uploadIsLoading,
        isSuccess: uploadIsSuccess,
    } = useUploadTemplatesMutation();

    const resetModal = () => {
        setOpenModal(null);
        setEmail(myEmail);
    };
    const handleConfirmExportModal = useCallback(() => {
        resetModal();
        downloadMutate(email);
    }, [downloadMutate, email]);

    const handleConfirmImportModal = useCallback(() => {
        if (isFormValid) uploadMutate({ file: files[0], email });
        setFiles([]);
        resetModal();
        if (!uploadIsLoading && uploadIsSuccess) setEditTemplate('false');
    }, [isFormValid, uploadMutate, files, email, setEditTemplate]);

    const handleProcessFile = () => setOpenModal('import');
    const handleCloseModal = () => setOpenModal(null);

    const handleDropFileSuccess = (files: CustomFile[]) => setFiles(files);
    const handleClearFile = () => setFiles([]);

    const acceptedDescription = useMemo(
        () => (
            <Stack gap="8px" alignItems="center">
                <Text as="span" variant="bodyMRegular" color="secondary">
                    <strong>{t('drop_zone_file_accepted')}</strong> {acceptedFileExtensions}
                </Text>
                <Button variant="secondary" appearance="outlined" size="small" dataTrackId="upload">
                    {t('drop_zone_choose_file_button')}
                </Button>
            </Stack>
        ),
        [t, acceptedFileExtensions],
    );

    return (
        <FunnelModal isOpen={isOpen} onClose={onClose}>
            <StyledFunnelModalHeader>
                <StyledBoxIcon>
                    <Icon
                        icon={['fa-pen-clip', IconPrefix.REGULAR]}
                        color="primary"
                        iconSize="12px"
                    />
                </StyledBoxIcon>
                <Text variant="heading5" color="blackberry">
                    {t('bulk_update_page_title')}
                </Text>
            </StyledFunnelModalHeader>
            <StyledFunnelModalBody>
                <StyledRowsContainer>
                    <BulkReplyTemplateRow
                        title={`1. ${t('bulk_update_export_title')}`}
                        subTitle={t('bulk_update_export_subtitle_text')}
                        button={
                            <Button
                                dataTrackId="bulk_export_data_button"
                                icon={['fa-download', IconPrefix.REGULAR]}
                                onClick={() => setOpenModal('export')}
                            >
                                {t('bulk_export_data')}
                            </Button>
                        }
                    />
                    <BulkReplyTemplateRow
                        title={`2. ${t('bulk_update_import_title')}`}
                        subTitle={t('bulk_update_import_subtitle_text')}
                        button={
                            <Button
                                dataTrackId="bulk_show_instruction_button"
                                appearance="text"
                                icon={['fa-info-circle', IconPrefix.REGULAR]}
                                onClick={() => setOpenModal('instruction')}
                            >
                                {t('show_instructions')}
                            </Button>
                        }
                    />
                </StyledRowsContainer>
                <Dropzone
                    files={files}
                    multiple={false}
                    type={DropzoneTypeEnum.Document}
                    maxSize={MAX_SIZE}
                    onDropSuccess={handleDropFileSuccess}
                    onDelete={handleClearFile}
                    title={t('drop_zone_drap_drop_title')}
                    accept={ACCEPTED_FILE_TYPES}
                    acceptedDescription={acceptedDescription}
                />
            </StyledFunnelModalBody>
            <StyledFunnelModalFooter>
                <Button
                    dataTrackId="bulk_import_data_button"
                    full
                    onClick={handleProcessFile}
                    disabled={uploadIsLoading || files.length === 0}
                    size="large"
                    isLoading={uploadIsLoading}
                >
                    {uploadIsLoading
                        ? t('import_reply_template_data_update_button')
                        : t('process_file')}
                </Button>
            </StyledFunnelModalFooter>
            {openModal === 'export' && (
                <ExportModal
                    isOpenConfirmModal={true}
                    onConfirm={handleConfirmExportModal}
                    onHide={resetModal}
                    email={email}
                    setEmail={setEmail}
                />
            )}
            {openModal === 'import' && (
                <ImportModal
                    isOpenConfirmModal={true}
                    onConfirm={handleConfirmImportModal}
                    onHide={resetModal}
                    email={email}
                    setEmail={setEmail}
                />
            )}
            {openModal === 'instruction' && (
                <InstructionModal isOpen={true} onClose={handleCloseModal} />
            )}
        </FunnelModal>
    );
};
