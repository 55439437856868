import { useState } from 'react';

import { Button, FontAwesomeIconsPartooUsed, Stack, Text, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';

import { BulkExportBusinessClientFilter } from 'app/api/v2/api_calls/bulkExportApiCalls';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import useSelectedBusinessesTitle from 'app/common/components/businessModal/hooks/useSelectedBusinessesTitle';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { EmailInput } from 'app/common/components/form/EmailInput';
import FormRow from 'app/common/designSystem/components/templates/FormRow';

import i18n from 'app/common/translations/i18n';

import { BusinessesSelect } from './filters/businesses/BusinessesSelect';
import { SectionsSelect } from './filters/sections/SectionsSelect';
import { InstructionModal } from './InstructionModal';
import { SectionHeader, SectionSubTitle } from '../BulkUpdateForm/BulkUpdatedForm.styled';

type Props = {
    email: string;
};

export const ExportData = ({ email }: Props) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [emailToSend, setEmailToSend] = useState<string>(email);
    const [sections, setSections] = useState<Array<string>>([]);

    const { title } = useSelectedBusinessesTitle();
    const filters = useBusinessModalFilters();
    const count = useSelectedBusinessesCount() ?? 0;

    const queryParams = {
        ...filters,
        sections__in: sections.length ? sections.join(',') : null,
        email: emailToSend,
    };

    const mutation = useMutation(
        (myQueryParams: BulkExportBusinessClientFilter) =>
            api.bulkExportClient.bulkBusinessExport(myQueryParams),
        {
            onSuccess: () => {
                toast.loading(
                    i18n.t('hours_update_progress_export_progress_report'),
                    i18n.t('hours_update_progress_export_progress'),
                );
            },
            onError: (error: Error) => {
                toast.error(error.message, i18n.t('request_issue'));
            },
        },
    );

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleOpenModal = () => setIsModalOpen(true);

    return (
        <Stack gap="24px">
            <SectionHeader>
                <Stack gap="0">
                    <Text variant="heading6">{t('bulk_update_export_title')}</Text>
                    <SectionSubTitle>{t('bulk_update_export_subtitle')}</SectionSubTitle>
                </Stack>

                <Button
                    dataTrackId=""
                    variant="primary"
                    appearance="text"
                    onClick={handleOpenModal}
                    icon={[FontAwesomeIconsPartooUsed.faCircleInfo]}
                >
                    {t('bulk_update_export_instructions')}
                </Button>
            </SectionHeader>

            <Stack gap="24px">
                <FormRow>
                    <BusinessesSelect label={title} placeholder={t('select_businesses')} />
                    <SectionsSelect
                        sections={sections}
                        setSections={setSections}
                        businessCount={count}
                    />
                </FormRow>
                <FormRow>
                    <Button
                        dataTrackId="bulk_export_data__button"
                        onClick={toggleModal}
                        size="large"
                        shape="cube"
                        variant="primary"
                        disabled={mutation.isLoading}
                        isLoading={mutation.isLoading}
                        full
                        icon={[FontAwesomeIconsPartooUsed.faDownload]}
                        iconPosition="left"
                    >
                        {t('download_data')}
                    </Button>
                </FormRow>
            </Stack>

            <ConfirmModal
                show={showModal}
                title={t('bulk_export_report')}
                content={t('export_sent_to_this_email')}
                confirmLabel={t('send_email')}
                onConfirm={() => {
                    mutation.mutate(queryParams);
                    toggleModal();
                }}
                onHide={toggleModal}
                trackId="bulk_export_data__modal"
                modalFor="download"
            >
                <EmailInput
                    value={emailToSend}
                    onChange={setEmailToSend}
                    label={t('email_input_placeholder')}
                    required
                />
            </ConfirmModal>

            <InstructionModal show={isModalOpen} handleClose={() => setIsModalOpen(false)} />
        </Stack>
    );
};
