import { useMemo } from 'react';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    ALL_GOOGLE_POSTS,
    ERROR_GOOGLE_POSTS,
    EXPIRED_POSTS,
    LIVE_POSTS,
    PENDING_GOOGLE_POSTS,
    PUBLISHED_GOOGLE_POSTS,
    TO_CONFIGURE_POSTS,
} from 'app/presence/googlePosts/variables/googlePostStatus';

export const STATUS_FILTER_PARAM = 'status';

const formatGooglePostState = {
    [ALL_GOOGLE_POSTS]: undefined,
    [PUBLISHED_GOOGLE_POSTS]: LIVE_POSTS,
    [TO_CONFIGURE_POSTS]: TO_CONFIGURE_POSTS,
    [ERROR_GOOGLE_POSTS]: ERROR_GOOGLE_POSTS,
    [PENDING_GOOGLE_POSTS]: ['pending', 'scheduled'],
    [EXPIRED_POSTS]: EXPIRED_POSTS,
};

export default function useStatusFilter() {
    const [value, setValue] = useStateQueryParams(STATUS_FILTER_PARAM);

    return useMemo(() => {
        const parsedValue = value
            ? value
                  .split(',')
                  .filter(v => formatGooglePostState[v])
                  .reduce((obj: Record<string, boolean>, v: string) => {
                      obj[v] = true;
                      return obj;
                  }, {})
            : {};

        const setSearchParameter = (value: Record<string, boolean>) => {
            setValue(
                Object.keys(value)
                    .filter(v => formatGooglePostState[v] && value[v])
                    .toString(),
            );
        };

        return [
            parsedValue,
            setSearchParameter,
            value && value !== ALL_GOOGLE_POSTS
                ? {
                      state__in: value
                          .split(',')
                          .filter(s => formatGooglePostState[s])
                          .map(s => formatGooglePostState[s])
                          .toString(),
                  }
                : {},
        ];
    }, [value]);
}
