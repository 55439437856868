import styled from '@emotion/styled';

type EvolutionColor = {
    color: string;
};

export const DeltaContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const DeltaContent = styled.div`
    cursor: pointer;
`;

export const DeltaIcon = styled.span<EvolutionColor>`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.color};
    margin-right: 5px;
`;

export const DeltaNumber = styled.span<EvolutionColor>`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.color};
    margin-right: 5px;
`;
