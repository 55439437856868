import {
    DndContext,
    DragOverlay,
    DropAnimation,
    PointerSensor,
    defaultDropAnimation,
    pointerWithin,
    useDroppable,
    useSensor,
    useSensors,
} from '@dnd-kit/core';

import { SortableContext } from '@dnd-kit/sortable';

import useCustomFieldDnDLogic, {
    CF_LIST_TYPE,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useCustomFieldDnDLogic';
import { CustomFieldsSectionsListItem } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/sections/CustomFieldsContent/CustomFieldsList/CustomFieldsSectionsListItem/CustomFieldsSectionsListItem';

import { CUSTOM_FIELD_TYPE } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/typing';

import { CustomFieldsListDroppable, FieldDraggable } from './CustomFieldsList.styled';
import CustomFieldsListItem from './CustomFieldsListItem';

export const CustomFieldsList = () => {
    // DND
    const sensors = useSensors(useSensor(PointerSensor));

    const {
        customFieldsToDisplay,
        sectionsOpened,
        setSectionOpened,

        handleDragStart,
        handleDragOver,
        handleDragMove,
        handleDragEnd,

        dragOverlay,
    } = useCustomFieldDnDLogic();

    const { setNodeRef } = useDroppable({
        id: 'droppableList',
    });

    const dropAnimation: DropAnimation = {
        ...defaultDropAnimation,
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={pointerWithin}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragMove={handleDragMove}
            onDragEnd={handleDragEnd}
        >
            <SortableContext id={CF_LIST_TYPE} items={customFieldsToDisplay.map(cf => cf.dndId)}>
                <CustomFieldsListDroppable ref={setNodeRef}>
                    {customFieldsToDisplay.map(data => (
                        <FieldDraggable key={data.dndId}>
                            {data.type === CUSTOM_FIELD_TYPE ? (
                                <CustomFieldsListItem customField={data.obj} />
                            ) : (
                                <CustomFieldsSectionsListItem
                                    section={data.obj}
                                    customFields={data.customFields}
                                    isOpened={sectionsOpened[data.obj.id]}
                                    setIsOpened={opened => setSectionOpened(data.obj.id, opened)}
                                />
                            )}
                        </FieldDraggable>
                    ))}
                </CustomFieldsListDroppable>
            </SortableContext>

            <DragOverlay dropAnimation={dropAnimation}>{dragOverlay}</DragOverlay>
        </DndContext>
    );
};
