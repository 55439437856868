import { useMutation, useQueryClient } from 'react-query';

import { BusinessAttributesPostParamsType } from 'app/api/types/attributes';
import api from 'app/api/v2/api_calls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { BUSINESS } from 'app/common/data/queryKeysConstants';

export default function useAttributesUpdate(
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
    ignoreInvalidateQueries = false,
) {
    const businessId = useGetBusinessId();
    const queryClient = useQueryClient();
    return useMutation(
        (payload: BusinessAttributesPostParamsType) =>
            api.business.businessAttributesPost(businessId, payload),
        {
            onSuccess: () => {
                if (ignoreInvalidateQueries) {
                    return onSuccess();
                }
                queryClient
                    .invalidateQueries(invalidatesAny(BUSINESS))
                    .then(onSuccess);
            },
            onError,
        },
    );
}
