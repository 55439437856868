import { get, mergeWith } from 'lodash-es';

import { APPLY_FILTER_BY_BUSINESS_FILTERS } from 'app/common/components/businessModalDeprecated/reducers';
import { concatArray } from 'app/states/presence/reducers/listing/service';

import {
    GET_BUSINESS_BY_PUBLISHER_STATES_EMPTIED,
    GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST,
    GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST_FAILURE,
    GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST_SUCCESS,
    PublisherStatesAction,
    REFRESH_BUSINESS_BY_PUBLISHER_STATE,
} from './actions';

// TYPE
export type FormattedPublisherBusinessDetails = {
    businessId: string;
    businessName: string;
    businessFormattedAddress: string;
    errorType?: string;
    businessUrl?: string;
};

export type BusinessesByPublisher = {
    ids: [string];
    byId: Record<string, FormattedPublisherBusinessDetails>;
    maxPage: number;
    page: number;
    isFetching: boolean;
    error: string;
};

export type PublisherBusinessesState = {
    // @ts-ignore
    oneBusinessInfo?: boolean;
    [key: string]: BusinessesByPublisher;
};

// INITIAL STATE
const initialState = {};

// REDUCER
const PublisherStatesReducer = (
    state: PublisherBusinessesState = initialState,
    action: PublisherStatesAction,
): PublisherBusinessesState => {
    switch (action.type) {
        case GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST: {
            const updatedData: any = get(
                state,
                `[${action.publisherName}][${action.state}]`,
                {},
            );
            updatedData.isFetching = true;
            return mergeWith({}, state, {
                [action.publisherName]: {
                    [action.state]: { ...updatedData },
                },
            });
        }

        case REFRESH_BUSINESS_BY_PUBLISHER_STATE: {
            // @ts-ignore
            return { ...state, [action.publisherName]: {} };
        }

        case GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST_FAILURE:
        case GET_BUSINESS_BY_PUBLISHER_STATES_REQUEST_SUCCESS: {
            const actionWithoutType: any = { ...action };
            delete actionWithoutType.type;
            return mergeWith({}, state, actionWithoutType, concatArray);
        }

        case GET_BUSINESS_BY_PUBLISHER_STATES_EMPTIED:
            return {};

        case APPLY_FILTER_BY_BUSINESS_FILTERS:
            if (state.oneBusinessInfo) {
                return {};
            }

            return state;

        default:
            return state;
    }
};

export default PublisherStatesReducer;
