import React from 'react';

import { useTranslation } from 'react-i18next';

import useBusinessProblemNumber from 'app/businessEditV2/hooks/editV2utils/useBusinessProblemNumber';
import Clickable from 'app/common/components/buttons/Clickable';

import {
    BusinessStatusDescriptionContainer,
    Description,
    Title,
    WarningMessage,
} from './BusinessStatusDescription.styled';

type Props = {
    onClick: () => void;
};

const BusinessStatusDescription = ({ onClick }: Props) => {
    const problemNumber = useBusinessProblemNumber();
    const { t } = useTranslation();

    return (
        <BusinessStatusDescriptionContainer>
            <Title>{t('business_edit_business_status_title')}</Title>
            <Description>
                {t('business_edit_business_status_description')}
            </Description>
            {problemNumber > 0 && (
                <Clickable onClick={onClick}>
                    <WarningMessage>
                        {t('business_edit_missing_info_count', {
                            count: problemNumber,
                        })}
                    </WarningMessage>
                </Clickable>
            )}
        </BusinessStatusDescriptionContainer>
    );
};

export default BusinessStatusDescription;
