import styled from '@emotion/styled';

export const AccessLine = styled.div`
    display: flex;
    gap: 8px;

    // To align the IconButton with the input...
    > button {
        margin-top: 2px;
    }
`;
