import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { Checkbox, Icon } from '@partoohub/ui';

import Clickable from 'app/common/components/buttons/Clickable';
import { MAX_TABLET_WIDTH } from 'app/hooks';

type PlatformContainerType = {
    checked: boolean;
};

export const TABLET_BREAKPOINT = `max-width: ${MAX_TABLET_WIDTH}px`;

export const PostFormTypePlatformContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px;
    gap: 32px;

    @media (${TABLET_BREAKPOINT}) {
        margin-top: 70px;
        padding-top: 32px;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HeaderTitleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

export const HeaderTitleIcon = styled(Icon)`
    margin-right: 16px;
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.primary.alpha};
    border-radius: 50%;
`;

export const HeaderTitle = styled.div`
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
`;

export const HeaderDescriptionContainer = styled.div`
    display: flex;
`;

export const HeaderDescriptionIcon = styled.i`
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin: auto 8px auto 0;
`;

export const HeaderDescription = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const ContentContainer = styled.div``;

export const ContentTitle = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 24px;
`;

export const PlatformTitle = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #7f8ea4;
`;

export const MandatoryField = styled.span`
    margin-left: 2px;
    color: #ff426e;
`;

export const PlatformsContainer = styled.div`
    display: flex;
    gap: 12px;
`;

export const PlatformContainer = styled(Clickable)<PlatformContainerType>`
    display: flex;
    width: 200px;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid ${greyColorObject.initial};
    ${({ checked, theme }) =>
        checked &&
        css`
            background-color: ${theme.colors.primary.alpha};
            border: 1px solid ${HEX_COLORS.white};
        `}
`;

export const StyledCheckbox = styled(Checkbox)`
    margin: 0;
`;

export const PlatformLabelContainer = styled.div`
    display: flex;
`;

export const PlatformLabel = styled.div`
    margin: auto 8px;
    font-weight: bold;
`;

export const PlatformIcon = styled.img`
    width: 20px;
    display: flex;
    margin: auto;
`;
