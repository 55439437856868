import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { PUBLISHERS_STATES_BUSINESS_INFO } from 'app/common/data/queryKeysConstants';
import { usePublishersStates } from 'app/presence/management/hooks/usePublishersStates';

export const usePublishersStatesBusinessInfo = () => {
    const { data } = usePublishersStates();

    const businessId = data?.first_business_id ?? '';

    const queryKey = [PUBLISHERS_STATES_BUSINESS_INFO, { businessId }];

    const enabled = businessId !== '';

    const fetchBusinessInfo = () =>
        api.publisherState.getPublishersStatesBusinessInfo({
            business_id: businessId!,
        });

    return useQuery(queryKey, fetchBusinessInfo, {
        enabled,
    });
};
