import { Stack } from '@partoohub/ui';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BusinessCreationErrorsObject } from 'app/api/types/business';
import ControlledAddressFullInput from 'app/businessEditV2/hookControllerWrapper/ControlledAddressFullInput';
import ControlledAsyncSingleSelect from 'app/businessEditV2/hookControllerWrapper/ControlledAsyncSingleSelect';
import { ControlledCityField } from 'app/businessEditV2/hookControllerWrapper/ControlledCityField';
import { ControlledTextInput } from 'app/businessEditV2/hookControllerWrapper/ControlledTextInput';
import useCountry from 'app/businessEditV2/hooks/business/useCountry';
import Section from 'app/businessEditV2/oldFromEditV1/Section';
import { FieldRowContainer } from 'app/pages/visibility/BusinessCreate/sections/BusinessCreateContent/BusinessCreateContent.styled';
import { BusinessCreateFormValues } from 'app/pages/visibility/BusinessCreate/utils/businessCreateForm';

import ControlledGeocodingInput from './ControlledGeocodingInput';

type Props = {
    form: UseFormReturn<BusinessCreateFormValues>;
    errors?: BusinessCreationErrorsObject;
};

const AddressSection = ({ form, errors }: Props) => {
    const { t } = useTranslation();
    const { data: countryData } = useCountry();

    const country = form.watch('country').value;

    return (
        <Section title={t('business_about')}>
            <Stack gap="24px">
                <FieldRowContainer direction="row">
                    <ControlledAddressFullInput
                        dataTrackId="addressFull"
                        name="addressFull"
                        control={form.control}
                        setValue={form.setValue}
                        isMandatory
                    />
                    <ControlledTextInput
                        dataTrackId="addressComplement"
                        name="addressComplement"
                        control={form.control}
                        label={t('business_address_complement')}
                        hasOldTextFieldHeight
                    />
                </FieldRowContainer>

                <FieldRowContainer direction="row">
                    <ControlledTextInput
                        dataTrackId="zipcode"
                        name="zipcode"
                        control={form.control}
                        label={t('zipcode')}
                        error={!!errors?.zipcode}
                        notice={errors?.zipcode ? t(errors?.zipcode) : ''}
                        hasOldTextFieldHeight
                    />
                    <ControlledCityField
                        name="city"
                        control={form.control}
                        renderSelect={country === 'FR'}
                        errorMessage={errors?.city}
                        hasError={!!errors?.city}
                        hasWarning={false}
                    />
                </FieldRowContainer>

                <ControlledAsyncSingleSelect
                    name="country"
                    control={form.control}
                    placeholder={t('country')}
                    options={countryData || []}
                    hideCross
                    hasError={!!errors?.country}
                    explanation={errors?.country ? t(errors?.country) : ''}
                />
            </Stack>
            <ControlledGeocodingInput control={form.control} />
        </Section>
    );
};

export default AddressSection;
