import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';

import { HEX_COLORS, TransparencyEnum, greyColorObject } from '@partoohub/branding';
import { NavLink } from 'react-router-dom';

import EASINGS from 'app/styles/constants/easings';
import Flexbox, { FlexProps } from 'app/styles/utils/flexbox';

export const NavSectionChevronLink = styled(NavLink)<{ css?: SerializedStyles }>`
    color: ${HEX_COLORS.black};
    ${({ css }) => css};
`;

export const NavSectionTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const NavSectionTitleContainer = styled.div``;

export const NavSectionTitleLink = styled('a', {
    shouldForwardProp: prop => !['active', 'as', 'bold'].includes(prop),
})`
    color: ${HEX_COLORS.blackberry};
    font-weight: ${props =>
        // @ts-ignore
        props.bold ? 'bold' : 'normal'};

    ${({ active, theme }) =>
        // @ts-ignore
        active &&
        css`
            color: ${theme.colors.primary.initial};
        `};
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: ${HEX_COLORS.blackberry};
        ${({ active, theme }) =>
            // @ts-ignore
            active &&
            css`
                color: ${theme.colors.primary.initial};
            `};
    }

    &:focus {
        text-decoration: none;
        color: ${HEX_COLORS.blackberry};
        ${({ active, theme }) =>
            // @ts-ignore
            active &&
            css`
                color: ${theme.colors.primary.initial};
            `};
    }
`;
export const DisabledNavSectionTitleLink = styled.span`
    opacity: ${TransparencyEnum.DISABLED};
    cursor: default;
`;

type NavSectionContainerProps = {
    isSubNavSection: boolean;
    marginTop?: string;
};

export const NavSectionContainer = styled.div<NavSectionContainerProps>`
    padding-top: ${props => (props.isSubNavSection ? '16' : '24')}px;
    font-size: 14px;
    font-weight: bold;
    background-color: ${greyColorObject.light};
    will-change: opacity, transform;

    ${props =>
        props.marginTop &&
        css`
            margin-top: ${props.marginTop};
        `}

    & > .fade-exit-active {
        opacity: 0;
        transition:
            opacity 0.3s ${EASINGS.easeOutStrong},
            transform 0.3s ${EASINGS.easeOutStrong};
        transform: scaleY(0);
    }
`;

type NavSectionContentProps = {
    match: boolean;
};

export const NavSectionContent = styled.div<NavSectionContentProps>`
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top left;
    padding-top: 2px;
    padding-left: 8px;
    background-color: ${greyColorObject.light};
    ${props =>
        // @ts-ignore
        !props.match &&
        css`
            position: absolute;
            height: 100px;
            width: 100%;
            background-color: #f5f7fb;
        `}
`;

export const ChevronIcon = styled.i`
    margin-left: 8px;
    &::before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: 'Font Awesome 6 Pro';
        content: '\\f054';
        font-size: 10px;
        font-weight: 900;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
`;

type NavSectionCounterContainerProps = {
    match: boolean;
    shouldHideCounterOnMatch: boolean;
};

export const NavSectionCounterContainer = styled(Flexbox)<
    NavSectionCounterContainerProps & FlexProps
>`
    ${props =>
        props.match &&
        props.shouldHideCounterOnMatch &&
        css`
            display: none;
        `}
`;
