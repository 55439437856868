import {
    REVIEW_ANALYTICS_DATE_FROM,
    REVIEW_ANALYTICS_DATE_TO,
    REVIEW_ANALYTICS_KEYWORDS,
    REVIEW_ANALYTICS_TAGS,
} from 'app/common/data/routeIds';
import { getParsedSearchParam } from 'app/common/data/routeIds.parsers';
import { ANALYTICS_REVIEW_PATH } from 'app/routing/routeIds';

export const parseSearchParams = (location: Location) => ({
    date: {
        from: getParsedSearchParam(
            location,
            ANALYTICS_REVIEW_PATH,
            REVIEW_ANALYTICS_DATE_FROM,
        ),
        to: getParsedSearchParam(
            location,
            ANALYTICS_REVIEW_PATH,
            REVIEW_ANALYTICS_DATE_TO,
        ),
    },
    keyword: getParsedSearchParam(
        location,
        ANALYTICS_REVIEW_PATH,
        REVIEW_ANALYTICS_KEYWORDS,
    ),
    tag: getParsedSearchParam(
        location,
        ANALYTICS_REVIEW_PATH,
        REVIEW_ANALYTICS_TAGS,
    ),
});
