import React from 'react';

import { useTranslation } from 'react-i18next';

import {
    DropZoneErrors,
    DropZoneExplications,
    DropZoneLabel,
    DropZoneTextWrapper,
    DroppedFiles,
    FileExtensions,
    UploadIconWrapper,
} from './DropZone.styled';
import { FileError } from './helpers';
import DragFileIcon from '../../atoms/svg/DragFileIcon';

type DropZoneDescriptionProps = {
    files: File[];
    errors: FileError[];
    label?: string;
    maxFiles: number;
    acceptedExtensions: string[];
    minSize: number;
    maxSize: number;
    uploadIcon?: React.ReactNode;
    errorIcon?: React.ReactNode;
    isSuccessful: boolean;
    isCompact?: boolean;
    hideAcceptedFiles?: boolean;
    inFoodMenuModal?: boolean;
    // TODO MORTY photo errors: When refactoring photo errors, we shouldn't need more than 1 prop
    isBusinessEditV2?: boolean; // If true, uses updated styling for Business Edit V2
    useCompactError?: boolean;
};

const showAsKb = (bytes: number) => bytes / 1_000;
const showAsMb = (bytes: number) => bytes / 1_000_000;

const DropZoneDescription = ({
    files,
    errors,
    label,
    maxFiles,
    acceptedExtensions,
    minSize,
    maxSize,
    uploadIcon,
    errorIcon,
    isSuccessful = false,
    isCompact = false,
    hideAcceptedFiles = false,
    inFoodMenuModal,
    isBusinessEditV2 = false,
    useCompactError = false,
}: DropZoneDescriptionProps) => {
    const { t } = useTranslation();
    const hasBusinessEditV2Error = isBusinessEditV2 && !!errors.length;

    const showLabel = () => {
        if (hasBusinessEditV2Error) {
            return t('drop_zone_upload_error');
        }

        if (!files || !files.length) {
            return label || t('drop_zone_label', { count: maxFiles });
        }

        if (files.length === 1) {
            return files[0].name;
        }

        if (files.length <= 3) {
            return (
                <>
                    <DroppedFiles>{t('drop_zone_files_dropped')}</DroppedFiles>{' '}
                    {files.map(file => file.name).join(', ')}
                </>
            );
        }

        // Don't bother showing too many files
        return t('drop_zone_files_dropped_with_count', { count: files.length });
    };

    const getSupportedSizesText = () => {
        if (!minSize) {
            return t('drop_zone_explanation_supported_sizes_without_min', {
                maxSize: showAsMb(maxSize),
            });
        }

        return t('drop_zone_explanation_supported_sizes', {
            minSize: showAsKb(minSize),
            maxSize: showAsMb(maxSize),
        });
    };

    const showErrors = () => {
        if (!errors.length) {
            return null;
        }

        return (
            <DropZoneErrors>
                <DropZoneTextWrapper className="drop_zone_text_wrapper">
                    {!useCompactError &&
                        t('drop_zone_error_text', { count: errors.length })}

                    <ul>
                        {errors.map(({ filename, errorType }) => (
                            <li key={filename}>
                                <strong>{filename}</strong> (
                                {t(`drop_zone_error_type_${errorType}`)})
                            </li>
                        ))}
                    </ul>
                </DropZoneTextWrapper>
            </DropZoneErrors>
        );
    };

    const iconToDisplay = hasBusinessEditV2Error
        ? errorIcon
        : uploadIcon || <DragFileIcon />;

    return (
        <>
            {iconToDisplay && (
                <UploadIconWrapper
                    isSuccessful={isSuccessful}
                    hasError={hasBusinessEditV2Error}
                >
                    {iconToDisplay}
                </UploadIconWrapper>
            )}

            <DropZoneLabel
                hasError={hasBusinessEditV2Error}
                inFoodMenuModal={inFoodMenuModal}
            >
                {showLabel()}
            </DropZoneLabel>

            {!files?.length && (
                <DropZoneExplications>
                    {!isCompact && (
                        <DropZoneTextWrapper className="drop_zone_text_wrapper">
                            {t('drop_zone_explanation_howto', {
                                count: maxFiles,
                            })}{' '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: getSupportedSizesText(),
                                }}
                            />
                        </DropZoneTextWrapper>
                    )}

                    {!hideAcceptedFiles && (
                        <DropZoneTextWrapper className="drop_zone_text_wrapper">
                            {!inFoodMenuModal &&
                                t('drop_zone_accepted_files', {
                                    count: acceptedExtensions.length,
                                })}{' '}
                            <FileExtensions>
                                <strong>{acceptedExtensions.join(', ')}</strong>
                            </FileExtensions>
                        </DropZoneTextWrapper>
                    )}
                </DropZoneExplications>
            )}

            {showErrors()}
        </>
    );
};

export default DropZoneDescription;
