import React, { useState } from 'react';

import { Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';

import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useLogoSectionColors from 'app/businessEditV2/hooks/editV2utils/sectionColors/useLogoSectionColors';
import LogoForm from 'app/businessEditV2/sections/AddressSection/PhotoFormModal/LogoForm';
import { trackSelectBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import AddImageIcon from 'app/common/components/icons/AddImageIcon';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { getMappedUrl, resizeImage } from 'app/utils/resizeImage';

import {
    PhotoLogo,
    PhotoLogoContainer,
    PhotoLogoOverlay,
    PhotoLogoOverlayNoLogo,
} from './PhotoLogoSection.styled';

const PhotoLogoSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);
    const { data: business } = useBusiness();
    const sectionColor = useLogoSectionColors();
    const logoUrl = business?.photos?.LOGO ?? '';

    const onClick = () => {
        setShowModal(true);
        trackSelectBusinessEditSection(SectionNames.LOGO, sectionColor);
    };

    return (
        <>
            <PhotoLogoContainer ref={ref}>
                <TooltipWrapper
                    text={sectionColor === ColorState.DISABLED ? t('field_read_only') : ''}
                >
                    <PhotoLogo
                        url={resizeImage(getMappedUrl(logoUrl, business?.media_mapping), 200, 200)}
                        onClick={onClick}
                        sectionColor={sectionColor}
                    >
                        {logoUrl ? (
                            <PhotoLogoOverlay>
                                <i className="fa-solid fa-camera" />
                            </PhotoLogoOverlay>
                        ) : (
                            <PhotoLogoOverlayNoLogo sectionColor={sectionColor}>
                                <AddImageIcon />
                            </PhotoLogoOverlayNoLogo>
                        )}
                    </PhotoLogo>
                </TooltipWrapper>
            </PhotoLogoContainer>

            <Modal isOpen={showModal} closeModal={() => setShowModal(false)}>
                <LogoForm closeModal={() => setShowModal(false)} />
            </Modal>
        </>
    );
});

export default PhotoLogoSection;
