import { useMutation } from 'react-query';

import { CreateGroupParams } from 'app/api/types/groups';
import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { GROUPS } from 'app/common/data/queryKeysConstants';
import { GroupData } from 'app/common/reducers/groups';
import formatGroupData from 'app/common/services/formatGroupDataService';
import queryClient from 'app/states/queryClient';

export default function useGroupCreate(
    onSuccess: (group: GroupData) => void = () => undefined,
    onError: () => void = () => undefined,
) {
    return useMutation((groupData: CreateGroupParams) => api.groups.createGroup(groupData), {
        onSuccess: data => {
            queryClient.invalidateQueries(invalidatesAny(GROUPS)).then(() => {
                onSuccess(formatGroupData(data));
            });
        },
        onError,
    });
}
