import { InfiniteData, useQueryClient } from 'react-query';

import { REVIEW_LIST } from 'app/common/data/queryKeysConstants';
import { ReviewObject } from 'app/states/reviews';

import {
    DELETED_REVIEWS,
    LIVE_REVIEWS,
    NOT_TREATED_REVIEWS,
    TREATED_REVIEWS,
    navTabSelector,
} from 'app/states/reviews/filters';

import { ReviewListQueryData, useReviewListQueryKey } from '../useGetReviews';

type UpdateReviewQueryDataOptions = {
    hideReview?: boolean;
    markReviewAs?: 'treated' | 'not-treated' | 'deleted';
};

export const useUpdateReviewQueryData = () => {
    const queryClient = useQueryClient();
    const queryKey = useReviewListQueryKey();
    const navTab = navTabSelector();

    const shouldHideReviewMarkedAs = (markReviewAs: 'treated' | 'not-treated' | 'deleted') => {
        return (() =>
            !{
                treated: [LIVE_REVIEWS, TREATED_REVIEWS],
                'not-treated': [LIVE_REVIEWS, NOT_TREATED_REVIEWS],
                deleted: [DELETED_REVIEWS],
            }[markReviewAs].includes(navTab))();
    };

    const updateReviewQueryData = (
        updatedReview: ReviewObject,
        options: UpdateReviewQueryDataOptions = { hideReview: false, markReviewAs: undefined },
    ) => {
        /*
         ** Function to update the review query data
         */

        const {
            /*
             ** Set hideReview to true if you need to hide the review from ReviewList, for instance:
             ** when the updated review does not satisfy the filters set on the ReviewList anymore
             **/
            hideReview,

            /*
             ** Mark the updated review as treated/not_treated/deleted
             ** The review will be hidden accordingly if a status filter is set on the ReviewList
             **/
            markReviewAs,
        } = options;

        const currentData = queryClient.getQueryData<InfiniteData<ReviewListQueryData>>(queryKey, {
            exact: true,
        });
        if (!currentData) return;

        let newReview = updatedReview;
        let hideNewReview = hideReview;
        if (markReviewAs) {
            newReview = { ...updatedReview, state: markReviewAs };
            hideNewReview ||= shouldHideReviewMarkedAs(markReviewAs);
        }

        const newPages = currentData.pages.map(page => {
            const oldReviewIndex = page.items.findIndex(item => item.id === newReview.id);
            if (oldReviewIndex !== -1) {
                if (hideNewReview) {
                    return {
                        ...page,
                        items: page.items.toSpliced(oldReviewIndex, 1),
                    };
                }
                return {
                    ...page,
                    items: page.items.toSpliced(oldReviewIndex, 1, newReview),
                };
            } else {
                return page;
            }
        });

        const currentCount = newPages[0].countByType[newReview.reviewObjectType];
        if (hideNewReview && currentCount) {
            newPages[0].countByType = {
                ...newPages[0].countByType,
                [newReview.reviewObjectType]: currentCount - 1,
            };
        }
        queryClient.setQueryData<InfiniteData<ReviewListQueryData>>(queryKey, {
            ...currentData,
            pages: newPages,
        });

        queryClient.invalidateQueries({
            queryKey: [REVIEW_LIST],
            refetchActive: false,
        });
    };

    return updateReviewQueryData;
};
