import { Component } from 'react';

import {
    Button,
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    LeftElementType,
    TextInput,
} from '@partoohub/ui';
import { isEqual } from 'lodash-es';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { withTranslation } from 'react-i18next';

import { ErrorObject } from 'app/api/types';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import {
    AttributeTypeRepeatableUrlContainer,
    AttributeTypeRepeatableUrlName,
    AttributeValue,
    SubSection,
    SubSectionContent,
} from './AttributesTypeRepeatableUrl.styled';

type Props = {
    value: Array<string | null> | null;
    displayName: string;
    onChange: (
        newValue: Array<string | null> | null,
        attributeError?: Record<string, any> | null,
    ) => void;
    disabled: boolean;
    error: ErrorObject | null | undefined;
    t: (text: string, obj?: Record<string, any>) => string;
    // Optional props
    id?: string;
};

type State = {
    autofocusOnNewOption: boolean;
};

class RepeatableUrl extends Component<Props, State> {
    static defaultProps = {
        id: undefined,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            autofocusOnNewOption: false,
        };
    }

    onChange = (value: Array<string | null> | null, error?: Record<string, any> | null) => {
        const { onChange } = this.props;

        if (isEqual(value, []) || isEqual(value, [''])) {
            onChange(null, null);
        } else {
            onChange(value, error);
        }
    };

    changeTextFieldValue = (newValue: string, index: number, oldValue: string) => {
        const { value, error } = this.props;
        const newValues = value === null ? [''] : value;
        newValues[index] = newValue;

        if (error !== undefined && error !== null) {
            delete error[index];

            if (error.duplicated_urls) {
                const duplicatedIndex = error.duplicated_urls.indexOf(oldValue);

                if (duplicatedIndex !== -1) {
                    error.duplicated_urls.splice(duplicatedIndex, 1);
                    if (isEqual(error.duplicated_urls, [])) delete error.duplicated_urls;
                }
            }
        }

        this.onChange(newValues, error);
    };

    addOption = () => {
        const { value, error } = this.props;
        const newValues = value === null ? [''] : value;
        newValues.push('');
        this.setState(
            {
                autofocusOnNewOption: true,
            },
            () => {
                this.onChange(newValues, error);
            },
        );
    };

    removeOption = (index: number) => {
        const { value, error } = this.props;
        const oldValue = value !== null ? value[index] : '';
        if (value !== null) value.splice(index, 1);
        const newError = {};

        // Handle the order of the errors
        if (error !== undefined && error !== null) {
            delete error[index];
            Object.entries(error).forEach(([key, errorValue]) => {
                if (key === 'duplicated_urls' && value !== null) {
                    const duplicatedError = errorValue;

                    if (Array.isArray(duplicatedError)) {
                        const duplicatedIndex = duplicatedError.indexOf(oldValue);
                        if (duplicatedIndex !== -1) duplicatedError.splice(duplicatedIndex, 1);

                        if (!isEqual(duplicatedError, [])) {
                            newError[key] = duplicatedError;
                        }
                    }
                } else {
                    let newKey = parseInt(key, 10);
                    if (newKey >= index) newKey -= 1;
                    newError[newKey] = errorValue;
                }
            });
        }

        this.onChange(value, newError);
    };

    hasError = (index: number, error: ErrorObject | null | undefined, valueText: string) => {
        if (error) {
            if (error[index]) return true;
            if (error.duplicated_urls && error.duplicated_urls.includes(valueText)) return true;
        }

        return false;
    };

    errorExplanation = (
        index: number,
        error: ErrorObject | null | undefined,
        valueText: string,
    ) => {
        const { t } = this.props;

        if (error) {
            if (error[index]) return t(error[index]);
            if (error.duplicated_urls && error.duplicated_urls.includes(valueText))
                return t('duplicated_url');
        }

        return '';
    };

    render() {
        const { id, value, displayName, t, disabled, error } = this.props;
        const { autofocusOnNewOption } = this.state;
        const valueToDisplay = value ?? [''];
        return (
            <AttributeTypeRepeatableUrlContainer id={id}>
                <AttributeTypeRepeatableUrlName
                    disabled={disabled}
                    variant={'bodySBold'}
                    color={'secondary'}
                >
                    {displayName}
                </AttributeTypeRepeatableUrlName>
                <SubSection>
                    <SubSectionContent>
                        {valueToDisplay.map((valueText, index) => (
                            <AttributeValue key={index}>
                                <TextInput
                                    value={valueText || ''}
                                    onChange={newValue =>
                                        this.changeTextFieldValue(
                                            newValue || '',
                                            index,
                                            valueText || '',
                                        )
                                    }
                                    label={`${t('link')} ${
                                        valueToDisplay.length > 1
                                            ? `${t('num_abbrev', {
                                                  number: index + 1,
                                              })}`
                                            : ''
                                    }`}
                                    autoFocus={autofocusOnNewOption}
                                    disabled={disabled}
                                    readOnly={disabled}
                                    error={this.hasError(index, error, valueText || '')}
                                    notice={
                                        this.hasError(index, error, valueText || '')
                                            ? this.errorExplanation(index, error, valueText || '')
                                            : ''
                                    }
                                    leftElement={['fa-link', IconPrefix.SOLID]}
                                    leftElementType={LeftElementType.Icon}
                                    dataTrackId="visibility_location__form_links__additional_repeatable_urls"
                                />
                                <IconButton
                                    appearance="outlined"
                                    variant="danger"
                                    onClick={() => this.removeOption(index)}
                                    icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                                    tooltip={disabled ? t('field_read_only') : ''}
                                    disabled={disabled}
                                    dataTrackId="delete_link__button"
                                />
                            </AttributeValue>
                        ))}
                        <TooltipWrapper
                            text={disabled ? t('field_read_only') : ''}
                            className="tooltip-position__button"
                            position="bottom-start"
                        >
                            <Button
                                dataTrackId="add_link__button"
                                onClick={this.addOption}
                                variant="primary"
                                shape="cube"
                                icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                                appearance="outlined"
                                disabled={disabled}
                            >
                                {t('add_link')}
                            </Button>
                        </TooltipWrapper>
                    </SubSectionContent>
                </SubSection>
            </AttributeTypeRepeatableUrlContainer>
        );
    }
}

const Comp = withTranslation()(RepeatableUrl);

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'onChange' | 'value' | 't';

const AttributesTypeRepeatableUrl = ({
    name,
    control,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { ref: _ref, ...usedFields } }) => <Comp {...props} {...usedFields} />}
    />
);

export default AttributesTypeRepeatableUrl;
