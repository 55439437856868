import styled from '@emotion/styled';

export const DoughnutLegendStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &:not(:last-child) {
        padding-bottom: 8px;
    }
`;

export const DoughnutLegendBodyStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DoughnutLegendColorStyled = styled.div`
    text-align: center;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
`;

export const DoughnutLegendCount = styled.b`
    padding: 0 8px;
    border-radius: 40px;
    margin-left: 16px;
`;
