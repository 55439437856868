const WHITELIST_PROVIDERS = ['partoo', 'localoo', 'partoo_it', 'demo', 'partoo_tech_test'];

export const NPS_WHITELIST_PROVIDERS = [
    ...WHITELIST_PROVIDERS,
    'Fil',
    'Point Fort Fichet',
    'Five Pizza Original',
    'Aloy',
    'Tilli',
    'Renovation Man',
    'Apollo Sporting Club',
    'Atout France',
    'Bosch Car Service',
    'Carmila',
    'Nexus Automotive International',
    'L\'Oréal',
    'Cyclofix',
    'Cmonjardinier',
    'Kandbaz',
    'Gîtes de France de Bretagne',
    'husqvarna',
    'Husqvarna ES-PT',
    'husqvarna uk',
    'Husqvarna-RO',
    'Husqvarna Germany',
    'Fercad',
    'CC Lomagne Tarn et Garonnaise',
];

export default WHITELIST_PROVIDERS;
