import i18n from 'app/common/translations/i18n';
import { MetricsAttributesResponse } from 'app/pages/analytics/VisibilityAnalyticsV2/types/metrics';
import { formatXAxisLabels } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/formatTooltip';
import { viewsChartColor } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/view';

export const normalizrViews = (data, dates) => {
    const datesReturned = data.map(item => item.date);
    const labels = data.map(item => formatXAxisLabels(item.bucket, item.date, dates.from));
    const datasets = [
        {
            label: i18n.t('mobile_word'),
            backgroundColor: viewsChartColor.normal,
            data: data.map(
                item =>
                    item[MetricsAttributesResponse.BUSINESS_IMPRESSIONS_MOBILE_SEARCH] +
                    item[MetricsAttributesResponse.BUSINESS_IMPRESSIONS_MOBILE_MAPS],
            ),
        },
        {
            label: i18n.t('desktop_word'),
            backgroundColor: viewsChartColor.light,
            data: data.map(
                item =>
                    item[MetricsAttributesResponse.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH] +
                    item[MetricsAttributesResponse.BUSINESS_IMPRESSIONS_DESKTOP_MAPS],
            ),
        },
    ];

    return { labels, datasets, datesReturned };
};
