import { times } from 'lodash-es';

import { STATIC_BASE_URL } from 'app/config';
import { starRating, tripAdvisorRating } from 'app/states/reviews';

import { StyledStars } from './RatingNumber.styled';

type Props = {
    rating: number;
    partner: string;
};

export const RatingStars = ({ rating }: { rating: number }) => (
    <StyledStars>
        {starRating(rating).map(([className, value]) =>
            // @ts-ignore
            times(value, index => (
                // @ts-ignore
                <i key={`rating_${index}`} className={className} />
            )),
        )}
    </StyledStars>
);

const RatingNumber = ({ rating, partner }: Props) => {
    if (partner === 'tripadvisor') {
        return (
            <span>
                {tripAdvisorRating(rating).map(([type, value]) =>
                    // @ts-ignore
                    times(value, index => (
                        <img
                            // @ts-ignore
                            alt={type}
                            key={`rating_${index}`}
                            src={`${STATIC_BASE_URL}/images/app/app_review/${type}.svg`}
                        />
                    )),
                )}
            </span>
        );
    }

    return <RatingStars rating={rating} />;
};

export default RatingNumber;
