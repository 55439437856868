import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
    ATTRIBUTES_CONTAINER_ID,
    TOP_FIXED_INDEX,
    TRIGGER_FIXED_POSITION,
} from 'app/businessEditV2/sections/AttributesSection/AttributesForm';
import ScrollSpyClick from 'app/businessEditV2/sections/AttributesSection/ScrollSpy/ScrollSpyClick';

import {
    AttributesIndexContainer,
    IndexCategory,
    IndexTitle,
} from './AttributesIndex.styled';

type Props = {
    categories: Array<string>;
};

const AttributesIndex = ({ categories }: Props) => {
    const { t } = useTranslation();
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = (event: Event) => {
            // @ts-ignore
            const scrollY = event.currentTarget.scrollTop;
            setIsSticky(scrollY > TRIGGER_FIXED_POSITION);
        };

        const content = document.getElementById(ATTRIBUTES_CONTAINER_ID);

        if (content !== null) {
            content.addEventListener('scroll', handleScroll);
        }

        return function () {
            if (content !== null) {
                content.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <AttributesIndexContainer
            isSticky={isSticky}
            topFixedIndex={TOP_FIXED_INDEX}
        >
            <IndexTitle>{t('categories')}</IndexTitle>
            {categories.map(category => (
                <ScrollSpyClick
                    idScrollTo={category}
                    defaultActiveId={categories[0]}
                    key={`attribute_index${category}`}
                >
                    <IndexCategory>{category}</IndexCategory>
                </ScrollSpyClick>
            ))}
        </AttributesIndexContainer>
    );
};

export default AttributesIndex;
