import { useQuery } from 'react-query';
import { call } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import { ME, ORGANIZATIONS } from 'app/common/data/queryKeysConstants';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import queryClient from 'app/states/queryClient';

export const getEnableNewGroups = async () => {
    const { org_id: orgId } = await queryClient.fetchQuery(
        [ME, 'uncamel'],
        () => api.user.getMe(),
    );

    const groupConfig = await queryClient.fetchQuery(
        [ORGANIZATIONS, { orgId }, 'groups_config'],
        () => api.org.getGroupsConfig(orgId),
    );

    return groupConfig.switched_to_new_groups;
};

/**
 * @deprecated
 * Please stop using saga and only use useBusinessModalFilters()
 */
export function* enableNewGroupsGenerator() {
    return yield call(getEnableNewGroups);
}

function useGroupsConfig(orgId: number | string | null | undefined) {
    return useQuery(
        [ORGANIZATIONS, { orgId }, 'groups_config'],
        () => api.org.getGroupsConfig(orgId ?? ''),
        {
            enabled: !!orgId,
        },
    );
}

export default function useNewGroups(orgId?: number): boolean | undefined {
    const { data: me } = useMe();
    const { data: groupsConfig } = useGroupsConfig(orgId ?? me?.org_id);

    return groupsConfig?.switched_to_new_groups;
}
