import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const ChatbotCommunicationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const ChatbotCommunicationSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ChatbotCommunicationSectionTitle = styled.div`
    display: flex;
    gap: 8px;
`;

export const ToneOfVoiceCardList = styled.div`
    display: flex;
    gap: 16px;
`;

export const ToneOfVoiceCard = styled.div<{ selected: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    word-break: break-word;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.secondary.alpha};
    padding: 16px;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    flex: 1;
    ${({ selected, theme }) =>
        selected &&
        css`
            border: 1px solid ${theme.colors.primary.background};
            box-shadow: 0 0 0 3px ${theme.colors.primary.alpha};
        `}
    transition: all ease-in-out .3s;
`;

export const ChipContainer = styled.div`
    position: absolute;
    padding: 4px 8px;
    border-radius: 24px;
    top: -12px;
    ${({ theme }) => css`
        background: linear-gradient(
                0deg,
                ${theme.colors.primary.alpha} 0%,
                ${theme.colors.primary.alpha} 100%
            ),
            #fff;
    `}
`;

export const IconContainer = styled.div`
    width: fit-content;
    font-size: 44px;
`;

export const DescriptionText = styled(Text)`
    text-align: center;
`;
