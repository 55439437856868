import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SOCIAL_COLORS, greyColorObject } from '@partoohub/branding';

import { Color } from 'app/common/designSystem/constants/color';

type ButtonProps = {
    color?: Color;
    iconPositionRight?: boolean;
};

export const ButtonWrapper = styled.button<ButtonProps>`
    display: flex;
    gap: 4px;
    border: none;
    background-color: unset;
    font-size: 14px;
    font-weight: bold;
    align-items: center;

    transition: color 0.2s linear;

    > i {
        margin-right: 4px;

        ${(props: ButtonProps) =>
            props.iconPositionRight &&
            css`
                margin-left: 4px;
                margin-right: 0;
            `};
    }

    ${({ color, theme }) =>
        color &&
        css`
            /*-----Buttons Primary-----*/
            ${color === 'primary' &&
            css`
                color: ${theme.colors.primary.initial} !important;

                &:hover {
                    color: ${theme.colors.primary.dark} !important;
                }
            `};

            /*-----Buttons Secondary-----*/
            ${color === 'secondary' &&
            css`
                color: ${theme.colors.secondary.initial} !important;
            `};

            /*-----Buttons Dark-----*/
            ${color === 'dark' &&
            css`
                color: ${theme.colors.default.initial} !important;
            `};

            /*-----Buttons Sidebar Color-----*/
            ${color === 'sidebar-color' &&
            css`
                color: ${greyColorObject.light} !important;
            `};

            /*-----Buttons Success-----*/
            ${color === 'success' &&
            css`
                color: ${theme.colors.success.initial} !important;
            `};

            /*-----Buttons Danger-----*/
            ${color === 'danger' &&
            css`
                color: ${theme.colors.danger.initial} !important;
            `};

            /*-----Buttons Warning-----*/
            ${color === 'warning' &&
            css`
                color: ${theme.colors.warning.initial} !important;
            `};

            /*-----Buttons Social Facebook-----*/
            ${color === 'social-facebook' &&
            css`
                color: ${SOCIAL_COLORS.facebook} !important;
            `};

            /*-----Buttons Social Google-----*/
            ${color === 'social-google' &&
            css`
                color: ${SOCIAL_COLORS.google} !important;
            `};
        `};
`;
