import { V2FormattedBusinessData } from 'app/api/types/business';

const gmbFilter = (
    businesses: Array<V2FormattedBusinessData>,
    connected: boolean,
): Array<V2FormattedBusinessData> => {
    if (connected) {
        return businesses.filter(
            ({ googleLocationId }) =>
                googleLocationId && googleLocationId !== '',
        );
    }

    return businesses.filter(({ googleLocationId }) => !googleLocationId);
};

export default gmbFilter;
