import { useContext } from 'react';

import { Button, FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import FunnelContext from 'app/common/components/funnel/FunnelContext';
import {
    FullPageContainer,
    SubTitle,
    Title,
} from 'app/common/components/funnel/FunnelModal.styled';
import dataLayer from 'app/common/utils/dataLayer';
import { STATIC_BASE_URL } from 'app/config';
import { VISIBILITY_LOCATION_ADD_PATH } from 'app/routing/routeIds';

import {
    CardContainer,
    CardItem,
    CardItemSubTitle,
    CardItemTitle,
    VerticalSeparator,
} from './AddNewLocationPage.styled';

const AddNewLocationPage = () => {
    const { t } = useTranslation();
    const funnel = useContext(FunnelContext);
    const navigate = useNavigate();

    const handleImportGoogleLocations = () => {
        dataLayer.pushDict('import_auto_location');
        funnel.navigate('selection_page');
    };

    const handleCreateLocation = () => {
        // Google Tag Manager
        dataLayer.pushDict('import_manually_location');
        funnel.abort();
        navigate(VISIBILITY_LOCATION_ADD_PATH);
    };

    return (
        <FullPageContainer>
            <Title>{t('onboarding_add_new_location_page_title')}</Title>
            <SubTitle>{t('onboarding_add_new_location_page_sub_title')}</SubTitle>
            <CardContainer>
                <CardItem onClick={handleImportGoogleLocations}>
                    <img
                        src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/google.svg`}
                        alt="import from google"
                    />
                    <CardItemTitle>
                        {t('onboarding_add_new_location_page_google_card_title')}
                    </CardItemTitle>
                    <CardItemSubTitle>
                        {t('onboarding_add_new_location_page_google_card_sub_title')}
                    </CardItemSubTitle>
                    <Button
                        dataTrackId="go_to_import_locations_onboarding"
                        variant="primary"
                        shape="cube"
                        icon={['fa-sync', IconPrefix.SOLID]}
                    >
                        {t('onboarding_add_new_location_page_google_card_button')}
                    </Button>
                </CardItem>
                <VerticalSeparator />
                <CardItem onClick={handleCreateLocation}>
                    <span className="add-location-icon">
                        <i />
                    </span>
                    <CardItemTitle>
                        {t('onboarding_add_new_location_page_new_card_title')}
                    </CardItemTitle>
                    <CardItemSubTitle>
                        {t('onboarding_add_new_location_page_new_card_sub_title')}
                    </CardItemSubTitle>
                    <Button
                        dataTrackId="go_to_create_location_onboarding"
                        icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                        variant="primary"
                        shape="cube"
                    >
                        {t('onboarding_add_new_location_page_new_card_button')}
                    </Button>
                </CardItem>
            </CardContainer>
        </FullPageContainer>
    );
};

export default AddNewLocationPage;
