import { Dispatch, SetStateAction } from 'react';

import { ActionModal } from './ActionModal';

type Props = {
    isOpenConfirmModal: boolean;
    onConfirm: () => void;
    onHide: () => void;
    email: string;
    setEmail: Dispatch<SetStateAction<string>>;
};

export const ExportModal = ({ isOpenConfirmModal, onConfirm, onHide, email, setEmail }: Props) => {
    return (
        <ActionModal
            isOpenConfirmModal={isOpenConfirmModal}
            onConfirm={onConfirm}
            onHide={onHide}
            email={email}
            setEmail={setEmail}
            type="export"
        />
    );
};
