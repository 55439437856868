import { useTranslation } from 'react-i18next';

import { useAppDataContext } from 'app/common/contexts/appData';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { ReviewReplyTemplatesPage } from 'app/pages/settings/ReviewReplyTemplates';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';
import { useHasBulkReplyTemplateTemp } from 'app/reviewManagement/replyTemplate/hooks/useHasBulkReplyTemplateTemp';
import { BulkReplyTemplateModal } from 'app/reviewManagement/replyTemplate/subComponents/BulkReplyTemplateModal/BulkReplyTemplateModal';
import { CreateReplyTemplateButton } from 'app/reviewManagement/replyTemplate/subComponents/CreateReplyTemplateButton/CreateReplyTemplateButton';
import { EditReplyTemplateButton } from 'app/reviewManagement/replyTemplate/subComponents/EditReplyTemplateButton/EditReplyTemplateButton';

export const ReplyTemplates = () => {
    const { t } = useTranslation();

    const { me, organization } = useAppDataContext();
    const [editTemplate, setEditTemplate] = useStateQueryParams('editTemplate', false);

    const hasBulkReplyTemplate = useHasBulkReplyTemplateTemp();
    const hasBulkFeature =
        me?.can_use_bulk_modification &&
        organization?.has_bulk_modification &&
        hasBulkReplyTemplate;

    const isOpenTemplateModal = editTemplate === 'true';
    const handleOpenTemplateModal = () => setEditTemplate('true');
    const handleCloseTemplateModal = () => setEditTemplate('false');

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('reply_templates')}
                    description={t('reply_templates_description')}
                    actionsButton={[
                        hasBulkFeature && (
                            <EditReplyTemplateButton
                                key="modify_reply_template_button"
                                onClick={handleOpenTemplateModal}
                            />
                        ),
                        <CreateReplyTemplateButton key="create_reply_template_button" />,
                    ]}
                />
            }
        >
            {hasBulkFeature && (
                <BulkReplyTemplateModal
                    isOpen={isOpenTemplateModal}
                    onClose={handleCloseTemplateModal}
                    setEditTemplate={setEditTemplate}
                />
            )}
            <MenuListContentContainer>
                <ReviewReplyTemplatesPage />
            </MenuListContentContainer>
        </MenuListContentTemplate>
    );
};
