import { QueryKey, useQuery } from 'react-query';

import { KeywordType } from 'app/api/types/competitiveBenchmark';
import api from 'app/api/v2/api_calls';
import { COMPETITIVE_BENCHMARK_KEYWORDS } from 'app/common/data/queryKeysConstants';

export const useGetKeywordsCompetitive = (): {
    available_keywords: KeywordType[];
    isLoading: boolean;
} => {
    const { data, isLoading } = useQuery([COMPETITIVE_BENCHMARK_KEYWORDS] as QueryKey, () =>
        api.competitiveBenchmark.getKeywordsCompetitive(),
    );

    return {
        available_keywords: data?.available_keywords || [],
        isLoading,
    };
};
