import { useTheme } from '@emotion/react';
import { ColorVariants } from '@partoohub/branding/dist/themes';
import { FontAwesomeIconsPartooUsed, Icon, IconPrefix } from '@partoohub/ui';
import { IconElement } from '@partoohub/ui/dist/types/components';

import { ContentLoader } from 'app/pages/Homepage/utils/ContentLoader';

import {
    ShortcutContainer,
    ShortcutContent,
    ShortcutIcon,
    ShortcutTitle,
} from './DefaultShortcutComponent.styled';

type Props = {
    title: string;
    variant?: ColorVariants;
    iconElement?: IconElement;
    onClick?: () => void;
    isLoading?: boolean;
};

export const DefaultShortcutComponent = ({
    title,
    variant = 'primary',
    iconElement = [FontAwesomeIconsPartooUsed.faHouse, IconPrefix.REGULAR],
    onClick = () => undefined,
    isLoading,
}: Props) => {
    const itemColor = useTheme().colors[variant];

    if (isLoading) {
        return (
            <ShortcutContainer>
                <ContentLoader noStroke />
            </ShortcutContainer>
        );
    }

    return (
        <ShortcutContainer>
            <ShortcutContent hoverColor={itemColor.alpha} onClick={onClick}>
                <ShortcutIcon icon={iconElement} color={variant} />
                <ShortcutTitle variant="bodyMBold">{title}</ShortcutTitle>
                <Icon icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.REGULAR]} />
            </ShortcutContent>
        </ShortcutContainer>
    );
};
