import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import NotAccessibleForAdmin from 'app/common/components/placeholder/NotAccessibleForAdmin';
import { ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import GooglePostsList from 'app/presence/googlePosts/components/googlePostList';

export const GooglePostsListPage = () => {
    const { t } = useTranslation();
    const { data: me } = useMe();

    return (
        <>
            <DynamicPageHeader title={t('google_posts_title')} />
            {me?.role === ADMIN ? <NotAccessibleForAdmin /> : <GooglePostsList />}
        </>
    );
};
