import React, { useContext } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { V2FormattedLocationData } from 'app/api/types/account';
import FunnelContext from 'app/common/components/funnel/FunnelContext';
import {
    FullPageContainer,
    SubTitle,
    Title,
} from 'app/common/components/funnel/FunnelModal.styled';
import { STATIC_BASE_URL } from 'app/config';
import {
    FullButton,
    FullButtonWrapper,
} from 'app/onboarding/SelectionPage/SelectionPage.styled';

const NoAddressPage = () => {
    const { t } = useTranslation();
    const funnel = useContext(FunnelContext);
    const { selectedLocations = [] } = funnel.store;
    const locationsWithoutAddress = selectedLocations.filter(
        (location: V2FormattedLocationData) => !location.addressDetails,
    );

    const onClick = () => {
        if (selectedLocations.length == locationsWithoutAddress.length) {
            funnel.navigate('selection_page');
        } else {
            funnel.navigate('summary_page');
        }
    };

    return (
        <FullPageContainer>
            <img
                src={`${STATIC_BASE_URL}/images/app/very-happy-laptop.svg`}
                alt="business"
            />
            <Title>{t('onboarding_no_address_page_title')}</Title>
            <SubTitle>{t('onboarding_no_address_page_sub_title')}</SubTitle>

            <FullButtonWrapper>
                <FullButton
                    dataTrackId="continue_from_no_address_onboarding"
                    onClick={onClick}
                    icon={[
                        FontAwesomeIconsPartooUsed.faArrowRight,
                        IconPrefix.SOLID,
                    ]}
                    shape="cube"
                    variant="primary"
                    size="large"
                >
                    {t('continue')}
                </FullButton>
            </FullButtonWrapper>
        </FullPageContainer>
    );
};

export default NoAddressPage;
