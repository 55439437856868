import { useContext, useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix, toast } from '@partoohub/ui';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { V2FormattedLocationData } from 'app/api/types/account';
import { BusinessLocation } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import AccessGroupModal from 'app/common/components/AccessGroupModal/AccessGroupModal';
import FunnelContext from 'app/common/components/funnel/FunnelContext';
import { SubTitle, Title } from 'app/common/components/funnel/FunnelModal.styled';
import { ToastWithAction } from 'app/common/components/ToastWithAction/ToastWithAction';
import { useTaskContext } from 'app/common/contexts/taskListener';
import { GROUP_MANAGER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';

import {
    CardContainer,
    CardsContainer,
    LocationCardStyled,
    SelectionPageResultsContainer,
} from 'app/onboarding/SelectionPage/components/SelectionPageResults/SelectionPageResults.styled';
import {
    FullButton,
    FullButtonWrapper,
    Scroller,
    SelectionContainer,
    SelectionContentContainer,
} from 'app/onboarding/SelectionPage/SelectionPage.styled';
import { SummaryCard } from 'app/onboarding/SummaryPage/SummaryPage.styled';

interface Props {
    t: TFunction;
    toImportLocations: Array<BusinessLocation>;
    groups?: Array<number>;
}

export const createBusinessesFromLocations = async ({
    t,
    toImportLocations,
    groups,
}: Props): Promise<string[]> => {
    const numberLocations = toImportLocations.length;
    const result = await api.business.onboardingBusinessesCreation(toImportLocations, groups);
    const handleReload = () => window.location.reload();

    if ('business_ids' in result) {
        const businessIds = result.business_ids;
        const totalErrors = numberLocations - businessIds.length;

        if (totalErrors === numberLocations) {
            toast.error(
                t('onboarding_snackbar_business_creation_error'),
                t('onboarding_snackbar_title_error'),
            );
        } else if (totalErrors > 0) {
            toast.warning(
                <ToastWithAction
                    action={handleReload}
                    description={t('onboarding_snackbar_business_creation_warning', {
                        totalNumberErrors: totalErrors,
                        count: numberLocations,
                    })}
                >
                    {t('refresh_page_snackbar_title')}
                </ToastWithAction>,
                t('onboarding_snackbar_title_warning'),
            );
        }

        return businessIds;
    }
    return [];
};

const SummaryPage = () => {
    const { t } = useTranslation();

    // Handle accesses for GM on new groups
    const { data: me } = useMe();
    const enableNewGroups = useNewGroups();
    const displayGroupsSelect = enableNewGroups && me?.role === GROUP_MANAGER;
    const [showGroupModal, setShowGroupModal] = useState<boolean>(false);

    const funnel = useContext(FunnelContext);
    const { storage, setStorage } = useTaskContext();

    const { selectedLocations = [], editedLocations = [] } = funnel.store;

    // here we want to get locations with address details to display it
    const validLocations = selectedLocations
        .map((location: V2FormattedLocationData) => {
            const editedLocation = editedLocations.find(
                (editedLocation: BusinessLocation) => editedLocation.id === location.id,
            );

            if (editedLocation) {
                return {
                    ...location,
                    addressDetails: editedLocation.address.address_full,
                    postalCode: editedLocation.address.zipcode,
                    locality: editedLocation.address.city,
                };
            } else if (location.addressDetails) {
                return location;
            }
            return null;
        })
        .filter(Boolean);

    const onImportClick = () => {
        if (displayGroupsSelect) {
            setShowGroupModal(true);
        } else {
            importSelectedLocations();
        }
    };

    const importSelectedLocations = (groups?: Array<number>) => {
        // Just in case something goes wrong
        if (displayGroupsSelect && !groups?.length) {
            return;
        }

        // here we want to get an edited businessLocation associated to a valid location
        const toImportLocations = validLocations.map(
            (location: V2FormattedLocationData) =>
                editedLocations.find(
                    (editedLocation: BusinessLocation) => editedLocation.id === location.id,
                ) || { id: location.id },
        );

        createBusinessesFromLocations({
            t,
            toImportLocations,
            groups,
        }).then(businessIds => setStorage({ ...storage, businessIds }));

        funnel.navigate('congratulations_page');
    };

    return (
        <>
            <SelectionContainer id="react-content-3">
                <SelectionContentContainer>
                    <Scroller
                        hasMore={false}
                        loadMore={() => {}}
                        threshold={500}
                        useWindow={false}
                        getScrollParent={() => document.getElementById('react-content-3')}
                    >
                        <Title>{t('onboarding_summary_page_title')}</Title>
                        <SubTitle>{t('onboarding_summary_page_subtitle')}</SubTitle>
                        <SelectionPageResultsContainer hasSelected={validLocations.length > 0}>
                            <CardsContainer>
                                {validLocations.map((location: V2FormattedLocationData) => {
                                    return (
                                        <CardContainer key={location.id}>
                                            <SummaryCard
                                                onClick={() => {}}
                                                isSelected
                                                isServiceArea={editedLocations.some(
                                                    (editedLocation: BusinessLocation) =>
                                                        editedLocation.id === location.id,
                                                )}
                                            >
                                                <LocationCardStyled
                                                    partnerName="google"
                                                    location={location}
                                                />
                                            </SummaryCard>
                                        </CardContainer>
                                    );
                                })}
                            </CardsContainer>
                        </SelectionPageResultsContainer>
                    </Scroller>
                </SelectionContentContainer>
            </SelectionContainer>

            {validLocations.length > 0 && (
                <FullButtonWrapper>
                    <FullButton
                        dataTrackId="import_locations_with_addresses_onboarding"
                        onClick={onImportClick}
                        icon={[FontAwesomeIconsPartooUsed.faArrowRight, IconPrefix.SOLID]}
                        shape="cube"
                        variant="primary"
                        size="large"
                    >
                        {displayGroupsSelect
                            ? t('create_user_assign_group_field')
                            : t('onboarding_selection_page_import', {
                                  count: validLocations.length,
                              })}
                    </FullButton>
                </FullButtonWrapper>
            )}

            <AccessGroupModal
                opened={showGroupModal}
                closeModal={() => setShowGroupModal(false)}
                value={[]}
                onChange={importSelectedLocations}
            />
        </>
    );
};

export default SummaryPage;
