import { RefObject, useEffect, useState } from 'react';

export const useScrollToRef = (
    ref: RefObject<HTMLDivElement>,
    targetRef: RefObject<HTMLElement>,
) => {
    const [isBoundary, setIsBoundary] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsBoundary(entry.isIntersecting);
            },
            { rootMargin: '20px' },
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref]);

    const handleScroll = () => {
        targetRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return { isBoundary, handleScroll };
};
