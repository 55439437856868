import { FC, useEffect, useRef, useState } from 'react';

import { IconButton, IconElement, MenuList, Option } from '@partoohub/ui';

import { createPortal } from 'react-dom';

import dataLayer from 'app/common/utils/dataLayer';

import { SidebarButtonWithMenuContainer, WrapperRef } from './SidebarButtonWithMenu.styled';

interface Props {
    icon: IconElement;
    onMenuClick: (value: string) => void;
    options: Array<Option>;
    dataTrack?: string;
}

export const SidebarButtonWithMenu: FC<Props> = ({ icon, options, dataTrack, onMenuClick }) => {
    const [isMenuShown, setIsMenuShown] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const toggleMenu = () => {
        setIsMenuShown(prevIsMenuShown => !prevIsMenuShown);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            isMenuShown &&
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target as Node)
        ) {
            toggleMenu();
        }
    };

    useEffect(() => {
        if (isMenuShown) {
            document.addEventListener('mousedown', handleClickOutside);
            dataLayer.pushDict('click_action_plus_button');
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuShown]);

    const onClick = (value: string) => {
        toggleMenu();
        onMenuClick(value);
    };

    // Not useful in this case, should be removed and use a more proper component than menu list
    const handleIsChecked = () => {
        return false;
    };

    const handleOnChange = (option: Option) => {
        onClick(option.value || '');
    };

    return (
        <SidebarButtonWithMenuContainer data-track={dataTrack} data-intercom-target={dataTrack}>
            <IconButton
                onClick={toggleMenu}
                dataTrackId="sidebar_plus"
                icon={icon}
                appearance="contained"
            />

            {isMenuShown &&
                createPortal(
                    <WrapperRef ref={wrapperRef}>
                        <MenuList
                            isChecked={handleIsChecked}
                            sections={[{ options: options }]}
                            onChange={handleOnChange}
                        />
                    </WrapperRef>,
                    document.body,
                )}
        </SidebarButtonWithMenuContainer>
    );
};
