import React from 'react';

import { useTranslation } from 'react-i18next';

import TextButton from 'app/common/designSystem/components/atoms/TextButton';

type Props = {
    setShowModal: (boolean) => void;
};

const SubscriptionsButton = ({ setShowModal }: Props) => {
    const { t } = useTranslation();

    return (
        <TextButton
            text={t('subscribe')}
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setShowModal(true);
            }}
            color={'primary'}
            icon={<i className="fas fa-plus" />}
            iconPositionRight
        />
    );
};

export default SubscriptionsButton;
