import { css } from '@emotion/react';
import { FontAwesomeIconsPartooUsed, IconPrefix, Image, Modal } from '@partoohub/ui';

import { ImageModalCloseButton } from './ImageUploaderRowHeader.styled';

interface ImageModalProps {
    imageUrl: string;
    showImage: boolean;
    setShowImage: (value: boolean) => void;
}
export const ImageModal = ({ imageUrl, showImage, setShowImage }: ImageModalProps) => {
    return (
        <Modal
            isOpen={showImage}
            closeModal={() => setShowImage(false)}
            placement="center"
            animation="fadeIn"
        >
            <ImageModalCloseButton
                dataTrackId="close_button_action"
                appearance="contained"
                variant="default"
                icon={[FontAwesomeIconsPartooUsed.faClose, IconPrefix.SOLID]}
                onClick={() => setShowImage(false)}
            />
            <Image
                src={imageUrl}
                css={css`
                    max-width: calc(100vw - 120px);
                    max-height: calc(100vh - 120px);
                `}
            />
        </Modal>
    );
};
