import { MutableRefObject, useRef, useState } from 'react';

import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { LiteralRating } from 'app/api/types/review';
import { ReviewAutoReplySettings } from 'app/api/types/reviewAutoReplySettings';
import { FormattedReplyTemplate } from 'app/api/types/reviewReplyTemplates';
import { ChoiceWithSubtitle } from 'app/api/types/user';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import {
    REVIEW_AUTO_REPLY_SELECTED_BUSINESSES,
    REVIEW_AUTO_REPLY_SETTINGS,
} from 'app/common/data/queryKeysConstants';

import useSaveSettingsMutation from './queries/useSaveSettingsMutation';

export type SaveSettingsHelpers = {
    errorBannerRef: MutableRefObject<HTMLDivElement | null>;
    hasTriedToActivate: boolean;
    checkIfCanActivate: () => boolean;
    getAutoCheckErrors: () => Array<string>;
    setRatingsForAutoCheck: (ratings: Array<ChoiceWithSubtitle<LiteralRating>>) => void;
    setHasTriedToActivate: (value: boolean) => void;
    save: () => void;
    saveAndActivate: (notifySuccess?: boolean) => void;
    saveAndDeactivate: (notifySuccess?: boolean) => void;
    isSaving: boolean;
};

const REQUIRED_TEMPLATE_PER_TYPE = 3;

type Props = {
    loadedSettings: ReviewAutoReplySettings | undefined;
    languages: Array<string>;
    selectedRatings: Array<ChoiceWithSubtitle<LiteralRating>>;
    templates: Array<FormattedReplyTemplate>;
    setSelectedTemplates: React.Dispatch<React.SetStateAction<FormattedReplyTemplate[]>>;
    setShowSuccessModal: (show: boolean) => void;
    forReviewsWithComment: boolean;
};

const useSaveSettingsHelpers = ({
    forReviewsWithComment,
    loadedSettings,
    languages,
    selectedRatings,
    templates,
    setSelectedTemplates,
    setShowSuccessModal,
}: Props) => {
    const { t } = useTranslation();
    // save ratings for which on the go check will be done
    const [ratingsForAutoCheck, setRatingsForAutoCheck] = useState<
        Array<ChoiceWithSubtitle<LiteralRating>>
    >([]);
    const queryClient = useQueryClient();
    const [hasTriedToActivate, setHasTriedToActivate] = useState(false);
    const errorBannerRef = useRef<HTMLDivElement | null>(null);

    const settingsId = loadedSettings?.id;

    const checkErrors = (ratings: Array<ChoiceWithSubtitle<LiteralRating>>) => {
        const errors: Array<string> = [];

        languages.forEach(language => {
            ratings.forEach(selectedRating => {
                const { value, label } = selectedRating;
                const templateCount = templates.filter(
                    template => template.ratings.includes(value) && template.language === language,
                ).length;

                if (templateCount < REQUIRED_TEMPLATE_PER_TYPE) {
                    errors.push(
                        t('auto_reply_missing_template_count', {
                            count: REQUIRED_TEMPLATE_PER_TYPE - templateCount,
                            language: t(language),
                            rating: t(`${label}_reviews`),
                        }),
                    );
                }
            });
        });
        return errors;
    };

    // Get errors for the auto check. This will only perform a partial check,
    // and ignore ratings that the user has just selected and not tried to activate yet.
    const getAutoCheckErrors = () => checkErrors(ratingsForAutoCheck);

    const checkIfCanActivate = () => {
        const errors = checkErrors(selectedRatings);
        setRatingsForAutoCheck(selectedRatings);
        return !!selectedRatings.length && !errors.length;
    };

    const filterTemplatesBySelectedRatings = () => {
        // Remove templates that do not comply with the remaining ratings
        const filteredTemplates = templates.filter(template =>
            selectedRatings.some(selectedRating => template.ratings.includes(selectedRating.value)),
        );
        setSelectedTemplates(filteredTemplates);
        return filteredTemplates;
    };

    const onFormSave = (notifySuccess = false) => {
        queryClient.invalidateQueries(REVIEW_AUTO_REPLY_SETTINGS);
        queryClient.invalidateQueries(REVIEW_AUTO_REPLY_SELECTED_BUSINESSES);
        if (notifySuccess) {
            toast.success(null, t('auto_reply_settings_saved'));
        }
    };

    const businessModalFilters = useBusinessModalFilters();
    const businessModalCount = useSelectedBusinessesCount();
    const businessFilter = businessModalCount ? businessModalFilters : undefined;
    const { mutate: saveSettings, isLoading: isSaving } = useSaveSettingsMutation();

    const save = () => {
        setRatingsForAutoCheck(selectedRatings);
        saveSettings(
            {
                forReviewsWithComment,
                settingsId,
                ratings: selectedRatings,
                templates: filterTemplatesBySelectedRatings(),
                businessFilter,
            },
            {
                onSuccess: () => {
                    onFormSave();
                    toast.success(null, t('auto_reply_settings_saved'));
                },
            },
        );
    };

    const saveAndDeactivate = (notifySuccess = false) => {
        setRatingsForAutoCheck(selectedRatings);
        saveSettings(
            {
                forReviewsWithComment,
                activate: false,
                settingsId,
                ratings: selectedRatings,
                templates: filterTemplatesBySelectedRatings(),
                businessFilter,
            },
            {
                onSuccess: () => onFormSave(notifySuccess),
            },
        );
    };

    const saveAndActivate = (notifySuccess = false) => {
        const isFirstActivation = !loadedSettings?.enabled_at;
        if (checkIfCanActivate()) {
            saveSettings(
                {
                    forReviewsWithComment,
                    activate: true,
                    settingsId,
                    ratings: selectedRatings,
                    templates: filterTemplatesBySelectedRatings(),
                    businessFilter,
                },
                {
                    onSuccess: () => {
                        if (isFirstActivation) setShowSuccessModal(true);
                        if (hasTriedToActivate) setHasTriedToActivate(false);
                        onFormSave(notifySuccess);
                    },
                },
            );
        } else {
            if (isFirstActivation) saveAndDeactivate(notifySuccess);
            if (!hasTriedToActivate) setHasTriedToActivate(true);
            errorBannerRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return {
        errorBannerRef,
        hasTriedToActivate,
        getAutoCheckErrors,
        checkIfCanActivate,
        setRatingsForAutoCheck,
        setHasTriedToActivate,
        save,
        saveAndActivate,
        saveAndDeactivate,
        isSaving,
    };
};

export default useSaveSettingsHelpers;
