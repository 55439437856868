import React from 'react';

import { Button, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { SpecificHourFormData } from 'app/api/types/business';
import { Holiday } from 'app/api/types/holidays';
import Collapsible from 'app/common/components/Collapsible';

import HolidayItem from './HolidayItem';

import {
    HolidaysGrid,
    HolidaysGridSeeMore,
    HolidaysSuggestionCollapsibleContainer,
} from './HolidaysSuggestionList.styled';

type Props = {
    holidays: Array<Holiday>;
    onChange: (specificHours: SpecificHourFormData) => void;
    headerCollapsed: boolean;
    seeMoreCollapsed: boolean;
    specificHours: SpecificHourFormData;
    onClick: () => void;
    scrollToRef: () => void;
};

const NUMBER_OF_HOLIDAYS_TO_DISPLAY = 10;

const HolidaysSuggestionList = ({
    holidays,
    onChange,
    headerCollapsed,
    seeMoreCollapsed,
    specificHours,
    onClick,
    scrollToRef,
}: Props) => {
    const { t } = useTranslation();
    const getHolidaysToDisplay = (seeMoreCollapsed: boolean): [Holiday[], Holiday[]] => {
        if (!seeMoreCollapsed) {
            return [holidays, []];
        }

        return [
            holidays.slice(0, NUMBER_OF_HOLIDAYS_TO_DISPLAY),
            holidays.slice(NUMBER_OF_HOLIDAYS_TO_DISPLAY),
        ];
    };

    const [holidaysToDisplay, holidaysToHide] = getHolidaysToDisplay(seeMoreCollapsed);

    return (
        <Collapsible collapsed={headerCollapsed} deps={[holidays]}>
            <HolidaysSuggestionCollapsibleContainer>
                <Text variant="bodyMRegular" color="secondary">
                    {t('bank_holidays__add_bank_holidays_description')}
                </Text>

                <HolidaysGrid collapsed={headerCollapsed}>
                    {holidaysToDisplay.map(holiday => (
                        <HolidayItem
                            key={`holiday_${holiday.date}`}
                            specificHours={specificHours}
                            onChange={onChange}
                            holiday={holiday}
                            scrollToRef={scrollToRef}
                        />
                    ))}
                </HolidaysGrid>

                {holidays.length > NUMBER_OF_HOLIDAYS_TO_DISPLAY && (
                    <HolidaysGridSeeMore>
                        <Button
                            dataTrackId={'see-more-holidays-button'}
                            appearance="text"
                            variant="secondary"
                            icon={
                                seeMoreCollapsed
                                    ? ['fa-solid fa-chevron-right']
                                    : ['fa-solid fa-chevron-up']
                            }
                            iconPosition="right"
                            onClick={onClick}
                        >
                            {seeMoreCollapsed
                                ? `${t('bank_holidays__see_more', {
                                      holidays_count: holidaysToHide.length,
                                  })}`
                                : t('bank_holidays__see_less')}
                        </Button>
                    </HolidaysGridSeeMore>
                )}
            </HolidaysSuggestionCollapsibleContainer>
        </Collapsible>
    );
};

export default HolidaysSuggestionList;
