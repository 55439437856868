import React from 'react';

import { DayString } from 'app/api/types';

import {
    OpenHourContainer,
    OpenHourContainerLeftRight,
} from 'app/common/components/OpenHour/OpenHour.styled';

import DayHeader from './DayHeader';
import HoursContent from './HoursContent';
import Toggles from './Toggles';

type Props = {
    disabled: boolean;
    day: DayString;
    dayValue: Array<string>;
    onChange: (dayValue: Array<string>) => void;
    applyDefaultHours?: () => void;
    hoursError: string;
};
const OpenHourDay = ({
    disabled,
    day,
    dayValue,
    onChange,
    applyDefaultHours,
    hoursError,
}: Props) => {
    return (
        <OpenHourContainer>
            <OpenHourContainerLeftRight>
                <DayHeader day={day} />
                <Toggles
                    disabled={disabled}
                    dayValue={dayValue}
                    onChange={onChange}
                />
            </OpenHourContainerLeftRight>

            <OpenHourContainerLeftRight>
                <HoursContent
                    disabled={disabled}
                    dayValue={dayValue}
                    onChange={onChange}
                    hoursError={hoursError}
                    applyDefaultHours={applyDefaultHours}
                />
            </OpenHourContainerLeftRight>
        </OpenHourContainer>
    );
};

export default OpenHourDay;
