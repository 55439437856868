import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import BusinessFiltersButton from 'app/pages/visibility/BusinessListV2/sections/BusinessFiltersSection/BusinessFiltersButton';
import BusinessSearch from 'app/pages/visibility/BusinessListV2/sections/BusinessFiltersSection/BusinessSearch';

import BusinessGroupsFilter from './BusinessFilters/BusinessGroupsFilter';
import BusinessOldGroupsFilter from './BusinessFilters/BusinessOldGroupsFilter';
import {
    BusinessFiltersContainer,
    BusinessFiltersLeftContainer,
} from './BusinessFiltersSection.styled';

const BusinessFiltersSection = () => {
    const enableNewGroups = useNewGroups();
    return (
        <BusinessFiltersContainer>
            <BusinessFiltersLeftContainer>
                <BusinessSearch />
                {enableNewGroups ? <BusinessGroupsFilter /> : <BusinessOldGroupsFilter />}
            </BusinessFiltersLeftContainer>
            <BusinessFiltersButton />
        </BusinessFiltersContainer>
    );
};

export default BusinessFiltersSection;
