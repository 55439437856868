import { PropsWithChildren, ReactNode } from 'react';

import { BodyContainer, BodyContent } from './Template.styled';
import { DesktopHeader } from '../DesktopHeader/DesktopHeader';
import { VisibilityContextProvider } from '../DesktopHeader/DesktopHeader.context';

interface TemplateProps {
    header?: ReactNode;
}

export const Template = ({ header, children }: PropsWithChildren<TemplateProps>) => {
    return (
        <BodyContainer>
            <VisibilityContextProvider>
                <DesktopHeader header={header} />
                <BodyContent>{children}</BodyContent>
            </VisibilityContextProvider>
        </BodyContainer>
    );
};
