import { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';

export const RouterContext = createContext({
    routes: [] as string[],
});

export const RouterProvider = ({ children }: PropsWithChildren) => {
    const { pathname, search } = useLocation();
    const [routes, setRoutes] = useState<string[]>([`${pathname}${search}`]);

    useEffect(() => {
        setRoutes(prevHistory => {
            const newHistory = [`${pathname}${search}`, ...prevHistory.slice(0, 9)]; // Limit to 10 items
            return newHistory;
        });
    }, [pathname, search]);

    const value = useMemo(() => ({ routes }), [routes]);

    return <RouterContext.Provider value={value}>{children}</RouterContext.Provider>;
};
