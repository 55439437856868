import React, { LegacyRef } from 'react';

import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { useClampText } from 'use-clamp-text';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessCountry from 'app/businessEditV2/hooks/business/useBusinessCountry';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useAddressSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useAddressSectionColor';
import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import AddressFormModal from 'app/businessEditV2/sections/AddressSection/AddressFormModal';
import { trackSelectBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import {
    AddressCard,
    AddressCardContainer,
    BusinessAddress,
    BusinessAddressContainer,
    BusinessInfos,
    BusinessName,
    ErrorStatusBar,
} from './BusinessAddressSection.styled';

const BusinessAddressSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const { t } = useTranslation();
    const {
        showModal,
        setShowModal,
        closeSafeModal,
        showUnsavedModal,
        setShowUnsavedModal,
        useDirtyUpdate,
    } = useUnsavedModal(SectionNames.ADDRESS);
    const { data: business } = useBusiness();
    const country = useBusinessCountry();
    const sectionColor = useAddressSectionColor();

    const businessName = business?.name ?? '';

    const [reference, { clampedText: businessNameClamped }] = useClampText({
        text: businessName,
        ellipsis: '...',
        lines: 2,
        debounceTime: 0,
    });

    const hasWarning =
        sectionColor === ColorState.MISSING_WARNING || sectionColor === ColorState.PRECHECK_ERRORS;

    const onClick = () => {
        setShowModal(true);
        trackSelectBusinessEditSection(SectionNames.ADDRESS, sectionColor);
    };

    return (
        <>
            <TooltipWrapper text={sectionColor === ColorState.DISABLED ? t('field_read_only') : ''}>
                <AddressCardContainer ref={ref}>
                    <AddressCard onClick={onClick}>
                        <BusinessInfos>
                            <BusinessName ref={reference as LegacyRef<HTMLDivElement>}>
                                {businessNameClamped}
                            </BusinessName>
                            <BusinessAddressContainer>
                                {hasWarning && <i className="fa-solid fa-circle-xmark" />}
                                <BusinessAddress>
                                    {business?.address_full}
                                    <br />
                                    {business?.zipcode} {business?.city}, {country}
                                </BusinessAddress>
                            </BusinessAddressContainer>
                        </BusinessInfos>
                        <i className="fa-regular fa-angle-right" />
                    </AddressCard>

                    {hasWarning && (
                        <ErrorStatusBar onClick={() => setShowModal(true)}>
                            <span>{t('business_edit_address_correct_address')}</span>
                            <span>
                                {t('business_edit_address_correct')}{' '}
                                <i className="fa-solid fa-arrow-right" />
                            </span>
                        </ErrorStatusBar>
                    )}
                </AddressCardContainer>
            </TooltipWrapper>

            {/* CSSTransition because the form is defined outside the FullScreen modal */}
            <CSSTransition in={showModal} timeout={300} unmountOnExit>
                <AddressFormModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    useDirtyUpdate={useDirtyUpdate}
                    closeModal={closeSafeModal}
                    showUnsavedModal={showUnsavedModal}
                    setShowUnsavedModal={setShowUnsavedModal}
                />
            </CSSTransition>
        </>
    );
});

export default BusinessAddressSection;
