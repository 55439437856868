import { FC } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { IconPrefix } from '@partoohub/ui';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';

const PageErrorPlaceholder: FC = () => {
    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };
    return (
        <NotFoundTemplate
            show
            imgSrc={illustrationElement}
            title="error_page_placeholder_title"
            subtitle="error_page_placeholder_subtitle"
            buttonText="refresh"
            handleClick={() => window.location.reload()}
            icon={['fa-redo', IconPrefix.SOLID]}
        />
    );
};

export default PageErrorPlaceholder;
