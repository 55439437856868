import {
    VISIBILITY_DIFFUSION_PATH,
    VISIBILITY_LOCATION_ADD_PATH,
    VISIBILITY_LOCATION_EDIT_PATH,
    VISIBILITY_LOCATION_PATH,
} from 'app/routing/routeIds';

export const pageSagas = {
    [VISIBILITY_LOCATION_ADD_PATH]: [],
    // Necessary to no load Business List/Edit Sagas
    [VISIBILITY_LOCATION_EDIT_PATH]: [],
    [VISIBILITY_LOCATION_PATH]: [],
    [VISIBILITY_DIFFUSION_PATH]: [],
};
