import { useGetQuery } from 'app/pages/visibility/BusinessListV2/hooks/queryParams/params/useGetQuery';

export const useGetBusinessQuery = (): { business_query?: string } => {
    const { query } = useGetQuery();
    return query
        ? {
              business_query: query,
          }
        : {};
};
