import { ChangeEventHandler, useEffect, useState } from 'react';

import { Chip, FontAwesomeIconsPartooUsed, Icon, IconButton, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import usePhotosUpload from 'app/businessEditV2/hooks/usePhotosUpload';
import { useImagesUploaderContext } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/hooks/useImagesUploaderContext';

import {
    ACCEPTED_FILES,
    MAX_FILE_SIZE,
    formatSizeUnits,
    preventCardOpen,
} from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/utils';

import { ImageEdit } from './ImageEdit';
import { ImageModal } from './ImageModal';
import { ImagePreview } from './ImagePreview';
import {
    ClickableRow,
    IconWrapper,
    ImageUploaderRowHeaderRight,
    TextWrapper,
} from './ImageUploaderRowHeader.styled';

type ImageUploaderRowHeaderProps = {
    imageIndex: number;
    toggleOpen: () => void;
};

export const ImageUploaderRowHeader = ({ imageIndex, toggleOpen }: ImageUploaderRowHeaderProps) => {
    const { t } = useTranslation();
    const [file, setFile] = useState<File | null>(null);
    const [showImage, setShowImage] = useState(false);
    const [imageErrorName, setImageErrorName] = useState('');
    const [imageError, setImageError] = useState('');
    const { images, textFields, replaceImage } = useImagesUploaderContext();
    const image = images[imageIndex];

    const nonEmptyFieldsCount = Object.values(image.texts).filter(text => !!text).length;
    const hasTextFields = !!textFields.length;

    const handleFileUpload: ChangeEventHandler<HTMLInputElement> = e => {
        const { files } = e.target;
        if (files?.length) {
            const file = files[0];
            if (file.size > MAX_FILE_SIZE) {
                setImageErrorName(file.name);
                setImageError(t('file_too_big', { maxSize: formatSizeUnits(MAX_FILE_SIZE) }));
                return;
            }
            if (!Object.keys(ACCEPTED_FILES).includes(file.type)) {
                setImageErrorName(file.name);
                setImageError(t('invalid_file_type'));
                return;
            }
            setFile(file);
        }
    };

    const { mutate: uploadPhotos, isLoading } = usePhotosUpload(im => {
        replaceImage(imageIndex, im.result.uploads[0], file?.size || 0, file?.name || '');
        setImageErrorName('');
        setImageError('');
    });

    useEffect(() => {
        if (file) {
            uploadPhotos([file]);
        }
    }, [file]);

    return (
        <>
            <ClickableRow
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                onClick={toggleOpen}
            >
                <IconWrapper onClick={preventCardOpen}>
                    <Icon icon={[FontAwesomeIconsPartooUsed.faBars]} color="secondary" />
                </IconWrapper>
                <ImagePreview
                    isLoading={isLoading}
                    image={image}
                    hasError={!!imageError}
                    setShowImage={setShowImage}
                    onFileUpload={handleFileUpload}
                />
                <ImageUploaderRowHeaderRight
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <TextWrapper collapsable={hasTextFields}>
                        <Text variant="bodyMSemibold" oneLine>
                            {!!imageError ? imageErrorName : image.name}
                        </Text>
                        {imageError ? (
                            <Text variant="bodySRegular" color="danger" oneLine>
                                {imageError}
                            </Text>
                        ) : (
                            <Text variant="bodySRegular" oneLine>
                                {formatSizeUnits(image.size)}
                            </Text>
                        )}
                    </TextWrapper>
                    <Stack direction="row" gap="16px" alignItems="center">
                        {hasTextFields && (
                            <Chip
                                dataTrackId={`visibility_location__form_custom_fields__chip__${imageIndex}`}
                                chipShape="cube"
                            >
                                {`${nonEmptyFieldsCount} / ${textFields.length} ${t('business_edit_custom_fields_type_images_uploader_required_fields')}`}
                            </Chip>
                        )}
                        <Stack direction="row" gap="0px">
                            <ImageEdit imageIndex={imageIndex} onFileUpload={handleFileUpload} />
                            {hasTextFields && (
                                <IconButton
                                    icon={[FontAwesomeIconsPartooUsed.faAngleDown]}
                                    dataTrackId={`visibility_location__form_custom_fields__expand__${imageIndex}`}
                                    appearance="outlined"
                                />
                            )}
                        </Stack>
                    </Stack>
                </ImageUploaderRowHeaderRight>
            </ClickableRow>
            {!imageError && !isLoading && (
                <ImageModal
                    imageUrl={image.url}
                    showImage={showImage}
                    setShowImage={setShowImage}
                />
            )}
        </>
    );
};
