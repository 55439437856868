import styled from '@emotion/styled';
import { Button } from '@partoohub/ui';

export const OverrideButton = styled(Button)`
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    padding: 16px;
    transition: all 0.2s ease-in-out;
    &:hover {
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.colors.default.initial};
    }
`;
