import { css } from '@emotion/react';
import { Stack } from '@partoohub/ui';

import {
    CardsSection,
    FilterSection,
    GraphSection,
    GraphSelectorSection,
    HeaderSection,
    InternalRankingSection,
} from './Sections';
import { PageContainer } from '../ReviewAnalytics/ReviewAnalytics.styled';
import { AnalyticsTabs } from '../VisibilityAnalyticsV2/components/AnalyticsTabs';

export const ReviewAnalyticsV2 = () => {
    return (
        <PageContainer>
            <HeaderSection />
            <AnalyticsTabs />
            <Stack>
                <FilterSection />
                <CardsSection />
                <Stack direction="row">
                    <GraphSelectorSection />
                    <Stack
                        css={css`
                            min-width: calc(75% - 16px);
                        `}
                    >
                        <GraphSection />
                        <InternalRankingSection />
                    </Stack>
                </Stack>
            </Stack>
        </PageContainer>
    );
};
