import styled from '@emotion/styled';

export const SettingsMenuListContainer = styled.div<{ isBelowTablet: boolean }>`
    position: sticky;
    top: -16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: ${({ theme }) => theme.colors.theme.background};
    border-radius: 8px;
    width: ${({ isBelowTablet }) => (isBelowTablet ? '100%' : '215px')};
    height: 100%;
    padding: 16px;
`;

export const SettingsMenuListContent = styled.div<{ isBelowTablet: boolean }>`
    width: ${({ isBelowTablet }) => (isBelowTablet ? '100%' : '183px')};
`;
