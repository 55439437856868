import { UseQueryOptions, useQuery } from 'react-query';

import { useSelector } from 'react-redux';

import { OrgLanguagesRequest, OrgLanguagesResponse } from 'app/api/types/org';
import api from 'app/api/v2/api_calls/camel';
import { ORG_LANGUAGES } from 'app/common/data/queryKeysConstants';

import { meSelector } from 'app/states/reducers';

export default function useOrgLanguages(
    params: OrgLanguagesRequest = {},
    options: UseQueryOptions<OrgLanguagesResponse> = {},
) {
    const { data: me } = useSelector(meSelector);
    const enabled = options.hasOwnProperty('enabled') ? options.enabled : true;

    return useQuery(
        [ORG_LANGUAGES, params],
        () => api.org.getBusinessesLanguages(me?.orgId, params),
        { ...options, enabled: !!me?.orgId && enabled },
    );
}
