import React, { FC, PropsWithChildren } from 'react';

import { Icon } from '@partoohub/ui';
import { Draggable, DraggableProps } from 'react-beautiful-dnd';

import { DraggableItem } from 'app/common/components/DragAndDrop/DraggableWrapper/DraggableWrapper.styled';

interface Props {
    index: number;
    draggableId: string;
}

// Have to do this because Draggable typing sucks
// https://github.com/atlassian/react-beautiful-dnd/issues/2401
const TypedDraggable = Draggable as unknown as FC<DraggableProps>;

export const DraggableWrapper = ({
    index,
    draggableId,
    children,
}: PropsWithChildren<Props>) => {
    return (
        <TypedDraggable draggableId={draggableId} index={index}>
            {draggableRef => (
                <DraggableItem
                    {...draggableRef.draggableProps}
                    {...draggableRef.dragHandleProps}
                    ref={draggableRef.innerRef}
                >
                    <Icon icon={['fa-grip-dots-vertical']} />
                    {children}
                </DraggableItem>
            )}
        </TypedDraggable>
    );
};
