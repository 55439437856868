import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const GoogleMapsPlaceHolderMain = styled.div`
    background-color: ${greyColorObject.initial};
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    position: absolute;
    z-index: 2;
    i {
        margin-bottom: 8px;
        font-size: 36px;
    }
`;

export const GoogleMapsPlaceHolderMessage = styled.div`
    padding: 0 32px;
    text-align: center;
`;
