import { FC, ReactNode, createContext, useContext, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { V2OrgData } from 'app/api/types/org';
import { V2UserData } from 'app/api/types/user';
import LoadingView from 'app/common/components/LoadingView';
import useMe from 'app/common/hooks/queries/useMeUncamel';

import { forceGetMe } from 'app/common/sagas/getMeSaga';

import useOrganization from '../hooks/queries/useOrganization';

export type AppDataContextType = {
    me: V2UserData;
    organization: V2OrgData;
};

export const AppDataContext = createContext<AppDataContextType | undefined>(undefined);

export const useAppDataContext = () => {
    const context = useContext(AppDataContext);
    if (context === undefined) {
        throw new Error('useAppDataContext must be used within AppDataProvider');
    }
    return context;
};

type AppDataProviderType = {
    children: ReactNode;
};

export const AppDataProvider: FC<AppDataProviderType> = ({ children }) => {
    const dispatch = useDispatch();

    const { data: me } = useMe();
    const { data: organization } = useOrganization(me?.org_id ?? 0, !!me);

    const isDataAvailable = me && organization;

    useEffect(() => {
        if (me) {
            // Set me and org in the store, do mandatory stuff on login
            // Should be killed with fire once all pages don't use sagas anymore
            dispatch(forceGetMe());
        }
    }, [me]);

    const value = useMemo(
        () => (isDataAvailable ? { me, organization } : undefined),
        [me, organization, isDataAvailable],
    );

    if (!value) {
        return <LoadingView />;
    }

    return <AppDataContext.Provider value={value}>{children}</AppDataContext.Provider>;
};
