export enum ApiKeysQueryKeys {
    TYPE = 'type',
    STATUS = 'status',
    QUERY = 'query',
}

export enum ApiKeyTypeEnum {
    USER = 'user',
    BOT = 'bot',
}

export enum ApiKeyStatusEnum {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum ApiKeysQueryParams {
    FORM = 'form',
    CREATE = 'create',
}
