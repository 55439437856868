import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { REVIEW_USER_REPORT_PREFERENCES } from 'app/common/data/queryKeysConstants';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useUserReportPreferences = () => {
    const { data: me } = useMe();

    const query = useQuery(
        [REVIEW_USER_REPORT_PREFERENCES, me?.id],
        () => api.user.getUserReportPreferences(me?.id ?? ''),
        { enabled: !!me },
    );

    return [query.data, query] as const;
};
