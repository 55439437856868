import { Active, Over } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import env from 'app/common/services/getEnvironment';
import { CF_LIST_TYPE } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useCustomFieldDnDLogic';
import {
    CUSTOM_FIELD_SECTION_TYPE,
    CUSTOM_FIELD_TYPE,
    CustomFieldListType,
    CustomFieldOrSectionType,
    CustomFieldSectionType,
    CustomFieldType,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/typing';

export const debugLog = (...data: any[]) => {
    if (env.isDev()) {
        console.log(...data);
    }
};

export const getSelect = (
    cfs: CustomFieldListType,
    id: string | null,
): CustomFieldOrSectionType | null => {
    if (!id) {
        return null;
    }

    if (id.startsWith('section_')) {
        return cfs.find(cf => cf.type === CUSTOM_FIELD_SECTION_TYPE && cf.dndId === id) ?? null;
    }

    if (id.startsWith('cf_')) {
        for (const cf of cfs) {
            if (cf.type === CUSTOM_FIELD_TYPE) {
                if (cf.dndId === id) {
                    return cf;
                }
            } else if (cf.type === CUSTOM_FIELD_SECTION_TYPE) {
                const field = cf.customFields.find(cfi => id === 'cf_' + cfi.id);
                if (field) {
                    return {
                        dndId: id,
                        type: CUSTOM_FIELD_TYPE,
                        obj: field,
                    };
                }
            }
        }
    }

    return null;
};

export const checkExternalToExternal = (
    activeCf: CustomFieldOrSectionType,
    active: Active,
    over: Over | null,
): boolean => {
    return (
        activeCf.type === CUSTOM_FIELD_SECTION_TYPE ||
        (active.data.current?.sortable?.containerId === CF_LIST_TYPE &&
            over?.data.current?.sortable?.containerId === CF_LIST_TYPE)
    );
};

export const checkInternalToSameInternal = (
    activeCf: CustomFieldOrSectionType,
    overCf: CustomFieldOrSectionType,
    active: Active,
    over: Over | null,
): boolean => {
    return (
        activeCf.type === CUSTOM_FIELD_TYPE &&
        overCf.type === CUSTOM_FIELD_TYPE &&
        active.data.current?.sortable.containerId != CF_LIST_TYPE &&
        active.data.current?.sortable.containerId === over?.data.current?.sortable.containerId
    );
};

export const checkInternalToOtherInternal = (
    activeCf: CustomFieldOrSectionType,
    overCf: CustomFieldOrSectionType,
    active: Active,
    over: Over | null,
    isSectionOpened: boolean,
): boolean => {
    return (
        activeCf.type == CUSTOM_FIELD_TYPE &&
        overCf.type === CUSTOM_FIELD_TYPE &&
        active.data.current?.sortable.containerId !== CF_LIST_TYPE &&
        over?.data.current?.sortable.containerId !== CF_LIST_TYPE &&
        active.data.current?.sortable.containerId !== over?.data.current?.sortable.containerId &&
        isSectionOpened
    );
};

export const checkInternalToExternal = (
    activeCf: CustomFieldOrSectionType,
    active: Active,
    over: Over | null,
): boolean => {
    return (
        activeCf.type === CUSTOM_FIELD_TYPE &&
        active.data.current?.sortable.containerId !== CF_LIST_TYPE &&
        over?.data.current?.sortable.containerId === CF_LIST_TYPE
    );
};

export const checkExternalToInternal = (
    activeCf: CustomFieldOrSectionType,
    overCf: CustomFieldOrSectionType,
    active: Active,
    over: Over | null,
    isSectionOpened: boolean,
): boolean => {
    return (
        activeCf.type === CUSTOM_FIELD_TYPE &&
        active.data.current?.sortable.containerId === CF_LIST_TYPE &&
        overCf.type === CUSTOM_FIELD_TYPE &&
        over?.data.current?.sortable.containerId !== CF_LIST_TYPE &&
        isSectionOpened
    );
};

export const handleExternalToExternal = (
    cpCfs: CustomFieldListType,
    active: Active,
    over: Over | null,
    setCfs: (cfs: CustomFieldListType) => void,
    setOrderHasChanged: (orderHasChanged: boolean) => void,
) => {
    debugLog('External to external');

    const oldIndex = cpCfs.findIndex(cf => cf.dndId === active.id);
    const newIndex = cpCfs.findIndex(cf => cf.dndId === over?.id);

    if (oldIndex === -1 || newIndex === -1) {
        return;
    }

    setCfs(arrayMove(cpCfs, oldIndex, newIndex));
    setOrderHasChanged(true);
};

export const handleExternalToEmptySection = (
    cpCfs: CustomFieldListType,
    activeCf: CustomFieldType,
    overCf: CustomFieldSectionType,
    active: Active,
    setCfs: (cfs: CustomFieldListType) => void,
    setOrderHasChanged: (orderHasChanged: boolean) => void,
    setSectionOpened: (sectionId: number, isOpened: boolean) => void,
) => {
    debugLog('External to internal empty section');

    const cpCfs2 = cpCfs.filter(cf => cf.dndId !== active.id);
    overCf.customFields.push({
        ...activeCf.obj,
        section_id: overCf.obj.id,
    });

    setCfs(cpCfs2);
    setOrderHasChanged(true);

    // Open the section that was previously empty
    setSectionOpened(overCf.obj.id, true);
};

export const handleInternalToSameInternal = (
    cpCfs: CustomFieldListType,
    active: Active,
    over: Over | null,
    setCfs: (cfs: CustomFieldListType) => void,
    setOrderHasChanged: (orderHasChanged: boolean) => void,
) => {
    debugLog('Internal to same internal');

    const section = cpCfs.find(
        cf =>
            cf.type === CUSTOM_FIELD_SECTION_TYPE &&
            cf.dndId === active.data.current?.sortable.containerId,
    ) as CustomFieldSectionType;

    if (!section) {
        return;
    }

    const oldIndex = section.customFields.findIndex(cf => active.id === 'cf_' + cf.id);
    const newIndex = section.customFields.findIndex(cf => over?.id === 'cf_' + cf.id);

    if (oldIndex === -1 || newIndex === -1) {
        return;
    }

    section.customFields = arrayMove(section.customFields, oldIndex, newIndex);
    setCfs(cpCfs);
    setOrderHasChanged(true);
};

export const handleInternalToOtherInternal = (
    cpCfs: CustomFieldListType,
    active: Active,
    over: Over | null,
    setCfs: (cfs: CustomFieldListType) => void,
    setOrderHasChanged: (orderHasChanged: boolean) => void,
) => {
    debugLog('Internal to other internal');

    const fromSection = cpCfs.find(
        cf => cf.dndId === active.data.current?.sortable.containerId,
    ) as CustomFieldSectionType;
    const toSection = cpCfs.find(
        cf => cf.dndId === over?.data.current?.sortable.containerId,
    ) as CustomFieldSectionType;

    if (!fromSection || !toSection) {
        return;
    }

    const field = fromSection.customFields.find(cf => active.id === 'cf_' + cf.id);
    if (!field) {
        return;
    }

    fromSection.customFields = fromSection.customFields.filter(cf => active.id !== 'cf_' + cf.id);
    toSection.customFields.push({
        ...field,
        section_id: toSection.obj.id,
    });

    setCfs(cpCfs);
    setOrderHasChanged(true);
};

export const handleInternalToExternal = (
    cpCfs: CustomFieldListType,
    active: Active,
    setCfs: (cfs: CustomFieldListType) => void,
    setOrderHasChanged: (orderHasChanged: boolean) => void,
) => {
    debugLog('Internal to external');

    const section = cpCfs.find(
        cf => cf.dndId === active.data.current?.sortable.containerId,
    ) as CustomFieldSectionType;
    if (!section) {
        return;
    }

    const field = section.customFields.find(cf => 'cf_' + cf.id === active.id);
    if (!field) {
        return;
    }

    section.customFields = section.customFields.filter(cf => 'cf_' + cf.id !== active.id);
    cpCfs.push({
        obj: {
            ...field,
            section_id: null,
        },
        dndId: 'cf_' + field.id,
        type: CUSTOM_FIELD_TYPE,
    });

    setCfs(cpCfs);
    setOrderHasChanged(true);
};

export const handleExternalToInternal = (
    cpCfs: CustomFieldListType,
    active: Active,
    over: Over | null,
    setCfs: (cfs: CustomFieldListType) => void,
    setOrderHasChanged: (orderHasChanged: boolean) => void,
) => {
    debugLog('External to internal');

    const toSection = cpCfs.find(
        cf => cf.dndId === over?.data.current?.sortable.containerId,
    ) as CustomFieldSectionType;
    if (!toSection) {
        return;
    }

    const field = cpCfs.find(cf => cf.dndId === active.id) as CustomFieldType;
    if (!field) {
        return;
    }

    const cpCfs2 = cpCfs.filter(cf => cf.dndId !== active.id);
    toSection.customFields.push({
        ...field.obj,
        section_id: toSection.obj.id,
    });

    setCfs(cpCfs2);
    setOrderHasChanged(true);
};
