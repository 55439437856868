import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { CITIES } from 'app/common/data/queryKeysConstants';

export default function useCities(query = '') {
    const { data: queryCities } = useQuery([CITIES, { query }], () =>
        api.cities.search(query),
    );

    const { data: topCities } = useQuery([CITIES, 'top'], () =>
        api.cities.getTopCities(),
    );

    const cities = query ? queryCities : topCities;

    return (
        cities?.map(city => ({
            label: city.city,
            value: city.city,
        })) ?? []
    );
}
