import { FC } from 'react';

import { Button, FontAwesomeIconsPartooUsed, Icon, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import ConnectionButtonLogo from 'app/businessConnection/components/commonOld/ConnectionButtonLogo';
import { Partner, useIsConnectedToPartner } from 'app/businessEditV2/hooks/useIsConnectedToPartner';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import { InnerTextButtonContainer } from './CTAButton.styled';

interface CTAButtonProps {
    partner: Partner;
    connectionsPageLink: string;
}

const CTAButton: FC<CTAButtonProps> = ({ partner, connectionsPageLink }) => {
    const { t } = useTranslation();
    const isConnected = useIsConnectedToPartner(partner);
    const navigate = useNavigate();

    const onClick = () => {
        navigate(connectionsPageLink);
    };

    const partnerLogo = (
        <ConnectionButtonLogo partnerName={partner} loading={false} disabled={false} />
    );
    return (
        <TooltipWrapper position="bottom-end" text={isConnected ? t('pam_status_connected') : ''}>
            {isConnected ? (
                <Button
                    appearance="outlined"
                    onClick={onClick}
                    shape="cube"
                    size="medium"
                    variant="success"
                    icon={partnerLogo}
                    dataTrackId="edit_business_partner_button--connected"
                >
                    <InnerTextButtonContainer variant="bodyMBold" as="span" color="success">
                        {t('pam_status_connected')}
                        <Icon
                            icon={[FontAwesomeIconsPartooUsed.faCheck, IconPrefix.REGULAR]}
                            color="success"
                        />
                    </InnerTextButtonContainer>
                </Button>
            ) : (
                <Button
                    appearance="outlined"
                    onClick={onClick}
                    shape="cube"
                    size="medium"
                    variant="secondary"
                    icon={partnerLogo}
                    dataTrackId="edit_business_partner_button--not-connected"
                >
                    {t('connection_required')}
                </Button>
            )}
        </TooltipWrapper>
    );
};

export default CTAButton;
