import { KeyboardEvent, useEffect, useState } from 'react';

import { Button, TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { KeyCode } from 'app/common/types/keyboard';
import useCreateGroup from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/hooks/groups/useCreateGroup';
import useSection from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/hooks/sections/useSection';
import { GroupsSettingsQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';
import groupsTracking from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/groupsTracking';

import {
    ModalButtons,
    ModalDescription,
    ModalSectionName,
    ModalTitle,
    SmallModalContainer,
} from '../../GroupsSettingsModals.styled';

type Props = {
    closeModal: () => void;
};

const CreateGroupContent = ({ closeModal }: Props) => {
    const { t } = useTranslation();
    const { data: me } = useMe();

    const [sectionId] = useStateQueryParams(GroupsSettingsQueryKeys.CREATE);

    const { data: section } = useSection(sectionId);
    const [groupName, setGroupName] = useState<string>('');

    useEffect(() => {
        if (section && !section.can_edit) {
            closeModal();
        }
    }, [section?.can_edit]);

    const createGroup = useCreateGroup(sectionId, () => {
        closeModal();
        groupsTracking.clickGroupCreation(me?.role);
    });

    // @ts-ignore
    const error = createGroup.error?.response?.data?.errors?.json?.name;

    const createGroupAction = () => {
        createGroup.mutate(groupName);
    };

    const handlePressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyCode.Enter) {
            createGroupAction();
        }
    };
    return (
        <SmallModalContainer>
            {section?.name && <ModalSectionName>{section.name}</ModalSectionName>}
            <ModalTitle>🗂 {t('group_modal_title')}</ModalTitle>
            <ModalDescription>{t('group_modal_subtitle')}</ModalDescription>
            <TextInput
                dataTrackId={groupName}
                value={groupName}
                onChange={value => {
                    setGroupName(value ?? '');
                }}
                label={t('group_modal_field')}
                error={error}
                notice={error ? t(error) : ''}
                onKeyUp={handlePressEnter}
                autoFocus
            />
            <ModalButtons>
                <Button
                    appearance="outlined"
                    variant="secondary"
                    dataTrackId="create_groupe_cancel"
                    onClick={closeModal}
                >
                    {t('cancel')}
                </Button>
                <Button
                    dataTrackId="create_groupe_create"
                    variant="primary"
                    onClick={createGroupAction}
                    disabled={!groupName}
                >
                    {t('group_modal_create_button')}
                </Button>
            </ModalButtons>
        </SmallModalContainer>
    );
};

export default CreateGroupContent;
