import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

const AUDIO_UPLOAD_URI = 'audio/upload';

interface UploadAudioResponse {
    status: 'success' | 'error';
    url: string;
}

class MessagingCallDeflectionApiCalls extends ApiResourceBaseClass {
    static uploadAudio(audio_file: File): Promise<UploadAudioResponse> {
        const formData = new FormData();
        formData.append('audio_file', audio_file);
        return MessagingCallDeflectionApiCalls.post(
            AUDIO_UPLOAD_URI,
            formData,
        ).then(({ data }) => data);
    }
}

export default MessagingCallDeflectionApiCalls;
