/* eslint-disable indent */
// (it seems there are conflicts between ident and react/jsx-indent eslint rules)
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { HEX_COLORS, convertToRgba } from '@partoohub/branding';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';

import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useMe from 'app/common/hooks/queries/useMe';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { ConsumerIconWithPartner } from 'app/reviewManagement/common/icon/consumerIcon';
import { useConversationRead } from 'app/reviewManagement/messaging/hooks/useConversationRead';
import { decrementMessageCount } from 'app/reviewManagement/messaging/services/decrementMessageCount';
import { lastMessageContent } from 'app/reviewManagement/messaging/services/getLastMessageContent';
import { CONVERSATIONS_MESSAGING_PATH } from 'app/routing/routeIds';
import {
    AssignFilterOption,
    Conversation,
    MessagingPartnerEnum,
    StatusFilterOption,
    URLParams,
} from 'app/states/messaging';
import FlexContainer from 'app/styles/utils/flexbox';

import { AssignmentIcon } from './AssignmentIcon';
import {
    BusinessInfo,
    ConsumerInfoAndMessageContainer,
    ConsumerName,
    Content,
    ConversationCardContainer,
    LastMessage,
    MainInfo,
} from './ConversationCard.styled';
import { MessagingContext } from '../../MessagingContext';
import { updateConversation } from '../../services/handleConversationsCache';
import { DurationAnchor } from '../DurationAnchor';

dayjs.extend(utc);

// if a user didn't respond to a consumer message after 12 hours, an SLA warning with time left is displayed
const timeBeforeWarning = 43200000;
// if a user didn't respond to a consumer message after 24 hours, an SLA 'late' is displayed
const timeBeforeTimeout = 86400000;
const timestampFormat = 'YYYY-MM-DD HH:mm:ssZ';
// We refresh the conversation Card duration every min
const REFRESH_TIMER_DELAY = 60 * 1000;

export type ConversationCardProps = {
    conversation: Conversation;
    isActive: boolean;
    isSidebarCollapsed: boolean;
    shouldAutoScrollToCard: boolean;
    setShouldAutoScroll: (shouldAutoScroll: boolean) => void;
};

export const ConversationCard = ({
    conversation,
    isActive,
    isSidebarCollapsed,
    shouldAutoScrollToCard,
    setShouldAutoScroll,
}: ConversationCardProps) => {
    const convCardRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { setActiveConversation } = useContext(MessagingContext);
    const { data: me } = useMe();
    const readConversation = useConversationRead();

    const [statusFilter] = useStateQueryParams<StatusFilterOption>(URLParams.STATUS);
    const [assignFilter] = useStateQueryParams<AssignFilterOption>(URLParams.ASSIGN);
    const businessFilters = useBusinessModalFilters();

    const isClosed = conversation.is_expired || conversation.is_solved;

    const onClickCard = useCallback(() => {
        if (!isActive) {
            setActiveConversation(conversation);
            navigate(`${CONVERSATIONS_MESSAGING_PATH}?conversationId=${conversation.id}`);
        }
    }, [conversation, isActive]);

    useEffect(() => {
        if (convCardRef.current && isActive && shouldAutoScrollToCard) {
            convCardRef.current.scrollIntoView({ behavior: 'smooth' });
            setShouldAutoScroll(false);
        }
    }, [isActive, shouldAutoScrollToCard, setShouldAutoScroll]);

    useEffect(() => {
        /**
         * A shadow user cannot set a conversation as read
         * We have to wait for stats fetching to be finished before
         * setting a conversation as Read to avoid chaotic race conditions
         */
        if (isActive && !conversation.is_read) {
            if (!me?.shadowUserId) {
                readConversation.mutate(conversation.id);
            } else {
                // Only for connect as purpose to mock read behavior
                if (!isClosed) {
                    decrementMessageCount(businessFilters);
                }
            }
            const updatedConversation = {
                ...conversation,
                is_read: true,
            };
            updateConversation(updatedConversation, statusFilter, assignFilter, businessFilters);
        }
    }, [isActive, conversation]);

    return (
        <ConversationCardContainer
            onMouseDown={onClickCard}
            isActive={isActive}
            isSidebarCollapsed={isSidebarCollapsed}
            ref={convCardRef}
        >
            <Content
                isActive={isActive}
                activeHoverBackground={convertToRgba(HEX_COLORS.primary, 0.24)}
            >
                <FlexContainer flexDirection="row" justifyContent="space-between">
                    <MainInfoSection conversation={conversation} />
                    <SecondaryInfoSection conversation={conversation} />
                </FlexContainer>
            </Content>
        </ConversationCardContainer>
    );
};

interface ConversationProps {
    conversation: Conversation;
}

// Consumer name, business info, last message
const MainInfoSection = ({ conversation }: ConversationProps) => {
    const { t } = useTranslation();

    const consumerName =
        conversation.consumer_name ||
        (conversation.consumer_phone_number
            ? formatPhoneNumberIntl(conversation.consumer_phone_number)
            : t('messaging_conversation_consumer_name_unknown'));

    const businessInfo =
        conversation.display_name ||
        `${conversation.business.name} - ${conversation.business.city}, ${conversation.business.zipcode}`;

    return (
        <MainInfo>
            <ConsumerIconWithPartner conversation={conversation} />
            <ConsumerInfoAndMessageContainer>
                <ConsumerName variant="bodyMSemibold" as="span" color={HEX_COLORS.blackberry}>
                    {consumerName}
                </ConsumerName>
                <BusinessInfo variant="bodySSemibold" as="span" color={HEX_COLORS.secondary}>
                    {businessInfo}
                </BusinessInfo>
                <LastMessage variant="bodyMRegular" as="span" color={HEX_COLORS.secondary}>
                    {lastMessageContent(conversation, t)}
                </LastMessage>
            </ConsumerInfoAndMessageContainer>
        </MainInfo>
    );
};

// SLA, assigned initials
const SecondaryInfoSection = ({ conversation }: ConversationProps) => {
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        setTimer(0);
        const interval = setInterval(() => {
            setTimer(prevTimer => prevTimer + 1);
        }, REFRESH_TIMER_DELAY);
        return () => {
            clearInterval(interval);
        };
    }, [conversation.last_message]);

    const currentTimeUtc = dayjs().utc();
    const firstMessageDateUtc = dayjs(conversation.first_message_date, timestampFormat);
    const timeSinceFirstMessage = dayjs
        .duration(currentTimeUtc.diff(firstMessageDateUtc))
        .asMilliseconds();

    const lastMessageDateUtc = dayjs(conversation.last_message.date, timestampFormat);
    const timeSinceLastMessage =
        dayjs.duration(currentTimeUtc.diff(lastMessageDateUtc)).asMilliseconds() +
        60 * 1000 * timer;

    const isClosed = conversation.is_expired || conversation.is_solved;
    const shouldDisplayDot =
        !conversation.has_one_user_message &&
        timeSinceFirstMessage > timeBeforeWarning &&
        !isClosed;

    return (
        <FlexContainer flexDirection="column" justifyContent="space-between" alignItems="flex-end">
            {conversation.last_message &&
                conversation.messaging_partner !== MessagingPartnerEnum.DELIGHT && (
                    <DurationAnchor
                        anchorDuration={
                            !shouldDisplayDot ? timeSinceLastMessage : timeSinceFirstMessage
                        }
                        shouldDisplayDot={shouldDisplayDot}
                        timeBeforeTimeout={timeBeforeTimeout}
                        isRead={conversation.is_read}
                    />
                )}
            <AssignmentIcon
                isAutomated={conversation.is_automated}
                assignedUserId={conversation.assigned_user_id}
            />
        </FlexContainer>
    );
};
