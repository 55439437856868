import styled from '@emotion/styled';

export const PostContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: 50px 100px minmax(80px, 1fr) 40px;
    grid-template-areas:
        'photo photo photo'
        'photo photo photo'
        'description description description'
        'partner partner partner';
    transition: all ease-in-out 0.2s;
`;
