import { Control, useController } from 'react-hook-form';

import {
    LocaleTimeField,
    convert24hTimeToLocaleFormat,
} from 'app/common/components/LocaleTimeField';

interface Props {
    control: Control<any>;
    name: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    error?: boolean;
}

export const ControlledHourField = ({
    control,
    name,
    label,
    placeholder = convert24hTimeToLocaleFormat('09:00'),
    disabled = false,
    error,
}: Props) => {
    const {
        field: { ref, value, onChange },
    } = useController({ name, control });

    return (
        <LocaleTimeField
            ref={ref}
            dataTrackId={name}
            disabled={disabled}
            label={label}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            error={error}
        />
    );
};
