import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import {
    BUSINESSES_CITIES,
    BUSINESSES_GROUPS,
    BUSINESSES_QUERIES,
    EXCLUDED_BUSINESSES,
    INCLUDED_BUSINESSES,
} from 'app/common/data/routeIds';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import { BusinessServicesApiKey } from 'app/pages/visibility/BusinessListV2/hooks/queryParams/params/useGetProductFilter';

export default function useBusinessConnectionSearchQueryParams(forSearchCompanion = false) {
    const isNewGroups = useNewGroups();
    const [searchParams] = useSearchParams();
    const queryParamsToApiParams = forSearchCompanion
        ? {
              [INCLUDED_BUSINESSES]: 'business__in',
              [EXCLUDED_BUSINESSES]: 'business__notin',
              [BUSINESSES_QUERIES]: 'business_query',
              [BUSINESSES_CITIES]: 'business_city',
              [BUSINESSES_GROUPS]: isNewGroups ? 'groups' : 'business_group__in',
              [BusinessServicesApiKey.PRESENCE]: BusinessServicesApiKey.PRESENCE,
              status: 'status',
          }
        : {
              [INCLUDED_BUSINESSES]: 'business__in',
              [EXCLUDED_BUSINESSES]: 'business__notin',
              [BUSINESSES_QUERIES]: 'query',
              [BUSINESSES_CITIES]: 'city',
              [BUSINESSES_GROUPS]: isNewGroups ? 'groups' : 'group_id__in',
              [BusinessServicesApiKey.PRESENCE]: BusinessServicesApiKey.PRESENCE,
              status: 'status',
          };

    return useMemo(() => {
        const businessSearchQueryParams = {};
        searchParams.forEach((value, param) => {
            if (queryParamsToApiParams.hasOwnProperty(param)) {
                businessSearchQueryParams[queryParamsToApiParams[param]] = value;
            }
        });

        return businessSearchQueryParams;
    }, [searchParams]);
}
