import { useLocation } from 'react-router-dom';

import { SETTINGS_PATH } from 'app/routing/routeIds';

/**
 * Hook to check if the current page is a setting page
 */
export const useIsSetting = (): boolean => {
    const { pathname } = useLocation();
    return pathname.startsWith(SETTINGS_PATH);
};
