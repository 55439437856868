import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useLocation } from 'react-router-dom';

import api from 'app/api/v2/api_calls';
import { CONVERSATION_STARTERS } from 'app/common/data/queryKeysConstants';
import { formatPlaceholderToMention } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/services/utils';
import { ConversationStarter } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';

export function useConversationStarter() {
    const { search } = useLocation();
    const { t } = useTranslation();
    const conversationStarterId = qs.parse(search).conversationStarterId;
    return useQuery(
        [CONVERSATION_STARTERS, conversationStarterId],
        () => api.conversationStarters.getConversationStarter(conversationStarterId),
        {
            select: (data: ConversationStarter): ConversationStarter => ({
                ...data,
                welcome_message: formatPlaceholderToMention(data.welcome_message ?? '', t),
                offline_message: formatPlaceholderToMention(data.offline_message ?? '', t),
            }),
        },
    );
}
