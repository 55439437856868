import { useState } from 'react';

import { IconButton, IconPrefix, List, Stack } from '@partoohub/ui';

import { IconButtonProps } from '@partoohub/ui/dist/components/IconButton/IconButton.types';
import { useTranslation } from 'react-i18next';

import { FeedbackFormData } from 'app/api/types/feedbackForm';
import { ORG_ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { FEEDBACK_MANAGEMENT_BASE_URL } from 'app/config';

import { stringifyDate } from 'app/utils/dates';

import { FeedbackFormPreviewModal } from './FeedbackFormPreviewModal';
import { useGeneratePreviewUrl } from './hooks';

const ActionIconButton = ({
    iconName,
    ...props
}: Omit<IconButtonProps, 'icon' | 'dataTrackId'> & { iconName: string }) => (
    <IconButton
        dataTrackId=""
        appearance="outlined"
        tooltipPlacement="top"
        icon={[iconName, IconPrefix.REGULAR]}
        {...props}
    />
);

interface Props {
    form: FeedbackFormData;
}

export const FeedbackFormActions = ({ form }: Props) => {
    const { t } = useTranslation();
    const { data: me } = useMe();
    const [showPreviewModal, setShowPreviewModal] = useState(false);

    const canCopyFormLink = me?.role === ORG_ADMIN;
    const feedbackFormLink = `${FEEDBACK_MANAGEMENT_BASE_URL}/${form.id}?email=email&store_code=store_code&firstname=firstname&lastname=lastname&date=${stringifyDate(new Date())}`;
    const [copyLinkTooltip, setCopyLinkTooltip] = useState(
        t('feedback_form_list__action_copy_link'),
    );
    const onCopyLink = () => {
        navigator.clipboard.writeText(feedbackFormLink);

        setCopyLinkTooltip(t('feedback_form_list__action_link_copied'));
        setTimeout(() => {
            setCopyLinkTooltip(t('feedback_form_list__action_copy_link'));
        }, 5000);
    };

    const previewUrl = useGeneratePreviewUrl(feedbackFormLink);

    return (
        <>
            <FeedbackFormPreviewModal
                previewUrl={previewUrl}
                isOpen={showPreviewModal}
                onClose={() => setShowPreviewModal(false)}
            />

            <List.Cell>
                <Stack direction="row" justifyContent="flex-end">
                    <ActionIconButton
                        iconName="fa-eye"
                        tooltip={t('feedback_form_list__action_preview')}
                        disabled={!previewUrl}
                        onClick={() => {
                            if (previewUrl) setShowPreviewModal(true);
                        }}
                    />
                    {canCopyFormLink && (
                        <ActionIconButton
                            iconName="fa-copy"
                            tooltip={copyLinkTooltip}
                            onClick={onCopyLink}
                        />
                    )}
                </Stack>
            </List.Cell>
        </>
    );
};
