import { SuccessResponse } from 'app/api/types';
import { ReceivedReviewAutoReplySettings } from 'app/api/types/reviewAutoReplySettings';

import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

import ApiResourceBaseClass from './baseApiCalls';

const RESOURCE_URI = 'review_auto_reply_settings';

class ReviewAutoReply extends ApiResourceBaseClass {
    static async saveSettings(body: {
        is_with_content?: boolean;
        enabled?: boolean;
        positive: boolean;
        neutral: boolean;
        negative: boolean;
        templates: Array<{ id: number }>;
        business_search?: BusinessModalFiltersType | null;
    }): Promise<SuccessResponse> {
        const { data } = await ReviewAutoReply.post(RESOURCE_URI, body);
        return data;
    }

    static async updateSettings(
        id: string,
        body: {
            is_with_content?: boolean;
            enabled?: boolean;
            positive?: boolean;
            neutral?: boolean;
            negative?: boolean;
            templates?: Array<{ id: number }>;
            business_search?: BusinessModalFiltersType | null;
        },
    ): Promise<SuccessResponse> {
        const { data } = await ReviewAutoReply.put(`${RESOURCE_URI}/${id}`, body);
        return data;
    }

    static async getSettings(
        forReviewsWithComment?: boolean,
    ): Promise<ReceivedReviewAutoReplySettings> {
        const { data } = await ReviewAutoReply.get(RESOURCE_URI, {
            is_with_content: forReviewsWithComment ?? false,
        });
        return data;
    }

    static async toggleSettings(id: string, enabled: boolean): Promise<SuccessResponse> {
        const res = await ReviewAutoReply.updateSettings(id, { enabled });
        return res;
    }

    static async getBusinessesLanguages(settingsId: string): Promise<Record<string, string>> {
        const { data } = await ReviewAutoReply.get(
            `${RESOURCE_URI}/${settingsId}/get_business_languages`,
        );
        return data;
    }
}

export default ReviewAutoReply;
