import React from 'react';

const DragFileIcon = () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none">
        <path
            d="M55.5111 14.1229L41.6629 0.472281C41.3559 0.169641 40.9422 0 40.5112 0H8.18475C7.27869 0 6.54413 0.734562 6.54413 1.64062V14.2143C2.78895 15.1582 0 18.562 0 22.606V39.8307C0 43.8746 2.78895 47.2784 6.54413 48.2225V54.3594C6.54413 55.2654 7.27869 56 8.18475 56H54.3594C55.2654 56 56 55.2654 56 54.3594V15.2913C56 14.852 55.8239 14.4313 55.5111 14.1229ZM42.1518 5.56161L50.358 13.6507H43.7915C42.8872 13.6507 42.1518 12.9147 42.1518 12.01V5.56161ZM3.28125 39.8308V22.606C3.28125 19.6441 5.69089 17.2344 8.65277 17.2344H25.8678C28.8297 17.2344 31.2394 19.6441 31.2394 22.606V39.8307C31.2394 42.7925 28.8297 45.2022 25.8678 45.2022H8.65277C5.69089 45.2023 3.28125 42.7926 3.28125 39.8308ZM9.82537 52.7188V48.4835H25.8678C30.639 48.4835 34.5206 44.6019 34.5206 39.8308V22.606C34.5206 17.8348 30.639 13.9532 25.8678 13.9532H9.82537V3.28125H38.8706V12.01C38.8706 14.724 41.0781 16.9319 43.7915 16.9319H52.7188V52.7188H9.82537Z"
            fill="#0085F2"
        />
        <path
            d="M13.3651 29.5282L15.621 27.2722V39.1872C15.7078 41.364 18.8164 41.3624 18.9023 39.1872V27.2722L21.1584 29.5283C21.799 30.169 22.8379 30.169 23.4785 29.5283C24.1193 28.8876 24.1193 27.8489 23.4785 27.2081L18.4218 22.1514C17.8109 21.5159 16.7124 21.5159 16.1016 22.1514L11.045 27.2081C10.4043 27.8489 10.4043 28.8876 11.045 29.5283C11.6855 30.1689 12.7243 30.1689 13.3651 29.5282Z"
            fill="#0085F2"
        />
    </svg>
);

export default DragFileIcon;
