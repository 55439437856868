import { ReactNode } from 'react';

import { StyledPBoxBody } from './PBox.styled';

interface Props {
    content: ReactNode;
}

export const PBoxBody = ({ content }: Props) => (
    <StyledPBoxBody>{content}</StyledPBoxBody>
);
