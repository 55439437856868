import { CustomChatHours } from 'app/api/types/business_message_settings';

export const CONVERSATION_STARTER_ITEM_TITLE_MAXIMUM_CHARACTERS = 20;
export const CONVERSATION_STARTER_ITEM_REPLY_MESSAGE_MAXIMUM_CHARACTERS = 900;
export const CONVERSATION_STARTER_ITEM_MAXIMUM_ENTRY = 5;

export const ICE_BREAKER_SECTION = 'ICE_BREAKER_SECTION';
export const OFFLINE_SECTION = 'OFFLINE_SECTION';

export enum ConversationStarterItemTypeEnum {
    webUrl = 'web_url',
    postback = 'postback',
}

export type ConversationStarter = {
    id: string;
    org_id: number;
    name?: string;
    enabled?: boolean;
    welcome_message: string;
    offline_message: string;
    autoclose_enabled: boolean;
    uses_business_hours: boolean;
    chat_open_hours: CustomChatHours;
    conv_starters_enabled: boolean;
    automatic_message_enabled: boolean;
    autoclose_message: string;
    created_at?: string;
    updated_at?: string;
    business_ids: Array<string>;
    businesses_count: number;
    item_titles: Array<string>;
};

export type ConversationStarterPatchPayload = {
    name?: string;
    welcome_message?: string;
    offline_message?: string;
    autoclose_enabled?: boolean;
    autoclose_message?: string;
    conv_starters_enabled?: boolean;
    uses_business_hours?: boolean;
    chat_open_hours?: CustomChatHours;
};

export type ConversationStarterItemBaseType = {
    id: string;
    title: string;
    order_index: number;
    conversation_starter_id: string;
};

export type ConversationStarterItemWebUrlSpecificType = {
    type: ConversationStarterItemTypeEnum.webUrl;
    url: string;
};

export type ConversationStarterItemPostbackSpecificType = {
    type: ConversationStarterItemTypeEnum.postback;
    reply_message: string;
};

export type ConversationStarterItemWebUrl = ConversationStarterItemBaseType &
    ConversationStarterItemWebUrlSpecificType;

export type ConversationStarterItemPostback = ConversationStarterItemBaseType &
    ConversationStarterItemPostbackSpecificType;

export type ConversationStarterItem =
    | ConversationStarterItemWebUrl
    | ConversationStarterItemPostback;

export type ConversationStarterItemsGetResponse = {
    items: Array<ConversationStarterItem>;
};

export type ConversationStarterItemCreatePayloadBase = {
    title: string;
};

export type ConversationStarterItemPostBackCreatePayload =
    ConversationStarterItemCreatePayloadBase & ConversationStarterItemPostbackSpecificType;

export type ConversationStarterItemWebUrlCreatePayload = ConversationStarterItemCreatePayloadBase &
    ConversationStarterItemWebUrlSpecificType;

export type ConversationStarterItemPayload =
    | ConversationStarterItemPostBackCreatePayload
    | ConversationStarterItemWebUrlCreatePayload;

export type ConversationStarterPlaceholder = {
    id: string;
    display: string;
};

export const ConversationStarterPlaceholders: Array<ConversationStarterPlaceholder> = [
    { id: 'business_name', display: 'business_name' },
    { id: 'address', display: 'placeholder_address' },
    { id: 'website_url', display: 'placeholder_business_website_url' },
    { id: 'phone_number', display: 'placeholder_phone_number' },
    { id: 'opening_hours', display: 'business_open_hours' },
];

export const ConversationStarterAutomaticMessagesPlaceholders: Array<ConversationStarterPlaceholder> =
    [{ id: 'business_name', display: 'business_name' }];

export type BusinessValidationEntry = {
    business_id: string;
    business_name?: string;
    conversation_starter_id: string;
    conversation_starter_name?: string;
};

export type BusinessValidationResponse = {
    businesses: Array<BusinessValidationEntry>;
};
