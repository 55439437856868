import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

export const MoreHourContainer = styled.div`
    border: solid 1px ${greyColorObject.initial};
    border-bottom: none;
`;

export const MoreHourHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    background: none;
    border: none;
    outline: none;
    padding: 0 24px;
    cursor: pointer;
`;

type MoreHourNameProps = {
    disabled?: boolean;
};

export const MoreHourName = styled.div<MoreHourNameProps>`
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    color: ${props => (props.disabled ? DISABLED_COLOR : HEX_COLORS.blackberry)};
`;

export const MoreHourButtons = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
`;

export const MoreHourAngleIcon = styled.div`
    width: 16px;
    margin-left: 16px;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const MoreHoursList = styled.div`
    > div:last-child {
        border-bottom: solid 1px ${greyColorObject.initial};
        margin-bottom: 40px;
    }

    > div:first-of-type {
        border-radius: 4px 4px 0 0;
    }
`;

type MoreHoursContentProps = {
    collapsed: boolean;
    maxHeight: number;
};

export const MoreHourContent = styled.div<MoreHoursContentProps>`
    padding: 0;
    overflow: hidden;
    max-height: ${props => props.maxHeight}px;
    transition: max-height 0.3s ease-in-out;

    ${props =>
        props.collapsed &&
        css`
            max-height: 0;
        `}
`;

export const MoreHourOpenHourDay = styled.div`
    // Overriding
    > div {
        border-radius: 0;
        margin-bottom: 0;
        gap: 0;
        box-shadow: none;
        border-top: 1px solid ${({ theme }) => theme.colors.default.alpha};
    }
`;
