import React from 'react';

import { useTranslation } from 'react-i18next';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useBusinessFoodMenus from 'app/businessEditV2/hooks/menu/useBusinessFoodMenus';

type Props = {
    colorState?: ColorState;
};

const MenuSectionContent = ({ colorState = ColorState.OK }: Props) => {
    const { t } = useTranslation();
    const { data: business } = useBusiness();
    const { data: menuData } = useBusinessFoodMenus();

    const businessMenuPhotos = business?.photos.MENU ?? [];
    const menuSections = menuData?.menus ?? [];

    switch (colorState) {
        case ColorState.PRECHECK_ERRORS:
            return <>{t('business_edit_content_correct_this_field')}</>;
        case ColorState.MISSING_WARNING:
        case ColorState.CLOSED:
        case ColorState.DISABLED:
            return <>{t('business_edit_menu_section_add')}</>;
        case ColorState.OK:
            return (
                <>
                    <i className="fa-solid fa-images" />
                    <span>
                        {t('menu_photos_count', {
                            count: businessMenuPhotos.length,
                        })}
                    </span>
                    <i className="fa-solid fa-clipboard-list" />
                    <span>
                        {t('business_edit_menu_section_count', {
                            count: menuSections.length,
                        })}
                    </span>
                </>
            );
        default:
            return null;
    }
};

export default MenuSectionContent;
