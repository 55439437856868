import { useState } from 'react';

import { css } from '@emotion/react';
import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix, Text } from '@partoohub/ui';
import { format, isPast, isValid, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import { ApiKeyPayload } from 'app/api/types/api_keys';
import api from 'app/api/v2/api_calls';
import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';
import { API_KEYS_LIST } from 'app/common/data/queryKeysConstants';
import AsyncInputLabel from 'app/common/designSystem/components/atoms/AsyncInputLabel';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    ApiKeyStatusEnum,
    ApiKeyTypeEnum,
    ApiKeysQueryKeys,
} from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/utils/enums';
import { useApiKeyContext } from 'app/settingsManagement/components/ApiKey/ApiKey.context';

import queryClient from 'app/states/queryClient';
import { ISO_DATETIME_FORMAT, ISO_DATE_FORMAT, TIME_FORMAT } from 'app/utils/dates';

import {
    ApiKeyCardContainer,
    ApiKeyCardContent,
    ApiKeyCardDatetimeBox,
    ApiKeyCardEllipsis,
    ApiKeyCardIp,
    ApiKeyCardLabel,
    ApiKeyCardMarkup,
    ApiKeyCardOwner,
    ApiKeyCardShow,
} from './ApiKeyCard.styled';

const DEFAULT_CREATOR = 'Partoo';

type Props = {
    apiKey: Record<string, any>;
};

const ApiKeyCard = ({ apiKey }: Props) => {
    const { t } = useTranslation();
    const {
        setDefaultFormValues,
        setApiKeyId,
        setApiKeyLabel,
        setApiKeyShowModal,
        setKeyValue,
        setApiKeyShowModalForm,
    } = useApiKeyContext();

    const [typeQuery] = useStateQueryParams(ApiKeysQueryKeys.TYPE);
    const [statusQuery] = useStateQueryParams(ApiKeysQueryKeys.STATUS);
    const [inlineEditHasError, setInlineEditHasError] = useState<boolean>(false);
    const { data: me } = useMe();

    const { mutate, isLoading } = useMutation(
        ({ apiKeyId, payload }: { apiKeyId: number; payload: ApiKeyPayload }) =>
            api.apiKeys.editApiKey(apiKeyId, payload),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([API_KEYS_LIST]);
            },
            onError: () => {
                setInlineEditHasError(true);
            },
        },
    );

    const openRevokeModal = (keyId: number, keyLabel: string | null) => {
        setApiKeyId(keyId);
        setApiKeyLabel(keyLabel);
    };

    const onMenuClick = (value: string, apiKey: Record<string, any>) => {
        if (value === 'revoke') {
            openRevokeModal(apiKey.id, apiKey.label);
        } else if (value === 'edit') {
            setDefaultFormValues({
                keyId: apiKey.id,
                label: apiKey.label,
                expiration_date: apiKey.expiration_date,
                ip_whitelist: apiKey.ip_whitelist,
                type: apiKey.type === 'human' ? ApiKeyTypeEnum.USER : ApiKeyTypeEnum.BOT,
            });
            setApiKeyId(apiKey.id);
            setApiKeyShowModalForm(true);
        }
    };

    const displayRestrictionIcon = (hasRestrictions: boolean) => {
        return (
            <ApiKeyCardIp>
                {hasRestrictions ? (
                    <TooltipWrapper
                        text={t('api_key_manager_ip_tooltip_restrictions_yes')}
                        position={'bottom-start'}
                        className="api-key-card__tooltip"
                    >
                        <i className="fa-solid fa-lock" />
                    </TooltipWrapper>
                ) : (
                    <TooltipWrapper
                        text={t('api_key_manager_ip_tooltip_restrictions_no')}
                        position={'bottom-start'}
                        className="api-key-card__tooltip"
                    >
                        <i className="fa-solid fa-lock-open" />
                    </TooltipWrapper>
                )}
            </ApiKeyCardIp>
        );
    };

    const displayDateTime = (datetime: string, placeholder: string) => {
        const parsedDatetime = datetime && parseISO(datetime);
        const datetimeIsValid = parsedDatetime && isValid(parsedDatetime);

        return (
            <ApiKeyCardDatetimeBox isValidDate={datetimeIsValid}>
                {datetimeIsValid ? (
                    <div>
                        <Text variant="bodyMBold">{format(parsedDatetime, ISO_DATE_FORMAT)}</Text>
                        <Text variant="bodySRegular" color="secondary">
                            {format(parsedDatetime, TIME_FORMAT)}
                        </Text>
                    </div>
                ) : (
                    placeholder
                )}
            </ApiKeyCardDatetimeBox>
        );
    };

    const displayDate = (date: string, placeholder: string) => {
        const parsedDate = date && parseISO(date);
        const dateIsValid = parsedDate && isValid(parsedDate);

        return (
            <ApiKeyCardDatetimeBox isValidDate={dateIsValid}>
                {dateIsValid ? (
                    <Text variant="bodyMBold">{format(parsedDate, ISO_DATE_FORMAT)}</Text>
                ) : (
                    placeholder
                )}
            </ApiKeyCardDatetimeBox>
        );
    };

    const displayOwner = (name: string, role: string) => {
        return (
            <ApiKeyCardOwner>
                <Text variant="bodyMRegular" as="span" color="secondary">
                    {name}
                </Text>
                <Text variant="bodySRegular" as="span" color="secondary">
                    {t(role)}
                </Text>
            </ApiKeyCardOwner>
        );
    };

    const displayCreator = (name: string, placeholder: string) => (
        <ApiKeyCardOwner>
            <Text variant="bodyMRegular" as="span" color="secondary">
                {name || placeholder}
            </Text>
        </ApiKeyCardOwner>
    );

    const displayHelp = () => {
        const revokedBy = apiKey.revoked_by_name || t('api_key_manager_missing_creation_date');

        const revokedAt = apiKey.revoked_at
            ? format(parseISO(`${apiKey.revoked_at}Z`), ISO_DATETIME_FORMAT)
            : t('api_key_manager_missing_creation_date');

        const text = apiKey.disabled
            ? `${t('api_key_manager_revoked_by')} ${revokedBy}\n${t(
                  'api_key_manager_revoke_date',
              )} ${revokedAt}`
            : t('api_key_manager_expired');

        return (
            <TooltipWrapper text={text} className="margin_right--double" position="left-start">
                <i className="fa-solid fa-question-circle" />
            </TooltipWrapper>
        );
    };

    const checkLabelLength = (label: string) => {
        return label.length > 100 ? t('api_key_manager_error_label_length') : null;
    };

    const isExpired = apiKey.expiration_date !== null && isPast(parseISO(apiKey.expiration_date));

    const isActive = !apiKey.disabled && !isExpired;

    const isAllowedToReadKeyValue = () => {
        return apiKey.user_role === me?.role;
    };

    const options = [
        {
            name: 'edit',
            label: (
                <MenuLabelItem
                    icon={['fa-pen-alt', IconPrefix.REGULAR]}
                    text={t('api_key_manager_edit_button')}
                />
            ),
            value: 'edit',
        },
        {
            name: 'revoke',
            label: (
                <MenuLabelItem
                    icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                    text={t('api_key_manager_revoke_button')}
                    color="danger"
                />
            ),
            value: 'revoke',
        },
    ];
    return (
        <ApiKeyCardContainer>
            <ApiKeyCardMarkup isActive={isActive} />
            <ApiKeyCardContent>
                {(!statusQuery || statusQuery === ApiKeyStatusEnum.ACTIVE) &&
                    displayRestrictionIcon(apiKey.ip_whitelist.length > 0)}
                <ApiKeyCardLabel>
                    <AsyncInputLabel
                        placeholder={t('api_key_manager_add_label')}
                        label={apiKey.label}
                        onLabelChange={value =>
                            mutate({
                                apiKeyId: apiKey.id,
                                payload: {
                                    label: value,
                                    ip_whitelist: apiKey.ip_whitelist,
                                    expiration_date: apiKey.expiration_date,
                                },
                            })
                        }
                        isLoading={isLoading}
                        errorMessage={
                            inlineEditHasError ? t('api_key_manager_snackbar_error') : null
                        }
                        disabledPlaceholder={t('api_key_manager_missing_label')}
                        disabled={!isActive}
                        dataField="api_key_manager_inline_label_editor"
                        validation={checkLabelLength}
                    />
                </ApiKeyCardLabel>
                {typeQuery === ApiKeyTypeEnum.USER
                    ? displayOwner(apiKey.user_name, apiKey.user_role)
                    : displayCreator(apiKey.created_by_name, DEFAULT_CREATOR)}
                {displayDateTime(apiKey.created_at, t('api_key_manager_missing_creation_date'))}
                {displayDateTime(apiKey.last_used_at, '-')}
                {displayDate(apiKey.expiration_date, '-')}
                <ApiKeyCardShow>
                    {isAllowedToReadKeyValue() && isActive ? (
                        <IconButton
                            dataTrackId="show_apikey"
                            onClick={() => {
                                setKeyValue(apiKey.id);
                                setApiKeyShowModal(true);
                            }}
                            icon={['fa-eye', IconPrefix.SOLID]}
                        />
                    ) : (
                        <div />
                    )}
                </ApiKeyCardShow>
                <ApiKeyCardEllipsis>
                    {isActive ? (
                        <ActionButtonWithMenu
                            dataTrackId="api_key_card__action_button"
                            icon={['fa-ellipsis-v', IconPrefix.SOLID]}
                            onMenuClick={value => {
                                onMenuClick(value, apiKey);
                            }}
                            options={options}
                            cssMenu={css`
                                top: 60px;
                                right: -24px;
                                left: unset;
                            `}
                            tooltip={t('more')}
                        />
                    ) : (
                        displayHelp()
                    )}
                </ApiKeyCardEllipsis>
            </ApiKeyCardContent>
        </ApiKeyCardContainer>
    );
};

export default ApiKeyCard;
