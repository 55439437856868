import { Tabs } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDataContext } from 'app/common/contexts/appData';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { COMPETITIVE_BENCHMARK, USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import IS_IFRAME from 'app/common/data/iframe';
import { ADMIN } from 'app/common/data/roles';
import { useProductsAccess } from 'app/hooks/useProductsAccess';
import { useHasReviewAnalyticsV2 } from 'app/pages/analytics/hooks';
import { useReviewAnalyticsDefaultFilter } from 'app/pages/analytics/ReviewAnalyticsV2/hooks/useReviewAnalyticsDefaultFilter';
import {
    ANALYTICS_REVIEW_PATH,
    ANALYTICS_VISIBILITY_PATH,
    COMPETITIVE_BENCHMARK_PATH,
} from 'app/routing/routeIds';

import { AnalyticsTabsContainer } from './AnalyticsTabs.styled';

export const AnalyticsTabs = () => {
    const { t } = useTranslation();
    const { me } = useAppDataContext();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { hasRmAccess, hasPmAccess } = useProductsAccess();

    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const hasCompetitiveBenchmark =
        useFeatureFlag(COMPETITIVE_BENCHMARK) &&
        !me?.sidebar_excluded_pages.includes('competitive_benchmark') &&
        hasPmAccess;
    const hasReviewAnalyticsV2 = useHasReviewAnalyticsV2();
    const { defaultDateQuery } = useReviewAnalyticsDefaultFilter();

    const disabledTabs = [ADMIN].includes(me.role);

    const trueCount = [hasRmAccess, hasPmAccess, hasCompetitiveBenchmark].filter(Boolean).length;

    const handleNavigateTabLink = (path: string) => {
        navigate(path);
    };

    if (IS_IFRAME || trueCount <= 1) {
        return null;
    }

    return (
        <AnalyticsTabsContainer isNewSidebar={isNewSidebarEnabled}>
            <Tabs
                selected={pathname}
                dataTrackId="analytics_tabs"
                onChange={value => {
                    if (value === `${ANALYTICS_REVIEW_PATH}/v2`) {
                        handleNavigateTabLink(`${value}?${defaultDateQuery}`);
                    } else {
                        handleNavigateTabLink(value as string);
                    }
                }}
            >
                {hasPmAccess && (
                    <Tabs.Tab value={ANALYTICS_VISIBILITY_PATH} disabled={disabledTabs}>
                        {t('visibility')}
                    </Tabs.Tab>
                )}

                {hasRmAccess && (
                    <Tabs.Tab value={ANALYTICS_REVIEW_PATH} disabled={disabledTabs}>
                        {t('reviews')}
                    </Tabs.Tab>
                )}

                {hasRmAccess && hasReviewAnalyticsV2 && (
                    <Tabs.Tab value={`${ANALYTICS_REVIEW_PATH}/v2`} disabled={disabledTabs}>
                        {t('reviews')} V2
                    </Tabs.Tab>
                )}

                {hasCompetitiveBenchmark && (
                    <Tabs.Tab value={COMPETITIVE_BENCHMARK_PATH} disabled={disabledTabs}>
                        {t('competitive_benchmark_wording')}
                    </Tabs.Tab>
                )}
            </Tabs>
        </AnalyticsTabsContainer>
    );
};
