import { useTranslation } from 'react-i18next';

import {
    MESSAGING_STATS_PERIOD_MONTHS,
    useHomepageMessagingStats,
} from 'app/pages/Homepage/hooks/stats/statsItems/messages/useHomepageMessagingStats';
import {
    ChipColor,
    ChipEvolution,
    StatChip,
} from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/StatChip';

const calculateResponseTimeEvolution = (from: number, to: number): number => {
    if (from === 0) {
        return 0;
    }

    const diffInSeconds = to - from;
    const result = Math.round(diffInSeconds / 60);

    // Handle edge cases
    if (isNaN(result) || result === Infinity || result === -Infinity) {
        return 0;
    }

    return result;
};

export const StatMessagesChip = () => {
    const { t } = useTranslation();
    const { data } = useHomepageMessagingStats(MESSAGING_STATS_PERIOD_MONTHS, 0);
    const { data: dataPrevious } = useHomepageMessagingStats(
        MESSAGING_STATS_PERIOD_MONTHS * 2,
        MESSAGING_STATS_PERIOD_MONTHS,
    );

    const actualAverageResponseTime = data?.previousAverageResponseTime ?? 0;
    const previousAverageResponseTime = dataPrevious?.previousAverageResponseTime ?? 0;

    const responseTimeEvolution = calculateResponseTimeEvolution(
        previousAverageResponseTime,
        actualAverageResponseTime,
    );

    const statChipProps = () => {
        if (responseTimeEvolution === 0) {
            return {
                text: `${t('homepage_stats_no_evolution_months', {
                    duration: 1,
                })}`,
                chipColor: ChipColor.GREY,
                chipEvolution: ChipEvolution.NONE,
            };
        }

        if (responseTimeEvolution > 0) {
            return {
                text: `${t('homepage_stats_messages_positive_evolution', {
                    amount: responseTimeEvolution,
                })}`,
                chipColor: ChipColor.RED,
                chipEvolution: ChipEvolution.INCREASE,
            };
        }

        return {
            text: `${t('homepage_stats_messages_negative_evolution', {
                amount: responseTimeEvolution,
            })}`,
            chipColor: ChipColor.GREEN,
            chipEvolution: ChipEvolution.DECREASE,
        };
    };

    return <StatChip {...statChipProps()} />;
};
