import { useEffect } from 'react';

import { LiteralRating } from 'app/api/types/review';
import { REVIEW_AUTO_REPLY_SUGGESTED_TEMPLATES } from 'app/common/data/queryKeysConstants';
import { useReplyTemplates } from 'app/common/hooks/queries/ReviewReplyTemplates/useReplyTemplates';

import { ReplyTemplateSelectFilters } from '../useSettingsPageContext';

type Props = {
    forReviewsWithComment: boolean;
    languageFilter: Array<string>;
    ratingsFilter: Array<LiteralRating>;
    filters: ReplyTemplateSelectFilters;
};

const useGetCustomTemplates = ({ languageFilter, ratingsFilter, filters }: Props) => {
    // Fetch custom templates, not made by partoo: with orgId != null
    const [customTemplates, { fetchNextPage, hasNextPage }] = useReplyTemplates(
        {
            search: filters.search,
            titleSearch: filters.search,
            languages: languageFilter,
            ratings: ratingsFilter,
        },
        {
            enabled: !!ratingsFilter.length,
            keepPreviousData: true,
        },
        t => !!t.org_id,
    );

    return { customTemplates, fetchNextPage, hasNextPage };
};

const useGetSuggestedTemplates = ({
    forReviewsWithComment,
    languageFilter,
    ratingsFilter,
    filters,
}: Props) => {
    // Fetch templates to be marked as suggested: with is_auto_reply == True and orgId == null
    const filter = {
        search: filters.search,
        titleSearch: filters.search,
        languages: languageFilter,
        ratings: ratingsFilter,
        isAutoReply: true,
    };
    const [suggestedTemplates, { fetchNextPage, hasNextPage }] = useReplyTemplates(
        filter,
        {
            queryKey: [REVIEW_AUTO_REPLY_SUGGESTED_TEMPLATES, filter, forReviewsWithComment],
            enabled: !!ratingsFilter.length && !forReviewsWithComment,
        },
        t => !t.org_id,
    );

    useEffect(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage]);

    return suggestedTemplates;
};

export const useGetReplyTemplates = (props: Props) => {
    const suggestedTemplates = useGetSuggestedTemplates(props);
    const { customTemplates, fetchNextPage, hasNextPage } = useGetCustomTemplates(props);

    return {
        suggestedTemplates,
        customTemplates,
        hasNextPage,
        fetchNextPage,
    };
};
