import { useTranslation } from 'react-i18next';

import { useAppDataContext } from 'app/common/contexts/appData';
import { PRESENCE_MANAGEMENT } from 'app/common/data/productIds';
import { ADMIN } from 'app/common/data/roles';
import { Export } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Export/Export';
import { PageHeaderWithAvatar } from 'app/routing/components/PageHeaderWithAvatar';

export const VisibilityPageHeader = () => {
    const { t } = useTranslation();
    const { me } = useAppDataContext();

    // TODO: Tricia - TT-318
    const isNotAdmin = me.role !== ADMIN;
    const hasPmAccess = me.sidebar_products.includes(PRESENCE_MANAGEMENT.toLowerCase());
    const showHeader = isNotAdmin && hasPmAccess;

    return (
        showHeader && (
            <PageHeaderWithAvatar
                title={t('analytics')}
                actions={[<Export key="--export-analytics-button-key--" />]}
            />
        )
    );
};
