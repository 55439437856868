import React from 'react';

type Props = {
    width?: number;
    height?: number;
};

const LineHigh = React.forwardRef<SVGSVGElement>(
    ({ width = 99, height = 83 }: Props, ref) => (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.6"
                d="M84.667 24.3324C95.2 34.7745 90.5079 53.9215 103.08 61.7917C124.287 75.0671 165.892 53.1359 153.902 11.1192C148.416 -8.1083 171.489 -9.27158 164.712 -28.0721C157.354 -48.4838 126.427 -46.3903 107.911 -57.6992C86.7571 -70.6199 76.9271 -96.8923 54.9437 -85.4038C40.4576 -77.8334 30.9437 -68.6019 29.4315 -61.5583C27.1491 -50.9267 31.9548 -39.3561 36.3462 -24.8081C40.8803 -9.78757 34.0575 1.86273 47.4093 10.1001C61.5398 18.8179 72.8766 12.6437 84.667 24.3324Z"
                stroke="#98F4D2"
                strokeWidth="0.3"
            />
            <g filter="url(#filter0_d_21729_155707)">
                <path
                    d="M67.9415 17.8191C68.9781 15.8683 68.237 13.4465 66.2861 12.4099C64.3353 11.3733 61.9135 12.1145 60.8769 14.0653C59.8403 16.0162 60.5815 18.438 62.5323 19.4746C64.4832 20.5111 66.9049 19.77 67.9415 17.8191Z"
                    fill="#98F4D2"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_21729_155707"
                    x="50.4084"
                    y="1.94141"
                    width="28.0016"
                    height="28.0017"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.597353 0 0 0 0 0.956465 0 0 0 0 0.823346 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_21729_155707"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_21729_155707"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    ),
);

export default LineHigh;
