import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import { DoughnutStyled } from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/AverageGrade/Doughnut.styled';

export const AverageGradeSectionStyled = styled.div`
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    max-width: 100%;
    min-width: 0;

    ${DoughnutStyled}:first-of-type:after {
        position: absolute;
        content: '';
        height: 80%;
        background-color: ${greyColorObject.initial};
        width: 1px;
        right: 0;
        transform: translate(0, 10%);
    }

    @media screen and (max-width: 854px) {
        flex-direction: column;

        ${DoughnutStyled}:first-of-type:after {
            height: 1px;
            width: 80%;
            right: initial;
            bottom: 0;
            transform: translate(10%, 0);
        }
    }
`;
