import styled from '@emotion/styled';

export const ADVICE_SECTION_HEIGHT = 178;

export const BusinessAdviceDescriptionContainer = styled.div`
    display: flex;
    align-items: center;
    height: ${ADVICE_SECTION_HEIGHT}px;
    background-color: ${({ theme }) => theme.colors.theme.background};
    border-radius: 16px;
    padding: 18px 32px;
    flex-grow: 1;
`;
