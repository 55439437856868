import { FC, useEffect } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { V2FormattedLocationData } from 'app/api/types/account';
import StatusButton from 'app/businessConnection/components/connectionsTable/BusinessRow/StatusButton';

import { CardLine } from '../commonOld/CardLine';
import LocationDetails from '../commonOld/LocationDetails';

interface Props {
    state: 'error' | 'loading' | 'success';
    onClick: () => void;
    fetchLocation: () => void;
    publisherStatus?: string;
    errorMessage?: string;
    location: V2FormattedLocationData;
}

const ConnectedButton: FC<Props> = ({
    state,
    onClick,
    fetchLocation,
    publisherStatus = 'error',
    errorMessage = 'error',
    location = undefined,
}) => {
    const { t } = useTranslation();
    const isPartnerConnectionOk =
        state === 'success' && location?.partnerConnection?.status !== 'broken';

    useEffect(() => {
        if (state === 'loading') {
            fetchLocation();
        }
    }, [state, fetchLocation]);

    const color = state === 'loading' ? 'primary' : isPartnerConnectionOk ? 'success' : 'danger';
    const renderIcon = isPartnerConnectionOk
        ? FontAwesomeIconsPartooUsed.faCircleCheck
        : FontAwesomeIconsPartooUsed.faExclamationCircle;
    const iconType = state === 'loading' ? FontAwesomeIconsPartooUsed.faSpin : renderIcon;

    const isPublisherSuccessWithError = publisherStatus === 'success' && color === 'danger';
    const isDisabled = state !== 'success' || isPublisherSuccessWithError;
    const text =
        state === 'success' && !isPublisherSuccessWithError ? (
            // @ts-ignore
            <LocationDetails location={location} />
        ) : (
            <CardLine text={t(errorMessage)} />
        );

    return (
        <StatusButton
            icon={[iconType, IconPrefix.SOLID]}
            text={text}
            textTooltip={isDisabled ? t('partner_connection_button_tooltip') : undefined}
            color={color}
            onClick={onClick}
            disabled={isDisabled}
        />
    );
};

export default ConnectedButton;
