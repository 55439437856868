import { MESSAGING_CONVERSATION_STATS } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

export const decrementMessageCount = filters => {
    const previousData = queryClient.getQueryData([
        MESSAGING_CONVERSATION_STATS,
        filters,
    ]);
    if (previousData) {
        queryClient.setQueryData<any>([MESSAGING_CONVERSATION_STATS, filters], {
            ...previousData,
            unread_open: previousData.unread_open - 1,
        });
    }
};
