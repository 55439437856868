import { createContext, useContext, useRef, useState } from 'react';

import { toast } from '@partoohub/ui';
import { flushSync } from 'react-dom';

import { useTranslation } from 'react-i18next';

import { ReplySuggestionParams } from 'app/api/types/comments';
import { ReplySuggestion, ReviewObjectType } from 'app/api/types/review';
import { FormattedReplyTemplate } from 'app/api/types/reviewReplyTemplates';
import api from 'app/api/v2/api_calls';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useTypewriterEffect from 'app/common/hooks/useTypewriterEffect';
import useReduxBusiness from 'app/reviewManagement/common/hooks/useReduxBusiness';
import {
    useShowReplySuggestionButton,
    useShowReplyTemplatesButton,
} from 'app/reviewManagement/reviewList/hooks/ReviewCard';
import { CommentFormTextArea } from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/CommentSection/CommentTable/CommentFormTextArea';
import queryClient from 'app/states/queryClient';
import {
    TemplatePlaceholder,
    fillTemplateService,
    placeholderConvertor,
} from 'app/states/reviews/services';

import Flexbox from 'app/styles/utils/flexbox';

import { ButtonSeparator, StyledButtonSection } from './CommentForm.styled';
import { useReportSuggestionMutation } from './hooks/useReportSuggestionMutation';
import ResponseButtons from './ResponseButtons';
import { ReviewCardContext, ReviewCardContextType } from '../../ReviewCard';
import EmojiPicker from '../EmojiPicker/EmojiPicker';
import PlaceholderSelector from '../PlaceholderSelector/PlaceholderSelector';
import ReplySuggestionButton from '../ReplySuggestionButton/ReplySuggestionButton';
import { ReplyTemplateButton } from '../ReplyTemplateButton/ReplyTemplateButton';
import ReportSuggestionModal from '../ReportSuggestionModal/ReportSuggestionModal';
import SuggestionMenubar, { SuggestionIndex } from '../SuggestionMenubar/SuggestionMenubar';

export type CommentOptions = {
    reply_template_id: string | undefined;
    reply_suggestion: ReplySuggestionParams | undefined;
};

type Props = {
    value: string;
    canCancel: boolean;
    parentId?: number;
    isReplying: boolean;
    onChange: (arg0: string) => void;
    onReply: (comment: string, options: CommentOptions) => void;
    onUndo: () => void;
};

export type ReviewReplyContextType = {
    onSuggestionMode: boolean;
    currentSuggestion: ReplySuggestion | undefined;
    showTemplateModal: boolean;
};

const ReviewReplyContextDefault: ReviewReplyContextType = {
    onSuggestionMode: false,
    currentSuggestion: undefined,
    showTemplateModal: false,
};

export const ReviewReplyContext = createContext<ReviewReplyContextType>(ReviewReplyContextDefault);
function CommentForm({ value, canCancel, parentId, isReplying, onChange, onReply, onUndo }: Props) {
    const { review, cardRef } = useContext<ReviewCardContextType>(ReviewCardContext);
    const { t } = useTranslation();
    const [preselectedTemplate, setPreselectedTemplate] = useState<
        FormattedReplyTemplate | undefined
    >(undefined);
    const [tempValue, setTempValue] = useState<string | null>(null);
    const [chosenTemplateId, setChosenTemplateId] = useState<string | undefined>(undefined);
    const [loadingSuggestion, setLoadingSuggestion] = useState<boolean>(false);
    const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false);
    const [onSuggestionMode, setOnSuggestionMode] = useState<boolean>(false);
    const [showReportModal, setShowReportModal] = useState<boolean>(false);
    const [currentSuggestion, setCurrentSuggestion] = useState<ReplySuggestion | undefined>(
        undefined,
    );

    const business = useReduxBusiness(review.businessId);
    const { data: meData } = useMe();
    const textAreaRef = useRef<HTMLTextAreaElement>({} as HTMLTextAreaElement);

    const showReplySuggestionButton = useShowReplySuggestionButton(parentId);
    const showReplyTemplatesButton = useShowReplyTemplatesButton();

    const { typewrite, isWriting } = useTypewriterEffect(onChange);

    const onHoverTemplate = (value: FormattedReplyTemplate | undefined) =>
        setPreselectedTemplate(value);

    const getFilledTemplate = (template: FormattedReplyTemplate) =>
        fillTemplateService(
            template,
            review,
            business,
            // @ts-expect-error
            meData,
        );

    const onClickTemplate = (template: FormattedReplyTemplate) => {
        setShowTemplateModal(false);
        flushSync(() => {
            const filledTemplate = getFilledTemplate(template);
            onChange(filledTemplate);
            setChosenTemplateId(template.id.toString());
            setPreselectedTemplate(undefined);
        });
        if (textAreaRef) {
            textAreaRef.current?.focus();
        }
    };

    function handleReply() {
        const commentOptions: CommentOptions = {
            reply_template_id: chosenTemplateId,
            reply_suggestion: undefined,
        };
        if (onSuggestionMode && currentSuggestion) {
            commentOptions.reply_suggestion = {
                reply_suggestion_id: currentSuggestion.id,
                reply_suggestion_modified: value !== currentSuggestion?.content,
            };
        }
        onReply(value, commentOptions);
        setOnSuggestionMode(false);
    }

    const scrollCardToTop = () => {
        const cardRefRect = cardRef?.current?.getBoundingClientRect();
        if (!cardRefRect) return;

        if (cardRefRect.height < window.innerHeight) {
            cardRef?.current?.scrollIntoView({ behavior: 'smooth' });
        } else {
            // If the card is too large, because it has a lot of replies:
            // Scroll to the comment area centered instead
            textAreaRef?.current?.scrollIntoView({
                block: 'center',
                inline: 'start',
                behavior: 'smooth',
            });
        }
    };

    const onTemplateModalOpen = () => {
        setShowTemplateModal(true);
        scrollCardToTop();
    };

    async function onReplySuggestionClick(index: SuggestionIndex) {
        if (isWriting) {
            return;
        }

        const queryKey = ['GET_REPLY_SUGGESTION', review.id, index];
        const suggestionIsInCache = queryClient.getQueryData<ReplySuggestion>(queryKey);

        // Clear reply template
        setChosenTemplateId(undefined);
        // Show loader when suggestion is not in cache
        setLoadingSuggestion(!suggestionIsInCache);
        // When suggestion is in cache, we do not use typewrite effect
        const changeFn = suggestionIsInCache ? onChange : typewrite;
        try {
            const data = await queryClient.fetchQuery(queryKey, () => {
                const apis = {
                    [ReviewObjectType.FEEDBACK_RESULT]: api.feedbackResults,
                    [ReviewObjectType.REVIEW]: api.review,
                };
                return apis[review.reviewObjectType].getReplySuggestion(review.id, index);
            });
            changeFn(data.content);
            setCurrentSuggestion(data);
            setOnSuggestionMode(true);
        } catch (e) {
            if (e.response.data?.errors?.json === 'language_issue')
                toast.error(
                    t('review_ai_suggestion_language_issue_content'),
                    t('review_ai_suggestion_language_issue_title'),
                );
            else toast.error(t('unexpected_error'), t('error'));
        }

        setLoadingSuggestion(false);
    }

    const { mutate: onReportSuggestion } = useReportSuggestionMutation(
        review,
        currentSuggestion,
        setCurrentSuggestion,
        () => setShowReportModal(false),
    );

    function getCallback(convertor: (v: string) => string = s => s): (v: string) => void {
        return function (text: string): void {
            const { selectionStart: start, selectionEnd: end } = textAreaRef.current;
            const textToAdd = convertor(text);

            // Need to flush synchronously here so that
            // the textArea value is being reflected directly
            // (which allows us to use setSelectionRange below without any issue)
            flushSync(() => {
                onChange(value.slice(0, start) + textToAdd + value.slice(end));
            });

            const cursorPos = start + textToAdd.length;
            textAreaRef.current?.focus();
            textAreaRef.current?.setSelectionRange(cursorPos, cursorPos);
        };
    }

    function onQuitSuggestionMode() {
        onChange('');
        setOnSuggestionMode(false);
    }

    function onPlaceholderHover(placeholder: string) {
        const { start, end } = cursorPos;
        const textToAdd = placeholderConvertor(placeholder as TemplatePlaceholder, {
            review,
            business,
            meData,
        });
        const text = value.slice(0, start) + textToAdd + value.slice(end);

        flushSync(() => {
            setTempValue(text);
        });

        textAreaRef.current?.setSelectionRange(start, start);
    }

    const [cursorPos, setCursorPos] = useState<{
        [key in 'start' | 'end']: number;
    }>({ start: 0, end: 0 });

    const getButtons = () => {
        const buttons = [];
        if (onSuggestionMode) {
            buttons.push(
                <SuggestionMenubar
                    key={'button-suggestion-menu-bar'}
                    onClear={onQuitSuggestionMode}
                    onSlide={onReplySuggestionClick}
                    onReport={() => setShowReportModal(true)}
                />,
            );
        } else {
            if (showReplySuggestionButton) {
                buttons.push(
                    <ReplySuggestionButton
                        key={'button-reply-suggestion'}
                        onClick={() => onReplySuggestionClick(0)}
                    />,
                );
            }

            if (showReplyTemplatesButton) {
                buttons.push(
                    <ReplyTemplateButton
                        key={'button-reply-template'}
                        onHoverTemplate={onHoverTemplate}
                        onClickTemplate={onClickTemplate}
                        onOpen={onTemplateModalOpen}
                        onClose={() => setShowTemplateModal(false)}
                    />,
                );
            }
        }

        if (buttons.length > 0) {
            buttons.push(<ButtonSeparator key={'button-separator'}></ButtonSeparator>);
        }

        buttons.push(
            <EmojiPicker
                key={'button-emoji-picker'}
                onClick={() => scrollCardToTop()}
                onEmojiClick={getCallback()}
            />,
            <PlaceholderSelector
                key={'button-placeholder-selector'}
                onMenuClick={getCallback((p: TemplatePlaceholder) =>
                    placeholderConvertor(p, {
                        review,
                        business,
                        meData,
                    }),
                )}
                onMenuEnter={onPlaceholderHover}
                onMenuLeave={() => {
                    setTempValue(null);
                }}
                onMenuToggle={(show: boolean): void => {
                    if (show) {
                        scrollCardToTop();
                        // Storing textArea current cursor position
                        const { selectionStart: start, selectionEnd: end } = textAreaRef.current;
                        setCursorPos({ start, end });
                    }
                }}
            />,
        );

        return buttons;
    };

    return (
        <ReviewReplyContext.Provider
            value={{ onSuggestionMode, currentSuggestion, showTemplateModal }}
        >
            <CommentFormTextArea
                ref={textAreaRef}
                textValue={value}
                preselectedTemplate={preselectedTemplate}
                tempValue={tempValue}
                onChange={v => {
                    if (v === '') {
                        setOnSuggestionMode(false);
                    }
                    onChange(v);
                }}
                loading={loadingSuggestion}
            >
                <StyledButtonSection flex={'1'} disabled={loadingSuggestion || isWriting}>
                    {getButtons()}
                </StyledButtonSection>
                <Flexbox justifyContent="right" alignItems="center" style={{ gap: '8px' }}>
                    <ResponseButtons
                        disabled={loadingSuggestion || isWriting || isReplying}
                        handleUndo={onUndo}
                        handleReply={handleReply}
                        canCancel={canCancel}
                    />
                </Flexbox>
            </CommentFormTextArea>
            <ReportSuggestionModal
                show={showReportModal}
                onCancel={() => setShowReportModal(false)}
                onConfirm={onReportSuggestion}
            ></ReportSuggestionModal>
        </ReviewReplyContext.Provider>
    );
}

export default CommentForm;
