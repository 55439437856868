import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const Body = styled.div`
    padding-bottom: 4px;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
`;

export const FiltersWrapper = styled.div`
    display: flex;
    column-gap: 8px;
    margin: 8px 0;

    > *:first-of-type {
        flex: 1;
    }
`;

export const SideBar = styled.div`
    background: ${greyColorObject.light};
    border-radius: 4px;
    display: flex;
    padding: 8px 16px;

    p {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.909091px;
        text-transform: uppercase;
        margin: auto 0;
        width: 50%;
        color: ${({ theme }) => theme.colors.secondary.initial};
    }

    p:first-of-type {
        width: 92%;
    }
`;
