import { SearchBusinessParams } from 'app/api/types/business';
import {
    BusinessesState,
    connectedToFacebookLocationSelector,
    connectedToGmbLocationSelector,
    googlePostApiDisabledSelector,
    groupIdFilterSelector,
    groupIdInFilterSelector,
    orgIdFilterSelector,
    orgIdInFilterSelector,
    selectedCitySelector,
} from 'app/common/reducers/newBusinesses';

import booleanToQueryParam from 'app/common/services/booleanToQueryParams';
import getGroupIdFilterParams from 'app/common/services/getGroupIdParams';
import retrieveQueryParam from 'app/common/services/retrieveQueryParam';

const formatBusinessStateToSearchBusinessParams = (
    businessState: BusinessesState,
    query?: string,
    page?: number,
    additionalParams?: Record<string, string>,
): SearchBusinessParams => {
    const groupIdIn = groupIdInFilterSelector(businessState);
    const orgIdIn = orgIdInFilterSelector(businessState);
    const businessId = retrieveQueryParam('business_id');

    return {
        query,
        page,
        city: selectedCitySelector(businessState) || null,
        connected_to_gmb_location: booleanToQueryParam(
            connectedToGmbLocationSelector(businessState),
        ),
        connected_to_facebook_location: booleanToQueryParam(
            connectedToFacebookLocationSelector(businessState),
        ),
        /**
         * RIP these filters, this function shouldn't even be used anyways
         * If needed, it's now in filterByBusiness.searchFilters
         *
         *         subscribed_to_pm: booleanToQueryParam(
         *             subscribedToPmSelector(businessState),
         *         ),
         *         subscribed_to_rm: booleanToQueryParam(
         *             subscribedToRmSelector(businessState),
         *         ),
         *         subscribed_to_rb: booleanToQueryParam(
         *             subscribedToRbSelector(businessState),
         *         ),
         *         status: businessStatusSelector(businessState),
         */
        business__in: businessId,
        group_id__in: groupIdIn && groupIdIn.length ? groupIdIn.toString() : null,
        org_id: orgIdFilterSelector(businessState),
        org_id__in: orgIdIn?.length ? orgIdIn.toString() : null,
        partoo_ui: true,
        is_google_post_api_disabled: googlePostApiDisabledSelector(businessState),
        ...getGroupIdFilterParams(groupIdFilterSelector(businessState)),
        order_by: '-created',
        ...(additionalParams ? additionalParams : {}),
    };
};

export default formatBusinessStateToSearchBusinessParams;
