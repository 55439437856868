import styled from '@emotion/styled';

export const MessagingEmptyPlaceholderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 10%;
    flex: 1.5;
`;
