import { useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Icon, IconPrefix, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import ImageCropperModal from './ImageCropper';
import {
    StyledAutoCroppedChip,
    StyledCropIcon,
    StyledMainPhotoChip,
    StyledMultiImagePhoto,
    StyledPhotoOverlayBackground,
    StyledPhotoOverlayContent,
    StyledTooltip,
    StyledTrashIcon,
} from './MultiImagePhoto.styled';
import { ImageType } from '../../context/NewPost';
import usePlatformSelection from '../../hooks/usePlatformSelection';

type Props = {
    isMainPhoto: boolean;
    mainPhotoAspectRatio: number | undefined;
    postImage: ImageType;
    disabled: boolean;
    onDelete: () => void;
    onCrop: (
        croppedFile: Blob,
        cropperData: Cropper.Data,
        isMainPhoto: boolean,
    ) => void;
};

const MultiImagePhoto = ({
    isMainPhoto,
    mainPhotoAspectRatio,
    postImage,
    disabled,
    onDelete,
    onCrop,
}: Props) => {
    const [hovered, setHovered] = useState(false);
    const [cropperOpen, setCropperOpen] = useState(false);

    const { t } = useTranslation();
    const { containsGoogle, containsInstagram } = usePlatformSelection();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
        isSorting,
    } = useSortable({ id: postImage.data_url, disabled });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1 : undefined,
    };

    const showOverlay = !disabled && hovered && !isDragging;

    return (
        <>
            <StyledMultiImagePhoto
                isDragging={isDragging}
                onClick={() => {
                    if (showOverlay && containsInstagram) setCropperOpen(true);
                }}
                onMouseOver={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                url={postImage.data_url}
                ref={setNodeRef}
                style={style}
                {...attributes}
                {...listeners}
            >
                <StyledTooltip
                    visible={showOverlay}
                    text={t('post_photo_click_and_drag_tooltip')}
                    position="top"
                    yOffset={8}
                >
                    <StyledPhotoOverlayBackground visible={showOverlay}>
                        <StyledPhotoOverlayContent>
                            {!isSorting && (
                                <StyledMainPhotoChip visible={isMainPhoto}>
                                    {containsGoogle && (
                                        <Icon
                                            icon={[
                                                'fa-google',
                                                IconPrefix.BRANDS,
                                            ]}
                                            color="white"
                                            iconSize="12px"
                                        />
                                    )}
                                    <Text
                                        as="span"
                                        variant="bodySBold"
                                        color="white"
                                    >
                                        {t('post_photo_main_photo')}
                                    </Text>
                                </StyledMainPhotoChip>
                            )}
                            {showOverlay && (
                                <>
                                    {containsInstagram && (
                                        <StyledCropIcon
                                            icon={['fa-crop', IconPrefix.SOLID]}
                                            color="white"
                                            iconSize="12px"
                                            onClick={() => setCropperOpen(true)}
                                        />
                                    )}
                                    <StyledTrashIcon
                                        icon={['fa-trash', IconPrefix.SOLID]}
                                        color="white"
                                        iconSize="12px"
                                        onClick={onDelete}
                                    />
                                </>
                            )}
                        </StyledPhotoOverlayContent>
                        {postImage.autoCropped && (
                            <StyledAutoCroppedChip>
                                <Text
                                    as="span"
                                    variant="bodySBold"
                                    color="white"
                                >
                                    {t('post_photo_cropped_automatically')}
                                </Text>
                            </StyledAutoCroppedChip>
                        )}
                    </StyledPhotoOverlayBackground>
                </StyledTooltip>
            </StyledMultiImagePhoto>
            <ImageCropperModal
                initialAspectRatio={mainPhotoAspectRatio}
                isOpen={cropperOpen}
                onClose={() => setCropperOpen(false)}
                onApply={onCrop}
                photo={postImage}
                isMainPhoto={isMainPhoto}
            />
        </>
    );
};

export default MultiImagePhoto;
