import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { OUTLINED, Type } from 'app/common/designSystem/constants/type';
import ImageBubble from 'app/reviewManagement/messaging/activeConversation/messages/ImageBubble';
import MediaBubble from 'app/reviewManagement/messaging/activeConversation/messages/MediaBubble';
import {
    DeletedBubbleStyled,
    MessageBubbleWrapperStyled,
    StoryDetailHeader,
    StoryReplySpacer,
} from 'app/reviewManagement/messaging/activeConversation/messages/MessageBubble.styled';
import { TextBubbleContainer } from 'app/reviewManagement/messaging/activeConversation/messages/MessageBubbleContent.styled';
import TextBubble from 'app/reviewManagement/messaging/activeConversation/messages/TextBubble';

import { useMessagingSettings } from 'app/reviewManagement/messaging/hooks/useMessagingSettings';
import { SETTINGS_MESSAGES_CONVERSATION_STARTERS_PATHNAME } from 'app/routing/routeIds';
import {
    AnswerMessage,
    ButtonMessageContent,
    Conversation,
    ConversationMessage,
    ConversationStarterMessageContent,
    MESSAGE_CONTENT_TYPE,
    MESSAGE_SENDER_TYPE,
    MessageStoryReplyType,
    MessagingPartnerEnum,
    QuickRepliesMessageContent,
} from 'app/states/messaging';

type Props = {
    message: ConversationMessage;
    conversation: Conversation;
    handleBubbleClick: () => void;
    consumerName: string;
};

const MessageBubbleContent = ({
    message,
    conversation,
    handleBubbleClick,
    consumerName,
}: Props) => {
    let translationKey: string;
    const { t } = useTranslation();
    const isConsumerMessage = message.sender_type === MESSAGE_SENDER_TYPE.CONSUMER;
    const navigate = useNavigate();
    const [allSettings] = useMessagingSettings('', conversation.business.id);
    const correspondingSetting = allSettings.find(
        setting => setting.businessId === conversation.business.id,
    );

    if (message.is_deleted) {
        return (
            <MessageBubbleWrapperStyled onClick={handleBubbleClick} isIgStory={false}>
                <DeletedBubbleStyled
                    variant="bodySMedium"
                    as="span"
                    italic
                    color={'secondary'}
                    isConsumer={isConsumerMessage}
                >
                    {t('messaging_conversation_message_deleted')}
                </DeletedBubbleStyled>
            </MessageBubbleWrapperStyled>
        );
    }

    switch (message.content_type) {
        case MESSAGE_CONTENT_TYPE.TEXT: {
            const messageContent = message.content as string;
            return (
                <TextBubble
                    text={
                        conversation.messaging_partner === MessagingPartnerEnum.DELIGHT
                            ? t(messageContent)
                            : messageContent
                    }
                    isConsumerMessage={isConsumerMessage}
                    onClick={handleBubbleClick}
                />
            );
        }

        case MESSAGE_CONTENT_TYPE.ANSWER: {
            const answerContent = message.content as AnswerMessage;
            return (
                <TextBubble
                    text={answerContent.text as string}
                    isConsumerMessage={isConsumerMessage}
                    onClick={handleBubbleClick}
                />
            );
        }

        case MESSAGE_CONTENT_TYPE.IMAGE: {
            return (
                <ImageBubble
                    url={message.content as string}
                    isConsumerMessage={isConsumerMessage}
                    onClick={handleBubbleClick}
                />
            );
        }

        case MESSAGE_CONTENT_TYPE.BUTTONS: {
            const buttonsContent = message.content as ButtonMessageContent;
            return (
                <TextBubbleContainer>
                    {buttonsContent.text && (
                        <TextBubble
                            text={buttonsContent.text}
                            isConsumerMessage={isConsumerMessage}
                            onClick={handleBubbleClick}
                        />
                    )}
                    {buttonsContent.buttons.map(button => (
                        <TextBubble
                            text={button.title}
                            isConsumerMessage={isConsumerMessage}
                            bubbleType={OUTLINED as Type}
                            onClick={() => undefined}
                        />
                    ))}
                </TextBubbleContainer>
            );
        }
        case MESSAGE_CONTENT_TYPE.QUICK_REPLIES: {
            const quickRepliesContent = message.content as QuickRepliesMessageContent;
            // todo ZEEP: fix this when api call is passed back to snake_case
            const quickRepliesList =
                quickRepliesContent.quick_replies || quickRepliesContent.quickReplies;
            return (
                <TextBubbleContainer>
                    {quickRepliesContent.text && (
                        <TextBubble
                            text={quickRepliesContent.text}
                            isConsumerMessage={isConsumerMessage}
                            onClick={handleBubbleClick}
                        />
                    )}
                    {quickRepliesList.map(quickReply => (
                        <TextBubble
                            text={quickReply.title}
                            isConsumerMessage={isConsumerMessage}
                            bubbleType={OUTLINED as Type}
                            onClick={() => undefined}
                        />
                    ))}
                </TextBubbleContainer>
            );
        }
        case MESSAGE_CONTENT_TYPE.CONVERSATION_STARTER: {
            const conversationMessageContent = message.content as ConversationStarterMessageContent;
            const conversationStarterSideIcon = {
                name: 'fa-gear',
                onClickIcon: () =>
                    navigate(
                        `${SETTINGS_MESSAGES_CONVERSATION_STARTERS_PATHNAME}?conversationStarterId=${correspondingSetting?.conversationStarterId ?? ''}`,
                    ),
            };
            return (
                <TextBubbleContainer>
                    {conversationMessageContent.text && (
                        <TextBubble
                            text={conversationMessageContent.text}
                            isConsumerMessage={isConsumerMessage}
                            onClick={handleBubbleClick}
                            sideIcon={conversationStarterSideIcon}
                        />
                    )}
                    {conversationMessageContent.buttons.map(button => (
                        <TextBubble
                            text={button.title}
                            isConsumerMessage={isConsumerMessage}
                            bubbleType={OUTLINED as Type}
                            onClick={() => undefined}
                            sideIcon={conversationStarterSideIcon}
                        />
                    ))}
                </TextBubbleContainer>
            );
        }

        case MESSAGE_CONTENT_TYPE.IG_STORY_MENTION: {
            translationKey = isConsumerMessage
                ? 'messaging_meta_story_mention'
                : 'messaging_meta_story_mention_echo';
            return (
                <>
                    <StoryDetailHeader
                        variant="bodySRegular"
                        as="span"
                        isConsumer={isConsumerMessage}
                    >
                        {t(translationKey, { name: consumerName })}
                    </StoryDetailHeader>
                    <MediaBubble
                        url={message.content as string}
                        isConsumerMessage={isConsumerMessage}
                        onClick={handleBubbleClick}
                        isIgStory={true}
                    />
                </>
            );
        }

        case MESSAGE_CONTENT_TYPE.IG_STORY_REPLY: {
            translationKey = isConsumerMessage
                ? 'messaging_meta_story_reply'
                : 'messaging_meta_story_reply_echo';
            const content = message.content as MessageStoryReplyType;
            return (
                <>
                    <StoryDetailHeader
                        variant="bodySRegular"
                        as="span"
                        isConsumer={isConsumerMessage}
                    >
                        {t(translationKey, { name: consumerName })}
                    </StoryDetailHeader>
                    <MediaBubble
                        url={content.url}
                        isConsumerMessage={isConsumerMessage}
                        onClick={handleBubbleClick}
                        isIgStory={true}
                    />
                    <StoryReplySpacer />
                    {content.content_type === MESSAGE_CONTENT_TYPE.IMAGE ? (
                        <ImageBubble
                            url={content.content}
                            isConsumerMessage={isConsumerMessage}
                            onClick={handleBubbleClick}
                        />
                    ) : (
                        <TextBubble
                            text={content.content}
                            isConsumerMessage={isConsumerMessage}
                            onClick={handleBubbleClick}
                            isIgStory={true}
                        />
                    )}
                </>
            );
        }

        default:
            return null;
    }
};

export default MessageBubbleContent;
