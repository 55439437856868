import { useMemo } from 'react';

import { Option } from '@partoohub/ui';

import { KeywordType } from 'app/api/types/competitiveBenchmark';
import BusinessModalButton from 'app/common/components/businessModal/BusinessModalButton';
import { FilterBusinessModalButton } from 'app/common/components/businessModal/components/FilterBusinessModalButton/FilterBusinessModalButton';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { ComparisonPeriodSelect } from 'app/pages/analytics/CompetitiveBenchmark/components/ComparisonPeriodSelect/ComparisonPeriodSelect';
import { FiltersSectionContainer } from 'app/pages/analytics/CompetitiveBenchmark/components/FiltersSection/FiltersSection.styled';
import { KeywordDropDownSelection } from 'app/pages/analytics/CompetitiveBenchmark/components/KeywordsDropDownSelection/KeywordsDropDownSelection';
import { MonthPicker } from 'app/pages/analytics/CompetitiveBenchmark/components/MonthPicker/MonthPicker';

type Props = {
    allAvailableKeywords: KeywordType[];
    currentDate: Date;
    setCurrentDate: (date: Date) => void;
};

const DEFAULT_PERIOD = 'month';

export const FiltersSection = ({ allAvailableKeywords, currentDate, setCurrentDate }: Props) => {
    const defaultKeyword = allAvailableKeywords?.[0]?.keyword;

    const [currentKeyword, setCurrentKeyword] = useStateQueryParams('keyword');
    const [period, setPeriod] = useStateQueryParams('period');

    const currentCompetitiveKeyword =
        allAvailableKeywords.find(keyword => keyword.keyword === currentKeyword) ??
        allAvailableKeywords[0];

    const buildSelectOption = (keyword: string): Option => ({
        value: keyword,
        name: keyword,
        label: keyword,
    });

    const selectedKeyword = useMemo(
        () => buildSelectOption(currentKeyword !== '' ? currentKeyword : defaultKeyword),
        [currentKeyword, allAvailableKeywords],
    );

    const handleChangeKeyword = (option?: Option) => {
        setCurrentKeyword(option?.value ?? '');
    };

    const selectedPeriod = useMemo(
        () => buildSelectOption(period !== '' ? period : DEFAULT_PERIOD),
        [period, allAvailableKeywords],
    );
    const handleChangePeriod = (option?: Option) => {
        setPeriod(option?.value ?? '');
    };

    const sections = useMemo(
        () => [
            {
                options: allAvailableKeywords.map(keyword => buildSelectOption(keyword.keyword)),
            },
        ],
        [allAvailableKeywords],
    );

    return (
        <FiltersSectionContainer>
            <BusinessModalButton
                key="business-modal-key"
                dataTrack="analytics_competitive_benchmark__business_filter"
                button={
                    <div>
                        <FilterBusinessModalButton />
                    </div>
                }
            />
            <KeywordDropDownSelection
                currentKeyword={selectedKeyword}
                allAvailableKeywords={sections}
                setCurrentKeyword={handleChangeKeyword}
            />
            <MonthPicker
                availableDates={currentCompetitiveKeyword.dates}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
            />
            <ComparisonPeriodSelect
                currentComparisonPeriod={selectedPeriod}
                setCurrentComparisonPeriod={handleChangePeriod}
            />
        </FiltersSectionContainer>
    );
};
