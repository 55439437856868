import { FontAwesomeIconsPartooUsed, Icon, Text } from '@partoohub/ui';
import { useMatch } from 'react-router-dom';

import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks/useDeviceType';

import { SettingMenuItemContainer } from './SettingMenuItem.styled';

type SettingMenuItem = {
    name: string;
    value: string;
    label: string;
    pathname: string;
};
interface SettingMenuItemProps {
    item: SettingMenuItem;
}

export const SettingMenuItem = ({ item }: SettingMenuItemProps) => {
    const match = useMatch(`${item.pathname}/*`);
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const getColor = () => {
        if (match) {
            return 'primary';
        }
        return 'secondary';
    };

    return (
        <SettingMenuItemContainer key={item.label} to={item.pathname} isActive={!!match}>
            <Text variant="bodyMBold" as="span" color={getColor()}>
                {item.label}
            </Text>
            {isBelowProvidedDevices && (
                <Icon
                    icon={[FontAwesomeIconsPartooUsed.faChevronRight]}
                    iconSize="16px"
                    color="secondary"
                />
            )}
        </SettingMenuItemContainer>
    );
};
