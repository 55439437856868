import { Dispatch, SetStateAction, useState } from 'react';

import { Banner } from '@partoohub/ui';

import { addDays } from 'date-fns';
import { useTranslation } from 'react-i18next';

import LoadingView from 'app/common/components/LoadingView';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import useLocalStorage from 'app/common/hooks/useLocalStorage';
import { MetaRelogin } from 'app/pages/visibility/subComponents/MetaRelogin/MetaRelogin';

import { BannerContainer } from './MessagingBanners.styled';
import { useConversationStats } from '../hooks/useConversationStats';
import { useMessagingSettingsStats } from '../hooks/useMessagingSettingsStats';
import { useShowMissingAccessBanner } from '../hooks/useShowMissingAccessBanner';
import MissingAccessBanner from '../MissingAccessBanner';

const SLA_BREACHED_THRESHOLD = 24;

export const MessagingBanners = () => {
    const [, displayActivateMessagingBanner, isLoading] = useMessagingSettingsStats();
    const { data: conversationStats } = useConversationStats();
    const showMissingAccessBanner = useShowMissingAccessBanner();
    const { data: me } = useMe();
    const [StorageSLADate, setStorageSLADate] = useLocalStorage<Date | null>(
        `SLADate-${me?.id}`,
        null,
    );

    if (isLoading) {
        return <LoadingView />;
    }

    const now = new Date();
    const SLADate = StorageSLADate ? new Date(StorageSLADate) : null;
    const isLaterThanSLADate = SLADate ? now > SLADate : true;

    const hasSLABanner =
        conversationStats?.median_sla &&
        conversationStats?.median_sla >= SLA_BREACHED_THRESHOLD &&
        isLaterThanSLADate;

    const hasBanner = showMissingAccessBanner ?? hasSLABanner ?? displayActivateMessagingBanner;

    return (
        <BannerContainer hasBanner={hasBanner}>
            {showMissingAccessBanner && <MissingAccessBanner />}
            <SLABanner isBannerShown={!!hasSLABanner} setStorageSLADate={setStorageSLADate} />
            <ActivateMessagingBanner isBannerShown={!!displayActivateMessagingBanner} />
            {!showMissingAccessBanner && <MetaRelogin />}
        </BannerContainer>
    );
};

interface SLABannerProps {
    isBannerShown: boolean;
    setStorageSLADate: Dispatch<SetStateAction<Date | null>>;
}

const SLABanner = ({ isBannerShown, setStorageSLADate }: SLABannerProps) => {
    const { t } = useTranslation();
    const [isShown, setIsShown] = useState<boolean>(isBannerShown);

    if (!isShown) return null;

    const onClose = () => {
        const now = new Date();
        const SLAThresholdDate = addDays(now, 7);
        setIsShown(false);
        setStorageSLADate(SLAThresholdDate);
    };

    return (
        <Banner dataTrackId="sla_banner" variant="warning" onClose={onClose}>
            {t('sla_banner_warning')}
        </Banner>
    );
};

interface ActivateMessagingBannerProps {
    isBannerShown: boolean;
}

const ActivateMessagingBanner = ({ isBannerShown }: ActivateMessagingBannerProps) => {
    const { t } = useTranslation();
    const [isShown, setIsShown] = useState<boolean>(isBannerShown);

    if (!isShown) return null;

    const onClose = () => {
        setIsShown(false);
    };

    return (
        <Banner dataTrackId="messages_deactivated_banner" variant="info" onClose={onClose}>
            {t('banner_for_messages_deactivated_text')}
        </Banner>
    );
};
