import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';
import {
    CONVERSATION_STARTERS,
    CONVERSATION_STARTERS_ITEMS,
} from 'app/common/data/queryKeysConstants';
import { formatMentionToPlaceholder } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/services/utils';
import {
    ConversationStarterItem,
    ConversationStarterItemPostBackCreatePayload,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';

export default function useConversationStarterItemCreate(
    conversationStarterId: string,
    onSuccess: () => void,
) {
    const queryClient = useQueryClient();
    return useMutation(
        (payload: ConversationStarterItemPostBackCreatePayload) => {
            payload.reply_message = formatMentionToPlaceholder(payload.reply_message);
            return api.conversationStarters.createConversationStarterItem(
                conversationStarterId,
                payload,
            );
        },
        {
            onSuccess: (data: ConversationStarterItem) => {
                queryClient.invalidateQueries([
                    CONVERSATION_STARTERS_ITEMS,
                    data.conversation_starter_id,
                ]);
                queryClient.invalidateQueries([CONVERSATION_STARTERS]);
                onSuccess();
            },
        },
    );
}
