import { TFunction } from 'i18next';

export const getDefaultDropzoneTranslations = (
    t: TFunction,
    options?: {
        acceptedFiles?: Record<string, string[]>;
        minSize?: number;
        maxSize?: number;
        maxFile?: number;
    },
) => {
    const acceptedFiles = [];
    if (options?.acceptedFiles) {
        for (const [fileType, extension] of Object.entries(options.acceptedFiles)) {
            acceptedFiles.push(fileType);
            acceptedFiles.push(...extension);
        }
    }
    return {
        'file-invalid-type': t('dropzone_error__file_invalid_type', {
            acceptedFiles: acceptedFiles.join(','),
        }),
        'file-too-large': t('dropzone_error__file_too_large', { maxSize: options?.maxSize }),
        'file-too-small': t('dropzone_error__file_too_small', { minSize: options?.minSize }),
        'too-many-files': t('dropzone_error__too_many_files', { maxFile: options?.maxFile }),
    };
};
