export const IllustrationSlide2 = () => {
    return (
        <svg
            width="157"
            height="95"
            viewBox="0 0 157 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="157"
                height="95"
                rx="8"
                fill="url(#paint0_linear_0_2131)"
                fillOpacity="0.12"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.9835 74.5495C66.6282 89.1942 90.3719 89.1942 105.017 74.5495C119.661 59.9049 119.661 36.1612 105.017 21.5165C90.3719 6.87186 66.6282 6.87186 51.9835 21.5165C37.3388 36.1612 37.3388 59.9049 51.9835 74.5495Z"
                fill="#0085F2"
                fillOpacity="0.12"
            />
            <path
                d="M71.8392 40.6223C70.5897 40.6223 69.5765 41.6356 69.5765 42.885C69.5765 44.1345 70.5897 45.1478 71.8392 45.1478C73.0887 45.1478 74.1019 44.1345 74.1019 42.885C74.1019 41.6356 73.0908 40.6223 71.8392 40.6223ZM58.2628 40.6223C56.9688 40.6223 56 41.6808 56 42.885C56 44.0914 56.9709 45.0841 58.2628 45.0841C59.5957 45.0841 60.0616 44.1437 60.1741 44.0164H62.7882V41.7537H60.1741C60.0616 41.6264 59.5957 40.6223 58.2628 40.6223ZM85.4157 40.6223C84.1662 40.6223 83.1529 41.6356 83.1529 42.885C83.1529 44.1345 84.1662 45.1478 85.4157 45.1478C86.6651 45.1478 87.6784 44.1345 87.6784 42.885C87.6784 41.6356 86.6672 40.6223 85.4157 40.6223ZM98.9921 40.6223C97.6592 40.6223 97.1932 41.5635 97.0808 41.7537H94.4666V44.0164H97.0815C97.1961 44.271 97.6627 45.1478 98.9921 45.1478C100.285 45.1478 101.255 44.0914 101.255 42.9487C101.255 41.806 100.286 40.6223 98.9921 40.6223Z"
                fill="#0085F2"
            />
            <path
                opacity="0.4"
                d="M88.6823 29.3722H68.3177C65.1922 29.3722 62.6608 31.9044 62.6608 35.0291V53.0674C62.6608 56.1914 65.1937 58.7242 68.3177 58.7242H71.7118V64.6632C71.7118 64.9609 71.9027 65.5761 72.5603 65.5761C72.7387 65.5761 72.9137 65.5189 73.0621 65.4115L81.8941 58.7242L88.6823 58.7246C91.8063 58.7246 94.3392 56.1918 94.3392 53.0678V35.0291C94.3392 31.9044 91.8077 29.3722 88.6823 29.3722ZM89.8137 47.4741C89.8137 48.7236 88.8004 49.7369 87.551 49.7369H69.449C68.1974 49.7369 67.1863 48.7257 67.1863 47.4741V38.4232C67.1863 37.1737 68.1996 36.1604 69.449 36.1604H87.551C88.8004 36.1604 89.8137 37.1737 89.8137 38.4232V47.4741Z"
                fill="#0085F2"
            />
            <rect x="4" y="4" width="21" height="21" rx="10.5" fill="#0085F2" fillOpacity="0.12" />
            <path
                d="M11.458 17.746L14.71 14.386C14.91 14.186 15.07 14.002 15.19 13.834C15.31 13.666 15.398 13.51 15.454 13.366C15.51 13.214 15.538 13.062 15.538 12.91C15.538 12.582 15.43 12.326 15.214 12.142C15.006 11.95 14.73 11.854 14.386 11.854C14.05 11.854 13.746 11.946 13.474 12.13C13.21 12.306 12.954 12.598 12.706 13.006L11.446 11.914C11.782 11.346 12.21 10.918 12.73 10.63C13.25 10.342 13.846 10.198 14.518 10.198C15.102 10.198 15.606 10.306 16.03 10.522C16.462 10.738 16.794 11.046 17.026 11.446C17.266 11.846 17.386 12.314 17.386 12.85C17.386 13.186 17.342 13.498 17.254 13.786C17.166 14.066 17.022 14.346 16.822 14.626C16.63 14.898 16.37 15.198 16.042 15.526L13.786 17.77L11.458 17.746ZM11.458 18.802V17.746L13.054 17.194H17.554V18.802H11.458Z"
                fill="#0085F2"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_0_2131"
                    x1="78.5"
                    y1="0"
                    x2="78.5"
                    y2="124.292"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C182FF" />
                    <stop offset="1" stopColor="#0085F2" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};
