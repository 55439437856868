import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

export const BusinessCount = styled.div`
    margin-top: 16px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
    color: ${HEX_COLORS.blackberry};
`;

interface BusinessSortSectionContainerProps {
    displayBulkCheckbox?: boolean;
}

export const BusinessSortSectionContainer = styled.div<BusinessSortSectionContainerProps>`
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    position: relative;
    background: ${greyColorObject.light};
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 0 24px 0 ${({ displayBulkCheckbox }) => (displayBulkCheckbox ? '21px' : '24px')};
`;

export const BusinessSortCompletion = styled.div`
    position: absolute;
    right: 126px;
    transform: translateX(50%);
`;
