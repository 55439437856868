import { ReactNode } from 'react';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import rtlPlugin from 'stylis-plugin-rtl';

import getLang from 'app/common/services/getLang';
import { RTL_LANGUAGES } from 'app/common/services/languages';

interface Props {
    children: ReactNode;
}

const RTLCacheProvider = ({ children }: Props) => {
    const lang = getLang();
    const cache = RTL_LANGUAGES.includes(lang)
        ? createCache({
              key: 'rtl',
              stylisPlugins: [rtlPlugin],
          })
        : createCache({ key: 'ltr' });
    return <CacheProvider value={cache}>{children}</CacheProvider>;
};

export default RTLCacheProvider;
