import { ReviewAutoReplySettingsTemplate } from 'app/api/types/reviewAutoReplySettings';
import {
    FormattedReplyTemplate,
    NEGATIVE_REVIEWS,
    NEUTRAL_REVIEWS,
    POSITIVE_REVIEWS,
} from 'app/api/types/reviewReplyTemplates';

/**
 * Format and camelCasify a ReplyTemplate provided by the AutoReplySettings api:
 */
export const formatAutoReplySettingsTemplate = (
    t: ReviewAutoReplySettingsTemplate,
): FormattedReplyTemplate => {
    return {
        id: t.id,
        language: t.language,
        title: t.title,
        text: t.text,
        orgId: t.org_id,
        updatedBy: t.updated_by,
        ratings: ([POSITIVE_REVIEWS, NEUTRAL_REVIEWS, NEGATIVE_REVIEWS] as const).filter(
            rating => t[rating],
        ),
        creationDate: t.creation_date,
        updateDate: t.update_date ?? undefined,
        isAutoReply: true,
    };
};
