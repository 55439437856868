import { autoCrop } from './autoCrop';
import { ImageListType, ImageType } from '../../../../context/NewPost';

const recropOneImage = async (
    image: ImageType,
    htmlImg: HTMLImageElement,
    aspectRatio: number,
): Promise<ImageType> => {
    if (image.width / image.height === aspectRatio) return image;

    const autoCroppedImage = autoCrop(htmlImg, aspectRatio);
    const blob = await new Promise<Blob | null>(resolve =>
        autoCroppedImage.canvas.toBlob(resolve, 'image/jpeg'),
    );
    return {
        original: image.original,
        data_url: URL.createObjectURL(blob!),
        file: new File(
            [blob!],
            image.original.file instanceof File ? image.original.file.name : '',
            { type: 'image/jpeg' },
        ),
        width: autoCroppedImage.canvas.width,
        height: autoCroppedImage.canvas.height,
        autoCropped: autoCroppedImage.autoCropped,
    };
};

export const recropImages = async (
    images: ImageListType,
    aspectRatio: number,
) => {
    return Promise.all(
        images.map(
            image =>
                new Promise<ImageType>((resolve, reject) => {
                    const htmlImg = new Image();

                    htmlImg.onload = () =>
                        resolve(recropOneImage(image, htmlImg, aspectRatio));
                    htmlImg.onerror = () =>
                        reject(new Error('unexpected error'));

                    htmlImg.crossOrigin = 'anonymous';
                    htmlImg.src = image.original.data_url;
                }),
        ),
    );
};
