import { Stack } from '@partoohub/ui';
import { IconButton, IconPrefix, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

type Props = {
    ipAddress: string;
    removeIpAddress: (index: number) => void;
    index: number;
    changeIPAddress: (index: number, newIP: string) => void;
    ipError: boolean;
};

const IPAddress = ({ removeIpAddress, index, changeIPAddress, ipAddress, ipError }: Props) => {
    const { t } = useTranslation();
    return (
        <Stack direction="row" alignItems="center">
            <TextInput
                dataTrackId="api_key_manager_ip_restrictions_input"
                value={ipAddress}
                placeholder={t('api_key_manager_ip_restrictions_input_placeholder')}
                onChange={newIP => changeIPAddress(index, newIP)}
                error={ipError}
                notice={ipError && t('api_key_manager_ip_restrictions_error')}
                autoFocus
            />
            <IconButton
                dataTrackId="close_model_apikey"
                appearance="outlined"
                variant="danger"
                onClick={() => removeIpAddress(index)}
                icon={['fa-trash-alt', IconPrefix.SOLID]}
            />
        </Stack>
    );
};

export default IPAddress;
