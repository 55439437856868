import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import { Homepage as HomepageComponent } from './Homepage';

export const Homepage = () => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('homepage_title')} />
            <HomepageComponent />
        </>
    );
};
