import { useTranslation } from 'react-i18next';

import BusinessEditV2 from 'app/businessEditV2';
import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

export const BusinessEditV2Page = () => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('info_view_title')} />
            <BusinessEditV2 />
        </>
    );
};
