import React from 'react';

import GoogleMapsMap from 'app/common/components/form/GoogleMaps/GoogleMapsMap';
import GoogleMapsStreetView from 'app/common/components/form/GoogleMaps/GoogleMapsStreetView';

import { GoogleMapsMain } from './GoogleMaps.styled';

type Props = {
    lat: number | null;
    long: number | null;
    onDrag: (lat: number, long: number) => void;
    disabled?: boolean;
};

const GoogleMaps = ({ lat, long, onDrag, disabled = false }: Props) => (
    <GoogleMapsMain disabled={disabled}>
        <GoogleMapsMap lat={lat} long={long} onDrag={onDrag} disabled={disabled} />
        <GoogleMapsStreetView lat={lat} long={long} onDrag={onDrag} disabled={disabled} />
    </GoogleMapsMain>
);

export default GoogleMaps;
