import IS_IFRAME from 'app/common/data/iframe';
import { GROUP_MANAGER, ORG_ADMIN } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMe';
import { useCanCreateBusiness } from 'app/pages/visibility/BusinessListV2/hooks/useCanCreateBusiness';
import { hideableComponentsNames, withHideableComponent } from 'app/SDKBridge';

import CreateBusinessButton from './Buttons/CreateBusinessButton';
import { DiffusionListButton } from './Buttons/DiffusionListButton';
import ManageGroupsButton from './Buttons/ManageGroupsButton';

const HeaderButton = () => {
    const { data: me } = useMe();

    const canCreateBusiness = useCanCreateBusiness();

    return (
        <>
            {[ORG_ADMIN, GROUP_MANAGER].includes(me?.role ?? '') && <ManageGroupsButton />}
            {canCreateBusiness && <CreateBusinessButton />}
            {!IS_IFRAME && <DiffusionListButton />}
        </>
    );
};

export default withHideableComponent(HeaderButton, hideableComponentsNames.KNOWLEDGE_ADD_BUTTON);
