import { useEffect, useMemo } from 'react';

import { Stack } from '@partoohub/ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditForm from 'app/businessEditV2/components/EditForm';
import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import { useIsLoadingCustomFields } from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingCustomFields';
import useCustomFieldsSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useCustomFieldsSectionColor';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import { useCustomFieldsSectionsWithOrg } from 'app/businessEditV2/hooks/editV2utils/useCustomFieldsSectionsWithOrg';
import usePermissions from 'app/businessEditV2/hooks/permissions/usePermissions';
import { CustomField } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomField';
import { CustomFieldSection } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldSection/CustomFieldSection';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';

import { getCleanData } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/services';
import {
    CUSTOM_FIELD_SECTION_TYPE,
    CUSTOM_FIELD_TYPE,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/typing';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

import { useCustomFieldsSectionContext } from './hooks/useCustomFieldsDisabledSaveButton';

type nameMapping = {
    name: string;
    mapping: string;
};

export type namesMapping = Array<nameMapping>;

type Props = {
    closeModal: () => void;
    useDirtyUpdate: (isDirty: boolean) => void;
};

export const CustomFieldFormWithSections = ({ closeModal, useDirtyUpdate }: Props) => {
    const { t } = useTranslation();
    const colorState = useCustomFieldsSectionColor();
    const { data: business } = useBusiness();
    const { data: permissions } = usePermissions();
    const { data: customFieldsSections } = useCustomFieldsSectionsWithOrg();
    const isLoading = useIsLoadingCustomFields();
    const customFields = business?.custom_fields ?? [];

    const { disabled } = useCustomFieldsSectionContext();

    // To avoid issues with single quotes with react hook form
    const namesMapping = useMemo(
        () =>
            customFields.map((customField, index) => ({
                name: customField.name,
                mapping: 'cf_' + index,
            })),
        [customFields],
    );

    const setFormValue = () => {
        const customFields = {};
        const businessCustomFields = business?.custom_fields ?? [];
        businessCustomFields.forEach(customField => {
            const mapping = namesMapping.find(cf => cf.name === customField.name)!.mapping;
            customFields[mapping] = customField.value;
        });
        return customFields;
    };

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    useDirtyUpdate(isDirty);

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const businessUpdate = useBusinessUpdate(() => {
        closeModal();
    });

    // @ts-ignore
    const errors = businessUpdate.error?.response?.data?.errors?.json?.custom_fields ?? {};

    const onSubmit = payload => {
        businessUpdate.mutate(payload);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_CUSTOM_FIELDS_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
    };

    // Permissions
    const businessIsOpen = !useIsBusinessClosed();
    const metadata =
        permissions?.find(section => section.name === 'custom_fields' && businessIsOpen)?.fields ??
        [];

    const enabledFields = customFields
        .filter(customField => {
            const field = metadata?.find(
                permissionField => permissionField.name === customField.name,
            );
            return field?.enabled && businessIsOpen;
        })
        .map(field => field.name);

    const formatPayload = payload => {
        const result = {};
        for (const [key, value] of Object.entries(payload)) {
            const name = namesMapping.find(cf => cf.mapping === key)!.name;
            if (enabledFields.includes(name)) {
                result[name] = value;
            }
        }
        return { custom_fields: result };
    };

    const submitForm = () => {
        handleSubmit(payload => onSubmit(formatPayload(payload)))();
        trackSaveBusinessEditSection(SectionNames.CUSTOM_FIELDS, colorState);
    };

    const cleanCFdata = getCleanData(metadata, customFieldsSections);

    return (
        <>
            <EditForm
                title={t('custom_fields')}
                description={t('business_edit_custom_fields_description')}
                colorState={colorState}
            >
                <Stack gap="36px">
                    {cleanCFdata.map(data => (
                        <>
                            {data.type === CUSTOM_FIELD_TYPE
                                ? !data.obj.section_id && (
                                      <div key={`${CUSTOM_FIELD_TYPE}_${data.obj.id}`}>
                                          <CustomField
                                              customField={data.obj}
                                              namesMapping={namesMapping}
                                              errors={errors}
                                              control={control}
                                          />
                                      </div>
                                  )
                                : data?.customFields?.length > 0 && (
                                      <div key={`${CUSTOM_FIELD_SECTION_TYPE}_${data.obj.id}`}>
                                          <CustomFieldSection
                                              section={data.obj}
                                              customFields={data?.customFields}
                                              namesMapping={namesMapping}
                                              errors={errors}
                                              control={control}
                                          />
                                      </div>
                                  )}
                        </>
                    ))}
                </Stack>
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={businessUpdate.isLoading}
                    hasError={!!businessUpdate.error}
                    onClick={submitForm}
                    disabled={disabled}
                    dataTrackId="visibility_location__form_custom_fields__save_button"
                />
            </EditFormFooter>
        </>
    );
};
