import { cloneDeep } from 'lodash-es';

import {
    PlaceActionLinkUrlTypeForm,
    PlaceActionLinkUrlsError,
} from 'app/api/types/placeActionLinks';
import usePlaceActionLinksTranslations from 'app/businessEditV2/hooks/placeActionLinks/usePlaceActionLinksTranslations';

import { PlaceActionLinkUrls } from './PlaceActionLinkUrls';
import { PlaceActionLinkName, PlaceActionLinksContainer } from './PlaceActionLinkUrls.styled';

type Props = {
    value: Record<string, Array<PlaceActionLinkUrlTypeForm>>;
    onChange: (value: Record<string, Array<PlaceActionLinkUrlTypeForm>>) => void;
    errors?: Record<string, PlaceActionLinkUrlsError>;
    disabled?: boolean;
};

export const PlaceActionLinks = ({ value, onChange, errors, disabled }: Props) => {
    const placeActionLinksTranslations = usePlaceActionLinksTranslations();

    const onPlaceActionLinkChange = (urls: Array<PlaceActionLinkUrlTypeForm>, gmbId: string) => {
        const result = cloneDeep(value);
        result[gmbId] = urls.length === 1 && !urls[0].value ? [] : urls;
        onChange(result);
    };

    const sortedPlaceActionLinks = Object.entries(value)
        .map(([gmbId, urls], index) => ({
            urls: urls,
            gmbId: gmbId,
            displayName: placeActionLinksTranslations?.[gmbId] ?? gmbId,
            key: `place_action_link_${gmbId}}`,
            originalIndex: index,
        }))
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

    const originalToSortedIndexMapping: Record<number, number> = {};
    sortedPlaceActionLinks.forEach((item, sortedIndex) => {
        originalToSortedIndexMapping[item.originalIndex] = sortedIndex;
    });

    const remappedErrors: Record<string, PlaceActionLinkUrlsError> = {};
    if (errors) {
        Object.entries(errors).forEach(([originalIndex, error]) => {
            const sortedIndex = originalToSortedIndexMapping[originalIndex];
            remappedErrors[sortedIndex] = error;
        });
    }

    return (
        <div>
            {sortedPlaceActionLinks.map((placeActionLink, index) => {
                return (
                    <PlaceActionLinksContainer key={placeActionLink.key}>
                        <PlaceActionLinkName variant="bodyMBold">
                            {placeActionLink.displayName}
                        </PlaceActionLinkName>

                        <PlaceActionLinkUrls
                            urls={placeActionLink.urls}
                            onChange={urls => onPlaceActionLinkChange(urls, placeActionLink.gmbId)}
                            errors={remappedErrors?.[index]?.urls}
                            disabled={disabled}
                        />
                    </PlaceActionLinksContainer>
                );
            })}
        </div>
    );
};
