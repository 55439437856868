export const IllustrationSlide1 = () => {
    const filter_1 = (
        <>
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
            />
            <feOffset dy="1.48181" />
            <feGaussianBlur stdDeviation="3.70454" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_2123" />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_0_2123"
                result="shape"
            />
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
            />
            <feOffset dy="1.48181" />
            <feGaussianBlur stdDeviation="3.70454" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_2123" />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_0_2123"
                result="shape"
            />
        </>
    );

    const filter_2 = (
        <>
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
            />
            <feOffset dy="1.48181" />
            <feGaussianBlur stdDeviation="1.85227" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_2123" />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_0_2123"
                result="shape"
            />
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
            />
            <feOffset dy="1.48181" />
            <feGaussianBlur stdDeviation="1.85227" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_2123" />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_0_2123"
                result="shape"
            />
        </>
    );

    const filter_3 = (
        <>
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
            />
            <feOffset dy="1.48181" />
            <feGaussianBlur stdDeviation="2.7784" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_2123" />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_0_2123"
                result="shape"
            />
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
            />
            <feOffset dy="1.48181" />
            <feGaussianBlur stdDeviation="2.7784" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_2123" />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_0_2123"
                result="shape"
            />
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
            />
            <feOffset dy="1.48181" />
            <feGaussianBlur stdDeviation="2.7784" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_2123" />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_0_2123"
                result="shape"
            />
        </>
    );
    return (
        <svg
            width="157"
            height="95"
            viewBox="0 0 157 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="157"
                height="95"
                rx="8"
                fill="url(#paint0_linear_0_2123)"
                fillOpacity="0.12"
            />
            <g filter="url(#filter0_d_0_2123)">
                <rect
                    x="27.8333"
                    y="29.7809"
                    width="56.2083"
                    height="33.6085"
                    rx="7.40907"
                    transform="rotate(-12 27.8333 29.7809)"
                    fill="#B2C7E6"
                />
                <path
                    d="M51.9622 63.6781L44.1213 58.3874L59.3131 55.1583L54.5897 63.0904C54.0453 64.0047 52.8443 64.2733 51.9622 63.6781Z"
                    fill="#B2C7E6"
                />
            </g>
            <g filter="url(#filter1_d_0_2123)">
                <rect
                    x="35.9234"
                    y="37.6055"
                    width="43.2656"
                    height="5.22799"
                    rx="2.61399"
                    transform="rotate(-12 35.9234 37.6055)"
                    fill="white"
                />
            </g>
            <g filter="url(#filter2_d_0_2123)">
                <rect
                    x="37.8644"
                    y="46.7372"
                    width="43.2656"
                    height="5.22799"
                    rx="2.61399"
                    transform="rotate(-12 37.8644 46.7372)"
                    fill="white"
                />
            </g>
            <g filter="url(#filter3_d_0_2123)">
                <rect
                    x="73.1954"
                    y="37.3463"
                    width="56.2083"
                    height="33.6085"
                    rx="7.40907"
                    transform="rotate(6 73.1954 37.3463)"
                    fill="#CCF0D9"
                />
                <path
                    d="M85.6684 77.0409L79.8462 69.5861L95.2924 71.2096L88.349 77.2938C87.5487 77.9951 86.3234 77.8795 85.6684 77.0409Z"
                    fill="#CCF0D9"
                />
            </g>
            <g filter="url(#filter4_d_0_2123)">
                <ellipse
                    cx="87.6205"
                    cy="55.7593"
                    rx="4.4375"
                    ry="4.48113"
                    transform="rotate(6 87.6205 55.7593)"
                    fill="#25D366"
                />
            </g>
            <g filter="url(#filter5_d_0_2123)">
                <ellipse
                    cx="99.3891"
                    cy="56.9962"
                    rx="4.4375"
                    ry="4.48113"
                    transform="rotate(6 99.3891 56.9962)"
                    fill="#25D366"
                />
            </g>
            <g filter="url(#filter6_d_0_2123)">
                <ellipse
                    cx="111.158"
                    cy="58.2331"
                    rx="4.4375"
                    ry="4.48113"
                    transform="rotate(6 111.158 58.2331)"
                    fill="#25D366"
                />
            </g>
            <g filter="url(#filter7_d_0_2123)">
                <circle cx="81.5005" cy="19.5938" r="6.72917" fill="#FF5C9A" />
                <path
                    d="M81.0576 22.8213V17.2188H82.2955V22.8213H81.0576ZM79.9783 18.2742V17.2188H82.2162V18.2742H79.9783Z"
                    fill="white"
                />
            </g>
            <path
                d="M81.6667 28C86.0849 28 89.6667 24.4183 89.6667 20C89.6667 15.5817 86.0849 12 81.6667 12C77.2484 12 73.6667 15.5817 73.6667 20C73.6667 24.4183 77.2484 28 81.6667 28Z"
                fill="#25D366"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M85.9916 19.8795C85.9911 21.3852 85.1949 22.7786 83.8979 23.5435C82.6009 24.3083 80.9962 24.3308 79.6783 23.6025L77.3255 24.3588L78.0931 22.0737C77.1649 20.5332 77.3056 18.5755 78.4445 17.1837C79.5835 15.7918 81.4746 15.2664 83.1683 15.8714C84.8619 16.4764 85.9922 18.081 85.9916 19.8795ZM85.2811 19.8795C85.2841 18.3436 84.2972 16.9807 82.8371 16.5042C81.3771 16.0277 79.7763 16.5461 78.8727 17.7881C77.9691 19.03 77.9685 20.7127 78.8712 21.9552L78.4258 23.2698L79.7911 22.8356C80.8783 23.554 82.2725 23.6168 83.4199 22.999C84.5673 22.3811 85.2824 21.1827 85.2811 19.8795ZM83.949 21.0636C83.9504 21.035 83.9466 21.0063 83.9377 20.979C83.8982 20.9114 82.9791 20.4321 82.8853 20.4342C82.8142 20.4342 82.7118 20.5616 82.6057 20.6935C82.4921 20.8348 82.3744 20.9811 82.2869 20.9811C82.2428 20.9793 82.2 20.9656 82.1629 20.9417C81.8511 20.797 81.5636 20.6049 81.3107 20.3722C81.0763 20.1483 80.8818 19.8861 80.7356 19.5968C80.7187 19.5717 80.709 19.5425 80.7074 19.5122C80.7074 19.4553 80.7824 19.376 80.8666 19.2871C80.9741 19.1736 81.0964 19.0444 81.0964 18.9258C81.0964 18.8694 80.8089 18.1019 80.7694 18.006C80.713 17.8594 80.6848 17.8143 80.5326 17.8143C80.4938 17.8143 80.4579 17.8099 80.4227 17.8056C80.3889 17.8014 80.3557 17.7974 80.3211 17.7974C80.2104 17.7974 80.1044 17.8421 80.0272 17.9214C79.7565 18.1787 79.6212 18.4402 79.6099 18.813V18.8582C79.6043 19.2479 79.7988 19.6363 80.0103 19.9528C80.4966 20.6696 80.9971 21.2955 81.8055 21.6592C82.048 21.7748 82.6062 22.0116 82.8776 22.0116C83.199 22.0116 83.7234 21.8086 83.8531 21.4929C83.9102 21.3566 83.9427 21.2112 83.949 21.0636Z"
                fill="white"
            />
            <rect x="4" y="4" width="21" height="21" rx="10.5" fill="#0085F2" fillOpacity="0.12" />
            <path
                d="M14.38 18.736V10.264H16.252V18.736H14.38ZM12.748 11.86V10.264H16.132V11.86H12.748Z"
                fill="#0085F2"
            />
            <defs>
                <filter
                    id="filter0_d_0_2123"
                    x="21.8013"
                    y="13.5442"
                    width="74.0318"
                    height="59.3419"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    {filter_1}
                </filter>
                <filter
                    id="filter1_d_0_2123"
                    x="32.7047"
                    y="26.8732"
                    width="49.8446"
                    height="20.5466"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    {filter_2}
                </filter>
                <filter
                    id="filter2_d_0_2123"
                    x="34.6457"
                    y="36.0048"
                    width="49.8446"
                    height="20.5466"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    {filter_2}
                </filter>
                <filter
                    id="filter3_d_0_2123"
                    x="63.0065"
                    y="32.1523"
                    width="72.7651"
                    height="54.4916"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    {filter_1}
                </filter>
                <filter
                    id="filter4_d_0_2123"
                    x="77.6254"
                    y="47.2032"
                    width="19.9903"
                    height="20.0756"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    {filter_3}
                </filter>
                <filter
                    id="filter5_d_0_2123"
                    x="89.3939"
                    y="48.4402"
                    width="19.9903"
                    height="20.0756"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    {filter_3}
                </filter>
                <filter
                    id="filter6_d_0_2123"
                    x="101.162"
                    y="49.6771"
                    width="19.9903"
                    height="20.0756"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    {filter_3}
                </filter>
                <filter
                    id="filter7_d_0_2123"
                    x="70.0083"
                    y="10.0068"
                    width="22.9843"
                    height="22.9843"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.90519" />
                    <feGaussianBlur stdDeviation="2.38149" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_0_2123"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_0_2123"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_0_2123"
                    x1="78.5"
                    y1="0"
                    x2="78.5"
                    y2="124.292"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C182FF" />
                    <stop offset="1" stopColor="#0085F2" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};
