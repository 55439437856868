import { combineReducers } from 'redux';

import optInPrompt, { OptInPromptStateType } from './optInPromptReducer';

export type PushNotificationsStateType = {
    optInPrompt: OptInPromptStateType;
};

export default combineReducers({
    optInPrompt,
});
