import React from 'react';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { CustomFieldsSectionQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

import CustomFieldsSectionDeleteContent from './CustomFieldsSectionDeleteContent';

const CustomFieldsSectionDeleteModal = () => {
    const [sectionDeleteId, setSectionDeleteId] = useStateQueryParams(
        CustomFieldsSectionQueryKeys.DELETE,
    );
    const closeModal = () => setSectionDeleteId('');
    const showModal = !!sectionDeleteId;

    return <CustomFieldsSectionDeleteContent showModal={showModal} closeModal={closeModal} />;
};

export default CustomFieldsSectionDeleteModal;
