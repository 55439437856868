import { HEX_COLORS, greyColorObject } from '@partoohub/branding';
import { Chip, Text } from '@partoohub/ui';
import { Trans, useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { useSettingsPageContext } from 'app/pages/settings/AutoReply/hooks/useSettingsPageContext';

import AutoReplyToggle from './AutoReplyToggle';
import { StyledIcon, StyledText, StyledTitle } from './TitleBar.styled';

const TitleBar = () => {
    const { t } = useTranslation();
    const { forReviewsWithComment, loadedSettings } = useSettingsPageContext();
    const showToggle = !!loadedSettings?.enabled_at;

    return (
        <>
            <StyledTitle>
                <Text variant="heading5" as="div">
                    <Trans
                        t={t}
                        i18nKey={
                            forReviewsWithComment
                                ? 'auto_reply_reviews_with_comment'
                                : 'auto_reply_reviews_without_comment'
                        }
                        components={{
                            primary: <span style={{ color: HEX_COLORS.primary }} />,
                        }}
                    />
                </Text>
                <TooltipWrapper
                    text={
                        forReviewsWithComment
                            ? t('auto_reply_with_comment_help')
                            : t('auto_reply_without_comment_help')
                    }
                    position="top-start"
                >
                    <StyledIcon className="fa-solid fa-question-circle category-card__question-mark-icon" />
                </TooltipWrapper>
                {!loadedSettings?.enabled && (
                    <Chip dataTrackId="loaded_settings__inactive__chip" variant="secondary">
                        {t('inactive')}
                    </Chip>
                )}
            </StyledTitle>
            {showToggle ? (
                <AutoReplyToggle />
            ) : (
                <StyledText variant="bodyLRegular" as="div" color={greyColorObject.dark}>
                    {t('auto_reply_form_specify_settings')}
                </StyledText>
            )}
        </>
    );
};

export default TitleBar;
