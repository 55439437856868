import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const StyledCommentAuthorMessage = styled.div`
    width: 100%;
    white-space: pre-wrap;
    word-break: break-word;

    .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
        border-color: #e6e8ed;
    }
`;

export const StyledAuthorRow = styled.div`
    display: flex;
    flex-flow: row nowrap;
`;

export const StyledAutoReplyDate = styled(Text)`
    margin-left: 4px;
`;
