import styled from '@emotion/styled';
import { Button } from '@partoohub/ui';

export const FooterButton = styled(Button)`
    display: flex;
    width: 100%;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
`;
