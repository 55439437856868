import { FC, useCallback, useState } from 'react';

import { IconPrefix } from '@partoohub/ui';

import { getAllInfoByISO } from 'iso-country-currency';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { FoodMenuItemType, FoodMenuSectionType } from 'app/api/types/food_menu';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import {
    SectionFooter,
    SectionGlobal,
    SectionHeader,
    SectionHeaderMenu,
    SectionSubtitle,
    SectionTitle,
    Suggestion,
} from 'app/businessEditV2/sections/MenuSection/components/MenuItems.styled';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { MenuLabelItem } from 'app/common/components/MenuLabelItem/MenuLabelItem';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import SectionBody from 'app/common/designSystem/components/molecules/SectionContainer/SectionBody';

import { FoodMenuMenu } from './FoodMenuMenu';
import MenuItem from './MenuItem';
import AddItemModal from './modals/AddItemModal';
import EditSectionModal from './modals/EditSectionModal';

interface MenuSectionsProps {
    section: FoodMenuSectionType;
    sections: Array<FoodMenuSectionType>;
    setSections: (sections: Array<FoodMenuSectionType>) => void;
    disabled: boolean;
    first: boolean;
    last: boolean;
}

type ModalType = 'add' | 'edit' | undefined;

const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
};

const MenuItems: FC<MenuSectionsProps> = ({
    section,
    sections,
    setSections,
    disabled,
    first,
    last,
}) => {
    const { t } = useTranslation();
    const [showEditSectionModal, setShowEditSectionModal] = useState<boolean>(false);
    const [showDeleteSectionModal, setShowDeleteSectionModal] = useState<boolean>(false);
    const [itemModalData, setItemModalData] = useState<{
        initialData: FoodMenuItemType | null;
        visible: boolean;
        type: ModalType;
    }>({
        initialData: null,
        visible: false,
        type: undefined,
    });
    const [deleteItemModalData, setDeleteItemModalData] = useState<{
        itemName: string | null;
        onConfirm: () => void | null;
        visible: boolean;
    }>({
        itemName: null,
        onConfirm: () => null,
        visible: false,
    });

    // It's used by admin page...
    const { data: business } = useBusiness();
    const { currency } = getAllInfoByISO(business?.country ?? 'FR');

    const onMenuSectionClick = (value: string) => {
        if (value === 'edit') setShowEditSectionModal(true);
        if (value === 'delete') setShowDeleteSectionModal(true);
        const index = sections.findIndex(obj => obj.order === section.order);
        if (value === 'moveUp') {
            move(index);
        }
        if (value === 'moveDown') {
            move(index + 1);
        }
    };

    const move = index => {
        const sectionsToUpdate = [...sections];
        const tempVariable = sectionsToUpdate[index - 1];
        sectionsToUpdate[index - 1] = sectionsToUpdate[index];
        sectionsToUpdate[index] = tempVariable;
        sectionsToUpdate[index].order = index + 1;
        sectionsToUpdate[index - 1].order = index;
        setSections(sectionsToUpdate);
    };

    const getOptions = () => {
        const options = [
            {
                name: 'edit',
                label: <MenuLabelItem text={t('edit')} icon={['fa-pen-alt', IconPrefix.REGULAR]} />,
                value: 'edit',
            },
            {
                name: 'delete',
                label: (
                    <MenuLabelItem
                        text={t('delete')}
                        icon={['fa-trash-alt', IconPrefix.REGULAR]}
                        color="danger"
                    />
                ),
                value: 'delete',
            },
        ];
        if (!first) {
            options.push({
                name: 'moveUp',
                label: (
                    <MenuLabelItem text={t('move_up')} icon={['fa-chevron-up', IconPrefix.SOLID]} />
                ),
                value: 'moveUp',
            });
        }
        if (!last) {
            options.push({
                name: 'moveDown',
                label: (
                    <MenuLabelItem
                        text={t('move_down')}
                        icon={['fa-chevron-down', IconPrefix.SOLID]}
                    />
                ),
                value: 'moveDown',
            });
        }
        return options;
    };

    const deleteSection = () => {
        let sectionsToUpdate = [...sections];
        const index = sectionsToUpdate.findIndex(obj => obj.order === section.order);
        sectionsToUpdate = sectionsToUpdate.filter(obj => obj.order !== section.order);
        let i;
        for (i = index; i < sectionsToUpdate.length; i += 1) {
            sectionsToUpdate[index].order -= 1;
        }
        setSections(sectionsToUpdate);
        setShowDeleteSectionModal(false);
    };

    const openCreateItemModal = useCallback(() => {
        setItemModalData({
            visible: true,
            initialData: null,
            type: 'add',
        });
    }, [setItemModalData]);

    const openEditItemModal = useCallback(
        (item: FoodMenuItemType) => {
            setItemModalData({
                visible: true,
                initialData: item,
                type: 'edit',
            });
        },
        [setItemModalData],
    );

    const handleCloseItemModal = useCallback(
        () =>
            setItemModalData({
                visible: false,
                initialData: null,
                type: undefined,
            }),
        [setItemModalData],
    );

    const handleCloseDeleteItemModal = useCallback(
        () =>
            setDeleteItemModalData({
                itemName: null,
                onConfirm: () => null,
                visible: false,
            }),
        [setDeleteItemModalData],
    );

    const setSection = useCallback(
        (sectionToUpdate: FoodMenuSectionType) => {
            const sectionsToUpdate = [...sections];
            const sectionIndex = sections.findIndex(s => s.order === sectionToUpdate.order);
            sectionsToUpdate[sectionIndex] = sectionToUpdate;
            setSections(sectionsToUpdate);
        },
        [sections, setSections],
    );

    const createItem = useCallback(
        (item: FoodMenuItemType) => {
            const itemOrder =
                section.items.reduce((prev, curr) => Math.max(prev, curr.order), 0) + 1;
            const items = [...section.items, { ...item, order: itemOrder }];
            setSection({ ...section, items });
        },
        [section, setSection],
    );

    const editItem = useCallback(
        (item: FoodMenuItemType) => {
            const items = [...section.items];
            const itemIndex = items.findIndex(obj => obj.order === item.order);
            items[itemIndex] = item;
            setSection({ ...section, items });
        },
        [section, setSection],
    );

    const deleteItem = useCallback(
        (item: FoodMenuItemType) => {
            const items = [...section.items];
            const itemIndex = items.findIndex(obj => obj.order === item.order);
            items.splice(itemIndex, 1);
            setSection({ ...section, items });
        },
        [section, setSection],
    );

    const openDeleteItemModal = useCallback(
        (item: FoodMenuItemType) => {
            setDeleteItemModalData({
                itemName: item.name,
                onConfirm: () => {
                    deleteItem(item);
                    handleCloseDeleteItemModal();
                },
                visible: true,
            });
        },
        [setDeleteItemModalData, deleteItem, handleCloseDeleteItemModal],
    );

    const onDragEnd = useCallback(
        (result: any) => {
            // dropped outside the list
            if (
                !result.destination ||
                result.destination.index === result.source.index ||
                disabled
            ) {
                return;
            }
            const newItemList = reorder(
                [...section.items],
                result.source.index,
                result.destination.index,
            ).map((item, index) => ({ ...item, order: index + 1 }));
            setSection({ ...section, items: newItemList });
        },
        [section, setSection],
    );
    return (
        <div>
            <SectionGlobal>
                <SectionHeader>
                    <SectionTitle className={disabled ? 'text-field__disabled' : ''}>
                        {section.name}
                    </SectionTitle>
                    <SectionHeaderMenu>
                        <FoodMenuMenu
                            key={`food_menu_menu_${section.name}_for_${section.order}`}
                            onMenuClick={(value: string) => {
                                onMenuSectionClick(value);
                            }}
                            disabled={disabled}
                            options={getOptions()}
                        />
                    </SectionHeaderMenu>
                </SectionHeader>
                {section.items.length === 0 ? (
                    <SectionBody
                        key={`section_body_${section.items.length}`}
                        id={`section_body_id_${section.items.length}`}
                        mainLabel={t('food_menu_empty_section_message')}
                        description=""
                        disabled
                    />
                ) : (
                    <div>
                        <DragDropContext onDragEnd={disabled ? () => null : onDragEnd}>
                            <Droppable droppableId="droppable" isDropDisabled={disabled}>
                                {droppableRef => (
                                    <div
                                        {...droppableRef.droppableProps}
                                        ref={droppableRef.innerRef}
                                    >
                                        {section.items.map((item, index) => (
                                            <MenuItem
                                                key={`menu-item-${index}`}
                                                itemIndex={index}
                                                currency={currency}
                                                disabled={disabled}
                                                item={item}
                                                onEdit={openEditItemModal}
                                                onDelete={openDeleteItemModal}
                                                t={t}
                                            />
                                        ))}
                                        {droppableRef.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                )}
                <SectionFooter>
                    <SectionSubtitle>
                        <Suggestion key={`custom_for_${section.order}`}>
                            <StrikeThroughButton
                                color="primary"
                                text={t('add_a_food_menu_item')}
                                disabled={disabled}
                                icon={<i className="fa fa-plus-circle" />}
                                onClick={openCreateItemModal}
                            />
                        </Suggestion>
                    </SectionSubtitle>
                </SectionFooter>
            </SectionGlobal>
            <AddItemModal
                handleClose={handleCloseItemModal}
                initialData={itemModalData.initialData}
                show={itemModalData.visible}
                handleConfirm={itemModalData.initialData ? editItem : createItem}
                currency={currency}
                type={itemModalData.type}
            />
            <ConfirmModal
                show={deleteItemModalData.visible}
                onHide={handleCloseDeleteItemModal}
                modalFor="delete"
                title={t('delete_food_menu_item_modal_title')}
                content={t('delete_food_menu_item_modal_content', {
                    itemName: deleteItemModalData.itemName,
                })}
                onConfirm={deleteItemModalData.onConfirm}
                confirmLabel={t('delete')}
                trackId="delete_food_menu_item_modal"
            />
            <EditSectionModal
                show={showEditSectionModal}
                handleClose={() => setShowEditSectionModal(false)}
                section={section}
                sections={sections}
                setSections={setSections}
            />
            <ConfirmModal
                show={showDeleteSectionModal}
                onHide={() => setShowDeleteSectionModal(false)}
                modalFor="delete"
                title={
                    section.items.length === 0
                        ? t('delete_food_menu_section_no_item_modal_title')
                        : t('delete_food_menu_section_modal_title')
                }
                content={
                    section.items.length === 0
                        ? t('delete_food_menu_section_no_item_modal_content', {
                              sectionName: section.name,
                          })
                        : t('delete_food_menu_section_modal_content', { sectionName: section.name })
                }
                onConfirm={deleteSection}
                confirmLabel={t('delete')}
                trackId="delete_food_menu_section_modal"
            />
        </div>
    );
};

export default MenuItems;
