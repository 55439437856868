import styled from '@emotion/styled';

export const PartnerIcon = styled.svg`
    &:hover .background {
        fill: #e0f0fd;
    }

    &:hover .cross-background {
        fill: #2885f2;
    }
`;

export const PartnerButton = styled(PartnerIcon)`
    cursor: pointer;
`;
