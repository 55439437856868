import { connect } from 'react-redux';

import { AccountPartner } from 'app/api/types/account';
import { partnerToPartnerName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { displayConnectionModal } from 'app/states/knowledge/reducers/businessConnection/connection';

type OwnProps = {
    loading: boolean;
    businessId: string | null;
    partner: AccountPartner;
};

const mapStateToProps = ({ partner }: OwnProps) => ({
    partnerName: partnerToPartnerName[partner],
});

const mapDispatchToProps = (dispatch: any, { loading, businessId, partner }: OwnProps) => ({
    onClick: () => {
        if (businessId && !loading) {
            dispatch(displayConnectionModal(businessId, partner));
        }
    },
});

const PartnerConnectionButton = connect(
    mapStateToProps,
    mapDispatchToProps,
    // @ts-ignore
)(StrikeThroughButton);

export default PartnerConnectionButton;
