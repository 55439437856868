import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const HomepageStatsContainer = styled.div``;

export const HomepageStatsContainerWithNewSidebar = styled.div``;

export const HomepageStatsTitle = styled(Text)`
    margin-bottom: 16px;
`;

type HomepageStatsItemProps = {
    mobileView: boolean;
    small: boolean;
};

export const HomepageStatsGrid = styled.div<HomepageStatsItemProps>`
    display: grid;
    grid-template-columns: repeat(${props => (props.small ? 3 : 2)}, minmax(0, 1fr));
    gap: 24px;

    // Handle mobile view
    ${props =>
        props.mobileView &&
        css`
            grid-template-columns: repeat(1, minmax(0, 1fr));
        `}
`;
