import { useMemo } from 'react';

import useMetricsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useMetricsAnalytics';
import { useMetricsQueryCount } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/metrics/count/useMetricsQueryCount';
import { MetricsAttributesResponse } from 'app/pages/analytics/VisibilityAnalyticsV2/types/metrics';

export const METRICS_ACTIONS = [
    MetricsAttributesResponse.WEBSITE_CLICKS,
    MetricsAttributesResponse.CALL_CLICKS,
    MetricsAttributesResponse.BUSINESS_DIRECTION_REQUESTS,
];

export const METRICS_VIEWS = [
    MetricsAttributesResponse.BUSINESS_IMPRESSIONS_DESKTOP_MAPS,
    MetricsAttributesResponse.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH,
    MetricsAttributesResponse.BUSINESS_IMPRESSIONS_MOBILE_MAPS,
    MetricsAttributesResponse.BUSINESS_IMPRESSIONS_MOBILE_SEARCH,
];

export function sumKeyInTab(tab: Array<any>, key: string): number {
    return tab.reduce((acc, val) => acc + (val[key] || 0), 0);
}

export function forcedDelta(delta: number | null): number | null {
    // Forcing 1 or -1 when delta is near 0 because the parse int function set to 0 and doesn't show proper delta on the app
    if (delta === null) return null;
    const sign = delta >= 0 ? 1 : -1;
    const forced = delta <= -1 || delta >= 1 ? delta : sign;
    return parseInt(forced.toFixed(2));
}

export const useMetricsDataCount = () => {
    const { queryCurrent, queryPrevious } = useMetricsQueryCount();

    const { data: dataCurrent } = useMetricsAnalytics(queryCurrent);
    const { data: dataPrevious } = useMetricsAnalytics(queryPrevious);

    const countCurrentActions = useMemo(
        () =>
            dataCurrent?.metrics?.length > 0
                ? METRICS_ACTIONS.reduce(
                      (acc, key) => acc + sumKeyInTab(dataCurrent?.metrics, key),
                      0,
                  )
                : 0,
        [dataCurrent],
    );

    const countPreviousActions = useMemo(
        () =>
            dataPrevious?.metrics?.length > 0
                ? METRICS_ACTIONS.reduce(
                      (acc, key) => acc + sumKeyInTab(dataPrevious?.metrics, key),
                      0,
                  )
                : 0,
        [dataPrevious],
    );

    const countMonthCurrentPeriod = useMemo(() => dataCurrent?.count || 0, [dataCurrent?.count]);

    const countMonthPreviousPeriod = useMemo(() => dataPrevious?.count || 0, [dataPrevious?.count]);

    const countCurrentViews = useMemo(
        () =>
            dataCurrent?.metrics?.length > 0
                ? METRICS_VIEWS.reduce(
                      (acc, key) => acc + sumKeyInTab(dataCurrent?.metrics, key),
                      0,
                  )
                : 0,
        [dataCurrent],
    );

    const countPreviousViews = useMemo(
        () =>
            dataPrevious?.metrics?.length > 0
                ? METRICS_VIEWS.reduce(
                      (acc, key) => acc + sumKeyInTab(dataPrevious?.metrics, key),
                      0,
                  )
                : 0,
        [dataPrevious],
    );

    const periodsCountDiffers = countMonthCurrentPeriod != countMonthPreviousPeriod;

    const deltaActions =
        countPreviousActions <= 0 || periodsCountDiffers
            ? null
            : ((countCurrentActions - countPreviousActions) / countPreviousActions) * 100;

    const deltaViews =
        countPreviousViews <= 0 || periodsCountDiffers
            ? null
            : ((countCurrentViews - countPreviousViews) / countPreviousViews) * 100;

    const metrics = Object.fromEntries(
        [...METRICS_ACTIONS, ...METRICS_VIEWS].map(field => [field, 0]),
    );
    const data =
        dataCurrent?.metrics?.length > 0
            ? dataCurrent?.metrics.reduce((acc, metric) => {
                  for (const field in acc) {
                      acc[field] += metric[field];
                  }
                  return acc;
              }, metrics)
            : {};

    return {
        data,
        count: { views: countCurrentViews, actions: countCurrentActions },
        delta: {
            views: forcedDelta(deltaViews),
            actions: forcedDelta(deltaActions),
        },
    };
};
