import React from 'react';

import { FormRowStyled } from './FormRow.styled';

type Props = {
    children: React.ReactNode;
};

const FormRow: React.FC<Props> = ({ children }) => (
    <FormRowStyled>{children}</FormRowStyled>
);

export default FormRow;
