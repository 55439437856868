import { Icon, IconElement, IconPrefix, Stack, Table, Text, Tooltip } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

type Props = {
    isHighlighted: boolean;
    rank: number | 'N/A';
    previousRank?: number;
};

export const CompetitorRankCell = ({ isHighlighted, rank, previousRank }: Props) => {
    const { t } = useTranslation();
    const renderRankDifference = (
        isHighlighted: boolean,
        rank: number | 'N/A',
        previousRank?: number,
    ) => {
        if (rank === 'N/A') {
            return (
                <Tooltip text={t('tooltip_no_ranking')}>
                    <Icon
                        iconSize="16px"
                        icon={['fa-circle-info', IconPrefix.REGULAR]}
                        color="secondary"
                    />
                </Tooltip>
            );
        }
        if (!previousRank) {
            return;
        }

        const rankDifference = rank - previousRank;
        if (rankDifference === 0) {
            return (
                <Stack direction="row" gap="6px" justifyContent="center" alignItems="center">
                    <Icon iconSize="16px" icon={['fa-equals']} color="secondary" />
                </Stack>
            );
        }

        const variant = isHighlighted ? 'bodyMBold' : 'bodyMRegular';
        const color = rankDifference > 0 ? 'danger' : 'success';
        const icon: IconElement =
            rankDifference > 0
                ? ['fa-caret-down', IconPrefix.SOLID]
                : ['fa-caret-up', IconPrefix.SOLID];

        return (
            <Stack direction="row" gap="6px" justifyContent="center" alignItems="center">
                <Icon iconSize="16px" icon={icon} color={color} />
                <Text variant={variant} color={color} as={'span'}>
                    {Math.abs(rankDifference)}
                </Text>
            </Stack>
        );
    };

    return (
        <Table.Cell>
            <Stack direction="row" gap={rank === 'N/A' ? '4px' : '16px'}>
                <Text variant={'bodyMBold'} color="secondary" as={'span'}>
                    {rank}
                </Text>
                {renderRankDifference(isHighlighted, rank, previousRank)}
            </Stack>
        </Table.Cell>
    );
};
