import { Stack } from '@partoohub/ui';

import { IS_BELOW_MOBILE, useDeviceType } from 'app/hooks';

import Flexbox from 'app/styles/utils/flexbox';

import { DateFilter } from './DateFilter';
import { FilterButton } from './FilterButton';
import { SearchFilter } from './SearchFilter';

export function FilterSection() {
    const { isBelowProvidedDevices: isMobile } = useDeviceType(IS_BELOW_MOBILE);

    return (
        <Stack direction="column">
            {!isMobile ? (
                <Flexbox gap="8px">
                    <DateFilter />
                    <SearchFilter />
                    <FilterButton />
                </Flexbox>
            ) : (
                <Flexbox flexDirection="column" gap="8px" flex="1">
                    <SearchFilter />
                    <Flexbox gap="8px" flex="1">
                        <DateFilter isMobile={isMobile} />
                        <FilterButton />
                    </Flexbox>
                </Flexbox>
            )}
        </Stack>
    );
}
