import { useTranslation } from 'react-i18next';

import BusinessModalButton from 'app/common/components/businessModal';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import NewFilterButton from 'app/common/components/buttons/NewFilterButton';
import { ADMIN, PROVIDER } from 'app/common/data/roles';
import useGroupsV2, { hasAnyGroupWithBusinessCount } from 'app/common/hooks/queries/useGroupsV2';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';

const BusinessGroupsFilter = () => {
    const { t } = useTranslation();

    const enableNewGroups = useNewGroups();

    const { data: me } = useMe();
    const { sections } = useGroupsV2({}, { enabled: enableNewGroups });

    const { groups } = useBusinessModalFilters();
    const groupNumber = groups?.split(',').length ?? 0;

    if (!hasAnyGroupWithBusinessCount(sections) && ![PROVIDER, ADMIN].includes(me?.role ?? '')) {
        return null;
    }

    return (
        <BusinessModalButton
            variation="group-only"
            button={
                <NewFilterButton
                    // Will be provided properly by the Business Modal
                    onClick={() => undefined}
                    text={t('business_drawer_groups')}
                    selectedNumber={groupNumber}
                />
            }
        />
    );
};

export default BusinessGroupsFilter;
