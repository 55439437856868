import { useQuery } from 'react-query';

import { CompetitorCentralRank } from 'app/api/types/competitiveBenchmark';
import api from 'app/api/v2/api_calls';
import { GetGlobalRankingsParams } from 'app/api/v2/api_calls/competitiveBenchmark';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { COMPETITIVE_BENCHMARK_GLOBAL_RANKINGS } from 'app/common/data/queryKeysConstants';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';

import { useKeywordFilter } from './useKeywordFilter';
import { formatDate } from '../utils/formatDate';

export type GlobalRankings = {
    data: CompetitorCentralRank[];
    isLoading: boolean;
};

export const useGlobalRankings = (date: Date): GlobalRankings => {
    const [keyword] = useKeywordFilter();

    const { data: user } = useMe();
    const { data: org } = useOrganization(user?.org_id ?? 0);

    const businessFilters = useBusinessModalFilters();
    const params: GetGlobalRankingsParams = {
        date: formatDate(date),
        keyword: keyword.keyword,
        ...businessFilters,
    };
    const { data, isLoading } = useQuery([COMPETITIVE_BENCHMARK_GLOBAL_RANKINGS, params], () =>
        api.competitiveBenchmark.getCompetitorRankings(params),
    );

    if (data && org && !data.find(rank => rank.highlight)) {
        data.push({
            name: org.name,
            highlight: true,
            rating: 0,
            reviewCount: 0,
            apparitionPercentage: 0,
        });
    }

    return { data: data || [], isLoading };
};
