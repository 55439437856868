import useKeywordsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useKeywordsAnalytics';
import { numberFormatter } from 'app/common/services/formatNumber';
import { useKeywordsQueryKpis } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/keywords/kpis/useKeywordsQueryKpis';
import { Keyword } from 'app/pages/analytics/VisibilityAnalyticsV2/types/keyword';
import { capitalize } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/string';

const formatKeywordValue = (keyword: Keyword): string => {
    return keyword.count
        ? `${numberFormatter(keyword.count || 0)}`
        : `< ${numberFormatter(keyword.threshold || 0)}`;
};

export const useKeywordsDataKpis = () => {
    const { queryCount, queryThreshold } = useKeywordsQueryKpis();

    const { data: dataCount } = useKeywordsAnalytics(queryCount);
    const { data: dataThreshold } = useKeywordsAnalytics(queryThreshold);

    const mergeArray =
        [...(dataCount?.keywords || []), ...(dataThreshold?.keywords || [])].filter(
            item => item.hasOwnProperty('count') || item.hasOwnProperty('threshold'),
        ) || [];

    const transformedData =
        mergeArray && mergeArray.length > 0
            ? mergeArray.map(element => ({
                  ...element,
                  keyword: capitalize(element?.keyword || 'Unknown'),
                  count: formatKeywordValue(element || 0),
              }))
            : [];

    return {
        data: transformedData,
    };
};
