import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const HomepageStatsContainer = styled.div`
    margin-bottom: 32px;
`;

export const HomepageStatsTitle = styled(Text)`
    margin-bottom: 16px;
`;

type HomepageStatsItemProps = {
    small: boolean;
};

export const HomepageStatsGrid = styled.div<HomepageStatsItemProps>`
    display: grid;
    grid-template-columns: repeat(${props => (props.small ? 3 : 2)}, minmax(0, 1fr));
    gap: 24px;
`;
