import { Button, IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

type Props = {
    onClick: () => void;
};

export const EditReplyTemplateButton = ({ onClick }: Props) => {
    const { t } = useTranslation();
    return (
        <Button
            dataTrackId="bulk_edit_template__web__button"
            onClick={onClick}
            variant="secondary"
            appearance="outlined"
            key="--bulk-edit-template-button-key--"
            icon={['fa-pen-clip', IconPrefix.REGULAR]}
            full
        >
            {t('bulk_update_page_title')}
        </Button>
    );
};
