import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';
import { Icon, Text } from '@partoohub/ui';

import { DRAGGING_OPACITY } from '../CustomFieldsList.styled';

interface CustomFieldsListItemContainerProps {
    isInSection: boolean;
    isDragging?: boolean;
}

export const CustomFieldsListItemContainer = styled.div<CustomFieldsListItemContainerProps>`
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;

    ${({ isInSection, theme }) =>
        !isInSection
            ? css`
                  border-radius: ${theme.radius.large};
                  border: solid 1px ${theme.colors.default.alpha};
                  padding-right: 16px;
              `
            : css`
                  border-bottom: solid 1px ${theme.colors.secondary.alpha};
              `}

    // If transparent, it will be weird with the drag and drop
    background-color: ${HEX_COLORS.white};

    opacity: ${({ isDragging }) => (isDragging ? DRAGGING_OPACITY : 1)};
`;

export const CustomFieldDraggable = styled.div<CustomFieldsListItemContainerProps>`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    ${({ isInSection }) =>
        !isInSection &&
        css`
            padding-left: 24px;
        `}

    &:hover {
        cursor: grab;
    }

    &:active {
        cursor: grabbing;
    }
`;

export const GrabIcon = styled(Icon)`
    margin-right: 21px;
`;

export const CustomFieldName = styled(Text)`
    display: flex;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 21px;
`;

export const CustomFieldType = styled.div`
    display: flex;
    align-items: center;
    @media (min-width: 769px) {
        width: 25%;
    }

    > img {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        pointer-events: none;
    }
`;
