import React from 'react';

import { useTranslation } from 'react-i18next';

import HeaderActions from 'app/businessConnection/components/businessConnectionHeader/headerActions';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';

const ConnectionHeader = () => {
    const { t } = useTranslation();
    return (
        <DefaultMenuListHeader
            title={t('connections')}
            description={t('connections_settings_description')}
            actionsButton={[<HeaderActions key="connection-buttons-header" />]}
        />
    );
};

export default ConnectionHeader;
