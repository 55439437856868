import React from 'react';

import { V2FormattedLocationData } from 'app/api/types/account';

import Complete from 'app/settingsManagement/components/GoogleVerification/Complete';

import Options from 'app/settingsManagement/components/GoogleVerification/Options';

import BusinessVideoScreen from 'app/settingsManagement/components/GoogleVerification/screens/StatusScreen/BusinessVideoScreen';

import { useGoogleVerificationContext } from '../GoogleVerification.context';

import ErrorScreen from '../screens/StatusScreen/ErrorScreen';
import PostcardSentScreen from '../screens/StatusScreen/PostcardSentScreen';
import {
    AlreadyVerifiedScreen,
    InReviewScreen,
    VerifiedScreen,
} from '../screens/StatusScreen/SuccessScreen';
import {
    AlreadyInReviewScreen,
    NoMethodScreen,
} from '../screens/StatusScreen/WarningScreen';

type ProcessProps = {
    selectedLocation: V2FormattedLocationData | undefined;
    handleClose: () => void;
};

const Process = ({ selectedLocation, handleClose }: ProcessProps) => {
    const { store } = useGoogleVerificationContext();

    if (!selectedLocation) return <></>;

    switch (selectedLocation.googleVerifications?.status) {
        case 'in_review':
            return <InReviewScreen handleClose={handleClose} />;
        case 'verified':
            return <AlreadyVerifiedScreen handleClose={handleClose} />;
    }

    if (store?.locations) {
        const storedLocation = store.locations[selectedLocation.id];

        switch (storedLocation?.screen) {
            case 'complete':
                return <Complete location={selectedLocation} />;
            case 'in_review':
                return <InReviewScreen handleClose={handleClose} />;
            case 'verified':
                return <VerifiedScreen handleClose={handleClose} />;
            case 'postcard_sent':
                return (
                    <PostcardSentScreen
                        address={storedLocation?.optionSelected.full_address}
                        daysToDeliver={
                            storedLocation?.optionSelected
                                .expected_delivery_days
                        }
                        handleClose={handleClose}
                    />
                );
            case 'no_method':
                return <NoMethodScreen handleClose={handleClose} />;
            case 'business_video':
                return <BusinessVideoScreen location={selectedLocation} />;
            case 'listing_in_review':
                return (
                    <AlreadyInReviewScreen
                        warningMessage={storedLocation?.warningMessage}
                        handleClose={handleClose}
                    />
                );
            case 'error':
                return (
                    <ErrorScreen
                        errorMessage={storedLocation?.errorMessage}
                        handleClose={handleClose}
                    />
                );
        }
    }

    return <Options location={selectedLocation} />;
};

export default Process;
