import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import AccessesSelect from 'app/common/components/AccessesSelect';
import { Props } from 'app/common/components/AccessesSelect/AccessesSelect';

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'value' | 'onChange';

const ControlledAccessesSelect = ({
    name,
    control,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return <AccessesSelect value={value} onChange={onChange} {...props} />;
            }}
        />
    );
};

export default ControlledAccessesSelect;
