import { Modal } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { GroupsSettingsQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

import EditSectionContent from './EditSectionContent';

const EditSectionModal = () => {
    const [edit, setEdit] = useStateQueryParams(GroupsSettingsQueryKeys.EDIT, true);

    const [sectionId, groupId] = edit.split(',');

    return (
        <Modal isOpen={!!sectionId && !groupId} closeModal={() => setEdit('')}>
            <EditSectionContent closeModal={() => setEdit('')} />
        </Modal>
    );
};

export default EditSectionModal;
