import { call, put } from 'redux-saga/effects';

import { SearchAccountsReceivedData } from 'app/api/types/account';
import api from 'app/api/v2/api_calls';
import formatAccounts from 'app/businessConnection/services/formatAccounts';
import { Saga } from 'app/common/types';
import {
    FACEBOOK,
    GOOGLE_MY_BUSINESS,
    getAccountsRequestFailure,
    getAccountsRequestSuccess,
} from 'app/states/knowledge/reducers/businessConnection/accounts';

function* loadPartnerAccounts(): Saga {
    try {
        const google = (yield call(
            api.account.searchAccount,
            GOOGLE_MY_BUSINESS,
        ) as any) as SearchAccountsReceivedData;
        const facebook = (yield call(
            api.account.searchAccount,
            FACEBOOK,
        ) as any) as SearchAccountsReceivedData;
        const allAccounts = google.accounts.concat(facebook.accounts);
        const { accountsById, accountIdsByPartner } =
            formatAccounts(allAccounts);
        yield put(getAccountsRequestSuccess(accountsById, accountIdsByPartner));
    } catch (error) {
        yield put(getAccountsRequestFailure(error));
    }
}

export default loadPartnerAccounts;
