import { ReactNode, useContext } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Banner, Icon, IconButton, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import MobilePageHeader from 'app/common/components/pageHeaders/MobilePageHeader';
import IntercomWrapper from 'app/common/services/intercomWrapper';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';
import { EditPostsContext } from 'app/presence/googlePosts/components/googlePostList/PostsList';
import {
    ERROR_GOOGLE_POSTS,
    TO_CONFIGURE_POSTS,
} from 'app/presence/googlePosts/variables/googlePostStatus';

import { BannerContainer } from './PostCreationModalTemplate.styled';
import { NewPostContext, NewPostContextType } from '../../context/NewPost';
import {
    HeaderContainer,
    HeaderTitle,
    HeaderTitleContainer,
    HeaderTitleIcon,
    PostFormTypePlatformContainer,
} from '../../screens/TypePlatform/PostFormTypePlatform.styled';

type Props = {
    children: ReactNode;
    onHideClick: () => void;
};

export default function PostCreationModalTemplate({ children, onHideClick }: Props) {
    const { t } = useTranslation();
    const {
        formFields: { type },
        isEditing,
    } = useContext<NewPostContextType>(NewPostContext);

    const { postCreationModalEditMode, postToEditData } = useContext(EditPostsContext);

    const shouldDisplayEditErrorBanner = !!postToEditData?.postStatus?.some(({ state }) =>
        [ERROR_GOOGLE_POSTS, TO_CONFIGURE_POSTS].includes(state),
    );
    const googlePostType = t(`post_type_name_${type}`);
    const { isBelowProvidedDevices: isTablet } = useDeviceType(IS_BELOW_TABLET);

    const headerTitle = `${postCreationModalEditMode ? t('edit_post') : t('create_post')} ${
        type && ` · ${googlePostType}`
    }`;

    const handleButtonClick = () => {
        IntercomWrapper.show();
    };

    return (
        <PostFormTypePlatformContainer>
            {isTablet ? (
                <MobilePageHeader
                    title={headerTitle}
                    rightContent={
                        <IconButton
                            dataTrackId="post-creation-modal-close"
                            appearance="outlined"
                            onClick={onHideClick}
                            icon={<Icon icon={['fa-close', IconPrefix.REGULAR]} iconSize="24px" />}
                        />
                    }
                />
            ) : (
                <HeaderContainer>
                    <HeaderTitleContainer>
                        <HeaderTitleIcon
                            icon={['fa-solid fa-newspaper']}
                            color={HEX_COLORS.primary}
                            iconSize="20px"
                        />
                        <HeaderTitle>{headerTitle}</HeaderTitle>
                    </HeaderTitleContainer>
                </HeaderContainer>
            )}
            {isEditing && shouldDisplayEditErrorBanner && (
                <BannerContainer>
                    <Banner
                        dataTrackId="google_post_warning_banner"
                        variant="warning"
                        withCloseIcon={false}
                        onAction={handleButtonClick}
                        actionButtonText={t('messaging_messenger_error_global_banner_button')}
                    >
                        {t('google_post_warning_message')}
                    </Banner>
                </BannerContainer>
            )}
            {children}
        </PostFormTypePlatformContainer>
    );
}
