import styled from '@emotion/styled';

export const NoAccounts = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
    padding: 0 44px;
    min-height: 200px;
    justify-content: center;
`;

export const Text = styled.p`
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;

export const AccountListContainer = styled.div`
    overflow-y: scroll;
    flex: 1;
`;

export const LoadMore = styled.div`
    display: flex;
    height: 60px;
    padding: auto;
    justify-content: center;
    align-items: center;
    min-height: 200px;

    a {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
    }
`;

export const AccountRowContainer = styled.div`
    flex: 1;
    &:not(:first-of-type) {
        padding-top: 8px;
    }
`;
