import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { FrontendReviewState } from 'app/api/types/review';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { useIsMobile } from 'app/common/services/screenDimensions';

import { useReviewDateFormatter } from 'app/reviewManagement/reviewList/hooks/ReviewCard/useReviewDateFormatter';

import { StyledDate } from './ReviewDate.styled';

type Props = {
    date: number;
    formattedCreateDate: string;
    modified: boolean;
    state: FrontendReviewState;
    highlight: boolean;
};

export const ReviewDate = ({ date, formattedCreateDate, state, modified, highlight }: Props) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const formatDate = useReviewDateFormatter();

    const dateText = (() => {
        const formattedDate = formatDate(date, {
            showHour: state !== 'deleted' ? !isMobile : false,
            showDay: !isMobile,
        });

        if (state === 'deleted') {
            return `${t('deleted_on')} ${formattedDate}`;
        }
        if (modified) {
            return `${t('modified_on')} ${formattedDate}`;
        }
        return formattedDate;
    })();

    return (
        <TooltipWrapper
            placement="bottom"
            text={modified ? `${t('tooltip_rm_created_at')} ${formattedCreateDate}` : ''}
        >
            <StyledDate highlight={highlight}>
                <Text
                    variant={highlight ? 'bodySBold' : 'bodySRegular'}
                    color={highlight ? 'warning' : 'secondary'}
                >
                    {dateText}
                </Text>
            </StyledDate>
        </TooltipWrapper>
    );
};
