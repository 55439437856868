import { useEffect, useState } from 'react';

import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { SearchOrgParams } from 'app/api/types/org';
import { Choice } from 'app/api/types/user';
import Organization from 'app/api/v2/api_calls/orgApiCalls';
import Provider from 'app/api/v2/api_calls/providerApiCalls';
import {
    DesktopHeaderContainer,
    PageHeaderFilter,
} from 'app/businessConnection/components/PageHeader/DesktopHeader/DesktopHeader.styled';
import AsyncSingleSelect from 'app/common/designSystem/components/molecules/AsyncSingleSelect/AsyncSingleSelect';
import {
    setBusinessOrgIdFilter,
    setBusinessOrgIdInFilter,
} from 'app/common/reducers/newBusinesses';
import getLang from 'app/common/services/getLang';
import { convertOrgToChoice } from 'app/common/utils/convertOrgToChoice';
import { convertProviderToChoice } from 'app/common/utils/convertProviderToChoice';

type Props = {
    displayFilters: boolean;
    count: number;
};

const BusinessConnectionHeader = ({ count, displayFilters }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [org, setOrg] = useState<Choice | null>(null);
    const [provider, setProvider] = useState<Choice | null>(null);
    const [providerOptions, setProviderOptions] = useState<Array<Choice>>([]);
    const [orgOptions, setOrgOptions] = useState<Array<Choice>>([]);

    useEffect(() => {
        loadProvider('');
        loadOrg('');
    }, []);

    const loadProvider = async (value: string) => {
        const x = await Provider.search(value);
        setProviderOptions(x.map(convertProviderToChoice));
    };

    const loadOrgByProvider = async (value: string, currProvider: Choice | null) => {
        const queryParams: Partial<SearchOrgParams> = { query: value };
        if (currProvider) queryParams.provider = currProvider.value;

        const result = await Organization.searchOrgs(queryParams);
        setOrgOptions(result.orgs.map(convertOrgToChoice));

        return result.orgs.map(convertOrgToChoice);
    };

    const loadOrg = async (value: string) => {
        loadOrgByProvider(value, provider);
    };

    const handleChangeOrganization = organization => {
        setOrg(organization);
        dispatch(setBusinessOrgIdFilter(organization?.value));
    };

    const handleChangeProvider = async (currProvider: Choice) => {
        setProvider(currProvider);
        setOrg(null);

        const organizations = currProvider ? await loadOrgByProvider('', currProvider) : [];

        // @ts-ignore
        const orgIds: Array<number> = organizations.reduce((res, curr) => [...res, curr.value], []);
        dispatch(setBusinessOrgIdInFilter(orgIds?.length > 0 ? orgIds : null));
    };

    return (
        <DesktopHeaderContainer>
            <Text variant="heading6">
                {`${count?.toLocaleString(getLang())} ${t('header_counter_connections', { count })}`}
            </Text>

            {displayFilters && (
                <PageHeaderFilter>
                    <AsyncSingleSelect
                        placeholder={t('PROVIDER')}
                        options={providerOptions}
                        selectedValue={provider}
                        onChange={handleChangeProvider}
                        loadMore={loadProvider}
                        displaySelectedOptionOneLine
                    />
                    <AsyncSingleSelect
                        placeholder={'ORG'}
                        options={orgOptions}
                        selectedValue={org}
                        onChange={handleChangeOrganization}
                        loadMore={loadOrg}
                        displaySelectedOptionOneLine
                    />
                </PageHeaderFilter>
            )}
        </DesktopHeaderContainer>
    );
};

export default BusinessConnectionHeader;
