import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { MetricsApiKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/types/query';
import { AnalyticsQueryKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queryKeys';
import { DATE_FORMAT, getUTCDate } from 'app/utils/dates';

const PERIOD_MONTH_NUMBER = 5;
export const DATE_DEFAULT_VALUE = {
    [MetricsApiKeys.FILTER_DATE_GTE]: format(
        subMonths(startOfMonth(new Date()), PERIOD_MONTH_NUMBER),
        DATE_FORMAT,
    ),
    [MetricsApiKeys.FILTER_DATE_LTE]: format(endOfMonth(new Date()), DATE_FORMAT),
};

export const useGetDate = () => {
    const [dateFrom] = useStateQueryParams(AnalyticsQueryKeys.DATE_FROM);
    const [dateTo] = useStateQueryParams(AnalyticsQueryKeys.DATE_TO);

    const defaultStartDate = format(
        subMonths(startOfMonth(new Date()), PERIOD_MONTH_NUMBER),
        DATE_FORMAT,
    );

    const startDate = dateFrom ? dateFrom : defaultStartDate;
    const startDateUTC = format(getUTCDate(new Date(startDate)), DATE_FORMAT);

    const endDate = dateTo
        ? format(new Date(dateTo), DATE_FORMAT)
        : DATE_DEFAULT_VALUE.filter_date__lte;
    const endDateUTC = format(getUTCDate(new Date(endDate)), DATE_FORMAT);

    return {
        [MetricsApiKeys.FILTER_DATE_GTE]: startDateUTC,
        [MetricsApiKeys.FILTER_DATE_LTE]: endDateUTC,
    };
};
