import styled from '@emotion/styled';

import { Container } from 'app/common/components/funnel/FunnelModal.styled';

export const LoadingLocationsContainer = styled(Container)`
    flex-direction: column;
    padding-top: 0;
    height: 60%;
`;

export const ImageTitle = styled.img`
    height: 78px;
    width: 78px;
    margin-bottom: 18px;
    animation: rotation 2s infinite ease-in-out;

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
`;
