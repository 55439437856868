import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import { GROUPS_V2 } from 'app/common/data/queryKeysConstants';
import queryClient from 'app/states/queryClient';

import { GroupsSettings as GroupsSettingsV2 } from './GroupsSettings';

export const GroupsSettings = () => {
    const { t } = useTranslation();

    // Reset queries cache
    useEffect(() => {
        document.getElementById('react-content')?.scrollTo(0, 0);
        queryClient.invalidateQueries(invalidatesAny(GROUPS_V2));
    }, []);

    return (
        <>
            <DynamicPageHeader title={t('group_page_title')} />
            <GroupsSettingsV2 />
        </>
    );
};
