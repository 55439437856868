import 'regenerator-runtime/runtime';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router';
import axios from 'axios';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import {
    SET_ANY_STATE,
    setAnyStateReducer,
} from 'app/common/hooks/useGetParamReduxSync';
import { actionGenerators } from 'app/common/reducers/newBusinesses';
import { API_AUTH_EXCEPTION } from 'app/common/sagas/authInterceptorSaga';
import createSagaOrchestrator from 'app/sagaOrchestrator';
import { LOGOUT_RESET_REDUCERS_DATA } from 'app/states/connection/reducers/login';
import { browserHistory } from 'app/states/history';
import reducers from 'app/states/reducers';
import { pageSagas, sharedSagas } from 'app/states/sagas';

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [
        createRouterMiddleware(browserHistory),
        sagaMiddleware,
    ];
    let composeEnhancer = compose;

    if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable no-underscore-dangle */
        composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  actionCreators: actionGenerators,
              })
            : compose; // eslint-enable
    }

    const appReducers = reducers(browserHistory);

    const rootReducer = (state, action) => {
        if (action.type === LOGOUT_RESET_REDUCERS_DATA) {
            // eslint-disable-next-line no-param-reassign
            state = undefined;
        } else if (action.type === SET_ANY_STATE) {
            // eslint-disable-next-line no-param-reassign
            state = setAnyStateReducer(state, action);
        }

        return appReducers(state, action);
    };

    const store = createStore(
        rootReducer,
        composeEnhancer(applyMiddleware(...middlewares)),
    );
    axios.interceptors.response.use(undefined, error => {
        if (error.response?.status === 401) {
            store.dispatch({
                type: API_AUTH_EXCEPTION,
            });
        } else {
            throw error;
        }
    });
    const sagaOrchestrator = createSagaOrchestrator(sharedSagas, pageSagas);
    sagaMiddleware.run(sagaOrchestrator);
    return store;
};

export default configureStore;
