import { PropsWithChildren, useState } from 'react';

import { Dialog, FontAwesomeIconsPartooUsed, IconButton, IconPrefix, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { HelperCardContainer } from 'app/pages/analytics/VisibilityAnalyticsV2/components/HelperCard/HelperCard.styled';

type Props = {
    title?: string;
    description?: string;
};

export const HelperCard = ({ title, description, children }: PropsWithChildren<Props>) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);

    const onClick = () => {
        setShowModal(true);
    };

    return (
        <HelperCardContainer>
            <IconButton
                icon={[FontAwesomeIconsPartooUsed.faCircleQuestion, IconPrefix.REGULAR]}
                color="secondary"
                size="small"
                appearance="outlined"
                tooltip={t('more_info')}
                dataTrackId="kpi_icon_button"
                onClick={onClick}
            />
            <Modal isOpen={showModal} closeModal={() => setShowModal(false)}>
                <Dialog
                    dataTrackId="analytics_visibility__helper_card__dialog"
                    title={title}
                    subTitle={description}
                >
                    {children}
                </Dialog>
            </Modal>
        </HelperCardContainer>
    );
};
