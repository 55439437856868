import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const BusinessEditSectionContainer = styled.div`
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    margin-bottom: 24px;
`;

type WithChildrenProps = {
    childrenExist?: boolean;
};
export const BusinessEditSectionHeader = styled.div<WithChildrenProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 24px;
    border-bottom: solid 1px ${greyColorObject.initial};

    ${({ childrenExist }) =>
        !childrenExist &&
        css`
            border-bottom: none;
        `}
`;

export const BusinessEditSectionTitle = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
`;

export const BusinessEditSectionPublisher = styled.div`
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.warning.initial};
`;

type WithPaddingProps = {
    childrenExist?: boolean;
    noPadding?: boolean;
};
export const BusinessEditSectionContent = styled.div<WithPaddingProps>`
    padding: 0;

    ${({ noPadding, childrenExist }) =>
        !noPadding &&
        childrenExist &&
        css`
            padding: 32px 24px;
        `}
`;
