import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

export const TooltipModalSubtitle = styled(Text)`
    text-align: center;
    margin-bottom: 16px;
`;

export const ListItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5.5px 0;
    :not(:last-child) {
        border-bottom: 1px solid #f6f8fc;
    }
`;

export const ListItemKeyContainer = styled.div`
    text-align: left;
    max-width: 300px;
`;

export const ListItemValue = styled(Text)`
    text-align: right;
`;

export const KeywordsCardContainer = styled.div``;

export const ExportAdviceContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.secondary.initial};
    width: 100%;
    > i {
        font-size: 10px;
        margin-right: 3px;
    }
`;

export const ExportAdviceDescription = styled.div`
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
`;

export const CardTitleContainer = styled.div`
    width: 100%;
`;
