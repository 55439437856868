import { Navigate, useRoutes } from 'react-router-dom';

import { ADMIN, BUSINESS_MANAGER, GROUP_MANAGER, PROVIDER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks/useDeviceType';
import { findFirstDisplayPathname } from 'app/pages/settingsV2/helpers';
import { useSettingTeamMenu } from 'app/pages/settingsV2/hooks/Team/useSettingMenuTeam';
import { GroupsSettings } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings';
import { Permissions } from 'app/pages/settingsV2/subPages/Team/components/Permissions';
import { UserManagement } from 'app/pages/settingsV2/subPages/Team/components/UserManagement';
import { SettingTeam } from 'app/pages/settingsV2/subPages/Team/SettingTeam';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import {
    GROUPS_SETTINGS_PATH_TOKEN,
    PERMISSIONS_PATH_TOKEN,
    SETTINGS_TEAM_PATHNAME,
    USERS_PATH_TOKEN,
} from 'app/routing/routeIds';

export const TeamRoutes = () => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const { menus: settingsTeamMenu } = useSettingTeamMenu();
    const defaultSettingTeamPathname = findFirstDisplayPathname(settingsTeamMenu);

    const { data: me } = useMe();
    const meRole = me ? me.role : null;
    const enableNewGroups = useNewGroups();

    const enableNewGroupsRoutes = enableNewGroups
        ? [
              {
                  path: GROUPS_SETTINGS_PATH_TOKEN,
                  element: (
                      <ProtectedRoute
                          userRole={meRole}
                          excludeRoles={[ADMIN, PROVIDER, BUSINESS_MANAGER]}
                      >
                          <GroupsSettings />
                      </ProtectedRoute>
                  ),
              },
          ]
        : [];

    const indexRoute = isBelowProvidedDevices ? SETTINGS_TEAM_PATHNAME : defaultSettingTeamPathname;

    const routes = [
        {
            element: <SettingTeam />,
            children: [
                {
                    index: true,
                    element: <Navigate replace to={indexRoute} />,
                },
                {
                    path: USERS_PATH_TOKEN,
                    element: <UserManagement />,
                },
                {
                    path: PERMISSIONS_PATH_TOKEN,
                    element: (
                        <ProtectedRoute
                            userRole={meRole}
                            excludeRoles={[ADMIN, PROVIDER, GROUP_MANAGER, BUSINESS_MANAGER]}
                        >
                            <Permissions />
                        </ProtectedRoute>
                    ),
                },
                ...enableNewGroupsRoutes,
            ],
        },
    ];

    return useRoutes(routes);
};
