import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import BusinessModalButton from 'app/common/components/businessModal';
import useSelectedBusinessesTitle from 'app/common/components/businessModal/hooks/useSelectedBusinessesTitle';
import MenuIcon from 'app/common/components/icons/MenuIcon';
import MobilePageHeader from 'app/common/components/pageHeaders/MobilePageHeader';
import MobilePageSubHeader from 'app/common/components/pageHeaders/MobilePageSubHeader';
import { collapseSidebar } from 'app/common/reducers/global';

export const MobileHeader = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const openSidebar = () => dispatch(collapseSidebar());

    const { title, subtitle } = useSelectedBusinessesTitle();

    return (
        <div>
            <MobilePageHeader
                leftContent={<MenuIcon onClick={openSidebar} />}
                title={title}
                subtitle={subtitle}
                rightContent={<BusinessModalButton resetReducerOnUnmount={false} />}
                hideLeftContentOnIframe
            />
            <MobilePageSubHeader title={t('my_reviews')} />
        </div>
    );
};
