import React, { useState } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Choice } from 'app/api/types/user';
import ButtonWithSearchMenuFilterSelection from 'app/common/designSystem/components/molecules/ButtonWithSearchMenuFilterSelection';

import FLAG from 'app/common/flags.json';
import {
    getTranslationKey,
    languagesData,
} from 'app/common/services/languages';

import FilterButton from './buttons/FilterButton';

import FilterSelect from './FilterSelect';
import { ImageContainer } from '../designSystem/components/molecules/ButtonWithSearchMenuFilterSelection/ButtonWithSearchMenuFilterSelection.styled';

// TODO: Unify the UI of this component
// If withSearchBar is set to true, then the old component will be rendered used in `/settings/reply-templates/reviews` page
// Otherwise, the new component will be rendered, which is similar to the `/visibility/locations` page filters
type Props = {
    availableLanguages?: string[];
    onChange: (selectedLanguages: Array<Choice>) => void;
    selectedOptions: Array<Choice>;
    withSearchbar?: boolean;
};

export const languageToOption =
    (t: TFunction, withSearchbar?: boolean) => lang => ({
        value: lang.code,
        label: t(getTranslationKey(lang)),
        icon: withSearchbar ? (
            <img src={FLAG[lang.country]} alt={lang.country} />
        ) : (
            <div>
                <ImageContainer>
                    <img src={FLAG[lang.country]} alt={lang.country} />,
                </ImageContainer>
            </div>
        ),
    });

const LanguagesMultiSelect = ({
    availableLanguages,
    onChange,
    selectedOptions,
    withSearchbar,
}: Props) => {
    const { t } = useTranslation();

    const active = !!selectedOptions.length;
    const buttonText = active
        ? selectedOptions.map(option => option.label).join(', ')
        : t('language');

    let languages;
    if (availableLanguages) {
        languages = languagesData.filter(lang =>
            availableLanguages.includes(lang.code),
        );
    } else {
        languages = languagesData;
    }

    const languagesOptionsData = languages
        .map(languageToOption(t, withSearchbar))
        .sort((a, b) => a.label.localeCompare(b.label));

    const [search, setSearch] = useState('');
    const languagesOptions = languagesOptionsData.filter(lang =>
        lang.label.toLowerCase().includes(search.toLowerCase()),
    );

    return withSearchbar ? (
        <ButtonWithSearchMenuFilterSelection
            input={
                <FilterButton
                    text={buttonText}
                    active={active}
                    handleErase={() => onChange([])}
                />
            }
            options={languagesOptions}
            hasHeader={false}
            onChange={onChange}
            loadMore={setSearch}
            singleSelect={false}
            selectedOptions={selectedOptions}
            placeholder={t('language')}
        />
    ) : (
        <FilterSelect
            title={buttonText}
            options={languagesOptions}
            selectedOptions={selectedOptions}
            onChange={onChange}
        />
    );
};

export default LanguagesMultiSelect;
