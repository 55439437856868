import { useEffect, useMemo } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditForm from 'app/businessEditV2/components/EditForm';
import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import useIsLoadingMenu from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingMenu';
import useMenuSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useMenuSectionColor';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useBusinessFoodMenus from 'app/businessEditV2/hooks/menu/useBusinessFoodMenus';
import useMenuSectionUpdate from 'app/businessEditV2/hooks/menu/useMenuSectionUpdate';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useFormFieldFormatter from 'app/businessEditV2/hooks/publisherErrors/useFormFieldFormatter';
import MenuSections from 'app/businessEditV2/sections/MenuSection/components/MenuSections';
import { SectionsTitle } from 'app/businessEditV2/sections/MenuSection/MenuForm/MenuForm.styled';
import MenuPhotosForm from 'app/businessEditV2/sections/MenuSection/MenuForm/MenuPhotosForm';
import { OpenHoursPrechecks } from 'app/businessEditV2/sections/OpenHoursSection/OpenHoursForm/OpenHoursForm.styled';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames, invalidatesAny } from 'app/businessEditV2/utils/utils';
import { BUSINESS } from 'app/common/data/queryKeysConstants';
import ExplanationWithPublisherList from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithPublisherList';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';
import queryClient from 'app/states/queryClient';

type Props = {
    closeModal: () => void;
    useDirtyUpdate: (boolean) => void;
};

const MenuForm = ({ closeModal, useDirtyUpdate }: Props) => {
    const { t } = useTranslation();
    const colorState = useMenuSectionColor();
    const isLoading = useIsLoadingMenu();
    const { data: business } = useBusiness();
    const { data: menuData, isLoading: isLoadingMenu } = useBusinessFoodMenus();

    const setFormValue = () => ({
        sections: menuData?.menus ?? [],
        photosMenu: business?.photos?.MENU ?? '',
    });

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        watch,
        reset,
    } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    useDirtyUpdate(isDirty);

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const businessUpdate = useBusinessUpdate(
        () => undefined,
        () => undefined,
        true,
    );
    const menuSectionUpdate = useMenuSectionUpdate(
        () => undefined,
        () => undefined,
        true,
    );

    const onSubmit = payload => {
        Promise.all([
            businessUpdate.mutateAsync(formatBusinessPayload(payload)),
            menuSectionUpdate.mutateAsync(formatMenuSectionPayload(payload)),
        ])
            .then(() => {
                // Invalidating queries in the hooks will refetch the other hooks in error
                queryClient.invalidateQueries(invalidatesAny(BUSINESS)).then(() => {
                    businessUpdateCompletionRate.mutate();
                    closeModal();
                });
            })
            .catch(() => undefined);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_MENU_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
    };

    // Permission
    const businessIsOpen = !useIsBusinessClosed();
    const foodMenuPermission =
        useFieldMetadata('food_menu', 'food_menu')?.enabled && businessIsOpen;

    const formatBusinessPayload = payload => {
        if (!foodMenuPermission) {
            return {};
        }

        return {
            photos: { MENU: payload.photosMenu },
        };
    };

    const formatMenuSectionPayload = payload => ({
        businessId: business?.id ?? '',
        sections: payload.sections,
    });

    const submitForm = () => {
        if (foodMenuPermission) {
            handleSubmit(onSubmit)();
            trackSaveBusinessEditSection(SectionNames.MENU, colorState);
        } else {
            closeModal();
        }
    };

    const description =
        (business?.photos?.MENU ?? []).length > 0
            ? t('menu_photos_section_description')
            : t('menu_photos_section_title');

    const foodMenuFormatter = useFormFieldFormatter(
        'food_menu',
        'food_menu',
        watch,
        () => undefined, // currently unnecessary since we do not have any prechecks.
        null,
    );

    return (
        <>
            <EditForm title={t('food_menu')} description={description} colorState={colorState}>
                <div>
                    <Controller
                        name="photosMenu"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <MenuPhotosForm
                                value={value}
                                onChange={onChange}
                                disabled={!foodMenuPermission}
                            />
                        )}
                    />
                    <SectionsTitle>
                        <div>{t('menu_food_section_helper_title')}</div>
                        {foodMenuFormatter.hasWarning && (
                            <OpenHoursPrechecks>
                                <ExplanationWithPublisherList
                                    publisherErrors={foodMenuFormatter.publisherErrors}
                                    publishersInfo={foodMenuFormatter.publishersInfo}
                                    disabled={foodMenuFormatter.disabled}
                                />
                            </OpenHoursPrechecks>
                        )}
                    </SectionsTitle>
                    <Controller
                        name="sections"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <MenuSections
                                isLoading={isLoadingMenu}
                                value={value}
                                disabled={!foodMenuPermission}
                                onChange={onChange}
                            />
                        )}
                    />
                </div>
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={businessUpdate.isLoading || menuSectionUpdate.isLoading}
                    hasError={!!(businessUpdate.error || menuSectionUpdate.error)}
                    onClick={submitForm}
                    dataTrackId="visibility_location__form_menu__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default MenuForm;
