import { ChartType, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Tooltip } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ChartContainer, ChartHeader, OverviewContainer, StyledChart } from './Overview.styled';
import { useChartOptions } from '../../hooks/useChartOptions';
import { useOverviewChartData } from '../../hooks/useOverviewChartData';

export const Overview = () => {
    const { i18n, t } = useTranslation();
    const chartOptions = useChartOptions();
    const data = useOverviewChartData(i18n.language);

    return (
        <OverviewContainer>
            <ChartHeader variant="heading6" as="span">
                {t('key_figures_word')}
                <Tooltip text={t('tooltip_key_figures')}>
                    <Icon
                        icon={[FontAwesomeIconsPartooUsed.faCircleInfo, IconPrefix.REGULAR]}
                        iconSize="16px"
                        color="secondary"
                    />
                </Tooltip>
            </ChartHeader>
            <ChartContainer>
                <StyledChart
                    type={ChartType.Line}
                    dataTrackId={''}
                    data={data}
                    options={chartOptions}
                />
            </ChartContainer>
        </OverviewContainer>
    );
};
