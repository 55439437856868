import React, { useContext, useMemo } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Skeleton, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useBusiness from 'app/common/hooks/queries/useBusiness';
import { STATIC_BASE_URL } from 'app/config';
import ConversationStarterItemText from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/ConversationStarterItemText';
import {
    CREATION_MODE_ITEM_ID,
    ConversationStartersContext,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/contexts/ConversationStartersContext';
import { useConversationStarter } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarter';
import useConversationStartersItems from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStartersItems';
import { useFormatConversationStarterDate } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useFormatConversationStarterDate';
import { formatPlaceholderToMention } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/services/utils';
import { getMappedUrl, resizeImage } from 'app/utils/resizeImage';

import {
    IPhoneTemplate,
    PreviewBusinessHeader,
    PreviewBusinessHeaderContainer,
    PreviewBusinessLabel,
    PreviewBusinessName,
    PreviewConversationStarterMessageBubble,
    PreviewMessageContainer,
    PreviewMessages,
    PreviewMessagesContainer,
    PreviewPanel,
    PreviewReplyMessageBubble,
    PreviewSettingIcon,
    PreviewWelcomeMessageBubble,
} from './PreviewPanel.styled';

type Props = {
    businessId?: string;
};

const PreviewPanelContainer = ({ businessId }: Props) => {
    const { t } = useTranslation();
    const {
        activeConversationStarterItemId,
        activeConversationStarterItem,
        setActiveConversationStarterItemId,
        isEnabled,
        currentWelcomeMessage,
    } = useContext(ConversationStartersContext);

    const { data: businessData } = useBusiness(businessId);

    const { data: conversationStarter, isLoading: isConversationStarterLoading } =
        useConversationStarter();

    const { data } = useConversationStartersItems(conversationStarter?.id);

    const conversationStarterItems = data?.items;

    const opening_hours = useFormatConversationStarterDate(
        businessData?.open_hours,
        businessData?.specific_hours,
    );

    const renderItem = (itemId: string) => {
        if (activeConversationStarterItemId === itemId && activeConversationStarterItem) {
            return activeConversationStarterItem;
        }

        if (conversationStarterItems) {
            return conversationStarterItems.find(item => item.id === itemId);
        }
    };

    const shouldRenderSelectedConversationStarterItem = () => {
        const isExistingItem =
            conversationStarterItems &&
            !!conversationStarterItems.find(item => item.id === activeConversationStarterItemId);
        const isInCreatingItem = activeConversationStarterItemId === CREATION_MODE_ITEM_ID;
        return isExistingItem || isInCreatingItem;
    };

    const placeholders = useMemo(
        () => ({
            business_name: businessData?.name,
            address: businessData?.address_full,
            website_url: businessData?.website_url,
            phone_number: businessData?.contacts[0]?.phone_numbers[0],
            opening_hours: opening_hours,
        }),
        [businessData],
    );

    // Captures all placeholders like "Hello @[client_full_name](client_full_name) how are you?"
    const replacePlaceholders = (templateText: string) => {
        return templateText?.replace(
            /@\[.*?\]\((\w+)\)/g,
            (_match, placeholder_value: string) =>
                placeholders[placeholder_value as keyof typeof placeholders] || '',
        );
    };

    const replyMessagePlaceholder = replacePlaceholders(
        renderItem(activeConversationStarterItemId)?.reply_message ||
            t('conversation_starters_settings_preview_new_item_reply_message'),
    );

    return (
        <PreviewPanel>
            <IPhoneTemplate>
                <img
                    src={`${STATIC_BASE_URL}/images/app/app_review/mobile_preview_template.svg`}
                    alt="iphone-preview"
                />
                {businessData && (
                    <>
                        <PreviewBusinessHeaderContainer>
                            <PreviewBusinessHeader>
                                <PreviewSettingIcon
                                    src={resizeImage(
                                        getMappedUrl(
                                            businessData?.logo_url ??
                                                `${STATIC_BASE_URL}/images/app/app_review/mobile_preview_agent_icon.png`,
                                        ),
                                        400,
                                        400,
                                    )}
                                    alt="agent-icon"
                                />
                                <PreviewBusinessName
                                    color={HEX_COLORS.blackberry}
                                    variant="bodyMBold"
                                >
                                    {businessData?.name ?? t('business_name')}
                                </PreviewBusinessName>
                            </PreviewBusinessHeader>
                        </PreviewBusinessHeaderContainer>
                        <PreviewMessages>
                            <PreviewBusinessLabel>
                                <Text color={'secondary'} variant="bodySRegular">
                                    {businessData?.name ?? t('business_name')}
                                </Text>
                            </PreviewBusinessLabel>

                            <PreviewMessageContainer>
                                <PreviewSettingIcon
                                    src={resizeImage(
                                        getMappedUrl(
                                            businessData?.logo_url ??
                                                `${STATIC_BASE_URL}/images/app/app_review/mobile_preview_agent_icon.png`,
                                        ),
                                        400,
                                        400,
                                    )}
                                    alt="agent-icon"
                                />
                                <PreviewWelcomeMessageBubble>
                                    {!isConversationStarterLoading && businessData ? (
                                        <Text variant="bodySRegular">
                                            {replacePlaceholders(
                                                currentWelcomeMessage ||
                                                    formatPlaceholderToMention(
                                                        t('default_welcome_message_settingsV2'),
                                                        t,
                                                    ),
                                            )}
                                        </Text>
                                    ) : (
                                        <Skeleton variant="line" />
                                    )}
                                </PreviewWelcomeMessageBubble>
                            </PreviewMessageContainer>

                            {conversationStarterItems && conversationStarterItems.length > 0 && (
                                <PreviewMessagesContainer active={isEnabled}>
                                    {conversationStarterItems.map(item => {
                                        return (
                                            <PreviewMessageContainer
                                                key={item.id}
                                                isConsumerMessage
                                            >
                                                <PreviewConversationStarterMessageBubble
                                                    onClick={() =>
                                                        setActiveConversationStarterItemId(item.id)
                                                    }
                                                    active={
                                                        item.id === activeConversationStarterItemId
                                                    }
                                                >
                                                    <Text variant="bodySRegular">
                                                        {renderItem(item.id).title}
                                                    </Text>
                                                </PreviewConversationStarterMessageBubble>
                                            </PreviewMessageContainer>
                                        );
                                    })}

                                    {activeConversationStarterItemId === CREATION_MODE_ITEM_ID && (
                                        <PreviewMessageContainer
                                            key={CREATION_MODE_ITEM_ID}
                                            isConsumerMessage
                                        >
                                            <PreviewConversationStarterMessageBubble
                                                onClick={() =>
                                                    setActiveConversationStarterItemId(
                                                        CREATION_MODE_ITEM_ID,
                                                    )
                                                }
                                            >
                                                <Text variant="bodySRegular">
                                                    {renderItem(CREATION_MODE_ITEM_ID)?.title ||
                                                        t(
                                                            'conversation_starters_settings_preview_new_item_title',
                                                        )}
                                                </Text>
                                            </PreviewConversationStarterMessageBubble>
                                        </PreviewMessageContainer>
                                    )}

                                    {shouldRenderSelectedConversationStarterItem() && (
                                        <>
                                            <PreviewBusinessLabel>
                                                <Text color={'secondary'} variant="bodySRegular">
                                                    {businessData?.name}
                                                </Text>
                                            </PreviewBusinessLabel>
                                            <PreviewMessageContainer>
                                                <PreviewSettingIcon
                                                    src={resizeImage(
                                                        getMappedUrl(
                                                            businessData?.logo_url ??
                                                                `${STATIC_BASE_URL}/images/app/app_review/mobile_preview_agent_icon.png`,
                                                        ),
                                                        400,
                                                        400,
                                                    )}
                                                    alt="agent-icon"
                                                />
                                                <PreviewReplyMessageBubble>
                                                    <Text
                                                        color={HEX_COLORS.white}
                                                        variant="bodySRegular"
                                                    >
                                                        <ConversationStarterItemText
                                                            text={replyMessagePlaceholder}
                                                        />
                                                    </Text>
                                                </PreviewReplyMessageBubble>
                                            </PreviewMessageContainer>
                                        </>
                                    )}
                                </PreviewMessagesContainer>
                            )}
                        </PreviewMessages>
                    </>
                )}
            </IPhoneTemplate>
        </PreviewPanel>
    );
};

export default PreviewPanelContainer;
