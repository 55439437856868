import { RefObject, useRef } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix } from '@partoohub/ui';
import { Outlet, useLocation } from 'react-router-dom';

import IS_IFRAME from 'app/common/data/iframe';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';

import { useScrollToRef } from 'app/hooks/useScrollToRef';
import { REGEX_SETTINGS_CATEGORY_EXACT_PATH } from 'app/pages/settingsV2/helpers';
import {
    SettingMenuItem,
    SettingsMenuList,
} from 'app/pages/settingsV2/subComponents/SettingsMenuList/SettingsMenuList';

import {
    MenuListContent,
    ScrollToBottomContainer,
    SettingSkeletonContainer,
} from './SettingSkeleton.styled';

interface SettingsSkeleton {
    sections: SettingMenuItem[];
    activateScroll?: boolean;
}

export const SettingSkeleton = ({ sections, activateScroll = true }: SettingsSkeleton) => {
    const { pathname } = useLocation();
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const shouldNotRender = (section: SettingMenuItem) =>
        section.options.every(option => option.display === false);

    const targetScrollRef: RefObject<HTMLElement> | null = useRef(
        document.getElementById('react-content'),
    );
    const boundaryRef: RefObject<HTMLDivElement> | null = useRef(null);
    const { isBoundary: isAtTop, handleScroll } = useScrollToRef(boundaryRef, targetScrollRef);

    const shouldNotRenderComponent = sections.every(section => shouldNotRender(section));

    const matchSettingsCategoryPath = REGEX_SETTINGS_CATEGORY_EXACT_PATH.test(pathname);

    if (shouldNotRenderComponent) {
        return null;
    }

    return (
        <>
            <div ref={boundaryRef} />
            <SettingSkeletonContainer isBelowTablet={isBelowProvidedDevices}>
                {!IS_IFRAME && (matchSettingsCategoryPath || !isBelowProvidedDevices) && (
                    <SettingsMenuList sections={sections} />
                )}
                <MenuListContent>
                    <Outlet />
                </MenuListContent>
                {activateScroll && !isAtTop && (
                    <ScrollToBottomContainer>
                        <IconButton
                            icon={[FontAwesomeIconsPartooUsed.faChevronUp, IconPrefix.SOLID]}
                            dataTrackId="scroll_to_top"
                            onClick={handleScroll}
                        />
                    </ScrollToBottomContainer>
                )}
            </SettingSkeletonContainer>
        </>
    );
};
