import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const StyledCard = styled(Stack)`
    display: flex;
    min-height: 190px;
    width: 100%;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1;
    background-color: white;
    border-radius: 8px;
`;
