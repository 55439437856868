import React from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

import {
    BoxIcon,
    TitleContainer,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/CardTitle/CardTitle.styled';

type Props = {
    icon: React.ReactNode;
    color: any;
    children: React.ReactNode;
};

export const CardTitle = ({
    icon,
    children,
    color = { hex: HEX_COLORS.blackberry, rgba: 'transparent' },
}: Props) => {
    return (
        <TitleContainer>
            {icon && <BoxIcon color={color}>{icon}</BoxIcon>}
            <Text variant="heading6">{children}</Text>
        </TitleContainer>
    );
};
