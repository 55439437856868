import { CommentData } from 'app/api/types/comments';
import { ReviewObject } from 'app/states/reviews';

const insertCommentData = (
    reviewComments: Array<CommentData>,
    newComment: CommentData,
): Array<CommentData> => {
    if (!newComment.parent_id) {
        return [...reviewComments, newComment];
    }

    return reviewComments.map(reviewComment => {
        if (reviewComment.id === newComment.parent_id) {
            return {
                ...reviewComment,
                replies: [...(reviewComment.replies ?? []), newComment],
            };
        }
        return reviewComment;
    });
};

export const createReviewCommentData = (review: ReviewObject, commentData: CommentData) => {
    const { comments: reviewComments } = review;
    const newComments = insertCommentData(reviewComments, commentData);
    return { ...review, comments: newComments };
};
