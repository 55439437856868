import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

import Flexbox from 'app/styles/utils/flexbox';

export const MessageSender = styled(Text)`
    margin-bottom: 4px;
    margin-right: 15px;
    text-align: right;
    ${(props: { isConsumer: boolean }) =>
        props.isConsumer &&
        css`
            text-align: left;
            margin-left: 25px;
            margin-right: 0;
        `};
`;

export const MessageBubbleFlexbox = styled(Flexbox)`
    margin-bottom: 8px;
`;

export const MessageConsumerIconContainer = styled.div`
    ${(props: { hide: boolean }) =>
        props.hide &&
        css`
            visibility: hidden;
        `}
`;
