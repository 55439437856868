import { FC, MouseEvent } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Button, Modal, Text } from '@partoohub/ui';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { usePopupWindow } from 'app/common/hooks/usePopupWindow';
import { STATIC_BASE_URL } from 'app/config';
import { OnboardingReportData } from 'app/reviewManagement/messaging/messenger/AddPartnersButtons';
import { FACEBOOK_OAUTH2_START_PATH } from 'app/routing/routeIds';
import { Camel } from 'app/utils/types';

import {
    LargeButton,
    ModalContainer,
    StyledBodyText,
    StyledButtonContainer,
    StyledCancelButton,
    StyledErrorBackground,
    StyledList,
    StyledMessengerIcon,
    StyledModalContent,
} from './AddPartnerModal.styled';

const MessengerIcon: FC = () => (
    <StyledMessengerIcon
        src={`${STATIC_BASE_URL}/images/app/app_messaging/messenger_3D_icon.png`}
        alt="messenger_icon"
    />
);

const Background: FC = () => (
    <img
        src={`${STATIC_BASE_URL}/images/app/app_messaging/messenger_modal_background.svg`}
        alt="background"
    />
);

export const ErrorBackground: FC = () => (
    <StyledErrorBackground
        src={`${STATIC_BASE_URL}/images/app/app_messaging/messenger_modal_error_background.svg`}
        alt="background"
    />
);

interface Props {
    show: boolean;
    onHideClick: () => void;
    onButtonClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

interface TextProps {
    title: string;
    subTitle: string;
    cancelText?: string;
    buttonText: string;
}

const MessengerModal: FC<Props & TextProps> = ({
    show,
    onHideClick,
    title,
    subTitle,
    cancelText,
    buttonText,
    onButtonClick = () => undefined,
}) => (
    <Modal isOpen={show} closeModal={onHideClick}>
        <ModalContainer>
            <Background />
            <MessengerIcon />
            <StyledModalContent style={{ textAlign: 'center' }}>
                <Text variant="heading4" as="h4" color={HEX_COLORS.blackberry}>
                    {title}
                </Text>
                <StyledBodyText variant="bodyMRegular" as="span" color={'secondary'}>
                    {subTitle}
                </StyledBodyText>
            </StyledModalContent>
            {cancelText && (
                <StyledCancelButton>
                    <StrikeThroughButton
                        text={cancelText}
                        color="secondary"
                        onClick={onHideClick}
                    />
                </StyledCancelButton>
            )}
            <StyledButtonContainer>
                <Button
                    dataTrackId="add_messenger__button"
                    variant="primary"
                    full
                    size="large"
                    shape="cube"
                    onClick={onButtonClick}
                >
                    {buttonText}
                </Button>
            </StyledButtonContainer>
        </ModalContainer>
    </Modal>
);

export const AddMessengerModal: FC<Props & { onClose: () => void }> = ({ onClose, ...props }) => {
    const { t } = useTranslation();
    const openOauthDialog = usePopupWindow(
        `${FACEBOOK_OAUTH2_START_PATH}?${qs.stringify({ state: 'messages' })}`,
        { onClose },
    );
    const { onButtonClick, ...rest } = props;
    return (
        <MessengerModal
            title={t('messaging_add_messenger_information_modale_title')}
            subTitle={t('messaging_add_messenger_information_modale_text')}
            cancelText={t('messaging_add_messenger_information_modale_later_button')}
            buttonText={t('messaging_add_messenger_information_modale_button')}
            onButtonClick={() => openOauthDialog()}
            {...rest}
        />
    );
};

export const MessengerAddedModal: FC<Props> = ({ ...props }) => {
    const { t } = useTranslation();
    return (
        <MessengerModal
            title={t('messaging_add_messenger_confirmation_modale_title')}
            subTitle={t('messaging_add_messenger_confirmation_modale_text')}
            buttonText={t('messaging_add_messenger_confirmation_modale_button')}
            {...props}
        />
    );
};

interface MessengerErrorModalProps {
    report: Camel<OnboardingReportData>;
}

export const MessengerErrorModal: FC<Props & MessengerErrorModalProps> = ({
    show,
    onHideClick,
    onButtonClick,
    report,
}) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={show} closeModal={onHideClick}>
            <ModalContainer>
                <ErrorBackground />
                <StyledModalContent marginTop="14px">
                    <Text variant="heading4" as="h4" color={HEX_COLORS.blackberry}>
                        {t('messaging_add_messenger_error_modale_title')}
                    </Text>
                    <StyledBodyText variant="bodyMRegular" as="span" color={'secondary'}>
                        {t('messaging_add_messenger_error_modale_text')}
                        {report.errorCount !== null && report.successCount !== null && (
                            <StyledList>
                                <li>
                                    {t('messaging_add_messenger_error_modale_text_error_count', {
                                        count: report.errorCount,
                                    })}
                                </li>
                                <li>
                                    {t('messaging_add_messenger_error_modale_text_success_count', {
                                        count: report.successCount,
                                    })}
                                </li>
                            </StyledList>
                        )}
                    </StyledBodyText>
                </StyledModalContent>
                <LargeButton
                    dataTrackId="add_messenger_error_modal__button"
                    full
                    variant="primary"
                    shape="cube"
                    onClick={onButtonClick}
                >
                    {t('messaging_add_messenger_error_modale_button')}
                </LargeButton>
            </ModalContainer>
        </Modal>
    );
};
