import { useSelector } from 'react-redux';

import { CommentData } from 'app/api/types/comments';
import { canSendClevertapEventsSelector } from 'app/common/reducers/me';
import { PushNotifsEvent } from 'app/common/services/pushNotifications/events';
import { PushNotificationsWrapper } from 'app/common/services/pushNotifications/pushNotificationsWrapper';
import { ReviewObject } from 'app/states/reviews';

export const useNotifyOnComment = () => {
    const canSendClevertapEvents = useSelector(canSendClevertapEventsSelector);

    const notifyOnComment = (review: ReviewObject, comment?: CommentData) => {
        if (canSendClevertapEvents) {
            PushNotificationsWrapper.getInstance().sendEvent(PushNotifsEvent.REPLY_TO_REVIEW, {
                review_id: review.id,
                is_first_reply: !comment?.id,
            });
        }
    };

    return notifyOnComment;
};
