import { useContext } from 'react';

import { IconPrefix } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { GoogleButton } from 'app/common/components/funnel/Buttons';
import { CommonButton } from 'app/common/components/funnel/Buttons.styled';
import FunnelContext from 'app/common/components/funnel/FunnelContext';
import {
    FullPageContainer,
    SubTitle,
    Title,
} from 'app/common/components/funnel/FunnelModal.styled';
import { STATIC_BASE_URL } from 'app/config';
import { VISIBILITY_LOCATION_ADD_PATH } from 'app/routing/routeIds';

const NoLocationPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const funnel = useContext(FunnelContext);

    const handleCreateLocation = () => {
        funnel.navigate('add_location_page');
        funnel.abort();
        navigate(VISIBILITY_LOCATION_ADD_PATH);
    };

    return (
        <FullPageContainer>
            <img
                src={`${STATIC_BASE_URL}/images/app/funnel/business.svg`}
                alt="business"
            />
            <Title>{t('onboarding_no_location_page_title')}</Title>
            <SubTitle>{t('onboarding_no_location_page_sub_title')}</SubTitle>
            <CommonButton
                dataTrackId="go_to_create_location_onboarding"
                shape="cube"
                size="large"
                appearance="contained"
                variant="primary"
                onClick={handleCreateLocation}
                icon={['fa-plus', IconPrefix.SOLID]}
            >
                {t('onboarding_no_location_page_create_button')}
            </CommonButton>
            <GoogleButton
                text={t('onboarding_no_location_page_google_button')}
            />
        </FullPageContainer>
    );
};

export default NoLocationPage;
