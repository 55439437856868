import { css } from '@emotion/react';
import styled from '@emotion/styled';

type ModalParagraphProps = {
    isExample?: boolean;
};

export const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SectionSubTitle = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-weight: normal;
`;

export const ModalContent = styled.div`
    max-width: 500px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 8px;

    p {
        margin-left: auto;
        margin-right: auto;
    }
`;

export const ModalImage = styled.img`
    width: 100%;
`;

export const ModalSlide = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
`;

export const ModalText = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 25px 0 50px 0;
    text-align: center;
`;

export const ModalTitle = styled.p`
    margin-top: 8px;
    max-width: 65%;
    font-size: 26px;
    font-weight: bold;
    line-height: 100%;
`;

export const ModalParagraph = styled.p<ModalParagraphProps>`
    margin-top: 8px;
    max-width: 75%;
    font-size: 14px;
    white-space: pre-wrap;
    text-align: left;

    ${({ isExample, theme }) =>
        isExample
            ? css`
                  color: ${theme.colors.primary.initial};
                  font-style: italic;
              `
            : css`
                  color: ${theme.colors.secondary.initial};
              `}
`;
