import styled from '@emotion/styled';
import { Button } from '@partoohub/ui';

export const ModalContent = styled.div`
    padding: 26px 16px 36px 16px;
    width: 470px;
    text-align: center;
    display: flex;
    flex-direction: column;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    margin-top: 24px;
`;

export const MarginHolder = styled.div<{ height: number }>`
    height: ${({ height }) => height + 'px'};
`;
