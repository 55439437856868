import { Chart, ChartType } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { sumValues } from 'app/common/utils/sumValues';

import { BAR_CHART_OPTIONS } from './common';
import { useReviewMetricsByDate } from '../../../hooks/useMetrics';

export const ReplyTimeGraph = () => {
    const { t } = useTranslation();
    const metrics = useReviewMetricsByDate();

    if (!metrics) return undefined;

    const { data } = metrics;

    return (
        <Chart
            dataTrackId=""
            type={ChartType.Bar}
            options={BAR_CHART_OPTIONS}
            data={{
                datasets: [
                    {
                        label: t('review_analytics_reply_time_fast'),
                        data: data.map(({ metrics: { reply_time } }) => {
                            const total = sumValues(reply_time);
                            return total ? (100 * reply_time.fast) / total : 0;
                        }),
                        backgroundColor: 'rgba(44, 184, 132, 0.8)',
                    },
                    {
                        label: t('review_analytics_reply_time_slow'),
                        data: data.map(({ metrics: { reply_time } }) => {
                            const total = sumValues(reply_time);
                            return total ? (100 * reply_time.slow) / total : 0;
                        }),
                        backgroundColor: 'rgba(247, 194, 0, 0.55)',
                    },
                    {
                        label: t('review_analytics_reply_time_not_replied'),
                        data: data.map(({ metrics: { reply_time } }) => {
                            const total = sumValues(reply_time);
                            return total ? (100 * reply_time.not_replied) / total : 0;
                        }),
                        backgroundColor: 'rgba(255, 92, 154, 0.8)',
                    },
                ],
                labels: data.map(d => d.dimension),
            }}
        />
    );
};
