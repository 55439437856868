import { call, put, takeEvery } from 'redux-saga/effects';

import { AccountPartner, V2LocationData } from 'app/api/types/account';
import api from 'app/api/v2/api_calls';
import formatLocation from 'app/businessConnection/services/formatLocation';
import {
    getPartnerLocationFailureAction,
    getPartnerLocationSuccessAction,
} from 'app/businessConnection/services/PartnerToAccountsMethods';
import { Saga } from 'app/common/types';
import {
    FACEBOOK,
    GOOGLE_MY_BUSINESS,
} from 'app/states/knowledge/reducers/businessConnection/accounts';
import {
    GET_FACEBOOK_LOCATION_REQUEST,
    GetFacebookLocationAction,
} from 'app/states/knowledge/reducers/businessConnection/facebook/facebookLocations';
import {
    GET_GMB_LOCATION_REQUEST,
    GetGmbLocationAction,
} from 'app/states/knowledge/reducers/businessConnection/gmb/gmbLocations';

function* getLocationSaga(
    partner: AccountPartner,
    { locationId }: GetGmbLocationAction | GetFacebookLocationAction,
): Saga {
    try {
        const data = yield call(api.account.getLocationDetail, locationId, partner);
        const formattedLocation = formatLocation(data as V2LocationData);
        yield put(getPartnerLocationSuccessAction[partner](formattedLocation));
    } catch (error) {
        yield put(getPartnerLocationFailureAction[partner](locationId, error));
    }
}

function* partnerConnectionGetLocationSaga(): Saga {
    yield takeEvery(GET_GMB_LOCATION_REQUEST, getLocationSaga, GOOGLE_MY_BUSINESS);
    yield takeEvery(GET_FACEBOOK_LOCATION_REQUEST, getLocationSaga, FACEBOOK);
}

export default partnerConnectionGetLocationSaga;
