import React, { ReactNode } from 'react';

import {
    EditDescription,
    EditFormBox,
    EditFormBusiness,
    EditFormContainer,
    EditFormHeader,
    EditFormHeaderIcon,
    EditFormHeaderRight,
    EditSubtitle,
    EditTitle,
} from './EditForm.styled';

type Props = {
    title: string;
    icon: ReactNode;
    subtitle?: string;
    children: ReactNode;
    description?: string;
    headerRight?: ReactNode;
};

const EditForm = ({ title, subtitle, icon, children, description, headerRight }: Props) => {
    return (
        <EditFormContainer>
            <EditFormBox>
                <EditFormHeader>
                    <EditFormHeaderIcon>{icon}</EditFormHeaderIcon>
                    <EditFormBusiness>
                        <EditTitle>{title}</EditTitle>
                        {subtitle && <EditSubtitle>{subtitle}</EditSubtitle>}
                    </EditFormBusiness>
                    <EditFormHeaderRight>{headerRight}</EditFormHeaderRight>
                </EditFormHeader>
                {description && <EditDescription>{description}</EditDescription>}
                {children}
            </EditFormBox>
        </EditFormContainer>
    );
};

export default EditForm;
