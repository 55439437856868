import { Text } from '@partoohub/ui';

type Props = {
    title: string;
    subtitle: string;
    rightComponent?: JSX.Element;
};

const PublisherBusinessDetails = ({
    title,
    subtitle,
    rightComponent = undefined,
}: Props): JSX.Element => (
    <div className="flex--row flex--align_items_end flex--justify_between">
        <div className="flex--col min_width-zero children_but_last_margin--right">
            <Text variant="bodyMBold" color="secondary" oneLine noTooltip>
                {title}
            </Text>
            <Text variant="bodyMRegular" color="secondary" oneLine noTooltip>
                {subtitle}
            </Text>
        </div>
        <div>{rightComponent}</div>
    </div>
);

export default PublisherBusinessDetails;
