import React from 'react';

import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { BusinessAttributeEnumValueType } from 'app/api/types/attributes';
import { Choice } from 'app/api/types/user';
import PairedButtons from 'app/common/designSystem/components/molecules/PairedButtons';

import stringCompare from 'app/common/utils/stringCompare';

import {
    AttributeTypeRepeatableEnumContainer,
    AttributeTypeRepeatableEnumName,
    SubSection,
    SubSectionPair,
} from './AttributesTypeRepeatableEnum.styled';

type Props = {
    value: BusinessAttributeEnumValueType | null;
    name: string;
    possibleValues: Array<Choice>;
    onChange: (newValue: BusinessAttributeEnumValueType | null) => void;
    disabled: boolean;
    displayName?: string;
};

const getEnumValue = (value: Record<string, any> | null, attributeValue: string) => {
    if (value?.set_values.some(opt => opt === attributeValue)) {
        return 'yes';
    }

    if (value?.unset_values.some(opt => opt === attributeValue)) {
        return 'no';
    }

    return 'not_specified';
};

const Component = ({ value, name, possibleValues, onChange, disabled, displayName }: Props) => {
    const { t } = useTranslation();
    const setEnumValue = (boolValue: boolean | null, option: string) => {
        // Filter the option
        const newValue =
            value === null
                ? {
                      set_values: [],
                      unset_values: [],
                  }
                : {
                      set_values: value.set_values.filter(valueOption => valueOption !== option),
                      unset_values: value.unset_values.filter(
                          valueOption => valueOption !== option,
                      ),
                  };

        // Add option when needed
        if (boolValue === true) {
            newValue.set_values.push(option);
            newValue.set_values.sort(stringCompare);
        } else if (boolValue === false) {
            newValue.unset_values.push(option);
            newValue.unset_values.sort(stringCompare);
        }

        if (newValue.set_values.length || newValue.unset_values.length) {
            onChange(newValue);
        } else {
            onChange(null);
        }
    };

    return (
        <AttributeTypeRepeatableEnumContainer>
            <div>
                <AttributeTypeRepeatableEnumName
                    disabled={disabled}
                    variant={'bodySBold'}
                    color={'secondary'}
                >
                    {displayName || name}
                </AttributeTypeRepeatableEnumName>
                <SubSection>
                    {possibleValues.map((option, index) => (
                        <SubSectionPair key={index}>
                            <AttributeTypeRepeatableEnumName
                                disabled={disabled}
                                variant={'bodySBold'}
                                color={'secondary'}
                            >
                                {option.label}
                            </AttributeTypeRepeatableEnumName>
                            <PairedButtons
                                selectedButton={t(getEnumValue(value, option.value))}
                                options={[
                                    {
                                        label: t('yes'),
                                        value: true,
                                    },
                                    {
                                        label: t('no'),
                                        value: false,
                                    },
                                    {
                                        label: t('not_specified'),
                                        value: null,
                                    },
                                ]}
                                onClick={val => setEnumValue(val, option.value)}
                                disabled={disabled}
                                textTooltip={disabled ? t('field_read_only') : ''}
                            />
                        </SubSectionPair>
                    ))}
                </SubSection>
            </div>
        </AttributeTypeRepeatableEnumContainer>
    );
};

type ControlledComponent = {
    name: string;
    control: Control<any>;
};

type OmitProps = 'onChange' | 'value';

const AttributesTypeRepeatableEnum = ({
    name,
    control,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { ref: _ref, ...usedFields } }) => (
            <Component {...props} {...usedFields} />
        )}
    />
);

export default AttributesTypeRepeatableEnum;
