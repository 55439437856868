import styled from '@emotion/styled';

export const TextBubbleContainer = styled.div`
    gap: 4px;
    display: flex;
    flex-direction: column;
    && > div {
        border-radius: 20px 0px 0px 20px;
    }
    && > div:last-of-type {
        border-radius: 20px 0px 20px 20px;
    }
    && > div:first-of-type {
        border-radius: 20px 20px 0px 20px;
    }
`;

export const TextBubbleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-radius: inherit;
`;

export const IconWrapper = styled.div`
    &:hover {
        cursor: pointer;
    }
`;
