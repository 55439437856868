import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { PUBLISHERS_STATES } from 'app/common/data/queryKeysConstants';

export const usePublishersStates = () => {
    const businessModalFilters = useBusinessModalFilters();

    return useQuery([PUBLISHERS_STATES, businessModalFilters], () =>
        api.publisherState.getPublishersStates(businessModalFilters),
    );
};
