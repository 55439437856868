import { useEffect, useRef, useState } from 'react';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { IconButton, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useLanguage } from 'app/common/services/getLang';
import { EMOJI_LANGUAGE } from 'app/common/services/languages';

import { EmojiPickerWrapper } from './EmojiPicker.styled';

type Props = {
    showOnLeft?: boolean;
    onClick: () => void;
    onEmojiClick: (emoji: string) => void;
};

function EmojiPicker({ showOnLeft = false, onClick, onEmojiClick = _ => undefined }: Props) {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const language = useLanguage();

    const onClickOutside = ({
        // @ts-expect-error
        target,
    }) => {
        if (showModal && ref && ref.current && !ref.current.contains(target)) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside);
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    });

    return (
        <div>
            <IconButton
                dataTrackId="review-add-emoji"
                appearance="outlined"
                icon={['fa-face-smile', IconPrefix.REGULAR]}
                onClick={() => {
                    setShowModal(true);
                    onClick();
                }}
                tooltip={t('review_add_emoji_tooltip')}
            />
            {showModal && (
                <EmojiPickerWrapper ref={ref} showOnLeft={showOnLeft}>
                    <Picker
                        data={data}
                        onEmojiSelect={({ native: emoji }: { native: string }) => {
                            setShowModal(false);
                            onEmojiClick(emoji);
                        }}
                        i18n={EMOJI_LANGUAGE[language]}
                        previewPosition={'none'}
                        navPosition={'bottom'}
                        perLine={7}
                        theme={'light'}
                    />
                </EmojiPickerWrapper>
            )}
        </div>
    );
}

export default EmojiPicker;
