import { useEffect, useMemo, useState } from 'react';

import { DatePickerType, List, RangeDate } from '@partoohub/ui';

import { format } from 'date-fns';
import { uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useInfiniteQuery } from 'react-query';

import { InvoiceData } from 'app/api/types/invoice';

import api from 'app/api/v2/api_calls';
import { LocaleDatePicker } from 'app/common/components/LocaleDatePicker/LocaleDatePicker';
import { INVOICE } from 'app/common/data/queryKeysConstants';
import { DeviceType, useDeviceType } from 'app/hooks';
import { useVisibilityContext } from 'app/pages/settingsV2/subComponents/DesktopHeader/DesktopHeader.context';
import {
    InvoiceContainer,
    MobileInvoiceContainer,
} from 'app/settingsManagement/components/Invoice/Invoice.styled';
import InvoicePlaceholder from 'app/settingsManagement/components/Invoice/InvoicePlaceholder/InvoicePlaceholder';
import InvoiceRow from 'app/settingsManagement/components/Invoice/InvoiceRow/InvoiceRow';
import MobileInvoiceRow from 'app/settingsManagement/components/Invoice/MobileInvoiceRow/MobileInvoiceRow';
import { DATE_FORMAT } from 'app/utils/dates';

const Invoice = () => {
    const { t } = useTranslation();
    const { device } = useDeviceType();
    const [dates, setDates] = useState<RangeDate>([null, null]);
    const [datesPicker, setDatesPicker] = useState<RangeDate>([null, null]);
    const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(
        [INVOICE, dates],
        ({ pageParam = 1 }) =>
            api.invoice.getInvoices(pageParam, {
                filter_date__gte: dates[0] ? format(dates[0], DATE_FORMAT) : undefined,
                filter_date__lte: dates[1] ? format(dates[1], DATE_FORMAT) : undefined,
            }),
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );

    const invoices = useMemo(
        () => uniqBy(data?.pages?.flatMap(page => page.invoices) ?? [], invoice => invoice.id),
        [data],
    );

    const { setHeaderVisible } = useVisibilityContext();

    let columns: any[];
    const isMobile = device === DeviceType.Mobile || device === DeviceType.MobileApp;

    useEffect(() => {
        setHeaderVisible(!isMobile);
    }, [isMobile]);

    if (isMobile) {
        columns = [{ label: '' }, { label: '', width: '80px' }, { label: '', width: '40px' }];
    } else {
        columns = [
            { label: t('date') },
            { label: t('invoice_number') },
            { label: t('invoice_status') },
            { label: t('invoice_price') },
            { label: '', width: '40px' },
        ];
    }

    const isNoDateSelected = (data: RangeDate): boolean => {
        return JSON.stringify([null, null]) === JSON.stringify(data);
    };

    const Container = isMobile ? MobileInvoiceContainer : InvoiceContainer;
    const Row = isMobile ? MobileInvoiceRow : InvoiceRow;

    const handleResetFilter = () => {
        setDatesPicker([null, null]);
        setDates([null, null]);
    };
    return (
        <Container>
            <LocaleDatePicker
                dataTrackId="month-year-range-picker"
                type={DatePickerType.DateRangePicker}
                labels={{
                    startDate: t('date_begin'),
                    endDate: t('date_end'),
                }}
                placeholders={{
                    startDate: t('date_placeholder'),
                    endDate: t('date_placeholder'),
                }}
                dates={datesPicker}
                onChange={(data: RangeDate) => {
                    setDatesPicker(data);
                    if (isNoDateSelected(data) || (data[0] && data[1])) {
                        setDates(data);
                    }
                }}
            />

            <InfiniteScroll
                hasMore={hasNextPage && !isFetchingNextPage}
                loadMore={() => fetchNextPage()}
                useWindow={false}
                getScrollParent={() => document.getElementById('react-content')}
            >
                <List columns={columns} loading={isLoading}>
                    {invoices.length == 0 && (
                        <InvoicePlaceholder
                            is_default={isNoDateSelected(dates)}
                            resetFilter={handleResetFilter}
                        />
                    )}

                    {invoices.length > 0 &&
                        invoices.map((invoice: InvoiceData) => (
                            <Row key={invoice.id} invoice={invoice} />
                        ))}
                </List>
            </InfiniteScroll>
        </Container>
    );
};

export default Invoice;
