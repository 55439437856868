import React, { ReactNode } from 'react';

import { Text } from '@partoohub/ui';

import { STATIC_BASE_URL } from 'app/config';

import { StyledAdvice, StyledIcon } from './Advice.styled';

type Props = {
    children: ReactNode;
};

const Advice = ({ children }: Props) => {
    return (
        <StyledAdvice>
            <StyledIcon src={`${STATIC_BASE_URL}/images/app/icons/lightbulb.svg`} alt="advice" />
            <Text variant="bodyMMedium" color={'secondary'}>
                {children}
            </Text>
        </StyledAdvice>
    );
};

export default Advice;
