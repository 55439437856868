import styled from '@emotion/styled';

import VARS from 'app/styles/constants/vars';

export const FormRowStyled = styled('div')`
    display: flex !important;
    flex-direction: row !important;
    flex: 1;

    > *:nth-of-type(n + 1) {
        margin-left: calc(3 * ${VARS.paddingSimple});
        flex: 1;
    }

    > *:first-of-type {
        margin-left: 0;
        flex: 1;
    }
`;
