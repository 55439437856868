import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

type ArrowProps = {
    direction: string;
};

export const ArrowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: ${HEX_COLORS.white};
    color: ${({ theme }) => theme.colors.secondary.initial};
    box-shadow: 0px 2px 3px ${greyColorObject.initial};
    cursor: pointer;

    ${(props: ArrowProps) =>
        props.direction === 'left'
            ? css`
                  left: 0;
                  border-radius: 100px 100px 100px 100px;
              `
            : css`
                  right: 0;
                  border-radius: 100px 100px 100px 100px;
              `}
`;
