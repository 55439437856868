import { format, isValid, parse } from 'date-fns';

/*
 ** Convert a time string to a 24-hour formatted time string
 ** Returns undefined if the input is not empty but not a valid time
 */
export const convertTimeTo24hourFormat = (timeStr: string | undefined) => {
    if (!timeStr) return timeStr;

    if (isValid(parse(timeStr, 'HH:mm', new Date()))) {
        // Already in 24-hour format
        return timeStr;
    }

    const time12hWithMinutes = parse(timeStr.replace(/\s+/g, ''), 'hh:mma', new Date());
    if (isValid(time12hWithMinutes)) {
        return format(time12hWithMinutes, 'HH:mm');
    }

    const time12hWithoutMinutes = parse(timeStr.replace(/\s+/g, ''), 'hha', new Date());
    if (isValid(time12hWithoutMinutes)) {
        return format(time12hWithoutMinutes, 'HH:mm');
    }

    return undefined;
};

/*
 ** Convert a 24-hour formatted time string to a locale formatted time string
 ** Returns undefined if the input is not empty but not a valid time
 */
export const convert24hTimeToLocaleFormat = (timeStr: string) => {
    if (!timeStr) return timeStr;

    const time24h = parse(timeStr, 'HH:mm', new Date());
    if (!isValid(time24h)) {
        return undefined;
    }

    return format(time24h, 'p');
};
