import React from 'react';

import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import EditSection from 'app/businessEditV2/components/EditSection';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import UnsavedModal from 'app/businessEditV2/components/UnsavedModal';
import useDisplayMenu from 'app/businessEditV2/hooks/editV2utils/displaySections/useDisplayMenu';
import useIsLoadingMenu from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingMenu';
import useMenuSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useMenuSectionColor';
import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import MenuSectionContent from 'app/businessEditV2/sections/MenuSection/MenuSectionContent/MenuSectionContent';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import MenuForm from './MenuForm/MenuForm';

const MenuSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const {
        showModal,
        setShowModal,
        closeSafeModal,
        showUnsavedModal,
        setShowUnsavedModal,
        useDirtyUpdate,
    } = useUnsavedModal(SectionNames.MENU);

    const displayFoodMenu = useDisplayMenu();
    const sectionColor = useMenuSectionColor();
    const { t } = useTranslation();

    const isLoading = useIsLoadingMenu();

    if (isLoading) {
        return <EditSectionLoader />;
    }

    if (!displayFoodMenu) {
        return null;
    }

    return (
        <div
            ref={ref}
            data-track="visibility_location__section_menu"
            data-intercom-target="visibility_location__section_menu"
        >
            <EditSection
                title={t('food_menu')}
                description={<MenuSectionContent colorState={sectionColor} />}
                onClick={() => setShowModal(true)}
                colorState={sectionColor}
                sectionName={SectionNames.MENU}
            />
            <FullScreenModal
                show={showModal}
                childrenRight={
                    <EditFormDescription
                        title={t('business_edit_title_menu')}
                        description={t('business_edit_description_menu')}
                        advice={t('business_edit_advice_menu')}
                    />
                }
                childrenLeft={
                    <>
                        <MenuForm
                            closeModal={() => setShowModal(false)}
                            useDirtyUpdate={useDirtyUpdate}
                        />
                        <UnsavedModal
                            show={showUnsavedModal}
                            cancel={() => setShowUnsavedModal(false)}
                            validate={() => setShowModal(false)}
                        />
                    </>
                }
                onHideClick={closeSafeModal}
            />
        </div>
    );
});

export default MenuSection;
