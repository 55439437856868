import { ShortcutCreateUser } from 'app/pages/Homepage/sections/HomepageShortcuts/shortcuts/ShortcutCreateUser';
import { Item } from 'app/pages/Homepage/utils/typing';
import { useCanCreateUser } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/hooks/useCanCreateUser';

export const useShortcutCreateUserItem = (): Item => {
    const isAvailable = useCanCreateUser();

    return {
        order: 5,
        isAvailable,
        component: ShortcutCreateUser,
        key: 'shortcut-create-user',
    };
};
