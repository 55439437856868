// TODO: Replace by InfiniteScroll from 'react-infinite-scroll-component'
import { useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import InfiniteScroll from 'react-infinite-scroller';

import { useSearchParams } from 'react-router-dom';

import { ALL_TEMPLATES, FormattedReplyTemplate } from 'app/api/types/reviewReplyTemplates';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { OUTLINED, Type } from 'app/common/designSystem/constants/type';

import { EditableReplyTemplateCard } from './EditableReplyTemplateCard';
import { ReplyTemplateForm } from './replyTemplateForm/ReplyTemplateForm';
import { StyledReviewTemplates } from './ReplyTemplateTable.styled';
import TemplateCardLoader from './TemplateCardLoader';

interface Props {
    replyTemplates: FormattedReplyTemplate[];
    isLoading: boolean;
    hasNextPage: boolean;
    fetchNextPage: () => void;
    creatingTemplate: boolean;
    onAfterCreateTemplate: () => void;
}

export function ReplyTemplateTable({
    replyTemplates,
    isLoading,
    hasNextPage,
    fetchNextPage,
    creatingTemplate,
    onAfterCreateTemplate,
}: Props) {
    const [queryParams, setSearchParams] = useSearchParams();

    const resetFilters = () => {
        for (const queryParam of queryParams.keys()) {
            if (queryParam == 'rating') {
                queryParams.set(queryParam, ALL_TEMPLATES);
            } else {
                queryParams.set(queryParam, '');
            }
        }

        setSearchParams(queryParams);
    };

    const [duplicatingTemplate, setDuplicatingTemplate] = useState<
        { initialValues: FormattedReplyTemplate } | undefined
    >(undefined);

    const onDuplicate = (fromTemplate: FormattedReplyTemplate) => {
        setDuplicatingTemplate({ initialValues: fromTemplate });
    };

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };

    return (
        <div>
            <InfiniteScroll
                hasMore={hasNextPage}
                loadMore={fetchNextPage}
                useWindow={false}
                getScrollParent={() => document.getElementById('react-content')}
                threshold={500}
            >
                <StyledReviewTemplates>
                    {creatingTemplate && <ReplyTemplateForm onClose={onAfterCreateTemplate} />}
                    {duplicatingTemplate && (
                        <ReplyTemplateForm
                            initialValues={duplicatingTemplate.initialValues}
                            onClose={() => setDuplicatingTemplate(undefined)}
                            duplicated
                        />
                    )}
                    {replyTemplates.map(template => (
                        <EditableReplyTemplateCard
                            key={template.id}
                            template={template}
                            onClickDuplicate={() => onDuplicate(template)}
                        />
                    ))}
                    {(isLoading || hasNextPage) && <TemplateCardLoader key="loading_review_card" />}
                </StyledReviewTemplates>
            </InfiniteScroll>
            {!isLoading && !hasNextPage && !replyTemplates.length && !creatingTemplate && (
                <NotFoundTemplate
                    show
                    imgSrc={illustrationElement}
                    buttonStatus={OUTLINED as Type}
                    title="no_template_found"
                    subtitle="update_filters_for_more_templates"
                    buttonText="see_all_templates"
                    handleClick={resetFilters}
                />
            )}
        </div>
    );
}
