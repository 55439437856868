import { useEffect, useMemo } from 'react';

import { uniqWith } from 'lodash-es';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { V2BusinessData } from 'app/api/types/business';
import { FeedbackResultData } from 'app/api/types/feedbackResults';
import { CursorPaginationParams, ReviewData, ReviewObjectType } from 'app/api/types/review';
import API from 'app/api/v2/api_calls';
import { REVIEW_LIST } from 'app/common/data/queryKeysConstants';
import { UNGROUPED_ID } from 'app/common/reducers/groups';
import {
    searchBusinessesFailure,
    searchBusinessesSuccess,
} from 'app/common/reducers/newBusinesses';
import byIdFormatter from 'app/common/services/byIdFormatter';
import { useActiveQueries } from 'app/reviewManagement/reviewList/hooks/useActiveQueries';
import { useFeedbackResultQueryParams } from 'app/reviewManagement/reviewList/hooks/useFeedbackResultQueryParams';
import { useGetReviewsFilters } from 'app/reviewManagement/reviewList/hooks/useGetReviewsFilters';
import {
    REVIEW_LIST_PAGE_SIZE,
    useGetReviewsQueryParams,
} from 'app/reviewManagement/reviewList/hooks/useGetReviewsQueryParams';
import { ReviewCountByType, ReviewObject } from 'app/states/reviews';
import { formatReviewApiStateToInternalState } from 'app/states/reviews/services/reviewFormatter';

const fetchReviewBusinesses = async (
    data: MergedCursorPaginatedResult<ReviewObject>,
    reviews: Array<{ businessId: string }>,
) => {
    const businessIds = new Set(reviews.map(({ businessId }) => businessId));

    if (businessIds.size === 0) {
        return Promise.resolve({
            ...data,
            businesses: [],
        });
    }

    const businessData = await API.business.searchBusinesses({
        business__in: Array.from(businessIds).toString(),
        partoo_ui: true,
    });

    return {
        ...data,
        businesses: businessData.businesses,
    };
};

type CursorPaginatedResult<T> = {
    items: Array<T>;
    count?: number;
    next_cursor?: string;
};

type MergedCursorPaginatedResult<T> = {
    items: Array<T>;
    count?: number;
    countByType: ReviewCountByType;
    cursors: Record<ReviewObjectType, CursorPaginationParams>;
};

export type ReviewListQueryData = {
    businesses: Array<V2BusinessData>;
    items: Array<ReviewObject>;
    count?: number;
    countByType: ReviewCountByType;
    cursors: Record<ReviewObjectType, CursorPaginationParams>;
};

export const useReviewListQueryKey = () => {
    const searchReviewsParams = useGetReviewsQueryParams();
    const searchFeedbackResultsParams = useFeedbackResultQueryParams();
    const { reviewObjectType } = useGetReviewsFilters();

    return [REVIEW_LIST, searchReviewsParams, searchFeedbackResultsParams, reviewObjectType];
};

export const useGetReviews = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const searchReviewsParams = useGetReviewsQueryParams();
    const searchFeedbackResultsParams = useFeedbackResultQueryParams();
    const queryKey = useReviewListQueryKey();

    const defaultPageParams = {
        [ReviewObjectType.REVIEW]: { cursor: 1 },
        [ReviewObjectType.FEEDBACK_RESULT]: { cursor: 1 },
    };
    const { activeQueries, enabled } = useActiveQueries();

    // Needed for the cache to not be garbage collected
    const cacheKey = [queryKey, '_CACHE'];
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useQuery(cacheKey, () => {});

    const { fetchNextPage, hasNextPage, data, isFetching } = useInfiniteQuery<ReviewListQueryData>(
        queryKey,
        async ({ pageParam = defaultPageParams }) => {
            if (pageParam === defaultPageParams) {
                queryClient.setQueryData(cacheKey, []);
            }

            const promiseConfiguration = [
                {
                    type: ReviewObjectType.REVIEW,
                    get: async () => {
                        const v = await API.review.searchReviews({
                            ...searchReviewsParams,
                            ...pageParam[ReviewObjectType.REVIEW],
                        });

                        return { ...v, items: v.reviews };
                    },
                },
                {
                    type: ReviewObjectType.FEEDBACK_RESULT,
                    get: () => {
                        return API.feedbackResults.searchFeedbackResults({
                            ...searchFeedbackResultsParams,
                            ...pageParam[ReviewObjectType.FEEDBACK_RESULT],
                        });
                    },
                },
            ];

            const promises = promiseConfiguration.reduce(
                (promises, config) => {
                    if (activeQueries[config.type] && pageParam.hasOwnProperty(config.type)) {
                        if (
                            pageParam[config.type].next_cursor &&
                            pageParam[config.type].per_page <= 0
                        ) {
                            // We have more than REVIEW_LIST_PAGE_SIZE items in the cache
                            // so we do not perform an api call by still need to pass on
                            // the next_cursor so that we can resume querying
                            // when we have less items in the cache
                            promises.push(
                                Promise.resolve({
                                    items: [], // actual items will be retrieved from the cache
                                    next_cursor: pageParam[config.type].next_cursor,
                                }),
                            );
                        } else {
                            // We perform an api call
                            promises.push(
                                config.get().catch(() => {
                                    // Catching errors in individual api request
                                    // so that we display at least the objects
                                    // from requests that succeeded
                                    return { items: [] };
                                }),
                            );
                        }
                    } else {
                        // Query is not active or exhausted (ie we have fetched all the data)
                        promises.push(Promise.resolve({ items: [] }));
                    }

                    return promises;
                },
                [] as Array<Promise<CursorPaginatedResult<ReviewData | FeedbackResultData>>>,
            );

            const values = await Promise.all(promises);
            const countByType = values.reduce((countByType, promiseValue, idx) => {
                countByType[promiseConfiguration[idx].type] = promiseValue.count;
                return countByType;
            }, {} as ReviewCountByType);
            const count = Object.values(countByType).reduce((count: number, c) => {
                return count + (c ?? 0);
            }, 0) as number;

            const _cache = queryClient.getQueryData<ReviewObject[]>([queryKey, '_CACHE']) ?? [];
            const remainingItems = uniqWith(
                // FIXME: Sometimes on first render, queries will get executed twice and we end up with duplicate reviews...
                values
                    .reduce(
                        (items, d) => {
                            items.push(
                                ...(d.items?.map(formatReviewApiStateToInternalState) ?? []),
                            );
                            return items;
                        },
                        [..._cache] as Array<ReviewObject>,
                    )
                    .sort((a, b) => b.updateDate - a.updateDate),
                (a, b) => a.id === b.id && a.reviewObjectType === b.reviewObjectType,
            );

            const items = remainingItems.splice(0, REVIEW_LIST_PAGE_SIZE);

            // Caching remaining items
            queryClient.setQueryData(cacheKey, remainingItems);

            const cursors = [ReviewObjectType.REVIEW, ReviewObjectType.FEEDBACK_RESULT].reduce(
                (cursors, objectType, idx) => {
                    const remaining = remainingItems.filter(i => i.reviewObjectType == objectType);

                    if (values[idx].next_cursor) {
                        cursors[objectType] = {
                            next_cursor: values[idx].next_cursor,

                            // We are going to handle the case per_page <= 0
                            // when creating the promises
                            per_page: REVIEW_LIST_PAGE_SIZE - remaining.length,
                        };
                    }

                    return cursors;
                },
                {} as Record<ReviewObjectType, CursorPaginationParams>,
            );

            return fetchReviewBusinesses({ items, count, countByType, cursors }, items);
        },
        {
            getNextPageParam: lastPage => {
                const _cache = queryClient.getQueryData<ReviewObject[]>(cacheKey) ?? [];
                // There are no next page to load when:
                // - no next cursor for both reviews and feebacks
                // - AND cache is empty
                if (Object.keys(lastPage.cursors).length == 0 && !_cache.length) {
                    return undefined;
                }

                return lastPage.cursors;
            },

            enabled,
        },
    );

    const { reviews, formattedBusinesses } = useMemo(() => {
        return {
            reviews: data?.pages.map(d => d.items).flat() ?? [],
            formattedBusinesses: byIdFormatter(
                data?.pages.map(({ businesses }) => businesses).flat() ?? [],
            ),
        };
    }, [data]);

    useEffect(() => {
        if (!formattedBusinesses) return;
        try {
            const { byId, ids } = formattedBusinesses;
            Object.keys(byId).forEach(key => {
                byId[key].groupId = byId[key].groupId === null ? UNGROUPED_ID : byId[key].groupId;
            });
            dispatch(searchBusinessesSuccess(byId, ids, 0));
        } catch (error) {
            dispatch(searchBusinessesFailure(error));
        }
    }, [formattedBusinesses]);

    return {
        count: data?.pages[0].countByType,
        reviews,
        hasNextPage,
        fetchNextPage,
        isLoading: isFetching,
    };
};
