import { Button, Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import WarningImage from 'app/common/designSystem/components/atoms/svg/modalImages/warning';

import { Buttons, Description, ModalContent, Title } from './UnsavedModal.styled';

type Props = {
    show: boolean;
    cancel: () => void;
    validate: () => void;
};

const UnsavedModal = ({ show, cancel, validate }: Props) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={show} closeModal={cancel}>
            <ModalContent>
                <WarningImage />
                <Title>{t('unsaved_changes_modal_title')}</Title>
                <Description>{t('unsaved_changes_modal_description')}</Description>
                <Buttons>
                    <Button
                        dataTrackId="edit_business__unsaved_changes_modal_cancel"
                        onClick={cancel}
                        appearance="outlined"
                        variant="secondary"
                        full
                        shape="cube"
                        size="large"
                    >
                        {t('unsaved_changes_modal_stay')}
                    </Button>
                    <Button
                        dataTrackId="edit_business__unsaved_changes_modal_validate"
                        onClick={validate}
                        variant="danger"
                        full
                        shape="cube"
                        size="large"
                    >
                        {t('unsaved_changes_modal_leave')}
                    </Button>
                </Buttons>
            </ModalContent>
        </Modal>
    );
};

export default UnsavedModal;
