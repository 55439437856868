import styled from '@emotion/styled';

import { HEX_COLORS } from '@partoohub/branding';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

export const OverrideDatePicker = styled(DatePicker)`
    width: 90px;
    border: none;
    background-color: ${HEX_COLORS.white};
    cursor: pointer;
`;

export const MonthYearPickerContainer = styled.div`
    display: flex;
    position: relative;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    height: 46px;
    padding: 0 16px;
    background-color: ${HEX_COLORS.white};
    border: 1px solid #dddfe3;
    border-radius: 4px;
    width: 290px;

    .react-datepicker {
        background-color: ${HEX_COLORS.white};
        box-shadow: 0px 5px 10px rgba(20, 37, 66, 0.16);
        border-radius: 12px;
        border: none;
    }
    .react-datepicker__header {
        margin-top: 8px;
        background-color: ${HEX_COLORS.white};
        border: none;
    }
    .react-datepicker__month-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
    .react-datepicker__month-container {
        width: 255px;
    }
    .react-datepicker__month-text {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.colors.default.initial};
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        font-size: 12px;
        height: 24px;
        width: 70px;
        background-color: rgba(215, 219, 223, 0.12);
        border-radius: 8px;
        margin-bottom: 8px;
        transition: all 0.2s;
        &:active {
            background-color: red;
        }
        &:hover {
            background-color: rgba(0, 133, 242, 0.12);
        }
    }
    .react-datepicker__month-text--disabled {
        background-color: rgba(215, 219, 223, 0.4);
        color: rgba(215, 219, 223, 0.8);
        cursor: not-allowed;
    }
    .react-datepicker-ignore-onclickoutside {
        border: none;
    }
    .DateInput_input::placeholder {
        color: #00b0ea;
    }
    // Delete triangle of react-datepicker
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
        border: none;
    }
    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous {
        position: absolute;
        color: ${({ theme }) => theme.colors.default.initial};
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
    }
    .react-datepicker__navigation--next {
        top: 19px;
        right: 15px;
        border-bottom: 5px solid ${({ theme }) => theme.colors.default.initial};
    }
    .react-datepicker__navigation--previous {
        top: 26px;
        left: 230px;
        border-top: 5px solid ${({ theme }) => theme.colors.default.initial};
    }
    .react-datepicker__navigation-icon--next,
    .react-datepicker__navigation-icon--previous {
        display: none;
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        margin-right: 32px;
    }
    *:focus {
        outline: none;
    }
`;

export const Separator = styled.div`
    width: 1px;
    height: 30px;
    background-color: #dddfe3;
`;

export const TextBetweenMonthPicker = styled.div`
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
`;

export const MonthYearPickerFrom = styled.div`
    position: relative;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.default.initial};
    .react-datepicker {
        position: absolute;
        top: 16px;
        left: -51px;
    }
`;

export const MonthYearPickerTo = styled.div`
    position: relative;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.default.initial};
    .react-datepicker {
        position: absolute;
        top: 16px;
        left: -162px;
    }
`;

export const ButtonContainer = styled.div`
    text-align: right;
    margin: 16px;
`;

export const Text = styled.div`
    position: absolute;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    top: 16px;
    left: 16px;
`;

export const IconMonthPicker = styled.i`
    margin-right: 6px;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;
export const IconReset = styled.i`
    color: ${({ theme }) => theme.colors.primary.initial};
    cursor: pointer;
`;

export const IconResetContainer = styled.div`
    position: absolute;
    right: 8px;
`;
