import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReviewTagStat } from 'app/api/types/review';
import IS_IFRAME from 'app/common/data/iframe';
import {
    TagStatCard,
    TagStatCardLabel,
    TagStatCardRating,
    TagStatCardTitle,
} from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/TagStatSection/TagStatCard.styled';
import RatingNumber from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/ReviewCardRating/RatingNumber';
import { CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH } from 'app/routing/routeIds';
import { businessParams } from 'app/utils/queryString';

type Props = {
    tagStat: ReviewTagStat;
};

export default function ({ tagStat }: Props) {
    const { t } = useTranslation();
    const urlSearchParams = useMemo(
        () =>
            new URLSearchParams({
                status: 'live',
                tag: String(tagStat.interaction_tag.id),
                platforms: 'google',
            }).toString(),
        [tagStat],
    );

    return (
        <TagStatCard>
            <TagStatCardTitle>
                <span>{tagStat.interaction_tag.label}</span>
                {!IS_IFRAME && (
                    <Link
                        data-track="review_analytics__view_reviews_button"
                        data-intercom-target="review_analytics__view_reviews_button"
                        className={'color--secondary'}
                        to={{
                            pathname: CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH,
                            search: `?${urlSearchParams}&${businessParams()}`,
                        }}
                        target="_blank"
                    >
                        <i className="test fa fa-arrow-up-right-from-square"></i>
                    </Link>
                )}
            </TagStatCardTitle>
            <TagStatCardLabel>
                {tagStat.review_count}
                &nbsp;
                {t('header_counter_reviews', { count: tagStat.review_count })}
                <br />
                <RatingNumber rating={tagStat.review_avg_rating} partner={'google_my_business'} />
            </TagStatCardLabel>
            <TagStatCardRating rating={tagStat.review_avg_rating}>
                {tagStat.review_avg_rating.toFixed(1)}
            </TagStatCardRating>
        </TagStatCard>
    );
}
