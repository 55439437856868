import styled from '@emotion/styled';

export const TagStatSectionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    padding: 10px 10px;
    gap: 10px;

    &.tag-stat-section-no-tag {
        img {
            position: absolute;
            top: 25px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
`;

export const TagStatElement = styled.div`
    text-align: center;
    flex: 1;
`;
