import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { ReviewObject } from 'app/api/types/review';
import { REVIEW_LIST } from 'app/common/data/queryKeysConstants';
import { NEW_REVIEW, REVIEW_DOWNLOAD_FAILED, REVIEW_UPDATED } from 'app/common/data/websocket';
import useWebsocket from 'app/common/hooks/useWebsocket';

import {
    clearAllRealtimeReviewsAction,
    realtimeUpdateReviewReceivedAction,
} from 'app/states/realtimeUpdates';

import { realtimeUpdatesSelector } from 'app/states/realtimeUpdates/selectors';

// Listen to websocket and fill the store with updated or new reviews received from live fetch
export const useReviewListWebsocket = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onUpdateReviewReceived = (review: ReviewObject) => {
        dispatch(realtimeUpdateReviewReceivedAction({ data: review, type: REVIEW_UPDATED }));
    };

    const onNewReviewReceived = (review: ReviewObject) => {
        dispatch(realtimeUpdateReviewReceivedAction({ data: review, type: NEW_REVIEW }));
    };

    const onReviewDownloadFailed = (message: string) => {
        toast.error(t(message), t('error'));
    };

    useWebsocket(REVIEW_UPDATED, onUpdateReviewReceived);
    useWebsocket(NEW_REVIEW, onNewReviewReceived);
    useWebsocket(REVIEW_DOWNLOAD_FAILED, onReviewDownloadFailed);
};

// Controls if the refresh button on RM page should be shown or not
// It is shown when the store contains updated or new reviews
export const useRefreshReviewList = () => {
    const realtimeUpdates = useSelector(realtimeUpdatesSelector);
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return {
        showRefreshAction: !!realtimeUpdates.reviews.length,
        refreshReviewList: () => {
            queryClient.invalidateQueries(REVIEW_LIST);
            dispatch(clearAllRealtimeReviewsAction());
        },
    };
};
