import { MessagingBodyContainer } from 'app/reviewManagement/messaging/Messaging.styled';

import { MessagingContent } from './MessagingContent';

export const MessagingBody = () => {
    return (
        <MessagingBodyContainer>
            <MessagingContent />
        </MessagingBodyContainer>
    );
};
