import styled from '@emotion/styled';

export const StyledAdvice = styled.div`
    display: flex;
    align-items: center;
    padding: 18px 32px 18px 16px;
    margin: 16px 0;
    background-color: ${({ theme }) => theme.colors.ratings.alpha};
    border-radius: 8px;
`;

export const StyledIcon = styled.img`
    height: 40px;
    margin-right: 16px;
`;
