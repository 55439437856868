import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

type PostCardMainContainerType = {
    isExpired: boolean;
};

export const PostCardMainContainer = styled.div`
    grid-area: description;
    padding: 16px 16px 12px 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid ${greyColorObject.initial};
    border-left: 1px solid ${greyColorObject.initial};
    width: 100%;
    ${(props: PostCardMainContainerType) =>
        props.isExpired &&
        css`
            opacity: 0.33;
        `}
`;

export const PostCardMainBusinessName = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: ${HEX_COLORS.blackberry};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
`;

export const PostCardMainBusinessIcon = styled.i`
    margin: 0 3px 0 0;
`;

export const PostCardMainDescription = styled.p`
    font-size: 14px;
    display: -webkit-box; /* WebKit */
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 8px;
    width: 100%;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;
