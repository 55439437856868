import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba } from '@partoohub/branding';

export const BusinessEditV2ContentDescriptionContainer = styled.div`
    margin-bottom: 24px;
    padding: 34px 24px 24px;
    border-radius: 8px;
    box-shadow: 0 2px 10px ${convertToRgba(HEX_COLORS.black, 0.15)};
`;

export const Title = styled.div`
    margin-bottom: 24px;
    color: ${HEX_COLORS.blackberry};
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
`;
