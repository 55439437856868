import styled from '@emotion/styled';

export const BusinessFiltersContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const BusinessFiltersLeftContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
`;
