import { ScriptableContext } from 'chart.js';

import { useComparisonDateFilter } from './useComparisonDateFilter';
import { useCompetitiveOverview } from './useCompetitiveOverview';
import { useCompetitorColors } from './useCompetitorColors';
import { DEFAULT_COLOR } from './useCompetitorColors';
import { useOverviewSelection } from './useOverviewSelection';

export const useOverviewChartData = (language: Intl.LocalesArgument) => {
    const colors = useCompetitorColors();
    const { overview } = useCompetitiveOverview();
    const [overviewSelection] = useOverviewSelection();
    const [date, , monthDelta] = useComparisonDateFilter();

    const month = date.getMonth();
    const year = date.getFullYear();
    const currIndex = overview.dates.findIndex(
        d => d.getMonth() === month && d.getFullYear() === year,
    );
    const prevIndex = currIndex - monthDelta;

    return {
        labels: overview.dates.map(date => date.toLocaleString(language, { month: 'short' })),
        datasets: overview.businesses.map(business => ({
            label: business.name,
            data: business.data.map(d => (d ? d[overviewSelection] : d)),
            borderColor: colors[business.name] ?? DEFAULT_COLOR,
            backgroundColor: colors[business.name] ?? DEFAULT_COLOR,
            pointStyle: (context: ScriptableContext<'line'>) => {
                const isSingleDataPoint = business.data.filter(d => d !== null).length === 1;
                const isComparedIndex =
                    context.dataIndex === currIndex || context.dataIndex == prevIndex;
                if (isSingleDataPoint || isComparedIndex) return 'circle';
                return false;
            },
            pointRadius: 5,
        })),
    };
};
