import { useState } from 'react';

export default function usePostCreationModalNavigationState() {
    const [allSteps, setAllSteps] = useState([]);
    const [step, setStep] = useState(0);

    const setPreviousStep = () => {
        setStep(step - 1);
    };

    const setNextStep = () => {
        setStep(step + 1);
    };

    const resetStep = () => {
        setStep(0);
    };

    return {
        allSteps,
        setAllSteps,
        step,
        setStep,
        setPreviousStep,
        setNextStep,
        resetStep,
    };
}
