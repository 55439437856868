import useLocalBusinessIdCompetitiveBenchmark from 'app/common/components/businessModal/hooks/birdOnly/useLocalBusinessIdCompetitiveBenchmark';
import LoadingView from 'app/common/components/LoadingView';
import { CompetitiveBenchmarkContainer } from 'app/pages/analytics/CompetitiveBenchmark/CompetitiveBenchmark.styled';
import { BenchmarkContainer } from 'app/pages/analytics/CompetitiveBenchmark/CompetitiveBenchmark.styled';
import { FiltersSection } from 'app/pages/analytics/CompetitiveBenchmark/components';

import { GlobalCompetitiveBenchmark } from './components/content/GlobalCompetitiveBenchmark';
import { LocalCompetitiveBenchmark } from './components/content/LocalCompetitiveBenchmark';
import { NotFoundPlaceholder } from './components/NotFound/NotFoundDataPlaceholder';
import { useFiltersAreLoading } from './hooks/useFiltersAreLoading';
import { useKeywordFilter } from './hooks/useKeywordFilter';

export const CompetitiveBenchmark = () => {
    const isLoadingFilters = useFiltersAreLoading();
    const localBusinessId = useLocalBusinessIdCompetitiveBenchmark();
    const [keyword] = useKeywordFilter();

    if (isLoadingFilters) {
        return <LoadingView />;
    }

    if (!keyword.keyword) {
        return (
            <NotFoundPlaceholder
                title={'competitive_benchmark_no_results_title'}
                subtitle={'competitive_benchmark_no_keywords_text'}
            />
        );
    }

    if (keyword.dates.length === 0) {
        return (
            <NotFoundPlaceholder
                title={'competitive_benchmark_no_results_title'}
                subtitle={'competitive_benchmark_no_results_text'}
            />
        );
    }

    const ContentComponent = localBusinessId
        ? LocalCompetitiveBenchmark
        : GlobalCompetitiveBenchmark;

    return (
        <CompetitiveBenchmarkContainer>
            <FiltersSection />
            <BenchmarkContainer>
                <ContentComponent />
            </BenchmarkContainer>
        </CompetitiveBenchmarkContainer>
    );
};
