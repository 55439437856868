import { intlFormat } from 'date-fns';

import { useLanguage } from 'app/common/services/getLang';

export const useReviewDateFormatter = () => {
    const lang = useLanguage();

    const dateFormatter = (
        date: Date | number | string,
        options: {
            showHour?: boolean;
            showDay?: boolean;
        } = { showHour: true, showDay: true },
    ) => {
        const { showDay, showHour } = options;
        return intlFormat(
            date,
            {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: showDay ? 'numeric' : undefined,
                hour: showHour ? '2-digit' : undefined,
                minute: showHour ? '2-digit' : undefined,
            },
            { locale: lang },
        );
    };

    return dateFormatter;
};
