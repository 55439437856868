import * as dataType from '../dataTypes';

const deleteSecondLayerCommentById = (
    byReviewId: Record<string, Array<dataType.FirstLevelComment>>,
    reviewId: string,
    commentId: string,
    parentId: string,
): Record<string, Array<dataType.FirstLevelComment>> => ({
    ...byReviewId,
    [reviewId]: byReviewId[reviewId].map(fComment =>
        fComment.id !== parentId
            ? fComment
            : {
                  ...fComment,
                  replies: fComment.replies.filter(
                      ({ id }) => id !== commentId,
                  ),
              },
    ),
});

const updateSecondLayerCommentById = (
    byReviewId: Record<string, Array<dataType.FirstLevelComment>>,
    reviewId: string,
    commentId: string,
    parentId: string,
    comment: dataType.FirstLevelComment | dataType.SecondLevelComment,
): Record<string, Array<dataType.FirstLevelComment>> => ({
    ...byReviewId,
    [reviewId]: byReviewId[reviewId].map(fComment =>
        fComment.id !== parentId
            ? fComment
            : {
                  ...fComment,
                  replies: fComment.replies.map(sComment =>
                      sComment.id === commentId ? comment : sComment,
                  ),
              },
    ),
});

const createSecondLayerCommentById = (
    byReviewId: Record<string, Array<dataType.FirstLevelComment>>,
    reviewId: string,
    parentId: string,
    comment: dataType.FirstLevelComment | dataType.SecondLevelComment,
): Record<string, Array<dataType.FirstLevelComment>> => ({
    ...byReviewId,
    [reviewId]: byReviewId[reviewId].map(fComment =>
        fComment.id !== parentId
            ? fComment
            : { ...fComment, replies: [...fComment.replies, comment] },
    ),
});

const deleteFirstLayerCommentById = (
    byReviewId: Record<string, Array<dataType.FirstLevelComment>>,
    reviewId: string,
    commentId: string,
): Record<string, Array<dataType.FirstLevelComment>> => ({
    ...byReviewId,
    [reviewId]: byReviewId[reviewId].filter(({ id }) => id !== commentId),
});

const updateFirstLayerCommentById = (
    byReviewId: Record<string, Array<dataType.FirstLevelComment>>,
    reviewId: string,
    commentId: string,
    comment: dataType.FirstLevelComment | dataType.SecondLevelComment,
    // @ts-ignore
): Record<string, Array<dataType.FirstLevelComment>> => ({
    ...byReviewId,
    [reviewId]: byReviewId[reviewId].map(fComment =>
        fComment.id === commentId ? comment : fComment,
    ),
});

const createFirstLayerCommentById = (
    byReviewId: Record<string, Array<dataType.FirstLevelComment>>,
    reviewId: string,
    comment: dataType.FirstLevelComment | dataType.SecondLevelComment,
    // @ts-ignore
): Record<string, Array<dataType.FirstLevelComment>> => ({
    ...byReviewId,
    [reviewId]: [...(byReviewId[reviewId] || []), comment],
});

const deleteCommentById = (
    byReviewId: Record<string, Array<dataType.FirstLevelComment>>,
    reviewId: string,
    commentId: string,
    parentId?: string,
): Record<string, Array<dataType.FirstLevelComment>> =>
    parentId
        ? deleteSecondLayerCommentById(
            byReviewId,
            reviewId,
            commentId,
            parentId,
        )
        : deleteFirstLayerCommentById(byReviewId, reviewId, commentId);

const updateCommentById = (
    byReviewId: Record<string, Array<dataType.FirstLevelComment>>,
    reviewId: string,
    commentId: string,
    comment: dataType.FirstLevelComment | dataType.SecondLevelComment,
    parentId?: string,
): Record<string, Array<dataType.FirstLevelComment>> =>
    parentId
        ? updateSecondLayerCommentById(
            byReviewId,
            reviewId,
            commentId,
            parentId,
            comment,
        )
        : updateFirstLayerCommentById(byReviewId, reviewId, commentId, comment);

const createCommentById = (
    byReviewId: Record<string, Array<dataType.FirstLevelComment>>,
    reviewId: string,
    comment: dataType.FirstLevelComment | dataType.SecondLevelComment,
    parentId?: string,
): Record<string, Array<dataType.FirstLevelComment>> =>
    parentId
        ? createSecondLayerCommentById(byReviewId, reviewId, parentId, comment)
        : createFirstLayerCommentById(byReviewId, reviewId, comment);

export const byReviewIdUpdater = (
    byReviewId: Record<string, Array<dataType.FirstLevelComment>>,
    reviewId: string,
    commentId?: string,
    parentId?: string,
    comment?: dataType.FirstLevelComment | dataType.SecondLevelComment,
): Record<string, Array<dataType.FirstLevelComment>> => {
    if (comment) {
        // @ts-ignore
        if (commentId || commentId === 0) {
            return updateCommentById(
                byReviewId,
                reviewId,
                commentId,
                comment,
                parentId,
            );
        }

        return createCommentById(byReviewId, reviewId, comment, parentId);
    }

    // @ts-ignore
    if (commentId || commentId === 0) {
        return deleteCommentById(byReviewId, reviewId, commentId, parentId);
    }

    return byReviewId;
};
export default byReviewIdUpdater;
