import { ColorVariants } from '@partoohub/branding';
import { Chip, Icon, List, Text, Tooltip } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { WhatsAppPhoneNumber } from 'app/api/types/whatsapp';

type WhatsAppBusinessAccountPhoneNumberRowProps = {
    phoneNumber: WhatsAppPhoneNumber;
};

const WhatsappAccountPhoneNumberRow = ({
    phoneNumber,
}: WhatsAppBusinessAccountPhoneNumberRowProps) => {
    const { t } = useTranslation();
    let status = 'whatsapp_account_list_row__status_blocked';
    let statusColor: ColorVariants = 'danger';
    let tooltip = 'whatsapp_account_list_row__status_blocked_tooltip';
    switch (phoneNumber.status) {
        case 'CONNECTED':
            status = 'whatsapp_account_list_row__status_available';
            statusColor = 'success';
            tooltip = '';
            break;
        case 'PENDING':
            status = 'whatsapp_account_list_row__status_limited';
            if (
                phoneNumber.verification_code_sent_at &&
                phoneNumber.code_verification_status &&
                phoneNumber.code_verification_status === 'NOT_VERIFIED'
            ) {
                const codeSentDatetime = new Date(phoneNumber.verification_code_sent_at);
                const currentDatetime = new Date();
                status =
                    Math.round(
                        (currentDatetime.valueOf() - codeSentDatetime.valueOf()) / 1000 / 60,
                    ) < 15
                        ? 'whatsapp_account_list_row__status_verification'
                        : status;
            }
            statusColor = 'warning';
            tooltip = '';
            break;
        case 'MIGRATED':
        case 'RATE_LIMITED':
        case 'FLAGGED':
            status = 'whatsapp_account_list_row__status_limited';
            statusColor = 'warning';
            tooltip = '';
            break;
    }
    return (
        <Link to={phoneNumber.id.toString()}>
            <List.Row
                key={phoneNumber.number}
                dataTrackId="whatsapp_account_phone_number_row"
                id={phoneNumber.number}
            >
                <List.Cell>
                    <Text variant="bodyMSemibold">{phoneNumber.display_name}</Text>
                </List.Cell>
                <List.Cell>
                    <Text variant="bodySRegular" color="secondary">
                        {phoneNumber.number}
                    </Text>
                </List.Cell>
                <List.Cell>
                    <Tooltip text={t(tooltip)} placement="bottom">
                        <Chip dataTrackId="" chipShape="cube" variant={statusColor}>
                            {t(status)}
                        </Chip>
                    </Tooltip>
                </List.Cell>
                <Icon icon={['fa-chevron-right']} color="secondary" />
            </List.Row>
        </Link>
    );
};

export default WhatsappAccountPhoneNumberRow;
