import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { BUSINESS } from 'app/common/data/queryKeysConstants';

export const useGetBusiness = business_id =>
    useQuery([BUSINESS, business_id], () => {
        if (business_id === '0') {
            return Promise.reject(
                'Delight conversation has no valid business.',
            );
        }
        return api.business.getBusiness(business_id);
    });
