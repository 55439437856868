import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    BusinessListQueryKeys,
    CompletionRateArrayValues,
} from 'app/pages/visibility/BusinessListV2/utils/enums';

export const useGetCompletionFilter = () => {
    const [completion] = useStateQueryParams(BusinessListQueryKeys.COMPLETION_RATE);
    const hasCompletion =
        completion && !CompletionRateArrayValues.every(s => completion?.includes(s));

    return {
        ...(hasCompletion ? { completion_rate: completion } : {}),
    };
};
