import { RadialBar, Stack, Text } from '@partoohub/ui';

import { StyledBar, StyledProgress, StyledProgressBars } from './CardsSection.styled';
import { StyledCard } from './CardsSection.styled';

export const CardsSection = () => {
    return (
        <Stack direction="row">
            <StyledCard>
                <StyledProgressBars>
                    <StyledBar direction="row">
                        <Text variant="bodySRegular">600</Text>
                        <StyledProgress value={60} />
                        <Text variant="bodySRegular">60%</Text>
                    </StyledBar>
                    <StyledBar direction="row">
                        <Text variant="bodySRegular">150</Text>
                        <StyledProgress value={15} />
                        <Text variant="bodySRegular">15%</Text>
                    </StyledBar>
                    <StyledBar direction="row">
                        <Text variant="bodySRegular">100</Text>

                        <StyledProgress value={10} />
                        <Text variant="bodySRegular">10%</Text>
                    </StyledBar>
                    <StyledBar direction="row">
                        <Text variant="bodySRegular">50</Text>

                        <StyledProgress value={5} />
                        <Text variant="bodySRegular">5%</Text>
                    </StyledBar>
                    <StyledBar direction="row">
                        <Text variant="bodySRegular">100</Text>

                        <StyledProgress value={10} />
                        <Text variant="bodySRegular">10%</Text>
                    </StyledBar>
                </StyledProgressBars>
            </StyledCard>
            <StyledCard>
                <RadialBar value={66} />
            </StyledCard>
            <StyledCard>Card 3</StyledCard>
        </Stack>
    );
};
