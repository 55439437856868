import { createContext } from 'react';

import { mandatoryFunctionType } from 'app/common/utils/typing';

export type FunnelScreen = {
    id: string;
    component: JSX.Element;
    hideCloseButton?: boolean;
    backButtonAction?: Function;
    additionButtonsActions?: JSX.Element;
    progressBar?: number;
    displayUnsavedAlert?: boolean;
};

export class FunnelObject {
    constructor(
        private screens: FunnelScreen[],
        public store: any = {},
        public currentScreenIndex: number = 0,
        public setScreenIndex: Function = mandatoryFunctionType,
        public setShow: Function = mandatoryFunctionType,
    ) {}

    currentScreen() {
        return this.screens[this.currentScreenIndex];
    }

    next() {
        if (this.currentScreenIndex < this.screens.length - 1) {
            this.currentScreenIndex += 1;
            this.setScreenIndex(this.currentScreenIndex);
        }
    }

    previous() {
        if (this.currentScreenIndex > 0) {
            this.currentScreenIndex -= 1;
            this.setScreenIndex(this.currentScreenIndex);
        }
    }

    navigate(screenID: string) {
        const screenIdx = this.screens.findIndex(screen => screen.id === screenID);

        if (screenIdx !== -1) {
            this.currentScreenIndex = screenIdx;
            this.setScreenIndex(this.currentScreenIndex);
        }
    }

    push(screen: FunnelScreen) {
        if (this.currentScreenIndex < this.screens.length - 1) {
            this.screens.splice(this.currentScreenIndex + 1, 0, screen);
        } else {
            this.screens.push(screen);
        }
    }

    pop() {
        if (this.currentScreenIndex < this.screens.length - 1) {
            this.screens.splice(this.currentScreenIndex + 1, 1);
        }
    }

    done(cb?: Function) {
        if (cb) cb();
        this.setShow(false);
    }

    abort(cb?: Function) {
        if (cb) cb();
        this.setShow(false);
    }

    showCloseButton() {
        return !this.currentScreen().hideCloseButton;
    }

    showAdditionButtonsActions() {
        return this.currentScreen().additionButtonsActions;
    }

    backButtonAction() {
        this.currentScreen().backButtonAction?.(this);
    }

    getProgressBar() {
        return this.currentScreen().progressBar || 0;
    }
}

const FunnelContext = createContext(new FunnelObject([]));

export default FunnelContext;
