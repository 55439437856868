import { FC } from 'react';

import { ProductIcon, ProductIconsAppEnum } from '@partoohub/iconography';

import { useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import getLang from 'app/common/services/getLang';
import { useIsMobile } from 'app/common/services/screenDimensions';

import {
    ProductButtonContainer,
    ProductButtonHover,
    ProductButtonTooltip,
} from './ProductSidebar/ProductButton.styled';

export const HelpCenterButton: FC = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const content = (
        <ProductButtonContainer
            href={`https://help.partoo.co/${getLang()}`}
            target="_blank"
            data-cy="product-sidebar-product-button"
            data-track="sidemenu_settings__help_center"
            data-intercom-target="sidemenu_settings__help_center"
        >
            <ProductButtonHover>
                <ProductIcon name={ProductIconsAppEnum.HelpCenter} color="white" />
            </ProductButtonHover>
        </ProductButtonContainer>
    );

    if (!isMobile) {
        return (
            <TooltipWrapper tooltip={ProductButtonTooltip} text={t('help_center')} position="right">
                {content}
            </TooltipWrapper>
        );
    }

    return content;
};
