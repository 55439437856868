import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls/camel';

export const POST_BULK_COUNT = 'POST_BULK_COUNT';

export default function (bulkId) {
    const { data: bulkCount = 0, ...rest } = useQuery(
        [POST_BULK_COUNT, bulkId],
        () => {
            return api.post.countPostBulk(bulkId);
        },
        {
            enabled: !!bulkId,
        },
    );

    return { bulkCount, ...rest };
}
