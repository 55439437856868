import React from 'react';

import { Modal } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    CustomFieldsCreationKeys,
    CustomFieldsQueryKeys,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

import CatalogModal from './CatalogModal';

const CustomFieldsCatalogModal = () => {
    const [createCustomField, setCreateCustomField] = useStateQueryParams(
        CustomFieldsQueryKeys.CREATE,
    );

    const closeModal = () => setCreateCustomField('');

    return (
        <Modal
            isOpen={createCustomField === CustomFieldsCreationKeys.CATALOG}
            closeModal={closeModal}
        >
            <CatalogModal />
        </Modal>
    );
};

export default CustomFieldsCatalogModal;
