import styled from '@emotion/styled';

export const OpenHoursDescription = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OpenHoursDescriptionText = styled.div`
    padding-bottom: 2px;
`;

export const OpenHoursPrechecks = styled.div`
    color: ${({ theme }) => theme.colors.warning.initial};
    font-weight: bold;
`;
