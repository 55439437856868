import { FontAwesomeIconsPartooUsed, IconButton } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { V2UserData } from 'app/api/types/user';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import AuthorizationService from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/authorization';
import {
    UserListQueryKeys,
    UserStatus,
} from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

import { ActionsItemContainer } from './ActionsItem.styled';

type Props = {
    user: V2UserData;
};

const ActionsItem = ({ user }: Props) => {
    const { data: me } = useMe();
    const { t } = useTranslation();
    const enableNewGroups = useNewGroups();

    const authorizationService = new AuthorizationService(me);

    const [, setUserDelete] = useStateQueryParams(UserListQueryKeys.USER_DELETE);
    const [, setUserInvite] = useStateQueryParams(UserListQueryKeys.USER_INVITE);
    const [, setUserEdit] = useStateQueryParams(UserListQueryKeys.USER_EDIT, true);

    return (
        <ActionsItemContainer>
            {user.status === UserStatus.INVITED && (
                <IconButton
                    dataTrackId="delete_user__icon_button"
                    tooltip={t('re_invite_user')}
                    caret={false}
                    icon={['fa-sync-alt']}
                    appearance="outlined"
                    size="small"
                    onClick={() => setUserInvite(user.id)}
                />
            )}

            {authorizationService.canUpdateUser(user) && (
                <IconButton
                    dataTrackId="delete_user__icon_button"
                    tooltip={t('edit')}
                    caret={false}
                    icon={['fa-pen-clip']}
                    appearance="outlined"
                    size="small"
                    onClick={() => setUserEdit(user.id)}
                />
            )}

            {authorizationService.canDeleteUser(user, enableNewGroups) && (
                <IconButton
                    dataTrackId="delete_user__icon_button"
                    tooltip={t('delete')}
                    caret={false}
                    icon={[FontAwesomeIconsPartooUsed.faTrash]}
                    appearance="outlined"
                    variant="danger"
                    size="small"
                    onClick={() => setUserDelete(user.id)}
                />
            )}
        </ActionsItemContainer>
    );
};

export default ActionsItem;
