import { useCallback, useState } from 'react';

import { useQueryClient } from 'react-query';

import { MESSAGING_INFOS } from 'app/common/data/queryKeysConstants';
import {
    INSTAGRAM_ONBOARDING_REPORT,
    MESSENGER_ONBOARDING_REPORT,
} from 'app/common/data/websocket';
import useWebsocket from 'app/common/hooks/useWebsocket';
import { OnboardingReportData } from 'app/reviewManagement/messaging/messenger/AddPartnersButtons';
import { Camel } from 'app/utils/types';

export const reportHasError = (report: Camel<OnboardingReportData> | null) =>
    report !== null && (report.errorCount === null || report.errorCount > 0);

export const reportHasSuccess = (report: Camel<OnboardingReportData> | null) =>
    report !== null && (report.successCount === null || report.successCount > 0);

type OnboardingReportChannel =
    | typeof MESSENGER_ONBOARDING_REPORT
    | typeof INSTAGRAM_ONBOARDING_REPORT;

const getUseOnboardingReport =
    (channel: OnboardingReportChannel, updateButtonState) =>
    (onReceiveReport?: () => void): [Camel<OnboardingReportData> | null, () => void] => {
        // Below we choose to have a local state : the modal (error or success) will
        // appear only if the user is on the right page while the event is received,
        // if the user leaves the page after the modal has shown up, the modal doesn't
        // re-appear when coming back.
        const [report, setReport] = useState<Camel<OnboardingReportData> | null>(null);

        const queryClient = useQueryClient();
        useWebsocket(
            channel,
            (data: Camel<OnboardingReportData>) => {
                setReport(data);
                onReceiveReport?.();
                updateButtonState?.(queryClient, data);
            },
            [],
            { camel: true },
        );

        const reset = useCallback(() => {
            setReport(null);
        }, [setReport]);

        return [report, reset];
    };

export const useMessengerOnboardingReport = getUseOnboardingReport(
    MESSENGER_ONBOARDING_REPORT,
    (queryClient, report) => {
        if (!reportHasError(report)) {
            queryClient.setQueryData(MESSAGING_INFOS, {
                show_add_messenger_button: false,
            });
        }
    },
);

// Not exactly the same button update logic. For IG, we want to remove it if we have at least one success
export const useInstagramOnboardingReport = getUseOnboardingReport(
    INSTAGRAM_ONBOARDING_REPORT,
    (queryClient, report) => {
        if (reportHasSuccess(report)) {
            queryClient.setQueryData(MESSAGING_INFOS, {
                show_add_instagram_button: false,
                show_instagram_onboarded_button: true,
            });
        }
    },
);
