import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { CONVERSATION_STARTERS } from 'app/common/data/queryKeysConstants';

export default function useUpdateConversationStarterBusinesses(conversationStarterId: string) {
    const queryClient = useQueryClient();
    return useMutation(
        (businessSearchFilter: BusinessModalFiltersType) => {
            return api.conversationStarters.updateConversationStarterBusinesses(
                conversationStarterId,
                businessSearchFilter,
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([CONVERSATION_STARTERS]);
            },
        },
    );
}
