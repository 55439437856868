import { useState } from 'react';

import { Stack } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { PasswordChecks } from 'app/common/components/PasswordChecks/PasswordChecks';
import { PasswordField } from 'app/common/designSystem/components/atoms/PasswordField/PasswordField';
import isPasswordValid, {
    passWordChecker,
    passwordErrorHandler,
} from 'app/common/services/passwordChecker';
import {
    LoginFormButton,
    LoginFormContainer,
    LoginFormMessage,
} from 'app/connection/components/LoginForm/LoginForms.styled';

import {
    completeInvitationRequest,
    globalErrorSelector,
    hasErrors,
    passwordChecksErrorSelector,
    passwordConfirmationSelector,
    passwordSelector,
    updateInvitationPassword,
    updateInvitationPasswordConfirmation,
} from 'app/states/connection/reducers/invitation';

import { AppState } from 'app/states/reducers';

const InvitationForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showPasswordChecker, setShowPasswordChecker] = useState<boolean>(false);
    const invitation = useSelector((state: AppState) => state.invitation);

    const password = passwordSelector(invitation);
    const passwordConfirmation = passwordConfirmationSelector(invitation);
    const disabled = !(password === passwordConfirmation && isPasswordValid(password));

    const errorPassword = hasErrors(invitation);
    const pwdNotEquals = passwordConfirmation.length > 5 && passwordConfirmation !== password;
    const errorConfirmValue = pwdNotEquals || errorPassword;
    const error = globalErrorSelector(invitation) || '';
    const passwordError = passwordChecksErrorSelector(invitation)
        ? passwordErrorHandler(passwordChecksErrorSelector(invitation))
        : '';
    const noticeMessage = t(
        pwdNotEquals ? 'password and its confirmation are not equal' : error || passwordError,
    );

    const variant = disabled ? 'secondary' : 'primary';

    const handleChangePassword = (value: string) => dispatch(updateInvitationPassword(value));

    const handleChangeConfirmPassword = (value: string) => {
        dispatch(updateInvitationPasswordConfirmation(value));
    };

    return (
        <LoginFormContainer>
            <LoginFormMessage>
                <i className="fa-solid fa-info-circle" />
                {t('invitation__explanation')}
            </LoginFormMessage>
            <Stack>
                <Stack gap={showPasswordChecker ? '16px' : '0px'}>
                    <PasswordField
                        dataTrackId="password"
                        label={t('password')}
                        value={password}
                        onChange={handleChangePassword}
                        onFocus={() => setShowPasswordChecker(true)}
                        onBlur={() => setShowPasswordChecker(false)}
                        error={errorPassword}
                    />
                    <PasswordChecks
                        displayChecker={showPasswordChecker}
                        {...passWordChecker(password ?? '')}
                    />
                </Stack>
                <PasswordField
                    dataTrackId="confirm_password"
                    label={t('confirm_password')}
                    value={passwordConfirmation}
                    onChange={handleChangeConfirmPassword}
                    error={errorConfirmValue}
                    notice={noticeMessage}
                />
                <LoginFormButton
                    variant={variant}
                    shape="cube"
                    size="large"
                    full
                    onClick={() => dispatch(completeInvitationRequest())}
                    dataTrackId="sso_sign_in_button"
                    type="submit"
                    disabled={variant === 'secondary'}
                >
                    {t('create_an_account')}
                </LoginFormButton>
            </Stack>
        </LoginFormContainer>
    );
};

export default InvitationForm;
