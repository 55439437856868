import { useMemo } from 'react';

import { IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import LoadingSpinner from 'app/common/components/loaders/LoadingSpinner';
import { PBox } from 'app/common/components/PBox/components/PBox';
import PairedButtons from 'app/common/designSystem/components/molecules/PairedButtons';
import allowedSeasonalityOptions from 'app/common/services/allowedSeasonalityOptions';

import { useIsTablet } from 'app/hooks/useIsTablet';
import { Select } from 'app/pages/analytics/components/Select/Select';
import { DoughnutOverlayBigStyled } from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/AverageGrade/DoughnutOverlay.styled';
import QualitativeChart from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/ReviewsEvolution/QualitativeChart';
import QuantitativeChart from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/ReviewsEvolution/QuantitativeChart';
import {
    MobileFooterStyled,
    ReviewsEvolutionStyled,
} from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/ReviewsEvolution/ReviewsEvolution.styled';
import SelectButton from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/ReviewsEvolution/SelectButton.container';
import { updateBucketAction } from 'app/states/reviewAnalytics';

type Props = {
    dates: Array<string>;
    added: Array<number>;
    cumSum: Array<number>;
    previousCumSum: Array<number>;
    average: Array<number>;
    isPeriod: boolean;
    isQualitative: boolean;
    isLoading: boolean;
    hasReviews: boolean;
    selectedBucket: string;
    updateIsQualitative: (value: boolean) => void;
    reviewsPeriodTotalRequest: (value: boolean) => void;
};

const ReviewsEvolution = ({
    dates,
    added,
    cumSum,
    average,
    isPeriod,
    isQualitative,
    previousCumSum,
    isLoading,
    selectedBucket,
    hasReviews,
    updateIsQualitative,
    reviewsPeriodTotalRequest,
}: Props) => {
    const { t } = useTranslation();
    const isTablet = useIsTablet();

    const dispatch = useDispatch();
    const momentDates = useMemo(
        () => ({
            from: dates[0] || new Date(),
            to: dates[dates.length - 1] || new Date(),
        }),
        [dates],
    );
    const options = allowedSeasonalityOptions(momentDates);
    const handleClick = (value: string) => dispatch(updateBucketAction(value));
    return (
        <PBox
            title={t(isQualitative ? 'average_evolution' : 'review_evolution')}
            icon={['fa-chart-bar', IconPrefix.SOLID]}
            actions={[
                // @ts-ignore
                <PairedButtons
                    selectedButton={isQualitative ? t('average_grade') : t('review_management')}
                    options={[
                        {
                            label: t('review_management'),
                            value: false,
                        },
                        {
                            label: t('average_grade'),
                            value: true,
                        },
                    ]}
                    onClick={updateIsQualitative}
                    key="average_grade"
                />,
                // @ts-ignore
                <PairedButtons
                    selectedButton={isPeriod ? t('period_reviews') : t('in_total')}
                    options={[
                        {
                            label: t('period_reviews'),
                            value: true,
                        },
                        {
                            label: t('in_total'),
                            value: false,
                        },
                    ]}
                    onClick={reviewsPeriodTotalRequest}
                    key="period_reviews"
                />,
                !isTablet && (
                    <Select
                        label={`${t('display_by')} : `}
                        handleClick={handleClick}
                        options={options}
                        selectedValue={selectedBucket}
                        key="period_span"
                    />
                ),
            ]}
            content={
                <ReviewsEvolutionStyled>
                    <div>
                        {!isQualitative ? (
                            <QuantitativeChart
                                dates={dates}
                                added={added}
                                cumSum={previousCumSum}
                                isPeriod={isPeriod}
                                hasReviews={hasReviews}
                                seasonalityChoice={selectedBucket}
                            />
                        ) : (
                            <QualitativeChart
                                dates={dates}
                                cumAverage={average}
                                // @ts-ignore
                                added={added}
                                // @ts-ignore
                                cumSum={cumSum}
                                isPeriod={isPeriod}
                                hasReviews={hasReviews}
                                seasonalityChoice={selectedBucket}
                            />
                        )}
                    </div>
                    {isLoading && (
                        <DoughnutOverlayBigStyled>
                            <LoadingSpinner fontSize="1em" />
                        </DoughnutOverlayBigStyled>
                    )}
                    {isTablet && (
                        <MobileFooterStyled>
                            <SelectButton
                                key="bucket_mobile_ra"
                                // @ts-ignore
                                selectedBucket={selectedBucket}
                            />
                        </MobileFooterStyled>
                    )}
                </ReviewsEvolutionStyled>
            }
        />
    );
};

export default ReviewsEvolution;
