import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';

export const AddOpenHoursTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: ${HEX_COLORS.blackberry};
`;

export const OpenHoursPreviewContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: left;
`;

export const OpenHoursContent = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > i {
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
`;

type NoHoursPreviewProps = {
    sectionColor: ColorState;
};

export const OpenHoursPreviewContainerNoOpenHours = styled.div<NoHoursPreviewProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    border-radius: 4px;

    color: ${({ theme }) => theme.colors.primary.initial};
    font-size: 22px;
    transition: all ease-in-out 0.3s;

    background-color: ${greyColorObject.light};
    border: dashed 1px ${({ theme }) => theme.colors.secondary.initial};

    &:hover {
        border-color: ${HEX_COLORS.blackberry};
        cursor: pointer;
    }

    ${({ sectionColor, theme }) =>
        sectionColor &&
        [ColorState.PRECHECK_ERRORS, ColorState.MISSING_WARNING].includes(sectionColor) &&
        css`
            background-color: rgba(215, 219, 223, 0.12);
            border-color: ${theme.colors.warning.initial};

            &:hover {
                border-color: ${theme.colors.warning.initial};
            }

            svg {
                * {
                    fill: ${theme.colors.warning.initial};
                }
            }
        `};

    ${({ sectionColor, theme }) =>
        [ColorState.DISABLED, ColorState.CLOSED].includes(sectionColor) &&
        css`
            background-color: rgba(215, 219, 223, 0.12);
            border-color: ${theme.colors.secondary.alpha};

            * {
                color: ${theme.colors.secondary.alpha} !important;
            }

            &:hover {
                border-color: ${theme.colors.secondary.alpha};
            }

            svg {
                * {
                    fill: ${theme.colors.secondary.alpha};
                }
            }
        `};
`;
