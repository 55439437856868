/**
 *
 * @param publishers list of google, facebook
 * @returns publishers facebook, google_my_business
 */
export const transformPlatformNametoApiName = (platforms: string[]): string[] =>
    platforms.map(pl => {
        if (pl === 'google') return 'google_my_business';
        return pl;
    });
