import styled from '@emotion/styled';
import { TextInput } from '@partoohub/ui';

export const StyledFiltersBar = styled.div`
    display: flex;
    margin: 16px 0;
    gap: 12px;
`;

export const StyledTextInput = styled(TextInput)`
    flex-grow: 1;
`;
