import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';

import { ColorState } from './types';

export default function useContactSectionColor(): ColorState {
    // Hooks
    const { data: publisherErrors } = usePublisherErrors({});

    const emailPermission = useFieldMetadata('contact', 'email')?.enabled;
    const phonePermission = useFieldMetadata('contact', 'phone_number')?.enabled;
    const faxPermission = useFieldMetadata('contact', 'phone_number')?.enabled;

    const { data: business } = useBusiness();
    const isClosed = useIsBusinessClosed();

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // Disabled
    if (!emailPermission && !phonePermission && !faxPermission) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (
        publisherErrors?.contact?.email ||
        publisherErrors?.contact?.phone_number ||
        publisherErrors?.contact?.fax
    ) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields, ignore missing fax and missing email
    if (!business?.contacts?.[0]?.phone_numbers?.[0]) {
        return ColorState.MISSING_WARNING;
    }

    // OK
    return ColorState.OK;
}
