import { BUSINESS_MANAGER, GROUP_MANAGER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMe';
import useGroupIds from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroupIds';
import useGroups from 'app/pages/visibility/BusinessListV2/hooks/groups/useGroups';

import { hideableComponentsNames, withHideableComponent } from 'app/SDKBridge';

import AddBusinessButton from './Buttons/AddBusinessButton';
import AddMenuButton from './Buttons/AddMenuButton';
import AddSubGroupButton from './Buttons/AddSubgroupButton';
import { ManageGroupButton } from './Buttons/ManageGroupButton';

const OldHeaderButton = () => {
    const { data: me } = useMe();
    const { groups } = useGroups();

    const [currentGroupId, currentSubgroupId] = useGroupIds();

    if (me?.role === BUSINESS_MANAGER) return null;

    // Subgroup manager
    if (me?.role === GROUP_MANAGER && groups?.length === 1 && !!groups?.[0]?.parentId)
        return <AddBusinessButton />;

    // All or ungrouped
    if (['all', 'ungrouped', ''].includes(currentGroupId)) return <AddMenuButton />;

    // If subgroup
    if (currentSubgroupId) {
        // If ungrouped
        if (currentGroupId === currentSubgroupId) return <AddBusinessButton />;
        else return <ManageGroupButton />;
    }

    // If group
    if (currentGroupId) {
        if (me?.role === GROUP_MANAGER) {
            return <AddMenuButton />;
        } else {
            return (
                <>
                    <AddSubGroupButton />
                    <ManageGroupButton />
                </>
            );
        }
    }

    return null;
};

export default withHideableComponent(OldHeaderButton, hideableComponentsNames.KNOWLEDGE_ADD_BUTTON);
