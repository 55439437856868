import { Chart, ChartType } from '@partoohub/ui';

export const RatingDistributionGraph = () => {
    return (
        <Chart
            dataTrackId=""
            type={ChartType.Bar}
            data={{
                datasets: [
                    {
                        label: '1 étoile',
                        data: [100, 356, 678, 546, 1000, 123, 1509],
                        backgroundColor: 'rgba(255, 66, 110, 0.8)',
                    },
                    {
                        label: '2 étoiles',
                        data: [1034, 34, 678, 246, 560, 562, 1202],
                        backgroundColor: 'rgba(255, 66, 110, 0.5)',
                    },
                    {
                        label: '3 étoiles',
                        data: [232, 656, 1223, 246, 560, 562, 194],
                        backgroundColor: 'rgba(247, 194, 0, 0.8)',
                    },
                    {
                        label: '4 étoiles',
                        data: [42, 656, 91, 123, 9283, 562, 1202],
                        backgroundColor: 'rgba(44, 184, 132, 0.5)',
                    },
                    {
                        label: '5 étoiles',
                        data: [409, 656, 112, 246, 1340, 562, 230],
                        backgroundColor: 'rgba(44, 184, 132, 0.8)',
                    },
                ],
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            }}
        />
    );
};
