import { PublisherStateStats } from 'app/api/types/publisher_state';
import { defaultPublisherStateStats } from 'app/presence/management/constants/defaultPublisherStateStats';
import { usePublishersStates } from 'app/presence/management/hooks/usePublishersStates';

export const usePublisherStatesByName = (name: string) => {
    const { data, ...rest } = usePublishersStates();
    const publisherStates: PublisherStateStats =
        data?.publisher_state_stats?.[name] ?? defaultPublisherStateStats;

    return { publisherStates, ...rest };
};
