import styled from '@emotion/styled';

import { Icon, Text } from '@partoohub/ui';

export const StyledSearchbar = styled.div`
    width: 100%;
`;

export const StyledSearchIcon = styled(Icon)`
    padding: 12px;
`;

export const StyledCount = styled(Text)`
    margin-top: 16px;
`;
