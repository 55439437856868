import isEqual from 'lodash/isEqual';

import { SpecificHourFormData } from 'app/api/types/business';

export const mergeSpecificHours = (
    obj: Record<string, any>,
): SpecificHourFormData => {
    const specificHoursOpen = obj?.open ?? [];
    const specificHoursClose = obj?.close ?? [];

    // Temporary until we clean the data for closed specific hours
    const open = specificHoursOpen.map(specificHour => {
        return {
            ...specificHour,
            open: true,
        };
    });

    const closed = specificHoursClose.map(specificHour => {
        return {
            ...specificHour,
            open_hours: [],
            open: false,
        };
    });

    return open.concat(closed);
};

export const dateInRange = (
    startDate: string,
    endDate: string,
    date: string,
): boolean => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const current = new Date(date);

    return start <= current && current <= end;
};

export const isOpenAllDay = (open_hours: Array<string>): boolean =>
    isEqual(open_hours, ['00:00-23:59']) ||
    isEqual(open_hours, ['00:00-00:00']);
