import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import useIsServicesAvailable from 'app/businessEditV2/hooks/services/useIsServicesAvailable';
import {
    BUSINESS,
    GET_SERVICES,
    SERVICES_SUGGESTIONS,
} from 'app/common/data/queryKeysConstants';

export default function useServicesSuggestions() {
    const businessId = useGetBusinessId();
    const { data } = useIsServicesAvailable();

    return useQuery(
        [BUSINESS, GET_SERVICES, SERVICES_SUGGESTIONS, { businessId }],
        () => api.services.getServicesSuggestions({ business_id: businessId }),
        {
            enabled: !!(businessId && data),
            retry: false,
            retryOnMount: false,
        },
    );
}
