// GENERAL
export type RatingRange = [1 | 2 | 3 | 4 | 5, 1 | 2 | 3 | 4 | 5];

export type NavValue = 'live' | 'treated' | 'not-treated' | 'deleted';

export type AllFilters = 'date' | 'tag' | 'all';

export const [GOOGLE, FACEBOOK, TRIPADVISOR, FOURSQUARE] = [
    'google_my_business',
    'facebook',
    'tripadvisor',
    'foursquare',
];

export const [WITH_MESSAGE, WITHOUT_MESSAGE] = ['with_message', 'without_message'];

export const [RECOMMENDED, NOT_RECOMMENDED] = ['recommended', 'notRecommended'];

export const keywordLimit = 12;

export const LIVE_REVIEWS = 'live';

export const TREATED_REVIEWS = 'treated';

export const NOT_TREATED_REVIEWS = 'not-treated';

export const API_NOT_TREATED_REVIEWS = 'not_treated';

export const TREATED_AUTO_REPLY_REVIEWS = 'treated_auto_reply';

export const PENDING_AUTO_REPLY_REVIEWS = 'pending_auto_reply';

export const DELETED_REVIEWS = 'deleted';

export const REVIEW_STATUSES = [
    LIVE_REVIEWS,
    TREATED_REVIEWS,
    NOT_TREATED_REVIEWS,
    DELETED_REVIEWS,
];

// ACTIONS CONSTANTS

export const REMOVE_TAG_FILTER = 'REMOVE_TAG_FILTER';

export const SET_DATE_FILTER = 'SET_DATE_FILTER';

export const SET_INPUT_DATE_FILTER = 'SET_INPUT_DATE_FILTER';

export const APPLY_FILTERS = 'APPLY_FILTERS';

export const RESET_PENDING_FILTER = 'RESET_PENDING_FILTER';

export const RESET_REVIEW_FILTER = 'RESET_REVIEW_FILTER';

export const RESET_REVIEW_TOOLBAR_FILTERS = 'RESET_REVIEW_TOOLBAR_FILTERS';

export const RE_INIT_REVIEW_FILTERS = 'RE_INIT_REVIEW_FILTERS';

// MOBILE
export const DISPAY_REVIEW_MOBILE_FILTERS = 'DISPAY_REVIEW_MOBILE_FILTERS';

export const HIDE_REVIEW_MOBILE_FILTERS = 'HIDE_REVIEW_MOBILE_FILTERS';
