import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { ATTRIBUTES, BUSINESS } from 'app/common/data/queryKeysConstants';

export default function useBusinessAttributes() {
    const businessId = useGetBusinessId();
    const { data: business } = useBusiness();
    return useQuery(
        [BUSINESS, ATTRIBUTES, { businessId }],
        () => api.business.businessAttributesGet(businessId, {}),
        {
            enabled: !!business?.categories?.[0],
            retry: false,
            retryOnMount: false,
        },
    );
}
