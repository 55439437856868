import { endOfMonth, endOfWeek, endOfYear, max, min } from 'date-fns';
import { format } from 'date-fns/format';

import { DateRange } from 'app/common/data/datetime';
import { StringOrDate } from 'app/pages/analytics/VisibilityAnalyticsV2/types/queryParams';
import { getUTCDate } from 'app/utils/dates';

const LABEL_MONTH_FORMAT = 'MMM yyyy';
const LABEL_DAY_FORMAT = 'd MMM';
const LABEL_WEEK_FORMAT = 'eee d MMM';
const LABEL_YEAR_FORMAT = 'yyyy';

export const formatXAxisLabels = (bucket: string, date: Date, fromDate: StringOrDate) => {
    let startDate = date;
    let dateFormat = LABEL_MONTH_FORMAT;

    if (bucket === 'day') {
        dateFormat = LABEL_DAY_FORMAT;
    } else if (bucket === 'week') {
        dateFormat = LABEL_WEEK_FORMAT;
        startDate = max([date, fromDate]);
    } else if (bucket === 'month') {
        dateFormat = LABEL_MONTH_FORMAT;
    } else if (bucket === 'year') {
        dateFormat = LABEL_YEAR_FORMAT;
    }

    const startDateUTC = getUTCDate(startDate);
    return format(startDateUTC, dateFormat);
};

const TOOLTIP_MONTH_FORMAT = 'd MMM yyyy';
export const formatTooltipTitle = (bucket: string, userDates: DateRange, date: Date) => {
    const startPeriod = getUTCDate(max([userDates?.from || new Date(), date]));
    let endBucket;
    let formatStart = TOOLTIP_MONTH_FORMAT;
    let formatEnd = TOOLTIP_MONTH_FORMAT;

    if (bucket === 'week') {
        endBucket = endOfWeek(startPeriod);
        formatStart = 'eee d MMM';
        formatEnd = 'eee d MMM';
    } else if (bucket === 'month') {
        endBucket = endOfMonth(startPeriod);
        formatStart = TOOLTIP_MONTH_FORMAT;
        formatEnd = TOOLTIP_MONTH_FORMAT;
    } else if (bucket === 'year') {
        endBucket = endOfYear(startPeriod);
        formatStart = TOOLTIP_MONTH_FORMAT;
        formatEnd = TOOLTIP_MONTH_FORMAT;
    } else if (bucket === 'day') {
        return format(date, 'eee, do MMM');
    }

    const endPeriod = min([userDates?.to || new Date(), endBucket]);
    const startPeriodFormat = format(startPeriod, formatStart);
    const endPeriodFormat = format(endPeriod, formatEnd);

    return `${startPeriodFormat} - ${endPeriodFormat}`;
};
