import { useEffect, useMemo, useState } from 'react';

import { Icon, IconPrefix, toast } from '@partoohub/ui';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PhotosData } from 'app/api/types/business';
import EditForm from 'app/businessEditV2/components/EditForm';
import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import usePhotosSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/usePhotosSectionColor';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useFetchGmbImages from 'app/businessEditV2/hooks/photos/useFetchGmbImages';
import useGmbPhotosUpdate, {
    IMPORT_IN_PROGRESS_TOAST_ID,
} from 'app/businessEditV2/hooks/photos/useGmbPhotosUpdate';
import useTimeOfLastPhotoUpdateInfo from 'app/businessEditV2/hooks/photos/useTimeOfLastPhotoUpdate';
import { Partner, useIsConnectedToPartner } from 'app/businessEditV2/hooks/useIsConnectedToPartner';
import getAllPhotos, { handledPhotosArray } from 'app/businessEditV2/utils/photos';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import TextButton from 'app/common/designSystem/components/atoms/TextButton';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

import { LoadingElement } from './PhotoForm.styled';
import PhotosTable from './PhotosTable';

type Props = {
    closeModal: () => void;
    useDirtyUpdate: (boolean) => void;
};

const PhotosForm = ({ closeModal, useDirtyUpdate }: Props) => {
    const { t } = useTranslation();
    const colorState = usePhotosSectionColor();
    const { data: business, isLoading } = useBusiness();

    const setFormValue = () => ({
        photos: business?.photos ?? ({} as PhotosData),
    });

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        watch,
        reset,
    } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    useDirtyUpdate(isDirty);

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading, business?.photos]);

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const businessUpdate = useBusinessUpdate(() => {
        businessUpdateCompletionRate.mutate();
        closeModal();
    });

    const onSubmit = payload => {
        businessUpdate.mutate(payload);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_PHOTOS_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
    };

    // Permission
    const businessIsOpen = !useIsBusinessClosed();
    const secondaryPermission =
        !!useFieldMetadata('photos', 'secondary')?.enabled && businessIsOpen;
    const logoPermission =
        !!useFieldMetadata('photos', 'logo')?.enabled && secondaryPermission && businessIsOpen;
    const coverPermission =
        !!useFieldMetadata('photos', 'cover')?.enabled && secondaryPermission && businessIsOpen;

    const formatPayload = payload => {
        const result = {
            ...(coverPermission && {
                primary: payload?.photos?.primary || '',
            }),
            ...(logoPermission && {
                LOGO: payload?.photos?.LOGO || '',
            }),
        };

        if (secondaryPermission) {
            handledPhotosArray.forEach(category => {
                result[category] = payload?.photos?.[category];
            });
        }

        return {
            photos: result,
        };
    };

    const photos = getAllPhotos(business?.photos ?? ({} as PhotosData)).map(photo => photo.url);

    const submitForm = () => {
        handleSubmit(payload => onSubmit(formatPayload(payload)))();
        trackSaveBusinessEditSection(
            SectionNames.PHOTOS,
            photos.length > 0 ? ColorState.OK : ColorState.MISSING_WARNING,
        );
    };

    const photosLength = getAllPhotos(watch('photos') as PhotosData).length;

    // GMB Photos Fetch (Import)
    const [fetchGmbLoading, setFetchGmbLoading] = useState<boolean>(false);
    useGmbPhotosUpdate(() => setFetchGmbLoading(false));
    const fetchGmbImages = useFetchGmbImages();
    const isConnectedToGoogle = useIsConnectedToPartner(Partner.Google);

    const timeOfLastPhotoUpdateInfo = useTimeOfLastPhotoUpdateInfo();

    return (
        <>
            <EditForm
                title={t('photos')}
                description={`${t('business_edit_photos_description')} (${photosLength})`}
                descriptionRight={
                    isConnectedToGoogle && (
                        <TextButton
                            text={t('import_from_gmb')}
                            icon={
                                fetchGmbLoading ? (
                                    <LoadingElement />
                                ) : (
                                    <Icon
                                        icon={['fa-download', IconPrefix.SOLID]}
                                        color="primary"
                                    />
                                )
                            }
                            iconPositionRight
                            color={fetchGmbLoading ? 'secondary' : 'primary'}
                            disabled={fetchGmbLoading}
                            onClick={() => {
                                fetchGmbImages.mutate();
                                toast.loading(null, t('business_edit_photo_import_in_progress'), {
                                    toastId: IMPORT_IN_PROGRESS_TOAST_ID,
                                });
                                setFetchGmbLoading(true);
                            }}
                        />
                    )
                }
                info={
                    !!photos.length &&
                    timeOfLastPhotoUpdateInfo && (
                        <>
                            <i className="fa-solid fa-upload" />
                            <div>{timeOfLastPhotoUpdateInfo}</div>
                        </>
                    )
                }
                colorState={colorState}
            >
                <Controller
                    name="photos"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <PhotosTable
                            value={value}
                            onChange={onChange}
                            secondaryPermission={secondaryPermission}
                            logoPermission={logoPermission}
                            coverPermission={coverPermission}
                        />
                    )}
                />
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={businessUpdate.isLoading}
                    hasError={!!businessUpdate.error}
                    onClick={submitForm}
                    dataTrackId="visibility_location__form_photos__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default PhotosForm;
