import { ReactNode } from 'react';

import { Switch } from '@partoohub/ui';

import {
    ExpandableListToggle,
    ExpandableToggleBoxContainer,
    ExpandableToggleBoxHeader,
    ExpandableToggleBoxTextBox,
    TitleContainer,
} from './ExpandableToggleBox.styled';

export type Props = {
    title?: ReactNode;
    subtitle?: ReactNode;
    nodes: Array<ReactNode>;
    onClick: () => void;
    // Optional props
    checked?: boolean;
    disabled?: boolean;
    className?: string;
};

const ExpandableToggleBox = ({
    title,
    subtitle,
    nodes,
    checked = true,
    disabled = false,
    onClick,
    className,
}: Props) => {
    return (
        <ExpandableToggleBoxContainer className={className}>
            {title && (
                <ExpandableToggleBoxHeader>
                    <ExpandableToggleBoxTextBox>
                        <TitleContainer>{title}</TitleContainer>
                        {subtitle}
                    </ExpandableToggleBoxTextBox>
                    <Switch
                        dataTrackId="expandable_toggle_box"
                        name="expandable_toggle_box"
                        onChange={onClick}
                        checked={checked}
                        disabled={disabled}
                    />
                </ExpandableToggleBoxHeader>
            )}
            <ExpandableListToggle checked={checked}>
                {nodes.map((node, idx) => (
                    <div key={`a_key_${idx}`}>{node}</div>
                ))}
            </ExpandableListToggle>
        </ExpandableToggleBoxContainer>
    );
};

export default ExpandableToggleBox;
