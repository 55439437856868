import { useCallback } from 'react';

import { HEX_COLORS } from '@partoohub/branding';

import { SidebarCounterText, SidebarCounterWrapper } from './SidebarCounter.styled';

interface SidebarCounterProps {
    count: number;
}

const SidebarCounter = ({ count }: SidebarCounterProps) => {
    /**
     * Use of useCallback to memoize helper between each rerender
     */
    const formatCount = useCallback((countToFormat: number) => {
        if (countToFormat > 99) {
            return '99+';
        }

        return countToFormat;
    }, []);

    return (
        <SidebarCounterWrapper>
            <SidebarCounterText variant="bodySBold" as="span" color={HEX_COLORS.white}>
                {formatCount(count)}
            </SidebarCounterText>
        </SidebarCounterWrapper>
    );
};

export default SidebarCounter;
