import { Button, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import { resetBusinessFilter } from 'app/common/components/businessModalDeprecated/reducers';

import {
    StyledBusinessSelectStep,
    StyledBusinessSelectStepButtons,
} from './BusinessSelectStep.styled';

type Props = {
    toggleBusinessModal: () => void;
};

const BusinessSelectStep = ({ toggleBusinessModal }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const businessCount = useSelectedBusinessesCount();

    return (
        <StyledBusinessSelectStep>
            <Text color={'secondary'}>
                {businessCount
                    ? t('selected_businesses_with_count', {
                          count: businessCount,
                      })
                    : t('auto_reply_form_step_business_default_all')}
            </Text>
            <StyledBusinessSelectStepButtons>
                {!!businessCount && (
                    <Button
                        dataTrackId="auto_reply_settings_business_step_reset"
                        variant="secondary"
                        appearance="outlined"
                        shape="cube"
                        onClick={() => dispatch(resetBusinessFilter())}
                    >
                        {t('auto_reply_form_step_business_reset')}
                    </Button>
                )}
                <Button
                    dataTrackId="auto_reply_settings_business_step_modal_open"
                    variant="primary"
                    appearance="outlined"
                    shape="cube"
                    onClick={toggleBusinessModal}
                >
                    {t('auto_reply_form_step_business_edit')}
                </Button>
            </StyledBusinessSelectStepButtons>
        </StyledBusinessSelectStep>
    );
};

export default BusinessSelectStep;
