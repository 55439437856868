import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix, TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import formatHours from 'app/businessEditV2/oldFromEditV1/services/formatHours';
import {
    EmptyButton,
    HoursContainer,
    OpenHourIconButtons,
    OpenHourStartEndContainer,
    TextInputWithError,
} from 'app/common/components/OpenHour/OpenHour.styled';

type Props = {
    disabled: boolean;
    startHour: string;
    endHour: string;
    isFirstOpenHour: boolean;
    updateTimeWindow: (start: string, end: string) => void;
    addTimeWindow: () => void;
    deleteTimeWindow: () => void;
    hoursError: string;
};

const HoursItem = ({
    disabled,
    startHour,
    endHour,
    isFirstOpenHour,
    updateTimeWindow,
    addTimeWindow,
    deleteTimeWindow,
    hoursError,
}: Props) => {
    const { t } = useTranslation();

    const onBlur = () => {
        updateTimeWindow(formatHours(startHour), formatHours(endHour));
    };

    const onEnter = (e: { key: string }) => {
        if (e.key === 'Enter') {
            onBlur();
        }
    };
    return (
        <HoursContainer>
            <OpenHourStartEndContainer>
                <TextInputWithError error={!!hoursError}>
                    <TextInput
                        dataTrackId="hours_opens"
                        disabled={disabled}
                        label={t('specific_hours__opens')}
                        placeholder="09:00"
                        value={startHour}
                        onChange={(value: string) => {
                            updateTimeWindow(value, endHour);
                        }}
                        onBlur={onBlur}
                        onKeyDown={onEnter}
                    />
                </TextInputWithError>
                <TextInputWithError error={!!hoursError}>
                    <TextInput
                        dataTrackId="hours_closes"
                        disabled={disabled}
                        label={t('specific_hours__closes')}
                        placeholder="19:00"
                        value={endHour}
                        onChange={(value: string) => {
                            updateTimeWindow(startHour, value);
                        }}
                        onBlur={onBlur}
                        onKeyDown={onEnter}
                    />
                </TextInputWithError>
            </OpenHourStartEndContainer>
            <OpenHourIconButtons>
                {isFirstOpenHour ? (
                    <IconButton
                        appearance="outlined"
                        variant="primary"
                        icon={[FontAwesomeIconsPartooUsed.faCirclePlus, IconPrefix.REGULAR]}
                        onClick={addTimeWindow}
                        disabled={disabled}
                        dataTrackId="add_time_slot_button"
                        tooltip={t('add_a_time_slot')}
                    />
                ) : (
                    <IconButton
                        appearance="outlined"
                        variant="danger"
                        icon={[FontAwesomeIconsPartooUsed.faCircleMinus, IconPrefix.REGULAR]}
                        onClick={deleteTimeWindow}
                        disabled={disabled}
                        dataTrackId="remove_time_slot_button"
                    />
                )}
                {/* Empty button for spacing */}
                <EmptyButton />
            </OpenHourIconButtons>
        </HoursContainer>
    );
};

export default HoursItem;
