import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    displayModalSelector,
    hideConfirm,
} from 'app/common/components/ConfirmModal/reducers/confirm';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { confirmModalSelector } from 'app/states/reducers';

export default function AlertTemplateHasAutoReplySettingsModal() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const hide = () => {
        dispatch(hideConfirm());
    };

    const showModal = displayModalSelector(
        useSelector(confirmModalSelector),
        'reply_template_has_auto_reply_settings_modal',
    );

    return (
        <ConfirmModal
            modalFor="error"
            show={showModal}
            title={t('reply_template_has_auto_reply_settings_modal_title')}
            content={t('reply_template_has_auto_reply_settings_modal_text')}
            confirmLabel={t('reply_template_has_auto_reply_settings_modal_confirm')}
            onConfirm={hide}
            onHide={hide}
            trackId={'reply_template_has_auto_reply_settings'}
        />
    );
}
