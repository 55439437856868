import { useInfiniteQuery } from 'react-query';

import Messaging from 'app/api/v2/api_calls/messagingApiCalls';
import { MESSAGING_MESSAGES } from 'app/common/data/queryKeysConstants';

export const useGetMessages = (conversationId: number) => {
    const queryKey = getMessagesKey(conversationId);

    const fetchMessages = ({ pageParam = null }) =>
        Messaging.getMessages(conversationId, pageParam, true);

    const { data, hasNextPage, isLoading, fetchNextPage } = useInfiniteQuery(
        queryKey,
        fetchMessages,
        {
            getNextPageParam: lastPage => lastPage.next_cursor || null,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );
    const messages = data?.pages.flatMap(data => data.messages) || [];

    return { messages, hasNextPage, isLoading, fetchNextPage };
};

export const getMessagesKey = (conversationId: number): Array<any> => [
    MESSAGING_MESSAGES,
    { conversationId },
];
