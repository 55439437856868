import React from 'react';

import { DecorationDown, DecorationUp } from './Image.styled';

type Props = {
    color: string;
};

const DecorationHoveringUp = React.forwardRef<SVGSVGElement, Props>(
    ({ color }, ref) => (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 312 143"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.76476 8.11563H15.1381V1.8083C15.1381 0.809205 15.9499 0 16.9585 0C17.9671 0 18.7789 0.809205 18.7789 1.8083V8.11935H25.1548C26.1633 8.11935 26.9827 8.92856 26.9814 9.92765C26.9827 10.4266 26.7785 10.8846 26.449 11.211C26.1195 11.5386 25.6634 11.7459 25.1598 11.7459H18.7789V18.0482C18.7789 18.5472 18.5797 19.0002 18.249 19.3266C17.9195 19.653 17.4647 19.8565 16.961 19.8565C15.9525 19.8565 15.1381 19.0473 15.1381 18.0482V11.7459H8.76476C7.75745 11.7459 6.94058 10.9292 6.94058 9.93014C6.94058 8.93228 7.75745 8.11563 8.76476 8.11563Z"
                fill={color}
            />
            <path
                d="M0.999821 52.5C0.999821 56.644 4.35196 60 8.48282 60C12.6137 60 15.9658 56.644 15.9658 52.5C15.9658 48.356 12.6137 45 8.48282 45C4.35196 45 0.999821 48.356 0.999821 52.5Z"
                stroke={color}
                strokeWidth="2"
            />
            <path
                d="M67 94C67 96.211 68.7883 98 70.99 98C73.1917 98 74.98 96.211 74.98 94C74.98 91.789 73.1917 90 70.99 90C68.7883 90 67 91.789 67 94Z"
                stroke={color}
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M303.235 8.11563H296.862V1.8083C296.862 0.809205 296.05 0 295.041 0C294.033 0 293.221 0.809205 293.221 1.8083V8.11935H286.845C285.837 8.11935 285.017 8.92856 285.019 9.92765C285.017 10.4266 285.222 10.8846 285.551 11.211C285.881 11.5386 286.337 11.7459 286.84 11.7459H293.221V18.0482C293.221 18.5472 293.42 19.0002 293.751 19.3266C294.081 19.653 294.535 19.8565 295.039 19.8565C296.048 19.8565 296.862 19.0473 296.862 18.0482V11.7459H303.235C304.243 11.7459 305.059 10.9292 305.059 9.93014C305.059 8.93228 304.243 8.11563 303.235 8.11563Z"
                fill={color}
            />
            <path
                d="M311 52.5C311 56.644 307.648 60 303.517 60C299.386 60 296.034 56.644 296.034 52.5C296.034 48.356 299.386 45 303.517 45C307.648 45 311 48.356 311 52.5Z"
                stroke={color}
                strokeWidth="2"
            />
            <path
                d="M237.522 32C237.522 34.211 235.734 36 233.532 36C231.33 36 229.542 34.211 229.542 32C229.542 29.789 231.33 28 233.532 28C235.734 28 237.522 29.789 237.522 32Z"
                stroke={color}
                strokeWidth="2"
            />
        </svg>
    ),
);

const DecorationHoveringDown = React.forwardRef<SVGSVGElement, Props>(
    ({ color }, ref) => (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 312 143"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M58.1824 16.6818L59.493 15.3823L58.1832 14.0835C57.9756 13.8786 57.9748 13.5465 58.1824 13.3408C58.39 13.1351 58.7251 13.1359 58.9319 13.3416L60.2442 14.6404L61.5556 13.3408C61.7632 13.1351 62.0992 13.1351 62.3068 13.3408C62.4102 13.4433 62.4644 13.5787 62.4644 13.7134C62.4644 13.8481 62.4127 13.9836 62.3093 14.086L60.997 15.3872L62.3059 16.6843C62.4093 16.7876 62.4627 16.9206 62.4627 17.0552C62.4627 17.1899 62.4102 17.3246 62.3068 17.427C62.0992 17.6327 61.764 17.6319 61.5564 17.4262L60.2475 16.1291L58.9361 17.4287C58.7285 17.6344 58.3908 17.6327 58.1841 17.427C57.9765 17.2213 57.9748 16.8867 58.1824 16.6818Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7093 102.495L19.0199 101.196L17.7101 99.897C17.5025 99.6921 17.5017 99.36 17.7093 99.1543C17.9168 98.9486 18.252 98.9494 18.4588 99.1551L19.771 100.454L21.0825 99.1543C21.2901 98.9486 21.626 98.9486 21.8336 99.1543C21.937 99.2567 21.9912 99.3922 21.9912 99.5269C21.9912 99.6616 21.9395 99.797 21.8361 99.8995L20.5239 101.201L21.8328 102.498C21.9362 102.601 21.9895 102.734 21.9895 102.869C21.9895 103.003 21.937 103.138 21.8336 103.24C21.626 103.446 21.2909 103.445 21.0833 103.24L19.7744 101.943L18.4629 103.242C18.2553 103.448 17.9177 103.446 17.7109 103.24C17.5033 103.035 17.5017 102.7 17.7093 102.495Z"
                fill={color}
            />
            <path
                d="M47.2344 55L47.2344 59.4815"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M47.2344 63.9258L47.2344 68.4073"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M40.5439 61.7031L45.0165 61.7031"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M49.0454 61.7031L53.5179 61.7031"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />

            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M253.818 16.6818L252.507 15.3823L253.817 14.0835C254.025 13.8786 254.026 13.5465 253.818 13.3408C253.61 13.1351 253.275 13.1359 253.069 13.3416L251.756 14.6404L250.445 13.3408C250.237 13.1351 249.901 13.1351 249.694 13.3408C249.59 13.4433 249.536 13.5787 249.536 13.7134C249.536 13.8481 249.588 13.9836 249.691 14.086L251.003 15.3872L249.695 16.6843C249.591 16.7876 249.538 16.9206 249.538 17.0552C249.538 17.1899 249.59 17.3246 249.694 17.427C249.901 17.6327 250.236 17.6319 250.444 17.4262L251.753 16.1291L253.064 17.4287C253.272 17.6344 253.61 17.6327 253.816 17.427C254.024 17.2213 254.026 16.8867 253.818 16.6818Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M294.291 102.495L292.98 101.196L294.29 99.897C294.498 99.6921 294.498 99.36 294.291 99.1543C294.083 98.9486 293.748 98.9494 293.541 99.1551L292.229 100.454L290.918 99.1543C290.71 98.9486 290.374 98.9486 290.166 99.1543C290.063 99.2567 290.009 99.3922 290.009 99.5269C290.009 99.6616 290.06 99.797 290.164 99.8995L291.476 101.201L290.167 102.498C290.064 102.601 290.01 102.734 290.01 102.869C290.01 103.003 290.063 103.138 290.166 103.24C290.374 103.446 290.709 103.445 290.917 103.24L292.226 101.943L293.537 103.242C293.745 103.448 294.082 103.446 294.289 103.24C294.497 103.035 294.498 102.7 294.291 102.495Z"
                fill={color}
            />
            <path
                d="M264.766 55L264.766 59.4815"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M264.766 63.9258L264.766 68.4073"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M271.456 61.7031L266.984 61.7031"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M262.955 61.7031L258.482 61.7031"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    ),
);

const Decoration: React.FC<Props> = ({ color }) => (
    <>
        <DecorationUp>
            <DecorationHoveringDown color={color} />
        </DecorationUp>
        <DecorationDown>
            <DecorationHoveringUp color={color} />
        </DecorationDown>
    </>
);

export default Decoration;
