import { useMemo } from 'react';

import useMetricsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useMetricsAnalytics';
import { useGetDimension } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/actions/useGetDimensions';
import { useKeywordsSearchQueryParams } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/useKeywordsSearchQueryParams';
import { normalizrActions } from 'app/pages/analytics/VisibilityAnalyticsV2/services/charts/normalizrActions';
import { MetricsAttributesResponse } from 'app/pages/analytics/VisibilityAnalyticsV2/types/metrics';
import { MetricsApiKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/types/query';

const METRICS_ACTIONS = [
    MetricsAttributesResponse.WEBSITE_CLICKS,
    MetricsAttributesResponse.CALL_CLICKS,
    MetricsAttributesResponse.BUSINESS_DIRECTION_REQUESTS,
];

export const useMetricsActionsChart = () => {
    const queryTmp = useKeywordsSearchQueryParams();
    const { dimensions } = useGetDimension();
    const momentDates = useMemo(
        () => ({
            from: queryTmp.filter_date__gte || new Date(),
            to: queryTmp.filter_date__lte || new Date(),
        }),
        [queryTmp.filter_date__gte, queryTmp.filter_date__lte],
    );
    const query = {
        ...queryTmp,
        [MetricsApiKeys.METRICS]: METRICS_ACTIONS.join(','),
        [MetricsApiKeys.DIMENSIONS]: dimensions,
        [MetricsApiKeys.ORDER_BY]: dimensions,
    };

    const { data, isLoading, error, refetch } = useMetricsAnalytics(query);

    const dataChart = useMemo(() => {
        if (!data || data?.metrics?.length <= 0) return null;
        return {
            ...normalizrActions(data?.metrics, momentDates),
        };
    }, [data]);

    return {
        isLoading,
        data: dataChart,
        error,
        refetch,
    };
};
