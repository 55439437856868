import { useContext, useEffect, useState } from 'react';

import QuickConnectContext from 'app/businessConnection/components/genericQuickConnect/QuickConnect.context';
import { DISABLED, TOSAVE } from 'app/common/components/buttons/StatefulButton';

export const useButtonStatus = initialState => {
    const { selectedSuggestions } = useContext(QuickConnectContext);

    const [buttonStatus, setButtonStatus] = useState(initialState);

    useEffect(() => {
        if (selectedSuggestions.length === 0) setButtonStatus(DISABLED);
        else if (buttonStatus !== TOSAVE) setButtonStatus(TOSAVE);
    }, [selectedSuggestions]);

    return [buttonStatus, setButtonStatus];
};
