import React, { useCallback, useState } from 'react';

import { Text, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useMutation, useQueryClient } from 'react-query';

import {
    GoogleVerificationCompleteData,
    GoogleVerificationCompleteReceivedData,
    V2FormattedLocationData,
} from 'app/api/types/account';
import CamelApi from 'app/api/v2/api_calls/camel';
import LocationCard from 'app/businessConnection/components/common/LocationCard/LocationCard';

import Clickable from 'app/common/components/buttons/Clickable';
import {
    BUSINESS_LOCATIONS,
    GOOGLE_VERIFICATION_OPTIONS,
} from 'app/common/data/queryKeysConstants';

import { ConfirmButton } from '../Fields/ConfirmButton.styled';
import { FloatingFooter, FooterChangeMethodWrapper } from '../Fields/FloatingFooter.styled';
import { Message } from '../Fields/Message';
import { SideTabContainer } from '../Fields/SideTabContainer.styled';
import { SubTitle, Title } from '../Fields/Title.styled';
import { useGoogleVerificationContext } from '../GoogleVerification.context';

type Props = {
    location: V2FormattedLocationData;
};

const getMethodValue = (option: any): string | undefined => {
    const methodToValueDict: { [key: string]: string | undefined } = {
        SMS: option.phone_number,
        PHONE_CALL: option.phone_number,
        EMAIL: option.email,
        ADDRESS: option?.full_name + ' ' + option?.full_address,
    };

    return methodToValueDict[option.method] || option.method;
};

const Complete = ({ location }: Props) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { store, setStore } = useGoogleVerificationContext();
    const [hasError, setHasError] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);
    const [verificationCode, setVerificationCode] = useState<string | undefined>('');

    const { mutate: completeVerification, isLoading: isCompleteVerificationLoading } = useMutation(
        (data: GoogleVerificationCompleteData) =>
            CamelApi.account.completeGoogleVerification(location.id, data),
        {
            onSuccess: (response: GoogleVerificationCompleteReceivedData) => {
                if (response.status === 'success') {
                    let nextScreen = '';
                    if (response.message === 'complete_in_review') {
                        nextScreen = 'in_review';
                    } else {
                        nextScreen = 'verified';
                    }

                    setVerificationCode('');
                    setStore((prev: any) => ({
                        ...prev,
                        locations: {
                            ...(prev.locations || []),
                            [location.id]: { screen: nextScreen },
                        },
                    }));
                    queryClient.invalidateQueries(BUSINESS_LOCATIONS);
                    queryClient.resetQueries([GOOGLE_VERIFICATION_OPTIONS, location.id]);
                } else {
                    if (response.message === 'complete_invalid_pin') setInvalidPin(true);
                    else queryClient.invalidateQueries(BUSINESS_LOCATIONS);
                    setHasError(true);
                }
            },
            onError: () =>
                setStore((prev: any) => ({
                    ...prev,
                    locations: {
                        ...(prev.locations || []),
                        [location.id]: { screen: 'error' },
                    },
                })),
        },
    );

    const optionSelected = store.locations[location.id].optionSelected;

    const handleCompleteClick = useCallback(() => {
        completeVerification({
            pin_code: verificationCode,
            method: optionSelected.method,
        });
    }, [completeVerification, verificationCode, optionSelected]);

    const handleChangeVerification = () =>
        setStore((prev: any) => ({
            ...prev,
            locations: {
                ...(prev.locations || []),
                [location.id]: { screen: 'force_method_list' },
            },
        }));

    const handleVerificationCodeChange = useCallback((verificationCode?: string) => {
        setVerificationCode(verificationCode || '');
        setInvalidPin(false);
        setHasError(false);
    }, []);

    return (
        <SideTabContainer>
            <Title>{t('google_verification__complete__title')}</Title>
            <SubTitle>
                {t('google_verification__complete__subtitle', {
                    value: getMethodValue(optionSelected),
                })}
            </SubTitle>
            <TextInput
                dataTrackId="google_verification__complete__verification_code"
                placeholder={t('google_verification__complete__verification_code_placeholder')}
                value={verificationCode}
                onChange={handleVerificationCodeChange}
                disabled={isCompleteVerificationLoading}
                error={invalidPin}
                notice={invalidPin ? t('google_verification__incorrect_pin') : ''}
            />
            {!optionSelected.isPending && (
                <Message
                    description={t('google_verification__complete__info_text', {
                        value: optionSelected?.expectedDeliveryDays
                            ? `${optionSelected?.expectedDeliveryDays} ${t('days')}`
                            : t('google_verification__complete__expected_delivery_times'),
                    })}
                />
            )}
            <LocationCard partnerName="google" location={location} />

            <FloatingFooter>
                <ConfirmButton
                    dataTrackId="google_verification__methods__complete_confirm_button"
                    icon={
                        hasError
                            ? ['fa-warning']
                            : isCompleteVerificationLoading
                              ? ['fa-spinner fa-spin']
                              : undefined
                    }
                    variant={hasError ? 'danger' : 'primary'}
                    disabled={!verificationCode || isCompleteVerificationLoading || hasError}
                    size="large"
                    shape="cube"
                    onClick={handleCompleteClick}
                >
                    {hasError ? t('error') : t('confirm')}
                </ConfirmButton>
                <FooterChangeMethodWrapper>
                    <Text variant="bodyMRegular">
                        {t('google_verification__methods__change_label')}
                    </Text>
                    <Clickable onClick={handleChangeVerification}>
                        <Text variant="bodyMBold" color="#0085F2">
                            {t('google_verification__methods__change_button')}
                        </Text>
                    </Clickable>
                </FooterChangeMethodWrapper>
            </FloatingFooter>
        </SideTabContainer>
    );
};

export default Complete;
