import { MetricsAttributesResponse } from 'app/pages/analytics/VisibilityAnalyticsV2/types/metrics';

const METRICS_DEFAULT_VALUE = `${MetricsAttributesResponse.WEBSITE_CLICKS},${MetricsAttributesResponse.CALL_CLICKS},${MetricsAttributesResponse.BUSINESS_DIRECTION_REQUESTS},${MetricsAttributesResponse.BUSINESS_IMPRESSIONS_DESKTOP_MAPS},${MetricsAttributesResponse.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH},${MetricsAttributesResponse.BUSINESS_IMPRESSIONS_MOBILE_MAPS},${MetricsAttributesResponse.BUSINESS_IMPRESSIONS_MOBILE_SEARCH}`;

export const useGetMetrics = () => {
    const temporaryData =
        'actions_website,actions_driving_directions,actions_phone';
    return {
        metrics: temporaryData || METRICS_DEFAULT_VALUE,
    };
};
