import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba, greyColorObject } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

export const TypeItemContainer = styled.button`
    display: flex;
    height: 80px;
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    padding: 8px;
    transition: all ease 0.3s;

    background: none;
    font: inherit;
    outline: none;
    text-align: inherit;
    letter-spacing: inherit;

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        box-shadow: 0 2px 10px ${convertToRgba(HEX_COLORS.black, 0.15)};
    }
`;

export const TypeItemImage = styled.img`
    width: 64px;
    height: 64px;
    margin-right: 8px;
    pointer-events: none;
`;

export const TypeItemInfo = styled.div`
    width: 142px;
`;

export const TypeItemTitle = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
