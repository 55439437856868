import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const Title = styled.div`
    font-weight: 700;
    font-size: 22px;
    color: ${HEX_COLORS.blackberry};
    margin-bottom: 19px;
    padding-right: 50px;
`;

export const SubTitle = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${HEX_COLORS.blackberry};
`;
