import { useState } from 'react';

import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useHasGroupPuller from 'app/pages/visibility/BusinessListV2/hooks/groups/useHasGroupPuller';

import { BannerContainer } from './BusinessListGroupBanner.styled';

const BusinessListGroupBanner = () => {
    const { t } = useTranslation();
    const hasGroupPuller = useHasGroupPuller();
    const [isShown, setIsShown] = useState<boolean>(true);

    const onClose = () => {
        setIsShown(false);
    };

    if (hasGroupPuller && isShown) {
        return (
            <BannerContainer>
                <Banner
                    dataTrackId="business_list_group_banner"
                    variant="warning"
                    onClose={onClose}
                >
                    {t('puller_group_warning_message')}
                </Banner>
            </BannerContainer>
        );
    }

    return null;
};

export default BusinessListGroupBanner;
