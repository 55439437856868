import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';
import { REVIEW_LIST } from 'app/common/data/queryKeysConstants';

export const useDeleteFeedbackResult = () => {
    const queryClient = useQueryClient();
    const mutation = useMutation(
        (feedbackResultId: number) => {
            return api.feedbackResults.deleteFeedbackResult(feedbackResultId);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: [REVIEW_LIST],
                    refetchActive: true,
                });
            },
        },
    );

    return [mutation.mutate, mutation] as const;
};
