import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useReviewListCount } from '../hooks/useReviewListCount';

export const ReviewCount = () => {
    const { t } = useTranslation();
    const countByType = useReviewListCount();

    const countString = Object.entries(countByType)
        .map(([reviewObjectType, count]) => {
            if (count !== null && count !== undefined) {
                return t(`${reviewObjectType}_count`, { count });
            }

            return false;
        })
        .filter(Boolean)
        .join(' / ');

    return <Text variant="heading6">{countString}</Text>;
};
