import React, { useEffect, useState } from 'react';

import { Text, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { GoogleVerificationStartData, V2FormattedLocationData } from 'app/api/types/account';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useLanguage } from 'app/common/services/getLang';
import dataLayer from 'app/common/utils/dataLayer';
import {
    ExplanationDiv,
    ExplanationRowDiv,
    OverrideButton,
    OverrideIcon,
    OverrideText,
} from 'app/settingsManagement/components/GoogleVerification/screens/ConfirmationScreen/ConfirmationMethodInformation.styled';

import {
    AddressWrapper,
    EditEmailWrapper,
    InputWrapper,
    SpanExplanation,
} from './ConfirmationScreen.styled';

import { EmailInput } from '../../Fields/EmailInput';

type Props = {
    optionSelected: GoogleVerificationStartData;
};

type EmailProps = {
    optionSelected: GoogleVerificationStartData;
    setOptionSelected: React.Dispatch<React.SetStateAction<GoogleVerificationStartData>>;
};

type AddressProps = {
    location: V2FormattedLocationData;
    optionSelected: GoogleVerificationStartData;
    setOptionSelected: React.Dispatch<React.SetStateAction<GoogleVerificationStartData>>;
};

export const ConfirmationMethodInformationSMS: React.FC<Props> = ({ optionSelected }) => {
    const { t } = useTranslation();

    return (
        <Text variant="bodyMRegular">
            <span
                dangerouslySetInnerHTML={{
                    __html: t('google_verification__methods__sms_confirmation_content', {
                        phoneNumber: optionSelected.phone_number,
                    }),
                }}
            ></span>
        </Text>
    );
};

export const ConfirmationMethodInformationPhone: React.FC<Props> = ({ optionSelected }) => {
    const { t } = useTranslation();

    return (
        <Text variant="bodyMRegular">
            <span
                dangerouslySetInnerHTML={{
                    __html: t('google_verification__methods__phone_call_confirmation_content', {
                        phoneNumber: optionSelected.phone_number,
                    }),
                }}
            ></span>
        </Text>
    );
};

export const ConfirmationMethodInformationEmail: React.FC<EmailProps> = ({
    optionSelected,
    setOptionSelected,
}) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState<string | undefined>(optionSelected.email);

    useEffect(() => {
        setOptionSelected({ ...optionSelected, email: email });
    }, [email]);

    let domainEmail = '';
    if (optionSelected.email) {
        domainEmail = optionSelected.email.substring(optionSelected.email.indexOf('@'));
    }

    return (
        <>
            {optionSelected.is_editable && (
                <EditEmailWrapper>
                    <Text variant="bodyMRegular">
                        <SpanExplanation>
                            {t('google_verification__methods__email_explanation')}
                        </SpanExplanation>
                    </Text>
                    <EmailInput
                        placeholder={t('google_verification__methods__email_input')}
                        value={email}
                        onChange={setEmail}
                        domain={domainEmail}
                    />
                </EditEmailWrapper>
            )}
            <Text variant="bodyMRegular">
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('google_verification__methods__email_confirmation_content', {
                            email: `${optionSelected.email}`,
                        }),
                    }}
                ></span>
            </Text>
        </>
    );
};

export const ConfirmationMethodInformationAddress: React.FC<AddressProps> = ({
    location,
    optionSelected,
    setOptionSelected,
}) => {
    const { t } = useTranslation();
    const [contactName, setContactName] = useState<string | undefined>('');

    useEffect(() => {
        setOptionSelected({ ...optionSelected, full_name: contactName });
    }, [contactName]);

    return (
        <>
            <div>
                <Text variant="bodyMRegular">
                    {t('google_verification__methods__address_confirmation_content')}
                </Text>
                <Text variant="bodyMRegular" color="#7F8EA4">
                    {t('google_verification__methods__address_confirmation_detail', {
                        daysToDeliver: optionSelected.expected_delivery_days,
                    })}
                </Text>
            </div>
            <InputWrapper>
                <Text variant="bodyLSemibold">
                    {t('google_verification__methods__address_input_label')}
                </Text>
                <TextInput
                    dataTrackId="google_verification__methods__address_input"
                    label={t('google_verification__methods__address_input_placeholder')}
                    value={contactName}
                    onChange={setContactName}
                    required
                />
            </InputWrapper>
            <AddressWrapper>
                <Text variant="bodyLBold">{location.locationName}</Text>
                <Text variant="bodyMMedium" color="#7F8EA4">
                    {optionSelected.full_address}
                </Text>
            </AddressWrapper>
        </>
    );
};

export const getVideoUrl = (lang: string) => {
    switch (lang) {
        case 'fr':
            return 'https://youtu.be/EPMFLiWy8_Y';
        case 'es':
            return 'https://youtu.be/BBM1_NPiNfw';
        case 'it':
            return 'https://youtu.be/PAk_wQOssrg';
        case 'pt':
            return 'https://youtu.be/aTsZPZzclq0';
        default:
            return 'https://youtu.be/3Xj0hNAI-f4';
    }
};

export const getArticleUrl = (lang: string) => {
    switch (lang) {
        case 'fr':
        case 'es':
        case 'it':
            return `https://help.partoo.co/${lang}/articles/7903097-comment-valider-ma-fiche-google-business-profile-par-video`;
        case 'pt':
        case 'pt-BR':
            return 'https://help.partoo.co/pt-BR/articles/7903097-comment-valider-ma-fiche-google-business-profile-par-video';
        default:
            return 'https://help.partoo.co/en/articles/7903097-comment-valider-ma-fiche-google-business-profile-par-video';
    }
};

type ConfirmationMethodInformationBusinessVideoProps = {
    location: V2FormattedLocationData | undefined;
};

export const ConfirmationMethodInformationBusinessVideo = ({
    location,
}: ConfirmationMethodInformationBusinessVideoProps) => {
    const { t } = useTranslation();
    const lang = useLanguage();
    const { data: me } = useMe();

    const sendTrackingData = (eventName: string) => {
        dataLayer.pushDict(eventName, {
            user_id: me?.id,
            shadow_user_id: me?.shadow_user_id,
            location_id: location?.id,
            business_id: location?.partnerConnection?.owningBusinessId,
            verification_status: location?.googleVerifications?.status,
        });
    };

    return (
        <>
            <div>
                <OverrideText variant="bodyMRegular">
                    {t('google_verification__methods__business_video_content_text')}
                </OverrideText>
                <Text variant="bodySRegular">
                    {t('google_verification__methods__business_video_content_detail')}
                </Text>
            </div>
            <ExplanationDiv>
                <ExplanationRowDiv>
                    <OverrideIcon icon={['fa-circle-question']} />
                    <Text as="span" variant="bodyMBold">
                        {t('google_verification__methods__business_video_explanation_title')}
                    </Text>
                </ExplanationRowDiv>
                <ExplanationRowDiv>
                    <OverrideButton
                        dataTrackId="continue_to_video_verification"
                        appearance="outlined"
                        onClick={() => {
                            sendTrackingData('continue_to_video_verification');
                            window.open(getVideoUrl(lang), '_blank');
                        }}
                    >
                        {t('google_verification__methods__business_video_tutorial_button')}
                    </OverrideButton>
                    <OverrideButton
                        dataTrackId="continue_to_article_verification"
                        appearance="outlined"
                        onClick={() => {
                            sendTrackingData('continue_to_article_verification');
                            window.open(getArticleUrl(lang), '_blank');
                        }}
                    >
                        {t('google_verification__methods__business_video_article_button')}
                    </OverrideButton>
                </ExplanationRowDiv>
            </ExplanationDiv>
        </>
    );
};
