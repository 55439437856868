import React, { FC } from 'react';

import { ConsumerImageContainer } from './ConsumerImage.styled';

type ConsumerImageProps = {
    imageUrl: string;
    size: number;
    onImageLoadError: () => void;
};

const ConsumerImage: FC<ConsumerImageProps> = ({
    imageUrl,
    size,
    onImageLoadError,
}) => (
    // This component renders a customer image as an icon.
    // Given an imageUrl, it will render the image, but if the url is wrong,
    // or could not be loaded, it will inform the parent component by calling
    // onImageLoadError callback.
    <ConsumerImageContainer
        src={imageUrl}
        size={size}
        onError={() => onImageLoadError()}
    />
);

export default ConsumerImage;
