import { useQuery } from 'react-query';

import type { CompetitiveOverview } from 'app/api/types/competitiveBenchmark';
import api from 'app/api/v2/api_calls';
import { GetOverviewParams, OVERVIEW_DEFAULT } from 'app/api/v2/api_calls/competitiveBenchmark';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { COMPETITIVE_OVERVIEW } from 'app/common/data/queryKeysConstants';

import { useDateFilter } from './useDateFilter';
import { useKeywordFilter } from './useKeywordFilter';
import { formatDate } from '../utils/formatDate';

const getMonthDifference = (first: Date, second: Date): number => {
    const yearDiff = Math.abs(second.getFullYear() - first.getFullYear());
    const monthDiff = Math.abs(second.getMonth() - first.getMonth());
    return yearDiff * 12 + monthDiff;
};

const hasMonth = (dates: Date[], date: Date): boolean => {
    const month = date.getMonth();
    const year = date.getFullYear();
    return dates.some(d => d.getMonth() === month && d.getFullYear() === year);
};

const getOnePastYearDateRange = (date: Date): [Date, Date] => {
    const startDate = new Date(date);
    startDate.setFullYear(startDate.getFullYear() - 1);
    startDate.setDate(1);

    const endDate = new Date(date);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0);

    return [startDate, endDate];
};

const getOneFutureYearDateRange = (date: Date): [Date, Date] => {
    const startDate = new Date(date);
    startDate.setDate(1);

    const endDate = new Date(startDate.getFullYear() + 1, startDate.getMonth() + 1, 0);

    return [startDate, endDate];
};

/**
 * Given a list of dates and a current date, returns a range of start and end
 * dates to display in the competitive overview.
 *
 * The logic is as follows:
 * If there is less than 12 months difference from most recent to most oldest date
 * make the startDate the oldest and display the graph from this date + 12 months.
 * Else, if the oldest date is bigger than 12 months from the currentDate,
 * display the graph from the currentDate - 12 months.
 * If date does not exist, return to the first case
 *
 * @param {Date[]} dates A list of dates to consider.
 * @param {Date} currentDate The current date.
 * @returns {[Date, Date]} A range of start and end dates.
 */
const getDatesToDisplay = (dates: Date[], currentDate: Date): [Date, Date] => {
    const firstDate = dates[0] || currentDate;
    const monthsDifference = getMonthDifference(firstDate, currentDate);

    if (monthsDifference < 12) return getOneFutureYearDateRange(firstDate);

    let [startDate, endDate] = getOnePastYearDateRange(currentDate);
    if (!hasMonth(dates, startDate)) {
        startDate = dates.filter(date => date > startDate)[0] || currentDate;
        [startDate, endDate] = getOneFutureYearDateRange(startDate);
    }

    return [startDate, endDate];
};

export const useCompetitiveOverview = (): {
    overview: CompetitiveOverview;
    isLoading: boolean;
} => {
    const businessFilters = useBusinessModalFilters();
    const [keyword] = useKeywordFilter();
    const [currentDate] = useDateFilter();
    const [startDate, endDate] = getDatesToDisplay(keyword.dates, currentDate);

    const params: GetOverviewParams = {
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
        keyword: keyword.keyword,
        ...businessFilters,
    };
    const { data, isPreviousData, isLoading } = useQuery(
        [COMPETITIVE_OVERVIEW, params],
        () => api.competitiveBenchmark.getOverview(params),
        { keepPreviousData: true },
    );
    return { overview: data ?? OVERVIEW_DEFAULT, isLoading: isLoading || isPreviousData };
};
