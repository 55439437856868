import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const CongratulationBadgeContainer = styled.div`
    position: relative;
    width: 457px;
    background-color: #101d34;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 50px;

    > i {
        position: absolute;
        top: 15px;
        right: 20px;
        cursor: pointer;
    }

    > img {
        position: absolute;
        top: 48px;
    }
`;

export const Title = styled.div`
    z-index: 2;
    color: ${HEX_COLORS.white};
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 290px;
`;

export const Description = styled.p`
    z-index: 2;
    color: ${HEX_COLORS.white};
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin: 0;
`;
