import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { BUSINESS, MORE_HOURS } from 'app/common/data/queryKeysConstants';

export default function useBusinessMoreHours() {
    const businessId = useGetBusinessId();
    const { data: business } = useBusiness();
    return useQuery(
        [BUSINESS, MORE_HOURS, { businessId }],
        () => api.business.businessMoreHoursGet(businessId, {}),
        {
            enabled: !!(business && business.categories[0]),
        },
    );
}
