import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

export const GroupSectionContainer = styled.div`
    &::after {
        display: flex;
        content: '';
        border-bottom: solid 1px ${greyColorObject.initial};
        margin: 20px 24px;
    }
`;

export const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-inline: 24px;
    font-size: 18px;
    color: ${HEX_COLORS.blackberry};

    &:hover {
        cursor: pointer;
    }

    > i {
        // Hardcoded to align with checkbox... see GroupLine
        margin-right: 5px;
    }
`;

type SectionNameProps = {
    disabled?: boolean;
};

export const SectionName = styled.div<SectionNameProps>`
    overflow: hidden;
    font-weight: bold;
    margin-right: 8px;

    ${props =>
        props.disabled &&
        css`
            color: ${DISABLED_COLOR};
        `}
`;

type SectionContentProps = {
    collapsed: boolean;
    maxHeight: number;
};

export const SectionContent = styled.div<SectionContentProps>`
    overflow: hidden;
    max-height: ${props => props.maxHeight}px;
    transition: all 0.3s ease-in-out;
    padding-top: 12px;
    padding-inline: 24px;

    ${props =>
        props.collapsed &&
        css`
            max-height: 0;
            padding-top: 0;
        `}
`;

type GroupLineProps = {
    disabled?: boolean;
};

export const GroupLine = styled.div<GroupLineProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 34px;

    font-size: 14px;
    color: ${props => (props.disabled ? DISABLED_COLOR : HEX_COLORS.blackberry)};

    // Really bad but the checkbox is too big and we don't have other component to use
    margin-right: -8px;
`;

export const GroupName = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
