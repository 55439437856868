import { useEffect, useMemo } from 'react';

import { Button, Stack } from '@partoohub/ui';
import { Controller, useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import { DIGIT_SEPARATOR, HOUR_SEPARATOR } from 'app/businessEditV2/oldFromEditV1/data/constants';
import { findFirstString } from 'app/businessEditV2/utils/utils';
import TimeImage from 'app/common/designSystem/components/atoms/svg/modalImages/time';

import HourWindowWithButtonWrapper from './HourWindowWithButtonWrapper';
import {
    ErrorMessage,
    ModalBody,
    ModalButtonsContainer,
    ModalText,
    ModalTitle,
    TimeSlotTable,
} from './TimeSlotReferenceModalContent.styled';

type Props = {
    closeModal: () => void;
};

export const isValidWindow = (window: string) => {
    try {
        const [start, end] = window.split(HOUR_SEPARATOR);
        const [startHour, startMinutes] = start.split(DIGIT_SEPARATOR);
        const [endHour, endMinutes] = end.split(DIGIT_SEPARATOR);
        return (
            !Number.isNaN(parseInt(startHour, 10)) &&
            !Number.isNaN(parseInt(startMinutes, 10)) &&
            !Number.isNaN(parseInt(endHour, 10)) &&
            !Number.isNaN(parseInt(endMinutes, 10))
        );
    } catch (e) {
        return false;
    }
};

const TimeSlotReferenceModalContent = ({ closeModal }: Props) => {
    // Hooks
    const { data: business } = useBusiness();
    const businessUpdate = useBusinessUpdate(() => {
        closeModal();
    });
    const hoursPermission = useFieldMetadata('hours', 'open_hours')?.enabled;
    const { t } = useTranslation();

    const businessTimeSlot = business?.time_slot_reference ?? [];
    const timeSlotExists = businessTimeSlot.length !== 0;

    // Form
    const setFormValue = () => ({
        timeSlotReference: timeSlotExists ? businessTimeSlot : ['-'],
    });
    const { control, handleSubmit, watch, reset } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    useEffect(() => {
        if (business) {
            reset(setFormValue());
        }
    }, [business]);

    const formatPayload = payload => ({
        time_slot_reference: payload.timeSlotReference,
    });
    const onSubmit = payload => {
        businessUpdate.mutate(payload);
    };
    const submitForm = () => {
        handleSubmit(payload => {
            onSubmit(formatPayload(payload));
        })();
    };

    const error = findFirstString(businessUpdate.error?.response || '');

    // Buttons
    const allWindowsAreValid = watch('timeSlotReference').every(isValidWindow);
    const submittButtonIsDisabled = !allWindowsAreValid;
    const submitButtonLabel = t(timeSlotExists ? 'update' : 'create');

    return (
        <ModalBody>
            <TimeImage />
            <Stack style={{ marginBottom: '16px', padding: '0 8px' }}>
                <ModalTitle variant="heading5">{t('define_time_slot_reference')}</ModalTitle>
                <ModalText variant="bodyMRegular" color="secondary">
                    {t('define_time_slot_reference_content')}
                </ModalText>
            </Stack>
            <TimeSlotTable>
                <Controller
                    name="timeSlotReference"
                    control={control}
                    render={({ field: { ref: _ref, ...usedFields } }) => (
                        <HourWindowWithButtonWrapper
                            {...usedFields}
                            hasError={!!error}
                            disabled={!hoursPermission}
                        />
                    )}
                />
                <ErrorMessage>{t(error || '')}</ErrorMessage>
            </TimeSlotTable>

            <ModalButtonsContainer>
                <Button
                    variant="secondary"
                    shape="cube"
                    size="large"
                    appearance="outlined"
                    full
                    onClick={closeModal}
                    dataTrackId="edit_business__time_slot_reference_modal__cancel_button"
                >
                    {t('cancel')}
                </Button>
                <EditFormStatefulButton
                    size="large"
                    appearance="contained"
                    full
                    overrideLabel={submitButtonLabel}
                    onClick={submitForm}
                    isLoading={businessUpdate.isLoading}
                    disabled={submittButtonIsDisabled}
                    hasError={!!businessUpdate.error}
                    hideIcon
                    dataTrackId="edit_business__time_slot_reference_modal__save_button"
                />
            </ModalButtonsContainer>
        </ModalBody>
    );
};

export default TimeSlotReferenceModalContent;
