import { SettingMenuItem } from 'app/pages/settingsV2/subComponents/SettingsMenuList/SettingsMenuList';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

export const REGEX_SETTINGS_CATEGORY_EXACT_PATH =
    /^\/settings\/(main|messages|integrations|platforms|reviews|team)\/?$/;

export const findFirstDisplayPathname = (menus: SettingMenuItem[]) => {
    for (const menu of menus) {
        for (const option of menu.options) {
            if (option.display === undefined || option.display === true) {
                return option.pathname;
            }
        }
    }
    return NOT_FOUND_PATH; // Return null if no such pathname is found
};
