import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';
import {
    CONVERSATION_STARTERS,
    CONVERSATION_STARTERS_ITEMS,
} from 'app/common/data/queryKeysConstants';

export default function useConversationStarterItemDelete(
    conversationStarterId: string,
    conversationStarterItemId: string,
) {
    const queryClient = useQueryClient();
    return useMutation(
        () =>
            api.conversationStarters.deleteConversationStarterItem(
                conversationStarterId,
                conversationStarterItemId,
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([CONVERSATION_STARTERS_ITEMS, conversationStarterId]);
                queryClient.invalidateQueries([CONVERSATION_STARTERS]);
            },
        },
    );
}
