import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, List, Text } from '@partoohub/ui';

export const LocationCardAddressRowContainer = styled.div<{
    isRequired: boolean;
}>`
    ${({ isRequired }) =>
        isRequired &&
        css`
            & > div:last-child {
                border: 2px solid var(--Status-Danger-Initial, #ff426e);
            }
        `}
`;

export const LocationCardAddressRow = styled(List.Row)`
    width: 70%;
    min-height: 96px;
`;

export const PartnerImg = styled.div`
    width: 64px;
    height: 64px;
`;
export const StyledText = styled(Text)`
    max-width: 148px;
`;

export const StyledCell = styled(List.Cell)`
    align-items: flex-end;
    margin-right: 15px;
`;

export const EditButton = styled(Button)`
    margin: 10px 0;
    white-space: initial;
    height: auto;
    background: var(--Grey-Light, #f6f8fc);
    text-align: left;
`;
