import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { EmailInput } from 'app/common/components/form/EmailInput';
import { PasswordInput } from 'app/common/components/form/PasswordInput';
import WHITELIST_PROVIDERS from 'app/common/data/providers';
import env from 'app/common/services/getEnvironment';

import { useProviderFromUrl } from 'app/connection/hooks/useProviderFromUrl';

import {
    emailErrorSelector,
    emailSelector,
    globalErrorSelector,
    updateEmail,
} from 'app/states/connection/reducers/login';
import { AppState } from 'app/states/reducers';

import ChangePasswordModal from './ChangePasswordModal';
import GoogleSignIn from './GoogleSignIn';
import LoginButton from './LoginButton';
import { FormContainer, LoginFormForgetLink } from './LoginForms.styled';
import LostPasswordLink from './LostPasswordLink.container';
import SsoSignIn from './SsoSignIn';

type Props = {
    onSubmit: () => void;
    showUnsafePasswordModal: boolean;
};

const LoginForm: FC<Props> = ({ onSubmit, showUnsafePasswordModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const login = useSelector((state: AppState) => state.login);
    const provider = useProviderFromUrl();
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit();
    };

    const value = emailSelector(login);
    const error = !!(globalErrorSelector(login) || emailErrorSelector(login));
    const notice = emailErrorSelector(login) ? 'Email not valid' : '';

    const handleChange = (value: string) => dispatch(updateEmail(value));

    return (
        <>
            {!env.isAdmin() && (
                <FormContainer onSubmit={handleSubmit}>
                    <EmailInput
                        value={value}
                        label={t('email_extended')}
                        onChange={handleChange}
                        error={error}
                        notice={notice}
                    />
                    <PasswordInput />
                    <LoginFormForgetLink as="span">
                        <LostPasswordLink translationKey="ask_forgotten_password" />
                    </LoginFormForgetLink>
                    <LoginButton />
                </FormContainer>
            )}

            <GoogleSignIn />

            {!env.isAdmin() && WHITELIST_PROVIDERS.includes(provider) && <SsoSignIn />}

            <ChangePasswordModal show={showUnsafePasswordModal} />
        </>
    );
};

export default LoginForm;
