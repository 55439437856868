import React from 'react';

import { Text } from '@partoohub/ui';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import {
    BackgroundContainer,
    BackgroundImageContainer,
    DownloadButton,
    DownloadButtonContainer,
    LaterButton,
    ParagraphText,
} from 'app/common/components/DownloadMobileApp/DownloadMobileApp.styled';
import useLocalStorage from 'app/common/hooks/useLocalStorage';
import { useIsMobile } from 'app/common/services/screenDimensions';
import { STATIC_BASE_URL } from 'app/config';
import DURATIONS from 'app/styles/constants/durations';

import { AppleStore } from './Icons/AppleStore';
import { GoogleStore } from './Icons/GoogleStore';

const IOS_APP_DOWNLOAD_LINK = 'https://apps.apple.com/fr/app/partoo/id1580048834';
const ANDROID_APP_DOWNLOAD_LINK = 'https://play.google.com/store/apps/details?id=com.partoo.mobile';

interface Props {
    visible: boolean;
    setVisible: (value: boolean) => void;
}

export const DownloadMobileApp = ({ visible, setVisible }: Props) => {
    const isMobileScreenSize = useIsMobile();
    const { t } = useTranslation();

    const nodeRef = React.useRef(null);

    const handleClickLater = () => {
        setVisible(false);
    };

    if (!isMobileScreenSize) {
        return null;
    }

    return ReactDOM.createPortal(
        <CSSTransition nodeRef={nodeRef} in={visible} timeout={DURATIONS.short} unmountOnExit>
            <BackgroundContainer
                ref={nodeRef}
                url={`${STATIC_BASE_URL}/images/app/background-mobile-app.png`}
            >
                <Text variant="heading1" color="white">
                    {t('web_banner_download_mobile_app_small_devices_modal_title')}
                </Text>
                <ParagraphText>
                    <Text variant="bodyLRegular" as="span" color="white">
                        {t('web_banner_download_mobile_app_small_devices_modal_desc')}
                    </Text>
                </ParagraphText>
                <DownloadButtonContainer>
                    <DownloadButton
                        href={IOS_APP_DOWNLOAD_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <AppleStore />
                        <Text variant="bodySMedium" as="span" color="white">
                            {t('app_store')}
                        </Text>
                    </DownloadButton>
                    <DownloadButton
                        href={ANDROID_APP_DOWNLOAD_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <GoogleStore />
                        <Text variant="bodySMedium" as="span" color="white">
                            {t('google_play')}
                        </Text>
                    </DownloadButton>
                </DownloadButtonContainer>
                <BackgroundImageContainer>
                    <img
                        src={`${STATIC_BASE_URL}/images/app/mobile-responsive.png`}
                        alt="responsive-mobile-app"
                    />
                </BackgroundImageContainer>
                <LaterButton onClick={handleClickLater}>
                    <Text variant="bodyMBold" as="span" color="white">
                        {t('web_banner_download_mobile_app_small_devices_modal_continue_button')}
                    </Text>
                </LaterButton>
            </BackgroundContainer>
        </CSSTransition>,
        document.body,
    );
};

/** A tiny wrapper around DownloadMobileAppPersisted that persists
 *  its hidden state in the localStorage for a few days when
 *  the user clicks on "Later".
 */
export const DownloadMobileAppPersisted = () => {
    const [visible, setVisible] = useLocalStorage('downloadMobileAppBanner', true, {
        maxAge: 3 * 24 * 3600, // 3 Days
    });

    return <DownloadMobileApp visible={visible} setVisible={setVisible} />;
};

export default DownloadMobileApp;
