import styled from '@emotion/styled';

export const SidebarButtonWithMenuContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 14px 0;
    height: 68px;

    // Override Default Icon Button style to match with Sidebar color
    i {
        color: ${({ theme }) => theme.colors.primary.initial};
        &:hover {
            color: ${({ theme }) => theme.colors.primary.initial};
        }
    }
`;

export const WrapperRef = styled.div`
    position: absolute;
    left: 64px;
    top: 80px;
`;
