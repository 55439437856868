import { FC } from 'react';

import { List } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { WhatsAppBusinessAccount, WhatsAppPhoneNumber } from 'app/api/types/whatsapp';

import WhatsappAccountListPlaceholder from './WhatsappAccountListPlaceholder';
import WhatsappAccountPhoneNumberRow from './WhatsappAccountPhoneNumberRow';

interface Props {
    isLoading: boolean;
    accounts?: WhatsAppBusinessAccount[];
}

const WhatsappAccountList: FC<Props> = ({ isLoading = false, accounts }) => {
    const { t } = useTranslation();

    const columns = [
        { label: t('whatsapp_account_list_header__title') },
        { label: t('whatsapp_account_list_header__phone_number') },
        { label: t('whatsapp_account_list_header__status') },
        { width: '40px' },
    ];

    if (isLoading) {
        return null;
    }

    if (accounts!.length == 0) {
        return <WhatsappAccountListPlaceholder />;
    }

    return (
        <List columns={columns} loading={isLoading}>
            {accounts!.map((whatsappAccount: WhatsAppBusinessAccount) =>
                whatsappAccount.phone_numbers.map((number: WhatsAppPhoneNumber) => (
                    <WhatsappAccountPhoneNumberRow key={number.number} phoneNumber={number} />
                )),
            )}
        </List>
    );
};

export default WhatsappAccountList;
