import { Loader, Stack } from '@partoohub/ui';
import { InfiniteData } from 'react-query';

import {
    BusinessDetailsData,
    PublisherStateKeysEnum,
    PublishersStatesBusinessesInfoData,
} from 'app/api/types/publisher_state';
import { usePublisherByName } from 'app/presence/management/hooks/usePublisherByName';
import { usePublishersStatesBusinessesInfo } from 'app/presence/management/hooks/usePublishersStatesBusinessesInfo';
import { usePublisherStatesByName } from 'app/presence/management/hooks/usePublisherStatesByName';

import { DropdownListBusinesses } from '../DropdownListBusiness/DropdownListBusinesses';

type Props = {
    publisherName: string;
};

interface GetBusinessesByState {
    publisherName: string;
    state: PublisherStateKeysEnum;
    data: InfiniteData<PublishersStatesBusinessesInfoData> | undefined;
}

const getBusinessesByState = ({ publisherName, state, data }: GetBusinessesByState) => {
    const businessesByState =
        data?.pages?.reduce(
            (businesses: BusinessDetailsData[], page: PublishersStatesBusinessesInfoData) => {
                const publishersBusiness = page[publisherName][state].publishers_business;

                return [...businesses, ...publishersBusiness];
            },
            [],
        ) ?? [];
    return businessesByState;
};

export const PublisherRowDropDown = ({ publisherName }: Props) => {
    const { publisherStates } = usePublisherStatesByName(publisherName);
    const { publisher } = usePublisherByName(publisherName);

    const fetchedBusinessSuccess = publisherStates.success >= 1;
    const fetchedBusinessPending = publisherStates.pending >= 1;
    const fetchedBusinessToConfigure = publisherStates.to_configure >= 1;

    const {
        data: successData,
        fetchNextPage: fetchSuccessNextPage,
        hasNextPage: SuccessHasNextPage,
        isFetching: isFetchingSuccess,
        isLoading: isLoadingSuccess,
    } = usePublishersStatesBusinessesInfo({
        publisherName,
        state: 'success',
        enabled: fetchedBusinessSuccess,
    });

    const {
        data: pendingData,
        fetchNextPage: fetchPendingNextPage,
        hasNextPage: PendingHasNextPage,
        isFetching: isFetchingPending,
        isLoading: isLoadingPending,
    } = usePublishersStatesBusinessesInfo({
        publisherName,
        state: 'pending',
        enabled: fetchedBusinessPending,
    });

    const {
        data: toConfigureData,
        fetchNextPage: fetchToConfigureNextPage,
        hasNextPage: ToConfigureHasNextPage,
        isFetching: isFetchingToConfigure,
        isLoading: isLoadingToConfigure,
    } = usePublishersStatesBusinessesInfo({
        publisherName,
        state: 'to_configure',
        enabled: fetchedBusinessToConfigure,
    });

    const businessesSuccess = getBusinessesByState({
        publisherName,
        state: 'success',
        data: successData,
    });
    const businessesPending = getBusinessesByState({
        publisherName,
        state: 'pending',
        data: pendingData,
    });
    const businessesToConfigure = getBusinessesByState({
        publisherName,
        state: 'to_configure',
        data: toConfigureData,
    });

    if (!publisher) {
        return null;
    }

    if (isLoadingSuccess || isLoadingPending || isLoadingToConfigure) {
        return (
            <Stack justifyContent="center" alignItems="center">
                <Loader color="secondary" trailColor="secondary" />
            </Stack>
        );
    }

    return (
        <>
            <DropdownListBusinesses
                title={publisher.is_indirect ? 'transmitted' : 'updated'}
                status="success"
                publisherName={publisher.name}
                publisherFormattedName={publisher.formatted_name}
                businesses={businessesSuccess}
                fetchNextPage={fetchSuccessNextPage}
                hasNextPage={SuccessHasNextPage || false}
                isFetching={isFetchingSuccess}
            />
            <DropdownListBusinesses
                title="pending"
                status="pending"
                publisherName={publisher.name}
                publisherFormattedName={publisher.formatted_name}
                businesses={businessesPending}
                fetchNextPage={fetchPendingNextPage}
                hasNextPage={PendingHasNextPage || false}
                isFetching={isFetchingPending}
            />
            <DropdownListBusinesses
                title="to_configure"
                status="to_configure"
                publisherName={publisher.name}
                publisherFormattedName={publisher.formatted_name}
                businesses={businessesToConfigure}
                fetchNextPage={fetchToConfigureNextPage}
                hasNextPage={ToConfigureHasNextPage || false}
                isFetching={isFetchingToConfigure}
            />
        </>
    );
};
