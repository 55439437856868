import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import useSelectedBusinessesTitle from 'app/common/components/businessModal/hooks/useSelectedBusinessesTitle';
import MenuIcon from 'app/common/components/icons/MenuIcon';
import MobilePageHeader from 'app/common/components/pageHeaders/MobilePageHeader';
import { collapseSidebar } from 'app/common/reducers/global';

import { MobileHeaderContainer } from './MobileHeader.styled';

export const MobileHeader = () => {
    const dispatch = useDispatch();
    const openSidebar = useCallback(() => dispatch(collapseSidebar()), []);

    const { title, subtitle } = useSelectedBusinessesTitle();

    return (
        <MobileHeaderContainer>
            <MobilePageHeader
                leftContent={<MenuIcon onClick={openSidebar} />}
                title={title}
                subtitle={subtitle}
                hideLeftContentOnIframe
            />
        </MobileHeaderContainer>
    );
};
