import React from 'react';

import { Modal } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { GroupsSettingsQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/GroupsSettings/utils/enums';

import DeleteSectionContent from './DeleteSectionContent';

const DeleteSectionModal = () => {
    const [deleteSection, setDeleteSection] = useStateQueryParams(
        GroupsSettingsQueryKeys.DELETE,
        true,
    );

    const [sectionId, groupId] = deleteSection.split(',');

    return (
        <Modal isOpen={!!sectionId && !groupId} closeModal={() => setDeleteSection('')}>
            <DeleteSectionContent closeModal={() => setDeleteSection('')} />
        </Modal>
    );
};

export default DeleteSectionModal;
