import { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { DropdownMenuButton } from 'app/common/components/buttons/DropdownMenuButton/DropdownMenuButton';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import dataLayer from 'app/common/utils/dataLayer';

import { useChatbots } from 'app/pages/settings/Chatbot/hooks/useChatbots';
import { AssignFilterOption, StatusFilterOption, URLParams } from 'app/states/messaging/dataTypes';

import queryClient from 'app/states/queryClient';

import { DropdownMenuContainer } from './AssignedConversationFilter.styled';

import { getMenuLabels, getOptions, getOptionsLabels } from './helper';
import { useConversationStats } from '../../hooks/useConversationStats';
import { getConversationsKey } from '../../hooks/useGetConversations';
import { useHasAssignConversationFeature } from '../../hooks/useHasAssignConversationFeature';
import { MessagingContext } from '../../MessagingContext';

const AssignConversationFilter = () => {
    const { t } = useTranslation();
    const { hasAssignConversationFeature } = useHasAssignConversationFeature();
    const navigate = useNavigate();
    const { setActiveConversation } = useContext(MessagingContext);
    const { data: me } = useMe();
    const { data: chatbots } = useChatbots();

    const [conversationStatusFilter] = useStateQueryParams(URLParams.STATUS);
    const { data } = useConversationStats();
    const stats = data?.[conversationStatusFilter] || {};
    const options = getOptions(
        getOptionsLabels(t, stats, hasAssignConversationFeature, !!chatbots, me),
    );
    const section = [{ options: options }];
    const menuLabels = getMenuLabels(t, me);

    const [statusFilter] = useStateQueryParams<StatusFilterOption>(URLParams.STATUS);
    const [assignFilter, setAssignFilter] = useStateQueryParams<AssignFilterOption>(
        URLParams.ASSIGN,
    );
    const businessFilters = useBusinessModalFilters();

    const handleAssignConversationFilter = (value: AssignFilterOption) => {
        const conversationsKey = getConversationsKey(statusFilter, value, businessFilters);

        setAssignFilter(value);
        dataLayer.pushDict('messaging_filter_assignation', { status: value });
        setActiveConversation(undefined);
        queryClient.removeQueries(conversationsKey);
        navigate({ search: '?conversationId=' }, { replace: true });
    };

    return (
        <DropdownMenuContainer>
            <DropdownMenuButton
                selectedOptionName={assignFilter}
                sections={section}
                onMenuClick={handleAssignConversationFilter}
                menuLabels={menuLabels}
                customPosition="right"
            />
        </DropdownMenuContainer>
    );
};

export default AssignConversationFilter;
