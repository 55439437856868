import { connect } from 'react-redux';

import filteredBusinessCount from 'app/businessConnection/services/filteredBusinessCount';
import {
    isFetchingSelector as businessesBeingFetchedSelector,
    businessesCurrentPageSelector,
    businessesListSelector,
    businessesMaxPageSelector,
    connectedToFacebookLocationSelector,
    connectedToGmbLocationSelector,
    orgIdFilterSelector,
    orgIdInFilterSelector,
    searchBusinesses,
    searchQuerySelector,
    setConnectedToFacebookLocationFilterByFilterId,
    setConnectedToGmbLocationFilterByFilterId,
} from 'app/common/reducers/newBusinesses';
import filterBusinesses from 'app/common/services/filterBusinesses';
import fuzzySearch, {
    PARTOO_BUSINESS_TERMS_TO_SEARCH_IN,
} from 'app/common/services/fuzzySearch';
import {
    NavValue,
    navTabSelector,
} from 'app/states/knowledge/reducers/businessConnection/nav';
import { getBusinessPublisherStates } from 'app/states/presence/reducers/listing/businessDetails/actions';
import { AppState } from 'app/states/reducers';

import ConnectionsTableComponent from './ConnectionsTable';

const mapStateToProps = (state: AppState) => {
    const {
        businesses,
        businessConnection: { stats },
    } = state;
    const businessesList = businessesListSelector(businesses);
    const searchQuery = searchQuerySelector(businesses);
    const currentPage = businessesCurrentPageSelector(businesses);
    const maxPage = businessesMaxPageSelector(businesses);
    const navTab = navTabSelector(state);
    const totalBusinessCount = filteredBusinessCount(stats, navTab);
    const businessesAreBeingFetched =
        businessesBeingFetchedSelector(businesses);
    // @ts-ignore
    const businessesFilteredList = filterBusinesses(businessesList, {
        gmbConnection: connectedToGmbLocationSelector(businesses),
        facebookConnection: connectedToFacebookLocationSelector(businesses),
        orgId: orgIdFilterSelector(businesses),
        orgIdIn: orgIdInFilterSelector(businesses),
    });
    const businessesFuzzySearchedList = fuzzySearch(
        businessesFilteredList,
        PARTOO_BUSINESS_TERMS_TO_SEARCH_IN,
        searchQuery,
    );
    const businessesInfos = businessesFuzzySearchedList.map(
        (business: any) => ({
            business_id: business.id,
            status: business.status,
        }),
    );

    return {
        businessesInfos,
        businessesAreBeingFetched,
        searchQuery,
        currentPage,
        hasBusinesses: businessesList.length !== 0,
        allBusinessesHaveBeenFetched:
            currentPage >= maxPage ||
            (totalBusinessCount &&
                totalBusinessCount === businessesInfos.length),
        hasBusinessToConnect: businessesFilteredList.length !== 0,
        navTab,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    searchBusinessesPage: (
        page: number,
        query: string,
        additionalParams: Record<string, string>,
    ) => dispatch(searchBusinesses(page, query, undefined, additionalParams)),
    setConnectedToFacebookLocationFilterByFilterId: (navTab: NavValue) =>
        dispatch(setConnectedToFacebookLocationFilterByFilterId(navTab)),
    setConnectedToGmbLocationFilterByFilterId: (navTab: NavValue) =>
        dispatch(setConnectedToGmbLocationFilterByFilterId(navTab)),
    fetchPublisherSuccess: (publisherName: string) =>
        dispatch(getBusinessPublisherStates(publisherName, 'success')),
});

const mergeProps = (mapProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...mapProps,
    ...dispatchProps,
    fetchNextBusinesses: (additionalParams: Record<string, string>) => {
        if (!mapProps.businessesAreBeingFetched) {
            dispatchProps.searchBusinessesPage(
                mapProps.currentPage + 1,
                mapProps.searchQuery,
                additionalParams,
            );
        }
    },
});

const ConnectionsTable = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
)(ConnectionsTableComponent);

export default ConnectionsTable;
