import React from 'react';

import { Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import { StyledChip, StyledClickable } from './Chip.styled';

type Props = {
    active: boolean;
    translationParams?: Record<string, any>;
    text: string;
    handleClick: (arg0: Event) => void;
    loading: boolean;
    chipClass?: string;
    hide: boolean;
};

const Chip: React.FC<Props> = ({
    active,
    text,
    handleClick,
    loading,
    hide,
    translationParams = {},
    chipClass = '',
}) => {
    const { t } = useTranslation();

    if (!hide) return <></>;

    if (loading) return <LoadingRectangle recHeight="20px" recRadius="4px" />;

    return (
        <StyledClickable onClick={handleClick} active={active} className={chipClass}>
            {active ? (
                <StyledChip>
                    <i className="fa fa-times" />
                    <Stack alignItems="center" justifyContent="center" style={{ flex: 1 }}>
                        <Text variant="bodySBold" as="span" color="theme">
                            {t(text, translationParams)}
                        </Text>
                    </Stack>
                </StyledChip>
            ) : (
                <Text variant="bodySBold" as="span" color="primary">
                    {t(text, translationParams)}
                </Text>
            )}
        </StyledClickable>
    );
};

export default Chip;
