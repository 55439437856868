import styled from '@emotion/styled';

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 4px 0;
`;

export const HourFieldsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

export const HourFieldWrapper = styled.div`
    width: 120px;
`;
