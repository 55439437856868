import React from 'react';

import { useTranslation } from 'react-i18next';

import { V2FormattedLocationData } from 'app/api/types/account';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useLanguage } from 'app/common/services/getLang';
import dataLayer from 'app/common/utils/dataLayer';
import { SideTabContainer } from 'app/settingsManagement/components/GoogleVerification/Fields/SideTabContainer.styled';
import { Title } from 'app/settingsManagement/components/GoogleVerification/Fields/Title.styled';
import { getCardByMethod } from 'app/settingsManagement/components/GoogleVerification/Options/OptionCard';
import { ConfirmationMethodInformationBusinessVideo } from 'app/settingsManagement/components/GoogleVerification/screens/ConfirmationScreen/ConfirmationMethodInformation';

import { ConfirmButton } from '../../Fields/ConfirmButton.styled';
import { FloatingFooter } from '../../Fields/FloatingFooter.styled';

type LangMap = {
    [key: string]: string;
};

export const getGoogleBusinessUrl = (lang: string): string => {
    const langMap: LangMap = {
        ar: 'ar_sa',
        ca: 'ca_es',
        cs: 'cs_cz',
        // yes we need a double // in en url...
        en: 'en/',
        es: 'es_es',
        de: 'de_de',
        fr: 'fr_fr',
        it: 'it_it',
        lv: 'lv_lv',
        nl: 'nl_nl',
        pl: 'pl_pl',
        pt: 'pt-PT_pt',
        'pt-BR': 'pt-BR_br',
        sl: 'sl_si',
    };

    const googleLang = langMap[lang];
    return googleLang
        ? `https://www.google.com/intl/${googleLang}/business/`
        : 'https://www.google.com/business/';
};

type BusinessVideoScreenProps = {
    location: V2FormattedLocationData | undefined;
};

export const BusinessVideoScreen = ({ location }: BusinessVideoScreenProps) => {
    const { t } = useTranslation();
    const lang = useLanguage();
    const { data: me } = useMe();

    const onClick = () => {
        dataLayer.pushDict('continue_to_google_verification', {
            user_id: me?.id,
            shadow_user_id: me?.shadow_user_id,
            location_id: location?.id,
            business_id: location?.partnerConnection?.owningBusinessId,
            verification_status: location?.googleVerifications?.status,
        });
        window.open(getGoogleBusinessUrl(lang), '_blank');
    };

    return (
        <SideTabContainer>
            <Title>{t('google_verification__options__title')}</Title>

            <>
                {getCardByMethod('BUSINESS_VIDEO', true, 'BUSINESS_VIDEO')}
                <ConfirmationMethodInformationBusinessVideo
                    location={location}
                />
                <FloatingFooter>
                    <ConfirmButton
                        dataTrackId="continue_to_google_verification"
                        size="large"
                        shape="cube"
                        variant="primary"
                        onClick={onClick}
                    >
                        {t(
                            'google_verification__methods__business_video_confirm_button',
                        )}
                    </ConfirmButton>
                </FloatingFooter>
            </>
        </SideTabContainer>
    );
};

export default BusinessVideoScreen;
