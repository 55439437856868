import React from 'react';

import useCustomFields from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useCustomFields';

import CustomFieldsEmptyPlaceholder from './CustomFieldsEmptyPlaceholder';
import { CustomFieldsList } from './CustomFieldsList';

export const CustomFieldsContent = () => {
    const customFields = useCustomFields();

    if (!customFields.length) {
        return <CustomFieldsEmptyPlaceholder />;
    }

    return <CustomFieldsList />;
};
