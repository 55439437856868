import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { MESSAGING_CONVERSATION_STATS } from 'app/common/data/queryKeysConstants';
import { NEW_CONVERSATION } from 'app/common/data/websocket';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import useWebsocket from 'app/common/hooks/useWebsocket';
import {
    AssignFilterOption,
    LiveConversationEventData,
    StatusFilterOption,
    URLParams,
} from 'app/states/messaging';
import queryClient from 'app/states/queryClient';

import { addConversation } from '../services/handleConversationsCache';
import { isBusinessIdInFilteredBusinesses } from '../services/isBusinessIdInFilteredBusinesses';

export const useHandleNewConversation = () => {
    const [statusFilter] = useStateQueryParams<StatusFilterOption>(URLParams.STATUS);
    const [assignFilter] = useStateQueryParams<AssignFilterOption>(URLParams.ASSIGN);
    const businessFilters = useBusinessModalFilters();

    return useWebsocket(
        NEW_CONVERSATION,
        (liveConversationEventData: LiveConversationEventData) => {
            if (
                isBusinessIdInFilteredBusinesses(
                    businessFilters,
                    liveConversationEventData.businessId,
                )
            ) {
                addConversation(
                    liveConversationEventData,
                    statusFilter,
                    assignFilter,
                    businessFilters,
                );
            }
            queryClient.invalidateQueries([MESSAGING_CONVERSATION_STATS, businessFilters]);
        },
        [businessFilters],
    );
};
