import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import Flexbox from 'app/styles/utils/flexbox';

import { ImageListType } from '../../context/NewPost';

type Props = {
    postImages: ImageListType;
};

const AutoCropBanner = ({ postImages }: Props) => {
    const { t } = useTranslation();
    const [mainPostImage, ...otherPostImages] = postImages;
    const otherCroppedCount = otherPostImages.filter(postImage => postImage.autoCropped).length;

    return (
        <Flexbox gap="8px" flexDirection="column">
            {mainPostImage?.autoCropped && (
                <Banner dataTrackId="post_main_photo_cropped" variant="info" withCloseIcon={false}>
                    {t('post_main_photo_cropped')}
                </Banner>
            )}
            {otherCroppedCount > 0 && (
                <Banner
                    dataTrackId="post_secondary_photo_cropped"
                    variant="info"
                    withCloseIcon={false}
                >
                    {t('post_secondary_photo_cropped')}
                </Banner>
            )}
        </Flexbox>
    );
};

export default AutoCropBanner;
