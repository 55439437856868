const FORBIDDEN_EVENT_NAMES = [
    // GA4 naming rules: https://support.google.com/analytics/answer/13316687?hl=en#zippy=%2Cweb%2Cmobile-android-and-ios
    'app_remove',
    'app_store_refund',
    'app_store_subscription_cancel',
    'app_store_subscription_renew',
    'click',
    'error',
    'file_download',
    'first_open',
    'first_visit',
    'form_start',
    'form_submit',
    'in_app_purchase',
    'page_view',
    'scroll',
    'session_start',
    'user_engagement',
    'view_complete',
    'video_progress',
    'video_start',
    'view_search_results',
];

const FORBIDDEN_PARAMS_NAMES = [
    'event', // to not override event name

    'engagement_time_msec',
    'gclid',
    'session_id',
    'session_number',
    'first_open_time',
    'first_visit_time',
    'last_deep_link_referrer',
    'user_id',
    'first_open_after_install',
];

const FORBIDDEN_STARTS_WITH = ['_', 'firebase_', 'ga_', 'google_', 'gtag_'];

const REGEX_KEY_RULE = /^[a-z][a-z0-9_]*$/;

const log = (...data: any[]): void => {
    console.warn('[GTM DATALAYER ⚠️]', ...data);
};

const checkEventName = (event: string): boolean => {
    if (!REGEX_KEY_RULE.test(event)) {
        log(
            `Invalid event name: ${event}, must be in snake_case and start with a letter`,
        );
        return false;
    }

    if (FORBIDDEN_EVENT_NAMES.includes(event)) {
        log(`Forbidden event name: ${event}`);
        return false;
    }

    return true;
};

const checkDictKeysNames = (dict: object): boolean => {
    for (const key in dict) {
        if (FORBIDDEN_PARAMS_NAMES.includes(key)) {
            log(`Forbidden parameter name: ${key}`);
            return false;
        }

        for (const startWith of FORBIDDEN_STARTS_WITH) {
            if (key.startsWith(startWith)) {
                log(
                    `Invalid parameter name: ${key}, must not start with ${startWith}`,
                );
                return false;
            }
        }

        if (!REGEX_KEY_RULE.test(key)) {
            log(
                `Invalid parameter name: ${key}, must be in snake_case and start with a letter`,
            );
            return false;
        }
    }

    return true;
};

const dataLayer = {
    /**
     * @param event name of the event, must be in snake_case and start with a letter
     * @param dict object of key/value pairs, keys must be in snake_case and start with a letter
     */
    pushDict: (event: string, dict: object = {}): void => {
        checkEventName(event);
        checkDictKeysNames(dict);

        const layer = window.dataLayer || [];
        layer.push({
            ...dict,
            event,
        });
    },
};

export default dataLayer;
