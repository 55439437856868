import { AppIcon, AppIconsValuesEnum } from '@partoohub/iconography';

import { useTranslation } from 'react-i18next';

import { STATIC_APP_ASSETS_URL } from 'app/config';
import {
    MESSAGING_STATS_PERIOD_MONTHS,
    useHomepageMessagingStats,
} from 'app/pages/Homepage/hooks/stats/statsItems/messages/useHomepageMessagingStats';
import { useIsLoadingMessagingStats } from 'app/pages/Homepage/hooks/stats/statsItems/messages/useIsLoadingMessagingStats';
import { DefaultStatComponent } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent';
import { SmallStatProps } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/DefaultStatComponent';
import {
    formatResponseTime,
    getResponseTimeTranslation,
} from 'app/pages/Homepage/utils/responseTimeFunctions';

import { StatMessagesChip } from './StatMessagesChip';

export const StatMessages = (props: SmallStatProps) => {
    const { t } = useTranslation();

    const { data } = useHomepageMessagingStats(MESSAGING_STATS_PERIOD_MONTHS, 0);
    const isLoading = useIsLoadingMessagingStats();

    const actualAverageResponseTime = data?.previousAverageResponseTime ?? 0;
    const noStats = actualAverageResponseTime === 0 || isNaN(actualAverageResponseTime);
    const formattedAverageResponseTime = formatResponseTime(actualAverageResponseTime);
    const { translationKey: responseTimeTranslationKey, ...units } = getResponseTimeTranslation(
        formattedAverageResponseTime,
    );

    return (
        <DefaultStatComponent
            {...props}
            productTitle={t('homepage_stats_messages_title')}
            statTitle={t(responseTimeTranslationKey, units)}
            statSubtitle={t('homepage_stats_messages_subtitle')}
            description={t('homepage_stats_messages_description')}
            productIcon={
                <AppIcon
                    dataTrackId="stat_message__app_icon"
                    name={AppIconsValuesEnum.GetClients}
                    width="24"
                    height="24"
                />
            }
            isLoading={isLoading}
            bannerUrl={`${STATIC_APP_ASSETS_URL}/homepage/MessagesBanner.svg`}
            displayEmptyPlaceholder={!isLoading && noStats}
            statChip={<StatMessagesChip />}
            dataTrack="click_homepage_stats_messages"
        />
    );
};
