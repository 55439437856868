import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';
import { AutomaticMessagesConfiguration } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/AutomaticMessagesConfiguration';
import { ConversationStartersContextProvider } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/contexts/ConversationStartersContext';

interface Props {
    show: boolean;
}

export const AutomaticMessagesConfigurationModal = ({ show }: Props) => (
    <ConversationStartersContextProvider>
        <FullScreenModal
            show={show}
            childrenLeft={<AutomaticMessagesConfiguration />}
            onHideClick={() => null}
        />
    </ConversationStartersContextProvider>
);
