import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

export const SectionCase = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-bottom: solid 1px ${greyColorObject.initial};
`;

export const SectionGlobal = styled.div`
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    margin-bottom: 24px;
`;

export const SectionHeader = styled(SectionCase)``;

export const SectionTitle = styled.div`
    display: flex;
    align-items: baseline;
    font-size: 18px;
    font-weight: bold;
    color: ${HEX_COLORS.blackberry};
`;

export const SectionPhotoCount = styled.div`
    float: right;
    font-weight: bold;
    font-size: 14px;
`;

export const SectionPhotoBody = styled(SectionCase)`
    padding: 32px 17px 0 27px;
    border-bottom: none;
`;
