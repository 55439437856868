import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import LocationCard from 'app/businessConnection/components/common/LocationCard/LocationCard';
import Clickable from 'app/common/components/buttons/Clickable';
import { SubTitle, Title } from 'app/common/components/funnel/FunnelModal.styled';
import { STATIC_BASE_URL } from 'app/config';

export const SelectionPageResultsContainer = styled.div<{
    hasSelected: boolean;
}>`
    width: 100%;

    ${({ hasSelected }) => hasSelected && 'margin-bottom: 100px;'}
`;

export const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    justify-content: space-between;
    row-gap: 8px;
    width: 100%;
    margin-bottom: 30px;
`;

export const CardContainer = styled.div`
    height: 94px;
    width: 49.5%;
`;

export const Card = styled(Clickable)<{
    isSelected: boolean;
    isServiceArea?: boolean;
    isDisabled: boolean;
}>`
    position: relative;
    border: 1px solid ${greyColorObject.initial};
    border-radius: 4px;
    align-items: center;
    background-color: white;
    height: 100%;
    width: 100%;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.2 : 1)};
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

    ${({ isSelected, theme }) =>
        isSelected &&
        css`
            border: 1px solid ${theme.colors.primary.initial};
            box-shadow: 0 0 0 1px ${theme.colors.primary.initial};

            &:before {
                position: absolute;

                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 40px 40px 0;

                top: 0;
                right: 0;
                opacity: 1;
                transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
                border-color: transparent ${theme.colors.primary.initial} transparent transparent;
                content: '';
            }

            &:after {
                position: absolute;
                top: 2px;
                right: 4px;
                content: url(${`${STATIC_BASE_URL}/images/app/funnel/icon-checked.svg`});
            }
        `};

    ${({ isSelected, isServiceArea, theme }) =>
        isSelected &&
        isServiceArea &&
        css`
            border: 1px solid ${theme.colors.slate.initial};
            box-shadow: 0 0 0 1px ${theme.colors.slate.initial};
            &:before {
                border-color: transparent ${theme.colors.slate.initial} transparent transparent;
            }

            &:after {
                top: 4px;
                content: url(${`${STATIC_BASE_URL}/images/app/funnel/service-area.svg`});
            }
        `};
`;

export const AdminLocationPartnerLogoBorderGradient = styled.div`
    position: absolute;
    z-index: 1;
    height: 66px;
    width: 66px;
    bottom: 11px;
    left: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: conic-gradient(
        from 262.41deg at 50% 50%,
        #0085f2 0deg,
        #15a2ff 61.87deg,
        #00eba9 129.37deg,
        #ffba01 191.25deg,
        #ff9600 241.88deg,
        #ad46ff 296.25deg,
        #8a78ff 360deg
    );
`;

export const AdminLocationLogoBorderGradient = styled.div`
    z-index: 2;
    position: absolute;
    height: 28px;
    width: 28px;
    bottom: 12%;
    left: 2%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: conic-gradient(
        from 262.41deg at 50% 50%,
        #0085f2 0deg,
        #15a2ff 61.87deg,
        #00eba9 129.37deg,
        #ffba01 191.25deg,
        #ff9600 241.88deg,
        #ad46ff 296.25deg,
        #8a78ff 360deg
    );
`;

export const AdminLocationLogoContainer = styled.div`
    height: 26px;
    width: 26px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AdminLocationLogo = styled.img`
    height: 21px;
    width: 21px;
`;

export const NoLocationContainer = styled.div`
    margin: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const NoLocationTitle = styled(Title)`
    width: 488px;
    font-size: 30px;
    line-height: normal;
    margin: 8px 0 8px;
`;

export const NoLocationSubtitle = styled(SubTitle)`
    margin-bottom: 16px;
`;

export const LoadingPlaceholder = styled.div`
    width: 100%;
    text-align: center;
`;

export const LocationCardStyled = styled(LocationCard)`
    position: relative;
    z-index: 2;
    border-color: transparent;
`;
