import { Button } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { NavLink } from 'react-router-dom';

import { V2UserData } from 'app/api/types/user';

import { UserStatus } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';
import { SETTINGS_MAIN_PROFILE_PATHNAME } from 'app/routing/routeIds';

import ActionsItem from './ActionsItem';
import {
    RowContainer,
    Status,
    UserActions,
    UserEmail,
    UserInfo,
    UserName,
    UserRole,
} from './UserRow.styled';

type Props = {
    user: V2UserData;
    displayActions: boolean;
    isUser?: boolean;
};

const UserRow = ({ user, displayActions, isUser = false }: Props) => {
    const { t } = useTranslation();

    const redirectionToProfile = SETTINGS_MAIN_PROFILE_PATHNAME;

    return (
        <RowContainer>
            <UserInfo>
                <UserName>
                    {user.first_name} {user.last_name}
                </UserName>
                <UserEmail>{user.email}</UserEmail>
            </UserInfo>

            <UserRole>{t(user.role)}</UserRole>

            <Status status={user.status}>
                {user.status === UserStatus.INVITED ? (
                    <>
                        <i className="fa-sharp fa-solid fa-arrows-rotate" />
                        {t('invited')}
                    </>
                ) : (
                    <>
                        <i className="fa-solid fa-circle-check" />
                        {t('activated')}
                    </>
                )}
            </Status>

            <UserActions>
                {!isUser && displayActions && <ActionsItem user={user} />}

                {isUser && (
                    <NavLink to={redirectionToProfile} style={{ textDecoration: 'none' }}>
                        <Button
                            size="small"
                            variant="secondary"
                            appearance="outlined"
                            shape="cube"
                            dataTrackId="my_profile_button"
                        >
                            {t('settings_profile')}
                        </Button>
                    </NavLink>
                )}
            </UserActions>
        </RowContainer>
    );
};

export default UserRow;
