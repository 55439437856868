import styled from '@emotion/styled';

import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

type EditSectionLoaderContainerProps = {
    height: number;
};

export const EditSectionLoaderContainer = styled.div<EditSectionLoaderContainerProps>`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${props => props.height}px;
    border: solid 1px ${DISABLED_COLOR};
    border-radius: 8px;

    > svg {
        width: 50px;
        height: 50px;
    }
`;
