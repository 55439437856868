import { Banner, Text } from '@partoohub/ui';
import parsePhoneNumber from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

import { WhatsappProfile } from 'app/api/types/whatsapp';

import { STATIC_APP_ASSETS_URL } from 'app/config';

import CopyButton from './CopyButton';
import QrcodeDownloadButton from './QrcodeDownloadButton';
import { WhatsappTips } from './Tips';
import {
    BannerContainer,
    BodyContainer,
    CartoucheText,
    CopyCartouche,
    DetailCard,
    DetailCardImage,
    DetailCardsContainer,
    DetailStack,
} from './WhatsappPhoneNumberSettings.styled';

interface WhatsappPhoneNumberSettingsProps {
    whatsappProfileData?: WhatsappProfile;
}

const WhatsappPhoneNumberSettings = ({ whatsappProfileData }: WhatsappPhoneNumberSettingsProps) => {
    const { t } = useTranslation();

    const phoneNumber = `+${whatsappProfileData!.whatsapp_phone_number.number}`;
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    const displayPhoneNumber = parsedPhoneNumber?.formatInternational() || phoneNumber;
    const link = `https://wa.me/${whatsappProfileData!.whatsapp_phone_number.number}`;
    const isStatusBlocked =
        !whatsappProfileData!.whatsapp_phone_number.status ||
        !['CONNECTED', 'PENDING', 'MIGRATED', 'RATE_LIMITED', 'FLAGGED'].includes(
            whatsappProfileData!.whatsapp_phone_number.status,
        );

    return (
        <BannerContainer>
            {isStatusBlocked && (
                <Banner variant="danger" dataTrackId={''} withCloseIcon={false}>
                    {t('whatsapp_profile_settings__status_blocked_banner')}
                </Banner>
            )}
            <BodyContainer>
                <DetailCardsContainer>
                    <DetailCard>
                        <DetailCardImage
                            src={`${STATIC_APP_ASSETS_URL}/whatsapp/profile_details_number.svg`}
                        />
                        <DetailStack>
                            <Text variant="bodyLSemibold">
                                {t('whatsapp_profile_settings__number_card_text')}
                            </Text>
                            <CopyCartouche>
                                <CartoucheText variant="bodyMRegular">
                                    {displayPhoneNumber}
                                </CartoucheText>
                                <CopyButton
                                    text={phoneNumber}
                                    tooltipText={t('whatsapp_account_list__copy_number')}
                                    gtmEvent="copy_wa_number"
                                />
                            </CopyCartouche>
                        </DetailStack>
                    </DetailCard>
                    <DetailCard>
                        <DetailCardImage
                            src={`${STATIC_APP_ASSETS_URL}/whatsapp/profile_details_link.svg`}
                        />
                        <DetailStack>
                            <Text variant="bodyLSemibold">
                                {t('whatsapp_profile_settings__link_card_text')}
                            </Text>
                            <CopyCartouche>
                                <CartoucheText variant="bodyMRegular">{link}</CartoucheText>
                                <CopyButton
                                    text={link}
                                    tooltipText={t('whatsapp_account_list__copy_link')}
                                    gtmEvent="copy_wa_link"
                                />
                            </CopyCartouche>
                        </DetailStack>
                    </DetailCard>
                    <DetailCard>
                        <DetailCardImage
                            src={`${STATIC_APP_ASSETS_URL}/whatsapp/profile_details_qr_code.svg`}
                        />
                        <DetailStack>
                            <Text variant="bodyLSemibold">
                                {t('whatsapp_profile_settings__qrcode_card_text')}
                            </Text>
                            <QrcodeDownloadButton link={link} />
                        </DetailStack>
                    </DetailCard>
                </DetailCardsContainer>

                <WhatsappTips />
            </BodyContainer>
        </BannerContainer>
    );
};

export default WhatsappPhoneNumberSettings;
