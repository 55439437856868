import { FontAwesomeIconsPartooUsed, LeftElementType, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { ApiKeysQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/utils/enums';

const ApiKeyListingSearch = () => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useStateQueryParams(ApiKeysQueryKeys.QUERY);

    const handleSearchReset = () => setSearchValue('');

    return (
        <TextInput
            dataTrackId="api_key_search_by_input"
            value={searchValue}
            onChange={setSearchValue}
            label={t('quick_action__modal__input_text')}
            leftElement={[FontAwesomeIconsPartooUsed.faSearch]}
            leftElementType={LeftElementType.Icon}
            isReset
            onReset={handleSearchReset}
        />
    );
};

export default ApiKeyListingSearch;
