import React from 'react';

import { useTranslation } from 'react-i18next';

import { MoreHoursType } from 'app/api/types/more_hours';

import {
    AddMoreHourButton,
    AddMoreHourContent,
    AddMoreHourDescription,
    AddMoreHourModalContainer,
    AddMoreHourTitle,
} from './AddMoreHourModal.styled';

type Props = {
    moreHours: MoreHoursType;
    onClick: (gmbId: string) => void;
};

const AddMoreHourModal = ({ moreHours, onClick }: Props) => {
    const { t } = useTranslation();

    return (
        <AddMoreHourModalContainer>
            <AddMoreHourTitle>
                {t('add_business_more_hours_modal_title')}
            </AddMoreHourTitle>
            <AddMoreHourDescription>
                {t('add_business_more_hours_modal_subtitle')}
            </AddMoreHourDescription>
            <AddMoreHourContent>
                {moreHours.map(moreHour => (
                    <AddMoreHourButton
                        key={`add_more_hour_${moreHour.gmb_id}`}
                        onClick={() => onClick(moreHour.gmb_id)}
                    >
                        <i className="fas fa-plus" />
                        {moreHour.name}
                    </AddMoreHourButton>
                ))}
            </AddMoreHourContent>
        </AddMoreHourModalContainer>
    );
};

export default AddMoreHourModal;
