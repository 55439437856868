import { Stack } from '@partoohub/ui';

import { EReputationCard, ReviewReplyCard, TagsCard } from './Cards';

export const CardsSection = () => {
    return (
        <Stack direction="row">
            <EReputationCard />
            <ReviewReplyCard />
            <TagsCard />
        </Stack>
    );
};
