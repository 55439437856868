import { addYears, getTime, isAfter, isBefore } from 'date-fns';

import { formatTZDate } from 'app/utils/dates';

export const hasEndDateIsAfterStartDate = (
    startDate: Date,
    startTime: string,
    endDate: Date,
    endTime: string,
): boolean => {
    if (!startDate || !startTime) {
        return true;
    }

    const startDateTime = formatTZDate(startDate, startTime);
    const endDateTime = formatTZDate(endDate ?? new Date(), endTime);
    return isAfter(endDateTime, startDateTime);
};

export const hasEndDateIsFuture = (endDate: Date, endTime: string) => {
    if (!endDate) {
        return true;
    }

    return isAfter(formatTZDate(endDate, endTime), new Date());
};

export const hasStartDateIsBefore1970 = (startDate: Date, startTime: string): boolean => {
    if (!startDate) {
        return true;
    }
    const startEpoch = getTime(formatTZDate(startDate, startTime)) / 1000;

    return startEpoch < 0;
};

export const hasEndDateIsWithinYear = (endDate: string): boolean => {
    const parsedEndDate = new Date(endDate);
    const nextYear = addYears(new Date(), 1);
    return isBefore(parsedEndDate, nextYear);
};
