import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import { BusinessCardLines, SmallBusinessCardContainer } from './SmallBusinessCard.styled';

const SmallBusinessLoadingCard = () => (
    <SmallBusinessCardContainer>
        <LoadingRectangle recMargin="8px 0 8px 0" recHeight="50px" recWidth="50px" />
        <BusinessCardLines>
            <LoadingRectangle recHeight="10px" recWidth="50%" recMargin="4px 0 4px 0" />
            <LoadingRectangle recHeight="10px" recWidth="70%" recMargin="4px 0 4px 0" />
            <LoadingRectangle recHeight="10px" recWidth="40%" recMargin="4px 0 4px 0" />
        </BusinessCardLines>
    </SmallBusinessCardContainer>
);

export default SmallBusinessLoadingCard;
