import useMetricsAnalytics from 'app/common/hooks/queries/presenceAnalytics/useMetricsAnalytics';
import { useMetricsQueryCount } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/metrics/count/useMetricsQueryCount';

export const useMetricsLoadingCount = () => {
    const { queryCurrent, queryPrevious } = useMetricsQueryCount();

    const { isLoading: isLoadingCurrent } = useMetricsAnalytics(queryCurrent);
    const { isLoading: isLoadingPrevious } = useMetricsAnalytics(queryPrevious);

    return isLoadingCurrent || isLoadingPrevious;
};
