import { HEX_COLORS } from '@partoohub/branding';

import { Color } from 'app/common/designSystem/constants/color';

type Props = {
    width?: number;
    height?: number;
    color?: Color;
};

const ImageErrorIcon = ({ width = 56, height = 56, color = HEX_COLORS.blackberry }: Props) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 57 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.1667 20.9998C14.744 20.9998 16.8333 18.9105 16.8333 16.3332C16.8333 13.7558 14.744 11.6665 12.1667 11.6665C9.58934 11.6665 7.5 13.7558 7.5 16.3332C7.5 18.9105 9.58934 20.9998 12.1667 20.9998Z"
            fill={color}
        />
        <path
            d="M22.6667 40.8333C22.6667 31.738 29.2093 24.1523 37.8333 22.5073V7C37.8333 3.14067 34.6927 0 30.8333 0H7.5C3.64067 0 0.5 3.14067 0.5 7V39.6667C0.5 43.526 3.64067 46.6667 7.5 46.6667H23.614C23.0073 44.828 22.6667 42.8727 22.6667 40.8333ZM11.56 28.42C10.02 26.88 7.31333 26.88 5.77333 28.42L5.16667 29.0243V7C5.16667 5.712 6.212 4.66667 7.5 4.66667H30.8333C32.1213 4.66667 33.1667 5.712 33.1667 7V22.0243L31.3933 20.251C29.7973 18.655 27.2003 18.655 25.6067 20.251L14.5 31.3577L11.56 28.42Z"
            fill={color}
        />
        <path
            d="M52.0572 30.1092C49.1918 27.2462 45.3838 25.6665 41.3332 25.6665C37.2825 25.6665 33.4745 27.2462 30.6092 30.1092C27.7438 32.9722 26.1665 36.7825 26.1665 40.8332C26.1665 44.8838 27.7462 48.6918 30.6092 51.5572C33.4722 54.4225 37.2825 55.9998 41.3332 55.9998C45.3838 55.9998 49.1918 54.4225 52.0572 51.5572C54.9225 48.6918 56.4998 44.8838 56.4998 40.8332C56.4998 36.7825 54.9202 32.9745 52.0572 30.1092ZM47.5188 43.7195C48.4312 44.6318 48.4312 46.1065 47.5188 47.0188C47.0638 47.4738 46.4665 47.7025 45.8692 47.7025C45.2718 47.7025 44.6745 47.4738 44.2195 47.0188L41.3332 44.1325L38.4468 47.0188C37.9918 47.4738 37.3945 47.7025 36.7972 47.7025C36.1998 47.7025 35.6025 47.4738 35.1475 47.0188C34.2352 46.1065 34.2352 44.6318 35.1475 43.7195L38.0338 40.8332L35.1475 37.9468C34.2352 37.0345 34.2352 35.5598 35.1475 34.6475C36.0598 33.7352 37.5345 33.7352 38.4468 34.6475L41.3332 37.5338L44.2195 34.6475C45.1318 33.7352 46.6065 33.7352 47.5188 34.6475C48.4312 35.5598 48.4312 37.0345 47.5188 37.9468L44.6325 40.8332L47.5188 43.7195Z"
            fill={color}
        />
    </svg>
);

export default ImageErrorIcon;
