import { IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import useTotalBusinessesCount from 'app/common/components/businessModal/hooks/useTotalBusinessesCount';
import { PBox } from 'app/common/components/PBox/components/PBox';

import AnalyticsBoxTextBody from './AnalyticsBoxTextBody';
import RatingsFooter from './RatingsFooter';

type Props = {
    totalRatings: number;
    periodRatings: number;
    inputPeriod: boolean;
    isSmall: boolean;
    leftLoading: boolean;
    rightLoading: boolean;
};

const Ratings = ({
    totalRatings,
    periodRatings,
    inputPeriod,
    isSmall,
    leftLoading,
    rightLoading,
}: Props) => {
    const { t } = useTranslation();
    const total = useTotalBusinessesCount() ?? 0;
    const businessCount = useSelectedBusinessesCount();

    return (
        <PBox
            title={t('my_reviews')}
            icon={['fa-comments', IconPrefix.SOLID]}
            content={
                <AnalyticsBoxTextBody
                    // @ts-ignore
                    leftValue={periodRatings}
                    // @ts-ignore
                    rightValue={totalRatings}
                    leftValueText={
                        inputPeriod ? 'period_reviews' : 'last_six_months'
                    }
                    rightValueText="total_my_reviews"
                    isSmall={isSmall}
                    leftLoading={leftLoading}
                    rightLoading={rightLoading}
                />
            }
            boxLegend={<RatingsFooter businessCount={businessCount || total} />}
        />
    );
};

export default Ratings;
