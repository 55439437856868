import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

export const AttributeValue = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const AttributeTypeRepeatableUrlContainer = styled.div`
    margin-bottom: 24px;
`;

export const AttributeTypeRepeatableUrlName = styled(Text)`
    margin-bottom: 8px;
`;

export const SubSection = styled.div`
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    padding: 20px 16px;
`;

export const SubSectionContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
