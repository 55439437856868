import { useEffect, useRef } from 'react';

import { Stack } from '@partoohub/ui';
import { format } from 'date-fns';
import qs from 'query-string';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { ReviewObjectType } from 'app/api/types/review';
import LoadingView from 'app/common/components/LoadingView';
import NoBusiness from 'app/common/components/placeholder/NoBusiness.container';
import { NoReviewManagement } from 'app/common/components/placeholder/reviewMangement/NoReviewManagement';
import { UNSEEN_REVIEWS_COUNT } from 'app/common/data/queryKeysConstants';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import {
    CEOrgFeatures,
    useHasCEOrgFeatures,
} from 'app/pages/customerExperience/settings/useHasCEOrgFeatures';
import useMeUpdate from 'app/pages/settingsV2/subPages/Main/components/ProfileSettings/hooks/useMeUpdate';
import { ReviewsQueryContext } from 'app/reviewManagement/reviewList/hooks/useReviewsQueryContext';
import { LIVE_REVIEWS, NOT_TREATED_REVIEWS, navTabSelector } from 'app/states/reviews/filters';

import { GetParamsReduxSync } from './GetParamsReduxSync';
import { useGetReviews } from './hooks/useGetReviews';
import { useReviewListIsLoading } from './hooks/useReviewListIsLoading';
import { StyledReviewList } from './ReviewListWithNewSidebar.styled';
import {
    BannerSection,
    FilterSection,
    ReviewCount,
    ReviewListModals,
    ReviewTableSection,
} from './sections';
import { ReviewListModalsProvider } from './sections/ReviewListModals/ReviewListModals.context';

export const ReviewListWithNewSidebar = () => {
    const location = useLocation();
    const queryClient = useQueryClient();
    const { data: meData } = useMe();
    const { mutate: updateMe } = useMeUpdate();
    const reviewsQueryData = useGetReviews();
    const { reviews } = reviewsQueryData;

    const { hasBusinesses } = useBusinessSubscriptions();
    const { orgFeatures, isLoading: isOrgFeaturesLoading } = useHasCEOrgFeatures();

    const navTab = navTabSelector();

    const { review: reviewParam } = qs.parse(location.search);

    const latestReviewDateSoFarRef = useRef<number | null>(null);

    const lastReviewDate =
        reviews.find(review => review.reviewObjectType === ReviewObjectType.REVIEW)?.date ?? null;
    const latestReviewDateSoFar = latestReviewDateSoFarRef.current;

    useEffect(() => {
        if (
            lastReviewDate !== null &&
            [LIVE_REVIEWS, NOT_TREATED_REVIEWS].includes(navTab) &&
            !reviewParam &&
            (latestReviewDateSoFar === null || latestReviewDateSoFar < lastReviewDate)
        ) {
            if (meData && meData.shadow_user_role !== 'ADMIN') {
                updateMe({
                    rm_last_seen_date: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
                });
            }
            queryClient.setQueriesData([UNSEEN_REVIEWS_COUNT], 0);
            latestReviewDateSoFarRef.current = lastReviewDate;
        }
    }, [navTab, reviewParam, lastReviewDate]);

    const pageIsLoading = useReviewListIsLoading();

    if (pageIsLoading || isOrgFeaturesLoading) {
        return <LoadingView />;
    }

    if (!hasBusinesses) {
        return <NoBusiness />;
    }

    if (![CEOrgFeatures.RM, CEOrgFeatures.FeedbackManagement].some(p => Boolean(orgFeatures[p]))) {
        return <NoReviewManagement />;
    }

    return (
        <ReviewsQueryContext.Provider value={reviewsQueryData}>
            <ReviewListModalsProvider>
                {/* Keep this for now: this allows the date filter and resetting from NoReviewsFound button to work */}
                <GetParamsReduxSync />
                <StyledReviewList>
                    <ReviewListModals />
                    <Stack direction="column">
                        <BannerSection />
                        <FilterSection />
                        <ReviewCount />
                    </Stack>

                    <ReviewTableSection />
                </StyledReviewList>
            </ReviewListModalsProvider>
        </ReviewsQueryContext.Provider>
    );
};
