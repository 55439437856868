import { endOfDay, parseISO, startOfDay } from 'date-fns';

import {
    CursorPaginationParams,
    LiteralRating,
    ReviewObjectType,
    ReviewQueryParams,
} from 'app/api/types/review';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { WITHOUT_TAG, ratingToRange, ratingToRecommendation } from 'app/states/reviews';
import {
    API_NOT_TREATED_REVIEWS,
    DELETED_REVIEWS,
    LIVE_REVIEWS,
    NOT_TREATED_REVIEWS,
    PENDING_AUTO_REPLY_REVIEWS,
    TREATED_AUTO_REPLY_REVIEWS,
    TREATED_REVIEWS,
} from 'app/states/reviews/filters';

import { useGetReviewsFilters } from './useGetReviewsFilters';

export const stateToQueryReviewParam = (
    reviewState: string,
    reviewObjectType: ReviewObjectType,
) => {
    const stateQueryParams: Record<string, Record<ReviewObjectType, Array<string>>> = {
        [LIVE_REVIEWS]: {
            [ReviewObjectType.FEEDBACK_RESULT]: [TREATED_REVIEWS, API_NOT_TREATED_REVIEWS],
            [ReviewObjectType.REVIEW]: [
                TREATED_REVIEWS,
                API_NOT_TREATED_REVIEWS,
                TREATED_AUTO_REPLY_REVIEWS,
                PENDING_AUTO_REPLY_REVIEWS,
            ],
        },
        [TREATED_REVIEWS]: {
            [ReviewObjectType.FEEDBACK_RESULT]: [TREATED_REVIEWS],
            [ReviewObjectType.REVIEW]: [
                TREATED_REVIEWS,
                TREATED_AUTO_REPLY_REVIEWS,
                PENDING_AUTO_REPLY_REVIEWS,
            ],
        },
        [NOT_TREATED_REVIEWS]: {
            [ReviewObjectType.FEEDBACK_RESULT]: [API_NOT_TREATED_REVIEWS],
            [ReviewObjectType.REVIEW]: [API_NOT_TREATED_REVIEWS],
        },
        [DELETED_REVIEWS]: {
            [ReviewObjectType.FEEDBACK_RESULT]: [DELETED_REVIEWS],
            [ReviewObjectType.REVIEW]: [DELETED_REVIEWS],
        },
    };

    return stateQueryParams[reviewState][reviewObjectType] ?? [reviewState];
};

export const REVIEW_LIST_PAGE_SIZE = 30;

export const useGetReviewsQueryParams = (): ReviewQueryParams & CursorPaginationParams => {
    const reviewFilters = useGetReviewsFilters();
    const businessModalFilters = useBusinessModalFilters();
    const { dateFrom, dateTo, search, rating, platforms, status, comments, tags } = reviewFilters;

    const withoutTag = tags.split(',').includes(`${WITHOUT_TAG}`);

    return {
        update_date__gte: dateFrom ? startOfDay(parseISO(dateFrom)).toISOString() : null,
        update_date__lte: dateTo ? endOfDay(parseISO(dateTo)).toISOString() : null,
        content__isnull: comments ? comments !== 'with' : null,
        gmb_rating__in: rating ? ratingToRange(rating.split(',') as LiteralRating[]) : null,
        tripadvisor_rating__in: rating ? ratingToRange(rating.split(',') as LiteralRating[]) : null,
        recommended__in: rating
            ? ratingToRecommendation(rating.split(',') as LiteralRating[])
            : null,
        partner__in: platforms || null,
        state__in: status ? stateToQueryReviewParam(status, ReviewObjectType.REVIEW) : null,
        keywords: search || null,
        tags: withoutTag ? `${WITHOUT_TAG}` : tags || null,
        without_tag: withoutTag,
        ...businessModalFilters,
        per_page: REVIEW_LIST_PAGE_SIZE,
    };
};
