import { IconButton } from '@partoohub/ui';

import { STATIC_BASE_URL } from 'app/config';
import { hideableComponentsNames, withHideableComponent } from 'app/SDKBridge';

type Props = {
    collapseSidebar?: () => void;
};

const PageHeaderSideBarButton = ({ collapseSidebar }: Props) => (
    <IconButton
        dataTrackId="menu-burger__icon_button"
        icon={<img src={`${STATIC_BASE_URL}/images/app/icons/menu.svg`} alt="" />}
        appearance="outlined"
        onClick={collapseSidebar}
    />
);

export default withHideableComponent(PageHeaderSideBarButton, hideableComponentsNames.APP_SIDEBAR);
