import {
    Checkbox,
    FontAwesomeIconsPartooUsed,
    Icon,
    IconPrefix,
    Stack,
    Text,
    Tooltip,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { ImportFieldSection, ImportFieldSectionTop } from './SimpleImportFieldSection.styled';

interface Props {
    name: string;
    onChange: (value: boolean) => void;
    isChecked: boolean;
    // Optional props
    description?: string;
    isFilled?: boolean;
    disabled?: boolean;
}

export const SimpleImportFieldSection = ({
    name,
    onChange,
    isChecked,
    description,
    isFilled,
    disabled,
}: Props) => {
    const { t } = useTranslation();
    return (
        <ImportFieldSection>
            <ImportFieldSectionTop>
                <Stack gap="4px" justifyContent="center" direction="column" style={{ flex: 1 }}>
                    <Stack direction="row" alignItems="center">
                        <Text variant="heading6" disabled={disabled}>
                            {name}
                        </Text>
                        {isFilled && isChecked && (
                            <Tooltip text={t('data_will_be_overwritten_by_google')}>
                                <Icon
                                    icon={[
                                        FontAwesomeIconsPartooUsed.faCircleExclamation,
                                        IconPrefix.SOLID,
                                    ]}
                                    color="warning"
                                />
                            </Tooltip>
                        )}
                    </Stack>
                    {description && (
                        <Stack gap="4px" alignItems="center" direction="row">
                            <Icon
                                icon={[FontAwesomeIconsPartooUsed.faCircleInfo, IconPrefix.SOLID]}
                                color="secondary"
                                iconSize="12px"
                            />
                            <Text variant="bodyMBold" as="span" color="secondary">
                                {description}test
                            </Text>
                        </Stack>
                    )}
                </Stack>
                <Tooltip text={disabled ? t('import_disabled') : ''}>
                    <Checkbox
                        dataTrackId="simple_import_field__checkbox"
                        aria-label=""
                        checked={isChecked}
                        onChange={() => onChange(!isChecked)}
                        disabled={disabled}
                    />
                </Tooltip>
            </ImportFieldSectionTop>
        </ImportFieldSection>
    );
};
