import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const BUSINESS_EDIT_BREAKPOINT = 'min-width: 1180px';

export const CenterLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    button {
        width: 100%;
        justify-content: center;
        margin: 16px auto 0 auto;
        max-width: 330px;
    }
`;

export const BusinessEditV2Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1100px;
`;

type CompletionBadgeAnimationType = {
    from?: number;
    to?: number;
    rotatePath?: boolean;
};

const keyframeGrow = (from: number, to: number) => keyframes`
    0% {
        transform: scale(${from}%);
    }

    50% {
        transform: scale(${to}%);
    }

    100% {
        transform: scale(${from}%);
    }
`;

export const CompletionBadgeAnimation = ({
    from = 100,
    to = 120,
    rotatePath = true,
}: CompletionBadgeAnimationType) => css`
    @keyframes completionBadgeRotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }

    animation: ${keyframeGrow(from, to)} 2s linear infinite;

    ${rotatePath &&
    css`
        > path:first-of-type {
            animation: completionBadgeRotation 8s infinite linear;
            transform-origin: center;
        }
    `}
`;
