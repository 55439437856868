import React from 'react';

import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import EditSection from 'app/businessEditV2/components/EditSection';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import UnsavedModal from 'app/businessEditV2/components/UnsavedModal';
import useDisplayCustomFields from 'app/businessEditV2/hooks/editV2utils/displaySections/useDisplayCustomFields';
import { useIsLoadingCustomFields } from 'app/businessEditV2/hooks/editV2utils/isLoadingSections/useIsLoadingCustomFields';
import useCustomFieldsSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useCustomFieldsSectionColor';
import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import { CustomFieldFormWithSections } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldFormWithSections';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import CustomFieldsSectionContent from './CustomFieldsSectionContent';
import { CustomFieldsSectionProvider } from './hooks/useCustomFieldsDisabledSaveButton';

const CustomFieldsSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const {
        showModal,
        setShowModal,
        closeSafeModal,
        showUnsavedModal,
        setShowUnsavedModal,
        useDirtyUpdate,
    } = useUnsavedModal(SectionNames.CUSTOM_FIELDS);

    const displayCustomFields = useDisplayCustomFields();
    const sectionColor = useCustomFieldsSectionColor();
    const { t } = useTranslation();

    const isLoading = useIsLoadingCustomFields();

    if (isLoading) {
        return <EditSectionLoader />;
    }

    if (!displayCustomFields) {
        return null;
    }

    return (
        <div
            ref={ref}
            data-track="visibility_location__section_custom_fields"
            data-intercom-target="visibility_location__section_custom_fields"
        >
            <EditSection
                title={t('custom_fields')}
                description={<CustomFieldsSectionContent colorState={sectionColor} />}
                onClick={() => setShowModal(true)}
                colorState={sectionColor}
                sectionName={SectionNames.CUSTOM_FIELDS}
            />
            <FullScreenModal
                show={showModal}
                childrenRight={
                    <EditFormDescription
                        title={t('business_edit_title_custom_fields')}
                        description={t('business_edit_description_custom_fields')}
                    />
                }
                childrenLeft={
                    <CustomFieldsSectionProvider>
                        <CustomFieldFormWithSections
                            closeModal={() => setShowModal(false)}
                            useDirtyUpdate={useDirtyUpdate}
                        />
                        <UnsavedModal
                            show={showUnsavedModal}
                            cancel={() => setShowUnsavedModal(false)}
                            validate={() => setShowModal(false)}
                        />
                    </CustomFieldsSectionProvider>
                }
                onHideClick={closeSafeModal}
            />
        </div>
    );
});

export default CustomFieldsSection;
