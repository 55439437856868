import UnsavedChangesModal from 'app/common/components/UnsavedChangesModal/UnsavedChangesModal';
import useNavigationBlocker from 'app/common/hooks/useNavigationBlocker';

type Props = {
    when: boolean;
};

export default function ({ when }: Props) {
    const { blocker, confirmNavigation, rejectNavigation } =
        useNavigationBlocker({ when });

    return (
        <UnsavedChangesModal
            show={blocker}
            onConfirm={confirmNavigation}
            onHide={rejectNavigation}
        />
    );
}
