import { isEqual } from 'lodash-es';

import {
    LOGOUT_RESET_REDUCERS_DATA,
    LogoutResetReducersDataAction,
} from './login';

// ACTION TYPES
const UPDATE_INVITATION_PASSWORD = 'UPDATE_INVITATION_PASSWORD';
const UPDATE_INVITATION_PASSWORD_CONFIRMATION =
    'UPDATE_INVITATION_PASSWORD_CONFIRMATION';
const DISPLAY_PASSWORD_CHECKER = 'DISPLAY_PASSWORD_CHECKER';
export const COMPLETE_INVITATION_REQUEST = 'COMPLETE_INVITATION_REQUEST';
const COMPLETE_INVITATION_REQUEST_FAILURE =
    'COMPLETE_INVITATION_REQUEST_FAILURE';
const COMPLETE_INVITATION_REQUEST_SUCCESS =
    'COMPLETE_INVITATION_REQUEST_SUCCESS';
// ACTION FLOW TYPES
export type UpdateInvitationPasswordAction = {
    type: 'UPDATE_INVITATION_PASSWORD';
    password: string;
};
export type UpdateInvitationPasswordConfirmationAction = {
    type: 'UPDATE_INVITATION_PASSWORD_CONFIRMATION';
    password: string;
};
type DisplayPasswordCheckerAction = {
    type: 'DISPLAY_PASSWORD_CHECKER';
    displayed: boolean;
};
export type CompleteInvitationRequestAction = {
    type: 'COMPLETE_INVITATION_REQUEST';
};
type CompleteInvitationRequestFailureAction = {
    type: 'COMPLETE_INVITATION_REQUEST_FAILURE';
    errors: Record<string, any>;
};
type CompleteInvitationRequestSuccessAction = {
    type: 'COMPLETE_INVITATION_REQUEST_SUCCESS';
};
export const updateInvitationPassword = (
    password: string,
): UpdateInvitationPasswordAction => ({
    type: UPDATE_INVITATION_PASSWORD,
    password,
});
export const updateInvitationPasswordConfirmation = (
    password: string,
): UpdateInvitationPasswordConfirmationAction => ({
    type: UPDATE_INVITATION_PASSWORD_CONFIRMATION,
    password,
});
export const displayPasswordChecker = (
    displayed: boolean,
): DisplayPasswordCheckerAction => ({
    type: DISPLAY_PASSWORD_CHECKER,
    displayed,
});
export const completeInvitationRequest =
    (): CompleteInvitationRequestAction => ({
        type: COMPLETE_INVITATION_REQUEST,
    });
export const completeInvitationRequestFailure = (
    errors: Record<string, any>,
): CompleteInvitationRequestFailureAction => ({
    type: COMPLETE_INVITATION_REQUEST_FAILURE,
    errors,
});
export const completeInvitationRequestSuccess =
    (): CompleteInvitationRequestSuccessAction => ({
        type: COMPLETE_INVITATION_REQUEST_SUCCESS,
    });
export type InvitationState = {
    password: string;
    passwordConfirmation: string;
    errors: Record<string, any>;
    isSubmitting: boolean;
    isSuccess: boolean;
    displayPasswordChecks: boolean;
};
type InvitationAction =
    | UpdateInvitationPasswordAction
    | UpdateInvitationPasswordConfirmationAction
    | CompleteInvitationRequestAction
    | CompleteInvitationRequestFailureAction
    | CompleteInvitationRequestSuccessAction
    | DisplayPasswordCheckerAction
    | LogoutResetReducersDataAction;
const initialState = {
    passwordConfirmation: '',
    password: '',
    errors: {},
    isSubmitting: false,
    isSuccess: false,
    displayPasswordChecks: false,
};

const invitationReducer = (
    state: InvitationState = initialState,
    action: InvitationAction,
): InvitationState => {
    switch (action.type) {
        case UPDATE_INVITATION_PASSWORD:
            return { ...state, password: action.password };

        case UPDATE_INVITATION_PASSWORD_CONFIRMATION:
            return { ...state, passwordConfirmation: action.password };

        case DISPLAY_PASSWORD_CHECKER:
            return { ...state, displayPasswordChecks: action.displayed };

        case COMPLETE_INVITATION_REQUEST:
            return { ...state, isSubmitting: true, errors: {} };

        case COMPLETE_INVITATION_REQUEST_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                errors: { ...action.errors },
            };

        case COMPLETE_INVITATION_REQUEST_SUCCESS:
            return { ...state, isSubmitting: false, isSuccess: true };

        case LOGOUT_RESET_REDUCERS_DATA:
            return { ...initialState };

        default:
            return state;
    }
};

export const passwordConfirmationSelector = ({
    passwordConfirmation,
}: InvitationState): string => passwordConfirmation;
export const passwordSelector = ({ password }: InvitationState): string =>
    password;
export const isSubmittingSelector = ({
    isSubmitting,
}: InvitationState): boolean => isSubmitting;
export const isSuccessSelector = ({ isSuccess }: InvitationState): boolean =>
    isSuccess;

/* eslint-disable camelcase */
export const passwordConfirmationErrorSelector = ({
    errors: { password_confirmation },
}: InvitationState): string | void => password_confirmation;
export const passwordChecksErrorSelector = ({
    errors: { password_rules },
}: InvitationState): Array<string> => password_rules;

/* eslint-enable camelcase */
export const passwordErrorSelector = ({
    errors: { password },
}: InvitationState): string | void => password;
export const globalErrorSelector = ({
    errors: { global },
}: InvitationState): string | void => global;
export const ErrorSelector = ({
    errors,
}: InvitationState): Record<string, any> => errors;
export const hasErrors = ({ errors }: InvitationState): boolean =>
    !isEqual({}, errors);
export const displayCheckerSelector = ({
    displayPasswordChecks,
}: InvitationState): boolean => displayPasswordChecks;
export default invitationReducer;
