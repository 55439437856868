import { IconElement, IconPrefix } from '@partoohub/ui';

import {
    AttributeMetadataType,
    BusinessAttributesType,
    attributesEnumType,
} from 'app/api/types/attributes';

export const ATTRIBUTE_URL_FACEBOOK = 'url_facebook';
export const ATTRIBUTE_URL_TWITTER = 'url_twitter';
const ATTRIBUTE_URL_YOUTUBE = 'url_youtube';
const ATTRIBUTE_URL_INSTAGRAM = 'url_instagram';
const ATTRIBUTE_URL_TIKTOK = 'url_tiktok';
const ATTRIBUTE_URL_PINTEREST = 'url_pinterest';
const ATTRIBUTE_URL_LINKEDIN = 'url_linkedin';

// Facebook and Twitter are not included in this list because they are handled differently
export const ATTRIBUTES_SOCIAL_MEDIA = [
    ATTRIBUTE_URL_YOUTUBE,
    ATTRIBUTE_URL_INSTAGRAM,
    ATTRIBUTE_URL_TIKTOK,
    ATTRIBUTE_URL_PINTEREST,
    ATTRIBUTE_URL_LINKEDIN,
];

export const ATTRIBUTES_URL_TYPES = [attributesEnumType.URL, attributesEnumType.REPEATABLE_URL];

export const isMissingAllAttributesUrls = (
    businessAttributes: BusinessAttributesType,
    attributesUrls: AttributeMetadataType[],
) =>
    attributesUrls.length === 0 ||
    businessAttributes.filter(
        attribute => ATTRIBUTES_URL_TYPES.includes(attribute.type) && attribute.value !== null,
    ).length === 0;

export const attributesUrlsIcons: Record<string, IconElement> = {
    [ATTRIBUTE_URL_YOUTUBE]: ['fa-youtube', IconPrefix.BRANDS],
    [ATTRIBUTE_URL_INSTAGRAM]: ['fa-instagram', IconPrefix.BRANDS],
    [ATTRIBUTE_URL_TIKTOK]: ['fa-tiktok', IconPrefix.BRANDS],
    [ATTRIBUTE_URL_PINTEREST]: ['fa-pinterest', IconPrefix.BRANDS],
    [ATTRIBUTE_URL_LINKEDIN]: ['fa-linkedin', IconPrefix.BRANDS],
};
