import HourField from './HourField';
import { HourFieldWrapper, HourFieldsContainer } from './HourField.styled';

type Props = {
    windowNumber: number;
    updateWindowValue: (
        windowNumber: number,
        windowValue: { startHour: string; endHour: string },
    ) => void;
    startHour: string;
    endHour: string;
    disabled: boolean;
    hasError?: boolean;
};

const HourWindow = ({
    windowNumber,
    startHour,
    endHour,
    disabled,
    hasError = false,
    updateWindowValue,
}: Props) => (
    <HourFieldsContainer>
        <HourFieldWrapper>
            <HourField
                disabled={disabled}
                placeholder="09:00"
                value={startHour}
                hasError={hasError}
                onChange={(value: string) => {
                    updateWindowValue(windowNumber, {
                        startHour: value,
                        endHour,
                    });
                }}
            />
        </HourFieldWrapper>

        <div>—</div>

        <HourFieldWrapper>
            <HourField
                disabled={disabled}
                placeholder="19:00"
                value={endHour}
                hasError={hasError}
                onChange={(value: string) => {
                    updateWindowValue(windowNumber, {
                        startHour,
                        endHour: value,
                    });
                }}
            />
        </HourFieldWrapper>
    </HourFieldsContainer>
);

export default HourWindow;
