import React, { ReactNode } from 'react';

import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import WarningImage from './images/WarningImage';
import { StatusScreenImageWrapper, StatusScreenWrapper } from './StatusScreens.styled';
import { ConfirmButton } from '../../Fields/ConfirmButton.styled';
import { FloatingFooter } from '../../Fields/FloatingFooter.styled';

type Props = {
    handleClose: () => void;
    warningMessage?: string;
};

type WarningScreenProps = {
    title: string;
    content: ReactNode;
    handleClose: () => void;
};

const WarningScreen: React.FC<WarningScreenProps> = ({ title, content, handleClose }) => {
    const { t } = useTranslation();

    return (
        <>
            <StatusScreenWrapper>
                <StatusScreenImageWrapper>
                    <WarningImage />
                </StatusScreenImageWrapper>
                <Text variant="heading5">{title}</Text>
                {content}
            </StatusScreenWrapper>
            <FloatingFooter>
                <ConfirmButton
                    dataTrackId="google_verification__methods__warning_confirm_button"
                    size="large"
                    shape="cube"
                    variant="warning"
                    onClick={handleClose}
                >
                    {t('google_verification__status_screen__okay')}
                </ConfirmButton>
            </FloatingFooter>
        </>
    );
};

export const NoMethodScreen: React.FC<Props> = ({ handleClose }) => {
    const { t } = useTranslation();

    return (
        <WarningScreen
            title={t('google_verification__warning_screen__no_method_title')}
            content={
                <Text variant="bodyMRegular">
                    {t('google_verification__warning_screen__no_method_content__first_line')}
                    <br />
                    {t('google_verification__warning_screen__no_method_content__second_line')}
                    <a href="https://business.google.com" target="_blank">
                        <Text as="span" variant="bodyMRegular" color="#0085F2">
                            {' '}
                            Google Business Profile
                        </Text>
                    </a>
                </Text>
            }
            handleClose={handleClose}
        />
    );
};

export const AlreadyInReviewScreen: React.FC<Props> = ({ warningMessage, handleClose }) => {
    const { t } = useTranslation();

    return (
        <WarningScreen
            title={t('google_verification__error_screen__title')}
            content={
                <>
                    {warningMessage && (
                        <Text variant="bodyMRegular" italic>
                            {t(`google_verification__message_${warningMessage}`)}
                        </Text>
                    )}
                </>
            }
            handleClose={handleClose}
        />
    );
};
