import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const FiltersContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Filters = styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 500px;
    height: 50px;
    margin-bottom: auto;
    .multiple_select__placeholder {
        font-weight: bold;
        color: black;
    }
    > *:not(:last-child) {
        margin-right: 15px;
    }
`;

export const Counter = styled.div`
    color: ${HEX_COLORS.blackberry};
    font-weight: bold;
`;
