import styled from '@emotion/styled';

import { MAX_TABLET_WIDTH, MIN_TABLET_WIDTH } from 'app/hooks/useDeviceType';

export const PostsTableStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;
    grid-auto-rows: auto;

    @media (min-width: ${MIN_TABLET_WIDTH}px) and (max-width: ${MAX_TABLET_WIDTH}px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: ${MAX_TABLET_WIDTH}px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;
