import { Chart, ChartType } from '@partoohub/ui';

export const ReplyTimeGraph = () => {
    return (
        <Chart
            dataTrackId=""
            type={ChartType.Bar}
            data={{
                datasets: [
                    {
                        label: 'Manuel',
                        data: [100, 356, 678, 546, 1000, 123, 1509],
                        backgroundColor: 'rgba(0, 133, 242, 0.55)',
                    },
                    {
                        label: 'Modèles',
                        data: [1034, 112, 678, 246, 560, 4324, 3939],
                        backgroundColor: 'rgba(0, 186, 255, 0.6)',
                    },
                    {
                        label: 'Automatique',
                        data: [431, 656, 239, 123, 111, 562, 1202],
                        backgroundColor: 'rgba(64, 219, 162, 0.8)',
                    },
                    {
                        label: 'IA',
                        data: [323, 656, 678, 246, 334, 111, 402],
                        backgroundColor: 'rgba(138, 93, 242, 0.55)',
                    },
                ],
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            }}
        />
    );
};
