import styled from '@emotion/styled';
import { IconButton, MenuList, Text } from '@partoohub/ui';

export const StyledReviewCardMenu = styled.div`
    position: relative;
`;

export const CommentMenuButton = styled(IconButton)`
    width: 20px;
    height: 20px;
    margin-left: 6px;
`;

export const StyledMenu = styled(MenuList)`
    min-width: 112px;
    padding: 8px 0;
    top: 0;
    left: 8px;
`;

export const StyledMenuContainer = styled.div`
    position: absolute;
    z-index: 1;
`;

export const StyledMenuLabel = styled(Text)`
    padding: 4px 8px;
`;
