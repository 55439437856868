import React from 'react';

import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import HourWindow from 'app/businessEditV2/sections/OpenHoursSection/OpenHoursForm/TimeSlotReferenceModal/HoursFieldTimeslot/HourWindow';

import { FlexContainer } from './HourField.styled';

export type Props = {
    startHour: string;
    endHour: string;
    isFirst: boolean;
    windowNumber: number;
    addWindow: () => void;
    removeWindow: (windowNumber?: number) => void;
    disabled: boolean;
    hasError: boolean;
    updateWindowValue: (
        windowNumber: number,
        windowValue: { startHour: string; endHour: string },
    ) => void;
};

const HourWindowWithButton = ({
    startHour,
    endHour,
    isFirst,
    windowNumber,
    addWindow,
    removeWindow,
    disabled,
    hasError,
    updateWindowValue,
}: Props) => {
    const { t } = useTranslation();

    return (
        <FlexContainer>
            <HourWindow
                startHour={startHour}
                endHour={endHour}
                windowNumber={windowNumber}
                disabled={disabled}
                hasError={hasError}
                updateWindowValue={updateWindowValue}
            />
            {!disabled &&
                (isFirst ? (
                    <IconButton
                        appearance="outlined"
                        variant="primary"
                        onClick={addWindow}
                        icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                        tooltip={t('add_a_time_slot')}
                        dataTrackId="add_time_slot_button"
                    />
                ) : (
                    <IconButton
                        appearance="outlined"
                        variant="danger"
                        onClick={() => removeWindow(windowNumber)}
                        icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                        dataTrackId="remove_time_slot_button"
                    />
                ))}
        </FlexContainer>
    );
};

export default HourWindowWithButton;
