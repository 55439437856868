import { useMemo } from 'react';

import { numberFormatter } from '../services/formatNumber';
import { useLanguage } from '../services/getLang';

export const useFormatNumber = (value: number, humanize = false): string => {
    const locale = useLanguage() ?? 'en-GB';

    return useMemo(() => numberFormatter(value, locale, humanize), [value, humanize]);
};
