import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

type MenuPhotoDeleteClickableProps = {
    photoUrl: string;
};

type MenuPhotoDeleteProps = {
    height: string;
};

export const MenuPhotosImagesWrapper = styled.div`
    margin: -16px -8px 16px -8px;
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const MenuPhotosImageWrapper = styled.div`
    height: 206px;
    width: 206px;
    min-width: 206px;
    margin-right: 16px;
`;

export const MenuPhotosCarousel = styled.div`
    overflow-x: hidden;
    flex-grow: 1;
`;

export const MenuPhotosDeleteClickable = styled.div`
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-right: 16px;
    height: 206px;
    width: 206px;
    min-width: 206px;
    border: 1px solid #dfe3ec;
    background-image: ${({ photoUrl }: MenuPhotoDeleteClickableProps) => `url(${photoUrl})`};

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: black;
        opacity: 0.1;
        transition: all ease-in-out 0.3s;
        z-index: 0;
    }

    &:hover::before {
        opacity: 0.7;
    }
`;

export const MenuPhotosDelete = styled.div`
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 1em;
    width: 100%;
    height: ${({ height }: MenuPhotoDeleteProps) => `${height}`};

    &:hover {
        color: darkgray;
        opacity: 1;
        transition: all ease-in-out 0.3s;
    }
`;

export const MenuPhotoDeleteIconButton = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: ${HEX_COLORS.white};
    color: ${({ theme }) => theme.colors.danger.initial};
    cursor: pointer;
    border-radius: 100px 100px 100px 100px;
`;

export const MenuPhotoSlide = styled.div`
    height: 206px;
    width: 100%;
    display: flex;
`;
