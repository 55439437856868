export const MAX_FILE_SIZE = 5_000_000;

export const ACCEPTED_FILES = {
    ['image/png']: ['.png'],
    ['image/jpeg']: ['.jpg', '.jpeg'],
};

export enum ImagesUploaderErrorEnum {
    MAX_LEN = 'does_not_respect_the_constraint_max_len',
}
export interface ImagesUploaderError {
    index: number;
    message: ImagesUploaderErrorEnum;
    data?: any;
}

export const formatSizeUnits = (bytes: number) => {
    if (bytes >= 1_000_000_000) {
        return (bytes / 1_000_000_000).toFixed(2) + ' Gb';
    } else if (bytes >= 1_000_000) {
        return (bytes / 1_000_000).toFixed(2) + ' Mb';
    } else if (bytes >= 1_000) {
        return (bytes / 1_000).toFixed(2) + ' Kb';
    } else if (bytes > 1) {
        return bytes + ' bytes';
    } else if (bytes == 1) {
        return bytes + ' byte';
    } else {
        return '0 bytes';
    }
};

export const preventCardOpen = (event: any) => {
    event.stopPropagation();
};

export const getErrors = (errors: ImagesUploaderError[], imageIndex: number) => {
    return errors.filter(err => err.index === imageIndex);
};
