import styled from '@emotion/styled';
import { Button, Icon, Text } from '@partoohub/ui';

export const OverrideText = styled(Text)`
    padding-bottom: 16px;
`;

export const ExplanationDiv = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #f6f8fc;
`;

export const ExplanationRowDiv = styled.div`
    display: flex;
    gap: 8px;
`;

export const OverrideIcon = styled(Icon)`
    display: flex;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;
export const OverrideButton = styled(Button)`
    display: flex;
    gap: 4px;
    border: 1px solid rgba(20, 37, 66, 0.12);
`;
