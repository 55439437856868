import React from 'react';

import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import EditSectionFullHeight from 'app/businessEditV2/components/EditSectionFullHeight';
import { FULL_HEIGHT_SECTION } from 'app/businessEditV2/components/EditSectionFullHeight/EditSectionFullHeight.styled';
import EditSectionLoader from 'app/businessEditV2/components/EditSectionLoader';
import UnsavedModal from 'app/businessEditV2/components/UnsavedModal';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useOpenHoursSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useOpenHoursSectionColor';
import useUnsavedModal from 'app/businessEditV2/hooks/editV2utils/useUnsavedModal';
import { trackSelectBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';

import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import OpenHoursForm from './OpenHoursForm';
import OpenHoursPreview from './OpenHoursPreview';

const HoursSection = React.forwardRef<HTMLDivElement>((_, ref) => {
    const {
        showModal,
        setShowModal,
        closeSafeModal,
        showUnsavedModal,
        setShowUnsavedModal,
        useDirtyUpdate,
    } = useUnsavedModal(SectionNames.OPEN_HOURS);

    const sectionColor = useOpenHoursSectionColor();
    const { t } = useTranslation();
    const { isLoading } = useBusiness();

    if (isLoading) {
        return <EditSectionLoader height={FULL_HEIGHT_SECTION} />;
    }

    const onClick = () => {
        setShowModal(true);
        trackSelectBusinessEditSection(SectionNames.OPEN_HOURS, sectionColor);
    };

    return (
        <div
            ref={ref}
            data-track="visibility_location__section_hours"
            data-intercom-target="visibility_location__section_hours"
        >
            <EditSectionFullHeight
                onClick={onClick}
                sectionColor={sectionColor}
            >
                <OpenHoursPreview sectionColor={sectionColor} />
            </EditSectionFullHeight>

            <FullScreenModal
                show={showModal}
                childrenRight={
                    <EditFormDescription
                        title={t('business_edit_title_hours')}
                        description={t('business_edit_description_hours')}
                        advice={t('business_edit_advice_hours')}
                    />
                }
                childrenLeft={
                    <>
                        <OpenHoursForm
                            closeModal={() => setShowModal(false)}
                            useDirtyUpdate={useDirtyUpdate}
                        />
                        <UnsavedModal
                            show={showUnsavedModal}
                            cancel={() => setShowUnsavedModal(false)}
                            validate={() => setShowModal(false)}
                        />
                    </>
                }
                onHideClick={closeSafeModal}
            />
        </div>
    );
});

export default HoursSection;
