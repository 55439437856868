import useBusiness from 'app/reviewManagement/reviewList/hooks/useBusiness';

export default function (businessId: string): string | null {
    const business = useBusiness(businessId);
    if (business) {
        return `${business.name} - ${business.addressFull}, ${business.zipcode} ${business.city}`;
    }

    return null;
}
