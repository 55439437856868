import { Button } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import GoogleIcon from 'app/common/components/icons/provider/GoogleLogo';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import dataLayer from 'app/common/utils/dataLayer';
import { BusinessStatusLogo } from 'app/pages/visibility/BusinessListV2/sections/BusinessListSection/BusinessCard/BusinessCard.styled';
import { SETTINGS_PLATFORMS_GOOGLE_VERIFICATION_PATHNAME } from 'app/routing/routeIds';
import { hideableComponentsNames, withHideableComponent } from 'app/SDKBridge';

interface Props {
    eventName: string;
    location: any;
}

const VerificationRequiredButton = ({ eventName, location }: Props) => {
    const navigate = useNavigate();
    const { data: me } = useMe();
    const { data: business } = useBusiness();
    const { t } = useTranslation();

    const googleVerificationLogo = (
        <BusinessStatusLogo>
            <GoogleIcon />
        </BusinessStatusLogo>
    );

    const handleClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (location) {
            dataLayer.pushDict(eventName, {
                user_id: me?.id,
                shadow_user_id: me?.shadow_user_id,
                business_id: business?.id,
                location_id: location.id,
            });

            navigate(
                `${SETTINGS_PLATFORMS_GOOGLE_VERIFICATION_PATHNAME}?location_id=${
                    location.id ?? ''
                }&query=${encodeURIComponent(
                    `${location.location_name ?? ''} ${location.locality ?? ''}`,
                )}`,
            );
        }
    };

    return (
        <Button
            appearance="outlined"
            onClick={handleClick}
            shape="cube"
            size="medium"
            variant="danger"
            icon={googleVerificationLogo}
            dataTrackId={eventName}
        >
            {t('google_verification_location_status_unverified')}
        </Button>
    );
};

export default withHideableComponent(
    VerificationRequiredButton,
    hideableComponentsNames.VERIFICATION_REQUIRED_BUTTON,
);
