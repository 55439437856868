import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { ApiKeysPage } from 'app/pages/settings/ApiKeyManager';
import { useIsApiKeysPageEnabled } from 'app/pages/settingsV2/hooks/Integrations/pagesEnabled/useIsApiKeysPageEnabled';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';

import { ApiKeysFiltersContainer } from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/ApiKeys.styled';
import ApiKeyListingSearch from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/sections/ApiKeyListingSearch';
import Filters from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/sections/ApiKeysFilters/Filters';
import ApiKeysHeader from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/sections/ApiKeysHeader';
import {
    ApiKeyStatusEnum,
    ApiKeyTypeEnum,
    ApiKeysQueryKeys,
} from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/utils/enums';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

export const ApiKeys = () => {
    const navigate = useNavigate();
    const isApiKeysPageEnabled = useIsApiKeysPageEnabled();

    const [status] = useStateQueryParams(ApiKeysQueryKeys.STATUS);
    const [type] = useStateQueryParams(ApiKeysQueryKeys.TYPE);

    const isApiKeyType = (type: string | (string | null)[] | null): boolean => {
        return typeof type === 'string' ? Object.values(ApiKeyTypeEnum).includes(type) : false;
    };
    const isApiKeyStatus = (status: string | (string | null)[] | null): boolean => {
        return typeof status === 'string'
            ? Object.values(ApiKeyStatusEnum).includes(status)
            : false;
    };

    useEffect(() => {
        if (!isApiKeysPageEnabled && !isApiKeyType(type) && !isApiKeyStatus(status)) {
            navigate(NOT_FOUND_PATH);
        }
    }, []);

    return (
        <MenuListContentTemplate headerContent={<ApiKeysHeader />}>
            <MenuListContentContainer>
                <ApiKeysFiltersContainer>
                    <ApiKeyListingSearch />
                    <Filters />
                </ApiKeysFiltersContainer>

                <ApiKeysPage />
            </MenuListContentContainer>
        </MenuListContentTemplate>
    );
};
