import { ReportUserPreferencesResponse } from 'app/api/types/user';

import { NotificationsFormState } from './types';

export const reportUserPreferencesToFormState = (
    preferences: ReportUserPreferencesResponse,
    hasPartners: { hasTripAdvisor: boolean; hasFacebook: boolean; hasGoogle: boolean },
): NotificationsFormState => {
    const { hasTripAdvisor, hasFacebook, hasGoogle } = hasPartners;

    return {
        receiveReviewsEmailNotifications: preferences.receive_negative_reviews_email_notifications,
        reports: {
            selectRmDailyReport: preferences.reports.review_management_daily,
            selectRmWeeklyReport: preferences.reports.review_management_weekly,
            selectRmMonthlyReport: preferences.reports.review_management_monthly,
            dailyReportPartners: {
                google:
                    preferences.reports.daily_review_preferences?.partners.includes(
                        'google_my_business',
                    ) ?? hasGoogle,
                facebook:
                    preferences.reports.daily_review_preferences?.partners.includes('facebook') ??
                    hasFacebook,
                tripAdvisor:
                    preferences.reports.daily_review_preferences?.partners.includes(
                        'tripadvisor',
                    ) ?? hasTripAdvisor,
            },
            reviewType: preferences.reports.daily_review_preferences?.review_type ?? 2,
        },
    };
};
