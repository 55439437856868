import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Clickable from 'app/common/components/buttons/Clickable';

export const StyledChip = styled.div`
    display: flex;
    flex-flow: row nowrap;
    flex: 1;
`;

export const StyledClickable = styled(Clickable)<{ active?: boolean }>`
    display: flex;
    border-radius: 4px;
    border: solid 1px ${({ theme }) => theme.colors.primary.initial};
    justify-content: center;
    flex-flow: row nowrap;
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
    height: 20px;
    padding: 3px 8px 3px 8px;

    @media (min-width: 769px) {
        min-width: 160px;
    }

    div {
        i {
            display: flex;
            align-self: center;
        }
    }

    ${({ active, theme }) =>
        active
            ? css`
                  display: flex;
                  color: white;
                  background-color: ${theme.colors.primary.initial};
              `
            : css`
                  display: flex;
                  color: ${theme.colors.primary.initial};
              `};
`;
