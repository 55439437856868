import { BusinessFieldsType } from 'app/api/types/business';
import { isBusinessFieldDisabled } from 'app/businessEditV2/oldFromEditV1/services/utils';

const getBusinessFieldsDisabled = (businessFields: BusinessFieldsType) => {
    const photosDisabled =
        isBusinessFieldDisabled(businessFields, 'photos', 'cover') ||
        isBusinessFieldDisabled(businessFields, 'photos', 'logo') ||
        isBusinessFieldDisabled(businessFields, 'photos', 'secondary');
    const attributesDisabled = isBusinessFieldDisabled(businessFields, 'attributes', 'attributes');
    const infosDisabled = {
        name: isBusinessFieldDisabled(businessFields, 'infos', 'name'),
        code: isBusinessFieldDisabled(businessFields, 'advanced_settings', 'code'),
        categories: isBusinessFieldDisabled(businessFields, 'infos', 'categories'),
        coordinates: isBusinessFieldDisabled(businessFields, 'infos', 'coordinates'),
        openHours: isBusinessFieldDisabled(businessFields, 'hours', 'open_hours'),
        moreHours: isBusinessFieldDisabled(businessFields, 'hours', 'more_hours'),
        specificHours: isBusinessFieldDisabled(businessFields, 'hours', 'specific_hours'),
        openingDate: isBusinessFieldDisabled(businessFields, 'advanced_settings', 'status'),
        description: isBusinessFieldDisabled(businessFields, 'description', 'description_short'),
        phone: isBusinessFieldDisabled(businessFields, 'contact', 'phone_number'),
        website: isBusinessFieldDisabled(businessFields, 'links', 'website_url'),
        additionalUrls: isBusinessFieldDisabled(businessFields, 'links', 'attributes_url'),
        addressFull: isBusinessFieldDisabled(businessFields, 'infos', 'address_full'),
    };
    const servicesDisabled = isBusinessFieldDisabled(businessFields, 'services', 'services');
    const foodMenuDisabled = isBusinessFieldDisabled(businessFields, 'food_menu', 'food_menu');
    return {
        photos: photosDisabled,
        attributes: attributesDisabled,
        businessInfos: infosDisabled,
        services: servicesDisabled,
        foodMenu: foodMenuDisabled,
    };
};

export default getBusinessFieldsDisabled;
