import useBusinessAttributes from 'app/businessEditV2/hooks/attributes/attributesBusiness/useBusinessAttributes';
import { ATTRIBUTES_URL_TYPES } from 'app/businessEditV2/utils/attributes';

export default function useFormattedBusinessAttributes() {
    const { data: businessAttributes } = useBusinessAttributes();

    return (
        businessAttributes?.attributes.filter(attribute => {
            return !ATTRIBUTES_URL_TYPES.includes(attribute.type);
        }) ?? []
    );
}
