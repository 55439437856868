import React, { FC } from 'react';

import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';
import {
    Body,
    BodyComponent,
    BodyContent,
    BodyDescription,
    BodyLabel,
    BodyMain,
    BodyOptionalElements,
} from 'app/common/designSystem/components/molecules/SectionContainer/SectionBody.styled';
import {
    SectionFooter,
    SectionFooterContent,
    SectionGlobal,
    SectionHeader,
    SectionTitle,
} from 'app/common/designSystem/components/molecules/SectionContainer/SectionContainer.styled';

const LoadingMenuSection: FC = () => (
    <SectionGlobal>
        <SectionHeader>
            <SectionTitle>
                <LoadingRectangle
                    recWidth="200px"
                    recHeight="18px"
                    recRadius="5%"
                />
            </SectionTitle>
        </SectionHeader>

        <LoadingMenuItem />
        <LoadingMenuItem />

        <SectionFooter>
            <SectionFooterContent>
                <LoadingRectangle
                    recWidth="200px"
                    recHeight="18px"
                    recRadius="5%"
                />
            </SectionFooterContent>
        </SectionFooter>
    </SectionGlobal>
);

const LoadingMenuItem: FC = () => (
    <Body>
        <BodyContent>
            <BodyMain>
                <BodyLabel>
                    <LoadingRectangle
                        recWidth="300px"
                        recHeight="14px"
                        recRadius="5%"
                        recMargin="4px 0"
                    />
                </BodyLabel>
                <BodyDescription>
                    <LoadingRectangle
                        recWidth="100px"
                        recHeight="10px"
                        recRadius="5%"
                        recMargin="4px 0"
                    />
                </BodyDescription>
                <BodyDescription>
                    <LoadingRectangle
                        recWidth="600px"
                        recHeight="14px"
                        recRadius="5%"
                        recMargin="4px 0"
                    />
                </BodyDescription>
            </BodyMain>
            <BodyOptionalElements>
                <BodyComponent>
                    <LoadingRectangle
                        recWidth="24px"
                        recHeight="24px"
                        recRadius="100%"
                        recMargin="24px 0 24px 0"
                    />
                </BodyComponent>
            </BodyOptionalElements>
        </BodyContent>
    </Body>
);

export default LoadingMenuSection;
