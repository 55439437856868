import React, { ReactElement, useCallback, useRef, useState } from 'react';

import useResizeObserver from '@react-hook/resize-observer';

import {
    ArrowLeft,
    ArrowRight,
    GradientLeft,
    GradientRight,
    ScrollContainer,
    ScrollElement,
} from 'app/common/designSystem/components/molecules/HorizontalScroller/HorizontalScroller.styled';

type Props = {
    children: ReactElement | ReactElement[];
    scrollStep?: number;
};

const getScrollRange = (
    scrollStep: number,
    containerWidth: number,
    isEdge: boolean,
): number => {
    const nonVisibleWidth = isEdge
        ? NONVISIBLE_EDGE_WIDTH
        : 2 * NONVISIBLE_EDGE_WIDTH;
    return scrollStep && containerWidth - nonVisibleWidth >= scrollStep
        ? Math.trunc((containerWidth - nonVisibleWidth) / scrollStep) *
              scrollStep
        : containerWidth - nonVisibleWidth;
};

const NONVISIBLE_EDGE_WIDTH = 48;

export default function ({ children, scrollStep = 0 }: Props) {
    const ref = useRef(null);
    const [scrollWidth, setScrollWidth] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    // Whether to use arrows of regular scrollbar
    const [useArrow, setUseArrow] = useState(true);

    const onRightScroll = useCallback(() => {
        const isEdge = scrollLeft <= 0;
        setScrollLeft(
            Math.min(
                scrollWidth - containerWidth,
                scrollLeft +
                    getScrollRange(scrollStep, containerWidth, isEdge) -
                    (isEdge ? NONVISIBLE_EDGE_WIDTH : 0),
            ),
        );
    }, [scrollWidth, scrollStep, containerWidth, scrollLeft]);

    const onLeftScroll = useCallback(() => {
        const isEdge = containerWidth + scrollLeft >= scrollWidth;
        setScrollLeft(
            Math.max(
                0,
                scrollLeft -
                    getScrollRange(scrollStep, containerWidth, isEdge) +
                    (isEdge ? NONVISIBLE_EDGE_WIDTH : 0),
            ),
        );
    }, [scrollWidth, scrollStep, containerWidth, scrollLeft]);

    useResizeObserver(ref, entry => {
        const { width } = entry.contentRect;
        const containerWidth = Math.round(width);

        // Arrows/Gradient are on top of the scroll content,
        // creating a non visible area of NONVISIBLE_EDGE_WIDTH on each side of the container
        // We want to make sure that there is enough content visible
        // (at least the same amount visible as not visible, so 4 times NONVISIBLE_EDGE_WIDTH)
        // so the scroll does not look sketchy
        setUseArrow(containerWidth >= 4 * NONVISIBLE_EDGE_WIDTH);

        setContainerWidth(Math.round(width));
        if (scrollLeft === 0) {
            setScrollWidth(Math.round(entry.target.scrollWidth));
        }
    });

    return (
        <ScrollContainer ref={ref}>
            <ScrollElement scrollLeft={scrollLeft} useArrow={useArrow}>
                {children}
            </ScrollElement>

            {useArrow && scrollLeft > 0 && (
                <>
                    <GradientLeft />
                    <ArrowLeft onClick={onLeftScroll}>
                        <i className={'fas fa-chevron-left'} />
                    </ArrowLeft>
                </>
            )}

            {useArrow && containerWidth + scrollLeft < scrollWidth && (
                <>
                    <GradientRight />
                    <ArrowRight onClick={onRightScroll}>
                        <i className={'fas fa-chevron-right'} />
                    </ArrowRight>
                </>
            )}
        </ScrollContainer>
    );
}
