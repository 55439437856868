import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useAddressSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useAddressSectionColor';
import useAdvancedSettingsSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useAdvancedSettingsSectionColor';
import useAttributesSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useAttributesSectionColor';
import useCategoriesSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useCategoriesSectionColor';
import useContactSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useContactSectionColor';
import useCoverSectionColors from 'app/businessEditV2/hooks/editV2utils/sectionColors/useCoverSectionColors';
import useCustomFieldsSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useCustomFieldsSectionColor';
import useDescriptionSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useDescriptionSectionColor';
import useLinksSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useLinksSectionColor';
import useLogoSectionColors from 'app/businessEditV2/hooks/editV2utils/sectionColors/useLogoSectionColors';
import useMenuSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useMenuSectionColor';
import useMoreHoursSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useMoreHoursSectionColor';
import useOpenHoursSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useOpenHoursSectionColor';
import usePhotosSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/usePhotosSectionColor';
import useServicesSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useServicesSectionColor';
import useSpecificHoursSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useSpecificHoursSectionColor';

export default function useBusinessProblemNumber(): number {
    return [
        useAddressSectionColor(),
        useAdvancedSettingsSectionColor(),
        useAttributesSectionColor(),
        useCategoriesSectionColor(),
        useContactSectionColor(),
        useCoverSectionColors(),
        useCustomFieldsSectionColor(),
        useDescriptionSectionColor(),
        useLinksSectionColor(),
        useLogoSectionColors(),
        useMenuSectionColor(),
        useMoreHoursSectionColor(),
        useOpenHoursSectionColor(),
        usePhotosSectionColor(),
        useServicesSectionColor(),
        useSpecificHoursSectionColor(),
    ].filter(sectionColor =>
        [ColorState.MISSING_WARNING, ColorState.PRECHECK_ERRORS].includes(
            sectionColor,
        ),
    ).length;
}
