import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TransparencyEnum } from '@partoohub/branding';
import { List } from '@partoohub/ui';

export const PublisherRowContainer = styled(List.Row)<{ disabled?: boolean }>`
    cursor: pointer;
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: ${TransparencyEnum.DISABLED};
            pointer-events: none;
        `}
`;
