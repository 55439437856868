import { Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import {
    DoughnutLegendBodyStyled,
    DoughnutLegendColorStyled,
    DoughnutLegendStyled,
} from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/AverageGrade/DoughnutLegend.styled';

const NoReviewPlaceHolder = () => {
    const { t } = useTranslation();
    return (
        <DoughnutLegendStyled>
            <DoughnutLegendBodyStyled>
                <DoughnutLegendColorStyled className="bg-light" />
                <Text variant="bodyMBold" as="span" color="secondary">
                    {t('no_reviews_found')}
                </Text>
            </DoughnutLegendBodyStyled>
        </DoughnutLegendStyled>
    );
};

export default NoReviewPlaceHolder;
