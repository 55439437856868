import { useTranslation } from 'react-i18next';

import LoadingView from 'app/common/components/LoadingView';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import usePermissionsPageLogicContent from 'app/pages/settingsV2/subPages/Team/components/Permissions/hooks/usePermissionsPageLogicContent';
import PermissionsPageContent from 'app/pages/settingsV2/subPages/Team/components/Permissions/sections/PermissionsPageContent';
import PermissionsSaveButton from 'app/pages/settingsV2/subPages/Team/components/Permissions/sections/PermissionsSaveButton';

export const Permissions = () => {
    const { t } = useTranslation();

    // Custom hook that handles all the logic for the permissions page
    const { isLoading, form, updatePermissions } = usePermissionsPageLogicContent();

    if (isLoading) {
        return <LoadingView />;
    }

    return (
        <MenuListContentTemplate
            headerContent={
                <DefaultMenuListHeader
                    title={t('business_fields_title')}
                    description={t('business_fields_description')}
                />
            }
            footerContent={
                <PermissionsSaveButton form={form} updatePermissions={updatePermissions} />
            }
        >
            <PermissionsPageContent form={form} />
        </MenuListContentTemplate>
    );
};
