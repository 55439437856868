import { ReviewObjectType } from 'app/api/types/review';

import { CEOrgFeatures, useHasCEOrgFeatures } from './settings/useHasCEOrgFeatures';
import { CEUserProducts, useHasCEUserProducts } from './settings/useHasCEUserProducts';

export const useCanShowClientReviewCards = (): {
    canShowClientReviewCards: Record<ReviewObjectType, boolean | undefined>;
    isLoading: boolean;
} => {
    const { orgFeatures, isLoading: orgFeatureLoading } = useHasCEOrgFeatures();
    const { userProducts, isLoading: userProductsLoading } = useHasCEUserProducts();

    return {
        canShowClientReviewCards: {
            [ReviewObjectType.REVIEW]:
                orgFeatures[CEOrgFeatures.RM] && userProducts[CEUserProducts.RM],
            [ReviewObjectType.FEEDBACK_RESULT]:
                orgFeatures[CEOrgFeatures.FeedbackManagement] && userProducts[CEUserProducts.FBM],
        },
        isLoading: orgFeatureLoading || userProductsLoading,
    };
};
