import { useTranslation } from 'react-i18next';

import { Navigate } from 'react-router-dom';

import LoadingView from 'app/common/components/LoadingView';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';
import { DeviceType, useDeviceType } from 'app/hooks';
import { useHideIntercom } from 'app/hooks/useHideIntercom';
import { useIsInvoicesPageEnabled } from 'app/pages/settingsV2/hooks/Integrations/pagesEnabled/useIsInvoicesPageEnabled';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { useVisibilityContext } from 'app/pages/settingsV2/subComponents/DesktopHeader/DesktopHeader.context';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';
import { MenuListContentContainer } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate.styled';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';
import Invoice from 'app/settingsManagement/components/Invoice/Invoice';

export const Invoices = () => {
    const { t } = useTranslation();
    const { data: me, isLoading: isLoadingMe } = useMe();
    const { isLoading: isLoadingOrg } = useOrganization(me?.org_id ?? 0, !!me?.org_id);
    const { isHeaderVisible } = useVisibilityContext();
    const isInvoicesPageEnabled = useIsInvoicesPageEnabled();
    const { device } = useDeviceType();
    const isMobile = device === DeviceType.Mobile || device === DeviceType.MobileApp;

    useHideIntercom(isMobile);

    if (isLoadingMe || isLoadingOrg) {
        return <LoadingView />;
    }

    if (!isInvoicesPageEnabled) {
        return <Navigate replace to={NOT_FOUND_PATH} />;
    }

    return (
        <MenuListContentTemplate
            headerContent={isHeaderVisible && <DefaultMenuListHeader title={t('billing')} />}
        >
            <MenuListContentContainer>
                <Invoice />
            </MenuListContentContainer>
        </MenuListContentTemplate>
    );
};
