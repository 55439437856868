import { SET_RECEIVED_GOOGLE_POST } from 'app/states/presence/googlePost/googlePostList/actions/constants';
import { SetReceivedGooglePostType } from 'app/states/presence/googlePost/googlePostList/actions/types';
import { GooglePostListState } from 'app/states/presence/googlePost/googlePostList/reducers/types';

export const initialState = {
    isFetching: false,
    isForbidden: false,
    byId: {},
    ids: [],
    page: 0,
    maxPage: Infinity,
    errors: {},
};

export const googlePostListReducer = (
    state: GooglePostListState = initialState,
    action: SetReceivedGooglePostType,
): GooglePostListState => {
    if (action.type === SET_RECEIVED_GOOGLE_POST) {
        return {
            ...state,
            byId: {
                ...state.byId,
                [action.postId]: action.receivedGooglePost,
            },
            ids: [...state.ids, action.postId],
        };
    }
    return state;
};
