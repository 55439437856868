import React from 'react';

import { HOUR_SEPARATOR } from 'app/businessEditV2/oldFromEditV1/data/constants';

import HourWindowWithButton, {
    Props,
} from './HoursFieldTimeslot/HourWindowWithButton';

type WrapperProps = {
    value: Array<string>;
    onChange: (value: Array<string>) => void;
};

type OmitProps =
    | 'startHour'
    | 'endHour'
    | 'isFirst'
    | 'windowNumber'
    | 't'
    | 'addWindow'
    | 'removeWindow'
    | 'updateWindowValue';

const HourWindowWithButtonWrapper = ({
    value,
    onChange,
    ...props
}: WrapperProps & Omit<Props, OmitProps>) => {
    // If there is no time slot reference set for the business yet
    if (value?.length === 0) {
        value = [''];
    }

    const transformedValue = value.map(stringWindow => ({
        startHour: stringWindow.length
            ? stringWindow.split(HOUR_SEPARATOR)[0]
            : '',
        endHour: stringWindow.length
            ? stringWindow.split(HOUR_SEPARATOR)[1]
            : '',
    }));

    // Callbacks for UI actions (add, remove, update)
    const addWindow = () => {
        value.push('');
        onChange(value);
    };
    const removeWindow = (index: number) => {
        value.splice(index, 1);
        onChange(value);
    };
    const updateWindow = (index: number, { startHour, endHour }) => {
        value[index] = `${startHour}-${endHour}`;
        onChange(value);
    };

    return (
        <>
            {transformedValue.map((timeSlot, index) => (
                <HourWindowWithButton
                    {...props}
                    startHour={timeSlot.startHour}
                    endHour={timeSlot.endHour}
                    isFirst={index === 0}
                    windowNumber={index}
                    addWindow={addWindow}
                    removeWindow={removeWindow}
                    updateWindowValue={updateWindow}
                    key={index}
                />
            ))}
        </>
    );
};

export default HourWindowWithButtonWrapper;
