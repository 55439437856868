import UserDeleteModal from './modals/UserDeleteModal';
import UserEditModal from './modals/UserEditModal';
import UserReinviteAllModal from './modals/UserReinviteAllModal';
import UserReinviteModal from './modals/UserReinviteModal';

const UserManagementModals = () => {
    return (
        <>
            <UserEditModal />
            <UserDeleteModal />
            <UserReinviteModal />
            <UserReinviteAllModal />
        </>
    );
};

export default UserManagementModals;
