import { useEffect, useState } from 'react';

import { Dialog, Modal } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import useLocalStorage from 'app/common/hooks/useLocalStorage';
import dataLayer from 'app/common/utils/dataLayer';
import { STATIC_APP_ASSETS_URL } from 'app/config';
import { SETTINGS_MESSAGES_WHATSAPP_PATHNAME } from 'app/routing/routeIds';

import {
    ButtonContainer,
    Content,
    ContentButton,
    ContentText,
    TextContainer,
} from './WhatsappAvailableModal.styled';

type Props = {
    showWhatsappAvailable: boolean;
};

const WhatsappAvailableModal = ({ showWhatsappAvailable }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [timestamp, setNextDateToShowModal] = useLocalStorage<Date | null>(
        'nextDateToShowWhatsappAvailableModal',
        null,
    );
    const nextDateToShowModal = timestamp ? new Date(timestamp) : null;

    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        if (showWhatsappAvailable && (!nextDateToShowModal || nextDateToShowModal < new Date())) {
            setShowModal(true);
            dataLayer.pushDict('viewed_wa_popin');
        } else {
            setShowModal(false);
        }
    }, [showWhatsappAvailable, nextDateToShowModal]);

    const handleCloseModal = () => {
        setShowModal(false);
        const dateTmp = new Date();
        dateTmp.setDate(dateTmp.getDate() + 15);
        setNextDateToShowModal(dateTmp);
    };

    const redirectWhatsappLoginPage = () => {
        dataLayer.pushDict('proceed_wa_popin');
        setShowModal(false);
        navigate(SETTINGS_MESSAGES_WHATSAPP_PATHNAME);
    };

    return (
        <Modal isOpen={showModal} closeModal={() => undefined} placement="default">
            <Dialog dataTrackId="">
                <img src={`${STATIC_APP_ASSETS_URL}/common/whatsapp_available_image.svg`} />
                <Content>
                    <ContentText>
                        <TextContainer variant="heading4" color="blackberry">
                            {t('whatsapp_available_modal_title')}
                        </TextContainer>
                        <TextContainer variant="bodyMRegular" color="secondary">
                            {t('whatsapp_available_modal_description')}
                        </TextContainer>
                    </ContentText>

                    <ContentButton>
                        <ButtonContainer
                            onClick={handleCloseModal}
                            shape="cube"
                            size="large"
                            variant="secondary"
                            appearance="outlined"
                            dataTrackId={''}
                            full
                        >
                            {t('whatsapp_available_modal_later')}
                        </ButtonContainer>
                        <ButtonContainer
                            onClick={redirectWhatsappLoginPage}
                            shape="cube"
                            size="large"
                            variant="primary"
                            appearance="contained"
                            dataTrackId={''}
                            full
                        >
                            {t('whatsapp_available_modal_connect_account')}
                        </ButtonContainer>
                    </ContentButton>
                </Content>
            </Dialog>
        </Modal>
    );
};

export default WhatsappAvailableModal;
