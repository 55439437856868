import { map, uniqBy } from 'lodash-es';

import qs from 'query-string';

import deleteGroupService from 'app/pages/visibility/BusinessListV2/oldFromListV1/deleteGroup';
import editGroupNameService from 'app/pages/visibility/BusinessListV2/oldFromListV1/editGroupName';

export type GroupData = {
    created: string;
    groupId: number;
    groupName: string;
    orgId: number;
    parentId: number | null;
    provider: string;
    pullerId: number | null;
    subgroups?: Array<GroupData>;
    updated: string;
};

export const UNGROUPED_ID = -1;

// ACTION TYPES
export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST';
export const GET_GROUP_REQUEST_WITHOUT_COUNT = 'GET_GROUP_REQUEST_WITHOUT_COUNT';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_FAILURE = 'GET_GROUP_FAILURE';
export const GET_GROUP_BY_ORG_ID_REQUEST = 'GET_GROUP_BY_ORG_ID_REQUEST';
export const GET_GROUP_BY_ORG_ID_SUCCESS = 'GET_GROUP_BY_ORG_ID_SUCCESS';
export const GET_GROUP_BY_ORG_ID_FAILURE = 'GET_GROUP_BY_ORG_ID_FAILURE';
export const GET_GROUP_COUNT_REQUEST = 'GET_GROUP_COUNT_REQUEST';
export const GET_GROUP_COUNT_REQUEST_SUCCESS = 'GET_GROUP_COUNT_REQUEST_SUCCESS';
export const GET_GROUP_COUNT_REQUEST_FAILURE = 'GET_GROUP_COUNT_REQUEST_FAILURE';
export const SET_CURRENT_GROUP_ID = 'SET_CURRENT_GROUP_ID';
export const SET_CURRENT_SUB_GROUP_ID = 'SET_CURRENT_SUB_GROUP_ID';
export const SET_CURRENT_GROUP_HAS_PULLER = 'SET_CURRENT_GROUP_HAS_PULLER';
export const SET_COUNT_BY_SUB_ID = 'SET_COUNT_BY_SUB_ID';
export const ADD_NEW_GROUP = 'ADD_NEW_GROUP';
export const ADD_NEW_SUB_GROUP = 'ADD_NEW_SUB_GROUP';
export const EDIT_GROUP_NAME = 'EDIT_GROUP_NAME';
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_REQUEST_SUCCESS = 'DELETE_GROUP_REQUEST_SUCCESS';
export const DELETE_GROUP_REQUEST_FAILURE = 'DELETE_GROUP_REQUEST_FAILURE';
export const CHECK_DELETABLE_REQUEST = 'CHECK_DELETABLE_REQUEST';
export const CHECK_DELETABLE_REQUEST_SUCCESS = 'CHECK_DELETABLE_REQUEST_SUCCESS';
export const CHECK_DELETABLE_REQUEST_FAILURE = 'CHECK_DELETABLE_REQUEST_FAILURE';

export type GetGroupAction = {
    type: 'GET_GROUP_REQUEST';
    queryString: string | null;
    page: number | null;
};
export type GetGroupWithoutCountAction = {
    type: 'GET_GROUP_REQUEST_WITHOUT_COUNT';
    queryString: string | null;
    page: number | null;
};
export type GetGroupSuccessAction = {
    type: 'GET_GROUP_SUCCESS';
    data: Array<GroupData>;
    maxPage: number;
};
export type GetGroupFailureAction = {
    type: 'GET_GROUP_FAILURE';
    errors: Record<string, any>;
};
export type GetGroupByOrgIdRequestType = {
    type: 'GET_GROUP_BY_ORG_ID_REQUEST';
    orgId: number | null;
};
export type GetGroupByOrgIdSuccessType = {
    type: 'GET_GROUP_BY_ORG_ID_SUCCESS';
    data: Array<GroupData>;
    maxPage: number;
};
export type GetGroupByOrgIdFailureType = {
    type: 'GET_GROUP_BY_ORG_ID_FAILURE';
    error: Record<string, any>;
};
export type GetGroupCountAction = {
    type: 'GET_GROUP_COUNT_REQUEST';
    filters: Record<string, any>;
};
export type GetGroupCountSuccessAction = {
    type: 'GET_GROUP_COUNT_REQUEST_SUCCESS';
    countById: Record<string, any>;
};
export type GetGroupCountFailureAction = {
    type: 'GET_GROUP_COUNT_REQUEST_FAILURE';
    errors: Record<string, any>;
};
export type SetCurrentGroupIdAction = {
    type: 'SET_CURRENT_GROUP_ID';
    groupId: number | null;
};
export type SetCurrentGroupHasPullerAction = {
    type: 'SET_CURRENT_GROUP_HAS_PULLER';
    hasPuller: boolean;
};
export type SetCurrentSubGroupIdAction = {
    type: 'SET_CURRENT_SUB_GROUP_ID';
    groupId: number | null;
};
export type SetCountBySubIdActon = {
    type: 'SET_COUNT_BY_SUB_ID';
    countBySubId: Record<string, any>;
};
export type AddNewGroupAction = {
    type: 'ADD_NEW_GROUP';
    group: GroupData;
};
export type AddNewSubGroupAction = {
    type: 'ADD_NEW_SUB_GROUP';
    groupId: number;
    group: GroupData;
};
export type EditGroupNameAction = {
    type: 'EDIT_GROUP_NAME';
    parentGroupId: number | null;
    groupId: number;
    groupName: string;
};
export type DeleteGroupAction = {
    type: 'DELETE_GROUP_REQUEST';
    groupId: number | null;
};
export type DeleteGroupSuccessAction = {
    type: 'DELETE_GROUP_REQUEST_SUCCESS';
    groupId: number;
    parentGroupId: number | null;
};
export type DeleteGroupFailureAction = {
    type: 'DELETE_GROUP_REQUEST_FAILURE';
    errors: Record<string, any>;
};
export type CheckDeletableAction = {
    type: 'CHECK_DELETABLE_REQUEST';
    groupId: number;
};
export type CheckDeletableSuccessAction = {
    type: 'CHECK_DELETABLE_REQUEST_SUCCESS';
};
export type CheckDeletableFailureAction = {
    type: 'CHECK_DELETABLE_REQUEST_FAILURE';
    errors: Record<string, any>;
};

// ACTION FLOW TYPES
export type GroupState = {
    data: Array<GroupData> | null;
    countById: Record<string, any>;
    countBySubId: Record<string, any>;
    isFetching: boolean;
    currentGroupHasPuller: boolean;
    errors: Record<string, any>;
    searchQuery: string;
    currentPage: number;
    maxPage: number;
};

export type GroupAction =
    | GetGroupAction
    | GetGroupWithoutCountAction
    | GetGroupFailureAction
    | GetGroupSuccessAction
    | GetGroupCountAction
    | GetGroupCountSuccessAction
    | GetGroupCountFailureAction
    | SetCurrentGroupIdAction
    | SetCurrentGroupHasPullerAction
    | SetCurrentSubGroupIdAction
    | SetCountBySubIdActon
    | AddNewGroupAction
    | AddNewSubGroupAction
    | EditGroupNameAction
    | DeleteGroupAction
    | DeleteGroupSuccessAction
    | DeleteGroupFailureAction
    | CheckDeletableAction
    | CheckDeletableSuccessAction
    | CheckDeletableFailureAction
    | GetGroupByOrgIdRequestType
    | GetGroupByOrgIdSuccessType
    | GetGroupByOrgIdFailureType;

// ACTION CREATORS
export const getGroup = (page?: number, queryString?: string): GetGroupAction => ({
    type: GET_GROUP_REQUEST,
    page: page || null,
    queryString: queryString || '',
});
export const getGroupWithoutCount = (
    page?: number,
    queryString?: string,
): GetGroupWithoutCountAction => ({
    type: GET_GROUP_REQUEST_WITHOUT_COUNT,
    page: page || null,
    queryString: queryString || '',
});
export const getGroupSuccess = (
    data: Array<GroupData>,
    maxPage: number,
): GetGroupSuccessAction => ({
    type: GET_GROUP_SUCCESS,
    data,
    maxPage,
});
export const getGroupFailure = (errors: Record<string, any>): GetGroupFailureAction => ({
    type: GET_GROUP_FAILURE,
    errors,
});
export const getGroupCount = (filters: Record<string, any> = {}): GetGroupCountAction => ({
    type: GET_GROUP_COUNT_REQUEST,
    filters,
});
export const getGroupCountSuccess = (
    countById: Record<string, any>,
): GetGroupCountSuccessAction => ({
    type: GET_GROUP_COUNT_REQUEST_SUCCESS,
    countById,
});
export const getGroupCountFailure = (errors: Record<string, any>): GetGroupCountFailureAction => ({
    type: GET_GROUP_COUNT_REQUEST_FAILURE,
    errors,
});
export const setCountBySubId = (countBySubId: Record<string, any>): SetCountBySubIdActon => ({
    type: SET_COUNT_BY_SUB_ID,
    countBySubId,
});
export const setGroupIdFilter = (groupId: number | null): SetCurrentGroupIdAction => ({
    type: SET_CURRENT_GROUP_ID,
    groupId,
});
export const setSubGroupIdFilter = (groupId: number | null): SetCurrentSubGroupIdAction => ({
    type: SET_CURRENT_SUB_GROUP_ID,
    groupId,
});
export const setGroupHasPuller = (hasPuller: boolean): SetCurrentGroupHasPullerAction => ({
    type: SET_CURRENT_GROUP_HAS_PULLER,
    hasPuller,
});
export const addNewGroup = (group: GroupData): AddNewGroupAction => ({
    type: ADD_NEW_GROUP,
    group,
});
export const addNewSubGroup = (groupId: number, group: GroupData): AddNewSubGroupAction => ({
    type: ADD_NEW_SUB_GROUP,
    groupId,
    group,
});
export const editGroupName = (
    parentGroupId: number | null,
    groupId: number,
    groupName: string,
): EditGroupNameAction => ({
    type: EDIT_GROUP_NAME,
    parentGroupId,
    groupId,
    groupName,
});
export const deleteGroup = (groupId: number | null): DeleteGroupAction => ({
    type: DELETE_GROUP_REQUEST,
    groupId,
});
export const deleteGroupSuccess = (
    groupId: number,
    parentGroupId: number | null,
): DeleteGroupSuccessAction => ({
    type: DELETE_GROUP_REQUEST_SUCCESS,
    groupId,
    parentGroupId,
});
export const deleteGroupFailure = (errors: Record<string, any>): DeleteGroupFailureAction => ({
    type: DELETE_GROUP_REQUEST_FAILURE,
    errors,
});
export const checkDeletable = (groupId: number): CheckDeletableAction => ({
    type: CHECK_DELETABLE_REQUEST,
    groupId,
});
export const checkDeletableSuccess = (): CheckDeletableSuccessAction => ({
    type: CHECK_DELETABLE_REQUEST_SUCCESS,
});
export const checkDeletableFailure = (
    errors: Record<string, any>,
): CheckDeletableFailureAction => ({
    type: CHECK_DELETABLE_REQUEST_FAILURE,
    errors,
});
export const getGroupByOrgIdAction = (orgId: number | null): GetGroupByOrgIdRequestType => ({
    type: GET_GROUP_BY_ORG_ID_REQUEST,
    orgId,
});
export const getGroupByOrgIdSuccessAction = (
    data: Array<GroupData>,
    maxPage: number,
): GetGroupByOrgIdSuccessType => ({
    type: GET_GROUP_BY_ORG_ID_SUCCESS,
    data,
    maxPage,
});
export const getGroupByOrgIdFailureAction = (
    error: Record<string, any>,
): GetGroupByOrgIdFailureType => ({
    type: GET_GROUP_BY_ORG_ID_FAILURE,
    error,
});

export const groupSearchParamToGroupSubGroupValue = (
    groupSearchParam?: string,
): [number | null, number | null] => {
    const [groupIdSearchParam, subGroupIdSearchParam] = groupSearchParam?.split(',') || [];
    let groupId;

    switch (groupIdSearchParam) {
        case 'all':
            groupId = null;
            break;

        case 'ungrouped':
            groupId = -1;
            break;

        default:
            groupId = Number(groupIdSearchParam) || null;
    }

    return [groupId, Number(subGroupIdSearchParam) || null];
};

const initialState = {
    data: null,
    countById: {},
    countBySubId: {},
    isFetching: false,
    errors: {},
    currentGroupHasPuller: false,
    currentPage: 1,
    maxPage: Infinity,
    searchQuery: '',
};

// REDUCER
const groupReducer = (state: GroupState = initialState, action: GroupAction): GroupState => {
    switch (action.type) {
        case GET_GROUP_REQUEST:
        case GET_GROUP_REQUEST_WITHOUT_COUNT:
            return {
                ...state,
                errors: {},
                isFetching: true,
                currentPage: action.page || 1,
                searchQuery: action.queryString || '',
            };

        case GET_GROUP_FAILURE:
        case GET_GROUP_COUNT_REQUEST_FAILURE:
        case DELETE_GROUP_REQUEST_FAILURE:
        case CHECK_DELETABLE_REQUEST_FAILURE:
            return { ...state, errors: action.errors, isFetching: false };

        case GET_GROUP_SUCCESS:
            return {
                ...state,
                data: uniqBy([...(state.data || []), ...action.data], group => group.groupId),
                isFetching: false,
                maxPage: action.maxPage,
            };

        case GET_GROUP_COUNT_REQUEST:
            return { ...state, errors: {}, isFetching: true };

        case GET_GROUP_COUNT_REQUEST_SUCCESS:
            return { ...state, countById: action.countById, isFetching: false };

        case SET_COUNT_BY_SUB_ID:
            return {
                ...state,
                countBySubId: action.countBySubId,
                isFetching: false,
            };

        case SET_CURRENT_GROUP_ID:
            return { ...state, isFetching: false };

        case SET_CURRENT_SUB_GROUP_ID:
            return { ...state, isFetching: false };

        case SET_CURRENT_GROUP_HAS_PULLER:
            return { ...state, currentGroupHasPuller: action.hasPuller };

        case ADD_NEW_GROUP:
            return {
                ...state,
                data: state.data ? [...state.data, action.group] : null,
            };

        case ADD_NEW_SUB_GROUP: {
            const actionGroupId = action.groupId;
            const actionGroup = action.group;
            return {
                ...state,
                data: state.data
                    ? state.data.map(group => {
                          if (group.groupId === actionGroupId) {
                              return {
                                  ...group,
                                  subgroups: group.subgroups
                                      ? [...group.subgroups, actionGroup]
                                      : [actionGroup],
                              };
                          }

                          return group;
                      })
                    : null,
            };
        }

        case EDIT_GROUP_NAME:
            return {
                ...state,
                data: editGroupNameService(
                    state.data,
                    action.parentGroupId,
                    action.groupId,
                    action.groupName,
                ),
            };

        case DELETE_GROUP_REQUEST:
            return { ...state, errors: {}, isFetching: true };

        case DELETE_GROUP_REQUEST_SUCCESS:
            return {
                ...state,
                data: deleteGroupService(state.data, action.parentGroupId, action.groupId),
                isFetching: false,
            };

        case CHECK_DELETABLE_REQUEST:
            return { ...state, errors: {}, isFetching: true };

        case CHECK_DELETABLE_REQUEST_SUCCESS:
            return { ...state, isFetching: false };

        case GET_GROUP_BY_ORG_ID_SUCCESS:
            return { ...state, data: action.data, maxPage: action.maxPage };

        default:
            return state;
    }
};

// HELPER
const findSubGroupInGroup = (group, subGroupId) =>
    group && group.subgroups ? group.subgroups.find(sg => sg.groupId === subGroupId) : null;

// SELECTORS
export const groupDataSelector = ({ data }: GroupState): Array<GroupData> | null => data;

export const pageSelector = ({ currentPage }: GroupState): number => currentPage;

export const maxPageSelector = ({ maxPage }: GroupState): number => maxPage;

export const searchQuerySelector = ({ searchQuery }: GroupState): string => searchQuery;

export const currentGroupIdSelector = (): number | null => {
    // TODO:
    //  - make this selector have global state as single argument
    //  - use state.router.location to access current location
    const { location } = window;
    const { group: groupSearchParam } = qs.parse(location.search);
    const [currentGroupId] =
        // @ts-ignore
        groupSearchParamToGroupSubGroupValue(groupSearchParam);
    return currentGroupId;
};

export const currentGroupHasPullerSelector = ({ currentGroupHasPuller }: GroupState): boolean =>
    currentGroupHasPuller;

export const isFetchingGroupsSelector = ({ isFetching }: GroupState): boolean => isFetching;

export const currentSubGroupIdSelector = (): number | null => {
    // TODO:
    //  - make this selector have global state as single argument
    //  - use state.router.location to access current location
    const { location } = window;
    const { group: groupSearchParam } = qs.parse(location.search);
    const [, currentSubGroupId] =
        // @ts-ignore
        groupSearchParamToGroupSubGroupValue(groupSearchParam);
    return currentSubGroupId;
};

export const getGroupByIdSelector = (
    { data }: GroupState,
    groupId: number | null,
): GroupData | null => {
    let group;
    if (data) group = data.find(item => item.groupId === groupId);
    return group || null;
};

export const getSubGroupIdByGroupId = (
    { data }: GroupState,
    groupId: number | null,
): Array<number> => {
    const group = data && data.find(g => g.groupId === groupId);

    if (group) {
        return map(group.subgroups, sg => sg.groupId);
    }

    return [];
};

export const getSubGroupByIdSelector = (
    groupState: GroupState,
    groupId: number | null,
    subGroupId: number | null,
) => {
    const group = getGroupByIdSelector(groupState, groupId);
    return findSubGroupInGroup(group, subGroupId);
};

export const getParentGroupIdByGroupIdSelector = (
    { data }: GroupState,
    subGroupId: number | null,
): number | null => {
    if (data && subGroupId) {
        const parentGroup = data.find(g => findSubGroupInGroup(g, subGroupId));
        return (parentGroup && parentGroup.groupId) || null;
    }

    return null;
};

export const getSubGroupBySubGroupIdSelector = (
    groupState: GroupState,
    subGroupId: number | null,
) => {
    if (subGroupId) {
        const parentGroupId = getParentGroupIdByGroupIdSelector(groupState, subGroupId);

        if (parentGroupId) {
            return getSubGroupByIdSelector(groupState, parentGroupId, subGroupId);
        }
    }

    return null;
};

// In the Subgroup Manager we have the parentId directly in the data
export const getParentIdByIdSelector = (groupState: GroupState, subGroupId: number) => {
    const group = getGroupByIdSelector(groupState, subGroupId);
    return group && group.parentId;
};

export const countByIdSelector = (
    { countById, data }: GroupState,
    groupId: number | null,
): number => {
    if (countById) {
        // "All location" group
        if (!groupId && Object.prototype.hasOwnProperty.call(countById, 'all'))
            return countById.all;
        // Groups, not empty subgroups and ungrouped
        if (Object.prototype.hasOwnProperty.call(countById, groupId)) {
            // @ts-ignore
            return countById[groupId];
        }

        // Empty subgroups
        if (data && data.find(group => findSubGroupInGroup(group, groupId))) {
            return 0;
        }
    }

    return -1;
};

export const countBySubIdSelector = (
    { countBySubId }: GroupState,
    subGroupId: number | null,
): number => {
    if (countBySubId) {
        // "All location" group
        if (!subGroupId && Object.prototype.hasOwnProperty.call(countBySubId, 'all')) {
            return countBySubId.all;
        }

        if (Object.prototype.hasOwnProperty.call(countBySubId, subGroupId)) {
            // @ts-ignore
            return countBySubId[subGroupId];
        }
    }

    return -1;
};

export default groupReducer;
