import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

export const BusinessNameSelectorId = 'BusinessName';
export const BusinessAddressSelectorId = 'BusinessAddress';

export const BusinessName = styled.div`
    text-align: start;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${HEX_COLORS.blackberry};
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 4px;
    transition: all 0.2s;
`;

export const BusinessAddressContainer = styled.div`
    display: flex;
    align-items: center;
    > i {
        color: ${({ theme }) => theme.colors.warning.initial};
        font-size: 16px;
        margin-right: 8px;
    }
`;

export const BusinessInfos = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    margin-bottom: 8px;
    margin-right: 8px;
    overflow: hidden;
`;

export const BusinessAddress = styled.div`
    text-align: start;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 14px;
    line-height: 18px;
    transition: all 0.2s;
`;

export const BusinessReach = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 2px;
    height: 24px;
    padding: 4px 8px;
    border: solid 1px ${({ theme }) => theme.colors.default.alpha};
    border-radius: 4px;
    width: fit-content;

    > * {
        text-transform: uppercase !important;
        transition: all 0.2s;
    }
`;

export const ErrorStatusBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    padding: 0 16px;
    height: 25px;
    width: 100%;
    max-height: 25px;
    background-color: ${({ theme }) => theme.colors.warning.initial};
    cursor: pointer;
    color: ${HEX_COLORS.white};
    font-weight: bold;
    font-size: 12px;
    z-index: 1;

    > span > i {
        margin-left: 4px;
    }
`;

export const AddressCardContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;

    background: none;
    outline: inherit;
    border: none;

    overflow: hidden;
`;

export const AddressCard = styled.button`
    display: flex;
    flex: 1;
    align-items: center;

    overflow: hidden;
    width: 100%;
    padding: 0 20px 0 184px;

    background: none;
    outline: inherit;
    border: none;

    &:hover {
        cursor: pointer;
    }

    > i {
        color: ${({ theme }) => theme.colors.secondary.initial};
        font-weight: bold;
        transition: all 0.2s;
    }

    &:hover i,
    &:hover [data-css-selector=${`${BusinessNameSelectorId}`}], &:hover [data-css-selector=${`${BusinessAddressSelectorId}`}] {
        color: ${greyColorObject.dark};

        * {
            color: ${greyColorObject.dark};
        }
    }
`;
