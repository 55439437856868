import React from 'react';

import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import { ConvHeaderContainer } from './ConversationHeader.styled';
import {
    ConvHeaderImgLoader,
    ConvHeaderNameLoader,
} from './ConversationHeaderLoader.styled';

const ConversationHeaderLoader = () => (
    <ConvHeaderContainer>
        <ConvHeaderImgLoader>
            <LoadingRectangle
                recWidth="32px"
                recHeight="32px"
                recRadius="50%"
            />
        </ConvHeaderImgLoader>
        <ConvHeaderNameLoader>
            <LoadingRectangle recWidth="220px" recHeight="8px" />
        </ConvHeaderNameLoader>
    </ConvHeaderContainer>
);

export default ConversationHeaderLoader;
