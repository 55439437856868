import styled from '@emotion/styled';

export const ModalContent = styled.div`
    overflow: hidden;
    margin: 0 auto;
    border-radius: 8px;
    padding: 40px 24px 24px 24px;
    width: 400px;
`;

export const ModalTitle = styled.div`
    margin-bottom: 8px;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    line-height: 100%;
`;

export const ModalSubtitle = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.colors.secondary.initial};
    font-size: 14px;
    line-height: 16px;
`;
