import React from 'react';

type Props = {
    width?: number;
    height?: number;
};

const BadgeLow = React.forwardRef<SVGSVGElement>(
    ({ width = 128, height = 130 }: Props, ref) => (
        <svg
            ref={ref}
            width={width}
            height={height}
            viewBox="0 0 128 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M58.4555 17L80.1201 20.3206L70.0479 48.1914L87.6364 22.7557L101.564 35.8168L78.3009 54.2036L105.195 42.6794L110.185 60.168L81.4533 63.9314L110.185 70.5726L103.111 89.8322L78.3009 73.6592L96.2581 98.023L78.3009 107.321L70.0479 79.6713L71.0563 110.42L49.6127 109.092L59.8466 79.6713L41.6543 105.771L26.9531 90.7177L51.5935 73.6592L22.4214 84.0764L18 61.7176L48.4412 63.9314L19.1053 53.3054L28.3902 35.8168L51.5935 54.2036L33.9169 29.6183L49.6127 19.2137L59.8466 48.1914L58.4555 17Z"
                fill="url(#paint0_radial_22641_119198)"
            />
            <g
                style={{ mixBlendMode: 'color-burn' }}
                filter="url(#filter0_f_22641_119198)"
            >
                <path
                    d="M56.2935 43.9311L44.0611 56.164C39.7544 60.4709 39.7544 67.4538 44.0611 71.7606L56.2935 83.9936C60.6002 88.3005 67.5827 88.3005 71.8894 83.9936L84.1218 71.7606C88.4285 67.4538 88.4285 60.4709 84.1218 56.164L71.8894 43.9311C67.5827 39.6242 60.6002 39.6242 56.2935 43.9311Z"
                    fill="#FA91AD"
                />
                <path
                    d="M58.7983 47.7377L47.8704 58.6661C44.9455 61.5911 44.9455 66.3335 47.8704 69.2585L58.7983 80.187C61.7232 83.112 66.4654 83.112 69.3902 80.187L80.3182 69.2585C83.2431 66.3335 83.2431 61.5911 80.3182 58.6661L69.3902 47.7377C66.4654 44.8127 61.7232 44.8127 58.7983 47.7377Z"
                    fill="#FDD2DD"
                />
                <path
                    d="M60.8105 80.1866C62.0168 81.393 63.5312 82.0999 65.1001 82.3114C62.8626 82.6135 60.5145 81.9045 58.7966 80.1866L47.8673 69.2568C44.9432 66.3326 44.9432 61.5917 47.8673 58.6674L58.7966 47.7376C60.5145 46.0197 62.8626 45.3108 65.1001 45.6129C63.5312 45.8243 62.0168 46.5312 60.8105 47.7376L49.8812 58.6674C46.957 61.5917 46.957 66.3326 49.8812 69.2568L60.8105 80.1866Z"
                    fill="#FA90AC"
                />
                <path
                    d="M72.2614 63.9317C72.3259 64.9931 72.0056 65.6074 71.6311 66.2115C69.7521 69.2406 67.8853 72.2756 66.0023 75.3026C64.7074 77.387 63.7185 77.0427 62.3451 74.2714C60.8206 71.1941 58.9618 68.302 56.8975 65.557C56.1806 64.6044 56.3055 63.6961 56.7848 62.8744C58.8188 59.3943 60.8709 55.9242 62.9976 52.5005C63.7951 51.2176 64.9067 51.1713 65.6639 52.3857C67.7966 55.8054 69.8427 59.2795 71.8888 62.7495C72.1466 63.1866 72.203 63.7364 72.2594 63.9297L72.2614 63.9317Z"
                    fill="#FA90AC"
                />
                <path
                    d="M70.9544 63.5635C71.0148 64.5685 70.7168 65.1506 70.3663 65.7225C68.6123 68.5884 66.8703 71.4623 65.1142 74.3282C63.9058 76.3019 62.9835 75.9736 61.7026 73.3514C60.2788 70.4372 58.5328 67.7063 56.6116 65.0942C55.951 64.1959 56.0678 63.34 56.5149 62.5646C58.412 59.2718 60.3292 55.987 62.3128 52.7445C63.056 51.5301 64.0931 51.4858 64.802 52.6358C66.7937 55.8722 68.7009 59.161 70.61 62.4458C70.8497 62.8587 70.902 63.3803 70.9564 63.5635H70.9544Z"
                    fill="#FFABCA"
                />
            </g>
            <path
                d="M56.2935 43.9311L44.0612 56.164C39.7545 60.4709 39.7545 67.4538 44.0612 71.7607L56.2935 83.9936C60.6002 88.3005 67.5828 88.3005 71.8895 83.9936L84.1219 71.7607C88.4286 67.4538 88.4286 60.4709 84.1219 56.164L71.8895 43.9311C67.5828 39.6242 60.6002 39.6242 56.2935 43.9311Z"
                fill="#FA91AD"
            />
            <path
                d="M58.7984 47.7377L47.8704 58.6662C44.9455 61.5912 44.9455 66.3335 47.8704 69.2585L58.7984 80.187C61.7233 83.112 66.4654 83.112 69.3903 80.187L80.3183 69.2585C83.2432 66.3335 83.2432 61.5912 80.3183 58.6662L69.3903 47.7377C66.4654 44.8127 61.7233 44.8127 58.7984 47.7377Z"
                fill="#FDD2DD"
            />
            <g style={{ mixBlendMode: 'color-burn' }}>
                <path
                    d="M60.8106 80.1866C62.0169 81.393 63.5314 82.0999 65.1002 82.3114C62.8628 82.6134 60.5146 81.9045 58.7967 80.1866L47.8674 69.2568C44.9433 66.3326 44.9433 61.5917 47.8674 58.6674L58.7967 47.7376C60.5146 46.0197 62.8628 45.3108 65.1002 45.6129C63.5314 45.8243 62.0169 46.5312 60.8106 47.7376L49.8813 58.6674C46.9572 61.5917 46.9572 66.3326 49.8813 69.2568L60.8106 80.1866Z"
                    fill="#FA90AC"
                />
            </g>
            <g style={{ mixBlendMode: 'multiply' }}>
                <path
                    d="M72.2614 63.9318C72.3259 64.9932 72.0057 65.6074 71.6311 66.2116C69.7522 69.2406 67.8853 72.2757 66.0023 75.3027C64.7074 77.3871 63.7186 77.0427 62.3451 74.2715C60.8206 71.1942 58.9618 68.3021 56.8976 65.5571C56.1806 64.6045 56.3055 63.6962 56.7848 62.8745C58.8188 59.3943 60.871 55.9243 62.9976 52.5005C63.7951 51.2176 64.9068 51.1713 65.664 52.3857C67.7967 55.8055 69.8428 59.2796 71.8889 62.7496C72.1467 63.1866 72.203 63.7365 72.2594 63.9298L72.2614 63.9318Z"
                    fill="#FA90AC"
                />
            </g>
            <path
                d="M70.9545 63.5636C71.0149 64.5686 70.7169 65.1506 70.3665 65.7226C68.6124 68.5884 66.8704 71.4624 65.1143 74.3282C63.9059 76.3019 62.9836 75.9736 61.7028 73.3515C60.279 70.4373 58.5329 67.7063 56.6117 65.0942C55.9511 64.196 56.0679 63.34 56.515 62.5647C58.4121 59.2718 60.3293 55.9871 62.313 52.7446C63.0561 51.5301 64.0932 51.4858 64.8021 52.6358C66.7938 55.8723 68.701 59.1611 70.6101 62.4458C70.8498 62.8587 70.9022 63.3803 70.9565 63.5636H70.9545Z"
                fill="#FFABCA"
            />
            <defs>
                <filter
                    id="filter0_f_22641_119198"
                    x="0.831055"
                    y="0.700928"
                    width="126.521"
                    height="126.523"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="20"
                        result="effect1_foregroundBlur_22641_119198"
                    />
                </filter>
                <radialGradient
                    id="paint0_radial_22641_119198"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(64.8664 63.9314) rotate(90.9535) scale(46.4951 45.3252)"
                >
                    <stop stopColor="#FFA1B7" stopOpacity="0.32" />
                    <stop offset="1" stopColor="#FFA1B7" stopOpacity="0" />
                </radialGradient>
            </defs>
        </svg>
    ),
);

export default BadgeLow;
