import { Stack } from '@partoohub/ui';

import LoadingView from 'app/common/components/LoadingView';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import usePartnersConnected from 'app/common/hooks/queries/usePartnersConnected';

import { SettingsEditFormContainer } from './Notifications.styled';
import { NoReviewManagementSubscribed } from '../NoReviewManagementSubscribed';
import { ReportsForm } from '../reportsForm/ReportsForm';

export const Notifications = () => {
    const { isFetched } = usePartnersConnected();

    const { isLoading, subscribedToRM } = useBusinessSubscriptions();

    if (isLoading || !isFetched) {
        return <LoadingView />;
    }

    return (
        <Stack direction="column">
            <SettingsEditFormContainer>
                {!subscribedToRM ? <NoReviewManagementSubscribed /> : <ReportsForm />}
            </SettingsEditFormContainer>
        </Stack>
    );
};
