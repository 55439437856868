import styled from '@emotion/styled';

export const BannerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    border-radius: 8px;
    padding: 10px;

    width: 100%;

    background-color: #ff426e1f;
`;

export const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;

    border-radius: 8px;

    height: 53px;
    width: 53px;

    background-color: #ffffff;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1px;
`;
