import { PropsWithChildren } from 'react';

import { IconElement, Notification, NotificationColorsVariants, Text } from '@partoohub/ui';

import { IconTitleContainer } from './IconTitle.styled';

interface Props {
    textColor?: string;
    icon: IconElement;
    variant?: NotificationColorsVariants;
}

export const IconTitle = ({
    textColor = 'default',
    icon,
    variant = 'primary',
    children,
}: PropsWithChildren<Props>) => (
    <IconTitleContainer>
        <Notification dataTrackId="icon_title" icon={icon} variant={variant} />
        <Text variant="heading6" color={textColor}>
            {children}
        </Text>
    </IconTitleContainer>
);
