import React from 'react';

import LoadingServicesBody from 'app/businessEditV2/sections/ServicesSection/ServicesListForm/LoadingServicesBody';
import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';
import {
    SectionFooter,
    SectionFooterContent,
    SectionGlobal,
    SectionHeader,
    SectionSubtitle,
    SectionTitle,
    Suggestion,
} from 'app/common/designSystem/components/molecules/SectionContainer/SectionContainer.styled';

const LoadingServicesList = () => (
    <SectionGlobal>
        <SectionHeader>
            <div>
                <SectionTitle>
                    <LoadingRectangle
                        recWidth="200px"
                        recHeight="18px"
                        recRadius="5%"
                    />
                </SectionTitle>
                <SectionSubtitle>
                    <Suggestion>
                        <LoadingRectangle
                            recWidth="50px"
                            recHeight="14px"
                            recRadius="500px"
                        />
                    </Suggestion>
                    <Suggestion>
                        <LoadingRectangle
                            recWidth="50px"
                            recHeight="14px"
                            recRadius="500px"
                        />
                    </Suggestion>
                    <Suggestion>
                        <LoadingRectangle
                            recWidth="50px"
                            recHeight="14px"
                            recRadius="500px"
                        />
                    </Suggestion>
                </SectionSubtitle>
            </div>
        </SectionHeader>

        <LoadingServicesBody />
        <LoadingServicesBody />

        <SectionFooter>
            <SectionFooterContent>
                <LoadingRectangle
                    recWidth="200px"
                    recHeight="14px"
                    recRadius="5%"
                    recMargin="0 0 0 24px"
                />
            </SectionFooterContent>
        </SectionFooter>
    </SectionGlobal>
);

export default LoadingServicesList;
