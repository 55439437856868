export type GooglePostState =
    | 'all'
    | 'published'
    | 'pending'
    | 'to_configure'
    | 'error'
    | 'expired';

export const ALL_GOOGLE_POSTS = 'all';
export const PENDING_GOOGLE_POSTS = 'pending';
export const PUBLISHED_GOOGLE_POSTS = 'published';
export const TO_CONFIGURE_POSTS = 'to_configure';
export const ERROR_GOOGLE_POSTS = 'error';
export const EXPIRED_POSTS = 'expired';
export const LIVE_POSTS = 'live';
