import { useTranslation } from 'react-i18next';

import { Navigate } from 'react-router-dom';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import LoadingView from 'app/common/components/LoadingView';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import ReviewBooster from 'app/pages/conversations/ReviewBooster/components/ReviewBooster';
import { ReviewBoosterWithNewSidebar } from 'app/pages/conversations/ReviewBooster/components/ReviewBoosterWithNewSidebar';
import {
    CEPages,
    useCanAccessCustomerExperiencePage,
} from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

export const ReviewBoosterPage = () => {
    const { t } = useTranslation();
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);

    const ReviewBoosterComponent = isNewSidebarEnabled
        ? ReviewBoosterWithNewSidebar
        : ReviewBooster;

    const {
        canAccessCustomerExperiencePage: { [CEPages.RB]: hasAccess },
        isLoading: accessLoading,
    } = useCanAccessCustomerExperiencePage();

    if (accessLoading) {
        return <LoadingView />;
    }

    return (
        <>
            <DynamicPageHeader title={t('review_booster')} />
            {hasAccess ? <ReviewBoosterComponent /> : <Navigate replace to={NOT_FOUND_PATH} />}
        </>
    );
};
