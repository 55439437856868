export enum ColorState {
    DISABLED,
    PRECHECK_ERRORS,
    MISSING_WARNING,
    OK,
    CLOSED,
}

export const ColorStateToText = {
    [ColorState.DISABLED]: 'Disabled',
    [ColorState.PRECHECK_ERRORS]: 'Precheck errors',
    [ColorState.MISSING_WARNING]: 'Missing warning',
    [ColorState.OK]: 'Ok',
    [ColorState.CLOSED]: 'Closed',
};

export enum ColorProgress {
    LOW,
    MEDIUM,
    HIGH,
    NONE,
}
