import styled from '@emotion/styled';

export const BadgeFrame = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: #f6f8fc;
    border-radius: 4px;
    padding: 4px 8px;
    & > span {
        line-height: 12px;
    }
    & > img {
        width: 12px;
    }
`;

export const IconHolder = styled.div`
    display: flex;
    align-items: center;
    width: 10px;
    height: 10px;
`;
