import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';
import { mergeSpecificHours } from 'app/businessEditV2/utils/specificHours';

import { ColorState } from './types';

export default function useSpecificHoursSectionColor(): ColorState {
    // Hooks
    const { data: publisherErrors } = usePublisherErrors({});
    const specificHoursPermission = useFieldMetadata('hours', 'specific_hours')?.enabled;
    const { data: business } = useBusiness();
    const isClosed = useIsBusinessClosed();

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // Disabled
    if (!specificHoursPermission) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (publisherErrors?.hours?.specific_hours) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields
    const specificHours = mergeSpecificHours(business?.specific_hours ?? {});
    if (specificHours.length === 0) {
        return ColorState.MISSING_WARNING;
    }

    // OK
    return ColorState.OK;
}
