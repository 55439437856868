import { call, put, select, takeEvery } from 'redux-saga/effects';

import api from 'app/api/v2/api_calls';
import { Saga } from 'app/common/types';
import {
    LOST_PASSWORD_REQUEST,
    LostPasswordState,
    emailSelector,
    lostPasswordRequestFailure,
    lostPasswordRequestSuccess,
} from 'app/states/connection/reducers/lostPassword';
import { lostPasswordSelector } from 'app/states/reducers';

const lostPassword = function* (): Saga {
    const lostPasswordState: LostPasswordState =
        yield select(lostPasswordSelector);
    const email = emailSelector(lostPasswordState);

    try {
        yield call(api.connection.lostPassword, {
            email,
        });
        yield put(lostPasswordRequestSuccess());
    } catch (error) {
        yield put(lostPasswordRequestFailure(error));
    }
};

function* lostPasswordSaga(): Saga {
    yield takeEvery(LOST_PASSWORD_REQUEST, lostPassword);
}

export default lostPasswordSaga;
