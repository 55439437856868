import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { BUSINESS_EDIT_BREAKPOINT } from 'app/businessEditV2/BusinessEditV2.styled';

export const HEADER_SECTION_HEIGHT = 222;
export const GRID_GAP = 16;

export const BusinessEditV2ContentContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

type GridSectionProps = {
    withMarginBottom?: boolean;
};

export const GridSection = styled.div<GridSectionProps>`
    display: grid;
    grid-gap: ${GRID_GAP}px ${GRID_GAP}px;
    width: 100%;

    ${props =>
        props.withMarginBottom &&
        css`
            margin-bottom: 24px;
        `};

    @media (${BUSINESS_EDIT_BREAKPOINT}) {
        grid-template-columns: 1fr 1fr;
    }
`;

export const AddressCompletionHeader = styled.div`
    display: flex;
    flex-direction: column-reverse;

    > div {
        margin-bottom: 24px;
    }

    @media (${BUSINESS_EDIT_BREAKPOINT}) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: ${GRID_GAP}px ${GRID_GAP}px;
    }
`;

export const GridSectionRows = styled.div`
    display: grid;
    grid-gap: ${GRID_GAP}px ${GRID_GAP}px;

    @media (${BUSINESS_EDIT_BREAKPOINT}) {
        grid-template-rows: 1fr 1fr;
    }
`;
