import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { V2BusinessData } from 'app/api/types/business';
import { Choice } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import { getBusinessRequestKey } from 'app/businessEditV2/utils/utils';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

const ERROR_CODE_LIST = [403, 404];

export default function useBusiness(businessId?: string) {
    const navigate = useNavigate();
    return useQuery(
        getBusinessRequestKey(businessId),
        // @ts-ignore
        () => api.business.getBusiness(businessId),
        {
            enabled: !!businessId,
            onError: (err: Record<string, any>) => {
                if (ERROR_CODE_LIST.includes(err?.response?.status)) {
                    navigate(NOT_FOUND_PATH);
                }
            },
        },
    );
}

export function formatBusinessToChoice(business: V2BusinessData): Choice {
    return {
        value: business.id,
        label: `${business.name} (${business.city}, ${business.address_full}) - ${business.code}`,
    };
}
