import { useMutation, useQueryClient } from 'react-query';

import { deleteServiceQueryParams } from 'app/api/types/services';
import api from 'app/api/v2/api_calls';
import useGetBusinessId from 'app/businessEditV2/hooks/business/useGetBusinessId';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { GET_SERVICES } from 'app/common/data/queryKeysConstants';

export default function useServiceDelete(
    onSuccess: () => void = () => undefined,
    onError: (ServicesGetErrorType) => void = () => undefined,
) {
    const businessId = useGetBusinessId();
    const queryClient = useQueryClient();
    return useMutation(
        (serviceId: number) =>
            api.services.deleteService(
                businessId,
                deleteServiceQueryParams(serviceId),
            ),
        {
            onSuccess: () => {
                queryClient
                    .invalidateQueries(invalidatesAny(GET_SERVICES))
                    .then(onSuccess);
            },
            onError,
        },
    );
}
