import { Button, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { NavValue } from 'app/states/reviews/filters';

export type RibbonStateType = 'enabled' | 'disabled' | 'success' | 'loading' | 'danger';

interface Props {
    handleClick: (arg0?: NavValue) => void;
    state: RibbonStateType;
    text: string;
    preventClick?: boolean;
    successIcon?: string;
    dangerIcon?: string;
    enabledIcon?: string;
    noIcon: boolean;
    navTabPending?: NavValue;
}

const isEnabled = (state: string): boolean =>
    state === 'enabled' || state === 'success' || state === 'danger';

const ModalValidationRibbon = ({
    handleClick,
    state,
    text,
    preventClick = false,
    successIcon = 'fa fa-check',
    dangerIcon = 'fa fa-frown',
    enabledIcon = 'fa fa-save',
    navTabPending,
    noIcon,
}: Props) => {
    const { t } = useTranslation();

    const isDisabled = () => {
        return state === 'disabled' || state === 'loading' || preventClick;
    };

    const onClick = () => {
        if (!isDisabled()) {
            if (navTabPending) {
                handleClick(navTabPending);
            } else {
                handleClick();
            }
        }
    };

    const getColorButton = () => {
        if (['danger', 'success'].includes(state)) {
            return state;
        }
        if (['disabled', 'loading'].includes(state)) {
            return 'secondary';
        }
        return 'primary';
    };

    return (
        <Button
            dataTrackId="modal_ribbon"
            disabled={['disabled', 'loading'].includes(state)}
            variant={getColorButton() as 'primary' | 'danger' | 'success' | 'secondary'}
            onClick={() => isEnabled(state) && onClick()}
            role="button"
            tabIndex={-1}
            size="large"
            full
            isLoading={state === 'loading'}
        >
            <Stack gap="8px" direction="row" alignItems="center">
                {!noIcon && (
                    <>
                        {state === 'enabled' && <i className={enabledIcon} />}
                        {state === 'success' && <i className={successIcon} />}
                        {state === 'danger' && <i className={dangerIcon} />}
                    </>
                )}
                <Text as="span" variant="bodyLBold" color="white">
                    {t(text)}
                </Text>
            </Stack>
        </Button>
    );
};

export default ModalValidationRibbon;
