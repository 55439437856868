import styled from '@emotion/styled';

type ColorProps = {
    hex: string;
    rgba: string;
};

export const ActionsCardContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.theme.background};
    border: 1px solid #dddfe3;
    border-radius: 4px;
    padding: 10px;
    position: relative;
`;

export const SearchKeywordsContainer = styled.div`
    margin-top: 16px;
`;

export const SuptitleContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 6.5px;
`;

export const SuptitleBoxIconWrapper = styled.div`
    display: flex;
`;
export const SuptitleBoxIcon = styled.div<ColorProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    color: ${props => props.hex};
    font-size: 10px;
    padding: 7px;
    background-color: ${props => props.rgba};
    border-radius: 50%;
    margin-right: 5px;
`;

export const SuptitleText = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.secondary.initial};
`;
export const SuptitleValue = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.default.initial};
    text-align: right;
`;
