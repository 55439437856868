import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';

export const passwordChecksContainer = css`
    max-height: 400px;
    opacity: 1;
    display: flex;
    gap: 6px;
    background-color: #f4f5f9;
    color: #333333;
    flex-flow: column nowrap;
    margin-bottom: 16px;
    border: 1px solid #e6e8ed;
    padding: 16px;
    border-radius: 4px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: #f4f5f9;
        top: -5px;
        left: 15px;
        transform: rotate(45deg);
        box-shadow: -1px -1px 0px #e6e8ed;
    }
`;

export const passwordChecksContainerHidden = css`
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
`;

export const PasswordChecksContainer = styled.div<{ css: SerializedStyles[] }>`
    ${({ css }) => css};
`;

export const PasswordChecksRow = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    flex-flow: row nowrap;
`;
