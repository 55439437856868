import React from 'react';

import { Modal } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { catalogTypes } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/sections/CustomFieldsModals/modals/CustomFieldsCatalogModal/CatalogModal/CatalogModal';
import { CustomFieldsQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

import CustomFieldsCreateContent from './CustomFieldsCreateContent';

const CustomFieldsCreateModal = () => {
    const [createCustomField, setCreateCustomField] = useStateQueryParams(
        CustomFieldsQueryKeys.CREATE,
    );

    const closeModal = () => setCreateCustomField('');

    return (
        <Modal
            isOpen={catalogTypes.includes(createCustomField)}
            closeModal={closeModal}
            placement="center"
            animation="fadeIn"
        >
            <CustomFieldsCreateContent closeModal={closeModal} />
        </Modal>
    );
};

export default CustomFieldsCreateModal;
