import { useState } from 'react';

import { DynamicFilters } from '@partoohub/modular-components';
import { useTranslation } from 'react-i18next';

import { DeviceType, useDeviceType } from 'app/hooks';

import { useFilterSchema, useGetFiltersFromStateQuery, useSetStateQueryFromFilters } from './hooks';

export const FilterButton = () => {
    const { t } = useTranslation();
    const isDesktop = useDeviceType().device === DeviceType.Desktop;

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const filters = useGetFiltersFromStateQuery();
    const setFilters = useSetStateQueryFromFilters();

    const { initialSchema, getVisibleSchema } = useFilterSchema();

    return (
        <DynamicFilters
            schema={initialSchema}
            getVisibleSchema={getVisibleSchema}
            filters={filters}
            onApply={setFilters}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            resetText={t('reset')}
            applyText={t('apply')}
            title={t('filters')}
            buttonText={isDesktop ? t('filters') : ''}
        />
    );
};
