import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const SectionsTitle = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    color: ${HEX_COLORS.blackberry};
`;
