import useBusinessHasOpenHours from 'app/businessEditV2/hooks/editV2utils/useBusinessHasOpenHours';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import usePublisherErrors from 'app/businessEditV2/hooks/publisherErrors/usePublisherErrors';

import { ColorState } from './types';

export default function useOpenHoursSectionColor(): ColorState {
    // Hooks
    const { data: publisherErrors } = usePublisherErrors({});

    const openHoursPermission = useFieldMetadata('hours', 'open_hours')?.enabled;
    const isClosed = useIsBusinessClosed();
    const hasOpenHours = useBusinessHasOpenHours();

    // Closed
    if (isClosed) {
        return ColorState.CLOSED;
    }

    // Disabled
    if (!openHoursPermission) {
        return ColorState.DISABLED;
    }

    // Publisher Errors
    if (publisherErrors?.hours?.open_hours) {
        return ColorState.PRECHECK_ERRORS;
    }

    // Missing Fields
    if (!hasOpenHours) {
        return ColorState.MISSING_WARNING;
    }

    // OK
    return ColorState.OK;
}
