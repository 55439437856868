import React from 'react';

import { useDispatch } from 'react-redux';

import PageHeader from 'app/common/designSystem/components/molecules/PageHeader';
import { collapseSidebar } from 'app/common/reducers/global';

const BusinessCreateHeader = () => {
    const dispatch = useDispatch();
    const openSidebar = () => dispatch(collapseSidebar());

    return <PageHeader title="add_business" collapseSidebar={openSidebar} />;
};

export default BusinessCreateHeader;
