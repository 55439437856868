import styled from '@emotion/styled';

export const DRAGGING_OPACITY = 0;

export const CustomFieldsListDroppable = styled.div`
    > *:last-child {
        margin-bottom: 0;
    }
`;

export const FieldDraggable = styled.div`
    margin-bottom: 16px;
`;
