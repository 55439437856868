import { parseISO } from 'date-fns';

import { CommentData } from 'app/api/types/comments';
import {
    FeedbackResultComment,
    FeedbackResultData,
    FeedbackResultField,
    FeedbackResultState,
} from 'app/api/types/feedbackResults';
import { ReviewData, ReviewDataState, ReviewObjectType } from 'app/api/types/review';
import { ReviewObject } from 'app/states/reviews';
import { NOT_TREATED_REVIEWS } from 'app/states/reviews/filters';

const getOriginalContent = (content?: string) => {
    if (!content) {
        return content;
    }

    let contentParts = content.split('\n\n(Original)\n');
    if (contentParts.length == 2) {
        return contentParts[1];
    }

    contentParts = content.split('\n\n(Translated by Google)\n');
    if (contentParts.length == 2) {
        return contentParts[0];
    }

    return content;
};

const formatContent = (review: ReviewData) => {
    if (review.partner !== 'google_my_business') {
        return review;
    }
    review.content = getOriginalContent(review.content);
    if (review.comments.length > 0) {
        review.comments.forEach(comment => {
            comment.content = getOriginalContent(comment.content);
        });
    }

    return review;
};

const formatFeedbackResult = (feedbackResults: FeedbackResultData): ReviewObject => {
    const formatFeedbackResultComments = (
        comments: Array<FeedbackResultComment>,
    ): Array<CommentData> =>
        comments.map(comment => ({
            ...comment,
            review_id: comment.feedback_result_id,
            date: comment.created,
            can_edit: false,
            partner_id: '',
            reply_template_id: '',
            replies: [],
            parent_id: 0,
            update_date: comment.created,
        }));

    const formatFields = (fields: Array<FeedbackResultField>) =>
        fields.map(field => ({
            type: field.feedback_form_field.field_type,
            question: field.feedback_form_field.field_label,
            rating: field.rating,
            content: field.content,
        }));

    return {
        reviewObjectType: ReviewObjectType.FEEDBACK_RESULT,
        authorName: [feedbackResults.firstname, feedbackResults.lastname].filter(Boolean).join(' '),
        businessId: feedbackResults.business_id,
        content: feedbackResults.content,
        date: parseISO(feedbackResults.created).valueOf(),
        updateDate: parseISO(feedbackResults.created).valueOf(),
        deleteDate: null,
        recommended: feedbackResults.nps > 8,
        details: formatFields(feedbackResults.feedback_result_fields),
        id: Number(feedbackResults.id),
        key: feedbackResults.id,
        lang: null,
        link: null,
        orgId: 0,
        partner: '',
        partnerId: '',
        rating: feedbackResults.nps,
        replyComment: '',
        // TODO: just use the same format in the frontend :)
        state:
            feedbackResults.state === FeedbackResultState.NOT_TREATED
                ? NOT_TREATED_REVIEWS
                : feedbackResults.state,
        title: null,
        seqUnfoundCount: 0,
        tags: [],
        pendingAutoReply: null,
        comments: formatFeedbackResultComments(feedbackResults.comments ?? []),
    };
};

const formatReviewResult = (review: ReviewData): ReviewObject => {
    const formatComments = (comments: Array<CommentData>): Array<CommentData> =>
        comments.map(comment => ({
            ...comment,
            reviewId: comment.review_id,
            date: comment.created,
            canEdit: comment.can_edit,
            partnerId: comment.partner_id,
            replyTemplateId: comment.reply_template_id,
            replies: comment.replies,
            parentId: comment.parent_id ?? 0,
            updateDate: comment.update_date ?? comment.created,
        }));

    return {
        reviewObjectType: ReviewObjectType.REVIEW,
        authorName: review.author_name,
        businessId: review.business_id,
        content: review.content ?? null,
        date: parseISO(review.date).valueOf(),
        updateDate: parseISO(review.update_date).valueOf(),
        deleteDate: review.delete_date ? parseISO(review.delete_date).valueOf() : null,
        recommended: review.recommended ?? false,
        id: review.id,
        key: review.key,
        lang: null,
        link: review.link ?? null,
        orgId: review.org_id,
        partner: review.partner,
        partnerId: review.partner_id,
        rating: review.rating ?? 0,
        replyComment: '',
        state: review.state === ReviewDataState.NOT_TREATED ? NOT_TREATED_REVIEWS : review.state,
        title: review.title ?? null,
        seqUnfoundCount: 0,
        tags: review.tags,
        pendingAutoReply: review.pending_auto_reply
            ? {
                  content: review.pending_auto_reply.content,
                  scheduledAt: review.pending_auto_reply.scheduled_at,
              }
            : null,
        comments: formatComments(review.comments ?? []),
        details: undefined,
    };
};

export const formatReviewApiStateToInternalState = (
    review: ReviewData | FeedbackResultData,
): ReviewObject => {
    if ('feedback_result_fields' in review) {
        return formatFeedbackResult(review);
    }

    const updatedReviewContent = formatContent(review);

    return formatReviewResult(updatedReviewContent);
};
