import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';

import { CustomFields as CustomFieldsV2 } from './CustomFields';

export const CustomFields = () => {
    const { t } = useTranslation();

    return (
        <>
            <DynamicPageHeader title={t('custom_fields')} />
            <CustomFieldsV2 />
        </>
    );
};
