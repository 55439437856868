import { useEffect, useState } from 'react';

import {
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    Modal,
    TextInput,
} from '@partoohub/ui';
import { cloneDeep, isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import SelectImageDropzone from './SelectImageDropzone';
import SelectImageValueModal from './SelectImageValueModal';
import { SelectButton } from '../../CustomFieldForm.styled';
import {
    PossibleValueContainerWrapper,
    PossibleValueIcons,
    PossibleValueImageContainer,
} from '../FormType.styled';

type Props = {
    params: Record<string, any>;
    updateParams: (typeParams: Record<string, any>) => void;
    editMode: boolean;
    duplicates: Record<string, any>;
};

export const FormTypeSelectImage = ({
    params: oldParams,
    updateParams,
    editMode,
    duplicates,
}: Props) => {
    const { t } = useTranslation();

    const params = cloneDeep(oldParams);

    const [autofocusOnNewOption, setAutofocusOnNewOption] = useState<boolean>(false);
    const [showValueModal, setShowValueModal] = useState<boolean>(false);
    const [modalIndex, setModalIndex] = useState<number>(0);
    const [modalValue, setModalValue] = useState<string>('');

    // Format data in the first render
    useEffect(() => {
        if (!editMode) {
            updateParams({
                ...params,
                possible_images_labels: [''],
                possible_images_urls: [''],
                possible_values: [''],
            }); // If EDITION mode, we need to transform our data
        } else {
            updateParams({
                ...params,
                editValues: transformPossibleValuesForEdit(
                    params.possible_images_labels,
                    params.possible_images_urls,
                    params.possible_values,
                ),
            });
        }
    }, []);

    // From old component, not sure what it does but it's necessary
    useEffect(() => {
        if (editMode && params.editValues === undefined) {
            updateParams({
                ...params,
                editValues: transformPossibleValuesForEdit(
                    params.possible_images_labels,
                    params.possible_images_urls,
                    params.possible_values,
                ),
            });
            setAutofocusOnNewOption(false);
        }
    });

    /*
      Transform our data to:
      [
          {
              oldValues: { label: '', url: '', value: ''},
              newValues: { label: '', url: '', value: ''},
          },
      ]
      for the edit.
      If deleted value, newValues = null.
      If created value, oldValues = null.
    */
    const transformPossibleValuesForEdit = (
        labels: Array<string>,
        urls: Array<string>,
        values: Array<string>,
    ) =>
        values.map((value, index) => ({
            oldValues: {
                label: labels[index],
                url: urls[index],
                value,
            },
            newValues: {
                label: labels[index],
                url: urls[index],
                value,
            },
        }));

    // Change label in CREATION mode
    const changeLabelValue = (value: string, index: number) => {
        const labels = params.possible_images_labels;
        labels[index] = value;
        updateParams({ ...params, possible_images_labels: labels });
    };

    // Change label in EDITION mode
    const changeLabelValueEdit = (value: string, index: number) => {
        const { editValues } = params;
        editValues[index].newValues.label = value;
        updateParams({ ...params, editValues });
    };

    // Change URL in CREATION mode
    const changeUrlValue = (value: string, index: number) => {
        const urls = params.possible_images_urls;
        const values = params.possible_values;

        if (urls[index] === values[index]) {
            values[index] = value;
        }

        urls[index] = value;
        updateParams({
            ...params,
            possible_images_urls: urls,
            possible_values: values,
        });
    };

    // Change URL in EDITION mode
    const changeUrlValueEdit = (value: string, index: number) => {
        const { editValues } = params;

        if (editValues[index].newValues.url === editValues[index].newValues.value) {
            editValues[index].newValues.value = value;
        }

        editValues[index].newValues.url = value;
        updateParams({ ...params, editValues });
    };

    // Change value in CREATION mode
    const changeValue = (value: string, index: number) => {
        const values = params.possible_values;
        const urls = params.possible_images_urls;

        if (value === '') {
            values[index] = urls[index];
        } else {
            values[index] = value;
        }

        setShowValueModal(false);
        updateParams({ ...params, possible_values: values });
    };

    // Change value in CREATION mode
    const changeValueEdit = (value: string, index: number) => {
        const { editValues } = params;

        if (value === '') {
            editValues[index].newValues.value = editValues[index].newValues.url;
        } else {
            editValues[index].newValues.value = value;
        }

        setShowValueModal(false);
        updateParams({ ...params, editValues });
    };

    // Add an option in CREATION mode
    const addOption = () => {
        const labels =
            params.possible_images_labels === undefined ? [] : params.possible_images_labels;
        const urls = params.possible_images_urls === undefined ? [] : params.possible_images_urls;
        const values = params.possible_values === undefined ? [] : params.possible_values;
        labels.push('');
        urls.push('');
        values.push('');

        setAutofocusOnNewOption(true);
        updateParams({
            ...params,
            possible_images_labels: labels,
            possible_images_urls: urls,
            possible_values: values,
        });
    };

    // Delete an option in CREATION mode
    const removeOption = (index: number) => {
        const labels = params.possible_images_labels;
        const urls = params.possible_images_urls;
        const values = params.possible_values;
        labels.splice(index, 1);
        urls.splice(index, 1);
        values.splice(index, 1);
        updateParams({
            ...params,
            possible_images_labels: labels,
            possible_images_urls: urls,
            possible_values: values,
        });
    };

    // Add an option in EDITION mode
    const addOptionEdit = () => {
        const editValues = params.editValues === undefined ? [] : params.editValues;
        editValues.push({
            oldValues: null,
            newValues: {
                label: '',
                url: '',
                value: '',
            },
        });

        setAutofocusOnNewOption(true);
        updateParams({ ...params, editValues });
    };

    // Remove an option in EDITION mode, set newValues to null if it's an existing option
    const removeOptionEdit = (index: number) => {
        const { editValues } = params;

        if (editValues[index].oldValues === null) {
            editValues.splice(index, 1);
        } else {
            editValues[index].newValues = null;
        }

        updateParams({ ...params, editValues });
    };

    const updateShowValueModal = (index: number, value: string) => {
        setShowValueModal(true);
        setModalIndex(index);
        setModalValue(value);
    };

    const hideValueModal = () => {
        setShowValueModal(false);
    };

    const hasDuplicateErrors = (
        duplicates: Record<string, any>,
        value: string,
        index: number,
        url: string,
    ) => ({
        hasError: !isEmpty(duplicates) && duplicates[index.toString()] === value,
        valueError: !isEmpty(duplicates) && duplicates[index.toString()] === value && value !== url,
    });

    return (
        <div>
            <PossibleValueContainerWrapper>
                {!editMode &&
                    params.possible_values &&
                    params.possible_values.map((value, index) => {
                        const duplicateError = hasDuplicateErrors(
                            duplicates,
                            value,
                            index,
                            params.possible_images_urls[index],
                        );
                        return (
                            // CREATION MODE
                            <PossibleValueImageContainer key={index}>
                                <TextInput
                                    dataTrackId="custom_fields_image__create_label"
                                    value={params.possible_images_labels[index] || ''}
                                    onChange={newValue => changeLabelValue(newValue, index)}
                                    label={t('image_title')}
                                    autoFocus={autofocusOnNewOption}
                                />
                                <TextInput
                                    dataTrackId="custom_fields_image__create_url"
                                    value={params.possible_images_urls[index]}
                                    onChange={newValue => changeUrlValue(newValue, index)}
                                    label={t('url')}
                                    error={duplicateError.hasError}
                                    notice={
                                        duplicateError.hasError
                                            ? t('custom_fields_option_already_exist')
                                            : ''
                                    }
                                />

                                <SelectImageDropzone
                                    url={params.possible_images_urls[index]}
                                    setUrl={newValue => changeUrlValue(newValue, index)}
                                />

                                <PossibleValueIcons>
                                    <IconButton
                                        appearance="outlined"
                                        onClick={() => updateShowValueModal(index, value)}
                                        icon={['fa-cog', IconPrefix.SOLID]}
                                        disabled={params.possible_images_urls[index] === ''}
                                        dataTrackId="custom_fields_select_image__associate_data"
                                        tooltip={
                                            params.possible_images_urls[index] === ''
                                                ? ''
                                                : t('associate_data')
                                        }
                                    />
                                    <IconButton
                                        appearance="outlined"
                                        variant="danger"
                                        onClick={() => removeOption(index)}
                                        icon={[
                                            FontAwesomeIconsPartooUsed.faTrash,
                                            IconPrefix.REGULAR,
                                        ]}
                                        dataTrackId="custom_fields_select_image__remove_option"
                                        tooltip={t('delete')}
                                    />
                                </PossibleValueIcons>
                            </PossibleValueImageContainer>
                        );
                    })}
                {editMode &&
                    params.editValues &&
                    params.editValues.map((value, index) => {
                        let duplicateError;

                        if (value.newValues !== null) {
                            duplicateError = hasDuplicateErrors(
                                duplicates,
                                value.newValues.value,
                                index,
                                value.newValues.url,
                            );
                        }

                        return value.newValues !== null ? (
                            /* EDITION, display option if newValue not null */
                            <PossibleValueImageContainer key={index}>
                                <TextInput
                                    dataTrackId="custom_fields_image__edit_label"
                                    value={value.newValues.label}
                                    onChange={newValue =>
                                        changeLabelValueEdit(newValue ?? '', index)
                                    }
                                    label={t('image_title')}
                                    autoFocus={autofocusOnNewOption}
                                />
                                <TextInput
                                    dataTrackId="custom_fields_image__edit_label"
                                    value={value.newValues.url}
                                    onChange={newValue => changeUrlValueEdit(newValue ?? '', index)}
                                    label={t('url')}
                                    error={duplicateError && duplicateError.hasError}
                                    notice={
                                        duplicateError && duplicateError.hasError
                                            ? t('custom_fields_option_already_exist')
                                            : ''
                                    }
                                />

                                <SelectImageDropzone
                                    url={value.newValues.url}
                                    setUrl={newValue => changeUrlValueEdit(newValue ?? '', index)}
                                />

                                <PossibleValueIcons>
                                    <IconButton
                                        appearance="outlined"
                                        onClick={() =>
                                            updateShowValueModal(index, value.newValues.value)
                                        }
                                        icon={['fa-cog', IconPrefix.SOLID]}
                                        disabled={value.newValues.url === ''}
                                        dataTrackId="custom_fields_select_image__associate_data"
                                        tooltip={
                                            value.newValues.url === '' ? '' : t('associate_data')
                                        }
                                    />
                                    <IconButton
                                        appearance="outlined"
                                        variant="danger"
                                        onClick={() => removeOptionEdit(index)}
                                        icon={[
                                            FontAwesomeIconsPartooUsed.faTrash,
                                            IconPrefix.REGULAR,
                                        ]}
                                        dataTrackId="custom_fields_select_image__remove_option"
                                        tooltip={t('delete')}
                                    />
                                </PossibleValueIcons>
                            </PossibleValueImageContainer>
                        ) : null;
                    })}
            </PossibleValueContainerWrapper>

            <SelectButton
                size="medium"
                variant="primary"
                appearance="outlined"
                shape="cube"
                onClick={editMode ? addOptionEdit : addOption}
                icon={[FontAwesomeIconsPartooUsed.faPlus, IconPrefix.SOLID]}
                dataTrackId="custom_fields_image__add_option"
            >
                {t('add_option')}
            </SelectButton>

            <Modal isOpen={showValueModal} closeModal={hideValueModal}>
                <SelectImageValueModal
                    value={modalValue}
                    saveValue={newValue =>
                        editMode
                            ? changeValueEdit(newValue, modalIndex)
                            : changeValue(newValue, modalIndex)
                    }
                    hideModal={hideValueModal}
                />
            </Modal>
        </div>
    );
};
