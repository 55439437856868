import { useTranslation } from 'react-i18next';

import { LoadRealtimeButton } from './LoadRealtimeUpdatesButton.styled';
import { useRefreshReviewList } from '../hooks/useReviewListWebsocket';

export function LoadRealtimeUpdatesButton() {
    const { t } = useTranslation();
    const { showRefreshAction, refreshReviewList } = useRefreshReviewList();

    return (
        <LoadRealtimeButton
            show={showRefreshAction}
            type="button"
            onClick={() => refreshReviewList()}
        >
            <i className="fa-solid fa-arrow-up" />
            {t('refresh')}
        </LoadRealtimeButton>
    );
}
