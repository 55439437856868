import { differenceInDays, differenceInMonths, parse } from 'date-fns';

import { ReviewAnalyticsDateDimension } from 'app/api/types/review_analytics';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { DATE_FORMAT } from 'app/utils/dates';

import {
    REVIEW_ANALYTICS_FILTER_DATE_FROM,
    REVIEW_ANALYTICS_FILTER_DATE_TO,
} from './useReviewAnalyticsFilters';

export const useDateDimension = (): ReviewAnalyticsDateDimension => {
    const [from] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_DATE_FROM);
    const [to] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_DATE_TO);

    const dateFrom = from ? parse(from, DATE_FORMAT, new Date()) : new Date(0);
    const dateTo = to ? parse(to, DATE_FORMAT, new Date()) : new Date();

    const daysDiff = differenceInDays(dateTo, dateFrom);
    if (daysDiff < 45) return 'day';

    const monthsDiff = differenceInMonths(dateTo, dateFrom);
    if (monthsDiff < 16) return 'month';

    return 'year';
};
