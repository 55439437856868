import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { Text } from '@partoohub/ui';

import Flexbox from 'app/styles/utils/flexbox';

export const PreviewPanel = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
`;

// Image size: 248 × 496 px
export const IPhoneTemplate = styled.image`
    width: auto;
    max-height: auto;
    margin-right: auto;
    margin-left: auto;
    position: relative;
`;

export const PreviewBusinessHeaderContainer = styled.div`
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0 20px;
`;

export const PreviewBusinessHeader = styled.div`
    display: flex;
    justify-content: center;
`;

export const PreviewBusinessName = styled(Text)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const PreviewHeading = styled.div`
    margin: 64px auto 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const PreviewHeaderIcon = styled.img`
    height: 30px;
`;

export const PreviewMessages = styled.div`
    position: absolute;
    width: 100%;
    top: 84px;
    max-height: 344px;
    padding: 0 20px 5px;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const PreviewBusinessLabel = styled.div`
    margin-left: 40px;
`;

export const PreviewMessageContainer = styled(Flexbox)`
    flex-direction: row;
    justify-content: flex-start;
    ${(props: { isConsumerMessage?: boolean }) =>
        props.isConsumerMessage &&
        css`
            justify-content: flex-end;
        `}
    align-items: end;
    margin-top: 4px;
`;

export const PreviewSettingIcon = styled.img`
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: auto;
    border-radius: 12px;
    object-fit: cover;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
`;

export const PreviewBusinessMessageBubble = styled.div`
    border-radius: 20px;
    max-width: 160px;
    padding: 12px 16px;
    display: inline-block;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
`;

export const PreviewConversationStarterMessageBubble = styled(PreviewBusinessMessageBubble)<{
    active?: boolean;
}>`
    margin-left: 24px;
    transition: all 0.2s ease;
    &:hover {
        cursor: pointer;
        background-color: #f6f8fc;
    }
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    ${({ active, theme }) =>
        active &&
        css`
            border: 1px solid ${theme.colors.primary.initial};
            box-shadow: 0 0 0 3px rgba(0, 133, 242, 0.12);
        `}
`;

export const PreviewWelcomeMessageBubble = styled(PreviewBusinessMessageBubble)`
    background-color: #f6f8fc;
`;

export const PreviewReplyMessageBubble = styled(PreviewBusinessMessageBubble)`
    background: linear-gradient(127deg, #03d2f1 14.42%, #0085f2 85.42%);
    .mentions__mention_preview {
        color: ${HEX_COLORS.white};
    }
`;

export const PreviewConsumerMessageBubble = styled(Flexbox)`
    flex-direction: row;
    justify-content: flex-end;
    border-radius: 20px 20px 0 20px;
    background: ${({ theme }) => theme.colors.default.alpha};
    max-width: 160px;
    padding: 8px;
    margin-top: 4px;
    color: ${HEX_COLORS.black};
    font-size: 12px;
    word-break: break-word;
`;

export const TextPlaceholder = styled.p`
    font-size: 30px;
    font-weight: bold;
    color: ${greyColorObject.initial};
    position: absolute;
    top: 50%;
    text-align: center;
    display: flex;
    align-self: center;
`;

export const PreviewMessagesContainer = styled.div`
    ${(props: { active?: boolean }) =>
        !props.active &&
        css`
            opacity: 0.5;
        `}
`;
