import { CommentData } from 'app/api/types/comments';
import { ReviewObject } from 'app/states/reviews';

const replaceCommentData = (
    reviewComments: Array<CommentData>,
    newComment: CommentData,
): Array<CommentData> => {
    let commentWasFound = false;

    return reviewComments.map(reviewComment => {
        if (commentWasFound) return reviewComment;

        if (reviewComment.id === newComment.id) {
            commentWasFound = true;
            return { ...newComment, replies: reviewComment.replies };
        }
        if (reviewComment.replies) {
            return {
                ...reviewComment,
                replies: replaceCommentData(reviewComment.replies, newComment),
            };
        }
        return reviewComment;
    });
};

export const updateReviewCommentData = (review: ReviewObject, commentData: CommentData) => {
    const { comments: reviewComments } = review;
    const newComments = replaceCommentData(reviewComments, commentData);
    return { ...review, comments: newComments };
};
