import { Stack } from '@partoohub/ui/dist/components/Stack';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTranslation } from 'react-i18next';

import { timeUnitConverter } from 'app/reviewManagement/messaging/services/timeUnitConverter';

import { IsReadDot } from './ConversationCard/ConversationCard.styled';
import { ColoredDot, DelayText, OverridenTooltipWrapper } from './DurationAnchor.styled';

dayjs.extend(duration);

/**
 * Use this component to display a human readable duration and special warnings after timeout.
 */
interface DurationAnchorProps {
    anchorDuration: number;
    shouldDisplayDot: boolean;
    timeBeforeTimeout: number; //in ms
    isRead: boolean;
}

export const DurationAnchor = ({
    anchorDuration,
    shouldDisplayDot,
    timeBeforeTimeout,
    isRead,
}: DurationAnchorProps) => {
    return (
        <Stack direction="row">
            {shouldDisplayDot ? (
                <Tooltip anchorDuration={anchorDuration} timeBeforeTimeout={timeBeforeTimeout} />
            ) : (
                <DelayText
                    variant="bodyMRegular"
                    as="span"
                    color={isRead ? 'secondary' : 'primary'}
                >
                    <IsReadDot isRead={isRead} />
                    {timeUnitConverter(anchorDuration)}
                </DelayText>
            )}
        </Stack>
    );
};

type TooltipInformation = {
    tooltipText: string;
    anchorText: string;
    timeout: boolean;
};

interface TooltipProps {
    anchorDuration: number;
    timeBeforeTimeout: number; //in ms
}

const Tooltip = ({ anchorDuration, timeBeforeTimeout }: TooltipProps) => {
    const { t } = useTranslation();

    const getTooltipData = (): TooltipInformation => {
        if (anchorDuration > timeBeforeTimeout) {
            return {
                tooltipText: t('messaging_conversation_SLA_24_tooltip'),
                anchorText: t('messaging_conversation_SLA_breached'),
                timeout: true,
            };
        }

        return {
            tooltipText: t('messaging_conversation_SLA_12_tooltip'),
            anchorText: t('messaging_conversation_SLA_at_risk', {
                hour: dayjs.duration(timeBeforeTimeout - anchorDuration).hours() + 1,
            }),
            timeout: false,
        };
    };

    const { tooltipText, anchorText, timeout } = getTooltipData();

    return (
        <OverridenTooltipWrapper
            text={tooltipText}
            position="right-start"
            className="conversation-card__content__sla__tooltip"
        >
            <ColoredDot timeout={timeout}>{anchorText}</ColoredDot>
        </OverridenTooltipWrapper>
    );
};
