import { PropsWithChildren } from 'react';

import { DragDropContext } from 'react-beautiful-dnd';

interface Props {
    onDragEndCallback: (destination: any, source: any) => void;
}

export const DragDropContextWrapper = ({
    onDragEndCallback,
    children,
}: PropsWithChildren<Props>) => {
    const onDragEnd = (result: any) => {
        const { destination, source } = result;
        // If item dropped outside of the droppable box
        if (!destination) {
            return;
        }
        // If item dropped at the same place.
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        onDragEndCallback(destination, source);
    };

    return <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>;
};
