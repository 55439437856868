import React, { useEffect, useRef, useState } from 'react';

import {
    Button,
    Chip,
    FontAwesomeIconsPartooUsed,
    IconButton,
    Modal,
    Stack,
    Text,
} from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { CHATBOTS } from 'app/common/data/queryKeysConstants';
import { ActivationDialog } from 'app/pages/settings/Chatbot/components/Activation/ChatbotActivationDialog';
import { ChatbotBanner } from 'app/pages/settings/Chatbot/components/PageContent/Banner';
import { ChatbotSections } from 'app/pages/settings/Chatbot/components/PageContent/ChatbotSections';
import { ChatbotPreview } from 'app/pages/settings/Chatbot/components/Preview/ChatbotPreview';
import { useChatbot } from 'app/pages/settings/Chatbot/hooks/useChatbot';
import { useChatbotActivation } from 'app/pages/settings/Chatbot/hooks/useChatbotActivation';
import { useChatbotDelete } from 'app/pages/settings/Chatbot/hooks/useChatbotDelete';
import { useChatbotUpdate } from 'app/pages/settings/Chatbot/hooks/useChatbotUpdate';
import { ChatbotType } from 'app/pages/settings/Chatbot/type/chatbot';

import {
    ActivationButtonContainer,
    BackIconButton,
    ChatPreviewContainer,
    ChatPreviewTitle,
    ChatbotConfigurationContainer,
    ChatbotConfigurationSectionContainer,
    ChatbotConfigurationSectionWrapper,
    ChatbotHeaderContainer,
    ChatbotNameInput,
    ChatbotNameText,
    HiddenChatbotName,
    NameSkeleton,
} from 'app/pages/settingsV2/subPages/Messages/components/Chatbots/ChatbotConfiguration.styled';
import queryClient from 'app/states/queryClient';

export const ChatbotName = () => {
    const { t } = useTranslation();
    const { data, isLoading } = useChatbot();
    const [name, setName] = useState(data?.name ?? '');
    const [isEditing, setIsEditing] = useState(false);
    const updateChatbotMutation = useChatbotUpdate();
    const inputRef = useRef<HTMLInputElement>(null);
    const hiddenRef = useRef<HTMLSpanElement>(null);
    const iconRef = useRef<HTMLElement>(null);

    const handleSubmit = () => {
        if (name.length >= 1) {
            // Only change the name if it contains a value.
            updateChatbotMutation.mutate({ name }, { onSuccess: () => setIsEditing(false) });
        }
    };

    const handleClick = () => {
        if (isEditing) {
            handleSubmit();
        } else {
            setIsEditing(true);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!iconRef.current?.contains(event.relatedTarget as Node)) {
            handleSubmit();
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.width = `${hiddenRef.current?.clientWidth}px`;
        }
        if (isEditing) {
            inputRef.current?.focus();
        }
    }, [isEditing, name]);

    useEffect(() => {
        setName(data?.name ?? '');
    }, [data]);

    if (isLoading && !data) {
        return <NameSkeleton variant="line" />;
    }

    return (
        <Stack direction="row" alignItems="center">
            {isEditing ? (
                <>
                    <ChatbotNameInput
                        value={name}
                        onChange={event => setName(event.target.value)}
                        onKeyDown={handleKeyDown}
                        onBlur={onBlur}
                        ref={inputRef}
                        placeholder={t('chatbot_page_title')}
                        maxLength={50}
                    />
                    <HiddenChatbotName ref={hiddenRef}>
                        <Text as="span" variant="heading4">
                            {name || data?.name || t('chatbot_page_title')}
                        </Text>
                    </HiddenChatbotName>
                </>
            ) : (
                <div onClick={handleClick} onKeyDown={handleClick}>
                    <ChatbotNameText variant="heading4">
                        {name || data?.name || t('chatbot_page_title')}
                    </ChatbotNameText>
                </div>
            )}
            <IconButton
                ref={iconRef}
                icon={[isEditing ? 'fa-check' : 'fa-pen-clip']}
                dataTrackId="chatbot-rename-button"
                variant={isEditing ? 'primary' : 'default'}
                size="xsmall"
                appearance="outlined"
                onClick={handleClick}
            />
        </Stack>
    );
};

const isChatbotModified = (data?: ChatbotType) => {
    if (!data) {
        return false;
    }
    return !!data.business_ids.length || !!data.knowledge[0].updated_at || !!data.updated_at;
};

export const ChatbotConfiguration = () => {
    const { data } = useChatbot();
    const [isEnabled, setIsEnabled] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const { t } = useTranslation();
    const ChatbotActivationMutation = useChatbotActivation(data?.id);
    const ChatbotDeleteMutation = useChatbotDelete(data?.id);
    const navigate = useNavigate();

    useEffect(() => {
        setIsEnabled(data?.enabled ?? false);
    }, [data]);

    const handleActivateClick = () => {
        if (isEnabled) {
            setShowDeactivateModal(true);
        } else {
            ChatbotActivationMutation.mutate(!isEnabled);
            setModalIsOpen(true);
        }
    };

    return (
        <ChatbotConfigurationContainer>
            <BackIconButton
                appearance="contained"
                onClick={() => {
                    if (!isChatbotModified(data)) {
                        ChatbotDeleteMutation.mutate(undefined, {
                            onSuccess: () => {
                                queryClient.removeQueries([CHATBOTS]);
                                navigate({ search: '?chatbot_id=' });
                            },
                        });
                    } else {
                        navigate({ search: '?chatbot_id=' });
                    }
                }}
                icon={[FontAwesomeIconsPartooUsed.faArrowLeft]}
                dataTrackId="close_chatbot_configuration_button"
            />

            <ChatbotConfigurationSectionWrapper>
                <ChatbotConfigurationSectionContainer>
                    <ChatbotHeaderContainer
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <ChatbotName />
                        {data?.enabled ? (
                            <Chip dataTrackId="" chipShape="cube" variant="emerald">
                                <Text color="emerald" variant="bodySBold">
                                    {t('chatbot_active_state')}
                                </Text>
                            </Chip>
                        ) : (
                            <Chip dataTrackId="" chipShape="cube" variant="warning">
                                <Text color="warning" variant="bodySBold">
                                    {t('chatbot_paused_state')}
                                </Text>
                            </Chip>
                        )}
                    </ChatbotHeaderContainer>
                    <ChatbotBanner />
                    <ChatbotSections />
                </ChatbotConfigurationSectionContainer>

                <ActivationButtonContainer>
                    <Button
                        onClick={handleActivateClick}
                        dataTrackId="chatbot_activate_button"
                        appearance="contained"
                        shape="cube"
                        variant={isEnabled ? 'secondary' : 'success'}
                        size="large"
                        full
                        disabled={!data?.business_ids.length}
                    >
                        {isEnabled ? t('chatbot_deactivate_button') : t('chatbot_activate_button')}
                    </Button>
                </ActivationButtonContainer>
            </ChatbotConfigurationSectionWrapper>

            <ChatPreviewContainer>
                <ChatPreviewTitle variant="heading6">{t('chatbot_preview_title')}</ChatPreviewTitle>
                <ChatbotPreview />
            </ChatPreviewContainer>

            <Modal isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)}>
                <ActivationDialog onClose={() => setModalIsOpen(false)} />
            </Modal>

            <ConfirmModal
                show={showDeactivateModal}
                title={t('chatbot_configuration_deactivate_modal_title')}
                content={t('chatbot_configuration_deactivate_modal_content')}
                confirmLabel={t('chatbot_configuration_deactivate_button')}
                modalFor="warning"
                onConfirm={() => {
                    ChatbotActivationMutation.mutate(false);
                    setShowDeactivateModal(false);
                }}
                onHide={() => setShowDeactivateModal(false)}
                trackId="chatbot_configuration__deactivate_modal"
            />
        </ChatbotConfigurationContainer>
    );
};
