import { useMemo } from 'react';

import {
    StyledInternalRankingSection,
    TagsRankingContainer,
} from './InternalRankingSection.styled';
import { EReputationRanking, ReplyMeansRanking, ReplyTimeRanking, TagsRanking } from './Rankings';
import { useGraphSelection } from '../../hooks/useGraphSelection';

export const InternalRankingSection = () => {
    const [graph] = useGraphSelection();

    const { ContainerComponent, RankingComponent } = useMemo(() => {
        return (
            {
                average_rating: {
                    ContainerComponent: StyledInternalRankingSection,
                    RankingComponent: EReputationRanking,
                },
                review_count: {
                    ContainerComponent: StyledInternalRankingSection,
                    RankingComponent: EReputationRanking,
                },
                rating_distribution: {
                    ContainerComponent: StyledInternalRankingSection,
                    RankingComponent: EReputationRanking,
                },
                reply_means: {
                    ContainerComponent: StyledInternalRankingSection,
                    RankingComponent: ReplyMeansRanking,
                },
                reply_time: {
                    ContainerComponent: StyledInternalRankingSection,
                    RankingComponent: ReplyTimeRanking,
                },
                tags: {
                    ContainerComponent: TagsRankingContainer,
                    RankingComponent: TagsRanking,
                },
            }[graph] ?? { ContainerComponent: undefined, RankingComponent: undefined }
        );
    }, [graph]);

    if (!RankingComponent || !ContainerComponent) return undefined;

    return (
        <ContainerComponent>
            <RankingComponent />
        </ContainerComponent>
    );
};
