import useBusinessAttributes from 'app/businessEditV2/hooks/attributes/attributesBusiness/useBusinessAttributes';
import useAttributes from 'app/businessEditV2/hooks/attributes/attributesMetadata/useAttributes';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';

import useBusinessPlaceActionLinks from '../../placeActionLinks/useBusinessPlaceActionLinks';
import usePlaceActionLinks from '../../placeActionLinks/usePlaceActionLinks';

export function useIsLoadingLinks(): boolean {
    const { isLoading: isLoadingBusiness } = useBusiness();
    const { isLoading: isLoadingAttributes } = useAttributes();
    const { isLoading: isLoadingBusinessAttributes } = useBusinessAttributes();
    const { isLoading: isLoadingBusinessPlaceActionLinks } = useBusinessPlaceActionLinks();
    const { isLoading: isLoadingPlaceActionLinks } = usePlaceActionLinks();

    return (
        isLoadingBusiness ||
        isLoadingAttributes ||
        isLoadingBusinessAttributes ||
        isLoadingBusinessPlaceActionLinks ||
        isLoadingPlaceActionLinks
    );
}
