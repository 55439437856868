import { connect } from 'react-redux';

import { modalRedirectionUrlSelector } from 'app/states/connection/reducers/login';
import { AppState } from 'app/states/reducers';

import ChangePasswordModal from './ChangePasswordModal';

const mapStateToProps = ({ login }: AppState) => ({
    modalRedirectionUrl: modalRedirectionUrlSelector(login),
});

const ChangePasswordModalComponent =
    connect(mapStateToProps)(ChangePasswordModal);

export default ChangePasswordModalComponent;
