import styled from '@emotion/styled';

export const StatusScreenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    height: calc(100% - 100px);

    text-align: center;
`;

export const StatusScreenImageWrapper = styled.div`
    position: relative;

    margin: 0 auto;
    padding: 0px 44px;

    max-width: 420px;
`;
