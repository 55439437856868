import React, { useMemo } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { Stack } from '@partoohub/ui';
import { uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
// TODO: Replace by InfiniteScroll from 'react-infinite-scroll-component'
import InfiniteScroll from 'react-infinite-scroller';

import { useInfiniteQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { API_KEYS_LIST } from 'app/common/data/queryKeysConstants';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    ApiKeyStatusEnum,
    ApiKeysQueryKeys,
} from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/utils/enums';
import { useApiKeyContext } from 'app/settingsManagement/components/ApiKey/ApiKey.context';
import ApiKeyManagerHeaderSection from 'app/settingsManagement/components/ApiKey/ApiKeyManagerHeaderSection';

import { ApiKeyListingEmptyPlaceholderContainer } from './ApiKeyListing.styled';
import ApiKeyCard from '../ApiKeyCard';
import ApiKeyListingHeader from '../ApiKeyListingHeader';
import ApiKeyLoadingCard from '../ApiKeyLoadingCard';

const ApiKeyListing: React.FC = () => {
    const { t } = useTranslation();
    const { setApiKeyShowModalForm } = useApiKeyContext();
    const [typeQuery] = useStateQueryParams(ApiKeysQueryKeys.TYPE);
    const [statusQuery] = useStateQueryParams(ApiKeysQueryKeys.STATUS);
    const [searchQuery] = useStateQueryParams(ApiKeysQueryKeys.QUERY);

    const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(
        [API_KEYS_LIST, typeQuery, statusQuery, searchQuery],
        ({ pageParam = 1 }) =>
            api.apiKeys.getApiKeys(
                {
                    type: typeQuery || null,
                    status: statusQuery || ApiKeyStatusEnum.ACTIVE,
                    search_text: searchQuery || null,
                },
                pageParam,
            ),
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined,
        },
    );

    const count = data?.pages[0].count ?? 0;

    const apiKeys = useMemo(
        () => uniqBy(data?.pages?.flatMap(page => page.api_keys) ?? [], api_key => api_key.id),
        [data],
    );

    const openCreateForm = () => {
        setApiKeyShowModalForm(true);
    };

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };

    return (
        <Stack direction="column">
            <ApiKeyManagerHeaderSection count={count} />
            <ApiKeyListingHeader />
            <InfiniteScroll
                hasMore={hasNextPage && !isFetchingNextPage}
                loadMore={() => fetchNextPage()}
                useWindow={false}
                getScrollParent={() => document.getElementById('react-content')}
                threshold={500}
            >
                {isLoading && <ApiKeyLoadingCard />}
                {apiKeys.map((apiKey, index) => (
                    <ApiKeyCard key={apiKey.user_id + index} apiKey={apiKey} />
                ))}
            </InfiniteScroll>
            {!apiKeys.length && !isLoading && (
                <ApiKeyListingEmptyPlaceholderContainer>
                    <NotFoundTemplate
                        show
                        title={t('api_key_manager_empty_list_placeholder_title')}
                        subtitle={
                            !statusQuery || statusQuery === ApiKeyStatusEnum.ACTIVE
                                ? t('api_key_manager_empty_list_placeholder_message_active')
                                : t('api_key_manager_empty_list_placeholder_message_inactive')
                        }
                        imgSrc={illustrationElement}
                        buttonText={t('api_key_manager_create_button')}
                        handleClick={openCreateForm}
                        withButton={!statusQuery || statusQuery === ApiKeyStatusEnum.ACTIVE}
                    />
                </ApiKeyListingEmptyPlaceholderContainer>
            )}
        </Stack>
    );
};

export default ApiKeyListing;
