import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

export const KpiCard = styled.div`
    display: flex;
    flex-flow: column nowrap;
    border: 1px solid ${greyColorObject.initial};
    background-color: ${HEX_COLORS.white};
    border-radius: 4px;
    padding: 10px;
    height: 100%;
`;

export const KpiCardTitle = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
`;

export const KpiCardFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex - end;
    margin-top: 16px;
    height: 100%;
    .kpi_card_footer--loader {
        margin-bottom: 10px !important;
    }
`;

export const KpiCardHeader = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    .kpi_card_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 16px;
        width: 32px;
        height: 32px;
        margin-right: 8px !important;
    }
`;

export const KpiCardContent = styled.div`
    display: flex;
    flex-flow: column nowrap;
    .kpi_card_content--loader {
        margin-top: 15px !important;
    }
`;
