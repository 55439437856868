import { IconButton, IconPrefix } from '@partoohub/ui';

import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import useLocalStorage from 'app/common/hooks/useLocalStorage';

import { IconButtonWithLabelWrapper } from './MessageForm.styled';

interface ReviewBoosterButtonProps {
    reviewUrl?: string;
    setValue: UseFormSetValue<FieldValues>;
}

const ReviewBoosterButton = ({ reviewUrl, setValue }: ReviewBoosterButtonProps) => {
    const { t } = useTranslation();
    const [makeRBButtonBounce, setMakeRBButtonBounce] = useLocalStorage(
        'make_RB_button_bounce',
        true,
    );

    if (!reviewUrl) return null;

    const handleReviewRequestClick = () => {
        setValue('message', t('messaging_review_request_text', { review_url: reviewUrl }));
        if (makeRBButtonBounce) setMakeRBButtonBounce(false);
    };

    return (
        <TooltipWrapper text={t('messaging_review_button_hover_label')} position="top">
            <IconButtonWithLabelWrapper isBouncing={makeRBButtonBounce}>
                <IconButton
                    appearance="outlined"
                    icon={['fa-rocket', IconPrefix.REGULAR]}
                    onClick={handleReviewRequestClick}
                    dataTrackId="messaging_button_review_invitation"
                />
            </IconButtonWithLabelWrapper>
        </TooltipWrapper>
    );
};

export default ReviewBoosterButton;
