export const simpleArrayExclusion = (array: Array<any>, valueToAdd: any) =>
    [...array, valueToAdd].filter(a => !array.includes(a) || a !== valueToAdd);

// arrayPairExclusion adds or removes values from a pair of arrays.
// Values are unique within a single array and across both arrays.
// If one of the arrays already includes the value, this array removes this value.
// Otherwise the array with the condition set to true pushes the new value.
// ex: arrayPairExclusion([ 1, 2 ], [ 3, 4 ], [ 1, 4, 5 ], false) returns [ [ 2 ], [ 3, 5 ] ]
// ex: arrayPairExclusion([ 1, 2 ], [ 3, 4 ], [ 2, 3, 5 ], true) returns [ [ 1, 5 ], [ 4 ] ]
const arrayPairExclusion = (
    mainArray: Array<any>,
    pairArray: Array<any>,
    valuesToAdd: Array<any>,
    condition: boolean,
): Array<Array<any>> => {
    const newValues = [...valuesToAdd];
    const value = newValues.pop();

    if (value) {
        const newMainArray =
            (condition || mainArray.includes(value)) &&
            !pairArray.includes(value)
                ? simpleArrayExclusion(mainArray, value)
                : mainArray;
        const newPairArray =
            (!condition || pairArray.includes(value)) &&
            !mainArray.includes(value)
                ? simpleArrayExclusion(pairArray, value)
                : pairArray;
        return arrayPairExclusion(
            newMainArray,
            newPairArray,
            newValues,
            condition,
        );
    }

    return [mainArray, pairArray];
};

export const mainArrayValuesInPairArray = (
    mainArray: Array<any>,
    pairArray: Array<any>,
): boolean =>
    mainArray.filter(a => pairArray.includes(a)).length === mainArray.length;

export default arrayPairExclusion;
