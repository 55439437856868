import { useEffect } from 'react';

import { invalidatesAny } from 'app/businessEditV2/utils/utils';

import { MESSAGING_CONVERSATIONS } from 'app/common/data/queryKeysConstants';

import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';

import { MessagingFlexContainer } from 'app/reviewManagement/messaging/Messaging.styled';

import queryClient from 'app/states/queryClient';

import { MessagingBanners } from './banners/MessagingBanners';

import { useConversationDeeplink } from './hooks/useConversationDeeplink';
import { useConversationSetup } from './hooks/useConversationSetup';

import { useHandleBusinessFiltersChange } from './hooks/useHandleBusinessFiltersChange';
import { useHandleNewConversation } from './hooks/useHandleNewConversation';

import { MessagingUsersProvider } from './LoadedUsersProvider';
import { MessagingBody } from './MessagingBody';

import { DesktopHeader } from './pageHeader/DesktopHeader/DesktopHeader';
import { MobileHeader } from './pageHeader/MobileHeader/MobileHeader';

const Messaging = () => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);

    useHandleBusinessFiltersChange();
    useHandleNewConversation();
    useConversationSetup();
    useConversationDeeplink();

    /**
     * On component unmount, we remove the messaging cache.
     */
    useEffect(
        () => () => {
            queryClient.removeQueries(invalidatesAny(MESSAGING_CONVERSATIONS));
        },
        [],
    );

    return (
        <MessagingUsersProvider>
            <MessagingFlexContainer flexDirection="column">
                {isBelowProvidedDevices ? <MobileHeader /> : <DesktopHeader />}
                <MessagingBanners />
                <MessagingBody />
            </MessagingFlexContainer>
        </MessagingUsersProvider>
    );
};

export default Messaging;
