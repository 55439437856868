import { useQuery } from 'react-query';

import api from '../../../../../../../../api/v2/api_calls';
import { GROUPS_V2 } from '../../../../../../../../common/data/queryKeysConstants';

export default function useGroup(sectionId: string, groupId: string) {
    return useQuery(
        [GROUPS_V2, { sectionId }, { groupId }],
        () => api.groupsV2.getGroup(sectionId, groupId),
        { enabled: !!sectionId && !!groupId },
    );
}
