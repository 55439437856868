import { omit, uniq } from 'lodash-es';

import { V2FormattedLocationData, V2LocationPartnerStatusData } from 'app/api/types/account';

// REQUEST STATUS
const RUNNING = 'RUNNING';
const FAILED = 'FAILED';
const DONE = 'DONE';

// ACTION TYPES
export const GET_FACEBOOK_LOCATION_REQUEST = 'GET_FACEBOOK_LOCATION_REQUEST';
const GET_FACEBOOK_LOCATION_REQUEST_SUCCESS = 'GET_FACEBOOK_LOCATION_REQUEST_SUCCESS';
const GET_FACEBOOK_LOCATION_REQUEST_FAILURE = 'GET_FACEBOOK_LOCATION_REQUEST_FAILURE';
export const GET_FACEBOOK_LOCATION_STATUS_REQUEST = 'GET_FACEBOOK_LOCATION_STATUS_REQUEST';
const GET_FACEBOOK_LOCATION_STATUS_REQUEST_SUCCESS = 'GET_FACEBOOK_LOCATION_STATUS_REQUEST_SUCCESS';
const GET_FACEBOOK_LOCATION_STATUS_REQUEST_FAILURE = 'GET_FACEBOOK_LOCATION_STATUS_REQUEST_FAILURE';
export const FUZZY_SEARCH_FACEBOOK_LOCATIONS = 'FUZZY_SEARCH_FACEBOOK_LOCATIONS';
const FUZZY_SEARCH_FACEBOOK_LOCATIONS_SUCCESS = 'FUZZY_SEARCH_FACEBOOK_LOCATIONS_SUCCESS';
const FUZZY_SEARCH_FACEBOOK_LOCATIONS_FAILURE = 'FUZZY_SEARCH_FACEBOOK_LOCATIONS_FAILURE';

// ACTION FLOW TYPES
export type GetFacebookLocationAction = {
    type: 'GET_FACEBOOK_LOCATION_REQUEST';
    locationId: string;
};

type GetFacebookLocationSuccessAction = {
    type: 'GET_FACEBOOK_LOCATION_REQUEST_SUCCESS';
    location: V2FormattedLocationData;
};

type GetFacebookLocationFailureAction = {
    type: 'GET_FACEBOOK_LOCATION_REQUEST_FAILURE';
    error: Record<string, any>;
    locationId: string;
};

export type GetFacebookLocationStatusAction = {
    type: 'GET_FACEBOOK_LOCATION_STATUS_REQUEST';
    locationId: string;
};

type GetFacebookLocationStatusSuccessAction = {
    type: 'GET_FACEBOOK_LOCATION_STATUS_REQUEST_SUCCESS';
    locationId: string;
    locationStatus: V2LocationPartnerStatusData;
};

type GetFacebookLocationStatusFailureAction = {
    type: 'GET_FACEBOOK_LOCATION_STATUS_REQUEST_FAILURE';
    locationId: string;
    error: Record<string, any>;
};

export type FuzzySearchFacebookLocationsAction = {
    type: 'FUZZY_SEARCH_FACEBOOK_LOCATIONS';
    searchInput: string;
};

type FuzzySearchFacebookLocationsSuccessAction = {
    type: 'FUZZY_SEARCH_FACEBOOK_LOCATIONS_SUCCESS';
    searchResultsById: Record<string, V2FormattedLocationData>;
    searchResultsIds: Array<string>;
};

type FuzzySearchFacebookLocationsFailureAction = {
    type: 'FUZZY_SEARCH_FACEBOOK_LOCATIONS_FAILURE';
    error: Record<string, any>;
};

export type FacebookLocationsState = {
    isSearchingOnServer: boolean;
    searchInput: string;
    searchErrors: Record<string, any>;
    searchResultsById: {};
    searchResultsIds: Array<number | string>;
    byId: {};
    ids: Array<number | string>;
    statusById: Record<string, any>;
    getRequests: {
        statuses: Record<string, 'RUNNING' | 'DONE' | 'FAILED'>;
        errors: Record<string, Record<string, any> | null>;
    };
};

export type FacebookResourceAction =
    | FuzzySearchFacebookLocationsAction
    | FuzzySearchFacebookLocationsSuccessAction
    | FuzzySearchFacebookLocationsFailureAction
    | GetFacebookLocationAction
    | GetFacebookLocationSuccessAction
    | GetFacebookLocationFailureAction
    | GetFacebookLocationStatusAction
    | GetFacebookLocationStatusSuccessAction
    | GetFacebookLocationStatusFailureAction;

// ACTION CREATORS
export const getFacebookLocation = (locationId: string): GetFacebookLocationAction => ({
    type: GET_FACEBOOK_LOCATION_REQUEST,
    locationId,
});

export const getFacebookLocationSuccess = (
    location: V2FormattedLocationData,
): GetFacebookLocationSuccessAction => ({
    type: GET_FACEBOOK_LOCATION_REQUEST_SUCCESS,
    location,
});

export const getFacebookLocationFailure = (
    locationId: string,
    error: Record<string, any>,
): GetFacebookLocationFailureAction => ({
    type: GET_FACEBOOK_LOCATION_REQUEST_FAILURE,
    locationId,
    error,
});

export const fuzzySearchFacebookLocations = (
    searchInput: string,
): FuzzySearchFacebookLocationsAction => ({
    type: FUZZY_SEARCH_FACEBOOK_LOCATIONS,
    searchInput,
});

export const fuzzySearchFacebookLocationsFailure = (
    error: Record<string, any>,
): FuzzySearchFacebookLocationsFailureAction => ({
    type: FUZZY_SEARCH_FACEBOOK_LOCATIONS_FAILURE,
    error,
});

export const fuzzySearchFacebookLocationsSuccess = (
    searchResultsById: Record<string, V2FormattedLocationData>,
    searchResultsIds: Array<string>,
): FuzzySearchFacebookLocationsSuccessAction => ({
    type: FUZZY_SEARCH_FACEBOOK_LOCATIONS_SUCCESS,
    searchResultsById,
    searchResultsIds,
});

// INITIAL STATES
const initialState = {
    isSearchingOnServer: false,
    searchInput: '',
    searchErrors: {},
    searchResultsById: {},
    searchResultsIds: [],
    byId: {},
    ids: [],
    statusById: {},
    getRequests: {
        statuses: {},
        errors: {},
    },
};

// REDUCER
const facebookResourcesReducer = (
    state: FacebookLocationsState = initialState,
    action: FacebookResourceAction,
): FacebookLocationsState => {
    switch (action.type) {
        case GET_FACEBOOK_LOCATION_REQUEST:
            return {
                ...state,
                getRequests: {
                    ...state.getRequests,
                    statuses: {
                        ...state.getRequests.statuses,
                        [action.locationId]: RUNNING,
                    },
                    errors: {
                        ...omit(state.getRequests.errors, [action.locationId]),
                    },
                },
            };

        case GET_FACEBOOK_LOCATION_REQUEST_SUCCESS:
            return {
                ...state,
                byId: { ...state.byId, [action.location.id]: action.location },
                ids: uniq([...state.ids, action.location.id]),
                getRequests: {
                    ...state.getRequests,
                    statuses: {
                        ...state.getRequests.statuses,
                        [action.location.id]: DONE,
                    },
                },
            };

        case GET_FACEBOOK_LOCATION_REQUEST_FAILURE:
            return {
                ...state,
                getRequests: {
                    ...state.getRequests,
                    statuses: {
                        ...state.getRequests.statuses,
                        [action.locationId]: FAILED,
                    },
                    errors: {
                        ...state.getRequests.errors,
                        [action.locationId]: action.error,
                    },
                },
            };

        case GET_FACEBOOK_LOCATION_STATUS_REQUEST:
            return {
                ...state,
                statusById: {
                    ...state.statusById,
                    [action.locationId]: {
                        status: RUNNING,
                    },
                },
            };

        case GET_FACEBOOK_LOCATION_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                statusById: {
                    ...state.statusById,
                    [action.locationId]: action.locationStatus,
                },
            };

        case GET_FACEBOOK_LOCATION_STATUS_REQUEST_FAILURE:
            return {
                ...state,
                statusById: {
                    ...state.statusById,
                    [action.locationId]: {
                        status: FAILED,
                        error: action.error,
                    },
                },
            };

        case FUZZY_SEARCH_FACEBOOK_LOCATIONS:
            return {
                ...state,
                isSearchingOnServer: true,
                searchInput: action.searchInput,
                searchErrors: {},
                searchResultsIds: [],
                searchResultsById: {},
            };

        case FUZZY_SEARCH_FACEBOOK_LOCATIONS_SUCCESS:
            return {
                ...state,
                isSearchingOnServer: false,
                searchResultsIds: uniq(action.searchResultsIds),
                searchResultsById: action.searchResultsById,
            };

        case FUZZY_SEARCH_FACEBOOK_LOCATIONS_FAILURE:
            return {
                ...state,
                isSearchingOnServer: false,
                searchErrors: action.error,
            };

        default:
            return state;
    }
};

// SELECTORS
export const isSearchingOnServerSelector = (state: FacebookLocationsState): boolean =>
    state.isSearchingOnServer;

export const allFacebookLocationSearchResultsSelector = (
    state: FacebookLocationsState,
): Array<V2FormattedLocationData> => state.searchResultsIds.map(id => state.searchResultsById[id]);

export const facebookLocationSelector = (
    state: FacebookLocationsState,
    locationId: string,
): V2FormattedLocationData | void => state.byId[locationId];

export const facebookLocationSearchResultsSelector = (
    state: FacebookLocationsState,
    locationId: string,
): V2FormattedLocationData | void => state.searchResultsById[locationId];

export const facebookLocationGetRequestErrorSelector = (
    state: FacebookLocationsState,
    locationId: string,
): Record<string, any> | null => state.getRequests.errors[locationId];

export default facebookResourcesReducer;
