import usePermissions from 'app/businessEditV2/hooks/permissions/usePermissions';
import useOrgPermissions from 'app/pages/settingsV2/subPages/Team/components/Permissions/hooks/useOrgPermissions';

/**
 * To be used ONLY in Business Edit
 */
export default function useFieldMetadata(section: string, field: string) {
    const { data } = usePermissions();
    const permissions = data ?? [];

    const s = permissions.find(permissionSection => permissionSection.name === section);
    return s?.fields.find(permissionField => permissionField.name === field);
}

/**
 * Used to retrieve some orgs permissions
 */
export function useOrgFieldMetadata(section: string, field: string) {
    const { data } = useOrgPermissions();
    const permissions = data ?? [];

    const s = permissions.find(permissionSection => permissionSection.name === section);
    return s?.fields.find(permissionField => permissionField.name === field);
}
