import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import {
    MenuWrapper as BaseMenuWrapper,
    InputContainer,
    MenuItemWrapper,
} from 'app/common/designSystem/components/molecules/ButtonWithSearchMenuFilterSelection/ButtonWithSearchMenuFilterSelection.styled';

export const MenuWrapper = styled(BaseMenuWrapper)`
    top: 0;
`;

export const TagsInputContainer = styled(InputContainer)`
    max-height: 110px;
    overflow-y: auto;
    > input {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #7f8ea4;
    }
    gap: 4px;
`;

export const TagMenuItemWrapper = styled(MenuItemWrapper)`
    width: 100%;
`;

export const MenuItemLabel = styled.div`
    white-space: nowrap;
`;

export const CreateMenuItemLabel = styled(MenuItemLabel)`
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #7f8ea4;
`;

export const IconContainer = styled.div`
    color: #7f8ea4;
`;

export const CreateTagMenuItemWrapper = styled(MenuItemWrapper)`
    background-color: ${greyColorObject.light};
    margin-bottom: 2px;
    min-height: unset;
    padding: 4px 11px;
    &.disabled {
        pointer-events: none;
    }
`;
