import styled from '@emotion/styled';
import { Button, Text } from '@partoohub/ui';

import Card from 'app/common/designSystem/components/templates/Card';

export const StyledCard = styled(Card)`
    &:hover {
        background: none;
        box-shadow: none !important;
    }

    border: solid 1px ${({ theme }) => theme.colors.primary.initial};
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
`;

export const ReplyTemplateFormGrid = styled.div`
    display: grid;
    grid-gap: 8px 8px;
    grid-template-columns: auto 1fr 1fr;
    grid-template-areas:
        'flag             title           title'
        'text_area_title  text_area_title placeholder_button'
        'text_area        text_area       text_area'
        'review_types     review_types    confirm_button';

    width: 100%;
    margin: 16px 24px;
`;

export const FlagGridArea = styled.div`
    grid-area: flag;
    margin-top: 5px;
`;

export const TitleGridArea = styled.div`
    grid-area: title;
`;

export const TextAreaTitleGridArea = styled(Text)`
    line-height: 40px;
    grid-area: text_area_title;
`;

export const PlaceholderButtonGridArea = styled.div`
    position: relative;
    grid-area: placeholder_button;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const FormTextGridArea = styled.div`
    grid-area: text_area;
`;

export const ReviewTypesGridArea = styled.div`
    grid-area: review_types;
`;

export const StyledReviewTypesText = styled(Text)`
    margin-top: 16px;
`;

export const ButtonContainer = styled.div`
    margin-top: auto;

    > div {
        margin-left: auto;
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;

export const StyledSaveButton = styled(Button)`
    margin-left: 16px;
`;
