import { useQuery } from 'react-query';

import { Choice } from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import { AVAILABLE_LANGUAGES } from 'app/common/data/queryKeysConstants';

interface Options {
    enabled?: boolean;
    onSuccess?: (languages: Choice[]) => void;
    onError?: (err: Record<string, any>) => void;
}

export default function useAvailableLanguages(options?: Options) {
    return useQuery(
        AVAILABLE_LANGUAGES,
        api.lang.getAvailableLanguages,
        options,
    );
}
