// open hours
export const DIGIT_SEPARATOR = ':';

export const HOUR_SEPARATOR = '-';

export const DAYS = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

export const PHOTO_TYPES_PREFERRED_PHOTO = {
    primary: 'COVER',
    PREFERRED_PHOTO_UNSPECIFIED: 'PREFERRED_PHOTO_UNSPECIFIED',
};

export const URL_SEARCH_PARAMS = {
    DISPLAY_PUBLISHER_ERROR: 'display_publisher_errors',
};
