import {
    MESSAGING_STATS_PERIOD_MONTHS,
    useHomepageMessagingStats,
} from './useHomepageMessagingStats';

export const useIsLoadingMessagingStats = (): boolean => {
    const { isLoading } = useHomepageMessagingStats(MESSAGING_STATS_PERIOD_MONTHS, 0);
    const { isLoading: isLoadingPrevious } = useHomepageMessagingStats(
        MESSAGING_STATS_PERIOD_MONTHS * 2,
        MESSAGING_STATS_PERIOD_MONTHS,
    );

    return isLoading || isLoadingPrevious;
};
