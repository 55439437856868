import React from 'react';

import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import LoginForm from 'app/connection/components/LoginForm';

export const LoginPage = () => {
    const { t } = useTranslation();
    return (
        <>
            <DynamicPageHeader title={t('login')} />
            <LoginForm />
        </>
    );
};
