import React, { useEffect, useState } from 'react';

import { Button, Text, TextInput, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import useCustomFieldsSections from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useCustomFieldsSections';
import useEditCustomFieldSection from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useEditCustomFieldSection';
import {
    CustomFieldsCreateSectionModalButtons,
    CustomFieldsCreateSectiongModalContainer,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/sections/CustomFieldsModals/modals/CustomFieldsCreateSectionModal/CustomFieldsCreateSectionModal.styled';
import { CustomFieldsSectionQueryKeys } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/enums';

type Props = {
    closeModal: () => void;
};

const CustomFieldsSectionRenameContent = ({ closeModal }: Props) => {
    const { t } = useTranslation();

    const [editSectionId] = useStateQueryParams(CustomFieldsSectionQueryKeys.RENAME);
    const { data: customFieldsSections } = useCustomFieldsSections();

    const section = customFieldsSections?.sections.find(
        ({ id }) => id?.toString() === editSectionId,
    );

    const [name, setName] = useState<string>(section ? section.name : '');

    useEffect(() => {
        if (customFieldsSections) {
            const section = customFieldsSections.sections.find(
                ({ id }) => id?.toString() === editSectionId,
            );
            setName(section?.name ?? '');
        }
    }, [customFieldsSections]);

    const editSection = useEditCustomFieldSection(section?.id ?? 0, () => {
        toast.success(
            t('custom_fields_section_toast_edit_successful_description'),
            t('custom_fields_section_edit_successful', { name }),
        );
        closeModal();
    });

    // @ts-ignore
    const error = editSection.error?.response?.data?.errors?.json ?? {};
    const errorMessage = t(editSection.error?.response?.data?.errors?.json?.name);

    const getButtonStatus = () => {
        if (editSection.isLoading) {
            return 'secondary';
        }
        if (editSection.error) {
            return 'danger';
        }
        return 'primary';
    };

    return (
        <CustomFieldsCreateSectiongModalContainer>
            <Text variant="heading4">🚀 {t('custom_fields_section_rename_title')}</Text>

            <Text color="secondary">{t('custom_fields_section_create_description')}</Text>

            <TextInput
                dataTrackId="custom_field_rename_section_modal_input"
                label={t('name')}
                value={name}
                onChange={newName => setName(newName ?? '')}
                error={!!error?.name}
                notice={errorMessage}
            />

            <CustomFieldsCreateSectionModalButtons>
                <Button
                    appearance="outlined"
                    shape="rounded"
                    variant="secondary"
                    onClick={closeModal}
                    dataTrackId="custom_field_rename_section_modal__cancel"
                >
                    {t('cancel')}
                </Button>
                <Button
                    shape="rounded"
                    variant={getButtonStatus()}
                    onClick={() => editSection.mutate({ name: name })}
                    dataTrackId="custom_field_rename_section_modal__save"
                >
                    {t('rename_section')}
                </Button>
            </CustomFieldsCreateSectionModalButtons>
        </CustomFieldsCreateSectiongModalContainer>
    );
};

export default CustomFieldsSectionRenameContent;
