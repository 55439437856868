import { PublisherStateStats } from 'app/api/types/publisher_state';
import { doughnutColors } from 'app/presence/management/constants/doughnutColors';
import { publisherStateStatsLabels } from 'app/presence/management/constants/publisherStateStatsLabels';

const doughnutColorsArray = [
    doughnutColors.success,
    doughnutColors.pending,
    doughnutColors.warning,
];

type GetDoughnutDatasetsType = {
    publishersStateStatsTotal: PublisherStateStats;
    hoveredIndex: number | null;
};

export const getDoughnutDatasets = ({
    publishersStateStatsTotal,
    hoveredIndex,
}: GetDoughnutDatasetsType) => {
    const labelValues =
        publisherStateStatsLabels.map(
            label => publishersStateStatsTotal[label],
        ) || [];
    const labelPercentages = labelValues.map(
        a => a / labelValues.reduce((b, c) => b + c, 0),
    );

    const averageGrade =
        labelPercentages[0] >= 0
            ? `${Math.round(labelPercentages[0] * 100).toString()}%`
            : '';
    const percentage =
        hoveredIndex !== null
            ? `${(labelPercentages[hoveredIndex] * 100).toFixed()}%`
            : hoveredIndex;
    const color =
        hoveredIndex !== null
            ? Object.keys(doughnutColors)[hoveredIndex]
            : null;

    const datasets = [
        {
            data: labelValues,
            backgroundColor: doughnutColorsArray,
            hoverBorderColor: doughnutColorsArray,
            hoverBackgroundColor: doughnutColorsArray,
            hoverBorderWidth: [3, 3, 3],
            borderAlign: ['inner', 'inner', 'inner'],
        },
    ];

    return { averageGrade, percentage, color, datasets, labelValues };
};
