import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
    Button,
    Dialog,
    Filters,
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    Modal,
    Stack,
    TextInput,
} from '@partoohub/ui';
import { capitalize } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';

import { V2FormattedAccountData } from 'app/api/types/account';
import AccountAPI from 'app/api/v2/api_calls/accountApiCalls';
import AccountSection from 'app/businessConnection/components/AccountsList/AccountSection/AccountSection';
import { partnerToPartnerName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import { OAUTH_ACCOUNTS } from 'app/common/data/queryKeysConstants';
import {
    FACEBOOK,
    GOOGLE_MY_BUSINESS,
} from 'app/states/knowledge/reducers/businessConnection/accounts';

import { Body, FiltersWrapper, SideBar } from './AccountsList.styled';

const partnerOptions = [
    {
        options: [
            {
                label: capitalize(partnerToPartnerName[GOOGLE_MY_BUSINESS]),
                name: GOOGLE_MY_BUSINESS,
                value: GOOGLE_MY_BUSINESS,
            },
            {
                label: capitalize(partnerToPartnerName[FACEBOOK]),
                name: FACEBOOK,
                value: FACEBOOK,
            },
        ],
    },
];

interface AccountsListModalProps {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}
const AccountsListModal = ({ showModal, setShowModal }: AccountsListModalProps) => {
    const [searchText, setSearchText] = useState<string>('');
    const [partnerOption, setPartnerOption] = useState<Record<string, boolean>>({});
    const { t } = useTranslation();

    const { refetch, isFetching, hasNextPage, fetchNextPage, data } = useInfiniteQuery(
        [
            OAUTH_ACCOUNTS,
            {
                partner: Object.keys(partnerOption)[0],
                search: searchText.length > 3 ? searchText : '',
            },
        ],
        ({ pageParam = 1 }) => {
            if (pageParam === 1) {
                const element = document.getElementById('accountListScroll');
                if (element) element.scrollTop = 0;
            }

            return AccountAPI.searchAccount(
                Object.keys(partnerOption)[0],
                searchText,
                false,
                pageParam,
            );
        },
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined,
        },
    );

    useEffect(() => {
        refetch();
    }, [showModal]);

    const partnerCount = data?.pages?.[0].count;
    const partnerAccounts: V2FormattedAccountData[] =
        data?.pages?.map(({ accounts }) => accounts)?.flat() || [];

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Modal isOpen={showModal} closeModal={handleCloseModal}>
            <Dialog
                dataTrackId="account_list__dialog"
                title={t('linked_accounts')}
                subTitle={t('linked_accounts_detail')}
            >
                <Body>
                    <FiltersWrapper>
                        <TextInput
                            dataTrackId=""
                            label={t('search')}
                            leftElement={
                                <IconButton
                                    appearance="outlined"
                                    icon={[FontAwesomeIconsPartooUsed.faSearch, IconPrefix.REGULAR]}
                                    dataTrackId="account-search-icon"
                                />
                            }
                            onChange={setSearchText}
                            isReset={searchText.length > 0}
                            onReset={() => setSearchText('')}
                            value={searchText || ''}
                        />
                        <Filters
                            dataTrackId="accounts-list-filters"
                            placeholder={t('PUBLISHER')}
                            onChange={setPartnerOption}
                            selectedItems={partnerOption}
                            position="bottom"
                            menuListArray={partnerOptions}
                            maxHeight={300}
                        />
                    </FiltersWrapper>
                    <Stack style={{ maxHeight: '400px', height: '100%', overflowY: 'auto' }}>
                        <SideBar>
                            <p>
                                {t('accounts_with_count', {
                                    count: partnerCount,
                                })}
                            </p>
                            <p>{t('status')}</p>
                        </SideBar>
                        {showModal && (
                            <AccountSection
                                accounts={partnerAccounts}
                                isFetching={isFetching}
                                hasNextPage={hasNextPage}
                                fetchNextPage={fetchNextPage}
                                hasSearch={!!searchText || !!partnerOption}
                            />
                        )}
                    </Stack>
                </Body>
            </Dialog>
        </Modal>
    );
};

const AccountsList = () => {
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <Button
                dataTrackId="manage_accounts__button"
                variant="secondary"
                appearance="outlined"
                shape="cube"
                onClick={() => setShowModal(true)}
                icon={[FontAwesomeIconsPartooUsed.faUser, IconPrefix.SOLID]}
                iconPosition="left"
            >
                {t('manage_accounts')}
            </Button>
            {showModal && <AccountsListModal showModal={showModal} setShowModal={setShowModal} />}
        </>
    );
};

export default AccountsList;
