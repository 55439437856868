import { BUSINESS_MODAL_SET_SELECTED_BUSINESSES } from 'app/common/components/businessModal/context/businessModalFiltersReducer';

/**
 * Sets the selected businesses in the business modal manually
 */
export const setSelectedBusinessIdsModalActions = (
    business_ids: Array<string>,
) => ({
    type: BUSINESS_MODAL_SET_SELECTED_BUSINESSES,
    payload: business_ids,
});
