import React from 'react';

import { Button } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { STATIC_BASE_URL } from 'app/config';

import {
    DeleteModalButtons,
    DeleteModalContainer,
    DeleteModalDescription,
    DeleteModalImage,
    DeleteModalTitle,
} from '../../DeleteModals.styled';

type Props = {
    closeModal: () => void;
    deleteGroup: () => void;
};

const DeleteGroupWarningContent = ({ closeModal, deleteGroup }: Props) => {
    const { t } = useTranslation();

    return (
        <DeleteModalContainer>
            <DeleteModalImage>
                <img
                    src={`${STATIC_BASE_URL}/images/app/delete_modal_image.svg`}
                    alt="delete_section"
                />
            </DeleteModalImage>
            <DeleteModalTitle>{t('delete_group_modal_title')}</DeleteModalTitle>
            <DeleteModalDescription>{t('delete_modal_subtitle')}</DeleteModalDescription>
            <DeleteModalButtons>
                <Button
                    appearance="outlined"
                    variant="secondary"
                    dataTrackId="delete_group_cancel"
                    onClick={closeModal}
                    size="large"
                    shape="cube"
                >
                    {t('cancel')}
                </Button>
                <Button
                    dataTrackId="delete_group_delete"
                    variant="primary"
                    onClick={deleteGroup}
                    size="large"
                    shape="cube"
                >
                    {t('delete')}
                </Button>
            </DeleteModalButtons>
        </DeleteModalContainer>
    );
};

export default DeleteGroupWarningContent;
