import { BusinessAdviceDescriptionContainer } from './BusinessAdviceDescription.styled';
import BusinessAverageCompletion from './BusinessAverageCompletion';
import BusinessAverageDescription from './BusinessAverageDescription';

type Props = {
    average?: number | null;
};

const BusinessAdviceDescription = ({ average }: Props) => (
    <BusinessAdviceDescriptionContainer>
        <BusinessAverageCompletion average={average} />
        <BusinessAverageDescription />
    </BusinessAdviceDescriptionContainer>
);

export default BusinessAdviceDescription;
