import { Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import NegativeIcon from 'app/common/components/icons/NegativeIcon';
import NeutralIcon from 'app/common/components/icons/NeutralIcon';
import PositiveIcon from 'app/common/components/icons/PositiveIcon';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { STATIC_BASE_URL } from 'app/config';

import { BadgeFrame, IconHolder } from './ReplyTemplateBadge.styled';

type Props = {
    literalRating: string;
};

export default function ReplyTemplateBadge({ literalRating }: Props) {
    const { t } = useTranslation();
    const { data: me } = useMe();
    const provider = me?.provider;

    switch (literalRating) {
        case 'positive': {
            return (
                <BadgeFrame>
                    <IconHolder>
                        <PositiveIcon />
                    </IconHolder>
                    <Text variant="bodySSemibold" as="span" color={'success'}>
                        {t('positive')}
                    </Text>
                </BadgeFrame>
            );
        }
        case 'neutral': {
            return (
                <BadgeFrame>
                    <IconHolder>
                        <NeutralIcon />
                    </IconHolder>
                    <Text variant="bodySSemibold" as="span" color={'warning'}>
                        {t('neutral')}
                    </Text>
                </BadgeFrame>
            );
        }
        case 'negative': {
            return (
                <BadgeFrame>
                    <IconHolder>
                        <NegativeIcon />
                    </IconHolder>
                    <Text variant="bodySSemibold" as="span" color={'danger'}>
                        {t('negative')}
                    </Text>
                </BadgeFrame>
            );
        }
        case 'original': {
            return (
                <BadgeFrame>
                    {provider === 'partoo' && (
                        <img
                            src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/partoo.svg`}
                            alt="partoo"
                        />
                    )}
                    <Text variant="bodySSemibold" as="span" color={'secondary'}>
                        {t('auto_reply_suggested_template')}
                    </Text>
                </BadgeFrame>
            );
        }
        default: {
            return <div></div>;
        }
    }
}
