import { ReactNode, useRef } from 'react';

import { SerializedStyles } from '@emotion/react';
import { Image as ImagePartoo } from '@partoohub/ui';

import { ImageStatus, useImageWithFallback } from 'app/hooks/useImageWithFallback';

import {
    DefaultImageLoader,
    ImageFallbackContainer,
    InitialDiv,
    StyledIcon,
} from './ImageWithFallback.styled';

interface ImageWithFallbackProps {
    src: string;
    fallback: ReactNode;
    alt?: string;
    shouldResize?: boolean;
    width?: number;
    height?: number;
    title?: string;
    containerCss?: SerializedStyles;
    loader?: ReactNode;
    onError?: () => void;
}

/**
 * The ImageWithFallback component renders an image with loading, error handling, and fallback behavior.
 *
 * @component
 * @param {ImageWithFallbackProps} props - The component's props.
 * @returns {React.ReactElement} The rendered ImageWithFallback component.
 */
export const ImageWithFallback = ({
    src,
    fallback,
    alt,
    title,
    shouldResize = true,
    width,
    height,
    containerCss,
    loader = (
        <DefaultImageLoader>
            <StyledIcon className="fa-solid fa-circle-notch fa-spin" />
        </DefaultImageLoader>
    ),
    ...props
}: ImageWithFallbackProps) => {
    const target = useRef<HTMLDivElement>(null);
    const { state, transformedSrc } = useImageWithFallback(
        target,
        src,
        width,
        height,
        shouldResize,
    );
    return (
        <ImageFallbackContainer ref={target} css={containerCss}>
            {state === ImageStatus.Initial && <InitialDiv />}
            {state === ImageStatus.Loading && loader}
            {state === ImageStatus.Error && fallback}
            {state === ImageStatus.Success && (
                <ImagePartoo src={transformedSrc} alt={alt} title={title} {...props} />
            )}
        </ImageFallbackContainer>
    );
};
