import { mapValues } from 'lodash-es';

import usePublisherInfos from 'app/businessEditV2/hooks/publisherErrors/usePublisherInfos';
import camelCasify from 'app/common/services/CamelCasify';

const publisherErrorMergerSelector = (prechecks, section, field) => {
    const publisherErrors = prechecks?.[section]?.[field] ?? {};
    return mapValues(publisherErrors, x => [x]);
};

const formatPublishers = publisherInfos => {
    const filteredByName = publisherInfos.filter(
        publisher =>
            !publisher.hidden_by_default || publisher.status === 'unavailable',
    );
    // status condition aims at displaying all publishers in admin view
    return filteredByName.reduce(
        (seq, publisher) => ({
            ...seq,
            [publisher.name]: camelCasify(publisher),
        }),
        {},
    );
};

export default function useFormatPublisherErrors(
    prechecks: object,
    section: string,
    field: string,
) {
    const { data: publishers } = usePublisherInfos();

    const formattedPublishers = formatPublishers(publishers ?? []);

    const publisherErrors = publisherErrorMergerSelector(
        prechecks ?? {},
        section,
        field,
    );

    const publishersInfo = {};

    Object.keys(publisherErrors).forEach(publisherName => {
        const publisherData = formattedPublishers[publisherName];
        publishersInfo[publisherName] = {
            formattedName: publisherData?.formattedName ?? publisherName,
            order: publisherData?.order ?? Number.MAX_SAFE_INTEGER,
        };
    });

    return {
        publisherErrors,
        publishersInfo,
    };
}
