import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import { DISABLED_COLOR } from 'app/common/designSystem/constants/color';

type CheckableBarProps = {
    isLoading?: boolean;
    disabled?: boolean;
};

export const LeftIconWrapper = styled.div`
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;

    svg {
        max-width: 100%;
        max-height: 100%;
    }

    svg,
    .fa,
    .fas {
        color: ${HEX_COLORS.white};
        font-size: 10px;
    }
`;

export const CheckboxWrapper = styled.div`
    margin-left: auto;

    svg {
        max-width: 100%;
        max-height: 100%;
    }

    svg,
    .fa,
    .fas {
        color: ${({ theme }) => theme.colors.secondary.initial};
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 6px;

    p {
        margin: 0;
        padding: 0;
        color: ${HEX_COLORS.blackberry};
        font-size: 16px;
        font-weight: bold;

        ${(props: CheckableBarProps) =>
            props.disabled &&
            css`
                color: ${DISABLED_COLOR} !important;
            `}
    }
`;

export const LinkWrapper = styled.div`
    a {
        margin: 0;
        text-align: 'left';
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: ${({ theme }) => theme.colors.primary.initial};
        text-decoration: none !important;

        ${(props: CheckableBarProps) =>
            props.disabled &&
            css`
                color: ${DISABLED_COLOR} !important;
            `};

        &:hover {
            text-decoration: underline !important;
        }
    }
`;

export const ArrowLink = styled.i`
    margin-left: 5px;
`;

export const CheckableBarWrapper = styled.div<CheckableBarProps>`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 14px 10px;
    border-radius: 16px;
    transition: all 0.2s ease-in;
    background: ${greyColorObject.light};

    ${({ isLoading }) =>
        isLoading &&
        css`
            opacity: 0.6;
        `}

    &:hover {
        cursor: ${({ isLoading, disabled }) => (isLoading || disabled ? 'not-allowed' : 'pointer')};
        background-color: ${({ disabled, theme }) => (disabled ? '' : theme.colors.primary.alpha)};
    }
`;
