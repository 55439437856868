import { TextArea } from '@partoohub/ui';
import { useController, useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { ContentContainer } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/forms/News.styled';
import usePlatformSelection from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/hooks/usePlatformSelection';

const MAX_CHARACTERS_FACEBOOK = 10000;
const MAX_CHARACTERS_DEFAULT = 1500;

export const ControlledPostDescription = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    const { hasOnlyFacebook } = usePlatformSelection();
    const charLimit = hasOnlyFacebook ? MAX_CHARACTERS_FACEBOOK : MAX_CHARACTERS_DEFAULT;
    const { field, formState } = useController({
        control,
        name: 'post_description',
        rules: { required: true, maxLength: charLimit },
        defaultValue: '',
    });

    const hasErrorDescription = formState.errors.post_description?.type === 'required';
    const hasErrorDescriptionLength = formState.errors.post_description?.type === 'maxLength';

    const handleChange = (value: string) => {
        field.onChange(value?.slice(0, charLimit));
    };

    return (
        <ContentContainer>
            <TextArea
                {...field}
                dataTrackId="post_description"
                required
                label={t('post_description')}
                error={hasErrorDescription || hasErrorDescriptionLength}
                characterCount={charLimit}
                onChange={handleChange}
                notice={hasErrorDescription && t('field_is_necessary')}
            />
        </ContentContainer>
    );
};
