import camelCasify from './CamelCasify';

export type FormattedObjects<T extends number | string> = {
    byId: Record<string, any>;
    ids: Array<T>;
};

type UnformattedObject<T extends number | string> = {
    id: T;
    [key: string]: any;
};

/** @deprecated
 *  Normalization is an optimization that was popularized by
 *  Redux but is usually not required with react-query.
 */
const byIdFormatter = <T extends number | string>(
    unformattedObjects: Array<UnformattedObject<T>>,
    additionalFormatter?: (obj: Record<string, any>) => any,
): FormattedObjects<T> =>
    unformattedObjects.reduce(
        (obj, unformattedObject) => ({
            byId: {
                ...obj.byId,
                [unformattedObject.id]: additionalFormatter
                    ? additionalFormatter(camelCasify(unformattedObject))
                    : camelCasify(unformattedObject),
            },
            ids: [...obj.ids, unformattedObject.id],
        }),
        {
            byId: {},
            ids: [],
        } as FormattedObjects<T>,
    );

export default byIdFormatter;
