import { PropsWithChildren } from 'react';

import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack, Text } from '@partoohub/ui';

import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks/useDeviceType';
import { HeaderPrincipalPage } from 'app/pages/settingsV2/subComponents/HeaderPrincipalPage/HeaderPrincipalPage';

export const HeaderSubPages = ({ children }: PropsWithChildren) => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    return (
        <Stack direction="row">
            <HeaderPrincipalPage />
            {!isBelowProvidedDevices && (
                <Stack direction="row" alignItems="center" style={{ pointerEvents: 'none' }}>
                    <Icon icon={[FontAwesomeIconsPartooUsed.faChevronRight, IconPrefix.SOLID]} />
                    <Text variant="heading3" as="span">
                        {children}
                    </Text>
                </Stack>
            )}
        </Stack>
    );
};
