import BusinessModalButton from 'app/common/components/businessModal/BusinessModalButton';
import { FilterBusinessModalButton } from 'app/common/components/businessModal/components/FilterBusinessModalButton/FilterBusinessModalButton';
import { DateFilterSelector } from 'app/common/components/DateFilter/DateFilterSelector';
import { FiltersContainerStyled, FiltersStyled } from 'app/common/components/FilterSection.styled';
import useHasReviewTagFeature from 'app/reviewManagement/common/hooks/useHasReviewTagFeature';
import { TagFilter } from 'app/reviewManagement/common/pageHeader/desktop/filters';

export const FilterSection = () => {
    const hasReviewTagFeature = useHasReviewTagFeature();
    return (
        <FiltersContainerStyled
            data-track="analytics_reviews__filters"
            data-intercom-target="analytics_reviews__filters"
        >
            <FiltersStyled>
                <BusinessModalButton
                    key="business-modal-key"
                    dataTrack="analytics_reviews__business_filter"
                    button={
                        <div>
                            <FilterBusinessModalButton />
                        </div>
                    }
                />
                <DateFilterSelector />
                {hasReviewTagFeature && <TagFilter />}
            </FiltersStyled>
        </FiltersContainerStyled>
    );
};
