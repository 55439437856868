import { AvatarMenuItem } from '@partoohub/modular-components';
import { Icon, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LOG_OUT_PATH } from 'app/routing/routeIds';

import { IconContainer } from './AvatarLogoutItem.styled';

export const AvatarLogoutItem = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(LOG_OUT_PATH);
    };
    return (
        <AvatarMenuItem callback={handleClick}>
            <IconContainer>
                <Icon icon={['fa-power-off']} color="danger" />
            </IconContainer>
            <Text variant="bodyMMedium" as="span" color="danger">
                {t('logout')}
            </Text>
        </AvatarMenuItem>
    );
};
