import React from 'react';

import { useTranslation } from 'react-i18next';

import {
    LOGO_PHOTO,
    PRIMARY_PHOTO,
} from 'app/businessEditV2/oldFromEditV1/data/photoConsts';
import { getLabel } from 'app/businessEditV2/utils/photos';

import {
    DeleteIcon,
    EditIcon,
    PhotoContainer,
    PhotoOverlay,
    SelectedPhotoOverlay,
} from './Photo.styled';

type Props = {
    category: string;
    url: string;
    secondaryPermission: boolean;
    logoPermission: boolean;
    coverPermission: boolean;
    onClick: () => void;
    deletePhoto: () => void;
};

const Photo = ({
    category,
    url,
    secondaryPermission,
    logoPermission,
    coverPermission,
    onClick,
    deletePhoto,
}: Props) => {
    const { t } = useTranslation();

    return (
        <PhotoContainer url={url} onClick={onClick}>
            {[LOGO_PHOTO, PRIMARY_PHOTO].includes(category) ? (
                <SelectedPhotoOverlay>
                    {((category === LOGO_PHOTO && logoPermission) ||
                        (category === PRIMARY_PHOTO && coverPermission)) && (
                        <EditIcon>
                            <i className="fa-solid fa-pen-to-square" />
                        </EditIcon>
                    )}
                    {t(getLabel(category))}
                </SelectedPhotoOverlay>
            ) : (
                <PhotoOverlay>
                    <EditIcon>
                        {secondaryPermission ? (
                            <i className="fa-solid fa-pen-to-square" />
                        ) : (
                            <i className="fa-solid fa-eye" />
                        )}
                    </EditIcon>
                    {secondaryPermission && (
                        <DeleteIcon
                            onClick={e => {
                                deletePhoto();
                                e.stopPropagation();
                            }}
                        >
                            <i className="fa-solid fa-trash" />
                        </DeleteIcon>
                    )}
                </PhotoOverlay>
            )}
        </PhotoContainer>
    );
};

export default Photo;
