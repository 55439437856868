import React from 'react';

export const UrlMatcher = (
    pattern,
    value,
    href,
    newTab = false,
    disabled = false,
    inverseName = '',
    propName = '',
) => ({
    inverseName: inverseName,
    propName: propName,
    asTag: () => 'a',
    createElement: (match, props) => (
        <a
            href={href}
            target={newTab ? '_blank' : ''}
            rel="noreferrer noopener"
            onClick={event => {
                if (disabled) {
                    event.preventDefault();
                }
                event.stopPropagation();
            }}
            {...props}
        >
            {value}
        </a>
    ),
    match: str => {
        const matches = str.match(pattern);
        if (!matches) return null;
        return {
            index: matches.index!,
            length: matches[0].length,
            match: matches[0],
            valid: true,
        };
    },
});

export const ChipMatcher = (pattern, value) => ({
    asTag: () => 'span',
    createElement: (match, props) => (
        <span {...props} className="mentions__mention_preview">
            &nbsp;&nbsp;@
            {value}
            &nbsp;&nbsp;
        </span>
    ),
    match: str => {
        const matches = str.match(pattern);
        if (!matches) return null;
        return {
            index: matches.index!,
            length: matches[0].length,
            match: matches[0],
            valid: true,
        };
    },
});
