import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

export const ConversationStarterItemFormContainer = styled.div`
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.primary.initial};
    background-color: ${HEX_COLORS.white};
    width: 100%;
`;

export const ConversationStarterItemFormFooter = styled.div`
    display: flex;
    gap: 16px;
    justify-content: flex-end;
`;
