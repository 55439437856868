import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

const lineHeight = 20;
const marginSide = 16;
const maxLineNum = 14;

export const PostCardMainContainer = styled.div`
    grid-area: description;
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid ${greyColorObject.initial};
    border-left: 1px solid ${greyColorObject.initial};
    border-bottom: 1px solid ${greyColorObject.initial};
    background: ${HEX_COLORS.white};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
`;

export const PostCardMainBusinessIcon = styled.i`
    margin: 0 3px 0 0;
`;

export const PostCardMainBusinessName = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: ${HEX_COLORS.blackberry};
    padding: 0 16px;
    width: 100%;
    display: flex;
`;

export const PostCardMainBusinessInfo = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const PostCardMainDescription = styled.p`
    border: none;
    background: none;
    font-size: 14px;
    line-height: ${`${lineHeight}px`};
    padding: 0;
    overflow-y: auto;
    width: calc(100% - ${`${marginSide * 2}px`});
    white-space: pre-wrap;
    word-break: break-all;
    max-height: ${`${lineHeight * maxLineNum}px`};
    margin: 0 ${`${marginSide}px`};
    color: ${({ theme }) => theme.colors.secondary.initial};
    -webkit-transition: max-height 0.1s ease-in;
    transition: max-height 0.1s ease-in;
`;

export const ReadMoreButton = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.secondary.initial};
    margin: 0 12px;
    font-weight: bold;
    cursor: pointer;
    span {
        display: inline-block;
        margin-right: 8px;
    }
`;
