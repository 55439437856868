import React from 'react';

import { EDIT_SECTION_HEIGHT } from 'app/businessEditV2/components/EditSection/EditSection.styled';
import Loader from 'app/common/designSystem/components/atoms/svg/Loader';

import { EditSectionLoaderContainer } from './EditSectionLoader.styled';

type Props = {
    height?: number;
};

const EditSectionLoader = ({ height = EDIT_SECTION_HEIGHT }: Props) => (
    <EditSectionLoaderContainer height={height}>
        <Loader />
    </EditSectionLoaderContainer>
);

export default EditSectionLoader;
