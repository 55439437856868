import styled from '@emotion/styled';

import { GRID_GAP } from 'app/businessEditV2/components/BusinessEditV2Content/BusinessEditV2Content.styled';

export const BusinessEditV2HeaderContainer = styled.div`
    position: relative;
    flex-direction: column;
    margin-bottom: 24px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${GRID_GAP}px ${GRID_GAP}px;
    justify-content: space-between;

    > * + * {
        justify-content: flex-end;
    }
`;

export const Title = styled.div`
    display: flex;
    margin-bottom: 4px;

    > button {
        margin-right: 16px;
    }
`;
