import { useEffect, useState } from 'react';

import { DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import { cloneDeep } from 'lodash';

import { CustomFieldsChangeOrderPayload } from 'app/api/types/business';
import useCustomFields from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useCustomFields';
import useCustomFieldsSections from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useCustomFieldsSections';
import useUpdateCustomFieldsOrder from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/hooks/useUpdateCustomFieldsOrder';
import CustomFieldsListItem from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/sections/CustomFieldsContent/CustomFieldsList/CustomFieldsListItem';
import { CustomFieldsSectionsListItem } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/sections/CustomFieldsContent/CustomFieldsList/CustomFieldsSectionsListItem/CustomFieldsSectionsListItem';
import {
    checkExternalToExternal,
    checkExternalToInternal,
    checkInternalToExternal,
    checkInternalToOtherInternal,
    checkInternalToSameInternal,
    debugLog,
    getSelect,
    handleExternalToEmptySection,
    handleExternalToExternal,
    handleExternalToInternal,
    handleInternalToExternal,
    handleInternalToOtherInternal,
    handleInternalToSameInternal,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/dndHandleLogic';
import { getCleanData } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/services';
import {
    CUSTOM_FIELD_SECTION_TYPE,
    CUSTOM_FIELD_TYPE,
    CustomFieldListType,
} from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields/utils/typing';
import useOrgPermissions from 'app/pages/settingsV2/subPages/Team/components/Permissions/hooks/useOrgPermissions';

export const CF_LIST_TYPE = 'CF_LIST_TYPE';

export default function useCustomFieldDnDLogic() {
    // Data to display while the order api call is being made
    const [cfs, setCfs] = useState<CustomFieldListType>([]);

    // Fix to retrieve the correct index when moving a field from one section to another
    const [fixHandle, setFixHandle] = useState<boolean>(false);

    // Used to not trigger the order api call if the order hasn't changed
    const [orderHasChanged, setOrderHasChanged] = useState<boolean>(false);

    // Id of the field/section being dragged, used for the overlay
    const [draggingId, setDraggingId] = useState<string | null>(null);

    // We need to keep track of opened sections in the parent because the children will be rerendered with new keys :(
    const [sectionsOpened, setSectionsOpened] = useState<Record<number, boolean | undefined>>([]);
    const setSectionOpened = (sectionId: number, isOpened: boolean) => {
        setSectionsOpened({
            ...sectionsOpened,
            [sectionId]: isOpened,
        });
    };

    const { isFetching: isFetchingCustomFields } = useOrgPermissions();
    const customFields = useCustomFields();
    const { data: customFieldsSections, isFetching: isFetchingSections } =
        useCustomFieldsSections();

    const updateCustomFieldsOrder = useUpdateCustomFieldsOrder();

    // Need to get order right by merging Fields and Sections
    const cleanCFdata = getCleanData(customFields, customFieldsSections);
    const customFieldsToDisplay: CustomFieldListType = cfs.length ? cfs : cleanCFdata;

    useEffect(() => {
        // Reset once all the data is refetched
        if (!isFetchingCustomFields && !isFetchingSections) {
            setCfs([]);
        }
    }, [customFields, customFieldsSections, isFetchingCustomFields, isFetchingSections]);

    const handleDragStart = ({ active }: DragStartEvent) => {
        setDraggingId(active.id as string);
        setOrderHasChanged(false);
    };

    const handleDragOver = ({ active, over }: DragOverEvent) => {
        debugLog('---');
        debugLog('handleDragOver');

        // Same
        if (active.id === over?.id) {
            return;
        }

        const cpCfs = cloneDeep(customFieldsToDisplay);

        const activeCf = getSelect(cpCfs, active.id as string);
        const overCf = getSelect(cpCfs, over?.id as string);

        if (!activeCf || !overCf) {
            return;
        }

        // External to external
        if (checkExternalToExternal(activeCf, active, over)) {
            // External to internal empty section
            if (
                overCf.type === CUSTOM_FIELD_SECTION_TYPE &&
                activeCf.type === CUSTOM_FIELD_TYPE &&
                overCf.customFields.length === 0
            ) {
                handleExternalToEmptySection(
                    cpCfs,
                    activeCf,
                    overCf,
                    active,
                    setCfs,
                    setOrderHasChanged,
                    setSectionOpened,
                );
                return;
            }

            handleExternalToExternal(cpCfs, active, over, setCfs, setOrderHasChanged);
            return;
        }

        // Internal to same internal
        if (checkInternalToSameInternal(activeCf, overCf, active, over)) {
            handleInternalToSameInternal(cpCfs, active, over, setCfs, setOrderHasChanged);
            return;
        }

        // Internal to other internal
        if (
            checkInternalToOtherInternal(
                activeCf,
                overCf,
                active,
                over,
                !!sectionsOpened[over?.data.current?.sortable.containerId.split('_')[1]],
            )
        ) {
            handleInternalToOtherInternal(cpCfs, active, over, setCfs, setOrderHasChanged);
            setFixHandle(true);
            return;
        }

        // Internal to external
        if (checkInternalToExternal(activeCf, active, over)) {
            handleInternalToExternal(cpCfs, active, setCfs, setOrderHasChanged);
            setFixHandle(true);
            return;
        }

        // External to internal
        if (
            checkExternalToInternal(
                activeCf,
                overCf,
                active,
                over,
                !!sectionsOpened[over?.data.current?.sortable.containerId.split('_')[1]],
            )
        ) {
            handleExternalToInternal(cpCfs, active, over, setCfs, setOrderHasChanged);
            setFixHandle(true);
            return;
        }
    };

    const handleDragMove = ({ active, over }: DragOverEvent) => {
        if (!fixHandle) {
            return;
        }

        debugLog('---');
        debugLog('handleDragMove');

        setFixHandle(false);

        // Same
        if (active.id === over?.id) {
            return;
        }

        const cpCfs = cloneDeep(cfs);

        const activeCf = getSelect(cpCfs, active.id as string);
        const overCf = getSelect(cpCfs, over?.id as string);

        if (!activeCf || !overCf) {
            return;
        }

        // External to external
        if (checkExternalToExternal(activeCf, active, over)) {
            handleExternalToExternal(cpCfs, active, over, setCfs, setOrderHasChanged);
            return;
        }

        // Internal to same internal
        if (checkInternalToSameInternal(activeCf, overCf, active, over)) {
            handleInternalToSameInternal(cpCfs, active, over, setCfs, setOrderHasChanged);
            return;
        }
    };

    const handleDragEnd = () => {
        if (!orderHasChanged) {
            return;
        }

        let cpCfs = cloneDeep(customFieldsToDisplay);

        // Reordering
        cpCfs = cpCfs.map((cf, index) => {
            cf.obj.order = index + 1;
            if (cf.type === CUSTOM_FIELD_SECTION_TYPE) {
                cf.customFields = cf.customFields.map((field, indexCf) => {
                    field.order = indexCf + 1;
                    return field;
                });
            }
            return cf;
        });

        const orderPayload: CustomFieldsChangeOrderPayload = {
            orders: [],
            section_orders: [],
        };

        for (const cf of cpCfs) {
            if (cf.type === CUSTOM_FIELD_TYPE) {
                orderPayload.orders.push({
                    id: cf.obj.id ?? 0,
                    order: cf.obj.order ?? 0,
                    section_id: null,
                });
            } else {
                orderPayload.section_orders.push({
                    id: cf.obj.id ?? 0,
                    order: cf.obj.order ?? 0,
                });
                for (const field of cf.customFields) {
                    orderPayload.orders.push({
                        id: field.id ?? 0,
                        order: field.order ?? 0,
                        section_id: cf.obj.id,
                    });
                }
            }
        }

        updateCustomFieldsOrder.mutate(orderPayload);

        setCfs(cpCfs);
        setOrderHasChanged(false);
    };

    const task = getSelect(customFieldsToDisplay, draggingId);
    let dragOverlay = null;
    if (task?.type === CUSTOM_FIELD_TYPE) {
        dragOverlay = <CustomFieldsListItem customField={task.obj} />;
    } else if (task?.type === CUSTOM_FIELD_SECTION_TYPE) {
        dragOverlay = (
            <CustomFieldsSectionsListItem
                section={task.obj}
                customFields={task.customFields}
                isOpened={sectionsOpened[task.obj.id]}
                setIsOpened={opened => setSectionOpened(task.obj.id, opened)}
            />
        );
    }

    return {
        customFieldsToDisplay,
        sectionsOpened,
        setSectionOpened,

        handleDragStart,
        handleDragOver,
        handleDragMove,
        handleDragEnd,

        dragOverlay,
    };
}
