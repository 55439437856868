import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';

import Flexbox from 'app/styles/utils/flexbox';

type InputsContainerProps = {
    isSuggestionMode: boolean;
};

export const InputsContainer = styled(Flexbox)<InputsContainerProps>`
    width: 100%;
    border-radius: 8px;
    border: 1px solid
        ${({ isSuggestionMode, theme }) =>
            isSuggestionMode ? theme.colors.slate.initial : greyColorObject.initial};
    background-color: ${HEX_COLORS.white};
    padding: 16px 16px 8px 16px;
    cursor: text;
`;

export const ActionButtonsContainer = styled(Flexbox)`
    margin-top: 16px;
    gap: 4px;
`;

export const TextArea = styled.textarea`
    border: none;
    font-size: 14px;
    line-height: 16px;
    cursor: text;
    letter-spacing: inherit;
    transition: height 0.2s ease-in;

    &::placeholder {
        color: ${({ theme }) => theme.colors.secondary.initial} !important;
        letter-spacing: inherit;
    }

    :focus {
        outline: none;
    }
`;

export const ReplySuggestionLoader = styled.div`
    width: fit-content;
    height: 18px;
    display: flex;
    align-items: center;
`;

export const Label = styled.label`
    margin: 0;
    top: -6px;
    position: relative;
    padding-left: 2px;
    color: ${({ theme }) => theme.colors.slate.initial};
    font-size: 10px;
`;
