import { useEffect } from 'react';

import { UseInfiniteQueryResult, useInfiniteQuery } from 'react-query';

import {
    BusinessUser,
    BusinessUsersParams,
    V2UserData,
} from 'app/api/types/user';
import api from 'app/api/v2/api_calls';
import { MESSAGING_BUSINESS_USERS } from 'app/common/data/queryKeysConstants';

const useGetBusinessUsers = (
    businessId: string,
    queryParams: BusinessUsersParams,
): [Array<BusinessUser>, UseInfiniteQueryResult<V2UserData>] => {
    const query = useInfiniteQuery(
        [MESSAGING_BUSINESS_USERS, businessId],
        ({ pageParam = 1 }) =>
            api.user.getBusinessUsers(businessId, pageParam, queryParams),
        {
            retry: false,
            retryOnMount: false,
            getNextPageParam: param =>
                param.max_page > param.page ? param.page + 1 : undefined,
        },
    );
    const users = query.data?.pages
        .flatMap(({ users }) => users)
        .map(user =>
            Object.create({
                id: user.id,
                firstName: user.first_name,
                lastName: user.last_name,
                role: user.role,
            }),
        );
    return [users || [], query];
};

export const useGetAllBusinessUsers = (
    businessId: string,
    queryParams: BusinessUsersParams,
): [Array<BusinessUser>, boolean] => {
    const [users, query] = useGetBusinessUsers(businessId, queryParams);

    useEffect(() => {
        if (query.hasNextPage && !query.isFetchingNextPage) {
            query.fetchNextPage();
        }
    }, [query]);
    const isLoading = query.hasNextPage === undefined || query.hasNextPage;

    return [users, isLoading];
};
