import styled from '@emotion/styled';

import { ControlledMonthYearPicker } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Form/ControlledMonthYearPicker';

export const ModalBody = styled.div`
    text-align: center;
    padding: 24px;
    white-space: pre-wrap;
    width: 500px;
    .mdc-text-field-helper-line {
        margin-bottom: 0;
    }
    > svg {
        width: 340px;
        height: 140px;
    }
`;

export const ModalTitle = styled.div`
    padding: 16px 0 8px;
    font-size: 22px;
    font-weight: 900;
    margin-bottom: 24px;
`;

export const ModalText = styled.div`
    font-size: 14px;
    line-height: 1.57;
    color: #7e8ea5;
`;

export const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.colors.danger.initial};
`;

export const ModalButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 8px;
`;

export const DefaultControllerContainer = styled.div`
    display: flex;
    gap: 16px;
`;

export const PeriodicityContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    .mdc-text-field-helper-line:last-of-type {
        display: none;
    }
`;

const DESIGN_SYSTEM_COMPONENTS_HEIGHT = 56;

export const StyledControlledMonthYearPicker = styled(ControlledMonthYearPicker)`
    height: ${DESIGN_SYSTEM_COMPONENTS_HEIGHT}px;
`;
