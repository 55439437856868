import styled from '@emotion/styled';

export const LoadingLocationCardContainer = styled.div`
    border-radius: 3px;
    padding: 16px;
    margin: 16px 0 8px;
    box-shadow:
        0 2px 4px 0 rgba(0, 0, 0, 0.2),
        0 3px 10px 0 rgba(0, 0, 0, 0.19);
`;
