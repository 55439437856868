import { ReactNode, useCallback } from 'react';

import {
    ChevronItemIcon,
    ClickableWrapper,
    NavItemChildren,
    NavItemContainer,
    NavItemNavLink,
    NavItemTitleContainer,
} from './NavItem.styled';

const NavItemTitle = ({
    to,
    children,
    chevron,
    onClick,
    onChevronClick,
    chevronDown,
    selected,
    dataTrack,
}: any) => {
    return (
        <NavItemTitleContainer>
            <NavItemNavLink
                to={to}
                onClick={onClick}
                selected={selected}
                data-cy="collapsible-sidebar-subsection-title"
                end
                data-track={dataTrack}
                data-intercom-target={dataTrack}
            >
                {children}
            </NavItemNavLink>

            {chevron && (
                <ClickableWrapper
                    onClick={onChevronClick}
                    chevronDown={chevronDown}
                    data-cy="collapsible-sidebar-subsection-chevron"
                >
                    <ChevronItemIcon />
                </ClickableWrapper>
            )}
        </NavItemTitleContainer>
    );
};

type Props = {
    title: string;
    to: string;
    children?: ReactNode;
    opened?: boolean;
    onClick?: (id: any) => void;
    onChevronClick?: (id: any) => void;
    selected: boolean;
    id?: any;
    dataTrack?: string;
    isChild?: boolean;
};

const NavItem = ({
    title,
    to,
    children,
    opened = false,
    onClick = () => undefined,
    onChevronClick = () => undefined,
    selected,
    id,
    dataTrack,
    isChild,
}: Props) => {
    /**
     * Chinese copy of the NavItem component, so it's easier to find override the css and won't interfere with the original component
     * Quick and dirty solution, but it's only until the old groups system die
     */

    const handleClick = useCallback(() => {
        onClick?.(id);
    }, [id, onClick]);
    const handleChevronClick = useCallback(() => {
        onChevronClick?.(id);
    }, [id, onChevronClick]);
    return (
        <NavItemContainer isChild={isChild}>
            <NavItemTitle
                to={to}
                chevron={!!children}
                onClick={handleClick}
                onChevronClick={handleChevronClick}
                chevronDown={opened}
                selected={selected}
                dataTrack={dataTrack}
            >
                {title}
            </NavItemTitle>
            {opened && children && <NavItemChildren>{children}</NavItemChildren>}
        </NavItemContainer>
    );
};

export default NavItem;
