import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const PlaceholderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 544px;
    margin: 54px auto 0 auto;
`;

export const PlaceholderPicto = styled.img`
    width: 206px;
    height: 223px;
    margin-bottom: 24px;
`;

export const PlaceholderTitle = styled(Text)`
    text-align: center;
    margin-bottom: 8px;
`;

export const PlaceholderText = styled(Text)`
    text-align: center;
    margin-bottom: 16px;
`;
