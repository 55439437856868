import { useTranslation } from 'react-i18next';

import { useSettingMainMenu } from 'app/pages/settingsV2/hooks/Main/useSettingMainMenu';

import { PageSettingsHeaderWithAvatar } from 'app/routing/components/PageSettingsHeaderWithAvatar';

import { HeaderSubPages } from '../../subComponents';
import { SettingSkeleton } from '../../subComponents/SettingSkeleton';
import { Template } from '../../subComponents/Template/Template';

export const SettingMain = () => {
    const { t } = useTranslation();
    const { menus } = useSettingMainMenu();

    return (
        <Template
            header={
                <PageSettingsHeaderWithAvatar
                    title={<HeaderSubPages>{t('settings_menu_general')}</HeaderSubPages>}
                />
            }
        >
            <SettingSkeleton sections={menus} />
        </Template>
    );
};
