import { call, put, select, takeEvery } from 'redux-saga/effects';

import { ConnectionReceivedData } from 'app/api/types/connection';
import api from 'app/api/v2/api_calls';
import storeError from 'app/common/sagas/storeError';
import { Saga } from 'app/common/types';
import { getInvitationTokenValue } from 'app/connection/services/getTokenValue';
import {
    COMPLETE_INVITATION_REQUEST,
    InvitationState,
    completeInvitationRequestFailure,
    completeInvitationRequestSuccess,
    passwordConfirmationSelector,
    passwordSelector,
} from 'app/states/connection/reducers/invitation';
import { invitationSelector, pathNameSelector } from 'app/states/reducers';

function* completeInvitation(): Saga {
    const completeInvitationState: InvitationState =
        yield select(invitationSelector);
    const pathName = yield select(pathNameSelector);
    const password = passwordSelector(completeInvitationState);

    const passwordConfirmation = passwordConfirmationSelector(
        completeInvitationState,
    );

    try {
        const data: ConnectionReceivedData = yield call(
            api.connection.completeInvitation,
            {
                password,
                password_confirmation: passwordConfirmation,
                token: getInvitationTokenValue(pathName),
            },
        );
        yield put(completeInvitationRequestSuccess());
        // TODO: Update to get rid of back
        window.location.href = data.next_url;
    } catch (error) {
        // @ts-ignore
        yield* storeError(completeInvitationRequestFailure, error);
    }
}

function* completeInvitationSaga(): Saga {
    yield takeEvery(COMPLETE_INVITATION_REQUEST, completeInvitation);
}

export default completeInvitationSaga;
