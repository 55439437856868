import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TitleContainer = styled.div`
    display: flex;
    font-size: 16px;
    font-weight: bold;
`;

export const ExpandableToggleBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ExpandableToggleBoxHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
`;

export const ExpandableToggleBoxTextBox = styled.div`
    display: flex;
    flex-direction: column;
`;

const openStyle = css`
    transition: max-height 0.5s ease;
    overflow-y: auto;
`;

const closeStyle = css`
    max-height: 0;
    transition: max-height 0.3s ease;
`;

export const ExpandableListToggle = styled.div<{ checked: boolean }>`
    height: 100%;
    overflow: hidden;
    ${({ checked }) => (checked ? openStyle : closeStyle)};
`;
