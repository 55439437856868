import { Icon, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { IconContainer } from './BulkUpdateHeader.styled';

export const BulkUpdateHeader = () => {
    const { t } = useTranslation();
    return (
        <Stack
            gap="8px"
            title={t('listing')}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
        >
            <Stack gap="8px" direction="row" alignItems="center">
                <IconContainer justifyContent="center" alignItems="center">
                    <Icon icon={['fa-pen-clip']} color="primary" iconSize="12px" />
                </IconContainer>
                <Text variant="heading6" as="span">
                    {t('bulk_update_page_title')}
                </Text>
            </Stack>
        </Stack>
    );
};
