import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ChoiceWithSubtitle } from 'app/api/types/user';
import CommonSelectMenu from 'app/common/designSystem/components/atoms/CommonSelectMenu/CommonSelectMenu';

import { FilterContainer, MenuContainer } from './FilterSelect.styled';
import FilterButton from '../buttons/FilterButton';

type Props = {
    title: string;
    options: Array<ChoiceWithSubtitle>;
    selectedOptions: Array<ChoiceWithSubtitle>;
    onChange: (options: Array<ChoiceWithSubtitle>) => void;
    isSingleSelect?: boolean;
    mandatory?: boolean;
    getActiveButtonText?: (options: Array<ChoiceWithSubtitle>) => string;
};

const getDefaultButtonText = (options: Array<ChoiceWithSubtitle>) =>
    options.map(option => option.label).join(', ');

const FilterSelect = ({
    title,
    options,
    selectedOptions,
    onChange,
    isSingleSelect = false,
    mandatory = false,
    getActiveButtonText = getDefaultButtonText,
}: Props) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const active = !!selectedOptions.length;
    const buttonText = active ? getActiveButtonText(selectedOptions) : t(title);

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (
                showMenu &&
                event.target instanceof Node &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref.current, showMenu]);

    const onClickMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleErase = () => {
        onChange([]);
    };

    const toggleActive = (item: ChoiceWithSubtitle) => {
        const toggleIdx = selectedOptions.findIndex(
            option => option.value === item.value,
        );
        if (toggleIdx === -1) {
            onChange(isSingleSelect ? [item] : [...selectedOptions, item]);
        } else {
            const newSelection = [...selectedOptions];
            newSelection.splice(toggleIdx, 1);
            onChange(isSingleSelect ? [] : newSelection);
        }

        if (isSingleSelect) {
            setShowMenu(false);
        }
    };

    return (
        <FilterContainer ref={ref}>
            <FilterButton
                text={buttonText}
                active={active}
                handleClick={onClickMenu}
                handleErase={mandatory ? undefined : handleErase}
            />
            {showMenu && (
                <MenuContainer>
                    <CommonSelectMenu
                        options={options}
                        selectedOptions={selectedOptions}
                        onClick={toggleActive}
                        closeMenu={() => setShowMenu(false)}
                        menuClassName="mdc-menu-width-auto"
                    />
                </MenuContainer>
            )}
        </FilterContainer>
    );
};

export default FilterSelect;
