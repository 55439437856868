import { useContext } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledContentLinkInput } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledContentLinkInput';
import { ControlledCustomLinkInput } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledCustomLinkInput';
import { ControlledGoogleCustomLink } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledGoogleCustomLink';
import { ControlledPostDescription } from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/components/ControlledPostDescription';

import { NoLinkMessage, TitleContainer } from './News.styled';
import NextBackButtons from '../components/NextBackButtons/NextBackButtons';
import { NewPostContext, NewPostContextType } from '../context/NewPost';
import usePlatformSelection from '../hooks/usePlatformSelection';
import { getLinkOptions } from '../PostConstants';

export default function NewsForm() {
    const { t } = useTranslation();
    const { formState, watch, handleSubmit } = useFormContext();
    const { setNextStep, setPreviousStep } = useContext<NewPostContextType>(NewPostContext);
    const { hasOnlyGoogle } = usePlatformSelection();

    const showLinkInput = watch('content_link') === 'custom_link';

    const customGoogleButton = watch('google_custom_link');

    /**
     * Show the link field when :
     *   - Not only Google selected;
     *   - Only Google selected and button selected except call
     */
    const showLinkField = !hasOnlyGoogle || (!!customGoogleButton && customGoogleButton !== 'call');

    const shouldDisplayCustomPhoneWarningGoogle = hasOnlyGoogle && customGoogleButton === 'call';

    const calculateDisabled = () => !formState.isDirty || Object.keys(formState.errors).length > 0;

    return (
        <div>
            <TitleContainer>{t('post_global_content')}</TitleContainer>
            <ControlledPostDescription />
            {hasOnlyGoogle && <ControlledGoogleCustomLink />}

            {shouldDisplayCustomPhoneWarningGoogle && (
                <NoLinkMessage>{t('posts_custom_google_field_link_save')}</NoLinkMessage>
            )}

            {showLinkField && (
                <ControlledContentLinkInput
                    options={getLinkOptions(t).filter(({ value }) =>
                        hasOnlyGoogle ? value !== 'no_link' : true,
                    )}
                    isMandatory
                />
            )}

            {showLinkInput && <ControlledCustomLinkInput />}

            <NextBackButtons
                disabled={calculateDisabled()}
                onNext={() => {
                    handleSubmit(setNextStep)();
                }}
                onPrevious={() => {
                    setPreviousStep?.();
                }}
            />
        </div>
    );
}
