import { UseQueryOptions, useQuery } from 'react-query';

import { BusinessGoogleConnectionsErrorsCountData } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import {
    BUSINESS,
    BUSINESSES,
    GOOGLE_CONNEXIONS_ERRORS_COUNT,
} from 'app/common/data/queryKeysConstants';

export const useBusinessGoogleConnectionsErrors = (
    options?: UseQueryOptions<BusinessGoogleConnectionsErrorsCountData>,
) => {
    return useQuery(
        [GOOGLE_CONNEXIONS_ERRORS_COUNT, BUSINESS, BUSINESSES],
        () => api.businessModule.publisherConnection.googleConnectionsErrorsCount(),
        options,
    );
};
