import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';
import { IconButton, Stack, Text } from '@partoohub/ui';

import { STATIC_BASE_URL } from 'app/config';

export const FullScreen = styled.div`
    width: 100vw;
    height: 100vh;
    background: whitesmoke url(${STATIC_BASE_URL}/images/app/funnel/background.png);
    background-size: cover;
    padding: 30px 0;
`;

export const FunnelModalRightButton = styled(IconButton)`
    position: fixed;
    top: 16px;
    right: 16px;
`;

export const FunnelModalLeftButton = styled(IconButton)`
    position: fixed;
    top: 16px;
    left: 16px;
`;

export const FunnelBody = styled(Stack)`
    position: relative;
    left: 100px;
    right: 100px;
    width: calc(100vw - 200px);
`;
export const FunnelModalContentWrapper = styled(Stack)`
    position: relative;
    height: calc(100vh - 60px);
    width: 65%;
    background-color: ${HEX_COLORS.white};
    border-radius: 16px;
    align-items: center;
`;

export const FunnelModalTips = styled(Stack)`
    padding: 56px 0;
    width: 35%;
`;

export const TipDescription = styled(Text)`
    white-space: break-spaces;
`;

export const Tip = styled(Stack)`
    background-color: ${HEX_COLORS.white};
    padding: 16px;
    border-radius: 16px;

    > svg {
        min-width: 87px;
    }
`;

export const FunnelPageBody = styled.div`
    padding: 24px;
    flex-grow: 1;
    width: 100%;
    overflow-y: scroll;
`;

export const FunnelPageFooter = styled.div`
    padding: 16px;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.colors.default.alpha};
`;
