import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import MenuIcon from 'app/common/components/icons/MenuIcon';
import MobilePageHeader from 'app/common/components/pageHeaders/MobilePageHeader';
import MobilePageSubHeader from 'app/common/components/pageHeaders/MobilePageSubHeader';
import { collapseSidebar } from 'app/common/reducers/global';

export const MobileHeader = () => {
    const dispatch = useDispatch();
    const openSidebar = () => dispatch(collapseSidebar());

    const { t } = useTranslation();

    return (
        <>
            <MobilePageHeader
                leftContent={<MenuIcon onClick={openSidebar} />}
                title={t('sidebar_title_conversations')}
                hideLeftContentOnIframe
            />
            <MobilePageSubHeader title={t('rb_invite_title')} />
        </>
    );
};
