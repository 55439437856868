import { Outlet, useRoutes } from 'react-router-dom';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { ReviewBoosterPageHeader } from 'app/pages/conversations/ReviewBooster/components/pageHeader/ReviewBoosterPageHeader';
import { CustomerExperienceTabs } from 'app/pages/customerExperience/components/CustomerExperienceTabs/CustomerExperienceTabs';
import {
    CEPages,
    useCanAccessCustomerExperiencePage,
} from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import { ReviewListPageHeader } from 'app/reviewManagement/reviewList/sections/PageHeader/ReviewListPageHeader';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import { REVIEWS_PATH_TOKEN, SEND_INVITE_PATH_TOKEN } from 'app/routing/routeIds';

const CustomerExperienceHeaderRoutes = () => {
    const { data: me } = useMe();
    const meRole = me ? me.role : null;

    const { canAccessCustomerExperiencePage } = useCanAccessCustomerExperiencePage();

    const canAccessToClientReviews = canAccessCustomerExperiencePage[CEPages.ClientReviews];
    const canAccessToRB = canAccessCustomerExperiencePage[CEPages.RB];

    const routes = [
        ...(canAccessToClientReviews
            ? [
                  {
                      path: REVIEWS_PATH_TOKEN,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <ReviewListPageHeader />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
        ...(canAccessToRB
            ? [
                  {
                      path: SEND_INVITE_PATH_TOKEN,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <ReviewBoosterPageHeader />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
    ];

    return useRoutes(routes);
};

export const CustomerExperienceHeader = () => {
    return (
        <>
            <CustomerExperienceHeaderRoutes />
            <CustomerExperienceTabs />
            <Outlet />
        </>
    );
};
