import styled from '@emotion/styled';

export const SuggestionIndexBox = styled.div`
    width: 24px;
    text-align: center;
    align-self: center;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.slate.initial};
`;

export const ReviewIconButtonWrapper = styled.div`
    &.disabled {
        pointer-events: none;
        opacity: 50%;
    }
    i {
        color: ${({ theme }) => theme.colors.slate.initial};
    }
`;
