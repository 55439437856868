import { Control } from 'react-hook-form';

import {
    PartnerCheckBoxes,
    StyledControlledExpandableToggleBox,
} from './DailyReportExpandableToggle.styled';
import { DailyReportSlider } from './DailyReportSlider';
import { DailyReportTitle } from './DailyReportTitle';
import { PartnerCheckBox } from './PartnerCheckBox';

interface Props {
    control: Control;
    hasPartners: {
        hasGoogle: boolean;
        hasFacebook: boolean;
        hasTripAdvisor: boolean;
    };
}

export const DailyReportExpandableToggle = ({ control, hasPartners }: Props) => {
    return (
        <StyledControlledExpandableToggleBox
            title={<DailyReportTitle />}
            subtitle=""
            control={control}
            name="reports.selectRmDailyReport"
            rules={{}}
            nodes={[
                <DailyReportSlider
                    title="daily_report_slider_title"
                    control={control}
                    name="reports.reviewType"
                />,
                <PartnerCheckBoxes key="--partner-checkboxes--">
                    <PartnerCheckBox
                        label="Google Business Profile"
                        partner="google_my_business"
                        control={control}
                        name="reports.dailyReportPartners.google"
                        disabled={!hasPartners.hasGoogle}
                    />
                    <PartnerCheckBox
                        label="Facebook"
                        partner="facebook"
                        control={control}
                        name="reports.dailyReportPartners.facebook"
                        disabled={!hasPartners.hasFacebook}
                    />
                    {hasPartners.hasTripAdvisor && (
                        <PartnerCheckBox
                            label="TripAdvisor"
                            partner="tripadvisor"
                            control={control}
                            name="reports.dailyReportPartners.tripAdvisor"
                            disabled={!hasPartners.hasTripAdvisor}
                        />
                    )}
                </PartnerCheckBoxes>,
            ]}
        />
    );
};
