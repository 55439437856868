import { useEffect, useRef, useState } from 'react';

import { SerializedStyles } from '@emotion/react';
import {
    IconButton,
    IconButtonAppearance,
    IconButtonSize,
    IconButtonVariant,
    IconElement,
    MenuList,
    Option,
} from '@partoohub/ui';

import { Container, MenuListWrapper } from './ActionButtonWithMenu.styled';

export interface Props {
    dataTrackId: string;
    icon: IconElement;
    onMenuClick: (value: string) => void;
    options: Array<Option>;
    appearance?: IconButtonAppearance;
    variant?: IconButtonVariant;
    size?: IconButtonSize;
    selectedOptionName?: string;
    disabled?: boolean;
    menuClassName?: string;
    containerClassName?: string;
    menuContainerClassName?: string;
    disabledValues?: Array<string>;
    customPosition?: 'left' | 'right';
    cssMenu?: SerializedStyles;
    cssContainer?: SerializedStyles;
    tooltip?: string;
}

export const ActionButtonWithMenu = ({
    dataTrackId,
    icon,
    onMenuClick,
    options,
    appearance = 'outlined',
    variant = 'default',
    size = 'medium',
    selectedOptionName = '',
    disabled = false,
    cssMenu,
    cssContainer,
    tooltip,
}: Props) => {
    const wrapperRef = useRef<HTMLElement | null>(null);
    const [isMenuShown, setIsMenuShown] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (
                isMenuShown &&
                wrapperRef.current &&
                event.target instanceof Node &&
                !wrapperRef.current.contains(event.target)
            ) {
                toggleMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuShown]);

    const toggleMenu = () => {
        setIsMenuShown(prevIsMenuShown => !prevIsMenuShown);
    };

    const onClick = (value: Option) => {
        toggleMenu();
        onMenuClick(value.value);
    };

    const handleIsChecked = (option: Option) => {
        return option.name === selectedOptionName;
    };

    return (
        <Container ref={ref => (wrapperRef.current = ref)} cssContainer={cssContainer}>
            <IconButton
                dataTrackId={dataTrackId}
                icon={icon}
                appearance={appearance}
                variant={variant}
                onClick={toggleMenu}
                size={size}
                disabled={disabled}
                tooltip={tooltip}
            />
            {isMenuShown && (
                <MenuListWrapper css={cssMenu}>
                    <MenuList
                        sections={[{ options: options }]}
                        isChecked={handleIsChecked}
                        onChange={value => {
                            if (value) onClick(value);
                        }}
                    />
                </MenuListWrapper>
            )}
        </Container>
    );
};
