import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TransparencyEnum } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

import Clickable from 'app/common/components/buttons/Clickable';

export const IconContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: end;
`;
export const ButtonSelectContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
`;
export const TextWrap = styled.div`
    white-space: pre-line;
`;
export const Notice = styled(Text)`
    padding: 4px 16px;
    margin-bottom: 8px;
    min-height: 28px;
`;

export const BusinessSelect = styled(Clickable)<{ disabled?: boolean; readonly?: boolean }>`
    display: flex;
    position: relative;
    min-height: 56px;
    background: ${({ theme }) => theme.colors.theme.background};
    align-items: center;
    padding: 8px 16px;
    border: 1px solid ${({ theme }) => theme.colors.default.alpha};
    border-radius: ${({ theme }) => theme.radius.large};
    transition: all 0.2s;
    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.default.background};
    }
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: ${TransparencyEnum.DISABLED};
            cursor: default;
            pointer-events: none;
        `};

    ${({ readonly, theme }) =>
        readonly &&
        css`
            background-color: ${theme.colors.secondary.alpha};
        `};
`;
