import { useImagesUploaderContext } from 'app/businessEditV2/sections/CustomFieldsSection/CustomFieldsComponents/CustomFieldsTypeImagesUploader/hooks/useImagesUploaderContext';

import { ImageUploaderBodyWrapper } from './ImageUploaderRowBody.styled';
import { ImageUploaderTextField } from './ImageUploaderTextField';

type ImageUploaderRowBodyProps = {
    imageIndex: number;
};

export const ImageUploaderRowBody = ({ imageIndex }: ImageUploaderRowBodyProps) => {
    const { textFields, maxLengths } = useImagesUploaderContext();

    return (
        <ImageUploaderBodyWrapper gap="16px">
            {textFields.map((textField, i) => (
                <ImageUploaderTextField
                    key={`{image_uploader__${textField}`}
                    imageIndex={imageIndex}
                    field={textField}
                    maxLength={maxLengths[i] as number}
                />
            ))}
        </ImageUploaderBodyWrapper>
    );
};
