import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { GROUPS_V2 } from 'app/common/data/queryKeysConstants';

export default function useDeleteSection(
    sectionId: string,
    onSuccess: () => void = () => undefined,
    onError: () => void = () => undefined,
) {
    const queryClient = useQueryClient();
    return useMutation(() => api.groupsV2.deleteSection(sectionId), {
        onSuccess: () => {
            onSuccess();

            // Hack to invalidate the cache after the modal is closed
            setTimeout(() => {
                queryClient.invalidateQueries(invalidatesAny(GROUPS_V2));
            }, 1);
        },
        onError,
    });
}
