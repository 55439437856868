import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba, greyColorObject } from '@partoohub/branding';

type ProductButtonContainerProps = {
    marginTop?: string;
    active?: boolean;
    cutAWhole?: boolean;
    to?: string;
};

export const ProductButtonContainer = styled('a', {
    shouldForwardProp: (prop: string) => !['active', 'as', 'marginTop', 'cutAWhole'].includes(prop),
})<ProductButtonContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 64px;
    min-height: 64px;
    width: 64px;
    min-width: 64px;
    padding: 12px;
    cursor: pointer;
    color: white;

    ${props =>
        props.marginTop &&
        css`
            margin-top: ${props.marginTop};
        `}

    &:active,
    &:focus {
        outline: 0;
    }

    &:hover {
        text-decoration: none;
        color: white;
    }

    &:before,
    &:after {
        box-shadow: none;
        position: absolute;
        content: '';
        pointer-events: none;
    }

    ${props =>
        !props.active &&
        css`
            &:hover:not(:active):not(:focus) {
                > div {
                    background-color: rgba(0, 0, 0, 0.2);
                }
            }

            &:active,
            &:focus {
                > div {
                    background-color: rgba(0, 0, 0, 0.35);
                }
            }
        `}

    // Handle reverse border with box-shadow
    ${props =>
        props.active &&
        css`
            background-color: ${greyColorObject.light} !important; // override app.css:137
            &:before,
            &:not(:last-child):after {
                width: 30px;
                height: 8px;
                background-color: transparent;
                right: 0;
            }
            &:before {
                top: 0;
                border-radius: 0 0 4px 0;
                box-shadow: 0 4px 0 0 ${greyColorObject.light};
                transform: translateY(-100%);
            }
            &:not(:last-child):after {
                bottom: 0;
                border-radius: 0 4px 0 0;
                box-shadow: 0 -4px 0 0 ${greyColorObject.light};
                transform: translateY(100%);
            }
        `}

    ${props =>
        props.cutAWhole &&
        css`
            svg {
                // use clip-path to contour the 40px * 40px svg icon and enter from a corner to cut a whole
                // in it
                clip-path: path(
                    'M 40,0 L 40,40 L 0,40 L 0,0 L 40,0 L 34.4,4.5 a 10,10 0 1 0 1,1 Z'
                );
            }
        `}
`;

export const ProductButtonHover = styled.div`
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 8px;
`;

export const ProductButtonTooltip = styled.div`
    padding: 8px 16px;
    background: ${HEX_COLORS.white};
    color: ${HEX_COLORS.blackberry};
    box-shadow: 0 0 8px ${convertToRgba(HEX_COLORS.black, 0.15)};
    text-align: center;
    font-size: 12px;
    border-radius: 4px;
    white-space: pre;
    text-overflow: ellipsis;
    max-width: 500px;
    z-index: 9999;
    font-weight: bold;
    pointer-events: none;
    left: 2px !important;

    &:before {
        content: '';
        position: absolute;
        width: 14.77px;
        height: 14.77px;
        border-radius: 2px;
        transform: matrix(-0.72, 0.72, -0.67, -0.72, 0, 0);
        left: -3px;
        top: 9px;
        background-color: ${HEX_COLORS.white};
    }
`;

export const RedDot = styled('div', {
    shouldForwardProp: prop => prop !== 'color',
})`
    z-index: 1;
    background-color: ${props => props.color || HEX_COLORS.danger};
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;
    transform: translate(50%, -50%);
`;
