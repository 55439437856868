import { SmallStatProps } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/DefaultStatComponent';
import { Item } from 'app/pages/Homepage/utils/typing';

import { useAllStatsItems } from './useAllStatsItems';

const MAX_STATS_ITEMS = 3;

export const useStatsItems = (): Array<Item<SmallStatProps>> => {
    const allShortcutsItems = useAllStatsItems();
    return allShortcutsItems
        .filter(item => item.isAvailable)
        .sort((a, b) => a.order - b.order)
        .slice(0, MAX_STATS_ITEMS);
};
