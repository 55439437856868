import { endOfDay, parseISO, startOfDay } from 'date-fns';

import { FeedbackResultQueryParams } from 'app/api/types/feedbackResults';
import { CursorPaginationParams, LiteralRating, ReviewObjectType } from 'app/api/types/review';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { useGetReviewsFilters } from 'app/reviewManagement/reviewList/hooks/useGetReviewsFilters';
import {
    REVIEW_LIST_PAGE_SIZE,
    stateToQueryReviewParam,
} from 'app/reviewManagement/reviewList/hooks/useGetReviewsQueryParams';
import { ratingToNps } from 'app/states/reviews';

export const useFeedbackResultQueryParams = (): FeedbackResultQueryParams &
    CursorPaginationParams => {
    const reviewFilters = useGetReviewsFilters();
    const businessModalFilters = useBusinessModalFilters();
    const { dateFrom, dateTo, search, rating, status } = reviewFilters;

    return {
        created__gte: dateFrom ? startOfDay(parseISO(dateFrom)).toISOString() : null,
        created__lte: dateTo ? endOfDay(parseISO(dateTo)).toISOString() : null,
        nps__in: rating ? ratingToNps(rating.split(',') as LiteralRating[]) : null,
        search: search || null,
        state__in: status
            ? stateToQueryReviewParam(status, ReviewObjectType.FEEDBACK_RESULT)
            : null,
        optin: 1,
        ...businessModalFilters,
        per_page: REVIEW_LIST_PAGE_SIZE,
    };
};
