import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS } from '@partoohub/branding';

import { DropzoneWrapperSelectorId } from 'app/common/designSystem/components/molecules/DropZone/DropZone.styled';

export const MARGIN = 8;

export const PhotoSizeCss = css`
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    margin: calc(${MARGIN}px / 2);
    height: 0; // Hack to make it square with the padding-bottom
    width: calc(20% - ${MARGIN}px);
    padding-bottom: calc(20% - ${MARGIN}px);
    @media screen and (max-width: 1440px) {
        width: calc(25% - ${MARGIN}px);
        padding-bottom: calc(25% - ${MARGIN}px);
    }
    @media screen and (max-width: 1180px) {
        width: calc(33% - ${MARGIN}px);
        padding-bottom: calc(33% - ${MARGIN}px);
    }
    @media screen and (max-width: 980px) {
        width: calc(50% - ${MARGIN}px);
        padding-bottom: calc(50% - ${MARGIN}px);
    }
    @media screen and (max-width: 640px) {
        width: calc(100% - ${MARGIN}px);
        padding-bottom: calc(100% - ${MARGIN}px);
    }
`;

export const PhotosContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 28px;
`;

export const DropZoneContainer = styled.div<{ disabled?: boolean }>`
    ${PhotoSizeCss};

    [data-css-selector=${`${DropzoneWrapperSelectorId}`}] {
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
        padding: 8px;

        ${props =>
            props.disabled
                ? css`
                      opacity: 0.4;
                  `
                : css`
                      &:hover,
                      &:hover .drop_zone_text_wrapper {
                          border-color: ${HEX_COLORS.blackberry};
                          color: ${HEX_COLORS.blackberry};
                      }
                  `}
    }
`;

export const FullScreenImageRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 44px 24px;
    font-size: 14px;
    font-weight: bold;
`;

export const FullScreenImageRightText = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.secondary.initial};

    > i {
        margin-right: 8px;
    }
`;

export const FullScreenImageRightIcons = styled.div`
    display: flex;
    flex-direction: column;

    > * {
        margin-top: 8px;
    }
`;
