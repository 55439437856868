import styled from '@emotion/styled';

export const DraggableItem = styled.div`
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 8px;

    &:last-of-type {
        margin-bottom: 0;
    }
`;
