import { calculateDataPayloadCreatePost } from './calculatePayloadCreatePost';

export async function calculateDataPayloadEditPost(formFields, formState) {
    /**
     * @param formFields<FormFields> stored in a local React Reducer
     * @param formState<News|Event> stored by react-hook-form switchable depending on selected post type
     * @returns dataPayload
     * Any state to be processed and merged before post creation should be done here
     * Since we keep 2 different states (react and react-form), we proceed to merge them depending on business requiriments
     * Not all state is updatable.
     * Per publisher:
     * Facebook: Can't update photo, link
     * Google: Nothing
     */

    // leaving this function open in case we need different business logic for update at some point

    return calculateDataPayloadCreatePost(formFields, formState);
}
