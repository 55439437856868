import useUsersStats from 'app/common/hooks/queries/useUsersStats';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import {
    UserListQueryKeys,
    UserStatus,
} from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

export default function useUserCount(): number {
    const { data: userStats } = useUsersStats();
    const [queryStatus] = useStateQueryParams(UserListQueryKeys.STATUS);

    if (!userStats) {
        return 0;
    } else if (queryStatus === UserStatus.ACTIVE) {
        return userStats.activated_count;
    } else if (queryStatus === UserStatus.INVITED) {
        return userStats.invited_count;
    } else {
        return userStats.count;
    }
}
