import { ShortcutCreatePost } from 'app/pages/Homepage/sections/HomepageShortcuts/shortcuts/ShortcutCreatePost';
import { Item } from 'app/pages/Homepage/utils/typing';

import { useCanCreatePost } from './useCanCreatePost';

export const useShortcutCreatePostItem = (): Item => {
    const isAvailable = useCanCreatePost();

    return {
        order: 3,
        isAvailable,
        component: ShortcutCreatePost,
        key: 'shortcut-create-post',
    };
};
