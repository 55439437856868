import { useEffect } from 'react';

import { Table } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { sumValues } from 'app/common/utils/sumValues';

import { useInternalRankingParameters } from './hooks/useInternalRankingParameters';
import { StyledCell } from './Rankings.styled';
import { useReviewTagMetrics } from '../../../hooks/useMetrics';

export const TagsRanking = () => {
    const { t } = useTranslation();
    const { resetParameters } = useInternalRankingParameters();
    useEffect(() => {
        resetParameters({});
    }, []);

    const metrics = useReviewTagMetrics();

    return (
        <Table
            columns={[
                {
                    label: t('review_analytics_rankings_tag'),
                    width: '3000px',
                },
                {
                    label: t('review_analytics_rankings_reviews_tagged'),
                    width: '3000px',
                },
                {
                    label: t('review_analytics_rankings_tag_average_rating'),
                    width: '3000px',
                },
            ]}
        >
            {!!metrics ? (
                <Table.Body>
                    {metrics.data.map(({ dimension, dimension_name, metrics }) => (
                        <Table.Row dataTrackId="" id={dimension} key={dimension}>
                            <StyledCell>{dimension_name}</StyledCell>
                            <StyledCell>{sumValues(metrics.rating_distribution)}</StyledCell>
                            <StyledCell>{metrics.average_rating}</StyledCell>
                        </Table.Row>
                    ))}
                </Table.Body>
            ) : undefined}
        </Table>
    );
};
