import React from 'react';

import { useFormatNumber } from 'app/common/hooks/useFormatNumber';
import { CountNumber } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Count/Count.styled';

type Props = {
    count: number;
};

export const Count = ({ count }: Props) => {
    const formattedCount = useFormatNumber(count);
    return <CountNumber>{formattedCount}</CountNumber>;
};
