import { useEffect } from 'react';

import { intersection } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LiteralRating } from 'app/api/types/review';
import { ChipMatcher } from 'app/common/services/Matchers';
import { useGetReplyTemplates } from 'app/pages/settings/AutoReply/hooks/queries/useGetReplyTemplates';
import useGetTemplatePlaceholders from 'app/pages/settings/AutoReply/hooks/queries/useGetTemplatePlaceholders';
import { useSettingsPageContext } from 'app/pages/settings/AutoReply/hooks/useSettingsPageContext';

import ReplyTemplateCard from './ReplyTemplateCard';
import { CardsContainer } from './ReplyTemplateList.styled';

import WarningBox from '../WarningBox';

const ReplyTemplateList = () => {
    const { t } = useTranslation();
    const {
        forReviewsWithComment,
        loadedSettings,
        languages,
        selectedRatings,
        filters,
        onTemplatesSelect,
    } = useSettingsPageContext();

    const languageFilter = filters.languages.length
        ? filters.languages.map(langFilter => langFilter.value)
        : languages;

    const ratingsFilter = filters.ratings.length
        ? (filters.ratings.map(rating => rating.value) as LiteralRating[])
        : (selectedRatings.map(selectedRating => selectedRating.value) as LiteralRating[]);

    const placeholders = useGetTemplatePlaceholders();
    const { suggestedTemplates, customTemplates, fetchNextPage, hasNextPage } =
        useGetReplyTemplates({
            forReviewsWithComment,
            languageFilter,
            ratingsFilter,
            filters,
        });

    useEffect(() => {
        if (forReviewsWithComment) {
            return;
        }
        // Auto select suggested templates on ratings that were not the target selected in step 1
        // Only for reviews without comments
        const templateRatingsToSelect = ['positive', 'neutral', 'negative'].filter(rating =>
            loadedSettings ? !loadedSettings[rating] : true,
        );

        onTemplatesSelect(
            true,
            ...suggestedTemplates.filter(
                template => !!intersection(template.ratings, templateRatingsToSelect).length,
            ),
        );
    }, [suggestedTemplates]);

    const templates = [...suggestedTemplates, ...customTemplates];

    if (!selectedRatings.length) {
        return (
            <WarningBox
                title={t('auto_reply_template_select_no_target_title')}
                description={t('auto_reply_template_select_no_target_description')}
            />
        );
    }

    if (!templates.length || !placeholders) {
        return (
            <WarningBox
                title={t('auto_reply_template_select_no_template_title')}
                description={t('auto_reply_template_select_no_template_description')}
                showLink
            />
        );
    }

    return (
        <InfiniteScroll
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={<></>}
            dataLength={templates.length}
            scrollableTarget="react-content"
        >
            <CardsContainer>
                {templates.map(template => (
                    <ReplyTemplateCard
                        key={template.id}
                        template={template}
                        textMatchers={placeholders.map(element =>
                            ChipMatcher(
                                `@\\[${element.label}]\\(${element.value}\\)`,
                                t(element.label),
                            ),
                        )}
                    />
                ))}
            </CardsContainer>
        </InfiniteScroll>
    );
};

export default ReplyTemplateList;
