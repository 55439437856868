import styled from '@emotion/styled';

import { Text } from '@partoohub/ui';

export const ConnectionModalInfoSection = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 82px 40px;
    gap: 32px;
    overflow-y: auto;
    overscroll-behavior: contain;
`;

export const StyledDescription = styled(Text)`
    white-space: pre-line;
`;

export const ContentText = styled(Text)`
    text-align: center;
`;
