import { useEffect, useMemo, useState } from 'react';

import { Modal } from '@partoohub/ui';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditForm from 'app/businessEditV2/components/EditForm';
import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import { ColorState } from 'app/businessEditV2/hooks/editV2utils/sectionColors/types';
import useOpenHoursSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useOpenHoursSectionColor';
import useBusinessHasOpenHours from 'app/businessEditV2/hooks/editV2utils/useBusinessHasOpenHours';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useFormFieldFormatter from 'app/businessEditV2/hooks/publisherErrors/useFormFieldFormatter';
import { formatOpenHoursFields } from 'app/businessEditV2/oldFromEditV1/services/formatDataForAPI';
import OpenHours from 'app/businessEditV2/sections/OpenHoursSection/OpenHoursForm/OpenHours';
import {
    OpenHoursDescription,
    OpenHoursDescriptionText,
    OpenHoursPrechecks,
} from 'app/businessEditV2/sections/OpenHoursSection/OpenHoursForm/OpenHoursForm.styled';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import ExplanationWithPublisherList from 'app/common/designSystem/components/atoms/ExplanationHOC/ExplanationWithPublisherList';
import StrikeThroughButton from 'app/common/designSystem/components/atoms/StrikeThroughButton';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

import TimeSlotReferenceModalContent from './TimeSlotReferenceModal';

type Props = {
    closeModal: () => void;
    useDirtyUpdate: (boolean) => void;
};

const OpenHoursForm = ({ closeModal, useDirtyUpdate }: Props) => {
    const { t } = useTranslation();
    const colorState = useOpenHoursSectionColor();
    const { data: business, isLoading } = useBusiness();
    const hasOpenHours = useBusinessHasOpenHours();
    const [showTimeSlotReferenceModal, setShowTimeSlotReferenceModal] = useState<boolean>(false);

    const setFormValue = () => ({
        openHours: business?.open_hours ?? {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
        },
    });

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        watch,
        reset,
    } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    useDirtyUpdate(isDirty);

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const timeSlotReference = business?.time_slot_reference;

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const businessUpdate = useBusinessUpdate(() => {
        businessUpdateCompletionRate.mutate();
        closeModal();
    });

    const errors = businessUpdate.error?.response?.data?.errors?.json?.open_hours;

    const onSubmit = payload => {
        businessUpdate.mutate(payload);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_OPEN_HOURS_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
    };

    // Permission
    const businessIsOpen = !useIsBusinessClosed();
    const hoursPermission = useFieldMetadata('hours', 'open_hours')?.enabled && businessIsOpen;

    const formatPayload = payload => {
        if (!hoursPermission) {
            return {};
        }

        return {
            open_hours: formatOpenHoursFields(payload.openHours),
        };
    };

    const submitForm = () => {
        handleSubmit(payload => onSubmit(formatPayload(payload)))();
        trackSaveBusinessEditSection(
            SectionNames.OPEN_HOURS,
            hasOpenHours ? colorState : ColorState.MISSING_WARNING,
        );
    };

    const formatter = useFormFieldFormatter('hours', 'open_hours', watch, formatPayload, errors);

    return (
        <>
            <EditForm
                title={t('business_open_hours')}
                description={
                    <OpenHoursDescription>
                        <OpenHoursDescriptionText>
                            {t('business_edit_open_hours_description')}
                        </OpenHoursDescriptionText>
                        {formatter.hasWarning && (
                            <OpenHoursPrechecks>
                                <ExplanationWithPublisherList
                                    publisherErrors={formatter.publisherErrors}
                                    publishersInfo={formatter.publishersInfo}
                                    disabled={formatter.disabled}
                                />
                            </OpenHoursPrechecks>
                        )}
                    </OpenHoursDescription>
                }
                descriptionRight={
                    !!hoursPermission ? (
                        <StrikeThroughButton
                            icon={<i className="fa-solid fa-cog" />}
                            text={t(
                                (timeSlotReference ?? []).length === 0
                                    ? 'time_slot_reference'
                                    : 'update_time_slot_reference',
                            )}
                            color={'secondary'}
                            onClick={() => setShowTimeSlotReferenceModal(true)}
                            disabled={!hoursPermission}
                        />
                    ) : undefined
                }
                colorState={colorState}
            >
                <div>
                    <Controller
                        name="openHours"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <OpenHours
                                value={value}
                                onChange={onChange}
                                errors={errors}
                                disabled={!hoursPermission}
                                timeSlotReference={timeSlotReference}
                                openTimeSlotReferenceModal={() =>
                                    setShowTimeSlotReferenceModal(true)
                                }
                            />
                        )}
                    />
                </div>
                <Modal
                    isOpen={showTimeSlotReferenceModal}
                    closeModal={() => setShowTimeSlotReferenceModal(false)}
                >
                    <TimeSlotReferenceModalContent
                        closeModal={() => setShowTimeSlotReferenceModal(false)}
                    />
                </Modal>
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={businessUpdate.isLoading}
                    hasError={!!businessUpdate.error}
                    onClick={submitForm}
                    dataTrackId="visibility_location__form_open_hours__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default OpenHoursForm;
