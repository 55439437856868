import { TextArea, TextAreaProps } from '@partoohub/ui';
import { Control, useController } from 'react-hook-form';

interface ControlledComponent {
    name: string;
    control: Control<any>;
    onChangeValue?: (value?: string) => string | void;
}

type OmitProps = 'onChange' | 'value';

export const ControlledTextArea = ({
    name,
    control,
    onChangeValue = value => value,
    ...props
}: Omit<TextAreaProps, OmitProps> & ControlledComponent) => {
    const {
        field: { ref: _ref, onChange, ...usedFields },
    } = useController({
        name,
        control,
    });

    const handleChange = (value: string) => onChange(onChangeValue(value));

    return <TextArea {...props} {...usedFields} onChange={handleChange} />;
};
