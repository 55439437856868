import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEX_COLORS, convertToRgba } from '@partoohub/branding';

export const StyledFeedbackResultLinearScaleField = styled.div`
    display: flex;
    flex-direction: column;
    grid-column: span 2;
    gap: 16px;
    background-color: transparent;
`;

export const StyledLinearScale = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

export const StyledLinearScaleButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledLinearScaleButton = styled.div<{ color: string; selected: boolean }>`
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;

    ${({ color, selected }) => css`
        background-color: ${selected ? color : undefined};
        opacity: ${selected ? 1 : 0.3};
        border: ${selected ? undefined : `1px solid ${convertToRgba(HEX_COLORS.blackberry, 0.3)}`};
    `}
    border-radius: 50%;
`;

export const StyledLinearScaleLabels = styled.div`
    display: flex;
    justify-content: space-between;
`;
