import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import { FunnelBulkInApp } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/components/FunnelBulkInApp';
import { FunnelModalContextProvider } from 'app/pages/visibility/BusinessListV2/sections/BusinessBulkInAppModal/hooks/FunnelModalContext';

import BusinessList from './BusinessList';
import { BusinessListWithNewSidebar } from './BusinessListWithNewSidebar';

export const BusinessListV2Page = () => {
    const { t } = useTranslation();
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const BusinessListComponent = isNewSidebarEnabled ? BusinessListWithNewSidebar : BusinessList;

    return (
        <>
            <DynamicPageHeader title={t('businesses_list')} />
            <FunnelModalContextProvider>
                <BusinessListComponent />
                <FunnelBulkInApp />
            </FunnelModalContextProvider>
        </>
    );
};
