import { Option } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import type { OverviewType } from 'app/api/types/competitiveBenchmark';

import { OverviewMenuList, OverviewSelectionContainer } from './OverviewSelection.styled';
import { useOverviewSelection } from '../../hooks/useOverviewSelection';

export const OverviewSelection = () => {
    const { t } = useTranslation();
    const [overviewSelection, setOverviewSelection] = useOverviewSelection();

    const options: Option[] = [
        { name: 'rank', value: 'rank', label: t('ranking_word') },
        { name: 'average_reviews', value: 'average_reviews', label: t('reviews_count') },
        { name: 'average_rating', value: 'average_rating', label: t('average_grade') },
    ];

    return (
        <OverviewSelectionContainer>
            <OverviewMenuList
                sections={[{ options }]}
                itemType="radio"
                isChecked={option => option.value === overviewSelection}
                onChange={option => setOverviewSelection(option.value as OverviewType)}
            />
        </OverviewSelectionContainer>
    );
};
