import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

import { Button, Text } from '@partoohub/ui';

export const ApiKeyFormModalContainer = styled.div`
    height: 100vh;
    width: 100vw;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ApiKeyFormModalHeader = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    margin-right: 4px;
`;

export const ApiKeyFormModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    max-width: 750px;
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.secondary.initial};
    height: 24px;
    width: 24px;
    border-radius: 4px;
`;

export const ApiKeyFormModalForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: solid 1px ${greyColorObject.initial};
    border-radius: 4px;
    padding: 32px 24px;
    width: 100%;
`;

export const StyledExpirationText = styled(Text)`
    padding: 4px 16px;
`;

export const StyledSubmitButton = styled(Button)`
    margin: 24px 0 40px 0;
`;
