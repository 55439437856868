import { ReviewsStatsState } from './types';
import { ReviewsStatsActionType } from '../actions';
import * as constants from '../actions/constants';

// INITIAL STATES
export const initialState: ReviewsStatsState = {
    count: 0,
    treated: 0,
    notTreated: 0,
    deleted: 0,
};

// REDUCER
export const reviewsStatsReducer = (
    state: ReviewsStatsState = initialState,
    action: ReviewsStatsActionType,
): ReviewsStatsState => {
    switch (action.type) {
        case constants.GET_REVIEWS_STATS_SUCCESS:
            return {
                ...state,
                count: action.count,
                treated: action.treated,
                notTreated: action.notTreated,
                deleted: action.deleted,
            };

        case constants.UPDATE_REVIEW_STATS:
            return {
                ...state,
                treated:
                    state.treated + action.treated > 0
                        ? state.treated + action.treated
                        : 0,
                notTreated:
                    state.notTreated + action.notTreated > 0
                        ? state.notTreated + action.notTreated
                        : 0,
            };

        case constants.ADD_REVIEW_STATS:
            return {
                ...state,
                count: state.count + action.count,
                treated: state.treated + action.treated,
                notTreated: state.notTreated + action.notTreated,
            };

        default:
            return state;
    }
};

export default reviewsStatsReducer;
