import { Navigate, useRoutes } from 'react-router-dom';

import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks/useDeviceType';
import { findFirstDisplayPathname } from 'app/pages/settingsV2/helpers';
import { useIsCustomFieldsPageEnabled } from 'app/pages/settingsV2/hooks/Integrations/pagesEnabled/useIsCustomFieldsPageEnabled';
import { useSettingIntegrationsMenu } from 'app/pages/settingsV2/hooks/Integrations/useSettingIntegrationsMenu';
import { ApiKeys } from 'app/pages/settingsV2/subPages/Integrations/components/ApiKeys/ApiKeys';
import { CustomFields } from 'app/pages/settingsV2/subPages/Integrations/components/CustomFields';
import { SettingIntegrations } from 'app/pages/settingsV2/subPages/Integrations/SettingIntegrations';
import {
    API_KEYS_PATH_TOKEN,
    CUSTOM_FIELDS_PATH_TOKEN,
    SETTINGS_INTEGRATIONS_PATHNAME,
} from 'app/routing/routeIds';

export const IntegrationsRoutes = () => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const { menus: settingsIntegrationsMenu } = useSettingIntegrationsMenu();
    const defaultSettingIntegrationsPathname = findFirstDisplayPathname(settingsIntegrationsMenu);

    const isCustomFieldsPageEnabled = useIsCustomFieldsPageEnabled();

    const indexRoute = isBelowProvidedDevices
        ? SETTINGS_INTEGRATIONS_PATHNAME
        : defaultSettingIntegrationsPathname;

    const routes = [
        {
            element: <SettingIntegrations />,
            children: [
                {
                    index: true,
                    element: <Navigate replace to={indexRoute} />,
                },
                ...(isCustomFieldsPageEnabled
                    ? [
                          {
                              path: CUSTOM_FIELDS_PATH_TOKEN,
                              element: <CustomFields />,
                          },
                      ]
                    : []),
                {
                    path: API_KEYS_PATH_TOKEN,
                    element: <ApiKeys />,
                },
            ],
        },
    ];

    return useRoutes(routes);
};
