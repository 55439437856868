import { Outlet, useRoutes } from 'react-router-dom';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { GooglePostsListPageHeader } from 'app/presence/googlePosts/components/googlePostList/TitleBar/GooglePostsListPageHeader';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';

export const PostsHeaderRoutes = () => {
    const { data: me } = useMe();
    const meRole = me ? me.role : null;

    const routes = [
        {
            index: true,
            element: (
                <ProtectedRoute userRole={meRole}>
                    <GooglePostsListPageHeader />
                </ProtectedRoute>
            ),
        },
    ];

    return useRoutes(routes);
};

export const PostsHeader = () => {
    return (
        <>
            <PostsHeaderRoutes />
            <Outlet />
        </>
    );
};
