import { useRef } from 'react';

import { css } from '@emotion/react';
import { IconPrefix } from '@partoohub/ui';

import { Controller, FieldValues, FormState, UseFormWatch } from 'react-hook-form';

import { Control } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import MentionTextArea from 'app/common/designSystem/components/atoms/MentionTextArea';
import { MentionTextAreaImperativeHandle } from 'app/common/designSystem/components/atoms/MentionTextArea/MentionTextArea';
import { ActionButtonWithMenu } from 'app/common/designSystem/components/molecules/ActionButtonWithMenu/ActionButtonWithMenu';
import useDebounce from 'app/common/hooks/useDebounce';
import {
    ButtonsContainer,
    TextAreaContainer,
    TextAreaFieldName,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/AutomaticMessagesConfigurationMentionTextArea.styled';
import { ConversationStarterAutomaticMessagesPlaceholders } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';
import EmojiPicker from 'app/reviewManagement/reviewList/sections/ReviewTableSection/ReviewCard/CommentSection/EmojiPicker/EmojiPicker';

interface Props {
    control: Control<{ welcome_message: string; offline_message: string }>;
    formState: FormState<FieldValues>;
    watch: UseFormWatch<{ welcome_message: string; offline_message: string }>;
    fieldId: string;
    fieldName: string;
    explanation?: string;
}

export const AutomaticMessagesConfigurationMentionTextArea = ({
    control,
    formState,
    watch,
    fieldId,
    fieldName,
    explanation,
}: Props) => {
    const { t } = useTranslation();

    const textRef = useRef<MentionTextAreaImperativeHandle>(null);

    const translatedPlaceholders = ConversationStarterAutomaticMessagesPlaceholders.map(
        placeholder => ({
            ...placeholder,
            display: t(placeholder.display),
        }),
    );

    const currentText = useDebounce(watch(fieldId));

    const addPlaceholder = (value: string) => {
        if (!textRef.current) {
            return;
        }
        const selectedPlaceholder = ConversationStarterAutomaticMessagesPlaceholders.find(
            placeholder => placeholder.id == value,
        );

        if (selectedPlaceholder) {
            textRef.current.addPlaceholder(t(selectedPlaceholder.display), selectedPlaceholder.id);
        }
    };

    const onEmojiClick = (emoji: string) => {
        textRef.current?.insert(emoji, currentText);
    };

    return (
        <TextAreaContainer>
            <Controller
                name={fieldId}
                control={control}
                rules={{
                    required: true,
                }}
                render={({ field: { ref: _ref, ...usedFields } }) => (
                    <MentionTextArea
                        {...usedFields}
                        ref={textRef}
                        isMandatory
                        choices={translatedPlaceholders}
                        hasError={['required', 'maxLength'].includes(
                            formState.errors.reply_message?.type,
                        )}
                        explanation={explanation}
                        hasCustomAbsoluteButtons
                    />
                )}
            />
            <TextAreaFieldName as="p" variant="bodySBold">
                {fieldName}
            </TextAreaFieldName>
            <ButtonsContainer>
                <EmojiPicker onEmojiClick={onEmojiClick} onClick={() => undefined} />
                <ActionButtonWithMenu
                    dataTrackId="automatic_messages__add_placeholder"
                    appearance="outlined"
                    icon={['fa-at', IconPrefix.REGULAR]}
                    options={ConversationStarterAutomaticMessagesPlaceholders.map(
                        ({ id, display }) => ({
                            label: t(display),
                            value: id,
                        }),
                    )}
                    onMenuClick={value => addPlaceholder(value)}
                    cssMenu={css`
                        top: 40px;
                    `}
                />
            </ButtonsContainer>
        </TextAreaContainer>
    );
};
