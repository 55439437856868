import { useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton } from '@partoohub/ui';

import { usePublishersStates } from 'app/presence/management/hooks/usePublishersStates';
import { hideableComponentsNames, withHideableComponent } from 'app/SDKBridge';
import { DOWNLOADABLE_MAX } from 'app/states/reviews';

import { ConfirmEmailModalPresenceManagement } from '../ConfirmEmailModal/ConfirmEmailModalPresenceManagement';

const ExportDataButton = () => {
    const { data } = usePublishersStates();
    const businessCount = data?.business_count;
    const [isOpen, setIsOpen] = useState(false);

    const handleExportDataButtonOnClick = () => {
        setIsOpen(true);
    };

    if (!businessCount) {
        return null;
    }

    const isDisabled = businessCount >= DOWNLOADABLE_MAX;

    return (
        <>
            <ConfirmEmailModalPresenceManagement isOpen={isOpen} setIsOpen={setIsOpen} />
            <IconButton
                dataTrackId="open_download_broadcast_data_modal_button"
                icon={[FontAwesomeIconsPartooUsed.faArrowDownToLine]}
                appearance="outlined"
                variant="primary"
                onClick={handleExportDataButtonOnClick}
                disabled={isDisabled}
            />
        </>
    );
};

export default withHideableComponent(
    ExportDataButton,
    hideableComponentsNames.PRESENCE_DOWNLOAD_BUTTON,
);
