import React, { useState } from 'react';

import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import AsyncSingleSelect from 'app/common/designSystem/components/molecules/AsyncSingleSelect';
import { Props } from 'app/common/designSystem/components/molecules/AsyncSingleSelect/AsyncSingleSelect';
import { formatOrgToChoice, useOrgs } from 'app/common/hooks/queries/useOrgs';
import useDebounce from 'app/common/hooks/useDebounce';

type ControlledComponent = {
    name: string;
    control: Control<any>;
    setValue: UseFormSetValue<any>;
};

type OmitProps =
    | 'onChange'
    | 'onInputChange'
    | 'value'
    | 't'
    | 'options'
    | 'isLoading'
    | 'selectedOption'
    | 'placeholder';

const ControlledOrgSelect = ({
    name,
    control,
    setValue,
    ...props
}: Omit<Props, OmitProps> & ControlledComponent) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const queryDebounced = useDebounce(query);
    const [orgsQuery, orgs] = useOrgs({ query: queryDebounced });

    const options = orgs?.map(organization => formatOrgToChoice(organization)) ?? [];
    const loadMore = (searchInput: string) => {
        if (searchInput != query) {
            setQuery(searchInput);
        } else {
            orgsQuery.fetchNextPage();
        }
    };

    const resetDataOnOrgChange = () => {
        setValue('group', undefined);
        setValue('businesses', []);
        setValue('accesses', []);
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return (
                    <AsyncSingleSelect
                        options={options}
                        selectedValue={value}
                        onChange={value => {
                            resetDataOnOrgChange();
                            onChange(value);
                        }}
                        hasMore={orgsQuery.hasNextPage}
                        loadMore={loadMore}
                        placeholder={t('organization')}
                        isMandatory
                        mustFilterOptions
                        {...props}
                    />
                );
            }}
        />
    );
};

export default ControlledOrgSelect;
