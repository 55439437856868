import React from 'react';

import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import ErrorBoundary from 'app/common/components/ErrorBoundary';
import PageErrorPlaceholder from 'app/common/components/errors/PageErrorPlaceholder';
import GoogleVerification from 'app/settingsManagement/components/GoogleVerification/GoogleVerification';

export const GoogleVerificationPage = () => {
    const { t } = useTranslation();

    return (
        <ErrorBoundary fallback={<PageErrorPlaceholder />}>
            <DynamicPageHeader title={t('google_verification')} />
            <GoogleVerification />
        </ErrorBoundary>
    );
};
