import { useEffect, useMemo } from 'react';

import { Stack } from '@partoohub/ui';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import EditForm from 'app/businessEditV2/components/EditForm';
import { EditFormFooter } from 'app/businessEditV2/components/EditForm/EditForm.styled';
import EditFormStatefulButton from 'app/businessEditV2/components/EditForm/EditFormStatefulButton';
import { ControlledTextArea } from 'app/businessEditV2/hookControllerWrapper/ControlledTextArea';
import useBusiness from 'app/businessEditV2/hooks/business/useBusiness';
import useBusinessUpdate from 'app/businessEditV2/hooks/business/useBusinessUpdate';
import useBusinessUpdateCompletionRate from 'app/businessEditV2/hooks/business/useBusinessUpdateCompletionRate';
import useDescriptionSectionColor from 'app/businessEditV2/hooks/editV2utils/sectionColors/useDescriptionSectionColor';
import { useIsBusinessClosed } from 'app/businessEditV2/hooks/editV2utils/useBusinessStatus';
import useFieldMetadata from 'app/businessEditV2/hooks/permissions/useFieldMetadata';
import useFormFieldFormatter from 'app/businessEditV2/hooks/publisherErrors/useFormFieldFormatter';
import { trackSaveBusinessEditSection } from 'app/businessEditV2/utils/tracking';
import { SectionNames } from 'app/businessEditV2/utils/utils';
import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

const MAX_LONG_DESCRIPTION_CHARACTER_COUNT = 750;
const MAX_SHORT_DESCRIPTION_CHARACTER_COUNT = 80;

type Props = {
    closeModal: () => void;
    useDirtyUpdate: (boolean) => void;
};

const DescriptionsForm = ({ closeModal, useDirtyUpdate }: Props) => {
    const { t } = useTranslation();
    const colorState = useDescriptionSectionColor();
    const { data: business, isLoading } = useBusiness();

    const setFormValue = () => ({
        descriptionLong: business?.description_long || '',
        descriptionShort: business?.description_short || '',
    });

    const {
        control,
        handleSubmit,
        formState: { isDirty },
        watch,
        reset,
    } = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    useDirtyUpdate(isDirty);

    useEffect(() => {
        if (!isLoading) {
            reset(setFormValue());
        }
    }, [isLoading]);

    const businessUpdateCompletionRate = useBusinessUpdateCompletionRate();
    const businessUpdate = useBusinessUpdate(() => {
        businessUpdateCompletionRate.mutate();
        closeModal();
    });

    // @ts-ignore
    const errors = businessUpdate.error?.response?.data?.errors?.json;

    const onSubmit = payload => {
        businessUpdate.mutate(payload);
        sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_DESCRIPTION_UPDATED_EVENT, {
            ...payload,
            businessId: business?.id,
        });
    };

    // Permission
    const businessIsOpen = !useIsBusinessClosed();
    const descriptionShortPermission =
        useFieldMetadata('description', 'description_short')?.enabled && businessIsOpen;

    const descriptionLongPermission =
        useFieldMetadata('description', 'description_long')?.enabled && businessIsOpen;

    const formatPayload = payload => ({
        ...(descriptionShortPermission && {
            description_short: payload?.descriptionShort || '',
        }),
        ...(descriptionLongPermission && {
            description_long: payload?.descriptionLong || '',
        }),
    });

    const submitForm = () => {
        handleSubmit(payload => onSubmit(formatPayload(payload)))();
        trackSaveBusinessEditSection(SectionNames.DESCRIPTIONS, colorState);
    };

    return (
        <>
            <EditForm
                title={t('business_description')}
                description={t('business_edit_content_descriptions_add')}
                colorState={colorState}
            >
                <Stack>
                    <ControlledTextArea
                        dataTrackId="visibility_location__form_descriptions__short_description"
                        name="descriptionShort"
                        control={control}
                        label={t('description_short')}
                        rows={3}
                        characterCount={MAX_SHORT_DESCRIPTION_CHARACTER_COUNT}
                        onChangeValue={value =>
                            value?.slice(0, MAX_SHORT_DESCRIPTION_CHARACTER_COUNT)
                        }
                        {...useFormFieldFormatter(
                            'description',
                            'description_short',
                            watch,
                            formatPayload,
                            errors,
                        )}
                    />
                    <ControlledTextArea
                        dataTrackId="visibility_location__form_descriptions__long_description"
                        name="descriptionLong"
                        control={control}
                        label={t('description_long')}
                        rows={7}
                        maxRows={14}
                        characterCount={MAX_LONG_DESCRIPTION_CHARACTER_COUNT}
                        onChangeValue={value =>
                            value?.slice(0, MAX_LONG_DESCRIPTION_CHARACTER_COUNT)
                        }
                        {...useFormFieldFormatter(
                            'description',
                            'description_long',
                            watch,
                            formatPayload,
                            errors,
                        )}
                    />
                </Stack>
            </EditForm>
            <EditFormFooter>
                <EditFormStatefulButton
                    isLoading={businessUpdate.isLoading}
                    hasError={!!businessUpdate.error}
                    onClick={submitForm}
                    dataTrackId="visibility_location__form_descriptions__save_button"
                />
            </EditFormFooter>
        </>
    );
};

export default DescriptionsForm;
