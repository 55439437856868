import { connect } from 'react-redux';

import { hidePushNotificationsOptInPrompt } from 'app/states/pushNotifs/actions';
import { pushNotificationsOptInStateSelector } from 'app/states/pushNotifs/selectors';
import { AppState } from 'app/states/reducers';

import { PushNotificationsOptInPrompt } from './PushNotificationsOptInPrompt';

const mapStateToProps = (state: AppState) => ({
    isVisible: pushNotificationsOptInStateSelector(state).isVisible,
});

const mapDispatchToProps = (dispatch: any) => ({
    onClose: () => dispatch(hidePushNotificationsOptInPrompt()),
});

const ConnectedPushNotificationsOptInPrompt = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PushNotificationsOptInPrompt);

export default ConnectedPushNotificationsOptInPrompt;
