import { endOfDay } from 'date-fns';

import { useDateFilter } from 'app/presence/googlePosts/hooks/filters/useDateFilter';

const DATE_TO_FILTER_PARAM = 'date-to';

export function useDateToFilter() {
    return useDateFilter(DATE_TO_FILTER_PARAM, 'end_datetime', (date: Date) => {
        return endOfDay(date);
    });
}
