import QrCodeWithLogo from 'qrcode-with-logos';

import { useTranslation } from 'react-i18next';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import dataLayer from 'app/common/utils/dataLayer';
import { STATIC_BASE_URL } from 'app/config';

import { DetailButton } from './WhatsappPhoneNumberSettings.styled';

interface Props {
    link: string;
}

const QrcodeDownloadButton = ({ link }: Props) => {
    const { t } = useTranslation();
    const logoUrl = `${STATIC_BASE_URL}/images/app/app_messaging/logo_whatsapp.png`;

    const nbMarginPixels = 1;
    const nbPixels = 25 + nbMarginPixels * 2;
    const pixelWidth = 12;
    const width = pixelWidth * (nbPixels + 2);

    const { data: me } = useMe();
    const handleClick = () => {
        dataLayer.pushDict('download_wa_qr_code', {
            partoo_user_id: me?.id,
            org_id: me?.org_id,
        });

        new QrCodeWithLogo({
            // NOSONAR useless object instanciation is not actually useless
            content: link,
            width,
            download: true,
            downloadName: 'whatsapp_qrcode.png',
            logo: {
                src: logoUrl,
                logoSize: 0.25,
                borderSize: 0.05,
                borderRadius: width / 2,
            },
            nodeQrCodeOptions: {
                margin: nbMarginPixels,
            },
        });
    };
    return (
        <DetailButton
            dataTrackId={''}
            full={true}
            appearance="outlined"
            icon={['fa-download']}
            iconPosition="right"
            size="large"
            onClick={handleClick}
        >
            {t('whatsapp_profile_settings__qrcode_button')}
        </DetailButton>
    );
};

export default QrcodeDownloadButton;
