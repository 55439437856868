import { CompetitiveOverviewBusinessData } from 'app/api/types/competitiveBenchmark';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { FiltersEnum } from '../constant/filtersEnum';

export const useOverviewSelection = (): [
    overviewSelection: keyof CompetitiveOverviewBusinessData,
    setOverviewSelection: (overviewSelection: keyof CompetitiveOverviewBusinessData) => void,
] => {
    const [overviewSelection, setOverviewSelection] = useStateQueryParams<
        keyof CompetitiveOverviewBusinessData
    >(FiltersEnum.OVERVIEW);

    if (!overviewSelection) {
        setOverviewSelection('rank');
        return [overviewSelection, setOverviewSelection];
    }

    return [overviewSelection, setOverviewSelection];
};
