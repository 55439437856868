export type PeriodOption = {
    value: string;
    label: string;
    disabled: boolean;
};
export type PeriodSelectOptions = Array<PeriodOption>;

export const period: PeriodSelectOptions = [
    { value: 'day', label: 'day_capitalized', disabled: true },
    { value: 'week', label: 'week_capitalized', disabled: false },
    { value: 'month', label: 'month_capitalized', disabled: false },
    { value: 'year', label: 'year_capitalized', disabled: true },
];

export const PeriodComparisonOptions = [
    { value: 'period', label: 'compare_previous_period' },
    { value: 'year', label: 'compare_previous_year' },
];

export enum Period {
    YEAR = 'year',
    PERIOD = 'period',
}
