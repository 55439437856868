import styled from '@emotion/styled';

import ButtonWithSearchMenuFilterSelection from 'app/common/designSystem/components/molecules/ButtonWithSearchMenuFilterSelection';
import { MenuWrapper } from 'app/common/designSystem/components/molecules/ButtonWithSearchMenuFilterSelection/ButtonWithSearchMenuFilterSelection.styled';

export const StyledButtonWithMenu = styled(ButtonWithSearchMenuFilterSelection)`
    ${MenuWrapper} {
        transform: translate(40px, -40px);
    }
`;
