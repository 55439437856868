import styled from '@emotion/styled';
import { Text } from '@partoohub/ui';

export const ApiKeyRevokeModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: white;
    border-radius: 8px;
    max-width: 500px;
    padding-top: 32px;
    width: max-content;
    img {
        height: 140px;
        width: 320px;
    }
`;
export const RevokeInputContainer = styled.div`
    width: 100%;
`;

export const ApiKeyRevokeModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0 40px;
`;

export const StyledDescriptionText = styled(Text)`
    text-align: center;
`;

export const RevokeButtonContainer = styled.div`
    padding: 8px;
    width: 100%;
    .mdc-button {
        border-radius: 8px !important;
        font-size: 18px !important;
        width: 100%;
    }

    .mdc-text-field-helper-line {
        margin-bottom: 0px;
    }
`;
