import { css } from '@emotion/react';
import { Chip, List, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { InvoiceData } from 'app/api/types/invoice';
import DownloadInvoiceButton from 'app/settingsManagement/components/Invoice/DownloadInvoiceButton/DownloadInvoiceButton';

import { getInvoiceVariant } from 'app/settingsManagement/components/Invoice/InvoiceRow/InvoiceRow';

import { formatISODate } from 'app/utils/dates';

import { MobileChipContainer } from './MobileInvoiceRow.styled';

type InvoiceRowProps = {
    invoice: InvoiceData;
};

const MobileInvoiceRow = ({ invoice }: InvoiceRowProps) => {
    const { t } = useTranslation();

    return (
        <List.Row
            dataTrackId="invoice_row"
            id={invoice.id}
            rowCssContainer={css`
                padding: 8px;
            `}
        >
            <List.Cell>
                <Text variant="bodyMSemibold">{formatISODate(invoice.date)}</Text>
                <Text variant="bodyMRegular">{invoice.invoice_id}</Text>
                <MobileChipContainer>
                    <Chip
                        dataTrackId="invoices_status"
                        chipShape="cube"
                        variant={getInvoiceVariant(invoice.invoice_status)}
                    >
                        {t(`invoice_status__${invoice.invoice_status}`)}
                    </Chip>
                </MobileChipContainer>
            </List.Cell>
            <List.Cell>
                <Text variant="bodyMRegular">{invoice.formatted_total}</Text>
            </List.Cell>
            <List.Cell>
                <DownloadInvoiceButton invoiceId={invoice.id} />
            </List.Cell>
        </List.Row>
    );
};

export default MobileInvoiceRow;
