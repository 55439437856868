import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { parseISO } from 'date-fns/parseISO';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { YEAR, datesRangeOneYear } from 'app/common/services/allowedSeasonalityOptions';
import { DeviceType, useDeviceType } from 'app/hooks';
import {
    CaretContainer,
    Content,
    DropdownContainer,
    DropdownHeaderContainer,
    DropdownList,
    DropdownListContainer,
    DropdownSubTitle,
    DropdownTitle,
    ListItem,
    ListItemText,
    Separator,
    TextContainer,
} from 'app/pages/analytics/VisibilityAnalyticsV2/components/ComparisonPeriodSelect/ComparisonPeriodSelect.styled';
import { useGetDate } from 'app/pages/analytics/VisibilityAnalyticsV2/hooks/queryParams/params/useGetDate';
import {
    Period,
    PeriodComparisonOptions,
} from 'app/pages/analytics/VisibilityAnalyticsV2/types/period';
import {
    COMPARISON_PERIOD_FORMAT,
    calculateRangeDate,
} from 'app/pages/analytics/VisibilityAnalyticsV2/utils/date';
import { AnalyticsQueryKeys } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queryKeys';

export type Props = {
    dataTrack?: string;
};

export const ComparisonPeriodSelect = ({ dataTrack }: Props) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const dates = useGetDate();

    const { device } = useDeviceType();
    const isDesktop = device === DeviceType.Desktop;

    const [comparison, setComparison] = useStateQueryParams(AnalyticsQueryKeys.COMPARISON);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
        comparison || PeriodComparisonOptions[0].value,
    );

    const toggling = useCallback(() => {
        setIsOpen(!isOpen);
    }, []);

    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        if (
            datesRangeOneYear({
                from: parseISO(dates.filter_date__gte),
                to: parseISO(dates.filter_date__lte),
            })
        ) {
            setSelectedOption(Period.PERIOD);
            setComparison(Period.PERIOD);
        }
    }, [dates.filter_date__gte, dates.filter_date__lte]);

    const handleClick = useCallback(
        value => () => {
            setIsOpen(false);
            setComparison(value);
        },
        [],
    );

    const capitalize = useCallback(
        (value: string): string => value.charAt(0).toUpperCase() + value.slice(1),
        [],
    );

    useEffect(() => {
        setSelectedOption(comparison || PeriodComparisonOptions[0].value);
    }, [comparison]);

    const options = PeriodComparisonOptions.map(option => {
        if (option.value === 'year') {
            return {
                ...option,
                disabled: datesRangeOneYear({
                    from: parseISO(dates.filter_date__gte),
                    to: parseISO(dates.filter_date__lte),
                }),
            };
        }
        return {
            ...option,
            disabled: false,
        };
    });

    const data = useMemo(
        () =>
            calculateRangeDate(
                comparison,
                dates.filter_date__gte,
                dates.filter_date__lte,
                COMPARISON_PERIOD_FORMAT,
            ),
        [comparison, dates.filter_date__gte, dates.filter_date__lte],
    );

    return (
        <DropdownContainer
            isDesktop={isDesktop}
            data-track={dataTrack}
            data-intercom-target={dataTrack}
        >
            <DropdownHeaderContainer onClick={toggling}>
                <i className="fa fa-fas fa-chart-simple" />
                <Separator />
                <Content>
                    <TextContainer>
                        <DropdownTitle>
                            {comparison && comparison === YEAR
                                ? capitalize(t('pa_comparison_text_year'))
                                : capitalize(t('compare_period_button_title'))}
                        </DropdownTitle>
                        <DropdownSubTitle>
                            {data ? `${data?.dateFrom} - ${data?.dateTo}` : '-'}
                        </DropdownSubTitle>
                    </TextContainer>
                    <CaretContainer>
                        {isOpen ? (
                            <i className="fa fa-fas fa-caret-right" />
                        ) : (
                            <i className="fa fa-fas fa-caret-down" />
                        )}
                    </CaretContainer>
                </Content>
            </DropdownHeaderContainer>

            {isOpen && (
                <DropdownListContainer ref={ref}>
                    <DropdownList>
                        {options.map(option => (
                            <ListItem
                                disabled={option.disabled}
                                active={option.value === selectedOption}
                                onClick={handleClick(option.value)}
                                key={option.value}
                            >
                                <ListItemText active={option.value === selectedOption}>
                                    {t(option.label)}
                                </ListItemText>
                                {option.value === selectedOption && (
                                    <i className="fa fa-fas fa-check" />
                                )}
                            </ListItem>
                        ))}
                    </DropdownList>
                </DropdownListContainer>
            )}
        </DropdownContainer>
    );
};
