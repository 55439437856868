import { all, put, takeLatest } from 'redux-saga/effects';

import {
    requestPossibleCities,
    searchBusinesses,
} from 'app/common/reducers/newBusinesses';
import { searchOrgs } from 'app/common/reducers/orgs';
import { Saga } from 'app/common/types';

import { LOAD_INITIAL_FILTER_DATA_REQUEST } from '../reducers/loadInitialFilterData';

function* loadInitialData() {
    yield all([
        put(searchBusinesses(1, '')),
        put(requestPossibleCities()),
        put(searchOrgs(1, '')),
    ]);
}

function* loadInitialDataSaga(): Saga {
    yield takeLatest(LOAD_INITIAL_FILTER_DATA_REQUEST, loadInitialData);
}

export default loadInitialDataSaga;
