import { FontAwesomeIconsPartooUsed, LeftElementType, TextInput } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { BusinessListQueryKeys } from 'app/pages/visibility/BusinessListV2/utils/enums';

import { BusinessSearchContainer } from './BusinessSearch.styled';

const BusinessSearch = () => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useStateQueryParams(BusinessListQueryKeys.QUERY);

    return (
        <BusinessSearchContainer
            data-track="visibility_locations__search_bar"
            data-intercom-target="visibility_locations__search_bar"
        >
            <TextInput
                dataTrackId="business_list_search_by"
                value={searchValue}
                onChange={setSearchValue}
                label={t('business_list_search_by')}
                leftElement={[FontAwesomeIconsPartooUsed.faSearch]}
                leftElementType={LeftElementType.Icon}
                isReset
                onReset={() => setSearchValue('')}
            />
        </BusinessSearchContainer>
    );
};

export default BusinessSearch;
