import LogoButton from 'app/common/components/buttons/LogoButton';

type Props = {
    text: string;
    section?: string;
    active?: boolean;
    disabled?: boolean;
    handleClick?: ((string: string) => void) | null;
};

export const SectionButton = ({
    text,
    section = '',
    active = true,
    disabled = false,
    handleClick = null,
}: Props) => {
    const click = () => {
        if (handleClick) handleClick(section);
    };

    return (
        <LogoButton text={text} active={active} disabled={disabled} handleClick={click} logo="" />
    );
};
