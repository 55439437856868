import { useContext, useState } from 'react';

import { Icon, Text } from '@partoohub/ui';
import { CountryCode } from 'libphonenumber-js/types';
import { useTranslation } from 'react-i18next';
import flags from 'react-phone-number-input/flags';

import { SelectedBusinessContext } from 'app/pages/conversations/ReviewBooster/components/ReviewBoosterForm';

import {
    ExplanationText,
    PhoneInputContainer,
    PhoneInputStyled,
    StyledLabel,
} from './PhoneInput.styled';

type Props = {
    phoneNumber: string;
    setPhoneNumber: (value: string) => void;
    disabled?: boolean;
    explanation?: string; // explanation is used as an error message by the explanation HOC
};

const PhoneInput = ({ phoneNumber, setPhoneNumber, explanation, disabled }: Props) => {
    const { t } = useTranslation();
    const { selectedBusiness } = useContext(SelectedBusinessContext);
    const [inputState, setInputState] = useState({ touched: false, focused: false });

    const hasError = !!explanation && inputState.touched;
    const edit = !!phoneNumber || inputState.focused;

    return (
        <>
            <PhoneInputContainer disabled={disabled} hasError={hasError}>
                <PhoneInputStyled
                    defaultCountry={
                        selectedBusiness ? (selectedBusiness.country as CountryCode) : 'FR'
                    }
                    flags={flags}
                    disabled={disabled}
                    value={phoneNumber}
                    onFocus={() => setInputState({ ...inputState, focused: true })}
                    onBlur={() => {
                        setInputState({ focused: false, touched: true });
                    }}
                    onChange={value => setPhoneNumber(value ?? '')}
                />
                <StyledLabel
                    hasError={hasError}
                    disabled={disabled}
                    focused={edit}
                    aria-live="assertive"
                >
                    <Text
                        variant={edit ? 'bodyXSRegular' : 'bodyMRegular'}
                        as="span"
                        color={hasError ? 'danger' : 'secondary'}
                    >
                        {t('rb_customer_phone_placeholder')}
                    </Text>
                    <sup>
                        {' '}
                        <Icon
                            icon={['fa-asterisk']}
                            color="danger"
                            iconSize={edit ? '8px' : '10px'}
                        />
                    </sup>
                </StyledLabel>
            </PhoneInputContainer>
            {hasError && (
                <ExplanationText variant="bodySRegular" color="secondary">
                    {explanation}
                </ExplanationText>
            )}
        </>
    );
};

export default PhoneInput;
