import { IconPrefix } from '@partoohub/ui';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { ControlledTextInput } from 'app/businessEditV2/hookControllerWrapper/ControlledTextInput';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import ControlledLanguageSelect from 'app/pages/settingsV2/subPages/Team/components/UserManagement/sections/UserManagementModals/modals/UserEditModal/UserEditForm/hookControllerWrapper/ControlledLanguageSelect';

import { removeHtmlSpecialCharacters } from 'app/utils/regex';

import PasswordField from './fields/PasswordField';
import SsoOnlyField from './fields/SsoOnlyField';
import { FieldContainer, MyAccountTitle, RoleInput } from './ProfileSettingsForm.styled';

type Props = {
    form: UseFormReturn<any>;
    errors: any;
    orgHasSso: boolean;
    ssoOnly: boolean;
    isSsoAdmin: boolean;
};

const ProfileSettingsForm = ({ form, errors, orgHasSso, ssoOnly, isSsoAdmin }: Props) => {
    const { t } = useTranslation();

    const { data: me } = useMe();
    const { control, watch } = form;

    const handleChangeValueWithoutHtmlCharacters = (value: string) => {
        return removeHtmlSpecialCharacters(value);
    };

    return (
        <>
            <MyAccountTitle variant="heading6">{t('user_info_title')}</MyAccountTitle>

            <FieldContainer>
                <ControlledTextInput
                    dataTrackId="firstName"
                    control={control}
                    name="firstName"
                    label={t('first_name')}
                    leftElement={['fa-circle-user', IconPrefix.SOLID]}
                    error={!!errors?.first_name}
                    onChangeValue={handleChangeValueWithoutHtmlCharacters}
                    notice={errors?.first_name ? t(errors.first_name) : ''}
                    hasOldTextFieldHeight
                />
                <ControlledTextInput
                    dataTrackId="lastName"
                    control={control}
                    name="lastName"
                    label={t('last_name')}
                    leftElement={['fa-id-card-clip', IconPrefix.SOLID]}
                    error={!!errors?.last_name}
                    onChangeValue={handleChangeValueWithoutHtmlCharacters}
                    notice={errors?.last_name ? t(errors.last_name) : ''}
                    hasOldTextFieldHeight
                />
                <ControlledTextInput
                    dataTrackId="email"
                    control={control}
                    name="email"
                    type="email"
                    label={t('email_extended')}
                    leftElement={['fa-at', IconPrefix.SOLID]}
                    disabled={orgHasSso && ssoOnly && !isSsoAdmin}
                    error={!!errors?.email}
                    notice={errors?.email ? t(errors.email) : ''}
                    hasOldTextFieldHeight
                />
                <ControlledLanguageSelect
                    control={control}
                    name="lang"
                    translateLabel={false}
                    hideCross
                />
            </FieldContainer>

            <RoleInput
                dataTrackId="role"
                value={t(me?.role ?? '')}
                onChange={() => undefined}
                label={t('role')}
                disabled
                hasOldTextFieldHeight
            />

            {orgHasSso && isSsoAdmin && <SsoOnlyField control={control} name="ssoOnly" />}

            {!ssoOnly && <PasswordField control={control} watch={watch} errors={errors} />}
        </>
    );
};

export default ProfileSettingsForm;
