import * as React from 'react';

import { Modal } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { V2FormattedBusinessData } from 'app/api/types/business';

import BusinessTable from 'app/common/components/businessModalDeprecated/components/BusinessTable/BusinessTable';
import IS_IFRAME from 'app/common/data/iframe';

import BodyTitle from './BodyTitle';
import {
    BusinessModalContainer,
    BusinessModalContent,
    NoBusinessText,
    NoResultContainer,
} from './BusinessModal.styled';
import FilterByBusinessRibbon from './FilterByBusinessRibbon.container';
import FilterByBusinessTitle from './FilterByBusinessTitle';

type BodyTitleProps = {
    title: string;
    canSelectAll: boolean;
    selectButtonHandleClick: () => void;
    isLoading: boolean;
};

type BusinessTableProps = {
    businessesList: Array<V2FormattedBusinessData>;
    fetchNextBusinesses: () => void;
    allBusinessesHaveBeenFetched: boolean;
    businessLoadingCards: number;
};

export type Props = {
    show: boolean;
    onHide: () => void;
    children?: React.ReactNode;
    headerTitle: string;
    activeRibbon?: boolean;
} & BodyTitleProps &
    BusinessTableProps;

const BusinessModal = ({
    show,
    onHide,
    headerTitle,
    title,
    canSelectAll,
    selectButtonHandleClick,
    isLoading,
    businessesList,
    fetchNextBusinesses,
    allBusinessesHaveBeenFetched,
    businessLoadingCards,
    activeRibbon,
    children,
}: Props) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={show} closeModal={onHide} overlayAppearance={IS_IFRAME ? 'iframe' : 'dark'}>
            <BusinessModalContainer>
                <BusinessModalContent>
                    <FilterByBusinessTitle onHide={onHide} headerTitle={headerTitle} />
                    <div>
                        {children}
                        <BodyTitle
                            title={title}
                            selectAll={canSelectAll}
                            handleClick={selectButtonHandleClick}
                            loading={isLoading}
                        />
                        <div className="separator--horizontal margin_bottom--double" />
                        {allBusinessesHaveBeenFetched && !businessesList.length ? (
                            <NoResultContainer>
                                <NoBusinessText variant="heading5" color="secondary">
                                    {t('no_business_found')}
                                </NoBusinessText>
                            </NoResultContainer>
                        ) : (
                            <BusinessTable
                                businesses={businessesList}
                                fetchNextBusinesses={fetchNextBusinesses}
                                allBusinessesHaveBeenFetched={allBusinessesHaveBeenFetched}
                                businessLoadingCards={businessLoadingCards}
                            />
                        )}
                    </div>
                </BusinessModalContent>
                <FilterByBusinessRibbon active={activeRibbon} />
            </BusinessModalContainer>
        </Modal>
    );
};

export default BusinessModal;
