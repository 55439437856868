import React from 'react';

import { useTranslation } from 'react-i18next';

import { CardTitle } from 'app/pages/analytics/VisibilityAnalyticsV2/components/CardTitle/CardTitle';
import { QueriesChartContainer } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Keywords/KeywordsChart/QueriesChart/QueriesChart.styled';
import { QueriesChartGraph } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Keywords/KeywordsChart/QueriesChart/QueriesChartGraph/QueriesChartGraph';
import { queriesColor } from 'app/pages/analytics/VisibilityAnalyticsV2/utils/queries';

export const QueriesChart = () => {
    const { t } = useTranslation();

    return (
        <QueriesChartContainer
            data-track="analytics_visiblity__chart_queries"
            data-intercom-target="analytics_visiblity__chart_queries"
        >
            <CardTitle
                color={queriesColor}
                icon={<i className="fa fa-fas fa-keyboard"></i>}
            >
                {t('queries')}
            </CardTitle>
            <QueriesChartGraph />
        </QueriesChartContainer>
    );
};
