import { useState } from 'react';

import { Button, FontAwesomeIconsPartooUsed, Stack, Text, toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { EmailInput } from 'app/common/components/form/EmailInput';
import DropZone from 'app/common/designSystem/components/molecules/DropZone';
import i18n from 'app/common/translations/i18n';

import { InstructionModal } from './InstructionModal';
import { SectionHeader, SectionSubTitle } from '../BulkUpdateForm/BulkUpdatedForm.styled';

type Props = {
    email: string;
};

interface ImportDataApi {
    myEmail: string;
    myFile: File | null;
}

export const ImportData = ({ email }: Props) => {
    const { t } = useTranslation();
    const [importedFile, setImportedFile] = useState<File | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);
    const [emailToSend, setEmailToSend] = useState<string>(email);
    const mutation = useMutation(
        ({ myEmail, myFile }: ImportDataApi) =>
            api.bulkImportClient.bulkBusinessImport(myFile as Blob, myEmail),
        {
            onSuccess: () => {
                toast.loading(
                    i18n.t('report_will_be_sent_after_uploader'),
                    i18n.t('hours_update_progress'),
                );
                setTimeout(() => {
                    resetForm();
                }, 1000);
            },
            onError: (error: Error) => {
                toast.error(error.message, i18n.t('request_issue'));
                setTimeout(() => {
                    resetForm();
                }, 1000);
            },
        },
    );

    const maxSize = 5 * 1000000; // 5Mo

    const resetForm = () => {
        setImportedFile(null);
    };

    const handleDropSuccess = (files: File[]) => {
        const [file] = files;

        setImportedFile(file);
    };

    const handleDropClear = () => {
        resetForm();
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleOpenModal = () => setIsModalOpen(true);

    return (
        <Stack gap="24px">
            <SectionHeader>
                <Stack gap="0">
                    <Text variant="heading6">{t('bulk_update_import_title')}</Text>
                    <SectionSubTitle>{t('bulk_update_import_subtitle')}</SectionSubTitle>
                </Stack>

                <Button
                    dataTrackId=""
                    variant="primary"
                    appearance="text"
                    onClick={handleOpenModal}
                    icon={[FontAwesomeIconsPartooUsed.faCircleInfo]}
                >
                    {t('bulk_update_import_instructions')}
                </Button>
            </SectionHeader>

            <DropZone
                files={importedFile && [importedFile]}
                maxSize={maxSize}
                handleDropSuccess={handleDropSuccess}
                handleDropClear={handleDropClear}
            />

            <Button
                dataTrackId="bulk_update_import__button"
                onClick={toggleModal}
                full
                variant="primary"
                disabled={mutation.isLoading || !importedFile}
                size="large"
                shape="cube"
                icon={[FontAwesomeIconsPartooUsed.faDownload]}
                isLoading={mutation.isLoading}
            >
                {t('upload_data')}
            </Button>
            <ConfirmModal
                show={showModal}
                title={t('bulk_import_report')}
                content={t('export_sent_to_this_email')}
                confirmLabel={t('send_email')}
                onConfirm={() => {
                    mutation.mutate({
                        myEmail: emailToSend,
                        myFile: importedFile,
                    });
                    toggleModal();
                }}
                onHide={toggleModal}
                trackId="bulk_import_data__modal"
                modalFor="download"
            >
                <EmailInput
                    value={emailToSend}
                    onChange={setEmailToSend}
                    label={t('email_input_placeholder')}
                    required
                />
            </ConfirmModal>

            <InstructionModal show={isModalOpen} handleClose={() => setIsModalOpen(false)} />
        </Stack>
    );
};
