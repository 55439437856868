import { useLocation } from 'react-router-dom';

import { HOMEPAGE_PATH } from 'app/routing/routeIds';

/**
 * Hook to check if the current page is the homepage
 */
export const useIsHomepage = (): boolean => {
    const { pathname } = useLocation();
    return pathname.startsWith(HOMEPAGE_PATH);
};
