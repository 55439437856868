import React from 'react';

import { Modal } from '@partoohub/ui';

import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { UserListQueryKeys } from 'app/pages/settingsV2/subPages/Team/components/UserManagement/utils/enums';

import UserDeleteContent from './UserDeleteContent';

const UserDeleteModal = () => {
    const [userDelete, setUserDelete] = useStateQueryParams(UserListQueryKeys.USER_DELETE);

    return (
        <Modal isOpen={!!userDelete} closeModal={() => setUserDelete('')}>
            <UserDeleteContent userId={userDelete} closeModal={() => setUserDelete('')} />
        </Modal>
    );
};

export default UserDeleteModal;
