import React from 'react';

import { useTranslation } from 'react-i18next';

import { AnalyticsBoxTextBodyStyled } from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsSection/analyticsCards/AnalyticsBoxTextBody.styled';

import AnalyticsBoxTextStat from './AnalyticsBoxTextStat';

type Props = {
    leftValue: string;
    rightValue: string;
    leftValueText: string;
    rightValueText: string;
    isSmall: boolean;
    rightLoading: boolean;
    leftLoading: boolean;
};

const AnalyticsBoxTextBody = ({
    leftValue,
    rightValue,
    leftValueText,
    rightValueText,
    isSmall,
    rightLoading,
    leftLoading,
}: Props) => {
    const { t } = useTranslation();

    return (
        <AnalyticsBoxTextBodyStyled>
            <AnalyticsBoxTextStat
                analyticStat={leftValue}
                analyticColor="blueberry"
                analyticText={t(leftValueText)}
                isSmall={isSmall}
                isLoading={leftLoading}
            />
            <AnalyticsBoxTextStat
                analyticStat={rightValue}
                analyticColor="warning"
                analyticText={t(rightValueText)}
                isSmall={isSmall}
                isLoading={rightLoading}
            />
        </AnalyticsBoxTextBodyStyled>
    );
};

export default AnalyticsBoxTextBody;
